import React, { memo, useCallback, useMemo } from 'react';
import { TableRow, TableCell, Grid } from '@mui/material';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import {
  SalesOrderBundleItem,
  SalesOrderItem,
  SalesOrderItemTypes,
} from 'services/salesOrders';
import { ImageItemBox } from 'ui/components/Image/ImageItemBox';
import { showThumbnail } from 'services/thumbnail/redux';
import { EACH_UOM_ID } from 'services/uoms';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';
import { TextFieldQuantity } from 'ui/components/TextField/TextFieldQuantity';

import {
  useSalesOrderTableCellStyle,
  useSalesOrderItemsStyle,
} from '../../styled';
import { getSettingsCompany } from 'services/settings/company';
import { calculateBundleSubItemQuantity } from './helpers';
import { itemHasSerialTracking } from 'services/inventory';

const BundleSubRow: React.FC<RenderCustomRowProps<SalesOrderBundleItem>> = (
  props
) => {
  const {
    row,
    meta: { salesOrder },
  } = props;

  const { homeCurrency, useMultiCurrency } = useSelector(getSettingsCompany);

  const homeCurrencyCode = (homeCurrency && homeCurrency.code) || 'USD';

  const activeMulticurrencyCode = _.get(
    salesOrder,
    'customer.currency.code',
    null
  );

  const showMultiCurrency = useMemo(
    () =>
      useMultiCurrency &&
      activeMulticurrencyCode &&
      activeMulticurrencyCode !== homeCurrencyCode,
    [useMultiCurrency, activeMulticurrencyCode]
  );

  const dispatch = useDispatch();

  const handleOpenPreview = useCallback(
    (e) => (imageUrl: string) => {
      dispatch(showThumbnail(imageUrl));
      e.stopPropagation();
    },
    [dispatch]
  );
  const isSerialTracked = useMemo(() => {
    if (row.saleItem?.item) {
      return itemHasSerialTracking(row.saleItem.item);
    }
    return false;
  }, [row.saleItem]);
  const calculateBundleItemQuantity = useCallback(
    (bundleQuantity: number | null, itemQuantity: number | null) => {
      return calculateBundleSubItemQuantity(bundleQuantity, itemQuantity);
    },
    [row]
  );

  const classes = useSalesOrderTableCellStyle();
  const rowClasses = useSalesOrderItemsStyle();

  if (!row) {
    return null;
  }

  const salesOrderItems = _.get(row, 'salesOrderItems');

  return (
    <>
      {!!salesOrderItems &&
        salesOrderItems.map((salesOrderItem: SalesOrderItem, index: number) => {
          const imageUrl = _.get(
            salesOrderItem,
            'saleItem.defaultImage',
            ''
          ) as string;

          return (
            <TableRow
              key={index}
              className={
                salesOrderItem.salesOrderItemType ===
                SalesOrderItemTypes.CreditReturn
                  ? rowClasses.creditReturn
                  : ''
              }
            >
              <TableCell />
              <TableCell
                style={{
                  paddingLeft: 30,
                }}
                className={classes.cell}
              >
                <ImageItemBox
                  name={_.get(salesOrderItem, 'name', null)}
                  description={_.get(salesOrderItem, 'description', null)}
                  source={imageUrl}
                  onClick={handleOpenPreview(imageUrl)}
                />
              </TableCell>
              <TableCell className={classes.cell}>
                <Grid container>
                  <Grid item xs>
                    <CurrencyField
                      placeholder="Price"
                      name="price"
                      value={salesOrderItem.price}
                      disabled
                      fullWidth
                      decimalPlaces={6}
                      noMargin
                      allowNegative
                    />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell className={classes.cell}>
                <TextFieldQuantity
                  placeholder="Quantity"
                  name="quantity"
                  value={calculateBundleItemQuantity(
                    row.quantity,
                    salesOrderItem.quantity
                  )}
                  selectedUomId={salesOrderItem.uomId || EACH_UOM_ID}
                  fullWidth
                  uoms={[_.get(salesOrderItem, 'saleItem.defaultUom', [])]}
                  disabled
                  disableAutoFocus
                  noMargin
                  isDecimal={!isSerialTracked}
                />
              </TableCell>
              <TableCell colSpan={showMultiCurrency ? 7 : 5} />
            </TableRow>
          );
        })}
    </>
  );
};

export default memo(BundleSubRow);
