import React from 'react';

import { colorPalette, icons, styled, SupportColors } from 'ui/theme';

import { IconCmp, IconProps } from './types';

const Icon: IconCmp = (props: IconProps) => {
  const { name, ...otherProps } = props;
  const ResolvedIcon = icons[name];

  if (!ResolvedIcon) {
    return null;
  }

  return <ResolvedIcon {...otherProps} />;
};

const resolveFillColor = (props: IconProps) => {
  const isOnDarkColor = props.onDark && SupportColors.iconOnDark;
  const resolvedColor =
    props.fill || isOnDarkColor || colorPalette.applicationDarkGray80;
  return props.noDefaultFill ? `` : resolvedColor;
};

// Prevent passing down noDefaultFill & onDark on HTML element
const StyledIcon = styled(Icon).withConfig({
  shouldForwardProp: (forwardProps) =>
    !['noDefaultFill', 'onDark'].includes(forwardProps),
})`
  height: ${({ height }) => `${height}px` || '24px'};
  width: ${({ width }) => `${width}px` || '24px'};
  fill: ${resolveFillColor};
`;

export default React.memo(StyledIcon);
