import { createApiAction } from 'services/api';
import {
  StartAction,
  SuccessAction,
  FailureAction,
} from 'services/api/redux/types';
import {
  createAddSearch,
  createDeleteSearch,
  createFetchSearch,
  createUpdateSearch,
} from 'services/search';

export enum LocationsActionTypes {
  FETCH_LOCATIONS_START = 'locations/fetch_locations_start',
  FETCH_LOCATIONS_SUCCESS = 'locations/fetch_locations_success',
  FETCH_LOCATIONS_FAILURE = 'locations/fetch_locations_failure',
}

const fetchLocationsActionStart = (): StartAction => ({
  type: LocationsActionTypes.FETCH_LOCATIONS_START,
  payload: null,
});

const fetchLocationsActionSuccess = (payload: any): SuccessAction => {
  return {
    type: LocationsActionTypes.FETCH_LOCATIONS_SUCCESS,
    payload,
  };
};

const fetchLocationsActionFailure = (error: string): FailureAction => ({
  type: LocationsActionTypes.FETCH_LOCATIONS_FAILURE,
  payload: { error },
});

export const fetchLocations = () =>
  createApiAction(
    {
      path: '/v1/locations?expand=address&pageSize=100&orderBy=sortNumber&ascending=true',
      method: 'GET',
    },
    fetchLocationsActionStart,
    fetchLocationsActionSuccess,
    fetchLocationsActionFailure
  );
// SEARCH ACTION CREATORS

export const fetchSearch = createFetchSearch('location');
export const addSearch = createAddSearch('location');
export const updateSearch = createUpdateSearch('location');
export const deleteSearch = createDeleteSearch('location');
