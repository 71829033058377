import { ExportHistory } from 'services/accounting';
import { DataWithPagination } from 'services/api';
import { Pagination } from 'services/search';

export interface CardHistoryProps {
  history: DataWithPagination<ExportHistory>;
  handlePaginationChange: (pagination: Pagination) => void;
}
export enum ExportStatus {
  Success = 'Success',
  Failure = 'Failure',
}
