import { Dispatch } from 'redux';

import { Thunk } from './types';

export const dispatchThunks = async (
  thunks: Thunk<Promise<any>>[] | undefined,
  dispatch: Dispatch<any>
) => {
  if (!thunks || !thunks.length) {
    return;
  }
  const promises = thunks.map((thunk) => dispatch(thunk()));
  const results: any = await Promise.all(promises);
  results.forEach((result: any) => {
    if (!result.ok) {
      throw new Error(result.errorMessage);
    }
  });
};
