import React, { memo, useMemo } from 'react';
import { TableCell, Typography } from '@mui/material';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import { ImageItemBox } from 'ui/components/Image/ImageItemBox';
import { IconItemBox } from 'ui/components/Icon/IconItemBox';
import { IconNames } from 'ui/theme';

import { useReconcileWizardSummaryStyle } from '../../../ReconcileWizard/components/ReconcileWizardSummary/styled';
import { ReceiptItemReceive } from 'services/receiving';

const RecieveSummarySplitRow: React.FC<
  RenderCustomRowProps<ReceiptItemReceive>
> = (props) => {
  const { row } = props;

  const classes = useReconcileWizardSummaryStyle();

  const isItem = useMemo(
    () => row.receiptItem.item !== null,
    [row.receiptItem.item]
  );
  const quantityRemaining = useMemo(
    () => (row.receiptItem.quantity ?? 0) - (row.quantity ?? 0),
    [row.receiptItem.quantity, row.quantity]
  );
  return (
    <>
      <TableCell>
        {isItem ? (
          <ImageItemBox
            name={row.receiptItem.item!.name}
            description={row.receiptItem.item!.description}
            source={row.receiptItem.item!.imageLinkList[0]?.url}
          />
        ) : (
          <IconItemBox
            source={IconNames.AttachMoney}
            description={'Some description'}
            name={''}
          />
        )}
      </TableCell>

      <TableCell className={classes.quantityColumn}>
        <Typography>
          {(quantityRemaining ?? '') +
            ' ' +
            (row.receiptItem.uom?.abbreviation ?? '')}
        </Typography>
      </TableCell>
    </>
  );
};

export default memo(RecieveSummarySplitRow);
