import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colorPalette } from 'ui/theme';

export const useDashboardWidgetsStyle = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(7),
  },
  card: {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(1),
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.16)',
    backgroundColor: colorPalette.white,
    borderRadius: 4,
    display: 'flex',
  },
  cardIcon: {
    width: 32,
    height: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colorPalette.statusGreen,
    borderRadius: 2,
  },
  cardIconRed: {
    backgroundColor: colorPalette.statusRed,
  },
  icon: {
    color: colorPalette.white,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(1),
  },
  cardContentHeader: {
    display: 'flex',
  },
  cardContentLabel: {
    fontSize: 'calc(12px * var(--font-size-multiplier, 1))',
    lineHeight: 1,
    color: colorPalette.applicationDarkGray80,
    textTransform: 'uppercase',
  },
  cardContentPercentage: {
    marginLeft: theme.spacing(0.5),
    fontSize: 'calc(12px * var(--font-size-multiplier, 1))',
    lineHeight: 1,
    color: colorPalette.statusGreen,
  },
  cardContentRed: {
    color: colorPalette.statusRed,
  },
  cardContentValue: {
    marginTop: 4,
    fontWeight: 700,
    fontSize: 'calc(16px * var(--font-size-multiplier, 1))',
    lineHeight: 1,
  },
}));
