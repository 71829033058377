import _ from 'lodash';
import { SerialRow, TrackingGroup } from 'services/inventory';

export const existingInventoryTrackingGroupValidation = (
  hasSerialTracking: boolean,
  trackingGroups: TrackingGroup[]
) => {
  // check if any tracking info has empty value
  const hasTrackingErrors = trackingGroups.some((trg) => {
    if (trg.quantity && trg.quantity > 0) {
      return trg.trackingInfoList.some((ti) => _.isNull(ti.value));
    }
    return false;
  });

  if (hasSerialTracking) {
    const isSerialValid = existingInventorySerialValidation(trackingGroups);
    return isSerialValid || hasTrackingErrors;
  }
  return hasTrackingErrors;
};

const existingInventorySerialValidation = (trackingGroups: TrackingGroup[]) => {
  // check if number of serials is not equal to item quantity
  return trackingGroups
    .filter((tg) => tg.quantity)
    .some(
      (tg) =>
        tg.serialList.length !== tg.quantity ||
        existingInventoryOnlySerialListValidation(tg.serialList)
    );
};

export const existingInventoryOnlySerialListValidation = (
  serialList: SerialRow[]
) => {
  return serialList.some((s) => Object.values(s.serialNumbers).some(_.isNull));
};
