import React, { memo, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';

import {
  COMPANY_WIDE_ID,
  fetchLocationsAPI,
  Location,
  sortLocations,
} from 'services/locations';

import { Modal } from 'ui/components/Modal/Modal';
import { ItemsTable } from 'ui/components/Table/ItemsTable';

import { locationOrderModalColumns, locationOrderPagination } from './consts';
import { LocationOrderModalProps } from './types';
import { useLocationOrderModalStyle } from './styled';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { IconNames } from 'ui/theme';

const LocationOrderModal: React.FC<LocationOrderModalProps> = (props) => {
  const { open, onClose, onApply } = props;

  const [locations, setLocations] = useState<Location[]>([]);
  const [loading, setLoading] = useState(false);
  const [activeId, setActiveId] = useState<number | null>(null);

  const classes = useLocationOrderModalStyle();

  const activeLocation = useMemo(
    () => locations.find((l) => l.id === activeId) || null,
    [locations, activeId]
  );

  const disabledUp = useMemo(
    () => activeLocation === null || activeLocation.sortNumber! === 1,
    [activeLocation]
  );

  const disabledDown = useMemo(() => {
    if (!activeLocation) {
      return true;
    }

    return activeLocation.sortNumber === locations.length;
  }, [activeLocation, locations]);

  useEffect(() => {
    if (!open) {
      setLocations([]);
      return;
    }

    (async () => {
      setLoading(true);
      try {
        const resLocations = await fetchLocationsAPI({
          pagination: locationOrderPagination,
        });

        const filteredData = resLocations.data.filter(
          (l) => l.id !== COMPANY_WIDE_ID
        );
        setLocations(filteredData);
      } catch {
        // do nothing
      }
      setLoading(false);
    })();
  }, [open]);

  const handleApplyClicked = async () => {
    setLoading(true);
    try {
      await sortLocations(locations);
    } catch {
      setLoading(false);
      return;
    }

    onApply();
    setLoading(false);
    setLocations([]);
    setActiveId(null);
  };

  const handleCancelClicked = () => {
    onClose();
    setLocations([]);
    setActiveId(null);
  };

  const handleMoveUpClicked = () => {
    if (!activeLocation) {
      return;
    }

    const activeIndex = locations.findIndex((l) => l.id === activeId);

    // if first
    if (activeIndex < 1) {
      return;
    }

    const reorderedLocations = [
      ...locations.slice(0, activeIndex - 1),
      activeLocation,
      locations[activeIndex - 1],
      ...locations.slice(activeIndex + 1),
    ];

    setLocations(
      reorderedLocations.map((l, i) => ({ ...l, sortNumber: i + 1 }))
    );
  };

  const handleMoveDownClicked = () => {
    if (!activeLocation) {
      return;
    }

    const activeIndex = locations.findIndex((l) => l.id === activeId);

    // if last
    if (activeIndex === locations.length - 1) {
      return;
    }

    const reorderedLocations = [
      ...locations.slice(0, activeIndex),
      locations[activeIndex + 1],
      activeLocation,
      ...locations.slice(activeIndex + 2),
    ];

    setLocations(
      reorderedLocations.map((l, i) => ({ ...l, sortNumber: i + 1 }))
    );
  };

  return (
    <Modal
      open={open}
      onCancelClicked={handleCancelClicked}
      onApplyClicked={handleApplyClicked}
      isLoadingContent={loading}
      applyDisabled={!activeLocation || !activeLocation.sortNumber}
      applyLabel="Save order"
      title="Order Locations"
      withoutDefaultPadding
      nestedScrollAreas
      maxWidth="sm"
    >
      <Box display="flex" height="100%" className={classes.content}>
        <Box overflow="auto" flex={1} className="locations-table">
          <ItemsTable
            data={locations}
            columns={locationOrderModalColumns}
            selectableItems={false}
            onItemClick={setActiveId}
            activeId={activeId}
            emptyTableText="Loading locations"
          />
        </Box>
        <Box className={classes.sidebar}>
          <>
            <FBOButton
              variant="primary"
              color="neutral"
              size="large"
              icon={IconNames.CaratUp}
              disabled={disabledUp}
              onClick={handleMoveUpClicked}
              data-qa="order-pick-arrow-arrow-up"
              sx={{ marginBottom: '16px' }}
            />
            <FBOButton
              variant="primary"
              color="neutral"
              size="large"
              icon={IconNames.FBOCaratDown}
              disabled={disabledDown}
              onClick={handleMoveDownClicked}
              data-qa="order-pick-arrow-down"
            />
          </>
        </Box>
      </Box>
    </Modal>
  );
};

export default memo(LocationOrderModal);
