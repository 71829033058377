import {
  SerialRow,
  ItemTrackingType,
  ItemInventoryEvents,
} from 'services/inventory';
import { PickStatus, PickItemStatus } from 'services/picking';
import { ShipStatus } from 'services/shipping';
import { SalesOrderStatus } from 'services/salesOrders';
import { Errors } from 'services/forms/validation';
import { Item } from 'services/items';

export enum SerialTableStatus {
  AutoSelected = 'Auto selected',
  Success = 'Success',
  PartialSelected = 'Partial selected',
}

export interface SerialTableProps {
  serialList: SerialRow[];
  setSerialList?: React.Dispatch<React.SetStateAction<SerialRow[]>>;
  itemTrackingTypes: ItemTrackingType[];
  minSerialQuantity?: number;
  selectedRows?: number[];
  setSelectedRows?: React.Dispatch<React.SetStateAction<number[]>>;
  onSelectedRowsChanged?: (newRowIds: number[]) => void;
  title?: string;
  withoutTitleBar?: boolean;
  status?:
    | PickStatus
    | PickItemStatus
    | ShipStatus
    | SalesOrderStatus
    | SerialTableStatus;
  customStatus?: string;
  customStatusStyle?: string;
  emptyTableText?: string;
  canEditRow?: boolean;
  canClearRow?: boolean;
  canSelectRow?: boolean;
  disableAutoAssign?: boolean;
  allowSerialNumberImport?: boolean;
  rowErrors?: Errors[];
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setQtyErrorMessage?: React.Dispatch<React.SetStateAction<string>>;
  isLoading?: boolean;
  quantity?: number;
  item?: Item;
  setItem?: React.Dispatch<React.SetStateAction<Item>>;
  showDuplicates?: boolean;
  duplicateArray?: string[];
  eventType?: ItemInventoryEvents;
  readOnly?: boolean;
  alreadySorted?: boolean;
  headerMargin?: string;
  sx?: React.CSSProperties;
  meta?: any;
}
