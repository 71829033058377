import { createApiAction } from 'services/api';
import {
  StartAction,
  SuccessAction,
  FailureAction,
} from 'services/api/redux/types';
import {
  createAddSearch,
  createDeleteSearch,
  createFetchSearch,
  createUpdateSearch,
} from 'services/search';

export enum CustomersActionTypes {
  FETCH_CUSTOMERS_START = 'customers/fetch_customers_start',
  FETCH_CUSTOMERS_SUCCESS = 'customers/fetch_customers_success',
  FETCH_CUSTOMERS_FAILURE = 'customers/fetch_customers_failure',
}

const fetchCustomersActionStart = (): StartAction => ({
  type: CustomersActionTypes.FETCH_CUSTOMERS_START,
  payload: null,
});

const fetchCustomersActionSuccess = (payload: any): SuccessAction => {
  return {
    type: CustomersActionTypes.FETCH_CUSTOMERS_SUCCESS,
    payload,
  };
};

const fetchCustomersActionFailure = (error: string): FailureAction => ({
  type: CustomersActionTypes.FETCH_CUSTOMERS_FAILURE,
  payload: { error },
});

export const fetchCustomers = () =>
  createApiAction(
    {
      path: '/v1/customers?expand=customerAddresses.customerAddressContacts&pageNumber=1&pageSize=100',
      method: 'GET',
    },
    fetchCustomersActionStart,
    fetchCustomersActionSuccess,
    fetchCustomersActionFailure
  );

// SEARCH ACTIONS

export const fetchSearch = createFetchSearch('customers');
export const addSearch = createAddSearch('customers');
export const updateSearch = createUpdateSearch('customers');
export const deleteSearch = createDeleteSearch('customers');
