import _ from 'lodash';

import { Address, Contact } from 'services/addresses';
import { transformDateToMomentDate } from 'helpers';
import {
  ObjectType,
  transformCustomField,
  transformToRequestCustomField,
} from 'services/customFields';
import { transformItem } from 'services/items';
import { transformToTag } from 'services/tags';
import {
  transformDocument,
  transformToRequestDocument,
} from 'services/documents';
import { transformCurrency } from 'services/currencies';

import { Vendor, VendorItem } from './types';

export const transformVendor = (response: any): Vendor => {
  const addresses = response.vendorAddresses || [];
  const vendorItems = response.vendorItems || [];
  const tags = response.tags || [];
  const customFields = response.customFields || [];
  const documents = response.documents || [];

  const exchangeRate = _.get(response.currency, 'exchangeRate', 1);

  return {
    id: response.id,
    acctNum: response.acctNum,
    accountingClassId: response.accountingClassId,
    addresses: addresses.map(transformToAddress),
    alertNotes: response.alertNotes,
    bcc: response.bcc,
    cc: response.cc,
    currencyId: response.currencyId,
    currency: response.currency ? transformCurrency(response.currency) : null,
    customExchangeRate: response.customExchangeRate,
    customFields: customFields.map(transformCustomField),
    dateCreated: transformDateToMomentDate(response.dateCreated),
    dateLastModified: transformDateToMomentDate(response.dateLastModified),
    defaultCarrierId: response.defaultCarrierId,
    defaultCarrierServiceId: response.defaultCarrierServiceId,
    deleted: response.deleted,
    documents: documents.map(transformDocument),
    email: response.email,
    fax: response.fax,
    mobile: response.mobile,
    name: response.name,
    notes: response.notes,
    onlyShowVendorItemsOnPo: response.onlyShowVendorItemsOnPo,
    other: response.other,
    paymentTerm: response.paymentTerm,
    paymentTermId: response.paymentTermId,
    office: response.office,
    taxable: response.taxable,
    tags: tags.map(transformToTag),
    url: response.url,
    vendorItems: vendorItems.map((i: any) =>
      transformToVendorItem(i, exchangeRate)
    ),
    vendorMin: response.vendorMin,
    version: response.version,
  };
};

const transformToAddress = (res: any): Address => {
  const contacts = res.vendorAddressContacts || [];
  return {
    id: res.id,
    attention: res.attention,
    city: res.city,
    companyName: res.companyName,
    contacts: contacts.map(transformToContact),
    country: res.country,
    deleted: false,
    email: res.email,
    name: res.name,
    phone: res.phone,
    postalCode: res.postalCode,
    state: res.state,
    street: res.street,
    street2: res.street2,
    residential: res.residential,
    version: res.version,
    defaultBillTo: res.defaultBillTo,
    defaultShipTo: res.defaultShipTo,
    verified: res.verified,
  };
};

export const transformToVendorItem = (
  res: any,
  exchangeRate: number
): VendorItem => {
  return {
    id: res.id,
    cost: res.cost,
    dateCreated: transformDateToMomentDate(res.dateCreated),
    dateLastModified: transformDateToMomentDate(res.dateLastModified),
    defaultUomId: res.defaultUomId,
    defaultFlag: res.defaultFlag,
    deleted: res.deleted,
    item: res.item ? transformItem(res.item) : null,
    itemId: res.itemId,
    leadTime: res.leadTime,
    multicurrencyVendorItemCost: (res.cost || 0) * exchangeRate,
    minimumQuantity: res.minimumQuantity,
    name: res.name,
    vendor: res.vendor ? transformVendor(res.vendor) : null,
    vendorId: res.vendorId,
    version: res.version,
  };
};

const transformToContact = (res: any): Contact => {
  return {
    id: res.id,
    dateCreated: transformDateToMomentDate(res.dateCreated),
    dateLastModified: transformDateToMomentDate(res.dateLastModified),
    defaultFlag: res.defaultFlag,
    deleted: false,
    name: res.name,
    type: res.type,
    value: res.value,
    version: res.version,
  };
};

export const transformToRequestItem = (item: VendorItem): any => {
  const payload = {
    itemId: item.itemId,
    cost: item.cost,
    name: item.name,
    // TODO: Always returning true until changed on backend ( probably this will be removed )
    defaultFlag: true,
    leadTime: item.leadTime,
    minimumQuantity: item.minimumQuantity,
    defaultUomId: item.defaultUomId,
  };

  // POST
  if (item.id === null || item.id < 0) {
    return payload;
  }

  // PUT
  return {
    ...payload,
    id: item.id,
    deleted: item.deleted,
    version: item.version,
  };
};

export const transformVendorToRequestVendor = (vendor: Vendor): any => {
  const newVendor = {
    alertNotes: vendor.alertNotes,
    accountingClassId: vendor.accountingClassId,
    name: vendor.name,
    paymentTermId: vendor.paymentTermId,
    currencyId: vendor.currencyId,
    currency: vendor.currency,
    customExchangeRate: vendor.customExchangeRate,
    vendorAddresses: vendor.addresses.map(transformToRequestAddress),
    vendorItems: vendor.vendorItems.map(transformToRequestItem),
    onlyShowVendorItemsOnPo: vendor.onlyShowVendorItemsOnPo,
    customFields: vendor.customFields.map((c) =>
      transformToRequestCustomField(c, ObjectType.Vendor, true)
    ),
    other: vendor.other,
    email: vendor.email,
    cc: vendor.cc,
    bcc: vendor.bcc,
    office: vendor.office,
    mobile: vendor.mobile,
    fax: vendor.fax,
    url: vendor.url,
    acctNum: vendor.acctNum,
    notes: vendor.notes,
    taxable: vendor.taxable,
    tags: vendor.tags.map((t) => ({ name: t.name })),
    defaultCarrierId: vendor.defaultCarrierId,
    defaultCarrierServiceId: vendor.defaultCarrierServiceId,
    documents: vendor.documents.map(transformToRequestDocument),
    vendorMin: vendor.vendorMin,
  };

  // POST
  if (!vendor.id || vendor.id < 0) {
    return newVendor;
  }

  // PUT
  return {
    ...newVendor,
    id: vendor.id,
    customFields: vendor.customFields.map((c) =>
      transformToRequestCustomField(c, ObjectType.Vendor, true)
    ),
    deleted: vendor.deleted,
    documents: vendor.documents.map(transformToRequestDocument),
    version: vendor.version,
  };
};

const transformToRequestAddress = (address: Address): any => {
  const newAddress = {
    attention: address.attention,
    city: address.city,
    companyName: address.companyName,
    country: address.country,
    defaultBillTo: address.defaultBillTo,
    defaultShipTo: address.defaultShipTo,
    name: address.name,
    postalCode: address.postalCode,
    residential: address.residential,
    state: address.state,
    street: address.street,
    street2: address.street2,
    vendorAddressContacts: address.contacts.map(transformToRequestContact),
    verified: address.verified,
  };

  // POST
  if (!address.id || address.id < 0) {
    return newAddress;
  }

  // PUT
  return {
    ...newAddress,
    id: address.id,
    deleted: address.deleted,
    version: address.version,
  };
};

const transformToRequestContact = (contact: Contact): any => {
  const newContact = {
    name: contact.name,
    type: contact.type,
    value: contact.value,
    defaultFlag: contact.defaultFlag,
  };

  // POST
  if (!contact.id || contact.id < 0) {
    return newContact;
  }

  // PUT
  return {
    ...newContact,
    id: contact.id,
    deleted: contact.deleted,
    version: contact.version,
  };
};
