import {
  PaymentFormValuesCard,
  PaymentFormValuesCheck,
  PaymentShownValues,
} from './types';

export const MODAL_HEIGHT = 366;

export const defaultFormValuesCard: PaymentFormValuesCard = {
  nameOnCard: null,
  cardType: null,
  expDate: null,
  lastFourDigits: null,
};

export const defaultFormValuesCheck: PaymentFormValuesCheck = {
  referenceNumber: null,
};

export const defaultShownValues: PaymentShownValues = {
  total: 110.0,
  totalPayments: 10.0,
  balance: 0.0,
};
