import { PricingRule } from 'services/pricingRules';

import { PricingRulesCustomersGroup } from './components/PricingRulesCustomers/types';
import { PricingRulesProductsGroup } from './components/PricingRulesProducts/types';

export const getCheckedCustomersGroup = (pricingRule: PricingRule) => {
  if (pricingRule.customerId) {
    return PricingRulesCustomersGroup.Customer;
  }

  if (pricingRule.customerTags.length > 0) {
    return PricingRulesCustomersGroup.Tag;
  }

  return PricingRulesCustomersGroup.Everyone;
};

export const getCheckedProductsGroup = (pricingRule: PricingRule) => {
  if (pricingRule.saleItemId || pricingRule.itemId) {
    return PricingRulesProductsGroup.Product;
  }

  if (pricingRule.saleItemTags.length > 0) {
    return PricingRulesProductsGroup.Tag;
  }

  return PricingRulesProductsGroup.All;
};
