import { imageApi } from 'services/api/config';
import { ImageStorageType } from 'services/images';

export const uploadImage = async (
  image: string,
  storageType: ImageStorageType,
  name?: string | null
): Promise<string> => {
  const res = await imageApi.post(`/api/v1/storage/images`, [
    { url: image, type: storageType, name: name },
  ]);

  return res.data[0];
};

export const copyImages = async (images: string[]): Promise<string[]> => {
  const res = await imageApi.post(`/api/v1/storage/images/copy`, images);

  return res.data;
};

export const deleteImages = async (images: string[]): Promise<string[]> => {
  const res = await imageApi.post(`/api/v1/storage/images/delete`, images);

  return res.data;
};
