import React, { memo, useEffect, useState, useCallback } from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  useHandleCheckboxChange,
  useHandleTextFieldChange,
} from 'services/forms/hooks';
import { SalesOrder } from 'services/salesOrders';
import { ObjectType } from 'services/customFields';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { TextField } from 'ui/components/TextField/TextField';
import { SalesOrderAsyncAutocomplete } from 'ui/components/Autocomplete/SalesOrderAsyncAutocomplete';

import { DateOptions } from './types';
import { ReportFiltersProps } from '../../types';
import { CustomFieldsOptions } from '../CustomFieldsOptions';
import lodash from 'lodash';

const SalesOrderFilters: React.FC<ReportFiltersProps> = (props) => {
  const { parameters, setParameters, errors } = props;

  const [hasExchangeCurrency, setHasExchangeCurrency] =
    useState<boolean>(false);

  const handleSelectAll = useCallback(
    (name: string) =>
      (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (name === 'optional') {
          setParameters((old) => ({
            ...old,
            isInvoice: checked,
            reportTitle: checked ? 'Invoice' : 'Sales Order',
            showBarcode: checked,
            showCompanyAddress: checked,
            showCompanyName: checked,
            showContact: checked,
            showOrderNotes: checked,
            showPayments: checked,
            showTax: checked,
            selectAllOptional: checked,
            showHomeCurrency: checked,
            showLineItemNotes: checked,
          }));
        } else {
          setParameters((old) => ({
            ...old,
            columnOrderDateScheduled: checked,
            columnItemTotalPrice: checked,
            columnItemDiscount: checked,
            columnItemType: checked,
            columnItemUnitPrice: checked,
            columnItemQuantity: checked,
            columnCarrier: checked,
            columnFobPoint: checked,
            columnPaymentTerm: checked,
            columnSeller: checked,
            columnShipService: checked,
            columnShippingTerms: checked,
            columnPoNumber: checked,
            selectAllColumns: checked,
            columnTags: checked,
            columnRowNumber: checked,
          }));
        }
      },
    [setParameters]
  );

  const handleCheckboxChange = useHandleCheckboxChange(setParameters);

  useEffect(() => {
    const noHomeCurrency = lodash.isEmpty(parameters.showHomeCurrency);
    const noForeignCurrency = !hasExchangeCurrency;

    if (noHomeCurrency && noForeignCurrency) {
      setParameters((s) => ({ ...s, showHomeCurrency: true }));
    }
  }, [parameters.showHomeCurrency, hasExchangeCurrency]);

  const handleTextFieldChange = useHandleTextFieldChange(
    setParameters,
    parameters
  );

  const handleDateChange = useCallback(
    (_e: any, date: DateOptions | null) => {
      setParameters((old) => ({ ...old, dateOption: date ? date : null }));
    },
    [setParameters]
  );

  const handleInvoiceChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setParameters((old) => ({
        ...old,
        isInvoice: checked,
        reportTitle: checked ? 'Invoice' : 'Sales Order',
      }));
    },
    [setParameters]
  );

  const handleSalesOrderChange = useCallback(
    (value: SalesOrder | null) => {
      setParameters((old) => ({
        ...old,
        salesOrderId: value ? value.id : null,
        email: {
          to: value?.emails ?? null,
          cc: value?.customer?.cc ?? null,
          bcc: value?.customer?.bcc ?? null,
        },
      }));

      if (value && value.exchangeCurrencyId && value.homeCurrencyId) {
        if (value.exchangeCurrencyId !== value.homeCurrencyId) {
          setHasExchangeCurrency(true);
        } else {
          setHasExchangeCurrency(false);
        }
        setParameters((s) => ({ ...s, showHomeCurrency: true }));
      }
    },
    [setParameters]
  );

  const handleSetSelectedCustomFields = (customFields: number[]) => {
    setParameters((old) => ({
      ...old,
      customFields,
    }));
  };

  // TODO : RESTYLING : Remove box wrappers around inputs after FF removal

  return (
    <>
      <Accordion square defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          data-qa="sales-order-report-required"
        >
          <Typography>
            <b>Required</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%">
            <Box>
              <SalesOrderAsyncAutocomplete
                label="Sales Order Number"
                value={parameters.salesOrderId}
                onChange={handleSalesOrderChange}
                placeholder="Select Sales Order"
                required
                error={!!errors.salesOrderId}
                helperText={errors.salesOrderId}
                dataQa="sales-order-report-number-field"
                customQuickSearchColumns={['number']}
              />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Box px={2} pb={0} pt="16px">
        <FormControlLabel
          className="redesign"
          sx={{ marginBottom: '8px' }}
          control={
            <Checkbox
              className="redesign"
              checked={Boolean(parameters.selectAllOptional)}
              onChange={handleSelectAll('optional')}
              color="primary"
              data-qa="sales-order-report-select-all-optional"
            />
          }
          label="Select All Optional"
        />
      </Box>
      <Accordion square>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          data-qa="sales-order-report-select-all-optional-expand"
        >
          <Typography>
            <b>Optional</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%">
            <Box>
              <TextField
                className="redesign"
                variant="standard"
                label="Report Title"
                placeholder="Report Title"
                name="reportTitle"
                value={parameters.reportTitle || null}
                onChange={handleTextFieldChange}
                disabled={parameters.isInvoice}
                fullWidth
                dataQa="sales-order-report-title"
              />
            </Box>
            <Box mb="16px">
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.isInvoice)}
                    onChange={handleInvoiceChange}
                    color="primary"
                    data-qa="sales-order-report-is-invoice"
                  />
                }
                label="Is Invoice"
              />
            </Box>
            <Box>
              <Autocomplete
                label="Date Option"
                options={Object.values(DateOptions)}
                placeholder="Select Date Option"
                value={parameters.dateOption || null}
                onChange={handleDateChange}
                required
                fullWidth
                dataQa="sales-order-report-select-date-option"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showBarcode)}
                    onChange={handleCheckboxChange}
                    name="showBarcode"
                    color="primary"
                    data-qa="sales-order-report-show-barcode"
                  />
                }
                label="Show Barcode"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showCompanyAddress)}
                    onChange={handleCheckboxChange}
                    name="showCompanyAddress"
                    color="primary"
                    data-qa="sales-order-report-show-company-address"
                  />
                }
                label="Show Company Address"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showCompanyName)}
                    onChange={handleCheckboxChange}
                    name="showCompanyName"
                    color="primary"
                    data-qa="sales-order-report-show-company-name"
                  />
                }
                label="Show Company Name"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showContact)}
                    onChange={handleCheckboxChange}
                    name="showContact"
                    color="primary"
                    data-qa="sales-order-report-show-contact"
                  />
                }
                label="Show Contact"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showOrderNotes)}
                    onChange={handleCheckboxChange}
                    name="showOrderNotes"
                    color="primary"
                    data-qa="sales-order-report-show-order-notes"
                  />
                }
                label="Show Order Notes"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showPayments)}
                    onChange={handleCheckboxChange}
                    name="showPayments"
                    color="primary"
                    data-qa="sales-order-report-show-payments"
                  />
                }
                label="Show Payments"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showTax)}
                    onChange={handleCheckboxChange}
                    name="showTax"
                    color="primary"
                    data-qa="sales-order-report-show-tax"
                  />
                }
                label="Show Tax"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showHomeCurrency)}
                    onChange={handleCheckboxChange}
                    name="showHomeCurrency"
                    color="primary"
                    defaultChecked={true}
                    disabled={!hasExchangeCurrency}
                    data-qa="sales-order-report-show-home-currency"
                  />
                }
                label="Show Home Currency"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showLineItemNotes)}
                    onChange={handleCheckboxChange}
                    name="showLineItemNotes"
                    color="primary"
                    data-qa="sales-order-report-show-line-item-notes"
                  />
                }
                label="Show Line Item Notes"
              />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Box px={2} pb={0} pt="16px">
        <FormControlLabel
          className="redesign"
          sx={{ marginBottom: '8px' }}
          control={
            <Checkbox
              className="redesign"
              checked={Boolean(parameters.selectAllColumns)}
              onChange={handleSelectAll('columns')}
              color="primary"
              data-qa="sales-order-report-select-all-columns"
            />
          }
          label="Select All Columns"
        />
      </Box>
      <Accordion square>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          data-qa="sales-order-report-accordion-summary"
        >
          <Typography>
            <b>Columns</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%">
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnOrderDateScheduled)}
                    onChange={handleCheckboxChange}
                    name="columnOrderDateScheduled"
                    color="primary"
                    data-qa="sales-order-report-show-date-scheduled"
                  />
                }
                label="Order Date Scheduled"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnItemTotalPrice)}
                    onChange={handleCheckboxChange}
                    name="columnItemTotalPrice"
                    color="primary"
                    data-qa="sales-order-report-item-total-price"
                  />
                }
                label="Item Total Price"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnItemDiscount)}
                    onChange={handleCheckboxChange}
                    name="columnItemDiscount"
                    color="primary"
                    data-qa="sales-order-report-item-discount"
                  />
                }
                label="Item Discount"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnRowNumber)}
                    onChange={handleCheckboxChange}
                    name="columnRowNumber"
                    color="primary"
                    data-qa="sales-order-report-show-row-number"
                  />
                }
                label="Show Row Number"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnItemType)}
                    onChange={handleCheckboxChange}
                    name="columnItemType"
                    color="primary"
                    data-qa="sales-order-report-item-type"
                  />
                }
                label="Item Type"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnItemUnitPrice)}
                    onChange={handleCheckboxChange}
                    name="columnItemUnitPrice"
                    color="primary"
                    data-qa="sales-order-report-item-unit-price"
                  />
                }
                label="Item Unit Price"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnItemQuantity)}
                    onChange={handleCheckboxChange}
                    name="columnItemQuantity"
                    color="primary"
                    data-qa="sales-order-report-item-quantity-ordered"
                  />
                }
                label="Item Quantity Ordered"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnCarrier)}
                    onChange={handleCheckboxChange}
                    name="columnCarrier"
                    color="primary"
                    data-qa="sales-order-report-order-carrier"
                  />
                }
                label="Order Carrier"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnFobPoint)}
                    onChange={handleCheckboxChange}
                    name="columnFobPoint"
                    color="primary"
                    data-qa="sales-order-report-order-fob-point"
                  />
                }
                label="Order FOB Point"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnPaymentTerm)}
                    onChange={handleCheckboxChange}
                    name="columnPaymentTerm"
                    color="primary"
                    data-qa="sales-order-report-order-payment-term"
                  />
                }
                label="Order Payment Term"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnSeller)}
                    onChange={handleCheckboxChange}
                    name="columnSeller"
                    color="primary"
                    data-qa="sales-order-report-order-seller"
                  />
                }
                label="Order Seller"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnShipService)}
                    onChange={handleCheckboxChange}
                    name="columnShipService"
                    color="primary"
                    data-qa="sales-order-report-order-ship-service"
                  />
                }
                label="Order Ship Service"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnPoNumber)}
                    onChange={handleCheckboxChange}
                    name="columnPoNumber"
                    color="primary"
                    data-qa="sales-order-report-order-po-number"
                  />
                }
                label="PO Number"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnTags)}
                    onChange={handleCheckboxChange}
                    name="columnTags"
                    color="primary"
                    data-qa="sales-order-report-order-tags"
                  />
                }
                label="Tags"
              />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      <CustomFieldsOptions
        objectType={ObjectType.SalesOrder}
        selectedCustomFields={(parameters.customFields || []) as number[]}
        setSelectedCustomFields={handleSetSelectedCustomFields}
        dataQa="sales-order-report"
      />
    </>
  );
};

export default memo(SalesOrderFilters);
