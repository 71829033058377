import React, { memo, useCallback, useMemo } from 'react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';

import _ from 'lodash';
import { Timezone } from 'timezones.json';
import { TIMEZONES } from 'helpers/timezones';

import { AdvancedSearchFieldsCmpProps } from 'ui/components/Page/PageWithAdvancedSearch';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import { Location } from 'services/locations';

const UsersAdvancedSearch: React.FC<AdvancedSearchFieldsCmpProps> = (props) => {
  const { setFormValues, formValues } = props;

  const selectedTimezone = useMemo(() => {
    return TIMEZONES.find((t) => t.utc[0] === formValues.timezone);
  }, [formValues]);

  const handleDefaultLocationChange = useCallback(
    (v: Location | null) => {
      const id = _.get(v, 'id', null);
      setFormValues({
        ...formValues,
        defaultLocationId: id,
      });
    },
    [formValues, setFormValues]
  );

  const handleTimezoneChange = useCallback(
    (e: any, value: Timezone | null) => {
      setFormValues({ ...formValues, timezone: value ? value.utc[0] : null });
    },
    [formValues, setFormValues]
  );

  const handleShowDeletedChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setFormValues({ ...formValues, deleted: checked ? 'true' : null });
    },
    [formValues, setFormValues]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <LocationsAsyncAutocomplete
          onChange={handleDefaultLocationChange}
          label=" Default Location"
          value={formValues.locationId as number}
          companyWide={false}
          parentId={null}
          dataQa="default-location"
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          onChange={handleTimezoneChange}
          label="Timezone"
          placeholder="Enter timezone"
          options={TIMEZONES}
          getOptionLabel={(option: Timezone) => option.text || ''}
          value={selectedTimezone}
          dataQa="timezone"
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={!!formValues.deleted}
              onChange={handleShowDeletedChange}
              color="primary"
              data-qa="show-deleted"
            />
          }
          label="Show Deleted"
        />
      </Grid>
    </Grid>
  );
};

export default memo(UsersAdvancedSearch);
