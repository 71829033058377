import React from 'react';
import { Typography, Box } from '@mui/material';
import { Icon } from 'ui/components/Icon';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { FBOInventoryHeroStatusItemProps } from './types';
import { useFBOInventoryHeroStatusItemStyle } from './styped';

const FBOInventoryHeroStatusItem: React.FC<FBOInventoryHeroStatusItemProps> = (
  props
) => {
  const { onClick, icon, title, value, buttonIcon, disabled, dataQa, warn } =
    props;
  const classes = useFBOInventoryHeroStatusItemStyle();
  return (
    <Box
      className={classes.itemContainer}
      data-qa={`inventory-hero-item-${dataQa}`}
    >
      <Icon
        name={icon}
        data-qa={`inventory-hero-icon-${dataQa}`}
        className={warn ? classes.warningIcon : ''}
      />
      <FBOButton
        variant="tertiary"
        color="neutral"
        size="small"
        endIcon={<Icon name={buttonIcon} />}
        onClick={onClick}
        disabled={!!disabled}
        data-qa={`inventory-hero-button-${dataQa}`}
        className={`${onClick ? classes.clickable : classes.default}`}
      >
        {title}
      </FBOButton>
      <Typography
        className={classes.bold}
        data-qa={`inventory-hero-value-${dataQa}`}
      >
        {value}
      </Typography>
    </Box>
  );
};

export default React.memo(FBOInventoryHeroStatusItem);
