import _ from 'lodash';

import { transformCarrier } from 'services/carriers';
import { transformItem } from 'services/items';
import { transformUom } from 'services/uoms';
import {
  transformSalesOrder,
  transformSalesOrderItem,
} from 'services/salesOrders';
import { transformTrackingGroupDto } from 'services/inventory';
import { transformPurchaseOrderItem } from 'services/purchaseOrders';
import {
  ObjectType,
  transformCustomField,
  transformToRequestCustomField,
} from 'services/customFields';
import {
  transformDocument,
  transformToRequestDocument,
} from 'services/documents';
import { transformDateToMomentDate } from 'helpers';

import { Ship, ShipCarton, ShipItem } from './types';

export const transformShip = (res: any): Ship => {
  // order ship items by line number
  const shipBundleItems = res.shipBundleItems
    ? res.shipBundleItems.map((sb: any) => sb.shipItems).flat()
    : [];
  const shipItemList: ShipItem[] = _.orderBy(
    [...(res.shipItems || []), ...shipBundleItems].map(transformShipItem),
    (i: ShipItem) => {
      if (i.salesOrderItem) {
        return i.salesOrderItem.lineNumber || 0;
      }

      if (i.purchaseOrderItem) {
        return i.purchaseOrderItem.lineNumber || 0;
      }

      return 0;
    },
    ['asc']
  );

  const shipCartonList: ShipCarton[] = res.shipCartons
    ? res.shipCartons.map((carton: ShipCarton) => transformShipCarton(carton))
    : [];

  const salesOrders = res.salesOrders || [];
  const picks = res.picks || [];
  const customFields = res.customFields || [];
  const documents = res.documents || [];

  return {
    id: res.id,
    carrier: transformCarrier(res.carrier || {}),
    carrierId: res.carrierId,
    carrierServiceId: res.carrierServiceId,
    customFields: customFields.map(transformCustomField),
    notes: res.notes,
    number: res.number,
    orderPriorityId: res.orderPriorityId,
    orderType: res.orderType,
    packedByUserId: res.packedByUserId,
    parentLocationId: res.parentLocationId,
    picks,
    purchaseOrderId: res.purchaseOrderId,
    salesOrder: res.salesOrder ? transformSalesOrder(res.salesOrder) : null,
    salesOrderId: res.salesOrderId,
    salesOrders,
    shipCartonList,
    dateShip: transformDateToMomentDate(res.dateShip),
    dateCreated: transformDateToMomentDate(res.dateCreated),
    dateLastModified: transformDateToMomentDate(res.dateLastModified),
    documents: documents.map(transformDocument),
    shipItemList,
    shippedByUserId: res.shippedByUserId,
    shipToAttention: res.shipToAttention,
    shipToCity: res.shipToCity,
    shipToCompanyName: res.shipToCompanyName,
    shipToCountry: res.shipToCountry,
    shipToName: res.shipToName,
    shipToPostalCode: res.shipToPostalCode,
    shipToResidential: res.shipToResidential,
    shipToState: res.shipToState,
    shipToStreet: res.shipToStreet,
    shipToStreet2: res.shipToStreet2,
    shipToVerified: res.shipToVerified,
    status: res.status,
    version: res.version,
    returnLabel: res.returnLabel,
    returnLabelTracking: res.returnLabelTracking,
    returnLabelCost: res.returnLabelCost,
  };
};

export const transformShipCarton = (res: any): ShipCarton => {
  return {
    id: res.id,
    cost: res.cost,
    insuredValue: res.insuredValue,
    declaredValue: res.declaredValue,
    dimensionUnit: res.dimensionUnit,
    height: res.height,
    length: res.length,
    number: res.number,
    trackingNumber: res.trackingNumber,
    trackingUrl: res.trackingUrl,
    shippingLabelUrl: res.shippingLabelUrl,
    version: res.version,
    weight: res.weight,
    width: res.width,
    weightUnit: res.weightUnit,
    returnLabelUrl: res.returnLabelUrl,
    returnTracking: res.returnTracking,
    returnTrackingUrl: res.returnTrackingUrl,
    returnLabelCost: res.returnLabelCost,
  };
};

export const transformShipItem = (res: any): ShipItem => {
  const resTrackingGroups = res.trackingGroupObjects || [];

  return {
    id: res.id,
    deleted: null,
    item: transformItem(res.item),
    itemId: res.itemId,
    purchaseOrderItem: res.purchaseOrderItem
      ? transformPurchaseOrderItem(res.purchaseOrderItem)
      : null,
    purchaseOrderItemId: res.purchaseOrderItemId,
    quantity: res.quantity,
    salesOrderItem: res.salesOrderItem
      ? transformSalesOrderItem(res.salesOrderItem)
      : null,
    salesOrderItemId: res.salesOrderItemId,
    shipCartonId: res.shipCartonId,
    trackingGroups: resTrackingGroups.map(transformTrackingGroupDto),
    uom: res.uom ? transformUom(res.uom) : null,
    uomId: res.uomId,
    version: res.version,
  };
};

export const transformToUpdateShip = (ship: Ship): any => {
  return {
    id: ship.id,
    version: ship.version,
    parentLocationId: ship.parentLocationId,
    number: ship.number,
    shipCartons: ship.shipCartonList,
    notes: ship.notes,
    orderPriorityId: ship.orderPriorityId,
    customFields: ship.customFields.map((f) =>
      transformToRequestCustomField(f, ObjectType.Ship, true)
    ),
    carrierId: ship.carrierId,
    carrierServiceId: ship.carrierServiceId,
    documents: ship.documents.map(transformToRequestDocument),
    shipToName: ship.shipToName,
    shipToStreet: ship.shipToStreet,
    shipToStreet2: ship.shipToStreet2,
    shipToCity: ship.shipToCity,
    shipToState: ship.shipToState,
    shipToCountry: ship.shipToCountry,
    shipToPostalCode: ship.shipToPostalCode,
    shipToCompanyName: ship.shipToCompanyName,
    shipToResidential: ship.shipToResidential,
    shipToAttention: ship.shipToAttention,
    shipToVerified: ship.shipToVerified,
    returnLabel: ship.returnLabel,
    returnLabelTracking: ship.returnLabelTracking,
    returnLabelCost: ship.returnLabelCost,
  };
};

export const transformToCreateShipItem = (shipItem: ShipItem): ShipItem =>
  _.omit(shipItem, ['uom', 'item']) as any;
