import moment from 'moment';
import _ from 'lodash';

import { GlobalUser } from './types';

export const isUserExpired = (user: GlobalUser) => {
  const expirationDate = _.get(user, 'company.expirationDate', null);
  const todayDate = moment();

  return expirationDate && !todayDate.isBefore(expirationDate);
};
