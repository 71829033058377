import { IconNames } from 'ui/theme';
import { MenuItem } from 'ui/components/Menu';
import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

import { CustomRowActions } from './types';

export const ITEM_COLUMS: Column[] = [
  {
    title: 'Name',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Type',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Required',
    width: 60,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: '',
    width: 50,
    type: ColumnTypes.renderByCustomRow,
  },
];

export const ActionBarOptions = (
  onActionClick: (actionType: CustomRowActions) => void
): MenuItem[] => [
  {
    label: 'Edit',
    icon: IconNames.Mode,
    onOptionClick: () => onActionClick(CustomRowActions.Edit),
    dataQa: 'custom-field-edit',
  },
  {
    label: 'Duplicate',
    icon: IconNames.Duplicate,
    separateAfter: true,
    onOptionClick: () => onActionClick(CustomRowActions.Duplicate),
    dataQa: 'custom-field-duplicate',
  },
  {
    label: 'Delete',
    icon: IconNames.Delete,
    onOptionClick: () => onActionClick(CustomRowActions.Delete),
    dataQa: 'custom-field-delete',
  },
];
