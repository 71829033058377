import { ShipCarton } from 'services/shipping';
import { MenuItem } from 'ui/components/Menu';
import { ColumnTypes } from 'ui/components/Table/ItemsTable';
import { Column } from 'ui/components/Table/ItemsTable/types';

export const SHIPPING_ITEMS_COLUMNS: Column[] = [
  {
    title: 'Package',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Cost',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Insured Amount',

    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Dimensions',

    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Weight',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Carrier Tracking',
    type: ColumnTypes.renderByCustomRow,
    width: 280,
  },
  {
    title: '',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: '',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: '',
    type: ColumnTypes.renderByCustomRow,
  },
];

export const RETURNLABELS_ITEMS_COLUMNS: Column[] = [
  {
    title: 'Package',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Cost',
    width: 134,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Carrier Tracking',
    type: ColumnTypes.renderByCustomRow,
    width: 280,
  },
  {
    title: '',
    type: ColumnTypes.renderByCustomRow,
  },
];

export const newCarton = {
  id: null,
  cost: null,
  insuredValue: null,
  declaredValue: null,
  dimensionUnit: null,
  height: null,
  number: null,
  trackingNumber: null,
  trackingUrl: null,
  version: null,
  weight: null,
  weightUnit: null,
  width: null,
  length: null,
  returnLabelUrl: null,
  returnTracking: null,
  returnTrackingUrl: null,
  returnLabelCost: null,
};

export const moveShipItemsOptions = (
  shipCartons: ShipCarton[],
  updateShipCarton: (cartonId: number) => void
): MenuItem[] => {
  return shipCartons.map((i) => ({
    label: `Package ${i.number}`,
    onOptionClick: () => updateShipCarton(i.id!),
  }));
};
