export interface Account {
  id: number | null;
  version: number | null;
  number: string | null;
  name: string | null;
  accountType: AccountType | string | null;
  accountMappingId: string | null;
  integrationsTypeFilter: string | null;
}

export enum AccountType {
  AccountsPayable = 'Accounts Payable',
  AccountsReceivable = 'Accounts Receivable',
  UndepositedFunds = 'Undeposited Funds',
  NonInventory = 'Non-Inventory',
  Service = 'Service',
  SalesRetail = 'Sales Retail',
  DiscountExpense = 'Discount Expense',
  DiscountIncome = 'Discount Income',
  MiscellaneousExpense = 'Miscellaneous Expense',
  ShippingIncome = 'Shipping Income',
  ShippingExpense = 'Shipping Expense',
  ShippingAccrual = 'Shipping Accrual',
  CostOfGoodsSold = 'Cost of Goods Sold',
  ScrapInventory = 'Scrap Inventory',
  InventoryAdjustment = 'Inventory Adjustment',
  CostVariance = 'Cost Variance',
  InventoryAsset = 'Inventory Asset',
  Holding = 'Holding',
  FlatRateTax = 'Flat Rate Tax',
  SalesTaxPayable = 'Sales Tax Payable',
  OtherExpense = 'Other Expense',
  // From here down - leftovers that are not on Account Mapping modal but are in API docs
  // https://documenter.getpostman.com/view/7672991/SW12zHdQ?version=latest#3cce22ec-ae77-46ea-88b2-5f4816becb9b
  Bank = 'Bank',
  CreditCard = 'CreditCard',
  Equity = 'Equity',
  Asset = 'Asset',
  CurrentAsset = 'Current Asset',
  FixedAsset = 'Fixed Asset',
  OtherCurrentLiability = 'Other Current Liability',
  OtherCurrentAsset = 'Other Current Asset',
  LongTermLiability = 'Long Term Liability',
  Income = 'Income',
  Expense = 'Expense',
}

//from https://developer.intuit.com/app/developer/qbo/docs/api/accounting/most-commonly-used/account#the-account-object
export enum QBOAccountType {
  AccountsPayable = 'Accounts Payable',
  AccountsReceivable = 'Accounts Receivable',
  Bank = 'Bank',
  CostOfGoodsSold = 'Cost of Goods Sold',
  CreditCard = 'Credit Card',
  Equity = 'Equity',
  Expense = 'Expense',
  FixedAsset = 'Fixed Asset',
  Income = 'Income',
  LongTermLiability = 'Long Term Liability',
  OtherAsset = 'Other Asset',
  OtherCurrentAsset = 'Other Current Asset',
  OtherCurrentLiability = 'Other Current Liability',
  OtherExpense = 'Other Expense',
  OtherIncome = 'Other Income',
}

export enum XeroAccountType {
  Bank = 'Bank',
  Current = 'Current Asset',
  CurrLiab = 'Current Liability',
  Depreciatn = 'Depreciation',
  DirectCosts = 'Direct Costs',
  Equity = 'Equity',
  Expense = 'Expense',
  Fixed = 'Fixed Asset',
  Inventory = 'Inventory Asset',
  Liability = 'Liability',
  NonCurrent = 'Non-current Asset',
  OtherIncome = 'Other Income',
  OverHeads = 'Overhead',
  PayGLiability = 'PAYG Liability',
  PrePayment = 'Prepayment',
  Revenue = 'Revenue',
  Sales = 'Sale',
  SuperAnnuationExpense = 'Superannuation Expense',
  SuperAnnuationLiability = 'Superannuation Liability',
  TermiLab = 'Non-current Liability',
  WagesExpense = 'Wages Expense',
}

export interface WaitingToExport {
  bills: number;
  inventoryAdjustments: number;
  invoices: number;
  itemReceipts: number;
  payments: number;
  creditMemos: number;
  journalEntries: number;
  vendorCredits: number;
}

export interface ExportHistory {
  id: number;
  checkVersion: boolean;
  createdBy: null;
  createdById: number;
  dateCreated: Date | null;
  dateLastModified: Date | null;
  jwt: string;
  lastModifiedBy: null;
  lastModifiedById: number;
  ledger: ExportLedger | null;
  ledgerId: number;
  message: string;
  parentAccountingHistory: null;
  parentAccountingHistoryId: number;
  type: string;
  version: number;
}

export interface ExportLedger {
  id: number;
  checkVersion: boolean;
  createdBy: null;
  createdById: number;
  customer: null;
  customerId: null;
  dateCreated: Date;
  dateLastModified: Date;
  datePosted: Date;
  inventoryEvent: null;
  inventoryEventId: number;
  jwt: string;
  lastModifiedBy: null;
  lastModifiedById: number;
  ledgerItems: null;
  note: null;
  payment: null;
  paymentId: null;
  postDate: Date;
  purchaseOrder: null;
  purchaseOrderId: number;
  salesOrder: null;
  salesOrderId: number;
  type: string;
  user: null;
  userId: number;
  version: number;
}
