import moment from 'moment';

import { GeneralWidgetValues } from './types';
import { DateRange } from 'materialui-daterange-picker';

export const initialGeneralWidgetValues: GeneralWidgetValues = {
  previousRevenueTotal: 0,
  previousExpensesTotal: 0,
  previousItemsTotal: 0,
  previousSoTotal: 0,
  latestExpensesTotal: 0,
  latestSoTotal: 0,
  latestRevenueTotal: 0,
  latestItemsTotal: 0,
  lowStock: 0,
};

export const initialFromDateRange: DateRange = {
  startDate: moment().startOf('month').toDate(),
  endDate: moment().startOf('month').add('14', 'days').toDate(),
};

export const initialToDateRange: DateRange = {
  startDate: moment().subtract(1, 'month').startOf('month').toDate(),
  endDate: moment()
    .subtract(1, 'month')
    .startOf('month')
    .add('14', 'days')
    .toDate(),
};
