import React, { memo, useCallback, useState } from 'react';
import AutoComplete from '@mui/material/Autocomplete';
import { CircularProgress, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import _ from 'lodash';

import { fetchSaleItem, Item } from 'services/items';
import { Pagination } from 'services/search';
import { PaginationDirection } from 'services/url';
import { initialPagination } from 'services/accounting';
import { TextField } from 'ui/components/TextField/TextField';
import { SaleItem } from 'services/items/saleItems';

import { SoAsyncItemsAutocompleteProps } from './types';
import { useAsyncAutocomplete } from '../Autocomplete/hooks';
import {
  resolvedFetchSoItemsFunction,
  soItemsAutocompleteFilterOptions,
  soItemsAutocompleteOptionLabel,
} from './helpers';
import { useSalesOrderItemsStyle } from './styled';
import { SaleItemOption } from './components';
import NewItemModal from './NewItemModal';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const initialSoItemPagination: Pagination = {
  ...initialPagination,
  pageSize: 50,
  sort: { sortBy: 'name', direction: PaginationDirection.Ascending },
};

export const SalesOrderItemsAsyncAutocomplete: React.FC<
  SoAsyncItemsAutocompleteProps
> = (props) => {
  const {
    salesOrder,
    value,
    label,
    required,
    onChange,
    additionalInputProps,
    error,
    dataQa,
    onEnterClick,
    sx = {},
  } = props;
  const {
    data: saleItems,
    isLoading,
    innerValue,
    open,
    onCloseAutocomplete,
    onNextPage,
    onOpenAutocomplete,
    setSearchValue,
    setInnerValue,
    setData,
  } = useAsyncAutocomplete(
    value,
    resolvedFetchSoItemsFunction(),
    fetchSaleItem,
    initialSoItemPagination
  );

  const classes = useSalesOrderItemsStyle();

  const [showModal, setShowModal] = useState(false);
  const [newItemName, setNewItemName] = useState<string | null>(null);
  const [highlightValue, setHighlightValue] = useState<SaleItem | null>(null);

  const handleAutocompleteInputChange = (
    e: any,
    val: string,
    reason: string
  ) => {
    if (reason === 'input') {
      if (!val) {
        onOpenAutocomplete();
        setInnerValue(null);
      }
      setSearchValue(val || null);
      onOpenAutocomplete();
      return;
    }

    if (reason === 'clear') {
      onChange(null);
    }

    setSearchValue(null);
  };

  const handleChange = (e: any, saleItem: SaleItem | null) => {
    if (saleItem && (saleItem.id === null || saleItem.id < 0)) {
      setNewItemName(saleItem.name);
      setShowModal(true);
      return;
    }

    setInnerValue(saleItem);
    onChange(saleItem);
  };

  const handleSaveModal = useCallback(
    async (item: Item) => {
      const saleItem = item.saleItemList[0];

      if (!saleItem.item) {
        saleItem.item = item;
      }

      setShowModal(false);
      setData([saleItem]);
      setInnerValue(saleItem);
      onChange(saleItem);
    },
    [onChange, setData, setInnerValue]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (e.key === 'Enter' && !open && value && onEnterClick) {
        onEnterClick();
      }
      if (e.key === 'Tab' && open && highlightValue) {
        if (highlightValue.id === -1) {
          setShowModal(true);
          setNewItemName(highlightValue.name);
          setHighlightValue(null);
          return;
        }

        onChange(highlightValue);
      }
    },
    [open, value, highlightValue, onEnterClick, onChange]
  );

  const handleHighlightChange = (
    ev: any,
    option: SaleItem | null,
    reason: string
  ) => {
    console.log('Highlight changed:', { option, reason });
    if (reason === 'keyboard' && option) {
      setHighlightValue(option);
    }
  };

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  return (
    <>
      <AutoComplete
        sx={sx}
        options={saleItems}
        open={open}
        onOpen={onOpenAutocomplete}
        onClose={onCloseAutocomplete}
        onChange={handleChange}
        className={`redesign ${classes.autocomplete}`}
        onHighlightChange={handleHighlightChange}
        filterOptions={soItemsAutocompleteFilterOptions}
        onInputChange={handleAutocompleteInputChange}
        getOptionLabel={soItemsAutocompleteOptionLabel}
        isOptionEqualToValue={(option, val) => option.id === val.id}
        value={innerValue}
        autoSelect={false}
        clearOnEscape
        noOptionsText={isLoading ? 'Loading...' : 'No options'}
        classes={{
          root: classes.searchInputOuter,
          option: classes.searchOption,
        }}
        ListboxProps={{
          onScroll: (event: React.SyntheticEvent) => {
            const node = event.currentTarget;
            if (
              Math.ceil(node.scrollTop + node.clientHeight) ===
              node.scrollHeight
            ) {
              onNextPage();
            }
          },
        }}
        renderInput={(params) => (
          <TextField
            {..._.omit(params, 'variant', 'size', 'InputLabelProps')}
            placeholder="Enter sale item name, description, or SKU"
            label={label}
            error={error}
            required={required}
            InputProps={{
              ...params.InputProps,
              ...additionalInputProps,
              endAdornment: (
                <>
                  {isLoading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </>
              ),
              classes: {
                root: classes.searchInputInner,
                notchedOutline: classes.noBorder,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            inputProps={{
              'data-qa': dataQa,
              onKeyDown: handleKeyDown,
              ...params.inputProps,
            }}
          />
        )}
        renderOption={(SoAsyncItemsAutocompleteProps, option: SaleItem) => (
          <li {...SoAsyncItemsAutocompleteProps} key={option.id}>
            <SaleItemOption salesOrder={salesOrder} option={option} />
          </li>
        )}
        clearIcon={
          dataQa && (
            <CloseIcon
              fontSize="small"
              data-qa={`${dataQa}-autocomplete-clear-icon`}
            />
          )
        }
        popupIcon={
          dataQa && (
            <ArrowDropDownIcon
              fontSize="small"
              data-qa={`${dataQa}-autocomplete-dropdown-icon`}
            />
          )
        }
      />
      <NewItemModal
        show={showModal}
        newItemName={newItemName}
        onSave={handleSaveModal}
        onClose={handleCloseModal}
      />
    </>
  );
};

export default memo(SalesOrderItemsAsyncAutocomplete);
