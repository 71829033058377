import { Customer } from 'services/customers';
import { PermissionType } from 'services/permissions';

export const editCustomerPermissions = (
  customer: Customer
): PermissionType[] => {
  if (customer.deleted) {
    return [PermissionType.Undelete];
  }
  return customer.id && customer.id > 0
    ? [PermissionType.CustomersEdit]
    : [PermissionType.CustomersCreate];
};
