import React, { memo, useCallback, useEffect, useState } from 'react';
import AutoComplete from '@mui/material/Autocomplete';
import { CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import _ from 'lodash';

import { TextField } from 'ui/components/TextField/TextField';
import { fetchItem, Item } from 'services/items';
import { Pagination } from 'services/search';
import { initialPagination } from 'ui/components/Table/ItemsTable';
import { PaginationDirection } from 'services/url';

import { ItemsAutocompleteProps } from './types';
import NewItemModal from './NewItemModal';
import {
  itemsAutocompleteFilterOptions,
  itemsAutocompleteGetOptionLabel,
  resolvedFetchItemsFunction,
} from './helpers';
import { useAsyncAutocomplete } from '../Autocomplete/hooks';
import ItemOption from './ItemOption';
import { getItemInventory } from 'services/inventory';

const initialItemPagination: Pagination = {
  ...initialPagination,
  pageSize: 50,
  sort: { sortBy: 'name', direction: PaginationDirection.Ascending },
};

const QuickTransferItemsAutocomplete: React.FC<ItemsAutocompleteProps> = (
  props
) => {
  const {
    label,
    value,
    placeholder,
    required,
    onChange,
    disableAdd = false,
    additionalInputProps,
    itemTypes = [],
    dataQa,
    error,
    expands,
    customQuickSearchColumns,
    onlySaleItems,
    disabled = false,
    locationId,
  } = props;

  const {
    data: items,
    isLoading,
    innerValue,
    open,
    onCloseAutocomplete,
    onNextPage,
    onOpenAutocomplete,
    setSearchValue,
    setInnerValue,
    setData,
  } = useAsyncAutocomplete(
    value,
    resolvedFetchItemsFunction(
      itemTypes,
      expands,
      customQuickSearchColumns,
      onlySaleItems
    ),
    fetchItem,
    initialItemPagination
  );

  const [newItemName, setNewItemName] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [filteredItems, setFilteredItems] = useState<Item[]>([]);
  useEffect(() => {
    if (locationId) {
      items.forEach(async (item) => {
        if (item.itemType === 'Inventory' && item.id) {
          const inventory = await getItemInventory(item.id);
          const locationSummary = inventory.inventoryRowList.find(
            (summary) => summary.id === locationId
          );
          if (
            (locationSummary?.totalQty || 0) -
              (locationSummary?.committedQty || 0) >
            0
          ) {
            setFilteredItems((prev) => {
              const exists = prev.some(
                (filteredItem) => filteredItem.id === item.id
              );
              if (!exists) {
                return [...prev, item];
              }
              return prev;
            });
          }
        }
      });
    } else {
      setFilteredItems([]);
      setNewItemName(null);
    }
  }, [items, locationId]);

  const handleAutocompleteInputChange = (
    e: any,
    val: string,
    reason: string
  ) => {
    // reason why this event is triggered
    // it can be 'input', 'clear' and 'reset'
    if (reason === 'input') {
      if (!val) {
        onOpenAutocomplete();
        setInnerValue(null);
      }
      setSearchValue(val || null);
      onOpenAutocomplete();
      return;
    }

    if (reason === 'clear') {
      onChange(null);
    }

    setSearchValue(null);
  };

  const handleChange = (e: any, item: Item | null) => {
    if (item && (item.id === null || item.id < 0)) {
      setNewItemName(item.name);
      setShowModal(true);
      return;
    }

    setInnerValue(item);
    onChange(item);
  };

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleSaveModal = useCallback(
    async (item: Item) => {
      setShowModal(false);
      setData([item]);
      setInnerValue(item);
      onChange(item);
    },
    [onChange, setData, setInnerValue]
  );

  return (
    <>
      <AutoComplete
        className={'redesign'}
        options={filteredItems}
        open={open}
        onOpen={onOpenAutocomplete}
        onClose={onCloseAutocomplete}
        disabled={disabled}
        onChange={handleChange}
        filterOptions={itemsAutocompleteFilterOptions(disableAdd)}
        onInputChange={handleAutocompleteInputChange}
        getOptionLabel={itemsAutocompleteGetOptionLabel}
        isOptionEqualToValue={(option, val) => option.id === val.id}
        renderOption={(ItemsAutocompleteProps, option: Item) => (
          <li {...ItemsAutocompleteProps} key={option.id}>
            <ItemOption option={option} locationId={locationId} />
          </li>
        )}
        value={innerValue}
        noOptionsText={isLoading ? 'Loading...' : 'No options'}
        ListboxProps={{
          onScroll: (event: React.SyntheticEvent) => {
            const node = event.currentTarget;
            if (
              Math.ceil(node.scrollTop + node.clientHeight) ===
              node.scrollHeight
            ) {
              onNextPage();
            }
          },
        }}
        renderInput={(params) => {
          // params.InputProps.endAdornment is component with 2 children
          // first child is clear button, so we want to set it to null
          const endAdornment = params.InputProps.endAdornment as any;

          return (
            <TextField
              // we are omitting props that would overwrite our styling in TextField
              {..._.omit(params, 'variant', 'size', 'InputLabelProps')}
              label={label}
              placeholder={placeholder}
              error={error}
              required={required}
              InputProps={{
                ...params.InputProps,
                ...additionalInputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {endAdornment}
                  </React.Fragment>
                ),
              }}
              inputProps={{
                'data-qa': dataQa,
                ...params.inputProps,
              }}
            />
          );
        }}
        clearIcon={
          <CloseIcon
            fontSize="small"
            data-qa={`${dataQa}-autocomplete-clear-icon`}
          />
        }
        popupIcon={
          <ArrowDropDownIcon
            fontSize="small"
            data-qa={`${dataQa}-autocomplete-dropdown-icon`}
          />
        }
      />
      <NewItemModal
        show={showModal}
        newItemName={newItemName}
        itemTypes={itemTypes}
        onSave={handleSaveModal}
        onClose={handleCloseModal}
      />
    </>
  );
};

export default memo(QuickTransferItemsAutocomplete);
