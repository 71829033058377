import React, { memo } from 'react';
import ReactQuill from 'react-quill';
import Quill from 'quill';
import 'react-quill/dist/quill.snow.css';

import { useQuillTextEditor } from './styled';
import { QuillTextEditorProps } from './types';
import { Box } from '@mui/material';

const Link = Quill.import('formats/link');

Link.sanitize = (url: string) => {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `http://${url}`;
  }
  return url;
};

const QuillTextEditor: React.FC<QuillTextEditorProps> = (props) => {
  const { placeholder, onChange, value, error } = props;

  const classes = useQuillTextEditor(props);

  return (
    <Box
      className={error ? classes.quill_error : classes.quill_style}
      data-qa="quill-text-editor"
    >
      <ReactQuill
        className={classes.quill_style}
        theme="snow"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </Box>
  );
};

export default memo(QuillTextEditor);
