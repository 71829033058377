import _ from 'lodash';
import { statusOptions } from './consts';
import { StatusOption } from './types';

export const updateStatusOptions = (selectedValues: StatusOption[]) => {
  const selectedMap = _.keyBy(selectedValues, 'property');
  return statusOptions.reduce((acc: { [key: string]: boolean }, curr) => {
    acc[curr.property] = !!selectedMap[curr.property];
    return acc;
  }, {});
};
