import React, {
  useCallback,
  memo,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';
import { Box, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import {
  COMPANY_WIDE_ID,
  fetchLocationsAPI,
  Location,
} from 'services/locations';
import { Tag } from 'services/tags';

import {
  MappingDescriptor,
  LocationPickerDescriptor,
  RowDescriptor,
  RowType,
  TextDescriptor,
  TagDescriptor,
} from '../../types';
import { ModalRowProps } from './types';
import { useModalRowStyle } from './styled';
import _ from 'lodash';
import { MultiSelect } from 'ui/components/Autocomplete/MultiSelect';
import { TagsAsyncAutocomplete } from 'ui/components/Autocomplete/TagsAutocomplete';
import { getIdsAsArray } from 'services/commerce/transformations';
import { GlobalSettingsFields } from 'services/commerce/settings';
import { locationOrderPagination } from 'ui/modules/setup/pages/LocationPage/components/LocationOrderModal/consts';
import { isTextFieldRequired } from '../../../SettingsCard/consts';

type DescriptorType = TextDescriptor;
type TextRowType = DescriptorType & ModalRowProps;

const TextRow: React.FC<TextRowType> = (props) => {
  const classes = useModalRowStyle(props);
  const { title, dataQa } = props;

  return (
    <Box className={classes.textRow}>
      <Box className={classes.textContent}>
        <Box className={classes.title}>
          <Typography data-qa={dataQa} fontWeight="bold">
            {title}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const MappingRow: React.FC<MappingDescriptor & ModalRowProps> = (props) => {
  const classes = useModalRowStyle(props);
  const {
    field,
    title,
    cardData,
    options,
    setCardData,
    handleAutocompleteChange,
    errors,
  } = props;

  const autocompleteValue = useMemo(() => {
    if (!field) {
      return '';
    } else if (
      field ===
        GlobalSettingsFields.inventoryExport.inventoryWarehousesEnabled ||
      field === GlobalSettingsFields.inventoryExport.quantityType
    ) {
      return _.get(cardData?.inventoryExport, field);
    }
    return _.get(cardData?.productExport, field);
  }, [cardData, field]);

  const error = useMemo(() => {
    if (!field) {
      return undefined;
    } else if (
      field ===
        GlobalSettingsFields.inventoryExport.inventoryWarehousesEnabled ||
      field === GlobalSettingsFields.inventoryExport.quantityType
    ) {
      return errors[`inventoryExport.${field}`];
    }
    return errors[`productExport.${field}`];
  }, [errors, field]);

  const onChange = useCallback(
    (e: any, value: any) => {
      if (!field) {
        return;
      }
      if (handleAutocompleteChange) {
        handleAutocompleteChange(e, value, field, setCardData);
        return;
      }
      setCardData(field, value);
    },
    [field, setCardData, handleAutocompleteChange]
  );

  return (
    <Box className={classes.textRow}>
      <Box className={classes.textContent}>
        <Box flex={1}>
          <Typography className={classes.accountingTitle}>
            {title} {isTextFieldRequired(title) && ' *'}
          </Typography>
        </Box>
        <Box px={4}>
          <NavigateNextIcon />
        </Box>
        <Box className={classes.content}>
          <Autocomplete
            onChange={onChange}
            required
            options={options}
            value={autocompleteValue}
            placeholder="Not mapped"
            data-qa={`mapping-${title}`}
            error={!!error}
            helperText={error}
          />
        </Box>
      </Box>
    </Box>
  );
};

const LocationPickerRow: React.FC<LocationPickerDescriptor & ModalRowProps> = (
  props
) => {
  const classes = useModalRowStyle(props);
  const { field, title, setCardData, dataQa, cardData, errors } = props;
  const [locations, setLocations] = useState<Location[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<Location[]>([]);

  const locationPickerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    (async () => {
      try {
        const resLocations = await fetchLocationsAPI({
          pagination: locationOrderPagination,
        });
        const filteredData = resLocations.data.filter(
          (l) => l.parentLocationId === null && l.id !== COMPANY_WIDE_ID
        );
        setLocations(filteredData);
      } catch {
        // do nothing
      }
    })();
  }, []);

  useEffect(() => {
    const previouslySelectedLocationIds =
      cardData?.inventoryExport?.inventoryWarehousesEnabled;
    const previouslySelectedLocations = locations?.filter((location) =>
      previouslySelectedLocationIds?.includes(location.id)
    );
    setSelectedLocations(previouslySelectedLocations);
  }, [locations]);

  const handleChange = (value: Location[]) => {
    if (!field) {
      return;
    }
    setSelectedLocations(value);
    setCardData(field, getIdsAsArray(value));
  };

  const getOptionLabel = (location: Location) => {
    return location.name ?? '';
  };

  useEffect(() => {
    if (
      Object.keys(errors).length === 1 &&
      !!errors['inventoryExport.inventoryWarehousesEnabled']
    ) {
      locationPickerRef?.current?.focus();
    }
  }, [errors]);

  return (
    <Box className={classes.textRow}>
      <Box className={classes.textContent}>
        <Box className={classes.shortTitle}>
          <Typography>{title}*</Typography>
        </Box>
        <Box className={classes.contentExtended}>
          <MultiSelect
            options={locations}
            getOptionLabel={getOptionLabel}
            onChange={handleChange}
            values={selectedLocations || []}
            data-qa={dataQa}
            error={!!errors['inventoryExport.inventoryWarehousesEnabled']}
            helperText={errors['inventoryExport.inventoryWarehousesEnabled']}
            inputRef={locationPickerRef}
          />
        </Box>
      </Box>
    </Box>
  );
};

const TagRow: React.FC<TagDescriptor & ModalRowProps> = (props) => {
  const classes = useModalRowStyle(props);
  const { setCardData, field, dataQa, cardData, errors } = props;
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);

  const handleChange = (value: Tag[]) => {
    if (!field) {
      return;
    }
    setSelectedTags(value);
    setCardData(field, getIdsAsArray(value));
  };

  const tagIds = useMemo(() => {
    if (
      cardData?.productExport?.limitTags &&
      cardData?.productExport?.limitTags.length > 0
    ) {
      return cardData?.productExport?.limitTags;
    }
    return null;
  }, [cardData]);

  return (
    <Box className={classes.tagsContainer}>
      <Box className={classes.textRow}>
        <Typography variant="body1" color="textPrimary">
          Limit product create and update to items with the following tags
        </Typography>
      </Box>

      <TagsAsyncAutocomplete
        value={selectedTags}
        onChange={handleChange}
        dataQa={dataQa}
        ids={tagIds}
        error={!!errors['productExport.limitTags']}
      />
    </Box>
  );
};

const ModalRow: React.FC<RowDescriptor & ModalRowProps> = (props) => {
  switch (props.type) {
    case RowType.Text:
      return <TextRow {...props} />;
    case RowType.LocationPicker:
      return <LocationPickerRow {...props} />;
    case RowType.Tag:
      return <TagRow {...props} />;
    case RowType.Mapping:
      return <MappingRow {...props} />;
    default:
      return <></>;
  }
};

export default memo(ModalRow);
