import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, useLocation, Route, matchRoutes } from 'react-router-dom';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import lodash from 'lodash';
import clsx from 'clsx';
import { Box } from '@mui/material';
import { MaterialsRouter } from 'ui/modules/materials';
import { SetupRouter } from 'ui/modules/setup';
import { PurchasingRouter } from 'ui/modules/purchasing';
import { SalesRouter } from 'ui/modules/sales';
import { ReportsRouter } from 'ui/modules/reports';
import { HomeRouter } from 'ui/modules/home';
import { AccountingRouter } from 'ui/modules/accounting';
import { CommerceRouter } from 'ui/modules/commerce';
import { IntegrationsRouter } from 'ui/modules/integrations';
import { NotificationsRouter } from 'ui/modules/notifications';
import { RemoteSupportRouter } from 'ui/modules/remotesupport';
import { FeedbackRouter } from 'ui/modules/feedback/navigation';
import { fetchActiveUser } from 'services/user';
import { fetchQuickbooksActiveAccount } from 'services/integrations/quickbooks/redux';
import { getAccessToken } from 'services/auth';
import { fetchShippingIntegrationConnections } from 'services/integrations/shipping/redux';
import { fetchSettingsCompanies } from 'services/settings/company/redux';
import { fetchXeroActiveAccount } from 'services/integrations/xero';

import { Routes as AppRoutes } from '../../navigation';
import { useAppHomeStyles } from './styled';

import { getActiveUser } from 'services/user';

import { fetchSellwareActiveAccount } from 'services/commerce';
import FBODrawer from 'ui/theme/components/FBODrawer/FBODrawer';
import { FBOAppBar } from 'ui/theme/components/FBOAppBar';
import { fetchPaymentTerms } from 'services/paymentTerms';
import { datadogRum } from '@datadog/browser-rum';
export interface AppHomeCmp extends React.FunctionComponent {
  route: string;
}

const AppHome: AppHomeCmp = (props: any) => {
  const classes = useAppHomeStyles(props);

  const dispatch = useDispatch();
  const location = useLocation();

  const activeUser = useSelector(getActiveUser);
  const ldClient = useLDClient();

  const [drawerExpanded, setDrawerExpanded] = React.useState(true);
  const [drawerExpandedPersistent, setDrawerExpandedPersistent] =
    React.useState(true);

  const handleDrawerExpand = useCallback(
    (expanded: boolean, persistent: boolean) => {
      setDrawerExpanded(expanded);
      if (persistent) {
        setDrawerExpandedPersistent(expanded);
      }
    },
    []
  );

  useEffect(() => {
    if (ldClient && activeUser.userGlobalId && activeUser.user) {
      const key = lodash.get(activeUser, 'userGlobalId', '000') as string;
      const email = lodash.get(activeUser, 'user.email', 'n/a');
      const firstName = lodash.get(activeUser, 'user.firstName', 'n/a');
      const lastName = lodash.get(activeUser, 'user.lastName', 'n/a');
      const name = `${firstName} ${lastName}`;

      ldClient.identify({
        kind: 'user',
        key,
        email,
        name,
      });
    }
  }, [activeUser]);

  useEffect(() => {
    // if there's an access token in storage fetch current user
    const accessToken = getAccessToken();
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    if (accessToken) {
      dispatch(fetchActiveUser() as any);
      // @ts-ignore
      dispatch(fetchQuickbooksActiveAccount());
      // @ts-ignore
      dispatch(fetchShippingIntegrationConnections());
      // @ts-ignore
      dispatch(fetchSettingsCompanies());
      // @ts-ignore
      dispatch(fetchPaymentTerms());
      // Check for Xero integration
      // @ts-ignore
      dispatch(fetchXeroActiveAccount());
      // @ts-ignore
      dispatch(fetchSellwareActiveAccount());
    }
  }, [dispatch]);

  const routes = [
    { path: MaterialsRouter.route, element: <MaterialsRouter /> },
    { path: SetupRouter.route, element: <SetupRouter /> },
    { path: PurchasingRouter.route, element: <PurchasingRouter /> },
    { path: AccountingRouter.route, element: <AccountingRouter /> },
    { path: SalesRouter.route, element: <SalesRouter /> },
    { path: ReportsRouter.route, element: <ReportsRouter /> },
    { path: NotificationsRouter.route, element: <NotificationsRouter /> },
    { path: CommerceRouter.route, element: <CommerceRouter /> },
    { path: IntegrationsRouter.route, element: <IntegrationsRouter /> },
    { path: RemoteSupportRouter.route, element: <RemoteSupportRouter /> },
    { path: FeedbackRouter.route, element: <FeedbackRouter /> },
    { path: HomeRouter.route, element: <HomeRouter /> },
  ];

  // Track every route change with useLocation API
  const routerLocation = useLocation();

  // React router instrumentation
  // https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm#react-router-instrumentation
  const computeViewName = (routeMatches: string | any[]) => {
    let viewName = '';
    for (let index = 0; index < routeMatches.length; index++) {
      const routeMatch = routeMatches[index];
      const path = routeMatch.route.path;
      // Skip pathless routes
      if (!path) {
        continue;
      }

      if (path.startsWith('/')) {
        // Handle absolute child route paths
        viewName = path;
      } else {
        // Handle route paths ending with "/"
        viewName += viewName.endsWith('/') ? path : `/${path}`;
      }
    }

    return viewName || '/';
  };

  useEffect(() => {
    const routeMatches = matchRoutes(routes, routerLocation.pathname);

    const viewName = routeMatches && computeViewName(routeMatches);
    if (viewName) {
      datadogRum.startView({ name: viewName });
    }
  }, [routerLocation.pathname]);

  return (
    <Box
      className={clsx('redesign', {
        [classes.homeWrapper]: false,
        'home-page-layout': true,
      })}
    >
      <FBODrawer
        drawerExpanded={drawerExpanded || drawerExpandedPersistent}
        drawerPersistent={drawerExpandedPersistent}
        onDrawerExpand={handleDrawerExpand}
        activePathName={location.pathname}
      />

      <Box
        className={clsx('main-page-layout', {
          [classes.contentWrapper]: false,
        })}
      >
        <FBOAppBar />
        <Routes>
          <Route path={MaterialsRouter.route} element={<MaterialsRouter />} />
          <Route path={SetupRouter.route} element={<SetupRouter />} />
          <Route path={PurchasingRouter.route} element={<PurchasingRouter />} />
          <Route path={AccountingRouter.route} element={<AccountingRouter />} />
          <Route path={SalesRouter.route} element={<SalesRouter />} />
          <Route path={ReportsRouter.route} element={<ReportsRouter />} />
          <Route
            path={NotificationsRouter.route}
            element={<NotificationsRouter />}
          />
          <Route path={CommerceRouter.route} element={<CommerceRouter />} />
          <Route
            path={IntegrationsRouter.route}
            element={<IntegrationsRouter />}
          />
          <Route
            path={RemoteSupportRouter.route}
            element={<RemoteSupportRouter />}
          />
          <Route path={FeedbackRouter.route} element={<FeedbackRouter />} />
          <Route path={HomeRouter.route} element={<HomeRouter />} />
        </Routes>
      </Box>
    </Box>
  );
};

AppHome.route = AppRoutes.AppHome;

export default AppHome;
