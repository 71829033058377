import React, { memo, useMemo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import {
  getBackgroundAction,
  getBackgroundType,
  getProgressAlertMsg,
  hideProgressAlert,
  isProgressAlertVisible,
} from 'services/alert/redux';
import { ProgressBar } from 'ui/components/ProgressBar';
import {
  showNotification,
  ShowQboRefreshNotification,
} from 'services/api/notifications';
import {
  useBackgroundTasks,
  BackgroundStatus,
  clearCompletedDownloads,
  BackgroundType,
} from 'services/backgroundTasks';

import { ConfirmationModal } from '../ConfirmationModal';
import { useAlertProgressModalStyle } from './styled';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const AlertProgressModal: React.FC = (props) => {
  const classes = useAlertProgressModalStyle(props);

  const { startFetching, backgroundTasks } = useBackgroundTasks();

  const dispatch = useDispatch();

  const alert = useSelector(getProgressAlertMsg);
  const open = useSelector(isProgressAlertVisible);
  const bgType = useSelector(getBackgroundType);
  const bgAction = useSelector(getBackgroundAction);

  const backgroundTask = useMemo(() => {
    const backgroundTasksQbo = backgroundTasks.filter(
      (bg) => bg.type === bgType && bg.action === bgAction
    );

    return _.last(backgroundTasksQbo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backgroundTasks]);

  useEffect(() => {
    // We are listening to latests qbo export changes and show message once its finished
    if (!backgroundTask) {
      return;
    }

    (async () => {
      const type = _.capitalize(backgroundTask.type);
      const action = _.capitalize(backgroundTask.action || '');

      if (backgroundTask.status === BackgroundStatus.Completed) {
        switch (backgroundTask.type) {
          case BackgroundType.Qbo:
            ShowQboRefreshNotification();
            break;
          default:
            showNotification(`${type} ${action} finished!`, {
              variant: 'success',
            });
        }
        // Clear all qbo exports
        await clearCompletedDownloads(backgroundTask.action, bgType);
        return;
      }

      if (backgroundTask.status === BackgroundStatus.Failed) {
        showNotification(
          backgroundTask.message ||
            `${type} ${action} failed, please try again!`,
          {
            variant: 'error',
          }
        );
        // Clear all qbo exports
        await clearCompletedDownloads(backgroundTask.action, bgType);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backgroundTask]);

  useEffect(() => {
    if (open) {
      startFetching();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = useCallback(() => {
    dispatch(hideProgressAlert(alert, bgType, bgAction));
  }, [alert, bgType, bgAction, dispatch]);

  return (
    <ConfirmationModal
      open={open}
      title="Alert"
      onConfirmClicked={_.noop}
      onCancelClicked={_.noop}
      body={alert ? alert : ''}
      DialogActionsComponent={() => (
        <div>
          <FBOButton
            onClick={handleClose}
            variant="primary"
            color="positive"
            data-qa="alert-modal-ok"
          >
            OK
          </FBOButton>
          <ProgressBar
            value={backgroundTask ? backgroundTask.progress || 0 : 0}
            className={classes.progressBar}
          />
        </div>
      )}
    />
  );
};

export default memo(AlertProgressModal);
