import { StatusObj } from './types';

export const resetLocalStatuses = (): void => {
  sessionStorage.removeItem('localStatuses');
};

export const getLocalStatuses = (): string | null => {
  return sessionStorage.getItem('localStatuses');
};

const setLocalStatuses = (stringStatus: string): void => {
  sessionStorage.setItem('localStatuses', stringStatus);
};

const toggleJSON2Str = (argToProcess: JSON): string => {
  return JSON.stringify(argToProcess);
};

const toggleStr2JSON = (argToProcess: string): JSON => {
  return JSON.parse(argToProcess);
};

export const removeLocalStatus = (id: string): void => {
  const existingStatuses = toggleStr2JSON(getLocalStatuses() as string) || {};
  delete existingStatuses[id as keyof typeof JSON];
  setLocalStatuses(toggleJSON2Str(existingStatuses));
};

export const setLocalStatus = (statusObj: StatusObj): void => {
  const existingStatuses = toggleStr2JSON(getLocalStatuses() as string) || {};
  delete existingStatuses[statusObj.id as keyof typeof JSON];
  const newStatus = { ...existingStatuses, [statusObj.id]: statusObj };
  setLocalStatuses(toggleJSON2Str(newStatus));
};

export const getLocalStatus = (id: string): any => {
  const existingStatuses = toggleStr2JSON(getLocalStatuses() as string) || {};
  const localStatus = existingStatuses[id as keyof typeof JSON];
  return localStatus;
};
