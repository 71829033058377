import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { Box, Grid, InputAdornment, Typography } from '@mui/material';
import moment from 'moment';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import {
  getCurrencyById,
  createCurrency,
  updateCurrency,
  deleteCurrency,
  restoreCurrency,
} from 'services/currencies/api';
import { Currency } from 'services/currencies';
import { initialCurrency } from 'services/currencies/consts';
import { DetailsCard } from 'ui/components/Page/DetailsCard';
import {
  CountryAutocomplete,
  CountryType,
  COUNTRIES,
} from 'ui/components/Autocomplete/CountryAutocomplete';
import { TextField } from 'ui/components/TextField/TextField';
import { useHandleTextFieldChange } from 'services/forms';
import { NumberFormatCustom } from 'ui/components/TextField/MultiFormatInput';
import { getUsers, User } from 'services/user';
import { getUsers as getUsersV2, User as UserV2 } from 'services/userV2';
import { GridDivider } from 'ui/components/Grid';
import { useUrlQueryObject } from 'services/url';
import { Errors, validateYup } from 'services/forms/validation';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';

import { CurrencyTitleBar } from '../CurrencyTitleBar';
import { CurrencyDetailsCardProps } from './types';
import { yupCurrencySchema } from './validations';
import { useGetCurrencySymbol, useGetIntlDateFormatString } from 'helpers';
import { logErrorCtx } from 'app/logging';
import { PermissionType } from 'services/permissions';
import { useFlags } from 'helpers/useFlags';
import { useLocation, useNavigate } from 'react-router-dom';

const CurrencyDetailsCard: React.FC<CurrencyDetailsCardProps> = (props) => {
  const { activeCurrencyId, onClose, fetchSearchResult } = props;

  const oldState = useRef<Currency | null>(initialCurrency);

  const navigate = useNavigate();
  const location = useLocation();
  const [, setQueryParams] = useUrlQueryObject(navigate, location);

  const flags = useFlags();
  const globalStateUsers = useSelector(getUsers);
  const globalStateUsersV2 = useSelector(getUsersV2);
  const users: (User | UserV2)[] = flags.newUserEndpoint
    ? globalStateUsersV2
    : globalStateUsers;
  const intlFormatDate = useGetIntlDateFormatString();

  const [isLoading, setIsLoading] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [errors, setErrors] = useState<Errors>({});
  const [activeCurrency, setActiveCurrency] =
    useState<Currency>(initialCurrency);

  const selectedCountryType = useMemo(() => {
    return COUNTRIES.find((c) => c.code === activeCurrency.country) || null;
  }, [activeCurrency.country]);

  const handleTextFieldChange = useHandleTextFieldChange(
    setActiveCurrency,
    activeCurrency
  );

  const lastModifiedUser = useMemo(() => {
    return users.find((c) => c.id === activeCurrency.lastModifiedById) || null;
  }, [activeCurrency, users]);

  const lastModifiedUserName = () => {
    if (!lastModifiedUser) {
      return 'N/A';
    }
    return `${lastModifiedUser.firstName} ${lastModifiedUser.lastName}`;
  };

  useEffect(() => {
    if (!activeCurrencyId || activeCurrencyId < 0) {
      setActiveCurrency(initialCurrency);
      oldState.current = initialCurrency;
      return;
    }

    const asyncFc = async () => {
      setIsLoading(true);
      try {
        const currency = await getCurrencyById(activeCurrencyId);
        oldState.current = currency;
        setActiveCurrency(currency);
      } catch (e) {
        logErrorCtx('Error in getCurrencyById', {
          error: e as Error,
          component: 'CurrencyDetailsCard',
          description: `Currency id ${activeCurrencyId}`,
        });
      }
      setIsLoading(false);
    };

    asyncFc();
  }, [activeCurrencyId]);

  const handleSave = useCallback(
    (close: boolean = false) =>
      async () => {
        if (!validateYup(activeCurrency, yupCurrencySchema, setErrors)) {
          return false;
        }
        setIsLoading(true);

        // CREATE CURRENCY
        if (!activeCurrency.id || activeCurrency.id < 0) {
          try {
            const newCurrency = await createCurrency(activeCurrency);
            oldState.current = newCurrency;
            setActiveCurrency(newCurrency);
            await fetchSearchResult();
            setQueryParams({ activeId: newCurrency.id });
            if (close) {
              setQueryParams({ activeId: null });
              onClose();
              return true;
            }
          } catch {
            setIsLoading(false);
            return false;
          }
          setIsLoading(false);
          return true;
        }

        // UPDATE CURRENCY
        try {
          const newCurrency = await updateCurrency(activeCurrency);
          oldState.current = newCurrency;
          setActiveCurrency(newCurrency);
          await fetchSearchResult();
        } catch {
          setIsLoading(false);
          return false;
        }
        if (close) {
          onClose();
        }

        setIsLoading(false);
        return true;
      },
    [activeCurrency, fetchSearchResult, onClose, setQueryParams]
  );

  const handleUndeleteClicked = useCallback(
    async (close: boolean = false) => {
      setIsLoading(true);

      try {
        await restoreCurrency(activeCurrencyId!);
        const restoredCurrency = await getCurrencyById(activeCurrencyId!);
        oldState.current = restoredCurrency;
        setActiveCurrency(restoredCurrency);
      } catch {
        setIsLoading(false);
        return false;
      }

      if (close) {
        onClose();
      }

      setIsLoading(false);
      fetchSearchResult();
      return true;
    },

    [fetchSearchResult, onClose, activeCurrencyId]
  );

  const deleteCurrencyClicked = useCallback(async () => {
    setIsLoading(true);

    try {
      await deleteCurrency(activeCurrency.id!);
      await fetchSearchResult();
      onClose();
    } catch (e) {
      logErrorCtx('Error in deleteCurrency', {
        error: e as Error,
        component: 'CurrencyDetailsCard',
        description: `Currency id ${activeCurrency.id}`,
      });
    }

    setIsLoading(false);
    setDeleteModalVisible(false);
  }, [activeCurrency, fetchSearchResult, onClose]);

  const handleCountryChange = useCallback(
    (e: any, countryType: CountryType) => {
      if (countryType) {
        setActiveCurrency((old) => ({
          ...old,
          country: countryType.code,
          code: countryType.currencyCode,
          name: countryType.currencyName,
          exchangeRate: null,
        }));
      } else {
        setActiveCurrency(initialCurrency);
      }
    },
    []
  );

  const handleExchangeRateChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value ? parseFloat(e.target.value) : null;
    setActiveCurrency((old) => ({ ...old, exchangeRate: value }));
  };

  return (
    <>
      <DetailsCard
        isLoading={isLoading}
        onSubmit={handleSave(false)}
        state={activeCurrency}
        oldState={oldState}
      >
        <CurrencyTitleBar
          activeCurrency={activeCurrency}
          onSave={handleSave()}
          onUndeleteClicked={handleUndeleteClicked}
          onClose={onClose}
          deleteModalVisible={() => setDeleteModalVisible(true)}
        />
        <Box p={4}>
          <Grid container spacing={2}>
            <Grid item container xs={12}>
              <Typography>
                <b>Currency Details</b>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <CountryAutocomplete
                value={selectedCountryType}
                onChange={handleCountryChange}
                placeholder="Select Country"
                error={!!errors.name}
                required
                permissions={
                  activeCurrency.id && activeCurrency.id > 0
                    ? [PermissionType.CurrencyEdit]
                    : [PermissionType.CurrencyCreate]
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                className="redesign"
                name="name"
                label="Currency Name"
                placeholder="Currency Name"
                value={activeCurrency.name}
                onChange={handleTextFieldChange}
                disabled
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                className="redesign"
                name="symbol"
                label="Currency Symbol"
                placeholder="Currency Symbol"
                value={_.get(selectedCountryType, 'currencySymbol', '')}
                onChange={handleTextFieldChange}
                disabled
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                className="redesign"
                name="code"
                label="Currency Code"
                placeholder="Currency Code"
                value={activeCurrency.code}
                onChange={handleTextFieldChange}
                disabled
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={1}>
            <Grid item xs>
              <Box pt={5}>
                <Typography>
                  <strong>Conversions</strong>
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid alignItems="center" container xs={12} spacing={2}>
            <Grid item>
              <Box display="flex" alignItems="center" width="100%">
                <TextField
                  className="redesign"
                  fullWidth={false}
                  style={{ flex: '0 0 100px' }}
                  value={1}
                  disabled
                  variant="standard"
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                    endAdornment: (
                      <InputAdornment position="end">
                        {useGetCurrencySymbol()}
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                  }}
                  inputProps={{
                    activeType: { type: 'suffix' },
                    allowNegative: false,
                    decimalPlaces: 0,
                  }}
                  onChange={handleTextFieldChange}
                />
                <Typography>&nbsp;=&nbsp;</Typography>
                <TextField
                  className="redesign"
                  variant="standard"
                  fullWidth={false}
                  style={{ display: 'flex', flex: '0 0 auto' }}
                  name="exchangeRate"
                  label="Exchange Rate"
                  disabled={activeCurrency.code === null}
                  value={activeCurrency.exchangeRate}
                  required
                  permissions={
                    activeCurrency.id
                      ? [PermissionType.CurrencyEdit]
                      : [PermissionType.CurrencyCreate]
                  }
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                    endAdornment: (
                      <InputAdornment position="end">
                        {activeCurrency.code}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    activeType: {
                      type: 'suffix',
                    },
                    allowNegative: false,
                    decimalPlaces: 6,
                  }}
                  error={!!errors.exchangeRate}
                  onChange={handleExchangeRateChange}
                />
              </Box>
            </Grid>
          </Grid>
          <GridDivider />
          <Grid container item spacing={1}>
            <Grid item xs={6}>
              <TextField
                className="redesign"
                variant="standard"
                label="Last Modified"
                value={
                  activeCurrency.dateLastModified
                    ? moment(activeCurrency.dateLastModified).format(
                        intlFormatDate
                      )
                    : 'N/A'
                }
                InputLabelProps={{ style: { fontSize: 18 } }}
                readOnly
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="redesign"
                variant="standard"
                label="Last Modified By"
                value={lastModifiedUserName()}
                InputLabelProps={{ style: { fontSize: 18 } }}
                readOnly
              />
            </Grid>
          </Grid>
        </Box>
      </DetailsCard>
      <ConfirmationModal
        open={deleteModalVisible}
        title="Delete Currency"
        body={`This will delete '${
          activeCurrency.name || ''
        }' currency, are you sure?`}
        onCancelClicked={() => setDeleteModalVisible(false)}
        onConfirmClicked={deleteCurrencyClicked}
        confirmLabel={'Delete'}
        cancelLabel={'Cancel'}
        confirmButtonRed
      />
    </>
  );
};

export default CurrencyDetailsCard;
