import styled from 'styled-components';

export const AccordionContainer = styled.div<{
  expanded: boolean;
  maxHeight: string;
}>`
  border: none;
  width: 100%;
  max-height: ${({ expanded }) => (expanded ? '100%' : '7%')};
  transition: max-height 0.3s ease, padding 0.3s ease;
  overflow: hidden;
  height: ${({ maxHeight }) => maxHeight};
`;

export const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 16px;
  cursor: pointer;
  border-bottom: 1px solid #e2e8ee;
  border-top: 1px solid #e2e8ee;
`;

export const AccordionContent = styled.div<{ expanded: boolean }>`
  max-height: ${({ expanded }) => (expanded ? '80%' : '0')};
  overflow-y: auto;
  padding: 0;
  border-radius: 5px;
`;
