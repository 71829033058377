import React from 'react';
import { TableCell } from '@mui/material';
import moment from 'moment';
import _ from 'lodash';

import { useCurrencyFormatter, useGetIntlDateFormatString } from 'helpers';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import { TransactionsTableRow } from './types';

const TransactionsTableCustomRow: React.FC<
  RenderCustomRowProps<TransactionsTableRow>
> = (props) => {
  const { row, index } = props;

  const currencyFormatter = useCurrencyFormatter();
  const intlDateFormat = useGetIntlDateFormatString();

  return (
    <>
      <TableCell data-qa={`Row-${index}-Date`} style={{ cursor: 'default' }}>
        {row.date ? moment(row.date || '').format(intlDateFormat) : ''}
      </TableCell>
      <TableCell data-qa={`Row-${index}-Type`} style={{ cursor: 'default' }}>
        {row.type}
      </TableCell>
      <TableCell data-qa={`Row-${index}-Reference-Number`}>
        {row.refNo}
      </TableCell>
      <TableCell data-qa={`Row-${index}-Name`} style={{ cursor: 'default' }}>
        {row.name}
      </TableCell>
      <TableCell data-qa={`Row-${index}-Memo`} style={{ cursor: 'default' }}>
        {row.memo}
      </TableCell>
      <TableCell data-qa={`Row-${index}-Account`} style={{ cursor: 'default' }}>
        {row.accountName}
      </TableCell>
      <TableCell data-qa={`Row-${index}-Debit`} style={{ cursor: 'default' }}>
        {_.isNull(row.debitAmount) ? '-' : currencyFormatter(row.debitAmount)}
      </TableCell>
      <TableCell data-qa={`Row-${index}-Credit`} style={{ cursor: 'default' }}>
        {_.isNull(row.creditAmount) ? '-' : currencyFormatter(row.creditAmount)}
      </TableCell>
    </>
  );
};

export default React.memo(TransactionsTableCustomRow);
