import React, { memo, useState, useEffect, useRef } from 'react';
import { pdfjs } from 'react-pdf';
import { Box, Tooltip, Typography } from '@mui/material';

import { IconNames } from 'ui/theme';
import { isTestEnv } from 'services/api/testEnv';

import { PdfViewerProps } from './types';
import { usePdfViewerStyles } from './styled';
import { getAccessToken } from 'services/auth';

import { DownloadFileType } from '../ReportsViewerContent/consts';
import { ReportId } from 'services/reports';
import * as Helpers from './helpers';
import axios from 'axios';
import HtmlReport from './HtmlReport';
import { tooltipDelays } from './helpers';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const PdfViewer: React.FC<PdfViewerProps> = (props) => {
  const {
    reportName,
    params,
    onDownloadClicked,
    onPrintClicked,
    onEmailClicked,
    reportTheme,
    reportVersion,
  } = props;

  const [scale, setScale] = useState(1);
  const [fullscreen, setFullscreen] = useState(false);
  const [htmlReport, setHtmlReport] = useState('');
  const [isLoadingHtml, setIsLoadingHtml] = useState(false);
  const { pageNumber, nextPage, previousPage, pageCount } =
    Helpers.useReportPagination();
  const classes = usePdfViewerStyles({ scale });
  const fullscreenElementRef = useRef<HTMLButtonElement>(null);

  const loadHtmlReport = () => {
    const loadReport = async () => {
      setIsLoadingHtml(true);
      // client timezone
      const newParam = Helpers.handleTimeZoneDifference(params);
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let url = `${
        process.env.REACT_APP_REPORTING_SERVICE_URL
      }/api/v1/reports?reportName=${reportName}&parameters=${encodeURIComponent(
        JSON.stringify(newParam)
      )}&timezone=${timezone}&html=true&DownloadFileType=html`;

      if (reportTheme) {
        url += `&themeId=${reportTheme.id}`;
      }

      const { data: htmlString } = await axios({
        method: 'GET',
        url,
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          environment: isTestEnv() ? 'test' : '',
        },
      });

      if (htmlString !== '') {
        setHtmlReport(htmlString);
      }

      setIsLoadingHtml(false);
    };

    loadReport();
  };
  useEffect(loadHtmlReport, [reportName, params, reportTheme, reportVersion]);

  const onFullscreenClicked = () => setFullscreen(true);
  const onFullscreenExitClicked = () => setFullscreen(false);

  const zoomIn = () => setScale(scale + 0.1);
  const zoomReset = () => setScale(1);
  const zoomOut = () => setScale(scale - 0.1);
  const delay = tooltipDelays();
  const shouldShowPdfDownloadButton = !Helpers.isPdfDisabled(
    reportName as ReportId
  );
  const shouldShowExcelDownloadButton = !Helpers.isExcelDisabled(
    reportName as ReportId
  );
  return (
    <>
      <Box
        className={`${fullscreen ? classes.containerFullscreen : ''} ${
          classes.wrapper
        }`}
      >
        <Box
          className={classes.toolbar}
          boxShadow={2}
          ref={fullscreenElementRef}
        >
          <Box className={classes.toolbarTitle}>
            <Tooltip
              title="Zoom out of the report"
              enterDelay={delay.initialDelay}
              enterNextDelay={delay.subsequentDelay}
              PopperProps={{ container: fullscreenElementRef.current }}
            >
              <div>
                <FBOButton
                  onClick={zoomOut}
                  color="neutral"
                  variant="tertiary"
                  size="medium"
                  icon={IconNames.FBOSubCircle}
                  data-qa={`${reportName}-zoom-out`}
                />
              </div>
            </Tooltip>

            <Tooltip
              title="Reset report zoom"
              enterDelay={delay.initialDelay}
              enterNextDelay={delay.subsequentDelay}
              PopperProps={{ container: fullscreenElementRef.current }}
            >
              <div>
                <FBOButton
                  onClick={zoomReset}
                  color="neutral"
                  size="small"
                  variant="tertiary"
                  style={{ padding: 0 }}
                  data-qa="reset-zoom"
                >
                  Reset
                </FBOButton>
              </div>
            </Tooltip>

            <Tooltip
              title="Zoom into the report"
              enterDelay={delay.initialDelay}
              enterNextDelay={delay.subsequentDelay}
              PopperProps={{ container: fullscreenElementRef.current }}
            >
              <div>
                <FBOButton
                  onClick={zoomIn}
                  color="neutral"
                  variant="tertiary"
                  size="medium"
                  icon={IconNames.FBOAddCircle}
                  data-qa={`${reportName}-zoom-in`}
                />
              </div>
            </Tooltip>
          </Box>
          <Box className={classes.toolbarNavigation}>
            {pageCount !== null && pageCount > 0 && (
              <>
                <FBOButton
                  onClick={previousPage}
                  disabled={pageNumber <= 1}
                  color="neutral"
                  variant="tertiary"
                  size="large"
                  icon={IconNames.ArrowBack}
                  data-qa={`${reportName}-previous-page`}
                />
                <Box display="flex" alignItems="center" px={2}>
                  <Typography variant="body1">
                    Page {pageNumber || (pageCount ? 1 : '--')} of{' '}
                    {pageCount || '--'}
                  </Typography>
                </Box>
                <FBOButton
                  onClick={nextPage}
                  disabled={pageCount ? pageNumber >= pageCount : false}
                  color="neutral"
                  variant="tertiary"
                  size="large"
                  icon={IconNames.ArrowForward}
                  data-qa={`${reportName}-next-page`}
                />
              </>
            )}
          </Box>
          <Box className={classes.toolbarActions}>
            {Boolean(onDownloadClicked) && (
              <>
                {shouldShowExcelDownloadButton && (
                  <Tooltip
                    title="Download Excel"
                    enterDelay={delay.initialDelay}
                    enterNextDelay={delay.subsequentDelay}
                    PopperProps={{ container: fullscreenElementRef.current }}
                  >
                    <div>
                      <FBOButton
                        color="neutral"
                        variant="tertiary"
                        icon={IconNames.FBOExcel}
                        onClick={() =>
                          onDownloadClicked(DownloadFileType.Excel)
                        }
                        size="large"
                        data-qa={`${reportName}-download-excel`}
                      />
                    </div>
                  </Tooltip>
                )}
                {shouldShowPdfDownloadButton && (
                  <Tooltip
                    title="Download PDF"
                    enterDelay={delay.initialDelay}
                    enterNextDelay={delay.subsequentDelay}
                    PopperProps={{ container: fullscreenElementRef.current }}
                  >
                    <div>
                      <FBOButton
                        color="neutral"
                        variant="tertiary"
                        icon={IconNames.FBOPdf}
                        onClick={() => onDownloadClicked(DownloadFileType.PDF)}
                        size="large"
                        data-qa={`${reportName}-download-pdf`}
                      />
                    </div>
                  </Tooltip>
                )}
              </>
            )}
            {Boolean(onPrintClicked) && (
              <Tooltip
                title="Print Report"
                enterDelay={delay.initialDelay}
                enterNextDelay={delay.subsequentDelay}
                PopperProps={{ container: fullscreenElementRef.current }}
              >
                <div>
                  <FBOButton
                    color="neutral"
                    variant="tertiary"
                    icon={IconNames.FBOPrint}
                    onClick={onPrintClicked}
                    size="large"
                    data-qa={`${reportName}-print-report`}
                  />
                </div>
              </Tooltip>
            )}
            {
              <Tooltip
                title="Email Report"
                enterDelay={delay.initialDelay}
                enterNextDelay={delay.subsequentDelay}
                PopperProps={{ container: fullscreenElementRef.current }}
              >
                <div>
                  <FBOButton
                    color="neutral"
                    variant="tertiary"
                    icon={IconNames.FBOEmail}
                    onClick={onEmailClicked}
                    size="large"
                    data-qa={`${reportName}-email-report`}
                  />
                </div>
              </Tooltip>
            }
            {!fullscreen ? (
              <Tooltip
                title="Expand"
                enterDelay={delay.initialDelay}
                enterNextDelay={delay.subsequentDelay}
                PopperProps={{ container: fullscreenElementRef.current }}
              >
                <div>
                  <FBOButton
                    color="neutral"
                    variant="tertiary"
                    icon={IconNames.FBOFullscreen}
                    onClick={onFullscreenClicked}
                    size="large"
                    data-qa={`${reportName}-expand`}
                  />
                </div>
              </Tooltip>
            ) : (
              <div>
                <Tooltip
                  title="Contract"
                  enterDelay={delay.initialDelay}
                  enterNextDelay={delay.subsequentDelay}
                  PopperProps={{ container: fullscreenElementRef.current }}
                >
                  <div>
                    <FBOButton
                      color="neutral"
                      variant="tertiary"
                      icon={IconNames.FBOFullscreenContract}
                      onClick={onFullscreenExitClicked}
                      size="large"
                      data-qa={`${reportName}-contract`}
                    />
                  </div>
                </Tooltip>
              </div>
            )}
          </Box>
        </Box>
        <HtmlReport
          scale={scale}
          isLoading={isLoadingHtml}
          htmlReport={htmlReport}
        />
      </Box>
    </>
  );
};

export default memo(PdfViewer);
