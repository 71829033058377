import React, { useCallback, memo, useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { fetchPaymentTerms, getPaymentTerms } from 'services/paymentTerms';
import { CompanySettings, putCompanySettings } from 'services/settings/company';
import {
  fetchOrderPriorities,
  getOrderPriorities,
} from 'services/settings/orderPriorities';
import { ImageStorageType, uploadImage } from 'services/images';
import { fetchLocations } from 'services/locations';
import {
  fetchSettingsCompanies,
  getSettingsCompany,
} from 'services/settings/company/redux';
import { PermissionType } from 'services/permissions';
import { fetchCurrencies } from 'services/currencies';
import { activeUserHasPermission } from 'services/user/redux';

import { companyRowDescriptor, companyCardId, title } from './consts';
import { CompanyCardProps } from './types';
import { Card, RowDescriptor } from '../Card';
import { searchFilter, searchTitle } from '../../helpers';
import { fetchTaxRates } from 'services/taxRates';

const CompanyCard: React.FC<CompanyCardProps> = (props) => {
  const { search } = props;
  const dispatch = useDispatch();
  const [companySettings, setCompanySettings] = useState<CompanySettings>();

  const { items: paymentTerms } = useSelector(getPaymentTerms);
  const { items: orderPriorities } = useSelector(getOrderPriorities);
  const companySettingsCopy = useSelector(getSettingsCompany);

  const companyRows = companyRowDescriptor(paymentTerms, orderPriorities);

  const hasMultiCurrencyViewPermission = useSelector(
    activeUserHasPermission([PermissionType.SettingsMultiCurrencyView])
  );
  const hasMultiCurrencyEditPermission = useSelector(
    activeUserHasPermission([PermissionType.SettingsMultiCurrencyEdit])
  );

  const regex = RegExp(_.escapeRegExp(search), 'gi');

  const hasSearch = companyRows.some((t) => regex.test(t.title));

  const hasTitle = new RegExp(_.escapeRegExp(search), 'i').test(title);

  const filterRows = (): RowDescriptor[] => {
    const rows: RowDescriptor[] = hasMultiCurrencyViewPermission
      ? companyRows
      : companyRows.filter((val) => val.field !== 'useMultiCurrency');

    const multiCurrencyRowIndex = rows.findIndex(
      (row) => row.field === 'useMultiCurrency'
    );

    if (multiCurrencyRowIndex >= 0) {
      hasMultiCurrencyEditPermission
        ? (rows[multiCurrencyRowIndex].disabled = false)
        : (rows[multiCurrencyRowIndex].disabled = true);
    }

    if (hasSearch) {
      return searchFilter(rows, regex);
    }
    return rows;
  };

  const highlightedTitle = hasTitle ? searchTitle(title, regex) : title;

  useEffect(() => {
    setCompanySettings(companySettingsCopy);
  }, [companySettingsCopy]);

  useEffect(() => {
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchPaymentTerms());
    // @ts-ignore
    dispatch(fetchOrderPriorities());
    // @ts-ignore
    dispatch(fetchLocations());
    // @ts-ignore
    dispatch(fetchSettingsCompanies());
    // @ts-ignore
    dispatch(fetchCurrencies());
  }, [dispatch]);

  const handleApplyClicked = useCallback(async (newCompanySettings) => {
    try {
      if (
        newCompanySettings.imageUrl &&
        newCompanySettings.imageUrl.includes('data:image')
      ) {
        newCompanySettings.imageUrl = await uploadImage(
          newCompanySettings.imageUrl,
          ImageStorageType.UserImage
        );
      }
      const settings = await putCompanySettings(newCompanySettings);
      setCompanySettings(settings);

      // @ts-ignore
      dispatch(fetchSettingsCompanies());
    } catch {
      // Ignore
    }
  }, []);

  useEffect(() => {
    // @ts-ignore
    dispatch(fetchTaxRates());
  });

  return (
    <Card
      title={highlightedTitle}
      rows={filterRows()}
      id={companyCardId}
      data={companySettings}
      onApplyClicked={handleApplyClicked}
      show={hasSearch || hasTitle}
      viewPermissions={[PermissionType.SettingsCompanyView]}
      editPermissions={[PermissionType.SettingsCompanyEdit]}
      noReset
      dataQa="company-settings"
    />
  );
};

export default memo(CompanyCard);
