import {
  FulfillServiceAndMiscItemsOnShip,
  ShipmentBatchSelectionDefault,
} from 'services/settings/shipping';

import { RowDescriptor, RowType } from '../Card';

export const shippingCardId = 'shipping-card-navigation-id';

export const title = 'Shipping';

export const shippingRowDescriptor: RowDescriptor[] = [
  {
    type: RowType.Autocomplete,
    title: 'Fulfill service and misc items on ship',
    field: 'fulfillServiceAndMiscItemsOnShip',
    options: Object.values(FulfillServiceAndMiscItemsOnShip),
    dataQa: 'fulfill-service-and-misc-items-on-ship',
  },
  {
    type: RowType.Boolean,
    title: 'Fulfill ship items on ship',
    field: 'fulfillShippingLinesOnShip',
  },
  {
    type: RowType.Boolean,
    title: 'Print packing list on ship',
    field: 'printPackingListOnShip',
    dataQa: 'print-packing-list-on-ship',
  },
  {
    type: RowType.Boolean,
    title: 'Print packing list on pack',
    field: 'printPackingListOnPack',
    dataQa: 'print-packing-list-on-pack',
  },
  {
    type: RowType.Autocomplete,
    title: 'Batch selection default',
    field: 'shipmentBatchSelectionDefault',
    options: Object.values(ShipmentBatchSelectionDefault),
    dataQa: 'batch-selection-default',
  },
  {
    type: RowType.Boolean,
    title: 'Auto pack shipments',
    field: 'autoPackShipments',
    dataQa: 'auto-pack-shipment',
  },
  {
    type: RowType.Boolean,
    title: 'Notify if order is not paid',
    field: 'orderNotPaidWarning',
    dataQa: 'notify-if-order-is-not-paid',
  },
  {
    type: RowType.Boolean,
    title: 'Show paid stamp',
    field: 'showPaidStamp',
    dataQa: 'show-paid-stamp',
  },
];
