import React, { memo, useCallback } from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { ItemsAutocomplete } from 'ui/components/Autocomplete/ItemsAutocomplete';
import { Location } from 'services/locations';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import { Item } from 'services/items';
import { useHandleCheckboxChange } from 'services/forms/hooks';

import { ReportFiltersProps } from '../../types';

const ReorderFilters: React.FC<ReportFiltersProps> = (props) => {
  const { parameters, setParameters } = props;

  const handleLocationChange = useCallback(
    (location: Location | null) => {
      setParameters((old) => ({
        ...old,
        locationId: location ? location.id : null,
      }));
    },
    [setParameters]
  );

  const handleItemChange = useCallback(
    (selectedItem: Item | null) => {
      setParameters((old) => ({
        ...old,
        itemId: selectedItem ? selectedItem.id : null,
      }));
    },
    [setParameters]
  );

  const handleSelectAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setParameters((old) => ({
        ...old,
        showDefaultVendor: checked,
        showAllItemsWithReorderPoints: checked,
        showDescription: checked,
        selectAllOptional: checked,
        showAllItemsWithNoReorderPoints: checked,
      }));
    },
    [setParameters]
  );
  const handleCheckboxChange = useHandleCheckboxChange(setParameters);

  // TODO : RESTYLING : Remove box wrappers around inputs after FF removal

  return (
    <>
      <Box px={2} pb={0} pt="16px">
        <FormControlLabel
          className="redesign"
          sx={{ marginBottom: '8px' }}
          control={
            <Checkbox
              className="redesign"
              checked={Boolean(parameters.selectAllOptional)}
              onChange={handleSelectAll}
              color="primary"
              data-qa="reorder-report-select-all-optional"
            />
          }
          label="Select All Optional"
        />
      </Box>
      <Accordion square defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          data-qa="reorder-report-optional"
        >
          <Typography>
            <b>Optional</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%">
            <Box>
              <LocationsAsyncAutocomplete
                label="Location"
                value={parameters.locationId}
                placeholder="Select location"
                companyWide={false}
                onChange={handleLocationChange}
                parentId={null}
                dataQa="reorder-report-location"
              />
            </Box>
            <Box>
              <ItemsAutocomplete
                label="Item"
                placeholder="Select item"
                onChange={handleItemChange}
                value={parameters.itemId}
                dataQa="reorder-report-item"
              />
            </Box>
            <FormControlLabel
              className="redesign"
              sx={{ marginBottom: '8px' }}
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(parameters.showDefaultVendor)}
                  onChange={handleCheckboxChange}
                  name="showDefaultVendor"
                  color="primary"
                  data-qa="reorder-report-show-default-vendor"
                />
              }
              label="Show Default Vendor"
            />
            <FormControlLabel
              className="redesign"
              sx={{ marginBottom: '8px' }}
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(parameters.showAllItemsWithReorderPoints)}
                  onChange={handleCheckboxChange}
                  name="showAllItemsWithReorderPoints"
                  color="primary"
                  data-qa="reorder-report-show-all-items-with-reorder-points"
                />
              }
              label="Show All Reorder Items"
            />
            <FormControlLabel
              className="redesign"
              sx={{ marginBottom: '8px' }}
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(parameters.showDescription)}
                  onChange={handleCheckboxChange}
                  name="showDescription"
                  color="primary"
                  data-qa="reorder-report-show-description"
                />
              }
              label="Show Item Description"
            />
            <FormControlLabel
              className="redesign"
              sx={{ marginBottom: '8px' }}
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(parameters.showAllItemsWithNoReorderPoints)}
                  onChange={handleCheckboxChange}
                  name="showAllItemsWithNoReorderPoints"
                  color="primary"
                  data-qa="reorder-report-show-all-items-with-no-reorder-points"
                />
              }
              label="Include Items with No Reorder Point"
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default memo(ReorderFilters);
