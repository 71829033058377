import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { FormControlLabel, Checkbox, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import {
  findNextNegativeId,
  replaceValueInCollection,
  getExchangeRate,
} from 'helpers';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { TextField } from 'ui/components/TextField/TextField';
import { TagsAsyncAutocomplete } from 'ui/components/Autocomplete/TagsAutocomplete';
import { PaymentTermsAutocomplete } from 'ui/components/Autocomplete/PaymentTermsAutocomplete';
import { AddressAutocomplete } from 'ui/components/Autocomplete/AddressAutocomplete';
import { ActiveItemIdState } from 'ui/components/Page/WithSearchResults';
import { ClassAutocomplete } from 'ui/components/Autocomplete/ClassesAutocomplete';
import { PhoneInputField } from 'ui/components/TextField/PhoneInputField';
import { CurrencyAutocomplete } from 'ui/components/Autocomplete/CurrencyAutocomplete';
import { Address } from 'services/addresses';
import { getCarriers } from 'services/carriers/redux';
import { getPaymentTerms, PaymentTerm } from 'services/paymentTerms';
import { useHandleTextFieldChange } from 'services/forms';
import { Vendor } from 'services/vendors';
import { activeUserHasPermission } from 'services/user/redux';
import { getSettingsCompany } from 'services/settings/company';
import { Tag } from 'services/tags';
import {
  CustomField,
  fetchCustomFieldsAPI,
  ObjectType,
  useCustomFields,
} from 'services/customFields';
import { Class, getClasses } from 'services/classes';
import { Currency } from 'services/currencies';
import { PermissionType } from 'services/permissions';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';

import { GeneralTabProps, ExchangeRateInputProps } from './types';
import { editVendorPermissions } from '../helpers';
import { useVendorGeneralStyle } from './styled';
import { stringToDecimal } from 'services/forms';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { colorPalette } from 'ui/theme';
import FBOCustomFields from 'ui/components/CustomFields/CustomFields/FBOCustomFields';
import { useEnterKeyPressHandler } from 'services/forms/hooks';

const FBOGeneralTab: React.FC<GeneralTabProps> = (props) => {
  const {
    activeVendor,
    setActiveVendor,
    validationErrors,
    customFieldsErrors,
    oldState,
  } = props;

  const { items: paymentTerms } = useSelector(getPaymentTerms);
  const { items: carriers } = useSelector(getCarriers);

  const styleClasses = useVendorGeneralStyle(props);

  const classes = useSelector(getClasses);

  const setCustomFields = useCustomFields<Vendor>(setActiveVendor);

  const handleTextFieldChange = useHandleTextFieldChange<Vendor>(
    setActiveVendor,
    activeVendor
  );

  const handleEnterPress = useEnterKeyPressHandler();

  const editPermission = editVendorPermissions(activeVendor);

  const canClick = useSelector(activeUserHasPermission(editPermission));

  const firstInputElement = useRef<HTMLInputElement>(null);

  const useMultiCurrencyEnabled =
    useSelector(getSettingsCompany).useMultiCurrency;

  const selectedClass = useMemo(() => {
    return classes.find((v) => v.id === activeVendor.accountingClassId) || null;
  }, [activeVendor, classes]);

  const selectedPaymentTerm = useMemo(() => {
    return (
      paymentTerms.find((p) => p.id === activeVendor.paymentTermId) || null
    );
  }, [paymentTerms, activeVendor]);

  const existingAddresses = useMemo(
    () => activeVendor.addresses.filter((a) => !a.deleted),
    [activeVendor.addresses]
  );

  const selectedBillToAddress = useMemo(
    () => existingAddresses.find((a) => a.defaultBillTo) || null,
    [existingAddresses]
  );

  const selectedShipToAddress = useMemo(
    () => existingAddresses.find((a) => a.defaultShipTo) || null,
    [existingAddresses]
  );

  const selectedCarrier = useMemo(() => {
    return carriers.find((c) => c.id === activeVendor.defaultCarrierId) || null;
  }, [carriers, activeVendor]);

  const selectedService = useMemo(() => {
    if (!selectedCarrier) {
      return null;
    }
    return (
      selectedCarrier.carrierServiceList.find(
        (c) => c.id === activeVendor.defaultCarrierServiceId
      ) || null
    );
  }, [activeVendor.defaultCarrierServiceId, selectedCarrier]);

  useEffect(() => {
    if (activeVendor.id !== null && firstInputElement.current !== null) {
      firstInputElement.current.focus();
    }
  }, [activeVendor.id]);

  useEffect(() => {
    // Fetch customFields and add them to state
    if (activeVendor.id === ActiveItemIdState.New) {
      (async () => {
        const customFields = (await fetchCustomFieldsAPI({}, ObjectType.Vendor))
          .data;
        if (oldState.current) {
          oldState.current.customFields = customFields;
        }
        setCustomFields(customFields);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeVendor.id]);

  const handleAddressApply = useCallback(
    (type: 'defaultBillTo' | 'defaultShipTo') => async (address: Address) => {
      setActiveVendor((old) => ({
        ...old,
        addresses: [
          ...old.addresses.map((a) => ({ ...a, [type]: false })),
          { ...address, id: findNextNegativeId(old.addresses), [type]: true },
        ],
      }));
    },
    [setActiveVendor]
  );

  const handlePaymentTermChange = useCallback(
    (paymentValue: PaymentTerm | null) => {
      setActiveVendor((old) => ({
        ...old,
        paymentTermId: paymentValue ? paymentValue.id : null,
      }));
    },
    [setActiveVendor]
  );

  const handleCurrencyChange = useCallback(
    (currency: Currency | null) => {
      setActiveVendor((old) => ({
        ...old,
        currencyId: currency ? currency.id : null,
        currency: currency,
        vendorItems: old.vendorItems.map((i) => {
          const exchangeRate = _.get(currency, 'exchangeRate', 1);
          return {
            ...i,
            multicurrencyVendorItemCost: (i.cost || 0) * (exchangeRate || 1),
          };
        }),
      }));
    },
    [setActiveVendor]
  );

  const handleCheckboxChange = useCallback(() => {
    setActiveVendor((old) => ({
      ...old,
      onlyShowVendorItemsOnPo: !old.onlyShowVendorItemsOnPo,
    }));
  }, [setActiveVendor]);

  const handleAutocompleteChange = useCallback(
    (name: string) => (_e: any, v: any) => {
      setActiveVendor((old) => ({ ...old, [name]: v ? v.id : null }));
    },
    [setActiveVendor]
  );

  const autocompleteLabelResolver = useCallback((option) => option.name, []);

  const handleVendorMinAmountChanged = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setActiveVendor((old) => ({
      ...old,
      vendorMin: e.target.value ? parseInt(e.target.value, 10) : null,
    }));
  };

  const handleTagsChange = useCallback(
    (values: Tag[]) => setActiveVendor((old) => ({ ...old, tags: values })),
    [setActiveVendor]
  );

  const customFieldChanged = useCallback(
    (customField: CustomField) => {
      const index = activeVendor.customFields.findIndex(
        (c) => c.id === customField.id
      );
      setCustomFields(
        (old) => replaceValueInCollection<CustomField>(old, customField, index)!
      );
    },
    [activeVendor.customFields, setCustomFields]
  );

  const handleDefaultAddress =
    (type: 'defaultBillTo' | 'defaultShipTo') =>
    (_e: any, address: Address | null) => {
      // if address is selected, we want to set it's default flag
      // otherwise all default flags are false
      setActiveVendor((old) => ({
        ...old,
        addresses: old.addresses.map((a) => ({
          ...a,
          [type]: address ? a.id === address.id : false,
        })),
      }));
    };

  const handleClassChange = (accountingClass: Class | null) => {
    setActiveVendor((old) => ({
      ...old,
      accountingClassId: accountingClass ? accountingClass.id : null,
    }));
  };

  const handleExchangeRateChange = useCallback(
    setActiveVendorCallback(setActiveVendor),
    [setActiveVendor]
  );

  const resetRate = useCallback(() => {
    setActiveVendor((old) => ({
      ...old,
      customExchangeRate: null,
    }));
  }, [setActiveVendor]);

  return (
    <>
      <Grid
        sx={{
          overflowY: 'scroll',
        }}
        container
        direction="row"
        alignItems="flex-start"
        rowSpacing={{ xs: 2 }}
        columnSpacing={{ xs: 2 }}
        padding="16px"
        disableEqualOverflow
      >
        <Grid container xs={12}>
          <Grid xs={4}>
            <Typography className={styleClasses.title}>Vendor</Typography>
          </Grid>
          <Grid xs={4}>
            <Typography className={styleClasses.title}>
              Contact Phone
            </Typography>
          </Grid>
          <Grid xs={4}>
            <Typography className={styleClasses.title}>
              Contact Email
            </Typography>
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid xs={4}>
            <TextField
              variant="standard"
              className="redesign"
              type="text"
              label="Name"
              placeholder="Enter vendor name"
              name="name"
              autoComplete="off"
              inputRef={firstInputElement}
              value={activeVendor.name}
              required
              permissions={editPermission}
              onChange={handleTextFieldChange}
              error={!!validationErrors.name}
              dataQa="vendor-name"
            />
          </Grid>
          <Grid xs={4}>
            <PhoneInputField
              className="redesign"
              label="Office"
              placeholder="Enter office phone number"
              name="office"
              autoComplete="off"
              fullWidth
              permissions={editPermission}
              value={activeVendor.office}
              onChange={handleTextFieldChange}
              data-qa="vendor-office"
            />
          </Grid>
          <Grid xs={4}>
            <TextField
              variant="standard"
              className="redesign"
              type="email"
              label="Email"
              placeholder="Enter email address"
              name="email"
              autoComplete="off"
              fullWidth
              permissions={editPermission}
              value={activeVendor.email}
              error={!!validationErrors.email}
              onChange={handleTextFieldChange}
              dataQa="vendor-email"
            />
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid xs={4}>
            <PaymentTermsAutocomplete
              onChange={handlePaymentTermChange}
              value={selectedPaymentTerm}
              label="Payment Term"
              placeholder="Select payment term"
              required
              permissions={editPermission}
              error={!!validationErrors.paymentTermId}
              dataQa="vendor-payment-term"
            />
          </Grid>
          <Grid xs={4}>
            <PhoneInputField
              className="redesign"
              label="Mobile"
              placeholder="Enter mobile number"
              name="mobile"
              autoComplete="off"
              fullWidth
              permissions={editPermission}
              value={activeVendor.mobile}
              onChange={handleTextFieldChange}
              data-qa="vendor-mobile"
            />
          </Grid>
          <Grid xs={4}>
            <TextField
              variant="standard"
              className="redesign"
              type="email"
              label="CC"
              placeholder="Enter CC"
              name="cc"
              autoComplete="off"
              fullWidth
              permissions={editPermission}
              error={!!validationErrors.cc}
              value={activeVendor.cc}
              onChange={handleTextFieldChange}
              dataQa="vendor-cc"
            />
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid xs={2}>
            <TextField
              variant="standard"
              className="redesign"
              type="text"
              label="Account Number"
              placeholder="Enter account number"
              name="acctNum"
              autoComplete="off"
              permissions={editPermission}
              value={activeVendor.acctNum}
              onChange={handleTextFieldChange}
              dataQa="vendor-account-number"
            />
          </Grid>
          <Grid xs={2}>
            <CurrencyField
              label="Vendor minimum"
              placeholder="Vendor Minimum"
              name="vendorMin"
              value={activeVendor.vendorMin}
              onChange={handleVendorMinAmountChanged}
              fullWidth
              permissions={[PermissionType.VendorsOverrideVendorMinimum]}
              allowNegative
              decimalPlaces={6}
            />
          </Grid>
          <Grid xs={4}>
            <PhoneInputField
              className="redesign"
              label="Fax"
              placeholder="Enter fax number"
              name="fax"
              autoComplete="off"
              fullWidth
              permissions={editPermission}
              value={activeVendor.fax}
              onChange={handleTextFieldChange}
              data-qa="vendor-fax"
            />
          </Grid>
          <Grid xs={4}>
            <TextField
              variant="standard"
              className="redesign"
              type="email"
              label="BCC"
              placeholder="Enter BCC"
              name="bcc"
              autoComplete="off"
              fullWidth
              permissions={editPermission}
              value={activeVendor.bcc}
              error={!!validationErrors.bcc}
              onChange={handleTextFieldChange}
              dataQa="vendor-bcc"
            />
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid xs={4}>
            <ClassAutocomplete
              label="Class"
              placeholder="Select Class"
              value={selectedClass}
              onChange={handleClassChange}
              permissions={editPermission}
            />
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  className="redesign"
                  checked={activeVendor.onlyShowVendorItemsOnPo!}
                  onChange={handleCheckboxChange}
                  color="primary"
                  inputProps={
                    {
                      'data-qa': 'vendor-show-vendor-items-on-purchase-order',
                    } as any
                  }
                  onKeyPress={handleEnterPress}
                />
              }
              disabled={!canClick}
              label="Show vendor items on purchase order"
            />
          </Grid>
        </Grid>
        <Grid
          container
          xs={12}
          borderBottom={1}
          borderColor={colorPalette.redesign.background3}
          padding="0px -16px 0px 0px"
          margin="16px 0px"
        />

        <Grid container xs={12}>
          {useMultiCurrencyEnabled && (
            <Grid xs={4}>
              <Typography className={styleClasses.title}>Currency</Typography>
            </Grid>
          )}
          <Grid xs={useMultiCurrencyEnabled ? 4 : 6}>
            <Typography className={styleClasses.title}>Address</Typography>
          </Grid>
          <Grid xs={useMultiCurrencyEnabled ? 4 : 6}>
            <Typography className={styleClasses.title}>Shipping</Typography>
          </Grid>
        </Grid>
        <Grid container xs={12}>
          {useMultiCurrencyEnabled && (
            <Grid xs={4}>
              <CurrencyAutocomplete
                label="Currency"
                placeholder="Select currency"
                value={activeVendor.currency}
                onChange={handleCurrencyChange}
                dataQa="vendor-currency"
              />
            </Grid>
          )}
          <Grid xs={useMultiCurrencyEnabled ? 4 : 6}>
            <AddressAutocomplete
              label="Default Billing Address"
              placeholder="Select default billing address"
              value={selectedBillToAddress}
              addresses={existingAddresses}
              onChange={handleDefaultAddress('defaultBillTo')}
              permissions={editPermission}
              modalApplyClicked={handleAddressApply('defaultBillTo')}
              dataQa="customer-default-billing-address"
            />
          </Grid>
          <Grid xs={useMultiCurrencyEnabled ? 4 : 6}>
            <Autocomplete
              label="Carrier"
              name="carrierId"
              value={selectedCarrier}
              placeholder="Select carrier"
              options={carriers}
              permissions={editPermission}
              getOptionLabel={autocompleteLabelResolver}
              onChange={handleAutocompleteChange('defaultCarrierId')}
              autoSelect={false}
              dataQa="vendor-carrier"
            />
          </Grid>
        </Grid>
        <Grid container xs={12}>
          {useMultiCurrencyEnabled && (
            <ExchangeRateInput
              handleExchangeRateChange={handleExchangeRateChange}
              styleClasses={styleClasses}
              resetRate={resetRate}
              exchangeRate={getExchangeRate(activeVendor)}
              resetDisabled={
                _.get(activeVendor, 'customExchangeRate', null) === null
              }
              disableDebounce
            />
          )}
          <Grid xs={useMultiCurrencyEnabled ? 4 : 6}>
            <AddressAutocomplete
              label="Default Shipping Address"
              placeholder="Select default shipping address"
              value={selectedShipToAddress}
              addresses={existingAddresses}
              permissions={editPermission}
              onChange={handleDefaultAddress('defaultShipTo')}
              modalApplyClicked={handleAddressApply('defaultShipTo')}
              dataQa="customer-default-billing-address"
            />
          </Grid>
          <Grid xs={useMultiCurrencyEnabled ? 4 : 6}>
            <Autocomplete
              label="Service"
              name="service"
              value={selectedService}
              placeholder="Select service"
              permissions={editPermission}
              options={
                selectedCarrier ? selectedCarrier.carrierServiceList : []
              }
              getOptionLabel={autocompleteLabelResolver}
              onChange={handleAutocompleteChange('defaultCarrierServiceId')}
              autoSelect={false}
              dataQa="vendor-service"
            />
          </Grid>
        </Grid>

        <Grid
          container
          xs={12}
          borderBottom={1}
          borderColor={colorPalette.redesign.background3}
          padding="0px -16px 0px 0px"
          margin="16px 0px"
        />

        <Grid container xs={12}>
          <Grid xs={12}>
            <Typography className={styleClasses.title}>
              Miscellaneous
            </Typography>
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid xs={6}>
            <TextField
              variant="standard"
              className="redesign"
              type="text"
              label="Other"
              placeholder="Other"
              name="other"
              permissions={editPermission}
              autoComplete="off"
              value={activeVendor.other}
              onChange={handleTextFieldChange}
              dataQa="vendor-other"
            />
          </Grid>
          <Grid xs={6}>
            <TextField
              variant="standard"
              className="redesign"
              type="text"
              label="URL"
              placeholder="Enter URL"
              name="url"
              permissions={editPermission}
              autoComplete="off"
              value={activeVendor.url}
              onChange={handleTextFieldChange}
              dataQa="vendor-url"
              error={!!validationErrors.url}
            />
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid xs={12}>
            <TextField
              variant="standard"
              className="redesign"
              type="text"
              label="Notes"
              placeholder="Enter notes"
              name="notes"
              multiline
              minRows="8"
              fullWidth
              permissions={editPermission}
              value={activeVendor.notes}
              onChange={handleTextFieldChange}
              dataQa="vendor-notes"
            />
          </Grid>
        </Grid>
        <Grid xs={12}>
          <TagsAsyncAutocomplete
            label="Tags"
            value={activeVendor.tags}
            permissions={editPermission}
            onChange={handleTagsChange}
            dataQa="vendor-tag"
          />
        </Grid>
        <FBOCustomFields
          customFields={activeVendor.customFields}
          onFieldChange={customFieldChanged}
          errors={customFieldsErrors}
          permissions={editPermission}
        />
      </Grid>
    </>
  );
};

export const setActiveVendorCallback =
  (setActiveVendor: React.Dispatch<React.SetStateAction<Vendor>>) =>
  (e: any) => {
    const value = e.target.value;
    setActiveVendor((old) => ({
      ...old,
      customExchangeRate: value === '' ? value : stringToDecimal(value),
    }));
  };

export const ExchangeRateInput = (props: ExchangeRateInputProps) => {
  const {
    handleExchangeRateChange,
    resetRate,
    exchangeRate,
    resetDisabled,
    disableDebounce,
  } = props;

  return (
    <>
      <Grid xs={3}>
        <TextField
          variant="standard"
          className="redesign"
          type="number"
          label="Rate"
          placeholder="Enter rate"
          name="rate"
          fullWidth
          value={exchangeRate}
          onChange={handleExchangeRateChange}
          disableDebounce={disableDebounce}
          dataQa="vendor-exchange-rate-input"
        />
      </Grid>
      <Grid xs={1}>
        <FBOButton
          sx={{ marginTop: '16px' }}
          variant="secondary"
          color="neutral"
          size="medium"
          onClick={resetRate}
          data-qa="vendor-exchange-rate-reset-button"
          disabled={resetDisabled}
        >
          Reset
        </FBOButton>
      </Grid>
    </>
  );
};

export default memo(FBOGeneralTab);
