import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { Page } from 'ui/components/Page/Page';

import { Routes } from '../../navigation';
import { useQboDisconnectedStyle } from './styled';
import { QboDisconnectedPageCmp } from './types';

const QboDisconnectedPage: QboDisconnectedPageCmp = () => {
  const classes = useQboDisconnectedStyle();
  return (
    <Page>
      <Box
        height="100vh"
        width="100vw"
        margin="auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography className={classes.qboTitle} variant="h1">
          QuickBooks Disconnected
        </Typography>
        <Typography variant="h4">
          Your QuickBooks integration has been disconnected. You will no longer
          be able to send invoices, bills, or inventory adjustments directly to
          your QuickBooks account from Fishbowl Online.
        </Typography>
        <Box mt={2} display="flex">
          <Typography variant="h4">To reconnect navigate to</Typography>
          <Link className={classes.link} to="/integrations/market">
            <Typography variant="h4">{'Integrations -> Market.'}</Typography>
          </Link>
        </Box>
      </Box>
    </Page>
  );
};

QboDisconnectedPage.route = Routes.QboDisconnect;

export default QboDisconnectedPage;
