import React, { memo } from 'react';
import { Box, Grid } from '@mui/material';
import { PermissionType } from 'services/permissions';
import { useHandleTextFieldChange } from 'services/forms';
import { TextField } from 'ui/components/TextField/TextField';
import { PermissionsGroupTabProps } from './types';

const PermissionsGroupTab: React.FC<PermissionsGroupTabProps> = (props) => {
  const { permissionGroup, setPermissionGroup, validation } = props;

  const canEditPermissions =
    permissionGroup.id && permissionGroup.id > 0
      ? [PermissionType.PermissionEdit]
      : [PermissionType.PermissionCreate];

  const handleTextFieldChange = useHandleTextFieldChange(
    setPermissionGroup,
    permissionGroup
  );

  return (
    <Box p={4}>
      <Grid container spacing={1}>
        <Grid item xs={5}>
          <TextField
            className={'redesign'}
            variant={'standard'}
            label="Name"
            placeholder="Enter name"
            name="name"
            permissions={canEditPermissions}
            onChange={handleTextFieldChange}
            value={permissionGroup.name}
            required
            error={!!validation.name}
            disabled={permissionGroup.readOnly}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(PermissionsGroupTab);
