import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Box } from '@mui/material';

import {
  ItemsTable,
  RenderCustomRowProps,
  useSelectedItemsChanges,
} from 'ui/components/Table/ItemsTable';
import { FBOButtonWithActions } from 'ui/components/Button/ButtonWithActions';
import { TextFieldQuantity } from 'ui/components/TextField/TextFieldQuantity';
import { getUoms, EACH_UOM_ID, Uom } from 'services/uoms';
import { getTaxRates } from 'services/taxRates';
import {
  SalesOrderItemTypes,
  SalesOrderItem,
  fulfillSalesOrderItem,
  SalesOrderStatus,
  newSalesOrderItem,
  SalesOrderBundleItem,
  SalesOrderItemStatus,
} from 'services/salesOrders';
import { SaleItem } from 'services/items/saleItems';
import { initialVendorItem, VendorItem } from 'services/vendors';
import {
  bundleItemHasSerialTracking,
  itemHasSerialTracking,
} from 'services/inventory';

import { activeUserHasPermission } from 'services/user/redux';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { showAlert } from 'services/alert/redux';
import { deleteEntityFromArray, replaceValueInCollection } from 'helpers';
import { createVendorItem, fetchSaleItem, ItemType } from 'services/items';
import SalesOrderItemsAsyncAutocomplete from 'ui/components/Autocomplete/ItemsAutocomplete/SalesOrderItemsAsyncAutocomplete';

import { BundleSubRow } from './components/BundleRow';
import {
  SalesOrderItemsCmp,
  SalesOrderItemsProps,
  ThreeDotMenuActions,
} from './types';
import { FBOTableFooter } from './components';
import { resolvedSoItemColumns, salesItemRenderMap } from './consts';
import {
  createSoItems,
  moveDownSoItem,
  moveUpSoItem,
  duplicateSoItem,
  duplicateSoItemToCreditReturn,
} from './transformations';
import { useSalesOrderItemsStyle } from './styled';
import { SalesOrderItemEditModal } from './components/SalesOrderItemEditModal';
import SubstituteItemsModal from './components/SubstituteItemsModal/SubstituteItemsModal';
import NoQuantityModalActions from './components/NoQuantityModalActions/NoQuantityModalActions';
import { editSalesOrderPermissions } from '../../helpers';
import {
  saleItemHasDefaultVendor,
  resolveSaleItemDefaultType,
  createOptionsForSalesOrderItems,
  resolveSalesOrderItemRowClasses,
} from './helpers';
import { VendorItemModal } from 'ui/components/Modal/VendorItemModal';
import { filterItemUoms } from 'services/items/helpers';
import { getSettingsCompany } from 'services/settings/company';
import { getSettingsSalesOrder } from 'services/settings/salesOrders';
import { logErrorCtx } from 'app/logging';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import { colorPalette } from 'ui/theme';

const RenderCustomRow: React.FC<
  RenderCustomRowProps<SalesOrderItem | SalesOrderBundleItem>
> = memo((props) => {
  const { row } = props;
  const ResolvedFunction = salesItemRenderMap[row.salesOrderItemType];
  return <ResolvedFunction {...(props as any)} />;
});

const SalesOrderItems: SalesOrderItemsCmp = (props: SalesOrderItemsProps) => {
  const {
    salesOrder,
    setSalesOrder,
    validationErrors,
    rowValidationErrors,
    setIsLoading,
    oldState,
  } = props;

  const dispatch = useDispatch();

  const classes = useSalesOrderItemsStyle();

  const { items: defaultUoms } = useSelector(getUoms);
  const { items: taxRates } = useSelector(getTaxRates);

  const { homeCurrency, useMultiCurrency } = useSelector(getSettingsCompany);
  const homeCurrencyCode = (homeCurrency && homeCurrency.code) || 'USD';
  const activeMulticurrencyCode = _.get(
    salesOrder,
    'customer.currency.code',
    null
  );

  const showMultiCurrency = useMemo(
    () =>
      useMultiCurrency &&
      activeMulticurrencyCode &&
      activeMulticurrencyCode !== homeCurrencyCode,
    [useMultiCurrency, activeMulticurrencyCode]
  );

  const [selectedSaleItem, setSelectedSaleItem] = useState<SaleItem | null>(
    null
  );

  const [noQuantitySaleItem, setNoQuantitySaleItem] = useState<SaleItem | null>(
    null
  );
  const [activeSalesOrderItem, setActiveSalesOrderItem] = useState<
    SalesOrderItem | SalesOrderBundleItem
  >(newSalesOrderItem(SalesOrderItemTypes.Sale));
  const [selectedAmount, setSelectedAmount] = useState<number | null>(null);
  const [selectedUomId, setSelectedUomId] = useState<number>(EACH_UOM_ID);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [showSalesOrderItemEditModal, setSalesOrderItemEditModal] =
    useState(false);
  const [showSubstituteItemsModal, setShowSubstituteItemsModal] =
    useState(false);
  const [quantityModalVisible, setQuantityModalVisible] =
    useState<boolean>(false);
  const [vendorItem, setVendorItem] = useState<VendorItem>(initialVendorItem);
  const [dropShipModal, setDropShipModal] = useState(false);
  const [vendorItemModalVisible, setVendorItemModalVisible] = useState(false);
  const [isVendorModalLoading, setIsVendorModalLoading] = useState(false);
  const [showSubstituteBtn, setShowSubstituteBtn] = useState(true);
  const [uoms, setUoms] = useState<Uom[]>([]);
  const [showNoQuantityModal, setShowNoQuantityModal] = useState(false);

  const inputElement = useRef<HTMLInputElement>(null);

  const disabled =
    salesOrder.status === SalesOrderStatus.Fulfilled ||
    salesOrder.status === SalesOrderStatus.Cancelled;

  const vendorItems: VendorItem[] = _.get(
    selectedSaleItem,
    'item.vendorItems',
    []
  );

  const defaultVendorItem = vendorItems.find((vi) => vi.defaultFlag);
  const minimumQuantity = defaultVendorItem
    ? defaultVendorItem.minimumQuantity
    : null;

  const editPermission = editSalesOrderPermissions(salesOrder);

  const canAddSoItems = useSelector(activeUserHasPermission(editPermission));
  const soSettings = useSelector(getSettingsSalesOrder);

  const selectedItemType = _.get(selectedSaleItem, 'item.itemType', null);

  const selectedTaxRate = useMemo(() => {
    const taxId =
      salesOrder.customer && salesOrder.customer.taxId
        ? salesOrder.customer.taxId
        : salesOrder.salesTaxId;
    return taxRates.find((r) => r.id === taxId) || null;
  }, [salesOrder.customer, salesOrder.salesTaxId, taxRates]);

  const substituteItems = useMemo(
    () =>
      noQuantitySaleItem
        ? noQuantitySaleItem.item
          ? noQuantitySaleItem.item.itemSubstitutes
          : []
        : [],
    [noQuantitySaleItem]
  );

  const isSerialTracked = useMemo(() => {
    if (selectedSaleItem?.item?.itemType === ItemType.Bundle) {
      return !bundleItemHasSerialTracking(selectedSaleItem.item.bundleItems);
    } else if (selectedSaleItem?.item) {
      return itemHasSerialTracking(selectedSaleItem.item);
    } else return false;
  }, [selectedSaleItem]);

  const lastNonDeletedSoItemIndex = useMemo(
    () => salesOrder.salesItems.filter((i) => !i.deleted).length - 1,
    [salesOrder.salesItems]
  );

  useEffect(() => {
    if (selectedSaleItem) {
      const itemUoms = filterItemUoms(selectedSaleItem.defaultUom, defaultUoms);
      if (itemUoms) {
        setUoms(itemUoms);
      }
      return;
    }

    setUoms([]);
  }, [selectedSaleItem, defaultUoms]);

  const handleDeleteClick = useCallback(() => {
    const newSoItems: (SalesOrderItem | SalesOrderBundleItem)[] =
      salesOrder.salesItems.map((item) => {
        if (
          selectedItems.includes(item.id! as number) &&
          item.status !== SalesOrderItemStatus.Fulfilled
        ) {
          return { ...item, deleted: true };
        }
        return item;
      });

    const newRemoved: (SalesOrderItem | SalesOrderBundleItem)[] =
      newSoItems.filter((item) => {
        return !((item.id || 0) < 0 && item.deleted);
      });

    setSalesOrder((old) => ({ ...old, salesItems: newRemoved }));
    setSelectedItems([]);
  }, [salesOrder.salesItems, setSalesOrder, selectedItems]);

  const deleteTableRowButton = useMemo(() => {
    const selectedItemsData: (SalesOrderItem | SalesOrderBundleItem)[] =
      salesOrder.salesItems.filter((item) => selectedItems.includes(item.id!));

    const fulfilledSelectedItems = selectedItemsData.every(
      (item) => item.status === SalesOrderItemStatus.Fulfilled
    );
    return selectedItemsData.length && !fulfilledSelectedItems
      ? [
          <FBOButton
            style={{ marginLeft: '8px' }}
            key={1}
            variant="secondary"
            color="negative"
            size="medium"
            icon="TrashCan"
            permissions={editPermission}
            onClick={handleDeleteClick}
            data-qa="sale-order-items-delete"
          >
            Delete
          </FBOButton>,
        ]
      : [];
  }, [selectedItems, handleDeleteClick, editPermission, salesOrder.salesItems]);

  const setSalesItems = useCallback(
    (
      salesItems: React.SetStateAction<
        (SalesOrderItem | SalesOrderBundleItem)[]
      >
    ) => {
      if (typeof salesItems === 'function') {
        setSalesOrder((s) => ({
          ...s,
          salesItems: salesItems(s.salesItems),
        }));
        return;
      }

      setSalesOrder((s) => ({
        ...s,
        salesItems: salesItems,
      }));
    },
    [setSalesOrder]
  );

  const handleEditModalSaveClicked = useCallback(() => {
    const index = salesOrder.salesItems.findIndex(
      (s) => s.id === activeSalesOrderItem.id
    );

    setSalesOrder((old) => ({
      ...old,
      salesItems: replaceValueInCollection(
        salesOrder.salesItems,
        activeSalesOrderItem,
        index
      )!,
    }));

    setSalesOrderItemEditModal(false);
  }, [activeSalesOrderItem, salesOrder, setSalesOrder]);

  const handleItemClick = useCallback(
    (id: number) => {
      const salesItem = salesOrder.salesItems.find((i) => i.id === id);

      if (
        !salesItem ||
        salesItem.salesOrderItemType === SalesOrderItemTypes.Note
      ) {
        return;
      }

      setActiveSalesOrderItem(salesItem);
      setSalesOrderItemEditModal(true);
    },
    [salesOrder.salesItems]
  );

  const handleSelectClick = useSelectedItemsChanges(
    selectedItems,
    setSelectedItems
  );

  const handleFocus = useCallback(() => {
    setTimeout(() => {
      if (inputElement.current !== null) {
        inputElement.current.focus();
      }
    }, 100);
  }, []);

  const handleAddItemClicked = useCallback(
    async (
      salesOrderItemType: SalesOrderItemTypes,
      saleItem?: SaleItem | null
    ) => {
      setIsLoading(true);
      const isBundle = selectedSaleItem
        ? selectedSaleItem.defaultSalesOrderItemType ===
            SalesOrderItemTypes.Bundle ||
          selectedSaleItem.defaultSalesOrderItemType ===
            SalesOrderItemTypes.BundleCreditReturn
        : false;

      if (
        !saleItemHasDefaultVendor(selectedSaleItem) &&
        salesOrderItemType === SalesOrderItemTypes.DropShip &&
        !saleItem
      ) {
        setDropShipModal(true);
        setIsLoading(false);
        return;
      }

      if (
        salesOrderItemType === SalesOrderItemTypes.DropShip &&
        minimumQuantity &&
        selectedAmount &&
        selectedAmount < minimumQuantity
      ) {
        setQuantityModalVisible(true);
        setIsLoading(false);
        return;
      }

      const newSoItems = await createSoItems(
        salesOrderItemType,
        salesOrder.exchangeRate,
        salesOrder.salesItems,
        saleItem || selectedSaleItem,
        selectedAmount,
        selectedUomId || EACH_UOM_ID,
        selectedTaxRate,
        salesOrder.customerId,
        uoms,
        false,
        isBundle,
        taxRates,
        salesOrder.customer ? salesOrder.customer.taxExempt : false,
        soSettings.priceIncludesTax ? soSettings.priceIncludesTax : false
      );

      setSalesOrder((old) => ({ ...old, salesItems: newSoItems }));
      setSelectedSaleItem(null);
      setSelectedAmount(null);
      setSelectedUomId(EACH_UOM_ID);
      handleFocus();

      setIsLoading(false);
    },
    [
      salesOrder.salesItems,
      salesOrder.exchangeRate,
      selectedTaxRate,
      selectedAmount,
      selectedSaleItem,
      selectedUomId,
      setSalesOrder,
      handleFocus,
      salesOrder.customerId,
      setIsLoading,
      uoms,
      taxRates,
      minimumQuantity,
      salesOrder.customer,
      soSettings.priceIncludesTax,
    ]
  );

  const fulfillItem = useCallback(
    async (soItem: SalesOrderItem) => {
      if (!salesOrder.id || !soItem.id) {
        return;
      }

      const resSalesOrder = await fulfillSalesOrderItem(
        salesOrder.id,
        soItem.id
      );
      oldState.current = resSalesOrder;
      setSalesOrder(resSalesOrder);
    },
    [setSalesOrder, oldState, salesOrder]
  );

  const rowActionClicked = useCallback(
    (data: {
      type: ThreeDotMenuActions;
      payload: SalesOrderItem | SalesOrderBundleItem;
    }) => {
      switch (data.type) {
        case ThreeDotMenuActions.Duplicate:
          setSalesItems(duplicateSoItem(data.payload, salesOrder.salesItems));
          break;
        case ThreeDotMenuActions.DuplicateToCreditReturn:
          setSalesItems(
            duplicateSoItemToCreditReturn(data.payload, salesOrder.salesItems)
          );
          break;
        case ThreeDotMenuActions.MoveDown: {
          setSalesItems(moveDownSoItem(data.payload, salesOrder.salesItems));
          break;
        }
        case ThreeDotMenuActions.MoveUp: {
          setSalesItems(moveUpSoItem(data.payload, salesOrder.salesItems));
          break;
        }
        case ThreeDotMenuActions.Remove: {
          const index = salesOrder.salesItems.findIndex(
            (item) => item.id === data.payload.id
          );
          setSalesItems(deleteEntityFromArray(salesOrder.salesItems, index));
          break;
        }
        case ThreeDotMenuActions.FulFill:
          fulfillItem(data.payload);
          break;
        case ThreeDotMenuActions.Edit:
          setActiveSalesOrderItem(data.payload);
          setSalesOrderItemEditModal(true);
          break;
      }
    },
    [setSalesItems, salesOrder.salesItems, fulfillItem]
  );

  const handleAutocompleteChange = useCallback(
    (value: SaleItem | null) => {
      const itemHasSubstitutes =
        _.get(value, 'item.itemSubstitutes.length', '0') > 0;

      if (!value) {
        setSelectedSaleItem(null);
        setSelectedAmount(null);
        setSelectedUomId(EACH_UOM_ID);
        return;
      }

      const itemLocationSummary = value.inventoryLocationSummaryList.find(
        (ils) => ils.rootLocationId === salesOrder.locationId
      );

      if (value.alertNotes) {
        dispatch(showAlert(value.alertNotes));
      }

      if (
        (itemLocationSummary && itemLocationSummary.availableQty > 0) ||
        !itemHasSubstitutes
      ) {
        setSelectedSaleItem(value);
        setSelectedAmount(1);
        setSelectedUomId(value.defaultUomId!);
      }

      if (!(itemLocationSummary && itemLocationSummary.availableQty > 0)) {
        if (
          itemHasSubstitutes ||
          (soSettings.checkForInventory && !itemHasSubstitutes)
        ) {
          setShowSubstituteBtn(itemHasSubstitutes);
          setNoQuantitySaleItem(value);
          setShowNoQuantityModal(true);
        }
      }
      const nonQuantityItemTypes = [
        ItemType.Service,
        ItemType.Shipping,
        ItemType.NonInventory,
      ];

      if (
        value.item?.itemType &&
        nonQuantityItemTypes.includes(value.item?.itemType)
      ) {
        setShowNoQuantityModal(false);
      }
    },
    [salesOrder, dispatch]
  );

  const handleNoQuantityModalSave = useCallback((value: SaleItem) => {
    setSelectedSaleItem(value);
    setSelectedAmount(1);
    setSelectedUomId(value.defaultUomId!);
    setNoQuantitySaleItem(null);
    setShowNoQuantityModal(false);
  }, []);

  const handleNoQuantityModalCancel = useCallback(() => {
    setNoQuantitySaleItem(null);
    setSelectedAmount(null);
    setSelectedUomId(EACH_UOM_ID);
    setShowNoQuantityModal(false);
  }, []);

  const handleOnUseSubstitute = useCallback(() => {
    setShowSubstituteItemsModal(true);
    setShowNoQuantityModal(false);
  }, []);

  const handleOnAddAnyway = useCallback(() => {
    handleNoQuantityModalSave(noQuantitySaleItem!);
  }, [noQuantitySaleItem]);

  const handleAmountTextChange = useCallback(
    (value: number | null) => {
      setSelectedAmount(value);
    },
    [setSelectedAmount]
  );

  const handleAmountMenuChange = useCallback(
    (nextUomId: number) => {
      setSelectedUomId(nextUomId);
    },
    [setSelectedUomId]
  );

  const handleCreateVendorItem = useCallback(async () => {
    let saleItem: SaleItem | null = null;
    setIsVendorModalLoading(true);
    try {
      await createVendorItem(vendorItem);

      //selectedSaleItem will always be here
      saleItem = await fetchSaleItem(selectedSaleItem!.id);
      await handleAddItemClicked(SalesOrderItemTypes.DropShip, saleItem);
    } catch (error) {
      logErrorCtx('Error in handleCreateVendorItem', {
        error: error as Error,
        stackTrace: (error as Error).stack,
        component: 'SalesOrderItems',
        title: 'Error in handleCreateVendorItem',
        description: `Sale item id ${selectedSaleItem?.id}`,
      });
    }

    setVendorItemModalVisible(false);
    setIsVendorModalLoading(false);
  }, [vendorItem, handleAddItemClicked, selectedSaleItem]);

  const handleDropShipModalConfirm = useCallback(() => {
    setVendorItemModalVisible(true);
    setDropShipModal(false);
    setVendorItem((old) => ({
      ...old,
      item: selectedSaleItem && selectedSaleItem.item,
      itemId: selectedSaleItem && selectedSaleItem.itemId,
    }));
  }, [selectedSaleItem]);

  const handleAutocompleteEnterClick = useCallback(() => {
    handleAddItemClicked(SalesOrderItemTypes.Sale);
  }, [handleAddItemClicked]);

  const handleSelectedSubstitute = async (saleItem: SaleItem) => {
    const itemLocationSummary = saleItem.inventoryLocationSummaryList.find(
      (ils) => ils.rootLocationId === salesOrder.locationId
    );

    if (
      soSettings.checkForInventory &&
      !(itemLocationSummary && itemLocationSummary.availableQty > 0)
    ) {
      setShowSubstituteBtn(false);
      setNoQuantitySaleItem(saleItem);
      setShowNoQuantityModal(true);
      return;
    }

    const newSoItems = await createSoItems(
      SalesOrderItemTypes.Sale,
      salesOrder.exchangeRate,
      salesOrder.salesItems,
      saleItem,
      selectedAmount,
      selectedUomId,
      selectedTaxRate,
      salesOrder.customerId,
      uoms
    );

    setSalesOrder((old) => ({ ...old, salesItems: newSoItems }));
  };

  const handleQuantityModalConfirm = async () => {
    handleQuantityModalAction(minimumQuantity);
  };

  const handleQuantityModalCancel = async () => {
    handleQuantityModalAction(selectedAmount);
  };

  const handleQuantityModalAction = async (quantity: number | null) => {
    setSelectedAmount(minimumQuantity);
    const isBundle = selectedSaleItem
      ? selectedSaleItem.defaultSalesOrderItemType ===
          SalesOrderItemTypes.Bundle ||
        selectedSaleItem.defaultSalesOrderItemType ===
          SalesOrderItemTypes.BundleCreditReturn
      : false;

    const newSoItems = await createSoItems(
      SalesOrderItemTypes.DropShip,
      salesOrder.exchangeRate,
      salesOrder.salesItems,
      selectedSaleItem,
      quantity,
      selectedUomId || EACH_UOM_ID,
      selectedTaxRate,
      salesOrder.customerId,
      uoms,
      false,
      isBundle,
      taxRates,
      salesOrder.customer ? salesOrder.customer.taxExempt : false,
      soSettings.priceIncludesTax ? soSettings.priceIncludesTax : false
    );

    setSalesOrder((old) => ({ ...old, salesItems: newSoItems }));
    setSelectedSaleItem(null);
    setSelectedAmount(null);
    setSelectedUomId(EACH_UOM_ID);
    handleFocus();
    setIsLoading(false);
    setQuantityModalVisible(false);
  };

  const NoQuantityDialogActions = () => (
    <NoQuantityModalActions
      showUseSubstitute={showSubstituteBtn}
      onCancel={handleNoQuantityModalCancel}
      onUseSubstitute={handleOnUseSubstitute}
      onAdd={handleOnAddAnyway}
    />
  );

  return (
    <Box
      display="flex"
      flexGrow={1}
      flexDirection="column"
      overflow={'hidden'}
      // TODO : RESTYLING : FBOTitleBar needs "no padding" prop for inner-placements, along with border-prop
      sx={{
        borderTop: `1px solid ${colorPalette.redesign.background3}`,
      }}
    >
      <Box paddingLeft={2} paddingRight={2}>
        <FBOTitleBar
          title="Items"
          beforeElements={deleteTableRowButton}
          noPadding
        >
          {salesOrder.status !== SalesOrderStatus.Fulfilled &&
            salesOrder.status !== SalesOrderStatus.Cancelled &&
            canAddSoItems && (
              <>
                <SalesOrderItemsAsyncAutocomplete
                  onChange={handleAutocompleteChange}
                  value={selectedSaleItem}
                  salesOrder={salesOrder}
                  additionalInputProps={{ inputRef: inputElement }}
                  onEnterClick={handleAutocompleteEnterClick}
                  dataQa="sale-order-item-picker"
                  disabled={!salesOrder.customerId || !salesOrder.locationId}
                  sx={{ marginLeft: '8px' }}
                />
                <TextFieldQuantity
                  placeholder="Quantity"
                  name="amount"
                  className={classes.amountInputOuter}
                  value={selectedAmount}
                  selectedUomId={selectedUomId}
                  onTextChange={handleAmountTextChange}
                  onMenuChange={handleAmountMenuChange}
                  additionalInputProps={{
                    classes: {
                      root: classes.amountInputInner,
                      notchedOutline: classes.noBorder,
                    },
                  }}
                  fullWidth={false}
                  isDecimal={!isSerialTracked}
                  uoms={uoms}
                  isUomSelectDisabled={selectedItemType === ItemType.Shipping}
                  dataQa="sale-order-item-quantity"
                  sx={{ marginRight: '8px' }}
                />
                <FBOButtonWithActions
                  buttonDisabled={!selectedSaleItem}
                  actionItems={createOptionsForSalesOrderItems(
                    selectedSaleItem,
                    handleAddItemClicked
                  )}
                  text="Add Item"
                  onClick={() =>
                    handleAddItemClicked(
                      resolveSaleItemDefaultType(selectedSaleItem)
                    )
                  }
                  dataQa="sale-order-item"
                />
              </>
            )}
        </FBOTitleBar>
      </Box>

      <ItemsTable
        data={salesOrder.salesItems}
        setData={setSalesItems}
        onAction={rowActionClicked}
        columns={resolvedSoItemColumns(
          showMultiCurrency,
          activeMulticurrencyCode,
          homeCurrencyCode
        )}
        onItemClick={handleItemClick}
        onSelectedChange={!disabled ? handleSelectClick : _.noop}
        selectedItems={selectedItems}
        RenderCustomRow={RenderCustomRow}
        RenderRowSubrows={BundleSubRow}
        filterRows={(item) => !item.deleted}
        rowErrors={rowValidationErrors}
        emptyTableText="ADD NEW ITEMS BY PRESSING 'ADD ITEM'"
        RenderCustomFooter={FBOTableFooter}
        footerData={{ validationErrors, salesOrder, oldState }}
        onFooterAction={{ setSalesOrder }}
        meta={{
          lastNonDeletedSoItemIndex,
          salesOrder,
          setIsLoading,
        }}
        dataQa="sale-order-item-table"
        getRowclsx={(row: SalesOrderItem | SalesOrderBundleItem) => ({
          [classes.creditReturn]: resolveSalesOrderItemRowClasses(row),
          [classes.dropShip]:
            row.salesOrderItemType === SalesOrderItemTypes.DropShip,
        })}
      />
      <SalesOrderItemEditModal
        activeSaleOrderItem={activeSalesOrderItem}
        setActiveSaleOrderItem={setActiveSalesOrderItem}
        salesOrder={salesOrder}
        show={showSalesOrderItemEditModal}
        salesOrderStatus={salesOrder.status}
        onSave={handleEditModalSaveClicked}
        onClose={() => setSalesOrderItemEditModal(false)}
      />

      <SubstituteItemsModal
        onClose={() => setShowSubstituteItemsModal(false)}
        show={showSubstituteItemsModal}
        substituteItems={substituteItems}
        onSelect={handleSelectedSubstitute}
        cancelModal={handleNoQuantityModalCancel}
        locationRootId={salesOrder.locationId}
      />

      <ConfirmationModal
        body={'Item has no available quantity'}
        title={'No Quantity'}
        onCancelClicked={handleNoQuantityModalCancel}
        onConfirmClicked={handleNoQuantityModalSave}
        open={showNoQuantityModal}
        DialogActionsComponent={NoQuantityDialogActions}
        maxWidth="sm"
      />
      <ConfirmationModal
        open={quantityModalVisible}
        onCancelClicked={handleQuantityModalCancel}
        onConfirmClicked={handleQuantityModalConfirm}
        cancelLabel="No"
        confirmLabel="Yes"
        title="Increase quantity?"
        body={`Vendor minimum is ${minimumQuantity} for this item. Would you like to increase the quantity to meet the minimum?`}
      />
      <ConfirmationModal
        open={dropShipModal}
        onCancelClicked={() => setDropShipModal(false)}
        onConfirmClicked={handleDropShipModalConfirm}
        cancelLabel="No"
        confirmLabel="Yes"
        title="Create Vendor Item"
        body={`${
          selectedSaleItem && selectedSaleItem.name
        } does not have an active vendor item. Would you like to create one?`}
      />
      <VendorItemModal
        show={vendorItemModalVisible}
        vendorItem={vendorItem}
        setVendorItem={setVendorItem}
        onSave={handleCreateVendorItem}
        onClose={() => {
          setVendorItemModalVisible(false);
          setVendorItem(initialVendorItem);
        }}
        isLoading={isVendorModalLoading}
      />
    </Box>
  );
};

export default memo(SalesOrderItems);
