import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { TableCell, TableRow, Checkbox } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { activeUserHasPermission } from 'services/user/redux';
import { PermissionType } from 'services/permissions';
import { ShipCarton, ShipItem, ShipStatus } from 'services/shipping';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import { ImageItemBox } from 'ui/components/Image/ImageItemBox';
import { convertUnits, getWeightUnitAbbreviation } from 'helpers';
import { showThumbnail } from 'services/thumbnail/redux';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { IconNames } from 'ui/theme';

const ShipItemRowSubrows: React.FC<RenderCustomRowProps<ShipCarton>> = (
  props
) => {
  const {
    row,
    meta: {
      showSplitModal,
      viewTracking,
      ship,
      onSelectedItems,
      selectedItems,
    },
  } = props;

  const dispatch = useDispatch();

  const handleOpenPreview = useCallback(
    (shipItem: ShipItem) => (e: any) => {
      const imageUrl = _.get(shipItem.item, 'defaultImage', '') as string;

      dispatch(showThumbnail(imageUrl));
      e.stopPropagation();
    },
    [dispatch]
  );

  const isItemSelected = useCallback(
    (shipItemId: number | null) => selectedItems.indexOf(shipItemId) !== -1,
    [selectedItems]
  );

  const canEditShippingRow = useSelector(
    activeUserHasPermission([PermissionType.ShippingEdit])
  );

  const disabledSplitCarton =
    !canEditShippingRow || ship.status !== ShipStatus.Open;

  const handleSplitClicked = useCallback(
    (shipId, cartonId) => {
      showSplitModal(shipId, cartonId);
    },
    [showSplitModal]
  );

  const handleSelectChange = useCallback(
    (shipItemId: number | null) => () => {
      onSelectedItems(shipItemId);
    },
    [onSelectedItems]
  );

  const handleViewTrackingClicked = useCallback(
    (shipItem: ShipItem) => (e: any) => {
      e.preventDefault();
      viewTracking(shipItem);
    },
    [viewTracking]
  );

  const shipItemList: ShipItem[] = useMemo(
    () =>
      ship &&
      ship.shipItemList.filter((i: ShipItem) => i.shipCartonId === row.id),
    [ship, row]
  );

  const resolveWeight = useCallback(
    (shipItem: ShipItem) => `${convertUnits(
      _.get(shipItem, 'salesOrderItem.saleItem.weight', 0),
      getWeightUnitAbbreviation(
        _.get(shipItem, 'salesOrderItem.saleItem.weightUnit', 'Pounds')
      ),
      getWeightUnitAbbreviation(row.weightUnit)
    )} ${row.weightUnit}
  `,
    [row]
  );

  return (
    <>
      {shipItemList.map((shipItem, key) => (
        <TableRow hover key={key}>
          <TableCell colSpan={2}>
            <Checkbox
              onClick={handleSelectChange(shipItem.id)}
              checked={isItemSelected(shipItem.id)}
            />
            {` ${shipItem.quantity} ${_.get(
              shipItem,
              'uom.abbreviation',
              null
            )}`}
          </TableCell>
          <TableCell colSpan={3}>
            <ImageItemBox
              name={_.get(shipItem.item, 'name', null)}
              description={_.get(shipItem.item, 'description', null)}
              source={_.get(
                shipItem,
                'salesOrderItem.saleItem.defaultImage',
                null
              )}
              onClick={handleOpenPreview(shipItem)}
            />
          </TableCell>
          <TableCell>{resolveWeight(shipItem)}</TableCell>
          <TableCell>
            {!_.isEmpty(shipItem.trackingGroups) && (
              <FBOButton
                variant="tertiary"
                color="neutral"
                size="medium"
                icon={IconNames.TrackingAim}
                onClick={handleViewTrackingClicked(shipItem)}
                data-qa="shop-item-row-subrows-track-changes-button"
              >
                View Tracking
              </FBOButton>
            )}
          </TableCell>
          <TableCell>
            <FBOButton
              variant="tertiary"
              color="neutral"
              size="large"
              icon={IconNames.IconSplit}
              disabled={disabledSplitCarton}
              onClick={() => handleSplitClicked(shipItem.id, row.id)}
              data-qa="shipping-item-move"
            />
          </TableCell>
          <TableCell />
        </TableRow>
      ))}
    </>
  );
};

export default React.memo(ShipItemRowSubrows);
