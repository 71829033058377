import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const columns: Column[] = [
  {
    title: 'Item',
    width: '20%',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'On Hand',
    type: ColumnTypes.renderByCustomRow,
    width: '8%',
  },
  {
    title: 'Allocated',
    type: ColumnTypes.renderByCustomRow,
    width: '8%',
  },
  {
    title: 'Available',
    type: ColumnTypes.renderByCustomRow,
    width: '8%',
  },
  {
    title: 'Incoming',
    type: ColumnTypes.renderByCustomRow,
    width: '8%',
  },
  {
    title: 'Min',
    type: ColumnTypes.renderByCustomRow,
    width: '8%',
  },
  {
    title: 'Max',
    type: ColumnTypes.renderByCustomRow,
    width: '8%',
  },
  {
    title: 'UOM',
    type: ColumnTypes.renderByCustomRow,
    width: '4%',
  },
  {
    title: 'To Order',
    type: ColumnTypes.renderByCustomRow,
    width: '12%',
  },
  {
    title: 'Vendor On Order',
    type: ColumnTypes.renderByCustomRow,
    width: '20%',
  },
];
