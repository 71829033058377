import React, { useState, memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';

import { DateRange } from 'materialui-daterange-picker';
import { AdvancedSearchFieldsCmpProps } from 'ui/components/Page/PageWithAdvancedSearch';
import {
  COUNTRIES,
  CountryAutocomplete,
  CountryType,
} from 'ui/components/Autocomplete/CountryAutocomplete';
import DateRangePicker from 'ui/components/TextField/DateRangePicker/DateRangePicker';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { getPaymentTerms, PaymentTerm } from 'services/paymentTerms';
import { Tag } from 'services/tags';
import { getRangeForRequest } from 'ui/components/TextField/DateRangePicker/helpers';
import { transformDateToMomentDate } from 'helpers';
import { TagsAsyncAutocomplete } from 'ui/components/Autocomplete/TagsAutocomplete';

const VendorsAdvancedSearch: React.FC<AdvancedSearchFieldsCmpProps> = (
  props
) => {
  const { setFormValues, formValues } = props;

  const { items: paymentTerms } = useSelector(getPaymentTerms);

  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);

  const tagsIds = useMemo(() => {
    return formValues['tags.id'] as number[];
  }, [formValues]);

  const selectedPaymentTerm = useMemo(() => {
    return paymentTerms.find((p) => p.id === formValues.paymentTermId) || null;
  }, [formValues.paymentTermId, paymentTerms]);

  const selectedCountry = useMemo(() => {
    return (
      COUNTRIES.find((c) => c.code === formValues['vendorAddresses.country']) ||
      null
    );
  }, [formValues]);

  const dateCreatedRange: DateRange | null = useMemo(() => {
    if (!formValues.dateCreatedFrom || !formValues.dateCreatedTo) {
      return null;
    }

    return {
      startDate: transformDateToMomentDate(
        formValues.dateCreatedFrom as string
      )!,
      endDate: transformDateToMomentDate(formValues.dateCreatedTo as string)!,
    };
  }, [formValues.dateCreatedFrom, formValues.dateCreatedTo]);

  const dateLastModifiedRange: DateRange | null = useMemo(() => {
    if (!formValues.dateLastModifiedFrom || !formValues.dateLastModifiedTo) {
      return null;
    }

    return {
      startDate: transformDateToMomentDate(
        formValues.dateLastModifiedFrom as string
      )!,
      endDate: transformDateToMomentDate(
        formValues.dateLastModifiedTo as string
      )!,
    };
  }, [formValues.dateLastModifiedFrom, formValues.dateLastModifiedTo]);

  const handleDateRangeChange = useCallback(
    (field: string) => (value: DateRange) => {
      const requestRange = getRangeForRequest(field, value);

      if (!requestRange) {
        return;
      }

      setFormValues({
        ...formValues,
        ...requestRange,
      });
    },
    [formValues, setFormValues]
  );

  const handleShowDeletedChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setFormValues({ ...formValues, deleted: checked ? 'true' : null });
    },
    [formValues, setFormValues]
  );

  const handlePaymentTermChange = useCallback(
    (e: React.ChangeEvent<{}>, value: PaymentTerm | null) => {
      setFormValues({ ...formValues, paymentTermId: value ? value.id : 0 });
    },
    [formValues, setFormValues]
  );

  const handleTagsChange = useCallback(
    (value: Tag[]) => {
      setSelectedTags(value);
      setFormValues({
        ...formValues,
        'tags.id': value.length ? value.map((i) => i.id!) : null,
      });
    },
    [formValues, setFormValues]
  );

  const handleCountryChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, value: CountryType | null) => {
      setFormValues({
        ...formValues,
        'vendorAddresses.country': _.get(value, 'code', ''),
      });
    },
    [formValues, setFormValues]
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Autocomplete
            label="Payment Term"
            placeholder="Select payment term"
            options={paymentTerms}
            getOptionLabel={(p) => p.name}
            value={selectedPaymentTerm}
            onChange={handlePaymentTermChange}
            dataQa="select-payment-term"
          />
        </Grid>
        <Grid item xs={6}>
          <TagsAsyncAutocomplete
            label="Tags"
            placeholder="Select tag"
            value={selectedTags}
            onChange={handleTagsChange}
            ids={tagsIds}
            disableTagsModal
            dataQa="select-tag"
          />
        </Grid>
        <Grid item xs={6}>
          <CountryAutocomplete
            value={selectedCountry}
            onChange={handleCountryChange}
            label="Country"
            placeholder="Select country"
            data-qa="select-country"
          />
        </Grid>
        <Grid item xs={6}>
          <Box>
            <DateRangePicker
              label="Date Created"
              placeholder="Select date created range"
              value={dateCreatedRange}
              onChange={handleDateRangeChange('dateCreated')}
              fullWidth
              dataQa="select-date-created-range"
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <DateRangePicker
              value={dateLastModifiedRange}
              label="Date Modified"
              placeholder="Select date modified range"
              onChange={handleDateRangeChange('dateLastModified')}
              dataQa="selected-date-modified-range"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!formValues.deleted}
                onChange={handleShowDeletedChange}
                color="primary"
                data-qa="show-deleted"
              />
            }
            label="Show Deleted"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default memo(VendorsAdvancedSearch);
