import React, { useMemo } from 'react';
import { TableCell } from '@mui/material';
import moment from 'moment';
import _ from 'lodash';

import { ImageItemBox } from 'ui/components/Image/ImageItemBox';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import { useCurrencyFormatter, useGetIntlDateFormatString } from 'helpers';
import { VendorItem } from 'services/vendors';
import { MultiCurrencyWrapper } from 'ui/components/MultiCurrencyWrapper/MultiCurrencyWrapper';

const ItemsTabCustomRow: React.FC<RenderCustomRowProps<VendorItem>> = (
  props
) => {
  const {
    row,
    meta: { activeMulticurrencyCode },
  } = props;

  const currencyFormatter = useCurrencyFormatter();
  const intlFormatDate = useGetIntlDateFormatString();

  const dateModified = useMemo(() => {
    if (row.dateLastModified) {
      return moment(row.dateLastModified).format(intlFormatDate);
    }
    return '';
  }, [row.dateLastModified, intlFormatDate]);

  const formatedMulticurrency = useMemo(
    () =>
      activeMulticurrencyCode &&
      currencyFormatter(
        row.multicurrencyVendorItemCost || 0,
        activeMulticurrencyCode
      ),
    [
      row.multicurrencyVendorItemCost,
      activeMulticurrencyCode,
      currencyFormatter,
    ]
  );

  return (
    <>
      <TableCell>
        <ImageItemBox
          name={_.get(row, 'item.name', null)}
          description={_.get(row, 'item.description', null)}
          source={_.get(row, 'item.defaultImage', null)}
        />
      </TableCell>
      <TableCell>{row.name || '-'}</TableCell>
      <TableCell>{currencyFormatter(row.cost || 0)}</TableCell>
      <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
        <TableCell>{formatedMulticurrency}</TableCell>
      </MultiCurrencyWrapper>
      <TableCell>{dateModified}</TableCell>
      <TableCell>{row.leadTime}</TableCell>
      <TableCell>{row.minimumQuantity}</TableCell>
    </>
  );
};

export default React.memo(ItemsTabCustomRow);
