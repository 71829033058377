export enum EventTypes {
  Add = 'Add',
  Remove = 'Remove',
  Move = 'Move',
  Cycle = 'Cycle',
  AdjustCost = 'AdjustCost',
  Ship = 'Ship',
  VoidShip = 'Void Ship',
  Receive = 'Receive',
  VoidReceive = 'Void Receive',
  Commit = 'Commit',
  VoidCommit = 'Void Commit',
  Finish = 'Finish',
  VoidFinish = 'Void Finish',
  AddTracking = 'AddTracking',
  RemoveTracking = 'RemoveTracking',
}
