import { createApiCall } from 'services/api';
import { transformCarrier, transformRequestCarrier } from './transformations';
import { Carrier } from './types';

export const fetchCarrier = async (id: number): Promise<Carrier> => {
  const response = await createApiCall(
    {
      path: `/v1/carriers/${id}?expand=carrierServices`,
      method: 'GET',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();
  return transformCarrier(response.data);
};

export const postCarrier = async (carrier: Carrier): Promise<Carrier> => {
  const response = await createApiCall(
    {
      path: '/v1/carriers?expand=carrierServices',
      method: 'POST',
      body: transformRequestCarrier(carrier),
    },
    {
      getMessage: {
        success: () => 'Carrier created',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformCarrier(response.data);
};

export const putCarrier = async (carrier: Carrier): Promise<Carrier> => {
  const response = await createApiCall(
    {
      path: '/v1/carriers?expand=carrierServices',
      method: 'PUT',
      body: transformRequestCarrier(carrier),
    },
    {
      getMessage: {
        success: () => 'Carrier updated',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformCarrier(response.data);
};

export const deleteCarrier = async (id: number): Promise<boolean> => {
  await createApiCall(
    {
      path: `/v1/carriers/${id}`,
      method: 'DELETE',
    },
    {
      getMessage: {
        success: () => 'Carrier deleted',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return true;
};

export const restoreCarrier = async (carrierId: number) => {
  await createApiCall(
    {
      path: `/v1/carriers/${carrierId}/restore`,
      method: 'POST',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
        success: () => 'Carrier successfully restored',
      },
    }
  )();
};
