import { Routes as RemoteSupportRoutes } from 'ui/modules/remotesupport/navigation';

export const calculateForwardUrl = (
  pathname: string,
  search: string
): string => {
  // if a user without remote support privileges accesses the page we get a 401 error that logs the user out so we do not add it to the forward ref
  if (pathname === RemoteSupportRoutes.RemoteSupportPage) {
    return '';
  }
  return `?forwardUrl=${pathname + search}`;
};
