import React, { memo, useCallback, useMemo } from 'react';
import { TableCell, InputAdornment, Tooltip, Box } from '@mui/material';
import _ from 'lodash';
import { Column } from 'ui/components/Table/ItemsTable/types';
import { TrackingType, TrackingDataTypes } from 'services/settings/tracking';
import { TextField } from 'ui/components/TextField/TextField';
import { DatePickerWrapper } from 'ui/components/TextField/DatePickerWrapper';
import { TrackingColumnsProps } from '../../types';
import { TextFieldQuantity } from 'ui/components/TextField/TextFieldQuantity';
import { EACH_UOM_ID } from 'services/uoms';
import { calculateSerialLinkLabel } from './helpers';
import { sortMultipleTrackingColumns } from 'ui/components/Table/TrackingTable/helpers';
import { IconNames } from 'ui/theme';
import { StatusLabel } from 'ui/components/Status/StatusLabel';
import { SerialStatusLabel } from 'services/inventory';
import { useTrackingCycleColumns } from './styled';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { colorPalette } from 'ui/theme/';

const TrackingCycleColumns: React.FC<TrackingColumnsProps> = (props) => {
  const {
    trackingGroup,
    onSerialLinkClick,
    createLotChangeHandler,
    createDateChangeHandler,
    columns,
    itemUom,
    isDecimal,
    formValues,
    onAmountChange,
    handleLotAutoAssign,
    handleDateAutoAssign,
    handleRemoveTrackingGroup,
    itemTrackingTypes,
    rowContainsDuplicates,
    canEditTracking,
    isSearchedRow,
  } = props;

  const classes = useTrackingCycleColumns();

  const dataQaString = `remove${trackingGroup?.trackingInfoList
    .sort((a, b) => {
      if (a.trackingTypeId > b.trackingTypeId) return -1;
      if (a.trackingTypeId < b.trackingTypeId) return 1;
      return 0;
    })
    .map((info) => `-${info.value}`)
    .join('')}`;

  const sortedColumns = useMemo(
    () => sortMultipleTrackingColumns(columns),
    [columns]
  );
  const committedGreaterThanQty = useMemo(() => {
    if (
      trackingGroup.quantity !== undefined &&
      trackingGroup.committedQuantity &&
      (trackingGroup.quantity ?? 0) < trackingGroup.committedQuantity
    ) {
      return true;
    }
    return false;
  }, [trackingGroup.committedQuantity, trackingGroup.quantity]);

  const hasNoSelectedLocation = useMemo(() => {
    if (formValues) {
      return !formValues.locationFromId || formValues.locationFromId < 0;
    }
    return false;
  }, [formValues]);

  const handleSerialListClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      onSerialLinkClick(e);
    },
    [onSerialLinkClick]
  );

  const renderAdornment = (
    adornmentHandler: any,
    nextValue: string | null,
    newTrackingType: boolean,
    column: Column
  ) => {
    const trackingDataType: TrackingDataTypes = _.get(
      column,
      'meta.trackingDataType'
    );
    const isDisabled = nextValue ? false : true;

    const title =
      trackingDataType === TrackingDataTypes.Date
        ? 'Populate with the next expiration date in the sequence.'
        : 'Populate with the next lot number in the sequence.';

    return (
      <InputAdornment position="end" style={{ marginRight: '0px' }}>
        <Tooltip title={title} placement="top">
          <FBOButton
            variant="tertiary"
            color="neutral"
            size="large"
            icon={IconNames.IconFlashFilledLightning}
            onClick={() => adornmentHandler()}
            disabled={isDisabled || hasNoSelectedLocation || newTrackingType}
            data-qa="tracking-cycle-columns-flash-lightning-button"
            style={{ color: colorPalette.redesign.contentSecondary }}
            sx={{ padding: '8px', minWidth: '0px' }}
          />
        </Tooltip>
      </InputAdornment>
    );
  };

  const renderTrackingColumn = (column: Column): JSX.Element | null => {
    const trackingType: TrackingType = _.get(column, 'meta.trackingType', {});
    const trackingTypeIndex = trackingGroup.trackingInfoList.findIndex(
      (t) => t.trackingTypeId === trackingType.id
    );
    const trackingInfo = trackingGroup.trackingInfoList[trackingTypeIndex];
    const trackingTypeId = trackingInfo?.trackingTypeId;

    const isTrackingInfoNew = trackingInfo && trackingInfo.id === -1;
    const quantity = trackingGroup.quantity || 0;
    const serialQuantity = trackingGroup.serialQuantity || quantity;
    const serialStatusLabel: SerialStatusLabel = calculateSerialLinkLabel(
      serialQuantity - trackingGroup.serialList.length,
      rowContainsDuplicates,
      trackingGroup.committedQuantity,
      committedGreaterThanQty
    );
    const itemTrackingType = itemTrackingTypes.find((t) =>
      t.trackingType ? t.trackingType.id === trackingType.id : null
    );

    const newItemTrackingType =
      !itemTrackingType || !itemTrackingType.id || itemTrackingType.id < 0;

    if (_.get(column, 'meta.trackingDataType') === TrackingDataTypes.Text) {
      if (isTrackingInfoNew && canEditTracking) {
        return (
          <TextField
            className="redesign no-margin"
            disabled={hasNoSelectedLocation}
            onChange={createLotChangeHandler(trackingTypeIndex)}
            value={trackingInfo?.value}
            placeholder="Add tracking"
            additionalInputProps={{
              endAdornment: itemTrackingType
                ? renderAdornment(
                    handleLotAutoAssign(
                      trackingTypeIndex,
                      itemTrackingType.id,
                      trackingTypeId
                    ),
                    itemTrackingType.nextValue,
                    newItemTrackingType,
                    column
                  )
                : null,
            }}
          />
        );
      } else if (!isTrackingInfoNew && canEditTracking) {
        return (
          <TextField
            onChange={createLotChangeHandler(trackingTypeIndex)}
            value={trackingInfo?.value}
          />
        );
      } else {
        return (
          <Tooltip title="Can't edit tracking fields if the quantity is committed">
            <TextField
              onChange={createLotChangeHandler(trackingTypeIndex)}
              value={trackingInfo?.value}
              readOnly
            />
          </Tooltip>
        );
      }
    } else if (
      _.get(column, 'meta.trackingDataType') === TrackingDataTypes.Date
    ) {
      if (isTrackingInfoNew && canEditTracking) {
        return (
          <DatePickerWrapper
            disabled={hasNoSelectedLocation || !canEditTracking}
            onChange={createDateChangeHandler(trackingTypeIndex)}
            value={trackingInfo?.value}
            InputProps={{
              endAdornment: itemTrackingType
                ? renderAdornment(
                    handleDateAutoAssign(
                      trackingTypeIndex,
                      itemTrackingType.id,
                      trackingTypeId
                    ),
                    itemTrackingType.nextValue,
                    newItemTrackingType,
                    column
                  )
                : null,
            }}
            InputAdornmentProps={{ position: 'start' }}
            fullWidth
          />
        );
      } else if (!isTrackingInfoNew && canEditTracking) {
        return (
          <DatePickerWrapper
            onChange={createDateChangeHandler(trackingTypeIndex)}
            value={trackingInfo?.value}
            fullWidth
          />
        );
      } else {
        return (
          <Tooltip title="Can't edit tracking fields if the quantity is committed">
            <TextField
              onChange={createLotChangeHandler(trackingTypeIndex)}
              value={trackingInfo?.value}
              readOnly
            />
          </Tooltip>
        );
      }
    } else if (
      _.get(column, 'meta.trackingDataType') === TrackingDataTypes.SerialNumber
    ) {
      return serialQuantity !== null ? (
        <Box className={classes.statusLabel}>
          <StatusLabel
            status={serialStatusLabel.status}
            label={serialStatusLabel.label}
            onClick={handleSerialListClick}
            combineStatusLabel
          />
          <FBOButton
            variant="tertiary"
            color="neutral"
            size="medium"
            icon={IconNames.FBOCaratRight}
            onClick={handleSerialListClick}
            data-qa="tracking-cycle-columns-carat-right-button"
            disabled={committedGreaterThanQty}
          />
        </Box>
      ) : null;
    } else if (column.title === 'Quantity') {
      return (
        <TextFieldQuantity
          disabled={hasNoSelectedLocation}
          selectedUomId={itemUom ? itemUom.id! : EACH_UOM_ID}
          uoms={itemUom ? [itemUom] : []}
          value={trackingGroup.quantity}
          onTextChange={onAmountChange}
          dataQa="tracking-table-quantity"
          isDecimal={isDecimal}
        />
      );
    } else if (column.title === 'Remove') {
      return canEditTracking ? (
        <FBOButton
          variant="tertiary"
          color="negative"
          size="small"
          icon={IconNames.TrashCan}
          disableRipple
          onClick={handleRemoveTrackingGroup}
          data-qa={dataQaString}
        />
      ) : (
        <></>
      );
    }
    return <></>;
  };

  return (
    <>
      {sortedColumns.map((column: Column, index: number) => (
        <TableCell
          sx={isSearchedRow ? { backgroundColor: '#C2DEFE' } : {}}
          key={index}
        >
          {renderTrackingColumn(column)}
        </TableCell>
      ))}
    </>
  );
};

export default memo(TrackingCycleColumns);
