import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useLocationOrderModalStyle = makeStyles((theme: Theme) => ({
  sidebar: {
    backgroundColor: '#EBEBEB',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  button: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    minWidth: 48,
    marginBottom: theme.spacing(1),
  },
  content: {
    overflow: 'auto',
    '& .locations-table .Mui-selected td': {
      fontWeight: 700,
    },
  },
}));
