import { Errors } from 'services/forms/validation';
import { Pick, PickItem } from 'services/picking';

export interface GeneralTabProps {
  activePick: Pick;
  setActivePick: React.Dispatch<React.SetStateAction<Pick>>;
  validationErrors: any;
  pickId: number | null;
  oldState: React.MutableRefObject<Pick | null>;
  fetchSearchResults(): void;
  customFieldsErrors: Errors;
  expand?: boolean;
}

export interface RowActionType {
  pickItem: PickItem;
  identifier: RowActionIdentifier;
}

export enum RowActionIdentifier {
  Split = 'split',
  Start = 'start',
  Commit = 'commit',
  Hold = 'hold',
  Finish = 'finish',
  Void = 'void',
  ShowTracking = 'showTracking',
}
