import React, { memo } from 'react';
import _ from 'lodash';
import { TableCell } from '@mui/material';

import { PurchaseOrder } from 'services/purchaseOrders';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import FBOStatusDot, {
  DefaultStatus,
} from 'ui/theme/components/FBOStatusDot/FBOStatusDot';

const PurchaseOrderRow: React.FC<RenderCustomRowProps<PurchaseOrder>> = (
  props
) => {
  const { row } = props;

  return (
    <>
      <TableCell>{_.get(row, 'number', '-')}</TableCell>
      <FBOStatusDot status={row.status || DefaultStatus.Transparent} />
      <TableCell>{_.get(row, 'vendor.name', '-')}</TableCell>
    </>
  );
};

export default memo(PurchaseOrderRow);
