import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import clsx from 'clsx';
import { matchPath } from 'react-router-dom';
import { Drawer, Collapse, Box, IconButton, List, Grid } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { getQuickbooksAccountIsConnected } from 'services/integrations/quickbooks';
import { isTestEnv } from 'services/api/testEnv';
import { getActiveUser } from 'services/user/redux';
import { hasPermission } from 'services/permissions';
import { getShippingIntegration } from 'services/integrations/shipping/redux';
import { getXeroAccountIsConnected } from 'services/integrations/xero';
import { getSettingsCompany } from 'services/settings/company/redux';

import { DRAWER_ITEMS } from './consts';
import { useAddDrawerFooterStyle, useAppDrawerStyle } from './styled';
export const sideDrawerStatus = React.createContext({ drawerExpanded: false });
import { getCommerceAccountIsOrWasConnected } from 'services/commerce';

import DrawerSubItem, { DrawerSubItemType } from './DrawerSubItem';
import DrawerItem, { DrawerItemType } from './DrawerItem';
import DrawerNav from './DrawerNav';
import { AccountCircle } from '@mui/icons-material';
import ProfileMenu from '../FBOProfileMenu/FBOProfileMenu';

import { ActiveUser } from 'services/user';
import { FBOLogoHorizontal, FBOLogoNoText } from 'ui/theme/images';
import FBOButton from '../FBOButton/FBOButton';
import { useFlags } from 'helpers/useFlags';

interface AppDrawerProps {
  drawerExpanded: boolean;
  drawerPersistent: boolean;
  onDrawerExpand(expanded: boolean, persistent: boolean): void;
  user?: ActiveUser;
  activePathName: string;
}

const FBODrawer: React.FunctionComponent<AppDrawerProps> = ({
  drawerExpanded,
  onDrawerExpand,
  drawerPersistent,
  activePathName,
}) => {
  const classes = useAppDrawerStyle();

  const quickbooksConnected = useSelector(getQuickbooksAccountIsConnected);
  const xeroConnected = useSelector(getXeroAccountIsConnected);
  const commerceConnected = useSelector(getCommerceAccountIsOrWasConnected);
  const connectedShippingChannels = useSelector(getShippingIntegration);
  const {
    permissions: userPermissions,
    isAdmin,
    isExecutor,
    user: activeUser,
  } = useSelector(getActiveUser);

  const hasConnectedShippingChannels = useMemo(
    () => connectedShippingChannels.connections.length > 0,
    [connectedShippingChannels]
  );

  const [expandedItem, setExpandedItem] = React.useState<number | null>(null);
  const [hoverExpanded, setHoverExpanded] = React.useState<boolean>(false);

  const { name: companyName, useMultiCurrency } =
    useSelector(getSettingsCompany);

  const [isProfileMenuOpen, setIsProfileMenuOpen] =
    React.useState<boolean>(false);

  const anchorEl = useRef<null | HTMLDivElement>(null);

  const flags = useFlags();
  const footerClasses = useAddDrawerFooterStyle({
    commerceIntegrationUiRefactor: flags.commerceIntegrationUiRefactor,
    drawerExpanded,
    isProfileMenuOpen,
  });

  const { topItems, bottomItems } = useMemo(() => {
    const items = DRAWER_ITEMS({
      quickbooksConnected,
      isTestEnv: isTestEnv(),
      hasConnectedShippingChannels,
      xeroConnected,
      commerceConnected,
      isMultiCurrencyVisible: useMultiCurrency,
      isCommerceRefactored: flags.commerceIntegrationUiRefactor,
    });

    return {
      topItems: items?.filter((i) => !i?.placement || i?.placement === 'top'),
      bottomItems: items?.filter((i) => i?.placement === 'bottom'),
    };
  }, [
    quickbooksConnected,
    hasConnectedShippingChannels,
    xeroConnected,
    flags.sellware,
    commerceConnected,
    useMultiCurrency,
    flags.commerceIntegrationUiRefactor,
  ]);

  useEffect(() => {
    const allItems = topItems.concat(bottomItems);
    // find index of active item
    allItems.forEach((value, index) => {
      value.subitems.forEach((subitem: DrawerSubItemType) => {
        if (subitem.route === activePathName) {
          setExpandedItem(index);
        }
      });
    });
  }, [activePathName, topItems, bottomItems]);

  const checkIfRouteIsActive = useCallback(
    (path: string | null) => {
      if (!path) {
        return false;
      }
      return matchPath(activePathName, path);
    },
    [activePathName]
  );

  const handleItemClicked = useCallback(
    (id: number) => () => {
      const newActiveItem: number | null = id === expandedItem ? null : id;
      setExpandedItem(newActiveItem);
    },
    [expandedItem]
  );

  const handleDrawerExpand = useCallback(
    (expanded: boolean, persistent: boolean) => {
      onDrawerExpand(expanded, persistent);
    },
    [onDrawerExpand]
  );

  let timeout: any = null;

  const handleMouseEnter = useCallback(
    (e: any) => {
      const el = document.getElementById('expand-toggle-button');
      const topOfExpandButton = el?.getBoundingClientRect()?.y;

      if (el && topOfExpandButton && e.clientY < topOfExpandButton) {
        timeout = setTimeout(() => {
          handleDrawerExpand(true, false);
          setHoverExpanded(true);
        }, 500);
      }
    },
    [handleDrawerExpand]
  );

  const handleMouseLeave = useCallback(() => {
    clearTimeout(timeout);
    handleDrawerExpand(false, false);
    setHoverExpanded(false);
  }, [handleDrawerExpand]);

  const drawerExpandedValue = useMemo(
    () => ({ drawerExpanded: drawerExpanded }),
    [drawerExpanded]
  );

  const renderDrawerItems = useCallback(
    (items: DrawerItemType[], offset?: number) => {
      const renderItems = () =>
        items.map((option, index) => {
          if (
            !hasPermission(option.permissions || [], userPermissions, isAdmin)
          ) {
            return null;
          }

          // only accessable to user with executor property
          if (option.onlyExecutor && !isExecutor) {
            return null;
          }

          const realIndex = _.isNil(offset) ? index : index + offset;

          return (
            <sideDrawerStatus.Provider
              value={drawerExpandedValue}
              key={option.text}
            >
              <DrawerItem
                key={option.text}
                text={option.text}
                icon={option.icon}
                to={option.route}
                visible={option.visible}
                itemExpanded={expandedItem === realIndex}
                active={!!checkIfRouteIsActive(option.route)}
                handleClick={handleItemClicked(realIndex)}
                dataQa={option.text}
                link={option.link}
              />
              <List
                component="div"
                className="sandbox-container"
                disablePadding
              >
                <Collapse
                  in={
                    (expandedItem === realIndex && drawerExpanded) ||
                    option.text === 'Sample Database'
                  }
                  timeout="auto"
                  key={option.text}
                  className={classes.expander}
                >
                  {option.subitems.map((subItem, index) => (
                    <DrawerSubItem
                      className="sub-item"
                      key={`${subItem.text}-${index}`}
                      text={subItem.text}
                      to={subItem.route}
                      visible={subItem.visible}
                      active={!!checkIfRouteIsActive(subItem.route)}
                      secondary
                      permissions={subItem.permissions}
                      dataQa={`${option.text}-${subItem.text}`}
                      component={subItem.component}
                    />
                  ))}
                </Collapse>
              </List>
            </sideDrawerStatus.Provider>
          );
        });

      return <List disablePadding>{renderItems()}</List>;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      userPermissions,
      isAdmin,
      isExecutor,
      checkIfRouteIsActive,
      expandedItem,
      handleItemClicked,
      drawerExpanded,
    ]
  );

  const handleIconButtonClick = useCallback(
    (e) => {
      e.stopPropagation();
      return hoverExpanded && drawerPersistent
        ? handleDrawerExpand(!drawerExpanded, true)
        : hoverExpanded && !drawerPersistent
        ? handleDrawerExpand(drawerExpanded, true)
        : handleDrawerExpand(!drawerExpanded, true);
    },
    [drawerExpanded, drawerPersistent, handleDrawerExpand, hoverExpanded]
  );

  const closeProfileMenu = (event: { currentTarget: any }, reason: string) => {
    if (reason === 'backdropClick' || event.currentTarget !== anchorEl) {
      setIsProfileMenuOpen(false);
    }
  };

  const openProfileMenu = (e: React.MouseEvent<HTMLElement | MouseEvent>) => {
    if (!isProfileMenuOpen && e.currentTarget === anchorEl.current) {
      setIsProfileMenuOpen(!isProfileMenuOpen);
    }
  };

  return (
    <DrawerNav persisted={drawerPersistent}>
      <Drawer
        variant="permanent"
        anchor="left"
        className={clsx('redesign', {
          drawerOpen: drawerExpanded,
          drawerClose: !drawerExpanded,
        })}
        classes={{
          paper: clsx(classes.drawer, {
            [classes.drawerOpen]: drawerExpanded,
            [classes.drawerClose]: !drawerExpanded,
          }),
        }}
        open={drawerExpanded}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Box
          sx={{
            scrollbarWidth: 'thin',
            marginRight: '-2px',
            padding: '8px 10px 0 8px',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: '1',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          {drawerExpanded ? (
            <img
              src={FBOLogoHorizontal}
              alt="FishBowl"
              width="175px"
              style={{
                maxWidth: '163px',
                margin: '16px 4px 24px 0px',
                alignSelf: 'center',
              }}
              data-qa={'fbo-logo-horizontal'}
            />
          ) : (
            <img
              src={FBOLogoNoText}
              alt="FishBowl"
              style={{
                maxWidth: '56px',
                margin: '16px 0px 24px 0px',
                display: 'flex',
                alignSelf: 'center',
              }}
              data-qa={'fbo-logo-no-text'}
            />
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
            }}
          >
            {renderDrawerItems(topItems)}
            {renderDrawerItems(bottomItems, topItems.length)}
          </Box>
        </Box>

        <Box className={footerClasses.drawerFooter}>
          {flags.commerceIntegrationUiRefactor && commerceConnected && (
            <FBOButton
              variant="primary"
              color="neutral"
              href={process.env.REACT_APP_SELLWARE_URL}
              className={footerClasses.goToCommerceButton}
              data-qa="go-to-commerce"
            >
              Go To Commerce
            </FBOButton>
          )}
          <Box className={footerClasses.profileAndExpandButtonContainer}>
            <Box
              ref={anchorEl}
              className={clsx(footerClasses.profile, {
                active: isProfileMenuOpen,
              })}
              onClick={openProfileMenu}
            >
              <IconButton
                edge="end"
                className={footerClasses.avatar}
                aria-label="open profile"
                aria-haspopup="true"
                color="inherit"
                data-qa="open-profile-icon"
                size="large"
                disableRipple
              >
                <AccountCircle />
              </IconButton>
              <Grid container xs={12} className={footerClasses.names}>
                <Grid item xs={12} className="name user-name">
                  {activeUser?.firstName} {activeUser?.lastName}
                </Grid>
                <Grid item xs={12} className="name company-name">
                  {companyName}
                </Grid>
              </Grid>
              <ProfileMenu
                anchorEl={anchorEl.current}
                isMenuOpen={isProfileMenuOpen}
                onClose={closeProfileMenu}
              />
            </Box>
            <IconButton
              id="expand-toggle-button"
              aria-label="open drawer"
              onClick={handleIconButtonClick}
              className={footerClasses.expandIcon}
              size="large"
              disableRipple
            >
              {drawerPersistent ? (
                <ChevronLeftIcon />
              ) : drawerExpanded && !hoverExpanded ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </Box>
        </Box>
      </Drawer>
    </DrawerNav>
  );
};

export default React.memo(FBODrawer);
