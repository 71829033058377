import React, { memo, useState, useMemo, useCallback } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import _ from 'lodash';

import { Modal } from 'ui/components/Modal/Modal';
import { TabPanel } from 'ui/components/TabPanel';
import { ReceivingTabReceiving, ReceivingTabOrders } from './components';
import { PermissionType } from 'services/permissions';

import { ReceivingItemModalProps } from './types';
import { RECEIVING_ITEM_MODAL_HEIGHT } from './consts';
import { ReceiptItemStatus } from 'services/receiving';

const ReceivingItemModal: React.FC<ReceivingItemModalProps> = (props) => {
  const {
    activeReceiptItem,
    setActiveReceiptItem,
    receipt,
    show,
    onClose,
    onSave,
    errors,
  } = props;

  const [activeTab, setActiveTab] = useState(0);

  const oldReceiptIten = useMemo(
    () => activeReceiptItem,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeReceiptItem.id]
  );

  const handleActiveTabChange = (
    e: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setActiveTab(newValue);
  };

  const handleResetClicked = useCallback(() => {
    setActiveReceiptItem(oldReceiptIten);
  }, [oldReceiptIten, setActiveReceiptItem]);

  const renderNavigationModal = () => (
    <Tabs
      value={activeTab}
      onChange={handleActiveTabChange}
      indicatorColor="primary"
    >
      <Tab label="Receiving" />
      <Tab label="Orders" />
    </Tabs>
  );

  return (
    <Modal
      open={show}
      onCancelClicked={onClose}
      onApplyClicked={onSave}
      onResetClicked={handleResetClicked}
      resetButtonVisible={
        activeReceiptItem.status !== ReceiptItemStatus.Fulfilled
      }
      renderNavigation={renderNavigationModal}
      applyDisabled={activeReceiptItem.status === ReceiptItemStatus.Fulfilled}
      status={activeReceiptItem ? activeReceiptItem.status! : undefined}
      title={_.get(activeReceiptItem, 'item.name', '')}
      applyLabel="save"
      withoutDefaultPadding
      customHeight={RECEIVING_ITEM_MODAL_HEIGHT}
      dataQa="receiving-item-modal"
      permissions={[PermissionType.ReceivingEdit]}
    >
      <Box>
        <TabPanel value={activeTab} index={0} noSpacing overflow>
          <ReceivingTabReceiving
            receipt={receipt}
            activeReceiptItem={activeReceiptItem}
            setActiveReceiptItem={setActiveReceiptItem}
            errors={errors}
          />
        </TabPanel>

        <TabPanel value={activeTab} index={1} noSpacing overflow>
          <ReceivingTabOrders activeReceiptItem={activeReceiptItem} />
        </TabPanel>
      </Box>
    </Modal>
  );
};

export default memo(ReceivingItemModal);
