import React from 'react';
import { Box, Grid } from '@mui/material';

import { CurrencyField } from 'ui/components/TextField/CurrencyField';
import { TextField } from 'ui/components/TextField/TextField';
import { useHandleTextFieldChange } from 'services/forms';

import { PaymentTabCheckProps } from './types';
import { PaymentFormValuesCheck } from '../../types';

const PaymentTabCheck = (props: PaymentTabCheckProps) => {
  const {
    formValues,
    setFormValues,
    paymentAmount,
    setPaymentAmount,
    validationErrors,
  } = props;

  const handleTextInputChanged =
    useHandleTextFieldChange<PaymentFormValuesCheck>(setFormValues, formValues);

  const handlePaymentAmountChanged = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPaymentAmount(parseFloat(e.target.value));
  };

  return (
    <Box p={3} width="100%">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CurrencyField
            label="Payment Amount"
            placeholder="Enter amount"
            name="paymentAmount"
            fullWidth
            value={paymentAmount}
            required
            onChange={handlePaymentAmountChanged}
            error={!!validationErrors.paymentAmount}
            dataQa="soPayments-checkTab-paymentAmount"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="Reference number"
            placeholder="Enter reference"
            name="referenceNumber"
            fullWidth
            value={formValues.referenceNumber}
            required
            onChange={handleTextInputChanged}
            error={!!validationErrors.referenceNumber}
            dataQa="soPayments-checkTab-referenceNumber"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(PaymentTabCheck);
