import { createApiAction } from 'services/api';
import {
  StartAction,
  SuccessAction,
  FailureAction,
} from 'services/api/redux/types';
import {
  createAddSearch,
  createDeleteSearch,
  createFetchSearch,
  createUpdateSearch,
} from 'services/search';

export enum ClassesActionTypes {
  FETCH_CLASSES_START = 'classes/fetch_classes_start',
  FETCH_CLASSES_SUCCESS = 'classes/fetch_classes_success',
  FETCH_CLASSES_FAILURE = 'classes/fetch_classes_failure',
}

const fetchClassesActionStart = (): StartAction => ({
  type: ClassesActionTypes.FETCH_CLASSES_START,
  payload: null,
});

const fetchClassesActionSuccess = (payload: any): SuccessAction => {
  return {
    type: ClassesActionTypes.FETCH_CLASSES_SUCCESS,
    payload,
  };
};

const fetchClassesActionFailure = (error: string): FailureAction => ({
  type: ClassesActionTypes.FETCH_CLASSES_FAILURE,
  payload: { error },
});

export const fetchClasses = () =>
  createApiAction(
    {
      path: '/v1/accounting_class?pageNumber=1&pageSize=100',
      method: 'GET',
    },
    fetchClassesActionStart,
    fetchClassesActionSuccess,
    fetchClassesActionFailure
  );

// SEARCH ACTION CREATORS

export const fetchSearch = createFetchSearch('classes');
export const addSearch = createAddSearch('classes');
export const updateSearch = createUpdateSearch('classes');
export const deleteSearch = createDeleteSearch('classes');
