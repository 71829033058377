import React, { useEffect, useState } from 'react';
import {
  Box,
  InputAdornment,
  Grid,
  FormControlLabel,
  Checkbox,
  Chip,
  IconButton,
} from '@mui/material';
import clsx from 'clsx';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import TuneIcon from '@mui/icons-material/Tune';
import _ from 'lodash';
import { Icon } from 'ui/components/Icon';

import { TextField } from 'ui/components/TextField/TextField';
import { Modal } from 'ui/components/Modal/Modal';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { colorPalette } from 'ui/theme';
import { AdvancedSearchItem } from 'services/search';

import { useSearchHeaderStyle, SearchInput } from './styled';

import { IconNames, icons } from 'ui/theme/icons';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { View } from 'ui/components/Page/PageWithAdvancedSearch';
import FBOSearchHeaderAutoComplete from './components/FBOSeachHeaderAutocomplete/FBOSearchHeaderAutoComplete';

export interface FBOSearchHeaderProps {
  advancedSearches: AdvancedSearchItem[];
  activeAdvancedSearch: AdvancedSearchItem;
  onQuickSearchChanged: (value: string) => void;
  onActiveAdvancedSearchChanged: (id: number) => void;
  onResetClicked: () => void;
  onApplyClicked: () => void;
  onCancelClicked: () => void;
  onChipDeleteClicked: (fieldName: string) => void;
  onSaveSearchClicked: (params: SaveSearchParams) => Promise<void>;
  onQuickSearchDeleteClicked: () => void;
  onDeleteSearchClicked: (id: number) => Promise<void>;
  displayNameMap?: { [columnKey: string]: string };
  displayValueMap?: { [columnKey: string]: (value: any) => any };
  children: JSX.Element;
  showAllLabel?: string;
  filterModalTitle?: string;
}

export interface SaveSearchParams {
  searchName: string;
  isDefault: boolean;
  quickSearchValue?: string;
}

const FBOSearchHeader: React.FC<FBOSearchHeaderProps> = (props) => {
  const {
    advancedSearches,
    activeAdvancedSearch,
    onQuickSearchChanged,
    displayValueMap,
    onActiveAdvancedSearchChanged,
    onApplyClicked,
    onCancelClicked,
    onResetClicked,
    onChipDeleteClicked,
    onQuickSearchDeleteClicked,
    onSaveSearchClicked,
    onDeleteSearchClicked,
    filterModalTitle,
    children,
  } = props;

  const classes = useSearchHeaderStyle(props);

  // Active advanced search refreshed on every render
  const activeSearchId = _.get(activeAdvancedSearch, 'id', -1);
  const activeSearchName = _.get(activeAdvancedSearch, 'searchName', '');
  const isActiveSearchDefault = _.get(activeAdvancedSearch, 'isDefault', false);
  const activeQuickSearchValue = _.get(
    activeAdvancedSearch,
    'quickSearchValue',
    undefined
  );

  // State for modals and spinners
  const [advancedModalVisible, setAdvancedModalVisible] = useState(false);
  const [saveModalVisible, setSaveModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [overwriteDefaultModalVisible, setOverwriteDefaultModalVisible] =
    useState(false);

  const [isSaveSearchLoading, setIsSaveSearchLoading] = useState(false);
  const [nameError, setNameError] = useState<string | null>(null);

  // State for form values
  const [formSearchName, setFormSearchName] = useState(activeSearchName);
  const [isFormDefault, setIsFormDefault] = useState(isActiveSearchDefault);
  const [idToDelete, setIdToDelete] = useState<number>(-1);
  const [quickSearchValue, setQuickSearchValue] = useState<undefined | string>(
    undefined
  );

  // When advanced search id, update state for form values
  useEffect(() => {
    setFormSearchName(activeSearchName);
    setIsFormDefault(isActiveSearchDefault);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSearchId]);

  const handleAdvancedSearchClicked = () => {
    setAdvancedModalVisible(!advancedModalVisible);
  };

  const handleResetClicked = () => {
    if (onResetClicked) {
      onResetClicked();
    }
  };

  const handleCancelClicked = () => {
    onCancelClicked();
    setAdvancedModalVisible(false);
  };

  const handleApplyClicked = () => {
    onApplyClicked();
    setAdvancedModalVisible(false);
  };

  const handleChipDelete = (fieldName: string) => {
    onChipDeleteClicked(fieldName);
  };

  const handleQuickSearchChipDelete = () => {
    onQuickSearchDeleteClicked();
  };

  const handleViewSelected = (e: any, view: View) => {
    if (view && view.id !== activeSearchId) {
      setQuickSearchValue('');
      onActiveAdvancedSearchChanged(view.id);
    } else {
      onActiveAdvancedSearchChanged(-1);
    }
  };

  const handleSaveClicked = () => {
    setSaveModalVisible(true);
  };

  const handleQuickSearchChanged = (e: any) => {
    const value = e.target.value;
    setQuickSearchValue(value);
    onQuickSearchChanged(value);
  };

  const handleNameChanged = (e: any) => {
    setFormSearchName(e.target.value);
  };

  const handleDefaultClicked = () => {
    setIsFormDefault(!isFormDefault);
  };

  const handleCancelSaveSearchClicked = () => {
    setSaveModalVisible(false);
    setFormSearchName('');
    setNameError(null);
  };

  const handleSaveSearchClicked = async () => {
    const hasSameSearchName = advancedSearches.some(
      (i) => i.searchName === formSearchName
    );
    if (hasSameSearchName) {
      setNameError('Search name already exist');
      return;
    }
    if (!formSearchName) {
      setNameError('Please enter search name');
      return;
    }
    if (isFormDefault && advancedSearches.some((i) => i.isDefault)) {
      setOverwriteDefaultModalVisible(true);
    } else {
      await handleSaveConfirmed();
    }
  };

  const handleSaveConfirmed = async () => {
    setIsSaveSearchLoading(true);
    await onSaveSearchClicked({
      searchName: formSearchName,
      isDefault: isFormDefault,
      quickSearchValue,
    });
    setSaveModalVisible(false);
    setOverwriteDefaultModalVisible(false);
    setIsSaveSearchLoading(false);
    setFormSearchName('');
    setNameError(null);
  };

  const handleSaveCanceled = () => {
    setOverwriteDefaultModalVisible(false);
  };

  const handleDeleteIconClicked = (id: number) => {
    setIdToDelete(id);
    setDeleteModalVisible(true);
  };

  const handleDeleteSearchConfirm = async () => {
    await onDeleteSearchClicked(idToDelete);
    setDeleteModalVisible(false);
    setIdToDelete(-1);
  };

  const handleDeleteSearchCancel = () => {
    setDeleteModalVisible(false);
  };

  const renderViewOption = (props: any, option: AdvancedSearchItem) => {
    return (
      <li {...props}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          {option.searchName} {option.isDefault ? `(Default)` : ''}
          <Icon
            name={IconNames.TrashCan}
            height="12px"
            width="12px"
            color={colorPalette.redesign.lightRedPrimary}
            onClick={() => handleDeleteIconClicked(option.id)}
            data-qa={`data-qa-delete-${option.searchName}-view`}
          />
        </Box>
      </li>
    );
  };

  const renderChip = (
    value:
      | string
      | number
      | null
      | Date
      | string[]
      | boolean
      | number[]
      | undefined,
    fieldName: string
  ) => {
    if (!value) {
      return;
    }

    const displayValue =
      displayValueMap && displayValueMap[fieldName]
        ? displayValueMap[fieldName](value)
        : value;

    return (
      <Chip
        key={fieldName}
        onDelete={() => handleChipDelete(fieldName)}
        deleteIcon={
          <Icon
            name={IconNames.FBOCloseSmall}
            fill={colorPalette.redesign.contentTertiary}
            data-qa="advanced-search-chip-close-quickSearchValue"
            height="12px"
            width="12px"
          />
        }
        icon={
          <TuneIcon
            sx={{
              fill: `${colorPalette.redesign.blueSecondary}`,
              fontSize: '16px',
            }}
          />
        }
        label={displayValue}
        variant="outlined"
        className={classes.chip}
        data-qa={`advanced-search-chip-${displayValue}-${fieldName}`}
        sx={{
          backgroundColor: `${colorPalette.redesign.background2}`,
          color: `${colorPalette.redesign.blueSecondary}`,
          fontWeight: '600',
          border: 'none',
          maxWidth: '80%',
          padding: '4px',
          height: 'auto',
          '& .MuiChip-label': {
            padding: '0',
            margin: '0 16px 0 14px',
          },
        }}
      />
    );
  };

  const renderQuickSearch = () => {
    return (
      <SearchInput
        type="text"
        placeholder="Search"
        className={classes.search}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {<icons.MagnifyingGlass />}
            </InputAdornment>
          ),
        }}
        onChange={handleQuickSearchChanged}
        dataQa="quick-search-input"
      />
    );
  };

  if (!activeAdvancedSearch) {
    return <Box mb={2}>{renderQuickSearch()}</Box>;
  }

  return (
    <Box
      sx={{
        backgroundColor: '#FFF',
        padding: '16px',
        borderRadius: '10px',
        marginBottom: '8px',
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexGrow={1}
        >
          <SearchInput
            id="search-input"
            type="text"
            placeholder="Search"
            inputProps={{
              'data-qa': 'quick-search-input',
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  className={clsx({
                    redesign: true,
                    search: true,
                  })}
                >
                  <icons.MagnifyingGlass />
                </InputAdornment>
              ),
              endAdornment: quickSearchValue ? (
                <InputAdornment position="end">
                  <CloseIcon
                    className={classes.clearInput}
                    fontSize="small"
                    onClick={() =>
                      handleQuickSearchChanged({ target: { value: '' } })
                    }
                  />
                </InputAdornment>
              ) : (
                <></>
              ),
            }}
            onChange={handleQuickSearchChanged}
            value={quickSearchValue}
          />
          <IconButton
            className={clsx({
              redesign: true,
              search: true,
            })}
            onClick={handleAdvancedSearchClicked}
            data-qa="add-filter-button"
            disableTouchRipple
          >
            {<icons.Tune />}
          </IconButton>
        </Box>
        <Box
          className={classes.autocompleteViews}
          display="flex"
          alignItems="center"
        >
          <FBOSearchHeaderAutoComplete
            aria-label="Saved Views"
            label="Saved Views"
            name="saved views"
            value={activeAdvancedSearch || null}
            placeholder="Select View"
            options={advancedSearches}
            getOptionLabel={(option: any) => option.searchName || ''}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={handleViewSelected}
            dataQa="saved-views-auto-complete"
            renderOption={renderViewOption}
            selectOnFocus
            autoSelect={false}
            sx={{ width: '280px' }}
          />
        </Box>
      </Box>
      {(activeQuickSearchValue ||
        !_.isEmpty(_.pickBy(activeAdvancedSearch.columns, (val) => !!val))) && (
        <Box
          mt={'8px'}
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            alignItems="center"
            sx={{
              minHeight: 40,
              flexWrap: 'wrap',
              width: '100%',
            }}
          >
            {activeQuickSearchValue && (
              <Chip
                key={'quickSearchValue'}
                onDelete={handleQuickSearchChipDelete}
                deleteIcon={
                  <Icon
                    name={IconNames.FBOCloseSmall}
                    fill={colorPalette.redesign.contentTertiary}
                    data-qa="advanced-search-chip-close-quickSearchValue"
                    height="12px"
                    width="12px"
                  />
                }
                icon={
                  <SearchIcon
                    sx={{
                      fill: `${colorPalette.redesign.blueSecondary}`,
                      fontSize: '16px',
                    }}
                  />
                }
                label={`"${activeQuickSearchValue}"`}
                variant="outlined"
                className={classes.chip}
                data-qa={`advanced-search-chip-${activeQuickSearchValue}}`}
                sx={{
                  backgroundColor: `${colorPalette.redesign.background2}`,
                  color: `${colorPalette.redesign.blueSecondary}`,
                  fontWeight: '600',
                  border: 'none',
                  padding: '4px',
                }}
              />
            )}
            {_.map({ ...activeAdvancedSearch.columns }, (value, name) =>
              renderChip(value, name)
            )}
            {!_.isEmpty(
              _.pickBy(activeAdvancedSearch.columns, (val) => !!val)
            ) && (
              <FBOButton
                variant="primary"
                color="positive"
                size="small"
                key={'save chip'}
                onClick={handleSaveClicked}
                data-qa="save-view-chip"
              >
                Save View
              </FBOButton>
            )}
          </Box>
        </Box>
      )}
      <Modal
        open={advancedModalVisible}
        onClose={handleCancelClicked}
        title={filterModalTitle}
        onResetClicked={handleResetClicked}
        onApplyClicked={handleApplyClicked}
        onCancelClicked={handleCancelClicked}
        applyLabel="Search"
        footerDivider="shadow"
        customHeight={600}
        dataQa={`${filterModalTitle}-modal`}
      >
        {children}
      </Modal>
      <Modal
        maxWidth="xs"
        open={saveModalVisible}
        onClose={handleCancelClicked}
        title="Save View"
        onApplyClicked={handleSaveSearchClicked}
        applyLabel="Save"
        cancelLabel="Cancel"
        onCancelClicked={handleCancelSaveSearchClicked}
        isLoading={isSaveSearchLoading}
        dataQa="save-view-modal"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Name"
              placeholder="Enter search name"
              name="name"
              fullWidth
              value={formSearchName}
              onChange={handleNameChanged}
              error={!!nameError}
              helperText={nameError}
              dataQa="save-view-name"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              className="redesign"
              control={
                <Checkbox
                  className="redesign"
                  checked={isFormDefault}
                  value="default"
                  data-qa="default-checkbox"
                />
              }
              label="Default"
              onClick={handleDefaultClicked}
            />
          </Grid>
        </Grid>
      </Modal>
      <ConfirmationModal
        open={deleteModalVisible}
        title="Delete saved settings"
        onConfirmClicked={handleDeleteSearchConfirm}
        onCancelClicked={handleDeleteSearchCancel}
        body={`This will delete view '${activeAdvancedSearch.searchName}', are you sure?`}
        confirmButtonRed
        confirmLabel="Delete"
        dataQa="delete-view-confirm-modal"
      />
      <ConfirmationModal
        open={overwriteDefaultModalVisible}
        title="Default Search"
        onConfirmClicked={handleSaveConfirmed}
        onCancelClicked={handleSaveCanceled}
        body={`You are about to overwrite your existing default search with a new one.  Would you like to continue?`}
        confirmLabel="Continue"
        dataQa="default-overwrite-confirm-modal"
      />
    </Box>
  );
};

export default FBOSearchHeader;
