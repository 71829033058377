import { PurchaseOrder } from 'services/purchaseOrders';
import { PurchaseOrderSettings } from 'services/settings/purchaseOrders';

export interface PurchaseOrderItemsProps {
  purchaseOrder: PurchaseOrder;
  setPurchaseOrder: React.Dispatch<React.SetStateAction<PurchaseOrder>>;
  oldState: React.MutableRefObject<PurchaseOrder | null>;
  validationErrors: any;
  rowValidationErrors: any[];
  poSettings: PurchaseOrderSettings;
}

export enum ThreeDotMenuActions {
  Duplicate,
  DuplicateToCreditReturn,
  MoveDown,
  MoveUp,
  Remove,
  FulFill,
}
