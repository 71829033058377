import React, { useCallback, useState } from 'react';

import { PaperSlidingLayout } from 'ui/components/Paper/PaperSlidingLayout';
import { PageWithAdvancedSearch } from 'ui/components/Page/PageWithAdvancedSearch';
import { withSearchResults } from 'ui/components/Page/WithSearchResults';

import {
  PricingRule,
  transformPricingRule,
  fetchSearch,
  getSearches,
} from 'services/pricingRules';
import { Pagination } from 'services/search';
import { fetchTags } from 'services/tags';
import { fetchCustomers } from 'services/customers';
import { useUrlQueryObject } from 'services/url';
import { Dates } from 'helpers';

import {
  initialASFormValues,
  advancedSearchReduxActions,
  PRICING_RULES_COLUMNS,
  initialPagination,
  displayNameMap,
} from './consts';

import { PricingRulesPageCmp, PricingRulesPageProps } from './types';
import { Routes } from '../../navigation';
import {
  PricingRulesAdvancedSearch,
  PricingRulesDetailsCard,
  PricingRulesSearchResults,
} from './components';
import { PricingRulesPageAction } from './components/PricingRulesSearchResults/consts';
import { useLocation, useNavigate } from 'react-router-dom';

const PricingRulesPage: PricingRulesPageCmp = (
  props: PricingRulesPageProps
) => {
  const {
    searchState: searchResult,
    refreshSearchState: fetchSearchResult,
    isLoadingSearchState: isLoadingSearchResult,
    activeItemId: activePricingRuleId,
  } = props;
  const [activeDate, setActiveDate] = useState<Dates>(Dates.DateCreated);

  const navigate = useNavigate();
  const location = useLocation();
  const [, extendUrlQuery] = useUrlQueryObject(navigate, location);

  const close = () => {
    extendUrlQuery({ activeId: null });
  };

  const handleAddNewPress = () => extendUrlQuery({ activeId: -1 });

  const handlePricingRuleClicked = (id: number | null) =>
    extendUrlQuery({ activeId: id });

  const handlePaginationChange = useCallback(
    (newPagination: Pagination) => {
      fetchSearchResult({ pagination: newPagination });
    },
    [fetchSearchResult]
  );

  const handlePageAction = useCallback(
    (action: PricingRulesPageAction, date: Dates) => {
      switch (action) {
        case PricingRulesPageAction.ChangeDate:
          setActiveDate(date);
          break;
      }
    },
    []
  );

  return (
    <>
      <PageWithAdvancedSearch
        detailCardColumns={PRICING_RULES_COLUMNS(activeDate)}
        initialFormValues={initialASFormValues}
        advancedSearchReduxActions={advancedSearchReduxActions}
        searchResult={searchResult}
        fetchSearchResult={fetchSearchResult}
        AdvancedSearchFieldsCmp={PricingRulesAdvancedSearch}
        displayNameMap={displayNameMap}
        pageName="Pricing Rules"
      >
        <PaperSlidingLayout shown={Boolean(activePricingRuleId)}>
          <PricingRulesSearchResults
            items={searchResult.items}
            activePricingRuleId={activePricingRuleId}
            handleItemClick={handlePricingRuleClicked}
            isLoadingItems={isLoadingSearchResult}
            onAddNewPress={handleAddNewPress}
            pagination={searchResult.pagination || initialPagination}
            onPageAction={handlePageAction}
            activeDate={activeDate}
            onPaginationChange={handlePaginationChange}
          />
          <PricingRulesDetailsCard
            activePricingRuleId={activePricingRuleId}
            onClose={close}
            fetchSearchResult={fetchSearchResult}
          />
        </PaperSlidingLayout>
      </PageWithAdvancedSearch>
    </>
  );
};

PricingRulesPage.route = Routes.PricingRulesPage;

export default withSearchResults<PricingRule>(PricingRulesPage, {
  url: '/v1/pricing_rules',
  dataAdapter: transformPricingRule,
  columns: PRICING_RULES_COLUMNS(Dates.DateCreated),
  fetchSearch,
  getSearches,
  initialPagination,
  rehydrationThunks: [fetchTags, fetchCustomers],
});
