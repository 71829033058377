import React, { memo, useMemo } from 'react';
import { TableCell } from '@mui/material';
import moment from 'moment';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { useGetIntlDateFormatString } from 'helpers';
import { Class } from 'services/classes';

const ClassesRow: React.FC<RenderCustomRowProps<Class>> = (props) => {
  const { row } = props;

  const intlFormatDate = useGetIntlDateFormatString();

  const dateValue: string = useMemo(() => {
    const { dateCreated } = row;
    return dateCreated ? moment(dateCreated).format(intlFormatDate) : '';
  }, [row, intlFormatDate]);

  return (
    <>
      <TableCell>{dateValue}</TableCell>
      <TableCell>{row.name}</TableCell>
    </>
  );
};

export default memo(ClassesRow);
