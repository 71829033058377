import {
  createAddSearch,
  createDeleteSearch,
  createFetchSearch,
  createUpdateSearch,
} from 'services/search';

// SEARCH ACTION CREATORS
export const fetchSearch = createFetchSearch('salesOrder');
export const addSearch = createAddSearch('salesOrder');
export const updateSearch = createUpdateSearch('salesOrder');
export const deleteSearch = createDeleteSearch('salesOrder');
