import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { colorPalette } from 'ui/theme';

export const usePurchaseLabelWizardStyle = makeStyles((theme: Theme) => ({
  activeIcon: {
    color: 'green',
    fill: `${colorPalette.fishbowlCorporateBlue} !important`,
  },
  footer: {
    display: 'flex',
    padding: theme.spacing(2),
    borderTop: `1px solid ${colorPalette.applicationDarkGray10}`,
  },
}));
