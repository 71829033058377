import { State } from 'app/redux';

export const getPaymentTerms = (state: State) =>
  state.paymentTerms.paymentTerms;

export const getSearches = (state: State) => state.paymentTerms.searches;

export const getPaymentTermById = (id: number | null) => (state: State) => {
  if (id === null) {
    return undefined;
  }
  return getPaymentTerms(state).items.find((item) => item.id === id);
};
