import { createApiAction } from 'services/api';
import {
  StartAction,
  SuccessAction,
  FailureAction,
} from 'services/api/redux/types';

export enum SettingsSalesOrderActionTypes {
  FETCH_SALES_ORDER_START = 'settings/fetch_sales_order_start',
  FETCH_SALES_ORDER_SUCCESS = 'settings/fetch_sales_order_success',
  FETCH_SALES_ORDER_FAILURE = 'settings/fetch_sales_order_failure',
}

// SALES_ORDER ACTIONS
const fetchSettingsSalesOrderActionStart = (): StartAction => ({
  type: SettingsSalesOrderActionTypes.FETCH_SALES_ORDER_START,
  payload: null,
});

const fetchSettingsSalesOrderActionSuccess = (payload: any): SuccessAction => {
  return {
    type: SettingsSalesOrderActionTypes.FETCH_SALES_ORDER_SUCCESS,
    payload,
  };
};

const fetchSettingsSalesOrderActionFailure = (
  error: string
): FailureAction => ({
  type: SettingsSalesOrderActionTypes.FETCH_SALES_ORDER_FAILURE,
  payload: { error },
});

export const fetchSettingsSalesOrders = () =>
  createApiAction(
    {
      path: '/v1/sales_order_settings?expand=salesTax',
      method: 'GET',
    },
    fetchSettingsSalesOrderActionStart,
    fetchSettingsSalesOrderActionSuccess,
    fetchSettingsSalesOrderActionFailure
  );
