import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { colorPalette } from 'ui/theme';

export const useFBOWizardStyle = makeStyles((theme: Theme) => ({
  paper: {
    width: '832px',
    padding: 0,
  },
  container: {
    alignSelf: 'flex-start',
  },
  sidebar: {
    width: 220,
    height: 500,
    overflow: 'auto',
    borderRight: `1px solid ${colorPalette.applicationDarkGray10}`,
  },
  content: {
    width: '100%',
    flex: 1,
    height: 500,
    overflow: 'auto',
  },
  footer: {
    display: 'flex',
    padding: theme.spacing(2),
    borderTop: `1px solid ${colorPalette.applicationDarkGray10}`,
  },
  tabs: {
    backgroundColor: 'transparent',
    width: '100%',
    height: 'inherit',
    '& .MuiTabs-indicator': { backgroundColor: 'transparent' },
    '& .MuiTabs-scroller': {
      backgroundColor: '#ffffff',
    },
  },
  tab: {
    cursor: 'unset',
    minHeight: 56,
    height: 56,
    borderRadius: 2,
    textTransform: 'none',
    borderBottom: `1px solid ${colorPalette.applicationDarkGray10}`,
    '&.Mui-selected': {
      backgroundColor: colorPalette.applicationLightGray,
      border: 'none',
      '& svg': {
        fill: colorPalette.applicationDarkGray80,
      },
    },
    '&.MuiTab-labelIcon': {
      paddingTop: 6,
    },
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& svg': {
      marginBottom: '0 !important',
      marginRight: theme.spacing(2),
      fill: colorPalette.applicationMediumGray,
    },
  },
  tabVertical: {
    padding: '15px !important',
    maxHeight: 'fit-content',
    height: 'auto',
    '& .MuiTab-wrapper': {
      alignItems: 'flex-start',
    },
  },
  tabVerticalLabel: {
    padding: '0px',
    textAlign: 'left',
  },
  tabVerticalList: {
    listStyle: 'none',
    padding: '0px',
    margin: '0px',
    textAlign: 'left',
  },
  cardRow: {
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 56,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  cardRowExpandable: {
    display: 'flex',
    flexShrink: 0,
    flexDirection: 'column',
  },
  cardRowInner: {
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  activeSubStep: {
    fontWeight: 'bold',
  },
}));
