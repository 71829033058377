import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { DismissibleModalProps } from './types';
import { useDismissibleModalStyle } from './styled';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { IconNames } from 'ui/theme';

export const DismissibleModal = (props: DismissibleModalProps) => {
  const { open, title, body, onDismiss, dismissButtonTitle } = props;

  const classes = useDismissibleModalStyle();

  return (
    <Dialog open={open} onClose={onDismiss}>
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      <FBOButton
        variant="tertiary"
        color="neutral"
        size="small"
        icon={IconNames.FBOClose}
        onClick={onDismiss}
        data-qa="dismissible-modal-close-icon"
        style={{
          position: 'absolute',
          top: '8px',
          right: '8px',
        }}
      />

      <DialogContent>
        <DialogContentText className={classes.body}>{body}</DialogContentText>
      </DialogContent>
      <DialogActions classes={{ spacing: classes.action }}>
        <FBOButton
          variant="primary"
          color="positive"
          size="large"
          autoFocus
          onClick={onDismiss}
          data-qa="dismissible-modal-dismiss-btn"
          style={{ width: '100%' }}
        >
          {dismissButtonTitle ?? 'Dismiss'}
        </FBOButton>
      </DialogActions>
    </Dialog>
  );
};
