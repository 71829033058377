import React, { useCallback, useState, useEffect, useRef, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Tabs, Tab } from '@mui/material';

import { TabPanel } from 'ui/components/TabPanel';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { DetailsCard } from 'ui/components/Page/DetailsCard';
import { customFieldsYupSchema } from 'ui/components/CustomFields/CustomFields';
import { getSettingsCompany } from 'services/settings/company/redux';
import { useUrlQueryObject } from 'services/url';
import {
  Customer,
  initialCustomer,
  fetchCustomers,
  createCustomer,
  fetchCustomerById,
  updateCustomer,
  deleteCustomer,
  restoreCustomer,
} from 'services/customers';
import { showNotification } from 'services/api';
import { Errors, validateYup } from 'services/forms/validation';
import { deleteDocuments } from 'services/documents/api';

import {
  AddressesTab,
  CarrierAccountsTab,
  CustomerDocumentsTab,
} from './components';
import { CustomerDetailsCardProps } from './types';
import { yupCustomerGeneralSchema } from './validation';
import { ItemsTab } from './components/ItemsTab';
import CustomerTitleBar from '../CustomerTitleBar/CustomerTitleBar';
import { getErrorMessage } from 'helpers';
import { logErrorCtx } from 'app/logging';
import GeneralTab from './components/GeneralTab/GeneralTab';
import { useLocation, useNavigate } from 'react-router-dom';

const CustomerDetailCard: React.FC<CustomerDetailsCardProps> = (props) => {
  const { activeCustomerId, fetchSearchResult, onClose } = props;

  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const [, setQueryParams] = useUrlQueryObject(navigate, location);

  const companySettings = useSelector(getSettingsCompany);

  const [activeCustomer, setActiveCustomer] =
    useState<Customer>(initialCustomer);
  const [validationErrors, setValidationErrors] = useState<Errors>({});
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [customFieldsErrors, setCustomFieldsErrors] = useState<Errors>({});
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = React.useState(0);
  const oldState = useRef<Customer | null>(initialCustomer);

  const handleDeleteModalVisible = (visible: boolean) => () =>
    setDeleteModalVisible(visible);

  useEffect(() => {
    const asyncFc = async (customerId: number) => {
      setIsLoading(true);
      try {
        const customer = await fetchCustomerById(customerId);
        oldState.current = customer;
        setActiveCustomer(customer);
      } catch (err) {
        const message = getErrorMessage(err);
        showNotification(`${message} - customer couldn't be loaded.`, {
          variant: 'error',
        });
        setIsLoading(false);
        onClose();
        return;
      }

      setIsLoading(false);
    };

    if (!activeCustomerId || activeCustomerId === -1) {
      const newCustomer: Customer = {
        ...initialCustomer,
        id: activeCustomerId,
        paymentTermId: companySettings.defaultPaymentTermId,
        defaultOrderPriorityId: companySettings.defaultPriorityId,
      };
      oldState.current = newCustomer;
      setActiveCustomer(newCustomer);
    } else {
      asyncFc(activeCustomerId);
    }

    setActiveTab(0);
    setValidationErrors({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCustomerId]);

  const saveCustomerClicked = useCallback(
    (close: boolean = false) =>
      async () => {
        const isValid = validateYup(
          activeCustomer,
          yupCustomerGeneralSchema,
          setValidationErrors
        );

        const isCustomFieldsValid = validateYup(
          activeCustomer.customFields,
          customFieldsYupSchema,
          setCustomFieldsErrors
        );

        if (!isValid || !isCustomFieldsValid) {
          setActiveTab(0);
          return false;
        }

        setIsLoading(true);

        const notDeletedDocuments = activeCustomer.documents.filter(
          (d) => !d.deleted
        );
        const deletedDocuments = activeCustomer.documents.filter(
          (d) => d.deleted
        );
        const resolvedCustomer = {
          ...activeCustomer,
          documents: notDeletedDocuments,
        };

        // Create new Customer
        if (!activeCustomer.id || activeCustomer.id === -1) {
          try {
            await deleteDocuments(deletedDocuments);
          } catch (e) {
            const error = e as Error;
            logErrorCtx('Error in CustomerPage ', {
              error,
              stackTrace: error.stack,
              title: 'Error while deleting customer ',
              description: 'deleteDocuments Not Fetched from Server',
              component: 'CustomerPage -> CustomerDetailCard',
            });
          }

          try {
            const newCustomer = await createCustomer(resolvedCustomer);
            oldState.current = newCustomer;
            setActiveCustomer(newCustomer);
            await fetchSearchResult();
            setQueryParams({ activeId: newCustomer.id });
            if (close) {
              setQueryParams({ activeId: null });
              onClose();
              return true;
            }
          } catch {
            setIsLoading(false);
            return false;
          }

          setIsLoading(false);
          return true;
        }

        // Update Customer
        try {
          await deleteDocuments(deletedDocuments);
        } catch (e) {
          const error = e as Error;
          logErrorCtx('Error in CustomerDetailCard', {
            error,
            stackTrace: error.stack,
            title: 'Error deleting customer documents',
            description: 'deleteDocuments Not Fetched from Server',
            component: 'CustomerPage-> CustomerDetailCard',
          });
        }

        try {
          const newCustomer = await updateCustomer(resolvedCustomer);
          oldState.current = newCustomer;
          setActiveCustomer(newCustomer);
          await fetchSearchResult();
        } catch {
          setIsLoading(false);
          return false;
        }
        // Lint skip to be removed
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(fetchCustomers());
        if (close) {
          onClose();
        }
        setIsLoading(false);
        return true;
      },
    [activeCustomer, dispatch, fetchSearchResult, onClose, setQueryParams]
  );

  const deleteCustomerClicked = useCallback(async () => {
    setIsLoading(true);

    try {
      await deleteCustomer(activeCustomer.id!);
      await fetchSearchResult();
      onClose();
    } catch {
      // continue regardless of error
    }

    setIsLoading(false);
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchCustomers());
    setDeleteModalVisible(false);
  }, [activeCustomer, fetchSearchResult, onClose, dispatch]);

  const handleUndeleteClicked = useCallback(
    async (close: boolean = false) => {
      setIsLoading(true);

      try {
        await restoreCustomer(activeCustomerId!);
        const restoredCustomer = await fetchCustomerById(activeCustomerId!);
        oldState.current = restoredCustomer;
        setActiveCustomer(restoredCustomer);
      } catch {
        setIsLoading(false);
        return false;
      }

      if (close) {
        onClose();
      }

      setIsLoading(false);
      fetchSearchResult();
      return true;
    },

    [fetchSearchResult, onClose, activeCustomerId]
  );

  const handleActiveTabChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      setActiveTab(newValue);
    },
    []
  );

  return (
    <DetailsCard
      isLoading={isLoading}
      onSubmit={saveCustomerClicked(false)}
      state={activeCustomer}
      oldState={oldState}
    >
      <CustomerTitleBar
        activeCustomer={activeCustomer}
        onSave={saveCustomerClicked()}
        onClose={onClose}
        onUndeleteClicked={handleUndeleteClicked}
        deleteModalVisible={handleDeleteModalVisible(true)}
      />

      <Tabs
        value={activeTab}
        onChange={handleActiveTabChange}
        indicatorColor="primary"
        className="redesign"
      >
        <Tab label="General" data-qa="customer-general-tab" />
        <Tab label="Addresses" data-qa="customer-addresses-tab" />
        <Tab label="Carrier Accounts" data-qa="customer-carrier-accounts-tab" />
        <Tab label="Items" data-qa="customer-items-tab" />
        <Tab label="Documents" data-qa="customer-documents-tab" />
      </Tabs>

      <TabPanel
        value={activeTab}
        index={0}
        noSpacing
        style={{ flexDirection: 'column' }}
      >
        <GeneralTab
          activeCustomer={activeCustomer}
          setActiveCustomer={setActiveCustomer}
          validationErrors={validationErrors}
          customFieldsErrors={customFieldsErrors}
          oldState={oldState}
          phone
        />
      </TabPanel>

      <TabPanel value={activeTab} index={1} noSpacing flexGrow>
        <AddressesTab
          activeCustomer={activeCustomer}
          setActiveCustomer={setActiveCustomer}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={2} noSpacing flexGrow>
        <CarrierAccountsTab
          activeCustomer={activeCustomer}
          setActiveCustomer={setActiveCustomer}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={3} noSpacing flexGrow>
        <ItemsTab
          activeCustomer={activeCustomer}
          setActiveCustomer={setActiveCustomer}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={4} noSpacing flexGrow>
        <CustomerDocumentsTab
          activeCustomer={activeCustomer}
          setActiveCustomer={setActiveCustomer}
        />
      </TabPanel>

      <ConfirmationModal
        open={deleteModalVisible}
        title="Delete Customer"
        body={`This will delete '${_.get(
          activeCustomer,
          'name',
          'Customer'
        )}', are you sure?`}
        onCancelClicked={handleDeleteModalVisible(false)}
        onConfirmClicked={deleteCustomerClicked}
        confirmLabel="Delete"
        confirmButtonRed
      />
    </DetailsCard>
  );
};

export default memo(CustomerDetailCard);
