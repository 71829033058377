import { FetchOptions } from 'ui/components/Page/WithSearchResults';

export interface SalesOrderDetailsCardCmp
  extends React.FunctionComponent<SalesOrderDetailsCardProps> {}

export interface SalesOrderDetailsCardProps {
  activeSalesOrderId: null | number;
  nextSalesOrdersNumber: number;
  setNextSalesOrderNumber: React.Dispatch<React.SetStateAction<number>>;
  onClose(): void;
  fetchSearchResult(options?: FetchOptions): Promise<void>;
  clone: boolean;
  setClone: React.Dispatch<React.SetStateAction<boolean>>;
}

export enum SalesOrderActiveIdState {
  New = -1,
  Duplicate = -2,
  DuplicateAsCreditReturn = -3,
}

export enum SalesOrderTab {
  General,
  Details,
  Documents,
  Notes,
}
