import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { PickItemCommitModalProps } from './types';

// Lint skip to be removed
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const usePickingModalStyle = makeStyles((theme: Theme) => ({
  gridWithPadding: {
    padding: theme.spacing(3),
  },
  gridWithoutRightPadding: {
    padding: theme.spacing(3),
    paddingRight: 0,
  },
  flexibleGrid: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    borderLeftWidth: (props: PickItemCommitModalProps) =>
      !props.activePickItem ? '1px' : '0px',
    borderColor: theme.palette.divider,
    borderLeftStyle: 'solid',
  },
  growingGrid: {
    flexGrow: 1,
  },
  actionButtonMargin: {
    marginRight: theme.spacing(2),
  },
  cancelButton: {
    color: theme.palette.text.primary,
  },
  bigNumber: {
    ...theme.typography.h4,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    height: '67px',
  },
}));
