import { PopoverOrigin } from '@mui/material';

export const totalAnchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

export const totalTransformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'left',
};

export const discountAnchorOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'center',
};

export const discountTransformOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};
