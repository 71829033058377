import { makeStyles } from '@mui/styles';

export const usePricingRulesSidebarStyle = makeStyles(() => ({
  sidebar: {
    width: 240,
    borderLeft: '1px solid #EBEBEB',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
  },
  sidebarWidget: {
    borderBottom: '1px solid #EBEBEB',
  },
}));
