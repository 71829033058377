import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const SUBSTITUTE_ITEMS_COLUMNS: Column[] = [
  {
    title: 'Name',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Quantity',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Location',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Price',
    type: ColumnTypes.renderByCustomRow,
  },
];
