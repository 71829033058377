import React, { memo, useMemo } from 'react';
import { TableCell } from '@mui/material';
import moment from 'moment';
import _ from 'lodash';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { StatusLabel } from 'ui/components/Status/StatusLabel';
import { DateMessage } from 'ui/components/DateTooltip/types';
import { DateTooltip } from 'ui/components/DateTooltip';
import { Dates, useGetIntlDateFormatString } from 'helpers';
import { DisplayPick } from 'services/picking';

import FBOStatusDot, {
  DefaultStatus,
} from 'ui/theme/components/FBOStatusDot/FBOStatusDot';
import { FBOStockStatusTag } from 'ui/theme/components/FBOStockStatusTag/FBOStockStatusTag';

const PickingRow: React.FC<RenderCustomRowProps<DisplayPick>> = (props) => {
  const { row, columns } = props;

  const intlFormatDate = useGetIntlDateFormatString();

  const itemActive = useMemo(() => columns.length === 3, [columns]);

  const tooltipDates: DateMessage[] = useMemo(
    () => [
      { date: row.dateCreated, message: 'Date Created' },
      { date: row.dateLastModified, message: 'Date Last Modified' },
      { date: row.scheduledDate, message: 'Date Scheduled' },
    ],
    [row]
  );

  const dateValue: string = useMemo(() => {
    const dateField = columns.find(
      (i) =>
        i.field === Dates.DateScheduled ||
        i.field === Dates.DateCreated ||
        i.field === Dates.DateLastModified
    )?.field;
    const date = dateField
      ? _.get(
          row,
          dateField === Dates.DateScheduled ? Dates.ScheduledDate : dateField,
          null
        )
      : null;

    return date ? moment(date).format(intlFormatDate) : '';
  }, [columns, row, itemActive, intlFormatDate]);

  return (
    <>
      <TableCell data-qa={` - ${row.number}`}>{row.number}</TableCell>
      {!itemActive && (
        <TableCell data-qa={` - ${row.stockStatus}`}>
          <FBOStockStatusTag row={row} />
        </TableCell>
      )}
      {itemActive ? (
        <FBOStatusDot status={row.status || DefaultStatus.Transparent} />
      ) : (
        <TableCell data-qa={`Status - ${row.number}`}>
          <StatusLabel status={row.status} />
        </TableCell>
      )}
      <TableCell data-qa={`Customer - ${row.number}`}>
        {row.customer?.name || ''}
      </TableCell>
      {!itemActive && (
        <>
          <TableCell data-qa={`Location - ${row.number}`}>
            {_.get(row, 'parentLocation.name', '')}
          </TableCell>
          <DateTooltip values={tooltipDates}>
            <TableCell data-qa={`Date Created - ${row.number}`}>
              {dateValue}
            </TableCell>
          </DateTooltip>
          <TableCell data-qa={`Priority - ${row.number}`}>
            {_.get(row, 'orderPriority.name')}
          </TableCell>
        </>
      )}
    </>
  );
};

export default memo(PickingRow);
