import React, { useCallback, memo, useEffect, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import { TextField } from 'ui/components/TextField/TextField';
import { Location } from 'services/locations';
import { useHandleTextFieldChange } from 'services/forms';
import { QuickTransferFormProps } from './types';
import { Item } from 'services/items/types';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import QuickTransferItemsAutocomplete from 'ui/components/Autocomplete/ItemsAutocomplete/QuickTransferItemsAutocomplete';
import { useQuickTransferFormStyle } from './styled';
import { icons } from 'ui/theme/icons';
import { v4 as uuidv4 } from 'uuid';
import { showNotification } from 'services/api/notifications';
import { validateBulkItemMoveQuantity } from '../CycleEventModal/helpers';
import { themeRestyle } from 'ui/theme';
import {
  ItemInventory,
  getItemInventory,
  initialItemInventory,
} from 'services/inventory';
import { Box } from '@mui/system';
import { AddAllItems, getAllItemIds } from './Helper';
const reducer = (
  state: {
    lockQty: boolean;
    lockNote: boolean;
    lockFrom: boolean;
    lockTo: boolean;
    lockItem: boolean;
  },
  action: {
    type: 'lockQty' | 'lockNote' | 'lockFrom' | 'lockTo' | 'lockItem';
    value: boolean;
  }
): {
  lockQty: boolean;
  lockNote: boolean;
  lockFrom: boolean;
  lockTo: boolean;
  lockItem: boolean;
} => {
  switch (action.type) {
    case 'lockQty':
      return { ...state, lockQty: action.value };
    case 'lockNote':
      return { ...state, lockNote: action.value };
    case 'lockFrom':
      return { ...state, lockFrom: action.value };
    case 'lockTo':
      return { ...state, lockTo: action.value };
    case 'lockItem':
      return { ...state, lockItem: action.value };
    default:
      return state;
  }
};
const QuickTransferForm: React.FC<QuickTransferFormProps> = (props) => {
  const { formValues, validationErrors, setFormValues, setItems, meta } = props;
  const { tableData, setTableData } = meta;
  const [disableAddBtn, setDisableAddBtn] = useState(true);
  const [checked, setChecked] = useState(false);
  const [itemInventory, setItemInventory] =
    useState<ItemInventory>(initialItemInventory);
  const [state, dispatch] = React.useReducer(reducer, {
    lockQty: false,
    lockNote: false,
    lockFrom: false,
    lockTo: false,
    lockItem: false,
  });
  const classes = useQuickTransferFormStyle();
  const handleLocationChange = useCallback(
    (value: Location | null) => {
      setFormValues((f) => ({
        ...f,
        locationFromId: value ? value.id : null,
        locationFromName: value ? value.displayName : null,
        Item: null,
      }));
    },
    [setFormValues]
  );
  const handleItemChange = useCallback(
    (item: Item | null) => {
      setFormValues((old) => ({ ...old, Item: item || null }));
    },
    [setFormValues]
  );
  useEffect(() => {}, [tableData, state]);
  useEffect(() => {
    if (checked) {
      setDisableAddBtn(false);
    } else if (
      formValues.Item &&
      formValues.locationFromId &&
      formValues.locationToId &&
      formValues.quantity
    ) {
      setDisableAddBtn(false);
    } else setDisableAddBtn(true);
  }, [formValues, setDisableAddBtn, checked]);
  const handleLocationToChange = useCallback(
    (value: Location | null) => {
      setFormValues((f) => ({
        ...f,
        locationToId: value ? value.id : null,
        locationToName: value ? value.displayName : null,
      }));
    },
    [setFormValues]
  );
  const handleTextFieldChange = useHandleTextFieldChange(
    setFormValues,
    formValues,
    true
  );
  const handleResetForm = () => {
    if (
      !state.lockFrom ||
      !state.lockTo ||
      !state.lockNote ||
      !state.lockQty ||
      !state.lockItem
    ) {
      setFormValues((f) => ({
        ...f,
        notes: state.lockNote ? f.notes : null,
        quantity: state.lockQty ? f.quantity : null,
        locationFromId: state.lockFrom ? f.locationFromId : null,
        locationToId: state.lockTo ? f.locationToId : null,

        Item: state.lockItem ? f.Item : null,
      }));
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      if (formValues.Item?.id) {
        const inventory = await getItemInventory(formValues.Item.id);
        setItemInventory(inventory);
      }
    };

    fetchData();
  }, [formValues.Item?.id]);
  const handleAddItem = useCallback(async () => {
    if (checked) {
      const allItems = await getAllItemIds(formValues.locationFromId!);
      if (allItems)
        await AddAllItems(
          allItems,
          formValues.locationFromId!,
          setTableData,
          formValues
        );
      handleResetForm();
      setChecked(false);
      return;
    }
    const insufficientQuantity = validateBulkItemMoveQuantity(
      formValues,
      itemInventory,
      tableData
    );
    if (insufficientQuantity) {
      showNotification('Not enough quantity on hand to move.', {
        variant: 'error',
      });
      return;
    }

    setItems((items) => {
      const newItem = formValues.Item;
      if (newItem !== null && newItem !== undefined) {
        return [...items, newItem];
      }
      return items;
    });

    setTableData((data: any) => [...data, { ...formValues, id: uuidv4() }]);
    handleResetForm();
  }, [
    setItems,
    formValues,
    itemInventory,
    tableData,
    showNotification,
    handleResetForm,
  ]);

  return (
    <Grid
      container
      className={classes.container}
      xs={12}
      spacing={themeRestyle.spacing(2)}
    >
      <Grid xs={2}>
        <LocationsAsyncAutocomplete
          placeholder="Select"
          label="From Location"
          value={formValues.locationFromId}
          onChange={handleLocationChange}
          dataQa="from-location"
          companyWide={false}
          error={!!validationErrors.locationFromId}
          disabled={state.lockFrom}
          additionalInputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                className={classes.lockIconPosition}
              >
                <FBOButton
                  icon={state.lockFrom ? 'FBOLock' : 'FBOUnlock'}
                  variant="tertiary"
                  color="neutral"
                  size="medium"
                  onClick={() =>
                    dispatch({ type: 'lockFrom', value: !state.lockFrom })
                  }
                  data-qa="from-location-lock"
                  sx={{
                    height: '36.13px',
                    width: '36.13px',
                    marginLeft: '1px',
                  }}
                />
              </InputAdornment>
            ),
            style: {
              paddingLeft: '46px',
            },
          }}
        />
      </Grid>
      <Grid xs={2}>
        <LocationsAsyncAutocomplete
          placeholder="Select"
          label="To Location"
          value={formValues.locationToId}
          onChange={handleLocationToChange}
          companyWide={false}
          dataQa="to-location"
          getLocationLabel={(l) => l.path || ''}
          filterOutLocations={[formValues.locationToId]}
          error={!!validationErrors.locationToId}
          disabled={state.lockTo}
          additionalInputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                className={classes.lockIconPosition}
              >
                <FBOButton
                  icon={state.lockTo ? 'FBOLock' : 'FBOUnlock'}
                  variant="tertiary"
                  color="neutral"
                  size="medium"
                  onClick={() =>
                    dispatch({ type: 'lockTo', value: !state.lockTo })
                  }
                  data-qa="to-location-lock"
                  sx={{
                    height: '36.13px',
                    width: '36.13px',
                    marginLeft: '1px',
                  }}
                />
              </InputAdornment>
            ),
            style: {
              paddingLeft: '46px',
            },
          }}
        />
      </Grid>
      <Grid xs={3.3}>
        {formValues.locationToId && formValues.locationFromId && (
          <FormControlLabel
            value="applyMoveAll"
            name="moveAll"
            //className={'redesign'}
            color="neutral"
            control={
              <Checkbox
                className={'redesign'}
                color="primary"
                inputProps={
                  {
                    'data-qa': 'bulk-move-all-checkbox',
                  } as any
                }
              />
            }
            //  disabled={false
            label={
              <Typography className={classes.topText}>
                Move All Items
              </Typography>
            }
            checked={checked}
            onChange={() => setChecked(!checked)}
            sx={{
              justifyContent: 'flex-end',
              alignItems: 'center',
              display: 'flex',
              marginTop: '-5px',
            }}
          />
        )}
        <Box
          sx={{
            marginTop:
              formValues.locationToId && formValues.locationFromId
                ? '-20px'
                : '0px',
          }}
        >
          <QuickTransferItemsAutocomplete
            placeholder="Search Item"
            label="Item"
            value={formValues.locationFromId ? formValues.Item : null}
            onChange={handleItemChange}
            disabled={state.lockItem || !formValues.locationFromId || checked}
            locationId={formValues.locationFromId}
            additionalInputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  className={classes.lockIconPosition}
                >
                  <FBOButton
                    icon={state.lockItem ? 'FBOLock' : 'FBOUnlock'}
                    variant="tertiary"
                    color="neutral"
                    size="medium"
                    disabled={!formValues.locationFromId || checked}
                    onClick={() =>
                      dispatch({ type: 'lockItem', value: !state.lockItem })
                    }
                    data-qa="item-lock"
                    sx={{
                      height: '36.13px',
                      width: '36.13px',
                      marginLeft: '1px',
                    }}
                  />
                  <icons.MagnifyingGlass
                    className={classes.magnifyingGlassIcon}
                  />
                </InputAdornment>
              ),

              style: {
                paddingLeft: '85px',
              },
            }}
            dataQa="item-search"
          />
        </Box>
      </Grid>
      <Grid xs={1.2}>
        <TextField
          className="redesign"
          variant="standard"
          type="number"
          label="Qty To Move"
          onChange={handleTextFieldChange}
          name="quantity"
          value={formValues.quantity}
          disabled={state.lockQty || checked}
          inputProps={{
            min: 0,
            style: {
              textAlign: 'start',
              paddingLeft: '48px',
              ...(!state.lockQty && { backgroundColor: 'white' }),
            },
            'data-qa': 'qty-to-Move',
          }}
          InputProps={{
            startAdornment: (
              <FBOButton
                icon={state.lockQty ? 'FBOLock' : 'FBOUnlock'}
                variant="tertiary"
                color="neutral"
                size="medium"
                disabled={checked}
                onClick={() =>
                  dispatch({ type: 'lockQty', value: !state.lockQty })
                }
                data-qa="quantity-lock"
                sx={{
                  position: 'absolute',
                  left: '1px',
                  height: '36.13px',
                  width: '36.13px',
                }}
              />
            ),
          }}
        />
      </Grid>
      <Grid xs={2}>
        <TextField
          className="redesign"
          variant="standard"
          label="Note"
          type="text"
          name="notes"
          value={formValues.notes}
          onChange={handleTextFieldChange}
          disabled={state.lockNote}
          inputProps={{
            min: 0,
            style: {
              textAlign: 'start',
              paddingLeft: '48px',
              ...(!state.lockNote && { backgroundColor: 'white' }),
            },
          }}
          InputProps={{
            startAdornment: (
              <FBOButton
                icon={state.lockNote ? 'FBOLock' : 'FBOUnlock'}
                variant="tertiary"
                color="neutral"
                size="medium"
                onClick={() =>
                  dispatch({ type: 'lockNote', value: !state.lockNote })
                }
                data-qa="note-lock"
                sx={{
                  position: 'absolute',
                  left: '1px',
                  height: '36.13px',
                  width: '36.13px',
                }}
              />
            ),
          }}
        />
      </Grid>
      <Grid xs={1.5}>
        <FBOButton
          variant="secondary"
          color="positive"
          size="medium"
          icon="FBOAddCircle"
          onClick={handleAddItem}
          data-qa="quick-transfer-add-item"
          disabled={disableAddBtn}
          fullWidth
        >
          {checked ? 'Add All ' : 'Add Item '}
        </FBOButton>
      </Grid>
    </Grid>
  );
};

export default memo(QuickTransferForm);
