import React, { memo } from 'react';

import { ItemsTable } from 'ui/components/Table/ItemsTable';

import { ReportsPageTableProps } from './types';
import { REPORTS_COLUMNS } from './consts';

import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';

const ReportsPageTable: React.FC<ReportsPageTableProps> = (props) => {
  const { items, activeId, onReportClick } = props;

  const alwaysOnColumns = REPORTS_COLUMNS.filter((c) => c.title === 'Name');

  return (
    <>
      <FBOTitleBar title="Reports" />

      <ItemsTable
        data={items}
        columns={activeId ? alwaysOnColumns : REPORTS_COLUMNS}
        activeId={activeId}
        selectableItems={false}
        onItemClick={onReportClick}
        dataQa="reports"
      />
    </>
  );
};
export default memo(ReportsPageTable);
