import _ from 'lodash';
import moment from 'moment';

import {
  CustomField,
  CustomFieldOption,
  CustomFieldType,
  CustomFieldValue,
  ObjectType,
} from './types';

/* RESPONSE TRANSFORMATIONS */

export const transformCustomField = (resp: any): CustomField => {
  const customFieldOptions = resp.customFieldOptions || [];

  return {
    id: resp.id,
    checkVersion: resp.checkVersion,
    customFieldOptions: customFieldOptions.map(transformCustomFieldOption),
    customFieldType: resp.customFieldType,
    customFieldValues: transformCustomFieldValues(
      resp.customFieldValues,
      resp.customFieldType
    ),
    dateCreated: resp.dateCreated,
    dateLastModified: resp.dateLastModified,
    lineNumber: resp.lineNumber,
    name: resp.name,
    objectType: resp.objectType,
    required: resp.required,
    version: resp.version,
  };
};

export const transformCustomFieldOption = (resp: any): CustomFieldOption => {
  return {
    id: resp.id,
    checkVersion: resp.checkVersion,
    customFieldId: resp.customFieldId,
    dateCreated: resp.dateCreated,
    dateLastModified: resp.dateLastModified,
    option: resp.option,
    version: resp.version,
  };
};

export const transformCustomFieldValues = (
  resp: any,
  customFieldType: CustomFieldType
): CustomFieldValue | null => {
  if (!resp || !resp.length) {
    return null;
  }

  // If multiple choice return list of string values
  if (customFieldType === CustomFieldType.MultipleChoice) {
    return resp.map((r: any) => r.value);
  }

  // Boolean fields are returning values as string
  if (customFieldType === CustomFieldType.Boolean) {
    const boolString = _.orderBy(resp, (r) => r.id, 'desc')[0].value;
    return boolString === 'true';
  }

  // If any other field return the latests saved value
  return _.orderBy(resp, (r) => r.id, 'desc')[0].value;
};

/* REQUEST TRANSFROMATIONS */

export const transformToRequestCustomField = (
  customField: CustomField,
  module?: ObjectType,
  sendValues = false
): any => {
  const body: any = {
    version: customField.version,
    dateCreated: customField.dateCreated,
    dateLastModified: customField.dateLastModified,
    name: customField.name,
    objectType: customField.objectType,
    customFieldType: customField.customFieldType,
    required: customField.required,
    customFieldOptions: customField.customFieldOptions.map(
      transformToRequestCustomFieldOption
    ),
    lineNumber: customField.lineNumber,
  };

  if (sendValues) {
    body['customFieldValues'] =
      transformToRequestCustomFieldValues(customField);
  }

  // POST
  if (!customField.id) {
    return {
      ...body,
      objectType: module,
    };
  }

  // PUT
  return {
    ...body,
    id: customField.id,
  };
};

export const transformToRequestCustomFieldOption = (
  customFieldOption: CustomFieldOption
): any => {
  const body = {
    checkVersion: customFieldOption.checkVersion,
    customFieldId: customFieldOption.customFieldId,
    dateCreated: customFieldOption.dateCreated,
    dateLastModified: customFieldOption.dateLastModified,
    option: customFieldOption.option,
    version: customFieldOption.version,
  };

  // POST
  if (!customFieldOption.id) {
    return body;
  }

  // PUT
  return {
    ...body,
    id: customFieldOption.id,
  };
};

export const transformToRequestCustomFieldValues = (
  customField: CustomField
) => {
  if (
    customField.customFieldValues &&
    customField.customFieldType === CustomFieldType.MultipleChoice
  ) {
    const customFields = customField.customFieldValues as string[];
    return customFields.map((c) => ({ value: c }));
  }

  if (
    customField.customFieldValues &&
    customField.customFieldType === CustomFieldType.Date
  ) {
    return [
      {
        value: moment(customField.customFieldValues as Date).format(
          'YYYY-MM-DD'
        ),
      },
    ];
  }

  return [{ value: customField.customFieldValues }];
};
