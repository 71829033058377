import React, { useEffect, useState } from 'react';
import { Location } from 'history';
import { Box } from '@mui/material';

import { RouteLeavingGuardProps } from './types';
import { ConfirmationModal } from '../../Modal/ConfirmationModal';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { useBlocker } from 'react-router-dom';

const RouteLeavingGuard = ({
  when,
  navigate,
  onSubmit,
  bypassRouteLeavingGuard = false,
}: RouteLeavingGuardProps) => {
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [toLocation, setToLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    setToLocation(nextLocation);
    return (
      when &&
      (currentLocation.pathname !== nextLocation.pathname ||
        currentLocation.search !== nextLocation.search)
    );
  });

  useEffect(() => {
    if (bypassRouteLeavingGuard) {
      handleDontSaveClicked();
    }
  }, [bypassRouteLeavingGuard]);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function

      navigate(lastLocation);
      setConfirmedNavigation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedNavigation, lastLocation]);

  const closeModal = () => {
    blocker.reset && blocker.reset();
  };

  const handleDontSaveClicked = () => {
    blocker.proceed && blocker?.proceed();
    setConfirmedNavigation(true);
  };

  const handleSaveClicked = async (e: any) => {
    setLastLocation(toLocation);
    const success = await onSubmit(e);
    if (success) {
      blocker.reset && blocker?.reset();
      if (lastLocation) {
        navigate({
          ...lastLocation,
          state: { triggerRouteGuard: false },
        } as Location);
      }
    }
  };

  const FBODialogActions = () => (
    <Box
      display="flex"
      justifyContent="center"
      alignContent="space-evenly"
      sx={{ width: '100%', paddingLeft: '16px', paddingRight: '16px' }}
    >
      <FBOButton
        variant="secondary"
        color="neutral"
        size="medium"
        onClick={handleDontSaveClicked}
        data-qa="unsavedChanges-modal-dontSavebtn"
        style={{ flexGrow: 1, minWidth: '127px' }}
      >
        Don't save
      </FBOButton>
      <FBOButton
        variant="secondary"
        color="positive"
        size="medium"
        onClick={closeModal}
        data-qa="unsavedChanges-modal-cancelbtn"
        style={{
          flexGrow: 1,
          minWidth: '127px',
          marginLeft: '8px',
          marginRight: '8px',
        }}
      >
        Cancel
      </FBOButton>
      <FBOButton
        variant="primary"
        color="positive"
        size="medium"
        onClick={handleSaveClicked}
        data-qa="unsavedChanges-modal-savebtn"
        style={{ flexGrow: 1, minWidth: '127px' }}
      >
        Save
      </FBOButton>
    </Box>
  );

  return (
    <>
      {blocker.state === 'blocked' && (
        <ConfirmationModal
          body={'Do you want to save changes before leaving this page?'}
          title={'Unsaved Changes'}
          onCancelClicked={closeModal}
          onConfirmClicked={handleSaveClicked}
          open={blocker.state === 'blocked'}
          DialogActionsComponent={FBODialogActions}
        />
      )}
    </>
  );
};

export default RouteLeavingGuard;
