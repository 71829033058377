import { createApiAction } from 'services/api';
import {
  StartAction,
  SuccessAction,
  FailureAction,
} from 'services/api/redux/types';
import {
  createAddSearch,
  createDeleteSearch,
  createFetchSearch,
  createUpdateSearch,
} from 'services/search';

export enum TagsActionTypes {
  FETCH_TAGS_START = 'tags/fetch_tags_start',
  FETCH_TAGS_SUCCESS = 'tags/fetch_tags_success',
  FETCH_TAGS_FAILURE = 'tags/fetch_tags_failure',
}

const fetchTagsActionStart = (): StartAction => ({
  type: TagsActionTypes.FETCH_TAGS_START,
  payload: null,
});

const fetchTagsActionSuccess = (payload: any): SuccessAction => ({
  type: TagsActionTypes.FETCH_TAGS_SUCCESS,
  payload,
});

const fetchTagsActionFailure = (error: any): FailureAction => ({
  type: TagsActionTypes.FETCH_TAGS_FAILURE,
  payload: { error },
});

export const fetchTags = () =>
  createApiAction(
    {
      path: '/v1/tags?orderBy=name&pageSize=100',
      method: 'GET',
    },
    fetchTagsActionStart,
    fetchTagsActionSuccess,
    fetchTagsActionFailure
  );

export const fetchSearch = createFetchSearch('tag');
export const addSearch = createAddSearch('tag');
export const updateSearch = createUpdateSearch('tag');
export const deleteSearch = createDeleteSearch('tag');
