import { createApiCall } from 'services/api';

import {
  transformPaymentTerm,
  transformPaymentTermRequest,
} from './transformations';
import { PaymentTerm } from './types';

export const fetchPaymentById = async (id: number): Promise<PaymentTerm> => {
  const response = await createApiCall({
    path: `/v1/payment_terms/${id}`,
    method: 'GET',
  })();

  return transformPaymentTerm(response.data, true);
};

export const updatePaymentTerm = async (
  paymentTerm: PaymentTerm
): Promise<PaymentTerm> => {
  const response = await createApiCall(
    {
      path: '/v1/payment_terms',
      method: 'PUT',
      body: transformPaymentTermRequest(paymentTerm),
    },
    {
      getMessage: {
        success: () => 'Payment successfully updated',
        error: (error: any) => error.response.data.message,
      },
    }
  )();

  return transformPaymentTerm(response.data, true);
};

export const createPaymentTerm = async (
  paymentTerm: PaymentTerm
): Promise<PaymentTerm> => {
  const response = await createApiCall(
    {
      path: '/v1/payment_terms',
      method: 'POST',
      body: transformPaymentTermRequest(paymentTerm),
    },
    {
      getMessage: {
        success: () => 'Payment successfully created',
        error: (error: any) => error.response.data.message,
      },
    }
  )();

  return transformPaymentTerm(response.data, true);
};

export const deletePaymentTerm = async (id: number): Promise<void> => {
  await createApiCall(
    {
      path: `/v1/payment_terms/${id}`,
      method: 'DELETE',
    },
    {
      getMessage: {
        success: () => 'Payment successfully deleted',
        error: (error: any) => error.response.data.message,
      },
    }
  )();
};

export const restorePaymentTerm = async (paymenTermId: number) => {
  await createApiCall(
    {
      path: `/v1/payment_terms/${paymenTermId}/restore`,
      method: 'POST',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
        success: () => 'Payment term successfully restored',
      },
    }
  )();
};
