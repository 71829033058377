import React, { memo, useEffect, useState } from 'react';
import { Box } from '@mui/material';

import {
  fetchOpenPicks,
  fetchOpenSalesOrders,
  fetchOpenShipments,
  fetchTopSellingItems,
} from 'services/dashboard/api';
import {
  OpenPick,
  OpenSalesOrder,
  OpenShipment,
  TopSellingItem,
} from 'services/dashboard';
import { Routes as ItemRoutes } from 'ui/modules/materials';
import { Routes as SalesRoutes } from 'ui/modules/sales';
import { Pagination } from 'services/search';
import { DataWithPagination } from 'services/api';
import { useCurrencyFormatter, useGetIntlDateFormatString } from 'helpers';

import { DashboardTable } from '../DashboardTable';
import { default as TopSellingItemsRow } from './TopSellingItemsRow';
import {
  TOP_SELLING_ITEMS_COLUMNS,
  OPEN_PICKS_COLUMNS,
  OPEN_SALES_ORDERS_COLUMNS,
  OPEN_SHIPMENTS_COLUMNS,
} from './consts';
import { DashboardSalesProps } from './types';
import { dashboardInitialDataWithPagination, DATE_FORMAT } from '../../consts';
import { concatRoute } from 'helpers/routes';

const DashboardSales: React.FC<DashboardSalesProps> = ({
  fromStartDate,
  fromEndDate,
}) => {
  const [topSellingItems, setTopSellingItems] = useState<
    DataWithPagination<TopSellingItem>
  >(dashboardInitialDataWithPagination);

  const [salesOrders, setSalesOrders] = useState<
    DataWithPagination<OpenSalesOrder>
  >(dashboardInitialDataWithPagination);
  const [shipments, setShipments] = useState<DataWithPagination<OpenShipment>>(
    dashboardInitialDataWithPagination
  );
  const [picks, setPicks] = useState<DataWithPagination<OpenPick>>(
    dashboardInitialDataWithPagination
  );

  const currencyFormatter = useCurrencyFormatter();
  const intlDateFormat = useGetIntlDateFormatString();

  useEffect(() => {
    getTopSellingItems(topSellingItems.pagination);
    getSalesOrders(salesOrders.pagination);
    getShippments(shipments.pagination);
    getPicks(picks.pagination);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromStartDate, fromEndDate]);

  const getTopSellingItems = async (pagination: Pagination) => {
    if (!fromStartDate.isValid() || !fromEndDate.isValid()) {
      return;
    }

    try {
      const mostSellingItems = await fetchTopSellingItems(
        pagination,
        fromStartDate.format(DATE_FORMAT),
        fromEndDate.format(DATE_FORMAT)
      );

      setTopSellingItems(mostSellingItems);
    } catch {
      setTopSellingItems(dashboardInitialDataWithPagination);
    }
  };

  const getSalesOrders = async (pagination: Pagination) => {
    if (!fromStartDate.isValid() || !fromEndDate.isValid()) {
      return;
    }

    try {
      const openSalesOrders = await fetchOpenSalesOrders(
        pagination,
        fromStartDate.format(DATE_FORMAT),
        fromEndDate.format(DATE_FORMAT)
      );
      setSalesOrders(openSalesOrders);
    } catch {
      setSalesOrders(dashboardInitialDataWithPagination);
    }
  };

  const getShippments = async (pagination: Pagination) => {
    if (!fromStartDate.isValid() || !fromEndDate.isValid()) {
      return;
    }

    try {
      const openShipments = await fetchOpenShipments(
        pagination,
        fromStartDate.format(DATE_FORMAT),
        fromEndDate.format(DATE_FORMAT)
      );
      setShipments(openShipments);
    } catch {
      setShipments(dashboardInitialDataWithPagination);
    }
  };

  const getPicks = async (pagination: Pagination) => {
    if (!fromStartDate.isValid() || !fromEndDate.isValid()) {
      return;
    }

    try {
      const openPicks = await fetchOpenPicks(
        pagination,
        fromStartDate.format(DATE_FORMAT),
        fromEndDate.format(DATE_FORMAT)
      );
      setPicks(openPicks);
    } catch {
      setPicks(dashboardInitialDataWithPagination);
    }
  };

  const handleTopSellingItemsPaginationChange = (pagination: Pagination) => {
    setTopSellingItems({ ...topSellingItems, pagination });
    getTopSellingItems(pagination);
  };

  const handleShipsPaginationChange = (pagination: Pagination) => {
    setShipments({ ...shipments, pagination });
    getShippments(pagination);
  };

  const handlePicksPaginationChange = (pagination: Pagination) => {
    setPicks({ ...picks, pagination });
    getPicks(pagination);
  };

  const handleOpenSalesOrdersPaginationChange = (pagination: Pagination) => {
    setSalesOrders({ ...salesOrders, pagination });
    getSalesOrders(pagination);
  };

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <DashboardTable
        title="Top Selling Items"
        routeLink={`${concatRoute(
          ItemRoutes.MaterialsRouter,
          ItemRoutes.ItemsPage
        )}`}
        routeLinkLabel="Go to items"
        data={topSellingItems.data}
        pagination={topSellingItems.pagination}
        columns={TOP_SELLING_ITEMS_COLUMNS}
        selectableItems={false}
        onPaginationChange={handleTopSellingItemsPaginationChange}
        containerHeight={460}
        RenderCustomRow={TopSellingItemsRow}
      />
      <DashboardTable
        title="Open Sales Orders"
        routeLink={`${concatRoute(
          SalesRoutes.SalesRouter,
          SalesRoutes.SalesOrderPage
        )}`}
        routeLinkLabel="Go to sales orders"
        data={salesOrders.data}
        pagination={salesOrders.pagination}
        columns={OPEN_SALES_ORDERS_COLUMNS(intlDateFormat, currencyFormatter)}
        selectableItems={false}
        onPaginationChange={handleOpenSalesOrdersPaginationChange}
        containerHeight={460}
      />
      <DashboardTable
        title="Open Shipments"
        routeLink={`${concatRoute(
          SalesRoutes.SalesRouter,
          SalesRoutes.ShippingPage
        )}`}
        routeLinkLabel="Go to shipping"
        data={shipments.data}
        pagination={shipments.pagination}
        columns={OPEN_SHIPMENTS_COLUMNS}
        selectableItems={false}
        onPaginationChange={handleShipsPaginationChange}
        containerHeight={460}
      />
      <DashboardTable
        title="Open Picks"
        routeLink={`${concatRoute(
          SalesRoutes.SalesRouter,
          SalesRoutes.PickingPage
        )}`}
        routeLinkLabel="Go to picking"
        data={picks.data}
        pagination={picks.pagination}
        columns={OPEN_PICKS_COLUMNS(intlDateFormat)}
        selectableItems={false}
        onPaginationChange={handlePicksPaginationChange}
        containerHeight={460}
      />
    </Box>
  );
};

export default memo(DashboardSales);
