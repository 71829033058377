import { Typography } from '@mui/material';
import React from 'react';
import { Modal } from 'ui/components/Modal/Modal';
import { ReconnectingModalProps } from './types';
import { FishbowlSpinner } from 'ui/theme';
import { useReconnectingModalStyle } from './styled';

const titleBar = () => <></>;

const ReconnectingModal = (props: ReconnectingModalProps) => {
  const { open } = props;
  const classes = useReconnectingModalStyle();

  const handleClose = (
    event: React.ChangeEvent<HTMLInputElement>,
    reason: string
  ) => {
    if (reason && reason === 'backdropClick') return;
  };
  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      TitleBarComponent={titleBar}
      withoutFooter
      fullWidth
    >
      <div className={classes.modalContent}>
        <img
          className={classes.image}
          src={FishbowlSpinner.toString()}
          data-qa="fishbowl-spinner"
        ></img>
        <Typography className={classes.headingText} variant="h6">
          We’re Reconnecting Your Account...
        </Typography>
        <Typography variant="body1">This May Take a Moment</Typography>
      </div>
    </Modal>
  );
};

export default ReconnectingModal;
