import { createApiCall } from 'services/api';
import { commerceApi } from 'services/api/config';
import {
  transformChannel,
  transformChannelSettings,
  transformToChannelSettingsPut,
} from './transformations';
import { ChannelSettings } from './types';

export const getChannels = async () => {
  const response = await commerceApi({
    url: `/commerce/v1/channels`,
    method: 'GET',
  });

  return response.data.map(transformChannel);
};

export const channelSettingsPut = async (
  channelId: number,
  channelSettings: ChannelSettings
) => {
  await createApiCall(
    {
      hostname: `${process.env.REACT_APP_COMMERCE_URL}`,
      path: `/commerce/v1/channels/${channelId}/settings`,
      method: `PUT`,
      body: transformToChannelSettingsPut(channelSettings),
    },
    {
      getMessage: {
        success: () => 'Set channel settings',
        error: (error: any) => `${error.response.data}`,
      },
    }
  )();
};

export const getChannelSettings = async (channelId: number) => {
  const response = await commerceApi({
    url: `/commerce/v1/channels/${channelId}/settings`,
    method: 'GET',
  });

  return transformChannelSettings(response.data);
};
