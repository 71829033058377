import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const QUICK_TRANSFER_COLUMNS: Column[] = [
  {
    title: '',
    type: ColumnTypes.renderByCustomRow,
    width: '0%',
  },
  {
    title: 'From Location',
    type: ColumnTypes.renderByCustomRow,
    width: '15.5%',
  },
  {
    title: 'To Location',
    type: ColumnTypes.renderByCustomRow,
    width: '15.5%',
  },

  {
    title: 'Item',
    type: ColumnTypes.renderByCustomRow,
    width: '18%',
  },
  {
    title: 'Able to Move',
    type: ColumnTypes.renderByCustomRow,
    width: '10%',
  },
  {
    title: 'Qty To Move',
    type: ColumnTypes.renderByCustomRow,
    width: '10%',
  },

  {
    title: 'Note',
    type: ColumnTypes.renderByCustomRow,
    width: '19%',
  },
  {
    title: 'Tracking',
    type: ColumnTypes.renderByCustomRow,
    width: '13%',
  },
];
