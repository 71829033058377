import React, { useCallback } from 'react';
import moment from 'moment';
import { TableCell, Typography, Tooltip } from '@mui/material';

import { LedgerLineItem } from 'services/ledgers';
import { useCurrencyFormatter, useGetIntlDateFormatString } from 'helpers';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import { Icon } from 'ui/components/Icon';
import { IconNames } from 'ui/theme';
import { useSelector } from 'react-redux';
import { getQuickbooksAccountIsConnected } from 'services/integrations/quickbooks';
import Popover from '@mui/material/Popover';
import {
  QBO_INVOICE_URL,
  QBO_JOURNAL_URL,
  QBO_BILL_URL,
  QBO_PAYMENT_URL,
  QBO_CREDITMEMO_URL,
  QBO_VENDORCREDIT_URL,
} from './const';
const CustomRow: React.FC<RenderCustomRowProps<LedgerLineItem>> = (props) => {
  const {
    row,
    meta: { markedAsPosted },
  } = props;
  const currencyFormatter = useCurrencyFormatter();
  const intlDateFormat = useGetIntlDateFormatString();
  const quickbooksConnected = useSelector(getQuickbooksAccountIsConnected);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleIconClicked = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      const { ledgerType, externalTransactionId } = row;
      let url = '';

      switch (ledgerType?.toUpperCase()) {
        case 'INVOICE':
          url = QBO_INVOICE_URL;
          break;
        case 'JOURNAL ENTRY':
        case 'ADJUSTMENT':
        case 'ITEM RECEIPT':
          url = QBO_JOURNAL_URL;
          break;
        case 'BILL':
          url = QBO_BILL_URL;
          break;
        case 'PAYMENT':
          url = QBO_PAYMENT_URL;
          break;
        case 'CREDIT MEMO':
          url = QBO_CREDITMEMO_URL;
          break;
        case 'VENDOR CREDIT':
          url = QBO_VENDORCREDIT_URL;
          break;
        default:
          break;
      }

      if (url) {
        window.open(url + externalTransactionId, '_blank');
        event.stopPropagation();
      }
    },
    [row]
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const displayMarkAsPosted = () =>
    markedAsPosted ? (
      <Tooltip placement="top" title="Marked as posted">
        <TableCell>
          <Icon onDark={false} name={IconNames.Warning} />
        </TableCell>
      </Tooltip>
    ) : (
      <TableCell />
    );
  const displayPostedLink = () => {
    if (quickbooksConnected) {
      return (
        <>
          <Icon
            onDark={false}
            name={IconNames.QuickBook}
            height={24}
            width={24}
            onClick={handleIconClicked}
            data-qa="quickbook-icon"
          />

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          ></Popover>
        </>
      );
    }
  };
  return (
    <>
      <TableCell data-qa={`Date-${row.description}`}>
        {row.date ? moment(row.date).format(intlDateFormat) : ''}
      </TableCell>
      <TableCell data-qa={`Type/Ref-${row.description}`}>
        {row.ledgerType}
      </TableCell>
      <TableCell data-qa={`Payee/Account-${row.description}`}>
        <Typography>{row.payee}</Typography>
        <Typography variant="caption">
          {row.split.length === 1 ? row.split[0] : 'Split'}
        </Typography>
      </TableCell>
      <TableCell data-qa={`Memo-${row.description}`}>
        {row.description}
      </TableCell>
      <TableCell data-qa={`Posted-${row.description}`}>
        {row.externalTransactionId && displayPostedLink()}
      </TableCell>
      <TableCell data-qa={`Amount-${row.description}`}>
        {currencyFormatter(row.amount || 0)}
      </TableCell>
      <TableCell data-qa={`Balance-${row.description}`}>
        {currencyFormatter(row.balance || 0)}
      </TableCell>
      {displayMarkAsPosted()}
    </>
  );
};

export default React.memo(CustomRow);
