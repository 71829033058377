import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import _ from 'lodash';

import { MultiCurrencyWrapper } from 'ui/components/MultiCurrencyWrapper/MultiCurrencyWrapper';
import { RenderCustomFooterProps } from 'ui/components/Table/ItemsTable';
import { useCurrencyFormatter, toMulticurrencyCalculate } from 'helpers';

import { ReconcileWizardReconcileItemsFooterProps } from './types';

const ReconcileWizardItemsFooter: React.FC<
  RenderCustomFooterProps<ReconcileWizardReconcileItemsFooterProps>
> = (props) => {
  const { footerData } = props;

  const currencyFormatter = useCurrencyFormatter();
  const exchangeRate = _.get(footerData, 'currency.exchangeRate', 1);
  const activeMulticurrencyCode = _.get(footerData, 'currency.code', null);

  const multiCurrencyGrandTotal = toMulticurrencyCalculate(
    footerData!.grandTotalCost,
    exchangeRate
  );

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      height="100%"
      px={3}
    >
      <Grid item xs={3} xl={2}>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Typography component="div">
            Total: <b>{currencyFormatter(footerData!.grandTotalCost)}</b>
          </Typography>
        </Box>
      </Grid>
      <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
        <Grid item xs={3} xl={2}>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Typography component="div">
              Total:{' '}
              <b>
                {currencyFormatter(
                  multiCurrencyGrandTotal,
                  activeMulticurrencyCode
                )}
              </b>
            </Typography>
          </Box>
        </Grid>
      </MultiCurrencyWrapper>
    </Box>
  );
};

export default React.memo(ReconcileWizardItemsFooter);
