import { logErrorCtx } from 'app/logging';
import { Errors } from './types';

const requiredEnvVariables = [
  'REACT_APP_DD_APPLICATION_ID',
  'REACT_APP_DD_CLIENT_TOKEN',
  'REACT_APP_DD_SITE',
  'REACT_APP_DD_SERVICE',
  'REACT_APP_DD_ENVIRONMENT',
  'REACT_APP_DD_LOGGER_CLIENT_TOKEN',
  'REACT_APP_DD_FORWARD_ERRORS_TO_LOGS',
  'REACT_APP_DD_FORWARD_CONSOLE_LOGS',
];

const optionalEnvVariables = [
  'REACT_APP_DD_TRACK_INTERACTIONS',
  'REACT_APP_DD_MONITORING_SAMPLE_RATE',
  'REACT_APP_DD_SESSION_REPLAY_SAMPLE_RATE',
  'REACT_APP_DD_TRACING_SAMPLE_RATE',
  'REACT_APP_DD_TELEMETRY_SAMPLE_RATE',
  'REACT_APP_DD_FORWARD_REPORTS',
  'REACT_APP_DD_LOGGING_SAMPLE_RATE',
  'REACT_APP_DD_TELEMETRY_SAMPLE_RATE',
];

export const hasValidDatadogConfig = (env: any) => {
  const errors: Errors = {
    required: [],
    optional: [],
  };

  requiredEnvVariables.forEach((rev) => {
    if (env[rev] === undefined) {
      errors.required.push(rev);
    }
  });

  optionalEnvVariables.forEach((oev) => {
    if (env[oev] === undefined) {
      errors.optional.push(oev);
    }
  });

  if (errors.required.length > 0) {
    logErrorCtx('Missing Environmental Variables when initializing Datadog', {
      description: JSON.stringify(errors),
    });

    return false;
  }

  return true;
};
