import React, { memo, useMemo } from 'react';
import { Box, TableCell, Grid } from '@mui/material';
import { useSelector } from 'react-redux';

import { IconNames } from 'ui/theme';
import { Icon } from 'ui/components/Icon';
import { TextField } from 'ui/components/TextField/TextField';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import {
  PurchaseOrderItem,
  PurchaseOrderStatus,
} from 'services/purchaseOrders';
import { stopInputPropagation } from 'helpers';
import { getSettingsCompany } from 'services/settings/company';

import { MenuTableCell } from '../MenuTableCell';
import { editPurchaseOrderPermissions } from '../../../../helpers';

const NoteRow: React.FC<RenderCustomRowProps<PurchaseOrderItem>> = (props) => {
  const {
    row,
    index,
    setData,
    errors,
    meta: { purchaseOrder, activeMulticurrencyCode },
  } = props;

  const { homeCurrency, useMultiCurrency } = useSelector(getSettingsCompany);

  const homeCurrencyCode = (homeCurrency && homeCurrency.code) || 'USD';

  const hasMultiCurrencyColSpan = useMemo(
    () => useMultiCurrency && activeMulticurrencyCode !== homeCurrencyCode,
    [useMultiCurrency, activeMulticurrencyCode]
  );

  const fieldsDisabled = purchaseOrder.status === PurchaseOrderStatus.Fulfilled;

  const editPermission = editPurchaseOrderPermissions(purchaseOrder);

  const handleTextInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value ? e.target.value : null;
    setData({ ...row, name: value }, index);
  };

  return (
    <>
      <TableCell colSpan={hasMultiCurrencyColSpan ? 8 : 6}>
        <Grid container alignItems="center">
          <Grid item>
            <Box
              marginRight={2}
              width={40}
              height={40}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexShrink={0}
            >
              <Icon name={IconNames.Mode} noDefaultFill />
            </Box>
          </Grid>
          <Grid item xs>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Note"
              placeholder="Type note"
              name="name"
              autoComplete="off"
              disabled={fieldsDisabled}
              permissions={editPermission}
              value={row.name}
              onChange={handleTextInputChanged}
              onClick={stopInputPropagation}
              fullWidth
              error={!!errors.name}
              dataQa="purchase-order-item-note"
            />
          </Grid>
        </Grid>
      </TableCell>
      <MenuTableCell {...props} />
    </>
  );
};

export default memo(NoteRow);
