import {
  createApiReducer,
  chainReducers,
  INITIAL_API_STATE,
} from 'services/api';
import {
  createAddSearchReducer,
  createDeleteSearchReducer,
  createFetchSearchReducer,
  createUpdateSearchReducer,
} from 'services/search';
import { combineReducers } from 'redux';

import { UomsActionTypes } from './actions';
import { transformUom } from '../transformations';

const fetchUomsReducer = createApiReducer(
  [
    UomsActionTypes.FETCH_UOMS_START,
    UomsActionTypes.FETCH_UOMS_SUCCESS,
    UomsActionTypes.FETCH_UOMS_FAILURE,
  ],
  (data) => ({ items: data.results.map(transformUom) }),
  (data) => data
);

const fetchSearchReducer = createFetchSearchReducer('uom');
const addSearchReducer = createAddSearchReducer('uom');
const updateSearchReducer = createUpdateSearchReducer('uom');
const deleteSearchReducer = createDeleteSearchReducer('uom');

const uomChainedReducers = chainReducers(INITIAL_API_STATE, fetchUomsReducer);

const searchChainedReducers = chainReducers(
  INITIAL_API_STATE,
  fetchSearchReducer,
  addSearchReducer,
  updateSearchReducer,
  deleteSearchReducer
);

export default combineReducers({
  uoms: uomChainedReducers,
  searches: searchChainedReducers,
});
