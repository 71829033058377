import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { colorPalette } from 'ui/theme';

export const useProfileMenuStyle = makeStyles((theme: Theme) => ({
  drawerToggleLabel: {
    width: '100%',
    marginRight: 0,
    '& .MuiFormControlLabel-label ': {
      paddingLeft: theme.spacing(2),
      color: '#3F4043',
      fontSize: 'calc(14px * var(--font-size-multiplier, 1))',
    },
  },
  navigationLink: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    '&:focus': {
      color: colorPalette.fishbowlCorporateBlue,
    },
    '& svg': {
      marginRight: theme.spacing(1.5),
    },
  },
}));
