import React, { memo } from 'react';
import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { PermissionType } from 'services/permissions';
import { Pagination } from 'services/search';
import { ClassesSearchResultsProps } from './types';
import { CLASSES_DETAILS_COLUMNS } from './consts';
import ClassesRow from './ClassesRow';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const ClassesRulesSearchResults: React.FC<ClassesSearchResultsProps> = (
  props
) => {
  const {
    activeClassId,
    handleClassClicked,
    onAddNewPress,
    classes,
    isLoadingClasses,
    onPaginationChange,
    pagination,
  } = props;

  const handlePaginationChange = (newPagination: Pagination) => {
    onPaginationChange(newPagination);
  };

  return (
    <>
      <FBOTitleBar title="Classes">
        {activeClassId !== -1 && (
          <FBOButton
            variant={!activeClassId ? 'primary' : 'secondary'}
            color="positive"
            size="medium"
            icon="FBOAddCircle"
            onClick={onAddNewPress}
            permissions={[PermissionType.AccountingEdit]}
            data-qa="accounting-new-class-add-new"
          >
            {!activeClassId ? 'Add New' : ''}
          </FBOButton>
        )}
      </FBOTitleBar>

      <ItemsTable
        data={classes}
        columns={CLASSES_DETAILS_COLUMNS}
        RenderCustomRow={ClassesRow}
        activeId={activeClassId}
        onItemClick={handleClassClicked}
        emptyTableText={
          activeClassId !== -1
            ? "ADD NEW ENTRY BY PRESSING 'ADD NEW'"
            : 'NO CLASSES AVAILABLE'
        }
        selectableItems={false}
        isLoadingData={isLoadingClasses}
        pagination={pagination}
        onPaginationChange={handlePaginationChange}
        showEnhancedTablePaginationActions={!Boolean(activeClassId)}
        dataQa="accounting-classes"
      />
    </>
  );
};
export default memo(ClassesRulesSearchResults);
