import React, { memo } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ErrorIcon from '@mui/icons-material/Error';
import { useSelector } from 'react-redux';

import { getShippingIntegrationConnection } from 'services/integrations/shipping/redux';
import { ShippingConnectionType } from 'services/integrations/shipping';

import { useAddressStyle } from '../styled';
import { VerifiedAddressLabelProps } from './types';

const VerifiedAddressLabel: React.FC<VerifiedAddressLabelProps> = (props) => {
  const { verifiedLabelVisible } = props;

  const classes = useAddressStyle();

  const connection = useSelector(
    getShippingIntegrationConnection(ShippingConnectionType.Shippo)
  );

  return (
    <>
      {!!connection && (
        <Grid item xs={12}>
          {verifiedLabelVisible ? (
            <Box
              display="flex"
              textAlign="center"
              justifyContent="flex-end"
              mt="5px"
              mr="5px"
            >
              <VerifiedUserIcon style={{ color: 'green' }} />
              <Typography className={classes.iconValid}>
                Verified address
              </Typography>
            </Box>
          ) : (
            <Box
              display="flex"
              textAlign="center"
              justifyContent="flex-end"
              mt="5px"
              mr="5px"
            >
              <ErrorIcon style={{ color: 'red' }} />
              <Typography className={classes.iconError}>
                Not verified address
              </Typography>
            </Box>
          )}
        </Grid>
      )}
    </>
  );
};

export default memo(VerifiedAddressLabel);
