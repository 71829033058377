import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { PermissionType } from 'services/permissions';
import SecureRoute from 'ui/components/Route/SecureRoute';

import { Routes as PurchasingRoutes } from './routes';
import { VendorsPage } from '../pages';
import { PurchasingRouterProps, PurchasingRouterCmp } from './types';
import { PurchaseOrderPage } from '../pages/PurchaseOrderPage';
import { ReceivingPage } from '../pages/ReceivingPage';

const PurchasingRouter: PurchasingRouterCmp = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: PurchasingRouterProps
) => {
  return (
    <Routes>
      <Route
        element={<SecureRoute permissions={[PermissionType.VendorsView]} />}
      >
        <Route path={VendorsPage.route} element={<VendorsPage />} />
      </Route>
      <Route
        element={
          <SecureRoute permissions={[PermissionType.PurchaseOrderView]} />
        }
      >
        <Route path={PurchaseOrderPage.route} element={<PurchaseOrderPage />} />
      </Route>
      <Route
        element={<SecureRoute permissions={[PermissionType.ReceivingView]} />}
      >
        <Route path={ReceivingPage.route} element={<ReceivingPage />} />
      </Route>
    </Routes>
  );
};

PurchasingRouter.route = PurchasingRoutes.PurchasingRouter;

export default PurchasingRouter;
