import React from 'react';
import { Box } from '@mui/material';

import { Menu } from 'ui/components/Menu';
import { IconNames } from 'ui/theme';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

import { ButtonWithActionsProps } from './types';

const FBOButtonWithActions = (props: ButtonWithActionsProps) => {
  const { actionItems, text, onClick, buttonDisabled, dataQa } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const isActionMenuVisible = Boolean(anchorEl);

  const handleIconClicked = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleActionMenuClose = () => setAnchorEl(null);

  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <FBOButton
          variant="secondary"
          color="positive"
          size="medium"
          onClick={onClick}
          disabled={buttonDisabled}
          data-qa={`${dataQa}-add-button`}
        >
          {text}
        </FBOButton>
        <FBOButton
          variant="secondary"
          color="positive"
          size="medium"
          icon={IconNames.DownwardExpandArrow}
          onClick={handleIconClicked}
          data-qa={`${dataQa}-dropdown`}
          sx={{ marginLeft: '4px' }}
        />
      </Box>
      <Menu
        items={actionItems}
        open={isActionMenuVisible}
        anchorEl={anchorEl}
        onClose={handleActionMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      />
    </>
  );
};

FBOButtonWithActions.defaultProps = {
  actionItems: [],
  text: '',
  onClick: () => {},
};
export default FBOButtonWithActions;
