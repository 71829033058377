import React, { memo, useMemo } from 'react';

import { TableCell, Typography } from '@mui/material';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';
import { useCurrencyFormatter } from 'helpers';
import { InvoiceDetailsItem } from 'services/invoices';
import _ from 'lodash';
import { ImageItemBox } from 'ui/components/Image/ImageItemBox';
import { colorPalette } from 'ui/theme';
import { showNumberAsPercentage } from 'services/taxRates';
import { FBOQuantityFieldReadOnly } from 'ui/theme/components/FBOQuantityFieldReadOnly';

const InvoiceDetailsItemRow: React.FC<
  RenderCustomRowProps<InvoiceDetailsItem>
> = (props) => {
  const { row } = props;

  const currencyFormatter = useCurrencyFormatter();

  const rowIcon = useMemo(() => {
    const description = _.get(row, 'description', '');
    const source = _.get(row, 'image', null);
    const name = _.get(row, 'name', '');

    return (
      <ImageItemBox
        name={name}
        description={_.truncate(description!, { length: 30 })}
        source={source}
        isRedesign
      />
    );
  }, [row]);

  return (
    <>
      <TableCell style={{ cursor: 'default' }}>{rowIcon}</TableCell>
      <TableCell
        data-qa={`Unit-Price -${row.name}`}
        style={{ cursor: 'default' }}
      >
        <CurrencyField
          name="unit-price"
          value={row.price || 0}
          inputProps={{
            style: {
              color: colorPalette.redesign.contentSecondary,
            },
          }}
          style={{
            backgroundColor: `${colorPalette.redesign.background1}`,
            width: '150px',
            paddingLeft: '8px',
            borderRadius: '3px',
          }}
          allowNegative
          noMargin
          readOnly
        />
      </TableCell>
      <TableCell
        data-qa={`Quantity - ${row.name}`}
        style={{ cursor: 'default' }}
      >
        <FBOQuantityFieldReadOnly
          quantity={row.quantity || 0}
          uom={row.uom || null}
          noMargin
        />
      </TableCell>
      <TableCell
        align="left"
        data-qa={`Discount - ${row.name}`}
        style={{ cursor: 'default' }}
      >
        <Typography style={{ fontWeight: '600' }}>
          {currencyFormatter(row.discount || 0)}
        </Typography>
      </TableCell>
      <TableCell
        data-qa={`Tax-Rates-${row.name}`}
        style={{ cursor: 'default' }}
      >
        <Typography style={{ fontWeight: '600' }}>
          {showNumberAsPercentage(row.taxRate) + '%' || 0}
        </Typography>
      </TableCell>

      <TableCell
        align="right"
        style={{ cursor: 'default', marginRight: '16px' }}
      >
        <Typography
          variant="body2"
          color="textPrimary"
          data-qa={`subtotal-${row.name}`}
        >
          {currencyFormatter(row.subtotal || 0)}
        </Typography>

        <Typography
          data-qa={`tax-amount-${row.name}`}
          color={colorPalette.redesign.contentSecondary}
        >
          {currencyFormatter(row.taxTotal || 0)}
        </Typography>
      </TableCell>
      <TableCell style={{ width: '16px' }}></TableCell>
    </>
  );
};

export default memo(InvoiceDetailsItemRow);
