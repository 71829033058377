export const CONTAINER_SPACING_SIZE = 3;

export const getLabelName = (option: any) => option.displayName || option.name;

export const PRINT_STATUS = {
  id: 'printStatus',
  label: 'Print Status',
};

export const EMAIL_STATUS = {
  id: 'emailStatus',
  label: 'Email Status',
};

export const ADDRESS_TYPE = {
  id: 'addressType',
  label: 'Address Type',
};
