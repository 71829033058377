import React from 'react';
import { useSelector } from 'react-redux';

import {
  ItemsTable,
  useSelectedItemsChanges,
} from 'ui/components/Table/ItemsTable';
import { colorPalette } from 'ui/theme';
import { getOrderPriorities } from 'services/settings/orderPriorities';

import { PickingSearchResultsProps } from './types';
import {
  PICKING_COLUMNS,
  pickingPageActionOptions,
  PICKING_COLUMNS_SMALL,
} from './consts';
import PickingRow from './PickingRow';

import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';

const CustomersSearchResults: React.FC<PickingSearchResultsProps> = (props) => {
  const {
    activePickId,
    handlePickClick,
    picks,
    isLoadingPicks,
    activeDate,
    pagination,
    onPaginationChange,
    selectedItems,
    setSelectedItems,
    onPageAction,
    onVoidClicked,
    onPrintPickTicketsClicked,
  } = props;

  const { items: orderPriorities } = useSelector(getOrderPriorities);

  const alwaysOnColumns = PICKING_COLUMNS_SMALL;

  const handleSelectClick = useSelectedItemsChanges(
    selectedItems,
    setSelectedItems
  );

  return (
    <>
      <FBOTitleBar title="Picking">
        {selectedItems.length > 0 && !activePickId && (
          <FBOButton
            style={{ marginRight: '8px' }}
            variant="secondary"
            color="positive"
            size="medium"
            onClick={onPrintPickTicketsClicked}
            data-qa="print-pick-tickets-button"
          >
            Print Pick Tickets
          </FBOButton>
        )}
        {selectedItems.length > 0 && !activePickId && (
          <FBOButton
            style={{ marginRight: '8px' }}
            variant="secondary"
            color="negative"
            size="medium"
            onClick={onVoidClicked}
            data-qa="void-button"
          >
            Void
          </FBOButton>
        )}
        <FBOMenuButton
          variant="secondary"
          items={pickingPageActionOptions(onPageAction)}
          data-qa="picking-three-dot-menu-button"
          style={{ color: colorPalette.redesign.contentSecondary }}
        />
      </FBOTitleBar>

      <ItemsTable
        data={picks}
        RenderCustomRow={PickingRow}
        columns={
          activePickId
            ? alwaysOnColumns
            : PICKING_COLUMNS(orderPriorities, activeDate)
        }
        activeId={activePickId}
        onItemClick={handlePickClick}
        onSelectedChange={handleSelectClick}
        selectedItems={selectedItems}
        selectableItems={!activePickId}
        isLoadingData={isLoadingPicks}
        emptyTableText="no picks available"
        pagination={pagination}
        onPaginationChange={onPaginationChange}
        dataQa="picking-table"
        showEnhancedTablePaginationActions={!Boolean(activePickId)}
      />
    </>
  );
};

export default React.memo(CustomersSearchResults);
