import React, { memo, useCallback, useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import _ from 'lodash';

import { Modal } from 'ui/components/Modal/Modal';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { NetworkSpinnerWrapper } from 'ui/components/NetworkSpinnerWrapper';
import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { COMPANY_WIDE_ID, getLocations } from 'services/locations';
import { replaceValueInCollection, useLongPolling } from 'helpers';
import {
  PickItem,
  pickItemIsInventoryType,
  PickItemStatus,
} from 'services/picking';
import { Errors } from 'services/forms/validation';
import { initialPickItem } from 'services/picking/consts';
import { getSettingsPicking } from 'services/settings/picking/redux';

import { PickItemFinishRow } from './components';
import { PICK_ITEMS_COLUMNS, PICK_ITEMS_TABLE_HEIGHT } from './consts';
import {
  ErrorModal,
  PickFinishWizardButtons,
  PickFinishWizardProps,
} from './types';
import { usePickingModalStyle } from './styled';
import { isTrackingGroupAmountValid } from './helpers';
import { handleAutoFinish, pickHasExpiredItems } from '../../helpers';
import { SummaryTrackingTable } from '../SummaryTrackingTable';
import { finishColumns, splitColumns } from '../SummaryTrackingTable/consts';
import { OverPickingNotifyModal } from '../PickCommitWizard/OverPickingNotifyModal';
import { useNavigate } from 'react-router-dom';
import { LongPolling } from 'ui/components/Dialog/LongPolling';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { colorPalette, themeRestyle } from 'ui/theme';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOPickItemFinishDetails from './components/PickItemFinishDetails/FBOPickItemFinishDetails';
import { isTrackingAvailable } from '../PickCommitWizard/helpers';
import { getItemInventory, ItemInventory } from 'services/inventory';
import { concatRoute } from 'helpers/routes';
import { Routes } from 'ui/modules/sales/navigation';

const summaryFakePickItem = { ...initialPickItem, id: -1 };

const FBOPickFinishWizard: React.FC<PickFinishWizardProps> = (props) => {
  const { show, pickItems, onClose, onSave } = props;

  const classes = usePickingModalStyle(props);
  const navigate = useNavigate();
  const locations = useSelector(getLocations).items;
  const pickingSettings = useSelector(getSettingsPicking);

  const [formPickItems, setFormPickItems] = React.useState<PickItem[]>([]);
  const [activePickItem, setActivePickItem] =
    React.useState<PickItem>(initialPickItem);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { isPolling, notify, clearNotify, startPolling, stopPolling } =
    useLongPolling();
  const [trackingTableErrors, setTrackingTableErrors] = React.useState<
    Errors[]
  >([]);
  const [showNotifyWhenOverPickingModal, setShowNotifyWhenOverPickingModal] =
    useState(false);

  const [errorModal, setErrorModal] = React.useState<ErrorModal>({
    show: false,
    title: '',
    msg: '',
  });
  const [showExpiredItemsConfirmation, setShowExpiredItemsConfirmation] =
    useState(false);

  const [buttonPressed, setButtonPressed] =
    useState<PickFinishWizardButtons | null>(null);

  const isInventoryType = pickItemIsInventoryType(activePickItem);

  const isTrackingTableValid = useMemo(
    () => trackingTableErrors.filter((error) => !_.isEmpty(error)).length === 0,
    [trackingTableErrors]
  );
  const activeCommitSoNumber = useMemo(() => {
    const firstPickItem = _.get(
      formPickItems[0],
      'salesOrderItem.salesOrder.number',
      ''
    );
    return `Finish ${firstPickItem ? ' - SO ' + firstPickItem : ''}`;
  }, [formPickItems]);

  const isCommitReady = useMemo(() => {
    // commit is ready if all inventory pick items have some amount
    return formPickItems.some((i) => i.amount);
  }, [formPickItems]);

  const commitButtonDisabled =
    !isTrackingTableValid ||
    ((activePickItem.amount || 0) > (activePickItem.quantityAvailable || 0) &&
      isInventoryType);

  const itemsToBeSplit = useMemo(() => {
    return formPickItems.filter((i) => (i.amount || 0) < (i.quantity || 0));
  }, [formPickItems]);

  useEffect(() => {
    if (show) {
      // empty tracking groups to populate it from the inventory
      const availablePickItems = pickItems
        .filter(
          (i) =>
            i.status === PickItemStatus.Available ||
            i.status === PickItemStatus.Short ||
            i.status === PickItemStatus.Started
        )
        .map((i) => ({ ...i, trackingGroupList: [] }));

      setFormPickItems(availablePickItems);
      if (availablePickItems.length) {
        setActivePickItem(availablePickItems[0]);
      }
    } else {
      setActivePickItem(initialPickItem);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, pickItems]);

  const hideNotifyWhenOverPickingModal = useCallback(() => {
    setShowNotifyWhenOverPickingModal(false);
  }, []);

  const handleApplyClicked = useCallback(async () => {
    startPolling();
    // we want to send formPickItems and previously commited ones
    const previouslyCommitted = pickItems
      .filter((i) => i.status === PickItemStatus.Committed)
      .map((i) => ({ ...i, amount: i.quantity }));
    await onSave([...formPickItems, ...previouslyCommitted]);

    stopPolling();
  }, [
    formPickItems,
    pickItems,
    pickingSettings.pickingExpiredItemWarning,
    onSave,
  ]);

  const handleNextClicked = () => {
    if (
      pickingSettings.pickingExpiredItemWarning &&
      pickHasExpiredItems([activePickItem])
    ) {
      setButtonPressed(PickFinishWizardButtons.Next);
      setShowExpiredItemsConfirmation(true);
      return true;
    }
    nextCallback();
  };

  const nextCallback = useCallback(() => {
    if (
      ((activePickItem.amount || 0) > activePickItem.quantityAvailable! ||
        (activePickItem.amount || 0) > activePickItem.quantity!) &&
      isInventoryType
    ) {
      if (!pickingSettings.enableOverPicking) {
        setErrorModal({
          show: true,
          title: 'Overpicking is not enabled',
          msg: 'At the moment, overpicking is not supported. You can enable it under Settings > Picking',
        });
        return;
      } else if (
        pickingSettings.enableOverPicking &&
        pickingSettings.notifyWhenOverPicking
      ) {
        setShowNotifyWhenOverPickingModal(true);
      }
    }

    if (!isTrackingGroupAmountValid(activePickItem.trackingGroupList)) {
      setErrorModal({
        show: true,
        title: 'Wrong quantity',
        msg: 'You cannot commit more quantity in tracking group than is available.',
      });
      return;
    }

    const index = formPickItems.findIndex((i) => i.id === activePickItem.id);
    setFormPickItems(
      replaceValueInCollection(formPickItems, activePickItem, index)!
    );

    // set next pickItem as active
    if (formPickItems.length > index + 1) {
      setActivePickItem(formPickItems[index + 1]);
    } else {
      setActivePickItem(summaryFakePickItem);
    }
  }, [
    formPickItems,
    activePickItem,
    isInventoryType,
    pickingSettings.enableOverPicking,
    pickingSettings.notifyWhenOverPicking,
  ]);

  const handlePickItemClicked = useCallback(
    (itemId: number) => {
      setActivePickItem(
        formPickItems.find((i) => i.id === itemId) || summaryFakePickItem
      );
    },
    [formPickItems]
  );

  const handleAutoAssignClicked = () => {
    if (
      pickingSettings.pickingExpiredItemWarning &&
      pickHasExpiredItems([activePickItem])
    ) {
      setButtonPressed(PickFinishWizardButtons.AutoSelectAndContinue);
      setShowExpiredItemsConfirmation(true);
      return true;
    }
    autoAssignCallback();
  };

  const autoAssignCallback = useCallback(() => {
    const index = formPickItems.findIndex((i) => i.id === activePickItem.id);
    setFormPickItems(
      replaceValueInCollection(
        formPickItems,
        {
          ...activePickItem,
          quantity: activePickItem.quantity,
          autoAssign: true,
        },
        index
      )!
    );

    // set next pickItem as active
    if (formPickItems.length > index + 1) {
      setActivePickItem(formPickItems[index + 1]);
    } else {
      setActivePickItem(summaryFakePickItem);
    }
  }, [formPickItems, activePickItem]);

  const hideOverpickingModal = useCallback(() => {
    setErrorModal({
      show: false,
      title: '',
      msg: '',
    });
  }, []);

  const handleExpiredItemsConfirmClicked = () => {
    if (buttonPressed === PickFinishWizardButtons.Next) {
      nextCallback();
    } else if (
      buttonPressed === PickFinishWizardButtons.AutoSelectAndContinue
    ) {
      autoAssignCallback();
    }
    setShowExpiredItemsConfirmation(false);
  };

  const handleExpiredItemsCancelClicked = useCallback(() => {
    setShowExpiredItemsConfirmation(false);
  }, []);

  const handleClose = useCallback(() => {
    setTrackingTableErrors([]);
    onClose();
  }, [onClose]);
  const closeLongPollingDialog = () => {
    clearNotify();
    navigate(concatRoute(Routes.SalesRouter, Routes.PickingPage), {
      replace: true,
    });
  };
  const checkTracking = useMemo(() => {
    return isTrackingAvailable(formPickItems);
  }, [formPickItems]);

  const calculateAvailableQty = useCallback(
    (
      inventory: ItemInventory,
      pickFromLocationId: number | null,
      pickItem: PickItem
    ) => {
      if (!pickFromLocationId || pickFromLocationId === COMPANY_WIDE_ID) {
        return inventory.availableToPickQty;
      }

      const locationInventory = inventory.inventoryRowList.find(
        (row: any) => row.locationId === pickFromLocationId
      );

      if (locationInventory) {
        // find same item with same location
        const splittedItemsAmount = pickItems
          .filter((i) => i.itemId === pickItem.itemId)
          .reduce((acc, i) => acc + (i.amount || 0), 0);

        return Math.max(
          locationInventory.availableToPickQty - splittedItemsAmount,
          0
        );
      }

      return 0;
    },
    [pickItems]
  );

  const onAutoFinish = useCallback(() => {
    handleAutoFinish(
      formPickItems,
      setFormPickItems,
      setActivePickItem,
      summaryFakePickItem,
      isInventoryType,
      pickingSettings,
      setErrorModal,
      setShowNotifyWhenOverPickingModal,
      getItemInventory,
      calculateAvailableQty,
      setIsLoading
    );
  }, [
    formPickItems,
    setFormPickItems,
    setActivePickItem,
    summaryFakePickItem,
    isInventoryType,
    pickingSettings,
    setErrorModal,
    setShowNotifyWhenOverPickingModal,
    getItemInventory,
    calculateAvailableQty,
    setIsLoading,
  ]);
  const ModalActions = () => {
    return (
      <Box display="flex" justifyContent={'flex-end'} width="100%">
        <FBOButton
          variant="secondary"
          color="neutral"
          size="medium"
          onClick={handleClose}
          data-qa="picking-finish-wizard-cancel-button"
          sx={{ marginRight: '16px' }}
        >
          Cancel
        </FBOButton>
        <Box>
          {activePickItem.id &&
            activePickItem.id > 0 &&
            activePickItem.trackingGroupList.length > 0 && (
              <FBOButton
                variant="secondary"
                color="positive"
                size="medium"
                onClick={handleAutoAssignClicked}
                data-qa="picking-finish-wizard-select-and-commit"
                sx={{ marginRight: '16px' }}
              >
                Auto select and continue
              </FBOButton>
            )}
          {!checkTracking && activePickItem.id && activePickItem.id !== -1 && (
            <FBOButton
              variant="secondary"
              color="positive"
              size="medium"
              onClick={onAutoFinish}
              data-qa="picking-wizard-auto-commit-and-continue"
              sx={{ marginRight: '16px' }}
            >
              Auto Finish All and Continue
            </FBOButton>
          )}
          {activePickItem.id && activePickItem.id > 0 && (
            <FBOButton
              variant="primary"
              color="positive"
              size="medium"
              disabled={commitButtonDisabled}
              onClick={handleNextClicked}
              data-qa="picking-finish-wizard-finish-items"
            >
              Next
            </FBOButton>
          )}
          {activePickItem.id === -1 && (
            <FBOButton
              variant="primary"
              color="positive"
              size="medium"
              disabled={!isCommitReady}
              onClick={handleApplyClicked}
              data-qa="picking-finish-wizard-finish"
            >
              Finish
            </FBOButton>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Modal
        open={show}
        onApplyClicked={_.noop}
        onCancelClicked={handleClose}
        withoutDefaultPadding
        isLoadingContent={isLoading}
        title={activeCommitSoNumber}
        onResetClicked={_.noop}
        maxWidth="lg"
        nestedScrollAreas
        ModalActionsComponent={ModalActions}
      >
        <Grid
          container
          disableEqualOverflow
          sx={{
            paddingLeft: themeRestyle.spacing(3),
            paddingRight: themeRestyle.spacing(3),
          }}
        >
          <Grid xs={4}>
            <ItemsTable
              columns={PICK_ITEMS_COLUMNS}
              data={[...formPickItems, summaryFakePickItem]} // dirty hack to show summary page
              selectableItems={false}
              onItemClick={handlePickItemClicked}
              containerHeight={PICK_ITEMS_TABLE_HEIGHT}
              RenderCustomRow={PickItemFinishRow}
              activeId={activePickItem.id}
              tableLayoutFixed
              meta={{ isCommitReady }}
              dataQa="picking-item-finish-wizard-table"
              sx={{
                borderRadius: '5px',
                border: `1px solid ${colorPalette.redesign.background3}`,
                borderTop: 'none',
              }}
            />
          </Grid>
          {activePickItem.id !== null && activePickItem.id > 0 && (
            <Grid
              xs={8}
              sx={{ display: 'flex', flexDirection: 'column' }}
              paddingLeft={themeRestyle.spacing(3)}
              spacing={themeRestyle.spacing(2)}
            >
              <FBOPickItemFinishDetails
                pickItem={activePickItem}
                setPickItem={setActivePickItem}
                locations={locations}
                pickItems={formPickItems}
                trackingTableErrors={trackingTableErrors}
                setTrackingTableErrors={setTrackingTableErrors}
                setIsLoading={setIsLoading}
              />
            </Grid>
          )}
          {activePickItem.id === -1 && (
            <Grid
              xs={8}
              sx={{ display: 'flex', flexDirection: 'column' }}
              paddingLeft={themeRestyle.spacing(3)}
              spacing={themeRestyle.spacing(2)}
            >
              <FBOTitleBar title="Items to be finished" />
              <SummaryTrackingTable
                data={formPickItems.filter((i) => i.amount)}
                columns={finishColumns}
                emptyTableText={'No Items'}
                isCommitTable
                sx={{
                  borderRadius: '5px',
                  border: `1px solid ${colorPalette.redesign.background3}`,
                  borderTop: 'none',
                  height: '200px',
                }}
              />
              <FBOTitleBar title="Unassigned Items To Be Split To New Lines" />
              <SummaryTrackingTable
                data={itemsToBeSplit}
                columns={splitColumns}
                emptyTableText={'No Items'}
                sx={{
                  borderRadius: '5px',
                  border: `1px solid ${colorPalette.redesign.background3}`,
                  borderTop: 'none',
                  height: '200px',
                }}
              />
            </Grid>
          )}
          <Box className={isPolling ? classes.centeredSpinner : undefined}>
            {/* Empty fragment used as a placeholder for required children props */}
            <NetworkSpinnerWrapper
              isLoading={!!isLoading}
              size={24}
              isPolling={isPolling}
            >
              <></>
            </NetworkSpinnerWrapper>
          </Box>
        </Grid>
      </Modal>
      <ConfirmationModal
        open={errorModal.show}
        title={errorModal.title}
        body={errorModal.msg}
        onCancelClicked={hideOverpickingModal}
        onConfirmClicked={hideOverpickingModal}
      />
      {pickingSettings.pickingExpiredItemWarning && (
        <ConfirmationModal
          open={showExpiredItemsConfirmation}
          title="Item Expired"
          body="The item you have selected has already expired.  Would you like to proceed?"
          onCancelClicked={handleExpiredItemsCancelClicked}
          onConfirmClicked={handleExpiredItemsConfirmClicked}
          confirmLabel="Continue"
          confirmButtonRed
          showCloseIcon={true}
        />
      )}
      <OverPickingNotifyModal
        open={showNotifyWhenOverPickingModal}
        onCancel={hideNotifyWhenOverPickingModal}
        title="Over-Picking"
        content="The quantity entered is larger than ordered"
        onContinue={hideNotifyWhenOverPickingModal}
      />
      <LongPolling
        open={notify}
        onClose={closeLongPollingDialog}
        title="Pick processing"
        content="Your finish is processing. Check back later."
        dataQa="Pick-processing-dialog"
      />
    </>
  );
};

export default memo(FBOPickFinishWizard);
