import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { FBOAutoComplete, FBOTextField } from 'ui/theme/components';
import PasswordRequirements, {
  passwordRequirementsMet,
} from 'ui/components/TextField/PasswordRequirements/PasswordRequirements';
import { LoadingArrowButton } from 'ui/components/LoadingArrowButton';
import {
  CreateAccountFormValues,
  initialCreateAccountForm,
  registerUser,
} from 'services/auth';
import { Errors } from 'services/forms/validation';
import { getSettingsCompanyCountry } from 'services/settings/company/redux';
import { Timezone } from 'timezones.json';
import { TIMEZONES } from 'helpers/timezones';
import { COUNTRIES } from 'helpers/countries';
import { CountryType } from 'helpers';
import { flags as countryFlags } from 'ui/theme/images/flags';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { AccontSetupAlertMessage, AccountSetupAlertTitle } from './consts';
import _ from 'lodash';

const emailRegExp = /(\W|^)[\w.-]{1,25}@(.+)\..+(\W|$)/;
const justNumbers = (value: string) => value.replaceAll(/\D/g, '');

const VALIDATION_SCHEMA = (key: string = '', value: string = ''): string => {
  let error = '';
  switch (key) {
    case 'email':
      if (isEmpty(value)) {
        error = 'Field is required';
      } else if (!value.match(emailRegExp)) {
        error = 'Invalid email';
      }
      break;
    case 'firstName':
      if (isEmpty(value)) error = 'Field is required';
      break;
    case 'lastName':
      if (isEmpty(value)) error = 'Field is required';
      break;
    case 'companyName':
      if (isEmpty(value)) error = 'Field is required';
      break;
    case 'country':
      if (isEmpty(value)) error = 'Field is required';
      break;
    case 'companyPhone':
      if (isEmpty(value)) {
        error = 'Field is required';
      } else if (
        value.length >= 10 &&
        value.length <= 15 &&
        !Number(justNumbers(value))
      ) {
        error = 'Invalid phone';
      }
      break;
    case 'timezone':
      if (isEmpty(value)) error = 'Field is required';
      break;
    case 'currencyCountry':
      if (isEmpty(value)) error = 'Field is required';
      break;
    case 'password':
      if (isEmpty(value)) {
        error = 'Field is required';
      } else if (!passwordRequirementsMet(value)) {
        error = 'Password does not meet requirements';
      }
      break;
    case 'eulaAccepted':
      if (value === 'false') error = 'Please accept the Terms of Service';
      break;
  }
  return error;
};

/**
 * checks obj to see if there is any key that has a value
 */
const hasValues = (obj: Errors | CreateAccountFormValues): boolean =>
  !!Object.values(obj).find((e: string) => e !== '');

interface CreateAccountFormProps {
  afterRegistration(): void;
}

export const CreateAccountForm: React.FC<CreateAccountFormProps> = ({
  afterRegistration,
}) => {
  const [formValues, setFormValues] = useState<CreateAccountFormValues>(
    initialCreateAccountForm
  );
  const [errors, setErrors] = useState<Errors>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requirementsMet, setRequirementsMet] = useState<boolean>(false);

  const defaultCountry = useSelector(getSettingsCompanyCountry);

  const checkPasswordRequirements = () => {
    setRequirementsMet(passwordRequirementsMet(formValues.password));
  };
  useEffect(checkPasswordRequirements, [formValues]);

  const handleFormSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    if (!validateForm()) return;
    if (!requirementsMet) return;

    setIsLoading(true);
    try {
      await registerUser({
        ...formValues,
        companyPhone: justNumbers(formValues.companyPhone),
      });
      setIsLoading(false);
      afterRegistration();
    } catch (error: any) {
      setIsLoading(false);
    }
  };

  const validateForm = (): boolean => {
    const {
      email,
      firstName,
      lastName,
      companyName,
      country,
      companyPhone,
      timezone,
      currencyCountry,
      password,
      eulaAccepted,
    } = formValues;

    const countryCode = country?.code || '';
    const tz = timezone?.value || '';
    const currencyCode = currencyCountry?.currencyCode || '';

    const formErrors = {
      firstName: VALIDATION_SCHEMA('firstName', firstName),
      lastName: VALIDATION_SCHEMA('lastName', lastName),
      email: VALIDATION_SCHEMA('email', email),
      companyName: VALIDATION_SCHEMA('companyName', companyName),
      country: VALIDATION_SCHEMA('country', countryCode),
      companyPhone: VALIDATION_SCHEMA('companyPhone', companyPhone),
      timezone: VALIDATION_SCHEMA('timezone', tz),
      currencyCountry: VALIDATION_SCHEMA('currencyCountry', currencyCode),
      password: VALIDATION_SCHEMA('password', password),
      eulaAccepted: VALIDATION_SCHEMA('eulaAccepted', eulaAccepted.toString()),
    };

    setErrors({ ...formErrors });

    if (hasValues(formErrors)) return false;

    return true;
  };

  const validateInput = (input: { name: string; value: string }): string => {
    const name = input.name;
    const value = input.value;
    return VALIDATION_SCHEMA(name, value);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { name, checked, type, value } = event.target;
    setErrors({ ...errors, [name]: '' });

    const resolvedValue = type === 'checkbox' ? checked : value;
    setFormValues((old) => ({
      ...old,
      [name]: resolvedValue,
    }));
  };

  const handleAutocompleteChange =
    (id: 'timezone' | 'country' | 'currencyCountry') =>
    (_: any, value: any) => {
      setErrors({ ...errors, [id]: '' });
      setFormValues((old) => ({
        ...old,
        [id]: value,
      }));
    };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    const { name } = event.target;
    const { value } = event.target;
    const error = validateInput({ name, value });
    if (error.length) setErrors({ ...errors, [name]: error });
  };

  const hasErrors = useMemo(
    () => hasValues(errors) && !requirementsMet,
    [errors, requirementsMet]
  );

  const countryOptions = useMemo(() => {
    const index = COUNTRIES.findIndex((c) => c.code === defaultCountry);

    if (index > -1) {
      const moveToFirst = COUNTRIES[index];
      const countries = [
        ...COUNTRIES.slice(0, index),
        ...COUNTRIES.slice(index + 1),
      ];
      return [moveToFirst, ...countries];
    }

    return COUNTRIES;
  }, [defaultCountry]);

  return (
    <>
      <form onSubmit={handleFormSubmit} noValidate>
        <input hidden={true} name="address" />
        {/*This input is required to prevent Chrome autofill from overriding our settings*/}
        <Grid className="redesign" container spacing={{ xs: 2 }}>
          <Grid item xs={6}>
            <FBOTextField
              autoFocus
              required
              name="firstName"
              className="redesign"
              type="text"
              label="First Name"
              value={formValues.firstName}
              error={!!errors.firstName}
              errorText={errors.firstName}
              dataQa="firstName"
              allowLastPassIcon
              onChange={handleInputChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={6}>
            <FBOTextField
              required
              name="lastName"
              className="redesign"
              type="text"
              label="Last Name"
              value={formValues.lastName}
              error={!!errors.lastName}
              errorText={errors.lastName}
              dataQa="lastName"
              allowLastPassIcon
              onChange={handleInputChange}
              onBlur={handleBlur}
            />
          </Grid>
        </Grid>
        <Grid className="redesign" container>
          <Grid item xs={12}>
            <FBOTextField
              required
              name="email"
              className="redesign"
              type="text"
              label="Email"
              value={formValues.email}
              error={!!errors.email}
              errorText={errors.email}
              dataQa="email"
              allowLastPassIcon
              onChange={handleInputChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12}>
            <FBOTextField
              required
              name="companyName"
              className="redesign"
              type="text"
              label="Company"
              value={formValues.companyName}
              error={!!errors.companyName}
              errorText={errors.companyName}
              dataQa="company"
              allowLastPassIcon
              onChange={handleInputChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12}>
            <FBOAutoComplete
              required
              name="country"
              className="redesign"
              label="Country"
              placeholder={errors.country ? '' : 'Select Country'}
              options={countryOptions}
              getOptionLabel={(option: CountryType) => option.label}
              renderOption={(CountryAutocompleteProps, option: CountryType) => (
                <li {...CountryAutocompleteProps} key={option.code}>
                  <img
                    src={countryFlags[option.code]}
                    alt=""
                    width="20px"
                    height="auto"
                  />
                  <span style={{ marginLeft: 8 }}>
                    {option.label} ({option.code})
                  </span>
                </li>
              )}
              value={formValues.country}
              error={!!errors.country}
              errorText={errors.country}
              dataQa="country"
              allowLastPassIcon
              onChange={handleAutocompleteChange('country')}
            />
          </Grid>
          <Grid item xs={12}>
            <FBOTextField
              required
              name="companyPhone"
              className="redesign"
              type="text"
              label="Phone"
              value={formValues.companyPhone}
              error={!!errors.companyPhone}
              errorText={errors.companyPhone}
              dataQa="phoneNumber"
              allowLastPassIcon
              onChange={handleInputChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12}>
            <FBOAutoComplete
              required
              name="timezone"
              className="redesign"
              label="Time Zone"
              placeholder={errors.timezone ? '' : 'Select Time Zone'}
              options={TIMEZONES}
              getOptionLabel={(option: Timezone) => option.text}
              value={formValues.timezone}
              error={!!errors.timezone}
              errorText={errors.timezone}
              dataQa="timeZone"
              allowLastPassIcon
              onChange={handleAutocompleteChange('timezone')}
            />
          </Grid>
          <Grid item xs={12}>
            <FBOAutoComplete
              required
              name="currencyCountry"
              className="redesign"
              label="Currency"
              placeholder={errors.currencyCountry ? '' : 'Select Currency'}
              options={countryOptions}
              getOptionLabel={(option: CountryType) => option.currencyName}
              renderOption={(CountryAutocompleteProps, option: CountryType) => (
                <li {...CountryAutocompleteProps} key={option.code}>
                  <img
                    src={countryFlags[option.code]}
                    alt=""
                    width="20px"
                    height="auto"
                  />
                  <span style={{ marginLeft: 8 }}>
                    {option.label} ({option.code})
                  </span>
                </li>
              )}
              value={formValues.currencyCountry}
              error={!!errors.currencyCountry}
              errorText={errors.currencyCountry}
              dataQa="currency"
              allowLastPassIcon
              onChange={handleAutocompleteChange('currencyCountry')}
            />
          </Grid>
          <Grid item xs={12} mb={'4px !important'}>
            <FBOTextField
              required
              name="password"
              className="redesign"
              type="password"
              label="Password"
              value={formValues.password}
              error={!!errors.password}
              errorText={errors.password}
              dataQa="password"
              allowLastPassIcon
              onChange={handleInputChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordRequirements value={formValues.password} />
          </Grid>
          <Grid item xs={12} mb={'32px !important'}>
            <FormControlLabel
              className="redesign"
              control={
                <Checkbox
                  required
                  name="eulaAccepted"
                  className="redesign"
                  size="small"
                  checked={formValues.eulaAccepted}
                  onChange={handleInputChange}
                  color={errors.eulaAccepted ? 'error' : 'primary'}
                  inputProps={
                    {
                      'data-qa': 'eula',
                    } as any
                  }
                  disableRipple
                />
              }
              label={
                <>
                  Accept the
                  <Link
                    className="redesign"
                    color="textSecondary"
                    variant="body1"
                    href="https://www.fishbowlinventory.com/eula"
                    target="_blank"
                    data-qa="terms-of-service"
                    underline="hover"
                    sx={{
                      fontSize: '13px !important',
                      marginLeft: '0.3em',
                    }}
                  >
                    Terms of Service
                  </Link>
                </>
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            // todo : move after redesign
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <LoadingArrowButton
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              data-qa="submit"
              disabled={hasErrors || isLoading}
              loadingPosition="start"
              loading={isLoading}
            >
              Create Account
            </LoadingArrowButton>
          </Grid>
          <Grid item xs={12} justifyContent={'center'}>
            <Typography variant="body2" className="redesign" fontSize={'15px'}>
              Already have an account?&nbsp;
              <Link
                className="redesign"
                color="textSecondary"
                component={RouterLink}
                to="/login"
                data-qa="login"
                underline="hover"
              >
                Log In
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </form>
      <ConfirmationModal
        open={isLoading}
        title={AccountSetupAlertTitle}
        body={AccontSetupAlertMessage}
        onConfirmClicked={_.noop}
        onCancelClicked={_.noop}
        confirmLabel=""
        cancelLabel=""
        DialogActionsComponent={() => null}
      />
    </>
  );
};

export default CreateAccountForm;
