import * as yup from 'yup';

import { USCountryString } from 'helpers';
import { Errors, Fields, validateYup } from 'services/forms/validation';
import { ItemTrackingType } from 'services/inventory';

export const yupItemGeneralTabSchema = (country: string) => {
  const schema = yup.object().shape({
    name: yup.string().required(),
    defaultUomId: yup.number().required(),
    cost: yup.number().nullable(true).min(0),
    weight: yup.number().nullable(true).min(0),
    length: yup.number().nullable(true).min(0),
    width: yup.number().nullable(true).min(0),
    sku: yup.string().nullable(true).min(0).max(255),
    height: yup.number().nullable(true).min(0),
    itemType: yup.string().required(),
    itemUomConversionList: yup.array().of(
      yup.object().shape({
        factor: yup.number().moreThan(0),
      })
    ),
  });

  if (country !== USCountryString) {
    return schema.shape({
      salesTaxId: yup.number().required(),
    });
  }
  return schema;
};
export const yupItemTrackingTabSchema = yup.object().shape({
  itemTrackingTypeList: yup.array().of(
    yup.object().shape({
      nextValue: yup
        .string()
        .test(
          'hasNumber',
          'Next value must be blank or contain at least one digit',
          function (value) {
            return value ? /\d/.test(value) : true;
          }
        )
        .nullable(true),
    })
  ),
});

export const yupSaleItemSchema = (country: string) => {
  const schema = yup.object().shape({
    name: yup.string().required(),
    defaultUomId: yup.number().required(),
    price: yup.number().nullable(true).min(0),
    weight: yup.number().nullable(true).min(0),
    length: yup.number().nullable(true).min(0),
    sku: yup.string().nullable(true).min(0).max(255),
    width: yup.number().nullable(true).min(0),
    height: yup.number().nullable(true).min(0),
    url: yup.string().nullable(true),
  });

  if (country !== 'US') {
    return schema.shape({
      salesTaxId: yup.number().required(),
    });
  }

  return schema;
};

export const yupBundleItemSchema = yup.object().shape({
  saleItemId: yup.number().required(),
  quantity: yup
    .number()
    .test('quantity', 'More than 0', (val) => (val ? val > 0 : false))
    .required(),
  price: yup.number().required(),
});

export const mapTrackingTabErrorsByIds = (
  errors: Errors,
  trackingTypeList: ItemTrackingType[]
) => {
  const mappedErrors: number[] = [];
  trackingTypeList.forEach((c, index) => {
    if (errors[`itemTrackingTypeList[${index}].nextValue`]) {
      mappedErrors.push(c.trackingTypeId!);
    }
  });
  return mappedErrors;
};

export const yupValidateSkuField = yup.object().shape({
  sku: yup.string().nullable(true).min(0).max(255),
});

export const isSkuFieldValid = (
  fields: Fields,
  setErrors: React.Dispatch<React.SetStateAction<Errors>>
) => {
  if (!validateYup(fields, yupValidateSkuField, setErrors)) {
    return false;
  }

  return true;
};
