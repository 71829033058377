import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useShipItemRowHeaderStyle = makeStyles((theme: Theme) => ({
  smallColumn: {
    width: theme.spacing(19),
  },
  mediumColumn: {
    width: theme.spacing(26),
  },
  cartonName: {
    fontWeight: 700,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  linkButton: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    borderRadius: theme.spacing(3),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  button: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    borderRadius: theme.spacing(4),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  buttonDisabled: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    borderRadius: theme.spacing(4),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'rgba(0, 0, 0, 0.24)',
  },
  iconDisabled: {
    fill: 'rgba(0, 0, 0, 0.24)',
  },
}));
