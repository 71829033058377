import { PermissionUser } from 'services/permissions';
import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const GROUP_USERS_COLUMNS: Column[] = [
  {
    title: 'Name',
    field: 'firstName',
    type: ColumnTypes.custom,
    render: (row: PermissionUser) => `${row.firstName} ${row.lastName}`,
  },
  {
    title: 'Email',
    field: 'email',
    type: ColumnTypes.string,
  },
];
