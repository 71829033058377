import React, { memo } from 'react';
import { TableCell } from '@mui/material';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { Currency } from 'services/currencies';

const CurrencyRow: React.FC<RenderCustomRowProps<Currency>> = (props) => {
  const { row: currencyRow } = props;

  return (
    <>
      <TableCell>{currencyRow.name}</TableCell>
      <TableCell>{currencyRow.code}</TableCell>
    </>
  );
};

export default memo(CurrencyRow);
