import _ from 'lodash';

import {
  getSearches,
  addSearch,
  updateSearch,
  deleteSearch,
} from 'services/inventory';
import {
  AdvancedSearchFormValues,
  AdvancedSearchReduxFunctions,
} from 'services/search';
import { PaginationDirection } from 'services/url';
import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';
import { Location } from 'services/locations';
import { Tag } from 'services/tags';

export const advancedSearchReduxActions: AdvancedSearchReduxFunctions = {
  add: addSearch,
  delete: deleteSearch,
  update: updateSearch,
  get: getSearches,
};

export const initialInventoryFormValues: AdvancedSearchFormValues = {
  name: null,
  description: null,
  locationId: null,
  'tags.id': null,
  'itemTrackingTypes.trackingType.trackingDataType': null,
  trackingValue: null,
  includeChildren: null,
  hideZeroQuantityItems: null,
};

export const displayNameMap = {
  name: 'Name',
  description: 'Description',
  locationId: 'Location',
  'tags.id': 'Tag',
  'itemTrackingTypes.trackingType.trackingDataType': 'Tracking type',
  trackingValue: 'Tracking value',
  includeChildren: 'Include children',
  hideZeroQuantityItems: 'Hide Zero Quantity Items',
};

export const createDisplayValueMap = (locations: Location[], tags: Tag[]) => ({
  locationId: (id: number) =>
    _.get(
      locations.find((l) => l.id === id),
      'path',
      null
    ),
  'tags.id': () => tags.map((t) => t.name).join(', '),
});

export const ITEM_COLUMNS_HIDABLE: Column[] = [
  {
    title: 'Name',
    field: 'name',
    type: ColumnTypes.string,
  },
  {
    title: 'On Hand',
    align: 'center',
    field: 'onHand',
    type: ColumnTypes.string,
    searchable: false,
    width: 70,
  },
  {
    title: 'UOM',
    field: 'defaultUom.name',
    sortable: true,
    type: ColumnTypes.string,
    searchable: true,
    width: 50,
  },
];

export const ITEM_COLUMNS_ALWAYS_ON: Column[] = [
  {
    title: 'Name',
    field: 'name',
    sortable: true,
    width: 300,
    type: ColumnTypes.string,
  },
  {
    title: 'Barcode / UPC',
    field: 'upc',
    sortable: true,
    type: ColumnTypes.string,
    width: 150,
  },
  {
    title: 'SKU',
    field: 'sku',
    sortable: true,
    type: ColumnTypes.string,
    width: 130,
  },
  {
    title: 'On Hand',
    align: 'center',
    field: 'onHand',
    type: ColumnTypes.string,
    searchable: false,
    width: 100,
  },
  {
    title: 'Allocated',
    align: 'center',
    field: 'id',
    type: ColumnTypes.string,
    searchable: false,
    width: 100,
  },
  {
    title: 'Committed',
    align: 'center',
    field: 'id',
    type: ColumnTypes.string,
    searchable: false,
    width: 100,
  },
  {
    title: 'Available',
    align: 'center',
    field: 'id',
    type: ColumnTypes.string,
    searchable: false,
    width: 100,
  },
  {
    title: 'Incoming',
    align: 'center',
    field: 'id',
    type: ColumnTypes.string,
    searchable: false,
    width: 100,
  },
  {
    title: 'Cost',
    field: 'cost',
    sortable: true,
    type: ColumnTypes.string,
    searchable: false,
    width: 100,
  },
  {
    title: 'UOM',
    field: 'defaultUom.name',
    sortable: true,
    type: ColumnTypes.string,
    searchable: true,
    width: 50,
  },
  {
    title: '',
    width: 50,
    field: '',
    type: ColumnTypes.string,
    searchable: false,
  },
];

const ITEM_COLUMNS_ALWAYS_ON_COPY = _.cloneDeep(ITEM_COLUMNS_ALWAYS_ON);

export const ITEM_COLUMNS_ALWAYS_ON_ADJUST_SIZES: Column[] =
  ITEM_COLUMNS_ALWAYS_ON_COPY.map((columnItem) => {
    if (columnItem.title === 'Name') {
      columnItem.width = 250;
    }

    return columnItem;
  });

export const ITEM_COLUMNS: Column[] = [
  ...ITEM_COLUMNS_ALWAYS_ON,
  ...ITEM_COLUMNS_HIDABLE,
];

export const initialPagination = {
  page: 1,
  pageSize: 25,
  sort: {
    sortBy: 'dateLastModified',
    direction: PaginationDirection.Descending,
  },
  totalRows: 25,
};
