import React, { memo, useCallback } from 'react';

import { PermissionType } from 'services/permissions';

import { PurchaseOrderTitleBarProps } from './types';
import { hasDropShipItem } from './helpers';
import { PurchaseOrderActiveIdState } from '../../types';
import { createActionBarOptions } from '../../consts';
import {
  shouldShowIssue,
  shouldShowSave,
  shouldShowUnissue,
} from '../../helpers';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';

const PurchaseOrderTitleBar: React.FC<PurchaseOrderTitleBarProps> = (props) => {
  const {
    activePurchaseOrder,
    onSave,
    onClose,
    onIssueClicked,
    onUnIssueClicked,
    duplicateClicked,
    duplicateAsCreditReturnClicked,
    onDeleteClicked,
    showReportModal,
    emailClicked,
    onUndeleteClicked,
  } = props;

  const DeletedPurchaseOrderActionsRestyle = useCallback(() => {
    return (
      <>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="purchase-order-save"
          permissions={
            activePurchaseOrder.id && activePurchaseOrder.id > 0
              ? [PermissionType.PurchaseOrderEdit]
              : [PermissionType.PurchaseOrderCreate]
          }
          onClick={() => onUndeleteClicked(false)}
        >
          Undelete
        </FBOButton>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="purchase-order-save-and-close"
          permissions={
            activePurchaseOrder.id && activePurchaseOrder.id > 0
              ? [PermissionType.PurchaseOrderEdit]
              : [PermissionType.PurchaseOrderCreate]
          }
          onClick={() => onUndeleteClicked(true)}
        >
          Undelete And Close
        </FBOButton>
      </>
    );
  }, [activePurchaseOrder.id, onUndeleteClicked]);

  const PurchaseOrderActionsRestyle = useCallback(() => {
    return (
      <>
        {shouldShowUnissue(activePurchaseOrder.status) && (
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="secondary"
            color="positive"
            size="medium"
            data-qa="purchase-order-unissue"
            permissions={[PermissionType.PurchaseOrderIssue]}
            onClick={onUnIssueClicked}
          >
            Unissue
          </FBOButton>
        )}
        {shouldShowIssue(activePurchaseOrder.status) && (
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="secondary"
            color="positive"
            size="medium"
            data-qa="purchase-order-issue"
            permissions={[PermissionType.PurchaseOrderIssue]}
            onClick={onIssueClicked}
          >
            Issue
          </FBOButton>
        )}
        {shouldShowSave(activePurchaseOrder.status) && (
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="secondary"
            color="positive"
            size="medium"
            data-qa="purchase-order-save"
            onClick={() => onSave(false)}
            permissions={
              activePurchaseOrder.id && activePurchaseOrder.id > 0
                ? [PermissionType.PurchaseOrderEdit]
                : [PermissionType.PurchaseOrderCreate]
            }
          >
            Save
          </FBOButton>
        )}
        {shouldShowSave(activePurchaseOrder.status) && (
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="secondary"
            color="positive"
            size="medium"
            data-qa="purchase-order-save-and-close"
            permissions={
              activePurchaseOrder.id && activePurchaseOrder.id > 0
                ? [PermissionType.PurchaseOrderEdit]
                : [PermissionType.PurchaseOrderCreate]
            }
            onClick={async () => {
              await onSave(true);
              onClose();
            }}
          >
            Save And Close
          </FBOButton>
        )}
        <FBOMenuButton
          sx={{ marginRight: '8px' }}
          variant="tertiary"
          data-qa="purchase-order-three-dot-menu"
          items={createActionBarOptions(
            activePurchaseOrder.id,
            onDeleteClicked,
            duplicateClicked,
            duplicateAsCreditReturnClicked,
            () => showReportModal(true),
            emailClicked,
            activePurchaseOrder.status,
            hasDropShipItem(activePurchaseOrder.purchaseOrderItemList)
          )}
        />
      </>
    );
  }, [
    activePurchaseOrder.id,
    activePurchaseOrder.status,
    activePurchaseOrder.purchaseOrderItemList,
    onDeleteClicked,
    duplicateClicked,
    duplicateAsCreditReturnClicked,
    showReportModal,
    emailClicked,
    onSave,
    onIssueClicked,
    onUnIssueClicked,
  ]);

  return (
    <FBOTitleBar
      title={`PO${
        activePurchaseOrder.number ? ` - ${activePurchaseOrder.number}` : ''
      } ${
        activePurchaseOrder.id === PurchaseOrderActiveIdState.Duplicate
          ? 'Copy'
          : ''
      }`}
      status={activePurchaseOrder.status}
    >
      {activePurchaseOrder.deleted ? (
        <DeletedPurchaseOrderActionsRestyle />
      ) : (
        <PurchaseOrderActionsRestyle />
      )}
      <FBOButton
        variant="tertiary"
        color="neutral"
        size="medium"
        icon="FBOClose"
        data-qa="purchase-order-close"
        onClick={onClose}
      />
    </FBOTitleBar>
  );
};

export default memo(PurchaseOrderTitleBar);
