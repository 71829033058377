import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable/types';

export const resolvedInvoiceDetailsItemsColumns = (
  currencyCode: string
): Column[] => [
  {
    title: 'Item',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: `Unit Price ${currencyCode}`,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Quantity',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Discount',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Tax Rate',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: `Subtotal ${currencyCode}`,
    align: 'right',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: '',
    type: ColumnTypes.renderByCustomRow,
  },
];
