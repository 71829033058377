import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { theme } from 'ui/theme';
import { colorPalette } from 'ui/theme';

import { ImageItemStyle } from './types';

const DEFAULT_IMAGE_HEIGHT = 40;
const DEFAULT_IMAGE_WIDTH = 40;

export const useImageStyles = makeStyles<Theme, ImageItemStyle>(() => ({
  imageStyle: {
    height: ({ height }) => (height ? height : DEFAULT_IMAGE_HEIGHT),
    width: ({ width }) => (width ? width : DEFAULT_IMAGE_WIDTH),
    objectFit: ({ objectFit }) => (objectFit ? objectFit : 'contain'),
  },
  noImage: {
    minHeight: ({ height }) => (height ? height : DEFAULT_IMAGE_HEIGHT),
    minWidth: ({ width }) => (width ? width : DEFAULT_IMAGE_WIDTH),
    height: ({ height }) => (height ? height : DEFAULT_IMAGE_HEIGHT),
    width: ({ width }) => (width ? width : DEFAULT_IMAGE_WIDTH),
    backgroundColor: colorPalette.applicationDarkGray,
    color: theme.palette.common.white,
    position: 'relative',
    lineHeight: 1,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noImageIcon: {
    opacity: 0.6,
  },
}));
