import { Address, initialAddress } from 'services/addresses';
import { Ship } from 'services/shipping';

export const transformShipToAddress = (ship: Ship): Address => {
  return {
    ...initialAddress,
    name: ship.shipToName,
    country: ship.shipToCountry,
    companyName: ship.shipToCompanyName,
    postalCode: ship.shipToPostalCode,
    residential: ship.shipToResidential,
    city: ship.shipToCity,
    street: ship.shipToStreet,
    street2: ship.shipToStreet2,
    state: ship.shipToState,
  };
};
