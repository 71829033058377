import { DataWithPagination } from 'services/api';
import { Pagination } from 'services/search';
import { PaginationDirection } from 'services/url';

import { Account, WaitingToExport } from './types';

export const initialAccount: Account = {
  id: null,
  version: null,
  name: null,
  accountType: null,
  number: null,
  accountMappingId: null,
  integrationsTypeFilter: null,
};

export const initialToExportData: WaitingToExport = {
  bills: 0,
  inventoryAdjustments: 0,
  invoices: 0,
  itemReceipts: 0,
  payments: 0,
  creditMemos: 0,
  journalEntries: 0,
  vendorCredits: 0,
};

export enum WaitingToExportLedgers {
  InventoryAdjustments = 'Inventory Adjustments',
  Invoices = 'Invoices',
  CreditMemos = 'Credit Memos',
  Payments = 'Payments',
  ItemReceipts = 'Item Receipts',
  Bills = 'Bills',
  VendorCredits = 'Vendor Credits',
  JournalEntries = 'Journal Entries',
}

export const initialPagination: Pagination = {
  page: 1,
  pageSize: 25,
  sort: {
    sortBy: 'dateCreated',
    direction: PaginationDirection.Descending,
  },
  totalRows: 25,
};

export const initialDataWithPagination: DataWithPagination = {
  data: [],
  pagination: initialPagination,
};
