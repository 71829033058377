import React from 'react';
import {
  TableHead,
  TableCell,
  TableRow,
  Checkbox,
  TableSortLabel,
} from '@mui/material';

import { PaginationDirection } from 'services/url/pagination';

import { EnhancedHeaderProps } from './types';
import { useEnhancedHeaderStyles } from './styled';

import clsx from 'clsx';
import { IconNames, colorPalette, icons } from 'ui/theme';
import { Icon } from 'ui/components/Icon';

const EnhancedHeader: React.FC<EnhancedHeaderProps> = (props) => {
  const {
    columnProperties,
    selectableItems,
    rowCount,
    disableSelection,
    numSelected,
    onSelectAllClick,
    sort,
    onSort,
    dataQa,
  } = props;

  const classes = useEnhancedHeaderStyles(props);

  const handleSortClicked = (field: string) => () => {
    if (sort && onSort) {
      // if clicked field is not currently sorted OR sort direction is desc, sort asc
      const direction =
        field !== sort.sortBy ||
        sort.direction === PaginationDirection.Descending
          ? PaginationDirection.Ascending
          : PaginationDirection.Descending;

      onSort({ sortBy: field, direction });
    }
  };

  return (
    <TableHead className={classes.headerStyle}>
      <TableRow style={{ minWidth: '100%' }}>
        {selectableItems ? (
          <TableCell className={classes.selectCheckboxRedesign}>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              disabled={Boolean(disableSelection)}
              color="primary"
              size="small"
              inputProps={{ 'data-qa': 'enhanced-header-checkbox' } as any}
              className={`redesign ${classes.cellCheckboxRedesign}`}
              icon={
                <Icon
                  name={IconNames.FBOUncheckedCircle}
                  stroke={colorPalette.redesign.background4}
                />
              }
              checkedIcon={<Icon name={IconNames.FBOCheckedCircle} />}
              indeterminateIcon={
                <Icon name={IconNames.FBOIndeterminateCircle} />
              }
              disableRipple
            />
          </TableCell>
        ) : (
          <TableCell style={{ minWidth: 0, width: 0, maxWidth: 0 }} />
        )}
        {columnProperties.map((property, index) => (
          <TableCell
            key={`${property.title}ColumnTitle${index}`}
            className={`${classes.headerText} ${
              property.fitWidth ? classes.fitWidth : ''
            }`}
            align={property.align}
            style={{ width: property.width }}
            data-qa={`${dataQa}-column-${property.title}`}
          >
            {!!sort && property.sortable ? (
              <TableSortLabel
                className={clsx({ redesign: true })}
                active={property.field === sort.sortBy}
                direction={
                  property.field === sort.sortBy
                    ? sort.direction
                    : PaginationDirection.Ascending
                }
                onClick={handleSortClicked(property.field)}
                IconComponent={icons.IconCaratFilled}
              >
                {property.title}
              </TableSortLabel>
            ) : (
              property.title
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedHeader;
