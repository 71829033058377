import * as yup from 'yup';

import { CustomField, CustomFieldType } from 'services/customFields';
import { Errors } from 'services/forms/validation';
import { isValidUrl } from 'helpers';

const customFieldTypeFilter = (
  customFieldType: CustomFieldType,
  required: boolean | any
): any => {
  if (!required && customFieldType !== CustomFieldType.Url) {
    return;
    //Else if added to deal with Safari issue where .nullable() needs to be hard coded into the check
    //to avoid errors on null and non required url fields
  } else if (!required && customFieldType === CustomFieldType.Url) {
    return yup.string().nullable().test(isValidUrl);
  }

  switch (customFieldType) {
    case CustomFieldType.Text:
      return yup.string();
    case CustomFieldType.Number:
      return yup.number();
    case CustomFieldType.Url:
      return yup.string().test(isValidUrl);
    case CustomFieldType.Date:
      return yup.date();
    case CustomFieldType.SingleChoice:
      return yup.string();
    case CustomFieldType.MultipleChoice:
      return yup.array().of(yup.string());
  }
};

const customFieldValues = yup
  .mixed()
  .when(['customFieldType', 'required'], customFieldTypeFilter);

export const customFieldsYupSchema = yup
  .array()
  .of(yup.object({ customFieldValues }));

export const mapErrorsByIds = (errors: Errors, customFields: CustomField[]) => {
  const mappedErrors: number[] = [];
  customFields.forEach((c, index) => {
    if (errors[`[${index}].customFieldValues`]) {
      mappedErrors.push(c.id!);
    }
  });
  return mappedErrors;
};
