import { createApiCall } from 'services/api';

import {
  transformPurchaseOrderSettings,
  transformToPurchaseSettingsPut,
} from './transformations';
import { PurchaseOrderSettings } from './types';

export const getPurchaseSettings = async () => {
  const purchaseSettingsResponse = await createApiCall({
    path: `/v1/purchase_order_settings`,
    method: 'GET',
  })();

  return transformPurchaseOrderSettings(purchaseSettingsResponse.data);
};

export const putPurchaseSettings = async (
  purchaseSettings: PurchaseOrderSettings
) => {
  const purchaseSettingsResponse = await createApiCall(
    {
      path: `/v1/purchase_order_settings?expand=purchaseTax`,
      method: 'PUT',
      body: transformToPurchaseSettingsPut(purchaseSettings),
    },
    {
      getMessage: {
        success: () => 'Updated purchase order settings',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformPurchaseOrderSettings(purchaseSettingsResponse.data);
};
