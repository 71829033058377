import { AccountingSettings } from './types';

export const transformAccountingSettings = (
  response: any
): AccountingSettings => {
  return {
    id: response.id,
    version: response.version,
    accountingMethod: response.accountingMethod,
    accountsReceivableId: response.accountsReceivableId,
    accountsPayableId: response.accountsPayableId,
    undepositedFundsId: response.undepositedFundsId,
    nonInventoryId: response.nonInventoryId,
    serviceId: response.serviceId,
    salesRetailId: response.salesRetailId,
    discountExpenseId: response.discountExpenseId,
    discountIncomeId: response.discountIncomeId,
    miscellaneousExpenseId: response.miscellaneousExpenseId,
    shippingIncomeId: response.shippingIncomeId,
    shippingExpenseId: response.shippingExpenseId,
    shippingAccrualId: response.shippingAccrualId,
    costOfGoodsSoldId: response.costOfGoodsSoldId,
    scrapInventoryId: response.scrapInventoryId,
    holdingId: response.holdingId,
    inventoryAdjustmentId: response.inventoryAdjustmentId,
    costVarianceId: response.costVarianceId,
    flatRateTaxId: response.flatRateTaxId,
    salesTaxPayableId: response.salesTaxPayableId,
    inventoryAssetId: response.inventoryAssetId,
    laborExpenseId: response.laborExpenseId,
    overheadExpenseId: response.overheadExpenseId,
  };
};

export const transformToAccountingSettingsPut = (
  accountingSettings: AccountingSettings
) => ({
  id: accountingSettings.id,
  version: accountingSettings.version,
  accountingMethod: accountingSettings.accountingMethod,
  accountsPayableId: accountingSettings.accountsPayableId,
  accountsReceivableId: accountingSettings.accountsReceivableId,
  undepositedFundsId: accountingSettings.undepositedFundsId,
  nonInventoryId: accountingSettings.nonInventoryId,
  serviceId: accountingSettings.serviceId,
  salesRetailId: accountingSettings.salesRetailId,
  discountExpenseId: accountingSettings.discountExpenseId,
  discountIncomeId: accountingSettings.discountIncomeId,
  miscellaneousExpenseId: accountingSettings.miscellaneousExpenseId,
  shippingIncomeId: accountingSettings.shippingIncomeId,
  shippingExpenseId: accountingSettings.shippingExpenseId,
  shippingAccrualId: accountingSettings.shippingAccrualId,
  costOfGoodsSoldId: accountingSettings.costOfGoodsSoldId,
  scrapInventoryId: accountingSettings.scrapInventoryId,
  holdingId: accountingSettings.holdingId,
  inventoryAdjustmentId: accountingSettings.inventoryAdjustmentId,
  costVarianceId: accountingSettings.costVarianceId,
  flatRateTaxId: accountingSettings.flatRateTaxId,
  salesTaxPayableId: accountingSettings.salesTaxPayableId,
  inventoryAssetId: accountingSettings.inventoryAssetId,
  laborExpenseId: accountingSettings.laborExpenseId,
  overheadExpenseId: accountingSettings.overheadExpenseId,
});
