import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const finishColumns: Column[] = [
  {
    title: 'Item',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Qty at Loc.',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Qty to Commit',
    type: ColumnTypes.renderByCustomRow,
  },
];

export const splitColumns: Column[] = [
  {
    title: 'Item',
    type: ColumnTypes.renderByCustomRow,
  },

  {
    title: 'Quantity Remaining',
    type: ColumnTypes.renderByCustomRow,
  },
];
