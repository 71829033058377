import * as yup from 'yup';

export const yupReceivingExpenseSchema = yup.object().shape({
  itemId: yup.number().required(),
  quantity: yup.number().min(1, 'Miniumum is 1').required().typeError(' '),
  cost: yup.number().min(1, 'Miniumum is 1').required().typeError(' '),
});

export const yupReceivingRejectSchema = yup.object().shape({
  quantity: yup.number().required(),
  rejectionResponsibility: yup.string().required(),
  reasonForRejection: yup.string().required(),
});

export const receiptItemsYupSchema = yup.object().shape({
  dateBilled: yup.date().nullable(true),
  carrierId: yup.number().required(),
});
