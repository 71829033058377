import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography, Paper, Grid } from '@mui/material';

import { Modal } from 'ui/components/Modal/Modal';
import { removeValueFromCollection } from 'helpers';
import { showNotification } from 'services/api';
import { uploadDocuments } from 'services/documents/api';

import { FileDropZoneProps } from './types';
import { useDropZoneStyle } from './styled';
import { faultyFilesChecker } from './helpers';
import { FAULTYFILETYPES } from './consts';
import _ from 'lodash';
import { logErrorCtx } from 'app/logging';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { IconNames } from 'ui/theme';

const FileDropZone: React.FC<FileDropZoneProps> = (
  props: FileDropZoneProps
) => {
  const {
    onSave,
    storageType,
    permissions = [],
    buttonTitle = 'UPLOAD',
    disabled = false,
  } = props;

  const [files, setFiles] = useState<File[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);

  const classes = useDropZoneStyle(files);

  const handleDrop = useCallback(
    (droppedFiles: File[]) => {
      const filteredFiles = droppedFiles.filter(
        (df) => !files.find((f) => f.name === df.name)
      );

      if (filteredFiles.length !== droppedFiles.length) {
        showNotification('Duplicate files have been removed.', {
          variant: 'warning',
        });
      }
      setFiles((old) => [...old, ...filteredFiles]);
    },
    [files]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
  });

  const handleDeleteClicked = useCallback(
    (file: File) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      const deletedFileIndex = files.findIndex((f) => f.name === file.name);

      setFiles(removeValueFromCollection(files, deletedFileIndex)!);
    },
    [files]
  );

  const handleCancelClicked = useCallback(() => {
    setFiles([]);
    setModalVisible(false);
  }, []);

  const handleBrowseClicked = useCallback(
    (e) => {
      const rootProps = getRootProps();
      (rootProps as any).onClick(e);
    },
    [getRootProps]
  );

  const handleSaveClicked = useCallback(async () => {
    if (!files.length) {
      return;
    }

    if (faultyFilesChecker(files)) {
      return;
    }

    setIsModalLoading(true);

    try {
      const res = await uploadDocuments(storageType, files);
      await onSave(res);
    } catch (e) {
      const error = e as Error;
      logErrorCtx('Error in uploading files', {
        error,
        stackTrace: error.stack,
        title: error.message,
        description: 'Failed to upload files successfully.',
        component: 'FileDropZone -> FileDropZone',
      });
      showNotification(
        _.get(
          e,
          'response.data.errorMessage',
          'Drive service error. Please contact support.'
        ),
        { variant: 'error' }
      );
      setIsModalLoading(false);
      return;
    }
    setModalVisible(false);
    setIsModalLoading(false);
    setFiles([]);
  }, [files, onSave, storageType]);

  const handleAddImagesPress = useCallback(() => {
    setModalVisible(true);
  }, []);

  const renderDocument = (file: File) => {
    const fileExtension = file.name.split('.')[1];
    const isFaulty = FAULTYFILETYPES.includes(fileExtension);

    return (
      <Box
        key={file.name}
        className={classes.card}
        style={{
          backgroundColor: isFaulty ? 'rgba(250,227,230,0.5)' : 'white',
        }}
      >
        <Box className={classes.cardRow}>
          <Box className={classes.cardContent}>
            <Typography variant="body1" color="textPrimary">
              <b>{file.name}</b>
            </Typography>
          </Box>
          <Box ml="auto">
            <FBOButton
              variant="tertiary"
              color="negative"
              size="small"
              icon={IconNames.TrashCan}
              onClick={handleDeleteClicked(file)}
              data-qa={'delete-document-button'}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box className={classes.container}>
      <FBOButton
        disabled={disabled}
        onClick={handleAddImagesPress}
        permissions={permissions}
        variant="secondary"
        color="positive"
        size="medium"
        data-qa="sales-order-upload-documents"
      >
        {buttonTitle.charAt(0).toUpperCase() +
          buttonTitle.slice(1).toLowerCase()}
      </FBOButton>

      <Modal
        open={modalVisible}
        onClose={handleCancelClicked}
        title="Upload Documents"
        onCancelClicked={handleCancelClicked}
        onResetClicked={handleBrowseClicked}
        onApplyClicked={handleSaveClicked}
        applyLabel="Add"
        resetLabel="Browse"
        isLoadingContent={isModalLoading}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper
              {...getRootProps()}
              className={classes.documentsPaper}
              variant="outlined"
            >
              <input {...getInputProps()} />
              {files.length ? (
                <Grid container spacing={2}>
                  {files.map(renderDocument)}
                </Grid>
              ) : (
                <Typography variant="subtitle2">
                  DRAG AND DROP FILES HERE
                </Typography>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Modal>
    </Box>
  );
};

export default React.memo(FileDropZone);
