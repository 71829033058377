import { ShipCarton } from 'services/shipping';
import { DimensionsInputParams } from './types';
/**
 * Transforms dimensions from ShipCarton to DimensionsInputParams
 * @param carton
 */
export const transformToDimensions = (
  carton: ShipCarton
): DimensionsInputParams => ({
  width: carton.width || 0,
  height: carton.height || 0,
  length: carton.length || 0,
  dimensionUnit: carton.dimensionUnit,
});
