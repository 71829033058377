import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';

import {
  AccountingSettings,
  getAccountingSettings,
  putAccountingSettings,
} from 'services/settings/accounting';
import { fetchAccounts, Account } from 'services/accounting';
import { PermissionType } from 'services/permissions';

import { Card } from '../Card';
import { searchFilter, searchTitle } from '../../helpers';
import { AccountingCardProps } from './types';
import { accountingRowDescriptor, title, accountingCardId } from './consts';

const AccountingCard: React.FunctionComponent<AccountingCardProps> = (
  props
) => {
  const { search } = props;

  const [accountingSettings, setAccountingSettings] =
    useState<AccountingSettings>();
  const [accounts, setAccounts] = useState<Account[]>([]);

  const regex = useMemo(() => {
    return RegExp(_.escapeRegExp(search), 'gi');
  }, [search]);

  const hasSearch = useMemo(() => {
    return accountingRowDescriptor(accounts).some((t) => regex.test(t.title));
  }, [accounts, regex]);

  const hasTitle = useMemo(() => {
    return new RegExp(_.escapeRegExp(search), 'i').test(title);
  }, [search]);

  const highlightedRow = useMemo(() => {
    if (hasSearch) {
      return searchFilter(accountingRowDescriptor(accounts), regex);
    }
    return accountingRowDescriptor(accounts);
  }, [accounts, hasSearch, regex]);

  const highlightedTitle = useMemo(() => {
    if (hasTitle) {
      return searchTitle(title, regex);
    }
    return title;
  }, [hasTitle, regex]);

  useEffect(() => {
    const apiCall = async () => {
      try {
        const accountingSettingsFromApi = await getAccountingSettings();
        const accountsFromApi = await fetchAccounts();
        setAccounts(accountsFromApi);
        setAccountingSettings(accountingSettingsFromApi);
      } catch (e) {
        // continue
      }
    };

    apiCall();
  }, []);

  const handleApplyClicked = useCallback(async (newAccountingSettings) => {
    try {
      const accountingSettingsFromApi = await putAccountingSettings(
        newAccountingSettings
      );
      setAccountingSettings(accountingSettingsFromApi);
    } catch (e) {
      // continue
    }
  }, []);

  return (
    <Card
      title={highlightedTitle}
      rows={highlightedRow}
      id={accountingCardId}
      data={accountingSettings}
      onApplyClicked={handleApplyClicked}
      show={hasSearch || hasTitle}
      viewPermissions={[PermissionType.SettingsAccountMappingView]}
      editPermissions={[PermissionType.SettingsAccountMappingEdit]}
    />
  );
};

export default memo(AccountingCard);
