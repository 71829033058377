import React, { useEffect, useState } from 'react';
import { Box, TableCell, Typography } from '@mui/material';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import { Channel, ChannelStatus } from 'services/commerce/channels/types';
import { StatusLabel } from 'ui/components/Status/StatusLabel';
import { useCustomRowStyles } from './styled';
import moment from 'moment';
import { VendorIcon } from '../VendorIcon';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const PageCustomRow: React.FC<RenderCustomRowProps<Channel>> = (props) => {
  const { row, meta } = props;
  const [timeSinceLastSync, setTimeSinceLastSync] = useState('');

  const classes = useCustomRowStyles();

  const rowStatus =
    row?.status === 'CONNECTED'
      ? ChannelStatus.Connected
      : ChannelStatus.Disconnected;

  useEffect(() => {
    const syncedDay = moment(row.syncedOn);
    const today = moment();

    const diff = today.diff(syncedDay);

    const duration = moment.duration(diff);
    const years = duration.years();
    const months = duration.months();
    const weeks = duration.weeks();
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    if (years) {
      setTimeSinceLastSync(`${years} year${years > 1 ? 's' : ''} ago`);
    } else if (months) {
      setTimeSinceLastSync(`${months} month${months > 1 ? 's' : ''} ago`);
    } else if (weeks) {
      setTimeSinceLastSync(`${weeks} week${weeks > 1 ? 's' : ''} ago`);
    } else if (days) {
      setTimeSinceLastSync(`${days} day${days > 1 ? 's' : ''} ago`);
    } else if (hours) {
      setTimeSinceLastSync(`${hours} hour${hours > 1 ? 's' : ''} ago`);
    } else if (minutes) {
      setTimeSinceLastSync(`${minutes} minute${minutes > 1 ? 's' : ''} ago`);
    } else {
      setTimeSinceLastSync(`${seconds} second${seconds === 1 ? '' : 's'} ago`);
    }
  }, [row.syncedOn]);

  return (
    <>
      <TableCell className={classes.vendor} data-qa={`Vendor- ${row.id}`}>
        <VendorIcon className={classes.icon} row={row} />
        {!meta.activeItemId && row.vendor}
      </TableCell>
      <TableCell data-qa={`Name- ${row.id}`}>{row.label}</TableCell>

      {!meta.activeItemId && (
        <>
          <TableCell>
            <Box>
              <StatusLabel
                status={rowStatus}
                data-qa={`channel-row${row.id}-status`}
              />
            </Box>
          </TableCell>
          <TableCell data-qa={`Synchronized- ${row.id}`}>
            {row.configuration === 'CONFIGURED' ? (
              timeSinceLastSync
            ) : (
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <ErrorOutlineIcon
                  sx={{
                    width: '24px',
                    height: '24px',
                    marginRight: '4px',
                    color: 'red',
                  }}
                />
                Channel Settings not Configured
              </Typography>
            )}
          </TableCell>{' '}
        </>
      )}
    </>
  );
};

export default React.memo(PageCustomRow);
