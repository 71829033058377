import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { colorPalette } from 'ui/theme';

import { DRAWER_WIDTH, DRAWER_SMALL_WIDTH } from './consts';

import { TopBkgSvg } from 'ui/theme/images/styleguide/TopBkgSvg';
import { MiddleBkgSvg } from 'ui/theme/images/styleguide/MiddleBkgSvg';
import { BottomBkgSvg } from 'ui/theme/images/styleguide/BottomBkgSvg';

export const useAppDrawerStyle = makeStyles((theme: Theme) => ({
  drawer: {
    backgroundColor: colorPalette.redesign.backgroundMenu,
    backgroundImage: `${TopBkgSvg}, ${MiddleBkgSvg}, ${BottomBkgSvg}`,
    backgroundRepeat: 'no-repeat, no-repeat, no-repeat',
    backgroundPosition: 'top right, center right, bottom left',
  },
  drawerOpen: {
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    width: DRAWER_SMALL_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  buttonBlock: {
    marginTop: 'auto',
    position: 'sticky',
    backgroundColor: theme.palette.appDrawer.primary,
    color: 'white',
    bottom: 0,
    display: 'flex',
  },
  expander: {
    backgroundColor: colorPalette.transparent,
    '&:hover': {
      backgroundColor: colorPalette.transparent,
    },
  },
}));

export const useAddDrawerFooterStyle = makeStyles(() => ({
  drawerFooter: {
    backgroundColor: colorPalette.redesign.backgroundMenuSecondary,
    color: colorPalette.redesign.contentTertiary,
    margin: 0,
    position: 'sticky',
    bottom: '0',
  },
  profile: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    padding: (props: Record<string, boolean>) =>
      props.drawerExpanded ? '18px 0px 16px 16px' : '16px 10px',
    '&.active': {
      backgroundColor: colorPalette.redesign.background4,
      color: colorPalette.redesign.contentPrimary,
    },
    maxWidth: (props: Record<string, boolean>) =>
      props.commerceIntegrationUiRefactor ? '139px' : 'unset',
  },
  avatar: {
    padding: '0px',
    margin: '0px',
    '& > svg': {
      height: '36px',
      width: '36px',
      margin: '0px',
    },
  },
  names: {
    overflow: 'hidden',
    margin: '0px 8px',
    '& .name': {
      fontSize: '12px',
      maxWidth: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '&.user-name': {
        fontWeight: 600,
      },
      '&.company-name': {
        fontWeight: 400,
      },
    },
  },
  expandIcon: {
    position: 'sticky',
    bottom: 0,
    left: 180,
    borderRadius: '0px',
    backgroundColor: (props: Record<string, boolean>) =>
      props.commerceIntegrationUiRefactor &&
      props.isProfileMenuOpen &&
      props.drawerExpanded
        ? colorPalette.redesign.background4
        : 'transparent',
    color: (props: Record<string, boolean>) =>
      props.commerceIntegrationUiRefactor &&
      props.isProfileMenuOpen &&
      props.drawerExpanded
        ? colorPalette.redesign.contentPrimary
        : colorPalette.redesign.contentTertiary,
    padding: (props: Record<string, boolean>) =>
      props.drawerExpanded ? '12px' : '12px 0px',
    width: (props: Record<string, boolean>) =>
      props.drawerExpanded ? 'fit-content' : '100%',
    paddingTop: '0px',
    margin: '0px',
    '& > svg': {
      height: '36px',
      width: '36px',
      margin: '0px',
    },
  },
  goToCommerceButton: {
    display: (props: Record<string, boolean>) =>
      props.drawerExpanded ? 'flex' : 'none',
    background: colorPalette.white,
    color: colorPalette.redesign.contentSecondary,
    fontSize: '12px',
    fontWeight: '600',
    textTransform: 'capitalize',
    margin: '16px 8px 8px 8px',
    width: 'calc(100% - 16px)',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&:hover': {
      color: colorPalette.white,
      background: colorPalette.redesign.contentSecondary,
    },
  },
  profileAndExpandButtonContainer: {
    display: 'flex',
    flexDirection: (props: Record<string, boolean>) =>
      props.commerceIntegrationUiRefactor && props.drawerExpanded
        ? 'row'
        : 'column',
    width: '100%',
  },
}));

export const useItemStyle = makeStyles<Theme>(() => ({
  iconLoading: {
    width: 24,
    height: 24,
    borderRadius: 12,
    backgroundColor: '#555',
  },
  loadingText: {
    color: 'red',
    display: 'block',
    textIndent: -9999,
    borderRadius: 4,
    animationDuration: '1s',
    animationFillMode: 'forwards',
    animationIterationCount: 'infinite',
    animationName: '$placeHolderShimmer',
    animationTimingFunction: 'linear',
    background: 'linear-gradient(to right, #444 8%, #666 18%, #444 33%)',
    backgroundSize: '800px 24px',
    position: 'relative',
    height: 24,
  },
}));
