import { Representative } from 'services/representatives';
import { Customer } from 'services/customers';
import { ItemReference } from 'services/api';
import { SaleItem } from 'services/items/saleItems';
import { TaxRate } from 'services/taxRates';
import { Location } from 'services/locations';
import { CustomField } from 'services/customFields';
import { PaymentTerm } from 'services/paymentTerms';
import { Item } from 'services/items';
import { Document } from 'services/documents';
import { Currency } from 'services/currencies';
import { StockStatus } from 'ui/theme/components/FBOStockStatusTag/types';

export enum SalesOrderType {
  Sale = 'SALE',
  Return = 'RETURN',
  DropShip = 'DROP_SHIP',
  Mixed = 'MIXED',
  Unknown = 'UNKNOWN',
}

export interface DisplaySalesOrder {
  id: number;
  number: number | null;
  salesOrderStatus: SalesOrderStatus | null;
  salesOrderType: SalesOrderType | null;
  stockStatus: string | null;
  customerId: number | null;
  customer: {
    id: number;
    name: string;
  } | null;
  representativeId: string | null;
  representative: {
    id: number;
    initials: string;
  } | null;
  locationId: number | null;
  location: {
    id: number;
    name: string;
  } | null;
  source: string | null;
  dateCreated: Date | null;
  dateLastModified: Date | null;
  dateIssued: Date | null;
  total: number | null;
  paymentTotal: number | null;
  deleted: boolean;
}

export interface SalesOrder {
  accountingClassId: number | null;
  id: number | null;
  billToCity: string | null;
  billToCountry: string | null;
  billToName: string | null;
  billToState: string | null;
  billToStreet: string | null;
  billToStreet2: string | null;
  billToPostalCode: string | null;
  billToResidential: boolean;
  billToCompanyName: string | null;
  billToAttention: string | null;
  billToVerified: boolean;
  billToPhone: string | null; // @beta: additionally added for testing
  billToEmail: string | null; // @beta: additionally added for testing
  carrierId: number | null;
  carrierServiceId: number | null;
  channelSource: string | null;
  customerId: number | null;
  customer: Customer | null;
  customerPONumber: string | null;
  customFields: CustomField[];
  dateCreated: Date | null;
  dateExpires: Date | null;
  dateFulfilled: Date | null;
  dateIssued: Date | null;
  dateLastModified: Date | null;
  dateScheduled: string | null;
  dateDue: Date | null;
  deleted: boolean;
  documents: Document[];
  dropShipPurchaseOrders: DropShipPurchaseOrders[];
  emails: string | null;
  emailStatus: string | null;
  exchangeCurrency: Currency | null;
  exchangeCurrencyId: number | null;
  exchangeRate: number | null;
  fobPointId: number | null;
  grandTotal: number | null;
  homeCurrency: Currency | null;
  homeCurrencyId: number | null;
  locationId: number | null;
  location: Location | null;
  // multi currency part
  multiCurrencyDiscountTotal: number | null;
  multiCurrencyGrandTotal: number | null;
  multiCurrencyOrderLevelDiscountTotal: number | null;
  multiCurrencyPaymentTotal: number | null;
  multiCurrencySubtotal: number | null;
  multiCurrencyTaxTotal: number | null;
  // end
  margin: number | null;
  marginPercent: number | null;
  markupPercent: number | null;
  markup: number | null;
  notes: string | null;
  number: string | null;
  orderLevelDiscountFlatAmount: number | null;
  orderLevelDiscountPercent: number | null;
  paymentTerm: PaymentTerm | null;
  paymentTermId: number | null;
  paymentTotal: number | null;
  payments: SalesOrderPayment[] | null;
  picks: ItemReference[];
  phone: string | null;
  printStatus: string | null;
  priorityId: number | null;
  representativeId: number | null;
  representative: Representative | null;
  salesOrderItemList: SalesOrderItem[];
  salesTax: TaxRate | null;
  salesTaxId: number | null;
  status: SalesOrderStatus;
  shipToResidential: boolean;
  shippingTermId: number | null;
  shipToCity: string | null;
  shipToCountry: string | null;
  shipToName: string | null;
  shipToState: string | null;
  shipToStreet: string | null;
  shipToStreet2: string | null;
  shipToPostalCode: string | null;
  shipToCompanyName: string | null;
  shipToAttention: string | null;
  shipToPhone: string | null; // @beta: additionally added for testing
  shipToEmail: string | null; // @beta: additionally added for testing
  shipToVerified: boolean;
  shipments: ItemReference[];
  salesOrderBundleItems: SalesOrderBundleItem[];
  salesItems: (SalesOrderBundleItem | SalesOrderItem)[];
  stockStatus: StockStatus | null;
  store: string | null;
  receipt: { id: number; number: string } | null;
  vendorPONumber: string | null;
  version: number | null;
}

export enum SalesOrderStatus {
  Estimate = 'Estimate',
  Cancelled = 'Cancelled',
  Issued = 'Issued',
  Picking = 'Picking',
  PartiallyPicked = 'Partially Picked',
  Picked = 'Picked',
  PartiallyFulfilled = 'Partially Fulfilled',
  Fulfilled = 'Fulfilled',
  ClosedShort = 'Closed Short',
  Voided = 'Voided',
  Expired = 'Expired',
}

export enum SalesOrderPaymentStatus {
  Paid = 'Paid',
  Unpaid = 'Unpaid',
  PartiallyPaid = 'Partially paid',
}

export enum SalesOrderPaymentType {
  Cash = 'Cash',
  Check = 'Check',
  Card = 'Card',
  RequestLink = 'Request Link',
}

export enum SalesOrderTransactionType {
  Charge = 'Charge',
  Refund = 'Refund',
}

export enum PaymentStatus {
  Pending = 'Pending',
  Paid = 'Paid',
  Canceled = 'Canceled',
  Expired = 'Expired',
  Refunded = 'Refunded',
}

export interface SalesOrderPayment {
  id: number | null;
  amount: number | null;
  checkVersion: boolean;
  createdBy: null;
  createdById: number | null;
  creditCardType: null;
  customer: Customer | null;
  customerId: number | null;
  data: { type?: string; paymentIntentId?: string; requestLink?: string };
  date: Date | null;
  dateCreated: Date | null;
  dateLastModified: Date | null;
  expirationMonth: string | null;
  expirationYear: string | null;
  lastFour: null;
  lastModifiedBy: null;
  lastModifiedById: number | null;
  multiCurrencyAmount: number | null;
  nameOnCard: string | null;
  originalPaymentId: number | null;
  paymentType: PaymentType | null;
  paymentTypeId: number | null;
  referenceNumber: string | null;
  salesOrderId: number | null;
  status: PaymentStatus;
  transactionId: number | null;
  transactionType: SalesOrderTransactionType;
  version: number | null;
  refunded: boolean | null;
  remainingForRefund: number | null;
}

export interface PaymentType {
  id: number | null;
  createdBy: string | null;
  createdById: number | null;
  dateCreated: Date | null;
  dateDeleted: Date | null;
  dateLastModified: Date | null;
  deleted: boolean;
  lastModifiedBy: null;
  lastModifiedById: number | null;
  name: string | null;
  systemPaymentType: boolean;
  version: number | null;
}

export interface SalesOrderItem {
  id: number | null;
  cost: number | null;
  deleted: boolean;
  description: string | null;
  discount: Discount;
  discountTotal: number;
  // mc part
  exchangeCurrency: Currency | null;
  exchangeCurrencyId: number | null;
  exchangeRate: number | null;
  multiCurrencyDiscountTotal: number | null;
  multiCurrencySubTotal: number | null;
  multiCurrencyTaxTotal: number | null;
  multiCurrencyTotal: number | null;
  // does not exist on BE
  multiCurrencyItemPrice: number | null;
  // end
  hasPricingRule: boolean;
  isFiveDecimals: boolean;
  lineNumber: number;
  name: string | null;
  notes: string | null;
  price: number | null;
  quantity: number | null;
  quantityFulfilled: number | null;
  saleItem: SaleItem | null;
  salesOrder: SalesOrder | null;
  saleItemId: number | null;
  salesOrderId: number | null;
  salesOrderItemType: SalesOrderItemTypes;
  status: SalesOrderItemStatus;
  subTotal: number;
  tax: TaxRate | null;
  taxable: boolean;
  taxId: number | null;
  taxRate: number | null;
  taxTotal: number;
  total: number;
  uomId: number | null;
  version: number | null;
  countyTaxAmount: number;
  countyTaxRate: number;
  stateTaxAmount: number;
  stateTaxRate: number;
  cityTaxAmount: number;
  cityTaxRate: number;
  specialDistrictTaxAmount: number;
  specialDistrictTaxRate: number;
}

export interface SalesOrderBundleItem extends SalesOrderItem {
  itemId: number | null;
  item: Item | null;
  salesOrderItems: SalesOrderItem[];
}

export enum SalesOrderItemTypes {
  Sale = 'Sale',
  CreditReturn = 'Credit Return',
  FlatTaxRate = 'Flat Rate Tax',
  Note = 'Note',
  MiscSale = 'Misc. Sale',
  MiscReturn = 'Misc. Credit',
  Bundle = 'Bundle',
  BundleCreditReturn = 'BundleCreditReturn',
  DropShip = 'Drop Ship',
}

export enum SalesOrderItemStatus {
  Entered = 'Entered',
  PartiallyPicked = 'Partially Picked',
  Picked = 'Picked',
  Picking = 'Picking',
  PartiallyFulfilled = 'Partially Fulfilled',
  Fulfilled = 'Fulfilled',
  ClosedShort = 'Closed Short',
  Voided = 'Voided',
}

export interface CreateSalesOrderPayment {
  paymentType: string;
  paymentTypeId: number;
  creditCardType?: string;
  amount: number;
  lastFour?: string;
  nameOnCard?: string;
  expirationMonth?: number;
  expirationYear?: number;
  referenceNumber?: string;
}

export enum DiscountTypes {
  None,
  Percent,
  FlatRate,
}

export interface Discount {
  type: DiscountTypes;
  value: number | null;
}

export interface SalesOrderEmail {
  to: string | null;
  cc: string | null;
  bcc: string | null;
  subject: string | null;
  body: string | null;
}

export interface SalesOrderEmailStatus {
  name: string | null;
  value: string | null;
}

export interface SalesOrderPrintStatus {
  name: string | null;
  value: string | null;
}

export enum SalesOrderTableRowOrderType {
  Mixed = 'Mixed',
  CreditReturn = 'Credit Return',
  Sale = 'Sale',
  DropShip = 'Drop Ship',
}

export interface GeneratePaymentInfo {
  amount: number | null;
  dueDate: Date | null;
  salesOrderId: number | null;
}

export interface DropShipPurchaseOrders {
  id: number | null;
  number: string | null;
}

export interface CalculatedTotals {
  discountTotal: number;
  taxTotal: number;
  total: number;
  subTotal: number;
  multiCurrencyDiscountTotal: number;
  multiCurrencyTaxTotal: number;
  multiCurrencyTotal: number;
  multiCurrencySubTotal: number;
}
