import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import AuthHandler from './AuthHandler';
import { Auth0WrapperProps } from './type';

/**
 * Provides authentication context via Auth0.
 */
export const Auth0Wrapper: React.FC<Auth0WrapperProps> = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN || '';
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
  const authorizationParams = {
    redirect_uri: window.location.origin,
  };
  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={authorizationParams}
    >
      <AuthHandler>{children}</AuthHandler>
    </Auth0Provider>
  );
};

export default Auth0Wrapper;
