import React, { memo, forwardRef, useEffect, useCallback } from 'react';
import {
  Box,
  ClickAwayListener,
  IconButton,
  Button,
  Grow,
  Popper,
  Card,
  Pagination,
  PaginationItem,
} from '@mui/material';
import NavigateNext from '@mui/icons-material/NavigateNext';
import NavigateBefore from '@mui/icons-material/NavigateBefore';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import { useEnhancedFooterStyles } from './styled';
import { CurrentPageButtonProps } from './types';

/**
 *
 * @type {ForwardRefExoticComponent<PropsWithoutRef<CurrentPageButtonProps> & RefAttributes<unknown>>}
 * @param props
 * @return {JSX.Element}
 */
const CurrentPageButton = forwardRef<HTMLButtonElement, CurrentPageButtonProps>(
  (
    {
      className,
      onClick,
      currentPage,
      title = 'Click to open pagination menu',
    }: CurrentPageButtonProps,
    ref
  ) => {
    return (
      <Button ref={ref} className={className} onClick={onClick} title={title}>
        {currentPage}
      </Button>
    );
  }
);

/**
 *
 * @param onPageChange
 * @param count
 * @param rowsPerPage
 * @param page
 * @param rest
 * @return {JSX.Element | null}
 * @constructor
 */
const EnhancedTablePaginationActionsReduce = ({
  onPageChange,
  count,
  rowsPerPage,
  page,
}: TablePaginationActionsProps) => {
  const [open, setOpen] = React.useState(false);

  const numberOfPages = Math.ceil(count / rowsPerPage);
  const paginationCurrentPage = page + 1;

  const anchorRef = React.useRef<any>(null);
  const classes = useEnhancedFooterStyles();

  const handleToggle = () => setOpen((open) => !open);

  const closeMenu = () => setOpen(false);

  const handleListKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key !== 'Escape' && open) {
        return;
      }
      closeMenu();
    },
    [open]
  );

  useEffect(() => {
    document.addEventListener('keydown', (event) => handleListKeyDown(event));

    return () =>
      document.removeEventListener(
        'keydown',
        (event) => handleListKeyDown(event),
        false
      );
  }, [handleListKeyDown]);

  // Stop execute if rowsPerPage is not available or if there is only one page
  if (!rowsPerPage || numberOfPages <= 1) {
    return null;
  }

  /**
   *
   * @param event
   * @param page
   * @description Go to pagination number/page when numbered od prev/next/first/last element is clicked
   */
  const onElementClickHandler = (event: any, page: number) => {
    onPageChange(event, page - 1);
  };

  return (
    <ClickAwayListener onClickAway={closeMenu}>
      <Box className={classes.paginationWrapper}>
        <IconButton
          onClick={(e) => onElementClickHandler(e, paginationCurrentPage - 1)}
          disabled={paginationCurrentPage === 1}
          aria-label="Previous page"
          size="small"
        >
          <NavigateBefore />
        </IconButton>

        <CurrentPageButton
          ref={anchorRef}
          className={classes.tablePaginationButton}
          currentPage={paginationCurrentPage}
          onClick={handleToggle}
        />

        <IconButton
          onClick={(e) => onElementClickHandler(e, paginationCurrentPage + 1)}
          disabled={paginationCurrentPage >= numberOfPages}
          aria-label="Next page"
          size="small"
        >
          <NavigateNext />
        </IconButton>

        {/* Menu with pagination */}
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          transition
          placement="top-start"
          className={classes.tablePaginationPopper}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Card className={classes.tablePaginationCard}>
                <Pagination
                  count={numberOfPages}
                  defaultPage={paginationCurrentPage}
                  page={paginationCurrentPage}
                  size="medium"
                  hideNextButton
                  hidePrevButton
                  siblingCount={5}
                  boundaryCount={5}
                  renderItem={(paginationItem) => (
                    <PaginationItem
                      {...paginationItem}
                      onClick={(e) => {
                        if (paginationItem.page) {
                          onElementClickHandler(e, paginationItem.page);
                        }
                      }}
                    />
                  )}
                />
              </Card>
            </Grow>
          )}
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

export default memo(EnhancedTablePaginationActionsReduce);
