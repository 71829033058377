import { LedgerForWaitingToExportModal } from 'services/ledgers';
import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const LEDGER_DETAILS_COLUMNS: Column[] = [
  {
    title: 'Date',
    field: 'postDate',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Type / Ref.',
    field: 'type',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Memo',
    field: 'note',
    type: ColumnTypes.renderByCustomRow,
  },
];

export const LEDGER_DETAILS_COLUMNS_WITH_LINK: Column[] = [
  {
    title: 'Date',
    field: 'postDate',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Type / Ref.',
    field: 'type',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Memo',
    field: 'note',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Details',
    type: ColumnTypes.renderByCustomRow,
  },
];

export const initalLedgerItems: LedgerForWaitingToExportModal[] = [
  {
    id: null,
    postDate: null,
    type: null,
    note: null,
    purchaseOrderId: null,
    salesOrderId: null,
  },
];

export const InvoiceTypeLedgerItems: LedgerForWaitingToExportModal[] = [
  {
    id: 123,
    postDate: null,
    type: 'Invoice',
    note: 'test note for invoice',
    purchaseOrderId: null,
    salesOrderId: 123,
  },
];

export const BillTypeLedgerItems: LedgerForWaitingToExportModal[] = [
  {
    id: 456,
    postDate: null,
    type: 'Bill',
    note: 'test note for bill',
    purchaseOrderId: 456,
    salesOrderId: null,
  },
];

export const AdjustmentTypeLedgerItems: LedgerForWaitingToExportModal[] = [
  {
    id: 789,
    postDate: null,
    type: 'Adjustment',
    note: 'test note for adjustment',
    purchaseOrderId: null,
    salesOrderId: null,
  },
];
