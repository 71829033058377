import { TrackingType } from './types';

export const transformTrackingType = (res: any): TrackingType => ({
  id: res.id,
  version: res.version,
  name: res.name,
  description: res.description,
  trackingDataType: res.trackingDataType,
  deleted: res.deleted,
});
