import { defaultMaximumPagination } from 'helpers';
import { PaginationDirection } from 'services/url';
import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const locationOrderModalColumns: Column[] = [
  {
    title: 'Name',
    field: 'displayName',
    type: ColumnTypes.string,
  },
];

export const locationOrderPagination = {
  ...defaultMaximumPagination,
  sort: {
    sortBy: 'sortNumber',
    direction: PaginationDirection.Ascending,
  },
};
