import React, { memo, useCallback, useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Box, Typography } from '@mui/material';
import _ from 'lodash';

import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import {
  trackingTableValidationSchema,
  TrackingTableTypes,
} from 'ui/components/Table/TrackingTable';
import { TextFieldQuantity } from 'ui/components/TextField/TextFieldQuantity';
import { EACH_UOM_ID, getUoms } from 'services/uoms';
import { Location } from 'services/locations';
import {
  TrackingGroup,
  getItemInventory,
  ItemInventory,
  SerialRow,
  initialItemInventory,
  itemHasOnlySerialTracking,
  itemHasTracking,
  itemHasSerialTracking,
} from 'services/inventory';
import { useHandleTextFieldChange } from 'services/forms';
import {
  PickItem,
  PickItemStatus,
  pickItemIsInventoryType,
} from 'services/picking';
import { replaceValueInCollection } from 'helpers';
import { getSettingsPicking } from 'services/settings/picking/redux';
import { initialPickItem } from 'services/picking/consts';
import { Errors, validateYup } from 'services/forms/validation';

import { PickItemCommitModalProps } from './types';
import { usePickingModalStyle } from './styled';
import { pickHasExpiredItems } from '../../../../helpers';
import { resolvePickItemCommitSchema } from '../../../../validations';
import TrackingTable from 'ui/components/Table/TrackingTable/TrackingTable';
import SerialTable from 'ui/components/Table/SerialTable/SerialTable';
import { OverPickingNotifyModal } from '../../../PickCommitWizard/OverPickingNotifyModal';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { colorPalette, themeRestyle } from 'ui/theme';
import LocationItemAsyncAutocomplete from 'ui/components/Autocomplete/LocationItemAsyncAutocomplete/LocationItemAsyncAutocomplete';

const PickItemCommitModal: React.FC<PickItemCommitModalProps> = (props) => {
  const { activePickItem, show, isFinishClicked, onClose, onSave } = props;

  const classes = usePickingModalStyle(props);
  const { items: uoms } = useSelector(getUoms);
  const pickingSettings = useSelector(getSettingsPicking);
  const [showNotifyWhenOverPickingModal, setShowNotifyWhenOverPickingModal] =
    useState(false);
  const [validationErrors, setValidationErrors] = React.useState<Errors>({});
  const [formPickItem, setFormPickItem] =
    React.useState<PickItem>(initialPickItem);
  const [isLoading, setIsLoading] = React.useState(false);
  const [itemInventory, setItemInventory] =
    React.useState<ItemInventory>(initialItemInventory);
  const [showConfirmationModal, setShowConfirmationModal] =
    React.useState(false);
  const [showOverpickingModal, setShowOverpickingModal] = React.useState(false);
  const [showExpiredItemsConfirmation, setShowExpiredItemsConfirmation] =
    React.useState(false);
  const [autoAssignClicked, setAutoAssignClicked] = React.useState(false);
  const [trackingTableErrors, setTrackingTableErrors] = React.useState<
    Errors[]
  >([]);
  const buttonValue = isFinishClicked ? 'Finish' : 'Commit';

  const isInventoryType = pickItemIsInventoryType(activePickItem);
  const resolvedQuantity = `${_.get(formPickItem, 'quantity', '0')} ${_.get(
    activePickItem,
    'item.defaultUom.abbreviation',
    'ea'
  )}`;

  const hasTracking = useMemo(
    () => !!activePickItem.item && itemHasTracking(activePickItem.item),
    [activePickItem.item]
  );
  const isAllowedDecimal = useMemo(
    () => !!activePickItem.item && !itemHasSerialTracking(activePickItem.item),
    [activePickItem]
  );

  const isTrackingTableInvalid = useMemo(
    () => trackingTableErrors.some((error) => !_.isEmpty(error)),
    [trackingTableErrors]
  );

  const autoQuantityToCommit = useMemo(() => {
    if (
      !activePickItem.quantity ||
      !activePickItem.fromLocationQuantityAvailable
    ) {
      return 0;
    }

    return activePickItem.quantity >
      activePickItem.fromLocationQuantityAvailable
      ? activePickItem.fromLocationQuantityAvailable
      : activePickItem.quantity;
  }, [activePickItem.quantity, activePickItem.fromLocationQuantityAvailable]);

  const locationQty = useMemo(() => {
    const fromInventoryRow = itemInventory.inventoryRowList.find(
      (i) => i.locationId === formPickItem.pickFromLocationId
    );

    return fromInventoryRow ? fromInventoryRow.availableToPickQty : 0;
  }, [itemInventory, formPickItem.pickFromLocationId]);

  const hasOnlySerial = useMemo(
    () =>
      !!activePickItem.item && itemHasOnlySerialTracking(activePickItem.item),
    [activePickItem.item]
  );

  const hasSerialTracking = useMemo(
    () => !!activePickItem.item && itemHasSerialTracking(activePickItem.item),
    [activePickItem.item]
  );

  const onlySerialData: SerialRow[] = useMemo(() => {
    return _.get(formPickItem, 'trackingGroupList[0].serialList', []);
  }, [formPickItem]);

  const selectedSerialRows: number[] = useMemo(() => {
    return _.get(formPickItem, 'trackingGroupList[0].serialIds', []);
  }, [formPickItem]);

  useEffect(() => {
    if (!show) {
      return;
    }

    setValidationErrors({});

    // if item is non inventory type no need for tracking
    if (!isInventoryType) {
      setFormPickItem({
        ...activePickItem,
        amount: activePickItem.quantity,
      });
      return;
    }

    (async () => {
      const inventory = await getItemInventory(activePickItem.itemId!);
      setItemInventory(inventory);

      const locationInventory = inventory.inventoryRowList.find(
        (row) => row.locationId === activePickItem.pickFromLocationId
      );

      setFormPickItem({
        ...activePickItem,
        trackingGroupList: locationInventory
          ? locationInventory.trackingGroupList
          : [],
        quantityAvailable: locationInventory
          ? locationInventory.availableToPickQty
          : 0,
      });

      // pre populate amount filed if there is no tracking
      if (!hasTracking) {
        setFormPickItem({
          ...activePickItem,
          amount: activePickItem.quantity,
        });
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const handleModalClicked = useCallback(() => {
    setShowConfirmationModal(true);
  }, []);

  const handleExpiredItemsCancelClicked = useCallback(() => {
    setShowExpiredItemsConfirmation(false);
  }, []);

  const handleCancelConfirmationModalClicked = useCallback(() => {
    setShowConfirmationModal(false);
  }, []);
  const hideNotifyWhenOverPickingModal = useCallback(() => {
    setShowNotifyWhenOverPickingModal(false);
  }, []);

  const handleLocationChange = useCallback(
    (value: Location | null) => {
      if (!formPickItem) {
        return;
      }

      let trackingGroupList: TrackingGroup[] = [];
      let quantityAvailable: number = 0;
      if (value && itemInventory) {
        const locationInventory = itemInventory.inventoryRowList.find(
          (row) => row.locationId === value.id
        );
        if (locationInventory) {
          trackingGroupList = locationInventory.trackingGroupList;
          quantityAvailable = locationInventory.availableToPickQty;
        }
      }

      setFormPickItem({
        ...formPickItem,
        pickFromLocationId: value ? value.id : null,
        trackingGroupList,
        quantityAvailable,
      });
    },
    [formPickItem, itemInventory]
  );

  const handleContinueClick = useCallback(async () => {
    setShowNotifyWhenOverPickingModal(false);
    setIsLoading(true);
    await onSave(formPickItem);
    setIsLoading(false);
  }, [formPickItem, onSave]);

  const handleApplyClicked = useCallback(
    (autoAssign: boolean) => async () => {
      if (!formPickItem) {
        return;
      }

      setAutoAssignClicked(autoAssign);

      const pickItem: PickItem = {
        ...formPickItem,
        autoAssign,
        amount: autoAssign ? formPickItem.quantity : formPickItem.amount,
      };
      if (
        autoAssign &&
        pickItem.status === PickItemStatus.Short &&
        isFinishClicked
      ) {
        pickItem.amount = formPickItem.quantityAvailable;
      }
      const isValid = validateYup(
        pickItem,
        resolvePickItemCommitSchema(isInventoryType),
        setValidationErrors
      );

      // validation check
      if (!isValid) {
        return;
      }

      // check for overpicking
      if (pickItem.amount! > pickItem.quantity!) {
        if (!pickingSettings.enableOverPicking) {
          setShowOverpickingModal(true);
          return;
        } else if (
          pickingSettings.enableOverPicking &&
          pickingSettings.notifyWhenOverPicking
        ) {
          // If both enableOverPicking and notifyWhenOverPicking are enabled, show the dialog
          setShowNotifyWhenOverPickingModal(true);
          return;
        }
      }

      // check if finish and expired tracking
      if (
        isFinishClicked &&
        pickingSettings.pickingExpiredItemWarning &&
        pickHasExpiredItems([pickItem])
      ) {
        setShowExpiredItemsConfirmation(true);
        return;
      }

      // finish
      setIsLoading(true);
      await onSave(pickItem);
      setIsLoading(false);
      setShowConfirmationModal(false);
    },
    [
      formPickItem,
      isFinishClicked,
      pickingSettings.pickingExpiredItemWarning,
      isInventoryType,
      onSave,
      pickingSettings.enableOverPicking,
    ]
  );

  const handleSetTrackingGroups = useCallback(
    (trackingGroupList: TrackingGroup[]) => {
      const amount = trackingGroupList
        .map((trackingGroup) => trackingGroup.quantity || 0)
        .reduce((a, b) => a + b, 0);

      setTrackingTableErrors([]);

      const customSetter = (newError: Errors) =>
        setTrackingTableErrors((errors) => [...errors, newError]);

      trackingGroupList.forEach((tg) =>
        validateYup(tg, trackingTableValidationSchema, customSetter)
      );
      setFormPickItem({ ...formPickItem!, trackingGroupList, amount });
    },
    [formPickItem]
  );

  const handleTextFieldChanged = useHandleTextFieldChange(
    setFormPickItem,
    formPickItem
  );

  const handleSelectedSerialRowsChange = useCallback(
    (id: number | number[]) => {
      if (!formPickItem) {
        return;
      }

      const trackingGroup = formPickItem.trackingGroupList[0];
      let newSerialIds: number[] = [];

      if (Array.isArray(id)) {
        newSerialIds = id;
      } else {
        const isIdAlreadySelected = trackingGroup.serialIds.includes(id);
        newSerialIds = isIdAlreadySelected
          ? _.without(trackingGroup.serialIds, id)
          : [...trackingGroup.serialIds, id];
      }

      setFormPickItem({
        ...formPickItem,
        amount: newSerialIds.length,
        trackingGroupList: replaceValueInCollection(
          formPickItem.trackingGroupList,
          {
            ...trackingGroup,
            quantity: newSerialIds.length,
            serialIds: newSerialIds,
          },
          0
        )!,
      });
    },
    [formPickItem]
  );

  const hideOverpickingModal = useCallback(() => {
    setShowOverpickingModal(false);
  }, []);

  const handleExpiredItemsConfirmClicked = useCallback(async () => {
    setShowExpiredItemsConfirmation(false);
    setIsLoading(true);

    const pickItem: PickItem = {
      ...formPickItem!,
      autoAssign: autoAssignClicked,
      amount: autoAssignClicked ? formPickItem!.quantity : formPickItem!.amount,
    };
    await onSave(pickItem);

    setIsLoading(false);
    setShowConfirmationModal(false);
  }, [formPickItem, autoAssignClicked, onSave]);

  const handleClose = useCallback(() => {
    setTrackingTableErrors([]);
    onClose();
  }, [onClose]);

  const handleAmountChange = useCallback(
    (value: number | null) => {
      setFormPickItem((prevPick) => ({ ...prevPick, amount: value }));
    },
    [setFormPickItem]
  );

  const FBOModalActions = () => {
    return (
      <Box display="flex" justifyContent={'flex-end'} width="100%">
        <FBOButton
          variant="secondary"
          color="neutral"
          size="medium"
          onClick={handleClose}
          data-qa="pick-item-commit-modal-cancel-button"
          sx={{ marginRight: '16px' }}
        >
          Cancel
        </FBOButton>
        <Box>
          {formPickItem && hasTracking && (
            <FBOButton
              variant="secondary"
              color="positive"
              size="medium"
              disableElevation
              loading={!!isLoading}
              onClick={handleModalClicked}
              data-qa="pick-item-commit-modal-pick-items"
              sx={{ marginRight: '16px' }}
            >
              Auto Select and {buttonValue}
            </FBOButton>
          )}
          <FBOButton
            variant="primary"
            color="positive"
            size="medium"
            disableElevation
            loading={!!isLoading}
            disabled={isTrackingTableInvalid}
            onClick={handleApplyClicked(false)}
            data-qa="picking-modal-commit"
          >
            {buttonValue}
          </FBOButton>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Modal
        open={show}
        onApplyClicked={_.noop}
        onCancelClicked={handleClose}
        withoutDefaultPadding
        isLoading={isLoading}
        status={activePickItem ? activePickItem.status : undefined}
        title={`${_.get(activePickItem, 'item.name', '')}`}
        onResetClicked={_.noop}
        maxWidth="lg"
        footerDivider={!activePickItem || hasTracking ? 'shadow' : undefined}
        nestedScrollAreas
        ModalActionsComponent={FBOModalActions}
        dataQa="commit"
      >
        <Grid container>
          <Grid item xs={12} classes={{ root: classes.flexibleGrid }}>
            <Grid container>
              <Grid
                item
                xs={2}
                classes={{ root: classes.gridWithoutRightPadding }}
              >
                <TextField
                  readOnly
                  additionalInputProps={{
                    classes: { input: classes.bigNumber },
                  }}
                  value={resolvedQuantity}
                  label="Pick Quantity"
                  dataQa="pick-item-commit-modal-pick-quantity"
                />
              </Grid>
              <Grid item xs={10} classes={{ root: classes.gridWithPadding }}>
                {isInventoryType ? (
                  <Grid container spacing={1}>
                    <Grid item xs={hasTracking ? 10 : 8}>
                      <LocationItemAsyncAutocomplete
                        placeholder="Pick Origin"
                        onChange={handleLocationChange}
                        label="Pick Origin"
                        value={formPickItem.pickFromLocationId}
                        dataQa="pick-finish-wizard-pick-origin"
                        companyWide={false}
                        itemId={formPickItem?.itemId ?? undefined}
                        renderOption={(props, option: Location) => {
                          return (
                            <li {...props}>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                width="100%"
                              >
                                <Typography variant="body1">
                                  {option.name}
                                </Typography>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  {_.round(option.availableToPickQty!, 2)}
                                </Typography>
                              </Box>
                            </li>
                          );
                        }}
                      />
                    </Grid>
                    {!hasTracking && (
                      <Grid item xs={2}>
                        <TextFieldQuantity
                          label="Quantity"
                          name="amount"
                          required
                          autoComplete="off"
                          disableDebounce
                          value={formPickItem.amount}
                          selectedUomId={formPickItem.uomId || EACH_UOM_ID}
                          uoms={uoms}
                          onTextChange={handleAmountChange}
                          isDecimal={isAllowedDecimal}
                          error={Boolean(validationErrors.amount)}
                          helperText={validationErrors.amount}
                          dataQa="pick-item-commit-modal-quantity-input"
                        />
                      </Grid>
                    )}
                    <Grid item xs={2}>
                      <TextFieldQuantity
                        label="Quantity At Loc."
                        placeholder="Quantity"
                        name="amount"
                        value={locationQty}
                        selectedUomId={
                          formPickItem
                            ? formPickItem.uomId || EACH_UOM_ID
                            : EACH_UOM_ID
                        }
                        error={false}
                        uoms={uoms}
                        readOnly
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className="redesign"
                        variant="standard"
                        label="Notes"
                        placeholder="Notes"
                        value={formPickItem.notes}
                        name="notes"
                        onChange={handleTextFieldChanged}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={1}>
                    <Grid item xs={2}>
                      <TextFieldQuantity
                        label="Quantity"
                        name="amount"
                        required
                        autoComplete="off"
                        disableDebounce
                        value={formPickItem.amount}
                        selectedUomId={formPickItem.uomId || EACH_UOM_ID}
                        uoms={uoms}
                        onTextChange={handleAmountChange}
                        isDecimal={isAllowedDecimal}
                        error={Boolean(validationErrors.amount)}
                        helperText={validationErrors.amount}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        className="redesign"
                        variant="standard"
                        placeholder="Notes"
                        value={formPickItem.notes}
                        name="notes"
                        onChange={handleTextFieldChanged}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} classes={{ root: classes.growingGrid }}>
                {formPickItem && hasTracking && (
                  <Box>
                    {!hasOnlySerial ? (
                      <TrackingTable
                        itemTrackingTypes={_.get(
                          formPickItem,
                          'item.itemTrackingTypeList',
                          []
                        )}
                        trackingGroups={
                          formPickItem ? formPickItem.trackingGroupList : []
                        }
                        errors={trackingTableErrors}
                        tableType={TrackingTableTypes.Remove}
                        disableAutoAssign
                        onSetTrackingGroups={handleSetTrackingGroups}
                        firstColumnTitle="Quantity To Commit"
                        isDecimal={!hasSerialTracking}
                        sx={{
                          borderRadius: '5px',
                          border: `1px solid ${colorPalette.redesign.background3}`,
                          borderTop: 'none',
                          marginRight: themeRestyle.spacing(4),
                          marginLeft: themeRestyle.spacing(4),
                          maxHeight: '270px',
                        }}
                        headerMargin="0px 32px"
                      />
                    ) : (
                      <SerialTable
                        title="Tracking"
                        serialList={onlySerialData}
                        selectedRows={selectedSerialRows}
                        onSelectedRowsChanged={handleSelectedSerialRowsChange}
                        itemTrackingTypes={_.get(
                          formPickItem,
                          'item.itemTrackingTypeList',
                          []
                        )}
                        canSelectRow
                        disableAutoAssign
                        sx={{
                          borderRadius: '5px',
                          border: `1px solid ${colorPalette.redesign.background3}`,
                          borderTop: 'none',
                          marginRight: themeRestyle.spacing(4),
                          marginLeft: themeRestyle.spacing(4),
                          maxHeight: '270px',
                        }}
                        headerMargin="0px 32px"
                      />
                    )}
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>

      <ConfirmationModal
        open={showConfirmationModal}
        title={`Auto Select And ${buttonValue} ${autoQuantityToCommit} Items`}
        body={`This will automatically select and ${buttonValue} ${autoQuantityToCommit} Items.`}
        onCancelClicked={handleCancelConfirmationModalClicked}
        onConfirmClicked={handleApplyClicked(true)}
        confirmLabel={buttonValue}
        isLoading={isLoading}
      />

      <ConfirmationModal
        open={showOverpickingModal}
        title="Overpicking is not enabled"
        body={`At the moment, overpicking is not supported. You can enable it under Settings > Picking`}
        onCancelClicked={hideOverpickingModal}
        onConfirmClicked={hideOverpickingModal}
        dataQa="pick-item-commit-modal-confiramtion-modal"
      />
      {pickingSettings.pickingExpiredItemWarning && (
        <ConfirmationModal
          open={showExpiredItemsConfirmation}
          title="Item Expired"
          body="The item you have selected has already expired.  Would you like to proceed?"
          onCancelClicked={handleExpiredItemsCancelClicked}
          onConfirmClicked={handleExpiredItemsConfirmClicked}
          confirmLabel="Continue"
          confirmButtonRed
          showCloseIcon={true}
        />
      )}
      <OverPickingNotifyModal
        open={showNotifyWhenOverPickingModal}
        onCancel={hideNotifyWhenOverPickingModal}
        title="Over-Picking"
        content="The quantity entered is larger than ordered"
        onContinue={handleContinueClick}
      />
    </>
  );
};

export default memo(PickItemCommitModal);
