import React, { memo, useCallback } from 'react';
import { TableCell, Box } from '@mui/material';
import _ from 'lodash';

import { SerialRow as SerialRowType } from 'services/inventory';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { TextField } from 'ui/components/TextField/TextField';
import { Icon } from 'ui/components/Icon';
import { IconNames } from 'ui/theme';

import { useSerialRowStyle } from './styled';
import { StatusLabel } from 'ui/components/Status/StatusLabel';
import { PickStatus } from 'services/picking';

const SerialRow: React.FC<RenderCustomRowProps<SerialRowType>> = (props) => {
  const {
    columns,
    row,
    errors,
    index,
    meta,
    setData,
    onAction,
    tableBordered,
  } = props;

  const rowClasses = useSerialRowStyle();

  const handleSerialNumberChange = useCallback(
    (trackingTypeId: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value || null;
      const serialNumbers = {
        ...row.serialNumbers,
        [trackingTypeId]: value,
      };
      const newData = { ...row, serialNumbers };

      setData(newData, index);
    },
    [index, row, setData]
  );

  const handleDeleteClick = useCallback(() => {
    if (onAction) {
      onAction(index, row);
    }
  }, [onAction, index, row]);

  const handleClearClick = useCallback(() => {
    if (onAction) {
      onAction(index, row);
    }
  }, [onAction, index, row]);

  const handleKeyPress = (index: number) => (e: any) => {
    if (e.charCode === 13) {
      const nextInput = document.querySelector(
        `[tabIndex="${index + 1}"] input`
      );

      if (nextInput) {
        (nextInput as HTMLElement).focus();
      }
    }
  };

  const rowStatus = row.committed ? PickStatus.Committed : PickStatus.Open;
  const trackingTypeColumns = columns.filter((column: any) => {
    return column?.meta?.trackingTypeId >= 0;
  });

  return (
    <>
      {trackingTypeColumns.map((column, i) => {
        const trackingTypeId: number = _.get(column, 'meta.trackingTypeId');
        const isRowEditable = _.get(meta, 'canEditRow');
        const isRowClearable = _.get(meta, 'canClearRow');
        const isLastColumn = i === columns.length - 1;
        const serialNumber = row.serialNumbers[trackingTypeId];

        // possible multiple columns
        const tabIndex = index * columns.length + i;
        const error = !!errors[`${trackingTypeId}`];

        return (
          <TableCell padding={tableBordered ? 'none' : 'normal'} key={i}>
            <Box className={rowClasses.cellContainer}>
              <Box className={rowClasses.textFieldContainer}>
                <TextField
                  autoComplete="nope"
                  placeholder="Add Serial Number"
                  onChange={handleSerialNumberChange(trackingTypeId)}
                  value={serialNumber}
                  readOnly={!isRowEditable}
                  additionalInputProps={{
                    classes: {
                      notchedOutline: error ? undefined : rowClasses.noBorder,
                    },
                  }}
                  dataQa={`serial-tracking-row-${index}`}
                  tabIndex={tabIndex}
                  error={error}
                  onKeyPress={handleKeyPress(tabIndex)}
                />
              </Box>
              {isRowClearable &&
                isLastColumn &&
                (meta?.isCycleCountSerialTable ? (
                  <Icon
                    name={IconNames.CloseCircle}
                    fill={'black'}
                    onClick={handleClearClick}
                    className={rowClasses.icon}
                    data-qa={`serial-tracking-row-${index}-clear`}
                  />
                ) : (
                  <Icon
                    name={IconNames.Delete}
                    onClick={handleDeleteClick}
                    className={rowClasses.icon}
                    data-qa={`serial-tracking-row-${index}-delete`}
                  />
                ))}
            </Box>
          </TableCell>
        );
      })}
      <TableCell className={rowClasses.status}>
        <Box>
          <StatusLabel
            status={rowStatus}
            data-qa={`serial-tracking-row-${index}-status`}
          />
        </Box>
      </TableCell>
    </>
  );
};

export default memo(SerialRow);
