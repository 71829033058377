import { LowStock, OpenSalesOrder } from 'services/dashboard';
import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const OPEN_SALES_ORDERS_COLUMNS: (
  currencyFormatter: (
    value: number,
    currencyCode?: string | undefined
  ) => string
) => Column[] = (currencyFormatter) => [
  {
    title: 'No.',
    field: 'number',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Customer',
    field: 'customer.name',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Total',
    sortable: true,
    field: 'grandTotal',
    render: (so: OpenSalesOrder) => currencyFormatter(so.total || 0),
    type: ColumnTypes.custom,
  },
  {
    title: 'Paid',
    field: 'paymentTotal',
    sortable: true,
    render: (so: OpenSalesOrder) => currencyFormatter(so.paid || 0),
    type: ColumnTypes.custom,
  },
];

export const LOW_STOCK_COLUMNS: (
  currencyFormatter: (
    value: number,
    currencyCode?: string | undefined
  ) => string
) => Column[] = (currencyFormatter) => [
  {
    title: 'Item',
    field: 'name',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Barcode / UPC',
    field: 'upc',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'SKU',
    field: 'sku',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Type',
    field: 'itemType',
    sortable: true,
    type: ColumnTypes.string,
  },

  {
    title: 'Quantity',
    field: 'quantity',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Price',
    field: 'price',
    type: ColumnTypes.custom,
    sortable: true,
    render: (ls: LowStock) => currencyFormatter(ls.price || 0),
  },
  {
    title: 'Cost',
    field: 'cost',
    width: 192,
    sortable: true,
    type: ColumnTypes.custom,
    render: (ls: LowStock) => currencyFormatter(ls.cost || 0),
  },
];
