import React, { memo, useMemo } from 'react';
import {
  Box,
  Typography,
  Radio,
  FormControlLabel,
  Divider,
} from '@mui/material';

import {
  ShippingIntegrationSettings,
  ShippingIntegrationRateService,
} from 'services/integrations/shipping';
import { useCurrencyFormatter } from 'helpers';

import { useCardRowStyle } from '../styled';
import { totalCostCalculation } from '../helpers';

const QuoteRatesRow = (props: {
  service: ShippingIntegrationRateService;
  onSelect: () => void;
  selected: boolean;
  defaultShippingSettings: ShippingIntegrationSettings;
}) => {
  const { service, onSelect, defaultShippingSettings, selected } = props;

  const currencyFormatter = useCurrencyFormatter();

  const classes = useCardRowStyle();

  const totalCost = useMemo(() => {
    const totalCostWithMarkup = totalCostCalculation(
      service,
      defaultShippingSettings
    );
    return totalCostWithMarkup;
  }, [defaultShippingSettings, service]);

  return (
    <Box overflow="auto">
      <Box className={classes.row} onClick={onSelect}>
        <FormControlLabel
          className={classes.label}
          value="parcel"
          control={
            <Radio color="primary" checked={selected} onChange={onSelect} />
          }
          label={
            <Box>
              <Typography
                variant="body1"
                color="textPrimary"
                className={classes.heading}
                title={service.serviceName}
              >
                {service.serviceName}
              </Typography>
            </Box>
          }
        />

        <Typography color="textSecondary">
          {currencyFormatter(totalCost)}
        </Typography>
      </Box>
      <Divider />
    </Box>
  );
};

export default memo(QuoteRatesRow);
