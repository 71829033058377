import React, { memo } from 'react';

import { ImageStorageType } from 'services/images';
import { DocumentsTable } from 'ui/components/Documents/DocumentsTable';

import { editVendorPermissions } from '../helpers';
import { VendorDocumentsTabProps } from './types';

const VendorDocumentsTab: React.FC<VendorDocumentsTabProps> = (props) => {
  const { activeVendor, setActiveVendor } = props;

  const editPermission = editVendorPermissions(activeVendor);

  return (
    <DocumentsTable
      storageType={ImageStorageType.VendorDocuments}
      data={activeVendor}
      setData={setActiveVendor}
      permissions={editPermission}
    />
  );
};

export default memo(VendorDocumentsTab);
