import { chainReducers, INITIAL_API_STATE } from 'services/api';
import {
  createAddSearchReducer,
  createDeleteSearchReducer,
  createFetchSearchReducer,
  createUpdateSearchReducer,
} from 'services/search';
import { combineReducers } from 'redux';

const fetchSearchReducer = createFetchSearchReducer('salesOrder');
const addSearchReducer = createAddSearchReducer('salesOrder');
const updateSearchReducer = createUpdateSearchReducer('salesOrder');
const deleteSearchReducer = createDeleteSearchReducer('salesOrder');

const searchChainedReducers = chainReducers(
  INITIAL_API_STATE,
  fetchSearchReducer,
  addSearchReducer,
  updateSearchReducer,
  deleteSearchReducer
);

export default combineReducers({
  searches: searchChainedReducers,
});
