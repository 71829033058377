import React from 'react';
import { Grid } from '@mui/material';

import { Page } from 'ui/components/Page/Page';

import { FeedbackPageCmp } from './types';
import { Routes } from '../../navigation';
import { useFeedbackPageStyle } from './styled';

const FeedbackPage: FeedbackPageCmp = () => {
  const classes = useFeedbackPageStyle();
  return (
    <Page>
      <Grid container>
        <Grid item xs={12} lg={6}>
          <iframe
            title="Feature Request"
            className={classes.frame}
            src="https://forms.clickup.com/f/a2veu-6580/NAN0QPRYEFT8TAPFXB"
            height="1150px"
          />
          <script
            async
            src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <iframe
            title="Report a bug"
            className={classes.frame}
            src="https://forms.clickup.com/f/a2veu-6480/3AV9TL8T69C55B1DDE"
            height="1400px"
          />
          <script
            async
            src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js"
          />
        </Grid>
      </Grid>
    </Page>
  );
};

FeedbackPage.route = Routes.FeedbackRouter;

export default FeedbackPage;
