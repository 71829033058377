import React, { memo, useCallback } from 'react';
import { Box, TableCell, Typography } from '@mui/material';
import _ from 'lodash';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { ItemLocation } from 'services/items';
import { colorPalette } from 'ui/theme';
import { PermissionType } from 'services/permissions';

import { LocationItemTableRowActions, createActionBarOptions } from './consts';

import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';
import { useSelector } from 'react-redux';
import { activeUserHasPermission } from 'services/user/redux/selectors';

const LocationItemRow: React.FC<RenderCustomRowProps<ItemLocation>> = (
  props
) => {
  const { row: itemLocation, onAction, index } = props;

  const canEditItem: PermissionType[] =
    itemLocation.id && itemLocation.id > 0
      ? [PermissionType.ItemsEdit]
      : [PermissionType.ItemsCreate];

  const hasPermissionToEditItem: boolean = useSelector(
    activeUserHasPermission(canEditItem)
  );

  const handleAction = useCallback(
    (actionType: LocationItemTableRowActions) => {
      if (onAction) {
        onAction({ type: actionType, itemIndex: index });
      }
    },
    [onAction, index]
  );

  return (
    <>
      <TableCell data-qa={`Parent-Location-${index + 1}`}>
        {_.get(itemLocation, 'parentLocation.displayName', '-')}
      </TableCell>
      <TableCell data-qa={`Pick-origin-Location-${index + 1}`}>
        <Typography>
          {_.get(itemLocation, 'pickFromLocation.displayName', '-')}
        </Typography>
      </TableCell>
      <TableCell data-qa={`receive-Location-${index + 1}`}>
        <Typography>
          {_.get(itemLocation, 'receiveLocation.displayName', '-')}
        </Typography>
      </TableCell>
      <TableCell data-qa={`pick-to-Location-${index + 1}`}>
        <Typography>
          {_.get(itemLocation, 'pickToLocation.displayName', '-')}
        </Typography>
      </TableCell>
      <TableCell>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <FBOMenuButton
            variant="tertiary"
            items={createActionBarOptions(handleAction)}
            disabled={!hasPermissionToEditItem}
            data-qa="location-item-three-dot-menu"
            style={{ color: colorPalette.redesign.contentSecondary }}
          />
        </Box>
      </TableCell>
    </>
  );
};

export default memo(LocationItemRow);
