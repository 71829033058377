import { defaultMaximumPagination, RequestConfig } from 'helpers';
import {
  createApiCall,
  DataWithPagination,
  paginatedApiCall,
} from 'services/api';

import {
  transformRepresentative,
  transformRequestRepresentative,
} from './transformations';
import { Representative } from './types';

export const fetchRepresentativesAPI = async (
  config: RequestConfig
): Promise<DataWithPagination<Representative>> => {
  const {
    pagination = defaultMaximumPagination,
    customQuickSearchColumns = ['firstName'],
    expands = [],
    quickSearchValue,
  } = config;

  let path = `/v1/representatives?expand=${expands.join(',')}`;

  if (quickSearchValue) {
    path += `&quickSearchColumns=${customQuickSearchColumns}&quickSearchValue=${quickSearchValue}`;
  }

  const res = await paginatedApiCall(path, pagination, transformRepresentative);

  return res;
};

export const fetchRepresentativeById = async (
  id: number
): Promise<Representative> => {
  const response = await createApiCall(
    {
      path: `/v1/representatives/${id}`,
      method: 'GET',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformRepresentative(response.data);
};

export const postRepresentative = async (
  representative: Representative
): Promise<Representative> => {
  const response = await createApiCall(
    {
      path: '/v1/representatives',
      method: 'POST',
      body: transformRequestRepresentative(representative),
    },
    {
      getMessage: {
        success: () => 'Representative created',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformRequestRepresentative(response.data);
};
export const putRepresentative = async (
  representative: Representative
): Promise<Representative> => {
  const response = await createApiCall(
    {
      path: '/v1/representatives',
      method: 'PUT',
      body: transformRequestRepresentative(representative),
    },
    {
      getMessage: {
        success: () => 'Representative updated',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformRequestRepresentative(response.data);
};
export const deleteRepresentative = async (id: number): Promise<void> => {
  await createApiCall(
    {
      path: `/v1/representatives/${id}`,
      method: 'DELETE',
    },
    {
      getMessage: {
        success: () => 'Representative successfully deleted',
        error: (error: any) => error.response.data.message,
      },
    }
  )();
};

export const restoreRepresentative = async (representativeId: number) => {
  await createApiCall(
    {
      path: `/v1/representatives/${representativeId}/restore`,
      method: 'POST',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
        success: () => 'Representative successfully restored',
      },
    }
  )();
};
