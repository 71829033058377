import React, { memo, useMemo } from 'react';
import { TableCell } from '@mui/material';
import moment from 'moment';
import _ from 'lodash';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { DateMessage } from 'ui/components/DateTooltip/types';
import { DateTooltip } from 'ui/components/DateTooltip';
import { PermissionGroup } from 'services/permissions';
import { useGetIntlDateFormatString } from 'helpers';

const PermissionRow: React.FC<RenderCustomRowProps<PermissionGroup>> = (
  props
) => {
  const { row, columns } = props;

  const intlFormatDate = useGetIntlDateFormatString();

  const itemActive = useMemo(() => columns.length === 2, [columns]);

  const tooltipDates: DateMessage[] = useMemo(
    () => [
      { date: row.dateCreated, message: 'Date Created' },
      { date: row.dateLastModified, message: 'Date Last Modified' },
    ],
    [row]
  );

  const dateValue: string = useMemo(() => {
    const dateField = !itemActive ? columns[2].field! : null;
    const date = dateField ? _.get(row, dateField, null) : null;

    return date ? moment(date).format(intlFormatDate) : '';
  }, [columns, row, itemActive, intlFormatDate]);

  return (
    <>
      <TableCell>{_.get(row, 'name')}</TableCell>
      <TableCell>{row.users.length}</TableCell>
      {!itemActive && (
        <>
          <DateTooltip values={tooltipDates}>
            <TableCell>{dateValue}</TableCell>
          </DateTooltip>
        </>
      )}
    </>
  );
};

export default memo(PermissionRow);
