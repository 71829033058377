import {
  PurchaseOrderItem,
  PurchaseOrderItemStatus,
  PurchaseOrderItemTypes,
  PurchaseOrder,
  PurchaseOrderStatus,
  PurchaseOrderEmail,
} from './types';
import { EACH_UOM_ID } from 'services/uoms';

export const initialPurchaseOrder: PurchaseOrder = {
  id: null,
  accountingClassId: null,
  buyer: null,
  buyerId: null,
  carrierId: null,
  carrierServiceId: null,
  costIncludesTax: false,
  customerSONumber: null,
  customFields: [],
  dateCreated: null,
  dateLastModified: null,
  dateFulfilled: null,
  dateIssued: null,
  dateScheduled: null,
  deleted: false,
  documents: [],
  dropShipSalesOrder: null,
  email: null,
  exchangeCurrencyId: 1,
  exchangeCurrency: null,
  exchangeRate: 1.0,
  fobPoint: null,
  fobPointId: null,
  grandTotal: null,
  homeCurrencyId: null,
  location: null,
  locationId: null,
  multiCurrencyGrandTotal: null,
  notes: null,
  number: null,
  paymentTermId: null,
  phone: null,
  picks: [],
  purchaseTax: null,
  purchaseTaxId: null,
  purchaseOrderItemList: [],
  shipments: [],
  shippingTermId: null,
  shipToAttention: null,
  shipToCity: null,
  shipToCompanyName: null,
  shipToCountry: null,
  shipToName: null,
  shipToPostalCode: null,
  shipToResidential: false,
  shipToState: null,
  shipToStreet: null,
  shipToStreet2: null,
  shipToVerified: false,
  status: PurchaseOrderStatus.BidRequest,
  vendor: null,
  vendorAddressCity: null,
  vendorAddressCountry: null,
  vendorAddressName: null,
  vendorAddressPostalCode: null,
  vendorAddressState: null,
  vendorAddressStreet: null,
  vendorAddressStreet2: null,
  vendorAddressVerified: false,
  vendorAttention: null,
  vendorCompanyName: null,
  vendorId: null,
  vendorResidential: false,
  vendorSONumber: null,
  version: null,
};

export const initialPurchaseOrderItem: PurchaseOrderItem = {
  id: null,
  customer: null,
  customerId: null,
  dateScheduled: null,
  deleted: false,
  description: null,
  item: null,
  itemId: null,
  lineNumber: null,
  multiCurrencyTotalCost: null,
  multiCurrencyUnitCost: null,
  name: null,
  notes: null,
  purchaseOrderId: null,
  status: PurchaseOrderItemStatus.Entered,
  purchaseOrderItemType: PurchaseOrderItemTypes.Purchase,
  purchaseOrder: null,
  quantity: null,
  quantityFulfilled: null,
  totalCost: null,
  isFiveDecimals: false,
  unitCost: null,
  uom: null,
  uomId: EACH_UOM_ID,
  vendorItemName: null,
  version: null,
  exchangeCurrencyId: null,
  exchangeRate: null,
  exchangeCurrency: null,
  taxId: null,
  taxable: false,
  taxRate: null,
  taxTotal: null,
  multiCurrencyTaxTotal: null,
  subTotal: null,
  multiCurrencySubTotal: null,
  costIncludeTax: false,
};

export const initialEmail: PurchaseOrderEmail = {
  to: null,
  cc: null,
  bcc: null,
  subject: null,
  body: null,
};
