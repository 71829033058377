import { ButtonBaseProps } from '@mui/material';

import { ModuleNavigationItemStatus } from 'services/moduleNavigation';

export interface BreadCrumbProps extends ButtonBaseProps {
  children: React.ReactNode;
  location: BreadCrumbLocation;
  status: ModuleNavigationItemStatus;
  zIndex: number;
}

export enum BreadCrumbLocation {
  'Left' = 'left',
  'Right' = 'right',
  'Middle' = 'middle',
}
