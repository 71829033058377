import React from 'react';
import { Route, Routes } from 'react-router-dom';

import SecureRoute from 'ui/components/Route/SecureRoute';

import { FeedbackPage } from '../pages/FeatureRequestPage';
import { Routes as FeedbackRoutes } from './routes';
import { FeedbackRouterCmp } from './types';

const FeedbackRouter: FeedbackRouterCmp = () => {
  return (
    <Routes>
      <Route element={<SecureRoute />}>
        <Route element={<FeedbackPage />} path={FeedbackPage.route} />
      </Route>
    </Routes>
  );
};

FeedbackRouter.route = FeedbackRoutes.FeedbackRouter;

export default FeedbackRouter;
