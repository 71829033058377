import React, { useState, memo, useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import _ from 'lodash';
import { TrackingTableTypes } from 'ui/components/Table/TrackingTable';
import { Modal } from 'ui/components/Modal/Modal';
import { SerialRow, TrackingGroup } from 'services/inventory';
import { TrackingDataTypes } from 'services/settings/tracking';
import { PermissionType } from 'services/permissions';
import { InventoryTrackingDetailsProps } from './types';
import TrackingTable from 'ui/components/Table/TrackingTable/TrackingTable';
import SerialTable from 'ui/components/Table/SerialTable/SerialTable';
import { TrackingRow } from './components/TrackingRow';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const InventoryTrackingDetails: React.FC<InventoryTrackingDetailsProps> = (
  props
) => {
  const {
    show,
    locationId,
    itemTrackingTypes,
    inventoryData,
    inventoryUom,
    onCancelClicked,
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [trackingGroups, setTrackingGroups] = useState<TrackingGroup[]>([]);
  const [serialList, setSerialList] = useState<SerialRow[]>([]);

  const inventoryRow = useMemo(
    () => inventoryData.find((i) => i.locationId === locationId),
    [inventoryData, locationId]
  );

  const headerTitle = useMemo(
    () => _.get(inventoryRow, 'location.displayName', ''),
    [inventoryRow]
  );

  const isOnlySerial = useMemo(() => {
    const otherTrackingGroups = itemTrackingTypes.filter(
      (t) =>
        _.get(t, 'trackingType.trackingDataType', null) !==
        TrackingDataTypes.SerialNumber
    );
    return otherTrackingGroups.length === 0;
  }, [itemTrackingTypes]);

  const hasSerialTracking = useMemo(() => {
    return itemTrackingTypes.some(
      (itemTrackingType) =>
        _.get(itemTrackingType, 'trackingType.trackingDataType', null) ===
        TrackingDataTypes.SerialNumber
    );
  }, [itemTrackingTypes]);

  useEffect(() => {
    const newTrackingGroup = inventoryRow ? inventoryRow.trackingGroupList : [];
    const newSerialList = _.get(newTrackingGroup, '[0].serialList', []);
    setTrackingGroups(newTrackingGroup);
    setSerialList(newSerialList);
  }, [inventoryRow]);

  const ModalActions = () => {
    return (
      <Box display="flex" justifyContent="flex-end" width="100%">
        <Box mr={2}>
          <FBOButton
            variant="primary"
            color="positive"
            size="medium"
            onClick={onCancelClicked}
            data-qa="inventory-tracking-details-done-button"
          >
            Done
          </FBOButton>
        </Box>
      </Box>
    );
  };

  return (
    <Modal
      className="redesign inventory-event-modal"
      open={show}
      title={`${headerTitle}`}
      onCancelClicked={onCancelClicked}
      ModalActionsComponent={ModalActions}
      isLoading={isLoading}
      withoutDefaultPadding
      nestedScrollAreas
      permissions={[PermissionType.InventoryEdit]}
    >
      <Box overflow="hidden" display="flex" flexDirection="column">
        {!isOnlySerial ? (
          <TrackingTable
            firstColumnTitle="Quantity" // needs to stay "Quantity" or column will be filtered out
            itemTrackingTypes={itemTrackingTypes}
            trackingGroups={trackingGroups}
            onSetTrackingGroups={setTrackingGroups}
            tableType={TrackingTableTypes.View}
            inventoryUom={inventoryUom}
            isDecimal={!hasSerialTracking}
            customRow={TrackingRow}
            closeParentModal={onCancelClicked}
            readOnlyTrackingTable
            headerMargin="0px 32px"
          />
        ) : (
          <SerialTable
            serialList={serialList}
            setSerialList={setSerialList}
            itemTrackingTypes={itemTrackingTypes}
            withoutTitleBar
            setIsLoading={setIsLoading}
          />
        )}
      </Box>
    </Modal>
  );
};

export default memo(InventoryTrackingDetails);
