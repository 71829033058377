import { initialThumbnailState } from './consts';
import { ThumbnailActionType, ThumbnailState } from './types';

const reducer = (
  state: ThumbnailState = initialThumbnailState,
  action: { type: ThumbnailActionType; payload: string }
): ThumbnailState => {
  switch (action.type) {
    case ThumbnailActionType.SHOW_THUMBNAIL:
      return {
        ...state,
        show: true,
        imageUrl: action.payload,
      };
    case ThumbnailActionType.HIDE_THUMBNAIL:
      return {
        ...state,
        show: false,
      };
    default:
      return state;
  }
};

export default reducer;
