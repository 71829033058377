import moment from 'moment';
import _ from 'lodash';

import { Pick, PickItem, PickItemStatus, PickStatus } from 'services/picking';
import { TrackingDataTypes } from 'services/settings/tracking';
import { ItemInventory, TrackingGroup, TrackingInfo } from 'services/inventory';
import { ErrorModal } from './components/PickCommitWizard/types';

const getDateTrackingTypeIds = (pickItem: PickItem) => {
  let dateTrackingTypeIds: number[] = [];
  if (pickItem.item) {
    dateTrackingTypeIds = pickItem.item.itemTrackingTypeList
      .filter(
        (t) =>
          _.get(t, 'trackingType.trackingDataType', null) ===
          TrackingDataTypes.Date
      )
      .map((t) => t.trackingTypeId);
  }

  return dateTrackingTypeIds;
};

const trackingInfoIsExpired = (trackingInfo: TrackingInfo) => {
  const today = moment().startOf('day');
  const value = trackingInfo.value;

  if (!value) {
    return true;
  }

  return moment(value).isBefore(today);
};

const trackingGroupsHaveExpiredItems = (
  trackingGroups: TrackingGroup[],
  dateTrackingTypeIds: number[]
) => {
  return trackingGroups.some((trackingGroup) => {
    const expiredInfos = trackingGroup.trackingInfoList.filter(
      (trackingInfo) =>
        dateTrackingTypeIds.includes(trackingInfo.trackingTypeId) &&
        trackingInfoIsExpired(trackingInfo)
    );

    return expiredInfos.length > 0;
  });
};

export const pickHasExpiredItems = (pickItems: PickItem[]): boolean => {
  return pickItems.some((pickItem) => {
    const dateTrackingTypeIds = getDateTrackingTypeIds(pickItem);

    return trackingGroupsHaveExpiredItems(
      pickItem.trackingGroupList,
      dateTrackingTypeIds
    );
  });
};

export const shouldShowCommit = (
  numberOfAvailableItems: number,
  pickStatus: PickStatus
) =>
  numberOfAvailableItems > 0 &&
  (pickStatus === PickStatus.Open ||
    pickStatus === PickStatus.Started ||
    pickStatus === PickStatus.PartiallyPicked);

export const shouldShowFinish = (pickStatus: PickStatus) =>
  pickStatus !== PickStatus.Finished;

export const shouldShowVoid = (pickStatus: PickStatus) =>
  pickStatus !== PickStatus.Open;

export const shouldShowStart = (pick: Pick) =>
  pick.pickItems.every(
    (i) =>
      i.status === PickItemStatus.Available || i.status === PickItemStatus.Short
  );

export const shouldShowUnGroup = (pick: Pick) => pick.number[0] === 'G';

export const sortPickItemsByPickFromLocation = (
  items: PickItem[]
): PickItem[] => {
  const pickFromLocationItems = items.filter((item) => item.pickFromLocation);
  const nonPickFromLocationItems = items.filter(
    (item) => !item.pickFromLocation
  );

  pickFromLocationItems.sort((a, b) => {
    const sortNumberA = a.pickFromLocation!.sortNumber ?? Infinity;
    const sortNumberB = b.pickFromLocation!.sortNumber ?? Infinity;
    return sortNumberA - sortNumberB || items.indexOf(a) - items.indexOf(b);
  });

  return [...pickFromLocationItems, ...nonPickFromLocationItems];
};
interface PickingSettings {
  enableOverPicking: boolean;
  notifyWhenOverPicking: boolean;
}
export const handleAutoFinish = async (
  formPickItems: PickItem[],
  setFormPickItems: React.Dispatch<React.SetStateAction<PickItem[]>>,
  setActivePickItem: (item: PickItem) => void,
  summaryFakePickItem: PickItem,
  isInventoryType: boolean,
  pickingSettings: PickingSettings,
  setErrorModal: (modal: ErrorModal) => void,
  setShowNotifyWhenOverPickingModal: (show: boolean) => void,
  getItemInventory: (itemId: number) => Promise<any>,
  calculateAvailableQty: (
    inventory: ItemInventory,
    pickFromLocationId: number | null,
    item: PickItem
  ) => number,
  setIsLoading: (isLoading: boolean) => void
) => {
  const updatedItems = [...formPickItems];

  for (const item of formPickItems) {
    const index = formPickItems.findIndex((i) => i.id === item.id);

    if (
      ((item.amount || 0) > item.quantityAvailable! ||
        (item.amount || 0) > item.quantity!) &&
      isInventoryType
    ) {
      if (!pickingSettings.enableOverPicking) {
        setErrorModal({
          show: true,
          title: 'Overpicking is not enabled',
          msg: 'At the moment, overpicking is not supported. You can enable it under Settings > Picking',
        });
        return;
      } else if (
        pickingSettings.enableOverPicking &&
        pickingSettings.notifyWhenOverPicking
      ) {
        setShowNotifyWhenOverPickingModal(true);
      }
    }

    setIsLoading(true);

    const inventory = await getItemInventory(item.itemId!);

    const quantityAvailable = calculateAvailableQty(
      inventory,
      item.pickFromLocationId,
      item
    );

    updatedItems[index] = {
      ...updatedItems[index],
      amount: Math.min(item.quantity || 0, quantityAvailable),
      quantityAvailable: quantityAvailable,
    };
  }

  setFormPickItems(updatedItems);
  setIsLoading(false);
  setActivePickItem(summaryFakePickItem);
};
