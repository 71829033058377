import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { colorPalette } from 'ui/theme';

export const useSalesOrderDetailsCardStyle = makeStyles((theme: Theme) => ({
  buttonLink: {
    'text-transform': 'none',
    'white-space': 'pre',
    cursor: 'pointer',
  },
  textField: {
    width: theme.spacing(10),
  },
  customStatusPaid: {
    background: colorPalette.statusGreen,
  },
  customStatusUnpaid: {
    background: colorPalette.statusRed,
  },
  customStatusPartiallyPaid: {
    background: `linear-gradient(-45deg, #808089 50%, ${colorPalette.statusGreen} 50%)`,
  },
}));
