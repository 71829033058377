import {
  createFilterOptions,
  FilterOptionsState,
} from '@mui/material/useAutocomplete';
import * as yup from 'yup';

import {
  initialRepresentative,
  Representative,
} from 'services/representatives';

export const yupSchema = yup.object().shape({
  firstName: yup.string(),
  initials: yup.string(),
});

export const representativesAutocompleteGetOptionLabel = (
  representative: Representative
) => {
  if (!representative.id || representative.id < 0) {
    return `+ Add "${representative.firstName}"`;
  }

  return `${representative.firstName || ''} ${representative.lastName || ''}`;
};

const filter = createFilterOptions<Representative>();

export const representativesAutocompleteFilterOptions =
  (disableAdd: boolean) =>
  (options: Representative[], params: FilterOptionsState<Representative>) => {
    const filtered = filter(options, params);

    if (disableAdd) {
      return filtered;
    }

    if (!!params.inputValue) {
      // add default representative at the beginning of options
      filtered.unshift({
        ...initialRepresentative,
        firstName: params.inputValue || null,
      });
    }

    return filtered;
  };
