import React, { memo, useMemo, useContext } from 'react';

import { ShippoWizard, ShippoCard } from './components';
import { ShippoProps } from './types';
import { ShippoContext } from '../../ShippoProvider';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useShippoStyle } from './styled';

const Shippo: React.FC<ShippoProps> = () => {
  const classes = useShippoStyle();
  const { isConnected, isSetupNeeded, isLoading } = useContext(ShippoContext);

  const showShippoCard = useMemo(() => {
    return isConnected && !isSetupNeeded;
  }, [isSetupNeeded]);

  if (isLoading) {
    return (
      <Box className={classes.isLoading}>
        <CircularProgress
          className={classes.spinner}
          size={150}
          thickness={2}
        />
      </Box>
    );
  }

  if (!isConnected) {
    return null;
  }

  return <>{showShippoCard ? <ShippoCard /> : <ShippoWizard />}</>;
};

export default memo(Shippo);
