import _ from 'lodash';

import {
  Receipt,
  ReceiptItem,
  ReceiptItemReceive,
  ReceiptItemStatus,
  ReceiptStatus,
} from 'services/receiving';

export const transformReceiptItemToReceiptItemReceive = (
  receiptItem: ReceiptItem
): ReceiptItemReceive => {
  const unitCost =
    receiptItem.status === ReceiptItemStatus.Reconciled
      ? null
      : _.get(receiptItem, 'purchaseOrderItem.unitCost', null);

  return {
    id: receiptItem.id || -1,
    receiptItemId: receiptItem.id || -1,
    receiptItem,
    locationId: receiptItem.locationId,
    quantity: 0,
    newUnitCost: unitCost,
    dateReceived: null,
    trackingGroups: [],
  };
};

export const shouldShowReceive = (receiptStatus: ReceiptStatus) =>
  receiptStatus === ReceiptStatus.Open ||
  receiptStatus === ReceiptStatus.PartiallyReceived ||
  receiptStatus === ReceiptStatus.PartiallyReconciled ||
  receiptStatus === ReceiptStatus.Reconciled ||
  receiptStatus === ReceiptStatus.PartiallyFulfilled;

export const shouldShowReconcile = (
  receiptStatus: ReceiptStatus,
  salesOrderId: Receipt['salesOrderId'],
  isDropShipReceipt: boolean
) =>
  (salesOrderId === null || isDropShipReceipt) &&
  (receiptStatus === ReceiptStatus.Open ||
    receiptStatus === ReceiptStatus.PartiallyReceived ||
    receiptStatus === ReceiptStatus.PartiallyReconciled ||
    receiptStatus === ReceiptStatus.Received ||
    receiptStatus === ReceiptStatus.PartiallyFulfilled);

export const shouldShowFulfill = (
  receiptStatus: ReceiptStatus,
  salesOrderId: Receipt['salesOrderId']
) => receiptStatus !== ReceiptStatus.Fulfilled && salesOrderId === null;

export const shouldShowVoid = (
  receiptStatus: ReceiptStatus,
  landedAndBilledAreDifferent: boolean
) =>
  receiptStatus === ReceiptStatus.PartiallyReceived ||
  receiptStatus === ReceiptStatus.PartiallyReconciled ||
  receiptStatus === ReceiptStatus.Received ||
  receiptStatus === ReceiptStatus.Reconciled ||
  receiptStatus === ReceiptStatus.PartiallyFulfilled ||
  (receiptStatus === ReceiptStatus.Fulfilled && !landedAndBilledAreDifferent);
