import React from 'react';
import { DRAWER_WIDTH, DRAWER_SMALL_WIDTH } from './consts';

export interface DrawerNavProps {
  children: React.ReactNode;
  persisted: boolean;
}

const DrawerNav = (props: DrawerNavProps) => {
  const { persisted, children } = props;

  const minWidth = persisted ? DRAWER_WIDTH : DRAWER_SMALL_WIDTH;
  const maxWidth = persisted ? DRAWER_WIDTH : DRAWER_SMALL_WIDTH;

  return <nav style={{ minWidth, maxWidth }}>{children}</nav>;
};

export default DrawerNav;
