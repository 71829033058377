import { RequestConfig, defaultMaximumPagination } from 'helpers';
import {
  createApiCall,
  DataWithPagination,
  paginatedApiCall,
} from 'services/api';
import { Address, transformAddressRequest } from 'services/addresses';

import { Customer, CustomerCarrierAccount } from './types';
import {
  transformCustomer,
  transformCustomerToRequestCustomer,
  transformToCustomerCarrierAccounts,
} from './transformations';
import { transformToRequestCustomerCarrierAccount } from '.';

const expands = [
  'customerAddresses.customerAddressContacts',
  'tags',
  'carrierAccountSettings',
  'currency',
  'customerSaleItems.saleItem',
];

export const fetchCustomersAPI = async (
  config: RequestConfig,
  ids: number[] = []
) => {
  const {
    pagination = defaultMaximumPagination,
    expands = [],
    customQuickSearchColumns = [
      'name',
      'representative.firstName',
      'phone',
      'email',
    ],
    quickSearchValue,
  } = config;

  let path = `/v1/customers?expand=${expands.join(',')}`;

  if (quickSearchValue) {
    path += `&quickSearchColumns=${customQuickSearchColumns}&quickSearchValue=${quickSearchValue}`;
  }

  if (ids.length) {
    path += `&id=${ids.join(',')}`;
  }

  const res = await paginatedApiCall(path, pagination, transformCustomer);

  return res;
};

export const fetchCustomerById = async (id: number): Promise<Customer> => {
  const response = await createApiCall({
    path: `/v1/customers/${id}?expand=${expands.join(',')}`,
    method: 'GET',
  })();

  return transformCustomer(response.data);
};

export const createCustomer = async (customer: Customer): Promise<Customer> => {
  const response = await createApiCall(
    {
      path: `/v1/customers?expand=${expands.join(',')}`,
      method: 'POST',
      body: transformCustomerToRequestCustomer(customer),
    },
    {
      getMessage: {
        success: () => 'New customer successfully added',
        error: (error: any) => error.response.data.message,
      },
    }
  )();

  return transformCustomer(response.data);
};

export const updateCustomer = async (customer: Customer): Promise<Customer> => {
  const response = await createApiCall(
    {
      path: `/v1/customers?expand=${expands.join(',')}`,
      method: 'PUT',
      body: transformCustomerToRequestCustomer(customer),
    },
    {
      getMessage: {
        success: () => 'Customer successfully updated',
        error: (error: any) => error.response.data.message,
      },
    }
  )();

  return transformCustomer(response.data);
};

export const deleteCustomer = async (id: number): Promise<void> => {
  await createApiCall(
    {
      path: `/v1/customers/${id}`,
      method: 'DELETE',
    },
    {
      getMessage: {
        success: () => 'Customer successfully deleted',
        error: (error: any) => error.response.data.message,
      },
    }
  )();
};

export const deleteCustomers = async (ids: number[]): Promise<void> => {
  await createApiCall(
    {
      path: `/v1/customers/`,
      method: 'DELETE',
      body: ids,
    },
    {
      getMessage: {
        success: () => 'Customers successfully deleted',
        error: (error: any) => error.response.data.message,
      },
    }
  )();
};

/* CUSTOMER ADDRESS */
export const deleteCustomerAddress = (
  customerId: number,
  addressesIds: number[]
) =>
  createApiCall(
    {
      path: `/v1/customers/${customerId}/addresses`,
      method: 'DELETE',
      body: addressesIds,
    },
    {
      getMessage: {
        success: () => 'Addresses successfully removed',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

export const postCustomerAddress = async (
  customerId: number,
  address: Address
): Promise<Customer> => {
  const response = await createApiCall(
    {
      path: `/v1/customers/${customerId}/addresses`,
      method: 'POST',
      body: [transformAddressRequest(address)],
    },
    {
      getMessage: {
        success: () => 'New address successfully added',
        error: (error: any) => error.response.data.message,
      },
    }
  )();

  return transformCustomer(response.data);
};

/* CUSTOMER CARRIER ACCOUNTS */
export const fetchCustomerCarrierAccounts = async (
  config: RequestConfig,
  customerId: number
): Promise<DataWithPagination<CustomerCarrierAccount>> => {
  const {
    pagination = defaultMaximumPagination,
    customQuickSearchColumns = ['name'],
    quickSearchValue,
  } = config;

  let path = `/v1/customers/${customerId}/carrier_account_settings`;

  if (quickSearchValue) {
    path += `?quickSearchColumns=${customQuickSearchColumns}&quickSearchValue=${quickSearchValue}`;
  }

  const res = await paginatedApiCall(
    path,
    pagination,
    transformToCustomerCarrierAccounts
  );
  return res;
};

export const fetchCustomerCarrierAccountsById = async (
  customerId: number | null,
  carrierAccountSettingsId: number | null
): Promise<CustomerCarrierAccount> => {
  const response = await createApiCall(
    {
      path: `/v1/customers/${customerId}/carrier_account_settings/${carrierAccountSettingsId}`,
      method: 'GET',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();
  return transformToCustomerCarrierAccounts(response.data);
};

export const restoreCustomer = async (customerId: number) => {
  await createApiCall(
    {
      path: `/v1/customers/${customerId}/restore`,
      method: 'POST',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
        success: () => 'Customer successfully restored',
      },
    }
  )();
};

export const createCustomerCarrierAccount = async (
  customerCarrierAccount: CustomerCarrierAccount
) => {
  await createApiCall(
    {
      path: `/v1/customers/${customerCarrierAccount.customerId}/carrier_account_settings`,
      method: 'POST',
      body: [transformToRequestCustomerCarrierAccount(customerCarrierAccount)],
    },
    {
      getMessage: {
        success: () => 'Customer carrier account settings successfully added',
        error: (error: any) => error.response.data.message,
      },
    }
  )();
};

export const updateCustomerCarrierAccount = async (
  customerCarrierAccount: CustomerCarrierAccount
) => {
  await createApiCall(
    {
      path: `/v1/customers/${customerCarrierAccount.customerId}/carrier_account_settings`,
      method: 'PUT',
      body: [transformToRequestCustomerCarrierAccount(customerCarrierAccount)],
    },
    {
      getMessage: {
        success: () => 'Customer carrier account settings successfully updated',
        error: (error: any) => error.response.data.message,
      },
    }
  )();
};
