import { ThumbnailAction, ThumbnailActionType } from './types';

export const showThumbnail = (imageUrl: string): ThumbnailAction => ({
  type: ThumbnailActionType.SHOW_THUMBNAIL,
  payload: imageUrl,
});

export const hideThumbnail = (): ThumbnailAction => ({
  type: ThumbnailActionType.HIDE_THUMBNAIL,
});
