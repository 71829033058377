import { createApiAction } from 'services/api';
import {
  StartAction,
  SuccessAction,
  FailureAction,
} from 'services/api/redux/types';

export enum SettingsPurchaseOrderActionTypes {
  FETCH_PURCHASE_ORDER_START = 'settings/fetch_purchase_order_start',
  FETCH_PURCHASE_ORDER_SUCCESS = 'settings/fetch_purchase_order_success',
  FETCH_PURCHASE_ORDER_FAILURE = 'settings/fetch_purchase_order_failure',
}

// PURCHASE ORDER ACTIONS
const fetchSettingsPurchaseOrderActionStart = (): StartAction => ({
  type: SettingsPurchaseOrderActionTypes.FETCH_PURCHASE_ORDER_START,
  payload: null,
});

const fetchSettingsPurchaseOrderActionSuccess = (
  payload: any
): SuccessAction => {
  return {
    type: SettingsPurchaseOrderActionTypes.FETCH_PURCHASE_ORDER_SUCCESS,
    payload,
  };
};

const fetchSettingsPurchaseOrderActionFailure = (
  error: string
): FailureAction => ({
  type: SettingsPurchaseOrderActionTypes.FETCH_PURCHASE_ORDER_FAILURE,
  payload: { error },
});

export const fetchSettingsPurchaseOrders = () =>
  createApiAction(
    {
      path: '/v1/purchase_order_settings?expand=purchaseTax',
      method: 'GET',
    },
    fetchSettingsPurchaseOrderActionStart,
    fetchSettingsPurchaseOrderActionSuccess,
    fetchSettingsPurchaseOrderActionFailure
  );
