import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useAccountingSettingsPageStyle = makeStyles((theme: Theme) => ({
  cardContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  companyNameContainer: {
    width: '600px',
  },
  companyName: {
    fontWeight: 'bold',
  },

  warningButton: {
    color: 'white',
    background: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  },
}));

export const useCardStyle = makeStyles((theme: Theme) => ({
  paper: {
    width: '832px',
    padding: 0,
  },
  container: {
    paddingBottom: theme.spacing(2),
  },
  rowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 56,
    padding: theme.spacing(2),
  },
  text: {
    width: '40%',
    textAlign: 'right',
  },
  titleContainer: {
    width: '40%',
  },
  link: {
    width: '40%',
    textAlign: 'right',
    cursor: 'pointer',
    ...theme.typography.body1,
  },
  exportButton: {
    marginLeft: '16px',
    marginRight: '16px',
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
}));
