import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable/types';

export const DOCUMENTS_ITEMS_COLUMNS: Column[] = [
  {
    title: 'Name',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Delete',
    width: 50,
    type: ColumnTypes.renderByCustomRow,
    align: 'right',
  },
];
