import { BackgroundAction, BackgroundType } from 'services/backgroundTasks';

import { AlertAction, AlertActionType, AlertProgressAction } from './types';

export const showAlert = (
  msg: string,
  url: string | null = null
): AlertAction => ({
  type: AlertActionType.SHOW_ALERT,
  payload: { show: true, msg, url },
});

export const hideAlert = (): AlertAction => ({
  type: AlertActionType.HIDE_ALERT,
  payload: { show: false, msg: null, url: null },
});

export const showProgressAlert = (
  msg: string,
  backgroundType: BackgroundType,
  backgroundAction: BackgroundAction
): AlertProgressAction => ({
  type: AlertActionType.SHOW_PROGRESS_ALERT,
  payload: { show: true, msg, backgroundType, backgroundAction },
});

export const hideProgressAlert = (
  msg: string | null,
  backgroundType: BackgroundType | null,
  backgroundAction: BackgroundAction | null
): AlertProgressAction => ({
  type: AlertActionType.SHOW_PROGRESS_ALERT,
  payload: { show: false, msg, backgroundType, backgroundAction },
});
