import React, { memo } from 'react';

import { PaymentTabCardProps } from './types';
import { PaymentForm } from './components';

const PaymentTabCard = (props: PaymentTabCardProps) => {
  return <PaymentForm {...props} />;
};

export default memo(PaymentTabCard);
