import { ObjectType } from 'services/customFields';

export interface CustomFieldsModalProps {
  open: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  module: ObjectType;
}

export enum CustomRowActions {
  Edit,
  MoveUp,
  MoveDown,
  Duplicate,
  Delete,
}
