import _ from 'lodash';
import moment from 'moment';

import { ExportRouteType, ExportSampleType, openInNewTab } from 'helpers';
import { createApiCall, showNotification } from 'services/api';
import { reportingApi } from 'services/api/config';
import { ReportParameters } from 'services/reports';

import {
  transformToReportLayout,
  transformToReportPreset,
  transformToReportTheme,
  transformToRequestReportPreset,
  transformToRequestReportTheme,
} from './transfomations';
import { ReportId, ReportLayout, ReportPreset, ReportTheme } from './types';
import { DownloadFileType } from 'ui/components/ReportsViewer/components/ReportsViewerContent/consts';
import { logErrorCtx } from 'app/logging';

export const fetchReportPresets = async (
  userId: number,
  reportId: string
): Promise<ReportPreset[]> => {
  const res = await createApiCall({
    path: `/v1/users/${userId}/reports?reportId=${reportId}`,
    method: 'GET',
  })();

  return res.data.results.map(transformToReportPreset);
};

export const postReportPreset = async (
  userId: number,
  preset: ReportPreset
): Promise<ReportPreset[]> => {
  const res = await createApiCall(
    {
      path: `/v1/users/${userId}/reports`,
      method: 'POST',
      body: [transformToRequestReportPreset(userId, preset)],
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
        success: () => 'New preset created',
      },
    }
  )();

  return res.data.reports
    .filter((r: any) => r.reportId === preset.reportId)
    .map(transformToReportPreset);
};

export const putReportPreset = async (
  userId: number,
  preset: ReportPreset
): Promise<ReportPreset[]> => {
  const res = await createApiCall(
    {
      path: `/v1/users/${userId}/reports`,
      method: 'PUT',
      body: [transformToRequestReportPreset(userId, preset)],
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
        success: () => 'Preset updated',
      },
    }
  )();

  return res.data.reports
    .filter((r: any) => r.reportId === preset.reportId)
    .map(transformToReportPreset);
};

export const deleteReportPreset = async (
  userId: number,
  presetId: number
): Promise<void> => {
  await createApiCall(
    {
      path: `/v1/users/${userId}/reports/${presetId}`,
      method: 'DELETE',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
        success: () => 'Preset deleted',
      },
    }
  )();
};

export const fetchDefaultReportPreset = async (
  userId: number,
  reportId: ReportId
): Promise<ReportPreset | null> => {
  const reportPresets = await fetchReportPresets(userId, reportId);

  const defaultReportPreset = reportPresets.find((p) => p.defaultFlag);

  return defaultReportPreset || null;
};

export const getReportBlob = async (
  reportName: string,
  parameters: ReportParameters,
  reportTheme?: ReportTheme | null,
  downloadFileType?: DownloadFileType,
  reportVersion?: number | null
): Promise<Blob> => {
  const uriParameters = encodeURI(JSON.stringify(parameters));

  // client timezone
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  let urlReport = `/api/v1`;

  if (reportVersion) {
    urlReport = `/api/v${reportVersion}`;
  }

  urlReport += `/reports?reportName=${reportName}&parameters=${uriParameters}&downloadFileType=${downloadFileType}&timezone=${timezone}`;

  if (reportTheme) {
    urlReport += `&themeId=${reportTheme.id}`;
  }

  const response = await reportingApi({
    url: urlReport,
    method: 'GET',
    responseType: 'blob',
  });

  return new Blob([response.data], { type: 'application/octet-stream' });
};

/**
 *
 * @param reportName
 * @returns
 */

export const getReportLayout = async (
  reportName: ReportId
): Promise<ReportLayout[]> => {
  const response = await reportingApi({
    url: `/api/v1/reports/layouts?reportName=${reportName}`,
    method: 'GET',
  });

  return response.data.map(transformToReportLayout);
};

/**
 *
 * @param reportName
 * @returns
 */

export const getReportThemes = async (
  reportName: ReportId
): Promise<ReportTheme[]> => {
  const response = await reportingApi({
    url: `/api/v1/themes?reportName=${reportName}`,
    method: 'GET',
  });

  return response.data.map(transformToReportTheme);
};

/**
 *
 * @param reportName
 * @param theme
 * @returns
 */

export const postReportTheme = async (
  theme: ReportTheme
): Promise<ReportTheme> => {
  const response = await reportingApi({
    url: `/api/v1/themes`,
    method: 'POST',
    data: transformToRequestReportTheme(theme),
  });

  return transformToReportTheme(response.data);
};

/**
 *
 * @param themeId
 * @param theme
 * @returns
 */

export const putReportTheme = async (
  themeId: number,
  theme: ReportTheme
): Promise<ReportTheme> => {
  const response = await reportingApi({
    url: `/api/v1/themes/${themeId}`,
    method: 'PUT',
    data: transformToRequestReportTheme(theme),
  });

  return transformToReportTheme(response.data);
};

/**
 *
 * @param themeId
 */

export const deleteReportTheme = async (themeId: number): Promise<void> => {
  await reportingApi({
    url: `/api/v1/themes/${themeId}`,
    method: 'DELETE',
  });
};

/**
 * Function for downloading report from report service
 *
 * @param reportName
 * @param parameters
 * @param fileName
 */
export const downloadReport = async (
  reportName: string,
  parameters: ReportParameters,
  downloadFileType: DownloadFileType,
  fileName?: string,
  reportTheme?: ReportTheme | null,
  version?: number | null
) => {
  try {
    const blob = await getReportBlob(
      reportName,
      parameters,
      reportTheme,
      downloadFileType,
      version
    );

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      fileName
        ? `${fileName}.${downloadFileType}`
        : `report.${downloadFileType}`
    );
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    const error = err as Error;
    showNotification(
      _.get(
        error,
        'response.statusText',
        'Something went wrong. Please try again.'
      ),
      { variant: 'error' }
    );
    logErrorCtx('Report Download Failed', {
      error,
      stackTrace: error.stack,
      title: `Report download for ${reportName} failed`,
      description: `Report Type: ${downloadFileType} Report Name: ${reportName}`,
      component: 'services/report/api.ts',
    });
  }
};

export const printReport = async (
  reportName: string,
  parameters: { [key: string]: any },
  reportTheme?: ReportTheme | null,
  reportVersion?: number | null
) => {
  try {
    const blob = await getReportBlob(
      reportName,
      parameters,
      reportTheme,
      undefined,
      reportVersion
    );
    const pdfBlob = new Blob([blob], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(pdfBlob);

    const newWindow = window.open(url, '_blank');

    if (newWindow) {
      newWindow.print();
    }
  } catch (err) {
    showNotification(
      _.get(
        err,
        'response.statusText',
        'Something went wrong. Please try again.'
      ),
      { variant: 'error' }
    );
  }
};

/**
 * Function for exporting CSV report
 *
 * @param exportRouteType
 * @param reportName
 */
export const exportCSV = async (
  exportRouteType: ExportRouteType,
  reportName: string = exportRouteType
) => {
  const response = await createApiCall(
    {
      path: `/v1/${exportRouteType}/export/csv`,
      method: 'GET',
    },
    {
      getMessage: {
        success: () => 'Exported successfully.',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  const currentDatetime = moment().format('YYYY-MM-DD_h:mm:ss');
  link.href = url;
  link.setAttribute('download', `${reportName}-${currentDatetime}.csv`);
  document.body.appendChild(link);
  link.click();
};

/**
 * Function for importing CSV report
 *
 * @param importRouteType
 * @param reportName
 */
export const importCSV = async (
  importRouteType: ExportRouteType,
  file: File
) => {
  const formData = new FormData();
  formData.append('file', file);

  await createApiCall(
    {
      path: `/v1/${importRouteType}/upload/csv`,
      method: 'POST',
      body: formData,
      timeout: 900000, //15 minutes
    },
    {
      getMessage: {
        success: () => 'Imported successfully.',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();
};

export const exportSampleCSV = async (exportSampleType: ExportSampleType) => {
  const response = await createApiCall(
    {
      path: `/v1/aws/storage/${exportSampleType}`,
      method: 'GET',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  const url = _.get(response, 'data[0].url');

  if (!url) {
    showNotification("File doesn't exist", { variant: 'error' });
    return;
  }

  openInNewTab(url);
};

/**
 * Accepts list of pdf or image url's and list of reports and will merge everything into 1 PDF
 *
 * @param urls
 * @param reports
 */
export const mergeReports = async (
  urls: string[],
  reports: { reportName: ReportId; parameters: string | ReportParameters }[]
) => {
  const response = await reportingApi({
    url: `/api/v1/reports/pdf-merge`,
    method: 'POST',
    responseType: 'blob',
    data: {
      urls,
      reports: reports.map((r) => ({ ...r, html: false })),
    },
  });

  const blob = new Blob([response.data], { type: 'application/pdf' });

  return blob;
};
