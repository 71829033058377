import React, { memo, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { colorPalette } from 'ui/theme';
import { ReportsViewer } from 'ui/components/ReportsViewer';
import { ReportId, reportList } from 'services/reports';

import { ReportsDetailsCardProps } from './types';
import { StyledForm } from './styled';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const ReportsDetailsCard: React.FC<ReportsDetailsCardProps> = (props) => {
  const { activeId, onClose } = props;

  const [reportVersion, setReportVersion] = useState<number | null>(null);

  const report = useMemo(
    () => reportList.find((r) => r.id === activeId) || null,
    [activeId]
  );

  useEffect(() => {
    if (report?.reportId === ReportId.ConsumptionReport) {
      setReportVersion(2);
    } else {
      setReportVersion(null);
    }
  }, [report]);

  return (
    <StyledForm onSubmit={_.noop}>
      <FBOTitleBar
        title={report ? report.title : null}
        sx={{
          borderBottom: `1px solid ${colorPalette.redesign.background3}`,
        }}
      >
        <FBOButton
          variant="tertiary"
          color="neutral"
          size="medium"
          icon="FBOClose"
          data-qa={`report-${report}-close`}
          onClick={onClose}
        />
      </FBOTitleBar>

      {!!report && (
        <ReportsViewer
          reportId={report.reportId}
          reportVersion={reportVersion}
        />
      )}
    </StyledForm>
  );
};

export default memo(ReportsDetailsCard);
