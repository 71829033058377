import { Tag } from 'services/tags';

export interface PricingRule {
  id: number | null;
  checkVersion: boolean;
  customerId: number | null;
  customerTags: Tag[];
  dateCreated: Date | null;
  dateLastModified: Date | null;
  deleted: boolean;
  description: string | null;
  endDate: Date | null;
  maxQuantity: number | null;
  minQuantity: number | null;
  name: string | null;
  percent: string | null;
  price: number | null;
  relativeTo: PricingRuleRelativeToType | null;
  saleItemId: number | null;
  itemId: number | null;
  saleItemTags: Tag[];
  startDate: Date | null;
  type: PricingRuleType | null;
  version: number | null;
}

export enum PricingRuleType {
  Markdown = 'Markdown',
  Markup = 'Markup',
  Margin = 'Margin',
  Fixed = 'Fixed',
}

export enum PricingRuleRelativeToType {
  AverageCost = 'Average Cost',
  ListPrice = 'List Price',
}
