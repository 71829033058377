import {
  ReconcileCalculateOptions,
  ReconcileExpense,
  Reconcile,
} from 'services/receiving';
import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

import { ReconcileWizardStep, WizardSteps } from './types';

export const reconcileWizardColumns: Column[] = [
  {
    title: 'Steps',
    type: ColumnTypes.renderByCustomRow,
  },
];

export const reconcileWizardSteps: ReconcileWizardStep[] = [
  {
    id: WizardSteps.SelectItems,
    checked: false,
    title: 'Select Items',
    description: 'Select items to be reconciled',
  },
  {
    id: WizardSteps.Expenses,
    checked: false,
    title: 'Add Expense',
    description: 'Select items to be reconciled',
  },
  {
    id: WizardSteps.ItemsToReconcile,
    checked: false,
    title: 'Select Items To Reconcile',
    description: 'Calculate landed cost',
  },
  {
    id: WizardSteps.Summary,
    checked: false,
    title: 'Summary',
    description: 'Review reconcile information',
  },
];

export const initialReconcileExpense: ReconcileExpense = {
  itemId: null,
  name: null,
  description: null,
  quantity: 1,
  cost: null,
  currency: null,
  multiCurrencyCost: null,
  addToVendorBill: true,
  new: true,
  receiptItemId: null,
  abbreviation: null,
};

export const initialReconcile: Reconcile = {
  referenceNumber: null,
  billDate: null,
  dueDate: null,
  selectedItems: [],
  reconcileReceiptItems: [],
  currency: null,
  calculation: ReconcileCalculateOptions.Cost,
  expenses: [],
  itemsToReconcile: [],
  selectedReconcileItems: [],
  reconcilePrices: {},
  summary: [],
  adjustment: 0,
};
