import React from 'react';
import { Box } from '@mui/material';
import { SpinLoaderProps } from './types';
import SpinLoaderImage from 'ui/theme/images/json/spin_loader.json';
import { useSpinnerStyle } from './styled';
import Lottie from 'lottie-react';

export const SpinLoader = (props: SpinLoaderProps) => {
  const style = useSpinnerStyle(props);

  const lottieStyles = {
    height: 50,
    width: 50,
  };

  return (
    <>
      <Box className={style.background} />
      <Box className={style.spinner}>
        <Lottie
          animationData={SpinLoaderImage}
          loop={true}
          style={lottieStyles}
          role="img"
          data-qa="sales-order-quick-fulfill-loading-spinner-animation"
        />
      </Box>
    </>
  );
};
