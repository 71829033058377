import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, InputAdornment } from '@mui/material';

import _ from 'lodash';
import moment from 'moment';

import { Ledger, getLedgersAPI } from 'services/ledgers';
import { DateRange } from 'materialui-daterange-picker';
import { PaperSlidingLayout } from 'ui/components/Paper/PaperSlidingLayout';
import { Page } from 'ui/components/Page/Page';
import { Pagination } from 'services/search';
import DateRangePicker from 'ui/components/TextField/DateRangePicker/DateRangePicker';

import { LedgerDetailsCard, LedgersSearchResults } from './components';
import {
  initialPagination,
  initialDateRange,
  customDateRangeLedger,
} from './consts';
import { LedgersPageCmp, LedgersPageProps } from './types';
import { Routes } from '../../navigation';
import { AccountModal } from './components/AccountModal';
import { logErrorCtx } from 'app/logging';
import { colorPalette, icons } from 'ui/theme';
import { FBOSearchInput } from 'ui/theme/components/FBOSearchHeader/styled';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LedgersPage: LedgersPageCmp = (props: LedgersPageProps) => {
  const [dateRange, setDateRange] = useState<DateRange>(initialDateRange);
  const [ledgers, setLedgers] = useState<Ledger[]>([]);
  const [activeId, setActiveId] = useState<number | null>(null);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [search, setSearch] = useState<string | null>(null);
  const [pagination, setPagination] = useState<Pagination>(initialPagination);
  const [loadingItems, setLoadingItems] = useState(false);

  const startMoment = useMemo(
    () => moment(dateRange.startDate),
    [dateRange.startDate]
  );
  const endMoment = useMemo(
    () => moment(dateRange.endDate),
    [dateRange.endDate]
  );

  const filteredLedgerList = useMemo(() => {
    if (!search) {
      return ledgers;
    }

    const rgxp = new RegExp(search, 'gi');
    return ledgers.filter(
      (l) => l.accountName!.match(rgxp) || l.accountType!.match(rgxp)
    );
  }, [search, ledgers]);

  // reorder data when pagination sort changed
  useEffect(() => {
    setLedgers(
      _.orderBy(ledgers, pagination.sort.sortBy, pagination.sort.direction)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.sort]);

  useEffect(() => {
    getLedgers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startMoment, endMoment]);

  const getLedgers = useCallback(async () => {
    setLoadingItems(true);
    // we need to empty ledgers if we want loader to show
    setLedgers([]);

    try {
      const res = (
        await getLedgersAPI(
          {},
          startMoment.toISOString(),
          endMoment.toISOString()
        )
      ).data;
      setLedgers(
        _.orderBy(res, pagination.sort.sortBy, pagination.sort.direction)
      );
    } catch (e) {
      logErrorCtx('Error in getLedgersAPI', {
        error: e as Error,
        component: 'LedgersPage',
      });
    }

    setLoadingItems(false);
  }, [pagination, startMoment, endMoment]);

  const handleDateChange = useCallback((value: DateRange) => {
    const startDate = moment(value.startDate).startOf('day').toDate();
    const endDate = moment(value.endDate).endOf('day').toDate();

    setDateRange({ startDate, endDate });
  }, []);

  const handleModalClose = () => {
    setActiveId(null);
  };

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = event.target.value;
      setSearch(value || null);
    },
    []
  );

  return (
    <>
      <Page>
        <Box
          display="flex"
          mb={'16px'}
          sx={{
            alignItems: 'center',
            padding: '16px',
            backgroundColor: `${colorPalette.white}`,
            borderRadius: '10px',
            gap: '8px',
          }}
        >
          <Box mr={0} sx={{ flexGrow: '1' }}>
            <FBOSearchInput
              type="text"
              placeholder="Search"
              value={search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <icons.MagnifyingGlass />
                  </InputAdornment>
                ),
                sx: { paddingLeft: '8px' },
              }}
              onChange={handleSearchChange}
              style={{ width: '100%', margin: 0 }}
              data-qa="search-ledgers"
            />
          </Box>
          <Box style={{ margin: 0 }}>
            <DateRangePicker
              onChange={handleDateChange}
              value={dateRange}
              label={''}
              style={{ width: 235, margin: 0 }}
              definedRanges={customDateRangeLedger}
              data-qa="leaders-page-date-range"
              aria-label="Date Range"
            />
          </Box>
        </Box>
        <PaperSlidingLayout shown={Boolean(activeId)}>
          <LedgersSearchResults
            items={filteredLedgerList}
            activeItemId={activeId}
            handleItemClick={setActiveId}
            isLoadingItems={loadingItems}
            pagination={pagination}
            onPaginationChange={setPagination}
            openCreateModal={setOpenCreateModal}
          />
          <LedgerDetailsCard
            activeItemId={activeId}
            ledgers={filteredLedgerList}
            onClose={handleModalClose}
            getLedgers={getLedgers}
            startMoment={startMoment}
            endMoment={endMoment}
          />
        </PaperSlidingLayout>
      </Page>
      <AccountModal
        open={openCreateModal}
        onClose={() => setOpenCreateModal(false)}
        getLedgers={getLedgers}
      />
    </>
  );
};

LedgersPage.route = Routes.LedgersPage;

export default LedgersPage;
