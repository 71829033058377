import {
  PurchaseOrderItemTypes,
  initialPurchaseOrderItem,
  PurchaseOrderItem,
  PurchaseOrderItemStatus,
} from 'services/purchaseOrders';
import { Item } from 'services/items';
import { VendorItem } from 'services/vendors';
import { MenuItem } from 'ui/components/Menu';
import {
  findNextNegativeId,
  replaceValueInCollection,
  toMulticurrencyCalculate,
} from 'helpers';
import { TaxRate } from 'services/taxRates';

export const convertPurchaseOrderItemToNegativePrice = (
  value: number,
  rowType: PurchaseOrderItemTypes
) => {
  if (
    (rowType === PurchaseOrderItemTypes.MiscCredit ||
      rowType === PurchaseOrderItemTypes.CreditReturn) &&
    value > 0
  ) {
    return -value;
  }
  return value;
};

export const findNextLineNumber = (purchaseOrderItems: PurchaseOrderItem[]) => {
  if (purchaseOrderItems.length !== 0) {
    const lineNumbers = purchaseOrderItems.map((item) =>
      item.lineNumber ? item.lineNumber : 1
    );
    return Math.max(...lineNumbers) + 1;
  }
  return 1;
};

export const orderLineNumbers = (poItems: PurchaseOrderItem[]) =>
  poItems.map((s, i) => ({ ...s, lineNumber: i + 1 }));

export const moveDownPoItem = (
  poItem: PurchaseOrderItem,
  poItems: PurchaseOrderItem[]
) => {
  let notDeletedPoItems = poItems.filter((i) => !i.deleted);
  const deletedPoItems = poItems.filter((i) => i.deleted);

  const index = notDeletedPoItems.findIndex((i) => i.id === poItem.id);

  if (index + 1 === notDeletedPoItems.length) {
    return poItems;
  }

  const nextItem = notDeletedPoItems[index + 1];

  notDeletedPoItems = replaceValueInCollection(
    notDeletedPoItems,
    poItem,
    index + 1
  )!;

  notDeletedPoItems = replaceValueInCollection(
    notDeletedPoItems,
    nextItem,
    index
  )!;

  return orderLineNumbers([...notDeletedPoItems, ...deletedPoItems]);
};

export const moveUpPoItem = (
  poItem: PurchaseOrderItem,
  poItems: PurchaseOrderItem[]
) => {
  let notDeletedPoItems = poItems.filter((i) => !i.deleted);
  const deletedPoItems = poItems.filter((i) => i.deleted);

  const index = notDeletedPoItems.findIndex((i) => i.id === poItem.id);

  if (index === 0) {
    return poItems;
  }

  const prevItem = notDeletedPoItems[index - 1];

  notDeletedPoItems = replaceValueInCollection(
    notDeletedPoItems,
    poItem,
    index - 1
  )!;

  notDeletedPoItems = replaceValueInCollection(
    notDeletedPoItems,
    prevItem,
    index
  )!;

  return orderLineNumbers([...notDeletedPoItems, ...deletedPoItems]);
};

export const duplicatePoItem = (
  poItem: PurchaseOrderItem,
  poItems: PurchaseOrderItem[]
) => {
  const notDeletedPoItems = poItems.filter((i) => !i.deleted);
  const deletedPoItems = poItems.filter((i) => i.deleted);

  const duplicatedPoItem = {
    ...poItem,
    id: findNextNegativeId<PurchaseOrderItem>(poItems),
    status: PurchaseOrderItemStatus.Entered,
    version: null,
  };

  return orderLineNumbers([
    ...notDeletedPoItems,
    duplicatedPoItem,
    ...deletedPoItems,
  ]);
};

export const duplicatePoItemToCreditReturn = (
  poItem: PurchaseOrderItem,
  poItems: PurchaseOrderItem[]
) => {
  const notDeletedPoItems = poItems.filter((i) => !i.deleted);
  const deletedPoItems = poItems.filter((i) => i.deleted);

  const newType =
    poItem.purchaseOrderItemType === PurchaseOrderItemTypes.Purchase
      ? PurchaseOrderItemTypes.CreditReturn
      : PurchaseOrderItemTypes.MiscCredit;

  const duplicatedPoItem = {
    ...poItem,
    id: findNextNegativeId<PurchaseOrderItem>(poItems),
    status: PurchaseOrderItemStatus.Entered,
    purchaseOrderItemType: newType,
    unitCost: poItem.unitCost ? -poItem.unitCost : 0,
    multiCurrencyUnitCost: poItem.multiCurrencyUnitCost
      ? -poItem.multiCurrencyUnitCost
      : 0,
    version: null,
  };

  return orderLineNumbers([
    ...notDeletedPoItems,
    duplicatedPoItem,
    ...deletedPoItems,
  ]);
};

export const removePoItem = (
  poItem: PurchaseOrderItem,
  poItems: PurchaseOrderItem[]
) => {
  const notDeletedPoItems = poItems.filter(
    (i) => !i.deleted && i.id !== poItem.id
  );
  const deletedPoItems = poItems.filter((i) => i.deleted);

  return orderLineNumbers([
    ...notDeletedPoItems,
    ...deletedPoItems,
    { ...poItem, deleted: true },
  ]);
};

export const findVendorItemName = (
  vendorId: number,
  vendorItems: VendorItem[]
) => {
  const vendorItem = vendorItems.find((i) => i.vendorId === vendorId);
  return vendorItem ? vendorItem.name : '';
};

export const createNewPurchaseOrderItem = (
  vendorId: number | null,
  purchaseOrderItemType: PurchaseOrderItemTypes,
  selectedItem: Item | null,
  poi: PurchaseOrderItem[],
  selectedUomId: number,
  amount: number | null,
  resolvedVendorItemName: string | null,
  newCost: number | null,
  exchangeRate: number | null,
  taxRate: TaxRate | null,
  taxRates: TaxRate[] = [],
  costIncludesTax: boolean = false
): PurchaseOrderItem => {
  let taxedPrice = 0;
  const taxable = selectedItem
    ? selectedItem.taxable || selectedItem.taxable === null
    : false;

  let newItemTaxRatePercentage: number | null = null;
  let newItemTaxRate: TaxRate | null = null;

  if (taxable) {
    const newItemTaxId = selectedItem ? selectedItem.salesTaxId : null;

    newItemTaxRate = newItemTaxId
      ? taxRates.find((tr: TaxRate) => newItemTaxId === tr.id) || null
      : taxRate;

    newItemTaxRatePercentage = newItemTaxRate
      ? newItemTaxRate.percentage
      : null;
  }
  if (
    costIncludesTax &&
    newItemTaxRatePercentage &&
    newItemTaxRatePercentage > 0
  ) {
    taxedPrice = newCost!;
  }

  const totalCost = (amount || 1) * (taxedPrice || 0);

  const convertedUnitCost = convertPurchaseOrderItemToNegativePrice(
    taxedPrice || 0,
    purchaseOrderItemType
  );

  switch (purchaseOrderItemType) {
    case PurchaseOrderItemTypes.Purchase: {
      return {
        ...initialPurchaseOrderItem,
        id: -poi.length - 1,
        name: selectedItem!.name,
        purchaseOrderItemType,
        quantity: amount,
        uomId: selectedUomId,
        unitCost: taxedPrice
          ? taxedPrice
          : selectedItem
          ? selectedItem.cost
          : null,
        itemId: selectedItem!.id,
        description: selectedItem!.description,
        item: selectedItem,
        lineNumber: findNextLineNumber(poi),
        vendorItemName: resolvedVendorItemName,
        exchangeRate,
        multiCurrencyUnitCost: toMulticurrencyCalculate(
          taxedPrice || 0,
          exchangeRate || 1
        ),
        multiCurrencyTotalCost: toMulticurrencyCalculate(
          totalCost,
          exchangeRate || 1
        ),
        taxable,
        taxRate: newItemTaxRatePercentage,
        taxId: newItemTaxRate ? newItemTaxRate.id : null,
      };
    }
    case PurchaseOrderItemTypes.CreditReturn:
      return {
        ...initialPurchaseOrderItem,
        id: -poi.length - 1,
        name: selectedItem!.name,
        purchaseOrderItemType,
        quantity: amount,
        uomId: selectedUomId,
        unitCost: calculateCreditReturnPrice(selectedItem, vendorId),
        itemId: selectedItem!.id,
        description: selectedItem!.description,
        item: selectedItem,
        lineNumber: findNextLineNumber(poi),
        vendorItemName: resolvedVendorItemName,
        exchangeRate,
        taxable,
        taxRate: newItemTaxRatePercentage,
        taxId: newItemTaxRate ? newItemTaxRate.id : null,
        multiCurrencyUnitCost: toMulticurrencyCalculate(
          convertedUnitCost,
          exchangeRate || 1
        ),
        multiCurrencyTotalCost: toMulticurrencyCalculate(
          totalCost,
          exchangeRate || 1
        ),
      };
    case PurchaseOrderItemTypes.MiscPurchase:
    case PurchaseOrderItemTypes.MiscCredit:
      return {
        ...initialPurchaseOrderItem,
        id: -poi.length - 1,
        purchaseOrderItemType,
        exchangeRate,
        lineNumber: findNextLineNumber(poi),
        taxable,
        taxRate: newItemTaxRatePercentage,
        taxId: newItemTaxRate ? newItemTaxRate.id : null,
        multiCurrencyUnitCost: toMulticurrencyCalculate(
          convertedUnitCost,
          exchangeRate || 1
        ),
        multiCurrencyTotalCost: toMulticurrencyCalculate(
          totalCost,
          exchangeRate || 1
        ),
      };
    case PurchaseOrderItemTypes.Note:
      return {
        ...initialPurchaseOrderItem,
        id: -poi.length - 1,
        name: null,
        purchaseOrderItemType,
        description: null,
        lineNumber: findNextLineNumber(poi),
      };
  }

  return initialPurchaseOrderItem;
};

export const calculateCreditReturnPrice = (
  selectedItem: Item | null,
  vendorId?: number | null
) => {
  const vendorItem = selectedItem?.vendorItems.find(
    (item) => item.itemId === selectedItem.id && item.vendorId === vendorId
  );

  const purchaseItemPrice = Math.abs(vendorItem?.cost || 0);
  return purchaseItemPrice === 0 ? 0 : -purchaseItemPrice;
};

export const createOptionsForItemSelected = (
  itemClicked: (type: PurchaseOrderItemTypes) => void
): MenuItem[] => [
  {
    label: 'Purchase',
    onOptionClick: () => itemClicked(PurchaseOrderItemTypes.Purchase),
    dataQa: 'purchase-order-purchase',
  },
  {
    label: 'Credit Return',
    onOptionClick: () => itemClicked(PurchaseOrderItemTypes.CreditReturn),
    dataQa: 'purchase-order-credit-return',
  },
];

export const createOptionsForItemNotSelected = (
  itemClicked: (type: PurchaseOrderItemTypes) => void
): MenuItem[] => [
  {
    label: 'Miscellaneous Purchase',
    onOptionClick: () => itemClicked(PurchaseOrderItemTypes.MiscPurchase),
    dataQa: 'purchase-order-miscellaneous-purchase',
  },
  {
    label: 'Miscellaneous Credit Return',
    onOptionClick: () => itemClicked(PurchaseOrderItemTypes.MiscCredit),
    dataQa: 'purchase-order-miscellaneous-credit-return',
  },
  {
    label: 'Note',
    onOptionClick: () => itemClicked(PurchaseOrderItemTypes.Note),
    dataQa: 'purchase-order-note',
  },
];
