import _ from 'lodash';
import * as yup from 'yup';

import {
  ItemInventoryEvents,
  SerialRow,
  TrackingGroup,
} from 'services/inventory';

export const getInventoryEventSchema = (event: ItemInventoryEvents) => {
  const schema = yup.object().shape({
    locationFromId: yup.number().required(),
    quantity: yup.number().required(),
    uomId: yup.number().required(),
  });

  if (event === ItemInventoryEvents.Move) {
    return schema.shape({
      locationToId: yup.number().required(),
    });
  }

  if (event === ItemInventoryEvents.Add) {
    return schema.shape({
      cost: yup.number().required(),
    });
  }
  return schema;
};

export const inventoryEventTrackingGroupValidation = (
  hasSerialTracking: boolean,
  trackingGroups: TrackingGroup[],
  event: ItemInventoryEvents
) => {
  // check if any tracking info has empty value
  const hasTrackingErrors = trackingGroups.some((trg) => {
    if (trg.quantity && trg.quantity > 0) {
      return trg.trackingInfoList.some((ti) => _.isNull(ti.value));
    }
    return false;
  });

  if (hasSerialTracking) {
    const isSerialValid = inventoryEventSerialValidation(trackingGroups, event);
    return isSerialValid || hasTrackingErrors;
  }
  return hasTrackingErrors;
};

const inventoryEventSerialValidation = (
  trackingGroups: TrackingGroup[],
  event: ItemInventoryEvents
) => {
  // for move and remove check selected serial ids else check serial list
  if (
    event === ItemInventoryEvents.Remove ||
    event === ItemInventoryEvents.Move
  ) {
    return trackingGroups
      .filter((tg) => tg.quantity)
      .some((tg) => tg.serialIds.length !== tg.quantity);
  }

  return trackingGroups
    .filter((tg) => tg.quantity)
    .some((tg) => tg.serialList.length !== tg.quantity);
};

export const inventoryEventOnlySerialListValidation = (
  serialList: SerialRow[],
  itemQuantity: number,
  event: ItemInventoryEvents
) => {
  // only validate add and cycle events
  if (event === ItemInventoryEvents.Add) {
    return (
      serialList.some((s) => Object.values(s.serialNumbers).some(_.isNull)) ||
      itemQuantity > serialList.length
    );
  }

  if (event === ItemInventoryEvents.Cycle) {
    return (
      serialList.some((s) => Object.values(s.serialNumbers).some(_.isNull)) ||
      itemQuantity != serialList.length
    );
  }
  return false;
};
