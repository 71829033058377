export interface Ledger {
  id: number | null;
  accountName: string | null;
  accountType: AccountType | null;
  startBalance: number | null;
  endBalance: number | null;
  ledgers: LedgerLineItem[];
  total: number | null;
  markedAsPosted: boolean | null;
}

export interface LedgerLineItem {
  id: number | null;
  date: Date | null;
  ledgerType: string | null;
  number: string | null;
  name: string | null;
  description: string | null;
  split: string[];
  amount: number | null;
  balance: number | null;
  ledgerId: number | null;
  payee: string | null;
  externalTransactionId: number | null;
  datePosted: Date | null;
}

export interface LedgerSimple {
  date: Date | null;
  ledgerId: number | null;
  ledgerItems: LedgerItemSimple[];
  name: string | null;
  refNo: string | null;
  type: string | null;
  customerId: string | null;
  accountingClassId: string | null;
}

export interface LedgerItemSimple {
  accountId: number | null;
  accountName: string | null;
  creditAmount: number | null;
  debitAmount: number | null;
  ledgerItemId: number | null;
  memo: string | null;
}

export enum AccountType {
  AccountsPayable = 'Accounts Payable',
  AccountsReceivable = 'Accounts Receivable',
  Bank = 'Bank',
  CreditCard = 'Credit Card',
  Equity = 'Equity',
  Asset = 'Asset',
  OtherCurrentAsset = 'Other Current Asset',
  FixedAsset = 'Fixed Asset',
  OtherCurrentLiability = 'Other Current Liability',
  LongTermLiability = 'Long Term Liability',
  Income = 'Income',
  Expense = 'Expense',
  CostOfGoodsSold = 'Cost of Goods Sold',
  Holding = 'Holding',
}

export interface LedgerForWaitingToExportModal {
  id: number | null;
  postDate: Date | null;
  type: string | null;
  note: string | null;
  purchaseOrderId: number | null;
  salesOrderId: number | null;
}
