import moment from 'moment';
import axios from 'axios';
import _ from 'lodash';

import { api, createApiCall, DataWithPagination } from 'services/api';
import { quickSearchURL } from 'services/url';
import { defaultMaximumPagination, RequestConfig } from 'helpers';

import { SaleItem } from './types';
import {
  transformSaleItem,
  transformSaleItemPricingRule,
  transformSaleItemsWithBundles,
} from './transformations';
import { ItemType } from '../types';

const expands = [
  'saleItems.images',
  'saleItems.item.vendorItems',
  'saleItems.tags',
  'saleItems.item.itemTrackingTypes',
  'saleItems.defaultUom',
  'saleItems.defaultUom.toConversions',
  'saleItems.defaultUom.fromConversions',
  'saleItems.item.itemSubstitutes.substituteItem',
  'saleItems.item.itemUomConversions',
  'saleItems.item.images',
  'bundles.bundleItems',
  'bundles.images',
  'bundles.tags',
  'bundles.itemTrackingTypes',
  'bundles.defaultUom',
  'bundles.itemLocations',
  'bundles.bundleItems.saleItem.images',
];

export const getSaleItems = async (
  itemName: string | null,
  pageSize?: number,
  itemTypes: ItemType[] = []
): Promise<SaleItem[]> => {
  let basePath = `/v1/sale_items?expand=images,tags,item.itemTrackingTypes,defaultUom,item.itemSubstitutes.substituteItem&pageSize=${
    pageSize || 99
  }`;

  if (itemTypes.length) {
    basePath += `&itemType=${itemTypes}`;
  }

  const pathWithQuickSearch = quickSearchURL(basePath, {
    value: itemName || '',
    columns: [
      { field: 'name' } as any,
      { field: 'description' } as any,
      { field: 'sku' } as any,
      { field: 'tags.name' } as any,
    ],
  });

  const saleItemResponse = await createApiCall({
    path: pathWithQuickSearch,
    method: 'GET',
  })();

  return saleItemResponse.data.results.map((soi: any) =>
    transformSaleItem(soi)
  );
};

export const getSaleItemsWithBundles = async (
  itemName: string | null,
  pageSize?: number
): Promise<SaleItem[]> => {
  const basePath = `/v1/sales_orders/items?expand=saleItems.images,saleItems.item.vendorItems,saleItems.tags,saleItems.item.itemTrackingTypes,saleItems.defaultUom,saleItems.item.itemSubstitutes.substituteItem,bundles.bundleItems,bundles.images,bundles.tags,bundles.itemTrackingTypes,bundles.defaultUom,bundles.itemLocations&pageSize=${
    pageSize || 99
  }`;
  const pathWithQuickSearch = quickSearchURL(basePath, {
    value: itemName || '',
    columns: [
      { field: 'name' } as any,
      { field: 'description' } as any,
      { field: 'sku' } as any,
      { field: 'tags.name' } as any,
    ],
  });

  const saleItemResponse = await createApiCall({
    path: pathWithQuickSearch,
    method: 'GET',
  })();

  return transformSaleItemsWithBundles(saleItemResponse.data);
};

export const fetchSaleItemsAPI = async (
  config: RequestConfig
): Promise<DataWithPagination<SaleItem>> => {
  const {
    pagination = defaultMaximumPagination,
    customQuickSearchColumns = ['name', 'description', 'sku', 'tags.name'],
    quickSearchValue,
  } = config;

  let basePath = `${
    api.hostname
  }/v1/sales_orders/items?expand=${expands.join()}&pageSize=${
    pagination.pageSize
  }&pageNumber=${pagination.page}`;

  if (quickSearchValue) {
    basePath += `&quickSearchColumns=${customQuickSearchColumns}&quickSearchValue=${quickSearchValue}`;
  }

  const { data } = await axios.get(basePath);

  const result = {
    data: _.sortBy(transformSaleItemsWithBundles(data), 'name'),
    pagination: { ...pagination, totalRows: data.maxResults },
  };
  return result;
};

export const fetchSaleItem = async (id: number) => {
  const res = await createApiCall(
    {
      path: `/v1/sale_items/${id}?expand=item.vendorItems`,
      method: 'GET',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformSaleItem(res.data);
};

export const addSaleItems = (itemId: number, newSaleItems: SaleItem[]) =>
  createApiCall(
    {
      path: `/v1/items/${itemId}/sale_items`,
      method: 'POST',
      body: newSaleItems,
    },
    {
      getMessage: {
        success: () => 'New Sale Item successfully added',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

export const updateSaleItems = (itemId: number, newSaleItems: SaleItem[]) =>
  createApiCall(
    {
      path: `/v1/items/${itemId}/sale_items`,
      method: 'PUT',
      body: newSaleItems,
    },
    {
      getMessage: {
        success: () => 'Sale Item successfully updated',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

export const deleteSaleItems = (itemId: number, saleItemIds: number[]) =>
  createApiCall(
    {
      path: `/v1/items/${itemId}/sale_items`,
      method: 'DELETE',
      body: saleItemIds,
    },
    {
      getMessage: {
        success: () => 'Sale Items successfully removed',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

export const getSaleItemPricingRule = async (
  itemId: number | null,
  saleItemId: number | null,
  customerId: number,
  quantity: number,
  uomId: number
) => {
  const res = await createApiCall(
    {
      path: `/v1/items/${itemId}/price_request`,
      method: 'POST',
      body: {
        itemId,
        saleItemId,
        customerId,
        quantity,
        date: moment().format('YYYY-MM-DD'),
        uomId,
      },
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  const priceRule =
    res.data?.length > 1
      ? res.data?.find((rule: any) => rule.saleItemId === saleItemId)
      : res.data?.[0];

  return transformSaleItemPricingRule(priceRule);
};
