import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import _ from 'lodash';

import {
  initialInventoryRow,
  InventoryRow,
  itemHasOnlySerialTracking,
  itemHasSerialTracking,
  ItemInventory,
  SerialRow,
  TrackingGroup,
} from 'services/inventory';
import { replaceValueInCollection } from 'helpers';
import { Modal } from 'ui/components/Modal/Modal';
import { ItemsTable } from 'ui/components/Table/ItemsTable';

import {
  TrackingTableTypes,
  transformToTrackingGroupsNewGroup,
} from 'ui/components/Table/TrackingTable';

import { TrackingWizardColumns, TrackingWizardTableHeight } from './consts';
import {
  TrackingWizardForm,
  TrackingWizardRow,
  TrackingWizardSummary,
} from './components';
import { transformToTrackingGroupWithNewTrackings } from './transformations';
import {
  existingInventoryOnlySerialListValidation,
  existingInventoryTrackingGroupValidation,
} from './validations';
import TrackingTable from 'ui/components/Table/TrackingTable/TrackingTable';
import SerialTable from 'ui/components/Table/SerialTable/SerialTable';

import { colorPalette, themeRestyle } from 'ui/theme';
import { Item } from 'services/items';

interface TrackingWizardProps {
  open: boolean;
  itemInventory: ItemInventory;
  item: Item;
  newTrackingTypeIds: number[];
  existingItemTrackingTypeIds: number[];
  onClose: () => void;
  onSubmit: (inventoryRows: InventoryRow[]) => Promise<void>;
  setItem?: React.Dispatch<React.SetStateAction<Item>>;
}

const FBOTrackingWizard: React.FC<TrackingWizardProps> = (props) => {
  const {
    open,
    itemInventory,
    item,
    newTrackingTypeIds,
    existingItemTrackingTypeIds,
    onClose,
    onSubmit,
    setItem,
  } = props;

  const [inventoryRows, setInventoryRows] = useState<InventoryRow[]>([]);
  const [serialList, setSerialList] = useState<SerialRow[]>([]);

  const [activeInventoryRow, setActiveInventoryRow] =
    useState<InventoryRow>(initialInventoryRow);

  const hasOnlySerial = useMemo(() => itemHasOnlySerialTracking(item), [item]);

  const hasSerial = useMemo(() => itemHasSerialTracking(item), [item]);

  const showSummaryPage = activeInventoryRow && activeInventoryRow.id === -1;

  const isTrackingInvalid = useMemo(() => {
    const trackingGroupList = [
      {
        ...activeInventoryRow.trackingGroupList[0],
        quantity: activeInventoryRow.totalQty,
      },
    ];

    return hasOnlySerial
      ? existingInventoryOnlySerialListValidation(serialList)
      : existingInventoryTrackingGroupValidation(hasSerial, trackingGroupList);
  }, [
    activeInventoryRow.trackingGroupList,
    activeInventoryRow.totalQty,
    serialList,
    hasOnlySerial,
    hasSerial,
  ]);

  useEffect(() => {
    if (open) {
      const inventory = itemInventory.inventoryRowList.filter(
        (i) => i.totalQty
      );
      setInventoryRows(inventory); // set fake row as summary page

      const trackingGroups = getTrackingGroupsByInventoryRow(inventory[0]);

      setActiveInventoryRow({
        ...inventory[0],
        trackingGroupList: trackingGroups,
      });

      if (hasOnlySerial) {
        setSerialList(trackingGroups[0].serialList);
      } else {
        setSerialList([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleAddNewTracking = useCallback(() => {
    const totalQty = Math.max(
      0,
      activeInventoryRow.totalQty -
        activeInventoryRow.trackingGroupList
          .map((t) => t.quantity || 0)
          .reduce((a, b) => a + b, 0)
    );

    const trackingGroups = transformToTrackingGroupsNewGroup(
      activeInventoryRow.trackingGroupList,
      item.itemTrackingTypeList,
      totalQty
    );

    setActiveInventoryRow({
      ...activeInventoryRow,
      trackingGroupList: trackingGroups,
    });
  }, [activeInventoryRow, item.itemTrackingTypeList]);

  const handleSetTrackingGroups = useCallback(
    (trackingGroups: TrackingGroup[]) => {
      setActiveInventoryRow({
        ...activeInventoryRow,
        trackingGroupList: trackingGroups,
      });
    },
    [activeInventoryRow]
  );

  const getTrackingGroupsByInventoryRow = useCallback(
    (inventoryRow: InventoryRow) => {
      if (_.isEmpty(inventoryRow.trackingGroupList)) {
        return transformToTrackingGroupsNewGroup(
          [],
          item.itemTrackingTypeList,
          inventoryRow.totalQty
        );
      }

      return transformToTrackingGroupWithNewTrackings(
        inventoryRow.trackingGroupList,
        item.itemTrackingTypeList,
        newTrackingTypeIds
      );
    },
    [item.itemTrackingTypeList, newTrackingTypeIds]
  );

  const handleLocationClicked = useCallback(
    (id) => {
      if (id === -1) {
        setActiveInventoryRow(initialInventoryRow);
        return;
      }

      const inventoryRow = inventoryRows.find((i) => i.id === id)!;

      const trackingGroups = getTrackingGroupsByInventoryRow(inventoryRow);

      if (hasOnlySerial) {
        setSerialList(trackingGroups[0].serialList);
      }

      setActiveInventoryRow({
        ...inventoryRow,
        trackingGroupList: trackingGroups,
      });
    },
    [inventoryRows, hasOnlySerial, getTrackingGroupsByInventoryRow]
  );

  const handleSubmit = useCallback(async () => {
    await onSubmit(inventoryRows);
  }, [inventoryRows, onSubmit]);

  const handleApplyClicked = useCallback(async () => {
    if (showSummaryPage) {
      await handleSubmit();
      return;
    }

    const inventoryRow = hasOnlySerial
      ? {
          ...activeInventoryRow,
          trackingGroupList: [
            {
              ...activeInventoryRow.trackingGroupList[0],
              quantity: activeInventoryRow.totalQty,
              serialList,
            },
          ],
        }
      : activeInventoryRow;

    const index = inventoryRows.findIndex((i) => i.id === inventoryRow.id);
    setInventoryRows(
      replaceValueInCollection(inventoryRows, inventoryRow, index)!
    );

    if (index === inventoryRows.length - 1) {
      setActiveInventoryRow(initialInventoryRow);
      return;
    }

    const nextInventoryRow = inventoryRows[index + 1];
    const trackingGroups = getTrackingGroupsByInventoryRow(nextInventoryRow);

    setActiveInventoryRow({
      ...nextInventoryRow,
      trackingGroupList: trackingGroups,
    });

    if (hasOnlySerial) {
      setSerialList(trackingGroups[0].serialList);
    } else {
      setSerialList([]);
    }
  }, [
    activeInventoryRow,
    inventoryRows,
    hasOnlySerial,
    serialList,
    showSummaryPage,
    handleSubmit,
    getTrackingGroupsByInventoryRow,
  ]);

  return (
    <Modal
      open={open}
      title="Tracking"
      onCancelClicked={onClose}
      withoutDefaultPadding
      maxWidth="lg"
      footerDivider="shadow"
      nestedScrollAreas
      onApplyClicked={handleApplyClicked}
      applyDisabled={isTrackingInvalid}
      applyLabel={showSummaryPage ? 'Finish' : 'Next'}
    >
      <Grid
        container
        disableEqualOverflow
        sx={{
          paddingLeft: themeRestyle.spacing(3),
          paddingRight: themeRestyle.spacing(3),
        }}
      >
        <Grid xs={4}>
          <ItemsTable
            columns={TrackingWizardColumns}
            data={[...inventoryRows, initialInventoryRow]} // set fake row as summary page
            RenderCustomRow={TrackingWizardRow}
            containerHeight={TrackingWizardTableHeight}
            selectableItems={false}
            meta={{ uom: item.defaultUom }}
            onItemClick={handleLocationClicked}
            activeId={_.get(activeInventoryRow, 'id', null)}
            sx={{
              borderRadius: '5px',
              border: `1px solid ${colorPalette.redesign.background3}`,
              borderTop: 'none',
            }}
          />
        </Grid>
        <Grid xs={8} paddingLeft={themeRestyle.spacing(3)}>
          {showSummaryPage ? (
            <TrackingWizardSummary />
          ) : (
            <TrackingWizardForm inventoryRow={activeInventoryRow} />
          )}
          {!showSummaryPage && (
            <Grid xs={12}>
              {hasOnlySerial ? (
                <SerialTable
                  title="Tracking"
                  serialList={serialList}
                  setSerialList={setSerialList}
                  itemTrackingTypes={item.itemTrackingTypeList}
                  allowSerialNumberImport
                  canEditRow
                  setItem={setItem}
                  item={item}
                  sx={{
                    borderRadius: '5px',
                    border: `1px solid ${colorPalette.redesign.background3}`,
                    borderTop: 'none',
                    borderBottom: 'none',
                    maxHeight: '40vh',
                  }}
                  headerMargin="8px 0px"
                />
              ) : (
                <TrackingTable
                  itemTrackingTypes={item.itemTrackingTypeList}
                  allowSerialNumberImport
                  trackingGroups={
                    activeInventoryRow
                      ? activeInventoryRow.trackingGroupList
                      : []
                  }
                  tableType={
                    _.isEmpty(existingItemTrackingTypeIds)
                      ? TrackingTableTypes.Add
                      : TrackingTableTypes.Edit
                  }
                  firstColumnTitle="Quantity To Add"
                  onSetTrackingGroups={handleSetTrackingGroups}
                  onAddNewTracking={handleAddNewTracking}
                  withoutAmountToAssign={
                    !_.isEmpty(existingItemTrackingTypeIds)
                  }
                  isDecimal={!hasSerial}
                  sx={{
                    borderRadius: '5px',
                    border: `1px solid ${colorPalette.redesign.background3}`,
                    borderTop: 'none',
                    borderBottom: 'none',
                    maxHeight: '40vh',
                  }}
                />
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default memo(FBOTrackingWizard);
