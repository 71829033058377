import React, { memo, useState, useCallback } from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import {
  ItemsTable,
  useSelectedItemsChanges,
} from 'ui/components/Table/ItemsTable';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { defaultSaleItem, SaleItem } from 'services/items/saleItems';
import {
  deleteEntityFromArray,
  findNextNegativeId,
  replaceValueInCollection,
} from 'helpers';
import { PermissionType } from 'services/permissions';
import { showNotification } from 'services/api';
import { getSettingsSalesOrder } from 'services/settings/salesOrders';
import { getSettingsCompany } from 'services/settings/company';

import { SaleItemTableRowActions, SALE_ITEMS_COLUMNS } from './consts';
import { SaleItemsTabProps } from './types';
import { SaleItemModal, SaleItemRow } from './components';
import { editItemPermissions } from '../../helpers';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const SaleItemsTab: React.FC<SaleItemsTabProps> = (props) => {
  const { item, setItem } = props;

  const soSettings = useSelector(getSettingsSalesOrder);
  const companySettings = useSelector(getSettingsCompany);

  const [activeSaleItem, setActiveSaleItem] = useState(defaultSaleItem);
  const [showModal, setShowModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const editPermissions = editItemPermissions(item);

  const handleAddNewClicked = useCallback(() => {
    const imageLinkList = item.imageLinkList.map((i, index) => ({
      ...i,
      id: -1 - index,
    }));

    setActiveSaleItem({
      ...defaultSaleItem,
      id: findNextNegativeId(item.saleItemList),
      name: item.name,
      description: item.description,
      sku: item.sku,
      upc: item.upc,
      dimensionUnit: item.dimensionUnit,
      weightUnit: item.weightUnit,
      height: item.height,
      weight: item.weight,
      width: item.width,
      length: item.length,
      defaultUomId: item.defaultUomId,
      imageLinkList,
      tags: item.tags,
      defaultFlag: _.isEmpty(item.saleItemList),
      salesTaxId:
        companySettings.country !== 'US' ? soSettings.salesTaxId : null,
    });
    setShowModal(true);
  }, [
    item.saleItemList,
    item.description,
    item.imageLinkList,
    item.tags,
    companySettings.country,
    soSettings.salesTaxId,
  ]);
  const handleModalCancelClicked = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleSaleItemClicked = useCallback(
    (id: number) => {
      const saleItem = item.saleItemList.find((i) => i.id === id)!;
      setActiveSaleItem(saleItem);
      setShowModal(true);
    },
    [item.saleItemList]
  );

  const handleModalApplyClicked = useCallback(() => {
    const saleItem = {
      ...activeSaleItem,
      width: activeSaleItem.width || 0,
      height: activeSaleItem.height || 0,
      length: activeSaleItem.length || 0,
      dimensionUnit: activeSaleItem.dimensionUnit || 'Inches',
      weight: activeSaleItem.weight || 0,
      weightUnit: activeSaleItem.weightUnit || 'Pounds',
    };
    const index = item.saleItemList.findIndex((i) => i.id === saleItem.id);

    let newSaleItems = [...item.saleItemList];

    // if sale item defaultFlag is true, set all others to false
    if (saleItem.defaultFlag) {
      newSaleItems = newSaleItems.map((i) => ({ ...i, defaultFlag: false }));
    } else {
      const itemHasDefaultSaletem = item.saleItemList.some(
        (si) => si.defaultFlag && si.id !== saleItem.id
      );
      if (!itemHasDefaultSaletem) {
        showNotification('There needs to be a default sale item', {
          variant: 'warning',
        });
        return;
      }
    }

    if (index === -1) {
      newSaleItems.push({
        ...saleItem,
        defaultFlag: _.isEmpty(newSaleItems) ? true : saleItem.defaultFlag,
      });
    } else {
      newSaleItems = replaceValueInCollection(newSaleItems, saleItem, index)!;
    }

    setItem((prevItem) => ({ ...prevItem, saleItemList: newSaleItems }));
    setShowModal(false);
  }, [item.saleItemList, activeSaleItem, setItem]);

  const handleSelectedChange = useSelectedItemsChanges(
    selectedItems,
    setSelectedItems
  );
  const handleDeleteModalVisible = (visible: boolean) => () =>
    setDeleteModalVisible(visible);

  const handleDeleteClicked = useCallback(() => {
    const newSaleItems = item.saleItemList
      .map((i) => ({ ...i, deleted: selectedItems.includes(i.id) }))
      .filter((i) => !(i.id < 0 && i.deleted));

    setItem((prevItem) => ({
      ...prevItem,
      saleItemList: newSaleItems,
    }));
    setSelectedItems([]);
    setDeleteModalVisible(false);
  }, [item.saleItemList, selectedItems, setItem]);

  const handleAction = useCallback(
    (action: any) => {
      const type: SaleItemTableRowActions = action.type;
      switch (type) {
        case SaleItemTableRowActions.Default: {
          // set all other sale item default flags to false
          const newSaleItems = item.saleItemList.map((i, index) => ({
            ...i,
            defaultFlag: index === action.itemIndex,
          }));

          setItem((prevItem) => ({
            ...prevItem,
            saleItemList: newSaleItems,
          }));
          break;
        }
        case SaleItemTableRowActions.Delete: {
          setItem((prevItem) => ({
            ...prevItem,
            saleItemList: deleteEntityFromArray(
              prevItem.saleItemList,
              action.itemIndex
            ),
          }));
          break;
        }
      }
    },
    [item.saleItemList, setItem]
  );

  const filterSaleItems = useCallback(
    (s: SaleItem) => {
      return editPermissions.includes(PermissionType.Undelete)
        ? s.deleted
        : !s.deleted;
    },
    [editPermissions]
  );

  return (
    // TODO : RESTYLING : Remove Box wrapper after FF cleanup
    <Box display="flex" flexDirection="column" height="100%" width={'100%'}>
      <FBOTitleBar title="Sale Items">
        {!_.isEmpty(selectedItems) && (
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="secondary"
            color="negative"
            size="medium"
            icon="TrashCan"
            data-qa="sale-item-delete"
            onClick={handleDeleteModalVisible(true)}
            permissions={editPermissions}
          >
            Delete
          </FBOButton>
        )}
        <FBOButton
          variant="secondary"
          color="positive"
          size="medium"
          icon="FBOAddCircle"
          data-qa="sale-item-add"
          onClick={handleAddNewClicked}
          permissions={editPermissions}
        >
          Add New
        </FBOButton>
      </FBOTitleBar>

      <ItemsTable
        data={item.saleItemList}
        columns={SALE_ITEMS_COLUMNS}
        onItemClick={handleSaleItemClicked}
        RenderCustomRow={SaleItemRow}
        selectedItems={selectedItems}
        onSelectedChange={handleSelectedChange}
        filterRows={filterSaleItems}
        onAction={handleAction}
        dataQa="sale-item-table"
        tableLayoutFixed
        emptyTableText="ADD NEW ENTRY BY PRESSING 'ADD NEW'"
      />
      <SaleItemModal
        show={showModal}
        saleItem={activeSaleItem}
        item={item}
        setItem={setItem}
        setSaleItem={setActiveSaleItem}
        onCancelClicked={handleModalCancelClicked}
        onApplyClicked={handleModalApplyClicked}
        country={companySettings.country}
      />
      <ConfirmationModal
        open={deleteModalVisible}
        title="Delete Checked Item"
        body={`This will delete selected items, are you sure?`}
        onCancelClicked={handleDeleteModalVisible(false)}
        onConfirmClicked={handleDeleteClicked}
        confirmLabel="Delete"
        confirmButtonRed
      />
    </Box>
  );
};

export default memo(SaleItemsTab);
