import { ShipCarton, ShippingBillingType } from '.';

export enum CustomerCarriersAccountType {
  UPS = 'UPS',
  FedEx = 'FedEx',
}

export enum CustomerCarriersBillingType {
  Sender = 'SENDER',
  Recipient = 'RECIPIENT',
  Third_Party = 'THIRD PARTY',
}

export enum ShippingCarrierType {
  USPS = 'USPS',
  UPS = 'UPS',
  FedEx = 'FedEx',
}

export const ShippoBillingTypeOptions: ShippingBillingType[] = [
  { name: 'Sender', code: 'SENDER' },
  { name: 'Recipient/Third Party', code: 'RECIPIENT' },
];

export const initialCarton: ShipCarton[] = [
  {
    id: -1,
    cost: null,
    insuredValue: null,
    declaredValue: null,
    dimensionUnit: null,
    height: null,
    length: null,
    number: 'Add new Package',
    trackingNumber: null,
    trackingUrl: null,
    shippingLabelUrl: null,
    version: null,
    weight: null,
    weightUnit: null,
    width: null,
    packageCode: null,
    returnLabelUrl: null,
    returnTracking: null,
    returnTrackingUrl: null,
    returnLabelCost: null,
  },
];
