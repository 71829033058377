import { DEFAULT_STATE } from './consts';
import { ModuleNavigationActions } from './actions';
import { ModuleNavigationState } from './types';

const moduleNavigationReducer = (
  state = DEFAULT_STATE,
  action: any
): ModuleNavigationState => {
  switch (action.type) {
    case ModuleNavigationActions.UPDATE_MODULE_NAVIGATION:
    case ModuleNavigationActions.CLEAR_MODULE_NAVIGATION:
    case ModuleNavigationActions.REMOVE_MODULE_NAVIGATION:
      return action.payload;
    default:
      return state;
  }
};

export default moduleNavigationReducer;
