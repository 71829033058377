import React, { memo, useCallback, useMemo } from 'react';
import { Tooltip } from '@mui/material';

import { PermissionType } from 'services/permissions';
import { colorPalette } from 'ui/theme';
import {
  SalesOrderPaymentStatus,
  SalesOrderStatus,
  SalesOrder,
  SalesOrderItemTypes,
} from 'services/salesOrders';

import { SalesOrderTitleBarProps } from './types';
import { useSalesOrderDetailsCardStyle } from '../SalesOrderDetailsCard/styled';
import {
  shouldShowQuickFulfill,
  shouldShowSave,
  shouldShowUnissue,
} from '../SalesOrderDetailsCard/helpers';
import { createActionBarOptions } from '../SalesOrderDetailsCard/consts';

import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';

export const disableQuickFulfillHandler = (
  activeSalesOrder: SalesOrder
): boolean => {
  return activeSalesOrder.salesItems.some((si) => {
    const itemTrackingTypeList = si.saleItem?.item?.itemTrackingTypeList;
    const isItemDropShipOrCreditReturnType =
      si.salesOrderItemType === SalesOrderItemTypes.DropShip ||
      si.salesOrderItemType === SalesOrderItemTypes.CreditReturn;
    if (
      !isItemDropShipOrCreditReturnType &&
      itemTrackingTypeList &&
      itemTrackingTypeList.length > 0
    ) {
      return true;
    }
  });
};

const SalesOrderTitleBar: React.FC<SalesOrderTitleBarProps> = (props) => {
  const {
    activeSalesOrder,
    onSave,
    resolvedTitle,
    soSettings,
    onClose,
    onIssueClicked,
    onUnIssueClicked,
    onPaymentsClicked,
    duplicateClicked,
    duplicateAsCreditReturnClicked,
    onDeleteClicked,
    showReportModal,
    showEmailModal,
    showCreatePOModal,
    onShippingQuoteClicked,
    onUndeleteClicked,
    onQuickFulfillClicked,
    onCancelClicked,
    connection,
    resolvedPaidStatus,
  } = props;

  const classes = useSalesOrderDetailsCardStyle(props);
  const disableQuickFulfill = useMemo(() => {
    return disableQuickFulfillHandler(activeSalesOrder);
  }, [activeSalesOrder]);

  const DeletedSalesOrderActions = useCallback(() => {
    return (
      <>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          onClick={() => onUndeleteClicked(false)}
          permissions={
            activeSalesOrder.id && activeSalesOrder.id > 0
              ? [PermissionType.SalesOrderEdit]
              : [PermissionType.SalesOrderCreate]
          }
          data-qa="sale-order-save"
        >
          Undelete
        </FBOButton>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          onClick={() => onUndeleteClicked(true)}
          permissions={
            activeSalesOrder.id && activeSalesOrder.id > 0
              ? [PermissionType.SalesOrderEdit]
              : [PermissionType.SalesOrderCreate]
          }
          data-qa="sale-order-save-and-close"
        >
          Undelete And Close
        </FBOButton>
      </>
    );
  }, [activeSalesOrder.id, onUndeleteClicked]);

  const SalesOrderActions = useCallback(() => {
    return (
      <>
        {shouldShowQuickFulfill(activeSalesOrder) && (
          <Tooltip
            title={
              disableQuickFulfill
                ? 'Quick Fulfill not available for tracked items'
                : ''
            }
            placement="bottom"
          >
            <span>
              <FBOButton
                sx={{ marginRight: '8px' }}
                variant="secondary"
                color="positive"
                size="medium"
                onClick={onQuickFulfillClicked}
                data-qa="sales-order-quick-fulfill-button"
                disabled={disableQuickFulfill}
              >
                Quick Fulfill
              </FBOButton>
            </span>
          </Tooltip>
        )}
        {activeSalesOrder.status === SalesOrderStatus.Estimate && (
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="secondary"
            color="positive"
            size="medium"
            onClick={onIssueClicked}
            permissions={[PermissionType.SalesOrderIssue]}
            data-qa="sale-order-issue"
          >
            Issue
          </FBOButton>
        )}
        {shouldShowUnissue(activeSalesOrder.status) && (
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="secondary"
            color="positive"
            size="medium"
            onClick={onUnIssueClicked}
            permissions={[PermissionType.SalesOrderIssue]}
            data-qa="sales-order-unissue"
          >
            Unissue
          </FBOButton>
        )}
        {activeSalesOrder.id && (
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="secondary"
            color="positive"
            size="medium"
            onClick={onPaymentsClicked}
            permissions={[PermissionType.SalesOrderPayment]}
            data-qa="sale-order-payment"
          >
            Payments
          </FBOButton>
        )}
        {shouldShowSave(activeSalesOrder.status) && (
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="secondary"
            color="positive"
            size="medium"
            permissions={
              activeSalesOrder.id && activeSalesOrder.id > 0
                ? [PermissionType.SalesOrderEdit]
                : [PermissionType.SalesOrderCreate]
            }
            onClick={() => onSave(false)}
            data-qa="sale-order-save"
          >
            Save
          </FBOButton>
        )}
        {shouldShowSave(activeSalesOrder.status) && (
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="secondary"
            color="positive"
            size="medium"
            permissions={
              activeSalesOrder.id && activeSalesOrder.id > 0
                ? [PermissionType.SalesOrderEdit]
                : [PermissionType.SalesOrderCreate]
            }
            onClick={() => onSave(true)}
            data-qa="sale-order-save-and-close"
          >
            Save And Close
          </FBOButton>
        )}
        <FBOMenuButton
          style={{
            marginRight: '8px',
            color: colorPalette.redesign.contentSecondary,
          }}
          variant="tertiary"
          items={createActionBarOptions(
            activeSalesOrder.id,
            activeSalesOrder.status,
            onDeleteClicked,
            duplicateClicked,
            duplicateAsCreditReturnClicked,
            () => showReportModal(true),
            () => showEmailModal(true),
            onShippingQuoteClicked,
            onCancelClicked,
            () => showCreatePOModal(true),
            !connection
          )}
          data-qa="sale-order-three-dot-menu"
        />
      </>
    );
  }, [
    activeSalesOrder,
    connection,
    duplicateAsCreditReturnClicked,
    duplicateClicked,
    onDeleteClicked,
    onIssueClicked,
    onPaymentsClicked,
    onUnIssueClicked,
    onShippingQuoteClicked,
    showEmailModal,
    showReportModal,
    showCreatePOModal,
    onQuickFulfillClicked,
    onCancelClicked,
    onSave,
  ]);

  return (
    <FBOTitleBar
      title={`SO ${resolvedTitle}`}
      status={activeSalesOrder ? activeSalesOrder.status : undefined}
      statusCustom={soSettings.showPaidStamp ? resolvedPaidStatus : undefined}
      statusCustomStyle={
        resolvedPaidStatus === SalesOrderPaymentStatus.Paid
          ? classes.customStatusPaid
          : resolvedPaidStatus === SalesOrderPaymentStatus.PartiallyPaid
          ? classes.customStatusPartiallyPaid
          : classes.customStatusUnpaid
      }
    >
      {activeSalesOrder.deleted ? (
        <DeletedSalesOrderActions />
      ) : (
        <SalesOrderActions />
      )}
      <FBOButton
        variant="tertiary"
        color="neutral"
        size="medium"
        icon="FBOClose"
        data-qa="sale-order-close"
        onClick={onClose}
      />
    </FBOTitleBar>
  );
};

export default memo(SalesOrderTitleBar);
