import {
  transformSearch,
  AdvancedSearchItem,
  SearchableState,
} from 'services/search';
import { isValid, ApiState } from 'services/api';
import { UrlQueryObject } from 'services/url/types';

export const INITIAL_ADVANCED_SEARCH: AdvancedSearchItem = {
  isDefault: false,
  columns: {},
  searchName: '',
  id: -1,
};

export async function getDefaultSearch(
  urlQuery: UrlQueryObject,
  searches: ApiState<AdvancedSearchItem>,
  fetchSearchAction: () => Promise<any>
): Promise<AdvancedSearchItem | undefined> {
  let defaultSearch: AdvancedSearchItem | undefined;

  if (isValid(searches)) {
    defaultSearch = searches.items.find((s) => s.isDefault);
  } else {
    const searchPayload = await fetchSearchAction();

    if (searchPayload.ok) {
      const newSearches = searchPayload.res.payload.map(transformSearch);
      defaultSearch = newSearches.find((s: AdvancedSearchItem) => s.isDefault);
    }
  }

  const queryDefaultSearch: AdvancedSearchItem = {
    ...INITIAL_ADVANCED_SEARCH,
    columns: urlQuery.advancedSearch || {},
  };

  // If there is advanced search present in url use it as default
  return urlQuery.advancedSearch ? queryDefaultSearch : defaultSearch;
}

/**
 * Defines new endpoints and checks if the given URL contains any of the new endpoints.
 * Add new endpoints above if needed.
 *
 * @param {string} url - The URL to check.
 * @return {boolean} Returns true if the URL contains any of the new endpoints, otherwise false.
 */
export const urlContainsNewSearchEndpoint = (url: string) => {
  const newEndpointUrls = [
    'warehouse-mgmt/v1/search-picks',
    'sales-mgmt/v1/orders',
    'user-mgmt/v1/users',
  ];
  let containsNewEndpoint = false;
  newEndpointUrls.forEach((newEndpoint) => {
    if (url.includes(newEndpoint)) {
      containsNewEndpoint = true;
    }
  });
  return containsNewEndpoint;
};

/**
 * Transforms the search state to match the new endpoint format.
 * Pagination is zero based and the status advanced search column is transformed to UPPER_SNAKE_CASE
 *
 * @param {SearchableState<any>} searchState - The current search state.
 * @return {SearchableState<any>} The transformed search state.
 */
export const transformSearchForNewEndpoint = (
  searchState: SearchableState<any>
) => {
  return {
    ...searchState,
    pagination: {
      ...searchState.pagination,
      page: (searchState.pagination?.page || 1) - 1,
    },
    advancedSearch: {
      ...searchState.advancedSearch,
      columns: {
        ...searchState.advancedSearch.columns,
        status: searchState.advancedSearch.columns.status
          ?.toString()
          .replaceAll(' ', '_')
          .toUpperCase(),
        orderStatus: searchState.advancedSearch.columns.salesOrderStatus
          ?.toString()
          .replaceAll(' ', '_')
          .toUpperCase(),
        salesOrderStatus: undefined,
      },
    },
  };
};

/**
 * Returns the search state based on the provided parameters.
 *
 * @param {string} url - The URL.
 * @param {SearchableState<any>} newSearchState - The new search state.
 * @return {SearchableState<any>} The search state.
 */
export const getSearchState = (
  url: string,
  newSearchState: SearchableState<any>
) => {
  if (urlContainsNewSearchEndpoint(url)) {
    return transformSearchForNewEndpoint(newSearchState);
  }
  return newSearchState;
};
