import React, { useCallback, memo, useContext } from 'react';
import { Box, Grid } from '@mui/material';

import { PaymentContext } from 'ui/components/Modal/PaymentModal/PaymentProvider';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';
import { TextField } from 'ui/components/TextField/TextField';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import {
  CardType,
  PaymentFormValuesCard,
} from 'ui/components/Modal/PaymentModal/types';
import { useHandleTextFieldChange } from 'services/forms';

import { PaymentFormProps } from './types';
import { ccExpiresFormat } from '../../helpers';
import { CARD_TYPES } from '../../consts';

const PaymentForm: React.FC<PaymentFormProps> = (props) => {
  const { validationErrors } = props;

  const { paymentAmount, setPaymentAmount, formValuesCard, setFormValuesCard } =
    useContext(PaymentContext);

  const handlePaymentAmountChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value ? parseFloat(e.target.value) : null;
      setPaymentAmount(value);
    },
    [setPaymentAmount]
  );

  const handleTextFieldChange = useHandleTextFieldChange<PaymentFormValuesCard>(
    setFormValuesCard,
    formValuesCard
  );

  const handleCardTypeChanged = useCallback(
    (e: React.ChangeEvent<{}>, value: CardType) => {
      setFormValuesCard({
        ...formValuesCard,
        cardType: value ? value.code : null,
      });
    },
    [formValuesCard, setFormValuesCard]
  );

  const handleExpDate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = ccExpiresFormat(e.target.value);
      setFormValuesCard({ ...formValuesCard, expDate: value });
    },
    [formValuesCard, setFormValuesCard]
  );

  return (
    <Box p={3}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CurrencyField
            label="Payment Amount"
            placeholder="Enter amount"
            name="paymentAmount"
            fullWidth
            value={paymentAmount}
            required
            onChange={handlePaymentAmountChanged}
            error={!!validationErrors.paymentAmount}
            dataQa="soPayments-cardTab-paymentAmount"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="Name On Card"
            placeholder="Enter name"
            name="nameOnCard"
            fullWidth
            value={formValuesCard.nameOnCard}
            required
            onChange={handleTextFieldChange}
            error={!!validationErrors.nameOnCard}
            autoComplete="disabled"
            dataQa="sale-order-payment-name-on-card"
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            label="Card Type"
            name="cardType"
            required
            placeholder="Select card type"
            options={CARD_TYPES}
            getOptionLabel={(option: CardType) => option.label}
            onChange={handleCardTypeChanged}
            error={!!validationErrors.cardType}
            dataQa="sale-order-payment-card-type"
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="Exp. Date"
            placeholder="xx/xx"
            name="expDate"
            fullWidth
            value={formValuesCard.expDate}
            required
            onChange={handleExpDate}
            error={!!validationErrors.expDate}
            disableDebounce
            dataQa="sale-order-payment-exp-date"
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="Last Four Digits"
            placeholder="Enter digits"
            name="lastFourDigits"
            fullWidth
            value={formValuesCard.lastFourDigits}
            required
            onChange={handleTextFieldChange}
            error={!!validationErrors.lastFourDigits}
            inputProps={{
              maxLength: 4,
              'data-qa': 'sale-order-payment-last-four-digits' as any,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(PaymentForm);
