import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { colorPalette } from 'ui/theme';

export const useMultiSelectStyles = makeStyles((theme: Theme) => ({
  chip: {
    background: colorPalette.applicationDarkGray,
    borderRadius: 20,
    color: colorPalette.white,
    fontSize: 15,
    fontWeight: 600,
    marginRight: theme.spacing(0.5),
    marginTop: 2,
    marginBottom: 2,
  },
  selectBox: {
    '&:focus': {
      background: 'transparent',
    },
    '& > div': {
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
}));
