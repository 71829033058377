import React from 'react';

import { AddressValidation } from 'services/addresses';
import { SalesOrder } from 'services/salesOrders';

export const setVerifiedShipToAddress = (
  addressValidation: AddressValidation,
  setSalesOrder: React.Dispatch<React.SetStateAction<SalesOrder>>
) => {
  setSalesOrder((old) => ({
    ...old,
    shipToPhone: addressValidation.validated.phone, // @beta: additionally added for testing
    shipToEmail: addressValidation.validated.email, // @beta: additionally added for testing
    shipToCity: addressValidation.validated.city,
    shipToStreet: addressValidation.validated.street,
    shipToStreet2: addressValidation.validated.street2,
    shipToState: addressValidation.validated.state,
    shipToPostalCode: addressValidation.validated.postalCode,
    shipToName: addressValidation.validated.name,
    shipToCountry: addressValidation.validated.country,
    shipToCompanyName: addressValidation.validated.companyName,
    shipToResidential: addressValidation.validated.residential,
    shipToAttention: addressValidation.validated.attention,
    shipToVerified: true,
  }));
};

export const setVerifiedBillToAddress = (
  addressValidation: AddressValidation,
  setSalesOrder: React.Dispatch<React.SetStateAction<SalesOrder>>
) => {
  setSalesOrder((old) => ({
    ...old,
    billToPhone: addressValidation.validated.phone, // @beta: additionally added for testing
    billToEmail: addressValidation.validated.email, // @beta: additionally added for testing
    billToCity: addressValidation.validated.city,
    billToStreet: addressValidation.validated.street,
    billToStreet2: addressValidation.validated.street2,
    billToState: addressValidation.validated.state,
    billToPostalCode: addressValidation.validated.postalCode,
    billToName: addressValidation.validated.name,
    billToCountry: addressValidation.validated.country,
    billToCompanyName: addressValidation.validated.companyName,
    billToResidential: addressValidation.validated.residential,
    billToAttention: addressValidation.validated.attention,
    billToVerified: true,
  }));
};

export const setShipToAsBillToAddress = (
  setSalesOrder: React.Dispatch<React.SetStateAction<SalesOrder>>
) => {
  setSalesOrder((old) => ({
    ...old,
    shipToPhone: old.billToPhone, // @beta: additionally added for testing
    shipToEmail: old.billToEmail, // @beta: additionally added for testing
    shipToName: old.billToName,
    shipToStreet: old.billToStreet,
    shipToStreet2: old.billToStreet2,
    shipToCity: old.billToCity,
    shipToState: old.billToState,
    shipToCountry: old.billToCountry,
    shipToPostalCode: old.billToPostalCode,
    shipToCompanyName: old.billToCompanyName,
    shipToResidential: old.billToResidential,
    shipToAttention: old.billToAttention,
    shipToVerified: old.billToVerified,
  }));
};
