import { useCallback, useMemo } from 'react';
import { NavigateFunction, Location } from 'react-router-dom';
import qs from 'qs';

import { UrlQueryObject } from './types';

export const useUrlQueryObject = <T extends UrlQueryObject>(
  navigate: NavigateFunction,
  location: Location<any>
): [T, (updatedQueries: T) => void] => {
  return [
    useMemo(() => {
      const parsedValues = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      return {
        ...parsedValues,
        activeId: parseInt(parsedValues.activeId as string, 10),
      } as T;
    }, [location.search]),

    useCallback(
      (updatedQueries: T) => {
        const currentQueryObject = qs.parse(location.search, {
          ignoreQueryPrefix: true,
        });

        const newQueryObject = {
          ...currentQueryObject,
          ...updatedQueries,
        };

        const queryString = qs.stringify(newQueryObject, {
          skipNulls: true,
        });

        navigate(
          {
            ...location,
            search: queryString,
          },
          { replace: true }
        );
      },
      [navigate, location]
    ),
  ];
};
