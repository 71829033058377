import _ from 'lodash';

import { ItemType } from 'services/items';
import { DefaultSaleItemType, SaleItem } from 'services/items/saleItems';
import { roundToDecimals } from 'helpers';
import {
  SalesOrderBundleItem,
  SalesOrderItem,
  SalesOrderItemTypes,
} from 'services/salesOrders';
import { VendorItem } from 'services/vendors';
import { MenuItem } from 'ui/components/Menu';

export const calculateHomeCurrencyPrice = (
  multicurrencyPrice: number,
  exchangeRate: number
) => {
  return roundToDecimals(multicurrencyPrice / exchangeRate, 6);
};

export const calculateMulticurrencyPrice = (
  price: number,
  exchangeRate: number
) => {
  return roundToDecimals(price * exchangeRate, 5);
};

export const saleItemHasDefaultVendor = (selectedSaleItem: SaleItem | null) => {
  const vendorItems: VendorItem[] =
    _.get(selectedSaleItem, 'item.vendorItems', []) || [];

  return vendorItems.some((vi: VendorItem) => vi.defaultFlag);
};

export const resolveSalesOrderItemRowClasses = (
  row: SalesOrderItem | SalesOrderBundleItem
) => {
  if (row.salesOrderItemType === SalesOrderItemTypes.BundleCreditReturn) {
    return true;
  }

  return (
    row.salesOrderItemType === SalesOrderItemTypes.CreditReturn ||
    row.salesOrderItemType === SalesOrderItemTypes.MiscReturn
  );
};

export const resolveSaleItemDefaultType = (
  selectedSaleItem: SaleItem | null
) => {
  if (
    selectedSaleItem &&
    selectedSaleItem.defaultSalesOrderItemType === DefaultSaleItemType.DropShip
  ) {
    return SalesOrderItemTypes.DropShip;
  }

  if (
    selectedSaleItem &&
    selectedSaleItem.defaultSalesOrderItemType ===
      DefaultSaleItemType.CreditReturn
  )
    return SalesOrderItemTypes.CreditReturn;

  return SalesOrderItemTypes.Sale;
};

const createOptionsForItemSelected = (
  type: string,
  itemType: ItemType | null,
  itemClicked: (type: SalesOrderItemTypes) => void
): MenuItem[] => [
  {
    label: 'Sale',
    onOptionClick: () => itemClicked(SalesOrderItemTypes.Sale),
    dataQa: 'sale-order-sale',
  },
  {
    label: 'Credit Return',
    disabled:
      type === SalesOrderItemTypes.Bundle ||
      type === SalesOrderItemTypes.BundleCreditReturn,
    onOptionClick: () => itemClicked(SalesOrderItemTypes.CreditReturn),
    dataQa: 'sale-order-credit-return',
  },
  {
    label: 'Drop Ship',
    disabled: !(
      itemType === ItemType.Inventory || itemType === ItemType.NonInventory
    ),
    onOptionClick: () => itemClicked(SalesOrderItemTypes.DropShip),
  },
];

const createOptionsForItemNotSelected = (
  itemClicked: (type: SalesOrderItemTypes) => void
): MenuItem[] => [
  {
    label: 'Miscellaneous Sale',
    onOptionClick: () => itemClicked(SalesOrderItemTypes.MiscSale),
    dataQa: 'sale-order-miscellaneous',
  },
  {
    label: 'Miscellaneous Credit Return',
    onOptionClick: () => itemClicked(SalesOrderItemTypes.MiscReturn),
    dataQa: 'sale-order-item-miscellaneous-credit-return',
  },
  {
    label: 'Note',
    onOptionClick: () => itemClicked(SalesOrderItemTypes.Note),
    dataQa: 'sale-order-item-note',
  },
  {
    label: 'Flat Rate Tax',
    onOptionClick: () => itemClicked(SalesOrderItemTypes.FlatTaxRate),
    dataQa: 'sale-order-item-flat-rate-tax',
  },
];

export const createOptionsForSalesOrderItems = (
  selectedSaleItem: SaleItem | null,
  itemClicked: (type: SalesOrderItemTypes) => void
) => {
  if (!selectedSaleItem) {
    return createOptionsForItemNotSelected(itemClicked);
  }

  return createOptionsForItemSelected(
    selectedSaleItem.defaultSalesOrderItemType,
    _.get(selectedSaleItem, 'item.itemType', null),
    itemClicked
  );
};
