import React from 'react';
import { FormControlLabel, Switch } from '@mui/material';

interface FBOSwitchProps {
  name: string;
  size: 'small' | 'medium';
  label?: string;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  value?: string | number;
  checked: boolean;
  disabled?: boolean;
  dataQa: string;
  inputProps?: any;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  sx?: React.CSSProperties;
}

const FBOSwitch = ({
  label,
  labelPlacement,
  onChange,
  dataQa,
  inputProps,
  sx,
  ...otherProps
}: FBOSwitchProps) => {
  const switchComponent = () => (
    <Switch
      className="redesign"
      onChange={onChange}
      inputProps={
        {
          'data-qa': dataQa,
          ...inputProps,
        } as any
      }
      {...otherProps}
      disableRipple
    />
  );

  return (
    <>
      {label ? (
        <FormControlLabel
          control={switchComponent()}
          label={label}
          labelPlacement={labelPlacement}
          className="redesign FBOSwitch-label" // redesign class is for colors, can be removed when FF is removed
          sx={sx}
        />
      ) : (
        switchComponent()
      )}
    </>
  );
};

export default FBOSwitch;
