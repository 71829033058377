import React, { memo, useEffect, useCallback, useState, useRef } from 'react';
import { Box } from '@mui/material';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';

import { postTag, putTag, deleteTag, Tag, fetchTagsAPI } from 'services/tags';
import { initialTag } from 'services/tags/consts';
import { findNextNegativeId, replaceValueInCollection } from 'helpers';
import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import { DataWithPagination } from 'services/api';
import { Pagination } from 'services/search';

import { initialTagsDataWithPagination } from './consts';
import { TagsModalProps } from './types';
import { useTagsModalStyles } from './styled';
import { logErrorCtx } from 'app/logging';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { IconNames } from 'ui/theme';

const TagsModal: React.FC<TagsModalProps> = (props) => {
  const { open, onClose, onTagDelete, onTagNameChange } = props;

  const classes = useTagsModalStyles();

  const [activeTag, setActiveTag] = useState<Tag | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [tags, setTags] = useState<DataWithPagination<Tag>>(
    initialTagsDataWithPagination
  );

  const newTagInputElement = useRef<HTMLInputElement>(null);

  // jsx helpers
  const showNewTag = activeTag && activeTag.id && activeTag.id < 0;
  const showActiveTagActions = (id: number) =>
    activeTag !== null && activeTag.id === id;
  const showEmptyBox = (id: number) =>
    activeTag !== null && activeTag.id !== id;

  useEffect(() => {
    if (!open) {
      return;
    }

    (async () => {
      setIsLoading(true);
      try {
        const tagsData = await fetchTagsAPI({
          pagination: initialTagsDataWithPagination.pagination,
        });
        setTags(tagsData);
      } catch (e) {
        const error = e as Error;
        logErrorCtx('Error in TagsModal', {
          error,
          stackTrace: error.stack,
          title: 'Error while adding new tag ',
          description: 'fetchTagsAPI Not Fetched from Server',
          component: 'TagsAutocomplete -> TagsModal',
        });
      }
      setIsLoading(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleEditClicked = (tag: Tag) => setActiveTag(tag);

  const handleDeleteClicked = (id: number) => async () => {
    setIsLoading(true);
    try {
      await deleteTag(id);
    } catch {
      setIsLoading(false);
      return;
    }

    const index = tags.data.findIndex((t) => t.id === id);

    setTags({
      ...tags,
      data: [...tags.data.slice(0, index), ...tags.data.slice(index + 1)],
    });
    onTagDelete(id);
    setIsLoading(false);
  };

  const handleAddNewClicked = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    setActiveTag({ ...initialTag, id: findNextNegativeId(tags.data) });

    // Without timeout it never focuses input
    setTimeout(() => {
      if (newTagInputElement.current !== null) {
        newTagInputElement.current.focus();
      }
    }, 150);
  };

  const handleSaveClicked = async () => {
    if (!activeTag) {
      return;
    }

    setIsLoading(true);

    // PUT
    if (activeTag.id && activeTag.id > 0) {
      try {
        const newTag = await putTag(activeTag);
        const index = tags.data.findIndex((t) => t.id === activeTag.id);

        setTags({
          ...tags,
          data: replaceValueInCollection(tags.data, newTag, index)!,
        });
      } catch {
        setIsLoading(false);
        return;
      }

      onTagNameChange(activeTag);
      setActiveTag(null);
      setIsLoading(false);
      return;
    }

    // POST
    try {
      const newTag = await postTag(activeTag);

      setTags({ ...tags, data: [...tags.data, newTag] });
    } catch {
      setIsLoading(false);
      return;
    }

    setActiveTag(null);
    setIsLoading(false);
  };

  const handleCancelClicked = () => {
    setActiveTag(null);
  };

  const handleTagChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (activeTag) {
      setActiveTag({ ...activeTag, name: event.target.value || null });
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.stopPropagation();
    // enter key
    if (event.keyCode === 13) {
      handleSaveClicked();
    }
  };

  const handleOnCloseModal = () => {
    onClose();
    setTags(initialTagsDataWithPagination);
    setActiveTag(null);
  };

  const handleNextPage = useCallback(async () => {
    const { pagination } = tags;

    if (pagination.totalRows <= pagination.pageSize * pagination.page) {
      return;
    }

    const newPagination: Pagination = {
      ...pagination,
      page: pagination.page + 1,
    };

    setIsLoading(true);
    try {
      const res = await fetchTagsAPI({ pagination: newPagination });

      setTags((old) => ({
        pagination: res.pagination,
        data: [...old.data, ...res.data],
      }));
    } catch (e) {
      const error = e as Error;
      logErrorCtx('Error in TagsModal', {
        error,
        stackTrace: error.stack,
        title: 'Error while rendering next tags page ',
        description: 'fetchTagsAPI Not Fetched from Server',
        component: 'TagsAutocomplete -> TagsModal',
      });
    }
    setIsLoading(false);
  }, [tags]);

  const handleScroll = (event: any) => {
    if (activeTag && (!activeTag.id || activeTag.id < 0)) {
      return;
    }

    const node = event.currentTarget;
    // check if at the end of scroll
    if (Math.ceil(node.scrollTop + node.clientHeight) === node.scrollHeight) {
      handleNextPage();
    }
  };

  return (
    <Modal
      open={open}
      onCancelClicked={handleOnCloseModal}
      onApplyClicked={handleOnCloseModal}
      onResetClicked={!activeTag ? handleAddNewClicked : undefined}
      resetLabel="Add new tag"
      applyDisabled={!!activeTag}
      applyLabel="Done"
      title="Manage Tags"
      customHeight={600}
      isLoadingContent={isLoading}
      withoutDefaultPadding
      maxWidth="sm"
      onKeyPress={undefined}
      footerDivider="shadow"
      dataQa="tagsModal-addNewTag"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        overflow="auto"
        p={3}
        onScroll={handleScroll}
        pb={5}
      >
        {tags.data.map((tag, index) => (
          <Box key={index} className={classes.tag}>
            <FBOButton
              variant="tertiary"
              color="neutral"
              size="large"
              icon={IconNames.IconSales}
              disabled
              data-qa="tags-modal-sales-button"
            />
            <TextField
              value={
                activeTag !== null && activeTag.id === tag.id
                  ? activeTag.name
                  : tag.name
              }
              fullWidth
              className={classes.tagInput}
              readOnly={activeTag ? activeTag.id !== tag.id : true}
              onChange={handleTagChange}
              onClick={() => handleEditClicked(tag)}
              onKeyDown={handleKeyDown}
            />
            {showActiveTagActions(tag.id!) && (
              <Box display="flex" minWidth={100}>
                <FBOButton
                  variant="tertiary"
                  color="neutral"
                  size="large"
                  icon={IconNames.IconSave}
                  onClick={handleSaveClicked}
                  data-qa="tags-modal-save-button"
                />
                <FBOButton
                  variant="tertiary"
                  color="neutral"
                  size="large"
                  icon={IconNames.IconClose}
                  onClick={handleCancelClicked}
                  data-qa="tags-modal-clear-button"
                />
              </Box>
            )}
            {!activeTag && (
              <Box
                display="flex"
                minWidth={100}
                className={`${classes.tagEditing} tagEditToolbar`}
              >
                <FBOButton
                  variant="tertiary"
                  color="neutral"
                  size="large"
                  icon={IconNames.IconSave}
                  onClick={() => handleEditClicked(tag)}
                  data-qa="tags-modal-save-button"
                />
                <FBOButton
                  variant="tertiary"
                  color="negative"
                  size="large"
                  icon={IconNames.TrashCan}
                  onClick={handleDeleteClicked(tag.id!)}
                  data-qa="tags-modal-trash-can-button"
                />
              </Box>
            )}
            {showEmptyBox(tag.id!) && <Box display="flex" minWidth={100} />}
          </Box>
        ))}
        {showNewTag && (
          <Box className={classes.tag}>
            <LocalOfferOutlinedIcon className={classes.tagIcon} />
            <TextField
              value={activeTag!.name}
              fullWidth
              onChange={handleTagChange}
              className={classes.tagInput}
              onKeyDown={handleKeyDown}
              inputRef={newTagInputElement}
            />
            <>
              <FBOButton
                variant="tertiary"
                color="neutral"
                size="large"
                icon={IconNames.Save}
                onClick={handleSaveClicked}
                data-qa="tags-modal-save-button"
              />
              <FBOButton
                variant="tertiary"
                color="neutral"
                size="large"
                icon={IconNames.IconClose}
                onClick={handleCancelClicked}
                data-qa="tags-modal-close-button"
              />
            </>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default memo(TagsModal);
