import React, { memo } from 'react';
import { Box, Typography } from '@mui/material';
import { ImageItem } from '../ImageItem';
import { IconNames, colorPalette } from 'ui/theme';

import { useImageItemBoxStyles } from './styled';
import { ImageItemBoxProps } from './types';

const ImageItemBox: React.FC<ImageItemBoxProps> = (props) => {
  const { source, sourceSize, name, description, onClick, dataQa, isRedesign } =
    props;

  const classes = useImageItemBoxStyles(props);

  return (
    <Box display="flex" alignItems="center" width="100%">
      <>
        {!source ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              border: '1px solid',
              padding: '8px',
              borderColor: colorPalette.redesign.background3,
              borderRadius: '2px',
            }}
          >
            <ImageItem
              iconSource={IconNames.ImagePhotoPicture}
              sourceSize={sourceSize}
              onClick={onClick}
            />
          </Box>
        ) : (
          <ImageItem
            source={source}
            sourceSize={sourceSize}
            onClick={onClick}
          />
        )}
      </>

      <Box className={classes.content}>
        <Typography
          variant="body1"
          color="textPrimary"
          className={classes.heading}
          data-qa={`image-item-box-name-${dataQa}`}
        >
          {name || 'Unknown item'}
        </Typography>

        {description && (
          <Typography
            variant="subtitle1"
            color={
              isRedesign
                ? colorPalette.redesign.contentSecondary
                : 'textSecondary'
            }
            className={classes.subHeading}
            data-qa={`image-item-box-description-${dataQa}`}
          >
            {description}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default memo(ImageItemBox);
