import { defaultMaximumPagination, RequestConfig } from 'helpers';
import { DataWithPagination } from 'services/api';

import { transformInvoiceDetails, transformInvoices } from './transformations';
import { Invoice, InvoiceDetails } from './types';
import {
  createApiCall,
  paginatedApiCallZeroBased,
} from 'services/api/createApiCall';

export const getInvoicesAPI = async (
  config: RequestConfig,
  startDate: string | null,
  endDate: string | null,
  quickSearchValue: string | null
): Promise<DataWithPagination<Invoice>> => {
  const { pagination = defaultMaximumPagination } = config;
  let path = `/v1/invoices`;

  if (quickSearchValue) {
    path += `?quickSearchValue=${quickSearchValue}`;
  }

  if (startDate && endDate) {
    path +=
      (quickSearchValue ? `&` : `?`) +
      `dateShippedFrom=${startDate}&dateShippedTo=${endDate}`;
  }

  const res = await paginatedApiCallZeroBased(
    path,
    pagination,
    transformInvoices
  );

  return res;
};

export const getInvoiceDetails = async (
  id: number
): Promise<InvoiceDetails> => {
  const response = await createApiCall(
    {
      path: `/v1/invoices/${id}`,
      method: 'GET',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformInvoiceDetails(response.data);
};
