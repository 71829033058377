import { transformDateToMomentDate, roundToDecimals } from 'helpers';

import { PricingRule } from './types';

export const transformPricingRule = (res: any): PricingRule => {
  return {
    id: res.id,
    checkVersion: res.checkVersion,
    customerId: res.customerId,
    customerTags: res.customerTags,
    dateCreated: transformDateToMomentDate(res.dateCreated),
    dateLastModified: transformDateToMomentDate(res.dateLastModified),
    deleted: res.deleted,
    description: res.description,
    endDate: transformDateToMomentDate(res.endDate),
    maxQuantity: res.maxQuantity,
    minQuantity: res.minQuantity,
    name: res.name,
    percent:
      res.percent !== null
        ? roundToDecimals(res.percent * 100, 2).toString()
        : null,
    price: res.price,
    relativeTo: res.relativeTo,
    saleItemId: res.saleItemId,
    saleItemTags: res.saleItemTags,
    startDate: transformDateToMomentDate(res.startDate),
    type: res.type,
    version: res.version,
    itemId: res.itemId,
  };
};

export const transformToRequestPricingRule = (
  pricingRule: PricingRule
): any => {
  const body = {
    deleted: pricingRule.deleted,
    description: pricingRule.description,
    endDate: pricingRule.endDate,
    maxQuantity: pricingRule.maxQuantity,
    minQuantity: pricingRule.minQuantity,
    name: pricingRule.name,
    percent:
      pricingRule.percent !== null
        ? roundToDecimals(Number(pricingRule.percent) / 100, 4)
        : null,
    price: pricingRule.price,
    relativeTo: pricingRule.relativeTo,
    startDate: pricingRule.startDate,
    type: pricingRule.type,
    customerTags: pricingRule.customerTags.map((t) => ({ name: t.name })),
    customerId: pricingRule.customerId,
    saleItemId: pricingRule.saleItemId,
    saleItemTags: pricingRule.saleItemTags.map((t) => ({ name: t.name })),
    itemId: pricingRule.itemId,
  };

  // POST
  if (pricingRule.id && pricingRule.id < 0) {
    return body;
  }

  // PUT
  return {
    ...body,
    id: pricingRule.id,
    version: pricingRule.version,
  };
};
