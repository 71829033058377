export interface MyChannelsPageCmp
  extends React.FunctionComponent<MyChannelsPageProps> {
  route: string;
}

export interface MyChannelsPageProps {}

export enum ChannelVendors {
  Walmart = 'Walmart',
  Amazon = 'Amazon',
  BigCommerce = 'BigCommerce',
  WooCommerce = 'WooCommerce',
  Ebay = 'Ebay',
  Shopify = 'Shopify',
}
