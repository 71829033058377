import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useDropZoneStyle = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  documentsPaper: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: (documents: File[]) =>
      documents.length ? 'flex-start' : 'center',
    height: 416,
    padding: theme.spacing(3),
    overflowY: 'scroll',
  },
  card: {
    border: 'none',
    width: '100%',
    boxShadow:
      '0px 4px 4px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.16)',
  },
  cardRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 16px',
    borderBottom: '1px solid #EBF0F5',
    transition: 'color .2s ease-in-out',
    '&:only-of-type': {
      borderBottom: 'none',
    },
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
