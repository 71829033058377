import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useDismissibleModalStyle = makeStyles((theme: Theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(2),
  },
  body: {
    padding: 0,
    margin: theme.spacing(0),
    whiteSpace: 'pre-line',
  },
  dismissButton: {
    color: theme.palette.text.primary,
    width: '100%',
  },
  action: {
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.text.primary,
  },
}));
