import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const usePickingModalStyle = makeStyles((theme: Theme) => ({
  gridWithPadding: {
    padding: theme.spacing(3),
    flexShrink: 0,
  },
  modalContent: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    borderColor: theme.palette.divider,
    borderLeftStyle: 'solid',
    maxHeight: `calc(90vh - 116px)`,
  },
  modalSidebar: {
    maxHeight: `calc(90vh - 116px)`,
  },
  inventoryTableGrid: {
    flexGrow: 1,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  actionButtonMargin: {
    marginRight: theme.spacing(2),
  },
  cancelButton: {
    color: theme.palette.text.primary,
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  titleText: {
    fontWeight: 'bold',
  },
  title: {
    padding: 0,
    margin: theme.spacing(2),
  },
  content: {
    padding: 0,
    margin: 0,
  },
  action: {
    padding: theme.spacing(2),
  },
}));
