import React, { memo, useCallback } from 'react';
import { TableCell, Box } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import _ from 'lodash';

import { SerialRow as SerialRowType } from 'services/inventory';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { TextField } from 'ui/components/TextField/TextField';

import { useSerialRowStyle } from './styled';
import { StatusLabel } from 'ui/components/Status/StatusLabel';
import { PickStatus } from 'services/picking';

const CycleCountSerialRow: React.FC<RenderCustomRowProps<SerialRowType>> = (
  props
) => {
  const { columns, row, errors, index, meta, setData, tableBordered } = props;

  const rowClasses = useSerialRowStyle();

  const handleSerialNumberChange = useCallback(
    (trackingTypeId: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value || null;
      const serialNumbers = {
        ...row.serialNumbers,
        [trackingTypeId]: value,
      };
      const newData = { ...row, serialNumbers };

      setData(newData, index);
    },
    [index, row, setData]
  );

  const handleKeyPress = (index: number) => (e: any) => {
    if (e.charCode === 13) {
      const nextInput = document.querySelector(
        `[tabIndex="${index + 1}"] input`
      );

      if (nextInput) {
        (nextInput as HTMLElement).focus();
      }
    }
  };

  const isRowEditable = _.get(meta, 'canEditRow');

  const rowStatus = row?.committed ? PickStatus.Committed : PickStatus.Open;

  const trackingTypeColumns = columns.filter((column: any) => {
    return column?.meta?.trackingTypeId >= 0;
  });

  return (
    <>
      {trackingTypeColumns.map((column, i) => {
        const trackingTypeId: number = _.get(column, 'meta.trackingTypeId');

        const serialNumber = row.serialNumbers[trackingTypeId];

        // possible multiple columns
        const tabIndex = index * columns.length + i;
        const error = !!errors[`${trackingTypeId}`];

        return (
          <TableCell padding={tableBordered ? 'none' : 'normal'} key={i}>
            <Box className={rowClasses.cellContainer}>
              <Box className={rowClasses.textFieldContainer}>
                {meta?.duplicateIds?.includes(row?.id) &&
                  meta?.showDuplicates && (
                    <ErrorOutlineIcon
                      sx={{
                        width: '18px',
                        height: '18px',
                        marginLeft: '10px',
                        marginRight: '10px',
                        color: 'red',
                      }}
                    />
                  )}
                <TextField
                  autoComplete="nope"
                  placeholder="Add Serial Number"
                  onChange={handleSerialNumberChange(trackingTypeId)}
                  value={serialNumber}
                  readOnly={!isRowEditable || row.committed}
                  dataQa={`serial-tracking-row-${index}`}
                  tabIndex={tabIndex}
                  error={error}
                  onKeyPress={handleKeyPress(tabIndex)}
                />
              </Box>
              <Box></Box>
            </Box>
          </TableCell>
        );
      })}
      <TableCell className={rowClasses.status}>
        <Box>
          <StatusLabel
            status={rowStatus}
            data-qa={`serial-tracking-row${index}-status`}
          />
        </Box>
      </TableCell>
    </>
  );
};

export default memo(CycleCountSerialRow);
