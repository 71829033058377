import React, { useState, useCallback, memo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Box } from '@mui/material';

import {
  ItemsTable,
  useSelectedItemsChanges,
} from 'ui/components/Table/ItemsTable';
import {
  Address,
  Contact,
  ContactType,
  initialAddress,
} from 'services/addresses';
import { getSettingsCompany } from 'services/settings/company/redux';
import {
  findNextNegativeId,
  replaceValueInCollection,
  removeValueFromCollection,
} from 'helpers';
import { activeUserHasPermission } from 'services/user/redux';
import { Errors, validateYup } from 'services/forms/validation';
import { AddressModal } from 'ui/components/Modal/AddressModal';
import {
  yupAddressContactSchema,
  yupAddressSchema,
} from 'services/addresses/validations';

import { AddressesTabProps } from './types';
import { ADDRESSES_COLUMNS, AddressTabAction, defaultContact } from './consts';
import AddressesRow from './AddressesRow';
import { editCustomerPermissions } from '../../../helpers';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const AddressesTab: React.FC<AddressesTabProps> = (props) => {
  const { activeCustomer, setActiveCustomer } = props;
  const { addresses } = activeCustomer;
  const companySettings = useSelector(getSettingsCompany);

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [activeAddress, setActiveAddress] = useState<Address>(initialAddress);
  const [validationErrors, setValidationErrors] = useState<Errors>({});
  const [contactErrors, setContactErrors] = useState<Errors[]>([{}]);
  const [selectedAddresses, setSelectedAddresses] = useState<number[]>([]);

  const editPermission = editCustomerPermissions(activeCustomer);

  const showAddressModal = useSelector(activeUserHasPermission(editPermission));

  const contacts: Contact[] = [];

  const handleSelectedChange = useSelectedItemsChanges(
    selectedAddresses,
    setSelectedAddresses
  );

  const openModalForEditing = useCallback(
    (id: number) => {
      setActiveAddress(addresses.find((a) => a.id === id)!);
      if (showAddressModal) {
        setModalVisible(true);
      }
    },
    [addresses, showAddressModal]
  );

  const openModalForAddingNew = useCallback(() => {
    //default first email
    if (activeCustomer.email) {
      contacts.push({
        ...defaultContact,
        type: ContactType.email,
        value: activeCustomer.email,
        name: 'Email',
      });
    } else if (activeCustomer.cc) {
      contacts.push({
        ...defaultContact,
        type: ContactType.email,
        value: activeCustomer.cc,
        name: 'Email',
      });
    } else if (activeCustomer.bcc) {
      contacts.push({
        ...defaultContact,
        type: ContactType.email,
        value: activeCustomer.bcc,
        name: 'Email',
      });
    }
    if (activeCustomer.office) {
      contacts.push({
        ...defaultContact,
        type: ContactType.office,
        value: activeCustomer.office,
        name: 'Office',
      });
    } else if (activeCustomer.mobile) {
      contacts.push({
        ...defaultContact,
        type: ContactType.mobile,
        value: activeCustomer.mobile,
        name: 'Mobile',
      });
    } else if (activeCustomer.fax) {
      contacts.push({
        ...defaultContact,
        type: ContactType.fax,
        value: activeCustomer.fax,
        name: 'Fax',
      });
    }

    setActiveAddress({
      ...initialAddress,
      id: findNextNegativeId(activeCustomer.addresses),
      country: companySettings.country,
      contacts: contacts,
    });
    setModalVisible(true);
  }, [activeCustomer.addresses, companySettings.country]);

  const setAddressItems = useCallback(
    (addressItems: React.SetStateAction<Address[]>) => {
      if (typeof addressItems === 'function') {
        setActiveCustomer((c) => ({
          ...c,
          addresses: addressItems(c.addresses),
        }));
        return;
      }

      setActiveCustomer((c) => ({
        ...c,
        addresses: addressItems,
      }));
    },
    [setActiveCustomer]
  );

  const onModalClose = useCallback(() => {
    setModalVisible(false);
    setContactErrors([]);
    setValidationErrors({});
    setActiveAddress(initialAddress);
  }, []);

  const handleSave = useCallback(
    (address: Address) => {
      const isValid = validateYup(
        address,
        yupAddressSchema,
        setValidationErrors
      );

      const newErrors = [...contactErrors];
      const contactValidArray = address.contacts.map((contact, index) => {
        if (contact.deleted) {
          return true;
        }
        return validateYup(
          contact,
          yupAddressContactSchema,
          (error: Errors) => {
            newErrors[index] = error;
          }
        );
      });
      setContactErrors(newErrors);

      const areContactInValid = contactValidArray.some((valid) => !valid);

      if (!isValid || areContactInValid) {
        return;
      }

      const indexNum = activeCustomer.addresses.findIndex(
        (a) => a.id === address.id
      );

      if (indexNum === -1) {
        setAddressItems((oldState) => [...oldState, address]);
      } else {
        setAddressItems(
          (oldState) => replaceValueInCollection(oldState, address, indexNum)!
        );
      }

      setModalVisible(false);
      setContactErrors([]);
    },
    [activeCustomer, setAddressItems, contactErrors]
  );

  const handleAction = useCallback(
    (action: any) => {
      switch (action.type) {
        case AddressTabAction.Delete: {
          const address = addresses[action.value];
          if (address.id === null || address.id < 0) {
            setAddressItems((old) =>
              removeValueFromCollection(old, action.value)
            );
          } else {
            setAddressItems(
              (old) =>
                replaceValueInCollection(
                  old,
                  { ...old[action.value], deleted: true },
                  action.value
                ) || []
            );
          }
          break;
        }
      }
    },
    [addresses, setAddressItems]
  );

  const deleteClicked = useCallback(() => {
    const newAddressItems: Address[] = activeCustomer.addresses.map(
      (address) => {
        if (selectedAddresses.includes(address.id!)) {
          return { ...address, deleted: true };
        }
        return address;
      }
    );
    const newRemoved: Address[] = newAddressItems.filter((address) => {
      return !((address.id || 0) < 0 && address.deleted);
    });

    setAddressItems(newRemoved);
    setSelectedAddresses([]);
  }, [setAddressItems, selectedAddresses, activeCustomer.addresses]);

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%">
      <FBOTitleBar title="Addresses">
        {!_.isEmpty(selectedAddresses) && (
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="secondary"
            color="negative"
            size="medium"
            icon="TrashCan"
            data-qa="address-delete"
            onClick={deleteClicked}
            permissions={editPermission}
          >
            Delete
          </FBOButton>
        )}
        <FBOButton
          variant="secondary"
          color="positive"
          size="medium"
          icon="FBOAddCircle"
          onClick={openModalForAddingNew}
          data-qa="address-add"
          permissions={editPermission}
        >
          Add New
        </FBOButton>
      </FBOTitleBar>

      <ItemsTable
        data={addresses}
        setData={setAddressItems}
        columns={ADDRESSES_COLUMNS}
        onItemClick={openModalForEditing}
        selectedItems={selectedAddresses}
        onSelectedChange={handleSelectedChange}
        filterRows={(address) => !address.deleted}
        dataQa="address-table"
        RenderCustomRow={AddressesRow}
        onAction={handleAction}
        emptyTableText="ADD NEW ENTRY BY PRESSING 'ADD'"
      />
      <AddressModal
        onClose={onModalClose}
        modalVisible={modalVisible}
        activeAddress={activeAddress}
        setActiveAddress={setActiveAddress}
        onSaveChanges={handleSave}
        validationErrors={validationErrors}
        contactErrors={contactErrors}
      />
    </Box>
  );
};

export default memo(AddressesTab);
