import { PermissionGroup } from './types';

export const initialPermissionGroup: PermissionGroup = {
  id: null,
  dateCreated: null,
  dateLastModified: null,
  name: null,
  permissionIds: [],
  permissions: [],
  readOnly: false,
  users: [],
  version: null,
};

export enum permissionCategory {
  GENERAL = 'General',
  MATERIALS = 'Materials',
  SALES = 'Sales',
  PURCHASING = 'Purchasing',
  SETTINGS = 'Settings',
  PREFERENCES = 'Preferences',
  REPORTS = 'Reports',
}

export enum permissionArea {
  REPORTS = 'Reports',
}

export const preferencesPermissionsOrder: string[] = [
  'settings-sales-order',
  'settings-purchase-order',
  'settings-picking',
  'settings-tracking',
  'settings-shipping',
  'settings-account-mapping',
  'settings-company',
  'settings-multicurrency',
  'settings-emailing',
];

export const settingsPermissionsOrder: string[] = [
  'uom',
  'locations',
  'users',
  'permission-groups',
  'payment-terms',
  'payment-types',
  'setup-currency',
  'representatives',
  'tax-rates',
  'carriers',
];
