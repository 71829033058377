import { PermissionType } from 'services/permissions';
import { Uom } from 'services/uoms';

export const editUomPermissions = (uom: Uom): PermissionType[] => {
  if (uom.deleted) {
    return [PermissionType.Undelete];
  }
  return uom.id && uom.id > 0
    ? [PermissionType.UomEdit]
    : [PermissionType.UomCreate];
};
