import { makeStyles } from '@mui/styles';

import { colorPalette } from 'ui/theme';

export const useInventoryTableHeaderStyle = makeStyles(() => ({
  iconButton: {
    minWidth: 'unset',
    paddingLeft: 10,
    paddingRight: 10,
  },
  cell: {
    pointerEvents: 'none',
  },
}));

export const useInventoryTableRowStyle = makeStyles(() => ({
  subRow: {
    '& td': {
      paddingTop: '0px',
      paddingBottom: '0px',
    },
    '& .MuiIconButton-root': {
      minWidth: 'unset',
      paddingLeft: 10,
      paddingRight: 10,
    },
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colorPalette.fishbowlLightGray,
    },
  },
  statusLabel: {
    lineHeight: 1,
    borderRadius: 20,
  },
  noTrackingRow: {
    cursor: 'default',
  },
  warningRowValue: {
    color: colorPalette.statusRed,
  },
}));
