import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const usePaymentTermsStyle = makeStyles((theme: Theme) => ({
  scrollable: { backgroundColor: theme.palette.primary.light },
  text: { textTransform: 'none' },
  dayInput: {
    maxWidth: 75,
  },
}));
