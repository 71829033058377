import React, { memo, useMemo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import _ from 'lodash';

import { EACH_UOM_ID, getUoms } from 'services/uoms';
import { calculatePriceBasedOnUomChange } from 'services/salesOrders';
import { useHandleTextFieldChange } from 'services/forms';
import { PurchaseOrderStatus } from 'services/purchaseOrders';
import { Errors, validateYup } from 'services/forms/validation';
import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import { TextFieldQuantity } from 'ui/components/TextField/TextFieldQuantity';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';
import { ItemType, ItemUomConversion } from 'services/items';
import {
  roundToDecimals,
  toMulticurrencyCalculate,
  toHomeCurrencyCalculate,
} from 'helpers';
import { getSettingsCompany } from 'services/settings/company';
import { MultiCurrencyWrapper } from 'ui/components/MultiCurrencyWrapper/MultiCurrencyWrapper';

import { PurchaseOrderItemEditModalProps } from './types';
import { yupPurchaseOrderItemEditModalSchema } from './validations';
import { convertPurchaseOrderItemToNegativePrice } from '../../helpers';

const PurchaseOrderItemEditModal: React.FC<PurchaseOrderItemEditModalProps> = (
  props
) => {
  const {
    show,
    onSave,
    onClose,
    activePurchaseOrderItem,
    setActivePurchaseOrderItem,
    purchaseOrderStatus,
    exchangeRate,
    activeMulticurrencyCode,
  } = props;

  const { items: uoms } = useSelector(getUoms);

  const [validationErrors, setValidationErrors] = useState<Errors>({});

  const activeItemType = _.get(activePurchaseOrderItem.item, 'itemType', null);

  const { homeCurrency, useMultiCurrency } = useSelector(getSettingsCompany);
  const homeCurrencyCode = (homeCurrency && homeCurrency.code) || 'USD';

  const hasMultiCurrency = useMemo(
    () =>
      useMultiCurrency &&
      activeMulticurrencyCode &&
      activeMulticurrencyCode !== homeCurrencyCode,
    [useMultiCurrency, activeMulticurrencyCode]
  );

  const handleTextFieldChange = useHandleTextFieldChange(
    setActivePurchaseOrderItem,
    activePurchaseOrderItem
  );

  const isFulfilled = useMemo(
    () => purchaseOrderStatus === PurchaseOrderStatus.Fulfilled,
    [purchaseOrderStatus]
  );

  const handleAmountInputChange = useCallback(
    (value: number | null) => {
      setActivePurchaseOrderItem({
        ...activePurchaseOrderItem,
        quantity: value,
      });
    },
    [activePurchaseOrderItem, setActivePurchaseOrderItem]
  );

  const handleCostChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value ? parseFloat(e.target.value) : 0;
      const resolvedValue = convertPurchaseOrderItemToNegativePrice(
        value,
        activePurchaseOrderItem.purchaseOrderItemType
      );
      const multiCurrencyUnitCost = toMulticurrencyCalculate(
        resolvedValue,
        exchangeRate || 1
      );

      setActivePurchaseOrderItem((old) => ({
        ...old,
        unitCost: resolvedValue,
        multiCurrencyUnitCost,
      }));
    },
    [
      setActivePurchaseOrderItem,
      activePurchaseOrderItem.purchaseOrderItemType,
      exchangeRate,
    ]
  );

  const handleMulticurrencyFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value ? parseFloat(e.target.value) : 0;
    const resolvedValue = convertPurchaseOrderItemToNegativePrice(
      value,
      activePurchaseOrderItem.purchaseOrderItemType
    );
    const unitCost = toHomeCurrencyCalculate(resolvedValue, exchangeRate || 1);
    setActivePurchaseOrderItem((old) => ({
      ...old,
      unitCost,
      multiCurrencyUnitCost: resolvedValue,
    }));
  };

  const handleApplyClicked = useCallback(() => {
    const isValid = validateYup(
      activePurchaseOrderItem,
      yupPurchaseOrderItemEditModalSchema,
      setValidationErrors
    );

    if (isValid) {
      onSave();
    }
  }, [activePurchaseOrderItem, onSave]);

  const handleAmountMenuChange = useCallback(
    (uomId: number) => {
      const newUom = uoms.find((u) => u.id === uomId)!;
      const oldUom = uoms.find((u) => u.id === activePurchaseOrderItem.uomId)!;

      const itemUomConversions: ItemUomConversion[] = _.get(
        activePurchaseOrderItem,
        'item.itemUomConversionList',
        null
      );

      const newPrice = calculatePriceBasedOnUomChange(
        newUom,
        oldUom,
        activePurchaseOrderItem.unitCost || 0,
        itemUomConversions
      );

      setActivePurchaseOrderItem({
        ...activePurchaseOrderItem,
        uomId,
        unitCost: newPrice,
      });
    },
    [uoms, activePurchaseOrderItem, setActivePurchaseOrderItem]
  );

  return (
    <Modal
      open={show}
      onCancelClicked={onClose}
      onApplyClicked={handleApplyClicked}
      applyDisabled={isFulfilled}
      applyLabel="Update"
      title={isFulfilled ? 'Purchase Order Item' : 'Edit Purchase Order Item'}
      withoutDefaultPadding
    >
      <Box p={3} width="100%">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Name"
              name="name"
              disabled
              value={activePurchaseOrderItem.name}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="SKU"
              name="sku"
              value={_.get(activePurchaseOrderItem, 'item.sku', '')}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldQuantity
              label="Quantity"
              name="quantity"
              value={activePurchaseOrderItem.quantity}
              selectedUomId={activePurchaseOrderItem.uomId || EACH_UOM_ID}
              uoms={uoms}
              onTextChange={handleAmountInputChange}
              onMenuChange={handleAmountMenuChange}
              disabled={isFulfilled}
              isUomSelectDisabled={activeItemType === ItemType.Shipping}
              error={!!validationErrors.quantity}
              dataQa="poItemEdit-quantityField"
            />
          </Grid>
          <Grid item xs={hasMultiCurrency ? 3 : 6}>
            <CurrencyField
              label="Cost"
              name="cost"
              placeholder="Enter cost"
              value={activePurchaseOrderItem.unitCost || 0}
              onChange={handleCostChange}
              disabled={isFulfilled}
              error={!!validationErrors.unitCost}
              allowNegative
              decimalPlaces={5}
              dataQa="poItemEdit-costField"
            />
          </Grid>
          <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
            <Grid item xs={3}>
              <CurrencyField
                label={`Cost ${activeMulticurrencyCode}`}
                name="cost"
                placeholder="Enter cost"
                debounceDelay={700}
                value={roundToDecimals(
                  activePurchaseOrderItem.multiCurrencyUnitCost || 0,
                  5
                )}
                onChange={handleMulticurrencyFieldChange}
                disabled={isFulfilled}
                error={!!validationErrors.unitCost}
                currencyCode={activeMulticurrencyCode}
                allowNegative
                decimalPlaces={5}
                dataQa="poItemEdit-multicurrencyCostField"
              />
            </Grid>
          </MultiCurrencyWrapper>
          <Grid item xs={12}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Description"
              name="description"
              placeholder="Enter Item Description"
              onChange={handleTextFieldChange}
              disabled={isFulfilled}
              value={activePurchaseOrderItem.description}
              dataQa="poItemEdit-descriptionField"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Notes"
              name="notes"
              placeholder="Enter notes"
              onChange={handleTextFieldChange}
              disabled={isFulfilled}
              value={activePurchaseOrderItem.notes}
              dataQa="poItemEdit-notesField"
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default memo(PurchaseOrderItemEditModal);
