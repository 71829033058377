import { DataWithPagination } from 'services/api';
import { initialPagination } from 'ui/components/Table/ItemsTable';

import { LineChartData } from './components/DashboardLineChart/types';

export const dashboardInitialDataWithPagination: DataWithPagination = {
  data: [],
  pagination: { ...initialPagination, pageSize: 10 },
};

export const lineChartDataDemo = [
  {
    name: 'Page A',
    pv: 2400,
    uv: 4000,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export const lineChartDataDemo2 = [
  {
    name: 'Page A',
    pv: 400,
    uv: 400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 300,
    pv: 198,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 800,
    pv: 300,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 270,
    pv: 308,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 180,
    pv: 100,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 630,
    pv: 500,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 990,
    pv: 1100,
    amt: 2100,
  },
];

export const DATE_FORMAT = 'YYYY-MM-DD';

export const lineChartDataGeneral: LineChartData = {
  legends: [
    { title: 'Income', color: 'green' },
    { title: 'Expenses', color: 'red' },
  ],
  lines: [
    {
      name: 'Previous Income',
      color: 'rgba(255, 101, 0, 0.4)',
      key: 'previousValue',
      dotStroke: 1,
    },
    {
      name: 'Latest Income',
      color: 'rgba(56, 187, 124, 1)',
      key: 'latestValue',
      dotStroke: 1,
    },
    {
      name: 'Previous Expense',
      color: 'rgba(10, 118, 31, 0.58)',
      key: 'previousValueSecondary',
      dotStroke: 1,
    },
    {
      name: 'Latest Expense',
      color: 'rgba(239, 69, 16, 1)',
      key: 'latestValueSecondary',
      dotStroke: 1,
    },
  ],
};

export const lineChartDataSales: LineChartData = {
  legends: [{ title: 'Income', color: 'green' }],
  lines: [
    {
      name: 'Previous Income',
      color: 'rgba(255, 101, 0, 0.4)',
      key: 'previousValue',
      dotStroke: 1,
    },
    {
      name: 'Latest Income',
      color: 'rgba(56, 187, 124, 1)',
      key: 'latestValue',
      dotStroke: 1,
    },
    {
      name: '',
      color: '',
      key: 'fake1',
      dotStroke: 1,
    },
    {
      name: '',
      color: '',
      key: 'fake2',
      dotStroke: 1,
    },
  ],
};

export const lineChartDataPurchasing: LineChartData = {
  legends: [{ title: 'Expenses', color: 'red' }],
  lines: [
    {
      name: 'Previous Expense',
      color: 'rgba(255, 101, 0, 0.4)',
      key: 'previousValue',
      dotStroke: 1,
    },
    {
      name: 'Latest Expense',
      color: 'rgba(239, 69, 16, 1)',
      key: 'latestValue',
      dotStroke: 1,
    },
    {
      name: '',
      color: '',
      key: 'fake1',
      dotStroke: 1,
    },
    {
      name: '',
      color: '',
      key: 'fake2',
      dotStroke: 1,
    },
  ],
};
