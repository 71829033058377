import { transformDateToMomentDate } from 'helpers';

import { TaxRate, TaxType } from './types';

export const transformTaxRate = (response: any): TaxRate => {
  return {
    id: response.id,
    agency: response.agency,
    agencyId: response.agencyId,
    amount: response.amount,
    code: response.code,
    deleted: response.deleted,
    dateLastModified: transformDateToMomentDate(response.dateLastModified),
    dateCreated: transformDateToMomentDate(response.dateCreated),
    name: response.name,
    percentage: response.percentage,
    taxType: response.taxType,
    version: response.version,
    channel: response.channel ?? null,
    mappedTaxName: response.mappedTaxName ?? null,
    channelTaxRate: response.channelTaxRate ?? null,
    accountingId: response.accountingId ?? null,
    channelTaxType: response.channelTaxType ?? null,
  };
};

export const transformTaxRateRequest = (taxRate: TaxRate): any => {
  const body = {
    agencyId: taxRate.agencyId,
    amount: taxRate.taxType === TaxType.flatRate ? taxRate.amount : null,
    code: taxRate.code,
    name: taxRate.name,
    percentage:
      taxRate.taxType === TaxType.percentage ? taxRate.percentage : null,
    taxType: taxRate.taxType,
    channel: taxRate.channel ?? null,
    mappedTaxName: taxRate.mappedTaxName ?? null,
    channelTaxRate: taxRate.channelTaxRate ?? null,
    accountingId: taxRate.accountingId ?? null,
    channelTaxType: taxRate.channelTaxType ?? null,
  };

  // POST
  if (taxRate.id && taxRate.id < 0) {
    return body;
  }

  // PUT
  return {
    ...body,
    id: taxRate.id,
    version: taxRate.version,
    deleted: taxRate.deleted,
  };
};
