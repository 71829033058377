import * as yup from 'yup';

export const yupShipCartonsSchema = yup.object().shape({
  weight: yup.number().required().moreThan(0),
  height: yup.number().required().moreThan(0),
  width: yup.number().required().moreThan(0),
  length: yup.number().required().moreThan(0),
});

export const yupShippingGeneralSchema = yup.object().shape({
  orderPriorityId: yup.number().required(),
  carrierId: yup.number().required(),
});

export const yupShippingSplitModalSchema = yup.object().shape({
  amount: yup
    .number()
    .when('defaultAmount', (defaultAmount: number) => {
      return yup
        .number()
        .moreThan(0, 'Please enter amount > 0')
        .max(defaultAmount, 'Quantity cannot exceed the package quantity')
        .typeError(' ');
    })
    .moreThan(0, 'Please enter amount > 0')
    .required()
    .typeError(' '),
  destination: yup.number().required(),
});

export const yupPurchaseLabelStepValidation = yup.object().shape({
  recipientEmail: yup.string().email(),
  recipientPhone: yup.string(),
  billingAccount: yup
    .mixed()
    .when('billingType', (billingType: string, schema: any) => {
      return billingType.toLowerCase() === 'recipient'
        ? yup.string().required()
        : schema;
    }),
});
