import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colorPalette } from 'ui/theme';

export const useDashboardPageStyle = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(1),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 304,
  },
  sidebar: {
    position: 'fixed',
    width: 240,
  },
  datepickers: {
    marginTop: theme.spacing(2),
  },
  tabs: {
    backgroundColor: 'transparent',
    '& .MuiTabs-indicator': { backgroundColor: 'transparent' },
    maxHeight: 'none',
  },
  tab: {
    minHeight: 'auto',
    height: 40,
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: 2,
    textTransform: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& svg': {
      marginBottom: '0 !important',
      marginRight: theme.spacing(0.5),
    },
    '&.MuiTab-labelIcon': {
      opacity: 0.7,
    },
    '&.Mui-selected': {
      backgroundColor: colorPalette.applicationLightGray,
      border: 'none',
      opacity: 1,
    },
  },
}));
