import {
  createApiReducer,
  chainReducers,
  INITIAL_API_STATUS,
} from 'services/api';
import {
  AdvancedSearchItem,
  createAddSearchReducer,
  createDeleteSearchReducer,
  createFetchSearchReducer,
  createUpdateSearchReducer,
} from 'services/search';
import { combineReducers } from 'redux';

import { CustomersActionTypes } from './actions';
import { transformCustomer } from '../transformations';

const initialState = {
  _status: INITIAL_API_STATUS,
  items: [],
};

/**
 * Transforms search item received from server to our format
 * @param response
 */
export const transformSearch = (serverSearchItem: any): AdvancedSearchItem => {
  return {
    id: serverSearchItem.id,
    columns: serverSearchItem.columns,
    searchName: serverSearchItem.searchName,
    isDefault: serverSearchItem.isDefault,
  };
};

const customerRatesReducer = createApiReducer(
  [
    CustomersActionTypes.FETCH_CUSTOMERS_START,
    CustomersActionTypes.FETCH_CUSTOMERS_SUCCESS,
    CustomersActionTypes.FETCH_CUSTOMERS_FAILURE,
  ],
  (data) => ({ items: data.results.map(transformCustomer) }),
  (data) => data
);

const fetchSearchReducer = createFetchSearchReducer('customers');
const addSearchReducer = createAddSearchReducer('customers');
const updateSearchReducer = createUpdateSearchReducer('customers');
const deleteSearchReducer = createDeleteSearchReducer('customers');

const customerChainedReducers = chainReducers(
  initialState,
  customerRatesReducer
);

const searchChainedReducers = chainReducers(
  initialState,
  fetchSearchReducer,
  addSearchReducer,
  updateSearchReducer,
  deleteSearchReducer
);

export default combineReducers({
  customers: customerChainedReducers,
  searches: searchChainedReducers,
});
