import { makeStyles, CSSProperties } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { ItemCompositeHeaderProps } from './types';

export const useItemRowStyle = makeStyles(() => ({
  width25: {
    width: '25%',
  },
  width15: {
    width: '15%',
  },
  widthButton: {
    width: '64px',
  },
}));

export const useItemCompositeHeaderStyles = makeStyles<
  Theme,
  ItemCompositeHeaderProps
>(() => {
  const textEllipsis: CSSProperties = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  return {
    constent: {
      width: 100,
    },
    name: {
      ...textEllipsis,
      fontStyle: 'normal',
      lineHeight: 1.2,
      paddingRight: 0,
    },
    description: {
      ...textEllipsis,
      lineHeight: 1.21,
      marginTop: 2,
    },
  };
});
