import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { NetworkSpinnerWrapper } from 'ui/components/NetworkSpinnerWrapper';

import { DetailCardProps } from './types';
import { StyledForm } from './styled';
import { RouteLeavingGuard } from '../RouteLeavingGuard';

const DetailCard = <T,>(props: DetailCardProps<T>) => {
  const {
    isLoading = false,
    isPolling = false,
    onSubmit,
    children,
    state,
    oldState,
    bypassRouteLeavingGuard = false,
  } = props;
  const [stateChanged, setStateChanged] = useState<boolean>(false);

  const navigate = useNavigate();

  const current = oldState ? oldState.current : null;
  useEffect(() => {
    if (state && oldState) {
      setStateChanged(!_.isEqual(state, oldState.current));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, current]);

  const handleSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      if (onSubmit) {
        const success = await onSubmit(e);
        return success;
      }
      return false;
    },
    [onSubmit]
  );

  return (
    <StyledForm onSubmit={handleSubmit} noValidate>
      <NetworkSpinnerWrapper
        isLoading={isLoading}
        isPolling={isPolling}
        display="flex"
        flexDirection="column"
        overflow="hidden"
        flexGrow={1}
        zIndex={2}
      >
        {children}
      </NetworkSpinnerWrapper>
      <RouteLeavingGuard
        when={stateChanged}
        onSubmit={handleSubmit}
        navigate={(path) => navigate(path)}
        bypassRouteLeavingGuard={bypassRouteLeavingGuard}
      />
    </StyledForm>
  );
};

export default DetailCard;
