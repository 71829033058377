import { IconNames } from 'ui/theme';

import { MenuItem, MenuItemVariant } from 'ui/components/Menu';
import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const CARRIER_ACCOUNTS_COLUMNS: Column[] = [
  {
    title: 'Name',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Carrier',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Account Number',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Billing Type',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Country',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Default',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: '',
    width: 50,
    type: ColumnTypes.renderByCustomRow,
  },
];

export enum CarrierAccountsTabAction {
  Delete,
}

export const createActionBarOptions = (
  handleActionClick: (type: CarrierAccountsTabAction) => void
): MenuItem[] => [
  {
    label: 'Delete Account',
    onOptionClick: () => handleActionClick(CarrierAccountsTabAction.Delete),
    icon: IconNames.FBODeleteSmall,
    variant: MenuItemVariant.Danger,
  },
];
