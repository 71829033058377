import { makeStyles } from '@mui/styles';
import { colorPalette } from 'ui/theme';

export const useDashboardMapStyle = makeStyles(() => ({
  card: {
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.16)',
    backgroundColor: colorPalette.white,
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
  },
  cardHeaderTitle: {
    fontSize: 'calc(16px * var(--font-size-multiplier, 1))',
    fontWeight: 700,
  },
  cardChartTitle: {
    marginTop: '20px',
    fontSize: 'calc(13px * var(--font-size-multiplier, 1))',
    padding: '5px 10px',
    minWidth: '130px',
    boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.16)',
    textAlign: 'center',
    borderRadius: '5px',
    backgroundColor: 'white',
  },
  mapContainer: {
    display: 'flex',
    height: '508px',
    backgroundColor: 'white',
    '& div': {
      display: 'flex',
      width: '100% !important',
      maxHeight: '508px !important',
      position: 'unset !important',
      justifySelf: 'center',
      alignSelf: 'center',
      flexWrap: 'wrap',
    },
  },
  mapInner: {
    '& svg': {
      width: '100% !important',
    },
  },
}));
