import { combineReducers } from 'redux';
import { reducer as salesOrderReducer } from '../salesOrders/redux';
import { reducer as companyReducer } from '../company/redux';
import { reducer as pickingReducer } from '../picking/redux';
import { reducer as shippingReducer } from '../shipping/redux';
import { reducer as purchaseOrderReducer } from '../purchaseOrders/redux';

export default combineReducers({
  salesOrder: salesOrderReducer,
  company: companyReducer,
  picking: pickingReducer,
  shipping: shippingReducer,
  purchaseOrder: purchaseOrderReducer,
});
