import { Currency } from './types';

export const initialCurrency: Currency = {
  id: null,
  code: null,
  country: null,
  dateCreated: null,
  dateLastModified: null,
  deleted: false,
  exchangeRate: null,
  isHomeCurrency: false,
  lastModifiedById: null,
  name: null,
  setManually: false,
  version: null,
};
