import _ from 'lodash';

import { ItemTrackingType, SerialRow } from 'services/inventory';
import { ReceiptItemReceive } from 'services/receiving';
import { Uom } from 'services/uoms';

export const transformSerialIdsToSerialList = (
  receive: ReceiptItemReceive,
  index: number
): SerialRow[] => {
  const serialIds = receive.receiptItem.trackingGroupList[index].serialIds;
  // create new SerialList array and fill it with serialIds data
  return [
    ...(Array(
      receive.receiptItem.trackingGroupList[index].onHand || 0
    ).keys() as any),
  ].map((index: number) => ({
    id: -1 - index,
    committed: false,
    serialNumbers: _.get(
      receive,
      'receiptItem.item.itemTrackingTypeList',
      []
    ).reduce(
      (o: any, type: ItemTrackingType) => ({
        ...o,
        [type.trackingTypeId]: serialIds[index],
      }),
      {}
    ),
  }));
};

export const getUomName = (uomId: number, uoms: Uom[]) => {
  const uomOption = uoms.find((option) => option.id === uomId);
  if (uomOption) {
    return uomOption.name;
  }
  return '';
};
