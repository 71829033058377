import React, { useCallback, useState, useEffect } from 'react';
import { Checkbox, Divider, FormControlLabel, Grid } from '@mui/material';
import _ from 'lodash';

import {
  CustomFieldType,
  postCustomFields,
  putCustomFields,
  initialCustomFieldOption,
  CustomFieldOption,
  fetchCustomFieldsAPI,
  CustomField,
} from 'services/customFields';
import {
  defaultMaximumPagination,
  replaceValueInCollection,
  RequestConfig,
} from 'helpers';
import { Errors, validateYup } from 'services/forms/validation';
import { useHandleTextFieldChange } from 'services/forms';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import { showNotification } from 'services/api';

import { useCustomFieldStyle } from './styled';
import { AddCustomFieldModalProps } from './types';
import { getYupSchema } from './validation';
import { PaginationDirection } from 'services/url';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { IconNames } from 'ui/theme';

export const customFieldHasValidOptionCount = (activeField: CustomField) => {
  const isFieldWithOptions =
    activeField.customFieldType === CustomFieldType.SingleChoice ||
    activeField.customFieldType === CustomFieldType.MultipleChoice;

  const hasOptions = activeField.customFieldOptions.length === 0;

  return isFieldWithOptions && hasOptions;
};

const AddCustomFieldModal: React.FC<AddCustomFieldModalProps> = (props) => {
  const {
    open,
    activeField,
    module,
    setActiveField,
    setVisible,
    setCustomFields,
  } = props;

  const classes = useCustomFieldStyle();
  const [isLoadingContent] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [errors, setErrors] = useState<Errors>({});

  const showCustomFieldOptions =
    activeField.customFieldType === CustomFieldType.MultipleChoice ||
    activeField.customFieldType === CustomFieldType.SingleChoice;

  const setCustomFieldOptions = useCallback(
    (customFieldOptions: React.SetStateAction<CustomFieldOption[]>) => {
      if (typeof customFieldOptions === 'function') {
        setActiveField((old) => ({
          ...old,
          customFieldOptions: customFieldOptions(old.customFieldOptions),
        }));
        return;
      }

      setActiveField((old) => ({
        ...old,
        customFieldOptions,
      }));
    },
    [setActiveField]
  );
  const [oldCustomFields, setOldCustomFields] = useState<CustomField[]>([]);
  useEffect(() => {
    (async () => {
      const requestConfig: RequestConfig = {
        pagination: {
          ...defaultMaximumPagination,
          sort: {
            sortBy: 'lineNumber',
            direction: PaginationDirection.Ascending,
          },
        },
      };

      try {
        const newCustomFields = await fetchCustomFieldsAPI(
          requestConfig,
          module
        );
        setOldCustomFields(newCustomFields.data);
      } catch {
        // Ignore error
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [newLine, setNewLine] = useState(oldCustomFields.length + 1);
  const handleSave = useCallback(async () => {
    setNewLine(newLine + 1);

    const isValid = validateYup(
      activeField,
      getYupSchema(activeField.customFieldType),
      setErrors
    );

    if (isValid) {
      if (customFieldHasValidOptionCount(activeField)) {
        showNotification(
          'Please add an option before saving the Custom Field',
          {
            variant: 'error',
          }
        );
        return;
      }
    } else {
      return;
    }

    setIsLoadingSave(true);
    if (!activeField.id) {
      try {
        const customFields = await postCustomFields(
          [{ ...activeField, lineNumber: newLine }],
          module
        );
        setVisible(false);
        setCustomFields(customFields);
      } catch {
        // Igonre error
      }
      setIsLoadingSave(false);
      return;
    }

    try {
      const customFields = await putCustomFields([activeField], module);
      setVisible(false);
      setCustomFields(customFields);
    } catch {
      // Ignore error
    }
    setIsLoadingSave(false);
  }, [activeField, module, setCustomFields, setVisible]);

  const addRow = useCallback(() => {
    setActiveField((old) => ({
      ...old,
      customFieldOptions: [...old.customFieldOptions, initialCustomFieldOption],
    }));
  }, [setActiveField]);

  const handleTextFieldChange = useHandleTextFieldChange(
    setActiveField,
    activeField
  );

  const handleCustomFieldChange = useCallback(
    (index: number) =>
      (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newFieldOptions = replaceValueInCollection<CustomFieldOption>(
          activeField.customFieldOptions,
          {
            ...activeField.customFieldOptions[index],
            option: ev.target.value || null,
          },
          index
        );

        setCustomFieldOptions(newFieldOptions || []);
      },
    [activeField.customFieldOptions, setCustomFieldOptions]
  );

  const handleTypeChange = useCallback(
    (e: any, value: CustomFieldType | null) => {
      if (
        value === CustomFieldType.MultipleChoice ||
        value === CustomFieldType.SingleChoice
      ) {
        setActiveField((old) => ({ ...old, customFieldType: value }));
        return;
      }

      //Force 'required' flag to false for Checkbox field type
      if (value === CustomFieldType.Boolean) {
        setActiveField((old) => ({
          ...old,
          customFieldType: value,
          customFieldOptions: [],
          required: false,
        }));
        return;
      }

      // When choice is not Multiple or Single we want to empty customFieldOptions
      setActiveField((old) => ({
        ...old,
        customFieldType: value,
        customFieldOptions: [],
      }));
    },
    [setActiveField]
  );

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setActiveField((old) => ({ ...old, [event.target.name]: checked }));
    },
    [setActiveField]
  );

  const showRequiredCheckbox =
    activeField.customFieldType !== CustomFieldType.Boolean;

  return (
    <Modal
      open={open}
      isLoadingContent={isLoadingContent}
      isLoading={isLoadingSave}
      onApplyClicked={handleSave}
      onCancelClicked={() => {
        setVisible(false);
        setErrors({});
      }}
      applyLabel="Save"
      cancelLabel="Cancel"
      maxWidth="sm"
      title="Add Custom Field"
      dataQa="add-custom-field"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            className="redesign"
            variant="standard"
            label="Name"
            placeholder="Enter name"
            value={activeField.name}
            name="name"
            onChange={handleTextFieldChange}
            error={!!errors.name}
            required
            dataQa="add-custom-field-name"
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            label="Type"
            placeholder="Select type"
            options={Object.values(CustomFieldType)}
            onChange={handleTypeChange}
            value={activeField.customFieldType}
            error={!!errors.customFieldType}
            getOptionLabel={(o) =>
              o === CustomFieldType.Boolean ? 'Checkbox' : o
            }
            required
            dataQa="add-custom-field-type"
          />
        </Grid>
        <Grid item xs={2}>
          {showRequiredCheckbox && (
            <FormControlLabel
              control={
                <Checkbox
                  className="redesign"
                  checked={activeField.required}
                  name="required"
                  onChange={handleCheckboxChange}
                  color="primary"
                  inputProps={
                    {
                      'data-qa': `add-custom-field-required`,
                    } as any
                  }
                />
              }
              label="Required"
            />
          )}
        </Grid>
      </Grid>
      {showCustomFieldOptions && (
        <>
          <Divider className={classes.divider} />
          <Grid container spacing={2}>
            {activeField.customFieldOptions.map((o, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <TextField
                    className="redesign"
                    variant="standard"
                    label={index + 1}
                    value={o.option}
                    onChange={handleCustomFieldChange(index)}
                    error={
                      !!_.get(
                        errors,
                        `customFieldOptions[${index}].option`,
                        null
                      )
                    }
                    required
                    dataQa={`custom-field-option-${index + 1}`}
                  />
                </Grid>
              );
            })}
            <Grid item>
              <FBOButton
                variant="tertiary"
                color="neutral"
                icon={IconNames.FBOAddCircle}
                onClick={addRow}
                size="medium"
                data-qa="add-row-choice"
              >
                Add Row
              </FBOButton>
            </Grid>
          </Grid>
        </>
      )}
    </Modal>
  );
};

export default AddCustomFieldModal;
