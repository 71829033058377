import { roundToDecimals } from './../../helpers/common';
import _ from 'lodash';
import moment from 'moment';

import { transformDateToMomentDate } from 'helpers';
import { transformUser } from 'services/user';
import {
  transformPurchaseOrder,
  transformPurchaseOrderItem,
} from 'services/purchaseOrders';
import {
  TrackingGroup,
  transformSerialDtoListToSerialRow,
  transformSerialRowToPostSerialRow,
  transformTrackingInfo,
} from 'services/inventory';
import { ItemType, transformItem } from 'services/items';
import {
  transformSalesOrder,
  transformSalesOrderItem,
} from 'services/salesOrders';
import {
  transformDocument,
  transformToRequestDocument,
} from 'services/documents';

import {
  Receipt,
  ReceiptItem,
  ReceiptItemReceive,
  ReceiptItemType,
  Reconcile,
  ReconcileCalculateOptions,
} from './types';
import { ReconcileReceiptItem } from '.';
import { DOT_CHAR } from 'helpers/consts';

export const transformReceipt = (res: any): Receipt => {
  const documents = res.documents || [];
  // order receipt items by line number
  const receiptItems: ReceiptItem[] = _.orderBy(
    (res.receiptItems || []).map((receiptItem: ReceiptItem) =>
      transformReceiptItem({ ...receiptItem, receiptId: res.id })
    ),
    (i: ReceiptItem) => {
      if (i.receiptItemType === ReceiptItemType.Sale) {
        return _.get(i, 'salesOrderItem.lineNumber', 0);
      }

      if (i.receiptItemType === ReceiptItemType.Purchase) {
        return _.get(i, 'purchaseOrderItem.lineNumber', 0);
      }

      return 0;
    },
    ['asc']
  );

  return {
    id: res.id,
    dateCreated: transformDateToMomentDate(res.dateCreated),
    dateLastModified: transformDateToMomentDate(res.dateLastModified),
    documents: documents.map(transformDocument),
    location: res.location,
    locationId: res.locationId,
    notes: res.notes,
    number: res.number,
    orderType: res.orderType,
    purchaseOrder: res.purchaseOrder
      ? transformPurchaseOrder(res.purchaseOrder)
      : null,
    purchaseOrderId: res.purchaseOrderId,
    purchaseUser: res.purchaseUser ? transformUser(res.purchaseUser) : null,
    purchaseUserId: res.purchaseUserId,
    receiptItems,
    salesOrder: res.salesOrder ? transformSalesOrder(res.salesOrder) : null,
    salesOrderId: res.salesOrderId,
    status: res.status,
    version: res.version,
  };
};

export const transformReceiptItem = (res: any): ReceiptItem => {
  const trackingGroupsDtos = res.trackingGroupObjects || [];
  return {
    id: res.id,
    addToVendorBill: res.addToVendorBill,
    billedTotalCost: res.billedTotalCost,
    carrier: res.carrier,
    carrierId: res.carrierId,
    carrierService: res.carrierService,
    carrierServiceId: res.carrierServiceId,
    carrierTracking: res.carrierTracking,
    carrierPackageCount: res.carrierPackageCount,
    customer: res.customer,
    customerId: res.customerId,
    dateBilled: transformDateToMomentDate(res.dateBilled),
    dateCreated: transformDateToMomentDate(res.dateCreated),
    dateLastModified: transformDateToMomentDate(res.dateLastModified),
    dateReceived: transformDateToMomentDate(res.dateReceived),
    dateReconciled: transformDateToMomentDate(res.dateReconciled),
    deliverTo: res.deliverTo,
    item: res.item ? transformItem(res.item) : null,
    itemId: res.itemId,
    landedTotalCost: res.landedTotalCost,
    location: res.location,
    locationId: res.locationId,
    multiCurrencyBilledTotalCost: res.multiCurrencyBilledTotalCost,
    multiCurrencyLandedTotalCost: res.multiCurrencyLandedTotalCost,
    notes: res.notes,
    purchaseOrderItem: res.purchaseOrderItem
      ? transformPurchaseOrderItem(res.purchaseOrderItem)
      : null,
    purchaseOrderItemId: res.purchaseOrderItemId,
    receiptItemType: res.receiptItemType,
    quantity: res.quantity,
    referenceNumber: res.referenceNumber,
    reasonForRejection: res.reasonForRejection,
    receiptId: res.receiptId,
    rejectionResponsibility: res.rejectionResponsibility,
    salesOrderItem: res.salesOrderItem
      ? transformSalesOrderItem(res.salesOrderItem)
      : null,
    salesOrderItemId: res.salesOrderItemId,
    status: res.status,
    trackingGroupList: trackingGroupsDtos.map(
      transformReceiptItemTrackingGroup
    ),
    uom: res.uom,
    uomId: res.uomId,
    version: res.version,
  };
};

export const transformReceiptToPatchReceipt = (receipt: Receipt): any => ({
  id: receipt.id,
  version: receipt.version,
  documents: receipt.documents.map(transformToRequestDocument),
});

export const transformReceiptItemToPutReceiptItem = (
  receiptItem: ReceiptItem
): any => ({
  id: receiptItem.id,
  version: receiptItem.version,
  dateLastModified: receiptItem.dateLastModified,
  dateCreated: receiptItem.dateCreated,
  receiptId: receiptItem.receiptId,
  billedTotalCost: receiptItem.billedTotalCost,
  multiCurrencyBilledTotalCost: receiptItem.multiCurrencyBilledTotalCost,
  landedTotalCost: receiptItem.landedTotalCost,
  multiCurrencyLandedTotalCost: receiptItem.multiCurrencyLandedTotalCost,
  carrierId: receiptItem.carrierId,
  carrierServiceId: receiptItem.carrierServiceId,
  carrierTracking: receiptItem.carrierTracking,
  carrierPackageCount: receiptItem.carrierPackageCount,
  customerId: receiptItem.customerId,
  dateBilled: receiptItem.dateBilled,
  dateReceived: receiptItem.dateReceived,
  deliverTo: receiptItem.deliverTo,
  locationId: receiptItem.locationId,
  itemId: receiptItem.itemId,
  purchaseOrderItemId: receiptItem.purchaseOrderItemId,
  receiptItemType: receiptItem.receiptItemType,
  salesOrderItemId: receiptItem.salesOrderItemId,
  quantity: receiptItem.quantity,
  reasonForRejection: receiptItem.reasonForRejection,
  referenceNumber: receiptItem.referenceNumber,
  rejectionResponsibility: receiptItem.rejectionResponsibility,
  addToVendorBill: receiptItem.addToVendorBill,
  uomId: receiptItem.uomId,
  notes: receiptItem.notes,
});

export const transformRejectItemToPost = (receiptItem: ReceiptItem): any => ({
  quantity: receiptItem.quantity,
  reasonForRejection: receiptItem.reasonForRejection,
  rejectionResponsibility: receiptItem.rejectionResponsibility,
});

export const transformReceiptItemReceiveToPostReceiptItemReceive = (
  receiptItemReceive: ReceiptItemReceive
): any => {
  const isInventoryType =
    _.get(receiptItemReceive, 'receiptItem.item.itemType') ===
    ItemType.Inventory;

  if (!isInventoryType) {
    return {
      receiptItemId: receiptItemReceive.receiptItemId,
      quantity: receiptItemReceive.quantity,
      locationId: receiptItemReceive.locationId,
      newUnitCost: receiptItemReceive.newUnitCost,
      dateReceived: receiptItemReceive.dateReceived
        ? moment.utc(receiptItemReceive.dateReceived).format()
        : null,
    };
  }

  const trackingGroups = receiptItemReceive.trackingGroups
    .filter((group) => group.quantity)
    .map((group) => {
      return {
        quantity: group.quantity,
        trackingGroupInfos: group.trackingInfoList.map((info) =>
          _.pick(info, ['trackingTypeId', 'value'])
        ),
        serialInputs: group.serialList.map(transformSerialRowToPostSerialRow),
      };
    });
  return {
    receiptItemId: receiptItemReceive.receiptItemId,
    quantity: receiptItemReceive.quantity,
    locationId: receiptItemReceive.locationId,
    newUnitCost: receiptItemReceive.newUnitCost,
    dateReceived: receiptItemReceive.dateReceived
      ? moment.utc(receiptItemReceive.dateReceived).format()
      : null,
    trackingGroupObjects: trackingGroups,
  };
};

export const tranformReceiptItemToFulfill = (receiptItem: ReceiptItem): any => {
  const isInventoryType =
    _.get(receiptItem, 'item.itemType') === ItemType.Inventory;

  if (isInventoryType) {
    return { ...receiptItem, receiptItemId: receiptItem.id };
  }

  return {
    ..._.omit(receiptItem, 'trackingGroupList'),
    receiptItemId: receiptItem.id,
  };
};

export const transformToPostReconcile = (
  receiptId: number,
  reconcileData: Reconcile
): any => {
  const filteredSummary = reconcileData.summary.filter(
    (s) => !!s.receiptItemId
  );
  // We want only newly added expenses sent to the server
  const newExpenses = reconcileData.expenses.filter((e) => e.new);
  const oldExpenses = reconcileData.expenses.filter((e) => !e.new);

  // in itemInputs we are sending every receipt item, including expenses
  const itemInputs: any = [];

  filteredSummary.forEach((s, index) => {
    let adj = 0;
    if (index === filteredSummary.length - 1) {
      adj = reconcileData.adjustment;
    }

    itemInputs.push({
      receiptItemId: s.receiptItemId,
      billedTotalCost: roundToDecimals(s.totalCost),
      landedTotalCost:
        reconcileData.calculation === ReconcileCalculateOptions.Manual
          ? s.landedCost
          : null,
      adjustment:
        reconcileData.calculation === ReconcileCalculateOptions.DoNotCalculate
          ? null
          : adj,
      landCosts:
        reconcileData.calculation === ReconcileCalculateOptions.DoNotCalculate
          ? false
          : true,
    });
  });

  // adding expenses that are added in PO to itemInputs
  oldExpenses.forEach((e) => {
    itemInputs.push({
      receiptItemId: e.receiptItemId,
      billedTotalCost: roundToDecimals((e.quantity || 0) * (e.cost || 0)),
      landedTotalCost: null,
      adjustment: null,
      landCosts: false,
    });
  });

  return {
    receiptId,
    type: reconcileData.calculation,
    itemInputs,
    newItemInputs: newExpenses.map((e) => ({
      itemId: e.itemId,
      quantity: e.quantity,
      billedTotalCost: roundToDecimals((e.quantity || 0) * (e.cost || 0)),
      addToVendorBill: e.addToVendorBill,
    })),
    refNumber: reconcileData.referenceNumber,
    billDate: reconcileData.billDate,
    dueDate: reconcileData.dueDate,
  };
};

export const transformReceiptItemToReconcileReceiptItem = (
  receiptItem: ReceiptItem
): ReconcileReceiptItem => {
  const { id, quantity, referenceNumber, status, itemId, dateReceived } =
    receiptItem;

  const vendorItemName = _.get(
    receiptItem,
    'purchaseOrderItem.vendorItemName',
    null
  );
  const itemName = _.get(receiptItem, 'purchaseOrderItem.name', '');
  const itemType = _.get(receiptItem, 'item.itemType', null);
  const purchaseOrderItemType = _.get(
    receiptItem,
    'purchaseOrderItem.purchaseOrderItemType',
    null
  );

  const name = vendorItemName
    ? `${itemName} ${DOT_CHAR} ${vendorItemName}`
    : itemName;

  const description = _.get(receiptItem, 'purchaseOrderItem.description', null);
  const unitCost = _.get(receiptItem, 'purchaseOrderItem.unitCost', 0);
  const totalCost =
    _.get(receiptItem, 'purchaseOrderItem.unitCost', 0) * (quantity || 1);
  const landedTotalCost = receiptItem.landedTotalCost;

  const multiCurrencyUnitCost = _.get(
    receiptItem,
    'purchaseOrderItem.multiCurrencyUnitCost',
    0
  );
  const multiCurrencyTotalCost = _.get(
    receiptItem,
    'purchaseOrderItem.multiCurrencyTotalCost',
    0
  );
  const currency = _.get(
    receiptItem,
    'purchaseOrderItem.exchangeCurrency',
    null
  );

  const abbreviation = _.get(
    receiptItem,
    'purchaseOrderItem.uom.abbreviation',
    'ea'
  );
  const image = _.get(receiptItem, 'item.defaultImage', null);

  const taxable = _.get(receiptItem, 'purchaseOrderItem.taxable', false);
  const taxId = _.get(receiptItem, 'purchaseOrderItem.taxId', null);
  const taxRate = _.get(receiptItem, 'purchaseOrderItem.taxRate', 0);
  const taxAmount = _.get(receiptItem, 'purchaseOrderItem.taxTotal', 0);
  const multiCurrencyTaxTotal = _.get(
    receiptItem,
    'purchaseOrderItem.multiCurrencyTaxTotal',
    0
  );

  const subTotal = _.get(receiptItem, 'purchaseOrderItem.subTotal', 0);
  const multiCurrencySubTotal = _.get(
    receiptItem,
    'purchaseOrderItem.multiCurrencySubTotal',
    0
  );

  const costIncludeTax = _.get(
    receiptItem,
    'purchaseOrderItem.purchaseOrder.costIncludesTax',
    false
  );

  return {
    id,
    itemId,
    quantity,
    name,
    unitCost,
    totalCost,
    multiCurrencyUnitCost,
    multiCurrencyTotalCost,
    currency,
    referenceNumber,
    status,
    image,
    description,
    abbreviation,
    itemType,
    landedTotalCost,
    purchaseOrderItemType,
    dateReceived,
    taxable,
    taxId,
    taxRate,
    taxAmount,
    multiCurrencyTaxTotal,
    subTotal,
    multiCurrencySubTotal,
    costIncludeTax,
  };
};

export const transformReceiptItemTrackingGroup = (res: any): TrackingGroup => ({
  committedQuantity: res.committedQty || 0,
  onHand: res.quantity,
  quantity: 0,
  trackingInfoList: (res.trackingGroupInfos || []).map((t: any) =>
    transformTrackingInfo(t)
  ),
  serialList: (res.serialInputs || []).map((s: any) =>
    transformSerialDtoListToSerialRow(s)
  ),
  serialIds: res.serialIds || [],
});
