import { makeStyles } from '@mui/styles';
import { AuthBackground } from 'ui/theme/images';

export const useAuthContainerStyle = makeStyles(() => ({
  background: {
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    width: '100vw',
    background: `url(${AuthBackground}) no-repeat fixed`,
    backgroundSize: 'cover',
    backgroundColor: 'white',
    overflow: 'auto',
    '& .MuiTypography-root': {
      fontFamily: 'Roboto, Roboto Condensed, Helvetica, sans-serif', // todo : move after style-guide theme
    },
    '& #FishbowlDriveLogo': {
      margin: 'auto',
    },
  },
  paper: {
    display: 'flex',
    justifySelf: 'center',
    alignSelf: 'flex-end',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 0,
    width: 430,
    boxShadow:
      '0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
    '&.MuiBox-root': {
      display: 'grid',
      '.password-reqs': {
        display: 'flex',
      },
    },
  },
  title: {
    '& .MuiTypography-h4': {
      fontWeight: 600, // todo : move after style-guide theme
    },
  },
}));
