import { makeStyles } from '@mui/styles';

import { colorPalette } from 'ui/theme';

export const useAutoPurchaseOrderWizardStyle = makeStyles(() => ({
  activeIcon: {
    color: 'green',
    fill: `${colorPalette.fishbowlCorporateBlue} !important`,
  },
  margin: {
    marginLeft: '170px',
  },
}));
