import _ from 'lodash';

import { transformCurrency } from 'services/currencies/transformations';
import { Address, Contact } from 'services/addresses';
import { transformToTag } from 'services/tags';
import {
  ObjectType,
  transformCustomField,
  transformToRequestCustomField,
} from 'services/customFields';
import {
  transformDocument,
  transformToRequestDocument,
} from 'services/documents';
import { transformDateToMomentDate } from 'helpers';
import { transformTaxRate } from 'services/taxRates';
import { transformSaleItem } from 'services/items/saleItems';

import { Customer, CustomerCarrierAccount, CustomerItem } from './types';

export const transformCustomer = (response: any): Customer => {
  const addresses = response.customerAddresses || [];
  const carrierAccountSettings = response.carrierAccountSettings || [];
  const customFields = response.customFields || [];
  const tags = response.tags || [];
  const documents = response.documents || [];
  const customerSaleItems = response.customerSaleItems || [];

  const exchangeRate = _.get(response.currency, 'exchangeRate', 1);

  return {
    id: response.id,
    accountingClassId: response.accountingClassId,
    addresses: addresses.map(transformToAddress),
    alertNotes: response.alertNotes,
    bcc: response.bcc,
    billWithParent: response.billWithParent,
    cc: response.cc,
    currencyId: response.currencyId,
    currency: response.currency ? transformCurrency(response.currency) : null,
    carrierAccountSettings: carrierAccountSettings.map(
      transformToCustomerCarrierAccounts
    ),
    customExchangeRate: response.customExchangeRate,
    customFields: customFields.map(transformCustomField),
    customerSaleItems: customerSaleItems.map((i: any) =>
      transformToCustomerItem(i, exchangeRate)
    ),
    dateCreated: transformDateToMomentDate(response.dateCreated),
    dateLastModified: transformDateToMomentDate(response.dateLastModified),
    defaultCarrierId: response.defaultCarrierId,
    defaultCarrierServiceId: response.defaultCarrierServiceId,
    defaultOrderPriorityId: response.defaultOrderPriorityId,
    deleted: response.deleted,
    hasSalesOrders: response.hasSalesOrders,
    documents: documents.map(transformDocument),
    email: response.email,
    fax: response.fax,
    level: response.level,
    mobile: response.mobile,
    name: response.name,
    notes: response.notes,
    other: response.other,
    parentCustomerId: response.parentCustomerId,
    paymentTermId: response.paymentTermId,
    office: response.office,
    representative: response.representative,
    representativeId: response.representativeId,
    tags: tags.map(transformToTag),
    taxExemptNumber: response.taxExemptNumber,
    taxExempt: response.taxExempt,
    url: response.url || null,
    version: response.version,
    taxId: response.taxId,
    tax: response.tax ? transformTaxRate(response.tax) : null,
  };
};

const transformToAddress = (res: any): Address => {
  const contacts = res.customerAddressContacts || [];
  return {
    id: res.id,
    attention: res.attention,
    city: res.city,
    companyName: res.companyName,
    contacts: contacts.map(transformToContact),
    country: res.country,
    defaultBillTo: res.defaultBillTo,
    defaultShipTo: res.defaultShipTo,
    deleted: false,
    email: res.email,
    name: res.name,
    phone: res.phone,
    postalCode: res.postalCode,
    residential: res.residential,
    state: res.state,
    street: res.street,
    street2: res.street2,
    version: res.version,
    verified: res.verified,
  };
};

const transformToContact = (res: any): Contact => {
  return {
    id: res.id,
    dateCreated: transformDateToMomentDate(res.dateCreated),
    dateLastModified: transformDateToMomentDate(res.dateLastModified),
    defaultFlag: res.defaultFlag,
    deleted: false,
    name: res.name,
    type: res.type,
    value: res.value,
    version: res.version,
  };
};

export const transformToRequestCustomerItem = (item: CustomerItem): any => {
  const payload = {
    saleItemId: item.saleItemId,
    price: item.price,
    name: item.name,
  };

  // POST
  if (item.id === null || item.id < 0) {
    return payload;
  }

  // PUT
  return {
    ...payload,
    id: item.id,
    version: item.version,
    deleted: item.deleted,
  };
};

export const transformCustomerToRequestCustomer = (customer: Customer): any => {
  const newCustomer = {
    accountingClassId: customer.accountingClassId,
    bcc: customer.bcc,
    alertNotes: customer.alertNotes,
    billWithParent: customer.billWithParent,
    cc: customer.cc,
    currencyId: customer.currencyId,
    carrierAccountSettings: customer.carrierAccountSettings.map(
      transformToCustomerCarrierAccounts
    ),
    customerAddresses: customer.addresses.map(transformToRequestAddress),
    customExchangeRate: customer.customExchangeRate,
    customFields: customer.customFields.map((c) =>
      transformToRequestCustomField(c, ObjectType.Customer, true)
    ),
    customerSaleItems: customer.customerSaleItems.map(
      transformToRequestCustomerItem
    ),
    defaultCarrierId: customer.defaultCarrierId,
    defaultCarrierServiceId: customer.defaultCarrierServiceId,
    defaultOrderPriorityId: customer.defaultOrderPriorityId,
    documents: customer.documents.map(transformToRequestDocument),
    name: customer.name,
    email: customer.email,
    fax: customer.fax,
    mobile: customer.mobile,
    notes: customer.notes,
    other: customer.other,
    parentCustomerId: customer.parentCustomerId,
    paymentTermId: customer.paymentTermId,
    office: customer.office,
    representativeId: customer.representativeId,
    tags: customer.tags.map((t) => ({ name: t.name })),
    taxExemptNumber: customer.taxExemptNumber,
    taxExempt: customer.taxExempt,
    url: customer.url,
    taxId: customer.taxId,
  };

  // POST
  if (!customer.id || customer.id < 0) {
    return newCustomer;
  }

  // PUT
  return {
    ...newCustomer,
    id: customer.id,
    deleted: customer.deleted,
    version: customer.version,
  };
};

const transformToRequestAddress = (address: Address): any => {
  const newAddress = {
    attention: address.attention,
    city: address.city,
    companyName: address.companyName,
    country: address.country,
    customerAddressContacts: address.contacts.map(transformToRequestContact),
    name: address.name,
    postalCode: address.postalCode,
    residential: address.residential,
    state: address.state,
    street: address.street,
    street2: address.street2,
    defaultBillTo: address.defaultBillTo,
    defaultShipTo: address.defaultShipTo,
    verified: address.verified,
  };

  // POST
  if (!address.id || address.id < 0) {
    return newAddress;
  }

  // PUT
  return {
    ...newAddress,
    id: address.id,
    deleted: address.deleted,
    version: address.version,
  };
};

const transformToRequestContact = (contact: Contact): any => {
  const newContact = {
    name: contact.name,
    type: contact.type,
    value: contact.value,
    defaultFlag: contact.defaultFlag,
  };

  // POST
  if (!contact.id || contact.id < 0) {
    return newContact;
  }

  // PUT
  return {
    ...newContact,
    id: contact.id,
    deleted: contact.deleted,
    version: contact.version,
  };
};

/* CARRIER ACCOUNTS */

export const transformToCustomerCarrierAccounts = (
  res: any
): CustomerCarrierAccount => {
  return {
    id: res.id,
    version: res.version,
    customerId: res.customerId,
    defaultFlag: res.defaultFlag,
    carrier: res.carrier,
    accountNumber: res.accountNumber,
    billingType: res.billingType,
    billingZip: res.billingZip,
    billingCountry: res.billingCountry,
    deleted: res.deleted,
    name: res.name,
  };
};

export const transformToRequestCustomerCarrierAccount = (
  customerCarrierAccount: CustomerCarrierAccount
): any => {
  const newcustomerCarrierAccount = {
    customerId: customerCarrierAccount.customerId,
    name: customerCarrierAccount.name,
    defaultFlag: customerCarrierAccount.defaultFlag,
    carrier: customerCarrierAccount.carrier,
    accountNumber: customerCarrierAccount.accountNumber,
    billingType: customerCarrierAccount.billingType,
    billingZip: customerCarrierAccount.billingZip,
    billingCountry: customerCarrierAccount.billingCountry,
  };

  // POST
  if (!customerCarrierAccount.id || customerCarrierAccount.id < 0) {
    return newcustomerCarrierAccount;
  }

  // PUT
  return {
    ...newcustomerCarrierAccount,
    id: customerCarrierAccount.id,
    deleted: customerCarrierAccount.deleted,
    version: customerCarrierAccount.version,
  };
};

export const transformToCustomerItem = (
  res: any,
  exchangeRate: number
): CustomerItem => {
  return {
    id: res.id,
    price: res.price,
    dateCreated: transformDateToMomentDate(res.dateCreated),
    dateLastModified: transformDateToMomentDate(res.dateLastModified),
    deleted: res.deleted,
    multicurrencyCustomerItemPrice: (res.price || 0) * exchangeRate,
    name: res.name,
    customer: res.customer ? transformCustomer(res.customer) : null,
    customerId: res.customerId,
    version: res.version,
    saleItem: res.saleItem ? transformSaleItem(res.saleItem) : null,
    saleItemId: res.saleItemId,
  };
};
