import React, { memo, useCallback, useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { UserAssignLocationsProps } from './types';
import { getLocations, Location } from 'services/locations';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { colorPalette } from 'ui/theme';
import { useAssignLocationsStyle } from './styled';

const AssignLocations: React.FC<UserAssignLocationsProps> = (props) => {
  const { user, setUser } = props;

  const [assignedLocations, setAssignedLocations] = useState<Location[]>([]);
  const [availableLocations, setAvailableLocations] = useState<Location[]>([]);

  const { items: locations } = useSelector(getLocations);

  const classes = useAssignLocationsStyle(props);

  useEffect(() => {
    const rootLocations = locations.filter((l) => !l.parentLocationId);
    const assigned: Location[] = [];
    const available: Location[] = [];
    rootLocations.forEach((rootLocation) => {
      if (
        rootLocation.id &&
        user.locationIdsPermitted.includes(rootLocation.id)
      ) {
        assigned.push(rootLocation);
      } else {
        available.push(rootLocation);
      }
    });

    // Sort the assigned array to move the default location to the top
    const defaultLocationIndex = assigned.findIndex(
      (location) => location.id === user.defaultLocationId
    );
    if (defaultLocationIndex !== -1) {
      const [defaultLocation] = assigned.splice(defaultLocationIndex, 1);
      assigned.unshift(defaultLocation);
    }

    setAssignedLocations(assigned);
    setAvailableLocations(available);
  }, [locations, user]);

  const handleAssignedLocationChange = useCallback(
    (location: Location | null) => {
      setUser((old) => ({
        ...old,
        locationIdsPermitted: [...old.locationIdsPermitted, location?.id ?? 0],
      }));
    },
    [locations]
  );

  const handleAvailableLocationChange = useCallback(
    (location: Location | null) => {
      setUser((old) => ({
        ...old,
        locationIdsPermitted: old.locationIdsPermitted.filter(
          (l) => l !== location?.id
        ),
      }));
    },
    [locations]
  );

  const handleAssignAll = useCallback(() => {
    const rootLocations = locations
      .filter((l) => !l.parentLocationId)
      .map((location) => location.id!);
    setUser((old) => ({
      ...old,
      locationIdsPermitted: rootLocations,
    }));
  }, [locations, setUser]);

  const handleUnassignAll = useCallback(() => {
    setUser((old) => ({
      ...old,
      locationIdsPermitted: old.locationIdsPermitted.filter(
        (id) => id === old.defaultLocationId
      ),
    }));
  }, [setUser]);

  const handleSetAsDefault = useCallback(
    (location: Location) => {
      setUser((old) => ({
        ...old,
        defaultLocationId: location.id,
      }));
    },
    [setUser]
  );

  return (
    <Grid container rowGap="10px" marginTop="20px">
      <Grid container>
        <Grid item xs={6}>
          <Typography className={classes.title}>Available Locations</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.title} marginLeft={'10px'}>
            Assigned Locations
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.container}>
        <Grid
          item
          xs={6}
          className={classes.locationContainer}
          borderRight={`1px solid ${colorPalette.redesign.background4}`}
        >
          {availableLocations.map((location) => (
            <Grid
              container
              key={`location_${location.id}`}
              className={classes.locationRow}
            >
              <Grid item xs={8} className={classes.locationName}>
                <Typography>{location.name}</Typography>
              </Grid>
              <Grid item xs={4} className={classes.buttonContainer}>
                <FBOButton
                  color="positive"
                  size="small"
                  variant="secondary"
                  onClick={() => handleAssignedLocationChange(location)}
                  data-qa={`assign-${location.name}`}
                >
                  Assign
                </FBOButton>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={6} className={classes.locationContainer}>
          {assignedLocations.map((location) => (
            <Grid
              container
              key={`location_${location.id}`}
              className={classes.locationRow}
            >
              <Grid item xs={7} className={classes.locationName}>
                <Typography>{location.name}</Typography>
                {user.defaultLocationId === location.id && (
                  <>
                    <Typography>&nbsp;</Typography>
                    <Typography color={colorPalette.redesign.contentSecondary}>
                      (Default)
                    </Typography>
                  </>
                )}
              </Grid>
              {user.defaultLocationId !== location.id && (
                <Grid item xs={5} className={classes.buttonContainer}>
                  <FBOButton
                    color="positive"
                    size="small"
                    variant="secondary"
                    onClick={() => handleSetAsDefault(location)}
                    style={{ marginRight: '5px' }}
                    data-qa={`set-as-default-${location.name}`}
                  >
                    Set As Default
                  </FBOButton>
                  <FBOButton
                    color="negative"
                    size="small"
                    variant="secondary"
                    onClick={() => handleAvailableLocationChange(location)}
                    data-qa={`unassign-${location.name}`}
                  >
                    Unassign
                  </FBOButton>
                </Grid>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <FBOButton
            color="positive"
            size="small"
            variant="secondary"
            onClick={handleAssignAll}
            data-qa={`assign-all`}
          >
            Assign All
          </FBOButton>
        </Grid>
        <Grid item xs={6}>
          <FBOButton
            style={{ marginLeft: '5px' }}
            color="negative"
            size="small"
            variant="secondary"
            onClick={handleUnassignAll}
            data-qa={`unassign-all`}
          >
            Unassign All
          </FBOButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(AssignLocations);
