import { transformDateToMomentDate } from 'helpers';

import { Currency } from './types';

export const transformCurrency = (response: any): Currency => {
  return {
    id: response.id,
    code: response.code,
    country: response.country,
    dateCreated: transformDateToMomentDate(response.dateCreated),
    dateLastModified: transformDateToMomentDate(response.dateLastModified),
    deleted: response.deleted,
    exchangeRate: response.exchangeRate,
    isHomeCurrency: response.isHomeCurrency,
    lastModifiedById: response.lastModifiedById,
    name: response.name,
    setManually: response.setManually,
    version: response.version,
  };
};

export const transformToRequestCurrency = (currency: Currency): any => {
  // POST
  if (currency.id === null || currency.id < 1) {
    return {
      name: currency.name,
      code: currency.code,
      country: currency.country,
      exchangeRate: currency.exchangeRate,
      setManually: true,
      deleted: currency.deleted,
      isHomeCurrency: currency.isHomeCurrency,
    };
  }

  // PUT
  return {
    id: currency.id,
    name: currency.name,
    code: currency.code,
    country: currency.country,
    version: currency.version,
    exchangeRate: currency.exchangeRate,
    setManually: true,
    isHomeCurrency: currency.isHomeCurrency,
  };
};
