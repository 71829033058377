import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { TextFieldQuantityProps } from './types';

export const useTextFieldQuantityStyle = makeStyles<
  Theme,
  TextFieldQuantityProps
>(() => ({
  link: {
    borderBottom: (props) =>
      props.disabled ? '1px solid #e0e0e0' : '1px solid black',
    '&:hover': {
      textDecoration: 'none',
    },
    paddingBottom: 1,
    opacity: (props) => (props.disabled ? 0.6 : 1),
  },
  noBorder: {
    borderLeft: 'none !important',
  },
}));
