import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { colorPalette, styled, SupportColors } from 'ui/theme';

import { EnhancedBodyProps, EnhancedHeaderProps } from './types';

export const useEnhancedBodyStyles = makeStyles<Theme, EnhancedBodyProps>(
  (theme: Theme) => ({
    cellStyle: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      cursor: ({ onItemClick }) => (onItemClick ? 'pointer' : 'default'),
      borderRightColor: ({ tableBordered }) =>
        tableBordered ? SupportColors.tableBorder : 'transparent',
      borderRightWidth: ({ tableBordered }) => (tableBordered ? 1 : 0),
      borderRightStyle: ({ tableBordered }) =>
        tableBordered ? 'solid' : 'none',
      ...theme.typography.body2,
    },
    cellStyleRedesign: {
      padding: 0,
    },
    cellCheckboxRedesign: {
      margin: '0 !important',
    },
    itemImage: {
      height: 40,
      objectFit: 'contain',
    },
    itemCheckbox: {
      padding: 0,
    },
    subHeaderStyle: {
      backgroundColor: theme.palette.grey['50'],
    },
    tableRow: {
      '&:hover .MuiCheckbox-root': {
        color: colorPalette.applicationDarkGray60,
      },
    },
    rowSelected: {
      backgroundColor: 'rgba(0,0,0,0.04)',
    },
    rowSelectedRestyle: {
      backgroundColor: colorPalette.redesign.background1,
    },
    rowClickable: {
      cursor: 'pointer',
    },
    fitWidth: {
      width: '1px',
      whiteSpace: 'nowrap',
    },
    loader: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

export const useEnhancedHeaderStyles = makeStyles<Theme, EnhancedHeaderProps>(
  () => ({
    headerStyle: {
      height: 40,
    },
    headerText: {
      whiteSpace: 'nowrap',
      color: SupportColors.tableHeaderTextColor,
      borderRightColor: ({ tableBordered }) =>
        tableBordered ? SupportColors.tableBorder : 'transparent',
      borderRightWidth: ({ tableBordered }) => (tableBordered ? 1 : 0),
      borderRightStyle: ({ tableBordered }) =>
        tableBordered ? 'solid' : 'none',
      height: 40,
      paddingTop: 0,
      paddingBottom: 0,
      '&:only-of-type': {
        width: '100% !important',
      },
    },
    statusCell: {
      width: 7,
      padding: 0,
    },
    fitWidth: {
      width: '1px',
      whiteSpace: 'nowrap',
    },
    selectCheckbox: {
      minWidth: 48,
      width: 48,
      maxWidth: 48,
      whiteSpace: 'nowrap',
      color: SupportColors.tableHeaderTextColor,
      borderRightColor: ({ tableBordered }) =>
        tableBordered ? SupportColors.tableBorder : 'transparent',
      borderRightWidth: ({ tableBordered }) => (tableBordered ? 1 : 0),
      borderRightStyle: ({ tableBordered }) =>
        tableBordered ? 'solid' : 'none',
      height: 40,
      paddingTop: 0,
      paddingBottom: 0,
    },
    selectCheckboxRedesign: {
      '& span': { marginLeft: '8px !important' },
      minWidth: 48,
      width: 48,
      maxWidth: 48,
    },
  })
);

export const useEnhancedFooterStyles = makeStyles((theme: Theme) => ({
  paginationWrapper: {
    display: 'flex',
    alignItems: 'center',
    order: -1,
  },
  tablePaginationStyle: {
    height: 55,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
  },
  tablePaginationToolbar: {
    width: '100%',
    overflow: 'auto',
    overflowY: 'hidden',
    paddingRight: 0,
  },
  tablePaginationContainer: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiPagination-ul': {
      flexWrap: 'nowrap',
    },
  },
  tablePaginationPopper: {
    display: 'flex',
    zIndex: 10,
    '& > .MuiPaper-root': {
      maxWidth: '16.125em',
      width: '100%',
      overflow: 'auto',

      '& > .MuiList-root': {
        display: 'inline-flex',
        flexWrap: 'wrap',
      },
    },
  },
  tablePaginationCard: {
    padding: theme.spacing(2),
  },
  tablePaginationButton: {
    height: '1.875rem',
    margin: '0 1px',
    padding: '0 4px',
    minWidth: '1.875rem',
    borderRadius: 16,
    color: colorPalette.fishbowlCorporateGray1,
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '& > .MuiButton-label': {
      fontWeight: 400,
      fontSize: '0.8125rem',
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
  },
  tablePaginationLabelButton: {
    appearance: 'none',
    backgroundColor: 'transparent',
    border: 'none',
    borderColor: 'transparent !important',
    fontSize: 'inherit',
    color: 'inherit',
    textTransform: 'capitalize',
    '& > span': {
      fontWeight: 400,
      fontSize: '0.8125rem',
    },
    margin: 0,
  },
  tablePaginationMenu: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 2),
  },
}));

export const NoImageContainer = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.common.white};
`;
