import { combineReducers } from 'redux';
import {
  chainReducers,
  INITIAL_API_STATE,
  createApiReducer,
} from 'services/api';
import {
  createAddSearchReducer,
  createDeleteSearchReducer,
  createFetchSearchReducer,
  createUpdateSearchReducer,
} from 'services/search';

import { PurchaseOrderActionTypes } from './actions';
import { transformPurchaseOrder } from '../transformations';

const fetchPurchaseOrderReducer = createApiReducer(
  [
    PurchaseOrderActionTypes.FETCH_PURCHASE_ORDER_START,
    PurchaseOrderActionTypes.FETCH_PURCHASE_ORDER_SUCCESS,
    PurchaseOrderActionTypes.FETCH_PURCHASE_ORDER_FAILURE,
  ],
  (data) => ({ items: data.results.map(transformPurchaseOrder) }),
  (data) => data
);

const purchaseOrderChainedReducers = chainReducers(
  INITIAL_API_STATE,
  fetchPurchaseOrderReducer
);

const fetchSearchReducer = createFetchSearchReducer('purchaseOrder');
const addSearchReducer = createAddSearchReducer('purchaseOrder');
const updateSearchReducer = createUpdateSearchReducer('purchaseOrder');
const deleteSearchReducer = createDeleteSearchReducer('purchaseOrder');

const searchChainedReducers = chainReducers(
  INITIAL_API_STATE,
  fetchSearchReducer,
  addSearchReducer,
  updateSearchReducer,
  deleteSearchReducer
);

export default combineReducers({
  purchaseOrders: purchaseOrderChainedReducers,
  searches: searchChainedReducers,
});
