import { createApiCall } from 'services/api';

import { Uom } from './types';
import {
  transformUom,
  transformUomToPostUom,
  transformUomToPutUom,
} from './transformations';

export const fetchUom = async (id: number): Promise<Uom> => {
  const expands = ['toConversions', 'fromConversions'];

  const response = await createApiCall(
    {
      path: `/v1/uoms/${id}?expand=${expands.join(',')}`,
      method: 'GET',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformUom(response.data);
};

export const postUom = async (uom: Uom): Promise<Uom> => {
  const response = await createApiCall(
    {
      path: '/v1/uoms?expand=toConversions,fromConversions',
      method: 'POST',
      body: transformUomToPostUom(uom),
    },
    {
      getMessage: {
        success: () => 'UOM created',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformUom(response.data);
};

export const putUom = async (uom: Uom): Promise<Uom> => {
  const response = await createApiCall(
    {
      path: '/v1/uoms?expand=toConversions,fromConversions',
      method: 'PUT',
      body: transformUomToPutUom(uom),
    },
    {
      getMessage: {
        success: () => 'UOM updated',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformUom(response.data);
};

export const deleteUom = async (id: number): Promise<boolean> => {
  await createApiCall(
    {
      path: `/v1/uoms/${id}`,
      method: 'DELETE',
    },
    {
      getMessage: {
        success: () => 'UOM deleted',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return true;
};

export const restoreUom = async (uomId: number) => {
  await createApiCall(
    {
      path: `/v1/uoms/${uomId}/restore`,
      method: 'POST',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
        success: () => 'UOM successfully restored',
      },
    }
  )();
};
