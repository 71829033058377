import { State } from 'app/redux';

export const getCurrencies = (state: State) => state.currency.currencies;

export const getCurrencyById = (id: number | null) => (state: State) => {
  if (id === null) {
    return;
  }

  return state.currency.currencies.items.find((item) => item.id === id);
};

export const getSearches = (state: State) => state.currency.searches;
