import { PermissionGroup } from 'services/permissions';

export interface PermissionsTabProps {
  permissionGroup: PermissionGroup;
  setPermissionGroup: React.Dispatch<React.SetStateAction<PermissionGroup>>;
}

export enum PermissionCategoryState {
  Checked,
  PartiallyChecked,
  NotChecked,
}
