import { chainReducers, createApiReducer } from 'services/api';

import { SettingsSalesOrderActionTypes } from './actions';
import { transformSalesOrderSettings } from '../transformations';

const fetchSettingsSalesOrderReducer = createApiReducer(
  [
    SettingsSalesOrderActionTypes.FETCH_SALES_ORDER_START,
    SettingsSalesOrderActionTypes.FETCH_SALES_ORDER_SUCCESS,
    SettingsSalesOrderActionTypes.FETCH_SALES_ORDER_FAILURE,
  ],
  (data) => transformSalesOrderSettings(data),
  (data) => data
);

const settingsSalesOrderChainedReducers = chainReducers(
  {},
  fetchSettingsSalesOrderReducer
);

export default settingsSalesOrderChainedReducers;
