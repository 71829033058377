import React, { memo, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { TableCell } from '@mui/material';
import moment from 'moment';

import { Receipt } from 'services/receiving';
import { getCarriers } from 'services/carriers';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { StatusLabel } from 'ui/components/Status/StatusLabel';
import { DateMessage } from 'ui/components/DateTooltip/types';
import { DateTooltip } from 'ui/components/DateTooltip';
import { Dates, useGetIntlDateFormatString } from 'helpers';
import FBOStatusDot, {
  DefaultStatus,
} from 'ui/theme/components/FBOStatusDot/FBOStatusDot';

const ReceivingRow: React.FC<RenderCustomRowProps<Receipt>> = (props) => {
  const { row, columns, meta } = props;

  const { items: carriers } = useSelector(getCarriers);

  const intlFormatDate = useGetIntlDateFormatString();

  const detailsCardOpened: boolean = meta.detailsCardOpened;

  const tooltipDates: DateMessage[] = [
    { date: row.dateCreated, message: 'Date Created' },
    { date: row.dateLastModified, message: 'Date Last Modified' },
  ];

  const getDateIssued = useCallback(() => {
    let dateIssued = null;
    if (row.salesOrder) {
      dateIssued = row.salesOrder.dateIssued;
    } else if (row.purchaseOrder) {
      dateIssued = row.purchaseOrder.dateIssued;
    }

    return dateIssued ? moment(dateIssued).format(intlFormatDate) : null;
  }, [row.salesOrder, row.purchaseOrder, intlFormatDate]);

  const dateValue: string = useMemo(() => {
    const dateField = !detailsCardOpened ? columns[3].field! : null;
    if (dateField === Dates.ReceivingDateIssued) {
      return getDateIssued() || '';
    }

    const date = dateField ? _.get(row, dateField, null) : null;
    return date ? moment(date).format(intlFormatDate) : '';
  }, [columns, row, detailsCardOpened, getDateIssued, intlFormatDate]);

  const getCustomerOrVendor = () => {
    if (row.salesOrder) {
      return _.get(row, 'salesOrder.customer.name', '');
    }

    return _.get(row, 'purchaseOrder.vendor.name', '');
  };

  const getCarrierName = () => {
    let carrierId: number | null = null;
    if (row.salesOrder) {
      carrierId = row.salesOrder.carrierId;
    } else if (row.purchaseOrder) {
      carrierId = row.purchaseOrder.carrierId;
    }

    const carrier = carriers.find((c) => c.id === carrierId);
    return carrier ? carrier.name : null;
  };

  const getCustomerNumber = (): string | null => {
    if (row.salesOrder) {
      return row.salesOrder.customerPONumber;
    }

    return _.get(row, 'purchaseOrder.customerSONumber');
  };

  const getVendorNumber = (): string | null => {
    if (row.salesOrder) {
      return row.salesOrder.vendorPONumber;
    }

    return _.get(row, 'purchaseOrder.vendorSONumber');
  };

  return (
    <>
      <TableCell>{row.number}</TableCell>
      {detailsCardOpened ? (
        <FBOStatusDot status={row.status || DefaultStatus.Transparent} />
      ) : (
        <TableCell>
          <StatusLabel status={row.status} />
        </TableCell>
      )}
      <TableCell>{getCustomerOrVendor()}</TableCell>
      {!detailsCardOpened && (
        <>
          <DateTooltip values={tooltipDates}>
            <TableCell>{dateValue}</TableCell>
          </DateTooltip>
          <TableCell>{getCarrierName()}</TableCell>
          <TableCell>{getCustomerNumber()}</TableCell>
          <TableCell>{getVendorNumber()}</TableCell>
        </>
      )}
    </>
  );
};

export default memo(ReceivingRow);
