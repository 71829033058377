import { BundleItem } from 'services/items';
import { DiscountTypes } from 'services/salesOrders';

export const calculateBundleItemTotalPrice = (
  bundleItem: BundleItem,
  saleItemPrice: number
) => {
  switch (bundleItem.discountType) {
    case DiscountTypes.FlatRate:
      return (
        saleItemPrice * (bundleItem.quantity || 0) -
        (bundleItem.discountFlatAmount || 0)
      );
    case DiscountTypes.Percent:
      return (
        saleItemPrice *
        (1 - (bundleItem.discountPercent || 0)) *
        (bundleItem.quantity || 0)
      );
    default:
      return (bundleItem.price || 0) * (bundleItem.quantity || 0);
  }
};
