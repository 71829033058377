import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getSettingsCompany } from 'services/settings/company';
import { Box, Typography } from '@mui/material';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { BackgroundStatus, DownloadHistoryRow } from 'services/backgroundTasks';
import { useZonedDate } from 'helpers';

import { useDownloadsHistoryPageStyle } from './styled';

const HistoryCustomRow: React.FC<RenderCustomRowProps<DownloadHistoryRow>> = (
  props
) => {
  const { row, onAction } = props;

  const classes = useDownloadsHistoryPageStyle(props);

  const selector = useSelector(getSettingsCompany);

  const timezone = selector.timezone as string;

  const handleHistoryRowClick = useCallback(() => {
    if (
      !onAction ||
      (row.status !== BackgroundStatus.Failed &&
        row.status !== BackgroundStatus.PartialSuccess)
    ) {
      return;
    }
    onAction(row);
  }, [onAction, row]);

  return (
    <Box
      key={`${row.id}-${row.fileName}`}
      className={classes.card}
      onClick={handleHistoryRowClick}
    >
      <Box className={classes.cardRow}>
        <Box className={classes.cardContent}>
          <Typography variant="body1" color="textPrimary">
            <b>{row.fileName}</b>
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {row.type} • {useZonedDate(new Date(row.dateCreated), timezone)}
          </Typography>
        </Box>
        <Typography variant="body1" color="textPrimary">
          {row.status}
        </Typography>
      </Box>
    </Box>
  );
};

export default memo(HistoryCustomRow);
