import { TaxRate } from 'services/taxRates';

import { RowDescriptor, RowType } from '../Card';

export const purchaseOrderCardId = 'purchase-order-card-navigation-id';

export const title = 'Purchase Order';

export const purchaseOrderRowDescriptor = (
  taxRates: TaxRate[] = []
): RowDescriptor[] => [
  {
    type: RowType.Text,
    title: 'Next order number',
    field: 'nextNumber',
    dataQa: 'purchase-order-next-order-number',
  },
  {
    type: RowType.Boolean,
    title: 'Enable over receiving',
    field: 'enableOverReceiving',
    dataQa: 'purchase-order-enable-over-receiving',
  },

  {
    type: RowType.Boolean,
    title: 'Cost includes tax',
    field: 'costIncludesTax',
  },
  {
    type: RowType.Autocomplete,
    title: 'Purchase tax',
    field: 'purchaseTax',
    options: taxRates,
    getOptionsLabel: (option: TaxRate) => option.name || 'Unknown tax',
    isOptionEqualToValue: (option: TaxRate, value: TaxRate) =>
      option.id === value.id,
    handleAutocompleteChange: (e, value, field, setCardData) => {
      setCardData('purchaseTax', value);
      setCardData('purchaseTaxId', value ? value.id : null);
    },
  },
  {
    type: RowType.Boolean,
    title: 'Only show vendor items in drop down filter',
    field: 'onlyShowVendorPartsInThePartDropDownFilter',
    dataQa: 'only-show-vendor-part-in-drop-down-filter',
  },
  {
    type: RowType.Boolean,
    title: 'Send email when purchase order is issued',
    field: 'promptToSendEmailOnPoIssue',
    dataQa: 'send-email-when-purchase-order-is-issued',
  },

  {
    type: RowType.Text,
    title: 'Report footer',
    textbox: true,
    field: 'reportFooter',
    dataQa: 'report-footer',
  },
];
