import React from 'react';
import NumberFormat from 'react-number-format';
import { noop } from 'lodash';
import { useSelector } from 'react-redux';

import { useTextFieldNoBorder } from '../TextField/styled';
import { activeUserHasPermission } from 'services/user/redux';

import { CustomNumberProps } from './types';

const NumberFormatCustom = (props: CustomNumberProps) => {
  const {
    inputRef,
    onChange,
    name,
    activeType,
    allowNegative,
    readOnly,
    permissions = [],
    decimalPlaces = 2,
    ...other
  } = props;

  const { notchedOutline } = useTextFieldNoBorder();

  const hasPermission = useSelector(activeUserHasPermission(permissions));

  const prefix = activeType.type === 'prefix' ? activeType.symbol : '';
  const suffix = activeType.type === 'suffix' ? activeType.symbol : '';

  const isReadonly = !!readOnly || !hasPermission;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      readOnly={isReadonly}
      // Lint skip to be removed
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      classes={isReadonly ? { notchedOutline } : {}}
      onValueChange={(values) => {
        // this fixes the bug Maximum update depth exceeded
        if (props.value === values.value) {
          return;
        }
        onChange({
          target: {
            value: values.value,
            name,
          },
          persist: noop,
        });
      }}
      thousandSeparator={true}
      decimalSeparator="."
      allowNegative={!!allowNegative}
      isNumericString
      decimalScale={decimalPlaces}
      fixedDecimalScale
      prefix={prefix}
      suffix={suffix}
    />
  );
};

export default NumberFormatCustom;
