import { Vendor, VendorItem } from './types';
import { DUE_ON_RECEIPT_ID } from 'services/paymentTerms';

export const initialVendor: Vendor = {
  id: null,
  acctNum: null,
  accountingClassId: null,
  addresses: [],
  alertNotes: null,
  bcc: null,
  cc: null,
  currencyId: null,
  customExchangeRate: null,
  customFields: [],
  dateCreated: null,
  dateLastModified: null,
  defaultCarrierId: null,
  defaultCarrierServiceId: null,
  deleted: false,
  documents: [],
  email: null,
  fax: null,
  mobile: null,
  name: null,
  notes: null,
  onlyShowVendorItemsOnPo: false,
  other: null,
  paymentTerm: [],
  paymentTermId: DUE_ON_RECEIPT_ID,
  office: null,
  taxable: false,
  tags: [],
  url: null,
  vendorItems: [],
  vendorMin: null,
  version: null,
  currency: null,
};

export const initialVendorItem: VendorItem = {
  id: null,
  cost: null,
  dateCreated: null,
  dateLastModified: null,
  defaultFlag: true,
  defaultUomId: null,
  deleted: false,
  item: null,
  itemId: null,
  leadTime: null,
  multicurrencyVendorItemCost: null,
  minimumQuantity: null,
  name: null,
  vendor: null,
  vendorId: null,
  version: null,
};
