import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { TableCell, Box } from '@mui/material';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { ImageItemBox } from 'ui/components/Image/ImageItemBox';
import { VendorAutocomplete } from 'ui/components/Autocomplete/VendorAutocomplete';
import { getVendors, Vendor, VendorItem } from 'services/vendors';
import { filterItemUoms, ItemReorderPoint } from 'services/items';
import {
  InventoryLocationSummary,
  itemHasSerialTracking,
} from 'services/inventory';
import { TextFieldQuantity } from 'ui/components/TextField/TextFieldQuantity';
import { getUoms } from 'services/uoms';
import { resolveQuantity } from '../../helpers';

const ItemsStepRow: React.FC<RenderCustomRowProps> = (props) => {
  const {
    row,
    setData,
    index,
    errors,
    meta: { selectedLocation },
  } = props;

  const { items: vendors } = useSelector(getVendors);
  const { items: defaultUoms } = useSelector(getUoms);

  const item = row.item;

  const itemUomAbbreviation = _.get(item, 'defaultUom.abbreviation', '');

  const itemUoms = filterItemUoms(row.defaultUom, defaultUoms);

  const selectedVendor = useMemo(() => {
    if (row.vendorId) {
      return vendors.find((v) => v.id === row.vendorId) || null;
    }
    return null;
  }, [row, vendors]);

  const inventoryLocationSummary: InventoryLocationSummary | null = useMemo(
    () =>
      item.inventoryLocationSummaryList[0]
        ? item.inventoryLocationSummaryList[0]
        : null,
    [item.inventoryLocationSummaryList]
  );

  const reorderPoint: ItemReorderPoint | null = useMemo(
    () => (item.reorderPointsList[0] ? item.reorderPointsList[0] : null),
    [item.reorderPointsList]
  );

  const handleAmountFieldChange = (value: number | null) => {
    setData({ ...row, quantity: value ?? null }, index);
  };

  const handleAmountMenuChange = (nextUomId: number) => {
    const itemUom = itemUoms.find((uom) => uom.id === nextUomId);
    setData({ ...row, uomId: nextUomId || null, uom: itemUom }, index);
  };

  const handleVendorChange = useCallback(
    (vendor: Vendor | null) => {
      const vendorItem = item?.vendorItems?.find(
        (vi: VendorItem) => vi.vendorId === vendor?.id
      );
      const itemUom = itemUoms.find(
        (uom) => uom.id === vendorItem?.defaultUomId
      );

      setData(
        {
          ...row,
          vendorId: vendor ? vendor.id : null,
          ...(vendorItem
            ? { uomId: vendorItem.defaultUomId || null, uom: itemUom }
            : { uomId: row?.defaultUom?.id || null, uom: row.defaultUom }),
        },
        index
      );
    },
    [row, index, setData, selectedLocation?.id]
  );

  useEffect(() => {
    const updatedQuantity = resolveQuantity(row, selectedLocation?.id);
    setData(
      {
        ...row,
        quantity: updatedQuantity,
      },
      index
    );
  }, [row.vendorId, selectedLocation?.id, row.uomId, index]);

  const isSerialTracked = itemHasSerialTracking(item);

  return (
    <>
      <TableCell>
        <Box>
          <ImageItemBox
            source={_.get(item, 'defaultImage', null)}
            name={_.get(item, 'name', null)}
            description={_.get(item, 'description', null)}
            dataQa="auto-order"
          />
        </Box>
      </TableCell>
      <TableCell data-qa="auto-order-onhand">
        <Box>
          {inventoryLocationSummary
            ? ` ${inventoryLocationSummary.totalQty}`
            : ''}
        </Box>
      </TableCell>
      <TableCell data-qa="auto-order-allocated">
        <Box>
          {inventoryLocationSummary
            ? ` ${
                inventoryLocationSummary.allocatedOnPurchaseOrders +
                inventoryLocationSummary.allocatedOnSalesOrders
              }`
            : ''}
        </Box>
      </TableCell>
      <TableCell data-qa="auto-order-available">
        <Box>
          {inventoryLocationSummary
            ? ` ${inventoryLocationSummary.availableQty}`
            : ''}
        </Box>
      </TableCell>

      <TableCell data-qa="auto-order-incoming">
        <Box>
          {inventoryLocationSummary
            ? ` ${
                inventoryLocationSummary.qtyIncomingOnPurchaseOrders +
                inventoryLocationSummary.qtyIncomingOnSalesOrders
              }`
            : ''}
        </Box>
      </TableCell>
      <TableCell data-qa="auto-order-min">
        <Box>{reorderPoint ? reorderPoint.min : '-'}</Box>
      </TableCell>
      <TableCell data-qa="auto-order-max">
        <Box>{reorderPoint ? reorderPoint.max : '-'}</Box>
      </TableCell>
      <TableCell data-qa="auto-order-UOM">
        <Box>{itemUomAbbreviation}</Box>
      </TableCell>
      <TableCell>
        <Box>
          <TextFieldQuantity
            placeholder="Quantity"
            name="quantity"
            className="redesign"
            value={row.quantity}
            selectedUomId={row.uomId}
            onTextChange={handleAmountFieldChange}
            onMenuChange={handleAmountMenuChange}
            fullWidth
            isDecimal={!isSerialTracked}
            uoms={itemUoms}
            dataQa="auto-order-to-order-value"
          />
        </Box>
      </TableCell>
      <TableCell>
        <Box>
          <VendorAutocomplete
            value={selectedVendor}
            onChange={handleVendorChange}
            error={!!errors.vendorId}
            dataQa="auto-order-vendor"
          />
        </Box>
      </TableCell>
    </>
  );
};

export default memo(ItemsStepRow);
