import { IconNames } from 'ui/theme';
import { MenuItem, MenuItemVariant } from 'ui/components/Menu';

import { SaleItemTableRowActions } from '../../consts';

export const createActionBarOptions = (
  onAction: (type: SaleItemTableRowActions) => void
): MenuItem[] => [
  {
    label: 'Set as Default',
    onOptionClick: () => onAction(SaleItemTableRowActions.Default),
    icon: IconNames.FBOCircleCheck,
    separateAfter: true,
  },
  {
    label: 'Delete Item',
    onOptionClick: () => onAction(SaleItemTableRowActions.Delete),
    icon: IconNames.FBODeleteSmall,
    dataQa: 'sale-item-menu-delete',
    variant: MenuItemVariant.Danger,
  },
];
