import React, { memo } from 'react';
import { Box } from '@mui/material';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';

import { useImageStyles } from './styled';
import { ImageItemProps } from './types';
import { Icon } from 'ui/components/Icon';

const ImageItem: React.FC<ImageItemProps> = (props) => {
  const {
    source,
    alt = 'Image',
    loading = 'lazy',
    sourceSize = 'small',
    onClick,
    iconSource,
  } = props;

  const classes = useImageStyles(props);
  const resolvedSource = source + `?size=${sourceSize}`;

  if (!source) {
    return iconSource ? (
      <Icon name={iconSource} />
    ) : (
      <Box className={classes.noImage}>
        <ImageOutlinedIcon className={classes.noImageIcon} />
      </Box>
    );
  }

  return (
    <img
      alt={alt}
      className={classes.imageStyle}
      loading={loading}
      src={resolvedSource}
      onClick={onClick}
    />
  );
};

export default memo(ImageItem);
