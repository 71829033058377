import { roundToDecimals } from 'helpers';

export const percentageChange = (previous: number, latest: number) => {
  if (latest === previous) {
    return 0;
  }

  if (latest === 0) {
    return -100;
  }

  return ((latest - previous) / latest) * 100;
};

export const formatToPercentage = (percentage: number) => {
  const roundedPercentage = roundToDecimals(percentage, 2);
  return (roundedPercentage < 0 ? '' : '+') + roundedPercentage + '%';
};
