import React, { useEffect, useMemo, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { useSelector } from 'react-redux';
import { noop } from 'lodash';

import { getSettingsCompany } from 'services/settings/company';

import { CurrencyFieldProps, CurrencyFormatProps } from './types';
import { TextField } from '../TextField';
import { useTextFieldNoBorder } from '../TextField/styled';

import { useGetCurrencySymbol } from 'helpers';

const resolveTrailingZeros = (value: any) => {
  if (!value && value !== 0) {
    return '';
  }

  let stringValue = String(value * 1);
  if (!stringValue.split('.')[1]) {
    stringValue += '.00';
  } else if (stringValue.split('.')[1].length === 1) {
    stringValue += '0';
  } else {
    // remove trailing zeroes if more than 2 decimal places
    stringValue = String(Number(stringValue));
  }

  return stringValue;
};

const CurrencyFormat = (props: CurrencyFormatProps) => {
  const {
    currency,
    decimalPlaces,
    inputRef,
    onChange,
    allowNegative,
    name,
    onBlur,
    ...other
  } = props;

  const onCurrencyFormatChange = (value: string) => {
    onChange({
      target: {
        value,
        name,
      },
      persist: noop,
    });
  };

  const prefix = currency
    ? useGetCurrencySymbol(currency)
    : useGetCurrencySymbol();

  return (
    <CurrencyInput
      {...other}
      onValueChange={onCurrencyFormatChange as any}
      allowNegativeValue={allowNegative}
      ref={inputRef}
      prefix={prefix}
      decimalsLimit={decimalPlaces}
      onBlur={() => {
        onCurrencyFormatChange(resolveTrailingZeros(props.value));
        onBlur();
      }}
    />
  );
};

const CurrencyField = (props: CurrencyFieldProps) => {
  const { notchedOutline } = useTextFieldNoBorder();
  const {
    currencyCode,
    decimalPlaces = 2,
    allowNegative = false,
    value,
    readOnly,
    dataQa,
    noMargin,
    ...other
  } = props;

  const { homeCurrency } = useSelector(getSettingsCompany);

  const currency = useMemo(() => {
    return currencyCode || (homeCurrency && homeCurrency.code) || 'USD';
  }, [currencyCode, homeCurrency]);

  const [resolvedValue, setResolvedValue] = useState<string>('');
  const [isFocused, setIsFocused] = useState<boolean>(false);

  useEffect(() => {
    if (isFocused) {
      // we are using this ternary to resolve null
      setResolvedValue(!value ? '' : `${value}`);
      return;
    }

    if (!isFocused) {
      setResolvedValue(resolveTrailingZeros(value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <TextField
      className={noMargin ? 'redesign no-margin' : 'redesign'}
      variant="standard"
      value={resolvedValue}
      type="text"
      InputProps={{
        classes: readOnly ? { notchedOutline } : {},
        readOnly: readOnly,
        inputComponent: CurrencyFormat as any,
      }}
      inputProps={{
        currency,
        decimalPlaces,
        allowNegative,
        'data-qa': dataQa,
      }}
      onBlur={() => setIsFocused(false)}
      onFocus={() => setIsFocused(true)}
      {...other}
    />
  );
};

export default CurrencyField;
