import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const ITEMS_QUANTITY_COLUMNS: Column[] = [
  {
    title: 'Order Number',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Ordered Quantity',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Order Status',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Order Location',
    type: ColumnTypes.renderByCustomRow,
  },
];

export const ITEMS_QUANTITY_COLUMNS_COMMITTED: Column[] = [
  {
    title: 'Order Number',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Ordered Quantity',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Commited Quantity',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Order Status',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Order Location',
    type: ColumnTypes.renderByCustomRow,
  },
];

export const ITEMS_QUANTITY_COLUMNS_ALLOCATION: Column[] = [
  {
    title: 'Order Number',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Ordered Quantity',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Allocated Quantity',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Order Status',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Order Location',
    type: ColumnTypes.renderByCustomRow,
  },
];
