import { PickItem, PickItemStatus } from 'services/picking';

export const shouldDisableStart = (pickStatus: PickItemStatus) =>
  pickStatus === PickItemStatus.Started ||
  pickStatus === PickItemStatus.Committed ||
  pickStatus === PickItemStatus.Finished ||
  pickStatus === PickItemStatus.Hold;

export const shouldDisableCommit = (pickItem: PickItem) =>
  pickItem.status === PickItemStatus.Committed ||
  pickItem.status === PickItemStatus.Finished ||
  pickItem.status === PickItemStatus.Hold ||
  (pickItem.status === PickItemStatus.Short &&
    pickItem.quantityAvailable === 0);

export const shouldDisableVoid = (pickStatus: PickItemStatus) =>
  pickStatus === PickItemStatus.Available;

export const shouldDisableFinish = (pickItem: PickItem) =>
  pickItem.status === PickItemStatus.Finished ||
  pickItem.status === PickItemStatus.Hold ||
  (pickItem.status === PickItemStatus.Short &&
    pickItem.quantityAvailable === 0);

export const shouldDisabledHold = (pickStatus: PickItemStatus) =>
  pickStatus !== PickItemStatus.Available;

export const displayStatus = (
  pickItem: PickItem,
  quantityAvailableToPick: number
) => {
  switch (pickItem.status) {
    case PickItemStatus.Available:
      if (pickItem?.quantity && pickItem?.quantity <= quantityAvailableToPick) {
        return PickItemStatus.Available;
      } else {
        return PickItemStatus.Short;
      }
    case PickItemStatus.Started:
      if (pickItem?.quantity && pickItem?.quantity <= quantityAvailableToPick) {
        return PickItemStatus.Started;
      } else {
        return PickItemStatus.Short;
      }
    case PickItemStatus.Hold:
      if (pickItem?.quantity && pickItem?.quantity <= quantityAvailableToPick) {
        return PickItemStatus.Hold;
      } else {
        return PickItemStatus.Short;
      }
    case PickItemStatus.Committed:
      return PickItemStatus.Committed;
    case PickItemStatus.Finished:
      return PickItemStatus.Finished;
    case PickItemStatus.Short:
      return PickItemStatus.Short;
  }
};
