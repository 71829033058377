import { combineReducers } from 'redux';

import {
  chainReducers,
  INITIAL_API_STATE,
  createApiReducer,
} from 'services/api';
import {
  createAddSearchReducer,
  createDeleteSearchReducer,
  createFetchSearchReducer,
  createUpdateSearchReducer,
} from 'services/search';

import { transformPermissionGroup } from '../transformations';
import { PermissionGroupsActionTypes } from './actions';

const fetchPermissionGroupssReducer = createApiReducer(
  [
    PermissionGroupsActionTypes.FETCH_PERMISSION_GROUPS_START,
    PermissionGroupsActionTypes.FETCH_PERMISSION_GROUPS_SUCCESS,
    PermissionGroupsActionTypes.FETCH_PERMISSION_GROUPS_FAILURE,
  ],
  (data) => ({ items: data.results.map(transformPermissionGroup) }),
  (data) => data
);

const permissionGroupsChainedReducers = chainReducers(
  INITIAL_API_STATE,
  fetchPermissionGroupssReducer
);

const fetchSearchReducer = createFetchSearchReducer('permissionGroups');
const addSearchReducer = createAddSearchReducer('permissionGroups');
const updateSearchReducer = createUpdateSearchReducer('permissionGroups');
const deleteSearchReducer = createDeleteSearchReducer('permissionGroups');

const searchChainedReducers = chainReducers(
  INITIAL_API_STATE,
  fetchSearchReducer,
  addSearchReducer,
  updateSearchReducer,
  deleteSearchReducer
);

export default combineReducers({
  permissionGroups: permissionGroupsChainedReducers,
  searches: searchChainedReducers,
});
