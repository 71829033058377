import React, { useCallback, useMemo } from 'react';
import { TableCell } from '@mui/material';
import _ from 'lodash';

import { IconNames } from 'ui/theme';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import {
  initialPurchaseOrder,
  PurchaseOrderItem,
  PurchaseOrderItemStatus,
  PurchaseOrderItemTypes,
  PurchaseOrderStatus,
} from 'services/purchaseOrders';
import { MenuItem, MenuItemVariant } from 'ui/components/Menu';

import { ThreeDotMenuActions } from '../../types';
import { editPurchaseOrderPermissions } from '../../../../helpers';
import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';

const MenuTableCell: React.FC<RenderCustomRowProps<PurchaseOrderItem>> = (
  props
) => {
  const { row, onAction, meta, index } = props;

  const selectedPurchaseOrder = _.get(meta, 'purchaseOrder', null);
  const lastIndex = _.get(meta, 'lastNonDeletedPoItemIndex');

  const editPermission = editPurchaseOrderPermissions(
    _.get(meta, 'purchaseOrder', initialPurchaseOrder)
  );

  const disableMenu =
    selectedPurchaseOrder.status === PurchaseOrderStatus.Fulfilled;

  const disabledPurchaseOrderItem = useMemo(
    () => selectedPurchaseOrder.status === PurchaseOrderStatus.Issued,
    [selectedPurchaseOrder.status]
  );

  const canFulfill =
    row.id !== null &&
    row.id > 0 &&
    (row.purchaseOrderItemType === PurchaseOrderItemTypes.MiscCredit ||
      row.purchaseOrderItemType === PurchaseOrderItemTypes.Note);

  const handleAction = useCallback(
    (type: ThreeDotMenuActions) => () => {
      if (onAction) {
        onAction({ type, payload: row });
      }
    },
    [onAction, row]
  );

  const menuActions: MenuItem[] = [
    {
      icon: IconNames.FBOArrowUp,
      label: 'Move Up',
      disabled: index === 0 || disabledPurchaseOrderItem,
      onOptionClick: handleAction(ThreeDotMenuActions.MoveUp),
      separateAfter: true,
    },
    {
      icon: IconNames.FBOArrowDown,
      label: 'Move Down',
      separateAfter: true,
      disabled: index === lastIndex || disabledPurchaseOrderItem,
      onOptionClick: handleAction(ThreeDotMenuActions.MoveDown),
    },
    {
      icon: IconNames.FBOReportSmall,
      label: 'Fulfill',
      disabled: !canFulfill || row.status === PurchaseOrderItemStatus.Fulfilled,
      onOptionClick: handleAction(ThreeDotMenuActions.FulFill),
      separateAfter: true,
    },
    {
      icon: IconNames.FBODuplicateSmall,
      label: 'Duplicate',
      onOptionClick: handleAction(ThreeDotMenuActions.Duplicate),
      separateAfter: true,
    },
    {
      icon: IconNames.FBODuplicateSmall,
      label: 'Duplicate To Credit Return',
      disabled:
        row.purchaseOrderItemType !== PurchaseOrderItemTypes.Purchase &&
        row.purchaseOrderItemType !== PurchaseOrderItemTypes.MiscPurchase,
      onOptionClick: handleAction(ThreeDotMenuActions.DuplicateToCreditReturn),
      separateAfter: true,
    },
    {
      icon: IconNames.FBODeleteSmall,
      label: 'Remove',
      disabled: disabledPurchaseOrderItem,
      onOptionClick: handleAction(ThreeDotMenuActions.Remove),
      variant: MenuItemVariant.Danger,
    },
  ];

  return (
    <TableCell padding="none" align="center">
      <FBOMenuButton
        variant="tertiary"
        size="small"
        items={menuActions}
        data-qa="purchase-order-items-three-dot-menu"
        permissions={editPermission}
        disabled={disableMenu}
      />
    </TableCell>
  );
};

export default MenuTableCell;
