import React, { memo, useCallback, useMemo } from 'react';
import { TableCell, Typography, Link } from '@mui/material';
import _ from 'lodash';

import { Column } from 'ui/components/Table/ItemsTable/types';
import { TrackingType, TrackingDataTypes } from 'services/settings/tracking';

import { TrackingColumnsProps } from '../../types';
import { getTrackingTypeColumns } from '../../helpers';
import { formatDatePreview, useGetIntlDateFormatString } from 'helpers';
import { getSettingsCompanyCountry } from 'services/settings/company';
import { useSelector } from 'react-redux';

const TrackingViewRow: React.FC<TrackingColumnsProps> = (props) => {
  const { trackingGroup, onSerialLinkClick, columns, itemUom } = props;
  const intlFormatDate = useGetIntlDateFormatString();
  const companyCountry = useSelector(getSettingsCompanyCountry);
  const trackingTypeColumns = useMemo(
    () => getTrackingTypeColumns(columns),
    [columns]
  );

  // if in picking, show commited quantity. In receiving committed qty is 0
  const quantity = trackingGroup.committedQuantity || trackingGroup.onHand || 0;
  const handleLinkClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      onSerialLinkClick(e);
    },
    [onSerialLinkClick]
  );

  const renderTrackingColumn = (column: Column): JSX.Element | null => {
    const trackingDataType: TrackingDataTypes = _.get(
      column,
      'meta.trackingDataType'
    );
    const trackingType: TrackingType = _.get(column, 'meta.trackingType', {});
    const trackingTypeIndex = trackingGroup.trackingInfoList.findIndex(
      (t) => t.trackingTypeId === trackingType.id
    );
    const trackingInfo = trackingGroup.trackingInfoList[trackingTypeIndex];

    switch (trackingDataType) {
      case TrackingDataTypes.Text:
        return <Typography>{trackingInfo?.value}</Typography>;
      case TrackingDataTypes.Date: {
        const formattedDateValue = formatDatePreview(
          trackingInfo?.value,
          companyCountry,
          intlFormatDate
        );
        return <Typography>{formattedDateValue}</Typography>;
      }
      case TrackingDataTypes.SerialNumber:
        return (
          <Link
            href="#"
            underline="always"
            onClick={handleLinkClick}
            data-qa="serial"
          >
            View Serials
          </Link>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <TableCell>
        <Typography>
          {quantity}
          {` ${itemUom ? itemUom.abbreviation : ''}`}
        </Typography>
      </TableCell>
      {trackingTypeColumns.map((column: Column, index: number) => (
        <TableCell key={index}>{renderTrackingColumn(column)}</TableCell>
      ))}
    </>
  );
};

export default memo(TrackingViewRow);
