import { createApiAction } from 'services/api';
import {
  StartAction,
  SuccessAction,
  FailureAction,
} from 'services/api/redux/types';
import {
  createAddSearch,
  createDeleteSearch,
  createFetchSearch,
  createUpdateSearch,
} from 'services/search';

export enum PermissionGroupsActionTypes {
  FETCH_PERMISSION_GROUPS_START = 'permission_groups/fetch_permission_groups_start',
  FETCH_PERMISSION_GROUPS_SUCCESS = 'permission_groups/fetch_permission_groups_success',
  FETCH_PERMISSION_GROUPS_FAILURE = 'permission_groups/fetch_permission_groups_failure',
}

const fetchPermissionGroupsActionStart = (): StartAction => ({
  type: PermissionGroupsActionTypes.FETCH_PERMISSION_GROUPS_START,
  payload: null,
});

const fetchPermissionGroupsActionSuccess = (payload: any): SuccessAction => {
  return {
    type: PermissionGroupsActionTypes.FETCH_PERMISSION_GROUPS_SUCCESS,
    payload,
  };
};

const fetchPermissionGroupsActionFailure = (error: string): FailureAction => ({
  type: PermissionGroupsActionTypes.FETCH_PERMISSION_GROUPS_FAILURE,
  payload: { error },
});

export const fetchPermissionGroups = () =>
  createApiAction(
    {
      path: '/v1/access_groups',
      method: 'GET',
    },
    fetchPermissionGroupsActionStart,
    fetchPermissionGroupsActionSuccess,
    fetchPermissionGroupsActionFailure
  );

// SEARCH ACTION CREATORS

export const fetchSearch = createFetchSearch('permissionGroups');
export const addSearch = createAddSearch('permissionGroups');
export const updateSearch = createUpdateSearch('permissionGroups');
export const deleteSearch = createDeleteSearch('permissionGroups');
