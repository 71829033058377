import { EnhancedBodyProps, DataWithId } from './components/types';
import { Pagination } from 'services/search';
import { Errors } from 'services/forms/validation';
import { TableCellProps } from '@mui/material';
import { CSSProperties } from 'react';

export interface ItemsTableProps<T extends DataWithId = any>
  extends Omit<
      EnhancedBodyProps<T>,
      | 'selectedItems'
      | 'onSelectItemClick'
      | 'isTableEmpty'
      | 'columnProperties'
    >,
    RenderCustomFooterProps {
  RenderCustomFooter?: React.FC<RenderCustomFooterProps<T>>;
  RenderCustomRowFooter?: any;
  onSelectedChange?: (selected: number[] | number) => void;
  selectedItems?: number[];
  containerHeight?: number;
  customFooterHeight?: number;
  dataQa?: string;
  size?: 'medium' | 'small';
  tableLayoutFixed?: boolean;
  onScrollNextPage?: () => void;
  hideFooter?: boolean;
  showSelectedCount?: boolean;
  sx?: React.CSSProperties;
}

export interface RenderCustomRowProps<T = any> {
  columns: Column[];
  row: T;
  setData(data: T, index: number): void;
  classes: any;
  index: number;
  errors: Errors;
  onAction?(action: any, row?: any): void;
  meta?: any;
  tableBordered?: boolean;
  selectedItems?: number;
  dataQa?: string;
}

export interface RenderCustomRowAddProps<T = any> {
  row: T;
  setData(data: T): void;
  onAction?(action: any): void;
}

export interface RenderCustomFooterProps<T = any, D = any> {
  footerData?: T;
  onFooterAction?: D;
  pagination?: Pagination;
  onPaginationChange?: (pagination: Pagination) => void;
  selectedItemsData?: T;
  showEnhancedTablePaginationActions?: boolean;
  isLoading?: boolean;
}

export interface SelectedItemsDataProps {
  pagination: number | undefined;
  selected: number;
  showSelected: boolean;
}

interface ColumnBase {
  title: string;
  align?: TableCellProps['align'];
  width?: CSSProperties['width'];
  fitWidth?: boolean;
  searchable?: boolean;
  sortable?: boolean;
  field?: string;
  dataQa?: string;
}

interface StringColumn extends ColumnBase {
  type: ColumnTypes.string;
  field: string;
}

interface ImageColumn extends ColumnBase {
  type: ColumnTypes.image;
  field: string;
}

interface BooleanColumn extends ColumnBase {
  type: ColumnTypes.boolean;
  field: string;
}

interface ButtonColumn extends ColumnBase {
  type: ColumnTypes.button;
  buttonConfig: ButtonConfig;
}

interface CustomColumn extends ColumnBase {
  type: ColumnTypes.custom;
  render(row: any): any;
}

interface RenderByRow extends ColumnBase {
  type: ColumnTypes.renderByCustomRow;
  meta?: any;
}

export type Column =
  | StringColumn
  | ImageColumn
  | BooleanColumn
  | ButtonColumn
  | CustomColumn
  | RenderByRow;

export enum ColumnTypes {
  string = 'string',
  image = 'image',
  boolean = 'boolean',
  button = 'button',
  custom = 'custom',
  renderByCustomRow = 'renderedByCustomRow',
}

interface ButtonConfig {
  text: string;
  action: (row: any) => void;
}

export interface ItemsTableContainerProps {
  isFooterVisible: boolean;
  customFooterHeight: number | undefined;
  containerHeight: number | undefined;
}

export interface ItemsTableFooterProps {
  customFooterHeight: number | undefined;
}

export interface ColumnProperty {
  title: string;
  width: CSSProperties['width'];
  align: TableCellProps['align'];
  sortable: boolean;
  field: string;
  fitWidth: boolean;
}

export interface ItemsTableStyleProps
  extends Partial<
    Pick<ItemsTableContainerProps, 'customFooterHeight' | 'containerHeight'>
  > {}
