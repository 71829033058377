import React, { useCallback, useEffect, useState, useMemo, memo } from 'react';
import _ from 'lodash';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';

import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import {
  COUNTRIES,
  CountryAutocomplete,
} from 'ui/components/Autocomplete/CountryAutocomplete';
import { useHandleTextFieldChange } from 'services/forms/hooks';
import {
  Address,
  BillToShipToAddress,
  initialBillToShipToAddress,
} from 'services/addresses';
import { validateYup } from 'services/forms/validation';
import { VerifiedAddressLabel } from 'ui/components/Modal/AddressModal/components';
import {
  StateAutocomplete,
  STATES,
} from 'ui/components/Autocomplete/StateAutocomplete';
import { postCustomerAddress } from 'services/customers';
import { yupAddressSchema } from 'services/addresses/validations';
import { postVendorAddress } from 'services/vendors';

import { AddressModalProps } from './types';
import { logErrorCtx } from 'app/logging';

const AddressModal: React.FC<AddressModalProps> = ({
  address,
  modalVisible,
  onCancel,
  onSave,
  editMode,
  dataQa,
  customerId = null,
  vendorId = null,
}) => {
  const [validationErrors, setValidationErrors] = useState<any>({});
  const [createAddress, setCreateAddress] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<BillToShipToAddress>(
    initialBillToShipToAddress
  );

  // Update From values when modal becomes visible
  useEffect(() => {
    if (modalVisible) {
      setFormValues(address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible]);

  const handleResetClick = useCallback(() => {
    setFormValues(address);
    setValidationErrors({});
  }, [address]);

  const activeCountry = useMemo(() => {
    return COUNTRIES.find((c) => c.code === formValues.country) || null;
  }, [formValues]);

  const activeState = useMemo(() => {
    return STATES.find((s) => s.abbreviation === formValues.state) || null;
  }, [formValues]);

  const handleTextFieldChange = useHandleTextFieldChange(setFormValues, {
    ...formValues,
    verified: false,
  });

  const handleNonVerifiedTextFieldChange = useHandleTextFieldChange(
    setFormValues,
    {
      ...formValues,
      verified: true,
    }
  );

  const handleResidentialChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setFormValues((old) => ({
        ...old,
        residential: checked,
        verified: false,
      }));
    },
    [setFormValues]
  );

  const handleCountryChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, v: any) => {
      setFormValues({
        ...formValues,
        country: _.get(v, 'code', ''),
        verified: false,
      });
    },
    [formValues]
  );

  const handleStateChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, v: any) => {
      setFormValues({
        ...formValues,
        state: _.get(v, 'abbreviation', ''),
        verified: false,
      });
    },
    [formValues]
  );

  const handleCreateAddressChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setCreateAddress(checked);
    },
    [setCreateAddress]
  );

  const handleAddSaveClick = async () => {
    const isValid = validateYup(
      formValues,
      yupAddressSchema,
      setValidationErrors
    );

    if (!isValid) {
      return;
    }

    if (customerId && createAddress && !editMode) {
      try {
        await postCustomerAddress(customerId, formValues as Address);
      } catch (error) {
        logErrorCtx('Error in postCustomerAddress', {
          error: error as Error,
          stackTrace: (error as Error).stack,
          component: 'AddressModal',
          title: 'Error in postCustomerAddress',
          description: `Customer id ${customerId}`,
        });
      }
    }

    if (vendorId && createAddress && !editMode) {
      try {
        await postVendorAddress(vendorId, formValues as Address);
      } catch (error) {
        logErrorCtx('Error in postVendorAddress', {
          error: error as Error,
          stackTrace: (error as Error).stack,
          component: 'AddressModal',
          title: 'Error in postVendorAddress',
          description: `Vendor id ${vendorId}`,
        });
      }
    }

    onSave(formValues);
  };

  return (
    <Modal
      open={modalVisible}
      title={editMode ? 'Edit Address' : 'Custom Address'}
      onCancelClicked={onCancel}
      onApplyClicked={handleAddSaveClick}
      onResetClicked={handleResetClick}
      applyLabel={editMode ? 'Update' : 'Add'}
      maxWidth="md"
      dataQa="address-modal"
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="Address Nickname"
            placeholder="Enter nickname"
            name="name"
            autoComplete="off"
            fullWidth
            value={formValues.name}
            required
            onChange={
              formValues.verified
                ? handleNonVerifiedTextFieldChange
                : handleTextFieldChange
            }
            error={!!validationErrors.name}
            dataQa={`${dataQa}-address-name`}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="Company Name"
            placeholder="Enter company name"
            name="companyName"
            autoComplete="off"
            fullWidth
            value={formValues.companyName}
            onChange={
              formValues.verified
                ? handleNonVerifiedTextFieldChange
                : handleTextFieldChange
            }
            dataQa={`${dataQa}-address-company-name`}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="Attention"
            placeholder="Enter attention"
            name="attention"
            autoComplete="off"
            fullWidth
            value={formValues.attention}
            onChange={
              formValues.verified
                ? handleNonVerifiedTextFieldChange
                : handleTextFieldChange
            }
            dataQa={`${dataQa}-address-attention`}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="Street"
            placeholder="Enter street name"
            name="street"
            autoComplete="nope"
            fullWidth
            value={formValues.street}
            required
            onChange={handleTextFieldChange}
            error={!!validationErrors.street}
            dataQa={`${dataQa}-address-street`}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="Street 2"
            placeholder="Enter street name"
            name="street2"
            autoComplete="nope"
            fullWidth
            value={formValues.street2}
            onChange={handleTextFieldChange}
            error={!!validationErrors.street2}
            dataQa={`${dataQa}-address-street2`}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="City"
            placeholder="Enter city name"
            name="city"
            autoComplete="nope"
            fullWidth
            value={formValues.city}
            required
            onChange={handleTextFieldChange}
            error={!!validationErrors.city}
            dataQa={`${dataQa}-address-city`}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="Postal Code"
            placeholder="Enter postal code"
            name="postalCode"
            fullWidth
            value={formValues.postalCode}
            required
            onChange={handleTextFieldChange}
            error={!!validationErrors.postalCode}
            dataQa={`${dataQa}-address-postal-code`}
          />
        </Grid>
        <Grid item xs={2}>
          {formValues.country === 'US' ? (
            <StateAutocomplete
              value={activeState}
              onChange={handleStateChange}
              error={!!validationErrors.state}
              required
            />
          ) : (
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="State"
              placeholder="Enter state name"
              name="state"
              autoComplete="nope"
              fullWidth
              value={formValues.state}
              required
              onChange={handleTextFieldChange}
              error={!!validationErrors.state}
              dataQa={`${dataQa}-address-state`}
            />
          )}
        </Grid>
        <Grid item xs={4}>
          <CountryAutocomplete
            value={activeCountry}
            onChange={handleCountryChange}
            required
            error={!!validationErrors.country}
          />
        </Grid>
        <Grid item xs={(customerId || vendorId) && !editMode ? 4 : 8}>
          <FormControlLabel
            control={
              <Checkbox
                className="redesign"
                checked={Boolean(formValues.residential)}
                name="residential"
                color="primary"
                onChange={handleResidentialChanged}
                inputProps={
                  {
                    'data-qa': `${dataQa}-address-residential`,
                  } as any
                }
              />
            }
            label="Residential"
          />
        </Grid>
        {customerId && !editMode && (
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  className="redesign"
                  checked={createAddress}
                  name="addToCustomer"
                  color="primary"
                  onChange={handleCreateAddressChange}
                />
              }
              label="Add to Customer"
            />
          </Grid>
        )}
        {vendorId && !editMode && (
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  className="redesign"
                  checked={createAddress}
                  name="addToVendors"
                  color="primary"
                  onChange={handleCreateAddressChange}
                />
              }
              label="Add to Vendor"
            />
          </Grid>
        )}
        <Grid item xs={4}>
          {formValues.country === 'US' && (
            <VerifiedAddressLabel verifiedLabelVisible={formValues.verified} />
          )}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default memo(AddressModal);
