import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';

import { Modal } from 'ui/components/Modal/Modal';
import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { fetchLedgerSimple, initialLedgerSimple } from 'services/ledgers';
import { useGetIntlDateFormatString } from 'helpers';

import { LEDGER_ITEMS_COLUMNS } from './consts';
import { LedgerModalProps } from './types';
import CustomRowModal from './CustomRowModal';
import TableFooterModal from './TableFooterModal';
import { logErrorCtx } from 'app/logging';

const LedgerModal: React.FC<LedgerModalProps> = (props) => {
  const { activeLedgerItem, setActiveLedgerItem } = props;

  const [simpleLedger, setSimpleLedger] = useState(initialLedgerSimple);
  const [isLoadingContent, setIsLoadingContent] = useState(false);
  const intlDateFormat = useGetIntlDateFormatString();

  const modalTitle = useMemo(() => {
    if (activeLedgerItem) {
      const date = moment(activeLedgerItem.date || '').format(intlDateFormat);
      const type = activeLedgerItem.ledgerType;
      const lineNumber = activeLedgerItem.number;
      return `${date} ${type} ${lineNumber ? `${lineNumber}` : ''}`;
    }
  }, [activeLedgerItem, intlDateFormat]);

  useEffect(() => {
    if (!activeLedgerItem) {
      setSimpleLedger(initialLedgerSimple);
      return;
    }
    setIsLoadingContent(true);
    (async () => {
      try {
        const newSimpleLedger = await fetchLedgerSimple(activeLedgerItem.id!);
        setSimpleLedger(newSimpleLedger);
      } catch (error) {
        logErrorCtx('Error in fetchLedgerSimple', {
          error: error as Error,
          component: 'LedgerModal',
          description: `Ledger id ${activeLedgerItem.id}`,
        });
      }
      setIsLoadingContent(false);
    })();
  }, [activeLedgerItem]);

  return (
    <Modal
      open={!!activeLedgerItem}
      title={modalTitle}
      onCancelClicked={() => setActiveLedgerItem(null)}
      ModalActionsComponent={React.Fragment}
      isLoadingContent={isLoadingContent}
      withoutDefaultPadding
      dataQa="invoice"
    >
      <ItemsTable
        data={simpleLedger.ledgerItems}
        columns={LEDGER_ITEMS_COLUMNS}
        RenderCustomRow={CustomRowModal}
        RenderCustomRowFooter={<TableFooterModal footerData={simpleLedger} />}
        selectableItems={false}
        emptyTableText="THERE ARE NO LEDGER ITEMS"
        meta={simpleLedger}
        dataQa="accounts-receivable-table"
      />
    </Modal>
  );
};

export default LedgerModal;
