import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useFeedbackPageStyle = makeStyles<Theme>(() => ({
  frame: {
    background: 'transparent',
    border: 'none',
    width: '100%',
  },
}));
