import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const TRANSACTIONS_COLUMNS: Column[] = [
  {
    title: 'Date',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Type',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Ref. No',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Name',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Memo',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Account',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Debit',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Credit',
    type: ColumnTypes.renderByCustomRow,
  },
];
