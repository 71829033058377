import { Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { Modal } from 'ui/components/Modal/Modal';
import { SuccessModalProps } from './types';
import { useSuccessModalStyle } from './styled';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'ui/modules/commerce/navigation';

const SuccessModal = (props: SuccessModalProps) => {
  const { open, handleClose } = props;
  const classes = useSuccessModalStyle();

  const navigate = useNavigate();
  const handleViewChannelsClicked = useCallback(
    () => navigate(Routes.MyChannelsPage),
    [history]
  );

  return (
    <Modal
      className={classes.modal}
      open={open}
      onCancelClicked={handleClose}
      onApplyClicked={handleViewChannelsClicked}
      applyLabel="View Channels"
      cancelLabel="Skip For Now"
      fullWidth
    >
      <div className={classes.modalContent}>
        <Typography variant="h6">Success! Next, Add Channels</Typography>
        <Typography variant="body1">
          You are now connected to Fishbowl Commerce! Next, view your commerce
          channels.
        </Typography>
      </div>
    </Modal>
  );
};

export default SuccessModal;
