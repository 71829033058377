import { PermissionType } from 'services/permissions';
import {
  PurchaseOrder,
  PurchaseOrderItem,
  PurchaseOrderItemStatus,
  PurchaseOrderItemTypes,
  PurchaseOrderStatus,
} from 'services/purchaseOrders';
import { PurchaseOrderActiveIdState } from './types';

export const shouldShowUnissue = (purchaseStatus: PurchaseOrderStatus) =>
  purchaseStatus === PurchaseOrderStatus.Issued;

export const shouldShowIssue = (purchaseStatus: PurchaseOrderStatus) =>
  purchaseStatus === PurchaseOrderStatus.BidRequest;

export const shouldShowSave = (purchaseStatus: PurchaseOrderStatus) =>
  purchaseStatus === PurchaseOrderStatus.BidRequest ||
  purchaseStatus === PurchaseOrderStatus.Issued ||
  purchaseStatus === PurchaseOrderStatus.Received ||
  purchaseStatus === PurchaseOrderStatus.PartiallyReceived ||
  purchaseStatus === PurchaseOrderStatus.Reconciled ||
  purchaseStatus === PurchaseOrderStatus.PartiallyReconciled ||
  purchaseStatus === PurchaseOrderStatus.PartiallyFulfilled ||
  purchaseStatus === PurchaseOrderStatus.Expired ||
  purchaseStatus === PurchaseOrderStatus.Picking ||
  purchaseStatus === PurchaseOrderStatus.PartiallyPicked ||
  purchaseStatus === PurchaseOrderStatus.Picked;

export const duplicateItemsAsCreditReturn = (poItems: PurchaseOrderItem[]) => {
  const notDeletedPoItems = poItems.filter((i) => !i.deleted);

  return notDeletedPoItems.map((poItem, index) => {
    let newType = poItem.purchaseOrderItemType;
    let newUnitCost = poItem.unitCost;

    if (poItem.purchaseOrderItemType === PurchaseOrderItemTypes.Purchase) {
      newType = PurchaseOrderItemTypes.CreditReturn;
      newUnitCost = poItem.unitCost ? -poItem.unitCost : 0;
    } else if (
      poItem.purchaseOrderItemType === PurchaseOrderItemTypes.MiscPurchase
    ) {
      newType = PurchaseOrderItemTypes.MiscCredit;
      newUnitCost = poItem.unitCost ? -poItem.unitCost : 0;
    }

    const newPoItem: PurchaseOrderItem = {
      ...poItem,
      id: -index - 1,
      lineNumber: index + 1,
      purchaseOrderId: null,
      status: PurchaseOrderItemStatus.Entered,
      version: null,
      purchaseOrderItemType: newType,
      unitCost: newUnitCost,
    };

    return newPoItem;
  });
};

export const transformToDuplicatedItem = (
  po: PurchaseOrder,
  nextPoNumber: number
): PurchaseOrder => {
  return {
    ...po,
    id: PurchaseOrderActiveIdState.Duplicate,
    number: nextPoNumber === -1 ? null : nextPoNumber.toString(),
    status: PurchaseOrderStatus.BidRequest,
    purchaseOrderItemList: po.purchaseOrderItemList.map((p, index) => ({
      ...p,
      id: -index - 1,
      status: PurchaseOrderItemStatus.Entered,
    })),
  };
};

export const transformToDuplicateAsCreditReturn = (
  po: PurchaseOrder
): PurchaseOrder => {
  return {
    ...po,
    id: PurchaseOrderActiveIdState.DuplicateAsCreditReturn,
    number: `${po.number}-R`,
    status: PurchaseOrderStatus.BidRequest,
    purchaseOrderItemList: duplicateItemsAsCreditReturn(
      po.purchaseOrderItemList
    ),
  };
};

export const editPurchaseOrderPermissions = (
  po: PurchaseOrder
): PermissionType[] => {
  if (po.deleted) {
    return [PermissionType.Undelete];
  }
  return po.id && po.id > 0
    ? [PermissionType.PurchaseOrderEdit]
    : [PermissionType.PurchaseOrderCreate];
};

export const useDisablePurchaseOrderField =
  (purchaseOrderStatus: PurchaseOrderStatus) => (fieldName: string) => {
    switch (purchaseOrderStatus) {
      case PurchaseOrderStatus.BidRequest:
        return false;

      case PurchaseOrderStatus.Issued:
        return ['tax'].includes(fieldName);

      case PurchaseOrderStatus.Received:
        return ['tax'].includes(fieldName);

      case PurchaseOrderStatus.PartiallyReceived:
        return ['tax'].includes(fieldName);

      case PurchaseOrderStatus.Reconciled:
        return ['tax'].includes(fieldName);

      case PurchaseOrderStatus.PartiallyReconciled:
        return ['tax'].includes(fieldName);

      case PurchaseOrderStatus.Fulfilled:
        return false;

      case PurchaseOrderStatus.PartiallyFulfilled:
        return ['tax'].includes(fieldName);

      case PurchaseOrderStatus.ClosedShort:
        return true;

      case PurchaseOrderStatus.Voided:
        return true;

      case PurchaseOrderStatus.Expired:
        return true;

      case PurchaseOrderStatus.Picking:
        return ['vendor', 'orderNumber', 'location', 'tax'].includes(fieldName);

      case PurchaseOrderStatus.PartiallyPicked:
        return ['vendor', 'orderNumber', 'location', 'tax'].includes(fieldName);

      case PurchaseOrderStatus.Picked:
        return ['vendor', 'orderNumber', 'location', 'tax'].includes(fieldName);

      default:
        return false; // default is enabled
    }
  };
