import React, { memo, useEffect, useState } from 'react';

import { useLocationsPresentationalComponentStyles } from './styled';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { LocationsPresentationalComponentProps } from './types';
import { Location, fetchLocationsAPI } from 'services/locations';

const LocationsPresentationalComponent: React.FC<
  LocationsPresentationalComponentProps
> = (props: LocationsPresentationalComponentProps) => {
  const [locations, setLocations] = useState<Location[] | null>(null);

  const fetchLocations = async (idsString: string) => {
    try {
      const response = await fetchLocationsAPI({
        filter: { id: idsString },
      });
      setLocations(response.data as unknown as Location[]);
      props.setLocationsLoaded(true);
    } catch (e) {
      //continue
    }
  };

  useEffect(() => {
    if (props.ids) {
      const idsAsString = props.ids.toString();
      fetchLocations(idsAsString);
    }
  }, [props.ids]);

  const classes = useLocationsPresentationalComponentStyles();

  return (
    <Box className={classes.locationContainer}>
      {locations?.map((location, index) => (
        <Typography key={location.id} className={classes.location}>
          <span>{index !== 0 && '|'}</span>
          {location.name}
        </Typography>
      ))}
    </Box>
  );
};

export default memo(LocationsPresentationalComponent);
