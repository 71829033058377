export enum BackgroundStatus {
  Started = 'Started',
  InProgress = 'In progress',
  Completed = 'Completed',
  Failed = 'Failed',
  Pending = 'Pending',
  PartialSuccess = 'Partial Success',
}

export enum BackgroundType {
  Carrier = 'carrier',
  Customer = 'customer',
  Item = 'item',
  Location = 'location',
  ItemLocation = 'itemLocation',
  PurchaseOrder = 'purchaseOrder',
  SalesOrder = 'salesOrder',
  Uom = 'uom',
  PaymentTerm = 'paymentTerm',
  Vendor = 'vendor',
  Qbo = 'qbo',
  Inventory = 'inventory',
  Cycle = 'cycle',
  ReorderPoint = 'reorderPoint',
  VendorItem = 'vendorItem',
  Representatives = 'representative',
  Tax = 'tax',
  Permissions = 'permissionGroup',
}

export enum BackgroundAction {
  Import = 'Import',
  Export = 'Export',
}

export interface BackgroundItem {
  id: string;
  email: string | null;
  fileName: string | null;
  filePath: string | null;
  dateCreated: Date | null;
  status: BackgroundStatus;
  action: BackgroundAction;
  errorLogUrl?: string;
  progress: number | null;
  message: string | null;
  type: BackgroundType;
  url: string | null;
}

export interface DownloadHistoryRow {
  action: string;
  fileName: string;
  filePath?: string;
  status: BackgroundStatus;
  errorLogUrl?: string;
  type: string;
  dateCreated: string;
  message: string;
  url?: string;
  id: string | null;
}
export interface StatusObj {
  action?: string;
  id: string;
  status: BackgroundStatus;
  type: BackgroundType;
  fileName?: string;
  filePath?: string;
  email?: string;
  tenant?: string;
  dateCreated?: string;
  dateModified?: string;
}

export interface FileUploadStatus {
  id: string;
  status: BackgroundType;
  type: BackgroundStatus;
}

export type FileUploadStatuses = {
  statuses: FileUploadStatus[];
};
