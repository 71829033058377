import { Address } from 'services/addresses';
import { ApiStatus } from 'services/api';

export enum ShippingConnectionType {
  Shippo = 2,
}

export enum ShippingEventHistoryType {
  LabelPurchased = 'LabelPurchased',
  LabelVoided = 'LabelVoided',
  SalesOrderQuoted = 'OrderQuoted',
}

export interface ShippingConnection {
  name: string;
  typeId: ShippingConnectionType;
  token: string | null;
  secret: string | null;
  requiresOauthExchange: boolean;
}

export interface ShippingIntegration {
  _status: ApiStatus;
  connections: ShippingConnection[];
}

export interface ShippingIntegrationSettings {
  customField1: string | null;
  customField2: string | null;
  fulfillShipments: boolean;
  applyCostToCarton: boolean;
  quickShipStrategy: number | null;
  alternatePhone: string | null;
  alternateEmail: string | null;
  shippingItem: number | null;
  labelType: string | null;
  shipFromLocation: string | null;
  markup: {
    type: 'percent' | 'flat';
    value: number | null;
  };
  cartonDimensions: {
    unit: string | null;
    width: number | null;
    height: number | null;
    length: number | null;
  };
  extraSettings: ShippingExtraSettings;
  internationalSettings: ShippingInternationalSettings;
}

export interface QuickShipStrategy {
  id: number;
  name: string;
}

export interface ShippingExtraSettings {
  useRetailRates: boolean;
  saturdayDelivery: boolean;
  useInsurance: boolean;
  containsDryIce: boolean;
  dryIceWeight: number | null;
  containsAlcohol: boolean;
  unitOfMeasure: string | null;
  recipientType: string | null;
  defaultSignature: string | null;
}

export interface ShippingInternationalSettings {
  nonDeliveryOption: string | null;
  contentsType: string | null;
  shipperEmail: string | null;
  eelPfc: string | null;
  incoterms: string | null;
  disclaimer: string | null;
  exporterReference: string | null;
  importerReference: string | null;
  enabled: boolean;
  taxIdType: string | null;
  taxIdNumber: string | null;
}

export interface ShippingCarrierService {
  name: string;
  code: string;
}

export interface ShippingCarrier {
  name: string;
  code: string;
  services: ShippingCarrierService[];
}

export interface ShippingIntegrationPurchaseLabel {
  cartons: ShippingIntegrationCarton[];
  shipFrom: ShippingIntegrationAddress;
  shipTo: ShippingIntegrationAddress;
  options: ShippingIntegrationOption;
  service: {
    serviceId: number | null;
    carrierId: number | null;
  };
  shipId: number | null;
  fileType: string | null;
  customDeclaration: ShippingIntegrationCustomDeclaration;
  shipNumber: string | null;
}

export interface SalesOrderShippingQuote {
  cartons: ShippingIntegrationCarton[];
  useRetailerRates: boolean;
  saturdayDelivery: boolean;
  containsDryIce: boolean;
  cod: boolean;
  containsAlcohol: boolean;
  signatureConfirmation: boolean;
  dryIceWeight: number | null;
  uom: string | null;
  recipientType: string | null;
  codMethod: string | null;
  codAmount: number | null;
  codCurrency: string | null;
  signature: string | null;
  nonDeliveryOption: string | null;
  contentsType: string | null;
  contentsTypeExplanation: string | null;
  eel: string | null;
  incoterms: string | null;
  disclaimer: string | null;
  certificate: string | null;
  exporterReference: string | null;
  importerReference: string | null;
  invoice: string | null;
  shipperEmailAddress: string | null;
  phoneNumber: string | null;
  notes: string | null;
  consent: boolean;
  certifierName: string | null;
  carrierCode: string | null;
  serviceCode: string | null;

  shipFromAddress: Address;
  shipToAddress: Address;
}

export interface ShippingIntegrationCarton {
  dimensionUnit: string | null;
  width: number | null;
  height: number | null;
  length: number | null;
  weight: number | null;
  weightUnit: string | null;
  insuredAmount: number | null;
}

export interface ShippingIntegrationAddress {
  name: string | null;
  company: string | null;
  street1: string | null;
  street2: string | null;
  street3: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  country: string | null;
  phone: string | null;
  isResidential: boolean;
}

export interface ShippingIntegrationOption {
  signatureConfirmation: string | null;
  authorityToLeave: boolean;
  saturdayDelivery: boolean;
  billing: {
    type: string | null;
    account: string | null;
    zip: string | null;
    country: string | null;
    participationCode: string | null;
  };
  cod: {
    amount: number | null;
    currency: string | null;
    payment_method: string | null;
  };
  alcohol: {
    recipient_type: string | null;
    contains_alcohol: boolean;
  };
  dryIce: {
    weight: {
      value: number | null;
      unit: string | null;
    };
  };
  insurance: {
    amount: number | null;
    provider: string | null;
    content: string | null;
  };
  reference1: string | null;
  reference2: string | null;
  non_machinable: boolean;
}

export interface ShippingIntegrationCustomDeclaration {
  customItems: ShippingIntegrationCustomDeclarationItem[];
  contentsType: string | null;
  contentsExplanation: string | null;
  nonDelivery: string | null;
  eel_pfc: string | null;
  aes_itn: string | null;
  incoterm: string | null;
  disclaimer: string | null;
  certificate: string | null;
  shipperEmail: string | null;
  invoice: string | null;
  notes: string | null;
  certify: boolean;
  signer: string | null;
  exporterReference: string | null;
  importerReference: string | null;
  license: string | null;
  b13AFilingOption: string | null;
  b13ANumber: string | null;
  metadata: string | null;
}

export interface ShippingIntegrationCustomDeclarationItem {
  description: string | null;
  quantity: number | null;
  cost: number | null;
  tariffNumber: string | null;
  countryOfOrigin: string | null;
  weight: {
    value: number | null;
    unit: string | null;
  };
  cartonId: number | null;
  skuCode: string | null;
  eccnEar99: string | null;
  metadata: string | null;
}

export interface ShippingIntegrationRateService {
  serviceCode: string;
  serviceName: string;
  totalCost: number;
}

export interface ShippingIntegrationRate {
  carrierCode: string;
  carrierName: string;
  services: ShippingIntegrationRateService[];
}

export interface ShippingIntegrationPackage {
  name: string;
  code: string;
  carrier: string;
}

export interface ShippingIntegrationFileType {
  name: string;
  code: string;
}

export interface ShippingEventHistory {
  id: number | null;
  object: number | null;
  date: Date | null;
  name: string | null;
  number: string | null;
  tracking: string | null;
  type: string | null;
  user: string | null;
  message: string | null;
  error: boolean;
}

export interface ShippingQuoteAddToSalesOrderParams {
  id: number | null;
  soNumber: string | null;
  carrierCode: string | null;
  serviceCode: string | null;
  carrierName: string | null;
  serviceName: string | null;
  totalCost: number | null;
  connection: ShippingConnection;
  shipTo: Address;
}

export interface ShippingLabel {
  cartonId: number | null;
  labelUrl: string | null;
  cost: number | null;
  tracking: string | null;
  trackingUrl: string | null;
  returnLabel: ShippingLabel | null;
}
