import { createApiAction } from 'services/api';
import {
  StartAction,
  SuccessAction,
  FailureAction,
} from 'services/api/redux/types';

export enum SettingsPickingActionTypes {
  FETCH_PICKING_START = 'settings/fetch_picking_start',
  FETCH_PICKING_SUCCESS = 'settings/fetch_picking_success',
  FETCH_PICKING_FAILURE = 'settings/fetch_picking_failure',
}

// PICKING ACTIONS
const fetchSettingsPickingActionStart = (): StartAction => ({
  type: SettingsPickingActionTypes.FETCH_PICKING_START,
  payload: null,
});

const fetchSettingsPickingActionSuccess = (payload: any): SuccessAction => {
  return {
    type: SettingsPickingActionTypes.FETCH_PICKING_SUCCESS,
    payload,
  };
};

const fetchSettingsPickingActionFailure = (error: string): FailureAction => ({
  type: SettingsPickingActionTypes.FETCH_PICKING_FAILURE,
  payload: { error },
});

export const fetchSettingsPicking = () =>
  createApiAction(
    {
      path: '/v1/picking_settings',
      method: 'GET',
    },
    fetchSettingsPickingActionStart,
    fetchSettingsPickingActionSuccess,
    fetchSettingsPickingActionFailure
  );
