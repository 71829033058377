import { IconNames } from 'ui/theme';
import { MenuItem } from 'ui/components/Menu';
import { PermissionType } from 'services/permissions';
import { PurchaseOrderStatus } from 'services/purchaseOrders';

export const createCustomShipToActionItems = (
  onOptionClick: any,
  status: PurchaseOrderStatus | null
): MenuItem[] => [
  {
    label: 'Same As Billing',
    icon: IconNames.Copy,
    onOptionClick,
    permissions: [
      PermissionType.PurchaseOrderEdit,
      PermissionType.PurchaseOrderCreate,
    ],
    disabled: status === PurchaseOrderStatus.Fulfilled,
  },
];
