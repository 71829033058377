import _ from 'lodash';
import { showNotification } from 'services/api';
import { itemHasSerialTracking, SerialRow } from 'services/inventory';
import { ReceiptItemReceive, ReceiptItemStatus } from 'services/receiving';

export const serialListValidation = (
  serialList: SerialRow[],
  itemQuantity: number
) => {
  const hasEmptySerial =
    serialList.some((s) => Object.values(s.serialNumbers).some(_.isEmpty)) ||
    itemQuantity !== serialList.length;

  return hasEmptySerial;
};

export const trackingValidation = (receipt: ReceiptItemReceive) => {
  let hasSerialErrors = false;

  if (
    receipt.quantity === 0 ||
    receipt.receiptItem.status === ReceiptItemStatus.Received
  ) {
    return true;
  }

  if (
    receipt.receiptItem.item &&
    itemHasSerialTracking(receipt.receiptItem.item)
  ) {
    hasSerialErrors = receipt.trackingGroups.some((trg) => {
      if (trg.serialQuantity && trg.serialQuantity > 0) {
        return serialListValidation(trg.serialList, trg.serialQuantity);
      }

      if (trg.quantity && trg.quantity > 0) {
        return serialListValidation(trg.serialList, trg.quantity);
      }

      return false;
    });
  }

  const hasTrackingErrors = receipt.trackingGroups.some((trg) => {
    if (trg.quantity && trg.quantity > 0) {
      return trg.trackingInfoList.some((til) => _.isNull(til.value));
    }
    return false;
  });

  if (hasSerialErrors || hasTrackingErrors) {
    showNotification('Invalid Tracking', { variant: 'error' });
    return false;
  }

  return true;
};
