import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';

import { LongPollingProps } from './types';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

export const LongPolling = (props: LongPollingProps) => {
  const { open, onClose, title, content, dataQa } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title} </DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <FBOButton
          autoFocus
          onClick={onClose}
          color="positive"
          variant="primary"
          data-qa={`${dataQa}-dismiss-button`}
        >
          Dismiss
        </FBOButton>
      </DialogActions>
    </Dialog>
  );
};
