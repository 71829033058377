import React, { memo, useMemo } from 'react';
import _ from 'lodash';

import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { PickItem } from 'services/picking';
import { ItemTrackingType } from 'services/inventory';

import { SummaryTrackingTableProps } from './types';
import { transformToSummaryTrackingTableColumns } from './transformations';
import { SummaryStepRow } from '.';
import SummaryStepSplitRow from './SummaryStepSplitRow';

const SummaryTrackingTable: React.FC<SummaryTrackingTableProps> = (props) => {
  const {
    data,
    emptyTableText,
    errors,
    columns,
    isCommitTable,
    sx = {},
  } = props;

  const itemTrackingTypes: ItemTrackingType[] = useMemo(() => {
    const reducedTrackingTypes = data.reduce((acc, i) => {
      return acc.concat(_.get(i, 'item.itemTrackingTypeList', []));
    }, []);

    return _.uniqBy(reducedTrackingTypes, 'trackingTypeId');
  }, [data]);

  const commitData = useMemo(() => {
    const dataSplitByTracking = data.map((item) => {
      // if item does not have tracking, return it
      if (!item.trackingGroupList.length || item.autoAssign) {
        return item;
      }
      // if item has multiple tracking we need to split it into separate items
      return item.trackingGroupList.map((tgl) => {
        return { ...item, amount: tgl.quantity, trackingGroupList: [tgl] };
      });
    });

    return dataSplitByTracking.flat();
  }, [data]);

  // items to be split do not need tracking groups
  const splitData = useMemo(
    () => data.map((item: PickItem) => ({ ...item, trackingGroupList: [] })),
    [data]
  );

  return (
    <>
      <ItemsTable
        columns={transformToSummaryTrackingTableColumns(
          itemTrackingTypes,
          columns,
          isCommitTable
        )}
        data={isCommitTable ? commitData.filter((i) => i.amount) : splitData}
        RenderCustomRow={isCommitTable ? SummaryStepRow : SummaryStepSplitRow}
        selectableItems={false}
        emptyTableText={emptyTableText}
        rowErrors={errors}
        dataQa="picking-item-summary-tracking-table"
        sx={sx}
      />
    </>
  );
};

export default memo(SummaryTrackingTable);
