import { createApiAction } from 'services/api';
import {
  StartAction,
  SuccessAction,
  FailureAction,
} from 'services/api/redux/types';
import {
  createAddSearch,
  createDeleteSearch,
  createFetchSearch,
  createUpdateSearch,
} from 'services/search';

export enum PricingRulesActionTypes {
  FETCH_PRICING_RULES_START = 'pricing_rules/fetch_pricing_rules_start',
  FETCH_PRICING_RULES_SUCCESS = 'pricing_rules/fetch_pricing_rules_success',
  FETCH_PRICING_RULES_FAILURE = 'pricing_rules/fetch_pricing_rules_failure',
}

const fetchPricingRulesActionStart = (): StartAction => ({
  type: PricingRulesActionTypes.FETCH_PRICING_RULES_START,
  payload: null,
});

const fetchPricingRulesActionSuccess = (payload: any): SuccessAction => {
  return {
    type: PricingRulesActionTypes.FETCH_PRICING_RULES_SUCCESS,
    payload,
  };
};

const fetchPricingRulesActionFailure = (error: string): FailureAction => ({
  type: PricingRulesActionTypes.FETCH_PRICING_RULES_FAILURE,
  payload: { error },
});

export const fetchPricingRules = () =>
  createApiAction(
    {
      path: '/v1/pricing_rules?expand=customerTags,saleItemTags,saleItem,customer',
      method: 'GET',
    },
    fetchPricingRulesActionStart,
    fetchPricingRulesActionSuccess,
    fetchPricingRulesActionFailure
  );

// SEARCH ACTION CREATORS

export const fetchSearch = createFetchSearch('pricingRules');
export const addSearch = createAddSearch('pricingRules');
export const updateSearch = createUpdateSearch('pricingRules');
export const deleteSearch = createDeleteSearch('pricingRules');
