import React, { useEffect, useState, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { NetworkSpinnerWrapper } from 'ui/components/NetworkSpinnerWrapper';
import { Auth0WrapperProps } from './type';

const AuthHandler: React.FC<Auth0WrapperProps> = ({ children }) => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleLoginRedirect = useCallback(async () => {
    try {
      setIsLoading(true);
      await loginWithRedirect();
    } catch (error) {
      // handle error
      setIsLoading(false);
    }
  }, [loginWithRedirect, setIsLoading]);

  useEffect(() => {
    if (isAuthenticated) {
      // Handle post-authentication logic once the backend API is ready
    } else if (!isAuthenticated && !isLoading) {
      handleLoginRedirect();
    }
  }, [isAuthenticated, isLoading, handleLoginRedirect, history]);

  if (isLoading) {
    return (
      <NetworkSpinnerWrapper
        isLoading={isLoading}
        display="flex"
        flexDirection="column"
        overflow="hidden"
        flexGrow={1}
        zIndex={2}
      >
        {/* Empty fragment used as a placeholder for required children props */}
        <></>
      </NetworkSpinnerWrapper>
    );
  }

  return <>{children}</>;
};

export default AuthHandler;
