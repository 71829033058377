import React from 'react';
import { Route, Routes } from 'react-router-dom';

import SecureRoute from 'ui/components/Route/SecureRoute';
import { PermissionType } from 'services/permissions';

import { Routes as SetupRoutes } from './routes';
import {
  UomPage,
  LocationPage,
  UsersPage,
  UsersPageV2,
  PaymentTermsPage,
  RepresentativesPage,
  TaxRatesPage,
  CarriersPage,
  SettingsPage,
  CurrencyPage,
} from '../pages';
import { SetupRouterCmp, SetupRouterProps } from './types';
import { PermissionsPage } from '../pages/PermissionsPage';
import { useFlags } from 'helpers/useFlags';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SetupRouter: SetupRouterCmp = (props: SetupRouterProps) => {
  const flags = useFlags();
  return (
    <Routes>
      <Route element={<SecureRoute permissions={[PermissionType.UomView]} />}>
        <Route path={UomPage.route} element={<UomPage />} />
      </Route>
      <Route
        element={<SecureRoute permissions={[PermissionType.LocationView]} />}
      >
        <Route path={LocationPage.route} element={<LocationPage />} />
      </Route>
      <Route element={<SecureRoute permissions={[PermissionType.UserView]} />}>
        <Route
          path={UsersPage.route}
          element={flags.newUserEndpoint ? <UsersPageV2 /> : <UsersPage />}
        />
      </Route>
      <Route
        element={<SecureRoute permissions={[PermissionType.PermissionView]} />}
      >
        <Route path={PermissionsPage.route} element={<PermissionsPage />} />
      </Route>
      <Route
        element={
          <SecureRoute permissions={[PermissionType.PaymentTermsView]} />
        }
      >
        <Route path={PaymentTermsPage.route} element={<PaymentTermsPage />} />
      </Route>
      <Route
        element={<SecureRoute permissions={[PermissionType.CurrencyView]} />}
      >
        <Route path={CurrencyPage.route} element={<CurrencyPage />} />
      </Route>
      <Route
        element={
          <SecureRoute permissions={[PermissionType.RepresentativesView]} />
        }
      >
        <Route
          path={RepresentativesPage.route}
          element={<RepresentativesPage />}
        />
      </Route>
      <Route
        element={<SecureRoute permissions={[PermissionType.TaxRatesView]} />}
      >
        <Route path={TaxRatesPage.route} element={<TaxRatesPage />} />
      </Route>
      <Route
        element={<SecureRoute permissions={[PermissionType.CarriersView]} />}
      >
        <Route path={CarriersPage.route} element={<CarriersPage />} />
      </Route>
      <Route
        element={
          <SecureRoute
            permissions={[
              PermissionType.SettingsAccountMappingView,
              PermissionType.SettingsCompanyView,
              PermissionType.SettingsEmailingView,
              PermissionType.SettingsPurchaseOrderView,
              PermissionType.SettingsSalesOrderView,
              PermissionType.SettingsTrackingView,
              PermissionType.SettingsShippingView,
              PermissionType.SettingsPickingView,
            ]}
          />
        }
      >
        <Route path={SettingsPage.route} element={<SettingsPage />} />
      </Route>
    </Routes>
  );
};

SetupRouter.route = SetupRoutes.SetupRouter;

export default SetupRouter;
