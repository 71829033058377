import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'ui/components/Modal/Modal';

import { ItemsTable } from 'ui/components/Table/ItemsTable';
import CustomDetailsRow from './CustomDetailsRow';
import { colorPalette, themeRestyle } from 'ui/theme';

import { logErrorCtx } from 'app/logging';
import { DataWithPagination } from 'services/api';
import { Pagination } from 'services/search';

import {
  ExportHistory,
  getHistoryDetails,
  initialDataWithPagination,
  initialPagination,
} from 'services/accounting';
import { fboCardHistoryDetailsColumns } from './consts';
import { Box } from '@mui/material';
import { useCardStyle } from '../../styled';

interface HistoryDetailsDetailsModalProps {
  open: boolean;
  onClose(): void;
  ParentHistoryId: number;
  title: string;
}

const HistoryDetailsModal = (props: HistoryDetailsDetailsModalProps) => {
  const { open, onClose, ParentHistoryId, title } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [historyDetails, setHistoryDetails] = useState<
    DataWithPagination<ExportHistory>
  >(initialDataWithPagination);
  const classes = useCardStyle();
  const fetchHistoryDetails = async () => {
    setIsLoading(true);

    try {
      const historyDetailsResponse = await getHistoryDetails(
        ParentHistoryId,
        initialPagination
      );

      setHistoryDetails(historyDetailsResponse);
    } catch (error) {
      logErrorCtx('Error in getHistoryDetails', {
        error: error as Error,
        component: 'HistoryDetailsDetailsMoal',
        title: 'Error in getHistoryDetails',
        description: 'Error in getHistoryDetails',
      });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchHistoryDetails();
  }, []);
  const handleHistoryDetailsPaginationChange = useCallback(
    async (pagination: Pagination) => {
      try {
        const historyDetailsResp = await getHistoryDetails(
          ParentHistoryId,
          pagination
        );
        setHistoryDetails(historyDetailsResp);
      } catch (e) {
        // Ignore error
      }
    },
    []
  );

  return (
    <Modal
      title={title}
      open={open}
      onClose={onClose}
      onCancelClicked={onClose}
      applyLabel="Done"
      maxWidth="lg"
      onApplyClicked={onClose}
      withoutCloseButton
    >
      <Box className={classes.tableContainer}>
        <ItemsTable
          columns={fboCardHistoryDetailsColumns}
          data={historyDetails.data}
          RenderCustomRow={CustomDetailsRow}
          selectableItems={false}
          tableBordered={false}
          dataQa="history-details-table"
          emptyTableText="NO HISTORY DETAILS"
          sx={{
            borderRadius: '10px',
            borderLeft: `1px solid ${colorPalette.redesign.background3}`,
            borderRight: `1px solid ${colorPalette.redesign.background3}`,
            marginLeft: themeRestyle.spacing(2),
            marginRight: themeRestyle.spacing(2),
          }}
          isLoading={isLoading}
          isLoadingData={isLoading}
          pagination={historyDetails.pagination}
          onPaginationChange={handleHistoryDetailsPaginationChange}
          showEnhancedTablePaginationActions
          containerHeight={500}
        />
      </Box>
    </Modal>
  );
};

export default HistoryDetailsModal;
