import React, { memo } from 'react';

import { ImageStorageType } from 'services/images';
import { DocumentsTable } from 'ui/components/Documents/DocumentsTable';

import { editCustomerPermissions } from '../../../helpers';
import { CustomerDocumentsTabProps } from './types';

const CustomerDocumentsTab: React.FC<CustomerDocumentsTabProps> = (props) => {
  const { activeCustomer, setActiveCustomer } = props;

  const editPermission = editCustomerPermissions(activeCustomer);

  return (
    <DocumentsTable
      storageType={ImageStorageType.CustomerDocuments}
      data={activeCustomer}
      setData={setActiveCustomer}
      permissions={editPermission}
    />
  );
};

export default memo(CustomerDocumentsTab);
