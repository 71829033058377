import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { colorPalette } from 'ui/theme';

// Lint skip to be removed
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const useInventoryHeroStatusStyle = makeStyles((theme: Theme) => ({
  bold: { fontWeight: theme.typography.fontWeightBold },
  link: { cursor: 'pointer' },
  gridItem: {
    border: `1px solid ${colorPalette.redesign.background3}`,
    borderRadius: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
  },
  gridItemSubItem: {
    border: `1px solid ${colorPalette.redesign.background3}`,
    borderRadius: 3,
    display: 'flex',
    justifyContent: 'space-around',
    gap: 10,
    alignItems: 'center',
    padding: 10,
  },
  editIcon: {
    color: `${colorPalette.redesign.contentLinkBlue}`,
  },
}));
