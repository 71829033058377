import React, { memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { QuickBooksLogo } from 'ui/theme/images';
import {
  fetchQuickbooksAuthUrl,
  getQuickbooksAccountIsConnected,
  quickbooksDisconnect,
} from 'services/integrations/quickbooks';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { showNotification } from 'services/api';

import { QuickBooksCardProps } from './types';
import { Card } from '../';
import { CardButton } from '../IntegrationButtons';
import QuickBooksDialogActions from './components/QuickBooksDialogActions/QuickBooksDialogActions';
import { getXeroAccountIsConnected } from 'services/integrations/xero';

const QuickBooksCard: React.FC<QuickBooksCardProps> = () => {
  const dispatch = useDispatch();

  const quickbooksConnected = useSelector(getQuickbooksAccountIsConnected);
  const xeroConnected = useSelector(getXeroAccountIsConnected);
  const [inegrationLabel, setIntegrationLabel] = useState('');
  const [disconnectModalVisible, setDisconnectModalVisible] = useState(false);
  const [connectModalVisible, setConnectModalVisible] = useState(false);
  const [disconnectMappingModalVisible, setDisconnectMappingModalVisible] =
    useState(false);

  const openDisconnectModal = (label: string) => () => {
    setIntegrationLabel(label);
    setDisconnectModalVisible(true);
  };

  const closeDisconnectModal = () => {
    setDisconnectModalVisible(false);
    setIntegrationLabel('');
  };

  const openConnectModal = (label: string) => () => {
    if (quickbooksConnected || xeroConnected) {
      showNotification(
        'You can only be connected to 1 Accounting integration.',
        {
          variant: 'error',
        }
      );
      return;
    }
    setIntegrationLabel(label);
    setConnectModalVisible(true);
  };

  const closeConnectModal = () => {
    setConnectModalVisible(false);
    setIntegrationLabel('');
  };

  const qboConnectClicked = useCallback(async () => {
    try {
      const url = await fetchQuickbooksAuthUrl();
      window.open(url, '_self');
    } catch {
      // Ignore error
    }
    setConnectModalVisible(false);
  }, []);

  const qboDisconnectClicked = useCallback(() => {
    setDisconnectMappingModalVisible(true);
    setDisconnectModalVisible(false);
  }, [setDisconnectMappingModalVisible]);

  const handleConfirmClicked = useCallback(async () => {
    const clearMappings = true;
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(quickbooksDisconnect(clearMappings));
    setDisconnectMappingModalVisible(false);
  }, [dispatch]);

  const handleCancelClicked = useCallback(async () => {
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(quickbooksDisconnect());
    setDisconnectMappingModalVisible(false);
  }, [dispatch]);

  return (
    <>
      <Card
        imageUrl={QuickBooksLogo}
        cardId="quickbooks"
        isLoading={false}
        description="Sync your Fishbowl orders and inventory adjustments to your QuickBooks Online account. No more double entry."
        title="QuickBooks Online"
      >
        {quickbooksConnected ? (
          <CardButton
            onClick={openDisconnectModal('QuickBooks')}
            variant="secondary"
            color="negative"
            label="Disconnect"
            dataQa="qbo-disconnect"
          />
        ) : (
          <CardButton
            onClick={openConnectModal('QuickBooks')}
            variant="primary"
            color="positive"
            label="Connect"
            dataQa="qbo-connect"
          />
        )}
      </Card>
      <ConfirmationModal
        open={connectModalVisible}
        title={`Connect to ${inegrationLabel}?`}
        onConfirmClicked={qboConnectClicked}
        onCancelClicked={closeConnectModal}
        body={`Fishbowl integrates with QuickBooks Online Plus or Advanced. Only these versions of QuickBooks Online have the necessary features (purchase orders, inventory journal entries, etc.)`}
        isLoading={false}
        DialogActionsComponent={() => (
          <QuickBooksDialogActions
            qboConnectClicked={qboConnectClicked}
            closeConnectModal={closeConnectModal}
          />
        )}
      />

      <ConfirmationModal
        open={disconnectModalVisible}
        title={`Disconnect ${inegrationLabel} integration?`}
        onConfirmClicked={qboDisconnectClicked}
        onCancelClicked={closeDisconnectModal}
        body={`You are about to disconnect ${inegrationLabel}  integration from your account. Do you want to continue?`}
        confirmButtonRed
        confirmLabel="Yes"
        isLoading={false}
      />
      <ConfirmationModal
        open={disconnectMappingModalVisible}
        title={`Disconnect QuickBooks Online integration?`}
        onConfirmClicked={handleConfirmClicked}
        onCancelClicked={handleCancelClicked}
        body={`Would you like to clear the mapping between Fishbowl Online and QuickBooks Online?
        You should only clear mappings if you're going to reconnect to a different version or a different account of QuickBooks?`}
        confirmButtonRed
        confirmLabel="Yes"
        cancelLabel="No"
      />
    </>
  );
};

export default memo(QuickBooksCard);
