import React, { useCallback } from 'react';
import {
  TextField as MTextField,
  Typography,
  OutlinedInputProps,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Autocomplete from '@mui/material/Autocomplete';
import { Box } from '@mui/system';
import { colorPalette } from 'ui/theme';

export interface FBOAutocompleteReadOnlyProps {
  label?: string;
  name?: string;
  additionalInputProps?: Partial<OutlinedInputProps>;
  dataQa?: string;
  width?: string;
  value: string | null;
  style?: any;
}

const FBOAutocompleteReadOnly: React.FC<FBOAutocompleteReadOnlyProps> = (
  props
) => {
  const { label, name, additionalInputProps, dataQa, style, ...otherProps } =
    props;

  const renderInput = useCallback(
    (params) => {
      return (
        <MTextField
          className={'redesign'}
          style={{
            marginTop: '0',
            marginBottom: '0',
          }}
          {...params}
          name={name}
          fullWidth
          InputLabelProps={{ shrink: true, margin: 'dense' }}
          variant="outlined"
          size="small"
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: `${colorPalette.redesign.contentSecondary}`,
            },
          }}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off',
            'data-qa': dataQa,
            'data-lpignore': 'true',
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <Box
                sx={{
                  backgroundColor: `${colorPalette.redesign.background3}`,
                  marginLeft: '-16px',
                  marginRight: '8px',
                  height: '38px',
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: '4px 0 0 4px',
                  borderRight: `1px solid ${colorPalette.redesign.contentTertiary}`,
                }}
              >
                <Typography
                  sx={{
                    padding: '0 8px',
                    color: `${colorPalette.redesign.contentSecondary}`,
                  }}
                >
                  {label}
                </Typography>
              </Box>
            ),
            ...additionalInputProps,
          }}
        ></MTextField>
      );
    },
    [additionalInputProps]
  );

  return (
    <Autocomplete
      {...otherProps}
      options={[]}
      style={style}
      className={'redesign'}
      renderInput={renderInput}
      disabled
      popupIcon={
        dataQa && (
          <ArrowDropDownIcon
            fontSize="small"
            data-qa={`${dataQa}-autocomplete-dropdown-icon`}
          />
        )
      }
    />
  );
};

export default React.memo(FBOAutocompleteReadOnly);
