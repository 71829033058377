import { PaymentTerm, PaymentTermType } from 'services/paymentTerms/types';
import moment from 'moment';

export const calculateDueDate = (
  billDate: Date,
  paymentTerm: PaymentTerm
): Date | null => {
  const selectedBillDate = moment(billDate);

  if (paymentTerm.paymentTermType === PaymentTermType.Standard) {
    const dueDays = paymentTerm.dueDays ?? 0;
    return selectedBillDate.add(dueDays, 'days').toDate();
  }

  if (paymentTerm.paymentTermType === PaymentTermType.DateDriven) {
    const dayOfMonthDue = paymentTerm.dayOfMonthDue ?? 0;
    const currentMonth = selectedBillDate.month();
    const dueDateInCurrentMonth = moment(selectedBillDate).date(dayOfMonthDue);
    if (dueDateInCurrentMonth.month() !== currentMonth) {
      return selectedBillDate.endOf('month').toDate();
    }

    if (selectedBillDate.isSameOrAfter(dueDateInCurrentMonth)) {
      return dueDateInCurrentMonth.add(1, 'month').toDate();
    } else {
      return dueDateInCurrentMonth.toDate();
    }
  }

  return null;
};
