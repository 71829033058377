import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { TableCell } from '@mui/material';
import _ from 'lodash';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import { useCurrencyFormatter } from 'helpers';
import { getLocations } from 'services/locations';

import { SaleItemRootLocation } from './types';

const SubstituteItemModalRow: React.FC<
  RenderCustomRowProps<SaleItemRootLocation>
> = (props) => {
  const { row } = props;

  const { items: locations } = useSelector(getLocations);

  const currencyFormatter = useCurrencyFormatter();

  const itemLocation =
    locations.find((loc) => loc.id === row.locationRootId) || null;

  return (
    <>
      <TableCell>{row.name}</TableCell>
      <TableCell>
        {row.quantity} {_.get(row, 'defaultUom.name', '')}
      </TableCell>
      <TableCell>{itemLocation ? itemLocation.name : ''}</TableCell>
      <TableCell>{currencyFormatter(row.price || 0)}</TableCell>
    </>
  );
};

export default memo(SubstituteItemModalRow);
