import React, { memo, useCallback, useMemo } from 'react';
import { Box, Grid } from '@mui/material';

import { AdvancedSearchFieldsCmpProps } from 'ui/components/Page/PageWithAdvancedSearch';

import {
  DateRangePicker,
  getRangeForRequest,
} from 'ui/components/TextField/DateRangePicker';
import { DateRange } from 'materialui-daterange-picker';
import { transformDateToMomentDate } from 'helpers';

const ClassesAdvancedSearch: React.FC<AdvancedSearchFieldsCmpProps> = ({
  formValues,
  setFormValues,
}) => {
  const handleDateRangeChange = useCallback(
    (value: DateRange) => {
      const requestRange = getRangeForRequest('dateCreated', value);

      if (!requestRange) {
        return;
      }

      setFormValues({
        ...formValues,
        ...requestRange,
      });
    },
    [formValues, setFormValues]
  );

  const dateCreatedRange: DateRange | null = useMemo(() => {
    if (!formValues.dateCreatedFrom || !formValues.dateCreatedTo) {
      return null;
    }
    return {
      startDate: transformDateToMomentDate(
        formValues.dateCreatedFrom as string
      )!,
      endDate: transformDateToMomentDate(formValues.dateCreatedTo as string)!,
    };
  }, [formValues.dateCreatedFrom, formValues.dateCreatedTo]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Box>
          <DateRangePicker
            label="Date Created"
            placeholder="Select date created range"
            value={dateCreatedRange}
            onChange={handleDateRangeChange}
            dataQa="select-date-created-range"
            fullWidth
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default memo(ClassesAdvancedSearch);
