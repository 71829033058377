import { State } from 'app/redux';
import _ from 'lodash';

const getQuickbooks = (state: State) => state.integrations.quickbooks;

export const getQuickbooksAccounts = (state: State) =>
  getQuickbooks(state).items;

export const getQuickbooksAccountsIsLoading = (state: State) =>
  getQuickbooks(state)._status.isLoading;

export const getQuickbooksAccountIsConnected = (state: State) =>
  !_.isEmpty(getQuickbooksAccounts(state));

export const getQuickbooksTaxRates = (state: State) =>
  getQuickbooks(state).taxRates;
