import React, { useState, useCallback, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import moment from 'moment';
import _ from 'lodash';

import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import { updateCompany } from 'services/user';
import { DatePickerWrapper } from 'ui/components/TextField/DatePickerWrapper';
import { Errors, validateYup } from 'services/forms/validation';

import { RemoteSupportUserEditModalProps } from './types';
import { updateCompanySchema } from './validations';
import { resolveUserEditModalTitle } from './helpers';
import { TenantAccountType } from '../../consts';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { FBOAutoComplete } from 'ui/theme/components';

const RemoteSupportUserEditModal: React.FC<RemoteSupportUserEditModalProps> = ({
  onClose,
  selectedUser,
  setSelectedUser,
  showModal,
  fetchSearchResult,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Errors>({});

  const handleSaveClicked = useCallback(async () => {
    const maxUsers = _.get(selectedUser, 'company.maxUsers', null);
    const expirationDate = _.get(selectedUser, 'company.expirationDate', null);
    const companyId = _.get(selectedUser, 'companyId', null);
    const crmId = _.get(selectedUser, 'company.crmAssociations[0].crmId');
    const tenantAccountType = _.get(selectedUser, 'tenantAccountType', null);
    if (
      !validateYup(
        { maxUsers, expirationDate, companyId, crmId, tenantAccountType },
        updateCompanySchema,
        setErrors
      )
    ) {
      return;
    }
    setIsLoading(true);
    try {
      await updateCompany(
        maxUsers,
        expirationDate,
        companyId!,
        crmId,
        tenantAccountType!
      );
      await fetchSearchResult();
      onClose();
    } catch {
      // ignore error
    }
    setIsLoading(false);
  }, [fetchSearchResult, selectedUser, onClose]);

  const handleDateExpiresChanged = useCallback(
    (value: string | null | undefined) => {
      setSelectedUser((old) => ({
        ...old,
        company: old.company
          ? {
              ...old.company,
              expirationDate: !value ? null : moment(value).toDate(),
            }
          : null,
      }));
    },
    [setSelectedUser]
  );
  const handleMaxUsersChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedUser((old) => ({
        ...old,
        company: old.company
          ? { ...old.company, maxUsers: parseInt(e.target.value) }
          : null,
      }));
    },
    [setSelectedUser]
  );
  useEffect(() => {}, [errors]);
  const handleCrmIdChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedUser((old) => ({
        ...old,
        company: old.company
          ? {
              ...old.company,
              crmAssociations:
                old.company.crmAssociations.length > 0
                  ? old.company.crmAssociations.map((crmAssociation) => {
                      return { ...crmAssociation, crmId: e.target.value };
                    })
                  : [
                      {
                        id: null,
                        version: null,
                        companyId: null,
                        crmId: e.target.value,
                        crmType: null,
                        errorObject: null,
                      },
                    ],
            }
          : null,
      }));
    },
    [setSelectedUser]
  );

  const handleTenantAccountTypeChange = useCallback(
    (e: React.ChangeEvent<{}>, value: TenantAccountType | null) => {
      setSelectedUser((old) => ({
        ...old,
        tenantAccountType: value ?? null,
      }));
      if (value) setErrors((old: any) => ({ ...old, tenantAccountType: null }));
    },
    [setSelectedUser]
  );

  const handleCloseUserModal = useCallback(() => {
    setErrors({});
    onClose();
  }, [onClose]);

  const resolveModalActionsComponent = useCallback(
    () => (
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box mr={2}>
          <FBOButton
            variant="secondary"
            color="positive"
            size="medium"
            onClick={handleCloseUserModal}
            data-qa="remote-support-modal-cancel-button"
          >
            Cancel
          </FBOButton>
        </Box>
        <Box>
          <FBOButton
            variant="primary"
            color="positive"
            size="medium"
            onClick={handleSaveClicked}
            data-qa="remote-support-modal-save-button"
            loading={isLoading}
          >
            Save
          </FBOButton>
        </Box>
      </Box>
    ),
    [handleSaveClicked, isLoading, handleCloseUserModal]
  );
  const tenantAccountTypeDisplay: { [key in TenantAccountType]: string } = {
    [TenantAccountType.PREVIOUSCUSTOMER]: 'PREVIOUS CUSTOMER',
    [TenantAccountType.CONSULTANT]: 'CONSULTANT',
    [TenantAccountType.CUSTOMER]: 'CUSTOMER',
    [TenantAccountType.INTERNAL]: 'INTERNAL',
    [TenantAccountType.PARTNER]: 'PARTNER',
    [TenantAccountType.TRIAL]: 'TRIAL',
  };
  return (
    <Modal
      open={showModal}
      title={resolveUserEditModalTitle(selectedUser)}
      maxWidth="sm"
      onCancelClicked={handleCloseUserModal}
      ModalActionsComponent={resolveModalActionsComponent}
    >
      <Grid container spacing={2}>
        <Grid item md={6}>
          <DatePickerWrapper
            label="Expiration Date"
            name="expirationDate"
            value={_.get(selectedUser, 'company.expirationDate', null)}
            onChange={handleDateExpiresChanged}
            fullWidth
            error={!!errors.expirationDate}
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            className="redesign"
            variant="standard"
            label="CRMID"
            name="crmId"
            value={_.get(selectedUser, 'company.crmAssociations[0].crmId')}
            onChange={handleCrmIdChange}
            type="string"
            error={!!errors.crmId}
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            className="redesign"
            variant="standard"
            label="Max Users"
            name="maxUsers"
            value={_.get(selectedUser, 'company.maxUsers', null)}
            onChange={handleMaxUsersChange}
            type="number"
            error={!!errors.maxUsers}
          />
        </Grid>

        <Grid item md={6}>
          <FBOAutoComplete
            options={Object.values(TenantAccountType)}
            getOptionLabel={(option: TenantAccountType) =>
              tenantAccountTypeDisplay[option] || option
            }
            required={true}
            name="Type"
            label="Type"
            placeholder="Select a type"
            error={!!errors.tenantAccountType}
            errorText={
              errors.tenantAccountType
                ? 'tenant account type cannot be empty'
                : ''
            }
            dataQa="tenant-account-type"
            allowLastPassIcon={false}
            value={selectedUser.tenantAccountType}
            onChange={handleTenantAccountTypeChange}
            className="redesign"
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default RemoteSupportUserEditModal;
