import React, { memo, useCallback, useState, useMemo } from 'react';
import _ from 'lodash';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import {
  getItemTrackingNextValue,
  ItemTrackingType,
  SerialRow,
  TrackingGroup,
} from 'services/inventory';
import { TrackingDataTypes } from 'services/settings/tracking';
import { useGetIntlDateFormatString, formatDate } from 'helpers';

import {
  updateWithNewTrackingInfoEntry,
  resolveTrackingColumns,
} from './helpers';
import { SerialModal } from './components';
import { useTrackingRowStyle } from './styled';
import { TrackingTableTypes } from '../../types';
import { getSettingsCompanyCountry } from 'services/settings/company';
import { useSelector } from 'react-redux';
import { showNotification } from 'services/api';

const TrackingRow: React.FC<RenderCustomRowProps<TrackingGroup>> = ({
  row: trackingGroup,
  classes,
  columns,
  index,
  setData,
  meta,
  errors: rowErrors,
}) => {
  const rowClasses = useTrackingRowStyle();
  const [serialModalVisible, setSerialModalVisible] = useState(false);
  const companyCountry = useSelector(getSettingsCompanyCountry);

  const tableType: TrackingTableTypes = useMemo(
    () => _.get(meta, 'tableType'),
    [meta]
  );

  const disableAutoAssign: boolean = useMemo(
    () => _.get(meta, 'disableAutoAssign'),
    [meta]
  );
  const itemTrackingTypes: ItemTrackingType[] = useMemo(
    () => _.get(meta, 'itemTrackingTypes'),
    [meta]
  );

  const allowSerialNumberImport: boolean = useMemo(
    () => _.get(meta, 'allowSerialNumberImport', false),
    [meta]
  );

  const intlDateFormat = useGetIntlDateFormatString();

  const serialItemTrackingTypes: ItemTrackingType[] = useMemo(() => {
    // For serial table we need only serial tracking types
    return itemTrackingTypes.filter(
      (t) =>
        _.get(t, 'trackingType.trackingDataType', null) ===
        TrackingDataTypes.SerialNumber
    );
  }, [itemTrackingTypes]);

  const handleSerialLinkClick = useCallback(() => {
    setSerialModalVisible(true);
  }, []);
  const handleAmountChange = useCallback(
    (value: number | null) => {
      if (
        value &&
        (columns[1].title === 'Quantity To Remove' ||
          columns[1].title === 'Quantity To Move' ||
          columns[1].title === 'Move') &&
        value > trackingGroup.onHand - trackingGroup.committedQuantity
      ) {
        showNotification(
          'The input value cannot be greater than the available quantity.',
          {
            variant: 'error',
          }
        );
        return;
      }

      const newTrackingGroup = { ...trackingGroup, quantity: value };
      setData(newTrackingGroup, index);
    },
    [setData, index, trackingGroup]
  );
  const createLotChangeHandler = useCallback(
    (tiIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const lot = e.target.value || null;
      const newTrackingGroup = updateWithNewTrackingInfoEntry(
        trackingGroup,
        lot,
        tiIndex
      );
      setData(newTrackingGroup, index);
    },
    [trackingGroup, index, setData]
  );

  const createDateChangeHandler = useCallback(
    (tiIndex: number) => (newDate: string | null | undefined) => {
      if (!newDate) return;

      const formattedDate = formatDate(newDate, companyCountry, intlDateFormat);

      const newTrackingGroup = updateWithNewTrackingInfoEntry(
        trackingGroup,
        formattedDate,
        tiIndex
      );
      setData(newTrackingGroup, index);
    },
    [trackingGroup, index, setData, intlDateFormat]
  );

  const handleLotAutoAssign = useCallback(
    (tiIndex: number, trackingId: number, trackingTypeId: number) =>
      async () => {
        try {
          const lotNextValue = await getItemTrackingNextValue(
            trackingId,
            trackingTypeId
          );
          const newTrackingGroup = updateWithNewTrackingInfoEntry(
            trackingGroup,
            lotNextValue,
            tiIndex
          );
          setData(newTrackingGroup, index);
        } catch {
          // Ignore error
        }
      },
    [trackingGroup, index, setData, itemTrackingTypes]
  );

  const handleDateAutoAssign = useCallback(
    (tiIndex: number, trackingId: number, trackingTypeId: number) =>
      async () => {
        try {
          const dateNextValue = await getItemTrackingNextValue(
            trackingId,
            trackingTypeId
          );

          const formattedDate = formatDate(
            dateNextValue,
            companyCountry,
            intlDateFormat
          );

          const newTrackingGroup = updateWithNewTrackingInfoEntry(
            trackingGroup,
            formattedDate,
            tiIndex
          );

          setData(newTrackingGroup, index);
        } catch {
          // Ignore error
        }
      },
    [trackingGroup, index, setData, intlDateFormat]
  );

  const handleCloseModal = useCallback(() => {
    setSerialModalVisible(false);
  }, []);

  const handleApplyModal = useCallback(
    (newSerialList: SerialRow[], newSerialIds: number[]) => {
      const newTrackingGroup: TrackingGroup = {
        ...trackingGroup,
        serialList: newSerialList,
        serialIds: newSerialIds,
      };
      setData(newTrackingGroup, index);
      setSerialModalVisible(false);
    },
    [index, setData, trackingGroup]
  );

  const ResolvedTrackingColumns = resolveTrackingColumns(tableType);

  return (
    <>
      <ResolvedTrackingColumns
        columns={columns}
        trackingGroup={trackingGroup}
        onAmountChange={handleAmountChange}
        onSerialLinkClick={handleSerialLinkClick}
        createLotChangeHandler={createLotChangeHandler}
        createDateChangeHandler={createDateChangeHandler}
        handleLotAutoAssign={handleLotAutoAssign}
        handleDateAutoAssign={handleDateAutoAssign}
        classes={classes}
        itemTrackingTypes={itemTrackingTypes}
        formValues={meta.formValues}
        rowClasses={rowClasses}
        rowErrors={rowErrors}
        itemUom={_.get(meta, 'itemUom')}
        inventoryUom={_.get(meta, 'inventoryUom')}
        isDecimal={_.get(meta, 'isDecimal')}
        index={index}
      />
      <SerialModal
        visible={serialModalVisible}
        tableType={tableType}
        itemTrackingTypes={serialItemTrackingTypes}
        quantity={
          trackingGroup.serialQuantity
            ? trackingGroup.serialQuantity
            : trackingGroup.quantity || 0
        }
        serialList={trackingGroup.serialList}
        onApplyClicked={handleApplyModal}
        allowSerialNumberImport={allowSerialNumberImport}
        onCloseClicked={handleCloseModal}
        disableAutoAssign={disableAutoAssign}
        meta={{ trackingGroup: trackingGroup, eventType: meta.eventType }}
      />
    </>
  );
};

export default memo(TrackingRow);
