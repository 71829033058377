import React from 'react';
import { GridSize } from '@mui/material';

export type GridContainerProps = {
  title?: string;
  columnWidth?: GridSize;
  children?: React.ReactChild | React.ReactChild[];
  rest?: any;
};

export type GridDividerProps = {
  top?: number;
  bottom?: number;
  fullWidthOffset?: number;
  transparent?: boolean;
};

export enum BorderPositionTypes {
  Top,
  Bottom,
}
