import { makeStyles } from '@mui/styles';

export const useReconcileWizardSummaryStyle = makeStyles(() => ({
  quantityColumn: {
    width: 110,
  },
  unitCostColumn: {
    width: 110,
  },
  totalCostColumn: {
    width: 110,
  },
  reconcileCostColumn: {
    width: 110,
  },
  landedCostColumn: {
    width: 110,
  },
}));
