import { createApiCall, paginatedApiCall } from 'services/api';
import { RequestConfig, defaultMaximumPagination } from 'helpers';

import { Tag } from './types';
import { transformToRequestTag, transformToTag } from './transformations';

export const fetchTagsAPI = async (
  config: RequestConfig,
  ids: number[] = []
) => {
  const {
    pagination = defaultMaximumPagination,
    expands = [],
    customQuickSearchColumns = ['name'],
    quickSearchValue,
  } = config;

  let path = `/v1/tags?expand=${expands.join(',')}`;

  if (quickSearchValue) {
    path += `&quickSearchColumns=${customQuickSearchColumns}&quickSearchValue=${quickSearchValue}`;
  }

  if (ids.length) {
    path += `&id=${ids.join(',')}`;
  }

  const res = await paginatedApiCall(path, pagination, transformToTag);

  return res;
};

export const postTag = async (tag: Tag): Promise<Tag> => {
  const resp = await createApiCall(
    {
      path: `/v1/tags`,
      method: 'POST',
      body: transformToRequestTag(tag),
    },
    {
      getMessage: {
        success: () => 'Tag successfully created',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformToTag(resp.data);
};

export const putTag = async (tag: Tag): Promise<Tag> => {
  const resp = await createApiCall(
    {
      path: `/v1/tags`,
      method: 'PUT',
      body: transformToRequestTag(tag),
    },
    {
      getMessage: {
        success: () => 'Tag successfully updated',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformToTag(resp.data);
};

export const deleteTag = async (id: number): Promise<void> => {
  await createApiCall(
    {
      path: `/v1/tags/${id}`,
      method: 'DELETE',
    },
    {
      getMessage: {
        success: () => 'Tag successfully deleted',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();
};
