import React, { useState, memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { DateRange } from 'materialui-daterange-picker';

import _ from 'lodash';

import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { AdvancedSearchFieldsCmpProps } from 'ui/components/Page/PageWithAdvancedSearch';
import { getOrderPriorities } from 'services/settings/orderPriorities';
import { PickStatus } from 'services/picking';
import { Item, ItemType } from 'services/items';
import { ItemsAutocomplete } from 'ui/components/Autocomplete/ItemsAutocomplete';
import { Customer, getCustomers } from 'services/customers';
import { getLocations, Location } from 'services/locations';
import { getUsers, User } from 'services/user';
import { getUsers as getUsersV2, User as UserV2 } from 'services/userV2';
import { Tag } from 'services/tags';
import DateRangePicker from 'ui/components/TextField/DateRangePicker/DateRangePicker';
import { getRangeForRequest } from 'ui/components/TextField/DateRangePicker/helpers';
import { transformDateToMomentDate } from 'helpers';

import { pickAdvancedSearchFieldsCombined } from '../../consts';
import { TagsAsyncAutocomplete } from 'ui/components/Autocomplete/TagsAutocomplete';
import { useFlags } from 'helpers/useFlags';

const PickingAdvancedSearch: React.FC<AdvancedSearchFieldsCmpProps> = (
  props
) => {
  const { setFormValues, formValues } = props;

  const { items: orderPriorities } = useSelector(getOrderPriorities);
  const { items: customers } = useSelector(getCustomers);
  const { items: locations } = useSelector(getLocations);
  const flags = useFlags();
  const globalStateUsers = useSelector(getUsers);
  const globalStateUsersV2 = useSelector(getUsersV2);
  const users: (User | UserV2)[] = flags.newUserEndpoint
    ? globalStateUsersV2
    : globalStateUsers;
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);

  const tagsIds = useMemo(() => {
    return formValues[pickAdvancedSearchFieldsCombined.Tags] as number[];
  }, [formValues]);

  const selectedCustomer = useMemo(() => {
    return (
      customers.find(
        (c) => c.id === formValues[pickAdvancedSearchFieldsCombined.Customer]
      ) || null
    );
  }, [formValues, customers]);

  const selectedLocation = useMemo(() => {
    return (
      locations.find(
        (c) => c.id === formValues[pickAdvancedSearchFieldsCombined.Location]
      ) || null
    );
  }, [formValues, locations]);

  const selectedUser = useMemo(() => {
    return (
      users.find(
        (u) => u.id === formValues[pickAdvancedSearchFieldsCombined.User]
      ) || null
    );
  }, [users, formValues]);

  const dateCreatedRange: DateRange | null = useMemo(() => {
    if (
      !formValues[pickAdvancedSearchFieldsCombined.DateCreatedFrom] ||
      !formValues[pickAdvancedSearchFieldsCombined.DateCreatedTo]
    ) {
      return null;
    }

    return {
      startDate: transformDateToMomentDate(
        formValues[pickAdvancedSearchFieldsCombined.DateCreatedFrom] as string
      )!,
      endDate: transformDateToMomentDate(
        formValues[pickAdvancedSearchFieldsCombined.DateCreatedTo] as string
      )!,
    };
  }, [
    formValues[pickAdvancedSearchFieldsCombined.DateCreatedFrom],
    formValues[pickAdvancedSearchFieldsCombined.DateCreatedTo],
  ]);

  const dateLastModifiedRange: DateRange | null = useMemo(() => {
    if (
      !formValues[pickAdvancedSearchFieldsCombined.DateModifiedFrom] ||
      !formValues[pickAdvancedSearchFieldsCombined.DateModifiedTo]
    ) {
      return null;
    }

    return {
      startDate: transformDateToMomentDate(
        formValues[pickAdvancedSearchFieldsCombined.DateModifiedFrom] as string
      )!,
      endDate: transformDateToMomentDate(
        formValues[pickAdvancedSearchFieldsCombined.DateModifiedTo] as string
      )!,
    };
  }, [
    formValues[pickAdvancedSearchFieldsCombined.DateModifiedFrom],
    formValues[pickAdvancedSearchFieldsCombined.DateModifiedTo],
  ]);

  const dateFulfilledRange: DateRange | null = useMemo(() => {
    if (
      !formValues[pickAdvancedSearchFieldsCombined.DateFulfilledFrom] ||
      !formValues[pickAdvancedSearchFieldsCombined.DateFulfilledTo]
    ) {
      return null;
    }

    return {
      startDate: transformDateToMomentDate(
        formValues[pickAdvancedSearchFieldsCombined.DateFulfilledFrom] as string
      )!,
      endDate: transformDateToMomentDate(
        formValues[pickAdvancedSearchFieldsCombined.DateFulfilledTo] as string
      )!,
    };
  }, [
    formValues[pickAdvancedSearchFieldsCombined.DateFulfilledFrom],
    formValues[pickAdvancedSearchFieldsCombined.DateFulfilledTo],
  ]);

  const dateScheduledRange: DateRange | null = useMemo(() => {
    if (
      !formValues[pickAdvancedSearchFieldsCombined.DateScheduledFrom] ||
      !formValues[pickAdvancedSearchFieldsCombined.DateScheduledTo]
    ) {
      return null;
    }

    return {
      startDate: transformDateToMomentDate(
        formValues[pickAdvancedSearchFieldsCombined.DateScheduledFrom] as string
      )!,
      endDate: transformDateToMomentDate(
        formValues[pickAdvancedSearchFieldsCombined.DateScheduledTo] as string
      )!,
    };
  }, [
    formValues[pickAdvancedSearchFieldsCombined.DateScheduledFrom],
    formValues[pickAdvancedSearchFieldsCombined.DateScheduledTo],
  ]);

  const resolvedPriorityValue = useMemo(
    () =>
      orderPriorities.find((i) => i.id === formValues.orderPriorityId) || null,
    [formValues.orderPriorityId, orderPriorities]
  );

  // Started status doesn't exists on BE
  const availablePickStatuses = useMemo(
    () => _.difference(Object.values(PickStatus), [PickStatus.Started]),
    []
  );

  const selectedPickStatuses = useMemo(() => {
    if (!formValues.status) {
      return [];
    }

    return formValues.status.toString().split(',');
  }, [formValues.status]);

  const autocompleteLabelResolver = useCallback(
    (option) => option.name || option,
    []
  );

  const handleStatusChange = useCallback(
    (e: React.ChangeEvent<{}>, statuses: PickStatus[]) => {
      setFormValues({
        ...formValues,
        status: statuses.length ? statuses.join(',') : null,
      });
    },
    [formValues, setFormValues]
  );

  const handleItemChange = useCallback(
    (selectedItem: Item | null) => {
      setFormValues({
        ...formValues,
        [pickAdvancedSearchFieldsCombined.Item]: selectedItem
          ? selectedItem.id
          : null,
      });
    },

    [setFormValues, formValues]
  );

  const handleCustomerChange = useCallback(
    (e: React.ChangeEvent<{}>, customer: Customer | null) => {
      setFormValues({
        ...formValues,
        [pickAdvancedSearchFieldsCombined.Customer]: customer
          ? customer.id
          : null,
      });
    },
    [setFormValues, formValues]
  );

  const handleLocationChange = useCallback(
    (e: React.ChangeEvent<{}>, location: Location | null) => {
      setFormValues({
        ...formValues,
        [pickAdvancedSearchFieldsCombined.Location]: location
          ? location.id
          : null,
      });
    },
    [setFormValues, formValues]
  );

  const handleDateRangeChange = useCallback(
    (field: string) => (value: DateRange) => {
      const requestRange = getRangeForRequest(field, value, true);

      if (!requestRange) {
        return;
      }

      setFormValues({
        ...formValues,
        ...requestRange,
      });
    },
    [formValues, setFormValues]
  );

  const handleAutocompleteChanged = useCallback(
    (filedName) => (e: any, value: any) => {
      if (!value) {
        setFormValues({
          ...formValues,
          [filedName]: null,
        });
        return;
      }
      setFormValues({
        ...formValues,
        [filedName]: value.id,
      });
    },
    [formValues, setFormValues]
  );

  const handleTagChange = useCallback(
    (value: Tag[]) => {
      setSelectedTags(value);
      setFormValues({
        ...formValues,
        [pickAdvancedSearchFieldsCombined.Tags]: value.length
          ? value.map((i) => i.id!)
          : null,
      });
    },
    [formValues, setFormValues]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Autocomplete
          label="Customer"
          options={customers}
          value={selectedCustomer}
          placeholder="Select customer"
          getOptionLabel={(c) => c.name}
          onChange={handleCustomerChange}
          dataQa="select-customer"
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          label="Location"
          options={locations}
          value={selectedLocation}
          placeholder="Select location"
          getOptionLabel={(c) => c.name}
          onChange={handleLocationChange}
          dataQa="select-location"
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          className="multi-line"
          placeholder="Select pick status"
          onChange={handleStatusChange}
          value={selectedPickStatuses}
          label="Status"
          multiple
          options={availablePickStatuses}
          getOptionLabel={autocompleteLabelResolver}
          dataQa="select-pick-status"
        />
      </Grid>
      <Grid item xs={6}>
        <ItemsAutocomplete
          label="Item"
          onChange={handleItemChange}
          value={
            (formValues[pickAdvancedSearchFieldsCombined.Item] || null) as
              | number
              | null
          }
          placeholder="Select item"
          disableAdd
          itemTypes={[ItemType.Inventory, ItemType.NonInventory]}
          dataQa="select-item"
        />
      </Grid>
      <Grid item xs={6}>
        <TagsAsyncAutocomplete
          label="Tags"
          placeholder="Select tag"
          value={selectedTags}
          onChange={handleTagChange}
          ids={tagsIds}
          dataQa="select-tag"
          disableTagsModal
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          label="Assigned User"
          options={users}
          value={selectedUser}
          placeholder="Select user"
          getOptionLabel={(u: User) => u.firstName + ' ' + u.lastName}
          onChange={handleAutocompleteChanged([
            pickAdvancedSearchFieldsCombined.User,
          ])}
          dataQa="assigned-user"
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          placeholder="Select priority"
          onChange={handleAutocompleteChanged([
            pickAdvancedSearchFieldsCombined.Priority,
          ])}
          value={resolvedPriorityValue}
          label="Order Priority"
          options={orderPriorities}
          getOptionLabel={autocompleteLabelResolver}
          dataQa="select-priority"
        />
      </Grid>
      <Grid item xs={6}></Grid>
      <Grid item xs={6}>
        <DateRangePicker
          label="Date Created"
          placeholder="Select date created range"
          value={dateCreatedRange}
          onChange={handleDateRangeChange('created')}
          dataQa="select-date-created-range"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <DateRangePicker
          value={dateLastModifiedRange}
          label="Date Modified"
          placeholder="Select date modified range"
          onChange={handleDateRangeChange('modified')}
          dataQa="select-date-modified-range"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <DateRangePicker
          label="Date Scheduled"
          placeholder="Select date scheduled range"
          value={dateScheduledRange}
          onChange={handleDateRangeChange('scheduled')}
          dataQa="select-date-scheduled-range"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <DateRangePicker
          value={dateFulfilledRange}
          label="Date Fulfilled"
          name="dateFulfilled"
          placeholder="Select date fulfilled range"
          onChange={handleDateRangeChange('fulfilled')}
          dataQa="select-date-fulfilled-range"
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default memo(PickingAdvancedSearch);
