import _ from 'lodash';

import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';
import { ItemTrackingType } from 'services/inventory';
import { TrackingDataTypes } from 'services/settings/tracking';

export const transformToSummaryTrackingTableColumns = (
  itemTrackingTypes: ItemTrackingType[],
  baseColumns: Column[],
  isCommitTable: boolean | undefined
): Column[] => {
  const columns: Column[] = [...baseColumns];

  if (isCommitTable) {
    const hasSerialTracking = itemTrackingTypes.some(
      (t) =>
        _.get(t, 'trackingType.trackingDataType') ===
        TrackingDataTypes.SerialNumber
    );

    if (hasSerialTracking) {
      const serialColumn: Column = {
        title: 'Serial Tracking',
        meta: { trackingDataType: TrackingDataTypes.SerialNumber },
        type: ColumnTypes.renderByCustomRow,
      };

      columns.push(serialColumn);
    }
  }

  itemTrackingTypes.forEach((i) => {
    if (!i.trackingType) {
      return;
    }

    if (i.trackingType.trackingDataType === TrackingDataTypes.SerialNumber) {
      return;
    }

    const dateOrLotColumn: Column = {
      title: i.trackingType.name,
      meta: {
        trackingDataType: i.trackingType.trackingDataType,
        trackingType: i.trackingType,
      },
      type: ColumnTypes.renderByCustomRow,
    };

    columns.push(dateOrLotColumn);
  });

  return columns;
};
