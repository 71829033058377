import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import {
  Box,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
} from '@mui/material';

import {
  Account,
  AccountType,
  QBOAccountType,
  XeroAccountType,
  fetchAccounts,
  updateAccounts,
} from 'services/accounting';
import { defaultMaximumPagination, replaceValueInCollection } from 'helpers';
import {
  fetchQuickBooksAccountsAPI,
  getQuickbooksAccountIsConnected,
  Quickbooks,
} from 'services/integrations/quickbooks';
import { fetchXeroAccountsAPI, Xero } from 'services/integrations/xero';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { NetworkSpinnerWrapper } from 'ui/components/NetworkSpinnerWrapper';
import { Modal } from 'ui/components/Modal/Modal';

import { MappingModalProps } from './types';
import { logErrorCtx } from 'app/logging';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const MappingModal: React.FC<MappingModalProps> = (props) => {
  const { open, onClose } = props;

  const quickbooksConnected = useSelector(getQuickbooksAccountIsConnected);

  const [accounts, setAccounts] = useState<Account[]>([]);
  const [quickbooksAccounts, setQuickbooksAccounts] = useState<Quickbooks[]>(
    []
  );
  const [xeroAccounts, setXeroAccounts] = useState<Xero[]>([]);
  const [contentLoading, setContentLoading] = useState(false);

  const fetchFBOAccounts = useCallback(async () => {
    try {
      const resFreshAccounts = await fetchAccounts();

      // We need to convert type Holding to Other Current Liability on fetch
      // so we can map it correctly.
      // That can not be changed on backed due some reasons
      const index = resFreshAccounts.findIndex(
        (el) => el.accountType === AccountType.Holding
      );

      const newFreshAccounts = replaceValueInCollection(
        resFreshAccounts,
        {
          ...resFreshAccounts[index],
          accountType: AccountType.OtherCurrentLiability,
        },
        index
      )!;

      const filteredAccounts = newFreshAccounts.filter(
        (a) => a.name !== AccountType.CostVariance
      );

      setAccounts(filteredAccounts);
    } catch (e) {
      const error = e as Error;
      logErrorCtx('Could not retrieve Fishbowl Account information', {
        error,
        stackTrace: error.stack,
        title: 'Fishbowl Account Endpoint Failure',
        description:
          'Fishbowl account information was not retrievable from endpoint',
        component: 'AccountingSettingsPage -> MappingModal',
      });
    }
  }, []);

  const fetchQuickBooksAccounts = useCallback(async () => {
    // TODO: we need to find a better way to display quickbooks accounts
    let resData: Quickbooks[] = [];
    let allFetched = false;
    let page = 0;

    try {
      while (!allFetched) {
        const response = await fetchQuickBooksAccountsAPI(
          {
            pagination: {
              ...defaultMaximumPagination,
              page: ++page,
            },
          },
          null,
          []
        );

        resData = [...resData, ...response.data];

        if (resData.length >= response.pagination.totalRows) {
          allFetched = true;
        }
      }
      setQuickbooksAccounts(resData);
    } catch (e) {
      const error = e as Error;
      logErrorCtx('Could not retrieve QuickBooks Account information', {
        error,
        stackTrace: error.stack,
        title: 'QuickBooks Account Endpoint Failure',
        description:
          'QuickBooks account information was not retrievable from endpoint',
        component: 'AccountingSettingsPage -> MappingModal',
      });
    }
  }, []);

  const fetchXeroAccounts = useCallback(async () => {
    const listId = accounts
      .filter((a) => a.accountMappingId)
      .map((a) => a.accountMappingId!);

    try {
      const resXeroAccounts = await fetchXeroAccountsAPI({}, null, listId);
      setXeroAccounts(resXeroAccounts.data);
    } catch (e) {
      const error = e as Error;
      logErrorCtx('Xero Accounts not Received', {
        error,
        stackTrace: error.stack,
        title: 'Xero Account API failure',
        description: 'Xero Account information not received from API',
        component: 'AccountSettingsPage -> MappingModal',
      });
    }
  }, [accounts]);

  useEffect(() => {
    if (!open) {
      return;
    }

    (async () => {
      setContentLoading(true);
      await fetchFBOAccounts();
      if (quickbooksConnected) {
        await fetchQuickBooksAccounts();
      } else {
        await fetchXeroAccounts();
      }
      setContentLoading(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, quickbooksConnected]);

  const handleQuickBooksAccountChange = useCallback(
    (index: number) => (e: any, value: Quickbooks | null) => {
      setAccounts(
        (oldState) =>
          replaceValueInCollection(
            oldState,
            {
              ...oldState[index],
              accountMappingId: value ? value.accountingId : '',
            },
            index
          )!
      );
    },
    [setAccounts]
  );

  const handleXeroAccountChange = useCallback(
    (index: number) => (e: any, value: Xero | null) => {
      setAccounts(
        (oldState) =>
          replaceValueInCollection(
            oldState,
            {
              ...oldState[index],
              accountMappingId: value ? value.accountingId : '',
            },
            index
          )!
      );
    },
    [setAccounts]
  );

  const saveAccounts = useCallback(async () => {
    setContentLoading(true);

    try {
      await updateAccounts(accounts);
    } catch {
      // continue regardless of error
    }

    setContentLoading(false);
    onClose();
  }, [accounts, onClose]);

  const handleAccountTypeChange = useCallback(
    (index: number) =>
      (e: any, value: { id: string; value: string } | null) => {
        setAccounts(
          (oldState) =>
            replaceValueInCollection(
              oldState,
              {
                ...oldState[index],
                integrationsTypeFilter: value ? value.value : null,
                accountType: value ? value.value : null,
                accountMappingId: null,
              },
              index
            )!
        );
      },
    []
  );

  const ModalActions = () => {
    return (
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box mr={2}>
          <FBOButton
            variant="secondary"
            color="positive"
            size="medium"
            onClick={onClose}
            data-qa="accounting-mapping-cancel"
          >
            Cancel
          </FBOButton>
        </Box>
        <NetworkSpinnerWrapper isLoading={contentLoading} size={24}>
          <FBOButton
            variant="primary"
            color="positive"
            size="medium"
            onClick={saveAccounts}
            data-qa="accounting-mapping-save"
          >
            Save
          </FBOButton>
        </NetworkSpinnerWrapper>
      </Box>
    );
  };

  const QboAccountTypes = useMemo(() => {
    const entries = Object.entries(QBOAccountType).map(([key, value]) => ({
      id: key,
      value: value,
    }));
    return entries;
  }, []);

  const XeroAccountTypes = useMemo(() => {
    const entries = Object.entries(XeroAccountType).map(([key, value]) => ({
      id: key,
      value: value,
    }));
    return entries;
  }, []);

  return (
    <Modal
      open={open}
      title="Account Mapping"
      onCancelClicked={onClose}
      isLoading={contentLoading}
      isLoadingContent={contentLoading}
      withoutDefaultPadding
      customHeight={700}
      maxWidth="md"
      ModalActionsComponent={ModalActions}
      footerDivider="shadow"
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>FBO Accounts</TableCell>
            <TableCell>
              {quickbooksConnected ? 'QuickBooks' : 'Xero'} Account Type Filter
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
              {quickbooksConnected ? 'QuickBooks Accounts' : 'Xero Accounts'}{' '}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {accounts.map((acc, index) => {
            const availableQboAccounts = acc.integrationsTypeFilter
              ? quickbooksAccounts.filter(
                  (qboAccount) =>
                    acc.integrationsTypeFilter === qboAccount.accountType
                )
              : quickbooksAccounts;

            const availableXeroAccounts = acc.integrationsTypeFilter
              ? xeroAccounts.filter(
                  (xeroAccount) =>
                    acc.integrationsTypeFilter === xeroAccount.accountType
                )
              : xeroAccounts;

            const selectedQboAccount =
              quickbooksAccounts.find(
                (qboAccount) => qboAccount.accountingId === acc.accountMappingId
              ) || null;
            const selectedXeroAccount =
              xeroAccounts.find(
                (xeroAccount) =>
                  xeroAccount.accountingId === acc.accountMappingId
              ) || null;

            return (
              <TableRow key={acc.id}>
                <TableCell style={{ width: '200px' }}>{acc.name}</TableCell>
                <TableCell style={{ width: '350px' }}>
                  {' '}
                  <Autocomplete
                    options={
                      quickbooksConnected ? QboAccountTypes : XeroAccountTypes
                    }
                    value={
                      acc.integrationsTypeFilter
                        ? {
                            id: acc.integrationsTypeFilter,
                            value: acc.integrationsTypeFilter,
                          }
                        : null
                    }
                    onChange={handleAccountTypeChange(index)}
                    getOptionLabel={(o) => o.value}
                  />
                </TableCell>
                <TableCell style={{ width: '30px' }}>
                  <NavigateNextIcon />
                </TableCell>
                <TableCell>
                  {quickbooksConnected ? (
                    <Autocomplete
                      options={availableQboAccounts}
                      value={selectedQboAccount}
                      getOptionLabel={(a: Quickbooks) => a.accountingName ?? ''}
                      groupBy={(a: Quickbooks) =>
                        (!acc.integrationsTypeFilter ? a.accountType : '') ?? ''
                      }
                      onChange={handleQuickBooksAccountChange(index)}
                      placeholder="Select"
                    />
                  ) : (
                    <Autocomplete
                      options={availableXeroAccounts}
                      value={selectedXeroAccount}
                      getOptionLabel={(a: Xero) => a.accountingName || ''}
                      onChange={handleXeroAccountChange(index)}
                      placeholder="Select"
                    />
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Modal>
  );
};

export default memo(MappingModal);
