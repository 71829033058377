import { initialPagination } from 'ui/components/Table/ItemsTable';

import { DataWithPagination } from './types';

export const methodDisplayNameMapper: any = {
  get: 'fetched',
  delete: 'deleted',
  head: 'sent',
  options: 'sent',
  post: 'created',
  put: 'updated',
  patch: 'updated',
};

export const initialDataWithPagination: DataWithPagination = {
  pagination: initialPagination,
  data: [],
};
