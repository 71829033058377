import React from 'react';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { Box, IconButton } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseOutlined from '@mui/icons-material/CloseOutlined';

import { colorPalette } from 'ui/theme';

let notificationEnqueueHandler: any = null;
let notificationCloseHandler: any = null;

export const setEnqueueHandler = (enqueueSnackbar: any) => {
  notificationEnqueueHandler = enqueueSnackbar;
};

export const setCloseHandler = (closeSnackbar: any) => {
  notificationCloseHandler = closeSnackbar;
};

export const showNotification = (message: string, options: any) => {
  if (
    (window.location.pathname === '/login' &&
      options.variant === 'error' &&
      message !== 'The username or password you entered is incorrect' &&
      message !== 'Login has expired.' &&
      message !== 'Unauthorized') ||
    message.includes('Request failed with status code 401') ||
    message === 'undefined'
  ) {
    return;
  }
  return notificationEnqueueHandler(
    <span data-qa="client-snackbar-message">{message}</span>,
    {
      persist: false,
      action: (key: any) => (
        <IconButton
          onClick={() => closeNotification(key)}
          size="small"
          edge="end"
          data-qa="client-snackbar-close"
        >
          <CloseOutlined htmlColor={colorPalette.white} fontSize="small" />
        </IconButton>
      ),
      'data-qa': 'client-snackbar',
      ...options,
    }
  );
};

export const closeNotification = (key: any) => {
  notificationCloseHandler(key);
};

export const showLoadingNotification = (message?: string) => {
  const resolvedMessage = message ? message : 'Loading ...';

  return notificationEnqueueHandler(resolvedMessage, {
    content: (key: any, message: string) => (
      <Box
        id={key}
        boxShadow={6}
        style={{
          borderRadius: 4,
          minWidth: 288,
          height: 48,
          paddingTop: 6,
          paddingBottom: 6,
          paddingLeft: 12,
          paddingRight: 12,
          backgroundColor: colorPalette.fishbowlCorporateBlue,
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box>{`${message} This may take a while.`}</Box>
      </Box>
    ),
  });
};

export const showDownloadNotification = (message?: string) => {
  const resolvedMessage = message ? message : 'Your export is ready,';

  return notificationEnqueueHandler(resolvedMessage, {
    content: (key: any, message?: string) => (
      <Box
        id={key}
        boxShadow={6}
        style={{
          borderRadius: 4,
          minWidth: 288,
          height: 48,
          paddingTop: 6,
          paddingBottom: 6,
          paddingLeft: 12,
          paddingRight: 12,
          backgroundColor: colorPalette.fishbowlCorporateBlue,
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <CheckCircleIcon style={{ marginRight: '10px' }} />
        <Box display="flex" alignItems="center">
          {message}&nbsp;
          <Link
            to="/notifications/downloads"
            style={{ color: 'white', textDecoration: 'underline' }}
          >
            go to downloads.
          </Link>
        </Box>
      </Box>
    ),
  });
};

export const ShowQboRefreshNotification = () => {
  return notificationEnqueueHandler('', {
    content: (key: any) => (
      <Box
        id={key}
        boxShadow={6}
        style={{
          borderRadius: 4,
          minWidth: 288,
          height: 48,
          paddingTop: 6,
          paddingBottom: 6,
          paddingLeft: 12,
          paddingRight: 12,
          backgroundColor: colorPalette.fishbowlCorporateBlue,
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <CheckCircleIcon style={{ marginRight: '10px' }} />
        <Box display="flex" alignItems="center">
          QuickBooks Online Export has finished.
        </Box>
      </Box>
    ),
  });
};

export const NotificationManager = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  setEnqueueHandler(enqueueSnackbar);
  setCloseHandler(closeSnackbar);

  return null;
};
