import React, { memo, useCallback, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';

import _ from 'lodash';
import moment from 'moment';

import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { AddressValidationModal } from 'ui/components/Modal/AddressModal/components';
import { TextField } from 'ui/components/TextField/TextField';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { RepresentativesAutocomplete } from 'ui/components/Autocomplete/RepresentativesAutocomplete';
import { DatePickerWrapper } from 'ui/components/TextField/DatePickerWrapper';
import { GridDivider } from 'ui/components/Grid';
import { TextFieldAddressBeta } from '../TextFieldAddressBeta';
import VerifiedButton from 'ui/components/Modal/AddressModal/components/VerifiedButton';
import FormRadioGroup from 'ui/components/FormRadioGroup/FormRadioGroup';
import AddressData from '../BillToShipToSelectBeta/AddressData';

import { EMAIL_STATUSES, PRINT_STATUSES } from 'services/salesOrders';
import { getRepresentatives, Representative } from 'services/representatives';
import { getPaymentTerms } from 'services/paymentTerms';
import { FOBS, SHIPPING_TERMS } from 'services/items';
import {
  Address,
  AddressValidation,
  BillToShipToAddressBeta, // @beta: additionally added for testing
  initalValidAddress,
} from 'services/addresses';
import {
  getValidateAddress,
  ShippingConnectionType,
} from 'services/integrations/shipping';
import { getShippingIntegrationConnection } from 'services/integrations/shipping/redux';
import { showNotification } from 'services/api';
import { getOrderPriorities } from 'services/settings/orderPriorities';

import { transformShipToAddress } from '../SalesOrderGeneralTab/transformations';

import { SalesOrderDetailsTabCmp, SalesOrderDetailsTabProps } from './types';
import { editSalesOrderPermissions } from '../../../SalesOrderDetailsCard/helpers';
import { createCustomShipToActionItems } from '../SalesOrderGeneralTab/consts';
import {
  setShipToAsBillToAddress,
  setVerifiedBillToAddress,
  setVerifiedShipToAddress,
} from '../SalesOrderGeneralTab/helpers';
import {
  CONTAINER_SPACING_SIZE,
  EMAIL_STATUS,
  getLabelName,
  PRINT_STATUS,
} from 'ui/components/FormRadioGroup/helpers';

import { useSalesOrderDetailsTabStyles } from './styled';
import { logErrorCtx } from 'app/logging';
import { useDisableSalesOrderField } from '../../helpers';

const SalesOrderDetailsTab: SalesOrderDetailsTabCmp = (
  props: SalesOrderDetailsTabProps
) => {
  const { salesOrder, setSalesOrder, isLoading, setIsLoading } = props;

  const [addressValidationModalVisible, setAddressValidationModalVisible] =
    useState<boolean>(false);
  const [addressValidation, setAddressValidation] =
    useState<AddressValidation>(initalValidAddress);

  const { items: orderPriorities } = useSelector(getOrderPriorities);
  const { items: representatives } = useSelector(getRepresentatives);
  const { items: paymentTerms } = useSelector(getPaymentTerms);
  const disableField = useDisableSalesOrderField(salesOrder.status);

  const connection = useSelector(
    getShippingIntegrationConnection(ShippingConnectionType.Shippo)
  );

  const editPermission = editSalesOrderPermissions(salesOrder);

  const classes = useSalesOrderDetailsTabStyles();

  // Transformation
  const shipToAddressTransformed = transformShipToAddress(salesOrder);

  const representative = useMemo(() => {
    return (
      representatives.find((r) => r.id === salesOrder.representativeId) || null
    );
  }, [salesOrder.representativeId, representatives]);

  const addressChoices: Address[] = useMemo(() => {
    const selectedCustomer = salesOrder.customer;

    return (selectedCustomer && selectedCustomer.addresses) || [];
  }, [salesOrder.customer]);

  const selectedPaymentTerm = useMemo(
    () => paymentTerms.find((t) => t.id === salesOrder.paymentTermId) || null,
    [paymentTerms, salesOrder.paymentTermId]
  );

  const setShippingSameAsBilling = useCallback(() => {
    setShipToAsBillToAddress(setSalesOrder);
  }, [setSalesOrder]);

  const customShipToActionItems = useMemo(
    () =>
      createCustomShipToActionItems(
        setShippingSameAsBilling,
        salesOrder.status
      ),
    [setShippingSameAsBilling, salesOrder.status]
  );

  const selectedShippingTerm = useMemo(
    () =>
      SHIPPING_TERMS.find((t) => t.id === salesOrder.shippingTermId) || null,
    [salesOrder.shippingTermId]
  );

  const selectedOrderPriority = useMemo(
    () => orderPriorities.find((p) => p.id === salesOrder.priorityId) || null,
    [orderPriorities, salesOrder.priorityId]
  );

  const selectedFob = useMemo(
    () => FOBS.find((f) => f.id === salesOrder.fobPointId) || null,
    [salesOrder.fobPointId]
  );

  const selectedEmailStatus = useMemo(
    () =>
      EMAIL_STATUSES.find((s) => s.value === salesOrder.emailStatus) || null,
    [salesOrder]
  );

  const selectedPrintStatus = useMemo(
    () =>
      PRINT_STATUSES.find((s) => s.value === salesOrder.printStatus) || null,
    [salesOrder]
  );

  const handleDateExpiresChanged = useCallback(
    (value: any) => {
      const date = moment(value);

      setSalesOrder((old) => ({
        ...old,
        dateExpires: !value ? null : date.toDate(),
      }));
    },
    [setSalesOrder]
  );

  const handleAutocompleteChange = useCallback(
    (name: string, defaultValue?: any, value?: string) =>
      (e: React.ChangeEvent<{}>, v?: any) => {
        setSalesOrder((old) => ({
          ...old,
          [name]: v ? (value ? v[value] : v) : defaultValue,
        }));
      },
    [setSalesOrder]
  );

  const handleRepresentativeChange = (value: Representative | null) => {
    setSalesOrder((old) => ({
      ...old,
      representativeId: value ? value.id : null,
    }));
  };

  const billToAddress: BillToShipToAddressBeta = useMemo(() => {
    return {
      phone: salesOrder.billToPhone, // @beta: additionally added for testing
      email: salesOrder.billToEmail, // @beta: additionally added for testing
      name: salesOrder.billToName,
      street: salesOrder.billToStreet,
      street2: salesOrder.billToStreet2,
      city: salesOrder.billToCity,
      state: salesOrder.billToState,
      country: salesOrder.billToCountry,
      postalCode: salesOrder.billToPostalCode,
      companyName: salesOrder.billToCompanyName,
      residential: salesOrder.billToResidential,
      attention: salesOrder.billToAttention,
      verified: salesOrder.billToVerified,
    };
  }, [salesOrder]);

  const shipToAddress: BillToShipToAddressBeta = useMemo(() => {
    return {
      phone: salesOrder.shipToPhone, // @beta: additionally added for testing
      email: salesOrder.shipToEmail, // @beta: additionally added for testing
      name: salesOrder.shipToName,
      street: salesOrder.shipToStreet,
      street2: salesOrder.shipToStreet2,
      city: salesOrder.shipToCity,
      state: salesOrder.shipToState,
      country: salesOrder.shipToCountry,
      postalCode: salesOrder.shipToPostalCode,
      companyName: salesOrder.shipToCompanyName,
      residential: salesOrder.shipToResidential,
      attention: salesOrder.shipToAttention,
      verified: salesOrder.shipToVerified,
    };
  }, [salesOrder]);

  const handleBillToChanged = useCallback(
    (adr: BillToShipToAddressBeta) => {
      setSalesOrder({
        ...salesOrder,
        billToPhone: adr.phone, // @beta: additionally added for testing
        billToEmail: adr.email, // @beta: additionally added for testing
        billToCity: adr.city,
        billToStreet: adr.street,
        billToStreet2: adr.street2,
        billToState: adr.state,
        billToPostalCode: adr.postalCode,
        billToName: adr.name,
        billToCountry: adr.country,
        billToCompanyName: adr.companyName,
        billToResidential: adr.residential,
        billToAttention: adr.attention,
        billToVerified: adr.verified,
      });
    },
    [setSalesOrder, salesOrder]
  );

  const handleShipToChanged = useCallback(
    (adr: BillToShipToAddressBeta) => {
      setSalesOrder({
        ...salesOrder,
        shipToPhone: adr.phone, // @beta: additionally added for testing
        shipToEmail: adr.email, // @beta: additionally added for testing
        shipToCity: adr.city,
        shipToStreet: adr.street,
        shipToStreet2: adr.street2,
        shipToState: adr.state,
        shipToPostalCode: adr.postalCode,
        shipToName: adr.name,
        shipToCountry: adr.country,
        shipToCompanyName: adr.companyName,
        shipToResidential: adr.residential,
        shipToAttention: adr.attention,
        shipToVerified: adr.verified,
      });
    },
    [setSalesOrder, salesOrder]
  );

  const handleVerifyClick = useCallback(async () => {
    if (!connection) {
      return;
    }

    setIsLoading(true);

    try {
      const validatedAddress = await getValidateAddress(
        connection,
        shipToAddressTransformed
      );

      if (!shipToAddress.verified) {
        setAddressValidationModalVisible(true);
      }

      setAddressValidation(validatedAddress);
    } catch (error) {
      logErrorCtx('Error in handleVerifyClick', {
        error: error as Error,
        stackTrace: (error as Error).stack,
        component: 'SalesOrderDetailsTabBeta SalesOrderDetailsTab',
        title: 'Error in handleVerifyClick',
        description: 'Error in handleVerifyClick',
      });
    }

    setIsLoading(false);
  }, [
    shipToAddressTransformed,
    shipToAddress.verified,
    connection,
    setIsLoading,
  ]);

  const onSaveVerified = useCallback(() => {
    setIsLoading(true);

    if (_.isEqual(billToAddress, shipToAddress)) {
      setVerifiedShipToAddress(addressValidation, setSalesOrder);

      setVerifiedBillToAddress(addressValidation, setSalesOrder);

      setAddressValidationModalVisible(false);

      showNotification('Bill to and ship to address validated', {
        variant: 'success',
      });

      setIsLoading(false);
      return;
    }

    setVerifiedShipToAddress(addressValidation, setSalesOrder);

    setAddressValidationModalVisible(false);

    showNotification('Ship to address validated', { variant: 'success' });

    setIsLoading(false);
  }, [
    setSalesOrder,
    addressValidation,
    setIsLoading,
    billToAddress,
    shipToAddress,
  ]);

  const handleAddressValidationModalClose = useCallback(
    () => setAddressValidationModalVisible(false),
    []
  );

  return (
    <Box p={CONTAINER_SPACING_SIZE} width="100%" overflow="auto">
      <Grid className={classes.gridRow} container spacing={2}>
        <Grid xs={2}>
          <Autocomplete
            label="Priority"
            options={orderPriorities}
            getOptionLabel={getLabelName}
            placeholder="Select priority"
            value={selectedOrderPriority}
            disabled={disableField('priority')}
            permissions={editPermission}
            onChange={handleAutocompleteChange('priorityId', null, 'id')}
          />
        </Grid>
        <Grid xs={2}>
          <DatePickerWrapper
            onChange={handleDateExpiresChanged}
            label="Expires"
            placeholder="Select expiration date"
            fullWidth
            disabled={disableField('expires')}
            permissions={editPermission}
            value={salesOrder.dateExpires}
          />
        </Grid>

        <Grid xs={2}>
          <TextField
            className={'redesign'}
            variant={'standard'}
            readOnly
            label="Created"
            value={
              salesOrder.dateCreated
                ? moment(salesOrder.dateCreated).format('MM/DD/YYYY')
                : 'N/A'
            }
          />
        </Grid>
        <Grid xs={2}>
          <TextField
            className={'redesign'}
            variant={'standard'}
            readOnly
            label="Modified"
            value={
              salesOrder.dateLastModified
                ? moment(salesOrder.dateLastModified).format('MM/DD/YYYY')
                : 'N/A'
            }
          />
        </Grid>
        <Grid xs={2}>
          <TextField
            className={'redesign'}
            variant={'standard'}
            readOnly
            label="Issued"
            value={
              salesOrder.dateIssued
                ? moment(salesOrder.dateIssued).format('MM/DD/YYYY')
                : 'Not Issued'
            }
          />
        </Grid>
        <Grid xs={2}>
          <TextField
            className={'redesign'}
            variant={'standard'}
            readOnly
            label="Completed"
            disabled
            value={
              salesOrder.dateFulfilled
                ? moment(salesOrder.dateFulfilled).format('MM/DD/YYYY')
                : 'Not Completed'
            }
          />
        </Grid>
      </Grid>

      <Grid className={classes.gridRow} container spacing={2}>
        <Grid xs={3}>
          <RepresentativesAutocomplete
            label="Sales Representative"
            value={representative}
            permissions={editPermission}
            disabled={disableField('salesRepresentative')}
            placeholder="Select Sales Representative"
            onChange={handleRepresentativeChange}
          />
        </Grid>
        <Grid xs={3}>
          <Autocomplete
            label="FOB"
            options={FOBS}
            getOptionLabel={getLabelName}
            disabled={disableField('fob')}
            placeholder="Select FOB"
            value={selectedFob}
            permissions={editPermission}
            onChange={handleAutocompleteChange('fobPointId', null, 'id')}
          />
        </Grid>
        <Grid xs={3}>
          <Autocomplete
            label="Payment Terms"
            options={paymentTerms}
            getOptionLabel={getLabelName}
            placeholder="Select payment term"
            permissions={editPermission}
            disabled={disableField('paymentTerms')}
            value={selectedPaymentTerm}
            onChange={handleAutocompleteChange('paymentTermId', null, 'id')}
          />
        </Grid>
        <Grid xs={3}>
          <Autocomplete
            label="Shipping Terms"
            options={SHIPPING_TERMS}
            getOptionLabel={getLabelName}
            disabled={disableField('shippingTerms')}
            placeholder="Select shipping term"
            permissions={editPermission}
            value={selectedShippingTerm}
            onChange={handleAutocompleteChange('shippingTermId', null, 'id')}
          />
        </Grid>
      </Grid>

      <Grid className={classes.gridRow} container spacing={2}>
        <Grid xs={6}>
          <FormRadioGroup
            id={PRINT_STATUS.id}
            label={PRINT_STATUS.label}
            value={_.get(selectedPrintStatus, 'value')}
            onChange={handleAutocompleteChange(PRINT_STATUS.id)}
            options={PRINT_STATUSES}
            selectedOption={selectedPrintStatus}
            disabled={disableField('printStatus')}
          />
        </Grid>
        <Grid xs={6}>
          <FormRadioGroup
            id={EMAIL_STATUS.id}
            label={EMAIL_STATUS.label}
            value={_.get(selectedEmailStatus, 'value')}
            onChange={handleAutocompleteChange(EMAIL_STATUS.id)}
            options={EMAIL_STATUSES}
            selectedOption={selectedEmailStatus}
            disabled={disableField('emailStatus')}
          />
        </Grid>
      </Grid>
      <GridDivider top={3} bottom={3} transparent />

      <Grid container xs={12} spacing={2}>
        <Grid xs={6}>
          <Grid xs={12}>
            <Typography>
              <b>Billing Address</b>
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Box display="flex" alignItems="center">
              <TextFieldAddressBeta
                label="Bill To"
                value={billToAddress}
                addressChoices={addressChoices}
                permissions={editPermission}
                disabled={disableField('billTo')}
                placeholder="Select bill to address"
                onChange={handleBillToChanged}
                customerId={salesOrder.customerId}
                salesOrder={salesOrder}
                setSalesOrder={setSalesOrder}
              />
            </Box>
            <Box px={2} py={2}>
              <AddressData heading={false} data={billToAddress} />
            </Box>
          </Grid>
        </Grid>

        <Grid xs={6}>
          <Grid xs={12}>
            <Typography>
              <b>Shipping Address</b>
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Box display="flex" alignItems="center">
              <TextFieldAddressBeta
                label="Ship To"
                value={shipToAddress}
                addressChoices={addressChoices}
                placeholder="Select ship to address"
                disabled={disableField('shipTo')}
                permissions={editPermission}
                onChange={handleShipToChanged}
                customerId={salesOrder.customerId}
                additionalActionItems={customShipToActionItems}
                salesOrder={salesOrder}
                setSalesOrder={setSalesOrder}
              />

              <VerifiedButton
                shipToAddress={shipToAddress}
                handleVerifyClick={handleVerifyClick}
                isLoading={isLoading}
                marginLeft={2}
              />
            </Box>
            <Box px={2} py={2}>
              <AddressData heading={false} data={shipToAddress} />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <GridDivider top={3} bottom={3} transparent />

      <AddressValidationModal
        onClose={handleAddressValidationModalClose}
        modalVisible={addressValidationModalVisible}
        addressValidation={addressValidation}
        onSaveOriginal={handleAddressValidationModalClose}
        onSaveVerified={onSaveVerified}
      />
    </Box>
  );
};

export default memo(SalesOrderDetailsTab);
