import { defaultMaximumPagination, RequestConfig } from 'helpers';
import {
  createApiCall,
  DataWithPagination,
  paginatedApiCall,
} from 'services/api';

import {
  transformLedger,
  transformLedgerForWaitingToExportModal,
  transformLedgerSimple,
} from './transformations';
import { Ledger, LedgerForWaitingToExportModal, LedgerSimple } from './types';

export const getLedgersAPI = async (
  config: RequestConfig,
  startDate: string,
  endDate: string
): Promise<DataWithPagination<Ledger>> => {
  const { pagination = defaultMaximumPagination } = config;
  const path = `/v1/ledgers/general?startDate=${startDate}&endDate=${endDate}`;

  const res = await paginatedApiCall(path, pagination, transformLedger);
  return res;
};

export const fetchLedgerSimple = async (id: number): Promise<LedgerSimple> => {
  const response = await createApiCall(
    {
      path: `/v1/ledgers/simple/${id}`,
      method: 'GET',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformLedgerSimple(response.data);
};

export const fetchLedgerDetails = async (
  id: number,
  startDate: string,
  endDate: string,
  quickSearchValue?: string | null
): Promise<Ledger> => {
  let path = `/v1/ledgers/general/${id}?startDate=${startDate}&endDate=${endDate}`;

  if (quickSearchValue) {
    path += `&quickSearchValue=${quickSearchValue}`;
  }

  const response = await createApiCall(
    {
      path,
      method: 'GET',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();
  return transformLedger(response.data);
};

export const createAccount = async (createAccData: any): Promise<void> => {
  await createApiCall(
    {
      path: `/v1/accounts`,
      method: 'POST',
      body: [createAccData],
    },
    {
      getMessage: {
        success: () => 'Account saved',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();
};

export const markAsPosted = async (): Promise<void> => {
  await createApiCall(
    {
      path: `/v1/ledgers/mark_as_posted`,
      method: 'POST',
    },
    {
      getMessage: {
        success: () => 'Marked as posted',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();
};
export const markAsPostedById = async (Ids: number[]): Promise<void> => {
  await createApiCall(
    {
      path: `/v1/ledgers/mark_as_posted`,
      method: 'POST',
      body: Ids,
    },
    {
      getMessage: {
        success: () => 'Marked as posted',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();
};

export const fetchWaitingToExportLedgerDetails = async (
  ledgerType: string
): Promise<LedgerForWaitingToExportModal[]> => {
  const response = await createApiCall(
    {
      path: `/v1/ledgers?orderBy=postDate&&descending=true&filterByNotPosted=true&type=${ledgerType}`,
      method: 'GET',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return response.data.results.map(transformLedgerForWaitingToExportModal);
};
export const fetchAllPaginatedLedgerDetails = async <T>(
  ledgerType: string,
  transformation?: (res: any) => T
): Promise<T[]> => {
  const path = `/v1/ledgers?orderBy=postDate&&descending=true&filterByNotPosted=true&type=${ledgerType}`;
  const initialResponse = await paginatedApiCall<T>(
    path,
    defaultMaximumPagination,
    transformation
  );

  const totalPages = Math.ceil(
    (initialResponse.pagination.totalRows || 0) /
      (initialResponse.pagination.pageSize || 1)
  );

  const allPages = Array.from({ length: totalPages - 1 }, (_, i) => i + 2);

  const paginatedPromises = allPages.map((page) =>
    paginatedApiCall<T>(
      path,
      { ...defaultMaximumPagination, page },
      transformation
    )
  );

  const responses = await Promise.all(paginatedPromises);

  const allItems = [
    initialResponse.data,
    ...responses.map((r) => r.data),
  ].flat();

  return allItems;
};
