import React, { memo, useCallback, useState } from 'react';
import { Box } from '@mui/material';
import _ from 'lodash';

import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import {
  ItemsTable,
  useSelectedItemsChanges,
} from 'ui/components/Table/ItemsTable';
import { initialSubstituteItem, SubstituteItem } from 'services/items';
import { showNotification } from 'services/api';
import { findNextNegativeId, replaceValueInCollection } from 'helpers';

import {
  SubstituteItemAddModal,
  SubstituteItemRow,
  SubstituteItemTableRowActions,
} from './components';
import { SUBSTITUTE_ITEMS_COLUMNS } from './consts';
import { SubstituteItemsTabProps } from './types';
import { editItemPermissions } from '../../helpers';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const SubstituteItemsTab: React.FC<SubstituteItemsTabProps> = (props) => {
  const { item, setItem } = props;

  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [showSubstituteModal, setShowSubstituteModal] = useState(false);
  const [substituteItem, setSubstituteItem] = useState<SubstituteItem>(
    initialSubstituteItem
  );
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const editPermissions = editItemPermissions(item);

  const onCancelClicked = () => setShowSubstituteModal(false);

  const handleAddNewClicked = useCallback(() => {
    setSubstituteItem({
      ...initialSubstituteItem,
      id: findNextNegativeId(item.itemSubstitutes),
    });

    setShowSubstituteModal(true);
  }, [item.itemSubstitutes]);

  const onApplyClicked = useCallback(() => {
    const substituteItemId = _.get(substituteItem, 'substituteItemId', null);

    if (!substituteItemId) {
      showNotification('Substitute Item must be selected', {
        variant: 'warning',
      });

      return;
    }

    const newSubstituteItems = [...item.itemSubstitutes];

    newSubstituteItems.push(substituteItem);

    setItem((prevItem) => ({
      ...prevItem,
      itemSubstitutes: newSubstituteItems,
    }));
    setShowSubstituteModal(false);
  }, [item.itemSubstitutes, setItem, substituteItem]);

  const handleAction = useCallback(
    (action: any) => {
      const type: SubstituteItemTableRowActions = action.type;
      switch (type) {
        case SubstituteItemTableRowActions.Delete: {
          setItem((prevItem) => ({
            ...prevItem,
            itemSubstitutes:
              replaceValueInCollection(
                prevItem.itemSubstitutes,
                {
                  ...prevItem.itemSubstitutes[action.itemIndex],
                  deleted: true,
                },
                action.itemIndex
              ) || [],
          }));
          break;
        }
      }
    },
    [setItem]
  );

  const handleDeleteModalVisible = (visible: boolean) => () =>
    setDeleteModalVisible(visible);

  const handleSelectedChange = useSelectedItemsChanges(
    selectedItems,
    setSelectedItems
  );

  const handleDeleteClicked = useCallback(() => {
    const deletedSubstituteItems = item.itemSubstitutes
      .map((i) => ({ ...i, deleted: selectedItems.includes(i.id) }))
      .filter((i) => !(i.id < 0 && i.deleted));

    setItem((prevItem) => ({
      ...prevItem,
      itemSubstitutes: deletedSubstituteItems,
    }));

    setSelectedItems([]);
    setDeleteModalVisible(false);
  }, [item.itemSubstitutes, selectedItems, setItem]);

  return (
    <Box display="flex" flexDirection="column" height="100%" width={'100%'}>
      <FBOTitleBar title="Substitute Items">
        {!_.isEmpty(selectedItems) && (
          <FBOButton
            variant="secondary"
            color="negative"
            size="medium"
            icon="TrashCan"
            data-qa="substitute-item-delete"
            onClick={handleDeleteModalVisible(true)}
            permissions={editPermissions}
          >
            Delete
          </FBOButton>
        )}
        <FBOButton
          variant="secondary"
          color="positive"
          size="medium"
          icon="FBOAddCircle"
          data-qa="substitute-item-add-new"
          onClick={handleAddNewClicked}
          permissions={editPermissions}
        >
          Add New
        </FBOButton>
      </FBOTitleBar>

      <ItemsTable
        data={item.itemSubstitutes}
        columns={SUBSTITUTE_ITEMS_COLUMNS}
        filterRows={(i) => !i.deleted}
        RenderCustomRow={SubstituteItemRow}
        onSelectedChange={handleSelectedChange}
        selectedItems={selectedItems}
        onAction={handleAction}
        dataQa="substitute-item-tab-table"
        tableLayoutFixed
      />
      <SubstituteItemAddModal
        show={showSubstituteModal}
        item={item}
        setSubstituteItem={setSubstituteItem}
        onCancelClicked={onCancelClicked}
        onApplyClicked={onApplyClicked}
      />
      <ConfirmationModal
        open={deleteModalVisible}
        title="Delete Checked Item"
        body={`This will delete selected items, are you sure?`}
        onCancelClicked={handleDeleteModalVisible(false)}
        onConfirmClicked={handleDeleteClicked}
        confirmLabel="Delete"
        cancelLabel="Cancel"
        confirmButtonRed
      />
    </Box>
  );
};

export default memo(SubstituteItemsTab);
