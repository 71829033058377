import { ItemInventoryEvents } from 'services/inventory';
import { TrackingTableTypes } from 'ui/components/Table/TrackingTable';

interface ResolvedLabel {
  modalLabel: string;
  buttonLabel: string;
}

export function resolveLabels(
  eventType: ItemInventoryEvents,
  quantity: number,
  uom: string = 'Items',
  itemName?: string | null
): ResolvedLabel {
  switch (eventType) {
    case ItemInventoryEvents.Add:
      return {
        modalLabel: `Add inventory - ${itemName}`,
        buttonLabel: `Add ${quantity} ${uom}`,
      };
    case ItemInventoryEvents.Remove:
      return {
        modalLabel: `Remove inventory - ${itemName}`,
        buttonLabel: `Remove ${quantity} ${uom}`,
      };
    case ItemInventoryEvents.Cycle:
      return {
        modalLabel: `Cycle Inventory - ${itemName}`,
        buttonLabel: `Cycle ${quantity} ${uom}`,
      };
    case ItemInventoryEvents.Move:
      return {
        modalLabel: `Move inventory - ${itemName}`,
        buttonLabel: `Move ${quantity} ${uom}`,
      };
  }
}

export function mapInventoryEventToTrackingTableType(
  event: ItemInventoryEvents
): TrackingTableTypes {
  switch (event) {
    case ItemInventoryEvents.Add:
      return TrackingTableTypes.Add;
    case ItemInventoryEvents.Cycle:
      return TrackingTableTypes.Cycle;
    default:
      return TrackingTableTypes.Remove;
  }
}
export function calculateCost(
  uomId: string,
  averageCost: string,
  itemUomConversionList: any[]
): number {
  const factor = itemUomConversionList?.find(
    (f: any) => f.toUomId == uomId || f.fromUomId == uomId
  );
  if (Number(uomId) === 1) {
    return parseFloat(averageCost);
  } else {
    return parseFloat(averageCost) * (factor ? factor.factor : 1);
  }
}
