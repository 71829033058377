import { State } from 'app/redux';
import { hasPermission, PermissionType } from 'services/permissions';

import { User } from '../types';

// ACTIVE USER

export const getActiveUser = (state: State) => state.user.activeUser;

export const getActiveUserLoading = (state: State) =>
  state.user.activeUser._status.isLoading;

export const activeUserHasPermission =
  (allowedPermissions: PermissionType[]) => (state: State) => {
    const { permissions, isAdmin, user } = getActiveUser(state);

    // We need to be able to redirect if there is no user
    // We return by default true, than redirect if necesary
    if (!user) {
      return true;
    }

    return hasPermission(allowedPermissions, permissions, isAdmin);
  };

// USERS

export const getUsers = (state: State) => state.userV2.users.items;

export const getSearches = (state: State) => state.user.searches;

export const getUserById = (id: number | null) => (state: State) => {
  if (id === null) {
    return undefined;
  }
  return state.userV2.users.items.find((item: User) => item.id === id);
};
