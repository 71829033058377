import React, { useState, useEffect, useCallback, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Tabs, Tab } from '@mui/material';

import {
  ItemInventory,
  getItemInventory,
  initialItemInventory,
} from 'services/inventory';
import { TabPanel } from 'ui/components/TabPanel';
import { initialItem } from 'services/items/consts';
import { ReportId } from 'services/reports';
import { ReportsModal } from 'ui/components/Modal/ReportsModal';
import { DetailsCard } from 'ui/components/Page/DetailsCard';
import { fetchItem, Item } from 'services/items';
import { showNotification } from 'services/api';
import {
  clearModuleNavigation,
  ModuleNavigationType,
  updateModuleNavigation,
} from 'services/moduleNavigation';

import { ItemDetailsProps } from './types';
import { useItemDetailsCardStyle } from './styled';
import { createActionBarOptions } from './consts';
import { InventoryTab } from './components/InventoryTab';
import { InventoryCostingTab } from './components/InventoryCostingTab';
import { FBOInventoryHeroStatus } from './components/InventoryHeroStatus';
import { getErrorMessage } from 'helpers';
import { logErrorCtx } from 'app/logging';

import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { activeUserHasPermission } from 'services/userV2/redux';
import { PermissionType } from 'services/permissions';

const ItemDetailsCard: React.FC<ItemDetailsProps> = (props) => {
  const {
    activeItemId,
    onCancel,
    fetchSearchResult,
    duplicateArray,
    setDuplicateArray,
  } = props;
  const dispatch = useDispatch();
  const classes = useItemDetailsCardStyle();

  const [itemInventory, setItemInventory] =
    useState<ItemInventory>(initialItemInventory);
  const [isLoading, setIsLoading] = useState(true);
  const [showReportModal, setShowReportModal] = useState(false);
  const [activeReportModalId, setActiveReportModalId] = useState<ReportId>(
    ReportId.Items
  );
  const [activeReportParameters, setActiveReportParameters] = useState({});
  const [activeItem, setActiveItem] = useState<Item>(initialItem);
  const [activeTab, setActiveTab] = useState(0);
  const [fetchInventory, setFetchInventory] = useState(false);
  const showCosting = useSelector(
    activeUserHasPermission([PermissionType.InventoryCosting])
  );

  const triggerFetchInventory = () => {
    setFetchInventory(!fetchInventory);
  };

  const handleReportModalClick = useCallback(
    (reportId: ReportId) => {
      if (
        reportId === ReportId.Items ||
        reportId === ReportId.CycleCount ||
        reportId === ReportId.InventoryValuation ||
        reportId === ReportId.Reorder
      ) {
        setActiveReportParameters({ itemId: activeItem.id });
      }
      setShowReportModal(true);
      setActiveReportModalId(reportId);
    },
    [activeItem.id]
  );

  useEffect(() => {
    setActiveTab(0);

    (async () => {
      setIsLoading(true);
      if (activeItemId && activeItemId !== -1) {
        try {
          const item = await fetchItem(activeItemId);
          const inventory = await getItemInventory(activeItemId, true);

          setActiveItem(item);
          setItemInventory(inventory);
        } catch (e) {
          const message = getErrorMessage(e);
          logErrorCtx('Error in fetchItem', {
            error: e as Error,
            stackTrace: (e as Error).stack,
            component: 'ItemDetailsCard',
            title: 'Error in fetchItem',
            description: `Item id ${activeItemId}`,
          });
          showNotification(`${message} - inventory item couldn't be loaded.`, {
            variant: 'error',
          });
          setIsLoading(false);
          onCancel();

          return;
        }
      }

      setIsLoading(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItemId, fetchInventory]);

  useEffect(() => {
    if (!activeItemId) {
      dispatch(clearModuleNavigation(ModuleNavigationType.Materials));
      return;
    }

    dispatch(
      updateModuleNavigation(ModuleNavigationType.Materials, {
        itemId: activeItemId,
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItemId]);

  const handleActiveTabChange = useCallback(
    async (_event: React.ChangeEvent<{}>, newValue: number) => {
      setActiveTab(newValue);
    },
    []
  );

  return (
    <DetailsCard isLoading={isLoading}>
      <FBOTitleBar title={activeItem.name}>
        <FBOMenuButton
          variant="tertiary"
          data-qa="inventory-item-three-dot-menu"
          items={createActionBarOptions(handleReportModalClick)}
        />
        <FBOButton
          variant="tertiary"
          color="neutral"
          size="medium"
          icon="FBOClose"
          data-qa="inventory-close"
          onClick={onCancel}
        />
      </FBOTitleBar>
      <Tabs
        value={activeTab}
        onChange={handleActiveTabChange}
        indicatorColor="primary"
        className="redesign"
      >
        <Tab label="Inventory" />
        {showCosting && <Tab label="Costing" />}
      </Tabs>
      <FBOInventoryHeroStatus
        item={activeItem}
        itemInventory={itemInventory}
        setItem={setActiveItem}
        triggerFetchInventory={triggerFetchInventory}
      />

      <TabPanel value={activeTab} index={0} noSpacing>
        <Box className={classes.tableContainer}>
          <InventoryTab
            activeItem={activeItem}
            itemInventory={itemInventory}
            setActiveItem={setActiveItem}
            setItemInventory={setItemInventory}
            activeItemId={activeItemId}
            fetchSearchResult={fetchSearchResult}
            duplicateArray={duplicateArray}
            setDuplicateArray={setDuplicateArray}
          />
        </Box>
      </TabPanel>
      <TabPanel value={activeTab} index={1} noSpacing>
        <Box className={classes.tableContainer}>
          <InventoryCostingTab activeItem={activeItem} />
        </Box>
      </TabPanel>
      <ReportsModal
        isOpen={showReportModal}
        reportId={activeReportModalId}
        params={activeReportParameters}
        onClose={() => setShowReportModal(false)}
        autoGenerate
      />
    </DetailsCard>
  );
};
export default memo(ItemDetailsCard);
