import React, { memo } from 'react';

import { ImageStorageType } from 'services/images';
import { PurchaseOrderStatus } from 'services/purchaseOrders';
import { DocumentsTable } from 'ui/components/Documents/DocumentsTable';

import { editPurchaseOrderPermissions } from '../../helpers';
import { PurchaseOrderDocumentsTabProps } from './types';

const PurchaseOrderDocumentsTab: React.FC<PurchaseOrderDocumentsTabProps> = (
  props
) => {
  const { purchaseOrder, setPurchaseOrder } = props;

  const editPermission = editPurchaseOrderPermissions(purchaseOrder);

  return (
    <DocumentsTable
      storageType={ImageStorageType.PurchaseOrderDocuments}
      data={purchaseOrder}
      disabled={purchaseOrder.status === PurchaseOrderStatus.Fulfilled}
      setData={setPurchaseOrder}
      permissions={editPermission}
    />
  );
};

export default memo(PurchaseOrderDocumentsTab);
