import { combineReducers } from 'redux';

import {
  createApiReducer,
  chainReducers,
  INITIAL_API_STATE,
  INITIAL_API_STATUS,
} from 'services/api';
import {
  createFetchSearchReducer,
  createUpdateSearchReducer,
  createAddSearchReducer,
  createDeleteSearchReducer,
} from 'services/search';
import { AuthActionTypes } from 'services/auth/redux/actions';

import { transformActiveUser, transformRequestUser } from '../transformations';
import { ActiveUserState } from './types';
import { UsersActionTypes } from './actions';

// USERS REDUCER

const fetchItemsReducer = createApiReducer(
  [
    UsersActionTypes.FETCH_USERS_START,
    UsersActionTypes.FETCH_USERS_SUCCESS,
    UsersActionTypes.FETCH_USERS_FAILURE,
  ],
  (data) => ({ items: data.results.map(transformRequestUser) }),
  (data) => data
);

const usersChainedReducers = chainReducers(
  INITIAL_API_STATE,
  fetchItemsReducer
);

// SEARCH REDUCER

const fetchSearchReducer = createFetchSearchReducer('user');
const addSearchReducer = createAddSearchReducer('user');
const updateSearchReducer = createUpdateSearchReducer('user');
const deleteSearchReducer = createDeleteSearchReducer('user');

const searchChainedReducers = chainReducers(
  INITIAL_API_STATE,
  fetchSearchReducer,
  addSearchReducer,
  updateSearchReducer,
  deleteSearchReducer
);

// ACTIVE USER REDUCER

const initialState: ActiveUserState = {
  currencyCode: null,
  environment: null,
  tenant: null,
  user: null,
  userGlobalId: null,
  _status: INITIAL_API_STATUS,
  permissions: [],
  isAdmin: false,
  externalAccountId: null,
  externalContactId: null,
  isExecutor: false,
  maxIntegrations: null,
};

const signInReducer = createApiReducer(
  [
    AuthActionTypes.SIGN_IN_START,
    AuthActionTypes.SIGN_IN_SUCCESS,
    AuthActionTypes.SIGN_IN_FAILURE,
  ],
  transformActiveUser,
  (data) => data
);

const fetchCurrentUserReducer = createApiReducer(
  [
    UsersActionTypes.FETCH_ACTIVE_USER_START,
    UsersActionTypes.FETCH_ACTIVE_USER_SUCCESS,
    UsersActionTypes.FETCH_ACTIVE_USER_FAILURE,
  ],
  transformActiveUser,
  (data) => data
);

const signOutReducer = createApiReducer(
  ['', AuthActionTypes.SIGN_OUT, ''],
  () => initialState,
  () => initialState
);

const activeUserReducer = chainReducers(
  initialState,
  signInReducer,
  fetchCurrentUserReducer,
  signOutReducer
);

// COMBINED REDUCER

export default combineReducers({
  activeUser: activeUserReducer,
  users: usersChainedReducers,
  searches: searchChainedReducers,
});
