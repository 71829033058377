import { Uom } from 'services/uoms';

export const removeUomsWithNoConversion = (defaultUOMId: number | null) => {
  return (options: Uom[]) => {
    if (!defaultUOMId) {
      return options;
    }

    const filteredOptions = options?.filter((uom) => {
      return (
        uom.id === defaultUOMId ||
        uom?.fromConversions?.some(
          ({ fromUomId }) => fromUomId === defaultUOMId
        ) ||
        uom?.toConversions?.some(({ toUomId }) => toUomId === defaultUOMId)
      );
    });

    if (filteredOptions.length === 0) {
      const defaultUOM = options?.find((uom) => uom.id === defaultUOMId);
      return defaultUOM ? [defaultUOM] : options;
    }

    return filteredOptions;
  };
};
