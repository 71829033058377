import _ from 'lodash';

import { Ship } from 'services/shipping';

export const isLocationAddressValid = (ship: Ship) => {
  const soAddress = _.get(ship, 'salesOrder.location.address', null);
  if (!soAddress) {
    return false;
  }
  return !!(
    soAddress.postalCode &&
    soAddress.state &&
    soAddress.street &&
    soAddress.city
  );
};

export const isLocationNameValid = (ship: Ship) => {
  const soAddress = _.get(ship, 'salesOrder.location.address', null);
  if (!soAddress) {
    return false;
  }
  return !!(soAddress.companyName || soAddress.name);
};
