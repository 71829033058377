import React from 'react';
import Measure from 'react-measure';
import { Box } from '@mui/material';
import { MeasureRef } from './types';
import { usePdfViewerStyles } from './styled';
import { NetworkSpinnerWrapper } from '../../../NetworkSpinnerWrapper';

interface Props {
  isLoading: boolean;
  htmlReport: string;
  scale: number;
}

export default function HtmlReport({ scale, isLoading, htmlReport }: Props) {
  const classes = usePdfViewerStyles({ scale });
  return (
    <Box className={`${classes.scrollHtml} docContainer`}>
      <NetworkSpinnerWrapper isLoading={isLoading} size={48} height="100%">
        <Measure bounds>
          {({ measureRef }: { measureRef: MeasureRef }) => (
            <div
              id="html-report-div"
              className={classes.htmlLayout}
              ref={measureRef}
              dangerouslySetInnerHTML={{
                __html: htmlReport,
              }}
            ></div>
          )}
        </Measure>
      </NetworkSpinnerWrapper>
    </Box>
  );
}
