import React, { memo, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import moment from 'moment';
import _ from 'lodash';

import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { TextField } from 'ui/components/TextField/TextField';
import { DatePickerWrapper } from 'ui/components/TextField/DatePickerWrapper';
import { PermissionType } from 'services/permissions';
import { TextFieldQuantity } from 'ui/components/TextField/TextFieldQuantity';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import { Location } from 'services/locations';
import { EACH_UOM_ID, getUoms } from 'services/uoms';
import {
  ReceiptItem,
  ReceiptItemStatus,
  RejectionResponsibilityType,
} from 'services/receiving';
import { useHandleTextFieldChange } from 'services/forms';
import { getCarriers } from 'services/carriers';
import { getCustomers } from 'services/customers';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';
import { useGetIntlDateFormatString } from 'helpers';

import { Fields, ReceivingTabReceivingProps } from './types';
import { disableMapping } from './consts';

const ReceivingTabReceiving: React.FC<ReceivingTabReceivingProps> = (props) => {
  const { receipt, activeReceiptItem, setActiveReceiptItem, errors } = props;

  const { items: carriers } = useSelector(getCarriers);
  const { items: customers } = useSelector(getCustomers);
  const { items: uoms } = useSelector(getUoms);

  const intlFormatDate = useGetIntlDateFormatString();

  const resolvedItemType = useMemo(() => {
    const type = activeReceiptItem.item
      ? activeReceiptItem.item.itemType
      : null;
    if (type === 'Shipping' || type === 'Service' || type === 'Non Inventory') {
      return true;
    }
    return false;
  }, [activeReceiptItem.item]);

  const selectedCarrier = useMemo(() => {
    return carriers.find((c) => c.id === activeReceiptItem.carrierId) || null;
  }, [carriers, activeReceiptItem]);

  const selectedService = useMemo(() => {
    if (!selectedCarrier) {
      return null;
    }
    return (
      selectedCarrier.carrierServiceList.find(
        (c) => c.id === activeReceiptItem.carrierServiceId
      ) || null
    );
  }, [activeReceiptItem.carrierServiceId, selectedCarrier]);

  const selectedCustomer = useMemo(() => {
    return customers.find((c) => c.id === activeReceiptItem.customerId) || null;
  }, [activeReceiptItem.customerId, customers]);

  const handleDateChange = useCallback(
    (name: string) => (value: any) => {
      const date = moment(value);

      setActiveReceiptItem((old) => ({
        ...old,
        [name]: !value ? null : date.toDate(),
      }));
    },
    [setActiveReceiptItem]
  );

  const handleTextFieldChange = useHandleTextFieldChange<ReceiptItem>(
    setActiveReceiptItem,
    activeReceiptItem
  );
  const handleNumberFieldChange = useHandleTextFieldChange<ReceiptItem>(
    setActiveReceiptItem,
    activeReceiptItem,
    true
  );

  const handleAutocompleteChange = useCallback(
    (name: string) => (_e: any, v: any) => {
      setActiveReceiptItem((old) => ({ ...old, [name]: v ? v.id : null }));
    },
    [setActiveReceiptItem]
  );

  const handleRespAutocompleteChange = useCallback(
    (_e: React.ChangeEvent<{}>, v: RejectionResponsibilityType | null) => {
      setActiveReceiptItem((old) => ({
        ...old,
        rejectionResponsibility: v,
      }));
    },
    [setActiveReceiptItem]
  );

  const autocompleteLabelResolver = useCallback((option) => option.name, []);

  const handleLocationChange = useCallback(
    (v: Location | null) => {
      setActiveReceiptItem((f) => ({
        ...f,
        locationId: v ? v.id : null,
      }));
    },
    [setActiveReceiptItem]
  );

  const getRececiptItemUnitCost = () => {
    if (activeReceiptItem.salesOrderItem) {
      return activeReceiptItem.salesOrderItem.price;
    }

    return _.get(activeReceiptItem, 'purchaseOrderItem.unitCost');
  };

  return (
    <Box p={3} width="100%">
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <LocationsAsyncAutocomplete
            placeholder="Select Location"
            onChange={handleLocationChange}
            label="Location"
            value={activeReceiptItem ? activeReceiptItem.locationId : null}
            companyWide={false}
            parentId={receipt.locationId!}
            disabled={
              resolvedItemType
                ? true
                : disableMapping[Fields.Location][activeReceiptItem.status!]
            }
            permissions={[PermissionType.ReceivingModifyItemLocation]}
            dataQa="receiving-item-location"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="Ref No."
            placeholder="Enter ref no."
            name="referenceNumber"
            value={activeReceiptItem.referenceNumber}
            onChange={handleTextFieldChange}
            disabled={disableMapping[Fields.RefNo][activeReceiptItem.status!]}
            dataQa="receiving-item-ref-no"
          />
        </Grid>
        <Grid item xs={2}>
          <TextFieldQuantity
            label="Quantity"
            placeholder="Quantity"
            name="quantity"
            value={activeReceiptItem.quantity}
            selectedUomId={activeReceiptItem.uomId || EACH_UOM_ID}
            error={false}
            uoms={uoms}
            readOnly
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyField
            readOnly
            label="Unit cost"
            value={getRececiptItemUnitCost()}
            allowNegative
          />
        </Grid>
        <Grid item xs={5}>
          <Autocomplete
            placeholder="Select customer"
            label="Customer"
            options={customers}
            name="customerId"
            getOptionLabel={autocompleteLabelResolver}
            onChange={handleAutocompleteChange('customerId')}
            value={selectedCustomer}
            disabled={
              disableMapping[Fields.CustomerJob][activeReceiptItem.status!]
            }
            dataQa="receiving-item-customer"
          />
        </Grid>
        <Grid item xs={3}>
          <DatePickerWrapper
            value={activeReceiptItem.dateBilled}
            label="Bill Date"
            placeholder="Enter bill date"
            name="dateBilled"
            onChange={handleDateChange('dateBilled')}
            error={errors.dateBilled}
            disabled={
              disableMapping[Fields.BillDate][activeReceiptItem.status!]
            }
            inputProps={{
              'data-qa': 'receiving-item-bill-date' as any,
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            className="redesign"
            variant="standard"
            readOnly
            label="Received"
            value={
              activeReceiptItem.dateReceived
                ? moment(activeReceiptItem.dateReceived).format(intlFormatDate)
                : 'N/A'
            }
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            className="redesign"
            variant="standard"
            readOnly
            label="Reconciled"
            value={
              activeReceiptItem.dateReconciled
                ? moment(activeReceiptItem.dateReconciled).format(
                    intlFormatDate
                  )
                : 'N/A'
            }
          />
        </Grid>
      </Grid>
      <Box mt={3} />
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Autocomplete
            placeholder="Select carrier"
            label="Carrier"
            name="carrierId"
            options={carriers}
            getOptionLabel={autocompleteLabelResolver}
            onChange={handleAutocompleteChange('carrierId')}
            value={selectedCarrier}
            disabled={disableMapping[Fields.Carrier][activeReceiptItem.status!]}
            required
            dataQa="receiving-item-carrier"
            error={!!errors.carrierId}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            placeholder="Select service"
            label="Service"
            name="carrierServiceId"
            options={selectedCarrier ? selectedCarrier.carrierServiceList : []}
            getOptionLabel={autocompleteLabelResolver}
            onChange={handleAutocompleteChange('carrierServiceId')}
            value={selectedService}
            disabled={disableMapping[Fields.Service][activeReceiptItem.status!]}
            dataQa="receiving-item-service"
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="Track No."
            placeholder="Select track no."
            name="carrierTracking"
            value={activeReceiptItem.carrierTracking}
            onChange={handleTextFieldChange}
            disabled={disableMapping[Fields.TrackNo][activeReceiptItem.status!]}
            dataQa="receiving-item-track-no"
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            className="redesign"
            variant="standard"
            type="number"
            label="Package Count"
            placeholder="Enter package count"
            name="carrierPackageCount"
            value={activeReceiptItem.carrierPackageCount}
            onChange={handleNumberFieldChange}
            disableDebounce={true}
            disabled={
              disableMapping[Fields.PackageCount][activeReceiptItem.status!]
            }
            dataQa="receiving-item-package-count"
          />
        </Grid>
        {activeReceiptItem.status === ReceiptItemStatus.Rejected && (
          <>
            <Grid item xs={6}>
              <Autocomplete
                label="Responsible"
                value={activeReceiptItem.rejectionResponsibility}
                onChange={handleRespAutocompleteChange}
                options={_.values(RejectionResponsibilityType)}
                error={!!errors.rejectionResponsibility}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="redesign"
                variant="standard"
                label="Reason"
                name="reasonForRejection"
                value={activeReceiptItem.reasonForRejection}
                onChange={handleTextFieldChange}
                placeholder="Enter reason"
                error={!!errors.reasonForRejection}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Box mt={3} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="Notes"
            placeholder="Enter notes"
            name="notes"
            value={activeReceiptItem.notes}
            multiline
            minRows="4"
            fullWidth
            onChange={handleTextFieldChange}
            disabled={disableMapping[Fields.Notes][activeReceiptItem.status!]}
            dataQa="receiving-item-notes"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(ReceivingTabReceiving);
