import { Receipt, ReceiptItem, ReceiptItemStatus } from 'services/receiving';

export interface ReceivingTabReceivingProps {
  receipt: Receipt;
  activeReceiptItem: ReceiptItem;
  setActiveReceiptItem: React.Dispatch<React.SetStateAction<ReceiptItem>>;
  errors: any;
}

export enum Fields {
  Location = 'location',
  RefNo = 'refNo',
  CustomerJob = 'customerJob',
  BillDate = 'billDate',
  Carrier = 'carrier',
  Service = 'service',
  TrackNo = 'trackNo',
  PackageCount = 'packageCount',
  Notes = 'notes',
}

export type FieldStatusMapping = {
  [key in Fields]: { [status in ReceiptItemStatus]: boolean };
};
