import _ from 'lodash';

import { TrackingGroup, TrackingInfo } from 'services/inventory';
import { replaceValueInCollection } from 'helpers';
import { TrackingDataTypes } from 'services/settings/tracking';
import { TrackingTableTypes } from 'ui/components/Table/TrackingTable';
import { Column } from 'ui/components/Table/ItemsTable';

import { TrackingColumnsProps } from './types';
import {
  TrackingAddColumns,
  TrackingEditColumns,
  TrackingRemoveCells,
  TrackingViewCells,
  TrackingReceivingViewCells,
  TrackingCycleColumns,
} from './components';

export const updateWithNewTrackingInfoEntry = (
  trackingGroup: TrackingGroup,
  newEntry: string | null,
  trackingInfoRowIndex: number
) => {
  const newTrackingInfoEntry = {
    ...trackingGroup.trackingInfoList[trackingInfoRowIndex],
    value: newEntry,
  };

  const newTrackingInfoList = replaceValueInCollection<TrackingInfo>(
    trackingGroup.trackingInfoList,
    newTrackingInfoEntry,
    trackingInfoRowIndex
  )!;

  return { ...trackingGroup, trackingInfoList: newTrackingInfoList };
};

export const resolveTrackingColumns = (
  tableType: TrackingTableTypes
): React.FC<TrackingColumnsProps> => {
  switch (tableType) {
    case TrackingTableTypes.Add:
      return TrackingAddColumns;
    case TrackingTableTypes.Remove:
      return TrackingRemoveCells;
    case TrackingTableTypes.Edit:
      return TrackingEditColumns;
    case TrackingTableTypes.View:
      return TrackingViewCells;
    case TrackingTableTypes.ReceivingView:
      return TrackingReceivingViewCells;
    case TrackingTableTypes.Cycle:
      return TrackingCycleColumns;
    case TrackingTableTypes.CycleNew:
      return TrackingAddColumns;
  }
};

export const getTrackingTypeColumns = (columns: Column[]): Column[] => {
  const newColumns: Column[] = [];

  // remove non tracking columns
  // move serial column to the first place of array
  columns
    .filter((c) => _.get(c, 'meta'))
    .forEach((column) => {
      if (
        _.get(column, 'meta.trackingDataType') ===
        TrackingDataTypes.SerialNumber
      ) {
        newColumns.unshift(column);
      } else {
        newColumns.push(column);
      }
    });

  return newColumns;
};
