import React, { useMemo, memo, useCallback } from 'react';
import { TableCell, Typography } from '@mui/material';
import _ from 'lodash';

import { MultiCurrencyWrapper } from 'ui/components/MultiCurrencyWrapper/MultiCurrencyWrapper';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import { PurchaseOrderItemTypes } from 'services/purchaseOrders';
import { ImageItemBox } from 'ui/components/Image/ImageItemBox';
import {
  ReconcileCalculateOptions,
  ReconcileReceiptItem,
} from 'services/receiving';
import {
  useCurrencyFormatter,
  toMulticurrencyCalculate,
  toHomeCurrencyCalculate,
} from 'helpers';

import { useReconcileWizardReconcileItemsStyle } from './styled';

const ReconcileWizardItemsRow: React.FC<
  RenderCustomRowProps<ReconcileReceiptItem>
> = (props) => {
  const {
    row,
    meta: { calculation, reconcilePrices, selectedItems },
    onAction,
  } = props;

  const currencyFormatter = useCurrencyFormatter();
  const currencyFormatterSix = useCurrencyFormatter(2, 6);

  const activeMulticurrencyCode = _.get(row, 'currency.code', null);

  const exchangeRate = _.get(row, 'currency.exchangeRate', 1);

  const resolveLandedCost = () => {
    if (row.purchaseOrderItemType === PurchaseOrderItemTypes.DropShip) {
      return reconcilePrice + row.landedTotalCost;
    }
    return reconcilePrice + row.totalCost;
  };

  const multiCurrencyReconcilePrice = useMemo(() => {
    return toMulticurrencyCalculate(
      reconcilePrices[row.id!] || 0,
      exchangeRate
    );
  }, [reconcilePrices, exchangeRate, row.id]);

  const classes = useReconcileWizardReconcileItemsStyle();

  const reconcilePrice = reconcilePrices[row.id!] || 0;
  const landedCost = resolveLandedCost();
  const landedUnitCost =
    landedCost && row.quantity ? landedCost / row.quantity : 0;

  const handleReconcileChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (onAction && row.id) {
        const value = event.target.value
          ? parseFloat(event.target.value)
          : null;
        onAction({ id: row.id, value });
      }
    },
    [onAction, row.id]
  );

  const handleMultiCurrencyReconcileChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (onAction && row.id) {
        const value = event.target.value ? parseFloat(event.target.value) : 0;
        onAction({
          id: row.id,
          value: toHomeCurrencyCalculate(value, exchangeRate),
        });
      }
    },
    [onAction, row.id, exchangeRate]
  );

  const resolvedMultiCurrencyCosts = useMemo(() => {
    const multicurrencyReconcilePrice = currencyFormatter(
      toMulticurrencyCalculate(reconcilePrice, exchangeRate),
      activeMulticurrencyCode
    );
    const multiCurrencyLandedUnitCost = currencyFormatter(
      toMulticurrencyCalculate(landedUnitCost, exchangeRate),
      activeMulticurrencyCode
    );
    const multiCurrencyLandedCost = currencyFormatter(
      toMulticurrencyCalculate(landedCost, exchangeRate),
      activeMulticurrencyCode
    );

    return {
      multicurrencyReconcilePrice,
      multiCurrencyLandedUnitCost,
      multiCurrencyLandedCost,
    };
  }, [
    activeMulticurrencyCode,
    exchangeRate,
    landedCost,
    landedUnitCost,
    reconcilePrice,
    currencyFormatter,
  ]);

  return (
    <>
      <TableCell>
        <ImageItemBox
          name={row.name}
          description={row.description}
          source={row.image}
        />
      </TableCell>
      <TableCell className={classes.quantityColumn}>
        <Typography>
          {row.quantity + ' ' + (row.abbreviation || 'ea')}
        </Typography>
      </TableCell>
      <TableCell className={classes.unitCostColumn}>
        <Typography>{currencyFormatterSix(row.unitCost || 0)}</Typography>
      </TableCell>
      <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
        <TableCell className={classes.unitCostColumn}>
          <Typography>
            {currencyFormatterSix(
              row.multiCurrencyUnitCost || 0,
              activeMulticurrencyCode
            )}
          </Typography>
        </TableCell>
      </MultiCurrencyWrapper>
      <TableCell className={classes.totalCostColumn}>
        <Typography>{currencyFormatter(row.totalCost || 0)}</Typography>
      </TableCell>
      <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
        <TableCell className={classes.totalCostColumn}>
          <Typography>
            {currencyFormatter(
              row.multiCurrencyTotalCost || 0,
              activeMulticurrencyCode
            )}
          </Typography>
        </TableCell>
      </MultiCurrencyWrapper>
      <TableCell className={classes.reconcileCostColumn}>
        {calculation === ReconcileCalculateOptions.Manual ? (
          <CurrencyField
            placeholder="Reconcile"
            value={reconcilePrice}
            fullWidth
            decimalPlaces={6}
            onChange={handleReconcileChanged}
            disabled={!selectedItems.includes(row.id)}
          />
        ) : (
          <Typography>
            {reconcilePrice > 0 ? currencyFormatter(reconcilePrice) : '-'}
          </Typography>
        )}
      </TableCell>
      <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
        <TableCell className={classes.reconcileCostColumn}>
          {calculation === ReconcileCalculateOptions.Manual ? (
            <CurrencyField
              placeholder="Reconcile"
              value={multiCurrencyReconcilePrice}
              fullWidth
              currencyCode={activeMulticurrencyCode}
              decimalPlaces={6}
              debounceDelay={700}
              onChange={handleMultiCurrencyReconcileChanged}
              disabled={!selectedItems.includes(row.id)}
            />
          ) : (
            <Typography>
              {reconcilePrice > 0
                ? resolvedMultiCurrencyCosts.multicurrencyReconcilePrice
                : '-'}
            </Typography>
          )}
        </TableCell>
      </MultiCurrencyWrapper>
      <TableCell className={classes.unitLandedColumn}>
        <Typography>{currencyFormatter(landedUnitCost)}</Typography>
      </TableCell>
      <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
        <TableCell className={classes.unitLandedColumn}>
          <Typography>
            {resolvedMultiCurrencyCosts.multiCurrencyLandedUnitCost}
          </Typography>
        </TableCell>
      </MultiCurrencyWrapper>
      <TableCell className={classes.landedCostColumn}>
        <Typography>{currencyFormatter(landedCost)}</Typography>
      </TableCell>
      <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
        <TableCell className={classes.landedCostColumn}>
          <Typography>
            {resolvedMultiCurrencyCosts.multiCurrencyLandedCost}
          </Typography>
        </TableCell>
      </MultiCurrencyWrapper>
    </>
  );
};

export default memo(ReconcileWizardItemsRow);
