import { CostingMethodOption, Event, GroupBy } from './types';

export const groupByOptions: GroupBy[] = [
  { type: 'item', label: 'Item' },
  { type: 'customer', label: 'Customer' },
];

export const eventOptions: Event[] = [
  { type: 'add', label: 'Add' },
  { type: 'remove', label: 'Remove' },
  { type: 'cycle out', label: 'Cycle Out' },
  { type: 'cycle in', label: 'Cycle In' },
];

export const costingMethodOptions: CostingMethodOption[] = [
  { type: 'actual', label: 'LIFO/FIFO/Actual', identifier: 'fifo,lifo,actual' },
  { type: 'average', label: 'Average', identifier: 'average' },
];
