import { Item } from 'services/items';
import { initialItem } from 'services/items/consts';
import { CompanySettings } from 'services/settings/company';
import { Uom, EACH_UOM_ID } from 'services/uoms';
import { ActiveItemIdState } from 'ui/components/Page/WithSearchResults';

export const transformToDuplicatedItem = (item: Item): Item => {
  return {
    ...item,
    id: ActiveItemIdState.Duplicate,
    name: item.name + ' (Copy)',
    saleItemList: item.saleItemList.map((s, index) => ({
      ...s,
      id: -index - 1,
      version: null,
      name: s.name + ' (Copy)',
      imageLinkList: s.imageLinkList.map((i) => ({ ...i, cloned: true })),
    })),
    locationList: item.locationList.map((l, index) => ({
      ...l,
      id: -index - 1,
      version: null,
    })),
    imageLinkList: item.imageLinkList.map((i) => ({ ...i, cloned: true })),
  };
};

export const transformToInitialItem = (
  uoms: Uom[],
  companySettings: CompanySettings,
  purchaseTaxId: number | null
): Item => {
  const defaultUom = uoms.find((u) => u.defaultFlag);
  const defaultUomId = defaultUom ? defaultUom.id : EACH_UOM_ID;
  const weightUnit = companySettings.defaultWeightUom || 'Pounds';
  const dimensionUnit = companySettings.defaultDimensionUom || 'Inches';

  return {
    ...initialItem,
    defaultUomId,
    salesTaxId: companySettings.country !== 'US' ? purchaseTaxId : null,
    weightUnit,
    dimensionUnit,
  };
};
