import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const PICK_ITEMS_COLUMNS: Column[] = [
  {
    title: 'Item',
    width: '70%',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Quantity',
    width: '30%',
    type: ColumnTypes.renderByCustomRow,
  },
];

export const PICK_ITEMS_TABLE_HEIGHT = 500;
