import { chainReducers, INITIAL_API_STATUS } from 'services/api';
import {
  AdvancedSearchItem,
  createAddSearchReducer,
  createDeleteSearchReducer,
  createFetchSearchReducer,
  createUpdateSearchReducer,
} from 'services/search';
import { combineReducers } from 'redux';

const initialState = {
  _status: INITIAL_API_STATUS,
  items: [],
};

export const transformSearch = (serverSearchItem: any): AdvancedSearchItem => {
  return {
    id: serverSearchItem.id,
    columns: serverSearchItem.columns,
    searchName: serverSearchItem.searchName,
    isDefault: serverSearchItem.isDefault,
  };
};

const fetchSearchReducer = createFetchSearchReducer('item');
const addSearchReducer = createAddSearchReducer('item');
const updateSearchReducer = createUpdateSearchReducer('item');
const deleteSearchReducer = createDeleteSearchReducer('item');

const searchChainedReducers = chainReducers(
  initialState,
  fetchSearchReducer,
  addSearchReducer,
  updateSearchReducer,
  deleteSearchReducer
);

export default combineReducers({
  searches: searchChainedReducers,
});
