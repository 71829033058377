import { PermissionType } from 'services/permissions';
import { Representative } from 'services/representatives';

export const editRepresentativePermissions = (
  representative: Representative
): PermissionType[] => {
  if (representative.deleted) {
    return [PermissionType.Undelete];
  }
  return representative.id && representative.id > 0
    ? [PermissionType.RepresentativesEdit]
    : [PermissionType.RepresentativesCreate];
};
