import React, { memo } from 'react';
import { TableCell, InputAdornment, Box, Tooltip } from '@mui/material';

import { TextField } from 'ui/components/TextField/TextField';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import { ShipCarton } from 'services/shipping';

import { useReturnLabelRowStyle } from './styled';

import { IconNames, colorPalette } from 'ui/theme';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const ReturnLabelRow: React.FC<RenderCustomRowProps<ShipCarton>> = (props) => {
  const { row: carton } = props;

  const classes = useReturnLabelRowStyle();

  const handleLinkClicked = (url: string | null) => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  return (
    <>
      <TableCell>
        <Box className={classes.cartonName}>Package {carton.number}</Box>
      </TableCell>
      <TableCell className={classes.smallColumn}>
        <TextField
          name="returnLabelCost"
          type="number"
          value={carton.returnLabelCost}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          disabled
        />
      </TableCell>
      <TableCell>
        <TextField
          name="returnTracking"
          value={carton.returnTracking}
          disabled
          InputProps={{
            endAdornment: (
              <>
                {carton.returnTracking && (
                  <FBOButton
                    variant="tertiary"
                    color="neutral"
                    size="small"
                    icon={IconNames.IconLinkHorizontal}
                    onClick={() => handleLinkClicked(carton.returnTrackingUrl)}
                    data-qa="return-label-row-carrier-tracking-link-button"
                  />
                )}
              </>
            ),
          }}
        />
      </TableCell>
      <TableCell padding="none" align="center">
        <Tooltip title="Open Shipping Label">
          <FBOButton
            variant="tertiary"
            color="neutral"
            size="small"
            icon={IconNames.IconNote}
            onClick={() => handleLinkClicked(carton.returnLabelUrl)}
            data-qa="return-label-row-tracking-note"
            style={{ color: colorPalette.redesign.contentPrimary }}
          />
        </Tooltip>
      </TableCell>
    </>
  );
};

export default memo(ReturnLabelRow);
