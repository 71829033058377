import { combineReducers } from 'redux';
import {
  chainReducers,
  INITIAL_API_STATE,
  createApiReducer,
} from 'services/api';
import {
  createAddSearchReducer,
  createDeleteSearchReducer,
  createFetchSearchReducer,
  createUpdateSearchReducer,
} from 'services/search';

import { LedgerActionTypes } from './actions';
import { transformLedger } from '../transformations';

const fetchLedgerReducer = createApiReducer(
  [
    LedgerActionTypes.FETCH_LEDGER_START,
    LedgerActionTypes.FETCH_LEDGER_SUCCESS,
    LedgerActionTypes.FETCH_LEDGER_FAILURE,
  ],
  (data) => ({ items: data.results.map(transformLedger) }),
  (data) => data
);

const ledgerChainedReducers = chainReducers(
  INITIAL_API_STATE,
  fetchLedgerReducer
);

const fetchSearchReducer = createFetchSearchReducer('ledger');
const addSearchReducer = createAddSearchReducer('ledger');
const updateSearchReducer = createUpdateSearchReducer('ledger');
const deleteSearchReducer = createDeleteSearchReducer('ledger');

const searchChainedReducers = chainReducers(
  INITIAL_API_STATE,
  fetchSearchReducer,
  addSearchReducer,
  updateSearchReducer,
  deleteSearchReducer
);

export default combineReducers({
  ledgers: ledgerChainedReducers,
  searches: searchChainedReducers,
});
