import React, { memo, useCallback } from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Pick } from 'services/picking';
import { useHandleCheckboxChange } from 'services/forms/hooks';

import { ReportFiltersProps } from '../../types';
import { PickAsyncAutocomplete } from 'ui/components/Autocomplete/PickAsyncAutocomplete';

const PickFilters: React.FC<ReportFiltersProps> = (props) => {
  const { parameters, setParameters, errors } = props;

  const handlePickChange = useCallback(
    (value: Pick | null) => {
      setParameters((old) => ({ ...old, pickId: value ? value.id : null }));
    },
    [setParameters]
  );

  const handleSelectAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setParameters((old) => ({
        ...old,
        showSo: checked,
        showSku: checked,
        showDescription: checked,
        showCustomer: checked,
        showItem: checked,
        showAddress: checked,
        showBarcode: checked,
        showCountry: checked,
        selectAllOptional: checked,
        showItemsInStock: checked,
        showItemStatus: checked,
        showInventoryStatus: checked,
        showTracking: checked,
        showItemsInSalesOrderUOM: checked,
      }));
    },
    [setParameters]
  );

  const handleCheckboxChange = useHandleCheckboxChange(setParameters);

  // TODO : RESTYLING : Remove box wrappers around inputs after FF removal

  return (
    <>
      <Accordion square defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <b>Required</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%">
            <Box>
              <PickAsyncAutocomplete
                label="Pick Number"
                value={parameters.pickId}
                onChange={handlePickChange}
                placeholder="Select Pick"
                error={!!errors.pickId}
                helperText={errors.pickId}
                customQuickSearchColumns={['number']}
              />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Box px={2} pb={0} pt="16px">
        <FormControlLabel
          className="redesign"
          sx={{ marginBottom: '8px' }}
          control={
            <Checkbox
              className="redesign"
              checked={Boolean(parameters.selectAllOptional)}
              onChange={handleSelectAll}
              color="primary"
            />
          }
          label="Select All Optional"
        />
      </Box>
      <Accordion square>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>
            <b>Optional</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%">
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showSo)}
                    onChange={handleCheckboxChange}
                    name="showSo"
                    color="primary"
                  />
                }
                label="Show SO Number"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showSku)}
                    onChange={handleCheckboxChange}
                    name="showSku"
                    color="primary"
                  />
                }
                label="Show SKU"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showDescription)}
                    onChange={handleCheckboxChange}
                    name="showDescription"
                    color="primary"
                  />
                }
                label="Show Description"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showCustomer)}
                    onChange={handleCheckboxChange}
                    name="showCustomer"
                    color="primary"
                  />
                }
                label="Show Customer Name"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showItem)}
                    onChange={handleCheckboxChange}
                    name="showItem"
                    color="primary"
                  />
                }
                label="Show Item"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showItemsInSalesOrderUOM)}
                    onChange={handleCheckboxChange}
                    name="showItemsInSalesOrderUOM"
                    color="primary"
                  />
                }
                label="Show items in Sales Order UOM"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showItemsInStock)}
                    onChange={handleCheckboxChange}
                    name="showItemsInStock"
                    color="primary"
                  />
                }
                label="Show Items in Stock"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showItemStatus)}
                    onChange={handleCheckboxChange}
                    name="showItemStatus"
                    color="primary"
                  />
                }
                label="Show Item Status"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showInventoryStatus)}
                    onChange={handleCheckboxChange}
                    name="showInventoryStatus"
                    color="primary"
                  />
                }
                label="Show Inventory Status"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showAddress)}
                    onChange={handleCheckboxChange}
                    name="showAddress"
                    color="primary"
                  />
                }
                label="Show Address"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showBarcode)}
                    onChange={handleCheckboxChange}
                    name="showBarcode"
                    color="primary"
                  />
                }
                label="Show Barcode"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showCountry)}
                    onChange={handleCheckboxChange}
                    name="showCountry"
                    color="primary"
                  />
                }
                label="Show Country"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showTracking)}
                    onChange={handleCheckboxChange}
                    name="showTracking"
                    color="primary"
                  />
                }
                label="Show Tracking"
              />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default memo(PickFilters);
