import { PurcahseReturnLabel, PurchaseLabelResponse } from './types';

export const transformToPurchaseLabelResponse = (
  res: any
): PurchaseLabelResponse => ({
  base64Label: res.base64Label,
  cost: res.cost,
  fileType: res.fileType,
  labellUrl: res.labelUrl,
  referenceId: res.referenceId,
  returnLabel: res.returnLabel
    ? transformToPurchaseReturnLabelResponse(res.returnLabel)
    : null,
  tracking: res.tracking,
  trackingUrl: res.trackingUrl,
});

export const transformToPurchaseReturnLabelResponse = (
  res: any
): PurcahseReturnLabel => ({
  cost: res.cost,
  labellUrl: res.labelUrl,
  cartonId: res.cartonId,
  returnLabel: res.returnLabel,
  tracking: res.tracking,
  trackingUrl: res.trackingUrl,
});
