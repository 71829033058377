import React, { useCallback, useEffect, useMemo } from 'react';
import { Grid } from '@mui/material';

import { GridRow } from 'ui/components/Grid';
import { TextField } from 'ui/components/TextField/TextField';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import { OrderSettingsProps } from './types';
import { INPUT_WIDTH, ORDER_NUMBER_ASSIGNMENT_OPTIONS } from '../consts';
import { TaxRatesAutocomplete } from 'ui/components/Autocomplete/TaxRatesAutocomplete';
import { TaxRateVariants } from 'ui/components/Autocomplete/TaxRatesAutocomplete/types';
import { fetchTaxRates, TaxRate } from 'services/taxRates';
import { ItemType, ShippingTerm, SHIPPING_TERMS } from 'services/items';
import { ORDER_STATUS } from './consts';
import { ClassAutocomplete } from 'ui/components/Autocomplete/ClassesAutocomplete';
import { Location } from 'services/locations';
import { Class } from 'services/classes';
import { SalesOrderStatus } from 'services/salesOrders';
import { useDispatch } from 'react-redux';

import { OrderNumberAssignment } from 'services/commerce/channels';
import { SaleItem } from 'services/items/saleItems';
import { SaleItemsAutocomplete } from 'ui/components/Autocomplete/SaleItemsAutocomplete';

const OrderSettings: React.FC<OrderSettingsProps> = (props) => {
  const { activeChannel, channelSettings, setChannelSettings, errors } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchTaxRates());
    // react-hooks/exhaustive-deps
  }, []);

  const selectedShippingTerm = useMemo(
    () =>
      SHIPPING_TERMS.find(
        (s) => s.id === channelSettings.orderSettings.shippingTerms
      ) || null,
    [channelSettings.orderSettings.shippingTerms]
  );

  const handleAutocompleteIdChange = useCallback(
    (
        name:
          | 'shippingTerms'
          | 'defaultLocation'
          | 'shippingItem'
          | 'status'
          | 'taxItem'
          | 'accountingClass'
      ) =>
      (v: ShippingTerm | SaleItem | Location | TaxRate | Class | null) => {
        setChannelSettings((old) => ({
          ...old,
          orderSettings: { ...old.orderSettings, [name]: v ? v.id : null },
        }));
      },
    [setChannelSettings]
  );

  const handleAutocompleteChange = useCallback(
    (name: 'status' | 'orderNumberAssignment') =>
      (v: SalesOrderStatus | OrderNumberAssignment | null) => {
        setChannelSettings((old) => ({
          ...old,
          orderSettings: { ...old.orderSettings, [name]: v || null },
        }));
      },
    [setChannelSettings]
  );

  const handlePrefixChange = (e: any) => {
    const newValue = e.target.value;
    setChannelSettings((old) => ({
      ...old,
      orderSettings: {
        ...old.orderSettings,
        orderNumberPrefix: newValue || null,
      },
    }));
  };

  const orderNumberAssignmentLabels = useCallback(
    (option: OrderNumberAssignment | null) => {
      if (!activeChannel?.vendor) {
        return '';
      }
      if (option === OrderNumberAssignment.Primary) {
        return "Use Fishbowl's Next Order Number";
      } else if (option === OrderNumberAssignment.Linked) {
        return `Use ${activeChannel?.vendor}'s Order Number`;
      } else if (option === OrderNumberAssignment.Alternate) {
        return `Use ${activeChannel?.vendor}'s Alternate Order Number`;
      } else {
        return '';
      }
    },
    [activeChannel?.vendor]
  );

  return (
    <GridRow title="Order Settings">
      <Grid width={INPUT_WIDTH} item>
        <LocationsAsyncAutocomplete
          onChange={(value: Location | null) =>
            handleAutocompleteIdChange('defaultLocation')(value)
          }
          value={channelSettings.orderSettings.defaultLocation}
          placeholder="Select Location"
          label="Default Location"
          parentId={null}
          companyWide={false}
          dataQa="order-default-location"
          required
          error={!!errors['orderSettings.defaultLocation']}
        />
      </Grid>
      <Grid width={INPUT_WIDTH} item>
        <Autocomplete
          options={ORDER_NUMBER_ASSIGNMENT_OPTIONS}
          value={channelSettings.orderSettings.orderNumberAssignment}
          getOptionLabel={orderNumberAssignmentLabels}
          doNotOverwriteGetOptionLabel
          onChange={(e: React.ChangeEvent<{}>, value: any) =>
            handleAutocompleteChange('orderNumberAssignment')(value)
          }
          placeholder="Select"
          label="Import Order Number As"
          dataQa="order-number-assignment"
          required
          error={!!errors['orderSettings.orderNumberAssignment']}
        />
      </Grid>
      <Grid width={INPUT_WIDTH} item>
        <TextField
          className={'redesign'}
          variant={'standard'}
          type="text"
          label="Prefix"
          placeholder="Prefix"
          name="prefix"
          autoComplete="off"
          fullWidth
          value={channelSettings.orderSettings.orderNumberPrefix}
          onChange={handlePrefixChange}
          dataQa="order-prefix"
        />
      </Grid>
      <Grid width={INPUT_WIDTH} item>
        <SaleItemsAutocomplete
          onChange={(value: SaleItem | null) =>
            handleAutocompleteIdChange('shippingItem')(value)
          }
          value={channelSettings.orderSettings.shippingItem}
          placeholder="Select Item"
          label="Shipping Item"
          itemTypes={[ItemType.Shipping]}
          dataQa="order-shipping-items"
          disableAdd
          required
          error={!!errors['orderSettings.shippingItem']}
        />
      </Grid>
      <Grid width={INPUT_WIDTH} item>
        <TaxRatesAutocomplete
          label="Sales Tax Item (Flat Rate)"
          placeholder="Sales Tax"
          taxRateVariant={TaxRateVariants.FlatRate}
          value={channelSettings.orderSettings.taxItem}
          onChange={(v: TaxRate | null) =>
            handleAutocompleteIdChange('taxItem')(v)
          }
          dataQa="order-sales-tax"
          required
          error={!!errors['orderSettings.taxItem']}
        />
      </Grid>
      <Grid width={INPUT_WIDTH} item>
        <Autocomplete
          options={ORDER_STATUS}
          value={channelSettings.orderSettings.status}
          onChange={(e: React.ChangeEvent<{}>, value: any) =>
            handleAutocompleteChange('status')(value)
          }
          placeholder="Order Status"
          label="Order Status"
          dataQa="order-status"
          required
          error={!!errors['orderSettings.status']}
        />
      </Grid>
      <Grid width={INPUT_WIDTH} item>
        <Autocomplete
          options={SHIPPING_TERMS}
          value={selectedShippingTerm}
          getOptionLabel={(option: ShippingTerm) => option.name}
          onChange={(e: React.ChangeEvent<{}>, v: ShippingTerm | null) =>
            handleAutocompleteIdChange('shippingTerms')(v)
          }
          placeholder="Shipping Terms"
          label="Shipping Terms"
          dataQa="order-shipping-terms"
          required
          error={!!errors['orderSettings.shippingTerms']}
        />
      </Grid>
      <Grid width={INPUT_WIDTH} item>
        <ClassAutocomplete
          value={channelSettings.orderSettings.accountingClass}
          onChange={(value: Class | null) =>
            handleAutocompleteIdChange('accountingClass')(value)
          }
          placeholder="Select a class"
          label="Accounting Class"
          dataQa="order-accounting-class"
          error={!!errors['orderSettings.accountingClass']}
        />
      </Grid>
    </GridRow>
  );
};

export default OrderSettings;
