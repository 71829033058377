import { makeStyles } from '@mui/styles';

const ColorPrimary = '#192229'; // todo : move after style-guide theme

export const useNotACustomerStyles = makeStyles({
  linkText: {
    color: ColorPrimary, // todo : move after style-guide theme
    fontSize: 16,
    fontWeight: 400, // todo : move after style-guide theme
  },
});
