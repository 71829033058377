import React from 'react';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import { WizardStep } from 'ui/components/Wizard/types';

export const getPurchaseLabelWizardSteps = (
  classes: any,
  activeStep: number,
  isInternational: boolean
): WizardStep[] => {
  const internationalSteps = [
    {
      label: 'Step 1',
      icon:
        activeStep > 0 ? (
          <RadioButtonCheckedIcon className={classes.activeIcon} />
        ) : (
          <RadioButtonUncheckedOutlinedIcon />
        ),
    },
    {
      label: 'Step 2',
      icon:
        activeStep > 1 ? (
          <RadioButtonCheckedIcon className={classes.activeIcon} />
        ) : (
          <RadioButtonUncheckedOutlinedIcon />
        ),
    },
  ];

  const purchaseLabelStep = {
    label: 'Step 3',
    icon:
      activeStep > 2 ? (
        <RadioButtonCheckedIcon className={classes.activeIcon} />
      ) : (
        <RadioButtonUncheckedOutlinedIcon />
      ),
  };

  return isInternational
    ? [...internationalSteps, purchaseLabelStep]
    : [purchaseLabelStep];
};
