import _ from 'lodash';

import {
  createFilterOptions,
  FilterOptionsState,
} from '@mui/material/useAutocomplete';

import { fetchItemsAPI, initialItem, Item, ItemType } from 'services/items';
import { defaultSaleItem, SaleItem } from 'services/items/saleItems';
import { fetchSaleItemsAPI } from 'services/items/saleItems/api';
import {
  PurchaseOrderItem,
  PurchaseOrderItemTypes,
} from 'services/purchaseOrders';
import { Pagination } from 'services/search';
import { matchSorter } from 'match-sorter';

export const DOT_CHAR = '\u2022';

export const itemsAutocompleteGetOptionLabel = (item: Item) => {
  if (!item.id || item.id < 0) {
    return `+ Add "${item.name}"`;
  }

  return item.name || '';
};

// PURCHASE ORDER ITEMS AUTOCOMPLETE
const poFilter = (vendorId: number | null) => {
  return createFilterOptions<Item>({
    stringify: (item) => {
      // if vendor is selected in purchase order we want to filter by vendor name
      if (vendorId) {
        const vendorItem = item.vendorItems.find(
          (i) => i.vendorId === vendorId
        );
        if (vendorItem && vendorItem.name) {
          return `${item.name} ${item.description} ${item.sku} ${
            vendorItem.name
          } ${item.tags.map((tag) => tag.name)}`;
        }
      }
      return `${item.name} ${item.description} ${item.sku} ${item.tags.map(
        (tag) => tag.name
      )}`;
    },
  });
};

export const poItemsAutocompleteFilterOptions =
  (vendorId: number | null) =>
  (options: Item[], params: FilterOptionsState<Item>) => {
    const filtered = poFilter(vendorId)(options, params);

    if (!!params.inputValue) {
      // add default item at the beginning of options
      filtered.unshift({
        ...initialItem,
        name: params.inputValue || null,
      });
    }

    return filtered;
  };

export const poItemsAutocompleteOptionLabel =
  (vendorId: number | null) => (item: Item) => {
    // if vendor is selected in purchase order we want to show vendor name with item name
    if (vendorId) {
      const vendorItem = item.vendorItems.find((i) => i.vendorId === vendorId);
      if (vendorItem && vendorItem.name) {
        return `${item.name} ${DOT_CHAR} ${vendorItem.name}`;
      }
    }

    return item.name || '';
  };

const filterKeys = {
  keys: ['name', 'description', 'sku', 'tags'],
};

export const soItemsAutocompleteFilterOptions = (
  options: SaleItem[],
  params: FilterOptionsState<SaleItem>
) => {
  const filtered = matchSorter(options, params.inputValue, filterKeys);

  if (!!params.inputValue) {
    // add default sale item at the beginning of options
    filtered.unshift({
      ...defaultSaleItem,
      name: params.inputValue || null,
    });
  }

  return filtered;
};

export const soItemsAutocompleteOptionLabel = (saleItem: SaleItem) => {
  let label = saleItem.name || '';
  if (saleItem.description) {
    label += ` - ${saleItem.description}`;
  }

  return label;
};

// ITEMS AUTOCOMPLETE

const filter = createFilterOptions<Item>({
  stringify: (item) => `${item.name} ${item.description} ${item.sku}`,
});

export const itemsAutocompleteFilterOptions =
  (disableAdd: boolean) =>
  (options: Item[], params: FilterOptionsState<Item>) => {
    const filtered = filter(options, params);

    if (disableAdd) {
      return filtered;
    }

    if (!!params.inputValue) {
      // add default item at the beginning of options
      filtered.unshift({
        ...initialItem,
        name: params.inputValue || null,
      });
    }

    return filtered;
  };

export const resolvedFetchItemsFunction = (
  itemTypes: ItemType[],
  expands?: string[],
  customQuickSearchColumns?: string[],
  onlySaleItems?: boolean,
  poHasDropShipItem?: boolean,
  poVendorId?: number | null,
  isPurchaseOrder: boolean = false,
  onlyShowVendorPartsInThePartDropDownFilter = false
) => {
  if (poHasDropShipItem) {
    itemTypes = [
      ItemType.Shipping,
      ItemType.Service,
      ItemType.Labor,
      ItemType.Overhead,
    ];
  }

  return async (pagination: Pagination, searchValue: string | null) => {
    const resItem = await fetchItemsAPI(
      {
        pagination,
        expands: expands,
        quickSearchValue: searchValue,
        customQuickSearchColumns,
      },
      itemTypes,
      [],
      false,
      onlySaleItems,
      onlyShowVendorPartsInThePartDropDownFilter ? poVendorId : null
    );

    if (isPurchaseOrder) {
      const items = resItem.data.map((item: Item) => {
        const vendorItem = item.vendorItems.find(
          (vi) => vi.vendorId === poVendorId
        );
        if (vendorItem) {
          return {
            ...item,
            cost: vendorItem.cost,
            defaultUomId: vendorItem.defaultUomId,
          };
        }
        return {
          ...item,
          cost: 0,
        };
      });

      return {
        data: items.filter((i: Item | null) => i !== null && i !== undefined),
        pagination: resItem.pagination,
      };
    }

    return resItem;
  };
};

export const resolvedFetchSoItemsFunction = () => {
  return (pagination: Pagination, searchValue: string | null) =>
    fetchSaleItemsAPI({
      pagination,
      quickSearchValue: searchValue,
    });
};

export const poHasDropShipItem = (poItems: PurchaseOrderItem[]): boolean => {
  return poItems.some(
    (poi: PurchaseOrderItem) =>
      poi.purchaseOrderItemType === PurchaseOrderItemTypes.DropShip
  );
};
