import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import GetAppIcon from '@mui/icons-material/GetApp';
import { getSettingsCompany } from 'services/settings/company';
import { useDownloadsPageStyle } from '../styled';
import { DownloadItemCmp, DownloadItemProps } from '../types';
import { useZonedDate } from 'helpers';
import { BackgroundAction, cancelDownload } from 'services/backgroundTasks';
import { ProgressBar } from 'ui/components/ProgressBar';

export const DownloadPendingItem: DownloadItemCmp = (
  props: DownloadItemProps
) => {
  const { item } = props;
  const classes = useDownloadsPageStyle();
  const selector = useSelector(getSettingsCompany);
  const timezone = selector.timezone as string;
  const zonedData = item.dateCreated ? new Date(item.dateCreated) : new Date();
  const dateCreated = useZonedDate(zonedData, timezone);

  const cancelClicked = React.useCallback(
    (fileName: string | null, action: BackgroundAction) =>
      async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();

        if (!fileName) {
          return;
        }
        await cancelDownload(fileName, action);
      },
    []
  );

  return (
    <Box key={item.id} className={classes.card}>
      <Box className={classes.cardRow}>
        <Box className={classes.cardContent}>
          <Typography variant="subtitle1" color="textSecondary">
            {dateCreated
              ? `${item.type} • ${dateCreated}`
              : `${item.type} waiting for download ...`}
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {item.fileName && <b>{item.fileName}</b>}
          </Typography>
        </Box>
        <Button
          variant="outlined"
          onClick={cancelClicked(item.fileName, item.action)}
        >
          CANCEL
        </Button>
        <GetAppIcon className={classes.icon} />
      </Box>
      <ProgressBar value={item.progress || 0} className={classes.progressBar} />
    </Box>
  );
};
