import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import AutoComplete from '@mui/material/Autocomplete';
import { CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import _ from 'lodash';

import { TextField } from 'ui/components/TextField/TextField';
import { activeUserHasPermission } from 'services/user/redux';
import { Pagination } from 'services/search';
import { initialPagination } from 'ui/components/Table/ItemsTable';
import { PaginationDirection } from 'services/url';
import { getPick, Pick } from 'services/picking';

import { PickAsyncAutocompleteProps } from './types';
import {
  resolvedFetchPickFunction,
  pickAutocompleteFilterOptions,
} from './helpers';
import { useAsyncAutocomplete } from '../Autocomplete/hooks';

const initialPickPagination: Pagination = {
  ...initialPagination,
  pageSize: 50,
  sort: { sortBy: 'dateCreated', direction: PaginationDirection.Ascending },
};

const PickAsyncAutocomplete: React.FC<PickAsyncAutocompleteProps> = (props) => {
  const {
    onChange,
    dataQa,
    permissions = [],
    disabled,
    label,
    value,
    placeholder,
    error,
    required,
    additionalInputProps,
    expands,
    customQuickSearchColumns,
    helperText,
  } = props;

  const {
    data: picks,
    isLoading,
    innerValue,
    open,
    onCloseAutocomplete,
    onNextPage,
    onOpenAutocomplete,
    setSearchValue,
    setInnerValue,
  } = useAsyncAutocomplete(
    value,
    resolvedFetchPickFunction(expands, customQuickSearchColumns),
    getPick,
    initialPickPagination
  );

  const canEdit = useSelector(activeUserHasPermission(permissions));

  const isDisabled = disabled || !canEdit;

  const handleAutocompleteInputChange = (
    e: any,
    val: string,
    reason: string
  ) => {
    // reason why this event is triggered
    // it can be 'input', 'clear' and 'reset'
    if (reason === 'input') {
      if (!val) {
        onOpenAutocomplete();
        setInnerValue(null);
      }
      setSearchValue(val || null);
      onOpenAutocomplete();
      return;
    }

    if (reason === 'clear') {
      onChange(null);
    }

    setSearchValue(null);
  };

  const handleChange = (e: any, pick: Pick | null) => {
    if (pick && (pick.id === null || pick.id < 0)) {
      return;
    }

    setInnerValue(pick);
    onChange(pick);
  };

  return (
    <>
      <AutoComplete
        className="redesign"
        // TODO : RESTYLING : minor style adjustment needed for now until we use the FBOAutoSelect component
        style={{ marginTop: '0px', marginBottom: '0px' }}
        options={picks}
        open={open}
        disabled={isDisabled}
        value={innerValue}
        onOpen={onOpenAutocomplete}
        onClose={onCloseAutocomplete}
        onChange={handleChange}
        onInputChange={handleAutocompleteInputChange}
        getOptionLabel={(option: Pick) => option.number || ''}
        filterOptions={pickAutocompleteFilterOptions()}
        noOptionsText={isLoading ? 'Loading...' : 'No options'}
        isOptionEqualToValue={(option, val) => option.id === val.id}
        ListboxProps={{
          onScroll: (event: React.SyntheticEvent) => {
            const node = event.currentTarget;

            if (
              Math.ceil(node.scrollTop + node.clientHeight) ===
              node.scrollHeight
            ) {
              onNextPage();
            }
          },
        }}
        renderInput={(params) => {
          const endAdornment = params.InputProps.endAdornment as any;

          return (
            <TextField
              // we are omitting props that would overwrite our styling in TextField
              {..._.omit(params, 'variant', 'size', 'InputLabelProps')}
              className="redesign"
              label={label}
              placeholder={placeholder}
              error={error}
              required={required}
              helperText={helperText}
              InputProps={{
                ...params.InputProps,
                ...additionalInputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {endAdornment}
                  </React.Fragment>
                ),
              }}
              inputProps={{
                'data-qa': dataQa,
                'data-lpignore': 'true',
                ...params.inputProps,
              }}
            />
          );
        }}
        clearIcon={
          dataQa && (
            <CloseIcon
              fontSize="small"
              data-qa={`${dataQa}-autocomplete-clear-icon`}
            />
          )
        }
        popupIcon={
          dataQa && (
            <ArrowDropDownIcon
              fontSize="small"
              data-qa={`${dataQa}-autocomplete-dropdown-icon`}
            />
          )
        }
      />
    </>
  );
};

export default memo(PickAsyncAutocomplete);
