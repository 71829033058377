import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { ImageDropZoneProps } from './types';
import { colorPalette } from 'ui/theme';

export const useDropZoneStyle = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  thumbnailPaper: {
    padding: (p: ImageDropZoneProps) =>
      p.images.length ? 0 : theme.spacing(2),
    cursor: 'default',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 136,
    height: 136,
  },
  photoPaper: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: 416,
  },
  photosPaper: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: (p: ImageDropZoneProps) =>
      p.images.length ? 'flex-start' : 'center',
    height: 416,
    padding: theme.spacing(3),
    overflowY: 'scroll',
  },
  button: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    width: 136,
    color: colorPalette.fishbowlCorporateGray1,
    borderColor: colorPalette.buttonBorderColor,
  },
  label: {
    color: theme.palette.text.primary,
  },
}));
