import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable/types';
import { SalesOrderItemTypes } from 'services/salesOrders';

import { FlatTaxRateRow, MiscRow, NoteRow, SaleRow } from './components';
import { BundleRow } from './components/BundleRow';

export const salesItemRenderMap = {
  [SalesOrderItemTypes.FlatTaxRate]: FlatTaxRateRow,
  [SalesOrderItemTypes.Note]: NoteRow,
  [SalesOrderItemTypes.Sale]: SaleRow,
  [SalesOrderItemTypes.CreditReturn]: SaleRow,
  [SalesOrderItemTypes.MiscSale]: MiscRow,
  [SalesOrderItemTypes.MiscReturn]: MiscRow,
  [SalesOrderItemTypes.BundleCreditReturn]: BundleRow,
  [SalesOrderItemTypes.Bundle]: BundleRow,
  [SalesOrderItemTypes.DropShip]: SaleRow,
};

export const resolvedSoItemColumns = (
  showMulticurrency: boolean,
  multiCurrencyCode: string,
  homeCurrencyCode: string
): Column[] => {
  let columns: Column[] = [
    {
      title: 'Item',
      field: 'name',
      type: ColumnTypes.string,
    },
    {
      title: `Unit Price ${homeCurrencyCode}`,
      width: 150,
      field: 'pricing',
      type: ColumnTypes.string,
    },
    {
      title: 'Quantity',
      width: 150,
      field: 'quantity',
      type: ColumnTypes.string,
    },
    {
      title: 'Discount',
      width: 90,
      align: 'left',
      field: 'discount',
      type: ColumnTypes.string,
    },
    {
      title: 'Tax Rates',
      width: 70,
      field: 'tax',
      type: ColumnTypes.string,
    },
    {
      title: 'Status',
      field: 'status',
      type: ColumnTypes.string,
      align: 'center',
      width: 180,
    },
    {
      title: `Subtotal ${homeCurrencyCode}`,
      width: 160,
      field: 'subtotal',
      align: 'right',
      type: ColumnTypes.string,
    },
    {
      title: '',
      width: 50,
      field: 'optionButton',
      type: ColumnTypes.string,
    },
  ];

  if (showMulticurrency) {
    columns = [
      {
        title: 'Item',
        field: 'name',
        type: ColumnTypes.string,
      },
      {
        title: `Unit Price ${homeCurrencyCode}`,
        width: 150,
        field: 'pricing',
        type: ColumnTypes.string,
      },
      {
        title: `Unit Price ${multiCurrencyCode}`,
        width: 150,
        field: 'pricing',
        type: ColumnTypes.string,
      },
      {
        title: 'Quantity',
        width: 150,
        field: 'quantity',
        type: ColumnTypes.string,
      },
      {
        title: 'Discount',
        width: 90,
        align: 'right',
        field: 'discount',
        type: ColumnTypes.string,
      },
      {
        title: 'Tax Rates',
        width: 70,
        field: 'tax',
        type: ColumnTypes.string,
      },
      {
        title: 'Status',
        field: 'status',
        type: ColumnTypes.string,
        align: 'center',
        width: 180,
      },
      {
        title: `Subtotal ${homeCurrencyCode}`,
        width: 160,
        field: 'subtotal',
        align: 'right',
        type: ColumnTypes.string,
      },
      {
        title: `Subtotal ${multiCurrencyCode}`,
        width: 160,
        field: 'subtotal',
        align: 'right',
        type: ColumnTypes.string,
      },
      {
        title: '',
        width: 50,
        field: 'optionButton',
        type: ColumnTypes.string,
      },
    ];
  }

  return columns;
};
