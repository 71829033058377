import React from 'react';
import { Box, Grid } from '@mui/material';

import { CurrencyField } from 'ui/components/TextField/CurrencyField';

import { PaymentTabCashProps } from './types';

const PaymentTabCash = (props: PaymentTabCashProps) => {
  const { paymentAmount, setPaymentAmount, validationErrors } = props;

  const handlePaymentAmountChanged = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPaymentAmount(parseFloat(e.target.value));
  };

  return (
    <Box p={3} width="100%">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CurrencyField
            label="Payment Amount"
            placeholder="Enter amount"
            name="paymentAmount"
            fullWidth
            value={paymentAmount}
            required
            onChange={handlePaymentAmountChanged}
            error={!!validationErrors.paymentAmount}
            dataQa="soPayments-addPaymentCashTab-paymentAmount"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(PaymentTabCash);
