import moment from 'moment';

import { DateRangeOption } from './types';

export const calculateDatesBasedOnRange = (
  option: DateRangeOption
): { startDate: Date; endDate: Date } => {
  switch (option) {
    case DateRangeOption.NextWeek:
      return {
        startDate: moment().add(1, 'weeks').startOf('isoWeek').toDate(),
        endDate: moment().add(1, 'weeks').endOf('isoWeek').toDate(),
      };
    case DateRangeOption.NextMonth:
      return {
        startDate: moment().add(1, 'month').startOf('month').toDate(),
        endDate: moment().add(1, 'month').endOf('month').toDate(),
      };
    case DateRangeOption.Next7Days:
      return {
        startDate: moment().toDate(),
        endDate: moment().add(1, 'week').toDate(),
      };
    case DateRangeOption.ThisWeek:
      return {
        startDate: moment().startOf('isoWeek').toDate(),
        endDate: moment().endOf('isoWeek').toDate(),
      };
  }
};
