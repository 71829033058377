import { makeStyles } from '@mui/styles';
import { colorPalette } from 'ui/theme';

export const useVendorGeneralStyle = makeStyles(() => ({
  resetButton: {
    color: colorPalette.applicationDarkGray80,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    padding: '5px 16px',
  },
  title: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    marginBottom: '8px',
  },
}));
