import { initialReportThemeColor, reportThemeColors } from './consts';
import {
  ReportLayout,
  ReportPreset,
  ReportTheme,
  ReportThemeColor,
} from './types';

export const transformToReportPreset = (res: any): ReportPreset => ({
  id: res.id,
  version: res.version,
  reportId: res.reportId,
  name: res.name,
  parameters: JSON.parse(res.parameters),
  defaultFlag: res.defaultFlag,
});

export const transformToReportLayout = (res: any): ReportLayout => ({
  id: res.id,
  reportName: res.reportName,
  name: res.name,
  isDefault: res.isDefault,
});

export const transformToReportTheme = (res: any): ReportTheme => {
  let color = initialReportThemeColor;

  const filteredThemes = reportThemeColors.filter(
    (c) => c.name === res.colorName
  );

  if (filteredThemes.length > 0) {
    color = filteredThemes[0];
  }

  const updatedColor: ReportThemeColor = Object.create(initialReportThemeColor);
  updatedColor.label = color.label;
  updatedColor.name = color.name;
  updatedColor.backgroundColor = res.backgroundColor;
  updatedColor.borderColor = res.borderColor;
  updatedColor.fontColor = res.color;
  updatedColor.radioColor = res.color;

  return {
    id: res.id,
    name: res.name,
    layoutId: res.layoutId,
    color: updatedColor,
    font: res.font,
    fontSize: res.fontSize,
  };
};

export const transformToRequestReportTheme = (theme: ReportTheme) => {
  // POST
  if (!theme.id || theme.id < 0) {
    return {
      name: theme.name,
      layoutId: theme.layoutId,
      color: theme.color.fontColor,
      font: theme.font,
      fontSize: theme.fontSize,
      backgroundColor: theme.color.backgroundColor,
      borderColor: theme.color.borderColor,
      colorName: theme.color.name,
    };
  }

  // PUT
  return {
    id: theme.id,
    name: theme.name,
    layoutId: theme.layoutId,
    color: theme.color.fontColor,
    font: theme.font,
    fontSize: theme.fontSize,
    backgroundColor: theme.color.backgroundColor,
    borderColor: theme.color.borderColor,
    colorName: theme.color.name,
  };
};

export const transformToRequestReportPreset = (
  userId: number,
  preset: ReportPreset
) => {
  // POST
  if (!preset.id || preset.id < 0) {
    return {
      name: preset.name,
      userId,
      reportId: preset.reportId,
      parameters: JSON.stringify(preset.parameters),
      defaultFlag: preset.defaultFlag,
    };
  }

  return {
    id: preset.id,
    version: preset.version,
    name: preset.name,
    userId,
    reportId: preset.reportId,
    parameters: JSON.stringify(preset.parameters),
    defaultFlag: preset.defaultFlag,
  };
};
