import { Account } from 'services/accounting';
import { AccountType } from 'services/ledgers';
import { RowDescriptor, RowType } from '../Card';

export const accountingCardId = 'accounting-card-navigation-id';

export const title = 'Account Mapping';

export const accountingRowDescriptor = (
  accounts: Account[]
): RowDescriptor[] => [
  {
    type: RowType.AccountMapping,
    title: 'Accounts Receivable',
    field: 'accountsReceivableId',
    accountType: AccountType.AccountsReceivable,
    options: accounts,
  },

  {
    type: RowType.AccountMapping,
    title: 'Accounts Payable',
    field: 'accountsPayableId',
    accountType: AccountType.AccountsPayable,
    options: accounts,
  },

  {
    type: RowType.AccountMapping,
    title: 'Undeposited Funds',
    field: 'undepositedFundsId',
    accountType: AccountType.OtherCurrentAsset,
    options: accounts,
  },

  {
    type: RowType.AccountMapping,
    title: 'Non-Inventory',
    field: 'nonInventoryId',
    accountType: AccountType.Expense,
    options: accounts,
  },

  {
    type: RowType.AccountMapping,
    title: 'Service',
    field: 'serviceId',
    accountType: AccountType.Expense,
    options: accounts,
  },

  {
    type: RowType.AccountMapping,
    title: 'Sales Retail',
    field: 'salesRetailId',
    accountType: AccountType.Income,
    options: accounts,
  },

  {
    type: RowType.AccountMapping,
    title: 'Discount Expense',
    field: 'discountExpenseId',
    accountType: AccountType.Expense,
    options: accounts,
  },

  {
    type: RowType.AccountMapping,
    title: 'Discount Income',
    field: 'discountIncomeId',
    accountType: AccountType.Income,
    options: accounts,
  },

  {
    type: RowType.AccountMapping,
    title: 'Miscellaneous Expense',
    field: 'miscellaneousExpenseId',
    accountType: AccountType.Expense,
    options: accounts,
  },

  {
    type: RowType.AccountMapping,
    title: 'Shipping Income',
    field: 'shippingIncomeId',
    accountType: AccountType.Income,
    options: accounts,
  },

  {
    type: RowType.AccountMapping,
    title: 'Shipping Expense',
    field: 'shippingExpenseId',
    accountType: AccountType.Expense,
    options: accounts,
  },

  {
    type: RowType.AccountMapping,
    title: 'Shipping Accrual',
    field: 'shippingAccrualId',
    accountType: AccountType.OtherCurrentLiability,
    options: accounts,
  },

  {
    type: RowType.AccountMapping,
    title: 'Cost of Goods Sold',
    field: 'costOfGoodsSoldId',
    accountType: AccountType.CostOfGoodsSold,
    options: accounts,
  },

  {
    type: RowType.AccountMapping,
    title: 'Scrap Inventory',
    field: 'scrapInventoryId',
    accountType: AccountType.Expense,
    options: accounts,
  },

  {
    type: RowType.AccountMapping,
    title: 'Inventory Adjustment',
    field: 'inventoryAdjustmentId',
    accountType: AccountType.CostOfGoodsSold,
    options: accounts,
  },

  {
    type: RowType.AccountMapping,
    title: 'Cost Variance',
    field: 'costVarianceId',
    accountType: AccountType.OtherCurrentAsset,
    options: accounts,
  },

  {
    type: RowType.AccountMapping,
    title: 'Inventory Asset',
    field: 'inventoryAssetId',
    accountType: AccountType.OtherCurrentAsset,
    options: accounts,
  },

  {
    type: RowType.AccountMapping,
    title: 'Flat Rate Tax',
    field: 'flatRateTaxId',
    accountType: AccountType.OtherCurrentLiability,
    options: accounts,
  },
  {
    type: RowType.AccountMapping,
    title: 'Holding',
    field: 'holdingId',
    accountType: AccountType.Holding,
    options: accounts,
  },
  {
    type: RowType.AccountMapping,
    title: 'Sales Tax Payable',
    field: 'salesTaxPayableId',
    accountType: AccountType.OtherCurrentLiability,
    options: accounts,
  },
  {
    type: RowType.AccountMapping,
    title: 'Labor',
    field: 'laborExpenseId',
    accountType: AccountType.Expense,
    options: accounts,
  },
  {
    type: RowType.AccountMapping,
    title: 'Overhead',
    field: 'overheadExpenseId',
    accountType: AccountType.Expense,
    options: accounts,
  },
];
