import React, { memo } from 'react';
import {
  Box,
  Typography,
  Radio,
  FormControlLabel,
  Divider,
} from '@mui/material';

import { ShippingIntegrationRateService } from 'services/integrations/shipping';
import { useCurrencyFormatter } from 'helpers';

import { useCardRowStyle } from './styled';

const RatesCustomRow = (props: {
  service: ShippingIntegrationRateService;
  selected: boolean;
  onSelect: () => void;
  billingType: string | null;
}) => {
  const { service, selected, onSelect, billingType } = props;

  const currencyFormatter = useCurrencyFormatter();

  const classes = useCardRowStyle();

  return (
    <Box overflow="auto">
      <Box className={classes.row} onClick={onSelect}>
        <FormControlLabel
          className={classes.label}
          value="parcel"
          control={
            <Radio color="primary" checked={selected} onChange={onSelect} />
          }
          label={
            <Box>
              <Typography
                variant="body1"
                color="textPrimary"
                className={classes.heading}
                title={service.serviceName}
              >
                {service.serviceName}
              </Typography>
            </Box>
          }
        />
        {(billingType === 'my_account' || billingType === 'SENDER') && (
          <Typography color="textSecondary">
            {currencyFormatter(service.totalCost)}
          </Typography>
        )}
      </Box>
      <Divider />
    </Box>
  );
};

export default memo(RatesCustomRow);
