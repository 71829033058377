import _ from 'lodash';

import { Pick } from 'services/picking';
import { mergeReports, ReportId, ReportParameters } from 'services/reports';

export const printPickTicketsReport = async (
  selectedItems: number[],
  parameters?: ReportParameters
) => {
  const pickTicketReports = selectedItems.map((item) => {
    return {
      reportName: ReportId.Pick,
      parameters: parameters
        ? JSON.stringify({ ...parameters, PickId: item })
        : JSON.stringify({ PickId: item }),
    };
  });

  const resBlob = await mergeReports([], pickTicketReports);

  const url = window.URL.createObjectURL(resBlob);

  const newWindow = window.open(url, '_blank');

  if (newWindow) {
    newWindow.print();
  }
};

export const resolvePickCustomerName = (pick: Pick): string => {
  return (
    _.get(pick, 'salesOrder.customer.name', '') ||
    _.get(pick, 'pickItems[0].salesOrderItem.salesOrder.customer.name') ||
    _.get(
      pick,
      'pickBundleItems[0].pickItems[0].salesOrderItem.salesOrder.customer.name',
      ''
    )
  );
};
export const resolvePickVendorName = (pick: Pick): string => {
  return _.get(
    pick,
    'pickItems[0].purchaseOrderItem.purchaseOrder.vendor.name'
  );
};
