import {
  createFilterOptions,
  FilterOptionsState,
} from '@mui/material/useAutocomplete';

import { defaultSaleItem, SaleItem } from 'services/items/saleItems';

export const DOT_CHAR = '\u2022';

const soFilter = createFilterOptions<SaleItem>({
  stringify: (item) => `${item.name}`,
});

export const saleItemsAutocompleteFilterOptions =
  (disableAdd: boolean) =>
  (options: SaleItem[], params: FilterOptionsState<SaleItem>) => {
    const filtered = soFilter(options, params);

    if (disableAdd) {
      return filtered;
    }

    if (!!params.inputValue) {
      // add default sale item at the beginning of options
      filtered.unshift({
        ...defaultSaleItem,
        name: params.inputValue || null,
      });
    }

    return filtered;
  };

export const saleItemsAutocompleteOptionLabel = (saleItem: SaleItem) => {
  if (!saleItem.id || saleItem.id < 0) {
    return `+ Add "${saleItem.name}"`;
  }

  return saleItem.name || '';
};
