import { makeStyles } from '@mui/styles';
import { colorPalette } from 'ui/theme';

export const useSerialModalStyle = makeStyles(() => ({
  chipSuccess: {
    backgroundColor: colorPalette.statusGreen,
    background: 'transparent',
    text: 'transparent',
  },
  chipWarning: {
    backgroundColor: colorPalette.statusOrange,
    background: 'transparent',
    text: 'transparent',
  },
}));
