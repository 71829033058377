import { transformDateToMomentDate } from 'helpers';
import {
  Invoice,
  InvoiceDetails,
  InvoiceDetailsItem,
  InvoiceDetailsLedger,
  InvoiceDetailsLedgerItem,
  InvoiceDetailsPayment,
} from './types';

export const transformInvoices = (resp: any): Invoice => {
  return {
    id: resp.id,
    amount: resp.amount,
    customer: resp.customer,
    dateDue: transformDateToMomentDate(resp.dateDue),
    dateShipped: transformDateToMomentDate(resp.dateShipped),
    location: resp.location,
    memo: resp.memo,
    number: resp.number,
    salesRepresentative: resp.salesRepresentative,
    transactionId: resp.transactionId,
    type: resp.type,
  };
};

export const transformInvoiceDetailsItem = (
  respItem: any
): InvoiceDetailsItem => {
  return {
    description: respItem.description,
    discount: respItem.discount,
    image: respItem.image,
    name: respItem.name,
    price: respItem.price,
    quantity: respItem.quantity,
    subtotal: respItem.subtotal,
    taxRate: respItem.taxRate,
    taxTotal: respItem.taxTotal,
    uom: respItem.uom,
  };
};

export const transformInvoiceDetailsLedgerItem = (
  respItem: any
): InvoiceDetailsLedgerItem => {
  return {
    accountId: respItem.accountId,
    accountName: respItem.accountName,
    creditAmount: respItem.creditAmount,
    debitAmount: respItem.debitAmount,
    ledgerItemId: respItem.ledgerItemId,
    memo: respItem.memo,
  };
};

export const transformInvoiceDetailsLedger = (
  respItem: any
): InvoiceDetailsLedger => {
  return {
    accountingClassId: respItem.accountingClassId,
    customerId: respItem.customerId,
    date: transformDateToMomentDate(respItem.date),
    ledgerId: respItem.ledgerId,
    ledgerItems: respItem?.ledgerItems?.map(transformInvoiceDetailsLedgerItem),
    name: respItem.name,
    refNo: respItem.refNo,
    type: respItem.type,
  };
};

export const transformInvoiceDetailsPayment = (
  respItem: any
): InvoiceDetailsPayment => {
  return {
    amount: respItem.amount,
    date: transformDateToMomentDate(respItem.date),
    id: respItem.id,
    paymentType: respItem.paymentType,
    status: respItem.status,
    transactionType: respItem.transactionType,
  };
};

export const transformInvoiceDetails = (resp: any): InvoiceDetails => {
  return {
    id: resp.id,
    billToAddress: resp.billToAddress,
    carrier: resp.carrier,
    carrierService: resp.carrierService,
    customer: resp.customer,
    customerPO: resp.customerPO,
    dateDue: transformDateToMomentDate(resp.dateDue),
    dateShipped: transformDateToMomentDate(resp.dateShipped),
    items: resp?.items?.map(transformInvoiceDetailsItem),
    location: resp.location,
    number: resp.number,
    orderClass: resp.orderClass,
    orderLevelDiscount: resp.orderLevelDiscount,
    orderTax: resp.orderTax,
    paymentTerm: resp.paymentTerm,
    payments: resp?.payments?.map(transformInvoiceDetailsPayment),
    salesOrderId: resp.salesOrderId,
    shipToAddress: resp.shipToAddress,
    total: resp.total,
    transactionId: resp.transactionId,
    transactions: resp?.transactions?.map(transformInvoiceDetailsLedger),
    type: resp.type,
  };
};
