import React from 'react';
import { Box, Typography } from '@mui/material';
import _ from 'lodash';

import { useCurrencyFormatter } from 'helpers';
import { ImageItemBox } from 'ui/components/Image/ImageItemBox';

import { SaleItemOptionProps } from './types';

const SaleItemOption: React.FC<SaleItemOptionProps> = ({
  option,
  ...props
}) => {
  const currencyFormatter = useCurrencyFormatter();

  return (
    <Box {...props} display="flex" width="100%" flexDirection="column">
      <Box display="flex" width="100%">
        <Box display="flex" alignItems="center" flexGrow={1} pr={2}>
          <Box display="flex" flexShrink={0}>
            <ImageItemBox
              name={_.get(option, 'name', null)}
              description={_.get(option, 'description', null)}
              source={_.get(option, 'defaultImage', null)}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          width={130}
          textAlign="right"
          flexShrink={0}
        >
          <Typography variant="body1">
            {option.price != null && currencyFormatter(option.price)}
          </Typography>
        </Box>
      </Box>
      {option.sku && (
        <Box pl={2}>
          <Typography variant="subtitle2" color="textSecondary">
            SKU: {option.sku}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default SaleItemOption;
