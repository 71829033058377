import { OrderByOption } from './types';

export const orderByOptions: OrderByOption[] = [
  { type: 'location', title: 'Location' },
  { type: 'itemNumber', title: 'Item number' },
];

export enum OnHandStatus {
  Valid = 'Valid',
  Expiring = 'Expiring',
  Expired = 'Expired',
  All = 'All',
}
