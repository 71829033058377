import React from 'react';
import { Typography, Link } from '@mui/material';
import { useNotACustomerStyles } from './styled';

const NotACustomer = () => {
  const classes = useNotACustomerStyles();

  return (
    <>
      <Typography
        variant="body1"
        className={classes.linkText}
        sx={{ marginBottom: '72px' }}
      >
        Not a Fishbowl Drive Customer?
        <Link
          href="https://www.fishbowlinventory.com/#fishbowl-drive"
          className="redesign"
          target="_blank"
          rel="noopener noreferrer"
          underline="hover"
          sx={{
            fontSize: '16px !important',
          }}
        >
          {` Click here `}
        </Link>
        to learn how Fishbowl Drive can help your business.
      </Typography>
    </>
  );
};

export default NotACustomer;
