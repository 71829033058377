import React, { memo, useCallback, useMemo } from 'react';
import { TableCell } from '@mui/material';
import { colorPalette } from 'ui/theme';
import _ from 'lodash';
import moment from 'moment';

import { DateTooltip } from 'ui/components/DateTooltip';
import { DateMessage } from 'ui/components/DateTooltip/types';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { Customer } from 'services/customers';
import { useGetIntlDateFormatString } from 'helpers';

import { createActionBarOptions, CustomerPageRowAction } from './consts';
import { useCustomersRowStyle } from './styled';

import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';

const CustomersRow: React.FC<RenderCustomRowProps<Customer>> = (props) => {
  const { row, columns, onAction } = props;

  const classes = useCustomersRowStyle();
  const intlFormatDate = useGetIntlDateFormatString();

  const handleAction = useCallback(
    (actionType: CustomerPageRowAction) => {
      if (onAction) {
        onAction({ type: actionType, value: row });
      }
    },
    [onAction, row]
  );

  const representativeName = `${_.get(row, 'representative.firstName', '')} ${
    _.get(row, 'representative.lastName') || ''
  }`;

  const tooltipDates: DateMessage[] = useMemo(
    () => [
      { date: row.dateCreated, message: 'Date Created' },
      { date: row.dateLastModified, message: 'Date Last Modified' },
    ],
    [row]
  );

  const dateValue: string = useMemo(() => {
    const dateField = columns.length > 1 ? columns[4].field! : null;
    const date = dateField ? _.get(row, dateField, null) : null;

    return date ? moment(date).format(intlFormatDate) : '';
  }, [columns, row, intlFormatDate]);

  return (
    <>
      <TableCell className={classes.cellStyle}>{_.get(row, 'name')}</TableCell>

      {columns.length > 1 && (
        <>
          <TableCell className={classes.cellStyle}>
            {representativeName}
          </TableCell>
          <TableCell className={classes.cellStyle}>
            {_.get(row, 'phone')}
          </TableCell>
          <TableCell className={classes.cellStyle}>
            {_.get(row, 'email')}
          </TableCell>
          <DateTooltip values={tooltipDates}>
            <TableCell>{dateValue}</TableCell>
          </DateTooltip>
          <TableCell sx={{ padding: 0 }} align="center">
            <FBOMenuButton
              variant="tertiary"
              items={createActionBarOptions(handleAction, row)}
              data-qa="customers-row-3-dot-menu-button"
              style={{ color: colorPalette.redesign.contentSecondary }}
            />
          </TableCell>
        </>
      )}
    </>
  );
};

export default memo(CustomersRow);
