import _ from 'lodash';

import { findNextNegativeId, replaceValueInCollection } from 'helpers';
import { SaleItem } from 'services/items/saleItems';
import { getSaleItemPricingRule } from 'services/items/saleItems/api';
import {
  SalesOrderItemTypes,
  SalesOrderItem,
  newSalesOrderItem,
  SalesOrderItemStatus,
  DiscountTypes,
  calculateTotals,
  Discount,
  SalesOrderBundleItem,
} from 'services/salesOrders';
import { TaxRate } from 'services/taxRates';
import { EACH_UOM_ID, Uom } from 'services/uoms';

const getTaxIdForNewSoItem = (
  salesOrderItemType: SalesOrderItemTypes,
  taxRate: TaxRate | null
): number | null => {
  // if type is FlatTaxRate, only flat rate tax types are available
  // in sales order you can only select percentage tax type so it will always be null
  if (!taxRate || salesOrderItemType === SalesOrderItemTypes.FlatTaxRate) {
    return null;
  }

  return taxRate.id;
};

/**
 * Creates new So Items based on current selected data
 * @param type
 * @param items
 * @param saleItemForm
 * @param lineNumber
 */

export const createSoItems = async (
  salesOrderItemType: SalesOrderItemTypes,
  exchangeRate: number | null,
  soItems: (SalesOrderItem | SalesOrderBundleItem)[],
  saleItem: SaleItem | null,
  amount: number | null,
  uomId: number | null,
  taxRate: TaxRate | null,
  customerId: number | null,
  uoms: Uom[],
  ignorePricingRule: boolean = false,
  isBundle: boolean = false,
  taxRates: TaxRate[] = [],
  taxExempt: boolean = false,
  priceIncludesTax: boolean = false
): Promise<SalesOrderItem[]> => {
  // We set id to negative number so it can be selected from Items table
  const nextId = findNextNegativeId(soItems);
  const notDeletedSoItems = soItems.filter((i) => !i.deleted);
  const deletedSoItems = soItems.filter((i) => i.deleted);

  const { price, hasPricingRule } = await calculateSoItemPrice(
    salesOrderItemType,
    saleItem,
    amount,
    customerId,
    uomId,
    ignorePricingRule
  );

  const discount: Discount = { type: DiscountTypes.Percent, value: null };
  const taxable = saleItem
    ? saleItem.taxable || saleItem.taxable === null
    : false;

  let newItemTaxRatePercentage: number | null = null;
  let newItemTaxRate: TaxRate | null = null;

  if (taxable && !taxExempt) {
    const newItemTaxId = saleItem ? saleItem.salesTaxId : null;
    newItemTaxRate = newItemTaxId
      ? taxRates.find((tr: TaxRate) => newItemTaxId === tr.id) || null
      : taxRate;
    newItemTaxRatePercentage = newItemTaxRate
      ? newItemTaxRate.percentage
      : null;
  }

  const totals = calculateTotals(
    price,
    amount || 0,
    discount,
    newItemTaxRatePercentage || 0,
    priceIncludesTax,
    exchangeRate || 1
  );

  if (isBundle && saleItem) {
    const bundleSoItems = saleItem.bundleItems.map((bi, index) => {
      const saleItem = bi.saleItem;
      const taxable = saleItem
        ? saleItem.taxable || saleItem.taxable === null
        : false;
      let saleItemTaxId: number | null = null;
      let saleItemTaxRatePercentage = null;

      if (taxable) {
        saleItemTaxId = _.get(bi, 'saleItem.salesTaxId', null);
        const taxRate =
          taxRates.find((tr: TaxRate) => saleItemTaxId === tr.id) || null;
        saleItemTaxRatePercentage = _.get(taxRate, 'percentage', null);
      }

      const newSoItem: SalesOrderItem = {
        ...newSalesOrderItem(SalesOrderItemTypes.Sale),
        version: null,
        saleItemId: saleItem ? saleItem.id : null,
        saleItem,
        salesOrderItemType: SalesOrderItemTypes.Sale,
        name: saleItem ? saleItem.name : null,
        discount: {
          type: DiscountTypes.Percent,
          value: bi.discountPercent,
        },
        price: bi.price || 0,
        hasPricingRule: false,
        quantity: bi.quantity,
        taxable,
        taxId: saleItemTaxId,
        taxRate: saleItemTaxRatePercentage,
        lineNumber: index,
        uomId: saleItem ? saleItem.defaultUomId : EACH_UOM_ID,
        description: saleItem ? saleItem.description : null,
      };
      return newSoItem;
    });

    const newSoBundleItem: SalesOrderBundleItem = {
      ...newSalesOrderItem(salesOrderItemType),
      id: nextId,
      itemId: saleItem ? saleItem.id : null,
      item: saleItem ? saleItem.item : null,
      saleItemId: saleItem ? saleItem.id : null,
      saleItem,
      version: null,
      salesOrderItemType: isBundle
        ? SalesOrderItemTypes.Bundle
        : salesOrderItemType,
      name: saleItem ? saleItem.name : null,
      discount,
      price: price,
      hasPricingRule,
      quantity: amount,
      taxable,
      description: saleItem ? saleItem.description : null,
      taxId: taxable ? getTaxIdForNewSoItem(salesOrderItemType, taxRate) : null,
      taxRate: newItemTaxRatePercentage,
      uomId,
      salesOrderItems: bundleSoItems,
      ...totals,
    };

    return orderLineNumbers([
      ...notDeletedSoItems,
      newSoBundleItem,
      ...deletedSoItems,
    ]);
  }

  const cost = _.get(saleItem, 'item.cost', 0);

  const newSoItem: SalesOrderItem = {
    ...newSalesOrderItem(salesOrderItemType),
    id: nextId,
    version: null,
    saleItemId: saleItem ? saleItem.id : null,
    saleItem,
    salesOrderItemType,
    name: saleItem ? saleItem.name : null,
    discount,
    price: price,
    multiCurrencyItemPrice: price * (exchangeRate || 1),
    hasPricingRule,
    quantity: amount,
    taxable,
    cost:
      salesOrderItemType === SalesOrderItemTypes.CreditReturn ||
      salesOrderItemType === SalesOrderItemTypes.BundleCreditReturn
        ? -cost
        : cost,
    exchangeRate,
    description: saleItem ? saleItem.description : null,
    taxId: taxable
      ? getTaxIdForNewSoItem(salesOrderItemType, newItemTaxRate)
      : null,
    taxRate: newItemTaxRatePercentage,
    uomId,
    ...totals,
  };

  return orderLineNumbers([...notDeletedSoItems, newSoItem, ...deletedSoItems]);
};

export const calculateSoItemPrice = async (
  salesOrderItemType: SalesOrderItemTypes,
  saleItem: SaleItem | null,
  quantity: number | null,
  customerId: number | null,
  uomId: number | null,
  ignorePricingRule: boolean = false
) => {
  let price = Math.abs((saleItem && saleItem.price) || 0);
  const itemId = saleItem?.item?.id || saleItem?.itemId;

  let hasPricingRule = false;

  if (
    !ignorePricingRule &&
    saleItem &&
    itemId &&
    saleItem.id &&
    customerId &&
    quantity &&
    uomId
  ) {
    try {
      const saleItemPricingRule = await getSaleItemPricingRule(
        itemId,
        saleItem.id,
        customerId,
        quantity,
        uomId
      );

      if (saleItemPricingRule.price !== price) {
        price = saleItemPricingRule.price;
        hasPricingRule = true;
      }
      // eslint-disable-next-line no-empty
    } catch {}
  }

  if (salesOrderItemType === SalesOrderItemTypes.CreditReturn) {
    price = -price;
  }

  return {
    price,
    hasPricingRule,
  };
};

export const orderLineNumbers = (
  soItems: (SalesOrderItem | SalesOrderBundleItem)[]
) => {
  let lastIndex = 0;
  const orderedSoItems = soItems.map((s, index) => {
    lastIndex = index === 0 ? 0 : ++lastIndex;

    if (
      s.salesOrderItemType === SalesOrderItemTypes.Bundle ||
      s.salesOrderItemType === SalesOrderItemTypes.BundleCreditReturn
    ) {
      const prevLineNumber = lastIndex + 1;
      const newBundleItemSoItems = (
        s as SalesOrderBundleItem
      ).salesOrderItems.map((soi, i) => {
        lastIndex = prevLineNumber + i;
        return { ...soi, lineNumber: lastIndex + 1 };
      });
      return {
        ...s,
        lineNumber: prevLineNumber,
        salesOrderItems: newBundleItemSoItems,
      };
    }
    return { ...s, lineNumber: lastIndex + 1 };
  });
  return orderedSoItems;
};

export const moveDownSoItem = (
  soItem: SalesOrderItem | SalesOrderBundleItem,
  soItems: (SalesOrderItem | SalesOrderBundleItem)[]
) => {
  let notDeletedSoItems = soItems.filter((i) => !i.deleted);
  const deletedSoItems = soItems.filter((i) => i.deleted);

  const index = notDeletedSoItems.findIndex((i) => i.id === soItem.id);

  if (index + 1 === notDeletedSoItems.length) {
    return soItems;
  }

  const nextItem = notDeletedSoItems[index + 1];

  notDeletedSoItems = replaceValueInCollection(
    notDeletedSoItems,
    soItem,
    index + 1
  )!;

  notDeletedSoItems = replaceValueInCollection(
    notDeletedSoItems,
    nextItem,
    index
  )!;

  return orderLineNumbers([...notDeletedSoItems, ...deletedSoItems]);
};

export const moveUpSoItem = (
  soItem: SalesOrderItem | SalesOrderBundleItem,
  soItems: (SalesOrderItem | SalesOrderBundleItem)[]
) => {
  let notDeletedSoItems = soItems.filter((i) => !i.deleted);
  const deletedSoItems = soItems.filter((i) => i.deleted);

  const index = notDeletedSoItems.findIndex((i) => i.id === soItem.id);

  if (index === 0) {
    return soItems;
  }

  const prevItem = notDeletedSoItems[index - 1];

  notDeletedSoItems = replaceValueInCollection(
    notDeletedSoItems,
    soItem,
    index - 1
  )!;

  notDeletedSoItems = replaceValueInCollection(
    notDeletedSoItems,
    prevItem,
    index
  )!;

  return orderLineNumbers([...notDeletedSoItems, ...deletedSoItems]);
};

export const duplicateSoItem = (
  soItem: SalesOrderItem | SalesOrderBundleItem,
  soItems: (SalesOrderItem | SalesOrderBundleItem)[]
) => {
  const notDeletedSoItems = soItems.filter((i) => !i.deleted);
  const deletedSoItems = soItems.filter((i) => i.deleted);

  const duplicatedSoItem = {
    ...soItem,
    id: findNextNegativeId<SalesOrderItem>(soItems),
    status: SalesOrderItemStatus.Entered,
    version: null,
  };

  if (
    soItem.salesOrderItemType === SalesOrderItemTypes.Bundle ||
    soItem.salesOrderItemType === SalesOrderItemTypes.BundleCreditReturn
  ) {
    return orderLineNumbers([
      ...notDeletedSoItems,
      duplicateBundleSoItem(soItem as SalesOrderBundleItem, soItems),
      ...deletedSoItems,
    ]);
  }

  return orderLineNumbers([
    ...notDeletedSoItems,
    duplicatedSoItem,
    ...deletedSoItems,
  ]);
};

const duplicateBundleSoItem = (
  soBundleItem: SalesOrderBundleItem,
  soItems: (SalesOrderItem | SalesOrderBundleItem)[]
) => {
  const bundleSalesOrderItems = soBundleItem.salesOrderItems.map(
    (soi: SalesOrderItem, index: number) => ({
      ...soi,
      id: parseFloat('-' + (index + 1)),
    })
  );

  const duplicatedSoItem: SalesOrderBundleItem = {
    ...soBundleItem,
    id: findNextNegativeId<SalesOrderItem>(soItems),
    status: SalesOrderItemStatus.Entered,
    version: null,
    salesOrderItems: bundleSalesOrderItems,
  };

  return duplicatedSoItem;
};

export const duplicateSoItemToCreditReturn = (
  soItem: SalesOrderItem,
  soItems: SalesOrderItem[]
) => {
  const notDeletedSoItems = soItems.filter((i) => !i.deleted);
  const deletedSoItems = soItems.filter((i) => i.deleted);

  const newType =
    soItem.salesOrderItemType === SalesOrderItemTypes.Sale
      ? SalesOrderItemTypes.CreditReturn
      : SalesOrderItemTypes.MiscReturn;

  const duplicatedSoItem = {
    ...soItem,
    id: findNextNegativeId<SalesOrderItem>(soItems),
    status: SalesOrderItemStatus.Entered,
    multiCurrencyItemPrice: soItem.multiCurrencyItemPrice
      ? -soItem.multiCurrencyItemPrice
      : 0,
    multiCurrencyTotal: soItem.multiCurrencyTotal
      ? -soItem.multiCurrencyTotal
      : 0,
    subTotal: soItem.subTotal ? -soItem.subTotal : 0,
    price: soItem.price ? -soItem.price : 0,
    salesOrderItemType: newType,
    version: null,
  };

  return orderLineNumbers([
    ...notDeletedSoItems,
    duplicatedSoItem,
    ...deletedSoItems,
  ]);
};
