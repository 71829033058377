import _ from 'lodash';

import { CreateAccountFormValues, CreateAccountPayload } from './types';

export const transformToRequestRegister = (
  formValues: CreateAccountFormValues
): CreateAccountPayload => {
  return {
    companyName: formValues.companyName,
    companyPhone: formValues.companyPhone,
    country: _.get(formValues, 'country.code', null),
    currencyRegistration: {
      currencyCountry: _.get(formValues, 'currencyCountry.code', null),
      currencyCode: _.get(formValues, 'currencyCountry.currencyCode', null),
      currencyName: _.get(formValues, 'currencyCountry.currencyName', null),
    },
    email: formValues.email,
    eulaAccepted: true,
    nameFirst: formValues.firstName,
    nameLast: formValues.lastName,
    timezone: _.get(formValues, 'timezone.utc[0]'),
    password: formValues.password,
  };
};
