import React, { memo } from 'react';
import { Box, IconButton, Pagination, PaginationItem } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import { useEnhancedFooterStyles } from './styled';

/**
 *
 * @param onPageChange
 * @param count
 * @param rowsPerPage
 * @param page
 * @return {JSX.Element | null}
 * @constructor
 */
const EnhancedTablePaginationActions = ({
  onPageChange,
  count,
  rowsPerPage,
  page,
}: TablePaginationActionsProps) => {
  const numberOfPages = Math.ceil(count / rowsPerPage);
  const paginationCurrentPage = page + 1;

  const classes = useEnhancedFooterStyles();

  // Stop execute if rowsPerPage is not available and if there is only one page
  if (!rowsPerPage || numberOfPages <= 1) {
    return null;
  }

  /**
   *
   * @param event
   * @param page
   * @description Go to pagination number/page when numbered od prev/next/first/last element is clicked
   */
  const onElementClickHandler = (event: any, page: number) => {
    onPageChange(event, page - 1);
  };

  return (
    <Box display="flex" ml={5}>
      <IconButton
        onClick={(e) => onElementClickHandler(e, 1)}
        disabled={paginationCurrentPage === 1}
        aria-label="First page"
        size="small"
      >
        <FirstPageIcon />
      </IconButton>

      <div className={classes.tablePaginationContainer}>
        <Pagination
          count={numberOfPages}
          defaultPage={paginationCurrentPage}
          page={paginationCurrentPage}
          renderItem={(paginationItem) => {
            return (
              <PaginationItem
                {...paginationItem}
                onClick={(e) => {
                  if (paginationItem.page) {
                    onElementClickHandler(e, paginationItem.page);
                  }
                }}
              />
            );
          }}
        />
      </div>

      <IconButton
        onClick={(e) => onElementClickHandler(e, numberOfPages)}
        disabled={paginationCurrentPage >= numberOfPages}
        aria-label="Last page"
        size="small"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
};

export default memo(EnhancedTablePaginationActions);
