import React, { memo, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createFilterOptions } from '@mui/material/Autocomplete';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import _ from 'lodash';

import {
  PaymentTerm,
  initialPaymentTerm,
  getPaymentTerms,
  createPaymentTerm,
  fetchPaymentTerms,
  PaymentTermType,
} from 'services/paymentTerms';
import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import { useHandleTextFieldChange } from 'services/forms';
import { Errors, validateYup } from 'services/forms/validation';

import { getPaymentTermValidation } from './validations';
import { Autocomplete } from '../Autocomplete';
import { PaymentTermsAutocompleteProps } from './types';

const filter = createFilterOptions<PaymentTerm>();

const PaymentTermsAutocomplete: React.FC<PaymentTermsAutocompleteProps> = (
  props
) => {
  const { onChange, ...otherProps } = props;

  const dispatch = useDispatch();

  const { items: paymentTerms } = useSelector(getPaymentTerms);

  const [showModal, setShowModal] = useState(false);
  const [formPaymentTerm, setFormPaymentTerm] = useState<PaymentTerm>({
    ...initialPaymentTerm,
    paymentTermType: PaymentTermType.Standard,
  });
  const [applyDiscountSelected, setApplyDiscountSelected] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Errors>({});

  const isNetTermSelected = useMemo(
    () => formPaymentTerm.paymentTermType === PaymentTermType.Standard,
    [formPaymentTerm.paymentTermType]
  );

  const handleChange = (e: any, paymentTerm: PaymentTerm | null) => {
    if (paymentTerm && (paymentTerm.id === null || paymentTerm.id < 0)) {
      setFormPaymentTerm((old) => ({ ...old, name: paymentTerm.name }));
      setErrors({});
      setShowModal(true);
      return;
    }

    onChange(paymentTerm);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFormPaymentTerm({
      ...initialPaymentTerm,
      paymentTermType: PaymentTermType.Standard,
    });
  };

  const handleApplyModal = async () => {
    if (
      !validateYup(
        formPaymentTerm,
        getPaymentTermValidation(applyDiscountSelected),
        setErrors
      )
    ) {
      return;
    }

    setIsLoading(true);

    try {
      const newPaymentTerm = await createPaymentTerm(formPaymentTerm);
      // Lint skip to be removed
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(fetchPaymentTerms());
      onChange(newPaymentTerm);
    } catch {
      setIsLoading(false);
      return;
    }

    setShowModal(false);
    setIsLoading(false);
    setFormPaymentTerm(initialPaymentTerm);
  };

  const handleTextFieldChange = useHandleTextFieldChange(
    setFormPaymentTerm,
    formPaymentTerm
  );

  const handleChangeType = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    // Reset form values and validations covered in Radio groups
    setFormPaymentTerm((old) => ({
      ...old,
      paymentTermType: value as PaymentTermType,
      dueDays: null,
      graceDays: null,
      dayOfMonthDue: null,
    }));
    setApplyDiscountSelected(false);
    setErrors({ name: errors.name });
  };

  const handleApplyDiscount = (e: any) => {
    setApplyDiscountSelected(e.target.checked);
    setFormPaymentTerm((old) => ({
      ...old,
      discountDays: null,
      discountPercent: null,
    }));
    // Remove validation errors from fields
    setErrors(_.omit(errors, ['discountPercent', 'discountDays']));
  };

  return (
    <>
      <Autocomplete
        options={paymentTerms}
        getOptionLabel={(paymentTerm: PaymentTerm) => {
          if (!paymentTerm.id || paymentTerm.id < 0) {
            return `+ Add "${paymentTerm.name}"`;
          }

          return paymentTerm.name || '';
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (!!params.inputValue) {
            filtered.unshift({
              ...initialPaymentTerm,
              name: params.inputValue || null,
              paymentTermType: PaymentTermType.Standard,
            });
          }

          return filtered;
        }}
        onChange={handleChange}
        autoSelect={false}
        {...otherProps}
      />
      <Modal
        open={showModal}
        title="New Payment Term"
        onCancelClicked={handleCloseModal}
        onApplyClicked={handleApplyModal}
        isLoadingContent={isLoading}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              className="redesign"
              variant="standard"
              name="name"
              label="Name"
              value={formPaymentTerm.name}
              onChange={handleTextFieldChange}
              required
              error={!!errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <RadioGroup
              value={formPaymentTerm.paymentTermType}
              onChange={handleChangeType}
              row
            >
              <FormControlLabel
                value={PaymentTermType.Standard}
                control={<Radio />}
                label="Net Term"
              />
              <FormControlLabel
                value={PaymentTermType.DateDriven}
                control={<Radio />}
                label="Date Driven"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={12}>
            {isNetTermSelected ? (
              <Grid item>
                <Box display="flex" alignItems="baseline" mb={2}>
                  <TextField
                    className="redesign"
                    variant="standard"
                    type="number"
                    name="dueDays"
                    label="Net Days"
                    fullWidth={false}
                    value={formPaymentTerm.dueDays}
                    onChange={handleTextFieldChange}
                    error={!!errors.dueDays}
                    dataQa="payment-term-net-days"
                  />
                </Box>
              </Grid>
            ) : (
              <>
                <Grid item>
                  <Box display="flex" alignItems="baseline" mb={2}>
                    <TextField
                      className="redesign"
                      variant="standard"
                      type="number"
                      name="dayOfMonthDue"
                      label="Date Due"
                      inputProps={{ max: 28 }}
                      fullWidth={false}
                      value={formPaymentTerm.dayOfMonthDue}
                      onChange={handleTextFieldChange}
                      error={!!errors.dayOfMonthDue}
                    />
                    <Box ml={2} mr={2}>
                      <Typography color="textPrimary">each month.</Typography>
                    </Box>
                    <Box mr={2}>
                      <Typography color="textPrimary">
                        Due the following month if fulfilled within
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="baseline">
                      <TextField
                        className="redesign"
                        variant="standard"
                        type="number"
                        name="graceDays"
                        label="Days"
                        inputProps={{ max: 28 }}
                        fullWidth={false}
                        value={formPaymentTerm.graceDays}
                        onChange={handleTextFieldChange}
                        error={!!errors.graceDays}
                      />
                    </Box>
                  </Box>
                </Grid>
              </>
            )}
            <Grid item>
              <Box mb={2}>
                <FormControlLabel
                  value="applyDiscountSelected"
                  name="discount"
                  control={
                    <Checkbox
                      className="redesign"
                      color="primary"
                      inputProps={
                        {
                          'data-qa': 'payment-term-apply-discount',
                        } as any
                      }
                    />
                  }
                  label="Apply discount if paid early"
                  checked={applyDiscountSelected}
                  onChange={handleApplyDiscount}
                />
              </Box>
            </Grid>
            <Grid item>
              <Box display="flex" alignItems="baseline">
                <TextField
                  className="redesign"
                  variant="standard"
                  type="number"
                  name="discountPercent"
                  label="Discount"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        disableTypography={
                          !isNetTermSelected || !applyDiscountSelected
                        }
                        position="end"
                      >
                        %
                      </InputAdornment>
                    ),
                  }}
                  fullWidth={false}
                  value={formPaymentTerm.discountPercent}
                  onChange={handleTextFieldChange}
                  error={!!errors.discountPercent}
                  disabled={!applyDiscountSelected}
                  dataQa="payment-term-discount-percent"
                />
                <Box ml={2} mr={2}>
                  <Typography
                    color={
                      !applyDiscountSelected ? 'textSecondary' : 'textPrimary'
                    }
                  >
                    if paid within
                  </Typography>
                </Box>
                <TextField
                  className="redesign"
                  variant="standard"
                  type="number"
                  name="discountDays"
                  label="Days"
                  fullWidth={false}
                  value={formPaymentTerm.discountDays}
                  onChange={handleTextFieldChange}
                  error={!!errors.discountDays}
                  disabled={!applyDiscountSelected}
                  dataQa="payment-term-discount-days"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default memo(PaymentTermsAutocomplete);
