import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@mui/material';

import {
  fetchIntegrationsRedirect,
  RedirectStoreTypes,
} from 'services/integrations';
import { Page } from 'ui/components/Page/Page';

import { Routes } from '../../navigation';
import {
  IntegrationsRedirectPageCmp,
  IntegrationsRedirectPageProps,
} from './types';
import { logErrorCtx } from 'app/logging';

const IntegrationsRedirectPage: IntegrationsRedirectPageCmp = (
  props: IntegrationsRedirectPageProps
) => {
  const {
    location: { search },
  } = props;

  const { store } = useParams<{ store: RedirectStoreTypes }>();
  const storeType =
    Object.values(RedirectStoreTypes).find((s) => s === store) || null;

  useEffect(() => {
    (async () => {
      if (!storeType) {
        return;
      }

      try {
        const newUrl = await fetchIntegrationsRedirect(storeType, search);
        window.location.href = newUrl;
      } catch (err) {
        logErrorCtx('Error in fetchIntegrationsRedirect', {
          error: err as Error,
          component: 'IntegrationsRedirectPage',
          description: `Arguments storeType ${storeType}, search ${search}`,
        });
      }
    })();
  }, [search, storeType]);

  return (
    <Page>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        {storeType && (
          <>
            <CircularProgress />
            <Box mt={5}>
              <Typography variant="h4" color="textPrimary">
                You are being redirected ...
              </Typography>
            </Box>
          </>
        )}
        {!storeType && (
          <Box mt={5}>
            <Typography variant="h4" color="textPrimary">
              No store registered under that name
            </Typography>
          </Box>
        )}
      </Box>
    </Page>
  );
};

IntegrationsRedirectPage.route = Routes.IntegratonsRedirectPage;

export default IntegrationsRedirectPage;
