import styled from 'styled-components';
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { IconButton } from '@mui/material';

import { StyledButtonProps } from './types';

const buttonStyle = {
  borderRadius: 0,
  width: 33,
  borderTop: '1px solid #c4c4c4',
  borderBottom: '1px solid #c4c4c4',
  borderLeft: '1px solid #c4c4c4',
};

export const useMultiFormatInputStyle = makeStyles((theme: Theme) => ({
  input: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  buttonMiddle: {
    ...buttonStyle,
    ...theme.typography.subtitle1,
    color: theme.palette.text.primary,
  },
  buttonEdge: {
    ...buttonStyle,
    ...theme.typography.subtitle1,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    color: theme.palette.text.primary,
  },
}));

export const StyledIconButton: React.FC<StyledButtonProps> = styled(IconButton)`
  background-color: ${(props: StyledButtonProps) =>
    props.active === 'true' ? '#ebebeb' : ''} !important;
`;
