import React, { memo, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Tab, Tabs } from '@mui/material';
import _, { isEqual } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { useLongPolling } from 'helpers';

import { TabPanel } from 'ui/components/TabPanel';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { ReportsModal } from 'ui/components/Modal/ReportsModal';
import { DetailsCard } from 'ui/components/Page/DetailsCard';
import {
  initialSalesOrder,
  SalesOrder,
  SalesOrderPaymentStatus,
} from 'services/salesOrders';
import { showNotification } from 'services/api/notifications';
import { Errors, validateYup } from 'services/forms/validation';
import { useUrlQueryObject } from 'services/url';
import { FulfillServiceAndMiscItemsOnShip } from 'services/settings/shipping';
import {
  clearModuleNavigation,
  ModuleNavigationType,
  updateModuleNavigation,
} from 'services/moduleNavigation';

import { Routes } from 'ui/modules/sales/navigation';
import { FetchOptions } from 'ui/components/Page/WithSearchResults';
import { LongPolling } from 'ui/components/Dialog/LongPolling';
import { DismissibleModal } from 'ui/components/Modal/DismissableModal/DismissibleModal';
import { ReportId } from 'services/reports/types';
import { SalesOrderGeneralTab } from 'ui/modules/sales/pages/SalesOrderPage/components/SalesOrderDetailsCard/components/SalesOrderGeneralTab';
import SalesOrderItems from 'ui/modules/sales/pages/SalesOrderPage/components/SalesOrderDetailsCard/components/SalesOrderItems/SalesOrderItems';
import SalesOrderDetailsTab from 'ui/modules/sales/pages/SalesOrderPage/components/SalesOrderDetailsCard/components/SalesOrderDetailsTabBeta/SalesOrderDetailsTab';

import {
  SalesOrderActiveIdState,
  SalesOrderTab,
} from 'ui/modules/sales/pages/SalesOrderPage/components/SalesOrderDetailsCard/types';
import { validateSalesOrder as salesOrderValidator } from 'ui/modules/sales/pages/SalesOrderPage/components/SalesOrderDetailsCard/helpers';
import { noTaxAddedModalTitle } from 'ui/modules/purchasing/pages/PurchaseOrderPage/components/PurchaseOrderDetailsCard/consts';
import { noTaxAddedBodyContent } from 'ui/modules/sales/pages/SalesOrderPage/components/SalesOrderDetailsCard/consts';
import CreatePurchaseOrderWizard from 'ui/modules/sales/pages/SalesOrderPage/components/SalesOrderDetailsCard/components/CreatePurchaseOrderWizard/CreatePurchaseOrderWizard';
import SalesOrderShippingQuoteModal from 'ui/modules/sales/pages/SalesOrderPage/components/SalesOrderDetailsCard/components/SalesOrderShippingQuoteModal/SalesOrderShippingQuoteModal';
import SalesOrderEmailModal from 'ui/modules/sales/pages/SalesOrderPage/components/SalesOrderDetailsCard/components/SalesOrderEmailModal/SalesOrderEmailModal';
import SalesOrderPaymentsModal from 'ui/modules/sales/pages/SalesOrderPage/components/SalesOrderDetailsCard/components/SalesOrderPaymentsModal/SalesOrderPaymentsModal';
import SalesOrderNotesTab from 'ui/modules/sales/pages/SalesOrderPage/components/SalesOrderDetailsCard/components/SalesOrderNotesTab/SalesOrderNotesTab';
import SalesOrderDocumentsTab from 'ui/modules/sales/pages/SalesOrderPage/components/SalesOrderDetailsCard/components/SalesOrderDocumentsTab/SalesOrderDocumentsTab';
import SalesOrderTitleBar from 'ui/modules/sales/pages/SalesOrderPage/components/SalesOrderTitleBar/SalesOrderTitleBar';
import { customerSchema } from 'ui/modules/sales/pages/SalesOrderPage/components/SalesOrderDetailsCard/validations';

import * as SalesOrderHooks from 'serviceModule/hooks/salesOrders';
import * as Handlers from './handlersSalesOrderServiceModule';
import { concatRoute } from 'helpers/routes';

const formatSalesOrderTitle = (
  customerName: string,
  salesOrderNumber: string,
  isDuplicate: boolean
) => {
  // could some of this be offloaded to the title bar?
  const customerDisplayName = customerName ? ` - ${customerName}` : '';
  const copySuffix = isDuplicate ? ' Copy' : '';
  return `${salesOrderNumber}${customerDisplayName}${copySuffix}`;
};

interface Props {
  activeSalesOrderId: null | number;
  onClose(): void;
  fetchSearchResult(options?: FetchOptions): Promise<void>;
  // nothing past this point is necessary any more. just can't remove until this component is officially in
  nextSalesOrdersNumber: number;
  setNextSalesOrderNumber: React.Dispatch<React.SetStateAction<number>>;
  clone: boolean;
  setClone: React.Dispatch<React.SetStateAction<boolean>>;
}

function SalesOrderDetailsCard({
  activeSalesOrderId,
  onClose,
  fetchSearchResult,
}: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    soSettings: salesOrderSettings,
    shippingSettings,
    connection,
  } = Handlers.useSalesOrderReduxState();
  const [, setQueryParams] = useUrlQueryObject(navigate, location);
  const queryClient = useQueryClient();

  const [updatedSalesOrder, setUpdatedSalesOrder] =
    useState<SalesOrder>(initialSalesOrder);
  const {
    data,
    refetch,
    // have to use "isFetching" for query because "isLoading" won't fire while it's holding the data
    isFetching: salesOrderIsLoading,
  } = useQuery({
    queryKey: ['sales-order', activeSalesOrderId],
    queryFn: () => getSelectedSalesOrder(activeSalesOrderId),
    placeholderData: initialSalesOrder,
    keepPreviousData: true,
    // we have to count on invalidating this on save or the form will clear out when it goes stale
    staleTime: Infinity,
    onSuccess(salesOrder) {
      setUpdatedSalesOrder(salesOrder);
      oldState.current = salesOrder;
    },
  });

  const activeSalesOrder = data || initialSalesOrder;
  const oldState = useRef<SalesOrder>(data!);

  const getSelectedSalesOrder = Handlers.useGetSelectedSalesOrder(
    activeSalesOrderId,
    updatedSalesOrder
  );

  const [activeTab, setActiveTab] = useState(SalesOrderTab.General);
  const [rowValidationErrors, setRowValidationErrors] = useState<Errors[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { isPolling, notify, clearNotify, startPolling, stopPolling } =
    useLongPolling();
  const [customFieldsErrors, setCustomFieldsErrors] = useState<Errors>({});
  const [errors, setErrors] = useState<Errors>({});

  // modal state
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPaidWarning, setShowPaidWarning] = useState(false);
  const [showPaymentsModal, setShowPaymentsModal] = useState(false);
  const [showQuickFulfillConfirmation, setShowQuickFulFillConfirmation] =
    useState(false);
  const [showShippingQuoteModal, setShowShippingQuoteModal] = useState(false);
  const [showCreatePOModal, setShowCreatePOModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showNoTaxAddedModal, setShowNoTaxAddedModal] = useState(false);
  const [taxWarningModalVisible, setTaxWarningModalVisible] = useState(false);

  // modal handlers (with no network logic)
  const handlePaymentsModalOnClose = () => setShowPaymentsModal(false);
  const hideDeleteModal = () => setShowDeleteModal(false);
  const onDeleteClicked = () => setShowDeleteModal(true);
  const handleTaxWarningModalCancel = () => setTaxWarningModalVisible(false);
  const handlePaidWarningModalCancel = () => setShowPaidWarning(false);
  const handleDismissClick = () => setShowNoTaxAddedModal(false);

  const { mutate: quickFulfillSalesOrder, isLoading: quickFulfillLoading } =
    SalesOrderHooks.useQuickFulfillSalesOrder();
  const { mutate: cancelSalesOrder, isLoading: cancelSalesOrderLoading } =
    SalesOrderHooks.useCancelSalesOrder();
  const { mutate: unissueSalesOrder, isLoading: unissueSalesOrderLoading } =
    SalesOrderHooks.useUnissueSalesOrder();
  const { mutate: issueSalesOrder, isLoading: issueSalesOrderLoading } =
    SalesOrderHooks.useIssueSalesOrder();
  const { mutate: deleteSalesOrder, isLoading: deleteSalesOrderLoading } =
    SalesOrderHooks.useDeleteSalesOrder();
  const { mutate: restoreSalesOrder, isLoading: restoreSalesOrderLoading } =
    SalesOrderHooks.useRestoreSalesOrder();

  const salesOrderTitle = formatSalesOrderTitle(
    updatedSalesOrder.customer?.name ?? '',
    updatedSalesOrder.number ?? '',
    activeSalesOrderId === SalesOrderActiveIdState.Duplicate
  );

  const validateSalesOrder = () =>
    salesOrderValidator(
      updatedSalesOrder,
      setErrors,
      setRowValidationErrors,
      setCustomFieldsErrors
    );

  // TODO: this is going to be replaced by invalidating the cache
  const setSelectedSalesOrder = React.useCallback(
    async (_activeSalesOrderId?: number | null) => {
      refetch();
    },
    [activeSalesOrderId]
  );

  React.useEffect(() => {
    setUpdatedSalesOrder(data!);
    oldState.current = data!;
  }, [activeSalesOrderId]);

  const handleActiveTabChange = (_event: unknown, newValue: number) =>
    setActiveTab(newValue);

  // could paid status just be passed in?
  const paidStatus = Handlers.getPaidStatus(
    // if either of these can be in the negative this could be a problem
    updatedSalesOrder.paymentTotal ?? 0,
    updatedSalesOrder.grandTotal ?? 0
  );

  // POTENTIAL BUG: this was effectively just the first part of the logical clause
  // don't know if consequences
  const paidStatusIsUnpaidOrPartiallyPaid =
    paidStatus === SalesOrderPaymentStatus.Unpaid ||
    paidStatus === SalesOrderPaymentStatus.PartiallyPaid;

  // POTENTIAL BUG: set next sales order removed from here in favor of setting when
  const onDuplicateSalesOrderClicked = async () =>
    // We use id -2 to duplicate
    setQueryParams({ activeId: SalesOrderActiveIdState.Duplicate });

  // update spatial navigation
  // the id should be based on the url so wherever this is set it should be able to get that
  useEffect(() => {
    if (!updatedSalesOrder.id || updatedSalesOrder.id < 0) {
      dispatch(clearModuleNavigation(ModuleNavigationType.Sales));
      return;
    }

    dispatch(
      updateModuleNavigation(ModuleNavigationType.Sales, {
        salesOrderIds: [updatedSalesOrder.id],
        // why is this specifically undefined
        purchaseOrderId:
          updatedSalesOrder.dropShipPurchaseOrders[0]?.id ?? undefined,
        // if this is going into the store it should be derivable higher up
        pickIds: updatedSalesOrder.picks.map((p) => p.id),
        shipIds: updatedSalesOrder.shipments.map((s) => s.id),
        receiptId: updatedSalesOrder.receipt?.id,
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    updatedSalesOrder.status,
    updatedSalesOrder.id,
    updatedSalesOrder.receipt,
  ]);

  useEffect(() => {
    if (!updatedSalesOrder.customerId) {
      // take setErrors out somehow.
      // When/why does this actually need to happen?
      // guess this requires that a customer be set. Maybe this is supposed
      // to happen on focus lost or on change
      validateYup(updatedSalesOrder, customerSchema, setErrors);
    }
    return () => {
      setErrors({});
    };
  }, [updatedSalesOrder.customerId]);

  // end effects

  // network calls
  // TODO: doesn't need to be a promise
  const cancelActiveSalesOrder = async () => {
    // can't cancel unless estimate not sure if this was here before
    // could make that a dependency?
    cancelSalesOrder(activeSalesOrderId!, {
      onSuccess() {
        queryClient.invalidateQueries(['sales-order']);
        fetchSearchResult();
      },
      onError() {
        // notification and dd log
      },
    });
  };

  const saveSalesOrder = async () => {
    const salesOrderIsNewOrDuplicate =
      activeSalesOrderId && activeSalesOrderId < 0;
    const salesOrderHasChanged = !isEqual(updatedSalesOrder, activeSalesOrder);

    if (salesOrderIsNewOrDuplicate || salesOrderHasChanged) {
      const salesOrder = await Handlers.saveSalesOrder(updatedSalesOrder);
      queryClient.invalidateQueries({
        queryKey: ['sales-order', activeSalesOrderId],
      });
      // necessary until there's something to react to other than the order id
      setUpdatedSalesOrder(salesOrder);
      oldState.current = salesOrder;

      // if the sales order was duplicate or created we need the new id
      return salesOrder.id;
    }

    return activeSalesOrderId;
  };

  const quickFulfillOrder = async () => {
    const isValid = validateSalesOrder();
    if (!isValid) {
      setActiveTab(SalesOrderTab.General);
      return;
    }

    startPolling();
    // TODO: handle null case
    const id = await saveSalesOrder();
    quickFulfillSalesOrder(id!, {
      async onSuccess() {
        // fetch necessary here because of potential status change
        queryClient.invalidateQueries(['sales-order', id]);
        await fetchSearchResult();
        stopPolling();
      },
      onError(err) {
        // notification and dd log
        showNotification(err.message, {
          variant: 'error',
        });
        stopPolling();
      },
    });
  };

  // UI action handlers
  // We use id -3 to duplicate as credit return
  // TODO: this doesn't need to be async but it's in the component type
  const duplicateAsCreditReturnClicked = async () =>
    setQueryParams({
      activeId: SalesOrderActiveIdState.DuplicateAsCreditReturn,
    });

  const showTaxWarning = () => {
    const hasTaxableItemWithoutTaxRate = updatedSalesOrder.salesItems.some(
      (item) => item.taxable && (item.taxRate === 0 || item.taxRate === null)
    );

    if (hasTaxableItemWithoutTaxRate) {
      setShowNoTaxAddedModal(true);
    }
  };

  const handleSaveClicked = async (close: boolean = false) => {
    showTaxWarning();
    const isValid = validateSalesOrder();

    if (!isValid) {
      setActiveTab(SalesOrderTab.General);
      return false;
    }

    setIsLoading(true);
    const id = await saveSalesOrder();
    // technically only needs to be fetched if the customer name or sales
    // order number changed
    await fetchSearchResult();
    if (close) {
      setQueryParams({ activeId: null });
      onClose();
    } else {
      setQueryParams({ activeId: id });
    }

    setIsLoading(false);

    return true;
  };

  const handleDeleteConfirm = async () => {
    setIsLoading(true);
    deleteSalesOrder(activeSalesOrderId!, {
      async onSuccess() {
        queryClient.invalidateQueries(['sales-order', activeSalesOrderId]);
        await fetchSearchResult();
        navigate(Routes.SalesOrderPage);
        // is this necessary if the component unmounts?
        setShowDeleteModal(false);
        onClose();
      },
      onError() {
        // notification and dd log
        showNotification('Error Encountered While Deleting Sales Order', {
          variant: 'error',
        });
      },
    });
  };

  // POSSIBLE BUG: took out some status check. The only thing it was doing was not
  // saving before showing in some cases. idk why
  // if it's fulfilled or canceled just don't save it?
  const onPaymentsClicked = async () => {
    // don't like this validation flow
    const isValid = validateSalesOrder();

    if (!isValid) {
      setActiveTab(SalesOrderTab.General);
      return;
    }

    try {
      await saveSalesOrder();
      setShowPaymentsModal(true);
    } catch (error) {
      // notification and dd log
    }
  };

  const handleIssue = async () => {
    const salesOrderHasOnlyDeletedItems = !updatedSalesOrder.salesItems.filter(
      (i) => !i.deleted
    ).length;
    if (salesOrderHasOnlyDeletedItems) {
      showNotification('You cannot issue a Sales Order that has no items.', {
        variant: 'warning',
      });
      return;
    }
    const isValid = validateSalesOrder();
    if (!isValid) {
      setActiveTab(SalesOrderTab.General);
      return;
    }
    const id = await saveSalesOrder();

    issueSalesOrder(id!, {
      onSuccess() {
        queryClient.invalidateQueries(['sales-order', id]);
        fetchSearchResult();

        // think this is only necessary if it's new
        // could probably be done when a new order is created/duplicated
        setQueryParams({ activeId: id });
      },
      onError(error) {
        // notification and dd log
        showNotification(error.message, {
          variant: 'error',
        });
      },
    });
  };

  const onUnIssueClicked = async () => {
    const id = await saveSalesOrder();
    // TODO: handle null
    unissueSalesOrder(id!, {
      onSuccess() {
        queryClient.invalidateQueries(['sales-order', id]);
        fetchSearchResult();
        // think this is only necessary if it's new
        // could probably be done when a new order is created/duplicated
        setQueryParams({ activeId: id });
      },
      onError(_err) {
        // notification and dd log
      },
    });
  };

  const onIssueClicked = () => {
    const showTaxWarningModal =
      !updatedSalesOrder.salesTaxId && salesOrderSettings.verifyNoneTaxRate;
    const showPaidWarningModal =
      paidStatusIsUnpaidOrPartiallyPaid &&
      salesOrderSettings.orderNotPaidWarning;

    if (showTaxWarningModal) {
      setTaxWarningModalVisible(true);
      return;
    }

    if (showPaidWarningModal) {
      setShowPaidWarning(true);
      return;
    }

    handleIssue();
  };

  const onQuickFulfillClicked = () => {
    // only show confirmation modal if ship has miscsale,service and note item
    const shouldShowQuickFulfillModal =
      updatedSalesOrder.salesItems.some(
        Handlers.salesOrderItemShopHasMiscSaleServiceAndNote
      ) ||
      shippingSettings.fulfillServiceAndMiscItemsOnShip ===
        FulfillServiceAndMiscItemsOnShip.Confirm;

    if (shouldShowQuickFulfillModal) {
      setShowQuickFulFillConfirmation(true);
    } else {
      quickFulfillOrder();
    }
  };

  const quickFulfillConfirmClicked = () => {
    quickFulfillOrder();
    setShowQuickFulFillConfirmation(false);
  };

  const handleTaxWarningModalContinue = () => {
    const shouldShowPaidModal =
      paidStatusIsUnpaidOrPartiallyPaid &&
      salesOrderSettings.orderNotPaidWarning;

    setTaxWarningModalVisible(false);
    if (shouldShowPaidModal) {
      setShowPaidWarning(true);
    } else {
      handleIssue();
    }
  };

  // it just hides a modal and refetches?
  const handleAddNewItemToSaleOrder = async () => {
    setShowShippingQuoteModal(false);
    setSelectedSalesOrder(activeSalesOrderId);
  };

  const handleUndeleteClicked = async (close: boolean = false) => {
    restoreSalesOrder(activeSalesOrderId!, {
      async onSuccess() {
        // fetch necessary here because of potential status change
        queryClient.invalidateQueries(['sales-order', activeSalesOrderId]);
        await fetchSearchResult();
        stopPolling();

        if (close) {
          onClose();
        }
      },
      onError() {
        // notification and dd log
      },
    });

    // this appears to be pointless but it's in the title bar props
    return true;
  };

  const handleCancelSalesOrderClicked = () => {
    // payments are only used for this one boolean
    const salesOrderHasPayments = !_.isEmpty(updatedSalesOrder.payments);
    if (salesOrderHasPayments) {
      setShowCancelModal(true);
    } else {
      cancelActiveSalesOrder();
    }
  };

  const handleCancelConfirmClicked = () => {
    cancelActiveSalesOrder();
    setShowCancelModal(false);
  };

  const handlePaidWarningModalContinue = () => {
    setShowPaidWarning(false);
    handleIssue();
  };

  const onShippingQuoteClicked = async () => {
    const isSaveValid = await handleSaveClicked();
    if (isSaveValid) {
      setShowShippingQuoteModal(true);
    }
  };

  const closeLongPollingDialog = () => {
    clearNotify();
    navigate(concatRoute(Routes.SalesRouter, Routes.SalesOrderPage), {
      replace: true,
    });
  };

  const loading =
    isLoading ||
    quickFulfillLoading ||
    cancelSalesOrderLoading ||
    unissueSalesOrderLoading ||
    issueSalesOrderLoading ||
    deleteSalesOrderLoading ||
    restoreSalesOrderLoading ||
    salesOrderIsLoading;

  return (
    <DetailsCard
      onSubmit={handleSaveClicked}
      isLoading={loading}
      isPolling={isPolling}
      state={updatedSalesOrder}
      oldState={oldState}
    >
      <SalesOrderTitleBar
        activeSalesOrder={updatedSalesOrder}
        connection={connection}
        resolvedTitle={salesOrderTitle}
        soSettings={salesOrderSettings}
        onSave={handleSaveClicked}
        onClose={onClose}
        onIssueClicked={onIssueClicked}
        onUnIssueClicked={onUnIssueClicked}
        onQuickFulfillClicked={onQuickFulfillClicked}
        onPaymentsClicked={onPaymentsClicked}
        onDeleteClicked={onDeleteClicked}
        onShippingQuoteClicked={onShippingQuoteClicked}
        duplicateClicked={onDuplicateSalesOrderClicked}
        onUndeleteClicked={handleUndeleteClicked}
        onCancelClicked={handleCancelSalesOrderClicked}
        duplicateAsCreditReturnClicked={duplicateAsCreditReturnClicked}
        showReportModal={() => setShowReportModal(true)}
        showEmailModal={() => setShowEmailModal(true)}
        showCreatePOModal={() => setShowCreatePOModal(true)}
        resolvedPaidStatus={paidStatus}
      />
      <Tabs
        value={activeTab}
        onChange={handleActiveTabChange}
        indicatorColor="primary"
        className="redesign"
      >
        <Tab
          value={SalesOrderTab.General}
          label="General"
          data-qa="sales-order-general-tab"
        />
        <Tab
          value={SalesOrderTab.Details}
          label="Details"
          data-qa="sales-order-details-tab"
        />
        <Tab
          value={SalesOrderTab.Documents}
          label="Documents"
          data-qa="sales-order-documents-tab"
        />
        <Tab
          value={SalesOrderTab.Notes}
          label="Notes"
          data-qa="sales-order-notes-tab"
        />
      </Tabs>

      {/* General Tab */}
      <TabPanel
        value={activeTab}
        index={SalesOrderTab.General}
        flexGrow
        noSpacing
      >
        <Box display="flex" flexDirection="column" width="100%" flexGrow={1}>
          <SalesOrderGeneralTab
            salesOrder={updatedSalesOrder}
            setSalesOrder={setUpdatedSalesOrder}
            validationErrors={errors}
            customFieldsErrors={customFieldsErrors}
            oldState={oldState}
            isLoading={loading}
            setIsLoading={setIsLoading}
          />
          <SalesOrderItems
            // something in this component is causing 200ms+ of lag
            salesOrder={updatedSalesOrder}
            setSalesOrder={setUpdatedSalesOrder}
            validationErrors={errors}
            rowValidationErrors={rowValidationErrors}
            setIsLoading={setIsLoading}
            oldState={oldState}
          />
        </Box>
      </TabPanel>

      {/* Details Tab */}
      <TabPanel value={activeTab} index={SalesOrderTab.Details} noSpacing>
        <SalesOrderDetailsTab
          salesOrder={updatedSalesOrder}
          setSalesOrder={setUpdatedSalesOrder}
          isLoading={loading}
          setIsLoading={setIsLoading}
        />
      </TabPanel>

      {/* Documents Tab */}
      <TabPanel value={activeTab} index={SalesOrderTab.Documents} noSpacing>
        <SalesOrderDocumentsTab
          salesOrder={updatedSalesOrder}
          setSalesOrder={setUpdatedSalesOrder}
        />
      </TabPanel>

      {/* Notes Tab */}
      <TabPanel value={activeTab} index={SalesOrderTab.Notes} noSpacing>
        <SalesOrderNotesTab
          salesOrder={updatedSalesOrder}
          setSalesOrder={setUpdatedSalesOrder}
        />
      </TabPanel>

      <ConfirmationModal
        open={showDeleteModal}
        title="Delete Sales Order"
        body={`This will delete '${
          updatedSalesOrder.number || 'SALES_ORDER'
        }' and all related sales order items, are you sure?`}
        onCancelClicked={hideDeleteModal}
        onConfirmClicked={handleDeleteConfirm}
        confirmLabel="Delete"
        confirmButtonRed
      />
      <ConfirmationModal
        open={taxWarningModalVisible}
        title="Tax Rate Not Selected"
        body={`No tax rate has been selected. Are you sure you want to continue?`}
        onCancelClicked={handleTaxWarningModalCancel}
        onConfirmClicked={handleTaxWarningModalContinue}
        confirmLabel="Continue"
        confirmButtonRed
      />
      <ConfirmationModal
        open={showCancelModal}
        title="Cancel Sales Order"
        body="You have taken payment for this order.  Would you still like to cancel?"
        onCancelClicked={() => setShowCancelModal(false)}
        onConfirmClicked={handleCancelConfirmClicked}
        confirmLabel="Yes"
        confirmButtonRed
      />
      <ConfirmationModal
        open={showPaidWarning}
        title="Order Not paid"
        body={`This order has not been paid for. Are you sure you want to continue?`}
        onCancelClicked={handlePaidWarningModalCancel}
        onConfirmClicked={handlePaidWarningModalContinue}
        confirmLabel="Continue"
        confirmButtonRed
      />
      <ConfirmationModal
        open={showQuickFulfillConfirmation}
        title="Quick Fulfill"
        body="Do you want to fulfill service, miscellaneous and note items?"
        onCancelClicked={() => setShowQuickFulFillConfirmation(false)}
        onConfirmClicked={quickFulfillConfirmClicked}
        confirmLabel="Fulfill"
        confirmButtonRed
      />
      <SalesOrderPaymentsModal
        salesOrder={updatedSalesOrder}
        setSalesOrder={setUpdatedSalesOrder}
        visible={showPaymentsModal}
        onClose={handlePaymentsModalOnClose}
        fetchSearchResult={fetchSearchResult}
        oldState={oldState}
      />
      <SalesOrderEmailModal
        salesOrder={updatedSalesOrder}
        onClose={() => setShowEmailModal(false)}
        show={showEmailModal}
      />
      <ReportsModal
        isOpen={showReportModal}
        reportId={ReportId.SalesOrder}
        params={{ salesOrderId: activeSalesOrderId }}
        onClose={() => setShowReportModal(false)}
        autoGenerate
      />
      <SalesOrderShippingQuoteModal
        visible={showShippingQuoteModal}
        onClose={() => setShowShippingQuoteModal(false)}
        activeSalesOrder={updatedSalesOrder}
        onAddNewItemClick={handleAddNewItemToSaleOrder}
      />
      <CreatePurchaseOrderWizard
        visible={showCreatePOModal}
        salesOrder={updatedSalesOrder}
        onClose={() => setShowCreatePOModal(false)}
      />
      <LongPolling
        open={notify}
        onClose={closeLongPollingDialog}
        title="Quick Fulfill Processing"
        content="Your order is processing. Check back later."
        dataQa="quickfulfill-proccessing-dialog"
      />
      <DismissibleModal
        open={showNoTaxAddedModal}
        title={noTaxAddedModalTitle}
        body={noTaxAddedBodyContent}
        onDismiss={handleDismissClick}
      />
    </DetailsCard>
  );
}

export default memo(SalesOrderDetailsCard);
