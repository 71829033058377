import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable/types';

export const VENDOR_ITEMS_COLUMNS: Column[] = [
  {
    title: 'Name',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Vendor',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Cost',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Last Date',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Lead Time',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Min Quantity',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Default',
    type: ColumnTypes.renderByCustomRow,
  },
];
