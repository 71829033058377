import React, { useMemo } from 'react';
import { Pagination } from 'services/search';
import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { PermissionType } from 'services/permissions';
import { arrayHasAllDeletedObjects } from 'helpers';
import { TaxRatesSearchResultsProps } from './types';
import {
  createActionBarOptions,
  TAX_RATE_COLUMNS,
  TAX_RATE_COLUMNS_ALWAYS_ON,
} from '../../consts';
import { default as TaxRateRow } from './TaxRateRow';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';
import { useSelector } from 'react-redux';
import { getActiveUser } from 'services/userV2';
import { useFlags } from 'helpers/useFlags';

const TaxRatesSearchResults: React.FC<TaxRatesSearchResultsProps> = (props) => {
  const {
    activeTaxRateId,
    handleTaxRateClick,
    onAddNewPress,
    onPaginationChange,
    taxRates,
    activeDate,
    onPageAction,
    isLoadingTaxRates,
    pagination,
  } = props;

  const activeUser = useSelector(getActiveUser);
  const flags = useFlags();
  const csvDisabled = flags.newUserEndpoint && !activeUser.user?.admin;

  const allDeletedTaxRates = useMemo(
    () => arrayHasAllDeletedObjects(taxRates),
    [taxRates]
  );

  const handlePaginationChange = (newPagination: Pagination) => {
    onPaginationChange(newPagination);
  };
  const resolvedColumns = activeTaxRateId
    ? TAX_RATE_COLUMNS_ALWAYS_ON
    : TAX_RATE_COLUMNS(activeDate);

  return (
    <>
      <FBOTitleBar title="Tax Rates">
        {activeTaxRateId !== -1 && (
          <FBOButton
            style={{ marginRight: '8px' }}
            variant={!activeTaxRateId ? 'primary' : 'secondary'}
            color="positive"
            size="medium"
            icon="FBOAddCircle"
            onClick={onAddNewPress}
            data-qa="tax-rate-new"
            permissions={[PermissionType.TaxRatesCreate]}
          >
            {!activeTaxRateId ? 'Add New' : ''}
          </FBOButton>
        )}
        <FBOMenuButton
          variant="secondary"
          items={createActionBarOptions(onPageAction, csvDisabled)}
          data-qa="tax-rate-three-dot-menu"
        />
      </FBOTitleBar>

      <ItemsTable
        data={taxRates}
        columns={resolvedColumns}
        RenderCustomRow={TaxRateRow}
        activeId={activeTaxRateId}
        onItemClick={handleTaxRateClick}
        selectableItems={false}
        isLoadingData={isLoadingTaxRates}
        disableSelection={allDeletedTaxRates}
        pagination={pagination}
        onPaginationChange={handlePaginationChange}
        dataQa="tax-rate-table"
        emptyTableText="ADD NEW ENTRY BY PRESSING 'ADD NEW'"
        showEnhancedTablePaginationActions={!Boolean(activeTaxRateId)}
      />
    </>
  );
};

export default TaxRatesSearchResults;
