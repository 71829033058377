import { createFilterOptions, FilterOptionsState } from '@mui/material';

import { LocationType } from 'services/locations/consts';
import {
  fetchChildLocationsAPI,
  fetchLocationsAPI,
  initialLocation,
  Location,
} from 'services/locations';
import { Pagination } from 'services/search';

const filter = createFilterOptions<Location>();

export const locationsAutocompleteOptionLabel =
  (getLocationLabel?: (l: Location) => string) => (location: Location) => {
    if (!location.id || location.id < 0) {
      return `+ Add "${location.name}"`;
    }
    if (getLocationLabel) {
      return getLocationLabel(location);
    }
    return location.name || '';
  };

export const locationsAutocompleteFilterOptions =
  (disableAdd: boolean) =>
  (options: Location[], params: FilterOptionsState<Location>) => {
    const filtered = filter(options, params);

    if (disableAdd) {
      return filtered;
    }

    if (!!params.inputValue) {
      filtered.unshift({
        ...initialLocation,
        name: params.inputValue || null,
      });
    }

    return filtered;
  };

export const resolvedFetchFunction = (
  parentId?: number | null,
  expands?: string[],
  customQuickSearchColumns?: string[],
  locationTypes?: LocationType[]
) => {
  if (parentId) {
    return (pagination: Pagination, searchValue: string | null) =>
      fetchChildLocationsAPI(
        {
          pagination: pagination,
          expands: expands,
          quickSearchValue: searchValue,
          customQuickSearchColumns: customQuickSearchColumns,
        },
        parentId
      );
  }

  return (pagination: Pagination, searchValue: string | null) =>
    fetchLocationsAPI(
      {
        pagination: pagination,
        expands: expands,
        quickSearchValue: searchValue,
        customQuickSearchColumns: customQuickSearchColumns,
        filter:
          parentId === undefined
            ? { deleted: false }
            : { root: true, deleted: false },
      },
      locationTypes
    );
};
