import React, { memo, useMemo } from 'react';
import { FormControlLabel, Grid, Checkbox, Box } from '@mui/material';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { Permission, PermissionType } from 'services/permissions';
import { activeUserHasPermission } from 'services/user/redux';

import { PermissionAreaProps, PermissionAreaState } from './types';
import { usePermissionsTabStyle } from '../../styled';
import {
  getPermissionAreaState,
  addAreaPermissions,
  removeAreaPermissions,
} from './helpers';

const PermissionArea: React.FC<PermissionAreaProps> = (props) => {
  const { permissionArea: area, permissionGroup, setPermissionGroup } = props;

  const classes = usePermissionsTabStyle();

  const canEditPermissions =
    permissionGroup.id && permissionGroup.id > 0
      ? [PermissionType.PermissionEdit]
      : [PermissionType.PermissionCreate];

  const canEdit = useSelector(activeUserHasPermission(canEditPermissions));

  const areaState = useMemo(
    () => getPermissionAreaState(area, permissionGroup.permissionIds),
    [area, permissionGroup.permissionIds]
  );

  const viewPermission = useMemo(
    () => area.permissions.find((p) => p.key === 'view') || null,
    [area.permissions]
  );

  const handleAreaChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      addAreaPermissions(area, setPermissionGroup);
    } else {
      removeAreaPermissions(area, setPermissionGroup);
    }
  };

  const handlePermissionChange =
    (permission: Permission) =>
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      // if unchecking view permission, uncheck al of the other permissions in area
      if (!checked && viewPermission && permission.id === viewPermission.id) {
        removeAreaPermissions(area, setPermissionGroup);
        return;
      }

      // add permission to permission group
      if (checked) {
        // view permission has to be checked if any other permission is checked
        // we are using _.uniq so we don't have to check if viewPermission is already in selected permissions
        setPermissionGroup((old) => ({
          ...old,
          permissionIds: viewPermission
            ? _.uniq([...old.permissionIds, permission.id!, viewPermission.id!])
            : [...old.permissionIds, permission.id!],
          permissions: viewPermission
            ? _.uniqBy(
                [...old.permissions, permission, viewPermission],
                (p) => p.id
              )
            : [...old.permissions, permission],
        }));

        return;
      }

      // remove permission from permission group
      setPermissionGroup((old) => ({
        ...old,
        permissionIds: old.permissionIds.filter((id) => id !== permission.id),
        permissions: old.permissions.filter((p) => p.id !== permission.id),
      }));
    };

  return (
    <Grid item xs={6} className={classes.permissionCategory}>
      <FormControlLabel
        control={
          <Checkbox
            className={'redesign large-checkbox'}
            checked={areaState !== PermissionAreaState.NotChecked}
            onChange={handleAreaChange}
            color="primary"
            disabled={!canEdit || permissionGroup.readOnly}
            checkedIcon={
              areaState === PermissionAreaState.PartiallyChecked ? (
                <CheckBoxOutlinedIcon />
              ) : undefined
            }
            data-qa={`permission-area-${area.name}`}
          />
        }
        label={area.name}
      />
      <Box pl={4} display="flex" flexDirection="column">
        {area.permissions.map((permission, perIndex) => (
          <FormControlLabel
            control={
              <Checkbox
                className={'redesign large-checkbox'}
                checked={permissionGroup.permissionIds.includes(permission.id)}
                onChange={handlePermissionChange(permission)}
                color="primary"
                data-qa={`permission-name-${permission.name}`}
              />
            }
            label={permission.name}
            disabled={!canEdit || permissionGroup.readOnly}
            key={`permission_${perIndex}`}
          />
        ))}
      </Box>
    </Grid>
  );
};

export default memo(PermissionArea);
