import React from 'react';
import { Route, Routes } from 'react-router-dom';

import SecureRoute from 'ui/components/Route/SecureRoute';

import { Routes as IntegrationsRoutes } from './routes';
import {
  IntegrationsPage,
  ShippingPage,
  IntegrationsRedirectPage,
} from '../pages';
import { IntegrationsRouterCmp, IntegrationsRouterProps } from './types';
import { isTestEnv } from 'services/api/testEnv';

const IntegrationsRouter: IntegrationsRouterCmp = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: IntegrationsRouterProps
) => {
  return (
    <Routes>
      <Route element={<SecureRoute redirectToHome={isTestEnv()} />}>
        <Route path={IntegrationsPage.route} element={<IntegrationsPage />} />
        <Route path={ShippingPage.route} element={<ShippingPage />} />
        <Route
          path={IntegrationsRedirectPage.route}
          element={
            <IntegrationsRedirectPage
              location={{
                search: '',
              }}
            />
          }
        />
      </Route>
    </Routes>
  );
};

IntegrationsRouter.route = IntegrationsRoutes.IntegrationsRouter;

export default IntegrationsRouter;
