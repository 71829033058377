import { SalesOrder, CreateSalesOrderPayment } from 'services/salesOrders';

export interface PaymentModalProps {
  visible: boolean;
  activeItem: SalesOrder;
  onSave(newPayment?: CreateSalesOrderPayment): void;
  onClose(): void;
  handleClosePaymentModal: () => void;
  processingPayment: boolean;
}

export interface Payment {
  name: string | null;
  type: PaymentType;
}

export interface CardType {
  code: string;
  label: string;
}

export interface PaymentFormValuesCard {
  nameOnCard: string | null;
  cardType: string | null;
  expDate: string | null;
  lastFourDigits: string | null;
}

export interface PaymentFormValuesCheck {
  referenceNumber: string | null;
}

export interface PaymentShownValues {
  total: number;
  totalPayments: number;
  balance: number;
}

export enum ActiveTabEnum {
  Card,
  Cash,
  Check,
}

export enum PaymentType {
  Card,
  Cash,
  Check,
}

export enum PaymentTypeId {
  Card = 1,
  Check = 3,
  Cash = 4,
}
