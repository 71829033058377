import * as yup from 'yup';

export const yupPickingGeneralSchema = yup.object().shape({
  orderPriorityId: yup.number().required(),
  parentLocationId: yup.number().required(),
  scheduledDate: yup.date().required(),
  userId: yup.number().required(),
});

export const yupPickNonInventoryItemSchema = yup.object().shape({
  slotNumber: yup.number().required(),
});

export const yupPickItemSchema = yup.object().shape({
  slotNumber: yup.number().required(),
  pickToLocationId: yup.string().required(),
});

export const yupPickItemCommitSchema = yup.object().shape({
  amount: yup.number().required(),
  pickFromLocationId: yup.number().required(),
  slotNumber: yup.number().required(),
});

export const yupPickNonInventoryItemCommitSchema = yup.object().shape({
  amount: yup.number().required(),
});

export const resolvePickItemCommitSchema = (isInventoryType: boolean) => {
  return isInventoryType
    ? yupPickItemCommitSchema
    : yupPickNonInventoryItemCommitSchema;
};

export const pickItemSchema = (isInventoryType: boolean) => {
  return isInventoryType ? yupPickItemSchema : yupPickNonInventoryItemSchema;
};

export const pickingTrackingTableValidationSchema = yup.object().shape({
  quantity: yup
    .number()
    .nullable(true)
    .test('quantity', 'Quantity too large', function (quantity) {
      return quantity ? quantity <= this.parent.onHand : true;
    }),
  serialIds: yup
    .array()
    .test('match', 'Selected serials do not match quantity', function (value) {
      return value!.length === this.parent.quantity;
    }),
});
