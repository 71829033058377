import { COUNTRIES } from 'ui/components/Autocomplete/CountryAutocomplete';
import { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import {
  getSettingsCompany,
  getSettingsCompanyCountry,
} from 'services/settings/company';

import { initialPageSettings } from './consts';
import { PageSettings } from './types';
import { roundToDecimals } from 'helpers';

export const useZonedDate = (date: Date | null, timezone: string = '') => {
  const dateTimeFormat = useGetIntlDateFormatString();
  return moment.tz(date, timezone).format(`${dateTimeFormat} hh:mm A`);
};

/**
 * @description
 * Hook for getting and setting page settings from/to local storage
 */

export const usePageSettings = () => {
  const [storedValue, setStoredValue] = useState<PageSettings>(() => {
    const item = localStorage.getItem('pageSettings');
    return item ? JSON.parse(item) : initialPageSettings;
  });

  const setPageSettings = useCallback(
    <K extends keyof PageSettings>(
      module: K,
      field: keyof PageSettings[K],
      value: any
    ) => {
      const newValue = {
        ...storedValue,
        [module]: { ...storedValue[module], [field]: value },
      };
      setStoredValue(newValue);
      localStorage.setItem('pageSettings', JSON.stringify(newValue));
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setStoredValue]
  );

  return { storedValue, setPageSettings };
};

export const useCurrencyFormatter = (
  minDigits: number = 2,
  maxDigits?: number
) => {
  const { homeCurrency } = useSelector(getSettingsCompany);
  const homeCurrencyCode = useMemo(
    () => (homeCurrency && homeCurrency.code) || 'USD',
    [homeCurrency]
  );

  const formatCurrency = useCallback(
    (value: number, currencyCode?: string) => {
      const currency = currencyCode || homeCurrencyCode;
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits || 6,
      });
      const floatValue = typeof value === 'string' ? parseFloat(value) : value;
      const valueToFormat = roundToDecimals(floatValue, maxDigits || 2);
      return formatter.format(valueToFormat);
    },
    [homeCurrencyCode]
  );

  return formatCurrency;
};

export const useGetCurrencySymbol = (currency?: string) => {
  const { homeCurrency } = useSelector(getSettingsCompany);
  const currencyCode = currency ?? homeCurrency?.code ?? 'USD';
  const parts = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 2,
  }).formatToParts();
  const currencyPart = parts.find((p) => p.type === 'currency');

  return currencyPart?.value ?? '$';
};

export const useGetIntlDateFormatString = () => {
  const companyCountry = useSelector(getSettingsCompanyCountry);

  const country = COUNTRIES.find((c) => c.code === companyCountry);
  const locale = country ? country.locale : 'en-US';
  const formatObj = new Intl.DateTimeFormat(locale).formatToParts(Date.now());

  return formatObj
    .map((obj) => {
      switch (obj.type) {
        case 'hour':
          return 'HH';
        case 'minute':
          return 'MM';
        case 'second':
          return 'SS';
        case 'day':
          return 'DD';
        case 'month':
          return 'MM';
        case 'year':
          return 'YYYY';
        default:
          return obj.value;
      }
    })
    .join('');
};

export const useDateFormat = () => {
  const companyCountry = useSelector(getSettingsCompanyCountry);

  const selectedCountry = useMemo(
    () => COUNTRIES.find((c) => c.code === companyCountry),
    [companyCountry]
  );

  const selectedLocale = useMemo(
    () => (selectedCountry ? selectedCountry.locale : 'en-US'),
    [selectedCountry]
  );

  const formatDate = useCallback(
    (date: Date | null, emptyValue: string = '') => {
      if (!date) {
        return emptyValue;
      }

      return new Intl.DateTimeFormat(selectedLocale, {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      } as any).format(date);
    },
    [selectedLocale]
  );

  return { formatDate };
};
