import React, { memo, useCallback, useMemo } from 'react';
import { TableCell, Box } from '@mui/material';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { ImageItemBox } from 'ui/components/Image/ImageItemBox';
import { VendorAutocomplete } from 'ui/components/Autocomplete/VendorAutocomplete';
import { getVendors, Vendor } from 'services/vendors';
import { AutoPurchaseOrderItem } from 'services/purchaseOrders';
import { TextFieldQuantity } from 'ui/components/TextField/TextFieldQuantity';
import { EACH_UOM_ID } from 'services/uoms';

const ItemsStepRow: React.FC<RenderCustomRowProps<AutoPurchaseOrderItem>> = (
  props
) => {
  const { row, setData, index, errors, meta } = props;

  const { items: vendors } = useSelector(getVendors);
  const item = row.item;
  const itemUom = _.get(item, 'defaultUom', null);
  const locationId = _.get(meta, 'locationId', null);

  const selectedVendor = useMemo(() => {
    if (row.vendorId) {
      return vendors.find((v) => v.id === row.vendorId) || null;
    }
    return null;
  }, [row, vendors]);

  const onHandQuantity: number | null = useMemo(() => {
    const inventoryLocationSummary = item.inventoryLocationSummaryList.find(
      (ils) => ils.rootLocationId === locationId
    );

    return inventoryLocationSummary
      ? inventoryLocationSummary.availableQty
      : null;
  }, [item.inventoryLocationSummaryList, locationId]);

  const handleQuantityChange = (value: number | null) => {
    setData({ ...row, quantity: value }, index);
  };

  const handleVendorChange = useCallback(
    (vendor: Vendor | null) => {
      setData({ ...row, vendorId: vendor ? vendor.id : null }, index);
    },
    [row, index, setData]
  );

  return (
    <>
      <TableCell>
        <Box>
          <ImageItemBox
            source={_.get(item, 'defaultImage', null)}
            name={_.get(item, 'name', null)}
            description={_.get(item, 'description', null)}
          />
        </Box>
      </TableCell>
      <TableCell>
        <Box>
          {onHandQuantity
            ? `${onHandQuantity} ${itemUom ? itemUom.abbreviation : ''}`
            : ''}
        </Box>
      </TableCell>
      <TableCell>
        <Box>
          <TextFieldQuantity
            placeholder="Quantity"
            name="quantity"
            uoms={itemUom ? [itemUom] : []}
            selectedUomId={itemUom ? itemUom.id! : EACH_UOM_ID}
            value={row.quantity}
            onTextChange={handleQuantityChange}
            error={!!errors.quantity}
            fullWidth
          />
        </Box>
      </TableCell>
      <TableCell>
        <Box>
          <VendorAutocomplete
            value={selectedVendor}
            onChange={handleVendorChange}
            error={!!errors.vendorId}
          />
        </Box>
      </TableCell>
    </>
  );
};

export default memo(ItemsStepRow);
