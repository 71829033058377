import { chainReducers, createApiReducer } from 'services/api';

import { SettingsPurchaseOrderActionTypes } from './actions';
import { transformPurchaseOrderSettings } from '../transformations';

const fetchSettingsPurchaseOrderReducer = createApiReducer(
  [
    SettingsPurchaseOrderActionTypes.FETCH_PURCHASE_ORDER_START,
    SettingsPurchaseOrderActionTypes.FETCH_PURCHASE_ORDER_SUCCESS,
    SettingsPurchaseOrderActionTypes.FETCH_PURCHASE_ORDER_FAILURE,
  ],
  (data) => transformPurchaseOrderSettings(data),
  (data) => data
);

const settingsPurchaseOrderChainedReducers = chainReducers(
  {},
  fetchSettingsPurchaseOrderReducer
);

export default settingsPurchaseOrderChainedReducers;
