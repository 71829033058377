import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import GetAppIcon from '@mui/icons-material/GetApp';
import { getSettingsCompany } from 'services/settings/company';
import { useDownloadsPageStyle } from '../styled';
import { DownloadItemCmp, DownloadItemProps } from '../types';
import { useZonedDate } from 'helpers';

export const DownloadFailedItem: DownloadItemCmp = (
  props: DownloadItemProps
) => {
  const { item } = props;
  const classes = useDownloadsPageStyle();
  const selector = useSelector(getSettingsCompany);
  const timezone = selector.timezone as string;
  const zonedData = item.dateCreated ? new Date(item.dateCreated) : new Date();
  const dateCreated = useZonedDate(zonedData, timezone);

  return (
    <Box key={item.id} className={classes.card}>
      <Box className={classes.cardRow}>
        <Box className={classes.cardContent}>
          <Typography variant="subtitle1" color="textSecondary">
            {item.type} • {dateCreated}
          </Typography>
          <Typography variant="body1" color="textPrimary">
            <b>{item.fileName}</b>
          </Typography>
        </Box>
        <GetAppIcon className={classes.icon} />
      </Box>
    </Box>
  );
};
