import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import { Page } from 'ui/components/Page/Page';
import {
  getShippingEventHistory,
  ShippingEventHistory,
} from 'services/integrations/shipping';
import { getShippingConnection } from 'services/integrations/shipping/redux';
import { fetchCarriers } from 'services/carriers';

import { HistoryCard, Shippo } from './components';
import { ShippingPageCmp, ShippingPageProps } from './types';
import { Routes } from '../../navigation';
import { useShippingPageStyle } from './styled';
import { ShippoProvider } from './ShippoProvider';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ShippingPage: ShippingPageCmp = (props: ShippingPageProps) => {
  const styles = useShippingPageStyle();

  const connection = useSelector(getShippingConnection);
  const dispatch = useDispatch();

  const [shippingEventHistory, setShippingEventHistory] = useState<
    ShippingEventHistory[]
  >([]);

  useEffect(() => {
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchCarriers());
  }, [dispatch]);

  useEffect(() => {
    if (!connection) {
      return;
    }

    (async () => {
      const history = await getShippingEventHistory(connection);
      setShippingEventHistory(history);
    })();
  }, [connection]);

  return (
    <Page direction="row">
      <Box className={styles.container}>
        {shippingEventHistory.length > 0 && (
          <HistoryCard history={shippingEventHistory} />
        )}

        <ShippoProvider>
          <Shippo />
        </ShippoProvider>
      </Box>
    </Page>
  );
};

ShippingPage.route = Routes.ShippingPage;

export default ShippingPage;
