import React, { useCallback, useMemo, useRef } from 'react';
import { ModalActionsContext } from './ModalActionsContext';

interface ModalActionsProviderProps {
  closeModal: () => void;
}

const ModalActionsProvider: React.FC<ModalActionsProviderProps> = ({
  closeModal,
  children,
}) => {
  const saveCallbacks = useRef<(() => void)[]>([]);
  const cancelCallbacks = useRef<(() => void)[]>([]);

  const registerSaveCallback = useCallback((callback: () => void) => {
    saveCallbacks.current.push(callback);
  }, []);

  const registerCancelCallback = useCallback((callback: () => void) => {
    cancelCallbacks.current.push(callback);
  }, []);

  const triggerSave = () => {
    saveCallbacks.current.forEach((callback) => callback());
  };

  const triggerCancel = () => {
    cancelCallbacks.current.forEach((callback) => callback());
  };

  const value = useMemo(
    () => ({
      registerSaveCallback,
      registerCancelCallback,
      triggerSave,
      triggerCancel,
      closeModal,
    }),
    [
      registerSaveCallback,
      registerCancelCallback,
      triggerSave,
      triggerCancel,
      closeModal,
    ]
  );

  return (
    <ModalActionsContext.Provider value={value}>
      {children}
    </ModalActionsContext.Provider>
  );
};

export default ModalActionsProvider;
