import { transformDateToMomentDate } from 'helpers';

import {
  Ledger,
  LedgerForWaitingToExportModal,
  LedgerItemSimple,
  LedgerLineItem,
  LedgerSimple,
} from './types';
import { getClassById } from 'services/classes/api';
import { fetchCustomerById } from 'services/customers';

export const transformLedger = (resp: any): Ledger => {
  const ledgers = resp.ledgers || [];

  return {
    id: resp.accountId,
    accountName: resp.accountName,
    startBalance: resp.startBalance,
    accountType: resp.accountType,
    endBalance: resp.endBalance,
    ledgers: ledgers.map(transformLedgerLineItems),
    markedAsPosted: resp.markedAsPosted,
    total: resp.total,
  };
};

export const transformLedgerLineItems = (resp: any): LedgerLineItem => {
  return {
    id: resp.ledgerId,
    date: transformDateToMomentDate(resp.date),
    ledgerType: resp.ledgerType,
    number: resp.number,
    name: resp.name,
    description: resp.description,
    split: resp.split,
    amount: resp.amount,
    balance: resp.balance,
    ledgerId: resp.ledgerId,
    payee: resp.payee,
    externalTransactionId: resp.externalTransactionId,
    datePosted: resp.datePosted,
  };
};

export const transformLedgerSimple = async (
  resp: any
): Promise<LedgerSimple> => {
  const ledgerItems = resp.ledgerItems || [];

  /* platform returns IDs only, requiring additional API calls for customer and class objects. 
     Web app caching is planned to minimize these calls in the future.
   */
  let customerName = null;
  let accountingClassName = null;
  if (resp.customerId !== null) {
    const customer = await fetchCustomerById(resp.customerId);
    customerName = customer.name;
  }
  if (resp.accountingClassId !== null) {
    const accountingClass = await getClassById(resp.accountingClassId);
    accountingClassName = accountingClass.name;
  }

  return {
    date: transformDateToMomentDate(resp.date),
    ledgerId: resp.ledgerId,
    ledgerItems: ledgerItems.map(transformLedgerItemsSimple),
    name: resp.name,
    refNo: resp.refNo,
    type: resp.type,
    customerId: customerName,
    accountingClassId: accountingClassName,
  };
};

export const transformLedgerItemsSimple = (resp: any): LedgerItemSimple => {
  return {
    accountId: resp.accountId,
    accountName: resp.accountName,
    creditAmount: resp.creditAmount,
    debitAmount: resp.debitAmount,
    ledgerItemId: resp.ledgerItemId,
    memo: resp.memo,
  };
};

export const transformLedgerForWaitingToExportModal = (
  resp: any
): LedgerForWaitingToExportModal => {
  return {
    id: resp.id,
    postDate: transformDateToMomentDate(resp.postDate),
    type: resp.type,
    note: resp.note,
    purchaseOrderId: resp.purchaseOrderId,
    salesOrderId: resp.salesOrderId,
  };
};
