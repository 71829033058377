import React, { memo, useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import moment from 'moment';

import { DatePickerWrapper } from 'ui/components/TextField/DatePickerWrapper';
import { TextField } from 'ui/components/TextField/TextField';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { PermissionType } from 'services/permissions';

import { DateRangeOption, PricingRulesSidebarProps } from './types';
import { usePricingRulesSidebarStyle } from './styled';
import { calculateDatesBasedOnRange } from './helpers';

const PricingRulesSidebar: React.FC<PricingRulesSidebarProps> = (props) => {
  const {
    activePricingRule,
    setActivePricingRule,
    handleTextFieldChange,
    validationErrors,
  } = props;

  const classes = usePricingRulesSidebarStyle();

  const canEditPricingRules =
    activePricingRule.id && activePricingRule.id > 0
      ? [PermissionType.PricinRulesEdit]
      : [PermissionType.PricinRulesCreate];

  const handleDateChange = useCallback(
    (name: string) => (value: any) => {
      const date = moment(value);

      setActivePricingRule((old) => ({
        ...old,
        [name]: !value ? null : date.toDate(),
      }));
    },
    [setActivePricingRule]
  );

  const handleDateRangeOptionChange = (
    _e: any,
    value: DateRangeOption | null
  ) => {
    if (!value) {
      return;
    }

    setActivePricingRule((old) => ({
      ...old,
      ...calculateDatesBasedOnRange(value),
    }));
  };

  return (
    <>
      <Box
        py={1}
        px={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className={classes.sidebarWidget}
      >
        <Typography variant="body1" color="textPrimary">
          <b>Date Range</b>
        </Typography>
      </Box>

      <Box
        p={2}
        display="flex"
        flexDirection="column"
        className={classes.sidebarWidget}
      >
        <Box>
          <Autocomplete
            label="Range"
            options={Object.values(DateRangeOption)}
            placeholder="Select range"
            onChange={handleDateRangeOptionChange}
            permissions={canEditPricingRules}
            fullWidth
            dataQa="pricing-rule-select-range"
          />
        </Box>

        <Box display="flex" alignItems="center" pt={1}>
          <DatePickerWrapper
            label="From"
            value={activePricingRule.startDate}
            name="startDate"
            onChange={handleDateChange('startDate')}
            permissions={canEditPricingRules}
            error={!!validationErrors.startDate}
            placeholder="Select date"
          />
        </Box>

        <Box display="flex" alignItems="center" pt={1}>
          <DatePickerWrapper
            label="To"
            value={activePricingRule.endDate}
            name="endDate"
            onChange={handleDateChange('endDate')}
            permissions={canEditPricingRules}
            error={!!validationErrors.endDate}
            placeholder="Select date"
          />
        </Box>
      </Box>

      <Box
        py={1}
        px={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className={classes.sidebarWidget}
      >
        <Typography variant="body1" color="textPrimary">
          <b>Quantities</b>
        </Typography>
      </Box>

      <Box
        p={2}
        display="flex"
        flexDirection="column"
        className={classes.sidebarWidget}
      >
        <Box display="flex" alignItems="center" pt={1}>
          <TextField
            className="redesign"
            variant="standard"
            label="Min"
            onChange={handleTextFieldChange}
            disableDebounce={true}
            value={activePricingRule.minQuantity}
            name="minQuantity"
            type="number"
            placeholder="0"
            permissions={canEditPricingRules}
            error={!!validationErrors.minQuantity}
            dataQa="pricing-rule-min"
          />
        </Box>

        <Box display="flex" alignItems="center" pt={1} mb={2}>
          <TextField
            className="redesign"
            variant="standard"
            label="Max"
            onChange={handleTextFieldChange}
            disableDebounce={true}
            value={activePricingRule.maxQuantity}
            name="maxQuantity"
            type="number"
            placeholder="0"
            permissions={canEditPricingRules}
            error={!!validationErrors.maxQuantity}
            dataQa="pricing-rule-max"
          />
        </Box>

        <Typography variant="body1" color="textSecondary">
          0 means infinite
        </Typography>
      </Box>
    </>
  );
};

export default memo(PricingRulesSidebar);
