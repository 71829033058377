import { combineReducers } from 'redux';
import {
  chainReducers,
  INITIAL_API_STATE,
  createApiReducer,
} from 'services/api';
import {
  createAddSearchReducer,
  createDeleteSearchReducer,
  createFetchSearchReducer,
  createUpdateSearchReducer,
} from 'services/search';

import { ReceivingActionTypes } from './actions';
import { transformReceipt } from '../transformations';

const fetchReceivingReducer = createApiReducer(
  [
    ReceivingActionTypes.FETCH_RECEIVING_START,
    ReceivingActionTypes.FETCH_RECEIVING_SUCCESS,
    ReceivingActionTypes.FETCH_RECEIVING_FAILURE,
  ],
  (data) => ({ items: data.results.map(transformReceipt) }),
  (data) => data
);

const receivingChainedReducers = chainReducers(
  INITIAL_API_STATE,
  fetchReceivingReducer
);

const fetchSearchReducer = createFetchSearchReducer('receiving');
const addSearchReducer = createAddSearchReducer('receiving');
const updateSearchReducer = createUpdateSearchReducer('receiving');
const deleteSearchReducer = createDeleteSearchReducer('receiving');

const searchChainedReducers = chainReducers(
  INITIAL_API_STATE,
  fetchSearchReducer,
  addSearchReducer,
  updateSearchReducer,
  deleteSearchReducer
);

export default combineReducers({
  receivings: receivingChainedReducers,
  searches: searchChainedReducers,
});
