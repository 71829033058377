export default function detectHeadlessBrowser() {
  try {
    if (
      /HeadlessChrome/.test(window.navigator?.userAgent ?? '') ||
      window.navigator.webdriver
    ) {
      console.warn(
        'Headless browser detected, using common key for LaunchDarkly context'
      );
      return true;
    }
  } catch (err) {
    console.error(
      'Something went wrong while attempting to detect headless browser',
      err
    );
  }
  return false;
}
