import React, { useCallback, memo, useMemo } from 'react';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import { Location } from 'services/locations';
import { useHandleCheckboxChange } from 'services/forms/hooks';
import { ReportFiltersProps } from '../../types';
import { groupByOptions } from './consts';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { GroupBy } from './types';

const InventoryAvailabilityFilter: React.FC<ReportFiltersProps> = (props) => {
  const { parameters, setParameters } = props;

  const handleCheckboxChange = useHandleCheckboxChange(setParameters);
  const handleLocationChange = useCallback(
    (location: Location | null) => {
      setParameters((old) => ({
        ...old,
        LocationId: location ? location.id : null,
      }));
    },
    [setParameters]
  );

  const handleGroupByChange = useCallback(
    (_e: any, type: GroupBy | null) => {
      setParameters((old) => ({
        ...old,
        GroupBy: type ? type.type : null,
      }));
    },
    [setParameters]
  );

  const selectedGroupBy = useMemo(() => {
    return groupByOptions.find((o) => o.type === parameters.GroupBy) || null;
  }, [parameters]);

  // TODO : RESTYLING : Remove box wrappers around inputs after FF removal

  return (
    <Box px={2} pb={0} pt="16px">
      <Box>
        <LocationsAsyncAutocomplete
          label="Location"
          value={parameters.LocationId}
          placeholder="Select location"
          companyWide={false}
          onChange={handleLocationChange}
          dataQa="inventory-availability-filter-select-location"
        />
      </Box>
      <Box>
        <Autocomplete
          label="Group by"
          options={groupByOptions}
          placeholder="Enter group by"
          getOptionLabel={(i) => i.title}
          onChange={handleGroupByChange}
          value={selectedGroupBy}
          fullWidth
          dataQa="inventory-availability-filter-group-by"
        />
      </Box>
      <FormControlLabel
        className="redesign"
        sx={{ marginBottom: '8px' }}
        control={
          <Checkbox
            className="redesign"
            checked={Boolean(parameters.ShowLocation)}
            onChange={handleCheckboxChange}
            name="ShowLocation"
            color="primary"
            data-qa="inventory-availability-filter-show-location"
          />
        }
        label="Show Location"
      />
      <FormControlLabel
        className="redesign"
        sx={{ marginBottom: '8px' }}
        control={
          <Checkbox
            className="redesign"
            checked={Boolean(parameters.IncludeZeroQuantity)}
            onChange={handleCheckboxChange}
            name="IncludeZeroQuantity"
            color="primary"
            data-qa="inventory-availability-filter-include-zero-quantity"
          />
        }
        label="Include Zero Quantity"
      />
    </Box>
  );
};

export default memo(InventoryAvailabilityFilter);
