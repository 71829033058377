import * as yup from 'yup';

import { PermissionType } from 'services/permissions';

export interface Report {
  id: number;
  title: string;
  reportId: ReportId;
  description: string;
  parameters: ReportParameters;
  validation?: yup.ObjectSchema<any>;
  permission?: PermissionType;
  clearPDFViewerWidth?: boolean;
  version?: number;
}

export interface ReportPreset {
  id: number | null;
  version: number | null;
  reportId: string | null;
  name: string | null;
  parameters: ReportParameters;
  defaultFlag: boolean;
}

export interface ReportParameters {
  [key: string]: any;
}

export interface ReportLayout {
  id: number | null;
  reportName: string | null;
  name: string | null;
  isDefault: boolean;
}

export enum ReportId {
  Items = 'items',
  SalesOrder = 'salesOrder',
  CustomerHistory = 'customerHistory',
  CycleCount = 'cycleCount',
  InventoryValuation = 'inventoryValuation',
  ItemPriceList = 'itemPriceList',
  Pick = 'Pick',
  PurchaseOrder = 'purchaseOrder',
  PackingList = 'packingList',
  ShippingInvoice = 'shippingInvoice',
  ReceivingList = 'receivingList',
  TotalItemSales = 'totalItemSales',
  Reorder = 'reorder',
  VendorFulfillmentHistory = 'vendorFulfillmentHistory',
  PurchaseOrderSummary = 'purchaseOrderSummary',
  SalesOrderSummary = 'salesOrderSummary',
  Barcode = 'barcodeReport',
  OnHandByExpiration = 'onHandByExpiration',
  LocationBarcodeReport = 'locationBarcodeReport',
  InventoryEventHistory = 'inventoryEventHistory',
  ItemBarcodeLabel = 'itemBarcodeLabel',
  ItemBarcodeOneOffReport = 'itemBarcodeOneOffReport',
  InventoryAvailabilityReport = 'inventoryAvailability',
  MarginReport = 'margin',
  ConsumptionReport = 'consumption',
  BOLReport = 'bol',
}

export interface ReportThemeColor {
  label: string;
  name: string;
  backgroundColor: string;
  borderColor: string;
  fontColor: string;
  radioColor: string;
}

export interface ReportTheme {
  id: number | null;
  name: string | null;
  layoutId: number | null;
  color: ReportThemeColor;
  font: string | null;
  fontSize: number | null;
}
export interface ReportFontAndBackgroundColor {
  type: string;
  title: string;
}

export interface ReportFontSize {
  value: number;
  title: string;
}
