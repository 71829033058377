import { OrderPriority } from './types';

export const transformOrderPriority = (response: any): OrderPriority => {
  return {
    id: response.id,
    name: response.name,
    priorityLevel: response.priorityLevel,
    version: response.version,
  };
};
