import * as yup from 'yup';

import {
  PurchaseOrderItem,
  PurchaseOrderItemTypes,
} from 'services/purchaseOrders';

export const yupPurchaseOrderSchema = yup.object().shape({
  vendorId: yup.number().required(),
  locationId: yup.number().required(),
  dateScheduled: yup.date().nullable(true),
});

export const yupNoteRowSchema = yup.object().shape({
  name: yup.string().required(),
});

export const yupPurchaseRowSchema = yup.object().shape({
  unitCost: yup.number().required(),
  quantity: yup.number().required(),
});

export const yupMiscRowSchema = yup.object().shape({
  name: yup.string().required(),
  unitCost: yup.number().required(),
  quantity: yup.number().required(),
});

export const resolvePurchaseRowSchema = (row: PurchaseOrderItem) => {
  switch (row.purchaseOrderItemType) {
    case PurchaseOrderItemTypes.OutSourced:
    case PurchaseOrderItemTypes.Note:
      return yupNoteRowSchema;
    case PurchaseOrderItemTypes.Purchase:
    case PurchaseOrderItemTypes.CreditReturn:
      return yupPurchaseRowSchema;
    case PurchaseOrderItemTypes.DropShip:
      return yupPurchaseRowSchema;
    case PurchaseOrderItemTypes.MiscCredit:
    case PurchaseOrderItemTypes.MiscPurchase:
      return yupMiscRowSchema;
  }
};

export const yupPoEmailModalSchema = yup.object().shape({
  to: yup.string().required(),
  subject: yup.string().required(),
  cc: yup
    .string()
    .test('different', "Cc and Bcc can't be same", function (value) {
      if (!value || !this.parent.bcc) {
        return true;
      }
      const trimmedCc = value && value.trim();
      const trimmedBcc = this.parent.bcc.trim();
      return !!trimmedCc ? trimmedCc !== trimmedBcc : true;
    })
    .nullable(true),
  body: yup
    .mixed()
    .test('email', 'Body is required', function (value) {
      if (!value || value === '<p><br></p>') {
        return false;
      }
      return true;
    })
    .required('Body is required'),
});
