import { createFilterOptions, FilterOptionsState } from '@mui/material';

import {
  initialLocation,
  Location,
  fetchLocationsByItemAPI,
} from 'services/locations';
import { Pagination } from 'services/search';

const filter = createFilterOptions<Location>();

export const locationsAutocompleteOptionLabel =
  (getLocationLabel?: (l: Location) => string) => (location: Location) => {
    if (!location.id || location.id < 0) {
      return `+ Add "${location.name}"`;
    }
    if (getLocationLabel) {
      return getLocationLabel(location);
    }
    return location.name ?? '';
  };

export const locationsAutocompleteFilterOptions =
  (disableAdd: boolean) =>
  (options: Location[], params: FilterOptionsState<Location>) => {
    const filtered = filter(options, params);

    if (disableAdd) {
      return filtered;
    }

    if (params.inputValue) {
      filtered.unshift({
        ...initialLocation,
        name: params.inputValue || null,
      });
    }

    return filtered;
  };

export const resolveItemLocation = (itemId: number) => {
  return (pagination: Pagination, searchValue: string | null) =>
    fetchLocationsByItemAPI(
      {
        pagination: pagination,
        quickSearchValue: searchValue,
      },
      itemId
    );
};
