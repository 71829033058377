import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { DateRangePickerProps } from './types';
import { colorPalette } from 'ui/theme';

export const useDateRangePickerStyle = makeStyles<Theme, DateRangePickerProps>(
  (theme: Theme) => ({
    link: {
      fontSize: 5,
      opacity: (props) => (props.disabled ? 0.6 : 1),
    },
    box: {
      position: 'relative',
    },
    textfield: {
      fontSize: 13.5,
    },
    datePickerWrapper: {
      visibility: 'hidden',
      opacity: '0',
      transition: 'opacity .2s ease-in-out',
    },
    datePicker: {
      position: 'relative',
      backgroundColor: 'pink',
      '& *': {
        boxShadow: 'none',
      },
      '& [class*="materialui-daterange-picker-makeStyles-filled-"]': {
        backgroundColor: '#0029f0',
      },
      '& [class*="materialui-daterange-picker-makeStyles-filled-"]:hover': {
        backgroundColor: '#0029f0',
      },
      '& [class*="materialui-daterange-picker-makeStyles-outlined-"]': {
        border: '1px solid #0029f0',
      },
    },
    datePickerContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    datePickerFooter: {
      padding: theme.spacing(1),
      display: 'flex',
      background: colorPalette.white,
    },
    modalClosed: {
      '& .footer': {
        display: 'none',
      },
    },
    modalOpen: {
      opacity: 1,
      visibility: 'visible',
      top: 0,
      left: 0,
      right: 0,
      width: '100vw',
      bottom: 0,
      height: '100vh',
      zIndex: 20000,
      position: 'fixed',
      background: 'red',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0,0,0,0.4)',
    },
  })
);
