import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable/types';

export const SUBSTITUTE_ITEMS_COLUMNS: Column[] = [
  {
    title: 'Name',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'UOM',
    type: ColumnTypes.renderByCustomRow,
    width: 100,
  },
  {
    title: 'W/H/L (Weight)',
    type: ColumnTypes.renderByCustomRow,
    width: 100,
  },
  {
    title: 'Price',
    type: ColumnTypes.renderByCustomRow,
    width: 100,
  },
  {
    title: '',
    width: 64,
    type: ColumnTypes.renderByCustomRow,
  },
];
