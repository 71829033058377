import React, { memo, useState, useEffect, useMemo } from 'react';
import { Box, TableCell, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { IconNames } from 'ui/theme';
import { TextField } from 'ui/components/TextField/TextField';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { SalesOrderItem, SalesOrderStatus } from 'services/salesOrders';
import { stopInputPropagation } from 'helpers';
import { Modal } from 'ui/components/Modal/Modal';
import { getSettingsCompany } from 'services/settings/company';

import { MenuTableCell } from '../MenuTableCell';
import { editSalesOrderPermissions } from '../../../../helpers';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const NoteRow: React.FC<RenderCustomRowProps<SalesOrderItem>> = (props) => {
  const {
    row,
    index,
    setData,
    errors,
    meta: { salesOrder },
  } = props;

  const [note, setNote] = useState<string | null>(null);
  const [editNoteModalVisible, setEditNoteModalVisible] =
    useState<boolean>(false);

  const { homeCurrency, useMultiCurrency } = useSelector(getSettingsCompany);

  const homeCurrencyCode = (homeCurrency && homeCurrency.code) || 'USD';
  const activeMulticurrencyCode = _.get(
    salesOrder,
    'customer.currency.code',
    null
  );

  const showMultiCurrency = useMemo(
    () =>
      useMultiCurrency &&
      activeMulticurrencyCode &&
      activeMulticurrencyCode !== homeCurrencyCode,
    [useMultiCurrency, activeMulticurrencyCode]
  );

  const fieldsDisabled =
    salesOrder.status === SalesOrderStatus.Fulfilled ||
    salesOrder.status === SalesOrderStatus.PartiallyFulfilled ||
    salesOrder.status === SalesOrderStatus.PartiallyPicked ||
    salesOrder.status === SalesOrderStatus.Cancelled;

  const editPermission = editSalesOrderPermissions(salesOrder);

  useEffect(() => {
    setNote(row.name);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editNoteModalVisible]);

  const handleTextInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value ? e.target.value : null;

    setData({ ...row, name: value }, index);
  };

  const handleEditNoteInputChanged = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value ? e.target.value : null;

    setNote(value);
  };

  const onApplyClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setData({ ...row, name: note }, index);
    setEditNoteModalVisible(false);
  };

  return (
    <>
      <TableCell colSpan={showMultiCurrency ? 9 : 7}>
        <Grid container alignItems="center">
          <Grid item>
            <Box
              marginRight={2}
              width={40}
              height={40}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <FBOButton
                variant="tertiary"
                color="neutral"
                size="large"
                icon={IconNames.IconEdit}
                onClick={() => setEditNoteModalVisible(true)}
                data-qa="note-row-edit-button"
              />
            </Box>
          </Grid>
          <Grid item xs>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Notes"
              placeholder="Enter notes"
              name="name"
              autoComplete="off"
              value={row.name}
              disabled={fieldsDisabled}
              permissions={editPermission}
              onClick={stopInputPropagation}
              onChange={handleTextInputChanged}
              error={!!errors.name}
              dataQa="note-row"
            />
          </Grid>
        </Grid>
      </TableCell>

      <MenuTableCell {...props} />

      <Modal
        applyDisabled={fieldsDisabled}
        applyLabel="Update"
        onApplyClicked={onApplyClicked}
        onCancelClicked={() => setEditNoteModalVisible(false)}
        open={editNoteModalVisible}
        title="Edit Note"
        withoutDefaultPadding
      >
        <Box p={3} width="100%">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                className="redesign"
                variant="standard"
                disabled={fieldsDisabled}
                label="Notes"
                multiline
                name="notes"
                onChange={handleEditNoteInputChanged}
                placeholder="Enter notes"
                type="text"
                value={note}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default memo(NoteRow);
