import React, { memo, useCallback } from 'react';
import { PermissionType } from 'services/permissions';
import { PaymentTermTitleBarProps } from './types';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const PaymenTermTitleBar: React.FC<PaymentTermTitleBarProps> = (props) => {
  const {
    activePaymentTerm,
    onSave,
    onClose,
    onUndeleteClicked,
    deleteModalVisible,
  } = props;

  const DeletedPaymenTermActionsRestyle = useCallback(() => {
    return (
      <>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="payment-term-undelete"
          onClick={() => onUndeleteClicked(false)}
          permissions={
            activePaymentTerm.id && activePaymentTerm.id > 0
              ? [PermissionType.PaymentTermsEdit]
              : [PermissionType.PaymentTermsCreate]
          }
        >
          Undelete
        </FBOButton>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="payment-term-delete-and-close"
          onClick={() => onUndeleteClicked(true)}
          permissions={
            activePaymentTerm.id && activePaymentTerm.id > 0
              ? [PermissionType.PaymentTermsEdit]
              : [PermissionType.PaymentTermsCreate]
          }
        >
          Delete And Close
        </FBOButton>
      </>
    );
  }, [onUndeleteClicked, activePaymentTerm.id]);

  const PaymenTermActionsRestyle = useCallback(() => {
    return (
      <>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="payment-term-save"
          onClick={() => onSave(false)}
          permissions={
            activePaymentTerm.id && activePaymentTerm.id > 0
              ? [PermissionType.PaymentTermsEdit]
              : [PermissionType.PaymentTermsCreate]
          }
        >
          Save
        </FBOButton>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="payment-term-save"
          onClick={async () => {
            await onSave(true);
            onClose();
          }}
          permissions={
            activePaymentTerm.id && activePaymentTerm.id > 0
              ? [PermissionType.PaymentTermsEdit]
              : [PermissionType.PaymentTermsCreate]
          }
        >
          Save And Close
        </FBOButton>
        {activePaymentTerm.id && activePaymentTerm.id > 0 && (
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="secondary"
            color="negative"
            size="medium"
            icon="TrashCan"
            data-qa="payment-term-delete"
            onClick={() => deleteModalVisible(true)}
            permissions={[PermissionType.PaymentTermsDelete]}
          >
            Delete
          </FBOButton>
        )}
      </>
    );
  }, [activePaymentTerm.id, deleteModalVisible, onSave]);

  return (
    <FBOTitleBar title={`${activePaymentTerm.name || 'New Payment Term'}`}>
      {activePaymentTerm.deleted ? (
        <DeletedPaymenTermActionsRestyle />
      ) : (
        <PaymenTermActionsRestyle />
      )}
      <FBOButton
        variant="tertiary"
        color="neutral"
        size="medium"
        icon="FBOClose"
        data-qa="payment-term-close"
        onClick={onClose}
      />
    </FBOTitleBar>
  );
};

export default memo(PaymenTermTitleBar);
