import { Address, initialAddress } from 'services/addresses';
import { PurchaseOrder, PurchaseOrderItemTypes } from 'services/purchaseOrders';
import { Vendor } from 'services/vendors';

import { roundToDecimals, getExchangeRate } from 'helpers';

export const calculatePoVendorFields = (
  po: PurchaseOrder,
  vendor: Vendor | null
) => {
  if (!vendor) {
    return {
      ...po,
      exchangeRate: 1,
      vendor: null,
      vendorId: null,
    };
  }

  const btAdr = vendor.addresses.find((a) => a.defaultBillTo);
  const stAdr = vendor.addresses.find((a) => a.defaultShipTo);

  const newExchangeRate = getExchangeRate(vendor) || po.exchangeRate;

  const isCreditReturn =
    po.purchaseOrderItemList.length > 0 &&
    po.purchaseOrderItemList.every(
      (s) => s.purchaseOrderItemType === PurchaseOrderItemTypes.CreditReturn
    );

  const newPurchaseOrder = {
    ...po,
    exchangeRate: newExchangeRate,
    purchaseOrderItemList: po.purchaseOrderItemList.map((i) => ({
      ...i,
      exchangeRate: newExchangeRate,
      multiCurrencyUnitCost: roundToDecimals(
        (i.unitCost || 0) * (newExchangeRate || 1),
        5
      ),
      multiCurrencyTotalCost: roundToDecimals(
        (i.totalCost || 0) * (newExchangeRate || 1),
        5
      ),
    })),
    exchangeCurrencyId: vendor.currency
      ? vendor.currencyId
      : po.exchangeCurrencyId,
    accountingClassId: vendor.accountingClassId
      ? vendor.accountingClassId
      : null,
    vendorId: vendor ? vendor.id : null,
    vendor,
    carrierId: vendor ? vendor.defaultCarrierId : po.carrierId,
    carrierServiceId: vendor
      ? vendor.defaultCarrierServiceId
      : po.carrierServiceId,
    vendorAddressName: btAdr ? btAdr.name : null,
    vendorAddressStreet: btAdr ? btAdr.street : null,
    vendorAddressCity: btAdr ? btAdr.city : null,
    vendorAddressState: btAdr ? btAdr.state : null,
    vendorAddressCountry: btAdr ? btAdr.country : null,
    vendorAddressPostalCode: btAdr ? btAdr.postalCode : null,
    vendorCompanyName: btAdr ? btAdr.companyName : null,
    vendorAttention: btAdr ? btAdr.attention : null,
    vendorResidential: btAdr ? btAdr.residential : false,
    email: vendor ? vendor.email : null,
    phone: vendor ? vendor.office : null,
    paymentTermId: vendor ? vendor.paymentTermId : null,
  };

  if (isCreditReturn) {
    return {
      ...newPurchaseOrder,
      shipToName: stAdr ? stAdr.name : null,
      shipToStreet: stAdr ? stAdr.street : null,
      shipToStreet2: stAdr ? stAdr.street2 : null,
      shipToCity: stAdr ? stAdr.city : null,
      shipToState: stAdr ? stAdr.state : null,
      shipToCountry: stAdr ? stAdr.country : null,
      shipToPostalCode: stAdr ? stAdr.postalCode : null,
      shipToCompanyName: stAdr ? stAdr.companyName : null,
      shipToResidential: stAdr ? stAdr.residential : false,
      shipToAttention: stAdr ? stAdr.attention : null,
    };
  }

  return newPurchaseOrder;
};

export const transformShipToAddress = (po: PurchaseOrder): Address => {
  return {
    ...initialAddress,
    name: po.shipToName,
    country: po.shipToCountry,
    companyName: po.shipToCompanyName,
    postalCode: po.shipToPostalCode,
    residential: po.shipToResidential,
    city: po.shipToCity,
    street: po.shipToStreet,
    street2: po.shipToStreet2,
    state: po.shipToState,
  };
};
