import React from 'react';
import { Route, Routes } from 'react-router-dom';

import SecureRoute from 'ui/components/Route/SecureRoute';

import { Routes as AccountingRoutes } from './routes';
import { LedgersPage, AccountingSettingsPage, ClassesPage } from '../pages';
import { AccountingRouterCmp, AccountingRouterProps } from './types';
import { PermissionType } from 'services/permissions';
import { InvoicesPage } from '../pages/InvoicesPage';

const AccountingRouter: AccountingRouterCmp = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: AccountingRouterProps
) => {
  return (
    <Routes>
      <Route
        element={<SecureRoute permissions={[PermissionType.AccountingView]} />}
      >
        <Route path={LedgersPage.route} element={<LedgersPage />} />
        <Route path={ClassesPage.route} element={<ClassesPage />} />
        <Route path={InvoicesPage.route} element={<InvoicesPage />} />
        <Route
          path={AccountingSettingsPage.route}
          element={<AccountingSettingsPage />}
        />
      </Route>
    </Routes>
  );
};

AccountingRouter.route = AccountingRoutes.AccountingRouter;

export default AccountingRouter;
