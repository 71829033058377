import { createApiAction } from 'services/api';
import {
  StartAction,
  SuccessAction,
  FailureAction,
} from 'services/api/redux/types';

export enum OrderPrioritiesActionTypes {
  FETCH_PRIORITY_TYPES_START = 'priorities/fetch_priority_types_start',
  FETCH_PRIORITY_TYPES_SUCCESS = 'priorities/fetch_priority_types_success',
  FETCH_PRIORITY_TYPES_FAILURE = 'priorities/fetch_priority_types_failure',
}

/**
 * ORDER PRIORITIES
 */
const fetchPriorityTypesActionStart = (): StartAction => ({
  type: OrderPrioritiesActionTypes.FETCH_PRIORITY_TYPES_START,
  payload: null,
});

const fetchOrderPrioritiesActionSuccess = (payload: any): SuccessAction => {
  return {
    type: OrderPrioritiesActionTypes.FETCH_PRIORITY_TYPES_SUCCESS,
    payload,
  };
};

const fetchOrderPrioritiesActionFailure = (error: string): FailureAction => ({
  type: OrderPrioritiesActionTypes.FETCH_PRIORITY_TYPES_FAILURE,
  payload: { error },
});

export const fetchOrderPriorities = () =>
  createApiAction(
    {
      path: '/v1/order_priorities',
      method: 'GET',
    },
    fetchPriorityTypesActionStart,
    fetchOrderPrioritiesActionSuccess,
    fetchOrderPrioritiesActionFailure
  );
