import { createApiAction } from 'services/api';
import {
  StartAction,
  SuccessAction,
  FailureAction,
} from 'services/api/redux/types';
import {
  createAddSearch,
  createDeleteSearch,
  createFetchSearch,
  createUpdateSearch,
} from 'services/search';

export enum PaymentTermsActionTypes {
  FETCH_PAYMENT_TERMS_START = 'paymentTerms/fetch_payment_terms_start',
  FETCH_PAYMENT_TERMS_SUCCESS = 'paymentTerms/fetch_payment_terms_success',
  FETCH_PAYMENT_TERMS_FAILURE = 'paymentTerms/fetch_payment_terms_failure',
}

const fetchPaymentTermsActionStart = (): StartAction => ({
  type: PaymentTermsActionTypes.FETCH_PAYMENT_TERMS_START,
  payload: null,
});

const fetchPaymentTermsActionSuccess = (payload: any): SuccessAction => {
  return {
    type: PaymentTermsActionTypes.FETCH_PAYMENT_TERMS_SUCCESS,
    payload,
  };
};

const fetchPaymentTermsActionFailure = (error: string): FailureAction => ({
  type: PaymentTermsActionTypes.FETCH_PAYMENT_TERMS_FAILURE,
  payload: { error },
});

export const fetchPaymentTerms = () =>
  createApiAction(
    {
      path: '/v1/payment_terms?pageSize=100',
      method: 'GET',
    },
    fetchPaymentTermsActionStart,
    fetchPaymentTermsActionSuccess,
    fetchPaymentTermsActionFailure
  );

export const fetchSearch = createFetchSearch('paymentTerms');
export const addSearch = createAddSearch('paymentTerms');
export const updateSearch = createUpdateSearch('paymentTerms');
export const deleteSearch = createDeleteSearch('paymentTerms');
