import { createApiAction } from 'services/api';
import {
  StartAction,
  SuccessAction,
  FailureAction,
} from 'services/api/redux/types';
import {
  createAddSearch,
  createDeleteSearch,
  createFetchSearch,
  createUpdateSearch,
} from 'services/search';

export enum UomsActionTypes {
  FETCH_UOMS_START = 'uoms/fetch_uoms_start',
  FETCH_UOMS_SUCCESS = 'uoms/fetch_uoms_success',
  FETCH_UOMS_FAILURE = 'uoms/fetch_uoms_failure',
}

// UOMS ACTIONS
const fetchUomsActionStart = (): StartAction => ({
  type: UomsActionTypes.FETCH_UOMS_START,
  payload: null,
});

const fetchUomsActionSuccess = (payload: any): SuccessAction => {
  return {
    type: UomsActionTypes.FETCH_UOMS_SUCCESS,
    payload,
  };
};

const fetchUomsActionFailure = (error: string): FailureAction => ({
  type: UomsActionTypes.FETCH_UOMS_FAILURE,
  payload: { error },
});

export const fetchUoms = () =>
  createApiAction(
    {
      path: '/v1/uoms?expand=toConversions,fromConversions&pageNumber=1&pageSize=100',
      method: 'GET',
    },
    fetchUomsActionStart,
    fetchUomsActionSuccess,
    fetchUomsActionFailure
  );

// SEARCH ACTION CREATORS

export const fetchSearch = createFetchSearch('uom');
export const addSearch = createAddSearch('uom');
export const updateSearch = createUpdateSearch('uom');
export const deleteSearch = createDeleteSearch('uom');
