import React, { memo } from 'react';

import { ImageStorageType } from 'services/images';
import { SalesOrderStatus } from 'services/salesOrders';
import { DocumentsTable } from 'ui/components/Documents/DocumentsTable';

import { editSalesOrderPermissions } from '../../helpers';
import { SalesOrderDocumentsTabProps } from './types';

const SalesOrderDocumentsTab: React.FC<SalesOrderDocumentsTabProps> = (
  props
) => {
  const { salesOrder, setSalesOrder } = props;

  const editPermission = editSalesOrderPermissions(salesOrder);

  return (
    <DocumentsTable
      data={salesOrder}
      setData={setSalesOrder}
      storageType={ImageStorageType.SalesOrderDocuments}
      disabled={
        salesOrder.status === SalesOrderStatus.Fulfilled ||
        salesOrder.status === SalesOrderStatus.Cancelled
      }
      permissions={editPermission}
    />
  );
};

export default memo(SalesOrderDocumentsTab);
