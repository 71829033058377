import _ from 'lodash';
import { Item, ItemType } from 'services/items';
import { DefaultSaleItemType, SaleItem } from 'services/items/saleItems';

import { ITEM_VALUES } from '../../../../consts';

export const filterDefaultSaleItemTypes = (item: Item) => {
  if (
    item.itemType === ItemType.Inventory ||
    item.itemType === ItemType.NonInventory
  ) {
    return Object.values(DefaultSaleItemType);
  }
  return [DefaultSaleItemType.Sale, DefaultSaleItemType.CreditReturn];
};

export const shouldShowSyncValuesModal = (item: Item, saleItem: SaleItem) => {
  if (!saleItem.defaultFlag) {
    return true;
  }

  const pickedDefaultSaleItemValues = _.pick(saleItem, ITEM_VALUES);
  const pickedItemValues = _.pick(item, ITEM_VALUES);

  const itemAndSaleItemPickedValuesDiff =
    _.isEqual(pickedDefaultSaleItemValues, pickedItemValues) && !!saleItem;

  return itemAndSaleItemPickedValuesDiff;
};
