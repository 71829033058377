import { useCallback } from 'react';
import { Dispatch, ActionCreator } from 'redux';

import {
  INITIAL_ADVANCED_SEARCH,
  FetchSearchResultFunc,
  FetchOptions,
} from 'ui/components/Page/WithSearchResults';
import { Column } from 'ui/components/Table/ItemsTable/types';

import { AdvancedSearchItem, Pagination } from './types';

export const useSaveAdvancedSearch = (
  dispatch: Dispatch<any>,
  addSearch: ActionCreator<any>,
  updateSearch: ActionCreator<any>,
  fetchSearchResult: FetchSearchResultFunc
) =>
  useCallback(
    async (newSearch: AdvancedSearchItem) => {
      let searchId = newSearch.id;
      if (newSearch.id < 0) {
        const { ...newSearchBody } = newSearch;
        const actionPayload = await dispatch(addSearch(newSearchBody));

        if (!actionPayload.ok) {
          return;
        }
        searchId = actionPayload.res.payload.id;
      } else {
        await dispatch(updateSearch(newSearch));
      }

      if (!newSearch.isDefault) {
        fetchSearchResult({ advancedSearch: INITIAL_ADVANCED_SEARCH });
        return;
      }

      fetchSearchResult({ advancedSearch: { ...newSearch, id: searchId } });
    },
    [addSearch, dispatch, fetchSearchResult, updateSearch]
  );

export const useChangeActiveAdvancedSearch = (
  savedAdvancedSearches: AdvancedSearchItem[],
  fetchSearchResult: FetchSearchResultFunc,
  detailsCardColumns: Column[],
  pagination?: Pagination
) =>
  useCallback(
    (id: number) => {
      if (id < 0) {
        fetchSearchResult({
          advancedSearch: INITIAL_ADVANCED_SEARCH,
          pagination: pagination ? { ...pagination, page: 1 } : undefined,
        });
        return;
      }

      const savedSearch = savedAdvancedSearches.find((c) => c.id === id);
      fetchSearchResult({
        advancedSearch: savedSearch,
        pagination: pagination ? { ...pagination, page: 1 } : undefined,
        quickSearch: { value: '', columns: detailsCardColumns },
      });
    },
    [savedAdvancedSearches, fetchSearchResult, pagination]
  );

export const useChangeQuickSearch = (
  fetchSearchResult: FetchSearchResultFunc,
  detailsCardColumns: Column[],
  pagination?: Pagination
) =>
  useCallback(
    (value: string) => {
      const newSearch: FetchOptions = {
        quickSearch: { value, columns: detailsCardColumns },
        pagination: pagination ? { ...pagination, page: 1 } : undefined,
      };
      fetchSearchResult(newSearch);
    },
    [detailsCardColumns, fetchSearchResult, pagination]
  );

export const useDeleteAdvancedSearch = (
  dispatch: Dispatch,
  deleteSearch: ActionCreator<any>,
  fetchSearchResult: FetchSearchResultFunc
) =>
  useCallback(
    async (id: number) => {
      await dispatch(deleteSearch(id));
      fetchSearchResult({ advancedSearch: INITIAL_ADVANCED_SEARCH });
    },
    [deleteSearch, dispatch, fetchSearchResult]
  );

export const useApplyAdvancedSearch = (
  activeAdvancedSearch: AdvancedSearchItem,
  fetchSearchResult: FetchSearchResultFunc,
  pagination?: Pagination
) =>
  useCallback(
    (columns: any) => {
      const newSearch = {
        ...activeAdvancedSearch,
        columns,
      };
      fetchSearchResult({
        advancedSearch: newSearch,
        pagination: pagination ? { ...pagination, page: 1 } : undefined,
      });
    },
    [activeAdvancedSearch, fetchSearchResult, pagination]
  );
