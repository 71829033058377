import { createApiAction } from 'services/api';
import {
  StartAction,
  SuccessAction,
  FailureAction,
} from 'services/api/redux/types';
import { InternationalTaxRate } from 'services/integrations/xero';

export enum XeroAccountActionTypes {
  FETCH_XERO_ACCOUNT_START = 'xero/fetch_xero_account_start',
  FETCH_XERO_ACCOUNT_SUCCESS = 'xero/fetch_xero_account_success',
  FETCH_XERO_ACCOUNT_FAILURE = 'xero/fetch_xero_account_failure',
  XERO_DISCONNECT_START = 'xero/xero_disconnect_start',
  XERO_DISCONNECT_SUCCESS = 'xero/xero_disconnect_success',
  XERO_DISCONNECT_FAILURE = 'xero/xero_disconnect_failure',
  XERO_TAX_RATES_START = 'xero/xero_tax_rates_start',
  XERO_TAX_RATES_SUCCESS = 'xero/xero_tax_rates_success',
  XERO_TAX_RATES_FAILURE = 'xero/xero_tax_rates_failure',
}

const fetchXeroAccountActionStart = (): StartAction => ({
  type: XeroAccountActionTypes.FETCH_XERO_ACCOUNT_START,
  payload: null,
});

const fetchXeroAccountActionSuccess = (payload: any): SuccessAction => ({
  type: XeroAccountActionTypes.FETCH_XERO_ACCOUNT_SUCCESS,
  payload,
});

const fetchXeroAccountActionFailure = (error: string): FailureAction => ({
  type: XeroAccountActionTypes.FETCH_XERO_ACCOUNT_FAILURE,
  payload: { error },
});

const xeroDisconnectActionStart = (): StartAction => ({
  type: XeroAccountActionTypes.XERO_DISCONNECT_START,
  payload: null,
});

const xeroDisconnectActionSuccess = (): SuccessAction => ({
  type: XeroAccountActionTypes.XERO_DISCONNECT_SUCCESS,
  payload: null,
});

const xeroDisconnectActionFailure = (error: string): FailureAction => ({
  type: XeroAccountActionTypes.XERO_DISCONNECT_FAILURE,
  payload: { error },
});

export const fetchXeroActiveAccount = () =>
  createApiAction(
    {
      path: '/integrations/xero/accounts',
      method: 'GET',
    },
    fetchXeroAccountActionStart,
    fetchXeroAccountActionSuccess,
    fetchXeroAccountActionFailure
  );

export const xeroDisconnect = (clearMappings: boolean = false) =>
  createApiAction(
    {
      path: `/integrations/xero/disconnect?clearMappings=${clearMappings}`,
      method: 'GET',
    },
    xeroDisconnectActionStart,
    xeroDisconnectActionSuccess,
    xeroDisconnectActionFailure
  );

// Xero Tax Rates
const xeroTaxRatesActionStart = (): StartAction => ({
  type: XeroAccountActionTypes.XERO_TAX_RATES_START,
  payload: null,
});

export const xeroTaxRatesActionSuccess = (
  payload: InternationalTaxRate[]
): SuccessAction => ({
  type: XeroAccountActionTypes.XERO_TAX_RATES_SUCCESS,
  payload,
});

const xeroTaxRatesActionFailure = (error: string): FailureAction => ({
  type: XeroAccountActionTypes.XERO_TAX_RATES_FAILURE,
  payload: { error },
});

export const fetchXeroTaxRates = () =>
  createApiAction(
    { path: '/v2/integrations/xero/taxes', method: 'GET' },
    xeroTaxRatesActionStart,
    xeroTaxRatesActionSuccess,
    xeroTaxRatesActionFailure
  );
