import React, { memo, useCallback } from 'react';

import { PermissionType } from 'services/permissions';
import { UomTitleBarProps } from './types';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const UomTitleBar: React.FC<UomTitleBarProps> = (props) => {
  const { activeUom, onSave, onClose, deleteModalVisible, onUndeleteClicked } =
    props;

  const DeletedUomActionsRestyle = useCallback(() => {
    return (
      <>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="uom-undelete"
          onClick={() => onUndeleteClicked(false)}
          permissions={
            activeUom.id && activeUom.id > 0
              ? [PermissionType.UomEdit]
              : [PermissionType.UomCreate]
          }
        >
          Undelete
        </FBOButton>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="undelete-and-close"
          onClick={() => onUndeleteClicked(true)}
          permissions={
            activeUom.id && activeUom.id > 0
              ? [PermissionType.UomEdit]
              : [PermissionType.UomCreate]
          }
        >
          Undelete And Close
        </FBOButton>
      </>
    );
  }, [onUndeleteClicked, activeUom.id]);

  const UomActionsRestyle = useCallback(() => {
    return (
      <>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="uom-save"
          onClick={() => onSave(false)}
          permissions={
            activeUom.id && activeUom.id > 0
              ? [PermissionType.UomEdit]
              : [PermissionType.UomCreate]
          }
        >
          Save
        </FBOButton>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="uom-save-and-close"
          onClick={async () => {
            await onSave(true);
            onClose();
          }}
          permissions={
            activeUom.id && activeUom.id > 0
              ? [PermissionType.UomEdit]
              : [PermissionType.UomCreate]
          }
        >
          Save And Close
        </FBOButton>
        {activeUom.id && activeUom.id > 0 && (
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="secondary"
            color="negative"
            size="medium"
            icon="TrashCan"
            data-qa="uom-delete"
            onClick={() => deleteModalVisible(true)}
            permissions={[PermissionType.UomDelete]}
          >
            Delete
          </FBOButton>
        )}
      </>
    );
  }, [activeUom.id, deleteModalVisible, onSave]);

  return (
    <FBOTitleBar title={`${activeUom.name || 'New UOM'}`}>
      {activeUom.deleted ? <DeletedUomActionsRestyle /> : <UomActionsRestyle />}
      <FBOButton
        variant="tertiary"
        color="neutral"
        size="medium"
        icon="FBOClose"
        data-qa="uom-close"
        onClick={onClose}
      />
    </FBOTitleBar>
  );
};

export default memo(UomTitleBar);
