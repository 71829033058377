import { State } from 'app/redux';
import { createApiReducer } from 'services/api';

import { searchActionTypes } from './actions';
import { AdvancedSearchItem } from '../types';

/**
 * Transforms search item received from server to our format
 * @param response
 */
export const transformSearch = (serverSearchItem: any): AdvancedSearchItem => {
  return {
    id: serverSearchItem.id,
    columns: serverSearchItem.columns,
    searchName: serverSearchItem.searchName,
    isDefault: serverSearchItem.isDefault,
    quickSearchValue: serverSearchItem.quickSearchValue,
  };
};

export const createFetchSearchReducer = (module: keyof State) =>
  createApiReducer(
    [
      searchActionTypes.getFetchStart(module),
      searchActionTypes.getFetchSuccess(module),
      searchActionTypes.getFetchFailure(module),
    ],
    (data) => ({ items: data.map(transformSearch) }),
    (data) => data
  );

export const createAddSearchReducer = (module: keyof State) =>
  createApiReducer(
    [
      searchActionTypes.getSearchStart(module),
      searchActionTypes.getSearchSuccess(module),
      searchActionTypes.getSearchFailure(module),
    ],
    (data, state) => {
      const newItem = transformSearch(data);
      if (newItem.isDefault) {
        return {
          ...state,
          items: [
            ...state.items.map((i: any) => ({ ...i, isDefault: false })),
            newItem,
          ],
        };
      }
      return {
        ...state,
        items: [...state.items, newItem],
      };
    },
    (data) => data
  );

export const createUpdateSearchReducer = (module: keyof State) =>
  createApiReducer(
    [
      searchActionTypes.getUpdateStart(module),
      searchActionTypes.getUpdateSuccess(module),
      searchActionTypes.getUpdateFailure(module),
    ],
    (data, state) => {
      const newItem = transformSearch(data);
      if (newItem.isDefault) {
        return {
          ...state,
          items: state.items.map((i: any) =>
            i.id === data.id ? newItem : { ...i, isDefault: false }
          ),
        };
      }
      return {
        ...state,
        items: state.items.map((i: any) => (i.id === data.id ? newItem : i)),
      };
    },
    (data) => data
  );

export const createDeleteSearchReducer = (module: keyof State) =>
  createApiReducer(
    [
      searchActionTypes.getDeleteStart(module),
      searchActionTypes.getDeleteSuccess(module),
      searchActionTypes.getDeleteFailure(module),
    ],
    (data, state) => ({
      ...state,
      items: state.items.filter((i: any) => i.id !== data.id),
    }),
    (data) => data
  );
