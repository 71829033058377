import { Carrier } from 'services/carriers';
import { PermissionType } from 'services/permissions';

export const editCarrierPermissions = (carrier: Carrier): PermissionType[] => {
  if (carrier.deleted) {
    return [PermissionType.Undelete];
  }
  return carrier.id && carrier.id > 0
    ? [PermissionType.CarriersEdit]
    : [PermissionType.CarriersCreate];
};
