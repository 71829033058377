import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { Grid } from '@mui/material';
import * as yup from 'yup';

import { Autocomplete } from '../Autocomplete';
import { UomAutocompleteProps } from './types';
import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import { useHandleTextFieldChange } from 'services/forms';
import { Errors, validateYup } from 'services/forms/validation';
import { defaultUom, fetchUoms, getUoms, postUom, Uom } from 'services/uoms';
import { activeUserHasPermission } from 'services/user/redux';

const filter = createFilterOptions<Uom>();

const yupSchema = yup.object().shape({
  name: yup.string(),
  abbreviation: yup.string(),
  description: yup.string(),
});

const UomAutocomplete: React.FC<UomAutocompleteProps> = (props) => {
  const {
    onChange,
    permissions = [],
    disabled,
    reduceOptions,
    value,
    ...otherProps
  } = props;

  const dispatch = useDispatch();

  const canEdit = useSelector(activeUserHasPermission(permissions));

  const isDisabled = disabled || !canEdit;

  const { items: uoms } = useSelector(getUoms);

  const [showModal, setShowModal] = useState(false);
  const [formUom, setFormUom] = useState<Uom>(defaultUom);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Errors>({});

  const handleChange = (e: any, uom: Uom | null) => {
    if (uom && (uom.id === null || uom.id < 0)) {
      setFormUom((old) => ({ ...old, name: uom.name }));
      setErrors({});
      setShowModal(true);
      return;
    }

    onChange(uom);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFormUom(defaultUom);
  };

  const handleApplyModal = async () => {
    if (!validateYup(formUom, yupSchema, setErrors)) {
      return;
    }

    setIsLoading(true);

    try {
      const newUom = await postUom(formUom);
      // Lint skip to be removed
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(fetchUoms());
      onChange(newUom);
    } catch {
      setIsLoading(false);
      return;
    }

    setShowModal(false);
    setIsLoading(false);
    setFormUom(defaultUom);
  };

  const handleTextFieldChange = useHandleTextFieldChange(setFormUom, formUom);

  return (
    <>
      <Autocomplete
        options={reduceOptions ? reduceOptions(uoms) : uoms}
        disabled={isDisabled}
        getOptionLabel={(uom: Uom) => {
          if (!uom.id || uom.id < 0) {
            return `+ Add "${uom.name}"`;
          }

          return uom.name || '';
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (!!params.inputValue) {
            filtered.unshift({
              ...defaultUom,
              name: params.inputValue || null,
            });
          }

          return filtered;
        }}
        onChange={handleChange}
        autoSelect={false}
        value={value}
        {...otherProps}
      />
      <Modal
        open={showModal}
        title="New UOM"
        onCancelClicked={handleCloseModal}
        onApplyClicked={handleApplyModal}
        isLoadingContent={isLoading}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              className="redesign"
              variant="standard"
              name="name"
              label="Name"
              value={formUom.name}
              onChange={handleTextFieldChange}
              required
              error={!!errors.name}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              className="redesign"
              variant="standard"
              name="abbreviation"
              label="Abbreviation"
              value={formUom.abbreviation}
              onChange={handleTextFieldChange}
              required
              error={!!errors.abbreviation}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="redesign"
              variant="standard"
              name="description"
              label="Description"
              value={formUom.description}
              onChange={handleTextFieldChange}
              required
              error={!!errors.description}
            />
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default memo(UomAutocomplete);
