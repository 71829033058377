import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const TrackingWizardColumns: Column[] = [
  {
    title: 'Location',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Quantity',
    type: ColumnTypes.renderByCustomRow,
  },
];

export const TrackingWizardTableHeight = 500;
