import _ from 'lodash';

import {
  Address,
  transformValidAddress,
  transformValidAddressRequest,
  AddressValidation,
} from 'services/addresses';
import { showNotification } from 'services/api';
import { shippingIntegrationsApi } from 'services/api/config';
import { Ship, ShipCarton } from 'services/shipping';

import { PurchaseLabelExtra, PurchaseLabelResponse } from './purchaseLabel';
import { transformToPurchaseLabelResponse } from './purchaseLabel/transformations';

import {
  transformToShippingCarriers,
  transformToShippingSettings,
  transformToFetchRatesPayload,
  transformToShippingIntegrationRates,
  transformShippingIntegrationPackage,
  transformToPurchaseLabelPayload,
  transformToShippingIntegrationFileType,
  transformToQuickShipPayload,
  transformToIntegrationShippingSettingsRequest,
  transformToQuotesFetchRatesPayload,
  transformShippingEventHistory,
  transformToShippingQuoteAddToSalesOrderPayload,
  transformShippingLabel,
} from './transformations';
import {
  SalesOrderShippingQuote,
  ShippingConnection,
  ShippingEventHistory,
  ShippingIntegrationCarton,
  ShippingIntegrationFileType,
  ShippingIntegrationSettings,
  ShippingQuoteAddToSalesOrderParams,
} from './types';

import { ShippingLabel } from '.';

export const shippingConnectionDisconnect = async (
  connection: ShippingConnection
) => {
  const { name } = connection;

  await shippingIntegrationsApi.delete(`/api/v1/connection`, {
    headers: {
      'X-Connection-Name': name,
    },
  });
};

export const getShippingSettings = async (connection: ShippingConnection) => {
  const { name } = connection;

  try {
    const res = await shippingIntegrationsApi.get(`/api/v1/settings`, {
      headers: {
        'X-Connection-Name': name,
      },
    });

    return transformToShippingSettings(res.data);
  } catch (e) {
    showNotification(
      _.get(e, 'response.data.message', 'Please contact support'),
      { variant: 'error' }
    );

    throw e;
  }
};

export const getCarrierServices = async (connection: ShippingConnection) => {
  const { name } = connection;

  try {
    const res = await shippingIntegrationsApi.get(`/api/v1/data/services`, {
      headers: {
        'X-Connection-Name': name,
      },
    });

    return transformToShippingCarriers(res.data);
  } catch (e) {
    showNotification(
      _.get(e, 'response.data.message', 'Please contact support'),
      { variant: 'error' }
    );

    throw e;
  }
};

export const getShippingIntegrationPackages = async (
  connection: ShippingConnection
) => {
  const { name } = connection;

  try {
    const res = await shippingIntegrationsApi.get(`/api/v1/data/packages`, {
      headers: {
        'X-Connection-Name': name,
      },
    });

    return res.data.map(transformShippingIntegrationPackage);
  } catch (e) {
    showNotification(
      _.get(e, 'response.data.message', 'Please contact support'),
      { variant: 'error' }
    );

    throw e;
  }
};

export const putShippingSettings = async (
  connection: ShippingConnection,
  settings: ShippingIntegrationSettings
) => {
  const { name } = connection;

  try {
    const res = await shippingIntegrationsApi.put(
      `/api/v1/settings`,
      transformToIntegrationShippingSettingsRequest(settings),
      {
        headers: {
          'X-Connection-Name': name,
        },
      }
    );
    showNotification('Settings successfully saved', { variant: 'success' });

    return transformToShippingSettings(res.data);
  } catch (e) {
    showNotification(
      _.get(e, 'response.data.message', 'Please contact support'),
      { variant: 'error' }
    );

    throw e;
  }
};

export const fetchShippingIntegrationRates = async (
  connection: ShippingConnection,
  cartons: ShipCarton[],
  shipTo: Address,
  shipFrom: Address,
  purchaseLabelData: PurchaseLabelExtra
): Promise<any[]> => {
  const { name } = connection;

  try {
    const res = await shippingIntegrationsApi.post(
      `/api/v1/rates/quote`,
      transformToFetchRatesPayload(
        cartons,
        shipTo,
        shipFrom,
        purchaseLabelData
      ),
      {
        headers: {
          'X-Connection-Name': name,
        },
      }
    );

    return transformToShippingIntegrationRates(res.data, connection);
  } catch (e) {
    showNotification('Error with Shippo integration. Contact support.', {
      variant: 'error',
    });

    throw e;
  }
};

export const fetchShippingIntegrationFileTypes = async (
  connection: ShippingConnection
): Promise<ShippingIntegrationFileType[]> => {
  const { name } = connection;

  try {
    const res = await shippingIntegrationsApi.get(`/api/v1/data/filetypes`, {
      headers: {
        'X-Connection-Name': name,
      },
    });

    return res.data.map(transformToShippingIntegrationFileType);
  } catch (e) {
    showNotification(
      _.get(e, 'response.data.message', 'Please contact support'),
      { variant: 'error' }
    );

    throw e;
  }
};

export const submitPurchaseLabel = async (
  connection: ShippingConnection,
  ship: Ship,
  cartons: ShipCarton[],
  shipTo: Address,
  shipFrom: Address,
  purchaseLabelData: PurchaseLabelExtra
): Promise<PurchaseLabelResponse[]> => {
  const { name } = connection;

  try {
    const res = await shippingIntegrationsApi.post(
      `/api/v1/label/purchase`,
      transformToPurchaseLabelPayload(
        ship,
        cartons,
        shipTo,
        shipFrom,
        purchaseLabelData
      ),
      {
        headers: {
          'X-Connection-Name': name,
        },
      }
    );

    return res.data.map(transformToPurchaseLabelResponse);
  } catch (e) {
    const message =
      _.get(e, 'response.data.message', 'Something went wrong') +
      ': ' +
      _.get(e, 'response.data.error', '');
    showNotification(message.substring(0, 300) + '...', {
      variant: 'error',
    });

    throw e;
  }
};

export const submitQuickShip = async (
  connection: ShippingConnection,
  ship: Ship,
  settings: ShippingIntegrationSettings
): Promise<PurchaseLabelResponse[]> => {
  const { name } = connection;

  try {
    const res = await shippingIntegrationsApi.post(
      `/api/v1/label/quickship`,
      transformToQuickShipPayload(ship, settings),
      {
        headers: {
          'X-Connection-Name': name,
        },
      }
    );

    return res.data.map(transformToPurchaseLabelResponse);
  } catch (e) {
    const messages = _.get(e, 'response.data.messages', []);
    const message: any = messages.find((m: any) => m.severity === 'Error');
    showNotification(_.get(message, 'body', 'Please contact support'), {
      variant: 'error',
    });

    throw e;
  }
};

export const fetchShippingSalesOrderQuotesRates = async (
  connection: ShippingConnection,
  cartons: ShippingIntegrationCarton[],
  shipTo: Address,
  shipFrom: Address,
  salesOrderShippingQuote: SalesOrderShippingQuote
): Promise<any[]> => {
  const { name } = connection;

  try {
    const res = await shippingIntegrationsApi.post(
      `/api/v1/rates/quote`,
      transformToQuotesFetchRatesPayload(
        cartons,
        shipTo,
        shipFrom,
        salesOrderShippingQuote
      ),
      {
        headers: {
          'X-Connection-Name': name,
        },
      }
    );

    return transformToShippingIntegrationRates(res.data, connection);
  } catch (e) {
    const messages = _.get(e, 'response.data.messages', []);
    const message: any = messages.find((m: any) => m.severity === 'Error');
    showNotification(_.get(message, 'body', 'Please contact support'), {
      variant: 'error',
    });

    throw e;
  }
};

export const shippingQuoteAddToSalesOrder = async (
  params: ShippingQuoteAddToSalesOrderParams
) => {
  try {
    await shippingIntegrationsApi.post(
      `/api/v1/rates`,
      transformToShippingQuoteAddToSalesOrderPayload(params),
      {
        headers: {
          'X-Connection-Name': params.connection.name,
        },
      }
    );
  } catch (e) {
    const message =
      _.get(e, 'response.data.message', 'Something went wrong') +
      ': ' +
      _.get(e, 'response.data.error', '');
    showNotification(message.substring(0, 300) + '...', {
      variant: 'error',
    });

    throw e;
  }
};

export const refundCarton = async (
  connection: ShippingConnection,
  trackingNumber: string
) => {
  const { name } = connection;

  await shippingIntegrationsApi.delete(`/api/v1/label/${trackingNumber}`, {
    headers: {
      'X-Connection-Name': name,
    },
  });
};

export const getShippingLabel = async (
  connection: ShippingConnection,
  trackingNumber: string
): Promise<ShippingLabel> => {
  const { name } = connection;

  try {
    const res = await shippingIntegrationsApi.get(
      `/api/v1/label/${trackingNumber}`,
      {
        headers: {
          'X-Connection-Name': name,
        },
      }
    );

    return transformShippingLabel(res.data);
  } catch (e) {
    showNotification(
      _.get(e, 'response.data.message', 'Please contact support'),
      { variant: 'error' }
    );

    throw e;
  }
};

export const getValidateAddress = async (
  connection: ShippingConnection,
  address: Address
): Promise<AddressValidation> => {
  const { name } = connection;

  try {
    const res = await shippingIntegrationsApi.post(
      `/api/v1/address/validate`,
      transformValidAddressRequest(address),
      {
        headers: {
          'X-Connection-Name': name,
        },
      }
    );

    return transformValidAddress(res.data);
  } catch (e) {
    const messages = _.get(e, 'response.data.messages', []);
    const message: any = messages.find((m: any) => m.severity === 'Error');
    showNotification(_.get(message, 'body', 'Please contact support'), {
      variant: 'error',
    });

    throw e;
  }
};

export const getShippingEventHistory = async (
  connection: ShippingConnection
): Promise<ShippingEventHistory[]> => {
  const { name } = connection;

  try {
    const res = await shippingIntegrationsApi.get(`/api/v1/events`, {
      headers: {
        'X-Connection-Name': name,
      },
    });

    return res.data.map(transformShippingEventHistory);
  } catch (e) {
    showNotification(
      _.get(e, 'response.data.message', 'Please contact support'),
      { variant: 'error' }
    );

    throw e;
  }
};
