import { makeStyles } from '@mui/styles';
import { colorPalette } from 'ui/theme';

export const useMenuStyle = makeStyles(() => ({
  danger: {
    color: colorPalette.redesign.contentRed,
  },
  default: {
    color: colorPalette.redesign.contentSecondary,
  },

  menuItem: {
    height: '48px',
    padding: '8px 16px',
    '&:hover': {
      backgroundColor: colorPalette.redesign.highlightBlue,
    },
    borderBottom: `1px solid ${colorPalette.redesign.background3}`,
  },

  defaultMenuItem: {
    height: '48px',
    padding: '8px 16px',
    '&:hover': {
      backgroundColor: colorPalette.redesign.highlightBlue,
    },
  },

  menu: {
    '& .MuiMenu-paper': {
      marginTop: '8px',
    },
    '& .MuiMenu-list': {
      padding: 0,
    },
  },
}));
