import { makeStyles } from '@mui/styles';
import { colorPalette } from 'ui/theme';

export const useDiscoverChannelsHeroComponent = makeStyles(() => ({
  container: {
    backgroundColor: colorPalette.white,
    borderRadius: 8,
    display: 'flex',
    padding: '48px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '24px',
    alignSelf: 'stretch',
    boxShadow:
      '0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
  },
  integrationsImage: {
    maxWidth: '100%',
  },
}));
