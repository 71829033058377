import _ from 'lodash';
import moment from 'moment';

import { GlobalUser } from 'services/user';

export const resolveModalBody = (user: GlobalUser) => {
  const tenant = _.get(user, 'company.tenant', null);
  const expirationDate = _.get(user, 'company.expirationDate', null);
  const todayDate = moment();
  const expiredDays = expirationDate && todayDate.diff(expirationDate, 'days');

  if (!tenant || !expiredDays) {
    return '';
  }

  return `${tenant} expired ${expiredDays} days ago`;
};
