import _ from 'lodash';

import {
  ItemTrackingType,
  SerialRow as SerialRowType,
  SerialColumn,
  SerialRow,
} from 'services/inventory';
import { findNextNegativeId } from 'helpers';
import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';
import { Errors } from 'services/forms/validation';

export const transformToColumns = (
  trackingTypes: ItemTrackingType[]
): Column[] => {
  const trackingTypeColumns = trackingTypes.map((type) => ({
    title: _.get(type, 'trackingType.name', ''),
    meta: { trackingTypeId: type.trackingTypeId },
    type: ColumnTypes.renderByCustomRow,
    sortable: true,
  }));

  const statusColumn = {
    title: 'Status',
    type: ColumnTypes.renderByCustomRow,
  };

  return [...trackingTypeColumns, statusColumn] as Column[];
};

export const transformToColumnsWithStatus = (
  trackingTypes: ItemTrackingType[]
): Column[] => {
  const trackingTypeColumns = trackingTypes.map((type) => ({
    title: _.get(type, 'trackingType.name', ''),
    meta: { trackingTypeId: type.trackingTypeId },
    type: ColumnTypes.renderByCustomRow,
    width: '90%',
  }));

  const statusColumn = {
    title: 'Status',
    type: ColumnTypes.renderByCustomRow,
    width: '10%',
  };

  return [...trackingTypeColumns, statusColumn] as Column[];
};

export const clearSerialRow = (index: number, serialList: SerialRowType[]) => {
  const emptySerialNumbers: SerialColumn = {};
  for (const key of Object.keys(serialList[index].serialNumbers)) {
    emptySerialNumbers[parseInt(key, 10)] = null;
  }
  const emptySerialRow: SerialRowType = {
    id: serialList[index].id,
    committed: serialList[index].committed,
    serialNumbers: emptySerialNumbers,
  };
  return emptySerialRow;
};

export const removeSurplusSerialRows = (
  serialList: SerialRowType[],
  quantity: number,
  canSelectRow: boolean
) => {
  // quantity is for removal of both non empty and empty rows
  // canSelectRow condition is due to quantity change and serialList change on row select
  return canSelectRow ? serialList : serialList.slice(0, quantity);
};

export const removeEmptyRows = (
  serialList: SerialRowType[],
  canSelectRow: boolean
) => {
  return canSelectRow
    ? serialList
    : serialList.filter((row) => Object.values(row.serialNumbers)[0] !== null);
};

export const addEmptySerialRows = (
  serialList: SerialRowType[],
  count: number,
  itemTrackingTypes: ItemTrackingType[]
) => {
  const newSerialList = [...serialList];
  const minId = findNextNegativeId<SerialRowType>(newSerialList);

  const emptyColumns = itemTrackingTypes.reduce(
    (ec, type) => ({ ...ec, [type.trackingTypeId]: null }),
    {}
  );
  const emptyRows = [...Array(count)].map((v, i) => ({
    id: minId - i,
    serialNumbers: emptyColumns,
  }));

  return emptyRows.concat(newSerialList);
};

export const getSerialsFromFile = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    if (file) {
      reader.readAsText(file);
      reader.onload = () =>
        resolve(reader.result ? reader.result.toString().split(',') : []);
      reader.onerror = (error) => reject(error);
    }
  });

export const resolveSerialTableErrors = (serialRows: SerialRow[]): Errors[] => {
  return serialRows.map((serialRow) => {
    const rowError: Errors = Object.entries(serialRow.serialNumbers)
      .filter(([, value]) => _.isEmpty(value))
      .reduce(
        (acc: any, [key]: any) => ({
          ...acc,
          [key]: 'Serial number must have value',
        }),
        {}
      );

    return rowError;
  });
};

export const autoAssignHandler = (
  serialLength: number,
  nextValue: string | null
) => {
  const arr = [];
  if (nextValue)
    if (!isNaN(nextValue as any)) {
      for (let i = 0; i < serialLength; i++) {
        const d = {
          id: -1 * (i + 1),
          serialNumbers: { 3: (i + parseInt(nextValue)).toString() },
        };
        arr.push(d);
      }
      return arr;
    } else {
      const str = nextValue.replace(/\D/g, ',').split(',');

      for (let j = str.length - 1; j >= 0; j--) {
        if (str[j] != '') {
          for (let i = 0; i < serialLength; i++) {
            const b = nextValue.split(str[j]);
            const d = {
              id: -1 * (i + 1),
              serialNumbers: {
                3: b[0] + (i + parseInt(str[j])).toString() + b[1],
              },
            };
            arr.push(d);
          }

          return arr;
        }
      }
      for (let i = 0; i < serialLength; i++) {
        const d = {
          id: -1 * (i + 1),
          serialNumbers: { 3: (nextValue + i).toString() },
        };
        arr.push(d);
      }
      return arr;
    }
};

export const isCommitted = (id: number, list: SerialRowType[]): boolean => {
  return list[list.findIndex((serial) => serial.id === id)]?.committed;
};

export const committedSerials = (serialList: any): SerialRow[] => {
  return serialList.filter((item: any) => {
    return item?.committed;
  });
};

export const listContainsOnlyNumericSerialNumbers = (
  serialList: any
): boolean => {
  const reg = /[a-zA-Z]/g;

  const serialContainsLetters = (testRow: SerialRowType) => {
    return reg.test(Object.values(testRow.serialNumbers)[0]);
  };

  return !serialList.some(serialContainsLetters);
};

//takes in array sorted by serial number
export const duplicateSerialNumberIndexes = (
  serialList: SerialRowType[],
  duplicateArray?: string[]
): number[] => {
  const dupeIndexes: number[] = [];
  for (let i = 1; i < serialList.length; i++) {
    if (
      Object.values(serialList[i - 1].serialNumbers)[0] ===
      Object.values(serialList[i].serialNumbers)[0]
    ) {
      if (dupeIndexes[dupeIndexes.length - 1] === i - 1) {
        dupeIndexes.push(i);
      } else {
        dupeIndexes.push(i - 1, i);
      }
    }
  }

  if (duplicateArray) {
    for (let i = 0; i < serialList.length; i++) {
      //also mark location duplicate indexes coming back from platform
      duplicateArray.forEach((num) => {
        if (num === Object.values(serialList[i]?.serialNumbers)[0]) {
          if (!dupeIndexes.includes(i)) {
            dupeIndexes.push(i);
          }
        }
      });
    }
  }
  return dupeIndexes;
};

//takes in array sorted by serial number
export const duplicateSerialNumberIds = (
  serialList: SerialRowType[],
  duplicateArray?: string[]
): number[] => {
  const dupeIds: number[] = [];
  for (let i = 1; i < serialList.length; i++) {
    if (
      Object.values(serialList[i - 1]?.serialNumbers)[0] ===
      Object.values(serialList[i]?.serialNumbers)[0]
    ) {
      if (!dupeIds.includes(serialList[i - 1]?.id)) {
        dupeIds.push(serialList[i - 1]?.id);
      }
      if (!dupeIds.includes(serialList[i]?.id)) {
        dupeIds.push(serialList[i]?.id);
      }
    }
  }

  if (duplicateArray) {
    for (let i = 0; i < serialList.length; i++) {
      //also mark location duplicate ids coming back from platform
      duplicateArray.forEach((num) => {
        if (num === Object.values(serialList[i]?.serialNumbers)[0]) {
          if (!dupeIds.includes(serialList[i]?.id)) {
            dupeIds.push(serialList[i]?.id);
          }
        }
      });
    }
  }
  return dupeIds;
};

export const sortSerialList = (serialList: SerialRowType[]) => {
  listContainsOnlyNumericSerialNumbers(serialList)
    ? serialList.sort(
        (a, b) =>
          Object.values(a.serialNumbers)[0] - Object.values(b.serialNumbers)[0]
      )
    : serialList.sort((a, b) =>
        Object.values(a.serialNumbers)[0] > Object.values(b.serialNumbers)[0]
          ? 1
          : Object.values(a.serialNumbers)[0] <
            Object.values(b.serialNumbers)[0]
          ? -1
          : 0
      );
};

//incoming serial list must be sorted
export const sortedSerialListWithDuplicatesFirst = (
  serialList: SerialRowType[],
  setSerialList: React.Dispatch<React.SetStateAction<SerialRow[]>>,
  dupeIndexes: number[]
) => {
  const duplicatedSerials: SerialRow[] = [];
  const tempSerials = _.cloneDeep(serialList);

  for (let i = dupeIndexes.length - 1; i >= 0; i--) {
    const index = dupeIndexes[i];
    duplicatedSerials.push(tempSerials[index]);
    tempSerials.splice(index, 1);
  }

  setSerialList(duplicatedSerials.reverse().concat(tempSerials));
};
