import { State } from 'app/redux';

export const isAlertVisible = (state: State) => state.alert.alert.show;

export const getAlert = (state: State) => state.alert.alert;

export const getAlertMsg = (state: State) => state.alert.alert.msg;

export const isProgressAlertVisible = (state: State) =>
  state.alert.progressAlert.show;

export const getProgressAlertMsg = (state: State) =>
  state.alert.progressAlert.msg;

export const getBackgroundType = (state: State) =>
  state.alert.progressAlert.backgroundType;

export const getBackgroundAction = (state: State) =>
  state.alert.progressAlert.backgroundAction;
