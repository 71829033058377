import _ from 'lodash';

import { DataWithTraits, TraitedData } from './types';

export function separateCollectionWithTraits<T>(
  collection: DataWithTraits<T>[]
): TraitedData<T> {
  const deletedCollection = collection.filter((c) => c.isDeleted && !c.isAdded);
  const editedCollection = collection.filter(
    (c) => c.isEdited && !c.isAdded && !c.isDeleted
  );
  const addedCollection = collection.filter((c) => c.isAdded && !c.isDeleted);

  return {
    added: addedCollection,
    edited: editedCollection,
    deleted: deletedCollection,
  };
}

export function removeTraitsFromCollection<T>(
  collection: DataWithTraits<T>[]
): T[] {
  return collection.map(
    (item) => _.omit(item, ['isDeleted', 'isAdded', 'isEdited']) as any
  );
}

export function removeTraitsFromSeparatedCollection<T>(
  collection: TraitedData<DataWithTraits<T>>
): TraitedData<T> {
  return {
    added: removeTraitsFromCollection(collection.added),
    edited: removeTraitsFromCollection(collection.edited),
    deleted: removeTraitsFromCollection(collection.deleted),
  };
}

/**
 * Raplaces object in the collection defined by index.
 * If index doesn't exists in the collection returns null.
 *
 * @param collection
 * @param newValue
 * @param index
 */
export function replaceValueInCollection<T = any>(
  collection: T[],
  newValue: T,
  index: number
): T[] | null {
  if (index > -1 && index < collection.length) {
    return [
      ...collection.slice(0, index),
      newValue,
      ...collection.slice(index + 1),
    ];
  }

  return null;
}

/**
 * Removes object in the collection defined by index.
 *
 * @param collection
 * @param index
 */
export function removeValueFromCollection<T = any>(
  collection: T[],
  index: number
): T[] {
  return [...collection.slice(0, index), ...collection.slice(index + 1)];
}

/**
 * Returns next negative value (starting from -1) for the field in the list
 *
 * @param ids
 * @param idName
 */
export function findNextNegativeId<T>(ids: T[], idName = 'id'): number {
  return _.min([_.min(_.map(ids, idName)) || 0, 0])! - 1;
}

export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

// When we delete object from array that does not have an ID, it will be removed from the state.
// Otherwise, it will have deleted true flag.
export const deleteEntityFromArray = <
  T extends { id: number | null; deleted: boolean }
>(
  data: T[],
  index: number
): T[] => {
  const entity = data[index];
  if (entity.id && entity.id > 0) {
    return replaceValueInCollection(data, { ...entity, deleted: true }, index)!;
  }

  return removeValueFromCollection(data, index);
};

/**
 * Insert value or array of values to collection after index
 *
 * @param collection
 * @param values
 * @param index
 */
export const insertValuesToCollection = <T = any>(
  collection: T[],
  values: T | T[],
  index: number
): T[] => {
  if (Array.isArray(values)) {
    return [
      ...collection.slice(0, index + 1),
      ...values,
      ...collection.slice(index + 1),
    ];
  }

  return [
    ...collection.slice(0, index + 1),
    values,
    ...collection.slice(index + 1),
  ];
};
