import React, { memo, useMemo, useCallback, useState, useEffect } from 'react';
import _, { noop } from 'lodash';
import { useSelector } from 'react-redux';
import { getSettingsCompany } from 'services/settings/company';
import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { ItemInventoryEvents, TrackingGroup } from 'services/inventory';
import { PermissionType } from 'services/permissions';
import { useFlags } from 'helpers/useFlags';

import {
  InventoryEventModal,
  InventoryEventFormValues,
  transformToInventoryEventData,
} from '../../../../../InventoryEventModal';
import {
  transformToEditInventoryNonSerialTrackingData,
  transformToEditInventorySerialTrackingData,
} from './transformations';
import { InventoryTableProps } from './types';
import { INVENTORY_COLUMNS } from './consts';
import { InventoryTableSubRow } from './components';
import { InventoryRowActionTypes } from './components/InventoryTableRow/types';
import { useInventoryTableRowStyle } from './components/InventoryTableRow/styled';
import { InventoryTrackingDetails } from './components/InventoryTrackingDetails';
import { CycleEventModal } from '../../../../../CycleEventModal';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const InventoryTable: React.FC<InventoryTableProps> = ({
  item,
  itemInventory,
  onEditInventoryApply,
  onEventInventoryApply,
  duplicateArray,
  setDuplicateArray,
}) => {
  const [eventType, setEventType] = useState(ItemInventoryEvents.Add);
  const [activeLocationId, setActiveLocationId] = useState<number | null>(null);
  const [showEventModal, setShowEventModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [confirmButtonRed, setConfirmButtonRed] = useState<boolean>(false);
  const company = useSelector(getSettingsCompany);
  const classes = useInventoryTableRowStyle();
  const flags = useFlags();

  const itemTrackingType = useMemo(
    () => _.get(item, 'itemTrackingTypeList', []),
    [item]
  );

  const noTracking = useMemo(
    () => _.isEmpty(itemTrackingType),
    [itemTrackingType]
  );

  const handleRowAction = useCallback(
    (actionData) => {
      switch (actionData.actionType) {
        case InventoryRowActionTypes.RowClick: {
          if (!noTracking) {
            setActiveLocationId(actionData.locationId);
            setShowEditModal(true);
          }
          break;
        }
        default: {
          setEventType(actionData.eventType);
          setActiveLocationId(actionData.locationId);
          setShowEventModal(true);
          setConfirmButtonRed(actionData.confirmButtonRed);
        }
      }
    },
    [noTracking]
  );

  const handleCycleClicked = useCallback(() => {
    setEventType(ItemInventoryEvents.Cycle);
    setActiveLocationId(null);
    setShowEventModal(true);
  }, []);

  const handleTableRowClicked = useCallback((locationId) => {
    setActiveLocationId(locationId);
    setShowEditModal(true);
  }, []);

  const handleAddToInventoryClicked = useCallback(() => {
    setEventType(ItemInventoryEvents.Add);
    setShowEventModal(true);
  }, []);

  const handleEventModalApply = async (
    formValues: InventoryEventFormValues,
    trackingGroups: TrackingGroup[]
  ) => {
    const inventoryEventData = transformToInventoryEventData(
      eventType,
      item.id!,
      formValues,
      trackingGroups,
      company?.homeCurrency?.code ?? 'USD'
    );

    try {
      await onEventInventoryApply(eventType, inventoryEventData);
    } catch {
      return;
    }
  };

  //this will run after every onEventInventoryApply > postInventoryEvent call
  useEffect(() => {
    if (!duplicateArray.length) {
      setShowEventModal(false);
    }
  }, [duplicateArray]);

  const handleEventModalCancel = () => {
    setShowEventModal(false);
    setActiveLocationId(null);
    setDuplicateArray([]);
  };

  const handleEditModalApply = async (
    initialTrackingGroups: TrackingGroup[],
    trackingGroups: TrackingGroup[]
  ) => {
    const editInventoryNonSerialTracking = initialTrackingGroups.map(
      (initialTrackingGroup, index) =>
        transformToEditInventoryNonSerialTrackingData(
          item ? item.id! : 0,
          activeLocationId,
          initialTrackingGroup,
          trackingGroups[index]
        )
    );

    const editInventorySerialTracking = trackingGroups.flatMap(
      (trackingGroup) =>
        trackingGroup.serialList.map((serialRow) =>
          transformToEditInventorySerialTrackingData(serialRow)
        )
    );

    try {
      await onEditInventoryApply(
        editInventoryNonSerialTracking,
        editInventorySerialTracking
      );
    } catch {
      return;
    }
    setShowEditModal(false);
    setActiveLocationId(null);
  };

  const handleEditModalCancel = () => {
    setShowEditModal(false);
    setActiveLocationId(null);
  };

  const getInventoryColumns = useCallback(() => {
    return flags.showInventoryShortValues
      ? INVENTORY_COLUMNS
      : _.filter(INVENTORY_COLUMNS, (column) => column.title !== 'Short');
  }, [flags.showInventoryShortValues]);

  return (
    <>
      <FBOTitleBar title="Inventory">
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          icon="FBOCycle"
          onClick={handleCycleClicked}
          data-qa="inventory-cycle"
          permissions={[PermissionType.InventoryCycle]}
        >
          Cycle
        </FBOButton>
        <FBOButton
          variant="secondary"
          color="positive"
          size="medium"
          icon="FBOAddCircle"
          onClick={handleAddToInventoryClicked}
          data-qa="inventory-add"
          permissions={[PermissionType.InventoryAdd]}
        >
          Add
        </FBOButton>
      </FBOTitleBar>
      <ItemsTable
        data={itemInventory ? itemInventory.inventoryTableRows : []}
        columns={getInventoryColumns()}
        onItemClick={noTracking ? noop : handleTableRowClicked}
        filterRows={(i) => !i.location.deleted}
        RenderCustomRow={InventoryTableSubRow}
        // RenderRowSubrows={InventoryTableSubRow}
        meta={{
          uom: item.defaultUom,
        }}
        getRowclsx={() => ({
          [classes.noTrackingRow]: noTracking,
        })}
        onAction={handleRowAction}
        selectableItems={false}
        dataQa="inventory-table"
        emptyTableText=""
        showEnhancedTablePaginationActions
      />
      {eventType === ItemInventoryEvents.Cycle ? (
        <CycleEventModal
          show={showEventModal}
          item={item}
          locationId={activeLocationId}
          eventType={eventType}
          onApplyClicked={handleEventModalApply}
          onCancelClicked={handleEventModalCancel}
          locationDuplicateArray={duplicateArray}
          setShowEventModal={setShowEventModal}
        />
      ) : (
        <InventoryEventModal
          show={showEventModal}
          item={item}
          locationId={activeLocationId}
          eventType={eventType}
          onApplyClicked={handleEventModalApply}
          onCancelClicked={handleEventModalCancel}
          confirmButtonRed={confirmButtonRed}
        />
      )}
      <InventoryTrackingDetails
        show={showEditModal}
        locationId={activeLocationId}
        itemTrackingTypes={itemTrackingType}
        inventoryData={itemInventory ? itemInventory.inventoryRowList : []}
        onApplyClicked={handleEditModalApply}
        onCancelClicked={handleEditModalCancel}
        inventoryUom={item.defaultUom}
      />
    </>
  );
};

export default memo(InventoryTable);
