import _ from 'lodash';

import { PaginationDirection } from 'services/url';
import {
  addSearch,
  deleteSearch,
  updateSearch,
  getSearches,
} from 'services/receiving';
import {
  AdvancedSearchFormValues,
  AdvancedSearchReduxFunctions,
} from 'services/search';
import { Location } from 'services/locations';
import { Customer } from 'services/customers';
import { Vendor } from 'services/vendors';
import { Item } from 'services/items';

export const initialPagination = {
  page: 1,
  pageSize: 25,
  sort: {
    sortBy: 'number',
    direction: PaginationDirection.Descending,
  },
  totalRows: 25,
};

export const createDisplayValueMap = (
  locations: Location[],
  customers: Customer[],
  vendors: Vendor[],
  item: Item | null
) => ({
  status: (status: string) => status.split(',').join(', '),
  locationId: (id: number) =>
    _.get(
      locations.find((p) => p.id === id),
      'name',
      null
    ),
  'salesOrder.customerId': (id: number) =>
    _.get(
      customers.find((c) => c.id === id),
      'name',
      null
    ),
  'purchaseOrder.vendorId': (id: number) =>
    _.get(
      vendors.find((v) => v.id === id),
      'name',
      null
    ),
  'receiptItems.itemId': () => (item ? item.name : '-'),
});

export const initialReceivingFormValues: AdvancedSearchFormValues = {
  number: null,
  status: null,
  customerId: null,
  locationId: null,
  orderType: null,
  'receiptItems.item.description': null,
  'salesOrder.customerId': null,
  'purchaseOrder.vendorId': null,
  'receiptItems.itemId': null,
};

export const advancedSearchReduxActions: AdvancedSearchReduxFunctions = {
  add: addSearch,
  delete: deleteSearch,
  update: updateSearch,
  get: getSearches,
};

export const displayNameMap = {
  number: 'Receipt',
  customerId: 'Customer',
  status: 'Statuses',
  locationId: 'Location',
  orderType: 'Order type',
  'salesOrder.customerId': 'Customer',
  'purchaseOrder.vendorId': 'Vendor',
  'receiptItems.itemId': 'Item',
  'receiptItems.item.description': 'Item description',
};
