import { PermissionType } from 'services/permissions';
import { TaxRate } from 'services/taxRates';

export const editTaxRatePermissions = (taxRate: TaxRate): PermissionType[] => {
  if (taxRate.deleted) {
    return [PermissionType.Undelete];
  }
  return taxRate.id && taxRate.id > 0
    ? [PermissionType.TaxRatesEdit]
    : [PermissionType.TaxRatesCreate];
};
