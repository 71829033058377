import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colorPalette } from 'ui/theme';

export const usePermissionsTabStyle = makeStyles((theme: Theme) => ({
  wrapper: {
    borderTop: `1px solid ${colorPalette.applicationDarkGray20}`,
  },
  tabs: {
    backgroundColor: 'transparent',
    overflow: 'auto',
    overflowX: 'hidden',
    width: '100%',
    '& .MuiTabs-indicator': { backgroundColor: 'transparent' },
    '& .MuiTabs-scroller': {
      overflow: 'unset !important',
    },
  },
  tabWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    '& svg': {
      marginBottom: '0 !important',
      marginRight: theme.spacing(0.5),
    },
  },
  tab: {
    minHeight: 'auto',
    height: 56,
    borderRadius: 2,
    textTransform: 'none',
    borderBottom: `1px solid ${colorPalette.applicationDarkGray10}`,
    '&.Mui-selected': {
      backgroundColor: colorPalette.applicationLightGray,
      border: 'none',
    },
    '&.MuiTab-labelIcon': {
      paddingTop: 6,
    },
  },
  sidebar: {
    width: 200,
    height: '100%',
    borderRight: `1px solid ${colorPalette.applicationDarkGray10}`,
  },
  content: {
    width: 'calc(100% - 200px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  permissionCategory: {
    padding: theme.spacing(3),
    borderBottom: `1px solid ${colorPalette.applicationDarkGray10}`,
    borderRight: `1px solid ${colorPalette.applicationDarkGray10}`,
    '&:nth-child(even)': {
      borderRight: 'none',
    },
  },
}));
