import {
  getSearches,
  addSearch,
  updateSearch,
  deleteSearch,
} from 'services/carriers';
import {
  AdvancedSearchFormValues,
  AdvancedSearchReduxFunctions,
} from 'services/search';
import { ColumnTypes } from 'ui/components/Table/ItemsTable';
import { Column } from 'ui/components/Table/ItemsTable/types';
import { PaginationDirection } from 'services/url';

export const CURRENCY_COLUMNS: Column[] = [
  {
    title: 'Name',
    field: 'name',
    sortable: true,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Code',
    field: 'code',
    sortable: true,
    type: ColumnTypes.renderByCustomRow,
  },
];

export const advancedSearchReduxActions: AdvancedSearchReduxFunctions = {
  add: addSearch,
  delete: deleteSearch,
  update: updateSearch,
  get: getSearches,
};

export const displayNameMap = {
  name: 'Name',
  code: 'Code',
  deleted: 'Deleted',
};

export const initialCurrencyFormValues: AdvancedSearchFormValues = {
  name: null,
  code: null,
  deleted: null,
};

export const initialPagination = {
  page: 1,
  pageSize: 25,
  sort: {
    sortBy: 'name',
    direction: PaginationDirection.Descending,
  },
  totalRows: 25,
};
