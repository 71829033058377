import moment from 'moment';
import _ from 'lodash';

import { PaginationDirection } from 'services/url';
import {
  addSearch,
  deleteSearch,
  updateSearch,
  getSearches,
} from 'services/purchaseOrders';
import {
  AdvancedSearchFormValues,
  AdvancedSearchReduxFunctions,
} from 'services/search';
import { Vendor } from 'services/vendors';
import { Carrier } from 'services/carriers';
import { PaymentTerm } from 'services/paymentTerms';
import { ShippingTerm } from 'services/items';
import { Location } from 'services/locations';
import { User } from 'services/user';
import { User as UserV2 } from 'services/userV2';

export const initialPagination = {
  page: 1,
  pageSize: 25,
  sort: {
    sortBy: 'number',
    direction: PaginationDirection.Descending,
  },
  totalRows: 25,
};

export const initialPurchaseOrderFormValues: AdvancedSearchFormValues = {
  number: null,
  vendorId: null,
  buyerId: null,
  purchaseOrderStatus: null,
  customerSONumber: null,
  vendorSONumber: null,
  dateScheduled: null,
  carrierId: null,
  email: null,
  phone: null,
  vendorAddressStreet: null,
  vendorAddressCity: null,
  vendorAddressState: null,
  vendorAddressPostalCode: null,
  vendorAddressCountry: null,
  shipToStreet: null,
  shipToCity: null,
  shipToState: null,
  shipToPostalCode: null,
  shipToCountry: null,
  notes: null,
  paymentTermId: null,
  shippingTermId: null,
  locationId: null,
  'purchaseOrderItems.name': null,
  'purchaseOrderItems.description': null,
  dateCreatedFrom: null,
  dateCreatedTo: null,
  dateLastModifiedFrom: null,
  dateLastModifiedTo: null,
  dateIssuedFrom: null,
  dateIssuedTo: null,
  dateScheduledFrom: null,
  dateScheduledTo: null,
  dateFulfilledFrom: null,
  dateFulfilledTo: null,
  deleted: null,
};

export const advancedSearchReduxActions: AdvancedSearchReduxFunctions = {
  add: addSearch,
  delete: deleteSearch,
  update: updateSearch,
  get: getSearches,
};

export const displayNameMap = {
  number: 'Number',
  vendorId: 'Vendor',
  purchaseOrderStatus: 'Status',
  buyerId: 'Buyer',
  customerSONumber: 'Customer SO Number ',
  vendorSONumber: 'Vendor SO Number ',
  carrierId: 'Carrier',
  email: 'Email',
  phone: 'Phone',
  vendorAddressStreet: 'Bill to street',
  vendorAddressCity: 'Bill to city',
  vendorAddressState: 'Bill to state',
  vendorAddressPostalCode: 'Bill to postal code',
  vendorAddressCountry: 'Bill to country',
  shipToStreet: 'Ship to street',
  shipToCity: 'Ship to city',
  shipToState: 'Ship to state',
  shipToPostalCode: 'Ship to postal code',
  shipToCountry: 'Ship to country',
  notes: 'Notes',
  paymentTermId: 'Payment term',
  shippingTermId: 'Shipping term',
  locationId: 'Location',
  'purchaseOrderItems.name': 'Item name',
  'purchaseOrderItems.description': 'Item description',
  dateScheduledFrom: 'Date Scheduled After',
  dateScheduledTo: 'Date Scheduled Before',
  dateCreatedFrom: 'Date Created After',
  dateCreatedTo: 'Date Created Before',
  dateLastModifiedFrom: 'Date Modified After',
  dateLastModifiedTo: 'Date Modified Before',
  dateIssuedFrom: 'Date Issued After',
  dateIssuedTo: 'Date Issued Before',
  dateFulfilledFrom: 'Date Fulfilled After',
  dateFulfilledTo: 'Date Fulfilled Before',
  deleted: 'Deleted',
};

export const createDisplayValueMap = (
  vendors: Vendor[],
  carriers: Carrier[],
  paymentTerms: PaymentTerm[],
  shippingTerms: ShippingTerm[],
  locations: Location[],
  buyer: (User | UserV2)[],
  format: string
) => ({
  vendorId: (id: number) =>
    _.get(
      vendors.find((v) => v.id === id),
      'name',
      null
    ),
  carrierId: (id: number) =>
    _.get(
      carriers.find((c) => c.id === id),
      'name',
      null
    ),
  paymentTermId: (id: number) =>
    _.get(
      paymentTerms.find((p) => p.id === id),
      'name',
      null
    ),
  shippingTermId: (id: number) =>
    _.get(
      shippingTerms.find((s) => s.id === id),
      'name',
      null
    ),
  locationId: (id: number) =>
    _.get(
      locations.find((l) => l.id === id),
      'name',
      null
    ),
  buyerId: (id: number) =>
    _.get(
      buyer.find((u) => u.id === id),
      'firstName',
      null
    ),
  dateScheduledFrom: (date: string) =>
    date ? moment(date).format(format) : '',
  dateScheduledTo: (date: string) => (date ? moment(date).format(format) : ''),
  dateCreatedFrom: (date: string) => (date ? moment(date).format(format) : ''),
  dateCreatedTo: (date: string) => (date ? moment(date).format(format) : ''),
  dateLastModifiedFrom: (date: string) =>
    date ? moment(date).format(format) : '',
  dateLastModifiedTo: (date: string) =>
    date ? moment(date).format(format) : '',
  dateIssuedFrom: (date: string) => (date ? moment(date).format(format) : ''),
  dateIssuedTo: (date: string) => (date ? moment(date).format(format) : ''),
  dateFulfilledFrom: (date: string) =>
    date ? moment(date).format(format) : '',
  dateFulfilledTo: (date: string) => (date ? moment(date).format(format) : ''),
  purchaseOrderStatus: (status: string) => status.split(',').join(', '),
});
