import { IconNames } from 'ui/theme';
import { MenuItem } from 'ui/components/Menu';
import { PermissionType } from 'services/permissions';
import { SalesOrderStatus } from 'services/salesOrders';

export const createCustomShipToActionItems = (
  onOptionClick: any,
  status: SalesOrderStatus | null
): MenuItem[] => [
  {
    label: 'Same As Billing',
    icon: IconNames.Copy,
    onOptionClick,
    permissions: [
      PermissionType.SalesOrderEdit,
      PermissionType.SalesOrderCreate,
    ],
    disabled: status === SalesOrderStatus.Fulfilled,
  },
];
