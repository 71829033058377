import {
  createApiReducer,
  chainReducers,
  INITIAL_API_STATUS,
} from 'services/api';

import { combineReducers } from 'redux';

import { TrackingTypesActionTypes } from './actions';
import { transformTrackingType } from '../transformations';
import { TrackingType } from '../types';

const initialState = {
  _status: INITIAL_API_STATUS,
  items: [],
};

const fetchTrackingTypesReducer = createApiReducer(
  [
    TrackingTypesActionTypes.FETCH_TRACKING_TYPES_START,
    TrackingTypesActionTypes.FETCH_TRACKING_TYPES_SUCCESS,
    TrackingTypesActionTypes.FETCH_TRACKING_TYPES_FAILURE,
  ],
  (data) => ({ items: data.results.map(transformTrackingType) }),
  (data) => data
);

const postTrackingTypesReducer = createApiReducer(
  [
    TrackingTypesActionTypes.POST_TRACKING_TYPES_START,
    TrackingTypesActionTypes.POST_TRACKING_TYPES_SUCCESS,
    TrackingTypesActionTypes.POST_TRACKING_TYPES_FAILURE,
  ],
  (data, state) => ({ items: [...state.items, transformTrackingType(data)] }),
  (data) => data
);

const putTrackingTypesReducer = createApiReducer(
  [
    TrackingTypesActionTypes.PUT_TRACKING_TYPES_START,
    TrackingTypesActionTypes.PUT_TRACKING_TYPES_SUCCESS,
    TrackingTypesActionTypes.PUT_TRACKING_TYPES_FAILURE,
  ],
  (data, state) => {
    const trackingTypes = state.items;
    const newTrackingType = transformTrackingType(data);

    const index = trackingTypes.findIndex(
      (t: TrackingType) => t.id === newTrackingType.id
    );
    const newTrackingTypes = [
      ...trackingTypes.slice(0, index),
      newTrackingType,
      ...trackingTypes.slice(index + 1, trackingTypes.length),
    ];

    return { items: newTrackingTypes };
  },
  (data) => data
);

const deleteTrackingTypesReducer = createApiReducer(
  [
    TrackingTypesActionTypes.DELETE_TRACKING_TYPES_START,
    TrackingTypesActionTypes.DELETE_TRACKING_TYPES_SUCCESS,
    TrackingTypesActionTypes.DELETE_TRACKING_TYPES_FAILURE,
  ],
  (data, state) => ({
    items: state.items.filter(
      (t: TrackingType) => !data.deletedIds.includes(t.id)
    ),
  }),
  (data) => data
);

const trackingChainedReducers = chainReducers(
  initialState,
  fetchTrackingTypesReducer,
  postTrackingTypesReducer,
  putTrackingTypesReducer,
  deleteTrackingTypesReducer
);

export default combineReducers({
  trackingTypes: trackingChainedReducers,
});
