import { createApiReducer, chainReducers } from 'services/api';

import { initialShippingIntegration } from '../consts';
import { ShippingIntegrationActionTypes } from './actions';

const fetchShippingIntegrationConnectionsReducer = createApiReducer(
  [
    ShippingIntegrationActionTypes.FETCH_SHIPPING_INTEGRATION_CONNECTIONS_START,
    ShippingIntegrationActionTypes.FETCH_SHIPPING_INTEGRATION_CONNECTIONS_SUCCESS,
    ShippingIntegrationActionTypes.FETCH_SHIPPING_INTEGRATION_CONNECTIONS_FAILURE,
  ],
  (data) => ({ connections: data }),
  (data) => data
);

const shippingIntegrationsChainedReducers = chainReducers(
  initialShippingIntegration,
  fetchShippingIntegrationConnectionsReducer
);

export default shippingIntegrationsChainedReducers;
