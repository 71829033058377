export type InitialThemeStateType = {
  theme: ThemeStyle;
  tableDensity: ThemeTableDensity;
  fontSizeMultiplier: number | null;
};

export enum ThemeStyle {
  Dark = 'dark',
  Light = 'light',
  Test = 'test',
}

export enum ThemeTableDensity {
  Small = 'small',
  Medium = 'medium',
}

export const initialThemeState: InitialThemeStateType = {
  theme: ThemeStyle.Light,
  tableDensity: ThemeTableDensity.Small,
  fontSizeMultiplier: 1,
};

export interface SnackbarProps extends React.HTMLAttributes<HTMLDivElement> {
  'data-qa': string;
}
