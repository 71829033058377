import React, { memo } from 'react';
import { Box, Paper } from '@mui/material';
import { ItemsTable } from 'ui/components/Table/ItemsTable';

import { HistoryCardRow } from './components';
import { HistoryCardProps } from './types';
import { useHistoryCardStyle } from './styled';
import { SHIPPING_HISTORY_COLUMNS } from './consts';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';

const HistoryCard: React.FC<HistoryCardProps> = (props) => {
  const { history } = props;
  const classes = useHistoryCardStyle();

  return (
    <Box className={classes.container}>
      <Paper id="history" className={classes.paper}>
        <FBOTitleBar title="Shipping Event History"></FBOTitleBar>

        <ItemsTable
          data={history}
          columns={SHIPPING_HISTORY_COLUMNS}
          selectableItems={false}
          emptyTableText="No history data"
          RenderCustomRow={HistoryCardRow}
          containerHeight={378}
          tableLayoutFixed
        />
      </Paper>
    </Box>
  );
};

export default memo(HistoryCard);
