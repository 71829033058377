import React from 'react';
import { StatusLabelProps } from './types';
import { colorStatusPaletteMapRestyled } from 'ui/theme/colors/statusColors';

import FBOTag from 'ui/theme/components/FBOTag/FBOTag';

const StatusLabel: React.FC<StatusLabelProps> = (props) => {
  const { status, label, combineStatusLabel = false, style } = props;

  const restyleColors =
    status && colorStatusPaletteMapRestyled[status]
      ? colorStatusPaletteMapRestyled[status]
      : { background: 'transparent', text: 'transparent' };

  return (
    <FBOTag
      label={combineStatusLabel ? label : status?.toString()}
      bgColor={restyleColors?.background}
      color={restyleColors?.text}
      data-qa="item-status"
      style={style}
    />
  );
};

export default React.memo(StatusLabel);
