import { Dates } from 'helpers';
import { PermissionType } from 'services/permissions';
import { MenuItem } from 'ui/components/Menu';
import { IconNames } from 'ui/theme';

export enum LocationsPageAction {
  Import,
  Export,
  ChangeDate,
}

export const createLocationsSearchActionBarOptions = (
  onActionClick: (actionType: LocationsPageAction, date?: Dates) => void,
  locationsCount: number,
  csvDisabled?: boolean
): MenuItem[] => [
  {
    label: 'Import Locations',
    icon: IconNames.FBOImport,
    iconRight: IconNames.FBOQuestionMarkInfo,
    iconRightHref:
      'https://fishbowlonline.zendesk.com/hc/en-us/articles/4402698381975#Import_location',
    onOptionClick: () => onActionClick(LocationsPageAction.Import),
    permissions: [PermissionType.LocationsCreate, PermissionType.LocationEdit],
    separateAfter: true,
    disabled: csvDisabled,
  },
  {
    label: 'Export Locations',
    icon: IconNames.FBOExport,
    onOptionClick: () => onActionClick(LocationsPageAction.Export),
    disabled: !locationsCount || csvDisabled,
    separateAfter: true,
  },
  {
    icon: IconNames.FBORecycleSmall,
    label: 'Change Dates',
    subItems: [
      {
        label: 'Date Created',
        onOptionClick: () =>
          onActionClick(LocationsPageAction.ChangeDate, Dates.DateCreated),
        dataQa: 'date-created',
        separateAfter: true,
      },
      {
        label: 'Date Last Modified',
        onOptionClick: () =>
          onActionClick(LocationsPageAction.ChangeDate, Dates.DateLastModified),
        dataQa: 'date-last-modified',
      },
    ],
  },
];
