import { DateOption } from './types';

export const DateOptions: DateOption[] = [
  { value: 'Open', label: 'Shipment Created' },
  { value: 'Shipped', label: 'Shipment Fulfilled' },
];

export const ItemTypeOptions = [
  'Sale',
  'Credit Return',
  'Misc. Sale',
  'Misc. Credit',
  'Note',
  'Flat Rate Tax',
  'Drop Ship',
];
