import {
  DefaultOptions,
  NotesOptions,
  QtyOptions,
} from 'services/commerce/settings';

import { RowDescriptor, RowType } from '../Card';

export const globalSettingsCardId = 'product-export-card-navigation-id';

export const title = 'Settings';

export const globalSettingsRowDescriptor: RowDescriptor[] = [
  {
    type: RowType.Text,
    title: 'Product Export',
    dataQa: 'header-product-export',
  },
  {
    type: RowType.Mapping,
    title: 'Master SKU',
    field: 'masterSku',
    options: Object.values(DefaultOptions),
    dataQa: 'master-sku',
  },
  {
    type: RowType.Mapping,
    title: 'Product Title',
    field: 'productTitle',
    options: Object.values(DefaultOptions),
    dataQa: 'product-title',
  },
  {
    type: RowType.Mapping,
    title: 'UPC',
    field: 'upc',
    options: Object.values(DefaultOptions),
    dataQa: 'upc',
  },
  {
    type: RowType.Mapping,
    title: 'Short Description',
    field: 'shortDescription',
    options: Object.values(NotesOptions),
    dataQa: 'short-description',
  },
  {
    type: RowType.Mapping,
    title: 'Long Description',
    field: 'longDescription',
    options: Object.values(NotesOptions),
    dataQa: 'long-description',
  },
  {
    type: RowType.Tag,
    title: 'Tags',
    field: 'limitTags',
    dataQa: 'tags',
  },
  {
    type: RowType.Text,
    title: 'Inventory Export',
    dataQa: 'header-inventory-export',
  },
  {
    type: RowType.Mapping,
    title: 'Quantity Type',
    field: 'quantityType',
    options: Object.values(QtyOptions),
    dataQa: 'qty-type',
  },
  {
    type: RowType.LocationPicker,
    title: 'Locations',
    field: 'inventoryWarehousesEnabled',
    dataQa: 'locations',
  },
];
export const isTextFieldRequired = (title: string) => {
  const requiredFields = ['Master SKU', 'Product Title', 'Quantity Type'];
  return requiredFields.includes(title);
};
