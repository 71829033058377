import { makeStyles } from '@mui/styles';

export const useSalesOrderRowSmallStyle = makeStyles(() => ({
  sidebarBox: {
    maxWidth: 100,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));
