import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { TableCell, Typography, Radio } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';

import { useCurrencyFormatter, useGetIntlDateFormatString } from 'helpers';
import { VendorItem } from 'services/vendors';
import { getUomById } from 'services/uoms';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';

const VendorItemRow: React.FC<RenderCustomRowProps<VendorItem>> = (props) => {
  const { row } = props;

  const currencyFormatterSix = useCurrencyFormatter(2, 6);
  const intlFormatDate = useGetIntlDateFormatString();

  const defaultUom = useSelector(getUomById(row.defaultUomId));

  return (
    <>
      <TableCell data-qa={`Name - ${row.name}`}>
        <Typography>{row.name}</Typography>
      </TableCell>
      <TableCell data-qa={`Vendor - ${row.name}`}>
        <Typography>{_.get(row, 'vendor.name', null)}</Typography>
      </TableCell>
      <TableCell data-qa={`Cost - ${row.name}`}>
        <Typography>
          {currencyFormatterSix(row.cost || 0)} /
          {defaultUom && defaultUom.abbreviation}
        </Typography>
      </TableCell>
      <TableCell data-qa={`Last-Date - ${row.name}`}>
        <Typography>
          {row.dateLastModified
            ? moment(row.dateLastModified).format(intlFormatDate)
            : ''}
        </Typography>
      </TableCell>
      <TableCell data-qa={`Lead-Time - ${row.name}`}>
        <Typography>{row.leadTime}</Typography>
      </TableCell>
      <TableCell data-qa={`Minimum-Quantity - ${row.name}`}>
        <Typography>{row.minimumQuantity}</Typography>
      </TableCell>
      <TableCell data-qa={`Default - ${row.name}`}>
        <Radio checked={row.defaultFlag} size="small" disabled />
      </TableCell>
    </>
  );
};

export default memo(VendorItemRow);
