import React from 'react';
import { Link } from 'react-router-dom';
import { ErrorMessageProps } from './types';
import { useErrorMessageStyles } from './styled';
import { Grid, Typography } from '@mui/material';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

const defaultTitle = 'Uh oh! Looks Like something went wrong.';

const defaultDescription = (
  <p>
    Page Not Loading Temporarily.
    <br /> Please Contact Support.
  </p>
);
const ErrorMessage = (props: ErrorMessageProps) => {
  const styles = useErrorMessageStyles(props);

  return (
    <Grid container justifyContent="center">
      <Grid container className={styles.container}>
        <Grid item xs={2}>
          <ReportProblemOutlinedIcon style={{ fontSize: 100 }} />
        </Grid>
        <Grid item xs={10}>
          <Grid container direction="column" spacing={3}>
            <Grid item xs={12}>
              <Typography className={styles.title}>
                {props.title || defaultTitle}
              </Typography>
              <Typography className={styles.description}>
                {props.description || defaultDescription}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {props.error}
              <Link
                className={styles.link}
                to={{
                  pathname: 'https://help.fishbowlonline.com/s/',
                }}
              >
                Contact Support
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ErrorMessage;
