import moment from 'moment';
import { DefinedRange } from 'materialui-daterange-picker';
import _ from 'lodash';

import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable/types';
import { MenuItem, MenuItemVariant } from 'ui/components/Menu';

import { IconNames } from 'ui/theme';

const REORDER_ITEM_COLUMNS_MANAGE_ALERTS_TITLE = 'Manage Alerts';

export const REORDER_ITEM_COLUMNS: Column[] = [
  {
    title: 'Parent Location',
    width: '25vw',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Daily Consumption Rate',
    width: '15vw',
    align: 'center',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Minimum',
    align: 'center',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Maximum',
    align: 'center',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Alert At',
    align: 'center',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: REORDER_ITEM_COLUMNS_MANAGE_ALERTS_TITLE,
    align: 'center',
    width: '5vw',
    type: ColumnTypes.renderByCustomRow,
  },
];

const REORDER_ITEM_COLUMNS_COPY = _.cloneDeep(REORDER_ITEM_COLUMNS);

export const REORDER_ITEM_COLUMNS_EDIT: Column[] =
  REORDER_ITEM_COLUMNS_COPY.map((columnItem) => {
    columnItem.type = ColumnTypes.string;
    columnItem.field = columnItem.title;

    if (columnItem.title === REORDER_ITEM_COLUMNS_MANAGE_ALERTS_TITLE) {
      columnItem.title = '';
    }

    return columnItem;
  });

export const REORDER_ITEM_COLUMNS_ADD: Column[] = [
  {
    title: 'Parent Location',
    width: '35%',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Daily Consumption Rate',
    width: '20%',
    align: 'center',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Minimum',
    width: '15%',
    align: 'center',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Maximum',
    width: '15%',
    align: 'center',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Alert At',
    width: '15%',
    align: 'center',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: '',
    align: 'center',
    type: ColumnTypes.renderByCustomRow,
  },
];

export const MANAGE_ALERTS_COLUMNS: Column[] = [
  {
    title: 'Name',
    width: 400,
    type: ColumnTypes.renderByCustomRow,
  },

  {
    title: 'Email',
    type: ColumnTypes.renderByCustomRow,
  },

  {
    title: 'Email',
    width: 64,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'In Stock',
    width: 64,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: '',
    width: 64,
    type: ColumnTypes.renderByCustomRow,
  },
];

export const customDateRange: DefinedRange[] = [
  {
    startDate: moment().startOf('week').toDate(),
    endDate: moment().endOf('week').toDate(),
    label: 'This Week',
  },
  {
    startDate: moment().add(-1, 'week').toDate(),
    endDate: moment().toDate(),
    label: 'Last Week',
  },
  {
    startDate: moment().startOf('month').toDate(),
    endDate: moment().endOf('month').toDate(),
    label: 'This Month',
  },
  {
    startDate: moment().startOf('month').add(-1, 'month').toDate(),
    endDate: moment().endOf('month').add(-1, 'month').toDate(),
    label: 'Last Month',
  },
  {
    startDate: moment().startOf('year').toDate(),
    endDate: moment().endOf('year').toDate(),
    label: 'Last Year',
  },
  {
    startDate: moment().add(-1, 'year').toDate(),
    endDate: moment().toDate(),
    label: '1 Year',
  },
  {
    startDate: moment().startOf('year').toDate(),
    endDate: moment().toDate(),
    label: 'Year to Date',
  },
];

export enum ReorderTabTableRowActions {
  Delete = 0,
}

export const createActionBarOptions = (
  onAction: (type: ReorderTabTableRowActions) => void
): MenuItem[] => [
  {
    label: 'Delete',
    onOptionClick: () => onAction(ReorderTabTableRowActions.Delete),
    icon: IconNames.FBODeleteSmall,
    dataQa: 'reorder-point-item-menu-delete',
    variant: MenuItemVariant.Danger,
  },
];
