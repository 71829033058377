import React, { memo, useCallback, useMemo } from 'react';
import { Grid } from '@mui/material';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { DateRange } from 'materialui-daterange-picker';

import { transformDateToMomentDate } from 'helpers';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import { AdvancedSearchFieldsCmpProps } from 'ui/components/Page/PageWithAdvancedSearch';
import DateRangePicker from 'ui/components/TextField/DateRangePicker/DateRangePicker';
import { getRangeForRequest } from 'ui/components/TextField/DateRangePicker/helpers';
import { SaleItemsAutocomplete } from 'ui/components/Autocomplete/SaleItemsAutocomplete';
import { Location } from 'services/locations';
import { ShipOrderType, ShipStatus } from 'services/shipping';
import { Customer, getCustomers } from 'services/customers';
import { getVendors, Vendor } from 'services/vendors';
import { Carrier, CarrierService, getCarriers } from 'services/carriers';
import { SaleItem } from 'services/items/saleItems';

const ShippingAdvancedSearch: React.FC<AdvancedSearchFieldsCmpProps> = (
  props
) => {
  const { setFormValues, formValues } = props;

  const { items: customers } = useSelector(getCustomers);
  const { items: vendors } = useSelector(getVendors);
  const { items: carriers } = useSelector(getCarriers);

  const selectedCarrier = useMemo(
    () => carriers.find((c) => c.id === formValues.carrierId) || null,
    [formValues, carriers]
  );

  const selectedService = useMemo(() => {
    if (!selectedCarrier) {
      return null;
    }
    return (
      selectedCarrier.carrierServiceList.find(
        (s) => s.id === formValues.carrierServiceId
      ) || null
    );
  }, [formValues, selectedCarrier]);

  const selectedCustomer = useMemo(() => {
    return (
      customers.find((c) => c.id === formValues['salesOrder.customerId']) ||
      null
    );
  }, [formValues, customers]);

  const selectedVendor = useMemo(
    () =>
      vendors.find((v) => v.id === formValues['purchaseOrder.vendorId']) ||
      null,
    [formValues, vendors]
  );

  const selectedShippStatuses = useMemo(() => {
    if (!formValues.status) {
      return [];
    }

    return formValues.status.toString().split(',');
  }, [formValues.status]);

  const dateShippedRange: DateRange | null = useMemo(() => {
    if (!formValues.dateShipFrom || !formValues.dateShipTo) {
      return null;
    }
    return {
      startDate: transformDateToMomentDate(formValues.dateShipFrom as string)!,
      endDate: transformDateToMomentDate(formValues.dateShipTo as string)!,
    };
  }, [formValues.dateShipFrom, formValues.dateShipTo]);

  const handleCarrierChange = useCallback(
    (e: React.ChangeEvent<{}>, carrier: Carrier | null) => {
      setFormValues({
        ...formValues,
        carrierId: carrier ? carrier.id : null,
      });
    },
    [setFormValues, formValues]
  );

  const handleCarrierServiceChange = (
    e: React.ChangeEvent<{}>,
    carrierService: CarrierService | null
  ) => {
    setFormValues({
      ...formValues,
      carrierServiceId: carrierService ? carrierService.id : null,
    });
  };

  const handleCustomerChange = useCallback(
    (e: React.ChangeEvent<{}>, value: Customer | null) => {
      setFormValues({
        ...formValues,
        'salesOrder.customerId': value ? value.id : null,
      });
    },
    [formValues, setFormValues]
  );

  const handleVendorChange = useCallback(
    (e: React.ChangeEvent<{}>, vendor: Vendor | null) => {
      setFormValues({
        ...formValues,
        'purchaseOrder.vendorId': vendor ? vendor.id : null,
      });
    },
    [setFormValues, formValues]
  );

  const handleSaleItemChange = useCallback(
    (saleItem: SaleItem | null) => {
      setFormValues({
        ...formValues,
        'shipItems.salesOrderItem.saleItemId': saleItem ? saleItem.id : null,
      });
    },
    [setFormValues, formValues]
  );

  const autocompleteLabelResolver = useCallback(
    (option) => option.displayName || option.value || option,
    []
  );

  const handleOrderTypeChange = useCallback(
    (e: React.ChangeEvent<{}>, value: ShipOrderType | null) => {
      setFormValues({ ...formValues, orderType: value });
    },
    [formValues, setFormValues]
  );

  const handleRootLocationChange = useCallback(
    (v: Location | null) => {
      const id = _.get(v, 'id', null);
      setFormValues({
        ...formValues,
        parentLocationId: id,
      });
    },
    [formValues, setFormValues]
  );

  const handleStatusChange = useCallback(
    (e: React.ChangeEvent<{}>, statuses: ShipStatus[]) => {
      setFormValues({
        ...formValues,
        status: statuses.length ? statuses.join(',') : null,
      });
    },
    [formValues, setFormValues]
  );

  const handleDateRangeChange = useCallback(
    (field: string) => (value: DateRange) => {
      const requestRange = getRangeForRequest(field, value);

      if (!requestRange) {
        return;
      }

      setFormValues({
        ...formValues,
        ...requestRange,
      });
    },
    [formValues, setFormValues]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Autocomplete
          label="Carrier"
          placeholder="Select carrier"
          options={carriers}
          getOptionLabel={(c: Carrier) => c.name || ''}
          value={selectedCarrier}
          onChange={handleCarrierChange}
          dataQa="select-carrier"
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          label="Customer"
          placeholder="Select customer"
          options={customers}
          getOptionLabel={(c: Customer) => c.name || ''}
          value={selectedCustomer}
          onChange={handleCustomerChange}
          dataQa="select-customer"
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          label="Carrier Service"
          name="carrierServiceId"
          value={selectedService}
          placeholder="Select carrier service"
          getOptionLabel={(c: CarrierService) => c.name || ''}
          options={selectedCarrier ? selectedCarrier.carrierServiceList : []}
          onChange={handleCarrierServiceChange}
          dataQa="select-carrier-service"
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          label="Vendor"
          options={vendors}
          value={selectedVendor}
          placeholder="Select vendor"
          getOptionLabel={(v: Vendor) => v.name || ''}
          onChange={handleVendorChange}
          dataQa="select-vendor"
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          className="multi-line"
          placeholder="Select shipping status"
          onChange={handleStatusChange}
          value={selectedShippStatuses}
          label="Status"
          options={Object.values(ShipStatus)}
          multiple
          getOptionLabel={autocompleteLabelResolver}
          dataQa="select-shipping-status"
        />
      </Grid>
      <Grid item xs={6}>
        <LocationsAsyncAutocomplete
          label="Location"
          placeholder="Select location"
          value={formValues.parentLocationId as number}
          onChange={handleRootLocationChange}
          companyWide={false}
          parentId={null}
          dataQa="select-location"
        />
      </Grid>
      <Grid item xs={6}>
        <SaleItemsAutocomplete
          onChange={handleSaleItemChange}
          placeholder="Select sale item"
          label="Sale Item"
          value={
            (formValues['shipItems.salesOrderItem.saleItemId'] || null) as
              | number
              | null
          }
          dataQa="select-sale-item"
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          label="Order Type"
          onChange={handleOrderTypeChange}
          options={Object.values(ShipOrderType)}
          value={formValues.orderType}
          placeholder="Select ship order type"
          dataQa="select-ship-order-type"
        />
      </Grid>
      <Grid item xs={6}>
        <DateRangePicker
          label="Date Shipped"
          placeholder="Select ship range"
          value={dateShippedRange}
          onChange={handleDateRangeChange('dateShip')}
          dataQa="select-ship-range"
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default memo(ShippingAdvancedSearch);
