import { createApiCall } from 'services/api';

import {
  transformCompanySettings,
  transformCompanyPut,
} from './transformations';
import { CompanySettings } from './types';

export const getCompanySettings = async (): Promise<CompanySettings> => {
  const response = await createApiCall({
    path: `/v1/company`,
    method: 'GET',
  })();

  return transformCompanySettings(response.data);
};

export const putCompanySettings = async (
  companySettings: CompanySettings
): Promise<CompanySettings> => {
  const response = await createApiCall(
    {
      path: `/v1/company/multicurrency`,
      method: 'PUT',
      body: transformCompanyPut(companySettings),
    },
    {
      getMessage: {
        success: () => 'Updated company settings',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformCompanySettings(response.data);
};
