import { logErrorCtx } from 'app/logging';
import React, { useCallback } from 'react';
import {
  EditInventoryData,
  EditInventorySerialData,
  editItemsInInventory,
  InventoryEventData,
  itemHasTracking,
  ItemInventoryEvents,
  postInventoryEvent,
  transformItemInventory,
} from 'services/inventory';
import { fetchItem } from 'services/items';

import { InventoryTable } from './components/InventoryTable';
import { InventoryTabProps } from './types';

const InventoryTab: React.FC<InventoryTabProps> = ({
  activeItem,
  itemInventory,
  setActiveItem,
  setItemInventory,
  activeItemId,
  fetchSearchResult,
  duplicateArray,
  setDuplicateArray,
}) => {
  const hasTracking = itemHasTracking(activeItem);

  const handleEditInventoryApply = useCallback(
    async (
      inventoryEditNonSerialTracking: EditInventoryData[],
      inventoryEditSerialTracking: EditInventorySerialData[]
    ) => {
      try {
        let editResponseData = null;
        const editResponse = await editItemsInInventory(
          activeItem.id!,
          inventoryEditNonSerialTracking,
          inventoryEditSerialTracking
        );
        editResponseData = editResponse.data;

        setItemInventory(transformItemInventory(editResponseData));

        if (activeItemId) {
          const item = await fetchItem(activeItemId);
          setActiveItem(item);
        }

        await fetchSearchResult();
      } catch (error) {
        logErrorCtx('Error in editItemsInInventory', {
          error: error as Error,
          stackTrace: (error as Error).stack,
          component: 'InventoryTab',
          title: 'Error in editItemsInInventory',
          description: `Item id ${activeItem.id}`,
        });
      }
    },
    [
      fetchSearchResult,
      activeItem,
      activeItemId,
      setActiveItem,
      setItemInventory,
    ]
  );

  const handleEventInventoryApply = useCallback(
    async (
      eventType: ItemInventoryEvents,
      inventoryEventData: InventoryEventData
    ) => {
      try {
        let postResponseData = null;
        const postResponse = await postInventoryEvent(
          eventType,
          inventoryEventData,
          hasTracking,
          setDuplicateArray
        );
        postResponseData = postResponse.data;
        setItemInventory(transformItemInventory(postResponseData));

        if (activeItemId) {
          const item = await fetchItem(activeItemId);
          setActiveItem(item);
        }

        await fetchSearchResult();
      } catch (error) {
        logErrorCtx('Error in handleEventInventoryApply', {
          error: error as Error,
          stackTrace: (error as Error).stack,
          component: 'InventoryTab',
          title: 'Error in handleEventInventoryApply',
          description: `Item id ${activeItem.id}`,
        });
      }
    },
    [
      fetchSearchResult,
      hasTracking,
      activeItemId,
      setActiveItem,
      setItemInventory,
    ]
  );

  return (
    <InventoryTable
      item={activeItem}
      itemInventory={itemInventory}
      onEditInventoryApply={handleEditInventoryApply}
      onEventInventoryApply={handleEventInventoryApply}
      duplicateArray={duplicateArray}
      setDuplicateArray={setDuplicateArray}
    />
  );
};

export default InventoryTab;
