import React, { memo } from 'react';

import { ImageStorageType } from 'services/images';
import { DocumentsTable } from 'ui/components/Documents/DocumentsTable';

import { ShippingDocumentsTabProps } from './types';

const ShippingDocumentsTab: React.FC<ShippingDocumentsTabProps> = (props) => {
  const { activeShip, setActiveShip } = props;

  return (
    <DocumentsTable
      storageType={ImageStorageType.ShippingDocuments}
      data={activeShip}
      setData={setActiveShip}
    />
  );
};

export default memo(ShippingDocumentsTab);
