import React from 'react';
import { Avatar, Chip } from '@mui/material';
import { Icon } from 'ui/components/Icon';
import { IconNames } from 'ui/theme/icons';
interface FBOTagProps {
  label?: string;
  icon?: IconNames;
  avatar?: { alt: string; src: string };
  bgColor?: string;
  color?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onDelete?: (event: any) => void;
  style?: React.CSSProperties;
  variant?: 'outlined' | 'filled';
}

const FBOTag = (props: FBOTagProps) => {
  const {
    label,
    icon,
    avatar,
    bgColor,
    color,
    onClick,
    onDelete,
    style,
    variant = 'filled',
    ...otherProps
  } = props;

  const resolveIcon = () => {
    if (icon) {
      return <Icon name={icon} />;
    }
    return undefined;
  };

  const resolveDeleteIcon = () => {
    if (onDelete) {
      return <Icon name={IconNames.CloseTag} />;
    }
    return undefined;
  };

  const resolveAvatar = () => {
    if (avatar) {
      return <Avatar alt={avatar.alt} src={avatar.src} />;
    }
    return undefined;
  };

  return (
    <Chip
      className="redesign"
      label={label}
      icon={resolveIcon()}
      deleteIcon={resolveDeleteIcon()}
      avatar={resolveAvatar()}
      sx={{ backgroundColor: bgColor, color: color }}
      onClick={onClick}
      onDelete={onDelete}
      style={{ ...style }}
      variant={variant}
      {...otherProps}
    />
  );
};

export default FBOTag;
