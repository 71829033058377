import _ from 'lodash';

import {
  isLocationAddressValid,
  isLocationNameValid,
} from 'services/addresses/helpers';
import { showNotification } from 'services/api';
import { Carrier } from 'services/carriers';
import { ShippingIntegrationSettings } from 'services/integrations/shipping';
import { Ship, ShipCarton } from 'services/shipping';

export const isInternationalAddress = (
  ship: Ship,
  settings: ShippingIntegrationSettings
): boolean => {
  const notUSAddress =
    (ship.shipToCountry !== 'US' ||
      _.get(ship, 'salesOrder.location.address.country') !== 'US') &&
    !settings.internationalSettings.enabled;
  return notUSAddress;
};

export const isQuickShipAndPurchaseLabelValid = (
  activeShip: Ship,
  shippoSettings: ShippingIntegrationSettings,
  validateShipCartonList: (cartons: ShipCarton[]) => any,
  carriers: Carrier[],
  isQuickShip: boolean,
  isShippo: boolean
): boolean => {
  if (
    activeShip.shipToCountry === 'US' &&
    isShippo &&
    !activeShip.shipToVerified
  ) {
    showNotification('Ship To address should be validated.', {
      variant: 'error',
    });
    return false;
  }

  if (!activeShip.shipToName) {
    showNotification(
      'Order Ship To address is invalid, please check your Ship To Address.',
      {
        variant: 'error',
      }
    );
    return false;
  }

  const validLocation = isLocationAddressValid(activeShip);

  if (!validLocation) {
    showNotification(
      'Order Ship From address is invalid, please input an address for a home location.',
      {
        variant: 'error',
      }
    );
    return false;
  }

  const validLocationName = isLocationNameValid(activeShip);

  if (!validLocationName) {
    showNotification(
      'Order location requires either a name or a company name',
      {
        variant: 'error',
      }
    );
    return false;
  }

  const isInternational = isInternationalAddress(activeShip, shippoSettings);

  if (isInternational) {
    showNotification(
      'International Shipping has been disabled for this account.',
      { variant: 'error' }
    );
    return false;
  }

  const isCartonListInvalid = validateShipCartonList(activeShip.shipCartonList);

  if (isCartonListInvalid) {
    showNotification('Package dimensions and weight are required.', {
      variant: 'error',
    });

    return false;
  }

  if (isQuickShip) {
    if (!activeShip.carrierId) {
      showNotification('Carrier is not selected.', {
        variant: 'error',
      });
      return false;
    }

    if (!activeShip.carrierServiceId) {
      showNotification('Carrier service is not selected.', {
        variant: 'error',
      });
      return false;
    }

    const validCarriers = ['UPS', 'USPS', 'FedEx'];

    if (
      !carriers.find(
        (c) => c.id === activeShip.carrierId && validCarriers.includes(c.name!)
      )
    ) {
      showNotification('Carrier must be UPS, USPS or FedEx.', {
        variant: 'error',
      });
      return false;
    }
  }

  return true;
};
