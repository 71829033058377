import React from 'react';
import { IconButton as MDIconButton, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Icon } from 'ui/components/Icon';
import { SupportColors } from 'ui/theme';

import { IconButtonCmp, IconButtonProps } from './types';
import { useIconButtonStyle } from './styled';
import { useSelector } from 'react-redux';
import { activeUserHasPermission } from 'services/user/redux';

const IconButton: IconButtonCmp = (props: IconButtonProps) => {
  const theme = useTheme();
  const classes = useIconButtonStyle(props);

  const {
    icon,
    text,
    disabled,
    fill,
    iconSize,
    onDark,
    permissions = [],
    boxStyle,
    startIcon,
    ...otherProps
  } = props;

  const canClick = useSelector(activeUserHasPermission(permissions));

  const isDisabled = !!disabled || !canClick;

  const fillOnBackground = onDark
    ? SupportColors.iconOnDark
    : SupportColors.iconOnLight;
  const resolvedFill = fill || fillOnBackground;

  return (
    <MDIconButton
      disabled={isDisabled}
      {...otherProps}
      className={boxStyle ? classes.button : ''}
    >
      <Box
        display="flex"
        flexDirection="row-reverse"
        alignItems="center"
        alignContent="center"
        component="span"
      >
        {icon && (
          <Icon
            name={icon}
            height={iconSize || 22}
            width={iconSize || 22}
            fill={!isDisabled ? resolvedFill : theme.palette.text.disabled}
          />
        )}
        {text && (
          <Typography variant="button" className={classes.label}>
            {text}
          </Typography>
        )}
        {startIcon && (
          <Icon
            name={startIcon}
            height={iconSize || 22}
            width={iconSize || 22}
            fill={!isDisabled ? resolvedFill : theme.palette.text.disabled}
          />
        )}
      </Box>
    </MDIconButton>
  );
};

export default IconButton;
