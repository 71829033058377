import moment from 'moment';

import {
  getSearches,
  addSearch,
  updateSearch,
  deleteSearch,
} from 'services/items';
import {
  AdvancedSearchFormValues,
  AdvancedSearchReduxFunctions,
} from 'services/search';
import { Tag } from 'services/tags';
import { PaginationDirection } from 'services/url';

export const advancedSearchReduxActions: AdvancedSearchReduxFunctions = {
  add: addSearch,
  delete: deleteSearch,
  update: updateSearch,
  get: getSearches,
};

export const displayNameMap = {
  name: 'Name',
  upc: 'Barcode / UPC',
  sku: 'SKU',
  description: 'Description',
  'tags.id': 'Tag',
  details: 'Details',
  'saleItems.name': 'Sale Item Name',
  'saleItems.description': 'Sale Item Description',
  'saleItems.details': 'Sale Item Details',
  'saleItems.sku': 'Sale Item SKU',
  itemType: 'Item Type',
  dateCreatedFrom: 'Date Created After',
  dateCreatedTo: 'Date Created Before',
  dateLastModifiedFrom: 'Date Modified After',
  dateLastModifiedTo: 'Date Modified Before',
  deleted: 'Deleted',
};

export const createDisplayValueMap = (format: string, tags: Tag[]) => {
  return {
    dateCreatedFrom: (date: string) =>
      date ? moment(date).format(format) : '',
    dateCreatedTo: (date: string) => (date ? moment(date).format(format) : ''),
    dateLastModifiedFrom: (date: string) =>
      date ? moment(date).format(format) : '',
    dateLastModifiedTo: (date: string) =>
      date ? moment(date).format(format) : '',
    'tags.id': () => tags.map((t) => t.name).join(', '),
  };
};

export const initialItemsFormValues: AdvancedSearchFormValues = {
  name: null,
  upc: null,
  sku: null,
  description: null,
  itemType: null,
  'tags.id': null,
  details: null,
  'saleItems.name': null,
  'saleItems.description': null,
  'saleItems.details': null,
  'saleItems.sku': null,
  dateCreatedFrom: null,
  dateCreatedTo: null,
  dateLastModifiedFrom: null,
  dateLastModifiedTo: null,
  deleted: null,
};

export const initialPagination = {
  page: 1,
  pageSize: 25,
  sort: {
    sortBy: 'name',
    direction: PaginationDirection.Ascending,
  },
  totalRows: 25,
};
