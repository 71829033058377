import { TableCell, Typography } from '@mui/material';
import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { MultiCurrencyWrapper } from 'ui/components/MultiCurrencyWrapper/MultiCurrencyWrapper';
import { getSettingsCompany } from 'services/settings/company';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import { IconItemBox } from 'ui/components/Icon/IconItemBox';
import { IconNames } from 'ui/theme';
import { useCurrencyFormatter } from 'helpers';
import { ReconcileExpense } from 'services/receiving';

import { useReconcileWizardExpenseslStyle } from './styled';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const ReconcileWizardExpensesRow: React.FC<
  RenderCustomRowProps<ReconcileExpense>
> = (props) => {
  const { row, onAction, index } = props;

  const currencyFormatterSix = useCurrencyFormatter(2, 6);
  const currencyFormatter = useCurrencyFormatter();
  const activeMulticurrencyCode = _.get(row, 'currency.code', null);

  const { homeCurrency } = useSelector(getSettingsCompany);

  const homeCurrencyCode = (homeCurrency && homeCurrency.code) || 'USD';

  const classes = useReconcileWizardExpenseslStyle();

  const total = (row.cost || 0) * (row.quantity || 0);
  const multicurrencyTotal = (row.multiCurrencyCost || 0) * (row.quantity || 0);

  const handleDelete = useCallback(() => {
    if (onAction) {
      onAction(index);
    }
  }, [onAction, index]);

  return (
    <>
      <TableCell>
        <IconItemBox
          source={IconNames.AttachMoney}
          name={row.name}
          description={row.description}
        />
      </TableCell>
      <TableCell className={classes.widthPrice}>
        <Typography>{row.addToVendorBill ? 'Added' : ''}</Typography>
      </TableCell>
      <TableCell className={classes.widthAmount}>
        <Typography>
          {row.quantity + ' ' + (row.abbreviation || 'ea')}
        </Typography>
      </TableCell>
      <TableCell className={classes.widthPrice}>
        <Typography>
          {currencyFormatterSix(row.cost || 0, homeCurrencyCode)}
        </Typography>
      </TableCell>
      <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
        <TableCell className={classes.widthPrice}>
          <Typography>
            {currencyFormatterSix(
              row.multiCurrencyCost || 0,
              activeMulticurrencyCode
            )}
          </Typography>
        </TableCell>
      </MultiCurrencyWrapper>
      <TableCell className={classes.widthPrice}>
        <Typography>
          {currencyFormatter(total || 0, homeCurrencyCode)}
        </Typography>
      </TableCell>

      <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
        <TableCell className={classes.widthPrice}>
          <Typography>
            {currencyFormatter(
              multicurrencyTotal || 0,
              activeMulticurrencyCode
            )}
          </Typography>
        </TableCell>
      </MultiCurrencyWrapper>
      <TableCell className={classes.widthIconWidth}>
        <FBOButton
          variant="tertiary"
          color="negative"
          size="medium"
          icon={IconNames.TrashCan}
          onClick={handleDelete}
          data-qa={'delete-expense-button'}
        />
      </TableCell>
    </>
  );
};

export default memo(ReconcileWizardExpensesRow);
