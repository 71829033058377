import React from 'react';

import { Box } from '@mui/material';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

export const FBOItemDialogActions = (
  handleActionClicked: (confirmed?: boolean) => () => Promise<void>,
  setShowConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>
) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      width="100%"
      pl={2}
      pr={2}
    >
      <Box>
        <FBOButton
          variant="secondary"
          color="positive"
          size="medium"
          onClick={() => setShowConfirmationModal(false)}
          data-qa="confirmation-modal-cancel"
        >
          Cancel
        </FBOButton>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <FBOButton
          variant="secondary"
          color="positive"
          size="medium"
          onClick={handleActionClicked(false)}
          data-qa="confirmation-modal-negate"
        >
          No
        </FBOButton>
        <FBOButton
          variant="primary"
          color="positive"
          size="medium"
          onClick={handleActionClicked(true)}
          data-qa="confirmation-modal-confirm"
          sx={{ marginLeft: '8px' }}
        >
          Yes
        </FBOButton>
      </Box>
    </Box>
  );
};
