import React, { memo } from 'react';
import { Box, Typography, Grid } from '@mui/material';

import { InvoiceDetails } from 'services/invoices';
import { useCurrencyFormatter } from 'helpers';
import { RenderCustomFooterProps } from 'ui/components/Table/ItemsTable';
import { colorPalette } from 'ui/theme';
import FBOAutocompleteReadOnly from 'ui/theme/components/FBOAutocompleteReadOnly/FBOAutocompleteReadOnly';

export interface InvoiceDetailsItemFooterProps {
  invoiceDetails: InvoiceDetails;
}

const InvoiceDetailsItemFooter = (
  props: RenderCustomFooterProps<InvoiceDetailsItemFooterProps>
) => {
  const { footerData } = props;

  const invoiceDetails = footerData?.invoiceDetails;

  const currencyFormatter = useCurrencyFormatter();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      height="100%"
      px={5}
    >
      <Grid container justifyContent={'space-between'}>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex' }}>
            <FBOAutocompleteReadOnly
              aria-label="Class"
              label="Class"
              name="class"
              value={invoiceDetails?.orderClass?.name ?? null}
              dataQa="class-disabled-autocomplete"
              style={{
                marginRight: '16px',
                maxWidth: '200px',
              }}
            />

            <FBOAutocompleteReadOnly
              aria-label="Tax"
              label="Tax"
              name="tax"
              value={invoiceDetails?.orderTax?.name ?? null}
              dataQa="order-tax-disabled-autocomplete"
              style={{
                maxWidth: '200px',
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          container
          xs={5}
          justifyContent="flex-end"
          alignItems="center"
          wrap="nowrap"
        >
          <Typography data-qa="order-level-discount">
            Discount:{' '}
            <span
              style={{
                marginLeft: '8px',
                marginRight: '8px',
                fontWeight: 600,
                color: colorPalette.redesign.contentSecondary,
              }}
            >
              {currencyFormatter(invoiceDetails?.orderLevelDiscount ?? 0)}
            </span>
          </Typography>

          <Typography data-qa="sale-order-total" sx={{ marginLeft: '16px' }}>
            Total:{' '}
            <span
              style={{
                fontWeight: 600,
                marginLeft: '8px',
                marginRight: '8px',
              }}
            >
              {currencyFormatter(invoiceDetails?.total ?? 0)}
            </span>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(InvoiceDetailsItemFooter);
