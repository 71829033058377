import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { SupportColors } from 'ui/theme';

export const useReceiveWizardRowStyle = makeStyles((theme: Theme) => ({
  firstColumnWidth: {
    width: '70%',
  },
  checkIcon: {
    marginRight: theme.spacing(2.25),
  },
  checkIconSuccess: {
    color: SupportColors.statusGreen,
  },
  checkIconPartial: {
    color: SupportColors.statusOrange,
  },
}));
