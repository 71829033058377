import { DiscountTypes } from 'services/salesOrders';
import { EACH_UOM_ID } from 'services/uoms';

import {
  ItemType,
  ShippingTerm,
  Fob,
  DimensionUnit,
  WeightUnit,
  Item,
  ItemLocation,
  ItemReorderPoint,
  ItemReorderPointsAlert,
  SubstituteItem,
  BundleItem,
} from './types';

export const WEIGHT_UNITS: WeightUnit[] = [
  { name: 'Pounds', shortName: 'lb' },
  { name: 'Ounces', shortName: 'oz' },
  { name: 'Tons', shortName: 't' },
  { name: 'Kilograms', shortName: 'kg' },
  { name: 'Grams', shortName: 'g' },
];

export const DIMENSIONS_UNITS: DimensionUnit[] = [
  { name: 'Inches', shortName: 'in' },
  { name: 'Feet', shortName: 'ft' },
  { name: 'Yards', shortName: 'yd' },
  { name: 'Millimeters', shortName: 'mm' },
  { name: 'Centimeters', shortName: 'cm' },
  { name: 'Meters', shortName: 'm' },
];

export const SHIPPING_TERMS: ShippingTerm[] = [
  {
    id: 1,
    name: 'Prepaid & Billed',
  },
  {
    id: 2,
    name: 'Prepaid',
  },
  {
    id: 3,
    name: 'Freight Collect',
  },
];

export const FOBS: Fob[] = [
  {
    id: 1,
    name: 'Destination',
    dateCreated: null,
    dateLastModified: null,
    deleted: false,
    readOnly: false,
    version: null,
  },
  {
    id: 2,
    name: 'Origin',
    dateCreated: null,
    dateLastModified: null,
    deleted: false,
    readOnly: false,
    version: null,
  },
  {
    id: 3,
    name: 'Port of Entry',
    dateCreated: null,
    dateLastModified: null,
    deleted: false,
    readOnly: false,
    version: null,
  },
];

export const initialItem: Item = {
  id: -1,
  accountingClassId: null,
  adjustmentAccountId: null,
  alertNotes: null,
  assetAccountId: null,
  bundleItems: [],
  cost: 0,
  price: 0,
  costOfGoodsSoldAccountId: null,
  customFields: [],
  dateCreated: null,
  dateLastModified: null,
  defaultImage: null,
  defaultUom: null,
  defaultUomId: EACH_UOM_ID,
  deleted: false,
  description: null,
  details: null,
  dimensionUnit: null,
  documents: [],
  height: 0,
  imageLinkList: [],
  inventoryLocationSummaryList: [],
  itemTrackingTypeList: [],
  itemType: ItemType.Inventory,
  itemUomConversionList: [],
  length: 0,
  locationList: [],
  name: null,
  reorderPointsList: [],
  saleItemList: [],
  salesTax: null,
  salesTaxId: null,
  scrapAccountId: null,
  sku: null,
  substituteItems: [],
  itemSubstitutes: [],
  tags: [],
  taxable: false,
  totalQty: null,
  upc: null,
  url: null,
  vendorId: null,
  vendorItems: [],
  version: null,
  weight: 0,
  weightUnit: null,
  width: 0,
};

export const initialItemLocation: ItemLocation = {
  id: null,
  version: null,
  itemId: null,
  parentLocation: null,
  parentLocationId: null,
  pickFromLocation: null,
  pickFromLocationId: null,
  pickToLocation: null,
  pickToLocationId: null,
  receiveLocation: null,
  receiveLocationId: null,
  deleted: null,
};

export const initialItemReorderPoint: ItemReorderPoint = {
  id: null,
  version: null,
  min: null,
  max: null,
  alert: null,
  itemId: null,
  locationId: null,
  location: null,
  dailyConsumptionRate: null,
  reorderPointAlerts: [],
  deleted: false,
};

export const initialItemReorderPointsAlert: ItemReorderPointsAlert = {
  id: null,
  reorderPointId: null,
  version: null,
  contactName: null,
  email: null,
  receiveEmailNotifications: false,
  receiveInStockNotifications: false,
  deleted: false,
};

export const initialSubstituteItem: SubstituteItem = {
  id: -1,
  createdBy: null,
  createdById: null,
  dateCreated: null,
  dateLastModified: null,
  deleted: false,
  itemId: null,
  lastModifiedBy: null,
  lastModifiedById: null,
  substituteItem: null,
  substituteItemId: null,
  version: null,
  checkVersion: true,
};

export const initialBundleItem: BundleItem = {
  id: null,
  itemId: null,
  saleItemId: null,
  saleItem: null,
  quantity: 1,
  price: null,
  discountFlatAmount: null,
  discountPercent: null,
  discountType: DiscountTypes.Percent,
  version: null,
  deleted: false,
};
