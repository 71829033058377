import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { Grid } from '@mui/material';

import { Errors, validateYup } from 'services/forms/validation';
import { useHandleTextFieldChange } from 'services/forms';
import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import { getPaymentTerms, PaymentTerm } from 'services/paymentTerms';
import { CurrencyAutocomplete } from '../CurrencyAutocomplete';
import { Currency } from 'services/currencies';
import {
  createVendor,
  fetchVendors,
  initialVendor,
  Vendor,
} from 'services/vendors';

import { Autocomplete } from '../Autocomplete';
import { NewVendorModalProps } from './types';
import { getSettingsCompany } from 'services/settings/company';

const yupSchema = yup.object().shape({
  name: yup.string(),
  paymentTermId: yup.number(),
});

const NewVendorModal: React.FC<NewVendorModalProps> = (props) => {
  const { show, newVendorName, onClose, onSave } = props;
  const useMultiCurrencyEnabled =
    useSelector(getSettingsCompany).useMultiCurrency;
  const dispatch = useDispatch();

  const { items: paymentTerms } = useSelector(getPaymentTerms);

  const [formVendor, setFormVendor] = useState<Vendor>(initialVendor);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Errors>({});

  const selectedPaymentTerm = useMemo(() => {
    return paymentTerms.find((p) => p.id === formVendor.paymentTermId) || null;
  }, [paymentTerms, formVendor]);

  const handlePaymentTermChange = useCallback(
    (e: any, v: PaymentTerm | null) => {
      setFormVendor((old) => ({ ...old, paymentTermId: v ? v.id : null }));
    },
    [setFormVendor]
  );

  useEffect(() => {
    setFormVendor({
      ...initialVendor,
      name: newVendorName,
    });
    setErrors({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const handleCurrencyChange = useCallback(
    (currency: Currency | null) => {
      setFormVendor((old) => ({
        ...old,
        currencyId: currency ? currency.id : null,
        currency: currency,
      }));
    },
    [setFormVendor]
  );

  const handleApplyModal = async () => {
    if (!validateYup(formVendor, yupSchema, setErrors)) {
      return;
    }

    setIsLoading(true);

    try {
      const newVendor = await createVendor(formVendor);
      // Lint skip to be removed
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(fetchVendors());
      onSave(newVendor);
    } catch {
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
    setFormVendor(initialVendor);
  };

  const handleTextFieldChange = useHandleTextFieldChange(
    setFormVendor,
    formVendor
  );

  return (
    <Modal
      open={show}
      title="New Vendor"
      onCancelClicked={onClose}
      onApplyClicked={handleApplyModal}
      isLoadingContent={isLoading}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            className="redesign"
            variant="standard"
            name="name"
            label="Name"
            value={formVendor.name}
            onChange={handleTextFieldChange}
            required
            error={!!errors.name}
            dataQa="vendor-name"
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            onChange={handlePaymentTermChange}
            value={selectedPaymentTerm}
            label="Payment Terms"
            options={paymentTerms}
            dataQa="vendor-payment-terms"
            getOptionLabel={(paymentTerm: PaymentTerm) =>
              paymentTerm.name || ''
            }
            error={!!errors.paymentTermid}
            required
          />
        </Grid>
        {useMultiCurrencyEnabled ? (
          <Grid item xs={6}>
            <CurrencyAutocomplete
              label="Currency"
              placeholder="Select currency"
              value={formVendor.currency}
              onChange={handleCurrencyChange}
            />
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Modal>
  );
};

export default memo(NewVendorModal);
