import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';

import { CardRowProps } from './types';
import { RowDescriptor } from '../../types';

export const useCardRowStyle = makeStyles<Theme, RowDescriptor & CardRowProps>(
  (theme: Theme) => {
    return {
      rowContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 56,
        padding: theme.spacing(2),
      },
      locationsRowContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        // minHeight: 100,
        padding: theme.spacing(2),
      },
      text: {
        color: (props) =>
          _.get(props.data, props.field || '')
            ? theme.palette.text.primary
            : theme.palette.text.disabled,
        width: '40%',
        display: 'flex',
        justifyContent: 'flex-end',
      },
      locationText: {
        color: theme.palette.text.disabled,
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        paddingTop: '16px',
      },
      titleContainer: {
        width: '40%',
      },
    };
  }
);
