import React, { memo, useMemo } from 'react';
import { TableCell } from '@mui/material';
import moment from 'moment';
import _ from 'lodash';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { DateMessage } from 'ui/components/DateTooltip/types';
import { DateTooltip } from 'ui/components/DateTooltip';
import { Location } from 'services/locations';
import { useGetIntlDateFormatString } from 'helpers';

const LocationRow: React.FC<RenderCustomRowProps<Location>> = (props) => {
  const { row, columns } = props;

  const intlFormatDate = useGetIntlDateFormatString();

  const itemActive = useMemo(() => columns.length === 1, [columns]);

  const tooltipDates: DateMessage[] = useMemo(
    () => [
      { date: row.dateCreated, message: 'Date Created' },
      { date: row.dateLastModified, message: 'Date Last Modified' },
    ],
    [row]
  );

  const dateValue: string = useMemo(() => {
    const dateField = !itemActive ? columns[3].field! : null;
    const date = dateField ? _.get(row, dateField, null) : null;

    return date ? moment(date).format(intlFormatDate) : '';
  }, [columns, row, itemActive, intlFormatDate]);

  return (
    <>
      <TableCell data-qa={`Name-${row.name}`}>{_.get(row, 'name')}</TableCell>
      {!itemActive && (
        <>
          <TableCell data-qa={`Description-${row.name}`}>
            {_.get(row, 'description')}
          </TableCell>
          <TableCell data-qa={`Path-${row.name}`}>
            {_.get(row, 'path')}
          </TableCell>
          <DateTooltip values={tooltipDates}>
            <TableCell data-qa={`Date-Created-${row.name}`}>
              {dateValue}
            </TableCell>
          </DateTooltip>
        </>
      )}
    </>
  );
};

export default memo(LocationRow);
