import { createApiCall } from 'services/api';

import { transformAddressRequest, transformAddress } from './transformations';
import { Address } from './types';

export const postAddress = async (address: Address): Promise<Address> => {
  const response = await createApiCall(
    {
      path: `/v1/addresses`,
      method: 'POST',
      body: transformAddressRequest(address),
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformAddress(response.data);
};

export const putAddress = async (address: Address): Promise<Address> => {
  const response = await createApiCall(
    {
      path: `/v1/addresses`,
      method: 'PUT',
      body: transformAddressRequest(address),
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformAddress(response.data);
};
