import React, { memo } from 'react';
import { Box, Grid } from '@mui/material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useSelector } from 'react-redux';

import { getShippingIntegrationConnection } from 'services/integrations/shipping/redux';
import { ShippingConnectionType } from 'services/integrations/shipping';

import { useVerifiedButtonStyles } from './styled';

import { VerifiedButtonProps } from './types';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const VerifiedButton = (props: VerifiedButtonProps) => {
  const { shipToAddress, handleVerifyClick, isLoading, marginLeft } = props;

  const classes = useVerifiedButtonStyles();

  const connection = useSelector(
    getShippingIntegrationConnection(ShippingConnectionType.Shippo)
  );

  const verifiedButtonVisible =
    !!connection && shipToAddress.name && shipToAddress.country === 'US';

  return (
    <>
      {verifiedButtonVisible && (
        <Box ml={marginLeft}>
          {!shipToAddress.verified ? (
            <Grid item xs={12}>
              <FBOButton
                variant="secondary"
                color="positive"
                onClick={handleVerifyClick}
                disabled={isLoading}
                fullWidth
                data-qa="verify-address-button"
              >
                Verify
              </FBOButton>
            </Grid>
          ) : (
            <Box display="flex" alignItems="center">
              <VerifiedUserIcon className={classes.icon} />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default memo(VerifiedButton);
