import React, { useMemo } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import _ from 'lodash';

import { MultiCurrencyWrapper } from 'ui/components/MultiCurrencyWrapper/MultiCurrencyWrapper';
import { RenderCustomFooterProps } from 'ui/components/Table/ItemsTable';
import { useCurrencyFormatter, toMulticurrencyCalculate } from 'helpers';

import { ReconcileWizardReconcileSummaryFooterProps } from './types';

const ReconcileWizardItemsFooter: React.FC<
  RenderCustomFooterProps<ReconcileWizardReconcileSummaryFooterProps>
> = (props) => {
  const { footerData } = props;

  const currencyFormatter = useCurrencyFormatter();
  const exchangeRate = _.get(footerData, 'currency.exchangeRate', 1);
  const activeMulticurrencyCode = _.get(footerData, 'currency.code', null);

  const multiCurrencyNotOnBillCost = useMemo(() => {
    const cost = toMulticurrencyCalculate(
      footerData!.notOnBillCost,
      exchangeRate
    );
    return currencyFormatter(cost, activeMulticurrencyCode);
  }, [footerData, exchangeRate, activeMulticurrencyCode, currencyFormatter]);

  const multiCurrencySummaryCost = useMemo(() => {
    const cost = toMulticurrencyCalculate(
      footerData!.summaryCost,
      exchangeRate
    );
    return currencyFormatter(cost, activeMulticurrencyCode);
  }, [footerData, exchangeRate, activeMulticurrencyCode, currencyFormatter]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="end"
      justifyContent="flex-end"
      height="100%"
      px={3}
    >
      {!footerData?.isUSTenant && (
        <Grid
          container
          item
          justifyContent="flex-end"
          direction="row"
          spacing={4}
          xs={12}
          xl={8}
        >
          <Grid item xs={4} xl={3}>
            <Grid item xs={12} xl={12}>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Typography
                  component="div"
                  data-qa="reconcile-wizard-footer-subtotal"
                >
                  Subtotal:{' '}
                  <b>{currencyFormatter(footerData?.subTotalCost || 0)}</b>
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Typography
                  component="div"
                  data-qa="reconcile-wizard-footer-totalTax"
                >
                  Tax: <b>{currencyFormatter(footerData?.totalTax || 0)}</b>
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={4} xl={3}>
            <Grid item xs={12} xl={12}>
              <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
                <Grid item xs={12} xl={12}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Typography
                      component="div"
                      data-qa="reconcile-wizard-footer-multi-currency-subtotal"
                    >
                      Subtotal:{' '}
                      <b>
                        {currencyFormatter(
                          footerData?.multiCurrencySubTotalCost || 0,
                          activeMulticurrencyCode
                        )}
                      </b>
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Typography
                      component="div"
                      data-qa="reconcile-wizard-footer-multi-currency-tax"
                    >
                      Tax:{' '}
                      <b>
                        {currencyFormatter(
                          footerData?.multiCurrencyTotalTax || 0,
                          activeMulticurrencyCode
                        )}
                      </b>
                    </Typography>
                  </Box>
                </Grid>
              </MultiCurrencyWrapper>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid container justifyContent="flex-end" spacing={3}>
        <Grid item>
          <Typography>
            Total not on Bill:{' '}
            <b>{currencyFormatter(footerData!.notOnBillCost)}</b>
          </Typography>
        </Grid>
        <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
          <Grid item>
            <Typography>
              Total not on Bill: <b>{multiCurrencyNotOnBillCost}</b>
            </Typography>
          </Grid>
        </MultiCurrencyWrapper>
        <Grid item>
          <Typography component="div">
            Total Billed: <b>{currencyFormatter(footerData!.summaryCost)}</b>
          </Typography>
        </Grid>
        <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
          <Grid item>
            <Typography component="div">
              Total Billed: <b>{multiCurrencySummaryCost}</b>
            </Typography>
          </Grid>
        </MultiCurrencyWrapper>
      </Grid>
    </Box>
  );
};

export default React.memo(ReconcileWizardItemsFooter);
