import moment from 'moment';
import _ from 'lodash';

import {
  getSearches,
  addSearch,
  updateSearch,
  deleteSearch,
} from 'services/salesOrders';
import {
  AdvancedSearchFormValues,
  AdvancedSearchReduxFunctions,
} from 'services/search';
import { Customer } from 'services/customers';
import { PaginationDirection } from 'services/url';
import { Representative } from 'services/representatives';
import { Carrier } from 'services/carriers';
import { OrderPriority } from 'services/settings/orderPriorities';
import { PaymentTerm } from 'services/paymentTerms';
import { ShippingTerm } from 'services/items';
import { Location } from 'services/locations';
import { Tag } from 'services/tags';

export const initialSalesOrderFormValues: AdvancedSearchFormValues = {
  number: null,
  customerId: null,
  emails: null,
  phone: null,
  representativeId: null,
  carrierId: null,
  billToStreet: null,
  billToCity: null,
  billToState: null,
  billToPostalCode: null,
  billToCountry: null,
  shipToStreet: null,
  shipToCity: null,
  shipToState: null,
  shipToPostalCode: null,
  shiptoCountry: null,
  customerPONumber: null,
  vendorPONumber: null,
  notes: null,
  priorityId: null,
  paymentTermId: null,
  shippingTermId: null,
  locationId: null,
  'salesOrderItems.name': null,
  'salesOrderItems.description': null,
  'salesOrderItems.saleItem.item.tags.id%7C%7CsalesOrderBundleItems.salesOrderItems.saleItem.item.tags.id':
    null,
  salesOrderStatus: null,
  dateCreatedFrom: null,
  dateCreatedTo: null,
  dateLastModifiedFrom: null,
  dateLastModifiedTo: null,
  dateIssuedFrom: null,
  dateIssuedTo: null,
  dateScheduledFrom: null,
  dateScheduledTo: null,
  dateFulfilledFrom: null,
  dateFulfilledTo: null,
  dateExpiresFrom: null,
  dateExpiresTo: null,
};

export const salesOrderAdvSearchFields = {
  Customer: 'customer.id',
  Location: 'location.id',
  Tags: 'tag.id',
  Representative: 'representative.id',
  Priority: 'orderPriority.id',
  ShippingTerm: 'shippingTerm.id',
  PaymentTerm: 'paymentTerm.id',
  Carrier: 'carrier.id',
  CarrierService: 'carrier.service.id',
  DateCreatedFrom: 'created.gte',
  DateCreatedTo: 'created.lte',
  DateModifiedFrom: 'modified.gte',
  DateModifiedTo: 'modified.lte',
  DateIssuedFrom: 'issued.gte',
  DateIssuedTo: 'issued.lte',
  DateScheduledFrom: 'scheduled.gte',
  DateScheduledTo: 'scheduled.lte',
  DateExpiresFrom: 'expires.gte',
  DateExpiresTo: 'expires.lte',
};

export const displayNameMap = {
  number: 'Order Number',
  customerId: 'Customer',
  emails: 'Email',
  phone: 'Phone',
  representativeId: 'Representative',
  carrierId: 'Carrier',
  carrierServiceId: 'Carrier service',
  billToStreet: 'Bill to street',
  billToCity: 'Bill to city',
  billToState: 'Bill to state',
  billToPostalCode: 'Bill to postal code',
  billToCountry: 'Bill to country',
  shipToStreet: 'Ship to street',
  shipToCity: 'Ship to city',
  shipToState: 'Ship to state',
  shipToPostalCode: 'Ship to postal code',
  shipToCountry: 'Ship to country',
  customerPONumber: 'Customer PO Number',
  vendorPONumber: 'Vendor PO Number',
  notes: 'Notes',
  paymentTermId: 'Payment term',
  shippingTermId: 'Shipping term',
  priorityId: 'Priority',
  locationId: 'Location',
  'salesOrderItems.name': 'Item name',
  'salesOrderItems.description': 'Item description',
  'salesOrderItems.saleItem.item.tags.id%7C%7CsalesOrderBundleItems.salesOrderItems.saleItem.item.tags.id':
    'Tags',
  salesOrderStatus: 'Statuses',
  dateScheduledFrom: 'Date Scheduled After',
  dateScheduledTo: 'Date Scheduled Before',
  dateCreatedFrom: 'Date Created After',
  dateCreatedTo: 'Date Created Before',
  dateLastModifiedFrom: 'Date Modified After',
  dateLastModifiedTo: 'Date Modified Before',
  dateIssuedFrom: 'Date Issued After',
  dateIssuedTo: 'Date Issued Before',
  dateFulfilledFrom: 'Date Fulfilled After',
  dateFulfilledTo: 'Date Fulfilled Before',
  dateExpiresFrom: 'Date Expiers After',
  dateExpiresTo: 'Date Expires Before',
  deleted: 'Deleted',
};

export const advancedSearchReduxActions: AdvancedSearchReduxFunctions = {
  add: addSearch,
  delete: deleteSearch,
  update: updateSearch,
  get: getSearches,
};

export const initialPagination = {
  page: 1,
  pageSize: 25,
  sort: {
    sortBy: 'number',
    direction: PaginationDirection.Descending,
  },
  totalRows: 25,
};

export const createDisplayValueMap = (
  customers: Customer[],
  representative: Representative[],
  carriers: Carrier[],
  priorityLevels: OrderPriority[],
  paymentTerms: PaymentTerm[],
  shippingTerms: ShippingTerm[],
  locations: Location[],
  tags: Tag[],
  format: string
) => {
  let carrier: Carrier | null = null;

  return {
    [salesOrderAdvSearchFields.Customer]: (id: number) =>
      _.get(
        customers.find((c) => c.id === id),
        'name',
        null
      ),
    [salesOrderAdvSearchFields.Representative]: (id: number) =>
      _.get(
        representative.find((r) => r.id === id),
        'firstName',
        null
      ) +
      ' ' +
      (_.get(
        representative.find((r) => r.id === id),
        'lastName'
      ) || ''),
    [salesOrderAdvSearchFields.Carrier]: (id: number) => {
      carrier = carriers.find((c) => c.id === id) || null;
      return _.get(carrier, 'name', null);
    },
    [salesOrderAdvSearchFields.Priority]: (id: number) =>
      _.get(
        priorityLevels.find((p) => p.id === id),
        'name',
        null
      ),
    [salesOrderAdvSearchFields.PaymentTerm]: (id: number) =>
      _.get(
        paymentTerms.find((p) => p.id === id),
        'name',
        null
      ),
    [salesOrderAdvSearchFields.ShippingTerm]: (id: number) =>
      _.get(
        shippingTerms.find((s) => s.id === id),
        'name',
        null
      ),
    [salesOrderAdvSearchFields.Location]: (id: number) =>
      _.get(
        locations.find((l) => l.id === id),
        'name',
        null
      ),
    [salesOrderAdvSearchFields.CarrierService]: (id: number) => {
      const carrierServices = carrier ? carrier.carrierServiceList : [];
      return _.get(
        carrierServices.find((s) => s.id === id),
        'name',
        null
      );
    },
    [salesOrderAdvSearchFields.DateScheduledFrom]: (date: string) =>
      date ? moment(date).format(format) : '',
    [salesOrderAdvSearchFields.DateScheduledTo]: (date: string) =>
      date ? moment(date).format(format) : '',
    [salesOrderAdvSearchFields.DateCreatedFrom]: (date: string) =>
      date ? moment(date).format(format) : '',
    [salesOrderAdvSearchFields.DateCreatedTo]: (date: string) =>
      date ? moment(date).format(format) : '',
    [salesOrderAdvSearchFields.DateModifiedFrom]: (date: string) =>
      date ? moment(date).format(format) : '',
    [salesOrderAdvSearchFields.DateModifiedTo]: (date: string) =>
      date ? moment(date).format(format) : '',
    [salesOrderAdvSearchFields.DateIssuedFrom]: (date: string) =>
      date ? moment(date).format(format) : '',
    [salesOrderAdvSearchFields.DateIssuedTo]: (date: string) =>
      date ? moment(date).format(format) : '',
    // [salesOrderAdvSearchFields.DateFulfilledFrom]: (
    //   date: string
    // ) => (date ? moment(date).format(format) : ''),
    // [salesOrderAdvSearchFields.DateFulfilledTo]: (date: string) =>
    //   date ? moment(date).format(format) : '',
    [salesOrderAdvSearchFields.DateExpiresFrom]: (date: string) =>
      date ? moment(date).format(format) : '',
    [salesOrderAdvSearchFields.DateExpiresTo]: (date: string) =>
      date ? moment(date).format(format) : '',
    orderStatus: (status: string) => status.split(',').join(', '),
    [salesOrderAdvSearchFields.Tags]: () => tags.map((t) => t.name).join(', '),
  };
};
