import { combineReducers } from 'redux';
import {
  chainReducers,
  INITIAL_API_STATE,
  createApiReducer,
} from 'services/api';
import {
  createAddSearchReducer,
  createDeleteSearchReducer,
  createFetchSearchReducer,
  createUpdateSearchReducer,
} from 'services/search';
import { transformPricingRule } from '../transformations';
import { PricingRulesActionTypes } from './actions';

const fetchPricingRulesReducer = createApiReducer(
  [
    PricingRulesActionTypes.FETCH_PRICING_RULES_START,
    PricingRulesActionTypes.FETCH_PRICING_RULES_SUCCESS,
    PricingRulesActionTypes.FETCH_PRICING_RULES_FAILURE,
  ],
  (data) => ({ items: data.results.map(transformPricingRule) }),
  (data) => data
);

const pricingRulesChainedReducers = chainReducers(
  INITIAL_API_STATE,
  fetchPricingRulesReducer
);

const fetchSearchReducer = createFetchSearchReducer('pricingRules');
const addSearchReducer = createAddSearchReducer('pricingRules');
const updateSearchReducer = createUpdateSearchReducer('pricingRules');
const deleteSearchReducer = createDeleteSearchReducer('pricingRules');

const searchChainedReducers = chainReducers(
  INITIAL_API_STATE,
  fetchSearchReducer,
  addSearchReducer,
  updateSearchReducer,
  deleteSearchReducer
);

export default combineReducers({
  pricingRules: pricingRulesChainedReducers,
  searches: searchChainedReducers,
});
