import { makeStyles } from '@mui/styles';

export const useItemDetailsCardStyle = makeStyles(() => ({
  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
}));
