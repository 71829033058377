import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colorPalette } from 'ui/theme';

export const useCustomerGeneralStyle = makeStyles((theme: Theme) => ({
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
    columnGap: theme.spacing(2),
    padding: theme.spacing(3),
    overflowY: 'scroll',
  },
  // 1/3 columns
  gridItem: {
    gridColumn: 'span 2',
  },
  gridItemWide: {
    gridColumn: 'span 2',
  },
  title: {
    marginBottom: theme.spacing(2),
    gridColumn: 'span 2',
  },
  titleWide: {
    marginBottom: theme.spacing(2),
    gridColumn: 'span 2',
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    gridColumn: 'span 6',
  },
  doubleRow: {
    gridRow: 'span 2',
    gridColumn: 'span 2',
  },
  spacer: {
    gridColumn: 'span 4',
  },

  // 1/2 columns
  gridItemSpan3: {
    gridColumn: 'span 3',
  },
  gridItemWideSpan3: {
    gridColumn: 'span 3',
  },
  titleSpan3: {
    marginBottom: theme.spacing(2),
    gridColumn: 'span 3',
  },
  titleWideSpan3: {
    marginBottom: theme.spacing(2),
    gridColumn: 'span 3',
  },
  doubleRowSpan3: {
    gridRow: 'span 2',
    gridColumn: 'span 3',
  },
  spacerSpan3: {
    gridColumn: 'span 3',
  },

  formControl: {
    width: '100%',
  },
  notes: {
    '& .MuiInputBase-root': {
      height: 97,
    },
  },
  linkButton: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    borderRadius: theme.spacing(3),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  resetButton: {
    color: colorPalette.applicationDarkGray80,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    padding: '5px 16px',
  },
}));
