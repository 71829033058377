import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Box, FormControlLabel, Radio, Typography } from '@mui/material';

import { TagsAsyncAutocomplete } from 'ui/components/Autocomplete/TagsAutocomplete';
import { activeUserHasPermission } from 'services/user/redux';
import { SaleItemAsyncAutocomplete } from 'ui/components/Autocomplete/SaleItemAsyncAutocomplete';
import { PermissionType } from 'services/permissions';
import { SaleItem } from 'services/items/saleItems';
import { Tag } from 'services/tags';

import { PricingRulesProductsGroup, PricingRulesProductsProps } from './types';
import { usePricingRulesProductsStyle } from './styled';

const PricingRulesProducts: React.FC<PricingRulesProductsProps> = (props) => {
  const {
    activePricingRule,
    setActivePricingRule,
    checkedProductsGroup,
    setCheckedProductsGroup,
    validationErrors,
    setValidationErrors,
  } = props;

  const classes = usePricingRulesProductsStyle();

  const canEditPricingRules =
    activePricingRule.id && activePricingRule.id > 0
      ? [PermissionType.PricinRulesEdit]
      : [PermissionType.PricinRulesCreate];

  const canSwitchRadio = useSelector(
    activeUserHasPermission(canEditPricingRules)
  );

  const handleTagsChange = useCallback(
    (values: Tag[]) =>
      setActivePricingRule((old) => ({
        ...old,
        saleItemTags: values,
        saleItemId: null,
      })),
    [setActivePricingRule]
  );

  const handleSaleItemChange = useCallback(
    (saleItem: SaleItem | null) => {
      const id = saleItem?.id ?? null;
      const bundleItems = saleItem?.bundleItems ?? [];

      const saleItemId = bundleItems.length > 0 ? null : id;
      const itemId = bundleItems.length > 0 ? id : null;

      setActivePricingRule((old) => ({
        ...old,
        saleItemId,
        itemId,
      }));
    },
    [setActivePricingRule]
  );

  const handleProductsGroupChange = useCallback(
    (event: any) => {
      const pricingRuleGroup = event.target.value;

      setCheckedProductsGroup(pricingRuleGroup);
      setValidationErrors({});

      switch (pricingRuleGroup) {
        case PricingRulesProductsGroup.All:
          setActivePricingRule((old) => ({
            ...old,
            saleItemId: null,
            itemId: null,
            saleItemTags: [],
          }));
          break;
        case PricingRulesProductsGroup.Product:
          setActivePricingRule((old) => ({
            ...old,
            saleItemId: activePricingRule.saleItemId,
            itemId: activePricingRule.itemId,
            saleItemTags: [],
          }));
          break;
        case PricingRulesProductsGroup.Tag:
          setActivePricingRule((old) => ({
            ...old,
            saleItemId: null,
            itemId: null,
            saleItemTags: activePricingRule.saleItemTags,
          }));
          break;
      }
    },
    [
      activePricingRule.saleItemTags,
      activePricingRule.saleItemId,
      setActivePricingRule,
      setCheckedProductsGroup,
      setValidationErrors,
    ]
  );

  return (
    <>
      <Typography variant="body1" color="textPrimary">
        <b>Sale Items</b>
      </Typography>
      <Box py={1}>
        <FormControlLabel
          control={
            <Radio
              checked={checkedProductsGroup === PricingRulesProductsGroup.All}
              onChange={handleProductsGroupChange}
              value={PricingRulesProductsGroup.All}
              name="radio-products"
              color="primary"
              inputProps={
                {
                  'data-qa': 'pricing-rule-product-all-radio',
                } as any
              }
            />
          }
          label="All"
          disabled={!canSwitchRadio}
          className={classes.radioButton}
        />
      </Box>

      <Box display="flex" alignItems="center" py={1}>
        <FormControlLabel
          control={
            <Radio
              checked={
                checkedProductsGroup === PricingRulesProductsGroup.Product
              }
              onChange={handleProductsGroupChange}
              value={PricingRulesProductsGroup.Product}
              name="radio-products"
              color="primary"
              inputProps={
                {
                  'data-qa': 'pricing-rule-sale-item-radio',
                } as any
              }
            />
          }
          label="Sale Item"
          disabled={!canSwitchRadio}
          className={classes.radioButton}
        />

        <Box flex={1}>
          <SaleItemAsyncAutocomplete
            onChange={handleSaleItemChange}
            value={activePricingRule.saleItemId || activePricingRule.itemId}
            placeholder="Select sale item"
            disabled={
              checkedProductsGroup !== PricingRulesProductsGroup.Product
            }
            permissions={canEditPricingRules}
            error={!!validationErrors.saleItemId}
            dataQa="pricing-rule-select-sale-item"
            isBundle={!activePricingRule.saleItemId}
          />
        </Box>
      </Box>

      <Box display="flex" alignItems="center" py={1}>
        <FormControlLabel
          control={
            <Radio
              checked={checkedProductsGroup === PricingRulesProductsGroup.Tag}
              onChange={handleProductsGroupChange}
              value={PricingRulesProductsGroup.Tag}
              name="radio-products"
              color="primary"
              inputProps={
                {
                  'data-qa': 'pricing-rule-product-tag-radio',
                } as any
              }
            />
          }
          label="Tag"
          disabled={!canSwitchRadio}
          className={classes.radioButton}
        />

        <Box flex={1}>
          <TagsAsyncAutocomplete
            value={activePricingRule.saleItemTags}
            permissions={canEditPricingRules}
            onChange={handleTagsChange}
            disabled={checkedProductsGroup !== PricingRulesProductsGroup.Tag}
            error={!!validationErrors.saleItemTags}
            disableTagsModal
          />
        </Box>
      </Box>
    </>
  );
};

export default memo(PricingRulesProducts);
