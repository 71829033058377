import * as yup from 'yup';

// Scheme
const yupSchemaNetTerm = yup.object().shape({
  name: yup.string().typeError('Enter name').required(),
  dueDays: yup.number().typeError('Enter Days').required(),
});

const yupSchemaDateDriven = yup.object().shape({
  name: yup.string().typeError('Enter name').required(),
  dayOfMonthDue: yup.number().typeError('Enter name').required(),
  graceDays: yup.number().typeError('Enter Days').required(),
});

// Scheme with Discount
const yupSchemaNetTermWithDiscount = yup.object().shape({
  name: yup.string().typeError('Enter name').required(),
  discountDays: yup.number().min(0).max(365),
  discountPercent: yup.number().max(99),
  dueDays: yup.number().typeError('Enter Days').required(),
});

const yupSchemaDateDrivenWithDiscount = yup.object().shape({
  name: yup.string().typeError('Enter name').required(),
  discountDays: yup.number().min(0).max(365),
  discountPercent: yup.number().max(99),
  dayOfMonthDue: yup.number().typeError('Enter name').required(),
  graceDays: yup.number().typeError('Enter Days').required(),
});

export const getPaymentTermValidation = (
  applyDiscount: boolean,
  isNetTermSelected: boolean
) => {
  if (applyDiscount) {
    return isNetTermSelected
      ? yupSchemaNetTermWithDiscount
      : yupSchemaDateDrivenWithDiscount;
  }
  return isNetTermSelected ? yupSchemaNetTerm : yupSchemaDateDriven;
};
