import { CardType } from '../../types';

export const CARD_TYPES: CardType[] = [
  { code: 'American Express', label: 'American Express' },
  { code: 'Visa', label: 'Visa' },
  { code: 'MasterCard', label: 'MasterCard' },
  { code: 'JCB', label: 'JCB' },
  { code: 'Discover', label: 'Discover' },
  { code: 'Diners Club', label: 'Diners Club' },
];
