import { createApiAction } from 'services/api';
import {
  StartAction,
  SuccessAction,
  FailureAction,
} from 'services/api/redux/types';
import { commerceHostname } from '../consts';

export enum SellwareAccountActionTypes {
  FETCH_SELLWARE_ACCOUNT_START = 'commerce/fetch_sellware_account_start',
  FETCH_SELLWARE_ACCOUNT_SUCCESS = 'commerce/fetch_sellware_account_success',
  FETCH_SELLWARE_ACCOUNT_FAILURE = 'commerce/fetch_sellware_account_failure',
}

const fetchSellwareAccountActionStart = (): StartAction => ({
  type: SellwareAccountActionTypes.FETCH_SELLWARE_ACCOUNT_START,
  payload: null,
});

const fetchSellwareAccountActionSuccess = (payload: any): SuccessAction => ({
  type: SellwareAccountActionTypes.FETCH_SELLWARE_ACCOUNT_SUCCESS,
  payload,
});

const fetchSellwareAccountActionFailure = (error: string): FailureAction => ({
  type: SellwareAccountActionTypes.FETCH_SELLWARE_ACCOUNT_FAILURE,
  payload: { error },
});

export const fetchSellwareActiveAccount = () =>
  createApiAction(
    {
      hostname: commerceHostname,
      path: '/commerce/v1/connection/status',
      method: 'GET',
    },
    fetchSellwareAccountActionStart,
    fetchSellwareAccountActionSuccess,
    fetchSellwareAccountActionFailure
  );
