import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { LabeledDividerProps } from './types';

const LabeledDivider: React.FC<LabeledDividerProps> = (props) => {
  const { label, ...otherProps } = props;

  return (
    <Box
      display="flex"
      flex={1}
      justifyContent="space-between"
      alignItems="center"
      {...otherProps}
    >
      <Box flex={1} pr={2}>
        <Divider />
      </Box>
      <Typography variant="body1" color="textPrimary">
        {label}
      </Typography>
      <Box flex={1} pl={2}>
        <Divider />
      </Box>
    </Box>
  );
};

export default LabeledDivider;
