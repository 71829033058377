import React from 'react';
import { Box, CircularProgress } from '@mui/material';

import { NetworkSpinnerWrapperProps, NetworkSpinnerWrapperComp } from './types';
import { useSpinnerStyle } from './styled';
import { SpinLoader } from '../SpinLoader/SpinLoader';

const NetworkSpinnerWrapper: NetworkSpinnerWrapperComp = (
  props: NetworkSpinnerWrapperProps
) => {
  const {
    isLoading,
    isPolling,
    children,
    size = 48,
    hideBackground = false,
    ...boxProps
  } = props;

  const classes = useSpinnerStyle(props);
  const displayBackground = !hideBackground && isLoading;

  return (
    <Box className={classes.wrapper} {...boxProps}>
      {children}
      {displayBackground && <Box className={classes.background} />}
      {isLoading && (
        <CircularProgress
          size={size}
          className={classes.spinner}
          data-qa="network-spinner"
        />
      )}
      {isPolling && <SpinLoader zIndex={2} />}
    </Box>
  );
};

export default NetworkSpinnerWrapper;
