import { Representative } from './types';

export const initialRepresentative: Representative = {
  id: null,
  firstName: null,
  lastName: null,
  middleName: null,
  initials: null,
  deleted: false,
  version: null,
  dateLastModified: null,
  dateCreated: null,
};
