import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colorPalette } from 'ui/theme';

export const useDashboardLineChartStyle = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(7),
  },
  card: {
    padding: theme.spacing(2),
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.16)',
    backgroundColor: colorPalette.white,
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
  },
  cardHeader: {
    marginBottom: theme.spacing(3),
    display: 'flex',
    alignItems: 'flex-start',
  },
  cardHeaderInfo: {
    display: 'flex',
  },
  cardHeaderLabel: {
    fontSize: 'calc(14px * var(--font-size-multiplier, 1))',
    lineHeight: 1,
    color: colorPalette.applicationDarkGray80,
    textTransform: 'uppercase',
  },
  cardHeaderPercentage: {
    marginLeft: theme.spacing(0.5),
    fontSize: 'calc(14px * var(--font-size-multiplier, 1))',
    lineHeight: 1,
    color: colorPalette.statusGreen,
  },
  cardHeaderValue: {
    marginTop: 4,
    fontWeight: 700,
    fontSize: 'calc(16px * var(--font-size-multiplier, 1))',
    lineHeight: 1,
  },
  chartLegend: {
    height: 16,
    marginLeft: 'auto',
  },
  chartLegendItem: {
    display: 'inline-flex',
    alignItems: 'center',
    height: 16,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    backgroundColor: colorPalette.applicationLightGray,
    marginLeft: theme.spacing(1),
  },
  chartLegendItemIcon: {
    width: 8,
    height: 8,
    marginRight: theme.spacing(0.5),
  },
  chartLegendItemLabel: {
    fontSize: 'calc(12px * var(--font-size-multiplier, 1))',
    color: colorPalette.applicationDarkGray80,
    textTransform: 'uppercase',
  },
}));
