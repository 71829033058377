import { GroupByOption, CostingMethod } from './types';

export const groupByOptions: GroupByOption[] = [
  { type: 'location', title: 'Location' },
  { type: 'item', title: 'Item' },
];

export const costingMethods: CostingMethod[] = [
  { type: 'actual', title: 'Actual' },
  { type: 'average', title: 'Average' },
];
