import React, { memo, useCallback, useMemo } from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { DateRange } from 'materialui-daterange-picker';
import DateRangePicker from 'ui/components/TextField/DateRangePicker/DateRangePicker';
import { useHandleCheckboxChange } from 'services/forms/hooks';

import { ReportFiltersProps } from '../../types';

const TotalItemSalesFilters: React.FC<ReportFiltersProps> = (props) => {
  const { parameters, setParameters } = props;

  const selectedDateRange = useMemo(() => {
    return parameters.dateRange || {};
  }, [parameters]);

  const handleSelectAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setParameters((old) => ({
        ...old,
        includeCoverSheet: checked,
        selectAllOptional: checked,
        showUom: checked,
        showQuantity: checked,
      }));
    },
    [setParameters]
  );

  const handleCheckboxChange = useHandleCheckboxChange(setParameters);

  const handleDateChange = useCallback(
    (value: DateRange) => {
      setParameters((old) => ({ ...old, dateRange: value }));
    },
    [setParameters]
  );
  return (
    <>
      <Box px={2} pb={0} pt={1}>
        <FormControlLabel
          control={
            <Checkbox
              className="redesign"
              checked={Boolean(parameters.selectAllOptional)}
              onChange={handleSelectAll}
              color="primary"
            />
          }
          label="Select All Optional"
        />
      </Box>
      <Accordion square defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <b>Optional</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%" pt={1}>
            <Box mb={1}>
              <DateRangePicker
                className="date-picker-small-font"
                value={selectedDateRange}
                label="Date Range"
                name="dateRange"
                placeholder="Select date range"
                onChange={handleDateChange}
                fullWidth
              />
            </Box>
            <Box mb={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.includeCoverSheet)}
                    onChange={handleCheckboxChange}
                    color="primary"
                    name="includeCoverSheet"
                  />
                }
                label="Include Cover Sheet"
              />
            </Box>
            <Box mb={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showUom)}
                    onChange={handleCheckboxChange}
                    color="primary"
                    name="showUom"
                  />
                }
                label="Show UOM"
              />
            </Box>
            <Box mb={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showQuantity)}
                    onChange={handleCheckboxChange}
                    color="primary"
                    name="showQuantity"
                  />
                }
                label="Show Quantity"
              />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default memo(TotalItemSalesFilters);
