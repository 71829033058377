import { defaultMaximumPagination, RequestConfig } from 'helpers';
import { createApiCall, paginatedApiCall } from 'services/api';

import {
  transformCurrency,
  transformToRequestCurrency,
} from './transformations';
import { Currency } from './types';

export const fetchCurrenciesAPI = async (config: RequestConfig) => {
  const {
    pagination = defaultMaximumPagination,
    expands = [],
    customQuickSearchColumns = ['name', 'code'],
    quickSearchValue,
  } = config;

  let path = `/v1/currencies?expand=${expands.join(',')}`;

  if (quickSearchValue) {
    path += `&quickSearchColumns=${customQuickSearchColumns}&quickSearchValue=${quickSearchValue}`;
  }

  const res = await paginatedApiCall(path, pagination, transformCurrency);

  return res;
};

export const getCurrencyById = async (id: number): Promise<Currency> => {
  const res = await createApiCall(
    {
      path: `/v1/currencies/${id}`,
      method: 'GET',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformCurrency(res.data);
};

export const createCurrency = async (data: Currency): Promise<Currency> => {
  const res = await createApiCall(
    {
      path: '/v1/currencies',
      method: 'POST',
      body: transformToRequestCurrency(data),
    },
    {
      getMessage: {
        success: () => 'Currency successfully created',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformCurrency(res.data);
};

export const updateCurrency = async (data: Currency): Promise<Currency> => {
  const res = await createApiCall(
    {
      path: '/v1/currencies',
      method: 'PUT',
      body: transformToRequestCurrency(data),
    },
    {
      getMessage: {
        success: () => 'Currency successfully updated',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformCurrency(res.data);
};

export const deleteCurrency = async (id: number): Promise<void> => {
  await createApiCall(
    {
      path: `/v1/currencies/${id}`,
      method: 'DELETE',
    },
    {
      getMessage: {
        success: () => 'Currency successfully deleted',
        error: (error: any) => error.response.data.message,
      },
    }
  )();
};

export const restoreCurrency = async (currencyId: number) => {
  await createApiCall(
    {
      path: `/v1/currencies/${currencyId}/restore`,
      method: 'POST',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
        success: () => 'Currency successfully restored',
      },
    }
  )();
};
