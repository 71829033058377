import {
  initialReportPreset,
  Report,
  ReportParameters,
  ReportPreset,
} from 'services/reports';

export const transformToInitialPreset = (
  report: Report,
  defaultParams: ReportParameters
): ReportPreset => ({
  ...initialReportPreset,
  parameters: { ...report.parameters, ...defaultParams, count: 1 },
  reportId: report.reportId,
});
