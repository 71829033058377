import { TrackingTableTypes } from 'ui/components/Table/TrackingTable';

export const generateSerialTableEditProps = (tableType: TrackingTableTypes) => {
  switch (tableType) {
    case TrackingTableTypes.Add:
      return {
        canClearRow: true,
        canEditRow: true,
        canSelectRow: false,
      };
    case TrackingTableTypes.Remove:
      return {
        canClearRow: false,
        canEditRow: false,
        canSelectRow: true,
      };
    case TrackingTableTypes.Edit:
      return {
        canClearRow: false,
        canEditRow: true,
        canSelectRow: false,
      };
    case TrackingTableTypes.View:
      return {
        canClearRow: false,
        canEditRow: false,
        canSelectRow: false,
      };
    default:
      return {};
  }
};

export const generateEditableTableStatus = (serialDiff: number) => {
  if (serialDiff === 0) {
    return 'Success';
  } else if (serialDiff < 0) {
    return `Remove ${Math.abs(serialDiff)} rows`;
  } else if (serialDiff > 0) {
    return `Add ${serialDiff} rows`;
  }
  return '';
};

export const generateTableStatus = (serialDiff: number) => {
  if (serialDiff === 0) {
    return 'Success';
  } else if (serialDiff < 0) {
    return `Unselect ${Math.abs(serialDiff)} rows`;
  } else if (serialDiff > 0) {
    return `Select ${serialDiff} rows`;
  }
  return '';
};
