import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { getCommerceAccountIsOrWasConnected } from 'services/commerce/redux/selectors';
import { useFlags } from 'helpers/useFlags';
import { getCommerceTenantId } from 'services/commerce/api';
import { SellwareIntegrations, IconNames } from 'ui/theme';
import { useDiscoverChannelsHeroComponent } from './styled';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { fishbowlCommerceLogo } from '../../consts';
import { getActiveUser } from 'services/user';

const DiscoverChannelsHeroComponent = (props: any) => {
  const flags = useFlags();
  const { sellwareUrl } = props;
  const activeUser = useSelector(getActiveUser);
  const [commerceTenantId, setCommerceTenantId] = useState<string | null>(null);

  const classes = useDiscoverChannelsHeroComponent();
  const isOrWasConnectedToSellware = useSelector(
    getCommerceAccountIsOrWasConnected
  );

  useEffect(() => {
    const getTenantId = async () => {
      if (activeUser?.user?.email) {
        const data = await getCommerceTenantId(activeUser.user.email);
        if (data.clientId) setCommerceTenantId(data.clientId);
      }
    };

    getTenantId();
  }, [activeUser?.user?.email]);

  const showAddChannelsButton = isOrWasConnectedToSellware;

  const showConnectToSellwareButton =
    !isOrWasConnectedToSellware &&
    flags.commerceIntegrationUiRefactor &&
    commerceTenantId !== null;

  const showContactManagerButton =
    !isOrWasConnectedToSellware &&
    flags.commerceIntegrationUiRefactor &&
    commerceTenantId === null;

  const showConnectToFishbowlCommerceButton =
    !isOrWasConnectedToSellware && !flags.commerceIntegrationUiRefactor;

  return (
    <Box className={classes.container}>
      <img
        src={fishbowlCommerceLogo}
        width="200px"
        alt="Fishbowl Commerce logo"
      />
      <Typography align="left" variant="h3">
        Discover Channels
      </Typography>
      {isOrWasConnectedToSellware ? (
        <Typography component="span" align="left">
          Connect new channels to your existing Fishbowl Commerce account via
          Fishbowl today to expand your reach and take your business to new
          levels of success!
          <ul>
            <li>
              Sell more, without the hassle of managing multiple platforms
              separately by integrating additional ecommerce channels
            </li>
            <li>
              Sync your new channels with Fishbowl Commerce via Fishbowl to
              process orders lightning-fast and delight customers with speedy
              deliveries
            </li>
            <li>Stay ahead of the curve by adding new channels as needed</li>
            <li>
              Maintain control and consistency across all channels from a
              centralized hub while easily managing inventory, preventing
              stockouts and overselling
            </li>
          </ul>
          Click “Add New Channels” to get started today!
        </Typography>
      ) : (
        <Typography component="span" align="left">
          Ready to take your online business to the next level? Connect your
          ecommerce channels with Fishbowl Commerce to turbo charge your
          ecommerce operations, reach a wider audience, and become more
          profitable!
          <ul>
            <li>
              Sync inventory, orders, and product listings automatically to keep
              your data up-to-date in real-time
            </li>
            <li>
              Consolidate your ecommerce channels to Increase visibility, sales,
              and customer satisfaction with every order fulfilled
            </li>
            <li>
              Maintain control and consistency across all channels from a
              centralized hub
            </li>
            <li>
              Efficiently manage inventory levels and prevent overselling
              nightmares
            </li>
          </ul>
          Connect to Fishbowl Commerce to get started today!
        </Typography>
      )}
      <img
        src={SellwareIntegrations}
        alt="Sellware integrations logos"
        className={classes.integrationsImage}
      />

      {showAddChannelsButton && (
        <FBOButton
          variant="primary"
          color="positive"
          size="medium"
          data-qa="add-channels-button"
          icon={IconNames.FBOAddCircle}
          href={`${process.env.REACT_APP_SELLWARE_URL}/channels`}
          target="_blank"
          linkStyledAsButton
        >
          Add Channels
        </FBOButton>
      )}

      {showConnectToSellwareButton && (
        <FBOButton
          variant="primary"
          color="positive"
          size="medium"
          data-qa="connect-to-sellware-button"
          icon={IconNames.IconAvatar}
          href={sellwareUrl}
          target="_blank"
          linkStyledAsButton
        >
          Click Here To Get Started
        </FBOButton>
      )}

      {showContactManagerButton && (
        <FBOButton
          variant="primary"
          color="positive"
          size="medium"
          data-qa="connect-to-sellware-button"
          icon={IconNames.IconAvatar}
          href={'https://help.fishbowlonline.com/'}
          target="_blank"
          linkStyledAsButton
        >
          Contact Your Account Manager To Get Started
        </FBOButton>
      )}

      {showConnectToFishbowlCommerceButton && (
        <FBOButton
          variant="primary"
          color="positive"
          size="medium"
          data-qa="connect-to-sellware-button"
          icon={IconNames.FBOLeavePage}
          href={sellwareUrl}
          linkStyledAsButton
        >
          Connect To Fishbowl Commerce
        </FBOButton>
      )}
    </Box>
  );
};

export default DiscoverChannelsHeroComponent;
