import React, { memo, useState } from 'react';

import { ItemsTable } from 'ui/components/Table/ItemsTable';

import ItemsStepRow from './ItemsStepRow';
import { columns } from './consts';
import { ItemsStepProp } from './types';
import { transformShipItemToDeclarationItem } from './transformations';
import { DeclarationItem } from 'services/integrations/shipping/purchaseLabel';

const ItemsStep: React.FC<ItemsStepProp> = ({ shipItems }) => {
  const [declarationItems, setDeclarationItems] = useState<DeclarationItem[]>(
    shipItems.map(transformShipItemToDeclarationItem)
  );

  return (
    <ItemsTable
      columns={columns}
      data={declarationItems}
      selectableItems={false}
      tableBordered
      RenderCustomRow={ItemsStepRow}
      emptyTableText=""
      setData={setDeclarationItems}
      tableLayoutFixed
    />
  );
};

export default memo(ItemsStep);
