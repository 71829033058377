import React, { memo, useCallback, useRef, useState } from 'react';
import {
  TablePagination,
  Table,
  TableRow,
  TableBody,
  Box,
  Typography,
} from '@mui/material';
import clsx from 'clsx';

import EnhancedTablePaginationActions from './EnhancedTablePaginationActions';
import EnhancedTablePaginationActionsReduce from './EnhancedTablePaginationActionsReduce';
import EnhancedTablePaginationLabel from './EnhancedTablePaginationLabel';
import EnhancedTablePaginationCountMenu from './EnhancedTablePaginationCountMenu';

import { RenderCustomFooterProps } from '../types';
import { useEnhancedFooterStyles } from './styled';

const SelectProps = {
  inputProps: {
    'aria-label': 'rows per page',
    'data-qa': 'table-pagination',
  },
};

const ROW_OPTIONS = [10, 25, 50, 75];

const EnhancedFooter = (props: RenderCustomFooterProps) => {
  const {
    onPaginationChange,
    pagination,
    selectedItemsData,
    showEnhancedTablePaginationActions,
    isLoading,
  } = props;

  const [openFirstLastPaginationMenu, setOpenFirstLastPaginationMenu] =
    useState(false);

  const anchorRef = useRef<HTMLButtonElement>(null);

  const classes = useEnhancedFooterStyles(props);

  const togglePaginationMenu = () =>
    setOpenFirstLastPaginationMenu(!openFirstLastPaginationMenu);

  const closePaginationMenu = () => setOpenFirstLastPaginationMenu(false);

  const handlePageChange = useCallback(
    (_event: any, page: number) => {
      if (!onPaginationChange || !pagination || isLoading) {
        return;
      }

      onPaginationChange({ ...pagination, page: page + 1 });
    },
    [onPaginationChange, pagination, isLoading]
  );

  const handleRowPerPageChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (!onPaginationChange || !pagination) {
        return;
      }

      const rowsNumber = parseInt(event.target.value, 10);
      onPaginationChange({ ...pagination, page: 1, pageSize: rowsNumber });
    },
    [onPaginationChange, pagination]
  );

  if (!pagination) {
    return null;
  }

  const shrinkFooterPagination = () => {
    const { page, pageSize, totalRows } = pagination;

    const start = (page - 1) * pageSize + 1;
    let end = totalRows;

    if (pageSize < totalRows) {
      end = pageSize * page;
      if (end > totalRows) {
        end = totalRows;
      }
    }

    // e.g. 21-30/193
    const displayText = start + '-' + end + '/' + totalRows;

    return displayText.length >= 10;
  };

  return (
    <Box display="flex" alignItems="center">
      {selectedItemsData.showSelected && (
        <Box alignItems="center" px={3}>
          <Typography variant="body2">
            Selected:{' '}
            <span>
              {selectedItemsData.selected} of {selectedItemsData.pagination}
            </span>
          </Typography>
        </Box>
      )}
      <Table>
        <TableBody>
          <TableRow>
            <TablePagination
              ref={anchorRef}
              className={clsx({
                redesign: true,
                expanded: showEnhancedTablePaginationActions,
                collapsed: !showEnhancedTablePaginationActions,
                shrink: shrinkFooterPagination(),
              })}
              classes={{
                root: classes.tablePaginationStyle,
                toolbar: classes.tablePaginationToolbar,
              }}
              rowsPerPageOptions={ROW_OPTIONS}
              rowsPerPage={pagination.pageSize}
              labelDisplayedRows={({ from, to, count, ...rest }) => (
                <EnhancedTablePaginationLabel
                  className={clsx([
                    'redesign',
                    'neutral',
                    'table-footer-pagination-button',
                  ])}
                  disableRipple
                  disableTouchRipple
                  from={from}
                  to={to}
                  count={count}
                  onClick={togglePaginationMenu}
                  showSimpleCount={showEnhancedTablePaginationActions}
                  {...rest}
                />
              )}
              count={pagination.totalRows}
              page={pagination.page - 1}
              SelectProps={SelectProps}
              labelRowsPerPage="Rows per page"
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowPerPageChange}
              ActionsComponent={
                showEnhancedTablePaginationActions
                  ? EnhancedTablePaginationActions
                  : EnhancedTablePaginationActionsReduce
              }
            />
          </TableRow>
        </TableBody>
      </Table>

      {/* Menu with pagination */}
      <EnhancedTablePaginationCountMenu
        pagination={pagination}
        onClickAway={closePaginationMenu}
        open={openFirstLastPaginationMenu}
        anchorEl={anchorRef.current}
        onChangePage={handlePageChange}
      />
    </Box>
  );
};

export default memo(EnhancedFooter);
