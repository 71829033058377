import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { EnhancedBodyProps } from 'ui/components/Table/ItemsTable/components/types';
import { colorPalette, SupportColors } from 'ui/theme';

export const usItemsStepRowStyle = makeStyles<
  Theme,
  Pick<EnhancedBodyProps, 'tableBordered'>
>(() => ({
  icon: {
    cursor: 'pointer',
  },
  input: {
    padding: 12,
  },
  inputSmall: {
    padding: '6px 8px 6px 8px',
  },
  cell: {
    padding: '0 !important',
    borderRightColor: ({ tableBordered }) =>
      tableBordered ? SupportColors.tableBorder : 'transparent',
    borderRightWidth: ({ tableBordered }) => (tableBordered ? 1 : 0),
    borderRightStyle: ({ tableBordered }) => (tableBordered ? 'solid' : 'none'),
  },
  cellContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  textFieldContainer: {
    flexGrow: 1,
  },
  noBorder: {
    border: 0,
  },
  disabledCell: {
    backgroundColor: colorPalette.applicationDarkGray10,
  },
  tooltipLabel: {
    fontSize: 12,
  },
}));
