import { createApiCall } from 'services/api';

import { UomConversion } from './types';

export const newConversions = (conversions: UomConversion[], uomId: number) =>
  createApiCall(
    {
      path: `/v1/uoms/${uomId}/uom_conversions`,
      method: 'POST',
      body: conversions,
    },
    {
      getMessage: {
        success: () => 'New conversions successfully added',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

export const updateConversions = (
  conversions: UomConversion[],
  uomId: number
) =>
  createApiCall(
    {
      path: `/v1/uoms/${uomId}/uom_conversions`,
      method: 'PUT',
      body: conversions,
    },
    {
      getMessage: {
        success: () => 'Conversions successfully updated',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

export const deleteConversion = (conversions: number[], uomId: number) =>
  createApiCall(
    {
      path: `/v1/uoms/${uomId}/uom_conversions`,
      method: 'DELETE',
      body: conversions,
    },
    {
      getMessage: {
        success: () => 'Conversions successfully deleted',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();
