import React, { useEffect, useRef } from 'react';
import { useFlags } from 'helpers/useFlags';
import { datadogRum } from '@datadog/browser-rum';

import { hasValidDatadogConfig } from './validations';

const DatadogMonitor: React.FC = ({ children }) => {
  const isInitialized = useRef(false);
  const flags = useFlags();

  const initializeDataDogMonitoring = () => {
    const env = process.env;

    if (
      flags.datadogMonitor &&
      !isInitialized.current &&
      hasValidDatadogConfig(env) &&
      env.REACT_APP_DD_APPLICATION_ID &&
      env.REACT_APP_DD_CLIENT_TOKEN
    ) {
      const sessionSampleRate = Number(
        env.REACT_APP_DD_MONITORING_SAMPLE_RATE ?? 0
      );
      const sessionReplaySampleRate = Number(
        env.REACT_APP_DD_SESSION_REPLAY_SAMPLE_RATE ?? 0
      );
      const traceSampleRate = Number(env.REACT_APP_DD_TRACE_SAMPLE_RATE ?? 0);

      datadogRum.setGlobalContextProperty('featureFlag', true);
      datadogRum.init({
        allowedTracingUrls: [
          (url: string) => url.includes('fishbowlonline.com'),
        ],
        applicationId: env.REACT_APP_DD_APPLICATION_ID,
        clientToken: env.REACT_APP_DD_CLIENT_TOKEN,
        defaultPrivacyLevel: 'mask-user-input',
        env: env.REACT_APP_DD_ENVIRONMENT,
        service: env.REACT_APP_DD_SERVICE,
        sessionReplaySampleRate,
        sessionSampleRate,
        site: env.REACT_APP_DD_SITE,
        telemetrySampleRate: 0,
        traceSampleRate,
        trackLongTasks: true,
        trackResources: true,
        trackUserInteractions: true,
        trackViewsManually: true,
        version: env.REACT_APP_DD_CLIENT_VERSION ?? '1.0.0',
        beforeSend: () => {
          const globalContext = datadogRum.getGlobalContext();
          return !!globalContext.featureFlag;
        },
      });
      isInitialized.current = true;
    }
  };
  useEffect(initializeDataDogMonitoring, [flags.datadogMonitor]);

  const haltDatadogMonitoring = () => {
    if (!flags.datadogMonitor) {
      datadogRum.setGlobalContextProperty('featureFlag', false);
    }
  };
  useEffect(haltDatadogMonitoring, [flags.datadogMonitor]);

  return <>{children}</>;
};

export default DatadogMonitor;
