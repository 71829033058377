import React, { memo, useCallback } from 'react';
import { TableCell, Typography } from '@mui/material';
import _ from 'lodash';

import { ReorderTabTableRowActions, createActionBarOptions } from './consts';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import { ItemReorderPoint } from 'services/items';

import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';
import { colorPalette } from 'ui/theme';

const ReorderTabRow = ({
  row,
  onAction,
  index,
}: RenderCustomRowProps<ItemReorderPoint>) => {
  const handleAction = useCallback(
    (actionType: ReorderTabTableRowActions) => {
      if (onAction) {
        onAction({ type: actionType, itemIndex: index });
      }
    },
    [onAction, index]
  );

  return (
    <>
      <TableCell data-qa={`Parent-Location- ${index}`}>
        <Typography variant="body2" color="textPrimary">
          {_.get(row, 'location.name')}
        </Typography>
      </TableCell>
      <TableCell align="center" data-qa={`Daily-Consumption-Rate- ${index}`}>
        {row.dailyConsumptionRate}
      </TableCell>
      <TableCell align="center" data-qa={`Minimum- ${index}`}>
        {row.min}
      </TableCell>
      <TableCell align="center" data-qa={`Maximum- ${index}`}>
        {row.max}
      </TableCell>
      <TableCell align="center" data-qa={`Alert- ${index}`}>
        {row.alert}
      </TableCell>
      <TableCell align="center">
        <FBOMenuButton
          variant="tertiary"
          items={createActionBarOptions(handleAction)}
          data-qa="reorder-table-three-dot-menu"
          style={{ color: colorPalette.redesign.contentSecondary }}
        />
      </TableCell>
    </>
  );
};

export default memo(ReorderTabRow);
