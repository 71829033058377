import React, { useMemo } from 'react';
import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { Pagination } from 'services/search';
import { PermissionType } from 'services/permissions';
import { arrayHasAllDeletedObjects } from 'helpers';
import { CarriersSearchResultsProps } from './types';
import { CARRIER_COLUMNS, CARRIER_COLUMNS_SMALL } from '../../consts';
import { createCarriersSearchActionBarOptions } from './consts';
import { default as CarrierRow } from './CarrierRow';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';
import { useSelector } from 'react-redux';
import { getActiveUser } from 'services/userV2';
import { useFlags } from 'helpers/useFlags';

const CarriersSearchResults: React.FC<CarriersSearchResultsProps> = (props) => {
  const {
    activeCarrierId,
    handleCarrierClick,
    carriers,
    onAddNewPress,
    onPaginationChange,
    isLoadingCarriers,
    pagination,
    activeDate,
    onCarriersPageAction,
  } = props;

  const activeUser = useSelector(getActiveUser);
  const flags = useFlags();
  const csvDisabled = flags.newUserEndpoint && !activeUser.user?.admin;

  const allDeletedCarriers = useMemo(
    () => arrayHasAllDeletedObjects(carriers),
    [carriers]
  );

  const handlePaginationChange = (newPagination: Pagination) => {
    onPaginationChange(newPagination);
  };

  return (
    <>
      <FBOTitleBar title="Carriers">
        {activeCarrierId !== -1 && (
          <FBOButton
            style={{ marginRight: '8px' }}
            variant={!activeCarrierId ? 'primary' : 'secondary'}
            color="positive"
            size="medium"
            icon="FBOAddCircle"
            onClick={onAddNewPress}
            data-qa="carrier-new"
            permissions={[PermissionType.CarriersCreate]}
          >
            {!activeCarrierId ? 'Add New' : ''}
          </FBOButton>
        )}
        <FBOMenuButton
          variant="secondary"
          items={createCarriersSearchActionBarOptions(
            onCarriersPageAction,
            carriers.length,
            csvDisabled
          )}
          data-qa="carriers-page-three-dot-menu"
        />
      </FBOTitleBar>

      <ItemsTable
        data={carriers}
        columns={
          activeCarrierId ? CARRIER_COLUMNS_SMALL : CARRIER_COLUMNS(activeDate)
        }
        RenderCustomRow={CarrierRow}
        activeId={activeCarrierId}
        onItemClick={handleCarrierClick}
        selectableItems={false}
        disableSelection={allDeletedCarriers}
        isLoadingData={isLoadingCarriers}
        pagination={pagination}
        onPaginationChange={handlePaginationChange}
        dataQa="carriers-table"
        showEnhancedTablePaginationActions={!Boolean(activeCarrierId)}
      />
    </>
  );
};

export default CarriersSearchResults;
