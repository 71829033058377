import _ from 'lodash';
import { initialAddress } from './consts';

import {
  BillToShipToAddress,
  BillToShipToAddressBeta,
  Address,
  AddressValidation,
} from './types';
import { Customer } from 'services/customers/types';

export const transformAddressToBillToShipToAddress = (
  address: Address
): BillToShipToAddress =>
  _.pick(address, [
    'city',
    'country',
    'street',
    'street2',
    'postalCode',
    'name',
    'state',
    'companyName',
    'residential',
    'attention',
    'defaultBillTo',
    'defaultShipTo',
    'verified',
  ]);

export const transformAddressToBillToShipToAddressBeta = (
  address: Address,
  customer: Customer | null
): BillToShipToAddressBeta => {
  const addressPhone =
    address.contacts.find(
      (contact) => contact.defaultFlag && contact.type === 'Mobile'
    )?.value ||
    address.contacts.find(
      (contact) => contact.defaultFlag && contact.type === 'Office'
    )?.value ||
    null;

  const customerPhone =
    customer?.office || customer?.mobile || customer?.other || null;

  const tempEmail =
    address.contacts.find(
      (contact) => contact.defaultFlag && contact.type === 'Email'
    )?.value ||
    customer?.email ||
    null;

  const tempPhone = addressPhone || customerPhone;

  const tempAddress = {
    ...address,
    phone: tempPhone,
    email: tempEmail,
  };

  return _.pick(tempAddress, [
    'city',
    'country',
    'street',
    'street2',
    'postalCode',
    'name',
    'state',
    'companyName',
    'residential',
    'attention',
    'defaultBillTo',
    'defaultShipTo',
    'verified',
    'email', // @beta: additionally added for testing
    'phone', // @beta: additionally added for testing
  ]);
};

export const transformAddress = (res: any): Address => {
  return {
    id: res.id,
    attention: res.attention,
    city: res.city,
    companyName: res.companyName,
    contacts: res.contacts,
    country: res.country,
    defaultBillTo: res.defaultBillTo,
    defaultShipTo: res.defaultShipTo,
    deleted: res.deleted,
    email: res.email,
    name: res.name,
    phone: res.phone,
    postalCode: res.postalCode,
    residential: res.residential,
    state: res.state,
    street: res.street,
    street2: res.street2,
    version: res.version,
    verified: res.verified,
  };
};

export const transformValidAddress = (res: any): AddressValidation => {
  const original = res.original;
  const validated = res.validated;
  return {
    original: {
      ...initialAddress,
      name: original.name,
      street: original.street1,
      street2: original.street2,
      postalCode: original.zip,
      city: original.city,
      state: original.state,
      companyName: original.company,
      country: original.country,
      phone: original.phone,
      email: original.email,
      residential: original.residential,
    },
    validated: {
      ...initialAddress,
      name: validated.name,
      street: validated.street1,
      street2: validated.street2,
      postalCode: validated.zip,
      city: validated.city,
      state: validated.state,
      companyName: validated.company,
      country: validated.country,
      phone: validated.phone,
      email: validated.email,
      residential: validated.residential,
    },
    isValid: res.isValid,
    isComplete: res.isComplete,
    messages: res.messages.map((m: any) => m.body),
  };
};

export const transformValidAddressRequest = (address: Address): any => {
  return {
    name: address.name ? address.name : '',
    company: address.companyName,
    street1: address.street,
    street2: address.street2,
    city: address.city,
    state: address.state,
    zip: address.postalCode,
    country: address.country,
    phone: address.phone,
    email: address.email,
    residential: address.residential,
  };
};

export const transformAddressRequest = (address: Address): any => {
  const body = {
    attention: address.attention,
    // We send empty strings because those fields are required on location addresses
    city: address.city || '',
    companyName: address.companyName,
    contacts: address.contacts,
    country: address.country,
    defaultBillTo: address.defaultBillTo,
    defaultShipTo: address.defaultShipTo,
    deleted: address.deleted,
    email: address.email,
    name: address.name || '',
    phone: address.phone,
    postalCode: address.postalCode || '',
    residential: address.residential,
    state: address.state || '',
    street: address.street || '',
    street2: address.street2,
    verified: address.verified,
  };

  // POST
  if (!address.id || address.id < 0) {
    return body;
  }

  // PUT
  return {
    ...body,
    id: address.id,
    version: address.version,
  };
};
