import { UomConversion } from './types';

export const defaultUomConversion: UomConversion = {
  id: -1,
  dateCreated: null,
  dateLastModified: null,
  deleted: false,
  factor: null,
  fromUomId: null,
  itemUomConversionList: [],
  toUomId: null,
  version: 0,
};
