import React from 'react';
import { AuthContainer } from 'ui/modules/public/components';

const MaintenancePage: React.FC = () => {
  return (
    <AuthContainer
      showNotACustomer={true}
      title="Site Under Maintenance"
      sx={{ height: '320px', width: '440px', borderRadius: '10px' }}
      isMaintenancePage
    >
      <p
        style={{
          margin: '16px auto 0px',
          width: '360px',
          textAlign: 'center',
          fontSize: '16px',
          fontFamily: 'Inter, sans-serif',
        }}
      >
        We are undergoing maintenance to enhance your experience. Thank you for
        your patience.
      </p>
    </AuthContainer>
  );
};

export default MaintenancePage;
