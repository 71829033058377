import { initialAddress, transformAddress } from 'services/addresses';
import { Location } from './types';

export const transformLocation = (res: any): Location => {
  return {
    address: res.address ? transformAddress(res.address) : initialAddress,
    addressId: res.addressId,
    companyWide: res.companyWide,
    dateCreated: res.dateCreated,
    dateLastModified: res.dateLastModified,
    deleted: res.deleted,
    depth: res.depth,
    description: res.description,
    displayName: res.displayName,
    errors: res.errors,
    heritage: res.heritage,
    id: res.id,
    name: res.name,
    parentLocation: res.parentLocation,
    parentLocationId: res.parentLocationId,
    rootLocationId: res.rootLocationId,
    defaultReceivingLocation: res.defaultReceivingLocation,
    defaultShippingLocation: res.defaultShippingLocation,
    path: res.path,
    sortNumber: res.sortNumber,
    totalLocationQty: res.totalLocationQty,
    useParentAddress: res.useParentAddress,
    version: res.version,
    type: res.type,
    locked: false,
    availableToPickQty: res.availableToPickQty,
  };
};

export const transformLocationRequest = (location: Location): any => {
  const body = {
    addressId: location.addressId,
    companyWide: location.companyWide,
    dateCreated: location.dateCreated,
    dateLastModified: location.dateLastModified,
    deleted: location.deleted,
    depth: location.depth,
    description: location.description,
    displayName: location.displayName,
    errors: location.errors,
    heritage: location.heritage,
    name: location.name,
    parentLocationId: location.parentLocationId,
    path: location.path,
    totalLocationQty: location.totalLocationQty,
    type: location.type,
    useParentAddress: location.useParentAddress,
    defaultReceivingLocation: location.defaultReceivingLocation,
    defaultShippingLocation: location.defaultShippingLocation,
    locked: false,
  };

  // POST
  if (location.id && location.id < 0) {
    return body;
  }

  // PUT
  return { ...body, id: location.id, version: location.version };
};

export const transformLocationToSort = (location: Location): any => ({
  id: location.id,
  sortNumber: location.sortNumber,
});
