import { createApiCall } from 'services/api';

import {
  transformPickOrderSettings,
  transformToPoSettingsPut,
} from './transformations';
import { PickingSettings } from './types';

export const getPoSettings = async () => {
  const poSettingsResponse = await createApiCall({
    path: `/v1/picking_settings`,
    method: `GET`,
  })();

  return transformPickOrderSettings(poSettingsResponse.data);
};

export const putPoSettings = async (poSettings: PickingSettings) => {
  const poSettingsResponse = await createApiCall(
    {
      path: `/v1/picking_settings`,
      method: `PUT`,
      body: transformToPoSettingsPut(poSettings),
    },
    {
      getMessage: {
        success: () => 'Updated picking settings',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();
  return transformPickOrderSettings(poSettingsResponse.data);
};
