import React, { createContext, useEffect, useState } from 'react';
import { Errors } from 'services/forms/validation';
import { SalesOrder } from 'services/salesOrders';

import { defaultFormValuesCard, defaultShownValues } from './consts';
import {
  PaymentFormValuesCard,
  PaymentShownValues,
  PaymentType,
} from './types';

export const PaymentContext = createContext<{
  paymentAmount: number | null;
  setPaymentAmount: React.Dispatch<React.SetStateAction<number | null>>;
  activePaymentType: PaymentType;
  setActivePaymentType: React.Dispatch<React.SetStateAction<PaymentType>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  shownValues: PaymentShownValues;
  formValuesCard: PaymentFormValuesCard;
  setFormValuesCard: React.Dispatch<
    React.SetStateAction<PaymentFormValuesCard>
  >;
  validationErrors: Errors;
  setValidationErrors: React.Dispatch<React.SetStateAction<Errors>>;
}>({
  paymentAmount: null,
  setPaymentAmount: () => {},
  activePaymentType: PaymentType.Card,
  setActivePaymentType: () => {},
  isLoading: false,
  setIsLoading: () => {},
  shownValues: defaultShownValues,
  formValuesCard: defaultFormValuesCard,
  setFormValuesCard: () => {},
  validationErrors: {},
  setValidationErrors: () => {},
});

export const PaymentProvider: React.FC<{ salesOrder: SalesOrder }> = ({
  children,
  salesOrder,
}) => {
  const [paymentAmount, setPaymentAmount] = useState<number | null>(null);
  const [activePaymentType, setActivePaymentType] = useState<PaymentType>(
    PaymentType.Card
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [shownValues, setShownValues] =
    useState<PaymentShownValues>(defaultShownValues);
  const [formValuesCard, setFormValuesCard] = useState<PaymentFormValuesCard>(
    defaultFormValuesCard
  );
  const [validationErrors, setValidationErrors] = useState<Errors>({});

  useEffect(() => {
    setShownValues((s) => ({
      ...s,
      balance: s.total - s.totalPayments - (paymentAmount || 0.0),
    }));
  }, [paymentAmount]);

  useEffect(() => {
    const total = salesOrder.grandTotal || 0.0;
    const totalPayment = salesOrder.paymentTotal || 0.0;

    setShownValues((oldState) => ({
      ...oldState,
      totalPayments: totalPayment,
      total,
      balance: total - totalPayment,
    }));
  }, [salesOrder]);

  return (
    <PaymentContext.Provider
      value={{
        paymentAmount,
        setPaymentAmount,
        activePaymentType,
        setActivePaymentType,
        isLoading,
        setIsLoading,
        shownValues,
        formValuesCard,
        setFormValuesCard,
        validationErrors,
        setValidationErrors,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};
