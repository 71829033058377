import {
  Receipt,
  ReceiptItem,
  ReceiptItemReceive,
  ReceiptItemType,
  ReceiptStatus,
} from './types';

export const initialReceipt: Receipt = {
  id: null,
  dateCreated: null,
  dateLastModified: null,
  documents: [],
  location: null,
  locationId: null,
  notes: null,
  number: null,
  orderType: null,
  purchaseOrder: null,
  purchaseOrderId: null,
  purchaseUser: null,
  purchaseUserId: null,
  receiptItems: [],
  salesOrder: null,
  salesOrderId: null,
  status: ReceiptStatus.Open,
  version: null,
};

export const initialReceiptItem: ReceiptItem = {
  id: null,
  addToVendorBill: false,
  billedTotalCost: null,
  carrier: null,
  carrierId: null,
  carrierService: null,
  carrierServiceId: null,
  carrierTracking: null,
  carrierPackageCount: null,
  customer: null,
  customerId: null,
  dateBilled: null,
  dateCreated: null,
  dateLastModified: null,
  dateReceived: null,
  dateReconciled: null,
  deliverTo: null,
  item: null,
  itemId: null,
  landedTotalCost: null,
  location: null,
  locationId: null,
  multiCurrencyBilledTotalCost: null,
  multiCurrencyLandedTotalCost: null,
  notes: null,
  purchaseOrderItem: null,
  purchaseOrderItemId: null,
  quantity: null,
  referenceNumber: null,
  reasonForRejection: null,
  receiptId: null,
  rejectionResponsibility: null,
  salesOrderItem: null,
  salesOrderItemId: null,
  receiptItemType: ReceiptItemType.Purchase,
  status: null,
  trackingGroupList: [],
  uom: null,
  uomId: null,
  version: null,
};

export const initialReceiptItemReceive: ReceiptItemReceive = {
  id: -1,
  receiptItemId: -1,
  receiptItem: initialReceiptItem,
  locationId: null,
  quantity: null,
  newUnitCost: null,
  dateReceived: null,
  trackingGroups: [],
};
