import { IconNames } from 'ui/theme';
import { ColumnTypes } from 'ui/components/Table/ItemsTable';
import { Column } from 'ui/components/Table/ItemsTable/types';
import { MenuItem } from 'ui/components/Menu';
import { Dates } from 'helpers';

export const RECEIVING_COLUMNS = (date: Dates): Column[] => {
  const resolveDateColumn = (): Column => {
    switch (date) {
      case Dates.DateCreated:
        return {
          title: 'Date Created',
          field: Dates.DateCreated,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
        };
      case Dates.DateLastModified:
        return {
          title: 'Date Last Modified',
          field: Dates.DateLastModified,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
        };
      default:
        return {
          title: 'Date Issued',
          field: Dates.ReceivingDateIssued,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
        };
    }
  };

  return [
    {
      title: 'Order',
      field: 'number',
      sortable: true,
      type: ColumnTypes.string,
      width: 100,
    },
    {
      title: 'Status',
      type: ColumnTypes.renderByCustomRow,
      field: 'status',
      sortable: true,
    },
    {
      title: 'Vendor',
      type: ColumnTypes.renderByCustomRow,
      searchable: false,
      field: 'purchaseOrder.vendor.name,salesOrder.customer.name',
      sortable: true,
    },
    resolveDateColumn(),
    {
      title: 'Carrier',
      type: ColumnTypes.renderByCustomRow,
      searchable: false,
      field: 'purchaseOrder.carrier.name,salesOrder.carrier.name',
      sortable: true,
    },
    {
      title: 'Customer PO/SO',
      type: ColumnTypes.renderByCustomRow,
      searchable: false,
      field: 'purchaseOrder.customerSONumber,salesOrder.customerPONumber',
      sortable: true,
    },
    {
      title: 'Vendor PO/SO',
      type: ColumnTypes.renderByCustomRow,
      searchable: false,
      field: 'purchaseOrder.vendorSONumber,salesOrder.vendorPONumber',
      sortable: true,
    },
  ];
};

export const RECEIVING_COLUMNS_SMALL: Column[] = [
  {
    title: 'Order',
    field: 'number',
    type: ColumnTypes.string,
  },
  {
    title: 'Status',
    type: ColumnTypes.renderByCustomRow,
    field: 'status',
  },
  {
    title: 'Vendor',
    type: ColumnTypes.renderByCustomRow,
    searchable: false,
    field: 'purchaseOrder.vendor.name,salesOrder.customer.name',
  },
];

export const ActionBarOptions: MenuItem[] = [
  { icon: IconNames.Help, label: 'Not yet defined.' },
];

export const SettingsBarOptions: MenuItem[] = [
  { icon: IconNames.Help, label: 'Not yet defined.' },
];

export enum ReceivingPageAction {
  ChangeDate,
}

export const createActionBarOptions = (
  onActionClick: (actionType: ReceivingPageAction, date: Dates) => void
): MenuItem[] => [
  {
    icon: IconNames.FBORecycleSmall,
    label: 'Change Dates',
    subItems: [
      {
        label: 'Date Created',
        onOptionClick: () =>
          onActionClick(ReceivingPageAction.ChangeDate, Dates.DateCreated),
        separateAfter: true,
      },
      {
        label: 'Date Last Modified',
        onOptionClick: () =>
          onActionClick(ReceivingPageAction.ChangeDate, Dates.DateLastModified),
        separateAfter: true,
      },
      {
        label: 'Date Issued',
        onOptionClick: () =>
          onActionClick(ReceivingPageAction.ChangeDate, Dates.DateIssued),
      },
    ],
  },
];
