import React, { memo } from 'react';

import { Box, Button, ButtonGroup, Card, Grow, Popper } from '@mui/material';
import { ClickAwayListener } from '@mui/base';
import { useEnhancedFooterStyles } from './styled';

import { EnhancedTablePaginationCountMenuProps } from './types';

const EnhancedTablePaginationCountMenu = ({
  pagination,
  onClickAway,
  open,
  anchorEl,
  onChangePage,
  firstPageTitle = 'Newest',
  lastPageTitle = 'Oldest',
}: EnhancedTablePaginationCountMenuProps) => {
  const classes = useEnhancedFooterStyles();
  const lastPage = Math.ceil(pagination.totalRows / pagination.pageSize);

  return (
    <Popper open={open} anchorEl={anchorEl} transition placement="top-end">
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Box>
            <ClickAwayListener onClickAway={onClickAway}>
              <Card className={classes.tablePaginationMenu}>
                <ButtonGroup size="small" variant="text" orientation="vertical">
                  <Button
                    title={firstPageTitle}
                    disabled={pagination.page === 1}
                    className={classes.tablePaginationLabelButton}
                    onClick={(e) => onChangePage(e, 0)}
                  >
                    {firstPageTitle}
                  </Button>
                  <Button
                    title={lastPageTitle}
                    disabled={pagination.page >= lastPage}
                    className={classes.tablePaginationLabelButton}
                    onClick={(e) => onChangePage(e, lastPage - 1)}
                  >
                    {lastPageTitle}
                  </Button>
                </ButtonGroup>
              </Card>
            </ClickAwayListener>
          </Box>
        </Grow>
      )}
    </Popper>
  );
};

export default memo(EnhancedTablePaginationCountMenu);
