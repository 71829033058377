import React, { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Box, FormControlLabel, Checkbox } from '@mui/material';

import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import { QuillTextEditor } from 'ui/components/TextField/QuillTextEditor';
import {
  initialEmail,
  PurchaseOrderEmail,
  sendPurchaseOrderEmail,
} from 'services/purchaseOrders';
import { useHandleTextFieldChange } from 'services/forms';
import { Errors, validateYup } from 'services/forms/validation';
import {
  fetchReportPresets,
  ReportId,
  reportList,
  getReportBlob,
} from 'services/reports';
import { getActiveUser } from 'services/user';
import { showNotification } from 'services/api';

import { EmailModalProps } from './types';
import { yupPoEmailModalSchema } from '../../validations';

const FBOPurchaseEmailModal: React.FC<EmailModalProps> = (props) => {
  const { show, purchaseOrder, onClose } = props;

  const { user: activeUser } = useSelector(getActiveUser);
  const activeUserId = activeUser ? activeUser.id! : 0;

  const [email, setEmail] = useState<PurchaseOrderEmail>(initialEmail);
  const [sendReport, setSendReport] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Errors>({});

  useEffect(() => {
    if (show) {
      setEmail({
        ...initialEmail,
        to: purchaseOrder.email,
        cc: purchaseOrder.vendor?.cc ?? null,
        bcc: purchaseOrder.vendor?.bcc ?? null,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const handleTextFieldchange = useHandleTextFieldChange(setEmail, email);

  const handleQuillTextChange = useCallback((content: string) => {
    setEmail((old) => ({ ...old, body: content }));
  }, []);

  const handleSendReportChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setSendReport(checked);
  };

  const fetchReportBlob = async () => {
    const presets = await fetchReportPresets(
      activeUserId || -1,
      ReportId.PurchaseOrder
    );

    const report = reportList.find(
      (report) => report.reportId === ReportId.PurchaseOrder
    )!;

    const defaultPreset = presets.find((p) => p.defaultFlag);

    const requiredParameters = { purchaseOrderId: purchaseOrder.id };

    const parameters = defaultPreset
      ? { ...defaultPreset.parameters, ...requiredParameters }
      : { ...report.parameters, ...requiredParameters };

    const blob = await getReportBlob(ReportId.PurchaseOrder, parameters);

    return blob;
  };

  const handleApplyClicked = async () => {
    // validation
    const isValid = validateYup(email, yupPoEmailModalSchema, setErrors);
    if (!isValid) {
      return;
    }

    setLoading(true);

    let file = new Blob();

    if (sendReport) {
      try {
        file = await fetchReportBlob();
      } catch {
        showNotification(
          'Something went wrong while generating report. Please try again.',
          { variant: 'error' }
        );

        setLoading(false);
        return;
      }
    }

    try {
      await sendPurchaseOrderEmail(
        email,
        purchaseOrder.number!,
        sendReport ? file : undefined
      );
    } catch {
      setLoading(false);
      return;
    }

    setLoading(false);
    onClose();
  };

  return (
    <Modal
      open={show}
      onClose={onClose}
      title="Send Email"
      applyLabel="send"
      onApplyClicked={handleApplyClicked}
      onCancelClicked={onClose}
      withoutDefaultPadding
      footerDivider="shadow"
      customHeight={630}
      maxWidth="sm"
      isLoadingContent={loading}
      disableEnter={true}
    >
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              className="redesign"
              type="text"
              label="To"
              name="to"
              placeholder="Enter email address"
              onChange={handleTextFieldchange}
              value={email.to}
              required
              error={!!errors.to}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              className="redesign"
              type="text"
              label="Cc"
              name="cc"
              placeholder="Enter email address"
              onChange={handleTextFieldchange}
              value={email.cc}
              error={!!errors.cc}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              className="redesign"
              type="text"
              label="Bcc"
              name="bcc"
              placeholder="Enter email address"
              onChange={handleTextFieldchange}
              value={email.bcc}
              error={!!errors.cc}
              helperText={errors.cc}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              className="redesign"
              type="text"
              name="subject"
              placeholder="Subject"
              onChange={handleTextFieldchange}
              value={email.subject}
              required
              error={!!errors.subject}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={sendReport}
                  onChange={handleSendReportChange}
                />
              }
              label="Send report"
            />
          </Grid>
          <Grid item xs={12}>
            <QuillTextEditor
              placeholder="Message"
              onChange={handleQuillTextChange}
              value={email.body || ''}
              error={!!errors.body}
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default memo(FBOPurchaseEmailModal);
