export enum Routes {
  SetupRouter = '/setup/*',
  UnitOfMeasurementPage = 'unit-of-measurement',
  LocationPage = 'location',
  UsersPage = 'users',
  PermissionsPage = 'permissions',
  PaymentTermsPage = 'payment-terms',
  RepresentativesPage = 'representatives',
  TaxRatesPage = 'tax-rates',
  CarriersPage = 'carriers',
  CurrencyPage = 'currency',
  SettingsPage = 'settings',
}
