import React, { useEffect, useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import _ from 'lodash';

import { ImageItemBox } from 'ui/components/Image/ImageItemBox';
import { DOT_CHAR } from 'helpers';
import { ItemType } from 'services/items';
import { ItemOptionProps } from './types';
import {
  ItemInventory,
  getItemInventory,
  initialItemInventory,
} from 'services/inventory';

const ItemOption: React.FC<ItemOptionProps> = (props) => {
  const { option, locationId } = props;
  const [itemInventory, setItemInventory] =
    useState<ItemInventory>(initialItemInventory);

  const nameResolved = useMemo(() => {
    const Item = option.saleItemList.find((i) => i.itemId === option.id);
    const ItemName = Item ? `${DOT_CHAR} ${Item.name}` : '';
    return `${_.get(option, 'name', '')} ${ItemName}`;
  }, [option]);
  useEffect(() => {
    const fetchData = async () => {
      const inventory = await getItemInventory(option.id!);
      setItemInventory(inventory);
    };

    fetchData();
  }, [option.id]);
  const availableQty = useMemo(() => {
    if (locationId) {
      const locationSummary = itemInventory.inventoryRowList.find(
        (summary) => summary.id === locationId
      );
      return (
        (locationSummary?.totalQty || 0) - (locationSummary?.committedQty || 0)
      );
    }
    return 0;
  }, [itemInventory, locationId]);

  if (!option.id || option.id < 0) {
    return <Typography variant="body1">+ Add "{option.name}"</Typography>;
  }

  return (
    <Box display="flex" width="100%" flexDirection="column">
      <Box display="flex" width="100%">
        <Box flexGrow={1} minWidth={0}>
          <ImageItemBox
            name={`${nameResolved}${
              option.sku ? ` ${DOT_CHAR} SKU: ${option.sku}` : ''
            }`}
            description={_.get(option, 'description', null)}
            source={_.get(option, 'defaultImage', null)}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          width={130}
          textAlign="right"
          flexShrink={0}
        >
          {option.itemType === ItemType.Inventory ? (
            <Typography variant="subtitle2" color="textSecondary">
              {availableQty} {option.defaultUom?.abbreviation}
            </Typography>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(ItemOption);
