import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useReconcileWizardlStyle = makeStyles((theme: Theme) => ({
  btnWithSpace: {
    marginRight: theme.spacing(2),
  },
  gridWithPadding: {
    padding: theme.spacing(3),
    flexShrink: 0,
  },
  modalContent: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    borderColor: theme.palette.divider,
    borderLeftStyle: 'solid',
    flex: 1,
    height: '100%',
  },
  modalSidebar: {
    height: '100%',
  },
  inventoryTableGrid: {
    flexGrow: 1,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  actionButtonMargin: {
    marginRight: theme.spacing(2),
  },
  cancelButton: {
    color: theme.palette.text.primary,
  },
  gridRoot: {
    height: '100%',
  },
}));
