import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { InputAdornment, SelectChangeEvent } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import { TextField } from 'ui/components/TextField/TextField';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import { Location, LocationType } from 'services/locations';
import { getUomById } from 'services/uoms';
import { useHandleTextFieldChange } from 'services/forms';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';

import { EventFormProps, QuantityToCycleInputProps } from '../../types';
import UomSelect from '../UomDropdown';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { themeRestyle } from 'ui/theme';
import { CustomersAutocomplete } from 'ui/components/Autocomplete/CustomerAutocomplete';
import { ClassAutocomplete } from 'ui/components/Autocomplete/ClassesAutocomplete';
import { Customer } from 'services/customers';
import { Class } from 'services/classes';
import _ from 'lodash';

export const QuantityToCycleInput: React.FC<QuantityToCycleInputProps> = ({
  setFormValues,
  value,
  hasError,
  errorMessage = '',
  formValues,
  onlySerial,
  noTracking,
  dataQa,
}) => {
  const handleAmountChange = useHandleTextFieldChange(
    setFormValues,
    formValues,
    true
  );

  const handleAmountChangeSerial = useHandleTextFieldChange(
    setFormValues,
    formValues,
    false
  );
  const isFieldEditable = onlySerial || noTracking;

  return (
    <TextField
      className="redesign"
      variant="standard"
      value={value}
      error={hasError}
      type="number"
      label="Cycle Quantity"
      onChange={onlySerial ? handleAmountChangeSerial : handleAmountChange}
      name="quantity"
      dataQa={dataQa ?? 'amt-to-cycle'}
      disableDebounce={true}
      helperText={errorMessage}
      disabled={!formValues.locationFromId}
      readOnly={!isFieldEditable}
    />
  );
};

const FBOEventCycleForm: React.FC<EventFormProps> = (props) => {
  const {
    formValues,
    onlySerial,
    noTracking,
    validationErrors,
    averageCost,
    totalQty,
    committedQty,
    setFormValues,
    setOldFormValues,
    errorMessage = '',
    uomIds,
    defaultUomAbbreviation,
    hasSerialTracking,
  } = props;

  const selectedUom = useSelector(getUomById(formValues.uomId)) || null;

  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    null
  );
  const [key, setKey] = useState(0);

  const handleLocationChange = useCallback(
    (value: Location | null) => {
      setSelectedLocation(value);
      setKey((key) => key + 1);
      setShowModal(true);
    },
    [setShowModal, setSelectedLocation]
  );

  const confirmLocationChange = useCallback(
    (value: Location | null) => {
      //test if value is location and not event type
      if (value?.addressId) {
        setFormValues((f) => ({
          ...f,
          locationFromId: value?.id || null,
        }));
        setShowModal(false);
        return;
      }
      setFormValues((f) => ({
        ...f,
        locationFromId: selectedLocation?.id || null,
      }));
      setShowModal(false);
      setKey((key) => key + 1);
    },
    [setFormValues, selectedLocation]
  );

  const handleUomSelectChange = (e: SelectChangeEvent) => {
    const uomId = e.target.value;
    setFormValues((form) => ({ ...form, uomId: Number(uomId) }));
  };
  const handleTextFieldChange = useHandleTextFieldChange(
    setFormValues,
    formValues,
    true
  );

  const handleCustomerChange = useCallback(
    (customer: Customer | null) => {
      setFormValues((form) => ({
        ...form,
        materialExpensing: _.merge({}, form.materialExpensing, {
          customerId: customer?.id ?? null,
        }),
      }));
    },
    [setFormValues]
  );

  const handleClassChange = useCallback(
    (accountingClass: Class | null) => {
      setFormValues((form) => ({
        ...form,
        materialExpensing: _.merge({}, form.materialExpensing, {
          accountingClassId: accountingClass?.id ?? null,
        }),
      }));
    },
    [setFormValues]
  );
  useEffect(() => {
    setFormValues((old) => ({ ...old, quantity: totalQty }));
    setOldFormValues((old) => ({ ...old, quantity: totalQty }));
  }, [setFormValues, totalQty]);

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        columns={16}
        columnSpacing={themeRestyle.spacing(2)}
        padding={`0px ${themeRestyle.spacing(4)} 0px ${themeRestyle.spacing(
          4
        )}`}
        disableEqualOverflow
      >
        <Grid xs={4}>
          <LocationsAsyncAutocomplete
            key={key} //causes re-render so we only show the selected location
            value={formValues.locationFromId}
            getLocationLabel={(l) => l.path || ''}
            label="Location"
            required
            placeholder="Select Location"
            onChange={
              formValues.locationFromId
                ? handleLocationChange
                : confirmLocationChange
            }
            companyWide={false}
            error={!!validationErrors.locationFromId}
            locationTypes={[
              LocationType.Stock,
              LocationType.Receiving,
              LocationType.Shipping,
              LocationType.Locked,
            ]}
            dataQa="cycle-inventory-location"
          />
        </Grid>
        <Grid xs={4}>
          <QuantityToCycleInput
            value={formValues.quantity}
            hasError={!!validationErrors.quantity || Boolean(errorMessage)}
            setFormValues={setFormValues}
            formValues={formValues}
            errorMessage={errorMessage}
            onlySerial={onlySerial}
            noTracking={noTracking}
            dataQa="amt-to-cycle"
          />
        </Grid>

        <Grid xs={2}>
          <UomSelect
            onChange={handleUomSelectChange}
            selectedUomId={selectedUom?.id ?? null}
            uomsWithConversionIds={uomIds}
            disabled={hasSerialTracking}
            data-qa="cycle-inventory-uom-select"
          />
        </Grid>
        <Grid xs={3}>
          <TextField
            className="redesign"
            variant="standard"
            readOnly
            label="Available to Cycle"
            value={totalQty - committedQty}
            additionalInputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {defaultUomAbbreviation}
                </InputAdornment>
              ),
            }}
            data-qa="cycle-inventory-available-qty"
          />
        </Grid>
        <Grid xs={3}>
          <CurrencyField
            readOnly
            label="Avg. Unit Cost"
            value={parseFloat(averageCost)}
            allowNegative
            data-qa="cycle-inventory-unit-cost"
          />
        </Grid>

        <Grid container xs={12}>
          <Grid xs={6}>
            <CustomersAutocomplete
              label="Customer/Job"
              onChange={handleCustomerChange}
              value={formValues.materialExpensing?.customerId ?? null}
              placeholder="Select Customer/Job"
              dataQa="cycle-inventory-customer"
            />
          </Grid>
          <Grid xs={6}>
            <ClassAutocomplete
              value={formValues.materialExpensing?.accountingClassId ?? null}
              onChange={handleClassChange}
              label="Class/Category"
              placeholder="Select Class/Category"
              dataQa="cycle-inventory-accounting-class"
            />
          </Grid>
        </Grid>

        <Grid xs={16}>
          <TextField
            className="redesign"
            variant="standard"
            label="Notes"
            type="text"
            placeholder="Notes"
            name="notes"
            value={formValues.notes}
            onChange={handleTextFieldChange}
            sx={{ marginTop: errorMessage ? '' : '24px' }}
            inputProps={{
              'data-qa': 'cycle-inventory-notes',
            }}
          />
        </Grid>
      </Grid>
      <ConfirmationModal
        open={showModal}
        onConfirmClicked={confirmLocationChange}
        onCancelClicked={() => {
          setShowModal(false);
          setSelectedLocation(null);
        }}
        title="Change Location?"
        body="Are you sure you want to change the location? All newly inputted tracking will be removed."
        dataQa="confirmation-modal"
      />
    </>
  );
};

export default FBOEventCycleForm;
