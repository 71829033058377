import { SetStateAction } from 'react';
import {
  ItemInventory,
  ItemInventoryEvents,
  TrackingGroup,
} from 'services/inventory';
import { TrackingTableTypes } from 'ui/components/Table/TrackingTable';
import { QuickTransferFormValues } from '../QuickTransferModal/types';

interface ResolvedLabel {
  modalLabel: string;
  buttonLabel: string;
}

export function resolveLabels(
  eventType: ItemInventoryEvents,
  quantity: number,
  uom: string = 'Items',
  itemName?: string | null
): ResolvedLabel {
  switch (eventType) {
    case ItemInventoryEvents.Add:
      return {
        modalLabel: 'Add inventory',
        buttonLabel: `Add ${quantity} ${uom}`,
      };
    case ItemInventoryEvents.Remove:
      return {
        modalLabel: 'Scrap Inventory',
        buttonLabel: `Scrap ${quantity} ${uom}`,
      };
    case ItemInventoryEvents.Cycle:
      return {
        modalLabel: `Cycle Inventory - ${itemName}`,
        buttonLabel: `Cycle ${quantity} ${uom}`,
      };
    case ItemInventoryEvents.Move:
      return {
        modalLabel: 'Move inventory',
        buttonLabel: `Move ${quantity} ${uom}`,
      };
  }
}

export function mapInventoryEventToTrackingTableType(
  event: ItemInventoryEvents
): TrackingTableTypes {
  switch (event) {
    case ItemInventoryEvents.Add:
      return TrackingTableTypes.Add;
    case ItemInventoryEvents.Cycle:
      return TrackingTableTypes.Cycle;
    default:
      return TrackingTableTypes.Remove;
  }
}

export function sortDuplicateTrackingGroupsToTop(
  trackingGroups: TrackingGroup[],
  setTrackingGroups: React.Dispatch<SetStateAction<TrackingGroup[]>>,
  duplicatedTrackingGroupIds: string[]
) {
  const dupeTrackingGroups: TrackingGroup[] = [];
  const nonDupeTrackingGroups: TrackingGroup[] = [];

  trackingGroups.forEach((tg) => {
    if (tg.id && duplicatedTrackingGroupIds.includes(tg.id)) {
      dupeTrackingGroups.push(tg);
    } else {
      nonDupeTrackingGroups.push(tg);
    }
  });

  setTrackingGroups([...dupeTrackingGroups, ...nonDupeTrackingGroups]);
}

export function sortSearchedTrackingGroupsToTop(
  trackingGroups: TrackingGroup[],
  searchedTrackingGroupIds: string[]
) {
  const searchedTrackingGroups: TrackingGroup[] = [];
  const otherTrackingGroups: TrackingGroup[] = [];

  trackingGroups.forEach((tg) => {
    if (tg.id && searchedTrackingGroupIds.includes(tg.id)) {
      searchedTrackingGroups.push(tg);
    } else {
      otherTrackingGroups.push(tg);
    }
  });

  return [...searchedTrackingGroups, ...otherTrackingGroups];
}
export const validateBulkItemMoveQuantity = (
  formValues: QuickTransferFormValues,
  itemInventory: ItemInventory,
  tableData: QuickTransferFormValues[]
) => {
  const locationSummary = itemInventory.inventoryRowList.find(
    (summary) => summary.id === formValues.locationFromId
  );
  const totalQuantity = tableData
    .filter((row) => row.Item?.id === formValues.Item?.id)
    .map((row) => (row.quantity ? Number(row.quantity) : 0))
    .reduce((acc, qty) => acc + qty, 0);

  const summaryTotal =
    (locationSummary?.totalQty || 0) - (locationSummary?.committedQty || 0);
  const summaryTotals =
    totalQuantity + (formValues.quantity ? Number(formValues.quantity) : 0);
  return summaryTotal < summaryTotals;
};
