import React, {
  useMemo,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Box,
  Divider,
  FormControlLabel,
  FormControl,
  Typography,
  Radio,
  RadioGroup,
} from '@mui/material';
import { Timezone } from 'timezones.json';
import { TIMEZONES } from 'helpers/timezones';
import _ from 'lodash';

import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { TextField } from 'ui/components/TextField/TextField';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { DetailsCard } from 'ui/components/Page/DetailsCard';
import { getTimezone } from 'ui/modules/setup/services';
import {
  deleteUser,
  fetchActiveUser,
  updateUser,
  fetchUserById,
  createUser,
  User,
  initialUser,
  PhoneTypeOptions,
} from 'services/userV2';
import { useHandleTextFieldChange } from 'services/forms';
import { requestPasswordChange, signOut } from 'services/auth';
import { showNotification } from 'services/api';
import { useUrlQueryObject } from 'services/url';
import { activeUserHasPermission, getActiveUser } from 'services/user/redux';
import { editUserPermissions } from './components/helpers';
import { Errors, validateYup } from 'services/forms/validation';
import { getSettingsCompany } from 'services/settings/company/redux';

import { UserDetailsCardCmp, UserDetailsCardProps } from './types';
import { useUserDetailsCardStyle } from './styled';
import { UserPermissionGroups } from './components';
import { yupSchema } from './validations';
import { PhoneInputField } from 'ui/components/TextField/PhoneInputField';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { IconNames, colorPalette } from 'ui/theme';
import AssignLocations from './components/AssignLocations/AssignLocations';
import { useLocation, useNavigate } from 'react-router-dom';

const UserDetailsCard: UserDetailsCardCmp = (props: UserDetailsCardProps) => {
  const { activeUserId, onClose, fetchSearchResult } = props;

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const [, setQueryParams] = useUrlQueryObject(navigate, location);

  const classes = useUserDetailsCardStyle(props);

  const [activeUser, setActiveUser] = useState<User>(initialUser);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [changeModalVisible, setChangeModalVisible] = useState<boolean>(false);
  const [editEmailModalVisible, setEditEmailModalVisible] =
    useState<boolean>(false);
  const [errors, setErrors] = useState<Errors>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLogoutLoading, setIsLogoutLoading] = useState(false);

  const company = useSelector(getSettingsCompany);
  const loggedInUser = useSelector(getActiveUser);

  const oldState = useRef<User | null>(initialUser);

  const firstInputElement = useRef<HTMLInputElement>(null);

  const canEditUser = editUserPermissions(activeUser);

  const canClick = useSelector(activeUserHasPermission(canEditUser));

  const title = useMemo(() => {
    if (!activeUser.id || activeUser.id < 0) {
      return 'New User';
    }
    return `${activeUser.firstName} ${activeUser.lastName}`;
  }, [activeUser.id, activeUser.firstName, activeUser.lastName]);

  useEffect(() => {
    if (activeUser.id !== null && firstInputElement.current !== null) {
      firstInputElement.current.focus();
    }
  }, [activeUser.id]);

  useEffect(() => {
    const asyncFc = async (id: number) => {
      setIsLoading(true);
      const user = await fetchUserById(id);
      oldState.current = user;
      setActiveUser(user);
      setIsLoading(false);
    };
    if (!activeUserId) {
      setIsLoading(false);
      return;
    }

    if (activeUserId < 0) {
      const newLocation: User = {
        ...initialUser,
        registered: false,
        deleted: false,
        id: activeUserId,
      };
      oldState.current = newLocation;
      setActiveUser(newLocation);
      setIsLoading(false);
      return;
    }
    setErrors({});
    asyncFc(activeUserId);
  }, [activeUserId]);

  const needLogout = useMemo(() => {
    // creating new users and editing them wont open logout modal
    return (
      activeUser &&
      activeUser.email !== _.get(oldState, 'current.email') &&
      activeUser.id !== -1 &&
      activeUser.id === _.get(loggedInUser, 'user.id')
    );
  }, [activeUser, loggedInUser]);

  const handleSubmitClicked = useCallback(
    (close: boolean = false) =>
      async () => {
        const isValid = validateYup(activeUser, yupSchema, setErrors);

        if (!isValid) {
          return false;
        }

        if (needLogout) {
          setEditEmailModalVisible(true);
          return false;
        }

        if (!activeUser.defaultLocationId) {
          showNotification('Please assign a default location', {
            variant: 'error',
          });
          return false;
        }

        if (!activeUser.accessGroupIds?.length) {
          showNotification('Please assign at least one permission group', {
            variant: 'error',
          });
          return false;
        }

        setIsLoading(true);

        // CREATE USER
        if (activeUser.id === -1) {
          try {
            const newActiveUser = await createUser(activeUser);
            oldState.current = newActiveUser;
            setActiveUser(newActiveUser);
            fetchSearchResult();
            setQueryParams({ activeId: newActiveUser.id });
            if (close) {
              setQueryParams({ activeId: null });
              onClose();
              return true;
            }
          } catch {
            setIsLoading(false);
            return false;
          }

          setIsLoading(false);
          return true;
        }

        // UPDATE USER
        try {
          const newActiveUser = await updateUser(activeUser);
          oldState.current = newActiveUser;
          setActiveUser(newActiveUser);
          dispatch(fetchActiveUser() as any);
        } catch {
          setIsLoading(false);
          return false;
        }
        if (close) {
          onClose();
        }
        setIsLoading(false);
        fetchSearchResult();
        return true;
      },

    [
      activeUser,
      fetchSearchResult,
      onClose,
      dispatch,
      setQueryParams,
      needLogout,
    ]
  );

  const handleLogoutClicked = useCallback(async () => {
    const isValid = validateYup(activeUser, yupSchema, setErrors);

    if (!isValid) {
      return;
    }

    setIsLogoutLoading(true);

    // UPDATE USER
    try {
      const newActiveUser = await updateUser(activeUser);
      // Clear the state before redirecting to login
      oldState.current = newActiveUser;
      setActiveUser(newActiveUser);
      // Lint skip to be removed
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(signOut());
    } catch {
      return;
    }
  }, [activeUser, dispatch]);

  const handleDeleteModalVisible = useCallback(
    (visible: boolean) => () => setDeleteModalVisible(visible),
    [setDeleteModalVisible]
  );

  const handleDeleteConfirm = useCallback(async () => {
    try {
      await deleteUser(activeUser.id!);
      fetchSearchResult();
    } catch {
      return;
    }

    setDeleteModalVisible(false);
    onClose();
  }, [activeUser.id, setDeleteModalVisible, fetchSearchResult, onClose]);

  const changePasswordClick = useCallback(() => {
    setChangeModalVisible(true);
  }, []);

  const handleChangeConfirm = useCallback(async () => {
    if (!activeUser.email) {
      showNotification('User email not provided!', { variant: 'warning' });
      return;
    }

    try {
      await requestPasswordChange(activeUser.email);
      setChangeModalVisible(false);
    } catch {
      // Ignore error
    }
  }, [activeUser.email]);

  const handleTimezoneChange = useCallback((e: any, v: any) => {
    setActiveUser((old) => ({ ...old, timezone: v ? v.utc[0] : null }));
  }, []);

  const handleTextInputChanged = useHandleTextFieldChange<User>(
    setActiveUser,
    activeUser
  );

  const handleUseEmailChange = useCallback((e: any) => {
    const value = e.target.value === 'true';
    setActiveUser((old) => ({ ...old, useEmail: value }));
  }, []);

  const handlePhoneNumberChange = useCallback((e: any) => {
    const value = e.target.value === '' ? null : e.target.value;
    setActiveUser((old) => ({ ...old, phoneNumber: value }));
  }, []);

  const handleOptionsAutocomplete = useCallback(
    (optionName: string) => (_e: any, option: any | null) => {
      setActiveUser((old) => ({ ...old, [optionName]: option }));
    },
    [setActiveUser]
  );

  return (
    <DetailsCard
      isLoading={isLoading}
      state={activeUser}
      oldState={oldState}
      onSubmit={handleSubmitClicked(false)}
    >
      <FBOTitleBar
        title={title}
        sx={{
          borderBottom: `1px solid ${colorPalette.redesign.background3}`,
        }}
      >
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="user-save"
          onClick={handleSubmitClicked(false)}
          permissions={canEditUser}
        >
          Save
        </FBOButton>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="user-save-and-close"
          permissions={canEditUser}
          onClick={handleSubmitClicked(true)}
        >
          Save And Close
        </FBOButton>
        {activeUserId && activeUserId > 0 && (
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="secondary"
            color="negative"
            size="medium"
            icon="TrashCan"
            onClick={handleDeleteModalVisible(true)}
            data-qa="user-delete"
            permissions={canEditUser}
          >
            Delete
          </FBOButton>
        )}
        <FBOButton
          variant="tertiary"
          color="neutral"
          size="medium"
          icon="FBOClose"
          data-qa="user-close"
          onClick={onClose}
        />
      </FBOTitleBar>

      <Box m={4}>
        <Grid
          container
          spacing={2}
          overflow="auto"
          sx={{
            padding: '16px',
            maxHeight: 'calc(100vh - 200px)',
          }}
        >
          <Grid container item spacing={2}>
            <Grid item xs={6}>
              <input hidden={true} name="address" />
              {/*This input is required to prevent Chrome autofill from overriding our settings*/}
              <TextField
                className="redesign"
                variant="standard"
                onChange={handleTextInputChanged}
                value={activeUser.firstName}
                type="text"
                label="First Name"
                placeholder="Enter name"
                name="firstName"
                autoComplete="nope"
                inputRef={firstInputElement}
                fullWidth
                permissions={canEditUser}
                error={!!errors.firstName}
                dataQa="user-first-name"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="redesign"
                variant="standard"
                onChange={handleTextInputChanged}
                value={activeUser.lastName}
                type="text"
                label="Last Name"
                placeholder="Enter name"
                name="lastName"
                autoComplete="nope"
                fullWidth
                permissions={canEditUser}
                error={!!errors.lastName}
                dataQa="user-first-last-name"
              />
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={6}>
              <PhoneInputField
                label="Phone Number"
                placeholder="Enter phone number"
                name="phoneNumber"
                autoComplete="nope"
                fullWidth
                value={activeUser.phoneNumber ?? null}
                permissions={canEditUser}
                onChange={handlePhoneNumberChange}
                dataQa="user-phone-number"
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={Object.values(PhoneTypeOptions)}
                name="phoneType"
                label="Phone Type"
                placeholder="Select Phone Type"
                value={activeUser.phoneType ?? null}
                onChange={handleOptionsAutocomplete('phoneType')}
                fullWidth
                dataQa="user-phone-type"
              />
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={6}>
                <TextField
                  className="redesign"
                  variant="standard"
                  onChange={handleTextInputChanged}
                  value={activeUser.email}
                  type="email"
                  label="Email"
                  placeholder="Enter email"
                  name="email"
                  autoComplete="nope"
                  fullWidth
                  permissions={canEditUser}
                  error={!!errors.email}
                  dataQa="user-email"
                />
              </Grid>

              <Grid item xs={6}>
                <Autocomplete
                  onChange={handleTimezoneChange}
                  value={getTimezone(activeUser.timezone)}
                  label="Timezone"
                  options={TIMEZONES}
                  permissions={canEditUser}
                  getOptionLabel={(option: Timezone) => option.text}
                  dataQa="user-timezone"
                  error={!!errors.timezone}
                />
              </Grid>
            </Grid>
          </Grid>

          {activeUser.id && activeUser.id > 0 && (
            <Grid item xs={4}>
              <FBOButton
                variant="tertiary"
                color="neutral"
                onClick={canClick ? changePasswordClick : _.noop}
                icon={IconNames.FBOLock}
                data-qa="user-change-password"
              >
                Change Password
              </FBOButton>
            </Grid>
          )}

          {activeUser.admin && (
            <Grid item xs={12}>
              <Divider />
              <Box pt={4}>
                <Typography variant="subtitle1">
                  <b>Reply To Email</b>
                </Typography>
                <FormControl
                  variant="standard"
                  component="fieldset"
                  className={classes.formControl}
                >
                  <RadioGroup
                    name="email"
                    value={activeUser.useEmail}
                    onChange={handleUseEmailChange}
                    row
                  >
                    <Grid item xs={6}>
                      <FormControlLabel
                        value={true}
                        control={<Radio color="primary" />}
                        label={`Use ${activeUser.email} for the reply to email address`}
                        className={classes.radioLabel}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        value={false}
                        control={<Radio color="primary" />}
                        label={
                          company.email !== null ? (
                            `Use ${company.email} for the reply to email address`
                          ) : (
                            <div>
                              <label>Company email not set</label>

                              <FBOButton
                                color="neutral"
                                variant="tertiary"
                                size="small"
                                href="/setup/settings"
                                data-qa="user-company-email-link"
                              >
                                (Set company email)
                              </FBOButton>
                            </div>
                          )
                        }
                        disabled={company.email === null}
                        className={classes.radioLabel}
                      />
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Box>
            </Grid>
          )}

          {!activeUser.admin && (
            <Grid item xs={12}>
              <Box pt={4}>
                <UserPermissionGroups
                  user={activeUser}
                  setUser={setActiveUser}
                />
              </Box>
              <AssignLocations user={activeUser} setUser={setActiveUser} />
            </Grid>
          )}
        </Grid>
      </Box>
      <ConfirmationModal
        open={deleteModalVisible}
        title="Delete user"
        body={`This will delete user '${title}', are you sure?`}
        onCancelClicked={handleDeleteModalVisible(false)}
        onConfirmClicked={handleDeleteConfirm}
        confirmLabel={'Delete'}
        cancelLabel={'Cancel'}
        confirmButtonRed
      />
      <ConfirmationModal
        open={changeModalVisible}
        title="Send change password email"
        body={`This will send a password change request to the user. Are you sure you want to do this?`}
        onCancelClicked={() => setChangeModalVisible(false)}
        onConfirmClicked={handleChangeConfirm}
        confirmLabel={'Send'}
        cancelLabel={'Cancel'}
      />
      <ConfirmationModal
        open={editEmailModalVisible}
        isLoading={isLogoutLoading}
        title="Edited user email"
        body={`You will be logged out after save`}
        onCancelClicked={() => setEditEmailModalVisible(false)}
        onConfirmClicked={handleLogoutClicked}
        confirmLabel={'Log Out'}
        cancelLabel={'Cancel'}
      />
    </DetailsCard>
  );
};

export default UserDetailsCard;
