import React from 'react';
import { Box, Typography } from '@mui/material';
import { IconNames } from 'ui/theme';
import { Icon } from 'ui/components/Icon';
import { useFlowPageStyle } from './styled';

export interface FBOFlowContainerProps {
  iconName: IconNames;
  title: string;
  description: string;
  children?: React.ReactNode;

  dataQa?: string;
}

export const FBOFlowContainer: React.FC<FBOFlowContainerProps> = (props) => {
  const { iconName, description, title, children, dataQa } = props;

  const classes = useFlowPageStyle();

  return (
    <Box className={classes.redesignFlowContainer} data-qa={dataQa}>
      <Box className={classes.redesignFlowTitleCard}>
        <Box className={classes.redesignFlowTitleBox}>
          <Icon name={iconName} className={classes.redesignFlowContainerIcon} />
          <Typography className={classes.redesignFlowContainerTitle}>
            {title}
          </Typography>
        </Box>
        <Typography className={classes.redesignFlowContainerDescription}>
          {description}
        </Typography>
      </Box>
      {children}
    </Box>
  );
};
