import React from 'react';
import _ from 'lodash';

import {
  initialShippingQuoteCarton,
  SalesOrderShippingQuote,
  ShippingIntegrationCarton,
  ShippingIntegrationSettings,
  ShippingIntegrationRateService,
} from 'services/integrations/shipping';
import { Errors, validateYup } from 'services/forms/validation';
import { transformShipToAddress } from './components/transformations';
import { SalesOrder } from 'services/salesOrders';
import { Address } from 'services/addresses';

import { roundToDecimals } from 'helpers';

import { yupQuoteCartonsSchema } from './validations';

export const setShippingQuoteFromSettings = (
  shippoSettings: ShippingIntegrationSettings,
  weightTotal: number,
  activeSalesOrder: SalesOrder,
  shipFromAddress: Address,
  setShippingQuote: React.Dispatch<
    React.SetStateAction<SalesOrderShippingQuote>
  >
) => {
  const { extraSettings, cartonDimensions, internationalSettings } =
    shippoSettings;

  const exporterReference = internationalSettings.exporterReference;
  const importerReference = internationalSettings.importerReference;

  setShippingQuote((old) => ({
    ...old,
    nonDeliveryOption: internationalSettings.nonDeliveryOption,
    contentsType: internationalSettings.contentsType,
    eel: internationalSettings.eelPfc,
    incoterms: internationalSettings.incoterms,
    shipperEmailAddress: internationalSettings.shipperEmail,
    disclaimer: internationalSettings.disclaimer,
    useRetailerRates: extraSettings.useRetailRates,
    saturdayDelivery: extraSettings.saturdayDelivery,
    containsDryIce: extraSettings.containsDryIce,
    dryIceWeight: extraSettings.dryIceWeight,
    uom: extraSettings.unitOfMeasure,
    containsAlcohol: extraSettings.containsAlcohol,
    recipientType: extraSettings.recipientType,
    exporterReference: exporterReference
      ? _.get(
          activeSalesOrder,
          exporterReference.replace('salesOrder.', ''),
          null
        )
      : null,
    importerReference: importerReference
      ? _.get(
          activeSalesOrder,
          importerReference.replace('salesOrder.', ''),
          null
        )
      : null,
    shipFromAddress: {
      ...shipFromAddress,
      name: shipFromAddress.name
        ? shipFromAddress.name
        : shipFromAddress.companyName,
    },
    shipToAddress: transformShipToAddress(activeSalesOrder),
    cartons: [
      {
        insuredAmount: 0,
        dimensionUnit: cartonDimensions.unit,
        width: cartonDimensions.width,
        length: cartonDimensions.length,
        height: cartonDimensions.height,
        weight: weightTotal,
        weightUnit: 'Pounds',
      },
    ],
  }));
};

export const totalCostCalculation = (
  selectedService: ShippingIntegrationRateService,
  defaultShippingSettings: ShippingIntegrationSettings
) => {
  if (!defaultShippingSettings.markup.value) {
    return selectedService.totalCost;
  }
  if (defaultShippingSettings.markup.type === 'flat') {
    return selectedService.totalCost + defaultShippingSettings.markup.value;
  } else {
    return (
      selectedService.totalCost +
      selectedService.totalCost * (defaultShippingSettings.markup.value / 100)
    );
  }
};

export const addNewCartonToSoQuote = (
  shippoSettings: ShippingIntegrationSettings,
  shippingQuote: SalesOrderShippingQuote,
  weightTotal: number,
  setShippingQuote: React.Dispatch<
    React.SetStateAction<SalesOrderShippingQuote>
  >
) => {
  const { cartonDimensions } = shippoSettings;

  const cartons = shippingQuote.cartons.map((i) => ({
    ...i,
    weight: roundToDecimals(
      weightTotal / (shippingQuote.cartons.length + 1),
      4
    ),
  }));
  setShippingQuote((old) => ({
    ...old,
    cartons: [
      ...cartons,
      {
        ...initialShippingQuoteCarton,
        dimensionUnit: cartonDimensions.unit,
        width: cartonDimensions.width,
        length: cartonDimensions.length,
        height: cartonDimensions.height,
        weight: roundToDecimals(
          weightTotal / (shippingQuote.cartons.length + 1),
          4
        ),
      },
    ],
  }));
};

export const validateSaleOrderQuoteCartonList = (
  cartons: ShippingIntegrationCarton[],
  setRowErrors: React.Dispatch<React.SetStateAction<Errors[]>>
) => {
  const newErrors: Errors[] = [];
  const itemsAreValid = cartons.map((item, index) => {
    return validateYup(item, yupQuoteCartonsSchema, (error: Errors) => {
      newErrors[index] = error;
    });
  });
  setRowErrors(newErrors);

  return itemsAreValid.some((valid) => !valid);
};
