import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const reconcileWizardSummaryColumns = (
  showMultiCurrency: boolean,
  multiCurrencyCode: string | null,
  homeCurrencyCode: string,
  isUSTenant: boolean | null
): Column[] => {
  let columns: Column[] = [
    {
      title: 'Item',
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: 'Quantity',
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: `Unit ${homeCurrencyCode}`,
      type: ColumnTypes.renderByCustomRow,
    },
    ...(!isUSTenant
      ? [
          {
            title: `Tax Rate`,
            type: ColumnTypes.renderByCustomRow,
          },
          {
            title: `Tax Amount ${homeCurrencyCode}`,
            type: ColumnTypes.renderByCustomRow,
          },
        ]
      : ([] as any[])),
    {
      title: `Total ${homeCurrencyCode}`,
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: `Reconcile ${homeCurrencyCode}`,
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: `Unit Landed ${homeCurrencyCode}`,
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: `Landed ${homeCurrencyCode}`,
      type: ColumnTypes.renderByCustomRow,
    },
  ];

  if (showMultiCurrency) {
    columns = [
      {
        title: 'Item',
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: 'Quantity',
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Unit`,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Unit ${multiCurrencyCode}`,
        type: ColumnTypes.renderByCustomRow,
      },
      ...(!isUSTenant
        ? [
            {
              title: `Tax Rate`,
              type: ColumnTypes.renderByCustomRow,
            },
            {
              title: `Tax Amount`,
              type: ColumnTypes.renderByCustomRow,
            },
            {
              title: `Tax Amount ${multiCurrencyCode}`,
              type: ColumnTypes.renderByCustomRow,
            },
          ]
        : ([] as any[])),
      {
        title: `Total ${homeCurrencyCode}`,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Total ${multiCurrencyCode}`,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Reconcile ${homeCurrencyCode}`,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Reconcile ${multiCurrencyCode}`,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Unit Landed ${homeCurrencyCode}`,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Unit Landed ${multiCurrencyCode}`,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Landed ${homeCurrencyCode}`,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Landed ${multiCurrencyCode}`,
        type: ColumnTypes.renderByCustomRow,
      },
    ];
  }

  return columns;
};
