import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { PermissionType } from 'services/permissions';
import SecureRoute from 'ui/components/Route/SecureRoute';
import { DownloadsPage } from '../pages';

import { Routes as NotificationsRoutes } from './routes';
import { NotificationsRouterCmp, NotificationsRouterProps } from './types';

const NotificationsRouter: NotificationsRouterCmp = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: NotificationsRouterProps
) => {
  return (
    <Routes>
      <Route
        element={<SecureRoute permissions={[PermissionType.CustomersView]} />}
      >
        <Route path={DownloadsPage.route} element={<DownloadsPage />} />
      </Route>
    </Routes>
  );
};

NotificationsRouter.route = NotificationsRoutes.NotificationsRouter;

export default NotificationsRouter;
