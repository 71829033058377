import React, { memo } from 'react';

import { colorPalette } from 'ui/theme';
import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { PermissionType } from 'services/permissions';
import { Pagination } from 'services/search';

import { PageCustomRow } from '../PageCustomRow';
import { ItemSearchResultsProps } from './types';
import { PRICING_RULES_COLUMNS } from '../../consts';
import { createActionBarOptions } from './consts';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';

const PricingRulesSearchResults: React.FC<ItemSearchResultsProps> = (props) => {
  const {
    activePricingRuleId,
    handleItemClick,
    onAddNewPress,
    items,
    isLoadingItems,
    onPaginationChange,
    onPageAction,
    activeDate,
    pagination,
  } = props;

  const handlePaginationChange = (newPagination: Pagination) => {
    onPaginationChange(newPagination);
  };

  const resolvedColumns = activePricingRuleId
    ? PRICING_RULES_COLUMNS(activeDate).filter(
        (c) => c.title === 'Name' || c.title === 'Type'
      )
    : PRICING_RULES_COLUMNS(activeDate);

  return (
    <>
      <FBOTitleBar title="Pricing Rules">
        {activePricingRuleId !== -1 && (
          <FBOButton
            style={{ marginRight: '8px' }}
            variant={!activePricingRuleId ? 'primary' : 'secondary'}
            color="positive"
            size="medium"
            icon="FBOAddCircle"
            onClick={onAddNewPress}
            data-qa="pricing-rule-add-new"
            permissions={[PermissionType.PricinRulesCreate]}
          >
            {!activePricingRuleId ? 'Add New' : ''}
          </FBOButton>
        )}
        <FBOMenuButton
          variant="secondary"
          items={createActionBarOptions(onPageAction)}
          data-qa="pricing-rules-three-dot-menu"
          style={{ color: colorPalette.redesign.contentSecondary }}
        />
      </FBOTitleBar>

      <ItemsTable
        data={items}
        columns={resolvedColumns}
        activeId={activePricingRuleId}
        RenderCustomRow={PageCustomRow}
        onItemClick={handleItemClick}
        selectableItems={false}
        isLoadingData={isLoadingItems}
        pagination={pagination}
        onPaginationChange={handlePaginationChange}
        meta={{ activePricingRuleId }}
        dataQa="pricing-rule-table"
        emptyTableText={
          activePricingRuleId
            ? 'NO PRICING RULES AVAILABLE'
            : "ADD NEW ENTRY BY PRESSING 'ADD NEW'"
        }
        showEnhancedTablePaginationActions={!Boolean(activePricingRuleId)}
      />
    </>
  );
};
export default memo(PricingRulesSearchResults);
