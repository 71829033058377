import { ShippingSettings } from './types';

export const transformShippingSettings = (response: any): ShippingSettings => {
  return {
    id: response.id,
    version: response.version,
    dateLastModified: response.dateLastModified,
    dateCreated: response.dateCreated,
    fulfillServiceAndMiscItemsOnShip: response.fulfillServiceAndMiscItemsOnShip,
    fulfillShippingLinesOnShip: response.fulfillShippingLinesOnShip,
    printPackingListOnShip: response.printPackingListOnShip,
    printPackingListOnPack: response.printPackingListOnPack,
    shipmentBatchSelectionDefault: response.shipmentBatchSelectionDefault,
    autoPackShipments: response.autoPackShipments,
    showConfirmationOnShip: response.showConfirmationOnShip,
    orderNotPaidWarning: response.orderNotPaidWarning,
    showPaidStamp: response.showPaidStamp,
  };
};

export const transformShippingPut = (shippingSettings: ShippingSettings) => {
  return {
    id: shippingSettings.id,
    version: shippingSettings.version,
    dateCreated: shippingSettings.dateCreated,
    dateLastModified: shippingSettings.dateLastModified,
    fulfillServiceAndMiscItemsOnShip:
      shippingSettings.fulfillServiceAndMiscItemsOnShip,
    fulfillShippingLinesOnShip: shippingSettings.fulfillShippingLinesOnShip,
    printPackingListOnShip: shippingSettings.printPackingListOnShip,
    printPackingListOnPack: shippingSettings.printPackingListOnPack,
    shipmentBatchSelectionDefault:
      shippingSettings.shipmentBatchSelectionDefault,
    autoPackShipments: shippingSettings.autoPackShipments,
    showConfirmationOnShip: shippingSettings.showConfirmationOnShip,
    orderNotPaidWarning: shippingSettings.orderNotPaidWarning,
    showPaidStamp: shippingSettings.showPaidStamp,
  };
};
