import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

import { activeUserHasPermission } from 'services/user/redux';
import {
  ItemsTable,
  useSelectedItemsChanges,
} from 'ui/components/Table/ItemsTable';
import {
  initialVendorItem,
  transformToVendorItem,
  VendorItem,
} from 'services/vendors';
import { fetchItemsAPI, getVendorItems, Item } from 'services/items';
import { getSettingsCompany } from 'services/settings/company';
import { Errors, validateYup } from 'services/forms/validation';
import { findNextNegativeId, replaceValueInCollection } from 'helpers';

import { resolvedVendorItemColumns } from './consts';
import { ItemsTabProps } from './types';
import ItemsTabModal from './ItemsTabModal';
import ItemsTabCustomRow from './ItemsTabCustomRow';
import { yupVendorItemSchema } from '../../validations';
import { editVendorPermissions } from '../helpers';
import { Pagination } from 'services/search';
import { initialPagination } from 'services/accounting';
import { logErrorCtx } from 'app/logging';
import { NetworkSpinnerWrapper } from 'ui/components/NetworkSpinnerWrapper';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const ItemsTab: React.FC<ItemsTabProps> = (props) => {
  const { activeVendor, setActiveVendor, refreshVendorItems, oldState } = props;

  const { homeCurrency, useMultiCurrency } = useSelector(getSettingsCompany);

  const [vendorItems, setVendorItems] = useState<VendorItem[]>([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [vendorItem, setVendorItem] = useState<VendorItem>(initialVendorItem);
  const [validationErrors, setValidationErrors] = useState<Errors>({});
  const [dropdownItems, setDropdownItems] = useState<Item[]>([]);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const editPermission = editVendorPermissions(activeVendor);

  const activeMulticurrencyCode = _.get(activeVendor, 'currency.code', null);
  const homeCurrencyCode = (homeCurrency && homeCurrency.code) || 'USD';
  const exchangeRate = _.get(activeVendor.currency, 'exchangeRate', 1);

  const showMultiCurrency = useMemo(
    () =>
      useMultiCurrency &&
      activeMulticurrencyCode &&
      activeMulticurrencyCode !== homeCurrencyCode,
    [useMultiCurrency, activeMulticurrencyCode]
  );

  const canOpenVendorItemsModal = useSelector(
    activeUserHasPermission(editPermission)
  );

  const handleSelectedChange = useSelectedItemsChanges(
    selectedItems,
    setSelectedItems
  );

  const [pagination, setPagination] = useState<Pagination>(initialPagination);

  const handlePaginationChange = async (newPagination: Pagination) => {
    await fetchVendorItems(newPagination);
  };

  const fetchVendorItems = async (pagination: Pagination) => {
    try {
      setIsLoading(true);
      const res = await getVendorItems(activeVendor.id!, pagination);
      setIsLoading(false);
      const vendorItems: VendorItem[] = res.data.map((i) =>
        transformToVendorItem(i, exchangeRate!)
      );
      if (oldState) {
        oldState.current = { ...activeVendor, vendorItems };
      }
      setVendorItems(vendorItems);
      setPagination(res.pagination);
    } catch (e) {
      setIsLoading(false);
      const error = e as Error;
      logErrorCtx('Error while fetching vendorItems', {
        error,
        stackTrace: error.stack,
        title: error.message,
        description: `Unable to fetch vendorItems for vendor id =${activeVendor.id}`,
        component: 'VendorDetail->ItemsTab',
      });
    }
  };

  useEffect(() => {
    const asyncFc = async () => {
      let resItems = [];
      try {
        resItems = (await fetchItemsAPI({})).data;
      } catch {
        return;
      }

      setDropdownItems(resItems);
    };

    asyncFc();
  }, [vendorItems]);

  useEffect(() => {
    fetchVendorItems(initialPagination);
  }, [refreshVendorItems]);

  useEffect(() => {
    setActiveVendor({ ...activeVendor, vendorItems });
  }, [vendorItems]);
  const openModalForAddingNew = useCallback(() => {
    setVendorItem({
      ...initialVendorItem,
      id: findNextNegativeId(vendorItems),
    });
    setModalVisible(true);
  }, [vendorItems]);

  const openModalForEditing = useCallback(
    (id: number) => {
      setVendorItem(vendorItems.find((a) => a.id === id)!);
      if (canOpenVendorItemsModal) {
        setModalVisible(true);
      }
    },
    [vendorItems, canOpenVendorItemsModal]
  );

  const onModalClose = useCallback(() => {
    setModalVisible(false);
    setVendorItem(initialVendorItem);
    setValidationErrors({});
  }, []);

  const handleSave = useCallback(
    (
      e:
        | React.MouseEvent<HTMLButtonElement>
        | React.KeyboardEvent<HTMLDivElement>
    ) => {
      e.preventDefault();
      const isValid = validateYup(
        vendorItem,
        yupVendorItemSchema,
        setValidationErrors
      );

      if (!isValid) {
        return;
      }

      const indexNum = vendorItems.findIndex((a) => a.id === vendorItem.id);

      if (indexNum === -1) {
        setVendorItems([...vendorItems, vendorItem]);
      } else {
        setVendorItems([
          ...replaceValueInCollection(vendorItems, vendorItem, indexNum)!,
        ]);
      }

      setModalVisible(false);
      setValidationErrors({});
    },
    [vendorItem, vendorItems]
  );

  const deleteClicked = useCallback(() => {
    const newItems: VendorItem[] = vendorItems.map((item) => {
      if (selectedItems.includes(item.id!)) {
        setVendorItems([]);
        return { ...item, deleted: true };
      }
      return item;
    });

    const newRemoved: VendorItem[] = newItems.filter((item) => {
      return !((item.id || 0) < 0 && item.deleted);
    });

    setVendorItems(newRemoved);
    setSelectedItems([]);
  }, [selectedItems, setVendorItems, vendorItems]);

  const filteredItems = useMemo(() => {
    const availableItems = dropdownItems.filter((i) => {
      const vendor = vendorItems.find((vItem) => vItem.itemId === i.id);

      if ((vendor && vendor.deleted === true) || !vendor) {
        return true;
      }
      return false;
    });

    return availableItems;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorItems, dropdownItems, modalVisible]);

  return (
    <Box flexDirection="column" display="flex" height="100%" width="100%">
      <NetworkSpinnerWrapper
        isLoading={isLoading}
        display="flex"
        flexDirection="column"
        overflow="hidden"
        flexGrow={1}
        zIndex={2}
      >
        <FBOTitleBar title="Items">
          {!_.isEmpty(selectedItems) && (
            <FBOButton
              sx={{ marginRight: '8px' }}
              variant="secondary"
              color="negative"
              size="medium"
              icon="TrashCan"
              data-qa="vendor-item-delete"
              onClick={deleteClicked}
              permissions={editPermission}
            >
              Delete
            </FBOButton>
          )}
          <FBOButton
            variant="secondary"
            color="positive"
            size="medium"
            data-qa="vendor-item-add"
            icon="FBOAddCircle"
            onClick={openModalForAddingNew}
            permissions={editPermission}
          >
            Add New
          </FBOButton>
        </FBOTitleBar>

        <ItemsTable
          data={vendorItems}
          filterRows={(item) => !item.deleted}
          columns={resolvedVendorItemColumns(
            showMultiCurrency,
            activeMulticurrencyCode,
            homeCurrencyCode
          )}
          onItemClick={openModalForEditing}
          selectedItems={selectedItems}
          onSelectedChange={handleSelectedChange}
          RenderCustomRow={ItemsTabCustomRow}
          meta={{ activeMulticurrencyCode }}
          dataQa="vendor-item-table"
          emptyTableText="ADD NEW ENTRY BY PRESSING 'ADD NEW'"
          pagination={pagination}
          onPaginationChange={handlePaginationChange}
          showEnhancedTablePaginationActions={true}
        />
        <ItemsTabModal
          onClose={onModalClose}
          modalVisible={modalVisible}
          vendorItems={vendorItems}
          vendorItem={vendorItem}
          setVendorItem={setVendorItem}
          items={filteredItems}
          onSaveChanges={handleSave}
          validationErrors={validationErrors}
          setValidationErrors={setValidationErrors}
          vendor={activeVendor}
        />
      </NetworkSpinnerWrapper>
    </Box>
  );
};

export default React.memo(ItemsTab);
