import React, { useState, useCallback, useMemo, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { Page } from 'ui/components/Page/Page';
import FBOSearchHeader, {
  SaveSearchParams,
} from 'ui/theme/components/FBOSearchHeader/FBOSearchHeader';
import {
  useSaveAdvancedSearch,
  useChangeActiveAdvancedSearch,
  useChangeQuickSearch,
  useDeleteAdvancedSearch,
  useApplyAdvancedSearch,
} from 'services/search';
import { useUrlQueryObject } from 'services/url';

import { PageWithAdvancedSearchProps } from './types';
import { useLocation, useNavigate } from 'react-router-dom';

const PageWithAdvancedSearch: React.FC<PageWithAdvancedSearchProps> = (
  props
) => {
  const {
    detailCardColumns,
    initialFormValues,
    displayNameMap,
    displayValueMap,
    advancedSearchReduxActions: reduxFunctions,
    fetchSearchResult,
    searchResult,
    AdvancedSearchFieldsCmp,
    children,
    showAllLabel,
    pageName,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [, setUrlQuery] = useUrlQueryObject(navigate, location);

  const { items: storedAdvancedSearches } = useSelector(reduxFunctions.get);

  const [formValues, setFormValues] = useState(initialFormValues);

  const activeAdvancedSearch = useMemo(
    () => searchResult.advancedSearch,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchResult.advancedSearch]
  );

  const [newAdvancedSearch, updateNewAdvancedSearch] =
    useState(activeAdvancedSearch);

  useEffect(() => {
    setFormValues(
      activeAdvancedSearch.id > 0
        ? activeAdvancedSearch.columns
        : initialFormValues
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAdvancedSearch.id]);

  const handleQuickSearchChanged = useChangeQuickSearch(
    fetchSearchResult,
    detailCardColumns,
    searchResult.pagination
  );

  const applyAdvancedSearch = useApplyAdvancedSearch(
    activeAdvancedSearch,
    fetchSearchResult,
    searchResult.pagination
  );

  const handleChangeAdvancedSearch = useChangeActiveAdvancedSearch(
    storedAdvancedSearches,
    fetchSearchResult,
    detailCardColumns,
    searchResult.pagination
  );

  const saveAdvancedSearch = useSaveAdvancedSearch(
    dispatch,
    reduxFunctions.add,
    reduxFunctions.update,
    fetchSearchResult
  );

  const handleDeleteAdvancedSearch = useDeleteAdvancedSearch(
    dispatch,
    reduxFunctions.delete,
    fetchSearchResult
  );

  const handleSaveSearchClicked = useCallback(
    async (params: SaveSearchParams) => {
      const newAdvancedSearch = {
        ...activeAdvancedSearch,
        ...params,
        id: -1,
        columns: _.pickBy(formValues, (val) => !!val),
      };
      saveAdvancedSearch(newAdvancedSearch);
      setFormValues(initialFormValues);
    },
    [activeAdvancedSearch, formValues, saveAdvancedSearch, initialFormValues]
  );

  const applyQuickSearchDelete = useApplyAdvancedSearch(
    newAdvancedSearch,
    fetchSearchResult,
    searchResult.pagination
  );

  const handleDeleteQuickSearch = useCallback(() => {
    const newAdvancedSearch = {
      ...activeAdvancedSearch,
    };
    newAdvancedSearch.quickSearchValue = '';
    updateNewAdvancedSearch(newAdvancedSearch);
    applyQuickSearchDelete(formValues);
    setUrlQuery({ advancedSearch: formValues });
  }, [applyQuickSearchDelete, formValues, setUrlQuery]);
  //

  const handleChipDeleteClicked = useCallback(
    (fieldName: string) => {
      const newState = {
        ...formValues,
        [fieldName]: null,
      };
      setFormValues(newState);
      applyAdvancedSearch(newState);
      setUrlQuery({
        advancedSearch: newState,
      });
    },
    [applyAdvancedSearch, formValues, setUrlQuery]
  );

  const handleCancelClicked = useCallback(() => {
    setFormValues({
      ...initialFormValues,
      ...activeAdvancedSearch.columns,
    });
  }, [initialFormValues, activeAdvancedSearch.columns]);

  const handleApplyClicked = useCallback(() => {
    applyAdvancedSearch(formValues);
    setUrlQuery({ advancedSearch: formValues });
  }, [applyAdvancedSearch, formValues, setUrlQuery]);

  const handleResetClicked = useCallback(() => {
    const id = activeAdvancedSearch.id;
    if (id < 0) {
      setFormValues(initialFormValues);
      return;
    }

    setFormValues({
      ...initialFormValues,
      ...(storedAdvancedSearches.find((t: any) => t.id === id) as any).columns,
    });
  }, [initialFormValues, storedAdvancedSearches, activeAdvancedSearch.id]);

  return (
    <Page>
      <FBOSearchHeader
        advancedSearches={storedAdvancedSearches}
        activeAdvancedSearch={searchResult.advancedSearch}
        onQuickSearchChanged={handleQuickSearchChanged}
        onActiveAdvancedSearchChanged={handleChangeAdvancedSearch}
        onResetClicked={handleResetClicked}
        onApplyClicked={handleApplyClicked}
        onCancelClicked={handleCancelClicked}
        onChipDeleteClicked={handleChipDeleteClicked}
        onSaveSearchClicked={handleSaveSearchClicked}
        onDeleteSearchClicked={handleDeleteAdvancedSearch}
        onQuickSearchDeleteClicked={handleDeleteQuickSearch}
        displayNameMap={displayNameMap}
        displayValueMap={displayValueMap}
        showAllLabel={showAllLabel}
        filterModalTitle={`${pageName} Filters`}
      >
        <AdvancedSearchFieldsCmp
          formValues={formValues}
          setFormValues={setFormValues}
        />
      </FBOSearchHeader>
      {children}
    </Page>
  );
};

export default memo(PageWithAdvancedSearch);
