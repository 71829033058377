import { Errors } from 'services/forms/validation';
import { PricingRule } from 'services/pricingRules';

export interface PricingRulesCustomersProps {
  activePricingRule: PricingRule;
  setActivePricingRule: React.Dispatch<React.SetStateAction<PricingRule>>;
  checkedCustomersGroup: string;
  setCheckedCustomersGroup: React.Dispatch<
    React.SetStateAction<PricingRulesCustomersGroup>
  >;
  validationErrors: Errors;
  setValidationErrors: React.Dispatch<React.SetStateAction<Errors>>;
}

export enum PricingRulesCustomersGroup {
  Everyone = 'Everyone',
  Customer = 'Customer',
  Tag = 'Tag',
}
