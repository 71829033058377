import {
  createApiAction,
  FailureAction,
  StartAction,
  SuccessAction,
} from 'services/api';

import { shippingIntegrationHostname } from '../consts';

export enum ShippingIntegrationActionTypes {
  FETCH_SHIPPING_INTEGRATION_CONNECTIONS_START = 'shippingIntegrations/fetch_shipping_integration_connections_start',
  FETCH_SHIPPING_INTEGRATION_CONNECTIONS_SUCCESS = 'shippingIntegrations/fetch_shipping_integration_connections_success',
  FETCH_SHIPPING_INTEGRATION_CONNECTIONS_FAILURE = 'shippingIntegrations/fetch_shipping_integration_connections_failure',
}

const fetchShippingIntegrationConnectionsStart = (): StartAction => ({
  type: ShippingIntegrationActionTypes.FETCH_SHIPPING_INTEGRATION_CONNECTIONS_START,
  payload: null,
});

const fetchShippingIntegrationConnectionsSuccess = (
  payload: any
): SuccessAction => ({
  type: ShippingIntegrationActionTypes.FETCH_SHIPPING_INTEGRATION_CONNECTIONS_SUCCESS,
  payload,
});

const fetchShippingIntegrationConnectionsFailure = (
  error: string
): FailureAction => ({
  type: ShippingIntegrationActionTypes.FETCH_SHIPPING_INTEGRATION_CONNECTIONS_FAILURE,
  payload: { error },
});

export const fetchShippingIntegrationConnections = () =>
  createApiAction(
    {
      hostname: shippingIntegrationHostname,
      path: '/api/v1/connection',
      method: 'GET',
    },
    fetchShippingIntegrationConnectionsStart,
    fetchShippingIntegrationConnectionsSuccess,
    fetchShippingIntegrationConnectionsFailure
  );
