import {
  getSearches,
  addSearch,
  updateSearch,
  deleteSearch,
} from 'services/representatives';
import {
  AdvancedSearchFormValues,
  AdvancedSearchReduxFunctions,
} from 'services/search';
import { PaginationDirection } from 'services/url';
import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';
import { Dates } from 'helpers';
import { MenuItem } from 'ui/components/Menu';
import { IconNames } from 'ui/theme';
import { PermissionType } from 'services/permissions';

export const REPRESENTATIVE_COLUMNS = (date: Dates): Column[] => {
  const resolveDateColumn = (): Column => {
    switch (date) {
      case Dates.DateLastModified:
        return {
          title: 'Date Last Modified',
          field: Dates.DateLastModified,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
        };
      default:
        return {
          title: 'Date Created',
          field: Dates.DateCreated,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
        };
    }
  };

  return [
    {
      title: 'First Name',
      field: 'firstName',
      sortable: true,
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: 'Last Name',
      field: 'lastName',
      sortable: true,
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: 'Initials',
      field: 'initials',
      sortable: true,
      type: ColumnTypes.renderByCustomRow,
    },
    resolveDateColumn(),
  ];
};

export const advancedSearchReduxActions: AdvancedSearchReduxFunctions = {
  add: addSearch,
  delete: deleteSearch,
  update: updateSearch,
  get: getSearches,
};

export const initialPagination = {
  page: 1,
  pageSize: 25,
  sort: {
    sortBy: 'dateLastModified',
    direction: PaginationDirection.Descending,
  },
  totalRows: 25,
};

export const displayNameMap = {
  firstName: 'Name',
  initials: 'Initials',
  deleted: 'Deleted',
};

export const initialRepresentativesASFormValues: AdvancedSearchFormValues = {
  firstName: null,
  initials: null,
  deleted: null,
};

export enum RepresentativesPageAction {
  ChangeDate,
  Export,
  Import,
}

export const createActionBarOptions = (
  onActionClick: (actionType: RepresentativesPageAction, date?: Dates) => void,
  representativesCount: number,
  csvDisabled?: boolean
): MenuItem[] => [
  {
    label: 'Import Representatives',
    icon: IconNames.FBOImport,
    onOptionClick: () => onActionClick(RepresentativesPageAction.Import),
    permissions: [
      PermissionType.RepresentativesCreate,
      PermissionType.RepresentativesEdit,
    ],
    separateAfter: true,
    disabled: csvDisabled,
  },
  {
    label: 'Export Representatives',
    icon: IconNames.FBOExport,
    onOptionClick: () => onActionClick(RepresentativesPageAction.Export),
    disabled: !representativesCount || csvDisabled,
    separateAfter: true,
  },
  {
    icon: IconNames.FBORecycleSmall,
    label: 'Change Dates',
    subItems: [
      {
        label: 'Date Created',
        onOptionClick: () =>
          onActionClick(
            RepresentativesPageAction.ChangeDate,
            Dates.DateCreated
          ),
        separateAfter: true,
      },
      {
        label: 'Date Last Modified',
        onOptionClick: () =>
          onActionClick(
            RepresentativesPageAction.ChangeDate,
            Dates.DateLastModified
          ),
      },
    ],
  },
];
