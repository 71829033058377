import _ from 'lodash';
import {
  Address,
  initialAddress,
  transformAddressToBillToShipToAddressBeta,
} from 'services/addresses';

import { Customer } from 'services/customers';
import { SalesOrder } from 'services/salesOrders';
import { SalesOrderSettings } from 'services/settings/salesOrders';
import { getExchangeRate } from 'helpers';

export const calculateSoCustomerFields = (
  so: SalesOrder,
  customer: Customer | null,
  salesOrderSettings: SalesOrderSettings
) => {
  if (!customer) {
    return {
      ...so,
      customerId: null,
      exchangeRate: 1,
      customer,
    };
  }

  const addresses = customer.addresses;

  const btAdrInitial = addresses.find((a) => a.defaultBillTo);
  const btAdr =
    btAdrInitial &&
    transformAddressToBillToShipToAddressBeta(btAdrInitial, customer);
  const stAdrInitial = addresses.find((a) => a.defaultShipTo);
  const stAdr =
    stAdrInitial &&
    transformAddressToBillToShipToAddressBeta(stAdrInitial, customer);

  return {
    ...so,
    customerId: customer.id,
    customer,
    billToPhone: btAdr ? btAdr.phone : null, // @beta: additionally added for testing
    billToEmail: btAdr ? btAdr.email : null, // @beta: additionally added for testing
    billToName: btAdr ? btAdr.name : null,
    billToStreet: btAdr ? btAdr.street : null,
    billToStreet2: btAdr ? btAdr.street2 : null,
    billToCity: btAdr ? btAdr.city : null,
    billToState: btAdr ? btAdr.state : null,
    billToCountry: btAdr ? btAdr.country : null,
    billToPostalCode: btAdr ? btAdr.postalCode : null,
    billToAttention: btAdr ? btAdr.attention : null,
    billToCompanyName: btAdr ? btAdr.companyName : null,
    billToResidential: btAdr ? btAdr.residential : false,
    shipToPhone: stAdr ? stAdr.phone : null, // @beta: additionally added for testing
    shipToEmail: stAdr ? stAdr.email : null, // @beta: additionally added for testing
    shipToName: stAdr ? stAdr.name : null,
    shipToStreet: stAdr ? stAdr.street : null,
    shipToStreet2: stAdr ? stAdr.street2 : null,
    shipToCity: stAdr ? stAdr.city : null,
    shipToState: stAdr ? stAdr.state : null,
    shipToCountry: stAdr ? stAdr.country : null,
    shipToPostalCode: stAdr ? stAdr.postalCode : null,
    shipToAttention: stAdr ? stAdr.attention : null,
    shipToCompanyName: stAdr ? stAdr.companyName : null,
    shipToResidential: stAdr ? stAdr.residential : false,
    exchangeCurrencyId: customer ? customer.currencyId : null,
    exchangeRate: getExchangeRate(customer),
    representativeId: salesOrderSettings.useCustomerDefaultRep
      ? _.get(customer, 'representativeId', null)
      : null,
    accountingClassId: customer.accountingClassId
      ? customer.accountingClassId
      : null,
    carrierId: customer.defaultCarrierId ? customer.defaultCarrierId : null,
    carrierServiceId: customer.defaultCarrierServiceId
      ? customer.defaultCarrierServiceId
      : null,
    paymentTermId: customer.paymentTermId,
    phone: stAdr?.phone || customer.office,
    emails: stAdr?.email || customer.email,
    priorityId: customer.defaultOrderPriorityId,
  };
};

export const transformShipToAddress = (so: SalesOrder): Address => {
  return {
    ...initialAddress,
    phone: so.shipToPhone, // @beta: additionally added for testing
    email: so.shipToEmail, // @beta: additionally added for testing
    name: so.shipToName,
    country: so.shipToCountry,
    companyName: so.shipToCompanyName,
    postalCode: so.shipToPostalCode,
    residential: so.shipToResidential,
    city: so.shipToCity,
    street: so.shipToStreet,
    street2: so.shipToStreet2,
    state: so.shipToState,
  };
};
