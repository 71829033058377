import React, { useMemo } from 'react';
import { TableCell, TableRow, TableFooter, Typography } from '@mui/material';

import { useCurrencyFormatter } from 'helpers';
import { LedgerSimple } from 'services/ledgers';
import { RenderCustomFooterProps } from 'ui/components/Table/ItemsTable';

const TableFooterModal: React.FC<RenderCustomFooterProps<LedgerSimple>> = (
  props
) => {
  const { footerData } = props;
  const currencyFormatter = useCurrencyFormatter();

  const debitTotal = useMemo(() => {
    if (!footerData) {
      return null;
    }

    return footerData.ledgerItems.reduce(
      (acc, item) => (item.debitAmount || 0) + acc,
      0
    );
  }, [footerData]);

  const creditTotal = useMemo(() => {
    if (!footerData) {
      return null;
    }

    return footerData.ledgerItems.reduce(
      (acc, item) => (item.creditAmount || 0) + acc,
      0
    );
  }, [footerData]);

  return (
    <TableFooter>
      <TableRow>
        <TableCell colSpan={8} />
        <TableCell data-qa="TotalRow-Total">
          <Typography variant="body2">
            <b>Total</b>
          </Typography>
        </TableCell>
        <TableCell data-qa="TotalRow-Debit">
          <Typography variant="body2">
            <b>{debitTotal ? currencyFormatter(debitTotal) : null}</b>
          </Typography>
        </TableCell>
        <TableCell data-qa="TotalRow-Credit">
          <Typography variant="body2">
            <b>{creditTotal ? currencyFormatter(creditTotal) : null}</b>
          </Typography>
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};

export default TableFooterModal;
