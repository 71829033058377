import React, { memo, useCallback, useState } from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { TextField } from 'ui/components/TextField/TextField';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  useHandleCheckboxChange,
  useHandleTextFieldChange,
} from 'services/forms/hooks';
import { PurchaseOrder, PurchaseOrderStatus } from 'services/purchaseOrders';
import { ObjectType } from 'services/customFields';

import { PurchaseOrderAsyncAutocomplete } from 'ui/components/Autocomplete/PurchaseOrderAutocomplete';

import { ReportFiltersProps } from '../../types';
import { CustomFieldsOptions } from '../CustomFieldsOptions';

const PurchaseOrderFilters: React.FC<ReportFiltersProps> = (props) => {
  const { parameters, setParameters, errors } = props;
  const [hasExchangeCurrency, setHasExchangeCurrency] =
    useState<boolean>(false);
  const handleSelectAll = useCallback(
    (name: string) =>
      (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (name === 'optional') {
          setParameters((old) => ({
            ...old,
            showBarcode: checked,
            showCompanyAddress: checked,
            showCompanyName: checked,
            showLineItem: checked,
            showNotes: checked,
            showItemName: checked,
            showStatus: checked,
            showTax: checked,
            showHomeCurrency: checked,
            selectAllOptional: checked,
          }));
        } else {
          setParameters((old) => ({
            ...old,
            showCost: checked,
            columnRowNumber: checked,
            columnItemSku: checked,
            columnItemDescription: checked,
            columnItemId: checked,
            columnItemQuantityOrdered: checked,
            columnItemType: checked,
            columnOrderBuyer: checked,
            columnOrderDateScheduled: checked,
            columnOrderFobPoint: checked,
            columnOrderPaymentTerms: checked,
            columnOrderCarrier: checked,
            columnShipService: checked,
            columnShippingTerms: checked,
            selectAllColumns: checked,
          }));
        }
      },
    [setParameters]
  );
  const handleCheckboxChange = useHandleCheckboxChange(setParameters);
  const handleTextFieldChange = useHandleTextFieldChange(
    setParameters,
    parameters
  );

  const handlePurchaseOrderChange = useCallback(
    (value: PurchaseOrder | null) => {
      setParameters((old) => ({
        ...old,
        purchaseOrderId: value ? value.id : null,
        reportTitle:
          value && value.status === PurchaseOrderStatus.BidRequest
            ? 'Bid Request'
            : 'Purchase Order',
        email: {
          to: value?.email ?? null,
          cc: value?.vendor?.cc ?? null,
          bcc: value?.vendor?.bcc ?? null,
        },
      }));
      if (value?.exchangeCurrencyId !== value?.homeCurrencyId) {
        setHasExchangeCurrency(true);
      } else {
        setHasExchangeCurrency(false);
      }
    },
    [setParameters]
  );
  const handleSetSelectedCustomFields = (customFields: number[]) => {
    setParameters((old) => ({
      ...old,
      customFields,
    }));
  };
  // TODO : RESTYLING : Remove box wrappers around inputs after FF removal

  return (
    <>
      <Accordion square defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          data-qa="purchase-order-report-required"
        >
          <Typography>
            <b>Required</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%" pt={1}>
            <Box>
              <PurchaseOrderAsyncAutocomplete
                label="Purchase Order Number"
                onChange={handlePurchaseOrderChange}
                value={parameters.purchaseOrderId}
                placeholder="Select Purchase Order"
                error={!!errors.purchaseOrderId}
                helperText={errors.purchaseOrderId}
                dataQa="purchase-order-report-number-field"
                customQuickSearchColumns={['number']}
              />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Box px={2} pb={0} pt="16px">
        <FormControlLabel
          className="redesign"
          sx={{ marginBottom: '8px' }}
          control={
            <Checkbox
              className="redesign"
              checked={Boolean(parameters.selectAllOptional)}
              onChange={handleSelectAll('optional')}
              color="primary"
              data-qa="purchase-order-report-select-all-optional"
            />
          }
          label="Select All Optional"
        />
      </Box>
      <Accordion square>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          data-qa="purchase-order-report-select-all-optional-expand"
        >
          <Typography>
            <b>Optional</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%">
            <Box>
              <TextField
                className="redesign"
                variant="standard"
                label="Report Title"
                placeholder="Report Title"
                name="reportTitle"
                value={parameters.reportTitle || null}
                onChange={handleTextFieldChange}
                dataQa="purchase-order-report-report-title"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showBarcode)}
                    onChange={handleCheckboxChange}
                    name="showBarcode"
                    color="primary"
                    data-qa="purchase-order-report-show-barcode"
                  />
                }
                label="Show Barcode"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showCompanyAddress)}
                    onChange={handleCheckboxChange}
                    name="showCompanyAddress"
                    color="primary"
                    data-qa="purchase-order-report-company-address"
                  />
                }
                label="Show Company Address"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showCompanyName)}
                    onChange={handleCheckboxChange}
                    name="showCompanyName"
                    color="primary"
                    data-qa="purchase-order-report-show-name"
                  />
                }
                label="Show Company Name"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showLineItem)}
                    onChange={handleCheckboxChange}
                    name="showLineItem"
                    color="primary"
                    data-qa="purchase-order-report-show-line-item"
                  />
                }
                label="Show Line Item"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showNotes)}
                    onChange={handleCheckboxChange}
                    name="showNotes"
                    color="primary"
                    data-qa="purchase-order-report-show-notes"
                  />
                }
                label="Show Notes"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showItemName)}
                    onChange={handleCheckboxChange}
                    name="showItemName"
                    color="primary"
                    data-qa="purchase-order-report-show-vendor-item-name"
                  />
                }
                label="Show Vendor Item Name"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showStatus)}
                    onChange={handleCheckboxChange}
                    name="showStatus"
                    color="primary"
                    data-qa="purchase-order-report-show-status"
                  />
                }
                label="Show Status"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showTax)}
                    onChange={handleCheckboxChange}
                    name="showTax"
                    color="primary"
                    data-qa="purchase-order-report-show-tax"
                  />
                }
                label="Show Tax"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showHomeCurrency)}
                    onChange={handleCheckboxChange}
                    name="showHomeCurrency"
                    color="primary"
                    disabled={!hasExchangeCurrency}
                    data-qa="purchase-order-report-show-home-currency"
                  />
                }
                label="Show Home Currency"
              />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Box px={2} pb={0} pt="16px">
        <FormControlLabel
          className="redesign"
          sx={{ marginBottom: '8px' }}
          control={
            <Checkbox
              className="redesign"
              checked={Boolean(parameters.selectAllColumns)}
              onChange={handleSelectAll('columns')}
              color="primary"
              data-qa="purchase-order-report-select-all-columns"
            />
          }
          label="Select All Columns"
        />
      </Box>
      <Accordion square>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          data-qa="purchase-order-report-select-all-columns-expand"
        >
          <Typography>
            <b>Columns</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%">
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showCost)}
                    onChange={handleCheckboxChange}
                    name="showCost"
                    color="primary"
                    data-qa="purchase-order-report-show-cost"
                  />
                }
                label="Show Cost"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnItemSku)}
                    onChange={handleCheckboxChange}
                    name="columnItemSku"
                    color="primary"
                    data-qa="purchase-order-report-item-SKU"
                  />
                }
                label="Item SKU"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnItemDescription)}
                    onChange={handleCheckboxChange}
                    name="columnItemDescription"
                    color="primary"
                    data-qa="purchase-order-report-item-description"
                  />
                }
                label="Item Description"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnRowNumber)}
                    onChange={handleCheckboxChange}
                    name="columnRowNumber"
                    color="primary"
                    data-qa="purchase-order-report-row-number"
                  />
                }
                label="Show Row Number"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnItemId)}
                    onChange={handleCheckboxChange}
                    name="columnItemId"
                    color="primary"
                    data-qa="purchase-order-report-item-name"
                  />
                }
                label="Item Name"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnItemQuantityOrdered)}
                    onChange={handleCheckboxChange}
                    name="columnItemQuantityOrdered"
                    color="primary"
                    data-qa="purchase-order-report-item-quantity-ordered"
                  />
                }
                label="Item Quantity Ordered"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnItemType)}
                    onChange={handleCheckboxChange}
                    name="columnItemType"
                    color="primary"
                    data-qa="purchase-order-report-item-type"
                  />
                }
                label="Item Type"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnOrderBuyer)}
                    onChange={handleCheckboxChange}
                    name="columnOrderBuyer"
                    color="primary"
                    data-qa="purchase-order-report-order-buyer"
                  />
                }
                label="Order Buyer"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnOrderDateScheduled)}
                    onChange={handleCheckboxChange}
                    name="columnOrderDateScheduled"
                    color="primary"
                    data-qa="purchase-order-report-order-date-scheduled"
                  />
                }
                label="Order Date Scheduled"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnOrderFobPoint)}
                    onChange={handleCheckboxChange}
                    name="columnOrderFobPoint"
                    color="primary"
                    data-qa="purchase-order-report-order-FOB-point"
                  />
                }
                label="Order FOB Point"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnOrderPaymentTerms)}
                    onChange={handleCheckboxChange}
                    name="columnOrderPaymentTerms"
                    color="primary"
                    data-qa="purchase-order-report-order-payment-terms"
                  />
                }
                label="Order Payment Terms"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnOrderCarrier)}
                    onChange={handleCheckboxChange}
                    name="columnOrderCarrier"
                    color="primary"
                    data-qa="purchase-order-report-order-carrier"
                  />
                }
                label="Order Carrier"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnShipService)}
                    onChange={handleCheckboxChange}
                    name="columnShipService"
                    color="primary"
                    data-qa="purchase-order-report-order-ship-service"
                  />
                }
                label="Order Ship Service"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnShippingTerms)}
                    onChange={handleCheckboxChange}
                    name="columnShippingTerms"
                    color="primary"
                    data-qa="purchase-order-report-order-shipping-terms"
                  />
                }
                label="Order Shipping Terms"
              />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      <CustomFieldsOptions
        objectType={ObjectType.PurchaseOrder}
        selectedCustomFields={(parameters.customFields || []) as number[]}
        setSelectedCustomFields={handleSetSelectedCustomFields}
        dataQa="purchase-order-report"
      />
    </>
  );
};

export default memo(PurchaseOrderFilters);
