import _ from 'lodash';

import { ItemType } from 'services/items';

import { PickItem } from './types';

export const pickItemIsInventoryType = (pickItem: PickItem) =>
  _.get(pickItem, 'item.itemType') === ItemType.Inventory;

export const getPickItemName = (pickItem: PickItem) => {
  if (pickItem.salesOrderItem) {
    return pickItem.salesOrderItem.name;
  }

  if (pickItem.purchaseOrderItem) {
    return pickItem.purchaseOrderItem.name;
  }

  return null;
};

export const getPickItemDescription = (pickItem: PickItem) => {
  if (pickItem.salesOrderItem) {
    return pickItem.salesOrderItem.description;
  }

  if (pickItem.purchaseOrderItem) {
    return pickItem.purchaseOrderItem.description;
  }

  return null;
};
