import React, { useMemo, useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Icon } from 'ui/components/Icon';
interface FBOTextFieldProps {
  autoFocus?: boolean;
  required: boolean;
  name: string;
  label: string;
  type: string;
  className?: string;
  value: string;
  error: boolean;
  errorText: string;
  dataQa: string;
  tabIndex?: number;
  allowLastPassIcon: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const canShowText = (inputFieldType: string) =>
  !['password'].includes(inputFieldType);

const FBOTextField = ({
  autoFocus = false,
  type,
  errorText,
  dataQa,
  allowLastPassIcon,
  onChange,
  onBlur = () => {},
  ...otherProps
}: FBOTextFieldProps) => {
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    onBlur(event);
  };

  const initialType = useMemo(() => {
    return type;
  }, []);

  const [showText, setShowText] = useState(canShowText(initialType));

  const handleClickShowPassword = () => setShowText((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <TextField
      {...otherProps}
      fullWidth
      type={showText ? 'text' : 'password'}
      autoFocus={autoFocus}
      variant="standard"
      helperText={errorText}
      inputProps={{
        'data-qa': dataQa,
        'data-lpignore': `${!allowLastPassIcon}`,
      }}
      InputProps={{
        endAdornment: !canShowText(initialType) && (
          <InputAdornment position="end">
            <IconButton
              aria-label="show-password"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
              disableRipple
            >
              {showText ? <Icon name="EyeClosed" /> : <Icon name="Eye" />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      InputLabelProps={{ shrink: true }}
      FormHelperTextProps={{ children: ' ' }}
      onChange={onChange}
      onBlur={handleBlur}
    />
  );
};

export default FBOTextField;
