import { Box } from '@mui/material';
import React, { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import {
  ItemsTable,
  useSelectedItemsChanges,
} from 'ui/components/Table/ItemsTable';
import {
  findNextNegativeId,
  replaceValueInCollection,
  removeValueFromCollection,
} from 'helpers';
import {
  CustomerCarrierAccount,
  initialCustomerCarrierAccount,
} from 'services/customers';
import { activeUserHasPermission } from 'services/user/redux';
import { Errors, validateYup } from 'services/forms/validation';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';

import { CarrierAccountsProps } from './types';
import { CarrierAccountsTabAction, CARRIER_ACCOUNTS_COLUMNS } from './consts';
import CarrierAccountAddModal from './CarrierAccountAddModal';
import CarrierAccountRow from './CarrierAccountRow';
import { yupCustomerCarrierAccountsSchema } from '../../validation';
import { editCustomerPermissions } from '../../../helpers';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const CarrierAccountsTab: React.FC<CarrierAccountsProps> = (props) => {
  const { activeCustomer, setActiveCustomer } = props;

  const [activeCarrierAccount, setActiveCarrierAccount] =
    useState<CustomerCarrierAccount>(initialCustomerCarrierAccount);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedAccounts, setSelectedAccounts] = useState<number[]>([]);
  const [validationErrors, setValidationErrors] = useState<Errors>({});
  const [reassignModalVisible, setReassignModalVisible] = useState(false);

  const editPermission = editCustomerPermissions(activeCustomer);

  const showCarrierAccountsModal = useSelector(
    activeUserHasPermission(editPermission)
  );

  const handleSelectedChange = useSelectedItemsChanges(
    selectedAccounts,
    setSelectedAccounts
  );

  const openModalForAddingNew = useCallback(() => {
    setActiveCarrierAccount({
      ...initialCustomerCarrierAccount,
      id: findNextNegativeId(activeCustomer.carrierAccountSettings),
    });
    setModalVisible(true);
  }, [activeCustomer.carrierAccountSettings]);

  const openModalForEditing = useCallback(
    (id: number) => {
      setActiveCarrierAccount(
        activeCustomer.carrierAccountSettings.find((c) => c.id === id)!
      );

      if (showCarrierAccountsModal) {
        setModalVisible(true);
      }
    },
    [activeCustomer.carrierAccountSettings, showCarrierAccountsModal]
  );

  const onModalClose = useCallback(() => {
    setModalVisible(false);
    setValidationErrors({});
    setActiveCarrierAccount(initialCustomerCarrierAccount);
  }, []);

  const setCarrierAccounts = useCallback(
    (carrierAccounts: React.SetStateAction<CustomerCarrierAccount[]>) => {
      if (typeof carrierAccounts === 'function') {
        setActiveCustomer((c) => ({
          ...c,
          carrierAccountSettings: carrierAccounts(c.carrierAccountSettings),
        }));
        return;
      }
      setActiveCustomer((c) => ({
        ...c,
        carrierAccountSettings: carrierAccounts,
      }));
    },
    [setActiveCustomer]
  );

  const save = useCallback(
    (reassignDefaultFlag: boolean) => {
      if (reassignDefaultFlag) {
        const indexNumDefault = activeCustomer.carrierAccountSettings.findIndex(
          (a) => a.defaultFlag === true
        );
        const customerAccountDefault =
          activeCustomer.carrierAccountSettings.find(
            (a) => a.defaultFlag === true
          );

        if (customerAccountDefault) {
          customerAccountDefault.defaultFlag = false;

          setCarrierAccounts(
            (oldState) =>
              replaceValueInCollection(
                oldState,
                customerAccountDefault,
                indexNumDefault
              )!
          );
        }
      }

      const indexNum = activeCustomer.carrierAccountSettings.findIndex(
        (a) => a.id === activeCarrierAccount.id
      );

      if (indexNum === -1) {
        setCarrierAccounts((oldState) => [...oldState, activeCarrierAccount]);
      } else {
        setCarrierAccounts(
          (oldState) =>
            replaceValueInCollection(oldState, activeCarrierAccount, indexNum)!
        );
      }
    },
    [
      activeCustomer.carrierAccountSettings,
      activeCarrierAccount,
      setCarrierAccounts,
    ]
  );

  const handleSave = useCallback(() => {
    const isValid = validateYup(
      activeCarrierAccount,
      yupCustomerCarrierAccountsSchema,
      setValidationErrors
    );

    if (!isValid) {
      return;
    }

    const indexNumDefault = activeCustomer.carrierAccountSettings.findIndex(
      (a) => a.defaultFlag === true
    );

    if (activeCarrierAccount.defaultFlag && indexNumDefault !== -1) {
      setReassignModalVisible(true);
      return;
    }

    save(false);

    setModalVisible(false);
  }, [save, activeCustomer, activeCarrierAccount]);

  const handleCloseReassignModal = () => {
    setReassignModalVisible(false);
  };

  const handleConfirmReassignModal = () => {
    save(true);
    setReassignModalVisible(false);
    setModalVisible(false);
  };

  const handleAction = useCallback(
    (action: any) => {
      switch (action.type) {
        case CarrierAccountsTabAction.Delete: {
          const carrierAccounts =
            activeCustomer.carrierAccountSettings[action.value];
          if (carrierAccounts.id === null || carrierAccounts.id < 0) {
            setCarrierAccounts((old) =>
              removeValueFromCollection(old, action.value)
            );
          } else {
            setCarrierAccounts(
              (old) =>
                replaceValueInCollection(
                  old,
                  { ...old[action.value], deleted: true },
                  action.value
                ) || []
            );
          }
          break;
        }
      }
    },
    [activeCustomer.carrierAccountSettings, setCarrierAccounts]
  );

  const deleteClicked = useCallback(() => {
    const existingItems: CustomerCarrierAccount[] =
      activeCustomer.carrierAccountSettings
        .filter((i) => i.id! > 0)
        .map((old) => {
          return {
            ...old,
            deleted: selectedAccounts.includes(old.id!) || old.deleted,
          };
        });

    const newItems: CustomerCarrierAccount[] =
      activeCustomer.carrierAccountSettings.filter((i) => {
        return i.id! < 0 && !selectedAccounts.includes(i.id!);
      });

    setCarrierAccounts([...existingItems, ...newItems]);
    setSelectedAccounts([]);
  }, [
    selectedAccounts,
    setCarrierAccounts,
    activeCustomer.carrierAccountSettings,
  ]);

  return (
    <Box display="flex" flexDirection="column" height="100%" width="100%">
      <FBOTitleBar title="Carrier Accounts">
        {!_.isEmpty(selectedAccounts) && (
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="secondary"
            color="positive"
            size="medium"
            data-qa="carrier-account-delete"
            onClick={deleteClicked}
            permissions={editPermission}
          >
            Delete
          </FBOButton>
        )}
        <FBOButton
          variant="secondary"
          color="positive"
          size="medium"
          icon="FBOAddCircle"
          data-qa="carrier-account-add"
          onClick={openModalForAddingNew}
          permissions={editPermission}
        >
          Add New
        </FBOButton>
      </FBOTitleBar>

      <ItemsTable
        data={activeCustomer.carrierAccountSettings}
        setData={setCarrierAccounts}
        columns={CARRIER_ACCOUNTS_COLUMNS}
        onItemClick={openModalForEditing}
        onSelectedChange={handleSelectedChange}
        onAction={handleAction}
        RenderCustomRow={CarrierAccountRow}
        filterRows={(s) => !s.deleted}
        selectedItems={selectedAccounts}
        emptyTableText="ADD NEW ENTRY BY PRESSING `ADD`"
      />
      <CarrierAccountAddModal
        modalVisible={modalVisible}
        activeCarrierAccount={activeCarrierAccount}
        setActiveCarrierAccount={setActiveCarrierAccount}
        onClose={onModalClose}
        onSaveChanges={handleSave}
        validationErrors={validationErrors}
      />
      <ConfirmationModal
        open={reassignModalVisible}
        title={`Warning`}
        body={`There is already a default carrier account specified. Do you want to reassign the default to the one you are adding?`}
        onCancelClicked={handleCloseReassignModal}
        onConfirmClicked={handleConfirmReassignModal}
        confirmLabel="Reassign"
      />
    </Box>
  );
};

export default memo(CarrierAccountsTab);
