import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const usePermissionItemsStyle = makeStyles((theme: Theme) => ({
  searchInputInner: {
    height: 32,
    background: 'white',
    paddingTop: `0!important`,
    paddingBottom: `0!important`,
  },
  searchInputOuter: {
    flex: 1,
    paddingRight: theme.spacing(1),
  },
  noBorder: {
    border: 'none',
  },
}));
