import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { ProgressBarProps } from './types';

const ProgressBar: React.FC<ProgressBarProps> = (props) => {
  const { value, className } = props;

  return (
    <LinearProgress variant="determinate" value={value} className={className} />
  );
};

export default ProgressBar;
