import { roundToDecimals } from 'helpers';

export const calculatedMarginAndMarkup = (
  subTotal: {
    homeCurrencySubtotal: number;
    multiCurrencySubTotal: number;
    avarageCostTotal: number;
  },
  orderDiscount: number
) => {
  const markupAmount =
    subTotal.homeCurrencySubtotal - orderDiscount - subTotal.avarageCostTotal;

  const markupPrecentage = markupAmount / subTotal.avarageCostTotal;

  const marginPercentage =
    markupAmount / (subTotal.homeCurrencySubtotal - orderDiscount);

  const resolvedMarkupAmount = Number.isFinite(markupAmount)
    ? roundToDecimals(markupAmount, 2)
    : 0;

  const resolvedMakupPercentage = Number.isFinite(markupPrecentage)
    ? roundToDecimals(markupPrecentage * 100, 2)
    : 0;

  const resolvedMarginPercentage = Number.isFinite(marginPercentage)
    ? roundToDecimals(marginPercentage * 100, 2)
    : 0;

  return {
    resolvedMarkupAmount,
    resolvedMakupPercentage,
    resolvedMarginPercentage,
  };
};
