import React, { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid, Typography, Tooltip } from '@mui/material';
import _ from 'lodash';

import { TextField } from 'ui/components/TextField/TextField';
import { getTrackingTypes } from 'services/settings/tracking';
import { ItemTrackingType } from 'services/inventory';
import { findNextNegativeId } from 'helpers';
import { activeUserHasPermission } from 'services/user/redux';

import { TrackingTabProps } from './types';
import { editItemPermissions } from '../../helpers';
import { mapTrackingTabErrorsByIds } from '../../validations';
import { decimalQuantitiesValidationMessage, tooltipTrackingMessage } from './';
import FBOSwitch from 'ui/theme/components/FBOSwitch/FBOSwitch';

const TrackingTab: React.FC<TrackingTabProps> = (props) => {
  const { item, setItem, errors } = props;

  const trackingTypes = useSelector(getTrackingTypes).items;

  const editPermissions = editItemPermissions(item);

  const canEdit = useSelector(activeUserHasPermission(editPermissions));
  const errorIds = mapTrackingTabErrorsByIds(errors, item.itemTrackingTypeList);

  const itemTrackingTypes = useMemo(
    () => _.keyBy(item.itemTrackingTypeList, 'trackingTypeId'),
    [item.itemTrackingTypeList]
  );

  const itemHasTrackingType = useCallback(
    (trackingTypeId: number) => {
      const itemTrackingType = itemTrackingTypes[trackingTypeId];
      return itemTrackingType !== undefined && !itemTrackingType.deleted;
    },
    [itemTrackingTypes]
  );
  const handleSwitchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const typeId = parseInt(event.target.value, 10);
      const index = item.itemTrackingTypeList.findIndex(
        (type) => type.trackingTypeId === typeId
      );
      // if unchecked and item tracking type has id < 0 remove that tracking type from state
      if (!checked && item.itemTrackingTypeList[index].id < 0) {
        setItem((prevItem) => ({
          ...prevItem,
          itemTrackingTypeList: [
            ...prevItem.itemTrackingTypeList.slice(0, index),
            ...prevItem.itemTrackingTypeList.slice(index + 1),
          ],
        }));
        return;
      }

      // add new tracking type to item
      if (index === -1) {
        setItem((prevItem) => ({
          ...prevItem,
          itemTrackingTypeList: [
            ...prevItem.itemTrackingTypeList,
            {
              id: findNextNegativeId<ItemTrackingType>(
                item.itemTrackingTypeList
              ),
              trackingTypeId: typeId,
              trackingType: trackingTypes.find((t) => t.id === typeId)!,
              nextValue: null,
              deleted: false,
            },
          ],
        }));

        return;
      }

      // update existing tracking type
      setItem((prevItem) => ({
        ...prevItem,
        itemTrackingTypeList: [
          ...prevItem.itemTrackingTypeList.slice(0, index),
          {
            ...prevItem.itemTrackingTypeList[index],
            deleted: !checked,
          },
          ...prevItem.itemTrackingTypeList.slice(index + 1),
        ],
      }));
    },
    [item.itemTrackingTypeList, trackingTypes, setItem]
  );

  const handleNextValueChange = useCallback(
    (trackingTypeId: number) =>
      (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value;
        const index = item.itemTrackingTypeList.findIndex(
          (t) => t.trackingTypeId === trackingTypeId
        );

        setItem((prevItem) => ({
          ...prevItem,
          itemTrackingTypeList: [
            ...prevItem.itemTrackingTypeList.slice(0, index),
            {
              ...prevItem.itemTrackingTypeList[index],
              nextValue: value,
            },
            ...prevItem.itemTrackingTypeList.slice(index + 1),
          ],
        }));
      },
    [item.itemTrackingTypeList, setItem]
  );

  return (
    <Box p={2} style={{ overflow: 'hidden', overflowY: 'scroll' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            <b>Tracking Types</b>
          </Typography>
        </Grid>
        {trackingTypes.map((type, index) => {
          const hasError = errorIds.includes(type.id!);
          const isTrackingTypeSerialNumber =
            type.trackingDataType === 'Serial Number';
          const isToggleDisabled =
            item.totalQty !== null &&
            isTrackingTypeSerialNumber &&
            !Number.isInteger(item.totalQty);

          return (
            <Grid item xs={12} key={index}>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Box
                  pr={1}
                  width="400px"
                  color={itemHasTrackingType(type.id) ? undefined : '#838383'}
                >
                  <Typography noWrap>{type.name}</Typography>
                  <Typography variant="subtitle2" noWrap>
                    {type.description}
                  </Typography>
                </Box>
                <Box px={1} width="200px">
                  <Tooltip
                    title={tooltipTrackingMessage[type.trackingDataType]}
                    placement="top-start"
                  >
                    <TextField
                      className="redesign"
                      variant="standard"
                      type={
                        type.trackingDataType === 'Date' ? 'number' : 'text'
                      }
                      placeholder={
                        type.trackingDataType === 'Date'
                          ? 'Shelf Life (Days)'
                          : type.trackingDataType
                      }
                      label="Next Value"
                      disabled={!itemHasTrackingType(type.id)}
                      error={!!hasError}
                      helperText={
                        !!hasError &&
                        'Next value must be blank or contain at least one digit'
                      }
                      permissions={editPermissions}
                      onChange={handleNextValueChange(type.id)}
                      value={_.get(
                        itemTrackingTypes[type.id],
                        'nextValue',
                        null
                      )}
                      dataQa={
                        'tracking-' +
                        type.name.replace(/\s/g, '-').toLowerCase()
                      }
                    />
                  </Tooltip>
                </Box>
                <Box px={1}>
                  <FBOSwitch
                    name={`tracking-${type.name
                      .replace(/\s/g, '-')
                      .toLowerCase()}-switch`}
                    label={
                      isToggleDisabled
                        ? decimalQuantitiesValidationMessage
                        : undefined
                    }
                    labelPlacement="end"
                    size="small"
                    onChange={handleSwitchChange}
                    value={type.id}
                    checked={itemHasTrackingType(type.id)}
                    disabled={!canEdit || isToggleDisabled}
                    dataQa={`tracking-${type.name
                      .replace(/\s/g, '-')
                      .toLowerCase()}-button`}
                  />
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default memo(TrackingTab);
