import { InventoryRow, ItemInventory } from './types';

export const initialItemInventory: ItemInventory = {
  availableQty: 0,
  committedQty: 0,
  totalQty: 0,
  totalCost: 0,
  allocatedQty: 0,
  availableToPickQty: 0,
  incomingQty: 0,
  inventoryRowList: [],
  inventoryTableRows: [],
};

export const initialInventoryRow: InventoryRow = {
  id: -1,
  availableQty: 0,
  committedQty: 0,
  totalQty: 0,
  incomingQty: 0,
  cost: null,
  locationId: -1,
  trackingGroupList: [],
  availableToPickQty: 0,
  allocatedOnPurchaseOrders: 0,
  allocatedOnSalesOrders: 0,
  qtyIncomingOnPurchaseOrders: 0,
  qtyIncomingOnSalesOrders: 0,
};
