import { State } from 'app/redux';
import _ from 'lodash';

const getXero = (state: State) => state.integrations.xero;

export const getXeroAccounts = (state: State) => getXero(state).items;

export const getXeroAccountsIsLoading = (state: State) =>
  getXero(state)._status.isLoading;

export const getXeroAccountIsConnected = (state: State) =>
  !_.isEmpty(getXeroAccounts(state));

export const getXeroTaxRates = (state: State) =>
  state.integrations.xero.taxRates;
