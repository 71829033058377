import React, { memo } from 'react';

import {
  ItemsTable,
  useSelectedItemsChanges,
} from 'ui/components/Table/ItemsTable';

import ItemsStepRow from './ItemsStepRow';
import { columns } from './consts';
import { ItemsStepProps } from './types';

const ItemsStep: React.FC<ItemsStepProps> = (props) => {
  const {
    autoPoItems,
    setAutoPoItems,
    selectedItems,
    setSelectedItems,
    locationId,
    errors,
  } = props;

  const handleSelectClick = useSelectedItemsChanges(
    selectedItems,
    setSelectedItems
  );

  return (
    <ItemsTable
      columns={columns}
      data={autoPoItems}
      setData={setAutoPoItems}
      selectedItems={selectedItems}
      onSelectedChange={handleSelectClick}
      RenderCustomRow={ItemsStepRow}
      meta={{
        locationId,
      }}
      emptyTableText="No Items"
      rowErrors={errors}
    />
  );
};

export default memo(ItemsStep);
