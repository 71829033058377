import React, { memo, useState, useCallback, useEffect, useRef } from 'react';
import { Divider, Box } from '@mui/material';
import { Paper } from 'ui/components/Paper/Paper';
import { CardProps, CardData } from './types';
import { useCardStyle } from './styled';
import { GlobalSettingsFields } from 'services/commerce/settings';
import { Errors, validateYup } from 'services/forms/validation';
import { yupGlobalSettings } from './validations';
import { NetworkSpinnerWrapper } from 'ui/components/NetworkSpinnerWrapper';
import { useModalActions } from '../../ModalActionsContext';
import { ModalRow } from './components/ModalRow';

const Card: React.FC<CardProps> = ({
  show,
  rows,
  id,
  data,
  onApplyClicked,
  cardDataLoaded,
}: CardProps) => {
  const classes = useCardStyle();

  const { registerSaveCallback, registerCancelCallback } = useModalActions();
  const [cardData, setCardData] = useState<CardData>(data!);
  const [validationErrors, setValidationErrors] = useState<Errors>({});
  const [isSaveTriggered, setIsSaveTriggered] = useState<boolean>(false);
  const isMounted = useRef<boolean>(true);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      setCardData(data!);
    }
  }, [data, cardDataLoaded]);

  const handleCardDataChanged = useCallback((field: string, value: unknown) => {
    if (isMounted.current) {
      setCardData((oldCardData) => {
        const updateNestedField = (section: string) => ({
          ...oldCardData,
          [section]: {
            ...oldCardData[section],
            [field]: value,
          },
        });

        if (Object.values(GlobalSettingsFields.productExport).includes(field)) {
          return updateNestedField('productExport');
        } else if (
          Object.values(GlobalSettingsFields.inventoryExport).includes(field)
        ) {
          return updateNestedField('inventoryExport');
        }

        return { ...oldCardData, [field]: value };
      });
    }
  }, []);

  const validateAndSave = useCallback(() => {
    if (!isSaveTriggered || !isMounted.current) return;

    if (!validateYup(cardData, yupGlobalSettings, setValidationErrors)) {
      setIsSaveTriggered(false);
      return;
    }

    onApplyClicked(cardData);
    setIsSaveTriggered(false);
  }, [cardData, onApplyClicked, isSaveTriggered]);

  useEffect(() => {
    if (isSaveTriggered) {
      validateAndSave();
    }
  }, [isSaveTriggered, validateAndSave]);

  const onSave = useCallback(() => {
    if (isMounted.current) {
      setIsSaveTriggered(true);
    }
  }, []);

  const onCancel = useCallback(() => {
    if (isMounted.current) {
      setCardData(data!);
      setValidationErrors({});
    }
  }, [data]);

  useEffect(() => {
    registerSaveCallback(onSave);
    registerCancelCallback(onCancel);

    return () => {
      registerSaveCallback(() => {});
      registerCancelCallback(() => {});
    };
  }, [registerSaveCallback, registerCancelCallback, onSave, onCancel]);

  return show ? (
    <Box className={classes.container}>
      <Paper
        id={id}
        className={`${classes.paper} static-paper redesign`}
        sx={{ boxShadow: 'none' }}
      >
        <NetworkSpinnerWrapper
          isLoading={!cardDataLoaded}
          size={24}
          height="350px"
          hideBackground
        >
          {cardDataLoaded &&
            rows.map((row, i) => (
              <React.Fragment key={row.field ?? i}>
                <ModalRow
                  {...row}
                  setCardData={handleCardDataChanged}
                  cardData={cardData}
                  errors={validationErrors}
                />
                {i !== rows.length - 1 && <Divider />}
              </React.Fragment>
            ))}
        </NetworkSpinnerWrapper>
      </Paper>
    </Box>
  ) : null;
};

export default memo(Card);
