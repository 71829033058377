import { TrackingGroup } from 'services/inventory';

export const isTrackingGroupAmountValid = (trackingGroups: TrackingGroup[]) => {
  let valid = true;
  trackingGroups.forEach((t) => {
    if ((t.quantity || 0) > t.onHand) {
      valid = false;
    }
  });

  return valid;
};
