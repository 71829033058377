import _ from 'lodash';

import { transformDateToMomentDate } from 'helpers';

import { Uom } from './types';
import { transformUomConversion } from './conversions';

export const transformUom = (response: any): Uom => {
  const toConversions = response.toConversions || [];
  const fromConversions = response.fromConversions || [];

  return {
    id: response.id,
    version: response.version,
    dateLastModified: transformDateToMomentDate(response.dateLastModified),
    dateCreated: transformDateToMomentDate(response.dateCreated),
    name: response.name,
    description: response.description,
    abbreviation: response.abbreviation,
    deleted: response.deleted,
    isIntegral: response.isIntegral,
    defaultFlag: response.defaultFlag,
    systemUom: response.systemUom,
    toConversions: toConversions.map(transformUomConversion),
    fromConversions: fromConversions.map(transformUomConversion),
  };
};

export const transformUomToPostUom = (uom: Uom): any => ({
  name: uom.name,
  description: uom.description,
  abbreviation: uom.abbreviation,
  defaultFlag: uom.defaultFlag,
  toConversions: uom.toConversions
    .filter((conversion) => !conversion.deleted)
    .map((conversion) => _.pick(conversion, ['toUomId', 'factor'])),
});

export const transformUomToPutUom = (uom: Uom): any => ({
  id: uom.id,
  version: uom.version,
  name: uom.name,
  description: uom.description,
  abbreviation: uom.abbreviation,
  defaultFlag: uom.defaultFlag,
  toConversions: uom.toConversions
    .filter((conversion) => !(conversion.id < 0 && conversion.deleted))
    .map((conversion) => {
      if (conversion.id < 0) {
        return _.pick(conversion, ['toUomId', 'factor']);
      }

      return _.pick(conversion, [
        'id',
        'toUomId',
        'factor',
        'version',
        'deleted',
      ]);
    }),
  fromConversions: uom.fromConversions.map((conversion) => {
    return _.pick(conversion, [
      'id',
      'fromUomId',
      'toUomId',
      'factor',
      'version',
      'deleted',
    ]);
  }),
  deleted: false,
  isIntegral: uom.isIntegral,
  systemUom: uom.systemUom,
});
