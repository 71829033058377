import { imageApi } from 'services/api/config';
import { ImageStorageType } from 'services/images';
import { Document } from '.';

import { resolveFileName } from './helpers';

export const uploadDocuments = async (
  storageType: ImageStorageType,
  files: File[]
): Promise<string[]> => {
  const formData = new FormData();
  formData.append('StorageType', storageType);

  files.forEach((file) => {
    formData.append('Files', file);
  });

  const res = await imageApi.post(`/api/v1/storage/documents`, formData);

  return res.data;
};

export const deleteDocuments = async (
  documentsToDelete: Document[]
): Promise<void> => {
  if (!documentsToDelete.length) {
    return;
  }
  const fileNames = documentsToDelete.map((doc) =>
    resolveFileName(doc.filePath)
  );
  await imageApi.delete(`/api/v1/storage/documents`, { data: fileNames });
};

export const fetchFileName = async (filePath: string): Promise<string> => {
  const fileName = resolveFileName(filePath);

  const res = await imageApi.get(`/api/v1/storage/documents/info/${fileName}`);

  return res.data.fileName;
};

export const downloadDocument = async (filePath: string): Promise<string> => {
  const fileName = resolveFileName(filePath);

  const response = await imageApi.get(`/api/v1/storage/documents/${fileName}`);

  return response.data;
};
