import React from 'react';
import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { Pagination } from 'services/search';
import { CurrencySearchResultsProps } from './types';
import { CURRENCY_COLUMNS } from '../../consts';
import CurrencyRow from './CurrencyRow';
import { PermissionType } from 'services/permissions';
import { useSelector } from 'react-redux';
import { activeUserHasPermission } from 'services/user/redux';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const CurrencySearchResults: React.FC<CurrencySearchResultsProps> = (props) => {
  const {
    activeCurrencyId,
    handleCurrencyClick,
    currency,
    onAddNewPress,
    onPaginationChange,
    isLoadingCurrencies,
    pagination,
  } = props;
  const isDisabled = useSelector(
    activeUserHasPermission([PermissionType.CurrencyCreate])
  );
  const isEdit = useSelector(
    activeUserHasPermission([PermissionType.CurrencyEdit])
  );
  const isView = useSelector(
    activeUserHasPermission([PermissionType.CurrencyView])
  );
  const handlePaginationChange = (newPagination: Pagination) => {
    onPaginationChange(newPagination);
  };

  return (
    <>
      <FBOTitleBar title="Currencies">
        {activeCurrencyId !== -1 && (
          <FBOButton
            variant={!activeCurrencyId ? 'primary' : 'secondary'}
            color="positive"
            size="medium"
            icon="FBOAddCircle"
            disabled={!isDisabled}
            onClick={onAddNewPress}
            permissions={[PermissionType.CurrencyCreate]}
            data-qa="currency-add-new"
          >
            {!activeCurrencyId ? 'Add New' : ''}
          </FBOButton>
        )}
      </FBOTitleBar>

      {(isEdit || isView) && (
        <ItemsTable
          data={currency}
          columns={CURRENCY_COLUMNS}
          RenderCustomRow={CurrencyRow}
          activeId={activeCurrencyId}
          onItemClick={handleCurrencyClick}
          selectableItems={false}
          isLoadingData={isLoadingCurrencies}
          pagination={pagination}
          onPaginationChange={handlePaginationChange}
          dataQa="currency-search"
          showEnhancedTablePaginationActions={!Boolean(activeCurrencyId)}
        />
      )}
    </>
  );
};

export default CurrencySearchResults;
