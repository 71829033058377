import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useQboDisconnectedStyle = makeStyles((theme: Theme) => ({
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
    marginLeft: 5,
    color: theme.palette.primary.main,
  },
  qboTitle: {
    fontWeight: 900,
    fontSize: '4rem',
    paddingBottom: '20px',
    letterSpacing: '0.5px',
  },
}));
