export interface TrackingType {
  id: number;
  version: number;
  name: string;
  description: string;
  trackingDataType: TrackingDataTypes;
  deleted: boolean;
}

export interface NewTrackingType {
  name: string | null;
  description: string | null;
  trackingDataType: TrackingDataTypes | null;
}

export enum TrackingDataTypes {
  Date = 'Date',
  SerialNumber = 'Serial Number',
  Text = 'Text',
}

export enum TrackingDataTypesOption {
  Date = 'Date',
  Text = 'Text',
}
