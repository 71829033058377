import React, { memo, useCallback } from 'react';
import { PermissionType } from 'services/permissions';
import { TaxRatesTitleBarProps } from './types';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const TaxRatesTitleBar: React.FC<TaxRatesTitleBarProps> = (props) => {
  const {
    activeTaxRate,
    onSave,
    onClose,
    deleteModalVisible,
    onUndeleteClicked,
  } = props;

  const DeletedTaxRateActionsRestyle = useCallback(() => {
    return (
      <>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="tax-rate-undelete"
          onClick={() => onUndeleteClicked(false)}
          permissions={
            activeTaxRate.id && activeTaxRate.id > 0
              ? [PermissionType.TaxRatesEdit]
              : [PermissionType.TaxRatesCreate]
          }
        >
          Undelete
        </FBOButton>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="tax-rate-undelete-and-close"
          onClick={() => onUndeleteClicked(true)}
          permissions={
            activeTaxRate.id && activeTaxRate.id > 0
              ? [PermissionType.TaxRatesEdit]
              : [PermissionType.TaxRatesCreate]
          }
        >
          Undelete And Close
        </FBOButton>
      </>
    );
  }, [onUndeleteClicked, activeTaxRate.id]);

  const TaxRateActionsRestyle = useCallback(() => {
    return (
      <>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          type="submit"
          data-qa="tax-rate-save-button"
          permissions={
            activeTaxRate.id && activeTaxRate.id > 0
              ? [PermissionType.TaxRatesEdit]
              : [PermissionType.TaxRatesCreate]
          }
        >
          Save
        </FBOButton>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="tax-rate-save-and-close"
          onClick={() => onSave(true)}
          permissions={
            activeTaxRate.id && activeTaxRate.id > 0
              ? [PermissionType.TaxRatesEdit]
              : [PermissionType.TaxRatesCreate]
          }
        >
          Save And Close
        </FBOButton>
        {activeTaxRate.id && activeTaxRate.id > 0 && (
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="secondary"
            color="negative"
            size="medium"
            icon="TrashCan"
            data-qa="tax-rate-delete"
            onClick={() => deleteModalVisible(true)}
            permissions={[PermissionType.TaxRatesDelete]}
          >
            Delete
          </FBOButton>
        )}
      </>
    );
  }, [activeTaxRate.id, deleteModalVisible, onSave]);

  return (
    <FBOTitleBar title={`${activeTaxRate.name || 'New Tax Rate'}`}>
      {activeTaxRate.deleted ? (
        <DeletedTaxRateActionsRestyle />
      ) : (
        <TaxRateActionsRestyle />
      )}
      <FBOButton
        variant="tertiary"
        color="neutral"
        size="medium"
        icon="FBOClose"
        data-qa="tax-rate-close"
        onClick={onClose}
      />
    </FBOTitleBar>
  );
};

export default memo(TaxRatesTitleBar);
