import {
  createAddSearch,
  createDeleteSearch,
  createFetchSearch,
  createUpdateSearch,
} from 'services/search';

export enum ItemsActionTypes {
  FETCH_SALE_ITEMS_START = 'items/fetch_sale_items_start',
  FETCH_SALE_ITEMS_SUCCESS = 'items/fetch_sale_items_success',
  FETCH_SALE_ITEMS_FAILURE = 'items/fetch_sale_items_failure',
}

export const fetchSearch = createFetchSearch('item');
export const addSearch = createAddSearch('item');
export const updateSearch = createUpdateSearch('item');
export const deleteSearch = createDeleteSearch('item');
