import React from 'react';
import { Box, TableCell, Tooltip } from '@mui/material';
import { Icon } from 'ui/components/Icon';
import { IconNames } from 'ui/theme/icons';
import { colorStatusPaletteMapRestyled } from 'ui/theme/colors/statusColors';
import { PickItemStatus, PickStatus } from 'services/picking';
import { ChannelStatus } from 'services/commerce/channels';
import { SerialListStatus } from 'services/inventory';
import { LocationType } from 'services/locations';
import {
  PurchaseOrderStatus,
  PurchaseOrderItemStatus,
  PurchaseOrderItemOrderType,
} from 'services/purchaseOrders';
import { ReceiptStatus, ReceiptItemStatus } from 'services/receiving';
import {
  SalesOrderStatus,
  SalesOrderItemStatus,
  SalesOrderTableRowOrderType,
  PaymentStatus,
} from 'services/salesOrders';
import { ShipStatus } from 'services/shipping';

export const enum DefaultStatus {
  Transparent = 'transparent',
}
interface FBOTagProps {
  status:
    | PickStatus
    | PickItemStatus
    | ShipStatus
    | SalesOrderStatus
    | SalesOrderItemStatus
    | ReceiptStatus
    | ReceiptItemStatus
    | PurchaseOrderStatus
    | PurchaseOrderItemStatus
    | SalesOrderTableRowOrderType
    | PurchaseOrderItemOrderType
    | PaymentStatus
    | ChannelStatus
    | SerialListStatus
    | LocationType
    | DefaultStatus;
}

const FBOStatusDot = ({ status }: FBOTagProps) => {
  const color = colorStatusPaletteMapRestyled[status];

  return (
    <Tooltip
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -30],
            },
          },
        ],
      }}
      title={status}
      enterDelay={1000}
      enterNextDelay={500}
      placement="top"
    >
      <TableCell>
        <Box className="redesign statusDot">
          <Icon name={IconNames.Circle} fill={color.dot} />
        </Box>
      </TableCell>
    </Tooltip>
  );
};

export default FBOStatusDot;
