import React, { ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getActiveUser } from 'services/user';

interface AnalyticsProviderProps {
  children: ReactNode;
}

declare global {
  interface Window {
    ChurnZero: any;
  }
}

export const AnalyticsContext = React.createContext(null);

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({
  children,
}) => {
  const user = useSelector(getActiveUser);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://analytics.churnzero.net/churnzero.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (user.tenant && window.ChurnZero) {
      window.ChurnZero.push(['setAppKey', process.env.REACT_APP_CHURN_ZERO]);

      window.ChurnZero.push([
        'setContact',
        user.externalAccountId || user.tenant,
        user.externalContactId || user.userGlobalId,
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, window.ChurnZero]);

  return (
    <AnalyticsContext.Provider value={null}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const ChurnZero = window.ChurnZero;
