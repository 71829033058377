import { useCallback } from 'react';
import { showNotification } from 'services/api';

export const useHandleTextFieldChange = <T>(
  setFormValues: (newFormValues: T) => void,
  formValues: T,
  doNotRound?: boolean
) =>
  useCallback(
    (e: any) => {
      const newValue = e.target.value;
      const fieldName = e.target.name;
      const isNumber = e.target.type === 'number';

      if (isNumber && decimalPointHandler(newValue)) {
        showNotification('A maximum of six decimal points are allowed', {
          variant: 'error',
        });
        const data = newValue.toString().split('.');

        const returnValue = parseFloat(data[0] + '.' + data[1].slice(0, 6));

        setFormValues({
          ...formValues,
          [fieldName]: returnValue.toString(),
        });
      } else {
        if (isNumber) {
          const resolvedNumberValue =
            newValue === ''
              ? null
              : doNotRound
              ? newValue
              : parseInt(newValue, 10);
          setFormValues({
            ...formValues,
            [fieldName]: resolvedNumberValue,
          });
          return;
        }

        const resolvedStringValue = newValue === '' ? '' : newValue;
        setFormValues({ ...formValues, [fieldName]: resolvedStringValue });
      }
    },
    [setFormValues, formValues, doNotRound]
  );

export const stringToDecimal = (value: string, digits: number = 6): string => {
  if (value === '.') return value;
  if (isNaN(Number(value))) return '';

  const [whole, fraction] = value.split('.');

  if (whole && fraction) {
    notifyOfMaxDecimalPlaces(fraction, digits);
    return Number(whole + '.' + fraction.slice(0, digits)).toString();
  } else if (fraction && !whole) {
    notifyOfMaxDecimalPlaces(fraction, digits);
    return Number('.' + fraction.slice(0, digits)).toString(); // adds whole number
  }

  return value;
};

const notifyOfMaxDecimalPlaces = (fraction: string, digits: number) => {
  if (fraction.length > digits)
    showNotification('A maximum of six decimal points are allowed', {
      variant: 'error',
    });
};

export const useHandleCheckboxChange = <T>(
  setFormValues: React.Dispatch<React.SetStateAction<T>>
) =>
  useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      // This synthetic event is reused for performance reasons.
      // If you must keep the original synthetic event around, use event.persist().
      event.persist();
      setFormValues((old) => ({ ...old, [event.target.name]: checked }));
    },
    [setFormValues]
  );

export const decimalPointHandler = (quantity: string) => {
  if (quantity === null) return false;

  if (isNaN(parseFloat(quantity))) return false;

  if (quantity?.toString().split('.').length == 1) return false;

  const DecimalPoint = quantity?.toString().split('.')[1].length;

  if (DecimalPoint == -1) return false;
  if (DecimalPoint > 6) return true;

  return false;
};

export const useEnterKeyPressHandler = () => {
  return useCallback((event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      event.currentTarget.click();
    }
  }, []);
};
