import {
  CreateAccountFormValues,
  ForgotPasswordFormValues,
  LoginFormValues,
} from './types';

export const initialForgotPasswordForm: ForgotPasswordFormValues = {
  email: '',
};

export const initialLoginForm: LoginFormValues = {
  email: '',
  password: '',
  keepLoggedIn: false,
};

export const initialCreateAccountForm: CreateAccountFormValues = {
  companyName: '',
  companyPhone: '',
  country: null,
  currencyCountry: null,
  eulaAccepted: false,
  firstName: '',
  lastName: '',
  timezone: null,
  password: '',
  email: '',
};
