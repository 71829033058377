import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { usePickingModalStyle } from './styled';
import { OverPickingNotifyModalProps } from './types';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { IconNames } from 'ui/theme';

export const OverPickingNotifyModal = (props: OverPickingNotifyModalProps) => {
  const { open, onCancel, onContinue } = props;

  const classes = usePickingModalStyle();

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      data-test-id="over-picking-notify-modal"
    >
      <DialogTitle className={classes.title}>Over-Picking</DialogTitle>
      <FBOButton
        variant="tertiary"
        color="neutral"
        size="small"
        icon={IconNames.IconClose}
        aria-label="close"
        onClick={onCancel}
        data-qa="over-picking-notify-modal-close-icon"
        style={{
          position: 'absolute',
          top: '8px',
          right: '8px',
        }}
      />

      <DialogContent>
        <DialogContentText className={classes.content}>
          The quantity entered is larger than ordered
        </DialogContentText>
      </DialogContent>
      <DialogActions classes={{ spacing: classes.action }}>
        <FBOButton
          variant="secondary"
          color="positive"
          size="medium"
          autoFocus
          onClick={onCancel}
          data-qa="over-picking-notify-modal-cancel-btn"
        >
          Cancel
        </FBOButton>
        <FBOButton
          variant="primary"
          color="positive"
          size="medium"
          autoFocus
          onClick={onContinue}
          data-qa="over-picking-notify-modal-continue-anyway-btn"
        >
          Continue Anyway
        </FBOButton>
      </DialogActions>
    </Dialog>
  );
};
