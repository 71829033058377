import { createFilterOptions, FilterOptionsState } from '@mui/material';
import { fetchSalesOrdersAPI, SalesOrder } from 'services/salesOrders';

import { Pagination } from 'services/search';

const filter = createFilterOptions<SalesOrder>();

export const salesOrderAutocompleteFilterOptions =
  () => (options: SalesOrder[], params: FilterOptionsState<SalesOrder>) => {
    const filtered = filter(options, params);

    return filtered;
  };

export const resolvedFetchSalesOrderFunction = (
  expands?: string[],
  customQuickSearchColumns?: string[]
) => {
  return (pagination: Pagination, searchValue: string | null) =>
    fetchSalesOrdersAPI({
      pagination,
      expands: expands,
      quickSearchValue: searchValue,
      customQuickSearchColumns,
    });
};
