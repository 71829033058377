import React, { useState, useCallback } from 'react';
import { Navigate } from 'react-router-dom';

import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { Routes } from 'ui/modules/home';
import { Pagination } from 'services/search';
import { GlobalUser, initialGlobalUser } from 'services/user';

import RemoteSupportPageTableRow from './RemoteSupportPageTableRow';
import { RemoteSupportPageTableProps } from './types';
import { remoteSupportTableColumns } from './consts';
import {
  RemoteSupportUserEditModal,
  RemoteSupportLogInModal,
} from './components';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';

const RemoteSupportPageTable: React.FC<RemoteSupportPageTableProps> = ({
  users,
  isLoadingUsers,
  pagination,
  onPaginationChange,
  fetchSearchResult,
}) => {
  const [showLogInModal, setShowLogInModal] = useState<boolean>(false);
  const [showUserEditModal, setShowUserEditModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] =
    useState<GlobalUser>(initialGlobalUser);
  const [user, setUser] = useState(initialGlobalUser);
  const [redirect, setRedirect] = React.useState(false);

  const handlePaginationChange = (newPagination: Pagination) => {
    onPaginationChange(newPagination);
  };

  const onLogInClicked = useCallback((user: GlobalUser) => {
    setUser(user);
    setShowLogInModal(true);
  }, []);

  const handleItemClick = useCallback(
    (userId: number) => {
      const selectedUser = users.find((user) => user.id === userId);

      if (selectedUser) {
        setSelectedUser(selectedUser);
        setShowUserEditModal(true);
      }
    },
    [users]
  );

  const handleCloseUserEditModal = useCallback(() => {
    setShowUserEditModal(false);
    setSelectedUser(initialGlobalUser);
  }, []);

  const handleCloseLogInModal = useCallback(() => {
    setShowLogInModal(false);
  }, []);

  if (redirect) {
    return <Navigate to={Routes.DashboardPage} replace />;
  }
  return (
    <>
      <FBOTitleBar title="Users" />
      <ItemsTable
        data={users}
        columns={remoteSupportTableColumns}
        isLoadingData={isLoadingUsers}
        selectableItems={false}
        pagination={pagination}
        onAction={onLogInClicked}
        RenderCustomRow={RemoteSupportPageTableRow}
        onPaginationChange={handlePaginationChange}
        onItemClick={handleItemClick}
        showEnhancedTablePaginationActions
      />
      <RemoteSupportLogInModal
        showModal={showLogInModal}
        user={user}
        setRedirect={setRedirect}
        onClose={handleCloseLogInModal}
      />
      <RemoteSupportUserEditModal
        fetchSearchResult={fetchSearchResult}
        onClose={handleCloseUserEditModal}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        showModal={showUserEditModal}
      />
    </>
  );
};

export default RemoteSupportPageTable;
