import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { colorPalette } from 'ui/theme';

export const useSalesOrderItemsStyle = makeStyles((theme: Theme) => ({
  amountInputOuter: {
    paddingRight: 5,
  },
  searchInputInner: {
    height: 32,
    background: 'white',
    paddingTop: `0!important`,
    paddingBottom: `0!important`,
  },
  searchInputOuter: {
    flex: 1,
    paddingRight: theme.spacing(1),
  },
  searchOption: {
    borderBottom: '1px solid #e0e0e0',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '&:last-of-type': {
      borderBottom: 'none',
    },
  },
  noBorder: {
    border: 'none',
  },
  autocomplete: {
    '& .Mui-disabled': {
      backgroundColor: colorPalette.applicationDarkGray10,
    },
  },
}));
