import React, { memo, useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Divider, Box, Typography } from '@mui/material';

import { Paper } from 'ui/components/Paper/Paper';
import { getTrackingTypes } from 'services/settings/tracking';
import { PermissionType } from 'services/permissions';
import { activeUserHasPermission } from 'services/user/redux';

import { TrackingCardProps } from './types';
import { useTrackingCardStyle } from './styled';
import { TrackingModal } from './components/TrackingModal';
import { searchTitle } from '../../helpers';
import { trackingCardId } from './consts';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const TrackingCard: React.FC<TrackingCardProps> = (props) => {
  const { search, isLoadingDependencies } = props;
  const classes = useTrackingCardStyle();
  const { items: trackingTypes } = useSelector(getTrackingTypes);
  const hasViewPermission = useSelector(
    activeUserHasPermission([PermissionType.SettingsTrackingView])
  );

  const [isModalVisible, setIsModalVisible] = useState(false);
  const title = 'Tracking';

  const lastIndex = useMemo(() => trackingTypes.length - 1, [trackingTypes]);

  const hasTitle = useMemo(() => {
    return new RegExp(_.escapeRegExp(search), 'i').test(title);
  }, [search]);

  const highlightedTitle = useMemo(() => {
    if (hasTitle) {
      return searchTitle(title, RegExp(_.escapeRegExp(search), 'gi'));
    }
    return title;
  }, [hasTitle, search]);

  const dangerouslySetInnerHTML = useMemo(() => {
    return highlightedTitle.includes('<mark>');
  }, [highlightedTitle]);

  const handleEditClicked = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  return (
    <>
      {hasViewPermission && hasTitle && (
        <Box className={classes.container} id={trackingCardId}>
          <Paper
            className={`${classes.paper} static-paper redesign`}
            sx={{ boxShadow: 'none' }}
          >
            <FBOTitleBar
              dangerouslySetInnerHTML={dangerouslySetInnerHTML}
              title={highlightedTitle}
              sx={{ borderBottom: '1px solid #e0e0e0' }}
            >
              <FBOButton
                color="positive"
                variant="primary"
                onClick={handleEditClicked}
                permissions={[PermissionType.SettingsTrackingEdit]}
                data-qa="tracking-settings-edit"
              >
                Edit
              </FBOButton>
            </FBOTitleBar>
            {trackingTypes.map((type, i) => (
              <React.Fragment key={`fragment-${i}`}>
                <Box className={classes.rowContainer}>
                  <Box className={classes.titleContainer}>
                    <Typography>{type.name}</Typography>
                  </Box>
                  <Box>
                    <Typography noWrap>{type.trackingDataType}</Typography>
                  </Box>
                </Box>
                {i !== lastIndex && <Divider />}
              </React.Fragment>
            ))}
          </Paper>

          <TrackingModal
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            isLoadingDependencies={isLoadingDependencies}
          />
        </Box>
      )}
    </>
  );
};

export default memo(TrackingCard);
