import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid, Typography } from '@mui/material';
import _ from 'lodash';

import { TextField } from 'ui/components/TextField/TextField';
import {
  TrackingTableTypes,
  transformToTrackingGroupsNewGroup,
} from 'ui/components/Table/TrackingTable';
import { TextFieldQuantity } from 'ui/components/TextField/TextFieldQuantity';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import {
  getItemInventory,
  InventoryRow,
  itemHasSerialTracking,
  itemHasTracking,
  ItemInventory,
  ItemTrackingType,
  SerialRow,
  TrackingGroup,
} from 'services/inventory';
import { Location } from 'services/locations';
import { TrackingDataTypes } from 'services/settings/tracking';
import { ItemType } from 'services/items';
import { EACH_UOM_ID, getUoms } from 'services/uoms';

import { ReceiveWizardItemProps } from './types';
import { useReceiveModalStyle } from './styled';
import { ReceiptItemReceive } from 'services/receiving';
import TrackingTable from 'ui/components/Table/TrackingTable/TrackingTable';
import SerialTable from 'ui/components/Table/SerialTable/SerialTable';
import { colorPalette } from 'ui/theme';

export const locationInventoryHandler = (
  locationInventory: InventoryRow | null,
  receive: ReceiptItemReceive
) => {
  return [
    ...(locationInventory?.trackingGroupList || []),
    ...receive?.trackingGroups.filter(
      (tg) => tg?.trackingInfoList[0]?.id === -1
    ),
  ].map((g) => ({ ...g, serialList: [] }));
};

const ReceiveWizardItem: React.FC<ReceiveWizardItemProps> = (props) => {
  const { receipt, receive, serialErrors, setReceive, setLoadingContent } =
    props;

  const { items: uoms } = useSelector(getUoms);

  const classes = useReceiveModalStyle();

  const [inventory, setInventory] = useState<ItemInventory | null>(null);

  const eachUomConversion = useMemo(() => {
    if (!receive.receiptItem.uom || !receive.receiptItem.uom.id) {
      return null;
    }

    const receiptItemUomId = receive.receiptItem.uom.id;
    const eachConversion =
      receive.receiptItem.uom.fromConversions.find(
        (c) => c.fromUomId === EACH_UOM_ID && c.toUomId === receiptItemUomId
      ) ||
      receive.receiptItem.uom.toConversions.find(
        (c) => c.fromUomId === receiptItemUomId || c.toUomId === EACH_UOM_ID
      );

    if (!eachConversion) {
      return null;
    }

    if (!receive.receiptItem.item) {
      return eachConversion;
    }
    // If there is an Item Conversion we need to use its Factor
    const itemConversion = receive.receiptItem.item.itemUomConversionList.find(
      (c) => c.uomConversionId === eachConversion.id
    );

    return {
      ...eachConversion,
      factor: itemConversion ? itemConversion.factor : eachConversion.factor,
    };
  }, [receive.receiptItem]);

  const serialQuantity = useMemo(() => {
    if (eachUomConversion && eachUomConversion.factor && receive.quantity) {
      return eachUomConversion.factor * receive.quantity;
    }
    return receive.quantity || 0;
  }, [eachUomConversion, receive.quantity]);

  const locationDisabled = useMemo(() => {
    const itemType = _.get(receive.receiptItem.item, 'itemType');
    return (
      itemType === ItemType.Service ||
      itemType === ItemType.Shipping ||
      itemType === ItemType.Labor ||
      itemType === ItemType.Overhead
    );
  }, [receive.receiptItem.item]);

  const hasTracking = useMemo(
    () => receive.receiptItem.item && itemHasTracking(receive.receiptItem.item),
    [receive.receiptItem]
  );

  const hasOnlySerial = useMemo(() => {
    if (!hasTracking) {
      return false;
    }

    return _.every(
      _.get(receive.receiptItem, 'item.itemTrackingTypeList', []),
      (itemTrackingType: ItemTrackingType) =>
        _.get(itemTrackingType, 'trackingType.trackingDataType', null) ===
        TrackingDataTypes.SerialNumber
    );
  }, [receive.receiptItem, hasTracking]);

  const hasSerialTracking = useMemo(
    () =>
      !!receive.receiptItem.item &&
      itemHasSerialTracking(receive.receiptItem.item),
    [receive.receiptItem]
  );

  const selectedReceiptItem = useMemo(() => {
    return receipt.receiptItems.find((p) => p.id === receive.id) || null;
  }, [receipt.receiptItems, receive.id]);

  const resolvedQuantity = `${_.get(
    selectedReceiptItem,
    'quantity',
    '0'
  )} ${_.get(selectedReceiptItem, 'uom.abbreviation', 'ea')}`;

  const itemLocation = useMemo(() => {
    if (!receive.receiptItem.item) {
      return;
    }
    return receive.receiptItem.item.locationList.find(
      (i) => i.parentLocationId === receipt.locationId
    );
  }, [receive.receiptItem, receipt.locationId]);

  const selectedReceiptItemType = _.get(selectedReceiptItem, 'item.itemType');

  // use effect for fetching item inventory
  useEffect(() => {
    const asyncFc = async (id: number) => {
      setLoadingContent(true);
      const itemInventory = await getItemInventory(id);
      setInventory(itemInventory);
      if (_.isEmpty(receive.trackingGroups) && receive.locationId) {
        const itemTracking = _.get(
          receive,
          'receiptItem.item.itemTrackingTypeList',
          []
        );
        const newTrackingGroup = itemTracking.length
          ? transformToTrackingGroupsNewGroup(
              receive.trackingGroups,
              itemTracking,
              0
            )
          : [];

        const trackingGroups = [
          ...getTrackingGroupsByLocation(itemInventory, receive.locationId!),
          ...newTrackingGroup,
        ];

        setReceive((old) => ({
          ...old,
          trackingGroups: trackingGroups,
          quantity: receive.receiptItem.quantity || 0,
          locationId: itemLocation
            ? itemLocation.receiveLocationId
            : receive.locationId,
        }));
      }

      setLoadingContent(false);
    };

    const itemId = _.get(receive, 'receiptItem.itemId', 0);
    if (receive.receiptItemId > 0 && itemId > 0) {
      asyncFc(itemId);
    } else {
      setInventory(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receive.receiptItem]);

  const getTrackingGroupsByLocation = useCallback(
    (itemInventory: ItemInventory, locationId: number) => {
      const locationInventory =
        itemInventory.inventoryRowList.find(
          (r) => r.locationId === locationId
        ) || null;

      if (hasOnlySerial) {
        const serialRows = [
          ...(Array(receive.quantity || 0).keys() as any),
        ].map((index: number) => ({
          id: -1 - index,
          committed: false,
          serialNumbers: _.get(
            receive,
            'receiptItem.item.itemTrackingTypeList',
            []
          ).reduce(
            (o: any, type: ItemTrackingType) => ({
              ...o,
              [type.trackingTypeId]: null,
            }),
            {}
          ),
        }));

        return [
          {
            committedQuantity: 0,
            onHand: 0,
            quantity: receive.quantity,
            trackingInfoList: [],
            serialList: serialRows,
            serialIds: [],
          },
        ];
      }

      return locationInventoryHandler(locationInventory, receive);
    },
    [receive, hasOnlySerial, setReceive]
  );

  const handleLocationChange = useCallback(
    (location: Location | null) => {
      setReceive((old) => ({
        ...old,
        locationId: location ? location.id : null,
        trackingGroups:
          inventory && location
            ? getTrackingGroupsByLocation(inventory, location.id!)
            : [],
      }));
    },

    [setReceive, inventory, getTrackingGroupsByLocation, receive]
  );

  const handleSetTrackingGroups = useCallback(
    (trackingGroups: TrackingGroup[]) => {
      const quantity = trackingGroups.reduce(
        (acc, g) => acc + (g.quantity || 0),
        0
      );

      if (eachUomConversion && eachUomConversion.factor) {
        const resolvedTrackingGroups: TrackingGroup[] = trackingGroups.map(
          (tg) => ({
            ...tg,
            serialQuantity: (tg.quantity || 0) * eachUomConversion!.factor!,
          })
        );

        setReceive((old) => ({
          ...old,
          quantity,
          trackingGroups: resolvedTrackingGroups,
        }));
        return;
      }
      setReceive((old) => ({
        ...old,
        quantity,
        trackingGroups,
      }));
    },
    [setReceive, eachUomConversion]
  );

  const handleAddTrackingGroup = useCallback(() => {
    setReceive((old) => ({
      ...old,
      trackingGroups: transformToTrackingGroupsNewGroup(
        old.trackingGroups,
        _.get(receive, 'receiptItem.item.itemTrackingTypeList', []),
        0
      ),
    }));
  }, [setReceive, receive]);

  const handleQuantityChange = useCallback(
    (value: number | null) => {
      setReceive((old) => ({
        ...old,
        quantity: value,
      }));
    },
    [setReceive]
  );

  useEffect(() => {
    if (hasOnlySerial) {
      const locationId: number | null = _.get(receive, 'locationId', null);
      if (inventory && locationId) {
        setReceive((old) => ({
          ...old,
          trackingGroups: getTrackingGroupsByLocation(inventory, locationId),
        }));
      }
    }
  }, [receive.quantity]);

  const setSerialList = useCallback(
    (serialList: React.SetStateAction<SerialRow[]>) => {
      if (typeof serialList === 'function') {
        setReceive((old) => ({
          ...old,
          trackingGroups: [
            {
              ...old.trackingGroups[0],
              quantity: old.quantity,
              serialList: serialList(old.trackingGroups[0].serialList),
              serialQuantity: serialQuantity,
            },
          ],
        }));
        return;
      }
      setReceive((old) => ({
        ...old,
        trackingGroups: [
          {
            ...old.trackingGroups[0],
            quantity: old.quantity,
            serialList,
            serialQuantity: serialQuantity,
          },
        ],
      }));
    },
    [serialQuantity, setReceive]
  );

  const getUomName = (uomId: number) => {
    const uomOption = uoms.find((option) => option.id === uomId);
    if (uomOption) {
      return uomOption.name;
    }
    return '';
  };

  return (
    <>
      <Box p={3} overflow="hidden" flexShrink={0}>
        <Grid container spacing={2}>
          <Grid item xs={2} classes={{ root: classes.gridWithoutRightPadding }}>
            {/* TODO: update with big number style */}
            <TextField
              className="redesign"
              variant="standard"
              readOnly
              additionalInputProps={{
                classes: { input: classes.bigNumber },
              }}
              value={resolvedQuantity}
              label="Receive Quantity"
            />
          </Grid>
          {selectedReceiptItemType === ItemType.Inventory && (
            <Grid item xs={6}>
              <LocationsAsyncAutocomplete
                label="Location"
                placeholder="Select Location"
                value={receive.locationId!}
                onChange={handleLocationChange}
                companyWide={false}
                required
                disabled={locationDisabled}
                getLocationLabel={(o) => o.path || ''}
                dataQa="receiving-item-location"
              />
            </Grid>
          )}
          <Grid item xs={4}>
            {(!hasTracking || hasOnlySerial) && (
              <>
                <TextFieldQuantity
                  fullWidth
                  label="Quantity"
                  name="quantity"
                  placeholder="0"
                  value={receive.quantity}
                  selectedUomId={receive.receiptItem.uomId || EACH_UOM_ID}
                  uoms={uoms}
                  onTextChange={handleQuantityChange}
                  required
                  dataQa="receive-wizard-quantity"
                  isDecimal={!hasSerialTracking}
                />
                {eachUomConversion && (
                  <Typography>
                    {`${eachUomConversion.factor} `}
                    <i>
                      <b>{getUomName(eachUomConversion.toUomId!)} </b>
                      {'per '}
                      <b>{getUomName(eachUomConversion.fromUomId!)}</b>
                    </i>
                  </Typography>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box
        display="flex"
        flexGrow={1}
        flexDirection="column"
        overflow="hidden"
        height="50vh"
      >
        {hasTracking && (
          <>
            {!hasOnlySerial ? (
              <TrackingTable
                firstColumnTitle="Quantity To Receive"
                itemTrackingTypes={_.get(
                  receive,
                  'receiptItem.item.itemTrackingTypeList',
                  []
                )}
                trackingGroups={receive.trackingGroups}
                onSetTrackingGroups={handleSetTrackingGroups}
                tableType={TrackingTableTypes.Add}
                onAddNewTracking={handleAddTrackingGroup}
                inventoryUom={
                  receive.receiptItem.item
                    ? receive.receiptItem.item.defaultUom
                    : null
                }
                itemUom={receive.receiptItem.uom || null}
                allowSerialNumberImport
                isDecimal={!hasSerialTracking}
                emptyTableText="ADD NEW TRACKING ROW"
                onEmptyTableTextRowClick={handleAddTrackingGroup}
                sx={{
                  borderRadius: '5px',
                  border: `1px solid ${colorPalette.redesign.background3}`,
                  borderTop: 'none',
                }}
              />
            ) : (
              <SerialTable
                serialList={_.get(receive, 'trackingGroups[0].serialList', [])}
                setSerialList={setSerialList}
                itemTrackingTypes={_.get(
                  receive,
                  'receiptItem.item.itemTrackingTypeList',
                  []
                )}
                title="Tracking"
                canClearRow
                canEditRow
                minSerialQuantity={serialQuantity}
                rowErrors={serialErrors}
                emptyTableText={
                  receive.locationId
                    ? 'Please fill in "Quantity" to begin'
                    : 'Please fill in "Location" and "Quantity" to begin'
                }
                allowSerialNumberImport
                setIsLoading={setLoadingContent}
                sx={{
                  borderRadius: '5px',
                  border: `1px solid ${colorPalette.redesign.background3}`,
                  borderTop: 'none',
                }}
              />
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default memo(ReceiveWizardItem);
