import { Address } from 'services/addresses';
import { PermissionType } from 'services/permissions';
import { Vendor } from 'services/vendors';

export const editVendorPermissions = (vendor: Vendor): PermissionType[] => {
  if (vendor.deleted) {
    return [PermissionType.Undelete];
  }
  return vendor.id && vendor.id > 0
    ? [PermissionType.VendorsEdit]
    : [PermissionType.VendorsCreate];
};

export const editVendorAddressPermissions = (
  address: Address
): PermissionType[] => {
  if (address.deleted) {
    return [PermissionType.Undelete];
  }
  return address.id && address.id > 0
    ? [PermissionType.VendorsEdit]
    : [PermissionType.VendorsCreate];
};
