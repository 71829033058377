export interface ShippingDetailsCardProps {
  activeShipId: null | number;
  onClose(): void;
  fetchSearchResult(): Promise<void>;
}

export enum ShipItemAction {
  DeleteCarton,
  RefundCarton,
  OpenShippingLabel,
}

export enum ShippingTab {
  General,
  ReturnLabels,
  Documents,
}
