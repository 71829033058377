import React, { memo, useCallback } from 'react';

import { PermissionType } from 'services/permissions';

import { CustomerTitleBarProps } from './types';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const CustomerTitleBar: React.FC<CustomerTitleBarProps> = (props) => {
  const {
    activeCustomer,
    onSave,
    onClose,
    deleteModalVisible,
    onUndeleteClicked,
  } = props;

  const DeletedCustomerActions = useCallback(() => {
    return (
      <>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="customer-save"
          onClick={() => onUndeleteClicked(false)}
          permissions={
            activeCustomer.id && activeCustomer.id > 0
              ? [PermissionType.CustomersEdit]
              : [PermissionType.CustomersCreate]
          }
        >
          Undelete
        </FBOButton>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="customer-save-and-close"
          onClick={() => onUndeleteClicked(true)}
          permissions={
            activeCustomer.id && activeCustomer.id > 0
              ? [PermissionType.CustomersEdit]
              : [PermissionType.CustomersCreate]
          }
        >
          Undelete And Close
        </FBOButton>
      </>
    );
  }, [onUndeleteClicked, activeCustomer.id]);

  const CustomerActions = useCallback(() => {
    return (
      <>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="customer-save"
          onClick={() => onSave(false)}
          permissions={
            activeCustomer.id && activeCustomer.id > 0
              ? [PermissionType.CustomersEdit]
              : [PermissionType.CustomersCreate]
          }
        >
          Save
        </FBOButton>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="customer-save-and-close"
          onClick={async () => {
            await onSave(true);
            onClose();
          }}
          permissions={
            activeCustomer.id && activeCustomer.id > 0
              ? [PermissionType.CustomersEdit]
              : [PermissionType.CustomersCreate]
          }
        >
          Save And Close
        </FBOButton>
        {activeCustomer.id !== -1 && (
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="secondary"
            color="negative"
            size="medium"
            icon="TrashCan"
            data-qa="customer-delete"
            onClick={() => deleteModalVisible(true)}
            permissions={[PermissionType.CustomersDelete]}
          >
            Delete
          </FBOButton>
        )}
      </>
    );
  }, [activeCustomer.id, onSave, deleteModalVisible]);

  return (
    <FBOTitleBar title={activeCustomer && activeCustomer.name}>
      {activeCustomer.deleted ? (
        <DeletedCustomerActions />
      ) : (
        <CustomerActions />
      )}
      <FBOButton
        variant="tertiary"
        color="neutral"
        size="medium"
        icon="FBOClose"
        data-qa="customer-close"
        onClick={onClose}
      />
    </FBOTitleBar>
  );
};

export default memo(CustomerTitleBar);
