import React, { memo, useCallback, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import { TextFieldQuantity } from 'ui/components/TextField/TextFieldQuantity';
import { useHandleTextFieldChange } from 'services/forms';
import { EACH_UOM_ID, getUoms } from 'services/uoms';
import { Errors, validateYup } from 'services/forms/validation';
import { ItemType, ItemUomConversion } from 'services/items';
import {
  calculatePriceBasedOnUomChange,
  calculateTotals,
  convertToNegative,
} from 'services/salesOrders';
import { itemHasSerialTracking } from 'services/inventory';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';
import { getSettingsCompany } from 'services/settings/company';
import { MultiCurrencyWrapper } from 'ui/components/MultiCurrencyWrapper/MultiCurrencyWrapper';
import { roundToDecimals, toMulticurrencyCalculate } from 'helpers';

import { SalesOrderItemEditModalProps } from './types';
import { yupSalesOrderItemEditModalSchema } from './validations';
import {
  calculateHomeCurrencyPrice,
  calculateMulticurrencyPrice,
} from '../../helpers';
import {
  salesOrderItemStatusRegister,
  isSalesOrderItemFulfilled,
} from 'ui/modules/sales/pages/SalesOrderPage/stateRegister';

const SalesOrderItemEditModal: React.FC<SalesOrderItemEditModalProps> = (
  props
) => {
  const {
    show,
    onClose,
    onSave,
    salesOrderStatus,
    activeSaleOrderItem,
    salesOrder,
    setActiveSaleOrderItem,
  } = props;

  const { items: uoms } = useSelector(getUoms);
  const { homeCurrency, useMultiCurrency } = useSelector(getSettingsCompany);

  const homeCurrencyCode = (homeCurrency && homeCurrency.code) || 'USD';

  const [validationErrors, setValidationErrors] = useState<Errors>({});

  const isDisabled = useCallback(
    salesOrderItemStatusRegister(activeSaleOrderItem),
    [activeSaleOrderItem.status]
  );

  const isFulfilled = useCallback(
    isSalesOrderItemFulfilled(activeSaleOrderItem),
    [salesOrderStatus, activeSaleOrderItem]
  );

  const activeMulticurrencyCode = _.get(
    salesOrder,
    'customer.currency.code',
    null
  );

  const showMulticurrency = useMemo(
    () =>
      useMultiCurrency &&
      activeMulticurrencyCode &&
      activeMulticurrencyCode !== homeCurrencyCode,
    [useMultiCurrency, activeMulticurrencyCode]
  );

  const activeItemType = _.get(
    activeSaleOrderItem.saleItem,
    'item.itemType',
    null
  );

  const isSerialTracked = useMemo(() => {
    if (activeSaleOrderItem.saleItem && activeSaleOrderItem.saleItem.item) {
      return itemHasSerialTracking(activeSaleOrderItem.saleItem.item);
    }
    return false;
  }, [activeSaleOrderItem.saleItem]);

  const handleTextFieldChange = useHandleTextFieldChange(
    setActiveSaleOrderItem,
    activeSaleOrderItem
  );

  const handleCostChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setActiveSaleOrderItem((old) => ({
        ...old,
        cost: ev.target.value ? parseFloat(ev.target.value) : null,
      }));
    },
    [setActiveSaleOrderItem]
  );

  const handlePriceInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const convertedValue = e.target.value
        ? convertToNegative(
            e.target.value,
            activeSaleOrderItem.salesOrderItemType
          )
        : null;

      const multiPrice = calculateMulticurrencyPrice(
        convertedValue || 0,
        salesOrder.exchangeRate || 1
      );

      const totals = calculateTotals(
        convertedValue || 0,
        activeSaleOrderItem.quantity || 0,
        activeSaleOrderItem.discount,
        activeSaleOrderItem.taxRate || 0,
        false
      );

      const multiCurrencyTotal = toMulticurrencyCalculate(
        totals.subTotal,
        salesOrder.exchangeRate || 1
      );

      const multiCurrencyTaxTotal = toMulticurrencyCalculate(
        totals.taxTotal,
        salesOrder.exchangeRate || 1
      );

      const multiCurrencyDiscountTotal = toMulticurrencyCalculate(
        totals.discountTotal,
        salesOrder.exchangeRate || 1
      );

      setActiveSaleOrderItem({
        ...activeSaleOrderItem,
        price: convertedValue,
        multiCurrencyItemPrice: multiPrice,
        ...totals,
        multiCurrencyTotal,
        multiCurrencyTaxTotal,
        multiCurrencyDiscountTotal,
      });
    },
    [activeSaleOrderItem, setActiveSaleOrderItem, salesOrder.exchangeRate]
  );

  const handleMultiCurrencyPriceInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const convertedValue = e.target.value
        ? convertToNegative(
            e.target.value,
            activeSaleOrderItem.salesOrderItemType
          )
        : null;

      const price = calculateHomeCurrencyPrice(
        convertedValue || 0,
        salesOrder.exchangeRate || 1
      );

      const totals = calculateTotals(
        price || 0,
        activeSaleOrderItem.quantity || 0,
        activeSaleOrderItem.discount,
        activeSaleOrderItem.taxRate || 0,
        false
      );

      const multiCurrencyTotal = toMulticurrencyCalculate(
        totals.subTotal,
        salesOrder.exchangeRate || 1
      );

      const multiCurrencyTaxTotal = toMulticurrencyCalculate(
        totals.taxTotal,
        salesOrder.exchangeRate || 1
      );

      const multiCurrencyDiscountTotal = toMulticurrencyCalculate(
        totals.discountTotal,
        salesOrder.exchangeRate || 1
      );

      setActiveSaleOrderItem({
        ...activeSaleOrderItem,
        price,
        multiCurrencyItemPrice: convertedValue,
        ...totals,
        multiCurrencyTotal,
        multiCurrencyTaxTotal,
        multiCurrencyDiscountTotal,
      });
    },
    [salesOrder.exchangeRate, activeSaleOrderItem, setActiveSaleOrderItem]
  );

  const handleAmountInputChange = useCallback(
    (value: number | null) => {
      const totals = calculateTotals(
        activeSaleOrderItem.price || 0,
        value || 0,
        activeSaleOrderItem.discount,
        activeSaleOrderItem.taxRate || 0,
        false
      );

      setActiveSaleOrderItem({
        ...activeSaleOrderItem,
        quantity: value,
        ...totals,
      });
    },
    [setActiveSaleOrderItem, activeSaleOrderItem]
  );

  const handleAmountMenuChange = useCallback(
    (uomId: number) => {
      const newUom = uoms.find((u) => u.id === uomId)!;
      const oldUom = uoms.find((u) => u.id === activeSaleOrderItem.uomId)!;

      const itemUomConversions: ItemUomConversion[] = _.get(
        activeSaleOrderItem,
        'saleItem.item.itemUomConversionList',
        null
      );

      const newPrice = calculatePriceBasedOnUomChange(
        newUom,
        oldUom,
        activeSaleOrderItem.price || 0,
        itemUomConversions
      );

      const totals = calculateTotals(
        newPrice,
        activeSaleOrderItem.quantity || 0,
        activeSaleOrderItem.discount,
        activeSaleOrderItem.taxRate || 0,
        false
      );

      setActiveSaleOrderItem({
        ...activeSaleOrderItem,
        uomId,
        price: newPrice,
        ...totals,
      });
    },
    [uoms, activeSaleOrderItem, setActiveSaleOrderItem]
  );

  const handleApplyClicked = useCallback(() => {
    const isValid = validateYup(
      activeSaleOrderItem,
      yupSalesOrderItemEditModalSchema,
      setValidationErrors
    );
    if (isValid) {
      onSave();
    }
  }, [activeSaleOrderItem, onSave]);

  return (
    <Modal
      open={show}
      onCancelClicked={onClose}
      onApplyClicked={handleApplyClicked}
      applyLabel="Update"
      title={isFulfilled() ? 'Sales Order Item' : 'Edit Sales Order Item'}
      withoutDefaultPadding
    >
      <Box p={3} width="100%">
        <Grid container spacing={2}>
          <Grid xs={6}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Name"
              name="name"
              disabled={isDisabled('name')}
              value={activeSaleOrderItem.name}
            />
          </Grid>
          <Grid xs={6}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="SKU"
              name="sku"
              value={_.get(activeSaleOrderItem, 'saleItem.sku', '')}
              disabled={isDisabled('sku')}
            />
          </Grid>
          <Grid xs={3}>
            <CurrencyField
              label="Unit Price"
              name="price"
              dataQa="SOItemEditModal-unitPrice"
              value={roundToDecimals(activeSaleOrderItem.price || 0, 5)}
              onChange={handlePriceInputChange}
              error={!!validationErrors.price}
              disabled={isDisabled('price')}
              allowNegative
              decimalPlaces={5}
            />
          </Grid>
          <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
            <Grid xs={3}>
              <CurrencyField
                label={`${activeMulticurrencyCode} price`}
                name="multiCurrencyItemPrice"
                value={roundToDecimals(
                  activeSaleOrderItem.multiCurrencyItemPrice || 0,
                  5
                )}
                onChange={handleMultiCurrencyPriceInputChange}
                allowNegative
                currencyCode={activeMulticurrencyCode}
                disabled={isDisabled('price')}
                decimalPlaces={5}
              />
            </Grid>
          </MultiCurrencyWrapper>

          <Grid xs={3}>
            <TextFieldQuantity
              label="Quantity"
              name="quantity"
              dataQa="SOItemEditModal-quantity"
              value={activeSaleOrderItem.quantity}
              selectedUomId={activeSaleOrderItem.uomId || EACH_UOM_ID}
              onTextChange={handleAmountInputChange}
              onMenuChange={handleAmountMenuChange}
              isDecimal={!isSerialTracked}
              uoms={uoms}
              disabled={isDisabled('quantity')}
              isUomSelectDisabled={activeItemType === ItemType.Shipping}
              error={!!validationErrors.quantity}
            />
          </Grid>
          <Grid xs={showMulticurrency ? 2 : 5}>
            <CurrencyField
              label="Unit Cost"
              name="cost"
              placeholder="Enter cost"
              value={activeSaleOrderItem.cost || 0}
              onChange={handleCostChange}
              disabled={isDisabled('cost')}
              allowNegative
              decimalPlaces={5}
            />
          </Grid>
          <Grid xs={12}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Description"
              name="description"
              dataQa="SOItemEditModal-description"
              placeholder="Enter item description"
              onChange={handleTextFieldChange}
              disabled={isDisabled('description')}
              value={activeSaleOrderItem.description}
            />
          </Grid>
          <Grid xs={12}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Notes"
              name="notes"
              dataQa="SOItemEditModal-notes"
              placeholder="Enter notes"
              disabled={isDisabled('notes')}
              onChange={handleTextFieldChange}
              value={activeSaleOrderItem.notes}
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default memo(SalesOrderItemEditModal);
