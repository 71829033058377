import React, { memo, useCallback, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';

import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import { useHandleTextFieldChange } from 'services/forms';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { AccountType, createAccount } from 'services/ledgers';
import { Errors, validateYup } from 'services/forms/validation';

import { AccountModalProps } from './types';
import { initialCreateFormValues } from '../../consts';
import { CreateFormValues } from '../../types';
import { yupLedgerAccountSchema } from '../../validations';
import { logErrorCtx } from 'app/logging';

const AccountModal: React.FC<AccountModalProps> = (props) => {
  const { open, onClose, getLedgers } = props;

  const [createFormValues, setCreateFormValues] = useState(
    initialCreateFormValues
  );
  const [validationErrors, setValidationErrors] = useState<Errors>({});
  const [isLoading, setIsLoading] = useState(false);

  const handleTextFieldChange = useHandleTextFieldChange<CreateFormValues>(
    setCreateFormValues,
    createFormValues
  );

  const handleAutocompleteChange = useCallback(
    (ev: any, accountType: AccountType) => {
      setCreateFormValues((old) => ({ ...old, accountType }));
    },
    []
  );

  const createModalSave = useCallback(async () => {
    const isValid = validateYup(
      createFormValues,
      yupLedgerAccountSchema,
      setValidationErrors
    );

    if (!isValid) {
      return;
    }
    setIsLoading(true);

    try {
      await createAccount(createFormValues);
    } catch (e) {
      logErrorCtx('Error in createAccount', {
        error: e as Error,
        component: 'AccountModal',
      });
    }

    setCreateFormValues(initialCreateFormValues);
    onClose();
    getLedgers();
    setIsLoading(false);
  }, [createFormValues, getLedgers, onClose]);

  return (
    <Modal
      open={open}
      title="Create Account"
      onCancelClicked={onClose}
      onApplyClicked={createModalSave}
      cancelLabel="Close"
      applyLabel="Save"
      isLoading={isLoading}
      dataQa="accounting-modal"
    >
      <Grid container spacing={2}>
        <Grid xs={6}>
          <TextField
            className={'redesign'}
            variant={'standard'}
            type="text"
            label="Number"
            placeholder="Enter account number"
            name="number"
            autoComplete="off"
            fullWidth
            value={createFormValues.number}
            required
            onChange={handleTextFieldChange}
            error={!!validationErrors.number}
            dataQa="accounting-number"
          />
        </Grid>
        <Grid xs={6}>
          <TextField
            className={'redesign'}
            variant={'standard'}
            type="text"
            label="Name"
            placeholder="Enter account name"
            name="name"
            autoComplete="off"
            fullWidth
            value={createFormValues.name}
            required
            onChange={handleTextFieldChange}
            error={!!validationErrors.name}
            dataQa="accounting-name"
          />
        </Grid>
        <Grid xs={6}>
          <Autocomplete
            placeholder="Select type"
            onChange={handleAutocompleteChange}
            value={createFormValues.accountType}
            label="Account Type"
            required
            options={Object.values(AccountType)}
            error={!!validationErrors.accountType}
            dataQa="accounting-account-type"
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default memo(AccountModal);
