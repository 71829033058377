import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { colorPalette } from 'ui/theme';

export const useFlowPageStyle = makeStyles<Theme>(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    minHeight: '112px',
    minWidth: '200px',
    maxWidth: '201px',
    marginRight: '40px',
    marginBottom: '20px',
  },
  title: {
    color: colorPalette.fishbowlCorporateGray1,
    fontWeight: 600,
    fontSize: '20px',
  },
  description: {
    color: colorPalette.applicationDarkGray80,
    fontWeight: 400,
    fontSize: '11px',
  },
  icon: {
    fill: colorPalette.fishbowlCorporateBlue,
    fontSize: '26px',
  },
  accountWarning: {
    fontWeight: 300,
    fontSize: '16px',
    textAlign: 'center',
    backgroundColor: colorPalette.fishbowlCorporateBlue,
    color: colorPalette.white,
    padding: '5px 0px',
    margin: '-24px -18px',
  },
  // Redesign //
  redesignFlowPage: {
    display: 'flex',
    padding: '14px 0px 0px 14px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    flex: '1 0 0',
    alignSelf: 'stretch',
  },
  redesignFlowContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '16px',
    alignItems: 'center',
    gap: '16px',
    background: '#FFF',
    boxShadow:
      '0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
    borderRadius: '10px',
  },
  redesignFlowTitleCard: {
    display: 'flex',
    width: '200px',
    height: '150px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '16px',
    alignSelf: 'stretch',
  },
  redesignFlowCard: {
    display: 'flex',
    width: '200px',
    height: '150px',
    padding: '16px 16px 24px 0px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // gap: '8px',
    borderRadius: '3px',
    border: `1px solid ${colorPalette.redesign.background4}`,
    background: colorPalette.redesign.background1,
  },
  redesignFlowCardLink: {
    textDecoration: 'none',
  },
  redesignFlowCardTitle: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    color: colorPalette.redesign.lightPrimaryBlue,
    textDecoration: 'none',
  },
  redesignFlowCardDescription: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    color: colorPalette.redesign.contentSecondary,
    paddingLeft: '16px',
  },
  redesignFlowContainerTitle: {
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    color: colorPalette.redesign.contentPrimary,
  },
  redesignFlowTitleBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    color: colorPalette.redesign.contentPrimary,
  },
  redesignFlowContainerDescription: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    color: colorPalette.redesign.contentSecondary,
  },
  redesignFlowContainerIcon: {
    width: '24px',
    height: '24px',
  },
}));
