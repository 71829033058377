import React, { useCallback } from 'react';
import { TableCell } from '@mui/material';

import {
  isProd,
  useCurrencyFormatter,
  useGetIntlDateFormatString,
} from 'helpers';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import { Invoice } from 'services/invoices';
import moment from 'moment';
import {
  QBO_CREDIT_MEMO_URL,
  QBO_INVOICE_URL,
  QBO_SANDBOX_CREDIT_MEMO_URL,
  QBO_SANDBOX_INVOICE_URL,
} from '../../consts';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const InvoiceSearchResultsCustomRow: React.FC<RenderCustomRowProps<Invoice>> = (
  props
) => {
  const { row, meta } = props;
  const currencyFormatter = useCurrencyFormatter();
  const intlDateFormat = useGetIntlDateFormatString();

  const getQBOTransactionLink = () => {
    const { type, transactionId } = row;
    let url = null;

    if (!transactionId) {
      return null;
    }

    const isProdEnv = isProd();

    if (isProdEnv) {
      if (type === 'Invoice') {
        url = QBO_INVOICE_URL;
      } else if (type === 'Credit Memo') {
        url = QBO_CREDIT_MEMO_URL;
      }
    } else {
      if (type === 'Invoice') {
        url = QBO_SANDBOX_INVOICE_URL;
      } else if (type === 'Credit Memo') {
        url = QBO_SANDBOX_CREDIT_MEMO_URL;
      }
    }

    if (url) {
      return url + transactionId;
    } else {
      return null;
    }
  };

  const QBOTransactionLink = getQBOTransactionLink();

  const handleLinkClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
    },
    [row]
  );

  return (
    <>
      <TableCell data-qa={`Number-${row.number}`}>{row.number}</TableCell>
      {!meta.activeInvoiceId && (
        <TableCell data-qa={`Order-Type-${row.number}`}>{row.type}</TableCell>
      )}
      <TableCell data-qa={`Customer-${row.number}`}>
        {row?.customer?.name}
      </TableCell>
      {!meta.activeInvoiceId && (
        <TableCell data-qa={`Date-Shipped-${row.number}`}>
          {row.dateShipped
            ? moment(row.dateShipped).format(intlDateFormat)
            : ''}
        </TableCell>
      )}
      {!meta.activeInvoiceId && (
        <TableCell data-qa={`Date-Due-${row.number}`}>
          {row.dateDue ? moment(row.dateDue).format(intlDateFormat) : ''}
        </TableCell>
      )}
      {!meta.activeInvoiceId && (
        <TableCell data-qa={`Location-${row.number}`}>
          {row?.location?.name || ''}
        </TableCell>
      )}
      {!meta.activeInvoiceId && (
        <TableCell data-qa={`SalesRep-${row.number}`}>
          {row?.salesRepresentative?.name || ''}
        </TableCell>
      )}
      {!meta.activeInvoiceId && (
        <TableCell data-qa={`Acct-Ref-${row.number}`}>
          {QBOTransactionLink ? (
            <FBOButton
              variant="tertiary"
              color="positive"
              size="medium"
              onClick={handleLinkClick}
              data-qa={`qbo-transaction-link-${row.number}`}
              href={QBOTransactionLink}
              style={{ padding: 0 }}
              // @ts-ignore
              target="_blank"
              rel="noopener noreferrer"
            >
              QBO Link
            </FBOButton>
          ) : (
            ''
          )}
        </TableCell>
      )}
      {!meta.activeInvoiceId && (
        <TableCell data-qa={`Memo-${row.number}`}>{row.memo}</TableCell>
      )}
      {!meta.activeInvoiceId && (
        <TableCell data-qa={`Amount-${row.amount}`}>
          {currencyFormatter(row.amount || 0)}
        </TableCell>
      )}
    </>
  );
};

export default React.memo(InvoiceSearchResultsCustomRow);
