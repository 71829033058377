import {
  Address,
  Contact,
  BillToShipToAddress,
  BillToShipToAddressBeta, // @beta: additionally added for testing
  AddressValidation,
} from './types';

export const initialAddress: Address = {
  id: null,
  attention: '',
  city: '',
  companyName: null,
  contacts: [],
  country: null,
  defaultBillTo: false,
  defaultShipTo: false,
  deleted: false,
  email: null,
  name: null,
  phone: null,
  postalCode: '',
  state: '',
  street: '',
  street2: null,
  residential: false,
  version: null,
  verified: false,
};

export const initialContact: Contact = {
  id: null,
  dateCreated: null,
  dateLastModified: null,
  defaultFlag: null,
  deleted: false,
  name: null,
  type: null,
  value: null,
  version: null,
};

export const initalValidAddress: AddressValidation = {
  original: initialAddress,
  validated: initialAddress,
  isComplete: false,
  isValid: false,
  messages: [],
};

export const initialBillToShipToAddress: BillToShipToAddress = {
  attention: null,
  city: null,
  companyName: null,
  country: null,
  name: null,
  postalCode: null,
  residential: false,
  state: null,
  street2: null,
  street: null,
  verified: false,
};

export const initialBillToShipToAddressBeta: BillToShipToAddressBeta = {
  ...initialBillToShipToAddress,
  phone: null, // @beta: additionally added for testing
  email: null, // @beta: additionally added for testing
};
