import React, { useCallback } from 'react';

import { Account } from 'services/accounting';

import { AccountsAutocomplete } from 'ui/components/Autocomplete/AccountsAutocomplete';
import { editItemPermissions } from '../../../../helpers';

import { IncomeAccountFieldsProps } from '../../../GeneralTab/types';
import { autocompleteValue } from '../../../GeneralTab/helpers';

export const IncomeAccountField: React.FC<IncomeAccountFieldsProps> = ({
  saleItem,
  setSaleItem,
  item,
  account,
}) => {
  const editPermissions = editItemPermissions(item);

  const handleAccountAutocomplete = useCallback(
    (name: string) => (value: Account | null) => {
      setSaleItem((old) => ({ ...old, [name]: value ? value.id : -1 }));
    },
    [setSaleItem]
  );
  return (
    <AccountsAutocomplete
      placeholder="Select Income account"
      label="Income Account"
      value={autocompleteValue(account, saleItem.incomeAccountId)}
      onChange={handleAccountAutocomplete('incomeAccountId')}
      dataQa="sale-item-income-account"
      permissions={editPermissions}
    />
  );
};
