import React from 'react';
import { Typography } from '@mui/material';

import { NoImageContainer } from './styled';

const NoImage: React.FC<{}> = () => (
  <NoImageContainer>
    <Typography variant="body2" align="center">
      No <br />
      Image
    </Typography>
  </NoImageContainer>
);

export default NoImage;
