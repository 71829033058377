import React, { useEffect, memo, useState } from 'react';
import _ from 'lodash';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  CustomField,
  fetchCustomFieldsAPI,
  ObjectType,
} from 'services/customFields';

const CustomFieldsOptions: React.FC<{
  objectType: ObjectType;
  selectedCustomFields: number[];
  dataQa?: string;
  setSelectedCustomFields: (ids: number[]) => void;
}> = (props) => {
  const { objectType, selectedCustomFields, setSelectedCustomFields, dataQa } =
    props;

  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  useEffect(() => {
    (async () => {
      const resCustomFields = await fetchCustomFieldsAPI({}, objectType);
      setCustomFields(resCustomFields.data);
    })();
  }, [objectType]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = parseInt(event.target.value, 10);
    setSelectedCustomFields(_.xor(selectedCustomFields, [id]));
  };

  return (
    <Accordion square>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel3a-content"
        id="panel3a-header"
        data-qa={`${dataQa}-customField-expand`}
      >
        <Typography>
          <b>Custom Fields</b>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" flexDirection="column" width="100%" pt={1}>
          {customFields.map((cf, index) => {
            return (
              <Box mb={1} width="100%" key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      className="redesign"
                      checked={selectedCustomFields.includes(cf.id!)}
                      onChange={handleCheckboxChange}
                      name={`customField_${cf.id}`}
                      color="primary"
                      value={cf.id}
                      data-qa={`${dataQa}-${cf.name}`}
                    />
                  }
                  label={cf.name}
                />
              </Box>
            );
          })}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(CustomFieldsOptions);
