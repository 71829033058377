import React, { memo } from 'react';
import { Pagination } from 'services/search';
import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { LEDGER_COLUMNS } from '../../consts';
import { ItemSearchResultsProps } from './types';
import { PageCustomRow } from '../PageCustomRow';
import { PermissionType } from 'services/permissions';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const LedgersSearchResults: React.FC<ItemSearchResultsProps> = (props) => {
  const {
    activeItemId,
    handleItemClick,
    items,
    isLoadingItems,
    onPaginationChange,
    pagination,
    openCreateModal,
  } = props;

  const handlePaginationChange = (newPagination: Pagination) => {
    onPaginationChange(newPagination);
  };

  const resolvedColumns = activeItemId
    ? LEDGER_COLUMNS.filter((c) => c.title === 'Name' || c.title === 'Balance')
    : LEDGER_COLUMNS;

  return (
    <>
      <FBOTitleBar title="Ledgers">
        <FBOButton
          variant={!activeItemId ? 'primary' : 'secondary'}
          color="positive"
          size="medium"
          icon="FBOAddCircle"
          onClick={() => openCreateModal(true)}
          data-qa="accounting-new"
          permissions={[PermissionType.AccountingEdit]}
        >
          {!activeItemId ? 'Add New' : ''}
        </FBOButton>
      </FBOTitleBar>

      <ItemsTable
        data={items}
        columns={resolvedColumns}
        activeId={activeItemId}
        RenderCustomRow={PageCustomRow}
        onItemClick={handleItemClick}
        selectableItems={false}
        isLoadingData={isLoadingItems}
        pagination={pagination}
        onPaginationChange={handlePaginationChange}
        meta={{ activeItemId }}
        RenderCustomFooter={() => <></>}
        dataQa="ledgers-table"
      />
    </>
  );
};
export default memo(LedgersSearchResults);
