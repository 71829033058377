import { PickItem } from 'services/picking';

export interface PickFinishWizardProps {
  show: boolean;
  pickItems: PickItem[];
  onClose(): void;
  onSave: (pickItems: PickItem[]) => Promise<void>;
}

export interface ErrorModal {
  show: boolean;
  title: string;
  msg: string;
}

export enum PickFinishWizardButtons {
  AutoSelectAndContinue = 'AutoSelectAndContinue',
  Next = 'Next',
}
