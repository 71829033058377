// TODO : RESTYLING : REFACTOR : combine this with FBOGeneralTab after FF cleanup
import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import { ItemType } from 'services/items';
import { AccountType } from 'services/ledgers';
import { Account, fetchAccounts } from 'services/accounting';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';

import { AccountFieldsProps } from './types';
import { editItemPermissions } from '../../helpers';
import {
  autocompleteOptions,
  getOptionLabel,
  autocompleteValue,
} from './helpers';
import { AccountsAutocomplete } from 'ui/components/Autocomplete/AccountsAutocomplete';
import { logErrorCtx } from 'app/logging';

export const FBOAccountFields: React.FC<AccountFieldsProps> = ({
  item,
  setItem,
}) => {
  const [accounts, setAccounts] = useState<Account[]>([]);

  const editPermissions = editItemPermissions(item);

  const handleAutocomplete = useCallback(
    (name: string) => (ev: React.ChangeEvent<{}>, value: Account | null) => {
      setItem((old) => ({ ...old, [name]: value ? value.id : -1 }));
    },
    [setItem]
  );

  const handleAccountAutocomplete = useCallback(
    (name: string) => (value: Account | null) => {
      setItem((old) => ({ ...old, [name]: value ? value.id : -1 }));
    },
    [setItem]
  );

  useEffect(() => {
    (async () => {
      try {
        const freshAccounts = await fetchAccounts();
        setAccounts(freshAccounts);
      } catch (e) {
        const error = e as Error;
        logErrorCtx('Error in ItemsDetailCard', {
          error,
          stackTrace: error.stack,
          title: 'Error while saving customer import',
          description: 'fetchAccounts Not Fetched from Server',
          component: 'ItemDetailCard -> AccountFields',
        });
      }
    })();
  }, []);

  return (
    <>
      <Grid xs={6}>
        <AccountsAutocomplete
          placeholder="Select COGS account"
          label="COGS Account"
          disabled={item.itemType === ItemType.Bundle}
          value={item.costOfGoodsSoldAccountId}
          onChange={handleAccountAutocomplete('costOfGoodsSoldAccountId')}
          dataQa="cogs-account"
        />
      </Grid>
      {item.itemType === ItemType.Inventory && (
        <>
          <Grid xs={6}>
            <AccountsAutocomplete
              placeholder="Select asset account"
              label="Asset Account"
              value={item.assetAccountId}
              onChange={handleAccountAutocomplete('assetAccountId')}
              dataQa="asset-account"
            />
          </Grid>
          <Grid xs={6}>
            <AccountsAutocomplete
              placeholder="Select adjustment account"
              label="Adjustment Account"
              value={item.adjustmentAccountId}
              onChange={handleAccountAutocomplete('adjustmentAccountId')}
              dataQa="adjustment-account"
            />
          </Grid>
          <Grid xs={6}>
            <AccountsAutocomplete
              placeholder="Select scrap account"
              label="Scrap Account"
              value={item.scrapAccountId}
              onChange={handleAccountAutocomplete('scrapAccountId')}
              dataQa="scrap-account"
            />
          </Grid>
        </>
      )}
      {(item.itemType === ItemType.Service ||
        item.itemType === ItemType.Shipping ||
        item.itemType === ItemType.NonInventory ||
        item.itemType === ItemType.Labor ||
        item.itemType === ItemType.Overhead) && (
        <Grid xs={6}>
          <Autocomplete
            label="Expense Account"
            placeholder="Select expense account"
            options={autocompleteOptions(accounts, AccountType.Expense)}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={(option, val) => option.name === val.name}
            permissions={editPermissions}
            value={autocompleteValue(accounts, item.assetAccountId)}
            onChange={handleAutocomplete('assetAccountId')}
            dataQa="expense-account"
          />
        </Grid>
      )}
      {item.itemType === ItemType.Shipping && (
        <Grid xs={6}>
          <Autocomplete
            label="Accrual Account"
            placeholder="Select accrual account"
            options={autocompleteOptions(accounts, AccountType.Holding)}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={(option, val) => option.name === val.name}
            permissions={editPermissions}
            value={autocompleteValue(accounts, item.adjustmentAccountId)}
            onChange={handleAutocomplete('adjustmentAccountId')}
            dataQa="accrual-account"
          />
        </Grid>
      )}
    </>
  );
};
