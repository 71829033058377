import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useSerialRowStyle = makeStyles((theme: Theme) => ({
  icon: {
    cursor: 'pointer',
  },
  cellContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(1),
  },
  textFieldContainer: {
    paddingRight: theme.spacing(1),
    flexGrow: 1,
  },
  noBorder: {
    border: 0,
  },
  status: {
    width: '360px',
  },
}));
