import React, { memo, useCallback } from 'react';
import { TableCell } from '@mui/material';
import { colorPalette } from 'ui/theme';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { Address } from 'services/addresses';
import { PermissionType } from 'services/permissions';

import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';

import { createActionBarOptions, AddressTabAction } from './consts';

const AddressesRow: React.FC<RenderCustomRowProps<Address>> = (props) => {
  const { row: address, index, onAction } = props;

  const canEditCustomerAddress =
    address.id && address.id > 0
      ? [PermissionType.CustomersEdit]
      : [PermissionType.CustomersCreate];

  const handleAction = useCallback(
    (actionType: AddressTabAction) => {
      if (onAction) {
        onAction({ type: actionType, value: index });
      }
    },
    [onAction, index]
  );

  return (
    <>
      <TableCell>{address.name}</TableCell>
      <TableCell>{address.city}</TableCell>
      <TableCell>{address.postalCode}</TableCell>
      <TableCell padding="none">
        <FBOMenuButton
          variant="tertiary"
          items={createActionBarOptions(handleAction)}
          permissions={canEditCustomerAddress}
          data-qa="addresses-row-3-dot-menu-button"
          style={{ color: colorPalette.redesign.contentSecondary }}
        />
      </TableCell>
    </>
  );
};

export default memo(AddressesRow);
