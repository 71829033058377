import { ItemTrackingType } from 'services/inventory';
import { MenuItem } from 'ui/components/Menu';

export const SerialAutoAssignMenuItems = (
  itemTrackingTypes: ItemTrackingType[],
  handleAutoAssign: (
    trackingId: number,
    trackingTypeId: number
  ) => Promise<void>
): MenuItem[] => {
  const menuItems: MenuItem[] = [];

  itemTrackingTypes.forEach((i) => {
    if (i.trackingType) {
      const trackingTypeId = i.trackingType.id;
      menuItems.push({
        label: i.trackingType.name,
        onOptionClick: () => handleAutoAssign(i.id, trackingTypeId),
        disabled: i.nextValue ? false : true,
      });
    }
  });

  return menuItems;
};

export const ImportSNMenuItems = (
  itemTrackingTypes: ItemTrackingType[],
  setActiveTrackingTypeId: React.Dispatch<React.SetStateAction<number | null>>
): MenuItem[] => {
  const menuItems: MenuItem[] = [];

  itemTrackingTypes.forEach((i) => {
    if (i.trackingType) {
      const trackingTypeId = i.trackingType.id;
      menuItems.push({
        label: i.trackingType.name,
        onOptionClick: () => setActiveTrackingTypeId(trackingTypeId),
        disabled: i.nextValue ? false : true,
      });
    }
  });

  return menuItems;
};
