import React, { useCallback, useRef } from 'react';
import { Box, Typography } from '@mui/material';

import { Modal } from 'ui/components/Modal/Modal';

import { SerialImportModalProps } from './types';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const SerialImportModal: React.FC<SerialImportModalProps> = (props) => {
  const {
    open,
    activeFile,
    handleModalApplyClicked,
    handleModalCancelClicked,
    setActiveFile,
  } = props;

  const hiddenInput = useRef<HTMLInputElement>(null);

  const handleAddFileClicked = useCallback(() => {
    hiddenInput.current!.click();
  }, [hiddenInput]);

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        return;
      }

      setActiveFile(event.target.files[0]);
    },
    [setActiveFile]
  );

  return (
    <Modal
      open={open}
      title="Import Serial Number List "
      applyDisabled={!activeFile}
      onApplyClicked={handleModalApplyClicked}
      onCancelClicked={handleModalCancelClicked}
      maxWidth="sm"
    >
      <Box display="flex" flexDirection="column">
        <Typography>
          <b>Please select a file with a list of serial numbers to import.</b>
          <p>
            NOTE: The file must be a comma separated formatted file with no
            header row (.csv). Any values already in the list will be
            overwritten by the import.
          </p>
          <p>Each serial number in the file must be separated by a comma.</p>
          <p>For example: 12345, 12346, 12347, 12348, etc.</p>
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Box mr={3}>
            <Typography
              sx={{
                width: '450px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                alignSelf: 'start',
              }}
            >
              {activeFile ? activeFile.name : 'No file selected'}
            </Typography>
          </Box>
          <FBOButton
            variant="secondary"
            color="positive"
            size="large"
            onClick={handleAddFileClicked}
            data-qa="serial-import-modal-add-file-button"
          >
            Add File
          </FBOButton>

          <input
            style={{ display: 'none' }}
            ref={hiddenInput}
            type="file"
            accept=".csv"
            onChange={handleFileChange}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default React.memo(SerialImportModal);
