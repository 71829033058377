import React from 'react';
import { Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { theme } from 'ui/theme';

import { CycleCountsProps } from './types';
import { useCycleCountsStyle } from './styled';

export const CycleCounts: React.FC<CycleCountsProps> = ({
  serials,
  quantity,
  selectedRows,
  showSelection,
}) => {
  const classes = useCycleCountsStyle();

  //don't count empty rows
  const validSerials = serials.filter(
    (row) => Object.values(row.serialNumbers)[0] !== null
  );
  const isValid = validSerials.length == quantity;
  const overSelecting = serials.length - selectedRows.length < quantity;

  return (
    <>
      {showSelection && (
        <>
          <Typography
            className={classes.overSelect}
            color={
              overSelecting ? theme.palette.error.main : theme.palette.grey[900]
            }
            data-qa={'selected-rows-text'}
          >
            {`${selectedRows.length} Selected`}
            {overSelecting && (
              <Typography
                className={classes.overSelectError}
                data-qa={'selected-rows-error-text'}
              >
                <ErrorOutlineIcon
                  sx={{
                    width: '18px',
                    height: '18px',
                    marginLeft: '10px',
                    marginRight: '2px',
                  }}
                  data-qa={'selected-rows-error-icon'}
                />
                {`Select correct amount to delete`}
              </Typography>
            )}
          </Typography>
        </>
      )}
      <Typography
        color={isValid ? theme.palette.primary.main : theme.palette.error.main}
        variant="body1"
        paddingTop={showSelection ? '5px' : '34.5px'}
        paddingLeft="20px"
        data-qa={'serial-numbers-to-cycle-text'}
      >
        {`${validSerials.length} / ${quantity} Serial Numbers`}
      </Typography>
    </>
  );
};
