import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { Grid } from '@mui/material';

import { Errors, validateYup } from 'services/forms/validation';
import { useHandleTextFieldChange } from 'services/forms';
import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';

import { NewClassModalProps } from './types';
import { initialClass } from 'services/classes/consts';
import { Class, fetchClasses } from 'services/classes';
import { postClass } from 'services/classes/api';

const yupSchema = yup.object().shape({
  name: yup.string(),
});

const NewClassModal: React.FC<NewClassModalProps> = (props) => {
  const { show, newClassName, onClose, onSave } = props;

  const dispatch = useDispatch();

  const [formClass, setFormClass] = useState<Class>(initialClass);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Errors>({});

  useEffect(() => {
    setFormClass({
      ...initialClass,
      name: newClassName,
    });
    setErrors({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const handleApplyModal = async () => {
    if (!validateYup(formClass, yupSchema, setErrors)) {
      return;
    }

    setIsLoading(true);

    try {
      const newClass = await postClass(formClass);
      // Lint skip to be removed
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(fetchClasses());
      onSave(newClass);
    } catch {
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
    setFormClass(initialClass);
  };

  const handleTextFieldChange = useHandleTextFieldChange(
    setFormClass,
    formClass
  );

  return (
    <Modal
      open={show}
      title="New Class"
      onCancelClicked={onClose}
      onApplyClicked={handleApplyModal}
      isLoadingContent={isLoading}
      maxWidth="xs"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            className="redesign"
            variant="standard"
            name="name"
            label="Name"
            value={formClass.name}
            onChange={handleTextFieldChange}
            required
            error={!!errors.name}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default memo(NewClassModal);
