import { transformTaxRate } from 'services/taxRates';
import { PurchaseOrderSettings } from './types';

export const transformPurchaseOrderSettings = (
  response: any
): PurchaseOrderSettings => {
  return {
    id: response.id,
    version: response.version,
    nextNumber: response.nextNumber,
    purchaseTax: response.purchaseTax
      ? transformTaxRate(response.purchaseTax)
      : null,
    purchaseTaxId: response.purchaseTaxId,
    onlyShowVendorPartsInThePartDropDownFilter:
      response.onlyShowVendorPartsInThePartDropDownFilter,
    promptToSendEmailOnPoIssue: response.promptToSendEmailOnPoIssue,
    reportFooter: response.reportFooter,
    enableOverReceiving: response.enableOverReceiving,
    costIncludesTax: response.costIncludesTax,
  };
};

export const transformToPurchaseSettingsPut = (
  purchaseSettings: PurchaseOrderSettings
) => ({
  id: purchaseSettings.id,
  version: purchaseSettings.version,
  nextNumber: purchaseSettings.nextNumber,
  onlyShowVendorPartsInThePartDropDownFilter:
    purchaseSettings.onlyShowVendorPartsInThePartDropDownFilter,
  promptToSendEmailOnPoIssue: purchaseSettings.promptToSendEmailOnPoIssue,
  purchaseTaxId: purchaseSettings.purchaseTaxId,
  reportFooter: purchaseSettings.reportFooter,
  automaticallyIssueDropShipOrders: false,
  updateVendorCostAfterPurchaseOrderIsIssued: false,
  enableOverReceiving: purchaseSettings.enableOverReceiving,
  costIncludesTax: purchaseSettings.costIncludesTax,
});
