import {
  createApiReducer,
  chainReducers,
  ApiStatus,
  INITIAL_API_STATUS,
} from 'services/api';

import { transformCommerce } from '../transformations';
import { SellwareAccountActionTypes } from './actions';

const fetchSellwareAccountReducer = createApiReducer(
  [
    SellwareAccountActionTypes.FETCH_SELLWARE_ACCOUNT_START,
    SellwareAccountActionTypes.FETCH_SELLWARE_ACCOUNT_SUCCESS,
    SellwareAccountActionTypes.FETCH_SELLWARE_ACCOUNT_FAILURE,
  ],
  (data) => transformCommerce(data),
  (data) => data
);

export interface CommerceApiState<T = any, R = any> {
  _status: ApiStatus;
  configuration: T;
  connection: R;
}

export const INITIAL_API_STATE: CommerceApiState = {
  _status: INITIAL_API_STATUS,
  configuration: '',
  connection: '',
};

const commerceChainedReducers = chainReducers(
  INITIAL_API_STATE,
  fetchSellwareAccountReducer
);

export default commerceChainedReducers;
