import React, { useMemo } from 'react';
import { noop } from 'lodash';
import { useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';

import { getSettingsCompanyCountry } from 'services/settings/company';

import { TextField } from '../TextField';
import {
  PhoneInputFieldProps,
  PhoneNumberWithCountryInputProps,
} from './types';

import 'react-phone-input-2/lib/plain.css';

const PhoneNumberWithCountryInput = (
  props: PhoneNumberWithCountryInputProps
) => {
  const { onChange, name, defaultCountry, dropdownUpward, ...other } = props;

  const companySettingsCountry = useSelector(getSettingsCompanyCountry);

  const defaultCountryFromCompanySettings = useMemo(() => {
    if (!companySettingsCountry) {
      return undefined;
    }

    return companySettingsCountry.toLowerCase();
  }, [companySettingsCountry]);

  const onNumberChange = (val: string) => {
    // this fixes the bug Maximum update depth exceeded
    if (props.value === val) {
      return;
    }
    onChange({
      target: {
        value: val,
        name,
      },
      persist: noop,
    });
  };

  return (
    <PhoneInput
      {...other}
      value={props.value}
      country={
        props.defaultCountry
          ? defaultCountry
          : defaultCountryFromCompanySettings
      }
      onChange={onNumberChange}
      enableSearch
      inputStyle={{
        border: 'none',
        fontSize: 'inherit',
        left: '0',
        position: 'absolute',
        top: '1px',
        width: '100%',
      }}
      buttonStyle={{
        border: 'none',
        borderRadius: '2px',
        height: '30px',
        left: '5px',
        top: '3px',
      }}
      dropdownStyle={{
        top: dropdownUpward ? 'auto' : '33px',
        bottom: dropdownUpward ? '100%' : '',
      }}
    />
  );
};

const PhoneInputField = (props: PhoneInputFieldProps) => {
  const { defaultCountry, dropdownUpward, ...other } = props;
  return (
    <TextField
      variant={'standard'}
      className={'redesign'}
      {...other}
      type="text"
      InputProps={{
        inputComponent: PhoneNumberWithCountryInput as any,
      }}
      inputProps={{
        defaultCountry: defaultCountry,
        dropdownUpward: dropdownUpward,
      }}
    />
  );
};

export default PhoneInputField;
