import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { colorPalette, styled } from 'ui/theme';
import { TextField } from 'ui/components/TextField/TextField';
import { TextFieldWithOptions } from 'ui/components/TextField/TextFieldWithOptions';

import { TextFieldDimensionsProps } from './types';

export const LengthInput = styled(TextField)`
  max-width: 25%;
  & > .MuiInputBase-root {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-right: 0;
  }
  & .MuiOutlinedInput-input {
    text-align: center;
  }
`;

export const WidthInput = styled(TextField)`
  max-width: 25%;
  & > .MuiInputBase-root {
    border-radius: 0;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-right: 0;
    border-left: 0;
  }
  & .MuiOutlinedInput-input {
    text-align: center;
  }
`;

export const HeightInput = styled(TextFieldWithOptions)`
  max-width: 50%;
  & > .MuiInputBase-root {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-left: 0;
  }
  & .MuiOutlinedInput-input {
    text-align: center;
  }
`;

export const useDimensionsStyle = makeStyles((theme: Theme) => ({
  separator: {
    display: 'flex',
    alignItems: 'center',
    minWidth: theme.spacing(1),
    borderTop: (props: TextFieldDimensionsProps) =>
      props.error ? '1px solid red' : '1px solid #c4c4c4',
    borderBottom: (props: TextFieldDimensionsProps) =>
      props.error ? '1px solid red' : '1px solid #c4c4c4',
  },
  labelContainer: {
    position: 'absolute',
    display: 'inline-flex',
    backgroundColor: colorPalette.white,
    zIndex: 10,
    marginTop: -9,
    height: 16,
    marginLeft: theme.spacing(1),
    paddingLeft: 3,
    paddingRiht: 3,
  },
  label: {
    fontSize: '11px',
  },
  labelDisabled: {
    fontSize: '11px',
    color: colorPalette.grey,
  },
}));
