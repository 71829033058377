import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
// import { Theme } from '@mui/material/styles/createMuiTheme';

// Lint skip to be removed
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const useAutocomplete = makeStyles((theme: Theme) => ({
  popup: {
    zIndex: theme.zIndex.modal + 200,
  },
  minWidth: {
    minWidth: '100px',
  },
  root: {},
  option: theme.typography.body1,
  status: {
    marginLeft: theme.spacing(2),
    borderRadius: 20,
  },
  statusLabel: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
    lineHeight: 1,
  },
}));
