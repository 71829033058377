import { Errors } from 'services/forms/validation';
import { PricingRule } from 'services/pricingRules';

export interface PricingRulesSidebarProps {
  activePricingRule: PricingRule;
  setActivePricingRule: React.Dispatch<React.SetStateAction<PricingRule>>;
  handleTextFieldChange(e: any): void;
  validationErrors: Errors;
}

export enum DateRangeOption {
  ThisWeek = 'This Week',
  NextWeek = 'Next Week',
  NextMonth = 'Next Month',
  Next7Days = 'Next 7 Days',
}
