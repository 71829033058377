import { createApiCall } from 'services/api';
import { transformTaxRate, transformTaxRateRequest } from './transformations';
import { TaxRate } from './types';

export const fetchTaxRateById = async (id: number): Promise<TaxRate> => {
  const response = await createApiCall({
    path: `/v1/taxes/${id}`,
    method: 'GET',
  })();

  return transformTaxRate(response.data);
};

export const createTaxRate = async (taxRate: TaxRate): Promise<TaxRate> => {
  const response = await createApiCall(
    {
      path: '/v1/taxes',
      method: 'POST',
      body: transformTaxRateRequest(taxRate),
    },
    {
      getMessage: {
        success: () => 'New tax rate successfully added',
        error: (error: any) => error.response.data.message,
      },
    }
  )();

  return transformTaxRate(response.data);
};

export const updateTaxRate = async (taxRate: TaxRate): Promise<TaxRate> => {
  const response = await createApiCall(
    {
      path: '/v1/taxes',
      method: 'PUT',
      body: transformTaxRateRequest(taxRate),
    },
    {
      getMessage: {
        success: () => 'Tax rate successfully updated',
        error: (error: any) => error.response.data.message,
      },
    }
  )();

  return transformTaxRate(response.data);
};

export const deleteTaxRate = async (id: number): Promise<void> => {
  await createApiCall(
    {
      path: `/v1/taxes/${id}`,
      method: 'DELETE',
    },
    {
      getMessage: {
        success: () => 'Tax rate successfully deleted',
        error: (error: any) => error.response.data.message,
      },
    }
  )();
};

export const restoreTaxRate = async (taxRateId: number) => {
  await createApiCall(
    {
      path: `/v1/taxes/${taxRateId}/restore`,
      method: 'POST',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
        success: () => 'Tax rate successfully restored',
      },
    }
  )();
};
