import React, { useCallback, useRef } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  AutocompleteProps,
  TextField,
  UseAutocompleteProps,
} from '@mui/material';
import { stopInputPropagation } from 'helpers';

type AutoCompleteCmp<T> = React.FunctionComponent<
  AutoCompleteProps<T> & UseAutocompleteProps<T, any, any, any>
>;

interface AutoCompleteProps<T>
  extends Omit<AutocompleteProps<T, any, any, any>, 'renderInput'> {
  required: boolean;
  name: string;
  label: string;
  placeholder: string;
  error: boolean;
  errorText: string;
  dataQa: string;
  allowLastPassIcon: boolean;
}

const FBOAutoComplete: AutoCompleteCmp<any> = ({
  required,
  label,
  placeholder,
  error,
  errorText,
  dataQa,
  allowLastPassIcon,
  ...otherProps
}) => {
  const inputElement = useRef<HTMLInputElement>(null);

  const handleOnOpen = useCallback(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, [inputElement]);

  const renderInput = (params: any) => {
    return (
      <TextField
        {...params}
        fullWidth
        variant="outlined"
        required={required}
        label={label}
        placeholder={placeholder}
        error={error}
        helperText={errorText}
        InputLabelProps={{ shrink: true }}
        FormHelperTextProps={{ children: ' ' }}
        inputProps={{
          ...params.inputProps,
          'data-qa': dataQa,
          'data-lpignore': `${!allowLastPassIcon}`,
        }}
        InputProps={{
          ...params.InputProps,
          inputRef: inputElement,
        }}
        onClick={stopInputPropagation}
      />
    );
  };

  return (
    <Autocomplete
      {...otherProps}
      fullWidth
      renderInput={renderInput}
      onOpen={handleOnOpen}
      clearIcon={
        <CloseIcon
          fontSize="small"
          data-qa={`${dataQa}-autocomplete-clear-icon`}
        />
      }
      popupIcon={
        <ArrowDropDownIcon
          fontSize="small"
          data-qa={`${dataQa}-autocomplete-dropdown-icon`}
        />
      }
    />
  );
};

export default FBOAutoComplete;
