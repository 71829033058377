import {
  TrackingGroup,
  SerialRow,
  EditInventorySerialData,
  EditInventoryNonSerialData,
} from 'services/inventory';

/**
 * Transforms InventoryEditFormValues to EditInventoryTrackingData
 * @param itemId
 * @param locationId
 */
export const transformToEditInventoryNonSerialTrackingData = (
  itemId: number,
  locationId: number | null,
  initialTrackingGroup: TrackingGroup,
  trackingGroup: TrackingGroup
): EditInventoryNonSerialData => {
  return {
    itemId,
    locationId,
    existingTrackingValues: initialTrackingGroup.trackingInfoList,
    newTrackingValues: trackingGroup.trackingInfoList,
  };
};

export const transformToEditInventorySerialTrackingData = (
  serialRow: SerialRow
): EditInventorySerialData => {
  const serialNumberDtoList = Object.keys(serialRow.serialNumbers).map(
    (trackingTypeId) => {
      const trackingTypeIdNum = parseInt(trackingTypeId, 10);
      return {
        trackingTypeId: trackingTypeIdNum,
        serialNumber: serialRow.serialNumbers[trackingTypeIdNum]!,
      };
    }
  );

  return {
    serialId: serialRow.id,
    serialNumberInputs: serialNumberDtoList,
  };
};
