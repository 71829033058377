import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';

import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import {
  TrackingTableTypes,
  transformToTrackingGroupsNewGroup,
} from 'ui/components/Table/TrackingTable';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import { DatePickerWrapper } from 'ui/components/TextField/DatePickerWrapper';
import {
  getItemInventory,
  itemHasSerialTracking,
  ItemInventory,
  ItemTrackingType,
  SerialRow,
  TrackingGroup,
} from 'services/inventory';
import { TrackingDataTypes } from 'services/settings/tracking';
import { Location } from 'services/locations';
import { useHandleTextFieldChange } from 'services/forms';
import { getSettingsPurchaseOrder } from 'services/settings/purchaseOrders';
import {
  initialReceiptItemReceive,
  ReceiptItemReceive,
  ReceiptItemStatus,
} from 'services/receiving';
import { ItemType } from 'services/items';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';

import { ReceiveModalProps } from './types';
import { useReceiveModalStyle } from './styled';
import { transformReceiptItemToReceiptItemReceive } from '../../transformations';
import SerialTable from 'ui/components/Table/SerialTable/SerialTable';
import TrackingTable from 'ui/components/Table/TrackingTable/TrackingTable';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { colorPalette, themeRestyle } from 'ui/theme';

import { TextFieldQuantity } from 'ui/components/TextField/TextFieldQuantity';
import { EACH_UOM_ID, getUoms } from 'services/uoms';
import { resolveSerialTableErrors } from 'ui/components/Table/SerialTable/helpers';
import { trackingValidation } from '../ReceiveWizard/validations';
import { Errors } from 'services/forms/validation';
import { locationInventoryHandler } from '../ReceiveWizard/components/ReceiveWizardItem/ReceiveWizardItem';
import { serialRows } from '../../helpers';
const ReceiveModal: React.FC<ReceiveModalProps> = (props) => {
  const { receipt, receiptItem, show, fulfillClicked, onCancel, onSubmit } =
    props;

  const classes = useReceiveModalStyle();

  const { items: uoms } = useSelector(getUoms);
  const poSettings = useSelector(getSettingsPurchaseOrder);

  const [inventory, setInventory] = useState<ItemInventory | null>(null);
  const [receive, setReceive] = useState<ReceiptItemReceive>(
    initialReceiptItemReceive
  );
  const [loading, setLoading] = useState(false);
  const [showOverreceivingModal, setShowOverreceivingModal] = useState(false);
  const [activeReceiveSerialErrors, setActiveReceiveSerialErrors] = useState<
    Errors[]
  >([]);

  const selectedReceiptItemType = _.get(receiptItem, 'item.itemType', null);
  const itemTrackingTypes = useMemo(
    () => (receiptItem.item ? receiptItem.item.itemTrackingTypeList : []),
    [receiptItem.item]
  );

  const itemHasTracking = useMemo(
    () => !_.isEmpty(itemTrackingTypes),
    [itemTrackingTypes]
  );

  const resolvedUnitCost = useMemo(() => {
    if (receive.newUnitCost) {
      return receive.newUnitCost;
    }

    return _.get(receiptItem, 'purchaseOrderItem.unitCost', null);
  }, [receiptItem, receive.newUnitCost]);

  const vendorItemName = useMemo(() => {
    const DOT_CHAR = '\u2022';

    if (!receiptItem.purchaseOrderItem) {
      return '';
    }
    return receiptItem.purchaseOrderItem.vendorItemName
      ? ` ${DOT_CHAR} ${receiptItem.purchaseOrderItem.vendorItemName}`
      : '';
  }, [receiptItem]);

  const hasOnlySerial = useMemo(() => {
    if (!itemHasTracking) {
      return false;
    }

    return _.every(
      _.get(receiptItem, 'item.itemTrackingTypeList', []),
      (itemTrackingType: ItemTrackingType) =>
        _.get(itemTrackingType, 'trackingType.trackingDataType', null) ===
        TrackingDataTypes.SerialNumber
    );
  }, [receiptItem, itemHasTracking]);

  const hasSerialTracking = useMemo(
    () => !!receiptItem.item && itemHasSerialTracking(receiptItem.item),
    [receiptItem.item]
  );

  const selectedReceiptItem = useMemo(() => {
    return receipt.receiptItems.find((p) => p.id === receive.id) || null;
  }, [receipt.receiptItems, receive.id]);

  const resolvedQuantity = `${_.get(
    selectedReceiptItem,
    'quantity',
    '0'
  )} ${_.get(selectedReceiptItem, 'uom.abbreviation', 'ea')}`;

  const showQuantityTextfield = !itemHasTracking || hasOnlySerial;

  // initial useEffect
  useEffect(() => {
    if (show) {
      setReceive({
        ...transformReceiptItemToReceiptItemReceive(receiptItem),
        quantity: receiptItem.quantity,
        dateReceived: moment().toDate(),
      });
    }

    if (show && receiptItem.itemId) {
      const fetchInventory = async () => {
        const itemInventory = await getItemInventory(receiptItem.itemId!);

        setInventory(itemInventory);
      };

      if (
        !_.isEmpty(
          receiptItem.item ? receiptItem.item.itemTrackingTypeList : []
        )
      ) {
        fetchInventory();
      }
    } else {
      setInventory(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  // watch location and inventory change and update tracking groups
  useEffect(() => {
    if (hasOnlySerial) {
      setReceive((old) => ({
        ...old,
        trackingGroups: [
          {
            committedQuantity: 0,
            onHand: 0,
            quantity: receive.quantity,
            trackingInfoList: [],
            serialList: serialRows(receive),
            serialIds: [],
          },
        ],
      }));

      return;
    }

    if (inventory) {
      const locationInventory =
        inventory.inventoryRowList.find(
          (i) => i.locationId === receive.locationId
        ) || null;

      const trackingGroups = [
        ...(locationInventory?.trackingGroupList?.map((tg) => ({
          ...tg,
          serialList: [],
        })) || []),
        ...transformToTrackingGroupsNewGroup([], itemTrackingTypes, 0),
      ];
      setReceive((old) => ({ ...old, trackingGroups }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receive.locationId, inventory]);

  const handleTextFieldChange = useHandleTextFieldChange(setReceive, receive);

  const handleLocationChange = useCallback((location: Location | null) => {
    setReceive((old) => ({
      ...old,
      locationId: location ? location.id : null,
    }));
  }, []);
  const handleQuantityChange = useCallback(
    (value: number | null) => {
      setReceive((old) => ({
        ...old,
        quantity: value,
      }));
    },
    [setReceive]
  );
  const handleApplyClicked = useCallback(async () => {
    if (receive.trackingGroups[0]?.serialList) {
      setActiveReceiveSerialErrors(
        resolveSerialTableErrors(receive.trackingGroups[0].serialList)
      );
    }
    const isTrackingValid = trackingValidation(receive);

    if (!isTrackingValid) {
      return;
    }
    setLoading(true);
    // check if quantity is greater then receipt item quantity
    if (
      (receive.quantity || 0) > (selectedReceiptItem?.quantity || 0) &&
      !poSettings.enableOverReceiving
    ) {
      setShowOverreceivingModal(true);
      setLoading(false);
      return;
    }

    await onSubmit(receive);
    setLoading(false);
  }, [receive, onSubmit, poSettings.enableOverReceiving, selectedReceiptItem]);

  const handleSetTrackingGroups = useCallback(
    (trackingGroups: TrackingGroup[]) => {
      const quantity = trackingGroups.reduce(
        (acc, i) => acc + (i.quantity || 0),
        0
      );
      setReceive((old) => ({ ...old, quantity, trackingGroups }));
    },
    []
  );

  //get quantity matching serial tracking rows
  const getTrackingGroupsForQuantity = useCallback(
    (itemInventory: ItemInventory, locationId: number) => {
      if (hasOnlySerial) {
        return [
          {
            committedQuantity: 0,
            onHand: 0,
            quantity: receive.quantity,
            trackingInfoList: [],
            serialList: serialRows(receive),
            serialIds: [],
          },
        ];
      }
      const locationInventory =
        itemInventory.inventoryRowList.find(
          (r) => r.locationId === locationId
        ) || null;
      return locationInventoryHandler(locationInventory, receive);
    },
    [hasOnlySerial, receive]
  );

  //useEffect to handle quantity change
  useEffect(() => {
    if (hasOnlySerial) {
      const locationId = receive?.locationId ?? -1;
      if (inventory === null || locationId < 0) {
        return;
      }
      setReceive((old) => ({
        ...old,
        trackingGroups: getTrackingGroupsForQuantity(inventory, locationId),
      }));
    }
  }, [receive.quantity]);

  const handleAddNewTrackingGroupClicked = useCallback(() => {
    setReceive((old) => ({
      ...old,
      trackingGroups: transformToTrackingGroupsNewGroup(
        old.trackingGroups,
        itemTrackingTypes,
        0
      ),
    }));
  }, [itemTrackingTypes]);

  const setSerialList = useCallback(
    (serialList: React.SetStateAction<SerialRow[]>) => {
      if (typeof serialList === 'function') {
        setReceive((old) => ({
          ...old,
          trackingGroups: [
            {
              ...old.trackingGroups[0],
              serialList: serialList(old.trackingGroups[0].serialList),
            },
          ],
        }));
        return;
      }

      setReceive((old) => ({
        ...old,
        trackingGroups: [
          { ...old.trackingGroups[0], quantity: old.quantity, serialList },
        ],
      }));
    },
    []
  );

  const handleDateReceivedChange = useCallback(
    (date: string | null | undefined) => {
      if (!date) {
        setReceive((old) => ({ ...old, dateReceived: null }));
        return;
      }
      const newDate = moment(date);
      setReceive((old) => ({
        ...old,
        dateReceived: newDate.toDate(),
      }));
    },
    []
  );

  const resolveDisableApply = useMemo(() => {
    return (
      !receive.quantity ||
      (receive.dateReceived !== null &&
        isNaN(receive.dateReceived ? receive.dateReceived.getTime() : NaN))
    );
  }, [receive]);

  const ConfirmationModalActions = () => {
    return (
      <FBOButton
        variant="primary"
        color="positive"
        size="large"
        onClick={() => setShowOverreceivingModal(false)}
        data-qa="alert-modal-ok"
      >
        OK
      </FBOButton>
    );
  };

  return (
    <>
      <Modal
        open={show}
        onCancelClicked={onCancel}
        onApplyClicked={handleApplyClicked}
        title={_.get(receiptItem, 'item.name', '') + vendorItemName}
        applyLabel={
          fulfillClicked
            ? `Fulfill ${receive.quantity || 0} items`
            : `Receive ${receive.quantity || 0} items`
        }
        withoutDefaultPadding
        applyDisabled={resolveDisableApply}
        isLoading={loading}
        dataQa="line-item-receive-modal"
      >
        <Grid container sx={{ mb: '64px' }}>
          <Grid item xs={2} classes={{ root: classes.gridWithoutRightPadding }}>
            {/* TODO: update with big number style */}
            <TextField
              className="redesign"
              variant="standard"
              readOnly
              additionalInputProps={{
                classes: { input: classes.bigNumber },
              }}
              value={resolvedQuantity}
              label="Receive Quantity"
            />
          </Grid>
          <Grid item xs={10} classes={{ root: classes.gridWithPadding }}>
            <Grid container spacing={1}>
              {selectedReceiptItemType === ItemType.Inventory && (
                <Grid item xs={showQuantityTextfield ? 5 : 7}>
                  <LocationsAsyncAutocomplete
                    placeholder="Select Location"
                    onChange={handleLocationChange}
                    label="Receive Location"
                    value={receive.locationId}
                    companyWide={false}
                    parentId={receipt.locationId!}
                    dataQa="line-item-receive-location-input"
                  />
                </Grid>
              )}
              <Grid item xs={3}>
                <DatePickerWrapper
                  label="Date Received"
                  placeholder="Date Received"
                  value={receive.dateReceived}
                  onChange={handleDateReceivedChange}
                  dataQa="line-item-receive-date-input"
                />
              </Grid>
              <Grid item xs={2}>
                <CurrencyField
                  label="Unit Cost"
                  placeholder="Unit Cost"
                  name="newUnitCost"
                  autoComplete="nope"
                  value={resolvedUnitCost}
                  onChange={handleTextFieldChange}
                  allowNegative
                  disabled={receiptItem.status === ReceiptItemStatus.Reconciled}
                  required
                  dataQa="line-item-receive-unit-cost-input"
                />
              </Grid>
              {showQuantityTextfield && (
                <Grid item xs={2}>
                  <TextFieldQuantity
                    name="quantity"
                    uoms={uoms}
                    placeholder="0"
                    label="Quantity"
                    required
                    autoComplete="nope"
                    value={receive.quantity}
                    onTextChange={handleQuantityChange}
                    selectedUomId={receive.receiptItem.uomId ?? EACH_UOM_ID}
                    dataQa="line-item-receive-quantity-input"
                    isDecimal={!hasSerialTracking}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {itemHasTracking && (
              <Box height="50vh" marginBottom={5}>
                {!hasOnlySerial ? (
                  <TrackingTable
                    itemTrackingTypes={_.get(
                      receiptItem,
                      'item.itemTrackingTypeList'
                    )}
                    tableType={TrackingTableTypes.Add}
                    trackingGroups={receive.trackingGroups}
                    onSetTrackingGroups={handleSetTrackingGroups}
                    onAddNewTracking={handleAddNewTrackingGroupClicked}
                    inventoryUom={
                      receive.receiptItem.item
                        ? receive.receiptItem.item.defaultUom
                        : null
                    }
                    itemUom={receive.receiptItem.uom || null}
                    isDecimal={!hasSerialTracking}
                    sx={{
                      borderRadius: '5px',
                      border: `1px solid ${colorPalette.redesign.background3}`,
                      borderTop: 'none',
                      marginRight: themeRestyle.spacing(4),
                      marginLeft: themeRestyle.spacing(4),
                    }}
                    headerMargin="0px 32px"
                  />
                ) : (
                  <SerialTable
                    serialList={_.get(
                      receive,
                      'trackingGroups[0].serialList',
                      []
                    )}
                    setSerialList={setSerialList}
                    itemTrackingTypes={_.get(
                      receiptItem,
                      'item.itemTrackingTypeList'
                    )}
                    minSerialQuantity={receive.quantity || 0}
                    rowErrors={activeReceiveSerialErrors}
                    canClearRow
                    canEditRow
                    setIsLoading={setLoading}
                    isLoading={loading}
                    headerMargin="0px 32px"
                    sx={{
                      borderRadius: '5px',
                      border: `1px solid ${colorPalette.redesign.background3}`,
                      borderTop: 'none',
                      borderBottom: 'none',
                      marginRight: themeRestyle.spacing(4),
                      marginLeft: themeRestyle.spacing(4),
                    }}
                  />
                )}
              </Box>
            )}
          </Grid>
        </Grid>
      </Modal>

      <ConfirmationModal
        open={showOverreceivingModal}
        title="Over receiving is not enabled"
        body={`At the moment, over receiving is not supported. You can enable it under Settings > Purchase Order > Enable over receiving`}
        onCancelClicked={() => {}}
        onConfirmClicked={() => {}}
        DialogActionsComponent={ConfirmationModalActions}
      />
    </>
  );
};

export default memo(ReceiveModal);
