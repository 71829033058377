import React from 'react';
import { Route, Routes } from 'react-router-dom';

import SecureRoute from 'ui/components/Route/SecureRoute';
import { PermissionType } from 'services/permissions';

import { Routes as MaterialsRoutes } from './routes';
import { ItemsPage, InventoryPage } from '../pages';
import { MaterialsRouterCmp, MaterialsRouterProps } from './types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MaterialsRouter: MaterialsRouterCmp = (props: MaterialsRouterProps) => {
  return (
    <Routes>
      <Route element={<SecureRoute permissions={[PermissionType.ItemsView]} />}>
        <Route element={<ItemsPage />} path={ItemsPage.route} />
      </Route>
      <Route
        element={<SecureRoute permissions={[PermissionType.InventoryView]} />}
      >
        <Route element={<InventoryPage />} path={InventoryPage.route} />
      </Route>
    </Routes>
  );
};

MaterialsRouter.route = MaterialsRoutes.MaterialsRouter;

export default MaterialsRouter;
