import { showNotification } from 'services/api';

import { FAULTYFILETYPES } from './consts';

export const faultyFilesChecker = (files: File[]) => {
  const faultFileExtensions: string[] = [];

  files.forEach((file: File) => {
    const fileExtension = file.name.split('.')[1];
    FAULTYFILETYPES.includes(fileExtension) &&
      faultFileExtensions.push(fileExtension);
  });

  if (!faultFileExtensions.length) {
    return false;
  }

  showNotification(
    `${faultFileExtensions.join(', ')} files cannot be uploaded`,
    {
      variant: 'warning',
    }
  );
  return true;
};
