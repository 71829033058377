import React from 'react';
import { TableCell, Checkbox, Button } from '@mui/material';
import _ from 'lodash';

import { Column, ColumnTypes, RenderCustomRowProps } from '../types';
import NoImage from './NoImage';

function resolveImageUrl(url: string[] | string) {
  if (Array.isArray(url) && url.length > 0) {
    return url[0];
  } else if (Array.isArray(url) && url.length === 0) {
    return null;
  }
  return url;
}

const EnhancedRow: React.FC<RenderCustomRowProps> = (props) => {
  const { row, columns, classes, tableBordered, dataQa } = props;

  const resolveTableCellBody = (column: Column) => {
    switch (column.type) {
      case ColumnTypes.image: {
        return resolveImageUrl(_.get(row, column.field)) ? (
          <img
            src={_.get(row, column.field)}
            alt="item"
            className={classes.itemImage}
          />
        ) : (
          <NoImage />
        );
      }
      case ColumnTypes.boolean: {
        return (
          <Checkbox
            color="primary"
            disabled
            checked={_.get(row, column.field)}
            className={classes.itemCheckbox}
            data-qa={`${dataQa}-checkbox`}
          />
        );
      }
      case ColumnTypes.button: {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={() => column.buttonConfig.action(row)}
          >
            {column.buttonConfig ? column.buttonConfig.text : ''}
          </Button>
        );
      }
      case ColumnTypes.string: {
        return _.get(row, column.field);
      }
      case ColumnTypes.custom: {
        return column.render(row);
      }
      default: {
        return null;
      }
    }
  };

  return (
    <>
      {columns.map((column: Column, index: number) => (
        <TableCell
          key={`${column.title}RowValue${index}`}
          className={classes.cellStyle}
          padding={tableBordered ? 'none' : 'normal'}
          style={{ width: column.width }}
          data-qa={`${dataQa}-${
            row.name ? _.get(row, 'name') : _.get(row, 'title')
          }`}
        >
          {resolveTableCellBody(column)}
        </TableCell>
      ))}
    </>
  );
};

export default EnhancedRow;
