import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import { getLocationById } from 'services/locations';
import { TextField } from 'ui/components/TextField/TextField';

import { TrackingWizardFormProps } from './types';

const TrackingWizardForm: React.FC<TrackingWizardFormProps> = (props) => {
  const { inventoryRow } = props;

  const location = useSelector(getLocationById(inventoryRow.locationId));

  return (
    <>
      <Grid item xs={6}>
        <TextField
          className="redesign"
          variant="standard"
          value={location ? location.name : null}
          label="Location"
          disabled
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          value={inventoryRow.totalQty}
          label="Quantity"
          disabled
          className="redesign"
          variant="standard"
        />
      </Grid>
    </>
  );
};

export default memo(TrackingWizardForm);
