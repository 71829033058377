import { Paper as MaterialPaper } from '@mui/material';
import { spacing } from '@mui/system';
import styled from 'styled-components';

/*
 * Extends default Material Paper by adding ability to add spacing
 * Default spacing set to 1 * 8 on all sides
 */
export const Paper = styled(MaterialPaper)`
  ${spacing}
`;

Paper.defaultProps = {
  p: 1,
};
