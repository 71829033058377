import { Uom } from 'services/uoms';
import { InventoryLocationSummary, ItemTrackingType } from 'services/inventory';
import { VendorItem } from 'services/vendors';
import { Location } from 'services/locations';
import { Tag } from 'services/tags';
import { CustomField } from 'services/customFields';
import { DiscountTypes } from 'services/salesOrders';
import { Document } from 'services/documents';
import { TaxRate } from 'services/taxRates';

import { SaleItem } from './saleItems';

export enum ItemType {
  Inventory = 'Inventory',
  Shipping = 'Shipping',
  Service = 'Service',
  NonInventory = 'Non Inventory',
  Bundle = 'Bundle',
  Labor = 'Labor',
  Overhead = 'Overhead',
}

export enum NonPhysical_ItemType {
  Inventory = 'Inventory',
  Service = 'Service',
  NonInventory = 'Non Inventory',
}

export interface WeightUnit {
  name: string;
  shortName: string;
}

export interface DimensionUnit {
  name: string;
  shortName: string;
}

export interface Item {
  id: number | null;
  accountingClassId: number | null;
  adjustmentAccountId: number | null;
  alertNotes: string | null;
  assetAccountId: number | null;
  bundleItems: BundleItem[];
  cost: number | null;
  price: number | null;
  costOfGoodsSoldAccountId: number | null;
  customFields: CustomField[];
  dateCreated: Date | null;
  dateLastModified: Date | null;
  defaultImage: string | null;
  defaultUom: Uom | null;
  defaultUomId: number | null;
  deleted: boolean;
  description: string | null;
  details: string | null;
  dimensionUnit: string | null;
  documents: Document[];
  height: number | null;
  imageLinkList: ItemImage[];
  inventoryLocationSummaryList: InventoryLocationSummary[];
  itemTrackingTypeList: ItemTrackingType[];
  itemType: ItemType | null;
  itemUomConversionList: ItemUomConversion[];
  length: number | null;
  locationList: ItemLocation[];
  name: string | null;
  reorderPointsList: ItemReorderPoint[];
  saleItemList: SaleItem[];
  scrapAccountId: number | null;
  sku: string | null;
  substituteItems: SubstituteItem[];
  salesTax: TaxRate | null;
  salesTaxId: number | null;
  itemSubstitutes: SubstituteItem[];
  tags: Tag[];
  taxable: boolean | null;
  totalQty: number | null;
  upc: string | null;
  url: string | null;
  vendorId: number | null;
  vendorItems: VendorItem[];
  version: number | null;
  weight: number | null;
  weightUnit: string | null;
  width: number | null;
}

export interface ItemLocation {
  id: number | null;
  version: number | null;
  itemId: number | null;
  parentLocation: Location | null;
  parentLocationId: number | null;
  pickFromLocation: Location | null;
  pickFromLocationId: number | null;
  pickToLocation: Location | null;
  pickToLocationId: number | null;
  receiveLocation: Location | null;
  receiveLocationId: number | null;
  deleted: boolean | null;
}
export interface ItemByLocation {
  id: number | null;
  name: string | null;
  defaultImage: string | null;
  description: string | null;
}

export interface ItemReorderPoint {
  id: number | null;
  version: number | null;
  location: Location | null;
  reorderPointAlerts: ItemReorderPointsAlert[];
  min: number | null;
  max: number | null;
  alert: number | null;
  dailyConsumptionRate: number | null;
  locationId: number | null;
  itemId: number | null;
  deleted: boolean;
}

export interface ItemReorderPointsAlert {
  id: number | null;
  reorderPointId: number | null;
  version: number | null;
  contactName: string | null;
  email: string | null;
  receiveEmailNotifications: boolean;
  receiveInStockNotifications: boolean;
  deleted: boolean;
}

export interface ItemImage {
  id: number;
  cloned: boolean;
  defaultFlag: boolean;
  deleted: boolean;
  url: string | null;
  version: number | null;
  name: string | null;
}

export interface ItemUomConversion {
  id: number | null;
  deleted: boolean;
  version: number | null;
  uomConversionId: number;
  factor: number | null;
  toUomId: number | null;
  fromUomId: number | null;
  originalFactor: number | null;
}

export interface SubstituteItem {
  id: number;
  checkVersion: boolean;
  createdBy: null;
  createdById: number | null;
  dateCreated: Date | null;
  dateLastModified: Date | null;
  deleted: boolean;
  itemId: number | null;
  lastModifiedBy: null;
  lastModifiedById: number | null;
  substituteItem: Item | null;
  substituteItemId: number | null;
  version: number | null;
}

export interface BundleItem {
  id: number | null;
  itemId: number | null;
  saleItemId: number | null;
  saleItem: SaleItem | null;
  quantity: number | null;
  price: number | null;
  discountFlatAmount: number | null;
  discountPercent: number | null;
  discountType: DiscountTypes;
  version: number | null;
  deleted: boolean;
}

// OTHER TYPES
export interface ShippingTerm {
  id: number;
  name: string;
}

export interface Fob {
  id: number | null;
  dateCreated: Date | null;
  dateLastModified: Date | null;
  deleted: boolean;
  name: string | null;
  readOnly: boolean;
  version: number | null;
}
