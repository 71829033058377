import { transformDateToMomentDate } from 'helpers';

import { UomConversion } from './types';

export const transformUomConversion = (res: any): UomConversion => ({
  id: res.id,
  dateCreated: transformDateToMomentDate(res.dateCreated),
  dateLastModified: transformDateToMomentDate(res.dateLastModified),
  deleted: false,
  factor: res.factor,
  fromUomId: res.fromUomId,
  itemUomConversionList: res.itemUomConversionList,
  toUomId: res.toUomId,
  version: res.version,
});
