import React, { memo, useState, useCallback, useMemo } from 'react';
import { Box, Grid, FormControlLabel, Checkbox } from '@mui/material';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { TextField } from 'ui/components/TextField/TextField';
import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { ItemsAutocomplete } from 'ui/components/Autocomplete/ItemsAutocomplete';
import { useHandleTextFieldChange } from 'services/forms';
import { getUoms } from 'services/uoms';
import { Item, ItemType } from 'services/items';
import { Errors, validateYup } from 'services/forms/validation';

import { ReconcileWizardExpensesProps } from './types';
import { initialReconcileExpense } from '../../consts';
import { ReconcileWizardExpensesColumns } from './consts';
import ReconcileWizardExpensesRow from './ReconcileWizardExpensesRow';
import ReconcileWizardExpensesFooter from './ReconcileWizardExpensesFooter';
import { yupReceivingExpenseSchema } from '../../../../validations';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';
import {
  toMulticurrencyCalculate,
  toHomeCurrencyCalculate,
  useGetCurrencySymbol,
} from 'helpers';
import { getSettingsCompany } from 'services/settings/company';
import { MultiCurrencyWrapper } from 'ui/components/MultiCurrencyWrapper/MultiCurrencyWrapper';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import { colorPalette } from 'ui/theme';

const ReconcileWizardExpenses: React.FC<ReconcileWizardExpensesProps> = (
  props
) => {
  const { reconcileExpenses, setReconcileExpenses, reconcile } = props;

  const { items: uoms } = useSelector(getUoms);
  const { homeCurrency, useMultiCurrency } = useSelector(getSettingsCompany);

  const homeCurrencyCode = (homeCurrency && homeCurrency.code) || 'USD';
  const activeMulticurrencyCode = _.get(reconcile, 'currency.code', null);

  const showMultiCurrency = useMemo(
    () =>
      useMultiCurrency &&
      activeMulticurrencyCode &&
      activeMulticurrencyCode !== homeCurrencyCode,
    [useMultiCurrency, activeMulticurrencyCode]
  );

  const exchangeRate = _.get(reconcile, 'currency.exchangeRate', 1);

  const [reconcileExpense, setReconcileExpense] = useState(
    initialReconcileExpense
  );
  const [errors, setErrors] = useState<Errors>({});
  const [selectedExpense, setSelectedExpense] = useState<Item | null>(null);

  const selectedUom = useMemo(() => {
    if (!selectedExpense) {
      return null;
    }

    return uoms.find((u) => u.id === selectedExpense.defaultUomId) || null;
  }, [uoms, selectedExpense]);

  const handleExpenseChange = useCallback(
    (item: Item | null) => {
      setSelectedExpense(item);
      const cost = item && item.cost ? item.cost : 0;
      const multiCurrencyCost = toMulticurrencyCalculate(cost, exchangeRate);
      setReconcileExpense((old) => ({
        ...old,
        addToVendorBill: true,
        currency: reconcile.currency,
        itemId: item ? item.id : null,
        name: item ? item.name : null,
        description: item ? item.description : null,
        cost,
        multiCurrencyCost,
      }));
    },
    [reconcile.currency, exchangeRate]
  );

  const handleNumberFieldChange = useHandleTextFieldChange(
    setReconcileExpense,
    reconcileExpense,
    true
  );

  const handleCheckboxChange = useCallback(
    (_event: React.ChangeEvent<{}>, checked: boolean) => {
      setReconcileExpense((old) => ({ ...old, addToVendorBill: checked }));
    },
    []
  );

  const handleAddExpenseClicked = useCallback(() => {
    const isValid = validateYup(
      reconcileExpense,
      yupReceivingExpenseSchema,
      setErrors
    );
    if (!isValid) {
      return;
    }

    setReconcileExpenses((old) => [
      ...old,
      {
        ...reconcileExpense,
        abbreviation: selectedUom ? selectedUom.abbreviation : null,
      },
    ]);

    setReconcileExpense(initialReconcileExpense);
    setSelectedExpense(null);
  }, [setReconcileExpenses, reconcileExpense, selectedUom]);

  const handleTableAction = useCallback(
    (index: number) => {
      const newReconcileExpenses = [...reconcileExpenses];
      newReconcileExpenses.splice(index, 1);
      setReconcileExpenses(newReconcileExpenses);
    },
    [setReconcileExpenses, reconcileExpenses]
  );

  const handleMultiCurrencyCostChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const multiCurrencyCost = event.target.value
        ? parseFloat(event.target.value)
        : null;
      const cost = toHomeCurrencyCalculate(
        multiCurrencyCost || 0,
        exchangeRate
      );
      setReconcileExpense((old) => ({ ...old, cost, multiCurrencyCost }));
    },
    [exchangeRate]
  );

  const handleCostChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const cost = event.target.value ? parseFloat(event.target.value) : null;
      const multiCurrencyCost = toMulticurrencyCalculate(
        cost || 0,
        exchangeRate
      );
      setReconcileExpense((old) => ({ ...old, cost, multiCurrencyCost }));
    },
    [exchangeRate]
  );

  return (
    <>
      <Box p={3} overflow="hidden" flexShrink={0}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <ItemsAutocomplete
              label="Expense"
              onChange={handleExpenseChange}
              value={selectedExpense}
              placeholder="Select Expense"
              itemTypes={[
                ItemType.Service,
                ItemType.Shipping,
                ItemType.Labor,
                ItemType.Overhead,
              ]}
              error={!!errors.itemId}
            />
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <TextField
                  className="redesign"
                  variant="standard"
                  fullWidth
                  label="Quantity"
                  name="quantity"
                  type="number"
                  placeholder="0"
                  value={reconcileExpense.quantity}
                  onChange={handleNumberFieldChange}
                  disableDebounce={true}
                  error={Boolean(errors.quantity)}
                  helperText={errors.quantity}
                  required
                />
              </Grid>
              <Grid item xs={2}>
                <CurrencyField
                  label="Cost"
                  placeholder={`${useGetCurrencySymbol()}0`}
                  name="cost"
                  value={reconcileExpense.cost || 0}
                  onChange={handleCostChange}
                  error={Boolean(errors.cost)}
                  helperText={errors.cost}
                  required
                  decimalPlaces={6}
                  allowNegative
                />
              </Grid>
              <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
                <Grid item xs={2}>
                  <CurrencyField
                    label={`Cost ${activeMulticurrencyCode}`}
                    placeholder={`0${activeMulticurrencyCode}`}
                    name="cost"
                    value={reconcileExpense.multiCurrencyCost || 0}
                    onChange={handleMultiCurrencyCostChange}
                    error={Boolean(errors.cost)}
                    helperText={errors.cost}
                    required
                    decimalPlaces={6}
                    allowNegative
                    currencyCode={activeMulticurrencyCode}
                    debounceDelay={700}
                  />
                </Grid>
              </MultiCurrencyWrapper>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      className="redesign"
                      checked={reconcileExpense.addToVendorBill}
                      color="primary"
                    />
                  }
                  label="Add To Vendor Bill"
                  onChange={handleCheckboxChange}
                  disabled={
                    selectedExpense
                      ? selectedExpense.itemType !== ItemType.Shipping
                      : false
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <FBOButton
                  variant="primary"
                  color="positive"
                  size="medium"
                  onClick={handleAddExpenseClicked}
                  data-qa="add-expense-button"
                >
                  Add
                </FBOButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box display="flex" flexGrow={1} flexDirection="column" overflow="hidden">
        <FBOTitleBar title="Add Expense" />

        <ItemsTable
          data={reconcileExpenses}
          columns={ReconcileWizardExpensesColumns(
            showMultiCurrency,
            activeMulticurrencyCode,
            homeCurrencyCode
          )}
          selectableItems={false}
          emptyTableText="No expenses added"
          RenderCustomRow={ReconcileWizardExpensesRow}
          onAction={handleTableAction}
          RenderCustomFooter={ReconcileWizardExpensesFooter}
          footerData={{ reconcileExpenses, currency: reconcile.currency }}
          sx={{
            borderRadius: '5px',
            border: `1px solid ${colorPalette.redesign.background3}`,
            borderTop: 'none',
            maxHeight: '245px',
          }}
        />
      </Box>
    </>
  );
};

export default memo(ReconcileWizardExpenses);
