import React from 'react';
import { Grid, Typography } from '@mui/material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

export interface PasswordFieldProps {
  value: string;
}

export interface PasswordValidations {
  length: boolean;
  uppercase: boolean;
  lowercase: boolean;
  hasNumber: boolean;
  specialChar: boolean;
}

export const passwordValidationDefaults: PasswordValidations = {
  length: false,
  uppercase: false,
  lowercase: false,
  hasNumber: false,
  specialChar: false,
};

export const validatePasswordRequirements = (
  value: string = ''
): PasswordValidations => {
  const validations = { ...passwordValidationDefaults };

  if (!value.length) return validations;

  validations.length = value.length >= 9;
  validations.uppercase = new RegExp(/[A-Z]/).test(value);
  validations.lowercase = new RegExp(/[a-z]/).test(value);
  validations.hasNumber = new RegExp(/\d/).test(value);
  validations.specialChar = new RegExp(/[!@#$%^&*]/).test(value);

  return validations;
};

export const passwordRequirementsMet = (value: string = ''): boolean => {
  const validations = validatePasswordRequirements(value);

  return Object.values(validations).every((validation) => validation === true);
};

const PasswordRequirements: React.FC<PasswordFieldProps> = ({ value }) => {
  const validations = validatePasswordRequirements(value);

  return (
    <Grid container className="redesign" pt={'4px'}>
      <Typography mb={'6px !important'}>Minimum requirements:</Typography>
      <Grid item xs={12} mb={'10px !important'}>
        {validations.length ? (
          <CheckCircleOutlineOutlinedIcon className="active" />
        ) : (
          <HighlightOffOutlinedIcon />
        )}
        <Typography>Must be at least 9 characters long</Typography>
      </Grid>
      <Typography mb={'6px !important'}>Must contain:</Typography>
      <Grid item xs={12} mb={'6px !important'}>
        {validations.uppercase ? (
          <CheckCircleOutlineOutlinedIcon className="active" />
        ) : (
          <HighlightOffOutlinedIcon />
        )}
        <Typography>At least one uppercase letter (A-Z)</Typography>
      </Grid>
      <Grid item xs={12} mb={'6px !important'}>
        {validations.lowercase ? (
          <CheckCircleOutlineOutlinedIcon className="active" />
        ) : (
          <HighlightOffOutlinedIcon />
        )}
        <Typography>At least one lowercase letter (a-z)</Typography>
      </Grid>
      <Grid item xs={12} mb={'6px !important'}>
        {validations.hasNumber ? (
          <CheckCircleOutlineOutlinedIcon className="active" />
        ) : (
          <HighlightOffOutlinedIcon />
        )}
        <Typography>At least one number (0-9)</Typography>
      </Grid>
      <Grid item xs={12}>
        {validations.specialChar ? (
          <CheckCircleOutlineOutlinedIcon className="active" />
        ) : (
          <HighlightOffOutlinedIcon />
        )}
        <Typography>At least one special character (!@#$%^&*)</Typography>
      </Grid>
    </Grid>
  );
};

export default React.memo(PasswordRequirements);
