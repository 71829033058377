import * as yup from 'yup';

import { CustomFieldType } from 'services/customFields';

const yupSchema = yup.object().shape({
  customFieldType: yup.string(),
  name: yup.string(),
});

const yupSchemaOptions = yup.object().shape({
  customFieldOptions: yup
    .array()
    .of(yup.object().shape({ option: yup.string() }))
    .required(),
  customFieldType: yup.string(),
  name: yup.string(),
});

export const getYupSchema = (customFieldType: CustomFieldType | null) => {
  if (
    customFieldType === CustomFieldType.SingleChoice ||
    customFieldType === CustomFieldType.MultipleChoice
  ) {
    return yupSchemaOptions;
  }

  return yupSchema;
};
