import React, { memo, useEffect, useMemo, useRef } from 'react';
import { Box } from '@mui/material';

import { DIMENSIONS_UNITS } from 'services/items';

import { PackageDimensionsInputProps } from './types';
import { MenuItem } from 'ui/components/Menu';

import { TextFieldDimensions } from 'ui/components/TextField/TextFieldDimensions';

const dimensionOptions: MenuItem[] = DIMENSIONS_UNITS.map((u) => ({
  label: u.name,
  shortName: u.shortName,
}));

const PackageDimensionsInput = (props: PackageDimensionsInputProps) => {
  const {
    lengthValue,
    widthValue,
    heightValue,
    onLengthChange,
    onWidthChange,
    onHeightChange,
    onMenuChange,
    error,
    dimension,
  } = props;

  const firstInputElement = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (firstInputElement.current !== null) {
      firstInputElement.current.focus();
      firstInputElement.current.select();
    }
  }, []);

  const selectedDimensionIndex = useMemo(() => {
    if (!dimension) {
      return null;
    }
    return dimensionOptions.findIndex((o) => o.label === dimension);
  }, [dimension]);

  return (
    <Box width="375px" display="flex" alignItems={'center'}>
      <TextFieldDimensions
        heightValue={heightValue}
        widthValue={widthValue}
        lengthValue={lengthValue}
        onHeightChange={onHeightChange}
        onWidthChange={onWidthChange}
        onLengthChange={onLengthChange}
        onMenuChange={onMenuChange}
        dimension={dimensionOptions[selectedDimensionIndex || 0]?.label}
        label=""
        error={error}
      />
    </Box>
  );
};

export default memo(PackageDimensionsInput);
