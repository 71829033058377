import { transformDateToMomentDate } from 'helpers/common';
import { createApiCall } from 'services/api/createApiCall';
import { Currency } from 'services/currencies/types';

export const transformCurrency = (response: any): Currency => {
  return {
    ...response,
    dateCreated: transformDateToMomentDate(response.dateCreated),
    dateLastModified: transformDateToMomentDate(response.dateLastModified),
  };
};

export const getCurrencyById = async (id: number): Promise<Currency> => {
  const res = await createApiCall(
    {
      path: `/v1/currencies/${id}`,
      method: 'GET',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformCurrency(res.data);
};
