import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { Modal } from 'ui/components/Modal/Modal';

import { transformToTrackingGroupsNewGroup } from 'ui/components/Table/TrackingTable';
import {
  getItemInventory,
  initialItemInventory,
  itemHasOnlySerialTracking,
  itemHasSerialTracking,
  itemHasTracking,
  ItemInventory,
  ItemInventoryEvents,
  SerialRow,
  TrackingGroup,
} from 'services/inventory';
import {
  getInventoryEventSchema,
  inventoryEventOnlySerialListValidation,
  inventoryEventTrackingGroupValidation,
} from 'ui/modules/materials/services';
import { EACH_UOM_ID, getUoms } from 'services/uoms';
import { validateYup } from 'services/forms/validation';
import { getSettingsCompany } from 'services/settings/company';
import { CostingMethod } from 'ui/modules/setup/pages/SettingsPage/components/CompanyCard/types';

import { InventoryEventFormValues, InventoryEventModalProps } from './types';
import { defaultInventoryEventFormValues } from './consts';
import { mapInventoryEventToTrackingTableType, resolveLabels } from './helpers';
import TrackingTable from 'ui/components/Table/TrackingTable/TrackingTable';
import SerialTable from 'ui/components/Table/SerialTable/SerialTable';
import {
  isCommitted,
  sortSerialList,
} from 'ui/components/Table/SerialTable/helpers';
import FBOEventAddForm from './components/EventAddForm/FBOEventAddForm';
import FBOEventMoveForm from './components/EventMoveForm/FBOEventMoveForm';
import FBOEventRemoveForm from './components/EventRemoveForm/FBOEventRemoveForm';

const firstColumnTitle = (inventoryEvent: ItemInventoryEvents): string => {
  switch (inventoryEvent) {
    case ItemInventoryEvents.Add:
      return 'Quantity To Add';
    case ItemInventoryEvents.Remove:
      return 'Quantity To Remove';
    case ItemInventoryEvents.Move:
      return 'Quantity To Move';
    default:
      return '';
  }
};

const InventoryEventModal = (props: InventoryEventModalProps) => {
  const {
    item,
    show,
    eventType,
    locationId = null,
    onApplyClicked,
    onCancelClicked,
    confirmButtonRed,
  } = props;
  const { items: uoms } = useSelector(getUoms);
  const companySettings = useSelector(getSettingsCompany);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [itemInventory, setItemInventory] =
    useState<ItemInventory>(initialItemInventory);
  const [trackingGroups, setTrackingGroups] = useState<TrackingGroup[]>([]);
  const [serialList, setSerialList] = useState<SerialRow[]>([]);
  const [formValues, setFormValues] = useState<InventoryEventFormValues>(
    defaultInventoryEventFormValues
  );
  const [selectedSerialRows, setSelectedSerialRows] = useState<number[]>([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [qtyErrorMessage, setQtyErrorMessage] = useState('');

  const formValuesUom = useMemo(() => {
    return formValues.uomId
      ? uoms.find((u) => u.id === formValues.uomId)
      : uoms.find((u) => u.id === EACH_UOM_ID);
  }, [formValues.uomId, uoms]);

  const selectedInventoryRow = useMemo(() => {
    if (!itemInventory) {
      return null;
    }

    return (
      itemInventory.inventoryRowList.find(
        (row) => row.locationId === formValues.locationFromId
      ) || null
    );
  }, [formValues.locationFromId, itemInventory]);

  const locationQuantity: {
    [locationId: number]: number;
  } = useMemo(
    () =>
      itemInventory.inventoryRowList.reduce(
        (acc, i) => ({ ...acc, [i.locationId]: i.availableQty }),
        {}
      ),
    [itemInventory]
  );

  const noTracking = useMemo(() => !itemHasTracking(item), [item]);

  const onlySerial = useMemo(() => itemHasOnlySerialTracking(item), [item]);

  const hasSerialTracking = useMemo(() => itemHasSerialTracking(item), [item]);

  const showTrackingTable = useMemo(
    () => !noTracking && !onlySerial,
    [noTracking, onlySerial]
  );

  const enabledTrackingTable = useMemo(
    () => !!formValues.locationFromId,
    [formValues.locationFromId]
  );

  const showSerialTable = useMemo(
    () => !noTracking && onlySerial,
    [noTracking, onlySerial]
  );

  const enabledSerialTable = useMemo(
    () => Boolean(formValues.locationFromId),
    [formValues.locationFromId]
  );

  const canEditSerial = useMemo(
    () => eventType === ItemInventoryEvents.Add,
    [eventType]
  );

  const hasInvalidTracking = useMemo(() => {
    if (noTracking) {
      return false;
    }

    if (onlySerial) {
      return inventoryEventOnlySerialListValidation(
        serialList,
        formValues.quantity || 0,
        eventType
      );
    }

    return inventoryEventTrackingGroupValidation(
      hasSerialTracking,
      trackingGroups,
      eventType
    );
  }, [
    trackingGroups,
    noTracking,
    hasSerialTracking,
    onlySerial,
    serialList,
    formValues.quantity,
    eventType,
  ]);

  const ResolvedForm = useMemo(() => {
    switch (eventType) {
      case ItemInventoryEvents.Add:
        return FBOEventAddForm;
      case ItemInventoryEvents.Remove:
        return FBOEventRemoveForm;
      case ItemInventoryEvents.Move:
        return FBOEventMoveForm;
      default:
        return FBOEventAddForm;
    }
  }, [eventType]);

  // only uoms that have conversion with default uom can be selected
  const uomIds = useMemo(() => {
    if (!item.defaultUom) {
      return [];
    }

    const fromUomIds = item.defaultUom.fromConversions.map(
      (uom) => uom.fromUomId!
    );
    const toUomIds = item.defaultUom.toConversions.map((uom) => uom.toUomId!);

    return [item.defaultUomId!, ...fromUomIds, ...toUomIds];
  }, [item]);

  const autoAssignDisabled = useMemo(() => {
    const areAdding = eventType === ItemInventoryEvents.Add;

    const hasZeroAmount = (formValues.quantity || 0) <= 0;

    return (areAdding && hasZeroAmount) || !areAdding;
  }, [eventType, formValues.quantity]);

  const addDisabled =
    !formValues.quantity ||
    (eventType === ItemInventoryEvents.Remove &&
      !noTracking &&
      onlySerial &&
      formValues.amountToRemove != formValues.quantity);

  useEffect(() => {
    // when modal closes reset state
    if (!show) {
      setItemInventory(initialItemInventory);
      setTrackingGroups([]);
      setSerialList([]);
      setSelectedSerialRows([]);
      setFormValues(defaultInventoryEventFormValues);
      setQtyErrorMessage('');
      return;
    }

    setIsLoading(true);
    const asyncFun = async () => {
      const inventory = await getItemInventory(item.id!, true);
      setItemInventory(inventory);
      setIsLoading(false);
    };

    asyncFun();

    const cost =
      companySettings.accountingMethod === CostingMethod.Average &&
      eventType === ItemInventoryEvents.Add
        ? item.cost
        : null;

    setFormValues((f) => ({
      ...f,
      cost,
      locationFromId: locationId,
      uomId: item.defaultUomId,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (!itemInventory) {
      return;
    }

    const inventoryRow = itemInventory.inventoryRowList.find(
      (row) => row.locationId === formValues.locationFromId
    );

    if (!inventoryRow) {
      setTrackingGroups(
        transformToTrackingGroupsNewGroup([], item.itemTrackingTypeList, 0)
      );
      return;
    }

    switch (eventType) {
      case ItemInventoryEvents.Add: {
        setTrackingGroups(
          inventoryRow.trackingGroupList.map((trackingGroup) => ({
            ...trackingGroup,
            serialList: [],
          }))
        );
        break;
      }
      default: {
        setTrackingGroups(inventoryRow.trackingGroupList);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.locationFromId, itemInventory]);

  useEffect(() => {
    const serialList = _.get(trackingGroups, '[0].serialList', []);
    sortSerialList(serialList);
    setSerialList(serialList);
    if (!serialList.length && !trackingGroups.length) {
      return;
    }

    const quantity = trackingGroups.reduce(
      (acc, trackingGroup) => acc + (trackingGroup.quantity || 0),
      0
    );

    setFormValues((f) => ({ ...f, quantity }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingGroups]);

  const handleAddTrackingClicked = useCallback(() => {
    const availableQuantity = Math.max(
      0,
      (formValues.quantity || 0) -
        trackingGroups.map((t) => t.quantity || 0).reduce((a, b) => a + b, 0)
    );

    setTrackingGroups(
      transformToTrackingGroupsNewGroup(
        trackingGroups,
        item.itemTrackingTypeList,
        availableQuantity
      )
    );
  }, [formValues, trackingGroups, item]);

  const saveTrackingItems = useCallback(
    async (
      newFormValues: InventoryEventFormValues,
      newTrackingGroups: TrackingGroup[]
    ) => {
      setIsLoading(true);
      try {
        onApplyClicked(newFormValues, newTrackingGroups);
      } catch (e) {
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
    },
    [onApplyClicked]
  );

  const handleApplyClicked = async () => {
    sortSerialList(serialList);
    const isValid = validateYup(
      formValues,
      getInventoryEventSchema(eventType),
      setValidationErrors
    );

    if (!isValid) {
      return;
    }

    if (onlySerial) {
      const newTrackingGroups: TrackingGroup[] = [
        {
          ...trackingGroups[0], // If only serial tracking group index is always 0
          quantity: formValues.quantity!,
          serialList,
          serialIds: selectedSerialRows,
          trackingInfoList: [],
        },
      ];
      await saveTrackingItems(formValues, newTrackingGroups);
      return;
    }

    await saveTrackingItems(formValues, trackingGroups);
  };

  const handleSelectedChange = useCallback(
    (id: number | number[]) => {
      if (Array.isArray(id)) {
        const uncommittedSerials = id.filter(
          (num) => !isCommitted(num, serialList)
        );
        setFormValues((fv) => ({
          ...fv,
          quantity:
            eventType === ItemInventoryEvents.Remove
              ? uncommittedSerials.length
              : id.length,
        }));
        setSelectedSerialRows(
          eventType === ItemInventoryEvents.Remove ? uncommittedSerials : id
        );
        return;
      }

      const isIdAlreadySelected = selectedSerialRows.includes(id);
      if (!isIdAlreadySelected) {
        setFormValues((fv) => ({
          ...fv,
          quantity: selectedSerialRows.length + 1,
        }));
        setSelectedSerialRows([...selectedSerialRows, id]);
        return;
      }
      setFormValues((fv) => ({
        ...fv,
        quantity: selectedSerialRows.length - 1,
      }));
      setSelectedSerialRows(_.without(selectedSerialRows, id));
    },
    [selectedSerialRows, setSelectedSerialRows, serialList, setSerialList]
  );

  const applyLabel = resolveLabels(
    eventType,
    eventType === ItemInventoryEvents.Remove && onlySerial
      ? selectedSerialRows.length
      : formValues.quantity || 0,
    formValuesUom?.name ?? undefined
  ).buttonLabel;

  return (
    <Modal
      className="redesign inventory-event-modal"
      open={show}
      title={
        resolveLabels(eventType, formValues.quantity || 0, undefined, item.name)
          .modalLabel
      }
      applyLabel={applyLabel}
      onCancelClicked={onCancelClicked}
      onApplyClicked={handleApplyClicked}
      isLoading={isLoading}
      withoutDefaultPadding
      applyDisabled={addDisabled || hasInvalidTracking}
      nestedScrollAreas
      isLoadingContent={isLoading}
      dataQa="inventory-modal"
      confirmButtonRed={confirmButtonRed}
    >
      <ResolvedForm
        formValues={formValues}
        setFormValues={setFormValues}
        onlySerial={onlySerial}
        hasSerialTracking={hasSerialTracking}
        noTracking={noTracking}
        averageCost={(item.cost ?? 0).toFixed(2)}
        totalQty={selectedInventoryRow ? selectedInventoryRow.totalQty : 0}
        committedQty={
          selectedInventoryRow ? selectedInventoryRow.committedQty : 0
        }
        validationErrors={validationErrors}
        locationQuantity={locationQuantity}
        uomIds={uomIds}
        errorMessage={qtyErrorMessage}
        defaultUomAbbreviation={item.defaultUom?.abbreviation ?? ''}
        meta={{ itemUomConversionList: item.itemUomConversionList }}
      />
      {showTrackingTable && (
        <TrackingTable
          itemTrackingTypes={item.itemTrackingTypeList}
          trackingGroups={enabledTrackingTable ? trackingGroups : []}
          onAddNewTracking={handleAddTrackingClicked}
          onSetTrackingGroups={setTrackingGroups}
          tableType={mapInventoryEventToTrackingTableType(eventType)}
          firstColumnTitle={firstColumnTitle(eventType)}
          allowSerialNumberImport={eventType === ItemInventoryEvents.Add}
          formValues={formValues}
          itemUom={formValuesUom!}
          inventoryUom={item.defaultUom}
          emptyTableText="Please select a location to begin"
          isDecimal={!hasSerialTracking}
          disableAutoAssign={autoAssignDisabled}
          headerMargin="0px 32px"
          bulkMove={eventType === ItemInventoryEvents.Move}
          eventType={eventType}
        />
      )}
      {showSerialTable ? (
        <SerialTable
          title={'Add Items'}
          minSerialQuantity={formValues.quantity || 0}
          serialList={enabledSerialTable ? serialList : []}
          allowSerialNumberImport={eventType === ItemInventoryEvents.Add}
          setSerialList={setSerialList}
          selectedRows={selectedSerialRows}
          onSelectedRowsChanged={handleSelectedChange}
          itemTrackingTypes={item.itemTrackingTypeList}
          canClearRow={canEditSerial}
          canEditRow={canEditSerial}
          canSelectRow={!canEditSerial}
          emptyTableText={
            eventType === ItemInventoryEvents.Add
              ? 'Please fill in "Amount to Add" to begin'
              : 'Please fill in "Location" and "Amount to Add" to begin'
          }
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          disableAutoAssign={autoAssignDisabled}
          quantity={formValues.quantity || 0}
          eventType={eventType}
          headerMargin="0px 32px"
        />
      ) : (
        <></>
      )}
    </Modal>
  );
};

export default memo(InventoryEventModal);
