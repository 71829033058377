import moment from 'moment';
import { OpenPick, OpenSalesOrder } from 'services/dashboard';
import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const OPEN_SALES_ORDERS_COLUMNS: (
  format: string,
  currencyFormatter: (
    value: number,
    currencyCode?: string | undefined
  ) => string
) => Column[] = (format, currencyFormatter) => [
  {
    title: 'No.',
    field: 'number',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Status',
    field: 'salesOrderStatus',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Customer',
    field: 'customer.name',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Location',
    field: 'location.name',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Date Created',
    field: 'dateCreated',
    sortable: true,
    render: (oso) => moment(oso.dateCreated || '').format(format),
    type: ColumnTypes.custom,
  },
  {
    title: 'Total',
    field: 'total',
    render: (oso: OpenSalesOrder) => currencyFormatter(oso.total || 0),
    sortable: true,
    type: ColumnTypes.custom,
  },
  {
    title: 'Paid',
    width: 192,
    field: 'paid',
    sortable: true,
    render: (oso: OpenSalesOrder) => currencyFormatter(oso.paid || 0),
    type: ColumnTypes.custom,
  },
];

export const TOP_SELLING_ITEMS_COLUMNS: Column[] = [
  {
    title: 'Name',
    field: 'name',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Barcode / UPC',
    field: 'upc',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'SKU',
    field: 'sku',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Quantity',
    field: 'quantity',
    sortable: true,
    type: ColumnTypes.string,
  },
];

export const OPEN_PICKS_COLUMNS: (format: string) => Column[] = (format) => [
  {
    title: 'Pick No.',
    field: 'number',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Status',
    field: 'status',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Location',
    field: 'location.displayName',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Schedule Date',
    field: 'scheduledDate',
    sortable: true,
    render: (op: OpenPick) => moment(op.scheduledDate || '').format(format),
    type: ColumnTypes.custom,
  },
  {
    title: 'Priority',
    width: 192,
    field: 'orderPriority.name',
    sortable: true,
    type: ColumnTypes.string,
  },
];

export const OPEN_SHIPMENTS_COLUMNS: Column[] = [
  {
    title: 'No.',
    field: 'number',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Status',
    field: 'status',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Priority',
    field: 'orderPriority.name',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Package',
    field: 'shipCartonCount',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Item Count',
    field: 'itemCount',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Carrier',
    width: 192,
    field: 'carrier.name',
    sortable: true,
    type: ColumnTypes.string,
  },
];
