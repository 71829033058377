import { defaultMaximumPagination, RequestConfig } from 'helpers';
import {
  createApiCall,
  DataWithPagination,
  paginatedApiCall,
} from 'services/api';
import { analyticsTrackEvent } from 'services/analytics/analytics';

import {
  transformRequestSalesOrder,
  transformSalesOrder,
  transformToRequestSalesOrderPayments,
} from './transformations';
import {
  CreateSalesOrderPayment,
  SalesOrder,
  SalesOrderEmail,
  SalesOrderPayment,
} from './types';

const expands = [
  'salesOrderItems.saleItem.images',
  'customer.customerAddresses.customerAddressContacts',
  'salesTax',
  'payments',
  'exchangeCurrency',
  'salesOrderItems.saleItem.item.itemTrackingTypes',
  'salesOrderItems.saleItem.item.tags',
  'salesOrderItems.saleItem.item.defaultUom.toConversions',
  'salesOrderItems.saleItem.item.defaultUom.fromConversions',
  'salesOrderItems.saleItem.defaultUom.fromConversions',
  'salesOrderItems.saleItem.defaultUom.toConversions',
  'salesOrderBundleItems.item.images',
  'salesOrderBundleItems.salesOrderItems.saleItem.defaultUom',
  'salesOrderBundleItems.salesOrderItems.saleItem.images',
  'salesOrderItems.saleItem.item.images',
];

// FF CU-863hakwb6
const optimizeExpandsFeatureFlagHelper = (
  apiUrl: string,
  featureFlagEnabled: boolean
): string => {
  if (featureFlagEnabled)
    return `${apiUrl}?expand=salesOrderBundleItems.item,salesOrderBundleItems.salesOrderItems.uom,salesOrderItems.uom,salesOrderItems.saleItem.images,salesOrderBundleItems.salesOrderItems.saleItem.images,salesOrderItems.saleItem.item.images`;
  return `${apiUrl}?expand=` + expands.join(',');
};

// SALES ORDER

export const fetchSalesOrdersAPI = async (
  config: RequestConfig
): Promise<DataWithPagination<SalesOrder>> => {
  const {
    pagination = defaultMaximumPagination,
    expands = [],
    customQuickSearchColumns = [],
    quickSearchValue,
  } = config;

  let path = `/v1/sales_orders?expand=${expands.join(',')}`;

  if (quickSearchValue) {
    path += `&quickSearchColumns=${customQuickSearchColumns}&quickSearchValue=${encodeURIComponent(
      quickSearchValue
    )}`;
  }
  const res = await paginatedApiCall(path, pagination, transformSalesOrder);

  return res;
};

export const fetchSalesOrderById = async (
  salesOrderId: number,
  expandsFeatureFlagEnabled: boolean = false
) => {
  const so = await createApiCall({
    path: optimizeExpandsFeatureFlagHelper(
      `/v1/sales_orders/${salesOrderId}`,
      expandsFeatureFlagEnabled
    ),
    method: 'GET',
  })();

  return transformSalesOrder(so.data);
};

export const getSalesOrderId = async (): Promise<number> => {
  const response = await createApiCall({
    path: '/v1/sales_orders/next_so_number',
    method: 'GET',
  })();

  return response.data;
};

export const createSalesOrder = async (
  salesOrder: SalesOrder,
  hideSnack: boolean = false
) => {
  const resp = await createApiCall(
    {
      path: `/v1/sales_orders?expand=${expands.join(',')}`,
      method: 'POST',
      body: transformRequestSalesOrder(salesOrder),
    },
    {
      getMessage: {
        success: hideSnack
          ? undefined
          : () => 'Sales order successfully created',
        error: (error: any) => error.response.data.message,
      },
    }
  )();

  analyticsTrackEvent({ eventName: 'Sales order created' });
  return transformSalesOrder(resp.data);
};

export const updateSalesOrder = async (
  salesOrder: SalesOrder,
  hideSnack: boolean = false
) => {
  const resp = await createApiCall(
    {
      path: `/v1/sales_orders?expand=${expands.join(',')}`,
      method: 'PUT',
      body: transformRequestSalesOrder(salesOrder),
    },

    {
      getMessage: {
        success: hideSnack
          ? undefined
          : () => 'Sales order successfully updated',
        error: (error: any) => error.response.data.message,
      },
    }
  )();

  return transformSalesOrder(resp.data);
};

export const deleteSalesOrder = async (salesOrderId: number) => {
  const resp = await createApiCall(
    {
      path: `/v1/sales_orders/${salesOrderId}?expand=${expands.join(',')}`,
      method: 'DELETE',
    },
    {
      getMessage: {
        success: () => 'Sales order successfully deleted',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();
  return transformSalesOrder(resp.data);
};

export const deleteSalesOrders = async (ids: number[]): Promise<void> => {
  await createApiCall(
    {
      path: `/v1/sales_orders/`,
      method: 'DELETE',
      body: ids,
    },
    {
      getMessage: {
        success: () => 'Sales orders are successfully deleted',
        error: (error: any) => error.response.data.message,
      },
    }
  )();
};

// ISSUE
export const issueSalesOrder = async (salesOrderId: number) => {
  const response = await createApiCall(
    {
      path: `/v1/sales_orders/${salesOrderId}/issue?expand=${expands.join(
        ','
      )}`,
      method: 'POST',
    },
    {
      getMessage: {
        success: () => 'Sales order successfully issued',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformSalesOrder(response.data);
};

export const unIssueSalesOrder = async (salesOrderId: number) => {
  const response = await createApiCall(
    {
      path: `/v1/sales_orders/${salesOrderId}/unissue?expand=${expands.join(
        ','
      )}`,
      method: 'POST',
    },
    {
      getMessage: {
        success: () => 'Sales order successfully unissued',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformSalesOrder(response.data);
};

export const unIssueSalesOrders = async (ids: number[]): Promise<void> => {
  await createApiCall(
    {
      path: `/v1/sales_orders/unissue`,
      method: 'POST',
      body: ids,
    },
    {
      getMessage: {
        success: (response: any) => {
          return response.data;
        },
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();
};

export const multipleIssueSalesOrders = async (
  ids: number[]
): Promise<void> => {
  await createApiCall(
    {
      path: `/v1/sales_orders/multiple_issue`,
      method: 'POST',
      body: ids,
    },
    {
      getMessage: {
        success: (response: any) => {
          return response.data;
        },
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();
};

export const fulfillSalesOrderItem = async (
  salesOrderId: number,
  salesOrderItemId: number
) => {
  const res = await createApiCall(
    {
      path: `/v1/sales_orders/${salesOrderId}/sales_order_items/${salesOrderItemId}/fulfill?expand=${expands.join(
        ','
      )}`,
      method: 'POST',
    },
    {
      getMessage: {
        success: () => 'Sales order item successfully fulfilled',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformSalesOrder(res.data);
};

// PAYMENT
export const fetchSalesOrderPaymentAPI = async (
  config: RequestConfig,
  salesOrderId: number
): Promise<DataWithPagination<SalesOrderPayment>> => {
  const { pagination = defaultMaximumPagination, expands = ['paymentType'] } =
    config;

  const path = `/v1/sales_orders/${salesOrderId}/payments?expand=${expands.join(
    ','
  )}`;

  const res = await paginatedApiCall(
    path,
    pagination,
    transformToRequestSalesOrderPayments
  );

  return res;
};

export const createPayment = (
  salesOrderId: number,
  item: CreateSalesOrderPayment
) =>
  createApiCall(
    {
      path: `/v1/sales_orders/${salesOrderId}/payments`,
      method: 'POST',
      body: item,
    },
    {
      getMessage: {
        success: () => 'New Payment successfully added',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

export const sendSalesOrderEmail = async (
  soEmail: SalesOrderEmail,
  soNumber: string,
  file?: Blob
): Promise<void> => {
  const formData = new FormData();
  const payloadJson = JSON.stringify(soEmail);
  formData.append('bodyString', payloadJson);

  if (file) {
    formData.append('file', file, `SO_${soNumber}.pdf`);
  }

  await createApiCall(
    {
      path: `/email`,
      method: 'POST',
      body: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    },
    {
      getMessage: {
        success: () => 'E-mail successfully sent',
        error: (error: any) => error.response.data.message,
      },
    }
  )();
};

export const refundSalesOrderPayment = async (
  salesOrderId: number,
  paymentId: number,
  amount: number | null
) => {
  await createApiCall(
    {
      path: `/v1/sales_orders/${salesOrderId}/payments/${paymentId}/refund`,
      method: 'POST',
      body: { amount },
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
        success: () => 'Payment refunded',
      },
    }
  )();
};

export const restoreSalesOrder = async (salesOrderId: number) => {
  await createApiCall(
    {
      path: `/v1/sales_orders/${salesOrderId}/restore`,
      method: 'POST',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
        success: () => 'Sales order successfully restored',
      },
    }
  )();
};

export const cancelSalesOrder = async (salesOrderId: number) => {
  await createApiCall(
    {
      path: `/v1/sales_orders/${salesOrderId}/cancel`,
      method: 'POST',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
        success: () => 'Sales order successfully cancelled',
      },
    }
  )();
};

// QUICK FULFILL
export const quickFulfillSalesOrder = async (salesOrderId: number) => {
  const response = await createApiCall(
    {
      path: `/v1/sales_orders/${salesOrderId}/quick_fulfill?expand=${expands.join(
        ','
      )}`,
      method: 'POST',
    },
    {
      getMessage: {
        success: () => 'Sales order successfully fulfilled',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformSalesOrder(response.data);
};
