import React, { memo } from 'react';
import { TableCell, Box } from '@mui/material';
import _ from 'lodash';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { ImageItemBox } from 'ui/components/Image/ImageItemBox';

const OrdersStepRow: React.FC<RenderCustomRowProps> = (props) => {
  const { row } = props;

  const item = row.item;
  const itemUom = _.get(item, 'defaultUom.abbreviation', '');

  return (
    <>
      <TableCell>
        <Box>
          <ImageItemBox
            source={_.get(item, 'defaultImage', null)}
            name={_.get(item, 'name', null)}
            description={_.get(item, 'description', null)}
          />
        </Box>
      </TableCell>
      <TableCell>
        <Box>{row.quantity || 0}</Box>
      </TableCell>
      <TableCell>
        <Box>{itemUom}</Box>
      </TableCell>
    </>
  );
};

export default memo(OrdersStepRow);
