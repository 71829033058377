import {
  getSearches,
  addSearch,
  updateSearch,
  deleteSearch,
} from 'services/uoms';
import { PaginationDirection } from 'services/url';
import {
  AdvancedSearchFormValues,
  AdvancedSearchReduxFunctions,
} from 'services/search';
import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';
import { Dates } from 'helpers';

export const advancedSearchReduxActions: AdvancedSearchReduxFunctions = {
  add: addSearch,
  delete: deleteSearch,
  update: updateSearch,
  get: getSearches,
};

export const initialUomASFormValues: AdvancedSearchFormValues = {
  abbreviation: null,
  name: null,
  description: null,
  deleted: null,
};

export const displayNameMap = {
  name: 'Name',
  abbreviation: 'Abbreviation',
  description: 'Description',
  deleted: 'Deleted',
};

export const UOM_COLUMNS = (date: Dates): Column[] => {
  const resolveDateColumn = (): Column => {
    switch (date) {
      case Dates.DateLastModified:
        return {
          title: 'Date Last Modified',
          field: Dates.DateLastModified,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
        };
      default:
        return {
          title: 'Date Created',
          field: Dates.DateCreated,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
        };
    }
  };

  return [
    {
      title: 'Name',
      field: 'name',
      sortable: true,
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: 'Abbreviation',
      field: 'abbreviation',
      sortable: true,
      type: ColumnTypes.renderByCustomRow,
    },
    resolveDateColumn(),
  ];
};

export const initialPagination = {
  page: 1,
  pageSize: 25,
  sort: {
    sortBy: 'dateLastModified',
    direction: PaginationDirection.Descending,
  },
  totalRows: 25,
};
