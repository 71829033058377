import React from 'react';
import { Typography } from '@mui/material';

import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';
import { CustomFieldType } from 'services/customFields';

export const customFieldTypeTableCell = (
  customFieldType: CustomFieldType | null
) => {
  switch (customFieldType) {
    case CustomFieldType.Boolean:
      return <Typography>Checkbox</Typography>;
    case CustomFieldType.Url:
      return <Typography>URL</Typography>;
    default:
      return <Typography>{customFieldType}</Typography>;
  }
};

export const customFieldsOrderModalColumns: Column[] = [
  {
    title: 'Name',
    field: 'name',
    type: ColumnTypes.string,
  },
  {
    title: 'Type',
    field: 'customFieldType',
    type: ColumnTypes.custom,
    render: (row) => {
      return (
        <Typography>{customFieldTypeTableCell(row.customFieldType)}</Typography>
      );
    },
  },
];
