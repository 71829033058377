import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { ConfirmationModalStyleProps } from './types';

export const useModalStyle = makeStyles<Theme, ConfirmationModalStyleProps>(
  (theme: Theme) => ({
    label: {
      color: theme.palette.text.primary,
    },
    cancelButton: {
      width: '100%',
    },
    confirmButton: {
      minWidth: theme.spacing(15.5),
      backgroundColor: ({ confirmButtonRed }) =>
        confirmButtonRed
          ? theme.palette.error.main
          : theme.palette.primary.main,
      '&:hover, &:active, &:focus': {
        backgroundColor: ({ confirmButtonRed }) =>
          confirmButtonRed
            ? theme.palette.error.main
            : theme.palette.primary.main,
      },
    },
    title: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(4),
    },
    titleText: {
      fontWeight: 'bold',
    },
    content: {
      padding: 0,
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    action: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    closeButton: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      color: theme.palette.text.primary,
    },
  })
);
