import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Divider } from '@mui/material';

import {
  getWaitingToExport,
  initialToExportData,
  WaitingToExport,
  WaitingToExportLedgers,
} from 'services/accounting';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { markAsPosted } from 'services/ledgers';
import { Paper } from 'ui/components/Paper/Paper';
import { showNotification } from 'services/api/notifications';
import { showProgressAlert } from 'services/alert/redux';
import {
  BackgroundAction,
  BackgroundType,
  startBackgroundExport,
  useBackgroundTasks,
} from 'services/backgroundTasks';
import { getQuickbooksAccountIsConnected } from 'services/integrations/quickbooks';
import {
  getXeroAccountIsConnected,
  exportToXero,
} from 'services/integrations/xero';

import { useCardStyle } from '../../styled';
import { CardWaitingToExportProps } from './types';
import { logErrorCtx } from 'app/logging';
import { useFlags } from 'helpers/useFlags';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { IconNames, themeRestyle } from 'ui/theme';
import { WaitingToExportDetailsModal } from './components/WaitingToExportDetailsModal';
import { PermissionType } from 'services/permissions';

const CardWaitingToExport: React.FC<CardWaitingToExportProps> = (props) => {
  const { onMappingClicked, onTaxMappingClicked, isInternationalConnected } =
    props;

  const [isLoading, setIsLoading] = useState(false);
  const [toExportData, setToExportData] = useState(initialToExportData);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [waitingToExportModalLedgerType, setWaitingToExportModalLedgerType] =
    useState<WaitingToExportLedgers | null>(null);
  const [showWaitingToExportDetailsModal, setShowWaitingToExportDetailsModal] =
    useState<boolean>(false);
  const { backgroundTasks } = useBackgroundTasks();

  const dispatch = useDispatch();

  const classes = useCardStyle();

  const quickbooksConnected = useSelector(getQuickbooksAccountIsConnected);
  const xeroConnected = useSelector(getXeroAccountIsConnected);
  const flags = useFlags();
  const integrationName = quickbooksConnected ? 'QuickBooks Online' : 'Xero';

  useEffect(() => {
    (async () => {
      try {
        const data = await getWaitingToExport();
        setToExportData(data);
      } catch (e) {
        const error = e as Error;
        logErrorCtx('Error in CardWaitingToExport', {
          error,
          stackTrace: error.stack,
          component: 'CardWaitingToExport',
        });
      }
    })();
  }, [backgroundTasks]);

  const handleMarkAsPosted = async () => {
    setIsLoading(true);
    try {
      await markAsPosted();
      const data = await getWaitingToExport();
      setToExportData(data);
    } catch (err) {
      const error = err as Error;
      logErrorCtx('Not Marked as Posted or Exported', {
        error,
        stackTrace: error.stack,
        title: 'Unable to mark as posted or exported',
        description: 'Account settings not marked as posted',
        component: 'CardWaitingToExport',
      });
    }
    setIsLoading(false);
    setShowModal(false);
  };

  const exportToQuickbooks = useCallback(async () => {
    const exportFilled =
      toExportData.bills ||
      toExportData.inventoryAdjustments ||
      toExportData.invoices ||
      toExportData.itemReceipts ||
      toExportData.payments ||
      toExportData.creditMemos ||
      toExportData.vendorCredits ||
      toExportData.journalEntries;

    if (exportFilled) {
      try {
        await startBackgroundExport(BackgroundType.Qbo);
        dispatch(
          showProgressAlert(
            'Your Export to QuickBooks has been initiated.  This may take a few minutes to complete.',
            BackgroundType.Qbo,
            BackgroundAction.Export
          )
        );
      } catch {
        return;
      }
    } else {
      showNotification('Nothing to export!', { variant: 'error' });
    }
  }, [dispatch, toExportData]);
  const exportToXeroIntegration = useCallback(async () => {
    exportToXeroIntegrationCallback(toExportData, setToExportData);
  }, [toExportData]);

  const openWaitingToExportModal = (ledgerType: WaitingToExportLedgers) => {
    setWaitingToExportModalLedgerType(ledgerType);
    setShowWaitingToExportDetailsModal(true);
  };

  const handleWaitingToExportDetailsModalClose = () => {
    setShowWaitingToExportDetailsModal(false);
    setWaitingToExportModalLedgerType(null);
  };

  return (
    <Box className={classes.container}>
      <Paper id="waitingToExportId" className={classes.paper}>
        <FBOTitleBar title="Waiting To Export">
          {isInternationalConnected && flags.intlTaxRates && (
            <FBOButton
              sx={{ marginRight: themeRestyle.spacing(1) }}
              onClick={onTaxMappingClicked}
              data-qa="accounting-account-mapping"
              variant="secondary"
              color="positive"
              size="medium"
              permissions={[PermissionType.AccountingEdit]}
            >
              Tax Mapping
            </FBOButton>
          )}
          <FBOButton
            sx={{ marginRight: themeRestyle.spacing(1) }}
            onClick={onMappingClicked}
            data-qa="accounting-account-mapping"
            variant="secondary"
            color="positive"
            size="medium"
            permissions={[PermissionType.AccountingEdit]}
          >
            Account Mapping
          </FBOButton>

          {quickbooksConnected && (
            <FBOButton
              sx={{ marginRight: themeRestyle.spacing(1) }}
              onClick={exportToQuickbooks}
              icon={IconNames.FBOExport}
              variant="primary"
              color="positive"
              size="medium"
              data-qa="accounting-export"
              permissions={[PermissionType.AccountingEdit]}
            >
              Export
            </FBOButton>
          )}
          {xeroConnected && (
            <FBOButton
              sx={{ marginRight: themeRestyle.spacing(1) }}
              onClick={exportToXeroIntegration}
              icon={IconNames.FBOExport}
              variant="primary"
              color="positive"
              size="medium"
              data-qa="accounting-export-xero"
              data-testid="bt-xero-export"
              permissions={[PermissionType.AccountingEdit]}
            >
              Export
            </FBOButton>
          )}
        </FBOTitleBar>
        <Box className={classes.rowContainer}>
          <Box className={classes.titleContainer}>
            <Typography>{'Inventory Adjustments'}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.text} noWrap>
              {toExportData.inventoryAdjustments}
            </Typography>
            <FBOButton
              variant="tertiary"
              color="neutral"
              size="large"
              icon={IconNames.FBOCaratRight}
              data-qa="inventory-adjustments-waiting-to-export-button"
              disabled={toExportData.inventoryAdjustments === 0}
              onClick={() =>
                openWaitingToExportModal(
                  WaitingToExportLedgers.InventoryAdjustments
                )
              }
            />
          </Box>
        </Box>
        <Divider />

        <Box className={classes.rowContainer}>
          <Box className={classes.titleContainer}>
            <Typography>{'Invoices'}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.text} noWrap>
              {toExportData.invoices}
            </Typography>
            <FBOButton
              variant="tertiary"
              color="neutral"
              size="large"
              icon={IconNames.FBOCaratRight}
              data-qa="invoices-waiting-to-export-button"
              disabled={toExportData.invoices === 0}
              onClick={() =>
                openWaitingToExportModal(WaitingToExportLedgers.Invoices)
              }
            />
          </Box>
        </Box>
        <Divider />

        <Box className={classes.rowContainer}>
          <Box className={classes.titleContainer}>
            <Typography>{'Credit Memos'}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.text} noWrap>
              {toExportData.creditMemos}
            </Typography>
            <FBOButton
              variant="tertiary"
              color="neutral"
              size="large"
              icon={IconNames.FBOCaratRight}
              data-qa="credit-memos-waiting-to-export-button"
              disabled={toExportData.creditMemos === 0}
              onClick={() =>
                openWaitingToExportModal(WaitingToExportLedgers.CreditMemos)
              }
            />
          </Box>
        </Box>
        <Divider />

        <Box className={classes.rowContainer}>
          <Box className={classes.titleContainer}>
            <Typography>{'Payments'}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.text} noWrap>
              {toExportData.payments}
            </Typography>
            <FBOButton
              variant="tertiary"
              color="neutral"
              size="large"
              icon={IconNames.FBOCaratRight}
              data-qa="payments-waiting-to-export-button"
              disabled={toExportData.payments === 0}
              onClick={() =>
                openWaitingToExportModal(WaitingToExportLedgers.Payments)
              }
            />
          </Box>
        </Box>
        <Divider />

        <Box className={classes.rowContainer}>
          <Box className={classes.titleContainer}>
            <Typography>{'Item Receipts'}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.text} noWrap>
              {toExportData.itemReceipts}
            </Typography>
            <FBOButton
              variant="tertiary"
              color="neutral"
              size="large"
              icon={IconNames.FBOCaratRight}
              data-qa="item-receipts-waiting-to-export-button"
              disabled={toExportData.itemReceipts === 0}
              onClick={() =>
                openWaitingToExportModal(WaitingToExportLedgers.ItemReceipts)
              }
            />
          </Box>
        </Box>
        <Divider />

        <Box className={classes.rowContainer}>
          <Box className={classes.titleContainer}>
            <Typography>{'Bills'}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.text} noWrap>
              {toExportData.bills}
            </Typography>
            <FBOButton
              variant="tertiary"
              color="neutral"
              size="large"
              icon={IconNames.FBOCaratRight}
              data-qa="bills-waiting-to-export-button"
              disabled={toExportData.bills === 0}
              onClick={() =>
                openWaitingToExportModal(WaitingToExportLedgers.Bills)
              }
            />
          </Box>
        </Box>
        <Divider />

        <Box className={classes.rowContainer}>
          <Box className={classes.titleContainer}>
            <Typography>{'Vendor Credits'}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.text} noWrap>
              {toExportData.vendorCredits}
            </Typography>
            <FBOButton
              variant="tertiary"
              color="neutral"
              size="large"
              icon={IconNames.FBOCaratRight}
              data-qa="vendor-credits-waiting-to-export-button"
              disabled={toExportData.vendorCredits === 0}
              onClick={() =>
                openWaitingToExportModal(WaitingToExportLedgers.VendorCredits)
              }
            />
          </Box>
        </Box>
        <Divider />

        <Box className={classes.rowContainer}>
          <Box className={classes.titleContainer}>
            <Typography>{'Journal Entries'}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.text} noWrap>
              {toExportData.journalEntries}
            </Typography>
            <FBOButton
              variant="tertiary"
              color="neutral"
              size="large"
              icon={IconNames.FBOCaratRight}
              data-qa="journal-entries-waiting-to-export-button"
              disabled={toExportData.journalEntries === 0}
              onClick={() =>
                openWaitingToExportModal(WaitingToExportLedgers.JournalEntries)
              }
            />
          </Box>
        </Box>
        <Divider />

        <Box className={classes.rowContainer}>
          <Box className={classes.titleContainer}>
            <Typography>
              {' '}
              {`Don't want to export to ${integrationName}?`}
            </Typography>
          </Box>
          <FBOButton
            onClick={() => setShowModal(true)}
            data-qa="accounting-mark-as-posted"
            variant="tertiary"
            color="positive"
            size="medium"
            permissions={[PermissionType.AccountingEdit]}
          >
            Mark as posted
          </FBOButton>
        </Box>
      </Paper>

      <WaitingToExportDetailsModal
        ledgerType={waitingToExportModalLedgerType}
        open={showWaitingToExportDetailsModal}
        onClose={handleWaitingToExportDetailsModalClose}
        integrationName={integrationName}
        setToExportData={setToExportData}
      />

      <ConfirmationModal
        body={`This will mark all available ${integrationName} exports as already
          posted. Continue?`}
        maxWidth="sm"
        open={showModal}
        title="Confirm Mark As Posted"
        onCancelClicked={() => setShowModal(false)}
        onConfirmClicked={handleMarkAsPosted}
        isLoading={isLoading}
        confirmLabel="Continue"
      />
    </Box>
  );
};

export default memo(CardWaitingToExport);
export const exportToXeroIntegrationCallback = async (
  toExportData: WaitingToExport,
  setToExportData: React.Dispatch<React.SetStateAction<any>>
) => {
  const exportFilled =
    toExportData.bills ||
    toExportData.inventoryAdjustments ||
    toExportData.invoices ||
    toExportData.itemReceipts ||
    toExportData.payments ||
    toExportData.creditMemos ||
    toExportData.vendorCredits ||
    toExportData.journalEntries;
  if (exportFilled) {
    try {
      await exportToXero();

      const data = await getWaitingToExport();
      setToExportData(data);
    } catch {
      const data = await getWaitingToExport();
      setToExportData(data);
      return;
    }
  } else {
    showNotification('Nothing to export!', { variant: 'error' });
  }
};
