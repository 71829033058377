import { makeStyles } from '@mui/styles';
import { colorPalette } from 'ui/theme';
export const useQuickTransferTableCellStyle = makeStyles(() => ({
  cell: {
    verticalAlign: 'inherit',
    '& .aligned-link': {
      display: 'inline-flex',
      height: 38,
    },
  },
  errorIcon: {
    color: colorPalette.redesign.redPrimary,
    marginRight: '4px',
    fontSize: '1.2rem',
  },
  itemCell: {
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  deleteRowCell: {
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: 1,
    backgroundColor: 'blue',
  },
}));

export const useQuickTransferFormStyle = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    margin: '0',
  },
  magnifyingGlassIcon: {
    display: 'flex',
    alignItems: 'flex-start',
    marginLeft: '8px',
    borderRadius: '3px',
  },
  lockIconPosition: {
    position: 'absolute',
    left: '-1px',
    top: '50%',
    transform: 'translateY(-50%)',
    marginRight: '8px',
  },
  topText: {
    color: '#547089',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '20px',
    fontFamily: 'Inter, sans-serif',
    display: 'block',
  },
}));

export const useQuickTransferModalStyle = makeStyles(() => ({
  container: {
    width: '100%',
    backgroundColor: colorPalette.redesign.background1,
    padding: '16px 16px 0',
  },
  errorMessage: {
    color: colorPalette.redesign.redPrimary,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    margin: '8px 0',
  },
  errorIcon: {
    color: colorPalette.redesign.redPrimary,
    marginRight: '4px',
    fontSize: '1.2rem',
  },
}));
export const useBulkMoveModalStyle = makeStyles(() => ({
  topText: {
    marginTop: '10px',
    color: '#547089',
    fontSize: '14px',
    fontWeight: 'semibold',
    lineHeight: '20px',
  },
  bottomText: {
    fontSize: '14px',
    marginBottom: '10px',
    padding: '12px 4px',
  },

  chipSuccess: {
    backgroundColor: colorPalette.statusGreen,
    background: 'transparent',
    text: 'transparent',
  },
  chipWarning: {
    backgroundColor: colorPalette.statusOrange,
    background: 'transparent',
    text: 'transparent',
  },
}));
