import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useThumbnailModalStyle = makeStyles<Theme>(() => ({
  image: {
    width: '650px',
    height: 'auto',
    margin: 'auto',
  },
}));
