import {
  createApiReducer,
  chainReducers,
  INITIAL_API_STATUS,
} from 'services/api';
import {
  createAddSearchReducer,
  createDeleteSearchReducer,
  createFetchSearchReducer,
  createUpdateSearchReducer,
} from 'services/search';
import { combineReducers } from 'redux';

import { CarriersActionTypes } from './actions';
import { transformCarrier } from '../transformations';

const initialState = {
  _status: INITIAL_API_STATUS,
  items: [],
};

const carrierReducer = createApiReducer(
  [
    CarriersActionTypes.FETCH_CARRIERS_START,
    CarriersActionTypes.FETCH_CARRIERS_SUCCESS,
    CarriersActionTypes.FETCH_CARRIERS_FAILURE,
  ],
  (data) => ({ items: data.results.map(transformCarrier) }),
  (data) => data
);

const fetchSearchReducer = createFetchSearchReducer('carrier');
const addSearchReducer = createAddSearchReducer('carrier');
const updateSearchReducer = createUpdateSearchReducer('carrier');
const deleteSearchReducer = createDeleteSearchReducer('carrier');

const carrierChainedReducers = chainReducers(initialState, carrierReducer);

const searchChainedReducers = chainReducers(
  initialState,
  fetchSearchReducer,
  addSearchReducer,
  updateSearchReducer,
  deleteSearchReducer
);

export default combineReducers({
  carriers: carrierChainedReducers,
  searches: searchChainedReducers,
});
