import { ChannelSettings } from './types';
import { ChannelVendors } from 'ui/modules/commerce/pages/MyChannelsPage/types';
import { Channel } from './types';

const transformVendorName = (vendor: string): string => {
  switch (vendor) {
    case 'AMAZON':
    case 'amazon':
      return ChannelVendors.Amazon;
    case 'BIGCOMMERCE':
    case 'bigcommerce':
      return ChannelVendors.BigCommerce;
    case 'EBAY':
    case 'ebay':
      return ChannelVendors.Ebay;
    case 'SHOPIFY':
    case 'shopify':
      return ChannelVendors.Shopify;
    case 'WALMART':
    case 'walmart':
      return ChannelVendors.Walmart;
    case 'WOOCOMMERCE':
    case 'woocommerce':
      return ChannelVendors.WooCommerce;
    default:
      return '';
  }
};

export const transformChannel = (resp: any): Channel => {
  return {
    id: resp.id,
    vendor: transformVendorName(resp.vendor),
    label: resp.label,
    channelType: resp.channelType,
    syncedOn: resp.syncedOn,
    status: resp.status,
    configuration: resp.configuration,
  };
};

export const transformToChannelSettingsPut = (
  channelSettings: ChannelSettings
) => {
  return {
    orderSettings: {
      orderNumberAssignment:
        channelSettings.orderSettings.orderNumberAssignment,
      orderNumberPrefix: channelSettings.orderSettings.orderNumberPrefix,
      defaultLocation: channelSettings.orderSettings.defaultLocation,
      shippingItem: channelSettings.orderSettings.shippingItem,
      taxItem: channelSettings.orderSettings.taxItem,
      status: channelSettings.orderSettings.status,
      shippingTerms: channelSettings.orderSettings.shippingTerms,
      accountingClass: channelSettings.orderSettings.accountingClass,
    },
    productImportSettings: {
      accountingClass: channelSettings.productImportSettings.accountingClass,
      uom: channelSettings.productImportSettings.uom,
      dimensionUom: channelSettings.productImportSettings.dimensionUom,
      weightUom: channelSettings.productImportSettings.weightUom,
    },
    customerSettings: {
      defaultPaymentTerms: channelSettings.customerSettings.defaultPaymentTerms,
      priority: channelSettings.customerSettings.priority,
      salesRepresentative: channelSettings.customerSettings.salesRepresentative,
    },
    posSettings: {
      defaultLocation: channelSettings.posSettings?.defaultLocation,
      taxItem: channelSettings.posSettings?.taxItem,
      shippingItem: channelSettings.posSettings?.shippingItem,
      shippingTerms: channelSettings.posSettings?.shippingTerms,
      status: channelSettings.posSettings?.status,
      orderNumberAssignment: channelSettings.posSettings?.orderNumberAssignment,
    },
  };
};

export const transformChannelSettings = (resp: any): ChannelSettings => {
  return {
    orderSettings: {
      orderNumberAssignment: resp?.orderSettings?.orderNumberAssignment,
      orderNumberPrefix: resp?.orderSettings?.orderNumberPrefix,
      defaultLocation: resp?.orderSettings?.defaultLocation,
      shippingItem: resp?.orderSettings?.shippingItem,
      taxItem: resp?.orderSettings?.taxItem,
      status: resp?.orderSettings?.status,
      shippingTerms: resp?.orderSettings?.shippingTerms,
      accountingClass: resp?.orderSettings?.accountingClass,
    },
    productImportSettings: {
      accountingClass: resp.productImportSettings?.accountingClass,
      uom: resp.productImportSettings?.uom,
      dimensionUom: resp.productImportSettings?.dimensionUom,
      weightUom: resp.productImportSettings?.weightUom,
    },
    customerSettings: {
      defaultPaymentTerms: resp.customerSettings?.defaultPaymentTerms,
      priority: resp.customerSettings?.priority,
      salesRepresentative: resp.customerSettings?.salesRepresentative,
    },
    posSettings: {
      defaultLocation: resp.posSettings?.defaultLocation,
      taxItem: resp.posSettings?.taxItem,
      shippingItem: resp.posSettings?.shippingItem,
      shippingTerms: resp.posSettings?.shippingTerms,
      status: resp.posSettings?.status,
      orderNumberAssignment: resp.posSettings?.orderNumberAssignment,
    },
  };
};
