import React, { useMemo } from 'react';
import { Box, Grid, List, ListItem, Typography } from '@mui/material';

import { Modal } from 'ui/components/Modal/Modal';

import { COUNTRIES } from 'ui/components/Autocomplete/CountryAutocomplete';

import { AddressValidationModalProps } from './types';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const AddressValidationModal: React.FC<AddressValidationModalProps> = (
  props
) => {
  const {
    modalVisible,
    onClose,
    onSaveOriginal,
    onSaveVerified,
    addressValidation,
  } = props;

  const { original: originalAddress, validated: verifiedAddress } =
    addressValidation;

  const originalCountry = useMemo(() => {
    return COUNTRIES.find((c) => c.code === originalAddress.country) || null;
  }, [originalAddress.country]);

  const verifiedCountry = useMemo(() => {
    return COUNTRIES.find((c) => c.code === verifiedAddress.country) || null;
  }, [verifiedAddress.country]);

  const FBOModalActions = () => (
    <Box display="flex" justifyContent="flex-end" width="100%">
      <Box mr={2}>
        <FBOButton
          variant="secondary"
          color="positive"
          size="medium"
          onClick={onClose}
          data-qa="address-validation-modal-cancel-button"
        >
          Cancel
        </FBOButton>
      </Box>

      <Box mr={2}>
        <FBOButton
          variant="primary"
          color="positive"
          size="medium"
          onClick={onSaveOriginal}
          data-qa="address-validation-modal-use-original-button"
        >
          Use original
        </FBOButton>
      </Box>

      <FBOButton
        variant="primary"
        color="positive"
        size="medium"
        onClick={onSaveVerified}
        disabled={!addressValidation.isValid}
        data-qa="address-validation-modal-use-verified-button"
      >
        Use verified
      </FBOButton>
    </Box>
  );

  if (!modalVisible) {
    return null;
  }

  return (
    <Modal
      open={modalVisible}
      title="Verify address"
      onCancelClicked={onClose}
      ModalActionsComponent={FBOModalActions}
    >
      <Box p={4} width="100%">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box mb={2}>
              <Typography align="left">
                <b>Original Address</b>
              </Typography>
            </Box>

            <Box mb={2}>
              <Typography align="left">{originalAddress.name}</Typography>
            </Box>
            <Box mb={2}>
              <Typography align="left">
                {originalAddress.companyName}
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography align="left">{originalAddress.attention}</Typography>
            </Box>
            <Box mb={2}>
              <Typography align="left">{originalAddress.street}</Typography>
            </Box>
            <Box mb={2}>
              <Typography align="left">{originalAddress.street2}</Typography>
            </Box>
            <Box mb={2}>
              <Typography align="left">
                {originalAddress.city}, {originalAddress.state},{' '}
                {originalAddress.postalCode}
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography align="left">
                {originalCountry ? originalCountry.label : ''}
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography align="left">
                {originalAddress.residential ? 'Residential Address' : ''}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            {!addressValidation.isValid ? (
              <>
                <Box mb={2}>
                  <Typography align="center">
                    <b>Error messages</b>
                  </Typography>
                </Box>
                <List>
                  {addressValidation.messages.map((msg, i) => (
                    <ListItem style={{ color: 'red' }} key={`msg_${i}`}>
                      {msg}
                    </ListItem>
                  ))}
                </List>
              </>
            ) : (
              <>
                <Box mb={2}>
                  <Typography align="left">
                    <b>Verified Address</b>
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography align="left">
                    <b>{verifiedAddress.name}</b>
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography align="left">
                    <b>{verifiedAddress.companyName}</b>
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography align="left">
                    <b>{verifiedAddress.attention}</b>
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography align="left">
                    <b>{verifiedAddress.street}</b>
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography align="left">
                    <b>{verifiedAddress.street2}</b>
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography align="left">
                    <b>
                      {verifiedAddress.city}, {verifiedAddress.state},{' '}
                      {verifiedAddress.postalCode}
                    </b>
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography align="left">
                    <b>{verifiedCountry ? verifiedCountry.label : ''}</b>
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography align="left">
                    <b>
                      {verifiedAddress.residential ? 'Residential Address' : ''}
                    </b>
                  </Typography>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AddressValidationModal;
