import React, { useCallback } from 'react';
import { TableCell } from '@mui/material';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import { CustomField, CustomFieldType } from 'services/customFields';
import { colorPalette } from 'ui/theme';

import { ActionBarOptions } from './consts';
import { customFieldTypeTableCell } from './components/CustomFieldsOrderModal/consts';
import { CustomRowActions } from './types';

import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';
import FBOSwitch from 'ui/theme/components/FBOSwitch/FBOSwitch';

const CustomRow: React.FC<RenderCustomRowProps<CustomField>> = (props) => {
  const { row, index, setData, onAction } = props;

  const switchChanged = useCallback(() => {
    setData({ ...row, required: !row.required }, index);
  }, [row, index, setData]);

  const onRowAction = useCallback(
    (actionType: CustomRowActions) => {
      if (onAction) {
        onAction({ type: actionType, payload: row });
      }
    },
    [row, onAction]
  );

  const showRequiredSwitch = row.customFieldType !== CustomFieldType.Boolean;

  return (
    <>
      <TableCell>{row.name}</TableCell>
      <TableCell>{customFieldTypeTableCell(row.customFieldType)}</TableCell>
      <TableCell
        onClick={(ev: any) => ev.stopPropagation()} // This will stop table onItemClick event
      >
        {showRequiredSwitch && (
          <FBOSwitch
            name="required"
            size="medium"
            checked={row.required}
            onChange={switchChanged}
            dataQa="custom-field-required-toggle"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        )}
      </TableCell>
      <TableCell padding="none" align="center">
        <FBOMenuButton
          variant="tertiary"
          items={ActionBarOptions(onRowAction)}
          data-qa="custom-field-three-dot-menu"
          style={{ color: colorPalette.redesign.contentSecondary }}
        />
      </TableCell>
    </>
  );
};

export default React.memo(CustomRow);
