import React, { memo, useCallback, useEffect, useState } from 'react';
import { Grid, Box } from '@mui/material';

import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import { postReportTheme } from 'services/reports';

import { ReportsThemePresetModalProps } from './types';
import { showNotification } from 'services/api';
import { logErrorCtx } from 'app/logging';

const ThemeBuilderPresetModal: React.FC<ReportsThemePresetModalProps> = (
  props
) => {
  const { show, onClose, reportTheme, setThemes, setReportTheme } = props;

  const [name, setName] = useState<string | null>(null);
  const [nameError, setNameError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (show) {
      setName(reportTheme.name);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const handleSaveClicked = async () => {
    if (!name) {
      setNameError(true);
      return;
    }

    setIsLoading(true);

    try {
      const newTheme = await postReportTheme({
        ...reportTheme,
        name,
      });

      showNotification('Successfully created theme', {
        variant: 'success',
      });

      setThemes((old) => [...old, newTheme]);
      setReportTheme(newTheme);
      setName('');
    } catch (e) {
      showNotification('Something went wrong', {
        variant: 'error',
      });
      const error = e as Error;
      logErrorCtx('Error while creating a new reports theme', {
        error,
        stackTrace: error.stack,
        title: error.message,
        description:
          'Error when trying to create a new reports theme in ThemeBuilderPresetModal',
        component: 'ThemeBuilderPresetModal',
      });
    }
    setIsLoading(false);
    onClose();
  };

  const handleNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setName(event.target.value || null);
    },
    []
  );

  return (
    <Modal
      open={show}
      onCancelClicked={onClose}
      onApplyClicked={handleSaveClicked}
      title="Theme modal"
      applyLabel="save"
      maxWidth="sm"
      withoutDefaultPadding
      isLoading={isLoading}
    >
      <Box p={4} pb={2} overflow="hidden">
        <Grid container spacing={2}>
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Theme name"
                placeholder="Enter name"
                value={name}
                onChange={handleNameChange}
                error={nameError}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default memo(ThemeBuilderPresetModal);
