import * as yup from 'yup';

export const orderSchema = yup.object({
  location: yup.number().typeError('Location is required'),
  taxItem: yup.number(),
  shippingItem: yup.number().typeError('Shipping item is required'),
  shippingTerms: yup.number().typeError('Shipping term is requred'),
  status: yup.string().typeError('Status is required'),
});

export const customerSchema = yup.object({
  customer: yup.string().required(),
  paymentTerms: yup.mixed().when('useDefault', {
    is: true,
    then: yup.number(),
  }),
  priority: yup.mixed().when('useDefault', {
    is: true,
    then: yup.string(),
  }),
  representative: yup.mixed().when('useDefault', {
    is: true,
    then: yup.string(),
  }),
});

export const itemSchema = yup.object({
  newName: yup.object(),
  newDescription: yup.object(),
  newSku: yup.object(),
  uom: yup.number(),
  dimensionsUnit: yup.string(),
  weightUnit: yup.string(),
});

export const shopifySchema = yup.object({
  customer: customerSchema,
  item: itemSchema,
  order: orderSchema,
});

export const inventorySchema = yup.object({
  update: yup.boolean(),
  locationMapping: yup.mixed().when('update', {
    is: true,
    then: yup
      .array()
      .of(
        yup.object({
          fishbowlLocation: yup.array().of(yup.number()).required(),
          shopifyLocation: yup.object({
            id: yup.number(),
            value: yup.string(),
          }),
        })
      )
      .required(),
  }),
});

export const wooCommerceInventorySchema = yup.object({
  update: yup.boolean(),
  locations: yup.mixed().when('update', {
    is: true,
    then: yup.array().of(yup.number()).required(),
  }),
});
