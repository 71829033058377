import { State } from 'app/redux';

export const getTaxRates = (state: State) => state.taxRate.taxRates;

export const getSearches = (state: State) => state.taxRate.searches;

export const getTaxRateById = (id: number | null) => (state: State) => {
  if (id === null) {
    return undefined;
  }
  return state.taxRate.taxRates.items.find((item) => item.id === id);
};
