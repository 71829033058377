import moment from 'moment';
import { DateRange } from 'materialui-daterange-picker';

import { defaultMaximumPagination, RequestConfig } from 'helpers';
import {
  createApiCall,
  DataWithPagination,
  paginatedApiCall,
} from 'services/api';
import { InventoryRow, ItemTrackingType } from 'services/inventory';
import { analyticsTrackEvent } from 'services/analytics/analytics';
import { deleteImages } from 'services/images';
import { VendorItem } from 'services/vendors';

import {
  transformInventoryRowsToExistingItem,
  transformItem,
  transformItemToPostItem,
  transformItemToPutItem,
  tranformItemByLocation,
  transformItemLocation,
} from './transformations';
import { Item, ItemType, ItemByLocation, ItemLocation } from './types';
import { Pagination } from 'services/search';

const defaultExpands = [
  'itemTrackingTypes.trackingType',
  'saleItems.images',
  'bundleItems.saleItem.images',
  'images',
  'itemUomConversions',
  'itemLocations',
  'reorderPoints.reorderPointAlerts',
  'reorderPoints.location',
  'tags',
  'saleItems.tags',
  'defaultUom.toConversions',
  'defaultUom.fromConversions',
  'vendorItems.vendor',
  'substituteItems',
  'substituteItems.substituteItem',
  'itemSubstitutes',
  'itemSubstitutes.substituteItem',
  'itemSubstitutes.substituteItem.images',
  'salesTax',
];

export const fetchItemsAPI = async (
  config: RequestConfig,
  itemTypes: ItemType[] = [],
  ids: number[] = [],
  hideZeroQuantityItems: boolean = false,
  onlySaleItems: boolean = false,
  vendorId?: number | null
): Promise<DataWithPagination<Item>> => {
  const {
    pagination = defaultMaximumPagination,
    expands = [],
    customQuickSearchColumns = ['name'],
    quickSearchValue,
  } = config;

  let path = `/v1/items?expand=${expands.join(
    ','
  )}&hideZeroQuantityItems=${hideZeroQuantityItems}`;

  if (quickSearchValue) {
    path += `&quickSearchColumns=${customQuickSearchColumns}&quickSearchValue=${quickSearchValue}`;
  }

  if (itemTypes.length) {
    path += `&itemType=${itemTypes}`;
  }

  if (onlySaleItems) {
    path += `&saleItems.name=*`;
  }

  if (ids.length) {
    path += `&id=${ids.join(',')}`;
  }

  if (vendorId) {
    path += `&vendor.id=${vendorId}`;
  }

  const res = await paginatedApiCall(path, pagination, transformItem);

  return res;
};

export const fetchItemsByLocationAPI = async (
  config: RequestConfig,
  locationIds: number[] = [],
  showTrackedItems = false
): Promise<DataWithPagination<ItemByLocation>> => {
  const {
    pagination = defaultMaximumPagination,
    customQuickSearchColumns = ['name'],
    quickSearchValue,
  } = config;

  let path = `/v1/items/location?showTrackedItems=${showTrackedItems}`;

  if (quickSearchValue) {
    path += `&quickSearchColumns=${customQuickSearchColumns}&quickSearchValue=${quickSearchValue}`;
  }

  if (locationIds.length) {
    path += `&locationIds=${locationIds.join(',')}`;
  }

  const res = await paginatedApiCall(path, pagination, tranformItemByLocation);

  return res;
};

export const fetchItem = async (id: number): Promise<Item> => {
  const res = await createApiCall(
    {
      path: `/v1/items/${id}?expand=${defaultExpands.join(',')}`,
      method: 'GET',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformItem(res.data);
};
export const fetchAllItemsByLocationId = async (
  locationId: number
): Promise<Item> => {
  const res = await createApiCall(
    {
      path: `/v1/items?locationId=${locationId}&expand=saleItems&hideZeroQuantityItems=true`,
      method: 'GET',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return res.data.results.map((val: any) => transformItem(val));
};

export const fetchAllItemsIdByLocationId = async (
  locationId: number
): Promise<number[]> => {
  const res = await createApiCall(
    {
      path: `/v1/items?locationId=${locationId}&expand=saleItems&hideZeroQuantityItems=true`,
      method: 'GET',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return res.data.results.map((val: any) => transformItem(val).id);
};
export const postItem = async (item: Item): Promise<Item> => {
  const res = await createApiCall(
    {
      path: `/v1/items?expand=${defaultExpands.join(',')}`,
      method: 'POST',
      body: transformItemToPostItem(item),
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
        success: () => 'New item created',
      },
    }
  )();

  analyticsTrackEvent({ eventName: 'New item created' });
  return transformItem(res.data);
};

export const putItem = async (item: Item): Promise<Item> => {
  // Preparing item images and sale item images to delete
  const imagesToDelete = item.imageLinkList
    .filter((i) => i.deleted)
    .map((u) => u.url!);

  item.saleItemList.forEach((i) => {
    // if sale item is deleted, delete all images
    if (i.deleted) {
      i.imageLinkList.forEach((u) => imagesToDelete.push(u.url!));
    } else {
      i.imageLinkList
        .filter((d) => d.deleted)
        .forEach((u) => imagesToDelete.push(u.url!));
    }
  });

  const res = await createApiCall(
    {
      path: `/v1/items?expand=${defaultExpands.join(',')}`,
      method: 'PUT',
      body: transformItemToPutItem(item),
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
        success: () => 'Item updated',
      },
    }
  )();

  // delete images
  if (imagesToDelete.length) {
    deleteImages(imagesToDelete);
  }

  return transformItem(res.data);
};

export const deleteItem = async (item: Item): Promise<void> => {
  await createApiCall(
    {
      path: `/v1/items/${item.id}`,
      method: 'DELETE',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
        success: () => 'Item successfully deleted',
      },
    }
  )();

  const imagesToDelete = item.imageLinkList.map((u) => u.url!);
  item.saleItemList.forEach((i) => {
    i.imageLinkList.forEach((img) => {
      imagesToDelete.push(img.url!);
    });
  });

  if (imagesToDelete.length) {
    deleteImages(imagesToDelete);
  }
};

export const deleteItems = async (items: Item[]): Promise<void> => {
  const itemIds: number[] = items.map((i) => i.id!);
  await createApiCall(
    {
      path: `/v1/items/`,
      method: 'DELETE',
      body: itemIds,
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
        success: () => 'Item(s) successfully deleted',
      },
    }
  )();
  const imagesToDelete: string[] = [];
  items.forEach((i) => {
    i.imageLinkList.forEach((img) => {
      imagesToDelete.push(img.url!);
    });
    i.saleItemList.forEach((s) => {
      s.imageLinkList.forEach((img) => {
        imagesToDelete.push(img.url!);
      });
    });
  });

  if (imagesToDelete.length) {
    deleteImages(imagesToDelete);
  }
};
export const postTrackingToExistingItem = async (
  itemId: number,
  inventoryRows: InventoryRow[],
  itemTrackingTypes: ItemTrackingType[],
  existingTrackingTypeIds: number[]
) => {
  const payload = transformInventoryRowsToExistingItem(
    inventoryRows,
    itemTrackingTypes,
    existingTrackingTypeIds
  );

  const res = await createApiCall(
    {
      path: `/v1/items/${itemId}/item_tracking_types?expand=${defaultExpands.join(
        ','
      )}`,
      method: 'POST',
      body: payload,
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformItem(res.data);
};

export const restoreItem = async (itemId: number) => {
  await createApiCall(
    {
      path: `/v1/items/${itemId}/restore`,
      method: 'POST',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
        success: () => 'Item successfully restored',
      },
    }
  )();
};

export const getConsumptionRate = async (
  itemId: number,
  locationId: number | null,
  value: DateRange
): Promise<number> => {
  const formatedStartDate = moment(value.startDate).format('MM-DD-YYYY');
  const formatedEndDate = moment(value.endDate).format('MM-DD-YYYY');

  const res = await createApiCall(
    {
      path: `/v1/consumption_rate?itemId=${itemId}&locationId=${locationId}&startDate=${formatedStartDate}&endDate=${formatedEndDate}`,
      method: 'GET',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return res.data;
};

export const updateAverageCost = async (newCost: number, itemId: number) => {
  await createApiCall(
    {
      path: `/v1/items/${itemId}/update_cost`,
      method: 'POST',
      body: newCost,
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
        success: () => 'Average Cost Successfully Updated',
      },
    }
  )();
};

//all /vendors api calls should me inside vendors api.ts

export const getVendorItems = async (
  vendorId: number,
  pagination: Pagination
): Promise<DataWithPagination<any>> => {
  const expands: string[] = ['item.images'];

  const path = `/v1/vendors/${vendorId}/vendor_items?expand=${expands.join(
    ','
  )}`;
  const res = await paginatedApiCall(path, pagination);
  return res;
};

export const createVendorItem = async (vendorItem: VendorItem) => {
  await createApiCall(
    {
      path: `/v1/vendors/${vendorItem.vendorId}/vendor_items`,
      method: 'POST',
      body: [vendorItem],
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
        success: () => 'Sucessfully created vendor item',
      },
    }
  )();
};

export const fetchItemLocationsAPI = async (
  itemId: number
): Promise<ItemLocation[]> => {
  const response = await createApiCall(
    {
      path: `/materials-mgmt/v1/items/${itemId}/locations`,
      method: 'GET',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();
  return response.data.results.map(transformItemLocation);
};
