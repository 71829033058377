import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const columns: Column[] = [
  {
    title: 'Item',
    type: ColumnTypes.renderByCustomRow,
    width: '50%',
  },
  {
    title: 'Qty',
    type: ColumnTypes.renderByCustomRow,
    width: '25%',
  },
  {
    title: 'UOM',
    type: ColumnTypes.renderByCustomRow,
    width: '25%',
  },
];
