import { createApiCall } from 'services/api';

import {
  transformAccountingSettings,
  transformToAccountingSettingsPut,
} from './transformations';
import { AccountingSettings } from './types';

export const getAccountingSettings = async () => {
  const accountingSettingsResponse = await createApiCall({
    path: `/v1/accounting_settings`,
    method: `GET`,
  })();

  return transformAccountingSettings(accountingSettingsResponse.data);
};

export const putAccountingSettings = async (
  accountingSettings: AccountingSettings
) => {
  const accountingSettingsResponse = await createApiCall(
    {
      path: `/v1/accounting_settings`,
      method: `PUT`,
      body: transformToAccountingSettingsPut(accountingSettings),
    },
    {
      getMessage: {
        success: () => 'Updated accounting settings',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();
  return transformAccountingSettings(accountingSettingsResponse.data);
};
