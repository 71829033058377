import { styled } from '@mui/styles';
import { Box } from '@mui/material';

interface Props {
  direction?: 'row' | 'column';
  height?: string;
}

const Page = styled(Box)({
  height: (props: Props) => (props.height ? props.height : '100%'),
  display: 'flex',
  flexDirection: (props: Props) =>
    props.direction ? props.direction : 'column',
  columnGap: '50px',
});

export default Page;
