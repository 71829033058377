import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import _ from 'lodash';

import { Routes } from '../../navigation';
import { Page } from 'ui/components/Page/Page';

import { SettingsPageCmp } from './types';
import { SettingsCard } from './components';
import { useSettingsPageStyle } from './styled';
import { GlobalSettingsBanner } from '../../components/GlobalSettingsBanner';
import { useSelector } from 'react-redux';
import {
  getCommerceAccountIsConfigured,
  getCommerceAccountIsDisconnected,
  getCommerceAccountIsOrWasConnected,
} from 'services/commerce';
import { getActiveUser } from 'services/user';
import { getSellwareUrl } from '../DiscoverChannels/helpers';
import { ReconnectBanner } from '../../components/ReconnectBanner';
import { getSettingsCompany } from 'services/settings/company';
import { useFlags } from '../../../../../helpers/useFlags';

const SettingsPage: SettingsPageCmp = () => {
  const [sellwareUrlForReconnectFlow, setSellwareUrlForReconnectFlow] =
    useState<string>('');

  const classes = useSettingsPageStyle();

  const isSellwareConfigured = useSelector(getCommerceAccountIsConfigured);

  const isSellwareConnected = useSelector(getCommerceAccountIsOrWasConnected);

  const isSellwareDisconnected = useSelector(getCommerceAccountIsDisconnected);
  const activeUser = useSelector(getActiveUser);

  const companySettings = useSelector(getSettingsCompany);
  const companyName = _.get(companySettings, 'name', '');

  const showReconnectBanner = isSellwareDisconnected;

  const showGlobalSettingsBanner =
    !isSellwareConfigured && !showReconnectBanner && isSellwareConnected;

  const flags = useFlags();

  useEffect(() => {
    const registrationUrl =
      (flags.commerceAuth0Enabled
        ? process.env.REACT_APP_COMMERCE_REGISTER_URL
        : process.env.REACT_APP_SELLWARE_REGISTER_URL) || '';
    const getSellwareUrlForReconnectFlow = async () => {
      const url = await getSellwareUrl(
        activeUser,
        companyName,
        registrationUrl,
        false,
        true
      );
      setSellwareUrlForReconnectFlow(url);
    };

    if (showReconnectBanner && activeUser && companyName) {
      getSellwareUrlForReconnectFlow();
    }
  }, [activeUser, companyName, showReconnectBanner]);

  return (
    <>
      {showReconnectBanner && (
        <ReconnectBanner sellwareUrl={sellwareUrlForReconnectFlow} />
      )}
      {showGlobalSettingsBanner && (
        <GlobalSettingsBanner showConfigureSettingsButton={false} />
      )}
      <Page direction="row">
        <Box className={classes.cardContainer}>
          <SettingsCard />
        </Box>
      </Page>
    </>
  );
};

SettingsPage.route = Routes.SettingsPage;

export default SettingsPage;
