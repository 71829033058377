import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ItemsTable } from 'ui/components/Table/ItemsTable';
import ReorderTabRow from './ReorderTabRow';
import ManageAlertsModal from './ManageAlertsModal';
import ReorderTabModal from './ReorderTabModal/ReorderTabModal';
import _ from 'lodash';

import { ItemReorderPoint, initialItemReorderPoint } from 'services/items';
import { showNotification } from 'services/api';

import {
  findNextNegativeId,
  replaceValueInCollection,
  deleteEntityFromArray,
} from 'helpers';
import { editItemPermissions } from '../../helpers';

import {
  REORDER_ITEM_COLUMNS_EDIT,
  REORDER_ITEM_COLUMNS_ADD,
  ReorderTabTableRowActions,
} from './consts';

import { ReorderTabProps } from './types';
import { useReorderTab } from './styled';

import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { useFlags } from 'helpers/useFlags';

const NOTIFICATION = Object.freeze({
  locationNotSelected: 'Parent location must be selected',
  locationExist: 'Parent location already exists',
});

const ReorderTab = (props: ReorderTabProps) => {
  const { item, setItem, currentUser } = props;
  const classes = useReorderTab();

  const editPermissions = editItemPermissions(item);

  const [activeReorderPoint, setActiveReorderPoint] =
    useState<ItemReorderPoint>(initialItemReorderPoint);
  const [manageAlertsModalVisible, setManageAlertsModalVisible] =
    useState(false);

  const [isNewItem, setIsNewItem] = useState(false);

  const [showReorderTabModal, setShowReorderTabModal] = useState(false);

  const [displayPoints, setDisplayPoints] = useState<ItemReorderPoint[]>([]);
  const flags = useFlags();

  useEffect(() => {
    const filteredReorderPoints =
      flags.newUserEndpoint && !currentUser.admin
        ? item.reorderPointsList.filter(
            (reorderPoint: ItemReorderPoint) =>
              reorderPoint.locationId !== null &&
              currentUser?.locationIdsPermitted?.includes(
                reorderPoint.locationId
              )
          )
        : item.reorderPointsList;

    setDisplayPoints(filteredReorderPoints);
  }, [item, currentUser, flags.newUserEndpoint]);

  /**
   *
   * @type {(reorderItems: React.SetStateAction<ItemReorderPoint[]>) => void}
   * @description Helper fn that set reorderPointsList
   */
  const setReorderItems = useCallback(
    (reorderItems: React.SetStateAction<ItemReorderPoint[]>) => {
      if (typeof reorderItems === 'function') {
        setItem((l) => ({
          ...l,
          reorderPointsList: reorderItems(l.reorderPointsList),
        }));
        return;
      }

      setItem((l) => ({
        ...l,
        reorderPointsList: reorderItems,
      }));
    },
    [setItem]
  );

  const hideManageAlertsModal = useCallback(
    () => setManageAlertsModalVisible(false),
    [setManageAlertsModalVisible]
  );

  const handleAlertApply = useCallback(() => {
    const newReorderPoints = [...item.reorderPointsList];
    const index = item.reorderPointsList.findIndex(
      (p) => p.id === activeReorderPoint.id
    );

    setReorderItems(
      replaceValueInCollection(
        item.reorderPointsList,
        activeReorderPoint,
        index
      )!
    );
    setItem((prevState) => ({
      ...prevState,
      reorderPointsList: newReorderPoints,
    }));
    setManageAlertsModalVisible(false);
  }, [item.reorderPointsList, activeReorderPoint, setReorderItems]);

  /**
   *
   * @type {(id: number) => false}
   * @description If item is in reorderPointsList array open <ManageAlertsModal>, if not just return from fn.
   */
  const showManageAlertsModal = useCallback(() => {
    setManageAlertsModalVisible(true);
  }, [item.reorderPointsList]);

  const hideReorderTabModal = useCallback(() => {
    setShowReorderTabModal(false);
  }, [item.reorderPointsList]);
  /**
   *
   * @description Find all location IDs
   */
  const locationsId = useMemo(() => {
    return item.reorderPointsList.reduce(
      (arr: number[], obj: ItemReorderPoint) => {
        const locationId = _.get(obj, 'location.id', null);

        arr.push(locationId);

        return arr;
      },
      []
    );
  }, [item.reorderPointsList]);

  const onApplyClicked = useCallback(() => {
    const selectedLocation = _.get(activeReorderPoint, 'location', null);
    const selectedLocationId = _.get(activeReorderPoint, 'location.id', null);

    const locationExists = locationsId.includes(selectedLocationId);

    const message = !selectedLocation
      ? 'locationNotSelected'
      : locationExists && isNewItem
      ? 'locationExist'
      : false;

    if (message) {
      showNotification(NOTIFICATION[message], { variant: 'error' });

      return;
    }

    const newReorderPoints = [...item.reorderPointsList];
    const locationIndex = newReorderPoints.findIndex(
      (loc) => loc.id === activeReorderPoint.id
    );

    if (locationExists) {
      setReorderItems(
        replaceValueInCollection(
          newReorderPoints,
          activeReorderPoint,
          locationIndex
        )!
      );
      setShowReorderTabModal(false);
      return;
    }

    newReorderPoints.push(activeReorderPoint);

    setItem((prevState) => ({
      ...prevState,
      reorderPointsList: newReorderPoints,
    }));

    setShowReorderTabModal(false);
    // eslint-disable-next-line
  }, [activeReorderPoint, setReorderItems]);

  const handleAddNewClicked = useCallback(() => {
    // Reset order points to initial values
    setActiveReorderPoint({
      ...initialItemReorderPoint,
      id: findNextNegativeId(item.reorderPointsList),
    });

    setShowReorderTabModal(true);
    setIsNewItem(true);
    // eslint-disable-next-line
  }, [item.reorderPointsList, item.id]);

  const openModalForEditing = useCallback(
    (id: any) => {
      const reorderPointToEdit = item.reorderPointsList.find(
        (i) => i.id === id
      )!;

      setShowReorderTabModal(true);

      if (editPermissions) {
        setActiveReorderPoint(reorderPointToEdit);
      }

      setIsNewItem(false);
    },
    [item.reorderPointsList, editPermissions]
  );

  /**
   *
   * @param itemIndex
   * @description Receive itemIndex that is index number ( position in table ).
   * Remove position from array and set as new collection
   * @type {(action: any) => void}
   */
  const handleAction = useCallback(
    (action: any) => {
      const type: ReorderTabTableRowActions = action.type;

      if (type === ReorderTabTableRowActions.Delete) {
        setItem((prevItem) => ({
          ...prevItem,
          reorderPointsList: deleteEntityFromArray(
            item.reorderPointsList,
            action.itemIndex
          ),
        }));
      }
    },
    [item.reorderPointsList, setItem]
  );

  return (
    <>
      <Box className={classes.container}>
        <FBOTitleBar title="Reorder Points">
          <FBOButton
            variant="secondary"
            color="positive"
            size="medium"
            data-qa="reorder-points-add-new"
            icon="FBOAddCircle"
            onClick={handleAddNewClicked}
            permissions={editPermissions}
          >
            Add New
          </FBOButton>
        </FBOTitleBar>

        {/* Static data */}
        <ItemsTable
          data={displayPoints}
          columns={REORDER_ITEM_COLUMNS_EDIT}
          filterRows={(irp: ItemReorderPoint) => !irp.deleted}
          RenderCustomRow={ReorderTabRow}
          onItemClick={openModalForEditing}
          selectableItems={false}
          onAction={handleAction}
          dataQa="reorder-points-tab-table"
          emptyTableText="ADD NEW REORDER POINT BY PRESSING 'ADD NEW'"
          meta={{ showManageAlertsModal, item }}
        />
      </Box>

      {/* Reorder tab add/edit modal */}
      <ReorderTabModal
        itemId={item.id}
        reorderPoint={activeReorderPoint}
        setReorderPoint={setActiveReorderPoint}
        show={showReorderTabModal}
        onApplyClicked={onApplyClicked}
        onCancelClicked={hideReorderTabModal}
        editPermissions={editPermissions}
        isNewItem={isNewItem}
        onAlertClick={showManageAlertsModal}
        columnRender={
          isNewItem ? REORDER_ITEM_COLUMNS_ADD : REORDER_ITEM_COLUMNS_EDIT
        }
      />

      {/* Alert modal */}
      <ManageAlertsModal
        modalVisible={manageAlertsModalVisible}
        onClose={hideManageAlertsModal}
        onApply={handleAlertApply}
        reorderPoint={activeReorderPoint}
        setReorderPoint={setActiveReorderPoint}
      />
    </>
  );
};

export default memo(ReorderTab);
