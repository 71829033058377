import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { colorPalette } from '../../theme';

import { BorderPositionTypes } from './types';

const GUTTER = 18;
const borderHorizontal = (
  borderPosition: BorderPositionTypes,
  theme: Theme
) => {
  const borderPos =
    borderPosition === BorderPositionTypes.Top ? 'top' : 'bottom';

  return {
    content: '""',
    position: 'absolute',
    [`${borderPos}`]: 0,
    left: (fullWidthOffset: number | undefined) => {
      return fullWidthOffset ? theme.spacing(-fullWidthOffset) : 0;
    },
    right: 0,
    display: 'block',
    width: (fullWidthOffset: number | undefined) => {
      return fullWidthOffset
        ? `calc(100% + ${theme.spacing(fullWidthOffset * 2)})`
        : '100%';
    },
    height: 1,
    backgroundColor: colorPalette.applicationDarkGray20,
  };
};

export const useGridBoxStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridGap: GUTTER,
      position: 'relative',
      '&::before': {
        ...borderHorizontal(BorderPositionTypes.Top, theme),
      },
      '&::after': {
        ...borderHorizontal(BorderPositionTypes.Bottom, theme),
      },
    },
    child: {
      padding: GUTTER,
      '&:first-of-type': {
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: (fullWidthOffset: number | undefined) => {
            return fullWidthOffset ? theme.spacing(-fullWidthOffset / 2) : 0;
          },
          width: 1,
          height: '100%',
          backgroundColor: colorPalette.applicationDarkGray20,
        },
      },
    },
    lastChild: {
      paddingRight: 0,
    },
  };
});
