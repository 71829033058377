import { createFilterOptions, FilterOptionsState } from '@mui/material';
import { Pagination } from 'services/search';
import { fetchTagsAPI, Tag } from 'services/tags';
import { initialTag } from 'services/tags/consts';

const filter = createFilterOptions<Tag>();

export const resolvedFetchTagsFunction = (
  expands?: string[],
  ids: number[] = []
) => {
  return (pagination: Pagination, searchValue: string | null) =>
    fetchTagsAPI(
      {
        pagination,
        expands: expands,
        quickSearchValue: searchValue,
      },
      ids
    );
};

export const tagsAsyncAutocompleteOptionLabel = (tag: Tag) => {
  if (!tag.id || tag.id < 0) {
    return `+ Add "${tag.name}"`;
  }
  return tag.name || '';
};

export const tagsAsyncAutocompleteFilterOptions = (
  options: Tag[],
  params: FilterOptionsState<Tag>
) => {
  const filtered = filter(options, params);

  if (!!params.inputValue) {
    filtered.unshift({
      ...initialTag,
      name: params.inputValue || null,
    });
  }

  return filtered;
};
