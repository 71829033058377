import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { Class } from 'services/classes';
import {
  deleteClassById,
  getClassById,
  postClass,
  putClass,
} from 'services/classes/api';
import { initialClass } from 'services/classes/consts';
import { useHandleTextFieldChange } from 'services/forms';
import { useUrlQueryObject } from 'services/url';
import { showNotification } from 'services/api';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { DetailsCard } from 'ui/components/Page/DetailsCard';
import { colorPalette } from 'ui/theme';
import { TextField } from 'ui/components/TextField/TextField';

import { ClassDetailsCardProps } from './types';
import { getErrorMessage } from 'helpers';
import { logErrorCtx } from 'app/logging';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { PermissionType } from 'services/permissions';
import { useLocation, useNavigate } from 'react-router-dom';

const ClassDetailsCard: React.FC<ClassDetailsCardProps> = (props) => {
  const { activeClassId, onClose, fetchSearchResult } = props;

  const oldState = useRef<Class | null>(initialClass);

  const navigate = useNavigate();
  const location = useLocation();
  const [, setQueryParams] = useUrlQueryObject(navigate, location);

  const [activeClass, setActiveClass] = useState<Class>(initialClass);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!activeClassId || activeClassId === -1) {
      setActiveClass(initialClass);
      oldState.current = initialClass;
      return;
    }

    const asyncFc = async () => {
      setIsLoading(true);

      try {
        const getClass = await getClassById(activeClassId);
        oldState.current = getClass;
        setActiveClass(getClass);
      } catch (e) {
        const message = getErrorMessage(e);
        logErrorCtx('Error while loading Class', {
          error: e as Error,
          description: message,
          component: 'ClassDetailsCard',
        });
        showNotification(`${message} - Class could not be loaded.`, {
          variant: 'error',
        });
        setIsLoading(false);
        onClose();

        return;
      }

      setIsLoading(false);
    };

    asyncFc();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeClassId]);

  const saveClicked = useCallback(
    async (close: boolean = false) => {
      if (!activeClassId) {
        return false;
      }

      setIsLoading(true);
      let newClass: Class = initialClass;

      // POST
      if (activeClassId === -1) {
        try {
          newClass = await postClass(activeClass);
        } catch (e) {
          setIsLoading(false);
          logErrorCtx('Class details save clicked post', {
            error: e as Error,
            component: 'ClassDetailsCard',
          });
          return false;
        }
      }

      // PUT
      if (activeClassId > 0) {
        try {
          newClass = await putClass(activeClass);
        } catch (e) {
          logErrorCtx('Class details save clicked put', {
            error: e as Error,
            component: 'ClassDetailsCard',
          });
          setIsLoading(false);
          return false;
        }
      }

      setActiveClass(newClass);
      oldState.current = newClass;
      await fetchSearchResult();
      setIsLoading(false);

      if (close) {
        setQueryParams({ activeId: null });
        onClose();
      } else {
        setQueryParams({ activeId: newClass.id });
      }

      return true;
    },
    [onClose, activeClass, activeClassId, fetchSearchResult, setQueryParams]
  );

  const deleteClicked = useCallback(async () => {
    try {
      await deleteClassById(activeClass.id!);
      oldState.current = activeClass;
      await fetchSearchResult();
      onClose();
    } catch (error) {
      logErrorCtx('Card details delete clicked', {
        error: error as Error,
        component: 'ClassDetailsCard',
      });
    }
  }, [activeClass, fetchSearchResult, onClose]);

  const handleTextFieldChange = useHandleTextFieldChange<Class>(
    setActiveClass,
    activeClass
  );

  const hideDeleteModal = (): void => setDeleteModalVisible(false);
  const showDeleteModal = (): void => setDeleteModalVisible(true);

  return (
    <>
      <DetailsCard
        onSubmit={saveClicked}
        state={activeClass}
        oldState={oldState}
        isLoading={isLoading}
      >
        <FBOTitleBar
          title={activeClass.name || 'New Class'}
          sx={{
            borderBottom: `1px solid ${colorPalette.redesign.background3}`,
          }}
        >
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="secondary"
            color="positive"
            size="medium"
            data-qa="accounting-new-class-save"
            onClick={() => saveClicked(false)}
            permissions={[PermissionType.AccountingEdit]}
          >
            Save
          </FBOButton>
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="secondary"
            color="positive"
            size="medium"
            data-qa="accounting-new-class-save-and-close"
            onClick={() => saveClicked(true)}
            permissions={[PermissionType.AccountingEdit]}
          >
            Save And Close
          </FBOButton>
          {activeClassId !== -1 && (
            <FBOButton
              sx={{ marginRight: '8px' }}
              variant="secondary"
              color="negative"
              size="medium"
              icon="TrashCan"
              data-qa="accounting-new-class-delete"
              onClick={showDeleteModal}
              permissions={[PermissionType.AccountingEdit]}
            >
              Delete
            </FBOButton>
          )}
          <FBOButton
            variant="tertiary"
            color="neutral"
            size="medium"
            icon="FBOClose"
            data-qa="accounting-new-class-close"
            onClick={onClose}
          />
        </FBOTitleBar>

        <Box p={4} width="100%" overflow="auto">
          <Grid container spacing={2}>
            <Grid xs={3}>
              <TextField
                className={'redesign'}
                variant={'standard'}
                type="text"
                label="Name"
                placeholder="Enter class name"
                name="name"
                autoComplete="nope"
                onChange={handleTextFieldChange}
                value={activeClass.name}
                data-qa="accounting-new-class-name"
                permissions={[PermissionType.AccountingEdit]}
              />
            </Grid>
          </Grid>
        </Box>
        <ConfirmationModal
          open={deleteModalVisible}
          title="Delete Class"
          body={`This will delete '${
            activeClass.name || ''
          }' class, are you sure?`}
          onCancelClicked={hideDeleteModal}
          onConfirmClicked={deleteClicked}
          confirmLabel="Delete"
          confirmButtonRed
        />
      </DetailsCard>
    </>
  );
};

export default ClassDetailsCard;
