import React, { memo, useCallback, useState, useMemo } from 'react';
import _ from 'lodash';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { ItemTrackingType, TrackingGroup } from 'services/inventory';

import { SerialModal } from './components/SerialModal';
import { TrackingViewCells } from './components';

const TrackingRow: React.FC<RenderCustomRowProps<TrackingGroup>> = ({
  row: trackingGroup,
  columns,
  meta,
}) => {
  const [serialModalVisible, setSerialModalVisible] = useState(false);

  const itemTrackingTypes: ItemTrackingType[] = useMemo(
    () => _.get(meta, 'itemTrackingTypes'),
    [meta]
  );

  const handleSerialLinkClick = useCallback(() => {
    setSerialModalVisible(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setSerialModalVisible(false);
  }, []);

  return (
    <>
      <TrackingViewCells
        columns={columns}
        trackingGroup={trackingGroup}
        onSerialLinkClick={handleSerialLinkClick}
        itemUom={_.get(meta, 'itemUom')}
      />
      <SerialModal
        visible={serialModalVisible}
        itemTrackingTypes={itemTrackingTypes}
        quantity={
          trackingGroup.serialQuantity
            ? trackingGroup.serialQuantity
            : trackingGroup.quantity || 0
        }
        serialList={trackingGroup.serialList}
        onCloseClicked={handleCloseModal}
        trackingGroup={trackingGroup}
        closeParentModal={_.get(meta, 'closeParentModal')}
      />
    </>
  );
};

export default memo(TrackingRow);
