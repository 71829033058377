import * as yup from 'yup';

export const yupSoEmailModalSchema = yup.object().shape({
  to: yup.string().required(),
  subject: yup.string().required(),
  cc: yup
    .string()
    .test('different', "Cc and Bcc can't be same", function (value) {
      if (!value || !this.parent.bcc) {
        return true;
      }
      const trimmedCc = value && value.trim();
      const trimmedBcc = this.parent.bcc.trim();
      return !!trimmedCc ? trimmedCc !== trimmedBcc : true;
    })
    .nullable(true),
  body: yup
    .mixed()
    .test('email', 'Body is required', function (value) {
      if (!value || value === '<p><br></p>') {
        return false;
      }
      return true;
    })
    .required('Body is required'),
});
