import React, { memo } from 'react';

import { BillToShipToAddressBeta } from 'services/addresses';

import { useAddressDataStyles } from './styled';

const AddressData = ({
  data,
  heading = true,
}: {
  data: BillToShipToAddressBeta;
  heading?: boolean;
}) => {
  const classes = useAddressDataStyles();

  if (!data.name) {
    return null;
  }

  return (
    <address className={classes.address}>
      <header className={classes.header}>
        {heading && (
          <p className={classes.title}>
            <strong>{data.name}</strong>
          </p>
        )}
        {data.companyName && (
          <p className={classes.subtitle}>
            <strong>{data.companyName}</strong>
          </p>
        )}
      </header>
      <div className={classes.body}>
        {data.street && <p>{data.street}</p>}
        {data.street2 && <p>{data.street2}</p>}
        {(data.city || data.state || data.postalCode) && (
          <p>
            {data.city},&nbsp;
            {data.state},&nbsp;
            {data.postalCode}
          </p>
        )}
        {data.email && (
          <p>
            <a title={data.email} href={`mailto: ${data.email}`}>
              {data.email}
            </a>
          </p>
        )}
        {data.phone && (
          <p>
            <a title={data.phone} href={`tel: ${data.phone}`}>
              {data.phone}
            </a>
          </p>
        )}
      </div>
    </address>
  );
};

export default memo(AddressData);
