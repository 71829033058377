import React, { memo } from 'react';
import { SalesOrderNotesTabProps } from './types';

import { Box, Grid } from '@mui/material';
import { TextField } from 'ui/components/TextField/TextField';

import { SalesOrder, SalesOrderStatus } from 'services/salesOrders';
import { useHandleTextFieldChange } from 'services/forms';

import { editSalesOrderPermissions } from '../../helpers';

const SalesOrderNotesTab = (props: SalesOrderNotesTabProps) => {
  const { salesOrder, setSalesOrder } = props;

  const fieldsDisabled =
    salesOrder.status === SalesOrderStatus.Fulfilled ||
    salesOrder.status === SalesOrderStatus.Cancelled;

  const editPermission = editSalesOrderPermissions(salesOrder);

  const handleTextFieldChange = useHandleTextFieldChange<SalesOrder>(
    setSalesOrder,
    salesOrder
  );

  return (
    <Box p={3} width="100%">
      <Grid container spacing={2}>
        <Grid item xs>
          <TextField
            className="redesign"
            variant="standard"
            label="Notes"
            placeholder="Write down a note here. Notes are not visible outside of this application."
            multiline
            rows={10}
            permissions={editPermission}
            disabled={fieldsDisabled}
            onChange={handleTextFieldChange}
            name="notes"
            value={salesOrder.notes}
            dataQa="sale-order-detail-note"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(SalesOrderNotesTab);
