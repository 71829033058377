import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';

import {
  fetchLedgerDetails,
  initialLedger,
  Ledger,
  LedgerLineItem,
} from 'services/ledgers';
import { DetailsCard } from 'ui/components/Page/DetailsCard';
import { initialPagination, ItemsTable } from 'ui/components/Table/ItemsTable';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { deleteAccount } from 'services/accounting';
import { Pagination } from 'services/search';
import { showNotification } from 'services/api';

import { CustomRow } from './components';
import { LedgerModal } from './components/LedgerModal';
import { createAccountsActionBarOptions, LEDGER_COLUMNS } from './consts';
import { LedgerDetailsCardProps } from './types';
import { getErrorMessage } from 'helpers';
import { logErrorCtx } from 'app/logging';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';
import { TextField } from 'ui/components/TextField/TextField';
import { Box } from '@mui/system';
import { InputAdornment } from '@mui/material';
import clsx from 'clsx';
import { icons } from 'ui/theme';
import CloseIcon from '@mui/icons-material/Close';
import { PermissionType } from 'services/permissions';

const LedgerDetailsCard: React.FC<LedgerDetailsCardProps> = (props) => {
  const { activeItemId, ledgers, onClose, getLedgers, startMoment, endMoment } =
    props;

  const [activeLedger, setActiveLedger] = useState<Ledger>(initialLedger);
  const [isLoading, setIsLoading] = useState(false);
  const [showAccountDeleteModal, setShowAccountDeleteModal] =
    useState<boolean>(false);
  const [pagination, setPagination] = useState<Pagination>(initialPagination);
  const [quickSearch, setQuickSearch] = useState<string | null>(null);
  const [activeLedgerLineItem, setActiveLedgerLineItem] =
    useState<LedgerLineItem | null>(null);
  useEffect(() => {
    setActiveLedger({
      ...activeLedger,
      ledgers: _.orderBy(
        activeLedger.ledgers,
        pagination.sort.sortBy,
        pagination.sort.direction
      ),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.sort, activeLedger.id]);

  const handleQuickSearchChange = (e: any) => {
    setQuickSearch(e.target.value || null);
  };

  useEffect(() => {
    if (!activeItemId) {
      setActiveLedger(initialLedger);
      return;
    }

    (async () => {
      try {
        setIsLoading(true);
        const newGeneralLedger = await fetchLedgerDetails(
          activeItemId,
          startMoment.toISOString(),
          endMoment.toISOString(),
          quickSearch
        );
        setActiveLedger(newGeneralLedger);
      } catch (error) {
        const message = getErrorMessage(error);
        logErrorCtx('Error in fetchLedgerDetails', {
          error: error as Error,
          component: 'LedgerDetailsCard',
          description: message,
        });
        showNotification(`${message} - ledger couldn't be loaded.`, {
          variant: 'error',
        });
        setIsLoading(false);
        onClose();

        return;
      }
      setIsLoading(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItemId, ledgers, startMoment, endMoment, quickSearch]);

  const handleItemClicked = useCallback(
    (id: number) => {
      const ledgerItem = activeLedger.ledgers.find((l) => l.id === id)!;
      setActiveLedgerLineItem(ledgerItem);
    },
    [activeLedger]
  );

  const handleDeleteAccountClicked = useCallback(async () => {
    setIsLoading(true);

    try {
      await deleteAccount(activeLedger.id!);
    } catch (e) {
      logErrorCtx('Error in deleteAccount', {
        error: e as Error,
        component: 'LedgerDetailsCard',
        description: `Ledger id ${activeLedger.id}`,
      });
      setIsLoading(false);
      return;
    }

    setShowAccountDeleteModal(false);
    onClose();
    getLedgers();
    setIsLoading(false);
  }, [activeLedger, getLedgers, onClose]);

  return (
    <DetailsCard isLoading={isLoading}>
      <FBOTitleBar title={activeLedger.accountName}>
        <Box flexGrow={1} sx={{ marginLeft: '16px' }}>
          <TextField
            id="search-input"
            type="text"
            placeholder="Search Transactions"
            inputProps={{
              'data-qa': 'quick-search-input',
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  className={clsx({
                    redesign: true,
                    search: true,
                  })}
                >
                  <icons.MagnifyingGlass />
                </InputAdornment>
              ),
              endAdornment: quickSearch ? (
                <InputAdornment position="end">
                  <CloseIcon
                    sx={{
                      '&:hover': {
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      },
                    }}
                    fontSize="small"
                    onClick={() =>
                      handleQuickSearchChange({ target: { value: '' } })
                    }
                  />
                </InputAdornment>
              ) : (
                <></>
              ),
            }}
            onChange={handleQuickSearchChange}
            value={quickSearch}
          />
        </Box>
        <FBOMenuButton
          sx={{ marginRight: '8px' }}
          variant="tertiary"
          data-qa="ledger-details-three-dot-menu"
          items={createAccountsActionBarOptions(() =>
            setShowAccountDeleteModal(true)
          )}
          permissions={[PermissionType.AccountingEdit]}
        />
        <FBOButton
          variant="tertiary"
          color="neutral"
          size="medium"
          icon="FBOClose"
          data-qa="account-receivable"
          onClick={onClose}
        />
      </FBOTitleBar>
      <ItemsTable
        data={activeLedger.ledgers}
        columns={LEDGER_COLUMNS}
        RenderCustomRow={CustomRow}
        selectableItems={false}
        pagination={pagination}
        onPaginationChange={setPagination}
        emptyTableText="THERE ARE NO LEDGER ITEMS"
        onItemClick={handleItemClicked}
        meta={{ markedAsPosted: activeLedger.markedAsPosted }}
        RenderCustomFooter={() => <></>}
        dataQa="invoice-table"
      />
      <LedgerModal
        activeLedgerItem={activeLedgerLineItem}
        setActiveLedgerItem={setActiveLedgerLineItem}
      />
      <ConfirmationModal
        open={showAccountDeleteModal}
        title="Delete Account"
        body={`Are you sure you want to delete ${activeLedger.accountName}?`}
        onConfirmClicked={handleDeleteAccountClicked}
        onCancelClicked={() => setShowAccountDeleteModal(false)}
      />
    </DetailsCard>
  );
};

export default LedgerDetailsCard;
