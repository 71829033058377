import React, { memo, useCallback } from 'react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';

import { AdvancedSearchFieldsCmpProps } from 'ui/components/Page/PageWithAdvancedSearch';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { PaymentTermType } from 'services/paymentTerms';

const PaymentTermsAdvancedSearch: React.FC<AdvancedSearchFieldsCmpProps> = (
  props
) => {
  const { setFormValues, formValues } = props;

  const handleShowDeletedChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
      setFormValues({ ...formValues, deleted: checked ? 'true' : null }),
    [formValues, setFormValues]
  );

  const handlePaymentTermTypeChange = useCallback(
    (e: React.ChangeEvent<{}>, value: PaymentTermType | null) => {
      setFormValues({ ...formValues, paymentTermType: value });
    },
    [formValues, setFormValues]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Autocomplete
          onChange={handlePaymentTermTypeChange}
          options={Object.values(PaymentTermType)}
          getOptionLabel={(option) =>
            option === 'Standard' ? 'Net Term' : option
          }
          value={formValues.paymentTermType}
          placeholder="Select payment term type"
          dataQa="select-payment-term-type"
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={!!formValues.deleted}
              onChange={handleShowDeletedChange}
              color="primary"
              data-qa="show-deleted"
            />
          }
          label="Show Deleted"
        />
      </Grid>
    </Grid>
  );
};

export default memo(PaymentTermsAdvancedSearch);
