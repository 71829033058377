import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography, Paper, Grid, Radio, Tooltip } from '@mui/material';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { IconNames, colorPalette } from 'ui/theme';
import { Modal } from 'ui/components/Modal/Modal';
import { Icon } from 'ui/components/Icon';
import { ItemImage } from 'services/items';
import { findNextNegativeId, toBase64 } from 'helpers';
import { activeUserHasPermission } from 'services/user/redux';

import { ImageDropZoneProps } from './types';
import { useDropZoneStyle } from './styled';
import { showNotification } from 'services/api';
import { ImageStorageType, uploadImage } from 'services/images';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const StyledImage = styled.img`
  width: ${({ width }) => `${width ? width : '100%'}`};
  height: ${({ height }) => `${height}px`};
  object-fit: contain;
  overflow: hidden;
  cursor: default;
`;

const ImageDropZone: React.FC<ImageDropZoneProps> = (
  props: ImageDropZoneProps
) => {
  const {
    images,
    setImages,
    onSave,
    onAddButtonClicked,
    onCancel,
    permissions = [],
    disabled = false,
  } = props;

  const canEdit = useSelector(activeUserHasPermission(permissions));

  const isDisabled = disabled || !canEdit;

  const defaultImage = useMemo(
    () => images.find((i) => i.defaultFlag),
    [images]
  );

  const notDeletedImages = images.filter((i) => !i.deleted);

  const handleDrop = useCallback(
    async (droppedFiles: File[]) => {
      setIsModalLoading(true);

      const newImageLinks: Promise<ItemImage>[] = droppedFiles.map(
        (image, index) =>
          toBase64(image).then((base64) => ({
            id: findNextNegativeId(images) - index,
            cloned: false,
            defaultFlag: !defaultImage && index === 0 ? true : false,
            deleted: false,
            url: base64 as string,
            version: null,
            name: image.name,
          }))
      );

      const resolvedImageLinks = await Promise.all(newImageLinks);

      let errorMessage = '';
      await Promise.all(
        resolvedImageLinks.map(async (img, index) => {
          try {
            const url = await uploadImage(
              img.url!,
              ImageStorageType.ItemImage,
              img.name
            );

            resolvedImageLinks[index].url = url;
          } catch (e) {
            resolvedImageLinks[index].url = null;
            errorMessage = _.get(
              e,
              'response.data.errorMessage',
              'Drive service error. Please contact support.'
            );
            return;
          }
        })
      );
      if (errorMessage) {
        showNotification(errorMessage, {
          variant: 'error',
        });
      }

      setImages((prevImages) => [
        ...prevImages,
        ...resolvedImageLinks.filter((image) => !!image.url),
      ]);
      setIsModalLoading(false);
    },
    [defaultImage, images, setImages]
  );

  const classes = useDropZoneStyle(props);
  const { getRootProps, getInputProps } = useDropzone({ onDrop: handleDrop });

  useEffect(() => {
    // this useEffect is used to handle default image if non is selected
    if (!defaultImage && !_.isEmpty(notDeletedImages)) {
      const firstNotDeletedImageId = notDeletedImages[0].id;
      const index = images.findIndex((i) => i.id === firstNotDeletedImageId);
      setImages([
        ...images.slice(0, index),
        { ...images[index], defaultFlag: true },
        ...images.slice(index + 1),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images, setImages]);

  const [modalVisible, setModalVisible] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);

  const resolvedTooltipTitle = useMemo(
    () => (disabled ? "Can't add images on new items" : 'Add image'),
    [disabled]
  );

  const handleDeleteClicked = useCallback(
    (id: number) => (e: React.MouseEvent<Element>) => {
      e.stopPropagation();
      setImages(
        images.map((i) => ({
          ...i,
          defaultFlag: i.id === id ? false : i.defaultFlag,
          deleted: i.id === id ? true : i.deleted,
        }))
      );
    },
    [images, setImages]
  );

  const handleRadioClicked = useCallback(
    (id: number) => (e: React.MouseEvent<Element>) => {
      e.stopPropagation();
      setImages(images.map((i) => ({ ...i, defaultFlag: i.id === id })));
    },
    [images, setImages]
  );

  const handleCancelClicked = useCallback(() => {
    onCancel();
    setModalVisible(false);
  }, [onCancel]);

  const handleBrowseClicked = useCallback(
    (e) => {
      const rootProps = getRootProps();
      (rootProps as any).onClick(e);
    },
    [getRootProps]
  );

  const handleSaveClicked = useCallback(async () => {
    setIsModalLoading(true);
    await onSave();
    setModalVisible(false);
    setIsModalLoading(false);
  }, [onSave]);

  const handleAddImagesPress = React.useCallback(() => {
    if (onAddButtonClicked) {
      onAddButtonClicked();
    }
    setModalVisible(true);
  }, [onAddButtonClicked]);

  const renderPhotoItem = (image: ItemImage) => (
    <Grid item xs={12} key={image.id}>
      <Box display="flex" alignItems="center" flexDirection="row">
        <Radio
          checked={image.defaultFlag}
          onClick={handleRadioClicked(image.id)}
        />
        <Paper variant="outlined">
          <Box display="flex" onClick={handleRadioClicked(image.id)}>
            <StyledImage alt="" height={56} width={56} src={image.url!} />
          </Box>
        </Paper>
        <Box ml="auto">
          <FBOButton
            variant="tertiary"
            color="negative"
            size="small"
            icon={IconNames.TrashCan}
            onClick={handleDeleteClicked(image.id)}
            data-qa="image-dropzone-delete-trashcan"
          />
        </Box>
      </Box>
    </Grid>
  );

  const restyleImageDropZone = () => (
    <Box
      data-qa="items-image-container"
      sx={{
        display: 'flex',
        width: '100%',
        maxWidth: '192px',
        height: '192px',
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid ${colorPalette.redesign.background3}`,
        backgroundColor: colorPalette.redesign.background1,
      }}
    >
      {defaultImage ? (
        <StyledImage alt="" height={136} src={defaultImage.url!} />
      ) : (
        <Icon
          name={IconNames.FBOPhotoPicture}
          fill={colorPalette.redesign.background4}
          width={96}
          height={106}
        />
      )}
    </Box>
  );

  return (
    <Box className={classes.container}>
      {restyleImageDropZone()}
      <Tooltip title={resolvedTooltipTitle} placement="bottom">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width="192px"
        >
          <FBOButton
            variant="secondary"
            size="medium"
            color="positive"
            data-qa="items-manage-images-button"
            disabled={isDisabled}
            onClick={handleAddImagesPress}
            style={{ marginTop: '8px', minWidth: '100%' }}
          >
            Manage Images
          </FBOButton>
        </Box>
      </Tooltip>
      <Modal
        open={modalVisible}
        onClose={handleCancelClicked}
        title="Images"
        onCancelClicked={handleCancelClicked}
        onResetClicked={handleBrowseClicked}
        onApplyClicked={handleSaveClicked}
        applyLabel="Update"
        resetLabel="Browse"
        isLoadingContent={isModalLoading}
      >
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Paper className={classes.photoPaper} variant="outlined">
              {defaultImage ? (
                <StyledImage alt="" height={416} src={defaultImage.url!} />
              ) : (
                <Icon
                  name={IconNames.PhotoPlaceHolder}
                  width={117}
                  height={76}
                  fill="#F0F0F0"
                />
              )}
            </Paper>
          </Grid>
          <Grid item xs={7}>
            <Paper
              {...getRootProps()}
              className={classes.photosPaper}
              variant="outlined"
            >
              <input {...getInputProps()} />
              {notDeletedImages.length ? (
                <Grid container spacing={2}>
                  {notDeletedImages.map(renderPhotoItem)}
                </Grid>
              ) : (
                <Typography variant="subtitle2">
                  DRAG AND DROP IMAGES HERE
                </Typography>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Modal>
    </Box>
  );
};

export default React.memo(ImageDropZone);
