import React, { memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, ButtonBase } from '@mui/material';
import _ from 'lodash';

import { ModuleNavigationItemStatus } from 'services/moduleNavigation';
import { Routes as SoRoutes } from 'ui/modules/sales';
import { Routes as PoRoutes } from 'ui/modules/purchasing';

import { SalesNavigationProps } from './types';
import { BreadCrumbLocation } from '../BreadCrumb/types';
import { Icon } from 'ui/components/Icon';
import { IconNames } from 'ui/theme';
import FBOBreadCrumb from '../BreadCrumb/FBOBreadCrumb';
import { concatRoute } from 'helpers/routes';

const FBOSalesNavigation: React.FC<SalesNavigationProps> = (props) => {
  const { items } = props;

  const {
    salesOrderIds = [],
    pickIds = [],
    shipIds = [],
    receiptId,
    purchaseOrderId,
  } = items;

  const hasSalesOrders = !_.isEmpty(salesOrderIds);
  const hasPicks = !_.isEmpty(pickIds);
  const hasShips = !_.isEmpty(shipIds);

  const classes = {
    icon: 'redesign',
    skeleton: 'redesign',
    navigation: 'redesign navigation',
    navigationContainer: 'redesign navigation-container',
    returnNavigation: 'redesign return-navigation',
    returnItem: 'redesign return-item',
    returnIcon: 'redesign',
  };

  const location = useLocation();
  const navigate = useNavigate();

  const onSalesOrderPage = location.pathname.includes(SoRoutes.SalesOrderPage);
  const onPicking = location.pathname.includes(SoRoutes.PickingPage);
  const onShipping = location.pathname.includes(SoRoutes.ShippingPage);

  const salesOrderStatus = !hasSalesOrders
    ? ModuleNavigationItemStatus.Undefined
    : onSalesOrderPage
    ? ModuleNavigationItemStatus.Active
    : ModuleNavigationItemStatus.InActive;

  const pickStatus = !hasPicks
    ? ModuleNavigationItemStatus.Undefined
    : onPicking
    ? ModuleNavigationItemStatus.Active
    : ModuleNavigationItemStatus.InActive;

  const shipStatus = !hasShips
    ? ModuleNavigationItemStatus.Undefined
    : onShipping
    ? ModuleNavigationItemStatus.Active
    : ModuleNavigationItemStatus.InActive;

  const salesOrderRoute = `${concatRoute(
    SoRoutes.SalesRouter,
    SoRoutes.SalesOrderPage
  )}?activeId=${_.last(salesOrderIds.sort())}`;

  const pickingRoute = `${concatRoute(
    SoRoutes.SalesRouter,
    SoRoutes.PickingPage
  )}?activeId=${_.last(pickIds.sort())}`;

  const shippingRoute = `${concatRoute(
    SoRoutes.SalesRouter,
    SoRoutes.ShippingPage
  )}?activeId=${_.last(shipIds.sort())}`;

  const purchaseRoute = `${concatRoute(
    PoRoutes.PurchasingRouter,
    PoRoutes.PurchaseOrderPage
  )}?activeId=${purchaseOrderId}`;

  const receivingRoute = `${concatRoute(
    PoRoutes.PurchasingRouter,
    PoRoutes.ReceivingPage
  )}?activeId=${receiptId}`;

  return (
    <Box className={classes.navigationContainer}>
      <Box className={classes.navigation}>
        <FBOBreadCrumb
          location={BreadCrumbLocation.Left}
          status={salesOrderStatus}
          zIndex={3}
          onClick={() =>
            navigate(salesOrderRoute, { state: { triggerRouteGuard: true } })
          }
          data-qa="spacial-navigation-sales-order"
          disabled={salesOrderStatus === ModuleNavigationItemStatus.Undefined}
          disableRipple
          disableTouchRipple
        >
          <Typography variant="body2">Sales Order</Typography>
        </FBOBreadCrumb>
        <Icon name={IconNames.FBOCaratRightSmall} />
        <FBOBreadCrumb
          location={BreadCrumbLocation.Middle}
          status={pickStatus}
          zIndex={2}
          onClick={() =>
            navigate(pickingRoute, { state: { triggerRouteGuard: true } })
          }
          data-qa="spacial-navigation-picking"
          disabled={pickStatus === ModuleNavigationItemStatus.Undefined}
          disableRipple
          disableTouchRipple
        >
          <Typography variant="body2">Picking</Typography>
        </FBOBreadCrumb>
        <Icon name={IconNames.FBOCaratRightSmall} />
        <FBOBreadCrumb
          location={BreadCrumbLocation.Right}
          status={shipStatus}
          zIndex={1}
          onClick={() =>
            navigate(shippingRoute, { state: { triggerRouteGuard: true } })
          }
          data-qa="spacial-navigation-shipping"
          disabled={shipStatus === ModuleNavigationItemStatus.Undefined}
          disableRipple
          disableTouchRipple
        >
          <Typography variant="body2">Shipping</Typography>
        </FBOBreadCrumb>
      </Box>
      {(!!receiptId || !!purchaseOrderId) && (
        <Box className={classes.returnNavigation}>
          {!!receiptId && (
            <ButtonBase
              className={classes.returnItem}
              onClick={() =>
                navigate(receivingRoute, { state: { triggerRouteGuard: true } })
              }
              data-qa="return-navigation-receiving"
              disableRipple
              disableTouchRipple
            >
              <Typography variant="body2">To Receiving</Typography>
            </ButtonBase>
          )}
          {!!purchaseOrderId && (
            <ButtonBase
              className={classes.returnItem}
              onClick={() =>
                navigate(purchaseRoute, { state: { triggerRouteGuard: true } })
              }
              data-qa="return-navigation-purchase-order"
              disableRipple
              disableTouchRipple
            >
              <Typography variant="body2">To Purchase Order</Typography>
            </ButtonBase>
          )}
        </Box>
      )}
    </Box>
  );
};

export default memo(FBOSalesNavigation);
