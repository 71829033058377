import React, { memo, useCallback, useMemo } from 'react';
import _ from 'lodash';
import { TableCell } from '@mui/material';

import { DateTooltip } from 'ui/components/DateTooltip';
import { DateMessage } from 'ui/components/DateTooltip/types';
import {
  PurchaseOrder,
  PurchaseOrderItemOrderType,
  PurchaseOrderItemTypes,
} from 'services/purchaseOrders';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { StatusLabel } from 'ui/components/Status/StatusLabel';
import { useCurrencyFormatter, useDateFormat } from 'helpers';

import { createActionBarOptions } from './consts';
import { PurchaseOrderTableRowActions } from './types';
import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';

const PurchaseOrderRow: React.FC<RenderCustomRowProps<PurchaseOrder>> = (
  props
) => {
  const { row, columns, onAction } = props;

  const currencyFormatter = useCurrencyFormatter();

  const tooltipDates: DateMessage[] = useMemo(
    () => [
      { date: row.dateCreated, message: 'Date Created' },
      { date: row.dateLastModified, message: 'Date Last Modified' },
      { date: row.dateFulfilled, message: 'Date Fulfilled' },
      { date: row.dateIssued, message: 'Date Issued' },
      { date: row.dateScheduled, message: 'Date Scheduled' },
    ],
    [row]
  );

  const { formatDate } = useDateFormat();

  const dateValue: string = useMemo(() => {
    const dateField = columns[4].field!;
    const date = dateField ? _.get(row, dateField, null) : null;

    return formatDate(date);
  }, [columns, row, formatDate]);

  const handleDuplicate = useCallback(() => {
    if (onAction) {
      onAction({ type: PurchaseOrderTableRowActions.Duplicate, value: row.id });
    }
  }, [onAction, row.id]);

  const handleDelete = useCallback(() => {
    if (onAction) {
      onAction({ type: PurchaseOrderTableRowActions.Delete, value: row.id });
    }
  }, [onAction, row.id]);

  const OrderType = useCallback(() => {
    const poItems = row.purchaseOrderItemList.filter(
      (i) =>
        i.purchaseOrderItemType === PurchaseOrderItemTypes.Purchase ||
        i.purchaseOrderItemType === PurchaseOrderItemTypes.CreditReturn
    );
    if (!poItems.length) {
      return null;
    }
    const isCreditReturn = poItems.every(
      (type) =>
        type.purchaseOrderItemType === PurchaseOrderItemTypes.CreditReturn
    );
    if (isCreditReturn) {
      return <StatusLabel status={PurchaseOrderItemOrderType.CreditReturn} />;
    }
    const isMixed = poItems.some(
      (type) =>
        type.purchaseOrderItemType === PurchaseOrderItemTypes.CreditReturn
    );
    if (isMixed) {
      return <StatusLabel status={PurchaseOrderItemOrderType.Mixed} />;
    }

    const isPurchase = poItems.every(
      (type) => type.purchaseOrderItemType === PurchaseOrderItemTypes.Purchase
    );
    if (isPurchase) {
      return <StatusLabel status={PurchaseOrderItemOrderType.Purchase} />;
    }
    return null;
  }, [row]);

  const PONumber = _.get(row, 'number', '-');

  return (
    <>
      <TableCell data-qa={`${PONumber}-No.`}>{PONumber}</TableCell>
      <TableCell data-qa={`${PONumber}-Status`}>
        <StatusLabel status={row.status} />
      </TableCell>
      <TableCell data-qa={`${PONumber}-Vendor`}>
        {_.get(row, 'vendor.name', '-')}
      </TableCell>
      <TableCell data-qa={`${PONumber}-Buyer`}>
        {`${_.get(row, 'buyer.firstName', '')} ${_.get(
          row,
          'buyer.lastName',
          ''
        )}`}
      </TableCell>
      <DateTooltip values={tooltipDates}>
        <TableCell data-qa={`${PONumber}-Date Created`}>{dateValue}</TableCell>
      </DateTooltip>
      <TableCell data-qa={`${PONumber}-Order Type`}>
        <OrderType />
      </TableCell>
      <TableCell align="right" data-qa={`${PONumber}-Total`}>
        <b>{currencyFormatter(row.grandTotal ? row.grandTotal : 0)}</b>
      </TableCell>
      <TableCell sx={{ padding: 0 }} align="center">
        <FBOMenuButton
          variant="tertiary"
          size="small"
          items={createActionBarOptions(handleDuplicate, handleDelete, row)}
          data-qa={`${PONumber}-three-dot-menu`}
        />
      </TableCell>
    </>
  );
};

export default memo(PurchaseOrderRow);
