import { createApiAction } from 'services/api';
import {
  StartAction,
  SuccessAction,
  FailureAction,
} from 'services/api/redux/types';

export enum SettingsCompanyActionTypes {
  FETCH_COMPANY_START = 'settings/fetch_company_start',
  FETCH_COMPANY_SUCCESS = 'settings/fetch_company_success',
  FETCH_COMPANY_FAILURE = 'settings/fetch_company_failure',
}

// COMPANY ACTIONS
const fetchSettingsCompanyActionStart = (): StartAction => ({
  type: SettingsCompanyActionTypes.FETCH_COMPANY_START,
  payload: null,
});

const fetchSettingsCompanyActionSuccess = (payload: any): SuccessAction => {
  return {
    type: SettingsCompanyActionTypes.FETCH_COMPANY_SUCCESS,
    payload,
  };
};

const fetchSettingsCompanyActionFailure = (error: string): FailureAction => ({
  type: SettingsCompanyActionTypes.FETCH_COMPANY_FAILURE,
  payload: { error },
});

export const fetchSettingsCompanies = () =>
  createApiAction(
    {
      path: '/v1/company',
      method: 'GET',
    },
    fetchSettingsCompanyActionStart,
    fetchSettingsCompanyActionSuccess,
    fetchSettingsCompanyActionFailure
  );
