import _ from 'lodash';
import * as yup from 'yup';

import { Fields, Errors } from './types';

export const validateYup = (
  fields: Fields,
  schema: yup.ObjectSchema<any> | any,
  setErrors: (errors: Errors) => void
): boolean => {
  let errors: Errors = {};

  try {
    if (schema instanceof Array) {
      schema.forEach((schema) =>
        schema.validateSync(fields, { abortEarly: false })
      );
    } else {
      schema.validateSync(fields, { abortEarly: false });
    }
  } catch (err) {
    if (err instanceof yup.ValidationError) {
      errors = err.inner.reduce(
        (acc: any, e: yup.ValidationError) => ({
          ...acc,
          [e.path as string]: e.message,
        }),
        {}
      );
    }
  }

  setErrors(errors);
  return _.isEmpty(errors);
};
