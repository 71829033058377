import { PickItem, PickItemStatus } from './types';

export const initialPickItem: PickItem = {
  id: null,
  amount: null,
  autoAssign: false,
  bundleId: null,
  dateCreated: null,
  dateLastModified: null,
  dateStarted: null,
  fromLocationQuantityAvailable: null,
  inventoryEventId: null,
  item: null,
  itemId: null,
  notes: null,
  pickFromLocationId: null,
  pickFromLocation: null,
  pickId: null,
  pickToLocation: null,
  pickToLocationId: null,
  purchaseOrderItem: null,
  purchaseOrderItemId: null,
  quantity: null,
  quantityAvailable: null,
  salesOrderItem: null,
  salesOrderItemId: null,
  shipId: null,
  slotNumber: null,
  status: PickItemStatus.Available,
  trackingGroupList: [],
  uomId: null,
  version: null,
};
