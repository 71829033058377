import React from 'react';
import { Typography, Container, Box } from '@mui/material';
import { FBOLogoHorizontalDark } from 'ui/theme';

import { TermsOfServiceCmp } from './types';
import { Routes } from '../../navigation';

const TermsOfServicePage: TermsOfServiceCmp = () => {
  return (
    <div>
      <Container maxWidth="md">
        <Box mt={2}>
          <img src={FBOLogoHorizontalDark} alt="terms-logo" />
        </Box>
        <Box>
          <Typography align="right">Fishbowl Solutions</Typography>
          <Typography align="right">580 East Technology Avenue</Typography>
          <Typography align="right">Suite C1100</Typography>
          <Typography align="right">Orem, Utah 84907</Typography>
          <Typography align="right">fishbowlinventory.com</Typography>
        </Box>
        <Box>
          <Typography variant="h5">
            Fishbowl Solutions SAAS Agreement
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography>
            The Fishbowl Solutions SAAS Agreement (“Agreement”) entered into by
            and between you (“Client”) and Fishbowl Solutions, Inc., located at
            580 Technology Avenue, Suite C1100, Orem, Utah 84907 (“Fishbowl”).
            For the purposes of this Agreement Client shall be you, the party
            identified within your Fishbowl account or the electronic Order
            Form. The “Effective Date” of this Agreement shall be the date that
            you electronically accept the Agreement via the Fishbowl website or
            platform. PLEASE BE AWARE THAT THERE ARE ARBITRATION AGREEMENTS THAT
            MAY AFFECT YOUR RIGHTS.
          </Typography>
          <Box mt={2}>
            <Typography>
              This Agreement includes all terms set forth herein and any terms
              in a fully-executed Ordering Document (defined below) that
              incorporates this Agreement.{' '}
            </Typography>
          </Box>
        </Box>
        <Box mt={2}>
          <Typography variant="h5">1. Definitions</Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            1.1 "Client Data" means any and all Client data, code, software,
            customer information, content, notes, information, or other
            materials provided to Fishbowl by Client.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            1.2 “Documentation” means the materials available at:
            <a
              href="https://www.fishbowlinventory.com/wiki/Main_Page"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.fishbowlinventory.com/wiki/Main_Page
            </a>{' '}
            , as amended from time to time.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            1.3 "Intellectual Property Rights" means any patent, patent
            application, copyright, moral right, trade name, trademark, trade
            secret, and any applications or right to apply for registration
            therefor, computer software programs or applications, tangible or
            intangible proprietary information, or any other intellectual
            property right, whether registered or unregistered that are owned by
            Fishbowl.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            1.4 “Order Form” means the electronic order form executed by Client
            to order Fishbowl Products.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            1.5 “Ordering Document(s)” means either singularly or collectively
            the “Scope of Work/SOW”, “Order Form”, and/or other agreement
            executed by the Parties to order Products. Each Ordering Document
            shall be considered a separate agreement subject to this Agreement’s
            terms.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            1.6 “Party” and/or “Parties” means Fishbowl or Client in the
            singular, and Fishbowl and Client in the plural.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            1.7 “Products” mean collectively the Fishbowl Software, Services,
            and Support provided.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            1.8 “Services” means any services provided by Fishbowl including but
            not limited to implementation, consulting, training, integrations,
            customization, or other services excluding Support provided to
            Client and subject to an Ordering Document.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            1.9 “Software” means the software, data and other materials made
            available to Client by Fishbowl, including but not limited to error
            corrections, modifications and updates to such items including
            Documentation. The Fishbowl Software does not include the Third
            Party Software (defined below) or any Services (defined below)
            provided.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            1.10 “Scope of Work” or “SOW” means a scope of work which sets forth
            the Services to be provided to the Client. Where Client requires
            specific integrations or implementation a SOW must be executed by
            the Parties. Fishbowl has no obligation to offer any Services or to
            accept any proposed integration or implementation.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            1.11 “Support” means the technical support based services described
            in Section 2.8 of this Agreement.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            1.12 “Third Party Software” means any third party software that is
            separately purchased, the third party software is wholly by the
            companies and organizations that make available such third party
            software and not Fishbowl.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="h5">2. Overview of Products</Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            2.1 Products. Upon execution of this Agreement and subject to the
            terms and conditions of this Agreement, Fishbowl shall provide to
            Client a non-exclusive, worldwide, and limited right to use the
            Products during the Term (defined below) of this Agreement solely
            for the internal business operations of Client. Under no
            circumstances shall Client be allowed to use the Products to process
            information by or for the benefit of third parties. Client shall
            immediately inform Fishbowl of any claim or proceeding involving the
            Products that comes to its attention.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            2.2 Fishbowl Software Subscription. Fishbowl provides the Software
            as a subscription (a “Subscription”) as set forth within an Order
            Form. Subject to the terms and conditions of this Agreement and the
            Order Form and upon timely payment of any Fees (defined below),
            Fishbowl shall make available the Fishbowl Software to Client. Where
            any portions of the Fishbowl Software are downloaded, Fishbowl
            grants Client a worldwide, non-exclusive, non-transferable,
            non-sublicensable, terminable license to use the Fishbowl Software
            for the duration of any Order Form. During Client’s Subscription,
            Fishbowl may make updates or upgrades to the Fishbowl Software as it
            deems necessary. Fishbowl shall host the Fishbowl Software and may
            update the functionality, user interface, usability and other user
            documentation, training and educational information of, and relating
            to the Software from time to time in its sole discretion and in
            accordance with this Agreement. Regarding the Software, nothing in
            this Agreement obligates Fishbowl to: (i) make any improvements or
            updates, (ii) continue to offer any features, or (iii) offer any
            backwards compatibility to Client. As the Software is designed to
            assist medium to small sized businesses, the usage of the Software
            as determined by Fishbowl, in its discretion, should not exceed such
            typical use standards. Client’s use will not adversely impact the
            experience of other customers or constitute excess usage of
            Software. The Software is not intended to support the sustained
            demand of large enterprises, power users or other non-typical
            applications suited to dedicated servers and bandwidth
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            2.3 Fishbowl Software Enhancements. During the Term, where Client
            requires the creation of Fishbowl Software enhancements or
            additional customization modules (“Enhancement”), each Enhancement
            shall be set forth in an Ordering Document. Fishbowl has no
            obligation to accept any Enhancement requests and any Enhancement
            shall be considered a part of the Fishbowl Software and may be
            offered to other clients of Fishbowl. All Enhancements shall remain
            the sole property of Fishbowl and Fishbowl shall retain all
            Intellectual Property Rights to such Enhancements.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            2.4 Third Party Software. The Fishbowl Software does not include,
            and Fishbowl does not warrant, software obtained from any source
            other than Fishbowl including any Third Party Software (even if sold
            to Client by Fishbowl) or any software, code, data, or other
            materials provided by Client. Where Client wishes to use any Third
            Party Software in conjunction with the Software, Client may be
            required to purchase at its own expense, a license or access to the
            Third Party Software. Although Fishbowl may provide integrations
            that allow Client to use the Software with any Third Party Software,
            Fishbowl shall not be liable to Client for any use of the Third
            Party Software or interoperability of the Software with any Third
            Party Software except as set forth in an Ordering Document. The use
            of the Third Party Software is subject to any agreements, terms,
            conditions, or licenses that are solely entered in by and between
            Client and such third party and Fishbowl is not a party to such
            agreement.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            2.5 Client Requirements. Client shall be exclusively responsible for
            supervision, management and control of its use of the Products,
            including without limitation; (a) assuring proper machine
            configurations, audit controls, and operating methods; (b) creating,
            modifying, entering or reentering Client Data and maintaining any
            authorizations to such; (c) monitoring and maintaining proper access
            controls and security for its authorized users; (d) ensuring that
            all authorized users abide by this Agreement and any Ordering
            Documents; (e) complying with all reasonable instructions from
            Fishbowl regarding the Products; (f) providing all hardware and
            connections required to access the Products; (g) ensuring that
            Client’s use of the Products complies with all laws the jurisdiction
            where Client resides; and (h) restricting access to any third
            parties that are not authorized to access the Products under this
            Agreement. Client and all authorized users shall be required to
            accept and adhere to the Fishbowl Terms of Use and Privacy Policy
            located at:{' '}
            <a
              href="https://www.fishbowlinventory.com/company/legal/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.fishbowlinventory.com/company/legal/privacy{' '}
            </a>
            and{' '}
            <a
              href="https://www.fishbowlinventory.com/company/legal/license-agreement"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.fishbowlinventory.com/company/legal/license-agreement{' '}
            </a>
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            2.6 Services. All Services provided by Fishbowl shall be set forth
            within an SOW and subject to any terms in conditions set forth
            within such SOW. Fishbowl has no obligation to accept any Service
            requests. All Services provided s shall remain the sole property of
            Fishbowl and Fishbowl shall retain all Intellectual Property Rights
            to such Services.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            2.7 Satisfaction Guarantee. Fishbowl provides a satisfaction
            guarantee (“Satisfaction Guarantee”) to all Clients subject to the
            following conditions:
            <span style={{ paddingLeft: 40 }}>
              (a) Client must purchase and begin an on-site training or virtual
              on-site training via an SOW. Client must purchase a minimum two
              (2) day training to be conducted at Client’s facility or a minimum
              two (2) day virtual on-site training to be conducted virtually.
              All training must be conducted by an employee(s) of Fishbowl. In
              order for the above described training to qualify for the
              guarantee set forth within this Section, the training must be
              purchased within thirty (30) days of the Effective Date of this
              Agreement. All training must be completed within one hundred
              eighty (180) days from the date the training is purchased;
            </span>
            <br />
            <span style={{ paddingLeft: 40 }}>
              (b) Clients electing to purchase training must actively use the
              Software for sixty (60) days after the final day of training
              (“Guarantee Period”).
            </span>
            <br />
            Following the expiration of the Guarantee Period, Clients will have
            thirty (30) days to request a refund for the Software and terminate
            this Agreement. The Satisfaction Guarantee is limited to the
            Software Subscription Fees and specifically excludes all Services
            purchased from Fishbowl. This Satisfaction Guarantee also excludes
            all software and services provided by a third-party company or any
            Third Party Software, even where this item is sold by Fishbowl.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            2.8 Support and Availability. Support for the Software shall be
            generally available to Client, specifically, Fishbowl shall provide
            phone support Monday through Friday, as available, from the hours of
            9AM to 5PM MST excluding holidays. Any Support requests from Client
            shall be dealt with in the order of receipt and severity. The
            Fishbowl Software will be generally available and accessible, except
            for scheduled downtime and maintenance, each calendar month. As
            Client's sole and exclusive remedy, if downtime where agreed upon in
            an Order Form, other than scheduled downtime and maintenance,
            exceeds any guaranteed level, then Fishbowl agrees to credit toward
            the next month's Fees a prorated amount equal to the Fees
            attributable to such downtime. For any scheduled downtime Fishbowl
            may attempt to alert Client to such scheduled downtime as permitted.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="h5">3. Payment</Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            3.1. Fees. Client will pay to Fishbowl all fees and expenses (the
            “Fees”) as specified in any Ordering Documents. Fees for Software
            Subscription are charged on an annual basis. Any Fees shall be
            invoiced to Client from time to time in accordance with Fishbowl’s
            invoicing policies. Where the due date for an invoice is not
            otherwise specified in the Ordering Document, all Fees are due
            within five (5) days from the date of invoice issuance. All Fees are
            non-refundable except where expressly stated otherwise. Unless
            otherwise specified, all Fees are in U.S. dollars. Client shall pay
            the Fees to Fishbowl in a method agreed upon between the Parties or
            as set forth within an Ordering Document. In the event of any
            dispute, Client shall continue to be responsible for paying all Fees
            and shall not withhold any Fees, disputed or undisputed from
            Fishbowl. Where Client fails to timely pay any Fees, Fishbowl may
            suspend Client’s access to all Products at its discretion. Where
            Client fails to pay an invoice in a timely manner, Fishbowl reserves
            the right to terminate this Agreement at its discretion.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            3.2 Taxes. Client is responsible for any duties, customs fees, or
            taxes (other than Fishbowl’s income tax) associated with the
            purchase of the Products provided by Fishbowl, (including any
            related penalties or interest) (“Taxes”), and shall pay the listed
            Fees without deduction for Taxes. If Client is required by local law
            to withhold any amount from any payments specified in an Ordering
            Document, then Client will pay Fishbowl the listed Fees as if no
            withholding were required, and shall remit the withholding to the
            appropriate governmental authorities on behalf of Fishbowl, with a
            copy of the tax receipt or certificate forwarded to Fishbowl.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            3.3 Expenses. Any expenses shall be set forth in an Ordering
            Document and any expenses exceeding one thousand dollars ($1,000)
            shall require approval from Client. All expenses shall be set forth
            in any invoice and shall be billed on the same basis as any Fees
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            3.4 Prior to any Subscription term renewal, Fishbowl reserves the
            right to increase any Fees it its discretion. Where Fishbowl
            exercises such right, Fishbowl shall provide notice of a Fee
            increase and Client may provide thirty (30) days written notice to
            terminate this Agreement prior to the commencement of the new term.
            Where Client does not provide written notice of termination such Fee
            increase shall be deemed accepted by Client at the commencement of
            the new term.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            3.5 Delinquencies. Any late payments by Client will accrue late
            charges at the rate of 1.5% of the outstanding balance per month, or
            the maximum rate permitted by law, whichever is lower (such charges,
            “Interest Charges”). Client shall pay to Fishbowl any Interest
            Charges as invoiced by Fishbowl. Where Client fails to timely pay
            any invoices, Fishbowl may at its discretion suspend its provision
            of Services to Client until such invoice is paid. In addition,
            Client will be responsible for any costs resulting from collections
            of any undisputed, overdue balance, including, without limitation,
            reasonable attorneys’ fees and court costs. Where Client has failed
            to pay any overdue invoices for a period of over sixty (60) days,
            Fishbowl may terminate this Agreement termination does not excuse
            Client from paying any Fees that have accrued up to and until the
            date of termination.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            3.6 Where Client’s access to the Products has been suspended due to
            non-payment of Fees, Fishbowl shall have no obligation to retain any
            Client Data after a period of sixty (60) days following the date of
            such suspension.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="h5">4. Restrictions</Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            Where applicable, all Intellectual Property Rights in the Products
            are reserved to Fishbowl or its licensors. Without limiting the
            foregoing, Client shall not, and shall not permit others under its
            control to (1) copy, modify, create a derivative work of, reverse
            engineer, decompile, translate, disassemble, or otherwise attempt to
            extract the source code of the Products or any component thereof;
            (2) assign, sublicense, transfer, lease, rent or otherwise
            distribute or make available the Products to any third party; (3)
            use the Products for High Risk Activities or otherwise contrary to
            the instructions of Fishbowl; or (4) use the Products to create,
            deliver training on, improve (directly or indirectly) or offer a
            substantially similar product or service. “High Risk Activities”
            means uses such as the operation of nuclear facilities, air traffic
            control, or life support systems, where the use or failure of the
            Products that could lead to death, personal injury, or environmental
            damage. Fishbowl has no obligation to monitor Clients use of the
            Products; however, Fishbowl may do so and may prohibit any use of
            the Products it believes may be (or alleged to be) in violation of
            this Agreement, any applicable Fishbowl Terms of Use or Privacy
            Policy, or if it believes that Client’s actions may harm Fishbowl or
            a third party.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="h5">
            5. Proprietary Rights and Client Data
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            5.1. Fishbowl’s Proprietary Rights. Fishbowl retains all right,
            title and interest in and to all its general techniques, codes,
            ideas, reports, methods, processes, routines, tools, concepts,
            inventions, patterns, algorithms, techniques and know-how developed
            previous to or during the provision of the Products (except where
            expressly stated otherwise) provided by Fishbowl to Client under
            this Agreement. Further, Client acknowledges that the structure,
            organization, and code of the Fishbowl Products and any software
            components thereof are proprietary to Fishbowl and/or Fishbowl's
            licensors and that Fishbowl and/or its licensors retains exclusive
            ownership of the Fishbowl Products, trademarks, and any and all
            other Intellectual Property Rights relating to the Fishbowl
            Software, including all Enhancements, modifications, derivatives,
            and other software and materials developed hereunder by Fishbowl,
            and all copies thereof. Client shall not remove any proprietary,
            copyright, trademark, or service mark legend from any portion of any
            of the Fishbowl Products, including any modifications, Software,
            Enhancements, derivatives, and other software and materials
            developed by Fishbowl. Client agrees to make all necessary and
            reasonable efforts to comply with the above provisions in a manner
            which Client takes to secure and protect its own intellectual
            property
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            5.2. Trademarks. “Fishbowl Solutions”, “Fishbowl Inventory”, the
            Fishbowl logo and any other trademarks and service marks adopted by
            Fishbowl to identify the Fishbowl Products are wholly owned or
            licensed to Fishbowl. Client has no rights in such marks except as
            specified in writing between the parties. Client may not publicly
            display the Fishbowl logo or any associated marks without express
            written consent from Fishbowl.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            5.3. Feedback. If Client provides Fishbowl any suggestions or
            feedback regarding the Products (“Client Feedback”), then Fishbowl
            may use the Client Feedback without obligation to Client, and Client
            hereby irrevocably assigns to Fishbowl all right, title, and
            interest in that Client Feedback.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            5.4 Client Data. Client owns all Client Data provided to Fishbowl.
            Title to Client Data and any copy thereof remains with Client.
            Notwithstanding Client's ownership of its Client Data, Client grants
            to Fishbowl a limited, royalty free, revocable license to the Client
            Data for the purposes of providing the Products contemplated under
            this Agreement. Client represents and warrants it has all title,
            right, and interest in the Client Data and that all Client Data is
            compliant with all laws and regulations of Client’s jurisdiction.
            During Client’s use of the Products, Client shall not upload any
            Client Data that is in violation of any US state or federal laws or
            laws applicable to Client. Fishbowl shall have no obligation to
            store or keep any Client Data in the event that Client fails to
            timely pay any Fees. In addition, Client grants Fishbowl the right
            to use such Client Data to help diagnose problems with Fishbowl’s
            servers and to administer and improve the Products. While Fishbowl
            may make back-ups of Client Data on a reasonable schedule, Fishbowl
            is not responsible for lost or destroyed Client Data, which loss or
            destruction shall be at Client's sole risk.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="h5">6. Term & Termination</Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            6.1. Agreement Term. This Agreement will begin on the Effective Date
            and continue until the latter of the following: (1) one year
            (“Initial Term”); (2) the expiration or completion of all
            commitments under any open Ordering Documents; or (3) the date on
            which the Agreement is terminated as specified herein, (such term
            including any automatic renewals, the “Term”). Upon expiration of
            the Initial Term this Agreement shall automatically renew for
            additional one (1) year periods until terminated by either Party.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            6.2. Termination for Convenience. After the Initial Term, each Party
            may terminate this Agreement without cause by providing the other
            with thirty (30) days written notice to the other Party. Where
            Client exercises termination under this Section 6.2 any pre-paid or
            deposited funds for any Products shall not be refunded.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            6.3. Termination for Breach. Either Party may terminate the
            Agreement if the other Party breaches any material term of the
            Agreement and fails to cure that breach within thirty (30) days
            after receipt of written notice of such breach by the non-breaching
            Party.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            6.4 Trial Termination. Aside from the Satisfaction Guarantee offered
            to Client, Client may terminate this Agreement within fourteen (14)
            days of the Effective Date of this Agreement. Where Client exercises
            termination under this Section, Client shall be entitled to a refund
            of all Fees paid for any Products except for any Fees paid for
            Services rendered.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            6.5. Effect of Termination. If the Agreement expires or is
            terminated, then: (1) the rights granted by one Party to the other
            will immediately cease (except where these rights must be granted in
            furtherance of any additional obligations arising from the
            Agreement); (2) all Fees accrued until termination and owed by
            Client are immediately due upon receipt of a final invoice from
            Fishbowl; and (3) upon request, each Party will use commercially
            reasonable efforts to return or destroy all Confidential Information
            of the other Party and certify that it has done so in writing.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            6.6 Data Migration. Where this Agreement is terminated and where no
            Fees are outstanding, Fishbowl shall make the Client Data stored
            within the Software available for migration by Client for a period
            of sixty (60) days. Except as set forth in an Ordering Document,
            Fishbowl shall have no obligation to offer any Services related to
            data migration unless agreed upon.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="h5">7. Confidentiality</Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            7.1. Confidentiality Obligation. During the term of this Agreement,
            both parties agree that (1) Confidential Information will be used
            only in accordance with the terms and conditions of the Agreement;
            (2) each will use the same degree of care it uses to protect its own
            confidential information, but in no event less than reasonable care;
            and (3) the Confidential Information may be disclosed only to
            employees, agents and contractors with a need to know, and to its
            auditors and legal counsel, in each case, who are under a written
            obligation to keep such information confidential using standards of
            confidentiality not less restrictive than those required by this
            Agreement. Fishbowl may use Client’s Data for intended business
            purposes only, including but not limited to providing Support,
            sharing with third party contractors that assist Fishbowl in
            providing the Products, and improving, testing and providing the
            Products. “Confidential Information” means any information
            designated as confidential orally or in writing by either party, or
            any information that the receiving party knows, or has reason to
            know, is confidential or proprietary based upon its treatment by the
            disclosing party.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            7.2. Exclusions. This Agreement imposes no obligation with respect
            to Confidential Information which: (1) is a part of or enters into
            the public domain; (2) was already in the recipient’s possession
            prior to the date of disclosure other than by breach of this
            Agreement; (3) is rightfully received from a third party without any
            duty of confidentiality; (4) is independently developed without
            reference to the Confidential Information of the disclosing party.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="h5">8. Warranties</Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            8.1 Services Warranty. Fishbowl warrants that it will perform any
            Services with reasonable care and skill and consistent with
            standards generally observed for Services of a substantially similar
            nature. If the Services fail to conform to the foregoing warranty,
            as Client’s sole and exclusive remedy for such failure, Fishbowl
            will promptly attempt to re-perform the applicable Services within
            thirty (30) days or such longer period as agreed to by the Parties
            in writing or at Fishbowl’s option and sole discretion refund any
            Fees Client has previously paid for the applicable Service(s).
            Client shall have deemed to have accepted any Services provided
            where it fails to provide written notice within ten (10) business
            days of receiving such Services.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            8.2 Fishbowl Software Warranty. Fishbowl represents and warrants
            that the Fishbowl Software will substantially conform in accordance
            with any then published Documentation provided.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            8.3 Harmful Code. Fishbowl will use commercially reasonable efforts
            and commercially available technology to scan the Software made
            available to Client for, and to remove from the then-current-version
            of the Software, any computer “viruses,” “worms” and other malicious
            code.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            8.4 Authority. Each Party warrants that it has full power and
            authority to enter into this Agreement.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            8.5 No Lawsuits. Each Party represents and warrants that entering
            into this Agreement shall not violate a third party right or
            interest or that there is any pending lawsuit or litigation that
            would preclude it from entering into this Agreement.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            8.6 Client acknowledges that any reports generated or results
            obtained or acquired through the use of the Products are used at
            Client's sole risk and discretion. As Client inptus all Client Data,
            Fishbowl is not liable or responsible for any results generated
            through the use of the Software.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            8.7 Client represents and warrants that it shall abide by all
            relevant laws, rules, or regulations when using the Products. Client
            represents and warrants that Client has received all consents
            necessary for any Client Data submitted to the Software and such
            Client Data is complaint with any data privacy laws that it is
            subject to including but not limited to the California Consumer
            Privacy Act, as amended, or the General Data Protection Regulation,
            as amended.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            8.8 EXCEPT AS EXPRESSLY PROVIDED IN THIS SECTION , NEITHER FISHBOWL
            NOR ITS AFFILIATES PROVIDES ANY OTHER REPRESENTATIONS OR WARRANTIES
            REGARDING THE PRODUCTS, AND TO THE FULLEST EXTENT PERMITTED BY LAW
            DISCLAIMS ALL OTHER WARRANTIES, REPRESENTATIONS, TERMS AND
            CONDITIONS, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY
            IMPLIED WARRANTIES OF MERCHANTABILITY, ACCURACY, QUALITY, FREEDOM
            FROM COMPUTER VIRUS, FITNESS FOR A PARTICULAR PURPOSE AND
            NON-INFRINGEMENT, AND ANY WARRANTIES, TERMS AND CONDITIONS ARISING
            OUT OF COURSE OF DEALING OR USAGE OF TRADE. NO ADVICE OR
            INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM FISHBOWL OR
            ELSEWHERE WILL CREATE ANY WARRANTY, TERM OR CONDITION UNLESS
            EXPRESSLY STATED IN THIS AGREEMENT.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="h5">9. Limitation of Liability</Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            To the fullest extent permitted by applicable law, in no event shall
            Fishbowl or its AFFILIATES be liable for damages other than direct
            damages, including the cost of procurement of substitute goods or
            technology, loss of profits, or for any special, consequential,
            incidental, punitive or indirect damages on any theory of liability,
            whether in statute, contract, tort (including negligence), strict
            liability or otherwise, even if advised of the possibility of such
            damages. To the fullest extent permitted by applicable law, in no
            event shall the total liability of Fishbowl to Client under this
            Agreement exceed the greater of the total amounts paid by Client to
            Fishbowl during the TWELVE (12) months prior to the date the claim
            arises, or fifty thousand US dollars ($50,000), whichever is LESSER.
            The liability limitations in this paragraph (and otherwise in the
            Agreement) do not limit or exclude damages for bodily injury or
            death or other damages that under applicable law cannot be limited
            or excluded.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="h5">10. Indemnity</Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            10.1. Indemnity by Fishbowl. Fishbowl shall defend or settle at its
            sole expense any claim brought against Client, its directors,
            officers, or employees by a third party alleging that the Fishbowl
            Software infringes or misappropriates any patent, copyright, or
            trade secret of a third party, and Fishbowl shall pay all damages
            finally awarded or costs of settlement of the claim provided that
            Client: (a) provides Fishbowl prompt written notice of any claim;
            (b) gives Fishbowl sole control of the defense and settlement of the
            claim; and (b) provides all reasonable assistance in connection with
            the claim. If Client’s rights to use the Fishbowl Software are, or
            in Fishbowl’s opinion could be, enjoined due to an indemnified
            claim, then Fishbowl may, at its sole discretion and expense: (1)
            procure for Client the right to continue using the Fishbowl Software
            according to the terms of this Agreement, (2) modify the Fishbowl
            Software such that it operates with materially equivalent
            functionality without infringing or misappropriation, or (3) if
            neither of the foregoing options is commercially reasonable,
            terminate the Agreement and refund the unused portion of any Fees
            paid
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            10.2 Exclusions. The indemnity provided by Fishbowl under this
            Agreement does not extend to claims arising from or relating to (1)
            use of the Third Party Software or any software provided by Client;
            (2) modifications to the Software not provided by or approved in
            writing by Fishbowl; (3) use of the Software in combination with any
            data, software, or hardware not provided by Fishbowl to the extent
            the alleged infringement would not have occurred without the
            combination; (4) use of the Software that is outside the scope of
            permissible use as defined by Fishbowl or (5) allegedly infringing
            activities that continue after Fishbowl has informed Client in
            writing of and made available to Client at no additional charge a
            version of the Software that would have avoided the alleged
            infringement.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            10.3. Remedy. This section states Client’s sole and exclusive remedy
            with respect to claims of infringement of third party proprietary
            rights of any kind and is subject to the terms of the section titled
            “Limitation of Liability.”
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            10.4. Additional Indemnity. Client will indemnify and hold Fishbowl,
            its directors, managers, officers, members, employees and agents,
            harmless from and against all liabilities, losses, costs, damages
            and expenses, (including reasonable attorneys' fees and court
            costs), relating to or arising from: (1) the unpermitted use of the
            Products by Client or its authorized users; (2) any information or
            results obtained through the use of the Products; and (3) any breach
            of this Agreement by Client and/or its authorized users.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="h5">11. Miscellaneous</Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            11.1. Publicity. Client may state publicly that it is a user of the
            Products, and Fishbowl may identify Client as a user of the
            Products, provided that any such statements shall conform with the
            trademark usage guidelines provided by one Party to the other. Each
            party must obtain the other Party’s advance written consent to any
            press release relating to use of the Products.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            11.2. Assignment. The Agreement may not be assigned by either Party
            by operation of law or otherwise, without the prior written consent
            of the other party, which consent will not be unreasonably withheld.
            Notwithstanding the foregoing, either Party may assign this
            Agreement in its entirety (including all Ordering Documents),
            without consent of the other Party, in connection with a merger,
            acquisition, corporate reorganization, or the sale of all or
            substantially all of the assets of the business to which the
            Agreement relates.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            11.3. Conflicting Terms. If there is a direct conflict among the
            documents that make up the agreement between the Parties, the
            documents will control in the following order: (1) this Agreement,
            (2) Ordering Documents, and (3) Fishbowl Terms of Use/Privacy
            Policy.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            11.4. Entire Agreement. The Agreement along with any Ordering
            Documents and the Terms of Use and Privacy Policy sets out all terms
            agreed between the parties and supersedes all other agreements
            between the parties relating to its subject matter. In entering into
            the Agreement, neither party has relied on, and neither party will
            have any right or remedy based on, any statement, representation or
            warranty (whether made negligently or innocently), except those
            expressly set out in the Agreement.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            11.5. Export Controls. Client agrees to comply with all export and
            re-export restrictions and regulations of the Department of Commerce
            and any other United States or foreign agencies and authorities in
            connection with Client’s use of the Products. In particular, but
            without limitation, the Software may not, in violation of any laws,
            be exported or re-exported (1) into any U.S. embargoed country or
            (2) to anyone on the U.S. Treasury Department’s list of Specially
            Designated Nationals or the U.S. Commerce Department’s Table of Deny
            Orders. Client represents and warrants that Client are not located
            in, under the control of, or a national or resident of any such
            country or on any such list.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            11.6. Force Majeure. Neither party will be liable for failure or
            delay in its performance under the Agreement to the extent caused by
            circumstances beyond its reasonable control including but not
            limited to war, fire, riot, strike, contagion, internet outage,
            catastrophic event, or other events beyond its reasonable control.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            11.7. Governing Law. The Agreement is to be construed in accordance
            with and governed by the internal laws of the State of Utah without
            regard to its conflict of laws principles. Any and all disputes
            arising out of or relating to this Agreement, including fraud in the
            inducement, whether in contract or tort, law or equity, shall be
            resolved by final arbitration before one arbitrator in accordance
            with the then applicable rules of Judicial Arbitration and Mediation
            Implementation Services, Inc. Any arbitration shall take place in
            Orem, UT. Judgment upon any award rendered by the arbitrator may be
            entered by any state or federal court having jurisdiction thereof.
            The Parties agree that any claim must be brought individually and
            may not be brought as a class action or other multi-party claim. Any
            claim or cause of action arising out of, related to or connected
            with this Agreement must be filed within one (1) year after such
            claim or cause of action arose or be forever banned. The dispute
            resolution procedures in this section shall not apply prior to a
            Party seeking a provisional remedy related to claims of
            misappropriation or ownership of Intellectual Property Rights,
            non-payment, trade secrets, or claims requiring emergency or
            injunctive relief such claims shall be heard in a court of competent
            jurisdiction located within Utah County, Utah and either Party may
            lodge such claims without having to post a bond.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            11.8. Independent Contractors. The Agreement will not be construed
            as creating an agency, partnership, joint venture or any other form
            of association, for tax purposes or otherwise, between the parties;
            the parties will at all times be and remain independent contractors.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            11.9. No Third-Party Beneficiaries. The Agreement does not confer
            any benefits on any third party unless it expressly states that it
            does.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            11.10. Notices. All notices to Fishbowl must be in writing and
            addressed to: <br />
            <span style={{ paddingLeft: 40 }}>
              Fishbowl Solutions, Inc.
            </span>{' '}
            <br />
            <span style={{ paddingLeft: 40 }}>Whitney Taylor</span> <br />
            <span style={{ paddingLeft: 40 }}>
              <a
                href="whitney.taylor@fishbowlinventory.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Whitney.Taylor@fishbowlinventory.com
              </a>
            </span>
            <br />
            <span style={{ paddingLeft: 40 }}>
              580 East Technology Ave, Suite C1100 Orem, Utah 84097
            </span>
            <br />
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            And to Client’s point of contact as set forth within the relevant
            Ordering Document or online Fishbowl account. Notice may be made by
            courier, certified mail, or email and will be treated as given on
            receipt, as verified by delivery receipt, automatic receipt, or by
            electronic log (as applicable).
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            11.11. Severability, Waiver, Interpretation. In the event that any
            provision of the Agreement (or any portion hereof) is determined by
            a court of competent jurisdiction to be illegal, invalid or
            otherwise unenforceable, the provision (or portion) will be enforced
            to the extent possible consistent with the stated intention of the
            Parties, or, if incapable of enforcement, will be deemed to be
            severed and deleted from this Agreement, while the remainder of the
            Agreement will continue in full force. The waiver by either Party of
            any default or breach of this Agreement will not constitute a waiver
            of any other or subsequent default or breach. This Agreement is
            mutually drafted and any ambiguities shall be interpreted by its
            plain meaning.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            11.12. Survival. Any portion of the Agreement that a reasonable
            party would deem to survive termination of this Agreement shall
            survive including but not limited to: Payment Obligations,
            Restrictions, Proprietary Rights, Effect of Termination,
            Indemnification, Confidentiality, Limitation of Liability, relevant
            Ordering Document provisions, and Miscellaneous.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            11.13. Modifications. Except as expressly provided herein, no
            modification of the Agreement will be effective unless contained in
            writing and signed by an authorized representative of each party.
          </Typography>
        </Box>
        <Box mt={2} pb={5}>
          <Typography>
            11.14 Electronic Signature. Where this Agreement is executed
            electronically, Client agrees that by clicking “I Agree” (or by
            otherwise consenting) Client shall be bound by this Agreement and
            the Agreement shall be executed by Company on the date of such
            consent. By accepting this Agreement, you agree and consent to
            receive electronically all communications, agreements, documents,
            notices and disclosures (collectively, “Communications”) that
            Fishbowl provides in connection with Client’s use of the Fishbowl
            products. Client also agrees that electronic delivery of a
            Communication has the same legal effect as if Fishbowl provided
            Client with a physical copy. A Communication will be considered to
            have been received by Client within 24 hours of the time a
            Communication is either posted on the Fishbowl website or emailed to
            Client.
          </Typography>
        </Box>
      </Container>
    </div>
  );
};

TermsOfServicePage.route = Routes.TermsOfService;

export default TermsOfServicePage;
