import { IconNames } from 'ui/theme';
import { Pick, PickStatus } from 'services/picking';
import { MenuItem } from 'ui/components/Menu';

export const defaultActivePick: Pick = {
  id: -1,
  customFields: [],
  dateCreated: null,
  dateLastModified: null,
  notes: null,
  number: '',
  orderPriorityId: null,
  parentLocationId: null,
  parentLocation: null,
  pickId: null,
  pickItems: [],
  pickBundleItems: [],
  purchaseOrders: [],
  salesOrders: [],
  scheduledDate: null,
  shipments: [],
  stockStatus: null,
  status: PickStatus.Open,
  userId: -1,
  version: -1,
  deleted: false,
};

export const createActionBarOptions = (
  id: number | null,
  onShowReport: () => void
): MenuItem[] => [
  {
    icon: IconNames.FBOReport,
    label: 'Show Report',
    onOptionClick: onShowReport,
    disabled: !id || id < 0,
    dataQa: 'show-report-button',
  },
];
