import { MenuItem, MenuItemVariant } from 'ui/components/Menu';
import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable/types';
import { IconNames } from 'ui/theme';

export const ADDRESSES_COLUMNS: Column[] = [
  {
    title: 'Name',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'City',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'ZIP',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: '',
    width: 50,
    type: ColumnTypes.renderByCustomRow,
  },
];

export enum AddressTabAction {
  Delete,
}

export const createActionBarOptions = (
  handleActionClick: (type: AddressTabAction) => void
): MenuItem[] => [
  {
    label: 'Delete Address',
    onOptionClick: () => handleActionClick(AddressTabAction.Delete),
    icon: IconNames.FBODeleteSmall,
    variant: MenuItemVariant.Danger,
  },
];

export const defaultContact = {
  type: null,
  value: null,
  name: null,
  id: null,
  dateCreated: null,
  dateLastModified: null,
  defaultFlag: null,
  deleted: null,
  version: null,
};
