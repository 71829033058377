import React, { memo, useCallback } from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import {
  useHandleCheckboxChange,
  useHandleTextFieldChange,
} from 'services/forms/hooks';
import { Location } from 'services/locations';

import { ReportFiltersProps } from '../../types';
import { CountComponent } from '../CountComponent/CountComponent';

const LocationBarcodeFilters: React.FC<ReportFiltersProps> = (props) => {
  const { parameters, setParameters } = props;

  const handleLocationChange = useCallback(
    (location: Location | null) => {
      setParameters((old) => ({
        ...old,
        locationId: location ? location.id : null,
      }));
    },
    [setParameters]
  );

  const handleNumberFieldChange = useHandleTextFieldChange(
    setParameters,
    parameters,
    false
  );

  const handleCheckboxChange = useHandleCheckboxChange(setParameters);

  // TODO : RESTYLING : Remove box wrappers around inputs after FF removal

  return (
    <>
      <Accordion square defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <b>Optional</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%">
            <Box>
              <LocationsAsyncAutocomplete
                label="Location"
                value={parameters.locationId}
                placeholder="Select location"
                companyWide={false}
                onChange={handleLocationChange}
              />
            </Box>
            <FormControlLabel
              className="redesign"
              sx={{ marginBottom: '16px' }}
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(parameters.showLocked)}
                  onChange={handleCheckboxChange}
                  name="showLocked"
                  color="primary"
                />
              }
              label="Show Locked Locations"
            />
            <CountComponent
              parameters={parameters}
              handleTextFieldChange={handleNumberFieldChange}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default memo(LocationBarcodeFilters);
