import React from 'react';

import { PaymentModalProps } from './types';
import { PaymentProvider } from './PaymentProvider';
import PaymentModalContent from './PaymentModalContent';

const PaymentModal: React.FC<PaymentModalProps> = (props) => {
  const { activeItem: salesOrder } = props;

  return (
    <PaymentProvider salesOrder={salesOrder}>
      <PaymentModalContent {...props} />
    </PaymentProvider>
  );
};

export default React.memo(PaymentModal);
