import React, { useCallback, useState, memo, useEffect } from 'react';

import {
  globalSettingsRowDescriptor,
  globalSettingsCardId,
  title,
} from './consts';
import { Card } from '../Card';
import {
  GlobalSettings,
  getGlobalSettings,
  globalSettingsPut,
} from 'services/commerce/settings';
import { showNotification } from 'services/api';
import { fetchSellwareActiveAccount } from 'services/commerce';
import { useDispatch } from 'react-redux';

const SettingsCard = () => {
  const [globalSettings, setGlobalSettings] = useState<GlobalSettings>();
  const [cardDataLoaded, setCardDataLoaded] = useState(false);
  const [tagsLoaded, setTagsLoaded] = useState(false);
  const [locationsLoaded, setLocationsLoaded] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (globalSettings && tagsLoaded && locationsLoaded) {
      setCardDataLoaded(true);
    }
  }, [globalSettings, tagsLoaded, locationsLoaded]);

  const handleApplyClicked = useCallback(async (settings) => {
    try {
      await globalSettingsPut(settings);
    } catch (e) {
      // continue
    }
    callGetGlobalSettings();
    // @ts-ignore
    dispatch(fetchSellwareActiveAccount());
  }, []);

  const callGetGlobalSettings = async () => {
    try {
      const response = await getGlobalSettings();
      setGlobalSettings(response);
    } catch (e) {
      setCardDataLoaded(true);
      showNotification(
        'There was an error connecting to Fishbowl Commerce. Try again later.',
        {
          variant: 'error',
        }
      );
    }
  };

  useEffect(() => {
    callGetGlobalSettings();
  }, []);

  return (
    <Card
      title={title}
      rows={globalSettingsRowDescriptor}
      id={globalSettingsCardId}
      data={globalSettings}
      onApplyClicked={handleApplyClicked}
      show
      dataQa="commerce-global-settings"
      cardDataLoaded={cardDataLoaded}
      setTagsLoaded={setTagsLoaded}
      setLocationsLoaded={setLocationsLoaded}
    />
  );
};

export default memo(SettingsCard);
