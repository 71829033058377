import { RowDescriptor } from './components/Card';

export const searchFilter = (rowDescriptor: RowDescriptor[], regex: RegExp) => {
  if (regex.source === '(?:)') {
    return rowDescriptor;
  }

  const marked = rowDescriptor.map((t) => {
    return {
      ...t,
      title: t.title.replace(regex, (match) => `<mark>${match}</mark>`),
    };
  });

  return marked;
};

export const searchTitle = (title: string, regex: RegExp) => {
  if (regex.source === '(?:)') {
    return title;
  }

  return title.replace(regex, (match) => `<mark>${match}</mark>`);
};
