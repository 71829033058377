import React, { useState, memo, useCallback, useMemo } from 'react';
import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';

import { useSelector } from 'react-redux';
import _ from 'lodash';
import { DateRange } from 'materialui-daterange-picker';

import { AdvancedSearchFieldsCmpProps } from 'ui/components/Page/PageWithAdvancedSearch';
import { getRepresentatives, Representative } from 'services/representatives';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { Customer, getCustomers } from 'services/customers';
import { getPaymentTerms, PaymentTerm } from 'services/paymentTerms';
import { Tag } from 'services/tags';
import {
  COUNTRIES,
  CountryAutocomplete,
  CountryType,
} from 'ui/components/Autocomplete/CountryAutocomplete';
import DateRangePicker from 'ui/components/TextField/DateRangePicker/DateRangePicker';
import { getRangeForRequest } from 'ui/components/TextField/DateRangePicker/helpers';
import { transformDateToMomentDate } from 'helpers';
import { TagsAsyncAutocomplete } from 'ui/components/Autocomplete/TagsAutocomplete';

const CustomersAdvancedSearch: React.FC<AdvancedSearchFieldsCmpProps> = (
  props
) => {
  const { setFormValues, formValues } = props;

  const { items: representatives } = useSelector(getRepresentatives);
  const { items: customers } = useSelector(getCustomers);
  const { items: paymentTerms } = useSelector(getPaymentTerms);

  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);

  const tagsIds = useMemo(() => {
    return formValues['tags.id'] as number[];
  }, [formValues]);

  const dateCreatedRange: DateRange | null = useMemo(() => {
    if (!formValues.dateCreatedFrom || !formValues.dateCreatedTo) {
      return null;
    }

    return {
      startDate: transformDateToMomentDate(
        formValues.dateCreatedFrom as string
      )!,
      endDate: transformDateToMomentDate(formValues.dateCreatedTo as string)!,
    };
  }, [formValues.dateCreatedFrom, formValues.dateCreatedTo]);

  const dateLastModifiedRange: DateRange | null = useMemo(() => {
    if (!formValues.dateLastModifiedFrom || !formValues.dateLastModifiedTo) {
      return null;
    }

    return {
      startDate: transformDateToMomentDate(
        formValues.dateLastModifiedFrom as string
      )!,
      endDate: transformDateToMomentDate(
        formValues.dateLastModifiedTo as string
      )!,
    };
  }, [formValues.dateLastModifiedFrom, formValues.dateLastModifiedTo]);

  const selectedCustomer = useMemo(() => {
    return customers.find((c) => c.id === formValues.parentCustomerId) || null;
  }, [formValues.parentCustomerId, customers]);

  const selectedPaymentTerm = useMemo(() => {
    return paymentTerms.find((p) => p.id === formValues.paymentTermId) || null;
  }, [formValues.paymentTermId, paymentTerms]);

  const selectedRepresentative = useMemo(() => {
    return (
      representatives.find((r) => r.id === formValues.representativeId) || null
    );
  }, [formValues.representativeId, representatives]);

  const selectedCountry = useMemo(() => {
    return (
      COUNTRIES.find(
        (c) => c.code === formValues['customerAddresses.country']
      ) || null
    );
  }, [formValues]);

  const handleCustomerChange = useCallback(
    (e: React.ChangeEvent<{}>, value: Customer | null) => {
      setFormValues({
        ...formValues,
        parentCustomerId: value ? value.id : null,
      });
    },
    [formValues, setFormValues]
  );

  const handleRepresentativeChange = useCallback(
    (e: React.ChangeEvent<{}>, value: Representative | null) => {
      setFormValues({
        ...formValues,
        representativeId: value ? value.id : null,
      });
    },
    [formValues, setFormValues]
  );

  const handleShowDeletedChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setFormValues({ ...formValues, deleted: checked ? 'true' : null });
    },
    [formValues, setFormValues]
  );

  const handlePaymentTermChange = useCallback(
    (e: React.ChangeEvent<{}>, value: PaymentTerm | null) => {
      setFormValues({ ...formValues, paymentTermId: value ? value.id : null });
    },
    [formValues, setFormValues]
  );

  const handleTagsChange = useCallback(
    (value: Tag[]) => {
      setSelectedTags(value);
      setFormValues({
        ...formValues,
        'tags.id': value.length ? value.map((i) => i.id!) : null,
      });
    },
    [formValues, setFormValues]
  );

  const handleCountryChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, value: CountryType | null) => {
      setFormValues({
        ...formValues,
        'customerAddresses.country': _.get(value, 'code', ''),
      });
    },
    [formValues, setFormValues]
  );

  const handleDateRangeChange = useCallback(
    (field: string) => (value: DateRange) => {
      const requestRange = getRangeForRequest(field, value);

      if (!requestRange) {
        return;
      }

      setFormValues({
        ...formValues,
        ...requestRange,
      });
    },
    [formValues, setFormValues]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Autocomplete
          label="Sales Representative"
          placeholder="Select sales representative"
          options={representatives}
          getOptionLabel={(option: Representative) =>
            option.firstName + ' ' + (option.lastName || '')
          }
          value={selectedRepresentative}
          onChange={handleRepresentativeChange}
          dataQa="select-sales-representative"
        />
      </Grid>
      <Grid item xs={6}>
        <CountryAutocomplete
          value={selectedCountry}
          onChange={handleCountryChange}
          label="Country"
          placeholder="Select country"
          data-qa="select-country"
        />
      </Grid>
      <Grid item xs={6}>
        <TagsAsyncAutocomplete
          label="Tags"
          placeholder="Select tag"
          value={selectedTags}
          onChange={handleTagsChange}
          ids={tagsIds}
          disableTagsModal
          dataQa="select-tag"
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          label="Parent Customer"
          placeholder="Select parent customer"
          options={customers}
          getOptionLabel={(c) => c.name}
          value={selectedCustomer}
          onChange={handleCustomerChange}
          dataQa="select-parent-customer"
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          label="Payment Term"
          placeholder="Select payment term"
          options={paymentTerms}
          getOptionLabel={(p) => p.name}
          value={selectedPaymentTerm}
          onChange={handlePaymentTermChange}
          dataQa="select-payment-term"
        />
      </Grid>
      <Grid item xs={6}>
        <Box>
          <DateRangePicker
            label="Date Created"
            placeholder="Select date created range"
            value={dateCreatedRange}
            onChange={handleDateRangeChange('dateCreated')}
            dataQa="select-date-created-range"
            fullWidth
          />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box>
          <DateRangePicker
            value={dateLastModifiedRange}
            label="Date Modified"
            placeholder="Select date modified range"
            onChange={handleDateRangeChange('dateLastModified')}
            dataQa="select-date-modified-range"
            fullWidth
          />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={!!formValues.deleted}
              onChange={handleShowDeletedChange}
              color="primary"
              data-qa="show-deleted"
            />
          }
          label="Show Deleted"
        />
      </Grid>
    </Grid>
  );
};

export default memo(CustomersAdvancedSearch);
