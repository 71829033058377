import React, { ReactNode, useContext, useState, useEffect } from 'react';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { theme } from 'ui/theme';

import {
  initialThemeState,
  InitialThemeStateType,
  ThemeTableDensity,
} from './types';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

type AppThemeProviderProps = {
  children: ReactNode;
};

const AppThemeContext = React.createContext<{
  themeSettings: InitialThemeStateType;
  setThemeSettings: React.Dispatch<React.SetStateAction<InitialThemeStateType>>;
}>({ themeSettings: initialThemeState, setThemeSettings: () => {} });

export const AppThemeProvider: React.FC<AppThemeProviderProps> = ({
  children,
}) => {
  const [themeSettings, setThemeSettings] =
    useState<InitialThemeStateType>(initialThemeState);

  useEffect(() => {
    const localStorageGetTableDensity = localStorage.getItem(
      'tableDensity'
    ) as ThemeTableDensity;

    const localStorageGetFonsSizeMultiplier = localStorage.getItem(
      'fontSizeMultiplier'
    ) as string;

    if (!localStorageGetTableDensity) {
      localStorage.setItem('tableDensity', ThemeTableDensity.Small);
      setThemeSettings((old) => ({
        ...old,
        tableDensity: ThemeTableDensity.Small,
      }));
      return;
    }

    if (!localStorageGetFonsSizeMultiplier) {
      localStorage.setItem('fontSizeMultiplier', '1');
      setThemeSettings((old) => ({
        ...old,
        fontSizeMultiplier: 1,
      }));
      document.documentElement.style.setProperty('--font-size-multiplier', '1');
      return;
    }

    document.documentElement.style.setProperty(
      '--font-size-multiplier',
      localStorageGetFonsSizeMultiplier
    );
    setThemeSettings((old) => ({
      ...old,
      tableDensity: localStorageGetTableDensity,
      fontSizeMultiplier: parseFloat(localStorageGetFonsSizeMultiplier),
    }));
  }, []);

  return (
    <AppThemeContext.Provider value={{ themeSettings, setThemeSettings }}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </AppThemeContext.Provider>
  );
};

export const useThemeTableDensity = () => {
  const { setThemeSettings, themeSettings } = useContext(AppThemeContext);

  const setTableDensity = (density: ThemeTableDensity) => {
    setThemeSettings((old) => ({
      ...old,
      tableDensity: density,
    }));
    localStorage.setItem('tableDensity', density);
  };

  return { setThemeSettings, themeSettings, setTableDensity };
};

export const useThemeFontSize = () => {
  const { setThemeSettings, themeSettings } = useContext(AppThemeContext);

  const setFontSizeMultiplier = (multiplier: number | null) => {
    if (multiplier) {
      setThemeSettings((old) => ({
        ...old,
        fontSizeMultiplier: multiplier,
      }));
      localStorage.setItem('fontSizeMultiplier', multiplier.toString());
    }
  };

  return { setThemeSettings, themeSettings, setFontSizeMultiplier };
};
