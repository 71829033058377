import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { FBOTitleBarProps } from './FBOTitleBar';

export const useTitleBarStyle = makeStyles<Theme>((theme: Theme) => ({
  titleBar: {
    display: 'flex',
    alignItems: 'center',
    padding: (props: FBOTitleBarProps) => (props.noPadding ? '0px' : '16px'),
    backgroundColor: 'white',
    borderRadius: '10px 10px 0px 0px',
  },
  status: {
    borderRadius: 20,
  },
  statusLabel: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
    lineHeight: 1,
  },
  image: {
    marginRight: '10px',
  },
}));
