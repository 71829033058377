export const ITEM_VALUES = [
  'alertNotes',
  'description',
  'details',
  'dimensionUnit',
  'height',
  'length',
  'name',
  'sku',
  'upc',
  'weight',
  'weightUnit',
  'width',
  'defaultUomId',
];
