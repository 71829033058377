import { ColumnTypes } from 'ui/components/Table/ItemsTable';
import { Column } from 'ui/components/Table/ItemsTable/types';
import { MenuItem } from 'ui/components/Menu';
import { PermissionType } from 'services/permissions';
import { IconNames } from 'ui/theme';
import { OrderPriority } from 'services/settings/orderPriorities';
import { Dates } from 'helpers';

import { PickingPageAction } from './types';

export const PICKING_COLUMNS: (
  orderPriorities: OrderPriority[],
  date: Dates
) => Column[] = (orderPriorities, date) => {
  const resolveDateColumn = (): Column => {
    switch (date) {
      case Dates.DateLastModified:
        return {
          title: 'Date Last Modified',
          field: Dates.DateLastModified,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
        };
      case Dates.DateIssued:
        return {
          title: 'Date Issued',
          field: Dates.DateIssued,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
        };
      case Dates.DateScheduled:
        return {
          title: 'Date Scheduled',
          field: Dates.DateScheduled,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
        };
      default:
        return {
          title: 'Date Created',
          field: Dates.DateCreated,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
        };
    }
  };

  return [
    {
      title: 'Pick',
      field: 'number',
      type: ColumnTypes.renderByCustomRow,
      width: 100,
      sortable: true,
    },
    {
      title: 'Stock',
      type: ColumnTypes.renderByCustomRow,
      field: 'stockStatus',
    },
    {
      title: 'Status',
      type: ColumnTypes.renderByCustomRow,
      field: 'status',
      sortable: true,
    },
    {
      title: 'Customer',
      type: ColumnTypes.renderByCustomRow,
      field: 'salesOrder.customer.name',
      sortable: true,
    },
    {
      title: 'Location',
      type: ColumnTypes.renderByCustomRow,
      field: 'parentLocation.name',
      sortable: true,
    },
    resolveDateColumn(),
    {
      title: 'Priority',
      type: ColumnTypes.renderByCustomRow,
      field: 'orderPriority.id',
      sortable: true,
    },
  ];
};

export const PICKING_COLUMNS_SMALL: Column[] = [
  {
    title: 'Pick',
    field: 'number',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Status',
    type: ColumnTypes.renderByCustomRow,
    field: 'status',
  },
  {
    title: 'Customer',
    type: ColumnTypes.renderByCustomRow,
    field: 'customer.name',
  },
];

export const pickingPageActionOptions = (
  onActionClick: (action: PickingPageAction, date?: Dates) => void
): MenuItem[] => [
  {
    label: 'Custom Fields',
    icon: IconNames.FBOReport,
    onOptionClick: () => onActionClick(PickingPageAction.CustomFields),
    permissions: [PermissionType.PickingEdit],
    dataQa: 'custom-fields-button',
    separateAfter: true,
  },
  {
    icon: IconNames.FBORecycleSmall,
    label: 'Change Dates',
    dataQa: 'change-dates-button',
    subItems: [
      {
        label: 'Date Created',
        onOptionClick: () =>
          onActionClick(PickingPageAction.ChangeDate, Dates.DateCreated),
        dataQa: 'date-created-button',
        separateAfter: true,
      },
      {
        label: 'Date Last Modified',
        onOptionClick: () =>
          onActionClick(PickingPageAction.ChangeDate, Dates.DateLastModified),
        dataQa: 'date-last-modified-button',
        separateAfter: true,
      },
      {
        label: 'Date Scheduled',
        onOptionClick: () =>
          onActionClick(PickingPageAction.ChangeDate, Dates.DateScheduled),
        dataQa: 'date-scheduled-button',
      },
    ],
  },
];
