import { createFilterOptions, FilterOptionsState } from '@mui/material';
import { fetchReceiptsAPI, Receipt } from 'services/receiving';
import { Pagination } from 'services/search';

const filter = createFilterOptions<Receipt>();

export const receiptAutocompleteFilterOptions =
  () => (options: Receipt[], params: FilterOptionsState<Receipt>) => {
    const filtered = filter(options, params);

    return filtered;
  };

export const resolvedReceiptFunction = (
  expands?: string[],
  customQuickSearchColumns?: string[]
) => {
  return (pagination: Pagination, searchValue: string | null) =>
    fetchReceiptsAPI({
      pagination,
      expands: expands,
      quickSearchValue: searchValue,
      customQuickSearchColumns,
    });
};
