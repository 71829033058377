import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Channel, fetchTaxRates, getTaxRates } from 'services/taxRates';
import { Modal } from 'ui/components/Modal/Modal';
import { InternationalTaxRates } from './InternationalTaxRates';
import { routes, TaxMappingRates, TaxRateMappingModalProps } from './types';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  getXeroAccountIsConnected,
  InternationalTaxRate,
} from 'services/integrations/xero';
import { getQuickbooksAccountIsConnected } from 'services/integrations/quickbooks';
import { NetworkSpinnerWrapper } from 'ui/components/NetworkSpinnerWrapper';
import { updateTaxRates } from './api';
import { removeObjectFromArrayByField } from './helper';
import { DetailsCard } from 'ui/components/Page/DetailsCard';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const TaxRateMappingModal: React.FC<TaxRateMappingModalProps> = (props) => {
  const { open, onClose } = props;
  const dispatch = useDispatch();
  const { items: taxes } = useSelector(getTaxRates);
  const xeroConnected = useSelector(getXeroAccountIsConnected);
  const qboConnected = useSelector(getQuickbooksAccountIsConnected);
  const [accountingType, setAccountingType] = useState(Channel.None);
  const [contentLoading, setContentLoading] = useState(false);

  const [selectedMappedAccounts, setSelectedMappedAccounts] = useState<
    TaxMappingRates[]
  >([]);
  const [selectedTaxRate, setSelectedTaxRate] =
    useState<InternationalTaxRate | null>(null);
  const oldState = useRef<TaxMappingRates[] | null>([]);

  useEffect(() => {
    if (xeroConnected) setAccountingType(Channel.Xero);
    if (qboConnected) setAccountingType(Channel.Qbo);
  }, [xeroConnected, qboConnected]);
  useEffect(() => {
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchTaxRates());
  }, [dispatch]);
  useEffect(() => {
    if (taxes.length > 0) {
      const firstTax = taxes[0];
      if (firstTax.channel) {
        setSelectedTaxRate({
          name: firstTax.mappedTaxName || '',
          rate: firstTax.channelTaxRate || '',
          code: firstTax.code || '',
          taxType: firstTax.channelTaxType || '',
        } as unknown as InternationalTaxRate);
      }
    }
  }, [taxes]);
  const handleInternationalTaxChange = useCallback(
    (taxRate: InternationalTaxRate, index?: number): boolean => {
      if (index) {
        const obj = {
          taxId: index,
          channelsTaxRate: {
            ...taxRate,
          },
        };
        removeObjectFromArrayByField(selectedMappedAccounts, taxRate.code);
        setSelectedMappedAccounts([...selectedMappedAccounts, obj]);
      }

      return true;
    },
    [selectedMappedAccounts, setSelectedMappedAccounts]
  );
  const saveMappedTaxRates = useCallback(async () => {
    setContentLoading(true);
    oldState.current = selectedMappedAccounts;

    try {
      await updateTaxRates(
        selectedMappedAccounts,
        accountingType.toLocaleLowerCase()
      );
    } catch {
      // continue regardless of error
    }
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchTaxRates());
    setContentLoading(false);
    onClose();
    return true;
  }, [selectedMappedAccounts, onClose]);
  const ModalActions = () => {
    return (
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box mr={2}>
          <FBOButton
            data-qa="create-new-tax"
            variant="secondary"
            color="positive"
            size="medium"
            href={routes.AddNewTaxRates}
          >
            Create New Tax In Drive
          </FBOButton>
        </Box>
        <Box display="flex">
          <Box mr={2}>
            <FBOButton
              onClick={onClose}
              variant="secondary"
              color="positive"
              size="medium"
              data-qa="tax-mapping-cancel"
            >
              Cancel
            </FBOButton>
          </Box>
          <NetworkSpinnerWrapper isLoading={contentLoading} size={24}>
            <FBOButton
              variant="primary"
              color="positive"
              size="medium"
              onClick={saveMappedTaxRates}
              data-qa="tax-mapping-save"
            >
              Save
            </FBOButton>
          </NetworkSpinnerWrapper>
        </Box>
      </Box>
    );
  };
  return (
    <DetailsCard
      onSubmit={saveMappedTaxRates}
      isLoading={contentLoading}
      state={selectedMappedAccounts}
      oldState={oldState}
    >
      <Modal
        open={open}
        onCancelClicked={onClose}
        title="Tax Mapping"
        ModalActionsComponent={ModalActions}
        withoutDefaultPadding
        customHeight={700}
        maxWidth="md"
        footerDivider="shadow"
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>FBO Tax Rates</TableCell>
              <TableCell></TableCell>
              <TableCell>
                {xeroConnected ? 'Xero Tax Rates' : 'Quickbook Tax Rates'}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {taxes.length > 0 ? (
              taxes.map((tax) => (
                <TableRow key={tax.id}>
                  <TableCell>{tax.name}</TableCell>
                  <TableCell style={{ width: '30px' }}>
                    <NavigateNextIcon />
                  </TableCell>
                  <TableCell>
                    <InternationalTaxRates
                      accountingType={accountingType}
                      label={
                        xeroConnected ? 'Xero mapping' : 'quickbook mapping'
                      }
                      selectedOptionCallback={handleInternationalTaxChange}
                      index={tax.id as number | undefined}
                      // @ts-ignore
                      selectedValue={selectedTaxRate}
                      setSelectedValue={setSelectedTaxRate}
                      disabled={false}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <Typography
                color="textSecondary"
                component="div"
                display="flex"
                marginTop="40%"
                marginLeft="30%"
              >
                ADD NEW TAX RATE BY PRESSING 'CREATE NEW TAX IN DRIVE'
              </Typography>
            )}
          </TableBody>
        </Table>
      </Modal>
    </DetailsCard>
  );
};

export default memo(TaxRateMappingModal);
