import { withStyles } from '@mui/styles';
import { Tooltip } from '@mui/material';

export const TextOnlyTooltip = withStyles({
  tooltip: {
    color: 'black',
    backgroundColor: 'transparent',
    width: '170px',
  },
})(Tooltip);
