import React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useSelector } from 'react-redux';

import { Menu } from 'ui/components/Menu';
import { activeUserHasPermission } from 'services/user/redux';

import { ButtonActionsProps } from './types';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

export const FBOButtonAction: React.FC<ButtonActionsProps> = (props) => {
  const { actionItems, text, permissions = [], disabled } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const canClick = useSelector(activeUserHasPermission(permissions));

  // button is disabled if disabled prop is passed OR user does not have permission for action
  const isDisabled = disabled || !canClick;

  const isActionMenuVisible = Boolean(anchorEl);

  const handleIconClicked = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleActionMenuClose = () => setAnchorEl(null);

  return (
    <>
      <FBOButton
        onClick={handleIconClicked}
        disabled={isDisabled}
        color="positive"
        variant="secondary"
        size="medium"
        data-qa={`${text}-action-button`}
        endIcon={<ArrowDropDownIcon />}
        sx={{ marginRight: '8px' }}
        style={{ gap: 0 }}
      >
        {text}
      </FBOButton>
      <Menu
        items={actionItems}
        open={isActionMenuVisible}
        anchorEl={anchorEl}
        onClose={handleActionMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      />
    </>
  );
};

export default FBOButtonAction;
