import React, { memo, useMemo } from 'react';
import { TableCell, Typography } from '@mui/material';
import _ from 'lodash';

import { Column } from 'ui/components/Table/ItemsTable/types';
import { TrackingType, TrackingDataTypes } from 'services/settings/tracking';

import { TrackingColumnsProps } from '../../types';
import { sortMultipleTrackingColumns } from 'ui/components/Table/TrackingTable/helpers';
import { formatDatePreview, useGetIntlDateFormatString } from 'helpers';
import { getSettingsCompanyCountry } from 'services/settings/company';
import { useSelector } from 'react-redux';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { IconNames } from 'ui/theme';

const TrackingViewCells: React.FC<TrackingColumnsProps> = (props) => {
  const { trackingGroup, onSerialLinkClick, columns, itemUom } = props;
  const intlFormatDate = useGetIntlDateFormatString();
  const companyCountry = useSelector(getSettingsCompanyCountry);
  const sortedColumns = useMemo(
    () => sortMultipleTrackingColumns(columns),
    [columns]
  );

  const quantity = trackingGroup.onHand || 0;

  const renderColumn = (column: Column): JSX.Element | null => {
    const columns: TrackingDataTypes | string = _.get(
      column,
      'meta.trackingDataType',
      'quantity'
    );
    const trackingType: TrackingType = _.get(column, 'meta.trackingType', {});
    const trackingTypeIndex = trackingGroup.trackingInfoList.findIndex(
      (t) => t.trackingTypeId === trackingType.id
    );
    const trackingInfo = trackingGroup.trackingInfoList[trackingTypeIndex];

    switch (columns) {
      case TrackingDataTypes.Date: {
        const formattedDateValue = formatDatePreview(
          trackingInfo?.value,
          companyCountry,
          intlFormatDate
        );
        return <Typography>{formattedDateValue}</Typography>;
      }
      case TrackingDataTypes.Text:
        return <Typography>{trackingInfo?.value}</Typography>;
      case TrackingDataTypes.SerialNumber:
        return (
          <FBOButton
            variant="tertiary"
            color="neutral"
            size="medium"
            icon={IconNames.IconCaratRight}
            onClick={onSerialLinkClick}
            data-qa="tracking-view-cell-right-carat-button"
          />
        );
      case 'quantity':
        return (
          <Typography>
            {quantity}
            {` ${itemUom ? itemUom.abbreviation : ''}`}
          </Typography>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {sortedColumns.map((column: Column, index: number) => (
        <TableCell key={index}>{renderColumn(column)}</TableCell>
      ))}
    </>
  );
};

export default memo(TrackingViewCells);
