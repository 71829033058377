export const FAULTYFILETYPES = [
  'exe',
  'com',
  'dll',
  'msi',
  'bat',
  'cmd',
  'sh',
  'pl',
  'vbs',
  'py',
  'php',
  'osx',
  'out',
  'run',
  'prg',
];
