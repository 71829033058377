import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

// Lint skip to be removed
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const useCardStyle = makeStyles((theme: Theme) => ({
  card: {
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.16)',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    position: 'relative',
    height: '100%',
  },
  cardHeader: {
    height: 40,
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardMedia: {
    display: 'block',
    maxWidth: 168,
    height: 40,
  },
  cardContent: {
    flex: 1,
  },
  cardTitle: {
    flex: 1,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  cardFooter: {
    marginTop: theme.spacing(2),
    position: 'relative',
  },
  cardFooterContent: {
    display: 'flex',
    justifyContent: 'center',
  },
  statusLabel: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
    lineHeight: 1,
    borderRadius: 20,
  },
}));
