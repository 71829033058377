import React, { useState, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Grid, Link } from '@mui/material';
import { LoadingArrowButton } from 'ui/components/LoadingArrowButton';
import {
  ForgotPasswordFormValues,
  initialForgotPasswordForm,
  requestPasswordChange,
} from 'services/auth';
import { Errors } from 'services/forms/validation';
import { FBOTextField } from 'ui/theme/components';

const emailRegExp = /(\W|^)[\w.-]{1,25}@(.+)\..+(\W|$)/;
const VALIDATION_SCHEMA = (key: string = '', value: string = ''): string => {
  let error = '';
  switch (key) {
    case 'email':
      if (isEmpty(value)) {
        error = 'Email is required';
      } else if (!emailRegExp.test(value)) {
        error = 'Invalid email';
      }
      break;
  }
  return error;
};

/**
 * checks obj to see if there is any key that has a value
 */
const hasValues = (obj: Errors | ForgotPasswordFormValues): boolean =>
  !!Object.values(obj).find((e: string) => e !== '');

const ForgotPasswordForm: React.FC = () => {
  const [formValues, setFormValues] = useState<ForgotPasswordFormValues>(
    initialForgotPasswordForm
  );
  const [errors, setErrors] = useState<Errors>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validateForm()) return;

    setIsLoading(true);
    try {
      await requestPasswordChange(formValues.email!);
    } catch {
      // Ignore error
    }
    setIsLoading(false);
  };

  const validateForm = (): boolean => {
    const { email } = formValues;

    const formErrors = {
      email: VALIDATION_SCHEMA('email', email),
    };
    setErrors({ ...formErrors });

    if (hasValues(formErrors)) return false;

    return true;
  };

  const validateInput = (input: {
    name: string;
    value: string;
  }): string | null => {
    const name = input.name;
    const value = input.value;
    return VALIDATION_SCHEMA(name, value);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { name, checked, type, value } = event.target;
    setErrors({ ...errors, [name]: '' });

    const resolvedValue = type === 'checkbox' ? !!checked : value;
    setFormValues((old) => ({
      ...old,
      [name]: resolvedValue,
    }));
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    const { name } = event.target;
    const { value } = event.target;
    const error = validateInput({ name, value });
    if (error) setErrors({ ...errors, [name]: error });
  };

  const hasErrors = useMemo(() => hasValues(errors), [errors]);

  return (
    <form onSubmit={handleFormSubmit} noValidate>
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12}>
          <FBOTextField
            autoFocus
            required
            name="email"
            className="redesign"
            type="email"
            label="Email"
            value={formValues.email}
            error={!!errors.email}
            errorText={errors.email}
            dataQa="email-address"
            allowLastPassIcon
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid
          item
          // todo : move after redesign
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoadingArrowButton
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            disabled={hasErrors || isLoading}
            loadingPosition="start"
            loading={isLoading}
            data-qa="submit-button"
          >
            Send Reset Password Link
          </LoadingArrowButton>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Link
            className="redesign"
            color="textSecondary"
            variant="body1"
            component={RouterLink}
            to="/login"
            data-qa="login-link"
            underline="hover"
          >
            Re-enter Password
          </Link>
        </Grid>
      </Grid>
    </form>
  );
};

export default React.memo(ForgotPasswordForm);
