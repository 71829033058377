import React, { memo, useState, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Divider, Box } from '@mui/material';

import { activeUserHasPermission } from 'services/user/redux';
import { Paper } from 'ui/components/Paper/Paper';
import { Modal } from 'ui/components/Modal/Modal';

import { CardProps } from './types';
import { useCardStyle } from './styled';
import { CardRow, ModalRow } from './components';
import { GlobalSettingsFields } from 'services/commerce/settings';
import { Errors, validateYup } from 'services/forms/validation';
import { yupGlobalSettings } from './validations';
import { NetworkSpinnerWrapper } from 'ui/components/NetworkSpinnerWrapper';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const MODAL_HEIGHT = 560;

const Card: React.FC<CardProps> = (props) => {
  const {
    show,
    rows,
    title,
    id,
    data,
    viewPermissions = [],
    editPermissions = [],
    onApplyClicked,
    dataQa,
    cardDataLoaded,
    setLocationsLoaded,
    setTagsLoaded,
  } = props;

  const classes = useCardStyle();

  const hasViewPermission = useSelector(
    activeUserHasPermission(viewPermissions)
  );
  const hasEditPermission = useSelector(
    activeUserHasPermission(editPermissions)
  );

  const [cardData, setCardData] = useState(data);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isWaitForResponse, setIsWaitForResponse] = useState(false);
  const [validationErrors, setValidationErrors] = useState<Errors>({});

  useEffect(() => {
    setCardData(data);
  }, [data]);
  const lastIndex = useMemo(() => rows.length - 1, [rows]);

  const handleEditClicked = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const handleCancelClicked = useCallback(() => {
    setCardData(data);
    setValidationErrors({});
    setIsModalVisible(false);
  }, [data]);

  const handleApplyClicked = useCallback(() => {
    setIsWaitForResponse(true);
    if (!validateYup(cardData as any, yupGlobalSettings, setValidationErrors)) {
      setIsWaitForResponse(false);
      return;
    }
    onApplyClicked(cardData);
    setIsModalVisible(false);
    setIsWaitForResponse(false);
  }, [cardData, onApplyClicked]);

  const handleCardDataChanged = useCallback((field: string, value: unknown) => {
    if (
      field === GlobalSettingsFields.productExport.limitTags ||
      field === GlobalSettingsFields.productExport.longDescription ||
      field === GlobalSettingsFields.productExport.masterSku ||
      field === GlobalSettingsFields.productExport.productTitle ||
      field === GlobalSettingsFields.productExport.shortDescription ||
      field === GlobalSettingsFields.productExport.upc
    ) {
      setCardData((oldCardData: any) => ({
        ...(oldCardData as object),
        productExport: {
          ...(oldCardData?.productExport as object),
          [field]: value,
        },
      }));
    }

    if (
      field ===
        GlobalSettingsFields.inventoryExport.inventoryWarehousesEnabled ||
      field === GlobalSettingsFields.inventoryExport.quantityType
    ) {
      setCardData((oldCardData: any) => ({
        ...(oldCardData as object),
        inventoryExport: {
          ...(oldCardData?.inventoryExport as object),
          [field]: value,
        },
      }));
    }
  }, []);

  const dangerouslySetInnerHTML = useMemo(() => {
    return title.includes('<mark>');
  }, [title]);

  return (
    <>
      {hasViewPermission && show && (
        <Box className={classes.container}>
          <Paper
            id={id}
            className={`${classes.paper} static-paper redesign`}
            sx={{ boxShadow: 'none' }}
          >
            <FBOTitleBar
              dangerouslySetInnerHTML={dangerouslySetInnerHTML}
              title={title}
              sx={{ borderBottom: '1px solid #e0e0e0' }}
            >
              <FBOButton
                color="positive"
                variant="primary"
                onClick={handleEditClicked}
                permissions={editPermissions}
                data-qa={`${dataQa}-edit`}
              >
                EDIT
              </FBOButton>
            </FBOTitleBar>
            <NetworkSpinnerWrapper
              isLoading={!cardDataLoaded}
              size={24}
              hideBackground
            >
              {rows.map((row, i) => (
                <React.Fragment key={`fragment-${row.field}`}>
                  <CardRow
                    {...row}
                    data={data}
                    setLocationsLoaded={setLocationsLoaded}
                    setTagsLoaded={setTagsLoaded}
                  />
                  {i !== lastIndex && <Divider />}
                </React.Fragment>
              ))}
            </NetworkSpinnerWrapper>
          </Paper>
          {hasEditPermission && (
            <Modal
              open={isModalVisible}
              onClose={handleCancelClicked}
              title={title}
              applyLabel={'Save Changes'}
              cancelLabel={'Cancel'}
              onApplyClicked={handleApplyClicked}
              onCancelClicked={handleCancelClicked}
              isLoading={isWaitForResponse}
              withoutDefaultPadding
              footerDivider="shadow"
              customHeight={MODAL_HEIGHT}
              maxWidth="sm"
              dataQa={dataQa}
            >
              {rows.map((row, i) => (
                <React.Fragment key={`fragment-${row.field}`}>
                  <ModalRow
                    {...row}
                    setCardData={handleCardDataChanged}
                    cardData={cardData}
                    errors={validationErrors}
                  />
                  {i !== lastIndex && <Divider />}
                </React.Fragment>
              ))}
            </Modal>
          )}
        </Box>
      )}
    </>
  );
};

export default memo(Card);
