import { Class } from './types';

export const transformClass = (res: any): Class => {
  return {
    id: res.id,
    name: res.name,
    version: res.version,
    dateCreated: res.dateCreated || null,
    parentId: res.parentId || null,
    accountingId: res.accountingId || null,
  };
};

export const transformToRequestClass = (innerClass: Class): any => {
  // POST
  if (innerClass.id === -1) {
    return {
      name: innerClass.name,
      parentId: innerClass.parentId,
      accountingId: innerClass.accountingId,
    };
  }

  // PUT
  return {
    id: innerClass.id,
    name: innerClass.name,
    version: innerClass.version,
    parentId: innerClass.parentId,
    accountingId: innerClass.accountingId,
  };
};
