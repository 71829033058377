import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { Grid } from '@mui/material';
import * as yup from 'yup';

import {
  Carrier,
  defaultCarrier,
  fetchCarriers,
  getCarriers,
} from 'services/carriers';

import { Autocomplete } from '../Autocomplete';
import { CarriersAutocompleteProps } from './types';
import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import { postCarrier } from 'services/carriers/api';
import { useHandleTextFieldChange } from 'services/forms';
import { Errors, validateYup } from 'services/forms/validation';
import { activeUserHasPermission } from 'services/user/redux';

const filter = createFilterOptions<Carrier>();

const yupSchema = yup.object().shape({
  name: yup.string(),
});

const CarriersAutocomplete: React.FC<CarriersAutocompleteProps> = (props) => {
  const { onChange, permissions = [], disabled, ...otherProps } = props;

  const dispatch = useDispatch();

  const { items: carriers } = useSelector(getCarriers);

  const [showModal, setShowModal] = useState(false);
  const [formCarrier, setFormCarrier] = useState<Carrier>(defaultCarrier);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Errors>({});

  const canEdit = useSelector(activeUserHasPermission(permissions));

  const isDisabled = disabled || !canEdit;

  const handleChange = (e: any, carrier: Carrier | null) => {
    if (carrier && (carrier.id === null || carrier.id < 0)) {
      setFormCarrier((old) => ({ ...old, name: carrier.name }));
      setErrors({});
      setShowModal(true);
      return;
    }

    onChange(carrier);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFormCarrier(defaultCarrier);
  };

  const handleApplyModal = async () => {
    if (!validateYup(formCarrier, yupSchema, setErrors)) {
      return;
    }

    setIsLoading(true);

    try {
      const newCarrier = await postCarrier(formCarrier);
      // Lint skip to be removed
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(fetchCarriers());
      onChange(newCarrier);
    } catch {
      setIsLoading(false);
      return;
    }

    setShowModal(false);
    setIsLoading(false);
    setFormCarrier(defaultCarrier);
  };

  const handleTextFieldChange = useHandleTextFieldChange(
    setFormCarrier,
    formCarrier
  );

  return (
    <>
      <Autocomplete
        options={carriers}
        disabled={isDisabled}
        getOptionLabel={(carrier: Carrier) => {
          if (!carrier.id || carrier.id < 0) {
            return `+ Add "${carrier.name}"`;
          }

          return carrier.name || '';
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (!!params.inputValue) {
            filtered.unshift({
              ...defaultCarrier,
              name: params.inputValue || null,
            });
          }

          return filtered;
        }}
        onChange={handleChange}
        autoSelect={false}
        {...otherProps}
      />
      <Modal
        open={showModal}
        title="New Carrier"
        onCancelClicked={handleCloseModal}
        onApplyClicked={handleApplyModal}
        isLoadingContent={isLoading}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              className="redesign"
              variant="standard"
              name="name"
              label="Name"
              value={formCarrier.name}
              onChange={handleTextFieldChange}
              required
              error={!!errors.name}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              className="redesign"
              variant="standard"
              name="scac"
              label="SCAC"
              value={formCarrier.scac}
              onChange={handleTextFieldChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="redesign"
              variant="standard"
              name="description"
              value={formCarrier.description}
              onChange={handleTextFieldChange}
              label="Description"
            />
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default memo(CarriersAutocomplete);
