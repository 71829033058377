import { createApiAction } from 'services/api';
import {
  StartAction,
  SuccessAction,
  FailureAction,
} from 'services/api/redux/types';
import {
  createAddSearch,
  createDeleteSearch,
  createFetchSearch,
  createUpdateSearch,
} from 'services/search';

export enum CarriersActionTypes {
  FETCH_CARRIERS_START = 'carriers/fetch_carriers_start',
  FETCH_CARRIERS_SUCCESS = 'carriers/fetch_carriers_success',
  FETCH_CARRIERS_FAILURE = 'carriers/fetch_carriers_failure',
}

/**
 * CARRIERS
 */
const fetchCarriersActionStart = (): StartAction => ({
  type: CarriersActionTypes.FETCH_CARRIERS_START,
  payload: null,
});

const fetchCarriersActionSuccess = (payload: any): SuccessAction => {
  return {
    type: CarriersActionTypes.FETCH_CARRIERS_SUCCESS,
    payload,
  };
};

const fetchCarriersActionFailure = (error: string): FailureAction => ({
  type: CarriersActionTypes.FETCH_CARRIERS_FAILURE,
  payload: { error },
});

export const fetchCarriers = () =>
  createApiAction(
    {
      path: '/v1/carriers?expand=carrierServices&pageSize=100',
      method: 'GET',
    },
    fetchCarriersActionStart,
    fetchCarriersActionSuccess,
    fetchCarriersActionFailure
  );

export const fetchSearch = createFetchSearch('carrier');
export const addSearch = createAddSearch('carrier');
export const updateSearch = createUpdateSearch('carrier');
export const deleteSearch = createDeleteSearch('carrier');
