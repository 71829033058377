import { makeStyles } from '@mui/styles';

export const useAddressStyle = makeStyles(() => ({
  buttonLink: {
    'text-transform': 'none',
    'white-space': 'pre',
    cursor: 'pointer',
  },
  iconValid: {
    color: 'green',
    marginLeft: '10px',
    marginTop: '3px',
  },
  iconError: {
    color: 'red',
    marginLeft: '10px',
    marginTop: '3px',
  },
}));
