import { createFilterOptions, FilterOptionsState } from '@mui/material';
import { Currency } from 'services/currencies';
import { fetchCurrenciesAPI } from 'services/currencies/api';
import { initialCurrency } from 'services/currencies/consts';

import { Pagination } from 'services/search';

const filter = createFilterOptions<Currency>();

export const currencyAutocompleteOptionLabel = (currency: Currency) => {
  if (!currency.id || currency.id < 0) {
    return `+ Add new currency`;
  }

  return currency.name || '';
};

export const currencyAutocompleteFilterOptions =
  (disableAdd: boolean) =>
  (options: Currency[], params: FilterOptionsState<Currency>) => {
    const filtered = filter(options, params);

    if (disableAdd) {
      return filtered;
    }

    if (!!params.inputValue) {
      filtered.unshift({
        ...initialCurrency,
        name: params.inputValue || null,
      });
    }

    return filtered;
  };

export const resolvedFetchCurrenciesFunction = (
  expands?: string[],
  customQuickSearchColumns?: string[]
) => {
  return (pagination: Pagination, searchValue: string | null) =>
    fetchCurrenciesAPI({
      pagination,
      expands: expands,
      quickSearchValue: searchValue,
      customQuickSearchColumns,
    });
};
