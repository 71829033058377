import { createApiAction } from 'services/api';
import {
  StartAction,
  SuccessAction,
  FailureAction,
} from 'services/api/redux/types';
import {
  createAddSearch,
  createDeleteSearch,
  createFetchSearch,
  createUpdateSearch,
} from 'services/search';

export enum VendorsActionTypes {
  FETCH_VENDORS_START = 'vendors/fetch_vendors_start',
  FETCH_VENDORS_SUCCESS = 'vendors/fetch_vendors_success',
  FETCH_VENDORS_FAILURE = 'vendors/fetch_vendors_failure',
}

const fetchVendorsActionStart = (): StartAction => ({
  type: VendorsActionTypes.FETCH_VENDORS_START,
  payload: null,
});

const fetchVendorsActionSuccess = (payload: any): SuccessAction => {
  return {
    type: VendorsActionTypes.FETCH_VENDORS_SUCCESS,
    payload,
  };
};

const fetchVendorsActionFailure = (error: string): FailureAction => ({
  type: VendorsActionTypes.FETCH_VENDORS_FAILURE,
  payload: { error },
});

export const fetchVendors = () =>
  createApiAction(
    {
      path: '/v1/vendors?expand=vendorAddresses&pageSize=100',
      method: 'GET',
    },
    fetchVendorsActionStart,
    fetchVendorsActionSuccess,
    fetchVendorsActionFailure
  );

export const fetchSearch = createFetchSearch('vendor');
export const addSearch = createAddSearch('vendor');
export const updateSearch = createUpdateSearch('vendor');
export const deleteSearch = createDeleteSearch('vendor');
