import React, { memo, useCallback } from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { TextField } from 'ui/components/TextField/TextField';
import { ItemsAutocomplete } from 'ui/components/Autocomplete/ItemsAutocomplete';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import { Item } from 'services/items';
import { Location } from 'services/locations';
import {
  useHandleCheckboxChange,
  useHandleTextFieldChange,
} from 'services/forms/hooks';

import { ReportFiltersProps } from '../../types';
import Autocomplete from 'ui/components/Autocomplete/Autocomplete/Autocomplete';
import { GroupByOptions } from './types';
import { TagsAsyncAutocomplete } from 'ui/components/Autocomplete/TagsAutocomplete';
import { Tag } from 'services/tags';

const InventoryOnHandFilters: React.FC<ReportFiltersProps> = (props) => {
  const { parameters, setParameters } = props;

  const handleLocationChange = useCallback(
    (location: Location | null) => {
      setParameters((old) => ({
        ...old,
        locationId: location ? location.id : null,
      }));
    },
    [setParameters]
  );

  const handleOptionsAutocomplete = useCallback(
    (optionName: string) => (e: any, option: any | null) => {
      setParameters((old) => ({ ...old, [optionName]: option }));
    },
    [setParameters]
  );

  const handleItemChange = useCallback(
    (selectedItem: Item | null) => {
      setParameters((old) => ({
        ...old,
        itemId: selectedItem ? selectedItem.id : null,
      }));
    },
    [setParameters]
  );

  const handleSelectAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setParameters((old) => ({
        ...old,
        showTracking: checked,
        selectAllOptional: checked,
      }));
    },
    [setParameters]
  );

  const handleCheckboxChange = useHandleCheckboxChange(setParameters);

  const handleTextFieldChange = useHandleTextFieldChange(
    setParameters,
    parameters
  );

  const handleTagChange = useCallback(
    (values: Tag[]) =>
      setParameters((old) => ({ ...old, tagIds: values.map((tag) => tag.id) })),
    [setParameters]
  );

  return (
    <>
      <Box px={2} pb={0} pt="16px">
        <FormControlLabel
          className="redesign"
          control={
            <Checkbox
              className="redesign"
              checked={Boolean(parameters.selectAllOptional)}
              onChange={handleSelectAll}
              color="primary"
              data-qa="select-optional"
            />
          }
          label="Select All Optional"
        />
      </Box>
      <Accordion square defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <b>Optional</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%" pt={1}>
            <Box>
              <LocationsAsyncAutocomplete
                label="Location"
                value={parameters.locationId}
                placeholder="Select location"
                companyWide={false}
                onChange={handleLocationChange}
                dataQa="report-location"
              />
            </Box>
            <Box>
              <ItemsAutocomplete
                label="Item"
                placeholder="Select item"
                onChange={handleItemChange}
                value={parameters.itemId}
                dataQa="report-item"
              />
            </Box>
            <Box>
              <TagsAsyncAutocomplete
                label="Tags"
                placeholder="Select Tags"
                value={parameters.tagIds}
                onChange={handleTagChange}
                disableTagsModal
              />
            </Box>
            <Box>
              <Autocomplete
                label="Group By"
                options={Object.values(GroupByOptions)}
                placeholder="Select Group By Option"
                value={parameters.groupBy || null}
                onChange={handleOptionsAutocomplete('groupBy')}
                required
                fullWidth
              />
            </Box>
            <Box>
              <TextField
                className="redesign"
                variant="standard"
                label="Tracking Value"
                placeholder="Tracking value"
                name="tracking"
                value={parameters.tracking || null}
                onChange={handleTextFieldChange}
                fullWidth
                dataQa="tracking-value"
              />
            </Box>
            <FormControlLabel
              className="redesign"
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(parameters.showSku)}
                  onChange={handleCheckboxChange}
                  name="showSku"
                  color="primary"
                  data-qa="add-sku"
                />
              }
              label="Show SKU"
            />
            <FormControlLabel
              className="redesign"
              sx={{ mt: 2 }}
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(parameters.showTracking)}
                  onChange={handleCheckboxChange}
                  name="showTracking"
                  color="primary"
                  data-qa="show-tracking"
                />
              }
              label="Show Tracking"
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default memo(InventoryOnHandFilters);
