import React, { memo, useCallback, useMemo } from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';

import {
  useHandleCheckboxChange,
  useHandleTextFieldChange,
} from 'services/forms/hooks';
import { Ship } from 'services/shipping';

import { ReportFiltersProps } from '../../types';
import { ShipAsyncAutocomplete } from 'ui/components/Autocomplete/ShipAutocomplete';
import { TextField } from 'ui/components/TextField/TextField';
import { DateOptions, ItemTypeOptions } from './consts';
import { DateOption } from './types';

const ShippingInvoiceFilters: React.FC<ReportFiltersProps> = (props) => {
  const { parameters, setParameters, errors } = props;

  const handleShipChange = useCallback(
    (value: Ship | null) =>
      setParameters((old) => ({ ...old, shipId: value ? value.id : null })),
    [setParameters]
  );

  const handleSelectAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setParameters((old) => ({
        ...old,
        showBarcode: checked,
        showShippingService: checked,
        showCartonInfo: checked,
        showCompanyName: checked,
        showTotals: checked,
        selectAllOptional: checked,
        showNotes: checked,
        showTracking: checked,
        ShowItemTracking: checked,
        ShowHomeCurrency: checked,
        ShowPayments: checked,
        ShowLineItemNotes: checked,
        ColumnSeller: checked,
        ColumnPONumber: checked,
        ColumnPaymentTerm: checked,
        ColumnFOBPoint: checked,
        ColumnShippingTerms: checked,
        ColumnRowNumber: checked,
        ColumnItemType: checked,
        ColumnItemDiscount: checked,
        ShowTax: checked,
      }));
    },
    [setParameters]
  );

  const handleCheckboxChange = useHandleCheckboxChange(setParameters);

  const handleTextFieldChange = useHandleTextFieldChange(
    setParameters,
    parameters
  );

  const handleDateOptionChange = useCallback(
    (_e: any, dateOption: DateOption | null) => {
      setParameters((old) => ({
        ...old,
        DateOption: dateOption?.value ?? null,
      }));
    },
    [setParameters]
  );

  const selectedDateOption = useMemo(() => {
    return DateOptions.find((o) => o.value === parameters.DateOption) || null;
  }, [parameters]);

  const handleItemTypesChange = useCallback(
    (_e: any, types: string[] | null) => {
      setParameters((old) => ({
        ...old,
        ItemTypes: types,
      }));
    },
    [setParameters]
  );

  return (
    <Box px={2} pb={0} pt={1}>
      <Box mb={1}>
        <TextField
          className="redesign"
          variant="standard"
          label="Report Title"
          placeholder="Enter a report title"
          name="ReportTitle"
          type="text"
          value={parameters.ReportTitle ?? null}
          onChange={handleTextFieldChange}
          fullWidth
          dataQa="shipping-invoice-report-title"
          inputProps={{
            maxLength: 25,
          }}
        />
      </Box>
      <Box>
        <Autocomplete
          label="Date Option"
          options={DateOptions}
          placeholder="Select date"
          getOptionLabel={(i) => i.label}
          onChange={handleDateOptionChange}
          value={selectedDateOption}
          fullWidth
        />
      </Box>
      <Accordion square defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <b>Required</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%" pt={1}>
            <Box mb={1}>
              <ShipAsyncAutocomplete
                label="Ship Number"
                value={parameters.shipId}
                placeholder="Select Ship"
                onChange={handleShipChange}
                required
                error={!!errors.shipId}
                helperText={errors.shipId}
                customQuickSearchColumns={['number']}
                dataQa="shipping-invoice-filter-ship"
              />
            </Box>
          </Box>
          <Box mb={1}>
            <Autocomplete
              className="reports-multi-line"
              label="Item Types"
              placeholder="Select item types"
              options={ItemTypeOptions}
              value={parameters.ItemTypes}
              onChange={handleItemTypesChange}
              dataQa="shipping-invoice-filter-event"
              multiple
            />
          </Box>
        </AccordionDetails>
      </Accordion>
      <Box px={2} pb={0} pt={1}>
        <FormControlLabel
          control={
            <Checkbox
              className="redesign"
              checked={Boolean(parameters.selectAllOptional)}
              onChange={handleSelectAll}
              color="primary"
            />
          }
          label="Select All Optional"
        />
      </Box>
      <Accordion square>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>
            <b>Optional</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%" pt={1}>
            <Box mb={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showBarcode)}
                    onChange={handleCheckboxChange}
                    name="showBarcode"
                    color="primary"
                  />
                }
                label="Show Barcode"
              />
            </Box>
            <Box mb={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showShippingService)}
                    onChange={handleCheckboxChange}
                    name="showShippingService"
                    color="primary"
                  />
                }
                label="Show Shipping Service"
              />
            </Box>
            <Box mb={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showCartonInfo)}
                    onChange={handleCheckboxChange}
                    name="showCartonInfo"
                    color="primary"
                  />
                }
                label="Show Package Info"
              />
            </Box>
            <Box mb={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showCompanyName)}
                    onChange={handleCheckboxChange}
                    name="showCompanyName"
                    color="primary"
                  />
                }
                label="Show Company Name"
              />
            </Box>
            <Box mb={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showTotals)}
                    onChange={handleCheckboxChange}
                    name="showTotals"
                    color="primary"
                  />
                }
                label="Show Totals"
              />
            </Box>
            <Box mb={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showNotes)}
                    onChange={handleCheckboxChange}
                    name="showNotes"
                    color="primary"
                  />
                }
                label="Show Order Notes"
              />
            </Box>
            <Box mb={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showTracking)}
                    onChange={handleCheckboxChange}
                    name="showTracking"
                    color="primary"
                  />
                }
                label="Show Shipment Tracking"
              />
            </Box>
            <Box mb={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.ColumnSeller)}
                    onChange={handleCheckboxChange}
                    name="ColumnSeller"
                    color="primary"
                  />
                }
                label="Show Sales Rep"
              />
            </Box>
            <Box mb={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.ColumnPONumber)}
                    onChange={handleCheckboxChange}
                    name="ColumnPONumber"
                    color="primary"
                  />
                }
                label="Show Customer PO"
              />
            </Box>
            <Box mb={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.ColumnPaymentTerm)}
                    onChange={handleCheckboxChange}
                    name="ColumnPaymentTerm"
                    color="primary"
                  />
                }
                label="Show Payment Terms"
              />
            </Box>
            <Box mb={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.ColumnFOBPoint)}
                    onChange={handleCheckboxChange}
                    name="ColumnFOBPoint"
                    color="primary"
                  />
                }
                label="Show FOB Point"
              />
            </Box>
            <Box mb={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.ColumnShippingTerms)}
                    onChange={handleCheckboxChange}
                    name="ColumnShippingTerms"
                    color="primary"
                  />
                }
                label="Show Shipping Terms"
              />
            </Box>
            <Box mb={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.ShowPayments)}
                    onChange={handleCheckboxChange}
                    name="ShowPayments"
                    color="primary"
                  />
                }
                label="Show Payments"
              />
            </Box>
            <Box mb={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.ColumnRowNumber)}
                    onChange={handleCheckboxChange}
                    name="ColumnRowNumber"
                    color="primary"
                  />
                }
                label="Show Line Item Number"
              />
            </Box>
            <Box mb={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.ColumnItemType)}
                    onChange={handleCheckboxChange}
                    name="ColumnItemType"
                    color="primary"
                  />
                }
                label="Show Type"
              />
            </Box>
            <Box mb={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.ColumnItemDiscount)}
                    onChange={handleCheckboxChange}
                    name="ColumnItemDiscount"
                    color="primary"
                  />
                }
                label="Show Line Item Discount"
              />
            </Box>
            <Box mb={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.ShowTax)}
                    onChange={handleCheckboxChange}
                    name="ShowTax"
                    color="primary"
                  />
                }
                label="Show Line Item Tax"
              />
            </Box>
            <Box mb={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.ShowLineItemNotes)}
                    onChange={handleCheckboxChange}
                    name="ShowLineItemNotes"
                    color="primary"
                  />
                }
                label="Show Item Notes"
              />
            </Box>
            <Box mb={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.ShowItemTracking)}
                    onChange={handleCheckboxChange}
                    name="ShowItemTracking"
                    color="primary"
                  />
                }
                label="Show Item Tracking"
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.ShowHomeCurrency)}
                    onChange={handleCheckboxChange}
                    name="ShowHomeCurrency"
                    color="primary"
                  />
                }
                label="Show Home Currency"
              />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default memo(ShippingInvoiceFilters);
