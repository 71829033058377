import React, { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, TableCell, Grid } from '@mui/material';
import _ from 'lodash';

import { IconNames } from 'ui/theme';
import { Icon } from 'ui/components/Icon';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { getTaxRates, TaxRate, TaxType } from 'services/taxRates';
import {
  SalesOrderItem,
  SalesOrderItemStatus,
  SalesOrderStatus,
} from 'services/salesOrders';
import { PermissionType } from 'services/permissions';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';
import { stopInputPropagation } from 'helpers';
import { getSettingsCompany } from 'services/settings/company';

import { MenuTableCell } from '../MenuTableCell';
import { editSalesOrderPermissions } from '../../../../helpers';
import { useSalesOrderTableCellStyle } from '../../styled';

const FlatTaxRateRow: React.FC<RenderCustomRowProps<SalesOrderItem>> = (
  props
) => {
  const {
    row,
    index,
    errors,
    setData,
    meta: { salesOrder },
  } = props;

  const { items: taxRates } = useSelector(getTaxRates);

  const { homeCurrency, useMultiCurrency } = useSelector(getSettingsCompany);

  const homeCurrencyCode = (homeCurrency && homeCurrency.code) || 'USD';

  const activeMulticurrencyCode = _.get(
    salesOrder,
    'customer.currency.code',
    null
  );

  const showMultiCurrency = useMemo(
    () =>
      useMultiCurrency &&
      activeMulticurrencyCode &&
      activeMulticurrencyCode !== homeCurrencyCode,
    [useMultiCurrency, activeMulticurrencyCode]
  );

  const fieldsDisabled =
    salesOrder.status === SalesOrderStatus.Fulfilled ||
    salesOrder.status === SalesOrderStatus.PartiallyFulfilled ||
    salesOrder.status === SalesOrderStatus.PartiallyPicked ||
    salesOrder.status === SalesOrderStatus.Cancelled ||
    salesOrder.deleted ||
    row.status === SalesOrderItemStatus.Fulfilled;

  const editPermission = editSalesOrderPermissions(salesOrder);

  const classes = useSalesOrderTableCellStyle();

  const flatTaxRates = useMemo(
    () => taxRates.filter((t) => t.taxType === TaxType.flatRate),
    [taxRates]
  );

  const selectedTaxRate = useMemo(
    () => taxRates.find((t) => t.id === row.taxId) || null,
    [taxRates, row]
  );

  const handlePriceChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value ? parseFloat(e.target.value) : null;
      const newSoiRow: SalesOrderItem = { ...row, price: value };
      setData(newSoiRow, index);
    },
    [index, row, setData]
  );

  const handleTaxRateChange = useCallback(
    (e: React.ChangeEvent<{}>, value: TaxRate) => {
      e.stopPropagation();
      const newSoiRow: SalesOrderItem = {
        ...row,
        taxId: value ? value.id : null,
        price: value ? value.amount : null,
      };
      setData(newSoiRow, index);
    },
    [index, row, setData]
  );

  return (
    <>
      <TableCell className={classes.cell}>
        <Grid container alignItems="center">
          <Grid item>
            <Box
              marginRight={2}
              width={40}
              height={40}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Icon name={IconNames.AttachMoney} noDefaultFill />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              options={flatTaxRates}
              getOptionLabel={(t) => t.name}
              onChange={handleTaxRateChange}
              label="Flat Tax Rate"
              permissions={editPermission}
              placeholder="Select flat tax rate"
              value={selectedTaxRate}
              disabled={fieldsDisabled}
              error={!!errors.taxId}
              dataQa="flat-tax-rate-row"
            />
          </Grid>
        </Grid>
      </TableCell>
      <TableCell className={classes.cell}>
        <CurrencyField
          placeholder="Price"
          name="price"
          value={row.price}
          onChange={handlePriceChange}
          onClick={stopInputPropagation}
          fullWidth
          disabled={fieldsDisabled}
          permissions={[PermissionType.SalesOrderOverridePrice]}
          decimalPlaces={5}
          error={!!errors.price}
        />
      </TableCell>
      <TableCell colSpan={showMultiCurrency ? 7 : 5} className={classes.cell} />
      <MenuTableCell {...props} />
    </>
  );
};

export default memo(FlatTaxRateRow);
