import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { fetchSearch, getSearches } from 'services/inventory/redux';
import { transformItem, Item } from 'services/items';
import {
  TrackingGroup,
  ItemInventoryEvents,
  postInventoryEvent,
  itemHasTracking,
} from 'services/inventory';
import { fetchLocations, getLocations } from 'services/locations';
import { fetchVendors } from 'services/vendors';
import { fetchUoms } from 'services/uoms';
import { fetchTaxRates } from 'services/taxRates';
import { fetchTrackingTypes } from 'services/settings/tracking';
import { Pagination } from 'services/search';
import { initialItem } from 'services/items/consts';
import { exportSampleCSV, ReportId } from 'services/reports';
import { useUrlQueryObject } from 'services/url';
import {
  showDownloadNotification,
  showLoadingNotification,
} from 'services/api/notifications';
import { ExportSampleType, getErrorMessage } from 'helpers';
import { PaperSlidingLayout } from 'ui/components/Paper/PaperSlidingLayout';
import { PageWithAdvancedSearch } from 'ui/components/Page/PageWithAdvancedSearch';
import { withSearchResults } from 'ui/components/Page/WithSearchResults';
import { ReportsModal } from 'ui/components/Modal/ReportsModal';
import { showProgressAlert } from 'services/alert/redux';
import {
  startBackgroundImport,
  BackgroundType,
  BackgroundAction,
  startBackgroundExport,
  useBackgroundTasks,
} from 'services/backgroundTasks';
import { fetchTagsAPI, Tag } from 'services/tags';
import {
  clearModuleNavigation,
  ModuleNavigationType,
  removeModuleNavigation,
} from 'services/moduleNavigation';
import { fetchSettingsCompanies } from 'services/settings/company';
import { Routes } from '../../navigation';
import {
  ItemSearchResults,
  ItemsAdvancedSearch,
  ItemDetailsCard,
  InventoryEventModal,
} from './components';
import {
  InventoryEventFormValues,
  transformToInventoryEventData,
} from './components/InventoryEventModal';
import { InventoryPageAction } from './components/ItemSearchResults/consts';
import {
  initialInventoryFormValues,
  advancedSearchReduxActions,
  ITEM_COLUMNS,
  initialPagination,
  displayNameMap,
  createDisplayValueMap,
} from './consts';
import { TrackingPageProps, TrackingPageCmp } from './types';
import { logErrorCtx } from 'app/logging';
import { getSettingsCompany } from 'services/settings/company';
import { CycleEventModal } from './components/CycleEventModal';
import { ModuleNavigation } from 'app/components/AppBar/components';
import QuickTransferModal from './components/QuickTransferModal/QuickTransferModal';
import { useLocation, useNavigate } from 'react-router-dom';

const TrackingPage: TrackingPageCmp = (props: TrackingPageProps) => {
  const {
    searchState: searchResult,
    refreshSearchState: fetchSearchResult,
    isLoadingSearchState: isLoadingSearchResult,
    activeItemId,
  } = props;

  const { items: locations } = useSelector(getLocations);
  const company = useSelector(getSettingsCompany);

  const [activeInventoryItem, setActiveInventoryItem] =
    useState<Item>(initialItem);
  const [eventType, setEventType] = useState<ItemInventoryEvents>(
    ItemInventoryEvents.Add
  );
  const [showEventModal, setShowEventModal] = useState<boolean>(false);
  const [showReport, setShowReport] = useState(false);
  const [showQuickTransferModal, setShowQuickTransferModal] = useState(false);
  const [searchTags, setSearchTags] = useState<Tag[]>([]);
  const [duplicateArray, setDuplicateArray] = useState<string[]>([]);

  const navigate = useNavigate();
  const location = useLocation();
  const [, extendUrlQuery] = useUrlQueryObject(navigate, location);
  const dispatch = useDispatch();

  const { startFetching, startCsvFetching } = useBackgroundTasks();

  const hiddenInput = useRef<HTMLInputElement>(null);

  const hasTracking = itemHasTracking(activeInventoryItem);

  const handleCloseItemInfoCard = () => extendUrlQuery({ activeId: null });

  const handlePaginationChange = (newPagination: Pagination) => {
    fetchSearchResult({ pagination: newPagination });
  };

  const handleRowThreeDotMenuClick = async (
    item: Item,
    type: ItemInventoryEvents
  ) => {
    setActiveInventoryItem(item);
    setEventType(type);
    setShowEventModal(true);
  };

  const handleEventModalSave = async (
    formValues: InventoryEventFormValues,
    trackingGroups: TrackingGroup[]
  ) => {
    const inventoryEventData = transformToInventoryEventData(
      eventType,
      activeInventoryItem.id!,
      formValues,
      trackingGroups,
      company?.homeCurrency?.code ?? 'USD'
    );

    await postInventoryEvent(
      eventType,
      inventoryEventData,
      hasTracking,
      setDuplicateArray
    );
    await fetchSearchResult();
  };

  //this will run after every postInventoryEvent call
  useEffect(() => {
    if (!duplicateArray.length) {
      setShowEventModal(false);
    }
  }, [duplicateArray]);

  // watch advanced search columns and fetch selected tags
  useEffect(() => {
    const tagIds = _.get(
      searchResult.advancedSearch.columns,
      ['tags.id'],
      null
    ) as number[];

    if (tagIds) {
      (async () => {
        try {
          const resTags = await fetchTagsAPI({}, tagIds);

          setSearchTags(resTags.data);
        } catch {
          //ignore Error
        }
      })();
    }
  }, [searchResult.advancedSearch.columns]);

  useEffect(() => {
    dispatch(clearModuleNavigation(ModuleNavigationType.Materials));

    return () => {
      dispatch(removeModuleNavigation());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleHiddenInput = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        return;
      }

      const uploadedFile = event.target.files[0];
      showLoadingNotification('Your Inventory import has been initiated.');

      startCsvFetching();

      const InventoryName = BackgroundType.Cycle;

      // reset file to default value
      event.target.value = '';
      try {
        await startBackgroundImport(InventoryName, uploadedFile);
        dispatch(
          showProgressAlert(
            'Your Import to Inventory has been initiated. This may take a few minutes to complete.',
            InventoryName,
            BackgroundAction.Import
          )
        );
      } catch (e) {
        const error = e as Error;
        const message = getErrorMessage(error);
        logErrorCtx('Error in startBackgroundImport', {
          error,
          stackTrace: error.stack,
          title: 'Background Import Failed',
          description: message,
          component: 'InventoryPage',
        });
        return;
      }
    },
    [dispatch]
  );

  const exportInventorySample = useCallback(async (type: ExportSampleType) => {
    try {
      await exportSampleCSV(type);
    } catch {
      return;
    }
  }, []);

  const exportInventory = useCallback(async () => {
    showDownloadNotification();
    try {
      await startBackgroundExport(BackgroundType.Inventory);
      startFetching();
    } catch {
      // continue regardless of error
    }
  }, [startFetching]);

  const importInventory = useCallback(() => {
    hiddenInput.current!.click();
  }, [hiddenInput]);

  const handleInventoryPageAction = useCallback(
    (action: InventoryPageAction) => {
      switch (action) {
        case InventoryPageAction.ImportInventory:
          importInventory();
          break;
        case InventoryPageAction.ExportAddInventorySample:
          exportInventorySample(ExportSampleType.InventoryExportAddSample);
          break;
        case InventoryPageAction.ExportCycleInventorySample:
          exportInventorySample(ExportSampleType.InventoryExportCycleSample);
          break;
        case InventoryPageAction.ExportInventory:
          exportInventory();
          break;
        case InventoryPageAction.ShowReport:
          setShowReport(true);
      }
    },
    [importInventory, exportInventorySample, exportInventory]
  );

  const handleCancelModalClicked = () => {
    closeModals();
  };

  const closeModals = () => {
    setShowEventModal(false);
    setActiveInventoryItem(initialItem);
    setDuplicateArray([]);
  };

  const handleItemClicked = (id: number | null) =>
    extendUrlQuery({ activeId: id });

  return (
    <>
      <ModuleNavigation />
      <PageWithAdvancedSearch
        detailCardColumns={ITEM_COLUMNS}
        initialFormValues={initialInventoryFormValues}
        advancedSearchReduxActions={advancedSearchReduxActions}
        searchResult={searchResult}
        displayNameMap={displayNameMap}
        displayValueMap={createDisplayValueMap(locations, searchTags)}
        fetchSearchResult={fetchSearchResult}
        AdvancedSearchFieldsCmp={ItemsAdvancedSearch}
        pageName="Inventory"
      >
        <PaperSlidingLayout shown={Boolean(activeItemId)}>
          <ItemSearchResults
            items={searchResult.items}
            activeItemId={activeItemId}
            handleItemClick={handleItemClicked}
            isLoadingItems={isLoadingSearchResult}
            pagination={searchResult.pagination || initialPagination}
            onPaginationChange={handlePaginationChange}
            onRowAction={handleRowThreeDotMenuClick}
            onInventoryPageAction={handleInventoryPageAction}
            setShowQuickTransferModal={setShowQuickTransferModal}
          />
          <ItemDetailsCard
            activeItemId={activeItemId}
            onCancel={handleCloseItemInfoCard}
            fetchSearchResult={fetchSearchResult}
            duplicateArray={duplicateArray}
            setDuplicateArray={setDuplicateArray}
          />
        </PaperSlidingLayout>
      </PageWithAdvancedSearch>
      {eventType === ItemInventoryEvents.Cycle ? (
        <CycleEventModal
          show={showEventModal}
          item={activeInventoryItem}
          locationId={null}
          eventType={ItemInventoryEvents.Cycle}
          onApplyClicked={handleEventModalSave}
          onCancelClicked={handleCancelModalClicked}
          locationDuplicateArray={duplicateArray}
          setShowEventModal={setShowEventModal}
        />
      ) : (
        <InventoryEventModal
          show={showEventModal}
          item={activeInventoryItem}
          eventType={eventType}
          onApplyClicked={handleEventModalSave}
          onCancelClicked={handleCancelModalClicked}
        />
      )}
      <input
        type="file"
        ref={hiddenInput}
        style={{ display: 'none' }}
        onChange={handleHiddenInput}
        accept=".csv"
      />
      <ReportsModal
        isOpen={showReport}
        reportId={ReportId.Items}
        params={{}}
        onClose={() => setShowReport(false)}
        autoGenerate
      />
      <QuickTransferModal
        show={showQuickTransferModal}
        setShowQuickTransferModal={setShowQuickTransferModal}
        item={activeInventoryItem}
        locationId={null}
        onCancelClicked={handleCancelModalClicked}
      />
    </>
  );
};

TrackingPage.route = Routes.TrackingPage;

export default withSearchResults<Item>(TrackingPage, {
  url: '/v1/items?itemType=Inventory',
  expand:
    'images,defaultUom.fromConversions,defaultUom.toConversions,itemTrackingTypes.trackingType,itemLocations',
  dataAdapter: transformItem,
  columns: ITEM_COLUMNS,
  quickSearchColumns: ['name', 'upc', 'sku', 'description'],
  fetchSearch,
  getSearches,
  initialPagination,
  rehydrationThunks: [
    fetchVendors,
    fetchUoms,
    fetchLocations,
    fetchTaxRates,
    fetchTrackingTypes,
    fetchSettingsCompanies,
  ],
});
