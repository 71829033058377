import React from 'react';
import { TableCell } from '@mui/material';
import moment from 'moment';
import _ from 'lodash';

import { LedgerItemSimple, LedgerSimple } from 'services/ledgers';
import { useCurrencyFormatter, useGetIntlDateFormatString } from 'helpers';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';

const CustomRow: React.FC<RenderCustomRowProps<LedgerItemSimple>> = (props) => {
  const { row, meta, index, columns } = props;

  const currencyFormatter = useCurrencyFormatter();
  const intlDateFormat = useGetIntlDateFormatString();

  const ledger: LedgerSimple = meta;

  return (
    <>
      <TableCell data-qa={`Row-${index}-Date`}>
        {index === 0 ? moment(ledger.date || '').format(intlDateFormat) : null}
      </TableCell>
      <TableCell data-qa={`Row-${index}-Type`}>
        {index === 0 ? ledger.type : null}
      </TableCell>
      <TableCell data-qa={`Row-${index}-Reference-Number`}>
        {index === 0 ? ledger.refNo : null}
      </TableCell>
      <TableCell data-qa={`Row-${index}-Customer`}>
        {index === 0 ? ledger.customerId : null}
      </TableCell>
      <TableCell data-qa={`Row-${index}-Class`}>
        {index === 0 ? ledger.accountingClassId : null}
      </TableCell>
      <TableCell data-qa={`Row-${index}-Name`}>
        {index === 0 ? ledger.name : null}
      </TableCell>
      <TableCell
        data-qa={`Row-${index}-Memo`}
        style={{ width: columns[4].width, maxWidth: columns[4].width }}
      >
        {row.memo}
      </TableCell>
      <TableCell data-qa={`Row-${index}-Account`}>{row.accountName}</TableCell>
      <TableCell data-qa={`Row-${index}-Debit`}>
        {_.isNull(row.debitAmount) ? '-' : currencyFormatter(row.debitAmount)}
      </TableCell>
      <TableCell data-qa={`Row-${index}-Credit`}>
        {_.isNull(row.creditAmount) ? '-' : currencyFormatter(row.creditAmount)}
      </TableCell>
    </>
  );
};

export default React.memo(CustomRow);
