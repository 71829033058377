import React, { memo } from 'react';

import { Box } from '@mui/material';

import { QuickBooksDialogActionsProps } from './types';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const QuickBooksDialogActions: React.FC<QuickBooksDialogActionsProps> = ({
  closeConnectModal,
  qboConnectClicked,
}) => {
  return (
    <Box display="flex" justifyContent="flex-end" width="100%">
      <FBOButton
        variant="secondary"
        color="positive"
        data-qa="cancel-button"
        onClick={closeConnectModal}
        sx={{ marginRight: '16px' }}
      >
        Cancel
      </FBOButton>
      <FBOButton
        variant="primary"
        color="positive"
        data-qa="connect-to-quickbooks-button"
        onClick={qboConnectClicked}
        sx={{ marginRight: '8px' }}
      >
        Connect to QuickBooks
      </FBOButton>
    </Box>
  );
};

export default memo(QuickBooksDialogActions);
