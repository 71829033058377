import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { Routes as CommerceRoutes } from './routes';
import { CommerceRouterCmp } from './types';
import { DiscoverChannels } from '../pages/DiscoverChannels';
import { MyChannels } from '../pages/MyChannelsPage';
import { Settings } from '../pages/Settings';
import { SecureRoute } from 'ui/components/Route';
import { isTestEnv } from 'services/api/testEnv';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CommerceRouter: CommerceRouterCmp = () => {
  return (
    <Routes>
      <Route element={<SecureRoute redirectToHome={isTestEnv()} />}>
        <Route path={DiscoverChannels.route} element={<DiscoverChannels />} />
        <Route path={MyChannels.route} element={<MyChannels />} />
        <Route path={Settings.route} element={<Settings />} />
      </Route>
    </Routes>
  );
};

CommerceRouter.route = CommerceRoutes.CommerceRouter;

export default CommerceRouter;
