import React, { memo, useCallback, useMemo } from 'react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';

import _ from 'lodash';

import { AdvancedSearchFieldsCmpProps } from 'ui/components/Page/PageWithAdvancedSearch';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import {
  COUNTRIES,
  CountryAutocomplete,
  CountryType,
} from 'ui/components/Autocomplete/CountryAutocomplete';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { Location, LocationType } from 'services/locations';

const LocationAdvancedSearch: React.FC<AdvancedSearchFieldsCmpProps> = (
  props
) => {
  const { setFormValues, formValues } = props;

  const selectedToCountry = useMemo(() => {
    return (
      COUNTRIES.find((c) => c.code === formValues['address.country']) || null
    );
  }, [formValues]);

  const handleTypeChange = useCallback(
    (e: React.ChangeEvent<{}>, type: LocationType | null) => {
      setFormValues({ ...formValues, type });
    },
    [formValues, setFormValues]
  );

  const handleRootLocationChange = useCallback(
    (v: Location | null) => {
      const id = _.get(v, 'id', null);
      setFormValues({
        ...formValues,
        parentLocationId: id,
      });
    },
    [formValues, setFormValues]
  );

  const handleCountryChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, value: CountryType | null) =>
      setFormValues({
        ...formValues,
        'address.country': _.get(value, 'code', ''),
      }),
    [formValues, setFormValues]
  );

  const handleShowDeletedChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
      setFormValues({ ...formValues, deleted: checked ? 'true' : null }),
    [formValues, setFormValues]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Autocomplete
          label="Type"
          placeholder="Select type"
          options={Object.values(LocationType)}
          value={formValues.type}
          onChange={handleTypeChange}
          dataQa="select-type"
        />
      </Grid>
      <Grid item xs={6}>
        <LocationsAsyncAutocomplete
          onChange={handleRootLocationChange}
          label="Parent Location"
          placeholder="Select parent location"
          value={formValues.parentLocationId as number}
          companyWide={false}
          parentId={null}
          dataQa="select-parent-location"
        />
      </Grid>
      <Grid item xs={6}>
        <CountryAutocomplete
          value={selectedToCountry}
          onChange={handleCountryChange}
          placeholder="Select country"
          label="Country"
          data-qa="select-country"
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={!!formValues.deleted}
              onChange={handleShowDeletedChange}
              color="primary"
              data-qa="show-deleted"
            />
          }
          label="Show Deleted"
        />
      </Grid>
    </Grid>
  );
};

export default memo(LocationAdvancedSearch);
