import React, { memo, useCallback } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';

import { themeRestyle } from 'ui/theme';
import { TextField } from 'ui/components/TextField/TextField';

import { TrackingHeaderProps } from './types';
import { TrackingTableTypes } from '../../types';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { Box } from '@mui/system';

const TrackingHeader: React.FC<TrackingHeaderProps> = (props) => {
  const { search, setSearch, onAddNewTracking, tableType, sx = {} } = props;

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value || null);
    },
    [setSearch]
  );

  const handleAddTrackingClick = useCallback(() => {
    if (onAddNewTracking) {
      onAddNewTracking();
    }
  }, [onAddNewTracking]);

  return (
    <FBOTitleBar sx={sx} noPadding>
      <Box flexGrow={1}>
        <TextField
          className="redesign"
          variant="standard"
          fullWidth
          value={search}
          onChange={handleSearchChange}
          placeholder="Search"
          dataQa="tracking-header-search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {(tableType === TrackingTableTypes.Add ||
        tableType === TrackingTableTypes.CycleNew) && (
        <FBOButton
          sx={{ marginLeft: themeRestyle.spacing(1) }}
          variant="secondary"
          color="positive"
          size="medium"
          icon="FBOAddCircle"
          data-qa="tracking-button-add-row"
          onClick={handleAddTrackingClick}
        >
          Add Row
        </FBOButton>
      )}
    </FBOTitleBar>
  );
};

export default memo(TrackingHeader);
