import React, { useMemo, useCallback } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import _ from 'lodash';

import { MultiCurrencyWrapper } from 'ui/components/MultiCurrencyWrapper/MultiCurrencyWrapper';
import { RenderCustomFooterProps } from 'ui/components/Table/ItemsTable';
import { useCurrencyFormatter, toMulticurrencyCalculate } from 'helpers';

import { CustomFooterProps } from './types';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';

import { useTableFooterStyle } from './styled';

const CustomFooter: React.FC<RenderCustomFooterProps<CustomFooterProps>> = (
  props
) => {
  const { footerData } = props;

  const classes = useTableFooterStyle();

  const currencyFormatter = useCurrencyFormatter();

  const activeMulticurrencyCode = _.get(
    footerData,
    'orderMulticurrencyData.currencyCode',
    'USD'
  );

  const totals = useMemo(() => {
    return footerData!.receiptItems.reduce(
      (acc, item) => {
        if (item.addToVendorBill) {
          acc.billed += item.billedTotalCost || 0;
        } else {
          acc.notBilled += item.billedTotalCost || 0;
        }
        return acc;
      },
      {
        billed: 0,
        notBilled: 0,
      }
    );
  }, [footerData]);

  const formatMulticurrency = useCallback(
    (cost: number) =>
      activeMulticurrencyCode &&
      currencyFormatter(
        toMulticurrencyCalculate(
          cost || 0,
          _.get(footerData, 'orderMulticurrencyData.exchangeRate', 1)
        ),
        activeMulticurrencyCode
      ),
    [activeMulticurrencyCode, footerData, currencyFormatter]
  );

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      height="100%"
      px={1}
    >
      <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
        <Grid container item xs={5}>
          <Grid item xs={5}>
            <CurrencyField
              label="Currency"
              value={1}
              disabled={true}
              fullWidth
              allowNegative
              decimalPlaces={5}
            />
          </Grid>
          <Grid item>
            <Box marginLeft="13px" marginRight="10px" marginTop="9px">
              =
            </Box>
          </Grid>
          <Grid item xs={5}>
            <CurrencyField
              label="Rate"
              value={_.get(
                footerData,
                'orderMulticurrencyData.exchangeRate',
                1
              )}
              disabled={true}
              onChange={() => {}}
              fullWidth
              currencyCode={activeMulticurrencyCode || 'USD'}
              decimalPlaces={5}
            />
          </Grid>
        </Grid>
      </MultiCurrencyWrapper>
      <Grid
        container
        item
        justifyContent="flex-end"
        direction="row"
        spacing={2}
        xs={12}
        xl={8}
        className={classes.paddingRight}
      >
        <Grid item xs={4} xl={3}>
          <Grid item xs={12} xl={12}>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <Typography component="div">
                Not Billed: <b>{currencyFormatter(totals.notBilled)}</b>
              </Typography>
            </Box>
          </Grid>
          <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
            <Grid item xs={12} xl={12}>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Typography component="div">
                  {`Not Billed ${_.get(
                    footerData,
                    'orderMulticurrencyData.currencyCode',
                    ''
                  )}:`}{' '}
                  <b>{formatMulticurrency(totals.notBilled) || ''}</b>
                </Typography>
              </Box>
            </Grid>
          </MultiCurrencyWrapper>
        </Grid>
        <Grid item xs={4} xl={3}>
          <Grid item xs={12} xl={12}>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <Typography component="div">
                Total: <b>{currencyFormatter(totals.billed)}</b>
              </Typography>
            </Box>
          </Grid>
          <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
            <Grid item xs={12} xl={12}>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Typography component="div">
                  {`Total ${_.get(
                    footerData,
                    'orderMulticurrencyData.currencyCode',
                    ''
                  )}:`}{' '}
                  <b>{formatMulticurrency(totals.billed) || ''}</b>
                </Typography>
              </Box>
            </Grid>
          </MultiCurrencyWrapper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(CustomFooter);
