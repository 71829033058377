import React, { useCallback, useEffect, useState, memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { putPoSettings, PickingSettings } from 'services/settings/picking';
import {
  fetchSettingsPicking,
  getSettingsPicking,
} from 'services/settings/picking/redux';
import { PermissionType } from 'services/permissions';

import { pickingRowDescriptor, pickingCardId, title } from './consts';
import { PickingCardProps } from './types';
import { Card } from '../Card';
import { searchFilter, searchTitle } from '../../helpers';

const PickingOrderCard: React.FunctionComponent<PickingCardProps> = (props) => {
  const { search } = props;
  const dispatch = useDispatch();

  const [poSettings, setPoSettings] = useState<PickingSettings>();
  const poSettingsCopy = useSelector(getSettingsPicking);

  const regex = useMemo(() => {
    return RegExp(_.escapeRegExp(search), 'gi');
  }, [search]);

  const hasSearch = useMemo(() => {
    return pickingRowDescriptor.some((t) => regex.test(t.title));
  }, [regex]);

  const hasTitle = useMemo(() => {
    return new RegExp(_.escapeRegExp(search), 'i').test(title);
  }, [search]);

  const highlightedRow = useMemo(() => {
    if (hasSearch) {
      return searchFilter(pickingRowDescriptor, regex);
    }
    return pickingRowDescriptor;
  }, [hasSearch, regex]);

  const highlightedTitle = useMemo(() => {
    if (hasTitle) {
      return searchTitle(title, regex);
    }
    return title;
  }, [hasTitle, regex]);

  useEffect(() => {
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchSettingsPicking());
  }, [dispatch]);

  useEffect(() => {
    setPoSettings(poSettingsCopy);
  }, [poSettingsCopy]);

  const handleApplyClicked = useCallback(async (newPoSettings) => {
    try {
      const poSettingsFromApi = await putPoSettings(newPoSettings);
      setPoSettings(poSettingsFromApi);
    } catch (e) {
      // continue
    }
  }, []);

  return (
    <Card
      title={highlightedTitle}
      rows={highlightedRow}
      id={pickingCardId}
      data={poSettings}
      onApplyClicked={handleApplyClicked}
      show={hasSearch || hasTitle}
      viewPermissions={[PermissionType.SettingsPickingView]}
      editPermissions={[PermissionType.SettingsPickingEdit]}
      dataQa="picking-settings"
    />
  );
};

export default memo(PickingOrderCard);
