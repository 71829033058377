import React from 'react';
import { useSelector } from 'react-redux';

import { getSettingsCompany } from 'services/settings/company';

import { MultiCurrencyWrapperProps } from './types';

export const MultiCurrencyWrapper: React.FC<MultiCurrencyWrapperProps> = ({
  children,
  multiCurrency,
}) => {
  const { homeCurrency, useMultiCurrency } = useSelector(getSettingsCompany);

  const homeCurrencyCode = (homeCurrency && homeCurrency.code) || 'USD';
  const showMultiCurrency =
    useMultiCurrency && multiCurrency && multiCurrency !== homeCurrencyCode;

  return showMultiCurrency ? <>{children}</> : null;
};
