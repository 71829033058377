import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const CLASSES_DETAILS_COLUMNS: Column[] = [
  {
    title: 'Date Created',
    type: ColumnTypes.renderByCustomRow,
    field: 'dateCreated',
    sortable: true,
  },
  {
    title: 'Name',
    type: ColumnTypes.renderByCustomRow,
    field: 'name',
    sortable: true,
  },
];
