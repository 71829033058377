import { createApiAction } from 'services/api';
import {
  StartAction,
  SuccessAction,
  FailureAction,
} from 'services/api/redux/types';
import { InternationalTaxRate } from 'services/integrations/xero';

export enum QuickbooksAccountActionTypes {
  FETCH_QUICKBOOKS_ACCOUNT_START = 'quickbooks/fetch_quickbooks_account_start',
  FETCH_QUICKBOOKS_ACCOUNT_SUCCESS = 'quickbooks/fetch_quickbooks_account_success',
  FETCH_QUICKBOOKS_ACCOUNT_FAILURE = 'quickbooks/fetch_quickbooks_account_failure',
  QUICKBOOKS_DISCONNECT_START = 'quickbooks/quickbooks_disconnect_start',
  QUICKBOOKS_DISCONNECT_SUCCESS = 'quickbooks/quickbooks_disconnect_success',
  QUICKBOOKS_DISCONNECT_FAILURE = 'quickbooks/quickbooks_disconnect_failure',
  QUICKBOOKS_TAX_RATES_START = 'quickbooks/quickbooks_tax_rates_start',
  QUICKBOOKS_TAX_RATES_SUCCESS = 'quickbooks/quickbooks_tax_rates_success',
  QUICKBOOKS_TAX_RATES_FAILURE = 'quickbooks/quickbooks_tax_rates_failure',
}

const fetchQuickbooksAccountActionStart = (): StartAction => ({
  type: QuickbooksAccountActionTypes.FETCH_QUICKBOOKS_ACCOUNT_START,
  payload: null,
});

const fetchQuickbooksAccountActionSuccess = (payload: any): SuccessAction => ({
  type: QuickbooksAccountActionTypes.FETCH_QUICKBOOKS_ACCOUNT_SUCCESS,
  payload,
});

const fetchQuickbooksAccountActionFailure = (error: string): FailureAction => ({
  type: QuickbooksAccountActionTypes.FETCH_QUICKBOOKS_ACCOUNT_FAILURE,
  payload: { error },
});

const quickbooksDisconnectActionStart = (): StartAction => ({
  type: QuickbooksAccountActionTypes.QUICKBOOKS_DISCONNECT_START,
  payload: null,
});

const quickbooksDisconnectActionSuccess = (): SuccessAction => ({
  type: QuickbooksAccountActionTypes.QUICKBOOKS_DISCONNECT_SUCCESS,
  payload: null,
});

const quickbooksDisconnectActionFailure = (error: string): FailureAction => ({
  type: QuickbooksAccountActionTypes.QUICKBOOKS_DISCONNECT_FAILURE,
  payload: { error },
});

export const fetchQuickbooksActiveAccount = () =>
  createApiAction(
    {
      path: '/integrations/qbo/accounts',
      method: 'GET',
    },
    fetchQuickbooksAccountActionStart,
    fetchQuickbooksAccountActionSuccess,
    fetchQuickbooksAccountActionFailure
  );

export const quickbooksDisconnect = (clearMappings: boolean = false) =>
  createApiAction(
    {
      path: `/integrations/qbo/disconnect?clearMappings=${clearMappings}`,
      method: 'GET',
    },
    quickbooksDisconnectActionStart,
    quickbooksDisconnectActionSuccess,
    quickbooksDisconnectActionFailure
  );

// quickbook taxes
const quickbookTaxRatesActionStart = (): StartAction => ({
  type: QuickbooksAccountActionTypes.QUICKBOOKS_TAX_RATES_START,
  payload: null,
});

const quickbookTaxRatesActionSuccess = (
  payload: InternationalTaxRate[]
): SuccessAction => ({
  type: QuickbooksAccountActionTypes.QUICKBOOKS_TAX_RATES_SUCCESS,
  payload,
});

const quickbookTaxRatesActionFailure = (error: string): SuccessAction => ({
  type: QuickbooksAccountActionTypes.QUICKBOOKS_TAX_RATES_FAILURE,
  payload: { error },
});

export const fetchQuickbookTaxRates = () =>
  createApiAction(
    { path: '/v2/integrations/qbo/taxes', method: 'GET' },
    quickbookTaxRatesActionStart,
    quickbookTaxRatesActionSuccess,
    quickbookTaxRatesActionFailure
  );
