import React, { useCallback, useMemo } from 'react';
import { Grid, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { getUoms, Uom } from 'services/uoms';
import { UomAutocomplete } from 'ui/components/Autocomplete/UomAutocomplete';
import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import { ItemsAutocomplete } from 'ui/components/Autocomplete/ItemsAutocomplete';
import { Item, ItemType } from 'services/items';
import { useHandleTextFieldChange } from 'services/forms';
import { VendorItem } from 'services/vendors';
import {
  calculateHomeCurrencyPrice,
  calculateMulticurrencyPrice,
} from 'ui/modules/sales/pages/SalesOrderPage/components/SalesOrderDetailsCard/components/SalesOrderItems/helpers';
import { GridColumn, GridDivider } from 'ui/components/Grid';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';
import { roundToDecimals } from 'helpers';
import { MultiCurrencyWrapper } from 'ui/components/MultiCurrencyWrapper/MultiCurrencyWrapper';

import { ItemsTabModalProps } from './types';
import { removeUomsWithNoConversion } from 'ui/components/Modal/VendorItemModal/helpers';

const ItemsTabModal: React.FC<ItemsTabModalProps> = (props) => {
  const {
    onClose,
    modalVisible,
    vendorItem,
    setVendorItem,
    onSaveChanges,
    validationErrors,
    setValidationErrors,
    vendorItems,
    vendor,
  } = props;

  const { items: uoms } = useSelector(getUoms);

  const currencyCode = _.get(vendor, 'currency.code', null);
  const exchangeRate = _.get(vendor, 'currency.exchangeRate', 1);
  const vendorItemType = _.get(vendorItem.item, 'itemType', null);

  const selectedUom = useMemo(
    () => uoms.find((uom) => uom.id === vendorItem.defaultUomId),
    [uoms, vendorItem.defaultUomId]
  );

  // Move selected item id to array
  const vendorItemsIdArray = useMemo(() => {
    return vendorItems.map((item) => {
      return item.itemId!;
    });
  }, [vendorItems]);

  const handleTextFieldChange = useHandleTextFieldChange<VendorItem>(
    setVendorItem,
    vendorItem
  );
  const handleNumberFieldChange = useHandleTextFieldChange<VendorItem>(
    setVendorItem,
    vendorItem,
    true
  );

  const oldVendorItem = useMemo(
    () => vendorItem,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [vendorItem.id]
  );

  const handleMultiCurrencyPriceInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value ? parseFloat(e.target.value) : 0;

      const cost = calculateHomeCurrencyPrice(value, exchangeRate);

      setVendorItem((old) => ({
        ...old,
        multicurrencyVendorItemCost: value,
        cost,
      }));
    },

    [exchangeRate, setVendorItem]
  );

  const handlePriceInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseFloat(e.target.value) ?? null;
      const multiPrice =
        value && calculateMulticurrencyPrice(value, exchangeRate);

      setVendorItem((old) => ({
        ...old,
        multicurrencyVendorItemCost: multiPrice,
        cost: value,
      }));
    },
    [exchangeRate, setVendorItem]
  );

  const handleUomChange = useCallback(
    (uom: Uom | null) => {
      setVendorItem((old) => ({
        ...old,
        defaultUomId: uom ? uom.id : null,
      }));
    },
    [setVendorItem]
  );

  const handleItemChange = useCallback(
    (v: Item | null) =>
      setVendorItem({
        ...vendorItem,
        item: v,
        itemId: v ? v.id : null,
        name: v ? v.name : null,
        cost: v ? v.cost : null,
        multicurrencyVendorItemCost: ((v && v.cost) || 0) * exchangeRate,
      }),
    [setVendorItem, vendorItem, exchangeRate]
  );

  const handleResetClicked = useCallback(() => {
    setVendorItem(oldVendorItem);
    setValidationErrors({});
  }, [oldVendorItem, setVendorItem, setValidationErrors]);

  return (
    <Modal
      open={modalVisible}
      title={vendorItem.id! > 0 ? 'Edit Vendor Item' : 'New Vendor Item'}
      onCancelClicked={onClose}
      onApplyClicked={onSaveChanges}
      applyLabel={vendorItem.id! > 0 ? 'Update' : 'Add'}
      onResetClicked={handleResetClicked}
      maxWidth="md"
      dataQa="vendor-item"
    >
      <Box px={1} py={2} width="100%" overflow="none">
        <Grid container spacing={2} alignContent={'center'}>
          <GridColumn title="Details" columnWidth={6}>
            <Grid item>
              <ItemsAutocomplete
                label="Item"
                onChange={handleItemChange}
                value={vendorItem.item}
                placeholder="Select item"
                required
                dataQa="vendor-item"
                error={!!validationErrors.itemId}
                removedIds={vendorItemsIdArray}
              />
            </Grid>

            <Grid item>
              <TextField
                className="redesign"
                variant="standard"
                type="text"
                label="Vendor Item Name"
                placeholder="Enter vendor item name"
                autoComplete="nope"
                fullWidth
                name="name"
                value={vendorItem.name}
                required
                onChange={handleTextFieldChange}
                error={!!validationErrors.name}
                dataQa="vendor-item-name"
              />
            </Grid>

            <Grid item container direction={'row'} spacing={2} wrap={'nowrap'}>
              <Grid item xs={4}>
                <UomAutocomplete
                  placeholder="Select UOM"
                  label="UOM"
                  required
                  onChange={handleUomChange}
                  value={selectedUom || null}
                  error={!!validationErrors.defaultUomId}
                  disabled={vendorItemType === ItemType.Shipping}
                  dataQa="vendor-item-uom"
                  reduceOptions={removeUomsWithNoConversion(
                    vendorItem.item?.defaultUomId ?? null
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  className="redesign"
                  variant="standard"
                  type="number"
                  label="Lead Time"
                  placeholder="Lead time"
                  autoComplete="nope"
                  fullWidth
                  name="leadTime"
                  value={vendorItem.leadTime}
                  onChange={handleTextFieldChange}
                  // error={!!errors.leadTime}
                  dataQa="vendor-item-lead-time"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  className="redesign"
                  variant="standard"
                  type="number"
                  label="Minimum Quantity"
                  placeholder="Min quantity"
                  autoComplete="nope"
                  fullWidth
                  name="minimumQuantity"
                  value={vendorItem.minimumQuantity}
                  onChange={handleNumberFieldChange}
                  disableDebounce={true}
                  // error={!!errors.minimumQuantity}
                  dataQa="vendor-item-minimum-quantity"
                />
              </Grid>
            </Grid>
          </GridColumn>
          <GridColumn title="Cost" columnWidth={6}>
            <Grid item container direction={'row'} spacing={2} wrap={'nowrap'}>
              <Grid item>
                <CurrencyField
                  label="Cost"
                  placeholder="Cost"
                  name="cost"
                  fullWidth
                  value={vendorItem.cost}
                  onChange={handlePriceInputChange}
                  error={!!validationErrors.cost}
                  allowNegative
                  decimalPlaces={6}
                  required
                  dataQa="vendor-item-cost"
                />
              </Grid>
              <MultiCurrencyWrapper multiCurrency={currencyCode}>
                <Grid item>
                  <TextField
                    className="redesign"
                    variant="standard"
                    type="text"
                    label="Rate"
                    placeholder="Rate"
                    fullWidth
                    value={exchangeRate}
                    disabled
                    onChange={_.noop}
                  />
                </Grid>
                <Grid item>
                  <CurrencyField
                    label={`Cost ${currencyCode}`}
                    placeholder={`Cost ${currencyCode}`}
                    fullWidth
                    value={roundToDecimals(
                      vendorItem.multicurrencyVendorItemCost || 0,
                      6
                    )}
                    onChange={handleMultiCurrencyPriceInputChange}
                    currencyCode={currencyCode}
                    allowNegative
                    decimalPlaces={6}
                  />
                </Grid>
              </MultiCurrencyWrapper>
            </Grid>
          </GridColumn>
        </Grid>
      </Box>
      <GridDivider />
    </Modal>
  );
};

export default React.memo(ItemsTabModal);
