import { AlertState } from './types';

export const initialAlertState: AlertState = {
  alert: {
    show: false,
    msg: null,
    url: null,
  },
  progressAlert: {
    show: false,
    msg: null,
    backgroundType: null,
    backgroundAction: null,
  },
};
