import React, { memo, useState, useCallback, useMemo, useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { InputAdornment, IconButton, PopoverOrigin, Link } from '@mui/material';

import { Menu, MenuItem } from 'ui/components/Menu';
import { TextField } from 'ui/components/TextField/TextField';
import { activeUserHasPermission } from 'services/user/redux';

import { TextFieldQuantityProps } from './types';
import { useTextFieldQuantityStyle } from './styled';
import NumberFormatQuantity from './NumberFormatQuantity';

const anchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

const transformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

const TextFieldQuantity: React.FC<TextFieldQuantityProps> = (props) => {
  const {
    selectedUomId,
    onMenuChange,
    onTextChange,
    isUomSelectDisabled = false,
    uoms,
    isDecimal = false,
    permissions = [],
    noMargin = false,
    dataQa,
    ...otherProps
  } = props;

  const classes = useTextFieldQuantityStyle(props);
  const canEdit = useSelector(activeUserHasPermission(permissions));

  const { value, disabled, readOnly, inputProps, additionalInputProps } =
    otherProps;

  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const isActionMenuVisible = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const isDisabled = useMemo(() => disabled || !canEdit, [disabled, canEdit]);

  const menuDisabled = useMemo(
    () => isDisabled || readOnly || !onMenuChange || isUomSelectDisabled,
    [isDisabled, readOnly, onMenuChange, isUomSelectDisabled]
  );

  const amountOptions: MenuItem[] = useMemo(
    () =>
      uoms.map((u) => ({
        id: u.id!,
        label: u.name!,
        shortName: u.abbreviation!,
      })),
    [uoms]
  );

  const readOnlyValue = useMemo(() => {
    return `${value} ${_.get(amountOptions[currentIndex], 'shortName', '')}`;
  }, [value, amountOptions, currentIndex]);

  useEffect(() => {
    if (selectedUomId) {
      const selectedUomIndex = amountOptions.findIndex(
        (item) => item.id === selectedUomId
      );

      if (selectedUomIndex !== -1) {
        setCurrentIndex(selectedUomIndex);
      }
    }
  }, [selectedUomId, amountOptions]);

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (!onTextChange) {
        return;
      }
      if (event.target.value === '') {
        onTextChange(null);
        return;
      }

      const parsedValue = isDecimal
        ? parseFloat(event.target.value)
        : parseInt(event.target.value, 10);

      onTextChange(parsedValue);
    },
    [isDecimal, onTextChange]
  );

  const handleActionMenuClose = useCallback((event) => {
    event.stopPropagation();

    setAnchorEl(null);
  }, []);

  const handleIconClicked = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleMenuItemClick = useCallback(
    (actionItemIndex: number, event: any) => {
      if (!onMenuChange) {
        return;
      }

      onMenuChange(amountOptions[actionItemIndex].id!);
      setCurrentIndex(actionItemIndex);
      handleActionMenuClose(event);
    },
    [amountOptions, onMenuChange, handleActionMenuClose]
  );

  const renderAdornment = useCallback(() => {
    const shortName = _.get(amountOptions[currentIndex], 'shortName', '');

    if (!shortName) {
      return null;
    }

    return (
      <InputAdornment position="end">
        <IconButton
          onClick={handleIconClicked}
          edge="end"
          classes={{ edgeEnd: classes.noBorder }}
          disabled={menuDisabled}
          size="large"
        >
          <Link
            className={classes.link}
            variant="body2"
            color="textPrimary"
            underline="hover"
          >
            {shortName}
          </Link>
        </IconButton>
      </InputAdornment>
    );
  }, [classes, menuDisabled, amountOptions, currentIndex, handleIconClicked]);

  return (
    <>
      {readOnly ? (
        <TextField
          {...otherProps}
          className={noMargin ? 'redesign no-margin' : 'redesign'}
          variant="standard"
          value={readOnlyValue}
        />
      ) : (
        <TextField
          {...otherProps}
          className={noMargin ? 'redesign no-margin' : 'redesign'}
          variant="standard"
          inputProps={{
            allowDecimal: isDecimal,
            'data-qa': dataQa,
            ...inputProps,
          }}
          additionalInputProps={{
            inputComponent: NumberFormatQuantity as any,
            endAdornment: renderAdornment(),
            ...additionalInputProps,
          }}
          disabled={isDisabled}
          onChange={handleOnChange}
        />
      )}

      <Menu
        items={amountOptions}
        open={isActionMenuVisible}
        anchorEl={anchorEl}
        onClose={handleActionMenuClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onMenuItemClick={(number: number, ev) =>
          handleMenuItemClick(number, ev)
        }
        dataQa={dataQa}
      />
    </>
  );
};

export default memo(TextFieldQuantity);
