import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { DRAWER_WIDTH } from 'ui/theme/components/FBODrawer/consts';

const toRem = (size: number): string => {
  return `${size / 16}rem`;
};

export const APP_PADDING = 18;
export const APP_MIN_WIDTH = 1280;
export const APP_MIN_HEIGHT = 768;

export const useAppHomeStyles = makeStyles((theme: Theme) => ({
  homeWrapper: {
    display: 'flex',
    minWidth: APP_MIN_WIDTH,
    maxWidth: '100vw',
    minHeight: APP_MIN_HEIGHT,
    maxHeight: '100vh',
    position: 'relative',
  },
  contentWrapper: {
    flexGrow: 1,
    padding: toRem(APP_PADDING),
    height: '100vh',
    paddingTop: '80px',
    width: `calc(100% - ${toRem(DRAWER_WIDTH)} - ${toRem(APP_PADDING)})`,
  },
  toolbarSpacer: {
    minHeight: theme.spacing(7),
  },
}));
