import React from 'react';
import {
  SearchableState,
  Pagination,
  AdvancedSearchItem,
} from 'services/search';
import { Column } from 'ui/components/Table/ItemsTable/types';
import { State } from 'app/redux';
import { ApiStatus, Thunk } from 'services/api';

export interface WrappedComponent<T>
  extends React.FunctionComponent<WithSearchProps<T>> {
  route?: string;
}

export enum ActiveItemIdState {
  Initial = 0,
  New = -1,
  Duplicate = -2,
}

export interface WithSearchProps<T> {
  expand?: string | string[];
  searchState: SearchableState<T>;
  refreshSearchState: FetchSearchResultFunc;
  isLoadingSearchState: boolean;
  setActiveItemId(id: number | ActiveItemIdState | null): void;
  activeItemId: number | ActiveItemIdState | null;
}

export interface PageWithSearchCmp
  extends React.FunctionComponent<{
    expand?: string | string[];
    fetchItems?: any;
  }> {
  route?: string;
}

export type FetchOptions = Partial<
  Pick<
    SearchableState<unknown>,
    'advancedSearch' | 'quickSearch' | 'pagination'
  >
>;

export type FetchSearchResultFunc = (options?: FetchOptions) => Promise<void>;

/**
 * Options for withSearchResults hoc.
 * `url` - path used for api calls.
 * `dataAdapter` - fnc used to transform data from API.
 * `columns` - used to create quick search string
 * `fetchSearch` - used for fetching advanced searches on first render.
 * `getSearches` - redux selector used to find default search from redux
 * `pagination` - used for initial pagination.
 * `expand` -  used to set expand value.
 */
export interface WithSearchOptions<T> {
  url: string;
  dataAdapter: (serverResponse: any) => T;
  columns: Column[];
  fetchSearch: () => (dispatch: any) => Promise<any>;
  getSearches: (state: State) => {
    _status: ApiStatus;
    items: AdvancedSearchItem[];
  };
  initialPagination?: Pagination;
  expand?: string;
  rehydrationThunks?: Thunk<Promise<any>>[];
  quickSearchColumns?: string[];
}
