import _ from 'lodash';

import { ItemTrackingType, TrackingGroup } from 'services/inventory';
import { TrackingDataTypes } from 'services/settings/tracking';

export const transformToTrackingGroupWithNewTrackings = (
  trackingGroups: TrackingGroup[],
  itemTrackingTypes: ItemTrackingType[],
  newTrackingTypeIds: number[]
) => {
  const newItemTrackingTypes = itemTrackingTypes.filter((t) =>
    newTrackingTypeIds.includes(t.trackingTypeId)
  );

  const newTrackingGroups = trackingGroups.map((g) => {
    const newGroup: TrackingGroup = { ...g, quantity: g.onHand };
    newItemTrackingTypes.forEach((itemTrackingType, index) => {
      const trackingDataType = _.get(
        itemTrackingType,
        'trackingType.trackingDataType'
      );
      const trackingGroupHasTrackingType =
        g.trackingInfoList.findIndex(
          (i) => i.trackingTypeId === itemTrackingType.trackingTypeId
        ) > -1;

      if (trackingGroupHasTrackingType) {
        return;
      }

      if (
        trackingDataType === TrackingDataTypes.Text ||
        trackingDataType === TrackingDataTypes.Date
      ) {
        g.trackingInfoList.push({
          id: -index - 1,
          trackingTypeId: itemTrackingType.trackingTypeId,
          value: null,
        });
      } else {
        g.serialList = g.serialList.map((serialRow) => {
          return {
            ...serialRow,
            serialNumbers: {
              ...serialRow.serialNumbers,
              [itemTrackingType.trackingTypeId]: null,
            },
          };
        });
      }
    });

    return newGroup;
  });

  return newTrackingGroups;
};
