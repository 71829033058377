import React, { memo, useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  InputAdornment,
} from '@mui/material';
import * as yup from 'yup';
import _ from 'lodash';

import { Errors, validateYup } from 'services/forms/validation';
import { useHandleTextFieldChange } from 'services/forms';
import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import { initialCurrency } from 'services/currencies/consts';
import { Currency, fetchCurrencies } from 'services/currencies';
import { createCurrency } from 'services/currencies/api';
import { NumberFormatCustom } from 'ui/components/TextField/MultiFormatInput';
import { useGetCurrencySymbol } from 'helpers';

import { NewCurrencyModalProps } from './types';
import {
  COUNTRIES,
  CountryAutocomplete,
  CountryType,
} from '../CountryAutocomplete';

const yupSchema = yup.object().shape({
  name: yup.string(),
  exchangeRate: yup.number(),
});

const NewClassModal: React.FC<NewCurrencyModalProps> = (props) => {
  const { show, onClose, onSave } = props;

  const dispatch = useDispatch();

  const [formCurrency, setFormCurrency] = useState<Currency>(initialCurrency);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Errors>({});

  const selectedCountryType = useMemo(() => {
    return COUNTRIES.find((c) => c.code === formCurrency.country) || null;
  }, [formCurrency.country]);

  const handleApplyModal = async () => {
    if (!validateYup(formCurrency, yupSchema, setErrors)) {
      return;
    }

    setIsLoading(true);

    try {
      const newCurrency = await createCurrency(formCurrency);
      // Lint skip to be removed
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(fetchCurrencies());
      onSave(newCurrency);
      setErrors({});
    } catch {
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    setFormCurrency(initialCurrency);
  };

  const handleTextFieldChange = useHandleTextFieldChange(
    setFormCurrency,
    formCurrency
  );

  const handleHomeCurrencyChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setFormCurrency({
        ...formCurrency,
        isHomeCurrency: checked,
      });
    },
    [formCurrency, setFormCurrency]
  );

  const handleCountryChange = useCallback((e: any, v: CountryType) => {
    if (v) {
      setFormCurrency((old) => ({
        ...old,
        country: v.code,
        code: v.currencyCode,
        name: v.currencyName,
        exchangeRate: null,
      }));
      setErrors({});
    } else {
      setFormCurrency(initialCurrency);
    }
  }, []);

  const handleExchangeRateChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value ? parseFloat(e.target.value) : null;
    setFormCurrency((old) => ({ ...old, exchangeRate: value }));
  };

  return (
    <Modal
      open={show}
      title="New Currency"
      onCancelClicked={onClose}
      onApplyClicked={handleApplyModal}
      isLoadingContent={isLoading}
      maxWidth="md"
    >
      <Grid container spacing={2}>
        <Grid item container xs={12} spacing={2}>
          <Grid item container xs={12}>
            <Typography variant="caption">
              <strong>Currency Details</strong>
            </Typography>
          </Grid>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={6}>
              <CountryAutocomplete
                value={selectedCountryType}
                onChange={handleCountryChange}
                required
                error={!!errors.name}
                placeholder="Select Country"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="redesign"
                variant="standard"
                name="name"
                label="Currency Name"
                placeholder="Currency Name"
                value={formCurrency.name}
                onChange={handleTextFieldChange}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="redesign"
                variant="standard"
                name="code"
                label="Currency Code"
                placeholder="Currency Code"
                value={formCurrency.code}
                onChange={handleTextFieldChange}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="redesign"
                variant="standard"
                name="symbol"
                label="Currency Symbol"
                placeholder="Currency Symbol"
                value={_.get(selectedCountryType, 'currencySymbol', '')}
                onChange={handleTextFieldChange}
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item container xs={12}>
            <Typography variant="caption">
              <strong>Conversion</strong>
            </Typography>
          </Grid>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={2}>
              <TextField
                className="redesign"
                variant="standard"
                value={1}
                disabled
                InputProps={{
                  inputComponent: NumberFormatCustom as any,
                  endAdornment: (
                    <InputAdornment position="end">
                      {useGetCurrencySymbol()}
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  activeType: { type: 'suffix' },
                  allowNegative: false,
                  decimalPlaces: 0,
                }}
                onChange={handleTextFieldChange}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                className="redesign"
                variant="standard"
                name="exchangeRate"
                label="Exchange Rate"
                disabled={formCurrency.code === null}
                value={formCurrency.exchangeRate}
                required
                InputProps={{
                  inputComponent: NumberFormatCustom as any,
                  endAdornment: (
                    <InputAdornment position="end">
                      {formCurrency.code}
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  activeType: {
                    type: 'suffix',
                  },
                  allowNegative: false,
                  decimalPlaces: 5,
                }}
                onChange={handleExchangeRateChange}
                error={!!errors.exchangeRate}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                className="redesign"
                checked={Boolean(formCurrency.isHomeCurrency)}
                onChange={handleHomeCurrencyChange}
                color="primary"
              />
            }
            label="Home Currency"
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default memo(NewClassModal);
