import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import _ from 'lodash';

import { initialPagination, ItemsTable } from 'ui/components/Table/ItemsTable';
import { Pagination } from 'services/search';

import { ReceivingItemsProps } from './types';
import { ITEM_COLUMNS } from './consts';
import { CustomRow, CustomFooter } from './components';
import { ReceiptOrderType } from 'services/receiving';
import { useSelector } from 'react-redux';
import { getSettingsCompany } from 'services/settings/company';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import { colorPalette } from 'ui/theme';

const ReceivingItems: React.FC<ReceivingItemsProps> = (props) => {
  const {
    receipt,
    setReceiptItems,
    onThreeDotMenuAction,
    onReceivingItemClick,
  } = props;

  const { receiptItems, orderType, purchaseOrder, salesOrder } = receipt;

  const { homeCurrency, useMultiCurrency } = useSelector(getSettingsCompany);

  const homeCurrencyCode = (homeCurrency && homeCurrency.code) || 'USD';

  const orderMulticurrencyData = useMemo(() => {
    if (orderType === ReceiptOrderType.PurchaseOrder) {
      return {
        exchangeRate: purchaseOrder ? purchaseOrder.exchangeRate : 1,
        currencyCode: purchaseOrder
          ? purchaseOrder.exchangeCurrency &&
            purchaseOrder.exchangeCurrency.code
          : null,
        orderType,
      };
    }
    return {
      exchangeRate: salesOrder ? salesOrder.exchangeRate : 1,
      currencyCode: salesOrder
        ? salesOrder.exchangeCurrency && salesOrder.exchangeCurrency.code
        : null,
      orderType,
    };
  }, [salesOrder, purchaseOrder, orderType]);

  const showMultiCurrency = useMemo(
    () =>
      useMultiCurrency &&
      !!orderMulticurrencyData.currencyCode &&
      orderMulticurrencyData.currencyCode !== homeCurrencyCode,
    [orderMulticurrencyData.currencyCode, useMultiCurrency]
  );

  const [pagination, setPagination] = useState<Pagination>(initialPagination);

  // reorder data when pagination sort changed
  useEffect(() => {
    setReceiptItems(
      _.orderBy(receiptItems, pagination.sort.sortBy, pagination.sort.direction)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.sort]);

  return (
    <Box display="flex" flexGrow={1} flexDirection="column" overflow={'hidden'}>
      <FBOTitleBar
        title="Items"
        sx={{
          borderTop: `1px solid ${colorPalette.redesign.background3}`,
          borderRadius: '0 !important',
        }}
      />

      <ItemsTable
        RenderCustomRow={CustomRow}
        columns={ITEM_COLUMNS(
          showMultiCurrency,
          orderMulticurrencyData.currencyCode || 'USD',
          homeCurrencyCode
        )}
        data={receiptItems}
        onAction={onThreeDotMenuAction}
        onItemClick={onReceivingItemClick}
        RenderCustomFooter={CustomFooter}
        meta={{
          orderMulticurrencyData,
        }}
        footerData={{ receiptItems, orderMulticurrencyData }}
        selectableItems={false}
        pagination={pagination}
        onPaginationChange={setPagination}
        dataQa="receiving-items-table"
      />
    </Box>
  );
};

export default React.memo(ReceivingItems);
