import { useCallback } from 'react';
import _ from 'lodash';

/**
 * Handles onSelectedChange callback from  ItemsTable
 * @param selectedItems
 * @param setSelectedItems
 */
export const useSelectedItemsChanges = (
  selectedItems: number[],
  setSelectedItems: (newItems: number[]) => void
) =>
  useCallback(
    (id: number | number[]) => {
      if (Array.isArray(id)) {
        setSelectedItems(id);
        return;
      }

      const isIdAlreadySelected = selectedItems.includes(id);
      if (!isIdAlreadySelected) {
        setSelectedItems([...selectedItems, id]);
        return;
      }

      setSelectedItems(_.without(selectedItems, id));
    },
    [selectedItems, setSelectedItems]
  );
