import React, { memo, useCallback } from 'react';
import { Grid } from '@mui/material';

import { Modal } from 'ui/components/Modal/Modal';
import { Location } from 'services/locations';
import LocationsAsyncAutocomplete from 'ui/components/Autocomplete/LocationsAsyncAutocomplete/LocationsAsyncAutocomplete';

import { LocationsItemAddProps } from './types';

const LocationsItemAddModal: React.FC<LocationsItemAddProps> = (props) => {
  const {
    show,
    locationsItem,
    setLocationsItem,
    onCancelClicked,
    onApplyClicked,
    editPermissions,
    errors,
    isNewItem,
  } = props;

  const isParentLocationSelected = Boolean(locationsItem.parentLocation);

  const handleAutocompleteChange = useCallback(
    (loc: Location | null, nameObject: string, nameId: string) => {
      if (nameId === 'parentLocationId') {
        setLocationsItem({
          ...locationsItem,
          parentLocation: loc,
          parentLocationId: loc ? loc.id : null,
          pickFromLocationId: null,
          pickFromLocation: null,
          receiveLocationId: null,
          receiveLocation: null,
          pickToLocationId: null,
          pickToLocation: null,
        });

        return;
      }

      setLocationsItem({
        ...locationsItem,
        [nameId]: loc ? loc.id : null,
        [nameObject]: loc,
      });
    },
    [setLocationsItem, locationsItem]
  );

  return (
    <Modal
      open={show}
      title={`${isNewItem ? 'Add' : 'Edit'} Locations Item`}
      applyLabel={isNewItem ? 'Add' : 'Save'}
      maxWidth="xs"
      isLoading={false}
      onApplyClicked={onApplyClicked}
      onCancelClicked={onCancelClicked}
      dataQa="item-locations"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LocationsAsyncAutocomplete
            placeholder="Select Location"
            onChange={(loc) => {
              handleAutocompleteChange(
                loc,
                'parentLocation',
                'parentLocationId'
              );
            }}
            label="Parent Location"
            permissions={editPermissions}
            parentId={null}
            companyWide={false}
            value={locationsItem.parentLocation}
            getLocationLabel={(l) => l.path || ''}
            error={!!errors.parentLocationId}
            disabled={!isNewItem}
            dataQa="parent-location"
          />
        </Grid>
        <Grid item xs={12}>
          <LocationsAsyncAutocomplete
            placeholder="Select Location"
            onChange={(loc) =>
              handleAutocompleteChange(
                loc,
                'pickFromLocation',
                'pickFromLocationId'
              )
            }
            label="Pick Origin Location"
            permissions={editPermissions}
            parentId={locationsItem.parentLocationId}
            value={locationsItem.pickFromLocation}
            getLocationLabel={(l) => l.path || ''}
            disabled={!isParentLocationSelected}
            dataQa="pick-origin-location"
          />
        </Grid>
        <Grid item xs={12}>
          <LocationsAsyncAutocomplete
            placeholder="Select Location"
            onChange={(loc) =>
              handleAutocompleteChange(
                loc,
                'receiveLocation',
                'receiveLocationId'
              )
            }
            label="Receive Location"
            permissions={editPermissions}
            parentId={locationsItem.parentLocationId}
            value={locationsItem.receiveLocation}
            getLocationLabel={(l) => l.path || ''}
            disabled={!isParentLocationSelected}
            dataQa="receive-location"
          />
        </Grid>
        <Grid item xs={12}>
          <LocationsAsyncAutocomplete
            placeholder="Select Location"
            onChange={(loc) =>
              handleAutocompleteChange(
                loc,
                'pickToLocation',
                'pickToLocationId'
              )
            }
            label="Pick Destination Location"
            permissions={editPermissions}
            parentId={locationsItem.parentLocationId}
            value={locationsItem.pickToLocation}
            getLocationLabel={(l) => l.path || ''}
            disabled={!isParentLocationSelected}
            dataQa="pick-destination-location"
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default memo(LocationsItemAddModal);
