import React from 'react';
import { TableCell } from '@mui/material';
import moment from 'moment';
import _ from 'lodash';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import { Costing } from 'services/inventory';
import { useCurrencyFormatter, useGetIntlDateFormatString } from 'helpers';

const CostingTableRow: React.FC<RenderCustomRowProps<Costing>> = (props) => {
  const { row } = props;

  const currencyFormatter = useCurrencyFormatter();
  const currencyFormatterSix = useCurrencyFormatter(2, 6);

  const intlFormatDate = useGetIntlDateFormatString();

  const uom = _.get(row, 'item.defaultUom.abbreviation', '');
  const date =
    row.dateCreated && moment(row.dateCreated).format(intlFormatDate);
  const time = row.dateCreated && moment(row.dateCreated).format('HH:mm:ss');

  return (
    <>
      <TableCell>{date}</TableCell>
      <TableCell>{time}</TableCell>
      <TableCell>{`${row.quantity} ${uom}`}</TableCell>
      <TableCell>{currencyFormatterSix(row.unitCost || 0)}</TableCell>
      <TableCell>{currencyFormatter(row.totalCost || 0)}</TableCell>
    </>
  );
};

export default CostingTableRow;
