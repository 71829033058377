import React, { memo, useCallback } from 'react';
import { Box, InputAdornment, Grid, Typography } from '@mui/material';

import { TextField } from 'ui/components/TextField/TextField';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { PermissionType } from 'services/permissions';
import {
  PricingRuleRelativeToType,
  PricingRuleType,
} from 'services/pricingRules';

import { PricingRulesPriceAdjustmentProps } from './types';
import { usePricingRulesPriceAdjustmentStyle } from './styled';
import { IGNORE_ERROR } from '../../validations';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';
import { stringToDecimal } from 'services/forms';

const PricingRulesSidebar: React.FC<PricingRulesPriceAdjustmentProps> = (
  props
) => {
  const { activePricingRule, setActivePricingRule, validationErrors } = props;

  const classes = usePricingRulesPriceAdjustmentStyle();

  const canEditPricingRules =
    activePricingRule.id && activePricingRule.id > 0
      ? [PermissionType.PricinRulesEdit]
      : [PermissionType.PricinRulesCreate];
  const isMargin = activePricingRule.type === PricingRuleType.Margin;
  const resolvedRelativeToOptions =
    activePricingRule.type === PricingRuleType.Margin
      ? Object.values(PricingRuleRelativeToType).filter(
          (option) => option !== PricingRuleRelativeToType.ListPrice
        )
      : Object.values(PricingRuleRelativeToType);

  const resolvedRelativeToValue =
    activePricingRule.type === PricingRuleType.Margin
      ? PricingRuleRelativeToType.AverageCost
      : activePricingRule.relativeTo;
  const handleRelativeToChange = (
    _e: any,
    relativeTo: PricingRuleRelativeToType
  ) => setActivePricingRule((old) => ({ ...old, relativeTo }));

  const handlePercentageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const value = event.target.value;

    setActivePricingRule((old) => ({
      ...old,
      percent: value ? stringToDecimal(value) : null,
    }));
  };

  const handlePriceChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const value = event.target.value;

    setActivePricingRule((old) => ({
      ...old,
      price: value ? parseFloat(value) : null,
    }));
  };

  const handleTypeChange = useCallback(
    (_e: any, type: PricingRuleType) => {
      const isFixedPrice = type === PricingRuleType.Fixed;

      setActivePricingRule((old) => ({
        ...old,
        type,
        percent: isFixedPrice ? null : activePricingRule.percent,
        price: isFixedPrice ? activePricingRule.price : null,
        relativeTo: isFixedPrice
          ? null
          : activePricingRule.relativeTo ||
            PricingRuleRelativeToType.AverageCost,
      }));
    },
    [
      activePricingRule.percent,
      activePricingRule.price,
      activePricingRule.relativeTo,
      setActivePricingRule,
    ]
  );

  return (
    <>
      <Typography variant="body1" color="textPrimary">
        <b>Price Adjustment</b>
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Autocomplete
            options={Object.values(PricingRuleType)}
            placeholder="Choose type"
            value={activePricingRule.type}
            onChange={handleTypeChange}
            fullWidth
            permissions={canEditPricingRules}
            clearIcon={false}
            dataQa="pricing-rule-price-adjustment"
          />
        </Grid>
        {activePricingRule.type === PricingRuleType.Fixed ? (
          <Grid item xs={5}>
            <CurrencyField
              name="price"
              placeholder="Price"
              allowNegative
              permissions={canEditPricingRules}
              value={activePricingRule.price}
              onChange={handlePriceChange}
              error={!!validationErrors.price}
              decimalPlaces={6}
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={4}>
              <TextField
                className="redesign"
                variant="standard"
                type="number"
                name="percent"
                value={activePricingRule.percent}
                placeholder="0"
                onChange={handlePercentageChange}
                disableDebounce={true}
                permissions={canEditPricingRules}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      disableTypography={
                        activePricingRule.type !== PricingRuleType.Markdown
                      }
                      position="start"
                    >
                      %
                    </InputAdornment>
                  ),
                }}
                error={!!validationErrors.percent}
                helperText={
                  validationErrors.percent === IGNORE_ERROR
                    ? undefined
                    : validationErrors.percent
                }
                dataQa="pricing-rule-price-adjustment-percent"
              />
            </Grid>
            <Grid item xs={5}>
              <Box display="flex" alignItems="center" pl={2}>
                <Typography noWrap className={classes.relativeTo}>
                  Relative to
                </Typography>
                <Autocomplete
                  options={resolvedRelativeToOptions}
                  placeholder="Realative to"
                  value={resolvedRelativeToValue}
                  onChange={handleRelativeToChange}
                  fullWidth
                  permissions={canEditPricingRules}
                  clearIcon={false}
                  dataQa="pricing-rule-price-adjustment-relative-to"
                  disabled={isMargin}
                />
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default memo(PricingRulesSidebar);
