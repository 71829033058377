import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const PAYMENTS_COLUMS: Column[] = [
  {
    title: 'Date',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Payment Type',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Transaction Type',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Status',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Amount',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: '',
    type: ColumnTypes.renderByCustomRow,
  },
];
