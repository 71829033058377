import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import AutoComplete from '@mui/material/Autocomplete';
import { CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import _ from 'lodash';

import { TextField } from 'ui/components/TextField/TextField';
import { fetchSaleItem, getSaleItems } from 'services/items';
import { SaleItem } from 'services/items/saleItems';
import { activeUserHasPermission } from 'services/user/redux';

import { SoItemsAutocompleteProps } from './types';
import {
  saleItemsAutocompleteFilterOptions,
  saleItemsAutocompleteOptionLabel,
} from './helpers';
import NewItemModal from './NewItemModal';

const SaleItemsAutocomplete: React.FC<SoItemsAutocompleteProps> = (props) => {
  const {
    label,
    value,
    placeholder,
    required,
    disableAdd = false,
    onChange,
    error,
    disabled,
    removedIds = [],
    permissions = [],
    dataQa,
    renderOption,
    autoSelect,
    itemTypes = [],
  } = props;

  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [newItemName, setNewItemName] = useState<string | null>(null);
  const [saleItems, setSaleItems] = useState<SaleItem[]>([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [innerValue, setInnerValue] = useState<SaleItem | null>(null);

  const canEdit = useSelector(activeUserHasPermission(permissions));

  const isDisabled = disabled || !canEdit;

  const filteredItems = useMemo(
    () => saleItems.filter((item) => !removedIds.includes(item.id!)),
    [saleItems, removedIds]
  );

  useEffect(() => {
    if (typeof value === 'number') {
      if (!innerValue) {
        (async () => {
          const resItem = await fetchSaleItem(value);
          setInnerValue(resItem);
        })();
      }
    } else {
      setInnerValue(value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const getItems = useDebouncedCallback(async () => {
    setFetchLoading(true);
    const resItems = await getSaleItems(searchValue, 99, itemTypes);
    setSaleItems(resItems);
    setFetchLoading(false);
  }, 300);

  useEffect(() => {
    // when autocomplete values is changed and it's not null
    // or when autocomplete is opened and doesn't have any data
    // we want to fetch items
    if (searchValue || (open && !saleItems.length)) {
      getItems();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, open]);

  const handleAutocompleteInputChange = (
    e: any,
    val: string,
    reason: string
  ) => {
    // reason why this event is triggered
    // it can be 'input', 'clear' and 'reset'
    if (reason === 'input') {
      setSearchValue(val || null);
      return;
    }

    setSearchValue(null);
  };

  const handleChange = (e: any, item: SaleItem | null) => {
    if (item && (item.id === null || item.id < 0)) {
      setNewItemName(item.name);
      setShowModal(true);
      return;
    }

    setInnerValue(item);
    onChange(item);
  };

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleSaveModal = useCallback(
    async (item: SaleItem) => {
      setShowModal(false);
      setSaleItems([item]);
      setInnerValue(item);
      onChange(item);
    },
    [onChange]
  );

  return (
    <>
      <AutoComplete
        className={'redesign'}
        options={filteredItems}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={handleChange}
        renderInput={(params) => {
          // params.InputProps.endAdornment is component with 2 children
          // first child is clear button, so we want to set it to null
          const endAdornment = params.InputProps.endAdornment as any;
          const endAdornmentWithoutClear = {
            ...endAdornment,
            props: {
              ...endAdornment.props,
              children: [null, endAdornment.props.children[1]],
            },
          };

          return (
            <TextField
              // we are omitting props that would overwrite our styling in TextField
              {..._.omit(params, 'variant', 'size', 'InputLabelProps')}
              label={label}
              placeholder={placeholder}
              error={error}
              required={required}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {fetchLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {endAdornmentWithoutClear}
                  </React.Fragment>
                ),
              }}
              inputProps={{
                'data-qa': dataQa,
                ...params.inputProps,
              }}
            />
          );
        }}
        filterOptions={saleItemsAutocompleteFilterOptions(disableAdd)}
        onInputChange={handleAutocompleteInputChange}
        getOptionLabel={saleItemsAutocompleteOptionLabel}
        isOptionEqualToValue={(option, val) => option.id === val.id}
        value={innerValue}
        disableClearable={required}
        renderOption={renderOption}
        autoSelect={autoSelect}
        disabled={isDisabled}
        clearIcon={
          dataQa && (
            <CloseIcon
              fontSize="small"
              data-qa={`${dataQa}-autocomplete-clear-icon`}
            />
          )
        }
        popupIcon={
          dataQa && (
            <ArrowDropDownIcon
              fontSize="small"
              data-qa={`${dataQa}-autocomplete-dropdown-icon`}
            />
          )
        }
      />
      <NewItemModal
        show={showModal}
        newItemName={newItemName}
        onSave={handleSaveModal}
        onClose={handleCloseModal}
      />
    </>
  );
};

export default memo(SaleItemsAutocomplete);
