import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableCell, Typography } from '@mui/material';
import _ from 'lodash';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { getUomById } from 'services/uoms';
import { ImageItemBox } from 'ui/components/Image/ImageItemBox';
import { useCurrencyFormatter } from 'helpers';
import { showThumbnail } from 'services/thumbnail/redux';
import { BundleItem } from 'services/items';
import { defaultSaleItem } from 'services/items/saleItems';
import { DiscountTypes } from 'services/salesOrders';

const BundleItemRow: React.FC<RenderCustomRowProps<BundleItem>> = (props) => {
  const { row } = props;

  const currencyFormatter = useCurrencyFormatter();

  const saleItem = _.get(row, 'saleItem', null) || defaultSaleItem;

  const defaultUom = useSelector(getUomById(saleItem.defaultUomId));

  const discount =
    row.discountType === DiscountTypes.FlatRate
      ? currencyFormatter(row.discountFlatAmount || 0)
      : `${((row.discountPercent || 0) * 100).toFixed(2)} %`;

  const itemHasNoDiscount = useMemo(
    () =>
      row.discountType === DiscountTypes.None ||
      (!row.discountFlatAmount && !row.discountPercent),
    [row]
  );

  const dispatch = useDispatch();

  const handleOpenPreview = useCallback(
    (e) => {
      const imageUrl = _.get(saleItem, 'defaultImage', '') as string;
      dispatch(showThumbnail(imageUrl));

      e.stopPropagation();
    },
    [dispatch, saleItem]
  );
  return (
    <>
      <TableCell data-qa={`Name - ${saleItem.name}`}>
        <ImageItemBox
          name={_.get(saleItem, 'name', null)}
          description={_.get(saleItem, 'description', null)}
          source={_.get(saleItem, 'defaultImage', null)}
          onClick={handleOpenPreview}
        />
      </TableCell>
      <TableCell data-qa={`Quantity - ${saleItem.name}`}>
        <Typography>
          {row.quantity} {defaultUom && defaultUom.abbreviation}
        </Typography>
      </TableCell>
      <TableCell data-qa={`Discount - ${saleItem.name}`}>
        <Typography>{itemHasNoDiscount ? 'None' : discount}</Typography>
      </TableCell>
      <TableCell data-qa={`Price - ${saleItem.name}`}>
        <Typography>{currencyFormatter(row.price || 0)}</Typography>
      </TableCell>
    </>
  );
};

export default memo(BundleItemRow);
