import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import _ from 'lodash';

import { ImageItemBox } from 'ui/components/Image/ImageItemBox';
import { useCurrencyFormatter } from 'helpers';

import { PurchaseItemOptionProps } from './types';
import { DOT_CHAR } from '../../helpers';

const PurchaseItemOption: React.FC<PurchaseItemOptionProps> = (
  props: PurchaseItemOptionProps
) => {
  const { option, vendorId } = props;

  const currencyFormatter = useCurrencyFormatter();
  const nameResolved = useMemo(() => {
    const vendorItem = option.vendorItems.find((i) => i.vendorId === vendorId);
    const vendorItemName = vendorItem ? `${DOT_CHAR} ${vendorItem.name}` : '';
    return `${_.get(option, 'name', '')} ${vendorItemName}`;
  }, [option, vendorId]);

  if (!option.id || option.id < 0) {
    return <Typography variant="body1">+ Add "{option.name}"</Typography>;
  }

  return (
    <Box display="flex" width="100%" flexDirection="column">
      <Box display="flex" width="100%">
        <Box flexGrow={1} minWidth={0}>
          <ImageItemBox
            name={`${nameResolved}${
              option.sku ? ` ${DOT_CHAR} SKU: ${option.sku}` : ''
            }`}
            description={_.get(option, 'description', null)}
            source={_.get(option, 'defaultImage', null)}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          width={120}
          textAlign="right"
        >
          <Typography>{currencyFormatter(option.cost || 0)}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(PurchaseItemOption);
