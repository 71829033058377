import React, { useState, useCallback, memo } from 'react';
import { Box } from '@mui/material';

import { Modal } from 'ui/components/Modal/Modal';

import { CommerceSettingsModalProps } from './types';
import { SettingsCard } from './components';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { useModalActions } from './ModalActionsContext';

const CommerceSettingsModal: React.FC<CommerceSettingsModalProps> = (props) => {
  const { visible, onClose } = props;

  const { triggerSave, triggerCancel } = useModalActions();
  const [isLoading] = useState(false);

  const handleCloseSettingsModal = useCallback(() => {
    onClose();
    triggerCancel();
  }, [triggerCancel, onClose]);

  const handleSaveSettingsModal = useCallback(() => {
    triggerSave();
  }, [triggerSave]);

  const FBOModalActions = useCallback(() => {
    return (
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box display="flex" justifyContent="flex-end" width="100%">
          <FBOButton
            variant="secondary"
            color="positive"
            size="medium"
            onClick={handleCloseSettingsModal}
            data-qa="cancel-add-payment-sales-order"
            sx={{ marginRight: '8px' }}
          >
            Cancel
          </FBOButton>
          <FBOButton
            variant="primary"
            color="positive"
            size="medium"
            onClick={handleSaveSettingsModal}
            data-qa="addPaymentbtn-soPaymentsModal"
          >
            Save
          </FBOButton>
        </Box>
      </Box>
    );
  }, []);

  return (
    <Modal
      id="commerce-settings-modal"
      open={visible}
      isLoadingContent={isLoading}
      withoutDefaultPadding
      title="Commerce Settings"
      customHeight={400}
      maxWidth="md"
      footerDivider="border"
      dataQa="commerce-settings-modal"
      onCancelClicked={handleCloseSettingsModal}
      ModalActionsComponent={FBOModalActions}
    >
      <SettingsCard id="global-settings-card" />
    </Modal>
  );
};

export default memo(CommerceSettingsModal);
