import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { createFilterOptions } from '@mui/material';
import * as yup from 'yup';

import { Errors, validateYup } from 'services/forms/validation';
import { Address, initialAddress } from 'services/addresses';
import { getSettingsCompany } from 'services/settings/company/redux';
import { activeUserHasPermission } from 'services/user/redux';

import { Autocomplete } from '../Autocomplete';
import { AddressAutocompleteProps } from './types';
import AddNewAndEditModal from './AddNewAndEditModal';

const filter = createFilterOptions<Address>();

const yupSchema = yup.object().shape({
  city: yup.string().required(),
  country: yup.string().required(),
  name: yup.string().required(),
  postalCode: yup.string().required(),
  state: yup.string().required(),
  street: yup.string().required(),
});

// we set this to big number because when new addresses are added in customers/vendors they have id -1, -2...
const newAddressId = -9999;

const AddressAutocomplete: React.FC<AddressAutocompleteProps> = (props) => {
  const {
    onChange,
    addresses,
    modalApplyClicked,
    disableAdd,
    permissions = [],
    disabled,
    ...otherProps
  } = props;

  const [showModal, setShowModal] = useState(false);
  const [formAddress, setFormAddress] = useState<Address>(initialAddress);
  const [errors, setErrors] = useState<Errors>({});

  const companySettings = useSelector(getSettingsCompany);

  const canEdit = useSelector(activeUserHasPermission(permissions));

  const isDisabled = disabled || !canEdit;

  const handleCloseModal = () => {
    setShowModal(false);
    setFormAddress(initialAddress);
  };

  const handleApplyModal = async () => {
    if (!validateYup(formAddress, yupSchema, setErrors)) {
      return;
    }
    try {
      await modalApplyClicked(formAddress);
      setShowModal(false);
    } catch {
      // Ignore error
    }
  };

  const handleAutocompleteChange = (
    e: React.ChangeEvent<{}>,
    value: Address | null
  ) => {
    if (value && value.id === newAddressId) {
      setFormAddress({ ...value, country: companySettings.country });
      setShowModal(true);
      return;
    }
    onChange(e, value);
  };

  return (
    <>
      <Autocomplete
        options={addresses}
        disabled={isDisabled}
        getOptionLabel={(address: Address) => {
          if (!address.id || address.id === newAddressId) {
            return `+ Add "${address.name}"`;
          }

          return address.name || '';
        }}
        filterOptions={(options: Address[], params: any) => {
          const filtered = filter(options, params);

          // Disable add label
          if (disableAdd) {
            return filtered;
          }

          if (!!params.inputValue) {
            filtered.unshift({
              ...initialAddress,
              id: newAddressId,
              name: params.inputValue || null,
            });
          }

          return filtered;
        }}
        onChange={handleAutocompleteChange}
        autoSelect={false}
        {...otherProps}
      />
      <AddNewAndEditModal
        modalVisible={showModal}
        onClose={handleCloseModal}
        onSaveChanges={handleApplyModal}
        activeAddress={formAddress}
        setActiveAddress={setFormAddress}
        validationErrors={errors}
        contactErrors={{}}
        disableContants
      />
    </>
  );
};

export default memo(AddressAutocomplete);
