import { camelCaseKeys } from 'helpers';

import { Xero } from './types';
import { InternationalTaxRate } from 'services/integrations/xero';

export const transformXero = (res: any): Xero => {
  return {
    accountType: res.accountType,
    accountingId: res.accountingId,
    accountingName: res.accountingName,
  };
};

export const transformInternationalTaxRates = (
  taxRate: InternationalTaxRate
): InternationalTaxRate => camelCaseKeys(taxRate);
