import { ModuleNavigationItems, ModuleNavigationType } from './types';

export enum ModuleNavigationActions {
  UPDATE_MODULE_NAVIGATION = 'module_navigation/update',
  REMOVE_MODULE_NAVIGATION = 'module_navigation/remove',
  CLEAR_MODULE_NAVIGATION = 'module_navigation/clear',
}

// ACTION CREATORS
export const updateModuleNavigation = (
  type: ModuleNavigationType | null,
  navigationItems: ModuleNavigationItems
) => {
  return {
    type: ModuleNavigationActions.UPDATE_MODULE_NAVIGATION,
    payload: {
      type,
      navigationItems,
    },
  };
};

export const removeModuleNavigation = () => {
  return {
    type: ModuleNavigationActions.REMOVE_MODULE_NAVIGATION,
    payload: {
      type: null,
      navigationItems: {},
    },
  };
};

export const clearModuleNavigation = (type: ModuleNavigationType) => {
  return {
    type: ModuleNavigationActions.CLEAR_MODULE_NAVIGATION,
    payload: {
      type,
      navigationItems: {},
    },
  };
};
