import React, { memo } from 'react';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import _ from 'lodash';

import { Modal } from 'ui/components/Modal/Modal';
import { RefundModalProps } from './types';
import { useCurrencyFormatter } from 'helpers';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';

const RefundModal: React.FC<RefundModalProps> = (props) => {
  const {
    showRefundModal,
    onCancelClicked,
    onApplyClicked,
    payment,
    refundAmount,
    setRefundAmount,
    error,
  } = props;

  const paymentTypeName = _.get(payment.paymentType, 'name', null);

  const currencyFormatter = useCurrencyFormatter();

  const handleRefundAmountChanged = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value ? parseFloat(e.target.value) : null;
    setRefundAmount(value);
  };

  return (
    <Modal
      open={showRefundModal}
      title="Refund Payment"
      applyLabel="Submit Refund"
      maxWidth="sm"
      onApplyClicked={onApplyClicked}
      onCancelClicked={onCancelClicked}
    >
      <Grid container spacing={2} xs={6} px={4} pb={4}>
        <Grid xs={6}>
          <Typography variant="caption">Amount: </Typography>
        </Grid>
        <Grid xs={6} justifyContent="flex-start">
          <Typography>{currencyFormatter(payment.amount || 0)}</Typography>
        </Grid>
        <Grid xs={6}>
          <Typography variant="caption">Payment Type: </Typography>
        </Grid>
        <Grid xs={6} justifyContent="flex-start">
          <Typography>{paymentTypeName}</Typography>
        </Grid>
        <Grid xs={6}>
          <Typography variant="caption">Transaction Type: </Typography>
        </Grid>
        <Grid xs={6} justifyContent="flex-start">
          <Typography>{payment.transactionType}</Typography>
        </Grid>
      </Grid>
      <Grid
        container
        px={4}
        pb={3}
        flex="1"
        display="flex"
        alignItems="flex-end"
      >
        <CurrencyField
          label="Refund Amount"
          required
          name="paymentAmount"
          fullWidth
          value={refundAmount}
          onChange={handleRefundAmountChanged}
          error={!!error}
          helperText={error}
          decimalPlaces={2}
        />
      </Grid>
    </Modal>
  );
};

export default memo(RefundModal);
