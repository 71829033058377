import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';
import { PurchaseOrderItemTypes } from 'services/purchaseOrders';

import { PurchaseRow, NoteRow, MiscRow } from './components';
import { PopoverOrigin } from '@mui/material';

export const DOT_CHAR = '\u2022';

export const ITEM_COLUMNS = (
  showMulticurrency: boolean,
  multiCurrencyCode: string,
  homeCurrencyCode: string
): Column[] => {
  let columns: Column[] = [
    {
      title: 'Item',
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: 'Cost',
      width: 150,
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: 'Quantity',
      width: 150,
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: 'Tax Rates',
      width: 150,
      align: 'center',
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: 'Status',
      width: 180,
      align: 'center',
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: 'Subtotal',
      width: 100,
      align: 'right',
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: '',
      width: 50,
      type: ColumnTypes.renderByCustomRow,
    },
  ];

  if (showMulticurrency) {
    columns = [
      {
        title: 'Item',
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Cost ${homeCurrencyCode}`,
        width: 150,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Cost ${multiCurrencyCode || ''}`,
        width: 150,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: 'Quantity',
        width: 150,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: 'Tax Rates',
        width: 150,
        align: 'center',
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: 'Status',
        width: 180,
        align: 'center',
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Subtotal ${homeCurrencyCode}`,
        width: 100,
        align: 'right',
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Subtotal ${multiCurrencyCode || ''}`,
        width: 120,
        align: 'right',
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: '',
        width: 50,
        type: ColumnTypes.renderByCustomRow,
      },
    ];
  }

  return columns;
};

export const purchaseItemRenderMap = {
  [PurchaseOrderItemTypes.Purchase]: PurchaseRow,
  [PurchaseOrderItemTypes.Note]: NoteRow,
  [PurchaseOrderItemTypes.CreditReturn]: PurchaseRow,
  [PurchaseOrderItemTypes.MiscPurchase]: MiscRow,
  [PurchaseOrderItemTypes.MiscCredit]: MiscRow,
  [PurchaseOrderItemTypes.OutSourced]: PurchaseRow,
  [PurchaseOrderItemTypes.DropShip]: PurchaseRow,
};

export const totalAnchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

export const totalTransformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'left',
};
