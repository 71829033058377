import React, { useMemo } from 'react';

import { Pagination } from 'services/search';
import { PAYMENT_TERM_COLUMNS } from 'services/paymentTerms';
import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { PermissionType } from 'services/permissions';
import { arrayHasAllDeletedObjects } from 'helpers';
import { PaymentSearchResultsProps } from './types';
import { default as PaymentTermRow } from './PaymentTermRow';
import { createActionBarOptions } from '../../consts';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';
import { useSelector } from 'react-redux';
import { getActiveUser } from 'services/userV2';
import { useFlags } from 'helpers/useFlags';

const PaymentTermsSearchResults: React.FC<PaymentSearchResultsProps> = (
  props
) => {
  const {
    paymentTerms,
    activePaymentTermId,
    handlePaymentTermClick,
    onAddNewPress,
    activeDate,
    onPageAction,
    onPaginationChange,
    isLoadingPaymentTerms,
    pagination,
  } = props;

  const activeUser = useSelector(getActiveUser);
  const flags = useFlags();
  const csvDisabled = flags.newUserEndpoint && !activeUser.user?.admin;

  const allDeletedPaymentTerms = useMemo(
    () => arrayHasAllDeletedObjects(paymentTerms),
    [paymentTerms]
  );

  const alwaysOnColumns = useMemo(
    () => PAYMENT_TERM_COLUMNS(activeDate).filter((c) => c.title === 'Name'),
    [activeDate]
  );

  const handlePaginationChange = (newPagination: Pagination) => {
    onPaginationChange(newPagination);
  };

  return (
    <>
      <FBOTitleBar title="Payment Terms">
        {activePaymentTermId !== -1 && (
          <FBOButton
            style={{ marginRight: '8px' }}
            variant={!activePaymentTermId ? 'primary' : 'secondary'}
            color="positive"
            size="medium"
            icon="FBOAddCircle"
            onClick={onAddNewPress}
            data-qa="payment-term-new"
            permissions={[PermissionType.PaymentTermsCreate]}
          >
            {!activePaymentTermId ? 'Add New' : ''}
          </FBOButton>
        )}
        <FBOMenuButton
          variant="secondary"
          items={createActionBarOptions(
            onPageAction,
            paymentTerms.length,
            csvDisabled
          )}
          data-qa="payment-terms-three-dot-menu"
        />
      </FBOTitleBar>

      <ItemsTable
        data={paymentTerms}
        columns={
          activePaymentTermId
            ? alwaysOnColumns
            : PAYMENT_TERM_COLUMNS(activeDate)
        }
        activeId={activePaymentTermId}
        RenderCustomRow={PaymentTermRow}
        onItemClick={handlePaymentTermClick}
        selectableItems={false}
        isLoadingData={isLoadingPaymentTerms}
        disableSelection={allDeletedPaymentTerms}
        pagination={pagination}
        onPaginationChange={handlePaginationChange}
        dataQa="payment-term-table"
        showEnhancedTablePaginationActions={!Boolean(activePaymentTermId)}
      />
    </>
  );
};

export default PaymentTermsSearchResults;
