import { makeStyles } from '@mui/styles';
import { colorPalette } from 'ui/theme';

export const useAssignLocationsStyle = makeStyles(() => ({
  title: {
    color: colorPalette.redesign.contentSecondary,
    fontWeight: 'bold',
  },
  container: {
    width: '100%',
    borderRadius: 3,
    border: `1px solid ${colorPalette.redesign.background4}`,
    paddingLeft: 2,
  },
  locationContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '10px',
    height: '200px',
    overflow: 'auto',
    padding: '10px',
  },
  locationRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    maxHeight: '32px',
    minHeight: '32px',
    alignContent: 'center',
  },
  locationName: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
