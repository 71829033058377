import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TableCell } from '@mui/material';
import moment from 'moment';
import _ from 'lodash';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { StatusLabel } from 'ui/components/Status/StatusLabel';
import { DateMessage } from 'ui/components/DateTooltip/types';
import { DateTooltip } from 'ui/components/DateTooltip';
import { Ship } from 'services/shipping';
import { getOrderPriorities } from 'services/settings/orderPriorities';
import { useGetIntlDateFormatString } from 'helpers';
import FBOStatusDot, {
  DefaultStatus,
} from 'ui/theme/components/FBOStatusDot/FBOStatusDot';

const ShipRow: React.FC<RenderCustomRowProps<Ship>> = (props) => {
  const { row, columns } = props;

  const priorities = useSelector(getOrderPriorities).items;
  const intlFormatDate = useGetIntlDateFormatString();

  const orderPriority = useMemo(() => {
    return priorities.find((p) => p.id === row.orderPriorityId);
  }, [priorities, row]);

  const itemActive = useMemo(() => columns.length === 3, [columns]);

  const tooltipDates: DateMessage[] = useMemo(
    () => [
      { date: row.dateCreated, message: 'Date Created' },
      { date: row.dateLastModified, message: 'Date Last Modified' },
      { date: row.dateShip, message: 'Date Shipped' },
    ],
    [row]
  );

  const dateValue: string = useMemo(() => {
    const dateField = !itemActive ? columns[8].field! : null;
    const date = dateField ? _.get(row, dateField, null) : null;

    return date ? moment(date).format(intlFormatDate) : '';
  }, [columns, row, itemActive, intlFormatDate]);

  return (
    <>
      <TableCell data-qa={`Ship-${row.number}`}>{row.number}</TableCell>
      {itemActive ? (
        <FBOStatusDot status={row.status || DefaultStatus.Transparent} />
      ) : (
        <TableCell data-qa={`Status-${row.number}`}>
          <StatusLabel status={row.status} />
        </TableCell>
      )}
      <TableCell data-qa={`Priority-${row.number}`}>
        {_.get(orderPriority, 'name')}
      </TableCell>
      {!itemActive && (
        <>
          <TableCell data-qa={`Order-Number-${row.number}`}>
            {_.get(row.salesOrder, 'number')}
          </TableCell>
          <TableCell data-qa={`Package-${row.number}`}>
            {row.shipCartonList.length}
          </TableCell>
          <TableCell data-qa={`Item-${row.number}`}>
            {row.shipItemList.length}
          </TableCell>
          <TableCell data-qa={`Ship-To-${row.number}`}>
            {row.shipToName || ''}
          </TableCell>
          <TableCell data-qa={`Carrier-${row.number}`}>
            {row.carrier && row.carrier.name}
          </TableCell>
          <DateTooltip values={tooltipDates}>
            <TableCell data-qa={`Shipped-Date-${row.number}`}>
              {dateValue}
            </TableCell>
          </DateTooltip>
        </>
      )}
    </>
  );
};

export default memo(ShipRow);
