export interface PaymentTerm {
  id: number | null;
  name: string | null;
  version: number | null;
  dateLastModified: Date | null;
  dateCreated: Date | null;
  dueDays: number | null;
  graceDays: number | null;
  paymentTermType: PaymentTermType | null;
  discountPercent: number | null;
  discountDays: number | null;
  dayOfMonthDue: number | null;
  deleted: boolean;
}

export enum PaymentTermType {
  Standard = 'Standard',
  DateDriven = 'Date Driven',
}
