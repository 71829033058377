export interface GlobalSettings {
  productExport: {
    masterSku: DefaultOptions;
    productTitle: DefaultOptions;
    upc: DefaultOptions;
    shortDescription: NotesOptions;
    longDescription: NotesOptions;
    limitTags: number[] | null;
  };
  inventoryExport: {
    quantityType: QtyOptions;
    inventoryWarehousesEnabled: number[] | null;
  };
}

export enum DefaultOptions {
  Name = 'Name',
  Description = 'Description',
  SKU = 'SKU',
  UPC = 'UPC',
}

export enum NotesOptions {
  Name = 'Name',
  Description = 'Description',
  SKU = 'SKU',
  UPC = 'UPC',
  Notes = 'Notes',
}

export enum QtyOptions {
  Available = 'Available',
  OnHand = 'On Hand',
}

export const GlobalSettingsFields = {
  productExport: {
    masterSku: 'masterSku',
    productTitle: 'productTitle',
    upc: 'upc',
    shortDescription: 'shortDescription',
    longDescription: 'longDescription',
    limitTags: 'limitTags',
  },
  inventoryExport: {
    quantityType: 'quantityType',
    inventoryWarehousesEnabled: 'inventoryWarehousesEnabled',
  },
};
