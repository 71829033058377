import {
  SalesOrder,
  SalesOrderItem,
  SalesOrderItemTypes,
} from 'services/salesOrders';
import {
  SalesOrderStatus,
  SalesOrderItemStatus,
} from 'services/salesOrders/types';
import { ItemType } from 'services/items';
import lodash from 'lodash';

type DomElemName = string;

export const salesOrderStatusRegister =
  (status: SalesOrderStatus) =>
  // isDisabled function
  (): boolean => {
    switch (status) {
      case SalesOrderStatus.Fulfilled:
        return false;
      case SalesOrderStatus.Cancelled:
      case SalesOrderStatus.ClosedShort:
      case SalesOrderStatus.Estimate:
      case SalesOrderStatus.Expired:
      case SalesOrderStatus.PartiallyFulfilled:
      case SalesOrderStatus.PartiallyPicked:
      case SalesOrderStatus.Issued:
      case SalesOrderStatus.Picked:
      case SalesOrderStatus.Picking:
      case SalesOrderStatus.Voided:
        return true;
      default:
        return false;
    }
  };

export const isSalesOrderIssued = (salesOrder: SalesOrder) => (): boolean => {
  return salesOrder.status === SalesOrderStatus.Issued;
};

export const isSalesOrderEstimate = (salesOrder: SalesOrder) => (): boolean => {
  return salesOrder.status === SalesOrderStatus.Estimate;
};

export const salesOrderItemStatusRegister =
  (salesOrderItem: SalesOrderItem) =>
  // isDisabled function
  (domElemName: DomElemName): boolean => {
    // One-Offs
    switch (domElemName) {
      case 'notes':
        return false;
      case 'quantity':
      case 'price':
        if (
          salesOrderItem.salesOrderItemType === SalesOrderItemTypes.DropShip &&
          !(
            salesOrderItem.status === SalesOrderItemStatus.PartiallyFulfilled ||
            salesOrderItem.status === SalesOrderItemStatus.Fulfilled
          )
        ) {
          return false;
        }
        break;
      case 'cost': {
        switch (salesOrderItem.salesOrderItemType) {
          case SalesOrderItemTypes.MiscSale:
          case SalesOrderItemTypes.MiscReturn:
            return false;
        }

        const saleItemType = lodash.get(
          salesOrderItem,
          'saleItem.item.itemType'
        );
        if (saleItemType) {
          switch (saleItemType) {
            case ItemType.Service:
            case ItemType.Shipping:
            case ItemType.Labor:
            case ItemType.Overhead:
              return false;
          }
        }
        break;
      }
      default: {
        if (salesOrderItem.salesOrderItemType === SalesOrderItemTypes.DropShip)
          return domElemName !== 'notes';
      }
    }

    switch (salesOrderItem.status) {
      // Group 0
      case SalesOrderItemStatus.Entered:
        return ![
          'description',
          'deleted',
          'discountFlatAmount',
          'discountPercent',
          'exchangeCurrencyId',
          'exchangeRate',
          'lineNumber',
          'notes',
          'price',
          'quantity',
          'salesOrderItemType',
          'taxId',
          'taxRate',
          'taxable',
        ].includes(domElemName);
      // Group 1
      case SalesOrderItemStatus.Picking:
      case SalesOrderItemStatus.Picked:
      case SalesOrderItemStatus.PartiallyPicked:
        return ![
          'description',
          'discountFlatAmount',
          'discountPercent',
          'exchangeCurrencyId',
          'exchangeRate',
          'lineNumber',
          'notes',
          'price',
          'TaxId',
          'TaxRate',
          'taxable',
        ].includes(domElemName);
      // Group 2
      case SalesOrderItemStatus.Fulfilled:
      case SalesOrderItemStatus.Voided:
      case SalesOrderItemStatus.ClosedShort:
        return !['notes'].includes(domElemName);
      // Group 3
      case SalesOrderItemStatus.PartiallyFulfilled:
        return !['notes'].includes(domElemName);
      default:
        return true;
    }
  };

export const isSalesOrderItemFulfilled =
  (salesOrderItem: SalesOrderItem) => (): boolean => {
    switch (salesOrderItem.status) {
      case SalesOrderItemStatus.Fulfilled:
      case SalesOrderItemStatus.Voided:
        return true;
      default:
        return false;
    }
  };

export const areFieldsDisabled =
  (salesOrderItem: SalesOrderItem, disabled: boolean = false) =>
  (): boolean => {
    if (disabled) return disabled;

    // One-Offs
    switch (salesOrderItem.status) {
      case SalesOrderItemStatus.Picked:
      case SalesOrderItemStatus.PartiallyPicked:
      case SalesOrderItemStatus.Fulfilled:
      case SalesOrderItemStatus.PartiallyFulfilled:
      case SalesOrderItemStatus.ClosedShort:
        return true;
      default:
        return false;
    }
  };
