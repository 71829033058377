import { Errors } from 'services/forms/validation';
import { PricingRule } from 'services/pricingRules';

export interface PricingRulesProductsProps {
  activePricingRule: PricingRule;
  setActivePricingRule: React.Dispatch<React.SetStateAction<PricingRule>>;
  checkedProductsGroup: string;
  setCheckedProductsGroup: React.Dispatch<
    React.SetStateAction<PricingRulesProductsGroup>
  >;
  validationErrors: Errors;
  setValidationErrors: React.Dispatch<React.SetStateAction<Errors>>;
}

export enum PricingRulesProductsGroup {
  All = 'All',
  Product = 'Product',
  Tag = 'Tag',
}
