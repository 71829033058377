import { LabelType, ProducedFromType } from './types';

export const producedFromTypes: ProducedFromType[] = [
  { type: 'name', title: 'Name' },
  { type: 'barcodeupc', title: 'Barcode/UPC' },
  { type: 'sku', title: 'SKU' },
];

export const labelTypes: LabelType[] = [
  { type: '2x1', title: '2" x 1" labels', width: 2, height: 1 },
  { type: '2x1.25', title: '2" x 1.25" labels', width: 2, height: 1.25 },
  { type: '2x1.1875', title: '2" x 1.1875" labels', width: 2, height: 1.1875 },
  { type: '2x3', title: '2" x 3" labels', width: 2, height: 3 },
  { type: '3x1.75', title: '3" x 1.75" labels', width: 3, height: 1.75 },
  { type: '4x2', title: '4" x 2" labels', width: 4, height: 2 },
  { type: '4x3', title: '4" x 3" labels', width: 4, height: 3 },
  { type: '4x6', title: '4" x 6" labels', width: 4, height: 6 },
];
export enum ItemType {
  Item = 'Item',
  SaleItem = 'Sale Item',
}
