import { State } from 'app/redux';

export const getUoms = (state: State) => state.uom.uoms;

export const getSearches = (state: State) => state.uom.searches;

export const getUomById = (id: number | null) => (state: State) => {
  if (id === null) {
    return undefined;
  }
  return getUoms(state).items.find((item) => item.id === id);
};
