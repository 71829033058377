import React from 'react';

import {
  ItemsTable,
  useSelectedItemsChanges,
} from 'ui/components/Table/ItemsTable';
import { colorPalette } from 'ui/theme';
import { PermissionType } from 'services/permissions';

import { ShippingSearchResultsProps } from './types';
import {
  shippingPageActionOptions,
  SHIPPING_COLUMNS,
  SHIPPING_COLUMNS_SMALL,
} from './consts';
import ShipRow from './ShipRow';

import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';

const ShippingSearchResults: React.FC<ShippingSearchResultsProps> = (props) => {
  const {
    activeShipId,
    handleShipClick,
    ships,
    isLoadingShips,
    pagination,
    onPaginationChange,
    selectedItems,
    setSelectedItems,
    onPageAction,
    onVoidClicked,
    activeDate,
  } = props;

  const alwaysOnColumns = SHIPPING_COLUMNS_SMALL;

  const handleSelectClick = useSelectedItemsChanges(
    selectedItems,
    setSelectedItems
  );

  return (
    <>
      <FBOTitleBar title="Shipping">
        {selectedItems.length > 0 && !activeShipId && (
          <FBOButton
            style={{ marginRight: '8px' }}
            variant="secondary"
            color="negative"
            size="medium"
            permissions={[PermissionType.ShippingEdit]}
            onClick={onVoidClicked}
            data-qa="void-button"
          >
            Void
          </FBOButton>
        )}
        <FBOMenuButton
          variant="secondary"
          items={shippingPageActionOptions(onPageAction)}
          data-qa="shipping-three-dot-menu-button"
          style={{ color: colorPalette.redesign.contentSecondary }}
        />
      </FBOTitleBar>

      <ItemsTable
        data={ships}
        columns={activeShipId ? alwaysOnColumns : SHIPPING_COLUMNS(activeDate)}
        activeId={activeShipId}
        onItemClick={handleShipClick}
        onSelectedChange={handleSelectClick}
        selectedItems={selectedItems}
        selectableItems={!activeShipId}
        isLoadingData={isLoadingShips}
        emptyTableText="no ships available"
        RenderCustomRow={ShipRow}
        pagination={pagination}
        onPaginationChange={onPaginationChange}
        dataQa="shipping-table"
        showEnhancedTablePaginationActions={!Boolean(activeShipId)}
      />
    </>
  );
};

export default React.memo(ShippingSearchResults);
