import { makeStyles } from '@mui/styles';
import { colorPalette } from 'ui/theme';

export const useSnackbarStyles = makeStyles(() => ({
  main: {
    display: 'flex',
    width: '370px',
    minHeight: '72px',
    borderRadius: '5px',
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    background: colorPalette.redesign.background1,
    '&.success': {
      background: colorPalette.redesign.greenBackgroundLighter,
    },
    '&.error': { background: colorPalette.redesign.redBackgroundLighter },
    '&.warning': {
      background: colorPalette.redesign.yellowBackgroundLighter,
    },
  },
  icon: {
    backgroundColor: colorPalette.redesign.greyBackground,
    display: 'flex',
    alignItems: 'center',
    padding: '0px 16px',
    borderRadius: '5px 0 0 5px',
    '.success &': {
      backgroundColor: colorPalette.redesign.greenBackground,
    },
    '.error &': { backgroundColor: colorPalette.redesign.redBackground },
    '.warning &': {
      backgroundColor: colorPalette.redesign.yellowBackground,
    },
    '& > svg': {
      '.success &': {
        fill: colorPalette.redesign.contentGreen,
      },
      '.error &': { fill: colorPalette.redesign.contentRed },
      '.warning &': {
        fill: colorPalette.redesign.contentYellow,
      },
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    justifyContent: 'center',
  },
  heading: {
    margin: '0px',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: 'normal',
    color: colorPalette.redesign.contentGrey,
    '.success &': {
      color: colorPalette.redesign.contentGreen,
    },
    '.error &': { color: colorPalette.redesign.contentRed },
    '.warning &': {
      color: colorPalette.redesign.contentYellow,
    },
  },
  content: {
    fontSize: '14px',
    fontWeight: '400',
    color: colorPalette.redesign.contentPrimary,
    lineHeight: 'normal',
  },
  closeButton: {
    marginLeft: 'auto',
    background: 'none',
    border: 'none',
    '&:hover': { background: 'none' },
  },
}));
