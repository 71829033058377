import { createApiAction } from 'services/api';
import {
  StartAction,
  SuccessAction,
  FailureAction,
} from 'services/api/redux/types';

export enum SettingsShippingActionTypes {
  FETCH_SHIPPING_START = 'settings/fetch_shipping_start',
  FETCH_SHIPPING_SUCCESS = 'settings/fetch_shipping_success',
  FETCH_SHIPPING_FAILURE = 'settings/fetch_shipping_failure',
}

// SHIPPING ACTIONS
const fetchSettingsShippingActionStart = (): StartAction => ({
  type: SettingsShippingActionTypes.FETCH_SHIPPING_START,
  payload: null,
});

const fetchSettingsShippingActionSuccess = (payload: any): SuccessAction => {
  return {
    type: SettingsShippingActionTypes.FETCH_SHIPPING_SUCCESS,
    payload,
  };
};

const fetchSettingsShippingActionFailure = (error: string): FailureAction => ({
  type: SettingsShippingActionTypes.FETCH_SHIPPING_FAILURE,
  payload: { error },
});

export const fetchSettingsShipping = () =>
  createApiAction(
    {
      path: '/v1/shipping_settings',
      method: 'GET',
    },
    fetchSettingsShippingActionStart,
    fetchSettingsShippingActionSuccess,
    fetchSettingsShippingActionFailure
  );
