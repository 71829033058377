import { PaginationDirection } from 'services/url';

export const initialPagination = {
  page: 1,
  pageSize: 25,
  sort: {
    sortBy: 'number',
    direction: PaginationDirection.Descending,
  },
  totalRows: 25,
};

export const displayNameMap = {
  number: 'Number',
  description: 'Description',
};

export const fishbowlCommerceLogo =
  'https://www.fishbowlinventory.com/fishbowl-icon-and-commerce-logo.png';
