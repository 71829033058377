import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable/types';

export const resolvedCustomerItemColumns = (
  showMulticurrency: boolean,
  currencyCode: string,
  homeCurrencyCode: string
): Column[] => {
  let columns: Column[] = [
    {
      title: 'Sale Item',
      width: 300,
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: 'Customer Sale Item Name',
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: `Last Price ${homeCurrencyCode}`,
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: 'Last Date',
      type: ColumnTypes.renderByCustomRow,
    },
  ];

  if (showMulticurrency) {
    columns = [
      {
        title: 'Sale Item',
        width: 300,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: 'Customer Sale Item Name',
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Last Price ${homeCurrencyCode}`,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Last Price ${currencyCode}`,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: 'Last Date',
        type: ColumnTypes.renderByCustomRow,
      },
    ];
  }
  return columns;
};
