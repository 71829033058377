/* eslint-disable prettier/prettier */
import * as React from 'react';
import ErrorMessage from './ErrorMessage';

export const FallbackUI = (error: Error) => {
  return (
    <ErrorMessage
      error={error}
      title={error.name}
      description={error.message}
    />
  );
};
