import React, { memo } from 'react';

import { Box } from '@mui/material';

import { ItemsTable } from 'ui/components/Table/ItemsTable';

import { InvoiceDetailsItemFooter, InvoiceDetailsItemRow } from './components';

import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import { colorPalette } from 'ui/theme';
import { InvoiceDetails } from 'services/invoices';
import { useSelector } from 'react-redux';
import { getSettingsCompany } from 'services/settings/company';
import { resolvedInvoiceDetailsItemsColumns } from './consts';

interface InvoiceDetailsItemsProps {
  invoiceDetails: InvoiceDetails;
}

const InvoiceDetailsItems: React.FC<InvoiceDetailsItemsProps> = (props) => {
  const { invoiceDetails } = props;

  const { homeCurrency } = useSelector(getSettingsCompany);
  const homeCurrencyCode = homeCurrency?.code || 'USD';

  return (
    <Box
      display="flex"
      flexGrow={1}
      flexDirection="column"
      overflow={'hidden'}
      sx={{
        borderTop: `1px solid ${colorPalette.redesign.background3}`,
      }}
    >
      <Box paddingLeft={2} paddingRight={2}>
        <FBOTitleBar title="Items" />
      </Box>

      <ItemsTable
        data={invoiceDetails?.items || []}
        columns={resolvedInvoiceDetailsItemsColumns(homeCurrencyCode)}
        RenderCustomRow={InvoiceDetailsItemRow}
        selectableItems={false}
        RenderCustomFooter={InvoiceDetailsItemFooter}
        footerData={{ invoiceDetails }}
        emptyTableText="No Items"
        dataQa="invoice-details-items-table"
      />
    </Box>
  );
};

export default memo(InvoiceDetailsItems);
