import { Carrier, CarrierService } from './types';

export const defaultCarrier: Carrier = {
  id: null,
  carrierServiceList: [],
  dateCreated: null,
  dateLastModified: null,
  deleted: false,
  description: null,
  name: null,
  readOnly: false,
  scac: null,
  version: null,
};

export const defaultCarrierService: CarrierService = {
  id: null,
  carrierId: null,
  code: null,
  dateCreated: null,
  dateLastModified: null,
  deleted: false,
  name: null,
  readOnly: false,
  version: null,
};
