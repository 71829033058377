import React, { memo } from 'react';
import { Box } from '@mui/material';

import { Paper } from 'ui/components/Paper/Paper';
import { ItemsTable } from 'ui/components/Table/ItemsTable';

import { useCardStyle } from '../../styled';
import { fboCardHistoryColumns } from './consts';
import { CardHistoryProps } from './types';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import CustomRow from './CustomRow';

const CardHistory: React.FC<CardHistoryProps> = (props) => {
  const { history, handlePaginationChange } = props;
  const classes = useCardStyle();
  return (
    <Box className={classes.container} sx={{ margin: '16px auto' }}>
      <Paper id="historyId" className={classes.paper}>
        <FBOTitleBar title="History" />
        <ItemsTable
          columns={fboCardHistoryColumns}
          data={history.data}
          RenderCustomRow={CustomRow}
          selectableItems={false}
          emptyTableText="No Data"
          pagination={history.pagination}
          onPaginationChange={handlePaginationChange}
          showEnhancedTablePaginationActions
        />
      </Paper>
    </Box>
  );
};

export default memo(CardHistory);
