import React, { memo, useMemo } from 'react';
import { TableCell, Box, Typography, Tooltip } from '@mui/material';
import _ from 'lodash';

import { useThemeTableDensity } from 'app/components/AppThemeProvider';
import { ThemeTableDensity } from 'app/components/types';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { TextField } from 'ui/components/TextField/TextField';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import {
  COUNTRIES,
  CountryType,
} from 'ui/components/Autocomplete/CountryAutocomplete';
import { DeclarationItem } from 'services/integrations/shipping/purchaseLabel';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';

import { usItemsStepRowStyle } from './styled';

const ItemsStepRow: React.FC<RenderCustomRowProps<DeclarationItem>> = (
  props
) => {
  const { row: declarationItem, tableBordered, setData, index } = props;

  const rowClasses = usItemsStepRowStyle({ tableBordered });

  const { themeSettings } = useThemeTableDensity();
  const denseTable = themeSettings.tableDensity === ThemeTableDensity.Small;

  const quantityAndUom = declarationItem.quantity
    ? `${declarationItem.quantity} ${_.get(
        declarationItem,
        'uom.abbreviation',
        ''
      )}`
    : null;

  const selectedCountry = useMemo(
    () =>
      COUNTRIES.find((c) => c.code === declarationItem.countryOfOrigin) || null,
    [declarationItem.countryOfOrigin]
  );

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData(
      { ...declarationItem, [e.target.name]: e.target.value || null },
      index
    );
  };

  const handleCountryChange = (e: any, value: CountryType | null) => {
    setData(
      { ...declarationItem, countryOfOrigin: value ? value.code : null },
      index
    );
  };

  return (
    <>
      <TableCell className={`${rowClasses.cell} ${rowClasses.disabledCell}`}>
        <Tooltip
          title={declarationItem.name || false}
          classes={{ tooltip: rowClasses.tooltipLabel }}
        >
          <Box
            px={2}
            display="flex"
            justifyContent="space-between"
            width="100%"
          >
            <Typography noWrap>{declarationItem.name}</Typography>
            <Box pl={1}>
              <Typography noWrap>({quantityAndUom})</Typography>
            </Box>
          </Box>
        </Tooltip>
      </TableCell>
      <TableCell className={rowClasses.cell}>
        <Box className={rowClasses.cellContainer}>
          <Box className={rowClasses.textFieldContainer}>
            <TextField
              autoComplete="nope"
              onChange={handleTextFieldChange}
              name="description"
              value={declarationItem.description}
              additionalInputProps={{
                classes: {
                  notchedOutline: rowClasses.noBorder,
                  inputSizeSmall: denseTable
                    ? rowClasses.inputSmall
                    : rowClasses.input,
                },
              }}
            />
          </Box>
        </Box>
      </TableCell>
      <TableCell className={rowClasses.cell}>
        <Box className={rowClasses.cellContainer}>
          <Box className={rowClasses.textFieldContainer}>
            <Autocomplete
              options={COUNTRIES}
              onChange={handleCountryChange}
              name="countryOfOrigin"
              value={selectedCountry}
              getOptionLabel={(c: CountryType) => c.label}
              additionalInputProps={{
                classes: {
                  notchedOutline: rowClasses.noBorder,
                  inputSizeSmall: denseTable
                    ? rowClasses.inputSmall
                    : rowClasses.input,
                },
              }}
            />
          </Box>
        </Box>
      </TableCell>
      <TableCell className={rowClasses.cell}>
        <Box className={rowClasses.cellContainer}>
          <Box className={rowClasses.textFieldContainer}>
            <TextField
              autoComplete="nope"
              onChange={handleTextFieldChange}
              name="tariffNumber"
              value={declarationItem.tariffNumber}
              additionalInputProps={{
                classes: {
                  notchedOutline: rowClasses.noBorder,
                  inputSizeSmall: denseTable
                    ? rowClasses.inputSmall
                    : rowClasses.input,
                },
              }}
            />
          </Box>
        </Box>
      </TableCell>
      <TableCell className={rowClasses.cell}>
        <Box className={rowClasses.cellContainer}>
          <Box className={rowClasses.textFieldContainer}>
            <CurrencyField
              autoComplete="nope"
              onChange={handleTextFieldChange}
              name="declaredValue"
              value={declarationItem.declaredValue}
              InputProps={{
                classes: {
                  notchedOutline: rowClasses.noBorder,
                  inputSizeSmall: denseTable
                    ? rowClasses.inputSmall
                    : rowClasses.input,
                },
              }}
              allowNegative
            />
          </Box>
        </Box>
      </TableCell>
    </>
  );
};

export default memo(ItemsStepRow);
