import { IconNames } from 'ui/theme';
import { MenuItem, MenuItemVariant } from 'ui/components/Menu';
import { PermissionType } from 'services/permissions';
import { PurchaseOrderStatus } from 'services/purchaseOrders';

export const createActionBarOptions = (
  id: number | null,
  onDeleteClick: () => void,
  onDuplicateClick: () => void,
  onDuplicateAsCreditReturnClick: () => void,
  onShowReport: () => void,
  onSendEmail: () => void,
  status: PurchaseOrderStatus | null,
  hasDropShipItem: boolean
): MenuItem[] => [
  {
    label: 'Show Report',
    disabled: !id || id < 0,
    onOptionClick: onShowReport,
    icon: IconNames.FBOReportSmall,
    separateAfter: true,
    dataQa: 'show-report',
  },
  {
    label: 'Send Email',
    disabled: !id || id < 0,
    onOptionClick: onSendEmail,
    icon: IconNames.FBOEmailSmall,
    dataQa: 'send-email',
    separateAfter: true,
  },
  {
    label: 'Duplicate Order',
    onOptionClick: onDuplicateClick,
    icon: IconNames.FBODuplicateSmall,
    disabled: hasDropShipItem,
    permissions: [
      PermissionType.PurchaseOrderEdit,
      PermissionType.PurchaseOrderCreate,
    ],
    dataQa: 'duplicate-order',
    separateAfter: true,
  },
  {
    label: 'Duplicate Order As Credit Return',
    onOptionClick: onDuplicateAsCreditReturnClick,
    icon: IconNames.FBODuplicateSmall,
    disabled: hasDropShipItem,
    permissions: [
      PermissionType.PurchaseOrderEdit,
      PermissionType.PurchaseOrderCreate,
    ],
    dataQa: 'duplicate-order-as-credit-return',
    separateAfter: true,
  },
  {
    label: 'Delete Order',
    onOptionClick: onDeleteClick,
    permissions: [PermissionType.PurchaseOrderDelete],
    icon: IconNames.FBODeleteSmall,
    dataQa: 'delete-order',
    disabled: status !== PurchaseOrderStatus.BidRequest,
    variant: MenuItemVariant.Danger,
  },
];

export const noTaxAddedBodyContent = `No tax is added to the company or item(s) on this \n purchase order, even though it was marked as taxable`;
export const noTaxAddedModalTitle = `No Tax Added`;
