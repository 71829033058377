import React, { useCallback, useEffect, useMemo } from 'react';
import { Grid } from '@mui/material';

import { GridRow } from 'ui/components/Grid';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { ProductImportSettingsProps } from './types';
import { INPUT_WIDTH } from '../consts';
import { ClassAutocomplete } from 'ui/components/Autocomplete/ClassesAutocomplete';
import {
  DIMENSIONS_UNITS,
  DimensionUnit,
  WeightUnit,
  WEIGHT_UNITS,
} from 'services/items';
import { UomAutocomplete } from 'ui/components/Autocomplete/UomAutocomplete';
import { Class } from 'services/classes';
import { fetchUoms, getUoms, Uom } from 'services/uoms';
import { useDispatch, useSelector } from 'react-redux';

const ProductImportSettings: React.FC<ProductImportSettingsProps> = (props) => {
  const { channelSettings, setChannelSettings, errors } = props;
  const { items: uoms } = useSelector(getUoms);

  const dispatch = useDispatch();

  useEffect(() => {
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchUoms());
    // react-hooks/exhaustive-deps
  }, []);

  const selectedUom = useMemo(
    () =>
      uoms.find((u) => u.id === channelSettings.productImportSettings.uom) ||
      null,
    [uoms, channelSettings]
  );

  const handleAutocompleteChange = useCallback(
    (name: 'dimensionUom' | 'weightUom') =>
      (v: DimensionUnit | WeightUnit | null) => {
        setChannelSettings((old) => ({
          ...old,
          productImportSettings: {
            ...old.productImportSettings,
            [name]: v ? v.name : null,
          },
        }));
      },
    [setChannelSettings]
  );

  const handleClassChange = (accountingClass: Class | null) => {
    setChannelSettings((old) => ({
      ...old,
      productImportSettings: {
        ...old.productImportSettings,
        accountingClass: accountingClass ? accountingClass.id : null,
      },
    }));
  };

  const handleUOMChange = (uom: Uom | null) => {
    setChannelSettings((old) => ({
      ...old,
      productImportSettings: {
        ...old.productImportSettings,
        uom: uom ? uom.id : null,
      },
    }));
  };

  return (
    <GridRow title="Product Import Settings">
      <Grid width={INPUT_WIDTH} item>
        <ClassAutocomplete
          value={channelSettings.productImportSettings.accountingClass}
          onChange={handleClassChange}
          placeholder="Select a class"
          label="Fishbowl Drive Accounting Class"
          dataQa="product-import-class"
        />
      </Grid>
      <Grid width={INPUT_WIDTH} item>
        <UomAutocomplete
          label="Product Unit of Measure"
          placeholder="Select UOM"
          onChange={handleUOMChange}
          value={selectedUom}
          dataQa="product-import-uom"
          required
          error={!!errors['productImportSettings.uom']}
        />
      </Grid>
      <Grid width={INPUT_WIDTH} item>
        <Autocomplete
          placeholder="Dimensions UOM"
          label="Product Dimensions UOM"
          onChange={(e: React.ChangeEvent<{}>, v: DimensionUnit | null) =>
            handleAutocompleteChange('dimensionUom')(v)
          }
          options={DIMENSIONS_UNITS}
          getOptionLabel={(u) => (u.name ? u.name : u)}
          value={channelSettings.productImportSettings.dimensionUom}
          dataQa="product-import-dimension-uom"
          required
          error={!!errors['productImportSettings.dimensionUom']}
        />
      </Grid>
      <Grid width={INPUT_WIDTH} item>
        <Autocomplete
          options={WEIGHT_UNITS}
          label="Product Weight UOM"
          value={channelSettings.productImportSettings.weightUom}
          getOptionLabel={(u) => (u.name ? u.name : u)}
          onChange={(e: React.ChangeEvent<{}>, v: WeightUnit | null) =>
            handleAutocompleteChange('weightUom')(v)
          }
          placeholder="Weight UOM"
          dataQa="product-import-weight-uom"
          required
          error={!!errors['productImportSettings.weightUom']}
        />
      </Grid>
    </GridRow>
  );
};

export default ProductImportSettings;
