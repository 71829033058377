import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getImageUrl,
  hideThumbnail,
  isThumbnailVisible,
} from 'services/thumbnail/redux';

import { Modal } from '../Modal';
import { useThumbnailModalStyle } from './styled';

const ThumbnailModal = () => {
  const classes = useThumbnailModalStyle();

  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  const isOpen = useSelector(isThumbnailVisible);
  const imageUrl = useSelector(getImageUrl);

  const onClose = useCallback(
    (e) => {
      setIsLoading(true);
      dispatch(hideThumbnail());
      e.stopPropagation();
    },
    [dispatch]
  );

  const stopPropagation = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <Modal
      customHeight={700}
      open={isOpen}
      onCancelClicked={onClose}
      isLoadingContent={isLoading}
      title="Image"
      withoutFooter
      onClick={stopPropagation}
    >
      <img
        src={imageUrl}
        alt="thumbnail"
        className={classes.image}
        onLoad={() => setIsLoading(false)}
      />
    </Modal>
  );
};

export default ThumbnailModal;
