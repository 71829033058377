import React, { memo, useCallback, useMemo } from 'react';
import { Menu as MuiMenu } from '@mui/material';
import _ from 'lodash';

import MenuItem from './MenuItem';
import { MenuProps, MenuItem as MenuItemProps } from './types';
import { USCountryString } from 'helpers/consts';
import { useSelector } from 'react-redux';
import { getSettingsCompanyCountry } from 'services/settings/company';

const Menu: React.FC<MenuProps> = (props) => {
  const { items, onClose, onMenuItemClick, open, ...otherProps } = props;

  const thereAreSubItems = useMemo(
    () => !!items.find((i) => i.subItems),
    [items]
  );
  const companyCountry = useSelector(getSettingsCompanyCountry);
  const handleMenuItemClick = useCallback(
    (
      event: React.MouseEvent<HTMLLIElement, MouseEvent>,
      itemIndex: number,
      item: MenuItemProps
    ) => {
      event.stopPropagation();

      const disabledItem = _.get(item, 'disabled');

      // prevent click on element if elements disabled prop is set to true
      if (disabledItem) {
        return false;
      }

      if (onMenuItemClick) {
        onMenuItemClick(itemIndex, event);
      }
      const itemCallback = items[itemIndex].onOptionClick;
      if (itemCallback) {
        itemCallback(event);
      }
      if (onClose) {
        onClose(event, 'backdropClick');
      }
    },
    [items, onClose, onMenuItemClick]
  );

  const handleSubMenuItemClick = useCallback(
    (
      ev: React.MouseEvent<HTMLLIElement, MouseEvent>,
      itemIndex: number,
      subItemIndex: number
    ) => {
      const item = items[itemIndex];
      if (!item.subItems) {
        return;
      }

      const subItemCallback = item.subItems[subItemIndex].onOptionClick;
      if (subItemCallback) {
        subItemCallback(ev);
      }
    },
    [items]
  );

  const handleSubItemClosed = useCallback(
    (ev: React.MouseEvent<{}>) => {
      if (onClose) {
        onClose(ev, 'backdropClick');
      }
    },
    [onClose]
  );

  // Don't render MuiMenu if menu isn't open
  if (!open) {
    return null;
  }

  return (
    <MuiMenu
      onClose={onClose}
      open={open}
      sx={{
        '& .MuiMenu-paper': {
          marginTop: '8px',
        },
        '& .MuiMenu-list': {
          padding: 0,
        },
      }}
      {...otherProps}
    >
      {companyCountry === USCountryString
        ? items.map((item, index) => (
            <MenuItem
              key={`item-${item.label}`}
              item={item}
              index={index}
              onMenuItemClicked={(e) => handleMenuItemClick(e, index, item)}
              onSubMenuItemClicked={handleSubMenuItemClick}
              onSubItemClosed={handleSubItemClosed}
              thereAreSubItems={thereAreSubItems}
              dataQa={item.dataQa}
            />
          ))
        : items
            .filter((item) => item.label !== 'Flat Rate Tax')
            .map((item, index) => (
              <MenuItem
                key={`item-${item.label}`}
                item={item}
                index={index}
                onMenuItemClicked={(e) => handleMenuItemClick(e, index, item)}
                onSubMenuItemClicked={handleSubMenuItemClick}
                onSubItemClosed={handleSubItemClosed}
                thereAreSubItems={thereAreSubItems}
                dataQa={item.dataQa}
              />
            ))}
    </MuiMenu>
  );
};

export default memo(Menu);
