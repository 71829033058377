import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const usePurchaseOrderItemsStyle = makeStyles((theme: Theme) => ({
  amountInputInner: {
    height: 32,
    background: 'white',
  },
  amountInputOuter: {
    paddingRight: 5,
  },
  noBorder: {
    border: 'none',
  },
  searchInputInner: {
    height: 32,
    background: 'white',
    paddingTop: `0!important`,
    paddingBottom: `0!important`,
  },
  searchInputOuter: {
    flex: 1,
    paddingRight: 5,
  },
  searchOption: {
    borderBottom: '1px solid #e0e0e0',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '&:last-of-type': {
      borderBottom: 'none',
    },
  },
  creditReturn: {
    backgroundColor: 'rgba(250,227,230,0.5)',
    '&:hover': {
      backgroundColor: 'rgba(250,227,230,0.55) !important',
    },
  },
}));
