import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const REPORTS_COLUMNS: Column[] = [
  {
    title: 'Name',
    width: '30%',
    type: ColumnTypes.string,
    field: 'title',
  },
  {
    title: 'Description',
    type: ColumnTypes.string,
    field: 'description',
  },
];
