import { SerialListStatus, SerialStatusLabel } from 'services/inventory';

export const calculateSerialLinkLabel = (
  serialDiff: number,
  rowContainsDuplicates?: boolean,
  committedQuantity?: number,
  committedGreaterThanQty?: boolean
): SerialStatusLabel => {
  if (rowContainsDuplicates) {
    return {
      status: SerialListStatus.Invalid,
      label: 'Duplicates',
    };
  } else if (committedGreaterThanQty) {
    return {
      status: SerialListStatus.Invalid,
      label: `${committedQuantity} Committed`,
    };
  } else if (serialDiff === 0) {
    return { status: SerialListStatus.Valid, label: 'Done' };
  } else if (serialDiff > 0) {
    return {
      status: SerialListStatus.Invalid,
      label: `${Math.abs(serialDiff)} to Add`,
    };
  } else if (serialDiff < 0) {
    return {
      status: SerialListStatus.Invalid,
      label: `${Math.abs(serialDiff)} to Remove`,
    };
  }
  return {
    status: SerialListStatus.Invalid,
    label: 'Error',
  };
};
