import React, { memo, useState, useEffect } from 'react';
import { TableCell, Tooltip, Typography } from '@mui/material';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import { IconNames } from 'ui/theme/icons';
import { Document, downloadDocument, fetchFileName } from 'services/documents';
import { openInNewTab } from 'helpers';
import { logErrorCtx } from 'app/logging';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { useSelector } from 'react-redux';
import { activeUserHasPermission } from 'services/user/redux/selectors';

const DocumentsRow: React.FC<RenderCustomRowProps<Document>> = (props) => {
  const {
    row,
    index,
    onAction,
    meta: { permissions, tooltip, disabledButton = false },
  } = props;

  const [fileName, setFileName] = useState('');

  const hasPermissionToEditItem = useSelector(
    activeUserHasPermission(permissions)
  );

  useEffect(() => {
    (async () => {
      try {
        const res = await fetchFileName(row.filePath);
        setFileName(res);
      } catch (e) {
        const error = e as Error;
        logErrorCtx('Error while fetching file names', {
          error,
          stackTrace: error.stack,
          title: error.message,
          description:
            'Error while fetching file names to display in documents table',
          component: 'DocumentsRow',
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row.filePath]);

  const handleDeleteClicked = () => {
    if (onAction) {
      onAction({ index, filePath: row });
    }
  };

  const onRowClick = async () => {
    try {
      const url = await downloadDocument(row.filePath);
      openInNewTab(url);
    } catch (e) {
      const error = e as Error;
      logErrorCtx('Error while downloading document', {
        error,
        stackTrace: error.stack,
        title: error.message,
        description:
          'Error while downloading document in DocumentsTable component',
        component: 'DocumentsTable',
      });
    }
  };

  return (
    <>
      <TableCell onClick={onRowClick}>
        <Typography>{fileName}</Typography>
      </TableCell>
      <TableCell align="right">
        <Tooltip
          title={tooltip || ''}
          placement="bottom"
          sx={{ color: 'unset' }}
        >
          <div>
            <FBOButton
              variant="tertiary"
              color="negative"
              size="small"
              icon={IconNames.TrashCan}
              permissions={permissions}
              onClick={handleDeleteClicked}
              disabled={disabledButton || !hasPermissionToEditItem}
              data-qa="documents-modal-delete-button"
              sx={{ svg: { padding: '2px' } }}
            />
          </div>
        </Tooltip>
      </TableCell>
    </>
  );
};

export default memo(DocumentsRow);
