import { TaxRate, TaxType } from 'services/taxRates';

export const setSelectedValueRequest = (
  response: TaxRate,
  setSelectedValue: React.Dispatch<React.SetStateAction<number | null>>
) => {
  const value =
    response.taxType === TaxType.percentage
      ? (response.percentage || 0) * 100
      : response.amount;

  setSelectedValue(value);
};
