import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid, Typography } from '@mui/material';
import _ from 'lodash';

import { TextField } from 'ui/components/TextField/TextField';
import { TrackingTableTypes } from 'ui/components/Table/TrackingTable';

import { TextFieldQuantity } from 'ui/components/TextField/TextFieldQuantity';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import {
  getItemInventory,
  itemHasTracking,
  ItemInventory,
  ItemTrackingType,
} from 'services/inventory';
import { TrackingDataTypes } from 'services/settings/tracking';
import { ItemType } from 'services/items';
import { EACH_UOM_ID, getUoms } from 'services/uoms';

import { ReceiveWizardItemProps } from './types';
import { useReceiveModalStyle } from './styled';
import { transformSerialIdsToSerialList, getUomName } from './helpers';
import TrackingTable from 'ui/components/Table/TrackingTable/TrackingTable';
import SerialTable from 'ui/components/Table/SerialTable/SerialTable';
import { colorPalette } from 'ui/theme';

const ReceiveWizardItem: React.FC<ReceiveWizardItemProps> = (props) => {
  const { receipt, receive, setReceive, setLoadingContent } = props;

  const { items: uoms } = useSelector(getUoms);

  const classes = useReceiveModalStyle();

  const eachUomConversion = useMemo(() => {
    if (!receive.receiptItem.uom || !receive.receiptItem.uom.id) {
      return null;
    }

    const receiptItemUomId = receive.receiptItem.uom.id;
    const eachConversion =
      receive.receiptItem.uom.fromConversions.find(
        (c) => c.fromUomId === EACH_UOM_ID && c.toUomId === receiptItemUomId
      ) ||
      receive.receiptItem.uom.toConversions.find(
        (c) => c.fromUomId === receiptItemUomId || c.toUomId === EACH_UOM_ID
      );

    if (!eachConversion) {
      return null;
    }

    if (!receive.receiptItem.item) {
      return eachConversion;
    }
    // If there is an Item Conversion we need to use its Factor
    const itemConversion = receive.receiptItem.item.itemUomConversionList.find(
      (c) => c.uomConversionId === eachConversion.id
    );

    return {
      ...eachConversion,
      factor: itemConversion ? itemConversion.factor : eachConversion.factor,
    };
  }, [receive.receiptItem]);

  const hasTracking = useMemo(
    () => receive.receiptItem.item && itemHasTracking(receive.receiptItem.item),
    [receive.receiptItem]
  );

  const hasOnlySerial = useMemo(() => {
    if (!hasTracking) {
      return false;
    }

    return _.every(
      _.get(receive.receiptItem, 'item.itemTrackingTypeList', []),
      (itemTrackingType: ItemTrackingType) =>
        _.get(itemTrackingType, 'trackingType.trackingDataType', null) ===
        TrackingDataTypes.SerialNumber
    );
  }, [receive.receiptItem, hasTracking]);

  const selectedReceiptItem = useMemo(() => {
    return receipt.receiptItems.find((p) => p.id === receive.id) || null;
  }, [receipt.receiptItems, receive.id]);

  const resolvedQuantity = `${_.get(
    selectedReceiptItem,
    'quantity',
    '0'
  )} ${_.get(selectedReceiptItem, 'uom.abbreviation', 'ea')}`;

  const selectedReceiptItemType = _.get(selectedReceiptItem, 'item.itemType');

  // use effect for fetching item inventory
  useEffect(() => {
    const asyncFc = async (id: number) => {
      setLoadingContent(true);
      const itemInventory = await getItemInventory(id);

      if (_.isEmpty(receive.trackingGroups) && receive.locationId) {
        const trackingGroups = getTrackingGroupsByLocation(
          itemInventory,
          receive.locationId!
        );
        setReceive((old) => ({
          ...old,
          trackingGroups: trackingGroups,
          quantity: receive.receiptItem.quantity,
        }));
      }

      setLoadingContent(false);
    };
    const itemId = _.get(receive, 'receiptItem.itemId', 0);
    if (receive.receiptItemId > 0 && itemId > 0) {
      asyncFc(itemId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receive.receiptItem]);

  const getTrackingGroupsByLocation = useCallback(
    (itemInventory: ItemInventory, locationId: number) => {
      const locationInventory =
        itemInventory.inventoryRowList.find(
          (r) => r.locationId === locationId
        ) || null;

      if (hasOnlySerial) {
        return [
          {
            committedQuantity: 0,
            onHand: 0,
            quantity: receive.receiptItem.quantity,
            trackingInfoList: [],
            serialList: transformSerialIdsToSerialList(receive, 0),
            serialIds: [],
          },
        ];
      }

      if (!locationInventory) {
        return receive.receiptItem.trackingGroupList;
      }

      return locationInventory.trackingGroupList.map((tg, index) => ({
        ...tg,
        serialList: transformSerialIdsToSerialList(receive, index),
        quantity: receive.receiptItem.trackingGroupList[index].onHand,
      }));
    },
    [receive, hasOnlySerial]
  );

  return (
    <>
      <Box p={3} overflow="hidden" flexShrink={0}>
        <Grid container spacing={2}>
          <Grid item xs={2} classes={{ root: classes.gridWithoutRightPadding }}>
            {/* TODO: update with big number style */}
            <TextField
              className="redesign"
              variant="standard"
              readOnly
              additionalInputProps={{
                classes: { input: classes.bigNumber },
              }}
              value={resolvedQuantity}
              label="Receive Quantity"
            />
          </Grid>
          {selectedReceiptItemType === ItemType.Inventory && (
            <Grid item xs={6}>
              <LocationsAsyncAutocomplete
                parentId={receipt.locationId!}
                label="Location"
                onChange={_.noop}
                value={receive.locationId}
                companyWide={false}
                required
                disabled
              />
            </Grid>
          )}
          <Grid item xs={4}>
            {(!hasTracking || hasOnlySerial) && (
              <>
                <TextFieldQuantity
                  fullWidth
                  label="Quantity"
                  name="quantity"
                  placeholder="0"
                  value={receive.quantity}
                  selectedUomId={receive.receiptItem.uomId || EACH_UOM_ID}
                  uoms={uoms}
                  onTextChange={_.noop}
                  required
                  disabled
                />
                {eachUomConversion && (
                  <Typography>
                    {`${eachUomConversion.factor} `}
                    <i>
                      <b>{getUomName(eachUomConversion.toUomId!, uoms)} </b>
                      {'per '}
                      <b>{getUomName(eachUomConversion.fromUomId!, uoms)}</b>
                    </i>
                  </Typography>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box
        display="flex"
        flexGrow={1}
        flexDirection="column"
        overflow="hidden"
        height="50vh"
      >
        {hasTracking && (
          <>
            {!hasOnlySerial ? (
              <TrackingTable
                firstColumnTitle="Quantity To Receive"
                itemTrackingTypes={_.get(
                  receive,
                  'receiptItem.item.itemTrackingTypeList',
                  []
                )}
                trackingGroups={receive.trackingGroups}
                onSetTrackingGroups={_.noop}
                tableType={TrackingTableTypes.ReceivingView}
                inventoryUom={
                  receive.receiptItem.item
                    ? receive.receiptItem.item.defaultUom
                    : null
                }
                itemUom={receive.receiptItem.uom || null}
                sx={{
                  borderRadius: '5px',
                  border: `1px solid ${colorPalette.redesign.background3}`,
                  borderTop: 'none',
                }}
              />
            ) : (
              <SerialTable
                serialList={_.get(receive, 'trackingGroups[0].serialList', [])}
                itemTrackingTypes={_.get(
                  receive,
                  'receiptItem.item.itemTrackingTypeList',
                  []
                )}
                title="Tracking"
                disableAutoAssign
                sx={{
                  borderRadius: '5px',
                  border: `1px solid ${colorPalette.redesign.background3}`,
                  borderTop: 'none',
                }}
              />
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default memo(ReceiveWizardItem);
