export enum ImageStorageType {
  ItemImage = 'item-image',
  UserImage = 'user-image',
  BomInstructionImage = 'bom-instruction-image',
  SalesOrderDocuments = 'so-documents',
  ItemDocuments = 'item-documents',
  ShippingDocuments = 'ship-documents',
  CustomerDocuments = 'customer-documents',
  PurchaseOrderDocuments = 'po-documents',
  VendorDocuments = 'vendor-documents',
  ReceivingDocuments = 'receipt-documents',
}
