import { transformDateToMomentDate } from 'helpers';
import { BackgroundAction, BackgroundItem, DownloadHistoryRow } from './types';

export const transformbackgroundItem = (response: any): BackgroundItem => {
  return {
    id: response.id,
    email: response.email,
    fileName: response.fileName,
    filePath: response.filePath,
    action: response.action,
    dateCreated: transformDateToMomentDate(response.dateCreated),
    status: response.status,
    errorLogUrl: response.errorLogUrl || '',
    progress: response.progress,
    message: response.message,
    type: response.type,
    url: response.url,
  };
};

export const transformDownloadHistory = (res: any): DownloadHistoryRow => {
  return {
    action: res.action,
    fileName: res.fileName,
    status: res.status,
    type: res.type,
    dateCreated: res.dateCreated,
    message: res.message,
    url: res.url,
    id: res.id,
  };
};

export const transformUploadHistory = (res: any): DownloadHistoryRow => {
  return {
    action: 'Import',
    fileName: res.filename,
    id: res.id,
    status: res.status,
    type: res.type,
    dateCreated: res.dateCreated,
    message: res.message,
    url: res.errorLogUrl || null,
    errorLogUrl: res.errorLogUrl || null,
  };
};

export const transformCSV = (res: any): BackgroundItem => {
  return {
    id: res.id,
    action: BackgroundAction.Import,
    fileName: res.filename,
    status: res.status,
    email: res.email || null,
    type: res.type,
    filePath: `${res.tenant || ''}/${res.email || ''}`,
    dateCreated: res.dateCreated,
    progress: 100,
    errorLogUrl: res.errorLogUrl || '',
    message: res.message,
    url: '',
  };
};
