import React, { memo, useCallback } from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { ReceiptAsyncAutocomplete } from 'ui/components/Autocomplete/ReiceptAsyncAutocomplete';
import { useHandleCheckboxChange } from 'services/forms/hooks';
import { Receipt } from 'services/receiving';

import { OrderByOptions } from './types';

import { ReportFiltersProps } from '../../types';

const ReceivingListFilters: React.FC<ReportFiltersProps> = (props) => {
  const { parameters, setParameters, errors } = props;

  const handleReceiptChange = useCallback(
    (value: Receipt | null) =>
      setParameters((old) => ({ ...old, receiptId: value ? value.id : null })),
    [setParameters]
  );

  const handleSelectAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setParameters((old) => ({
        ...old,
        includeItemBarcode: checked,
        includeReceivedItems: checked,
        showBarcode: checked,
        showVendorPartName: checked,
        selectAllOptional: checked,
      }));
    },
    [setParameters]
  );

  const handleCheckboxChange = useHandleCheckboxChange(setParameters);

  const handleOrderByChange = useCallback(
    (e: any, orderBy: OrderByOptions | null) => {
      setParameters((old) => ({ ...old, orderBy }));
    },
    [setParameters]
  );
  // TODO : RESTYLING : Remove box wrappers around inputs after FF removal

  return (
    <>
      <Accordion square defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <b>Required</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%">
            <Box>
              <ReceiptAsyncAutocomplete
                label="Receipt Number"
                value={parameters.receiptId}
                placeholder="Select Receipt"
                onChange={handleReceiptChange}
                required
                error={!!errors.receiptId}
                helperText={errors.receiptId}
                customQuickSearchColumns={['number']}
              />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Box px={2} pb={0} pt="16px">
        <FormControlLabel
          className="redesign"
          sx={{ marginBottom: '8px' }}
          control={
            <Checkbox
              className="redesign"
              checked={Boolean(parameters.selectAllOptional)}
              onChange={handleSelectAll}
              color="primary"
            />
          }
          label="Select All Optional"
        />
      </Box>
      <Accordion square>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>
            <b>Optional</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%">
            <Box>
              <Autocomplete
                label="Order By"
                options={Object.values(OrderByOptions)}
                value={parameters.orderBy || null}
                onChange={handleOrderByChange}
                placeholder="Select"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.includeItemBarcode)}
                    onChange={handleCheckboxChange}
                    name="includeItemBarcode"
                    color="primary"
                  />
                }
                label="Include Item Barcode"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.includeReceivedItems)}
                    onChange={handleCheckboxChange}
                    name="includeReceivedItems"
                    color="primary"
                  />
                }
                label="Include Received Items"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showBarcode)}
                    onChange={handleCheckboxChange}
                    name="showBarcode"
                    color="primary"
                  />
                }
                label="Show Barcode"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showVendorPartName)}
                    onChange={handleCheckboxChange}
                    name="showVendorPartName"
                    color="primary"
                  />
                }
                label="Show Vendor Part Name"
              />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default memo(ReceivingListFilters);
