import React, { memo, useEffect, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, Grid, Popover } from '@mui/material';
import _ from 'lodash';

import { RenderCustomFooterProps } from 'ui/components/Table/ItemsTable/types';
import { roundToDecimals, USCountryString } from 'helpers';
import {
  initialPurchaseOrder,
  PurchaseOrderStatus,
} from 'services/purchaseOrders';
import { showNotification } from 'services/api';
import { ClassAutocomplete } from 'ui/components/Autocomplete/ClassesAutocomplete';
import { Class, getClasses } from 'services/classes';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';
import { TaxRateVariants } from 'ui/components/Autocomplete/TaxRatesAutocomplete/types';
import { TaxRatesAutocomplete } from 'ui/components/Autocomplete/TaxRatesAutocomplete';
import { TaxRate } from 'services/taxRates';
import { calculateTaxTotal } from 'services/salesOrders';
import { getSettingsPurchaseOrder } from 'services/settings/purchaseOrders';

import { ItemFooterData, ItemFooterActions } from './types';
import { useTableFooterStyle } from './styled';
import {
  editPurchaseOrderPermissions,
  useDisablePurchaseOrderField,
} from '../../../../helpers';
import { useCurrencyFormatter } from 'helpers/hooks';
import {
  getSettingsCompany,
  getSettingsCompanyCountry,
} from 'services/settings/company';
import { MultiCurrencyWrapper } from 'ui/components/MultiCurrencyWrapper/MultiCurrencyWrapper';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { IconNames } from 'ui/theme';
import { totalAnchorOrigin, totalTransformOrigin } from '../../consts';

const PopoverItem: React.FC<{
  label: string;
  value: string;
  last?: boolean;
  popWeight?: number;
}> = (props) => {
  const { label, value, last, popWeight } = props;
  return (
    <Box display="flex" justifyContent="space-between" pb={last ? 0 : 3}>
      <Typography
        variant="body2"
        color="textSecondary"
        style={{ fontWeight: popWeight }}
      >
        {label}
      </Typography>
      <Typography style={{ fontWeight: popWeight }}>{value}</Typography>
    </Box>
  );
};

const TableFooter: React.FC<
  RenderCustomFooterProps<ItemFooterData, ItemFooterActions>
> = (props) => {
  const { footerData, onFooterAction } = props;
  const classes = useTableFooterStyle();
  const useMultiCurrencyEnabled =
    useSelector(getSettingsCompany).useMultiCurrency;
  const { homeCurrency } = useSelector(getSettingsCompany);
  const homeCurrencyCode = (homeCurrency && homeCurrency.code) || 'USD';
  const accountingClasses = useSelector(getClasses);
  const poSettings = useSelector(getSettingsPurchaseOrder);
  const [totalEl, setTotalEl] = useState<null | HTMLElement>(null);
  const [totalMultiEl, setTotaMultilEl] = useState<null | HTMLElement>(null);

  const activeMulticurrencyCode = _.get(
    footerData,
    'activeMulticurrencyCode',
    null
  );
  const isHomeCurrencyMulticurrencyUsd =
    activeMulticurrencyCode === homeCurrencyCode;

  const currencyFormatter = useCurrencyFormatter();
  const editPermission = editPurchaseOrderPermissions(
    _.get(footerData, 'purchaseOrder', initialPurchaseOrder)
  );
  const exchangeRate = _.get(footerData, 'purchaseOrder.exchangeRate', 1);

  const isTotalPopVisible = useMemo(() => Boolean(totalEl), [totalEl]);
  const isTotalMultiPopVisible = useMemo(
    () => Boolean(totalMultiEl),
    [totalMultiEl]
  );
  const disableField = useDisablePurchaseOrderField(
    footerData && footerData.purchaseOrder
      ? footerData.purchaseOrder.status
      : PurchaseOrderStatus.BidRequest
  );
  const fieldsDisabled =
    _.get(footerData, 'purchaseOrder.status', null) ===
    PurchaseOrderStatus.Fulfilled;
  const getCountry = useSelector(getSettingsCompanyCountry);
  const [hideTax, setHideTax] = useState(true);
  useEffect(() => {
    if (getCountry && getCountry === USCountryString) {
      setHideTax(false);
    }
  }, [getCountry]);
  const totals = useMemo(() => {
    return footerData!.purchaseOrder.purchaseOrderItemList.reduce(
      (acc, item) => {
        let costTaxStatus = false;
        if (footerData!.purchaseOrder.id === -1) {
          if (poSettings.costIncludesTax) {
            costTaxStatus = true;
          }
        }
        if (footerData!.purchaseOrder.costIncludesTax) {
          costTaxStatus = true;
        }
        if (!item.deleted) {
          acc.grandTotal =
            acc.grandTotal +
            (item.totalCost || 0) +
            ((!costTaxStatus && item.taxTotal) || 0);

          const subtotalItemCost = !costTaxStatus
            ? item.totalCost || 0
            : (item.totalCost || 0) / (1 + (item.taxRate || 0));

          acc.subtotal = acc.subtotal + subtotalItemCost;
          acc.taxTotal = acc.taxTotal + (item.taxTotal || 0);
        }

        return acc;
      },
      {
        grandTotal: 0,
        taxTotal: 0,
        subtotal: 0,
      }
    );
  }, [footerData, poSettings.costIncludesTax]);
  const formatedMulticurrency = useMemo(() => {
    const exchangeRate = _.get(footerData, 'purchaseOrder.exchangeRate', 1);
    const multiCurrencyGrandTotal = currencyFormatter(
      totals.grandTotal * exchangeRate,
      activeMulticurrencyCode
    );
    const multiCurrencySubTotal = currencyFormatter(
      totals.subtotal * exchangeRate,
      activeMulticurrencyCode
    );
    const multiCurrencyTaxTotal = currencyFormatter(
      totals.taxTotal * exchangeRate,
      activeMulticurrencyCode
    );
    return {
      multiCurrencyGrandTotal,
      multiCurrencyTaxTotal,
      multiCurrencySubTotal,
    };
  }, [
    activeMulticurrencyCode,
    footerData,
    totals.grandTotal,
    totals.subtotal,
    currencyFormatter,
  ]);

  const validationErrors = useMemo(() => {
    return footerData ? footerData.validationErrors : {};
  }, [footerData]);

  const vendorMin = useMemo(() => {
    return _.get(footerData, 'purchaseOrder.vendor.vendorMin', 0);
  }, [footerData]);

  // Show toast if the location is empty
  useEffect(() => {
    if (!!validationErrors.locationId) {
      showNotification('Please fill in the location', { variant: 'error' });
    }
  }, [validationErrors]);

  const selectedAccountingClass = useMemo(() => {
    return (
      accountingClasses.find(
        (c) =>
          c.id === _.get(footerData, 'purchaseOrder.accountingClassId', null)
      ) || null
    );
  }, [accountingClasses, footerData]);

  const handleIconClicked = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setTotalEl(event.currentTarget);
    },
    []
  );
  const handleMultiIconClicked = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setTotaMultilEl(event.currentTarget);
    },
    []
  );

  const handleTotalPopClose = useCallback(() => {
    setTotaMultilEl(null);
    setTotalEl(null);
  }, []);

  const handleClassChange = useCallback(
    (value: Class | null) => {
      if (onFooterAction) {
        onFooterAction.setPurchaseOrder((old) => ({
          ...old,
          accountingClassId: value ? value.id : null,
        }));
      }
    },
    [onFooterAction]
  );

  const handleRateChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const exchangeRate = e.target.value ? parseFloat(e.target.value) : 1;
    if (onFooterAction) {
      onFooterAction.setPurchaseOrder((old) => ({
        ...old,
        exchangeRate: isHomeCurrencyMulticurrencyUsd ? 1 : exchangeRate,
        purchaseOrderItemList: old.purchaseOrderItemList.map((i) => ({
          ...i,
          exchangeRate,
          multiCurrencyUnitCost: roundToDecimals(
            (i.unitCost || 0) * (exchangeRate || 1),
            5
          ),
          multiCurrencyTotalCost: roundToDecimals(
            (i.totalCost || 0) * (exchangeRate || 1),
            5
          ),
        })),
      }));
    }
  };

  const handleTaxRateChange = useCallback(
    (value: TaxRate | null) => {
      if (onFooterAction) {
        const purchaseItemsUpdates = footerData
          ? footerData.purchaseOrder.purchaseOrderItemList.map((s) => {
              if (s.deleted || !s.taxable) {
                return s;
              }

              const percentage = value ? value.percentage : 0;

              const taxTotal = percentage
                ? calculateTaxTotal(
                    percentage,
                    (s.unitCost || 0) * (s.quantity || 0),
                    0
                  )
                : 0;

              return {
                ...s,
                taxId: value ? value.id : null,
                totalCost: (s.unitCost || 0) * (s.quantity || 0),
                taxRate: value ? value.percentage : 0,
                taxTotal,
              };
            })
          : [];

        onFooterAction.setPurchaseOrder((old) => ({
          ...old,
          purchaseTaxId: value ? value.id : null,
          purchaseTax: value,
          purchaseOrderItemList: purchaseItemsUpdates,
        }));
      }
    },
    [onFooterAction, footerData]
  );

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      height="100%"
      px={1}
    >
      <Grid container item spacing={1} xs={4}>
        <Grid item xs={6} xl={5}>
          <ClassAutocomplete
            label="Class"
            disabled={fieldsDisabled}
            placeholder="Select Class"
            onChange={handleClassChange}
            value={selectedAccountingClass}
            permissions={editPermission}
            dataQa="po-generalTab-class"
          />
        </Grid>
        {!hideTax && (
          <Grid item xs={6} xl={5}>
            <TaxRatesAutocomplete
              label="Tax"
              value={_.get(footerData, 'purchaseOrder.purchaseTaxId', null)}
              onChange={handleTaxRateChange}
              disabled={fieldsDisabled || disableField('tax')}
              permissions={editPermission}
              taxRateVariant={TaxRateVariants.Percentage}
              placeholder="Select tax"
              dataQa="po-generalTab-tax"
            />
          </Grid>
        )}
      </Grid>
      <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
        <Grid container xs={5} xl={4}>
          <Grid item xs={5}>
            <CurrencyField
              label="Currency"
              value={1}
              disabled={true}
              fullWidth
              allowNegative
              decimalPlaces={5}
            />
          </Grid>
          <Grid item>
            <Box
              marginLeft="12px"
              marginRight="12px"
              display="flex"
              alignItems="center"
              height="100%"
            >
              =
            </Box>
          </Grid>
          <Grid item xs={5}>
            <CurrencyField
              label="Rates"
              value={exchangeRate}
              disabled={fieldsDisabled}
              onChange={handleRateChange}
              fullWidth
              currencyCode={activeMulticurrencyCode}
              decimalPlaces={5}
            />
          </Grid>
        </Grid>
      </MultiCurrencyWrapper>
      <Grid
        container
        item
        justifyContent="flex-end"
        alignItems={'center'}
        spacing={2}
        xs={12}
        xl={8}
        className={classes.paddingRight}
      >
        <Grid item xs={4} xl={4}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            data-qa="po-generalTab-vendorMin"
          >
            <Typography>Vendor Min: {currencyFormatter(vendorMin)}</Typography>
            <></>
          </Box>
        </Grid>
        {useMultiCurrencyEnabled &&
        activeMulticurrencyCode !== homeCurrency?.code ? (
          <Grid item xs={4} xl={3}>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <Typography
                component="div"
                data-qa="po-multi-currency-enabled-grandtotal-field"
              >
                Total {homeCurrencyCode}:
                <b>{currencyFormatter(totals.grandTotal)}</b>
              </Typography>
              <FBOButton
                variant="tertiary"
                color="neutral"
                size="medium"
                icon={IconNames.CaratUp}
                onClick={handleIconClicked}
                data-qa={'footer-expand-multicurrency-total-button'}
              />
            </Box>
          </Grid>
        ) : (
          <></>
        )}
        <Popover
          anchorOrigin={totalAnchorOrigin}
          transformOrigin={totalTransformOrigin}
          anchorEl={totalEl}
          open={isTotalPopVisible}
          onClose={handleTotalPopClose}
        >
          <Box width={200} px={2} py={2.5}>
            <Grid container direction="column">
              <Grid item xs={12}>
                <PopoverItem
                  label="Subtotal:"
                  value={currencyFormatter(totals.subtotal)}
                />
              </Grid>
              <Grid item xs={12}>
                <PopoverItem
                  label="Tax:"
                  value={currencyFormatter(totals.taxTotal)}
                />
              </Grid>
              <Grid item xs={12}>
                <PopoverItem
                  label="Total:"
                  value={currencyFormatter(totals.grandTotal)}
                />
              </Grid>
            </Grid>
          </Box>
        </Popover>

        <>
          <Grid item xs={4} xl={4}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              data-qa="po-multi-currency-popup"
            >
              <Typography
                component="div"
                data-qa="po-multi-currency-enabled-total-field"
              >
                {`Total ${activeMulticurrencyCode || ''} : `}
                <b>{formatedMulticurrency.multiCurrencyGrandTotal || ''}</b>
              </Typography>
              <FBOButton
                variant="tertiary"
                color="neutral"
                size="medium"
                icon={IconNames.CaratUp}
                onClick={handleMultiIconClicked}
                data-qa="po-multi-currency-btn"
              />
            </Box>
          </Grid>
          <Popover
            anchorOrigin={totalAnchorOrigin}
            transformOrigin={totalTransformOrigin}
            anchorEl={totalMultiEl}
            open={isTotalMultiPopVisible}
            onClose={handleTotalPopClose}
          >
            <Box width={200} px={2} py={2.5}>
              <Grid container direction="column">
                <Grid item xs={12}>
                  <PopoverItem
                    label="Subtotal:"
                    value={formatedMulticurrency.multiCurrencySubTotal}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PopoverItem
                    label="Tax:"
                    value={formatedMulticurrency.multiCurrencyTaxTotal}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography component="div" style={{ fontWeight: 800 }}>
                    <b style={{ fontWeight: 800 }}>
                      <PopoverItem
                        label="Total:"
                        value={formatedMulticurrency.multiCurrencyGrandTotal}
                        popWeight={800}
                      />
                    </b>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Popover>
        </>
      </Grid>
    </Box>
  );
};

export default memo(TableFooter);
