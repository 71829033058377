import { createFilterOptions, FilterOptionsState } from '@mui/material';
import { Class } from 'services/classes';
import { fetchClassesAPI } from 'services/classes/api';
import { initialClass } from 'services/classes/consts';

import { Pagination } from 'services/search';

const filter = createFilterOptions<Class>();

export const classAutocompleteOptionLabel = (accountingClass: Class) => {
  if (!accountingClass.id || accountingClass.id < 0) {
    return `+ Add "${accountingClass.name}"`;
  }

  return accountingClass.name || '';
};

export const classAutocompleteFilterOptions =
  (disableAdd: boolean) =>
  (options: Class[], params: FilterOptionsState<Class>) => {
    const filtered = filter(options, params);

    if (disableAdd) {
      return filtered;
    }

    if (!!params.inputValue) {
      filtered.unshift({
        ...initialClass,
        name: params.inputValue || null,
      });
    }

    return filtered;
  };

export const resolvedFetchClassesFunction = (
  expands?: string[],
  customQuickSearchColumns?: string[]
) => {
  return (pagination: Pagination, searchValue: string | null) =>
    fetchClassesAPI({
      pagination,
      expands: expands,
      quickSearchValue: searchValue,
      customQuickSearchColumns,
    });
};
