import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colorPalette } from 'ui/theme';

export const useDashboardTableStyle = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(7),
    width: '100%',
  },
  card: {
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.16)',
    backgroundColor: colorPalette.white,
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
  },
  cardHeader: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexShrink: 0,
  },
  cardHeaderTitle: {
    fontSize: 'calc(16px * var(--font-size-multiplier, 1))',
    fontWeight: 700,
  },
  cardHeaderLink: {
    textTransform: 'uppercase',
    textDecoration: 'underline',
    color: colorPalette.applicationDarkGray80,
  },
  cardContent: {},
}));
