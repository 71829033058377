import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useTrackingCardStyle = makeStyles((theme: Theme) => ({
  paper: {
    width: '600px',
    padding: 0,
  },
  container: {
    paddingBottom: '64px',
    paddingLeft: theme.spacing(15),
  },
  rowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 56,
    padding: theme.spacing(2),
  },
  titleContainer: {
    width: '40%',
  },
}));
