import React, { useState, memo, useCallback, useMemo } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  Box,
} from '@mui/material';
import { AdvancedSearchFieldsCmpProps } from 'ui/components/Page/PageWithAdvancedSearch';
import { Location } from 'services/locations';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { TrackingDataTypes } from 'services/settings/tracking';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import { TagsAsyncAutocomplete } from 'ui/components/Autocomplete/TagsAutocomplete';
import { Tag } from 'services/tags';

const ItemsAdvancedSearch: React.FC<AdvancedSearchFieldsCmpProps> = (props) => {
  const { setFormValues, formValues } = props;

  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);

  const tagsIds = useMemo(() => {
    return formValues['tags.id'] as number[];
  }, [formValues]);

  const handleLocationChange = useCallback(
    (value: Location | null) => {
      setFormValues({ ...formValues, locationId: value ? value.id : null });
    },
    [formValues, setFormValues]
  );

  const handleTrackingTypeChange = useCallback(
    (e: React.ChangeEvent<{}>, value: TrackingDataTypes | null) => {
      setFormValues({
        ...formValues,
        'itemTrackingTypes.trackingType.trackingDataType': value,
      });
    },
    [formValues, setFormValues]
  );

  const handleIncludeChildrenChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setFormValues({
        ...formValues,
        includeChildren: checked ? 'true' : null,
      });
    },
    [formValues, setFormValues]
  );

  const handleZeroQuantityItemsChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setFormValues({
        ...formValues,
        hideZeroQuantityItems: checked ? 'true' : null,
      });
    },
    [formValues, setFormValues]
  );

  const handleTagChange = useCallback(
    (value: Tag[]) => {
      setSelectedTags(value);
      setFormValues({
        ...formValues,
        'tags.id': value.length ? value.map((i) => i.id!) : null,
      });
    },
    [formValues, setFormValues]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Autocomplete
          label="Tracking Type"
          placeholder="Select tracking type"
          options={Object.values(TrackingDataTypes)}
          value={formValues['itemTrackingTypes.trackingType.trackingDataType']}
          onChange={handleTrackingTypeChange}
          dataQa="inventory-filter-tracking-type"
        />
      </Grid>
      <Grid item xs={6}>
        <LocationsAsyncAutocomplete
          placeholder="Select location"
          label="Location"
          value={formValues.locationId as number}
          onChange={handleLocationChange}
          companyWide={false}
          disableAdd
          renderOption={(props: Object, l: Location) => {
            return (
              <Box display="flex" width="100%" {...props}>
                <Typography variant="body1">{l.path}</Typography>
              </Box>
            );
          }}
          getLocationLabel={(l: Location) => l.path || l.name || 'n/a'}
          dataQa="inventory-filter-location"
        />
      </Grid>
      <Grid item xs={3}>
        <FormControlLabel
          className="redesign"
          control={
            <Checkbox
              className="redesign"
              checked={Boolean(formValues.includeChildren)}
              onChange={handleIncludeChildrenChange}
              color="primary"
              data-qsa="inventory-filter-include-children"
            />
          }
          label="Include Children"
          disabled={!formValues.locationId}
        />
      </Grid>
      <Grid item xs={3}>
        <FormControlLabel
          className="redesign"
          control={
            <Checkbox
              className="redesign"
              checked={Boolean(formValues.hideZeroQuantityItems)}
              onChange={handleZeroQuantityItemsChange}
              color="primary"
              data-qa="inventory-filter-hide-zero-quantity-items"
            />
          }
          label="Hide Zero-Quantity Items"
        />
      </Grid>
      <Grid item xs={6}>
        <TagsAsyncAutocomplete
          label="Tags"
          placeholder="Select tag"
          value={selectedTags}
          onChange={handleTagChange}
          ids={tagsIds}
          disableTagsModal
          dataQa="inventory-filter-tags"
        />
      </Grid>
    </Grid>
  );
};

export default memo(ItemsAdvancedSearch);
