import React, { useEffect, useCallback, useState, memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import {
  PurchaseOrderSettings,
  putPurchaseSettings,
  fetchSettingsPurchaseOrders,
  getSettingsPurchaseOrder,
} from 'services/settings/purchaseOrders';
import { PermissionType } from 'services/permissions';
import { fetchTaxRates, getTaxRates, TaxType } from 'services/taxRates';
import { USCountryString } from 'helpers';

import {
  purchaseOrderRowDescriptor,
  purchaseOrderCardId,
  title,
} from './consts';
import { PurchaseOrderCardProps } from './types';
import { Card } from '../Card';
import { searchFilter, searchTitle } from '../../helpers';
import { getSettingsCompanyCountry } from 'services/settings/company';

const PurchaseOrderCard: React.FunctionComponent<PurchaseOrderCardProps> = (
  props
) => {
  const { search } = props;
  const [purchaseSettings, setPurchaseSettings] =
    useState<PurchaseOrderSettings>();

  const dispatch = useDispatch();
  const purchaseSettingsCopy = useSelector(getSettingsPurchaseOrder);
  const { items: allTaxRates } = useSelector(getTaxRates);

  const companyCountry = useSelector(getSettingsCompanyCountry);
  const taxRates = useMemo(
    () => allTaxRates.filter((r) => r.taxType === TaxType.percentage),
    [allTaxRates]
  );

  useEffect(() => {
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchSettingsPurchaseOrders());
    // @ts-ignore
    dispatch(fetchTaxRates());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPurchaseSettings(purchaseSettingsCopy);
  }, [purchaseSettingsCopy]);

  const regex = useMemo(() => {
    return RegExp(_.escapeRegExp(search), 'gi');
  }, [search]);

  const hasSearch = useMemo(() => {
    return purchaseOrderRowDescriptor().some((t) => regex.test(t.title));
  }, [regex]);

  const hasTitle = useMemo(() => {
    return new RegExp(_.escapeRegExp(search), 'i').test(title);
  }, [search]);

  const highlightedRow = useMemo(() => {
    if (hasSearch) {
      const searchedData = searchFilter(
        purchaseOrderRowDescriptor(taxRates),
        regex
      );

      if (companyCountry !== USCountryString) {
        return searchedData.filter((val) => val.field !== 'purchaseTax');
      }

      return searchedData;
    } else {
      const searchedData = purchaseOrderRowDescriptor(taxRates);

      if (companyCountry !== USCountryString) {
        return searchedData.filter((val) => val.field !== 'purchaseTax');
      }
      return searchedData;
    }
  }, [hasSearch, regex, taxRates]);

  const highlightedTitle = useMemo(() => {
    if (hasTitle) {
      return searchTitle(title, regex);
    }
    return title;
  }, [hasTitle, regex]);

  const handleApplyClicked = useCallback(async (newPurchaseSettings) => {
    try {
      const purchaseSettingsFromApi = await putPurchaseSettings(
        newPurchaseSettings
      );
      setPurchaseSettings(purchaseSettingsFromApi);
    } catch (e) {
      // continue
    }
  }, []);

  return (
    <Card
      title={highlightedTitle}
      rows={highlightedRow}
      id={purchaseOrderCardId}
      data={purchaseSettings}
      onApplyClicked={handleApplyClicked}
      show={hasSearch || hasTitle}
      viewPermissions={[PermissionType.SettingsPurchaseOrderView]}
      editPermissions={[PermissionType.SettingsPurchaseOrderEdit]}
      dataQa="purchase-order-settings"
    />
  );
};

export default memo(PurchaseOrderCard);
