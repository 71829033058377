import React from 'react';
import moment from 'moment';
import { TableCell } from '@mui/material';

import { LedgerForWaitingToExportModal } from 'services/ledgers';
import { openInNewTab, useGetIntlDateFormatString } from 'helpers';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import { getLedgerDetailsLink } from './helpers';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { IconNames } from 'ui/theme';

const CustomRow: React.FC<
  RenderCustomRowProps<LedgerForWaitingToExportModal>
> = (props) => {
  const { row } = props;

  const intlDateFormat = useGetIntlDateFormatString();

  const rowDetailsLink = getLedgerDetailsLink(row);

  return (
    <>
      <TableCell data-qa={`Date-${row.note}`}>
        {row.postDate ? moment(row.postDate).format(intlDateFormat) : ''}
      </TableCell>
      <TableCell data-qa={`Type/Ref-${row.note}`}>{row.type}</TableCell>
      <TableCell data-qa={`Memo-${row.note}`}>{row.note}</TableCell>
      {rowDetailsLink && (
        <TableCell data-qa={`Details-${row.note}`}>
          <FBOButton
            variant="tertiary"
            color="neutral"
            size="medium"
            icon={IconNames.FBOLeavePage}
            onClick={() => {
              openInNewTab(rowDetailsLink);
            }}
            data-qa={`details-link-${row.note}`}
            component={'a'}
          />
        </TableCell>
      )}
    </>
  );
};

export default React.memo(CustomRow);
