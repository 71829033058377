export const analyticsTrackEvent = ({
  eventName,
  description = '',
  quantity = 1,
  customFields = {},
}: {
  eventName: string;
  description?: string;
  quantity?: number;
  customFields?: {};
}) => {
  window.ChurnZero.push([
    'trackEvent',
    eventName,
    description,
    quantity,
    customFields,
  ]);

  // Keep this until we have tested this well in DEV
  // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  //   const event = {
  //     eventName,
  //     description,
  //     quantity,
  //     customFields,
  //   };
  //   console.table(event);
  // }
};
