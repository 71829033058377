import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { ReportTheme } from 'services/reports';
import { styled } from 'ui/theme';

export const useThemeBuilderStyle = makeStyles<Theme>(() => ({
  modalPreview: {
    width: 'calc(100% - 240px)',
    maxWidth: '900px',
    margin: '0 auto',
  },
  reportsModal: {
    backgroundColor: 'transparent',
    '& .MuiDialog-paperFullScreen': {
      width: '95vw',
      height: '95vh',
      '& .modal-content-inner': {
        height: 'calc(95vh - 42px)',
      },
      '& .loader-wrapper': {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        '& > div:first-of-type': {
          flex: 1,
          '& > div:first-of-type': {
            height: '100%',
          },
        },
      },
      '& .MuiDialogActions-root': {
        display: 'none',
      },
    },
  },
}));

// eslint-disable-next-line no-unexpected-multiline
export const StyledThemeBuilderContent = styled(Box)<{
  reportTheme: ReportTheme;
}>`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  border: 1px solid #ebebeb;
  border-bottom-width: 3px;
  padding: 20px;
  & .pdf-report {
    font-size: ${(props) => `${props.reportTheme.fontSize}pt` || 'initial'};
    font-family: ${(props) => props.reportTheme.font || 'initial'};

    & .table {
      thead {
        background-color: ${(props) =>
          props.reportTheme.color.backgroundColor || 'initial'};
        color: ${(props) => props.reportTheme.color.fontColor || 'initial'};

        th {
          font-size: ${(props) =>
            `${props.reportTheme.fontSize}pt` || 'initial'};
        }
      }

      td {
        font-size: ${(props) => `${props.reportTheme.fontSize}pt` || 'initial'};
      }
    }
  }
`;
// eslint-disable-next-line no-unexpected-multiline
export const StyledThemeBuilderContentModern = styled(Box)<{
  reportTheme: ReportTheme;
}>`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  border: 1px solid #ebebeb;
  border-bottom-width: 3px;
  padding: 20px;
  & .pdf-report {
    font-size: ${(props) => `${props.reportTheme.fontSize}pt` || 'initial'};
    font-family: ${(props) => props.reportTheme.font || 'initial'};

    & .background {
      background: ${(props) =>
        props.reportTheme.color.backgroundColor || 'initial'};
    }

    & .border,
    & .table-bordered > * > *,
    & .table-bordered > * > * > *,
    & .row-bordered {
      border-color: ${(props) =>
        props.reportTheme.color.borderColor || 'initial'};
    }
  }
`;
