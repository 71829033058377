import React, { useCallback, useState, useMemo } from 'react';
import _ from 'lodash';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

import { activeUserHasPermission } from 'services/user/redux';
import {
  ItemsTable,
  useSelectedItemsChanges,
} from 'ui/components/Table/ItemsTable';
import { CustomerItem, initialCustomerItem } from 'services/customers';
import { getSettingsCompany } from 'services/settings/company';
import { Errors, validateYup } from 'services/forms/validation';
import { findNextNegativeId, replaceValueInCollection } from 'helpers';

import { resolvedCustomerItemColumns } from './consts';
import { ItemsTabProps } from './types';
import ItemsTabModal from './ItemsTabModal';
import ItemsTabCustomRow from './ItemsTabCustomRow';
import { yupCustomerItemSchema } from '../../validation';
import { editCustomerPermissions } from '../../../helpers';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const ItemsTab: React.FC<ItemsTabProps> = (props) => {
  const { activeCustomer, setActiveCustomer } = props;

  const { homeCurrency, useMultiCurrency } = useSelector(getSettingsCompany);

  const { customerSaleItems } = activeCustomer;
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [customerItem, setCustomerItem] =
    useState<CustomerItem>(initialCustomerItem);
  const [validationErrors, setValidationErrors] = useState<Errors>({});
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const editPermission = editCustomerPermissions(activeCustomer);

  const activeMulticurrencyCode = _.get(activeCustomer, 'currency.code', null);
  const homeCurrencyCode = (homeCurrency && homeCurrency.code) || 'USD';

  const showMultiCurrency = useMemo(
    () =>
      useMultiCurrency &&
      activeMulticurrencyCode &&
      activeMulticurrencyCode !== homeCurrencyCode,
    [useMultiCurrency, activeMulticurrencyCode]
  );

  const canOpenCustomersModal = useSelector(
    activeUserHasPermission(editPermission)
  );

  const handleSelectedChange = useSelectedItemsChanges(
    selectedItems,
    setSelectedItems
  );

  const setCustomerItems = useCallback(
    (items: React.SetStateAction<CustomerItem[]>) => {
      if (typeof items === 'function') {
        setActiveCustomer((c) => ({
          ...c,
          customerSaleItems: items(c.customerSaleItems),
        }));
        return;
      }

      setActiveCustomer((c) => ({
        ...c,
        customerSaleItems: items,
      }));
    },
    [setActiveCustomer]
  );

  const openModalForAddingNew = useCallback(() => {
    setCustomerItem({
      ...initialCustomerItem,
      id: findNextNegativeId(activeCustomer.customerSaleItems),
    });
    setModalVisible(true);
  }, [activeCustomer.customerSaleItems]);

  const openModalForEditing = useCallback(
    (id: number) => {
      setCustomerItem(customerSaleItems.find((a) => a.id === id)!);
      if (canOpenCustomersModal) {
        setModalVisible(true);
      }
    },
    [customerSaleItems, canOpenCustomersModal]
  );

  const onModalClose = useCallback(() => {
    setModalVisible(false);
    setCustomerItem(initialCustomerItem);
    setValidationErrors({});
  }, []);

  const handleSave = useCallback(
    (
      e:
        | React.MouseEvent<HTMLButtonElement>
        | React.KeyboardEvent<HTMLDivElement>
    ) => {
      e.preventDefault();
      const isValid = validateYup(
        customerItem,
        yupCustomerItemSchema,
        setValidationErrors
      );

      if (!isValid) {
        return;
      }

      const indexNum = activeCustomer.customerSaleItems.findIndex(
        (a) => a.id === customerItem.id
      );

      if (indexNum === -1) {
        setCustomerItems((oldState) => [...oldState, customerItem]);
      } else {
        setCustomerItems(
          (oldState) =>
            replaceValueInCollection(oldState, customerItem, indexNum)!
        );
      }
      setModalVisible(false);
      setValidationErrors({});
    },
    [setCustomerItems, customerItem, activeCustomer.customerSaleItems]
  );

  const deleteClicked = useCallback(() => {
    const newItems: CustomerItem[] = activeCustomer.customerSaleItems.map(
      (item) => {
        if (selectedItems.includes(item.id!)) {
          setCustomerItems([]);
          return { ...item, deleted: true };
        }
        return item;
      }
    );
    const newRemoved: CustomerItem[] = newItems.filter((item) => {
      return !((item.id || 0) < 0 && item.deleted);
    });

    setCustomerItems(newRemoved);
    setSelectedItems([]);
  }, [selectedItems, setCustomerItems, activeCustomer.customerSaleItems]);

  return (
    <Box flexDirection="column" display="flex" height="100%" width="100%">
      <FBOTitleBar title="Items">
        {!_.isEmpty(selectedItems) && (
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="secondary"
            color="negative"
            size="medium"
            icon="TrashCan"
            data-qa="customer-item-delete-button"
            onClick={deleteClicked}
            permissions={editPermission}
          >
            Delete
          </FBOButton>
        )}
        <FBOButton
          variant="secondary"
          color="positive"
          size="medium"
          icon="FBOAddCircle"
          data-qa="customer-item-add-button"
          onClick={openModalForAddingNew}
          permissions={editPermission}
        >
          Add New
        </FBOButton>
      </FBOTitleBar>

      <ItemsTable
        data={customerSaleItems}
        filterRows={(item) => !item.deleted}
        columns={resolvedCustomerItemColumns(
          showMultiCurrency,
          activeMulticurrencyCode,
          homeCurrencyCode
        )}
        onItemClick={openModalForEditing}
        selectedItems={selectedItems}
        onSelectedChange={handleSelectedChange}
        RenderCustomRow={ItemsTabCustomRow}
        meta={{ activeMulticurrencyCode }}
        dataQa="customer-item-table"
        emptyTableText="ADD NEW ENTRY BY PRESSING 'ADD NEW'"
      />
      <ItemsTabModal
        onClose={onModalClose}
        modalVisible={modalVisible}
        customerItems={customerSaleItems}
        customerItem={customerItem}
        setCustomerItem={setCustomerItem}
        onSaveChanges={handleSave}
        validationErrors={validationErrors}
        setValidationErrors={setValidationErrors}
        customer={activeCustomer}
      />
    </Box>
  );
};

export default React.memo(ItemsTab);
