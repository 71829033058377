import React from 'react';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import { WizardStep } from 'ui/components/Wizard/types';

export const getAutoPurchaseOrderWizardSteps = (
  classes: any,
  activeStep: number
): WizardStep[] => {
  return [
    {
      label: 'Step 1',
      icon:
        activeStep > 0 ? (
          <RadioButtonCheckedIcon className={classes.activeIcon} />
        ) : (
          <RadioButtonUncheckedOutlinedIcon />
        ),
    },
    {
      label: 'Step 2',
      icon:
        activeStep > 1 ? (
          <RadioButtonCheckedIcon className={classes.activeIcon} />
        ) : (
          <RadioButtonUncheckedOutlinedIcon />
        ),
    },
  ];
};
