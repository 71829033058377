import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useReportsModalStyle = makeStyles<Theme>(() => ({
  modalPreview: {
    width: 'calc(100% - 240px)',
    maxWidth: '900px',
    margin: '0 auto',
  },
  reportsModal: {
    backgroundColor: 'transparent',
    '& .MuiDialog-paperFullScreen': {
      width: '95vw',
      height: '95vh',
      '& .modal-content-inner': {
        height: 'calc(95vh - 110px)',
      },
      '& .loader-wrapper': {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        '& > div:first-of-type': {
          flex: 1,
          '& > div:first-of-type': {
            height: '100%',
          },
        },
      },
      '& .MuiDialogActions-root': {
        display: 'none',
      },
    },
  },
}));
