import React from 'react';
import {
  Select,
  MenuItem,
  SelectChangeEvent,
  InputLabel,
  FormControl,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { getUoms, Uom } from 'services/uoms';
import intersectionWith from 'lodash/fp/intersectionWith';

export const findUomsWithConversions = intersectionWith(
  (uom: Uom, id: number) => uom.id === id
);

interface Props {
  selectedUomId: number | null;
  onChange: (event: SelectChangeEvent) => void;
  uomsWithConversionIds: number[];
  disabled?: boolean;
}

export default function UomSelect({
  selectedUomId,
  onChange,
  uomsWithConversionIds,
  disabled = false,
}: Props) {
  const { items: uoms } = useSelector(getUoms);
  const uomId = selectedUomId ? selectedUomId.toString() : '';

  return (
    <FormControl className="redesign-temp" style={{ width: '100%' }}>
      <InputLabel
        id="uom-dropdown"
        htmlFor="mui-component-uom-dropdown"
        shrink={true}
        variant="standard"
      >
        UOM
      </InputLabel>
      <Select
        value={uomId}
        onChange={onChange}
        data-qa="uom-dropdown"
        name="uom-dropdown"
        labelId="uom-dropdown"
        label="UOM"
        inputProps={{ 'data-qa': 'uom-dropdown-input' }}
        sx={{
          '& .MuiSelect-select': { padding: '8.5px 32px 8.5px 14px' },
        }}
        className="redesign-temp"
        disabled={disabled}
      >
        {findUomsWithConversions(uoms, uomsWithConversionIds).map((uom) => (
          <MenuItem key={uom.id as number} value={uom.id as number}>
            {uom.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
