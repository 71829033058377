import * as yup from 'yup';

// Scheme
const yupSchema = yup.object().shape({
  name: yup.string().typeError('Enter name').required(),
});

// Scheme with Discount
const yupSchemaWithDiscount = yup.object().shape({
  name: yup.string().typeError('Enter name').required(),
  discountDays: yup.number().min(0).max(365),
  discountPercent: yup.number().max(99),
});

export const getPaymentTermValidation = (applyDiscount: boolean) => {
  return applyDiscount ? yupSchemaWithDiscount : yupSchema;
};
