import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable/types';

export const resolvedVendorItemColumns = (
  showMulticurrency: boolean,
  currencyCode: string,
  homeCurrencyCode: string
): Column[] => {
  let columns: Column[] = [
    {
      title: 'Item',
      width: 300,
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: 'Vendor Item Name',
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: `Last Cost ${homeCurrencyCode}`,
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: 'Last Date',
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: 'Lead Time',
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: 'Min Quantity',
      type: ColumnTypes.renderByCustomRow,
    },
  ];

  if (showMulticurrency) {
    columns = [
      {
        title: 'Item',
        width: 300,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: 'Vendor Item Name',
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Last Cost ${homeCurrencyCode}`,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Last Cost ${currencyCode}`,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: 'Last Date',
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: 'Lead Time',
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: 'Min Quantity',
        type: ColumnTypes.renderByCustomRow,
      },
    ];
  }
  return columns;
};
