import { SaleItem } from './types';

export enum DefaultSaleItemType {
  Sale = 'Sale',
  CreditReturn = 'Credit Return',
  DropShip = 'Drop Ship',
}

export const defaultSaleItem: SaleItem = {
  id: -1,
  accountingClassId: null,
  alertNotes: null,
  defaultFlag: false,
  defaultImage: null,
  defaultUom: null,
  defaultUomId: null,
  deleted: false,
  description: null,
  details: null,
  dimensionUnit: null,
  height: 0,
  imageLinkList: [],
  incomeAccountId: null,
  inventoryLocationSummaryList: [],
  item: null,
  itemId: null,
  length: 0,
  name: null,
  price: null,
  salesTaxId: null,
  sku: null,
  tags: [],
  taxable: false,
  productTaxCode: null,
  upc: null,
  url: null,
  version: null,
  weight: 0,
  weightUnit: null,
  width: 0,
  defaultSalesOrderItemType: DefaultSaleItemType.Sale,
  bundleItems: [],
};
