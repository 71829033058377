import * as yup from 'yup';

const phoneRegExp =
  /^((\+?[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?){1,2}?[0-9]{3,4}?[ -]?[0-9]{3,4}?$/gm;

export const yupAddressSchema = yup.object().shape({
  name: yup.string().required(),
  street: yup.string().required(),
  city: yup.string().required(),
  country: yup.string().required(),
  state: yup.mixed().when('country', {
    is: 'US',
    then: yup.string().required(),
    otherwise: yup.string().notRequired(),
  }),
  postalCode: yup.mixed().when('country', {
    is: 'US',
    then: yup.string().required(),
    otherwise: yup.string().notRequired(),
  }),
});

export const yupAddressContactSchema = yup.object().shape({
  name: yup.string().required(),
  type: yup.string().required(),
  value: yup.string().when('type', {
    is: 'Email',
    then: yup.string().email().required(),
    otherwise: yup.string().when('type', {
      is: (type: string): boolean => ['Mobile', 'Fax', 'Office'].includes(type),
      then: yup.string().matches(phoneRegExp, 'Enter a valid phone number'),
      otherwise: yup.string().required(),
    }),
  }),
});
