import React, { memo, useCallback, useContext, useState } from 'react';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import { ShippingIntegrationSettings } from 'services/integrations/shipping';
import {
  extraSettingsValidation,
  generalSettingsValidation,
  internationalSettingsValidation,
} from 'services/integrations/shipping/shippo/validations';
import { validateYup } from 'services/forms/validation';

import { ShippoWizardProps } from './types';
import { GeneralSettingsForm } from '../GeneralSettingsForm';
import { InternationalSettingsForm } from '../InternationalSettingsForm';
import { ShippingExtrasSettingsForm } from '../ShippingExtrasSettingsForm';
import { useShippingWizardStyle } from '../../../../styled';
import { ShippoContext } from '../../../../ShippoProvider';
import { ShippoLogoSmall } from 'ui/theme';
import FBOCardWizard from 'ui/theme/components/FBOWizard/FBOCardWizard';

const getValidation = (
  settings: ShippingIntegrationSettings,
  activeStep: number
): [any, any] => {
  switch (activeStep) {
    case 0:
      return [generalSettingsValidation, settings];
    case 1:
      return [internationalSettingsValidation, settings.internationalSettings];
    case 2:
      return [extraSettingsValidation, settings.extraSettings];
    default:
      return [generalSettingsValidation, settings];
  }
};

const steps = (classes: any = {}, activeStep: number = 0) => [
  {
    label: 'General settings',
    icon:
      activeStep >= 1 ? (
        <RadioButtonCheckedIcon className={classes.activeIcon} />
      ) : (
        <RadioButtonUncheckedOutlinedIcon />
      ),
  },
  {
    label: 'International settings',
    icon:
      activeStep >= 2 ? (
        <RadioButtonCheckedIcon className={classes.activeIcon} />
      ) : (
        <RadioButtonUncheckedOutlinedIcon />
      ),
  },
  {
    label: 'Shipping Extras',
    icon: <RadioButtonUncheckedOutlinedIcon />,
  },
];

const ShippoWizard: React.FC<ShippoWizardProps> = () => {
  const classes = useShippingWizardStyle();

  const [activeStep, setActiveStep] = useState(0);

  const { settings, setErrors, saveSettings, isLoading } =
    useContext(ShippoContext);

  const handleNext = async () => {
    // validation
    const [settingsValidation, settingsField] = getValidation(
      settings,
      activeStep
    );

    if (!validateYup(settingsField, settingsValidation, setErrors)) {
      return;
    }

    // if last step
    if (activeStep === steps().length - 1) {
      saveSettings();

      return;
    }

    setActiveStep(activeStep + 1);
  };

  const handlePrevious = useCallback(() => {
    setActiveStep(activeStep - 1);
  }, [activeStep]);

  return (
    <FBOCardWizard
      steps={steps(classes, activeStep)}
      title="Configure Shippo"
      activeStep={activeStep}
      onNext={handleNext}
      onPrevious={handlePrevious}
      isLoading={isLoading}
      imageUrl={ShippoLogoSmall}
    >
      {[
        <GeneralSettingsForm key={0} />,
        <InternationalSettingsForm key={1} />,
        <ShippingExtrasSettingsForm key={2} />,
      ]}
    </FBOCardWizard>
  );
};

export default memo(ShippoWizard);
