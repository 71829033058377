import { combineReducers } from 'redux';

import { reducer as quickbooksReducer } from '../quickbooks/redux';
import { reducer as shippingReducer } from '../shipping/redux';
import { reducer as xeroReducer } from '../xero/redux';

export default combineReducers({
  quickbooks: quickbooksReducer,
  shipping: shippingReducer,
  xero: xeroReducer,
});
