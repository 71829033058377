import { makeStyles } from '@mui/styles';
import { colorPalette } from 'ui/theme';

export const useShippoStyle = makeStyles({
  isLoading: {
    display: 'flex',
    height: 600,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  spinner: {
    color: colorPalette.applicationDarkGray60,
  },
});
