import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable/types';
import { Pagination } from 'services/search';
import { PaginationDirection } from 'services/url';

export const initialPagination: Pagination = {
  page: 1,
  pageSize: 25,
  sort: {
    sortBy: 'dateCreated',
    direction: PaginationDirection.Descending,
  },
  totalRows: 25,
};

export const CONSTING_TABLE_COLUMNS: Column[] = [
  {
    title: 'Date',
    field: 'dateCreated',
    sortable: true,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Time',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Quantity',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Unit Cost',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Total Cost',
    type: ColumnTypes.renderByCustomRow,
  },
];
