import { createFilterOptions, FilterOptionsState } from '@mui/material';
import { fetchPurchaseOrdersAPI, PurchaseOrder } from 'services/purchaseOrders';

import { Pagination } from 'services/search';

const filter = createFilterOptions<PurchaseOrder>();

export const purchaseOrderAutocompleteFilterOptions =
  () =>
  (options: PurchaseOrder[], params: FilterOptionsState<PurchaseOrder>) => {
    const filtered = filter(options, params);

    return filtered;
  };

export const resolvedFetchPurchaseOrderFunction = (
  expands?: string[],
  customQuickSearchColumns?: string[]
) => {
  return (pagination: Pagination, searchValue: string | null) =>
    fetchPurchaseOrdersAPI({
      pagination,
      expands: expands,
      quickSearchValue: searchValue,
      customQuickSearchColumns,
    });
};
