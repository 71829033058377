import {
  createApiReducer,
  chainReducers,
  INITIAL_API_STATUS,
  ApiStatus,
} from 'services/api';
import { transformInternationalTaxRates } from 'services/integrations/xero/transformations';

import { transformQuickbooks } from '../transformations';
import { QuickbooksAccountActionTypes } from './actions';

export interface QuickbookApiState<T = any, R = any> {
  _status: ApiStatus;
  items: T[];
  taxRates: R[];
}

export const INITIAL_API_STATE: QuickbookApiState = {
  _status: INITIAL_API_STATUS,
  items: [],
  taxRates: [],
};
const fetchQuickbooksAccountReducer = createApiReducer(
  [
    QuickbooksAccountActionTypes.FETCH_QUICKBOOKS_ACCOUNT_START,
    QuickbooksAccountActionTypes.FETCH_QUICKBOOKS_ACCOUNT_SUCCESS,
    QuickbooksAccountActionTypes.FETCH_QUICKBOOKS_ACCOUNT_FAILURE,
  ],
  (data, state) => ({ ...state, items: data.results.map(transformQuickbooks) }),
  (data) => data
);

const disconnectQuickbooksReducer = createApiReducer(
  [
    QuickbooksAccountActionTypes.QUICKBOOKS_DISCONNECT_START,
    QuickbooksAccountActionTypes.QUICKBOOKS_DISCONNECT_SUCCESS,
    QuickbooksAccountActionTypes.QUICKBOOKS_DISCONNECT_FAILURE,
  ],
  () => INITIAL_API_STATE,
  (error) => error.response.data.errorMessage
);
export const fetchQuickbooksTaxRatesReducer = createApiReducer(
  [
    QuickbooksAccountActionTypes.QUICKBOOKS_TAX_RATES_START,
    QuickbooksAccountActionTypes.QUICKBOOKS_TAX_RATES_SUCCESS,
    QuickbooksAccountActionTypes.QUICKBOOKS_TAX_RATES_FAILURE,
  ],
  (data, state) => ({
    ...state,
    taxRates: data.results.map(transformInternationalTaxRates),
  }),
  (error) => error.response.data.errorMessage
);
const quickbooksAccountChainedReducers = chainReducers(
  INITIAL_API_STATE,
  fetchQuickbooksAccountReducer,
  fetchQuickbooksTaxRatesReducer,
  disconnectQuickbooksReducer
);

export default quickbooksAccountChainedReducers;
