import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import {
  getShippingSettings,
  initialShippingIntegrationSettings,
  ShippingConnectionType,
  ShippingIntegrationSettings,
  ShippingInternationalSettings,
  ShippingExtraSettings,
  ShippingConnection,
  putShippingSettings,
  fetchShippingIntegrationFileTypes,
  ShippingIntegrationFileType,
} from 'services/integrations/shipping';
import { getShippingIntegrationConnection } from 'services/integrations/shipping/redux';
import { Errors } from 'services/forms/validation';
import { logErrorCtx } from 'app/logging';

export const ShippoContext = React.createContext<{
  settings: ShippingIntegrationSettings;
  setSettings: React.Dispatch<
    React.SetStateAction<ShippingIntegrationSettings>
  >;
  isConnected: boolean;
  isSetupNeeded: boolean;
  errors: Errors;
  setErrors: React.Dispatch<React.SetStateAction<Errors>>;
  connection: ShippingConnection | undefined;
  saveSettings: () => Promise<void>;
  fileTypes: ShippingIntegrationFileType[];
  isLoading: boolean;
}>({
  settings: initialShippingIntegrationSettings,
  setSettings: _.noop,
  isConnected: false,
  isSetupNeeded: false,
  errors: {},
  setErrors: _.noop,
  connection: undefined,
  saveSettings: () => new Promise(_.noop),
  fileTypes: [],
  isLoading: false,
});

export const ShippoProvider: React.FC<ShippoProviderProps> = ({ children }) => {
  const connection = useSelector(
    getShippingIntegrationConnection(ShippingConnectionType.Shippo)
  );

  const [settings, setSettings] = useState<ShippingIntegrationSettings>(
    initialShippingIntegrationSettings
  );
  const [errors, setErrors] = useState<Errors>({});
  const [isSetupNeeded, setIsSetupNeeded] = useState(false);
  const [fileTypes, setFileTypes] = useState<ShippingIntegrationFileType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!connection) {
      return;
    }

    (async () => {
      setIsLoading(true);

      const resSettings = await getShippingSettings(connection);
      setSettings(resSettings);
      setIsSetupNeeded(!resSettings.shippingItem);

      const resFileTypes = await fetchShippingIntegrationFileTypes(connection);
      setFileTypes(resFileTypes);

      setIsLoading(false);
    })();
  }, [connection]);

  const saveSettings = useCallback(async () => {
    if (!connection) {
      return;
    }
    setIsLoading(true);

    try {
      await putShippingSettings(connection, settings);
      setIsSetupNeeded(false);
    } catch (e) {
      const error = e as Error;
      logErrorCtx('ShippoProvider Fails to Save ShippingSettings', {
        error,
        stackTrace: error.stack,
        title: 'Shipping Settings Not Save in ShippoProvder',
        description: 'Api Call from putShippingSettings() method failed',
        component: 'ShippoProvider -> saveSettings()',
      });
      setIsSetupNeeded(true);
    }

    setIsLoading(false);
  }, [connection, settings]);

  return (
    <ShippoContext.Provider
      value={{
        settings,
        setSettings,
        isLoading,
        isConnected: !!connection,
        isSetupNeeded,
        errors,
        setErrors,
        connection,
        saveSettings,
        fileTypes,
      }}
    >
      {children}
    </ShippoContext.Provider>
  );
};

interface ShippoProviderProps {
  children: ReactNode;
}

export const useInternationalSettings = (): {
  settings: ShippingInternationalSettings;
  setSettings: React.Dispatch<
    React.SetStateAction<ShippingInternationalSettings>
  >;
  errors: Errors;
} => {
  const { settings, setSettings, errors } = useContext(ShippoContext);

  const setInternationalSettings = useCallback(
    (
      internationalSettings: React.SetStateAction<ShippingInternationalSettings>
    ) => {
      if (typeof internationalSettings === 'function') {
        setSettings((old) => ({
          ...old,
          internationalSettings: internationalSettings(
            old.internationalSettings
          ),
        }));
        return;
      }

      setSettings((old) => ({
        ...old,
        internationalSettings: internationalSettings,
      }));
    },
    [setSettings]
  );

  return {
    settings: settings.internationalSettings,
    setSettings: setInternationalSettings,
    errors,
  };
};

export const useExtraSettings = (): {
  settings: ShippingExtraSettings;
  setSettings: React.Dispatch<React.SetStateAction<ShippingExtraSettings>>;
  errors: Errors;
} => {
  const { settings, setSettings, errors } = useContext(ShippoContext);

  const setExtraSettings = useCallback(
    (extraSettings: React.SetStateAction<ShippingExtraSettings>) => {
      if (typeof extraSettings === 'function') {
        setSettings((old) => ({
          ...old,
          extraSettings: extraSettings(old.extraSettings),
        }));
        return;
      }

      setSettings((old) => ({
        ...old,
        extraSettings: extraSettings,
      }));
    },
    [setSettings]
  );

  return {
    settings: settings.extraSettings,
    setSettings: setExtraSettings,
    errors,
  };
};
