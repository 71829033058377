import { createApiAction } from 'services/api';
import {
  StartAction,
  SuccessAction,
  FailureAction,
} from 'services/api/redux/types';
import {
  createAddSearch,
  createDeleteSearch,
  createFetchSearch,
  createUpdateSearch,
} from 'services/search';

export enum RepresentativesActionTypes {
  FETCH_REPRESENTATIVES_START = 'representatives/fetch_representatives_start',
  FETCH_REPRESENTATIVES_SUCCESS = 'representatives/fetch_representatives_success',
  FETCH_REPRESENTATIVES_FAILURE = 'representatives/fetch_representatives_failure',
}

const fetchRepresentativesActionStart = (): StartAction => ({
  type: RepresentativesActionTypes.FETCH_REPRESENTATIVES_START,
  payload: null,
});

const fetchRepresentativesActionSuccess = (payload: any): SuccessAction => {
  return {
    type: RepresentativesActionTypes.FETCH_REPRESENTATIVES_SUCCESS,
    payload,
  };
};

const fetchRepresentativesActionFailure = (error: string): FailureAction => ({
  type: RepresentativesActionTypes.FETCH_REPRESENTATIVES_FAILURE,
  payload: { error },
});

export const fetchRepresentatives = () =>
  createApiAction(
    {
      path: '/v1/representatives?pageSize=100',
      method: 'GET',
    },
    fetchRepresentativesActionStart,
    fetchRepresentativesActionSuccess,
    fetchRepresentativesActionFailure
  );

export const fetchSearch = createFetchSearch('representative');
export const addSearch = createAddSearch('representative');
export const updateSearch = createUpdateSearch('representative');
export const deleteSearch = createDeleteSearch('representative');
