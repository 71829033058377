import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { TableCell, Box, Typography } from '@mui/material';
import _ from 'lodash';

import { colorPalette } from 'ui/theme';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { ImageItemBox } from 'ui/components/Image/ImageItemBox';

import { getUomById } from 'services/uoms';
import { useCurrencyFormatter } from 'helpers';
import { PermissionType } from 'services/permissions';

import {
  createActionBarOptions,
  SubstituteItemTableRowActions,
} from './consts';
import { SubstituteItem } from 'services/items';

import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';
import { activeUserHasPermission } from 'services/user/redux/selectors';

const SubstituteItemRow: React.FC<RenderCustomRowProps<SubstituteItem>> = (
  props
) => {
  const { row, onAction, index } = props;

  const currencyFormatter = useCurrencyFormatter();

  const defaultUom = useSelector(getUomById(row.substituteItem!.defaultUomId!));

  const formatWHL = `${row.substituteItem!.width}x${
    row.substituteItem!.height
  }x${row.substituteItem!.length} (${row.substituteItem!.weight} ${
    row.substituteItem!.weightUnit
  })`;

  const canEditItem =
    row.id && row.id > 0
      ? [PermissionType.ItemsEdit]
      : [PermissionType.ItemsCreate];

  const hasPermissionToEditItem = useSelector(
    activeUserHasPermission(canEditItem)
  );

  const handleAction = useCallback(
    (actionType: SubstituteItemTableRowActions) => {
      if (onAction) {
        onAction({ type: actionType, itemIndex: index });
      }
    },
    [onAction, index]
  );

  return (
    <>
      <TableCell data-qa={`Name- ${index + 1}`}>
        <ImageItemBox
          name={_.get(row, 'substituteItem.name', null)}
          description={_.get(row, 'substituteItem.description', null)}
          source={_.get(row, 'substituteItem.defaultImage', null)}
        />
      </TableCell>
      <TableCell data-qa={`Uom- ${index + 1}`}>
        <Typography>{defaultUom && defaultUom.name}</Typography>
      </TableCell>
      <TableCell data-qa={`W/H/L(weight)- ${index + 1}`}>
        <Typography>{formatWHL}</Typography>
      </TableCell>
      <TableCell data-qa={`Price-${index + 1}`}>
        <Typography>
          {currencyFormatter(row.substituteItem!.cost || 0)}
        </Typography>
      </TableCell>
      <TableCell>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <FBOMenuButton
            variant="tertiary"
            items={createActionBarOptions(handleAction)}
            disabled={!hasPermissionToEditItem}
            data-qa="substitute-item-three-dot-menu"
            style={{ color: colorPalette.redesign.contentSecondary }}
          />
        </Box>
      </TableCell>
    </>
  );
};

export default memo(SubstituteItemRow);
