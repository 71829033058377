import { CustomField, CustomFieldOption } from './types';

export const initialCustomField: CustomField = {
  id: null,
  checkVersion: false,
  customFieldOptions: [],
  customFieldType: null,
  customFieldValues: null,
  dateCreated: null,
  dateLastModified: null,
  name: null,
  objectType: null,
  required: false,
  version: null,
  lineNumber: null,
};

export const initialCustomFieldOption: CustomFieldOption = {
  id: null,
  checkVersion: false,
  customFieldId: null,
  dateCreated: null,
  dateLastModified: null,
  option: null,
  version: null,
};
