import { Alert, AlertActionType, AlertState, ProgressAlert } from './types';
import { initialAlertState } from './consts';

const reducer = (
  state: AlertState = initialAlertState,
  action: { type: AlertActionType; payload: Alert | ProgressAlert }
) => {
  switch (action.type) {
    case AlertActionType.SHOW_ALERT:
    case AlertActionType.HIDE_ALERT:
      return {
        ...state,
        alert: action.payload as Alert,
      };
    case AlertActionType.SHOW_PROGRESS_ALERT:
      return {
        ...state,
        progressAlert: action.payload as ProgressAlert,
      };
    case AlertActionType.HIDE_PROGRESS_ALERT:
      return {
        ...state,
        progressAlert: {
          ...(action.payload as ProgressAlert),
          backgroundType: state.progressAlert.backgroundType,
        },
      };
    default:
      return state;
  }
};

export default reducer;
