import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useTextFieldWithActionsStyle = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.primary.main,
  },
  label: {
    color: `${theme.palette.text.secondary} !important`,
  },
}));
