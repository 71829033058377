import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import WidgetsIcon from '@mui/icons-material/Widgets';
import clsx from 'clsx';

import { useCurrencyFormatter } from 'helpers';

import { DashboardWidgetsProps } from './types';
import { useDashboardWidgetsStyle } from './styled';
import { formatToPercentage, percentageChange } from '../../helpers';

const DashboardWidgets: React.FC<DashboardWidgetsProps> = (props) => {
  const { data } = props;

  const currencyFormatter = useCurrencyFormatter();

  const classes = useDashboardWidgetsStyle();

  const revenueChange = percentageChange(
    data.previousRevenueTotal,
    data.latestRevenueTotal
  );

  const soChange = percentageChange(data.previousSoTotal, data.latestSoTotal);

  const itemsChange = percentageChange(
    data.previousItemsTotal,
    data.latestItemsTotal
  );

  const expensesChange = percentageChange(
    data.previousExpensesTotal,
    data.latestExpensesTotal
  );

  return (
    <Box className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs>
          <Box className={classes.card}>
            <Box
              className={clsx({
                [classes.cardIcon]: true,
                [classes.cardIconRed]: revenueChange < 0,
              })}
            >
              <MonetizationOnIcon className={classes.icon} />
            </Box>
            <Box className={classes.cardContent}>
              <Box className={classes.cardContentHeader}>
                <Typography className={classes.cardContentLabel}>
                  Revenue
                </Typography>

                <Typography
                  className={clsx({
                    [classes.cardContentPercentage]: true,
                    [classes.cardContentRed]: revenueChange < 0,
                  })}
                >
                  {formatToPercentage(revenueChange)}
                </Typography>
              </Box>
              <Typography className={classes.cardContentValue}>
                {currencyFormatter(data.latestRevenueTotal)}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs>
          <Box className={classes.card}>
            <Box
              className={clsx({
                [classes.cardIcon]: true,
                [classes.cardIconRed]: soChange < 0,
              })}
            >
              <AssignmentTurnedInIcon className={classes.icon} />
            </Box>
            <Box className={classes.cardContent}>
              <Box className={classes.cardContentHeader}>
                <Typography className={classes.cardContentLabel}>
                  Sales orders
                </Typography>
                <Typography
                  className={clsx({
                    [classes.cardContentPercentage]: true,
                    [classes.cardContentRed]: soChange < 0,
                  })}
                >
                  {formatToPercentage(soChange)}
                </Typography>
              </Box>
              <Typography className={classes.cardContentValue}>
                {data.latestSoTotal}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs>
          <Box className={classes.card}>
            <Box
              className={clsx({
                [classes.cardIcon]: true,
                [classes.cardIconRed]: itemsChange < 0,
              })}
            >
              <ShoppingCartIcon className={classes.icon} />
            </Box>
            <Box className={classes.cardContent}>
              <Box className={classes.cardContentHeader}>
                <Typography className={classes.cardContentLabel}>
                  Items sold
                </Typography>
                <Typography
                  className={clsx({
                    [classes.cardContentPercentage]: true,
                    [classes.cardContentRed]: itemsChange < 0,
                  })}
                >
                  {formatToPercentage(itemsChange)}
                </Typography>
              </Box>
              <Typography className={classes.cardContentValue}>
                {data.latestItemsTotal}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs>
          <Box className={classes.card}>
            <Box
              className={clsx({
                [classes.cardIcon]: true,
                [classes.cardIconRed]: expensesChange < 0,
              })}
            >
              <ImportExportIcon className={classes.icon} />
            </Box>
            <Box className={classes.cardContent}>
              <Box className={classes.cardContentHeader}>
                <Typography className={classes.cardContentLabel}>
                  Expenses
                </Typography>
                <Typography
                  className={clsx({
                    [classes.cardContentPercentage]: true,
                    [classes.cardContentRed]: expensesChange < 0,
                  })}
                >
                  {formatToPercentage(expensesChange)}
                </Typography>
              </Box>
              <Typography className={classes.cardContentValue}>
                {currencyFormatter(data.latestExpensesTotal)}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs>
          <Box className={classes.card}>
            <Box
              className={clsx({
                [classes.cardIcon]: true,
                [classes.cardIconRed]: data.lowStock > 0,
              })}
            >
              <WidgetsIcon className={classes.icon} />
            </Box>
            <Box className={classes.cardContent}>
              <Box className={classes.cardContentHeader}>
                <Typography className={classes.cardContentLabel}>
                  Low stock
                </Typography>
              </Box>
              <Typography className={classes.cardContentValue}>
                {data.lowStock}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardWidgets;
