import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Grid, Box, Typography, Button } from '@mui/material';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import _ from 'lodash';

import { Page } from 'ui/components/Page/Page';
import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { AlertModal } from 'ui/components/Modal/AlertModal';

import {
  BackgroundAction,
  BackgroundItem,
  BackgroundStatus,
  clearCompletedDownloads,
  DownloadHistoryRow,
  fetchCSVStatus,
  fetchDownloadHistory,
  fetchUploadHistory,
  useBackgroundTasks,
} from 'services/backgroundTasks';
import { Pagination } from 'services/search';
import { showAlert } from 'services/alert/redux';
import { DataWithPagination } from 'services/api';

import HistoryCustomRow from './HistoryCustomRow';
import { useDownloadsPageStyle } from './styled';
import { DownloadsPageCmp } from './types';
import { initialHistoryDownloads, initialHistoryUploads } from './consts';
import { Routes } from '../../navigation';
import { logErrorCtx } from 'app/logging';
import {
  DownloadCompletedItem,
  DownloadFailedItem,
  DownloadInProgressItem,
  DownloadPendingItem,
} from './items';

const orderDateDescending = (
  itemA: BackgroundItem | DownloadHistoryRow,
  itemB: BackgroundItem | DownloadHistoryRow
) => {
  const date1 = moment(itemA.dateCreated);
  const date2 = moment(itemB.dateCreated);
  if (date1.isBefore(date2)) return 1;
  if (date1.isAfter(date2)) return -1;
  return 0;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DownloadsPage: DownloadsPageCmp = () => {
  const classes = useDownloadsPageStyle();

  const { backgroundTasks, startFetching } = useBackgroundTasks();

  const dispatch = useDispatch();

  const [uploadHistory, setUploadHistory] = useState<
    DataWithPagination<DownloadHistoryRow>
  >(initialHistoryUploads);

  const [downloadHistory, setDownloadHistory] = useState<
    DataWithPagination<DownloadHistoryRow>
  >(initialHistoryDownloads);

  const [mergedHistory, setMergedHistory] = useState<
    DataWithPagination<DownloadHistoryRow>
  >(initialHistoryDownloads);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    startFetching();
    (async () => {
      setLoading(true);
      await getUploadHistory(uploadHistory.pagination);
      await getDownloadHistory(downloadHistory.pagination);
      setLoading(false);
    })();
  }, []);

  const completedTasks: BackgroundItem[] = backgroundTasks
    .filter((item) => item.status === BackgroundStatus.Completed)
    .sort(orderDateDescending);
  const inProgressTasks: BackgroundItem[] = backgroundTasks
    .filter((item) => item.status === BackgroundStatus.InProgress)
    .sort(orderDateDescending);
  const pendingTasks: BackgroundItem[] = backgroundTasks
    .filter((item) => item.status === BackgroundStatus.Pending)
    .sort(orderDateDescending);
  const failedTasks: BackgroundItem[] = backgroundTasks
    .filter((item) => item.status === BackgroundStatus.Failed)
    .sort(orderDateDescending);

  const getUploadHistory = useCallback(async (pagination: Pagination) => {
    try {
      const res = await fetchUploadHistory(pagination);
      setUploadHistory((old) => ({
        pagination: res.pagination,
        data: [...old.data, ...res.data],
      }));
    } catch (e) {
      const error = e as Error;
      logErrorCtx('GetUploadHistory failed.', {
        error,
        stackTrace: error.stack,
        title: 'Error when trying to fetch upload history.',
        component: 'DownloadsPage',
      });
    }
  }, []);

  const getDownloadHistory = useCallback(async (pagination: Pagination) => {
    try {
      const res = await fetchDownloadHistory(pagination);

      setDownloadHistory((old) => ({
        pagination: res.pagination,
        data: [...old.data, ...res.data],
      }));
    } catch (e) {
      const error = e as Error;
      logErrorCtx('GetDownloadHistory failed.', {
        error,
        stackTrace: error.stack,
        title: 'Error when trying to fetch download history.',
        component: 'DownloadsPage',
      });
    }
  }, []);

  const handleClearAllDownloads = useCallback(async () => {
    try {
      await clearCompletedDownloads(BackgroundAction.Export);
    } catch (err) {
      const error = err as Error;
      logErrorCtx('Failed to clear downloads queue', {
        error,
        stackTrace: error.stack,
        title: 'Failed to Clear the File Downloads queue',
        description: 'Clear All Button not working as expected',
        component: 'DownloadsPage',
      });
    }
    startFetching();
  }, [startFetching]);

  const isDownloadDataEmpty =
    _.isEmpty(completedTasks) &&
    _.isEmpty(inProgressTasks) &&
    _.isEmpty(pendingTasks) &&
    _.isEmpty(failedTasks);

  const handleHistoryNextPage = useCallback(async () => {
    const { pagination } = mergedHistory;

    if (pagination.totalRows <= pagination.pageSize * pagination.page) {
      return;
    }

    const newPagination: Pagination = {
      ...pagination,
      page: pagination.page + 1,
    };

    await getDownloadHistory(newPagination);
  }, [uploadHistory, downloadHistory]);

  useMemo(() => {
    (async () => {
      const createMergedHistories: {
        data: Array<DownloadHistoryRow>;
        pagination: Pagination;
      } = {
        data: [...downloadHistory.data, ...uploadHistory.data],
        pagination: downloadHistory.pagination,
      };
      if (createMergedHistories.data.length !== mergedHistory.data.length) {
        createMergedHistories.data.sort(orderDateDescending);
        const newMergeHistory = {
          data: createMergedHistories.data,
          pagination: createMergedHistories.pagination,
        };
        setMergedHistory(newMergeHistory);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadHistory, uploadHistory]);

  const onHistoryRowClick = useCallback(
    (action) => {
      if (action.status === BackgroundStatus.Failed && !action.url) {
        (async () => {
          const res = fetchCSVStatus(action.id);
          res.then((output) => {
            dispatch(
              showAlert(
                action.message || 'There was an error in your file',
                output.errorLogUrl
              )
            );
          });
        })();
      } else if (
        action.status === BackgroundStatus.PartialSuccess &&
        !action.url
      ) {
        (async () => {
          const res = fetchCSVStatus(action.id);
          res.then((output) => {
            dispatch(
              showAlert(action.message || 'Partial Success', output.errorLogUrl)
            );
          });
        })();
      } else {
        dispatch(
          showAlert(action.message || 'No message available', action.url)
        );
      }
    },
    [dispatch]
  );

  return (
    <Page className={classes.page}>
      <Box display="flex" width="100%" justifyContent="space-around">
        <Box mb={4} className={classes.container}>
          <Typography variant="h4">Downloads</Typography>
        </Box>
        <Box mb={4} className={classes.container}>
          <Typography variant="h4">History</Typography>
        </Box>
      </Box>
      <Box display="flex">
        <Box className={classes.containerBox}>
          {isDownloadDataEmpty && (
            <Grid container spacing={2} className={classes.container}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                flex={1}
                width="45%"
              >
                <FolderOpenOutlinedIcon className={classes.noDownloadsIcon} />
                <Typography variant="h4">
                  Your downloads will appear here.
                </Typography>
              </Box>
            </Grid>
          )}
          {!_.isEmpty(pendingTasks) && (
            <>
              <Grid container spacing={2} className={classes.container}>
                <Grid item xs={12}>
                  <Box mb={1} className={classes.titleBox}>
                    <Typography variant="h5">Pending</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  {pendingTasks.map((item: BackgroundItem) => (
                    <DownloadPendingItem key={item.id} item={item} />
                  ))}
                </Grid>
              </Grid>
              <Box mb={8} />
            </>
          )}
          {!_.isEmpty(inProgressTasks) && (
            <>
              <Grid container spacing={2} className={classes.container}>
                <Grid item xs={12}>
                  <Box mb={1} className={classes.titleBox}>
                    <Typography variant="h5">In Progress</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  {inProgressTasks.map((item: BackgroundItem) => (
                    <DownloadInProgressItem key={item.id} item={item} />
                  ))}
                </Grid>
              </Grid>
              <Box mb={8} />
            </>
          )}
          {!_.isEmpty(completedTasks) && (
            <Grid container spacing={2} className={classes.container}>
              <Grid item xs={12}>
                <Box mb={1} className={classes.titleBox}>
                  <Typography variant="h5">Completed</Typography>
                  {completedTasks.length > 0 && (
                    <Button
                      variant="outlined"
                      onClick={handleClearAllDownloads}
                    >
                      Clear All
                    </Button>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12}>
                {completedTasks.map((item: BackgroundItem) => (
                  <DownloadCompletedItem key={item.id} item={item} />
                ))}
              </Grid>
            </Grid>
          )}
          {!_.isEmpty(failedTasks) && (
            <Grid container spacing={2} className={classes.container}>
              <Grid item xs={12}>
                <Box mb={1} className={classes.titleBox}>
                  <Typography variant="h5">Failed</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {failedTasks.map((item: BackgroundItem) => (
                  <DownloadFailedItem key={item.id} item={item} />
                ))}
              </Grid>
            </Grid>
          )}
        </Box>
        <Box height="720px" className={classes.historyTable}>
          <ItemsTable
            data={mergedHistory.data}
            columns={[]}
            emptyTableText={'NO DATA TO DISPLAY'}
            selectableItems={false}
            isLoadingData={loading}
            onScrollNextPage={handleHistoryNextPage}
            RenderCustomRow={HistoryCustomRow}
            onAction={onHistoryRowClick}
          />
        </Box>
        <AlertModal />
      </Box>
    </Page>
  );
};

DownloadsPage.route = Routes.DownloadsPage;

export default DownloadsPage;
