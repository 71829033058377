import React, { memo } from 'react';
import { Box, Divider } from '@mui/material';
import { GridDividerProps } from './types';
import { theme } from '../../theme';

/**
 *
 * @return {JSX.Element}
 * @constructor
 * @param fullWidthOffset
 * @param top
 * @param bottom
 * @param transparent
 */
const GridDivider = ({
  fullWidthOffset,
  top,
  bottom,
  transparent,
}: GridDividerProps) => {
  const fullWidth = fullWidthOffset
    ? {
        marginLeft: theme.spacing(-fullWidthOffset),
        width: `calc(100% + ${theme.spacing(fullWidthOffset * 2)})`,
      }
    : undefined;

  return (
    <Box mt={top || 5} mb={bottom || 6}>
      <Divider
        style={{
          ...fullWidth,
          backgroundColor: transparent ? 'transparent' : undefined,
        }}
      />
    </Box>
  );
};

export default memo(GridDivider);
