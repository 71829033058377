import { FetchSearchResultFunc } from 'ui/components/Page/WithSearchResults';

export interface ReceivingDetailsCardProps {
  activeReceiptId: number | null;
  fetchSearchResult: FetchSearchResultFunc;
  onClose(): void;
}

export enum ThreeDotMenuActions {
  Hold,
  Receive,
  Void,
  Reject,
  ShowTracking,
  Fulfill,
}
