import React, {
  memo,
  useMemo,
  useCallback,
  useRef,
  useEffect,
  useState,
} from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import _ from 'lodash';

import { replaceValueInCollection } from 'helpers';
import { DatePickerWrapper } from 'ui/components/TextField/DatePickerWrapper';
import { TextField } from 'ui/components/TextField/TextField';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { TextFieldAddress } from 'ui/components/TextField/TextFieldAddress';
import { VendorAutocomplete } from 'ui/components/Autocomplete/VendorAutocomplete';
import { UsersAutocomplete } from 'ui/components/Autocomplete/UsersAutocomplete';
import { AddressValidationModal } from 'ui/components/Modal/AddressModal/components';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import { getUsers, User } from 'services/user';
import { getUsers as getUsersV2, User as UserV2 } from 'services/userV2';
import { getLocations, Location, LocationType } from 'services/locations';
import { getCarriers, Carrier, CarrierService } from 'services/carriers';
import { useHandleTextFieldChange } from 'services/forms';
import { PurchaseOrder, PurchaseOrderStatus } from 'services/purchaseOrders';
import { showAlert } from 'services/alert/redux';
import { getVendors, Vendor } from 'services/vendors';
import { getShippingIntegrationConnection } from 'services/integrations/shipping/redux';
import {
  AddressValidation,
  BillToShipToAddress,
  initalValidAddress,
} from 'services/addresses';
import {
  CustomField,
  fetchCustomFieldsAPI,
  ObjectType,
  useCustomFields,
} from 'services/customFields';
import {
  getValidateAddress,
  ShippingConnectionType,
} from 'services/integrations/shipping';
import { showNotification } from 'services/api';

import { GeneralTabProps } from './types';
import { usePurchaseOrderGeneralTabStyles } from './styled';
import { createCustomShipToActionItems } from './consts';
import {
  calculatePoVendorFields,
  transformShipToAddress,
} from './transformations';
import {
  getBillToAddress,
  getShipToAddress,
  setShipToAsBillToAddress,
  setVerifiedBillToAddress,
  setVerifiedShipToAddress,
} from './helpers';
import { PurchaseOrderActiveIdState } from '../../types';
import {
  editPurchaseOrderPermissions,
  useDisablePurchaseOrderField,
} from '../../helpers';
import { logErrorCtx } from 'app/logging';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOCustomFields from 'ui/components/CustomFields/CustomFields/FBOCustomFields';
import { useFlags } from 'helpers/useFlags';
import { UsersAutocompleteV2 } from 'ui/components/Autocomplete/UsersAutocompleteV2';

const FBOGeneralTab: React.FC<GeneralTabProps> = (props) => {
  const {
    purchaseOrder,
    validationErrors,
    setPurchaseOrder,
    customFieldsErrors,
    oldState,
    isLoading,
    setIsLoading,
  } = props;

  const classes = usePurchaseOrderGeneralTabStyles();

  const dispatch = useDispatch();

  const { items: vendors } = useSelector(getVendors);
  const { items: carriers } = useSelector(getCarriers);
  const { items: locations } = useSelector(getLocations);
  const flags = useFlags();
  const users = useSelector(getUsers);
  const usersV2 = useSelector(getUsersV2);
  const connection = useSelector(
    getShippingIntegrationConnection(ShippingConnectionType.Shippo)
  );
  const disableField = useDisablePurchaseOrderField(purchaseOrder.status);
  const setCustomFields = useCustomFields<PurchaseOrder>(setPurchaseOrder);

  const firstInputElement = useRef<HTMLInputElement>(null);
  const secondInputElement = useRef<HTMLInputElement>(null);

  const [addressValidation, setAddressValidation] =
    useState<AddressValidation>(initalValidAddress);
  const [addressValidationModalVisible, setAddressValidationModalVisible] =
    useState<boolean>(false);

  const isFulfilled = purchaseOrder.status === PurchaseOrderStatus.Fulfilled;

  const isPOIssued = [
    PurchaseOrderStatus.Issued,
    PurchaseOrderStatus.Received,
    PurchaseOrderStatus.PartiallyReceived,
    PurchaseOrderStatus.Fulfilled,
    PurchaseOrderStatus.PartiallyFulfilled,
    PurchaseOrderStatus.Reconciled,
    PurchaseOrderStatus.PartiallyReconciled,
    PurchaseOrderStatus.Picking,
    PurchaseOrderStatus.Picked,
    PurchaseOrderStatus.PartiallyPicked,
  ].includes(purchaseOrder.status);

  const editPermission = editPurchaseOrderPermissions(purchaseOrder);

  const shipToAddressTransformed = transformShipToAddress(purchaseOrder);

  const selectedBuyer = useMemo(() => {
    return users.find((c) => c.id === purchaseOrder.buyerId) || null;
  }, [purchaseOrder, users]);

  const selectedBuyerV2 = useMemo(() => {
    return usersV2.find((c) => c.id === purchaseOrder.buyerId) || null;
  }, [purchaseOrder, users]);

  const selectedVendor = useMemo(() => {
    return vendors.find((c) => c.id === purchaseOrder.vendorId) || null;
  }, [purchaseOrder, vendors]);

  const selectedCarrier = useMemo(() => {
    return carriers.find((c) => c.id === purchaseOrder.carrierId) || null;
  }, [purchaseOrder, carriers]);

  const selectedService = useMemo(() => {
    if (!selectedCarrier) {
      return null;
    }
    return (
      selectedCarrier.carrierServiceList.find(
        (s) => s.id === purchaseOrder.carrierServiceId
      ) || null
    );
  }, [purchaseOrder, selectedCarrier]);

  const rootLocations = useMemo(() => {
    return locations.filter((l) => !l.parentLocationId);
  }, [locations]);

  useEffect(() => {
    if (purchaseOrder.id !== -1 && firstInputElement.current !== null) {
      firstInputElement.current.focus();
    } else if (purchaseOrder.id === -1 && secondInputElement.current !== null) {
      secondInputElement.current.focus();
    }
  }, [purchaseOrder.id]);

  const customFieldChanged = useCallback(
    (customField: CustomField) => {
      const index = purchaseOrder.customFields.findIndex(
        (p) => p.id === customField.id
      );
      setCustomFields(
        (old) => replaceValueInCollection<CustomField>(old, customField, index)!
      );
    },
    [purchaseOrder.customFields, setCustomFields]
  );

  const setShippingSameAsBilling = useCallback(() => {
    setShipToAsBillToAddress(setPurchaseOrder);
  }, [setPurchaseOrder]);

  const customShipToActionItems = useMemo(
    () =>
      createCustomShipToActionItems(
        setShippingSameAsBilling,
        purchaseOrder.status
      ),
    [setShippingSameAsBilling, purchaseOrder.status]
  );

  const billToAddress: BillToShipToAddress = useMemo(
    () => getBillToAddress(purchaseOrder),
    [purchaseOrder]
  );

  const shipToAddress: BillToShipToAddress = useMemo(
    () => getShipToAddress(purchaseOrder, selectedVendor),
    [purchaseOrder, selectedVendor]
  );

  const verifiedButtonVisible =
    !!connection && shipToAddress.name && shipToAddress.country === 'US';

  const addresses = useMemo(() => {
    const vendorAddresses = (selectedVendor && selectedVendor.addresses) || [];
    const locationAddresses =
      rootLocations && rootLocations.map((c) => c.address);
    return [...vendorAddresses, ...locationAddresses];
  }, [selectedVendor, rootLocations]);

  useEffect(() => {
    // Fetch customFields and add them to state
    if (purchaseOrder.id === PurchaseOrderActiveIdState.New) {
      (async () => {
        const customFields = (
          await fetchCustomFieldsAPI({}, ObjectType.PurchaseOrder)
        ).data;
        if (oldState.current) {
          oldState.current.customFields = customFields;
        }
        setCustomFields(customFields);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseOrder.id]);

  const handleTextFieldChange = useHandleTextFieldChange<PurchaseOrder>(
    setPurchaseOrder,
    purchaseOrder
  );

  const handleBuyerChange = useCallback(
    (user: User | null) => {
      setPurchaseOrder((old) => ({ ...old, buyerId: user ? user.id : null }));
    },
    [setPurchaseOrder]
  );

  const handleBuyerChangeV2 = useCallback(
    (user: UserV2 | null) => {
      setPurchaseOrder((old) => ({ ...old, buyerId: user ? user.id : null }));
    },
    [setPurchaseOrder]
  );

  const handleVendorChange = useCallback(
    (vendor: Vendor | null) => {
      setPurchaseOrder((old) => calculatePoVendorFields(old, vendor));
      if (vendor && vendor.alertNotes) {
        dispatch(showAlert(vendor.alertNotes));
      }
    },
    [setPurchaseOrder, dispatch]
  );

  const handleDateChange = useCallback(
    (name: string) => (value: any) => {
      const date = moment(value);
      setPurchaseOrder((old) => ({
        ...old,
        [name]: !value ? null : date.toDate(),
      }));
    },
    [setPurchaseOrder]
  );

  const handleCarrierChange = useCallback(
    (e: React.ChangeEvent<{}>, carrier: Carrier | null) => {
      setPurchaseOrder((old) => ({
        ...old,
        carrierId: carrier ? carrier.id : null,
        carrierServiceId: null,
      }));
    },
    [setPurchaseOrder]
  );

  const handleBillToChange = useCallback(
    (adr: BillToShipToAddress) => {
      setPurchaseOrder((old) => ({
        ...old,
        vendorAddressCity: adr.city,
        vendorAddressCountry: adr.country,
        vendorAddressName: adr.name,
        vendorAddressPostalCode: adr.postalCode,
        vendorAddressState: adr.state,
        vendorAddressStreet: adr.street,
        vendorAddressStreet2: adr.street2,
        vendorCompanyName: adr.companyName,
        vendorResidential: adr.residential,
        vendorAttention: adr.attention,
        vendorAddressVerified: adr.verified,
      }));
    },
    [setPurchaseOrder]
  );

  const handleShipToChange = useCallback(
    (adr: BillToShipToAddress) => {
      setPurchaseOrder((old) => ({
        ...old,
        shipToCity: adr.city,
        shipToCountry: adr.country,
        shipToName: adr.name,
        shipToPostalCode: adr.postalCode,
        shipToState: adr.state,
        shipToStreet: adr.street,
        shipToStreet2: adr.street2,
        shipToCompanyName: adr.companyName,
        shipToResidential: adr.residential,
        shipToAttention: adr.attention,
        shipToVerified: adr.verified,
      }));
    },
    [setPurchaseOrder]
  );

  const handleServiceChange = useCallback(
    (e: React.ChangeEvent<{}>, service: CarrierService | null) => {
      setPurchaseOrder((old) => ({
        ...old,
        carrierServiceId: service ? service.id : null,
      }));
    },
    [setPurchaseOrder]
  );

  const handleLocationChange = useCallback(
    (location: Location | null) => {
      setPurchaseOrder((old) => ({
        ...old,
        locationId: location ? location.id : null,
        shipToName: _.get(location, 'address.name'),
        shipToStreet: _.get(location, 'address.street'),
        shipToStreet2: _.get(location, 'address.street2'),
        shipToCity: _.get(location, 'address.city'),
        shipToState: _.get(location, 'address.state'),
        shipToCountry: _.get(location, 'address.country'),
        shipToPostalCode: _.get(location, 'address.postalCode'),
        shipToCompanyName: _.get(location, 'address.companyName'),
        shipToResidential: _.get(location, 'address.residential'),
        shipToAttention: _.get(location, 'address.attention'),
        shipToVerified: _.get(location, 'address.verified'),
      }));
    },
    [setPurchaseOrder]
  );

  const handleAddressValidationModalClose = () => {
    setAddressValidationModalVisible(false);
  };

  const onSaveVerified = useCallback(() => {
    setIsLoading(true);

    if (_.isEqual(billToAddress, shipToAddress)) {
      setVerifiedShipToAddress(addressValidation, setPurchaseOrder);

      setVerifiedBillToAddress(addressValidation, setPurchaseOrder);

      setAddressValidationModalVisible(false);
      showNotification('Bill to and ship to address validated', {
        variant: 'success',
      });

      setIsLoading(false);
      return;
    }

    setVerifiedShipToAddress(addressValidation, setPurchaseOrder);

    setAddressValidationModalVisible(false);

    showNotification('Ship to address validated', { variant: 'success' });
    setIsLoading(false);
  }, [
    setPurchaseOrder,
    setIsLoading,
    addressValidation,
    billToAddress,
    shipToAddress,
  ]);

  const handleVerifyClick = useCallback(async () => {
    if (!connection) {
      return;
    }

    setIsLoading(true);

    try {
      const validatedAddress = await getValidateAddress(
        connection,
        shipToAddressTransformed
      );

      if (!shipToAddress.verified) {
        setAddressValidationModalVisible(true);
      }

      setAddressValidation(validatedAddress);
    } catch (error) {
      logErrorCtx('Error in getValidateAddress', {
        error: error as Error,
        title: 'Error validating address in Purhcase Order',
        component: 'PurchaseOrderDetailsCard -> GeneralTab',
      });
    }

    setIsLoading(false);
  }, [
    shipToAddressTransformed,
    shipToAddress.verified,
    connection,
    setIsLoading,
  ]);

  return (
    <>
      <Grid
        sx={{ overflowY: 'scroll', height: '31%', padding: '16px' }}
        container
        columnSpacing="16px"
        disableEqualOverflow
      >
        <Grid xs={3}>
          <VendorAutocomplete
            onChange={handleVendorChange}
            value={selectedVendor}
            placeholder="Select vendor"
            label="Vendor"
            required
            disabled={isPOIssued}
            additionalInputProps={{ inputRef: secondInputElement }}
            permissions={editPermission}
            error={!!validationErrors.vendorId}
            expands={[
              'vendorAddresses.vendorAddressContacts',
              'vendorItems.item.images',
              'tags',
              'currency',
            ]}
            dataQa="purchase-order-vendor"
          />
        </Grid>
        <Grid xs={2}>
          <TextField
            variant="standard"
            className="redesign"
            type="text"
            label="Order No."
            placeholder="Enter number"
            name="number"
            value={purchaseOrder.number}
            fullWidth
            permissions={editPermission}
            onChange={handleTextFieldChange}
            disabled={isPOIssued}
            dataQa="purchase-order-no"
          />
        </Grid>
        <Grid xs={3}>
          <LocationsAsyncAutocomplete
            label="Location"
            placeholder="Select location"
            permissions={editPermission}
            onChange={handleLocationChange}
            value={purchaseOrder ? purchaseOrder.locationId : null}
            error={!!validationErrors.locationId}
            companyWide={false}
            required
            disabled={isPOIssued}
            dataQa="location-picker"
            parentId={null}
            locationTypes={[
              LocationType.Stock,
              LocationType.Receiving,
              LocationType.Shipping,
            ]}
          />
        </Grid>
        <Grid xs={2}>
          <TextField
            variant="standard"
            className="redesign"
            type="text"
            label="Customer SO"
            placeholder="Enter customer SO"
            name="customerSONumber"
            value={purchaseOrder.customerSONumber}
            fullWidth
            permissions={editPermission}
            onChange={handleTextFieldChange}
            disabled={disableField('customerSONumber')}
            dataQa="purchase-order-customer-so"
          />
        </Grid>
        <Grid xs={2}>
          <TextField
            variant="standard"
            className="redesign"
            type="text"
            label="Vendor SO"
            placeholder="Enter vendor SO"
            name="vendorSONumber"
            value={purchaseOrder.vendorSONumber}
            fullWidth
            onChange={handleTextFieldChange}
            disabled={disableField('vendorSONumber')}
            permissions={editPermission}
            dataQa="purchase-order-vendor-so"
          />
        </Grid>
        <Grid xs={3}>
          {flags.newUserEndpoint ? (
            <UsersAutocompleteV2
              required
              label="Buyer"
              value={selectedBuyerV2}
              placeholder="Select buyer"
              onChange={handleBuyerChangeV2}
              dataQa="purchase-order-buyer"
              disabled={isFulfilled}
            />
          ) : (
            <UsersAutocomplete
              required
              label="Buyer"
              value={selectedBuyer}
              placeholder="Select buyer"
              onChange={handleBuyerChange}
              dataQa="purchase-order-buyer"
              disabled={isFulfilled}
            />
          )}
        </Grid>
        <Grid xs={2}>
          <DatePickerWrapper
            className={classes.fullWidth}
            value={purchaseOrder.dateScheduled}
            label="Scheduled"
            placeholder="Select date scheduled"
            name="dateScheduled"
            onChange={handleDateChange('dateScheduled')}
            disabled={isPOIssued}
            permissions={editPermission}
            inputProps={{ 'data-qa': `purchase-order-scheduled` } as any}
            error={!!validationErrors.dateScheduled}
          />
        </Grid>
        <Grid xs={3}>
          <Autocomplete
            className="redesign"
            label="Carrier"
            name="carrierId"
            value={selectedCarrier}
            placeholder="Select carrier"
            options={carriers}
            disabled={isFulfilled}
            additionalInputProps={{ inputRef: firstInputElement }}
            getOptionLabel={(option: Carrier) =>
              option.name || 'Unknown Carrier'
            }
            onChange={handleCarrierChange}
            permissions={editPermission}
            dataQa="purchase-order-carrier"
            autoSelect={false}
          />
        </Grid>
        <Grid xs={4}>
          <Autocomplete
            className="redesign"
            label="Service"
            name="service"
            value={selectedService}
            permissions={editPermission}
            disabled={isFulfilled}
            placeholder="Select service"
            options={selectedCarrier ? selectedCarrier.carrierServiceList : []}
            getOptionLabel={(option: CarrierService) =>
              option.name || 'Unknown service'
            }
            onChange={handleServiceChange}
            dataQa="purchase-order-service"
            autoSelect={false}
          />
        </Grid>
        <Grid xs={5}>
          <TextFieldAddress
            label="Bill To"
            value={billToAddress}
            addressChoices={addresses}
            placeholder="Select vendor address"
            permissions={editPermission}
            onChange={handleBillToChange}
            disabled={isFulfilled}
            vendorId={purchaseOrder.vendorId}
            dataQa="purchase-order-bill-to"
          />
        </Grid>
        <Grid xs={verifiedButtonVisible ? 6 : 7}>
          <TextFieldAddress
            label="Ship To"
            value={shipToAddress}
            permissions={editPermission}
            addressChoices={addresses}
            placeholder="Select ship to address"
            onChange={handleShipToChange}
            disabled={isFulfilled}
            vendorId={purchaseOrder.vendorId}
            additionalActionItems={customShipToActionItems}
            dataQa="purchase-order-ship-to"
          />
        </Grid>
        {verifiedButtonVisible && (
          <Grid
            xs={1}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <>
              {!shipToAddress.verified ? (
                <FBOButton
                  variant="secondary"
                  color="positive"
                  size="medium"
                  onClick={handleVerifyClick}
                  disabled={isLoading}
                  fullWidth
                  data-qa="verify-PO-shippo-address"
                >
                  Verify
                </FBOButton>
              ) : (
                <VerifiedUserIcon sx={{ color: 'green' }} />
              )}
            </>
          </Grid>
        )}
        {purchaseOrder.customFields.length > 0 && (
          <FBOCustomFields
            customFields={purchaseOrder.customFields}
            onFieldChange={customFieldChanged}
            errors={customFieldsErrors}
            permissions={editPermission}
            disabled={isFulfilled}
          />
        )}
      </Grid>
      <AddressValidationModal
        onClose={handleAddressValidationModalClose}
        modalVisible={addressValidationModalVisible}
        addressValidation={addressValidation}
        onSaveOriginal={handleAddressValidationModalClose}
        onSaveVerified={onSaveVerified}
      />
    </>
  );
};

export default memo(FBOGeneralTab);
