import React from 'react';
import {
  AmazonColorLogoSmall,
  BigCommerceColorLogoSmall,
  EbayColorLogoSmall,
  ShopifyColorLogoSmall,
  WalmartColorLogoSmall,
  WooCommerceColorLogoSmall,
} from 'ui/theme';
import { ChannelVendors } from '../../types';

const VendorIcon = (props: any) => {
  const { row, ...other } = props;
  switch (row.vendor) {
    case ChannelVendors.Amazon:
      return <AmazonColorLogoSmall {...other} />;
    case ChannelVendors.BigCommerce:
      return <BigCommerceColorLogoSmall {...other} />;
    case ChannelVendors.Ebay:
      return <EbayColorLogoSmall {...other} />;
    case ChannelVendors.Shopify:
      return <ShopifyColorLogoSmall {...other} />;
    case ChannelVendors.Walmart:
      return <WalmartColorLogoSmall {...other} />;
    case ChannelVendors.WooCommerce:
      return <WooCommerceColorLogoSmall {...other} />;
    default:
      return <></>;
  }
};

export default VendorIcon;
