import React, { memo, useMemo } from 'react';
import { Box } from '@mui/material';

import { Item, SubstituteItem } from 'services/items';
import { Modal } from 'ui/components/Modal/Modal';
import { ItemsAutocomplete } from 'ui/components/Autocomplete/ItemsAutocomplete';

import { SubstituteItemAddProps } from './types';

const SubstituteItemAddModal: React.FC<SubstituteItemAddProps> = (props) => {
  const { show, item, setSubstituteItem, onCancelClicked, onApplyClicked } =
    props;

  const handleSubstituteItemChange = (selectedItem: Item | null) => {
    setSubstituteItem((old: SubstituteItem) => ({
      ...old,
      itemId: item ? item.id : null,
      substituteItem: selectedItem,
      substituteItemId: selectedItem ? selectedItem.id : null,
    }));
  };

  const filteredArray = useMemo(() => {
    return item.itemSubstitutes.map((item) => {
      return item.substituteItem!.id!;
    });
  }, [item.itemSubstitutes]);

  return (
    <Modal
      open={show}
      title="Add Substitute Item"
      applyLabel="Add"
      maxWidth="xs"
      isLoading={false}
      onApplyClicked={onApplyClicked}
      onCancelClicked={onCancelClicked}
      dataQa="substitute-item"
    >
      <Box>
        <ItemsAutocomplete
          disableAdd
          removedIds={[...filteredArray, item.id!]}
          onChange={handleSubstituteItemChange}
          value={null}
          placeholder="Select item as Substitute item"
          dataQa="substitute-item-autocomplete"
          expands={['images']}
        />
      </Box>
    </Modal>
  );
};

export default memo(SubstituteItemAddModal);
