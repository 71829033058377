import {
  ChannelSettings,
  OrderNumberAssignment,
} from 'services/commerce/channels';

export const INPUT_WIDTH = '50%';

export const initialChannelSettings: ChannelSettings = {
  orderSettings: {
    orderNumberAssignment: null,
    orderNumberPrefix: null,
    defaultLocation: null,
    shippingItem: null,
    taxItem: null,
    status: null,
    shippingTerms: null,
    accountingClass: null,
  },
  productImportSettings: {
    accountingClass: null,
    uom: null,
    dimensionUom: null,
    weightUom: null,
  },
  customerSettings: {
    defaultPaymentTerms: null,
    priority: null,
    salesRepresentative: null,
  },
  posSettings: {
    defaultLocation: null,
    taxItem: null,
    shippingItem: null,
    shippingTerms: null,
    status: null,
    orderNumberAssignment: null,
  },
};

export const ORDER_NUMBER_ASSIGNMENT_OPTIONS = [
  OrderNumberAssignment.Primary,
  OrderNumberAssignment.Linked,
  OrderNumberAssignment.Alternate,
];
