import { InputProps } from '@mui/material';
import { PermissionType } from 'services/permissions';

import { ChannelTaxType, TaxRate } from 'services/taxRates';

export interface TaxRatesAutocompleteProps {
  onChange: (vendor: TaxRate | null) => void;
  dataQa?: string;
  value: TaxRate | number | null;
  label?: string;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  permissions?: PermissionType[];
  additionalInputProps?: InputProps;
  helperText?: string;
  taxRateVariant?: TaxRateVariants;
  channelTaxType?: ChannelTaxType;
}

export enum TaxRateVariants {
  Percentage,
  FlatRate,
  All,
}
