import { useState, useRef } from 'react';
import { LongPollingProps, LongPollingState, IntervalId } from './types';

const DEFAULT_DELAY = 10_000; // milliseconds
const DEFAULT_INTERVAL = 1_000; // milliseconds

const defaultState = {
  isPolling: false,
  notify: false,
  timestamp: 0,
  intervalId: undefined,
};

// Wrapper method to provide access to current actual values
//  when inside the setInterval or setTimeout global methods.
//  This is not optional, as the variable states will be locked
//  in a "stale closure" otherwise.
//
// Links:
//  - https://stackoverflow.com/questions/65253665/settimeout-for-this-state-vs-usestate/66435915#66435915
const useStateAndRef = <T>(initial: T) => {
  const [state, setState] = useState<T>(initial);
  const stateRef = useRef<T>(state);

  stateRef.current = state;

  return { state, setState, stateRef };
};

export const useLongPolling = (props?: LongPollingProps) => {
  const { state, setState, stateRef } = useStateAndRef<LongPollingState>({
    ...defaultState,
  });

  const timeHasExpired = (): boolean => {
    const state = stateRef.current;

    if (state.timestamp) {
      const timeSpan = props?.delay || DEFAULT_DELAY;
      return state.timestamp + timeSpan < Date.now();
    }

    return true;
  };

  const pollChecking = () => {
    const state = stateRef.current;

    if (state.isPolling && state.timestamp > 0) {
      if (timeHasExpired()) {
        clearInterval(state.intervalId);
        setState((s) => ({
          ...s,
          intervalId: undefined,
          isPolling: false,
          timestamp: 0,
          notify: true,
        }));
      }
    }
  };

  const startPolling = () => {
    const state = stateRef.current;

    if (!state.isPolling && state.timestamp <= 0) {
      const id: IntervalId = setInterval(pollChecking, DEFAULT_INTERVAL);
      setState((s) => ({
        ...s,
        intervalId: id,
        timestamp: Date.now(),
        isPolling: true,
        notify: false,
      }));
    }
  };

  const stopPolling = () => {
    const state = stateRef.current;

    if (state.isPolling && state.timestamp > 0) {
      clearInterval(state.intervalId);
      setState((s) => ({
        ...s,
        intervalId: undefined,
        isPolling: false,
        timestamp: 0,
        notify: false,
      }));
    }
  };

  const clearNotify = () => setState((s) => ({ ...s, notify: false }));

  return {
    isPolling: state.isPolling,
    notify: state.notify,
    clearNotify,
    startPolling,
    stopPolling,
  };
};
