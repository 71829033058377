import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import _ from 'lodash';

import { Page } from 'ui/components/Page/Page';
import { FBOFlowCard } from './FBOFlowCard';
import { FBOFlowContainer } from './FBOFlowContainer';
import { IconNames } from 'ui/theme';

import { useFlowPageStyle } from './styled';
import { Routes } from '../../navigation';
import { getActiveUser } from 'services/user';
import { Icon } from 'ui/components/Icon';

export interface FBOFlowPageProps {}
export interface FBOFlowPageCmp
  extends React.FunctionComponent<FBOFlowPageProps> {
  route: string;
}

export const FBOFlowPage: FBOFlowPageCmp = () => {
  const classes = useFlowPageStyle();

  const { permissions, isAdmin } = useSelector(getActiveUser);

  const hasPermissions = !_.isEmpty(permissions);

  return (
    <Box>
      <Page>
        {!isAdmin && !hasPermissions && (
          <Typography className={classes.accountWarning}>
            There was an error with your account. Contact your account
            administrator for more information.
          </Typography>
        )}
        <Box className={classes.redesignFlowPage}>
          {/* Materials */}
          <FBOFlowContainer
            iconName={IconNames.FBOFlowMaterials}
            title="Materials"
            description="Manage Items and Inventory"
          >
            <FBOFlowCard
              title="Items"
              link="/materials/items"
              description="Manage Items, Sale Items, and Reorder Points for all of your item types"
              dataQa="flow-Items"
            />
            <Icon name={IconNames.FBOCaratRight}></Icon>
            <FBOFlowCard
              title="Inventory"
              link="/materials/tracking"
              description="Add, remove, move, and cycle Inventory and manage/view tracking for all of your inventory type items"
              dataQa="flow-inventory"
            />
          </FBOFlowContainer>

          {/* Sales */}
          <FBOFlowContainer
            iconName={IconNames.FBOFlowSales}
            title="Sales"
            description="Manage Sales Orders, Picking, Shipping, and Customers"
          >
            <FBOFlowCard
              title="Customers"
              link="/sales/customers"
              description="Create and manage customer details, addresses, and contacts"
              dataQa="flow-customer"
            />
            <Icon name={IconNames.FBOCaratRight}></Icon>
            <FBOFlowCard
              title="Sales Orders"
              link="/sales/sales-order"
              description="Manage Sales Order details and payments"
              dataQa="flow-salesOrder"
            />
            <Icon name={IconNames.FBOCaratRight}></Icon>
            <FBOFlowCard
              title="Picking"
              link="/sales/picking"
              description="Create and manage picks for full and partial orders"
              dataQa="flow-picking"
            />
            <Icon name={IconNames.FBOCaratRight}></Icon>
            <FBOFlowCard
              title="Shipping"
              link="/sales/shipping"
              description="Add and manage cartons. View and print packing lists and shipping labels"
              dataQa="flow-shipping"
            />
          </FBOFlowContainer>

          {/* Purchasing */}
          <FBOFlowContainer
            iconName={IconNames.FBOFlowPurchasing}
            title="Purchasing"
            description="Create and manage Purchase Orders, Vendors, and Receiving details"
          >
            <FBOFlowCard
              title="Vendors"
              link="/purchasing/vendors"
              description="Create and manage vendor details, addresses, and contacts, as well as Vendor Items"
              dataQa="flow-vendors"
            />
            <Icon name={IconNames.FBOCaratRight}></Icon>
            <FBOFlowCard
              title="Purchase Orders"
              link="/purchasing/purchase-order"
              description="Create and manage Purchase Orders"
              dataQa="flow-purchaseOrders"
            />
            <Icon name={IconNames.FBOCaratRight}></Icon>
            <FBOFlowCard
              title="Receiving"
              link="/purchasing/receiving"
              description="Reconcile and receive items and orders"
              dataQa="flow-receiving"
            />
          </FBOFlowContainer>

          {/* Other */}
          <FBOFlowContainer
            iconName={IconNames.FBOFlowOther}
            title="Other"
            description="Use powerful tools and integrations to stay on top of your day-to-day business"
          >
            <FBOFlowCard
              title="Accounting"
              link="/accounting/ledgers"
              description="Integrate with either QuickBooks Online or QuickBooks desktop to ensure all of your accounting records stay up to date"
              dataQa="flow-accounting"
            />
            <Icon name={IconNames.FBOCaratRight}></Icon>
            <FBOFlowCard
              title="Reports"
              link="/reports/reporting"
              description="Choose from dozens of standard reports or customize your own"
              dataQa="flow-reports"
            />
            <Icon name={IconNames.FBOCaratRight}></Icon>
            <FBOFlowCard
              title="Integrations"
              link="/integrations/market"
              description="Choose from dozens of integrations to streamline your business"
              dataQa="flow-integrations"
            />
          </FBOFlowContainer>
        </Box>
      </Page>
    </Box>
  );
};

FBOFlowPage.route = Routes.FlowPage;
