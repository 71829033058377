import { ColumnTypes } from 'ui/components/Table/ItemsTable';
import { Column } from 'ui/components/Table/ItemsTable/types';
import { MenuItem } from 'ui/components/Menu';
import { IconNames } from 'ui/theme';
import { PermissionType } from 'services/permissions';
import { Dates } from 'helpers';

import { ShippingPageAction } from './types';

export const SHIPPING_COLUMNS = (date: Dates): Column[] => {
  const resolveDateColumn = (date: Dates): Column => {
    switch (date) {
      case Dates.DateLastModified:
        return {
          title: 'Date Last Modified',
          field: Dates.DateLastModified,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
        };
      case Dates.DateShipped:
        return {
          title: 'Shipped',
          field: Dates.DateShipped,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
        };
      default:
        return {
          title: 'Date Created',
          field: Dates.DateCreated,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
        };
    }
  };
  return [
    {
      title: 'Ship',
      type: ColumnTypes.renderByCustomRow,
      field: 'number',
      sortable: true,
      width: 100,
    },
    {
      title: 'Status',
      type: ColumnTypes.renderByCustomRow,
      field: 'status',
      sortable: true,
    },
    {
      title: 'Priority',
      type: ColumnTypes.renderByCustomRow,
      field: 'orderPriority.name',
      sortable: true,
    },
    {
      title: 'Order No.',
      type: ColumnTypes.renderByCustomRow,
      field: 'salesOrder.number',
      searchable: false,
      sortable: true,
    },
    {
      title: 'Package(s)',
      type: ColumnTypes.renderByCustomRow,
      searchable: false,
    },
    {
      title: 'Item(s)',
      type: ColumnTypes.renderByCustomRow,
      searchable: false,
    },
    {
      title: 'Ship to',
      type: ColumnTypes.renderByCustomRow,
      field: 'shipToName',
      sortable: true,
    },
    {
      title: 'Carrier',
      type: ColumnTypes.renderByCustomRow,
      field: 'carrier.name',
      sortable: true,
    },
    resolveDateColumn(date),
  ];
};

export const SHIPPING_COLUMNS_SMALL: Column[] = [
  {
    title: 'Ship',
    type: ColumnTypes.renderByCustomRow,
    field: 'number',
  },
  {
    title: 'Status',
    type: ColumnTypes.renderByCustomRow,
    field: 'status',
  },
  {
    title: 'Priority',
    type: ColumnTypes.renderByCustomRow,
    field: 'orderPriority.name',
  },
];

export const shippingPageActionOptions = (
  onActionClick: (action: ShippingPageAction, date?: Dates) => void
): MenuItem[] => [
  {
    label: 'Custom Fields',
    icon: IconNames.FBOReportSmall,
    dataQa: 'custom-fields-button',
    onOptionClick: () => onActionClick(ShippingPageAction.CustomFields),
    permissions: [PermissionType.ShippingEdit],
    separateAfter: true,
  },
  {
    icon: IconNames.FBORecycleSmall,
    label: 'Change Dates',
    dataQa: 'change-dates-button',
    subItems: [
      {
        label: 'Date Created',
        onOptionClick: () =>
          onActionClick(ShippingPageAction.ChangeDate, Dates.DateCreated),
        dataQa: 'date-created',
        separateAfter: true,
      },
      {
        label: 'Date Last Modified',
        onOptionClick: () =>
          onActionClick(ShippingPageAction.ChangeDate, Dates.DateLastModified),
        dataQa: 'date-last-modified',
        separateAfter: true,
      },
      {
        label: 'Date Shipped',
        onOptionClick: () =>
          onActionClick(ShippingPageAction.ChangeDate, Dates.DateShipped),
        dataQa: 'date-shipped',
      },
    ],
  },
];
