import { makeStyles } from '@mui/styles';

export const useAlertProgressModalStyle = makeStyles(() => ({
  progressBar: {
    height: '5px',
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
}));
