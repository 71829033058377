import { TrackingGroup } from 'services/inventory';
import { PickItem } from 'services/picking/types';

export const isTrackingGroupAmountValid = (trackingGroups: TrackingGroup[]) => {
  let valid = true;
  trackingGroups.forEach((t) => {
    if ((t.quantity || 0) > t.onHand) {
      valid = false;
    }
  });

  return valid;
};
export const isTrackingAvailable = (pickItems: PickItem[]) => {
  return pickItems.some(
    (i) =>
      i?.item?.itemTrackingTypeList && i.item.itemTrackingTypeList.length > 0
  );
};
