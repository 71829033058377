import React, { memo, useCallback } from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { TagsAsyncAutocomplete } from 'ui/components/Autocomplete/TagsAutocomplete';
import { SaleItem } from 'services/items/saleItems';
import { useHandleCheckboxChange } from 'services/forms/hooks';
import { Tag } from 'services/tags';

import { ReportFiltersProps } from '../../types';
import { SaleItemAsyncAutocomplete } from 'ui/components/Autocomplete/SaleItemAsyncAutocomplete';

const ItemPriceListFilters: React.FC<ReportFiltersProps> = (props) => {
  const { parameters, setParameters } = props;

  const handleSaleItemChange = useCallback(
    (saleItem: SaleItem | null) => {
      setParameters((old) => ({
        ...old,
        saleItemId: saleItem ? saleItem.id : null,
      }));
    },
    [setParameters]
  );

  const handleTagChange = useCallback(
    (values: Tag[]) => setParameters((old) => ({ ...old, tags: values })),
    [setParameters]
  );

  const handleSelectAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setParameters((old) => ({
        ...old,
        groupBy: checked,
        showSku: checked,
        showUpc: checked,
        selectAllOptional: checked,
      }));
    },
    [setParameters]
  );

  const handleCheckboxChange = useHandleCheckboxChange(setParameters);
  // TODO : RESTYLING : Remove box wrappers around inputs after FF removal

  return (
    <>
      <Box px={2} pb={0} pt="16px">
        <FormControlLabel
          className="redesign"
          control={
            <Checkbox
              className="redesign"
              checked={Boolean(parameters.selectAllOptional)}
              onChange={handleSelectAll}
              color="primary"
            />
          }
          label="Select All Optional"
        />
      </Box>
      <Accordion square defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <b>Optional</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%">
            <Box mb={1}>
              <Typography variant="body2">Group By</Typography>
            </Box>
            <Box mb="24px">
              <FormControlLabel
                className="redesign"
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.groupBy)}
                    onChange={handleCheckboxChange}
                    name="groupBy"
                    color="primary"
                  />
                }
                label="Tags"
              />
            </Box>
            <Box>
              <TagsAsyncAutocomplete
                label="Tag"
                placeholder="Select Tag"
                value={parameters.selectedTag}
                onChange={handleTagChange}
                disableTagsModal
              />
            </Box>
            <Box>
              <SaleItemAsyncAutocomplete
                label="Sale Item"
                placeholder="Select Sale Item"
                value={parameters.saleItemId}
                onChange={handleSaleItemChange}
              />
            </Box>
            <FormControlLabel
              className="redesign"
              sx={{ marginBottom: '8px' }}
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(parameters.showSku)}
                  onChange={handleCheckboxChange}
                  name="showSku"
                  color="primary"
                />
              }
              label="Show SKU"
            />
            <FormControlLabel
              className="redesign"
              sx={{ marginBottom: '8px' }}
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(parameters.showUpc)}
                  onChange={handleCheckboxChange}
                  name="showUpc"
                  color="primary"
                />
              }
              label="Show UPC"
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default memo(ItemPriceListFilters);
