import React from 'react';
import { TableCell } from '@mui/material';
import moment from 'moment';
import _ from 'lodash';

import { useCurrencyFormatter, useGetIntlDateFormatString } from 'helpers';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import { PaymentsTableRow } from './types';
import { StatusLabel } from 'ui/components/Status/StatusLabel';

const PaymentsTableCustomRow: React.FC<
  RenderCustomRowProps<PaymentsTableRow>
> = (props) => {
  const { row, index } = props;

  const currencyFormatter = useCurrencyFormatter();
  const intlDateFormat = useGetIntlDateFormatString();

  return (
    <>
      <TableCell data-qa={`Row-${index}-Date`} style={{ cursor: 'default' }}>
        {row.date ? moment(row.date || '').format(intlDateFormat) : ''}
      </TableCell>
      <TableCell
        data-qa={`Row-${index}-payment-type`}
        style={{ cursor: 'default' }}
      >
        {row.paymentType}
      </TableCell>
      <TableCell
        data-qa={`Row-${index}-transaction-type`}
        style={{ cursor: 'default' }}
      >
        {row.transactionType}
      </TableCell>
      <TableCell data-qa={`Row-${index}-status`} style={{ cursor: 'default' }}>
        {row.status ? <StatusLabel status={row.status} /> : null}
      </TableCell>
      <TableCell
        data-qa={`Row-${index}-amount-paid`}
        style={{ cursor: 'default' }}
      >
        {_.isNull(row.amount) ? '-' : currencyFormatter(row.amount)}
      </TableCell>
    </>
  );
};

export default React.memo(PaymentsTableCustomRow);
