import { PaginationDirection } from 'services/url';
import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const CHANNEL_COLUMNS: Column[] = [
  {
    title: 'Vendor',
    field: 'vendor',
    sortable: true,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Name',
    field: 'label',
    sortable: true,
    type: ColumnTypes.renderByCustomRow,
    searchable: false,
  },
  {
    title: 'Status',
    field: 'status',
    sortable: true,
    type: ColumnTypes.renderByCustomRow,
    searchable: false,
  },
  {
    title: 'Synchronized',
    field: 'syncedOn',
    sortable: true,
    type: ColumnTypes.renderByCustomRow,
    searchable: false,
  },
];

export const initialPagination = {
  page: 1,
  pageSize: 25,
  sort: {
    sortBy: 'label',
    direction: PaginationDirection.Ascending,
  },
  totalRows: 25,
};
