import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { activeUserHasPermission } from 'services/user/redux';

import { TextField } from '../TextField';
import { useMultiFormatInputStyle } from './styled';
import { MultiFormatInputProps } from './types';
import NumberFormatCustom from './NumberFormatCustom';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const MultiFormatInput: React.FC<MultiFormatInputProps> = (
  props: MultiFormatInputProps
) => {
  const {
    onFormatChange,
    options,
    activeIndex,
    dataQa,
    permissions = [],
    decimalPlaces = 2,
    variant,
    ...textFieldProps
  } = props;

  const classes = useMultiFormatInputStyle(props);

  const canClick = useSelector(activeUserHasPermission(permissions));
  return (
    <Box display="flex">
      <TextField
        type="text"
        variant={variant}
        fullWidth
        disabled={!canClick}
        InputProps={{
          classes: {
            root: classes.input,
          },
          inputComponent: NumberFormatCustom as any,
        }}
        inputProps={{
          activeType: options[activeIndex],
          'data-qa': dataQa,
          decimalPlaces: decimalPlaces,
        }}
        sx={{ width: '158px' }} //taken from the figma file
        {...textFieldProps}
      />
      {options.map((option, index) => (
        <FBOButton
          key={`StyledIconButtonFormat${index}`}
          color={index === activeIndex ? 'positive' : 'neutral'}
          variant={index === activeIndex ? 'primary' : 'secondary'}
          size="small"
          onClick={onFormatChange}
          data-qa={`${dataQa}-button-${option.symbol}`}
          sx={{ marginTop: '19px', marginBottom: '17px', width: '40px' }}
        >
          {option.symbol}
        </FBOButton>
      ))}
    </Box>
  );
};

export default MultiFormatInput;
