import React from 'react';

import { Routes } from '../../navigation';
import { AuthContainer } from '../../components';
import { ChangePasswordForm } from './components';
import { ChangePasswordPageCmp } from './types';

const ChangePasswordPage: ChangePasswordPageCmp = () => {
  return (
    <AuthContainer
      title="Change your password"
      showNotACustomer={false}
      sx={{ alignSelf: 'auto !important' }}
    >
      <ChangePasswordForm />
    </AuthContainer>
  );
};

ChangePasswordPage.route = Routes.ChangePasswordPage;

export default ChangePasswordPage;
