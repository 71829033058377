import {
  getSearches,
  addSearch,
  updateSearch,
  deleteSearch,
} from 'services/user';
import {
  AdvancedSearchFormValues,
  AdvancedSearchReduxFunctions,
} from 'services/search';
import { PaginationDirection } from 'services/url';

export const advancedSearchReduxActions: AdvancedSearchReduxFunctions = {
  add: addSearch,
  delete: deleteSearch,
  update: updateSearch,
  get: getSearches,
};

export const initialUsersFormValues: AdvancedSearchFormValues = {
  email: null,
  nameFirst: null,
  nameLast: null,
  tenant: null,
  name: null,
  tenantAccountType: null,
};

export const displayNameMap = {
  nameFirst: 'First Name',
  nameLast: 'Last Name',
  email: 'Email',
  tenant: 'Tenant ID',
  name: 'Tenant Name',
  tenantAccountType: 'Account Type',
};

export const initialPagination = {
  page: 1,
  pageSize: 25,
  sort: {
    sortBy: 'id',
    direction: PaginationDirection.Descending,
  },
  totalRows: 25,
};
