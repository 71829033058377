import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useCardStyle = makeStyles((theme: Theme) => ({
  paper: {
    width: '600px',
    padding: 0,
  },
  container: {
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(15),
  },
}));
