import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { ListItem, ListItemText, Box } from '@mui/material';

import {
  activeUserHasPermission,
  getActiveUserLoading,
} from 'services/user/redux';
import { Icon } from 'ui/components/Icon';

import { useItemStyle } from './styled';
import { sideDrawerStatus } from './FBODrawer';
import { PermissionType } from 'services/permissions';
import { DrawerItemProps } from './DrawerItem';

export interface DrawerSubItemType {
  text: string;
  route: string | null;
  visible?: boolean;
  permissions?: PermissionType[];
  component?: React.ReactNode;
}

const DrawerSubItem: React.FC<DrawerItemProps> = (props) => {
  const {
    text,
    icon,
    to,
    active,
    secondary,
    handleClick,
    visible = true,
    permissions,
    itemExpanded,
    dataQa,
    component,
    className,
  } = props;

  const classes = useItemStyle(props);

  const canAccess = useSelector(activeUserHasPermission(permissions || []));
  const userLoading = useSelector(getActiveUserLoading);

  const { drawerExpanded } = useContext(sideDrawerStatus);

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref: any) => (
        <RouterLink to={to as string} {...itemProps} ref={ref} />
      )),
    [to]
  );

  const bgColor = !drawerExpanded
    ? classes.drawerItemExpanded
    : classes.drawerItem;

  if (!canAccess) {
    return null;
  }

  if (visible) {
    return (
      <ListItem
        onClick={handleClick}
        className={`${className} ${clsx(bgColor, {
          'active-link': active,
          [classes.activeBackground]: itemExpanded,
          [classes.nested]: secondary,
        })}`}
        dense={secondary}
        component={to ? (renderLink as any) : component}
        disableRipple
      >
        {icon && (
          <Box className={classes.icon}>
            {userLoading ? (
              <Box className={classes.iconLoading} />
            ) : (
              <Icon
                name={icon}
                width={24}
                height={24}
                fill={active ? 'white' : '#939598'}
              />
            )}
          </Box>
        )}
        <ListItemText
          primary={text}
          className={clsx({
            [classes.text]: true,
            [classes.loadingText]: userLoading,
          })}
          data-qa={`drawer-${dataQa}`}
        />
      </ListItem>
    );
  }

  return null;
};

DrawerSubItem.defaultProps = {
  secondary: false,
};

export default React.memo(DrawerSubItem);
