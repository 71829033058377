import { Channel } from './types';

export const initialChannel: Channel = {
  id: null,
  vendor: null,
  label: null,
  channelType: null,
  syncedOn: null,
  status: null,
  configuration: null,
};

// export const initialLedgerSimple: LedgerSimple = {
//   date: null,
//   ledgerId: null,
//   ledgerItems: [],
//   name: null,
//   refNo: null,
//   type: null,
// };
