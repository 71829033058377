import React, { memo, useCallback } from 'react';
import { TableCell } from '@mui/material';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { Address } from 'services/addresses';

import { createActionBarOptions, AddressTabAction } from './consts';
import { editVendorAddressPermissions } from '../helpers';
import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';

const AddressesRow: React.FC<RenderCustomRowProps<Address>> = (props) => {
  const { row: address, index, onAction } = props;

  const editPermission = editVendorAddressPermissions(address);

  const handleAction = useCallback(
    (actionType: AddressTabAction) => {
      if (onAction) {
        onAction({ type: actionType, value: index });
      }
    },
    [onAction, index]
  );

  return (
    <>
      <TableCell>{address.name}</TableCell>
      <TableCell>{address.city}</TableCell>
      <TableCell>{address.postalCode}</TableCell>
      <TableCell padding="none" align="center">
        <FBOMenuButton
          variant="tertiary"
          size="small"
          items={createActionBarOptions(handleAction)}
          permissions={editPermission}
          data-qa="address-three-dot-menu"
        />
      </TableCell>
    </>
  );
};

export default memo(AddressesRow);
