import React, { memo } from 'react';
import { Box, Paper } from '@mui/material';

import FBOWizard from './FBOWizard';
import { FBOCardWizardProps } from './types';
import { useFBOWizardStyle } from './styled';

const FBOCardWizard: React.FC<FBOCardWizardProps> = (props) => {
  const classes = useFBOWizardStyle();

  return (
    <Paper className={classes.paper}>
      <Box className={classes.container}>
        <FBOWizard {...props} />
      </Box>
    </Paper>
  );
};

export default memo(FBOCardWizard);
