import React, { useState } from 'react';
import { styled, TextField, TextFieldProps } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

type CustomTooltipTextFieldProps = {
  tooltipText: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
} & Omit<TextFieldProps, 'onChange' | 'value'>;

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    enterDelay={500}
    leaveDelay={200}
  />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 180,
  },
});

const CustomTooltipTextField: React.FC<CustomTooltipTextFieldProps> = ({
  tooltipText,
  value,
  onChange,
  ...rest
}) => {
  const [isInputFocused, setIsInputFocused] = useState(false);

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };

  return (
    <CustomWidthTooltip
      title={tooltipText && !isInputFocused ? `Notes: ${tooltipText}` : ''}
    >
      <TextField
        {...rest}
        className="redesign"
        variant="standard"
        type="text"
        placeholder="Notes"
        name="notes"
        value={value}
        onChange={onChange}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        sx={{
          '& .MuiInputBase-input': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }}
      />
    </CustomWidthTooltip>
  );
};

export default CustomTooltipTextField;
