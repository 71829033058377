import _ from 'lodash';

import { TrackingGroup, TrackingInfo } from 'services/inventory';
import { replaceValueInCollection } from 'helpers';
import { TrackingDataTypes } from 'services/settings/tracking';
import { Column } from 'ui/components/Table/ItemsTable';

export const updateWithNewTrackingInfoEntry = (
  trackingGroup: TrackingGroup,
  newEntry: string | null,
  trackingInfoRowIndex: number
) => {
  const newTrackingInfoEntry = {
    ...trackingGroup.trackingInfoList[trackingInfoRowIndex],
    value: newEntry,
  };

  const newTrackingInfoList = replaceValueInCollection<TrackingInfo>(
    trackingGroup.trackingInfoList,
    newTrackingInfoEntry,
    trackingInfoRowIndex
  )!;

  return { ...trackingGroup, trackingInfoList: newTrackingInfoList };
};

export const getTrackingTypeColumns = (columns: Column[]): Column[] => {
  const newColumns: Column[] = [];

  // remove non tracking columns
  // move serial column to the first place of array
  columns
    .filter((c) => _.get(c, 'meta'))
    .forEach((column) => {
      if (
        _.get(column, 'meta.trackingDataType') ===
        TrackingDataTypes.SerialNumber
      ) {
        newColumns.unshift(column);
      } else {
        newColumns.push(column);
      }
    });

  return newColumns;
};
