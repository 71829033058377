import React, { useCallback, memo } from 'react';
import { useSelector } from 'react-redux';
import { InputAdornment, SelectChangeEvent } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import { TextField } from 'ui/components/TextField/TextField';
import { Location } from 'services/locations';
import { getUomById } from 'services/uoms';
import { useHandleTextFieldChange } from 'services/forms';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';

import { AmountToMoveInputProps, EventFormProps } from '../../types';
import UomSelect from '../UomDropdown';
import { themeRestyle } from 'ui/theme';

export const AmountToMoveInput: React.FC<AmountToMoveInputProps> = ({
  setFormValues,
  value,
  hasError,
  formValues,
}) => {
  const handleAmountChange = useHandleTextFieldChange(
    setFormValues,
    formValues,
    true
  );

  return (
    <TextField
      className="redesign"
      variant="standard"
      type="number"
      label="Amount To Move"
      onChange={handleAmountChange}
      name="quantity"
      value={value}
      error={hasError}
      dataQa="amt-to-move"
      disableDebounce={true}
    />
  );
};

const FBOEventMoveForm: React.FC<EventFormProps> = (props) => {
  const {
    formValues,
    noTracking,
    validationErrors,
    averageCost,
    totalQty,
    setFormValues,
    committedQty,
    uomIds,
    defaultUomAbbreviation,
    hasSerialTracking,
  } = props;

  const selectedUom = useSelector(getUomById(formValues.uomId)) || null;

  const handleUomSelectChange = (e: SelectChangeEvent) => {
    const uomId = e.target.value;
    setFormValues((form) => ({ ...form, uomId: Number(uomId) }));
  };
  const handleLocationChange = useCallback(
    (value: Location | null) => {
      setFormValues((f) => ({ ...f, locationFromId: value ? value.id : null }));
    },
    [setFormValues]
  );

  const handleLocationToChange = useCallback(
    (value: Location | null) => {
      setFormValues((f) => ({ ...f, locationToId: value ? value.id : null }));
    },
    [setFormValues]
  );

  const handleTextFieldChange = useHandleTextFieldChange(
    setFormValues,
    formValues,
    true
  );

  return (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      columns={16}
      columnSpacing={themeRestyle.spacing(2)}
      padding={`0px ${themeRestyle.spacing(4)} 0px ${themeRestyle.spacing(4)}`}
      disableEqualOverflow
    >
      <Grid xs={noTracking ? 3 : 4}>
        <LocationsAsyncAutocomplete
          placeholder="Select location"
          label="Move From"
          value={formValues.locationFromId}
          onChange={handleLocationChange}
          companyWide={false}
          error={!!validationErrors.locationFromId}
          required
        />
      </Grid>
      <Grid xs={noTracking ? 3 : 4}>
        <LocationsAsyncAutocomplete
          placeholder="Select Move To Location"
          label="Move To"
          value={formValues.locationToId}
          onChange={handleLocationToChange}
          companyWide={false}
          dataQa="move-to"
          getLocationLabel={(l) => l.path || ''}
          filterOutLocations={[formValues.locationFromId]}
          error={!!validationErrors.locationToId}
          required
        />
      </Grid>
      {noTracking && (
        <Grid xs={2}>
          <AmountToMoveInput
            setFormValues={setFormValues}
            value={formValues.quantity}
            hasError={!!validationErrors.quantity}
            formValues={formValues}
          />
        </Grid>
      )}
      <Grid xs={2}>
        <UomSelect
          onChange={handleUomSelectChange}
          uomsWithConversionIds={uomIds}
          selectedUomId={selectedUom?.id ?? null}
          disabled={hasSerialTracking}
        />
      </Grid>
      <Grid xs={3}>
        <TextField
          className="redesign"
          variant="standard"
          readOnly
          additionalInputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {defaultUomAbbreviation}
              </InputAdornment>
            ),
          }}
          label="Available to Move"
          value={totalQty - committedQty}
        />
      </Grid>
      <Grid xs={3}>
        <CurrencyField
          readOnly
          label="Avg. Unit Cost"
          value={parseFloat(averageCost)}
          allowNegative
        />
      </Grid>
      <Grid xs={16}>
        <TextField
          className="redesign"
          variant="standard"
          label="Notes"
          type="text"
          placeholder="Notes"
          name="notes"
          value={formValues.notes}
          onChange={handleTextFieldChange}
        />
      </Grid>
    </Grid>
  );
};

export default memo(FBOEventMoveForm);
