import React, { useCallback, useMemo } from 'react';
import { Grid } from '@mui/material';

import { GridRow } from 'ui/components/Grid';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import { PosSettingsProps } from './types';
import { INPUT_WIDTH, ORDER_NUMBER_ASSIGNMENT_OPTIONS } from '../consts';
import { TaxRatesAutocomplete } from 'ui/components/Autocomplete/TaxRatesAutocomplete';
import { TaxRateVariants } from 'ui/components/Autocomplete/TaxRatesAutocomplete/types';
import { ItemType, ShippingTerm, SHIPPING_TERMS } from 'services/items';
import { ORDER_STATUS } from '../OrderSettings/consts';
import { TaxRate } from 'services/taxRates';
import { Location } from 'services/locations';
import { SalesOrderStatus } from 'services/salesOrders';
import { OrderNumberAssignment } from 'services/commerce/channels';
import { SaleItemsAutocomplete } from 'ui/components/Autocomplete/SaleItemsAutocomplete';
import { SaleItem } from 'services/items/saleItems';

const PosSettings: React.FC<PosSettingsProps> = (props) => {
  const { activeChannel, channelSettings, setChannelSettings, title, errors } =
    props;

  const selectedShippingTerm = useMemo(
    () =>
      SHIPPING_TERMS.find(
        (s) => s.id === channelSettings.posSettings.shippingTerms
      ) || null,
    [channelSettings.posSettings.shippingTerms]
  );

  const handleAutocompleteIdChange = useCallback(
    (name: 'defaultLocation' | 'shippingItem' | 'taxItem' | 'shippingTerms') =>
      (v: SaleItem | Location | TaxRate | ShippingTerm | null) => {
        setChannelSettings((old) => ({
          ...old,
          posSettings: { ...old.posSettings, [name]: v ? v.id : null },
        }));
      },
    [setChannelSettings]
  );

  const handleAutocompleteChange = useCallback(
    (name: 'status' | 'orderNumberAssignment') =>
      (v: SalesOrderStatus | OrderNumberAssignment | null) => {
        setChannelSettings((old) => ({
          ...old,
          posSettings: { ...old.posSettings, [name]: v || null },
        }));
      },
    [setChannelSettings]
  );

  const orderNumberAssignmentLabels = useCallback(
    (option: OrderNumberAssignment | null) => {
      if (!activeChannel?.vendor) {
        return '';
      }
      if (option === OrderNumberAssignment.Primary) {
        return "Use Fishbowl's Next Order Number";
      } else if (option === OrderNumberAssignment.Linked) {
        return `Use ${activeChannel?.vendor}'s Order Number`;
      } else if (option === OrderNumberAssignment.Alternate) {
        return `Use ${activeChannel?.vendor}'s Alternate Order Number`;
      } else {
        return '';
      }
    },
    [activeChannel?.vendor]
  );

  return (
    <GridRow title={title}>
      <Grid width={INPUT_WIDTH} item>
        <LocationsAsyncAutocomplete
          onChange={(value: Location | null) =>
            handleAutocompleteIdChange('defaultLocation')(value)
          }
          value={channelSettings.posSettings.defaultLocation}
          placeholder="Select Location"
          label="Default Location"
          parentId={null}
          companyWide={false}
          required
          dataQa="alternate-order-default-location"
          error={!!errors['posSettings.defaultLocation']}
        />
      </Grid>
      <Grid width={INPUT_WIDTH} item>
        <SaleItemsAutocomplete
          onChange={(value: SaleItem | null) =>
            handleAutocompleteIdChange('shippingItem')(value)
          }
          value={channelSettings.posSettings.shippingItem}
          placeholder="Select Item"
          label="Shipping Item"
          itemTypes={[ItemType.Shipping]}
          dataQa="alternate-order-shipping-items"
          disableAdd
          required
          error={!!errors['posSettings.shippingItem']}
        />
      </Grid>
      <Grid width={INPUT_WIDTH} item>
        <TaxRatesAutocomplete
          label="Sales Tax Item (Flat Rate)"
          placeholder="Sales Tax"
          taxRateVariant={TaxRateVariants.FlatRate}
          value={channelSettings.posSettings.taxItem}
          onChange={(v: TaxRate | null) =>
            handleAutocompleteIdChange('taxItem')(v)
          }
          required
          dataQa="alternate-order-tax-rates"
          error={!!errors['posSettings.taxItem']}
        />
      </Grid>
      <Grid width={INPUT_WIDTH} item>
        <Autocomplete
          options={ORDER_STATUS}
          value={channelSettings.posSettings.status}
          onChange={(e: React.ChangeEvent<{}>, value: any) =>
            handleAutocompleteChange('status')(value)
          }
          placeholder="Order Status"
          label="Order Status"
          dataQa="alternate-order-status"
          required
          error={!!errors['posSettings.status']}
        />
      </Grid>

      <Grid width={INPUT_WIDTH} item>
        <Autocomplete
          options={SHIPPING_TERMS}
          value={selectedShippingTerm}
          getOptionLabel={(option: ShippingTerm) => option.name}
          onChange={(e: React.ChangeEvent<{}>, v: ShippingTerm | null) =>
            handleAutocompleteIdChange('shippingTerms')(v)
          }
          placeholder="Shipping Terms"
          label="Shipping Terms"
          dataQa="alternate-order-shipping-terms"
          required
          error={!!errors['posSettings.shippingTerms']}
        />
      </Grid>
      <Grid width={INPUT_WIDTH} item>
        <Autocomplete
          options={ORDER_NUMBER_ASSIGNMENT_OPTIONS}
          value={channelSettings.posSettings.orderNumberAssignment}
          getOptionLabel={orderNumberAssignmentLabels}
          doNotOverwriteGetOptionLabel
          onChange={(e: React.ChangeEvent<{}>, value: any) =>
            handleAutocompleteChange('orderNumberAssignment')(value)
          }
          placeholder="Select"
          label="Import Order Number As"
          dataQa="order-number-assignment"
          required
          error={!!errors['posSettings.orderNumberAssignment']}
        />
      </Grid>
    </GridRow>
  );
};

export default PosSettings;
