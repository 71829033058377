import React, { useEffect, useCallback, useState, memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import {
  fetchSettingsSalesOrders,
  getSettingsSalesOrder,
  putSOSettings,
  SalesOrderSettings,
} from 'services/settings/salesOrders';
import { fetchTaxRates, getTaxRates, TaxType } from 'services/taxRates';

import { salesOrderRowDescriptor, salesOrderCardId, title } from './consts';
import { SalesOrderCardProps } from './types';
import { Card } from '../Card';
import { searchFilter, searchTitle } from '../../helpers';
import { PermissionType } from 'services/permissions';
import { getSettingsCompanyCountry } from 'services/settings/company';
import { USCountryString } from 'helpers';

const SalesOrderCard: React.FunctionComponent<SalesOrderCardProps> = (
  props
) => {
  const { search } = props;
  const [soSettings, setSoSettings] = useState<SalesOrderSettings>();

  const { items: allTaxRates } = useSelector(getTaxRates);

  const dispatch = useDispatch();
  const soSettingsCopy = useSelector(getSettingsSalesOrder);

  const companyCountry = useSelector(getSettingsCompanyCountry);

  const taxRates = useMemo(
    () => allTaxRates.filter((r) => r.taxType === TaxType.percentage),
    [allTaxRates]
  );

  const regex = useMemo(() => {
    return RegExp(_.escapeRegExp(search), 'gi');
  }, [search]);

  const hasSearch = useMemo(() => {
    return salesOrderRowDescriptor().some((t) => regex.test(t.title));
  }, [regex]);

  const hasTitle = useMemo(() => {
    return new RegExp(_.escapeRegExp(search), 'i').test(title);
  }, [search]);

  const highlightedRow = useMemo(() => {
    if (hasSearch) {
      const searchedData = searchFilter(
        salesOrderRowDescriptor(taxRates),
        regex
      );

      if (companyCountry !== USCountryString) {
        return searchedData.filter((val) => val.field !== 'salesTax');
      }

      return searchedData;
    } else {
      const searchedData = salesOrderRowDescriptor(taxRates);

      if (companyCountry !== USCountryString) {
        return searchedData.filter((val) => val.field !== 'salesTax');
      }

      return searchedData;
    }
  }, [hasSearch, regex, taxRates]);

  const highlightedTitle = useMemo(() => {
    if (hasTitle) {
      return searchTitle(title, regex);
    }
    return title;
  }, [hasTitle, regex]);

  useEffect(() => {
    setSoSettings(soSettingsCopy);
  }, [soSettingsCopy]);

  useEffect(() => {
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchSettingsSalesOrders());
    // @ts-ignore
    dispatch(fetchTaxRates());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleApplyClicked = useCallback(async (newSoSettings) => {
    try {
      const soSettingsFromApi = await putSOSettings(newSoSettings);
      setSoSettings(soSettingsFromApi);
    } catch (e) {
      // continue
    }
  }, []);

  return (
    <Card
      title={highlightedTitle}
      rows={highlightedRow}
      id={salesOrderCardId}
      data={soSettings}
      onApplyClicked={handleApplyClicked}
      show={hasSearch || hasTitle}
      viewPermissions={[PermissionType.SettingsSalesOrderView]}
      editPermissions={[PermissionType.SettingsSalesOrderEdit]}
      dataQa="sale-order-settings"
    />
  );
};

export default memo(SalesOrderCard);
