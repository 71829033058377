import React, { memo, useMemo } from 'react';
import { InvoiceDetails } from 'services/invoices';
import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { transformToTransactionsTable } from './helpers';
import { TRANSACTIONS_COLUMNS } from './consts';
import TransactionsTableCustomRow from './TransactionsTableCustomRow';
import TransactionsTableFooter from './TransactionsTableFooter';

interface TransationsTabProps {
  invoiceDetails: InvoiceDetails;
}

const TransactionsTab: React.FC<TransationsTabProps> = (props) => {
  const { invoiceDetails } = props;

  const transformedTransactions = useMemo(() => {
    if (invoiceDetails?.transactions) {
      return transformToTransactionsTable(invoiceDetails?.transactions);
    } else {
      return [];
    }
  }, [invoiceDetails]);

  return (
    <ItemsTable
      data={transformedTransactions}
      columns={TRANSACTIONS_COLUMNS}
      RenderCustomRow={TransactionsTableCustomRow}
      RenderCustomRowFooter={
        <TransactionsTableFooter footerData={transformedTransactions} />
      }
      selectableItems={false}
      emptyTableText="THERE ARE NO LEDGER ITEMS"
      dataQa="transactions-table"
    />
  );
};

export default memo(TransactionsTab);
