import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const SHIPPING_HISTORY_COLUMNS: Column[] = [
  {
    title: 'Date',
    type: ColumnTypes.renderByCustomRow,
    width: 80,
  },
  {
    title: 'User',
    type: ColumnTypes.renderByCustomRow,
    width: 90,
  },
  {
    title: 'Number',
    type: ColumnTypes.renderByCustomRow,
    width: 80,
  },
  {
    title: 'Carrier',
    type: ColumnTypes.renderByCustomRow,
    width: 50,
  },
  {
    title: 'Type',
    type: ColumnTypes.renderByCustomRow,
    width: 110,
  },
  {
    title: 'Tracking',
    type: ColumnTypes.renderByCustomRow,
  },
];
