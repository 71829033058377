import React from 'react';
import { Navigate } from 'react-router-dom';

import { Routes } from '../../navigation';
import { AuthContainer } from '../../components';
import CreateAccountForm from './components/CreateAccountForm';

interface CreateAccountCmp extends React.FunctionComponent {
  route: Routes;
}

const CreateAccountPage: CreateAccountCmp = () => {
  const [redirect, setRedirect] = React.useState(false);

  const redirectToLoginPage = () => {
    setRedirect(true);
  };

  if (redirect) {
    return <Navigate to={Routes.LoginPage} replace />;
  }

  return (
    <AuthContainer
      title="Create Account"
      showNotACustomer={true}
      sx={{ marginTop: '72px', marginBottom: '72px' }}
    >
      <CreateAccountForm afterRegistration={redirectToLoginPage} />
    </AuthContainer>
  );
};

CreateAccountPage.route = Routes.CreateAccountPage;

export default CreateAccountPage;
