import moment from 'moment';
import { DateRange, DefinedRange } from 'materialui-daterange-picker';

import {
  getSearches,
  addSearch,
  updateSearch,
  deleteSearch,
} from 'services/ledgers';
import {
  AdvancedSearchFormValues,
  AdvancedSearchReduxFunctions,
} from 'services/search';
import { PaginationDirection } from 'services/url';
import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

import { CreateFormValues } from './types';

export const advancedSearchReduxActions: AdvancedSearchReduxFunctions = {
  add: addSearch,
  delete: deleteSearch,
  update: updateSearch,
  get: getSearches,
};

export const initialDateRange: DateRange = {
  startDate: moment().startOf('month').toDate(),
  endDate: moment().endOf('date').toDate(),
};

export const initialCreateFormValues: CreateFormValues = {
  name: null,
  number: null,
  accountType: null,
};

export const initialASFormValues: AdvancedSearchFormValues = {
  name: null,
  description: null,
  location: null,
};

export const displayNameMap = {
  name: 'Name',
  description: 'Description',
  location: 'Location',
};

export const LEDGER_COLUMNS: Column[] = [
  {
    title: 'Name',
    field: 'accountName',
    sortable: true,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Type',
    field: 'accountType',
    sortable: true,
    type: ColumnTypes.renderByCustomRow,
    searchable: false,
  },
  {
    title: 'Balance',
    field: 'endBalance',
    sortable: true,
    type: ColumnTypes.renderByCustomRow,
    searchable: false,
  },
];

export const initialPagination = {
  page: 1,
  pageSize: 25,
  sort: {
    sortBy: 'accountName',
    direction: PaginationDirection.Ascending,
  },
  totalRows: 25,
};

export const customDateRangeLedger: DefinedRange[] = [
  {
    startDate: moment().startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
    label: 'Today',
  },
  {
    startDate: moment().add(-1, 'day').startOf('day').toDate(),
    endDate: moment().add(-1, 'day').endOf('day').toDate(),
    label: 'Yesterday',
  },
  {
    startDate: moment().startOf('week').toDate(),
    endDate: moment().endOf('week').toDate(),
    label: 'This Week',
  },
  {
    startDate: moment().add(-1, 'week').startOf('week').toDate(),
    endDate: moment().add(-1, 'week').endOf('week').toDate(),
    label: 'Last Week',
  },
  {
    startDate: moment().add(-7, 'days').toDate(),
    endDate: moment().toDate(),
    label: 'Last 7 Days',
  },
  {
    startDate: moment().startOf('month').toDate(),
    endDate: moment().endOf('month').toDate(),
    label: 'This Month',
  },
  {
    startDate: moment().startOf('month').add(-1, 'month').toDate(),
    endDate: moment().endOf('month').add(-1, 'month').toDate(),
    label: 'Last Month',
  },
];
