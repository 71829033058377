import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const columns: Column[] = [
  {
    title: 'Item',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'On Hand',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Qty To Order',
    type: ColumnTypes.renderByCustomRow,
    width: '15%',
  },
  {
    title: 'Vendor On Order',
    type: ColumnTypes.renderByCustomRow,
    width: '25%',
  },
];
