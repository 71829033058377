import { PermissionArea, PermissionGroup } from 'services/permissions';

export interface PermissionAreaProps {
  permissionArea: PermissionArea;
  permissionGroup: PermissionGroup;
  setPermissionGroup: React.Dispatch<React.SetStateAction<PermissionGroup>>;
}

export enum PermissionAreaState {
  Checked,
  PartiallyChecked,
  NotChecked,
}
