import { createApiAction } from 'services/api';
import {
  StartAction,
  SuccessAction,
  FailureAction,
} from 'services/api/redux/types';
import {
  createAddSearch,
  createDeleteSearch,
  createFetchSearch,
  createUpdateSearch,
} from 'services/search';

export enum TaxRatesActionTypes {
  FETCH_TAX_RATES_START = 'tax_rates/fetch_tax_rates_start',
  FETCH_TAX_RATES_SUCCESS = 'tax_rates/fetch_tax_rates_success',
  FETCH_TAX_RATES_FAILURE = 'tax_rates/fetch_tax_rates_failure',
}

const fetchTaxRatesActionStart = (): StartAction => ({
  type: TaxRatesActionTypes.FETCH_TAX_RATES_START,
  payload: null,
});

const fetchTaxRatesActionSuccess = (payload: any): SuccessAction => {
  return {
    type: TaxRatesActionTypes.FETCH_TAX_RATES_SUCCESS,
    payload,
  };
};

const fetchTaxRatesActionFailure = (error: string): FailureAction => ({
  type: TaxRatesActionTypes.FETCH_TAX_RATES_FAILURE,
  payload: { error },
});

export const fetchTaxRates = () =>
  createApiAction(
    {
      path: '/v1/taxes?pageSize=100',
      method: 'GET',
    },
    fetchTaxRatesActionStart,
    fetchTaxRatesActionSuccess,
    fetchTaxRatesActionFailure
  );

export const fetchSearch = createFetchSearch('taxRate');
export const addSearch = createAddSearch('taxRate');
export const updateSearch = createUpdateSearch('taxRate');
export const deleteSearch = createDeleteSearch('taxRate');
