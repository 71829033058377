import { makeStyles } from '@mui/styles';

export const useCustomRowStyles = makeStyles(() => ({
  vendor: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 4,
  },
  icon: {
    height: '48px',
    width: '60px',
    padding: '8px 0',
  },
}));
