import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import { fetchTrackingTypes } from 'services/settings/tracking';
import { dispatchThunks, showNotification } from 'services/api';
import { Page } from 'ui/components/Page/Page';

import { Routes } from '../../navigation';
import { SettingsPageCmp, SettingsPageProps } from './types';
import {
  CardNavigation,
  CompanyCard,
  TrackingCard,
  SalesOrderCard,
  ShippingCard,
  PickingOrderCard,
  PurchaseOrderCard,
  AccountingCard,
} from './components';
import { useSettingsPageStyle } from './styled';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SettingsPage: SettingsPageCmp = (props: SettingsPageProps) => {
  const dispatch = useDispatch();

  const classes = useSettingsPageStyle();
  const [search, setSearch] = useState('');
  const [isLoadingDependencies, setIsLoadingDependencies] = useState(true);

  useEffect(() => {
    const dependencies = [fetchTrackingTypes];

    dispatchThunks(dependencies, dispatch)
      .then(() => setIsLoadingDependencies(false))
      .catch(() =>
        showNotification('Error ocurred, please reload', { variant: 'error' })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page direction="row">
      <CardNavigation search={search} setSearch={setSearch} />
      <Box className={classes.cardContainer}>
        <SalesOrderCard search={search} />
        <PurchaseOrderCard search={search} />
        {/* <EmailCard search={search} /> */}
        <PickingOrderCard search={search} />
        <TrackingCard
          search={search}
          isLoadingDependencies={isLoadingDependencies}
        />
        <ShippingCard search={search} />
        <AccountingCard search={search} />
        <CompanyCard search={search} />
      </Box>
    </Page>
  );
};

SettingsPage.route = Routes.SettingsPage;

export default SettingsPage;
