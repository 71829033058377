import _ from 'lodash';
import { Errors, Fields, validateYup } from 'services/forms/validation';
import { copyImages } from 'services/images';
import { Item, ItemImage } from 'services/items';
import { SaleItem } from 'services/items/saleItems';
import { PermissionType } from 'services/permissions';
import { customFieldsYupSchema } from 'ui/components/CustomFields/CustomFields';
import { ActiveItemIdState } from 'ui/components/Page/WithSearchResults';

import { ITEM_VALUES } from './consts';
import { ItemTab } from './types';
import {
  yupItemGeneralTabSchema,
  yupItemTrackingTabSchema,
} from './validations';

export const copyItemImages = async (
  images: ItemImage[]
): Promise<ItemImage[]> => {
  const imagesToCopy = images.filter((i) => i.cloned && !i.deleted);
  const otherImages = images.filter((i) => !i.cloned || i.deleted);

  const copiedUrls = await copyImages(imagesToCopy.map((i) => i.url!));

  const copiedImages: ItemImage[] = imagesToCopy.map((img, index) => ({
    ...img,
    url: copiedUrls[index],
    cloned: false,
  }));

  return copiedImages.concat(otherImages);
};

export const shouldAddSaleItem = (item: Item, newValue: ItemTab) =>
  newValue === ItemTab.SaleItems &&
  item.name &&
  item.id === ActiveItemIdState.New &&
  !item.saleItemList.length;

export const editItemPermissions = (item: Item): PermissionType[] => {
  if (item.deleted) {
    return [PermissionType.Undelete];
  }
  return item.id && item.id > 0
    ? [PermissionType.ItemsEdit]
    : [PermissionType.ItemsCreate];
};

export const editSaleItemPermissions = (
  saleItem: SaleItem
): PermissionType[] => {
  if (saleItem.deleted) {
    return [PermissionType.Undelete];
  }
  return saleItem.id && saleItem.id > 0
    ? [PermissionType.ItemsEdit]
    : [PermissionType.ItemsCreate];
};

export const isItemDetailsCardValid = (
  fields: Fields,
  setActiveTab: React.Dispatch<React.SetStateAction<ItemTab>>,
  setErrors: React.Dispatch<React.SetStateAction<Errors>>,
  setCustomFieldsErrors: React.Dispatch<React.SetStateAction<Errors>>,
  country: string
) => {
  const notValidFields = !validateYup(
    fields,
    yupItemGeneralTabSchema(country),
    setErrors
  );
  const notValidCustomFields = !validateYup(
    fields.customFields,
    customFieldsYupSchema,
    setCustomFieldsErrors
  );

  if (notValidFields || notValidCustomFields) {
    setActiveTab(ItemTab.General);
    return false;
  }

  if (!validateYup(fields, yupItemTrackingTabSchema, setErrors)) {
    setActiveTab(ItemTab.Tracking);
    return false;
  }

  return true;
};

export const shouldShowCreateSaleItemModal = (
  item: Item,
  canHaveSaleItems: boolean
) => {
  const newItemHasNoSaleItems =
    item.id === ActiveItemIdState.New && !item.saleItemList.length;

  return newItemHasNoSaleItems && canHaveSaleItems;
};

export const shouldShowSyncModal = (item: Item, canHaveSaleItems: boolean) => {
  const defaultSaleItem = item.saleItemList.find((s) => s.defaultFlag) || null;

  const pickedDefaultSaleItemValues = _.pick(defaultSaleItem, ITEM_VALUES);

  const pickedItemValues = _.pick(item, ITEM_VALUES);

  const newItemAndSaleItemDiff =
    !!item.saleItemList.length &&
    !!defaultSaleItem &&
    !_.isEqual(pickedDefaultSaleItemValues, pickedItemValues);

  return newItemAndSaleItemDiff && canHaveSaleItems;
};
