import React from 'react';
import clsx from 'clsx';
import { Paper, Slide } from '@mui/material';
import { PaperSlidingLayoutProps } from './types';
import SliderContainerRestyle from './styledRedesign';

const PaperSlidingLayout: React.FC<PaperSlidingLayoutProps> = (props) => {
  const { children, shown } = props;

  return (
    <SliderContainerRestyle shown={shown}>
      <Paper
        variant="outlined"
        className={clsx('static-paper redesign', {
          'static-paper-gap': shown,
        })}
      >
        {children[0]}
      </Paper>
      <Slide
        direction="left"
        in={shown}
        appear={shown}
        timeout={{
          appear: 0,
          enter: 0,
        }}
        exit={false}
        unmountOnExit
        mountOnEnter
      >
        <Paper variant="outlined" className="sliding-paper redesign">
          {children[1]}
        </Paper>
      </Slide>
    </SliderContainerRestyle>
  );
};

export default PaperSlidingLayout;
