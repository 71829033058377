import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { TableCell, Grid, Link, Box, Popover, Typography } from '@mui/material';

import { getUoms, EACH_UOM_ID, Uom } from 'services/uoms';
import {
  PurchaseOrder,
  PurchaseOrderItem,
  PurchaseOrderItemTypes,
  PurchaseOrderStatus,
} from 'services/purchaseOrders';
import {
  useCurrencyFormatter,
  roundToDecimals,
  stopInputPropagation,
  toMulticurrencyCalculate,
  toHomeCurrencyCalculate,
  useGetCurrencySymbol,
} from 'helpers';
import { IconNames } from 'ui/theme';
import { MultiCurrencyWrapper } from 'ui/components/MultiCurrencyWrapper/MultiCurrencyWrapper';
import { TextFieldQuantity } from 'ui/components/TextField/TextFieldQuantity';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { StatusLabel } from 'ui/components/Status/StatusLabel';
import { ImageItemBox } from 'ui/components/Image/ImageItemBox';
import { IconItemBox } from 'ui/components/Icon/IconItemBox';
import { PermissionType } from 'services/permissions';
import { itemHasSerialTracking } from 'services/inventory';
import {
  calculatePriceBasedOnUomChange,
  convertToNegative,
  calculateTaxTotal,
} from 'services/salesOrders';
import { showThumbnail } from 'services/thumbnail/redux';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';
import { ItemType, ItemUomConversion } from 'services/items';
import { filterItemUoms } from 'services/items/helpers';
import { getTaxRates, renderTaxRate, TaxRate } from 'services/taxRates';
import { PopoverItem } from 'ui/components/PopoverItem';
import { TaxTooltip } from 'ui/components/TaxTooltip';
import {
  getSettingsPurchaseOrder,
  PurchaseOrderSettings,
} from 'services/settings/purchaseOrders';

import { MenuTableCell } from '../MenuTableCell';
import {
  DOT_CHAR,
  totalAnchorOrigin,
  totalTransformOrigin,
} from '../../consts';
import { editPurchaseOrderPermissions } from '../../../../helpers';
import { getSettingsCompany } from 'services/settings/company';
import { useFlags } from 'helpers/useFlags';

const PurchaseRow: React.FC<RenderCustomRowProps<PurchaseOrderItem>> = (
  props
) => {
  const {
    row,
    index,
    setData,
    errors,
    columns,
    meta: { purchaseOrder, activeMulticurrencyCode },
  } = props;

  const poSettings = useSelector(getSettingsPurchaseOrder);
  const currencySymbol = useGetCurrencySymbol();

  const [uoms, setUoms] = useState<Uom[]>([]);
  const [taxEl, setTaxEl] = useState<null | HTMLElement>(null);

  const flags = useFlags();

  const { items: defaultUoms } = useSelector(getUoms);
  const { items: storeTaxRates } = useSelector(getTaxRates);
  const companySettings = useSelector(getSettingsCompany);
  const [isInternationalConnected, setisInternationalConnected] =
    useState(false);
  const dispatch = useDispatch();

  const currencyFormatter = useCurrencyFormatter();
  useEffect(() => {
    if (companySettings.country !== 'US') setisInternationalConnected(true);
  }, [companySettings]);
  const rowItemType = _.get(row.item, 'itemType', null);

  const isTaxPopoverVisible = useMemo(() => Boolean(taxEl), [taxEl]);

  const selectedTaxRate = useMemo(
    () => storeTaxRates.find((t) => t.id === row.taxId) || null,
    [storeTaxRates, row.taxId]
  );

  const taxRates = useMemo(
    () =>
      storeTaxRates.filter((tax) =>
        isInternationalConnected && flags.intlTaxRates
          ? tax.channelTaxType !== 'SALES'
          : tax.taxType === 'Percentage'
      ),
    [storeTaxRates, isInternationalConnected]
  );

  const resolveDisplayTax = useMemo(() => {
    if (!row.taxable) {
      return 'Not Taxable';
    }

    if (selectedTaxRate) {
      return renderTaxRate(selectedTaxRate, currencySymbol);
    } else {
      return '0%';
    }
  }, [row, selectedTaxRate, currencySymbol]);

  const subtotalCost = useMemo(
    () => calculateSubtotal(row, poSettings, purchaseOrder),
    [poSettings.costIncludesTax, row]
  );

  const handleOpenPreview = useCallback(
    (e) => {
      const imageUrl = _.get(row, 'item.defaultImage', '') as string;

      dispatch(showThumbnail(imageUrl));
      e.stopPropagation();
    },
    [dispatch, row]
  );

  const fieldsDisabled =
    purchaseOrder.status === PurchaseOrderStatus.Fulfilled ||
    purchaseOrder.deleted;

  const editPermission = editPurchaseOrderPermissions(purchaseOrder);

  const rowIcon = useMemo(() => {
    switch (row.purchaseOrderItemType) {
      case PurchaseOrderItemTypes.DropShip:
      case PurchaseOrderItemTypes.Purchase: {
        const showVendorItemsOnPO = _.get(
          purchaseOrder,
          'vendor.onlyShowVendorItemsOnPo',
          false
        );
        const vendorItemName =
          row.vendorItemName && showVendorItemsOnPO
            ? ` ${DOT_CHAR} ${row.vendorItemName}`
            : '';
        const nameResolved = `${row.name}${vendorItemName}`;
        const description = _.get(row, 'description', '');
        return (
          <ImageItemBox
            name={nameResolved}
            description={_.truncate(description!, { length: 100 })}
            source={_.get(row, 'item.defaultImage', null)}
            onClick={handleOpenPreview}
            dataQa={'purchase-row'}
          />
        );
      }
      case PurchaseOrderItemTypes.CreditReturn:
        return (
          <IconItemBox
            name={_.get(row, 'name', null)}
            description={_.get(row, 'description', null)}
            source={IconNames.CreditReturn}
          />
        );
    }
  }, [row, handleOpenPreview]);

  const isSerialTracked = useMemo(() => {
    if (row.item) {
      return itemHasSerialTracking(row.item);
    }
    return false;
  }, [row.item]);

  useEffect(() => {
    // Set total cost for live update
    const cost = row.unitCost || 0;
    const mutlicurrencyCost = row.multiCurrencyUnitCost || 0;
    const quantity = row.quantity || 0;
    const multiCurrencyTotalCost = roundToDecimals(
      mutlicurrencyCost * quantity,
      2
    );

    const totalCost = cost * quantity;

    const taxTotal = row.taxRate
      ? calculateTaxTotal(row.taxRate, subtotalCost, 0)
      : 0;

    setData(
      {
        ...row,
        totalCost,
        taxTotal,
        exchangeRate: purchaseOrder.exchangeRate,
        multiCurrencyTotalCost: multiCurrencyTotalCost || null,
      },
      index
    );
    // eslint-disable-next-line
  }, [
    row.unitCost,
    row.quantity,
    purchaseOrder.exchangeRate,
    row.taxRate,
    row.taxTotal,
    subtotalCost,
    row.taxRate,
  ]);

  useEffect(() => {
    if (row.item) {
      const itemUoms = filterItemUoms(row.item.defaultUom, defaultUoms);
      if (itemUoms) {
        setUoms(itemUoms);
      }
    }
  }, [row, defaultUoms]);

  const handlePriceInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
        ? convertToNegative(e.target.value, row.purchaseOrderItemType)
        : null;

      setData(
        {
          ...row,
          unitCost: value,
          multiCurrencyUnitCost: toMulticurrencyCalculate(
            value || 0,
            purchaseOrder.exchangeRate || 1
          ),
        },
        index
      );
    },
    [index, row, setData, purchaseOrder.exchangeRate]
  );

  const handleAmountInputChange = useCallback(
    (value: number | null) => {
      const newPoiRow = {
        ...row,
        quantity: value,
      };
      setData(newPoiRow, index);
    },
    [row, index, setData]
  );

  const handleAmountMenuChange = useCallback(
    (uomId: number) => {
      const newUom = uoms.find((u) => u.id === uomId)!;
      const oldUom = uoms.find((u) => u.id === row.uomId)!;

      const itemUomConversions: ItemUomConversion[] = _.get(
        row,
        'item.itemUomConversionList',
        null
      );

      const newPrice = calculatePriceBasedOnUomChange(
        newUom,
        oldUom,
        row.unitCost || 0,
        itemUomConversions
      );

      const newSoiRow = {
        ...row,
        uomId,
        unitCost: newPrice,
        multiCurrencyUnitCost: toMulticurrencyCalculate(
          newPrice,
          row.exchangeRate || 1
        ),
      };
      setData(newSoiRow, index);
    },
    [row, index, setData, uoms]
  );

  const formatedMulticurrency = useMemo(
    () =>
      activeMulticurrencyCode &&
      currencyFormatter(
        toMulticurrencyCalculate(
          subtotalCost,
          purchaseOrder.exchangeRate || 1
        ) || 0,
        activeMulticurrencyCode
      ),
    [
      activeMulticurrencyCode,
      currencyFormatter,
      purchaseOrder.exchangeRate,
      subtotalCost,
    ]
  );

  const formatedMulticurrencyTax = useMemo(
    () =>
      activeMulticurrencyCode &&
      currencyFormatter(
        (row.taxTotal || 0) * (purchaseOrder.exchangeRate || 1),
        activeMulticurrencyCode
      ),
    [
      activeMulticurrencyCode,
      row,
      purchaseOrder.exchangeRate,
      currencyFormatter,
    ]
  );

  const handleMulticurrencyFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value
      ? convertToNegative(e.target.value, row.purchaseOrderItemType)
      : 0;

    const unitCost = toHomeCurrencyCalculate(value, purchaseOrder.exchangeRate);
    setData({ ...row, unitCost, multiCurrencyUnitCost: value }, index);
  };

  const isTaxDisabled = (): boolean => {
    return purchaseOrder.status !== PurchaseOrderStatus.BidRequest;
  };

  const taxButtonClickHandler = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      const permissionToClick = editPermission && !isTaxDisabled();

      // If it hasn't permission, don't click and prevent from opening rows item modal.
      if (!permissionToClick) return false;

      setTaxEl(event.currentTarget);
    },
    [editPermission, purchaseOrder.status]
  );

  const handleTaxPopClose = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();

      setTaxEl(null);
    },
    []
  );

  const handleTaxPopCliked = useCallback(
    (tax: TaxRate | null, taxable: boolean) => () => {
      const taxRate = tax ? tax.percentage : null;

      setData(
        {
          ...row,
          taxId: tax ? tax.id : null,
          taxRate,
          taxable,
        },
        index
      );
      setTaxEl(null);
    },
    [row, index, setData]
  );

  return (
    <>
      <TableCell style={{ width: columns[0].width }}>{rowIcon}</TableCell>
      <TableCell>
        <Grid item xs>
          <CurrencyField
            placeholder="Cost"
            name="unitCost"
            value={roundToDecimals(row.unitCost || 0, 6)}
            onChange={handlePriceInputChange}
            onClick={stopInputPropagation}
            disabled={fieldsDisabled}
            fullWidth
            permissions={[PermissionType.PurchaseOrderOverrideCost]}
            error={!!errors.unitCost}
            allowNegative
            decimalPlaces={6}
            noMargin
          />
        </Grid>
      </TableCell>
      {
        <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
          <TableCell>
            <CurrencyField
              placeholder={`Cost ${activeMulticurrencyCode}`}
              value={roundToDecimals(row.multiCurrencyUnitCost || 0, 6)}
              onClick={stopInputPropagation}
              disabled={fieldsDisabled}
              onChange={handleMulticurrencyFieldChange}
              fullWidth
              permissions={[PermissionType.PurchaseOrderOverrideCost]}
              error={!!errors.unitCost}
              currencyCode={activeMulticurrencyCode}
              allowNegative
              decimalPlaces={6}
              noMargin
            />
          </TableCell>
        </MultiCurrencyWrapper>
      }
      <TableCell>
        <Grid container>
          <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
            <Box margin="0px 5px 0px -15px" paddingLeft="5px">
              &times;
            </Box>
          </Grid>
          <Grid item xs>
            <TextFieldQuantity
              placeholder="Quantity"
              name="amount"
              value={row.quantity}
              disabled={fieldsDisabled}
              selectedUomId={row.uomId || EACH_UOM_ID}
              onClick={stopInputPropagation}
              onTextChange={handleAmountInputChange}
              onMenuChange={handleAmountMenuChange}
              fullWidth
              error={!!errors.quantity}
              uoms={uoms}
              isUomSelectDisabled={rowItemType === ItemType.Shipping}
              permissions={editPermission}
              isDecimal={!isSerialTracked}
              dataQa="purchase-order-item-quantity"
              noMargin
            />
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align="center">
        <TaxTooltip tax={selectedTaxRate} taxable={row.taxable}>
          <Link
            variant="body2"
            color="textPrimary"
            onClick={taxButtonClickHandler}
            underline={isTaxDisabled() ? 'none' : 'hover'}
            data-qa="item-row-tax"
            className="aligned-link"
          >
            {resolveDisplayTax}
          </Link>
        </TaxTooltip>
        <Popover
          anchorOrigin={totalAnchorOrigin}
          transformOrigin={totalTransformOrigin}
          anchorEl={taxEl}
          open={isTaxPopoverVisible}
          onClose={handleTaxPopClose}
        >
          <Box width={150}>
            <Grid container>
              <PopoverItem
                label="None"
                value="0%"
                onClick={handleTaxPopCliked(null, true)}
              />
              {taxRates.map((tax, i) => {
                return (
                  <PopoverItem
                    key={i}
                    label={tax.name!}
                    value={renderTaxRate(tax, currencySymbol)}
                    onClick={handleTaxPopCliked(tax, true)}
                  />
                );
              })}
              <PopoverItem
                label="Not Taxable"
                value=""
                onClick={handleTaxPopCliked(null, false)}
              />
            </Grid>
          </Box>
        </Popover>
      </TableCell>
      <TableCell align="center">
        <StatusLabel
          status={row.status}
          label={`${row.quantityFulfilled ? row.quantityFulfilled : 0}/${
            row.quantity ? row.quantity : 0
          }`}
        />
      </TableCell>
      <TableCell align="right">
        <Typography variant="body2" color="textPrimary">
          {currencyFormatter(subtotalCost || 0)}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {currencyFormatter(row.taxTotal || 0)}
        </Typography>
      </TableCell>
      <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
        <TableCell align="right">
          <Link underline="none" variant="body2" color="textPrimary">
            {formatedMulticurrency || ''}
          </Link>
          <Typography variant="subtitle2" color="textSecondary">
            {formatedMulticurrencyTax || ''}
          </Typography>
        </TableCell>
      </MultiCurrencyWrapper>
      <MenuTableCell {...props} />
    </>
  );
};

export default memo(PurchaseRow);

export const calculateSubtotal = (
  row: PurchaseOrderItem,
  poSettings: PurchaseOrderSettings,
  activePurchaseOrder: PurchaseOrder
): number => {
  const taxRate = row.taxRate ? row.taxRate : 0;
  let costWithQuantity = row.totalCost ?? 0;
  if (activePurchaseOrder?.id === -1) {
    if (poSettings.costIncludesTax && taxRate > 0) {
      costWithQuantity = (row.totalCost ?? 0) / (1 + taxRate);
    }
  }
  if (activePurchaseOrder?.costIncludesTax && taxRate > 0) {
    costWithQuantity = (row.totalCost ?? 0) / (1 + taxRate);
  }

  return costWithQuantity;
};
