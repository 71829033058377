import React from 'react';

import { Box } from '@mui/system';
import { TextField } from 'ui/components/TextField/TextField';
import { colorPalette } from 'ui/theme';

interface FBOQuantityFieldReadOnlyProps {
  quantity?: number | null;
  uom?: string | null;
  dataQa?: string;
  noMargin?: boolean;
}

const FBOQuantityFieldReadOnly = (props: FBOQuantityFieldReadOnlyProps) => {
  const { quantity, uom, dataQa, noMargin } = props;

  return (
    <Box style={{ margin: '0' }} display={'flex'} data-qa={dataQa}>
      <TextField
        className={noMargin ? 'redesign no-margin' : 'redesign'}
        variant="standard"
        value={quantity}
        type="text"
        InputProps={{
          readOnly: true,
        }}
        inputProps={{
          style: {
            color: colorPalette.redesign.contentSecondary,
          },
        }}
        style={{
          backgroundColor: `${colorPalette.redesign.background1}`,
          borderRight: `1px solid ${colorPalette.redesign.background4}`,
          paddingLeft: '8px',
          borderRadius: '3px 0 0 3px',
        }}
        fullWidth={false}
      />
      <TextField
        className={noMargin ? 'redesign no-margin' : 'redesign'}
        variant="standard"
        value={uom}
        type="text"
        InputProps={{
          readOnly: true,
        }}
        inputProps={{
          style: {
            color: colorPalette.redesign.contentSecondary,
          },
        }}
        style={{
          backgroundColor: `${colorPalette.redesign.background1}`,
          paddingLeft: '12px',
          borderRadius: '0 3px 3px 0',
          width: '80px',
        }}
        fullWidth={false}
      />
    </Box>
  );
};

export default FBOQuantityFieldReadOnly;
