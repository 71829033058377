import _ from 'lodash';

import { PermissionType } from 'services/permissions';
import { transformDateToMomentDate } from 'helpers';
import { transformCompanySettings } from 'services/settings/company';

import { ActiveUser, CrmAssociation, GlobalUser, User } from './types';

export const transformActiveUser = (response: any): ActiveUser => {
  const permissions: PermissionType[] = _.get(response, 'user.permissions', []);

  return {
    currencyCode: response.currencyCode,
    environment: response.environment,
    tenant: response.tenant,
    user: response.user ? transformUser(response.user) : null,
    userGlobalId: response.userGlobalId,
    permissions,
    isAdmin: response.user ? response.user.admin : false,
    externalAccountId: response.externalAccountId,
    externalContactId: response.externalContactId,
    isExecutor: response.executor ? response.executor : false,
    maxIntegrations: 50,
  };
};

export const transformUser = (user: any): User => {
  return {
    id: user.id,
    admin: user.admin,
    dateCreated: transformDateToMomentDate(user.dateCreated),
    dateLastModified: transformDateToMomentDate(user.dateLastModified),
    defaultContact: user.defaultContact,
    defaultLocationId: user.defaultLocationId,
    deleted: user.deleted,
    email: user.email,
    phoneNumber: user.phoneNumber,
    phoneType: transformPhoneType(user.phoneType, false),
    firstName: user.firstName,
    lastName: user.lastName,
    registered: user.registered,
    timezone: user.timezone,
    useEmail: user.useEmail,
    version: user.version,
    tenant: user.tenant,
    accessGroupIds: user.accessGroupIds,
    locationIdsPermitted: user.locationIdsPermitted,
    createdById: user.createdById,
    lastModifiedById: user.lastModifiedById,
    initials: user.initials,
    dateDeleted: transformDateToMomentDate(user.dateDeleted),
    permissions: user.permissions,
  };
};

export const transformRequestUser = (user: User): any => {
  const requestData = {
    accessGroupIds: user.accessGroupIds,
    admin: user.admin,
    defaultContact: user.defaultContact,
    defaultLocationId: user.defaultLocationId,
    deleted: user.deleted,
    email: user.email,
    phoneNumber: user.phoneNumber,
    phoneType: transformPhoneType(
      user.phoneNumber && user.phoneType ? user.phoneType : 'NONE',
      true
    ),
    firstName: user.firstName,
    lastName: user.lastName,
    registered: user.registered,
    timezone: user.timezone,
    useEmail: user.useEmail,
    tenant: user.tenant,
    locationIdsPermitted: user.locationIdsPermitted,
  };

  // POST REQUEST
  if (user.id && user.id < 0) {
    return requestData;
  }

  // PUT REQUEST
  return {
    ...requestData,
    id: user.id,
    version: user.version,
  };
};

export const transformGlobalUser = (user: any): GlobalUser => {
  return {
    id: user.id,
    email: user.email,
    nameFirst: user.nameFirst,
    nameLast: user.nameLast,
    companyId: user.company_id,
    company: transformCompanySettings(user.company),
    tenant: user.company.tenant,
    name: user.company.name,
    tenantAccountType: user.company.tenantAccountType,
    dateLoginAttempt: user.dateLoginAttempt,
  };
};

export const transformCrmAssociation = (res: any): CrmAssociation => {
  return {
    id: res?.id,
    crmId: res?.crmId,
    crmType: res?.crmType,
  };
};

export const transformPhoneType = (
  phoneType: string,
  toEnum: boolean
): string => {
  if (toEnum) {
    switch (phoneType) {
      case 'Home':
        return 'HOME';
      case 'Office':
        return 'OFFICE';
      case 'Mobile':
        return 'MOBILE';
      default:
        return 'NONE';
    }
  }
  switch (phoneType) {
    case 'HOME':
      return 'Home';
    case 'OFFICE':
      return 'Office';
    case 'MOBILE':
      return 'Mobile';
    default:
      return 'None';
  }
};
