import React from 'react';
import { ReportParameters, ReportId } from 'services/reports';

const pdfDisabledReports = new Set([
  'customerHistory',
  'cycleCount',
  'inventoryValuation',
  'inventoryEventHistory',
  'onHandByExpiration',
  'purchaseOrderSummary',
  'reorder',
  'salesOrderSummary',
  'totalItemSales',
  'items',
  'inventoryAvailability',
  'margin',
  'consumption',
]);

const excelDisabledReports = new Set([
  'barcodeReport',
  'locationBarcodeReport',
  'itemBarcodeLabel',
  'itemBarcodeOneOffReport',
]);

export const isPdfDisabled = (reportId: ReportId | undefined) =>
  reportId ? pdfDisabledReports.has(reportId) : false;

export const isExcelDisabled = (reportId: ReportId) =>
  excelDisabledReports.has(reportId);

export function useReportPagination() {
  const [pageCount, setPageCount] = React.useState<number | null>(null);
  const [pageNumber, setPageNumber] = React.useState(1);

  const previousPage = () => setPageNumber((number) => number - 1);
  const nextPage = () => setPageNumber((number) => number + 1);
  const resetReport = (pageCount: number) => {
    setPageNumber(1);
    setPageCount(pageCount);
  };

  return { pageNumber, pageCount, nextPage, previousPage, resetReport };
}

export const handleTimeZoneDifference = (params: ReportParameters) => {
  let tempParams = { ...params };

  if (tempParams.dateRange) {
    tempParams = {
      ...params,
      dateRange: {
        startDate: new Date(
          new Date(params.dateRange?.startDate).getTime() -
            new Date(params.dateRange?.startDate).getTimezoneOffset() * 60000
        ).toJSON(),
        endDate: new Date(
          new Date(params.dateRange?.endDate).getTime() -
            new Date(params.dateRange?.endDate).getTimezoneOffset() * 60000
        ).toJSON(),
      },
    };
  } else {
    const currentDate = new Date();
    const lastYearStartDate = new Date(
      currentDate.getFullYear() - 1,
      currentDate.getMonth(),
      currentDate.getDate()
    );

    tempParams.dateRange = {
      startDate: currentDate.toJSON(),
      endDate: lastYearStartDate.toJSON(),
    };
  }

  return tempParams;
};

export const tooltipDelays = () => {
  return {
    initialDelay: 1000,
    subsequentDelay: 1000,
  };
};
