import { PermissionType } from 'services/permissions';
import { User } from 'services/user';

export const editUserPermissions = (user: User): PermissionType[] => {
  if (user.deleted) {
    return [PermissionType.Undelete];
  }
  return user.id && user.id > 0
    ? [PermissionType.UsersEdit]
    : [PermissionType.UsersCreate];
};
