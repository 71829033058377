import React, { memo, useMemo, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getVendors } from 'services/vendors';
import { Modal } from 'ui/components/Modal/Modal';
import {
  AutoPurchaseOrderItem,
  getAutoPurchaseOrderItems,
  PurchaseOrder,
} from 'services/purchaseOrders';
import { Location } from 'services/locations';
import { Errors, validateYup } from 'services/forms/validation';

import { AutoPurchaseOrderWizardProps } from './types';
import { getAutoPurchaseOrderWizardSteps } from './consts';
import { useAutoPurchaseOrderWizardStyle } from './styled';
import { ItemsStep, OrdersStep } from './components';
import { rowSchema } from './validations';
import {
  createAutoPurchaseOrders,
  createPOsAndReturnIds,
  createPurchaseOrders,
  filterReorderPointsAndLocations,
} from './helpers';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOWizard from 'ui/theme/components/FBOWizard/FBOWizard';

const AutoPurchaseOrderWizard: React.FC<AutoPurchaseOrderWizardProps> = (
  props
) => {
  const { visible, onClose, fetchSearchResult } = props;

  const classes = useAutoPurchaseOrderWizardStyle();

  const { items: vendors } = useSelector(getVendors);

  const [activeStep, setActiveStep] = useState(0);
  const [items, setItems] = useState<AutoPurchaseOrderItem[]>([]);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrder[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    null
  );
  const [errors, setErrors] = useState<Errors[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const steps = useMemo(
    () => getAutoPurchaseOrderWizardSteps(classes, activeStep),
    [classes, activeStep]
  );

  const nextButtonDisabled = useMemo(
    () => activeStep === 0 && !selectedItems.length,
    [activeStep, selectedItems]
  );

  const validateAutoCompleteItems = useCallback(
    (autoItems: AutoPurchaseOrderItem[]) => {
      const newErrors: Errors[] = [];
      const itemsAreValid = autoItems.map((item, index) => {
        if (!selectedItems.includes(item.id)) {
          return true;
        }
        return validateYup(item, rowSchema, (error: Errors) => {
          newErrors[index] = error;
        });
      });
      setErrors(newErrors);

      return itemsAreValid.some((valid) => !valid);
    },
    [selectedItems]
  );

  // This function is used to fetch all items and filter them according to our selected location and their reorder points
  const handleLocationChange = useCallback(
    async (location: Location | null) => {
      if (!location || !location.id) {
        setSelectedLocation(null);
        setItems([]);
        setErrors([]);
        return;
      }
      try {
        setIsLoading(true);
        const items = await getAutoPurchaseOrderItems(location.id);
        const filteredItems = filterReorderPointsAndLocations(
          items,
          location.id
        );
        const allSelectedItems = filteredItems
          .filter((i) => i.quantity > 0)
          .map((ai) => ai.id);

        setSelectedItems(allSelectedItems);
        setItems(filteredItems);
      } catch (e) {
        // ignore error
      }
      setSelectedLocation(location);
      setIsLoading(false);
      setErrors([]);
    },
    []
  );

  const handleClose = useCallback(() => {
    onClose();
    setActiveStep(0);
    setSelectedItems([]);
    setItems([]);
    setSelectedLocation(null);
  }, [onClose]);

  const handleCreateAndOpen = useCallback(async () => {
    try {
      const purchaseOrderIds = await createPOsAndReturnIds(purchaseOrders);
      purchaseOrderIds.forEach((id) =>
        window.open(`/purchasing/purchase-order?activeId=${id}`, '_blank')
      );
    } catch {
      // ignore error
    }
    handleClose();
  }, [purchaseOrders, onClose]);

  const finishWizard = useCallback(async () => {
    try {
      await createPurchaseOrders(purchaseOrders);
      await fetchSearchResult();
    } catch {
      // ignore error
    }
    handleClose();
  }, [purchaseOrders, handleClose, fetchSearchResult]);

  const resolvePurchaseOrders = useCallback(async () => {
    const resolvedItems = items.filter((i) => selectedItems.includes(i.id));

    try {
      setIsLoading(true);
      const newOrders = await createAutoPurchaseOrders(
        resolvedItems,
        selectedLocation!.id!,
        vendors
      );
      setPurchaseOrders(newOrders);
    } catch (e) {
      // ignore error
    }
    setIsLoading(false);
  }, [selectedItems, selectedLocation, vendors, items]);

  const handleNextClicked = useCallback(() => {
    if (activeStep === 0) {
      const isInvalid = validateAutoCompleteItems(items);
      if (isInvalid) {
        return;
      }
      resolvePurchaseOrders();
    }
    if (activeStep === steps.length - 1) {
      finishWizard();
      return;
    }
    setActiveStep(activeStep + 1);
  }, [
    activeStep,
    steps,
    items,
    finishWizard,
    validateAutoCompleteItems,
    resolvePurchaseOrders,
  ]);

  const handleBackClicked = useCallback(() => {
    setActiveStep(activeStep - 1);
    setErrors([]);
  }, [activeStep]);

  if (!visible) {
    return null;
  }

  return (
    <Modal
      open={visible}
      onCancelClicked={handleClose}
      title="Auto Purchase Order"
      scroll={'paper'}
      isLoadingContent={isLoading}
      withoutFooter
      withoutDefaultPadding
      dataQa="auto-purchase-order-modal"
      maxWidth="xl"
      className={classes.margin}
    >
      <FBOWizard
        steps={steps}
        activeStep={activeStep}
        onNext={handleNextClicked}
        onPrevious={handleBackClicked}
        finishButtonLabel="Create Orders"
        secondaryButtonComp={
          <FBOButton
            variant="secondary"
            color="positive"
            onClick={handleCreateAndOpen}
            style={{ marginRight: '10px' }}
            data-qa="create-and-open-auto-purchase-orders"
          >
            Create and Open in New Tab(s)
          </FBOButton>
        }
        disableNext={nextButtonDisabled}
        hideSteps
      >
        {[
          <ItemsStep
            items={items}
            setItems={setItems}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            selectedLocation={selectedLocation}
            onLocationChange={handleLocationChange}
            errors={errors}
            key={1}
          />,
          <OrdersStep purchaseOrders={purchaseOrders} key={2} />,
        ]}
      </FBOWizard>
    </Modal>
  );
};

export default memo(AutoPurchaseOrderWizard);
