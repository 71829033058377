import { createContext, useContext } from 'react';

interface ModalActionsContextType {
  registerSaveCallback: (callback: () => void) => void;
  registerCancelCallback: (callback: () => void) => void;
  triggerSave: () => void;
  triggerCancel: () => void;
  closeModal: () => void;
}

export const ModalActionsContext =
  createContext<ModalActionsContextType | null>(null);

export const useModalActions = () => {
  const context = useContext(ModalActionsContext);
  if (!context) {
    throw new Error(
      'useModalActions must be used within a ModalActionsProvider'
    );
  }
  return context;
};
