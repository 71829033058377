import { createApiAction } from 'services/api';
import {
  StartAction,
  SuccessAction,
  FailureAction,
} from 'services/api/redux/types';
import {
  createAddSearch,
  createDeleteSearch,
  createFetchSearch,
  createUpdateSearch,
} from 'services/search';

export enum ReceivingActionTypes {
  FETCH_RECEIVING_START = 'receiving/fetch_receiving_start',
  FETCH_RECEIVING_SUCCESS = 'receiving/fetch_receiving_success',
  FETCH_RECEIVING_FAILURE = 'receiving/fetch_receiving_failure',
}

// RECEIVING ACTIONS
const fetchReceiptActionStart = (): StartAction => ({
  type: ReceivingActionTypes.FETCH_RECEIVING_START,
  payload: null,
});

const fetchReceiptActionSuccess = (payload: any): SuccessAction => {
  return {
    type: ReceivingActionTypes.FETCH_RECEIVING_SUCCESS,
    payload,
  };
};

const fetchReceiptActionFailure = (error: string): FailureAction => ({
  type: ReceivingActionTypes.FETCH_RECEIVING_FAILURE,
  payload: { error },
});

export const fetchReceipts = () =>
  createApiAction(
    {
      path: '/v1/receipts?pageNumber=1&pageSize=100',
      method: 'GET',
    },
    fetchReceiptActionStart,
    fetchReceiptActionSuccess,
    fetchReceiptActionFailure
  );

// SEARCH ACTION CREATORS

export const fetchSearch = createFetchSearch('receiving');
export const addSearch = createAddSearch('receiving');
export const updateSearch = createUpdateSearch('receiving');
export const deleteSearch = createDeleteSearch('receiving');
