export interface CustomField {
  id: number | null;
  checkVersion: boolean;
  customFieldOptions: CustomFieldOption[];
  customFieldType: CustomFieldType | null;
  customFieldValues: CustomFieldValue | null;
  dateCreated: Date | null;
  dateLastModified: Date | null;
  name: string | null;
  objectType: ObjectType | null;
  required: boolean;
  lineNumber: number | null;
  version: number | null;
}

export interface CustomFieldOption {
  id: number | null;
  checkVersion: boolean;
  customFieldId: number | null;
  dateCreated: Date | null;
  dateLastModified: Date | null;
  option: string | null;
  version: number | null;
}

export type CustomFieldValue = Date | string | string[] | boolean | number;

export enum CustomFieldType {
  Number = 'Number',
  Text = 'Text',
  Date = 'Date',
  Boolean = 'Boolean',
  SingleChoice = 'Single Choice',
  MultipleChoice = 'Multiple Choice',
  Url = 'Url',
}

export enum ObjectType {
  SalesOrder = 'Sales Order',
  SalesOrderItem = 'Sales Order Item',
  Pick = 'Pick',
  Ship = 'Ship',
  PurchaseOrder = 'Purchase Order',
  PurchaseOrderItem = 'Purchase Order Item',
  Receipt = 'Receipt',
  Item = 'Item',
  SaleItem = 'Sale Item',
  Customer = 'Customer',
  Vendor = 'Vendor',
}
