import { commerceApi, identityApi } from 'services/api/config';

export const register = async (
  tenantId: string,
  appId: string | null,
  appSecret: string | null,
  accessToken: string | null,
  refreshToken: string | null
) => {
  const response = await commerceApi({
    url: `/commerce/v1/registrations`,
    method: 'POST',
    data: {
      'app-id': appId,
      'app-secret': appSecret,
      'sellware-tenant-id': tenantId,
      'refresh-token': refreshToken,
      'access-token': accessToken,
    },
  });

  return response.data;
};

export const reconnect = async (
  tenantId: string,
  appId: string | null,
  appSecret: string | null,
  accessToken: string | null,
  refreshToken: string | null
) => {
  const response = await commerceApi({
    url: `/commerce/v1/registrations`,
    method: 'PUT',
    data: {
      'app-id': appId,
      'app-secret': appSecret,
      'sellware-tenant-id': tenantId,
      'refresh-token': refreshToken,
      'access-token': accessToken,
    },
  });

  return response.data;
};

export const getConnectionStatus = async () => {
  const response = await commerceApi({
    url: `/commerce/v1/connection/status`,
    method: 'GET',
  });

  return response.data;
};

export const getCommerceTenantId = async (
  email: string
): Promise<{ clientId: string | null }> => {
  try {
    const response = await identityApi({
      url: `/tenant`,
      method: 'GET',
      params: { email },
    });
    return { clientId: response.data };
  } catch (error: any) {
    if (error.response && error.response.status === 404) {
      return { clientId: null };
    }
    throw error;
  }
};
