import moment from 'moment';
import _ from 'lodash';
import convert from 'convert-units';

import { BrowserType, USCountryString } from 'helpers';
import { DefaultWeightUom } from 'services/settings/shipping';
import { Customer } from 'services/customers';
import { Vendor } from 'services/vendors';

/**
 * Check if all objects in array have deleted true flag
 *
 * @param data
 */
export const arrayHasAllDeletedObjects = <T extends { deleted: boolean }>(
  data: T[]
) => {
  if (!data.length) {
    return false;
  }

  return data.every((i) => i.deleted);
};

/**
 * Open link in new tab
 *
 * @param href
 */

export const openInNewTab = (href: string) => {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    href,
  }).click();
};

/**
 *
 * @param num
 * @param numberOfDecimals
 */

export const roundToDecimals = (num: number, numberOfDecimals: number = 6) => {
  const factor = 10 ** numberOfDecimals;

  return Math.round((num + Number.EPSILON) * factor) / factor;
};

/**
 * Formatting number to another currency
 */

export const toMulticurrencyCalculate = (
  price: number,
  exchangeRate: number
) => {
  return roundToDecimals(price * exchangeRate, 5);
};

export const toHomeCurrencyCalculate = (
  multicurrencyPrice: number,
  exchangeRate: number
) => {
  return roundToDecimals(multicurrencyPrice / exchangeRate, 6);
};

/**
 *
 * @param date
 */

export const transformDateToMomentDate = (date: string | null): Date | null => {
  return date ? moment(date).toDate() : null;
};

export const stopInputPropagation = (event: React.MouseEvent<HTMLElement>) => {
  event.stopPropagation();
};

export const checkBrowser = () => {
  const userAgent = window.navigator.userAgent;
  const isChrome = userAgent.indexOf('Chrome') > -1;

  if (isChrome) {
    return BrowserType.Chrome;
  } else if (
    userAgent.indexOf('MSIE') > -1 ||
    userAgent.indexOf('Trident') > -1
  ) {
    return BrowserType.IE;
  } else if (userAgent.indexOf('Edge') > -1) {
    return BrowserType.Edge;
  } else if (userAgent.indexOf('Firefox') > -1) {
    return BrowserType.Firefox;
  } else if (userAgent.indexOf('Safari') > -1 && !isChrome) {
    return BrowserType.Safari;
  } else if (userAgent.indexOf('OP') > -1 && !isChrome) {
    return BrowserType.Opera;
  } else {
    return BrowserType.Undefined;
  }
};

export const scrollToSection = (moduleId: string, initHeight: number) => {
  const moduleCard = document.getElementById(moduleId);

  if (!moduleCard) {
    return;
  }

  const scrollOffset = initHeight + 24;
  const topOfElement = moduleCard.offsetTop - scrollOffset;

  window.scroll({ top: topOfElement, behavior: 'smooth' });
};

export const resolveImageUrl = (url: string) => {
  if (url.includes('https') || url.includes('http')) {
    return url;
  }

  return process.env.REACT_APP_IMAGES_SERVICE_URL + url;
};

/**
 *
 * @param url
 */

export const handleUrlLinkClicked = (url: string | null) => {
  if (!url) {
    return;
  }

  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return window.open(`http://${url}`, '_blank');
  }
  return window.open(url, '_blank');
};

export const countDecimals = (number: number) => {
  if (Math.floor(number) === number) return 0;
  return number.toString().split('.')[1].length || 0;
};

export const urlSchemeRegExp = new RegExp(
  '^((?:http|ftp)s?:\\/\\/)', // scheme only
  'i'
);

export const urlWwwDomain = new RegExp(
  '^(?<hostname>(?:www\\.)(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,})', // domain name
  'i'
);

export const urlNonWwwDomain = new RegExp(
  '^[^(?:www\\.)](?<hostname>((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.){1}[a-z]{2,}))', // domain name
  'i'
);

export const urlLongDomain = new RegExp(
  '(?<hostname>(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.){2,}[a-z]{2,})', // domain name
  'i'
);

export const fullUrlRegExp = new RegExp(
  '^(?<protocol>((?:http|ftp)s?):\\/\\/)?' + // protocol
    '(?<hostname>(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}' + // domain name
    '|((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(?<port>\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(?<query>\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(?<fragemnt>\\#[-a-z\\d_]*)?$', // fragment locator
  'i'
);

export const domainOnlyRegExp = new RegExp(
  '^([^(?:https?)|(ftp):\\/\\/])' + // protocol
    '(?<hostname>(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.){2,}[a-z]{2,})' + // domain name
    '(?<port>\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(?<query>\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(?<fragemnt>\\#[-a-z\\d_]*)?$', // fragment locator
  'i'
);

export const isValidUrl = (
  urlString: string | Function | any,
  cntx: any = {}
): boolean => {
  if (urlString === null && cntx.schema.spec.nullable) return true;

  if (urlSchemeRegExp.test(urlString)) {
    try {
      const url = urlString.match(fullUrlRegExp);

      if (url.groups.hostname) {
        const hostname = url.groups.hostname;

        if (urlWwwDomain.test(hostname)) {
          return urlLongDomain.test(hostname);
        } else if (hostname) {
          return urlNonWwwDomain.test(hostname);
        }
      }

      return false;
    } catch {
      return false;
    }
  } else {
    return domainOnlyRegExp.test(urlString);
  }
};

export const convertUnits = (value: number, from: any, to: any) => {
  if (!value) {
    return 0;
  }
  return roundToDecimals(convert(value).from(from).to(to), 6);
};

export const getWeightUnitAbbreviation = (unit: string | null) => {
  switch (true) {
    case unit === DefaultWeightUom.Ounces:
      return 'oz';
    case unit === DefaultWeightUom.Tons:
      return 'mt';
    case unit === DefaultWeightUom.Kilograms:
      return 'kg';
    case unit === DefaultWeightUom.Grams:
      return 'g';
    default:
      return 'lb';
  }
};

export const getErrorMessage = (error: unknown): string => {
  if (error instanceof Error) {
    return error.message;
  }
  return String(error);
};

/**
 * Returns currency exchange rate if there is no custom exchange rate
 *
 * @param {Vendor | Customer} transactor - vendor or customer object
 * @returns {number}
 */
export const getExchangeRate = (transactor: Vendor | Customer | null): number =>
  transactor
    ? _.get(transactor, 'customExchangeRate', null) ??
      _.get(transactor, 'currency.exchangeRate', null)
    : 1;

export const camelCaseKeys = <R>(record: Record<string, any>): R => {
  return _.mapKeys(record, (_value, key) => _.camelCase(key)) as R;
};

/**
 * Formats a given date based on the company country and international date format.
 *
 * @param {string | null | undefined} date - The date to be formatted.
 * @param {string} companyCountry - The country of the company.
 * @param {string} intlDateFormat - The international date format to be used.
 * @return {string} The formatted date.
 */
export const formatDate = (
  date: string | null | undefined,
  companyCountry: string | null,
  intlDateFormat: string
): string => {
  if (!date) {
    return '';
  }
  if (companyCountry && companyCountry === USCountryString) {
    return moment(date).format(intlDateFormat);
  } else {
    return moment(date, 'YYYY-MM-DD').format('YYYY/MM/DD');
  }
};
export const formatDatePreview = (
  date: string | null | undefined,
  companyCountry: string | null,
  intlDateFormat: string
): string => {
  if (!date) {
    return '';
  }
  if (companyCountry && companyCountry === USCountryString) {
    return moment(date).format(intlDateFormat);
  } else {
    return moment(date).format('DD/MM/YYYY');
  }
};
