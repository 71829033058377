import { PaymentTerm } from 'services/paymentTerms';
import { PermissionType } from 'services/permissions';

export const editPaymenTermPermissions = (
  paymenTerm: PaymentTerm
): PermissionType[] => {
  if (paymenTerm.deleted) {
    return [PermissionType.Undelete];
  }
  return paymenTerm.id && paymenTerm.id > 0
    ? [PermissionType.PaymentTermsEdit]
    : [PermissionType.PaymentTermsCreate];
};
