import { IconNames } from 'ui/theme';
import { MenuItem, MenuItemVariant } from 'ui/components/Menu';

export enum LocationItemTableRowActions {
  Delete,
}

export const createActionBarOptions = (
  onAction: (type: LocationItemTableRowActions) => void
): MenuItem[] => [
  {
    label: 'Delete location',
    onOptionClick: () => onAction(LocationItemTableRowActions.Delete),
    icon: IconNames.FBODeleteSmall,
    dataQa: 'location-item-menu-delete',
    variant: MenuItemVariant.Danger,
  },
];
