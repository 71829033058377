import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { TrackingTableProps } from './types';

export const useTrackingTableStyle = makeStyles<Theme, TrackingTableProps>(
  () => ({
    container: {
      display: 'flex',
      overflowY: 'auto',
      flexDirection: 'column',
      height: 'calc(100% - 48px)',
    },
  })
);
