import { transformUom } from 'services/uoms';
import { InventoryLocationSummary } from 'services/inventory';
import { transformToTag } from 'services/tags';
import { resolveImageUrl, transformDateToMomentDate } from 'helpers';

import {
  transformBundleItem,
  transformItem,
  transformItemImage,
} from '../transformations';
import { SaleItem, SaleItemPriceRule } from './types';
import { defaultSaleItem } from './consts';

export const transformSaleItem = (res: any): SaleItem => {
  const imageLinkList = res.images || [];
  const resDefaultImage = imageLinkList.find((i: any) => i.defaultFlag);
  const inventoryLocationSummaryList = res.inventoryLocationSummaries || [];
  const tags = res.tags || [];

  return {
    id: res.id,
    accountingClassId: res.accountingClassId,
    alertNotes: res.alertNotes,
    defaultFlag: res.defaultFlag,
    defaultImage: resDefaultImage ? resolveImageUrl(resDefaultImage.url) : null,
    defaultUom: res.defaultUom ? transformUom(res.defaultUom) : null,
    defaultUomId: res.defaultUomId!,
    defaultSalesOrderItemType: res.defaultSalesOrderItemType,
    deleted: res.deleted,
    description: res.description,
    details: res.details,
    dimensionUnit: res.dimensionUnit,
    height: res.height,
    imageLinkList: imageLinkList.map((i: any) => transformItemImage(i)),
    incomeAccountId: res.incomeAccountId,
    inventoryLocationSummaryList: inventoryLocationSummaryList.map((i: any) =>
      transformInventoryLocationSummaryList(i)
    ),
    item: res.item ? transformItem(res.item) : null,
    itemId: res.itemId,
    length: res.length,
    name: res.name,
    price: res.price,
    salesTaxId: res.salesTaxId,
    sku: res.sku,
    tags: tags.map(transformToTag),
    taxable: res.taxable,
    productTaxCode: res.productTaxCode,
    upc: res.upc,
    url: res.url,
    version: res.version,
    weight: res.weight,
    weightUnit: res.weightUnit,
    width: res.width,
    bundleItems: [],
  };
};

const transformInventoryLocationSummaryList = (
  res: any
): InventoryLocationSummary => {
  return {
    availableQty: res.availableQty,
    committedQty: res.committedQty,
    totalQty: res.totalQty,
    itemId: res.itemId,
    rootLocationId: res.rootLocationId,
    qtyIncomingOnPurchaseOrders: res.qtyIncomingOnPurchaseOrders,
    qtyIncomingOnSalesOrders: res.qtyIncomingOnSalesOrders,
    allocatedOnPurchaseOrders: res.allocatedOnPurchaseOrders,
    allocatedOnSalesOrders: res.allocatedOnSalesOrders,
  };
};

export const transformSaleItemPricingRule = (res: any): SaleItemPriceRule => ({
  ...res,
  date: transformDateToMomentDate(res.data),
});

export const transformSaleItemsWithBundles = (res: any): SaleItem[] => {
  const bundleItems: SaleItem[] = res.bundles.map((i: any) => {
    const resDefaultImage =
      i.images && i.images.find((img: any) => img.defaultFlag);

    return {
      ...defaultSaleItem,
      id: i.id,
      name: i.name,
      item: transformItem(i),
      defaultSalesOrderItemType: 'Bundle',
      price: i.price,
      defaultUom: i.defaultUom ? transformUom(i.defaultUom) : null,
      defaultUomId: i.defaultUomId,
      defaultImage: resDefaultImage
        ? resolveImageUrl(resDefaultImage.url)
        : null,
      bundleItems: i.bundleItems.map(transformBundleItem),
      inventoryLocationSummaryList: i.inventoryLocationSummaries.map(
        transformInventoryLocationSummaryList
      ),
    };
  });

  const saleItems: SaleItem[] = res.saleItems.map(transformSaleItem);

  return [...bundleItems, ...saleItems];
};
