import { CostingMethod } from '../InventoryValuationSummaryFilters/types';
import { GroupByOption } from './types';

export const groupByOptions: GroupByOption[] = [
  { type: 'saleItem', title: 'Sale item' },
  { type: 'customer', title: 'Customer' },
];

export const costingMethods: CostingMethod[] = [
  { type: 'Actual', title: 'Actual' },
  { type: 'Average', title: 'Average' },
];
