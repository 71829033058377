import { SalesOrderItem } from 'services/salesOrders';
import { Item } from 'services/items';
import { TrackingGroup } from 'services/inventory';
import { ItemReference } from 'services/api';
import { PurchaseOrderItem } from 'services/purchaseOrders';
import { CustomField } from 'services/customFields';
import { Location } from 'services/locations';
import { StockStatus } from 'ui/theme/components/FBOStockStatusTag/types';

export interface Pick {
  id: number;
  customFields: CustomField[];
  dateCreated: Date | null;
  dateLastModified: Date | null;
  notes: string | null;
  number: string;
  orderPriorityId: number | null;
  parentLocationId: number | null;
  parentLocation: Location | null;
  pickId: number | null;
  pickItems: PickItem[];
  pickBundleItems: PickBundleItem[];
  purchaseOrders: ItemReference[];
  salesOrders: ItemReference[];
  scheduledDate: Date | null;
  shipments: ItemReference[];
  status: PickStatus;
  stockStatus: StockStatus | null;
  userId: number;
  version: number;
  deleted: boolean | null;
}

export interface DisplayPick {
  id: number;
  number: string | null;
  status: PickStatus | null;
  purchaseOrderId: number | null;
  salesOrderId: number | null;
  stockStatus: StockStatus | null;
  parentLocation: PickLocation | null;
  orderPriority: PickOrderPriority | null;
  customer: PickCustomer | null;
  dateCreated: Date | null;
  dateLastModified: Date | null;
  scheduledDate: Date | null;
}

export interface PickItem {
  id: number | null;
  amount: number | null;
  autoAssign: boolean;
  bundleId: number | null;
  dateCreated: Date | null;
  dateLastModified: Date | null;
  dateStarted: Date | null;
  fromLocationQuantityAvailable: number | null;
  inventoryEventId: number | null;
  item: Item | null;
  itemId: number | null;
  notes: string | null;
  pickFromLocation: Location | null;
  pickFromLocationId: number | null;
  pickId: number | null;
  pickToLocation: Location | null;
  pickToLocationId: number | null;
  purchaseOrderItem: PurchaseOrderItem | null;
  purchaseOrderItemId: number | null;
  quantity: number | null;
  quantityAvailable: number | null;
  salesOrderItem: SalesOrderItem | null;
  salesOrderItemId: number | null;
  shipId: number | null;
  slotNumber: number | null;
  status: PickItemStatus;
  trackingGroupList: TrackingGroup[];
  uomId: number | null;
  version: number | null;
}
export interface PickBundleItem {
  id: number | null;
  item: Item | null;
  itemId: number | null;
  pickId: number | null;
  pickItems: PickItem[];
  version: number | null;
}

export interface UpdatePickItem {
  id: number;
  itemId: number;
  notes: string | null;
  pickFromLocationId: number | null;
  pickToLocationId: number;
  quantity: number;
  slotNumber: number;
  status: string;
  uomId: number;
  version: number;
  deleted: boolean;
}

export interface PickLocation {
  id: string;
  name: string;
}

export interface PickOrderPriority {
  id: number;
  name: string;
}

export interface PickCustomer {
  id: number;
  name: string;
}

export enum PickStatus {
  'Open' = 'Open',
  'Started' = 'Started',
  'PartiallyPicked' = 'Partially Picked',
  'Committed' = 'Committed',
  'Finished' = 'Finished',
}

export enum PickItemStatus {
  'Available' = 'Available',
  'Started' = 'Started',
  'Hold' = 'Hold',
  'Short' = 'Short',
  'Committed' = 'Committed',
  'Finished' = 'Finished',
}

export interface PickItemQuantities {
  itemId: number;
  locationId: number | null;
  rootLocationId: number | null;
  onHandQty: number | null;
  ommittedQty: number | null;
  availableToPickQty: number | null;
}
