import React, { memo } from 'react';
import styled from 'styled-components';
import { TabPanelCmp, TabPanelBoxStyledProps } from './types';

import styles from './styled';

const TabPanel: TabPanelCmp = (panelProps: TabPanelBoxStyledProps) => {
  const { children, index, className, ...rest } = panelProps;

  if (rest.value !== index) {
    return null;
  }

  return (
    <div
      role="tabpanel"
      id={`tabpanel-${index}`}
      className={className}
      {...rest}
    >
      {children}
    </div>
  );
};

/*
 * https://styled-components.com/docs/api#shouldforwardprop
 * Property: shouldForwardProp
 *
 * shouldForwardProp works like Array.filter() and passes down to the component
 * only allowed properties.
 * */
const TabPanelStyled = styled(TabPanel).withConfig({
  shouldForwardProp: (forwardProps) =>
    ['children', 'index', 'value'].includes(forwardProps),
})((styleProps) => ({ ...styles(styleProps) }));

export default memo(TabPanelStyled);
