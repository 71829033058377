import _ from 'lodash';

import { RequestConfig, defaultMaximumPagination } from 'helpers';
import {
  createApiCall,
  DataWithPagination,
  paginatedApiCall,
} from 'services/api';

import { Receipt, ReceiptItem, ReceiptItemReceive, Reconcile } from './types';
import {
  tranformReceiptItemToFulfill,
  transformReceipt,
  transformReceiptItemReceiveToPostReceiptItemReceive,
  transformReceiptItemToPutReceiptItem,
  transformReceiptToPatchReceipt,
  transformRejectItemToPost,
  transformToPostReconcile,
} from './transformations';

const expands = [
  'purchaseOrder',
  'purchaseOrder.fobPoint',
  'receiptItems.purchaseOrderItem',
  'receiptItems.location',
  'receiptItems.item.images',
  'receiptItems.item.itemUomConversions',
  'receiptItems.uom.fromConversions',
  'receiptItems.uom.toConversions',
  'receiptItems.item.itemTrackingTypes.trackingType',
  'receiptItems.item.itemLocations',
  'salesOrder',
  'salesOrder.exchangeCurrency',
];

export const fetchReceiptsAPI = async (
  config: RequestConfig
): Promise<DataWithPagination<Receipt>> => {
  const {
    pagination = defaultMaximumPagination,
    expands = [],
    customQuickSearchColumns = [],
    quickSearchValue,
  } = config;

  let path = `/v1/receipts?expand=${expands.join(',')}`;

  if (quickSearchValue) {
    path += `&quickSearchColumns=${customQuickSearchColumns}&quickSearchValue=${encodeURIComponent(
      quickSearchValue
    )}`;
  }

  const res = await paginatedApiCall(path, pagination, transformReceipt);

  return res;
};

export const fetchReceiptById = async (id: number): Promise<Receipt> => {
  const response = await createApiCall({
    path: `/v1/receipts/${id}?expand=${expands.join(',')}`,
    method: 'GET',
  })();

  return transformReceipt(response.data);
};

export const updateReceiptItem = async (
  id: number,
  receiptItem: ReceiptItem
): Promise<Receipt> => {
  const response = await createApiCall(
    {
      path: `/v1/receipts/${id}/receipt_items?expand=${expands.join(',')}`,
      method: 'PUT',
      body: [transformReceiptItemToPutReceiptItem(receiptItem)],
    },
    {
      getMessage: {
        success: () => 'Receipt item successfully updated',
        error: (error: any) => error.response.data.message,
      },
    }
  )();

  return transformReceipt(response.data);
};

export const updateReceipt = async (receipt: Receipt) => {
  await createApiCall(
    {
      path: `/v1/receipts`,
      method: 'PATCH',
      body: transformReceiptToPatchReceipt(receipt),
    },
    {
      getMessage: {
        success: () => 'Receipt successfully updated',
        error: (error: any) => error.response.data.message,
      },
    }
  )();
};

export const rejectItem = async (
  id: number | null,
  receiptItem: ReceiptItem
): Promise<Receipt> => {
  const response = await createApiCall(
    {
      path: `/v1/receipts/${id}/receipt_items/${
        receiptItem.id
      }/reject?expand=${expands.join(',')}`,
      method: 'POST',
      body: transformRejectItemToPost(receiptItem),
    },
    {
      getMessage: {
        success: () => 'Item rejected',
        error: (error: any) => error.response.data.message,
      },
    }
  )();
  return transformReceipt(response.data);
};

export const receiveReceipt = async (
  receiptId: number,
  receiptItemReceives: ReceiptItemReceive[]
): Promise<Receipt> => {
  const response = await createApiCall(
    {
      path: `/v1/receipts/${receiptId}/receive?expand=${expands.join(',')}`,
      method: 'POST',
      body: receiptItemReceives.map(
        transformReceiptItemReceiveToPostReceiptItemReceive
      ),
    },
    {
      getMessage: {
        success: () => 'Receipt items successfully received',
        error: (error: any) => error.response.data.message,
      },
    }
  )();
  return transformReceipt(response.data);
};

export const receiveReceiptItem = async (
  receiptId: number,
  receiptItemId: number,
  receiptItemReceive: ReceiptItemReceive
): Promise<Receipt> => {
  const response = await createApiCall(
    {
      path: `/v1/receipts/${receiptId}/receipt_items/${receiptItemId}/receive?expand=${expands.join(
        ','
      )}`,
      method: 'POST',
      body: transformReceiptItemReceiveToPostReceiptItemReceive(
        receiptItemReceive
      ),
    },
    {
      getMessage: {
        success: () => 'Receipt item successfully received',
        error: (error: any) => error.response.data.message,
      },
    }
  )();
  return transformReceipt(response.data);
};

export const voidReceipt = async (id: number): Promise<Receipt> => {
  const response = await createApiCall(
    {
      path: `/v1/receipts/${id}/void?expand=${expands.join(',')}`,
      method: 'POST',
      body: [],
    },
    {
      getMessage: {
        success: () => 'Receipt successfully voided',
        error: (error: any) => error.response.data.message,
      },
    }
  )();
  return transformReceipt(response.data);
};

export const voidReceiptItem = async (
  id: number,
  receiptItemId: number
): Promise<Receipt> => {
  const response = await createApiCall(
    {
      path: `/v1/receipts/${id}/receipt_items/${receiptItemId}/void?expand=${expands.join(
        ','
      )}`,
      method: 'POST',
    },
    {
      getMessage: {
        success: () => 'Receipt item successfully voided',
        error: (error: any) => error.response.data.message,
      },
    }
  )();

  return transformReceipt(response.data);
};

export const holdReceiptItem = async (
  id: number,
  receiptItemId: number
): Promise<Receipt> => {
  const response = await createApiCall(
    {
      path: `/v1/receipts/${id}/receipt_items/${receiptItemId}/hold?expand=${expands.join(
        ','
      )}`,
      method: 'POST',
    },
    {
      getMessage: {
        success: () => 'Receipt item successfully held',
        error: (error: any) => error.response.data.message,
      },
    }
  )();

  return transformReceipt(response.data);
};

export const voidReceipts = async (ids: number[]): Promise<void> => {
  await createApiCall(
    {
      path: `/v1/receipts/void`,
      body: ids,
      method: 'POST',
    },
    {
      getMessage: {
        success: (response: any) => response.data,
        error: (error: any) => error.response.data.message,
      },
    }
  )();
};

export const reconcileReceipt = async (
  id: number,
  reconcileData: Reconcile
): Promise<Receipt> => {
  const response = await createApiCall(
    {
      path: `/v1/receipts/${id}/reconcile?expand=${expands.join(',')}`,
      method: 'POST',
      body: transformToPostReconcile(id, reconcileData),
    },
    {
      getMessage: {
        success: () => 'Receipt successfully reconciled',
        error: (error: any) => error.response.data.message,
      },
    }
  )();

  return transformReceipt(response.data);
};

export const fulfillReceipt = async (
  id: number,
  receiptItemsReceive: ReceiptItemReceive[] = [],
  receiptItems: ReceiptItem[] = []
): Promise<Receipt> => {
  const getBody = () => {
    if (!_.isEmpty(receiptItems)) {
      return receiptItems.map(tranformReceiptItemToFulfill);
    }
    return receiptItemsReceive.map(
      transformReceiptItemReceiveToPostReceiptItemReceive
    );
  };

  const response = await createApiCall(
    {
      path: `/v1/receipts/${id}/fulfill?expand=${expands.join(',')}`,
      method: 'POST',
      body: getBody(),
    },
    {
      getMessage: {
        success: () => 'Receipt successfully fulfilled',
        error: (error: any) => error.response.data.message,
      },
    }
  )();

  return transformReceipt(response.data);
};

export const fulfillReceiptItem = async (
  receiptId: number,
  receiptItem?: ReceiptItem,
  receiptItemReceive?: ReceiptItemReceive
): Promise<Receipt> => {
  let itemId;
  let body;

  if (receiptItem) {
    itemId = receiptItem.id!;
    body = tranformReceiptItemToFulfill(receiptItem!);
  } else {
    itemId = receiptItemReceive!.receiptItemId;
    body = transformReceiptItemReceiveToPostReceiptItemReceive(
      receiptItemReceive!
    );
  }

  const response = await createApiCall(
    {
      path: `/v1/receipts/${receiptId}/receipt_items/${itemId}/fulfill?expand=${expands.join(
        ','
      )}`,
      method: 'POST',
      body,
    },
    {
      getMessage: {
        success: () => 'Receipt successfully fulfilled',
        error: (error: any) => error.response.data.message,
      },
    }
  )();

  return transformReceipt(response.data);
};
