import { chainReducers, createApiReducer } from 'services/api';

import { SettingsShippingActionTypes } from './actions';
import { transformShippingSettings } from '../transformations';

const fetchSettingsShippingReducer = createApiReducer(
  [
    SettingsShippingActionTypes.FETCH_SHIPPING_START,
    SettingsShippingActionTypes.FETCH_SHIPPING_SUCCESS,
    SettingsShippingActionTypes.FETCH_SHIPPING_FAILURE,
  ],
  (data) => transformShippingSettings(data),
  (data) => data
);

const settingsShippingChainedReducers = chainReducers(
  {},
  fetchSettingsShippingReducer
);

export default settingsShippingChainedReducers;
