import React, { useState, useCallback } from 'react';
import _ from 'lodash';
import { Grid } from '@mui/material';
import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { useHandleTextFieldChange } from 'services/forms';
import {
  TrackingType,
  NewTrackingType,
  TrackingDataTypesOption,
} from 'services/settings/tracking';
import { validateYup } from 'services/forms/validation';
import { TrackingTypeModalCmp, TrackingTypeModalProps } from './types';
import { trackingTypeSchema } from './validations';

const TrackingTypeModal: TrackingTypeModalCmp = (
  props: TrackingTypeModalProps
) => {
  const {
    activeTrackingType,
    setActiveTrackingType,
    showModal,
    onSaveClicked,
    onCancelClicked,
    onResetClicked,
  } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<any>({});
  const isEditingTrackingField = _.has(activeTrackingType, 'id');
  const handleTextFieldChange = useHandleTextFieldChange<
    TrackingType | NewTrackingType
  >(setActiveTrackingType, activeTrackingType);

  const handleSaveClicked = useCallback(() => {
    setIsLoading(true);

    const isValid = validateYup(
      activeTrackingType,
      trackingTypeSchema,
      setValidationErrors
    );
    if (!isValid) {
      setIsLoading(false);
      return;
    }

    onSaveClicked();
    setIsLoading(false);
  }, [activeTrackingType, onSaveClicked]);

  const handleTrackingDataTypeChange = useCallback(
    (e: any, value: any) => {
      setActiveTrackingType({
        ...activeTrackingType,
        trackingDataType: value,
      });
    },
    [activeTrackingType, setActiveTrackingType]
  );

  return (
    <Modal
      open={showModal}
      title={
        _.has(activeTrackingType, 'id')
          ? (activeTrackingType.name as string)
          : 'New tracking type'
      }
      onApplyClicked={handleSaveClicked}
      applyLabel="Save"
      onCancelClicked={onCancelClicked}
      resetLabel="Reset"
      onResetClicked={onResetClicked}
      isLoading={isLoading}
      dataQa="tracking-modal"
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="Name"
            placeholder="Enter name"
            name="name"
            autoComplete="off"
            required
            onChange={handleTextFieldChange}
            value={activeTrackingType.name}
            error={!!validationErrors.name}
            dataQa="tracking-modal-name"
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            options={Object.values(TrackingDataTypesOption)}
            label="Type"
            placeholder="Enter type"
            name="trackingDataType"
            required
            onChange={handleTrackingDataTypeChange}
            value={activeTrackingType.trackingDataType}
            error={!!validationErrors.trackingDataType}
            dataQa="tracking-modal-type"
            disabled={isEditingTrackingField}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="Description"
            placeholder="Enter description"
            name="description"
            autoComplete="off"
            required
            onChange={handleTextFieldChange}
            value={activeTrackingType.description}
            error={!!validationErrors.description}
            dataQa="tracking-modal-description"
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default TrackingTypeModal;
