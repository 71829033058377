import {
  createFilterOptions,
  FilterOptionsState,
} from '@mui/material/useAutocomplete';
import {
  CustomerCarrierAccount,
  initialCustomerCarrierAccount,
} from 'services/customers';

export const carrierAccountAutocompleteGetOptionLabel = (
  customerCarrierAccount: CustomerCarrierAccount
) => {
  if (!customerCarrierAccount.id || customerCarrierAccount.id < 0) {
    return `+ Add "${customerCarrierAccount.name}"`;
  }

  return `${customerCarrierAccount.name} - ${customerCarrierAccount.accountNumber}`;
};

const filter = createFilterOptions<CustomerCarrierAccount>();

export const carrierAccountAutocompleteFilterOptions =
  (disableAdd: boolean) =>
  (
    options: CustomerCarrierAccount[],
    params: FilterOptionsState<CustomerCarrierAccount>
  ) => {
    const filtered = filter(options, params);

    if (disableAdd) {
      return filtered;
    }

    if (!!params.inputValue) {
      // add default item at the beginning of options
      filtered.unshift({
        ...initialCustomerCarrierAccount,
        name: params.inputValue || null,
      });
    }

    return filtered;
  };
