import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colorPalette } from 'ui/theme';

const BASE_ROW_HEIGHT = 56;

export const useCardRowStyle = makeStyles<Theme>((theme: Theme) => {
  return {
    content: {
      width: 'auto',
      flex: 1,
      padding: 0,
    },
    sidebar: {
      width: 300,
      height: '100%',
      maxHeight: 600,
      overflow: 'auto',
      borderLeft: `1px solid ${colorPalette.applicationDarkGray10}`,
    },
    buttonLink: {
      'text-transform': 'none',
      'white-space': 'pre',
      cursor: 'pointer',
    },
    expand: {
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    cartonName: {
      fontWeight: 700,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingTop: '4px',
    },
    cartonBox: {
      height: 200,
      maxHeight: 200,
      overflowY: 'auto',
      overflowX: 'hidden',
      paddingRight: 30,
    },
    button: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      borderRadius: theme.spacing(4),
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    label: {
      overflow: 'hidden',
      '& .MuiFormControlLabel-label': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    buttonGroup: {
      display: 'flex',
      marginLeft: 'auto',
      '& > button': {
        marginLeft: theme.spacing(2),
      },
    },
    wrapper: {
      display: 'flex',
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      minHeight: BASE_ROW_HEIGHT,
      height: BASE_ROW_HEIGHT,
      padding: theme.spacing(2),
      cursor: 'pointer',
    },
    heading: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontStyle: 'normal',
      fontSize: '12px',
      paddingRight: 0,
      lineHeight: 1.2,
    },
    tabPanelBox: {
      maxHeight: 360,
      overflowY: 'auto',
    },
  };
});
