import React, { useCallback } from 'react';

import {
  getSearches,
  fetchSearch,
  GLOBAL_USERS_COLUMNS,
  transformGlobalUser,
  GlobalUser,
} from 'services/user';
import { Pagination } from 'services/search';
import { PageWithAdvancedSearch } from 'ui/components/Page/PageWithAdvancedSearch';
import { withSearchResults } from 'ui/components/Page/WithSearchResults';
import { PaperSlidingLayout } from 'ui/components/Paper/PaperSlidingLayout';

import { Routes } from '../../navigation';
import {
  RemoteSupportPageTable,
  RemoteSupportUsersAdvancedSearch,
} from './components';
import { RemoteSupportPageCmp } from './types';
import {
  advancedSearchReduxActions,
  displayNameMap,
  initialUsersFormValues,
  initialPagination,
} from './consts';

const RemoteSupportPage: RemoteSupportPageCmp = (props) => {
  const {
    searchState: searchResult,
    refreshSearchState: fetchSearchResult,
    isLoadingSearchState: isLoadingSearchResult,
  } = props;

  const handlePaginationChange = useCallback(
    (newPagination: Pagination) => {
      fetchSearchResult({ pagination: newPagination });
    },
    [fetchSearchResult]
  );

  return (
    <PageWithAdvancedSearch
      detailCardColumns={GLOBAL_USERS_COLUMNS}
      initialFormValues={initialUsersFormValues}
      advancedSearchReduxActions={advancedSearchReduxActions}
      searchResult={searchResult}
      fetchSearchResult={fetchSearchResult}
      AdvancedSearchFieldsCmp={RemoteSupportUsersAdvancedSearch}
      displayNameMap={displayNameMap}
      pageName="Users"
    >
      <PaperSlidingLayout shown={false}>
        <RemoteSupportPageTable
          users={searchResult.items}
          isLoadingUsers={isLoadingSearchResult}
          pagination={searchResult.pagination || initialPagination}
          onPaginationChange={handlePaginationChange}
          fetchSearchResult={fetchSearchResult}
        />
        <></>
      </PaperSlidingLayout>
    </PageWithAdvancedSearch>
  );
};

RemoteSupportPage.route = Routes.RemoteSupportPage;

export default withSearchResults<GlobalUser>(RemoteSupportPage, {
  url: '/v1/admin/users',
  expand: 'company.crmAssociations',
  dataAdapter: transformGlobalUser,
  initialPagination,
  quickSearchColumns: [
    'nameFirst',
    'nameLast',
    'email',
    'company.tenant',
    'company.name',
    'company.crmAssociations.crmId',
  ],
  columns: [],
  getSearches,
  fetchSearch,
});
