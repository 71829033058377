import React, { useMemo } from 'react';
import { TableCell } from '@mui/material';
import moment from 'moment';
import _ from 'lodash';

import { DateTooltip } from 'ui/components/DateTooltip';
import { DateMessage } from 'ui/components/DateTooltip/types';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import { PricingRule } from 'services/pricingRules';
import { useGetIntlDateFormatString } from 'helpers';

import { usePageCustomRowStyle } from './styled';

const PageCustomRow: React.FC<RenderCustomRowProps<PricingRule>> = (props) => {
  const {
    row,
    columns,
    meta: { activePricingRuleId },
  } = props;

  const classes = usePageCustomRowStyle();
  const intlFormatDate = useGetIntlDateFormatString();

  const tooltipDates: DateMessage[] = useMemo(
    () => [
      { date: row.dateCreated, message: 'Date Created' },
      { date: row.dateLastModified, message: 'Date Last Modified' },
    ],
    [row]
  );

  const dateValue: string = useMemo(() => {
    const dateField = !activePricingRuleId ? columns[3].field! : null;
    const date = dateField ? _.get(row, dateField, null) : null;

    return date ? moment(date).format(intlFormatDate) : '';
  }, [columns, row, activePricingRuleId, intlFormatDate]);

  return (
    <>
      <TableCell className={classes.cell}>{row.name}</TableCell>
      {!activePricingRuleId && (
        <TableCell className={classes.cell}>{row.description}</TableCell>
      )}
      <TableCell>{row.type}</TableCell>
      {!activePricingRuleId && (
        <DateTooltip values={tooltipDates}>
          <TableCell>{dateValue}</TableCell>
        </DateTooltip>
      )}
    </>
  );
};

export default React.memo(PageCustomRow);
