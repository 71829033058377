import { transformDateToMomentDate } from 'helpers';
import { Account, ExportHistory, ExportLedger, WaitingToExport } from './types';

export const transformToAccount = (res: any): Account => {
  return {
    id: res.id,
    version: res.version,
    name: res.name,
    number: res.number,
    accountType: res.accountType,
    accountMappingId: res.accountMappingId,
    integrationsTypeFilter: res.integrationsTypeFilter,
  };
};

export const transformRequestAccounts = (accounts: Account): any => {
  return {
    id: accounts.id,
    version: accounts.version,
    name: accounts.name,
    number: accounts.number,
    accountMappingId: accounts.accountMappingId,
    integrationsTypeFilter: accounts.integrationsTypeFilter,
  };
};

export const transformToWaitingToExport = (res: any): WaitingToExport => {
  return {
    bills: res.bills,
    inventoryAdjustments: res.inventoryAdjustments,
    invoices: res.invoices,
    vendorCredits: res.vendorCredits,
    itemReceipts: res.itemReceipts,
    payments: res.payments,
    creditMemos: res.creditMemos,
    journalEntries: res.journalEntries,
  };
};

export const transformExportHistory = (res: any): ExportHistory => {
  return {
    id: res.id,
    checkVersion: res.checkVersion,
    createdBy: res.createdBy,
    createdById: res.createdById,
    dateCreated: transformDateToMomentDate(res.dateCreated),
    dateLastModified: transformDateToMomentDate(res.dateLastModified),
    jwt: res.jwt,
    lastModifiedBy: res.lastModifiedBy,
    lastModifiedById: res.lastModifiedById,
    ledger: res.ledger ? transformExportLedger(res.ledger) : null,
    ledgerId: res.ledgerId,
    message: res.message,
    parentAccountingHistory: res.parentAccountingHistory,
    parentAccountingHistoryId: res.parentAccountingHistoryId,
    type: res.type,
    version: res.version,
  };
};

export const transformExportLedger = (res: any): ExportLedger => {
  return {
    id: res.id,
    checkVersion: res.checkVersion,
    createdBy: res.createdBy,
    createdById: res.createdById,
    customer: res.customer,
    customerId: res.customerId,
    dateCreated: res.dateCreated,
    dateLastModified: res.dateLastModified,
    datePosted: res.datePosted,
    inventoryEvent: res.inventoryEvent,
    inventoryEventId: res.inventoryEventId,
    jwt: res.jwt,
    lastModifiedBy: res.lastModifiedBy,
    lastModifiedById: res.lastModifiedById,
    ledgerItems: res.ledgerItems,
    note: res.note,
    payment: res.payment,
    paymentId: res.paymentId,
    postDate: res.postDate,
    purchaseOrder: res.purchaseOrder,
    purchaseOrderId: res.purchaseOrderId,
    salesOrder: res.salesOrder,
    salesOrderId: res.salesOrderId,
    type: res.type,
    user: res.user,
    userId: res.userId,
    version: res.version,
  };
};
