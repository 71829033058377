import React, { memo } from 'react';

import { ImageStorageType } from 'services/images';
import { DocumentsTable } from 'ui/components/Documents/DocumentsTable';

import { editItemPermissions } from '../../helpers';
import { ItemDocumentsTabProps } from './types';

const ItemDocumentsTab: React.FC<ItemDocumentsTabProps> = (props) => {
  const { item, setItem } = props;

  const editPermissions = editItemPermissions(item);

  return (
    <DocumentsTable
      storageType={ImageStorageType.ItemDocuments}
      data={item}
      setData={setItem}
      permissions={editPermissions}
    />
  );
};

export default memo(ItemDocumentsTab);
