import { RequestConfig, defaultMaximumPagination } from 'helpers';
import {
  createApiCall,
  DataWithPagination,
  paginatedApiCall,
} from 'services/api';

import {
  transformCustomField,
  transformToRequestCustomField,
} from './transformations';
import { CustomField, ObjectType } from './types';

export const fetchCustomFieldsAPI = async (
  config: RequestConfig,
  module: ObjectType
): Promise<DataWithPagination<CustomField>> => {
  const { pagination = defaultMaximumPagination, expands = [] } = config;

  const path = `/v1/custom_fields?expand=${expands.join(',')}`;

  const res = await paginatedApiCall(path, pagination, transformCustomField);

  return {
    ...res,
    data: res.data.filter((r: CustomField) => r.objectType === module),
  };
};

export const putCustomFields = async (
  customFields: CustomField[],
  module: ObjectType
): Promise<CustomField[]> => {
  const resp = await createApiCall(
    {
      path: `/v1/custom_fields?expand=customFieldOptions&pageSize=100&orderBy=lineNumber&ascending=true`,
      method: 'PUT',
      body: customFields.map((c) => transformToRequestCustomField(c)),
    },
    {
      getMessage: {
        success: () => 'Custom fields updated',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return resp.data.results
    .map(transformCustomField)
    .filter((r: CustomField) => r.objectType === module);
};

export const postCustomFields = async (
  customFields: CustomField[],
  module: ObjectType
): Promise<CustomField[]> => {
  const resp = await createApiCall(
    {
      path: `/v1/custom_fields?expand=customFieldOptions&pageSize=100`,
      method: 'POST',
      body: customFields.map((c) => transformToRequestCustomField(c, module)),
    },
    {
      getMessage: {
        success: () => 'Custom field created',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return resp.data.results
    .map(transformCustomField)
    .filter((r: CustomField) => r.objectType === module);
};

export const deleteCustomField = async (
  customField: CustomField,
  module: ObjectType
): Promise<CustomField[]> => {
  const resp = await createApiCall(
    {
      path: `/v1/custom_fields/${customField.id}`,
      method: 'DELETE',
    },
    {
      getMessage: {
        success: () => 'Custom field deleted',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return resp.data.results
    .map(transformCustomField)
    .filter((r: CustomField) => r.objectType === module);
};
