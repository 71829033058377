import {
  refundSalesOrderPayment,
  SalesOrderPayment,
} from 'services/salesOrders';

export const refundPayment = async (
  payment: SalesOrderPayment,
  amount: number
) => {
  const { salesOrderId, id: paymentId } = payment;

  await refundSalesOrderPayment(salesOrderId!, paymentId!, amount);
};
