import * as yup from 'yup';

const phoneRegex = RegExp(/(\d{0,3})(\d{0,3})(\d{0,4})/);

export const generalSettingsValidation = yup.object({
  // defaultFileType: yup.string().required(),
  alternateEmail: yup.string().required().email(),
  shippingItem: yup.number().moreThan(0).required(),
  quickShipStrategy: yup.number().required(),
  alternatePhone: yup.string().matches(phoneRegex).required(),
});

export const carrierSettingsValidation = yup.array().of(yup.object({}));

export const internationalSettingsValidation = yup.object({
  nonDeliveryOption: yup.mixed().when('enabled', {
    is: true,
    then: yup.string(),
  }),
  contentsType: yup.mixed().when('enabled', {
    is: true,
    then: yup.string(),
  }),
  shipperEmail: yup.mixed().when('enabled', {
    is: true,
    then: yup.string().required().email(),
  }),
  eelPfc: yup.mixed().when('enabled', {
    is: true,
    then: yup.string(),
  }),
  incoterms: yup.mixed().when('enabled', {
    is: true,
    then: yup.string(),
  }),
});

export const extraSettingsValidation = yup.object({
  dryIceWeight: yup.mixed().when('containsDryIce', {
    is: true,
    then: yup.string(),
  }),
  unitOfMeasure: yup.mixed().when('containsDryIce', {
    is: true,
    then: yup.string(),
  }),
  recipientType: yup.mixed().when('containsAlcohol', {
    is: true,
    then: yup.string(),
  }),
});
