import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useCustomRowStyle = makeStyles((theme: Theme) => ({
  trackingIcon: {
    marginRight: theme.spacing(2.5),
  },
  widthStatusIcons: {
    width: '10px',
  },
  subHeading: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));
