import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { Grid, Box } from '@mui/material';
import { useSelector } from 'react-redux';

import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import { useHandleTextFieldChange } from 'services/forms';
import { CustomerItem } from 'services/customers';
import { SaleItem } from 'services/items/saleItems';
import {
  calculateHomeCurrencyPrice,
  calculateMulticurrencyPrice,
} from 'ui/modules/sales/pages/SalesOrderPage/components/SalesOrderDetailsCard/components/SalesOrderItems/helpers';
import { GridColumn, GridDivider } from 'ui/components/Grid';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';
import { roundToDecimals } from 'helpers';
import { getSettingsCompany } from 'services/settings/company';
import { MultiCurrencyWrapper } from 'ui/components/MultiCurrencyWrapper/MultiCurrencyWrapper';

import { ItemsTabModalProps } from './types';
import { SaleItemsAutocomplete } from 'ui/components/Autocomplete/SaleItemsAutocomplete';

const ItemsTabModal: React.FC<ItemsTabModalProps> = (props) => {
  const {
    onClose,
    modalVisible,
    customerItem,
    setCustomerItem,
    onSaveChanges,
    validationErrors,
    setValidationErrors,
    customerItems,
    customer,
  } = props;

  const homeCurrencyCode = _.get(
    useSelector(getSettingsCompany),
    'homeCurrency.code',
    'USD'
  );

  const currencyCode = _.get(customer, 'currency.code', null);
  const exchangeRate = _.get(customer, 'currency.exchangeRate', 1);

  const customerItemsIdArray = useMemo(() => {
    return customerItems.map((item) => {
      return item.saleItemId!;
    });
  }, [customerItems]);

  const handleTextFieldChange = useHandleTextFieldChange<CustomerItem>(
    setCustomerItem,
    customerItem
  );

  const oldCustomerItem = useMemo(
    () => customerItem,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerItem.id]
  );

  const handleMultiCurrencyPriceInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value ? parseFloat(e.target.value) : 0;

      const price = calculateHomeCurrencyPrice(value, exchangeRate);

      setCustomerItem((old) => ({
        ...old,
        multicurrencyCustomerItemPrice: value,
        price,
      }));
    },

    [exchangeRate, setCustomerItem]
  );

  const handlePriceInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value ? parseFloat(e.target.value) : 0;

      const multiPrice = calculateMulticurrencyPrice(value, exchangeRate);

      setCustomerItem((old) => ({
        ...old,
        multicurrencyCustomerItemPrice: multiPrice,
        price: value,
      }));
    },
    [exchangeRate, setCustomerItem]
  );

  const handleSaleItemChange = useCallback(
    (v: SaleItem | null) =>
      setCustomerItem({
        ...customerItem,
        saleItem: v,
        saleItemId: v ? v.id : null,
        name: v ? v.name : null,
        price: v ? v.price : null,
        multicurrencyCustomerItemPrice: ((v && v.price) || 0) * exchangeRate,
      }),
    [setCustomerItem, customerItem, exchangeRate]
  );

  const handleResetClicked = useCallback(() => {
    setCustomerItem(oldCustomerItem);
    setValidationErrors({});
  }, [oldCustomerItem, setCustomerItem, setValidationErrors]);

  return (
    <Modal
      open={modalVisible}
      title={customerItem.id! > 0 ? 'Edit Customer Item' : 'New Customer Item'}
      onCancelClicked={onClose}
      onApplyClicked={onSaveChanges}
      applyLabel={customerItem.id! > 0 ? 'Update' : 'Add'}
      onResetClicked={handleResetClicked}
      maxWidth="md"
      dataQa="customer-item-modal"
    >
      <Box px={1} py={2} width="100%" overflow="none">
        <Grid container spacing={2} alignContent={'center'}>
          <GridColumn title="Details" columnWidth={6}>
            <Grid item>
              <SaleItemsAutocomplete
                label="Sale Item"
                onChange={handleSaleItemChange}
                value={customerItem.saleItemId}
                placeholder="Select sale item"
                required
                dataQa="customer-item-autocomplete"
                error={!!validationErrors.saleItemId}
                removedIds={customerItemsIdArray}
                autoSelect={false}
              />
            </Grid>

            <Grid item>
              <TextField
                className="redesign"
                variant="standard"
                type="text"
                label="Customer Item Name"
                placeholder="Enter customer item name"
                autoComplete="nope"
                fullWidth
                name="name"
                value={customerItem.name}
                required
                onChange={handleTextFieldChange}
                error={!!validationErrors.name}
                dataQa="customer-item-name"
              />
            </Grid>
          </GridColumn>
          <GridColumn title="Price" columnWidth={6}>
            <Grid item container direction={'row'} spacing={2} wrap={'nowrap'}>
              <Grid item>
                <CurrencyField
                  label={`Price ${homeCurrencyCode}`}
                  placeholder="Enter price"
                  fullWidth
                  value={roundToDecimals(customerItem.price || 0, 6)}
                  onChange={handlePriceInputChange}
                  allowNegative
                  decimalPlaces={6}
                  required
                />
              </Grid>
              <MultiCurrencyWrapper multiCurrency={currencyCode}>
                <Grid item>
                  <TextField
                    className="redesign"
                    variant="standard"
                    type="text"
                    label="Rate"
                    placeholder="Rate"
                    fullWidth
                    value={exchangeRate}
                    disabled
                    onChange={_.noop}
                  />
                </Grid>
                <Grid item>
                  <CurrencyField
                    label={`Price ${currencyCode}`}
                    placeholder={`Price ${currencyCode}`}
                    fullWidth
                    value={roundToDecimals(
                      customerItem.multicurrencyCustomerItemPrice || 0,
                      6
                    )}
                    onChange={handleMultiCurrencyPriceInputChange}
                    currencyCode={currencyCode}
                    allowNegative
                    decimalPlaces={6}
                  />
                </Grid>
              </MultiCurrencyWrapper>
            </Grid>
          </GridColumn>
        </Grid>
      </Box>
      <GridDivider />
    </Modal>
  );
};

export default React.memo(ItemsTabModal);
