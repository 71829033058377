import { Pagination } from 'services/search';
import { fetchUsersAPI } from 'services/userV2';

export const resolvedUserFunction = (
  expands?: string[],
  customQuickSearchColumns?: string[]
) => {
  return (pagination: Pagination, searchValue: string | null) =>
    fetchUsersAPI({
      pagination,
      expands: expands,
      quickSearchValue: searchValue,
      customQuickSearchColumns,
    });
};
