import { createApiAction } from 'services/api';
import {
  StartAction,
  SuccessAction,
  FailureAction,
} from 'services/api/redux/types';
import { showNotification } from 'services/api/notifications';

import { NewTrackingType, TrackingType } from '../types';

export enum TrackingTypesActionTypes {
  FETCH_TRACKING_TYPES_START = 'tracking/fetch_tracking_types_start',
  FETCH_TRACKING_TYPES_SUCCESS = 'tracking/fetch_tracking_types_success',
  FETCH_TRACKING_TYPES_FAILURE = 'tracking/fetch_tracking_types_failure',

  POST_TRACKING_TYPES_START = 'tracking/post_tracking_types_start',
  POST_TRACKING_TYPES_SUCCESS = 'tracking/post_tracking_types_success',
  POST_TRACKING_TYPES_FAILURE = 'tracking/post_tracking_types_failure',

  PUT_TRACKING_TYPES_START = 'tracking/put_tracking_types_start',
  PUT_TRACKING_TYPES_SUCCESS = 'tracking/put_tracking_types_success',
  PUT_TRACKING_TYPES_FAILURE = 'tracking/put_tracking_types_failure',

  DELETE_TRACKING_TYPES_START = 'tracking/delete_tracking_types_start',
  DELETE_TRACKING_TYPES_SUCCESS = 'tracking/delete_tracking_types_success',
  DELETE_TRACKING_TYPES_FAILURE = 'tracking/delete_tracking_types_failure',
}

/**
 * TRACKING TYPES
 */
const fetchTrackingTypesActionStart = (): StartAction => ({
  type: TrackingTypesActionTypes.FETCH_TRACKING_TYPES_START,
  payload: null,
});

const fetchTrackingTypesActionSuccess = (payload: any): SuccessAction => {
  return {
    type: TrackingTypesActionTypes.FETCH_TRACKING_TYPES_SUCCESS,
    payload,
  };
};

const fetchTrackingTypesActionFailure = (error: string): FailureAction => ({
  type: TrackingTypesActionTypes.FETCH_TRACKING_TYPES_FAILURE,
  payload: { error },
});

export const fetchTrackingTypes = () =>
  createApiAction(
    {
      path: '/v1/tracking_types',
      method: 'GET',
    },
    fetchTrackingTypesActionStart,
    fetchTrackingTypesActionSuccess,
    fetchTrackingTypesActionFailure
  );

const postTrackingTypesActionStart = (): StartAction => ({
  type: TrackingTypesActionTypes.POST_TRACKING_TYPES_START,
  payload: null,
});

const postTrackingTypesActionSuccess = (payload: any): SuccessAction => {
  showNotification('Tracking type successfully created', {
    variant: 'success',
  });
  return {
    type: TrackingTypesActionTypes.POST_TRACKING_TYPES_SUCCESS,
    payload,
  };
};

const postTrackingTypesActionFailure = (error: any): FailureAction => {
  showNotification(error.message, { variant: 'error' });
  return {
    type: TrackingTypesActionTypes.POST_TRACKING_TYPES_FAILURE,
    payload: { error },
  };
};

export const addTrackingType = (newTrackingType: NewTrackingType) =>
  createApiAction(
    {
      path: '/v1/tracking_types',
      method: 'POST',
      body: newTrackingType,
    },
    postTrackingTypesActionStart,
    postTrackingTypesActionSuccess,
    postTrackingTypesActionFailure
  );

const putTrackingTypesActionStart = (): StartAction => ({
  type: TrackingTypesActionTypes.PUT_TRACKING_TYPES_START,
  payload: null,
});

const putTrackingTypesActionSuccess = (payload: any): SuccessAction => {
  showNotification('Tracking type successfully updated', {
    variant: 'success',
  });
  return {
    type: TrackingTypesActionTypes.PUT_TRACKING_TYPES_SUCCESS,
    payload,
  };
};

const putTrackingTypesActionFailure = (error: any): FailureAction => {
  showNotification(error.message, { variant: 'error' });
  return {
    type: TrackingTypesActionTypes.PUT_TRACKING_TYPES_FAILURE,
    payload: { error },
  };
};

export const updateTrackingType = (trackingType: TrackingType) =>
  createApiAction(
    {
      path: '/v1/tracking_types',
      method: 'PUT',
      body: trackingType,
    },
    putTrackingTypesActionStart,
    putTrackingTypesActionSuccess,
    putTrackingTypesActionFailure
  );

const deleteTrackingTypesActionStart = (): StartAction => {
  return {
    type: TrackingTypesActionTypes.DELETE_TRACKING_TYPES_START,
    payload: null,
  };
};

const deleteTrackingTypesActionSuccess = (payload: any): SuccessAction => {
  showNotification('Tracking type successfully deleted', {
    variant: 'success',
  });
  return {
    type: TrackingTypesActionTypes.DELETE_TRACKING_TYPES_SUCCESS,
    payload,
  };
};

const deleteTrackingTypesActionFailure = (error: any): FailureAction => {
  showNotification(error.message, { variant: 'error' });
  return {
    type: TrackingTypesActionTypes.DELETE_TRACKING_TYPES_FAILURE,
    payload: { error },
  };
};

export const deleteTrackingTypes = (trackingTypeIds: number[]) =>
  createApiAction(
    {
      path: '/v1/tracking_types',
      method: 'DELETE',
      body: trackingTypeIds,
      additionalData: { deletedIds: trackingTypeIds },
    },
    deleteTrackingTypesActionStart,
    deleteTrackingTypesActionSuccess,
    deleteTrackingTypesActionFailure
  );
