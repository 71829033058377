import React, { memo, useEffect, useMemo, useState } from 'react';
import {
  QuickTransferFormValues,
  QuickTransferModalProps,
  defaultQuickTransferFormValues,
} from './types';
import {
  ItemInventoryEvents,
  createBulkItemsForQuickTransfer,
  postBulkInventory,
} from 'services/inventory';
import { Modal } from 'ui/components/Modal/Modal';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import QuickTransferForm from './QuickTransferForm';
import { initialPagination, ItemsTable } from 'ui/components/Table/ItemsTable';
import { Item } from 'services/items/types';
import { QUICK_TRANSFER_COLUMNS } from './consts';
import QuickTransferSubRow from './QuickTransferSubRow';
import { useQuickTransferModalStyle } from './styled';
import { showNotification } from 'services/api';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { validateYup } from 'services/forms/validation';
import { Pagination } from 'services/search';
const QuickTransferModal: React.FC<QuickTransferModalProps> = (props) => {
  const { show, onCancelClicked, setShowQuickTransferModal } = props;
  const [pagination, setPagination] = useState<Pagination>(initialPagination);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [formValues, setFormValues] = useState<QuickTransferFormValues>(
    defaultQuickTransferFormValues
  );
  const [, setItems] = useState<Item[]>([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [qtyErrorMessage] = useState('');
  const [tableData, setTableData] = useState<QuickTransferFormValues[]>([]);
  const [isQuantityShort, setIsQuantityShort] = useState<boolean[]>([]);
  const [isTrackingFilled, setIsTrackingFilled] = useState<boolean[]>([]);

  const [IsQuantityShortStatus, setIsQuantityShortStatus] = useState(false);
  const classes = useQuickTransferModalStyle();
  const handleApplyClick = async () => {
    setIsLoading(true);
    if (
      tableData.length === 0 ||
      Object.values(isQuantityShort).some((value) => value === true) ||
      Object.values(isTrackingFilled).some((value) => value === false)
    ) {
      setIsLoading(false);
      return;
    }
    const isValid = validateYup(
      formValues,
      ItemInventoryEvents.Move,
      setValidationErrors
    );

    if (!isValid) {
      return;
    }
    const data = createBulkItemsForQuickTransfer(tableData);
    const result = await postBulkInventory(data);
    if (result) {
      showNotification('Successfully moved inventory', { variant: 'success' });
      setFormValues(defaultQuickTransferFormValues);
      setItems([]);
      setTableData([]);
    }
    setIsLoading(false);
    setShowQuickTransferModal(false);
  };
  const handleCancel = () => {
    setTableData([]);
    setFormValues(defaultQuickTransferFormValues);
    setShowQuickTransferModal(false);
    onCancelClicked();
  };
  const handleRemoveRow = (id: number) => {
    setTableData((old) => old.filter((i) => i?.id !== id));
    setIsQuantityShort((prevState: any) => {
      const updatedState = { ...prevState };
      delete updatedState[id];
      return updatedState;
    });
    setIsTrackingFilled((prevState: any) => {
      const updatedState = { ...prevState };
      delete updatedState[id];
      return updatedState;
    });
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsQuantityShortStatus(
        Object.values(isQuantityShort).some((value) => value === true)
      );
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [isQuantityShort]);
  useEffect(() => {
    if (tableData.length === 0) {
      setIsQuantityShort([]);
    }
  }, [tableData]);
  useEffect(() => {
    if (!show) {
      setTableData([]);
      setFormValues(defaultQuickTransferFormValues);
      setIsQuantityShort([]);
      setPagination(initialPagination);
      setIsTrackingFilled([]);
    }
  }, [show]);
  const paginatedTableData = useMemo(
    () =>
      tableData.slice(
        (pagination.page - 1) * pagination.pageSize,
        pagination.pageSize * pagination.page
      ),
    [pagination, tableData]
  );
  return (
    <Modal
      className="inventory-event-modal"
      open={show}
      title="Bulk Move"
      applyLabel="Bulk Move"
      onCancelClicked={handleCancel}
      onApplyClicked={handleApplyClick}
      isLoading={isLoading}
      withoutDefaultPadding
      // nestedScrollAreas
      applyDisabled={
        tableData.length === 0 ||
        Object.values(isQuantityShort).some((value) => value === true) ||
        Object.values(isTrackingFilled).some((value) => value === false)
      }
      dataQa="bulk-move-modal"
      maxWidth="xl"
    >
      <Box className={classes.container}>
        <QuickTransferForm
          formValues={formValues}
          setFormValues={setFormValues}
          validationErrors={validationErrors}
          errorMessage={qtyErrorMessage}
          setItems={setItems}
          meta={{
            tableData: tableData,
            setTableData: setTableData,
          }}
        />
      </Box>
      <ItemsTable
        data={paginatedTableData}
        columns={QUICK_TRANSFER_COLUMNS}
        RenderCustomRow={QuickTransferSubRow}
        emptyTableText="NO DATA TO DISPLAY"
        isLoadingData={isLoading}
        selectableItems={false}
        dataQa="bulk-move-table"
        meta={{
          tableData: tableData,
          setTableData: setTableData,
          handleRemoveRow: handleRemoveRow,
          formValues: formValues,
          setIsQuantityShort: setIsQuantityShort,
          setIsTrackingFilled: setIsTrackingFilled,
        }}
        pagination={pagination}
        onPaginationChange={setPagination}
        showEnhancedTablePaginationActions
      />
      {IsQuantityShortStatus && (
        <Typography
          variant="subtitle1"
          color="error"
          className={classes.errorMessage}
        >
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '24px',
            }}
          >
            <ErrorOutlineIcon className={classes.errorIcon} />
            Not enough quantity on hand to move.
          </span>
        </Typography>
      )}
    </Modal>
  );
};

export default memo(QuickTransferModal);
