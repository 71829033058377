import React from 'react';
import { Grid, ListItem } from '@mui/material';
import { PermissionType } from 'services/permissions';
import { useSelector } from 'react-redux';

import { replaceValueInCollection } from 'helpers';
import { CarrierService } from 'services/carriers';
import { activeUserHasPermission } from 'services/user/redux';
import { TextField } from 'ui/components/TextField/TextField';

import { CarrierServiceListItemProps } from './types';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { IconNames } from 'ui/theme';

export const CarrierServiceListItem = (props: CarrierServiceListItemProps) => {
  const {
    carrierService,
    setCarrierServices,
    index,
    carrierValidation,
    activeCarrierId,
    onDelete,
  } = props;

  const canEditCarrier =
    activeCarrierId && activeCarrierId > 0
      ? [PermissionType.CarriersEdit]
      : [PermissionType.CarriersCreate];

  const canClick = useSelector(activeUserHasPermission(canEditCarrier));

  const handleTextfieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newCarrierService: CarrierService = {
      ...carrierService,
      [event.target.name]: event.target.value || null,
    };
    setCarrierServices(
      (old) =>
        replaceValueInCollection<CarrierService>(old, newCarrierService, index)!
    );
  };

  return (
    <ListItem disableGutters>
      <Grid container spacing={2} alignItems="center" xs={10}>
        <Grid item xs={4}>
          <TextField
            className={`redesign`}
            variant={'standard'}
            label="Name"
            type="text"
            placeholder="Enter name"
            name="name"
            size="small"
            permissions={canEditCarrier}
            autoComplete="off"
            value={carrierService.name}
            disabled={carrierService.readOnly}
            onChange={handleTextfieldChange}
            error={!!carrierValidation[`carrierServiceList[${index}].name`]}
            required
            dataQa="carrier-service-name"
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            className={`redesign`}
            variant={'standard'}
            label="Code"
            type="text"
            placeholder="Enter code"
            name="code"
            size="small"
            autoComplete="off"
            value={carrierService.code}
            permissions={canEditCarrier}
            disabled={carrierService.readOnly}
            onChange={handleTextfieldChange}
            dataQa="carrier-service-code"
          />
        </Grid>
        <Grid item>
          {!carrierService.readOnly && canClick && (
            <FBOButton
              variant="tertiary"
              color="negative"
              size="medium"
              icon={IconNames.TrashCan}
              onClick={onDelete}
              data-qa="carrier-service-delete"
            />
          )}
        </Grid>
      </Grid>
    </ListItem>
  );
};
