import moment from 'moment';
import { DateRange, DefinedRange } from 'materialui-daterange-picker';
import { PaginationDirection } from 'services/url';
import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const initialDateRange: DateRange = {
  startDate: moment().startOf('month').toDate(),
  endDate: moment().endOf('date').toDate(),
};

export const INVOICE_COLUMNS: Column[] = [
  {
    title: 'Number',
    field: 'number',
    sortable: true,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Order Type',
    field: 'type',
    sortable: true,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Customer',
    field: 'customer.name',
    sortable: true,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Date Shipped',
    field: 'dateShipped',
    sortable: true,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Date Due',
    field: 'dateDue',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Location',
    field: 'location.name',
    sortable: true,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Sales Rep',
    field: 'salesRepresentative.name',
    sortable: true,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Acct. Ref',
    field: 'transactionId',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Memo',
    field: 'memo',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Amount',
    field: 'amount',
    type: ColumnTypes.renderByCustomRow,
  },
];

export const initialPagination = {
  page: 1,
  pageSize: 25,
  sort: {
    sortBy: 'dateShipped',
    direction: PaginationDirection.Descending,
  },
  totalRows: 25,
};

export const customDateRangeInvoices: DefinedRange[] = [
  {
    startDate: moment().startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
    label: 'Today',
  },
  {
    startDate: moment().add(-1, 'day').startOf('day').toDate(),
    endDate: moment().add(-1, 'day').endOf('day').toDate(),
    label: 'Yesterday',
  },
  {
    startDate: moment().startOf('week').toDate(),
    endDate: moment().endOf('week').toDate(),
    label: 'This Week',
  },
  {
    startDate: moment().add(-1, 'week').startOf('week').toDate(),
    endDate: moment().add(-1, 'week').endOf('week').toDate(),
    label: 'Last Week',
  },
  {
    startDate: moment().add(-7, 'days').toDate(),
    endDate: moment().toDate(),
    label: 'Last 7 Days',
  },
  {
    startDate: moment().startOf('month').toDate(),
    endDate: moment().endOf('month').toDate(),
    label: 'This Month',
  },
  {
    startDate: moment().startOf('month').add(-1, 'month').toDate(),
    endDate: moment().endOf('month').add(-1, 'month').toDate(),
    label: 'Last Month',
  },
];

export const QBO_INVOICE_URL = 'https://app.qbo.intuit.com/app/invoice?txnId=';
export const QBO_CREDIT_MEMO_URL =
  'https://app.qbo.intuit.com/app/creditmemo?txnId=';
export const QBO_SANDBOX_INVOICE_URL =
  'https://app.sandbox.qbo.intuit.com/app/invoice?txnId=';
export const QBO_SANDBOX_CREDIT_MEMO_URL =
  'https://app.sandbox.qbo.intuit.com/app/creditmemo?txnId=';
