import _ from 'lodash';

import { BundleItem, Item } from 'services/items';
import { TrackingDataTypes } from 'services/settings/tracking';

import { ItemTrackingType } from './types';

export const itemHasTracking = (item: Item) => {
  return !_.isEmpty(item.itemTrackingTypeList);
};

export const itemHasOnlySerialTracking = (item: Item) => {
  const trackingTypes = _.get(item, 'itemTrackingTypeList', []);

  if (!trackingTypes.length) {
    return false;
  }

  return trackingTypes.every(
    (itemTrackingType: ItemTrackingType) =>
      _.get(itemTrackingType, 'trackingType.trackingDataType', null) ===
      TrackingDataTypes.SerialNumber
  );
};

export const itemHasOnlyLotTracking = (item: Item) => {
  const trackingTypes = _.get(item, 'itemTrackingTypeList', []);

  if (!trackingTypes.length) {
    return false;
  }

  return trackingTypes.every(
    (itemTrackingType: ItemTrackingType) =>
      _.get(itemTrackingType, 'trackingType.trackingDataType', null) ===
      TrackingDataTypes.Text
  );
};

export const itemHasSerialTracking = (item: Item) => {
  return _.get(item, 'itemTrackingTypeList', []).some(
    (itemTrackingType: ItemTrackingType) =>
      _.get(itemTrackingType, 'trackingType.trackingDataType', null) ===
      TrackingDataTypes.SerialNumber
  );
};

export const bundleItemHasSerialTracking = (bundleItems: BundleItem[]) => {
  return !bundleItems.some((bundleItem) => {
    const saleItem = _.get(bundleItem, 'saleItem.item');
    return (
      _.get(
        saleItem,
        'itemTrackingTypeList[0].trackingType.trackingDataType',
        null
      ) === 'Serial Number'
    );
  });
};
/**
 * Attempts to parse last element into an array. Array must contain no spaces:
 *
 * "Whatever you want here [324234,sdffsdf,23453423f,dv87998ehuw489f,ifoiuhsj]"
 *
 * @param message string containing array at the end
 * @returns string[] parsed from errorMessage
 */
export const parseLastWordArray = (message: string): string[] => {
  const errorMessageArray = message.split(' ');
  //parses the array contained in the last element
  return (
    errorMessageArray[errorMessageArray.length - 1]
      .split('')
      .filter((x) => x !== '[' && x !== ']')
      .join('')
      .split(',') || []
  );
};
