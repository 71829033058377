import { PopoverOrigin } from '@mui/material';

export const dimensionsAnchorOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'center',
};

export const dimensionsTransformOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};
