import React, { memo, useMemo } from 'react';
import { Box } from '@mui/material';
import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { ReceivingTabOrdersProps } from './types';
import { RECEIVING_TAB_ORDERS_COLUMNS } from './consts';
import { useGetIntlDateFormatString } from 'helpers';

const ReceivingTabOrders: React.FC<ReceivingTabOrdersProps> = (props) => {
  const { activeReceiptItem } = props;

  const intlFormatDate = useGetIntlDateFormatString();

  const tableData = useMemo(() => {
    if (activeReceiptItem.purchaseOrderItem) {
      return [activeReceiptItem.purchaseOrderItem];
    } else {
      return [];
    }
  }, [activeReceiptItem.purchaseOrderItem]);

  return (
    <Box width="100%">
      <ItemsTable
        columns={RECEIVING_TAB_ORDERS_COLUMNS(intlFormatDate)}
        data={tableData}
        emptyTableText="No data"
        selectableItems={false}
      />
    </Box>
  );
};

export default memo(ReceivingTabOrders);
