import React, { memo } from 'react';
import { Menu, MenuItem } from 'ui/components/Menu';
import FBOButton, {
  FBOButtonProps,
} from 'ui/theme/components/FBOButton/FBOButton';

type FBOIconButtonWithMenuProps = FBOButtonProps & {
  items: MenuItem[];
  noBorder: boolean;
};

const FBOIconButtonWithMenu = (props: FBOIconButtonWithMenuProps) => {
  const { items, onClick, noBorder, ...iconButtonProps } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const isActionMenuVisible = Boolean(anchorEl);

  const handleIconClicked = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
    if (onClick) {
      onClick(event as any);
    }
  };

  const handleActionMenuClose = (
    ev: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    ev.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <FBOButton
        onClick={handleIconClicked}
        {...iconButtonProps}
        style={noBorder ? { border: 0 } : {}}
      />
      <Menu
        items={items}
        className="IconButtonWithMenu"
        open={isActionMenuVisible}
        anchorEl={anchorEl}
        onClose={handleActionMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      />
    </>
  );
};

FBOIconButtonWithMenu.defaultProps = {
  loading: false,
  icon: '',
  href: '',
  noBorder: false,
};
export default memo(FBOIconButtonWithMenu);
