export interface ShippingSettings {
  id: number | null;
  version: number | null;
  dateLastModified: string | null;
  dateCreated: string | null;
  fulfillServiceAndMiscItemsOnShip: string | null;
  fulfillShippingLinesOnShip: boolean;
  printPackingListOnShip: boolean;
  printPackingListOnPack: boolean;
  shipmentBatchSelectionDefault: string | null;
  autoPackShipments: boolean;
  showConfirmationOnShip: boolean;
  orderNotPaidWarning: boolean;
  showPaidStamp: boolean;
}

export enum DefaultWeightUom {
  Ounces = 'Ounces',
  Pounds = 'Pounds',
  Tons = 'Tons',
  Grams = 'Grams',
  Kilograms = 'Kilograms',
}

export enum FulfillServiceAndMiscItemsOnShip {
  Confirm = 'Confirm',
  NeverFulfill = 'Never Fulfill',
  AlwaysFulfill = 'Always Fulfill',
}

export enum ShipmentBatchSelectionDefault {
  All = 'All',
  None = 'None',
  Packed = 'Packed',
}
