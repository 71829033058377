import { WaitingToExportLedgers } from 'services/accounting';
import { LedgerForWaitingToExportModal } from 'services/ledgers';

export const getLedgerTypeForAPICall = (ledgerType: WaitingToExportLedgers) => {
  switch (ledgerType) {
    case WaitingToExportLedgers.Bills:
      return 'Bill';
    case WaitingToExportLedgers.InventoryAdjustments:
      return 'Adjustment';
    case WaitingToExportLedgers.Invoices:
      return 'Invoice';
    case WaitingToExportLedgers.JournalEntries:
      return 'Journal Entry';
    case WaitingToExportLedgers.ItemReceipts:
      return 'Item Receipt';
    case WaitingToExportLedgers.CreditMemos:
      return 'Credit Memo';
    case WaitingToExportLedgers.Payments:
      return 'Payment';
    case WaitingToExportLedgers.VendorCredits:
      return 'Vendor Credit';
    default:
      return 'Bill';
  }
};

export const getLedgerDetailsLink = (
  ledgerRow: LedgerForWaitingToExportModal
) => {
  const type = ledgerRow.type;

  switch (type) {
    case 'Bill':
    case 'Item Receipt':
    case 'Vendor Credit':
      return `/purchasing/purchase-order?activeId=${ledgerRow.purchaseOrderId}`;
    case 'Invoice':
    case 'Credit Memo':
    case 'Payment':
      return `/sales/sales-order?activeId=${ledgerRow.salesOrderId}`;
    default:
      return null;
  }
};
