import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const reconcileWizardReconcileItemsColumns = (
  showMulticurrency: boolean,
  currencyCode: string,
  homeCurrencyCode: string
): Column[] => {
  let columns: Column[] = [
    {
      title: 'Item',
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: 'Quantity',
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: `Unit ${homeCurrencyCode}`,
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: `Total ${homeCurrencyCode}`,
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: `Reconcile ${homeCurrencyCode}`,
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: `Unit Landed ${homeCurrencyCode}`,
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: `Landed Total ${homeCurrencyCode}`,
      type: ColumnTypes.renderByCustomRow,
    },
  ];

  if (showMulticurrency) {
    columns = [
      {
        title: 'Item',
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: 'Quantity',
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Unit ${homeCurrencyCode}`,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Unit ${currencyCode}`,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Total ${homeCurrencyCode}`,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Total ${currencyCode}`,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Reconcile ${homeCurrencyCode}`,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Reconcile ${currencyCode}`,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Unit Landed ${homeCurrencyCode}`,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Unit Landed ${currencyCode}`,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Landed Total ${homeCurrencyCode}`,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Landed Total ${currencyCode}`,
        type: ColumnTypes.renderByCustomRow,
      },
    ];
  }

  return columns;
};
