import { makeStyles } from '@mui/styles';

export const useTextFieldWithOptionsStyle = makeStyles(() => ({
  link: {
    fontSize: 14,
    borderBottom: '1px solid black',
    '&:hover': {
      textDecoration: 'none',
    },
    paddingBottom: 1,
  },
}));
