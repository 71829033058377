import { useMutation, MutationOptions } from '@tanstack/react-query';
import * as SalesOrderApi from '../api/salesOrders';
import { SalesOrder } from 'services/salesOrders/types';

type GenericError = {
  message: string;
};

export function useDeleteSalesOrder(
  options: MutationOptions<SalesOrder, unknown, number, unknown> = {}
) {
  return useMutation(
    (id: number) => SalesOrderApi.deleteSalesOrder(id),
    options
  );
}

export function useQuickFulfillSalesOrder(
  options: MutationOptions<void, GenericError, number, unknown> = {}
) {
  return useMutation(
    (id: number) => SalesOrderApi.quickFulfillSalesOrder(id),
    options
  );
}

export function useCancelSalesOrder(
  options: MutationOptions<void, unknown, number, unknown> = {}
) {
  return useMutation((id: number) => SalesOrderApi.cancelSalesOrder(id), {
    ...options,
  });
}

export function useCreateSalesOrder(
  options: MutationOptions<SalesOrder, unknown, SalesOrder, unknown> = {}
) {
  return useMutation(
    (salesOrder: SalesOrder) => SalesOrderApi.createSalesOrder(salesOrder),
    options
  );
}

export function useUnissueSalesOrder(
  options: MutationOptions<void, unknown, number, unknown> = {}
) {
  return useMutation(
    (id: number) => SalesOrderApi.unissueSalesOrder(id),
    options
  );
}

export function useIssueSalesOrder(
  options: MutationOptions<void, GenericError, number, unknown> = {}
) {
  return useMutation(
    (id: number) => SalesOrderApi.issueSalesOrder(id),
    options
  );
}

export function useRestoreSalesOrder(
  options: MutationOptions<void, unknown, number, unknown> = {}
) {
  return useMutation(
    (id: number) => SalesOrderApi.restoreSalesOrder(id),
    options
  );
}
