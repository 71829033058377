import { initialPagination } from 'ui/components/Table/ItemsTable';

export const initialHistoryDownloads = {
  data: [],
  pagination: initialPagination,
};

export const initialHistoryUploads = {
  data: [],
  pagination: initialPagination,
};
