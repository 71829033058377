import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const reconcileWizardItemsColumns = (
  showMulticurrency: boolean,
  multiCurrencyCode: string,
  homeCurrencyCode: string
): Column[] => {
  let columns: Column[] = [
    {
      title: 'Item',
      width: 250,
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: 'Quantity',
      width: 80,
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: 'Status',
      width: 80,
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: 'Ref. No',
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: 'Received',
      width: 120,
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: `Cost ${homeCurrencyCode}`,
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: `Total ${homeCurrencyCode}`,
      type: ColumnTypes.renderByCustomRow,
    },
  ];

  if (showMulticurrency) {
    columns = [
      {
        title: 'Item',
        width: 250,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: 'Quantity',
        width: 80,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: 'Status',
        width: 80,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: 'Ref. No',
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: 'Received',
        width: 120,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Cost ${homeCurrencyCode}`,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Cost ${multiCurrencyCode}`,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Total ${homeCurrencyCode}`,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Total ${multiCurrencyCode}`,
        type: ColumnTypes.renderByCustomRow,
      },
    ];
  }

  return columns;
};

export const DOT_CHAR = '\u2022';
