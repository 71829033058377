import { ReceiptItemStatus } from 'services/receiving';

import { FieldStatusMapping } from './types';

// Provides mapping for receipt item statuses and should they be enabled or disabled
export const disableMapping: FieldStatusMapping = {
  location: {
    [ReceiptItemStatus.Open]: false,
    [ReceiptItemStatus.Received]: true,
    [ReceiptItemStatus.Reconciled]: false,
    [ReceiptItemStatus.Fulfilled]: true,
    [ReceiptItemStatus.Rejected]: true,
    [ReceiptItemStatus.Hold]: true,
  },
  refNo: {
    [ReceiptItemStatus.Open]: false,
    [ReceiptItemStatus.Received]: true,
    [ReceiptItemStatus.Reconciled]: true,
    [ReceiptItemStatus.Fulfilled]: true,
    [ReceiptItemStatus.Rejected]: true,
    [ReceiptItemStatus.Hold]: true,
  },
  customerJob: {
    [ReceiptItemStatus.Open]: false,
    [ReceiptItemStatus.Received]: false,
    [ReceiptItemStatus.Reconciled]: true,
    [ReceiptItemStatus.Fulfilled]: true,
    [ReceiptItemStatus.Rejected]: true,
    [ReceiptItemStatus.Hold]: true,
  },
  billDate: {
    [ReceiptItemStatus.Open]: false,
    [ReceiptItemStatus.Received]: false,
    [ReceiptItemStatus.Reconciled]: true,
    [ReceiptItemStatus.Fulfilled]: true,
    [ReceiptItemStatus.Rejected]: true,
    [ReceiptItemStatus.Hold]: true,
  },
  carrier: {
    [ReceiptItemStatus.Open]: false,
    [ReceiptItemStatus.Received]: true,
    [ReceiptItemStatus.Reconciled]: false,
    [ReceiptItemStatus.Fulfilled]: true,
    [ReceiptItemStatus.Rejected]: true,
    [ReceiptItemStatus.Hold]: true,
  },
  service: {
    [ReceiptItemStatus.Open]: false,
    [ReceiptItemStatus.Received]: true,
    [ReceiptItemStatus.Reconciled]: false,
    [ReceiptItemStatus.Fulfilled]: true,
    [ReceiptItemStatus.Rejected]: true,
    [ReceiptItemStatus.Hold]: true,
  },
  trackNo: {
    [ReceiptItemStatus.Open]: false,
    [ReceiptItemStatus.Received]: true,
    [ReceiptItemStatus.Reconciled]: false,
    [ReceiptItemStatus.Fulfilled]: true,
    [ReceiptItemStatus.Rejected]: true,
    [ReceiptItemStatus.Hold]: true,
  },
  packageCount: {
    [ReceiptItemStatus.Open]: false,
    [ReceiptItemStatus.Received]: true,
    [ReceiptItemStatus.Reconciled]: false,
    [ReceiptItemStatus.Fulfilled]: true,
    [ReceiptItemStatus.Rejected]: true,
    [ReceiptItemStatus.Hold]: true,
  },
  notes: {
    [ReceiptItemStatus.Open]: false,
    [ReceiptItemStatus.Received]: false,
    [ReceiptItemStatus.Reconciled]: false,
    [ReceiptItemStatus.Fulfilled]: true,
    [ReceiptItemStatus.Rejected]: true,
    [ReceiptItemStatus.Hold]: true,
  },
};
