import { Carrier } from 'services/carriers';
import { Item } from 'services/items';
import { TrackingGroup } from 'services/inventory';
import { Uom } from 'services/uoms';
import { SalesOrder, SalesOrderItem } from 'services/salesOrders';
import { ItemReference } from 'services/api';
import { PurchaseOrderItem } from 'services/purchaseOrders';
import { CustomField } from 'services/customFields';
import { Document } from 'services/documents';

export interface Ship {
  id: number | null;
  carrier: Carrier | null;
  carrierId: number | null;
  carrierServiceId: number | null;
  customFields: CustomField[];
  notes: string | null;
  number: string | null;
  orderPriorityId: number | null;
  orderType: 'Purchase Order' | 'Sales Order' | null;
  packedByUserId: number | null;
  parentLocationId: number | null;
  picks: ItemReference[];
  purchaseOrderId: number | null;
  salesOrder: SalesOrder | null;
  salesOrderId: number | null;
  salesOrders: ItemReference[];
  shipCartonList: ShipCarton[];
  dateShip: Date | null;
  dateCreated: Date | null;
  dateLastModified: Date | null;
  documents: Document[];
  shipItemList: ShipItem[];
  shippedByUserId: string | null;
  shipToAttention: string | null;
  shipToCity: string | null;
  shipToCompanyName: string | null;
  shipToCountry: string | null;
  shipToName: string | null;
  shipToPostalCode: string | null;
  shipToResidential: boolean;
  shipToState: string | null;
  shipToStreet: string | null;
  shipToStreet2: string | null;
  shipToVerified: boolean;
  status: ShipStatus | null;
  version: number | null;
  returnLabel: string | null;
  returnLabelTracking: string | null;
  returnLabelCost: number | null;
}

export enum ShipStatus {
  Open = 'Open',
  Packed = 'Packed',
  Shipped = 'Shipped',
}

export enum ShipPaymentStatus {
  Paid = 'Paid',
  Unpaid = 'Unpaid',
}

export enum ShipOrderType {
  SalesOrder = 'Sales Order',
  PurchaseOrder = 'Purchase Order',
}

export interface ShipCarton {
  id: number | null;
  cost: number | null;
  insuredValue: number | null;
  declaredValue: number | null;
  dimensionUnit: string | null;
  height: number | null;
  length: number | null;
  number: string;
  trackingNumber: string | null;
  trackingUrl: string | null;
  shippingLabelUrl: string | null;
  version: number | null;
  weight: number | null;
  weightUnit: string | null;
  width: number | null;
  packageCode?: string | null;
  returnLabelUrl: string | null;
  returnTracking: string | null;
  returnTrackingUrl: string | null;
  returnLabelCost: number | null;
}

export interface ShipItem {
  id: number | null;
  deleted: boolean | null;
  item: Item;
  itemId: number;
  purchaseOrderItem: PurchaseOrderItem | null;
  purchaseOrderItemId: number | null;
  quantity: number;
  salesOrderItem: SalesOrderItem | null;
  salesOrderItemId: number | null;
  shipCartonId: number;
  trackingGroups: TrackingGroup[];
  uom: Uom | null;
  uomId: number;
  version: number;
}

export interface ShippingBillingType {
  name: string;
  code: string;
}
