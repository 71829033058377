import React, { memo, useCallback, useMemo } from 'react';
import { Grid } from '@mui/material';

import { useSelector } from 'react-redux';

import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { AdvancedSearchFieldsCmpProps } from 'ui/components/Page/PageWithAdvancedSearch';
import { ItemsAutocomplete } from 'ui/components/Autocomplete/ItemsAutocomplete';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import { Location } from 'services/locations';
import { ReceiptOrderType, ReceiptStatus } from 'services/receiving';
import { Customer, getCustomers } from 'services/customers';
import { getVendors, Vendor } from 'services/vendors';
import { Item } from 'services/items';

const ReceivingAdvancedSearch: React.FC<AdvancedSearchFieldsCmpProps> = (
  props
) => {
  const { setFormValues, formValues } = props;

  const { items: customers } = useSelector(getCustomers);
  const { items: vendors } = useSelector(getVendors);

  const selectedCustomer = useMemo(() => {
    return (
      customers.find((c) => c.id === formValues['salesOrder.customerId']) ||
      null
    );
  }, [formValues, customers]);

  const selectedVendor = useMemo(
    () =>
      vendors.find((v) => v.id === formValues['purchaseOrder.vendorId']) ||
      null,
    [formValues, vendors]
  );

  const selectedReceiveStatuses = useMemo(() => {
    if (!formValues.status) {
      return [];
    }

    return formValues.status.toString().split(',');
  }, [formValues.status]);

  const handleCustomerChange = useCallback(
    (e: React.ChangeEvent<{}>, value: Customer | null) => {
      setFormValues({
        ...formValues,
        'salesOrder.customerId': value ? value.id : null,
      });
    },
    [formValues, setFormValues]
  );

  const handleVendorChange = useCallback(
    (e: React.ChangeEvent<{}>, vendor: Vendor | null) => {
      setFormValues({
        ...formValues,
        'purchaseOrder.vendorId': vendor ? vendor.id : null,
      });
    },
    [setFormValues, formValues]
  );

  const handleStatusChange = useCallback(
    (e: React.ChangeEvent<{}>, statuses: ReceiptStatus[]) => {
      setFormValues({
        ...formValues,
        status: statuses.length ? statuses.join(',') : null,
      });
    },
    [formValues, setFormValues]
  );

  const handleRootLocationChange = useCallback(
    (location: Location | null) => {
      setFormValues({
        ...formValues,
        locationId: location ? location.id : null,
      });
    },
    [formValues, setFormValues]
  );

  const handleOrderTypeChange = useCallback(
    (e: React.ChangeEvent<{}>, value: ReceiptOrderType | null) => {
      setFormValues({ ...formValues, orderType: value });
    },
    [formValues, setFormValues]
  );

  const handleItemChange = useCallback(
    (selectedItem: Item | null) => {
      setFormValues({
        ...formValues,
        'receiptItems.itemId': selectedItem ? selectedItem.id : null,
      });
    },

    [setFormValues, formValues]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Autocomplete
          className="multi-line"
          label="Status"
          placeholder="Select receive status"
          options={Object.values(ReceiptStatus)}
          multiple
          value={selectedReceiveStatuses}
          onChange={handleStatusChange}
          dataQa="select-receive-status"
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          label="Customer"
          placeholder="Select customer"
          options={customers}
          getOptionLabel={(c: Customer) => c.name || ''}
          value={selectedCustomer}
          onChange={handleCustomerChange}
          dataQa="select-customer"
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          label="Vendor"
          options={vendors}
          value={selectedVendor}
          placeholder="Select vendor"
          getOptionLabel={(v: Vendor) => v.name || ''}
          onChange={handleVendorChange}
          dataQa="select-vendor"
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          label="Order Type"
          onChange={handleOrderTypeChange}
          options={Object.values(ReceiptOrderType)}
          value={formValues.orderType}
          placeholder="Select receipt order type"
          dataQa="select-order-type"
        />
      </Grid>

      <Grid item xs={6}>
        <LocationsAsyncAutocomplete
          label="Location"
          placeholder="Select location"
          value={formValues.locationId as number}
          onChange={handleRootLocationChange}
          companyWide={false}
          parentId={null}
          dataQa="select-location"
        />
      </Grid>
      <Grid item xs={6}>
        <ItemsAutocomplete
          label="Item"
          onChange={handleItemChange}
          value={(formValues['receiptItems.itemId'] || null) as number | null}
          placeholder="Select item"
          dataQa="select-item"
          disableAdd
        />
      </Grid>
    </Grid>
  );
};

export default memo(ReceivingAdvancedSearch);
