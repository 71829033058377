import {
  TrackingGroup,
  ItemInventoryEvents,
  InventoryEventData,
  transformSerialRowToPostSerialRow,
  TrackingInfo,
} from 'services/inventory';
//import { TrackingDataTypes } from 'services/settings/tracking';
import moment from 'moment';

import { InventoryEventFormValues } from './types';

/**
 * Transforms InventoryEventFormValues to InventoryEventData
 *
 * @param eventType
 * @param itemId
 * @param formValues
 * @param trackingGroups
 *
 * @returns InventoryEventData
 */
export const transformToInventoryEventData = (
  eventType: ItemInventoryEvents,
  itemId: number,
  formValues: InventoryEventFormValues,
  trackingGroups: TrackingGroup[],
  currencyCode: string
): InventoryEventData => {
  let filteredTrackingGroups = trackingGroups;

  if (eventType !== ItemInventoryEvents.Cycle) {
    filteredTrackingGroups = trackingGroups.filter(
      (t) => t.quantity && t.quantity > 0
    );
  }

  return {
    itemId,
    locationId: formValues.locationFromId!,
    quantity: formValues.quantity!,
    uomId: formValues.uomId!,
    notes: formValues.notes,
    cost: formValues.cost,
    moveToLocationId: formValues.locationToId,
    trackingGroupObjects: filteredTrackingGroups.map((row) => {
      return {
        quantity: row.quantity!,
        trackingGroupInfos: row.trackingInfoList.map(
          transformTrackingGroupInfo(currencyCode)
        ),
        serialInputs:
          eventType === ItemInventoryEvents.Add ||
          eventType === ItemInventoryEvents.Cycle
            ? row.serialList.map((serialRow) =>
                transformSerialRowToPostSerialRow(serialRow)
              )
            : [],
        serialIds: row.serialIds,
      };
    }),
    materialExpensing: {
      customerId: formValues.materialExpensing?.customerId,
      accountingClassId: formValues.materialExpensing?.accountingClassId,
    },
  };
};

const transformTrackingGroupInfo =
  (currencyCode: string) =>
  (trackingInfo: TrackingInfo): TrackingInfo => {
    let transformedDate: string = '';

    if (trackingInfo.trackingTypeId === 2) {
      if (currencyCode === 'USD') {
        transformedDate = trackingInfo.value ?? '';
      } else {
        transformedDate = moment(trackingInfo.value).format('DD/MM/YYYY');
      }
    } else {
      transformedDate = trackingInfo.value ?? '';
    }

    return {
      id: trackingInfo.id,
      trackingTypeId: trackingInfo.trackingTypeId,
      value: transformedDate,
    };
  };
