import axios from 'axios';
import { defaultMaximumPagination, RequestConfig } from 'helpers';
import { baseUrl } from 'serviceModule/consts';
import { analyticsTrackEvent } from 'services/analytics/analytics';
import { transformCustomer } from 'services/customers/transformations';
import { Customer } from 'services/customers/types';
import { Location } from 'services/locations/types';
import {
  transformRequestSalesOrder,
  transformSalesOrder,
} from 'services/salesOrders/transformations';
import { SalesOrder } from 'services/salesOrders/types';
import { transformSalesOrderSettings } from 'services/settings/salesOrders/transformations';
import { PaginationDirection } from 'services/url/pagination';

const expands = [
  'salesOrderItems.saleItem.images',
  'customer.customerAddresses.customerAddressContacts',
  'salesTax',
  'payments',
  'exchangeCurrency',
  'salesOrderItems.saleItem.item.itemTrackingTypes',
  'salesOrderItems.saleItem.item.tags',
  'salesOrderItems.saleItem.item.defaultUom.toConversions',
  'salesOrderItems.saleItem.item.defaultUom.fromConversions',
  'salesOrderItems.saleItem.defaultUom.fromConversions',
  'salesOrderItems.saleItem.defaultUom.toConversions',
  'salesOrderBundleItems.item.images',
  'salesOrderBundleItems.salesOrderItems.saleItem.defaultUom',
  'salesOrderBundleItems.salesOrderItems.saleItem.images',
];
const pagination = {
  pageNumber: 1,
  pageSize: 100,
  orderBy: 'dateCreated',
  ascending: true,
};

export const fetchCurrencies = async () => {
  const res = await axios.get(`${baseUrl}/v1/currencies`, {
    params: pagination,
  });

  return res;
};

export const fetchSalesOrderById = async (salesOrderId: number) => {
  const res = await axios.get(
    `${baseUrl}/v1/sales_orders/${salesOrderId}?expand=${expands.join(',')}`
  );

  return transformSalesOrder(res.data);
};

export const fetchSalesOrderSettings = async () => {
  const soSettingsResponse = await axios.get(
    `${baseUrl}/v1/sales_order_settings`
  );

  return transformSalesOrderSettings(soSettingsResponse.data);
};

export const fetchCustomers = async () => {
  const res = await axios.get(
    `${baseUrl}/v1/customers?expand=customerAddresses.customerAddressContacts&pageNumber=1&pageSize=100`
  );

  const transformedCustomers: Customer[] =
    res.data.results.map(transformCustomer);

  return transformedCustomers;
};

export const createSalesOrder = async (salesOrder: SalesOrder) => {
  const res = await axios.post(
    `${baseUrl}/v1/sales_orders`,
    transformRequestSalesOrder(salesOrder)
  );

  analyticsTrackEvent({ eventName: 'Sales order created' });
  return transformSalesOrder(res.data);
};

export const updateSalesOrder = async (salesOrder: SalesOrder) => {
  const res = await axios.put(
    // TODO: have to change transforms to remove expands, but the shouldn't be necessary any more
    `${baseUrl}/v1/sales_orders?expand=${expands.join(',')}`,
    transformRequestSalesOrder(salesOrder)
  );

  return transformSalesOrder(res.data);
};

export const deleteSalesOrder = async (salesOrderId: number) => {
  const res = await axios.delete(
    // TODO: have to change transforms to remove expands, but the shouldn't be necessary any more
    `${baseUrl}/v1/sales_orders/${salesOrderId}?expand=${expands.join(',')}`
  );
  return res.data.id;
};

// all update actions below this point return Promise<void> the assumption is that they
// will throw if they're unsuccessful so we don't need the response for anything.
// this may not be the case
export const quickFulfillSalesOrder = async (salesOrderId: number) => {
  await axios.post(`${baseUrl}/v1/sales_orders/${salesOrderId}/quick_fulfill`);
};

export const cancelSalesOrder = async (salesOrderId: number) => {
  await axios.post(`${baseUrl}/v1/sales_orders/${salesOrderId}/cancel`);
};

export const unissueSalesOrder = async (salesOrderId: number) => {
  await axios.post(`${baseUrl}/v1/sales_orders/${salesOrderId}/unissue`);
};

export const issueSalesOrder = async (salesOrderId: number) => {
  await axios.post(`${baseUrl}/v1/sales_orders/${salesOrderId}/issue`);
};

export const restoreSalesOrder = async (salesOrderId: number) => {
  await axios.post(`${baseUrl}/v1/sales_orders/${salesOrderId}/restore`);
};

export const unissueSalesOrders = async (salesOrderIds: number[]) => {
  await axios.post(`${baseUrl}/v1/sales_orders/unissue`, salesOrderIds);
};

export const fetchSalesOrders = async (config: RequestConfig = {}) => {
  const {
    pagination = defaultMaximumPagination,
    customQuickSearchColumns = [],
    quickSearchValue,
  } = config;

  const quickSearch = quickSearchValue
    ? { quickSearchColumns: customQuickSearchColumns, quickSearchValue }
    : {};
  const params = {
    orderBy: pagination.sort.sortBy,
    ascending: pagination.sort.direction === PaginationDirection.Ascending,
    pageSize: pagination.pageSize,
    pageNumber: pagination.page,
    expand: expands.join(','),
    ...quickSearch,
  };
  const path = `${baseUrl}/v1/sales_orders`;
  const res = await axios.get(path, { params });

  return res.data;
};

export const fetchLocationsByIds = async (
  ids: number[]
): Promise<Location[]> => {
  const res = await axios.get(`${baseUrl}/v1/locations?id=${ids}`);

  return res.data.results;
};
