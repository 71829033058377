import React, { memo, useCallback, useMemo } from 'react';
import { Box, Grid } from '@mui/material';

import { DIMENSIONS_UNITS, DimensionUnit, WEIGHT_UNITS } from 'services/items';
import { MenuItem } from 'ui/components/Menu';
import { TextFieldWithOptions } from 'ui/components/TextField/TextFieldWithOptions';
import { replaceValueInCollection } from 'helpers';

import { useCardRowStyle } from '../styled';
import { ShippingQuoteCartonProps } from './types';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';
import { TextField } from 'ui/components/TextField/TextField';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { IconNames } from 'ui/theme';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';

const weightOptions: MenuItem[] = WEIGHT_UNITS.map((u) => ({
  label: u.name,
  shortName: u.shortName,
}));

const ShippingQuoteCartonRow = (props: ShippingQuoteCartonProps) => {
  const {
    index,
    onDeleteClicked,
    carton,
    setShippingQuote,
    errors,
    onSetWeight,
  } = props;

  const classes = useCardRowStyle();

  const selectedWeightUnitIndex = useMemo(() => {
    if (!carton.weightUnit) {
      return null;
    }
    return weightOptions.findIndex((o) => o.label === carton.weightUnit);
  }, [carton.weightUnit]);

  const selectedDimensionUnit = useMemo(
    () => DIMENSIONS_UNITS.find((u) => u.name === carton.dimensionUnit),
    [carton.dimensionUnit]
  );

  const handleAmountChange = useCallback(
    (unit: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
      setShippingQuote((old) => ({
        ...old,
        cartons: replaceValueInCollection(
          old.cartons,
          {
            ...old.cartons[index],
            [unit]: parseFloat(ev.target.value),
          },
          index
        )!,
      }));

      if (unit === 'weight') {
        onSetWeight(parseFloat(ev.target.value));
      }
    },
    [index, setShippingQuote, onSetWeight]
  );

  const handleDimensionUnitChange = useCallback(
    (_event: any, value: DimensionUnit | null) => {
      setShippingQuote((old) => ({
        ...old,
        cartons: replaceValueInCollection(
          old.cartons,
          { ...old.cartons[index], dimensionUnit: value?.name || null },
          index
        )!,
      }));
    },
    [index, setShippingQuote]
  );

  const handleWeightUnitChange = useCallback(
    (optionIndex: number) => {
      setShippingQuote((old) => ({
        ...old,
        cartons: replaceValueInCollection(
          old.cartons,
          {
            ...old.cartons[index],
            weightUnit: weightOptions[optionIndex].label,
          },
          index
        )!,
      }));
    },
    [index, setShippingQuote]
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      mb={4}
      borderBottom="1px solid"
      borderColor="grey.300"
    >
      <Grid container xs={12} columnSpacing={2} mb={1}>
        <Grid item>
          <Box className={classes.cartonName}>Package {index + 1}</Box>
        </Grid>
        <Grid item paddingBottom={1}>
          <FBOButton
            variant="tertiary"
            color="negative"
            size="small"
            icon={IconNames.TrashCan}
            onClick={() => onDeleteClicked(index)}
            data-qa="shipping-quote-carton-row-trash-can-button"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <CurrencyField
            label="Insured amt"
            name="insuredAmount"
            value={carton.insuredAmount}
            onChange={handleAmountChange('insuredAmount')}
            decimalPlaces={2}
          />
        </Grid>

        <Grid item xs={6}>
          <TextFieldWithOptions
            label="Weight"
            options={weightOptions}
            type="number"
            value={carton.weight}
            onTextChange={handleAmountChange('weight')}
            onMenuChange={handleWeightUnitChange}
            selectedOptionIndex={selectedWeightUnitIndex}
            error={!!errors.weight}
          />
        </Grid>
      </Grid>
      <Grid container xs={12} spacing={2}>
        <Grid item xs={3}>
          <TextField
            className={'redesign'}
            variant={'standard'}
            type="number"
            placeholder="Width"
            label="Width"
            name="width"
            autoComplete="nope"
            value={carton.width}
            onChange={handleAmountChange('width')}
            disableDebounce={true}
            dataQa="carton-item-width"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            className={'redesign'}
            variant={'standard'}
            type="number"
            placeholder="Height"
            label="Height"
            name="height"
            autoComplete="nope"
            value={carton.height}
            onChange={handleAmountChange('height')}
            disableDebounce={true}
            dataQa="carton-item-height"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            className={'redesign'}
            variant={'standard'}
            type="number"
            placeholder="Length"
            label="Length"
            name="length"
            autoComplete="nope"
            value={carton.length}
            onChange={handleAmountChange('length')}
            disableDebounce={true}
            dataQa="sale-item-length"
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            placeholder="Dimension"
            onChange={handleDimensionUnitChange}
            label="Dimension"
            options={DIMENSIONS_UNITS}
            getOptionLabel={(u) => u.name}
            value={selectedDimensionUnit}
            dataQa="sale-item-dimension-unit"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(ShippingQuoteCartonRow);
