import moment from 'moment';
import { transformDateToMomentDate } from 'helpers';

import {
  ChartData,
  DashboardCarrier,
  DashboardCustomerOrVendor,
  DashboardLocation,
  LowStock,
  OpenPick,
  OpenPurchaseOrder,
  OpenReceipt,
  OpenSalesOrder,
  OpenShipment,
  RegionalSaleOrder,
  SalesOrderGeneral,
  TopPurchasedItem,
  TopSellingItem,
} from './types';

export const transformProfitToChartData = (res: any): ChartData => {
  return {
    name: moment(res.latestDate).format('MMM D YYYY'),
    previousValue: Math.abs(parseFloat(res.previousTotalRevenue || 0)),
    previousValueSecondary: Math.abs(parseFloat(res.previousExpenses || 0)),
    latestValue: Math.abs(parseFloat(res.latestTotalRevenue || 0)),
    latestValueSecondary: Math.abs(parseFloat(res.latestExpenses || 0)),
    previousValueToCalculate: Math.abs(parseFloat(res.previousProfit || 0)),
    latestValueToCalculate: Math.abs(parseFloat(res.latestProfit || 0)),
  };
};

export const transformSalesRevenueToChartData = (res: any): ChartData => {
  return {
    name: moment(res.latestDate).format('MMM D YYYY'),
    previousValue: Math.abs(parseFloat(res.previousTotalRevenue || 0)),
    latestValue: Math.abs(parseFloat(res.latestTotalRevenue || 0)),
    previousValueToCalculate: Math.abs(
      parseFloat(res.previousTotalRevenue || 0)
    ),
    latestValueToCalculate: Math.abs(parseFloat(res.latestTotalRevenue || 0)),
  };
};

export const transformPurchaseExpensesToChartData = (res: any): ChartData => {
  return {
    name: moment(res.latestDate).format('MMM D YYYY'),
    previousValue: Math.abs(parseFloat(res.previousExpenses || 0)),
    latestValue: Math.abs(parseFloat(res.latestExpenses || 0)),
    previousValueToCalculate: Math.abs(parseFloat(res.previousExpenses || 0)),
    latestValueToCalculate: Math.abs(parseFloat(res.latestExpenses || 0)),
  };
};

export const transformToSaleByRegion = (res: any): RegionalSaleOrder => {
  return {
    country: res.country,
    state: res.state,
    count: res.count,
    grandTotalSum: res.grandTotalSum,
    currency: res.currency,
  };
};

export const transformToSalesOrderGeneral = (res: any): SalesOrderGeneral => {
  return {
    number: res.number,
    customer: res.customer
      ? transformToDashboardCustomerOrVendor(res.customer)
      : null,
    total: res.total,
    paid: res.paid,
  };
};

export const transformToLowStock = (res: any): LowStock => {
  return {
    id: res.id,
    cost: res.cost,
    itemType: res.itemType,
    name: res.name,
    price: res.price,
    quantity: res.quantity,
    sku: res.sku,
    upc: res.upc,
  };
};

export const transformToTopSellingItem = (res: any): TopSellingItem => {
  return {
    id: res.id,
    name: res.name,
    description: res.description,
    upc: res.upc,
    sku: res.sku,
    quantity: res.quantity,
  };
};

export const transformToOpenSalesOrder = (res: any): OpenSalesOrder => {
  return {
    customer: res.customer
      ? transformToDashboardCustomerOrVendor(res.customer)
      : null,
    dateCreated: res.dateCreated,
    location: res.location ? transformToDashboardLocation(res.location) : null,
    number: res.number,
    paid: res.paid,
    salesOrderStatus: res.salesOrderStatus,
    total: res.total,
  };
};

export const transformToOpenShipment = (res: any): OpenShipment => {
  return {
    carrier: res.carrier ? transformToDashboardCarrier(res.carrier) : null,
    id: res.id,
    itemCount: res.itemCount,
    number: res.number,
    orderPriority: res.orderPriority
      ? transformToDashboardShipmentOrderPriority(res.orderPriority)
      : null,
    shipCartonCount: res.shipCartonCount,
    status: res.status,
  };
};

export const transformToOpenPick = (res: any): OpenPick => {
  return {
    id: res.id,
    number: res.number,
    status: res.status,
    location: res.location ? transformToDashboardLocation(res.location) : null,
    scheduledDate: transformDateToMomentDate(res.scheduledDate),
    orderPriority: res.orderPriority
      ? transformToDashboardShipmentOrderPriority(res.orderPriority)
      : null,
  };
};

export const transformToOpenReceipt = (res: any): OpenReceipt => {
  return {
    carrier: res.carrier ? transformToDashboardCarrier(res.carrier) : null,
    customer: res.customer
      ? transformToDashboardCustomerOrVendor(res.customer)
      : null,
    dateIssued: res.dateIssued,
    id: res.id,
    number: res.number,
    orderType: res.orderType,
    status: res.status,
    vendor: res.vendor
      ? transformToDashboardCustomerOrVendor(res.vendor)
      : null,
  };
};

export const transformToOpenPurchaseOrder = (res: any): OpenPurchaseOrder => {
  return {
    buyer: res.buyer ? transformToDashboardBuyer(res.buyer) : null,
    dateCreated: res.dateCreated,
    id: res.id,
    number: res.number,
    status: res.status,
    total: res.total,
    vendor: res.vendor
      ? transformToDashboardCustomerOrVendor(res.vendor)
      : null,
  };
};

export const transformToTopPurchasedItem = (res: any): TopPurchasedItem => {
  return {
    id: res.id,
    description: res.description,
    name: res.name,
    quantity: res.quantity,
    sku: res.sku,
    upc: res.upc,
  };
};

export const transformToDashboardCustomerOrVendor = (
  res: any
): DashboardCustomerOrVendor => {
  return {
    id: res.id,
    name: res.name,
    email: res.email,
  };
};

export const transformToDashboardLocation = (res: any): DashboardLocation => {
  return {
    id: res.id,
    name: res.name,
    description: res.description,
    displayName: res.displayName,
    path: res.path,
  };
};

export const transformToDashboardCarrier = (res: any): DashboardCarrier => {
  return {
    id: res.id,
    name: res.name,
    description: res.description,
    scac: res.scac,
  };
};

export const transformToDashboardShipmentOrderPriority = (res: any) => {
  return {
    id: res.id,
    name: res.name,
    priorityLevel: res.priorityLevel,
  };
};

export const transformToDashboardBuyer = (res: any) => {
  return {
    email: res.email,
    firstName: res.firstName,
    id: res.id,
    lastName: res.lastName,
  };
};
