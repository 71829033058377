import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const LEDGER_ITEMS_COLUMNS: Column[] = [
  {
    title: 'Date',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Type',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Ref. No',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Customer/Job',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Class/Category',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Name',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Memo',
    width: 270,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Account',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Debit',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Credit',
    type: ColumnTypes.renderByCustomRow,
  },
];
