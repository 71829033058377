import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Box } from '@mui/material';

import {
  ItemsTable,
  useSelectedItemsChanges,
} from 'ui/components/Table/ItemsTable';

import { getSettingsCompany } from 'services/settings/company/redux';
import { initialAddress, Address } from 'services/addresses';
import {
  findNextNegativeId,
  replaceValueInCollection,
  removeValueFromCollection,
} from 'helpers';
import { Errors, validateYup } from 'services/forms/validation';
import { activeUserHasPermission } from 'services/user/redux';
import {
  yupAddressContactSchema,
  yupAddressSchema,
} from 'services/addresses/validations';
import { AddressModal } from 'ui/components/Modal/AddressModal';

import { ADDRESSES_COLUMNS, AddressTabAction } from './consts';
import { AddressesTabProps } from './types';
import AddressesRow from './AddressesRow';
import { editVendorPermissions } from '../helpers';
import { extractDefaultContacts } from './helpers';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const AddressesTab: React.FC<AddressesTabProps> = (props) => {
  const { activeVendor, setActiveVendor } = props;
  const { addresses } = activeVendor;

  const companySettings = useSelector(getSettingsCompany);

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [activeAddress, setActiveAddress] = useState<Address>(initialAddress);
  const [validationErrors, setValidationErrors] = useState<Errors>({});
  const [contactErrors, setContactErrors] = useState<Errors[]>([{}]);
  const [selectedAddresses, setSelectedAddresses] = useState<number[]>([]);

  const editPermission = editVendorPermissions(activeVendor);

  const canShowAddressModal = useSelector(
    activeUserHasPermission(editPermission)
  );

  const handleSelectedChange = useSelectedItemsChanges(
    selectedAddresses,
    setSelectedAddresses
  );

  const openModalForEditing = useCallback(
    (id: number) => {
      setActiveAddress(addresses.find((a) => a.id === id)!);
      if (canShowAddressModal) {
        setModalVisible(true);
      }
    },
    [addresses, canShowAddressModal]
  );

  const openModalForAddingNew = useCallback(() => {
    setActiveAddress({
      ...initialAddress,
      contacts: extractDefaultContacts(activeVendor),
      id: findNextNegativeId(activeVendor.addresses),
      country: companySettings.country,
    });
    setModalVisible(true);
  }, [activeVendor.addresses, companySettings.country]);

  const setAddressItems = useCallback(
    (addressItems: React.SetStateAction<Address[]>) => {
      if (typeof addressItems === 'function') {
        setActiveVendor((v) => ({
          ...v,
          addresses: addressItems(v.addresses),
        }));
        return;
      }
      setActiveVendor((v) => ({
        ...v,
        addresses: addressItems,
      }));
    },
    [setActiveVendor]
  );

  const handleSave = useCallback(
    (address: Address) => {
      const isValid = validateYup(
        address,
        yupAddressSchema,
        setValidationErrors
      );

      const newErrors = [...contactErrors];
      const contactValidArray = address.contacts.map((contact, index) => {
        if (contact.deleted) {
          return true;
        }
        return validateYup(
          contact,
          yupAddressContactSchema,
          (error: Errors) => {
            newErrors[index] = error;
          }
        );
      });
      setContactErrors(newErrors);

      const areContactInValid = contactValidArray.some((valid) => !valid);

      if (!isValid || areContactInValid) {
        return;
      }

      const indexNum = activeVendor.addresses.findIndex(
        (a) => a.id === address.id
      );

      if (indexNum === -1) {
        setAddressItems((oldState) => [...oldState, address]);
      } else {
        setAddressItems(
          (oldState) => replaceValueInCollection(oldState, address, indexNum)!
        );
      }

      setModalVisible(false);
      setContactErrors([]);
    },
    [activeVendor, setAddressItems, contactErrors]
  );

  const handleAction = useCallback(
    (action: any) => {
      switch (action.type) {
        case AddressTabAction.Delete: {
          const address = addresses[action.value];
          if (address.id === null || address.id < 0) {
            setAddressItems((old) =>
              removeValueFromCollection(old, action.value)
            );
          } else {
            setAddressItems(
              (old) =>
                replaceValueInCollection(
                  old,
                  { ...old[action.value], deleted: true },
                  action.value
                ) || []
            );
          }
          break;
        }
      }
    },
    [addresses, setAddressItems]
  );

  const deleteClicked = useCallback(() => {
    const newAddressItems: Address[] = activeVendor.addresses.map((address) => {
      if (selectedAddresses.includes(address.id!)) {
        return { ...address, deleted: true };
      }
      return address;
    });
    const newRemoved: Address[] = newAddressItems.filter((address) => {
      return !((address.id || 0) < 0 && address.deleted);
    });

    setAddressItems(newRemoved);
    setSelectedAddresses([]);
  }, [setAddressItems, selectedAddresses, activeVendor.addresses]);

  const onModalClose = useCallback(() => {
    setModalVisible(false);
    setContactErrors([]);
    setValidationErrors({});
    setActiveAddress(initialAddress);
  }, []);

  return (
    <Box flexDirection="column" display="flex" height="100%" width="100%">
      <FBOTitleBar title="Addresses">
        {!_.isEmpty(selectedAddresses) && (
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="secondary"
            color="negative"
            size="medium"
            icon="TrashCan"
            data-qa="vendor-addresses-delete"
            onClick={deleteClicked}
            permissions={editPermission}
          >
            Delete
          </FBOButton>
        )}

        <FBOButton
          variant="secondary"
          color="positive"
          size="medium"
          icon="FBOAddCircle"
          data-qa="vendor-addresses-add"
          onClick={openModalForAddingNew}
          permissions={editPermission}
        >
          Add New
        </FBOButton>
      </FBOTitleBar>

      <ItemsTable
        data={addresses}
        filterRows={(address) => !address.deleted}
        columns={ADDRESSES_COLUMNS}
        onItemClick={openModalForEditing}
        selectedItems={selectedAddresses}
        RenderCustomRow={AddressesRow}
        onSelectedChange={handleSelectedChange}
        onAction={handleAction}
        dataQa="vendor-address-table"
        emptyTableText="ADD NEW ENTRY BY PRESSING 'ADD NEW'"
      />
      <AddressModal
        onClose={onModalClose}
        modalVisible={modalVisible}
        activeAddress={activeAddress}
        setActiveAddress={setActiveAddress}
        onSaveChanges={handleSave}
        validationErrors={validationErrors}
        contactErrors={contactErrors}
      />
    </Box>
  );
};

export default React.memo(AddressesTab);
