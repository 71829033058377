import {
  createAddSearch,
  createDeleteSearch,
  createFetchSearch,
  createUpdateSearch,
} from 'services/search';

// SEARCH ACTIONS

export const fetchSearch = createFetchSearch('picking');
export const addSearch = createAddSearch('picking');
export const updateSearch = createUpdateSearch('picking');
export const deleteSearch = createDeleteSearch('picking');
