import React, { memo } from 'react';
import { TableCell, Tooltip, Typography } from '@mui/material';
import moment from 'moment';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import { ImageItemBox } from 'ui/components/Image/ImageItemBox';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';
import { ReconcileReceiptItem } from 'services/receiving';

import {
  useCurrencyFormatter,
  roundToDecimals,
  toHomeCurrencyCalculate,
  toMulticurrencyCalculate,
  useGetIntlDateFormatString,
} from 'helpers';

import { useReconcileWizardItemsStyle } from './styled';
import { MultiCurrencyWrapper } from 'ui/components/MultiCurrencyWrapper/MultiCurrencyWrapper';

const ReconcileWizardItemsRow: React.FC<
  RenderCustomRowProps<ReconcileReceiptItem>
> = (props) => {
  const { row, setData, index } = props;

  const { currency } = row;
  const currencyFormatter = useCurrencyFormatter();

  const intlFormatDate = useGetIntlDateFormatString();

  const activeMulticurrencyCode =
    currency && currency.code ? currency.code : 'USD';

  const exchangeRate =
    currency && currency.exchangeRate ? currency.exchangeRate : 1;

  const classes = useReconcileWizardItemsStyle();

  const handleUnitCostChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const unitCost = event.target.value ? parseFloat(event.target.value) : null;
    const totalCost = (unitCost || 0) * (row.quantity || 0);
    const multiCurrencyUnitCost = toMulticurrencyCalculate(
      unitCost || 0,
      exchangeRate
    );
    const multiCurrencyTotalCost =
      (multiCurrencyUnitCost || 0) * (row.quantity || 0);

    setData(
      {
        ...row,
        unitCost,
        totalCost,
        multiCurrencyUnitCost,
        multiCurrencyTotalCost,
      },
      index
    );
  };

  const handleMulticurrencyUnitCostChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const multiCurrencyUnitCost = event.target.value
      ? parseFloat(event.target.value)
      : null;

    const unitCost = toHomeCurrencyCalculate(
      multiCurrencyUnitCost || 0,
      exchangeRate
    );
    const multiCurrencyTotalCost =
      (multiCurrencyUnitCost || 0) * (row.quantity || 0);
    const totalCost = (unitCost || 0) * (row.quantity || 0);
    setData(
      {
        ...row,
        unitCost,
        totalCost,
        multiCurrencyTotalCost,
        multiCurrencyUnitCost,
      },
      index
    );
  };

  return (
    <>
      <TableCell>
        <ImageItemBox
          name={row.name}
          description={row.description}
          source={row.image}
        />
      </TableCell>
      <TableCell>
        <Typography>{row.quantity + ' ' + row.abbreviation}</Typography>
      </TableCell>
      <TableCell>{row.status}</TableCell>
      <TableCell>
        <Tooltip title={row.referenceNumber || '-'}>
          <Typography className={classes.cellStyle}>
            {row.referenceNumber || '-'}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell>
        {row.dateReceived
          ? moment(row.dateReceived).format(intlFormatDate)
          : null}
      </TableCell>
      <TableCell>
        <CurrencyField
          placeholder="Reconcile"
          value={roundToDecimals(row.unitCost || 0, 6)}
          fullWidth
          onChange={handleUnitCostChange}
          allowNegative
          decimalPlaces={6}
        />
      </TableCell>
      <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
        <TableCell>
          <CurrencyField
            placeholder="Reconcile"
            value={roundToDecimals(row.multiCurrencyUnitCost || 0, 6)}
            fullWidth
            onChange={handleMulticurrencyUnitCostChange}
            currencyCode={activeMulticurrencyCode}
            allowNegative
            debounceDelay={500}
            decimalPlaces={6}
          />
        </TableCell>
      </MultiCurrencyWrapper>
      <TableCell>{currencyFormatter(row.totalCost || 0)}</TableCell>
      <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
        <TableCell>
          {currencyFormatter(
            row.multiCurrencyTotalCost || 0,
            activeMulticurrencyCode
          )}
        </TableCell>
      </MultiCurrencyWrapper>
    </>
  );
};

export default memo(ReconcileWizardItemsRow);
