import { Carrier } from 'services/carriers';

import { ShippingCarrierType } from '.';

export const getCarrierUrl = (
  trackingUrl: string,
  selectedCarrier: Carrier
) => {
  switch (selectedCarrier.name) {
    case ShippingCarrierType.UPS: {
      return `http://wwwapps.ups.com/WebTracking/track?loc=en_US&track.x=Track&trackNums=${trackingUrl}`;
    }
    case ShippingCarrierType.USPS: {
      return `https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${trackingUrl}`;
    }
    case ShippingCarrierType.FedEx: {
      return `http://fedex.com/Tracking?action=track&language=english&cntry_code=us&tracknumbers=${trackingUrl}`;
    }
    default:
      return;
  }
};
