import { InvoiceDetailsLedger } from 'services/invoices';
import { TransactionsTableRow } from './types';

export const transformToTransactionsTable = (
  ledgers: InvoiceDetailsLedger[]
) => {
  const transformedArray: TransactionsTableRow[] = [];
  ledgers.forEach((ledger) => {
    ledger.ledgerItems?.forEach((ledgerItem) => {
      let tempObject;
      if (ledger.ledgerItems?.indexOf(ledgerItem) === 0) {
        tempObject = {
          date: ledger.date,
          type: ledger.type,
          refNo: ledger.refNo,
          name: ledger.name,
          memo: ledgerItem.memo,
          accountName: ledgerItem.accountName,
          creditAmount: ledgerItem.creditAmount,
          debitAmount: ledgerItem.debitAmount,
        };
      } else {
        tempObject = {
          date: null,
          type: null,
          refNo: null,
          name: null,
          memo: ledgerItem.memo,
          accountName: ledgerItem.accountName,
          creditAmount: ledgerItem.creditAmount,
          debitAmount: ledgerItem.debitAmount,
        };
      }
      transformedArray.push(tempObject);
    });
  });
  return transformedArray;
};
