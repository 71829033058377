import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { colorPalette } from 'ui/theme';

export const useFBOInventoryHeroStatusItemStyle = makeStyles(
  (theme: Theme) => ({
    bold: { fontWeight: theme.typography.fontWeightBold },
    itemContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    clickable: {
      color: colorPalette.redesign.contentLinkBlue,
      fontWeight: 400,
      textTransform: 'none',
    },
    default: {
      color: colorPalette.redesign.contentPrimary,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      cursor: 'default',
      fontWeight: 400,
      textTransform: 'none',
    },
    warningIcon: {
      color: colorPalette.statusRed,
    },
  })
);
