import React, { useMemo } from 'react';
import _ from 'lodash';
import { TableCell } from '@mui/material';
import moment from 'moment';

import { ImageItemBox } from 'ui/components/Image/ImageItemBox';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import { useCurrencyFormatter } from 'helpers';
import { CustomerItem } from 'services/customers';
import { MultiCurrencyWrapper } from 'ui/components/MultiCurrencyWrapper/MultiCurrencyWrapper';

const ItemsTabCustomRow: React.FC<RenderCustomRowProps<CustomerItem>> = (
  props
) => {
  const {
    row,
    meta: { activeMulticurrencyCode },
  } = props;

  const currencyFormatter = useCurrencyFormatter();

  const dateModified = useMemo(() => {
    if (row.dateLastModified) {
      return moment(row.dateLastModified).format('MM/DD/YYYY');
    }
    return '';
  }, [row.dateLastModified]);

  const formatedMulticurrency = useMemo(
    () =>
      activeMulticurrencyCode &&
      currencyFormatter(
        row.multicurrencyCustomerItemPrice || 0,
        activeMulticurrencyCode
      ),
    [
      row.multicurrencyCustomerItemPrice,
      activeMulticurrencyCode,
      currencyFormatter,
    ]
  );

  return (
    <>
      <TableCell>
        <ImageItemBox
          name={_.get(row, 'saleItem.name', null)}
          description={_.get(row, 'saleItem.description', null)}
          source={_.get(row, 'saleItem.defaultImage', null)}
        />
      </TableCell>
      <TableCell>{row.name || '-'}</TableCell>
      <TableCell>{currencyFormatter(row.price || 0)}</TableCell>
      <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
        <TableCell>{formatedMulticurrency}</TableCell>
      </MultiCurrencyWrapper>
      <TableCell>{dateModified}</TableCell>
    </>
  );
};

export default React.memo(ItemsTabCustomRow);
