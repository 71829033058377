import { MenuItem, MenuItemVariant } from 'ui/components/Menu';
import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';
import { IconNames } from 'ui/theme';

export const createAccountsActionBarOptions = (
  onDeleteClick: () => void
): MenuItem[] => [
  {
    label: 'Delete Account',
    onOptionClick: () => onDeleteClick(),
    icon: IconNames.FBODeleteSmall,
    variant: MenuItemVariant.Danger,
  },
];

export const LEDGER_COLUMNS: Column[] = [
  {
    title: 'Date',
    field: 'date',
    sortable: true,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Type / Ref.',
    field: 'ledgerType',
    sortable: true,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Payee / Account',
    field: 'payee',
    sortable: true,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Memo',
    field: 'description',
    sortable: true,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Posted',
    field: 'posted',
    sortable: true,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Amount',
    field: 'amount',
    sortable: true,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Balance',
    field: 'balance',
    sortable: true,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: '',
    field: '',
    searchable: false,
    type: ColumnTypes.renderByCustomRow,
    width: 50,
  },
];
