import { DateRange } from 'materialui-daterange-picker';
import moment from 'moment';

export const getRangeForRequest = (
  field: string,
  value: DateRange,
  useNewFormat: boolean = false
) => {
  if (!value.startDate || !value.endDate) {
    return null;
  }

  const from = moment(value.startDate)
    .startOf('day')
    .format('YYYY-MM-DD HH:mm:ss');

  const to = moment(value.endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');

  if (useNewFormat) {
    return {
      [field + '.gte']: from,
      [field + '.lte']: to,
    };
  } else {
    return {
      [field + 'From']: from,
      [field + 'To']: to,
    };
  }
};
