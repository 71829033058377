import { Tag } from 'services/tags';
import { Location } from 'services/locations';
import { CommerceState } from './types';

export const transformCommerce = (res: any): CommerceState => {
  return {
    _status: res?._status,
    connection: res?.connection,
    configuration: res?.configuration,
    settings: res?.settings,
  };
};

export const getIdsAsArray = (
  objects: Location[] | Tag[]
): (number | null)[] | null => {
  if (objects) {
    if (objects.length > 0) {
      const idList = objects.map((item) => item?.id);
      return idList;
    }
  }
  return null;
};
