import * as yup from 'yup';

const yupCarriersServicesSchema = yup.object().shape({
  name: yup.mixed().when('deleted', {
    is: false,
    then: yup.string().trim().required(),
  }),
});

export const yupCarriersSchema = yup.object().shape({
  name: yup.string(),
  carrierServiceList: yup.array().of(yupCarriersServicesSchema),
});
