import React from 'react';

import { ReportsViewer } from 'ui/components/ReportsViewer';

import { Modal } from '../Modal';
import { useReportsModalStyle } from './styled';
import { ReportModalProps } from './types';

const ReportsModal: React.FC<ReportModalProps> = (props) => {
  const { isOpen, reportId, onClose, params, autoGenerate } = props;

  const classes = useReportsModalStyle();

  return (
    <Modal
      open={isOpen}
      title="Reports"
      applyLabel="Submit"
      onCancelClicked={onClose}
      withoutDefaultPadding
      fullScreen
      className={classes.reportsModal}
      ModalActionsComponent={() => <></>}
    >
      <ReportsViewer
        reportId={reportId}
        defaultParams={params}
        autoGenerate={autoGenerate}
      />
    </Modal>
  );
};

export default ReportsModal;
