import { AddressValidation } from 'services/addresses';
import { Ship } from 'services/shipping';

export const setVerifiedAddress = (
  addressValidation: AddressValidation,
  setActiveShip: React.Dispatch<React.SetStateAction<Ship>>
) => {
  setActiveShip((old) => ({
    ...old,
    shipToCity: addressValidation.validated.city,
    shipToStreet: addressValidation.validated.street,
    shipToStreet2: addressValidation.validated.street2,
    shipToState: addressValidation.validated.state,
    shipToPostalCode: addressValidation.validated.postalCode,
    shipToName: addressValidation.validated.name,
    shipToCountry: addressValidation.validated.country,
    shipToCompanyName: addressValidation.validated.companyName,
    shipToResidential: addressValidation.validated.residential,
    shipToAttention: addressValidation.validated.attention,
    shipToVerified: true,
  }));
};
