import React, { useMemo } from 'react';
import { TableCell, TableRow, TableFooter, Typography } from '@mui/material';

import { useCurrencyFormatter } from 'helpers';
import { RenderCustomFooterProps } from 'ui/components/Table/ItemsTable';
import { TransactionsTableRow } from './types';
import { colorPalette } from 'ui/theme';

const TransactionsTableFooter: React.FC<
  RenderCustomFooterProps<TransactionsTableRow[]>
> = (props) => {
  const { footerData } = props;
  const currencyFormatter = useCurrencyFormatter();

  const debitTotal = useMemo(() => {
    if (!footerData) {
      return null;
    }

    return footerData.reduce((acc, item) => (item.debitAmount || 0) + acc, 0);
  }, [footerData]);

  const creditTotal = useMemo(() => {
    if (!footerData) {
      return null;
    }

    return footerData.reduce((acc, item) => (item.creditAmount || 0) + acc, 0);
  }, [footerData]);

  return (
    <TableFooter>
      <TableRow style={{ height: '56px' }}>
        <TableCell colSpan={6} />
        <TableCell data-qa="TotalRow-Total">
          <Typography
            variant="body2"
            style={{ color: `${colorPalette.redesign.contentPrimary}` }}
          >
            <b>Total</b>
          </Typography>
        </TableCell>
        <TableCell data-qa="TotalRow-Debit">
          <Typography
            variant="body2"
            style={{ color: `${colorPalette.redesign.contentPrimary}` }}
          >
            <b>{currencyFormatter(debitTotal ?? 0)}</b>
          </Typography>
        </TableCell>
        <TableCell data-qa="TotalRow-Credit">
          <Typography
            variant="body2"
            style={{ color: `${colorPalette.redesign.contentPrimary}` }}
          >
            <b>{currencyFormatter(creditTotal ?? 0)}</b>
          </Typography>
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};

export default TransactionsTableFooter;
