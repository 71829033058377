import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useCardSectionStyle = makeStyles((theme: Theme) => ({
  cardSectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(8),
    flexShrink: 0,
  },
  cardSectionHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 42,
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  cardSectionEmpty: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
    borderRadius: 8,
    height: 200,
  },
}));
