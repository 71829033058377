import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable/types';

export const SALE_ITEMS_COLUMNS: Column[] = [
  {
    title: 'Name',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'UOM',
    type: ColumnTypes.renderByCustomRow,
    width: 100,
  },
  {
    title: 'W/H/L (Weight)',
    type: ColumnTypes.renderByCustomRow,
    width: 150,
  },
  {
    title: 'Price',
    type: ColumnTypes.renderByCustomRow,
    width: 100,
  },
  {
    title: 'Default',
    width: 120,
    type: ColumnTypes.renderByCustomRow,
  },
];

export enum SaleItemTableRowActions {
  Default,
  Delete,
}
