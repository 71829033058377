import React from 'react';
import { CircularProgress } from '@mui/material';

type ButtonSpinnerProps = {
  size: number | 'auto';
} & typeof defaultProps;

const defaultProps = {
  size: 'auto',
};

const ButtonSpinner = (props: ButtonSpinnerProps) => (
  <CircularProgress size={props.size} />
);

ButtonSpinner.defaultProps = defaultProps;

export default ButtonSpinner;
