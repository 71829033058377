import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { ItemsTable } from 'ui/components/Table/ItemsTable';

import { DashboardTableProps } from './types';
import { useDashboardTableStyle } from './styled';

const DashboardTable: React.FC<DashboardTableProps> = (props) => {
  const { routeLink, routeLinkLabel, title, data, columns, ...itemTableProps } =
    props;

  const classes = useDashboardTableStyle();

  return (
    <Box className={classes.container}>
      <Box className={classes.card}>
        <Box className={classes.cardHeader}>
          <Typography className={classes.cardHeaderTitle}>{title}</Typography>
          <Link className={classes.cardHeaderLink} to={routeLink}>
            {routeLinkLabel}
          </Link>
        </Box>
        <Box className={classes.cardContent}>
          <ItemsTable
            data={data}
            columns={columns}
            emptyTableText="No data"
            showEnhancedTablePaginationActions
            {...itemTableProps}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardTable;
