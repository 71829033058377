import { TaxRate, TaxType } from './types';

export const renderTaxRate = (taxRate: TaxRate, symbol: string) => {
  if (taxRate.taxType === TaxType.percentage) {
    return showNumberAsPercentage(taxRate.percentage) + '%';
  } else {
    return symbol + taxRate.amount!.toFixed(2);
  }
};

// Because of a precision error when multiplying certain numbers we use toFixed to round them and Number to remove trailing zeros
export const showNumberAsPercentage = (value: number | null) => {
  return Number(((value || 0) * 100).toFixed(2));
};
