import { makeStyles } from '@mui/styles';
import { colorPalette } from 'ui/theme';

export const useFormRadioGroupStyles = makeStyles((theme) => ({
  formLegend: {
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  radioGroup: {
    overflow: 'hidden',
    border: `1px solid ${colorPalette.white5}`,
    borderRadius: 16,
  },
  formControlLabel: {
    margin: 0,
    padding: '0.5rem 0.75rem',
    '&:not(:last-of-type)': {
      borderRight: `1px solid ${colorPalette.white5}`,
    },
    '& > .MuiIconButton-root': {
      padding: 0,
    },
    '& > .MuiFormControlLabel-label': {
      fontWeight: 900,
    },
  },
  formControlLabelChecked: {
    paddingLeft: '0.675rem',
    backgroundColor: colorPalette.white5,
    '& > .MuiFormControlLabel-label': {
      fontWeight: 900,
      color: colorPalette.fishbowlCorporateBlue,
    },
  },
  radio: {
    '&.Mui-checked': {
      marginRight: 5,
      pointerEvents: 'none',
    },
  },
}));
