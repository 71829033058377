import React, { memo, useMemo } from 'react';
import { Box, Typography, Link } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';

import { ImageItem } from 'ui/components/Image/ImageItem';
import { getTimezone } from 'ui/modules/setup/services';
import { COUNTRIES } from 'ui/components/Autocomplete/CountryAutocomplete/consts';
import { useGetIntlDateFormatString } from 'helpers';
import Icon from 'ui/components/Icon/Icon';

import {
  AutocompleteDescriptor,
  BooleanDescriptor,
  CountryAutocompleteDescriptor,
  HyperTextDescriptor,
  ImageDescriptor,
  LocationPickerDescriptor,
  RowDescriptor,
  RowType,
  TextDescriptor,
  TimezoneAutocompleteDescriptor,
  AccountMappingDescriptor,
  GoLiveToggleDescriptor,
} from '../../types';
import { CardRowProps } from './types';
import { useCardRowStyle } from './styled';
import { IconNames } from 'ui/theme';

type DescriptorType = BooleanDescriptor;
type BooleanRowType = DescriptorType & CardRowProps;

const HyperTextRow: React.FC<HyperTextDescriptor & CardRowProps> = (props) => {
  const classes = useCardRowStyle(props);
  const { title, data, field } = props;

  const value = _.get(data, field || '', '');

  const handleLinkClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (!value) {
      return;
    }
    if (!value.startsWith('http://') && !value.startsWith('https://')) {
      return window.open(`http://${value}`, '_blank');
    }
    return window.open(value, '_blank');
  };

  return (
    <Box className={classes.rowContainer}>
      <Box className={classes.titleContainer}>
        <Typography dangerouslySetInnerHTML={{ __html: title }} />
      </Box>
      <Link
        href={value as string}
        className={classes.link}
        noWrap
        onClick={handleLinkClick}
        underline="hover"
      >
        {value as string}
      </Link>
    </Box>
  );
};

const AccountMappingRow: React.FC<AccountMappingDescriptor & CardRowProps> = (
  props
) => {
  const classes = useCardRowStyle(props);
  const { title, data, options, field } = props;

  const selectedAccount = useMemo(() => {
    const value = _.get(data, field || '', '');
    return options.find((a) => a.id === value);
  }, [options, data, field]);

  return (
    <Box className={classes.rowContainer}>
      <Typography
        className={classes.titleContainer}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <Icon name={IconNames.FBOCaratRightSmall} />
      <Typography className={classes.text}>
        {selectedAccount ? selectedAccount.name : 'Not Mapped'}
      </Typography>
    </Box>
  );
};

const ImageRow: React.FC<ImageDescriptor & CardRowProps> = (props) => {
  const { title, data, field, iconSource } = props;

  const classes = useCardRowStyle(props);

  const value = _.get(data, field || '', '');

  return (
    <Box className={classes.imgContainer}>
      <Box className={classes.titleContainer}>
        <Typography dangerouslySetInnerHTML={{ __html: title }} />
      </Box>
      {!value && iconSource ? (
        <Icon name={iconSource} />
      ) : (
        <ImageItem source={value as string} />
      )}
    </Box>
  );
};

const TextRow: React.FC<TextDescriptor & CardRowProps> = (props) => {
  const classes = useCardRowStyle(props);
  const { title, data, field } = props;

  const value = _.get(data, field || '', '');

  const resolvedTextCmp = (
    <Typography className={classes.text} noWrap>
      {value || 'None'}
    </Typography>
  );

  return (
    <Box className={classes.rowContainer}>
      <Box className={classes.titleContainer}>
        <Typography dangerouslySetInnerHTML={{ __html: title }} />
      </Box>
      {resolvedTextCmp}
    </Box>
  );
};

const BooleanRow: React.FC<BooleanRowType> = (props) => {
  const classes = useCardRowStyle(props);
  const { title, data, subBooleanRows, field } = props;

  const resolvedTextBoolean = useMemo(() => {
    // if we are not using field then whether the resolvedText will be Enabled or Disabled depends on the subBooleanRows.
    if (field) {
      return _.get(data, field, false);
    }

    if (subBooleanRows) {
      return subBooleanRows
        .map((r) => _.get(data, r.field || ''))
        .includes(true);
    }

    return false;
  }, [data, field, subBooleanRows]);

  const resolvedText = resolvedTextBoolean ? 'Enabled' : 'Disabled';
  return (
    <Box className={classes.rowContainer}>
      <Box className={classes.titleContainer}>
        <Typography dangerouslySetInnerHTML={{ __html: title }} />
      </Box>
      <Typography className={classes.booleanText} noWrap>
        {resolvedText}
      </Typography>
    </Box>
  );
};

const GoLiveToggleRow: React.FC<GoLiveToggleDescriptor & CardRowProps> = (
  props
) => {
  const classes = useCardRowStyle(props);
  const { title, data, field } = props;
  const value = _.get(data, field || '', null);

  const intlFormatDate = useGetIntlDateFormatString();

  return (
    <Box className={classes.rowContainer}>
      <Box className={classes.titleContainer}>
        <Typography dangerouslySetInnerHTML={{ __html: title }} />
      </Box>
      <Typography className={classes.booleanText} noWrap>
        {value ? moment(value).format(intlFormatDate) : ''}
      </Typography>
    </Box>
  );
};

const AutocompleteRow: React.FC<AutocompleteDescriptor & CardRowProps> = (
  props
) => {
  const classes = useCardRowStyle(props);
  const { title, data, field } = props;

  const value = useMemo(() => {
    if (!field) {
      return '';
    }

    const fieldValue = _.get(data, field + '.name');
    if (fieldValue) {
      // if field is an object get its Name
      return fieldValue;
    }

    return _.get(data, field);
  }, [data, field]);

  const resolvedTextCmp = (
    <Typography className={classes.text} noWrap>
      {value || 'None'}
    </Typography>
  );

  return (
    <Box className={classes.rowContainer}>
      <Box className={classes.titleContainer}>
        <Typography dangerouslySetInnerHTML={{ __html: title }} />
      </Box>
      {resolvedTextCmp}
    </Box>
  );
};

const CountryAutocompleteRow: React.FC<
  CountryAutocompleteDescriptor & CardRowProps
> = (props) => {
  const classes = useCardRowStyle(props);
  const { title, data, field } = props;

  const value = _.get(data, field || '', '');

  const activeCountry = useMemo(() => {
    const newCountry = COUNTRIES.find((c) => c.code === value);
    if (!newCountry) {
      return '';
    }
    return newCountry.label;
  }, [value]);

  return (
    <Box className={classes.rowContainer}>
      <Box className={classes.titleContainer}>
        <Typography dangerouslySetInnerHTML={{ __html: title }} />
      </Box>
      <Typography className={classes.text} noWrap>
        {activeCountry}
      </Typography>
    </Box>
  );
};

const LocationPickerRow: React.FC<LocationPickerDescriptor & CardRowProps> = (
  props
) => {
  const classes = useCardRowStyle(props);
  const { title, data, field } = props;

  const value = useMemo(() => {
    if (!field) {
      return '';
    }
    return _.get(data, field + '.name');
  }, [data, field]);

  return (
    <Box className={classes.rowContainer}>
      <Box className={classes.titleContainer}>
        <Typography dangerouslySetInnerHTML={{ __html: title }} />
      </Box>
      <Typography className={classes.text} noWrap>
        {value}
      </Typography>
    </Box>
  );
};
const TimezoneAutocompleteRow: React.FC<
  TimezoneAutocompleteDescriptor & CardRowProps
> = (props) => {
  const classes = useCardRowStyle(props);
  const { title, data, field } = props;

  const value = useMemo(() => {
    if (!field) {
      return '';
    }

    const timezone = getTimezone(_.get(data, field));
    return timezone ? timezone.text : 'Unknown timezone';
  }, [data, field]);

  return (
    <Box className={classes.rowContainer}>
      <Box className={classes.titleContainer}>
        <Typography dangerouslySetInnerHTML={{ __html: title }} />
      </Box>
      <Typography className={classes.text} noWrap>
        {value}
      </Typography>
    </Box>
  );
};
const CardRow: React.FC<RowDescriptor & CardRowProps> = (props) => {
  switch (props.type) {
    case RowType.Boolean:
      return <BooleanRow {...props} />;
    case RowType.Text:
      return <TextRow {...props} />;
    case RowType.HyperText:
      return <HyperTextRow {...props} />;
    case RowType.Image:
      return <ImageRow {...props} />;
    case RowType.Autocomplete:
      return <AutocompleteRow {...props} />;
    case RowType.CountryAutocomplete:
      return <CountryAutocompleteRow {...props} />;
    case RowType.LocationPicker:
      return <LocationPickerRow {...props} />;
    case RowType.TimezoneAutocomplete:
      return <TimezoneAutocompleteRow {...props} />;
    case RowType.AccountMapping:
      return <AccountMappingRow {...props} />;
    case RowType.GoLiveToggle:
      return <GoLiveToggleRow {...props} />;
  }
};

export default memo(CardRow);
