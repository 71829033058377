import { State } from 'app/redux';

export const getLocations = (state: State) => state.location.locations;

export const getLocationsWithoutCompanyWide = (state: State) => {
  return state.location.locations.items.filter((l) => !l.companyWide);
};

export const getParentLocations = (state: State) => {
  return state.location.locations.items.filter(
    (l) => l.parentLocation === null
  );
};

export const getSearches = (state: State) => state.location.searches;

export const getLocationById = (id: number | null) => (state: State) => {
  if (id === null) {
    return undefined;
  }
  return state.location.locations.items.find((item) => item.id === id);
};
