import React, { memo, useCallback, useMemo } from 'react';
import { PermissionType } from 'services/permissions';
import { showNotification } from 'services/api/notifications';

import { ItemTitleBarProps } from './types';
import { createActionBarOptions } from './helpers';
import { ItemTab } from '../../types';

import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';

const ItemTitleBar: React.FC<ItemTitleBarProps> = (props) => {
  const {
    item,
    trackingTabName,
    onSave,
    onClose,
    onDelete,
    onDuplicate,
    onOpenReport,
    onUndeleteClicked,
    setSaveOnlyClicked,
  } = props;

  const committedQty = useMemo(
    () =>
      item.inventoryLocationSummaryList.reduce(
        (acc, curr) => acc + curr.committedQty,
        0
      ),
    [item]
  );

  const saveHandler = useCallback(
    (saveAndCloseStatus: boolean) => {
      if (committedQty > 0 && trackingTabName === ItemTab.Tracking) {
        showNotification(
          'Adding tracking type is not allowed with inventory already committed.  Please  resolve the inventory commitment and then try again',
          {
            variant: 'error',
          }
        );
      } else {
        setSaveOnlyClicked(!saveAndCloseStatus);
        onSave(saveAndCloseStatus);
      }
    },
    [committedQty, trackingTabName, onSave]
  );

  const DeletedItemActionsRestyle = useCallback(() => {
    return (
      <>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          onClick={() => onUndeleteClicked(false)}
          permissions={
            item.id && item.id > 0
              ? [PermissionType.ItemsEdit]
              : [PermissionType.ItemsCreate]
          }
          data-qa="item-save"
        >
          Undelete
        </FBOButton>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          onClick={() => onUndeleteClicked(true)}
          permissions={
            item.id && item.id > 0
              ? [PermissionType.ItemsEdit]
              : [PermissionType.ItemsCreate]
          }
          data-qa="item-save-and-close"
        >
          Undelete And Close
        </FBOButton>
      </>
    );
  }, [item.id, onUndeleteClicked]);

  const ItemActionsRestyle = useCallback(() => {
    return (
      <>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          permissions={
            item.id && item.id > 0
              ? [PermissionType.ItemsEdit]
              : [PermissionType.ItemsCreate]
          }
          data-qa="item-save"
          onClick={() => saveHandler(false)}
        >
          Save
        </FBOButton>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          onClick={() => saveHandler(true)}
          permissions={
            item.id && item.id > 0
              ? [PermissionType.ItemsEdit]
              : [PermissionType.ItemsCreate]
          }
          data-qa="item-save-and-close"
        >
          Save And Close
        </FBOButton>
        <FBOMenuButton
          sx={{ marginRight: '8px' }}
          variant="tertiary"
          size="medium"
          items={createActionBarOptions(
            item.id,
            onDelete,
            onDuplicate,
            onOpenReport
          )}
          data-qa="item-three-dot-menu"
        />
      </>
    );
  }, [item.id, onDelete, onDuplicate, onOpenReport, saveHandler]);

  return (
    <FBOTitleBar title={`${item.name || 'New Item'}`}>
      {item.deleted ? <DeletedItemActionsRestyle /> : <ItemActionsRestyle />}
      <FBOButton
        variant="tertiary"
        color="neutral"
        size="medium"
        icon="FBOClose"
        data-qa="item-close"
        onClick={onClose}
      ></FBOButton>
    </FBOTitleBar>
  );
};

export default memo(ItemTitleBar);
