import React, { memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { ModuleNavigationItemStatus } from 'services/moduleNavigation';
import { ItemType } from 'services/items';
import { Routes as MaterialsRoutes } from 'ui/modules/materials';

import { MaterialsNavigationProps } from './types';
import { BreadCrumbLocation } from '../BreadCrumb/types';
import { Icon } from 'ui/components/Icon';
import { IconNames } from 'ui/theme';
import FBOBreadCrumb from '../BreadCrumb/FBOBreadCrumb';
import { concatRoute } from 'helpers/routes';

const FBOMaterialsNavigation: React.FC<MaterialsNavigationProps> = (props) => {
  const { items } = props;

  const { itemId, itemType = ItemType.Inventory } = items;

  const classes = {
    icon: 'redesign',
    skeleton: 'redesign',
    navigation: 'redesign navigation',
    navigationContainer: 'redesign navigation-container',
    returnNavigation: 'redesign return-navigation',
    returnItem: 'redesign',
    returnIcon: 'redesign',
  };
  const location = useLocation();
  const navigate = useNavigate();

  const onItemsPage = location.pathname.includes(MaterialsRoutes.ItemsPage);
  const onInventoryPage = location.pathname.includes(
    MaterialsRoutes.TrackingPage
  );

  const itemsStatus = !itemId
    ? ModuleNavigationItemStatus.Undefined
    : onItemsPage
    ? ModuleNavigationItemStatus.Active
    : ModuleNavigationItemStatus.InActive;

  const inventoryStatus =
    !itemId || itemType !== ItemType.Inventory
      ? ModuleNavigationItemStatus.Undefined
      : onInventoryPage
      ? ModuleNavigationItemStatus.Active
      : ModuleNavigationItemStatus.InActive;

  const itemsRoute = `${concatRoute(
    MaterialsRoutes.MaterialsRouter,
    MaterialsRoutes.ItemsPage
  )}?activeId=${itemId}`;

  const inventoryRoute = `${concatRoute(
    MaterialsRoutes.MaterialsRouter,
    MaterialsRoutes.TrackingPage
  )}?activeId=${itemId}`;

  return (
    <Box className={classes.navigationContainer}>
      <Box className={classes.navigation}>
        <FBOBreadCrumb
          location={BreadCrumbLocation.Left}
          status={itemsStatus}
          zIndex={3}
          onClick={() =>
            navigate(itemsRoute, { state: { triggerRouteGuard: true } })
          }
          data-qa="spacial-navigation-items"
          disabled={itemsStatus === ModuleNavigationItemStatus.Undefined}
          disableRipple
          disableTouchRipple
        >
          <Typography variant="body2">Items</Typography>
        </FBOBreadCrumb>
        <Icon name={IconNames.FBOCaratRightSmall} />
        <FBOBreadCrumb
          location={BreadCrumbLocation.Right}
          status={inventoryStatus}
          zIndex={1}
          onClick={() =>
            navigate(inventoryRoute, {
              state: { triggerRouteGuard: true },
            })
          }
          data-qa="spacial-navigation-inventory"
          disabled={inventoryStatus === ModuleNavigationItemStatus.Undefined}
          disableRipple
          disableTouchRipple
        >
          <Typography variant="body2">Inventory</Typography>
        </FBOBreadCrumb>
      </Box>
    </Box>
  );
};

export default memo(FBOMaterialsNavigation);
