import { createApiCall } from 'services/api';

import {
  transformSalesOrderSettings,
  transformToSoSettingsPut,
} from './transformations';
import { SalesOrderSettings } from './types';

export const getSOSettings = async () => {
  const soSettingsResponse = await createApiCall({
    path: `/v1/sales_order_settings`,
    method: 'GET',
  })();

  return transformSalesOrderSettings(soSettingsResponse.data);
};

export const putSOSettings = async (soSettings: SalesOrderSettings) => {
  const soSettingsResponse = await createApiCall(
    {
      path: `/v1/sales_order_settings?expand=salesTax`,
      method: 'PUT',
      body: transformToSoSettingsPut(soSettings),
    },
    {
      getMessage: {
        success: () => 'Updated sales order settings',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformSalesOrderSettings(soSettingsResponse.data);
};
