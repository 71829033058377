import React, { memo, useCallback, useMemo } from 'react';
import { TableCell, Typography, Link } from '@mui/material';
import _ from 'lodash';

import { Column } from 'ui/components/Table/ItemsTable/types';
import { TrackingType, TrackingDataTypes } from 'services/settings/tracking';
import { PermissionType } from 'services/permissions';
import { TextField } from 'ui/components/TextField/TextField';
import { DatePickerWrapper } from 'ui/components/TextField/DatePickerWrapper';

import { TrackingColumnsProps } from '../../types';
import { getTrackingTypeColumns } from '../../helpers';

const TrackingEditColumns: React.FC<TrackingColumnsProps> = (props) => {
  const {
    trackingGroup,
    onSerialLinkClick,
    createLotChangeHandler,
    createDateChangeHandler,
    columns,
    inventoryUom,
  } = props;

  const handleLinkClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      onSerialLinkClick(e);
    },
    [onSerialLinkClick]
  );

  const trackingTypeColumns = useMemo(
    () => getTrackingTypeColumns(columns),
    [columns]
  );

  const renderTrackingColumn = (column: Column): JSX.Element | null => {
    const trackingDataType: TrackingDataTypes = _.get(
      column,
      'meta.trackingDataType'
    );
    const trackingType: TrackingType = _.get(column, 'meta.trackingType', {});
    const trackingTypeIndex = trackingGroup.trackingInfoList.findIndex(
      (t) => t.trackingTypeId === trackingType.id
    );
    const trackingInfo = trackingGroup.trackingInfoList[trackingTypeIndex];

    switch (trackingDataType) {
      case TrackingDataTypes.Text:
        return (
          <TextField
            onChange={createLotChangeHandler(trackingTypeIndex)}
            value={trackingInfo.value}
            permissions={[PermissionType.InventoryEdit]}
          />
        );
      case TrackingDataTypes.Date:
        return (
          <DatePickerWrapper
            onChange={createDateChangeHandler(trackingTypeIndex)}
            value={trackingInfo.value}
            fullWidth
            permissions={[PermissionType.InventoryEdit]}
          />
        );
      case TrackingDataTypes.SerialNumber:
        return (
          <Link href="#" underline="always" onClick={handleLinkClick}>
            View Serials
          </Link>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <TableCell>
        <Typography>
          {trackingGroup.onHand - trackingGroup.committedQuantity}
          {` ${inventoryUom ? inventoryUom.abbreviation : ''}`}
        </Typography>
      </TableCell>
      {trackingTypeColumns.map((column: Column, index: number) => (
        <TableCell key={index}>{renderTrackingColumn(column)}</TableCell>
      ))}
    </>
  );
};

export default memo(TrackingEditColumns);
