import React from 'react';
import moment from 'moment';
import { TableCell, Typography } from '@mui/material';

import { useGetIntlDateFormatString } from 'helpers';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';

import { ExportHistory } from 'services/accounting';
import { useCardStyle } from '../../styled';

const CustomDetailsRow: React.FC<RenderCustomRowProps<ExportHistory>> = (
  props
) => {
  const { row } = props;
  const intlDateFormat = useGetIntlDateFormatString();
  const classes = useCardStyle();
  return (
    <>
      <TableCell data-qa={`Date-${row.dateCreated}`}>
        {row.dateCreated ? moment(row.dateCreated).format(intlDateFormat) : ''}
      </TableCell>
      <TableCell data-qa={`Time-${row.dateCreated}`}>
        {row.dateCreated?.toLocaleTimeString()}
      </TableCell>
      <TableCell data-qa={`Memo-${row.type}`} className={classes.ellipsis}>
        {row.ledger?.note}
      </TableCell>
      <TableCell data-qa={`Memo-${row.type}`} className={classes.ellipsis}>
        {row.ledger?.type}
      </TableCell>

      <TableCell data-qa={`Memo-${row.type}`} className={classes.ellipsis}>
        {row.type}
      </TableCell>

      <TableCell data-qa={`Details-${row.id}`}>
        <Typography className={classes.ellipsis}>{row.message}</Typography>
      </TableCell>
    </>
  );
};

export default React.memo(CustomDetailsRow);
