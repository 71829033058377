import React, { memo, useEffect, useState, useCallback } from 'react';

import moment from 'moment';
import {
  Box,
  IconButton,
  InputAdornment,
  Link,
  Typography,
  Modal,
} from '@mui/material';
import {
  DateRange,
  DateRangePicker as MUIDateRangePicker,
} from 'materialui-daterange-picker';

import { TextField } from 'ui/components/TextField/TextField';

import { DateRangePickerProps } from './types';
import { useDateRangePickerStyle } from './styled';
import { useGetIntlDateFormatString } from 'helpers';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { IconNames, colorPalette } from 'ui/theme';
import { Icon } from 'ui/components/Icon';

const DateRangePicker = (props: DateRangePickerProps) => {
  const {
    value,
    rangeDays,
    definedRanges,
    closeOnClickOutside = false,
    onChange,
    applyLabel = 'Save',
    ...otherProps
  } = props;

  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange>();
  const [showError, setShowError] = useState(false);

  const classes = useDateRangePickerStyle(props);

  const format = useGetIntlDateFormatString();

  const isOpenClass = open ? classes.modalOpen : classes.modalClosed;

  const dateRangeStart =
    value && value.startDate ? moment(value.startDate).format(format) : null;

  const dateRangeEnd =
    value && value.endDate ? moment(value.endDate).format(format) : null;

  const combinedDate =
    dateRangeStart && dateRangeEnd
      ? `${dateRangeStart} > ${dateRangeEnd}`
      : null;

  useEffect(() => {
    setDateRange(value || undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggle = () => setOpen(!open);

  const renderAdornment = () => {
    return (
      <InputAdornment position="end">
        <IconButton
          onClick={() => {
            setOpen(true);
          }}
          edge="end"
          disabled={otherProps.readOnly || otherProps.disabled}
          size="large"
        >
          <Link className={classes.link} color="textPrimary" underline="hover">
            <Icon
              name={IconNames.Calendar}
              sx={{
                color: `${colorPalette.redesign.contentPrimary}`,
              }}
            />
          </Link>
        </IconButton>
      </InputAdornment>
    );
  };
  const handleSaveClicked = () => {
    if (dateRange && rangeDays) {
      const fromMoment = moment(dateRange.startDate);
      const toMoment = moment(dateRange.endDate);
      const diff = Math.abs(fromMoment.diff(toMoment, 'days'));
      if (diff !== rangeDays - 1) {
        setShowError(true);
        return;
      }
    }

    onChange(dateRange!);
    setOpen(false);
    setShowError(false);
  };

  const handleCancelClicked = () => {
    setOpen(false);
  };

  const handleOnChange = useCallback(
    (dr: DateRange) => {
      setDateRange(dr);
    },
    [setDateRange]
  );

  return (
    <Box className={classes.box}>
      <TextField
        variant={'standard'}
        onClick={otherProps.disabled ? undefined : () => setOpen(true)}
        additionalInputProps={{
          endAdornment: renderAdornment(),
          classes: { input: classes.textfield },
        }}
        value={combinedDate}
        {...otherProps}
        className={
          otherProps.className ? `${otherProps.className} redesign` : 'redesign'
        }
      />
      <Modal open={open} onClose={handleCancelClicked}>
        <Box className={`${classes.datePickerWrapper} ${isOpenClass}`}>
          <Box className={classes.datePickerContent}>
            <MUIDateRangePicker
              open={open}
              toggle={toggle}
              onChange={handleOnChange}
              wrapperClassName={classes.datePicker}
              initialDateRange={dateRange}
              definedRanges={definedRanges}
              closeOnClickOutside={closeOnClickOutside}
            />
            <Box className={`${classes.datePickerFooter} footer`} boxShadow={3}>
              <Box display="flex" alignItems="center" pl={1}>
                {showError && (
                  <Typography color="error">{`You have to select ${rangeDays} days`}</Typography>
                )}
              </Box>
              <Box ml="auto" display="flex">
                <FBOButton
                  variant="secondary"
                  color="positive"
                  size="medium"
                  onClick={handleCancelClicked}
                  data-qa="date-range-picker-cancel-button"
                >
                  Cancel
                </FBOButton>
                <FBOButton
                  variant="primary"
                  color="positive"
                  size="medium"
                  onClick={handleSaveClicked}
                  disabled={!dateRange}
                  data-qa="date-range-picker-save-button"
                  sx={{ marginLeft: '8px' }}
                >
                  {applyLabel}
                </FBOButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default memo(DateRangePicker);
