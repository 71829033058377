import * as yup from 'yup';

export const yupGlobalSettings = yup.object().shape({
  inventoryExport: yup.object().shape({
    quantityType: yup
      .string()
      .required()
      .typeError('Quantity Type is required'),
    inventoryWarehousesEnabled: yup
      .array()
      .of(yup.number())
      .required()
      .typeError('A minimum of one location is required'),
  }),
  productExport: yup.object().shape({
    limitTags: yup.array().of(yup.number()).nullable(),
    longDescription: yup.string().nullable(),
    masterSku: yup.string().required().typeError('Master SKU is required'),
    productTitle: yup
      .string()
      .required()
      .typeError('Product Title is required'),
    shortDescription: yup.string().nullable(),
    upc: yup.string().nullable(),
  }),
});
