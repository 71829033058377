import React, { useCallback, memo, useMemo, useEffect, useState } from 'react';
import { Box, Typography, Dialog } from '@mui/material';
import _ from 'lodash';
import { Timezone } from 'timezones.json';
import { TIMEZONES } from 'helpers/timezones';
import moment from 'moment';

import { toBase64, isValidUrl } from 'helpers';
import { TextField } from 'ui/components/TextField/TextField';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import {
  COUNTRIES,
  CountryAutocomplete,
  CountryType,
} from 'ui/components/Autocomplete/CountryAutocomplete';
import { getTimezone } from 'ui/modules/setup/services';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import { Location } from 'services/locations';

import {
  AutocompleteDescriptor,
  BooleanDescriptor,
  CountryAutocompleteDescriptor,
  HyperTextDescriptor,
  ImageDescriptor,
  LocationPickerDescriptor,
  RowDescriptor,
  RowType,
  TextDescriptor,
  TimezoneAutocompleteDescriptor,
  AccountMappingDescriptor,
  GoLiveToggleDescriptor,
} from '../../types';
import { ModalRowProps } from './types';
import { useCardRowStyle } from './styled';
import { isValidSSN } from './helpers';
import { showNotification } from 'services/api';
import { IconNames } from 'ui/theme';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOSwitch from 'ui/theme/components/FBOSwitch/FBOSwitch';
import { Icon } from 'ui/components/Icon';

type DescriptorType = TextDescriptor | HyperTextDescriptor;
type TextRowType = DescriptorType & ModalRowProps;

const ImageRow: React.FC<ImageDescriptor & ModalRowProps> = (props) => {
  const classes = useCardRowStyle(props);
  const { title, cardData, field, setCardData } = props;

  const value = _.get(cardData, field || '', '');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    toBase64(e.target.files![0]).then((base64) => {
      if (field) {
        setCardData(field, base64 as string);
      }
    });
  };

  const handleDeleteClicked = useCallback(() => {
    if (field) {
      setCardData(field, null);
    }
  }, [field, setCardData]);

  return (
    <Box className={classes.imgRow}>
      <Box className={classes.title}>
        <Typography>{title}</Typography>
      </Box>
      {value ? (
        <>
          <Box className={classes.textContent}>
            <img className={classes.logo} alt="Logo" src={value} />

            <FBOButton
              variant="tertiary"
              color="negative"
              size="medium"
              icon={IconNames.TrashCan}
              onClick={handleDeleteClicked}
              data-qa="image-delete-button"
            />
          </Box>
        </>
      ) : (
        <>
          <Box>
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              onChange={handleInputChange}
              type="file"
            />
            <label htmlFor="contained-button-file">
              <FBOButton
                variant="secondary"
                color="neutral"
                size="medium"
                icon={IconNames.DefaultCompanyLogo}
                component="span"
                data-qa="image-upload-button"
              >
                Add Image
              </FBOButton>
            </label>
          </Box>
        </>
      )}
    </Box>
  );
};

const TextRow: React.FC<TextRowType> = (props) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [hasError, setHasError] = useState(false);

  const classes = useCardRowStyle(props);
  const { field, title, cardData, textbox, setCardData, dataQa } = props;

  const value = _.get(cardData, field || '', '');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    if (field) {
      if (field === 'url' && newValue) {
        if (isValidUrl(newValue)) {
          setHasError(false);
          setCardData(field, newValue);
        } else {
          setHasError(true);
        }
        return;
      }

      if (field === 'taxExemptNumber' && newValue) {
        if (!isValidSSN(newValue)) {
          setHasError(false);
          setCardData(field, newValue);
        } else {
          setHasError(true);
          showNotification(
            'Invalid Tax Number Format - Social Security Number not allowed',
            { variant: 'error' }
          );
          setCardData(field, null);
        }
        return;
      }

      setCardData(field, newValue || null);
    }
  };

  const handleMultilineKeydown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.keyCode === 13) {
      e.stopPropagation();
    }
  };

  const handleOpenOnClick = () => {
    setOpenDialog(true);
  };

  const handleCloseClick = () => {
    setOpenDialog(false);
  };

  const resolvedTextField = textbox ? (
    <>
      <TextField
        {...props}
        className="redesign"
        variant="standard"
        onClick={handleOpenOnClick}
        onChange={handleChange}
        multiline
        rows={4}
        value={value}
      />
      <Dialog open={!!openDialog} onClose={handleCloseClick}>
        <TextField
          {...props}
          className="redesign"
          variant="standard"
          value={value}
          onChange={handleChange}
          onKeyDown={handleMultilineKeydown}
          multiline
          rows={13}
          style={{ width: 420 }}
          dataQa={dataQa}
        />
      </Dialog>
    </>
  ) : (
    <TextField
      {...props}
      className="redesign"
      variant="standard"
      error={hasError}
      value={value}
      onChange={handleChange}
      dataQa={dataQa}
    />
  );

  return (
    <Box className={classes.textRow}>
      <Box className={classes.textContent}>
        <Box className={classes.title}>
          <Typography>{title}</Typography>
        </Box>
        <Box className={classes.content}>{resolvedTextField}</Box>
      </Box>
    </Box>
  );
};

const BooleanRow: React.FC<BooleanDescriptor & ModalRowProps> = (props) => {
  const classes = useCardRowStyle(props);
  const {
    field,
    title,
    disabled,
    subBooleanRows,
    subAutocompleteRows,
    cardData,
    setCardData,
    dataQa,
  } = props;

  const value = _.get(cardData, field || '', true);

  useEffect(() => {
    if (disabled && field) {
      setCardData(field, false);
    }
  }, [disabled, setCardData, field]);

  const handleSwitchChange = useCallback(() => {
    if (field) {
      setCardData(field, !value);
    }
  }, [field, setCardData, value]);

  return (
    <React.Fragment>
      <Box className={classes.row}>
        <Box className={classes.title}>
          <Typography>{title}</Typography>
        </Box>
        <Box className={classes.contentRestyle}>
          {field && (
            <FBOSwitch
              name="setting-row-toggle"
              size="medium"
              checked={!!value}
              onChange={handleSwitchChange}
              disabled={disabled}
              dataQa={dataQa || 'setting-row-toggle'}
            />
          )}
        </Box>
      </Box>
      {!!value && (
        <>
          {subBooleanRows &&
            subBooleanRows.map((subRowProps, index) => (
              <Box pl={2} key={`${index}`}>
                <BooleanRow
                  dataQa={dataQa}
                  {...subRowProps}
                  disabled={!value}
                  setCardData={setCardData}
                  cardData={cardData}
                />
              </Box>
            ))}
          {subAutocompleteRows &&
            subAutocompleteRows.map((subRowProps, index) => (
              <Box pl={2} key={`${index}`}>
                <AutocompleteRow
                  {...subRowProps}
                  setCardData={setCardData}
                  cardData={cardData}
                />
              </Box>
            ))}
        </>
      )}
    </React.Fragment>
  );
};

const GoLiveToggleRow: React.FC<GoLiveToggleDescriptor & ModalRowProps> = (
  props
) => {
  const classes = useCardRowStyle(props);
  const { field, title, cardData, setCardData, dataQa } = props;
  const [modalVisible, setModalVisible] = useState(false);
  const value = _.get(cardData, field || '', null);

  const handleSwitchChange = useCallback(() => {
    setModalVisible(true);
  }, []);

  const handleConfirm = useCallback(() => {
    if (field) {
      setCardData(field, moment.now());
      setModalVisible(false);
    }
  }, [field, setCardData]);

  return (
    <React.Fragment>
      <Box className={classes.row}>
        <Box className={classes.title}>
          <Typography>{title}</Typography>
        </Box>
        <Box className={classes.contentRestyle}>
          {field && !value && (
            <FBOSwitch
              name="go-live-toggle"
              size="medium"
              checked={!!value}
              onChange={handleSwitchChange}
              dataQa={dataQa || 'go-live-toggle'}
            />
          )}
        </Box>
      </Box>
      <ConfirmationModal
        open={modalVisible}
        title="Go Live"
        body="Once your account is live, you will no longer have the ability to change your costing method, would you like to continue?"
        onCancelClicked={() => setModalVisible(false)}
        onConfirmClicked={handleConfirm}
        confirmLabel={'Continue'}
        cancelLabel={'Cancel'}
      />
    </React.Fragment>
  );
};

const AccountMappingRow: React.FC<AccountMappingDescriptor & ModalRowProps> = (
  props
) => {
  const classes = useCardRowStyle(props);
  const { field, title, accountType, cardData, options, setCardData } = props;

  const filteredOptions = useMemo(
    () => options.filter((i) => i.accountType === accountType),
    [options, accountType]
  );

  const selectedAccount = useMemo(() => {
    const value = _.get(cardData, field || '', '');
    return options.find((a) => a.id === value);
  }, [options, cardData, field]);

  const onChange = useCallback(
    (e: any, autocompleteValue: any) => {
      if (field) {
        setCardData(field, autocompleteValue ? autocompleteValue.id : null);
      }
    },
    [field, setCardData]
  );

  return (
    <Box className={classes.textRow}>
      <Box className={classes.textContent}>
        <Box width={'30%'}>
          <Typography className={classes.accountingTitle}>{title}</Typography>
        </Box>
        <Box
          px={4}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Icon name={IconNames.FBOCaratRightSmall} />
        </Box>
        <Box className={classes.content}>
          <Autocomplete
            onChange={onChange}
            required
            options={filteredOptions}
            getOptionLabel={(option) => option.name}
            value={selectedAccount ? selectedAccount : null}
            placeholder="Not mapped"
            data-qa={`account-mapping-${title}`}
          />
        </Box>
      </Box>
    </Box>
  );
};

const AutocompleteRow: React.FC<AutocompleteDescriptor & ModalRowProps> = (
  props
) => {
  const classes = useCardRowStyle(props);
  const {
    field,
    title,
    cardData,
    setCardData,
    options,
    isFieldDisabled,
    getOptionsLabel,
    isOptionEqualToValue,
    handleAutocompleteChange,
    dataQa,
  } = props;

  const autocompleteValue = useMemo(() => {
    if (!field) {
      return '';
    }
    return _.get(cardData, field);
  }, [cardData, field]);

  const onChange = useCallback(
    (e: any, value: any) => {
      if (!field) {
        return;
      }
      if (handleAutocompleteChange) {
        handleAutocompleteChange(e, value, field, setCardData);
        return;
      }
      setCardData(field, value);
    },
    [field, setCardData, handleAutocompleteChange]
  );

  return (
    <Box className={classes.textRow}>
      <Box className={classes.textContent}>
        <Box className={classes.title}>
          <Typography>{title}</Typography>
        </Box>
        <Box className={classes.content}>
          <Autocomplete
            onChange={onChange}
            required
            options={options}
            getOptionLabel={getOptionsLabel}
            disabled={isFieldDisabled ? isFieldDisabled(cardData) : false}
            isOptionEqualToValue={isOptionEqualToValue}
            value={autocompleteValue}
            placeholder="Input"
            dataQa={dataQa}
          />
        </Box>
      </Box>
    </Box>
  );
};

const CountryAutocompleteRow: React.FC<
  CountryAutocompleteDescriptor & ModalRowProps
> = (props) => {
  const classes = useCardRowStyle(props);
  const { field, title, cardData, setCardData, disabled } = props;

  const autocompleteValue = _.get(cardData, field || '', '');

  const onChange = useCallback(
    (e: React.ChangeEvent<{}>, value: CountryType) => {
      if (!field) {
        return;
      }
      setCardData(field, value ? value.code : null);
    },
    [setCardData, field]
  );
  const activeCountry = useMemo(() => {
    return COUNTRIES.find((c) => c.code === autocompleteValue) || null;
  }, [autocompleteValue]);

  return (
    <Box className={classes.textRow}>
      <Box className={classes.textContent}>
        <Box className={classes.title}>
          <Typography>{title}</Typography>
        </Box>
        <Box className={classes.content}>
          <CountryAutocomplete
            value={activeCountry}
            onChange={onChange}
            label=""
            disabled={disabled}
            required
          />
        </Box>
      </Box>
    </Box>
  );
};

const LocationPickerRow: React.FC<LocationPickerDescriptor & ModalRowProps> = (
  props
) => {
  const classes = useCardRowStyle(props);
  const { field, title, cardData, setCardData, dataQa } = props;

  const autocompleteValue = useMemo(() => {
    if (!field) {
      return '';
    }
    return _.get(cardData, field + '.id');
  }, [cardData, field]);

  const onChange = useCallback(
    (value: Location | null) => {
      if (!field) {
        return;
      }
      setCardData('defaultLocation', value);
      setCardData('defaultLocationId', value ? value.id : null);
    },
    [setCardData, field]
  );

  return (
    <Box className={classes.textRow}>
      <Box className={classes.textContent}>
        <Box className={classes.title}>
          <Typography>{title}</Typography>
        </Box>
        <Box className={classes.content}>
          <LocationsAsyncAutocomplete
            required
            onChange={onChange}
            value={autocompleteValue}
            companyWide={false}
            parentId={null}
            dataQa={dataQa}
          />
        </Box>
      </Box>
    </Box>
  );
};

const TimezoneAutocompleteRow: React.FC<
  TimezoneAutocompleteDescriptor & ModalRowProps
> = (props) => {
  const classes = useCardRowStyle(props);
  const { field, title, cardData, setCardData, dataQa } = props;

  const autocompleteValue = useMemo(() => {
    if (!field) {
      return '';
    }
    const value = _.get(cardData, field);
    return getTimezone(value);
  }, [cardData, field]);

  const onChange = useCallback(
    (e: React.ChangeEvent<{}>, value: Timezone) => {
      if (!field) {
        return;
      }
      setCardData(field, value ? value.utc[0] : null);
    },
    [setCardData, field]
  );

  return (
    <Box className={classes.textRow}>
      <Box className={classes.textContent}>
        <Box className={classes.title}>
          <Typography>{title}</Typography>
        </Box>
        <Box className={classes.content}>
          <Autocomplete
            onChange={onChange}
            required
            options={TIMEZONES}
            getOptionLabel={(option: Timezone) => option.text || ''}
            isOptionEqualToValue={(option: Timezone, value: Timezone) =>
              option.utc[0] === value.utc[0]
            }
            value={autocompleteValue}
            placeholder="Input"
            dataQa={dataQa}
          />
        </Box>
      </Box>
    </Box>
  );
};

const ModalRow: React.FC<RowDescriptor & ModalRowProps> = (props) => {
  switch (props.type) {
    case RowType.Boolean:
      return <BooleanRow {...props} />;
    case RowType.Text:
    case RowType.HyperText:
      return <TextRow {...props} />;
    case RowType.Image:
      return <ImageRow {...props} />;
    case RowType.Autocomplete:
      return <AutocompleteRow {...props} />;
    case RowType.CountryAutocomplete:
      return <CountryAutocompleteRow {...props} />;
    case RowType.LocationPicker:
      return <LocationPickerRow {...props} />;
    case RowType.TimezoneAutocomplete:
      return <TimezoneAutocompleteRow {...props} />;
    case RowType.AccountMapping:
      return <AccountMappingRow {...props} />;
    case RowType.GoLiveToggle:
      return <GoLiveToggleRow {...props} />;
  }
};

export default memo(ModalRow);
