import { makeStyles } from '@mui/styles';

export const useSettingsPageStyle = makeStyles({
  cardContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
});
