import { useCallback } from 'react';

import { CustomField } from './types';

export const useCustomFields = <T>(
  setFunction: React.Dispatch<
    React.SetStateAction<T & { customFields: CustomField[] }>
  >
) =>
  useCallback(
    (customFields: React.SetStateAction<CustomField[]>) => {
      if (typeof customFields === 'function') {
        setFunction((old) => ({
          ...old,
          customFields: customFields(old.customFields),
        }));
        return;
      }

      setFunction((old) => ({
        ...old,
        customFields,
      }));
    },
    [setFunction]
  );
