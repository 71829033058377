import {
  createApiCall,
  DataWithPagination,
  paginatedApiCall,
} from 'services/api';
import { expandURL } from 'services/url';
import { RequestConfig, defaultMaximumPagination } from 'helpers';

import { Pick, PickItem, PickItemQuantities } from './types';
import {
  transformPick,
  transformPickItemQuantities,
  transformToCommitPickItem,
  transformToUpdatePick,
  transformToUpdatePickItem,
} from './transformations';

const pickExpandArray = [
  'pickItems.salesOrderItem.saleItem.images',
  'pickItems.pickFromLocation',
  'pickItems.pickToLocation',
  'pickItems.item.images',
  'pickItems.item.itemTrackingTypes.trackingType',
  'pickItems.salesOrderItem.saleItem',
  'pickItems.item.tags',
  'pickItems.salesOrderItem.salesOrder.representative',
  'pickItems.salesOrderItem.salesOrder.customer',
  'pickItems.salesOrderItem.salesOrder.paymentTerm',
  'pickBundleItems.pickItems.salesOrderItem.saleItem.images',
  'pickBundleItems.pickItems.pickFromLocation',
  'pickBundleItems.pickItems.item.images',
  'pickBundleItems.pickItems.item.defaultUom',
  'pickBundleItems.pickItems.item.tags',
  'pickBundleItems.pickItems.item.itemTrackingTypes.trackingType',
  'pickBundleItems.pickItems.salesOrderItem.saleItem',
  'pickBundleItems.pickItems.salesOrderItem.salesOrder.representative',
  'pickBundleItems.pickItems.salesOrderItem.salesOrder.customer',
  'pickBundleItems.pickItems.salesOrderItem.salesOrder.paymentTerm',
];
const pickExpands = pickExpandArray.join(',');

// FF CU-863hakwb6
const optimizeExpandsFeatureFlagHelper = (
  apiUrl: string,
  expands: string,
  featureFlagEnabled: boolean
): string => {
  if (featureFlagEnabled) return apiUrl;
  return expandURL(apiUrl, expands);
};

export const fetchPicksAPI = async (
  config: RequestConfig
): Promise<DataWithPagination<Pick>> => {
  const {
    pagination = defaultMaximumPagination,
    expands = [],
    customQuickSearchColumns = [],
    quickSearchValue,
  } = config;

  let path = `/v1/picks?expand=${expands.join(',')}`;
  if (quickSearchValue) {
    path += `&quickSearchColumns=${customQuickSearchColumns}&quickSearchValue=${encodeURIComponent(
      quickSearchValue
    )}`;
  }

  const res = await paginatedApiCall(path, pagination, transformPick);
  return res;
};

export const getPick = async (pickId: number): Promise<Pick> => {
  const pick = await createApiCall({
    path: expandURL(`/v1/picks/${pickId}`, pickExpands),
    method: 'GET',
  })();
  return transformPick(pick.data);
};

export const updatePick = async (ship: Pick): Promise<any> =>
  createApiCall(
    {
      path: '/v1/picks',
      method: 'PUT',
      body: transformToUpdatePick(ship),
    },
    {
      getMessage: {
        success: () => 'Pick updated',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

export const postPickCommit = async (
  pickId: number,
  pickItems: PickItem[],
  expandsFeatureFlagEnabled = false
) => {
  const res = await createApiCall(
    {
      path: optimizeExpandsFeatureFlagHelper(
        `/v1/picks/${pickId}/commit`,
        pickExpands,
        expandsFeatureFlagEnabled
      ),
      method: 'POST',
      body: pickItems.map(transformToCommitPickItem),
    },
    {
      getMessage: {
        success: () => 'Pick items committed',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformPick(res.data);
};

export const postPickFinish = async (
  pickId: number,
  pickItems: PickItem[],
  expandsFeatureFlagEnabled = false
) => {
  const res = await createApiCall(
    {
      path: optimizeExpandsFeatureFlagHelper(
        `/v1/picks/${pickId}/finish`,
        pickExpands,
        expandsFeatureFlagEnabled
      ),
      method: 'POST',
      body: pickItems.map(transformToCommitPickItem),
    },
    {
      getMessage: {
        success: () => 'Pick items finished',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformPick(res.data);
};

export const postPickVoid = (
  pickId: number,
  expandsFeatureFlagEnabled = false
) =>
  createApiCall(
    {
      path: optimizeExpandsFeatureFlagHelper(
        `/v1/picks/${pickId}/void`,
        pickExpands,
        expandsFeatureFlagEnabled
      ),
      method: 'POST',
    },
    {
      getMessage: {
        success: () => 'Pick voided',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

export const postPicksVoid = async (ids: number[]): Promise<void> => {
  await createApiCall(
    {
      path: `/v1/picks/void`,
      method: 'POST',
      body: ids,
    },
    {
      getMessage: {
        success: (response: any) => {
          return response.data;
        },
        error: (error: any) => error.response.data.message,
      },
    }
  )();
};

export const postPickStart = (
  pickId: number,
  expandsFeatureFlagEnabled = false
) =>
  createApiCall(
    {
      path: optimizeExpandsFeatureFlagHelper(
        `/v1/picks/${pickId}/start`,
        pickExpands,
        expandsFeatureFlagEnabled
      ),
      method: 'POST',
    },
    {
      getMessage: {
        success: () => 'Pick started',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

export const updatePickItem = async (
  pickId: number,
  pickItem: PickItem,
  expandsFeatureFlagEnabled = false
) => {
  const res = await createApiCall(
    {
      path: optimizeExpandsFeatureFlagHelper(
        `/v1/picks/${pickId}/pick_items`,
        pickExpands,
        expandsFeatureFlagEnabled
      ),
      method: 'PUT',
      body: [transformToUpdatePickItem(pickItem)],
    },
    {
      getMessage: {
        success: () => 'Pick item updated',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformPick(res.data);
};

export const postPickItemStart = (pickId: number, pickItemId: number) =>
  createApiCall(
    {
      path: expandURL(
        `/v1/picks/${pickId}/pick_items/${pickItemId}/start`,
        pickExpands
      ),
      method: 'POST',
    },
    {
      getMessage: {
        success: () => 'Pick item started',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

export const postPickItemCommit = async (
  pickId: number,
  pickItem: PickItem
) => {
  const res = await createApiCall(
    {
      path: expandURL(
        `/v1/picks/${pickId}/pick_items/${pickItem.id}/commit`,
        pickExpands
      ),
      method: 'POST',
      body: transformToCommitPickItem(pickItem),
    },
    {
      getMessage: {
        success: () => 'Pick item committed',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformPick(res.data);
};

export const postPickItemHold = (pickId: number, pickItemId: number) =>
  createApiCall(
    {
      path: expandURL(
        `/v1/picks/${pickId}/pick_items/${pickItemId}/hold`,
        pickExpands
      ),
      method: 'POST',
    },
    {
      getMessage: {
        success: () => 'Pick item on hold',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

export const postPickItemVoid = (
  pickId: number,
  pickItemId: number,
  successMsg: string
) =>
  createApiCall(
    {
      path: expandURL(
        `/v1/picks/${pickId}/pick_items/${pickItemId}/void`,
        pickExpands
      ),
      method: 'POST',
    },
    {
      getMessage: {
        success: () => `Pick item ${successMsg}`,
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

export const postPickItemFinish = async (
  pickId: number,
  pickItem: PickItem,
  expandsFeatureFlagEnabled = false
) => {
  const res = await createApiCall(
    {
      path: optimizeExpandsFeatureFlagHelper(
        `/v1/picks/${pickId}/pick_items/${pickItem.id}/finish`,
        pickExpands,
        expandsFeatureFlagEnabled
      ),
      method: 'POST',
      body: transformToCommitPickItem(pickItem),
    },
    {
      getMessage: {
        success: () => 'Pick item finished',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformPick(res.data);
};

export const groupPicks = async (pickIds: number[]) => {
  await createApiCall(
    {
      path: `/v1/picks/group`,
      method: 'POST',
      body: pickIds,
    },
    {
      getMessage: {
        success: () => 'Pick items grouped',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();
};

export const unGroupPick = async (pickId: number) => {
  await createApiCall(
    {
      path: `/v1/picks/${pickId}/ungroup`,
      method: 'POST',
    },
    {
      getMessage: {
        success: () => 'Pick item ungrouped',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();
};

export const getPickItemQuantities = async (
  ids: number[],
  rootLocationId: number
): Promise<PickItemQuantities[]> => {
  const response = await createApiCall({
    path: `/inventory-mgmt/v1/buckets?item.id=${ids.toString()}&location.rootLocationId=${rootLocationId}&group.by=location.rootLocationId`,
    method: 'GET',
  })();

  return response.data.results.map(transformPickItemQuantities);
};
