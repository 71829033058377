import React, { memo } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { GridContainerProps } from './types';

/**
 *
 * @param props
 * @return {JSX.Element | null}
 * @constructor
 */
const GridRow = (props: GridContainerProps): JSX.Element | null => {
  const { title, children, ...rest } = props;

  if (children === 'undefined') {
    return null;
  }

  return (
    <Grid container item direction="column" {...rest}>
      {title && (
        <Box mb={3}>
          <Typography>
            <strong>{title}</strong>
          </Typography>
        </Box>
      )}
      <Grid container item direction="row" spacing={2}>
        {children}
      </Grid>
    </Grid>
  );
};

export default memo(GridRow);
