import _ from 'lodash';

import { roundToDecimals, toMulticurrencyCalculate } from 'helpers';
import { ItemType } from 'services/items';
import { PurchaseOrderItemTypes } from 'services/purchaseOrders';
import {
  ReceiptItem,
  ReceiptItemStatus,
  Reconcile,
  ReconcileCalculateOptions,
  ReconcileExpense,
  ReconcilePrice,
  ReconcileReceiptItem,
  ReconcileSummary,
} from 'services/receiving';
import { Uom } from 'services/uoms';

export function calculateReconcilePricesManual(
  receiptItems: ReceiptItem[]
): ReconcilePrice {
  const prices: ReconcilePrice = {};
  receiptItems.forEach((i) => {
    prices[i.id!] = 0;
  });
  return prices;
}

export function calculateReconcilePricesByCost(
  reconcileReceiptItems: ReconcileReceiptItem[],
  selectedReceiptItemIds: number[],
  reconcileAmount: number
): { adjustment: number; prices: ReconcilePrice } {
  const selectedReceiptItems = reconcileReceiptItems.filter((i) =>
    selectedReceiptItemIds.includes(i.id!)
  );

  const selectedTotalCost = selectedReceiptItems.reduce((acc, i) => {
    return acc + (i.unitCost || 0) * (i.quantity || 1);
  }, 0);

  const prices: ReconcilePrice = {};
  let adjustment = 0;

  let reconcileSum = 0;
  selectedReceiptItems.forEach((i, index) => {
    if (selectedTotalCost === 0) {
      prices[i.id!] = reconcileAmount / reconcileReceiptItems.length;
      return;
    }

    const unitTotalCost = i.totalCost || 0;
    const unitReconcile = (unitTotalCost / selectedTotalCost) * reconcileAmount;
    prices[i.id!] = unitReconcile;
    reconcileSum += unitReconcile;

    // last item add remaining cost
    if (index === reconcileReceiptItems.length - 1) {
      adjustment = parseFloat((reconcileAmount - reconcileSum).toFixed(2));
      prices[i.id!] = unitReconcile + adjustment;
    }
  });

  // not selected items have reconcile prices of 0
  reconcileReceiptItems
    .filter((i) => !selectedReceiptItemIds.includes(i.id!))
    .forEach((i) => {
      prices[i.id!] = 0;
    });

  return { adjustment, prices };
}

export function calculateReconcilePricesByQuantity(
  receiptItems: ReconcileReceiptItem[],
  selectedReceiptItemIds: number[],
  reconcileAmount: number
) {
  const selectedReconcileItems = receiptItems.filter((i) =>
    selectedReceiptItemIds.includes(i.id!)
  );

  const selectedTotalQuantity = selectedReconcileItems.reduce(
    (acc, i) => acc + (i.quantity || 0),
    0
  );

  const prices: ReconcilePrice = {};

  let reconcileSum = 0;
  selectedReconcileItems.forEach((i, index) => {
    if (index < selectedReconcileItems.length - 1) {
      const unitReconcile = parseFloat(
        (((i.quantity || 0) / selectedTotalQuantity) * reconcileAmount).toFixed(
          6
        )
      );
      prices[i.id!] = unitReconcile;
      reconcileSum += unitReconcile;
      return;
    }

    // last item add remaining cost
    prices[i.id!] = parseFloat((reconcileAmount - reconcileSum).toFixed(6));
  });

  // not selected items have reconcile prices of 0
  receiptItems
    .filter((i) => !selectedReceiptItemIds.includes(i.id!))
    .forEach((i) => {
      prices[i.id!] = 0;
    });

  return prices;
}

/**
 * Finds all selected service and shipping item types and returns a list of reconcile expenses
 * @param receiptItems
 * @param selectedItems
 */
export function findReconcileExpenses(
  receiptReconcileItems: ReconcileReceiptItem[],
  selectedItems: number[]
) {
  const newReconcileExpenses: ReconcileExpense[] = [];

  receiptReconcileItems.forEach((r) => {
    const {
      itemType,
      itemId,
      name,
      description,
      quantity,
      totalCost,
      id,
      abbreviation,
      multiCurrencyTotalCost,
      currency,
    } = r;

    if (
      itemType &&
      selectedItems.includes(r.id!) &&
      (itemType === ItemType.Service ||
        itemType === ItemType.Shipping ||
        itemType === ItemType.Labor ||
        itemType === ItemType.Overhead)
    ) {
      newReconcileExpenses.push({
        itemId,
        name,
        description,
        quantity,
        currency,
        multiCurrencyCost: multiCurrencyTotalCost,
        cost: totalCost,
        addToVendorBill: true,
        new: false,
        receiptItemId: id,
        abbreviation,
      });
    }
  });

  return newReconcileExpenses;
}

export function findItemsToReconcile(
  reconcileReceiptItems: ReconcileReceiptItem[]
) {
  return reconcileReceiptItems.filter((i) => {
    return (
      i.itemType === ItemType.Inventory || i.itemType === ItemType.NonInventory
    );
  });
}

export function prepareSummaryStep(
  reconcile: Reconcile,
  setReconcile: React.Dispatch<React.SetStateAction<Reconcile>>,
  eachUom: Uom
) {
  const data: ReconcileSummary[] = [];
  const exchangeRate = _.get(reconcile, 'currency.exchangeRate', 1);
  reconcile.expenses.forEach((e) => {
    const unitCost = e.cost || 0;
    const multiCurrencyUnitCost = toMulticurrencyCalculate(
      unitCost,
      exchangeRate
    );
    const totalCost = (e.quantity || 0) * (e.cost || 0);
    const multiCurrencyTotalCost = toMulticurrencyCalculate(
      totalCost,
      exchangeRate
    );
    const reconcileCost =
      reconcile.calculation === ReconcileCalculateOptions.DoNotCalculate
        ? (e.cost || 0) * (e.quantity || 0)
        : null;
    const multiCurrencyReconcileCost = toMulticurrencyCalculate(
      reconcileCost || 0,
      exchangeRate
    );
    const landedCost =
      reconcile.calculation === ReconcileCalculateOptions.DoNotCalculate
        ? (e.cost || 0) * (e.quantity || 0)
        : null;
    const multiCurrencyLandedCost = toMulticurrencyCalculate(
      landedCost ?? 0,
      exchangeRate
    );

    data.push({
      receiptItemId: null,
      reconcileReceiptItem: null,
      item: null,
      currency: reconcile.currency,
      expenseName: e.name,
      quantity: e.quantity || 0,
      uomAbbrevation: eachUom ? eachUom.abbreviation : '',
      unitCost: e.cost || 0,
      totalCost: (e.quantity || 0) * (e.cost || 0),
      multiCurrencyUnitCost,
      multiCurrencyTotalCost,
      reconcileCost:
        reconcile.calculation === ReconcileCalculateOptions.DoNotCalculate
          ? (e.cost || 0) * (e.quantity || 0)
          : null,
      landedCost:
        reconcile.calculation === ReconcileCalculateOptions.DoNotCalculate
          ? (e.cost || 0) * (e.quantity || 0)
          : null,
      multiCurrencyLandedCost,
      multiCurrencyReconcileCost,
      onVendorBill: e.addToVendorBill,
    });
  });

  reconcile.itemsToReconcile
    .filter((i) => reconcile.selectedReconcileItems.includes(i.id!))
    .forEach((i) => {
      const resolvelandedCost = () => {
        if (i.purchaseOrderItemType === PurchaseOrderItemTypes.DropShip) {
          const landedCost =
            (reconcile.reconcilePrices[i.id!] || 0) + (i.landedTotalCost || 0);
          const multiCurrencyLandedCost = toMulticurrencyCalculate(
            landedCost,
            exchangeRate
          );
          return {
            landedCost,
            multiCurrencyLandedCost,
          };
        }

        const landedCost =
          (reconcile.reconcilePrices[i.id!] || 0) +
          (i.quantity || 0) * (i.unitCost || 0);
        const multiCurrencyLandedCost = toMulticurrencyCalculate(
          landedCost,
          exchangeRate
        );

        return {
          landedCost,
          multiCurrencyLandedCost,
        };
      };

      const reconcileCost =
        reconcile.calculation === ReconcileCalculateOptions.DoNotCalculate
          ? null
          : reconcile.reconcilePrices[i.id!] || 0;

      const multiCurrencyReconcileCost = toMulticurrencyCalculate(
        reconcileCost || 0,
        exchangeRate
      );

      const totalTax = i.taxAmount ?? 0;
      const multiCurrencyTaxTotal = i.multiCurrencyTaxTotal ?? 0;
      const subTotal = i.subTotal ?? 0;
      const multiCurrencySubTotal = i.multiCurrencySubTotal ?? 0;
      const costIncludesTax = i.costIncludeTax ?? false;

      data.push({
        receiptItemId: i.id,
        reconcileReceiptItem: i,
        item: null,
        currency: reconcile.currency,
        expenseName: null,
        quantity: i.quantity || 0,
        multiCurrencyLandedCost: resolvelandedCost().multiCurrencyLandedCost,
        multiCurrencyReconcileCost,
        multiCurrencyTotalCost: toMulticurrencyCalculate(
          i.totalCost || 0,
          exchangeRate
        ),
        multiCurrencyUnitCost: toMulticurrencyCalculate(
          i.unitCost || 0,
          exchangeRate
        ),
        uomAbbrevation: i.abbreviation,
        unitCost: i.unitCost || 0,
        totalCost: i.totalCost || 0,
        reconcileCost,
        landedCost: roundToDecimals(resolvelandedCost().landedCost, 6),
        onVendorBill: true,
        totalTax,
        multiCurrencyTaxTotal,
        subTotal,
        multiCurrencySubTotal,
        costIncludesTax,
      });
    });

  setReconcile((old) => ({
    ...old,
    summary: data,
  }));
}

export const filterReceiptItemsForReconciling = (receiptItems: ReceiptItem[]) =>
  receiptItems.filter(
    (ri) =>
      ri.status === ReceiptItemStatus.Open ||
      ri.status === ReceiptItemStatus.Received
  );

export const resolveSelectedReconcileItems = (receiptItems: ReceiptItem[]) =>
  receiptItems.length === 1
    ? [receiptItems[0].id!]
    : receiptItems
        .filter((i) => i.status === ReceiptItemStatus.Received)
        .map((i) => i.id!);
