import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const PAYMENTS_COLUMNS: Column[] = [
  {
    title: 'Date',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Payment Method',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Transaction Type',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Status',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Amount Paid',
    type: ColumnTypes.renderByCustomRow,
  },
];
