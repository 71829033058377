import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { quickTransferSubRowProps } from './types';
import _ from 'lodash';

import { Box, Typography } from '@mui/material';
import { themeRestyle } from 'ui/theme';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Modal } from 'ui/components/Modal/Modal';
import { useBulkMoveModalStyle } from './styled';
import { InventoryEventFormValues } from '../InventoryEventModal';
import { initialItem } from 'services/items';
import {
  ItemInventory,
  ItemInventoryEvents,
  SerialRow,
  TrackingGroup,
  getItemInventory,
  initialItemInventory,
  itemHasOnlySerialTracking,
  itemHasSerialTracking,
  itemHasTracking,
} from 'services/inventory';
import { defaultInventoryEventFormValues } from '../InventoryEventModal/consts';
import {
  getInventoryEventSchema,
  inventoryEventOnlySerialListValidation,
  inventoryEventTrackingGroupValidation,
} from 'ui/modules/materials/services';
import { transformToTrackingGroupsNewGroup } from 'ui/components/Table/TrackingTable';
import TrackingTable from 'ui/components/Table/TrackingTable/TrackingTable';
import SerialTable from 'ui/components/Table/SerialTable/SerialTable';
import { mapInventoryEventToTrackingTableType } from '../InventoryEventModal/helpers';
import { validateYup } from 'services/forms/validation';
import { sortSerialList } from 'ui/components/Table/SerialTable/helpers';
import { CustomStatus } from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

interface BulkMoveModalProps extends quickTransferSubRowProps {
  onConfirmClicked(
    formValues: InventoryEventFormValues,
    trackingGroups: TrackingGroup[]
  ): void;
  onCancelClicked(): void;
  show: boolean;
  availableQty: number;
  setLabelStatus: React.Dispatch<React.SetStateAction<boolean>>;
  meta?: any;
}
const BulkMoveDetailsModal: React.FC<BulkMoveModalProps> = (props) => {
  const {
    onConfirmClicked,
    onCancelClicked,
    show,
    locationFromId,
    locationToId,
    Item,
    quantity,
    availableQty,
    setLabelStatus,
    meta: { data, row, labelStatus },
  } = props;
  const classes = useBulkMoveModalStyle();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [itemInventory, setItemInventory] =
    useState<ItemInventory>(initialItemInventory);
  const [trackingGroups, setTrackingGroups] = useState<TrackingGroup[]>([]);
  const [serialList, setSerialList] = useState<SerialRow[]>([]);
  const [formValues, setFormValues] = useState<InventoryEventFormValues>(
    defaultInventoryEventFormValues
  );
  const [selectedSerialRows, setSelectedSerialRows] = useState<number[]>([]);
  const [initialSelectedSerialRows, setInitialSelectedSerialRows] = useState<
    number[]
  >([]);
  const [, setValidationErrors] = useState({});
  const [, setQtyErrorMessage] = useState('');

  const noTracking = useMemo(
    () => !itemHasTracking(Item || initialItem),
    [Item]
  );

  const onlySerial = useMemo(
    () => itemHasOnlySerialTracking(Item || initialItem),
    [Item]
  );

  const hasSerialTracking = useMemo(
    () => itemHasSerialTracking(Item || initialItem),
    [Item]
  );

  const showTrackingTable = useMemo(
    () => !noTracking && !onlySerial,
    [noTracking, onlySerial]
  );
  const enabledTrackingTable = useMemo(
    () => !!formValues.locationFromId,
    [formValues.locationFromId]
  );

  const showSerialTable = useMemo(
    () => !noTracking && onlySerial,
    [noTracking, onlySerial]
  );

  const enabledSerialTable = useMemo(
    () => Boolean(formValues.locationFromId),
    [formValues.locationFromId]
  );

  useEffect(() => {
    // when modal closes reset state
    if (!show) {
      setItemInventory(initialItemInventory);
      setTrackingGroups([]);
      setSerialList([]);

      setFormValues(defaultInventoryEventFormValues);
      setQtyErrorMessage('');
      return;
    }

    setIsLoading(true);
    const asyncFun = async () => {
      const inventory = await getItemInventory(Item?.id || 0, true);
      setItemInventory(inventory);
      setIsLoading(false);
    };

    asyncFun();

    setFormValues((f) => ({
      ...f,
      cost: null,
      locationFromId: locationFromId,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (!itemInventory) {
      return;
    }

    const inventoryRow = itemInventory.inventoryRowList.find(
      (row) => row.locationId === formValues.locationFromId
    );
    if (!inventoryRow) {
      setTrackingGroups(
        transformToTrackingGroupsNewGroup(
          [],
          Item?.itemTrackingTypeList || [],
          0
        )
      );
      return;
    }
    setTrackingGroups(inventoryRow.trackingGroupList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.locationFromId, itemInventory]);

  useEffect(() => {
    const serialList = _.get(trackingGroups, '[0].serialList', []);
    sortSerialList(serialList);
    setSerialList(serialList);
    if (!serialList.length && !trackingGroups.length) {
      return;
    }

    const quantity = trackingGroups.reduce(
      (acc, trackingGroup) => acc + (trackingGroup.quantity || 0),
      0
    );

    setFormValues((f) => ({
      ...f,
      quantity,
      locationToId: locationToId,
      locationFromId: locationFromId,
      amountToRemove: quantity,
      uomId: Item?.defaultUom?.id || 0,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingGroups]);

  const handleAddTrackingClicked = useCallback(() => {
    const availableQuantity = Math.max(
      0,
      (formValues.quantity || 0) -
        trackingGroups.map((t) => t.quantity || 0).reduce((a, b) => a + b, 0)
    );

    setTrackingGroups(
      transformToTrackingGroupsNewGroup(
        trackingGroups,
        Item?.itemTrackingTypeList || [],
        availableQuantity
      )
    );
  }, [formValues, trackingGroups, Item]);

  const saveTrackingItems = useCallback(
    async (
      newFormValues: InventoryEventFormValues,
      newTrackingGroups: TrackingGroup[]
    ) => {
      setIsLoading(true);
      try {
        onConfirmClicked(newFormValues, newTrackingGroups);
      } catch (e) {
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
    },
    [onConfirmClicked]
  );

  const handleApplyClicked = async () => {
    sortSerialList(serialList);
    const isValid = validateYup(
      formValues,
      getInventoryEventSchema(ItemInventoryEvents.Move),
      setValidationErrors
    );

    if (!isValid) {
      return;
    }

    if (onlySerial) {
      const newTrackingGroups: TrackingGroup[] = [
        {
          ...trackingGroups[0], // If only serial tracking group index is always 0
          quantity: formValues.quantity!,
          serialList,
          serialIds: selectedSerialRows,
          trackingInfoList: [],
        },
      ];
      await saveTrackingItems(formValues, newTrackingGroups);
      setLabelStatus(true);
      return;
    }

    await saveTrackingItems(formValues, trackingGroups);
    setLabelStatus(true);
    onCancelClicked();
  };

  const handleSelectedChange = useCallback(
    (id: number | number[]) => {
      if (Array.isArray(id)) {
        setFormValues((fv) => ({
          ...fv,
          quantity: id.length,
        }));
        setSelectedSerialRows(id);
        return;
      }

      const isIdAlreadySelected = selectedSerialRows.includes(id);
      if (!isIdAlreadySelected) {
        setFormValues((fv) => ({
          ...fv,
          quantity: selectedSerialRows.length + 1,
        }));
        setSelectedSerialRows([...selectedSerialRows, id]);
        return;
      }
      setFormValues((fv) => ({
        ...fv,
        quantity: selectedSerialRows.length - 1,
      }));
      setSelectedSerialRows(_.without(selectedSerialRows, id));
    },
    [selectedSerialRows, setSelectedSerialRows, serialList, setSerialList]
  );
  const hasInvalidTracking = useMemo(() => {
    if (noTracking) {
      return false;
    }

    if (onlySerial) {
      return inventoryEventOnlySerialListValidation(
        serialList,
        formValues.quantity || 0,
        ItemInventoryEvents.Move
      );
    }

    return inventoryEventTrackingGroupValidation(
      hasSerialTracking,
      trackingGroups,
      ItemInventoryEvents.Move
    );
  }, [
    trackingGroups,
    noTracking,
    hasSerialTracking,
    onlySerial,
    serialList,
    formValues.quantity,
    ItemInventoryEvents.Move,
  ]);

  const addDisabled = useMemo(() => {
    return showSerialTable
      ? selectedSerialRows.length != quantity
      : formValues.quantity != quantity;
  }, [formValues.quantity, selectedSerialRows]);

  const customStatusStyle = useMemo(
    () => (!addDisabled ? classes.chipSuccess : classes.chipWarning),
    [classes, addDisabled]
  );
  const statusMessage = useMemo(() => {
    if (!addDisabled) {
      return 'Success';
    } else if ((quantity || 0) - selectedSerialRows.length >= 0) {
      return `Select ${(quantity || 0) - selectedSerialRows.length} rows`;
    } else {
      return `Remove ${selectedSerialRows.length - (quantity || 0)} rows`;
    }
  }, [addDisabled, quantity, selectedSerialRows.length]);
  const cancelHandler = () => {
    setSelectedSerialRows(initialSelectedSerialRows);
    onCancelClicked();
  };
  const FBOModalActions = useCallback(() => {
    return (
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box>
          <Box sx={{ width: '100%' }}>
            <CustomStatus
              statusCustom={!addDisabled ? 'Success' : 'Partial selected'}
              statusCustomStyle={customStatusStyle}
              combineStatusLabels={true}
              label={statusMessage}
              classes={classes}
            />
          </Box>
        </Box>

        <Box display="flex">
          <Box mr={2}>
            <FBOButton
              variant="secondary"
              color="positive"
              size="medium"
              onClick={cancelHandler}
              data-qa="serial-modal-cancel-button"
            >
              Cancel
            </FBOButton>
          </Box>

          <FBOButton
            variant="primary"
            color="positive"
            size="medium"
            onClick={handleApplyClicked}
            data-qa="inventory-modal-apply"
            disabled={addDisabled}
          >
            Save
          </FBOButton>
        </Box>
      </Box>
    );
  }, [
    onCancelClicked,
    handleApplyClicked,
    quantity,
    formValues,
    selectedSerialRows,
    addDisabled,
    statusMessage,
  ]);
  const filteredSerialList = useMemo(() => {
    return serialList.filter((s) => s.committed === false);
  }, [serialList]);
  const filteredtrackingGroupsList = useMemo(() => {
    if (row?.trackingGroupObjects?.length > 0) {
      const updatedTrackingGroups = trackingGroups.map((tg, index) => ({
        ...tg,
        quantity:
          row.trackingGroupObjects[index].quantity > 0
            ? row.trackingGroupObjects[index].quantity
            : tg.quantity,
        serialIds: row.trackingGroupObjects[index].serialIds,
        //serialList: tg.serialList.filter((s) => s.committed === false),
      }));
      return updatedTrackingGroups;
    }
    return trackingGroups.map((tg) => ({
      ...tg,
      serialList: tg.serialList.filter((s) => s.committed === false),
    }));
  }, [trackingGroups, labelStatus]);
  return (
    <Modal
      className="redesign inventory-event-modal"
      open={show}
      title="Bulk Move - Tracking"
      applyLabel="Save"
      onCancelClicked={onCancelClicked}
      onApplyClicked={handleApplyClicked}
      isLoading={isLoading}
      withoutDefaultPadding
      applyDisabled={hasInvalidTracking || addDisabled}
      nestedScrollAreas
      isLoadingContent={isLoading}
      dataQa="inventory-modal"
      confirmButtonRed={false}
      ModalActionsComponent={showSerialTable ? FBOModalActions : undefined}
    >
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        columns={16}
        columnSpacing={themeRestyle.spacing(2)}
        padding={`0px ${themeRestyle.spacing(4)} 0px ${themeRestyle.spacing(
          4
        )}`}
        disableEqualOverflow
      >
        <Grid
          xs={16}
          sx={{
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <Grid xs={2}>
            <Typography className={classes.topText}>Item</Typography>
            <Typography className={classes.bottomText}>{Item?.name}</Typography>
          </Grid>
          <Grid xs={3}>
            <Typography className={classes.topText}>From Location</Typography>
            <Typography className={classes.bottomText}>
              {data.locationFromName}
            </Typography>
          </Grid>
          <Grid xs={3}>
            <Typography className={classes.topText}>To Location</Typography>
            <Typography className={classes.bottomText}>
              {data.locationToName}
            </Typography>
          </Grid>
          <Grid xs={3}>
            <Typography className={classes.topText}>Able To Move</Typography>
            <Typography className={classes.bottomText}>
              {availableQty}
            </Typography>
          </Grid>
          <Grid xs={3}>
            <Typography className={classes.topText}>Qty To Move</Typography>
            <Typography className={classes.bottomText}>{quantity}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        {showTrackingTable && (
          <TrackingTable
            itemTrackingTypes={Item?.itemTrackingTypeList || []}
            trackingGroups={
              enabledTrackingTable ? filteredtrackingGroupsList : []
            }
            onAddNewTracking={handleAddTrackingClicked}
            onSetTrackingGroups={setTrackingGroups}
            tableType={mapInventoryEventToTrackingTableType(
              ItemInventoryEvents.Move
            )}
            firstColumnTitle="Move"
            allowSerialNumberImport={false}
            formValues={formValues}
            itemUom={Item?.defaultUom}
            inventoryUom={Item?.defaultUom}
            emptyTableText="Please select a location to begin"
            isDecimal={!hasSerialTracking}
            disableAutoAssign={true}
            headerMargin="0px 32px"
            bulkMove={true}
          />
        )}
        {showSerialTable ? (
          <SerialTable
            title={'Add Items'}
            minSerialQuantity={formValues.quantity || 0}
            serialList={enabledSerialTable ? filteredSerialList : []}
            allowSerialNumberImport={false}
            setSerialList={setSerialList}
            selectedRows={selectedSerialRows}
            onSelectedRowsChanged={handleSelectedChange}
            itemTrackingTypes={Item?.itemTrackingTypeList || []}
            canClearRow={false}
            canEditRow={false}
            canSelectRow={true}
            emptyTableText='Please fill in "Location" and "Amount to Add" to begin'
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            disableAutoAssign={true}
            quantity={formValues.quantity || 0}
            eventType={ItemInventoryEvents.Move}
            headerMargin="0px 32px"
            sx={{ height: '500px' }}
            meta={{
              setInitialSelectedSerialRows: setInitialSelectedSerialRows,
              resetFlag: true,
            }}
          />
        ) : (
          <></>
        )}
      </Grid>
    </Modal>
  );
};

export default memo(BulkMoveDetailsModal);
