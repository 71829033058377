import { State } from 'app/redux';

import { ShippingConnectionType, ShippingConnection } from '../types';

export const getShippingIntegration = (state: State) =>
  state.integrations.shipping;

export const getShippingIntegrationConnection =
  (type: ShippingConnectionType) => (state: State) => {
    const { connections } = getShippingIntegration(state);

    return connections.find((c) => c.typeId === type);
  };

export const getShippingConnection = (
  state: State
): ShippingConnection | undefined => {
  const { connections } = getShippingIntegration(state);

  return connections[0];
};

export const isShippingIntegrationConnected =
  (type: ShippingConnectionType) => (state: State) =>
    !!getShippingIntegrationConnection(type)(state);
