import {
  DisplaySalesOrder,
  SalesOrderBundleItem,
  SalesOrderItem,
  SalesOrderItemTypes,
  SalesOrderStatus,
} from 'services/salesOrders';

export const mergeSoItems = (
  salesItems: (SalesOrderItem | SalesOrderBundleItem)[]
) => {
  const mergedItems = salesItems
    .map((i) => {
      if (
        i.salesOrderItemType === SalesOrderItemTypes.Bundle ||
        i.salesOrderItemType === SalesOrderItemTypes.BundleCreditReturn
      ) {
        return (i as SalesOrderBundleItem).salesOrderItems;
      }

      if (
        i.salesOrderItemType === SalesOrderItemTypes.Sale ||
        i.salesOrderItemType === SalesOrderItemTypes.CreditReturn ||
        i.salesOrderItemType === SalesOrderItemTypes.DropShip
      ) {
        return i as SalesOrderItem;
      }
      return [];
    })
    .flat();

  return mergedItems;
};

export const isIssueButtonVisible = (
  activeId: number | null,
  salesOrders: DisplaySalesOrder[],
  selectedItems: number[]
) => {
  if (!selectedItems.length || activeId) {
    return;
  }

  return salesOrders
    .filter((so) => so.id && selectedItems.includes(so.id))
    .some((so) => so.salesOrderStatus === SalesOrderStatus.Estimate);
};

export const isUnissuedVisible = (
  activeId: number | null,
  salesOrders: DisplaySalesOrder[],
  selectedItems: number[]
) => {
  if (!selectedItems.length || activeId) {
    return;
  }

  return salesOrders
    .filter((so) => so.id && selectedItems.includes(so.id))
    .some((so) => so.salesOrderStatus === SalesOrderStatus.Issued);
};
