import {
  PricingRule,
  PricingRuleRelativeToType,
  PricingRuleType,
} from './types';

export const initialPricingRule: PricingRule = {
  id: null,
  checkVersion: true,
  customerId: null,
  customerTags: [],
  dateCreated: null,
  dateLastModified: null,
  deleted: false,
  description: null,
  endDate: null,
  maxQuantity: null,
  minQuantity: null,
  name: null,
  percent: null,
  price: null,
  relativeTo: PricingRuleRelativeToType.AverageCost,
  saleItemId: null,
  itemId: null,
  saleItemTags: [],
  startDate: null,
  type: PricingRuleType.Markdown,
  version: null,
};
