import { reportingApi } from 'services/api/config';

export const getHtmlReport = async (reportName: string, parameters: any) => {
  const uriParams = encodeURI(JSON.stringify(parameters));

  // client timezone
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const response = await reportingApi({
    url: `/api/v1/reports?reportName=${reportName}&parameters=${uriParams}&html=true&timezone={${timezone}}`,
    method: 'GET',
    responseType: 'text',
  });

  return response.data;
};

export const getHtmlReportThemePreview = async (
  reportName: string,
  layoutId: number
) => {
  const response = await reportingApi({
    url: `/api/v1/reports/theme-preview?reportName=${reportName}&layoutId=${layoutId}`,
    method: 'GET',
    responseType: 'text',
  });

  return response.data;
};
