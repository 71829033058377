import { ReceiptItemReceive } from 'services/receiving';
export const isTrackingAvailableOnRecieve = (
  recieveItems: ReceiptItemReceive[]
) => {
  return recieveItems.some(
    (i) =>
      i?.receiptItem?.item?.itemTrackingTypeList &&
      i.receiptItem.item.itemTrackingTypeList.length > 0
  );
};

export const handleAutoReceive = async (
  receives: ReceiptItemReceive[],
  setReceives: React.Dispatch<React.SetStateAction<ReceiptItemReceive[]>>,
  setActiveReceive: React.Dispatch<React.SetStateAction<ReceiptItemReceive>>,
  fakeSummaryStep: ReceiptItemReceive,
  setIsLoading: (isLoading: boolean) => void
) => {
  const updatedItems = [...receives];

  for (const recieve of receives) {
    const index = receives.findIndex(
      (r) => r.receiptItemId === recieve.receiptItemId
    );
    setIsLoading(true);
    updatedItems[index] = {
      ...updatedItems[index],
      quantity: recieve.receiptItem.quantity || 0,
    };
  }
  setReceives(updatedItems);
  setIsLoading(false);

  setActiveReceive(fakeSummaryStep);
};
