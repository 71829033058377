import React from 'react';
import { useSelector } from 'react-redux';

import { Button, ButtonProps } from '@mui/material';
import ButtonSpinner from './ButtonSpinner';
import { Icon } from 'ui/components/Icon';

import { activeUserHasPermission } from 'services/user/redux';
import { PermissionType } from 'services/permissions';

import clsx from 'clsx';

/**
   - Color
     | Fbo      | Mui                  |
     | ---      | ---                  |
     | positive | primary              |
     | negative | secondary            |
     | Neutral  | .neutral (css class) |

   - Size
     | Fbo    | Mui    |
     | ---    | ---    |
     | small  | small  |
     | medium | medium |
     | large  | large  |

   - Variant
     | Fbo       | Mui       |
     | ---       | ---       |
     | primary   | contained |
     | secondary | contained |
     | tertiary  | text      |
     | link      | text      |
 */
export type Variant = 'primary' | 'secondary' | 'tertiary' | 'link';
export type Color = 'positive' | 'negative' | 'neutral';

type Href = string;

type CustomProps = 'color' | 'variant';
type MuiProps = Omit<ButtonProps, CustomProps>;

type DefaultPropType = typeof defaultProps;

export type FBOButtonProps = {
  variant: Variant;
  color: Color;
  loading: boolean;
  icon: string;
  href: Href;
  target?: string;
  rel?: string;
  component?: string;
  'data-qa': string;
  permissions?: PermissionType[];
  linkStyledAsButton?: boolean;
} & MuiProps &
  DefaultPropType;

const defaultProps = {
  loading: false,
  icon: '',
  href: '',
};

const FBOButton = (props: FBOButtonProps) => {
  const {
    children,
    size,
    variant,
    color,
    loading,
    href,
    icon,
    disabled,
    permissions = [],
    linkStyledAsButton = false,
    ...muiProps
  } = props;

  const canClick = useSelector(activeUserHasPermission(permissions));

  const renderIcon = (icon: string) => {
    if (loading) return <ButtonSpinner />;
    return <Icon name={icon} />;
  };

  return (
    <Button
      disableRipple
      disableTouchRipple
      className={clsx('redesign', {
        'icon-only': icon && !children,
        loading,
        [color]: color,
      })}
      size={size}
      variant={mapVariant(variant, href, linkStyledAsButton)}
      color={mapColor(variant)}
      disabled={loading || disabled || !canClick}
      href={href ? href : undefined}
      {...muiProps}
    >
      {(loading || icon) && renderIcon(icon)}
      {children}
    </Button>
  );
};

const mapColor = (variant: Variant): ButtonProps['color'] => {
  if (variant === 'secondary') return 'secondary';
  return 'primary';
};

const mapVariant = (
  variant: Variant,
  href: Href,
  linkStyledAsButton: boolean
): ButtonProps['variant'] => {
  switch (variant) {
    case 'primary': {
      if (href && !linkStyledAsButton) return 'text';
      return 'contained';
    }
    case 'secondary':
      return 'contained';
    case 'tertiary':
      return 'text';
    case 'link':
      return 'text';
    default:
      return 'contained';
  }
};

FBOButton.defaultProps = defaultProps;
export default FBOButton;
