import React, { useCallback, useState, useEffect } from 'react';

import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Grid } from '@mui/material';
import { FBOTextField } from 'ui/theme/components';
import * as yup from 'yup';

import PasswordRequirements, {
  passwordRequirementsMet,
} from 'ui/components/TextField/PasswordRequirements/PasswordRequirements';
import { Errors, validateYup } from 'services/forms/validation';
import { useHandleTextFieldChange } from 'services/forms';
import { changePassword } from 'services/auth';
import { NetworkSpinnerWrapper } from 'ui/components/NetworkSpinnerWrapper';
import { Routes } from 'ui/modules/public/navigation';

import { ChangePasswordFormProps, FormValues } from './types';
import { initialFormValues } from './consts';

const yupSchema = yup.object().shape({
  password: yup.string().default('').required(),
});

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = () => {
  const query = new URLSearchParams(useLocation().search);
  const email = query.get('email');
  const verificationCode = query.get('verificationCode');

  const navigate = useNavigate();

  const [formValues, setFormValues] = useState<FormValues>(initialFormValues);
  const [errors, setErrors] = useState<Errors>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requirementsMet, setRequirementsMet] = useState<boolean>(false);

  const checkPasswordRequirements = () => {
    setRequirementsMet(passwordRequirementsMet(formValues.password));
  };
  useEffect(checkPasswordRequirements, [formValues]);

  const handleTextFieldChange = useHandleTextFieldChange(
    setFormValues,
    formValues
  );

  const handleSubmit = useCallback(
    async (ev: React.FormEvent<HTMLFormElement>) => {
      ev.preventDefault();

      const isValid = validateYup(formValues, yupSchema, setErrors);
      if (!isValid) return;
      if (!requirementsMet) return;

      setIsLoading(true);
      try {
        await changePassword({
          email: email!,
          verificationCode: verificationCode!,
          password: formValues.password!,
        });

        navigate(Routes.LoginPage);
      } catch {
        // Ignore error
      }
      setIsLoading(false);
    },
    [formValues, email, verificationCode, requirementsMet, navigate]
  );

  if (!email || !verificationCode) {
    return <Navigate to={Routes.LoginPage} replace />;
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <FBOTextField
            required
            name="password"
            className="redesign"
            type="password"
            label="NEW PASSWORD"
            value={formValues.password}
            error={!!errors.password}
            errorText={errors.password}
            dataQa="password"
            allowLastPassIcon
            onChange={handleTextFieldChange}
          />
        </Grid>
        <Grid item>
          <PasswordRequirements value={formValues.password} />
        </Grid>
        <Grid item>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <NetworkSpinnerWrapper
              isLoading={isLoading}
              hideBackground
              size={24}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                disabled={!formValues.password || isLoading}
              >
                Change password
              </Button>
            </NetworkSpinnerWrapper>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default React.memo(ChangePasswordForm);
