import _ from 'lodash';

import { AdvancedSearchItem, QuickSearchItem } from 'services/search';

import { Column } from 'ui/components/Table/ItemsTable';

/**
 * Enriches `url` with advanced search string.
 * From `search` param, only fields with defined values are used.
 * @param url
 * @param search
 */
export const advancedSearchURL = (
  url: string,
  search: AdvancedSearchItem
): string => {
  return _.reduce(
    search.columns,
    (acc, fieldValue, fieldName) => {
      if (!fieldValue) {
        return acc;
      }

      const querySymbol = acc.includes('?') ? '&' : '?';

      return `${acc}${querySymbol}${fieldName}=${fieldValue}`;
    },
    url
  );
};

/**
 * Enriches `url` with quick search string.
 * @param url
 * @param search
 * @param quickSearchColumns
 * @param advancedSearch
 */
export const quickSearchURL = (
  url: string,
  search: QuickSearchItem,
  quickSearchColumns?: string[],
  advancedSearch?: AdvancedSearchItem
): string => {
  const querySymbol = url.includes('?') ? '&' : '?';
  const searchableColumns = search.columns.filter(
    (c: Column) => c.searchable == null || c.searchable === true
  );
  const columnsArry =
    quickSearchColumns ||
    searchableColumns.reduce((acc: string[], c: Column) => {
      // eslint-disable-next-line no-prototype-builtins
      if (c.hasOwnProperty('field')) {
        return [...acc, (c as any).field];
      }
      return acc;
    }, []);
  const columnsString = columnsArry.reduce((acc, c) => `${acc},${c}`, '');

  if (advancedSearch?.quickSearchValue) {
    return `${url}${querySymbol}quickSearchValue=${
      search.value || advancedSearch?.quickSearchValue
    }&quickSearchColumns=${_.trim(columnsString, ',')}`;
  }

  return `${url}${querySymbol}quickSearchValue=${encodeURIComponent(
    search.value
  )}&quickSearchColumns=${_.trim(columnsString, ',')}`;
};

/**
 * Enriches `url` with search term string.
 * Using `searchTerm` and `searchColumns` to match new endpoint format.
 * @param url
 * @param search
 * @param quickSearchColumns
 * @param advancedSearch
 */
export const searchTermURL = (
  url: string,
  search: QuickSearchItem,
  quickSearchColumns?: string[],
  advancedSearch?: AdvancedSearchItem
): string => {
  const querySymbol = url.includes('?') ? '&' : '?';
  const searchableColumns = search.columns.filter(
    (c: Column) => c.searchable == null || c.searchable === true
  );
  const columnsArray =
    quickSearchColumns ||
    searchableColumns.reduce((acc: string[], c: Column) => {
      // eslint-disable-next-line no-prototype-builtins
      if (c.hasOwnProperty('field')) {
        return [...acc, (c as any).field];
      }
      return acc;
    }, []);
  const columnsString = columnsArray.reduce((acc, c) => `${acc},${c}`, '');

  if (advancedSearch?.quickSearchValue) {
    return `${url}${querySymbol}searchTerm=${
      search.value || advancedSearch?.quickSearchValue
    }&searchColumns=${_.trim(columnsString, ',')}`;
  }
  if (search.value) {
    return `${url}${querySymbol}searchTerm=${encodeURIComponent(
      search.value
    )}&searchColumns=${_.trim(columnsString, ',')}`;
  } else {
    return url;
  }
};

/**
 * Enriches `url` with search term string.
 * Using `search.like` and `search.cols` to match new new endpoint format.
 * @param url
 * @param search
 * @param quickSearchColumns
 * @param advancedSearch
 */
export const searchLikeURL = (
  url: string,
  search: QuickSearchItem,
  quickSearchColumns?: string[],
  advancedSearch?: AdvancedSearchItem
): string => {
  const querySymbol = url.includes('?') ? '&' : '?';
  const searchableColumns = search.columns.filter(
    (c: Column) => c.searchable == null || c.searchable === true
  );
  const columnsArray =
    quickSearchColumns ||
    searchableColumns.reduce((acc: string[], c: Column) => {
      // eslint-disable-next-line no-prototype-builtins
      if (c.hasOwnProperty('field')) {
        return [...acc, (c as any).field];
      }
      return acc;
    }, []);
  const columnsString = columnsArray.reduce((acc, c) => `${acc},${c}`, '');

  if (advancedSearch?.quickSearchValue) {
    return `${url}${querySymbol}search.like=${
      search.value || advancedSearch?.quickSearchValue
    }&search.cols=${_.trim(columnsString, ',')}`;
  }
  if (search.value) {
    return `${url}${querySymbol}search.like=${encodeURIComponent(
      search.value
    )}&search.cols=${_.trim(columnsString, ',')}`;
  } else {
    return url;
  }
};
