import _ from 'lodash';

import {
  Permission,
  PermissionCategory,
  PermissionGroup,
} from 'services/permissions';

import { PermissionCategoryState } from './types';

export const getPermissionCategoryState = (
  permissionCategory: PermissionCategory,
  permissionGroup: PermissionGroup
): PermissionCategoryState => {
  const categoryPermissionIds: number[] = [];

  permissionCategory.permissionAreas.forEach((cat) => {
    cat.permissions.forEach((per) => {
      categoryPermissionIds.push(per.id);
    });
  });

  // permission ids from category that are not checked
  const notCheckedIds = _.difference(
    categoryPermissionIds,
    permissionGroup.permissionIds
  );

  if (notCheckedIds.length === 0) {
    return PermissionCategoryState.Checked;
  }

  if (notCheckedIds.length === categoryPermissionIds.length) {
    return PermissionCategoryState.NotChecked;
  }

  return PermissionCategoryState.PartiallyChecked;
};

export const removeCategoryPermissions = (
  permissionCategory: PermissionCategory,
  setPermissionGroup: React.Dispatch<React.SetStateAction<PermissionGroup>>
) => {
  const permissionIdsToRemove: number[] = [];
  permissionCategory.permissionAreas.forEach((pA) => {
    const permissionIds = pA.permissions.map((p) => p.id);
    permissionIdsToRemove.push(...permissionIds);
  });

  // remove all area permissions from permission group
  setPermissionGroup((old) => ({
    ...old,
    permissionIds: old.permissionIds.filter(
      (id) => !permissionIdsToRemove.includes(id)
    ),
    permissions: old.permissions.filter(
      (p) => !permissionIdsToRemove.includes(p.id)
    ),
  }));
};

export const addCategoryPermissions = (
  permissionCategory: PermissionCategory,
  setPermissionGroup: React.Dispatch<React.SetStateAction<PermissionGroup>>
) => {
  setPermissionGroup((old) => {
    const permissionsToAdd: Permission[] = [];
    permissionCategory.permissionAreas.forEach((pA) => {
      // get permissions from area that are not checked and add them to permission group
      permissionsToAdd.push(...pA.permissions);
    });
    return {
      ...old,
      permissionIds: [
        ...old.permissionIds,
        ...permissionsToAdd.map((p) => p.id),
      ],
      permissions: [...old.permissions, ...permissionsToAdd],
    };
  });
};
