import React, { memo, useCallback, useState } from 'react';
import { Box } from '@mui/material';

import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { FileDropZone } from 'ui/components/FileDropZone';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { replaceValueInCollection } from 'helpers';
import { Document, transformDocument } from 'services/documents';

import { DocumentsRow } from './components';
import { DOCUMENTS_ITEMS_COLUMNS } from './consts';
import { DocumentsTableProps, DocumentTableType } from './types';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { IconNames } from 'ui/theme';

const DocumentsTable: React.FC<DocumentsTableProps> = (props) => {
  const {
    data,
    setData,
    storageType,
    disabled = false,
    customDocumentSave,
    permissions,
  } = props;

  const [activeDocument, setActiveDocument] = useState<{
    index: number;
    filePath: string;
  } | null>(null);

  const onSaveDocuments = useCallback(
    (documents: string[]) => {
      const newDocuments = documents.map(transformDocument);

      if (customDocumentSave) {
        customDocumentSave({
          ...data,
          documents: [...data.documents, ...newDocuments],
        });
        return;
      }

      setData((old: DocumentTableType) => ({
        ...old,
        documents: [...old.documents, ...newDocuments],
      }));
    },
    [setData, data, customDocumentSave]
  );

  const handleDeleteDocument = useCallback(() => {
    if (!activeDocument) {
      return;
    }

    const newDocuments: Document[] = replaceValueInCollection(
      data.documents,
      { ...data.documents[activeDocument.index], deleted: true },
      activeDocument.index
    )!;

    if (customDocumentSave) {
      customDocumentSave({ ...data, documents: newDocuments });
      setActiveDocument(null);
      return;
    }

    setData((old: DocumentTableType) => ({
      ...old,
      documents: newDocuments,
    }));
    setActiveDocument(null);
  }, [activeDocument, data, customDocumentSave, setData]);

  const handleOnAction = useCallback(
    (action: { index: number; filePath: string }) => {
      setActiveDocument(action);
    },
    []
  );

  const DialogActionsComponent = () => (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={{ minWidth: '100%' }}
    >
      <FBOButton
        variant="secondary"
        color="neutral"
        size="large"
        onClick={() => setActiveDocument(null)}
        data-qa="documents-table-cancel-button"
        style={{ minWidth: '43%', marginLeft: '24px' }}
      >
        Cancel
      </FBOButton>
      <FBOButton
        variant="primary"
        color="negative"
        size="large"
        icon={IconNames.TrashCan}
        onClick={handleDeleteDocument}
        data-qa="documents-table-delete-button"
        style={{ minWidth: '43%', marginLeft: '8px', marginRight: '24px' }}
      >
        Delete
      </FBOButton>
    </Box>
  );

  return (
    // TODO : RESTYLING : REMOVE BOX WRAPPER AFTER FF CLEANUP
    <Box
      display="flex"
      flexDirection="column"
      height="auto"
      width="100%"
      overflow="hidden"
    >
      <FBOTitleBar title="Documents">
        <FileDropZone
          storageType={storageType}
          disabled={disabled}
          onSave={onSaveDocuments}
          permissions={permissions}
        />
      </FBOTitleBar>
      <ItemsTable
        data={data.documents || []}
        columns={DOCUMENTS_ITEMS_COLUMNS}
        RenderCustomRow={DocumentsRow}
        selectableItems={false}
        filterRows={(row: Document) => !row.deleted}
        emptyTableText="NO DOCUMENTS TO DISPLAY"
        onAction={handleOnAction}
        meta={{
          permissions,
          disabledButton: disabled,
          tooltip: disabled ? 'Cannot remove documents.' : undefined,
        }}
      />
      <ConfirmationModal
        maxWidth="xs"
        open={!!activeDocument}
        title="Delete Document"
        body="Are you sure you want to delete this document?"
        onCancelClicked={() => {}}
        onConfirmClicked={() => {}}
        DialogActionsComponent={DialogActionsComponent}
      />
    </Box>
  );
};

export default memo(DocumentsTable);
