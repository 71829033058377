import {
  getSearches,
  addSearch,
  updateSearch,
  deleteSearch,
} from 'services/paymentTerms';
import { PaginationDirection } from 'services/url';
import {
  AdvancedSearchFormValues,
  AdvancedSearchReduxFunctions,
} from 'services/search';
import { Dates } from 'helpers';
import { MenuItem } from 'ui/components/Menu';
import { IconNames } from 'ui/theme';
import { PermissionType } from 'services/permissions';

export const advancedSearchReduxActions: AdvancedSearchReduxFunctions = {
  add: addSearch,
  delete: deleteSearch,
  update: updateSearch,
  get: getSearches,
};

export const displayNameMap = {
  name: 'Name',
  paymentTermType: 'Type',
  deleted: 'Deleted',
};

export const displayValueMap = {
  paymentTermType: (paymentTermType: string) => {
    if (paymentTermType === 'Standard') {
      return 'Net Term';
    }
    return paymentTermType;
  },
};

export const initialPaymentTermASFormValues: AdvancedSearchFormValues = {
  name: null,
  paymentTermType: null,
  deleted: null,
};

export const initialPagination = {
  page: 1,
  pageSize: 25,
  sort: {
    sortBy: 'id',
    direction: PaginationDirection.Descending,
  },
  totalRows: 25,
};

export enum PaymentTermsPageAction {
  Import,
  Export,
  ChangeDate,
}

export const createActionBarOptions = (
  onActionClick: (actionType: PaymentTermsPageAction, date?: Dates) => void,
  paymentTermsCount: number,
  csvDisabled?: boolean
): MenuItem[] => [
  {
    label: 'Import Payment Terms',
    icon: IconNames.FBOImport,
    onOptionClick: () => onActionClick(PaymentTermsPageAction.Import),
    permissions: [
      PermissionType.PaymentTermsCreate,
      PermissionType.PaymentTermsEdit,
    ],
    separateAfter: true,
    disabled: csvDisabled,
  },
  {
    label: 'Export Payment Terms',
    icon: IconNames.FBOExport,
    onOptionClick: () => onActionClick(PaymentTermsPageAction.Export),
    disabled: !paymentTermsCount || csvDisabled,
    separateAfter: true,
  },
  {
    icon: IconNames.FBORecycleSmall,
    label: 'Change Dates',
    subItems: [
      {
        label: 'Date Created',
        onOptionClick: () =>
          onActionClick(PaymentTermsPageAction.ChangeDate, Dates.DateCreated),
        dataQa: 'date-created',
        separateAfter: true,
      },
      {
        label: 'Date Last Modified',
        onOptionClick: () =>
          onActionClick(
            PaymentTermsPageAction.ChangeDate,
            Dates.DateLastModified
          ),
        dataQa: 'date-Last-modified',
      },
    ],
  },
];
