import {
  createApiReducer,
  chainReducers,
  INITIAL_API_STATUS,
  ApiState,
} from 'services/api';
import {
  createAddSearchReducer,
  createDeleteSearchReducer,
  createFetchSearchReducer,
  createUpdateSearchReducer,
} from 'services/search';
import { combineReducers } from 'redux';

import { LocationsActionTypes } from './actions';
import { transformLocation } from '../transformations';

const initialState: ApiState = {
  _status: INITIAL_API_STATUS,
  items: [],
};

const fetchLocationsReducer = createApiReducer(
  [
    LocationsActionTypes.FETCH_LOCATIONS_START,
    LocationsActionTypes.FETCH_LOCATIONS_SUCCESS,
    LocationsActionTypes.FETCH_LOCATIONS_FAILURE,
  ],
  (data) => ({ items: data.results.map(transformLocation) }),
  (data) => data
);

const locationChainedReducers = chainReducers(
  initialState,
  fetchLocationsReducer
);

const fetchSearchReducer = createFetchSearchReducer('location');
const addSearchReducer = createAddSearchReducer('location');
const updateSearchReducer = createUpdateSearchReducer('location');
const deleteSearchReducer = createDeleteSearchReducer('location');

const searchChainedReducers = chainReducers(
  initialState,
  fetchSearchReducer,
  addSearchReducer,
  updateSearchReducer,
  deleteSearchReducer
);
export default combineReducers({
  locations: locationChainedReducers,
  searches: searchChainedReducers,
});
