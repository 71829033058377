import React, { memo, useEffect, useState } from 'react';

import { useTagsPresentationalComponentStyles } from './styled';
import { Tag, fetchTagsAPI } from 'services/tags';
import { Box } from '@mui/system';
import { Chip } from '@mui/material';
import { TagsPresentationalComponentProps } from './types';

const TagsPresentationalComponent: React.FC<
  TagsPresentationalComponentProps
> = (props: TagsPresentationalComponentProps) => {
  const [tags, setTags] = useState<Tag[] | null>(null);

  const fetchTags = async (ids: number[]) => {
    try {
      const response = await fetchTagsAPI({}, ids);
      props.setTagsLoaded(true);
      setTags(response.data as unknown as Tag[]);
      props.setTagsLoaded(true);
    } catch (e) {
      //continue
    }
  };

  useEffect(() => {
    if (props.ids) {
      fetchTags(props.ids);
    }
  }, [props.ids]);

  const classes = useTagsPresentationalComponentStyles();

  return (
    <Box className={classes.tagContainer}>
      {tags?.map((tag) => (
        <Chip key={tag.id} label={tag.name} className={classes.tag} />
      ))}
    </Box>
  );
};

export default memo(TagsPresentationalComponent);
