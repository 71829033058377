import { transformDateToMomentDate } from 'helpers';
import { Carrier, CarrierService } from './types';

export const transformCarrier = (response: any): Carrier => {
  const carrierServices = response.carrierServices || [];
  return {
    id: response.id,
    carrierServiceList: carrierServices.map((i: any) =>
      transformCarrierServiceList(i)
    ),
    dateCreated: transformDateToMomentDate(response.dateCreated),
    dateLastModified: transformDateToMomentDate(response.dateLastModified),
    deleted: response.deleted,
    description: response.description,
    name: response.name,
    readOnly: response.readOnly,
    scac: response.scac,
    version: response.version,
  };
};

export const transformCarrierServiceList = (response: any): CarrierService => ({
  id: response.id,
  carrierId: response.carrierId,
  code: response.code,
  dateCreated: transformDateToMomentDate(response.dateCreated),
  dateLastModified: transformDateToMomentDate(response.dateLastModified),
  deleted: response.deleted,
  name: response.name,
  readOnly: response.readOnly,
  version: response.version,
});

export const transformRequestCarrier = (carrier: Carrier): any => {
  const body = {
    carrierServices: carrier.carrierServiceList.map(
      transformRequestCarrierService
    ),
    description: carrier.description,
    name: carrier.name,
    readOnly: carrier.readOnly,
    scac: carrier.scac,
  };

  // POST
  if (carrier.id === null || carrier.id < 0) {
    return body;
  }

  // PUT
  return {
    ...body,
    id: carrier.id,
    deleted: carrier.deleted,
    version: carrier.version,
  };
};

export const transformRequestCarrierService = (
  carrierService: CarrierService
): any => {
  const body = {
    name: carrierService.name,
    code: carrierService.code,
    readOnly: carrierService.readOnly,
  };

  // POST
  if (carrierService.id === null || carrierService.id < 0) {
    return body;
  }

  // PUT
  return {
    ...body,
    id: carrierService.id,
    deleted: carrierService.deleted,
    version: carrierService.version,
  };
};
