import React, { useCallback, useState, memo, useEffect, useRef } from 'react';
import {
  globalSettingsRowDescriptor,
  globalSettingsCardId,
  title,
} from './consts';
import { Card } from '../Card';
import {
  GlobalSettings,
  getGlobalSettings,
  globalSettingsPut,
} from 'services/commerce/settings';
import { showNotification } from 'services/api';
import { fetchSellwareActiveAccount } from 'services/commerce';
import { useDispatch } from 'react-redux';
import { useModalActions } from '../../ModalActionsContext';

interface SettingsCardProps extends React.HTMLAttributes<HTMLDivElement> {}

const SettingsCard: React.FC<SettingsCardProps> = () => {
  const { closeModal } = useModalActions();
  const [globalSettings, setGlobalSettings] = useState<
    GlobalSettings | undefined
  >();
  const [cardDataLoaded, setCardDataLoaded] = useState(false);
  const [tagsLoaded, setTagsLoaded] = useState(false);
  const [locationsLoaded, setLocationsLoaded] = useState(false);

  const dispatch = useDispatch();
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (globalSettings) {
      setCardDataLoaded(true);
    }
  }, [globalSettings, tagsLoaded, locationsLoaded]);

  const saveSettings = useCallback(
    async (settings: GlobalSettings) => {
      try {
        await globalSettingsPut(settings);
      } catch (e) {
        // Handle the error if needed
      }

      if (isMounted.current) {
        callGetGlobalSettings();
        // @ts-ignore
        dispatch(fetchSellwareActiveAccount());
      }
    },
    [dispatch]
  );

  const handleApplyClicked = useCallback(
    async (settings: GlobalSettings) => {
      await saveSettings(settings);
      if (isMounted.current) {
        closeModal();
      }
    },
    [saveSettings, closeModal]
  );

  const callGetGlobalSettings = async () => {
    try {
      const response = await getGlobalSettings();
      if (isMounted.current) {
        setGlobalSettings(response);
      }
    } catch (e) {
      if (isMounted.current) {
        showNotification(
          'There was an error connecting to Fishbowl Commerce. Try again later.',
          { variant: 'error' }
        );
      }
    }
  };

  useEffect(() => {
    callGetGlobalSettings();
  }, []);

  return (
    <Card
      title={title}
      rows={globalSettingsRowDescriptor}
      id={globalSettingsCardId}
      data={globalSettings}
      onApplyClicked={handleApplyClicked}
      show
      dataQa="commerce-global-settings"
      cardDataLoaded={cardDataLoaded}
      setTagsLoaded={setTagsLoaded}
      setLocationsLoaded={setLocationsLoaded}
    />
  );
};

export default memo(SettingsCard);
