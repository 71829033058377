import React, { memo, useCallback, useMemo } from 'react';
import { Table, TableContainer } from '@mui/material';
import clsx from 'clsx';
import _ from 'lodash';

import { useThemeTableDensity } from 'app/components/AppThemeProvider';
import { Pagination, Sort } from 'services/search';
import {
  ItemsTableProps,
  ColumnProperty,
  SelectedItemsDataProps,
} from './types';
import { FooterContainer, ItemsTableContainer, useTableStyles } from './styled';
import {
  EnhancedBody,
  EnhancedHeader,
  EnhancedFooter as DefaultEnhancedFooter,
} from './components';

const ItemsTable: React.FC<ItemsTableProps> = (props) => {
  const {
    data,
    footerData,
    onFooterAction,
    columns,
    pagination,
    onPaginationChange,
    RenderCustomFooter,
    RenderCustomRowFooter,
    selectedItems,
    onSelectedChange,
    selectableItems = true,
    disableSelection,
    containerHeight,
    customFooterHeight,
    filterRows,
    tableBordered,
    dataQa,
    tableLayoutFixed,
    onScrollNextPage,
    isLoadingData = false,
    showEnhancedTablePaginationActions,
    hideFooter = false,
    showSelectedCount = true,
    sx = {},
  } = props;

  const classes = useTableStyles(props);

  const columnProperties: ColumnProperty[] = useMemo(
    () =>
      _.map(columns, (c) => ({
        title: c.title,
        width: c.width,
        align: c.align,
        fitWidth: !!c.fitWidth,
        sortable: !!c.sortable,
        field: c.field || '',
      })),
    [columns]
  );

  const isFooterVisible = useMemo(
    () => Boolean((pagination || RenderCustomFooter) && !hideFooter),
    // eslint-disable-next-line
    []
  );

  const isRowFooterVisible = useMemo(
    () => Boolean(RenderCustomRowFooter),
    // eslint-disable-next-line
    []
  );

  const EnhancedFooter = useMemo(
    () => RenderCustomFooter || DefaultEnhancedFooter,
    // eslint-disable-next-line
    []
  );

  const isTableEmpty = useMemo(() => {
    const shouldHideDataItem = data.map((i) =>
      filterRows ? !filterRows(i) : false
    );
    return _.every(shouldHideDataItem, Boolean) || _.isEmpty(data);
  }, [data, filterRows]);

  const handleSelectAllClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!onSelectedChange) {
        return;
      }

      if (event.target.getAttribute('data-indeterminate') === 'true') {
        onSelectedChange([]);
        return;
      }

      if (!event.target.checked) {
        onSelectedChange([]);
        return;
      }

      const newSelectedItems = data.map((d) => d.id);
      onSelectedChange(newSelectedItems);
    },
    [data, onSelectedChange]
  );

  const handleSelectItemClick = useCallback(
    (id: number) => {
      if (onSelectedChange) {
        onSelectedChange(id);
      }
    },
    [onSelectedChange]
  );

  const handleSort = useCallback(
    (sort: Sort) => {
      if (onPaginationChange && pagination) {
        onPaginationChange({ ...pagination, sort });
      }
    },
    [pagination, onPaginationChange]
  );

  const { themeSettings } = useThemeTableDensity();

  const handleTableScroll = (event: any) => {
    if (!onScrollNextPage) {
      return;
    }

    const node = event.currentTarget;
    // check if at the end of scroll
    if (Math.ceil(node.scrollTop + node.clientHeight) >= node.scrollHeight) {
      onScrollNextPage();
    }
  };

  const selectedItemsData: SelectedItemsDataProps = useMemo(() => {
    const selectedLength = _.get(selectedItems, 'length', 0);

    return {
      showSelected: selectedLength > 0 && showSelectedCount,
      selected: selectedLength,
      pagination: _.get(pagination, 'totalRows'),
    };
  }, [selectedItems, pagination]);

  const handlePaginationChange = useCallback(
    (newPagination: Pagination) => {
      if (!onPaginationChange) {
        return;
      }
      onPaginationChange(newPagination);
    },
    [onPaginationChange]
  );

  return (
    <ItemsTableContainer
      isFooterVisible={isFooterVisible}
      customFooterHeight={customFooterHeight}
      containerHeight={containerHeight}
      data-testid="item-table"
      style={sx}
    >
      <TableContainer onScroll={handleTableScroll}>
        <Table
          className={clsx({
            redesign: true,
            [classes.tableStyle]: true,
            [classes.emptyTable]: isTableEmpty,
            [classes.layoutFixed]: tableLayoutFixed,
          })}
          stickyHeader
          size={themeSettings.tableDensity}
          data-qa={dataQa}
        >
          <EnhancedHeader
            columnProperties={columnProperties}
            selectableItems={selectableItems}
            rowCount={data.length}
            numSelected={(selectedItems && selectedItems.length) || 0}
            onSelectAllClick={handleSelectAllClick}
            tableBordered={tableBordered}
            disableSelection={disableSelection}
            sort={pagination ? pagination.sort : undefined}
            onSort={handleSort}
            dataQa={dataQa}
          />
          <EnhancedBody
            {...props}
            onSelectItemClick={handleSelectItemClick}
            selectedItems={selectedItems || []}
            isTableEmpty={isTableEmpty}
            tableBordered={tableBordered}
          />
          {isRowFooterVisible && RenderCustomRowFooter}
        </Table>
      </TableContainer>
      {isFooterVisible && (
        <FooterContainer customFooterHeight={customFooterHeight}>
          <EnhancedFooter
            footerData={footerData}
            onFooterAction={onFooterAction}
            pagination={pagination}
            onPaginationChange={handlePaginationChange}
            selectedItemsData={selectedItemsData}
            isLoading={isLoadingData}
            showEnhancedTablePaginationActions={
              showEnhancedTablePaginationActions
            }
          />
        </FooterContainer>
      )}
    </ItemsTableContainer>
  );
};

export default memo(ItemsTable);
