interface Props {
  overflowY?: boolean;
  overflow?: boolean;
  flexGrow?: boolean;
  autoHeight?: boolean;
  noSpacing?: boolean;
  // TODO: theme type needs to be handled at the provider level
  theme: any;
}

const Style = (props: Props) => ({
  display: 'flex',
  ...(!props.autoHeight && {
    height: '100%',
  }),
  ...(props.flexGrow && {
    flexGrow: 1,
  }),
  margin: props.noSpacing ? 0 : props.theme.spacing(2, 1, 2, 1),
  overflow: props.overflow ? 'visible' : props.overflowY ? 'auto' : 'hidden',
});

export default Style;
