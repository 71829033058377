import { transformDateToMomentDate } from 'helpers';

import { Representative } from './types';

export const transformRepresentative = (response: any): Representative => {
  return {
    id: response.id,
    version: response.version,
    dateLastModified: transformDateToMomentDate(response.dateLastModified),
    dateCreated: transformDateToMomentDate(response.dateCreated),
    firstName: response.firstName,
    lastName: response.lastName,
    middleName: response.middleName,
    initials: response.initials,
    deleted: response.deleted,
  };
};

export const transformRequestRepresentative = (
  representative: Representative
): any => {
  const body = {
    firstName: representative.firstName,
    lastName: representative.lastName,
    middleName: representative.middleName,
    initials: representative.initials,
  };

  // POST
  if (!representative.id || representative.id === -1) {
    return body;
  }

  // PUT
  return {
    ...body,
    id: representative.id,
    version: representative.version,
    deleted: representative.deleted,
  };
};
