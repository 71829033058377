import styled from 'styled-components';

export const TransitionContainer = styled('div')<{
  expand: boolean;
  maxHeight: string;
}>(({ expand, maxHeight }) => ({
  overflowY: 'scroll',
  maxHeight: expand ? maxHeight : '0',
  opacity: expand ? 1 : 0,
  transition: 'max-height 0.3s ease, opacity 0.3s ease',
  padding: expand ? '16px' : '0px',
}));
