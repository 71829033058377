import { chainReducers, INITIAL_API_STATUS } from 'services/api';
import {
  createAddSearchReducer,
  createDeleteSearchReducer,
  createFetchSearchReducer,
  createUpdateSearchReducer,
} from 'services/search';
import { combineReducers } from 'redux';

const initialState = {
  _status: INITIAL_API_STATUS,
  items: [],
};

const fetchSearchReducer = createFetchSearchReducer('shipping');
const addSearchReducer = createAddSearchReducer('shipping');
const updateSearchReducer = createUpdateSearchReducer('shipping');
const deleteSearchReducer = createDeleteSearchReducer('shipping');

const searchChainedReducers = chainReducers(
  initialState,
  fetchSearchReducer,
  addSearchReducer,
  updateSearchReducer,
  deleteSearchReducer
);

export default combineReducers({
  searches: searchChainedReducers,
});
