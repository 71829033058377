import { Pagination } from 'services/search';

export enum PaginationDirection {
  Ascending = 'asc',
  Descending = 'desc',
}

/**
 * Enriches `url` with pagination string.
 * @param url
 * @param pagination
 */
export const paginateURL = (url: string, pagination: Pagination): string => {
  const querySymbol = url.includes('?') ? '&' : '?';

  let paginatedUrl = `${url}${querySymbol}pageNumber=${pagination.page}&pageSize=${pagination.pageSize}`;

  if (pagination.sort.sortBy) {
    paginatedUrl = `${paginatedUrl}&orderBy=${
      pagination.sort.sortBy
    }&ascending=${pagination.sort.direction === PaginationDirection.Ascending}`;
  }

  return paginatedUrl;
};

/**
 * Enriches `url` with pagination string. Follows new api standard.
 * @param url
 * @param pagination
 */
export const paginateV2URL = (url: string, pagination: Pagination): string => {
  const querySymbol = url.includes('?') ? '&' : '?';

  let paginatedUrl = `${url}${querySymbol}page.number=${pagination.page}&page.size=${pagination.pageSize}`;

  if (pagination.sort.sortBy) {
    paginatedUrl = `${paginatedUrl}&order.by=${
      pagination.sort.sortBy
    }&order.direction=${
      pagination.sort.direction === PaginationDirection.Ascending
        ? 'ASC'
        : 'DESC'
    }`;
  }

  return paginatedUrl;
};
