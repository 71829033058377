import { InventoryEventFormValues } from './types';

export const MODAL_HEIGHT = 700;
export const MODAL_HEIGHT_NO_TRACKING = 132;

export const defaultInventoryEventFormValues: InventoryEventFormValues = {
  cost: null,
  locationFromId: null,
  locationToId: null,
  notes: null,
  quantity: 0,
  uomId: null,
  amountToRemove: 0,
  materialExpensing: {
    customerId: null,
    accountingClassId: null,
  },
};
