import { PickMethodType } from 'services/settings/picking';

import { RowDescriptor, RowType } from '../Card';

export const pickingCardId = 'picking-card-navigation-id';

export const title = 'Picking';

export const pickingRowDescriptor: RowDescriptor[] = [
  {
    type: RowType.Boolean,
    title: 'Notify if picking expired item',
    field: 'pickingExpiredItemWarning',
    dataQa: 'notify-if-expired-item',
  },
  {
    type: RowType.Boolean,
    title: 'Enable over-picking',
    field: 'enableOverPicking',
    subBooleanRows: [
      {
        type: RowType.Boolean,
        title: 'Notify when over-picking',
        field: 'notifyWhenOverPicking',
        dataQa: 'notify-when-over-picking',
      },
    ],
    dataQa: 'enable-over-picking',
  },
  {
    type: RowType.Autocomplete,
    title: 'Pick method',
    field: 'pickMethodType',
    options: Object.values(PickMethodType),
    dataQa: 'pick-method',
  },
];
