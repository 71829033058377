import { OptionType } from 'ui/components/TextField/MultiFormatInput';
import { PaginationDirection } from 'services/url';
import { Pagination } from 'services/search';

import { Dates, PageSettings } from './types';

export const DOT_CHAR = '\u2022';
export const USCountryString = 'US';

export const defaultPagination: Pagination = {
  page: 1,
  pageSize: 25,
  sort: {
    sortBy: 'dateCreated',
    direction: PaginationDirection.Descending,
  },
  totalRows: 25,
};

export const defaultMaximumPagination: Pagination = {
  ...defaultPagination,
  pageSize: 100,
};

export const initialPageSettings: PageSettings = {
  salesOrder: {
    dateColumn: Dates.DateCreated,
  },
};

export const formatTypes: (symbol: string) => OptionType[] = (symbol) => [
  {
    symbol: '%',
    type: 'suffix',
  },
  {
    symbol,
    type: 'prefix',
  },
];
