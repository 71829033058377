import React from 'react';
import { PermissionType } from 'services/permissions';

import { Pagination } from 'services/search';
import {
  ItemsTable,
  useSelectedItemsChanges,
} from 'ui/components/Table/ItemsTable';

import {
  createActionBarOptions,
  RECEIVING_COLUMNS,
  RECEIVING_COLUMNS_SMALL,
} from './consts';
import ReceivingRow from './ReceivingRow';
import { ReceivingSearchResultsProps } from './types';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';

const ReceivingSearchResults: React.FC<ReceivingSearchResultsProps> = (
  props
) => {
  const {
    receipts,
    activeReceiptId,
    handleReceiptClick,
    isLoadingReceipts,
    pagination,
    onPaginationChange,
    activeDate,
    onPageAction,
    selectedItems,
    setSelectedItems,
    onReceiveClicked,
  } = props;

  const handlePaginationChange = (newPagination: Pagination) => {
    onPaginationChange(newPagination);
  };

  const handleSelectClick = useSelectedItemsChanges(
    selectedItems,
    setSelectedItems
  );

  const alwaysOnColumns = RECEIVING_COLUMNS_SMALL;

  return (
    <>
      <FBOTitleBar title="Receiving">
        {selectedItems.length > 0 && !activeReceiptId && (
          <FBOButton
            style={{ marginRight: '8px' }}
            variant="secondary"
            color="negative"
            size="medium"
            permissions={[PermissionType.ReceivingItemReceive]}
            onClick={onReceiveClicked}
            data-qa="receivingSearchResults-voidbtn"
          >
            Void
          </FBOButton>
        )}
        <FBOMenuButton
          variant="secondary"
          items={createActionBarOptions(onPageAction)}
          data-qa="receiving-three-dot-menu"
        />
      </FBOTitleBar>

      <ItemsTable
        data={receipts}
        columns={
          activeReceiptId ? alwaysOnColumns : RECEIVING_COLUMNS(activeDate)
        }
        activeId={activeReceiptId}
        onItemClick={handleReceiptClick}
        RenderCustomRow={ReceivingRow}
        selectableItems={!activeReceiptId}
        onSelectedChange={handleSelectClick}
        isLoadingData={isLoadingReceipts}
        pagination={pagination}
        selectedItems={selectedItems}
        onPaginationChange={handlePaginationChange}
        meta={{ detailsCardOpened: !!activeReceiptId }}
        dataQa="receiving-table"
        emptyTableText="NO RECEIPTS AVAILABLE"
        showEnhancedTablePaginationActions={!Boolean(activeReceiptId)}
      />
    </>
  );
};

export default ReceivingSearchResults;
