import React, { memo, useState, useEffect, useMemo, useCallback } from 'react';
import { Box } from '@mui/material';
import _ from 'lodash';

import { Modal } from 'ui/components/Modal/Modal';
import { showNotification } from 'services/api/notifications';
import { TrackingTableTypes } from 'ui/components/Table/TrackingTable/types';
import { Errors } from 'services/forms/validation';
import { resolveSerialTableErrors } from 'ui/components/Table/SerialTable/helpers';

import {
  generateSerialTableEditProps,
  generateEditableTableStatus,
  generateTableStatus,
} from './helpers';
import { useSerialModalStyle } from './styled';
import { SerialModalProps } from './types';
import SerialTable from 'ui/components/Table/SerialTable/SerialTable';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { CustomStatus } from 'ui/theme/components/FBOTitleBar/FBOTitleBar';

const SerialModal: React.FC<SerialModalProps> = ({
  visible,
  tableType,
  itemTrackingTypes,
  quantity,
  serialList,
  allowSerialNumberImport = false,
  onCloseClicked,
  onApplyClicked,
  disableAutoAssign = false,
  meta: { trackingGroup, eventType } = {},
}) => {
  const classes = useSerialModalStyle();

  const [formValues, setFormValues] = useState(serialList);
  const [selectedRows, setSelectedRows] = useState<number[]>(
    trackingGroup?.serialIds || []
  );
  const [customStatus, setCustomStatus] = useState<string>('');
  const [errors, setErrors] = useState<Errors[]>([]);
  const [loadingContent, setLoadingContent] = useState<boolean>(false);
  const [initialSelectedSerialRows, setInitialSelectedSerialRows] = useState<
    number[]
  >([]);
  useEffect(() => {
    setFormValues(serialList);
  }, [serialList]);

  const isViewTable = useMemo(
    () =>
      tableType === TrackingTableTypes.View ||
      tableType === TrackingTableTypes.ReceivingView,
    [tableType]
  );
  const serialTableEditProps = useMemo(
    () => generateSerialTableEditProps(tableType),
    [tableType]
  );

  const customStatusStyle = useMemo(
    () =>
      customStatus === 'Success' ? classes.chipSuccess : classes.chipWarning,
    [classes, customStatus]
  );

  useEffect(() => {
    if (!quantity) {
      return;
    }

    const newStatus = serialTableEditProps.canEditRow
      ? generateEditableTableStatus(quantity - serialList.length)
      : generateTableStatus(quantity - selectedRows.length);
    setCustomStatus(newStatus);
  }, [quantity, selectedRows, serialList, serialTableEditProps]);

  const handleCancelClicked = useCallback(() => {
    onCloseClicked();
    setErrors([]);
    setFormValues(serialList);
    setSelectedRows(initialSelectedSerialRows);
  }, [serialList, onCloseClicked, selectedRows, initialSelectedSerialRows]);

  const handleResetClicked = useCallback(() => {
    setFormValues(serialList);
    setErrors([]);
  }, [serialList]);

  const handleApplyClicked = useCallback(() => {
    // Validate right number of rows
    if (serialTableEditProps.canSelectRow && quantity !== selectedRows.length) {
      showNotification('Please select the right amount of serial rows!', {
        variant: 'warning',
      });
      return;
    }

    if (serialTableEditProps.canEditRow && quantity !== formValues.length) {
      showNotification(`Please add the right amount of serial rows!`, {
        variant: 'warning',
      });
      return;
    }

    const errors = resolveSerialTableErrors(formValues);
    setErrors(errors);
    // Validate that all serials are not empty
    const isValid = errors.every((e) => _.isEmpty(e));

    // Cancel if not valid
    if (!isValid) {
      return;
    }

    onApplyClicked(formValues, selectedRows);
  }, [
    serialTableEditProps,
    quantity,
    selectedRows,
    onApplyClicked,
    formValues,
  ]);
  const handleSelectedRowChanged = useCallback((newRowIds: number[]) => {
    setSelectedRows(newRowIds);
  }, []);
  const statusCustom = useMemo(() => {
    if (customStatus === 'Success') {
      return customStatus;
    } else if (quantity - selectedRows.length > 0) {
      return `Select ${quantity - selectedRows.length} rows`;
    } else {
      return `Remove ${selectedRows.length - quantity} rows`;
    }
  }, [customStatus, quantity, selectedRows.length]);

  const FBOModalActions = useCallback(() => {
    const isSaveDisabled =
      (serialTableEditProps.canSelectRow && quantity !== selectedRows.length) ||
      (serialTableEditProps.canEditRow && quantity !== formValues.length);

    return (
      <Box display="flex" justifyContent="space-between" width="100%">
        {!isViewTable && (
          <Box>
            {customStatus && (
              <Box sx={{ width: '100%' }}>
                <CustomStatus
                  statusCustom={
                    customStatus === 'Success'
                      ? customStatus
                      : 'Partial selected'
                  }
                  statusCustomStyle={customStatusStyle}
                  combineStatusLabels={true}
                  label={statusCustom}
                  classes={classes}
                />
              </Box>
            )}
          </Box>
        )}
        <Box display="flex">
          <Box mr={2}>
            <FBOButton
              variant="secondary"
              color="positive"
              size="medium"
              onClick={handleCancelClicked}
              data-qa="serial-modal-cancel-button"
            >
              Cancel
            </FBOButton>
          </Box>
          {!isViewTable && (
            <Box>
              <FBOButton
                variant="primary"
                color="positive"
                size="medium"
                onClick={handleApplyClicked}
                data-qa="serial-modal-save-button"
                disabled={isSaveDisabled}
              >
                Save
              </FBOButton>
            </Box>
          )}
        </Box>
      </Box>
    );
  }, [
    isViewTable,
    handleCancelClicked,
    handleApplyClicked,
    handleResetClicked,
    serialTableEditProps,
    quantity,
    selectedRows,
    formValues.length,
    customStatus,
  ]);

  return (
    <Modal
      className="redesign inventory-event-modal"
      open={visible}
      TitleBarComponent={() => null}
      withoutDefaultPadding
      isLoadingContent={loadingContent}
      nestedScrollAreas
      ModalActionsComponent={FBOModalActions}
      dataQa="serial"
    >
      <SerialTable
        serialList={formValues}
        setIsLoading={setLoadingContent}
        isLoading={loadingContent}
        setSerialList={setFormValues}
        minSerialQuantity={quantity}
        itemTrackingTypes={itemTrackingTypes}
        selectedRows={selectedRows}
        onSelectedRowsChanged={handleSelectedRowChanged}
        customStatus={customStatus}
        disableAutoAssign={disableAutoAssign || isViewTable}
        customStatusStyle={customStatusStyle}
        allowSerialNumberImport={allowSerialNumberImport}
        rowErrors={errors}
        {...serialTableEditProps}
        meta={{
          setInitialSelectedSerialRows: setInitialSelectedSerialRows,
          resetFlag: true,
        }}
        eventType={eventType}
      />
    </Modal>
  );
};

export default memo(SerialModal);
