import React, { useState, useEffect, useCallback, useRef, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Tabs, Tab } from '@mui/material';

import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { TabPanel } from 'ui/components/TabPanel';
import { DetailsCard } from 'ui/components/Page/DetailsCard';
import { customFieldsYupSchema } from 'ui/components/CustomFields/CustomFields';
import {
  Vendor,
  fetchVendorById,
  initialVendor,
  createVendor,
  updateVendor,
  deleteVendor,
  fetchVendors,
  deleteVendorAddress,
  restoreVendor,
} from 'services/vendors';
import { getSettingsCompany } from 'services/settings/company/redux';
import { Errors, validateYup } from 'services/forms/validation';
import { useUrlQueryObject } from 'services/url';
import { ReportId } from 'services/reports';
import { deleteDocuments } from 'services/documents/api';
import { showNotification } from 'services/api';
import { ReportsModal } from 'ui/components/Modal/ReportsModal';

import { VendorDetailsCardProps } from './types';
import { AddressesTab, ItemsTab, VendorDocumentsTab } from './components';
import { yupVendorGeneralSchema } from './validations';
import { VendorTitleBar } from '../VendorTitleBar';
import { getErrorMessage } from 'helpers';
import { logErrorCtx } from 'app/logging';
import FBOGeneralTab from './components/GeneralTab/FBOGeneralTab';
import { useLocation, useNavigate } from 'react-router-dom';

const VendorDetailsCard: React.FC<VendorDetailsCardProps> = (props) => {
  const { activeVendorId, fetchSearchResult, onClose } = props;

  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const [, setQueryParams] = useUrlQueryObject(navigate, location);

  const companySettings = useSelector(getSettingsCompany);

  const [activeVendor, setActiveVendor] = useState<Vendor>(initialVendor);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState<Errors>({});
  const [customFieldsErrors, setCustomFieldsErrors] = useState<Errors>({});
  const [activeTab, setActiveTab] = useState(0);
  const [showReportModal, setShowReportModal] = useState(false);
  const [refreshVendorItems, setRefreshVendorItems] = useState(false);
  const oldState = useRef<Vendor | null>(initialVendor);

  const handleDeleteModalVisible = (visible: boolean) => () =>
    setDeleteModalVisible(visible);

  useEffect(() => {
    const asyncFc = async (vendorId: number) => {
      setIsLoading(true);

      try {
        const vendor = await fetchVendorById(vendorId);
        oldState.current = vendor;
        setActiveVendor(vendor);
      } catch (err) {
        const message = getErrorMessage(err);
        logErrorCtx('Error in fetchVendorById', {
          error: err as Error,
          component: 'VendorDetailsCard',
          description: `Vendor id ${vendorId}`,
        });
        showNotification(`${message} - vendor detail couldn't be loaded.`, {
          variant: 'error',
        });
        setIsLoading(false);
        onClose();

        return;
      }

      setIsLoading(false);
    };

    if (!activeVendorId || activeVendorId === -1) {
      const newVendor: Vendor = {
        ...initialVendor,
        id: activeVendorId,
        paymentTermId: companySettings.defaultPaymentTermId,
      };
      oldState.current = newVendor;
      setActiveVendor(newVendor);
    } else {
      asyncFc(activeVendorId);
    }

    setActiveTab(0);
    setValidationErrors({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeVendorId]);

  const saveVendorClicked = useCallback(
    (close: boolean = false) =>
      async () => {
        const isValid = validateYup(
          activeVendor,
          yupVendorGeneralSchema,
          setValidationErrors
        );

        const isCustomFieldsValid = validateYup(
          activeVendor.customFields,
          customFieldsYupSchema,
          setCustomFieldsErrors
        );

        if (!isValid || !isCustomFieldsValid) {
          setActiveTab(0);
          return false;
        }

        setIsLoading(true);

        const notDeletedDocuments = activeVendor.documents.filter(
          (d) => !d.deleted
        );
        const deletedDocuments = activeVendor.documents.filter(
          (d) => d.deleted
        );
        const resolvedVendor = {
          ...activeVendor,
          documents: notDeletedDocuments,
        };

        // Create new Vendor
        if (!activeVendor.id || activeVendor.id === -1) {
          try {
            await deleteDocuments(deletedDocuments);
          } catch (e) {
            const error = e as Error;
            logErrorCtx('Error deleting documents', {
              error: error,
              title: error.message,
              component: 'VendorDetailsCard',
            });
          }

          try {
            const newActiveVendor = await createVendor(resolvedVendor);
            oldState.current = newActiveVendor;
            setActiveVendor(newActiveVendor);
            await fetchSearchResult();
            setQueryParams({ activeId: newActiveVendor.id });
            if (close) {
              setQueryParams({ activeId: null });
              onClose();
              return true;
            }
          } catch {
            setIsLoading(false);
            return false;
          }
          setIsLoading(false);
          return true;
        }

        // Update Vendor
        try {
          await deleteDocuments(deletedDocuments);
        } catch (e) {
          const error = e as Error;
          logErrorCtx('Failed while updating Vendor.', {
            error,
            stackTrace: error.stack,
            title: 'Failed to update Vendor on save.',
            description: 'Failed to update Vendor in Purchasing.',
            component: 'VendorsPage -> VendorDetailsCard',
          });
        }

        try {
          const newActiveVendor = await updateVendor(resolvedVendor);
          oldState.current = newActiveVendor;
          setActiveVendor(newActiveVendor);
          await fetchSearchResult();
          setRefreshVendorItems(!refreshVendorItems);
        } catch {
          setIsLoading(false);
          return false;
        }

        // Lint skip to be removed
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(fetchVendors);
        if (close) {
          onClose();
        }
        setIsLoading(false);
        return true;
      },
    [activeVendor, dispatch, fetchSearchResult, onClose, setQueryParams]
  );

  const deleteVendorClicked = useCallback(async () => {
    setIsLoading(true);
    const addressesIds = activeVendor.addresses
      .filter((i) => i.id && i.id > 0)
      .map((i) => i.id!);

    if (addressesIds.length) {
      try {
        await deleteVendorAddress(activeVendor.id!, addressesIds);
      } catch {
        return;
      }
    }

    try {
      await deleteVendor(activeVendor.id!);
      await fetchSearchResult();

      setDeleteModalVisible(false);
      onClose();
    } catch {
      // continue regardless of error
    }
    setIsLoading(false);
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchVendors);
  }, [activeVendor, fetchSearchResult, onClose, dispatch]);

  const handleUndeleteClicked = useCallback(
    async (close: boolean = false) => {
      setIsLoading(true);

      try {
        await restoreVendor(activeVendorId!);
        const restoredVendor = await fetchVendorById(activeVendorId!);
        oldState.current = restoredVendor;
        setActiveVendor(restoredVendor);
      } catch {
        setIsLoading(false);
        return false;
      }

      if (close) {
        onClose();
      }

      setIsLoading(false);
      fetchSearchResult();
      return true;
    },

    [fetchSearchResult, onClose, activeVendorId]
  );

  const handleActiveTabChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      setActiveTab(newValue);
    },
    []
  );
  return (
    <DetailsCard
      isLoading={isLoading}
      onSubmit={saveVendorClicked(false)}
      state={activeVendor}
      oldState={oldState}
    >
      <VendorTitleBar
        activeVendor={activeVendor}
        onSave={saveVendorClicked()}
        onClose={onClose}
        deleteModalVisible={handleDeleteModalVisible(true)}
        showReportModal={() => setShowReportModal(true)}
        onUndeleteClicked={handleUndeleteClicked}
      />

      <Tabs
        value={activeTab}
        onChange={handleActiveTabChange}
        indicatorColor="primary"
        className="redesign"
      >
        <Tab label="General" />
        <Tab label="Addresses" data-qa="vendor-addresses-tab" />
        <Tab label="Items" data-qa="vendor-items-tab" />
        <Tab label="Documents" />
      </Tabs>

      <TabPanel value={activeTab} index={0} noSpacing>
        <FBOGeneralTab
          activeVendor={activeVendor}
          setActiveVendor={setActiveVendor}
          validationErrors={validationErrors}
          customFieldsErrors={customFieldsErrors}
          oldState={oldState}
        />
      </TabPanel>

      <TabPanel value={activeTab} index={1} noSpacing flexGrow>
        <AddressesTab
          activeVendor={activeVendor}
          setActiveVendor={setActiveVendor}
        />
      </TabPanel>

      <TabPanel value={activeTab} index={2} noSpacing flexGrow>
        <ItemsTab
          activeVendor={activeVendor}
          setActiveVendor={setActiveVendor}
          refreshVendorItems={refreshVendorItems}
          oldState={oldState}
        />
      </TabPanel>

      <TabPanel value={activeTab} index={3} noSpacing flexGrow>
        <VendorDocumentsTab
          activeVendor={activeVendor}
          setActiveVendor={setActiveVendor}
        />
      </TabPanel>

      <ConfirmationModal
        open={deleteModalVisible}
        title="Delete Vendor"
        body={`This will delete '${_.get(
          activeVendor,
          'name',
          'Vendor'
        )}', are you sure?`}
        onCancelClicked={handleDeleteModalVisible(false)}
        onConfirmClicked={deleteVendorClicked}
        confirmLabel="Delete"
        cancelLabel="Cancel"
        confirmButtonRed
      />
      <ReportsModal
        isOpen={showReportModal}
        reportId={ReportId.PurchaseOrderSummary}
        params={{ vendorId: activeVendorId }}
        onClose={() => setShowReportModal(false)}
        autoGenerate
      />
    </DetailsCard>
  );
};

export default memo(VendorDetailsCard);
