import React, { useCallback, useEffect, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { TabPanel } from 'ui/components/TabPanel';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { FBODetailCardReadOnly } from 'ui/theme/components/FBODetailsCardReadOnly';
import { GeneralTab } from './components/GeneralTab';
import { InvoiceDetails, getInvoiceDetails } from 'services/invoices';
import { logErrorCtx } from 'app/logging';
import { initialInvoiceDetails } from './consts';
import { TransactionsTab } from './components/TransactionsTab';
import InvoiceDetailsItems from './components/InvoiceDetailsItems/InvoiceDetailsItems';
import { getSONumber } from './helpers';
import { PaymentsTab } from './components/PaymentsTab';

interface InvoiceDetailsCardProps {
  activeId: null | number;
  onClose(): void;
}

const InvoiceDetailsCard: React.FC<InvoiceDetailsCardProps> = (props) => {
  const { activeId, onClose } = props;
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState<InvoiceDetails>(
    initialInvoiceDetails
  );

  useEffect(() => {
    if (activeId && activeId > 0) {
      (async () => {
        setIsLoading(true);
        try {
          const res = await getInvoiceDetails(activeId);
          setInvoiceDetails(res);
        } catch (e) {
          logErrorCtx('Error in getInvoiceDetails', {
            error: e as Error,
            component: 'InvoiceDetailCard',
          });
        }
        setIsLoading(false);
      })();

      return;
    }

    setActiveTab(0);
  }, [activeId]);

  const handleActiveTabChange = useCallback(
    async (event: React.ChangeEvent<{}>, newValue: number) => {
      setActiveTab(newValue);
    },
    []
  );

  return (
    <FBODetailCardReadOnly isLoading={isLoading}>
      <FBOTitleBar
        title={`Invoice ${getSONumber(invoiceDetails?.number)} - ${
          invoiceDetails?.customer?.name
        }`}
      >
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="tertiary"
          color="neutral"
          size="medium"
          icon="FBOClose"
          data-qa="invoice-close"
          onClick={onClose}
        />
      </FBOTitleBar>

      <Tabs
        value={activeTab}
        onChange={handleActiveTabChange}
        indicatorColor="primary"
        className={'redesign'}
      >
        <Tab label="General" />
        <Tab label="Transactions" />
        <Tab label="Payments" />
      </Tabs>
      <TabPanel value={activeTab} index={0} flexGrow noSpacing>
        <Box display="flex" flexDirection="column" width="100%" flexGrow={1}>
          <GeneralTab invoiceDetails={invoiceDetails} />
          <InvoiceDetailsItems invoiceDetails={invoiceDetails} />
        </Box>
      </TabPanel>
      <TabPanel value={activeTab} index={1} flexGrow noSpacing>
        <TransactionsTab invoiceDetails={invoiceDetails} />
      </TabPanel>
      <TabPanel value={activeTab} index={2} flexGrow noSpacing>
        <PaymentsTab invoiceDetails={invoiceDetails} />
      </TabPanel>
    </FBODetailCardReadOnly>
  );
};

export default InvoiceDetailsCard;
