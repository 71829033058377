import _ from 'lodash';
import moment from 'moment';

import {
  getSearches,
  addSearch,
  updateSearch,
  deleteSearch,
} from 'services/shipping';
import {
  AdvancedSearchFormValues,
  AdvancedSearchReduxFunctions,
} from 'services/search';
import { PaginationDirection } from 'services/url';
import { Location } from 'services/locations';
import { Customer } from 'services/customers';
import { Vendor } from 'services/vendors';
import { SaleItem } from 'services/items/saleItems';
import { Carrier } from 'services/carriers';

export const advancedSearchReduxActions: AdvancedSearchReduxFunctions = {
  add: addSearch,
  delete: deleteSearch,
  update: updateSearch,
  get: getSearches,
};

export const initialShippingFormValues: AdvancedSearchFormValues = {
  number: null,
  status: null,
  orderPriorityId: null,
  customer: null,
  parentLocationId: null,
  orderType: null,
  dateShipFrom: null,
  dateShipTo: null,
  carrierId: null,
  carrierServiceId: null,
  'salesOrder.number': null,
  'salesOrder.customerId': null,
  'purchaseOrder.vendorId': null,
  'carrierService.name': null,
  'shipCartons.trackingNumber': null,
  'shipItems.salesOrderItem.description': null,
  'shipItems.salesOrderItem.saleItemId': null,
};

export const displayNameMap = {
  number: 'Ship',
  orderPriorityId: 'Priority',
  status: 'Statuses',
  carrierId: 'Carrier',
  parentLocationId: 'Location',
  orderType: 'Order type',
  carrierServiceId: 'Carrier Service',
  dateShipFrom: 'Date Shipped After',
  dateShipTo: 'Date Shipped Before',
  'salesOrder.number': 'Order number',
  'salesOrder.customerId': 'Customer',
  'purchaseOrder.vendorId': 'Vendor',
  'carrierService.name': 'Carrier service name',
  'shipCartons.trackingNumber': 'Package tracking number',
  'shipItems.salesOrderItem.description': 'Sale item description',
  'shipItems.salesOrderItem.saleItemId': 'Sale Item',
};

export const createDisplayValueMap = (
  locations: Location[],
  customers: Customer[],
  vendors: Vendor[],
  carriers: Carrier[],
  saleItems: SaleItem | null,
  format: string
) => {
  let carrier: Carrier | null = null;

  return {
    orderPriorityId: () => null,
    status: (status: string) => status.split(',').join(', '),
    parentLocationId: (id: number) =>
      _.get(
        locations.find((l) => l.id === id),
        'name',
        null
      ),
    carrierId: (id: number) => {
      carrier = carriers.find((c) => c.id === id) || null;
      return _.get(carrier, 'name', null);
    },
    carrierServiceId: (id: number) => {
      const carrierServices = carrier ? carrier.carrierServiceList : [];
      return _.get(
        carrierServices.find((s) => s.id === id),
        'name',
        null
      );
    },
    'salesOrder.customerId': (id: number) =>
      _.get(
        customers.find((c) => c.id === id),
        'name',
        null
      ),
    'purchaseOrder.vendorId': (id: number) =>
      _.get(
        vendors.find((v) => v.id === id),
        'name',
        null
      ),
    'shipItems.salesOrderItem.saleItemId': () =>
      saleItems ? saleItems.name : '-',
    dateShipFrom: (date: string) => (date ? moment(date).format(format) : ''),
    dateShipTo: (date: string) => (date ? moment(date).format(format) : ''),
  };
};

export const initialPagination = {
  page: 1,
  pageSize: 25,
  sort: {
    sortBy: 'number',
    direction: PaginationDirection.Descending,
  },
  totalRows: 25,
};
