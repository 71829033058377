import { Dates } from 'helpers';
import { initialAddress } from 'services/addresses';
import { ColumnTypes } from 'ui/components/Table/ItemsTable';
import { Column } from 'ui/components/Table/ItemsTable/types';

import { Location } from './types';

export enum LocationType {
  Stock = 'Stock',
  Shipping = 'Shipping',
  Receiving = 'Receiving',
  Locked = 'Locked',
}

export const COMPANY_WIDE_ID = 1;

export const LOCATION_COLUMNS = (date: Dates): Column[] => {
  const resolveDateColumn = (): Column => {
    switch (date) {
      case Dates.DateLastModified:
        return {
          title: 'Date Last Modified',
          field: Dates.DateLastModified,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
        };
      default:
        return {
          title: 'Date Created',
          field: Dates.DateCreated,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
        };
    }
  };

  return [
    {
      title: 'Name',
      field: 'name',
      sortable: true,
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: 'Description',
      field: 'description',
      sortable: true,
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: 'Path',
      field: 'path',
      sortable: true,
      type: ColumnTypes.renderByCustomRow,
    },

    resolveDateColumn(),
  ];
};

export const initialLocation: Location = {
  id: null,
  address: initialAddress,
  addressId: null,
  companyWide: null,
  dateCreated: null,
  dateLastModified: null,
  deleted: false,
  depth: null,
  description: null,
  displayName: null,
  errors: null,
  heritage: null,
  name: null,
  parentLocation: null,
  parentLocationId: null,
  rootLocationId: null,
  defaultReceivingLocation: null,
  defaultShippingLocation: null,
  path: null,
  sortNumber: null,
  totalLocationQty: null,
  type: LocationType.Stock,
  useParentAddress: false,
  version: null,
  locked: false,
};
