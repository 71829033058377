import React, { memo } from 'react';

import { Box } from '@mui/material';

import { XeroDialogActionsProps } from './types';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const XeroDialogActions: React.FC<XeroDialogActionsProps> = ({
  closeConnectModal,
  xeroConnectClicked,
}) => {
  return (
    <Box display="flex" justifyContent="flex-end" width="100%">
      <FBOButton
        variant="secondary"
        color="positive"
        data-qa="cancel-button"
        onClick={closeConnectModal}
        sx={{ marginRight: '16px' }}
      >
        Cancel
      </FBOButton>
      <FBOButton
        variant="primary"
        color="positive"
        data-qa="connect-to-xero-button"
        onClick={xeroConnectClicked}
        sx={{ marginRight: '8px' }}
      >
        Connect to Xero
      </FBOButton>
    </Box>
  );
};

export default memo(XeroDialogActions);
