import React, { memo, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { Grid } from '@mui/material';

import { Errors, validateYup } from 'services/forms/validation';
import {
  initialItem,
  Item,
  ItemType,
  postItem,
  transformToDefaultSaleItem,
} from 'services/items';
import { getUoms, Uom } from 'services/uoms';
import { useHandleTextFieldChange } from 'services/forms';
import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';
import { getSettingsCompany } from 'services/settings/company';
import { getSettingsSalesOrder } from 'services/settings/salesOrders';

import { Autocomplete } from '../Autocomplete';

const yupSchema = yup.object().shape({
  name: yup.string(),
  defaultUomId: yup.number(),
  type: yup.string(),
  cost: yup.number(),
  price: yup.number(),
});

const NewItemModal: React.FC<any> = (props) => {
  const { show, newItemName, itemTypes, onClose, onSave } = props;

  const { items: uoms } = useSelector(getUoms);
  const companySettings = useSelector(getSettingsCompany);
  const soSettings = useSelector(getSettingsSalesOrder);

  const [formItem, setFormItem] = useState<Item>(initialItem);
  const [modalLoading, setModalLoading] = useState(false);
  const [errors, setErrors] = useState<Errors>({});
  const [price, setPrice] = useState<number | null>(0);

  const selectedUom = useMemo(
    () => uoms.find((u) => u.id === formItem.defaultUomId),
    [uoms, formItem.defaultUomId]
  );

  const itemTypeOptions = useMemo(() => {
    if (itemTypes && itemTypes.length) {
      return itemTypes;
    }

    return Object.values(ItemType);
  }, [itemTypes]);

  useEffect(() => {
    setFormItem({
      ...initialItem,
      name: newItemName,
      itemType: itemTypeOptions[0],
    });
    setErrors({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const handleApplyClicked = async () => {
    if (!validateYup({ ...formItem, price }, yupSchema, setErrors)) {
      return;
    }

    setModalLoading(true);

    const defaultSaleItem = {
      ...transformToDefaultSaleItem(formItem, soSettings, companySettings),
      price,
    };

    const formItemWithDefaultSaleItem: Item = {
      ...formItem,
      saleItemList: [defaultSaleItem],
    };

    try {
      const newItem = await postItem(formItemWithDefaultSaleItem);
      const saleItem =
        newItem.saleItemList.find((si) => si.defaultFlag) || newItem;
      onSave(saleItem);
    } catch {
      // do nothing
    }

    setModalLoading(false);
  };

  const handleTextFieldChange = useHandleTextFieldChange(setFormItem, formItem);

  const handleTypeChange = (e: any, itemType: ItemType | null) =>
    setFormItem((old) => ({ ...old, itemType }));

  const handleUomChange = (e: any, uom: Uom | null) =>
    setFormItem((old) => ({
      ...old,
      defaultUomId: uom ? uom.id : null,
    }));

  const handlePriceChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value ? parseFloat(e.target.value) : null;
    setPrice(value);
  };

  return (
    <Modal
      open={show}
      title="New Item"
      onCancelClicked={onClose}
      onApplyClicked={handleApplyClicked}
      applyLabel="Save"
      isLoadingContent={modalLoading}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            className="redesign"
            variant="standard"
            name="name"
            label="Name"
            placeholder="Name"
            value={formItem.name}
            onChange={handleTextFieldChange}
            required
            error={!!errors.name}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            options={itemTypeOptions}
            name="itemType"
            label="Type"
            value={formItem.itemType}
            onChange={handleTypeChange}
            required
            error={!!errors.itemType}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            options={uoms}
            name="defaultUomId"
            label="UOM"
            getOptionLabel={(uom: Uom) => uom.name || ''}
            value={selectedUom}
            onChange={handleUomChange}
            required
            placeholder="Select UOM"
            error={!!errors.defaultUom}
          />
        </Grid>
        <Grid item xs={3}>
          <CurrencyField
            label="Cost"
            placeholder="Cost"
            name="cost"
            value={formItem.cost}
            onChange={handleTextFieldChange}
            allowNegative
            required
            error={!!errors.cost}
          />
        </Grid>
        <Grid item xs={3}>
          <CurrencyField
            label="Price"
            placeholder="Price"
            value={price}
            onChange={handlePriceChange}
            allowNegative
            required
            error={!!errors.price}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default memo(NewItemModal);
