import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { apiMiddleware } from 'redux-api-middleware';
import thunk from 'redux-thunk';

import { reducer as authReducer, AuthActionTypes } from 'services/auth';
import { reducer as usersReducer } from 'services/user';
import { reducer as usersV2Reducer } from 'services/userV2';
import { reducer as itemsReducer } from 'services/items';
import { reducer as inventoryReducer } from 'services/inventory/redux';
import { reducer as uomsReducer } from 'services/uoms';
import { reducer as salesOrdersReducer } from 'services/salesOrders';
import { reducer as purchaseOrderReducer } from 'services/purchaseOrders';
import { reducer as locationsReducer } from 'services/locations';
import { reducer as paymentTermsReducer } from 'services/paymentTerms';
import { reducer as representativesReducer } from 'services/representatives';
import { reducer as taxRatesReducer } from 'services/taxRates';
import { reducer as vendorsReducer } from 'services/vendors';
import { reducer as customersReducer } from 'services/customers';
import { reducer as carriersReducer } from 'services/carriers';
import { reducer as currenciesReducer } from 'services/currencies';
import { reducer as pickingReducer } from 'services/picking';
import { reducer as shippingReducer } from 'services/shipping';
import { reducer as orderPrioritiesReducer } from 'services/settings/orderPriorities';
import { reducer as trackingReducer } from 'services/settings/tracking';
import { reducer as receivingReducer } from 'services/receiving';
import { reducer as moduleNavigationReducer } from 'services/moduleNavigation';
import { reducer as tagReducer } from 'services/tags';
import { reducer as ledgerReducer } from 'services/ledgers';
import { reducer as settingsReducer } from 'services/settings';
import { reducer as integrationsReducer } from 'services/integrations';
import { reducer as pricingRulesReducer } from 'services/pricingRules';
import { reducer as permissionGroupsReducer } from 'services/permissions';
import { reducer as classesReducer } from 'services/classes';
import { reducer as alertReducer } from 'services/alert';
import { reducer as thumbnailReducer } from 'services/thumbnail';
import { reducer as commerceReducer } from 'services/commerce';

import { AppStore, State } from './types';

export const appReducer = combineReducers<State>({
  auth: authReducer,
  user: usersReducer,
  userV2: usersV2Reducer,
  item: itemsReducer,
  inventory: inventoryReducer,
  uom: uomsReducer,
  salesOrder: salesOrdersReducer,
  location: locationsReducer,
  paymentTerms: paymentTermsReducer,
  representative: representativesReducer,
  taxRate: taxRatesReducer,
  vendor: vendorsReducer,
  customers: customersReducer,
  carrier: carriersReducer,
  currency: currenciesReducer,
  picking: pickingReducer,
  shipping: shippingReducer,
  orderPriority: orderPrioritiesReducer,
  tracking: trackingReducer,
  purchaseOrder: purchaseOrderReducer,
  receiving: receivingReducer,
  tag: tagReducer,
  moduleNavigation: moduleNavigationReducer,
  ledger: ledgerReducer,
  settings: settingsReducer,
  integrations: integrationsReducer,
  pricingRules: pricingRulesReducer,
  permissionGroups: permissionGroupsReducer,
  classes: classesReducer,
  alert: alertReducer,
  thumbnail: thumbnailReducer,
  commerce: commerceReducer,
});

const rootReducer = (state: State, action: any) => {
  if (action.type === AuthActionTypes.SIGN_OUT) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const middlewares = [thunk, apiMiddleware];

function getCompose() {
  const IS_BROWSER = typeof window !== 'undefined' && 'HTMLElement' in window;
  if (IS_BROWSER && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    return (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  }
  return compose;
}

const composeEnhancers = getCompose();

export const store: AppStore = createStore(
  rootReducer as any,
  composeEnhancers(applyMiddleware(...middlewares))
);

// added for unit tests
export type RootState = ReturnType<typeof rootReducer>;
export const setupStore = () => {
  return configureStore({
    reducer: appReducer,
  });
};
