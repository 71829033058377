import { copyImages } from 'services/images/api';
import { Uom } from 'services/uoms';
import { ItemImage } from '.';
import { Item, ItemUomConversion } from './types';

const copyItemImages = async (
  itemImages: ItemImage[]
): Promise<ItemImage[]> => {
  const copiedImages = await copyImages(itemImages.map((i) => i.url!));

  return itemImages.map((i, index) => ({
    ...i,
    url: copiedImages[index],
  }));
};

/**
 * Copy sale item images which have the same url as item image
 *
 * @param item
 */
export const copySaleItemsImages = async (item: Item): Promise<Item> => {
  const itemImages = item.imageLinkList.map((i) => i.url!);

  const saleItems = item.saleItemList.map(async (i) => {
    const imagesToCopy = i.imageLinkList.filter((img) =>
      itemImages.includes(img.url!)
    );
    const otherImages = i.imageLinkList.filter(
      (img) => !itemImages.includes(img.url!)
    );

    if (imagesToCopy.length) {
      const copiedImages = await copyItemImages(imagesToCopy);
      return { ...i, imageLinkList: copiedImages.concat(otherImages) };
    }

    return i;
  });

  const resolvedSaleItems = await Promise.all(saleItems);

  return { ...item, saleItemList: resolvedSaleItems };
};

export const getItemRoute = (id: number | null) => {
  return `/materials/items?advancedSearch%5Bid%5D=${id}&activeId=${id}`;
};

export const resolveItemUomConversions = (
  uom: Uom,
  itemUomConversions: ItemUomConversion[]
) => {
  const fromConversions: ItemUomConversion[] = uom.fromConversions.map(
    (fromConversion) => ({
      id: null,
      deleted: false,
      version: null,
      factor: fromConversion.factor,
      originalFactor: fromConversion.factor,
      uomConversionId: fromConversion.id,
      fromUomId: fromConversion.fromUomId,
      toUomId: fromConversion.toUomId,
    })
  );

  const toConversions: ItemUomConversion[] = uom.toConversions.map(
    (toConversion) => ({
      id: null,
      deleted: false,
      version: null,
      factor: toConversion.factor,
      originalFactor: toConversion.factor,
      uomConversionId: toConversion.id,
      fromUomId: toConversion.fromUomId,
      toUomId: toConversion.toUomId,
    })
  );

  const allConversions = [...fromConversions, ...toConversions];

  itemUomConversions.forEach((itemUomConversion) => {
    const conversion = allConversions.find(
      (c) => c.uomConversionId === itemUomConversion.uomConversionId
    );

    if (conversion) {
      conversion.factor = itemUomConversion.factor;
      conversion.id = itemUomConversion.id;
      conversion.version = itemUomConversion.version;
    }
  });

  return allConversions;
};

export const filterItemUoms = (
  itemDefaultUom: Uom | null,
  defaultUoms: Uom[]
) => {
  let filteredIds: number[] = [];
  if (itemDefaultUom) {
    const uomFromIds = itemDefaultUom.fromConversions.map((u) => u.fromUomId!);

    const uomToIds = itemDefaultUom.toConversions.map((u) => u.toUomId!);

    filteredIds = uomFromIds.concat(uomToIds);

    filteredIds.push(itemDefaultUom.id!);
  }

  return filteredIds.length > 0
    ? defaultUoms.filter((item) => filteredIds.includes(item.id!))
    : defaultUoms;
};
