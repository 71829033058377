import { initialAddress } from 'services/addresses';
import { INITIAL_API_STATUS } from 'services/api';

import {
  QuickShipStrategy,
  SalesOrderShippingQuote,
  ShippingIntegration,
  ShippingIntegrationCarton,
  ShippingIntegrationSettings,
} from './types';

export const shippingIntegrationHostname = process.env.REACT_APP_SHIPPING_URL;

export const initialShippingIntegration: ShippingIntegration = {
  _status: INITIAL_API_STATUS,
  connections: [],
};

export const initialShippingIntegrationSettings: ShippingIntegrationSettings = {
  customField1: null,
  customField2: null,
  fulfillShipments: false,
  applyCostToCarton: false,
  quickShipStrategy: null,
  shippingItem: null,
  alternateEmail: null,
  alternatePhone: null,
  labelType: 'PDF_4x6',
  shipFromLocation: null,
  markup: {
    type: 'flat',
    value: null,
  },
  cartonDimensions: {
    unit: null,
    width: null,
    height: null,
    length: null,
  },
  extraSettings: {
    useRetailRates: false,
    saturdayDelivery: false,
    useInsurance: false,
    containsDryIce: false,
    dryIceWeight: null,
    containsAlcohol: false,
    recipientType: null,
    unitOfMeasure: 'Kilograms',
    defaultSignature: null,
  },
  internationalSettings: {
    contentsType: null,
    disclaimer: null,
    eelPfc: null,
    incoterms: null,
    nonDeliveryOption: null,
    shipperEmail: null,
    exporterReference: null,
    importerReference: null,
    enabled: false,
    taxIdType: null,
    taxIdNumber: null,
  },
};

export const QUICK_SHIP_STRATEGY: QuickShipStrategy[] = [
  {
    id: 0,
    name: 'Lowest Price',
  },
  {
    id: 1,
    name: 'Order Carrier Service',
  },
];

export const initialShippingQuoteCarton: ShippingIntegrationCarton = {
  dimensionUnit: null,
  width: null,
  length: null,
  height: null,
  weight: null,
  weightUnit: 'Pounds',
  insuredAmount: 0,
};

export const inititialSalesOrderShippingQuote: SalesOrderShippingQuote = {
  cartons: [],
  useRetailerRates: false,
  saturdayDelivery: false,
  containsDryIce: false,
  dryIceWeight: null,
  uom: null,
  containsAlcohol: false,
  recipientType: null,
  cod: false,
  codMethod: null,
  codAmount: null,
  codCurrency: 'USD',
  signatureConfirmation: false,
  signature: null,
  nonDeliveryOption: null,
  contentsType: null,
  contentsTypeExplanation: null,
  eel: null,
  incoterms: null,
  disclaimer: null,
  certificate: null,
  exporterReference: null,
  importerReference: null,
  invoice: null,
  shipperEmailAddress: null,
  phoneNumber: null,
  notes: null,
  consent: false,
  certifierName: null,
  serviceCode: null,
  carrierCode: null,
  shipFromAddress: initialAddress,
  shipToAddress: initialAddress,
};
