import { Box, Grid, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import _ from 'lodash';

import { MultiCurrencyWrapper } from 'ui/components/MultiCurrencyWrapper/MultiCurrencyWrapper';
import { RenderCustomFooterProps } from 'ui/components/Table/ItemsTable';
import { useCurrencyFormatter, toMulticurrencyCalculate } from 'helpers';

import { ReconcileWizardExpensesFooterProps } from './types';

const ReconcileWizardExpensesFooter: React.FC<
  RenderCustomFooterProps<ReconcileWizardExpensesFooterProps>
> = (props) => {
  const { footerData } = props;

  const currencyFormatter = useCurrencyFormatter();
  const exchangeRate = _.get(footerData, 'currency.exchangeRate', 1);
  const activeMulticurrencyCode = _.get(footerData, 'currency.code', null);

  const grandTotal = useMemo(
    () =>
      footerData!.reconcileExpenses.reduce(
        (acc, item) => (item.cost || 0) * (item.quantity || 0) + acc,
        0
      ),
    [footerData]
  );

  const multiCurrencyGrandTotal = useMemo(() => {
    const total = toMulticurrencyCalculate(grandTotal, exchangeRate);
    return currencyFormatter(total, activeMulticurrencyCode);
  }, [grandTotal, exchangeRate, activeMulticurrencyCode, currencyFormatter]);

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      height="100%"
      px={3}
    >
      <Grid item xs={3} xl={2}>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Typography component="div">
            Total: <b>{currencyFormatter(grandTotal)}</b>
          </Typography>
        </Box>
      </Grid>
      <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
        <Grid item xs={3} xl={2}>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Typography component="div">
              Total: <b>{multiCurrencyGrandTotal}</b>
            </Typography>
          </Box>
        </Grid>
      </MultiCurrencyWrapper>
    </Box>
  );
};

export default React.memo(ReconcileWizardExpensesFooter);
