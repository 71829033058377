import { SalesOrderStatus } from 'services/salesOrders';

export interface Channel {
  id: number | null;
  vendor: string | null;
  label: string | null;
  channelType: string | null;
  syncedOn: string | null;
  status: string | null;
  configuration: string | null;
}

export enum ChannelStatus {
  Connected = 'Connected',
  Disconnected = 'Disconnected',
}

export enum OrderNumberAssignment {
  Primary = 'PRIMARY',
  Linked = 'LINKED',
  Alternate = 'ALTERNATE',
}

export interface ChannelSettings {
  orderSettings: OrderSettings;
  productImportSettings: ProductImportSettings;
  customerSettings: CustomerSettings;
  posSettings: PosSettings;
}

export interface OrderSettings {
  orderNumberAssignment: OrderNumberAssignment | null;
  orderNumberPrefix: string | null;
  defaultLocation: number | null;
  shippingItem: number | null;
  taxItem: number | null;
  status: SalesOrderStatus | null;
  shippingTerms: number | null;
  accountingClass: number | null;
}

export interface ProductImportSettings {
  accountingClass: number | null;
  uom: number | null;
  dimensionUom: string | null;
  weightUom: string | null;
}

export interface CustomerSettings {
  defaultPaymentTerms: number | null;
  priority: number | null;
  salesRepresentative: number | null;
}

export interface PosSettings {
  defaultLocation: number | null;
  taxItem: number | null;
  shippingItem: number | null;
  shippingTerms: number | null;
  status: SalesOrderStatus | null;
  orderNumberAssignment: OrderNumberAssignment | null;
}
