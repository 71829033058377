import { State } from 'app/redux';

export const getRepresentatives = (state: State) =>
  state.representative.representatives;

export const getSearches = (state: State) => state.representative.searches;

export const getRepresentativeById = (id: number | null) => (state: State) => {
  if (id === null) {
    return undefined;
  }
  return state.representative.representatives.items.find(
    (item) => item.id === id
  );
};
