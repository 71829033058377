import React, { memo } from 'react';

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import clsx from 'clsx';

import { FormRadioGroupProps } from './types';
import { useFormRadioGroupStyles } from './styled';

const FormRadioGroup = ({
  id,
  label,
  onChange,
  value,
  options,
  selectedOption,
  disabled,
}: FormRadioGroupProps) => {
  const classes = useFormRadioGroupStyles();

  if (!options.length || !selectedOption) {
    return null;
  }

  return (
    <FormControl variant="standard" component="fieldset">
      {label && (
        <FormLabel component="legend" className={classes.formLegend}>
          <Typography>
            <strong>{label}</strong>
          </Typography>
        </FormLabel>
      )}

      <RadioGroup
        row
        aria-label={label}
        id={id}
        name={id}
        className={classes.radioGroup}
        value={value}
        onChange={onChange}
      >
        {options.map((option, index) => {
          const isChecked = selectedOption.value === option.value;

          return (
            <FormControlLabel
              className={clsx(
                classes.formControlLabel,
                isChecked && classes.formControlLabelChecked
              )}
              key={index}
              value={option.value}
              control={
                <Radio
                  className={classes.radio}
                  color="primary"
                  checkedIcon={<CheckIcon fontSize="small" />}
                  icon={<span aria-hidden />}
                />
              }
              label={option.name}
              disabled={disabled}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default memo(FormRadioGroup);
