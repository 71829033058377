import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';
import {
  ItemTrackingType,
  SerialColumn,
  SerialRow,
  TrackingGroup,
} from 'services/inventory';
import { TrackingDataTypes } from 'services/settings/tracking';
import { TrackingTableTypes } from './types';
import { sortMultipleTrackingColumns } from './helpers';

export const transformToTrackingTableColumns = (
  itemTrackingTypes: ItemTrackingType[],
  withoutAmountToAssign: boolean,
  firstColumnLabel?: string,
  trackingTableType?: TrackingTableTypes,
  readOnlyTrackingTable?: boolean,
  bulkMove?: boolean
): Column[] => {
  const amountTitle = firstColumnLabel || 'Quantity';

  const columns: Column[] = [];

  if (
    trackingTableType !== TrackingTableTypes.View &&
    trackingTableType !== TrackingTableTypes.Cycle &&
    trackingTableType !== TrackingTableTypes.CycleNew
  ) {
    const onHandColumn: Column = {
      title: bulkMove ? 'Able to Move' : 'On Hand',
      type: ColumnTypes.renderByCustomRow,
    };

    columns.push(onHandColumn);
  }

  if (trackingTableType === TrackingTableTypes.CycleNew) {
    const removeIconPlaceholder: Column = {
      title: 'Remove',
      type: ColumnTypes.renderByCustomRow,
      dataQa: 'remove',
    };

    columns.push(removeIconPlaceholder);
  }

  if (trackingTableType)
    if (trackingTableType === TrackingTableTypes.Cycle) {
      const onHandColumn: Column = {
        title: 'Available to Cycle',
        type: ColumnTypes.renderByCustomRow,
      };

      columns.push(onHandColumn);
    }

  if (!withoutAmountToAssign) {
    columns.push({
      title: amountTitle,
      type: ColumnTypes.renderByCustomRow,
    });
  }

  const hasSerialTracking = itemTrackingTypes.some(
    (t) =>
      _.get(t, 'trackingType.trackingDataType') ===
      TrackingDataTypes.SerialNumber
  );

  if (hasSerialTracking) {
    const serialColumn: Column = {
      title: 'Serial Tracking',
      meta: { trackingDataType: TrackingDataTypes.SerialNumber },
      type: ColumnTypes.renderByCustomRow,
    };

    columns.push(serialColumn);
  }

  itemTrackingTypes.forEach((i) => {
    if (!i.trackingType) {
      return;
    }

    if (i.trackingType.trackingDataType === TrackingDataTypes.SerialNumber) {
      return;
    }

    const dateOrLotColumn: Column = {
      title: i.trackingType.name,
      meta: {
        trackingDataType: i.trackingType.trackingDataType,
        trackingType: i.trackingType,
      },
      type: ColumnTypes.renderByCustomRow,
    };

    columns.push(dateOrLotColumn);
  });

  //for read-only and new cycle tracking table, move serial column to end
  if (
    readOnlyTrackingTable ||
    trackingTableType === TrackingTableTypes.CycleNew
  ) {
    return sortMultipleTrackingColumns(columns);
  }

  return columns;
};

/**
 * Add new TrackingGroup to TrackingGroups
 * @param trackingGroups
 * @param itemTrackingTypes
 * @param amount
 */
export const transformToTrackingGroupsNewGroup = (
  trackingGroups: TrackingGroup[],
  itemTrackingTypes: ItemTrackingType[],
  availableQuantity: number
): TrackingGroup[] => {
  const itemSerialTrackings: ItemTrackingType[] = itemTrackingTypes.filter(
    (type) =>
      _.get(type, 'trackingType.trackingDataType', null) ===
      TrackingDataTypes.SerialNumber
  );
  let newSerialList: SerialRow[] = [];

  if (itemSerialTrackings.length) {
    const serialNumbers: SerialColumn = itemSerialTrackings
      .map((type) => type.trackingTypeId)
      .reduce((o, id) => ({ ...o, [id]: null }), {});
    newSerialList = [...(Array(availableQuantity).keys() as any)].map(
      (index: number) => ({
        id: -1 - index,
        committed: false,
        serialNumbers,
      })
    );
  }

  return [
    ...trackingGroups,
    {
      id: uuidv4(),
      committedQuantity: 0,
      quantity: availableQuantity,
      onHand: 0,
      trackingInfoList: itemTrackingTypes
        .filter((i) => {
          const trackingDataType = _.get(
            i,
            'trackingType.trackingDataType',
            null
          );
          return (
            trackingDataType === TrackingDataTypes.Text ||
            trackingDataType === TrackingDataTypes.Date
          );
        })
        .map((i) => ({
          id: -1,
          trackingTypeId: i.trackingTypeId,
          value: null,
        })),
      serialList: newSerialList,
      serialIds: [],
    },
  ];
};
