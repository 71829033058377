import { OptionType } from 'ui/components/TextField/MultiFormatInput';

export const formatTypesPercentage: OptionType[] = [
  {
    symbol: '%',
    type: 'suffix',
  },
];

export const formatTypesFlatRate: (symbol: string) => OptionType[] = (
  symbol
) => [
  {
    symbol,
    type: 'prefix',
  },
];
