import * as yup from 'yup';
import { isValidUrl } from 'helpers';

export const phoneRegExp =
  /^((\+?[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?){1,2}?[0-9]{3,4}?[ -]?[0-9]{3,4}?$/gm;

export const yupVendorGeneralSchema = yup.object().shape({
  bcc: yup.string().email().nullable().notRequired(),
  cc: yup.string().email().nullable().notRequired(),
  email: yup.string().email().nullable().notRequired(),
  name: yup.string().required(),
  paymentTermId: yup.number().required(),
  url: yup
    .string()
    .transform((value) => value || null)
    .nullable()
    .test(isValidUrl)
    .notRequired(),
});

export const yupVendorItemSchema = yup.object().shape({
  cost: yup.number().required(),
  defaultUomId: yup.number().required(),
  itemId: yup.number().required(),
  name: yup.string().required(),
});
