import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useUserDetailsCardStyle = makeStyles((theme: Theme) => ({
  scrollable: { backgroundColor: theme.palette.primary.light },
  text: { textTransform: 'none' },
  label: {
    color: theme.palette.secondary.main,
  },
  buttonLink: {
    'text-transform': 'none',
    'white-space': 'pre',
    cursor: 'pointer',
  },
  formControl: {
    paddingTop: '5px',
    width: '100%',
  },
  radioLink: {
    marginLeft: '5px',
  },
  radioLabel: {
    width: '100%',
  },
}));
