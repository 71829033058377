import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { colorPalette } from 'ui/theme';

export const useSalesOrderItemsStyle = makeStyles(() => ({
  amountInputInner: {
    height: 20,
    background: 'white',
  },
  amountInputOuter: {
    paddingRight: 5,
  },
  noBorder: {
    border: 'none',
  },
  creditReturn: {
    backgroundColor: 'rgba(250,227,230,0.5)',
    '&:hover': {
      backgroundColor: 'rgba(250,227,230,0.55) !important',
    },
  },
  dropShip: {
    backgroundColor: 'rgba(250, 227, 190, 0.5)',
    '&:hover': {
      backgroundColor: 'rgba(250, 227, 190, 0.55) !important',
    },
  },
}));

export const useSalesOrderTableCellStyle = makeStyles((theme: Theme) => ({
  cell: {
    verticalAlign: 'inherit',
    '& .aligned-link': {
      display: 'inline-flex',
      height: 20,
      alignItems: 'center',
    },
  },
  pricingRulePrice: {
    marginTop: 4,
    color: colorPalette.fishbowlCorporateLightBlue,
    display: 'inline-block',
    marginRight: theme.spacing(1),
  },
}));
