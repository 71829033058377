import React, { memo } from 'react';
import { Box, Grid, Typography, Paper, Divider, Chip } from '@mui/material';

import { NetworkSpinnerWrapper } from 'ui/components/NetworkSpinnerWrapper';

import { CardProps } from './types';
import { useCardStyle } from './styled';

const Card: React.FC<CardProps> = (props) => {
  const {
    imageUrl,
    description,
    title,
    children,
    isLoading = true,
    chipLables = [],
  } = props;

  const classes = useCardStyle(props);

  return (
    <Grid item xs={4} xl={3}>
      <Paper className={classes.card}>
        <Box className={classes.cardHeader}>
          <img
            src={imageUrl}
            alt="Integration Logo"
            className={classes.cardMedia}
          />
          {!!chipLables.length &&
            chipLables.map((lable, index) => (
              <Chip
                label={lable}
                key={index}
                className={classes.statusLabel}
                size="small"
                color="secondary"
              />
            ))}
        </Box>
        <Divider />
        <Box className={classes.cardTitle}>
          <Typography
            dangerouslySetInnerHTML={{ __html: title }}
            variant="h4"
            color="textPrimary"
            component="h4"
          />
        </Box>
        <Box className={classes.cardContent}>
          <Typography
            dangerouslySetInnerHTML={{ __html: description }}
            variant="body1"
            color="textSecondary"
            component="p"
          />
        </Box>
        <Box className={classes.cardFooter}>
          <NetworkSpinnerWrapper
            isLoading={isLoading}
            size={24}
            backgroundColor="white"
          >
            <Box className={classes.cardFooterContent}>{children}</Box>
          </NetworkSpinnerWrapper>
        </Box>
      </Paper>
    </Grid>
  );
};

export default memo(Card);
