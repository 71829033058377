import React, { useEffect, useRef } from 'react';
import { useFlags } from 'helpers/useFlags';
import { datadogLogs } from '@datadog/browser-logs';

import { hasValidDatadogConfig } from '../monitoring/validations';

export const DatadogLogger: React.FC = ({ children }) => {
  const isInitialized = useRef(false);
  const flags = useFlags();

  const initializeDataDogLogging = () => {
    const env = process.env;

    if (
      flags.datadogLogger &&
      !isInitialized.current &&
      hasValidDatadogConfig(env) &&
      env.REACT_APP_DD_LOGGER_CLIENT_TOKEN &&
      env.REACT_APP_DD_SITE
    ) {
      const settings = {
        clientToken: env.REACT_APP_DD_LOGGER_CLIENT_TOKEN,
        site: env.REACT_APP_DD_SITE,
        service: env.REACT_APP_DD_SERVICE,
        env: env.REACT_APP_DD_ENVIRONMENT,
        version: env.REACT_APP_DD_CLIENT_VERSION || '1.0.0',
      };

      const forwardErrorsToLogs = Boolean(
        env.REACT_APP_DD_FORWARD_ERRORS_TO_LOGS
      );

      const forwardConsoleLogs: any = env.REACT_APP_DD_FORWARD_CONSOLE_LOGS
        ? env.REACT_APP_DD_FORWARD_CONSOLE_LOGS.split(',')
        : [];

      const forwardReports: any = env.REACT_APP_DD_FORWARD_REPORTS
        ? env.REACT_APP_DD_FORWARD_REPORTS.split(',')
        : [];

      const sampleRate = env.REACT_APP_DD_LOGGING_SAMPLE_RATE
        ? Number(env.REACT_APP_DD_LOGGING_SAMPLE_RATE)
        : 0;

      const telemetrySampleRate = env.REACT_APP_DD_LOGGING_TELEMETRY_SAMPLE_RATE
        ? Number(env.REACT_APP_DD_LOGGING_TELEMETRY_SAMPLE_RATE)
        : 0;

      const configurations = {
        forwardErrorsToLogs,
        forwardConsoleLogs,
        forwardReports,
        sampleRate,
        telemetrySampleRate,
      };

      datadogLogs.setGlobalContextProperty('featureFlag', true);
      datadogLogs.init({
        ...settings,
        ...configurations,
        beforeSend: () => {
          const globalContext = datadogLogs.getGlobalContext();
          return !!globalContext.featureFlag;
        },
      });
      isInitialized.current = true;
    }
  };
  useEffect(initializeDataDogLogging, [flags.datadogLogger]);

  const haltDatadogLogging = () => {
    if (!flags.datadogLogger) {
      datadogLogs.setGlobalContextProperty('featureFlag', false);
    }
  };
  useEffect(haltDatadogLogging, [flags.datadogLogger]);

  return <>{children}</>;
};

export default DatadogLogger;
