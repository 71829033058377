import React, { useCallback, useMemo } from 'react';
import { TableCell } from '@mui/material';
import _ from 'lodash';

import { IconNames, colorPalette } from 'ui/theme';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { MenuItem, MenuItemVariant } from 'ui/components/Menu';
import {
  SalesOrderItemTypes,
  SalesOrderItem,
  initialSalesOrder,
  SalesOrderItemStatus,
  SalesOrder,
  SalesOrderStatus,
} from 'services/salesOrders';
import { ItemType } from 'services/items';

import { ThreeDotMenuActions } from '../../types';
import { editSalesOrderPermissions } from '../../../../helpers';

import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';

const MenuTableCell: React.FC<RenderCustomRowProps<SalesOrderItem>> = (
  props
) => {
  const { row, onAction, index, meta } = props;

  const lastIndex = _.get(meta, 'lastNonDeletedSoItemIndex');
  const salesOrder: SalesOrder = _.get(meta, 'salesOrder', initialSalesOrder);
  const disableMenu =
    row.status === SalesOrderItemStatus.Fulfilled ||
    salesOrder.status === SalesOrderStatus.Cancelled;

  const editPermission = editSalesOrderPermissions(
    _.get(meta, 'salesOrder', initialSalesOrder)
  );

  const disabledSalesOrderItem = useMemo(
    () => row.status !== SalesOrderItemStatus.Entered,
    [row.status]
  );

  // If row id is less than 0 it means that its new and we cannot fulfill it
  // If row is in fulfilled status we disable it whole
  // If row is Inventory or Non Inventory we cannot fulfill it
  // If sales order status is estimated we cannot fulfill so items
  const canFulfill =
    row.id &&
    row.id > 0 &&
    ((row.saleItem &&
      row.saleItem.item &&
      (row.saleItem.item.itemType === ItemType.Service ||
        row.saleItem.item.itemType === ItemType.Labor ||
        row.saleItem.item.itemType === ItemType.Overhead ||
        row.saleItem.item.itemType === ItemType.Shipping)) ||
      row.salesOrderItemType === SalesOrderItemTypes.MiscReturn ||
      row.salesOrderItemType === SalesOrderItemTypes.MiscSale ||
      row.salesOrderItemType === SalesOrderItemTypes.FlatTaxRate ||
      row.salesOrderItemType === SalesOrderItemTypes.Note) &&
    salesOrder.status !== SalesOrderStatus.Estimate;

  const handleAction = useCallback(
    (type: ThreeDotMenuActions) => () => {
      if (onAction) {
        onAction({ type, payload: row });
      }
    },
    [onAction, row]
  );

  const menuActions: MenuItem[] = [
    {
      icon: IconNames.FBOArrowUp,
      label: 'Move Up',
      disabled: index === 0 || disabledSalesOrderItem,
      onOptionClick: handleAction(ThreeDotMenuActions.MoveUp),
      separateAfter: true,
    },
    {
      icon: IconNames.FBOArrowDown,
      label: 'Move Down',
      separateAfter: true,
      disabled: index === lastIndex || disabledSalesOrderItem,
      onOptionClick: handleAction(ThreeDotMenuActions.MoveDown),
    },
    {
      icon: IconNames.FBOFileDocument,
      label: 'Fulfill',
      disabled: !canFulfill,
      onOptionClick: handleAction(ThreeDotMenuActions.FulFill),
      separateAfter: true,
    },
    {
      icon: IconNames.FBODuplicateSmall,
      label: 'Duplicate',
      onOptionClick: handleAction(ThreeDotMenuActions.Duplicate),
      separateAfter: true,
    },
    {
      icon: IconNames.FBODuplicateSmall,
      label: 'Duplicate To Credit Return',
      disabled:
        row.salesOrderItemType !== SalesOrderItemTypes.Sale &&
        row.salesOrderItemType !== SalesOrderItemTypes.MiscSale,
      onOptionClick: handleAction(ThreeDotMenuActions.DuplicateToCreditReturn),
      separateAfter: true,
    },
    {
      icon: IconNames.FBOEdit,
      label: 'Edit',
      onOptionClick: handleAction(ThreeDotMenuActions.Edit),
      separateAfter: true,
    },
    {
      icon: IconNames.FBODeleteSmall,
      label: 'Remove',
      disabled: disabledSalesOrderItem,
      onOptionClick: handleAction(ThreeDotMenuActions.Remove),
      variant: MenuItemVariant.Danger,
    },
  ];

  return (
    <TableCell padding="none" align="center">
      <FBOMenuButton
        variant="tertiary"
        items={menuActions}
        permissions={editPermission}
        disabled={disableMenu}
        data-qa="sales-order-items-three-dot-menu"
        style={{ color: colorPalette.redesign.contentSecondary }}
      />
    </TableCell>
  );
};

export default MenuTableCell;
