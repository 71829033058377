import { makeStyles } from '@mui/styles';

export const useSuccessModalStyle = makeStyles(() => ({
  modal: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  modalContent: {
    display: 'flex',
    width: '350px',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '12px',
    gap: '16px',
  },
  image: {
    width: '40px',
    height: '40px',
  },
}));
