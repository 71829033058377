import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { RowDescriptor } from '../../types';

const BASE_ROW_HEIGHT = 56;

export const useCardRowStyle = makeStyles<Theme, RowDescriptor>(
  (theme: Theme) => {
    return {
      row: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: BASE_ROW_HEIGHT,
        height: BASE_ROW_HEIGHT,
        padding: theme.spacing(2),
      },
      textRow: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: theme.spacing(2),
      },
      textContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      title: {
        width: '60%',
      },
      shortTitle: {
        width: '30%',
      },
      content: {
        width: '40%',
        textAlign: 'right',
      },
      contentExtended: {
        width: '70%',
        textAlign: 'right',
      },
      button: {
        width: 221,
      },
      logo: {
        height: 72,
        objectFit: 'contain',
      },
      input: {
        display: 'none',
      },
      error: {
        borderColor: 'red',
      },
      tagsContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
      },
    };
  }
);
