import { createApiAction } from 'services/api';
import {
  StartAction,
  SuccessAction,
  FailureAction,
} from 'services/api/redux/types';
import {
  createAddSearch,
  createDeleteSearch,
  createFetchSearch,
  createUpdateSearch,
} from 'services/search';

export enum LedgerActionTypes {
  FETCH_LEDGER_START = 'ledger/fetch_ledger_start',
  FETCH_LEDGER_SUCCESS = 'ledger/fetch_ledger_success',
  FETCH_LEDGER_FAILURE = 'ledger/fetch_ledger_failure',
}

// LEDGER ACTIONS
const fetchLedgerActionStart = (): StartAction => ({
  type: LedgerActionTypes.FETCH_LEDGER_START,
  payload: null,
});

const fetchLedgerActionSuccess = (payload: any): SuccessAction => {
  return {
    type: LedgerActionTypes.FETCH_LEDGER_SUCCESS,
    payload,
  };
};

const fetchLedgerActionFailure = (error: string): FailureAction => ({
  type: LedgerActionTypes.FETCH_LEDGER_FAILURE,
  payload: { error },
});

export const fetchLedgers = () =>
  createApiAction(
    {
      path: '/v1/ledgers/general',
      method: 'GET',
    },
    fetchLedgerActionStart,
    fetchLedgerActionSuccess,
    fetchLedgerActionFailure
  );

// SEARCH ACTION CREATORS

export const fetchSearch = createFetchSearch('ledger');
export const addSearch = createAddSearch('ledger');
export const updateSearch = createUpdateSearch('ledger');
export const deleteSearch = createDeleteSearch('ledger');
