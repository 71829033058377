import { makeStyles } from '@mui/styles';

export const useUomDropdownStyle = makeStyles(() => ({
  uomLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '80px',
  },
}));
