import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createFilterOptions } from '@mui/material';

import {
  fetchSettingsCompanies,
  getSettingsCompanyCountry,
} from 'services/settings/company/redux';
import { activeUserHasPermission } from 'services/user/redux';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { flags } from 'ui/theme/images/flags';

import { COUNTRIES } from './consts';
import {
  CountryAutocompleteProps,
  CountryAutocompleteCmp,
  CountryType,
} from './types';

const CountryAutocomplete: CountryAutocompleteCmp = (
  props: CountryAutocompleteProps
) => {
  const {
    required,
    placeholder,
    value,
    disabled,
    permissions = [],
    label = 'Country',
    optionLabel = 'label',
  } = props;

  const dispatch = useDispatch();

  const defaultCountry = useSelector(getSettingsCompanyCountry);

  const canEditCountries = useSelector(activeUserHasPermission(permissions));

  const isDisabled = disabled || !canEditCountries;

  useEffect(() => {
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchSettingsCompanies());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const options = useMemo(() => {
    const index = COUNTRIES.findIndex((c) => c.code === defaultCountry);

    if (index > -1) {
      const moveToFirst = COUNTRIES[index];
      const countries = [
        ...COUNTRIES.slice(0, index),
        ...COUNTRIES.slice(index + 1),
      ];
      return [moveToFirst, ...countries];
    }

    return COUNTRIES;
  }, [defaultCountry]);

  const filterOptions = createFilterOptions({
    stringify: (option: CountryType) => option.label + option.code,
  });

  return (
    <Autocomplete
      {...props}
      value={value}
      disabled={isDisabled}
      options={options}
      filterOptions={filterOptions}
      getOptionLabel={(option: CountryType) =>
        option[optionLabel as keyof CountryType]
      }
      renderOption={(CountryAutocompleteProps, option: CountryType) => (
        <li {...CountryAutocompleteProps} key={option.code}>
          <>
            <img src={flags[option.code]} alt="" width="20px" height="auto" />
            <span style={{ marginLeft: 8 }}>
              {option.label} ({option.code})
            </span>
          </>
        </li>
      )}
      label={label}
      placeholder={placeholder}
      name="country"
      required={required}
      dataQa="location-country"
      autoSelect={false}
    />
  );
};

export default CountryAutocomplete;
