import React from 'react';

import { Box } from '@mui/material';

import { TextField } from 'ui/components/TextField/TextField';

import { CountComponentProps } from './types';

export const CountComponent = (props: CountComponentProps) => {
  const { parameters, handleTextFieldChange } = props;

  return (
    <Box>
      <TextField
        className="redesign"
        variant="standard"
        label="Count"
        placeholder="Count"
        name="count"
        type="number"
        value={parameters.count}
        onChange={handleTextFieldChange}
        dataQa="report-label-count"
        fullWidth
      />
    </Box>
  );
};
