import _ from 'lodash';

import {
  createApiCall,
  DataWithPagination,
  paginatedApiCall,
} from 'services/api';
import { fetchItemsAPI, Item, ItemType } from 'services/items';
import { defaultMaximumPagination, RequestConfig } from 'helpers';

import {
  AutoPurchaseOrderItem,
  PurchaseOrder,
  PurchaseOrderEmail,
} from './types';
import {
  transformPurchaseOrder,
  transformPoToPostPo,
  transformPoToPutPo,
  transformToAutoPoItem,
} from './transformations';

const expands = [
  'purchaseOrderItems.item.images',
  'purchaseOrderItems.item.vendorItems',
  'purchaseOrderItems.item.itemTrackingTypes',
  'purchaseOrderItems.item.defaultUom.fromConversions',
  'purchaseOrderItems.item.defaultUom.toConversions',
  'vendor.currency',
];

export const fetchPurchaseOrdersAPI = async (
  config: RequestConfig
): Promise<DataWithPagination<PurchaseOrder>> => {
  const {
    pagination = defaultMaximumPagination,
    expands = [],
    customQuickSearchColumns = [],
    quickSearchValue,
  } = config;

  let path = `/v1/purchase_orders?expand=${expands.join(',')}`;

  if (quickSearchValue) {
    path += `&quickSearchColumns=${customQuickSearchColumns}&quickSearchValue=${encodeURIComponent(
      quickSearchValue
    )}`;
  }

  const res = await paginatedApiCall(path, pagination, transformPurchaseOrder);

  return res;
};

export const getPurchaseOrderId = async (): Promise<number> => {
  const response = await createApiCall({
    path: '/v1/purchase_orders/next_po_number',
    method: 'GET',
  })();

  return response.data;
};

export const fetchPurchaseOrderById = async (
  id: number
): Promise<PurchaseOrder> => {
  const response = await createApiCall({
    path: `/v1/purchase_orders/${id}?expand=${expands.join(',')}`,
    method: 'GET',
  })();

  return transformPurchaseOrder(response.data);
};

export const createPurchaseOrder = async (
  purchaseOrder: PurchaseOrder,
  silent: boolean = false
) => {
  const response = await createApiCall(
    {
      path: `/v1/purchase_orders?expand=${expands.join(',')}`,
      method: 'POST',
      body: transformPoToPostPo(purchaseOrder),
    },
    {
      getMessage: {
        success: silent
          ? undefined
          : () => 'New purchase order successfully added',
        error: (error: any) => error.response.data.message,
      },
    }
  )();

  return transformPurchaseOrder(response.data);
};

export const updatePurchaseOrder = async (
  purchaseOrder: PurchaseOrder,
  silent: boolean = false
) => {
  const response = await createApiCall(
    {
      path: `/v1/purchase_orders?expand=${expands.join(',')}`,
      method: 'PUT',
      body: transformPoToPutPo(purchaseOrder),
    },
    {
      getMessage: {
        success: silent
          ? undefined
          : () => 'Purchase order successfully updated',
        error: (error: any) => error.response.data.message,
      },
    }
  )();

  return transformPurchaseOrder(response.data);
};

export const deletePurchaseOrder = async (id: number): Promise<void> => {
  await createApiCall(
    {
      path: `/v1/purchase_orders/${id}`,
      method: 'DELETE',
    },
    {
      getMessage: {
        success: () => 'Purchase order successfully deleted',
        error: (error: any) => error.response.data.message,
      },
    }
  )();
};

export const deletePurchaseOrders = async (ids: number[]): Promise<void> => {
  await createApiCall(
    {
      path: `/v1/purchase_orders/`,
      method: 'DELETE',
      body: ids,
    },
    {
      getMessage: {
        success: () => 'Purchase orders are successfully deleted',
        error: (error: any) => error.response.data.message,
      },
    }
  )();
};

export const unissuePurchaseOrders = async (ids: number[]): Promise<void> => {
  await createApiCall(
    {
      path: `/v1/purchase_orders/unissue`,
      body: ids,
      method: 'POST',
    },
    {
      getMessage: {
        success: (response: any) => response.data,
        error: (error: any) => error.response.data.message,
      },
    }
  )();
};

export const issuePurchaseOrders = async (ids: number[]): Promise<void> => {
  await createApiCall(
    {
      path: `/v1/purchase_orders/issue`,
      body: ids,
      method: 'POST',
    },
    {
      getMessage: {
        success: (response: any) => response.data,
        error: (error: any) => error.response.data.message,
      },
    }
  )();
};

export const issuePurchaseOrder = async (purchaseOrder: PurchaseOrder) => {
  const response = await createApiCall(
    {
      path: `/v1/purchase_orders/${
        purchaseOrder.id
      }/issue?expand=${expands.join(',')}`,
      method: 'POST',
    },
    {
      getMessage: {
        success: () => 'Purchase order successfully issued',
        error: (error: any) => error.response.data.message,
      },
    }
  )();

  return transformPurchaseOrder(response.data);
};

export const unissuePurchaseOrder = async (purchaseOrder: PurchaseOrder) => {
  const response = await createApiCall(
    {
      path: `/v1/purchase_orders/${
        purchaseOrder.id
      }/unissue?expand=${expands.join(',')}`,
      method: 'POST',
    },
    {
      getMessage: {
        success: () => 'Purchase order successfully unissued',
        error: (error: any) => error.response.data.message,
      },
    }
  )();

  return transformPurchaseOrder(response.data);
};

export const fulfillPurchaseOrderItem = async (
  purchaseOrderId: number,
  purchaseOrderItemId: number
) => {
  const res = await createApiCall(
    {
      path: `/v1/purchase_orders/${purchaseOrderId}/purchase_order_items/${purchaseOrderItemId}/fulfill?expand=${expands.join(
        ','
      )}`,
      method: 'POST',
    },
    {
      getMessage: {
        success: () => 'Purchase order item successfully fulfilled',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformPurchaseOrder(res.data);
};

export const sendPurchaseOrderEmail = async (
  poEmail: PurchaseOrderEmail,
  poNumber: string,
  file?: Blob
): Promise<void> => {
  const formData = new FormData();
  const payloadJson = JSON.stringify(poEmail);
  formData.append('bodyString', payloadJson);

  if (file) {
    formData.append('file', file, `PO_${poNumber}.pdf`);
  }

  await createApiCall(
    {
      path: `/email`,
      method: 'POST',
      body: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    },
    {
      getMessage: {
        success: () => 'E-mail successfully sent',
        error: (error: any) => error.response.data.message,
      },
    }
  )();
};

export const getAutoPurchaseOrderItems = async (
  locationId: number
): Promise<AutoPurchaseOrderItem[]> => {
  const path = `/v1/inventory/${locationId}/reorder?expand=images,reorderPoints,vendorItems.defaultUom.fromConversions,vendorItems.defaultUom.toConversions,defaultUom.fromConversions,itemUomConversions&includeItemsWithNoReorderPoints=true&onlyInventoryTypeItems=true`;

  const res = await createApiCall(
    {
      path,
      method: 'GET',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return res.data
    .map(transformToAutoPoItem)
    .filter(
      (i: AutoPurchaseOrderItem) => i.item.itemType === ItemType.Inventory
    );
};

export const restorePurchaseOrder = async (purchaseOrderId: number) => {
  await createApiCall(
    {
      path: `/v1/purchase_orders/${purchaseOrderId}/restore`,
      method: 'POST',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
        success: () => 'Purchase order successfully restored',
      },
    }
  )();
};

export const fetchPurchaseOrderItems = async (
  config: RequestConfig,
  vendorId?: number | null,
  onlyShowVendorParts?: boolean | null,
  hasDropShip?: boolean
): Promise<DataWithPagination<Item>> => {
  let itemTypes = [
    ItemType.Inventory,
    ItemType.Shipping,
    ItemType.Service,
    ItemType.Labor,
    ItemType.Overhead,
    ItemType.NonInventory,
  ];

  if (hasDropShip) {
    itemTypes = [
      ItemType.Shipping,
      ItemType.Service,
      ItemType.Labor,
      ItemType.Overhead,
    ];
  }

  const itemsResponse = await fetchItemsAPI(config, itemTypes, []);

  if (!vendorId) {
    return {
      data: itemsResponse.data,
      pagination: itemsResponse.pagination,
    };
  }

  // In PO if vendor is selected and vendorItems exist we need to replace cost with vendorItem cost
  const items = itemsResponse.data.map((item: Item) => {
    const vendorItem = item.vendorItems.find((vi) => vi.vendorId === vendorId);
    // If in the PO Settings onlyShowVendorParts is selected we only show items with vendor parts in the dropdown
    if (onlyShowVendorParts && !vendorItem) {
      return null;
    }
    if (vendorItem) {
      return {
        ...item,
        cost: vendorItem.cost,
        defaultUomId: vendorItem.defaultUomId,
      };
    }
    return item;
  });

  return {
    data: items.filter((i: Item | null) => !_.isNull(i)) as Item[],
    pagination: itemsResponse.pagination,
  };
};
