import { makeStyles } from '@mui/styles';

export const useCycleCountsStyle = makeStyles(() => ({
  errorMessage: {
    variant: 'subtitle1',
    paddingTop: '10px',
    paddingLeft: '10px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 4,
  },
  errorIcon: {
    display: 'flex',
    alignItems: 'center',
  },
}));
