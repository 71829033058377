import React, { memo, useCallback, useState } from 'react';
import { Box } from '@mui/material';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { initialVendorItem, VendorItem } from 'services/vendors';
import {
  ItemsTable,
  useSelectedItemsChanges,
} from 'ui/components/Table/ItemsTable';
import { findNextNegativeId, replaceValueInCollection } from 'helpers';
import { activeUserHasPermission } from 'services/user/redux';
import { VendorItemModal } from 'ui/components/Modal/VendorItemModal';
import { showNotification } from 'services/api';

import { VendorItemRow } from './components';
import { VENDOR_ITEMS_COLUMNS } from './consts';
import { VendorItemsTabProps } from './types';
import { editItemPermissions } from '../../helpers';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const VendorItemsTab: React.FC<VendorItemsTabProps> = (props) => {
  const { item, setItem } = props;

  const [showModal, setShowModal] = useState(false);
  const [vendorItem, setVendorItem] = useState<VendorItem>(initialVendorItem);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const editPermissions = editItemPermissions(item);

  const canEdit = useSelector(activeUserHasPermission(editPermissions));

  const handleSelectedChange = useSelectedItemsChanges(
    selectedItems,
    setSelectedItems
  );

  const handleAddNewClicked = useCallback(() => {
    setVendorItem({
      ...initialVendorItem,
      name: item.name,
      id: findNextNegativeId(item.vendorItems),
      defaultUomId: item.defaultUomId,
    });

    setShowModal(true);
  }, [item.name, item.vendorItems, item.defaultUomId]);

  const openModalForEditing = useCallback(
    (id: number) => {
      const activeVendorItem = item.vendorItems.find((i) => i.id === id)!;
      setVendorItem({
        ...activeVendorItem,
      });
      if (canEdit) {
        setShowModal(true);
      }
    },
    [item.vendorItems, canEdit]
  );

  const handleModalClose = useCallback(() => {
    setShowModal(false);
    setVendorItem(initialVendorItem);
  }, []);

  const setVendorItems = useCallback(
    (items: React.SetStateAction<VendorItem[]>) => {
      if (typeof items === 'function') {
        setItem((l) => ({
          ...l,
          vendorItems: items(l.vendorItems),
        }));
        return;
      }

      setItem((l) => ({
        ...l,
        vendorItems: items,
      }));
    },
    [setItem]
  );

  const handleSaveClicked = useCallback(() => {
    const index = item.vendorItems.findIndex((p) => p.id === vendorItem.id);
    const itemHasDefaultVendorItem = item.vendorItems.some(
      (vi) => vi.defaultFlag && vi.id !== vendorItem.id
    );
    // Set all other items default flag to false
    const itemsWithRemovedDefault = item.vendorItems.map((i) => ({
      ...i,
      defaultFlag: false,
    }));

    // Show warning when there are no default flags
    if (!vendorItem.defaultFlag && !itemHasDefaultVendorItem) {
      showNotification('There needs to be a default vendor item', {
        variant: 'warning',
      });
      return;
    }

    // Create new Vendor Item or update existing one
    if (index === -1) {
      setShowModal(false);
      if (vendorItem.defaultFlag) {
        setVendorItems([...itemsWithRemovedDefault, vendorItem]);
        return;
      }
      setVendorItems((old) => [...old, vendorItem]);
      return;
    }

    if (vendorItem.defaultFlag) {
      setVendorItems(
        replaceValueInCollection(itemsWithRemovedDefault, vendorItem, index)!
      );
      setShowModal(false);
      return;
    }

    setVendorItems((old) => replaceValueInCollection(old, vendorItem, index)!);
    setShowModal(false);
  }, [item.vendorItems, vendorItem, setVendorItems]);

  const deleteClicked = useCallback(() => {
    const existingItems: VendorItem[] = item.vendorItems
      .filter((i) => i.id! > 0)
      .map((activeVendorItem) => {
        return {
          ...activeVendorItem,
          deleted:
            selectedItems.includes(activeVendorItem.id!) ||
            activeVendorItem.deleted,
        };
      });

    const newItems: VendorItem[] = item.vendorItems.filter((i) => {
      return i.id! < 0 && !selectedItems.includes(i.id!);
    });

    setVendorItems([...existingItems, ...newItems]);
    setSelectedItems([]);
  }, [selectedItems, setVendorItems, item.vendorItems]);

  return (
    <>
      {/* TODO : RESTYLING : remove box wrapper after FF cleanup */}
      <Box
        display="flex"
        flexDirection="column"
        height="auto"
        width="100%"
        overflow="hidden"
      >
        <FBOTitleBar title="Vendor Items">
          {!_.isEmpty(selectedItems) && (
            <FBOButton
              sx={{ marginRight: '8px' }}
              variant="secondary"
              color="negative"
              size="medium"
              icon="TrashCan"
              data-qa="vendor-item-delete"
              onClick={deleteClicked}
              permissions={editPermissions}
            >
              Delete
            </FBOButton>
          )}
          <FBOButton
            variant="secondary"
            color="positive"
            size="medium"
            icon="FBOAddCircle"
            data-qa="vendor-item-add"
            onClick={handleAddNewClicked}
            permissions={editPermissions}
          >
            Add New
          </FBOButton>
        </FBOTitleBar>

        <ItemsTable
          data={item.vendorItems}
          columns={VENDOR_ITEMS_COLUMNS}
          filterRows={(i) => !i.deleted}
          onItemClick={openModalForEditing}
          onSelectedChange={handleSelectedChange}
          selectedItems={selectedItems}
          dataQa="vendor-item-table"
          RenderCustomRow={VendorItemRow}
          emptyTableText="ADD NEW ENTRY BY PRESSING 'ADD'"
        />
      </Box>

      <VendorItemModal
        show={showModal}
        vendorItem={vendorItem}
        setVendorItem={setVendorItem}
        onSave={handleSaveClicked}
        onClose={handleModalClose}
        defaultUOMId={item?.defaultUomId ?? null}
      />
    </>
  );
};

export default memo(VendorItemsTab);
