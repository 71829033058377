import { makeStyles } from '@mui/styles';
import { colorPalette } from 'ui/theme';

export const useReconcileWizardReconcileItemsStyle = makeStyles(() => ({
  quantityColumn: {
    width: 110,
  },
  unitCostColumn: {
    width: 110,
  },
  totalCostColumn: {
    width: 110,
  },
  reconcileCostColumn: {
    width: 110,
  },
  landedCostColumn: {
    width: 110,
  },
  unitLandedColumn: {
    width: 110,
  },
  successColor: {
    color: colorPalette.statusGreen,
  },
  errorColor: {
    color: colorPalette.statusRed,
  },
}));
