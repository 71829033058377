import React from 'react';
import { Route, Routes } from 'react-router-dom';

import SecureRoute from 'ui/components/Route/SecureRoute';

import { RemoteSupportPage } from '../pages/RemoteSupportPage';
import { Routes as RemoteSupportRoutes } from './routes';
import { RemoteSupportRouterCmp, RemoteSupportRouterProps } from './types';

const RemoteSupportRouter: RemoteSupportRouterCmp = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: RemoteSupportRouterProps
) => {
  return (
    <Routes>
      <Route element={<SecureRoute />}>
        <Route path={RemoteSupportPage.route} element={<RemoteSupportPage />} />
      </Route>
    </Routes>
  );
};

RemoteSupportRouter.route = RemoteSupportRoutes.RemoteSupportRouter;

export default RemoteSupportRouter;
