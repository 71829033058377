import React, { useMemo, useCallback } from 'react';
import _ from 'lodash';
import Add from '@mui/icons-material/Add';
import {
  Grid,
  Box,
  Typography,
  IconButton,
  Link,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import {
  COUNTRIES,
  CountryAutocomplete,
} from 'ui/components/Autocomplete/CountryAutocomplete';
import { useHandleTextFieldChange } from 'services/forms';
import { Address, Contact, initialContact } from 'services/addresses';
import { findNextNegativeId, replaceValueInCollection } from 'helpers';

import { AddressContactListItem } from './AddressContactListItem';
import { AddNewAndEditModalProps } from './types';
import { useAddressStyle } from './styled';
import { StateAutocomplete, STATES } from '../StateAutocomplete';

const AddNewAndEditModal: React.FC<AddNewAndEditModalProps> = (props) => {
  const {
    modalVisible,
    onClose,
    activeAddress,
    setActiveAddress,
    onSaveChanges,
    validationErrors,
    contactErrors,
    disableContants = false,
  } = props;

  const { contacts } = activeAddress;

  const classes = useAddressStyle();

  const handleTextFieldChange = useHandleTextFieldChange<Address>(
    setActiveAddress,
    activeAddress
  );

  const oldAddress = useMemo(
    () => activeAddress,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeAddress.id]
  );

  const setContactItems = useCallback(
    (contactItems: React.SetStateAction<Contact[]>) => {
      if (typeof contactItems === 'function') {
        setActiveAddress((a) => ({
          ...a,
          contacts: contactItems(a.contacts),
        }));
        return;
      }

      setActiveAddress((a) => ({
        ...a,
        contacts: contactItems,
      }));
    },
    [setActiveAddress]
  );

  const handleResidentialChange = useCallback(
    (event: React.ChangeEvent<{}>, checked: boolean) => {
      setActiveAddress((old) => ({ ...old, residential: checked }));
    },
    [setActiveAddress]
  );

  const handleAddNewContact = useCallback(() => {
    setContactItems((oldState) => [
      ...oldState,
      { ...initialContact, id: findNextNegativeId<Contact>(oldState) },
    ]);
  }, [setContactItems]);

  const activeCountry = useMemo(() => {
    const newCountry = COUNTRIES.find((c) => c.code === activeAddress.country);
    if (newCountry) {
      return newCountry;
    } else {
      return null;
    }
  }, [activeAddress.country]);

  const activeState = useMemo(() => {
    return STATES.find((s) => s.abbreviation === activeAddress.state) || null;
  }, [activeAddress]);

  const handleCountryChange = useCallback(
    (e: any, v: any) => {
      setActiveAddress({ ...activeAddress, country: _.get(v, 'code', '') });
    },
    [setActiveAddress, activeAddress]
  );

  const handleStateChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, v: any) => {
      setActiveAddress({
        ...activeAddress,
        state: _.get(v, 'abbreviation', ''),
      });
    },
    [activeAddress, setActiveAddress]
  );

  const handleEditContact = useCallback(
    (index: number) => (contact: Contact) => {
      setContactItems(
        (prevContacts) =>
          replaceValueInCollection(prevContacts, contact, index)!
      );
    },
    [setContactItems]
  );

  const handleCheckboxChange = useCallback(
    (index: number) => () => {
      const newContacts = [...contacts];
      const previousActiveIndex = _.findIndex(contacts, {
        defaultFlag: true,
        type: contacts[index].type,
      });
      if (previousActiveIndex !== -1) {
        newContacts[previousActiveIndex] = {
          ...newContacts[previousActiveIndex],
          defaultFlag: false,
        };
      }
      newContacts[index] = {
        ...newContacts[index],
        defaultFlag: previousActiveIndex !== index,
      };
      setContactItems(newContacts);
    },
    [contacts, setContactItems]
  );

  const handleDeleteContact = useCallback(
    (index: number) => () => {
      const deletedContact = contacts[index];
      const newContacts = [...contacts];
      if (deletedContact.id! < 0) {
        newContacts.splice(index, 1);
      } else {
        newContacts[index].deleted = true;
      }

      setContactItems(newContacts);
    },
    [contacts, setContactItems]
  );

  const handleResetClicked = useCallback(() => {
    setActiveAddress(oldAddress);
  }, [oldAddress, setActiveAddress]);

  return (
    <Modal
      open={modalVisible}
      title={activeAddress.id! > 0 ? 'Edit Address' : 'New Address'}
      onCancelClicked={onClose}
      onApplyClicked={onSaveChanges}
      onResetClicked={handleResetClicked}
      applyLabel={activeAddress.id! > 0 ? 'SAVE' : 'ADD'}
      maxWidth="md"
      dataQa="address"
    >
      <Box mb={2}>
        <Grid container item spacing={1}>
          <Grid item xs={4}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Address Nickname"
              placeholder="Enter nickname"
              name="name"
              autoComplete="nope"
              fullWidth
              value={activeAddress.name}
              required
              onChange={handleTextFieldChange}
              error={!!validationErrors.name}
              dataQa="address-name"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Attention"
              placeholder="Enter Attention"
              name="attention"
              autoComplete="nope"
              fullWidth
              value={activeAddress.attention}
              onChange={handleTextFieldChange}
              dataQa="address-attention"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Company Name"
              placeholder="Enter company name"
              name="companyName"
              autoComplete="nope"
              fullWidth
              value={activeAddress.companyName}
              onChange={handleTextFieldChange}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={2}>
        <Grid container item spacing={1}>
          <Grid item xs={4}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Street"
              placeholder="Enter street name"
              name="street"
              autoComplete="nope"
              fullWidth
              value={activeAddress.street}
              required
              onChange={handleTextFieldChange}
              error={!!validationErrors.street}
              dataQa="address-street"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Street 2"
              placeholder="Enter street 2 name"
              name="street2"
              autoComplete="nope"
              fullWidth
              value={activeAddress.street2}
              onChange={handleTextFieldChange}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(activeAddress.residential)}
                  name="residential"
                  color="primary"
                  onChange={handleResidentialChange}
                />
              }
              label="Residential"
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={4}>
        <Grid container item spacing={1}>
          <Grid item xs={2}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="City"
              placeholder="Enter city name"
              name="city"
              autoComplete="nope"
              fullWidth
              value={activeAddress.city}
              required
              onChange={handleTextFieldChange}
              error={!!validationErrors.city}
              dataQa="address-city"
            />
          </Grid>
          <Grid item xs={2}>
            {activeAddress.country === 'US' ? (
              <StateAutocomplete
                value={activeState}
                onChange={handleStateChange}
                error={!!validationErrors.state}
                required
              />
            ) : (
              <TextField
                className="redesign"
                variant="standard"
                type="text"
                label="State"
                placeholder="Enter state name"
                name="state"
                autoComplete="nope"
                fullWidth
                value={activeAddress.state}
                required
                onChange={handleTextFieldChange}
                error={!!validationErrors.state}
              />
            )}
          </Grid>
          <Grid item xs={2}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="ZIP"
              placeholder="Enter ZIP code"
              name="postalCode"
              autoComplete="nope"
              fullWidth
              value={activeAddress.postalCode}
              required
              onChange={handleTextFieldChange}
              error={!!validationErrors.postalCode}
              dataQa="address-zip"
            />
          </Grid>
          <Grid item xs={2}>
            <CountryAutocomplete
              value={activeCountry}
              onChange={handleCountryChange}
              required
              error={!!validationErrors.country}
            />
          </Grid>
        </Grid>
      </Box>
      {!disableContants && (
        <>
          <Box mb={2}>
            <Typography>
              <b>Contacts</b>
            </Typography>
          </Box>
          {contacts.map((contact, index) => {
            return (
              !contact.deleted && (
                <Box mb={1} key={`AddressContactListItem${index}`}>
                  <AddressContactListItem
                    contact={contact}
                    onEdit={handleEditContact(index)}
                    onDelete={handleDeleteContact(index)}
                    onCheckboxClick={handleCheckboxChange(index)}
                    errors={contactErrors[index] || {}}
                  />
                </Box>
              )
            );
          })}
          <Box display="flex" alignItems="center">
            <IconButton
              onClick={handleAddNewContact}
              data-qa="address-new-contact"
              size="large"
            >
              <Add />
            </IconButton>
            <Link
              onClick={handleAddNewContact}
              className={classes.buttonLink}
              underline="always"
              variant="body2"
              color="textPrimary"
            >
              Add New Contact
            </Link>
          </Box>
        </>
      )}
    </Modal>
  );
};

export default React.memo(AddNewAndEditModal);
