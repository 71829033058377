import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import moment from 'moment';
import { useSelector } from 'react-redux';

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { useCurrencyFormatter, useGetIntlDateFormatString } from 'helpers';

import { useDashboardLineChartStyle } from './styled';
import { DashboardLineChartProps } from './types';
import { percentageChange } from '../../helpers';
import { getSettingsCompanyCountry } from 'services/settings/company';
import { COUNTRIES } from 'ui/components/Autocomplete/CountryAutocomplete';

const DashboardChart: React.FC<DashboardLineChartProps> = ({
  data,
  title,
  lineChartData,
}) => {
  const classes = useDashboardLineChartStyle();
  const companyCountry = useSelector(getSettingsCompanyCountry);
  const currencyFormatter = useCurrencyFormatter();
  const intlFormatDate = useGetIntlDateFormatString();

  const country = useMemo(
    () => COUNTRIES.find((c) => c.code === companyCountry),
    [companyCountry]
  );
  const locale = country ? country.locale : 'en-US';

  const previousValue = useMemo(
    () => data.reduce((acc, i) => acc + i.previousValueToCalculate, 0),
    [data]
  );

  const latestValue = useMemo(
    () => data.reduce((acc, i) => acc + i.latestValueToCalculate, 0),
    [data]
  );

  const tickFormatter = (val: any) => {
    const date = moment(val as string).toDate();
    const intlFormatDate = new Intl.DateTimeFormat(locale, {
      month: 'short',
      day: 'numeric',
    }).format(date);

    return intlFormatDate;
  };

  const tickFormatterY = (val: any) => {
    const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];
    const tier = (Math.log10(Math.abs(val)) / 3) | 0;

    // if zero, we don't need a suffix
    if (tier === 0) return val;
    // get suffix and determine scale
    const suffix = SI_SYMBOL[tier];
    const scale = Math.pow(10, tier * 3);
    // scale the val
    const scaled = val / scale;
    // format number and add suffix
    return scaled.toFixed(1) + suffix;
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.card}>
        <Box className={classes.cardHeader}>
          <Box>
            <Box className={classes.cardHeaderInfo}>
              <Typography className={classes.cardHeaderLabel}>
                {title}
              </Typography>
              <Typography className={classes.cardHeaderPercentage}>
                {`${percentageChange(previousValue, latestValue).toFixed(2)}%`}
              </Typography>
            </Box>
            <Typography className={classes.cardHeaderValue}>
              {currencyFormatter(latestValue)} -
              {currencyFormatter(previousValue)}
            </Typography>
          </Box>

          <Box className={classes.chartLegend}>
            {lineChartData.legends.map((legend, index) => (
              <Box className={classes.chartLegendItem} key={`legend_${index}`}>
                <FiberManualRecordIcon
                  className={classes.chartLegendItemIcon}
                  style={{ color: legend.color }}
                />
                <Typography className={classes.chartLegendItemLabel}>
                  {legend.title}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
        <ResponsiveContainer width="99%" height={250}>
          <LineChart
            data={data}
            margin={{ top: 5, right: 32, left: 0, bottom: 0 }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="name"
              axisLine={false}
              tickLine={false}
              tickFormatter={tickFormatter}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              tickFormatter={tickFormatterY}
            />
            <Tooltip labelFormatter={(d) => moment(d).format(intlFormatDate)} />
            {lineChartData.lines.map((line, index) => (
              <Line
                key={`line-${index}`}
                type="monotone"
                dataKey={line.key}
                stroke={line.color}
                strokeWidth={2}
                dot={{ fill: line.color, strokeWidth: line.dotStroke }}
                name={line.name}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default DashboardChart;
