import { UrlQueryObject } from 'services/url/types';

export interface ItemDetailsProps {
  itemId: number | null;
  onClose(): void;
  fetchSearchResult(): Promise<void>;
  clone: boolean;
  setClone: React.Dispatch<React.SetStateAction<boolean>>;
  extendUrlQuery: (updatedQueries: ItemUrlQueryObject) => void;
  setBypassRouteLeavingGuard: React.Dispatch<React.SetStateAction<boolean>>;
  bypassRouteLeavingGuard: boolean;
}

export enum ItemTab {
  General = 0,
  SaleItems = 1,
  BundleItems = 2,
  SubstituteItems = 3,
  ReorderPoints = 4,
  Tracking = 5,
  Locations = 6,
  VendorItems = 7,
  Documents = 8,
  History = 9,
}

export interface ItemUrlQueryObject extends UrlQueryObject {
  activeTab?: ItemTab | null;
}
