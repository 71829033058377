import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import {
  getModuleNavigation,
  ModuleNavigationType,
} from 'services/moduleNavigation';

import FBOMaterialsNavigation from './components/MaterialsNavigation/FBOMaterialsNavigation';
import FBOPurchaseNavigation from './components/PurchaseNavigation/FBOPurchaseNavigation';
import FBOSalesNavigation from './components/SalesNavigation/FBOSalesNavigation';

const ModuleNavigation: React.FC<{}> = () => {
  const { type, navigationItems } = useSelector(getModuleNavigation);

  if (type === ModuleNavigationType.Sales) {
    return <FBOSalesNavigation items={navigationItems} />;
  }

  if (type === ModuleNavigationType.Purchase) {
    return <FBOPurchaseNavigation items={navigationItems} />;
  }

  if (type === ModuleNavigationType.Materials) {
    return <FBOMaterialsNavigation items={navigationItems} />;
  }

  return null;
};

export default memo(ModuleNavigation);
