import { TaxMappingRates } from './types';

export const removeObjectFromArrayByField = (
  arr: TaxMappingRates[],
  value: string
) => {
  for (let i = arr.length - 1; i >= 0; i--) {
    if (arr[i]['channelsTaxRate'].code === value) {
      arr.splice(i, 1);
    }
  }
};
