import {
  createApiCall,
  DataWithPagination,
  paginatedApiCall,
} from 'services/api';
import { Pagination } from 'services/search';

import { initialPagination } from './consts';
import {
  transformExportHistory,
  transformRequestAccounts,
  transformToAccount,
  transformToWaitingToExport,
} from './transformations';
import { Account, ExportHistory, WaitingToExport } from './types';
import { RequestConfig, defaultMaximumPagination } from 'helpers';

export const fetchAccounts = async (): Promise<Account[]> => {
  const res = await createApiCall({
    path: `/v1/accounts?pageSize=100&orderBy=name&ascending=true`,
    method: 'GET',
  })();

  return res.data.results.map(transformToAccount);
};

export const fetchAccountsAPI = async (
  config: RequestConfig
): Promise<DataWithPagination<Account>> => {
  const { pagination = defaultMaximumPagination } = config;
  const path = `/v1/accounts`;

  const res = await paginatedApiCall(path, pagination, transformToAccount);
  return res;
};

export const getAccountById = async (id: number): Promise<Account> => {
  const res = await createApiCall(
    {
      path: `/v1/accounts/${id}`,
      method: 'GET',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformToAccount(res.data);
};

export const updateAccounts = async (accounts: Account[]) => {
  const resp = await createApiCall(
    {
      path: '/v1/accounts',
      method: 'PUT',
      body: accounts.map(transformRequestAccounts),
    },
    {
      getMessage: {
        success: () => 'Accounts successfully updated',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformRequestAccounts(resp.data);
};

export const deleteAccount = async (accountId: number): Promise<void> => {
  await createApiCall(
    {
      path: `/v1/accounts/${accountId}`,
      method: 'DELETE',
    },
    {
      getMessage: {
        success: () => 'Account successfully deleted',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();
};

export const getWaitingToExport = async (): Promise<WaitingToExport> => {
  const resp = await createApiCall({
    path: '/v1/ledgers/waiting_to_export',
    method: 'GET',
  })();

  return transformToWaitingToExport(resp.data);
};

export const getHistory = async (
  pagination: Pagination = initialPagination
): Promise<DataWithPagination<ExportHistory>> => {
  const path =
    '/accounting/history?parentAccountingHistoryId=&expand=ledger&orderBy=dateCreated&ascending=false';

  const res = await paginatedApiCall(path, pagination, transformExportHistory);

  return res;
};

export const getHistoryDetails = async (
  parentHistoryId: number,
  pagination: Pagination = initialPagination
): Promise<DataWithPagination<ExportHistory>> => {
  const path = `/accounting/history?parentAccountingHistoryId=${parentHistoryId}&expand=ledger`;

  const res = await paginatedApiCall(path, pagination, transformExportHistory);

  return res;
};
