import _ from 'lodash';
import moment from 'moment';
import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const RECEIVING_TAB_ORDERS_COLUMNS: (format: string) => Column[] = (
  format
) => [
  {
    title: 'Order',
    render: (row) => {
      return `PO ${row.purchaseOrder.number}`;
    },
    type: ColumnTypes.custom,
  },
  {
    title: 'Qty',
    field: 'quantity',
    type: ColumnTypes.string,
  },
  {
    title: 'Scheduled',
    render: (row) => {
      const formatedDate = _.get(row, 'purchaseOrder.dateScheduled', null);
      return formatedDate ? moment(formatedDate).format(format) : null;
    },
    type: ColumnTypes.custom,
  },
  {
    title: 'Main Receiving',
    render: (row) => _.get(row, 'purchaseOrder.location.displayName', ''),
    type: ColumnTypes.custom,
  },
];
