import { defaultMaximumPagination, RequestConfig } from 'helpers';
import {
  createApiCall,
  DataWithPagination,
  paginatedApiCall,
} from 'services/api';

import {
  EditInventoryData,
  EditInventorySerialData,
  ItemInventoryEvents,
  InventoryEventData,
  QuantityType,
  ItemQuantityRow,
  BulkWizardItem,
  BulkWizardError,
  Costing,
  BulkItem,
} from './types';
import {
  transformItemInventory,
  transformToPostInventoryEvent,
  transformItemQuantity,
  transformBulkWizardItem,
  transformToRequestBulkWizardItem,
  transformBulkWizardError,
  transformCosting,
} from './transformations';
import { parseLastWordArray } from './helpers';
import { paginatedApiCallNewStandard3 } from 'services/api/createApiCall';

export const getItemInventory = async (
  itemId: number,
  showCommitted: boolean = false
) => {
  const res = await createApiCall({
    path: `/v1/inventory/${itemId}?includeTracking=true&showCommitted=${showCommitted}`,
    method: 'GET',
  })();

  return transformItemInventory(res.data);
};

export const getInventorySummaries = async (
  itemIds: number[] = [],
  locationIds: number[] = []
): Promise<BulkWizardItem[]> => {
  const res = await createApiCall({
    path: `/v1/inventory/inventorySummary?itemIds=${itemIds.join(
      ','
    )}&locationIds=${locationIds.join(',')}`,
    method: 'GET',
  })();

  return res.data.flatMap(transformBulkWizardItem);
};

export const postBulkInventory = async (
  items: BulkItem[]
): Promise<BulkWizardError[]> => {
  const res = await createApiCall({
    path: `/v1/inventory/bulk`,
    method: 'POST',
    body: items.filter((i) => i.quantity).map(transformToRequestBulkWizardItem),
  })();

  return res.data.eventInputErrorResponseList.map(transformBulkWizardError);
};

export const getItemQuantity = async (
  itemId: number | null,
  quantityType: QuantityType
): Promise<ItemQuantityRow[]> => {
  const res = await createApiCall({
    path: `/v1/inventory/${itemId}/details?action=${quantityType}`,
    method: 'GET',
  })();

  return res.data.map(transformItemQuantity);
};

export const editItemsInInventory = (
  itemId: number,
  inventoryEditNonSerialTracking: EditInventoryData[],
  inventoryEditSerialTracking: EditInventorySerialData[]
) =>
  createApiCall(
    {
      path: `/v1/inventory/${itemId}/editTrackingValues`,
      method: 'POST',
      body: {
        editNonSerialTrackingInputs: inventoryEditNonSerialTracking,
        editSerialInputs: inventoryEditSerialTracking,
      },
    },
    {
      getMessage: {
        success: () => 'Successfully edited in inventory',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

export const postInventoryEvent = (
  inventoryEventType: ItemInventoryEvents,
  data: InventoryEventData,
  itemHasTracking: boolean,
  setDuplicateArray: React.Dispatch<React.SetStateAction<string[]>>
) => {
  let successMsg = '';
  switch (inventoryEventType) {
    case ItemInventoryEvents.Add:
      successMsg = 'Successfully added to inventory';
      break;
    case ItemInventoryEvents.Remove:
      successMsg = 'Successfully removed from inventory';
      break;
    case ItemInventoryEvents.Cycle:
      successMsg = 'Successfully cycled in inventory';
      break;
    case ItemInventoryEvents.Move:
      successMsg = 'Successfully moved to inventory';
      break;
  }

  return createApiCall(
    {
      path: `/v1/inventory?includeTracking=true`,
      method: 'POST',
      body: {
        inventoryEventType,
        ...transformToPostInventoryEvent(data, itemHasTracking),
      },
    },
    {
      getMessage: {
        success: () => {
          setDuplicateArray([]);
          return successMsg;
        },
        error: (error: any) => {
          const errorMessage = error.response.data.message;
          if (
            typeof errorMessage === 'string' &&
            errorMessage.includes(
              'A serial number with the provided value(s) already exists in location(s)'
            ) &&
            inventoryEventType === ItemInventoryEvents.Cycle
          ) {
            setDuplicateArray(parseLastWordArray(errorMessage));
          } else {
            setDuplicateArray([]);
          }
          return `${errorMessage}`;
        },
      },
    }
  )();
};

export const getItemTrackingNextValue = async (
  itemTrackingTypeId: number,
  trackingTypeId?: number
): Promise<string> => {
  const resolvedBody = { id: itemTrackingTypeId };

  const res = await createApiCall(
    {
      path: `/inventory-mgmt/v1/tracking-types/${trackingTypeId}/next-value`,
      method: 'POST',
      body: resolvedBody,
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return res.data[0].value;
};

export const getSerialItemTrackingNextValue = async (
  trackingId: number,
  serialQuantity: number
): Promise<string[]> => {
  const resolvedBody = { id: trackingId, quantity: serialQuantity };

  const res = await createApiCall(
    {
      path: `/v1/tracking_types/auto_get`,
      method: 'POST',
      body: resolvedBody,
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return res.data.map((i: { value: string }) => i.value);
};

export const getCostingByItemId = async (
  itemId: number,
  config: RequestConfig
): Promise<DataWithPagination<Costing>> => {
  const { pagination = defaultMaximumPagination, expands = [] } = config;
  const path = `/v1/costing/${itemId}?expand=${expands.join(',')}`;

  const res = await paginatedApiCall(path, pagination, transformCosting);

  return res;
};

export const getCostingByItemIdV2 = async (
  itemId: number,
  voided: boolean,
  config: RequestConfig
): Promise<DataWithPagination<Costing>> => {
  const { pagination = defaultMaximumPagination, expands = [] } = config;
  const path = `/v2/costing/${itemId}?voided=${voided}&expand=${expands.join(
    ','
  )}`;

  const res = await paginatedApiCallNewStandard3(
    path,
    pagination,
    transformCosting
  );

  return res;
};
