import axios from 'axios';
import {
  commerceApi,
  imageApi,
  integrationsApi,
  reportingApi,
  shippingIntegrationsApi,
} from 'services/api/config';

export const setAccessToken = (accessToken: string, keepLoggedIn: boolean) => {
  axios.defaults.headers.common.Authorization = 'Bearer ' + accessToken;
  reportingApi.defaults.headers.common.Authorization = 'Bearer ' + accessToken;
  commerceApi.defaults.headers.common.Authorization = 'Bearer ' + accessToken;
  imageApi.defaults.headers.common.Authorization = 'Bearer ' + accessToken;
  integrationsApi.defaults.headers.common.Authorization =
    'Bearer ' + accessToken;
  shippingIntegrationsApi.defaults.headers.common.Authorization =
    'Bearer ' + accessToken;
  sessionStorage.setItem('accessToken', accessToken);
  if (keepLoggedIn) {
    localStorage.setItem('accessToken', accessToken);
  }
};

export const getAccessToken = (): string | null => {
  return (
    localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken')
  );
};

export const clearAccessToken = () => {
  axios.defaults.headers.common.Authorization = '';
  reportingApi.defaults.headers.common.Authorization = '';
  commerceApi.defaults.headers.common.Authorization = '';
  imageApi.defaults.headers.common.Authorization = '';
  integrationsApi.defaults.headers.common.Authorization = '';
  sessionStorage.removeItem('accessToken');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('adminAccessToken');
};

export const setAdminAccessToken = (remoteUserToken: string) => {
  localStorage.setItem('adminAccessToken', getAccessToken()!);
  setAccessToken(remoteUserToken, true);
};

export const clearAdminAccessToken = () => {
  setAccessToken(localStorage.getItem('adminAccessToken')!, true);
  localStorage.removeItem('adminAccessToken');
};

export const getAdminAccessToken = (): string | null => {
  return localStorage.getItem('adminAccessToken');
};
