import {
  createAddSearch,
  createDeleteSearch,
  createFetchSearch,
  createUpdateSearch,
} from 'services/search';

export const fetchSearch = createFetchSearch('inventory');
export const addSearch = createAddSearch('inventory');
export const updateSearch = createUpdateSearch('inventory');
export const deleteSearch = createDeleteSearch('inventory');
