import { ReceiptItemStatus, ReceiptItemType } from 'services/receiving';
import { IconNames } from 'ui/theme';
import { MenuItem } from 'ui/components/Menu';
import { PermissionType } from 'services/permissions';

import { ThreeDotMenuActions } from '../../../../types';

type onActionFunction = (type: ThreeDotMenuActions) => void;

export const MENU_ACTIONS: (
  onAction: onActionFunction,
  status: ReceiptItemStatus | null,
  type: ReceiptItemType | null,
  landedTotalCost: number | null,
  billedTotalCost: number | null
) => MenuItem[] = (
  onAction: onActionFunction,
  status: ReceiptItemStatus | null,
  type: ReceiptItemType | null,
  landedTotalCost: number | null,
  billedTotalCost: number | null
) => [
  {
    icon: IconNames.FBOPauseCircleSmall,
    label: 'Hold',
    separateAfter: true,
    permissions: [PermissionType.ReceivingEdit],
    onOptionClick: (ev) => {
      ev.stopPropagation();
      onAction(ThreeDotMenuActions.Hold);
    },
    disabled: status !== ReceiptItemStatus.Open,
  },
  {
    icon: IconNames.FBODocumentApprovalSmall,
    label: 'Receive',
    permissions: [PermissionType.ReceivingItemReceive],
    onOptionClick: (ev) => {
      onAction(ThreeDotMenuActions.Receive);
      ev.stopPropagation();
    },
    disabled:
      status === ReceiptItemStatus.Received ||
      status === ReceiptItemStatus.Rejected ||
      status === ReceiptItemStatus.Hold ||
      status === ReceiptItemStatus.Fulfilled,
    separateAfter: true,
  },
  {
    icon: IconNames.FBOCircleCheckedSmall,
    label: 'Fulfill',
    permissions: [PermissionType.ReceivingItemFulfill],
    onOptionClick: (ev) => {
      ev.stopPropagation();
      onAction(ThreeDotMenuActions.Fulfill);
    },
    disabled:
      status === ReceiptItemStatus.Rejected ||
      status === ReceiptItemStatus.Hold ||
      status === ReceiptItemStatus.Fulfilled ||
      type === ReceiptItemType.Sale,
    separateAfter: true,
  },
  {
    icon: IconNames.FBOStopCircleSmall,
    label: 'Void',
    separateAfter: true,
    permissions: [PermissionType.ReceivingEdit],
    onOptionClick: () => onAction(ThreeDotMenuActions.Void),
    disabled:
      status === ReceiptItemStatus.Open ||
      (status === ReceiptItemStatus.Fulfilled &&
        landedTotalCost !== billedTotalCost),
  },
  {
    icon: IconNames.FBOCloseCircleSmall,
    label: 'Reject',
    permissions: [PermissionType.ReceivingItemReject],
    onOptionClick: (ev) => {
      ev.stopPropagation();
      onAction(ThreeDotMenuActions.Reject);
    },
    disabled:
      status === ReceiptItemStatus.Received ||
      status === ReceiptItemStatus.Rejected ||
      status === ReceiptItemStatus.Hold ||
      status === ReceiptItemStatus.Fulfilled,
  },
];
