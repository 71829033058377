import { State } from 'app/redux';

export const getCustomers = (state: State) => state.customers.customers;
export const getSearches = (state: State) => state.customers.searches;
export const getCustomerById = (id: number | null) => (state: State) => {
  if (id === null) {
    return;
  }

  return state.customers.customers.items.find((item) => item.id === id);
};
