import { createGlobalStyle } from 'styled-components';

const APP_PADDING = 16;

export default createGlobalStyle`
  html, body {
    overflow: hidden;
  }
  body {
    font-size: calc(14px * var(--font-size-multiplier, 1)) !important;
  }
  #root {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    overflow-x: hidden;
    background-color: #E2E8EE;
    display: flex;
  }
  .MuiTabs-flexContainer {
    overflow: auto;
  }
  .redesign.home-page-layout {
    overscroll-behavior: none;
    display: flex;
    width: 100%;
  }
  .redesign.home-page-layout .main-page-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-width: calc(100vw - 200px); // side-menu buffer
    padding: ${APP_PADDING}px;
    flex-grow: 1;
    justify-content: flex-start; 
  }
  .redesign.navigation-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    gap: 16px;
    
  }
  .redesign.navigation {
    display: flex;
    padding: 8px 24px;
    gap: 20px;
    border-radius: 50px;
    background: #FFF;
  }
  .redesign.return-navigation {
    display: flex;
    padding: 8px 24px;
    gap: 20px;
    border-radius: 50px;
    background: #FFF;
    align-items: center;
  }
  .redesign.breadcrumb {
    button[status="active"] {
      color: #137FFD;
    }
    button[status="inActive"] {
      display: inline-block;
      text-decoration: underline;
      text-underline-offset: 4px;
    }
    display: flex;
  }
  .redesign.return-item {
    text-decoration: underline;
    text-underline-offset: 4px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }
  ::-webkit-scrollbar {
    width: 0.3125em; // 5px
    height: 0.3125em; // 5px
    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #3f4043;
  }
  .react-pdf__Page {
    width: 100%;
  }
  .react-pdf__Page__canvas {
    margin: 0 auto;
  }
  .img-responsive {
    display: block;
    width: 100%;
  }
  .rc-slider-track {
    background-color: #0029f0;
  }
  .rc-slider-handle {
    border-color: #137FFD !important;
  }
  .rc-slider-dot-active {
    border-color: #137FFD !important;
  }
  .IconButtonWithMenu .MuiListItem-button.Mui-disabled {
    pointer-events: initial !important;
  }
`;
