import { createApiCall } from 'services/api';
import { TaxMappingRates } from './types';

export const updateTaxRates = async (
  mappedTaxRates: TaxMappingRates[],
  accountingType: string
) => {
  const resp = await createApiCall(
    {
      path: `/v2/integrations/${accountingType}/mapTaxes`,
      method: 'POST',
      body: mappedTaxRates,
    },
    {
      getMessage: {
        success: () => 'Taxes Mapping successfully updated',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();
  return resp.data;
};
