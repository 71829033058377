import React, { memo, useMemo } from 'react';
import { Box, Typography, Divider } from '@mui/material';

import { TextField } from 'ui/components/TextField/TextField';
import { ShippoRecipientType } from 'services/integrations/shipping/shippo';
import {
  useHandleCheckboxChange,
  useHandleTextFieldChange,
} from 'services/forms/hooks';
import { WEIGHT_UNITS, WeightUnit } from 'services/items';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';

import { useShippingWizardStyle } from '../../../../styled';
import { useExtraSettings } from '../../../../ShippoProvider';
import FBOSwitch from 'ui/theme/components/FBOSwitch/FBOSwitch';

const ShippingExtrasSettingsForm: React.FC = () => {
  const classes = useShippingWizardStyle();

  const { settings, setSettings, errors } = useExtraSettings();

  const selectedWeightUnit = useMemo(() => {
    return WEIGHT_UNITS.find((u) => u.name === settings.unitOfMeasure) || null;
  }, [settings.unitOfMeasure]);

  const handleCheckboxChange = useHandleCheckboxChange(setSettings);

  const handleTextFieldChange = useHandleTextFieldChange(setSettings, settings);

  const handleRecipientTypeChange = (
    e: any,
    recipientType: ShippoRecipientType | null
  ) => {
    setSettings((old) => ({ ...old, recipientType }));
  };

  const handleAutoCompleteChange =
    (name: 'recipientType' | 'unitOfMeasure') =>
    (e: any, value: string | null) => {
      setSettings((old) => ({ ...old, [name]: value }));
    };

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex="1"
      height="100%"
      overflow="auto"
      py={1}
    >
      <Box className={classes.wizardRow}>
        <Box>
          <Typography variant="body1" color="textPrimary">
            Use Retail Rates
          </Typography>
        </Box>

        <FBOSwitch
          size="medium"
          checked={settings.useRetailRates}
          onChange={handleCheckboxChange}
          name="useRetailRates"
          dataQa={'use-retail-rates-toggle'}
        />
      </Box>

      <Divider />

      <Box className={classes.wizardRow}>
        <Box>
          <Typography variant="body1" color="textPrimary">
            Saturday Delivery
          </Typography>
        </Box>

        <FBOSwitch
          size="medium"
          checked={settings.saturdayDelivery}
          onChange={handleCheckboxChange}
          name="saturdayDelivery"
          dataQa={'saturday-delivery-toggle'}
        />
      </Box>

      <Divider />

      <Box className={classes.wizardRow}>
        <Box>
          <Typography variant="body1" color="textPrimary">
            Use Insurance
          </Typography>
        </Box>

        <FBOSwitch
          size="medium"
          checked={settings.useInsurance}
          onChange={handleCheckboxChange}
          name="useInsurance"
          dataQa={'use-insurance-toggle'}
        />
      </Box>

      <Divider />

      <Box className={classes.wizardRow}>
        <Box>
          <Typography variant="body1" color="textPrimary">
            Contains Dry Ice
          </Typography>
        </Box>

        <FBOSwitch
          size="medium"
          checked={settings.containsDryIce}
          onChange={handleCheckboxChange}
          name="containsDryIce"
          dataQa={'contains-dry-ice-toggle'}
        />
      </Box>
      {settings.containsDryIce && (
        <>
          <Box className={classes.wizardRow}>
            <Box>
              <Typography variant="body1" color="textPrimary">
                Dry Ice Weight
              </Typography>
            </Box>

            <Box width="45%">
              <TextField
                placeholder="Enter dry ice weight"
                value={settings.dryIceWeight}
                name="dryIceWeight"
                onChange={handleTextFieldChange}
                error={!!errors.dryIceWeight}
                className="redesign"
                variant="standard"
              />
            </Box>
          </Box>

          <Box className={classes.wizardRow}>
            <Box>
              <Typography variant="body1" color="textPrimary">
                Unit of Measure
              </Typography>
            </Box>

            <Box width="45%">
              <Autocomplete
                options={WEIGHT_UNITS}
                onChange={(e: any, val: WeightUnit | null) =>
                  handleAutoCompleteChange('unitOfMeasure')(
                    e,
                    val ? val.name : null
                  )
                }
                getOptionLabel={(wu: WeightUnit) => wu.name}
                value={selectedWeightUnit}
                error={!!errors.unitOfMeasure}
              />
            </Box>
          </Box>
        </>
      )}
      <Box>
        <Divider />
      </Box>

      <Box className={classes.wizardRow}>
        <Box>
          <Typography variant="body1" color="textPrimary">
            Contains Alcohol
          </Typography>
        </Box>

        <FBOSwitch
          size="medium"
          checked={settings.containsAlcohol}
          onChange={handleCheckboxChange}
          name="containsAlcohol"
          dataQa={'contains-alcohol-toggle'}
        />
      </Box>
      {settings.containsAlcohol && (
        <Box className={classes.wizardRow}>
          <Box>
            <Typography variant="body1" color="textPrimary">
              Recipient Type
            </Typography>
          </Box>

          <Box width="45%">
            <Autocomplete
              options={Object.values(ShippoRecipientType)}
              placeholder="Select Type"
              onChange={handleRecipientTypeChange}
              value={settings.recipientType}
              error={!!errors.recipientType}
            />
          </Box>
        </Box>
      )}

      <Box>
        <Divider />
      </Box>

      <Box className={classes.wizardRow}>
        <Typography variant="body1" color="textPrimary">
          Default signature confirmation
        </Typography>
        <Box width="45%">
          <TextField
            placeholder="Enter default signature"
            value={settings.defaultSignature}
            name="defaultSignature"
            onChange={handleTextFieldChange}
            className="redesign"
            variant="standard"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default memo(ShippingExtrasSettingsForm);
