import _ from 'lodash';
import { TrackingDataTypes } from 'services/settings/tracking';
import { Column } from '../ItemsTable';

export const sortMultipleTrackingColumns = (columns: Column[]): Column[] => {
  const newColumns: Column[] = [];

  //remove icon column first
  columns.forEach((column) => {
    if (column.title === 'Remove') {
      newColumns.push(column);
    }
  });

  //group text next
  columns.forEach((column) => {
    if (_.get(column, 'meta.trackingDataType') === TrackingDataTypes.Text) {
      newColumns.push(column);
    }
  });

  //group dates next
  columns.forEach((column) => {
    if (_.get(column, 'meta.trackingDataType') === TrackingDataTypes.Date) {
      newColumns.push(column);
    }
  });

  //qty is second to last
  columns.forEach((column) => {
    if (column.title === 'Quantity') {
      newColumns.push(column);
    }
  });

  //serial is last
  columns.forEach((column) => {
    if (
      _.get(column, 'meta.trackingDataType') === TrackingDataTypes.SerialNumber
    ) {
      newColumns.push(column);
    }
  });

  return newColumns.filter(
    (col) =>
      _.get(col, 'meta') || col.title === 'Quantity' || col.title === 'Remove'
  );
};
