import React from 'react';

import { DisplayPick, Pick } from 'services/picking';
import { DisplaySalesOrder, SalesOrder } from 'services/salesOrders';
import { IconNames } from 'ui/theme';
import FBOTag from 'ui/theme/components/FBOTag/FBOTag';
import { StockStatus } from './types';

type StockStatusTagProps = {
  row: SalesOrder | Pick | DisplayPick | DisplaySalesOrder;
};

export const FBOStockStatusTag: React.FC<StockStatusTagProps> = ({ row }) => {
  const defaultProps = {
    bgColor: '#FFF',
    color: '#192229',
    style: { borderColor: '#E2E8EE' },
  };
  switch (row.stockStatus) {
    case StockStatus.PartialStock:
      return (
        <FBOTag
          {...defaultProps}
          label="Partial Stock"
          variant="outlined"
          icon={IconNames.FBOPartialStock}
        />
      );
    case StockStatus.InStock:
      return (
        <FBOTag
          {...defaultProps}
          label="In Stock"
          variant="outlined"
          icon={IconNames.FBOInStock}
        />
      );
    case StockStatus.NoStock:
      return (
        <FBOTag
          {...defaultProps}
          label="No Stock"
          variant="outlined"
          icon={IconNames.FBONoStock}
        />
      );
    default:
      return null;
  }
};
