import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';

import { colorPalette } from 'ui/theme';

import { CardRowProps } from './types';
import { BooleanDescriptor, RowDescriptor } from '../../types';

const getBooleanValue = (props: BooleanDescriptor & CardRowProps) => {
  if (props.field) {
    return _.get(props.data, props.field, false);
  }

  if (props.subBooleanRows) {
    return props.subBooleanRows
      .map((r) => _.get(props.data, r.field || ''))
      .includes(true);
  }

  return false;
};

export const useCardRowStyle = makeStyles<Theme, RowDescriptor & CardRowProps>(
  (theme: Theme) => {
    return {
      rowContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 56,
        padding: theme.spacing(2),
      },
      imgContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 88,
        padding: theme.spacing(2),
      },
      booleanText: {
        color: (props) =>
          getBooleanValue(props as any)
            ? theme.palette.primary.main
            : theme.palette.text.disabled,
      },
      text: {
        color: (props) =>
          _.get(props.data, props.field || '')
            ? theme.palette.text.primary
            : theme.palette.text.disabled,
        width: '40%',
        textAlign: 'right',
      },
      titleContainer: {
        width: '40%',
      },
      link: {
        color: colorPalette.fishbowlCorporateLightBlue,
        width: '40%',
        textAlign: 'right',
        ...theme.typography.body1,
      },
    };
  }
);
