import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colorPalette } from 'ui/theme';

export const useShippingPageStyle = makeStyles(() => ({
  container: {
    paddingBottom: '64px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export const useShippingWizardStyle = makeStyles((theme: Theme) => ({
  paper: {
    width: '832px',
    padding: 0,
  },
  container: {
    paddingBottom: '64px',
  },
  sidebar: {
    width: 200,
    borderRight: `1px solid ${colorPalette.applicationDarkGray10}`,
  },
  content: {
    width: '100%',
    flex: 1,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderTop: `1px solid ${colorPalette.applicationDarkGray10}`,
  },
  tabs: {
    backgroundColor: 'transparent',
    overflow: 'auto',
    overflowX: 'hidden',
    width: '100%',
    '& .MuiTabs-indicator': { backgroundColor: 'transparent' },
    '& .MuiTabs-scroller': {
      overflow: 'unset !important',
    },
  },
  tab: {
    minHeight: 56,
    height: 56,
    borderRadius: 2,
    textTransform: 'none',
    borderBottom: `1px solid ${colorPalette.applicationDarkGray10}`,
    '&.Mui-selected': {
      backgroundColor: colorPalette.applicationLightGray,
      border: 'none',
      '& svg': {
        fill: colorPalette.applicationDarkGray80,
      },
    },
    '&.MuiTab-labelIcon': {
      paddingTop: 6,
    },
    '& .MuiTab-wrapper': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      '& svg': {
        marginBottom: '0 !important',
        marginRight: theme.spacing(2),
        fill: colorPalette.applicationMediumGray,
      },
    },
  },
  activeIcon: {
    color: 'green',
    fill: `${colorPalette.fishbowlCorporateBlue} !important`,
  },
  wizardRow: {
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 48,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));
