import {
  createApiReducer,
  chainReducers,
  INITIAL_API_STATUS,
} from 'services/api';

import { combineReducers } from 'redux';

import { OrderPrioritiesActionTypes } from './actions';
import { transformOrderPriority } from '../transformations';

const initialState = {
  _status: INITIAL_API_STATUS,
  items: [],
};

const orderPrioritiesReducer = createApiReducer(
  [
    OrderPrioritiesActionTypes.FETCH_PRIORITY_TYPES_START,
    OrderPrioritiesActionTypes.FETCH_PRIORITY_TYPES_SUCCESS,
    OrderPrioritiesActionTypes.FETCH_PRIORITY_TYPES_FAILURE,
  ],
  (data) => ({ items: data.results.map(transformOrderPriority) }),
  (data) => data
);

const orderPriorityChainedReducers = chainReducers(
  initialState,
  orderPrioritiesReducer
);

export default combineReducers({
  orderPriorities: orderPriorityChainedReducers,
});
