export const isTestEnv = () => !!localStorage.getItem('testEnv');

export const enableTestEnv = () => {
  localStorage.setItem('testEnv', 'true');

  window.location.reload();
};

export const disableTestEnv = () => {
  localStorage.removeItem('testEnv');
};
