import { PickStatus, PickItemStatus } from 'services/picking';
import { ShipStatus } from 'services/shipping';
import {
  PaymentStatus,
  SalesOrderPaymentStatus,
  SalesOrderStatus,
  SalesOrderTableRowOrderType,
} from 'services/salesOrders';
import { SerialTableStatus } from 'ui/components/Table/SerialTable';
import { colorPalette } from 'ui/theme';
import { ReceiptStatus, ReceiptItemStatus } from 'services/receiving';
import {
  PurchaseOrderItemOrderType,
  PurchaseOrderItemStatus,
  PurchaseOrderStatus,
} from 'services/purchaseOrders';
import { ChannelStatus } from 'services/commerce/channels/types';
import { SerialListStatus } from 'services/inventory';
import { LocationType } from 'services/locations';
import { DefaultStatus } from '../components/FBOStatusDot/FBOStatusDot';
import { ExportStatus } from 'ui/modules/accounting/pages/AccountingSettingsPage/components/CardHistory/types';
export const colorStatusPaletteMap = {
  [PickStatus.Open]: [colorPalette.statusGreen, colorPalette.statusGreen],
  [PickStatus.Started]: ['#808089', colorPalette.statusOrange],
  [PickStatus.PartiallyPicked]: ['#808089', colorPalette.statusGreen],
  [PickStatus.Committed]: [
    colorPalette.statusOrange,
    colorPalette.statusOrange,
  ],
  [PickStatus.Finished]: [
    colorPalette.fishbowlCorporateLightBlue,
    colorPalette.fishbowlCorporateLightBlue,
  ],
  [PickItemStatus.Available]: [
    colorPalette.statusGreen,
    colorPalette.statusGreen,
  ],
  [PickItemStatus.Hold]: ['#808089', '#808089'],
  [PickItemStatus.Short]: [colorPalette.statusRed, colorPalette.statusRed],
  [ShipStatus.Open]: [colorPalette.statusGreen, colorPalette.statusGreen],
  [ShipStatus.Packed]: [colorPalette.statusOrange, colorPalette.statusOrange],
  [ShipStatus.Shipped]: [
    colorPalette.fishbowlCorporateLightBlue,
    colorPalette.fishbowlCorporateLightBlue,
  ],
  [SalesOrderStatus.Estimate]: [
    colorPalette.statusGreen,
    colorPalette.statusGreen,
  ],
  [SalesOrderStatus.Issued]: ['#808089', colorPalette.statusGreen],
  [SalesOrderStatus.Cancelled]: [
    colorPalette.statusYellow,
    colorPalette.statusYellow,
  ],
  [SalesOrderStatus.Picked]: [
    colorPalette.statusOrange,
    colorPalette.statusOrange,
  ],
  [SalesOrderStatus.Picking]: [
    '#808089',
    colorPalette.fishbowlCorporateLightBlue,
  ],
  [SalesOrderStatus.PartiallyPicked]: ['#808089', colorPalette.statusGreen],
  [SalesOrderStatus.PartiallyFulfilled]: ['#808089', colorPalette.statusGreen],
  [SalesOrderStatus.Expired]: ['#808089', '#808089'],
  [SalesOrderStatus.Voided]: [colorPalette.statusRed, colorPalette.statusRed],
  [SalesOrderStatus.ClosedShort]: ['#808089', '#808089'],
  [SalesOrderStatus.Fulfilled]: [
    colorPalette.fishbowlCorporateLightBlue,
    colorPalette.fishbowlCorporateLightBlue,
  ],
  [SerialTableStatus.AutoSelected]: ['#808089', colorPalette.statusGreen],
  [ReceiptStatus.Open]: [colorPalette.statusGreen, colorPalette.statusGreen],
  [ReceiptStatus.Received]: [
    colorPalette.statusOrange,
    colorPalette.statusOrange,
  ],
  [ReceiptStatus.PartiallyReceived]: ['#808089', colorPalette.statusGreen],
  [ReceiptStatus.Reconciled]: [
    '#808089',
    colorPalette.fishbowlCorporateLightBlue,
  ],
  [ReceiptStatus.PartiallyReconciled]: ['#808089', colorPalette.statusGreen],
  [ReceiptStatus.Fulfilled]: [
    colorPalette.fishbowlCorporateLightBlue,
    colorPalette.fishbowlCorporateLightBlue,
  ],
  [ReceiptStatus.PartiallyFulfilled]: ['#808089', colorPalette.statusGreen],
  [ReceiptItemStatus.Open]: [
    colorPalette.statusGreen,
    colorPalette.statusGreen,
  ],
  [ReceiptItemStatus.Received]: ['#808089', colorPalette.statusOrange],
  [ReceiptItemStatus.Reconciled]: [
    '#808089',
    colorPalette.fishbowlCorporateLightBlue,
  ],
  [ReceiptItemStatus.Rejected]: [
    colorPalette.statusRed,
    colorPalette.statusRed,
  ],
  [PurchaseOrderStatus.BidRequest]: [
    colorPalette.statusGreen,
    colorPalette.statusGreen,
  ],
  [PurchaseOrderStatus.Issued]: ['#808089', colorPalette.statusGreen],
  [PurchaseOrderStatus.Received]: [
    colorPalette.statusOrange,
    colorPalette.statusOrange,
  ],
  [PurchaseOrderStatus.PartiallyReceived]: [
    '#808089',
    colorPalette.statusGreen,
  ],
  [PurchaseOrderStatus.Reconciled]: [
    '#808089',
    colorPalette.fishbowlCorporateLightBlue,
  ],
  [PurchaseOrderStatus.PartiallyReconciled]: [
    '#808089',
    colorPalette.statusGreen,
  ],
  [PurchaseOrderStatus.Fulfilled]: [
    colorPalette.fishbowlCorporateLightBlue,
    colorPalette.fishbowlCorporateLightBlue,
  ],
  [PurchaseOrderStatus.PartiallyFulfilled]: [
    '#808089',
    colorPalette.statusGreen,
  ],
  [PurchaseOrderStatus.ClosedShort]: ['#808089', '#808089'],
  [PurchaseOrderStatus.Voided]: [
    colorPalette.statusRed,
    colorPalette.statusRed,
  ],
  [PurchaseOrderStatus.Expired]: ['#808089', '#808089'],
  [PurchaseOrderStatus.Picking]: [
    '#808089',
    colorPalette.fishbowlCorporateLightBlue,
  ],
  [PurchaseOrderStatus.PartiallyPicked]: ['#808089', colorPalette.statusGreen],
  [PurchaseOrderStatus.Picked]: [
    colorPalette.statusOrange,
    colorPalette.statusOrange,
  ],
  [PurchaseOrderItemStatus.Entered]: [
    colorPalette.statusGreen,
    colorPalette.statusGreen,
  ],
  [SalesOrderTableRowOrderType.Mixed]: ['#808089', colorPalette.statusRed],
  [SalesOrderTableRowOrderType.CreditReturn]: [
    colorPalette.statusRed,
    colorPalette.statusRed,
  ],
  [SalesOrderTableRowOrderType.Sale]: [
    colorPalette.statusGreen,
    colorPalette.statusGreen,
  ],
  [SalesOrderTableRowOrderType.DropShip]: [
    colorPalette.statusYellow,
    colorPalette.statusYellow,
  ],
  [SalesOrderPaymentStatus.Paid]: [
    colorPalette.statusGreen,
    colorPalette.statusGreen,
  ],
  [SalesOrderPaymentStatus.Unpaid]: [
    colorPalette.statusRed,
    colorPalette.statusRed,
  ],
  [SalesOrderPaymentStatus.PartiallyPaid]: [
    colorPalette.statusOrange,
    colorPalette.statusOrange,
  ],
  [PurchaseOrderItemOrderType.Purchase]: [
    colorPalette.statusGreen,
    colorPalette.statusGreen,
  ],
  [PurchaseOrderItemOrderType.Mixed]: ['#808089', colorPalette.statusRed],
  [PurchaseOrderItemOrderType.CreditReturn]: [
    colorPalette.statusRed,
    colorPalette.statusRed,
  ],
  [PaymentStatus.Canceled]: [colorPalette.statusRed, colorPalette.statusRed],
  [PaymentStatus.Paid]: [colorPalette.statusGreen, colorPalette.statusGreen],
  [PaymentStatus.Expired]: [
    colorPalette.statusOrange,
    colorPalette.statusOrange,
  ],
  [PaymentStatus.Pending]: ['#808089', colorPalette.statusOrange],
  [PaymentStatus.Refunded]: ['#808089', '#808089'],
  [ChannelStatus.Connected]: [
    colorPalette.statusGreen,
    colorPalette.statusGreen,
  ],
  [ChannelStatus.Disconnected]: [
    colorPalette.statusRed,
    colorPalette.statusRed,
  ],
  [SerialListStatus.Valid]: [
    colorPalette.statusGreen,
    colorPalette.statusGreen,
  ],
  [SerialListStatus.Invalid]: [colorPalette.statusRed, colorPalette.statusRed],
  [SerialTableStatus.AutoSelected]: [
    colorPalette.statusOrange,
    colorPalette.statusOrange,
  ],
  [SerialTableStatus.PartialSelected]: [
    colorPalette.statusYellow,
    colorPalette.statusYellow,
  ],
  [SerialTableStatus.Success]: [
    colorPalette.statusGreen,
    colorPalette.statusGreen,
  ],
  [LocationType.Stock]: [colorPalette.statusGreen, colorPalette.statusGreen],
  [LocationType.Shipping]: [
    colorPalette.statusYellow,
    colorPalette.statusYellow,
  ],
  [LocationType.Receiving]: [
    colorPalette.statusGreen,
    colorPalette.statusGreen,
  ],
  [LocationType.Locked]: [colorPalette.statusRed, colorPalette.statusRed],
};

export const colorStatusPaletteMapRestyled = {
  [PickStatus.Open]: colorPalette.redesign.statusColors.lightGray,
  [PickStatus.Started]: colorPalette.redesign.statusColors.lightOrange,
  [PickStatus.PartiallyPicked]: colorPalette.redesign.statusColors.lightYellow,
  [PickStatus.Committed]: colorPalette.redesign.statusColors.lightPurple,
  [PickStatus.Finished]: colorPalette.redesign.statusColors.lightGreen,
  [PickItemStatus.Available]: colorPalette.redesign.statusColors.lightGray,
  [PickItemStatus.Hold]: colorPalette.redesign.statusColors.lightGray,
  [PickItemStatus.Short]: colorPalette.redesign.statusColors.lightRed,
  [ShipStatus.Open]: colorPalette.redesign.statusColors.lightGray,
  [ShipStatus.Packed]: colorPalette.redesign.statusColors.lightPurple,
  [ShipStatus.Shipped]: colorPalette.redesign.statusColors.lightGreen,
  [SalesOrderStatus.Estimate]: colorPalette.redesign.statusColors.lightGray,
  [SalesOrderStatus.Issued]: colorPalette.redesign.statusColors.lightBlue,
  [SalesOrderStatus.Cancelled]: colorPalette.redesign.statusColors.lightRed,
  [SalesOrderStatus.Picked]: colorPalette.redesign.statusColors.lightPurple,
  [SalesOrderStatus.Picking]: colorPalette.redesign.statusColors.lightOrange,
  [SalesOrderStatus.PartiallyPicked]:
    colorPalette.redesign.statusColors.lightYellow,
  [SalesOrderStatus.PartiallyFulfilled]:
    colorPalette.redesign.statusColors.lightYellow,
  [SalesOrderStatus.Expired]: colorPalette.redesign.statusColors.lightRed,
  [SalesOrderStatus.Voided]: colorPalette.redesign.statusColors.lightRed,
  [SalesOrderStatus.ClosedShort]:
    colorPalette.redesign.statusColors.lightYellow,
  [SalesOrderStatus.Fulfilled]: colorPalette.redesign.statusColors.lightGreen,
  [SalesOrderPaymentStatus.Paid]: colorPalette.redesign.statusColors.lightGreen,
  [SalesOrderPaymentStatus.Unpaid]: colorPalette.redesign.statusColors.lightRed,
  [SalesOrderPaymentStatus.PartiallyPaid]:
    colorPalette.redesign.statusColors.lightYellow,
  [SerialTableStatus.AutoSelected]:
    colorPalette.redesign.statusColors.lightOrange,
  [SerialTableStatus.Success]: colorPalette.redesign.statusColors.lightGreen,
  [SerialTableStatus.PartialSelected]:
    colorPalette.redesign.statusColors.lightOrange,

  [ReceiptStatus.Open]: colorPalette.redesign.statusColors.lightGray,
  [ReceiptStatus.Received]: colorPalette.redesign.statusColors.lightPurple,
  [ReceiptStatus.PartiallyReceived]:
    colorPalette.redesign.statusColors.lightYellow,
  [ReceiptStatus.Reconciled]: colorPalette.redesign.statusColors.lightOrange,
  [ReceiptStatus.PartiallyReconciled]:
    colorPalette.redesign.statusColors.lightYellow,
  [ReceiptStatus.Fulfilled]: colorPalette.redesign.statusColors.lightGreen,
  [ReceiptStatus.PartiallyFulfilled]:
    colorPalette.redesign.statusColors.lightYellow,
  [ReceiptItemStatus.Open]: colorPalette.redesign.statusColors.lightGray,
  [ReceiptItemStatus.Received]: colorPalette.redesign.statusColors.lightPurple,
  [ReceiptItemStatus.Reconciled]:
    colorPalette.redesign.statusColors.lightOrange,
  [ReceiptItemStatus.Rejected]: colorPalette.redesign.statusColors.lightRed,
  [PurchaseOrderStatus.BidRequest]:
    colorPalette.redesign.statusColors.lightGray,
  [PurchaseOrderStatus.Issued]: colorPalette.redesign.statusColors.lightBlue,
  [PurchaseOrderStatus.Received]:
    colorPalette.redesign.statusColors.lightPurple,
  [PurchaseOrderStatus.PartiallyReceived]:
    colorPalette.redesign.statusColors.lightYellow,
  [PurchaseOrderStatus.Reconciled]:
    colorPalette.redesign.statusColors.lightOrange,
  [PurchaseOrderStatus.PartiallyReconciled]:
    colorPalette.redesign.statusColors.lightYellow,
  [PurchaseOrderStatus.Fulfilled]:
    colorPalette.redesign.statusColors.lightGreen,
  [PurchaseOrderStatus.PartiallyFulfilled]:
    colorPalette.redesign.statusColors.lightYellow,
  [PurchaseOrderStatus.ClosedShort]:
    colorPalette.redesign.statusColors.lightYellow,
  [PurchaseOrderStatus.Voided]: colorPalette.redesign.statusColors.lightRed,
  [PurchaseOrderStatus.Expired]: colorPalette.redesign.statusColors.lightRed,
  [PurchaseOrderStatus.Picking]: colorPalette.redesign.statusColors.lightOrange,
  [PurchaseOrderStatus.PartiallyPicked]:
    colorPalette.redesign.statusColors.lightYellow,
  [PurchaseOrderStatus.Picked]: colorPalette.redesign.statusColors.lightPurple,
  [PurchaseOrderItemStatus.Entered]:
    colorPalette.redesign.statusColors.lightGray,
  [SalesOrderTableRowOrderType.Mixed]:
    colorPalette.redesign.statusColors.lightBlue,
  [SalesOrderTableRowOrderType.CreditReturn]:
    colorPalette.redesign.statusColors.lightRed,
  [SalesOrderTableRowOrderType.Sale]:
    colorPalette.redesign.statusColors.lightGreen,
  [SalesOrderTableRowOrderType.DropShip]:
    colorPalette.redesign.statusColors.lightPurple,
  [SerialTableStatus.Success]: colorPalette.redesign.statusColors.lightGreen,
  [SerialTableStatus.PartialSelected]:
    colorPalette.redesign.statusColors.lightOrange,
  [PurchaseOrderItemOrderType.Purchase]:
    colorPalette.redesign.statusColors.lightGreen,
  [PurchaseOrderItemOrderType.Mixed]:
    colorPalette.redesign.statusColors.lightBlue,
  [PurchaseOrderItemOrderType.CreditReturn]:
    colorPalette.redesign.statusColors.lightRed,
  [PaymentStatus.Canceled]: colorPalette.redesign.statusColors.lightRed,
  [PaymentStatus.Paid]: colorPalette.redesign.statusColors.lightGreen,
  [PaymentStatus.Expired]: colorPalette.redesign.statusColors.lightRed,
  [PaymentStatus.Pending]: colorPalette.redesign.statusColors.lightYellow,
  [PaymentStatus.Refunded]: colorPalette.redesign.statusColors.lightRed,
  [ChannelStatus.Connected]: colorPalette.redesign.statusColors.lightGreen,
  [ChannelStatus.Disconnected]: colorPalette.redesign.statusColors.lightRed,
  [SerialListStatus.Valid]: colorPalette.redesign.statusColors.lightGreen,
  [SerialListStatus.Invalid]: colorPalette.redesign.statusColors.lightRed,
  [LocationType.Stock]: colorPalette.redesign.statusColors.lightGreen,
  [LocationType.Shipping]: colorPalette.redesign.statusColors.lightYellow,
  [LocationType.Receiving]: colorPalette.redesign.statusColors.lightPurple,
  [LocationType.Locked]: colorPalette.redesign.statusColors.lightRed,
  [DefaultStatus.Transparent]: colorPalette.redesign.statusColors.transparent,
  [ExportStatus.Success]: colorPalette.redesign.statusColors.lightGreen,
  [ExportStatus.Failure]: colorPalette.redesign.statusColors.lightRed,
};
