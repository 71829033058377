import { makeStyles } from '@mui/styles';

export const useReconcileWizardExpenseslStyle = makeStyles(() => ({
  widthAmount: {
    width: 80,
  },
  widthPrice: {
    width: 130,
  },
  widthAutoWidth: {
    whiteSpace: 'nowrap',
  },
  widthIconWidth: {
    paddingLeft: 0,
    width: 60,
    textOverflow: 'unset',
  },
}));
