import { createFilterOptions, FilterOptionsState } from '@mui/material';

import { Pagination } from 'services/search';
import { Account, fetchAccountsAPI } from 'services/accounting';

const filter = createFilterOptions<Account>();

export const accountsAutocompleteOptionLabel = (accountingClass: Account) => {
  return accountingClass.name || '';
};

export const accountsAutocompleteFilterOptions = (
  options: Account[],
  params: FilterOptionsState<Account>
) => {
  const filtered = filter(options, params);

  return filtered;
};

export const resolvedFetchAccountsFunction = (
  expands?: string[],
  customQuickSearchColumns?: string[]
) => {
  return (pagination: Pagination, searchValue: string | null) =>
    fetchAccountsAPI({
      pagination,
      expands: expands,
      quickSearchValue: searchValue,
      customQuickSearchColumns,
    });
};
