import { Dates } from 'helpers';
import { DisplayPick } from 'services/picking';
import { Pagination } from 'services/search';

export interface PickingSearchResultsProps {
  picks: DisplayPick[];
  activePickId: null | number;
  handlePickClick: (id: number) => void;
  isLoadingPicks: boolean;
  pagination: Pagination;
  onPaginationChange: (pagination: Pagination) => void;
  selectedItems: number[];
  setSelectedItems: React.Dispatch<React.SetStateAction<number[]>>;
  onPageAction: (action: PickingPageAction, date?: Dates) => void;
  activeDate: Dates;
  onVoidClicked: () => void;
  onGroupClicked: () => void;
  onPrintPickTicketsClicked: () => void;
  dataQa?: string;
}

export enum PickingPageAction {
  CustomFields,
  ChangeDate,
}
