import { IconNames } from 'ui/theme';
import { MenuItem, MenuItemVariant } from 'ui/components/Menu';
import { PermissionType } from 'services/permissions';
import { ReportId } from 'services/reports';

export const createActionBarOptions = (
  id: number | null,
  onDeleteClick: () => void,
  onDuplicateClick: () => void,
  onReportClick: (reportId: ReportId) => void
): MenuItem[] => [
  {
    icon: IconNames.FBOReportSmall,
    label: 'Show Report',
    disabled: !id || id < 0,
    separateAfter: true,
    subItems: [
      {
        label: 'Inventory On Hand',
        onOptionClick: () => onReportClick(ReportId.Items),
        separateAfter: true,
      },
      {
        label: 'Item Price List',
        onOptionClick: () => onReportClick(ReportId.ItemPriceList),
        separateAfter: true,
      },
      {
        label: 'Total Item Sales',
        onOptionClick: () => onReportClick(ReportId.TotalItemSales),
        separateAfter: true,
      },
      {
        label: 'Event History',
        onOptionClick: () => onReportClick(ReportId.InventoryEventHistory),
      },
    ],
  },
  {
    label: 'Duplicate Item',
    onOptionClick: onDuplicateClick,
    icon: IconNames.FBODuplicateSmall,
    permissions: [PermissionType.ItemsCreate],
    dataQa: 'item-three-dot-menu-duplicate-item',
    separateAfter: true,
  },
  {
    label: 'Delete Item',
    onOptionClick: onDeleteClick,
    icon: IconNames.FBODeleteSmall,
    dataQa: 'item-three-dot-menu-delete',
    permissions: [PermissionType.ItemsDelete],
    variant: MenuItemVariant.Danger,
  },
];
