import React from 'react';
import { Typography } from '@mui/material';
import moment from 'moment';

import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const cardHistoryColumns: (format: string) => Column[] = (format) => [
  {
    type: ColumnTypes.custom,
    field: 'dateCreated',
    title: 'Date',
    render: (row) => {
      return <Typography>{moment(row.dateCreated).format(format)}</Typography>;
    },
  },
  {
    type: ColumnTypes.custom,
    field: 'dateCreated',
    title: 'Time',
    render: (row) => {
      return <Typography>{row.dateCreated.toLocaleTimeString()}</Typography>;
    },
  },
  {
    type: ColumnTypes.string,
    field: 'type',
    title: 'Status',
  },
];

export const fboCardHistoryColumns: Column[] = [
  {
    title: 'Date',
    type: ColumnTypes.renderByCustomRow,
    width: '38%',
  },
  {
    title: 'Time',
    type: ColumnTypes.renderByCustomRow,
    width: '38%',
  },
  {
    title: 'Status',
    type: ColumnTypes.renderByCustomRow,
    width: '14%',
  },
  {
    title: 'Details',
    type: ColumnTypes.renderByCustomRow,
    width: '10%',
  },
];
export const fboCardHistoryDetailsColumns: Column[] = [
  {
    title: 'Date',
    type: ColumnTypes.renderByCustomRow,
    width: '10%',
  },
  {
    title: 'Time',
    type: ColumnTypes.renderByCustomRow,
    width: '10%',
  },
  {
    title: 'Details',
    type: ColumnTypes.renderByCustomRow,
    width: '30%',
  },
  {
    title: 'Type',
    type: ColumnTypes.renderByCustomRow,
    width: '10%',
  },
  {
    title: 'Status',
    type: ColumnTypes.renderByCustomRow,
    width: '10%',
  },
  {
    title: 'Results',
    type: ColumnTypes.renderByCustomRow,
    width: '100%',
  },
];
