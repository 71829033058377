import React, { memo } from 'react';

import { Menu } from 'ui/components/Menu';

import IconButton from './IconButton';
import { IconButtonWithMenuProps } from './types';

const IconButtonWithMenu: React.FC<IconButtonWithMenuProps> = (props) => {
  const { items, onClick, ...iconButtonProps } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const isActionMenuVisible = Boolean(anchorEl);

  const handleIconClicked = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
    if (onClick) {
      onClick(event as any);
    }
  };

  const handleActionMenuClose = (
    ev: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    ev.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        {...iconButtonProps}
        onClick={handleIconClicked}
        size="large"
      />
      <Menu
        items={items}
        className="IconButtonWithMenu"
        open={isActionMenuVisible}
        anchorEl={anchorEl}
        onClose={handleActionMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      />
    </>
  );
};

export default memo(IconButtonWithMenu);
