import * as yup from 'yup';

export const trackingTableValidationSchema = yup.object().shape({
  quantity: yup
    .number()
    .nullable(true)
    .test('quantity', 'Quantity too large', function (quantity) {
      return quantity ? quantity <= this.parent.onHand : true;
    }),
});
