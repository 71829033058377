import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const ReconcileWizardExpensesColumns = (
  showMulticurrency: boolean,
  multiCurrencyCode: string,
  homeCurrencyCode: string
): Column[] => {
  let columns: Column[] = [
    {
      title: 'Item',
      width: 350,
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: 'Add To Vendor Bill',
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: 'Quantity',
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: `Unit ${homeCurrencyCode}`,
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: `Total ${homeCurrencyCode}`,
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: '',
      type: ColumnTypes.renderByCustomRow,
    },
  ];

  if (showMulticurrency) {
    columns = [
      {
        title: 'Item',
        width: 350,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: 'Add To Vendor Bill',
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: 'Quantity',
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Unit ${homeCurrencyCode}`,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Unit ${multiCurrencyCode}`,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Total ${homeCurrencyCode}`,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Total ${multiCurrencyCode}`,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: '',
        type: ColumnTypes.renderByCustomRow,
      },
    ];
  }

  return columns;
};
