import _ from 'lodash';
import { COUNTRIES as countries } from './consts';
import { CountryType } from './types';

const mappedSymbols = _.mapValues(
  _.keyBy(countries, 'code'),
  (country: CountryType) => country.currencySymbol
);

export const getCurrencySymbol = (countryCode: string) =>
  mappedSymbols[countryCode] || '$';
