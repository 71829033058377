import { makeStyles } from '@material-ui/styles';
import { ErrorMessageProps } from './types';

export const useErrorMessageStyles = makeStyles<ErrorMessageProps>({
  title: {
    fontWeight: 400,
    fontSize: '30px',
    lineHeight: '38px',
    color: '#262626',
    marginBottom: '0.25em',
  },
  description: {
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '24px',
    color: '#262626',
  },
  button: {
    backgroundColor: '#0035E6',
    color: '#FFF',
  },
  link: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    textDecorationLine: 'underline',
    color: '#0035E6',
    margin: '0 2em',
  },
  container: {
    margin: 0,
    position: 'absolute',
    top: '50%',
    msTransform: 'translateY(-50%)',
    transform: 'translateY(-50%)',
    width: '50%',
  },
});
