import { Uom } from './types';

export const EACH_UOM_ID = 1;

export const defaultUom: Uom = {
  id: null,
  name: null,
  description: null,
  abbreviation: null,
  deleted: false,
  isIntegral: false,
  defaultFlag: false,
  toConversions: [],
  fromConversions: [],
  systemUom: false,
  version: 0,
  dateCreated: new Date(),
  dateLastModified: new Date(),
};
