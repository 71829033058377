import _ from 'lodash';

import { DeclarationItem } from 'services/integrations/shipping/purchaseLabel';
import { ShipItem } from 'services/shipping';

export const transformShipItemToDeclarationItem = (
  shipItem: ShipItem
): DeclarationItem => {
  return {
    name: _.get(shipItem, 'item.name', null),
    description: _.get(shipItem, 'item.description', null),
    countryOfOrigin: null,
    tariffNumber: null,
    declaredValue: _.get(shipItem, 'salesOrderItem.price', null),
    uom: shipItem.uom,
    quantity: shipItem.quantity,
  };
};
