import React, { memo } from 'react';

import { TrackingColumnsProps } from '../../types';
import { TrackingAddColumns } from '../TrackingAddColumns';

const TrackingCycleColumns: React.FC<TrackingColumnsProps> = (props) => (
  <TrackingAddColumns {...props} isCycle />
);

export default memo(TrackingCycleColumns);
