import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { ModalStyleProps } from './types';
import { MODAL_HEIGHT_WITHOUT_CONTENT, MODAL_MAX_HEIGHT } from './consts';

export const useModalStyle = makeStyles<Theme, ModalStyleProps>(
  (theme: Theme) => {
    const modalRootHeight = (props: any) => {
      const { innerContentFullHeight, customHeight, fullScreen } = props;

      let height;

      if (innerContentFullHeight) {
        height = customHeight;
      } else if (fullScreen) {
        height = '100vh';
      } else {
        height = MODAL_MAX_HEIGHT;
      }

      return height;
    };

    const innerContentMaxHeight = (props: any) => {
      const { innerContentFullHeight, customHeight, nestedScrollAreas } = props;
      let height;

      if (innerContentFullHeight) {
        height = '100%';
      } else if (customHeight) {
        height = customHeight;
      } else if (nestedScrollAreas) {
        height = `calc(${MODAL_MAX_HEIGHT} - ${MODAL_HEIGHT_WITHOUT_CONTENT}px)`;
      } else {
        height = 'none';
      }

      return height;
    };

    return {
      modalRoot: {
        maxHeight: (props) => modalRootHeight(props),
        height: (props) => (props.innerContentFullHeight ? '100%' : 'initial'),
        overflow: 'hidden',
        background: 'transparent',
      },
      modalHeader: {
        padding: 0,
      },
      modalContent: {
        backgroundColor: theme.palette.background.paper,
        padding: 0,
        overflowY: (props) => (props.nestedScrollAreas ? 'hidden' : 'auto'),
        '&:first-child': {
          paddingTop: '0',
        },
      },
      modalContentInner: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        padding: (props) =>
          props.withoutDefaultPadding ? 0 : theme.spacing(3),
        height: (props) =>
          props.innerContentFullHeight
            ? '100%'
            : props.customHeight
            ? `${props.customHeight}px`
            : 'auto',
        maxHeight: (props) => innerContentMaxHeight(props),
        minHeight: (props) =>
          props.innerContentFullHeight
            ? 'unset'
            : props.customHeight
            ? `${props.customHeight}px`
            : 'auto',
      },
      modalFooter: {
        backgroundColor: theme.palette.background.paper,
        zIndex: 2,
        justifyContent: 'space-between',
        padding: theme.spacing(3),
        borderTop: ({ footerDivider }) =>
          footerDivider === 'border' ? '1px solid #ebf0f5' : 'none',
        boxShadow: ({ footerDivider }) =>
          footerDivider === 'shadow'
            ? '0 -4px 16px rgba(0, 0, 0, 0.06)'
            : 'none',
      },
      modalLoader: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      },
      modalLoaderBackground: {
        position: 'absolute',
        top: 42,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.58)',
        zIndex: 20,
      },
      buttonGroup: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
      },
      label: {
        color: theme.palette.text.primary,
      },
      apply: {
        paddingRight: theme.spacing(5),
        paddingLeft: theme.spacing(5),
      },
      tabsBackground: {
        backgroundColor: theme.palette.primary.light,
      },
      spinnerContainer: {
        alignItems: 'center',
        justifyContent: 'center',
      },
    };
  }
);
