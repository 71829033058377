import { IconNames } from 'ui/theme';
import { MenuItem } from 'ui/components/Menu';
import { ReportId } from 'services/reports';

export const createActionBarOptions = (
  onReportClick: (reportId: ReportId) => void
): MenuItem[] => [
  {
    icon: IconNames.FBOReportSmall,
    label: 'Show Report',
    subItems: [
      {
        label: 'Inventory On Hand',
        onOptionClick: () => onReportClick(ReportId.Items),
        separateAfter: true,
      },
      {
        label: 'Cycle Count',
        onOptionClick: () => onReportClick(ReportId.CycleCount),
        separateAfter: true,
      },
      {
        label: 'Inventory Valuation Summary',
        onOptionClick: () => onReportClick(ReportId.InventoryValuation),
        separateAfter: true,
      },
      {
        label: 'Reorder',
        onOptionClick: () => onReportClick(ReportId.Reorder),
      },
    ],
  },
];
