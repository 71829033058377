import { makeStyles } from '@mui/styles';

import { colorPalette } from 'ui/theme';

export const useShippingDetailsCardStyle = makeStyles(() => ({
  customStatusPaid: {
    background: colorPalette.statusGreen,
  },
  customStatusUnpaid: {
    background: colorPalette.statusRed,
  },
}));
