import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const remoteSupportTableColumns: Column[] = [
  {
    title: 'First Name',
    field: 'nameFirst',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Last Name',
    field: 'nameLast',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Email',
    field: 'email',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Tenant ID',
    field: 'company.tenant',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Tenant Name',
    field: 'company.name',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'CRMID',
    field: 'crmId',
    sortable: false,
    type: ColumnTypes.string,
  },
  {
    title: 'Last Login',
    field: 'dateLoginAttempt',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Account Type',
    field: 'company.tenantAccountType',
    sortable: true,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: '',
    type: ColumnTypes.renderByCustomRow,
  },
];

export enum TenantAccountType {
  PARTNER = 'PARTNER',
  CUSTOMER = 'CUSTOMER',
  INTERNAL = 'INTERNAL',
  CONSULTANT = 'CONSULTANT',
  TRIAL = 'TRIAL',
  PREVIOUSCUSTOMER = 'PREVIOUS_CUSTOMER',
}
