import React from 'react';

import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { Pagination } from 'services/search';
import { PermissionType } from 'services/permissions';
import { UsersSearchResultsProps } from './types';
import { default as UserRow } from './UserRow';
import { alwaysOnColumns, extraColumn } from './consts';
import { createActionBarOptions } from '../../consts';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';

const UsersSearchCard: React.FC<UsersSearchResultsProps> = ({
  users,
  activeUserId,
  handleUserClick,
  onAddNewPress,
  isLoadingUsers,
  pagination,
  activeDate,
  onPageAction,
  onPaginationChange,
}) => {
  const handlePaginationChange = (newPagination: Pagination) => {
    onPaginationChange(newPagination);
  };

  return (
    <>
      <FBOTitleBar title="Users">
        {activeUserId !== -1 && (
          <FBOButton
            style={{ marginRight: '8px' }}
            variant={!activeUserId ? 'primary' : 'secondary'}
            color="positive"
            size="medium"
            icon="FBOAddCircle"
            onClick={onAddNewPress}
            data-qa="user-new"
            permissions={[PermissionType.UsersCreate]}
          >
            {!activeUserId ? 'Add New' : ''}
          </FBOButton>
        )}
        <FBOMenuButton
          variant="secondary"
          data-qa="users-three-dot-menu"
          items={createActionBarOptions(onPageAction)}
        />
      </FBOTitleBar>

      <ItemsTable
        data={users}
        columns={
          activeUserId
            ? alwaysOnColumns
            : alwaysOnColumns.concat(extraColumn(activeDate))
        }
        activeId={activeUserId}
        RenderCustomRow={UserRow}
        onItemClick={handleUserClick}
        selectableItems={false}
        isLoadingData={isLoadingUsers}
        pagination={pagination}
        onPaginationChange={handlePaginationChange}
        dataQa="users-table"
        showEnhancedTablePaginationActions={!Boolean(activeUserId)}
        emptyTableText="ADD NEW USERS BY PRESSING 'ADD NEW'"
      />
    </>
  );
};

export default UsersSearchCard;
