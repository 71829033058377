import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useReconnectBannerStyle = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FDD8D8',
    padding: '8px 16px',
    gap: '8px',
    minHeight: '56px',
  },
  captionText: {
    fontSize: '14px',
    fontWeight: '600',
    color: '#A50808',
    padding: '0',
  },
  settingsButton: {
    backgroundColor: theme.palette.error.main,
    '&:hover, &:active, &:focus': {
      backgroundColor: theme.palette.error.main,
    },
  },
}));
