import React from 'react';
import { TableCell } from '@mui/material';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import { ItemQuantityRow } from 'services/inventory';
import { OrderType, OrderTypeAbbreviation } from './consts';

const InventoryHeroRow: React.FC<RenderCustomRowProps<ItemQuantityRow>> = (
  props
) => {
  const { row, meta } = props;
  const abbreviation =
    row.orderType === OrderType.PurchaseOrder
      ? OrderTypeAbbreviation.PO
      : OrderTypeAbbreviation.SO;

  return (
    <>
      <TableCell>
        {abbreviation} {row.orderNumber}
      </TableCell>
      <TableCell>
        {row.quantity} {meta[0]}
      </TableCell>
      {(meta[1] === 'allocated' || meta[1] === 'committed') && (
        <TableCell>
          {meta[1] === 'allocated'
            ? row.allocatedQuantity
            : row.committedQuantity}{' '}
          {meta[0]}
        </TableCell>
      )}
      <TableCell>{row.orderStatus}</TableCell>
      <TableCell>{row.orderLocation}</TableCell>
    </>
  );
};

export default React.memo(InventoryHeroRow);
