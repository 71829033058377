import React, { memo, useCallback, useMemo, useState } from 'react';
import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';

import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import DateRangePicker from 'ui/components/TextField/DateRangePicker/DateRangePicker';
import { AdvancedSearchFieldsCmpProps } from 'ui/components/Page/PageWithAdvancedSearch';
import { DateRange } from 'materialui-daterange-picker';
import { ItemType } from 'services/items';
import { Tag } from 'services/tags';
import { getRangeForRequest } from 'ui/components/TextField/DateRangePicker/helpers';
import { transformDateToMomentDate } from 'helpers';
import { TagsAsyncAutocomplete } from 'ui/components/Autocomplete/TagsAutocomplete';

const ItemsAdvancedSearch: React.FC<AdvancedSearchFieldsCmpProps> = (props) => {
  const { setFormValues, formValues } = props;

  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);

  const tagsIds = useMemo(() => {
    return formValues['tags.id'] as number[];
  }, [formValues]);

  const dateCreatedRange: DateRange | null = useMemo(() => {
    if (!formValues.dateCreatedFrom || !formValues.dateCreatedTo) {
      return null;
    }

    return {
      startDate: transformDateToMomentDate(
        formValues.dateCreatedFrom as string
      )!,
      endDate: transformDateToMomentDate(formValues.dateCreatedTo as string)!,
    };
  }, [formValues.dateCreatedFrom, formValues.dateCreatedTo]);

  const dateLastModifiedRange: DateRange | null = useMemo(() => {
    if (!formValues.dateLastModifiedFrom || !formValues.dateLastModifiedTo) {
      return null;
    }

    return {
      startDate: transformDateToMomentDate(
        formValues.dateLastModifiedFrom as string
      )!,
      endDate: transformDateToMomentDate(
        formValues.dateLastModifiedTo as string
      )!,
    };
  }, [formValues.dateLastModifiedFrom, formValues.dateLastModifiedTo]);

  const handleTypeChange = useCallback(
    (e: any, value: any) => {
      setFormValues({ ...formValues, itemType: value || '' });
    },
    [formValues, setFormValues]
  );

  const handleTagChange = useCallback(
    (value: Tag[]) => {
      setSelectedTags(value);
      setFormValues({
        ...formValues,
        'tags.id': value.length ? value.map((i) => i.id!) : null,
      });
    },
    [formValues, setFormValues]
  );

  const handleShowDeletedChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setFormValues({ ...formValues, deleted: checked ? 'true' : null });
    },
    [formValues, setFormValues]
  );

  const handleDateRangeChange = useCallback(
    (field: string) => (value: DateRange) => {
      const requestRange = getRangeForRequest(field, value);

      if (!requestRange) {
        return;
      }

      setFormValues({
        ...formValues,
        ...requestRange,
      });
    },
    [formValues, setFormValues]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TagsAsyncAutocomplete
          label="Tags"
          placeholder="Select tag"
          value={selectedTags}
          onChange={handleTagChange}
          ids={tagsIds}
          dataQa="select-tag"
          disableTagsModal
        />
      </Grid>
      <Grid item xs={6}>
        <Box>
          <DateRangePicker
            label="Date Created"
            placeholder="Select date created range"
            value={dateCreatedRange}
            onChange={handleDateRangeChange('dateCreated')}
            dataQa="date-created-range"
            fullWidth
          />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box>
          <DateRangePicker
            label="Date Modified"
            placeholder="Select last modified range"
            value={dateLastModifiedRange}
            onChange={handleDateRangeChange('dateLastModified')}
            dataQa="date-modified-range"
            fullWidth
          />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          label="Type"
          placeholder="Select type"
          options={Object.values(ItemType)}
          value={formValues.itemType}
          onChange={handleTypeChange}
          dataQa="item-advanced-search-type"
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          className="redesign"
          control={
            <Checkbox
              className="redesign"
              checked={!!formValues.deleted}
              onChange={handleShowDeletedChange}
              color="primary"
              data-qa="show-deleted"
            />
          }
          label="Show Deleted"
        />
      </Grid>
    </Grid>
  );
};

export default memo(ItemsAdvancedSearch);
