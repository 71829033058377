import moment from 'moment';
import _ from 'lodash';

import {
  getSearches,
  addSearch,
  updateSearch,
  deleteSearch,
  Customer,
} from 'services/customers';
import {
  AdvancedSearchFormValues,
  AdvancedSearchReduxFunctions,
} from 'services/search';
import { PaginationDirection } from 'services/url';
import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';
import { Representative } from 'services/representatives';
import { PaymentTerm } from 'services/paymentTerms';
import { Dates } from 'helpers';
import { Tag } from 'services/tags';

export const advancedSearchReduxActions: AdvancedSearchReduxFunctions = {
  add: addSearch,
  delete: deleteSearch,
  update: updateSearch,
  get: getSearches,
};

export const initialCustomerFormValues: AdvancedSearchFormValues = {
  name: null,
  representativeId: null,
  email: null,
  cc: null,
  bcc: null,
  phone: null,
  fax: null,
  'customerAddresses.street': null,
  'customerAddresses.city': null,
  'customerAddresses.state': null,
  'customerAddresses.postalCode': null,
  'customerAddresses.country': null,
  notes: null,
  'tags.id': null,
  parentCustomerId: null,
  paymentTermId: null,
  url: null,
  dateCreatedFrom: null,
  dateCreatedTo: null,
  dateLastModifiedFrom: null,
  dateLastModifiedTo: null,
  deleted: null,
};

export const displayNameMap = {
  name: 'Name',
  representativeId: 'Representative',
  email: 'Email',
  cc: 'CC',
  bcc: 'BCC',
  phone: 'Phone',
  fax: 'Fax',
  notes: 'Notes',
  'tags.id': 'Tag',
  parentCustomerId: 'Parent customer',
  paymentTermId: 'Payment term',
  url: 'URL',
  dateCreatedFrom: 'Date Created After',
  dateCreatedTo: 'Date Created Before',
  dateLastModifiedFrom: 'Date Modified After',
  dateLastModifiedTo: 'Date Modified Before',
  'customerAddresses.street': 'Street',
  'customerAddresses.city': 'City',
  'customerAddresses.state': 'State',
  'customerAddresses.postalCode': 'Postal code',
  'customerAddresses.country': 'Country',
  deleted: 'Deleted',
};

export const createDisplayValueMap = (
  representative: Representative[],
  customers: Customer[],
  paymentTerms: PaymentTerm[],
  tags: Tag[],
  format: string
) => ({
  representativeId: (id: number) =>
    _.get(
      representative.find((r) => r.id === id),
      'firstName',
      null
    ) +
    ' ' +
    (_.get(
      representative.find((r) => r.id === id),
      'lastName'
    ) || ''),
  parentCustomerId: (id: number) =>
    _.get(
      customers.find((c) => c.id === id),
      'name',
      null
    ),
  paymentTermId: (id: number) =>
    _.get(
      paymentTerms.find((p) => p.id === id),
      'name',
      null
    ),
  dateCreatedFrom: (date: string) => (date ? moment(date).format(format) : ''),
  dateCreatedTo: (date: string) => (date ? moment(date).format(format) : ''),
  dateLastModifiedFrom: (date: string) =>
    date ? moment(date).format(format) : '',
  dateLastModifiedTo: (date: string) =>
    date ? moment(date).format(format) : '',
  'tags.id': () => tags.map((t) => t.name).join(', '),
});

export const CUSTOMER_COLUMNS_ALWAYS_ON: Column[] = [
  {
    title: 'Name',
    field: 'name',
    type: ColumnTypes.string,
    sortable: true,
  },
];

const CUSTOMER_COLUMNS_HIDABLE = (date: Dates): Column[] => {
  const resolveDateColumn = (): Column => {
    switch (date) {
      case Dates.DateLastModified:
        return {
          title: 'Date Last Modified',
          field: Dates.DateLastModified,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
        };
      default:
        return {
          title: 'Date Created',
          field: Dates.DateCreated,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
        };
    }
  };

  return [
    {
      title: 'Sales Representative',
      type: ColumnTypes.renderByCustomRow,
      field: 'representative.firstName',
      sortable: true,
    },
    {
      title: 'Phone',
      type: ColumnTypes.renderByCustomRow,
      field: 'phone',
      sortable: true,
    },
    {
      title: 'Email',
      type: ColumnTypes.renderByCustomRow,
      field: 'email',
      sortable: true,
    },
    resolveDateColumn(),
    {
      title: '',
      width: 50,
      type: ColumnTypes.renderByCustomRow,
    },
  ];
};

export const CUSTOMER_COLUMNS = (date: Dates): Column[] => [
  ...CUSTOMER_COLUMNS_ALWAYS_ON,
  ...CUSTOMER_COLUMNS_HIDABLE(date),
];

export const initialPagination = {
  page: 1,
  pageSize: 25,
  sort: {
    sortBy: 'dateLastModified',
    direction: PaginationDirection.Descending,
  },
  totalRows: 25,
};
