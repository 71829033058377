import React, { memo } from 'react';

import { ImageStorageType } from 'services/images';
import { DocumentsTable } from 'ui/components/Documents/DocumentsTable';

import { ReceivingDocumentsTabProps } from './types';

const ReceivingDocumentsTab: React.FC<ReceivingDocumentsTabProps> = (props) => {
  const { activeReceipt, setActiveReceipt, customDocumentSave } = props;

  return (
    <DocumentsTable
      storageType={ImageStorageType.ReceivingDocuments}
      data={activeReceipt}
      setData={setActiveReceipt}
      customDocumentSave={customDocumentSave}
    />
  );
};

export default memo(ReceivingDocumentsTab);
