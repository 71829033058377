import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useTrackingRowStyle = makeStyles((theme: Theme) => ({
  addTrackingMenu: {
    marginTop: theme.spacing(5),
  },
  popoverTracking: {
    marginTop: theme.spacing(3.25),
  },
}));
