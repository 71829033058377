import React, { forwardRef, memo } from 'react';
import { Button } from '@mui/material';
import { EnhancedTablePaginationLabelProps } from './types';

const EnhancedTablePaginationLabel = forwardRef<
  HTMLButtonElement,
  EnhancedTablePaginationLabelProps
>(
  (
    {
      from,
      to,
      count,
      onClick,
      showSimpleCount,
      className,
      disableRipple = false,
      disableTouchRipple = false,
    }: EnhancedTablePaginationLabelProps,
    ref
  ) => {
    const rowsText = `${from}-${to}/${count}`;

    const SimpleCount = <span style={{ marginRight: '17px' }}>{rowsText}</span>;

    const RowsButton = (
      <Button
        ref={ref}
        className={className}
        onClick={onClick}
        disableRipple={disableRipple}
        disableTouchRipple={disableTouchRipple}
      >
        {rowsText}
      </Button>
    );

    if (showSimpleCount) return SimpleCount;

    return RowsButton;
  }
);

export default memo(EnhancedTablePaginationLabel);
