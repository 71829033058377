import React, { memo } from 'react';
import { CardButtonProps } from './types';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const CardButton: React.FC<CardButtonProps> = ({
  label,
  onClick,
  variant,
  color,
  dataQa,
  ...props
}) => {
  return (
    <FBOButton
      variant={variant}
      color={color}
      onClick={onClick}
      data-qa={dataQa}
      fullWidth
      {...props}
    >
      {label}
    </FBOButton>
  );
};

export default memo(CardButton);
