import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { PermissionType } from 'services/permissions';
import SecureRoute from 'ui/components/Route/SecureRoute';

import { Routes as SalesRoutes } from './routes';
import {
  CustomersPage,
  SalesOrderPage,
  PickingPage,
  ShippingPage,
  PricingRulesPage,
} from '../pages';
import { SalesRouterProps, SalesRouterCmp } from './types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SalesRouter: SalesRouterCmp = (props: SalesRouterProps) => {
  return (
    <Routes>
      <Route
        element={<SecureRoute permissions={[PermissionType.CustomersView]} />}
      >
        <Route path={CustomersPage.route} element={<CustomersPage />} />
      </Route>
      <Route
        element={
          <SecureRoute permissions={[PermissionType.PricingRulesView]} />
        }
      >
        <Route path={PricingRulesPage.route} element={<PricingRulesPage />} />
      </Route>
      <Route
        element={<SecureRoute permissions={[PermissionType.SalesOrderView]} />}
      >
        <Route path={SalesRoutes.SalesOrderPage} element={<SalesOrderPage />} />
      </Route>
      <Route
        element={<SecureRoute permissions={[PermissionType.PickingView]} />}
      >
        <Route path={PickingPage.route} element={<PickingPage />} />
      </Route>
      <Route
        element={<SecureRoute permissions={[PermissionType.ShippingView]} />}
      >
        <Route path={ShippingPage.route} element={<ShippingPage />} />
      </Route>
    </Routes>
  );
};

SalesRouter.route = SalesRoutes.SalesRouter;

export default SalesRouter;
