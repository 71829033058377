/**
 * Defines new endpoints and checks if the given URL contains any of the new endpoints.
 *
 * @param {string} url - The URL to check.
 * @return {boolean} Returns true if the URL contains any of the new endpoints, otherwise false.
 */
export const urlContainsNewApiStandardsEndpoint = (url: string) => {
  const newEndpointUrls = ['user-mgmt/v1/users'];
  let containsNewEndpoint = false;
  newEndpointUrls.forEach((newEndpoint) => {
    if (url.includes(newEndpoint)) {
      containsNewEndpoint = true;
    }
  });
  return containsNewEndpoint;
};

/**
 * Defines new endpoints and checks if the given URL contains any of the old api standards endpoints.
 *
 * @param {string} url - The URL to check.
 * @return {boolean} Returns true if the URL contains any of the new endpoints, otherwise false.
 */
export const urlContainsOldApiStandardsEndpoint = (url: string) => {
  const newEndpointUrls = [
    'warehouse-mgmt/v1/search-picks',
    'sales-mgmt/v1/orders',
  ];
  let containsOldEndpoint = false;
  newEndpointUrls.forEach((newEndpoint) => {
    if (url.includes(newEndpoint)) {
      containsOldEndpoint = true;
    }
  });
  return containsOldEndpoint;
};
