import React from 'react';
import ReactDOM from 'react-dom';
import { App } from 'app';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import detectHeadlessBrowser from 'helpers/detectHeadlessBrowser';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';

const isHeadlessBrowser = detectHeadlessBrowser();
const headlessBrowserSharedContextKey = 'headless-browser';

(async () => {
  const initialContext = {
    kind: 'user',
    anonymous: true,
    key: isHeadlessBrowser ? headlessBrowserSharedContextKey : undefined,
  };
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LD_CLIENT_SIDE_ID || '',
    options: {
      streaming: true,
    },
    context: initialContext,
    reactOptions: {
      useCamelCaseFlagKeys: true,
    },
  });

  const router = createBrowserRouter(
    createRoutesFromElements(<Route path="*" element={<App />} />)
  );

  ReactDOM.render(
    <LDProvider>
      <RouterProvider router={router} />
    </LDProvider>,
    document.getElementById('root')
  );
})();
