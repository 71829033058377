import React, {
  memo,
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import { Modal } from 'ui/components/Modal/Modal';
import { ItemsTable } from 'ui/components/Table/ItemsTable';
import {
  roundToDecimals,
  replaceValueInCollection,
  useCurrencyFormatter,
} from 'helpers';
import {
  ReconcileExpense,
  ReconcileCalculateOptions,
  reconcileReceipt,
  transformReceiptItemToReconcileReceiptItem,
  ReconcileReceiptItem,
} from 'services/receiving';
import { EACH_UOM_ID, getUomById } from 'services/uoms';

import {
  ReconcileWizardRow,
  ReconcileWizardExpenses,
  ReconcileWizardReconcileItems,
  ReconcileWizardSummary,
  ReconcileWizardItems,
} from './components';
import { ReconcileWizardProps, WizardSteps } from './types';
import {
  reconcileWizardColumns,
  reconcileWizardSteps,
  initialReconcile,
} from './consts';
import {
  calculateReconcilePricesByCost,
  calculateReconcilePricesByQuantity,
  calculateReconcilePricesManual,
  filterReceiptItemsForReconciling,
  findItemsToReconcile,
  findReconcileExpenses,
  prepareSummaryStep,
  resolveSelectedReconcileItems,
} from './helpers';
import { getSettingsCompany } from 'services/settings/company';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { colorPalette, themeRestyle } from 'ui/theme';

const FBOReconcileWizard: React.FC<ReconcileWizardProps> = (props) => {
  const {
    show,
    receipt,
    activeReceiptItem,
    setActiveReceipt,
    onClose,
    fetchSearchResult,
  } = props;

  const currencyFormatter = useCurrencyFormatter();
  const [isLoading, setIsLoading] = useState(false);

  const eachUom = useSelector(getUomById(EACH_UOM_ID));
  const { homeCurrency, useMultiCurrency } = useSelector(getSettingsCompany);
  const homeCurrencyCode = (homeCurrency && homeCurrency.code) || 'USD';
  const billDateInputRef = useRef<HTMLInputElement | null>(null);

  const exchangeCurrency = useMemo(() => {
    if (receipt.purchaseOrder) {
      return _.get(receipt, 'purchaseOrder.exchangeCurrency');
    }
    return _.get(receipt, 'salesOrder.exchangeCurrency');
  }, [receipt]);
  const activeMulticurrencyCode = exchangeCurrency && exchangeCurrency.code;
  const showMultiCurrency = useMemo(
    () =>
      useMultiCurrency &&
      activeMulticurrencyCode &&
      activeMulticurrencyCode !== homeCurrencyCode,
    [useMultiCurrency, activeMulticurrencyCode]
  );

  const [reconcile, setReconcile] = useState(initialReconcile);
  const [steps, setSteps] = useState(reconcileWizardSteps);
  const [activeStepId, setActiveStepId] = useState<WizardSteps>(
    WizardSteps.SelectItems
  );
  const [reconcileError, setReconcileError] = useState<string | null>(null);

  const amountToReconcile = useMemo(() => {
    let sum = 0;
    reconcile.expenses.forEach((expense) => {
      sum += (expense.quantity || 0) * (expense.cost || 0);
    });
    return roundToDecimals(sum, 6);
  }, [reconcile.expenses]);

  const reconciledAmount = useMemo(() => {
    const sum = _.reduce(reconcile.reconcilePrices, (acc, val) => acc + val, 0);
    return roundToDecimals(sum, 6);
  }, [reconcile.reconcilePrices]);

  const receiptItems = useMemo(() => {
    // If we want to reconcile only one item
    // we set activeReceiptItem from row action menu
    if (activeReceiptItem.id) {
      return [activeReceiptItem];
    }

    return filterReceiptItemsForReconciling(receipt.receiptItems);
  }, [receipt.receiptItems, activeReceiptItem]);

  // initial useEffect
  useEffect(() => {
    if (show) {
      setSteps(reconcileWizardSteps);
      setActiveStepId(WizardSteps.SelectItems);

      const receiptItemList = activeReceiptItem.id
        ? [activeReceiptItem]
        : filterReceiptItemsForReconciling(receipt.receiptItems);

      setReconcile({
        ...initialReconcile,
        currency: exchangeCurrency,
        selectedItems: resolveSelectedReconcileItems(receiptItems),
        reconcileReceiptItems: receiptItemList.map(
          transformReceiptItemToReconcileReceiptItem
        ),
      });

      setReconcileExpenses([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  // calculate reconcile prices when calculation option and selected items are changed
  useEffect(() => {
    calculateReconcilePrices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reconcile.calculation, reconcile.selectedReconcileItems]);

  // select all items when calculation is DoNotCalculate
  useEffect(() => {
    if (reconcile.calculation === ReconcileCalculateOptions.DoNotCalculate) {
      setReconcile((old) => ({
        ...old,
        selectedReconcileItems: receiptItems.map((i) => i.id!),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reconcile.calculation]);

  // remove error when selecting items
  useEffect(() => {
    setReconcileError(null);
  }, [reconcile.selectedReconcileItems]);

  const setReconcileExpenses = useCallback(
    (reconcileExpenses: React.SetStateAction<ReconcileExpense[]>) => {
      if (typeof reconcileExpenses === 'function') {
        setReconcile((r) => ({
          ...r,
          expenses: reconcileExpenses(r.expenses),
        }));
        return;
      }

      setReconcile((r) => ({
        ...r,
        expenses: reconcileExpenses,
      }));
    },
    [setReconcile]
  );

  const setItemsToReconcile = useCallback(
    (receiptItem: React.SetStateAction<ReconcileReceiptItem[]>) => {
      if (typeof receiptItem === 'function') {
        setReconcile((r) => ({
          ...r,
          itemsToReconcile: receiptItem(r.itemsToReconcile),
        }));
        return;
      }

      setReconcile((r) => ({
        ...r,
        itemsToReconcile: receiptItem,
      }));
    },
    []
  );

  const setReconcileReceiptItems = useCallback(
    (reconcileReceiptItems: React.SetStateAction<ReconcileReceiptItem[]>) => {
      if (typeof reconcileReceiptItems === 'function') {
        setReconcile((r) => ({
          ...r,
          reconcileReceiptItems: reconcileReceiptItems(r.reconcileReceiptItems),
        }));
        return;
      }

      setReconcile((r) => ({
        ...r,
        reconcileReceiptItems: reconcileReceiptItems,
      }));
    },
    [setReconcile]
  );

  const calculateReconcilePrices = useCallback(() => {
    switch (reconcile.calculation) {
      case ReconcileCalculateOptions.Cost: {
        const { adjustment, prices } = calculateReconcilePricesByCost(
          reconcile.itemsToReconcile,
          reconcile.selectedReconcileItems,
          amountToReconcile
        );
        setReconcile((old) => ({
          ...old,
          reconcilePrices: prices,
          adjustment,
        }));
        break;
      }
      case ReconcileCalculateOptions.Manual:
      case ReconcileCalculateOptions.DoNotCalculate:
        setReconcile((old) => ({
          ...old,
          reconcilePrices: calculateReconcilePricesManual(receiptItems),
        }));
        break;
      case ReconcileCalculateOptions.Quantity:
        setReconcile((old) => ({
          ...old,
          reconcilePrices: calculateReconcilePricesByQuantity(
            reconcile.itemsToReconcile,
            reconcile.selectedReconcileItems,
            amountToReconcile
          ),
        }));
        break;
    }
  }, [
    reconcile.selectedReconcileItems,
    reconcile.itemsToReconcile,
    receiptItems,
    reconcile.calculation,
    amountToReconcile,
  ]);
  const handleApplyClicked = useCallback(async () => {
    if (activeStepId === WizardSteps.SelectItems) {
      if (_.isEmpty(reconcile.selectedItems)) {
        setReconcileError('You have to select at least 1 item');
        return;
      }

      let errorMessage = '';

      if (reconcile.billDate === null || isNaN(reconcile.billDate.getTime())) {
        errorMessage =
          reconcile.billDate === null
            ? 'Bill date is required'
            : 'Bill date is invalid';
      }
      if (errorMessage) {
        setReconcileError(errorMessage);

        if (billDateInputRef.current) {
          billDateInputRef.current.focus();
        }
        return;
      }

      setReconcileExpenses(
        findReconcileExpenses(
          reconcile.reconcileReceiptItems,
          reconcile.selectedItems
        )
      );
      setItemsToReconcile(
        findItemsToReconcile(reconcile.reconcileReceiptItems)
      );
    }

    if (activeStepId === WizardSteps.Expenses) {
      setReconcile({
        ...reconcile,
        selectedReconcileItems: reconcile.selectedItems,
      });
      calculateReconcilePrices();
    }

    if (activeStepId === WizardSteps.ItemsToReconcile) {
      const reconcileAmountDiff = amountToReconcile - reconciledAmount;
      if (
        reconcileAmountDiff !== 0 &&
        reconcile.calculation !== ReconcileCalculateOptions.DoNotCalculate
      ) {
        setReconcileError(
          reconcileAmountDiff < 0
            ? `Remove ${currencyFormatter(
                Math.abs(reconcileAmountDiff)
              )} from total reconcile amount`
            : `Add ${currencyFormatter(
                reconcileAmountDiff
              )} to total reconcile amount`
        );
        return;
      }
      prepareSummaryStep(reconcile, setReconcile, eachUom!);
    }

    if (activeStepId === WizardSteps.Summary) {
      try {
        setIsLoading(true);
        const newReceipt = await reconcileReceipt(receipt.id!, reconcile);
        setActiveReceipt(newReceipt);
        fetchSearchResult();
        onClose();
        setIsLoading(false);
      } catch {
        // Ignore error
      }
      return;
    }

    // Cleanup and go to the next page
    setReconcileError(null);
    setActiveStepId(activeStepId + 1);
    const index = steps.findIndex((s) => s.id === activeStepId);
    setSteps(
      (old) =>
        replaceValueInCollection(old, { ...old[index], checked: true }, index)!
    );
  }, [
    isLoading,
    setIsLoading,
    receipt.id,
    activeStepId,
    steps,
    reconcile,
    amountToReconcile,
    reconciledAmount,
    calculateReconcilePrices,
    setReconcileExpenses,
    setItemsToReconcile,
    onClose,
    eachUom,
    setActiveReceipt,
    fetchSearchResult,
    currencyFormatter,
  ]);

  const handleBackClicked = useCallback(() => {
    if (activeStepId === WizardSteps.SelectItems) {
      return;
    }

    setActiveStepId(activeStepId - 1);
  }, [activeStepId]);

  const selectedReconcileItems = useMemo((): ReconcileReceiptItem[] => {
    return reconcile.itemsToReconcile.filter((ri) =>
      reconcile.selectedItems.includes(ri.id!)
    );
  }, [reconcile.itemsToReconcile]);

  const ModalActions = () => {
    return (
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box>
          <FBOButton
            variant="secondary"
            color="neutral"
            size="large"
            onClick={handleBackClicked}
            disabled={activeStepId === WizardSteps.SelectItems}
            data-qa="reconcile-wizard-back-button"
          >
            Back
          </FBOButton>
        </Box>
        <Box display="flex">
          <FBOButton
            variant="secondary"
            color="positive"
            size="large"
            onClick={onClose}
            data-qa="reconcile-wizard-cancel-button"
          >
            Cancel
          </FBOButton>

          <FBOButton
            variant="primary"
            color="positive"
            size="large"
            onClick={handleApplyClicked}
            data-qa="receiving-reconcile-finish"
            loading={isLoading}
            sx={{ marginLeft: '16px' }}
          >
            {`${activeStepId === WizardSteps.Summary ? 'Finish' : 'Next'}`}
          </FBOButton>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Modal
        open={show}
        onCancelClicked={onClose}
        withoutDefaultPadding
        isLoading={false}
        title="Reconcile Order"
        onResetClicked={_.noop}
        maxWidth="xl"
        nestedScrollAreas
        innerContentFullHeight
        ModalActionsComponent={ModalActions}
      >
        <Grid
          container
          spacing={2}
          disableEqualOverflow
          sx={{
            paddingLeft: themeRestyle.spacing(3),
            paddingRight: themeRestyle.spacing(3),
          }}
        >
          <Grid xs={showMultiCurrency ? 2 : 3}>
            <ItemsTable
              data={steps}
              columns={reconcileWizardColumns}
              RenderCustomRow={ReconcileWizardRow}
              selectableItems={false}
              activeId={activeStepId}
              sx={{
                borderRadius: '5px',
                border: `1px solid ${colorPalette.redesign.background3}`,
                borderTop: 'none',
                maxHeight: '425px',
                overflowY: 'scroll',
              }}
            />
          </Grid>
          <Grid xs={showMultiCurrency ? 10 : 9}>
            {activeStepId === WizardSteps.SelectItems && (
              <ReconcileWizardItems
                billDateInputRef={billDateInputRef}
                receipt={receipt}
                reconcile={reconcile}
                setReconcile={setReconcile}
                setActiveReceipt={setActiveReceipt}
                error={reconcileError}
                setReconcileReceiptItems={setReconcileReceiptItems}
                referenceNumber={reconcile.referenceNumber}
                billDate={reconcile.billDate}
                dueDate={reconcile.dueDate}
              />
            )}
            {activeStepId === WizardSteps.Expenses && (
              <ReconcileWizardExpenses
                reconcileExpenses={reconcile.expenses}
                reconcile={reconcile}
                setReconcileExpenses={setReconcileExpenses}
              />
            )}
            {activeStepId === WizardSteps.ItemsToReconcile && (
              <ReconcileWizardReconcileItems
                receiptItems={receiptItems}
                itemsToReconcile={selectedReconcileItems}
                amountToReconcile={amountToReconcile}
                reconciledAmount={reconciledAmount}
                reconcile={reconcile}
                setReconcile={setReconcile}
                error={reconcileError}
              />
            )}
            {activeStepId === WizardSteps.Summary && (
              <ReconcileWizardSummary
                referenceNumber={reconcile.referenceNumber}
                billDate={reconcile.billDate}
                dueDate={reconcile.dueDate}
                error={reconcileError}
                summary={reconcile.summary}
                currency={reconcile.currency}
                setActiveReceipt={setActiveReceipt}
                receipt={receipt}
              />
            )}
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default memo(FBOReconcileWizard);
