export enum ContactType {
  email = 'Email',
  mobile = 'Mobile',
  office = 'Office',
  fax = 'Fax',
  other = 'Other',
}

export interface Address {
  id: number | null;
  attention: string | null;
  city: string | null;
  companyName: string | null;
  contacts: Contact[];
  country: string | null;
  defaultBillTo: boolean;
  defaultShipTo: boolean;
  deleted: boolean | null;
  email: string | null;
  name: string | null;
  phone: string | null;
  postalCode: string | null;
  residential: boolean;
  state: string | null;
  street: string | null;
  street2: string | null;
  version: number | null;
  verified: boolean;
}

export const AddressInitialValue = {
  attention: null,
  city: null,
  companyName: null,
  contacts: [],
  country: null,
  defaultBillTo: false,
  defaultShipTo: false,
  deleted: null,
  email: null,
  id: null,
  name: null,
  phone: null,
  postalCode: null,
  residential: false,
  state: null,
  street: null,
  street2: null,
  version: null,
  verified: false,
};

export interface Contact {
  id: number | null;
  dateCreated: Date | null;
  dateLastModified: Date | null;
  defaultFlag: boolean | null;
  deleted: boolean | null;
  name: string | null;
  type: ContactType | null;
  value: string | null;
  version: number | null;
}

export interface AddressValidation {
  original: Address;
  validated: Address;
  isValid: boolean;
  isComplete: boolean;
  messages: string[];
}

export interface BillToShipToAddress
  extends Omit<
    Address,
    | 'id'
    | 'contacts'
    | 'deleted'
    | 'version'
    | 'defaultBillTo'
    | 'defaultShipTo'
    | 'email'
    | 'phone'
  > {}

// @beta: additionally added for testing
export interface BillToShipToAddressBeta
  extends Omit<
    Address,
    | 'id'
    | 'contacts'
    | 'deleted'
    | 'version'
    | 'defaultBillTo'
    | 'defaultShipTo'
  > {}
