import { TaxRate } from 'services/taxRates';

import { RowDescriptor, RowType } from '../Card';

export const salesOrderCardId = 'sales-order-card-navigation-id';

export const title = 'Sales Order';

export const salesOrderRowDescriptor = (
  taxRates: TaxRate[] = []
): RowDescriptor[] => [
  {
    type: RowType.Boolean,
    title: 'Notify if order is not paid',
    field: 'orderNotPaidWarning',
    dataQa: 'notify-if-order-is-not-paid',
  },
  {
    type: RowType.Boolean,
    title: 'Notify if tax rate is ‘none’',
    field: 'verifyNoneTaxRate',
    dataQa: 'notify-if-tax.-rate-is-none',
  },
  {
    type: RowType.Boolean,
    title: 'Price includes tax',
    field: 'priceIncludesTax',
    dataQa: 'price-Includes-Tax',
  },
  {
    type: RowType.Autocomplete,
    title: 'Sales tax',
    field: 'salesTax',
    options: taxRates,
    getOptionsLabel: (option: TaxRate) => option.name || 'Unknown tax',
    isOptionEqualToValue: (option: TaxRate, value: TaxRate) =>
      option.id === value.id,
    handleAutocompleteChange: (e, value, field, setCardData) => {
      setCardData('salesTax', value);
      setCardData('salesTaxId', value ? value.id : null);
    },
  },
  {
    type: RowType.Boolean,
    title: 'Use customer’s sales representative',
    field: 'useCustomerDefaultRep',
    dataQa: 'use-customers-sale-representative',
  },
  {
    type: RowType.Boolean,
    title: 'Show ‘Paid’ stamp',
    field: 'showPaidStamp',
    dataQa: 'show-paid-stamp',
  },
  {
    type: RowType.Boolean,
    title: 'Show markup and margin',
    field: 'showMarkupAndMargin',
    dataQa: 'show-markup-and-margin',
  },
  {
    type: RowType.Text,
    title: 'Next order number',
    field: 'nextOrderNumber',
    dataQa: 'next-order-number',
  },
  /* Hidden for now, will be turned on later
   {
    type: RowType.Text,
    title: 'Quick fulfill title',
    field: 'quickFulfillTitle',
  }, */
  {
    type: RowType.Text,
    title: 'Report footer',
    textbox: true,
    field: 'reportFooter',
    dataQa: 'report-footer',
  },
  {
    type: RowType.Boolean,
    title: 'Check for inventory',
    field: 'checkForInventory',
    dataQa: 'check-for-inventory',
  },
  /* Hidden for now, will be turned on later
   {
    type: RowType.Boolean,
    title: 'SO to PO overrides',
    field: 'enableSoToPo',
    subBooleanRows: [
      {
        title: 'Round SO to PO quantity',
        type: RowType.Boolean,
        field: 'roundSoToPoQty',
      },
      {
        title: 'Use SO unit of measure on PO',
        type: RowType.Boolean,
        field: 'useSoUomOnPo',
      },
      {
        title: 'Combine item lines',
        type: RowType.Boolean,
        field: 'combineItemLines',
      },
    ],
  }, */
];
