import { transformTaxRate } from 'services/taxRates';
import { SalesOrderSettings } from './types';

export const transformSalesOrderSettings = (
  response: any
): SalesOrderSettings => {
  return {
    combineItemLines: response.combineItemLines,
    enableSoToPo: response.enableSoToPo,
    estimateReportTitle: response.estimateReportTitle,
    id: response.id,
    nextOrderNumber: response.nextOrderNumber,
    orderNotPaidWarning: response.orderNotPaidWarning,
    quickFulfillTitle: response.quickFulfillTitle,
    reportFooter: response.reportFooter,
    roundSoToPoQty: response.roundSoToPoQty,
    showMarkupAndMargin: response.showMarkupAndMargin,
    salesTaxId: response.salesTaxId,
    salesTax: response.salesTax ? transformTaxRate(response.salesTax) : null,
    showPaidStamp: response.showPaidStamp,
    useCustomerDefaultRep: response.useCustomerDefaultRep,
    useSoCarrierOnPo: response.useSoCarrierOnPo,
    useSoItemDescOnPoItem: response.useSoItemDescOnPoItem,
    useSoUomOnPo: response.useSoUomOnPo,
    verifyNoneTaxRate: response.verifyNoneTaxRate,
    checkForInventory: response.checkForInventory,
    version: response.version,
    priceIncludesTax: response.priceIncludesTax,
  };
};

export const transformToSoSettingsPut = (soSettings: SalesOrderSettings) => ({
  id: soSettings.id,
  version: soSettings.version,
  orderNotPaidWarning: soSettings.orderNotPaidWarning,
  nextOrderNumber: soSettings.nextOrderNumber,
  verifyNoneTaxRate: soSettings.verifyNoneTaxRate,
  useCustomerDefaultRep: soSettings.useCustomerDefaultRep,
  showPaidStamp: soSettings.showPaidStamp,
  showMarkupAndMargin: soSettings.showMarkupAndMargin,
  quickFulfillTitle: soSettings.quickFulfillTitle,
  estimateReportTitle: soSettings.estimateReportTitle,
  salesTaxId: soSettings.salesTaxId,
  reportFooter: soSettings.reportFooter,
  enableSoToPo: soSettings.enableSoToPo,
  roundSoToPoQty: soSettings.roundSoToPoQty,
  useSoUomOnPo: soSettings.useSoUomOnPo,
  combineItemLines: soSettings.combineItemLines,
  useSoItemDescOnPoItem: soSettings.useSoItemDescOnPoItem,
  useSoCarrierOnPo: soSettings.useSoCarrierOnPo,
  checkForInventory: soSettings.checkForInventory,
  priceIncludesTax: soSettings.priceIncludesTax,
});
