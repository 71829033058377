import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';

import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import { Errors, validateYup } from 'services/forms/validation';
import { useHandleTextFieldChange } from 'services/forms';
import { postAddress } from 'services/addresses/api';
import {
  Location,
  initialLocation,
  LocationType,
  getLocations,
  fetchLocations,
  postLocation,
} from 'services/locations';
import { Address, initialAddress } from 'services/addresses';

import { Autocomplete } from '../Autocomplete';
import LocationsAsyncAutocomplete from './LocationsAsyncAutocomplete';
import { NewLocationModalProps } from './types';
import {
  COUNTRIES,
  CountryAutocomplete,
  CountryType,
} from '../CountryAutocomplete';

const yupSchema = yup.object().shape({
  name: yup.string(),
  type: yup.mixed().oneOf(Object.values(LocationType)),
  address: yup.object().shape({
    country: yup.string(),
  }),
});

const NewLocationModal: React.FC<NewLocationModalProps> = (props) => {
  const { show, newLocationName, onClose, onSave } = props;

  const dispatch = useDispatch();

  const { items: locations } = useSelector(getLocations);

  const [formLocation, setFormLocation] = useState<Location>(initialLocation);
  const [parentAddress, setParentAddress] = useState(initialAddress);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Errors>({});

  const selectedCountryType = useMemo(() => {
    return (
      COUNTRIES.find((c) => c.code === formLocation.address.country) || null
    );
  }, [formLocation.address.country]);

  useEffect(() => {
    setFormLocation({
      ...initialLocation,
      name: newLocationName,
    });
    setErrors({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (!formLocation.parentLocationId) {
      return;
    }

    const parentLocation = locations.find(
      (loc) => loc.id === formLocation.parentLocationId
    )!;
    setParentAddress(parentLocation.address);
  }, [formLocation.parentLocationId, locations]);

  const handleTypeChanged = useCallback((e: any, type: LocationType | null) => {
    setFormLocation((old) => ({ ...old, type }));
  }, []);

  const handleTextFieldChange = useHandleTextFieldChange(
    setFormLocation,
    formLocation
  );

  const setAddress = useCallback((address: React.SetStateAction<Address>) => {
    if (typeof address === 'function') {
      setFormLocation((l) => ({
        ...l,
        address: address(l.address),
      }));
      return;
    }

    setFormLocation((l) => ({
      ...l,
      address,
    }));
  }, []);

  const handleParentAddress = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (!checked) {
        setFormLocation((old) => {
          return {
            ...old,
            useParentAddress: false,
            address: initialAddress,
            addressId: initialAddress ? initialAddress.id : null,
          };
        });
        return;
      }

      setFormLocation((old) => ({
        ...old,
        useParentAddress: true,
        address: old.parentLocation
          ? old.parentLocation.address
          : initialAddress,
      }));
    },
    []
  );

  const handlePathChanged = useCallback(
    (v: Location | null) => {
      const parentLocationId = v ? v.id : null;

      // if useParentAddress is selected and we remove parentLocationId
      // we need to disable useParentAddress
      if (!parentLocationId && formLocation.useParentAddress) {
        setFormLocation((old) => {
          const addNewAddress = old.address.id !== null;
          return {
            ...old,
            parentLocationId,
            useParentAddress: false,
            address: addNewAddress ? initialAddress : old.address,
            addressId: addNewAddress ? initialAddress.id : old.id,
            parentLocation: null,
          };
        });
        return;
      }

      if (formLocation.useParentAddress) {
        setFormLocation((old) => ({
          ...old,
          parentLocationId,
          address: v ? v.address : initialAddress,
          parentLocation: v,
        }));

        return;
      }

      setFormLocation((old) => ({
        ...old,
        parentLocationId,
        parentLocation: v,
      }));
    },
    [formLocation.useParentAddress]
  );

  const handleApplyModal = async () => {
    if (!validateYup(formLocation, yupSchema, setErrors)) {
      return;
    }

    setIsLoading(true);

    try {
      if (!formLocation.useParentAddress) {
        const newAddress = await postAddress(formLocation.address);
        const newLocation = await postLocation({
          ...formLocation,
          addressId: newAddress.id,
        });
        onSave(newLocation);
      } else {
        const newLocation = await postLocation(formLocation);
        onSave(newLocation);
      }
      // Lint skip to be removed
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(fetchLocations());
    } catch {
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
    setFormLocation(initialLocation);
  };

  const handleAddressTextInputChanged = useHandleTextFieldChange<Address>(
    setAddress,
    formLocation.address
  );

  const handleCountryChanged = useCallback(
    (e: any, country: CountryType | null) => {
      setFormLocation((old) => ({
        ...old,
        address: { ...old.address, country: country ? country.code : null },
      }));
    },
    []
  );

  return (
    <Modal
      open={show}
      title="New Location"
      onCancelClicked={onClose}
      onApplyClicked={handleApplyModal}
      isLoadingContent={isLoading}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            className="redesign"
            variant="standard"
            name="name"
            label="Name"
            value={formLocation.name}
            onChange={handleTextFieldChange}
            required
            error={!!errors.name}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            label="Type"
            options={Object.values(LocationType)}
            getOptionLabel={(option: string) => option}
            value={formLocation.type}
            onChange={handleTypeChanged}
            error={!!errors.type}
            placeholder="Select type"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <LocationsAsyncAutocomplete
            label="LOCATION PATH"
            value={formLocation.parentLocationId}
            onChange={handlePathChanged}
            placeholder="Select Location Path"
            companyWide={false}
            activeId={formLocation.id}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                className="redesign"
                onChange={handleParentAddress}
                checked={formLocation.useParentAddress}
                color="primary"
              />
            }
            label={'Use Parent Address'}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="City"
            placeholder="Enter city"
            name="city"
            autoComplete="nope"
            fullWidth
            value={
              formLocation.useParentAddress
                ? parentAddress.city
                : formLocation.address.city
            }
            disabled={formLocation.useParentAddress}
            onChange={handleAddressTextInputChanged}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="State"
            placeholder="Enter state"
            name="state"
            autoComplete="nope"
            fullWidth
            value={
              formLocation.useParentAddress
                ? parentAddress.state
                : formLocation.address.state
            }
            disabled={formLocation.useParentAddress}
            onChange={handleAddressTextInputChanged}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="Zip"
            placeholder="Enter ZIP"
            name="postalCode"
            autoComplete="nope"
            fullWidth
            value={
              formLocation.useParentAddress
                ? parentAddress.postalCode
                : formLocation.address.postalCode
            }
            disabled={formLocation.useParentAddress}
            onChange={handleAddressTextInputChanged}
          />
        </Grid>
        <Grid item xs={4}>
          <CountryAutocomplete
            value={selectedCountryType}
            disabled={formLocation.useParentAddress}
            onChange={handleCountryChanged}
            error={!!errors['address.country']}
            required
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default memo(NewLocationModal);
