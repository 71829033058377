import { makeStyles } from '@mui/styles';
import { colorPalette } from 'ui/theme';

const BUTTON_WIDTH = 90;
const ICON_DIMENSION = 36.44;
const ELEMENT_OFFSET = 10;

export const useAddressDetailsMenuStyles = makeStyles(() => {
  return {
    container: {
      zIndex: 10,
    },
    card: {
      width: 202,
      padding: 16,
      borderRadius: 4,
      backgroundColor: colorPalette.fishbowlDarkGray,
      color: colorPalette.white,
    },
  };
});

export const useAddressDataStyles = makeStyles(() => {
  return {
    address: {
      fontSize: '0.75rem',
      lineHeight: 2,
      fontStyle: 'normal',
    },
    title: {
      margin: 0,
      fontSize: '0.875rem',
      lineHeight: 2.25,
      fontWeight: 'bolder',
    },
    subtitle: {
      margin: 0,
      fontWeight: 'bolder',
    },
    header: {},
    body: {
      '& > p': {
        margin: 0,
      },
    },
  };
});

export const useBillToShipToSelectStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flex: 1,
    },
    select: {
      width: ({ fullWidth }: { fullWidth: boolean }) => {
        if (fullWidth) {
          return '100%';
        }
      },
    },
    disabled: {
      opacity: 0.5,
    },
  };
});

export const useVerifiedButtonStyles = makeStyles(() => {
  return {
    icon: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: ELEMENT_OFFSET,
      width: ICON_DIMENSION,
      height: ICON_DIMENSION,
      '& > svg': {
        color: colorPalette.statusGreen,
      },
    },
    button: {
      width: BUTTON_WIDTH,
      marginLeft: ELEMENT_OFFSET,
    },
  };
});
