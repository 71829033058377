import { Dates } from 'helpers';
import {
  getSearches,
  addSearch,
  updateSearch,
  deleteSearch,
} from 'services/ledgers';
import {
  AdvancedSearchFormValues,
  AdvancedSearchReduxFunctions,
} from 'services/search';
import { PaginationDirection } from 'services/url';
import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const advancedSearchReduxActions: AdvancedSearchReduxFunctions = {
  add: addSearch,
  delete: deleteSearch,
  update: updateSearch,
  get: getSearches,
};

export const initialASFormValues: AdvancedSearchFormValues = {
  name: null,
  description: null,
  location: null,
};

export const displayNameMap = {
  name: 'Name',
  description: 'Description',
};

export const PRICING_RULES_COLUMNS = (date: Dates): Column[] => {
  const resolveDateColumn = (): Column => {
    switch (date) {
      case Dates.DateLastModified:
        return {
          title: 'Date Last Modified',
          field: Dates.DateLastModified,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
          width: 200,
        };
      default:
        return {
          title: 'Date Created',
          field: Dates.DateCreated,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
          width: 200,
        };
    }
  };

  return [
    {
      title: 'Name',
      field: 'name',
      sortable: true,
      type: ColumnTypes.string,
    },
    {
      title: 'Description',
      field: 'description',
      sortable: true,
      type: ColumnTypes.string,
    },
    {
      title: 'Type',
      field: 'type',
      sortable: true,
      type: ColumnTypes.string,
      searchable: false,
      width: 150,
    },
    resolveDateColumn(),
  ];
};
export const initialPagination = {
  page: 1,
  pageSize: 25,
  sort: {
    sortBy: 'id',
    direction: PaginationDirection.Descending,
  },
  totalRows: 25,
};
