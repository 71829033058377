import React, { memo } from 'react';
import { Box, Typography } from '@mui/material';
import Icon from '../Icon';

import { useIconItemBoxStyles } from './styled';
import { IconItemBoxProps } from './types';

const IconItemBox: React.FC<IconItemBoxProps> = (props) => {
  const { source, iconWidth, iconHeight, name, description } = props;

  const classes = useIconItemBoxStyles(props);

  return (
    <Box display="flex" alignItems="center">
      <Box className={classes.iconWrapper}>
        <Icon
          name={source}
          noDefaultFill
          width={iconWidth}
          height={iconHeight}
        />
      </Box>
      <Box className={classes.content}>
        <Typography variant="body1" className={classes.heading}>
          {name || 'Unknown item'}
        </Typography>

        {description && (
          <Typography
            variant="subtitle2"
            color="textSecondary"
            className={classes.subHeading}
          >
            {description}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default memo(IconItemBox);
