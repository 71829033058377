import { PermissionUser } from 'services/permissions';
import { User } from 'services/user';
import { User as UserV2 } from 'services/userV2';

export const transformUserToPermissionUser = (
  user: User | UserV2
): PermissionUser => {
  return {
    id: user.id,
    version: user.version,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    dateCreated: null,
    dateLastModified: null,
  };
};
