import { Location } from 'services/locations';
import {
  PurchaseOrder,
  PurchaseOrderItem,
  PurchaseOrderItemTypes,
} from 'services/purchaseOrders';
import { SalesOrder, SalesOrderItem } from 'services/salesOrders';
import { User } from 'services/user';
import { Carrier, CarrierService } from 'services/carriers';
import { Customer } from 'services/customers';
import { Item, ItemType } from 'services/items';
import { Uom } from 'services/uoms';
import { TrackingGroup } from 'services/inventory';
import { Document } from 'services/documents';
import { Currency } from 'services/currencies';

export interface Receipt {
  id: number | null;
  dateCreated: Date | null;
  dateLastModified: Date | null;
  documents: Document[];
  location: Location | null;
  locationId: number | null;
  notes: string | null;
  number: string | null;
  orderType: 'Purchase Order' | 'Sales Order' | null;
  purchaseOrder: PurchaseOrder | null;
  purchaseOrderId: number | null;
  purchaseUser: User | null;
  purchaseUserId: number | null;
  receiptItems: ReceiptItem[];
  salesOrder: SalesOrder | null;
  salesOrderId: number | null;
  status: ReceiptStatus;
  version: number | null;
}

export interface ReceiptItem {
  id: number | null;
  addToVendorBill: boolean;
  billedTotalCost: number | null;
  carrier: Carrier | null;
  carrierId: number | null;
  carrierService: CarrierService | null;
  carrierServiceId: number | null;
  carrierTracking: any | null;
  carrierPackageCount: number | null;
  customer: Customer | null;
  customerId: number | null;
  dateBilled: Date | null;
  dateCreated: Date | null;
  dateLastModified: Date | null;
  dateReceived: Date | null;
  dateReconciled: Date | null;
  deliverTo: any | null;
  item: Item | null;
  itemId: number | null;
  landedTotalCost: number | null;
  location: Location | null;
  locationId: number | null;
  multiCurrencyBilledTotalCost: number | null;
  multiCurrencyLandedTotalCost: number | null;
  notes: string | null;
  purchaseOrderItem: PurchaseOrderItem | null;
  purchaseOrderItemId: number | null;
  quantity: number | null;
  receiptItemType: ReceiptItemType;
  referenceNumber: string | null;
  reasonForRejection: string | null;
  receiptId: number | null;
  rejectionResponsibility: RejectionResponsibilityType | null;
  salesOrderItem: SalesOrderItem | null;
  salesOrderItemId: number | null;
  status: ReceiptItemStatus | null;
  trackingGroupList: TrackingGroup[];
  uom: Uom | null;
  uomId: number | null;
  version: number | null;
}

export interface ReceiptItemReceive {
  id: number;
  receiptItemId: number;
  receiptItem: ReceiptItem;
  quantity: number | null;
  locationId: number | null;
  newUnitCost: number | null;
  dateReceived: Date | null;
  trackingGroups: TrackingGroup[];
}

export enum ReceiptItemType {
  Sale = 'Sale',
  Purchase = 'Purchase',
  Reconcile = 'Reconcile',
  DropShip = 'Drop Ship',
}

export enum ReceiptStatus {
  Open = 'Open',
  Received = 'Received',
  PartiallyReceived = 'Partially Received',
  Reconciled = 'Reconciled',
  PartiallyReconciled = 'Partially Reconciled',
  Fulfilled = 'Fulfilled',
  PartiallyFulfilled = 'Partially Fulfilled',
}

export enum ReceiptItemStatus {
  Open = 'Open',
  Received = 'Received',
  Reconciled = 'Reconciled',
  Fulfilled = 'Fulfilled',
  Rejected = 'Rejected',
  Hold = 'Hold',
}

export enum ReceiptOrderType {
  SalesOrder = 'Sales Order',
  PurchaseOrder = 'Purchase Order',
}

export enum RejectionResponsibilityType {
  Carrier = 'Carrier',
  Customer = 'Customer',
  Supplier = 'Supplier',
}

export enum ReconcileCalculateOptions {
  Cost = 'By Cost',
  Quantity = 'By Quantity',
  Manual = 'Manual',
  DoNotCalculate = 'Do Not Calculate',
}

export interface ReconcileExpense {
  itemId: number | null;
  name: string | null;
  description: string | null;
  quantity: number | null;
  cost: number | null;
  currency: Currency | null;
  multiCurrencyCost: number | null;
  addToVendorBill: boolean;
  new: boolean;
  receiptItemId: number | null;
  abbreviation: string | null;
}

export interface ReconcilePrice {
  [receiptItemId: number]: number;
}

export interface ReconcileSummary {
  receiptItemId: number | null;
  item: Item | null;
  currency: Currency | null;
  reconcileReceiptItem: ReconcileReceiptItem | null;
  expenseName: string | null;
  quantity: number;
  uomAbbrevation: string | null;
  unitCost: number;
  totalCost: number;
  multiCurrencyTotalCost: number;
  multiCurrencyUnitCost: number;
  reconcileCost: number | null;
  landedCost: number | null;
  multiCurrencyReconcileCost: number | null;
  multiCurrencyLandedCost: number | null;
  onVendorBill: boolean;
  totalTax?: number | null;
  multiCurrencyTaxTotal?: number | null;
  subTotal?: number;
  multiCurrencySubTotal?: number | null;
  costIncludesTax?: boolean | null;
}

export interface ReconcileReceiptItem {
  id: number | null;
  itemId: number | null;
  unitCost: number | null;
  totalCost: number | null;
  multiCurrencyUnitCost: number | null;
  multiCurrencyTotalCost: number | null;
  currency: Currency | null;
  quantity: number | null;
  name: string | null;
  referenceNumber: string | null;
  status: ReceiptItemStatus | null;
  image: string | null;
  description: string | null;
  abbreviation: string | null;
  itemType: ItemType | null;
  purchaseOrderItemType: PurchaseOrderItemTypes;
  landedTotalCost: number | null;
  dateReceived: Date | null;
  taxable: boolean;
  taxId: number | null;
  taxRate: number | null;
  taxAmount: number | null;
  multiCurrencyTaxTotal: number | null;
  subTotal: number | null;
  multiCurrencySubTotal: number | null;
  costIncludeTax: boolean;
}

export interface Reconcile {
  referenceNumber: string | null;
  billDate: Date | null;
  dueDate: Date | null;
  reconcileReceiptItems: ReconcileReceiptItem[];
  currency: Currency | null;
  selectedItems: number[];
  calculation: ReconcileCalculateOptions | null;
  expenses: ReconcileExpense[];
  itemsToReconcile: ReconcileReceiptItem[];
  selectedReconcileItems: number[];
  reconcilePrices: ReconcilePrice;
  summary: ReconcileSummary[];
  adjustment: number;
}
