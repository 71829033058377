import _ from 'lodash';

import { Account, initialAccount } from 'services/accounting';
import { AccountType } from 'services/ledgers';
import { Tag } from 'services/tags';

export const defaultAccount: Account = {
  ...initialAccount,
  name: 'Use Default Account',
  id: null,
};

export const autocompleteOptions = (
  accounts: Account[],
  accountType: AccountType
) => {
  return [defaultAccount, ..._.filter(accounts, ['accountType', accountType])];
};

export const autocompleteValue = (accounts: Account[], id: number | null) => {
  return [defaultAccount, ...accounts].find((l) => l.id === id) || null;
};

export const getOptionLabel = (option: Account) => {
  if (option.id === -1 || option.id === null) {
    return `${option.name}`;
  }
  return `${option.name} - ${option.accountType}`;
};

export const resolveDuplicateTags = (tags: Tag[], activeTag: Tag) => {
  const saleItemsTag = tags.find((tag) => tag.id === activeTag.id);
  if (saleItemsTag) {
    return tags;
  }
  return [...tags, activeTag];
};
