import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable/types';

export const ASSIGNED_LOCATIONS_COLUMNS: Column[] = [
  {
    title: 'Parent Location',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Pick Origin Location',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Receive Location',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Pick Destination Location',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: '',
    width: 64,
    type: ColumnTypes.renderByCustomRow,
  },
];
