import { makeStyles } from '@mui/styles';

export const useQuillTextEditor = makeStyles(() => ({
  quill_style: {
    height: 150,
    '& .ql-tooltip.ql-editing': {
      transform: 'translate(120px,10px)',
    },
    '& .ql-tooltip.ql-flip': {
      transform: 'translate(120px,10px)',
    },
    '& .ql-tooltip': {
      transform: 'translate(120px,10px)',
    },
    '& .ql-toolbar.ql-snow': {
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
    },
    '& .ql-container.ql-snow': {
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5,
    },
    '& .ql-container.ql-snow:hover': {
      border: '1px solid rgb(75,75,75)',
    },
    '& .ql-container.ql-snow:focus-within': {
      border: '2px solid rgb(75,75,75)',
    },
    '& .ql-editor.ql-blank::before': {
      fontStyle: 'normal',
      letterSpacing: 'inherit',
      left: 14,
      color: 'rgba(0,0,0,0.3)',
      font: 'inherit',
      fontSize: '1rem',
      fontFamily: 'Roboto Condensed, Roboto,Helvetica, sans-serif',
    },
    '& .ql-editor': {
      fontSize: '1rem',
      fontFamily: 'Roboto Condensed, Roboto,Helvetica, sans-serif',
    },
  },
  quill_error: {
    height: 150,
    '& .ql-tooltip.ql-editing': {
      transform: 'translate(120px,10px)',
      border: '1px solid #f44336',
    },
    '& .ql-tooltip.ql-flip': {
      transform: 'translate(120px,10px)',
      border: '1px solid #f44336',
    },
    '& .ql-tooltip': {
      transform: 'translate(120px,10px)',
      border: '1px solid #f44336',
    },
    '& .ql-toolbar.ql-snow': {
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      border: '1px solid #f44336',
    },
    '& .ql-container.ql-snow': {
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5,
      border: '1px solid #f44336',
    },
    '& .ql-container.ql-snow:hover': {
      border: '1px solid #f44336',
    },
    '& .ql-container.ql-snow:focus-within': {
      border: '2px solid #f44336',
    },
  },
}));
