import React, { memo, useCallback, useEffect, useState } from 'react';

import { RequestConfig } from 'helpers';
import { fetchItemsAPI } from 'services/items';
import { SaleItem } from 'services/items/saleItems';
import { Modal } from 'ui/components/Modal/Modal';
import { ItemsTable } from 'ui/components/Table/ItemsTable';

import { SUBSTITUTE_ITEMS_COLUMNS } from './consts';
import SubstituteItemModalRow from './SubstituteItemModalRow';
import { SaleItemRootLocation, SubstituteItemsModalProps } from './types';
import { logErrorCtx } from 'app/logging';

const SubstituteItemsModal: React.FC<SubstituteItemsModalProps> = (props) => {
  const {
    show,
    onClose,
    substituteItems,
    onSelect,
    cancelModal,
    locationRootId,
  } = props;

  const [saleItems, setSaleItems] = useState<SaleItem[]>([]);
  const [saleModalItems, setSaleModalItems] = useState<SaleItemRootLocation[]>(
    []
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!show) {
      return;
    }

    if (!substituteItems.length) {
      return;
    }

    (async () => {
      setLoading(true);
      try {
        const requestConfig: RequestConfig = {
          expands: ['saleItems.defaultUom', 'saleItems.images'],
        };
        const items = (
          await fetchItemsAPI(
            requestConfig,
            [],
            substituteItems.map((i) => i.substituteItemId!)
          )
        ).data;

        const filterLocationItems = items.map((item) =>
          item.inventoryLocationSummaryList.find(
            (i) => i.rootLocationId === locationRootId
          )
        );

        const itemSaleItems: SaleItem[] = [];

        items.forEach((item) => {
          item.saleItemList.forEach((saleItem) => {
            itemSaleItems.push(saleItem);
          });
        });

        const mergedArray: SaleItemRootLocation[] = itemSaleItems.map(
          (item) => {
            const matchingItem = filterLocationItems.find((item2) => {
              return item2!.itemId === item.itemId;
            });

            if (matchingItem) {
              return {
                ...item,
                quantity: matchingItem.availableQty,
                locationRootId: matchingItem.rootLocationId,
              };
            }

            return item;
          }
        );

        setSaleModalItems(mergedArray);

        setSaleItems(itemSaleItems);
      } catch (e) {
        logErrorCtx('Error in fetchItemsAPI', {
          error: e as Error,
          stackTrace: (e as Error).stack,
          component: 'SubstituteItemsModal',
          title: 'Error in fetchItemsAPI',
          description: 'Error in fetchItemsAPI',
        });
      }

      setLoading(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const onSaleItemClick = useCallback(
    (id: number) => {
      const saleItem = saleItems.find((i) => i.id === id)!;
      onSelect(saleItem);
      onClose();
      setSaleModalItems([]);
      setSaleItems([]);
    },
    [onSelect, cancelModal, onClose, saleItems]
  );

  return (
    <Modal
      open={show}
      title="Substitute Item"
      onCancelClicked={onClose}
      withoutDefaultPadding
      customHeight={300}
      isLoadingContent={loading}
    >
      <ItemsTable
        data={saleModalItems}
        columns={SUBSTITUTE_ITEMS_COLUMNS}
        RenderCustomRow={SubstituteItemModalRow}
        selectableItems={false}
        emptyTableText={loading ? 'LOADING' : 'THERE ARE NO ITEMS'}
        onItemClick={onSaleItemClick}
      />
    </Modal>
  );
};

export default memo(SubstituteItemsModal);
