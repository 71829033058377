import React, { memo } from 'react';
import clsx from 'clsx';
import { TableCell, Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';

import { ReconcileWizardStep } from '../../types';
import { useReconcileWizardRowStyle } from './styled';

const ReconcileWizardRow: React.FC<
  RenderCustomRowProps<ReconcileWizardStep>
> = (props) => {
  const { row } = props;

  const classes = useReconcileWizardRowStyle();

  return (
    <TableCell>
      <Box display="flex" alignItems="center">
        {row.checked ? (
          <CheckCircleIcon
            className={clsx({
              [classes.checkIcon]: true,
              [classes.checkIconSuccess]: true,
            })}
          />
        ) : (
          <RadioButtonUncheckedIcon className={classes.checkIcon} />
        )}
        <Box>
          <Typography>{row.title}</Typography>
          <Typography variant="subtitle2">{row.description}</Typography>
        </Box>
      </Box>
    </TableCell>
  );
};

export default memo(ReconcileWizardRow);
