import React, { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Box, Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import { PurchaseOrder, PurchaseOrderStatus } from 'services/purchaseOrders';
import { getPaymentTerms } from 'services/paymentTerms';
import { FOBS, SHIPPING_TERMS } from 'services/items';
import { useHandleTextFieldChange } from 'services/forms';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { TextField } from 'ui/components/TextField/TextField';
import { PhoneInputField } from 'ui/components/TextField/PhoneInputField';
import { useGetIntlDateFormatString } from 'helpers';

import { DetailsTabProps } from './types';
import { editPurchaseOrderPermissions } from '../../helpers';

const FBODetailsTab: React.FC<DetailsTabProps> = (props) => {
  const { purchaseOrder, setPurchaseOrder } = props;

  const paymentTerms = useSelector(getPaymentTerms).items;

  const fieldsDisabled = purchaseOrder.status === PurchaseOrderStatus.Fulfilled;

  const editPermission = editPurchaseOrderPermissions(purchaseOrder);
  const intlFormatDate = useGetIntlDateFormatString();

  const selectedPaymentTerm = useMemo(
    () =>
      paymentTerms.find((c) => c.id === purchaseOrder.paymentTermId) || null,
    [purchaseOrder, paymentTerms]
  );

  const selectedFob = useMemo(
    () => FOBS.find((f) => f.id === purchaseOrder.fobPointId) || null,
    [purchaseOrder]
  );

  const selectedShippingTerm = useMemo(
    () =>
      SHIPPING_TERMS.find((t) => t.id === purchaseOrder.shippingTermId) || null,
    [purchaseOrder]
  );

  const handlePaymentTermChange = useCallback(
    (e: React.ChangeEvent<{}>, value: PurchaseOrder) => {
      setPurchaseOrder((old) => ({
        ...old,
        paymentTermId: value ? value.id : null,
      }));
    },
    [setPurchaseOrder]
  );

  const handleShippingTermChange = useCallback(
    (e: React.ChangeEvent<{}>, value: PurchaseOrder) => {
      setPurchaseOrder((old) => ({
        ...old,
        shippingTermId: value ? value.id : null,
      }));
    },
    [setPurchaseOrder]
  );

  const handleAutocompleteChange = useCallback(
    (name: string, defaultValue: any, value?: string) =>
      (e: React.ChangeEvent<{}>, v: any) => {
        setPurchaseOrder((old) => ({
          ...old,
          [name]: v ? (value ? v[value] : v) : defaultValue,
        }));
      },
    [setPurchaseOrder]
  );

  const handleTextFieldChange = useHandleTextFieldChange<PurchaseOrder>(
    setPurchaseOrder,
    purchaseOrder
  );

  return (
    <Box p={3} width="100%">
      <Grid container spacing={2}>
        <Grid xs={3}>
          <PhoneInputField
            label="Phone"
            placeholder="Enter phone number"
            name="phone"
            autoComplete="off"
            fullWidth
            disabled={fieldsDisabled}
            permissions={editPermission}
            value={purchaseOrder.phone}
            onChange={handleTextFieldChange}
            dataQa="purchase-order-details-phone"
          />
        </Grid>
        <Grid xs={3}>
          <TextField
            variant="standard"
            className="redesign"
            type="email"
            label="Email"
            placeholder="Enter email address"
            name="email"
            autoComplete="off"
            fullWidth
            disabled={fieldsDisabled}
            permissions={editPermission}
            value={purchaseOrder.email}
            onChange={handleTextFieldChange}
            dataQa="purchase-order-details-email"
          />
        </Grid>
        <Grid container xs={6}>
          <Grid xs={4}>
            <TextField
              variant="standard"
              className="redesign"
              readOnly
              label="Created"
              value={
                purchaseOrder.dateCreated
                  ? moment(purchaseOrder.dateCreated).format(intlFormatDate)
                  : 'N/A'
              }
            />
          </Grid>
          <Grid xs={4}>
            <TextField
              variant="standard"
              className="redesign"
              readOnly
              label="Issued"
              value={
                purchaseOrder.dateIssued
                  ? moment(purchaseOrder.dateIssued).format(intlFormatDate)
                  : 'N/A'
              }
            />
          </Grid>
          <Grid xs={4}>
            <TextField
              variant="standard"
              className="redesign"
              readOnly
              label="Completed"
              value={
                purchaseOrder.dateFulfilled
                  ? moment(purchaseOrder.dateFulfilled).format(intlFormatDate)
                  : 'N/A'
              }
            />
          </Grid>
        </Grid>
        <Grid xs={3}>
          <Autocomplete
            onChange={handlePaymentTermChange}
            value={selectedPaymentTerm}
            label="Payment Term"
            placeholder="Select payment term"
            options={paymentTerms}
            disabled={fieldsDisabled}
            permissions={editPermission}
            getOptionLabel={(option) => option.name}
            dataQa="purchase-order-details-payment-term"
          />
        </Grid>
        <Grid xs={3}>
          <Autocomplete
            onChange={handleShippingTermChange}
            value={selectedShippingTerm}
            label="Shipping Term"
            placeholder="Select shipping term"
            options={SHIPPING_TERMS}
            disabled={fieldsDisabled}
            permissions={editPermission}
            getOptionLabel={(option: any) => option.name}
            dataQa="purchase-order-details-shipping-terms"
          />
        </Grid>
        <Grid xs={3}>
          <Autocomplete
            label="FOB"
            options={FOBS}
            getOptionLabel={(f) => f.name}
            placeholder="Select FOB"
            value={selectedFob}
            disabled={fieldsDisabled}
            permissions={editPermission}
            onChange={handleAutocompleteChange('fobPointId', null, 'id')}
            dataQa="purchase-order-details-fob"
          />
        </Grid>
      </Grid>

      <Box py={3}>
        <Divider />
      </Box>

      <Grid container spacing={2}>
        <Grid xs={12}>
          <TextField
            className="redesign"
            variant="standard"
            label="Notes"
            placeholder="Enter notes"
            multiline
            rows={4}
            permissions={editPermission}
            disabled={fieldsDisabled}
            onChange={handleTextFieldChange}
            name="notes"
            value={purchaseOrder.notes}
            dataQa="purchase-order-details-notes"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(FBODetailsTab);
