import React, { memo, useCallback, useMemo } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, Tooltip } from '@mui/material';
import _ from 'lodash';

import { TextField } from 'ui/components/TextField/TextField';
import { IconButtonWithActions } from 'ui/components/Button/IconButtonWithActions';
import { ImportSNMenuItems, SerialAutoAssignMenuItems } from './consts';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';

import { SerialHeaderProps } from './types';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import { themeRestyle } from 'ui/theme';

const SerialHeader: React.FC<SerialHeaderProps> = (props) => {
  const {
    title,
    customStatusStyle,
    handleAutoAssign,
    itemTrackingTypes,
    disableAutoAssign,
    search,
    allowSerialNumberImport = false,
    canSelectRow = false,
    setActiveTrackingTypeId,
    setSearch,
    handleSelectAllEnter,
    isLoading,
    autoAssignModalVisible = false,
    setAutoAssignModalVisible,
    selectedItems = [],
    handleDeleteSelectedRows = _.noop,
    showDeleteButton = false,
    disableDeleteButton = false,
    disableDeleteTooltip = '',
    disableImportButton = false,
    disableImportTooltip = '',
    hideSelectButton = false,
    sx = {},
  } = props;

  const isNewItemTrackingType = useMemo(
    () =>
      itemTrackingTypes[0]
        ? !itemTrackingTypes[0].id || itemTrackingTypes[0].id < 0
        : true,
    [itemTrackingTypes]
  );

  const noNextValue = useMemo(
    () => (itemTrackingTypes[0] ? !itemTrackingTypes[0].nextValue : true),
    [itemTrackingTypes]
  );

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => setSearch(e.target.value || null);

  const AutoAssignBtnRestyle = useCallback(() => {
    return itemTrackingTypes.length > 1 ? (
      <>
        {allowSerialNumberImport && (
          <Tooltip title={disableImportTooltip} placement="bottom">
            <div data-qa="serial-header-import-wrapper">
              <IconButtonWithActions
                iconSize={16}
                disabled={isLoading || disableImportButton}
                items={ImportSNMenuItems(
                  itemTrackingTypes,
                  setActiveTrackingTypeId
                )}
                text="IMPORT"
                data-qa="serial-header-import"
              />
            </div>
          </Tooltip>
        )}
        <IconButtonWithActions
          iconSize={16}
          disabled={isNewItemTrackingType || noNextValue || isLoading}
          items={SerialAutoAssignMenuItems(itemTrackingTypes, handleAutoAssign)}
          text="AUTO ASSIGN"
        />
      </>
    ) : (
      <>
        {allowSerialNumberImport && (
          <Tooltip title={disableImportTooltip} placement="bottom">
            <div data-qa="serial-header-import-wrapper">
              <FBOButton
                sx={{ marginRight: '8px' }}
                variant="secondary"
                color="positive"
                size="medium"
                onClick={() =>
                  setActiveTrackingTypeId(itemTrackingTypes[0].trackingTypeId)
                }
                disabled={isLoading || disableImportButton}
                data-qa="serial-header-import"
              >
                Import
              </FBOButton>
            </div>
          </Tooltip>
        )}
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          onClick={() =>
            handleAutoAssign(
              itemTrackingTypes[0].id,
              itemTrackingTypes[0].trackingTypeId
            )
          }
          data-qa="serial-header-auto-assign"
          disabled={noNextValue || isLoading}
        >
          Auto Assign
        </FBOButton>
      </>
    );
  }, [
    noNextValue,
    itemTrackingTypes,
    isNewItemTrackingType,
    allowSerialNumberImport,
    handleAutoAssign,
    setActiveTrackingTypeId,
    isLoading,
    selectedItems,
    handleDeleteSelectedRows,
    disableDeleteButton,
    disableImportButton,
    showDeleteButton,
  ]);

  return (
    <>
      <FBOTitleBar
        title={title}
        statusCustomStyle={customStatusStyle}
        noPadding
        sx={sx}
      >
        {!isNewItemTrackingType && (
          <TextField
            sx={{ marginLeft: '8px', marginRight: '8px', marginTop: '0px' }}
            placeholder="Search"
            className="redesign"
            variant="standard"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={search}
            onChange={handleSearchChange}
            dataQa="serial-header-search"
            onKeyDown={(e) => {
              if (e.keyCode === 13 && canSelectRow) handleSelectAllEnter();
            }}
          />
        )}
        {!disableAutoAssign && <AutoAssignBtnRestyle />}
        {showDeleteButton && (
          <Tooltip
            title={disableDeleteTooltip}
            placement="bottom"
            data-qa="serial-header-delete-button-tooltip"
          >
            <div data-qa="serial-header-delete-wrapper">
              <FBOButton
                sx={{ marginRight: themeRestyle.spacing(1) }}
                variant="secondary"
                color="negative"
                size="medium"
                icon="TrashCan"
                onClick={() => handleDeleteSelectedRows()}
                disabled={disableDeleteButton || selectedItems.length <= 0}
                data-qa="serial-header-delete"
              >
                Delete
              </FBOButton>
            </div>
          </Tooltip>
        )}
        {!hideSelectButton && (
          <FBOButton
            sx={{ marginRight: themeRestyle.spacing(1) }}
            variant="secondary"
            color="positive"
            size="medium"
            onClick={handleSelectAllEnter}
            disabled={!canSelectRow}
            data-qa="serial-header-select-btn"
          >
            Select
          </FBOButton>
        )}
      </FBOTitleBar>

      <ConfirmationModal
        open={autoAssignModalVisible}
        title="Auto Assign"
        onConfirmClicked={_.noop}
        onCancelClicked={_.noop}
        DialogActionsComponent={() => (
          <FBOButton
            variant="primary"
            color="positive"
            size="medium"
            loading={isLoading || false}
            onClick={() => setAutoAssignModalVisible(false)}
            data-qa="alert-modal-ok"
          >
            Ok
          </FBOButton>
        )}
        body="Auto assigning serials finished"
      />
    </>
  );
};

export default memo(SerialHeader);
