import React, { memo, useCallback } from 'react';
import { PermissionType } from 'services/permissions';
import { LocationTitleBarProps } from './types';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const LocationTitleBar: React.FC<LocationTitleBarProps> = (props) => {
  const {
    activeLocation,
    onSave,
    onClose,
    deleteModalVisible,
    onUndeleteClicked,
  } = props;

  const DeletedLocationActionsRestyle = useCallback(() => {
    return (
      <>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="location-undelete"
          onClick={() => onUndeleteClicked(false)}
          permissions={
            activeLocation.id && activeLocation.id > 0
              ? [PermissionType.LocationEdit]
              : [PermissionType.LocationsCreate]
          }
        >
          Undelete
        </FBOButton>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="undelete-and-close"
          onClick={() => onUndeleteClicked(true)}
          permissions={
            activeLocation.id && activeLocation.id > 0
              ? [PermissionType.LocationEdit]
              : [PermissionType.LocationsCreate]
          }
        >
          Undelete And Close
        </FBOButton>
      </>
    );
  }, [onUndeleteClicked, activeLocation.id]);

  const LocationActionsRestyle = useCallback(() => {
    return (
      <>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="location-save"
          onClick={() => onSave(false)}
          permissions={
            activeLocation.id && activeLocation.id > 0
              ? [PermissionType.LocationEdit]
              : [PermissionType.LocationsCreate]
          }
        >
          Save
        </FBOButton>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          onClick={async () => {
            await onSave(true);
            onClose();
          }}
          data-qa="location-save-and-close"
          permissions={
            activeLocation.id && activeLocation.id > 0
              ? [PermissionType.LocationEdit]
              : [PermissionType.LocationsCreate]
          }
        >
          Save And Close
        </FBOButton>
        {activeLocation.id && activeLocation.id > 0 && (
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="secondary"
            color="negative"
            size="medium"
            icon="TrashCan"
            data-qa="location-delete"
            onClick={() => deleteModalVisible(true)}
            permissions={[PermissionType.LocationsDelete]}
          >
            Delete
          </FBOButton>
        )}
      </>
    );
  }, [activeLocation.id, deleteModalVisible, onSave]);

  return (
    <FBOTitleBar title={`${activeLocation.name || 'New Location'}`}>
      {activeLocation.deleted ? (
        <DeletedLocationActionsRestyle />
      ) : (
        <LocationActionsRestyle />
      )}
      <FBOButton
        variant="tertiary"
        color="neutral"
        size="medium"
        icon="FBOClose"
        data-qa="location-close"
        onClick={onClose}
      />
    </FBOTitleBar>
  );
};

export default memo(LocationTitleBar);
