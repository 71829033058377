import _ from 'lodash';

import { convertUnits, getWeightUnitAbbreviation } from 'helpers';
import { Address, initialAddress } from 'services/addresses';
import { Ship } from 'services/shipping';
import { CompanySettings } from 'services/settings/company';

export const transformToShipWithSettings = (
  ship: Ship,
  settings: CompanySettings
): Ship => ({
  ...ship,
  shipCartonList: ship.shipCartonList.map((c) => {
    const weightUnit = c.weightUnit || settings.defaultWeightUom || 'Pounds';
    const dimensionUnit =
      c.dimensionUnit || settings.defaultDimensionUom || 'Inches';

    const weight = c.weight
      ? c.weight
      : ship.shipItemList.reduce((acc, cartonItem) => {
          const resolveCartonItem = convertUnits(
            _.get(cartonItem, 'salesOrderItem.saleItem.weight', 0),
            getWeightUnitAbbreviation(
              _.get(cartonItem, 'salesOrderItem.saleItem.weightUnit')
            ),
            getWeightUnitAbbreviation(weightUnit)
          );

          return acc + resolveCartonItem * cartonItem.quantity;
        }, 0);

    return {
      ...c,
      weight,
      weightUnit,
      dimensionUnit,
    };
  }),
});

export const transformShipToAddress = (ship: Ship): Address => {
  return {
    ...initialAddress,
    name: ship.shipToName,
    country: ship.shipToCountry,
    companyName: ship.shipToCompanyName,
    postalCode: ship.shipToPostalCode,
    residential: ship.shipToResidential,
    city: ship.shipToCity,
    street: ship.shipToStreet,
    street2: ship.shipToStreet2,
    state: ship.shipToState,
  };
};
