import React, { memo, useCallback, useMemo, useState } from 'react';
import { TableCell, Popover, Box, Link, Tooltip } from '@mui/material';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { IconNames, colorPalette } from 'ui/theme';

import { TextField } from 'ui/components/TextField/TextField';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import { TextFieldWithOptions } from 'ui/components/TextField/TextFieldWithOptions';
import { MenuItem } from 'ui/components/Menu';
import { ShipCarton, ShipItem, ShippingCarrierType } from 'services/shipping';
import { DIMENSIONS_UNITS, WEIGHT_UNITS } from 'services/items';
import { PermissionType } from 'services/permissions';
import { getCarriers } from 'services/carriers';
import { activeUserHasPermission } from 'services/user/redux';
import { getCarrierUrl } from 'services/shipping/helpers';
import { getShippingConnection } from 'services/integrations/shipping/redux';
import { ShippingConnectionType } from 'services/integrations/shipping';
import { convertUnits, getWeightUnitAbbreviation } from 'helpers';

import { PackageDimensionsInput } from '../PackageDimensionsInput';
import { dimensionsAnchorOrigin, dimensionsTransformOrigin } from './consts';
import { useShipItemRowHeaderStyle } from './styled';
import { DimensionsInputParams } from './types';
import { transformToDimensions } from './transformations';
import { ShipItemAction } from '../../../../types';
import { stringToDecimal } from 'services/forms';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const weightOptions: MenuItem[] = WEIGHT_UNITS.map((u) => ({
  label: u.name,
  shortName: u.shortName,
}));

const ShipItemRowHeader: React.FC<RenderCustomRowProps<ShipCarton>> = (
  props
) => {
  const {
    row: carton,
    index,
    setData,
    onAction,
    errors,
    meta: { ship, disabledFields },
  } = props;

  const classes = useShipItemRowHeaderStyle();

  const { items: carriers } = useSelector(getCarriers);
  const connection = useSelector(getShippingConnection);

  const [dimensionsEl, setDimensionsEl] = useState<null | HTMLElement>(null);
  const [dimensions, setDimensions] = useState<DimensionsInputParams>(
    transformToDimensions(carton)
  );

  const hasShipItems = ship.shipItemList.find(
    (e: ShipItem) => e.shipCartonId === carton.id
  );

  const canEditShippingRow = useSelector(
    activeUserHasPermission([PermissionType.ShippingEdit])
  );

  const canEditDimensions = canEditShippingRow && !disabledFields;

  const selectedCarrier = useMemo(
    () => carriers.find((c) => c.id === ship.carrierId) || null,
    [ship.carrierId, carriers]
  );

  const hasCarrierTracking = useMemo(() => {
    if (selectedCarrier) {
      return (
        selectedCarrier.name === ShippingCarrierType.USPS ||
        selectedCarrier.name === ShippingCarrierType.FedEx ||
        selectedCarrier.name === ShippingCarrierType.UPS
      );
    }
    return false;
  }, [selectedCarrier]);

  const isDimensionsPopoverVisible = useMemo(
    () => Boolean(dimensionsEl),
    [dimensionsEl]
  );
  const selectedWeightUnitIndex = useMemo(() => {
    if (!carton.weightUnit) {
      return null;
    }
    return weightOptions.findIndex((o) => o.label === carton.weightUnit);
  }, [carton.weightUnit]);

  const dimensionsString = useMemo(() => {
    const dimension = DIMENSIONS_UNITS.find(
      (u) => u.name === carton.dimensionUnit
    );

    return `${carton.length || 0} x ${carton.width || 0} x ${
      carton.height || 0
    } ${dimension ? dimension.shortName : DIMENSIONS_UNITS[0].shortName}`;
  }, [carton.height, carton.length, carton.width, carton.dimensionUnit]);

  const isShippingLabelDisabled = useMemo(() => {
    return !carton.shippingLabelUrl;
    // eslint-disable-next-line
  }, [carton.version]);

  const isIconVisible = useMemo(() => {
    if (!connection) {
      return false;
    }

    const shippingTypeConnection =
      connection.typeId === ShippingConnectionType.Shippo || null;

    return shippingTypeConnection && !isShippingLabelDisabled;
  }, [connection, isShippingLabelDisabled]);

  const handleTextFieldChange = useCallback(
    (key: string | null = null) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const value =
          event.target.value !== ''
            ? event.target.type === 'number'
              ? parseFloat(event.target.value)
              : event.target.value
            : null;
        setData({ ...carton, [key || event.target.name]: value }, index);
      },
    [carton, index, setData]
  );

  const handleNumberFieldChange = useCallback(
    (key: string | null = null) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = stringToDecimal(event?.target?.value);
        setData({ ...carton, [key || event.target.name]: value }, index);
      },
    [carton, index, setData]
  );

  const handleDimensionUnitChange = useCallback(
    (itemIndex: number) => {
      const dimensionUnit = DIMENSIONS_UNITS[itemIndex].name;
      setDimensions({ ...dimensions, dimensionUnit });
    },
    [dimensions]
  );

  const handleWeightUnitChange = useCallback(
    (optionIndex: number) => {
      setData(
        {
          ...carton,
          weightUnit: weightOptions[optionIndex].label,
          weight: convertUnits(
            carton.weight || 0,
            getWeightUnitAbbreviation(carton.weightUnit),
            getWeightUnitAbbreviation(weightOptions[optionIndex].label)
          ),
        },
        index
      );
    },
    [carton, index, setData]
  );

  const handleDimensionsClicked = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      setDimensions(transformToDimensions(carton));
      setDimensionsEl(event.currentTarget);
    },
    [carton]
  );

  const handleDimensionsPopClose = useCallback(() => {
    setDimensionsEl(null);
  }, []);

  const handleDimensionsChange = useCallback(
    (unit: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event?.target?.value;

      setDimensions({
        ...dimensions,
        [unit]: value ? stringToDecimal(value) : null,
      });
    },
    [dimensions]
  );

  const handleDimensionsSubmitClicked = useCallback(() => {
    setData({ ...carton, ...dimensions }, index);
    setDimensionsEl(null);
  }, [dimensions, carton, index, setData]);

  const handleDeleteClick = useCallback(() => {
    if (onAction) {
      onAction({ type: ShipItemAction.DeleteCarton, data: carton.id });
    }
  }, [onAction, carton.id]);

  const handleRefundClick = useCallback(() => {
    if (onAction) {
      onAction({ type: ShipItemAction.RefundCarton, data: carton.id });
    }
  }, [onAction, carton.id]);

  const handleShippingLabelClick = () => {
    if (carton.shippingLabelUrl) {
      window.open(carton.shippingLabelUrl, '_blank');
    }
  };
  const handleLinkClicked = () => {
    if (!carton.trackingNumber || !selectedCarrier) {
      return;
    }
    const url = getCarrierUrl(carton.trackingNumber, selectedCarrier);

    if (url) {
      window.open(url, '_blank');
    }
  };

  return (
    <>
      <TableCell>
        <Box className={classes.cartonName}>Package {carton.number}</Box>
      </TableCell>
      <TableCell className={classes.smallColumn}>
        <CurrencyField
          name="cost"
          value={carton.cost}
          permissions={[PermissionType.ShippingEdit]}
          disabled={disabledFields}
          onChange={handleNumberFieldChange('cost')}
          dataQa="shipping-item-cost"
          disableDebounce={true}
          decimalPlaces={2}
        />
      </TableCell>
      <TableCell className={classes.smallColumn}>
        <CurrencyField
          name="insuredValue"
          value={carton.insuredValue}
          disabled={disabledFields}
          permissions={[PermissionType.ShippingEdit]}
          onChange={handleNumberFieldChange('insuredValue')}
          dataQa="shipping-item-declared-value"
          disableDebounce={true}
          decimalPlaces={2}
        />
      </TableCell>
      <TableCell>
        <Tooltip title="L x W x H" placement="bottom-start">
          <div>
            <Link
              onClick={canEditDimensions ? handleDimensionsClicked : _.noop}
              href="#"
              underline="always"
              variant="body2"
              color={
                !errors.height && !errors.width && !errors.length
                  ? 'textSecondary'
                  : 'error'
              }
            >
              {dimensionsString}
            </Link>
          </div>
        </Tooltip>
        <Popover
          anchorOrigin={dimensionsAnchorOrigin}
          transformOrigin={dimensionsTransformOrigin}
          anchorEl={dimensionsEl}
          open={isDimensionsPopoverVisible}
          onClose={handleDimensionsPopClose}
        >
          <Box p={1} display="flex" alignItems="center">
            <PackageDimensionsInput
              heightValue={dimensions.height}
              widthValue={dimensions.width}
              lengthValue={dimensions.length}
              onHeightChange={handleDimensionsChange('height')}
              onWidthChange={handleDimensionsChange('width')}
              onLengthChange={handleDimensionsChange('length')}
              onMenuChange={handleDimensionUnitChange}
              dimension={dimensions.dimensionUnit}
            />
            <Box ml={1}>
              <FBOButton
                variant="primary"
                color="positive"
                size="medium"
                onClick={handleDimensionsSubmitClicked}
                data-qa="ship-item-row-header-add-button"
              >
                Add
              </FBOButton>
            </Box>
          </Box>
        </Popover>
      </TableCell>
      <TableCell className={classes.smallColumn}>
        <TextFieldWithOptions
          options={weightOptions}
          type="number"
          value={carton.weight}
          permissions={[PermissionType.ShippingEdit]}
          onTextChange={handleNumberFieldChange('weight')}
          onMenuChange={handleWeightUnitChange}
          disabled={disabledFields}
          disableDebounce={true}
          selectedOptionIndex={selectedWeightUnitIndex}
          dataQa="shipping-item-weight"
          error={!!errors.weight}
        />
      </TableCell>
      <TableCell>
        <Tooltip title={carton.trackingNumber} placement="right-start">
          <TextField
            className="redesign"
            variant="standard"
            name="trackingNumber"
            value={carton.trackingNumber}
            permissions={[PermissionType.ShippingEdit]}
            onChange={handleTextFieldChange()}
            dataQa="shipping-item-tracking-number"
            InputProps={{
              endAdornment: (
                <>
                  {carton.trackingNumber && hasCarrierTracking && (
                    <FBOButton
                      variant="tertiary"
                      color="neutral"
                      size="small"
                      icon={IconNames.IconLinkHorizontal}
                      onClick={handleLinkClicked}
                      data-qa="ship-item-row-header-link-icon-button"
                    />
                  )}
                </>
              ),
            }}
          />
        </Tooltip>
      </TableCell>
      <TableCell padding="none" align="center">
        {isIconVisible && (
          <Tooltip title="Open Shipping Label">
            <FBOButton
              variant="tertiary"
              color="neutral"
              size="small"
              icon={IconNames.IconNote}
              onClick={handleShippingLabelClick}
              data-qa="show-item-row-header-drive-file-button"
              style={{ color: colorPalette.redesign.contentPrimary }}
            />
          </Tooltip>
        )}
      </TableCell>

      <TableCell padding="none" align="center">
        {isIconVisible && (
          <Tooltip title="Refund Label">
            <FBOButton
              variant="tertiary"
              color="neutral"
              size="small"
              icon={IconNames.IconRefund}
              onClick={handleRefundClick}
              data-qa="ship-item-row-header-refund-button"
              style={{ color: colorPalette.redesign.contentPrimary }}
            />
          </Tooltip>
        )}
      </TableCell>
      <TableCell padding="none" align="center">
        <Tooltip
          title={
            hasShipItems
              ? 'Remove ship items before deleting'
              : 'Delete Package'
          }
        >
          <div>
            <FBOButton
              variant="tertiary"
              color="negative"
              size="small"
              icon={IconNames.TrashCan}
              onClick={handleDeleteClick}
              data-qa="ship-item-row-header-delete-button"
              disabled={hasShipItems}
            />
          </div>
        </Tooltip>
      </TableCell>
    </>
  );
};

export default memo(ShipItemRowHeader);
