import { Dispatch, SetStateAction } from 'react';
import { getItemInventory } from 'services/inventory/api';
import { QuickTransferFormValues } from './types';
import { fetchAllItemsIdByLocationId, fetchItem } from 'services/items';
import { v4 as uuidv4 } from 'uuid';
import { getErrorMessage } from 'helpers';
import { logErrorCtx } from 'app/logging';
import { showNotification } from 'services/api';

export const AddAllItems = async (
  ids: number[],
  locationId: number,
  setTableData: Dispatch<SetStateAction<QuickTransferFormValues[]>>,
  formValues: QuickTransferFormValues
) => {
  try {
    ids.map(async (itemId) => {
      const newItem = await fetchItem(itemId || 0);
      const newInventory = await getItemInventory(itemId || 0);
      const locationSummary = newInventory.inventoryRowList.find(
        (summary) => summary.id === locationId
      );
      if (
        (locationSummary?.totalQty || 0) -
          (locationSummary?.committedQty || 0) >
        0
      ) {
        setTableData((data: any) => [
          ...data,
          {
            ...formValues,
            id: uuidv4(),
            Item: newItem,
            quantity:
              (locationSummary?.totalQty || 0) -
              (locationSummary?.committedQty || 0),
            trackingGroupObjects: [],
          },
        ]);
      }
    });
  } catch (e) {
    const message = getErrorMessage(e);
    logErrorCtx('Error in adding all items to quick transfer ', {
      error: e as Error,
      stackTrace: (e as Error).stack,
      component: 'ItemDetailsCard',
      title: 'Error in fetchItem',
      description: `Items id ${ids}`,
    });
    showNotification(`${message} -  item couldn't be loaded.`, {
      variant: 'error',
    });
  }
};

export const getAllItemIds = async (locationId: number) => {
  try {
    const res = await fetchAllItemsIdByLocationId(locationId);
    return res; // Return the result if needed
  } catch (e) {
    const message = getErrorMessage(e);
    logErrorCtx('Error in getting all items by location to quick transfer ', {
      error: e as Error,
      stackTrace: (e as Error).stack,
      component: 'ItemDetailsCard',
      title: 'Error in fetchAllItemsIdByLocationId',
      description: `location id ${locationId}`,
    });
    showNotification(`${message} -  item couldn't be loaded.`, {
      variant: 'error',
    });
  }
};
