import { InvoiceDetails } from 'services/invoices';

export const initialInvoiceDetails: InvoiceDetails = {
  id: null,
  billToAddress: null,
  carrier: null,
  carrierService: null,
  customer: null,
  customerPO: null,
  dateDue: null,
  dateShipped: null,
  items: null,
  location: null,
  number: null,
  orderClass: null,
  orderLevelDiscount: null,
  orderTax: null,
  paymentTerm: null,
  payments: null,
  salesOrderId: null,
  shipToAddress: null,
  total: null,
  transactionId: null,
  transactions: null,
  type: null,
};
