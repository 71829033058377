import {
  PurchaseOrderItem,
  PurchaseOrderItemTypes,
} from 'services/purchaseOrders';

export const hasDropShipItem = (poItems: PurchaseOrderItem[]): boolean => {
  return poItems.some(
    (poi: PurchaseOrderItem) =>
      poi.purchaseOrderItemType === PurchaseOrderItemTypes.DropShip
  );
};
