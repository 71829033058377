import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import * as yup from 'yup';

import { Errors, validateYup } from 'services/forms/validation';
import { useHandleTextFieldChange } from 'services/forms';
import { postRepresentative } from 'services/representatives/api';
import {
  Representative,
  fetchRepresentatives,
  initialRepresentative,
} from 'services/representatives';
import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';

import { NewRepresentativeModalProps } from './types';

const yupSchema = yup.object().shape({
  firstName: yup.string(),
  initials: yup.string(),
});

const NewRepresentativeModal: React.FC<NewRepresentativeModalProps> = (
  props
) => {
  const { show, onClose, onSave, newItemName } = props;

  const dispatch = useDispatch();

  const [formRepresentative, setFormRepresentative] = useState<Representative>(
    initialRepresentative
  );
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Errors>({});

  const handleApplyModal = async () => {
    if (!validateYup(formRepresentative, yupSchema, setErrors)) {
      return;
    }

    setIsLoading(true);

    try {
      const newRepresentative = await postRepresentative(formRepresentative);
      // Lint skip to be removed
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(fetchRepresentatives());
      onSave(newRepresentative);
      setErrors({});
    } catch {
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    setFormRepresentative(initialRepresentative);
  };

  const handleTextFieldChange = useHandleTextFieldChange(
    setFormRepresentative,
    formRepresentative
  );

  useEffect(() => {
    setFormRepresentative({
      ...initialRepresentative,
      firstName: newItemName,
    });

    setErrors({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <Modal
      open={show}
      title="New Representative"
      onCancelClicked={onClose}
      onApplyClicked={handleApplyModal}
      isLoadingContent={isLoading}
      maxWidth="md"
    >
      <Grid container spacing={2}>
        <Grid item container xs={12} spacing={2}>
          <Grid item container xs={12}>
            <Typography variant="caption">
              <strong>Representative Details</strong>
            </Typography>
          </Grid>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={6}>
              <TextField
                className="redesign"
                variant="standard"
                name="firstName"
                label="First Name"
                placeholder="Enter first name"
                value={formRepresentative.firstName}
                onChange={handleTextFieldChange}
                error={!!errors.firstName}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="redesign"
                variant="standard"
                name="middleName"
                label="Middle Name"
                placeholder="Enter middle name"
                value={formRepresentative.middleName}
                onChange={handleTextFieldChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="redesign"
                variant="standard"
                name="lastName"
                label="Last Name"
                placeholder="Enter last name"
                value={formRepresentative.lastName}
                onChange={handleTextFieldChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="redesign"
                variant="standard"
                name="initials"
                label="Initials"
                placeholder="Enter initials"
                value={formRepresentative.initials}
                onChange={handleTextFieldChange}
                error={!!errors.initials}
                required
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default memo(NewRepresentativeModal);
