export const calculateSerialLinkLabel = (serialDiff: number) => {
  if (serialDiff === 0) {
    return 'Edit';
  } else if (serialDiff > 0) {
    return `${Math.abs(serialDiff)} to add`;
  } else if (serialDiff < 0) {
    return `${Math.abs(serialDiff)} to remove`;
  }
  return '';
};
