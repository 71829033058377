import React, { memo, useEffect, useState, useCallback } from 'react';
import _ from 'lodash';

import { Box } from '@mui/material';
import { Modal } from 'ui/components/Modal/Modal';
import { PaymentModal } from 'ui/components/Modal/PaymentModal';
import { ItemsTable } from 'ui/components/Table/ItemsTable';
import {
  createPayment,
  CreateSalesOrderPayment,
  fetchSalesOrderById,
  fetchSalesOrderPaymentAPI,
  initialSalesOrderPayment,
  SalesOrderPayment,
} from 'services/salesOrders';
import { showNotification } from 'services/api';

import { SalesOrderPaymentsModalProps } from './types';
import { PAYMENTS_COLUMS } from './consts';
import { PaymentRow } from './components';
import { RefundModal } from './components/RefundModal';
import { refundPayment } from './helpers';
import { logErrorCtx } from 'app/logging';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const SalesOrderPaymentsModal: React.FC<SalesOrderPaymentsModalProps> = (
  props
) => {
  const { salesOrder, visible, onClose, setSalesOrder, oldState } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [payments, setPayments] = useState<SalesOrderPayment[]>([]);
  const [showRefundModal, setShowRefundModal] = useState<boolean>(false);
  const [amountError, setAmountError] = useState<string | null>(null);
  const [refundAmount, setRefundAmount] = useState<number | null>(0);
  const [payment, setPayment] = useState<SalesOrderPayment>(
    initialSalesOrderPayment
  );
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);

  const asyncFc = useCallback(async (id: number) => {
    setIsLoading(true);
    try {
      const payments = await fetchSalesOrderPaymentAPI({}, id);
      setPayments(payments.data);
    } catch {
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (visible) {
      asyncFc(salesOrder.id!);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const openPaymentModal = useCallback(() => {
    setPaymentModalVisible(true);
  }, []);

  const handleClosePaymentModal = useCallback(() => {
    onClose();
    setPayments([]);
  }, [onClose]);

  const closeRefundModal = useCallback(() => {
    setShowRefundModal(false);
    setRefundAmount(0);
    setAmountError('');
  }, []);

  const onRowAction = (payment: SalesOrderPayment) => {
    setPayment(payment);
    setRefundAmount(payment.remainingForRefund);
    setShowRefundModal(true);
  };

  const fetchSalesOrderAfterRefund = useCallback(async () => {
    try {
      asyncFc(salesOrder.id!);
      const newSalesOrder = await fetchSalesOrderById(salesOrder.id!);
      setSalesOrder(newSalesOrder);
      oldState.current = newSalesOrder;
    } catch (e) {
      logErrorCtx('Error in fetchSalesOrderAfterRefund', {
        error: e as Error,
        stackTrace: (e as Error).stack,
        component: 'SalesOrderPaymentsModal',
        title: 'Error in fetchSalesOrderAfterRefund',
        description: `Sales order id ${salesOrder.id}`,
      });
    }
  }, [setSalesOrder, salesOrder.id, asyncFc, oldState]);

  const handleRefundPaymentClicked = useCallback(
    async (e: any) => {
      e.stopPropagation();

      if (payment.amount && refundAmount && payment.amount < refundAmount) {
        setAmountError(
          'Refund amount cannot be greater than the original payment'
        );
        return;
      }

      if (refundAmount === 0) {
        setAmountError('Refund amount must be greater then 0');
        return;
      }

      setIsLoading(true);
      try {
        await refundPayment(payment, refundAmount!);
      } catch (e) {
        logErrorCtx('Error in refundPayment', {
          error: e as Error,
          stackTrace: (e as Error).stack,
          component: 'SalesOrderPaymentsModal',
          title: 'Error in refundPayment',
          description: 'Error in refundPayment',
        });
        const msg = _.get(
          e,
          'response.data.errorMessage',
          'Payment service error. Contact Support.'
        );
        if (msg) {
          showNotification(msg, { variant: 'error' });
        }
        setIsLoading(false);
        return;
      }

      fetchSalesOrderAfterRefund();
      closeRefundModal();
      setIsLoading(false);
    },
    [closeRefundModal, fetchSalesOrderAfterRefund, payment, refundAmount]
  );

  const handlePaymentSave = useCallback(
    async (newPayment?: CreateSalesOrderPayment) => {
      setIsLoading(true);
      setPaymentModalVisible(false);
      try {
        if (newPayment) {
          await createPayment(salesOrder.id!, newPayment);
        }

        await new Promise((resolve) => setTimeout(resolve, 3000));

        const newSalesOrder = await fetchSalesOrderById(salesOrder.id!);
        setSalesOrder(newSalesOrder);
        oldState.current = newSalesOrder;
      } catch (e) {
        logErrorCtx('Error in createPayment', {
          error: e as Error,
          stackTrace: (e as Error).stack,
          component: 'SalesOrderPaymentsModal',
          title: 'Error in createPayment',
          description: 'Error in createPayment',
        });
        const msg = _.get(
          e,
          'response.data.errorMessage',
          'Payment service error. Contact Support.'
        );
        if (msg) {
          showNotification(msg, { variant: 'error' });
        }
      }

      await asyncFc(salesOrder.id!);
      setIsLoading(false);
    },
    [setSalesOrder, asyncFc, salesOrder, oldState]
  );

  const handlePaymentClose = useCallback(() => {
    setPaymentModalVisible(false);
  }, []);

  const FBOModalActions = useCallback(() => {
    return (
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box display="flex" justifyContent="flex-end" width="100%">
          <FBOButton
            variant="secondary"
            color="positive"
            size="medium"
            onClick={handleClosePaymentModal}
            data-qa="cancel-add-payment-sales-order"
            sx={{ marginRight: '8px' }}
          >
            Cancel
          </FBOButton>
          <FBOButton
            variant="primary"
            color="positive"
            size="medium"
            onClick={openPaymentModal}
            data-qa="addPaymentbtn-soPaymentsModal"
          >
            Add Payment
          </FBOButton>
        </Box>
      </Box>
    );
  }, [openPaymentModal]);

  return (
    <Modal
      open={visible}
      onCancelClicked={handleClosePaymentModal}
      onApplyClicked={openPaymentModal}
      isLoadingContent={isLoading}
      withoutDefaultPadding
      title="Payments"
      customHeight={400}
      maxWidth="md"
      footerDivider="border"
      dataQa="sale-order-payment-add-payment"
      ModalActionsComponent={FBOModalActions}
    >
      <ItemsTable
        data={payments}
        columns={PAYMENTS_COLUMS}
        RenderCustomRow={PaymentRow}
        selectableItems={false}
        onAction={onRowAction}
        emptyTableText="THERE ARE NO PAYMENTS FOR THIS SALES ORDER"
      />
      <PaymentModal
        activeItem={salesOrder}
        visible={paymentModalVisible}
        onSave={handlePaymentSave}
        handleClosePaymentModal={handleClosePaymentModal}
        onClose={handlePaymentClose}
        processingPayment={isLoading}
      />
      <RefundModal
        showRefundModal={showRefundModal}
        onApplyClicked={handleRefundPaymentClicked}
        onCancelClicked={closeRefundModal}
        payment={payment}
        refundAmount={refundAmount}
        setRefundAmount={setRefundAmount}
        error={amountError}
      />
    </Modal>
  );
};

export default memo(SalesOrderPaymentsModal);
