import React, { useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import _ from 'lodash';

import { DOT_CHAR, useCurrencyFormatter } from 'helpers';
import { ImageItemBox } from 'ui/components/Image/ImageItemBox';
import { COMPANY_WIDE_ID } from 'services/locations/consts';
import { InventoryLocationSummary } from 'services/inventory';
import { SalesOrderItemTypes } from 'services/salesOrders';
import { ItemType } from 'services/items';

import { SaleItemOptionProps } from './types';

const SaleItemOption: React.FC<SaleItemOptionProps> = (props) => {
  const { salesOrder, option } = props;

  const currencyFormatter = useCurrencyFormatter();

  const handleAutocompleteAvailableItemQuantity = useCallback(
    (locationList: InventoryLocationSummary[]) => {
      // if no location or company wide or bundle add all available quantities
      if (
        salesOrder.locationId === COMPANY_WIDE_ID ||
        option.defaultSalesOrderItemType === SalesOrderItemTypes.Bundle ||
        option.defaultSalesOrderItemType ===
          SalesOrderItemTypes.BundleCreditReturn ||
        salesOrder.locationId === null
      ) {
        return locationList.reduce(
          (total, item) => total + item.availableQty,
          0
        );
      }

      const locationInventory = locationList.find(
        (location) => location.rootLocationId === salesOrder.locationId
      );

      return locationInventory ? locationInventory.availableQty : 0;
    },
    [salesOrder.locationId, option.defaultSalesOrderItemType]
  );

  if (!option.id || option.id < 0) {
    return <Typography variant="body1">+ Add "{option.name}"</Typography>;
  }

  return (
    <Box display="flex" width="100%" flexDirection="column">
      <Box display="flex" width="100%">
        <Box flexGrow={1} minWidth={0}>
          <ImageItemBox
            name={`${_.get(option, 'name', '')}${
              option.sku ? ` ${DOT_CHAR} SKU: ${option.sku}` : ''
            }`}
            description={_.get(option, 'description', null)}
            source={_.get(option, 'defaultImage', null)}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          width={130}
          textAlign="right"
          flexShrink={0}
        >
          <Typography variant="body1">
            {option.price !== null ? currencyFormatter(option.price) : ''}
          </Typography>
          {option.item && option.item.itemType === ItemType.Inventory ? (
            <Typography variant="subtitle2" color="textSecondary">
              {handleAutocompleteAvailableItemQuantity(
                option.inventoryLocationSummaryList
              )}
              {` `}
              {option.defaultUom && option.defaultUom.abbreviation}
            </Typography>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(SaleItemOption);
