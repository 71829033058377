import { makeStyles } from '@mui/styles';
import { colorPalette } from 'ui/theme';

export const useLocationsPresentationalComponentStyles = makeStyles(() => ({
  locationContainer: {
    height: '100%',
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    overflow: 'auto',
    justifyContent: 'flex-start',
    padding: '16px 0',
    marginLeft: '-4px',
  },
  location: {
    color: colorPalette.applicationDarkGray80,
    marginRight: '4px',
    '& span': {
      marginRight: '4px',
    },
  },
}));
