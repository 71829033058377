import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { SpinLoaderProps } from './types';

export const useSpinnerStyle = makeStyles<Theme, SpinLoaderProps>({
  spinner: {
    position: ({ position }) => (position ? position : 'absolute'),
    top: '50%',
    left: '50%',
    height: ({ height }) => height ?? height,
    width: ({ width }) => width ?? width,
    marginTop: ({ marginTop }) => (marginTop ? marginTop : -12),
    marginLeft: ({ marginLeft }) => (marginLeft ? marginLeft : -12),
    zIndex: ({ zIndex }) => (typeof zIndex === 'number' ? zIndex + 1 : 'auto'),
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.38)',
    // TODO remove ts-ignore
    // @ts-ignore
    zIndex: ({ zIndex }) => (zIndex ? zIndex : 'auto'),
  },
});
