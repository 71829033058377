import React, { useCallback, useMemo } from 'react';
import { Box, TableCell, Typography, Tooltip } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { MultiCurrencyWrapper } from 'ui/components/MultiCurrencyWrapper/MultiCurrencyWrapper';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import { ImageItemBox } from 'ui/components/Image/ImageItemBox';
import { StatusLabel } from 'ui/components/Status/StatusLabel';
import { getSettingsCompany } from 'services/settings/company';
import { showThumbnail } from 'services/thumbnail/redux';
import {
  useCurrencyFormatter,
  useGetIntlDateFormatString,
} from 'helpers/hooks';
import { ReceiptItem } from 'services/receiving';
import { IconNames } from 'ui/theme';

import { useCustomRowStyle } from './styled';
import { MENU_ACTIONS } from './consts';
import { ThreeDotMenuActions } from '../../../../types';
import {
  calculateReceiptItemCost,
  calculateReceiptItemLandedUnitCost,
  calculateReceiptItemUnitCost,
  isTrackingIconButtonDisabled,
  resolveReceiptItemUnitCost,
} from './helpers';
import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const CustomRow: React.FC<RenderCustomRowProps<ReceiptItem>> = (props) => {
  const {
    row,
    onAction,
    meta: { orderMulticurrencyData },
  } = props;

  const { homeCurrency } = useSelector(getSettingsCompany);
  const intlFormatDate = useGetIntlDateFormatString();

  const homeCurrencyCode = (homeCurrency && homeCurrency.code) || 'USD';

  const activeMulticurrencyCode = _.get(
    orderMulticurrencyData,
    'currencyCode',
    'USD'
  );
  const exchangeRate = _.get(orderMulticurrencyData, 'exchangeRate', 1);

  const currencyFormatter = useCurrencyFormatter();
  const currencyFormatterSix = useCurrencyFormatter(2, 6);

  const classes = useCustomRowStyle();

  const { source, name, description } = useMemo(() => {
    const DOT_CHAR = '\u2022';

    let innerSource = null;
    let innerName = '';
    let innerDescription = null;

    if (row.purchaseOrderItem) {
      const vendorItemName = row.purchaseOrderItem.vendorItemName
        ? ` ${DOT_CHAR} ${row.purchaseOrderItem.vendorItemName}`
        : '';
      innerSource = row.purchaseOrderItem.item
        ? row.purchaseOrderItem.item.defaultImage
        : null;
      innerName = row.purchaseOrderItem.name
        ? row.purchaseOrderItem.name + vendorItemName
        : '';
      innerDescription = row.purchaseOrderItem.description;
    } else {
      innerSource = row.item ? row.item.defaultImage : null;
      innerName = _.get(row, 'item.name', '');
      innerDescription = _.get(row, 'item.description', '');
    }

    return {
      source: innerSource,
      name: innerName,
      description: innerDescription,
    };
  }, [row]);

  const dispatch = useDispatch();

  const handleOpenPreview = useCallback(
    (e) => {
      const imageUrl = source;

      dispatch(showThumbnail(imageUrl as string));
      e.stopPropagation();
    },
    [dispatch, source]
  );

  const dateScheduled = useMemo(() => {
    if (row.purchaseOrderItem && row.purchaseOrderItem.dateScheduled) {
      return moment(row.purchaseOrderItem.dateScheduled).format(intlFormatDate);
    }
    return '';
  }, [row.purchaseOrderItem, intlFormatDate]);

  const itemHasTracking = useMemo(
    () => !_.isEmpty(_.get(row, 'item.itemTrackingTypeList', [])),
    [row]
  );

  const onThreeDotMenuAction = (type: ThreeDotMenuActions) => {
    if (onAction) {
      const data = {
        type,
        receiptItem: row,
      };
      onAction(data);
    }
  };

  const handleTrackingIconClicked = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      if (onAction) {
        onAction({ type: ThreeDotMenuActions.ShowTracking, receiptItem: row });
      }
    },
    [row, onAction]
  );

  return (
    <>
      <TableCell>
        <ImageItemBox
          source={source}
          name={name}
          description={description}
          onClick={handleOpenPreview}
        />
      </TableCell>
      <TableCell>
        <StatusLabel status={row.status} />
      </TableCell>
      <TableCell>
        {row.quantity + ' ' + (row.uom ? row.uom.abbreviation : 'ea')}
      </TableCell>
      <TableCell className={classes.subHeading}>
        <Typography variant="subtitle2">
          {row.dateBilled ? moment(row.dateBilled).format(intlFormatDate) : ''}
        </Typography>
        <Tooltip
          title={row.referenceNumber ? `ref-${row.referenceNumber}` : ' '}
        >
          <Typography variant="caption" color="textSecondary">
            {row.referenceNumber ? `ref-${row.referenceNumber}` : ' '}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell>{dateScheduled}</TableCell>
      <TableCell>{row.location ? row.location.name : ''}</TableCell>
      <TableCell>
        {currencyFormatterSix(resolveReceiptItemUnitCost(row) || 0)}
      </TableCell>
      <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
        <TableCell>
          {calculateReceiptItemUnitCost(
            row,
            currencyFormatterSix,
            activeMulticurrencyCode,
            homeCurrencyCode
          )}
        </TableCell>
      </MultiCurrencyWrapper>
      <TableCell>
        {currencyFormatter(
          (resolveReceiptItemUnitCost(row) || 0) * (row.quantity || 1)
        )}
      </TableCell>
      <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
        <TableCell>
          {calculateReceiptItemCost(
            row,
            currencyFormatter,
            activeMulticurrencyCode,
            homeCurrencyCode
          )}
        </TableCell>
      </MultiCurrencyWrapper>
      <TableCell>{currencyFormatter(row.billedTotalCost || 0)}</TableCell>
      <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
        <TableCell>
          {currencyFormatter(
            row.multiCurrencyBilledTotalCost || 0,
            activeMulticurrencyCode
          )}
        </TableCell>
      </MultiCurrencyWrapper>
      <TableCell>
        {currencyFormatter((row.landedTotalCost || 0) / (row.quantity || 1))}
      </TableCell>
      <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
        <TableCell>
          {calculateReceiptItemLandedUnitCost(
            row,
            currencyFormatterSix,
            activeMulticurrencyCode,
            homeCurrencyCode,
            exchangeRate
          )}
        </TableCell>
      </MultiCurrencyWrapper>
      <TableCell>{currencyFormatter(row.landedTotalCost || 0)}</TableCell>
      <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
        <TableCell>
          {currencyFormatter(
            row.multiCurrencyLandedTotalCost || 0,
            activeMulticurrencyCode
          )}
        </TableCell>
      </MultiCurrencyWrapper>
      <TableCell align="center" padding="none">
        <Box display="flex" justifyContent="flex-end">
          {itemHasTracking && (
            <FBOButton
              variant="tertiary"
              color="neutral"
              size="medium"
              icon={IconNames.TrackingAim}
              onClick={handleTrackingIconClicked}
              data-qa={'item-row-tracking-details-button'}
              disabled={isTrackingIconButtonDisabled(row.status)}
            />
          )}

          <FBOMenuButton
            variant="tertiary"
            size="small"
            items={MENU_ACTIONS(
              onThreeDotMenuAction,
              row.status,
              row.receiptItemType,
              row.landedTotalCost,
              row.billedTotalCost
            )}
            data-qa="receiving-item-three-dot-menu"
          />
        </Box>
      </TableCell>
    </>
  );
};

export default React.memo(CustomRow);
