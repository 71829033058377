import { createFilterOptions, FilterOptionsState } from '@mui/material';

import { SaleItem } from 'services/items/saleItems';
import { fetchSaleItemsAPI } from 'services/items/saleItems/api';
import { Pagination } from 'services/search';

const filter = createFilterOptions<SaleItem>();

export const saleItemAutocompleteOptionLabel = (saleItem: SaleItem) => {
  if (!saleItem.id || saleItem.id < 0) {
    return `+ Add "${saleItem.name}"`;
  }

  return saleItem.name || '';
};

export const saleItemAsyncAutocompleteFilterOptions = (
  options: SaleItem[],
  params: FilterOptionsState<SaleItem>
) => {
  const filtered = filter(options, params);

  return filtered;
};

export const resolvedFetchSaleItemFunction = (
  expands?: string[],
  customQuickSearchColumns?: string[]
) => {
  return (pagination: Pagination, searchValue: string | null) =>
    fetchSaleItemsAPI({
      pagination,
      expands,
      quickSearchValue: searchValue,
      customQuickSearchColumns,
    });
};
