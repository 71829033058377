import _ from 'lodash';
import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  useRef,
  memo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tabs, Tab, Box } from '@mui/material';

import { TabPanel } from 'ui/components/TabPanel';
import { DetailsCard } from 'ui/components/Page/DetailsCard';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { customFieldsYupSchema } from 'ui/components/CustomFields/CustomFields';
import {
  getShip,
  Ship,
  updateShip,
  ShipStatus,
  finishShip,
  ShipPaymentStatus,
  ShipCarton,
} from 'services/shipping';
import {
  clearModuleNavigation,
  ModuleNavigationType,
  updateModuleNavigation,
} from 'services/moduleNavigation';
import { getCustomers } from 'services/customers';
import { getCarriers } from 'services/carriers';
import { ItemType } from 'services/items';
import { getSettingsShipping } from 'services/settings/shipping/redux';
import { getSettingsCompany } from 'services/settings/company';
import { FulfillServiceAndMiscItemsOnShip } from 'services/settings/shipping';
import {
  fetchDefaultReportPreset,
  printReport,
  ReportId,
  reportList,
} from 'services/reports';
import { Errors, validateYup } from 'services/forms/validation';
import { SalesOrderItemTypes } from 'services/salesOrders';
import {
  getShippingSettings,
  initialShippingIntegrationSettings,
  ShippingConnectionType,
  ShippingIntegrationSettings,
  submitQuickShip,
} from 'services/integrations/shipping';
import { PermissionType } from 'services/permissions';
import { colorPalette } from 'ui/theme';
import { ReportsModal } from 'ui/components/Modal/ReportsModal';
import { getShippingConnection } from 'services/integrations/shipping/redux';
import { PurchaseLabelResponse } from 'services/integrations/shipping/purchaseLabel';
import { showNotification } from 'services/api';
import { getActiveUser } from 'services/user';
import { deleteDocuments } from 'services/documents/api';

import { ShippingDetailsCardProps, ShippingTab } from './types';
import { useShippingDetailsCardStyle } from './styled';
import {
  createActionBarOptions,
  createShippingOptions,
  defaultShip,
  ShipAction,
  shipActions,
  ShipActions,
} from './consts';
import { ShippingDocumentsTab } from './components';
import { transformToShipWithSettings } from './transformations';
import { yupShipCartonsSchema, yupShippingGeneralSchema } from './validations';
import {
  PurchaseLabelProvider,
  PurchaseLabelWizard,
} from './components/PurchaseLabelWizard';
import { isQuickShipAndPurchaseLabelValid } from './helpers';
import { mergeLabelsAndReport } from './components/PurchaseLabelWizard/helpers';
import ReturnLabelsTab from './components/ReturnLabelsTab/ReturnLabelsTab';
import { getErrorMessage, useLongPolling } from 'helpers';
import { logErrorCtx } from 'app/logging';
import { LongPolling } from 'ui/components/Dialog';
import { useNavigate } from 'react-router-dom';

import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';
import FBOGeneralTab from './components/GeneralTab/FBOGeneralTab';
import FBOButtonAction from 'ui/components/Button/ButtonAction/FBOButtonAction';
import { concatRoute } from 'helpers/routes';
import { Routes } from 'ui/modules/sales/navigation';
import { AccordionHeader } from 'ui/components/Accordion/styled';

const ShippingDetailsCard: React.FunctionComponent<ShippingDetailsCardProps> = (
  props
) => {
  const { activeShipId, onClose, fetchSearchResult } = props;

  const classes = useShippingDetailsCardStyle(props);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const shippingSettings = useSelector(getSettingsShipping);
  const companySettings = useSelector(getSettingsCompany);

  const customers = useSelector(getCustomers).items;
  const carriers = useSelector(getCarriers).items;
  const { user } = useSelector(getActiveUser);

  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPurchaseLabelWizard, setShowPurchaseLabelWizard] = useState(false);
  const [activeShip, setActiveShip] = useState<Ship>(defaultShip);
  const [validationErrors, setValidationErrors] = useState<Errors>({});
  const [customFieldsErrors, setCustomFieldsErrors] = useState<Errors>({});
  const [rowErrors, setRowErrors] = useState<Errors[]>([]);
  const [showFulfillConfirmationModal, setShowFulFillConfirmationModal] =
    useState(false);
  const [showNotPaidConfirmationModal, setShowNotPaidConfirmationModal] =
    useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [activeReportModalId, setActiveReportModalId] = useState<ReportId>(
    ReportId.PackingList
  );
  const [activeReportParameters, setActiveReportParameters] = useState({});
  const [shippoSettings, setShippoSettings] =
    useState<ShippingIntegrationSettings>(initialShippingIntegrationSettings);
  const [isShippo, setIsShippo] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);

  const oldState = useRef<Ship | null>(defaultShip);

  const connection = useSelector(getShippingConnection);

  const { user: activeUser } = useSelector(getActiveUser);
  const activeUserId = activeUser ? activeUser.id! : 0;

  const { isPolling, notify, clearNotify, startPolling, stopPolling } =
    useLongPolling();

  const shouldShowShipButton = useMemo(() => {
    return activeShip.status !== ShipStatus.Shipped && !connection;
  }, [activeShip.status, connection]);

  const isPaid = useMemo(() => {
    const so = activeShip.salesOrder;
    if (!so) {
      return false;
    }

    return (
      !!so.grandTotal && !!so.paymentTotal && so.grandTotal <= so.paymentTotal
    );
  }, [activeShip.salesOrder]);

  const paidStatus = useMemo(() => {
    if (!shippingSettings.showPaidStamp) {
      return undefined;
    }

    return isPaid ? ShipPaymentStatus.Paid : ShipPaymentStatus.Unpaid;
  }, [shippingSettings.showPaidStamp, isPaid]);

  const shipStatus = useMemo(() => {
    return activeShip ? activeShip.status : null;
  }, [activeShip]);

  const returnLabelsTabVisible = useMemo(() => {
    let isVisible = false;
    if (activeShip) {
      if (activeShip.shipCartonList) {
        if (
          activeShip.shipCartonList.find(
            (c) => c.returnTracking !== null && c.returnTracking !== ''
          )
        ) {
          isVisible = true;
        }
      }
    }

    return isVisible;
  }, [activeShip]);

  const shouldShowFulFillModal = useMemo(() => {
    if (
      shippingSettings.fulfillServiceAndMiscItemsOnShip !==
        FulfillServiceAndMiscItemsOnShip.Confirm ||
      !activeShip.salesOrder
    ) {
      return false;
    }

    // only show confirmation modal if ship has miscsale,service, overhead or labor item
    return activeShip.salesOrder.salesOrderItemList.some((i) => {
      const itemType = _.get(i, 'saleItem.item.itemType');
      const salesOrderType = _.get(i, 'salesOrderItemType');
      return (
        itemType === ItemType.Service ||
        itemType === ItemType.Labor ||
        itemType === ItemType.Overhead ||
        salesOrderType === SalesOrderItemTypes.MiscSale ||
        salesOrderType === SalesOrderItemTypes.Note
      );
    });
  }, [
    shippingSettings.fulfillServiceAndMiscItemsOnShip,
    activeShip.salesOrder,
  ]);

  const shouldShowNotPaidModal = useMemo(
    () => shippingSettings.orderNotPaidWarning && !isPaid,
    [shippingSettings.orderNotPaidWarning, isPaid]
  );

  // purchase label is disabled if all cartons have tracking number
  const purchaseLabelDisabled = useMemo(
    () => activeShip.shipCartonList.every((c) => !!c.trackingNumber),
    // eslint-disable-next-line
    [activeShip.version]
  );

  useEffect(() => {
    const asyncFc = async (id: number) => {
      await fetchShip(id);
    };

    if (!activeShipId) {
      return;
    }

    asyncFc(activeShipId);
    setRowErrors([]);
    setActiveTab(0);
    // eslint-disable-next-line
  }, [activeShipId]);

  useEffect(() => {
    if (!activeShip.id || activeShip.id < 0) {
      dispatch(clearModuleNavigation(ModuleNavigationType.Sales));
      return;
    }

    if (activeShip.orderType === 'Sales Order') {
      dispatch(
        updateModuleNavigation(ModuleNavigationType.Sales, {
          salesOrderIds: activeShip.salesOrders.map((s) => s.id),
          pickIds: activeShip.picks.map((p) => p.id),
          shipIds: [activeShip.id],
        })
      );
    } else {
      dispatch(
        updateModuleNavigation(ModuleNavigationType.Purchase, {
          purchaseOrderId: activeShip.purchaseOrderId || undefined,
          pickIds: activeShip.picks.map((p) => p.id),
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeShip.id, activeShip.status]);

  useEffect(() => {
    if (!connection) {
      return;
    }

    setIsShippo(connection.typeId === ShippingConnectionType.Shippo);

    (async () => {
      try {
        const shippoSettings = await getShippingSettings(connection);
        setShippoSettings(shippoSettings);
      } catch (e) {
        const error = e as Error;
        logErrorCtx('Error while getting shipping setting', {
          error,
          stackTrace: error.stack,
          title: error.message,
          description:
            'Error while getting shipping setting for shippo configuration',
          component: 'ShippingDetailsCard',
        });
      }
    })();
  }, [connection]);

  const fetchShip = useCallback(
    async (id: number, skipSpinner = false) => {
      !skipSpinner && setIsLoading(true);
      let newShip;
      try {
        const ship = await getShip(id);
        newShip = transformToShipWithSettings(ship, companySettings);
      } catch (e) {
        const message = getErrorMessage(e);
        showNotification(`${message} - shipping couldn't be loaded.`, {
          variant: 'error',
        });
        !skipSpinner && setIsLoading(false);
        onClose();
        return;
      }

      if (newShip?.id) {
        oldState.current = newShip;
        setActiveShip(newShip);
      }
      !skipSpinner && setIsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companySettings]
  );

  const handleSaveClicked = useCallback(
    async (skipSpinner: boolean = false) => {
      oldState.current = activeShip;

      const isValid = validateYup(
        activeShip,
        yupShippingGeneralSchema,
        setValidationErrors
      );

      const isCustomFieldsValid = validateYup(
        activeShip.customFields,
        customFieldsYupSchema,
        setCustomFieldsErrors
      );

      if (!isValid || !isCustomFieldsValid) {
        return false;
      }

      !skipSpinner && setIsLoading(true);

      const notDeletedDocuments = activeShip.documents.filter(
        (d) => !d.deleted
      );
      const deletedDocuments = activeShip.documents.filter((d) => d.deleted);
      const resolvedShip = {
        ...activeShip,
        documents: notDeletedDocuments,
      };

      try {
        await deleteDocuments(deletedDocuments);
      } catch (e) {
        const error = e as Error;
        logErrorCtx('Error while deleting documents', {
          error,
          stackTrace: error.stack,
          title: error.message,
          description: 'Unable to delete documents in shipping details card',
          component: 'ShippingDetailsCard',
        });
      }

      try {
        const resShip = await updateShip(resolvedShip);
        oldState.current = resShip;
        setActiveShip(resShip);
        await fetchSearchResult();
      } catch {
        setIsLoading(false);
        return false;
      }

      setIsLoading(false);
      setRowErrors([]);
      return true;
    },
    [activeShip, fetchSearchResult]
  );

  const printPackingListReport = async (shipId: number) => {
    const defaultPreset = await fetchDefaultReportPreset(
      activeUserId,
      ReportId.PackingList
    );

    const reportParameters = defaultPreset
      ? defaultPreset.parameters
      : reportList.find((r) => r.reportId === ReportId.PackingList)!.parameters;

    await printReport(ReportId.PackingList, { ...reportParameters, shipId });
  };

  const handleChangeStatus = useCallback(
    (action: ShipActions) => async () => {
      const isShip = action === ShipActions.finish;
      // fulfill confimation
      if (isShip && shouldShowFulFillModal) {
        setShowFulFillConfirmationModal(true);
        return;
      }

      // notpaid confirmation
      if (isShip && shouldShowNotPaidModal) {
        setShowNotPaidConfirmationModal(true);
        return;
      }

      isShip ? startPolling() : setIsLoading(true);
      await handleSaveClicked(isShip);

      const sendConfirm =
        action === ShipActions.finish &&
        shippingSettings.fulfillServiceAndMiscItemsOnShip ===
          FulfillServiceAndMiscItemsOnShip.Confirm;

      try {
        const updateAction = shipActions[action];
        const newShip = await updateAction(
          activeShip.id || 0,
          sendConfirm || undefined
        );
        oldState.current = {
          ...activeShip,
          version: newShip.version,
          status: newShip.status,
        };
        setActiveShip({
          ...activeShip,
          version: newShip.version,
          status: newShip.status,
        });
        fetchShip(activeShip.id!, isShip);
        await fetchSearchResult();
      } catch {
        isShip ? stopPolling() : setIsLoading(false);
        return;
      }

      // if finish or pack and should print packing list
      if (
        (action === ShipActions.finish &&
          shippingSettings.printPackingListOnShip) ||
        (action === ShipActions.pack && shippingSettings.printPackingListOnPack)
      ) {
        await printPackingListReport(activeShipId!);
      }
      isShip ? stopPolling() : setIsLoading(false);
    },
    [
      activeShip,
      activeShipId,
      shouldShowFulFillModal,
      shouldShowNotPaidModal,
      shippingSettings,
      oldState,
      fetchSearchResult,
      handleSaveClicked,
      fetchShip,
    ]
  );

  const handleActiveTabChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      setActiveTab(newValue);
    },
    []
  );

  const validateShipCartonList = useCallback((cartons: ShipCarton[]) => {
    const newErrors: Errors[] = [];
    const itemsAreValid = cartons.map((item, index) => {
      return validateYup(item, yupShipCartonsSchema, (error: Errors) => {
        newErrors[index] = error;
      });
    });
    setRowErrors(newErrors);

    return itemsAreValid.some((valid) => !valid);
  }, []);

  const isPurchaseLabelWizardVisible = useCallback(async () => {
    const isPurchaseLabelValid = isQuickShipAndPurchaseLabelValid(
      activeShip,
      shippoSettings,
      validateShipCartonList,
      carriers,
      false,
      isShippo
    );

    if (!isPurchaseLabelValid) {
      return;
    }

    try {
      setIsLoading(true);
      const resShip = await updateShip(activeShip);
      oldState.current = resShip;
      setActiveShip(resShip);
      setShowPurchaseLabelWizard(true);
    } catch (e) {
      const error = e as Error;
      logErrorCtx('Error while updating shipping', {
        error,
        stackTrace: error.stack,
        title: error.message,
        description:
          'Error when updating shipping information in shipping details card',
        component: 'ShippingDetailsCard',
      });
    }

    setIsLoading(false);
  }, [activeShip, validateShipCartonList, shippoSettings, carriers, isShippo]);

  const handleQuickShipClicked = useCallback(async () => {
    const isQuickShipValid = isQuickShipAndPurchaseLabelValid(
      activeShip,
      shippoSettings,
      validateShipCartonList,
      carriers,
      true,
      isShippo
    );

    if (!isQuickShipValid) {
      return;
    }

    if (connection) {
      setIsLoading(true);

      let labels: PurchaseLabelResponse[] = [];

      try {
        const resShip = await updateShip(activeShip);
        oldState.current = resShip;
        setActiveShip(resShip);
        labels = await submitQuickShip(connection, activeShip, shippoSettings);
        fetchShip(activeShip.id!);
      } catch (e) {
        setIsLoading(false);
        const error = e as Error;
        logErrorCtx('Error while quick shipping', {
          error,
          stackTrace: error.stack,
          title: error.message,
          description: 'Error while performing a quick ship in shipping detail',
          component: 'ShippingDetailCard',
        });
        return;
      }

      if (labels.length) {
        try {
          await mergeLabelsAndReport(labels, activeShip.id!, user!.id!);
        } catch (e) {
          showNotification(
            'Something went wrong while generating labels. Please contact support.',
            { variant: 'error' }
          );
          const error = e as Error;
          logErrorCtx('Error while mergin labels and report', {
            error,
            stackTrace: error.stack,
            title: error.message,
            description:
              'Error while generating labels in shipping detail card',
            component: 'ShippingDetailsCard',
          });
        }
      }

      setIsLoading(false);
      fetchSearchResult();
    }
  }, [
    connection,
    activeShip,
    fetchShip,
    shippoSettings,
    user,
    validateShipCartonList,
    fetchSearchResult,
    carriers,
    isShippo,
  ]);

  const handleShippButtonAction = useCallback(
    (action: ShipAction) => {
      switch (action) {
        case ShipAction.PurchaseLabel:
          isPurchaseLabelWizardVisible();
          break;
        case ShipAction.FulFill:
          handleChangeStatus(ShipActions.finish)();
          break;
        case ShipAction.QuickShip:
          handleQuickShipClicked();
          break;
      }
    },
    [handleChangeStatus, isPurchaseLabelWizardVisible, handleQuickShipClicked]
  );

  const handleReportModalClick = useCallback(
    (reportId: ReportId) => {
      if (
        reportId === ReportId.PackingList ||
        reportId === ReportId.ShippingInvoice
      ) {
        setActiveReportParameters({ shipId: activeShip.id });
      }
      setShowReportModal(true);
      setActiveReportModalId(reportId);
    },
    [activeShip.id]
  );

  const handleFulFillConfirmClicked = useCallback(async () => {
    setShowFulFillConfirmationModal(false);

    // notpaid confirmation
    if (shouldShowNotPaidModal) {
      setShowNotPaidConfirmationModal(true);
      return;
    }

    setIsLoading(true);
    try {
      const newShip = await finishShip(activeShip.id || 0, true);
      oldState.current = {
        ...activeShip,
        version: newShip.version,
        status: newShip.status,
      };
      setActiveShip({
        ...activeShip,
        version: newShip.version,
        status: newShip.status,
      });
      await fetchSearchResult();
    } catch {
      setIsLoading(false);
      return;
    }

    // print packing list
    if (shippingSettings.printPackingListOnShip) {
      await printPackingListReport(activeShipId!);
    }

    setIsLoading(false);
  }, [
    activeShip,
    activeShipId,
    shouldShowNotPaidModal,
    shippingSettings.printPackingListOnShip,
    oldState,
    fetchSearchResult,
  ]);

  const handleNotPaidConfirmClicked = useCallback(async () => {
    setShowNotPaidConfirmationModal(false);

    setIsLoading(true);
    try {
      const newShip = await finishShip(activeShip.id || 0, true);
      oldState.current = newShip;
      setActiveShip({
        ...activeShip,
        version: newShip.version,
        status: newShip.status,
      });
      await fetchSearchResult();
    } catch {
      setIsLoading(false);
      return;
    }

    // print packing list
    if (shippingSettings.printPackingListOnShip) {
      await printPackingListReport(activeShipId!);
    }

    setIsLoading(false);
  }, [
    activeShip,
    activeShipId,
    shippingSettings.printPackingListOnShip,
    oldState,
    fetchSearchResult,
  ]);

  const handleClosePurchaseLabelWizard = useCallback(
    (refetchShip: boolean) => {
      setShowPurchaseLabelWizard(false);
      if (refetchShip) {
        fetchShip(activeShip.id!);
      }
    },
    [fetchShip, activeShip.id]
  );

  const closeLongPollingDialog = () => {
    clearNotify();
    navigate(concatRoute(Routes.SalesRouter, Routes.ShippingPage), {
      replace: true,
    });
  };

  const toggleAccordion = () => setIsExpanded((prev) => !prev);
  return (
    <>
      <DetailsCard
        isLoading={isLoading}
        isPolling={isPolling}
        onSubmit={handleSaveClicked}
        state={activeShip}
        oldState={oldState}
      >
        <FBOTitleBar
          title={activeShip.number}
          status={activeShip.status || undefined}
          statusCustom={paidStatus}
          statusCustomStyle={
            paidStatus === ShipPaymentStatus.Paid
              ? classes.customStatusPaid
              : classes.customStatusUnpaid
          }
        >
          {shipStatus === ShipStatus.Open && (
            <FBOButton
              sx={{ marginRight: '8px' }}
              variant="secondary"
              color="positive"
              size="medium"
              data-qa="shipping-pack"
              onClick={handleChangeStatus(ShipActions.pack)}
              permissions={[PermissionType.ShippingPack]}
            >
              Pack
            </FBOButton>
          )}
          {shipStatus === ShipStatus.Packed && (
            <FBOButton
              sx={{ marginRight: '8px' }}
              variant="secondary"
              color="positive"
              size="medium"
              data-qa="shipping-unpack"
              onClick={handleChangeStatus(ShipActions.unpack)}
              permissions={[PermissionType.ShippingPack]}
            >
              Unpack
            </FBOButton>
          )}

          {shouldShowShipButton && (
            <FBOButton
              sx={{ marginRight: '8px' }}
              variant="secondary"
              color="positive"
              size="medium"
              data-qa="shipping-ship"
              permissions={[PermissionType.ShippingShip]}
              onClick={handleChangeStatus(ShipActions.finish)}
            >
              Ship
            </FBOButton>
          )}
          {connection && activeShip.status !== ShipStatus.Shipped && (
            <FBOButtonAction
              actionItems={createShippingOptions(
                handleShippButtonAction,
                activeShip.status,
                purchaseLabelDisabled,
                purchaseLabelDisabled
              )}
              text="Ship"
              permissions={[PermissionType.ShippingShip]}
            />
          )}
          {shipStatus === ShipStatus.Shipped && (
            <FBOButton
              sx={{ marginRight: '8px' }}
              variant="secondary"
              color="negative"
              size="medium"
              data-qa="shipping-void"
              permissions={[PermissionType.ShippingEdit]}
              onClick={handleChangeStatus(ShipActions.void)}
            >
              Void
            </FBOButton>
          )}
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="secondary"
            color="positive"
            size="medium"
            onClick={() => handleSaveClicked()}
            permissions={[PermissionType.ShippingEdit]}
            data-qa="shipping-save"
          >
            Save
          </FBOButton>
          <FBOMenuButton
            style={{
              marginRight: '8px',
              color: colorPalette.redesign.contentSecondary,
            }}
            variant="tertiary"
            data-qa="shipping-item-three-dot-menu"
            items={createActionBarOptions(activeShipId, handleReportModalClick)}
          />
          <FBOButton
            variant="tertiary"
            color="neutral"
            size="medium"
            icon="FBOClose"
            data-qa="shipping-close"
            onClick={onClose}
          />
        </FBOTitleBar>

        {activeTab !== 0 ? (
          <Tabs
            value={activeTab}
            onChange={handleActiveTabChange}
            indicatorColor="primary"
            className="redesign"
          >
            <Tab label="General" value={ShippingTab.General} />
            {returnLabelsTabVisible && (
              <Tab label="Return Labels" value={ShippingTab.ReturnLabels} />
            )}
            <Tab label="Documents" value={ShippingTab.Documents} />
          </Tabs>
        ) : (
          <AccordionHeader onClick={toggleAccordion}>
            <Box style={{ flexGrow: 1 }}>
              <Tabs
                value={activeTab}
                onChange={handleActiveTabChange}
                indicatorColor="primary"
                className="redesign"
                sx={{
                  border: 'none !important',
                }}
              >
                <Tab label="General" value={ShippingTab.General} />
                {returnLabelsTabVisible && (
                  <Tab label="Return Labels" value={ShippingTab.ReturnLabels} />
                )}
                <Tab label="Documents" value={ShippingTab.Documents} />
              </Tabs>
            </Box>

            <FBOButton
              variant="tertiary"
              color="neutral"
              size="large"
              icon={isExpanded ? 'ChevronUp' : 'ChevronDown'}
              onClick={(e) => {
                e.stopPropagation();
                toggleAccordion();
              }}
              data-qa="picking-expand-toggle-button"
              sx={{ fontSize: '32px' }}
            />
          </AccordionHeader>
        )}
        <TabPanel
          value={activeTab}
          index={ShippingTab.General}
          flexGrow
          noSpacing
          style={{ flexDirection: 'column' }}
        >
          <FBOGeneralTab
            validationErrors={validationErrors}
            customers={customers}
            carriers={carriers}
            activeShip={activeShip}
            setActiveShip={setActiveShip}
            fetchSearchResult={fetchSearchResult}
            customFieldsErrors={customFieldsErrors}
            oldState={oldState}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            rowErrors={rowErrors}
            fetchActiveShip={fetchShip}
            updateShip={handleSaveClicked}
            expand={isExpanded}
          />
        </TabPanel>

        <TabPanel value={activeTab} index={ShippingTab.ReturnLabels} noSpacing>
          <ReturnLabelsTab activeShip={activeShip} />
        </TabPanel>
        <TabPanel value={activeTab} index={ShippingTab.Documents} noSpacing>
          <ShippingDocumentsTab
            activeShip={activeShip}
            setActiveShip={setActiveShip}
          />
        </TabPanel>
        <LongPolling
          open={notify}
          onClose={closeLongPollingDialog}
          title="Ship processing"
          content="Your shipment is processing. Check back later."
          dataQa="ship-processing-dialog"
        />
      </DetailsCard>
      {shippingSettings.fulfillServiceAndMiscItemsOnShip ===
        FulfillServiceAndMiscItemsOnShip.Confirm && (
        <ConfirmationModal
          open={showFulfillConfirmationModal}
          title="Ship"
          body="Do you want to fulfill service, miscellaneous and note items?"
          onCancelClicked={() => setShowFulFillConfirmationModal(false)}
          onConfirmClicked={handleFulFillConfirmClicked}
          confirmLabel="Ship"
          confirmButtonRed
        />
      )}

      <PurchaseLabelProvider ship={activeShip}>
        <PurchaseLabelWizard
          visible={showPurchaseLabelWizard}
          onClose={handleClosePurchaseLabelWizard}
          ship={activeShip}
        />
      </PurchaseLabelProvider>

      {shippingSettings.orderNotPaidWarning && (
        <ConfirmationModal
          open={showNotPaidConfirmationModal}
          title="Ship"
          body="This shipment has not yet been paid for. Are you sure you want to ship?"
          onCancelClicked={() => setShowNotPaidConfirmationModal(false)}
          onConfirmClicked={handleNotPaidConfirmClicked}
          confirmLabel="Ship"
          confirmButtonRed
        />
      )}
      <ReportsModal
        isOpen={showReportModal}
        reportId={activeReportModalId}
        params={activeReportParameters}
        onClose={() => setShowReportModal(false)}
        autoGenerate
      />
    </>
  );
};

export default memo(ShippingDetailsCard);
