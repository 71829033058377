import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';

import { colorPalette } from 'ui/theme';

const LoadingArrowButton = styled(LoadingButton)({
  fontSize: '16px !important',
  fontWeight: '600 !important',
  color: '#FFF !important',
  textTransform: 'capitalize',
  '&:hover, &:focus': {
    backgroundColor: colorPalette.redesign.hoverButtonBackground,
  },
  '&:disabled, &:active': {
    backgroundColor: colorPalette.redesign.disabledButtonBackground,
  },
  '& .MuiLoadingButton-loadingIndicator': {
    position: 'inherit',
  },
  '& .MuiLoadingButton-loadingIndicatorStart': {
    color: '#FFF',
    marginLeft: '-24px',
    paddingRight: '24px',
  },
  border: 'none',
  font: 'inherit',
  cursor: 'pointer',
  paddingLeft: '25px',
  paddingRight: '35px',
  height: '50px',
  width: '100%',
  backgroundColor: colorPalette.redesign.fishbowlBlue,
  boxShadow: 'none !important',
  clipPath: `padding-box polygon(
    calc(100% - 25px) 0,
    100% 51%,
    calc(100% - 25px) 100%,
    0% 100%,
    calc(0% + 15px) 50%,
    0% 0%
  )`,
});

export default LoadingArrowButton;
