import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useZonedDate } from 'helpers';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import GetAppIcon from '@mui/icons-material/GetApp';
import { downloadFile } from 'services/backgroundTasks';
import { getSettingsCompany } from 'services/settings/company';
import { useDownloadsPageStyle } from '../styled';
import { DownloadItemCmp, DownloadItemProps } from '../types';

export const DownloadCompletedItem: DownloadItemCmp = (
  props: DownloadItemProps
) => {
  const { item } = props;
  const classes = useDownloadsPageStyle();
  const selector = useSelector(getSettingsCompany);
  const timezone = selector.timezone as string;
  const zonedData = item.dateCreated ? new Date(item.dateCreated) : new Date();
  const dateCreated = useZonedDate(zonedData, timezone);

  const downloadClicked = useCallback(async () => {
    if (!item.fileName) {
      return;
    }
    await downloadFile(item.fileName);
  }, [item.fileName]);

  return (
    <Box key={item.id} className={classes.card}>
      <Box className={classes.cardRow} onClick={downloadClicked}>
        <Box className={classes.cardContent}>
          <Typography variant="body1" color="textPrimary">
            <b>{item.fileName}</b>
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {item.type} • {dateCreated}
          </Typography>
        </Box>
        <GetAppIcon className={classes.icon} />
      </Box>
    </Box>
  );
};
