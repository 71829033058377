import React, { memo } from 'react';
import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { INVOICE_COLUMNS } from '../../consts';
import { InvoiceSearchResultsProps } from './types';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import InvoiceSearchResultsCustomRow from './InvoiceSearchResultsCustomRow';

const InvoicesSearchResults: React.FC<InvoiceSearchResultsProps> = (props) => {
  const {
    activeInvoiceId,
    handleInvoiceClick,
    invoices,
    isLoadingInvoices,
    onPaginationChange,
    pagination,
  } = props;

  const resolvedColumns = activeInvoiceId
    ? INVOICE_COLUMNS.filter(
        (c) => c.title === 'Number' || c.title === 'Customer'
      )
    : INVOICE_COLUMNS;

  return (
    <>
      <FBOTitleBar title="Invoices" />

      <ItemsTable
        data={invoices}
        columns={resolvedColumns}
        activeId={activeInvoiceId}
        RenderCustomRow={InvoiceSearchResultsCustomRow}
        onItemClick={handleInvoiceClick}
        selectableItems={false}
        isLoadingData={isLoadingInvoices}
        pagination={pagination}
        onPaginationChange={onPaginationChange}
        meta={{ activeInvoiceId }}
        showEnhancedTablePaginationActions={!activeInvoiceId}
        dataQa="invoices-table"
        emptyTableText="No Invoices Found"
      />
    </>
  );
};
export default memo(InvoicesSearchResults);
