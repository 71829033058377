import { Pagination } from 'services/search';
import { PurchaseOrder } from 'services/purchaseOrders';
import { Dates } from 'helpers';

export interface PurchaseOrderSearchResultsProps {
  purchaseOrders: PurchaseOrder[];
  pagination: Pagination;
  onPaginationChange: (pagination: Pagination) => void;
  activePurchaseOrderId: null | number;
  handlePurchaseOrderClick: (id: number) => void;
  onAction: (type: PurchaseOrderTableRowActions, value: any) => void;
  onAddNewPress(): void;
  isLoadingPurchaseOrders: boolean;
  selectedItems: number[];
  setSelectedItems: React.Dispatch<React.SetStateAction<number[]>>;
  onUnissueClicked: () => void;
  onIssueClicked: () => void;
  onDeleteClicked: () => void;
  onPageAction: (type: PurchaseOrderPageAction, date?: Dates) => void;
  selectedPurchaseOrders: PurchaseOrder[];
  activeDate: Dates;
  onAutoOrderClicked: () => void;
}

export enum PurchaseOrderTableRowActions {
  Duplicate,
  Delete,
}

export enum PurchaseOrderPageAction {
  Export,
  Import,
  CustomFields,
  ShowReport,
  ChangeDate,
}
