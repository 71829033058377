import React, { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import { TextField } from 'ui/components/TextField/TextField';
import _ from 'lodash';

import { Modal } from 'ui/components/Modal/Modal';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { Errors, validateYup } from 'services/forms/validation';
import { useHandleTextFieldChange } from 'services/forms';
import {
  ReceiptItem,
  RejectionResponsibilityType,
  rejectItem,
} from 'services/receiving';
import { getUoms, EACH_UOM_ID } from 'services/uoms';

import { ReceivingRejectModalProps } from './types';
import { yupReceivingRejectSchema } from '../../validations';
import { TextFieldQuantity } from 'ui/components/TextField/TextFieldQuantity';

const ReceivingRejectModal: React.FC<ReceivingRejectModalProps> = (props) => {
  const {
    activeReceiptItem,
    activeReceiptId,
    setActiveReceiptItem,
    setActiveReceipt,
    show,
    onClose,
  } = props;

  const { items: uoms } = useSelector(getUoms);

  const [validationErrors, setValidationErrors] = useState<Errors>({});
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  const handleAmountTextChange = useCallback(
    (value: number | null) => {
      setActiveReceiptItem((old) => ({ ...old, quantity: value }));
    },
    [setActiveReceiptItem]
  );

  const handleAmountMenuChange = useCallback(
    (nextUomId: number) => {
      const selectedUom = uoms.find((u) => u.id === nextUomId)!;
      setActiveReceiptItem((old) => ({
        ...old,
        uomId: nextUomId,
        uom: selectedUom,
      }));
    },
    [setActiveReceiptItem, uoms]
  );

  const handleAutocompleteChange = useCallback(
    (e: React.ChangeEvent<{}>, v: RejectionResponsibilityType | null) => {
      setActiveReceiptItem((old) => ({
        ...old,
        rejectionResponsibility: v,
      }));
    },
    [setActiveReceiptItem]
  );

  const saveClicked = useCallback(async () => {
    const isValid = validateYup(
      activeReceiptItem,
      yupReceivingRejectSchema,
      setValidationErrors
    );

    if (!isValid) {
      return;
    }

    setIsLoadingSave(true);

    try {
      const newReceipt = await rejectItem(activeReceiptId!, activeReceiptItem);
      setActiveReceipt(newReceipt);
      onClose();
    } catch {
      // continue regardless of error
    }
    setIsLoadingSave(false);
  }, [activeReceiptItem, onClose, activeReceiptId, setActiveReceipt]);

  const handleTextFieldChange = useHandleTextFieldChange<ReceiptItem>(
    setActiveReceiptItem,
    activeReceiptItem
  );

  return (
    <Modal
      maxWidth="xs"
      open={show}
      onCancelClicked={onClose}
      onApplyClicked={saveClicked}
      title={_.get(activeReceiptItem, 'item.name', '')}
      applyLabel="save"
      withoutDefaultPadding
      isLoading={isLoadingSave}
    >
      <Box p={3} pb={2}>
        <Grid container spacing={2}>
          <Grid item container spacing={2}>
            <Grid item xs={4}>
              <TextFieldQuantity
                label="Reject Amt."
                name="quantity"
                placeholder="Quantity"
                value={activeReceiptItem.quantity}
                uoms={uoms}
                selectedUomId={activeReceiptItem.uomId || EACH_UOM_ID}
                onTextChange={handleAmountTextChange}
                onMenuChange={handleAmountMenuChange}
                error={!!validationErrors.quantity}
                required
              />
            </Grid>
            <Grid item xs={8}>
              <Autocomplete
                label="Responsible"
                value={activeReceiptItem.rejectionResponsibility}
                onChange={handleAutocompleteChange}
                options={_.values(RejectionResponsibilityType)}
                error={!!validationErrors.rejectionResponsibility}
                required
              />
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  className="redesign"
                  label="Reason"
                  name="reasonForRejection"
                  value={activeReceiptItem.reasonForRejection}
                  onChange={handleTextFieldChange}
                  placeholder="Reason"
                  error={!!validationErrors.reasonForRejection}
                  required
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
export default memo(ReceivingRejectModal);
