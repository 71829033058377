import { DUE_ON_RECEIPT_ID } from 'services/paymentTerms';

import { Customer, CustomerCarrierAccount, CustomerItem } from './types';

export const initialCustomer: Customer = {
  id: null,
  accountingClassId: null,
  addresses: [],
  alertNotes: null,
  bcc: null,
  billWithParent: null,
  cc: null,
  currencyId: 1,
  currency: null,
  customExchangeRate: null,
  customFields: [],
  carrierAccountSettings: [],
  customerSaleItems: [],
  dateCreated: null,
  dateLastModified: null,
  defaultCarrierId: null,
  defaultCarrierServiceId: null,
  defaultOrderPriorityId: null,
  deleted: false,
  hasSalesOrders: false,
  documents: [],
  email: null,
  fax: null,
  level: null,
  mobile: null,
  name: null,
  notes: null,
  other: null,
  parentCustomerId: null,
  paymentTermId: DUE_ON_RECEIPT_ID,
  office: null,
  representative: [],
  representativeId: null,
  tags: [],
  taxExemptNumber: null,
  taxExempt: false,
  url: null,
  version: null,
  taxId: null,
  tax: null,
};

export const initialCustomerCarrierAccount: CustomerCarrierAccount = {
  id: null,
  version: null,
  customerId: null,
  defaultFlag: false,
  carrier: null,
  accountNumber: null,
  billingType: null,
  billingZip: null,
  billingCountry: null,
  deleted: false,
  name: null,
};

export const initialCustomerItem: CustomerItem = {
  id: null,
  customer: null,
  customerId: null,
  dateCreated: null,
  dateLastModified: null,
  deleted: false,
  multicurrencyCustomerItemPrice: null,
  name: null,
  price: null,
  saleItem: null,
  saleItemId: null,
  version: null,
};
