import React from 'react';
import { Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { theme } from 'ui/theme';

import { CycleCountsProps } from './types';
import { useCycleCountsStyle } from './styled';

export const CycleCounts: React.FC<CycleCountsProps> = ({
  requiredQty,
  actualQty,
  errorLabel,
}) => {
  const classes = useCycleCountsStyle();

  const showError = requiredQty != actualQty;

  return (
    <>
      {(showError || errorLabel) && (
        <Typography
          className={classes.errorMessage}
          color={theme.palette.error.main}
          data-qa={'error-cycle-text'}
        >
          <Typography
            className={classes.errorIcon}
            data-qa={'selected-rows-error-text'}
          >
            <ErrorOutlineIcon
              sx={{
                width: '18px',
                height: '18px',
                marginLeft: '10px',
                marginRight: '2px',
              }}
              data-qa={'selected-rows-error-icon'}
            />
            {errorLabel ||
              (requiredQty > actualQty
                ? 'Input correct quantities to cycle.'
                : 'Remove excess tracking rows before cycling.')}
          </Typography>
        </Typography>
      )}
      <Typography
        color={
          showError ? theme.palette.error.main : theme.palette.primary.main
        }
        variant="body1"
        paddingTop={showError || errorLabel ? '5px' : '34.5px'}
        paddingLeft="20px"
        data-qa={'cycle-qty-text'}
      >
        {`${actualQty} / ${requiredQty} Cycle Quantity`}
      </Typography>
    </>
  );
};
