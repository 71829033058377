import { createApiCall } from 'services/api';

import {
  transformGlobalSettings,
  transformToGlobalSettingsPut,
} from './transformations';
import { GlobalSettings } from './types';

export const getGlobalSettings = async () => {
  const globalSettingsResponse = await createApiCall({
    hostname: `${process.env.REACT_APP_COMMERCE_URL}`,
    path: `/commerce/v1/settings/global`,
    method: `GET`,
  })();

  return transformGlobalSettings(globalSettingsResponse.data);
};

export const globalSettingsPut = async (globalSettings: GlobalSettings) => {
  const globalSettingsResponse = await createApiCall(
    {
      hostname: `${process.env.REACT_APP_COMMERCE_URL}`,
      path: `/commerce/v1/settings/global`,
      method: `PUT`,
      body: transformToGlobalSettingsPut(globalSettings),
    },
    {
      getMessage: {
        success: () => 'Set global commerce settings',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();
  return transformGlobalSettings(globalSettingsResponse.data);
};
