import { chainReducers, createApiReducer } from 'services/api';

import { SettingsPickingActionTypes } from './actions';
import { transformPickOrderSettings } from '../transformations';

const fetchSettingsPickingReducer = createApiReducer(
  [
    SettingsPickingActionTypes.FETCH_PICKING_START,
    SettingsPickingActionTypes.FETCH_PICKING_SUCCESS,
    SettingsPickingActionTypes.FETCH_PICKING_FAILURE,
  ],
  (data) => transformPickOrderSettings(data),
  (data) => data
);

const settingsPickingChainedReducers = chainReducers(
  {},
  fetchSettingsPickingReducer
);

export default settingsPickingChainedReducers;
