import React, { memo, useCallback, useMemo } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import {
  ItemsTable,
  useSelectedItemsChanges,
} from 'ui/components/Table/ItemsTable';
import { useCurrencyFormatter, toMulticurrencyCalculate } from 'helpers';
import { ReconcileCalculateOptions } from 'services/receiving';
import { getSettingsCompany } from 'services/settings/company';

import ReconcileWizardReconcileItemsRow from './ReconcileWizardReconcileItemsRow';
import { ReconcileWizardReconcileItemsProps } from './types';
import { reconcileWizardReconcileItemsColumns } from './consts';
import ReconcileWizardReconcileItemsFooter from './ReconcileWizardReconcileItemsFooter';
import { useReconcileWizardReconcileItemsStyle } from './styled';
import { MultiCurrencyWrapper } from 'ui/components/MultiCurrencyWrapper/MultiCurrencyWrapper';
import { colorPalette } from 'ui/theme';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';

const ReconcileWizardReconcileItems: React.FC<
  ReconcileWizardReconcileItemsProps
> = (props) => {
  const {
    itemsToReconcile,
    amountToReconcile,
    reconciledAmount,
    error,
    reconcile,
    setReconcile,
  } = props;

  const classes = useReconcileWizardReconcileItemsStyle();

  const { homeCurrency, useMultiCurrency } = useSelector(getSettingsCompany);

  const currencyFormatter = useCurrencyFormatter();
  const homeCurrencyCode = (homeCurrency && homeCurrency.code) || 'USD';
  const activeMulticurrencyCode = _.get(reconcile, 'currency.code', null);

  const showMultiCurrency = useMemo(
    () =>
      useMultiCurrency &&
      activeMulticurrencyCode &&
      activeMulticurrencyCode !== homeCurrencyCode,
    [activeMulticurrencyCode, useMultiCurrency]
  );

  const exchangeRate = _.get(reconcile, 'currency.exchangeRate', 1);

  const reconcileAmountDiff = useMemo(
    () => amountToReconcile - reconciledAmount,
    [amountToReconcile, reconciledAmount]
  );

  const multicurrencyReconcileAmounts = useMemo(() => {
    const multiCurrencyAmountToReconcile = currencyFormatter(
      toMulticurrencyCalculate(amountToReconcile, exchangeRate),
      activeMulticurrencyCode
    );

    const multiCurrencyreconciledAmount = currencyFormatter(
      toMulticurrencyCalculate(reconciledAmount, exchangeRate),
      activeMulticurrencyCode
    );

    const multiCurrencyreconcileAmountDiff = currencyFormatter(
      toMulticurrencyCalculate(reconcileAmountDiff, exchangeRate),
      activeMulticurrencyCode
    );

    return {
      multiCurrencyAmountToReconcile,
      multiCurrencyreconciledAmount,
      multiCurrencyreconcileAmountDiff,
    };
  }, [
    amountToReconcile,
    reconciledAmount,
    reconcileAmountDiff,
    exchangeRate,
    activeMulticurrencyCode,
    currencyFormatter,
  ]);

  const grandTotalCost = useMemo(() => {
    const selectedItems = reconcile.itemsToReconcile.filter((i) =>
      reconcile.selectedReconcileItems.includes(i.id!)
    );

    let grandTotal = 0;
    selectedItems.forEach((i) => {
      grandTotal +=
        (i.totalCost || 0) + (reconcile.reconcilePrices[i.id!] || 0);
    });

    return grandTotal;
  }, [
    reconcile.itemsToReconcile,
    reconcile.reconcilePrices,
    reconcile.selectedReconcileItems,
  ]);

  const handleCalculationChange = useCallback(
    (_e: any, calculation: ReconcileCalculateOptions) => {
      setReconcile((old) => ({ ...old, calculation }));
    },
    [setReconcile]
  );

  const handleTableAction = useCallback(
    (action: any) => {
      setReconcile((old) => ({
        ...old,
        reconcilePrices: {
          ...old.reconcilePrices,
          [action.id]: action.value,
        },
      }));
    },
    [setReconcile]
  );

  const setSelectedReconcileItems = useCallback(
    (selected: React.SetStateAction<number[]>) => {
      if (typeof selected === 'function') {
        setReconcile((r) => ({
          ...r,
          selectedReconcileItems: selected(r.selectedReconcileItems),
        }));
        return;
      }

      setReconcile((r) => ({
        ...r,
        selectedReconcileItems: selected,
      }));
    },
    [setReconcile]
  );

  const handleSelectedChange = useSelectedItemsChanges(
    reconcile.selectedReconcileItems,
    setSelectedReconcileItems
  );

  return (
    <>
      <Box p={3} overflow="hidden" flexShrink={0}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Box display="flex" alignItems="center" height="100%">
              <Grid container spacing={2}>
                <Grid item>
                  <Typography>Amount To Reconcile:</Typography>
                  <Typography align="right">Reconciled:</Typography>
                </Grid>
                <Grid item>
                  <Typography align="right">
                    <Box fontWeight="fontWeightBold">
                      {reconcile.calculation ===
                      ReconcileCalculateOptions.DoNotCalculate
                        ? '-'
                        : currencyFormatter(amountToReconcile)}
                    </Box>
                  </Typography>
                  <Typography align="right">
                    <Box fontWeight="fontWeightBold">
                      {reconcile.calculation ===
                      ReconcileCalculateOptions.DoNotCalculate
                        ? '-'
                        : currencyFormatter(reconciledAmount)}
                    </Box>
                  </Typography>
                  {reconcile.calculation !==
                    ReconcileCalculateOptions.DoNotCalculate && (
                    <Typography
                      align="right"
                      className={
                        reconcileAmountDiff === 0
                          ? classes.successColor
                          : classes.errorColor
                      }
                    >
                      <Box fontWeight="fontWeightBold">
                        {currencyFormatter(reconcileAmountDiff)}
                      </Box>
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
            <Grid item xs={4}>
              <Box display="flex" alignItems="center" height="100%">
                <Grid container spacing={2}>
                  <Grid item>
                    <Typography>Amount To Reconcile:</Typography>
                    <Typography align="right">Reconciled:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography align="right">
                      <Box fontWeight="fontWeightBold">
                        {reconcile.calculation ===
                        ReconcileCalculateOptions.DoNotCalculate
                          ? '-'
                          : multicurrencyReconcileAmounts.multiCurrencyAmountToReconcile}
                      </Box>
                    </Typography>
                    <Typography align="right">
                      <Box fontWeight="fontWeightBold">
                        {reconcile.calculation ===
                        ReconcileCalculateOptions.DoNotCalculate
                          ? '-'
                          : multicurrencyReconcileAmounts.multiCurrencyreconciledAmount}
                      </Box>
                    </Typography>
                    {reconcile.calculation !==
                      ReconcileCalculateOptions.DoNotCalculate && (
                      <Typography
                        align="right"
                        className={
                          reconcileAmountDiff === 0
                            ? classes.successColor
                            : classes.errorColor
                        }
                      >
                        <Box fontWeight="fontWeightBold">
                          {
                            multicurrencyReconcileAmounts.multiCurrencyreconcileAmountDiff
                          }
                        </Box>
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </MultiCurrencyWrapper>
          <Grid item xs={4}>
            <Autocomplete
              options={Object.values(ReconcileCalculateOptions)}
              label="Calculate By"
              placeholder="Select"
              value={reconcile.calculation}
              onChange={handleCalculationChange}
              required
            />
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Typography color="error">{error}</Typography>
            </Grid>
          )}
        </Grid>
      </Box>
      <Box display="flex" flexGrow={1} flexDirection="column" overflow="hidden">
        <FBOTitleBar title="Select Items To Reconcile" />

        <ItemsTable
          data={itemsToReconcile}
          columns={reconcileWizardReconcileItemsColumns(
            showMultiCurrency,
            activeMulticurrencyCode,
            homeCurrencyCode
          )}
          emptyTableText="No items"
          selectedItems={reconcile.selectedReconcileItems}
          onSelectedChange={handleSelectedChange}
          RenderCustomRow={ReconcileWizardReconcileItemsRow}
          meta={{
            calculation: reconcile.calculation,
            reconcilePrices: reconcile.reconcilePrices,
            selectedItems: reconcile.selectedReconcileItems,
          }}
          onAction={handleTableAction}
          RenderCustomFooter={ReconcileWizardReconcileItemsFooter}
          footerData={{ grandTotalCost, currency: reconcile.currency }}
          disableSelection={
            reconcile.calculation === ReconcileCalculateOptions.DoNotCalculate
          }
          sx={{
            borderRadius: '5px',
            border: `1px solid ${colorPalette.redesign.background3}`,
            borderTop: 'none',
            maxHeight: '355px',
          }}
        />
      </Box>
    </>
  );
};

export default memo(ReconcileWizardReconcileItems);
