import React, { memo, useCallback, useMemo } from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { DateRange } from 'materialui-daterange-picker';
import { SaleItemAsyncAutocomplete } from 'ui/components/Autocomplete/SaleItemAsyncAutocomplete';
import DateRangePicker from 'ui/components/TextField/DateRangePicker/DateRangePicker';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import { RepresentativeAsyncAutocomplete } from 'ui/components/Autocomplete/RepresentativeAsyncAutocomplete';
import { useHandleCheckboxChange } from 'services/forms/hooks';
import { Location } from 'services/locations';
import { Customer } from 'services/customers';
import { SaleItem } from 'services/items/saleItems';

import { DateRangeOptions, States, StatusOption } from './types';
import { ReportFiltersProps } from '../../types';
import { CustomersAutocomplete } from 'ui/components/Autocomplete/CustomerAutocomplete';
import { Tag } from 'services/tags';
import { TagsAsyncAutocomplete } from 'ui/components/Autocomplete/TagsAutocomplete';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ClassAutocomplete } from 'ui/components/Autocomplete/ClassesAutocomplete';
import { Class } from 'services/classes';
import { statusOptions } from './consts';
import { updateStatusOptions } from './helper';

const SalesOrderSummaryFiltersV2: React.FC<ReportFiltersProps> = (props) => {
  const { parameters, setParameters } = props;

  const selectedDateRange = useMemo(() => {
    return parameters.dateRange || {};
  }, [parameters]);

  const handleCheckboxChange = useHandleCheckboxChange(setParameters);

  const handleLocationChange = useCallback(
    (location: Location | null) => {
      setParameters((old) => ({
        ...old,
        locationId: location ? location.id : null,
      }));
    },
    [setParameters]
  );

  const handleSaleItemChange = useCallback(
    (saleItem: SaleItem | null) => {
      setParameters((old) => ({
        ...old,
        saleItemId: saleItem ? saleItem.id : null,
      }));
    },
    [setParameters]
  );

  const handleDateRangeOptionChange = useCallback(
    (e: any, dateRangeOption: DateRangeOptions | null) => {
      setParameters((old) => ({ ...old, dateRangeOption }));
    },
    [setParameters]
  );

  const handleShipToStateChange = useCallback(
    (e: any, shipToStateProvince: States | null) => {
      setParameters((old) => ({ ...old, shipToStateProvince }));
    },
    [setParameters]
  );

  const handleAutocompleteChange = useCallback(
    (parameter: string) => (value: any) => {
      setParameters((old) => ({
        ...old,
        [parameter]: value ? value.id : null,
      }));
    },
    [setParameters]
  );

  const handleCustomerChange = useCallback(
    (value: Customer | null) => {
      setParameters((old) => ({
        ...old,
        customerId: value !== undefined ? value?.id : null,
      }));
    },
    [setParameters]
  );

  const handleDateChange = useCallback(
    (value: DateRange) => {
      setParameters((old) => ({ ...old, dateRange: value }));
    },
    [setParameters]
  );

  const handleTagChange = useCallback(
    (values: Tag[]) =>
      setParameters((old) => ({
        ...old,
        tagIds: values?.length ? values.map((t) => t.id) : null,
      })),
    [setParameters]
  );

  const handleClassChange = useCallback(
    (accountingClass: Class | null) => {
      setParameters((old) => ({
        ...old,
        class: accountingClass ? [accountingClass.id] : null,
      }));
    },
    [setParameters]
  );

  const handleGroupByCustomerChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      event.persist();
      setParameters((old) => ({
        ...old,
        groupBy: checked ? 'Customer' : null,
      }));
    },
    [setParameters]
  );

  const handleStatusChange = useCallback(
    (e: any, statuses: StatusOption[]) => {
      setParameters((old) => ({
        ...old,
        ...updateStatusOptions(statuses),
      }));
    },
    [setParameters]
  );

  const getStatusValues = useCallback(() => {
    return statusOptions.filter(
      (option) => parameters[option.property] === true
    );
  }, [parameters]);

  return (
    <Box px={2} pb={0} pt="16px">
      <Box mb={1}>
        <Autocomplete
          label="Date Range Option"
          options={Object.values(DateRangeOptions)}
          placeholder="Select date range option"
          value={parameters.dateRangeOption ?? null}
          onChange={handleDateRangeOptionChange}
          fullWidth
        />
      </Box>
      <Box mb={1}>
        <ClassAutocomplete
          value={parameters.class?.[0] ?? null}
          onChange={handleClassChange}
          label="Class"
          placeholder="Select Class"
          dataQa="so-summary-filter-class"
        />
      </Box>
      <Box mb={1}>
        <TagsAsyncAutocomplete
          label="Tags"
          placeholder="Select Tag"
          value={parameters.selectedTag ?? null}
          onChange={handleTagChange}
          disableTagsModal
          dataQa="so-summary-filter-tag"
        />
      </Box>
      <Box mb={1}>
        <LocationsAsyncAutocomplete
          label="Location"
          value={parameters.locationId ?? null}
          placeholder="Select location"
          companyWide={false}
          onChange={handleLocationChange}
          dataQa="so-summary-filter-location"
        />
      </Box>
      <Box mb={1}>
        <CustomersAutocomplete
          label="Customer"
          placeholder="Select Customer"
          value={parameters.customerId ?? null}
          onChange={handleCustomerChange}
          dataQa="so-summary-filter-customer"
        />
      </Box>
      <Box mb={1}>
        <Autocomplete
          label="Status"
          options={statusOptions}
          placeholder="Select status"
          getOptionLabel={(option) => option.label}
          value={getStatusValues() ?? null}
          onChange={handleStatusChange}
          fullWidth
          className="multi-line"
          multiple
          dataQa="so-summary-filter-status"
        />
      </Box>
      <Box mb={1}>
        <RepresentativeAsyncAutocomplete
          label="Sales Rep"
          placeholder="Select sales representative"
          value={parameters.representativeId ?? null}
          onChange={handleAutocompleteChange('representativeId')}
          disableAdd
          dataQa="so-summary-filter-sales-representative"
        />
      </Box>
      <Box mb={1}>
        <Autocomplete
          label="Ship to State/Territory"
          options={Object.values(States)}
          placeholder="Select State/Province "
          value={parameters.shipToStateProvince ?? null}
          onChange={handleShipToStateChange}
          fullWidth
          dataQa="so-summary-filter-ship-to-state"
        />
      </Box>
      <Box mb={1}>
        <SaleItemAsyncAutocomplete
          label="Sale Item"
          placeholder="Select Sale Item"
          value={parameters.saleItemId ?? null}
          onChange={handleSaleItemChange}
          dataQa="so-summary-filter-sale-item"
        />
      </Box>
      <DateRangePicker
        className="date-picker-small-font"
        label="Date Range"
        value={selectedDateRange}
        aria-label="Date Range"
        name="dateRange"
        placeholder="Select date range"
        onChange={handleDateChange}
        fullWidth
        dataQa="so-summary-filter-date-range"
      />
      <Box mb={1}>
        <FormControlLabel
          className="redesign"
          sx={{ marginBottom: '8px' }}
          control={
            <Checkbox
              className="redesign"
              checked={Boolean(parameters.groupBy)}
              onChange={handleGroupByCustomerChange}
              name="groupBy"
              color="primary"
            />
          }
          label="Group by Customer"
        />
      </Box>
      <Accordion square>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>
            <b>Columns</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%">
            <Box mb={1}>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnDateFulfilled)}
                    onChange={handleCheckboxChange}
                    name="columnDateFulfilled"
                    color="primary"
                  />
                }
                label="Show Date Fulfilled"
              />
            </Box>
            <Box mb={1}>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnShipToStateProvince)}
                    onChange={handleCheckboxChange}
                    name="columnShipToStateProvince"
                    color="primary"
                  />
                }
                label="Show Ship to State"
              />
            </Box>
            <Box mb={1}>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnSalesRep)}
                    onChange={handleCheckboxChange}
                    name="columnSalesRep"
                    color="primary"
                  />
                }
                label="Show Sales Rep"
              />
            </Box>
            <Box mb={1}>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showTotalOnly)}
                    onChange={handleCheckboxChange}
                    name="showTotalOnly"
                    color="primary"
                  />
                }
                label="Show Total Only"
              />
            </Box>
            <Box mb={1}>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showShippedInfo)}
                    onChange={handleCheckboxChange}
                    name="showShippedInfo"
                    color="primary"
                  />
                }
                label="Show Shipped Info"
              />
            </Box>
            <Box mb={1}>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.showPaymentInfo)}
                    onChange={handleCheckboxChange}
                    name="showPaymentInfo"
                    color="primary"
                  />
                }
                label="Show Payment Info"
              />
            </Box>
            <Box mb={1}>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnPaymentTerm)}
                    onChange={handleCheckboxChange}
                    name="columnPaymentTerm"
                    color="primary"
                  />
                }
                label="Show Payment Terms"
              />
            </Box>
            <Box mb={1}>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnMargin)}
                    onChange={handleCheckboxChange}
                    name="columnMargin"
                    color="primary"
                  />
                }
                label="Show Margin"
              />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default memo(SalesOrderSummaryFiltersV2);
