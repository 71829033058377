import React, { memo, useState, useMemo, useCallback } from 'react';
import { Box } from '@mui/material';
import { Modal } from 'ui/components/Modal/Modal';
import { useSerialModalStyle } from './styled';
import { SerialModalProps } from './types';
import CycleCountSerialTable from 'ui/components/Table/SerialTable/CycleCountSerialTable';
import { TextField } from 'ui/components/TextField/TextField';
import { ItemTrackingType } from 'services/inventory';
import { TrackingDataTypes } from 'services/settings/tracking';
import _ from 'lodash';
import { formatDatePreview, useGetIntlDateFormatString } from 'helpers';
import { getSettingsCompanyCountry } from 'services/settings/company';
import { useSelector } from 'react-redux';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { colorPalette, themeRestyle } from 'ui/theme';

const SerialModal: React.FC<SerialModalProps> = ({
  visible,
  itemTrackingTypes,
  quantity,
  serialList,
  onCloseClicked,
  trackingGroup,
  closeParentModal,
}) => {
  const classes = useSerialModalStyle();
  const intlFormatDate = useGetIntlDateFormatString();
  const companyCountry = useSelector(getSettingsCompanyCountry);
  const [loadingContent, setLoadingContent] = useState<boolean>(false);

  const serialItemTrackingTypes: ItemTrackingType[] = useMemo(() => {
    // For serial table we need only serial tracking types
    return itemTrackingTypes.filter(
      (t) =>
        _.get(t, 'trackingType.trackingDataType', null) ===
        TrackingDataTypes.SerialNumber
    );
  }, [itemTrackingTypes]);

  const getTrackingLabel = (id: number) => {
    return itemTrackingTypes?.find((type) => type.trackingType?.id === id)
      ?.trackingType?.name;
  };

  const FBOModalActions = useCallback(() => {
    return (
      <Box display="flex" justifyContent="space-between" width="100%">
        <FBOButton
          variant="secondary"
          color="positive"
          size="medium"
          onClick={onCloseClicked}
          data-qa="back-to-tracking-button"
        >
          Back to Tracking
        </FBOButton>
        <FBOButton
          variant="primary"
          color="positive"
          size="medium"
          onClick={closeParentModal}
          data-qa="back-to-tracking-button"
        >
          Done
        </FBOButton>
      </Box>
    );
  }, [onCloseClicked, closeParentModal]);

  return (
    <Modal
      title="Tracking Details"
      open={visible}
      onCancelClicked={onCloseClicked}
      withoutDefaultPadding
      isLoadingContent={loadingContent}
      nestedScrollAreas
      ModalActionsComponent={FBOModalActions}
      dataQa="serial"
    >
      <Box className={classes.trackingQty}>
        <TextField
          className="redesign"
          variant="standard"
          value={serialList.length}
          label="Quantity"
          fullWidth={false}
          readOnly
        />
      </Box>
      <Box className={classes.trackingDetails}>
        {trackingGroup.trackingInfoList.map((info) => {
          const resolvedTrackingInfo =
            (itemTrackingTypes.find(
              (type) => type.trackingType?.id === info?.trackingTypeId
            )?.trackingType?.trackingDataType === TrackingDataTypes.Date
              ? formatDatePreview(info?.value, companyCountry, intlFormatDate)
              : info?.value) ?? '';
          return (
            <TextField
              className="redesign"
              variant="standard"
              key={info.trackingTypeId}
              value={resolvedTrackingInfo}
              label={getTrackingLabel(info.trackingTypeId)}
              fullWidth={false}
              readOnly
            />
          );
        })}
      </Box>
      <CycleCountSerialTable
        serialList={serialList}
        setIsLoading={setLoadingContent}
        isLoading={loadingContent}
        minSerialQuantity={quantity}
        itemTrackingTypes={serialItemTrackingTypes}
        allowSerialNumberImport={false}
        canSelectRow={false}
        disableAutoAssign
        readOnly
        sx={{
          borderRadius: '5px',
          borderLeft: `1px solid ${colorPalette.redesign.background3}`,
          borderRight: `1px solid ${colorPalette.redesign.background3}`,
          marginRight: themeRestyle.spacing(4),
          marginLeft: themeRestyle.spacing(4),
        }}
        headerMargin="0px 24px"
      />
    </Modal>
  );
};

export default memo(SerialModal);
