import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { PermissionType } from 'services/permissions';
import SecureRoute from 'ui/components/Route/SecureRoute';

import { Routes as HomeRoutes } from './routes';
import { DashboardRouterCmp } from './types';
import { DashboardPage, HomePage } from '../pages';

import { FBOFlowPage } from '../pages/FlowPage/FBOFlowPage';

const HomeRouter: DashboardRouterCmp = () => {
  return (
    <Routes>
      <Route
        element={<SecureRoute permissions={[PermissionType.DashboardView]} />}
      >
        <Route path={DashboardPage.route} element={<DashboardPage />} />
      </Route>
      <Route element={<SecureRoute />}>
        <Route path={HomeRoutes.FlowPage} element={<FBOFlowPage />} />
        <Route path={HomePage.route} element={<HomePage />} />
      </Route>
    </Routes>
  );
};

HomeRouter.route = HomeRoutes.HomeRouter;

export default HomeRouter;
