import { Tag } from './types';

export const transformToTag = (res: any): Tag => ({
  id: res.id,
  name: res.name,
  version: res.version,
});

export const transformToRequestTag = (tag: Tag): any => {
  // PUT
  if (tag.id && tag.id > 0) {
    return {
      id: tag.id,
      name: tag.name,
      version: tag.version,
    };
  }

  // POST
  return {
    name: tag.name,
  };
};
