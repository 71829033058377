import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colorPalette } from 'ui/theme';

// Lint skip to be removed
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const useCustomFieldsStyle = makeStyles((theme: Theme) => ({
  statusLabel: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
    lineHeight: 1,
    borderRadius: 20,
    margin: 8,
  },
  button: {
    color: colorPalette.fishbowlCorporateGray1,
    borderColor: colorPalette.buttonBorderColor,
  },
}));
