import { createFilterOptions, FilterOptionsState } from '@mui/material';

import { Pagination } from 'services/search';
import { fetchPicksAPI, Pick } from 'services/picking';

const filter = createFilterOptions<Pick>();

export const pickAutocompleteFilterOptions =
  () => (options: Pick[], params: FilterOptionsState<Pick>) => {
    const filtered = filter(options, params);

    return filtered;
  };

export const resolvedFetchPickFunction = (
  expands?: string[],
  customQuickSearchColumns?: string[]
) => {
  return (pagination: Pagination, searchValue: string | null) => {
    return fetchPicksAPI({
      pagination,
      expands: expands,
      quickSearchValue: searchValue,
      customQuickSearchColumns,
    });
  };
};
