import React, { useCallback } from 'react';

import { Pagination } from 'services/search';
import {
  ItemsTable,
  useSelectedItemsChanges,
} from 'ui/components/Table/ItemsTable';
import { colorPalette } from 'ui/theme';
import { PermissionType } from 'services/permissions';
import { arrayHasAllDeletedObjects } from 'helpers';

import {
  createSOSearchActionBarOptions,
  SALES_ORDER_COLUMNS,
  SALES_ORDER_DETAILS_COLUMNS_WITH_STATUS,
} from './consts';
import { SalesOrderSearchResultsProps } from './types';
import SalesOrderRow from './SalesOrderRow';
import SalesOrderRowSmall from './SalesOrderRowSmall';
import { isIssueButtonVisible, isUnissuedVisible } from './helpers';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';
import { useSelector } from 'react-redux';
import { getActiveUser } from 'services/userV2';
import { useFlags } from 'helpers/useFlags';

const SalesOrderSearchResults: React.FC<SalesOrderSearchResultsProps> = (
  props
) => {
  const {
    salesOrders,
    activeSalesOrderId,
    handleSalesOrderClick,
    onAddNewPress,
    isLoadingSalesOrders,
    pagination,
    onPaginationChange,
    selectedItems,
    activeSalesOrderDate,
    setSelectedItems,
    onAction,
    onDeleteClicked,
    onPageAction,
    onUnissueClicked,
    onIssueClicked,
  } = props;

  const activeUser = useSelector(getActiveUser);
  const flags = useFlags();
  const csvDisabled = flags.newUserEndpoint && !activeUser.user?.admin;

  const allDeletedSalesOrder = arrayHasAllDeletedObjects(salesOrders);
  const handlePaginationChange = (newPagination: Pagination) => {
    onPaginationChange(newPagination);
  };

  const resolvedColumns = activeSalesOrderId
    ? SALES_ORDER_DETAILS_COLUMNS_WITH_STATUS
    : SALES_ORDER_COLUMNS(activeSalesOrderDate);

  const handleSelectClick = useSelectedItemsChanges(
    selectedItems,
    setSelectedItems
  );

  const handleAction = useCallback(
    (action: any) => {
      onAction(action.type, action.value);
    },
    [onAction]
  );

  return (
    <>
      <FBOTitleBar title="Sales Orders">
        {selectedItems.length > 0 && !activeSalesOrderId && (
          <FBOButton
            style={{ marginRight: '8px' }}
            variant="secondary"
            size="medium"
            color="negative"
            icon="TrashCan"
            onClick={onDeleteClicked}
            data-qa="sales-order-delete-button"
          >
            Delete
          </FBOButton>
        )}
        {isUnissuedVisible(activeSalesOrderId, salesOrders, selectedItems) && (
          <FBOButton
            style={{ marginRight: '8px' }}
            variant="secondary"
            size="medium"
            color="negative"
            onClick={onUnissueClicked}
            data-qa="sales-order-unissue-button"
          >
            Unissue
          </FBOButton>
        )}
        {isIssueButtonVisible(
          activeSalesOrderId,
          salesOrders,
          selectedItems
        ) && (
          <FBOButton
            style={{ marginRight: '8px' }}
            variant="secondary"
            size="medium"
            color="positive"
            onClick={onIssueClicked}
            data-qa="sales-order-issue-button"
          >
            Issue
          </FBOButton>
        )}

        {activeSalesOrderId !== -1 && (
          <>
            <FBOButton
              style={{ marginRight: '8px' }}
              variant={!activeSalesOrderId ? 'primary' : 'secondary'}
              size="medium"
              color="positive"
              icon="FBOAddCircle"
              onClick={onAddNewPress}
              permissions={[PermissionType.SalesOrderCreate]}
              data-qa="sale-order-new"
            >
              {!activeSalesOrderId ? 'Add New' : ''}
            </FBOButton>
            <FBOMenuButton
              variant="secondary"
              items={createSOSearchActionBarOptions(
                onPageAction,
                salesOrders.length,
                csvDisabled
              )}
              data-qa="sale-order-three-dot-menu"
              style={{ color: colorPalette.redesign.contentSecondary }}
            />
          </>
        )}
      </FBOTitleBar>
      <ItemsTable
        data={salesOrders}
        columns={resolvedColumns}
        RenderCustomRow={
          activeSalesOrderId ? SalesOrderRowSmall : SalesOrderRow
        }
        activeId={activeSalesOrderId}
        onItemClick={handleSalesOrderClick}
        onSelectedChange={handleSelectClick}
        selectedItems={selectedItems}
        selectableItems={!activeSalesOrderId}
        isLoadingData={isLoadingSalesOrders}
        pagination={pagination}
        disableSelection={allDeletedSalesOrder}
        onPaginationChange={handlePaginationChange}
        onAction={handleAction}
        dataQa="sale-order-table"
        meta={{ hasConnection: false }}
        emptyTableText={
          !activeSalesOrderId
            ? "ADD NEW ENTRY BY PRESSING 'ADD NEW'"
            : 'NO SALES ORDERS AVAILABLE'
        }
        showEnhancedTablePaginationActions={!Boolean(activeSalesOrderId)}
      />
    </>
  );
};

export default SalesOrderSearchResults;
