import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TableCell, Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import clsx from 'clsx';
import _ from 'lodash';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import { ImageItemBox } from 'ui/components/Image/ImageItemBox';
import {
  getPickItemDescription,
  getPickItemName,
  PickItem,
} from 'services/picking';
import { getUoms } from 'services/uoms';

import { usePickItemCommitRowStyle } from './styled';

const PickItemCommitRow: React.FC<RenderCustomRowProps<PickItem>> = (props) => {
  const { row: pickItem, meta } = props;

  const uoms = useSelector(getUoms).items;

  const classes = usePickItemCommitRowStyle();

  const selectedUom = useMemo(() => {
    return uoms.find((u) => u.id === pickItem.uomId);
  }, [uoms, pickItem.uomId]);

  const resolvedQuantity = useMemo(() => {
    const abbreviation = _.get(selectedUom, 'abbreviation');
    if (pickItem.quantity === pickItem.amount) {
      return `${pickItem.amount || 0} ${abbreviation}`;
    } else {
      return `${pickItem.amount || 0} / ${pickItem.quantity} ${abbreviation}`;
    }
  }, [pickItem, selectedUom]);

  // Summary Row
  if (pickItem.id === -1) {
    return (
      <TableCell colSpan={2}>
        <Box display="flex" alignItems="center">
          {meta.isCommitReady ? (
            <CheckCircleIcon
              className={clsx({
                [classes.checkIcon]: true,
                [classes.checkIconSuccess]: true,
              })}
            />
          ) : (
            <RadioButtonUncheckedIcon className={classes.checkIcon} />
          )}
          <Box>
            <Typography>Summary</Typography>
            <Typography variant="subtitle2">
              Review Picking Information
            </Typography>
          </Box>
        </Box>
      </TableCell>
    );
  }
  return (
    <>
      <TableCell data-qa={`Item-${pickItem.salesOrderItem?.name}`}>
        <Box className={classes.columnItem}>
          {!!pickItem.amount ? (
            <CheckCircleIcon
              className={clsx({
                [classes.checkIcon]: true,
                [classes.checkIconSuccess]:
                  pickItem.amount === pickItem.quantity,
                [classes.checkIconPartial]:
                  pickItem.amount !== pickItem.quantity,
              })}
            />
          ) : (
            <RadioButtonUncheckedIcon className={classes.checkIcon} />
          )}
          <ImageItemBox
            name={getPickItemName(pickItem)}
            description={getPickItemDescription(pickItem)}
            source={_.get(pickItem, 'item.defaultImage', null)}
          />
        </Box>
      </TableCell>
      <TableCell data-qa={`Quantity-${pickItem.salesOrderItem?.name}`}>
        {resolvedQuantity}
      </TableCell>
    </>
  );
};

export default React.memo(PickItemCommitRow);
