import { createApiAction } from 'services/api';
import {
  StartAction,
  SuccessAction,
  FailureAction,
} from 'services/api/redux/types';
import {
  createAddSearch,
  createDeleteSearch,
  createFetchSearch,
  createUpdateSearch,
} from 'services/search';

export enum CurrenciesActionTypes {
  FETCH_CURRENCIES_START = 'currencies/fetch_currencies_start',
  FETCH_CURRENCIES_SUCCESS = 'currencies/fetch_currencies_success',
  FETCH_CURRENCIES_FAILURE = 'currencies/fetch_currencies_failure',
}

const fetchCurrenciesActionStart = (): StartAction => ({
  type: CurrenciesActionTypes.FETCH_CURRENCIES_START,
  payload: null,
});

const fetchCurrenciesActionSuccess = (payload: any): SuccessAction => {
  return {
    type: CurrenciesActionTypes.FETCH_CURRENCIES_SUCCESS,
    payload,
  };
};

const fetchCurrenciesActionFailure = (error: string): FailureAction => ({
  type: CurrenciesActionTypes.FETCH_CURRENCIES_FAILURE,
  payload: { error },
});

export const fetchCurrencies = () =>
  createApiAction(
    {
      path: '/v1/currencies',
      method: 'GET',
    },
    fetchCurrenciesActionStart,
    fetchCurrenciesActionSuccess,
    fetchCurrenciesActionFailure
  );

export const fetchSearch = createFetchSearch('currency');
export const addSearch = createAddSearch('currency');
export const updateSearch = createUpdateSearch('currency');
export const deleteSearch = createDeleteSearch('currency');
