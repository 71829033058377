import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const columns: Column[] = [
  {
    title: 'Product number',
    type: ColumnTypes.renderByCustomRow,
    width: '20%',
  },
  {
    title: 'Description',
    type: ColumnTypes.renderByCustomRow,
    width: '20%',
  },
  {
    title: 'Country of origin',
    type: ColumnTypes.renderByCustomRow,
    width: '20%',
  },
  {
    title: 'Tariff number',
    type: ColumnTypes.renderByCustomRow,
    width: '20%',
  },
  {
    title: 'Declared value',
    type: ColumnTypes.renderByCustomRow,
    width: '20%',
  },
];
