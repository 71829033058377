import React, { useCallback, useState, useEffect } from 'react';
import { InputAdornment, IconButton, Link } from '@mui/material';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { TextField } from 'ui/components/TextField/TextField';
import { Menu } from 'ui/components/Menu';
import { activeUserHasPermission } from 'services/user/redux';

import { TextFieldWithOptionsProps } from './types';
import { useTextFieldWithOptionsStyle } from './styled';

type Props = TextFieldWithOptionsProps;

const TextFieldWithOptions = (props: Props, ref: any) => {
  const {
    additionalInputProps,
    options,
    selectedOptionIndex,
    onTextChange,
    onMenuChange,
    dataQa,
    disabled,
    permissions = [],
    value,
    legacyStyle = false,
    ...otherProps
  } = props;

  const classes = useTextFieldWithOptionsStyle();

  const canEdit = useSelector(activeUserHasPermission(permissions));

  const isDisabled = !canEdit || disabled;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    if (selectedOptionIndex === null) {
      onMenuChange(0);
    }
  }, [selectedOptionIndex, onMenuChange]);

  const isActionMenuVisible = Boolean(anchorEl);

  const handleIconClicked = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleActionMenuClose = () => setAnchorEl(null);

  const handleMenuItemClick = useCallback(
    (actionItemIndex: number) => {
      onMenuChange(actionItemIndex);
      handleActionMenuClose();
    },
    [onMenuChange]
  );

  const renderAdornment = () => (
    <InputAdornment position="end">
      <IconButton onClick={handleIconClicked} edge="end" size="large">
        <Link
          className={classes.link}
          variant="body2"
          color="textPrimary"
          data-qa={`${dataQa}-unit`}
          underline="hover"
        >
          {_.get(
            options[selectedOptionIndex || 0],
            'shortName',
            options[selectedOptionIndex || 0].label
          )}
        </Link>
      </IconButton>
    </InputAdornment>
  );

  if (isDisabled) {
    const secondValue = _.get(options[selectedOptionIndex!], 'shortName', '');
    const resolvedValue = `${value || 0} ${secondValue}`;

    return (
      <TextField
        className="redesign"
        variant="standard"
        disabled
        value={resolvedValue}
      />
    );
  }

  return (
    <>
      <TextField
        className="redesign"
        variant={legacyStyle ? undefined : 'standard'}
        dataQa={dataQa}
        {...otherProps}
        ref={ref}
        onChange={onTextChange}
        value={value}
        InputProps={{
          ...additionalInputProps,
          endAdornment: renderAdornment(),
        }}
      />
      <Menu
        items={options}
        open={isActionMenuVisible}
        anchorEl={anchorEl}
        onClose={handleActionMenuClose}
        onMenuItemClick={handleMenuItemClick}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      />
    </>
  );
};

export default React.memo(React.forwardRef(TextFieldWithOptions));
