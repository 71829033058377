import { createFilterOptions, FilterOptionsState } from '@mui/material';

import { Pagination } from 'services/search';
import { fetchShipsAPI, Ship } from 'services/shipping';

const filter = createFilterOptions<Ship>();

export const shipAutocompleteFilterOptions =
  () => (options: Ship[], params: FilterOptionsState<Ship>) => {
    const filtered = filter(options, params);

    return filtered;
  };

export const resolvedFetchShipFunction = (
  expands?: string[],
  customQuickSearchColumns?: string[]
) => {
  return (pagination: Pagination, searchValue: string | null) =>
    fetchShipsAPI({
      pagination,
      expands: expands,
      quickSearchValue: searchValue,
      customQuickSearchColumns,
    });
};
