import React, { memo, useState, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Divider, Box } from '@mui/material';
import { activeUserHasPermission } from 'services/user/redux';
import { Paper } from 'ui/components/Paper/Paper';
import { Modal } from 'ui/components/Modal/Modal';
import { CardProps } from './types';
import { useCardStyle } from './styled';
import { CardRow, ModalRow } from './components';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const MODAL_HEIGHT = 560;

const Card: React.FC<CardProps> = (props) => {
  const {
    show,
    rows,
    title,
    id,
    data,
    viewPermissions = [],
    editPermissions = [],
    onApplyClicked,
    noReset,
    dataQa,
  } = props;

  const classes = useCardStyle();

  const hasViewPermission = useSelector(
    activeUserHasPermission(viewPermissions)
  );
  const hasEditPermission = useSelector(
    activeUserHasPermission(editPermissions)
  );

  const [cardData, setCardData] = useState(data);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isWaitForResponse, setIsWaitForResponse] = useState(false);

  useEffect(() => {
    setCardData(data);
  }, [data]);
  const lastIndex = useMemo(() => rows.length - 1, [rows]);

  const handleEditClicked = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const handleCancelClicked = useCallback(() => {
    setCardData(data);
    setIsModalVisible(false);
  }, [data]);

  const handleResetClicked = useCallback(() => {
    setCardData(data);
  }, [data]);

  const handleApplyClicked = useCallback(async () => {
    setIsWaitForResponse(true);
    await onApplyClicked(cardData);
    setIsModalVisible(false);
    setIsWaitForResponse(false);
  }, [cardData, onApplyClicked]);

  const handleCardDataChanged = useCallback((field: string, value: unknown) => {
    setCardData((oldCardData: unknown) => ({
      ...(oldCardData as object),
      [field]: value,
    }));
  }, []);

  const dangerouslySetInnerHTML = useMemo(() => {
    return title.includes('<mark>');
  }, [title]);

  return (
    <>
      {hasViewPermission && show && (
        <Box className={classes.container}>
          <Paper
            id={id}
            className={`${classes.paper} static-paper redesign`}
            sx={{ boxShadow: 'none' }}
          >
            <FBOTitleBar
              dangerouslySetInnerHTML={dangerouslySetInnerHTML}
              title={title}
              sx={{ borderBottom: '1px solid #e0e0e0' }}
            >
              <FBOButton
                color="positive"
                variant="primary"
                onClick={handleEditClicked}
                permissions={editPermissions}
                data-qa={`${dataQa}-edit`}
              >
                Edit
              </FBOButton>
            </FBOTitleBar>
            {rows.map((row, i) => (
              <React.Fragment key={`fragment-${i}`}>
                <CardRow {...row} data={data} />
                {i !== lastIndex && <Divider />}
              </React.Fragment>
            ))}
          </Paper>
          {hasEditPermission && (
            <Modal
              open={isModalVisible}
              onClose={handleCancelClicked}
              title={title}
              applyLabel={'Save Changes'}
              onApplyClicked={handleApplyClicked}
              onCancelClicked={handleCancelClicked}
              onResetClicked={noReset ? undefined : handleResetClicked}
              isLoading={isWaitForResponse}
              withoutDefaultPadding
              footerDivider="shadow"
              customHeight={MODAL_HEIGHT}
              maxWidth="sm"
              dataQa={dataQa}
            >
              {rows.map((row, i) => (
                <React.Fragment key={`fragment-${i}`}>
                  <ModalRow
                    {...row}
                    setCardData={handleCardDataChanged}
                    cardData={cardData}
                  />
                  {i !== lastIndex && <Divider />}
                </React.Fragment>
              ))}
            </Modal>
          )}
        </Box>
      )}
    </>
  );
};

export default memo(Card);
