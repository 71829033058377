import _ from 'lodash';

import { PurchaseOrderItemTypes } from 'services/purchaseOrders';
import { roundToDecimals, toMulticurrencyCalculate } from 'helpers';
import { SalesOrderItemTypes } from 'services/salesOrders';
import { Uom } from 'services/uoms';

import {
  DiscountTypes,
  Discount,
  SalesOrderBundleItem,
  CalculatedTotals,
} from './types';
import { ItemUomConversion } from 'services/items';

export const calculateTotals = (
  price: number,
  quantity: number,
  discount: Discount,
  taxRate: number,
  priceIncludesTax: boolean = false,
  exchangeRate: number = 1
): CalculatedTotals => {
  const priceWithQuantity = price * quantity;

  const priceWithoutTax = priceIncludesTax
    ? priceWithQuantity / (1 + taxRate)
    : priceWithQuantity;

  const discountTotal = calculateDiscountTotal(priceWithoutTax, discount) || 0;

  //For Credit Return Sale Item type, price is a negative number
  const priceWithDiscount =
    priceWithoutTax - Math.sign(priceWithoutTax) * discountTotal;

  const taxTotal = roundToDecimals(priceWithDiscount * taxRate, 2);

  const total = priceWithDiscount + taxTotal;

  const multiCurrencyDiscountTotal = toMulticurrencyCalculate(
    discountTotal,
    exchangeRate
  );

  const multiCurrencyTaxTotal = toMulticurrencyCalculate(
    taxTotal,
    exchangeRate
  );

  const multiCurrencyTotal = toMulticurrencyCalculate(total, exchangeRate);

  const multiCurrencySubTotal = toMulticurrencyCalculate(
    priceWithDiscount,
    exchangeRate
  );

  return {
    discountTotal: discountTotal || 0,
    taxTotal,
    total,
    subTotal: priceWithDiscount,
    multiCurrencyDiscountTotal,
    multiCurrencyTaxTotal,
    multiCurrencyTotal,
    multiCurrencySubTotal,
  };
};

export const bundleItemIsCreditReturn = (bundleItem: SalesOrderBundleItem) => {
  return (
    !!bundleItem.salesOrderItems.length &&
    bundleItem.salesOrderItems.every(
      (soi) => soi.salesOrderItemType === SalesOrderItemTypes.CreditReturn
    )
  );
};

export const calculateDiscountTotal = (price: number, discount: Discount) => {
  return discount.type === DiscountTypes.FlatRate
    ? discount.value
    : Math.abs(price) * ((discount.value || 0) / 100);
};

export const calculateTotal = (
  price: number,
  discountTotal: number,
  taxTotal: number
) => {
  return price - discountTotal + taxTotal;
};

export const calculateTaxTotal = (
  taxRate: number,
  price: number,
  discountTotal: number
) => {
  return roundToDecimals((price - discountTotal) * taxRate, 2);
};

export const convertToNegative = (
  value: string,
  rowType: SalesOrderItemTypes | PurchaseOrderItemTypes
) => {
  const negativeRow =
    rowType === SalesOrderItemTypes.CreditReturn ||
    rowType === SalesOrderItemTypes.MiscReturn ||
    rowType === PurchaseOrderItemTypes.CreditReturn ||
    rowType === PurchaseOrderItemTypes.MiscCredit;
  if (negativeRow && parseFloat(value) > 0) {
    return parseFloat('-' + value);
  }
  return parseFloat(value);
};

//calculating prices multiplying or dividing  by factor
//factor can be locaten in item's own uom conversions or in uom's general conversion
//if selected conversion is from then we devide, else we multiply
export const calculatePriceBasedOnUomChange = (
  newUom: Uom,
  oldUom: Uom,
  oldPrice: number,
  itemUomConversions: ItemUomConversion[]
): number => {
  if (!newUom || !oldUom || newUom.id === oldUom.id) {
    return oldPrice;
  }

  const itemUOMConversionFrom = itemUomConversions.find(
    (u) => u.fromUomId === newUom.id && u.toUomId === oldUom.id
  );
  const itemUOMConversionTo = itemUomConversions.find(
    (u) => u.toUomId === newUom.id && u.fromUomId === oldUom.id
  );

  const toActiveUomConversion = newUom.toConversions.find(
    (u) => u.toUomId === oldUom.id
  );
  const fromActiveUomConversion = newUom.fromConversions.find(
    (u) => u.fromUomId === oldUom.id
  );

  const factorDivide = itemUOMConversionTo
    ? _.get(itemUOMConversionTo, 'factor', 1)!
    : fromActiveUomConversion
    ? _.get(fromActiveUomConversion, 'factor', 1)!
    : null;
  const factorMultiply = itemUOMConversionFrom
    ? _.get(itemUOMConversionFrom, 'factor', 1)!
    : toActiveUomConversion
    ? _.get(toActiveUomConversion, 'factor', 1)!
    : null;

  const factor = factorDivide
    ? 1 / factorDivide
    : factorMultiply
    ? factorMultiply
    : 1;

  return oldPrice * factor || 0;
};
