import React, { memo, useCallback, useEffect, useState } from 'react';
import { Grid, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import { postReportPreset } from 'services/reports';
import { getActiveUser } from 'services/user';

import { ReportsPresetModalProps } from './types';
import { logErrorCtx } from 'app/logging';

const ReportsPresetModal: React.FC<ReportsPresetModalProps> = (props) => {
  const {
    visibility,
    activePreset,
    setActivePreset,
    presets,
    setPresets,
    onClose,
  } = props;

  const { user: activeUser } = useSelector(getActiveUser);
  const activeUserId = activeUser ? activeUser.id! : 0;

  const [name, setName] = useState<string | null>(null);

  useEffect(() => {
    if (visibility) {
      setName(activePreset.name);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibility]);

  const handleSaveClicked = async () => {
    try {
      const newPresets = await postReportPreset(activeUserId, {
        ...activePreset,
        name,
        defaultFlag: _.isEmpty(presets),
      });
      setPresets(newPresets);

      // find latest id in new presets and set that preset to active preset
      setActivePreset(_.maxBy(newPresets, 'id')!);
      onClose();
    } catch (e) {
      const error = e as Error;
      logErrorCtx('Error in ReportPresetModal', {
        stackTrace: error.stack,
        title: 'Error while adding presets',
        description: 'postReportPreset Not Fetched from Server',
        error,
        component: 'ReportsPresetModal',
      });
    }
  };

  const handleNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setName(event.target.value || null);
    },
    []
  );

  return (
    <Modal
      open={visibility}
      onCancelClicked={onClose}
      onApplyClicked={handleSaveClicked}
      onResetClicked={_.noop}
      title="Preset"
      applyLabel="save"
      withoutDefaultPadding
      resetButtonVisible={false}
    >
      <Box p={4} pb={2} overflow="hidden">
        <Grid container spacing={2}>
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <TextField
                className="redesign"
                variant="standard"
                dataQa="preset-name"
                label="Preset Name"
                placeholder="Name"
                value={name}
                onChange={handleNameChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default memo(ReportsPresetModal);
