import { makeStyles } from '@mui/styles';

export const useCustomFieldStyle = makeStyles(() => ({
  buttonLink: {
    'text-transform': 'none',
    'white-space': 'pre',
    cursor: 'pointer',
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
  },
}));
