import React, { memo } from 'react';
import { InvoiceDetails } from 'services/invoices';
import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { PAYMENTS_COLUMNS } from './consts';
import TransactionsTableCustomRow from './PaymentsTableCustomRow';

interface TransationsTabProps {
  invoiceDetails: InvoiceDetails;
}

const TransactionsTab: React.FC<TransationsTabProps> = (props) => {
  const { invoiceDetails } = props;

  return (
    <ItemsTable
      data={invoiceDetails.payments ?? []}
      columns={PAYMENTS_COLUMNS}
      RenderCustomRow={TransactionsTableCustomRow}
      selectableItems={false}
      emptyTableText="THERE ARE NO PAYMENTS"
      dataQa="payments-table"
    />
  );
};

export default memo(TransactionsTab);
