import React, { memo, useCallback, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoComplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { CircularProgress, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import * as yup from 'yup';

import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import { MultiFormatInput } from 'ui/components/TextField/MultiFormatInput';
import { useHandleTextFieldChange } from 'services/forms';
import { Errors, validateYup } from 'services/forms/validation';
import {
  createTaxRate,
  fetchTaxRates,
  getTaxRates,
  initialTaxRate,
  TaxRate,
  TaxType,
} from 'services/taxRates';
import { activeUserHasPermission } from 'services/user/redux';
import { fetchVendors, getVendors, Vendor } from 'services/vendors';

import { TaxRatesAutocompleteProps, TaxRateVariants } from './types';
import { formatTypesFlatRate, formatTypesPercentage } from './consts';
import _ from 'lodash';
import { Autocomplete } from '../Autocomplete';
import { useGetCurrencySymbol } from 'helpers';
import { useFlags } from 'helpers/useFlags';
import { getSettingsCompanyCountry } from 'services/settings/company';

const filter = createFilterOptions<TaxRate>();

const yupSchema = yup.object().shape({
  name: yup.string(),
  code: yup.string(),
  agencyId: yup.number(),
});

const TaxRatesAutocomplete: React.FC<TaxRatesAutocompleteProps> = (props) => {
  const {
    onChange,
    dataQa,
    permissions = [],
    disabled,
    label,
    value,
    placeholder,
    error,
    required,
    additionalInputProps,
    helperText,
    taxRateVariant = TaxRateVariants.All,
    channelTaxType,
  } = props;

  const dispatch = useDispatch();

  const { items: taxRates } = useSelector(getTaxRates);
  const { items: vendors } = useSelector(getVendors);
  const currencySymbol = useGetCurrencySymbol();

  const [showModal, setShowModal] = useState(false);
  const companyCountry = useSelector(getSettingsCompanyCountry);
  const [isInternationalConnected, setisInternationalConnected] =
    useState(false);
  const [formTaxRate, setFormTaxRate] = useState<TaxRate>({
    ...initialTaxRate,
    taxType:
      taxRateVariant === TaxRateVariants.FlatRate
        ? TaxType.flatRate
        : TaxType.percentage,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Errors>({});
  const [percentageValue, setPercentageValue] = useState(0);

  const canEdit = useSelector(activeUserHasPermission(permissions));
  const flag = useFlags();
  const isDisabled = disabled || !canEdit;
  useEffect(() => {
    if (companyCountry !== 'US') setisInternationalConnected(true);
  }, [companyCountry]);
  const selectedVendor = useMemo(
    () => vendors.find((v) => v.id === formTaxRate.agencyId) || null,
    [formTaxRate, vendors]
  );

  const selectedTaxRate = useMemo(
    () => taxRates.find((t) => t.id === value) || null,
    [value, taxRates]
  );

  const percentageOnlyTaxRates = useMemo(
    () => taxRates.filter((tax) => tax.taxType === TaxType.percentage),
    [taxRates]
  );

  const flatOnlyTaxRates = useMemo(
    () => taxRates.filter((tax) => tax.taxType === TaxType.flatRate),
    [taxRates]
  );
  const channelTaxTypeOnlyTaxRates = useMemo(
    () => taxRates.filter((tax) => tax.channelTaxType === channelTaxType),
    [taxRates]
  );
  useEffect(() => {
    // fetch only if modal is opened
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (showModal) dispatch(fetchVendors());
  }, [dispatch, showModal]);

  const taxRatesType = () => {
    // check what tax rates variant to show
    let rates: TaxRate[] = [];
    if (channelTaxType && flag.intlTaxRates && isInternationalConnected) {
      rates = channelTaxTypeOnlyTaxRates;
    } else {
      switch (taxRateVariant) {
        case TaxRateVariants.Percentage:
          rates = percentageOnlyTaxRates;
          break;
        case TaxRateVariants.FlatRate:
          rates = flatOnlyTaxRates;
          break;
        default:
          rates = taxRates;
          break;
      }
    }

    if (rates.length === 0) {
      rates.push(initialTaxRate);
    }

    return rates;
  };

  const handleChange = (e: any, taxRate: TaxRate | null) => {
    if (taxRate && (taxRate.id === null || taxRate.id < 0)) {
      setFormTaxRate((old) => ({ ...old, name: taxRate.name }));
      setErrors({});
      setShowModal(true);
      return;
    }

    onChange(taxRate);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFormTaxRate(initialTaxRate);
  };

  const handleApplyModal = async () => {
    if (!validateYup(formTaxRate, yupSchema, setErrors)) {
      return;
    }

    setIsLoading(true);

    try {
      const newTaxRate = await createTaxRate(formTaxRate);
      // Lint skip to be removed
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(fetchTaxRates());
      onChange(newTaxRate);
    } catch {
      setIsLoading(false);
      return;
    }

    setShowModal(false);
    setPercentageValue(0);
    setIsLoading(false);
    setFormTaxRate(initialTaxRate);
  };

  const handleTextFieldChange = useHandleTextFieldChange(
    setFormTaxRate,
    formTaxRate
  );

  const handleVendorChange = useCallback(
    (e: React.ChangeEvent<{}>, v: Vendor | null) => {
      setFormTaxRate((old) => ({ ...old, agencyId: v ? v.id : null }));
    },
    [setFormTaxRate]
  );

  const handleTaxChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const taxValue = event.target.value;

      if (formTaxRate.taxType === TaxType.percentage) {
        setFormTaxRate((old) => ({
          ...old,
          percentage: taxValue ? parseFloat(event.target.value) / 100 : 0,
        }));
        setPercentageValue(parseFloat(taxValue));
      } else {
        setFormTaxRate((old) => ({
          ...old,
          amount: taxValue ? parseFloat(taxValue) : null,
        }));
      }
    },
    [formTaxRate]
  );

  const handleTaxTypeChanged = useCallback(
    (e: any) => {
      const taxTypeValue =
        e.target.innerText === currencySymbol
          ? TaxType.flatRate
          : TaxType.percentage;
      setFormTaxRate((old) => ({ ...old, taxType: taxTypeValue }));
    },
    [currencySymbol]
  );

  return (
    <>
      <AutoComplete
        // TODO : RESTYLING : minor style adjustment needed for now until we use the FBOAutoSelect component
        style={{ marginTop: '0px', marginBottom: '0px' }}
        className={'redesign'}
        options={taxRatesType()}
        value={selectedTaxRate}
        disabled={isDisabled}
        getOptionLabel={(taxRate: TaxRate) => {
          if (!taxRate.id || taxRate.id < 0) {
            if (taxRate.name) {
              return `+ Add "${taxRate.name}"`;
            } else {
              return `+ Create New`;
            }
          }

          return taxRate.name || '';
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (!!params.inputValue) {
            filtered.unshift({
              ...initialTaxRate,
              name: params.inputValue || null,
            });
          }

          return filtered;
        }}
        onChange={handleChange}
        autoSelect={false}
        renderInput={(params) => {
          // params.InputProps.endAdornment is component with 2 children
          // first child is clear button, so we want to set it to null
          const endAdornment = params.InputProps.endAdornment as any;

          return (
            <TextField
              className={'redesign'}
              // we are omitting props that would overwrite our styling in TextField
              {..._.omit(params, 'variant', 'size', 'InputLabelProps')}
              label={label}
              placeholder={placeholder}
              error={error}
              required={required}
              helperText={helperText}
              InputProps={{
                ...params.InputProps,
                ...additionalInputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {endAdornment}
                  </React.Fragment>
                ),
              }}
              inputProps={{
                'data-qa': dataQa,
                ...params.inputProps,
              }}
            />
          );
        }}
        clearIcon={
          dataQa && (
            <CloseIcon
              fontSize="small"
              data-qa={`${dataQa}-autocomplete-clear-icon`}
            />
          )
        }
        popupIcon={
          dataQa && (
            <ArrowDropDownIcon
              fontSize="small"
              data-qa={`${dataQa}-autocomplete-dropdown-icon`}
            />
          )
        }
      />
      <Modal
        open={showModal}
        title="New Tax Rate"
        onCancelClicked={handleCloseModal}
        onApplyClicked={handleApplyModal}
        isLoadingContent={isLoading}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              className="redesign"
              variant="standard"
              name="name"
              label="Name"
              value={formTaxRate.name}
              onChange={handleTextFieldChange}
              required
              error={!!errors.name}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              className="redesign"
              variant="standard"
              name="code"
              label="Code"
              value={formTaxRate.code}
              onChange={handleTextFieldChange}
              required
              error={!!errors.code}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              onChange={handleVendorChange}
              value={selectedVendor}
              label="Vendor"
              options={vendors}
              getOptionLabel={(option) => option.name}
              required
              error={!!errors.agencyId}
            />
          </Grid>
          <Grid item xs={12}>
            <MultiFormatInput
              className="redesign"
              variant="standard"
              type="text"
              label="Amount"
              placeholder="Enter amount"
              decimalPlaces={formTaxRate.taxType === TaxType.percentage ? 3 : 2}
              name={
                formTaxRate.taxType === TaxType.percentage
                  ? 'percentage'
                  : 'amount'
              }
              value={
                formTaxRate.taxType === TaxType.flatRate
                  ? formTaxRate.amount
                  : percentageValue
              }
              onFormatChange={handleTaxTypeChanged}
              onChange={handleTaxChange}
              options={
                taxRateVariant === TaxRateVariants.Percentage
                  ? formatTypesPercentage
                  : formatTypesFlatRate(currencySymbol)
              }
              activeIndex={
                taxRateVariant !== TaxRateVariants.All
                  ? 0
                  : formTaxRate.taxType === TaxType.percentage
                  ? 0
                  : 1
              }
              required
            />
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default memo(TaxRatesAutocomplete);
