import { Location } from 'services/locations';
import { PermissionType } from 'services/permissions';

export const editLocationPermissions = (
  location: Location
): PermissionType[] => {
  if (location.deleted) {
    return [PermissionType.Undelete];
  }
  return location.id && location.id > 0
    ? [PermissionType.LocationEdit]
    : [PermissionType.LocationsCreate];
};
