import React, { memo, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';

import { CustomField } from 'services/customFields';
import { Modal } from 'ui/components/Modal/Modal';
import { ItemsTable } from 'ui/components/Table/ItemsTable';

import { CustomFieldsOrderModalProps } from './types';
import { useCustomFieldsOrderModalStyle } from './styled';
import { customFieldsOrderModalColumns } from './consts';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { IconNames } from 'ui/theme';

const CustomFieldsOrderModal: React.FC<CustomFieldsOrderModalProps> = (
  props
) => {
  const { open, customFields, onClose, onApply } = props;

  const [activeCustomFields, setActiveCustomFields] =
    useState<CustomField[]>(customFields);
  const [activeId, setActiveId] = useState<number | null>(null);

  const classes = useCustomFieldsOrderModalStyle();

  const activeCustomField = useMemo(
    () => activeCustomFields.find((l) => l.id === activeId) || null,
    [activeCustomFields, activeId]
  );

  const activeCustomFieldIndex = useMemo(
    () => activeCustomFields.findIndex((l) => l.id === activeId),
    [activeCustomFields, activeId]
  );

  const disabledUp = useMemo(
    () => activeCustomFieldIndex === -1 || activeCustomFieldIndex === 0,
    [activeCustomFieldIndex]
  );

  const disabledDown = useMemo(
    () =>
      activeCustomFieldIndex === -1 ||
      activeCustomFieldIndex === activeCustomFields.length - 1,
    [activeCustomFieldIndex, activeCustomFields]
  );

  useEffect(() => {
    setActiveCustomFields(customFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleApplyClicked = () => {
    onApply(activeCustomFields);
    setActiveId(null);
  };

  const handleCancelClicked = () => {
    onClose();
    setActiveId(null);
  };

  const handleMoveUpClicked = () => {
    if (!activeCustomField) {
      return;
    }

    // if first
    if (activeCustomFieldIndex < 1) {
      return;
    }

    const reorderedCustomFields = [
      ...activeCustomFields.slice(0, activeCustomFieldIndex - 1),
      activeCustomField,
      activeCustomFields[activeCustomFieldIndex - 1],
      ...activeCustomFields.slice(activeCustomFieldIndex + 1),
    ];

    setActiveCustomFields(
      reorderedCustomFields.map((c, i) => ({ ...c, lineNumber: i + 1 }))
    );
  };

  const handleMoveDownClicked = () => {
    if (!activeCustomField) {
      return;
    }

    // if last
    if (activeCustomFieldIndex === activeCustomFields.length - 1) {
      return;
    }

    const reorderedCustomFields = [
      ...activeCustomFields.slice(0, activeCustomFieldIndex),
      activeCustomFields[activeCustomFieldIndex + 1],
      activeCustomField,
      ...activeCustomFields.slice(activeCustomFieldIndex + 2),
    ];

    setActiveCustomFields(
      reorderedCustomFields.map((c, i) => ({ ...c, lineNumber: i + 1 }))
    );
  };

  return (
    <Modal
      open={open}
      onCancelClicked={handleCancelClicked}
      onApplyClicked={handleApplyClicked}
      applyLabel="Save order"
      title="Order Custom Fields"
      withoutDefaultPadding
      nestedScrollAreas
      maxWidth="sm"
      dataQa="custom-fields-order-modal"
    >
      <Box display="flex" height="100%" className={classes.content}>
        <Box overflow="auto" flex={1}>
          <ItemsTable
            data={activeCustomFields}
            columns={customFieldsOrderModalColumns}
            selectableItems={false}
            onItemClick={setActiveId}
            activeId={activeId}
            emptyTableText="No Custom Fields"
          />
        </Box>
        <Box className={classes.sidebar}>
          <>
            <FBOButton
              variant="primary"
              color="neutral"
              size="medium"
              icon={IconNames.IconArrowUp}
              disabled={disabledUp}
              onClick={handleMoveUpClicked}
              data-qa="order-modal-move-up"
              sx={{ marginBottom: '8px' }}
            />
            <FBOButton
              variant="primary"
              color="neutral"
              size="medium"
              icon={IconNames.IconArrowDown}
              disabled={disabledDown}
              onClick={handleMoveDownClicked}
              data-qa="order-modal-move-down"
            />
          </>
        </Box>
      </Box>
    </Modal>
  );
};

export default memo(CustomFieldsOrderModal);
