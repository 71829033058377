import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { colorPalette, themeRestyle } from 'ui/theme';

interface ViewerStyleProps {
  scale: number;
}

export const usePdfViewerStyles = makeStyles((theme: Theme) => ({
  htmlLayout: {
    padding: '30px',
    width: '8.5in',
    height: '11.0in',
    transformOrigin: 'top left',
    transform: (p: ViewerStyleProps) => `scale(${p.scale})`,
  },
  scrollHtml: {
    display: 'flex',
    flexDirection: 'column',
    background: '#FFF',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: '240px',
    top: 56,
    overflow: 'auto',
  },
  wrapper: {
    width: '100%',
    height: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    background: '#525659',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: '240px',
    top: 56,
    overflow: 'auto',
  },
  containerFullscreen: {
    position: 'fixed',
    right: 0,
    top: 0,
    zIndex: 9999999,
    '& .docContainer': {
      right: 0,
    },
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: 56,
    flexShrink: 0,
    background: themeRestyle.palette.background.default,
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-start',
    width: '100%',
    justifyContent: 'space-between',
    '& div': {
      display: 'flex',
      alignItems: 'center',
    },
    borderBottom: `1px solid #e2e8ee`,
  },
  toolbarTitle: {
    width: '20%',
  },
  toolbarNavigation: {
    width: '40%',
    justifyContent: 'center',
  },
  toolbarActions: {
    width: '40%',
    justifyContent: 'flex-end',
  },
  iconToolbar: {
    // TODO: remove !important
    // in the dev tools you can see some class overriding this but can't track it because it's minified
    fill: '#f1f1f1 !important',
  },
  labelToolbar: {
    color: colorPalette.grey,
  },
  documentContainer: {
    width: '100%',
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    userSelect: 'none',
  },
  document: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },
  downloadLabel: {
    marginLeft: theme.spacing(1),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.grey[500],
  },
  downloadButton: {
    paddingTop: theme.spacing(1.5),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(2),
    borderRadius: theme.spacing(4),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
}));
