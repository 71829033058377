import { createApiCall } from 'services/api';

export const clearEnvironment = async (): Promise<void> => {
  const response = await createApiCall(
    {
      path: '/v1/sandbox/clear_environment',
      method: 'POST',
    },
    {
      getMessage: {
        success: () => 'Test environment cleared.',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return response.data;
};

export const copyProductionEnvironment = async (): Promise<void> => {
  const response = await createApiCall(
    {
      path: '/v1/sandbox/copy_production_environment',
      method: 'POST',
    },
    {
      getMessage: {
        success: () => 'Copied production environment into test environment.',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return response.data;
};

export const populateTestEnvironment = async (): Promise<void> => {
  const response = await createApiCall(
    {
      path: '/v1/sandbox/populate_environment',
      method: 'POST',
    },
    {
      getMessage: {
        success: () => 'Populated test environment with dummy data.',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return response.data;
};
