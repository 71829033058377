import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableCell, Box, Typography, Radio } from '@mui/material';
import _ from 'lodash';

import { colorPalette } from 'ui/theme';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { SaleItem } from 'services/items/saleItems';
import { getUomById } from 'services/uoms';
import { ImageItemBox } from 'ui/components/Image/ImageItemBox';
import { useCurrencyFormatter } from 'helpers';

import { createActionBarOptions } from './consts';
import { SaleItemTableRowActions } from '../../consts';
import { editSaleItemPermissions } from '../../../../helpers';
import { showThumbnail } from 'services/thumbnail/redux';

import { activeUserHasPermission } from 'services/user/redux/selectors';
import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';

const SaleItemRow: React.FC<RenderCustomRowProps<SaleItem>> = (props) => {
  const { row, onAction, index } = props;

  const currencyFormatterSix = useCurrencyFormatter(2, 6);

  const defaultUom = useSelector(getUomById(row.defaultUomId));

  const formattedWHL = (row: SaleItem) => {
    const width = row.width ?? '-';
    const height = row.height ?? '-';
    const length = row.length ?? '-';

    const dimensions = `${width}x${height}x${length}`;

    let mass = '( )';
    if (row.weight && row.weightUnit) {
      mass = `(${row.weight} ${row.weightUnit})`;
    }

    return `${dimensions} ${mass}`;
  };

  const editPermissionSaleItem = editSaleItemPermissions(row);
  const hasPermissionToEditItem = useSelector(
    activeUserHasPermission(editPermissionSaleItem)
  );

  const dispatch = useDispatch();

  const handleOpenPreview = useCallback(
    (e) => {
      const imageUrl = _.get(row, 'defaultImage', '') as string;
      dispatch(showThumbnail(imageUrl));

      e.stopPropagation();
    },
    [dispatch, row]
  );

  const handleAction = useCallback(
    (actionType: SaleItemTableRowActions) => {
      if (onAction) {
        onAction({ type: actionType, itemIndex: index });
      }
    },
    [onAction, index]
  );

  return (
    <>
      <TableCell data-qa={`Name-${row.name}`}>
        <ImageItemBox
          name={_.get(row, 'name', null)}
          description={_.get(row, 'description', null)}
          source={_.get(row, 'defaultImage', null)}
          onClick={handleOpenPreview}
        />
      </TableCell>
      <TableCell data-qa={`UOM-${row.name}`}>
        <Typography>{defaultUom && defaultUom.name}</Typography>
      </TableCell>
      <TableCell data-qa={`W/H/L(weight)-${row.name}`}>
        <Typography>{formattedWHL(row)}</Typography>
      </TableCell>
      <TableCell data-qa={`Price-${row.name}`}>
        <Typography>{currencyFormatterSix(row.price || 0)}</Typography>
      </TableCell>
      <TableCell data-qa={`Default-${row.name}`}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Radio checked={row.defaultFlag} disabled />
          <FBOMenuButton
            variant="tertiary"
            items={createActionBarOptions(handleAction)}
            disabled={!hasPermissionToEditItem}
            data-qa="sale-item-three-dot-menu"
            style={{ color: colorPalette.redesign.contentSecondary }}
          />
        </Box>
      </TableCell>
    </>
  );
};

export default memo(SaleItemRow);
