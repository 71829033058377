import * as yup from 'yup';

export const IGNORE_ERROR = 'IGNORE';
export const phoneRegExp =
  /^((\+?[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?){1,2}?[0-9]{3,4}?[ -]?[0-9]{3,4}?$/gm;

// Scheme
export const yupSchema = yup.object().shape({
  name: yup.string().typeError('Enter name').required('Name is required'),
  type: yup.string().typeError('Enter type').required('Type is required'),
  address: yup.object().shape({
    phone: yup
      .string()
      .typeError(IGNORE_ERROR)
      .matches(phoneRegExp, 'Enter a valid phone number')
      .nullable(true),

    email: yup.string().email().nullable(true),
  }),
});

export const emailBlurValidation = yup.object().shape({
  address: yup.object().shape({
    email: yup.string().email().nullable(true),
  }),
});
