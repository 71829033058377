import React, { memo, useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';

import { Routes as SalesRoutes } from 'ui/modules/sales';
import { Routes as ItemRoutes } from 'ui/modules/materials';
import { fetchLowStock, fetchSalesOrderGeneral } from 'services/dashboard/api';
import { DataWithPagination } from 'services/api';
import { Pagination } from 'services/search';
import { LowStock, SalesOrderGeneral } from 'services/dashboard';

import { DashboardTable } from '../DashboardTable';
import DashboardMap from '../DashboardMap/DashboardMap';
import { DashboardGeneralProps } from './types';
import { LOW_STOCK_COLUMNS, OPEN_SALES_ORDERS_COLUMNS } from './consts';
import { dashboardInitialDataWithPagination, DATE_FORMAT } from '../../consts';
import { useCurrencyFormatter } from 'helpers';
import { concatRoute } from 'helpers/routes';

const DashboardGeneral: React.FC<DashboardGeneralProps> = ({
  fromStartDate,
  fromEndDate,
  salesByRegion,
  setSalesByRegion,
  nonAddressedOrder,
}) => {
  const [salesOrders, setSalesOrders] = useState<
    DataWithPagination<SalesOrderGeneral>
  >(dashboardInitialDataWithPagination);
  const [lowStock, setLowStock] = useState<DataWithPagination<LowStock>>(
    dashboardInitialDataWithPagination
  );

  const currencyFormatter = useCurrencyFormatter();

  useEffect(() => {
    getOpenSalesOrders(salesOrders.pagination);
    getLowStock(lowStock.pagination);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromStartDate, fromEndDate]);

  const getOpenSalesOrders = async (pagination: Pagination) => {
    if (!fromStartDate.isValid() || !fromEndDate.isValid()) {
      return;
    }

    try {
      const salesOrdersData = await fetchSalesOrderGeneral(
        pagination,
        fromStartDate.format(DATE_FORMAT),
        fromEndDate.format(DATE_FORMAT)
      );
      setSalesOrders(salesOrdersData);
    } catch {
      setSalesOrders(dashboardInitialDataWithPagination);
    }
  };

  const getLowStock = async (pagination: Pagination) => {
    if (!fromStartDate.isValid() || !fromEndDate.isValid()) {
      return;
    }

    try {
      const lowStockData = await fetchLowStock(
        pagination,
        fromStartDate.format(DATE_FORMAT),
        fromEndDate.format(DATE_FORMAT)
      );
      setLowStock(lowStockData);
    } catch {
      setLowStock(dashboardInitialDataWithPagination);
    }
  };

  const handleOpenSalesOrdersPaginationChange = (pagination: Pagination) => {
    setSalesOrders({ ...salesOrders, pagination });
    getOpenSalesOrders(pagination);
  };

  const handleLowStockPaginationChange = (pagination: Pagination) => {
    setLowStock({ ...lowStock, pagination });
    getLowStock(pagination);
  };

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Grid container spacing={2}>
        <Grid item lg={12} xl={6}>
          <DashboardMap
            title="Sales by Region"
            salesByRegion={salesByRegion}
            setSalesByRegion={setSalesByRegion}
            fromStartDate={fromStartDate}
            fromEndDate={fromEndDate}
            nonAddressedOrder={nonAddressedOrder}
          />
        </Grid>
        <Grid item lg={12} xl={6}>
          <DashboardTable
            title="Open Sales Orders"
            routeLink={`${concatRoute(
              SalesRoutes.SalesRouter,
              SalesRoutes.SalesOrderPage
            )}`}
            routeLinkLabel="Go to sales orders"
            data={salesOrders.data}
            pagination={salesOrders.pagination}
            columns={OPEN_SALES_ORDERS_COLUMNS(currencyFormatter)}
            selectableItems={false}
            onPaginationChange={handleOpenSalesOrdersPaginationChange}
            containerHeight={460}
          />
        </Grid>
      </Grid>
      <DashboardTable
        title="Low Stock"
        routeLink={`${concatRoute(
          ItemRoutes.MaterialsRouter,
          ItemRoutes.ItemsPage
        )}`}
        routeLinkLabel="Go to items"
        data={lowStock.data}
        pagination={lowStock.pagination}
        columns={LOW_STOCK_COLUMNS(currencyFormatter)}
        selectableItems={false}
        onPaginationChange={handleLowStockPaginationChange}
        containerHeight={460}
      />
    </Box>
  );
};

export default memo(DashboardGeneral);
