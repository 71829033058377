import React, { memo, useCallback, useMemo, useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';

import { TextField } from 'ui/components/TextField/TextField';
import {
  ItemsTable,
  useSelectedItemsChanges,
} from 'ui/components/Table/ItemsTable';
import { DatePickerWrapper } from 'ui/components/TextField/DatePickerWrapper';

import ReconcileWizardItemsRow from './ReconcileWizardItemsRow';
import ReconcileWizardItemsFooter from './ReconcileWizardItemsFooter';
import { ReconcileWizardItemsProps } from './types';
import { reconcileWizardItemsColumns } from './consts';
import { useGetIntlDateFormatString } from 'helpers';
import { useSelector } from 'react-redux';
import { getSettingsCompany } from 'services/settings/company';
import { PaymentTerm, getPaymentTerms } from 'services/paymentTerms';
import { calculateDueDate } from './helpers';
import { colorPalette, themeRestyle } from 'ui/theme';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';

const ReconcileWizardItems: React.FC<ReconcileWizardItemsProps> = (props) => {
  const {
    receipt,
    reconcile,
    billDateInputRef,
    setReconcile,
    setActiveReceipt,
    error,
    setReconcileReceiptItems,
  } = props;
  const paymentTerms = useSelector(getPaymentTerms).items;
  const [paymentTypeName, setPaymentTypeName] = useState('');
  useEffect(() => {
    paymentTerms.map((p) => {
      if (p.id === receipt.purchaseOrder?.paymentTermId && p.name)
        setPaymentTypeName(p.name);
    });
  }, [receipt, paymentTypeName]);
  const { homeCurrency, useMultiCurrency } = useSelector(getSettingsCompany);
  const homeCurrencyCode = (homeCurrency && homeCurrency.code) || 'USD';
  const activeMulticurrencyCode = _.get(reconcile, 'currency.code', null);
  const intlFormatDate = useGetIntlDateFormatString();
  const showMultiCurrency = useMemo(
    () =>
      useMultiCurrency &&
      activeMulticurrencyCode &&
      activeMulticurrencyCode !== homeCurrencyCode,
    [useMultiCurrency, activeMulticurrencyCode]
  );

  const grandTotalCost = useMemo(() => {
    const selected = reconcile.reconcileReceiptItems.filter((i) =>
      reconcile.selectedItems.includes(i.id || 0)
    );

    return selected.reduce((acc, i) => acc + (i.totalCost || 0), 0);
  }, [reconcile.reconcileReceiptItems, reconcile.selectedItems]);

  const dateScheduled = useMemo(() => {
    const date = _.get(receipt.purchaseOrder, 'dateScheduled', null);
    return date ? moment(date).format(intlFormatDate) : '-';
  }, [receipt.purchaseOrder, intlFormatDate]);

  const setSelectedItems = useCallback(
    (selected: React.SetStateAction<number[]>) => {
      if (typeof selected === 'function') {
        setReconcile((r) => ({
          ...r,
          selectedItems: selected(r.selectedItems),
        }));
        return;
      }

      setReconcile((r) => ({
        ...r,
        selectedItems: selected,
      }));
    },
    [setReconcile]
  );

  const handleSelectedChange = useSelectedItemsChanges(
    reconcile.selectedItems,
    setSelectedItems
  );

  const handleRefrenceNumberChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setReconcile((old) => ({ ...old, referenceNumber: e.target.value }));
      setActiveReceipt((old) => ({
        ...old,
        receiptItems: old.receiptItems.map((i) => ({
          ...i,
          referenceNumber: e.target.value,
        })),
      }));
    },
    [setReconcile, setActiveReceipt]
  );

  const handleDateChange = useCallback(
    (value: any) => {
      const date = value ? moment(value).toDate() : null;
      const selectedPaymentTerm: PaymentTerm | null =
        paymentTerms.find(
          (term) => term.id === receipt.purchaseOrder?.paymentTermId
        ) ?? null;

      if (date && selectedPaymentTerm) {
        const dueDate = calculateDueDate(date, selectedPaymentTerm);

        setReconcile((old) => ({
          ...old,
          billDate: date,
          dueDate: dueDate,
        }));
      } else if (date) {
        setReconcile((old) => ({
          ...old,
          billDate: date,
        }));
      }
    },
    [setReconcile, receipt.purchaseOrder?.paymentTermId, paymentTerms]
  );

  const handleDueDateChange = useCallback(
    (value: any) => {
      const date = value ? moment(value).toDate() : null;
      setReconcile((old) => ({
        ...old,
        dueDate: date,
      }));
    },
    [setReconcile]
  );
  return (
    <>
      <Box p={3} overflow="hidden" flexShrink={0}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <TextField
              className="redesign"
              variant="standard"
              label="Reference Number"
              placeholder="Enter Reference Number"
              name="referenceNumber"
              value={reconcile.referenceNumber}
              onChange={handleRefrenceNumberChange}
            />
          </Grid>
          <Grid item xs={2}>
            <DatePickerWrapper
              label="Bill Date"
              placeholder="Enter Bill Date"
              onChange={handleDateChange}
              value={reconcile.billDate}
              dataQa="reconcile-wizard-billdate"
              error={error}
              inputRef={billDateInputRef}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              className="redesign"
              variant="standard"
              label="Scheduled"
              value={dateScheduled}
              readOnly
              disabled
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              className="redesign"
              variant="standard"
              label="Payment Terms"
              value={paymentTypeName}
              readOnly
              disabled
            />
          </Grid>
          <Grid item xs={2}>
            <DatePickerWrapper
              label="Due Date"
              placeholder="Enter Due Date"
              onChange={handleDueDateChange}
              value={reconcile.dueDate}
            />
          </Grid>
        </Grid>
        {error && (
          <Grid item xs={12}>
            <Typography color="error">{error}</Typography>
          </Grid>
        )}
      </Box>

      <Box display="flex" flexGrow={1} flexDirection="column" overflow="hidden">
        <FBOTitleBar
          sx={{ marginLeft: themeRestyle.spacing(4) }}
          title="Select Items"
        />

        <ItemsTable
          data={reconcile.reconcileReceiptItems}
          columns={reconcileWizardItemsColumns(
            showMultiCurrency,
            activeMulticurrencyCode,
            homeCurrencyCode
          )}
          emptyTableText="No items"
          selectedItems={reconcile.selectedItems}
          onSelectedChange={handleSelectedChange}
          RenderCustomRow={ReconcileWizardItemsRow}
          RenderCustomFooter={ReconcileWizardItemsFooter}
          footerData={{ grandTotalCost, currency: reconcile.currency }}
          setData={setReconcileReceiptItems}
          tableLayoutFixed={!showMultiCurrency}
          sx={{
            borderRadius: '5px',
            border: `1px solid ${colorPalette.redesign.background3}`,
            borderTop: 'none',
            marginRight: themeRestyle.spacing(4),
            marginLeft: themeRestyle.spacing(4),
            maxHeight: '345px',
          }}
        />
      </Box>
    </>
  );
};

export default memo(ReconcileWizardItems);
