import { createApiCall } from 'services/api';
import { RedirectStoreTypes } from './types';

export const fetchIntegrationsRedirect = async (
  storeType: RedirectStoreTypes,
  params: string
): Promise<string> => {
  const res = await createApiCall({
    path: `/integrations/${storeType}/redirect${params}`,
    method: 'GET',
  })();

  return res.data;
};
