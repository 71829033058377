import { createFilterOptions, FilterOptionsState } from '@mui/material';

import {
  Customer,
  fetchCustomersAPI,
  initialCustomer,
} from 'services/customers';
import { Pagination } from 'services/search';

const filter = createFilterOptions<Customer>();

export const customersAutocompleteOptionLabel = (customer: Customer) => {
  if (!customer.id || customer.id < 0) {
    return `+ Add "${customer.name}"`;
  }

  return customer.name || '';
};

export const customersAutocompleteFilterOptions =
  (disableAdd: boolean) =>
  (options: Customer[], params: FilterOptionsState<Customer>) => {
    const filtered = filter(options, params);

    if (disableAdd) {
      return filtered;
    }

    if (!!params.inputValue) {
      filtered.unshift({
        ...initialCustomer,
        name: params.inputValue || null,
      });
    }

    return filtered;
  };

export const resolvedFetchCustomersFunction = (expands?: string[]) => {
  return (pagination: Pagination, searchValue: string | null) =>
    fetchCustomersAPI({
      pagination,
      expands: expands,
      quickSearchValue: searchValue,
    });
};
