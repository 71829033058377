import { ColumnTypes } from 'ui/components/Table/ItemsTable';
import { Column } from 'ui/components/Table/ItemsTable/types';
import { NewTrackingType } from 'services/settings/tracking';

export const SETTINGS_TAB_ITEM_COLUMNS: Column[] = [
  {
    title: 'Title',
    field: 'name',
    type: ColumnTypes.string,
  },
  {
    title: 'Description',
    field: 'description',
    type: ColumnTypes.string,
  },
  {
    title: 'Type',
    field: 'trackingDataType',
    type: ColumnTypes.string,
  },
];

export const defaultTrackingType: NewTrackingType = {
  name: null,
  description: null,
  trackingDataType: null,
};
