import React, { memo, useCallback, useMemo } from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';

import { ItemsAutocomplete } from 'ui/components/Autocomplete/ItemsAutocomplete';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import { DatePickerWrapper } from 'ui/components/TextField/DatePickerWrapper';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { Location } from 'services/locations';
import { Item } from 'services/items';
import { useHandleCheckboxChange } from 'services/forms/hooks';

import { ReportFiltersProps } from '../../types';
import { costingMethods, groupByOptions } from './consts';
import { CostingMethod, GroupByOption } from './types';

const InventoryValuationSummaryFilters: React.FC<ReportFiltersProps> = (
  props
) => {
  const { parameters, setParameters, errors } = props;

  const selectedGroupBy = useMemo(() => {
    return groupByOptions.find((o) => o.type === parameters.groupBy) || null;
  }, [parameters]);

  const selectedCostingMethod = useMemo(() => {
    return (
      costingMethods.find((o) => o.type === parameters.costingMethod) || null
    );
  }, [parameters]);

  const handleGroupByChange = useCallback(
    (e: any, group: GroupByOption | null) => {
      setParameters((old) => ({ ...old, groupBy: group ? group.type : null }));
    },
    [setParameters]
  );

  const handleCostingMethodChange = useCallback(
    (e: any, costing: CostingMethod | null) => {
      setParameters((old) => ({
        ...old,
        costingMethod: costing ? costing.type : null,
      }));
    },
    [setParameters]
  );

  const handleLocationChange = useCallback(
    (location: Location | null) => {
      setParameters((old) => ({
        ...old,
        locationId: location ? location.id : null,
      }));
    },
    [setParameters]
  );

  const handleItemChange = useCallback(
    (selectedItem: Item | null) => {
      setParameters((old) => ({
        ...old,
        itemId: selectedItem ? selectedItem.id : null,
      }));
    },
    [setParameters]
  );

  const handleSelectAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setParameters((old) => ({
        ...old,
        showLocationDetails: checked,
        selectAllOptional: checked,
      }));
    },
    [setParameters]
  );

  const handleCheckboxChange = useHandleCheckboxChange(setParameters);

  const handleDateChange = useCallback(
    (date: string | null | undefined) => {
      if (!date) {
        setParameters((old) => ({ ...old, dateTo: null }));
        return;
      }
      const newDate = moment(date);
      setParameters((old) => ({
        ...old,
        dateTo: newDate.format('YYYY-MM-DD'),
      }));
    },
    [setParameters]
  );
  // TODO : RESTYLING : Remove box wrappers around inputs after FF removal

  return (
    <>
      <Box px={2} pb={0} pt="16px">
        <FormControlLabel
          className="redesign"
          control={
            <Checkbox
              className="redesign"
              checked={Boolean(parameters.selectAllOptional)}
              onChange={handleSelectAll}
              color="primary"
            />
          }
          label="Select All Optional"
        />
      </Box>
      <Accordion square defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <b>Optional</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%">
            <Box>
              <DatePickerWrapper
                label="Date To"
                name="dateTo"
                value={parameters.dateTo}
                onChange={handleDateChange}
                fullWidth
                error={!!errors.dateTo}
              />
            </Box>
            <Box>
              <Autocomplete
                label="Group By"
                options={groupByOptions}
                placeholder="Group By"
                getOptionLabel={(i) => i.title}
                value={selectedGroupBy}
                onChange={handleGroupByChange}
                required
                fullWidth
              />
            </Box>
            <Box>
              <Box>
                <LocationsAsyncAutocomplete
                  label="Location"
                  value={parameters.locationId}
                  placeholder="Select location"
                  companyWide={false}
                  onChange={handleLocationChange}
                />
              </Box>
            </Box>
            <Box>
              <ItemsAutocomplete
                label="Item"
                placeholder="Select item"
                onChange={handleItemChange}
                value={parameters.itemId}
              />
            </Box>
            <Box>
              <Autocomplete
                label="Costing Method"
                options={costingMethods}
                placeholder="Costing Method"
                getOptionLabel={(i) => i.title}
                value={selectedCostingMethod}
                onChange={handleCostingMethodChange}
                required
                fullWidth
              />
            </Box>
            <FormControlLabel
              className="redesign"
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(parameters.showLocationDetails)}
                  onChange={handleCheckboxChange}
                  name="showLocationDetails"
                  color="primary"
                />
              }
              label="Show Location Details"
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default memo(InventoryValuationSummaryFilters);
