import React, { memo, useCallback } from 'react';
import { Checkbox, TableCell } from '@mui/material';
import { colorPalette } from 'ui/theme';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { CustomerCarrierAccount } from 'services/customers';

import { CarrierAccountsTabAction, createActionBarOptions } from './consts';

import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';

const CarrierAccountRow: React.FC<
  RenderCustomRowProps<CustomerCarrierAccount>
> = (props) => {
  const { row: carrierAccount, onAction, index } = props;

  const handleAction = useCallback(
    (actionType: CarrierAccountsTabAction) => {
      if (onAction) {
        onAction({ type: actionType, value: index });
      }
    },
    [onAction, index]
  );

  return (
    <>
      <TableCell>{carrierAccount.name}</TableCell>
      <TableCell>{carrierAccount.carrier}</TableCell>
      <TableCell>{carrierAccount.accountNumber}</TableCell>
      <TableCell>{carrierAccount.billingType}</TableCell>
      <TableCell>{carrierAccount.billingCountry}</TableCell>
      <TableCell>
        <Checkbox
          checked={Boolean(carrierAccount.defaultFlag)}
          name="defaultFlag"
          color="primary"
          disabled={true}
        />
      </TableCell>
      <TableCell padding="none">
        <FBOMenuButton
          variant="tertiary"
          items={createActionBarOptions(handleAction)}
          data-qa="carrier-account-row-3-dot-menu-button"
          style={{ color: colorPalette.redesign.contentSecondary }}
        />
      </TableCell>
    </>
  );
};

export default memo(CarrierAccountRow);
