import React, { memo } from 'react';
import { Box } from '@mui/material';

import { ItemsTable } from 'ui/components/Table/ItemsTable';

import { ReturnLabelsTabProps } from './types';
import { RETURNLABELS_ITEMS_COLUMNS } from '../GeneralTab/consts';
import { ReturnLabelRow } from './components/ReturnLabelRow';

const ReturnLabelsTab: React.FC<ReturnLabelsTabProps> = (props) => {
  const { activeShip } = props;

  return (
    <>
      <Box display="flex" height="100%" width="100%" flexDirection="column">
        <Box display="flex" flexGrow={1} flexDirection="column"></Box>

        <ItemsTable
          columns={RETURNLABELS_ITEMS_COLUMNS}
          data={activeShip.shipCartonList}
          RenderCustomRow={ReturnLabelRow}
          selectableItems={false}
        />
      </Box>
    </>
  );
};

export default memo(ReturnLabelsTab);
