import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import _ from 'lodash';

import { Pagination } from 'services/search';
import {
  getSerialItemTrackingNextValue,
  ItemInventoryEvents,
  SerialRow as SerialRowType,
} from 'services/inventory';
import {
  ItemsTable,
  useSelectedItemsChanges,
} from 'ui/components/Table/ItemsTable';

import { SerialTableProps } from './types';
import { initialPagination } from './consts';
import { SerialRow, SerialHeader } from './components';
import {
  transformToColumns,
  clearSerialRow,
  removeSurplusSerialRows,
  addEmptySerialRows,
  getSerialsFromFile,
  autoAssignHandler,
} from './helpers';
import { SerialImportModal } from './components/SerialImportModal';

const SerialTable: React.FC<SerialTableProps> = (props) => {
  const {
    serialList,
    itemTrackingTypes,
    setSerialList = _.noop,
    minSerialQuantity = 0,
    selectedRows = [],
    onSelectedRowsChanged = _.noop,
    title = '',
    withoutTitleBar = false,
    status,
    customStatus,
    customStatusStyle,
    emptyTableText,
    canEditRow = false,
    canClearRow = false,
    canSelectRow = false,
    disableAutoAssign = false,
    allowSerialNumberImport = false,
    rowErrors = [],
    isLoading,
    setIsLoading,
    quantity,
    item,
    setItem,
    eventType,
    headerMargin,
    sx = {},
    meta: { setInitialSelectedSerialRows, resetFlag = false } = {},
  } = props;

  const [pagination, setPagination] = useState<Pagination>(initialPagination);
  const [search, setSearch] = useState<string | null>(null);
  const [activeFile, setActiveFile] = useState<File | null>(null);
  const [activeTrackingTypeId, setActiveTrackingTypeId] = useState<
    number | null
  >(null);
  const [autoAssignModalVisible, setAutoAssignModalVisible] = useState(false);
  const initialRef = useRef(selectedRows);
  if (resetFlag) setInitialSelectedSerialRows(initialRef.current);
  const handleSelectedItemsChanges = useSelectedItemsChanges(
    selectedRows,
    onSelectedRowsChanged
  );

  const filteredSerialList = useMemo(() => {
    if (!search) {
      return serialList;
    }

    return serialList.filter((row) => {
      return Object.values(row.serialNumbers).some(
        (number) => number && number.includes(search)
      );
    });
  }, [serialList, search]);

  const columns = useMemo(
    () => transformToColumns(itemTrackingTypes),
    [itemTrackingTypes]
  );

  const paginatedSerialList = useMemo(
    () =>
      filteredSerialList.slice(
        (pagination.page - 1) * pagination.pageSize,
        pagination.pageSize * pagination.page
      ),
    [pagination, filteredSerialList]
  );

  const handleSelectAllEnter = useCallback(() => {
    const newSelectedItems = paginatedSerialList.map((d) => d.id);
    handleSelectedItemsChanges(newSelectedItems);
  }, [paginatedSerialList, handleSelectedItemsChanges]);

  useEffect(() => {
    setPagination((p) => ({
      ...p,
      pageSize: pagination.pageSize,
      totalRows: filteredSerialList.length,
    }));
  }, [filteredSerialList, pagination.pageSize]);

  useEffect(() => {
    // Populate rows when minSerialQuantity is changed and update pagination
    if (!minSerialQuantity) {
      return;
    }
    const serialDiff = minSerialQuantity - serialList.length;

    if (serialDiff === 0) {
      // No need to do anything
      return;
    }

    if (serialDiff < 1) {
      // Remove rows
      const reducedSerialList = removeSurplusSerialRows(
        serialList,
        quantity || serialList.length,
        canSelectRow
      );
      setSerialList(reducedSerialList);
      setPagination({
        ...initialPagination,
        page: pagination.page,
        pageSize: pagination.pageSize,
        totalRows: reducedSerialList.length,
      });
      return;
    }

    // Add empty rows
    const extendedSerialList = addEmptySerialRows(
      serialList,
      Math.abs(serialDiff),
      itemTrackingTypes
    );
    setSerialList(extendedSerialList);
    setPagination({
      ...initialPagination,
      pageSize: pagination.pageSize,
      totalRows: extendedSerialList.length,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minSerialQuantity]);

  const handleDeleteOrClearRow = useCallback(
    (index) => {
      if (minSerialQuantity >= serialList.length) {
        // Clear values of the rows
        const emptySerialRow = clearSerialRow(index, serialList);
        setSerialList([
          ...serialList.slice(0, index),
          emptySerialRow,
          ...serialList.slice(index + 1),
        ]);
        return;
      }

      // Delete row
      setSerialList([
        ...serialList.slice(0, index),
        ...serialList.slice(index + 1),
      ]);
    },
    [serialList, setSerialList, minSerialQuantity]
  );

  const handleSerialListChange = useCallback(
    (changedPage: SerialRowType[]) => {
      setSerialList((sl: SerialRowType[]) => [
        ...sl.slice(0, (pagination.page - 1) * pagination.pageSize),
        ...changedPage,
        ...sl.slice(pagination.pageSize * pagination.page),
      ]);
    },
    [pagination, setSerialList]
  );

  const handleAutoAssign = useCallback(
    async (trackingId: number, trackingTypeId: number) => {
      setIsLoading && setIsLoading(true);
      const index =
        item?.itemTrackingTypeList.findIndex(
          (t) => t.trackingTypeId === trackingTypeId
        ) || 0;
      try {
        if (trackingId < 0) {
          const d = autoAssignHandler(
            serialList.length,
            itemTrackingTypes[0].nextValue
          );
          setSerialList(d);

          let value = '0';
          if (d) {
            value = d[d?.length - 1].serialNumbers['3'];
          }

          if (setItem)
            setItem((prevItem) => ({
              ...prevItem,
              itemTrackingTypeList: [
                ...prevItem.itemTrackingTypeList.slice(0, index),
                {
                  ...prevItem.itemTrackingTypeList[index],
                  nextValue: value.toString(),
                },
                ...prevItem.itemTrackingTypeList.slice(index + 1),
              ],
            }));
        } else {
          const value = await getSerialItemTrackingNextValue(
            trackingId,
            serialList.length
          );

          setSerialList((old: SerialRowType[]) =>
            old.map((i, index) => ({
              ...i,
              serialNumbers: {
                ...i.serialNumbers,
                [trackingTypeId]: value[index],
              },
            }))
          );
        }
      } catch {
        // Ignore error
      }
      setIsLoading && setIsLoading(false);
      setAutoAssignModalVisible(true);
    },
    [serialList, setSerialList, setIsLoading]
  );

  const handleModalApplyClicked = useCallback(() => {
    if (!activeFile || !activeTrackingTypeId) {
      return;
    }

    getSerialsFromFile(activeFile).then((serials) => {
      setSerialList((old: SerialRowType[]) =>
        old.map((i, index) => ({
          ...i,
          serialNumbers: {
            ...i.serialNumbers,
            [activeTrackingTypeId]: (serials as string[])[index],
          },
        }))
      );
    });

    setActiveFile(null);
    setActiveTrackingTypeId(null);
  }, [activeFile, activeTrackingTypeId, setSerialList]);

  const handleModalCancelClicked = useCallback(() => {
    setActiveTrackingTypeId(null);
    setActiveFile(null);
  }, [setActiveTrackingTypeId]);
  const disableCommittedSerials = eventType === ItemInventoryEvents.Remove;
  return (
    <>
      {!withoutTitleBar && (
        <SerialHeader
          title={title}
          status={status}
          customStatus={customStatus}
          customStatusStyle={customStatusStyle}
          itemTrackingTypes={itemTrackingTypes}
          handleAutoAssign={handleAutoAssign}
          handleSelectAllEnter={handleSelectAllEnter}
          disableAutoAssign={disableAutoAssign}
          canSelectRow={canSelectRow}
          search={search}
          allowSerialNumberImport={allowSerialNumberImport}
          setActiveTrackingTypeId={setActiveTrackingTypeId}
          setSearch={setSearch}
          isLoading={isLoading}
          autoAssignModalVisible={autoAssignModalVisible}
          setAutoAssignModalVisible={setAutoAssignModalVisible}
          sx={{ margin: headerMargin, padding: '0 !important' }}
        />
      )}
      <ItemsTable
        columns={columns}
        data={paginatedSerialList}
        setData={handleSerialListChange}
        RenderCustomRow={SerialRow}
        meta={{
          canEditRow,
          canClearRow,
        }}
        onAction={handleDeleteOrClearRow}
        pagination={pagination}
        onPaginationChange={setPagination}
        selectedItems={selectedRows}
        onSelectedChange={handleSelectedItemsChanges}
        selectableItems={canSelectRow}
        containerHeight={serialList.length > 11 ? 700 : undefined}
        emptyTableText={emptyTableText || 'Add new rows by pressing "Add Rows"'}
        tableBordered={false}
        rowErrors={rowErrors}
        dataQa="serial-table"
        showEnhancedTablePaginationActions={true}
        isCycleCountOrScrap={disableCommittedSerials}
        sx={sx}
      />

      <SerialImportModal
        open={!!activeTrackingTypeId}
        activeFile={activeFile}
        handleModalApplyClicked={handleModalApplyClicked}
        handleModalCancelClicked={handleModalCancelClicked}
        setActiveFile={setActiveFile}
      />
    </>
  );
};

export default React.memo(SerialTable);
