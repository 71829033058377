import { TaxRate, TaxType } from './types';

export const initialTaxRate: TaxRate = {
  id: null,
  agency: null,
  agencyId: null,
  amount: null,
  code: null,
  deleted: false,
  dateCreated: null,
  dateLastModified: null,
  name: null,
  percentage: null,
  taxType: TaxType.percentage,
  version: null,
  channel: null,
  mappedTaxName: null,
  channelTaxRate: null,
  accountingId: null,
  channelTaxType: null,
};
