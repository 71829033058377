import React from 'react';
import { TableCell } from '@mui/material';

import { Ledger } from 'services/ledgers';
import { useCurrencyFormatter } from 'helpers';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';

const PageCustomRow: React.FC<RenderCustomRowProps<Ledger>> = (props) => {
  const { row, meta } = props;
  const currencyFormatter = useCurrencyFormatter();

  return (
    <>
      <TableCell data-qa={`Name- ${row.id}`}>{row.accountName}</TableCell>
      {!meta.activeItemId && (
        <TableCell data-qa={`Type- ${row.id}`}>{row.accountType}</TableCell>
      )}
      <TableCell data-qa={`Balance- ${row.id}`}>
        {currencyFormatter(row.endBalance || 0)}
      </TableCell>
    </>
  );
};

export default React.memo(PageCustomRow);
