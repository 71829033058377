import { Receipt, ReceiptItem } from 'services/receiving';
import { FetchSearchResultFunc } from 'ui/components/Page/WithSearchResults';

export interface ReconcileWizardProps {
  show: boolean;
  billDateInputRef?: any;
  receipt: Receipt;
  setActiveReceipt: React.Dispatch<React.SetStateAction<Receipt>>;
  activeReceiptItem: ReceiptItem;
  onClose(): void;
  fetchSearchResult: FetchSearchResultFunc;
}

export interface ReconcileWizardStep {
  id: number;
  checked: boolean;
  title: string;
  description: string;
}

export enum WizardSteps {
  SelectItems,
  Expenses,
  ItemsToReconcile,
  Summary,
}
