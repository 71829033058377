import React, { memo, useCallback, useMemo, useState } from 'react';

import {
  ItemsTable,
  useSelectedItemsChanges,
} from 'ui/components/Table/ItemsTable';
import { colorPalette } from 'ui/theme';
import { Pagination } from 'services/search';
import { PermissionType } from 'services/permissions';
import { ReportId } from 'services/reports';
import { ReportsModal } from 'ui/components/Modal/ReportsModal';
import { arrayHasAllDeletedObjects } from 'helpers';

import { CustomersSearchResultsProps } from './types';
import { default as CustomersRow } from './CustomersRow';
import { CUSTOMER_COLUMNS, CUSTOMER_COLUMNS_ALWAYS_ON } from '../../consts';
import { createCustomersSearchActionBarOptions } from './consts';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';
import { useSelector } from 'react-redux';
import { getActiveUser } from 'services/userV2';
import { useFlags } from 'helpers/useFlags';

const CustomersSearchResults: React.FC<CustomersSearchResultsProps> = (
  props
) => {
  const {
    activeCustomerId,
    handleCustomerClick,
    onAddNewPress,
    customers,
    activeDate,
    isLoadingCustomers,
    pagination,
    onPaginationChange,
    selectedItems,
    setSelectedItems,
    onDeleteClicked,
    onAction,
    onCustomerPageAction,
  } = props;

  const activeUser = useSelector(getActiveUser);
  const flags = useFlags();
  const csvDisabled = flags.newUserEndpoint && !activeUser.user?.admin;

  const [showReportModal, setShowReportModal] = useState(false);

  const allDeletedCustomers = useMemo(
    () => arrayHasAllDeletedObjects(customers),
    [customers]
  );

  const resolvedColumns = activeCustomerId
    ? CUSTOMER_COLUMNS_ALWAYS_ON
    : CUSTOMER_COLUMNS(activeDate);

  const handlePaginationChange = (newPagination: Pagination) => {
    onPaginationChange(newPagination);
  };

  const handleSelectClick = useSelectedItemsChanges(
    selectedItems,
    setSelectedItems
  );

  const handleAction = useCallback(
    (action: any) => {
      onAction(action.type, action.value);
    },
    [onAction]
  );

  return (
    <>
      <FBOTitleBar title="Customers">
        {selectedItems.length > 0 && !activeCustomerId && (
          <FBOButton
            style={{ marginRight: '8px' }}
            variant="secondary"
            color="negative"
            size="medium"
            icon="TrashCan"
            permissions={[PermissionType.CustomersDelete]}
            onClick={onDeleteClicked}
            data-qa="customers-delete-button"
          >
            Delete
          </FBOButton>
        )}
        {activeCustomerId !== -1 && (
          <FBOButton
            style={{ marginRight: '8px' }}
            variant={!activeCustomerId ? 'primary' : 'secondary'}
            color="positive"
            size="medium"
            icon="FBOAddCircle"
            onClick={onAddNewPress}
            data-qa="customer-new"
            permissions={[PermissionType.CustomersCreate]}
          >
            {!activeCustomerId ? 'Add New' : ''}
          </FBOButton>
        )}
        <FBOMenuButton
          variant="secondary"
          items={createCustomersSearchActionBarOptions(
            () => setShowReportModal(true),
            onCustomerPageAction,
            customers.length,
            csvDisabled
          )}
          data-qa="customers-three-dot-menu"
          style={{ color: colorPalette.redesign.contentSecondary }}
        />
      </FBOTitleBar>

      <ItemsTable
        data={customers}
        columns={resolvedColumns}
        activeId={activeCustomerId}
        onItemClick={handleCustomerClick}
        onSelectedChange={handleSelectClick}
        selectedItems={selectedItems}
        selectableItems={!activeCustomerId}
        isLoadingData={isLoadingCustomers}
        pagination={pagination}
        disableSelection={allDeletedCustomers}
        onPaginationChange={handlePaginationChange}
        RenderCustomRow={CustomersRow}
        onAction={handleAction}
        dataQa="customer-table"
        tableLayoutFixed
        emptyTableText={
          activeCustomerId
            ? 'NO CUSTOMERS AVAILABLE'
            : "ADD NEW ENTRY BY PRESSING 'ADD NEW'"
        }
        showEnhancedTablePaginationActions={!Boolean(activeCustomerId)}
      />
      <ReportsModal
        isOpen={showReportModal}
        reportId={ReportId.CustomerHistory}
        params={{}}
        onClose={() => setShowReportModal(false)}
        autoGenerate
      />
    </>
  );
};

export default memo(CustomersSearchResults);
