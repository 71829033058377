import React, {
  useState,
  memo,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import { Grid } from '@mui/material';
import _ from 'lodash';

import { BundleItem, initialBundleItem } from 'services/items';
import { Modal } from 'ui/components/Modal/Modal';
import { SaleItem } from 'services/items/saleItems';
import { SaleItemsAutocomplete } from 'ui/components/Autocomplete/SaleItemsAutocomplete';
import { validateYup, Errors } from 'services/forms/validation';
import { MultiFormatInput } from 'ui/components/TextField/MultiFormatInput';
import {
  formatTypes,
  stopInputPropagation,
  useGetCurrencySymbol,
} from 'helpers';
import { DiscountTypes } from 'services/salesOrders';
import { TextFieldQuantity } from 'ui/components/TextField/TextFieldQuantity';
import { EACH_UOM_ID } from 'services/uoms';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';

import { BundleItemModalProps } from './types';
import { calculateBundleItemTotalPrice } from './helpers';
import { yupBundleItemSchema } from '../../../../validations';
import { editItemPermissions } from '../../../../helpers';
import { SaleItemOption } from './';
import { itemHasSerialTracking } from 'services/inventory';

const BundleItemModal: React.FC<BundleItemModalProps> = (props) => {
  const {
    show,
    item,
    bundleItem,
    setBundleItem,
    onCancelClicked,
    onApplyClicked,
  } = props;

  const oldItem = useRef<BundleItem>(initialBundleItem);
  const currencySymbol = useGetCurrencySymbol();

  const editPermissions = editItemPermissions(item);

  const [validationErrors, setValidationErrors] = useState<Errors>({});
  const [discountValue, setDiscountValue] = useState<number | null>(null);

  const saleItemPrice = _.get(bundleItem, 'saleItem.price', 0) || 0;
  const defaultUom = _.get(bundleItem, 'saleItem.defaultUom', null);
  const defaultUomId = _.get(bundleItem, 'saleItem.defaultUomId', null);

  const removedSaleItemIds = useMemo(
    () =>
      item.bundleItems
        .filter(
          (i) =>
            !_.isNull(i.saleItemId) &&
            i.saleItemId !== oldItem.current.saleItemId
        )
        .map((bi) => bi.saleItemId!),
    // eslint-disable-next-line
    [item.bundleItems, oldItem.current.saleItemId]
  );

  useEffect(() => {
    if (show) {
      oldItem.current = bundleItem;
      const discount =
        bundleItem.discountType === DiscountTypes.FlatRate
          ? bundleItem.discountFlatAmount
          : (bundleItem.discountPercent || 0) * 100;

      setDiscountValue(discount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const handleApplyClicked = useCallback(() => {
    const isValid = validateYup(
      bundleItem,
      yupBundleItemSchema,
      setValidationErrors
    );
    if (isValid) {
      onApplyClicked();
    }
  }, [bundleItem, onApplyClicked]);

  const handleResetClicked = useCallback(() => {
    setBundleItem(oldItem.current);
  }, [oldItem, setBundleItem]);

  const handleSaleItemChange = useCallback(
    (saleItem: SaleItem | null) => {
      setBundleItem((old) => ({
        ...old,
        saleItem: saleItem,
        price: saleItem ? saleItem.price : 0,
        saleItemId: saleItem ? saleItem.id : null,
      }));
    },
    [setBundleItem]
  );

  const handleQuantityChange = (value: number | null) => {
    setBundleItem((old) => ({ ...old, quantity: value }));
  };

  const handleDiscountTypeChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setBundleItem((old) => ({
        ...old,
        discountType:
          e.target.innerText === '%'
            ? DiscountTypes.Percent
            : DiscountTypes.FlatRate,
      }));
    },
    [setBundleItem]
  );

  const handleMarkupChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const parsedValue = event.target.value
        ? parseFloat(event.target.value)
        : null;
      switch (bundleItem.discountType) {
        case DiscountTypes.FlatRate:
          setBundleItem((old) => ({
            ...old,
            discountFlatAmount: parsedValue,
            discountPercent: null,
            price: saleItemPrice - (parsedValue || 0),
          }));
          break;
        case DiscountTypes.Percent:
          setBundleItem((old) => ({
            ...old,
            discountPercent: parsedValue ? parsedValue / 100 : null,
            discountFlatAmount: null,
            price: saleItemPrice * (1 - (parsedValue || 0) / 100),
          }));
          break;
      }
      setDiscountValue(parsedValue);
    },
    [saleItemPrice, bundleItem.discountType, setBundleItem]
  );

  const renderOption = useCallback((props: object, saleItem: SaleItem) => {
    if (!saleItem.id || saleItem.id < 0) {
      return `+ Add "${saleItem.name}"`;
    }
    return <SaleItemOption option={saleItem} {...props} />;
  }, []);
  const isSerialTracked = useMemo(() => {
    if (bundleItem.saleItem?.item) {
      return itemHasSerialTracking(bundleItem.saleItem.item);
    }
    return false;
  }, [bundleItem.saleItem]);

  const applyLabel = () => {
    const done = 'Done';
    const add = 'Add';

    return bundleItem.id! > 0 ? done : add;
  };

  return (
    <Modal
      open={show}
      title={bundleItem.id! > 0 ? 'Edit Bundle Item' : 'Add Bundle Item'}
      applyLabel={applyLabel()}
      onResetClicked={handleResetClicked}
      maxWidth="md"
      onCancelClicked={onCancelClicked}
      onApplyClicked={handleApplyClicked}
      isLoading={false}
      permissions={editPermissions}
      dataQa="bundle-item-modal"
    >
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <SaleItemsAutocomplete
            label="Sale Item"
            placeholder="Select Sale Item"
            value={bundleItem.saleItem}
            onChange={handleSaleItemChange}
            error={!!validationErrors.saleItemId}
            removedIds={removedSaleItemIds}
            required
            renderOption={renderOption}
            dataQa="bundle-item-sale-item"
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldQuantity
            label="Quantity"
            name="quantity"
            autoComplete="nope"
            fullWidth
            placeholder="Quantity"
            value={bundleItem.quantity}
            uoms={defaultUom ? [defaultUom] : []}
            onTextChange={handleQuantityChange}
            selectedUomId={defaultUomId || EACH_UOM_ID}
            error={!!validationErrors.quantity}
            required
            dataQa="bundle-item-quantity"
            isDecimal={!isSerialTracked}
          />
        </Grid>
        <Grid item xs={6}>
          <MultiFormatInput
            className="redesign"
            variant="standard"
            type="text"
            label="Discount"
            placeholder="Enter discount"
            value={discountValue}
            onFormatChange={handleDiscountTypeChanged}
            onClick={stopInputPropagation}
            onChange={handleMarkupChange}
            options={formatTypes(currencySymbol)}
            activeIndex={
              bundleItem.discountType === DiscountTypes.FlatRate ? 1 : 0
            }
            dataQa="bundle-item-discount"
          />
        </Grid>
        <Grid container item xs={6} spacing={1}>
          <Grid item xs={6}>
            <CurrencyField
              readOnly
              name="price"
              label="Price"
              allowNegative
              fullWidth
              value={_.get(bundleItem, 'saleItem.price', 0) || 0}
              dataQa="bundle-item-price"
            />
          </Grid>
          <Grid item xs={6}>
            <CurrencyField
              name="total"
              label="Total"
              readOnly
              allowNegative
              fullWidth
              value={calculateBundleItemTotalPrice(bundleItem, saleItemPrice)}
              dataQa="bundle-item-total"
            />
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default memo(BundleItemModal);
