import React, { memo, useMemo } from 'react';
import _ from 'lodash';
import { TableCell, Box, Typography } from '@mui/material';

import { IconNames } from 'ui/theme';
import { LocationType } from 'services/locations';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { InventoryRow, ItemInventoryEvents } from 'services/inventory';
import { PermissionType } from 'services/permissions';
import { Uom } from 'services/uoms';

import { InventoryRowActionTypes } from './types';
import { useInventoryTableRowStyle } from './styled';

import { useFlags } from 'helpers/useFlags';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { StatusLabel } from 'ui/components/Status/StatusLabel';

const InventoryTableSubRow: React.FC<RenderCustomRowProps<InventoryRow>> = (
  props
) => {
  const { row, onAction, meta } = props;

  const flags = useFlags();

  const rowClasses = useInventoryTableRowStyle();

  const depth: number = _.get(row, 'location.depth');
  const qtyIncoming =
    row.qtyIncomingOnSalesOrders + row.qtyIncomingOnPurchaseOrders;
  const qtyAllocated =
    row.allocatedOnPurchaseOrders + row.allocatedOnSalesOrders;

  const locationType: LocationType | null = _.get(row, 'location.type', null);

  const notLockedType = locationType !== LocationType.Locked;

  const uomAbbreviation: string = useMemo(() => {
    const uom: Uom = _.get(meta, 'uom');
    return uom ? uom.abbreviation! : '';
  }, [meta]);

  const handleApplyClicked =
    (
      eventType: ItemInventoryEvents,
      locationId: number | null,
      confirmButtonRed: boolean = false
    ) =>
    (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      ev.stopPropagation();
      if (onAction) {
        onAction({
          actionType: InventoryRowActionTypes.InventoryEvent,
          eventType,
          locationId,
          confirmButtonRed,
        });
      }
    };

  return (
    <>
      <TableCell>
        <Typography>{row.location ? row.location.displayName : ''}</Typography>
      </TableCell>
      <TableCell>
        <StatusLabel
          status={locationType}
          data-qa={`location-type-${locationType}`}
        />
      </TableCell>
      <TableCell>
        <Typography>
          {row.totalQty}
          {` ${uomAbbreviation}`}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {qtyAllocated > 0 || depth === 0
            ? `${qtyAllocated} ${uomAbbreviation}`
            : '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {notLockedType ? `${row.committedQty} ${uomAbbreviation}` : '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {notLockedType ? `${row.availableQty} ${uomAbbreviation}` : '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {(notLockedType && qtyAllocated > 0) || depth === 0
            ? `${qtyIncoming} ${uomAbbreviation}`
            : '-'}
        </Typography>
      </TableCell>
      {flags.showInventoryShortValues && (
        <TableCell>
          <Typography
            className={row.shortQty ? rowClasses.warningRowValue : ''}
          >
            {notLockedType ? `${row.shortQty} ${uomAbbreviation}` : '-'}
          </Typography>
        </TableCell>
      )}
      <TableCell>
        <Box display="flex" justifyContent="flex-end">
          <FBOButton
            variant="tertiary"
            color="neutral"
            size="small"
            icon={IconNames.FBOAddCircle}
            data-qa="inventory-row-add"
            onClick={handleApplyClicked(
              ItemInventoryEvents.Add,
              row.locationId
            )}
            permissions={[PermissionType.InventoryAdd]}
          />
          <FBOButton
            variant="tertiary"
            color="negative"
            size="small"
            icon={IconNames.TrashCan}
            data-qa="inventory-row-remove"
            onClick={handleApplyClicked(
              ItemInventoryEvents.Remove,
              row.locationId,
              true
            )}
            permissions={[PermissionType.InventoryRemove]}
          />
          <FBOButton
            variant="tertiary"
            color="neutral"
            size="small"
            icon={IconNames.FBOMove}
            data-qa="inventory-row-move"
            onClick={handleApplyClicked(
              ItemInventoryEvents.Move,
              row.locationId
            )}
            permissions={[PermissionType.InventoryMove]}
          />
          <FBOButton
            variant="tertiary"
            color="neutral"
            size="small"
            icon={IconNames.CycleCircle}
            data-qa="inventory-row-cycle"
            onClick={handleApplyClicked(
              ItemInventoryEvents.Cycle,
              row.locationId
            )}
            permissions={[PermissionType.InventoryCycle]}
          />
        </Box>
      </TableCell>
    </>
  );
};

export default memo(InventoryTableSubRow);
