import React, { useCallback, useEffect, useState } from 'react';

import { themeRestyle } from 'ui/theme';
import { Modal } from 'ui/components/Modal/Modal';
import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import {
  CustomField,
  deleteCustomField,
  fetchCustomFieldsAPI,
  initialCustomField,
  postCustomFields,
  putCustomFields,
} from 'services/customFields';

import { ITEM_COLUMS } from './consts';
import CustomRow from './CustomRow';
import { CustomFieldsModalProps, CustomRowActions } from './types';
import { AddCustomFieldModal } from '../AddCustomFieldModal';
import { CustomFieldsOrderModal } from './components/CustomFieldsOrderModal';
import { defaultMaximumPagination, RequestConfig } from 'helpers';
import { PaginationDirection } from 'services/url';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const CustomFieldsModal = (props: CustomFieldsModalProps) => {
  const { open, setVisible, module } = props;

  const [customFields, setCustomFields] = useState<CustomField[]>([]);
  const [isLoadingContent, setIsLoadingContent] = useState<boolean>(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [openCustomFieldModal, setOpenCustomFieldModal] = useState(false);
  const [enterDisabled, setEnterDisabled] = useState(false);
  const [openCustomFieldOrderModal, setOpenCustomFieldOrderModal] =
    useState(false);
  const [activeField, setActiveField] =
    useState<CustomField>(initialCustomField);
  const [fieldToDelete, setFieldToDelete] =
    useState<CustomField>(initialCustomField);

  const onCancelHandler = useCallback(() => setVisible(false), [setVisible]);

  useEffect(() => {
    if (openCustomFieldModal) {
      setEnterDisabled(true);
    } else {
      setEnterDisabled(false);
    }
  }, [openCustomFieldModal]);

  useEffect(() => {
    if (open) {
      (async () => {
        setIsLoadingContent(true);

        const requestConfig: RequestConfig = {
          pagination: {
            ...defaultMaximumPagination,
            sort: {
              sortBy: 'lineNumber',
              direction: PaginationDirection.Ascending,
            },
          },
        };

        try {
          const newCustomFields = await fetchCustomFieldsAPI(
            requestConfig,
            module
          );
          setCustomFields(newCustomFields.data);
        } catch {
          // Ignore error
        }
        setIsLoadingContent(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const saveCustomFields = useCallback(async () => {
    setIsSaveLoading(true);
    try {
      const fields = await putCustomFields(customFields, module);
      setCustomFields(fields);
    } catch {
      // Ignore error
    }
    setIsSaveLoading(false);
    onCancelHandler();
  }, [customFields, onCancelHandler, module]);

  const duplicateField = useCallback(
    async (field: CustomField) => {
      const newField = {
        ...field,
        id: null,
        name: field.name + ' (Copy)',
        version: null,
        customFieldOptions: field.customFieldOptions.map((o) => ({
          ...o,
          id: null,
          version: null,
        })),
      };

      try {
        const newCustomFields = await postCustomFields([newField], module);
        setCustomFields(newCustomFields);
      } catch {
        // Ignore error
      }
    },
    [module]
  );

  const addNewField = useCallback(() => {
    setActiveField(initialCustomField);
    setOpenCustomFieldModal(true);
  }, []);

  const onTableItemClick = useCallback(
    (id: number) => {
      const field = customFields.find((c) => c.id === id);
      setActiveField(field || initialCustomField);
      setOpenCustomFieldModal(true);
    },
    [customFields]
  );

  const deleteConfirmed = useCallback(async () => {
    try {
      const newCustomFields = await deleteCustomField(fieldToDelete, module);
      setCustomFields(newCustomFields);
      setFieldToDelete(initialCustomField);
    } catch {
      // Ignore error
    }
  }, [fieldToDelete, module]);

  const handleRowThreeDotMenu = useCallback(
    async (action: { type: CustomRowActions; payload: CustomField }) => {
      const { type, payload } = action;

      switch (type) {
        case CustomRowActions.Edit:
          onTableItemClick(payload.id!);
          break;
        case CustomRowActions.Duplicate:
          await duplicateField(payload);
          break;
        case CustomRowActions.Delete:
          setFieldToDelete(payload);
          break;
      }
    },
    [onTableItemClick, duplicateField]
  );

  const handleSaveCustomFieldOrder = useCallback(
    async (customFields: CustomField[]) => {
      setIsSaveLoading(true);
      try {
        const fields = await putCustomFields(customFields, module);
        setCustomFields(fields);
      } catch {
        // Ignore error
      }
      setIsSaveLoading(false);
      setOpenCustomFieldOrderModal(false);
    },
    [module]
  );

  const handleCloseCustomFieldOrder = useCallback(
    () => setOpenCustomFieldOrderModal(false),
    []
  );
  const titleBar = () => (
    <FBOTitleBar title="Custom Fields">
      <FBOButton
        sx={{ marginRight: themeRestyle.spacing(1) }}
        variant="secondary"
        color="positive"
        size="medium"
        onClick={() => {
          setOpenCustomFieldOrderModal(true);
        }}
        data-qa="custom-field-order-fields"
      >
        Order Fields
      </FBOButton>
      <FBOButton
        sx={{ marginRight: themeRestyle.spacing(1) }}
        variant="secondary"
        color="positive"
        size="medium"
        onClick={addNewField}
        data-qa="custom-field-add-field"
      >
        Add Field
      </FBOButton>
      <FBOButton
        variant="tertiary"
        color="neutral"
        size="medium"
        icon="FBOClose"
        data-qa="close-custom-fields-modal"
        onClick={onCancelHandler}
      />
    </FBOTitleBar>
  );

  // Don't render modal if isn't opened
  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      isLoadingContent={isLoadingContent}
      isLoading={isSaveLoading}
      TitleBarComponent={titleBar}
      onApplyClicked={saveCustomFields}
      onCancelClicked={onCancelHandler}
      applyLabel="Save"
      withoutDefaultPadding
      customHeight={608}
      dataQa="custom-field"
      disableEnter={enterDisabled}
    >
      <ItemsTable
        data={customFields}
        setData={setCustomFields}
        columns={ITEM_COLUMS}
        selectableItems={false}
        emptyTableText="ADD NEW ENTRY BY PRESSING 'ADD FIELD'"
        RenderCustomRow={CustomRow}
        onItemClick={onTableItemClick}
        onAction={handleRowThreeDotMenu}
        meta={{ useIndexForRowTagging: true }}
      />
      <AddCustomFieldModal
        open={openCustomFieldModal}
        activeField={activeField}
        module={module}
        setActiveField={setActiveField}
        setVisible={setOpenCustomFieldModal}
        setCustomFields={setCustomFields}
      />
      <ConfirmationModal
        open={!!fieldToDelete.id}
        title="Delete Custom Field"
        onConfirmClicked={deleteConfirmed}
        onCancelClicked={() => setFieldToDelete(initialCustomField)}
        body="Are you sure you want to delete custom field?"
        confirmButtonRed
        confirmLabel="Delete"
      />
      <CustomFieldsOrderModal
        open={openCustomFieldOrderModal}
        customFields={customFields}
        onClose={handleCloseCustomFieldOrder}
        onApply={handleSaveCustomFieldOrder}
      />
    </Modal>
  );
};

export default CustomFieldsModal;
