import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable/types';

export const BUNDLE_ITEMS_COLUMNS: Column[] = [
  {
    title: 'Name',
    width: 290,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Quantity',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Discount',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Price',
    type: ColumnTypes.renderByCustomRow,
  },
];
