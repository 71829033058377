import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const ITEM_COLUMNS = (
  showMulticurrency: boolean,
  multiCurrencyCode: string,
  homeCurrencyCode: string
): Column[] => {
  let columns: Column[] = [
    {
      title: 'Item',
      type: ColumnTypes.renderByCustomRow,
      field: 'item.name',
      sortable: true,
    },
    {
      title: 'Status',
      fitWidth: true,
      type: ColumnTypes.renderByCustomRow,
      field: 'status',
      sortable: true,
      width: 120,
    },
    {
      title: 'Quantity',
      type: ColumnTypes.renderByCustomRow,
      field: 'quantity',
      sortable: true,
      width: 70,
    },
    {
      title: 'Billing Info',
      fitWidth: true,
      type: ColumnTypes.renderByCustomRow,
      field: 'dateBilled',
      sortable: true,
      width: 90,
    },
    {
      title: 'Scheduled',
      fitWidth: true,
      type: ColumnTypes.renderByCustomRow,
      field: 'dateScheduled',
      sortable: true,
      width: 75,
    },
    {
      title: 'Location',
      width: 80,
      type: ColumnTypes.renderByCustomRow,
      field: 'location.name',
      sortable: true,
    },
    {
      title: 'Unit Cost',
      width: 70,
      type: ColumnTypes.renderByCustomRow,
      field: 'purchaseOrderItem.unitCost',
      sortable: true,
    },
    {
      title: 'Cost',
      width: 70,
      type: ColumnTypes.renderByCustomRow,
      field: 'purchaseOrderItem.totalCost',
      sortable: true,
    },
    {
      title: 'Billed',
      width: 70,
      type: ColumnTypes.renderByCustomRow,
      field: 'billedTotalCost',
      sortable: true,
    },
    {
      title: 'Unit Landed Cost',
      width: 120,
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: 'Landed',
      width: 70,
      type: ColumnTypes.renderByCustomRow,
      field: 'landedTotalCost',
      sortable: true,
    },
    {
      title: '',
      width: 70,
      type: ColumnTypes.renderByCustomRow,
    },
  ];

  if (showMulticurrency) {
    columns = [
      {
        title: 'Item',
        type: ColumnTypes.renderByCustomRow,
        field: 'item.name',
        sortable: true,
      },
      {
        title: 'Status',
        fitWidth: true,
        type: ColumnTypes.renderByCustomRow,
        field: 'status',
        sortable: true,
        width: 120,
      },
      {
        title: 'Quantity',
        type: ColumnTypes.renderByCustomRow,
        field: 'quantity',
        sortable: true,
        width: 70,
      },
      {
        title: 'Billing Info',
        fitWidth: true,
        type: ColumnTypes.renderByCustomRow,
        field: 'dateBilled',
        sortable: true,
        width: 90,
      },
      {
        title: 'Scheduled',
        fitWidth: true,
        type: ColumnTypes.renderByCustomRow,
        field: 'dateScheduled',
        sortable: true,
        width: 75,
      },
      {
        title: 'Location',
        width: 80,
        type: ColumnTypes.renderByCustomRow,
        field: 'location.name',
        sortable: true,
      },
      {
        title: 'Unit Cost',
        width: 70,
        type: ColumnTypes.renderByCustomRow,
        field: 'purchaseOrderItem.unitCost',
        sortable: true,
      },
      {
        title: `Unit Cost ${multiCurrencyCode}`,
        width: 70,
        type: ColumnTypes.renderByCustomRow,
        field: 'purchaseOrderItem.unitCost',
        sortable: true,
      },
      {
        title: `Cost ${homeCurrencyCode}`,
        width: 90,
        type: ColumnTypes.renderByCustomRow,
        field: 'purchaseOrderItem.totalCost',
        sortable: true,
      },
      {
        title: `Cost ${multiCurrencyCode}`,
        width: 90,
        type: ColumnTypes.renderByCustomRow,
        field: 'purchaseOrderItem.totalCost',
        sortable: true,
      },
      {
        title: `Billed ${homeCurrencyCode}`,
        width: 80,
        type: ColumnTypes.renderByCustomRow,
        field: 'billedTotalCost',
        sortable: true,
      },
      {
        title: `Billed ${multiCurrencyCode}`,
        width: 80,
        type: ColumnTypes.renderByCustomRow,
        field: 'billedTotalCost',
        sortable: true,
      },
      {
        title: `Unit Landed Cost ${homeCurrencyCode}`,
        width: 120,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Unit Landed Cost ${multiCurrencyCode}`,
        width: 120,
        type: ColumnTypes.renderByCustomRow,
      },
      {
        title: `Landed ${homeCurrencyCode}`,
        width: 90,
        type: ColumnTypes.renderByCustomRow,
        field: 'landedTotalCost',
        sortable: true,
      },
      {
        title: `Landed ${multiCurrencyCode}`,
        width: 90,
        type: ColumnTypes.renderByCustomRow,
        field: 'landedTotalCost',
        sortable: true,
      },
      {
        title: '',
        width: 70,
        type: ColumnTypes.renderByCustomRow,
      },
    ];
  }

  return columns;
};
