import { Dates } from 'helpers';
import { ColumnTypes } from 'ui/components/Table/ItemsTable';
import { Column } from 'ui/components/Table/ItemsTable/types';

import { PaymentTerm } from './types';

export const PAYMENT_TERM_COLUMNS = (date: Dates): Column[] => {
  const resolveDateColumn = (): Column => {
    switch (date) {
      case Dates.DateLastModified:
        return {
          title: 'Date Last Modified',
          field: Dates.DateLastModified,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
        };
      default:
        return {
          title: 'Date Created',
          field: Dates.DateCreated,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
        };
    }
  };

  return [
    {
      title: 'Name',
      field: 'name',
      sortable: true,
      type: ColumnTypes.renderByCustomRow,
    },
    resolveDateColumn(),
  ];
};

export const DUE_ON_RECEIPT_ID = 1;

export const initialPaymentTerm: PaymentTerm = {
  id: null,
  version: null,
  dateLastModified: null,
  dateCreated: null,
  name: null,
  dueDays: null,
  graceDays: null,
  paymentTermType: null,
  discountPercent: null,
  discountDays: null,
  dayOfMonthDue: null,
  deleted: false,
};
