export interface PickingSettings {
  id: number;
  version: number;
  pickCreationType: PickCreationType;
  pickMethodType: PickMethodType;
  groupPickMaxItems: number;
  pickingExpiredItemWarning: boolean;
  autoCommitSoPicks: boolean;
  autoCommitPoPicks: boolean;
  autoCommitXoPicks: boolean;
  finishAutoCommittedPicks: boolean;
  enableOverPicking: boolean;
  notifyWhenOverPicking: boolean;
}

export enum PickCreationType {
  ByOrder = 'By Order',
  ByScheduledDate = 'By Scheduled Date',
}

export enum PickMethodType {
  Fifo = 'FIFO',
  Lifo = 'LIFO',
  PickFromFewest = 'Pick from Fewest',
}
