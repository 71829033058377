import React, { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  MenuItem as MuiMenuItem,
  Menu as MuiMenu,
  Typography,
  Box,
} from '@mui/material';

import { activeUserHasPermission } from 'services/user/redux';
import { Icon } from 'ui/components/Icon';

import { MenuItemProps } from './types';
import { useMenuStyle } from './styled';
import { MenuItemVariant } from './consts';
import { IconNames } from 'ui/theme';

const MenuItem: React.FC<MenuItemProps> = (props) => {
  const classes = useMenuStyle();
  const {
    item,
    onMenuItemClicked,
    onSubMenuItemClicked,
    onSubItemClosed,
    index,
    thereAreSubItems,
    dataQa,
    ...otherProps
  } = props;
  const canClick = useSelector(activeUserHasPermission(item.permissions || []));

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const isSubMenuVisible = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const handleMenuOnClose = useCallback((ev: React.MouseEvent<{}>) => {
    ev.preventDefault();
    setAnchorEl(null);
  }, []);

  const handleMenuItemClick = useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      if (item.subItems && item.subItems.length > 0) {
        setAnchorEl(event.currentTarget);
      } else {
        onMenuItemClicked(event, index);
      }
    },
    [item.subItems, onMenuItemClicked, index]
  );

  const handleSubMenuItemClick = useCallback(
    (ev: React.MouseEvent<HTMLLIElement, MouseEvent>, subItemIndex: number) => {
      onSubMenuItemClicked(ev, index, subItemIndex);
      handleMenuOnClose(ev);
      onSubItemClosed(ev);
    },
    [handleMenuOnClose, index, onSubMenuItemClicked, onSubItemClosed]
  );

  const isDisabled = !!item.disabled || !canClick;

  return (
    <>
      <MuiMenuItem
        disabled={isDisabled}
        onClick={handleMenuItemClick}
        data-qa={dataQa}
        disableRipple
        className={
          item.separateAfter ? classes.menuItem : classes.defaultMenuItem
        }
        {...otherProps}
      >
        {item.icon &&
          (typeof item.icon === 'string' ? (
            <Icon
              name={item.icon}
              width={20}
              height={20}
              className={
                item.variant === MenuItemVariant.Danger
                  ? classes.danger
                  : classes.default
              }
            />
          ) : (
            item.icon
          ))}
        <Box
          pl={item.icon ? 2 : 0}
          pr={item.subItems ? 0 : 1}
          data-qa={item.label}
        >
          <Typography
            className={
              item.variant === MenuItemVariant.Danger ? classes.danger : ''
            }
          >
            {item.label}
          </Typography>
        </Box>
        {thereAreSubItems && (
          <Box ml="auto" display="flex" justifyContent="center" width={24}>
            {item.subItems && (
              <Icon
                name={IconNames.FBOCaratRightSmall}
                pl={2}
                width={15}
                height={15}
                className={classes.default}
                onClick={(e: any) => {
                  e.preventDefault();
                }}
              />
            )}
          </Box>
        )}
        {item.iconRight && (
          <Box
            display="flex"
            justifyContent="center"
            mr={1}
            alignItems="center"
            onClick={(e) => {
              e.preventDefault();
              window.open(item.iconRightHref);
            }}
            data-qa="menu-item-icon"
          >
            {typeof item.iconRight === 'string' ? (
              <Icon
                name={item.iconRight}
                pl={2}
                width={15}
                height={15}
                className={
                  item.variant === MenuItemVariant.Danger
                    ? classes.danger
                    : classes.default
                }
                onClick={(e: any) => {
                  e.preventDefault();
                }}
              />
            ) : (
              item.iconRight
            )}
          </Box>
        )}
      </MuiMenuItem>
      <MuiMenu
        anchorEl={anchorEl}
        open={isSubMenuVisible}
        onClose={handleMenuOnClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className={classes.menu}
      >
        {item.subItems &&
          item.subItems.map((subItem, subItemIndex) => (
            <MuiMenuItem
              key={`item-${subItem.label}`}
              disabled={isDisabled}
              onClick={(event) => handleSubMenuItemClick(event, subItemIndex)}
              data-qa={subItem.dataQa}
              className={
                subItem.separateAfter
                  ? classes.menuItem
                  : classes.defaultMenuItem
              }
              disableRipple
            >
              <Box ml={0} mr={3}>
                <Typography
                  className={
                    item.variant === MenuItemVariant.Danger
                      ? classes.danger
                      : ''
                  }
                >
                  {subItem.label}
                </Typography>
              </Box>
              {subItem.iconRight && (
                <Box
                  display="flex"
                  justifyContent="center"
                  ml="auto"
                  alignItems="center"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(subItem.iconRightHref);
                  }}
                  data-qa="menu-subitem-icon"
                >
                  {typeof subItem.iconRight === 'string' ? (
                    <Icon
                      name={subItem.iconRight}
                      pl={2}
                      width={15}
                      height={15}
                      className={
                        item.variant === MenuItemVariant.Danger
                          ? classes.danger
                          : classes.default
                      }
                      onClick={(e: any) => {
                        e.preventDefault();
                      }}
                    />
                  ) : (
                    subItem.iconRight
                  )}
                </Box>
              )}
            </MuiMenuItem>
          ))}
      </MuiMenu>
    </>
  );
};

export default memo(MenuItem);
