import { FetchOptions } from 'ui/components/Page/WithSearchResults';

export interface PurchaseOrderDetailsCardProps {
  activePurchaseOrderId: number | null;
  nextPoNumber: number;
  setNextPoNumber: React.Dispatch<React.SetStateAction<number>>;
  onClose(): void;
  fetchSearchResult(options?: FetchOptions): Promise<void>;
  clone: boolean;
  setClone: React.Dispatch<React.SetStateAction<boolean>>;
}

export enum PurchaseOrderActiveIdState {
  New = -1,
  Duplicate = -2,
  DuplicateAsCreditReturn = -3,
}
