import { Pagination } from 'services/search';
import { PaginationDirection } from 'services/url';

export const initialPagination: Pagination = {
  page: 1,
  pageSize: 25,
  sort: {
    sortBy: 'id',
    direction: PaginationDirection.Descending,
  },
  totalRows: 25,
};
