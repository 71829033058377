import React, { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, FormControlLabel, Radio, Typography } from '@mui/material';

import { TagsAsyncAutocomplete } from 'ui/components/Autocomplete/TagsAutocomplete';
import { CustomersAutocomplete } from 'ui/components/Autocomplete/CustomerAutocomplete';
import { Tag } from 'services/tags';
import { Customer, getCustomers } from 'services/customers';
import { activeUserHasPermission } from 'services/user/redux';
import { PermissionType } from 'services/permissions';

import {
  PricingRulesCustomersGroup,
  PricingRulesCustomersProps,
} from './types';
import { usePricingRulesCustomersStyle } from './styled';

const PricingRulesCustomers: React.FC<PricingRulesCustomersProps> = (props) => {
  const {
    activePricingRule,
    setActivePricingRule,
    checkedCustomersGroup,
    setCheckedCustomersGroup,
    validationErrors,
    setValidationErrors,
  } = props;

  const { items: customers } = useSelector(getCustomers);

  const classes = usePricingRulesCustomersStyle();

  const canEditPricingRules =
    activePricingRule.id && activePricingRule.id > 0
      ? [PermissionType.PricinRulesEdit]
      : [PermissionType.PricinRulesCreate];

  const canSwitchRadio = useSelector(
    activeUserHasPermission(canEditPricingRules)
  );

  const selectedCustomer = useMemo(() => {
    return customers.find((c) => c.id === activePricingRule.customerId) || null;
  }, [customers, activePricingRule.customerId]);

  const handleTagsChange = useCallback(
    (values: Tag[]) =>
      setActivePricingRule((old) => ({
        ...old,
        customerTags: values,
        customerId: null,
      })),
    [setActivePricingRule]
  );

  const handleCustomerChange = useCallback(
    (customer: Customer | null) =>
      setActivePricingRule((old) => ({
        ...old,
        customerId: customer ? customer.id : null,
      })),
    [setActivePricingRule]
  );

  const handleCustomersGroupChange = useCallback(
    (event: any) => {
      const pricingRuleGroup = event.target.value;

      setCheckedCustomersGroup(pricingRuleGroup);
      setValidationErrors({});

      switch (pricingRuleGroup) {
        case PricingRulesCustomersGroup.Everyone:
          setActivePricingRule((old) => ({
            ...old,
            customerId: null,
            customerTags: [],
          }));
          break;
        case PricingRulesCustomersGroup.Customer:
          setActivePricingRule((old) => ({
            ...old,
            customerId: selectedCustomer ? selectedCustomer.id : null,
            customerTags: [],
          }));
          break;
        case PricingRulesCustomersGroup.Tag:
          setActivePricingRule((old) => ({
            ...old,
            customerId: null,
            customerTags: activePricingRule.customerTags,
          }));
          break;
      }
    },
    [
      setActivePricingRule,
      selectedCustomer,
      activePricingRule.customerTags,
      setCheckedCustomersGroup,
      setValidationErrors,
    ]
  );

  return (
    <>
      <Typography variant="body1" color="textPrimary">
        <b>Customers</b>
      </Typography>
      <Box py={1}>
        <FormControlLabel
          control={
            <Radio
              checked={
                checkedCustomersGroup === PricingRulesCustomersGroup.Everyone
              }
              onChange={handleCustomersGroupChange}
              value={PricingRulesCustomersGroup.Everyone}
              name="radio-customers"
              color="primary"
              inputProps={
                {
                  'data-qa': 'pricing-rule-everyone-radio',
                } as any
              }
            />
          }
          label="Everyone"
          className={classes.radioButton}
          disabled={!canSwitchRadio}
        />
      </Box>

      <Box display="flex" alignItems="center" py={1}>
        <FormControlLabel
          control={
            <Radio
              checked={
                checkedCustomersGroup === PricingRulesCustomersGroup.Customer
              }
              onChange={handleCustomersGroupChange}
              value={PricingRulesCustomersGroup.Customer}
              name="radio-customers"
              color="primary"
              inputProps={
                {
                  'data-qa': 'pricing-rule-customer-radio',
                } as any
              }
            />
          }
          label="Customer"
          className={classes.radioButton}
          disabled={!canSwitchRadio}
        />
        <Box flex={1}>
          <CustomersAutocomplete
            onChange={handleCustomerChange}
            placeholder="Select customer"
            value={selectedCustomer}
            label="Customer"
            permissions={canEditPricingRules}
            disabled={
              checkedCustomersGroup !== PricingRulesCustomersGroup.Customer
            }
            error={!!validationErrors.customerId}
            expands={[
              'customerAddresses.customerAddressContacts',
              'tags',
              'carrierAccountSettings',
            ]}
            dataQa="pricing-rule-autocomplete"
          />
        </Box>
      </Box>

      <Box display="flex" alignItems="center" py={1}>
        <FormControlLabel
          control={
            <Radio
              checked={checkedCustomersGroup === PricingRulesCustomersGroup.Tag}
              onChange={handleCustomersGroupChange}
              value={PricingRulesCustomersGroup.Tag}
              name="radio-customers"
              color="primary"
            />
          }
          label="Tag"
          disabled={!canSwitchRadio}
          className={classes.radioButton}
        />

        <Box flex={1}>
          <TagsAsyncAutocomplete
            value={activePricingRule.customerTags}
            onChange={handleTagsChange}
            permissions={canEditPricingRules}
            disabled={checkedCustomersGroup !== PricingRulesCustomersGroup.Tag}
            error={!!validationErrors.customerTags}
            disableTagsModal
            dataQa="pricing-rule-customer-tag"
          />
        </Box>
      </Box>
    </>
  );
};

export default memo(PricingRulesCustomers);
