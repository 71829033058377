import { combineReducers } from 'redux';
import {
  chainReducers,
  INITIAL_API_STATE,
  createApiReducer,
} from 'services/api';
import {
  createAddSearchReducer,
  createDeleteSearchReducer,
  createFetchSearchReducer,
  createUpdateSearchReducer,
} from 'services/search';

import { transformClass } from '../transformations';
import { ClassesActionTypes } from './actions';

const fetchClassesReducer = createApiReducer(
  [
    ClassesActionTypes.FETCH_CLASSES_START,
    ClassesActionTypes.FETCH_CLASSES_SUCCESS,
    ClassesActionTypes.FETCH_CLASSES_FAILURE,
  ],
  (data) => ({ items: data.results.map(transformClass) }),
  (data) => data
);

const classesChainedReducers = chainReducers(
  INITIAL_API_STATE,
  fetchClassesReducer
);

const fetchSearchReducer = createFetchSearchReducer('classes');
const addSearchReducer = createAddSearchReducer('classes');
const updateSearchReducer = createUpdateSearchReducer('classes');
const deleteSearchReducer = createDeleteSearchReducer('classes');

const searchChainedReducers = chainReducers(
  INITIAL_API_STATE,
  fetchSearchReducer,
  addSearchReducer,
  updateSearchReducer,
  deleteSearchReducer
);

export default combineReducers({
  classes: classesChainedReducers,
  searches: searchChainedReducers,
});
