import { makeStyles } from '@mui/styles';

export const useTrackingCycleColumns = makeStyles(() => ({
  statusLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: '153px',
    justifyContent: 'space-between',
  },
}));
