import React from 'react';
import { Route, Routes } from 'react-router-dom';

import SecureRoute from 'ui/components/Route/SecureRoute';

import { Routes as ReportsRoutes } from './routes';
import { ReportsPage } from '../pages';
import { ReportsRouterCmp, ReportsRouterProps } from './types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ReportsRouter: ReportsRouterCmp = (props: ReportsRouterProps) => {
  return (
    <Routes>
      <Route element={<SecureRoute />}>
        <Route path={ReportsPage.route} element={<ReportsPage />} />
      </Route>
    </Routes>
  );
};

ReportsRouter.route = ReportsRoutes.ReportsRouter;

export default ReportsRouter;
