import { createApiCall } from 'services/api';

import {
  transformPricingRule,
  transformToRequestPricingRule,
} from './transformations';
import { PricingRule } from './types';

export const fetchPricingRule = async (id: number): Promise<PricingRule> => {
  const response = await createApiCall(
    {
      path: `/v1/pricing_rules/${id}?expand=customerTags,saleItemTags,saleItem,customer`,
      method: 'GET',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformPricingRule(response.data);
};

export const postPricingRule = async (
  pricingRule: PricingRule
): Promise<PricingRule> => {
  const resp = await createApiCall(
    {
      path: `/v1/pricing_rules?expand=customerTags,saleItemTags,saleItem,customer`,
      method: 'POST',
      body: transformToRequestPricingRule(pricingRule),
    },
    {
      getMessage: {
        success: () => 'Pricing rule successfully created',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformPricingRule(resp.data);
};

export const putPricingRule = async (
  pricingRule: PricingRule
): Promise<PricingRule> => {
  const resp = await createApiCall(
    {
      path: `/v1/pricing_rules?expand=customerTags,saleItemTags,saleItem,customer`,
      method: 'PUT',
      body: transformToRequestPricingRule(pricingRule),
    },
    {
      getMessage: {
        success: () => 'Pricing rule updated',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformPricingRule(resp.data);
};

export const deletePricingRule = async (id: number): Promise<void> => {
  await createApiCall(
    {
      path: `/v1/pricing_rules/${id}`,
      method: 'DELETE',
    },
    {
      getMessage: {
        success: () => 'Pricing rule successfully deleted',
        error: (error: any) => error.response.data.message,
      },
    }
  )();
};
