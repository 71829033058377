import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, Grid } from '@mui/material';

import { TextField } from 'ui/components/TextField/TextField';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { DetailsCard } from 'ui/components/Page/DetailsCard';
import { useHandleTextFieldChange } from 'services/forms';
import {
  initialRepresentative,
  Representative,
} from 'services/representatives';
import { useUrlQueryObject } from 'services/url';
import {
  postRepresentative,
  deleteRepresentative,
  fetchRepresentativeById,
  putRepresentative,
  restoreRepresentative,
} from 'services/representatives/api';
import { Errors, validateYup } from 'services/forms/validation';

import {
  RepresentativeDetailsCardProps,
  RepresentativeDetailsCardCmp,
} from './types';
import { yupRepresentativeSchema } from './validations';
import { RepresentativesTitleBar } from '../RepresentativesTitleBar';
import { editRepresentativePermissions } from '../helpers';
import { useLocation, useNavigate } from 'react-router-dom';

const RepresentativeDetailCard: RepresentativeDetailsCardCmp = (
  props: RepresentativeDetailsCardProps
) => {
  const { activeRepresentativeId, fetchSearchResult, onClose } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const [, setQueryParams] = useUrlQueryObject(navigate, location);

  const [activeRepresentative, setActiveRepresentative] = useState(
    initialRepresentative
  );
  const [validationErrors, setValidationErrors] = useState<Errors>({});
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const oldState = useRef<Representative | null>(initialRepresentative);

  const firstInputElement = useRef<HTMLInputElement>(null);

  const editPermission = editRepresentativePermissions(activeRepresentative);

  const title = useMemo(() => {
    if (!activeRepresentative.id || activeRepresentative.id < 0) {
      return 'New Representative';
    }
    return `${activeRepresentative.firstName || ''} ${
      activeRepresentative.lastName || ''
    }`;
  }, [
    activeRepresentative.id,
    activeRepresentative.firstName,
    activeRepresentative.lastName,
  ]);

  useEffect(() => {
    if (
      activeRepresentative.id !== null &&
      firstInputElement.current !== null
    ) {
      firstInputElement.current.focus();
    }
  }, [activeRepresentative.id]);

  useEffect(() => {
    const asyncFc = async (id: number) => {
      setIsLoading(true);
      try {
        const newRepresentative = await fetchRepresentativeById(id);
        oldState.current = newRepresentative;
        setActiveRepresentative(newRepresentative);
      } catch (err) {
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
    };

    if (!activeRepresentativeId || activeRepresentativeId === -1) {
      const newRepresentative: Representative = {
        ...initialRepresentative,
        id: activeRepresentativeId,
      };
      oldState.current = newRepresentative;
      setActiveRepresentative(newRepresentative);
    } else {
      asyncFc(activeRepresentativeId);
    }
    setValidationErrors({});
  }, [activeRepresentativeId]);

  const saveRepresentative = useCallback(
    (close: boolean = false) =>
      async () => {
        if (
          !validateYup(
            activeRepresentative,
            yupRepresentativeSchema,
            setValidationErrors
          )
        ) {
          return false;
        }

        setIsLoading(true);

        // Create new
        if (!activeRepresentative.id || activeRepresentative.id === -1) {
          try {
            const newActiveRepresenetative = await postRepresentative(
              activeRepresentative
            );
            oldState.current = newActiveRepresenetative;
            setActiveRepresentative(newActiveRepresenetative);
            await fetchSearchResult();
            setQueryParams({ activeId: newActiveRepresenetative.id });
            if (close) {
              setQueryParams({ activeId: null });
              onClose();
              return true;
            }
          } catch {
            setIsLoading(false);
            return false;
          }
          setIsLoading(false);
          return true;
        }

        // Update
        try {
          const newActiveRepresenetative = await putRepresentative(
            activeRepresentative
          );
          oldState.current = newActiveRepresenetative;
          setActiveRepresentative(newActiveRepresenetative);
          await fetchSearchResult();
        } catch {
          setIsLoading(false);
          return false;
        }
        if (close) {
          onClose();
        }
        setIsLoading(false);
        return true;
      },
    [activeRepresentative, fetchSearchResult, onClose, setQueryParams]
  );

  const deleteRepresentativeClicked = useCallback(async () => {
    try {
      await deleteRepresentative(activeRepresentative.id!);
      await fetchSearchResult();
    } catch {
      return;
    }

    setDeleteModalVisible(false);
    onClose();
  }, [activeRepresentative, fetchSearchResult, onClose]);

  const handleUndeleteClicked = useCallback(
    async (close: boolean = false) => {
      setIsLoading(true);

      try {
        await restoreRepresentative(activeRepresentativeId!);
        const restoredRepresentative = await fetchRepresentativeById(
          activeRepresentativeId!
        );
        oldState.current = restoredRepresentative;
        setActiveRepresentative(restoredRepresentative);
      } catch {
        setIsLoading(false);
        return false;
      }

      if (close) {
        onClose();
      }

      setIsLoading(false);
      fetchSearchResult();
      return true;
    },

    [fetchSearchResult, onClose, activeRepresentativeId]
  );

  const handleTextInputChanged = useHandleTextFieldChange<Representative>(
    setActiveRepresentative,
    activeRepresentative
  );

  const handleDeleteModalVisible = (visible: boolean) => () =>
    setDeleteModalVisible(visible);

  return (
    <DetailsCard
      isLoading={isLoading}
      onSubmit={saveRepresentative(false)}
      state={activeRepresentative}
      oldState={oldState}
    >
      <RepresentativesTitleBar
        activeRepresentative={activeRepresentative}
        onSave={saveRepresentative()}
        onClose={onClose}
        onUndeleteClicked={handleUndeleteClicked}
        deleteModalVisible={handleDeleteModalVisible(true)}
      />
      <Box m={4}>
        <Grid container spacing={1} xs={12}>
          <Grid item xs={3}>
            <TextField
              className={'redesign'}
              variant={'standard'}
              type="text"
              label="First Name"
              placeholder="Enter first name"
              name="firstName"
              autoComplete="off"
              fullWidth
              permissions={editPermission}
              value={activeRepresentative.firstName}
              required
              inputRef={firstInputElement}
              onChange={handleTextInputChanged}
              error={Boolean(validationErrors.firstName)}
              dataQa="representative-name"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Middle Name"
              placeholder="Enter middle name"
              name="middleName"
              autoComplete="off"
              fullWidth
              permissions={editPermission}
              value={activeRepresentative.middleName}
              onChange={handleTextInputChanged}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Last Name"
              placeholder="Enter last name"
              name="lastName"
              autoComplete="off"
              fullWidth
              permissions={editPermission}
              value={activeRepresentative.lastName}
              onChange={handleTextInputChanged}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Initials"
              placeholder="Enter initials"
              name="initials"
              autoComplete="off"
              fullWidth
              value={activeRepresentative.initials}
              required
              permissions={editPermission}
              onChange={handleTextInputChanged}
              error={Boolean(validationErrors.initials)}
              dataQa="representative-initials"
            />
          </Grid>
        </Grid>
      </Box>
      <ConfirmationModal
        open={deleteModalVisible}
        title="Delete Representative"
        body={`This will delete representative '${title}', are you sure?`}
        onCancelClicked={handleDeleteModalVisible(false)}
        onConfirmClicked={deleteRepresentativeClicked}
        confirmLabel={'Delete'}
        cancelLabel={'Cancel'}
        confirmButtonRed
      />
    </DetailsCard>
  );
};

export default RepresentativeDetailCard;
