/**
 * Enriches `url` with expand query.
 * @param url
 * @param pagination
 */
export const expandURL = (url: string, expand: string): string => {
  const querySymbol = url.includes('?') ? '&' : '?';

  const expandedURL = `${url}${querySymbol}expand=${expand}`;

  return expandedURL;
};
