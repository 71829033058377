import { makeStyles } from '@mui/styles';

export const usePopoverItemStyle = makeStyles(() => ({
  popover: {
    cursor: 'pointer',
    '&:hover': {
      background: '#f5f5f5',
    },
  },
}));
