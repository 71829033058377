import { styled } from 'ui/theme';
import { StyledLayoutProps } from './types';

// TODO : RESTYLING : Refactor this file, most of the width styles are out-of-date / confusing.

const SliderContainerRestyle = styled.div<StyledLayoutProps>`
  display: flex;
  flex-grow: 1;
  min-height: 604px;
  height: calc(-220px + 100vh);
  // revisit this height calculation, it would be best to pass in a prop if search tags are present

  & > .static-paper {
    min-width: ${(props) => (props.shown ? '15em' : '100%')}; // 240px
    width: ${(props) => (props.shown ? '15em' : '100%')}; // 240px
    position: relative;
    display: flex;
    flex-direction: column;

    & .MuiTablePagination-caption:first-of-type,
    & [class*='MuiTablePagination-select'] {
      display: ${(props) => (props.shown ? 'none' : 'auto')};
    }

    @media (min-width: 96.875em) {
      min-width: ${(props) => (props.shown ? '18.75em' : '100%')}; // 300px
      width: ${(props) => (props.shown ? '18.75em' : '100%')}; // 300px
    }
  }

  & > .static-paper-gap {
    margin-right: 0.5em; // 8px
  }

  & > .sliding-paper {
    flex-grow: 1;
    max-width: ${(props) =>
      props.shown ? 'calc(100% - 15em - 0.5em)' : '100%'}; // 240px
    @media (min-width: 96.875em) {
      max-width: ${(props) =>
        props.shown ? 'calc(100% - 18.75em - 0.5em)' : '100%'}; // 300px
    }
    width: 100%;
  }
`;

export default SliderContainerRestyle;
