import React, { memo, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TableCell, Typography, Link } from '@mui/material';
import _ from 'lodash';

import { MultiCurrencyWrapper } from 'ui/components/MultiCurrencyWrapper/MultiCurrencyWrapper';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import { useCurrencyFormatter, USCountryString } from 'helpers';
import { ImageItemBox } from 'ui/components/Image/ImageItemBox';
import { IconItemBox } from 'ui/components/Icon/IconItemBox';
import { IconNames } from 'ui/theme';
import { ReconcileSummary } from 'services/receiving';

import { useReconcileWizardSummaryStyle } from './styled';
import {
  fetchTaxRates,
  getTaxRateById,
  showNumberAsPercentage,
} from 'services/taxRates';
import { getSettingsCompanyCountry } from 'services/settings/company';
import { TaxTooltip } from 'ui/components/TaxTooltip';

const ReconcileWizardSummaryRow: React.FC<
  RenderCustomRowProps<ReconcileSummary>
> = (props) => {
  const { row } = props;

  const currencyFormatter = useCurrencyFormatter();
  const currencyFormatterSix = useCurrencyFormatter(2, 6);

  const classes: any = useReconcileWizardSummaryStyle();

  const isItem = useMemo(
    () => row.reconcileReceiptItem !== null,
    [row.reconcileReceiptItem]
  );

  const activeMulticurrencyCode = _.get(row, 'currency.code', null);
  const tenantCountry = useSelector(getSettingsCompanyCountry);
  const isUSTenant = tenantCountry === USCountryString;
  const dispatch = useDispatch();

  const taxRate = useSelector(
    getTaxRateById(row?.reconcileReceiptItem?.taxId || null)
  );

  useEffect(() => {
    if (!taxRate) {
      // @ts-ignore
      dispatch(fetchTaxRates());
    }
  }, [row]);

  return (
    <>
      <TableCell>
        {isItem ? (
          <ImageItemBox
            name={row.reconcileReceiptItem!.name}
            description={row.reconcileReceiptItem!.description}
            source={row.reconcileReceiptItem!.image}
          />
        ) : (
          <IconItemBox
            source={IconNames.AttachMoney}
            name={row.expenseName}
            description={'Some description'}
          />
        )}
      </TableCell>
      <TableCell className={classes.quantityColumn}>
        <Typography>
          {(row.quantity || '') + ' ' + (row.uomAbbrevation || '')}
        </Typography>
      </TableCell>
      <TableCell className={classes.unitCostColumn}>
        {currencyFormatterSix(row.unitCost || 0)}
      </TableCell>
      <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
        <TableCell className={classes.unitCostColumn}>
          {currencyFormatterSix(
            row.multiCurrencyUnitCost || 0,
            activeMulticurrencyCode
          )}
        </TableCell>
      </MultiCurrencyWrapper>
      {!isUSTenant && (
        <TableCell className={classes.unitCostColumn} data-qa={`tax-rate`}>
          <TaxTooltip
            tax={taxRate || null}
            taxable={!!row?.reconcileReceiptItem?.taxable}
            data-qa={`tax-rate-tooltip`}
          >
            <Link
              variant="body2"
              color="textPrimary"
              underline="always"
              data-qa="item-row-tax"
              className="aligned-link"
            >
              {showNumberAsPercentage(row.reconcileReceiptItem?.taxRate || 0)}%
            </Link>
          </TaxTooltip>
        </TableCell>
      )}
      {!isUSTenant && (
        <TableCell className={classes.unitCostColumn} data-qa={`tax-amount`}>
          {currencyFormatter(row.reconcileReceiptItem?.taxAmount || 0)}
        </TableCell>
      )}
      {!isUSTenant && (
        <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
          <TableCell
            className={classes.unitCostColumn}
            data-qa={`multi-currency-tax-amount`}
          >
            {currencyFormatter(
              row.reconcileReceiptItem?.multiCurrencyTaxTotal || 0,
              activeMulticurrencyCode
            )}
          </TableCell>
        </MultiCurrencyWrapper>
      )}
      <TableCell className={classes.totalCostColumn}>
        {currencyFormatter(row.totalCost || 0)}
      </TableCell>
      <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
        <TableCell className={classes.totalCostColumn}>
          {currencyFormatter(
            row.multiCurrencyTotalCost || 0,
            activeMulticurrencyCode
          )}
        </TableCell>
      </MultiCurrencyWrapper>
      <TableCell className={classes.reconcileCostColumn}>
        {row.reconcileCost !== null
          ? currencyFormatter(row.reconcileCost || 0)
          : '-'}
      </TableCell>
      <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
        <TableCell className={classes.reconcileCostColumn}>
          {row.reconcileCost !== null
            ? currencyFormatter(
                row.multiCurrencyReconcileCost || 0,
                activeMulticurrencyCode
              )
            : '-'}
        </TableCell>
      </MultiCurrencyWrapper>
      <TableCell
        className={classes.landedCostColumn}
        data-qa={`unit-cost-landed`}
      >
        {row.landedCost !== null
          ? currencyFormatter((row.landedCost || 0) / (row.quantity || 1))
          : '-'}
      </TableCell>
      <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
        <TableCell className={classes.landedCostColumn}>
          {row.landedCost !== null
            ? currencyFormatter(
                (row.multiCurrencyLandedCost || 0) / (row.quantity || 1),
                activeMulticurrencyCode
              )
            : '-'}
        </TableCell>
      </MultiCurrencyWrapper>
      <TableCell className={classes.landedCostColumn}>
        {row.landedCost !== null ? currencyFormatter(row.landedCost || 0) : '-'}
      </TableCell>
      <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
        <TableCell className={classes.landedCostColumn}>
          {row.landedCost !== null
            ? currencyFormatter(
                row.multiCurrencyLandedCost || 0,
                activeMulticurrencyCode
              )
            : '-'}
        </TableCell>
      </MultiCurrencyWrapper>
    </>
  );
};

export default memo(ReconcileWizardSummaryRow);
