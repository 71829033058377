import {
  createAddSearch,
  createDeleteSearch,
  createFetchSearch,
  createUpdateSearch,
} from 'services/search';

// SEARCH ACTIONS

export const fetchSearch = createFetchSearch('shipping');
export const addSearch = createAddSearch('shipping');
export const updateSearch = createUpdateSearch('shipping');
export const deleteSearch = createDeleteSearch('shipping');
