import _ from 'lodash';

import { createApiCall } from '../createApiCall';
import { StartAction, SuccessAction, FailureAction } from './types';

import { ApiConfig } from '../types';
import { showNotification } from '../notifications';

type StartActionFn = () => StartAction;
type SuccessActionFn = (data: any) => SuccessAction;
type FailureActionFn = (data: any) => FailureAction;

/**
 * Function which creates a thunk action for given api config and associated action types
 * @param {string|Object} apiConfig if string it is interprated as path in api endpoint
 * @param {string} apiConfig.hostname hostname of the api, with a default values set in '../config'
 * @param {string} apiConfig.path path on the hostname which combined give an api endpoint
 * @param {string} apiConfig.method HTTP request method, defaults to 'GET'
 * @param {Object} apiConfig.headers headers to be used in HTTP request
 */
export const createApiAction =
  (
    apiConfig: ApiConfig,
    startAction: StartActionFn,
    successAction: SuccessActionFn,
    failureAction: FailureActionFn
  ) =>
  async (dispatch: any) => {
    const apiCall = createApiCall(apiConfig);

    dispatch(startAction());

    try {
      const response = await apiCall();

      let res;
      const additionalData = apiConfig.additionalData || {};

      if (!_.isEmpty(additionalData)) {
        res = await dispatch(
          successAction({ ...response.data, ...additionalData })
        );
      } else {
        res = await dispatch(successAction(response.data));
      }

      return { ok: true, res };
    } catch (e) {
      const handler = errorMessageHandler(apiConfig.hostname);
      const error = handler
        ? handler
        : _.get(e, 'response.data', {
            errorMessage:
              (e as any).message || 'Undefined error, please try again.',
          });

      await dispatch(
        failureAction({
          message: error.message,
        })
      );

      if (
        apiConfig.hostname === process.env.REACT_APP_COMMERCE_URL &&
        (e as any).response?.status === 303
      ) {
        window.location.href = (e as any).response?.data;
        return null;
      }
      //Dont show error if 404 is returned from commerce integration
      const hideCommerceError =
        apiConfig.hostname === process.env.REACT_APP_COMMERCE_URL &&
        (e as any).response?.status === 404;

      // Dont show multiple snackbars if permission error occurs
      const snackBarMessages = Array.from(
        document.querySelectorAll('[data-qa="client-snackbar-message"]')
      ).map((el) => (el as any).innerText || '');

      if (
        error?.message &&
        !snackBarMessages.includes(error.message) &&
        !hideCommerceError
      ) {
        showNotification(error.message, { variant: 'error' });
      }

      return {
        ok: false,
        errorMessage: error.message,
      };
    }
  };

const errorMessageHandler = (hostname?: string) => {
  if (hostname === process.env.REACT_APP_SHIPPING_URL) {
    return 'Error with Shipping features. Contact support.';
  }
  return '';
};
