import React, { memo, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { PurchaseOrderItemTypes } from 'services/purchaseOrders';
import { ReconcileSummary } from 'services/receiving';
import { ItemsTable } from 'ui/components/Table/ItemsTable';
import {
  getSettingsCompany,
  getSettingsCompanyCountry,
} from 'services/settings/company';
import { TextField } from 'ui/components/TextField/TextField';
import { reconcileWizardSummaryColumns } from './consts';
import ReconcileWizardSummaryFooter from './ReconcileWizardSummaryFooter';
import ReconcileWizardSummaryRow from './ReconcileWizardSummaryRow';
import { ReconcileWizardSummaryProps } from './types';
import { USCountryString } from 'helpers';
import { Box, Grid, Typography } from '@mui/material';
import { DatePickerWrapper } from 'ui/components/TextField/DatePickerWrapper';
import { getPaymentTerms } from 'services/paymentTerms/redux/selectors';
import { colorPalette } from 'ui/theme';

const ReconcileWizardSummary: React.FC<ReconcileWizardSummaryProps> = (
  props
) => {
  const {
    summary,
    currency,
    receipt,
    error,
    referenceNumber,
    billDate,
    dueDate,
  } = props;

  const paymentTerms = useSelector(getPaymentTerms).items;

  const { homeCurrency, useMultiCurrency } = useSelector(getSettingsCompany);
  const tenantCountry = useSelector(getSettingsCompanyCountry);
  const isUSTenant = tenantCountry === USCountryString;

  const homeCurrencyCode = (homeCurrency && homeCurrency.code) || 'USD';
  const activeMulticurrencyCode =
    currency && currency.code ? currency.code : null;

  const showMultiCurrency = useMemo(
    () =>
      useMultiCurrency &&
      !!activeMulticurrencyCode &&
      activeMulticurrencyCode !== homeCurrencyCode,
    [useMultiCurrency, activeMulticurrencyCode]
  );
  const [paymentTypeName, setPaymentTypeName] = useState('');

  useEffect(() => {
    paymentTerms.map((p) => {
      if (p.id === receipt.purchaseOrder?.paymentTermId && p.name)
        setPaymentTypeName(p.name);
    });
  }, [receipt, paymentTypeName]);

  const summaryCost = useMemo(() => {
    const sum = summary.reduce(
      (accumulator: number, item: ReconcileSummary) => {
        if (
          (item.reconcileReceiptItem &&
            item.reconcileReceiptItem.purchaseOrderItemType ===
              PurchaseOrderItemTypes.DropShip) ||
          item.onVendorBill
        ) {
          return accumulator + (item.totalCost || 0);
        }
        return accumulator;
      },
      0
    );

    return sum;
  }, [summary]);

  const notOnBillCost = useMemo(() => {
    return summary
      .filter((s) => !s.onVendorBill)
      .reduce((acc: number, item: ReconcileSummary) => {
        return acc + (item.totalCost || 0);
      }, 0);
  }, [summary]);

  const subTotalCost = useMemo(() => {
    return summary.reduce((acc: number, item: ReconcileSummary) => {
      return acc + (item.subTotal ?? 0);
    }, 0);
  }, [summary]);

  const multiCurrencySubTotalCost = useMemo(() => {
    return summary.reduce((acc: number, item: ReconcileSummary) => {
      return acc + (item.multiCurrencySubTotal ?? 0);
    }, 0);
  }, [summary]);

  const totalTax = useMemo(() => {
    return summary.reduce((acc: number, item: ReconcileSummary) => {
      return acc + (item.totalTax ?? 0);
    }, 0);
  }, [summary]);

  const multiCurrencyTotalTax = useMemo(() => {
    return summary.reduce((acc: number, item: ReconcileSummary) => {
      return acc + (item.multiCurrencyTaxTotal ?? 0);
    }, 0);
  }, [summary]);

  return (
    <>
      <Box p={3} overflow="hidden" flexShrink={0}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <TextField
              variant="standard"
              className="redesign"
              label="Reference Number"
              value={referenceNumber}
              readOnly
              disabled
              data-qa="reconcile-wizard-reference-number"
            />
          </Grid>
          <Grid item xs={2}>
            <DatePickerWrapper
              label="Bill Date"
              placeholder="Enter Bill Date"
              value={billDate}
              readOnly
              disabled
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              variant="standard"
              className="redesign"
              label="Payment Terms"
              value={paymentTypeName}
              readOnly
              disabled
              data-qa="reconcile-wizard-payment-terms"
            />
          </Grid>
          <Grid item xs={2}>
            <DatePickerWrapper
              label="Due Date"
              placeholder="Enter Due Date"
              value={dueDate}
              readOnly
              disabled
            />
          </Grid>
        </Grid>
        {error && (
          <Grid item xs={12}>
            <Typography color="error">{error}</Typography>
          </Grid>
        )}
      </Box>

      <ItemsTable
        data={summary}
        columns={reconcileWizardSummaryColumns(
          showMultiCurrency,
          activeMulticurrencyCode,
          homeCurrencyCode,
          isUSTenant
        )}
        selectableItems={false}
        emptyTableText="Summary"
        RenderCustomRow={ReconcileWizardSummaryRow}
        RenderCustomFooter={ReconcileWizardSummaryFooter}
        footerData={{
          summaryCost,
          notOnBillCost,
          currency,
          subTotalCost,
          multiCurrencySubTotalCost,
          totalTax,
          multiCurrencyTotalTax,
          isUSTenant,
        }}
        dataQa="reconcile-wizard-summary"
        sx={{
          borderRadius: '5px',
          border: `1px solid ${colorPalette.redesign.background3}`,
          borderTop: 'none',
          maxHeight: '345px',
          overflowY: 'scroll',
        }}
      />
    </>
  );
};

export default memo(ReconcileWizardSummary);
