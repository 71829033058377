import React, { memo, useState, useCallback } from 'react';
import { Box } from '@mui/material';
import _ from 'lodash';

import {
  ItemsTable,
  useSelectedItemsChanges,
} from 'ui/components/Table/ItemsTable';
import { IconNames } from 'ui/theme';
import { findNextNegativeId, replaceValueInCollection } from 'helpers';
import { BundleItem, initialBundleItem } from 'services/items';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { PermissionType } from 'services/permissions';

import { BUNDLE_ITEMS_COLUMNS } from './consts';
import { BundleItemsTabProps } from './types';
import { editItemPermissions } from '../../helpers';
import { BundleItemModal } from './components/BundleItemModal';
import BundleItemRow from './components/BundleItemRow';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const BundleItemsTab: React.FC<BundleItemsTabProps> = (props) => {
  const { item, setItem } = props;

  const [activeBundleItem, setActiveBundleItem] =
    useState<BundleItem>(initialBundleItem);
  const [showModal, setShowModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const editPermissions = editItemPermissions(item);

  const handleAddNewClicked = useCallback(() => {
    setActiveBundleItem({
      ...initialBundleItem,
      id: findNextNegativeId(item.bundleItems),
    });

    setShowModal(true);
  }, [item.bundleItems]);

  const handleModalCancelClicked = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleBundleItemClicked = useCallback(
    (id: number) => {
      const bundleItem = item.bundleItems.find((i) => i.id === id)!;
      setActiveBundleItem(bundleItem);
      setShowModal(true);
    },
    [item.bundleItems]
  );

  const handleModalApplyClicked = useCallback(() => {
    const index = item.bundleItems.findIndex(
      (i) => i.id === activeBundleItem.id
    );

    if (index !== -1) {
      const newBundleItems = replaceValueInCollection(
        item.bundleItems,
        activeBundleItem,
        index
      )!;
      const newCost = newBundleItems.reduce(
        (acc, i) => acc + (i.price && i.quantity ? i.price * i.quantity : 0),
        0
      );
      setItem((prevItem) => ({
        ...prevItem,
        bundleItems: newBundleItems,
        cost: newCost,
      }));
    } else {
      const newBundleItems = [...item.bundleItems];
      newBundleItems.push(activeBundleItem);
      const newCost = newBundleItems.reduce(
        (acc, i) => acc + (i.price && i.quantity ? i.price * i.quantity : 0),
        0
      );
      setItem((prevItem) => ({
        ...prevItem,
        bundleItems: newBundleItems,
        cost: newCost,
      }));
    }

    setShowModal(false);
  }, [activeBundleItem, item.bundleItems, setItem]);

  const handleSelectedChange = useSelectedItemsChanges(
    selectedItems,
    setSelectedItems
  );

  const handleDeleteModalVisible = (visible: boolean) => () =>
    setDeleteModalVisible(visible);

  const handleDeleteClicked = useCallback(() => {
    const newBundleItems = item.bundleItems
      .map((i) => ({
        ...i,
        deleted: i.id ? selectedItems.includes(i.id) : false,
      }))
      .filter((i) => !(i.id && i.id < 0 && i.deleted));

    setItem((prevItem) => ({
      ...prevItem,
      bundleItems: newBundleItems,
    }));

    setSelectedItems([]);
    setDeleteModalVisible(false);
  }, [item.bundleItems, selectedItems, setItem]);

  const filterBundleItems = useCallback(
    (b: BundleItem) => {
      return editPermissions.includes(PermissionType.Undelete)
        ? b.deleted
        : !b.deleted;
    },
    [editPermissions]
  );
  return (
    <Box display="flex" flexDirection="column" height="100%" width={'100%'}>
      <FBOTitleBar title="Bundle Items">
        <Box display="flex" flexGrow={1} />
        {!_.isEmpty(selectedItems) && (
          <FBOButton
            color="negative"
            variant="secondary"
            onClick={handleDeleteModalVisible(true)}
            permissions={editPermissions}
            icon={IconNames.TrashCan}
            data-qa="bundle-items-delete-button"
            sx={{ marginRight: '8px' }}
          >
            Delete
          </FBOButton>
        )}
        <FBOButton
          color="positive"
          variant="secondary"
          onClick={handleAddNewClicked}
          icon={IconNames.FBOAddCircle}
          permissions={editPermissions}
          size="medium"
          data-qa="bundle-items-new"
        >
          Add New
        </FBOButton>
      </FBOTitleBar>
      <ItemsTable
        data={item.bundleItems}
        columns={BUNDLE_ITEMS_COLUMNS}
        RenderCustomRow={BundleItemRow}
        selectedItems={selectedItems}
        onSelectedChange={handleSelectedChange}
        onItemClick={handleBundleItemClicked}
        filterRows={filterBundleItems}
        dataQa="bundle-item-table"
      />
      <BundleItemModal
        show={showModal}
        item={item}
        bundleItem={activeBundleItem}
        setBundleItem={setActiveBundleItem}
        onApplyClicked={handleModalApplyClicked}
        onCancelClicked={handleModalCancelClicked}
      />
      <ConfirmationModal
        open={deleteModalVisible}
        title="Delete Selected Bundle Items"
        body={`This will delete selected items, are you sure?`}
        onCancelClicked={handleDeleteModalVisible(false)}
        onConfirmClicked={handleDeleteClicked}
        confirmLabel="Delete"
        cancelLabel="Cancel"
        confirmButtonRed
      />
    </Box>
  );
};

export default memo(BundleItemsTab);
