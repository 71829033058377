import * as yup from 'yup';

import { CustomerCarriersBillingType } from 'services/shipping';

interface SchemaParams {
  billingType: CustomerCarriersBillingType | null;
  packagingCarrier: string | null;
}

export const fetchRatesValidationShippo = yup.object().shape({
  recipientPhone: yup
    .mixed()
    .when(
      ['billingType', 'packagingCarrier'],
      (params: SchemaParams, schema: any) => {
        if (
          params.billingType === CustomerCarriersBillingType.Recipient ||
          params.billingType === CustomerCarriersBillingType.Third_Party ||
          params.packagingCarrier === 'fedex'
        ) {
          return yup.string().required();
        }

        return schema;
      }
    ),
  recipientEmail: yup
    .mixed()
    .when(
      ['billingType', 'packagingCarrier'],
      (params: SchemaParams, schema: any) => {
        if (
          params.billingType === CustomerCarriersBillingType.Recipient ||
          params.billingType === CustomerCarriersBillingType.Third_Party ||
          params.packagingCarrier === 'fedex'
        ) {
          return yup.string().required().email();
        }

        return schema;
      }
    ),
  dryIceWeight: yup
    .mixed()
    .when(['containsDryIce'], (containsDryIce: boolean, schema: any) => {
      return containsDryIce ? yup.number().required() : schema;
    }),
  dryIceWeightUnit: yup
    .mixed()
    .when(['containsDryIce'], (containsDryIce: boolean, schema: any) => {
      return containsDryIce ? yup.string().required() : schema;
    }),
  recipientType: yup
    .mixed()
    .when(['containsAlcohol'], (containsAlcohol: boolean, schema: any) => {
      return containsAlcohol ? yup.string().required() : schema;
    }),
  codMethod: yup.mixed().when(['cod'], (cod: boolean, schema: any) => {
    return cod ? yup.string().required() : schema;
  }),
  codAmount: yup.mixed().when(['cod'], (cod: boolean, schema: any) => {
    return cod ? yup.number().required() : schema;
  }),
  signatureType: yup
    .mixed()
    .when(
      ['signatureConfirmation'],
      (signatureConfirmation: boolean, schema: any) => {
        return signatureConfirmation ? yup.string().required() : schema;
      }
    ),
  billingAccount: yup
    .mixed()
    .when(
      ['billingType'],
      (billingType: CustomerCarriersBillingType | null, schema: any) => {
        if (billingType === CustomerCarriersBillingType.Recipient) {
          return yup.string().required();
        }

        return schema;
      }
    ),
});
