import React, { memo, useCallback } from 'react';
import { Link, TableCell } from '@mui/material';
import _ from 'lodash';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { GlobalUser } from 'services/user/types';
import moment from 'moment';

const RemoteSupportPageTableRow: React.FC<RenderCustomRowProps<GlobalUser>> = (
  props
) => {
  const { row, onAction } = props;

  const onLogInClicked = useCallback(
    (e: any) => {
      e.stopPropagation();
      if (onAction) {
        onAction(row);
      }
    },
    [onAction, row]
  );

  const lastLogin = moment(_.get(row, 'dateLoginAttempt')).format('MM/DD/YYYY');

  return (
    <>
      <TableCell>{_.get<GlobalUser, 'nameFirst'>(row, 'nameFirst')}</TableCell>
      <TableCell>{_.get<GlobalUser, 'nameLast'>(row, 'nameLast')}</TableCell>
      <TableCell>{_.get<GlobalUser, 'email'>(row, 'email')}</TableCell>
      <TableCell>{_.get<GlobalUser, 'tenant'>(row, 'tenant')}</TableCell>
      <TableCell>{_.get<GlobalUser, 'name'>(row, 'name')}</TableCell>
      <TableCell>
        {_.get(row, 'company.crmAssociations[0].crmId') == undefined
          ? ''
          : _.get(row, 'company.crmAssociations[0].crmId') || ''}
      </TableCell>

      <TableCell>
        {lastLogin == 'Invalid date' ? '' : lastLogin || ''}
      </TableCell>

      <TableCell>
        {' '}
        {_.get(row, 'tenantAccountType') === 'PREVIOUS_CUSTOMER'
          ? 'PREVIOUS CUSTOMER'
          : _.get(row, 'tenantAccountType')}
      </TableCell>
      <TableCell padding="none" align="center">
        <Link
          variant="body2"
          color="primary"
          onClick={onLogInClicked}
          underline="hover"
        >
          LOG IN AS USER
        </Link>
      </TableCell>
    </>
  );
};

export default memo(RemoteSupportPageTableRow);
