import { Theme } from '@mui/material/styles';
import { makeStyles, CSSProperties } from '@mui/styles';
import { IconItemBoxStyleProps } from './types';

export const useIconItemBoxStyles = makeStyles<Theme, IconItemBoxStyleProps>(
  (theme: Theme) => {
    const textEllipsis: CSSProperties = {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    };

    return {
      iconWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 40,
        height: 40,
        minWidth: 40,
        minHeight: 40,
      },
      content: {
        ...textEllipsis,
        paddingLeft: theme.spacing(2),
      },
      heading: {
        ...textEllipsis,
        fontStyle: ({ name }) => (name ? 'normal' : 'italic'),
        paddingRight: ({ name }) => (name ? 0 : 2),
      },
      subHeading: textEllipsis,
    };
  }
);
