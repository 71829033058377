import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import {
  initialChannel,
  Channel,
  ChannelStatus,
  channelSettingsPut,
  getChannelSettings,
} from 'services/commerce/channels';
import { colorPalette } from 'ui/theme';
import { DetailsCard } from 'ui/components/Page/DetailsCard';
import { ChannelSettings } from 'services/commerce/channels';
import { ChannelDetailsCardProps } from './types';
import { StatusLabel } from 'ui/components/Status/StatusLabel';
import { OrderSettings } from './OrderSettings';
import { ProductImportSettings } from './ProductImportSettings';
import { CustomerSettings } from './CustomerSettings';
import { initialChannelSettings } from './consts';
import { Errors, validateYup } from 'services/forms/validation';
import {
  yupChannelSettingsSchema,
  yupChannelSettingsSchemaWithAlternates,
} from './validations';
import { showNotification } from 'services/api';
import { useUrlQueryObject } from 'services/url';
import { PosSettings } from './PosSettings';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { useLocation, useNavigate } from 'react-router-dom';

const ChannelDetailsCard: React.FC<ChannelDetailsCardProps> = (props) => {
  const { activeItemId, channels, onClose, fetchChannels } = props;
  const oldState = useRef<ChannelSettings | null>(initialChannelSettings);

  const [activeChannel, setActiveChannel] = useState<Channel | null>(
    initialChannel
  );
  const [isLoading, setIsLoading] = useState(false);
  const [channelSettings, setChannelSettings] = useState<ChannelSettings>(
    initialChannelSettings
  );
  const [showAlternateSettings, setShowAlternateSettings] =
    useState<boolean>(false);
  const [alternateSettingsTitle, setAlternateSettingsTitle] =
    useState<string>('');
  const [validationErrors, setValidationErrors] = useState<Errors>({});

  const navigate = useNavigate();
  const location = useLocation();
  const [, setQueryParams] = useUrlQueryObject(navigate, location);

  useEffect(() => {
    switch (activeChannel?.vendor) {
      case 'Walmart':
        setShowAlternateSettings(true);
        setAlternateSettingsTitle('Walmart WFS');
        break;
      case 'Shopify':
        setShowAlternateSettings(true);
        setAlternateSettingsTitle('Shopify POS');
        break;
      case 'Amazon':
        setShowAlternateSettings(true);
        setAlternateSettingsTitle('Amazon FBA');
        break;
      default:
        setShowAlternateSettings(false);
        setAlternateSettingsTitle('');
        break;
    }
  });

  const rowStatus =
    activeChannel?.status === 'CONNECTED'
      ? ChannelStatus.Connected
      : ChannelStatus.Disconnected;

  const callGetChannelSettings = async (id: number) => {
    try {
      const response = await getChannelSettings(id);
      oldState.current = response;
      setChannelSettings(response);
      setIsLoading(false);
    } catch (e) {
      showNotification('Error retrieving channel settings', {
        variant: 'error',
      });
      onClose();
    }
  };

  useEffect(() => {
    setChannelSettings(initialChannelSettings);
    oldState.current = initialChannelSettings;
    setIsLoading(true);
    setValidationErrors({});

    if (!activeItemId) {
      setActiveChannel(initialChannel);
      setIsLoading(false);
      return;
    }

    callGetChannelSettings(activeItemId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItemId]);

  useEffect(() => {
    setActiveChannel(
      channels[channels?.findIndex((channel) => channel?.id === activeItemId)]
    );
  }, [channels, activeItemId]);

  const validationSchema = showAlternateSettings
    ? yupChannelSettingsSchemaWithAlternates
    : yupChannelSettingsSchema;

  const saveClicked = useCallback(
    async (close: boolean = false) => {
      if (
        !validateYup(channelSettings, validationSchema, setValidationErrors)
      ) {
        setIsLoading(false);
        return false;
      }

      setIsLoading(true);

      try {
        await channelSettingsPut(activeChannel?.id || -1, channelSettings);
      } catch (e) {
        setIsLoading(false);
        return false;
      }
      oldState.current = channelSettings;
      if (activeItemId) {
        await callGetChannelSettings(activeItemId);
        fetchChannels();
      }

      setIsLoading(false);

      if (close) {
        setQueryParams({ activeId: null });
        onClose();
      }

      return true;
    },
    [channelSettings, validationSchema]
  );

  return (
    <DetailsCard
      onSubmit={saveClicked}
      isLoading={isLoading}
      state={channelSettings}
      oldState={oldState}
    >
      <FBOTitleBar
        title={
          <>
            {`${activeChannel?.vendor} - ${activeChannel?.label} `}
            <StatusLabel
              status={rowStatus}
              data-qa={`channel-row${activeChannel?.id}-status`}
            />
          </>
        }
        sx={{
          borderBottom: `1px solid ${colorPalette.redesign.background3}`,
        }}
      >
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          onClick={() => saveClicked(false)}
          data-qa="channel-details-save"
        >
          Save
        </FBOButton>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          onClick={() => saveClicked(true)}
          data-qa="channel-details-save-and-close"
        >
          Save and Close
        </FBOButton>
        <FBOButton
          variant="tertiary"
          color="neutral"
          size="medium"
          icon="FBOClose"
          data-qa="channel-details-close"
          onClick={onClose}
        />
      </FBOTitleBar>

      <Box px={4} py={5} width="100%" overflow="auto">
        <Grid container spacing={5}>
          <OrderSettings
            activeChannel={activeChannel}
            channelSettings={channelSettings}
            setChannelSettings={setChannelSettings}
            errors={validationErrors}
          />
          <ProductImportSettings
            channelSettings={channelSettings}
            setChannelSettings={setChannelSettings}
            errors={validationErrors}
          />
          <CustomerSettings
            channelSettings={channelSettings}
            setChannelSettings={setChannelSettings}
            errors={validationErrors}
          />
          {showAlternateSettings && (
            <PosSettings
              title={alternateSettingsTitle}
              activeChannel={activeChannel}
              channelSettings={channelSettings}
              setChannelSettings={setChannelSettings}
              errors={validationErrors}
            />
          )}
        </Grid>
      </Box>
    </DetailsCard>
  );
};

export default ChannelDetailsCard;
