import React, { memo, useCallback } from 'react';
import { Grid, FormControlLabel, Checkbox } from '@mui/material';

import { AdvancedSearchFieldsCmpProps } from 'ui/components/Page/PageWithAdvancedSearch';

const CurrencyAdvancedSearch: React.FC<AdvancedSearchFieldsCmpProps> = (
  props
) => {
  const { setFormValues, formValues } = props;

  const handleShowDeletedChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
      setFormValues({ ...formValues, deleted: checked }),
    [formValues, setFormValues]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={!!formValues.deleted}
              onChange={handleShowDeletedChange}
              color="primary"
              data-qa="show-deleted"
            />
          }
          label="Show Deleted"
        />
      </Grid>
    </Grid>
  );
};

export default memo(CurrencyAdvancedSearch);
