import { createApiAction } from 'services/api';
import {
  StartAction,
  SuccessAction,
  FailureAction,
} from 'services/api/redux/types';
import {
  createAddSearch,
  createDeleteSearch,
  createFetchSearch,
  createUpdateSearch,
} from 'services/search';

export enum PurchaseOrderActionTypes {
  FETCH_PURCHASE_ORDER_START = 'purchase_order/fetch_purchase_order_start',
  FETCH_PURCHASE_ORDER_SUCCESS = 'purchase_order/fetch_purchase_order_success',
  FETCH_PURCHASE_ORDER_FAILURE = 'purchase_order/fetch_purchase_order_failure',
}

// PURCHASE ORDER ACTIONS
const fetchPurchaseOrderActionStart = (): StartAction => ({
  type: PurchaseOrderActionTypes.FETCH_PURCHASE_ORDER_START,
  payload: null,
});

const fetchPurchaseOrderActionSuccess = (payload: any): SuccessAction => {
  return {
    type: PurchaseOrderActionTypes.FETCH_PURCHASE_ORDER_SUCCESS,
    payload,
  };
};

const fetchPurchaseOrderActionFailure = (error: string): FailureAction => ({
  type: PurchaseOrderActionTypes.FETCH_PURCHASE_ORDER_FAILURE,
  payload: { error },
});

export const fetchPurchaseOrder = () =>
  createApiAction(
    {
      path: '/v1/purchase_orders?pageNumber=1&pageSize=100',
      method: 'GET',
    },
    fetchPurchaseOrderActionStart,
    fetchPurchaseOrderActionSuccess,
    fetchPurchaseOrderActionFailure
  );

// SEARCH ACTION CREATORS

export const fetchSearch = createFetchSearch('purchaseOrder');
export const addSearch = createAddSearch('purchaseOrder');
export const updateSearch = createUpdateSearch('purchaseOrder');
export const deleteSearch = createDeleteSearch('purchaseOrder');
