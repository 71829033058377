import React, { memo, useCallback } from 'react';
import { PermissionType } from 'services/permissions';
import { CarrierTitleBarProps } from './types';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const CarriersTitleBar: React.FC<CarrierTitleBarProps> = (props) => {
  const {
    activeCarrier,
    onSave,
    onClose,
    deleteModalVisible,
    onUndeleteClicked,
  } = props;

  const DeletedCarrierActionsRestyle = useCallback(() => {
    return (
      <>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="carrier-undelete"
          onClick={() => onUndeleteClicked(false)}
          permissions={
            activeCarrier.id && activeCarrier.id > 0
              ? [PermissionType.CarriersEdit]
              : [PermissionType.CarriersCreate]
          }
        >
          Undelete
        </FBOButton>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="carrier-undelete-and-close"
          onClick={() => onUndeleteClicked(true)}
          permissions={
            activeCarrier.id && activeCarrier.id > 0
              ? [PermissionType.CarriersEdit]
              : [PermissionType.CarriersCreate]
          }
        >
          Undelete And Close
        </FBOButton>
      </>
    );
  }, [onUndeleteClicked, activeCarrier.id]);

  const CarrierActionsRestyle = useCallback(() => {
    return (
      <>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          type="submit"
          data-qa="carrier-save"
          permissions={
            activeCarrier.id && activeCarrier.id > 0
              ? [PermissionType.CarriersEdit]
              : [PermissionType.CarriersCreate]
          }
        >
          Save
        </FBOButton>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="carrier-save-and-close"
          onClick={() => onSave(true)}
          permissions={
            activeCarrier.id && activeCarrier.id > 0
              ? [PermissionType.CarriersEdit]
              : [PermissionType.CarriersCreate]
          }
        >
          Save And Close
        </FBOButton>
        {activeCarrier.id && activeCarrier.id > 0 && (
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="secondary"
            color="negative"
            size="medium"
            icon="TrashCan"
            data-qa="carrier-delete"
            onClick={() => deleteModalVisible(true)}
            permissions={[PermissionType.CarriersDelete]}
          >
            Delete
          </FBOButton>
        )}
      </>
    );
  }, [activeCarrier.id, deleteModalVisible, onSave]);

  return (
    <FBOTitleBar title={`${activeCarrier.name || 'New Carrier'}`}>
      {activeCarrier.deleted ? (
        <DeletedCarrierActionsRestyle />
      ) : (
        <CarrierActionsRestyle />
      )}
      <FBOButton
        variant="tertiary"
        color="neutral"
        size="medium"
        icon="FBOClose"
        data-qa="carrier-close"
        onClick={onClose}
      />
    </FBOTitleBar>
  );
};

export default memo(CarriersTitleBar);
