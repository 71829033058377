import _ from 'lodash';

import { Permission, PermissionArea, PermissionType } from './types';

/**
 * @param allowedPermissions
 * @param userPermissions
 *
 * Check for permission.
 * If no allowed permission is sent OR user has at least 1 permission, return TRUE
 */
export const hasPermission = (
  allowedPermissions: PermissionType[],
  userPermissions: PermissionType[],
  isAdmin: boolean
) => {
  return (
    !allowedPermissions?.includes(PermissionType.Undelete) &&
    (isAdmin ||
      allowedPermissions?.length === 0 ||
      _.difference(allowedPermissions, userPermissions).length <
        allowedPermissions?.length)
  );
};

export const defaultSortFunction = (a: Permission, b: Permission) => {
  return _.toLower(a.key ?? '').localeCompare(_.toLower(b.key ?? ''));
};

export function sortPermissionAreaByKey(order: string[]) {
  return (a: PermissionArea, b: PermissionArea) => {
    const indexA = _.indexOf(order, a.key ?? '');
    const indexB = _.indexOf(order, b.key ?? '');
    return indexA === -1 ? 1 : indexB === -1 ? -1 : indexA - indexB;
  };
}
