import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { SupportColors } from 'ui/theme';

export const usePickItemCommitRowStyle = makeStyles((theme: Theme) => ({
  columnItem: {
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 40px)',
  },
  checkIcon: {
    marginRight: theme.spacing(2.25),
  },
  checkIconSuccess: {
    color: SupportColors.statusGreen,
  },
  checkIconPartial: {
    color: SupportColors.statusOrange,
  },
}));
