import {
  createApiCall,
  DataWithPagination,
  paginatedApiCall,
} from 'services/api';
import { Address, transformAddressRequest } from 'services/addresses';
import { RequestConfig, defaultMaximumPagination } from 'helpers';

import {
  transformVendor,
  transformVendorToRequestVendor,
} from './transformations';
import { Vendor } from './types';

const expands = ['vendorAddresses.vendorAddressContacts', 'tags', 'currency'];

export const fetchVendorsAPI = async (
  config: RequestConfig
): Promise<DataWithPagination<Vendor>> => {
  const {
    pagination = defaultMaximumPagination,
    expands = [],
    customQuickSearchColumns = ['name'],
    quickSearchValue,
  } = config;

  let path = `/v1/vendors?expand=${expands.join(',')}`;

  if (quickSearchValue) {
    path += `&quickSearchColumns=${customQuickSearchColumns}&quickSearchValue=${quickSearchValue}`;
  }

  const res = await paginatedApiCall(path, pagination, transformVendor);

  return res;
};

export const fetchVendorById = async (id: number): Promise<Vendor> => {
  const response = await createApiCall({
    path: `/v1/vendors/${id}?expand=${expands.join(',')}`,
    method: 'GET',
  })();

  return transformVendor(response.data);
};

export const createVendor = async (vendor: Vendor): Promise<Vendor> => {
  const response = await createApiCall(
    {
      path: `/v1/vendors?expand=${expands.join(',')}`,
      method: 'POST',
      body: transformVendorToRequestVendor(vendor),
    },
    {
      getMessage: {
        success: () => 'New vendor successfully added',
        error: (error: any) => error.response.data.message,
      },
    }
  )();

  return transformVendor(response.data);
};

export const updateVendor = async (vendor: Vendor): Promise<Vendor> => {
  const response = await createApiCall(
    {
      path: `/v1/vendors?expand=${expands.join(',')}`,
      method: 'PUT',
      body: transformVendorToRequestVendor(vendor),
    },
    {
      getMessage: {
        success: () => 'Vendor successfully updated',
        error: (error: any) => error.response.data.message,
      },
    }
  )();

  return transformVendor(response.data);
};

export const deleteVendor = async (id: number): Promise<void> => {
  await createApiCall(
    {
      path: `/v1/vendors/${id}`,
      method: 'DELETE',
    },
    {
      getMessage: {
        success: () => 'Vendor successfully deleted',
        error: (error: any) => error.response.data.message,
      },
    }
  )();
};

export const deleteVendors = async (ids: number[]): Promise<void> => {
  await createApiCall(
    {
      path: `/v1/vendors/`,
      method: 'DELETE',
      body: ids,
    },
    {
      getMessage: {
        success: () => 'Vendors successfully deleted',
        error: (error: any) => error.response.data.message,
      },
    }
  )();
};

/* VENDOR ADDRESS */
export const postVendorAddress = async (
  vendorId: number,
  address: Address
): Promise<Vendor> => {
  const response = await createApiCall(
    {
      path: `/v1/vendors/${vendorId}/addresses`,
      method: 'POST',
      body: [transformAddressRequest(address)],
    },
    {
      getMessage: {
        success: () => 'New address successfully added',
        error: (error: any) => error.response.data.message,
      },
    }
  )();

  return transformVendor(response.data);
};

export const deleteVendorAddress = (vendorId: number, addressesIds: number[]) =>
  createApiCall(
    {
      path: `/v1/vendors/${vendorId}/addresses`,
      method: 'DELETE',
      body: addressesIds,
    },
    {
      getMessage: {
        success: () => 'Addresses successfully removed',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

export const restoreVendor = async (vendorId: number) => {
  await createApiCall(
    {
      path: `/v1/vendors/${vendorId}/restore`,
      method: 'POST',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
        success: () => 'Vnedor successfully restored',
      },
    }
  )();
};
