export enum ThumbnailActionType {
  SHOW_THUMBNAIL = 'thumbnail/show_thumbnail',
  HIDE_THUMBNAIL = 'thumbnail/hide_thumbnail',
}

export interface ThumbnailState {
  show: boolean;
  imageUrl: string;
}

export interface ThumbnailAction {
  type: ThumbnailActionType;
  payload?: string;
}
