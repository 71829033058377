import { Ledger, LedgerSimple } from './types';

export const initialLedger: Ledger = {
  id: null,
  accountName: null,
  startBalance: null,
  accountType: null,
  endBalance: null,
  ledgers: [],
  markedAsPosted: null,
  total: null,
};

export const initialLedgerSimple: LedgerSimple = {
  date: null,
  ledgerId: null,
  ledgerItems: [],
  name: null,
  refNo: null,
  type: null,
  customerId: null,
  accountingClassId: null,
};
