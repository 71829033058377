import React from 'react';
import _ from 'lodash';
import { TableCell } from '@mui/material';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import { ImageItemBox } from 'ui/components/Image/ImageItemBox';
import { TopSellingItem } from 'services/dashboard';

const TopSellingItemsRow: React.FC<RenderCustomRowProps<TopSellingItem>> = (
  props
) => {
  const { row } = props;

  return (
    <>
      <TableCell>
        <ImageItemBox
          source={_.get(row, 'defaultImage', null)}
          name={_.get(row, 'name', null)}
          description={_.get(row, 'description', null)}
        />
      </TableCell>
      <TableCell>{_.get(row, 'upc', null)}</TableCell>
      <TableCell>{_.get(row, 'sku', null)}</TableCell>
      <TableCell>{_.get(row, 'quantity', null)}</TableCell>
    </>
  );
};

export default React.memo(TopSellingItemsRow);
