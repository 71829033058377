import {
  Carrier,
  CarrierService,
  defaultCarrierService,
} from 'services/carriers';
import { findNextNegativeId } from 'helpers';

export const createNewCarrierService = (carrier: Carrier): CarrierService => {
  return {
    ...defaultCarrierService,
    id: findNextNegativeId<CarrierService>(carrier.carrierServiceList),
    carrierId: carrier.id,
  };
};
