import {
  ReceiptItem,
  ReceiptItemStatus,
  ReceiptItemReceive,
  ReceiptItemType,
} from 'services/receiving';
import { ItemTrackingType, TrackingGroup } from 'services/inventory';
import _, { isEmpty, isNumber } from 'lodash';

export const resolveReceiptItemsToFulfill = (receiptItems: ReceiptItem[]) => {
  return receiptItems.filter(
    (ri) =>
      ri.status !== ReceiptItemStatus.Fulfilled &&
      ri.status !== ReceiptItemStatus.Hold
  );
};

export const resolveItemsToReceive = (
  receiptItems: ReceiptItem[],
  fulfilledClicked: boolean
) => {
  if (fulfilledClicked) {
    return receiptItems.filter(
      (i) =>
        i.status === ReceiptItemStatus.Open ||
        i.status === ReceiptItemStatus.Received ||
        i.status === ReceiptItemStatus.Reconciled
    );
  }
  return receiptItems.filter(
    (i) =>
      i.status === ReceiptItemStatus.Open ||
      i.status === ReceiptItemStatus.Reconciled
  );
};

const isValidQuantity = (quantity: number | null) =>
  quantity && isNumber(quantity) && quantity > 0;

export const isValidReceiptItemReceives = ({
  quantity,
  trackingGroups,
}: ReceiptItemReceive) => {
  if (isValidQuantity(quantity)) {
    if (!isEmpty(trackingGroups)) {
      return trackingGroups.some((tg: TrackingGroup) =>
        isValidQuantity(tg.quantity)
      );
    }
    return true;
  }
  return false;
};

export const receiptContainsOnlyDropShipItems = (
  receiptItems: ReceiptItem[]
) => {
  if (receiptItems.length === 0) {
    return false;
  } else {
    return receiptItems.every(
      (ri) => ri.receiptItemType === ReceiptItemType.DropShip
    );
  }
};

export const serialRows = (receive: ReceiptItemReceive): any[] => {
  return [...(Array(receive.quantity ?? 0).keys() as any)].map(
    (index: number) => ({
      id: -1 - index,
      committed: false,
      serialNumbers: _.get(
        receive,
        'receiptItem.item.itemTrackingTypeList',
        []
      ).reduce(
        (o: any, type: ItemTrackingType) => ({
          ...o,
          [type.trackingTypeId]: null,
        }),
        {}
      ),
    })
  );
};
