import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { colorPalette } from 'ui/theme';

export const useTagsPresentationalComponentStyles = makeStyles(
  (theme: Theme) => ({
    tagContainer: {
      margin: '8px 0',
      height: '100%',
      display: 'flex',
      maxWidth: '100%',
      flexWrap: 'wrap',
      overflow: 'auto',
    },
    tag: {
      background: colorPalette.applicationLightGray,
      borderRadius: 20,
      margin: '0 4px 8px 4px',
      color: colorPalette.applicationDarkGray80,
      '& span': {
        color: colorPalette.applicationDarkGray80,
        fontSize: theme.typography.body2.fontSize,
        fontWeight: 600,
      },
    },
  })
);
