import React, { useEffect } from 'react';
import {
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@mui/material';
import { useSelector } from 'react-redux';

import { NetworkSpinnerWrapper } from 'ui/components/NetworkSpinnerWrapper';
import { themeRestyle } from 'ui/theme';
import { activeUserHasPermission } from 'services/user/redux';

import { ModalProps } from './types';
import { useModalStyle } from './styled';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';

export const Modal = (props: ModalProps) => {
  const {
    applyDisabled = false,
    applyLabel,
    cancelLabel,
    children,
    confirmButtonRed = false,
    customHeight,
    dataQa,
    disableEnter = false,
    footerDivider,
    fullScreen,
    isLoading,
    isLoadingContent,
    ModalActionsComponent,
    onApplyClicked,
    onCancelClicked,
    onResetClicked,
    open,
    permissions = [],
    renderNavigation,
    resetButtonVisible = true,
    resetLabel,
    status,
    title,
    TitleBarComponent,
    withoutCloseButton = false,
    withoutDefaultPadding = false,
    withoutFooter = false,
    ...otherProps
  } = props;

  const _customHeight = customHeight;
  const _footerDivider = footerDivider;
  const _withoutDefaultPadding = withoutDefaultPadding;

  const canEdit = useSelector(activeUserHasPermission(permissions));

  const isDisabled = applyDisabled || !canEdit;

  const classes = useModalStyle(props);

  const onApplyHandler = (e: any) => {
    // If enter is pressed and other conditions are met :)
    if (e.keyCode === 13 && onApplyClicked && open && !disableEnter) {
      onApplyClicked(e);
    }
  };

  useEffect(() => {
    if (window !== undefined) {
      document.addEventListener('keydown', onApplyHandler);

      return () => document.removeEventListener('keydown', onApplyHandler);
    }
  });
  if (!open) {
    return null;
  }

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.modalRoot }}
      fullScreen={fullScreen}
      fullWidth
      maxWidth="md"
      onClose={onCancelClicked}
      data-qa={`${dataQa}-dialog`}
      {...otherProps}
    >
      <DialogTitle className={classes.modalHeader}>
        {TitleBarComponent ? (
          <TitleBarComponent />
        ) : (
          <FBOTitleBar
            title={title}
            status={status}
            sx={{ padding: `${themeRestyle.spacing(4)} !important` }}
          >
            <FBOButton
              variant="tertiary"
              color="neutral"
              size="medium"
              icon="FBOClose"
              data-qa={`close-${dataQa}`}
              onClick={onCancelClicked}
            />
          </FBOTitleBar>
        )}
      </DialogTitle>

      <DialogContent className={classes.modalContent}>
        {renderNavigation && (
          <Box className={classes.tabsBackground}>{renderNavigation()}</Box>
        )}
        <Box
          className={`${classes.modalContentInner} modal-content-inner`}
          data-qa={`${dataQa}-dialog-content`}
        >
          {children}
        </Box>
      </DialogContent>

      {!withoutFooter && (
        <DialogActions className={classes.modalFooter}>
          {ModalActionsComponent ? (
            <ModalActionsComponent />
          ) : (
            <>
              {onResetClicked && resetButtonVisible ? (
                <FBOButton
                  variant="secondary"
                  color="neutral"
                  size="medium"
                  onClick={onResetClicked}
                  data-qa={`${dataQa}-reset`}
                >
                  {resetLabel ?? 'Reset'}
                </FBOButton>
              ) : null}
              <Box className={classes.buttonGroup}>
                {withoutCloseButton ? null : onCancelClicked ? (
                  <FBOButton
                    variant="secondary"
                    color="positive"
                    size="medium"
                    onClick={onCancelClicked}
                    data-qa={`${dataQa}-cancel`}
                  >
                    {cancelLabel ?? 'Cancel'}
                  </FBOButton>
                ) : null}
                {onApplyClicked ? (
                  <NetworkSpinnerWrapper isLoading={!!isLoading} size={24}>
                    <FBOButton
                      variant="primary"
                      color={confirmButtonRed ? 'negative' : 'positive'}
                      size="medium"
                      onClick={onApplyClicked}
                      disabled={isDisabled}
                      data-qa={`${dataQa}-apply`}
                      sx={{
                        minWidth: 0,
                        marginLeft: '16px',
                      }}
                    >
                      {applyLabel ?? 'Save'}
                    </FBOButton>
                  </NetworkSpinnerWrapper>
                ) : null}
              </Box>
            </>
          )}
        </DialogActions>
      )}

      {isLoadingContent && (
        <Box className={classes.modalLoaderBackground}>
          <CircularProgress
            size={48}
            color="primary"
            className={classes.modalLoader}
            data-qa="network-spinner"
          />
        </Box>
      )}
    </Dialog>
  );
};
