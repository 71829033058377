import { defaultMaximumPagination, RequestConfig } from 'helpers';
import {
  createApiCall,
  DataWithPagination,
  paginatedApiCall,
} from 'services/api';
import { transformClass, transformToRequestClass } from './transformations';
import { Class } from './types';

export const postClass = async (data: Class): Promise<Class> => {
  const res = await createApiCall(
    {
      path: '/v1/accounting_class',
      method: 'POST',
      body: transformToRequestClass(data),
    },
    {
      getMessage: {
        success: () => 'Class successfully created',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformClass(res.data);
};

export const putClass = async (data: Class): Promise<Class> => {
  const res = await createApiCall(
    {
      path: '/v1/accounting_class',
      method: 'PUT',
      body: transformToRequestClass(data),
    },
    {
      getMessage: {
        success: () => 'Class successfully updated',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformClass(res.data);
};

export const fetchClassesAPI = async (
  config: RequestConfig
): Promise<DataWithPagination<Class>> => {
  const { pagination = defaultMaximumPagination } = config;
  const path = `/v1/accounting_class`;

  const res = await paginatedApiCall(path, pagination, transformClass);
  return res;
};

export const getClassById = async (id: number): Promise<Class> => {
  const res = await createApiCall(
    {
      path: `/v1/accounting_class/${id}`,
      method: 'GET',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformClass(res.data);
};

export const deleteClassById = async (id: number): Promise<void> => {
  await createApiCall(
    {
      path: `/v1/accounting_class/${id}`,
      method: 'DELETE',
    },
    {
      getMessage: {
        success: () => 'Class successfully deleted',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();
};
