import React, { useCallback, memo, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import {
  putShippingSettings,
  ShippingSettings,
} from 'services/settings/shipping';
import {
  fetchSettingsShipping,
  getSettingsShipping,
} from 'services/settings/shipping/redux';
import { PermissionType } from 'services/permissions';

import { shippingRowDescriptor, shippingCardId, title } from './consts';
import { ShippingCardProps } from './types';
import { Card } from '../Card';
import { searchFilter, searchTitle } from '../../helpers';

const ShippingCard: React.FunctionComponent<ShippingCardProps> = (props) => {
  const { search } = props;
  const dispatch = useDispatch();

  const [shippingSettings, setShippingSettings] = useState<ShippingSettings>();
  const shippingSettingsCopy = useSelector(getSettingsShipping);

  const regex = useMemo(() => {
    return RegExp(_.escapeRegExp(search), 'gi');
  }, [search]);

  const hasSearch = useMemo(() => {
    return shippingRowDescriptor.some((t) => regex.test(t.title));
  }, [regex]);

  const hasTitle = useMemo(() => {
    return new RegExp(_.escapeRegExp(search), 'i').test(title);
  }, [search]);

  const highlightedRow = useMemo(() => {
    if (hasSearch) {
      return searchFilter(shippingRowDescriptor, regex);
    }
    return shippingRowDescriptor;
  }, [hasSearch, regex]);

  const highlightedTitle = useMemo(() => {
    if (hasTitle) {
      return searchTitle(title, regex);
    }
    return title;
  }, [hasTitle, regex]);

  useEffect(() => {
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchSettingsShipping());
  }, [dispatch]);

  useEffect(() => {
    setShippingSettings(shippingSettingsCopy);
  }, [shippingSettingsCopy]);

  const handleApplyClicked = useCallback(async (newShippingSettings) => {
    try {
      const shipping = await putShippingSettings(newShippingSettings);
      setShippingSettings(shipping);
    } catch {
      // Ignore error
    }
  }, []);

  return (
    <Card
      title={highlightedTitle}
      rows={highlightedRow}
      id={shippingCardId}
      data={shippingSettings}
      onApplyClicked={handleApplyClicked}
      show={hasSearch || hasTitle}
      viewPermissions={[PermissionType.SettingsShippingView]}
      editPermissions={[PermissionType.SettingsShippingEdit]}
      dataQa="shipping-settings"
    />
  );
};

export default memo(ShippingCard);
