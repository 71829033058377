import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Link, TableCell, Typography } from '@mui/material';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import { ShippingEventHistory } from 'services/integrations/shipping';
import { getCarriers } from 'services/carriers';
import { getCarrierUrl } from 'services/shipping/helpers';
import { useGetIntlDateFormatString } from 'helpers';

const HistoryCardRow: React.FC<RenderCustomRowProps<ShippingEventHistory>> = (
  props
) => {
  const { row } = props;

  const { items: carriers } = useSelector(getCarriers);
  const intlFormatDate = useGetIntlDateFormatString();

  const selectedCarrier = useMemo(() => {
    return carriers.find((c) => c.name === row.name);
  }, [carriers, row.name]);

  const handleLinkClicked = () => {
    if (!row.name || !selectedCarrier) {
      return;
    }
    const url = getCarrierUrl(row.number!, selectedCarrier);

    if (!url) {
      return;
    }

    window.open(url, '_blank');
  };

  return (
    <>
      <TableCell>
        <Typography variant="body2" color="textPrimary">
          {row.date ? moment(row.date).format(intlFormatDate) : ''}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" color="textPrimary">
          {row.user}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" color="textPrimary">
          {row.number}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" color="textPrimary">
          {selectedCarrier && selectedCarrier.name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" color="textPrimary">
          {row.type}
        </Typography>
      </TableCell>
      <TableCell>
        <Link
          onClick={handleLinkClicked}
          variant="body2"
          color="textSecondary"
          underline="hover"
        >
          {row.tracking}
        </Link>
      </TableCell>
    </>
  );
};

export default memo(HistoryCardRow);
