import { Theme } from '@mui/material/styles';
import { makeStyles, CSSProperties } from '@mui/styles';
import { ImageItemBoxStyleProps } from './types';

export const useImageItemBoxStyles = makeStyles<Theme, ImageItemBoxStyleProps>(
  () => {
    const textEllipsis: CSSProperties = {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    };

    return {
      content: {
        ...textEllipsis,
        paddingLeft: 10,
      },
      heading: {
        ...textEllipsis,
        fontStyle: ({ name }) => (name ? 'normal' : 'italic'),
        paddingRight: ({ name }) => (name ? 0 : 2),
        lineHeight: 1.2,
      },
      subHeading: {
        ...textEllipsis,
        lineHeight: 1.21,
        marginTop: 2,
      },
    };
  }
);
