import React, { memo } from 'react';
import { Box, Typography } from '@mui/material';

import { ItemsTable } from 'ui/components/Table/ItemsTable';

import { columns } from './consts';
import { OrdersStepProps } from './types';
import OrdersStepRow from './OrdersStepRow';

const OrdersStep: React.FC<OrdersStepProps> = (props) => {
  const { purchaseOrders } = props;

  return (
    <Box
      flexGrow={1}
      flexDirection="column"
      alignContent="center"
      overflow="auto"
    >
      {purchaseOrders.map((po, i) => (
        <React.Fragment key={i}>
          <Box flexGrow={1} boxShadow={1} m={4} p={4}>
            <Typography variant="h5">
              PO: {po.number} - {po.vendor ? po.vendor.name : ''}
            </Typography>
            <ItemsTable
              columns={columns}
              data={po.purchaseOrderItemList}
              selectableItems={false}
              RenderCustomRow={OrdersStepRow}
            />
          </Box>
        </React.Fragment>
      ))}
    </Box>
  );
};

export default memo(OrdersStep);
