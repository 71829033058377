import React, { memo, useCallback, useEffect, useState } from 'react';
import { Box, Grid, Checkbox, Tab, Tabs, Typography } from '@mui/material';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { useSelector } from 'react-redux';

import {
  fetchPermissionCategories,
  PermissionCategory,
  PermissionType,
} from 'services/permissions';
import { TabPanel } from 'ui/components/TabPanel';
import { activeUserHasPermission } from 'services/user/redux';

import { PermissionCategoryState, PermissionsTabProps } from './types';
import { usePermissionsTabStyle } from './styled';
import { PermissionArea } from './components';
import {
  addCategoryPermissions,
  getPermissionCategoryState,
  removeCategoryPermissions,
} from './helpers';

const PermissionsTab: React.FC<PermissionsTabProps> = (props) => {
  const { permissionGroup, setPermissionGroup } = props;

  const [activeTab, setActiveTab] = useState(0);
  const [permissionCategories, setPermissionCategories] = useState<
    PermissionCategory[]
  >([]);

  const canEditPermissions =
    permissionGroup.id && permissionGroup.id > 0
      ? [PermissionType.PermissionEdit]
      : [PermissionType.PermissionCreate];

  const canEdit = useSelector(activeUserHasPermission(canEditPermissions));

  useEffect(() => {
    (async () => {
      try {
        const resPermissionCategories = await fetchPermissionCategories();
        setPermissionCategories(resPermissionCategories);
      } catch {
        // do nothing
      }
    })();
  }, []);

  const handleActiveTabChange = useCallback(
    async (event: React.ChangeEvent<{}>, newValue: number) => {
      setActiveTab(newValue);
    },
    []
  );

  const classes = usePermissionsTabStyle();

  const renderTabLabel = useCallback(
    (permissionCategory: PermissionCategory) => {
      const state = getPermissionCategoryState(
        permissionCategory,
        permissionGroup
      );

      return (
        <span className={classes.tabWrapper}>
          <Checkbox
            className={'redesign large-checkbox'}
            checked={state !== PermissionCategoryState.NotChecked}
            color="primary"
            checkedIcon={
              state === PermissionCategoryState.PartiallyChecked ? (
                <CheckBoxOutlinedIcon />
              ) : undefined
            }
            onClick={(e: any) => {
              e.stopPropagation();
              if (state !== PermissionCategoryState.NotChecked) {
                removeCategoryPermissions(
                  permissionCategory,
                  setPermissionGroup
                );
                return;
              }
              addCategoryPermissions(permissionCategory, setPermissionGroup);
            }}
            disabled={!canEdit || permissionGroup.readOnly}
            data-qa={`permission-${permissionCategory.name}`}
          />
          <Typography variant="body2" color="textPrimary">
            {permissionCategory.name}
          </Typography>
        </span>
      );
    },
    [permissionGroup, canEdit, setPermissionGroup]
  );

  return (
    <Box display="flex" flex={1} className={classes.wrapper}>
      <Box display="flex" className={classes.sidebar}>
        <Tabs
          value={activeTab}
          onChange={handleActiveTabChange}
          textColor="primary"
          className={classes.tabs}
          orientation="vertical"
          sx={{ maxHeight: 'initial', minHeight: 'initial' }}
        >
          {permissionCategories.map((cat, catIndex) => (
            <Tab
              label={renderTabLabel(cat)}
              className={classes.tab}
              key={`cat_tab_${catIndex}`}
            />
          ))}
        </Tabs>
      </Box>

      <Box className={classes.content}>
        {permissionCategories.map((permissionCategory, catIndex) => (
          <TabPanel
            value={activeTab}
            key={`cat_panel_${catIndex}`}
            index={catIndex}
            noSpacing
            overflow
            autoHeight
          >
            <Grid container spacing={0}>
              {permissionCategory.permissionAreas.map(
                (permissionArea, areaIndex) => (
                  <PermissionArea
                    key={`area_${catIndex}_${areaIndex}`}
                    permissionArea={permissionArea}
                    permissionGroup={permissionGroup}
                    setPermissionGroup={setPermissionGroup}
                  />
                )
              )}
            </Grid>
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
};

export default memo(PermissionsTab);
