import React from 'react';
import NumberFormat from 'react-number-format';
import { noop } from 'lodash';

import { NumberQuantityProps } from './types';

const NumberFormatQuantity = (props: NumberQuantityProps) => {
  const { inputRef, onChange, name, allowDecimal, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        // this fixes the bug Maximum update depth exceeded
        if (props.value === values.value) {
          return;
        }
        onChange({
          target: {
            value: values.value,
            name,
          },
          persist: noop,
        });
      }}
      decimalSeparator="."
      isNumericString
      decimalScale={allowDecimal ? 6 : 0}
    />
  );
};

export default NumberFormatQuantity;
