import { Dates } from 'helpers';
import { MenuItem } from 'ui/components/Menu';
import { IconNames } from 'ui/theme';

export enum PricingRulesPageAction {
  ChangeDate,
}

export const createActionBarOptions = (
  onActionClick: (actionType: PricingRulesPageAction, date: Dates) => void
): MenuItem[] => [
  {
    icon: IconNames.FBORecycleSmall,
    label: 'Change Dates',
    subItems: [
      {
        label: 'Date Created',
        onOptionClick: () =>
          onActionClick(PricingRulesPageAction.ChangeDate, Dates.DateCreated),
        separateAfter: true,
      },
      {
        label: 'Date Last Modified',
        onOptionClick: () =>
          onActionClick(
            PricingRulesPageAction.ChangeDate,
            Dates.DateLastModified
          ),
      },
    ],
  },
];
