import { State } from 'app/redux';

export const getPurchaseOrdersAll = (state: State) =>
  state.purchaseOrder.purchaseOrders;

export const getPurchaseOrders = (state: State) =>
  getPurchaseOrdersAll(state).items;

export const getPurchaseOrder = (id: number) => (state: State) =>
  state.purchaseOrder.purchaseOrders.items.filter((item) => item.id === id);

export const getSearches = (state: State) => state.purchaseOrder.searches;
