import { Contact, ContactType } from 'services/addresses';
import { Vendor } from 'services/vendors';
import { defaultContact } from './consts';

export const extractDefaultContacts = (activeVendor: Vendor) => {
  const contacts: Contact[] = [];
  if (activeVendor.email) {
    contacts.push({
      ...defaultContact,
      type: ContactType.email,
      value: activeVendor.email,
      name: 'Email',
    });
  } else if (activeVendor.cc) {
    contacts.push({
      ...defaultContact,
      type: ContactType.email,
      value: activeVendor.cc,
      name: 'Email',
    });
  } else if (activeVendor.bcc) {
    contacts.push({
      ...defaultContact,
      type: ContactType.email,
      value: activeVendor.bcc,
      name: 'Email',
    });
  }

  if (activeVendor.office) {
    contacts.push({
      ...defaultContact,
      type: ContactType.office,
      value: activeVendor.office,
      name: 'Office',
    });
  } else if (activeVendor.mobile) {
    contacts.push({
      ...defaultContact,
      type: ContactType.mobile,
      value: activeVendor.mobile,
      name: 'Mobile',
    });
  } else if (activeVendor.fax) {
    contacts.push({
      ...defaultContact,
      type: ContactType.fax,
      value: activeVendor.fax,
      name: 'Fax',
    });
  }
  return contacts;
};
