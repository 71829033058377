import baseStyled, { ThemedStyledInterface } from 'styled-components';
import { Theme, createTheme } from '@mui/material/styles';
import { createBreakpoints } from '@mui/system';
import createPalette, {
  PaletteOptions,
} from '@mui/material/styles/createPalette';

import { WarningCircle } from './icons/styleguide/WarningCircle';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    appBar: Palette['text'];
    appDrawer: Palette['text'];
    titleBar: Palette['text'];
    breadCrumb: Palette['text'];
  }
  interface PaletteOptions {
    appBar: PaletteOptions['text'];
    appDrawer: PaletteOptions['text'];
    titleBar: PaletteOptions['text'];
    breadCrumb: PaletteOptions['text'];
  }
}

export enum SupportColors {
  dividerOnPrimaryMain = '#595959',
  dividerOnPrimaryDark = '#3F4043',
  iconOnLight = '#939598',
  iconOnDark = '#FFFF',
  tableHeaderTextColor = '#595959',
  buttonDisabled = '#595959',
  buttonDisabledText = '#838383',
  statusSuccess = '#38BB7C',
  statusWarning = '#F4963F',
  disabledIcon = '#D6D6D6',
  tableBorder = '#E0E0E0',
  statusGreen = '#38BB7C',
  statusOrange = '#F4B63F',
  statusRed = '#EF4510',
}

const colorPalette = {
  transparent: '#1fe0',
  white: '#FFF',
  white5: '#ebf0f5',
  grey: '#838383',
  fishbowlCorporateBlue: '#0029f0',
  fishbowlCorporateLightBlue: '#2987eb',
  fishbowlCorporateDarkBlue: '#0053f4',
  fishbowlCorporateGray1: '#19212b',
  fishbowlCorporateGray2: '#576977',
  fishbowlCorporateGray3: '#a9b5c0',
  fishbowlLightGray: '#F7F8FC',
  fishbowlDarkGray: '#343b44',
  applicationLightGray: '#F0F0F0',
  applicationDarkGray: '#303030',
  applicationDarkGray80: '#595959',
  applicationDarkGray60: '#838383',
  applicationDarkGray20: '#D6D6D6',
  applicationDarkGray10: '#EBEBEB',
  applicationMediumGray: '#B5B5B5',
  statusGreen: '#38BB7C',
  statusOrange: '#F4B63F',
  statusYellow: '#e3e00b',
  statusRed: '#EF4510',
  breadCrumb1: '#6E7B8B',
  breadCrumb2: '#545D69',
  buttonBorderColor: 'rgba(0, 0, 0, 0.23)',
  buttonHoverBackgroundColor: 'rgba(0, 0, 0, 0.05)',
  disabled: '#8A8A8A',
  redesign: {
    // content
    contentPrimary: '#192229',
    contentSecondary: '#547089',
    contentTertiary: '#AABBCB',

    // backgrounds
    background1: '#F9FAFB',
    background2: '#ECF0F4',
    background3: '#E2E8EE',
    background4: '#C1CFDC',
    backgroundMenu: '#2D3B49',
    backgroundMenuHover: '#58748D80',
    backgroundMenuSecondary: '#283640',
    lightRedContent: '#A50808',
    lightRedPrimary: '#DF0C0C',
    statusColors: {
      lightGray: {
        background: '#E5EBF0',
        text: '#486075',
        dot: '#AABBCB',
      },
      lightBlue: {
        background: '#DBECFF',
        text: '#024EA7',
        dot: '#137FFD',
      },
      lightOrange: {
        background: '#FEDAB8',
        text: '#A75102',
        dot: '#FD8B22',
      },
      lightPurple: {
        background: '#ECDEFC',
        text: '#600FBD',
        dot: '#A15AF2',
      },
      lightYellow: {
        background: '#FDF0BE',
        text: '#8F7104',
        dot: '#F8C612',
      },
      lightGreen: {
        background: '#C6FBCF',
        text: '#0B411D',
        dot: '#188B3F',
      },
      lightRed: {
        background: '#FDD8D8',
        text: '#A50808',
        dot: '#DF0C0C',
      },
      transparent: {
        background: 'transparent',
        text: 'transparent',
        dot: 'transparent',
      },
    },

    // blues
    fishbowlBlue: '#083CF3',
    blueSecondary: '#013674',
    lightPrimaryBlue: '#137FFD',
    highlightBlue: '#C2DEFE',
    tabActiveBlue: '#D7E9FE',

    // greens
    greenPrimary: '#188B3F',
    greenSecondary: '#046826',
    contentGreen: '#0B411D',
    greenBackground: '#CBF6D9',
    greenBackgroundLighter: '#E9FBEF',

    // reds
    redPrimary: '#DF0C0C',
    contentRed: '#A50808',
    redBackground: '#FDD8D8',
    redBackgroundLighter: '#FEECEC',

    // yellows
    contentYellow: '#8F7104',
    yellowBackground: '#FDF0BE',
    yellowBackgroundLighter: '#FEFAEB',

    // greys
    contentGrey: '#486075',
    greyBackground: '#E5EBF0',

    // links
    contentLinkBlue: '#0265D9',

    // buttons
    hoverBlue: '#4499FD',
    hoverButtonBackground: '#0026AB',
    disabledButtonBackground: '#839DF9',
    pressedBlue: '#024EA7',
  },
};

const breakpoints = createBreakpoints({});

const newPalette = createPalette({
  mode: 'light' as PaletteOptions['mode'],
  primary: {
    main: colorPalette.fishbowlCorporateBlue,
    light: colorPalette.statusGreen,
    dark: colorPalette.fishbowlCorporateDarkBlue,
  },
  text: {
    primary: colorPalette.fishbowlCorporateGray1,
    secondary: colorPalette.fishbowlCorporateGray2,
    disabled: colorPalette.disabled,
  },
  secondary: { main: colorPalette.fishbowlCorporateLightBlue },
  appBar: {
    primary: colorPalette.fishbowlDarkGray,
  },
  appDrawer: {
    primary: colorPalette.fishbowlCorporateGray1,
  },
  titleBar: {
    primary: colorPalette.fishbowlDarkGray,
  },
  breadCrumb: {
    primary: '#6E7B8B',
    secondary: '#545D69',
  },
});

const components = {
  MuiDialog: {
    styleOverrides: {
      root: {
        '&.redesign': {
          '&.inventory-event-modal': {
            'div:has(> .MuiTableContainer-root)': {
              margin: '0px 32px',
              borderRadius: '10px',
              border: `1px solid ${colorPalette.redesign.background3}`,
              overflowY: 'auto',
              '.MuiTableCell-root': {
                borderTop: 0,
                borderBottom: 0,
              },
            },
            '.MuiPaper-root': {
              maxHeight: 'none',
              height: 'unset',
            },
          },
        },
      },
    },
  },
  MuiTablePagination: {
    styleOverrides: {
      root: {
        '&.redesign': {
          'button, .MuiButtonBase-root': {
            fontWeight: 600,
            padding: '8px 16px',
            borderRadius: '3px',
            margin: 0,
            color: colorPalette.redesign.contentPrimary,
            '&.Mui-selected': {
              backgroundColor: colorPalette.redesign.background2,
            },
            '&.Mui-disabled': { opacity: 0.4 },
          },
          '&.expanded': {
            '.MuiTablePagination-toolbar': {
              '.MuiTablePagination-selectLabel': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '40px',
                padding: '1px 8px',
                backgroundColor: colorPalette.redesign.background2,
                borderColor: `${colorPalette.redesign.background4} !important`,
                border: '1px solid',
                borderRadius: '3px 0 0 3px',
                color: colorPalette.redesign.contentSecondary,
              },
              '.MuiInputBase-root': {
                borderColor: `${colorPalette.redesign.background4} !important`,
                border: '1px solid',
                borderLeft: 'none',
                borderRadius: '0 3px 3px 0',
                marginLeft: 0,
                marginRight: '16px',
                height: '40px',
                '.MuiSelect-select': {
                  marginRight: '8px',
                },
              },
              '.MuiTablePagination-displayedRows': {
                marginRight: '16px',
                span: { marginRight: '0 !important' },
              },
              '.MuiBox-root': {
                marginLeft: 0,
                marginRight: '16px',
              },
              '.MuiButtonBase-root': {
                color: colorPalette.redesign.contentPrimary,
                '&.Mui-disabled': {
                  opacity: 0.4,
                },
              },
            },
          },
          '&.collapsed': {
            '.MuiToolbar-root': {
              padding: '0px',
              justifyContent: 'space-evenly',
              '.MuiTablePagination-spacer': {
                display: 'none',
              },
              '.MuiButtonBase-root': {
                padding: '2px',
              },
              '.MuiBox-root': {
                width: '50%',
                justifyContent: 'space-between',
                '> .MuiButtonBase-root': {},
              },
            },
          },
        },
      },
    },
  },
  MuiTableSortLabel: {
    styleOverrides: {
      root: {
        '&.redesign': {
          '.MuiTableSortLabel-icon': {
            width: '14.289px',
            height: '8.295px',
            color: colorPalette.redesign.blueSecondary,
            marginLeft: '4px',
          },
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      rounded: {
        '&.redesign': {
          borderRadius: '10px',
          '.title': {
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
          },
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        '.MuiInputAdornment-positionStart': {
          '&.redesign.search': {
            svg: {
              color: colorPalette.redesign.contentSecondary,
            },
          },
        },
      },
    },
  },
  MuiPopover: {
    styleOverrides: {
      root: {
        '.MuiPaper-root': {
          '.MuiBox-root.redesign.profile': {
            '.MuiGrid-item.avatar': {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
            '.MuiTypography-root': {
              fontSize: '12px',
              fontWeight: 400,
              '&.names': {
                fontSize: '14px',
                fontWeight: 600,
                marginBottom: '8px',
              },
              '&.email': {
                marginBottom: '8px',
              },
              '&.company-name': {
                color: colorPalette.redesign.contentSecondary,
              },
            },
          },
        },
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        '&.redesign': {
          display: 'flex',
          padding: 0,
          width: '28px',
          height: '16px',
          '.MuiSwitch-switchBase': {
            padding: '2px',
            '&:hover': {
              '& +.MuiSwitch-track': {
                backgroundColor: colorPalette.redesign.contentTertiary,
              },
              backgroundColor: 'transparent',
            },
            '&.Mui-focusVisible': {
              '& +.MuiSwitch-track': {
                backgroundColor: colorPalette.redesign.contentTertiary,
              },
            },
            '&.Mui-checked': {
              '&:hover': {
                '& +.MuiSwitch-track': {
                  backgroundColor: colorPalette.redesign.greenSecondary,
                },
              },
              '&.Mui-focusVisible': {
                '& +.MuiSwitch-track': {
                  backgroundColor: colorPalette.redesign.greenSecondary,
                },
              },
              transform: 'translateX(12px)',
              '& +.MuiSwitch-track': {
                opacity: 1,
                backgroundColor: colorPalette.redesign.greenPrimary,
              },
            },
            '&.Mui-disabled': {
              '& +.MuiSwitch-track': {
                opacity: 0.4,
              },
            },
          },
          '.MuiSwitch-track': {
            opacity: 1,
            width: '28px',
            height: '16px',
            borderRadius: '16px',
            backgroundColor: colorPalette.redesign.background4,
          },
          '.MuiSwitch-thumb': {
            width: '12px',
            height: '12px',
            boxShadow: 'none',
            backgroundColor: 'white',
          },
          '&.MuiSwitch-sizeMedium': {
            width: '48px',
            height: '24px',
            '.MuiSwitch-switchBase': {
              padding: '3px',
              '&.Mui-checked': {
                transform: 'translateX(24px)',
              },
            },
            '.MuiSwitch-track': {
              width: '48px',
              height: '24px',
            },
            '.MuiSwitch-thumb': {
              width: '18px',
              height: '18px',
            },
          },
        },
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      root: {
        '&.redesign[class*="drawerClose"]': {
          '.MuiList-root': {
            '.MuiListItemButton-root': {
              width: 'max-content',
              '.MuiListItemText-root': {
                display: 'none',
              },
            },
          },
        },
        '&.redesign': {
          display: 'flex',
          flexDirection: 'column',
          '.MuiPaper-root': {
            height: '100%',
            padding: '0px',
            overflow: 'hidden',
            justifyContent: 'space-between',
          },
          '.MuiList-root': {
            // Main-menu Items
            '.MuiListItemButton-root': {
              width: '100%',
              padding: '8px',
              marginBottom: '16px',
              color: colorPalette.redesign.contentTertiary,
              backgroundColor: 'transparent',
              borderRadius: '5px',
              '&:hover': {
                backgroundColor: colorPalette.redesign.backgroundMenuHover,
              },
              svg: {
                color: colorPalette.redesign.contentTertiary,
              },
              '.MuiListItemText-root': {
                margin: '0',
                marginLeft: '8px',
              },
              '&.active-link': {
                backgroundColor: colorPalette.redesign.background4,
                color: colorPalette.redesign.contentPrimary,
                svg: { color: colorPalette.redesign.contentPrimary },
                '.MuiListItemText-root': {
                  color: colorPalette.redesign.contentPrimary,
                },
              },
              // Sample Database Item
              '&.sandbox': {
                backgroundColor: 'white',
                color: colorPalette.redesign.contentPrimary,
                svg: { color: colorPalette.redesign.contentPrimary },
                '.MuiListItemText-root': {
                  whiteSpace: 'nowrap',
                },
                '&.active-link': {
                  backgroundColor: 'white',
                  color: colorPalette.redesign.contentPrimary,
                  svg: { color: colorPalette.redesign.contentPrimary },
                  '.MuiTypography-root': {
                    color: colorPalette.redesign.contentPrimary,
                  },
                  '&:hover': {
                    backgroundColor: 'white',
                    color: colorPalette.redesign.contentPrimary,
                  },
                },
              },
            },
            // Sample Database Sub-Items
            '&.sandbox-container': {
              '.MuiCollapse-wrapperInner': {
                display: 'flex',
                flexDirection: 'column',
              },
              '.MuiButton-root': {
                borderRadius: '5px',
                fontSize: '12px',
                fontWeight: '600',
                margin: '0px 16px',
                marginBottom: '8px',
                padding: '9px 0',
                textTransform: 'capitalize',
                svg: {
                  color: colorPalette.redesign.contentPrimary,
                  marginRight: '8px',
                },
                '&.clear, &.leave': {
                  svg: {
                    color: colorPalette.white,
                  },
                },
                '&.sync': {
                  borderColor: colorPalette.redesign.contentLinkBlue,
                  background: colorPalette.redesign.lightPrimaryBlue,
                },
                '&.populate': {
                  borderColor: colorPalette.redesign.contentLinkBlue,
                  background: colorPalette.redesign.lightPrimaryBlue,
                },
                '&.clear': {
                  borderColor: colorPalette.redesign.contentRed,
                  background: colorPalette.redesign.redPrimary,
                },
                '&.leave': {
                  borderColor: colorPalette.redesign.backgroundMenu,
                  background: colorPalette.redesign.contentSecondary,
                  marginBottom: '24px',
                },
              },
            },
            // Sub-menu Items
            '.MuiListItem-root.sub-item': {
              paddingLeft: '16px',
              marginTop: '0',
              marginLeft: '16px',
              borderRadius: '0px',
              borderLeft: `2px solid ${colorPalette.redesign.contentTertiary}`,
              color: colorPalette.redesign.contentTertiary,
              backgroundColor: 'transparent',
              '&:hover': {
                borderLeft: `2px solid ${colorPalette.redesign.background3}`,
                backgroundColor: 'transparent',
              },
              '&.active-link': {
                borderLeft: '3px solid white',
                marginLeft: '15.5px',
                '.MuiTypography-root': {
                  color: 'white',
                  fontWeight: '600',
                  marginLeft: '-0.5px',
                },
                '&:hover': {
                  '.MuiListItemText-root': {
                    '.MuiTypography-root': {
                      color: 'white',
                    },
                  },
                },
              },
              '.MuiListItemText-root': {
                margin: '0',
                '.MuiTypography-root': {
                  fontSize: '13px',
                  '&:hover': {
                    color: colorPalette.redesign.background3,
                  },
                },
              },
              ':last-child': {
                marginBottom: '24px',
              },
            },
          },
        },
      },
    },
  },
  MuiGrid: {
    styleOverrides: {
      root: {
        '&.redesign': {
          '.MuiGrid-item': {
            display: 'flex',
            marginBottom: '16px',
            '.MuiSvgIcon-fontSizeMedium': {
              marginTop: '-3px',
              marginRight: '8px',
              width: '24px',
              color: colorPalette.redesign.contentTertiary,
              '&.active': {
                color: colorPalette.redesign.greenPrimary,
              },
            },
          },
        },
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        '&.redesign': {
          color: colorPalette.redesign.contentLinkBlue,
          fontWeight: 600,
          fontSize: 14,
          '&:hover': {
            color: colorPalette.redesign.hoverBlue,
            textDecoration: 'none',
          },
        },
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      button: {
        fontSize: 'calc(14px * var(--font-size-multiplier, 1))',
      },
      root: {
        '&.redesign': {
          '&.MuiTypography-body1': {},
          '&.MuiTypography-body2': {},
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        paddingLeft: 32,
        paddingRight: 32,
        transitionProperty: 'all',
        fontSize: 'calc(14px * var(--font-size-multiplier, 1))',

        '&.redesign': {
          borderRadius: '3px',
          border: '1px solid',
          gap: '8px',
          boxShadow: 'none',
          color: colorPalette.white,
          fontSize: '12px',
          fontWeight: 600,
          textTransform: 'capitalize',
          padding: '8px 16px',
          maxWidth: '100%',
          width: 'max-content',
          minWidth: 0,
          whiteSpace: 'nowrap',
          '&.MuiButton-fullWidth': {
            width: '100%',
          },
          '&.MuiButton-sizeSmall, &.MuiButton-sizeMedium, &.MuiButton-sizeLarge':
            {
              'span.MuiCircularProgress-root': {
                height: 'auto',
                width: 'auto',
              },
              'svg, .MuiCircularProgress-svg': {
                height: '20px',
                width: '20px',
              },
              '&.icon-only': {
                minWidth: 'auto',
              },
            },
          '&.MuiButton-sizeSmall': {
            padding: '8px 16px',
            lineHeight: 'normal',
            'svg, .MuiCircularProgress-svg': {
              height: '16px',
              width: '16px',
            },
            '&.icon-only': {
              padding: '7px',
            },
          },
          '&.MuiButton-sizeMedium': {
            padding: '8px 16px',
            fontSize: '14px',
            lineHeight: '1.57',
            '&.icon-only': {
              padding: '9px',
            },
          },
          '&.MuiButton-sizeLarge': {
            padding: '12px 16px',
            fontSize: '16px',
            lineHeight: '1.38',
            '&.icon-only': {
              padding: '12px 12px',
            },
          },
          '&.table-footer-pagination-button': {
            minWidth: 0,
            fontWeight: 'normal',
          },
        },
      },
      label: {
        fontWeight: 600,
      },
      contained: {
        boxShadow: 'none',
        paddingLeft: 32,
        paddingRight: 32,
        borderColor: colorPalette.redesign.contentLinkBlue,
        backgroundColor: colorPalette.redesign.lightPrimaryBlue,
        '&.Mui-disabled': {
          backgroundColor: colorPalette.applicationDarkGray20,
          boxShadow: 'inset 0px 2px 0px #C4C4C4',
        },
      },
      outlined: {
        color: colorPalette.applicationDarkGray80,
        '&:hover': {
          color: colorPalette.applicationDarkGray,
          backgroundColor: 'transparent',
          borderColor: '#303030',
        },
        '&.Mui-disabled': {
          backgroundColor: colorPalette.applicationDarkGray20,
          boxShadow: 'inset 0px 2px 0px #C4C4C4',
        },
      },
      text: {
        // Tertiary
        color: colorPalette.white,
        paddingLeft: 16,
        paddingRight: 16,
        fontSize: 'calc(14px * var(--font-size-multiplier, 1))',
      },
      link: {
        // Link
      },
      containedPrimary: {
        boxShadow: 'inset 0px -2px 0px rgba(104, 47, 10, 0.2)',
        '&:hover, &:active': {
          boxShadow: 'inset 0px -2px 0px rgba(104, 47, 10, 0.2)',
        },
        '& .MuiButton-label': {
          color: colorPalette.white,
        },
        '&.Mui-disabled': {
          '&.Mui-label': {
            color: colorPalette.applicationDarkGray60,
          },
        },
        '&.redesign': {
          boxShadow: 'none',
          '&.positive': {
            borderColor: colorPalette.redesign.contentLinkBlue,
            backgroundColor: colorPalette.redesign.lightPrimaryBlue,
            '&:hover, &:focus': {
              backgroundColor: colorPalette.redesign.hoverBlue,
            },
            '&:active': {
              borderColor: colorPalette.redesign.lightPrimaryBlue,
              backgroundColor: colorPalette.redesign.contentLinkBlue,
            },
            '&.loading, &:disabled, &.Mui-disabled': {
              borderColor: colorPalette.redesign.lightPrimaryBlue,
              backgroundColor: colorPalette.redesign.lightPrimaryBlue,
              cursor: 'not-allowed',
              textDecoration: 'none',
            },
            '&:disabled': { opacity: 0.4 },
            '&.loading:disabled': { opacity: 0.6 },
            '.MuiCircularProgress-root': {
              color: colorPalette.white,
            },
          },
          '&.negative': {
            borderColor: colorPalette.redesign.lightRedContent,
            backgroundColor: colorPalette.redesign.lightRedPrimary,
            '&:hover': {
              opacity: 0.6,
            },
            '&:active': {
              borderColor: colorPalette.redesign.lightRedPrimary,
              backgroundColor: colorPalette.redesign.lightRedContent,
              opacity: 1,
            },
            '&.loading, &:disabled, &.Mui-disabled': {
              cursor: 'not-allowed',
              textDecoration: 'none',
            },
            '&:disabled': { opacity: 0.4 },
            '&.loading:disabled': { opacity: 0.6 },
            '.MuiCircularProgress-root': {
              color: colorPalette.white,
            },
          },
          '&.neutral': {
            borderColor: colorPalette.redesign.backgroundMenu,
            backgroundColor: colorPalette.redesign.contentSecondary,
            '&:hover': {
              opacity: 0.6,
            },
            '&:active': {
              borderColor: colorPalette.redesign.backgroundMenu,
              backgroundColor: colorPalette.redesign.backgroundMenuSecondary,
              opacity: 1,
            },
            '&.loading, &:disabled, &.Mui-disabled': {
              cursor: 'not-allowed',
              textDecoration: 'none',
            },
            '&:disabled': { opacity: 0.4 },
            '&.loading:disabled': { opacity: 0.6 },
            '.MuiCircularProgress-root': {
              color: colorPalette.white,
            },
          },
        },
      },
      containedSecondary: {
        backgroundColor: colorPalette.applicationDarkGray,
        boxShadow: 'inset 0px -2px 0px #000000',
        '&:hover, &:active': {
          backgroundColor: colorPalette.applicationDarkGray80,
          boxShadow: 'inset 0px -2px 0px rgba(0, 0, 0, 0.4)',
        },
        '&.MuiButton-label': {
          color: colorPalette.white,
        },
        '&.redesign': {
          boxShadow: 'none',
          '&.positive': {
            color: colorPalette.redesign.lightPrimaryBlue,
            borderColor: colorPalette.redesign.background4,
            backgroundColor: colorPalette.white,
            '&:hover, &:focus': {
              borderColor: colorPalette.redesign.lightPrimaryBlue,
            },
            '&:active': {
              color: colorPalette.white,
              borderColor: colorPalette.redesign.lightPrimaryBlue,
              backgroundColor: colorPalette.redesign.lightPrimaryBlue,
            },
            '&.loading, &:disabled, &.Mui-disabled': {
              color: colorPalette.redesign.lightPrimaryBlue,
              borderColor: colorPalette.redesign.background4,
              backgroundColor: colorPalette.white,
              cursor: 'not-allowed',
              textDecoration: 'none',
            },
            '&:disabled': { opacity: 0.4 },
            '&.loading:disabled': { opacity: 0.65 },
            '.MuiCircularProgress-root': {
              color: colorPalette.redesign.lightPrimaryBlue,
            },
          },
          '&.negative': {
            color: colorPalette.redesign.lightRedPrimary,
            borderColor: colorPalette.redesign.background4,
            backgroundColor: colorPalette.white,
            '&:hover': {
              borderColor: colorPalette.redesign.lightRedPrimary,
            },
            '&:active': {
              color: colorPalette.white,
              borderColor: colorPalette.redesign.lightRedPrimary,
              backgroundColor: colorPalette.redesign.lightRedPrimary,
            },
            '&.loading, &:disabled, &.Mui-disabled': {
              cursor: 'not-allowed',
              textDecoration: 'none',
            },
            '&:disabled': { opacity: 0.4 },
            '&.loading:disabled': { opacity: 0.65 },
            '.MuiCircularProgress-root': {
              color: colorPalette.redesign.lightRedPrimary,
            },
          },
          '&.neutral': {
            color: colorPalette.redesign.contentSecondary,
            borderColor: colorPalette.redesign.background4,
            backgroundColor: colorPalette.white,
            '&:hover, &:focus': {
              borderColor: colorPalette.redesign.contentSecondary,
            },

            '&:active': {
              color: colorPalette.white,
              borderColor: colorPalette.redesign.contentSecondary,
              backgroundColor: colorPalette.redesign.backgroundMenu,
            },
            '&.loading, &:disabled, &.Mui-disabled': {
              color: colorPalette.redesign.contentSecondary,
              borderColor: colorPalette.redesign.background4,
              backgroundColor: colorPalette.white,
              cursor: 'not-allowed',
              textDecoration: 'none',
            },
            '&:disabled': { opacity: 0.4 },
            '&.loading:disabled': { opacity: 0.65 },
            '.MuiCircularProgress-root': {
              color: colorPalette.redesign.contentSecondary,
            },
          },
        },
      },
      textPrimary: {
        '&.redesign': {
          boxShadow: 'none',
          '&.positive': {
            color: colorPalette.redesign.lightPrimaryBlue,
            borderColor: colorPalette.transparent,
            backgroundColor: colorPalette.transparent,
            '&:hover, &:focus': {
              borderColor: colorPalette.redesign.background2,
              backgroundColor: colorPalette.redesign.background2,
            },
            '&:active': {
              borderColor: colorPalette.redesign.background2,
              backgroundColor: colorPalette.redesign.background2,
            },
            '&.loading, &:disabled, &.Mui-disabled': {
              borderColor: colorPalette.transparent,
              backgroundColor: colorPalette.transparent,
              cursor: 'not-allowed',
              textDecoration: 'none',
            },
            '&:disabled': { opacity: 0.4 },
            '&.loading:disabled': { opacity: 0.65 },
            '&[href]': {
              color: colorPalette.redesign.contentLinkBlue,
              borderColor: colorPalette.transparent,
              backgroundColor: colorPalette.transparent,
              '&:hover': {
                textDecoration: 'underline',
                color: colorPalette.redesign.hoverBlue,
              },
              '&:active': {
                textDecoration: 'underline',
                color: colorPalette.redesign.pressedBlue,
              },
              '&.loading, &.Mui-disabled, &.Mui-disabled': {
                color: colorPalette.redesign.contentLinkBlue,
                cursor: 'not-allowed',
                textDecoration: 'none',
              },
              '&.Mui-disabled': {
                opacity: 0.4,
              },
              '&.loading.Mui-disabled': {
                opacity: 0.65,
              },
            },
          },
          '&.negative': {
            color: colorPalette.redesign.lightRedPrimary,
            borderColor: colorPalette.transparent,
            backgroundColor: colorPalette.transparent,
            '&:hover': {
              borderColor: colorPalette.redesign.background1,
              backgroundColor: colorPalette.redesign.background1,
            },
            '&:active': {
              borderColor: colorPalette.redesign.background2,
              backgroundColor: colorPalette.redesign.background2,
            },
            '&.loading, &:disabled, &.Mui-disabled': {
              cursor: 'not-allowed',
              textDecoration: 'none',
            },
            '&:disabled': { opacity: 0.4 },
            '&.loading:disabled': { opacity: 0.65 },
            '.MuiCircularProgress-root': {
              color: colorPalette.redesign.lightRedPrimary,
            },
            '&[href]': {
              color: colorPalette.redesign.lightRedPrimary,
              borderColor: colorPalette.transparent,
              backgroundColor: colorPalette.transparent,
              '&:hover': {
                textDecoration: 'underline',
              },
              '&:active': {
                textDecoration: 'underline',
                color: colorPalette.redesign.lightRedContent,
              },
              '&.loading, &.Mui-disabled, &.Mui-disabled': {
                cursor: 'not-allowed',
                textDecoration: 'none',
              },
              '&.Mui-disabled': {
                opacity: 0.4,
              },
              '&.loading.Mui-disabled': {
                opacity: 0.65,
              },
            },
          },
          '&.neutral': {
            color: colorPalette.redesign.contentPrimary,
            borderColor: colorPalette.transparent,
            backgroundColor: colorPalette.transparent,
            '&:hover, &:focus': {
              backgroundColor:
                colorPalette.redesign.statusColors.lightGray.background,
              borderColor:
                colorPalette.redesign.statusColors.lightGray.background,
            },
            '&:active': {
              borderColor: colorPalette.redesign.background2,
              backgroundColor: colorPalette.redesign.background2,
              '&.table-footer-pagination-button': {
                backgroundColor: colorPalette.white,
                borderColor: colorPalette.white,
              },
            },
            '&.loading, &:disabled, &.Mui-disabled': {
              borderColor: colorPalette.transparent,
              backgroundColor: colorPalette.transparent,
              cursor: 'not-allowed',
              textDecoration: 'none',
            },
            '&:disabled': { opacity: 0.4 },
            '&.loading:disabled': { opacity: 0.65 },
            '.MuiCircularProgress-root': {
              color: colorPalette.redesign.contentPrimary,
            },
            '&[href]': {
              borderColor: colorPalette.transparent,
              backgroundColor: colorPalette.transparent,
              '&:hover': {
                textDecoration: 'underline',
              },
              '&:active': {
                textDecoration: 'underline',
                color: colorPalette.redesign.contentSecondary,
              },
              '&.loading, &.Mui-disabled, &.Mui-disabled': {
                color: colorPalette.redesign.contentPrimary,
                cursor: 'not-allowed',
                textDecoration: 'none',
              },
              '&.Mui-disabled': {
                opacity: 0.4,
              },
              '&.loading.Mui-disabled': {
                opacity: 0.65,
              },
            },
          },
        },
      },
    },
  },
  MuiButtonGroup: {
    styleOverrides: {
      groupedContained: {
        '&.MuiButtonGroup-groupedContainedPrimary:not(.Mui-disabled)': {
          boxShadow: 'inset 0px -2px 0px rgba(104, 47, 10, 0.2)',
        },
      },
      groupedContainedPrimary: {
        '&:not(:last-child)': {
          borderColor: 'rgba(0, 0, 0, 0.25)',
        },
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        '& .Mui-disabled': {
          opacity: 0.4,
        },
        '&.search': {
          margin: 'auto 8px',
          padding: 6,
          border: '1px solid',
          borderColor: colorPalette.redesign.background4,
          borderRadius: 3,
          color: colorPalette.redesign.contentSecondary,
          height: '40px',
          width: '40px',
        },
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        '&.redesign': {
          // adjustment for text fields to fill full space (label + error)
          marginTop: '18px',
          marginBottom: '16px',
        },
        '&.redesign.no-margin': {
          margin: 0,
        },
        '&.redesign.date-picker-wrapper': {
          '.MuiInputBase-root': {
            display: 'flex',
            justifyContent: 'space-between',
            borderColor: `${colorPalette.redesign.background4} !important`,
            border: '1px solid',
            borderRadius: '3px',
            '.MuiInputAdornment-root': {
              marginRight: 0,
              padding: 0,
            },
            '.MuiInputAdornment-positionStart': {
              position: 'relative',
              right: 'unset',
              marginRight: 'unset',
              '.MuiButtonBase-root': {
                minWidth: 'fit-content',
                paddingLeft: '8px',
                paddingRight: '8px',
                marginLeft: 'unset',
              },
            },
            '.MuiInputBase-input': {
              border: 0,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              '&:hover': {
                border: 0,
              },
            },
            '.MuiInputAdornment-positionEnd': {
              position: 'relative',
              right: 'unset',
              padding: 'unset',
              marginLeft: 'unset',
              '.MuiButtonBase-root': {
                minWidth: 'fit-content',
                paddingRight: '8px',
                paddingLeft: '8px',
                marginRight: 'unset',
              },
            },
          },
        },
        '.FBOInputSelect &': {
          flexGrow: 1,
          zIndex: 1,
          '.MuiInputBase-root': {
            '.MuiInputBase-input': {
              borderRadius: '3px 0px 0px 3px',
            },
          },
        },
        // TODO : RESTYLING : this is temporary for the multiselect component and should be revisited after FF cleanup / refactors
        '&.redesign-temp': {
          marginTop: '18px',
          marginBottom: '16px',
          '.MuiInputLabel-root': {
            fontSize: 16,
            fontWeight: 600,
            color: colorPalette.redesign.contentSecondary,
            marginTop: '-20px',
            '&.Mui-focused': {
              color: colorPalette.redesign.blueSecondary,
            },
          },
          '.MuiInputBase-root, .MuiOutlinedInput-root': {
            height: '40px !important',
            '.MuiOutlinedInput-notchedOutline': {},
            '.MuiInputAdornment-root': {
              position: 'absolute',
              right: '0',
              marginRight: '15px',
              svg: {
                width: '18px',
              },
            },
          },
          '.Mui-error': {
            '&::before': {
              borderBottom: 'none',
              content: 'none',
            },
            '&::after': {
              border: 'none',
            },
            '&.MuiFormHelperText-root': {
              position: 'absolute',
              top: '38px',
              '&::before': {
                content: WarningCircle,
                marginTop: 2,
                marginRight: 9,
              },
              display: 'flex',
              alignSelf: 'end',
              color: colorPalette.redesign.redPrimary,
            },
            span: {
              color: colorPalette.redesign.contentSecondary,
            },
          },
          ':hover:not([disabled]), .Mui-focused': {
            '.MuiInputLabel-root': {
              color: colorPalette.redesign.blueSecondary,
            },
            // this removes the outlined variant stuff, very temporary since the component itself should be refactored (MultiSelect)
            '.MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${colorPalette.redesign.blueSecondary}`,
              legend: { width: '0px' },
            },
          },
          '.MuiOutlinedInput-notchedOutline': {
            legend: { width: '0px' },
          },
          '.MuiInputBase-root': {
            height: 'auto',
            '.MuiSelect-select': {
              '.MuiChip-root': {
                height: 'auto',
                margin: '0',
                padding: '4px 9px',
                gap: '8px',
                backgroundColor: colorPalette.redesign.background1,
                color: 'transparent',
                span: {
                  fontSize: '12px',
                  color: colorPalette.redesign.blueSecondary,
                },
                svg: {
                  color: colorPalette.redesign.contentTertiary,
                },
              },
            },
            '.MuiSvgIcon-root': {
              marginRight: '8px',
            },
          },
        },
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        fontSize: 13,
        lineHeight: '16px',
        color: '#676767',
        [breakpoints.up('xl')]: {
          fontSize: 14,
        },
      },
      // redesign styles
      root: {
        '&.redesign': {
          '.MuiFormControlLabel-label': {
            fontSize: 14,
            color: colorPalette.redesign.contentPrimary,
            '&.Mui-disabled': {
              color: colorPalette.redesign.contentSecondary,
            },
          },
        },
        '&.FBOSwitch-label': {
          // might not need top and bottom styles
          '&.MuiFormControlLabel-labelPlacementTop': {
            '.MuiFormControlLabel-label': {},
          },
          '&.MuiFormControlLabel-labelPlacementEnd': {
            '.MuiFormControlLabel-label': {
              marginLeft: '8px',
            },
          },
          '&.MuiFormControlLabel-labelPlacementBottom': {
            '.MuiFormControlLabel-label': {},
          },
          '&.MuiFormControlLabel-labelPlacementStart': {
            '.MuiFormControlLabel-label': {
              marginRight: '8px',
            },
          },
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        '&.redesign': {
          color: colorPalette.redesign.background4,
          marginRight: 8,
          marginBottom: 2,
          marginLeft: 10,
          padding: 0,
          width: 20,
          height: 20,
          '.MuiSvgIcon-root': {
            outline: '1px solid white',
            outlineOffset: -3,
            borderRadius: 5,
            width: 20,
            height: 20,
          },
          '&:hover': {
            color: colorPalette.redesign.blueSecondary,
          },
          '&.Mui-checked': {
            color: colorPalette.redesign.blueSecondary,
            '.MuiSvgIcon-root': {
              outlineOffset: 0,
            },
            '&:hover': {
              color: `${colorPalette.redesign.pressedBlue} !important`,
            },
          },
          '&.Mui-disabled': {
            marginLeft: 10,
            '.MuiSvgIcon-root': {
              backgroundColor: colorPalette.redesign.background1,
              color: 'transparent',
              outline: `1px solid ${colorPalette.redesign.background3}`,
              outlineOffset: -2,
              border: '1px solid white',
              borderRadius: 4,
              width: 18,
              height: 18,
            },
            '&.Mui-checked': {
              '.MuiSvgIcon-root': {
                backgroundColor: colorPalette.redesign.contentTertiary,
                color: colorPalette.redesign.background3,
                outline: `2px solid ${colorPalette.redesign.background3}`,
                outlineOffset: -3,
              },
            },
          },
          '&.MuiCheckbox-colorError': {
            color: colorPalette.redesign.redPrimary,
          },
          '&.large-checkbox': {
            transform: 'scale(1.5)',
            margin: '8px',
          },
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: colorPalette.applicationDarkGray,
        },
      },
      input: {
        fontSize: 'calc(13px * var(--font-size-multiplier, 1))',
        '& .Mui-disabled': {
          color: colorPalette.applicationDarkGray80,
        },
      },
      inputSizeSmall: {},
      notchedOutline: {
        borderColor: colorPalette.applicationDarkGray20,
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      outlined: {
        '&.Mui-focused': {
          color: colorPalette.applicationDarkGray,
        },
      },
      shrink: {
        whiteSpace: 'nowrap' as const,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        right: 0,
      },
    },
  },
  MuiTable: {
    styleOverrides: {
      root: {
        '&.redesign': {
          '.MuiTableCell-root': {
            background: 'none',
            border: 'none',
            borderBottom: `1px solid ${colorPalette.redesign.background3}`,
            '&.MuiTableCell-head': {
              borderBottom: `1px solid ${colorPalette.redesign.background3}`,
              borderTop: `1px solid ${colorPalette.redesign.background3}`,
              backgroundColor: colorPalette.redesign.background2,
            },
          },
          '.MuiTableCell-head': {
            padding: '0px 8px 0px 8px', // needed so that calculated height remains 40px as Figma shows
          },
          '.MuiTableRow-hover': {
            height: '56px', // all regular Table Cells in Figma are this height, will check with UX on this to see if it's actually a fixed height
            '&:hover': {
              backgroundColor: colorPalette.redesign.background1,
            },
            '&.Mui-selected': {
              backgroundColor: colorPalette.redesign.highlightBlue,
            },
          },
        },
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          backgroundColor: '#D6D6D6',
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        padding: 12,
        '.MuiBox-root': {
          '&.redesign.statusDot': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
        },
      },
      stickyHeader: {
        backgroundColor: '#EBEBEB',
      },
      sizeSmall: {
        padding: '6px 8px 6px 8px',
        '& .Mui-paddingNone': {
          '& .MuiIconButton-root': {
            padding: 6,
          },
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        '&.redesign': {
          overflow: 'unset',
          padding: '8px',
          minHeight: 'auto',
          maxHeight: 'none',
          background: 'none',
          borderTop: `1px solid ${colorPalette.redesign.background3}`,
          borderBottom: `1px solid ${colorPalette.redesign.background3}`,

          '.MuiTab-root': {
            borderRadius: '3px',
            padding: '8px 16px',
            marginRight: '8px',
            color: colorPalette.redesign.contentPrimary,
            fontWeight: 400,
            '&.Mui-selected': {
              border: 'none',
              color: colorPalette.redesign.contentPrimary,
              fontWeight: 600,
              backgroundColor: colorPalette.redesign.tabActiveBlue,
            },
            '&:hover': {
              backgroundColor: colorPalette.redesign.background3,
            },
            // disable this prop completely after redesign clean-up
            '.MuiTouchRipple-root': {
              display: 'none',
            },
          },
          '.MuiTabs-indicator': {
            display: 'none',
            height: '0px',
            transition: 'none',
          },
        },

        minHeight: 40,
        maxHeight: 40,
        backgroundColor: colorPalette.applicationDarkGray10,
        '&.Mui-vertical': {
          minHeight: 'initial',
          maxHeight: 'initial',
        },
      },
      scrollable: {
        backgroundColor: colorPalette.applicationDarkGray10,
      },
      indicator: {
        height: 2,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        minHeight: 40,
        maxHeight: 40,
        fontWeight: 'normal',
        textTransform: 'capitalize' as const,
        minWidth: '100px !important',
        '&.Mui-selected': {},
        '&.Mui-disabled': {
          '& .Mui-wrapped': {
            opacity: 0.5,
          },
        },
        '&.MuiTab-labelIcon': {
          color: colorPalette.fishbowlCorporateGray1,
        },
      },
      wrapped: {
        color: '#222',
        fontSize: 'calc(14px * var(--font-size-multiplier, 1))',
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
        borderBottom: '1px solid #EBEBEB',
        '&:before': {
          opacity: 0,
        },
        '&.Mui-expanded': {
          margin: 0,
          '&:before': {
            opacity: 0,
          },
        },
        '& .Mui-expanded': {
          borderBottom: '1px solid #EBEBEB',
          margin: 0,
        },
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        paddingTop: 6,
        paddingBottom: 6,
        paddingRight: 12,
        paddingLeft: 12,
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        minHeight: 56,
        '&.Mui-expanded': {
          minHeight: 56,
          borderBottom: '1px solid #EBEBEB',
        },
      },
      content: {
        '&.Mui-expanded': {
          marginTop: 12,
          marginBottom: 12,
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        deleteIcon: {
          height: 16,
          width: 16,
        },
        borderRadius: 4,
        '&.redesign': {
          display: 'inline-flex',
          height: '24px',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
          flexShrink: 0,
          borderRadius: '60px',
          '.MuiChip-label': {
            padding: '2px 8px',
          },
          '.MuiChip-deleteIcon': {
            marginRight: '12px',
          },
        },
      },
    },
    MuiSvgIcon: {
      colorDisabled: {
        color: colorPalette.applicationDarkGray20,
      },
    },
  },
  // TODO : RESTYLING : this section needs some work after the FF removal
  MuiTextField: {
    styleOverrides: {
      root: ({ ownerState }: any) => ({
        '&.redesign': {
          '&.date-picker-small-font': {
            '.MuiInput-input': {
              border: !!ownerState.error
                ? `1px solid ${colorPalette.redesign.redPrimary}`
                : `1px solid ${colorPalette.redesign.background4}`,
              borderRadius: 3,
              padding: '8px 16px',
              fontSize: 12,
              fontWeight: 400,
              color: colorPalette.redesign.contentPrimary,
              '&.Mui-disabled': {
                border: 'none',
                backgroundColor: colorPalette.redesign.background3,
                color: colorPalette.redesign.contentSecondary,
                cursor: 'default',
              },
              '&:hover:not([disabled]), .Mui-focused': {
                border: `1px solid ${colorPalette.redesign.blueSecondary}`,
              },
            },
          },
          '.MuiInputLabel-root': {
            fontSize: 16,
            fontWeight: 600,
            color: colorPalette.redesign.contentSecondary,
            marginTop: '-20px',
            '&.Mui-focused': {
              color: colorPalette.redesign.blueSecondary,
            },
            '&.Mui-disabled': {
              color: colorPalette.redesign.contentTertiary,
            },
          },
          '.MuiInput-root': {
            marginTop: 0,
            '&::before': {
              borderBottom: 'none',
              content: 'none',
            },
            '&::after': {
              border: 'none',
            },
          },
          '.MuiInputBase-root': {
            height: '40px',
            '&.MuiInputBase-multiline': { height: 'auto', padding: '0' },
            '.MuiInputAdornment-root': {
              position: 'absolute',
              right: '0',
              marginRight: '15px',
              svg: {
                width: '18px',
              },
            },
            '&.MuiInputBase-readOnly': {
              '.MuiInput-input': {
                border: 'none',
                padding: '12px 4px',
                '&:hover': { border: 'none' },
                '&:selected': { border: 'none' },
              },
            },
          },
          '.MuiInput-input': {
            border: !!ownerState.error
              ? `1px solid ${colorPalette.redesign.redPrimary}`
              : `1px solid ${colorPalette.redesign.background4}`,
            borderRadius: 3,
            padding: '8px 16px',
            fontSize: 14,
            fontWeight: 400,
            color: colorPalette.redesign.contentPrimary,
            '&.Mui-disabled': {
              border: 'none',
              backgroundColor: colorPalette.redesign.background3,
              WebkitTextFillColor: colorPalette.redesign.contentSecondary,
              cursor: 'default',
            },
            '&:hover:not([disabled]), .Mui-focused': {
              border: `1px solid ${colorPalette.redesign.blueSecondary}`,
            },
          },
          '&.MuiInputLabel-root': {},
          '.Mui-error': {
            '&::before': {
              borderBottom: 'none',
              content: 'none',
            },
            '&::after': {
              border: 'none',
            },
            '&.MuiFormHelperText-root': {
              position: 'absolute',
              top: '38px',
              '&::before': {
                content: WarningCircle,
                marginTop: 2,
                marginRight: 9,
              },
              display: 'flex',
              alignSelf: 'end',
              color: colorPalette.redesign.redPrimary,
            },
            span: {
              color: colorPalette.redesign.contentSecondary,
            },
          },
          '&.Mui-focused': {
            '.MuiInputLabel-root': {
              color: colorPalette.redesign.blueSecondary,
            },
          },
        },
      }),
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: ({ ownerState }: any) => ({
        '&.redesign': {
          width: '100%',
          marginTop: '18px',
          marginBottom: '16px',
          '.MuiFormControl-root': {
            fieldset: { border: 'none' },
            '.MuiFormLabel-root': {
              width: 'min-content',
              marginTop: '-2px',
              fontSize: '0.75rem',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
              transformOrigin: 'bottom left',
              transform: 'translate(0.2rem, -1.2rem)',
              WebkitTransform: 'translate(0.2rem, -1.2rem)',
              MozTransform: 'translate(0.2rem, -1.2rem)',
              '&.Mui-focused': {
                color: colorPalette.redesign.blueSecondary,
              },
              '&.Mui-disabled': {
                color: colorPalette.redesign.contentTertiary,
              },
              '&.Mui-error': {
                color: colorPalette.redesign.contentSecondary,
                span: {
                  color: colorPalette.redesign.contentSecondary,
                },
              },
            },
            '.MuiInputBase-root:has(input[disabled])': {
              backgroundColor: colorPalette.redesign.background3,
              border: 'none',
              color: colorPalette.redesign.contentSecondary,
              input: {
                cursor: 'default',
              },
            },
            '.MuiInputBase-root': {
              height: '40px',
              padding: '8px 16px',
              alignContent: 'center', // used for tags inside of input, might have side-effects?
              backgroundColor:
                !!ownerState.disabled === true
                  ? colorPalette.redesign.background3
                  : 'white',
              border:
                !!ownerState.disabled === true
                  ? `1px solid ${colorPalette.redesign.background3}`
                  : `1px solid ${colorPalette.redesign.background4}`,
              ':hover': {
                border: `1px solid ${colorPalette.redesign.blueSecondary}`,
              },
              ':focus': {
                border: `1px solid ${colorPalette.redesign.blueSecondary}`,
              },
              '.MuiInputBase-input': {
                padding: 0,
                fontSize: '14px',
              },
              'input::placeholder': {
                opacity: '100% !important',
                color: colorPalette.redesign.contentTertiary,
              },
              '&.Mui-error': {
                border: `1px solid ${colorPalette.redesign.redPrimary}`,
              },
              '.MuiChip-root': {
                height: 'auto',
                margin: '0',
                padding: '4px 9px',
                gap: '8px',
                backgroundColor: colorPalette.redesign.background1,
                color: 'transparent',
                span: {
                  fontSize: '12px',
                  color: colorPalette.redesign.blueSecondary,
                },
                svg: {
                  color: colorPalette.redesign.contentTertiary,
                },
              },
            },
            '.MuiFormHelperText-root': {
              '&.Mui-error': {
                position: 'absolute',
                top: '42px',
                margin: '0px',
                '&::before': {
                  content: WarningCircle,
                  marginTop: 2,
                  marginRight: 9,
                },
                display: 'flex',
                alignSelf: 'end',
                color: colorPalette.redesign.redPrimary,
              },
            },
            '.MuiAutocomplete-endAdornment': {
              position: 'initial',
              marginRight: '-7px',
              '.MuiButtonBase-root': {
                padding: 0,
                '&.MuiAutocomplete-clearIndicator': {
                  '.MuiSvgIcon-root': {
                    color: colorPalette.redesign.contentTertiary,
                    width: '1.25rem',
                    height: '1.25rem',
                  },
                },
                '&.MuiAutocomplete-popupIndicator': {
                  '.MuiSvgIcon-root': {
                    color: colorPalette.redesign.contentSecondary,
                    width: '1.5rem',
                    height: '1.5rem',
                  },
                },
              },
            },
          },
          '.Mui-focused': {
            '.MuiInputLabel-root': {
              color: colorPalette.redesign.blueSecondary,
            },
          },
          '.FBOInputSelect &': {
            width: '125px',
            zIndex: 2,
            '.MuiInputBase-root': {
              height: '38px',
              marginTop: '-1px',
              borderLeft: 'none',
              borderRadius: '0px 3px 3px 0px',
            },
          },
          '&.multi-line': {
            '.MuiInputBase-root': { height: 'auto' },
          },
          '&.reports-multi-line': {
            '.MuiInputBase-root': {
              height: 'auto',
              input: {
                width: '50%',
              },
            },
          },
        },
      }),
      popper: {
        '.MuiAutocomplete-listbox': {
          '.MuiAutocomplete-option': {
            display: 'flex',
            height: '3rem',
            padding: '0.5rem 1rem',
            alignItems: 'center',
            alignSelf: 'stretch',
            '&.Mui-focused': {
              backgroundColor: colorPalette.redesign.background3,
            },
            '&:hover': {
              backgroundColor: colorPalette.redesign.highlightBlue,
            },
            '&.MuiAutocomplete-option[aria-selected="true"]': {
              backgroundColor: colorPalette.redesign.background3,
            },
          },
        },
      },
    },
  },
};

const theme = createTheme({
  palette: newPalette,
  spacing: 6,
  typography: {
    fontFamily: 'Inter, sans-serif',
    body1: {
      fontSize: 'calc(13px * var(--font-size-multiplier, 1))',
    },
    body2: {
      fontSize: 'calc(13px * var(--font-size-multiplier, 1))',
    },
    subtitle1: {
      fontSize: 'calc(12px * var(--font-size-multiplier, 1))',
    },
    h3: {
      fontSize: 30,
      fontWeight: 600,
    },
    h4: {
      fontSize: 20,
      lineHeight: '24px',
      fontWeight: 700,
    },
    h5: {
      fontSize: 15,
      lineHeight: '24px',
      fontWeight: 700,
    },
  },
  components,
});

// RESTYLING : will be added to / adjusted for spacing stuff and typography
const themeRestyle = createTheme({
  palette: newPalette,
  spacing: 8,
  typography: {
    fontFamily: 'Inter, sans-serif',
    body1: {
      fontSize: 'calc(13px * var(--font-size-multiplier, 1))',
    },
    body2: {
      fontSize: 'calc(13px * var(--font-size-multiplier, 1))',
    },
    subtitle1: {
      fontSize: 'calc(12px * var(--font-size-multiplier, 1))',
    },
    h3: {
      fontSize: 30,
      fontWeight: 600,
    },
    h4: {
      fontSize: 20,
      lineHeight: '24px',
      fontWeight: 700,
    },
    h5: {
      fontSize: 15,
      lineHeight: '24px',
      fontWeight: 700,
    },
  },
  components,
});

theme.shadows[1] = theme.shadows[4];

export { theme, themeRestyle, colorPalette };

export { icons, IconNames } from './icons';

export * from './images';

export { default as GlobalStylesRedesign } from './GlobalStylesRedesign';

export const styled = baseStyled as ThemedStyledInterface<Theme>;
