import React, { memo, useMemo, useCallback } from 'react';
import { TableCell } from '@mui/material';
import { IconNames } from 'ui/theme';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { Item } from 'services/items';
import { ItemInventoryEvents } from 'services/inventory';
import { FBOIconButtonWithActions } from 'ui/components/Button/IconButtonWithActions';
import { MenuItem, MenuItemVariant } from 'ui/components/Menu';
import { ImageItemBox } from 'ui/components/Image/ImageItemBox';
import { PermissionType } from 'services/permissions';
import { showThumbnail } from 'services/thumbnail/redux';
import { CostingMethod } from 'ui/modules/setup/pages/SettingsPage/components/CompanyCard/types';
import { useCurrencyFormatter, roundToDecimals } from 'helpers';
import ItemCompositeHeader from './ItemCompositeHeader';
import { activeUserHasPermission } from 'services/userV2/redux';

const ItemRow: React.FC<RenderCustomRowProps<Item>> = (props) => {
  const {
    row,
    columns,
    onAction,
    meta: { companySettings },
  } = props;

  const currencyFormatter = useCurrencyFormatter();

  const showCost = useSelector(
    activeUserHasPermission([PermissionType.InventoryCosting])
  );

  const {
    totalOnHand: onHand,
    totalCommited: commited,
    totalAvailable: available,
    totalAllocated: allocated,
    totalIncoming: incoming,
  } = useMemo(() => {
    const totalOnHand = row.inventoryLocationSummaryList.reduce(
      (total, item) => {
        return total + item.totalQty;
      },
      0
    );

    const totalCommited = row.inventoryLocationSummaryList.reduce(
      (total, item) => {
        return total + item.committedQty;
      },
      0
    );

    const totalAllocated = row.inventoryLocationSummaryList.reduce(
      (total, item) => {
        return (
          total + item.allocatedOnSalesOrders + item.allocatedOnPurchaseOrders
        );
      },
      0
    );

    const totalIncoming = row.inventoryLocationSummaryList.reduce(
      (total, item) => {
        return (
          total +
          item.qtyIncomingOnSalesOrders +
          item.qtyIncomingOnPurchaseOrders
        );
      },
      0
    );

    const totalAvailable = Math.max(totalOnHand - totalAllocated, 0);

    return {
      totalOnHand,
      totalCommited,
      totalAvailable,
      totalAllocated,
      totalIncoming,
    };
  }, [row.inventoryLocationSummaryList]);

  const dispatch = useDispatch();

  const handleOpenPreview = useCallback(
    (e) => {
      const imageUrl = _.get(row, 'defaultImage', '') as string;
      dispatch(showThumbnail(imageUrl));
      e.stopPropagation();
    },
    [dispatch, row]
  );

  const handleMenuActions = useCallback(
    (type: ItemInventoryEvents) => () => {
      if (onAction) {
        onAction({ item: row, type });
      }
    },
    [row, onAction]
  );

  const menuActions: MenuItem[] = useMemo(() => {
    const menuItems: MenuItem[] = [
      {
        icon: IconNames.FBOAddCircleSmall,
        label: 'Add',
        onOptionClick: handleMenuActions(ItemInventoryEvents.Add),
        permissions: [PermissionType.InventoryAdd],
        separateAfter: true,
      },
      {
        icon: IconNames.FBODeleteSmall,
        label: 'Remove',
        onOptionClick: handleMenuActions(ItemInventoryEvents.Remove),
        permissions: [PermissionType.InventoryRemove],
        separateAfter: true,
        variant: MenuItemVariant.Danger,
      },
      {
        icon: IconNames.FBOMove,
        label: 'Move',
        onOptionClick: handleMenuActions(ItemInventoryEvents.Move),
        permissions: [PermissionType.InventoryMove],
        separateAfter: true,
      },
      {
        icon: IconNames.FBORecycleSmall,
        label: 'Cycle',
        onOptionClick: handleMenuActions(ItemInventoryEvents.Cycle),
        permissions: [PermissionType.InventoryCycle],
      },
    ];

    return menuItems;
  }, [handleMenuActions]);

  return (
    <>
      {columns.length > 3 ? (
        <TableCell style={{ width: columns[0].width }}>
          <ImageItemBox
            source={_.get(row, 'defaultImage', null)}
            name={_.get(row, 'name', null)}
            description={_.get(row, 'description', null)}
            onClick={handleOpenPreview}
          />
        </TableCell>
      ) : (
        <>
          <TableCell>
            <ItemCompositeHeader
              name={_.get(row, 'name', null)}
              description={_.get(row, 'description', null)}
            />
          </TableCell>
          <TableCell align="center" data-qa={`On Hand - ${row.name}`}>
            {roundToDecimals(onHand, 0)}
          </TableCell>
          <TableCell data-qa={`Uom - ${row.name}`}>
            {row?.defaultUom?.name}
          </TableCell>
        </>
      )}

      {columns.length > 3 && (
        <>
          <TableCell data-qa={`Barcode / UPC - ${row.name}`}>
            {row.upc}
          </TableCell>
          <TableCell data-qa={`SKU - ${row.name}`}>{row.sku}</TableCell>
          <TableCell align="center" data-qa={`On Hand - ${row.name}`}>
            {onHand} {` ${row.defaultUom ? row.defaultUom.abbreviation : ''}`}
          </TableCell>
          <TableCell align="center" data-qa={`Allocated - ${row.name}`}>
            {allocated}
            {` ${row.defaultUom ? row.defaultUom.abbreviation : ''}`}
          </TableCell>
          <TableCell align="center" data-qa={`Committed - ${row.name}`}>
            {commited}
            {` ${row.defaultUom ? row.defaultUom.abbreviation : ''}`}
          </TableCell>
          <TableCell align="center" data-qa={`Available - ${row.name}`}>
            {available}
            {` ${row.defaultUom ? row.defaultUom.abbreviation : ''}`}
          </TableCell>
          <TableCell align="center" data-qa={`Incoming - ${row.name}`}>
            {incoming}
            {` ${row.defaultUom ? row.defaultUom.abbreviation : ''}`}
          </TableCell>
          {companySettings.accountingMethod === CostingMethod.Average &&
            showCost && (
              <TableCell data-qa={`Cost - ${row.name}`}>
                {currencyFormatter(row.cost || 0)}
              </TableCell>
            )}
          <TableCell data-qa={`Uom - ${row.name}`}>
            {row?.defaultUom?.name}
          </TableCell>
          <TableCell padding="none">
            <FBOIconButtonWithActions
              variant="secondary"
              color="neutral"
              size="small"
              noBorder
              icon={IconNames.IconKebab}
              items={menuActions}
              data-qa="materials-inventory-search-results-table-kabab-button"
            />
          </TableCell>
        </>
      )}
    </>
  );
};

export default memo(ItemRow);
