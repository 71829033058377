import React, { memo, useState, useMemo } from 'react';
import { Box } from '@mui/material';
import _ from 'lodash';

import { ItemsTable } from 'ui/components/Table/ItemsTable';

import { useTrackingTableStyle } from './styled';
import { TrackingHeader, TrackingRow } from './components';
import { TrackingTableProps } from './types';
import { transformToTrackingTableColumns } from './transformations';
import { TrackingGroup, TrackingInfo } from 'services/inventory';
import { sortSearchedTrackingGroupsToTop } from 'ui/modules/materials/pages/InventoryPage/components/CycleEventModal/helpers';
import { initialPagination } from 'services/accounting';
import { Pagination } from 'services/search';

const TrackingTable: React.FC<TrackingTableProps> = (props) => {
  const {
    tableType,
    itemTrackingTypes,
    trackingGroups,
    onSetTrackingGroups,
    onAddNewTracking,
    firstColumnTitle,
    withoutAmountToAssign,
    withoutTitleBar,
    emptyTableText,
    formValues,
    errors,
    inventoryUom,
    itemUom,
    isDecimal = false,
    allowSerialNumberImport = false,
    disableAutoAssign = false,
    onEmptyTableTextRowClick,
    customRow = TrackingRow,
    readOnlyTrackingTable = false,
    closeParentModal,
    removeTrackingGroup,
    locationId,
    uomDefaultAbbreviation,
    itemCost,
    duplicateArray,
    shouldSort = false,
    headerMargin,
    sx = {},
    bulkMove = false,
    eventType,
    recalculateErrorsAfterSearch = false,
    handleValidation,
  } = props;

  const classes = useTrackingTableStyle(props);

  const [search, setSearch] = useState<string | null>(null);
  const [pagination, setPagination] = useState<Pagination>(initialPagination);

  const [searchedTrackingGroups, setSearchedTrackingGroups] = useState<
    TrackingGroup[]
  >([]);

  const columns = useMemo(
    () =>
      transformToTrackingTableColumns(
        itemTrackingTypes,
        !!withoutAmountToAssign,
        firstColumnTitle,
        tableType,
        readOnlyTrackingTable,
        bulkMove
      ),
    [itemTrackingTypes, withoutAmountToAssign, firstColumnTitle, tableType]
  );
  const filteredTrackingGroups = useMemo(() => {
    if (!search) {
      setSearchedTrackingGroups([]);
      if (recalculateErrorsAfterSearch && handleValidation) {
        handleValidation(trackingGroups);
      }
      return trackingGroups;
    }

    const isMatchWithSearch = (value: string) =>
      !_.isNull(value.match(new RegExp(search, 'i')));

    const trackingInfoFilter = (ti: TrackingInfo) =>
      !!ti.value && isMatchWithSearch(ti.value);

    const trackingGroupFilter = (tg: TrackingGroup) => {
      const matchedTrackingInfo =
        tg.trackingInfoList.filter(trackingInfoFilter);
      return !_.isEmpty(matchedTrackingInfo);
    };

    const filteredTrackingGroups = trackingGroups.filter(trackingGroupFilter);

    if (shouldSort) {
      setSearchedTrackingGroups(filteredTrackingGroups);
      const sortedTrackingGroups = sortSearchedTrackingGroupsToTop(
        trackingGroups,
        filteredTrackingGroups.map((tg) => tg.id || '')
      );
      if (recalculateErrorsAfterSearch && handleValidation) {
        handleValidation(sortedTrackingGroups);
      }
      return sortedTrackingGroups;
    }

    return trackingGroups.filter(trackingGroupFilter);
  }, [search, trackingGroups, shouldSort]);

  return (
    <>
      {!withoutTitleBar && (
        <TrackingHeader
          search={search}
          setSearch={setSearch}
          onAddNewTracking={onAddNewTracking}
          tableType={tableType}
          sx={{
            margin: headerMargin,
          }}
        />
      )}
      <Box className={classes.container}>
        <ItemsTable
          sx={sx}
          columns={columns}
          data={filteredTrackingGroups}
          setData={onSetTrackingGroups}
          RenderCustomRow={customRow}
          meta={{
            tableType,
            itemTrackingTypes,
            formValues,
            inventoryUom,
            itemUom,
            isDecimal,
            allowSerialNumberImport,
            disableAutoAssign,
            closeParentModal,
            removeTrackingGroup,
            locationId,
            uomDefaultAbbreviation,
            itemCost,
            onSetTrackingGroups,
            duplicateArray,
            searchedTrackingGroups,
            eventType,
          }}
          selectableItems={false}
          emptyTableText={emptyTableText}
          rowErrors={errors}
          dataQa="tracking-table"
          onEmptyTableTextRowClick={onEmptyTableTextRowClick}
          showEnhancedTablePaginationActions
          pagination={pagination}
          onPaginationChange={setPagination}
          containerHeight={filteredTrackingGroups.length > 5 ? 500 : undefined}
        />
      </Box>
    </>
  );
};

export default memo(TrackingTable);
