import { createApiAction } from 'services/api';
import {
  StartAction,
  SuccessAction,
  FailureAction,
} from 'services/api/redux/types';

import { clearAccessToken } from '../services';
import { AuthenticatedUser } from './types';

export enum AuthActionTypes {
  SIGN_IN_START = 'auth/sign_in_start',
  SIGN_IN_SUCCESS = 'auth/sign_in_success',
  SIGN_IN_FAILURE = 'auth/sign_in_failure',
  SIGN_OUT = 'auth/SIGN_OUT',
  SIGN_OUT_ERROR = 'auth/SIGN_OUT_ERROR',
  REGISTRATION_START = 'auth/registration_start',
  REGISTRATION_SUCCESS = 'auth/registration_success',
  REGISTRATION_FAILURE = 'auth/registration_failure',
}

const signInActionStart = (): StartAction => ({
  type: AuthActionTypes.SIGN_IN_START,
  payload: null,
});

const signInActionSuccess = (payload: AuthenticatedUser): SuccessAction => ({
  type: AuthActionTypes.SIGN_IN_SUCCESS,
  payload,
});

const signInActionFailure = (error: string): FailureAction => ({
  type: AuthActionTypes.SIGN_IN_FAILURE,
  payload: { error },
});

const signOutAction = (): SuccessAction => ({
  type: AuthActionTypes.SIGN_OUT,
  payload: null,
});

const registrationActionStart = (): StartAction => ({
  type: AuthActionTypes.REGISTRATION_START,
  payload: null,
});

const registrationActionSuccess = (
  payload: AuthenticatedUser
): SuccessAction => ({
  type: AuthActionTypes.REGISTRATION_SUCCESS,
  payload,
});

const registrationActionFailure = (error: string): FailureAction => ({
  type: AuthActionTypes.REGISTRATION_FAILURE,
  payload: { error },
});

export const signIn = (
  email: string,
  password: string,
  keepLoggedIn: boolean
) =>
  createApiAction(
    {
      hostname: `${process.env.REACT_APP_AUTHENTICATION_URL}`,
      path: '/v2/auth/authenticate/',
      method: 'POST',
      body: { username: email, password },
      additionalData: { keepLoggedIn },
    },
    signInActionStart,
    signInActionSuccess,
    signInActionFailure
  );

export function signOut() {
  clearAccessToken();

  return (dispatch: any) => {
    dispatch(signOutAction());
  };
}

export const register = (
  email: string,
  firstName: string,
  lastName: string,
  companyName: string,
  phoneNumber: string,
  country: string,
  eulaAccepted: boolean,
  timeZone: string,
  password: string
) =>
  createApiAction(
    {
      path: '/v1/auth/register',
      method: 'POST',
      body: {
        email,
        nameFirst: firstName,
        nameLast: lastName,
        companyName,
        companyPhone: phoneNumber,
        country,
        eulaAccepted,
        timezone: timeZone,
        password,
      },
      headers: { 'Application-Id': 'fbo-client-small' },
    },
    registrationActionStart,
    registrationActionSuccess,
    registrationActionFailure
  );
