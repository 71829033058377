import React, { memo, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';

import {
  reportList,
  ReportParameters,
  ReportPreset,
  ReportTheme,
} from 'services/reports';

import { NetworkSpinnerWrapper } from '../NetworkSpinnerWrapper';
import { ReportsViewerContent, ReportsViewerSidebar } from './components';
import { ReportsViewerProps } from './types';

const ReportsViewer: React.FC<ReportsViewerProps> = (props) => {
  const { reportId, defaultParams, autoGenerate, reportVersion } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState<ReportParameters>({});
  const [reportName, setReportName] = useState('');
  const [reportTheme, setReportTheme] = useState<ReportTheme | null>(null);

  const report = useMemo(
    () => reportList.find((r) => r.reportId === reportId)!,
    [reportId]
  );

  const resetState = () => {
    setReportName('');
    setParams({});
  };
  useEffect(resetState, [reportId]);

  const handleGenerateClicked = (preset: ReportPreset) => {
    // PDF rerenders every time ReportsViewerContent rerenders and PDF will be shown
    // only when reportName !== ''
    // because of that we first need to set params, then reportName
    setParams(preset.parameters);
    setReportName(report ? report.reportId : '');
  };

  return (
    <NetworkSpinnerWrapper isLoading={isLoading} size={48} height="100%">
      <Box
        display="flex"
        flexGrow={1}
        overflow="hidden"
        height="100%"
        position="relative"
      >
        <ReportsViewerContent
          report={report}
          reportName={reportName}
          params={params}
          setIsLoading={setIsLoading}
          reportTheme={reportTheme}
          reportVersion={reportVersion}
        />
        <ReportsViewerSidebar
          report={report}
          setIsLoading={setIsLoading}
          defaultParams={defaultParams}
          onGenerate={handleGenerateClicked}
          autoGenerate={autoGenerate}
          params={params}
          reportTheme={reportTheme}
          setReportTheme={setReportTheme}
        />
      </Box>
    </NetworkSpinnerWrapper>
  );
};

export default memo(ReportsViewer);
