import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { ChromePicker } from 'react-color';

import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';

import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import {
  deleteReportTheme,
  getReportThemes,
  initialReportTheme,
  initialReportThemeColor,
  putReportTheme,
  ReportFontAndBackgroundColor,
  reportFonts,
  ReportFontSize,
  ReportTheme,
  ReportThemeColor,
  reportThemeColors,
  reportThemeFontSize,
} from 'services/reports';
import { IconNames } from 'ui/theme';
import { showNotification } from 'services/api';
import { removeValueFromCollection, replaceValueInCollection } from 'helpers';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';

import { useReportsViewerSidebarStyle } from '../../styled';
import { ThemeBuilderSidebarProps } from './types';
import ThemeBuilderPresetModal from './ThemeBuilderPresetModal';
import { lightenDarkenColor } from './helpers';
import { logErrorCtx } from 'app/logging';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const ThemeBuilderSidebar: React.FC<ThemeBuilderSidebarProps> = (props) => {
  const {
    reportLayouts,
    reportTheme,
    setReportTheme,
    reportName,
    getThemePreview,
    handleLayoutChange,
  } = props;

  const classes = useReportsViewerSidebarStyle();

  const [showThemePresetModal, setShowThemeModal] = useState<boolean>(false);
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [themes, setThemes] = useState<ReportTheme[]>([]);
  const [showColorPicker, setShowColorPicker] = useState(false);

  const selectedReportFont = useMemo(() => {
    return reportFonts.find((o) => o.type === reportTheme.font) || null;
  }, [reportTheme.font]);

  const selectedReportFontSize = useMemo(() => {
    return (
      reportThemeFontSize.find((o) => o.value === reportTheme.fontSize) || null
    );
  }, [reportTheme.fontSize]);

  const selectedLayout = useMemo(
    () => reportLayouts.find((c) => c.id === reportTheme.layoutId) || null,
    [reportLayouts, reportTheme.layoutId]
  );

  const selectedTheme = useMemo(
    () => themes.find((c) => c.id === reportTheme.id) || initialReportTheme,
    [themes, reportTheme.id]
  );

  useEffect(() => {
    (async () => {
      if (selectedLayout) {
        await getThemePreview(selectedLayout);
      }
    })();
  }, [selectedLayout, getThemePreview]);

  useEffect(() => {
    (async () => {
      const resThemes = await getReportThemes(reportName);

      setThemes(resThemes);
    })();
  }, [reportName]);

  useEffect(() => {
    if (reportTheme) {
      setShowColorPicker(reportTheme.color.name === 'picker' ? true : false);
    }
  }, [reportTheme]);

  const handleColorChange = useCallback(
    (e: any, color: string) => {
      setShowColorPicker(color === 'picker' ? true : false);

      const selectedColor = reportThemeColors.find((c) => c.name === color);
      setReportTheme((old) => ({
        ...old,
        color: selectedColor || initialReportThemeColor,
      }));
    },
    [setReportTheme]
  );

  const handleFontChange = useCallback(
    (e: any, font: ReportFontAndBackgroundColor) => {
      setReportTheme((old) => ({ ...old, font: font ? font.type : null }));
    },
    [setReportTheme]
  );

  const handleFontSizeChange = useCallback(
    (e: any, fontSize: ReportFontSize) => {
      setReportTheme((old) => ({
        ...old,
        fontSize: fontSize ? fontSize.value : null,
      }));
    },
    [setReportTheme]
  );

  const handleThemeChange = useCallback(
    (event: any, theme: ReportTheme | null) => {
      const changedTheme: ReportTheme = theme ? theme : initialReportTheme;
      setReportTheme(changedTheme);
      setShowColorPicker(changedTheme.color.name === 'picker' ? true : false);
    },
    [setReportTheme]
  );

  const openThemeModal = useCallback(() => {
    setShowThemeModal(true);
  }, []);

  const closeThemeModal = useCallback(() => {
    setShowThemeModal(false);
  }, []);

  const closeDeleteModal = useCallback(() => {
    setDeleteModalVisible(false);
  }, []);

  const openDeleteModal = useCallback(() => {
    setDeleteModalVisible(true);
  }, []);

  const handleThemeUpdate = useCallback(async () => {
    try {
      const newTheme = await putReportTheme(selectedTheme.id!, reportTheme);
      const index = themes.findIndex((p) => p.id === newTheme.id);

      setThemes(
        (oldState) => replaceValueInCollection(oldState, newTheme, index)!
      );

      setReportTheme(newTheme);

      showNotification('Successfully updated theme', {
        variant: 'success',
      });
    } catch (e) {
      showNotification('Something went wrong', {
        variant: 'error',
      });
      const error = e as Error;
      logErrorCtx('Error while trying to update report theme', {
        error,
        stackTrace: error.stack,
        title: error.message,
        description:
          'Errow while upating a reports theme in ThemeBuilderPresetModal',
        component: 'ThemeBuilderPresetModal',
      });
    }
  }, [reportTheme, selectedTheme, themes, setReportTheme]);

  const handleThemeDelete = useCallback(async () => {
    setDeleteModalLoading(true);

    try {
      await deleteReportTheme(selectedTheme.id!);
      const index = themes.findIndex((p) => p.id === selectedTheme.id);
      setThemes((oldState) => removeValueFromCollection(oldState, index));

      showNotification('Successfully deleted theme', {
        variant: 'success',
      });

      setReportTheme(initialReportTheme);
    } catch (e) {
      showNotification('Something went wrong', {
        variant: 'error',
      });
      const error = e as Error;
      logErrorCtx('Error while deleting report theme', {
        error,
        stackTrace: error.stack,
        title: error.message,
        description:
          'Error while deleing a reports theme in ThemeBuilderSidebar',
        component: 'ThemeBuilderSidebar',
      });
    }

    setDeleteModalVisible(false);

    setDeleteModalLoading(false);
  }, [selectedTheme, themes, setReportTheme]);

  const handleColorPickerChangeComplete = useCallback(
    (col: any) => {
      const selectedColor: ReportThemeColor = Object.create(
        initialReportThemeColor
      );

      const colorHex: string = col.hex;
      selectedColor.label = 'Picker';
      selectedColor.name = 'picker';
      selectedColor.backgroundColor = lightenDarkenColor(colorHex, 90);
      selectedColor.borderColor = lightenDarkenColor(colorHex, 90);
      selectedColor.fontColor = colorHex;
      selectedColor.radioColor = colorHex;

      setReportTheme((old) => ({
        ...old,
        color: selectedColor,
      }));
    },
    [setReportTheme]
  );

  return (
    <>
      <Box className={classes.sidebar}>
        <Box className={classes.header} display="flex" flexDirection="column">
          <Typography>
            <b>Theme</b>
          </Typography>
          <Box mt={1}>
            <Autocomplete
              name="newTheme"
              placeholder="Choose theme"
              options={themes}
              getOptionLabel={(theme: ReportTheme) => theme.name || ''}
              value={selectedTheme}
              onChange={handleThemeChange}
            />
          </Box>

          {reportTheme.id && (
            <Box display="flex" flexDirection="column" mt={1}>
              <Box display="flex" justifyContent="space-between">
                <Box mr={1} flexGrow={1}>
                  <FBOButton
                    variant="secondary"
                    color="positive"
                    size="small"
                    data-qa="update-theme-button"
                    onClick={handleThemeUpdate}
                    fullWidth
                  >
                    Update
                  </FBOButton>
                </Box>
                <FBOButton
                  variant="secondary"
                  color="negative"
                  size="small"
                  data-qa="report-delete-preset"
                  onClick={openDeleteModal}
                  icon={IconNames.TrashCan}
                />
              </Box>
            </Box>
          )}
          <Box mt={1}>
            <FBOButton
              variant="primary"
              color="positive"
              size="small"
              data-qa="create-new-theme-button"
              onClick={openThemeModal}
              fullWidth
            >
              Create New Theme
            </FBOButton>
          </Box>
        </Box>
        <Box className={classes.header} display="flex" flexDirection="column">
          <Typography>
            <b>Layout</b>
          </Typography>
          <Box mt={1}>
            <Autocomplete
              placeholder="Change Layout"
              options={reportLayouts}
              value={selectedLayout}
              getOptionLabel={(o) => o.name || ''}
              onChange={handleLayoutChange}
            />
          </Box>
        </Box>
        <Box className={classes.header} display="flex" flexDirection="column">
          <Typography>
            <b>Color</b>
          </Typography>

          <RadioGroup
            value={reportTheme.color.name}
            onChange={handleColorChange}
            classes={{ root: classes.radioButton }}
          >
            {reportThemeColors.map((color, key) => (
              <FormControlLabel
                key={`color_${key}`}
                value={color.name}
                label={color.label}
                control={
                  <Radio
                    value={color.name}
                    style={{
                      color:
                        color.name === 'picker'
                          ? reportTheme.color.radioColor
                          : color.radioColor,
                    }}
                  />
                }
              />
            ))}
          </RadioGroup>
          {showColorPicker ? (
            <ChromePicker
              onChangeComplete={handleColorPickerChangeComplete}
              color={reportTheme.color.fontColor}
            />
          ) : (
            ''
          )}
        </Box>
        <Box className={classes.header} display="flex" flexDirection="column">
          <Typography>
            <b>Font family</b>
          </Typography>
          <Box mt={1}>
            <Autocomplete
              placeholder="Change font family"
              options={reportFonts}
              value={selectedReportFont}
              getOptionLabel={(o) => o.title || ''}
              onChange={handleFontChange}
            />
          </Box>
        </Box>
        <Box className={classes.header} display="flex" flexDirection="column">
          <Typography>
            <b>Font size</b>
          </Typography>
          <Box mt={1}>
            <Autocomplete
              placeholder="Change font size"
              options={reportThemeFontSize}
              getOptionLabel={(o) => o.title || ''}
              value={selectedReportFontSize}
              onChange={handleFontSizeChange}
            />
          </Box>
        </Box>
      </Box>
      <ThemeBuilderPresetModal
        onClose={closeThemeModal}
        show={showThemePresetModal}
        reportTheme={reportTheme}
        setThemes={setThemes}
        setReportTheme={setReportTheme}
      />
      <ConfirmationModal
        open={deleteModalVisible}
        title={`Delete preset "${reportTheme.name}"`}
        body={`This will delete theme and all related theme options, are you sure?`}
        onCancelClicked={closeDeleteModal}
        onConfirmClicked={handleThemeDelete}
        isLoading={deleteModalLoading}
        confirmLabel="Delete"
        confirmButtonRed
      />
    </>
  );
};

export default memo(ThemeBuilderSidebar);
