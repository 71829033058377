import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { TableCell, Grid, Box, Link, Popover, Typography } from '@mui/material';

import { getUoms, EACH_UOM_ID, Uom } from 'services/uoms';
import {
  PurchaseOrderItem,
  PurchaseOrderItemStatus,
  PurchaseOrderItemTypes,
  PurchaseOrderStatus,
} from 'services/purchaseOrders';
import {
  useCurrencyFormatter,
  roundToDecimals,
  stopInputPropagation,
  toMulticurrencyCalculate,
  toHomeCurrencyCalculate,
  useGetCurrencySymbol,
} from 'helpers';
import { IconNames } from 'ui/theme';
import { TextField } from 'ui/components/TextField/TextField';
import { TextFieldQuantity } from 'ui/components/TextField/TextFieldQuantity';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { Icon } from 'ui/components/Icon';
import { StatusLabel } from 'ui/components/Status/StatusLabel';
import { PermissionType } from 'services/permissions';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';
import { PopoverItem } from 'ui/components/PopoverItem';
import {
  calculatePriceBasedOnUomChange,
  convertToNegative,
  calculateTaxTotal,
} from 'services/salesOrders';
import { TaxTooltip } from 'ui/components/TaxTooltip';
import { filterItemUoms } from 'services/items/helpers';
import { MultiCurrencyWrapper } from 'ui/components/MultiCurrencyWrapper/MultiCurrencyWrapper';
import { getTaxRates, renderTaxRate, TaxRate } from 'services/taxRates';
import { getSettingsPurchaseOrder } from 'services/settings/purchaseOrders';

import { MenuTableCell } from '../MenuTableCell';
import { editPurchaseOrderPermissions } from '../../../../helpers';
import { totalAnchorOrigin, totalTransformOrigin } from '../../consts';

const MiscRow: React.FC<RenderCustomRowProps<PurchaseOrderItem>> = (props) => {
  const {
    row,
    index,
    setData,
    errors,
    meta: { purchaseOrder, activeMulticurrencyCode },
  } = props;

  const [uoms, setUoms] = useState<Uom[]>([]);
  const [taxEl, setTaxEl] = useState<null | HTMLElement>(null);

  const { items: defaultUoms } = useSelector(getUoms);
  const { items: storeTaxRates } = useSelector(getTaxRates);
  const poSettings = useSelector(getSettingsPurchaseOrder);
  const currencySymbol = useGetCurrencySymbol();

  const fieldsDisabled =
    purchaseOrder.status === PurchaseOrderStatus.Fulfilled ||
    row.status === PurchaseOrderItemStatus.Fulfilled ||
    purchaseOrder.deleted;

  const editPermission = editPurchaseOrderPermissions(purchaseOrder);

  const currencyFormatter = useCurrencyFormatter();

  const isTaxPopoverVisible = useMemo(() => Boolean(taxEl), [taxEl]);

  const selectedTaxRate = useMemo(
    () => storeTaxRates.find((t) => t.id === row.taxId) || null,
    [storeTaxRates, row.taxId]
  );
  const taxRates = useMemo(
    () => storeTaxRates.filter((tax) => tax.taxType === 'Percentage'),
    [storeTaxRates]
  );

  const resolveDisplayTax = useMemo(() => {
    if (selectedTaxRate) {
      return renderTaxRate(selectedTaxRate, currencySymbol);
    } else {
      return '0%';
    }
  }, [selectedTaxRate, currencySymbol]);

  const subtotalCost = useMemo(() => {
    const taxRate = row.taxRate ? row.taxRate : 0;

    let costWithQuantity = row.totalCost || 0;
    if (purchaseOrder.id === -1) {
      if (poSettings.costIncludesTax && taxRate > 0) {
        costWithQuantity = (row.totalCost || 0) / (1 + taxRate);
      }
    }
    if (purchaseOrder.costIncludesTax && taxRate > 0) {
      costWithQuantity = (row.totalCost || 0) / (1 + taxRate);
    }

    return costWithQuantity;
  }, [poSettings.costIncludesTax, row]);

  const formatedMulticurrency = useMemo(
    () =>
      activeMulticurrencyCode &&
      currencyFormatter(
        toMulticurrencyCalculate(
          subtotalCost,
          purchaseOrder.exchangeRate || 1
        ) || 0,
        activeMulticurrencyCode
      ),
    [
      activeMulticurrencyCode,
      currencyFormatter,
      purchaseOrder.exchangeRate,
      subtotalCost,
    ]
  );

  const formatedMulticurrencyTax = useMemo(
    () =>
      activeMulticurrencyCode &&
      currencyFormatter(
        (row.taxTotal || 0) * (purchaseOrder.exchangeRate || 1),
        activeMulticurrencyCode
      ),
    [
      activeMulticurrencyCode,
      row,
      purchaseOrder.exchangeRate,
      currencyFormatter,
    ]
  );

  const rowIcon = useMemo(() => {
    switch (row.purchaseOrderItemType) {
      case PurchaseOrderItemTypes.MiscPurchase:
        return <Icon name={IconNames.MiscSale} noDefaultFill />;
      case PurchaseOrderItemTypes.MiscCredit:
        return <Icon name={IconNames.CreditReturn} noDefaultFill />;
    }
  }, [row.purchaseOrderItemType]);

  useEffect(() => {
    // Set total cost for live update
    const cost = row.unitCost || 0;
    const mutlicurrencyCost = row.multiCurrencyUnitCost || 0;
    const quantity = row.quantity || 0;
    const multiCurrencyTotalCost = roundToDecimals(
      mutlicurrencyCost * quantity,
      2
    );

    const totalCost = cost * quantity;

    const taxTotal = row.taxRate
      ? calculateTaxTotal(row.taxRate, subtotalCost, 0)
      : 0;

    setData(
      {
        ...row,
        totalCost,
        taxTotal,
        exchangeRate: purchaseOrder.exchangeRate,
        multiCurrencyTotalCost: multiCurrencyTotalCost || null,
      },
      index
    );
    // eslint-disable-next-line
  }, [
    row.unitCost,
    row.quantity,
    purchaseOrder.exchangeRate,
    row.taxRate,
    row.taxTotal,
    subtotalCost,
  ]);

  useEffect(() => {
    if (row.item) {
      const itemUoms = filterItemUoms(row.item.defaultUom, defaultUoms);
      if (itemUoms) {
        setUoms(itemUoms);
      }
    }
  }, [row, defaultUoms]);

  const handleTextInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value ? e.target.value : null;
    setData({ ...row, [e.target.name]: value }, index);
  };

  const handlePriceInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
        ? convertToNegative(e.target.value, row.purchaseOrderItemType)
        : null;
      setData(
        {
          ...row,
          unitCost: value,
          multiCurrencyUnitCost: toMulticurrencyCalculate(
            value || 0,
            purchaseOrder.exchangeRate || 1
          ),
        },
        index
      );
    },
    [index, row, setData, purchaseOrder.exchangeRate]
  );

  const handleAmountInputChange = useCallback(
    (value: number | null) => {
      const newPoiRow = {
        ...row,
        quantity: value,
      };
      setData(newPoiRow, index);
    },
    [row, index, setData]
  );

  const handleAmountMenuChange = useCallback(
    (uomId: number) => {
      const newUom = uoms.find((u) => u.id === uomId)!;
      const oldUom = uoms.find((u) => u.id === row.uomId)!;

      const newPrice = calculatePriceBasedOnUomChange(
        newUom,
        oldUom,
        row.unitCost || 0,
        []
      );

      const newSoiRow = {
        ...row,
        uomId,
        unitCost: newPrice,
        multiCurrencyUnitCost: toMulticurrencyCalculate(
          newPrice,
          row.exchangeRate || 1
        ),
      };
      setData(newSoiRow, index);
    },
    [row, index, setData, uoms]
  );

  const handleMulticurrencyFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value
      ? convertToNegative(e.target.value, row.purchaseOrderItemType)
      : null;
    const unitCost = toHomeCurrencyCalculate(
      value || 0,
      purchaseOrder.exchangeRate
    );
    setData({ ...row, unitCost, multiCurrencyUnitCost: value }, index);
  };

  const taxButtonClickHandler = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      setTaxEl(event.currentTarget);
    },
    []
  );

  const handleTaxPopClose = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();

      setTaxEl(null);
    },
    []
  );

  const handleTaxPopCliked = useCallback(
    (tax: TaxRate | null, taxable: boolean) => () => {
      const taxRate = tax ? tax.percentage : null;

      setData(
        {
          ...row,
          taxId: tax ? tax.id : null,
          taxRate,
          taxable,
        },
        index
      );
      setTaxEl(null);
    },
    [row, index, setData]
  );

  return (
    <>
      <TableCell>
        <Box display="flex" alignItems="center">
          <Box
            marginRight={2}
            width={40}
            height={40}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexShrink={0}
          >
            {rowIcon}
          </Box>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label={
              row.purchaseOrderItemType === PurchaseOrderItemTypes.MiscPurchase
                ? 'Miscellaneous Purchase'
                : 'Miscellaneous Credit'
            }
            placeholder="Enter name"
            name="name"
            permissions={editPermission}
            value={row.name}
            fullWidth
            disabled={fieldsDisabled}
            onChange={handleTextInputChanged}
            onClick={stopInputPropagation}
            autoComplete="disabled"
            error={!!errors.name}
            dataQa="misc-row-miscellaneous"
          />
        </Box>
      </TableCell>
      <TableCell>
        <Grid item xs>
          <CurrencyField
            placeholder="Cost USD"
            name="unitCost"
            value={roundToDecimals(row.unitCost || 0, 5)}
            onChange={handlePriceInputChange}
            onClick={stopInputPropagation}
            fullWidth
            disabled={fieldsDisabled}
            permissions={[PermissionType.PurchaseOrderOverrideCost]}
            error={!!errors.unitCost}
            allowNegative
            decimalPlaces={5}
          />
        </Grid>
      </TableCell>

      <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
        <TableCell>
          <CurrencyField
            placeholder={`Cost ${activeMulticurrencyCode}`}
            value={roundToDecimals(row.multiCurrencyUnitCost || 0, 5)}
            onClick={stopInputPropagation}
            onChange={handleMulticurrencyFieldChange}
            disabled={fieldsDisabled}
            fullWidth
            debounceDelay={700}
            permissions={[PermissionType.PurchaseOrderOverrideCost]}
            currencyCode={activeMulticurrencyCode}
            allowNegative
            decimalPlaces={5}
            error={!!errors.unitCost}
          />
        </TableCell>
      </MultiCurrencyWrapper>

      <TableCell>
        <Grid container>
          <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
            <Box margin="0px 5px 0px -15px" paddingLeft="5px">
              &times;
            </Box>
          </Grid>
          <Grid item xs>
            <TextFieldQuantity
              placeholder="Quantity"
              name="amount"
              value={row.quantity}
              selectedUomId={row.uomId || EACH_UOM_ID}
              onTextChange={handleAmountInputChange}
              onMenuChange={handleAmountMenuChange}
              onClick={stopInputPropagation}
              fullWidth
              disabled={fieldsDisabled}
              permissions={editPermission}
              error={!!errors.quantity}
              uoms={uoms}
              isDecimal
              dataQa="misc-row-quantity"
            />
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align="center">
        <TaxTooltip tax={selectedTaxRate} taxable={row.taxable}>
          <Link
            variant="body2"
            color="textPrimary"
            onClick={taxButtonClickHandler}
            underline="always"
            data-qa="item-row-tax"
            className="aligned-link"
          >
            {resolveDisplayTax}
          </Link>
        </TaxTooltip>
        <Popover
          anchorOrigin={totalAnchorOrigin}
          transformOrigin={totalTransformOrigin}
          anchorEl={taxEl}
          open={isTaxPopoverVisible}
          onClose={handleTaxPopClose}
        >
          <Box width={150}>
            <Grid container>
              <PopoverItem
                label="None"
                value="0%"
                onClick={handleTaxPopCliked(null, true)}
              />
              {taxRates.map((tax, i) => {
                return (
                  <PopoverItem
                    key={i}
                    label={tax.name!}
                    value={renderTaxRate(tax, currencySymbol)}
                    onClick={handleTaxPopCliked(tax, true)}
                  />
                );
              })}
              <PopoverItem
                label="Not Taxable"
                value=""
                onClick={handleTaxPopCliked(null, false)}
              />
            </Grid>
          </Box>
        </Popover>
      </TableCell>
      <TableCell align="center">
        <StatusLabel
          status={row.status}
          label={`${row.quantityFulfilled ? row.quantityFulfilled : 0}/${
            row.quantity ? row.quantity : 0
          }`}
        />
      </TableCell>
      <TableCell align="right">
        <Typography variant="body2" color="textPrimary">
          {currencyFormatter(subtotalCost || 0)}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {currencyFormatter(row.taxTotal || 0)}
        </Typography>
      </TableCell>
      <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
        <TableCell align="right">
          <Link underline="none" variant="body2" color="textPrimary">
            {formatedMulticurrency || ''}
          </Link>

          <Typography variant="subtitle2" color="textSecondary">
            {formatedMulticurrencyTax || ''}
          </Typography>
        </TableCell>
      </MultiCurrencyWrapper>
      <MenuTableCell {...props} />
    </>
  );
};

export default memo(MiscRow);
