import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useReportsViewerContentStyle = makeStyles<Theme>((theme) => ({
  content: {
    flex: 1,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: '240px',
  },
  contentInner: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    width: 100,
    height: 100,
    borderRadius: '50%',
    background: '#f1f1f1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  icon: {
    fill: '#666',
  },
}));
