import React, { memo, useCallback, useMemo } from 'react';

import { InputAdornment, IconButton, PopoverOrigin } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { TextField } from 'ui/components/TextField/TextField';
import { Menu } from 'ui/components/Menu';

import { TextFieldWithActionsProps } from './types';
import { useTextFieldWithActionsStyle } from './styled';

const anchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

const transformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

const TextFieldWithActions: React.FC<TextFieldWithActionsProps> = (props) => {
  const { actionItems, dataQa, ...otherProps } = props;

  const classes = useTextFieldWithActionsStyle(props);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const isActionMenuVisible = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const handleIconClicked = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleActionMenuClose = useCallback(() => setAnchorEl(null), []);

  const renderAdornment = useCallback(
    () => (
      <InputAdornment position="end">
        <IconButton onClick={handleIconClicked} edge="end" size="large">
          <ArrowDropDownIcon data-qa={`${dataQa}-arrowdown`} />
        </IconButton>
      </InputAdornment>
    ),
    [handleIconClicked, dataQa]
  );

  return (
    <>
      <TextField
        className="redesign"
        variant="standard"
        {...otherProps}
        InputLabelProps={{
          shrink: true,
          margin: 'dense',
          classes: { root: classes.label },
        }}
        InputProps={{
          endAdornment: renderAdornment(),
          classes: {
            root: classes.root,
            disabled: classes.root,
          },
        }}
        disabled
        dataQa={dataQa}
      />
      <Menu
        items={actionItems}
        open={isActionMenuVisible}
        anchorEl={anchorEl}
        onClose={handleActionMenuClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onMenuItemClick={handleActionMenuClose}
        dataQa={dataQa}
      />
    </>
  );
};

export default memo(TextFieldWithActions);
