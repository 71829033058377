import { PermissionType } from 'services/permissions';

export interface RowDescriptorBase {
  title: string;
  field?: string;
  textbox?: boolean;
  meta?: any;
  dataQa?: string;
  disabled?: boolean;
}

// Types for RowDescriptor
export interface TextDescriptor extends RowDescriptorBase {
  type: RowType.Text;
}

export interface TagDescriptor extends RowDescriptorBase {
  type: RowType.Tag;
}

export interface MappingDescriptor<T = any> extends RowDescriptorBase {
  type: RowType.Mapping;
  options: T[];
  isFieldDisabled?(option: T): boolean;
  getOptionsLabel?(option: T): string;
  isOptionEqualToValue?(option: T, value: T): boolean;
  handleAutocompleteChange?(
    e: React.ChangeEvent<{}>,
    value: T,
    field: string,
    setCardData: (field: string, value: unknown) => void
  ): void;
}

export interface LocationPickerDescriptor extends RowDescriptorBase {
  type: RowType.LocationPicker;
}

export type RowDescriptor =
  | TextDescriptor
  | LocationPickerDescriptor
  | MappingDescriptor
  | TagDescriptor;

export enum RowType {
  'Text' = 'text',
  'LocationPicker' = 'locationPicker',
  'Mapping' = 'mapping',
  'Tag' = 'tag',
}

export interface CardProps {
  title: string;
  data: unknown;
  onApplyClicked(newData: unknown): void;
  rows: RowDescriptor[];
  id?: string;
  show: boolean;
  viewPermissions?: PermissionType[];
  editPermissions?: PermissionType[];
  dataQa?: string;
  cardDataLoaded: boolean;
  setTagsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  setLocationsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}
