import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import _ from 'lodash';
import { Grid } from '@mui/material';

import { Errors, validateYup } from 'services/forms/validation';
import {
  initialItem,
  Item,
  ItemType,
  postItem,
  transformToDefaultSaleItem,
} from 'services/items';
import { EACH_UOM_ID, getUoms, Uom } from 'services/uoms';
import { useHandleTextFieldChange } from 'services/forms';
import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';
import {
  CustomField,
  fetchCustomFieldsAPI,
  ObjectType,
  useCustomFields,
} from 'services/customFields';
import { replaceValueInCollection } from 'helpers';
import {
  CustomFields,
  customFieldsYupSchema,
} from 'ui/components/CustomFields/CustomFields';
import { getSettingsCompany } from 'services/settings/company';
import { getSettingsSalesOrder } from 'services/settings/salesOrders';

import { NewItemModalProps } from './types';
import { Autocomplete } from '../Autocomplete';

const yupSchema = yup.object().shape({
  name: yup.string(),
  defaultUomId: yup.number(),
  type: yup.string(),
  cost: yup.number(),
  price: yup.number(),
});

const NewItemModal: React.FC<NewItemModalProps> = (props) => {
  const { show, newItemName, itemTypes, onClose, onSave } = props;

  const { items: uoms } = useSelector(getUoms);
  const companySettings = useSelector(getSettingsCompany);
  const soSettings = useSelector(getSettingsSalesOrder);

  const [formItem, setFormItem] = useState<Item>(initialItem);
  const [modalLoading, setModalLoading] = useState(false);
  const [errors, setErrors] = useState<Errors>({});
  const [customFieldsErrors, setCustomFieldsErrors] = useState<Errors>({});
  const [price, setPrice] = useState<number | null>(0);

  const setCustomFields = useCustomFields<Item>(setFormItem);

  const isItemTypeOfShipping = formItem.itemType === ItemType.Shipping;

  const isItemTypeOfLaborOrOverhead =
    formItem.itemType === ItemType.Labor ||
    formItem.itemType === ItemType.Overhead;

  const selectedUom = useMemo(
    () => uoms.find((u) => u.id === formItem.defaultUomId),
    [uoms, formItem.defaultUomId]
  );

  const itemTypeOptions = useMemo(() => {
    if (itemTypes && itemTypes.length) {
      return itemTypes;
    }

    return _.difference(Object.values(ItemType), [ItemType.Bundle]);
  }, [itemTypes]);

  const checkShippingType = useMemo(() => {
    // when only item option is shipping type, input should be disabled
    if (
      itemTypes &&
      itemTypes.length === 1 &&
      itemTypes[0] === ItemType.Shipping
    ) {
      return true;
    }
    return false;
  }, [itemTypes]);

  useEffect(() => {
    setFormItem({
      ...initialItem,
      name: newItemName,
      itemType: itemTypeOptions[0],
    });
    setErrors({});
    setPrice(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (isItemTypeOfShipping) {
      setFormItem((old) => ({
        ...old,
        defaultUomId: EACH_UOM_ID,
      }));
    }
  }, [isItemTypeOfShipping]);

  useEffect(() => {
    // Fetch customFields and add them to state
    if (show) {
      (async () => {
        const customFields = (await fetchCustomFieldsAPI({}, ObjectType.Item))
          .data;

        setCustomFields(customFields);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const handleApplyClicked = async () => {
    if (
      !validateYup({ ...formItem, price }, yupSchema, setErrors) ||
      !validateYup(
        formItem.customFields,
        customFieldsYupSchema,
        setCustomFieldsErrors
      )
    ) {
      return;
    }

    setModalLoading(true);

    const defaultSaleItem = {
      ...transformToDefaultSaleItem(formItem, soSettings, companySettings),
      price,
    };

    const formItemWithDefaultSaleItem: Item = {
      ...formItem,
      saleItemList: [defaultSaleItem],
    };

    try {
      const newItem = await postItem(formItemWithDefaultSaleItem);
      onSave(newItem);
    } catch {
      // do nothing
    }

    setModalLoading(false);
  };

  const handleTextFieldChange = useHandleTextFieldChange(setFormItem, formItem);

  const handleTypeChange = (e: any, itemType: ItemType | null) =>
    setFormItem((old) => ({ ...old, itemType }));

  const handleUomChange = (e: any, uom: Uom | null) =>
    setFormItem((old) => ({
      ...old,
      defaultUomId: uom ? uom.id : null,
    }));

  const handlePriceChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value ? parseFloat(e.target.value) : null;
    setPrice(value);
  };

  const customFieldChanged = useCallback(
    (customField: CustomField) => {
      const index = formItem.customFields.findIndex(
        (c) => c.id === customField.id
      );
      setCustomFields(
        (old) => replaceValueInCollection<CustomField>(old, customField, index)!
      );
    },
    [formItem.customFields, setCustomFields]
  );

  return (
    <Modal
      open={show}
      title="New Item"
      onCancelClicked={onClose}
      onApplyClicked={handleApplyClicked}
      applyLabel="Save"
      isLoadingContent={modalLoading}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            className="redesign"
            variant="standard"
            name="name"
            label="Name"
            placeholder="Name"
            value={formItem.name}
            onChange={handleTextFieldChange}
            required
            error={!!errors.name}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            options={itemTypeOptions}
            name="itemType"
            label="Type"
            value={formItem.itemType}
            onChange={handleTypeChange}
            disabled={checkShippingType}
            required
            error={!!errors.itemType}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            options={uoms}
            name="defaultUomId"
            label="UOM"
            getOptionLabel={(uom: Uom) => uom.name || ''}
            value={selectedUom}
            onChange={handleUomChange}
            required
            placeholder="Select UOM"
            disabled={isItemTypeOfShipping}
            error={!!errors.defaultUom}
          />
        </Grid>
        <Grid item xs={3}>
          <CurrencyField
            label="Average cost"
            placeholder="Average cost"
            name="cost"
            value={formItem.cost}
            onChange={handleTextFieldChange}
            allowNegative
            required
            error={!!errors.cost}
          />
        </Grid>
        {!isItemTypeOfLaborOrOverhead && (
          <Grid item xs={3}>
            <CurrencyField
              label="Price"
              placeholder="Enter price"
              value={price}
              onChange={handlePriceChange}
              decimalPlaces={5}
              autoComplete="nope"
              allowNegative
              required
              error={!!errors.price}
            />
          </Grid>
        )}
      </Grid>

      <CustomFields
        customFields={formItem.customFields}
        onFieldChange={customFieldChanged}
        errors={customFieldsErrors}
      />
    </Modal>
  );
};

export default memo(NewItemModal);
