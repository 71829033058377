import React, { memo, useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { Modal } from 'ui/components/Modal/Modal';
import {
  getReportLayout,
  initialReportTheme,
  ReportLayout,
  ReportTheme,
} from 'services/reports';

import ThemeBuilderSidebar from './ThemeBuilderSidebar';
import { ThemeBuilderProps } from './types';
import { getHtmlReportThemePreview } from './report';
import {
  StyledThemeBuilderContent,
  useThemeBuilderStyle,
  StyledThemeBuilderContentModern,
} from './styled';
import { logErrorCtx } from 'app/logging';

const ThemeBuilderViewer: React.FC<ThemeBuilderProps> = (props) => {
  const { visibility, onClose, reportName, selectedReportTheme } = props;

  const [html, setHtml] = useState('');
  const [reportLayouts, setReportLayouts] = useState<ReportLayout[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reportTheme, setReportTheme] =
    useState<ReportTheme>(initialReportTheme);

  const classes = useThemeBuilderStyle();

  useEffect(() => {
    if (!visibility) {
      return;
    }

    setReportTheme(
      selectedReportTheme ? selectedReportTheme : initialReportTheme
    );

    setHtml('');
    setIsLoading(true);

    (async () => {
      const layoutsRes = await getReportLayout(reportName);
      setReportLayouts(layoutsRes);

      const defaultLayout = layoutsRes.find((l) => l.isDefault);

      if (reportTheme) {
        setIsLoading(false);
        return;
      }

      if (defaultLayout) {
        setReportTheme((old) => ({
          ...old,
          layoutId: defaultLayout.id,
        }));

        await getThemePreview(defaultLayout);
      }

      setIsLoading(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibility, selectedReportTheme]);

  const getThemePreview = useCallback(async (layout: ReportLayout) => {
    if (layout.reportName && layout.id) {
      try {
        const response = await getHtmlReportThemePreview(
          layout.reportName,
          layout.id
        );
        setHtml(response);
      } catch (e) {
        const error = e as Error;
        logErrorCtx('Error while fetching theme preview', {
          error,
          stackTrace: error.stack,
          title: error.message,
          description: 'Error while trying to preview a reports theme',
          component: 'ThemeBuilderViewer',
        });
      }
    }
  }, []);

  const handleLayoutChange = useCallback(
    (e: any, layout: ReportLayout) => {
      setIsLoading(true);

      setReportTheme({ ...reportTheme, layoutId: layout ? layout.id : 1 });

      setIsLoading(false);
    },
    [reportTheme, setIsLoading, setReportTheme]
  );

  return (
    <Modal
      open={visibility}
      title="Theme Builder"
      onCancelClicked={onClose}
      className={classes.reportsModal}
      withoutFooter
      fullScreen
      isLoadingContent={isLoading}
    >
      <Box display="flex" paddingRight="240px">
        {reportTheme.layoutId !== 2 ? (
          <StyledThemeBuilderContent
            dangerouslySetInnerHTML={{ __html: html }}
            reportTheme={reportTheme}
          />
        ) : (
          <StyledThemeBuilderContentModern
            dangerouslySetInnerHTML={{ __html: html }}
            reportTheme={reportTheme}
          />
        )}

        <ThemeBuilderSidebar
          reportLayouts={reportLayouts}
          handleLayoutChange={handleLayoutChange}
          reportTheme={reportTheme}
          setReportTheme={setReportTheme}
          reportName={reportName}
          getThemePreview={getThemePreview}
        />
      </Box>
    </Modal>
  );
};

export default memo(ThemeBuilderViewer);
