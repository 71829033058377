import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Box, Grid, Link, Typography } from '@mui/material';
import _ from 'lodash';

import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { validateYup } from 'services/forms/validation';
import {
  splitShipItem,
  transformToCreateShipItem,
  getShip,
  updateShipItem,
  initialCarton,
  ShipCarton,
} from 'services/shipping';
import { useHandleTextFieldChange } from 'services/forms/hooks';

import { SplitItemModalProps, SplitItemModalForm } from './types';
import { defaultFormValues } from './consts';
import { yupShippingSplitModalSchema } from '../../../../validations';

const SplitItemModal: React.FC<SplitItemModalProps> = (props) => {
  const {
    activeCartonId,
    activeShip,
    setActiveShip,
    activeShipItemId,
    onCancelClicked,
    visible,
    onAddNewCarton,
    oldState,
  } = props;

  const [formValues, setFormValues] =
    useState<SplitItemModalForm>(defaultFormValues);
  const [formErrors, setFormErrors] = useState<any>({});
  const [activeShipItemAmount, setActiveShipItemAmount] = useState<number>(0);
  const [activeCartonNumber, setActiveCartonNumber] = useState<string>('');
  const [modalLoading, setModalLoading] = useState<boolean>(false);

  useEffect(() => {
    const newActiveCarton = activeShip.shipCartonList.find(
      (carton) => carton.id === activeCartonId
    );

    if (newActiveCarton) {
      const newActiveShipItem = activeShip.shipItemList.find(
        (item) => item.id === activeShipItemId
      );
      if (newActiveShipItem) {
        setActiveShipItemAmount(newActiveShipItem.quantity);
        setFormValues({
          ...defaultFormValues,
          amount: newActiveShipItem.quantity,
          defaultAmount: newActiveShipItem.quantity,
        });

        setActiveCartonNumber(newActiveCarton.number || '');
      }
    }
  }, [
    activeShipItemId,
    activeCartonId,
    activeShip.shipCartonList,
    activeShip.shipItemList,
  ]);

  const handleNumberFieldChange = useHandleTextFieldChange<SplitItemModalForm>(
    setFormValues,
    formValues,
    true
  );

  const selectedDestination = useMemo(() => {
    return (
      activeShip.shipCartonList.find((c) => c.id === formValues.destination) ||
      null
    );
  }, [activeShip.shipCartonList, formValues]);

  const activeShipItem = useMemo(
    () => activeShip.shipItemList.find((item) => item.id === activeShipItemId),
    [activeShip, activeShipItemId]
  );

  const rootLocationCartonList = useMemo(() => {
    return activeShip.shipCartonList.filter((l) => l.id !== activeCartonId);
  }, [activeCartonId, activeShip.shipCartonList]);

  const handleResetClick = useCallback(
    () => setFormValues({ ...formValues, amount: activeShipItemAmount }),
    [formValues, activeShipItemAmount]
  );

  const handleDestinationChange = useCallback(
    (_e: any, v: any) => {
      if (v.id < 0) {
        onAddNewCarton();
        return;
      }

      if (v == null) {
        setFormValues({ ...formValues, destination: null });
      } else {
        setFormValues({ ...formValues, destination: v.id });
      }
    },
    [formValues, setFormValues, onAddNewCarton]
  );

  const splitAndUpdateShipItem = useCallback(async () => {
    setModalLoading(true);
    await splitShipItem(
      activeShip.id || 0,
      activeShipItemId,
      formValues.amount || 0,
      formValues.destination || 0
    );

    const newShip = await getShip(activeShip.id || 0);
    oldState.current = newShip;
    setActiveShip(newShip);
    onCancelClicked();
    setModalLoading(false);
  }, [
    formValues,
    onCancelClicked,
    activeShip,
    oldState,
    activeShipItemId,
    setActiveShip,
  ]);

  const moveAndUpdateShipItem = useCallback(async () => {
    setModalLoading(true);
    if (!activeShipItem || !formValues.destination) {
      return;
    }
    const newShipItem = transformToCreateShipItem({
      ...activeShipItem,
      shipCartonId: formValues.destination,
    });
    await updateShipItem(activeShip.id || 0, newShipItem);

    const newShip = await getShip(activeShip.id || 0);
    oldState.current = newShip;
    setActiveShip(newShip);
    onCancelClicked();
    setModalLoading(false);
  }, [
    formValues,
    onCancelClicked,
    activeShip,
    setActiveShip,
    activeShipItem,
    oldState,
  ]);

  const handleApplyClicked = useCallback(async () => {
    const isValid = validateYup(
      formValues,
      yupShippingSplitModalSchema,
      setFormErrors
    );

    if (isValid) {
      try {
        if (formValues.amount! < activeShipItemAmount) {
          splitAndUpdateShipItem();
        } else {
          moveAndUpdateShipItem();
        }
      } catch {
        return;
      }
    }
  }, [
    formValues,
    activeShipItemAmount,
    moveAndUpdateShipItem,
    splitAndUpdateShipItem,
  ]);

  const handleModalClose = () => {
    onCancelClicked();
    setFormErrors({});
  };

  const applyLabel = () => {
    return formValues.amount === activeShipItemAmount ? 'Move' : 'Split';
  };

  return (
    <Modal
      open={visible}
      title={`Package ${activeCartonNumber} - ${activeShipItemAmount} ${
        activeShipItem && _.get(activeShipItem, 'uom.abbreviation', null)
      }`}
      onCancelClicked={handleModalClose}
      onResetClicked={handleResetClick}
      onApplyClicked={handleApplyClicked}
      applyLabel={applyLabel()}
      maxWidth="sm"
      isLoading={modalLoading}
      isLoadingContent={modalLoading}
      dataQa="shipping-item"
    >
      <Grid container spacing={1}>
        <Grid item xs={5}>
          <TextField
            type="number"
            label="Quantity"
            name="amount"
            autoComplete="nope"
            fullWidth
            required
            value={formValues.amount}
            onChange={handleNumberFieldChange}
            disableDebounce={true}
            error={!!formErrors.amount}
            helperText={formErrors.amount}
            dataQa="split-item-quantity"
            className="redesign"
            variant="standard"
          />
        </Grid>
        <Grid item xs={7}>
          <Autocomplete
            label="Destination"
            required
            options={[...initialCarton, ...rootLocationCartonList]}
            getOptionLabel={(c) => (c.id < 0 ? '' : `Package ${c.number}`)}
            onChange={handleDestinationChange}
            placeholder="Select destination"
            value={selectedDestination}
            autoSelect={false}
            renderOption={(SplitItemModalProps, c: ShipCarton) => {
              if (c.id && c.id < 0) {
                return (
                  <li {...SplitItemModalProps}>
                    <Box display="flex" width="100%">
                      <Link
                        underline="always"
                        color="textPrimary"
                        variant="body2"
                      >
                        {c.number}
                      </Link>
                    </Box>
                  </li>
                );
              }
              return (
                <li {...SplitItemModalProps}>
                  <Box display="flex" width="100%">
                    <Typography variant="body1"> Package {c.number}</Typography>
                  </Box>
                </li>
              );
            }}
            error={!!formErrors.destination}
            dataQa="split-item-destination"
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default React.memo(SplitItemModal);
