import React from 'react';
import { NetworkSpinnerWrapper } from 'ui/components/NetworkSpinnerWrapper';
import { FBODetailCardReadOnlyProps } from './types';
import { Box } from '@mui/system';

const FBODetailCardReadOnly = (props: FBODetailCardReadOnlyProps) => {
  const {
    isLoading = false,
    isPolling = false,

    children,
  } = props;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <NetworkSpinnerWrapper
        isLoading={isLoading}
        isPolling={isPolling}
        display="flex"
        flexDirection="column"
        overflow="hidden"
        flexGrow={1}
        zIndex={2}
      >
        {children}
      </NetworkSpinnerWrapper>
    </Box>
  );
};

export default FBODetailCardReadOnly;
