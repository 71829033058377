import { PickingSettings } from './types';

export const transformPickOrderSettings = (response: any): PickingSettings => {
  return {
    id: response.id,
    version: response.version,
    pickCreationType: response.pickCreationType,
    pickMethodType: response.pickMethodType,
    groupPickMaxItems: response.groupPickMaxItems,
    pickingExpiredItemWarning: response.pickingExpiredItemWarning,
    autoCommitSoPicks: response.autoCommitSoPicks,
    autoCommitPoPicks: response.autoCommitPoPicks,
    autoCommitXoPicks: response.autoCommitXoPicks,
    finishAutoCommittedPicks: response.finishAutoCommittedPicks,
    enableOverPicking: response.enableOverPicking,
    notifyWhenOverPicking: response.notifyWhenOverPicking,
  };
};

export const transformToPoSettingsPut = (poSettings: PickingSettings) => ({
  id: poSettings.id,
  version: poSettings.version,
  pickCreationType: poSettings.pickCreationType,
  pickMethodType: poSettings.pickMethodType,
  groupPickMaxItems: poSettings.groupPickMaxItems,
  pickingExpiredItemWarning: poSettings.pickingExpiredItemWarning,
  autoCommitSoPicks: poSettings.autoCommitSoPicks,
  autoCommitPoPicks: poSettings.autoCommitPoPicks,
  autoCommitXoPicks: poSettings.autoCommitXoPicks,
  finishAutoCommittedPicks: poSettings.finishAutoCommittedPicks,
  enableOverPicking: poSettings.enableOverPicking,
  notifyWhenOverPicking: poSettings.notifyWhenOverPicking,
});
