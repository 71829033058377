import {
  createApiCall,
  DataWithPagination,
  paginatedApiCall,
} from 'services/api';

import {
  transformProfitToChartData,
  transformPurchaseExpensesToChartData,
  transformSalesRevenueToChartData,
  transformToLowStock,
  transformToOpenPick,
  transformToOpenPurchaseOrder,
  transformToOpenReceipt,
  transformToOpenSalesOrder,
  transformToOpenShipment,
  transformToSaleByRegion,
  transformToSalesOrderGeneral,
  transformToTopPurchasedItem,
  transformToTopSellingItem,
} from './transformations';

import {
  ChartData,
  GeneralWidgetValues,
  LowStock,
  OpenPick,
  OpenPurchaseOrder,
  OpenReceipt,
  OpenSalesOrder,
  OpenShipment,
  RegionalSaleOrder,
  SalesOrderGeneral,
  TopPurchasedItem,
  TopSellingItem,
} from './types';
import { Pagination } from 'services/search';

export const fetchGeneralWidgetData = async (
  latestStartRange: string,
  latestEndRange: string,
  previousStartRange: string,
  previousEndRange: string
): Promise<GeneralWidgetValues> => {
  const widgetData: GeneralWidgetValues = {
    previousRevenueTotal: 0,
    previousExpensesTotal: 0,
    previousItemsTotal: 0,
    previousSoTotal: 0,
    latestExpensesTotal: 0,
    latestSoTotal: 0,
    latestRevenueTotal: 0,
    latestItemsTotal: 0,
    lowStock: 0,
  };

  try {
    const totalRevenueResponse = await createApiCall({
      path: `/v1/general_metrics/total_revenue?previousStartRange=${previousStartRange}&previousEndRange=${previousEndRange}&latestStartRange=${latestStartRange}&latestEndRange=${latestEndRange}`,
      method: 'GET',
    })();
    widgetData.previousRevenueTotal =
      totalRevenueResponse.data.previousTotal || 0;
    widgetData.latestRevenueTotal = totalRevenueResponse.data.latestTotal || 0;
  } catch {
    // Ignore error
  }

  try {
    const totalSalesOrdersResponse = await createApiCall({
      path: `/v1/general_metrics/sales_orders?previousStartRange=${previousStartRange}&previousEndRange=${previousEndRange}&latestStartRange=${latestStartRange}&latestEndRange=${latestEndRange}`,
      method: 'GET',
    })();
    widgetData.previousSoTotal =
      totalSalesOrdersResponse.data.previousTotal || 0;
    widgetData.latestSoTotal = totalSalesOrdersResponse.data.latestTotal || 0;
  } catch {
    // Ignore error
  }

  try {
    const totalItemsSoldResponse = await createApiCall({
      path: `/v1/general_metrics/items_sold?previousStartRange=${previousStartRange}&previousEndRange=${previousEndRange}&latestStartRange=${latestStartRange}&latestEndRange=${latestEndRange}`,
      method: 'GET',
    })();
    widgetData.previousItemsTotal =
      totalItemsSoldResponse.data.previousTotal || 0;
    widgetData.latestItemsTotal = totalItemsSoldResponse.data.latestTotal || 0;
  } catch {
    // Ignore error
  }

  try {
    const totalExpensesResponse = await createApiCall({
      path: `/v1/general_metrics/expenses?previousStartRange=${previousStartRange}&previousEndRange=${previousEndRange}&latestStartRange=${latestStartRange}&latestEndRange=${latestEndRange}`,
      method: 'GET',
    })();
    widgetData.previousExpensesTotal =
      totalExpensesResponse.data.previousTotal || 0;
    widgetData.latestExpensesTotal =
      totalExpensesResponse.data.latestTotal || 0;
  } catch {
    // Ignore error
  }

  try {
    const totalLowStockResponse = await createApiCall({
      path: `/v1/general_metrics/low_stock?previousStartRange=${latestStartRange}&previousEndRange=${latestEndRange}`,
      method: 'GET',
    })();
    widgetData.lowStock = totalLowStockResponse.data.maxResults || 0;
  } catch {
    // Ignore error
  }

  return widgetData;
};

export const fetchProfitData = async (
  latestStartRange: string,
  latestEndRange: string,
  previousStartRange: string,
  previousEndRange: string
): Promise<ChartData[]> => {
  const resp = await createApiCall({
    path: `/v1/general_metrics/profit?previousStartRange=${previousStartRange}&previousEndRange=${previousEndRange}&latestStartRange=${latestStartRange}&latestEndRange=${latestEndRange}`,
  })();

  return resp.data.map(transformProfitToChartData);
};

export const fetchSalesTotalRevenue = async (
  latestStartRange: string,
  latestEndRange: string,
  previousStartRange: string,
  previousEndRange: string
): Promise<ChartData[]> => {
  const resp = await createApiCall({
    path: `/v1/sales_metrics/total_revenue?previousStartRange=${previousStartRange}&previousEndRange=${previousEndRange}&latestStartRange=${latestStartRange}&latestEndRange=${latestEndRange}`,
  })();

  return resp.data.results.map(transformSalesRevenueToChartData);
};

export const fetchPurchasingExpenses = async (
  latestStartRange: string,
  latestEndRange: string,
  previousStartRange: string,
  previousEndRange: string
): Promise<ChartData[]> => {
  const resp = await createApiCall({
    path: `/v1/purchase_metrics/expenses?previousStartRange=${previousStartRange}&previousEndRange=${previousEndRange}&latestStartRange=${latestStartRange}&latestEndRange=${latestEndRange}`,
  })();
  return resp.data.results.map(transformPurchaseExpensesToChartData);
};

// GENERAL TABLE

export const fetchSalesOrderGeneral = async (
  pagination: Pagination,
  previousStartRange: string,
  previousEndRange: string
): Promise<DataWithPagination<SalesOrderGeneral>> => {
  const res = await paginatedApiCall(
    `/v1/general_metrics/sales_orders_list?previousStartRange=${previousStartRange}&previousEndRange=${previousEndRange}`,
    pagination,
    transformToSalesOrderGeneral
  );

  return res;
};

export const fetchLowStock = async (
  pagination: Pagination,
  previousStartRange: string,
  previousEndRange: string
): Promise<DataWithPagination<LowStock>> => {
  const res = await paginatedApiCall(
    `/v1/general_metrics/low_stock?previousStartRange=${previousStartRange}&previousEndRange=${previousEndRange}`,
    pagination,
    transformToLowStock
  );

  return res;
};

// SALES TABLE
export const fetchTopSellingItems = async (
  pagination: Pagination,
  previousStartRange: string,
  previousEndRange: string
): Promise<DataWithPagination<TopSellingItem>> => {
  const res = await paginatedApiCall(
    `/v1/sales_metrics/top_selling_items?previousStartRange=${previousStartRange}&previousEndRange=${previousEndRange}`,
    pagination,
    transformToTopSellingItem
  );

  return res;
};

export const fetchOpenSalesOrders = async (
  pagination: Pagination,
  previousStartRange: string,
  previousEndRange: string
): Promise<DataWithPagination<OpenSalesOrder>> => {
  const res = await paginatedApiCall(
    `/v1/sales_metrics/open_sales_orders?previousStartRange=${previousStartRange}&previousEndRange=${previousEndRange}`,
    pagination,
    transformToOpenSalesOrder
  );

  return res;
};

export const fetchOpenShipments = async (
  pagination: Pagination,
  previousStartRange: string,
  previousEndRange: string
): Promise<DataWithPagination<OpenShipment>> => {
  const res = await paginatedApiCall(
    `/v1/sales_metrics/open_shipments?previousStartRange=${previousStartRange}&previousEndRange=${previousEndRange}`,
    pagination,
    transformToOpenShipment
  );

  return res;
};

export const fetchOpenPicks = async (
  pagination: Pagination,
  previousStartRange: string,
  previousEndRange: string
): Promise<DataWithPagination<OpenPick>> => {
  const res = await paginatedApiCall(
    `/v1/sales_metrics/open_picks?previousStartRange=${previousStartRange}&previousEndRange=${previousEndRange}`,
    pagination,
    transformToOpenPick
  );

  return res;
};

// PURCHASE ORDER TABLE

export const fetchOpenPurchaseOrders = async (
  pagination: Pagination,
  previousStartRange: string,
  previousEndRange: string
): Promise<DataWithPagination<OpenPurchaseOrder>> => {
  const res = await paginatedApiCall(
    `/v1/purchase_metrics/open_purchase_orders?previousStartRange=${previousStartRange}&previousEndRange=${previousEndRange}`,
    pagination,
    transformToOpenPurchaseOrder
  );

  return res;
};

export const fetchOpenReceipts = async (
  pagination: Pagination,
  previousStartRange: string,
  previousEndRange: string
): Promise<DataWithPagination<OpenReceipt>> => {
  const res = await paginatedApiCall(
    `/v1/purchase_metrics/open_receipts?previousStartRange=${previousStartRange}&previousEndRange=${previousEndRange}`,
    pagination,
    transformToOpenReceipt
  );

  return res;
};

export const fetchMostPurchasedItems = async (
  pagination: Pagination,
  previousStartRange: string,
  previousEndRange: string
): Promise<DataWithPagination<TopPurchasedItem>> => {
  const res = await paginatedApiCall(
    `/v1/purchase_metrics/most_purchased_items?previousStartRange=${previousStartRange}&previousEndRange=${previousEndRange}`,
    pagination,
    transformToTopPurchasedItem
  );

  return res;
};

export const fetchSalesByRegions = async (
  dateFrom: string,
  dateTo: string,
  country: string | null = null
): Promise<{
  salesByRegion: RegionalSaleOrder[];
  nonAddressedOrder: RegionalSaleOrder | null;
}> => {
  let path = `/v1/sales_metrics/sales_by_region?previousStartRange=${dateFrom}&previousEndRange=${dateTo}`;
  if (country) {
    path += `&groupBy=state&country=${country}`;
  }

  const res = await createApiCall({
    path,
  })();

  const salesByRegion: RegionalSaleOrder[] = [];
  let nonAddressedOrder: RegionalSaleOrder | null = null;

  res.data.results.forEach((res: any) => {
    const sale = transformToSaleByRegion(res);
    if (sale.country) {
      salesByRegion.push(sale);
    } else {
      nonAddressedOrder = sale;
    }
  });

  return {
    nonAddressedOrder,
    salesByRegion,
  };
};
