import { CountryType } from './types';

export const COUNTRIES: CountryType[] = [
  {
    code: 'US',
    currencyCode: 'USD',
    currencyName: 'United States Dollar',
    currencySymbol: '$',
    label: 'United States',
    locale: 'en-US',
  },
  {
    code: 'CA',
    currencyCode: 'CAD',
    currencyName: 'Canadian Dollar',
    currencySymbol: '$',
    label: 'Canada',
    locale: 'en-CA',
  },
  {
    code: 'AF',
    currencyCode: 'AFN',
    currencyName: 'Afghanistan Afghani',
    currencySymbol: '؋',
    label: 'Afghanistan',
    locale: 'prs-AF',
  },
  {
    code: 'AX',
    currencyCode: 'EUR',
    currencyName: 'Euro',
    currencySymbol: '€',
    label: 'Aland Islands',
    locale: 'en-US',
  },
  {
    code: 'AL',
    currencyCode: 'ALL',
    currencyName: 'Albanian Lek',
    currencySymbol: 'Lek',
    label: 'Albania',
    locale: 'sq-AL',
  },
  {
    code: 'DZ',
    currencyCode: 'DZD',
    currencyName: 'Algerian Dinar',
    currencySymbol: 'دج',
    label: 'Algeria',
    locale: 'ar-DZ',
  },
  {
    code: 'AS',
    currencyCode: 'USD',
    currencyName: 'US Dollar',
    currencySymbol: '$',
    label: 'American Samoa',
    locale: 'en-US',
  },
  {
    code: 'AD',
    currencyCode: 'EUR',
    currencyName: 'Euro',
    currencySymbol: '€',
    label: 'Andorra',
    locale: 'en-US',
  },
  {
    code: 'AO',
    currencyCode: 'AOA',
    currencyName: 'Angolan Kwanza',
    currencySymbol: 'Kz',
    label: 'Angola',
    locale: 'en-US',
  },
  {
    code: 'AI',
    currencyCode: 'XCD',
    currencyName: 'East Caribbean Dollar',
    currencySymbol: '$',
    label: 'Anguilla',
    locale: 'en-US',
  },
  {
    code: 'AQ',
    currencyCode: 'AAD',
    currencyName: 'East Caribbean Dollar',
    currencySymbol: '$',
    label: 'Antarctica',
    locale: 'en-US',
  },
  {
    code: 'AG',
    currencyCode: 'XCD',
    currencyName: 'East Caribbean Dollar',
    currencySymbol: '$',
    label: 'Antigua and Barbuda',
    locale: 'en-US',
  },
  {
    code: 'AR',
    currencyCode: 'ARS',
    currencyName: 'Argentine Peso',
    currencySymbol: '$',
    label: 'Argentina',
    locale: 'es-AR',
  },
  {
    code: 'AM',
    currencyCode: 'AMD',
    currencyName: 'Armenian Dram',
    currencySymbol: '֏',
    label: 'Armenia',
    locale: 'hy-AM',
  },
  {
    code: 'AW',
    currencyCode: 'AWG',
    currencyName: 'Aruban Guilder',
    currencySymbol: 'ƒ',
    label: 'Aruba',
    locale: 'en-US',
  },
  {
    code: 'AU',
    currencyCode: 'AUD',
    currencyName: 'Australian Dollar',
    currencySymbol: '$',
    label: 'Australia',
    locale: 'en-AU',
  },
  {
    code: 'AT',
    currencyCode: 'EUR',
    currencyName: 'Euro',
    currencySymbol: '€',
    label: 'Austria',
    locale: 'de-AT',
  },
  {
    code: 'AZ',
    currencyCode: 'AZN',
    currencyName: 'Azerbaijan New Manat',
    currencySymbol: 'm',
    label: 'Azerbaijan',
    locale: 'az-AZ',
  },
  {
    code: 'BS',
    currencyCode: 'BSD',
    currencyName: 'Bahamian Dollar',
    currencySymbol: 'B$',
    label: 'Bahamas',
    locale: 'en-US',
  },
  {
    code: 'BH',
    currencyCode: 'BHD',
    currencyName: 'Bahraini Dinar',
    currencySymbol: '.د.ب',
    label: 'Bahrain',
    locale: 'ar-BH',
  },
  {
    code: 'BD',
    currencyCode: 'BDT',
    currencyName: 'Bangladeshi Taka',
    currencySymbol: '৳',
    label: 'Bangladesh',
    locale: 'bn-BD',
  },
  {
    code: 'BB',
    currencyCode: 'BBD',
    currencyName: 'Barbados Dollar',
    currencySymbol: 'Bds$',
    label: 'Barbados',
    locale: 'en-US',
  },
  {
    code: 'BY',
    currencyCode: 'BYN',
    currencyName: 'Belarussian Ruble',
    currencySymbol: 'Br',
    label: 'Belarus',
    locale: 'be-BY',
  },
  {
    code: 'BE',
    currencyCode: 'EUR',
    currencyName: 'Euro',
    currencySymbol: '€',
    label: 'Belgium',
    locale: 'fr-BE',
  },
  {
    code: 'BZ',
    currencyCode: 'BZD',
    currencyName: 'Belize Dollar',
    currencySymbol: '$',
    label: 'Belize',
    locale: 'en-BZ',
  },
  {
    code: 'BJ',
    currencyCode: 'XOF',
    currencyName: 'CFA Franc BCEAO',
    currencySymbol: 'CFA',
    label: 'Benin',
    locale: 'en-US',
  },
  {
    code: 'BM',
    currencyCode: 'BMD',
    currencyName: 'Bermudian Dollar',
    currencySymbol: '$',
    label: 'Bermuda',
    locale: 'en-US',
  },
  {
    code: 'BT',
    currencyCode: 'BTN',
    currencyName: 'Bhutan Ngultrum',
    currencySymbol: 'Nu.',
    label: 'Bhutan',
    locale: 'en-US',
  },
  {
    code: 'BO',
    currencyCode: 'BOB',
    currencyName: 'Boliviano',
    currencySymbol: 'Bs.',
    label: 'Bolivia',
    locale: 'es-BO',
  },
  {
    code: 'BQ',
    currencyCode: 'USD',
    currencyName: 'United States Dollar',
    currencySymbol: '$',
    label: 'Bonaire, Sint Eustatius and Saba',
    locale: 'en-US',
  },
  {
    code: 'BA',
    currencyCode: 'BAM',
    currencyName: 'Convertible Mark',
    currencySymbol: 'KM',
    label: 'Bosnia and Herzegovina',
    locale: 'hr-BA',
  },
  {
    code: 'BW',
    currencyCode: 'BWP',
    currencyName: 'Botswana Pula',
    currencySymbol: 'P',
    label: 'Botswana',
    locale: 'en-US',
  },
  {
    code: 'BV',
    currencyCode: 'NOK',
    currencyName: 'Norwegian Krone',
    currencySymbol: 'kr',
    label: 'Bouvet Island',
    locale: 'en-US',
  },
  {
    code: 'BR',
    currencyCode: 'BRL',
    currencyName: 'Brazilian Real',
    currencySymbol: 'R$',
    label: 'Brazil',
    locale: 'pt-BR',
  },
  {
    code: 'IO',
    currencyCode: 'USD',
    currencyName: 'US Dollar',
    currencySymbol: '$',
    label: 'British Indian Ocean Territory',
    locale: 'en-US',
  },
  {
    code: 'BN',
    currencyCode: 'BND',
    currencyName: 'Brunei Dollar',
    currencySymbol: 'B$',
    label: 'Brunei Darussalam',
    locale: 'ms-BN',
  },
  {
    code: 'BG',
    currencyCode: 'BGN',
    currencyName: 'Bulgarian Lev',
    currencySymbol: 'Лв.',
    label: 'Bulgaria',
    locale: 'bg-BG',
  },
  {
    code: 'BF',
    currencyCode: 'XOF',
    currencyName: 'CFA Franc BCEAO',
    currencySymbol: 'CFA',
    label: 'Burkina Faso',
    locale: 'en-US',
  },
  {
    code: 'BI',
    currencyCode: 'BIF',
    currencyName: 'Burundi Franc',
    currencySymbol: 'FBu',
    label: 'Burundi',
    locale: 'en-US',
  },
  {
    code: 'KH',
    currencyCode: 'KHR',
    currencyName: 'Kampuchean Riel',
    currencySymbol: 'KHR',
    label: 'Cambodia',
    locale: 'km-KH',
  },
  {
    code: 'CM',
    currencyCode: 'XAF',
    currencyName: 'CFA Franc BEAC',
    currencySymbol: 'FCFA',
    label: 'Cameroon',
    locale: 'en-US',
  },
  {
    code: 'CV',
    currencyCode: 'CVE',
    currencyName: 'Cape Verde Escudo',
    currencySymbol: '$',
    label: 'Cape Verde',
    locale: 'en-US',
  },
  {
    code: 'KY',
    currencyCode: 'KYD',
    currencyName: 'Cayman Islands Dollar',
    currencySymbol: '$',
    label: 'Cayman Islands',
    locale: 'en-US',
  },
  {
    code: 'CF',
    currencyCode: 'XAF',
    currencyName: 'CFA Franc BEAC',
    currencySymbol: 'FCFA',
    label: 'Central African Republic',
    locale: 'en-US',
  },
  {
    code: 'TD',
    currencyCode: 'XAF',
    currencyName: 'Central African CFA franc',
    currencySymbol: 'FCFA',
    label: 'Chad',
    locale: 'en-US',
  },
  {
    code: 'CL',
    currencyCode: 'CLP',
    currencyName: 'Chilean Peso',
    currencySymbol: '$',
    label: 'Chile',
    locale: 'arn-CL',
  },
  {
    code: 'CN',
    currencyCode: 'CNY',
    currencyName: 'Yuan Renminbi',
    currencySymbol: '¥',
    label: 'China',
    locale: 'zh-CN',
  },
  {
    code: 'CX',
    currencyCode: 'AUD',
    currencyName: 'Australian Dollar',
    currencySymbol: '$',
    label: 'Christmas Island',
    locale: 'en-US',
  },
  {
    code: 'CC',
    currencyCode: 'AUD',
    currencyName: ' Australian Dollar',
    currencySymbol: '$',
    label: 'Cocos (Keeling) Islands',
    locale: 'en-US',
  },
  {
    code: 'CO',
    currencyCode: 'COP',
    currencyName: 'Colombian Peso',
    currencySymbol: '$',
    label: 'Colombia',
    locale: 'es-CO',
  },
  {
    code: 'KM',
    currencyCode: 'KMF',
    currencyName: 'Comoros Franc',
    currencySymbol: 'CF',
    label: 'Comoros',
    locale: 'en-US',
  },
  {
    code: 'CG',
    currencyCode: 'XAF',
    currencyName: 'CFA Franc BEAC',
    currencySymbol: 'FC',
    label: 'Congo',
    locale: 'en-GB',
  },
  {
    code: 'CD',
    currencyCode: 'CDF',
    currencyName: 'Congolese franc',
    currencySymbol: 'FC',
    label: 'Congo, Democratic Republic of the Congo',
    locale: 'en-GB',
  },
  {
    code: 'CK',
    currencyCode: 'NZD',
    currencyName: 'New Zealand Dollar',
    currencySymbol: '$',
    label: 'Cook Islands',
    locale: 'en-US',
  },
  {
    code: 'CR',
    currencyCode: 'CRC',
    currencyName: 'Costa Rican Colon',
    currencySymbol: '₡',
    label: 'Costa Rica',
    locale: 'es-CR',
  },
  {
    code: 'CI',
    currencyCode: 'XOF',
    currencyName: 'West African CFA franc',
    currencySymbol: 'CFA',
    label: "Cote D'Ivoire",
    locale: 'en-US',
  },
  {
    code: 'HR',
    currencyCode: 'HRK',
    currencyName: 'Croatian Kuna',
    currencySymbol: 'kn',
    label: 'Croatia',
    locale: 'hr-HR',
  },
  {
    code: 'CU',
    currencyCode: 'CUP',
    currencyName: 'Cuban Peso',
    currencySymbol: '$',
    label: 'Cuba',
    locale: 'en-US',
  },
  {
    code: 'CW',
    currencyCode: 'ANG',
    currencyName: ' Netherlands Antillean guilder',
    currencySymbol: 'ƒ',
    label: 'Curacao',
    locale: 'en-US',
  },
  {
    code: 'CY',
    currencyCode: 'EUR',
    currencyName: 'Euro',
    currencySymbol: '€',
    label: 'Cyprus',
    locale: 'el-CY',
  },
  {
    code: 'CZ',
    currencyCode: 'CZK',
    currencyName: 'Czech koruna',
    currencySymbol: 'Kč',
    label: 'Czech Republic',
    locale: 'cs-CZ',
  },
  {
    code: 'DK',
    currencyCode: 'DKK',
    currencyName: 'Danish Krone',
    currencySymbol: 'Kr.',
    label: 'Denmark',
    locale: 'da-DK',
  },
  {
    code: 'DJ',
    currencyCode: 'DJF',
    currencyName: 'Djibouti Franc',
    currencySymbol: 'Fdj',
    label: 'Djibouti',
    locale: 'en-US',
  },
  {
    code: 'DM',
    currencyCode: 'XCD',
    currencyName: 'East Caribbean Dollar',
    currencySymbol: '$',
    label: 'Dominica',
    locale: 'en-US',
  },
  {
    code: 'DO',
    currencyCode: 'DOP',
    currencyName: 'Dominican Peso',
    currencySymbol: '$',
    label: 'Dominican Republic',
    locale: 'es-DO',
  },
  {
    code: 'EC',
    currencyCode: 'USD',
    currencyName: 'United States Dollar',
    currencySymbol: '$',
    label: 'Ecuador',
    locale: 'es-EC',
  },
  {
    code: 'EG',
    currencyCode: 'EGP',
    currencyName: 'Egyptian Pound',
    currencySymbol: 'ج.م',
    label: 'Egypt',
    locale: 'ar-EG',
  },
  {
    code: 'SV',
    currencyCode: 'USD',
    currencyName: 'United States Dollar',
    currencySymbol: '$',
    label: 'El Salvador',
    locale: 'es-SV',
  },
  {
    code: 'GQ',
    currencyCode: 'XAF',
    currencyName: 'CFA Franc BEAC',
    currencySymbol: 'FCFA',
    label: 'Equatorial Guinea',
    locale: 'en-US',
  },
  {
    code: 'ER',
    currencyCode: 'ERN',
    currencyName: 'Eritrean Nakfa',
    currencySymbol: 'Nfk',
    label: 'Eritrea',
    locale: 'en-US',
  },
  {
    code: 'EE',
    currencyCode: 'EUR',
    currencyName: 'Euro',
    currencySymbol: '€',
    label: 'Estonia',
    locale: 'et-EE',
  },
  {
    code: 'ET',
    currencyCode: 'ETB',
    currencyName: 'Ethiopian Birr',
    currencySymbol: 'Nkf',
    label: 'Ethiopia',
    locale: 'am-ET',
  },
  {
    code: 'FK',
    currencyCode: 'FKP',
    currencyName: 'Falkland Islands pound',
    currencySymbol: '£',
    label: 'Falkland Islands (Malvinas)',
    locale: 'en-US',
  },
  {
    code: 'FO',
    currencyCode: 'DKK',
    currencyName: ' Danish Krone',
    currencySymbol: 'Kr.',
    label: 'Faroe Islands',
    locale: 'fo-FO',
  },
  {
    code: 'FJ',
    currencyCode: 'FJD',
    currencyName: 'Fijian dollar',
    currencySymbol: 'FJ$',
    label: 'Fiji',
    locale: 'en-US',
  },
  {
    code: 'FI',
    currencyCode: 'EUR',
    currencyName: 'Euro',
    currencySymbol: '€',
    label: 'Finland',
    locale: 'fi-FI',
  },
  {
    code: 'FR',
    currencyCode: 'EUR',
    currencyName: 'Euro',
    currencySymbol: '€',
    label: 'France',
    locale: 'br-FR',
  },
  {
    code: 'GF',
    currencyCode: 'EUR',
    currencyName: 'Euro',
    currencySymbol: '€',
    label: 'French Guiana',
    locale: 'en-US',
  },
  {
    code: 'PF',
    currencyCode: 'XPF',
    currencyName: 'CFP franc',
    currencySymbol: '₣',
    label: 'French Polynesia',
    locale: 'en-US',
  },
  {
    code: 'TF',
    currencyCode: 'EUR',
    currencyName: 'CFP franc',
    currencySymbol: '€',
    label: 'French Southern Territories',
    locale: 'en-US',
  },
  {
    code: 'GA',
    currencyCode: 'XAF',
    currencyName: 'CFA Franc BEAC',
    currencySymbol: 'FCFA',
    label: 'Gabon',
    locale: 'en-US',
  },
  {
    code: 'GM',
    currencyCode: 'GMD',
    currencyName: 'Gambian Dalasi',
    currencySymbol: 'D',
    label: 'Gambia',
    locale: 'en-US',
  },
  {
    code: 'GE',
    currencyCode: 'GEL',
    currencyName: 'Georgian Lari',
    currencySymbol: 'ლ',
    label: 'Georgia',
    locale: 'ka-GE',
  },
  {
    code: 'DE',
    currencyCode: 'EUR',
    currencyName: 'Euro',
    currencySymbol: '€',
    label: 'Germany',
    locale: 'de-DE',
  },
  {
    code: 'GH',
    currencyCode: 'GHS',
    currencyName: 'Ghanaian Cedi',
    currencySymbol: 'GH₵',
    label: 'Ghana',
    locale: 'en-US',
  },
  {
    code: 'GI',
    currencyCode: 'GIP',
    currencyName: 'Gibraltar Pound',
    currencySymbol: '£',
    label: 'Gibraltar',
    locale: 'en-US',
  },
  {
    code: 'GR',
    currencyCode: 'EUR',
    currencyName: 'Euro',
    currencySymbol: '€',
    label: 'Greece',
    locale: 'el-GR',
  },
  {
    code: 'GL',
    currencyCode: 'DKK',
    currencyName: 'Danish Krone',
    currencySymbol: 'Kr.',
    label: 'Greenland',
    locale: 'kl-GL',
  },
  {
    code: 'GD',
    currencyCode: 'XCD',
    currencyName: 'East Carribean Dollar',
    currencySymbol: '$',
    label: 'Grenada',
    locale: 'en-US',
  },
  {
    code: 'GP',
    currencyCode: 'EUR',
    currencyName: 'Euro',
    currencySymbol: '€',
    label: 'Guadeloupe',
    locale: 'en-US',
  },
  {
    code: 'GU',
    currencyCode: 'USD',
    currencyName: 'United States Dollar',
    currencySymbol: '$',
    label: 'Guam',
    locale: 'en-US',
  },
  {
    code: 'GT',
    currencyCode: 'GTQ',
    currencyName: 'Guatemalan Quetzal',
    currencySymbol: 'Q',
    label: 'Guatemala',
    locale: 'es-GT',
  },
  {
    code: 'GG',
    currencyCode: 'GBP',
    currencyName: 'Guernsey pound',
    currencySymbol: '£',
    label: 'Guernsey',
    locale: 'en-US',
  },
  {
    code: 'GN',
    currencyCode: 'GNF',
    currencyName: 'Guinea Franc',
    currencySymbol: 'FG',
    label: 'Guinea',
    locale: 'en-US',
  },
  {
    code: 'GW',
    currencyCode: 'XOF',
    currencyName: 'Guinea-Bissau peso',
    currencySymbol: 'CFA',
    label: 'Guinea-Bissau',
    locale: 'en-US',
  },
  {
    code: 'GY',
    currencyCode: 'GYD',
    currencyName: 'Guyana Dollar',
    currencySymbol: '$',
    label: 'Guyana',
    locale: 'en-US',
  },
  {
    code: 'HT',
    currencyCode: 'HTG',
    currencyName: 'Haitian Gourde',
    currencySymbol: 'G',
    label: 'Haiti',
    locale: 'en-US',
  },
  {
    code: 'HM',
    currencyCode: 'AUD',
    currencyName: 'Australian Dollar',
    currencySymbol: '$',
    label: 'Heard Island and Mcdonald Islands',
    locale: 'en-US',
  },
  {
    code: 'VA',
    currencyCode: 'EUR',
    currencyName: 'Euro',
    currencySymbol: '€',
    label: 'Holy See (Vatican City State)',
    locale: 'en-US',
  },
  {
    code: 'HN',
    currencyCode: 'HNL',
    currencyName: 'Honduran Lempira',
    currencySymbol: 'L',
    label: 'Honduras',
    locale: 'es-HN',
  },
  {
    code: 'HK',
    currencyCode: 'HKD',
    currencyName: 'Hong Kong Dollar',
    currencySymbol: '$',
    label: 'Hong Kong',
    locale: 'zh-HK',
  },
  {
    code: 'HU',
    currencyCode: 'HUF',
    currencyName: 'Hungarian Forint',
    currencySymbol: 'Ft',
    label: 'Hungary',
    locale: 'hu-HU',
  },
  {
    code: 'IS',
    currencyCode: 'ISK',
    currencyName: 'Iceland Krona',
    currencySymbol: 'kr',
    label: 'Iceland',
    locale: 'is-IS',
  },
  {
    code: 'IN',
    currencyCode: 'INR',
    currencyName: 'Indian Rupee',
    currencySymbol: '₹',
    label: 'India',
    locale: 'as-IN',
  },
  {
    code: 'ID',
    currencyCode: 'IDR',
    currencyName: 'Indonesian Rupiah',
    currencySymbol: 'Rp',
    label: 'Indonesia',
    locale: 'id-ID',
  },
  {
    code: 'IR',
    currencyCode: 'IRR',
    currencyName: 'Iranian rial',
    currencySymbol: '﷼',
    label: 'Iran, Islamic Republic of',
    locale: 'fa-IR',
  },
  {
    code: 'IQ',
    currencyCode: 'IQD',
    currencyName: 'Iraqi Dinar',
    currencySymbol: 'د.ع',
    label: 'Iraq',
    locale: 'ar-IQ',
  },
  {
    code: 'IE',
    currencyCode: 'EUR',
    currencyName: 'Euro',
    currencySymbol: '€',
    label: 'Ireland',
    locale: 'en-IE',
  },
  {
    code: 'IM',
    currencyCode: 'GBP',
    currencyName: 'Pound',
    currencySymbol: '£',
    label: 'Isle of Man',
    locale: 'en-US',
  },
  {
    code: 'IL',
    currencyCode: 'ILS',
    currencyName: 'Israeli New Shekel',
    currencySymbol: '₪',
    label: 'Israel',
    locale: 'he-IL',
  },
  {
    code: 'IT',
    currencyCode: 'EUR',
    currencyName: 'Euro',
    currencySymbol: '€',
    label: 'Italy',
    locale: 'it-IT',
  },
  {
    code: 'JM',
    currencyCode: 'JMD',
    currencyName: 'Jamaican Dollar',
    currencySymbol: 'J$',
    label: 'Jamaica',
    locale: 'en-JM',
  },
  {
    code: 'JP',
    currencyCode: 'JPY',
    currencyName: 'Japanese Yen',
    currencySymbol: '¥',
    label: 'Japan',
    locale: 'ja-JP',
  },
  {
    code: 'JE',
    currencyCode: 'GBP',
    currencyName: 'Pound',
    currencySymbol: '£',
    label: 'Jersey',
    locale: 'en-US',
  },
  {
    code: 'JO',
    currencyCode: 'JOD',
    currencyName: 'Jordanian Dinar',
    currencySymbol: 'ا.د',
    label: 'Jordan',
    locale: 'ar-JO',
  },
  {
    code: 'KZ',
    currencyCode: 'KZT',
    currencyName: 'Kazakhstani tenge',
    currencySymbol: 'лв',
    label: 'Kazakhstan',
    locale: 'kk-KZ',
  },
  {
    code: 'KE',
    currencyCode: 'KES',
    currencyName: 'Kenyan Shilling',
    currencySymbol: 'KSh',
    label: 'Kenya',
    locale: 'sw-KE',
  },
  {
    code: 'KI',
    currencyCode: 'AUD',
    currencyName: 'Australian Dollar',
    currencySymbol: '$',
    label: 'Kiribati',
    locale: 'en-US',
  },
  {
    code: 'KP',
    currencyCode: 'KPW',
    currencyName: 'North Korean won',
    currencySymbol: '₩',
    label: "Korea, Democratic People's Republic of",
    locale: 'en-US',
  },
  {
    code: 'KR',
    currencyCode: 'KRW',
    currencyName: 'South Korean won',
    currencySymbol: '₩',
    label: 'Korea, Republic of',
    locale: 'ko-KR',
  },
  {
    code: 'XK',
    currencyCode: 'EUR',
    currencyName: 'Euro',
    currencySymbol: '€',
    label: 'Kosovo',
    locale: 'en-US',
  },
  {
    code: 'KW',
    currencyCode: 'KWD',
    currencyName: 'Kuwaiti Dinar',
    currencySymbol: 'ك.د',
    label: 'Kuwait',
    locale: 'ar-KW',
  },
  {
    code: 'KG',
    currencyCode: 'KGS',
    currencyName: 'Som',
    currencySymbol: 'лв',
    label: 'Kyrgyzstan',
    locale: 'ky-KG',
  },
  {
    code: 'LA',
    currencyCode: 'LAK',
    currencyName: 'Lao kip',
    currencySymbol: '₭',
    label: "Lao People's Democratic Republic",
    locale: 'lo-LA',
  },
  {
    code: 'LV',
    currencyCode: 'EUR',
    currencyName: 'Latvian Lats',
    currencySymbol: '€',
    label: 'Latvia',
    locale: 'lv-LV',
  },
  {
    code: 'LB',
    currencyCode: 'LBP',
    currencyName: 'Lebanese Pound',
    currencySymbol: '£',
    label: 'Lebanon',
    locale: 'ar-LB',
  },
  {
    code: 'LS',
    currencyCode: 'LSL',
    currencyName: 'Lesotho Loti',
    currencySymbol: 'L',
    label: 'Lesotho',
    locale: 'en-US',
  },
  {
    code: 'LR',
    currencyCode: 'LRD',
    currencyName: 'Liberian Dollar',
    currencySymbol: '$',
    label: 'Liberia',
    locale: 'en-US',
  },
  {
    code: 'LY',
    currencyCode: 'LYD',
    currencyName: 'Libyan dinar',
    currencySymbol: 'د.ل',
    label: 'Libyan Arab Jamahiriya',
    locale: 'ar-LY',
  },
  {
    code: 'LI',
    currencyCode: 'CHF',
    currencyName: 'Swiss Franc',
    currencySymbol: 'CHf',
    label: 'Liechtenstein',
    locale: 'de-LI',
  },
  {
    code: 'LT',
    currencyCode: 'EUR',
    currencyName: 'Lithuanian Litas',
    currencySymbol: '€',
    label: 'Lithuania',
    locale: 'lt-LT',
  },
  {
    code: 'LU',
    currencyCode: 'EUR',
    currencyName: 'Euro',
    currencySymbol: '€',
    label: 'Luxembourg',
    locale: 'de-LU',
  },
  {
    code: 'MO',
    currencyCode: 'MOP',
    currencyName: 'Macanese pataca',
    currencySymbol: '$',
    label: 'Macao',
    locale: 'zh-MO',
  },
  {
    code: 'MK',
    currencyCode: 'MKD',
    currencyName: 'Macedonian denar',
    currencySymbol: 'ден',
    label: 'Macedonia, the Former Yugoslav Republic of',
    locale: 'mk-MK',
  },
  {
    code: 'MG',
    currencyCode: 'MGA',
    currencyName: 'Malagasy Franc',
    currencySymbol: 'Ar',
    label: 'Madagascar',
    locale: 'en-US',
  },
  {
    code: 'MW',
    currencyCode: 'MWK',
    currencyName: 'Malawi Kwacha',
    currencySymbol: 'MK',
    label: 'Malawi',
    locale: 'en-US',
  },
  {
    code: 'MY',
    currencyCode: 'MYR',
    currencyName: 'Malaysian Ringgit',
    currencySymbol: 'RM',
    label: 'Malaysia',
    locale: 'en-MY',
  },
  {
    code: 'MV',
    currencyCode: 'MVR',
    currencyName: 'Maldive Rufiyaa',
    currencySymbol: 'Rf',
    label: 'Maldives',
    locale: 'dv-MV',
  },
  {
    code: 'ML',
    currencyCode: 'XOF',
    currencyName: 'CFA Franc BCEAO',
    currencySymbol: 'CFA',
    label: 'Mali',
    locale: 'en-US',
  },
  {
    code: 'MT',
    currencyCode: 'EUR',
    currencyName: 'Euro',
    currencySymbol: '€',
    label: 'Malta',
    locale: 'en-MT',
  },
  {
    code: 'MH',
    currencyCode: 'USD',
    currencyName: 'US Dollar',
    currencySymbol: '$',
    label: 'Marshall Islands',
    locale: 'en-US',
  },
  {
    code: 'MQ',
    currencyCode: 'EUR',
    currencyName: 'Euro',
    currencySymbol: '€',
    label: 'Martinique',
    locale: 'en-US',
  },
  {
    code: 'MR',
    currencyCode: 'MRO',
    currencyName: 'Mauritanian Ouguiya',
    currencySymbol: 'MRU',
    label: 'Mauritania',
    locale: 'en-US',
  },
  {
    code: 'MU',
    currencyCode: 'MUR',
    currencyName: 'Mauritius Rupee',
    currencySymbol: '₨',
    label: 'Mauritius',
    locale: 'en-US',
  },
  {
    code: 'YT',
    currencyCode: 'EUR',
    currencyName: 'Euro',
    currencySymbol: '€',
    label: 'Mayotte',
    locale: 'en-US',
  },
  {
    code: 'MX',
    currencyCode: 'MXN',
    currencyName: 'Mexican Nuevo Peso',
    currencySymbol: '$',
    label: 'Mexico',
    locale: 'es-MX',
  },
  {
    code: 'FM',
    currencyCode: 'USD',
    currencyName: 'United States Dollar',
    currencySymbol: '$',
    label: 'Micronesia, Federated States of',
    locale: 'en-US',
  },
  {
    code: 'MD',
    currencyCode: 'MDL',
    currencyName: 'Moldovan leu',
    currencySymbol: 'L',
    label: 'Moldova, Republic of',
    locale: 'en-US',
  },
  {
    code: 'MC',
    currencyCode: 'EUR',
    currencyName: 'Euro',
    currencySymbol: '€',
    label: 'Monaco',
    locale: 'fr-MC',
  },
  {
    code: 'MN',
    currencyCode: 'MNT',
    currencyName: 'Mongolian Tugrik',
    currencySymbol: '₮',
    label: 'Mongolia',
    locale: 'mn-MN',
  },
  {
    code: 'ME',
    currencyCode: 'EUR',
    currencyName: 'Euro',
    currencySymbol: '€',
    label: 'Montenegro',
    locale: 'sr-ME',
  },
  {
    code: 'MS',
    currencyCode: 'XCD',
    currencyName: 'East Caribbean Dollar',
    currencySymbol: '$',
    label: 'Montserrat',
    locale: 'en-US',
  },
  {
    code: 'MA',
    currencyCode: 'MAD',
    currencyName: 'Moroccan Dirham',
    currencySymbol: 'DH',
    label: 'Morocco',
    locale: 'ar-MA',
  },
  {
    code: 'MZ',
    currencyCode: 'MZN',
    currencyName: 'Mozambique Metical',
    currencySymbol: 'MT',
    label: 'Mozambique',
    locale: 'en-US',
  },
  {
    code: 'MM',
    currencyCode: 'MMK',
    currencyName: 'Myanmar Kyat',
    currencySymbol: 'K',
    label: 'Myanmar',
    locale: 'en-US',
  },
  {
    code: 'NA',
    currencyCode: 'NAD',
    currencyName: 'Namibian Dollar',
    currencySymbol: '$',
    label: 'Namibia',
    locale: 'en-US',
  },
  {
    code: 'NR',
    currencyCode: 'AUD',
    currencyName: 'Australian Dollar',
    currencySymbol: '$',
    label: 'Nauru',
    locale: 'en-US',
  },
  {
    code: 'NP',
    currencyCode: 'NPR',
    currencyName: 'Nepalese Rupee',
    currencySymbol: '₨',
    label: 'Nepal',
    locale: 'ne-NP',
  },
  {
    code: 'NL',
    currencyCode: 'EUR',
    currencyName: 'Euro',
    currencySymbol: '€',
    label: 'Netherlands',
    locale: 'fy-NL',
  },
  {
    code: 'AN',
    currencyCode: 'ANG',
    currencyName: 'Netherlands Antillean Guilder',
    currencySymbol: 'NAf',
    label: 'Netherlands Antilles',
    locale: 'en-US',
  },
  {
    code: 'NC',
    currencyCode: 'XPF',
    currencyName: 'New Caledonian franc',
    currencySymbol: '₣',
    label: 'New Caledonia',
    locale: 'en-US',
  },
  {
    code: 'NZ',
    currencyCode: 'NZD',
    currencyName: 'New Zealand Dollar',
    currencySymbol: '$',
    label: 'New Zealand',
    locale: 'en-NZ',
  },
  {
    code: 'NI',
    currencyCode: 'NIO',
    currencyName: 'Nicaraguan Cordoba Oro',
    currencySymbol: 'C$',
    label: 'Nicaragua',
    locale: 'es-NI',
  },
  {
    code: 'NE',
    currencyCode: 'XOF',
    currencyName: 'CFA Franc BCEAO',
    currencySymbol: 'CFA',
    label: 'Niger',
    locale: 'en-US',
  },
  {
    code: 'NG',
    currencyCode: 'NGN',
    currencyName: 'Nigerian Naira',
    currencySymbol: '₦',
    label: 'Nigeria',
    locale: 'ha-NG',
  },
  {
    code: 'NU',
    currencyCode: 'NZD',
    currencyName: 'New Zealand Dollar',
    currencySymbol: '$',
    label: 'Niue',
    locale: 'en-US',
  },
  {
    code: 'NF',
    currencyCode: 'AUD',
    currencyName: 'Australian Dollar',
    currencySymbol: '$',
    label: 'Norfolk Island',
    locale: 'en-US',
  },
  {
    code: 'MP',
    currencyCode: 'USD',
    currencyName: 'US Dollar',
    currencySymbol: '$',
    label: 'Northern Mariana Islands',
    locale: 'en-US',
  },
  {
    code: 'NO',
    currencyCode: 'NOK',
    currencyName: 'Norwegian Krone',
    currencySymbol: 'kr',
    label: 'Norway',
    locale: 'nb-NO',
  },
  {
    code: 'OM',
    currencyCode: 'OMR',
    currencyName: 'Omani Rial',
    currencySymbol: '.ع.ر',
    label: 'Oman',
    locale: 'ar-OM',
  },
  {
    code: 'PK',
    currencyCode: 'PKR',
    currencyName: 'Pakistan Rupee',
    currencySymbol: '₨',
    label: 'Pakistan',
    locale: 'ur-PK',
  },
  {
    code: 'PW',
    currencyCode: 'USD',
    currencyName: 'US Dollar',
    currencySymbol: '$',
    label: 'Palau',
    locale: 'en-US',
  },
  {
    code: 'PS',
    currencyCode: 'ILS',
    currencyName: 'Israeli Shekel',
    currencySymbol: '₪',
    label: 'Palestinian Territory, Occupied',
    locale: 'en-US',
  },
  {
    code: 'PA',
    currencyCode: 'PAB',
    currencyName: 'Panamanian Balboa',
    currencySymbol: 'B/.',
    label: 'Panama',
    locale: 'es-PA',
  },
  {
    code: 'PG',
    currencyCode: 'PGK',
    currencyName: 'Papua New Guinea Kina',
    currencySymbol: 'K',
    label: 'Papua New Guinea',
    locale: 'en-US',
  },
  {
    code: 'PY',
    currencyCode: 'PYG',
    currencyName: 'Paraguay Guarani',
    currencySymbol: '₲',
    label: 'Paraguay',
    locale: 'es-PY',
  },
  {
    code: 'PE',
    currencyCode: 'PEN',
    currencyName: 'Peruvian Nuevo Sol',
    currencySymbol: 'S/.',
    label: 'Peru',
    locale: 'es-PE',
  },
  {
    code: 'PH',
    currencyCode: 'PHP',
    currencyName: 'Philippine Peso',
    currencySymbol: '₱',
    label: 'Philippines',
    locale: 'en-PH',
  },
  {
    code: 'PN',
    currencyCode: 'NZD',
    currencyName: 'New Zealand dollar',
    currencySymbol: '$',
    label: 'Pitcairn',
    locale: 'en-US',
  },
  {
    code: 'PL',
    currencyCode: 'PLN',
    currencyName: 'Polish Zloty',
    currencySymbol: 'zł',
    label: 'Poland',
    locale: 'pl-PL',
  },
  {
    code: 'PT',
    currencyCode: 'EUR',
    currencyName: 'Euro',
    currencySymbol: '€',
    label: 'Portugal',
    locale: 'pt-PT',
  },
  {
    code: 'PR',
    currencyCode: 'USD',
    currencyName: 'US Dollar',
    currencySymbol: '$',
    label: 'Puerto Rico',
    locale: 'es-PR',
  },
  {
    code: 'QA',
    currencyCode: 'QAR',
    currencyName: 'Qatari Rial',
    currencySymbol: 'ق.ر',
    label: 'Qatar',
    locale: 'ar-QA',
  },
  {
    code: 'RE',
    currencyCode: 'EUR',
    currencyName: 'Euro',
    currencySymbol: '€',
    label: 'Reunion',
    locale: 'en-US',
  },
  {
    code: 'RO',
    currencyCode: 'RON',
    currencyName: 'Romanian New Leu',
    currencySymbol: 'lei',
    label: 'Romania',
    locale: 'ro-RO',
  },
  {
    code: 'RU',
    currencyCode: 'RUB',
    currencyName: 'Russian ruble',
    currencySymbol: '₽',
    label: 'Russian Federation',
    locale: 'ba-RU',
  },
  {
    code: 'RW',
    currencyCode: 'RWF',
    currencyName: 'Rwanda Franc',
    currencySymbol: 'FRw',
    label: 'Rwanda',
    locale: 'rw-RW',
  },
  {
    code: 'BL',
    currencyCode: 'EUR',
    currencyName: 'Euro',
    currencySymbol: '€',
    label: 'Saint Barthelemy',
    locale: 'en-US',
  },
  {
    code: 'SH',
    currencyCode: 'SHP',
    currencyName: 'St. Helena Pound',
    currencySymbol: '£',
    label: 'Saint Helena',
    locale: 'en-US',
  },
  {
    code: 'KN',
    currencyCode: 'XCD',
    currencyName: 'Eastern Caribbean dollar',
    currencySymbol: '$',
    label: 'Saint Kitts and Nevis',
    locale: 'en-US',
  },
  {
    code: 'LC',
    currencyCode: 'XCD',
    currencyName: 'East Caribbean Dollar',
    currencySymbol: '$',
    label: 'Saint Lucia',
    locale: 'en-US',
  },
  {
    code: 'MF',
    currencyCode: 'EUR',
    currencyName: 'Euro',
    currencySymbol: '€',
    label: 'Saint Martin',
    locale: 'en-US',
  },
  {
    code: 'PM',
    currencyCode: 'EUR',
    currencyName: ' Euro',
    currencySymbol: '€',
    label: 'Saint Pierre and Miquelon',
    locale: 'en-US',
  },
  {
    code: 'VC',
    currencyCode: 'XCD',
    currencyName: 'East Caribbean Dollar',
    currencySymbol: '$',
    label: 'Saint Vincent and the Grenadines',
    locale: 'en-US',
  },
  {
    code: 'WS',
    currencyCode: 'WST',
    currencyName: 'Samoan Tala',
    currencySymbol: 'SAT',
    label: 'Samoa',
    locale: 'en-US',
  },
  {
    code: 'SM',
    currencyCode: 'EUR',
    currencyName: 'Euro',
    currencySymbol: '€',
    label: 'San Marino',
    locale: 'en-US',
  },
  {
    code: 'ST',
    currencyCode: 'STD',
    currencyName: ' Dobra',
    currencySymbol: 'Db',
    label: 'Sao Tome and Principe',
    locale: 'en-US',
  },
  {
    code: 'SA',
    currencyCode: 'SAR',
    currencyName: 'Saudi Riyal',
    currencySymbol: '﷼',
    label: 'Saudi Arabia',
    locale: 'ar-SA',
  },
  {
    code: 'SN',
    currencyCode: 'XOF',
    currencyName: 'CFA Franc BCEAO',
    currencySymbol: 'CFA',
    label: 'Senegal',
    locale: 'wo-SN',
  },
  {
    code: 'RS',
    currencyCode: 'RSD',
    currencyName: 'Serbian dinar',
    currencySymbol: 'din',
    label: 'Serbia',
    locale: 'sr-RS',
  },
  {
    code: 'SC',
    currencyCode: 'SCR',
    currencyName: 'Seychelles Rupee',
    currencySymbol: 'SRe',
    label: 'Seychelles',
    locale: 'en-US',
  },
  {
    code: 'SL',
    currencyCode: 'SLL',
    currencyName: 'Sierra Leone Leone',
    currencySymbol: 'Le',
    label: 'Sierra Leone',
    locale: 'en-US',
  },
  {
    code: 'SG',
    currencyCode: 'SGD',
    currencyName: 'Singapore Dollar',
    currencySymbol: '$',
    label: 'Singapore',
    locale: 'en-SG',
  },
  {
    code: 'SX',
    currencyCode: 'ANG',
    currencyName: 'Netherlands Antillean guilder',
    currencySymbol: 'ƒ',
    label: 'Sint Maarten',
    locale: 'en-US',
  },
  {
    code: 'SK',
    currencyCode: 'EUR',
    currencyName: 'Euro',
    currencySymbol: '€',
    label: 'Slovakia',
    locale: 'sk-SK',
  },
  {
    code: 'SI',
    currencyCode: 'EUR',
    currencyName: 'Euro',
    currencySymbol: '€',
    label: 'Slovenia',
    locale: 'sl-SI',
  },
  {
    code: 'SB',
    currencyCode: 'SBD',
    currencyName: 'Solomon Islands Dollar',
    currencySymbol: 'Si$',
    label: 'Solomon Islands',
    locale: 'en-US',
  },
  {
    code: 'SO',
    currencyCode: 'SOS',
    currencyName: 'Somali Shilling',
    currencySymbol: 'Sh.so.',
    label: 'Somalia',
    locale: 'en-US',
  },
  {
    code: 'ZA',
    currencyCode: 'ZAR',
    currencyName: 'South African Rand',
    currencySymbol: 'R',
    label: 'South Africa',
    locale: 'af-ZA',
  },
  {
    code: 'GS',
    currencyCode: 'GBP',
    currencyName: 'Pound Sterling',
    currencySymbol: '£',
    label: 'South Georgia and the South Sandwich Islands',
    locale: 'en-US',
  },
  {
    code: 'SS',
    currencyCode: 'SSP',
    currencyName: 'South Sudan Pound',
    currencySymbol: '£',
    label: 'South Sudan',
    locale: 'en-US',
  },
  {
    code: 'ES',
    currencyCode: 'EUR',
    currencyName: 'Euro',
    currencySymbol: '€',
    label: 'Spain',
    locale: 'ca-ES',
  },
  {
    code: 'LK',
    currencyCode: 'LKR',
    currencyName: 'Sri Lankan rupee',
    currencySymbol: 'Rs',
    label: 'Sri Lanka',
    locale: 'si-LK',
  },
  {
    code: 'SD',
    currencyCode: 'SDG',
    currencyName: 'Sudanese Pound',
    currencySymbol: '.س.ج',
    label: 'Sudan',
    locale: 'ar-SD',
  },
  {
    code: 'SR',
    currencyCode: 'SRD',
    currencyName: 'Surinamese dollar',
    currencySymbol: '$',
    label: 'Surilabel',
    locale: 'en-US',
  },
  {
    code: 'SJ',
    currencyCode: 'NOK',
    currencyName: 'Norwegian krone',
    currencySymbol: 'kr',
    label: 'Svalbard and Jan Mayen',
    locale: 'en-US',
  },
  {
    code: 'SZ',
    currencyCode: 'SZL',
    currencyName: 'Swaziland Lilangeni',
    currencySymbol: 'E',
    label: 'Swaziland',
    locale: 'en-US',
  },
  {
    code: 'SE',
    currencyCode: 'SEK',
    currencyName: 'Swedish Krona',
    currencySymbol: 'kr',
    label: 'Sweden',
    locale: 'se-SE',
  },
  {
    code: 'CH',
    currencyCode: 'CHF',
    currencyName: 'Swiss Franc',
    currencySymbol: 'CHf',
    label: 'Switzerland',
    locale: 'de-CH',
  },
  {
    code: 'SY',
    currencyCode: 'SYP',
    currencyName: 'Syrian pound',
    currencySymbol: 'LS',
    label: 'Syrian Arab Republic',
    locale: 'ar-SY',
  },
  {
    code: 'TW',
    currencyCode: 'TWD',
    currencyName: 'Taiwan Dollar',
    currencySymbol: '$',
    label: 'Taiwan, Province of China',
    locale: 'zh-TW',
  },
  {
    code: 'TJ',
    currencyCode: 'TJS',
    currencyName: 'Tajik Somoni',
    currencySymbol: 'SM',
    label: 'Tajikistan',
    locale: 'tg-TJ',
  },
  {
    code: 'TZ',
    currencyCode: 'TZS',
    currencyName: 'Tanzanian shilling',
    currencySymbol: 'TSh',
    label: 'Tanzania, United Republic of',
    locale: 'en-US',
  },
  {
    code: 'TH',
    currencyCode: 'THB',
    currencyName: 'Thai Baht',
    currencySymbol: '฿',
    label: 'Thailand',
    locale: 'th-TH',
  },
  {
    code: 'TL',
    currencyCode: 'USD',
    currencyName: 'United States Dollar',
    currencySymbol: '$',
    label: 'Timor-Leste',
    locale: 'en-US',
  },
  {
    code: 'TG',
    currencyCode: 'XOF',
    currencyName: 'CFA Franc BCEAO',
    currencySymbol: 'CFA',
    label: 'Togo',
    locale: 'en-US',
  },
  {
    code: 'TK',
    currencyCode: 'NZD',
    currencyName: 'New Zealand Dollar',
    currencySymbol: '$',
    label: 'Tokelau',
    locale: 'en-US',
  },
  {
    code: 'TO',
    currencyCode: 'TOP',
    currencyName: "Tongan Pa'anga",
    currencySymbol: '$',
    label: 'Tonga',
    locale: 'en-US',
  },
  {
    code: 'TT',
    currencyCode: 'TTD',
    currencyName: 'Trinidad and Tobago Dollar',
    currencySymbol: '$',
    label: 'Trinidad and Tobago',
    locale: 'en-TT',
  },
  {
    code: 'TN',
    currencyCode: 'TND',
    currencyName: 'Tunisian Dollar',
    currencySymbol: 'ت.د',
    label: 'Tunisia',
    locale: 'ar-TN',
  },
  {
    code: 'TR',
    currencyCode: 'TRY',
    currencyName: 'Turkish Lira',
    currencySymbol: '₺',
    label: 'Turkey',
    locale: 'tr-TR',
  },
  {
    code: 'TM',
    currencyCode: 'TMT',
    currencyName: 'Manat',
    currencySymbol: 'T',
    label: 'Turkmenistan',
    locale: 'tk-TM',
  },
  {
    code: 'TC',
    currencyCode: 'USD',
    currencyName: 'United States Dollar',
    currencySymbol: '$',
    label: 'Turks and Caicos Islands',
    locale: 'en-US',
  },
  {
    code: 'TV',
    currencyCode: 'AUD',
    currencyName: 'Australian Dollar',
    currencySymbol: '$',
    label: 'Tuvalu',
    locale: 'en-US',
  },
  {
    code: 'UG',
    currencyCode: 'UGX',
    currencyName: 'Uganda Shilling',
    currencySymbol: 'USh',
    label: 'Uganda',
    locale: 'en-US',
  },
  {
    code: 'UA',
    currencyCode: 'UAH',
    currencyName: 'Ukraine Hryvnia',
    currencySymbol: '₴',
    label: 'Ukraine',
    locale: 'uk-UA',
  },
  {
    code: 'AE',
    currencyCode: 'AED',
    currencyName: 'Arab Emirates Dirham',
    currencySymbol: 'إ.د',
    label: 'United Arab Emirates',
    locale: 'ar-AE',
  },
  {
    code: 'GB',
    currencyCode: 'GBP',
    currencyName: 'Pound sterling',
    currencySymbol: '£',
    label: 'United Kingdom',
    locale: 'en-GB',
  },
  {
    code: 'UM',
    currencyCode: 'USD',
    currencyName: 'United States Dollar',
    currencySymbol: '$',
    label: 'United States Minor Outlying Islands',
    locale: 'en-US',
  },
  {
    code: 'UY',
    currencyCode: 'UYU',
    currencyName: 'Uruguayan Peso',
    currencySymbol: '$',
    label: 'Uruguay',
    locale: 'es-UY',
  },
  {
    code: 'UZ',
    currencyCode: 'UZS',
    currencyName: 'Uzbekistan Sum',
    currencySymbol: 'лв',
    label: 'Uzbekistan',
    locale: 'uz-UZ',
  },
  {
    code: 'VU',
    currencyCode: 'VUV',
    currencyName: 'Vanuatu Vatu',
    currencySymbol: 'VT',
    label: 'Vanuatu',
    locale: 'en-US',
  },
  {
    code: 'VE',
    currencyCode: 'VEF',
    currencyName: 'Venezuelan Bolivar',
    currencySymbol: 'Bs',
    label: 'Venezuela',
    locale: 'es-VE',
  },
  {
    code: 'VN',
    currencyCode: 'VND',
    currencyName: '  Vietnamese dong',
    currencySymbol: '₫',
    label: 'Viet Nam',
    locale: 'vi-VN',
  },
  {
    code: 'VG',
    currencyCode: 'USD',
    currencyName: 'United States Dollar',
    currencySymbol: '$',
    label: 'Virgin Islands, British',
    locale: 'en-US',
  },
  {
    code: 'VI',
    currencyCode: 'USD',
    currencyName: 'United States Dollar',
    currencySymbol: '$',
    label: 'Virgin Islands, U.s.',
    locale: 'en-US',
  },
  {
    code: 'WF',
    currencyCode: 'XPF',
    currencyName: 'CFP franc',
    currencySymbol: '₣',
    label: 'Wallis and Futuna',
    locale: 'en-US',
  },
  {
    code: 'EH',
    currencyCode: 'MAD',
    currencyName: 'Moroccan Dirham',
    currencySymbol: 'MAD',
    label: 'Western Sahara',
    locale: 'en-US',
  },
  {
    code: 'YE',
    currencyCode: 'YER',
    currencyName: 'Yemeni Rial',
    currencySymbol: '﷼',
    label: 'Yemen',
    locale: 'ar-YE',
  },
  {
    code: 'ZM',
    currencyCode: 'ZMW',
    currencyName: 'Zambian Kwacha',
    currencySymbol: 'ZK',
    label: 'Zambia',
    locale: 'en-US',
  },
  {
    code: 'ZW',
    currencyCode: 'ZWL',
    currencyName: 'Zimbabwe Dollar',
    currencySymbol: '$',
    label: 'Zimbabwe',
    locale: 'en-ZW',
  },
];
