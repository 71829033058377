import { PurchaseLabelExtra, PurchaseLabel, InternationalData } from './types';

export const initialPurchaseLabelExtra: PurchaseLabelExtra = {
  billingType: null,
  billingCarrierCode: null,
  billingAccount: null,
  billingCountry: null,
  billingZip: null,
  billingParticipationCode: null,
  packagingCarrier: null,
  packagingCode: null,
  referenceField1: null,
  referenceField2: null,
  recipientPhone: null,
  recipientEmail: null,
  labelFileType: null,
  useRetailRates: false,
  saturdayDelivery: false,
  useInsurance: false,
  containsDryIce: false,
  dryIceWeight: null,
  dryIceWeightUnit: 'Pounds',
  containsAlcohol: false,
  recipientType: null,
  cod: false,
  codMethod: null,
  codAmount: null,
  codCurrency: null,
  signatureConfirmation: false,
  signatureType: null,
  carrierCode: null,
  serviceCode: null,
  purchaseReturnLabel: false,
};

export const initialInternationalData: InternationalData = {
  nonDeliveryOption: null,
  contentsType: null,
  contentsTypeExplanation: null,
  eelPfc: null,
  incoterms: null,
  disclaimer: null,
  certificate: null,
  exporterReference: null,
  importerReference: null,
  invoice: null,
  shipperEmail: null,
  phoneNumber: null,
  notes: null,
  consent: false,
  certifierName: null,
  b13AFilingOption: null,
  b13ANumber: null,
  taxIdType: null,
  taxIdNumber: null,
};

export const initialPurchaseLabel: PurchaseLabel = {
  declarationItems: [],
  internationalData: initialInternationalData,
  purchaseLabelExtra: initialPurchaseLabelExtra,
};
