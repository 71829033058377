import { ReceiptItemReceive } from 'services/receiving/types';

export const checkTracking = (row: ReceiptItemReceive) => {
  if (row.trackingGroups.length > 0) {
    const hasValidTracking = row.trackingGroups.some(
      (tg) => tg.quantity !== null && tg.quantity > 0
    );
    return hasValidTracking;
  }
  return true;
};
