import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { colorPalette } from 'ui/theme';

import { RowDescriptor } from '../../types';

const BASE_ROW_HEIGHT = 56;
const TEXT_BOX_ROW_HEIGHT = 128;
const IMG_ROW_HEIGHT = 88;

export const useCardRowStyle = makeStyles<Theme, RowDescriptor>(
  (theme: Theme) => {
    const calculateTextRowHeight = (props: RowDescriptor) => {
      if (props.textbox) {
        return TEXT_BOX_ROW_HEIGHT;
      } else {
        return BASE_ROW_HEIGHT;
      }
    };
    return {
      row: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: BASE_ROW_HEIGHT,
        height: BASE_ROW_HEIGHT,
        padding: theme.spacing(2),
      },
      imgRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: IMG_ROW_HEIGHT,
        padding: theme.spacing(2),
      },
      textRow: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        height: (props) => calculateTextRowHeight(props),
        minHeight: (props) => calculateTextRowHeight(props),
        padding: theme.spacing(2),
      },
      textContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      title: {
        width: '60%',
      },
      content: {
        width: '40%',
        textAlign: 'right',
      },
      contentRestyle: {
        width: '40%',
        display: 'flex',
        flexDirection: 'row-reverse',
      },
      link: {
        color: colorPalette.fishbowlCorporateLightBlue,
        width: '40%',
        textAlign: 'right',
        ...theme.typography.body1,
      },
      button: {
        width: 221,
      },
      logo: {
        height: 72,
        objectFit: 'contain',
      },
      input: {
        display: 'none',
      },
      error: {
        borderColor: 'red',
      },
    };
  }
);
