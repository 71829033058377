import { styled } from 'ui/theme';
import { makeStyles } from '@mui/styles';

import {
  ItemsTableContainerProps,
  ItemsTableFooterProps,
  ItemsTableStyleProps,
} from './types';
import { Theme } from '@mui/material';

const DEFAULT_FOOTER_HEIGHT = 56;

export const useTableStyles = makeStyles<Theme, ItemsTableStyleProps>(() => ({
  tableStyle: {
    width: '100%',
  },
  emptyTable: {
    height: ({ containerHeight }) =>
      containerHeight ? `${containerHeight}px` : '100%',
  },
  layoutFixed: {
    tableLayout: 'fixed',
  },
}));

export const FooterContainer = styled.div<ItemsTableFooterProps>`
  position: absolute;
  right: 0;
  bottom: 0;
  border-top: 1px solid #ebebeb;
  width: 100%;
  height: ${(props) =>
    !props.customFooterHeight
      ? `${DEFAULT_FOOTER_HEIGHT}px`
      : `${props.customFooterHeight}px`};
  overflow: hidden;
`;

export const ItemsTableContainer = styled.div<ItemsTableContainerProps>`
  position: relative;
  padding-bottom: ${(props) =>
    !props.isFooterVisible
      ? '0 px'
      : props.customFooterHeight
      ? `${props.customFooterHeight}px`
      : `${DEFAULT_FOOTER_HEIGHT}px`};

  display: flex;
  flex-grow: 1;
  height: ${(props) =>
    props.containerHeight ? `${props.containerHeight}px` : '100%'};
  overflow: hidden;
`;
