import React, { memo, useCallback, useMemo } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import _ from 'lodash';

import { TextField } from 'ui/components/TextField/TextField';
import {
  COUNTRIES,
  CountryAutocomplete,
  CountryType,
} from 'ui/components/Autocomplete/CountryAutocomplete';
import {
  StateAutocomplete,
  STATES,
  StateType,
} from 'ui/components/Autocomplete/StateAutocomplete';

import { ShipTabPropsWithErrors } from './types';
import { useCardRowStyle } from '../styled';

const ShipAddressTab = (props: ShipTabPropsWithErrors) => {
  const { shippingQuote, setShippingQuote, errors } = props;

  const classes = useCardRowStyle();

  const shipToCountry = useMemo(() => {
    const newCountry =
      COUNTRIES.find((c) => c.code === shippingQuote.shipToAddress.country) ||
      null;

    return newCountry;
  }, [shippingQuote.shipToAddress.country]);

  const shipFromCountry = useMemo(() => {
    const newCountry =
      COUNTRIES.find((c) => c.code === shippingQuote.shipFromAddress.country) ||
      null;

    return newCountry;
  }, [shippingQuote.shipFromAddress.country]);

  const shipToState = useMemo(() => {
    const newState =
      STATES.find(
        (s) => s.abbreviation === shippingQuote.shipToAddress.state
      ) || null;

    return newState;
  }, [shippingQuote.shipToAddress.state]);

  const shipFromState = useMemo(() => {
    const newState =
      STATES.find(
        (s) => s.abbreviation === shippingQuote.shipFromAddress.state
      ) || null;

    return newState;
  }, [shippingQuote.shipFromAddress.state]);

  const handleShipFromAddressChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setShippingQuote((old) => ({
        ...old,
        shipFromAddress: {
          ...shippingQuote.shipFromAddress,
          [e.target.name]: value,
        },
      }));
    },
    [setShippingQuote, shippingQuote.shipFromAddress]
  );

  const handleShipToAddressChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setShippingQuote((old) => ({
        ...old,
        shipToAddress: {
          ...shippingQuote.shipToAddress,
          [e.target.name]: value,
        },
      }));
    },
    [setShippingQuote, shippingQuote.shipToAddress]
  );

  const handleShipFromCountryChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, value: CountryType | null) => {
      setShippingQuote((old) => ({
        ...old,
        shipFromAddress: {
          ...shippingQuote.shipFromAddress,
          country: _.get(value, 'code', ''),
        },
      }));
    },
    [setShippingQuote, shippingQuote.shipFromAddress]
  );

  const handleShipToCountryChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, value: CountryType | null) => {
      setShippingQuote((old) => ({
        ...old,
        shipToAddress: {
          ...shippingQuote.shipToAddress,
          country: _.get(value, 'code', ''),
        },
      }));
    },
    [setShippingQuote, shippingQuote.shipToAddress]
  );

  const handleShipFromStateChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, value: StateType | null) => {
      setShippingQuote((old) => ({
        ...old,
        shipFromAddress: {
          ...shippingQuote.shipFromAddress,
          state: _.get(value, 'abbreviation', ''),
        },
      }));
    },
    [setShippingQuote, shippingQuote.shipFromAddress]
  );

  const handleShipToStateChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, value: StateType | null) => {
      setShippingQuote((old) => ({
        ...old,
        shipToAddress: {
          ...shippingQuote.shipToAddress,
          state: _.get(value, 'abbreviation', ''),
        },
      }));
    },
    [setShippingQuote, shippingQuote.shipToAddress]
  );

  return (
    <Box p={4} width="100%" className={classes.tabPanelBox}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box mb={2}>
            <Typography align="center">
              <b>Ship From Address</b>
            </Typography>
          </Box>
          <Box mb={2}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Name"
              placeholder="Enter name"
              name="name"
              autoComplete="nope"
              fullWidth
              value={shippingQuote.shipFromAddress.name}
              onChange={handleShipFromAddressChange}
              error={!!errors['shipFromAddress.name']}
            />
          </Box>
          <Box mb={2}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Company Name"
              placeholder="Enter company name"
              name="companyName"
              autoComplete="nope"
              fullWidth
              value={shippingQuote.shipFromAddress.companyName}
              onChange={handleShipFromAddressChange}
            />
          </Box>
          <Box mb={2}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Attention"
              placeholder="Enter Attention"
              name="attention"
              autoComplete="nope"
              fullWidth
              value={shippingQuote.shipFromAddress.attention}
              onChange={handleShipFromAddressChange}
            />
          </Box>
          <Box mb={2}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Street"
              placeholder="Enter street name"
              name="street"
              autoComplete="nope"
              fullWidth
              value={shippingQuote.shipFromAddress.street}
              onChange={handleShipFromAddressChange}
              error={!!errors['shipFromAddress.street']}
            />
          </Box>
          <Box mb={2}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Street 2"
              placeholder="Enter street name"
              name="street2"
              autoComplete="nope"
              fullWidth
              value={shippingQuote.shipFromAddress.street2}
              onChange={handleShipFromAddressChange}
              error={!!errors['shipFromAddress.street']}
            />
          </Box>
          <Box mb={2}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="City"
              placeholder="Enter city name"
              name="city"
              autoComplete="nope"
              fullWidth
              value={shippingQuote.shipFromAddress.city}
              onChange={handleShipFromAddressChange}
              error={!!errors['shipFromAddress.city']}
            />
          </Box>
          <Box mb={2}>
            {shippingQuote.shipFromAddress.country === 'US' ? (
              <StateAutocomplete
                value={shipFromState}
                onChange={handleShipFromStateChange}
                error={!!errors['shipFromAddress.state']}
              />
            ) : (
              <TextField
                className="redesign"
                variant="standard"
                type="text"
                label="State"
                placeholder="Enter state name"
                name="state"
                autoComplete="nope"
                fullWidth
                value={shippingQuote.shipFromAddress.state}
                onChange={handleShipFromAddressChange}
                error={!!errors['shipFromAddress.state']}
              />
            )}
          </Box>
          <Box mb={2}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="ZIP"
              placeholder="Enter ZIP code"
              name="postalCode"
              autoComplete="nope"
              fullWidth
              value={shippingQuote.shipFromAddress.postalCode}
              onChange={handleShipFromAddressChange}
              error={!!errors['shipFromAddress.postalCode']}
            />
          </Box>
          <Box mb={2}>
            <CountryAutocomplete
              value={shipFromCountry}
              onChange={handleShipFromCountryChange}
              error={!!errors['shipFromAddress.country']}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box mb={2}>
            <Typography align="center">
              <b>Ship To Address</b>
            </Typography>
          </Box>
          <Box mb={2}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Name"
              placeholder="Enter name"
              name="name"
              autoComplete="nope"
              fullWidth
              value={shippingQuote.shipToAddress.name}
              onChange={handleShipToAddressChange}
              error={!!errors['shipToAddress.name']}
            />
          </Box>
          <Box mb={2}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Company Name"
              placeholder="Enter company name"
              name="companyName"
              autoComplete="nope"
              fullWidth
              value={shippingQuote.shipToAddress.companyName}
              onChange={handleShipToAddressChange}
            />
          </Box>
          <Box mb={2}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Attention"
              placeholder="Enter Attention"
              name="attention"
              autoComplete="nope"
              fullWidth
              value={shippingQuote.shipToAddress.attention}
              onChange={handleShipToAddressChange}
            />
          </Box>
          <Box mb={2}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Street"
              placeholder="Enter street name"
              name="street"
              autoComplete="nope"
              fullWidth
              value={shippingQuote.shipToAddress.street}
              onChange={handleShipToAddressChange}
              error={!!errors['shipToAddress.street']}
            />
          </Box>
          <Box mb={2}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Street 2"
              placeholder="Enter street name"
              name="street2"
              autoComplete="nope"
              fullWidth
              value={shippingQuote.shipToAddress.street2}
              onChange={handleShipToAddressChange}
              error={!!errors['shipToAddress.street']}
            />
          </Box>
          <Box mb={2}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="City"
              placeholder="Enter city name"
              name="city"
              autoComplete="nope"
              fullWidth
              value={shippingQuote.shipToAddress.city}
              onChange={handleShipToAddressChange}
              error={!!errors['shipToAddress.city']}
            />
          </Box>
          <Box mb={2}>
            {shippingQuote.shipToAddress.country === 'US' ? (
              <StateAutocomplete
                value={shipToState}
                onChange={handleShipToStateChange}
                error={!!errors['shipToAddress.state']}
              />
            ) : (
              <TextField
                className="redesign"
                variant="standard"
                type="text"
                label="State"
                placeholder="Enter state name"
                name="state"
                autoComplete="nope"
                fullWidth
                value={shippingQuote.shipToAddress.state}
                onChange={handleShipToAddressChange}
                error={!!errors['shipToAddress.state']}
              />
            )}
          </Box>
          <Box mb={2}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="ZIP"
              placeholder="Enter ZIP code"
              name="postalCode"
              autoComplete="nope"
              fullWidth
              onChange={handleShipToAddressChange}
              value={shippingQuote.shipToAddress.postalCode}
              error={!!errors['shipToAddress.postalCode']}
            />
          </Box>
          <Box mb={2}>
            <CountryAutocomplete
              value={shipToCountry}
              onChange={handleShipToCountryChange}
              error={!!errors['shipToAddress.country']}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(ShipAddressTab);
