import { toMulticurrencyCalculate } from 'helpers';
import { ReceiptItem, ReceiptItemStatus } from 'services/receiving';

export const resolveReceiptItemUnitCost = (
  receiptItem: ReceiptItem
): number | null => {
  const poItemCost =
    receiptItem.purchaseOrderItem && receiptItem.purchaseOrderItem.unitCost;
  const soItemCost =
    receiptItem.salesOrderItem && receiptItem.salesOrderItem.price;

  return poItemCost || soItemCost;
};

const resolveReceiptItemUnitMulticurrencyCost = (
  receiptItem: ReceiptItem
): number | null => {
  const poItemCost =
    receiptItem.purchaseOrderItem &&
    receiptItem.purchaseOrderItem.multiCurrencyUnitCost;
  const soItemCost =
    receiptItem.salesOrderItem &&
    receiptItem.salesOrderItem.multiCurrencyItemPrice;

  return poItemCost || soItemCost;
};

export const calculateReceiptItemLandedUnitCost = (
  receiptItem: ReceiptItem,
  currencyFormatter: (
    value: number,
    currencyCode?: string | undefined
  ) => string,
  activeMulticurrency: string,
  homeCurrency: string,
  exchangeRate?: number
) => {
  if (activeMulticurrency !== homeCurrency) {
    if (!receiptItem.landedTotalCost || !receiptItem.quantity) {
      return currencyFormatter(0, activeMulticurrency);
    }

    const cost = toMulticurrencyCalculate(
      receiptItem.landedTotalCost / receiptItem.quantity,
      exchangeRate || 1
    );

    return currencyFormatter(cost, activeMulticurrency);
  }
};

export const calculateReceiptItemUnitCost = (
  receiptItem: ReceiptItem,
  currencyFormatter: (
    value: number,
    currencyCode?: string | undefined
  ) => string,
  activeMulticurrency: string,
  homeCurrency: string
) => {
  let cost;
  if (activeMulticurrency !== homeCurrency) {
    cost = resolveReceiptItemUnitMulticurrencyCost(receiptItem);
  }

  if (!cost) {
    return currencyFormatter(0, activeMulticurrency);
  }

  return currencyFormatter(cost, activeMulticurrency);
};

export const calculateReceiptItemCost = (
  receiptItem: ReceiptItem,
  currencyFormatter: (
    value: number,
    currencyCode?: string | undefined
  ) => string,
  activeMulticurrency: string,
  homeCurrency: string
) => {
  let cost;
  if (activeMulticurrency !== homeCurrency) {
    cost = resolveReceiptItemUnitMulticurrencyCost(receiptItem);
  }

  if (!cost || !receiptItem.quantity) {
    return currencyFormatter(0, activeMulticurrency);
  }

  return currencyFormatter(cost * receiptItem.quantity, activeMulticurrency);
};

export const isTrackingIconButtonDisabled = (
  receiptStatus: ReceiptItemStatus | null
) =>
  receiptStatus === ReceiptItemStatus.Open ||
  receiptStatus === ReceiptItemStatus.Reconciled ||
  receiptStatus === ReceiptItemStatus.Hold ||
  receiptStatus === ReceiptItemStatus.Rejected;
