import React, { memo, useCallback, useMemo } from 'react';
import { Box, Typography } from '@mui/material';

import { TextField } from 'ui/components/TextField/TextField';
import {
  ShippoContentsType,
  ShippoDefaultIncotermsType,
  EEL_PFC,
  ShippoNonDeliveryType,
  ShippoTaxIdType,
  shippoReferenceFields,
  ShippoReferenceField,
} from 'services/integrations/shipping/shippo';
import { useHandleTextFieldChange } from 'services/forms';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { useHandleCheckboxChange } from 'services/forms/hooks';

import { useShippingWizardStyle } from '../../../../styled';
import { useInternationalSettings } from '../../../../ShippoProvider';
import FBOSwitch from 'ui/theme/components/FBOSwitch/FBOSwitch';

const InternationalSettingsForm: React.FC = () => {
  const classes = useShippingWizardStyle();

  const { settings, setSettings, errors } = useInternationalSettings();

  const handleTextFieldChange = useHandleTextFieldChange(setSettings, settings);

  const handleCheckboxChange = useHandleCheckboxChange(setSettings);

  const selectedExporterReferenceField = useMemo(() => {
    return (
      shippoReferenceFields.find((s) => s.key === settings.exporterReference) ||
      null
    );
  }, [settings.exporterReference]);

  const selectedImporterReferenceField = useMemo(() => {
    return (
      shippoReferenceFields.find((s) => s.key === settings.importerReference) ||
      null
    );
  }, [settings.importerReference]);

  const handleAutocompleteChange = useCallback(
    (
        name:
          | 'nonDeliveryOption'
          | 'contentsType'
          | 'incoterms'
          | 'eelPfc'
          | 'exporterReference'
          | 'importerReference'
          | 'taxIdType'
      ) =>
      (e: React.ChangeEvent<{}>, v: any) => {
        setSettings((old) => ({ ...old, [name]: v || null }));
      },
    [setSettings]
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex="1"
      height="100%"
      overflow="auto"
      py={1}
    >
      <Box className={classes.wizardRow}>
        <Typography variant="body1" color="textPrimary">
          International Shipping
        </Typography>
        <Box>
          <FBOSwitch
            size="medium"
            checked={settings.enabled}
            onChange={handleCheckboxChange}
            name="enabled"
            dataQa={'international-shipping-toggle'}
          />
        </Box>
      </Box>
      {settings.enabled && (
        <>
          <Box className={classes.wizardRow}>
            <Typography variant="body1" color="textPrimary">
              Non-delivery option
            </Typography>
            <Box width="45%">
              <Autocomplete
                options={Object.values(ShippoNonDeliveryType)}
                value={settings.nonDeliveryOption}
                onChange={handleAutocompleteChange('nonDeliveryOption')}
                placeholder="Non-delivery option"
                error={!!errors.nonDeliveryOption}
              />
            </Box>
          </Box>
          <Box className={classes.wizardRow}>
            <Typography variant="body1" color="textPrimary">
              Contents type
            </Typography>
            <Box width="45%">
              <Autocomplete
                options={Object.values(ShippoContentsType)}
                value={settings.contentsType}
                onChange={handleAutocompleteChange('contentsType')}
                placeholder="Contents type"
                error={!!errors.contentsType}
              />
            </Box>
          </Box>
          <Box className={classes.wizardRow}>
            <Typography variant="body1" color="textPrimary">
              Shipper email address
            </Typography>
            <Box width="45%">
              <TextField
                placeholder="Shipper email address"
                value={settings.shipperEmail}
                name="shipperEmail"
                onChange={handleTextFieldChange}
                error={!!errors.shipperEmail}
                className="redesign"
                variant="standard"
              />
            </Box>
          </Box>
          <Box className={classes.wizardRow}>
            <Typography variant="body1" color="textPrimary">
              Tax ID Type
            </Typography>
            <Box width="45%">
              <Autocomplete
                options={Object.values(ShippoTaxIdType)}
                value={settings.taxIdType}
                onChange={handleAutocompleteChange('taxIdType')}
                placeholder="Tax ID Type"
                error={!!errors.taxIdType}
              />
            </Box>
          </Box>
          <Box className={classes.wizardRow}>
            <Typography variant="body1" color="textPrimary">
              Tax Number
            </Typography>
            <Box width="45%">
              <TextField
                placeholder="Tax Number"
                value={settings.taxIdNumber}
                name="taxIdNumber"
                onChange={handleTextFieldChange}
                error={!!errors.taxIdNumber}
                className="redesign"
                variant="standard"
              />
            </Box>
          </Box>
          <Box className={classes.wizardRow}>
            <Typography variant="body1" color="textPrimary">
              EEL/PFC
            </Typography>
            <Box width="45%">
              <Autocomplete
                options={Object.values(EEL_PFC)}
                value={settings.eelPfc}
                onChange={handleAutocompleteChange('eelPfc')}
                placeholder="EEL/PFC"
                error={!!errors.eelPfc}
              />
            </Box>
          </Box>
          <Box className={classes.wizardRow}>
            <Typography variant="body1" color="textPrimary">
              Default Incoterms
            </Typography>
            <Box width="45%">
              <Autocomplete
                options={Object.values(ShippoDefaultIncotermsType)}
                value={settings.incoterms}
                onChange={handleAutocompleteChange('incoterms')}
                placeholder="Default Incoterms"
                error={!!errors.incoterms}
              />
            </Box>
          </Box>
          <Box className={classes.wizardRow}>
            <Typography variant="body1" color="textPrimary">
              Exporter Reference
            </Typography>
            <Box width="45%">
              <Autocomplete
                options={shippoReferenceFields}
                value={selectedExporterReferenceField}
                onChange={(e: any, v: ShippoReferenceField | null) =>
                  handleAutocompleteChange('exporterReference')(
                    e,
                    v ? v.key : null
                  )
                }
                getOptionLabel={(s: ShippoReferenceField) => s.label}
                error={!!errors.exporterReference}
              />
            </Box>
          </Box>
          <Box className={classes.wizardRow}>
            <Typography variant="body1" color="textPrimary">
              Importer Reference
            </Typography>
            <Box width="45%">
              <Autocomplete
                options={shippoReferenceFields}
                value={selectedImporterReferenceField}
                onChange={(e: any, v: ShippoReferenceField | null) =>
                  handleAutocompleteChange('importerReference')(
                    e,
                    v ? v.key : null
                  )
                }
                getOptionLabel={(s: ShippoReferenceField) => s.label}
                error={!!errors.importerReference}
              />
            </Box>
          </Box>
          <Box className={classes.wizardRow}>
            <Typography variant="body1" color="textPrimary">
              Disclaimer
            </Typography>
            <Box width="45%">
              <TextField
                multiline
                rows={4}
                placeholder="Disclaimer"
                name="disclaimer"
                value={settings.disclaimer}
                onChange={handleTextFieldChange}
                className="redesign"
                variant="standard"
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default memo(InternationalSettingsForm);
