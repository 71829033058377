import React, { memo, useEffect, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import _ from 'lodash';

import {
  MappingDescriptor,
  LocationPickerDescriptor,
  RowDescriptor,
  RowType,
  TextDescriptor,
  TagDescriptor,
} from '../../types';
import { CardRowProps } from './types';
import { useCardRowStyle } from './styled';
import TagsPresentationalComponent from '../TagsPresentationalComponent/TagsPresentationalComponent';
import { LocationsPresentationalComponent } from '../LocationsPresentationalComponent';
import { GlobalSettingsFields } from 'services/commerce/settings';
import { IconNames } from 'ui/theme';
import { Icon } from 'ui/components/Icon';

const WarningIcon = () => {
  return (
    <ErrorOutlineIcon
      sx={{
        width: '18px',
        height: '18px',
        marginRight: '5px',
        color: 'red',
      }}
    />
  );
};

const TextRow: React.FC<TextDescriptor & CardRowProps> = (props) => {
  const classes = useCardRowStyle(props);
  const { title } = props;

  return (
    <Box className={classes.rowContainer}>
      <Box className={classes.titleContainer}>
        <Typography
          fontWeight={'bold'}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </Box>
    </Box>
  );
};

const LocationPickerRow: React.FC<LocationPickerDescriptor & CardRowProps> = (
  props
) => {
  const classes = useCardRowStyle(props);
  const { title, data, setLocationsLoaded } = props;

  const locationIds = data?.inventoryExport
    ?.inventoryWarehousesEnabled as unknown as number[];

  useEffect(() => {
    if (!locationIds) {
      setLocationsLoaded(true);
    }
  }, []);

  return (
    <Box className={classes.locationsRowContainer}>
      <Box className={classes.titleContainer}>
        <Typography dangerouslySetInnerHTML={{ __html: title }} />
      </Box>
      {locationIds ? (
        <LocationsPresentationalComponent
          ids={locationIds}
          setLocationsLoaded={setLocationsLoaded}
        />
      ) : (
        <Typography className={classes.locationText}>
          <WarningIcon />
          No Locations Added
        </Typography>
      )}
    </Box>
  );
};

const MappingRow: React.FC<MappingDescriptor & CardRowProps> = (props) => {
  const classes = useCardRowStyle(props);
  const { title, data, options, field } = props;

  const selectedValue = useMemo(() => {
    let value = '';
    if (!field) {
      return '';
    } else if (
      field ===
        GlobalSettingsFields.inventoryExport.inventoryWarehousesEnabled ||
      field === GlobalSettingsFields.inventoryExport.quantityType
    ) {
      value = _.get(data?.inventoryExport, field);
    } else if (
      field === GlobalSettingsFields.productExport.limitTags ||
      field === GlobalSettingsFields.productExport.longDescription ||
      field === GlobalSettingsFields.productExport.masterSku ||
      field === GlobalSettingsFields.productExport.productTitle ||
      field === GlobalSettingsFields.productExport.shortDescription ||
      field === GlobalSettingsFields.productExport.upc
    ) {
      value = _.get(data?.productExport, field);
    }

    return options.find((a: string) => a === value);
  }, [options, data, field]);

  const fieldIsRequired = useMemo(() => {
    if (!field) {
      return null;
    } else if (
      field === GlobalSettingsFields.productExport.masterSku ||
      field === GlobalSettingsFields.productExport.productTitle ||
      field === GlobalSettingsFields.inventoryExport.quantityType
    ) {
      return true;
    } else {
      return false;
    }
  }, [field]);

  return (
    <Box className={classes.rowContainer}>
      <Typography
        className={classes.titleContainer}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <Icon name={IconNames.FBOCaratRightSmall} />
      <Typography className={classes.text}>
        {!selectedValue && fieldIsRequired && <WarningIcon />}
        {selectedValue || 'Select Option'}
      </Typography>
    </Box>
  );
};

const TagRow: React.FC<TagDescriptor & CardRowProps> = (props) => {
  const classes = useCardRowStyle(props);

  const { title, data, setTagsLoaded } = props;
  const tagIds = data?.productExport?.limitTags;

  useEffect(() => {
    if (!tagIds) {
      setTagsLoaded(true);
    }
  }, []);

  return (
    <Box className={classes.locationsRowContainer}>
      <Typography
        className={classes.titleContainer}
        dangerouslySetInnerHTML={{ __html: title }}
      />

      {tagIds && tagIds.length > 0 ? (
        <TagsPresentationalComponent
          ids={tagIds}
          setTagsLoaded={setTagsLoaded}
        />
      ) : (
        <Typography className={classes.locationText}>None</Typography>
      )}
    </Box>
  );
};

const CardRow: React.FC<RowDescriptor & CardRowProps> = (props) => {
  switch (props.type) {
    case RowType.Text:
      return <TextRow {...props} />;
    case RowType.Tag:
      return <TagRow {...props} />;
    case RowType.Mapping:
      return <MappingRow {...props} />;
    case RowType.LocationPicker:
      return <LocationPickerRow {...props} />;
    default:
      return <></>;
  }
};

export default memo(CardRow);
