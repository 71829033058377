import React, { memo, useCallback, useMemo } from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  useHandleCheckboxChange,
  useHandleTextFieldChange,
} from 'services/forms/hooks';
import { Item } from 'services/items';
import { TextField } from 'ui/components/TextField/TextField';

import { ReportFiltersProps } from '../../types';
import { producedFromTypes } from './consts';
import { ProducedFromType } from './types';

import { ItemsAutocomplete } from 'ui/components/Autocomplete/ItemsAutocomplete';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';

const BarcodeFilters: React.FC<ReportFiltersProps> = (props) => {
  const { parameters, setParameters } = props;

  const selectedProducedFromType = useMemo(() => {
    return (
      producedFromTypes.find((o) => o.type === parameters.producedFromType) ||
      null
    );
  }, [parameters]);

  const handleSelectAll = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setParameters((old) => ({
        ...old,
        showDescription: checked,
        showPrice: checked,
        showUpc: checked,
        selectAllOptional: checked,
      }));
    },
    [setParameters]
  );

  const handleCheckboxChange = useHandleCheckboxChange(setParameters);

  const handleItemChange = useCallback(
    (selectedItem: Item | null) => {
      setParameters((old) => ({
        ...old,
        itemId: selectedItem ? selectedItem.id : null,
      }));
    },
    [setParameters]
  );

  const handleTextFieldChange = useHandleTextFieldChange(
    setParameters,
    parameters
  );

  const handleProducedFromTypeChange = useCallback(
    (_e: any, type: ProducedFromType | null) => {
      setParameters((old) => ({
        ...old,
        producedFromType: type ? type.type : null,
      }));
    },
    [setParameters]
  );
  // TODO : RESTYLING : Remove box wrappers around inputs after FF removal

  return (
    <>
      <Box px={2} pb={0} pt="16px">
        <FormControlLabel
          className="redesign"
          control={
            <Checkbox
              className="redesign"
              checked={Boolean(parameters.selectAllOptional)}
              onChange={handleSelectAll}
              color="primary"
            />
          }
          label="Select All Optional"
        />
      </Box>
      <Accordion square defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <b>Optional</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%" pt={1}>
            <Box>
              <ItemsAutocomplete
                label="Item"
                placeholder="Select item"
                onChange={handleItemChange}
                value={parameters.itemId}
              />
            </Box>
            <Box>
              <TextField
                className="redesign"
                variant="standard"
                label="Count"
                placeholder="Count"
                name="count"
                type="number"
                value={parameters.count || null}
                onChange={handleTextFieldChange}
                fullWidth
              />
            </Box>
            <Box>
              <Autocomplete
                label="Produced From Type"
                options={producedFromTypes}
                placeholder="Enter produced from type"
                getOptionLabel={(i) => i.title}
                onChange={handleProducedFromTypeChange}
                value={selectedProducedFromType}
                fullWidth
              />
            </Box>
            <FormControlLabel
              className="redesign"
              sx={{ marginBottom: '8px' }}
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(parameters.showDescription)}
                  onChange={handleCheckboxChange}
                  color="primary"
                  name="showDescription"
                />
              }
              label="Show Description"
            />
            <FormControlLabel
              className="redesign"
              sx={{ marginBottom: '8px' }}
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(parameters.showPrice)}
                  onChange={handleCheckboxChange}
                  color="primary"
                  name="showPrice"
                />
              }
              label="Show Price"
            />
            <FormControlLabel
              className="redesign"
              sx={{ marginBottom: '8px' }}
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(parameters.showUpc)}
                  onChange={handleCheckboxChange}
                  color="primary"
                  name="showUpc"
                />
              }
              label="Show UPC"
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default memo(BarcodeFilters);
