import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { IconButtonProps } from './types';

export const useIconButtonStyle = makeStyles((theme: Theme) => ({
  label: {
    marginRight: (props: IconButtonProps) =>
      props.icon ? theme.spacing(1.5) : theme.spacing(0),
    marginLeft: (props: IconButtonProps) =>
      props.startIcon ? theme.spacing(1.5) : theme.spacing(0),
    color: (props: IconButtonProps) =>
      !props.disabled
        ? props.boxStyle
          ? '#595959'
          : theme.palette.common.white
        : theme.palette.text.disabled,
  },
  button: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    background: 'transparent',
    borderRadius: '2px',
    marginRight: '10px',
    height: '38px',
    '&:hover': {
      border: '1px solid #303030',
      background: 'transparent',
      color: '#303030',
    },
  },
}));
