import * as yup from 'yup';
import { isValidUrl } from 'helpers';

export const yupCustomerGeneralSchema = yup.object().shape({
  bcc: yup.string().email().nullable().notRequired(),
  cc: yup.string().email().nullable().notRequired(),
  email: yup.string().email().nullable().notRequired(),
  name: yup.string().required(),
  paymentTermId: yup.number().required(),
  url: yup.string().test(isValidUrl).nullable().notRequired(),
});

export const yupCustomerCarrierAccountsSchema = yup.object().shape({
  accountNumber: yup.string().required(),
  billingCountry: yup.string().required(),
  billingType: yup.string().required(),
  billingZip: yup.string().required(),
  carrier: yup.string().required(),
  name: yup.string().trim().required(),
});

export const yupCustomerItemSchema = yup.object().shape({
  saleItemId: yup.number().required(),
  price: yup.number().required(),
  name: yup.string().required(),
});
