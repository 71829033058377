import { makeStyles } from '@mui/styles';

export const useTrackingRemoveCells = makeStyles(() => ({
  spacing: {
    marginRight: '8px',
  },
  centeredFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  warningIcon: {
    width: '18px',
    height: '18px',
    marginRight: '5px',
    color: 'red',
  },
}));
