import { BackgroundAction, BackgroundType } from 'services/backgroundTasks';

export enum AlertActionType {
  SHOW_ALERT = 'alert/show_alert',
  HIDE_ALERT = 'alert/hide_alert',
  SHOW_PROGRESS_ALERT = 'alert/show_progress_alert',
  HIDE_PROGRESS_ALERT = 'alert/hide_progress_alert',
}

export interface AlertState {
  alert: Alert;
  progressAlert: ProgressAlert;
}

export interface Alert {
  show: boolean;
  msg: string | null;
  url: string | null;
}

export interface AlertAction {
  type: AlertActionType;
  payload: Alert;
}

export interface AlertProgressAction {
  type: AlertActionType;
  payload: ProgressAlert;
}

export interface ProgressAlert {
  show: boolean;
  msg: string | null;
  backgroundType: BackgroundType | null;
  backgroundAction: BackgroundAction | null;
}
