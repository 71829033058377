import React, { memo, useMemo } from 'react';
import clsx from 'clsx';
import { Box, TableCell, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import _ from 'lodash';

import { InventoryRow } from 'services/inventory';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';

import { useTrackingWizardRowStyle } from './styled';

const TrackingWizardRow: React.FC<RenderCustomRowProps<InventoryRow>> = (
  props
) => {
  const { row: inventoryRow, meta } = props;

  const classes = useTrackingWizardRowStyle();

  const quantity = useMemo(
    () =>
      inventoryRow.trackingGroupList.reduce(
        (acc, i) => acc + (i.quantity || 0),
        0
      ),
    [inventoryRow]
  );

  const resolvedQuantity = `${quantity}/${inventoryRow.totalQty} ${_.get(
    meta,
    'uom.abbreviation'
  )}`;

  if (inventoryRow.id === -1) {
    return (
      <TableCell colSpan={2}>
        <Typography>Summary Page</Typography>
      </TableCell>
    );
  }

  return (
    <>
      <TableCell className={classes.firstColumnWidth}>
        <Box display="flex" alignItems="center">
          {quantity > 0 ? (
            <CheckCircleIcon
              className={clsx({
                [classes.checkIcon]: true,
                [classes.checkIconSuccess]: quantity === inventoryRow.totalQty,
                [classes.checkIconPartial]: quantity !== inventoryRow.totalQty,
              })}
            />
          ) : (
            <RadioButtonUncheckedIcon className={classes.checkIcon} />
          )}
          <Box>
            <Typography>{_.get(inventoryRow, 'location.name', '')}</Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell>{resolvedQuantity}</TableCell>
    </>
  );
};

export default memo(TrackingWizardRow);
