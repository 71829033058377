import React, { useCallback, useEffect, useMemo } from 'react';
import { Grid } from '@mui/material';

import { GridRow } from 'ui/components/Grid';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { CustomerSettingsProps } from './types';
import { INPUT_WIDTH } from '../consts';
import {
  fetchPaymentTerms,
  getPaymentTerms,
  PaymentTerm,
} from 'services/paymentTerms';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchOrderPriorities,
  getOrderPriorities,
  OrderPriority,
} from 'services/settings/orderPriorities';
import { fetchCustomers } from 'services/customers';
import { RepresentativesAutocomplete } from 'ui/components/Autocomplete/RepresentativesAutocomplete';
import {
  fetchRepresentatives,
  getRepresentatives,
  Representative,
} from 'services/representatives';
import { ChannelSettings } from 'services/commerce/channels';

const CustomerSettings: React.FC<CustomerSettingsProps> = (props) => {
  const { channelSettings, setChannelSettings, errors } = props;
  const dispatch = useDispatch();

  const { items: representatives } = useSelector(getRepresentatives);
  const { items: orderPriorities } = useSelector(getOrderPriorities);
  const { items: paymentTerms } = useSelector(getPaymentTerms);

  useEffect(() => {
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchRepresentatives());
    // @ts-ignore
    dispatch(fetchOrderPriorities());
    // @ts-ignore
    dispatch(fetchCustomers());
    // @ts-ignore
    dispatch(fetchPaymentTerms());
    // react-hooks/exhaustive-deps
  }, []);

  const selectedOrderPriority = useMemo(() => {
    return (
      orderPriorities.find(
        (p) => p.id === channelSettings.customerSettings.priority
      ) || null
    );
  }, [orderPriorities, channelSettings.customerSettings.priority]);

  const selectedRepresentative = useMemo(() => {
    return (
      representatives.find(
        (r) => r.id === channelSettings.customerSettings.salesRepresentative
      ) || null
    );
  }, [representatives, channelSettings.customerSettings.salesRepresentative]);

  const selectedPaymentTerm = useMemo(
    () =>
      paymentTerms.find(
        (p) => p.id === channelSettings.customerSettings.defaultPaymentTerms
      ) || null,
    [channelSettings.customerSettings.defaultPaymentTerms, paymentTerms]
  );

  const handleAutocompleteChange = useCallback(
    (name: 'salesRepresentative' | 'priority' | 'defaultPaymentTerms') =>
      (v: Representative | OrderPriority | PaymentTerm | null) => {
        setChannelSettings((old: ChannelSettings) => ({
          ...old,
          customerSettings: {
            ...old.customerSettings,
            [name]: v ? v.id : null,
          },
        }));
      },
    [setChannelSettings]
  );

  return (
    <GridRow title="Customer Settings">
      <Grid width={INPUT_WIDTH} item>
        <Autocomplete
          options={paymentTerms}
          label="Default Payment Terms"
          value={selectedPaymentTerm}
          getOptionLabel={(option: PaymentTerm) => option.name || ''}
          onChange={(e: React.ChangeEvent<{}>, v: PaymentTerm | null) =>
            handleAutocompleteChange('defaultPaymentTerms')(v)
          }
          placeholder="Select payment term"
          dataQa="customer-payment-term"
          error={!!errors['customerSettings.defaultPaymentTerms']}
          required
        />
      </Grid>
      <Grid width={INPUT_WIDTH} item>
        <Autocomplete
          label="Priority"
          options={orderPriorities}
          value={selectedOrderPriority}
          getOptionLabel={(option: OrderPriority) => option.name}
          onChange={(e: React.ChangeEvent<{}>, v: OrderPriority | null) =>
            handleAutocompleteChange('priority')(v)
          }
          placeholder="Select priority"
          dataQa="customer-priority"
          error={!!errors['customerSettings.priority']}
        />
      </Grid>
      <Grid width={INPUT_WIDTH} item>
        <RepresentativesAutocomplete
          label="Sales Representative"
          value={selectedRepresentative}
          placeholder="Select representative"
          onChange={(v: Representative | null) =>
            handleAutocompleteChange('salesRepresentative')(v)
          }
          dataQa="customer-sales-representative"
          error={!!errors['customerSettings.salesRepresentative']}
        />
      </Grid>
    </GridRow>
  );
};

export default CustomerSettings;
