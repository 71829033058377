import React from 'react';
import { Box, Typography } from '@mui/material';
import { useItemCompositeHeaderStyles } from './styled';
import { ItemCompositeHeaderProps } from './types';

const ItemCompositeHeader = (props: ItemCompositeHeaderProps) => {
  const { name, description } = props;

  const cls = useItemCompositeHeaderStyles(props);

  return (
    <Box className={cls.content}>
      <Typography variant="body1" color="textPrimary" className={cls.name}>
        {name || 'Unknown item'}
      </Typography>
      <Typography
        variant="subtitle1"
        color="textSecondary"
        className={cls.description}
      >
        {description || <span>&nbsp;</span>}
      </Typography>
    </Box>
  );
};

export default ItemCompositeHeader;
