/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useRef } from 'react';
import {
  Chip,
  CircularProgress,
  TextField as MTextField,
  Typography,
  AutocompleteProps,
  OutlinedInputProps,
  UseAutocompleteProps,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector } from 'react-redux';

import { activeUserHasPermission } from 'services/user/redux';
import { stopInputPropagation } from 'helpers';

import { useAutocomplete } from './styled';

import { Pagination } from 'services/search';

import { PermissionType } from 'services/permissions';
import { Box } from '@mui/system';
import { colorPalette } from 'ui/theme';

export type FBOSearchHeaderAutoCompleteCmp<T> = React.FunctionComponent<
  FBOSearchHeaderAutoCompleteProps<T> & UseAutocompleteProps<T, any, any, any>
>;

export interface FBOSearchHeaderAutoCompleteProps<T>
  extends Omit<AutocompleteProps<T, any, any, any>, 'renderInput'> {
  label?: string;
  name?: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  additionalInputProps?: Partial<OutlinedInputProps>;
  dataQa?: string;
  permissions?: PermissionType[];
  statusCustom?: string;
  isLoading?: boolean;
  inputRef?: any;
  doNotOverwriteGetOptionLabel?: boolean;
}

export type PaginatedFetchFunction<T> = (
  pagination: Pagination,
  searchValue: string | null
) => Promise<{ data: T[]; pagination: Pagination }>;

export type FetchByIdFunction<T> = (id: number) => Promise<T>;

const FBOSearchHeaderAutoComplete: FBOSearchHeaderAutoCompleteCmp<any> = (
  props: any
) => {
  const {
    label,
    name,
    required,
    placeholder,
    error,
    helperText,
    getOptionLabel,
    additionalInputProps,
    className,
    disabled = false,
    autoSelect = true,
    classes,
    dataQa,
    statusCustom,
    permissions = [],
    isLoading,
    inputRef,
    doNotOverwriteGetOptionLabel = false,
    ...otherProps
  } = props;

  const classesInner = useAutocomplete(props);

  const inputElement = inputRef || useRef<HTMLInputElement>(null);

  const canEdit = useSelector(activeUserHasPermission(permissions));

  const isDisabled = disabled || !canEdit;

  const resolveOptionLabel = useCallback((option: any) => {
    if (!getOptionLabel || !option) {
      return option || '';
    }

    return getOptionLabel(option);
  }, []);

  const handleOnOpen = useCallback(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, [inputElement]);

  const renderInput = useCallback(
    (params) => {
      const endAdornment = params.InputProps.endAdornment as any;

      return (
        //TODO : RESTYLING : refactor this thing later to use FBOTextField
        //TODO : RESTYLING : refactor to make this component more generic and reuseable
        <MTextField
          className={'redesign'}
          style={{ marginTop: '0', marginBottom: '0' }}
          {...params}
          name={name}
          fullWidth
          InputLabelProps={{ shrink: true, margin: 'dense' }}
          required={required}
          placeholder={placeholder}
          onClick={stopInputPropagation}
          error={error}
          helperText={helperText}
          variant="outlined"
          size="small"
          disabled={disabled}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off',
            'data-qa': dataQa,
            'data-lpignore': 'true',
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <Box
                sx={{
                  backgroundColor: `${colorPalette.redesign.background2}`,
                  marginLeft: '-16px',
                  marginRight: '8px',
                  height: '38px',
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: '4px 0 0 4px',
                  borderRight: `1px solid ${colorPalette.redesign.background4}`,
                }}
              >
                <Typography
                  sx={{
                    padding: '0 8px',
                    color: `${colorPalette.redesign.contentSecondary}`,
                  }}
                >
                  {label}
                </Typography>
              </Box>
            ),
            endAdornment: (
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {endAdornment}
              </React.Fragment>
            ),
            ...additionalInputProps,
            inputRef: inputElement,
          }}
        >
          {statusCustom && label === 'Drop Ship' && (
            <Chip
              label={statusCustom}
              size="small"
              classes={{
                root: classesInner.status,
                label: classesInner.statusLabel,
              }}
              color="secondary"
            />
          )}
        </MTextField>
      );
    },
    [
      error,
      inputElement,
      helperText,
      disabled,
      additionalInputProps,
      statusCustom,
    ]
  );

  return (
    <Autocomplete
      {...otherProps}
      style={{ marginTop: '0px', marginBottom: '0px' }}
      className={`${'redesign'} ${classesInner.minWidth} ${className}`}
      classes={{
        popper: classesInner.popup,
        option: classesInner.option,
        ...classes,
      }}
      getOptionLabel={
        doNotOverwriteGetOptionLabel ? getOptionLabel : resolveOptionLabel
      }
      renderInput={renderInput}
      disabled={isDisabled}
      onOpen={handleOnOpen}
      autoSelect={autoSelect}
      clearIcon={
        dataQa && (
          <CloseIcon
            fontSize="small"
            data-qa={`${dataQa}-autocomplete-clear-icon`}
          />
        )
      }
      popupIcon={
        dataQa && (
          <ArrowDropDownIcon
            fontSize="small"
            data-qa={`${dataQa}-autocomplete-dropdown-icon`}
          />
        )
      }
    />
  );
};

export default React.memo(FBOSearchHeaderAutoComplete);
