import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import _ from 'lodash';

import { Modal } from 'ui/components/Modal/Modal';

import {
  TrackingTableTypes,
  transformToTrackingGroupsNewGroup,
} from 'ui/components/Table/TrackingTable';
import {
  getItemInventory,
  initialItemInventory,
  itemHasOnlySerialTracking,
  itemHasSerialTracking,
  itemHasTracking,
  ItemInventory,
  ItemInventoryEvents,
  SerialRow,
  TrackingGroup,
} from 'services/inventory';
import {
  getInventoryEventSchema,
  inventoryEventOnlySerialListValidation,
  inventoryEventTrackingGroupValidation,
} from 'ui/modules/materials/services';
import { EACH_UOM_ID, getUoms } from 'services/uoms';
import { validateYup } from 'services/forms/validation';
import { getSettingsCompany } from 'services/settings/company';
import { CostingMethod } from 'ui/modules/setup/pages/SettingsPage/components/CompanyCard/types';

import { CycleEventFormValues, InventoryEventModalProps } from './types';
import { defaultInventoryEventFormValues } from './consts';
import { resolveLabels, sortDuplicateTrackingGroupsToTop } from './helpers';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import TrackingTable from 'ui/components/Table/TrackingTable/TrackingTable';
import CycleCountSerialTable from 'ui/components/Table/SerialTable/CycleCountSerialTable';
import {
  duplicateSerialNumberIndexes,
  sortedSerialListWithDuplicatesFirst,
  sortSerialList,
} from 'ui/components/Table/SerialTable/helpers';
import { showNotification } from 'services/api';
import { TrackingRow } from './components/TrackingRow';
import { CycleCounts } from './components/CycleCounts';

import FBOEventCycleForm from './components/EventCycleForm/FBOEventCycleForm';
import { themeRestyle } from 'ui/theme';

const CycleEventModal: React.FC<InventoryEventModalProps> = (props) => {
  const {
    item,
    show,
    eventType,
    locationId = null,
    onApplyClicked,
    onCancelClicked,
    locationDuplicateArray,
    setShowEventModal,
  } = props;

  const { items: uoms } = useSelector(getUoms);
  const companySettings = useSelector(getSettingsCompany);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [itemInventory, setItemInventory] =
    useState<ItemInventory>(initialItemInventory);
  const [trackingGroups, setTrackingGroups] = useState<TrackingGroup[]>([]);
  const [serialList, setSerialList] = useState<SerialRow[]>([]);
  const [formValues, setFormValues] = useState<CycleEventFormValues>(
    defaultInventoryEventFormValues
  );
  const [selectedSerialRows, setSelectedSerialRows] = useState<number[]>([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [qtyErrorMessage, setQtyErrorMessage] = useState('');
  const [showDuplicates, setShowDuplicates] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [idToRemove, setIdToRemove] = useState('');
  const [addDisabled, setAddDisabled] = useState<boolean>(false);
  const [trackingGroupQty, setTrackingGroupQty] = useState<number>(0);
  const [errorLabel, setErrorLabel] = useState<string>('');
  const [hasInvalidTracking, setHasInvalidTracking] = useState<boolean>(false);
  const [duplicateArray, setDuplicateArray] = useState<string[]>([]);
  const [oldFormValues, setOldFormValues] = useState<CycleEventFormValues>(
    defaultInventoryEventFormValues
  );
  const [showModal, setShowModal] = useState<boolean>(false);
  const [flag, setFlag] = useState(false);
  const formValuesUom = useMemo(() => {
    return formValues.uomId
      ? uoms.find((u) => u.id === formValues.uomId)
      : uoms.find((u) => u.id === EACH_UOM_ID);
  }, [formValues.uomId, uoms]);

  const selectedInventoryRow = useMemo(() => {
    if (!itemInventory) {
      return null;
    }

    return (
      itemInventory.inventoryRowList.find(
        (row) => row.locationId === formValues.locationFromId
      ) || null
    );
  }, [formValues.locationFromId, itemInventory]);

  const locationQuantity: {
    [locationId: number]: number;
  } = useMemo(
    () =>
      itemInventory.inventoryRowList.reduce(
        (acc, i) => ({ ...acc, [i.locationId]: i.availableQty }),
        {}
      ),
    [itemInventory]
  );

  const noTracking = useMemo(() => !itemHasTracking(item), [item]);

  const onlySerial = useMemo(() => itemHasOnlySerialTracking(item), [item]);

  const hasSerialTracking = useMemo(() => itemHasSerialTracking(item), [item]);

  const showTrackingTable = useMemo(
    () => !noTracking && !onlySerial,
    [noTracking, onlySerial]
  );

  const enabledTrackingTable = useMemo(
    () => !!formValues.locationFromId,
    [formValues.locationFromId]
  );

  const showSerialTable = useMemo(
    () => !noTracking && onlySerial,
    [noTracking, onlySerial]
  );

  const showCycleCountSerialTable = useMemo(
    () => !noTracking && onlySerial && eventType === ItemInventoryEvents.Cycle,
    [noTracking, onlySerial, eventType]
  );

  const canEditSerial = useMemo(
    () =>
      eventType === ItemInventoryEvents.Add ||
      eventType === ItemInventoryEvents.Cycle,
    [eventType]
  );

  useMemo(() => {
    if (noTracking) {
      setHasInvalidTracking(false);
      setErrorLabel('');
      return;
    }
    let committedQtyError = false;
    trackingGroups.forEach((tg) => {
      if (
        tg.committedQuantity &&
        tg.quantity !== undefined &&
        tg.committedQuantity > (tg.quantity ?? 0)
      ) {
        committedQtyError = true;
        return;
      }
    });
    if (committedQtyError) {
      setHasInvalidTracking(true);
      setErrorLabel(
        'Unable to set value lower than the amount already committed'
      );
      return;
    }

    if (onlySerial) {
      const invalid = inventoryEventOnlySerialListValidation(
        serialList,
        formValues.quantity || 0,
        eventType
      );
      invalid
        ? setErrorLabel('Input required tracking information to cycle.')
        : setErrorLabel('');
      setHasInvalidTracking(invalid);
      return;
    }

    const invalid = inventoryEventTrackingGroupValidation(
      hasSerialTracking,
      trackingGroups,
      eventType
    );
    invalid
      ? setErrorLabel('Input required tracking information to cycle.')
      : setErrorLabel('');
    setHasInvalidTracking(invalid);
  }, [
    trackingGroups,
    noTracking,
    hasSerialTracking,
    onlySerial,
    serialList,
    formValues.quantity,
    eventType,
  ]);

  // only uoms that have conversion with default uom can be selected
  const uomIds = useMemo(() => {
    if (!item.defaultUom) {
      return [];
    }

    const fromUomIds = item.defaultUom.fromConversions.map(
      (uom) => uom.fromUomId!
    );
    const toUomIds = item.defaultUom.toConversions.map((uom) => uom.toUomId!);

    return [item.defaultUomId!, ...fromUomIds, ...toUomIds];
  }, [item]);
  const resetForm = () => {
    setItemInventory(initialItemInventory);
    setTrackingGroups([]);
    setSerialList([]);
    setSelectedSerialRows([]);
    setFormValues(defaultInventoryEventFormValues);
    setOldFormValues(defaultInventoryEventFormValues);
    setQtyErrorMessage('');
    setDuplicateArray([]);
  };
  useEffect(() => {
    // when modal closes reset state
    if (!show) {
      resetForm();
      return;
    }

    setIsLoading(true);
    const asyncFun = async () => {
      const inventory = await getItemInventory(item.id!, true);
      setItemInventory(inventory);
      setIsLoading(false);
    };

    asyncFun();

    const cost =
      companySettings.accountingMethod === CostingMethod.Average &&
      eventType === ItemInventoryEvents.Add
        ? item.cost
        : null;

    setFormValues((f) => ({
      ...f,
      cost,
      locationFromId: locationId,
      uomId: item.defaultUomId,
    }));
    setOldFormValues((f) => ({
      ...f,
      cost,
      locationFromId: locationId,
      uomId: item.defaultUomId,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (!itemInventory) {
      return;
    }

    const inventoryRow = itemInventory.inventoryRowList.find(
      (row) => row.locationId === formValues.locationFromId
    );

    if (!inventoryRow) {
      setTrackingGroups(
        transformToTrackingGroupsNewGroup([], item.itemTrackingTypeList, 0)
      );
      return;
    }

    switch (eventType) {
      case ItemInventoryEvents.Add: {
        setTrackingGroups(
          inventoryRow.trackingGroupList.map((trackingGroup) => ({
            ...trackingGroup,
            serialList: [],
          }))
        );
        break;
      }
      case ItemInventoryEvents.Cycle: {
        setTrackingGroups(
          inventoryRow.trackingGroupList.map((tgl) => ({
            ...tgl,
            quantity: tgl.onHand,
          }))
        );
        break;
      }
      default: {
        setTrackingGroups(inventoryRow.trackingGroupList);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.locationFromId, itemInventory]);

  useEffect(() => {
    const serialList = _.get(trackingGroups, '[0].serialList', []);
    sortSerialList(serialList);
    setSerialList(serialList);
    if (!serialList.length && !trackingGroups.length) {
      if (!noTracking) {
        setFormValues((f) => ({ ...f, quantity: 0 }));
        setOldFormValues((f) => ({ ...f, quantity: 0 }));

        setTrackingGroupQty(0);
      }
      return;
    }

    const quantity = trackingGroups.reduce(
      (acc, trackingGroup) => acc + (trackingGroup.quantity || 0),
      0
    );

    setTrackingGroupQty(quantity);

    setFormValues((f) => ({ ...f, quantity }));
    if (!flag && quantity > 0) {
      setOldFormValues((f) => ({ ...f, quantity }));
      setFlag(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingGroups]);

  useEffect(() => {
    if (noTracking) {
      setAddDisabled(false);
    } else if (onlySerial) {
      setAddDisabled(
        formValues.quantity === null || serialList.length != formValues.quantity
      );
    } else {
      setAddDisabled(
        formValues.quantity === null || trackingGroupQty != formValues.quantity
      );
    }
  }, [formValues.quantity, trackingGroupQty, serialList]);

  const handleAddTrackingClicked = useCallback(() => {
    setOldFormValues(defaultInventoryEventFormValues);
    const availableQuantity = Math.max(
      0,
      (formValues.quantity || 0) -
        trackingGroups.map((t) => t.quantity || 0).reduce((a, b) => a + b, 0)
    );

    setTrackingGroups(
      transformToTrackingGroupsNewGroup(
        trackingGroups,
        item.itemTrackingTypeList,
        availableQuantity
      )
    );
  }, [formValues, trackingGroups, item]);

  const saveTrackingItems = useCallback(
    async (
      newFormValues: CycleEventFormValues,
      newTrackingGroups: TrackingGroup[]
    ) => {
      setIsLoading(true);
      try {
        await onApplyClicked(newFormValues, newTrackingGroups);
      } catch (e) {
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
    },
    [onApplyClicked]
  );

  const handleRemoveTrackingGroup = (id: string) => {
    setIdToRemove(id);
    setShowConfirmationModal(true);
  };

  const handleRemoveConfirm = () => {
    setTrackingGroups(trackingGroups.filter((tg) => tg.id !== idToRemove));
    setShowConfirmationModal(false);
    setIdToRemove('');
  };

  const handleRemoveCancel = () => {
    setShowConfirmationModal(false);
    setIdToRemove('');
  };

  const handleApplyClicked = async () => {
    const isValid = validateYup(
      formValues,
      getInventoryEventSchema(eventType),
      setValidationErrors
    );

    if (!isValid) {
      return;
    }

    if (onlySerial) {
      sortSerialList(serialList);
      //check serial list for duplicates
      const duplicatesIndexArray = duplicateSerialNumberIndexes(serialList);

      if (duplicatesIndexArray.length > 0 && showCycleCountSerialTable) {
        sortedSerialListWithDuplicatesFirst(
          serialList,
          setSerialList,
          duplicatesIndexArray
        );
        showNotification(
          'Duplicates found, please navigate to the beginning of the list and resolve them before continuing',
          {
            variant: 'error',
          }
        );
        setShowDuplicates(true);
        return;
      }
      const newTrackingGroups: TrackingGroup[] = [
        {
          ...trackingGroups[0], // If only serial tracking group index is always 0
          quantity: formValues.quantity!,
          serialList,
          serialIds: selectedSerialRows,
          trackingInfoList: [],
        },
      ];
      await saveTrackingItems(formValues, newTrackingGroups);
    } else {
      //sort and check for duplicates across tracking groups
      const allSerials = trackingGroups.flatMap((group) =>
        group.serialList.map((serial) => {
          return { ...serial, trackingGroupId: group.id };
        })
      );
      sortSerialList(allSerials);
      const duplicateIds = duplicateSerialNumberIndexes(allSerials);
      if (duplicateIds.length > 0) {
        const currentDuplicateArray = duplicateIds.map((i) => allSerials[i]);
        const duplicatedTrackingGroupIds = _.uniq(
          currentDuplicateArray.map((dupe) => {
            return dupe.trackingGroupId || '';
          })
        );
        sortDuplicateTrackingGroupsToTop(
          trackingGroups,
          setTrackingGroups,
          duplicatedTrackingGroupIds
        );
        setDuplicateArray(
          currentDuplicateArray.map((i) => Object.values(i.serialNumbers)[0])
        );
        showNotification(
          'Duplicates found, please resolve them before continuing',
          {
            variant: 'error',
          }
        );
      } else {
        await saveTrackingItems(formValues, trackingGroups);
      }
    }
  };

  //remove the "DUPLICATE" label when duplicates are fixed
  useEffect(() => {
    const allSerials = trackingGroups.flatMap((group) =>
      group.serialList.map((serial) => {
        return { ...serial, trackingGroupId: group.id };
      })
    );
    sortSerialList(allSerials);
    const duplicateIds = duplicateSerialNumberIndexes(allSerials);

    if (duplicateIds.length === 0 && !locationDuplicateArray.length) {
      setShowDuplicates(false);
      setDuplicateArray([]);
    }
  }, [trackingGroups]);

  //mark and sort location duplicates if they come back from platform
  useEffect(() => {
    if (locationDuplicateArray.length) {
      setShowDuplicates(true);
      setDuplicateArray(locationDuplicateArray);
      sortSerialList(serialList);
      //check serial list for duplicates
      const duplicatesIndexArray = duplicateSerialNumberIndexes(
        serialList,
        locationDuplicateArray
      );

      sortedSerialListWithDuplicatesFirst(
        serialList,
        setSerialList,
        duplicatesIndexArray
      );
    } else {
      setShowDuplicates(false);
    }
  }, [locationDuplicateArray]);

  const handleSelectedCycleCountChange = useCallback(
    (id: number | number[]) => {
      if (Array.isArray(id)) {
        setSelectedSerialRows(id);
        return;
      }

      const isIdAlreadySelected = selectedSerialRows.includes(id);
      if (isIdAlreadySelected) {
        setSelectedSerialRows(_.without(selectedSerialRows, id));
        return;
      }
      setSelectedSerialRows([...selectedSerialRows, id]);
    },
    [selectedSerialRows, setSelectedSerialRows]
  );

  const applyLabel = resolveLabels(
    eventType,
    eventType === ItemInventoryEvents.Remove && onlySerial
      ? selectedSerialRows.length
      : formValues.quantity || 0,
    formValuesUom?.name ?? undefined
  ).buttonLabel;

  const cancelHandler = () => {
    const checkEquality = _.isEqual(oldFormValues, formValues);
    if (checkEquality) {
      setFlag(false);
      setShowEventModal(false);
      onCancelClicked();
    } else setShowModal(true);
  };

  return (
    <Modal
      className="redesign inventory-event-modal"
      open={show}
      title={
        resolveLabels(eventType, formValues.quantity || 0, undefined, item.name)
          .modalLabel
      }
      applyLabel={applyLabel}
      onCancelClicked={cancelHandler}
      onApplyClicked={handleApplyClicked}
      isLoading={isLoading}
      withoutDefaultPadding
      applyDisabled={addDisabled || hasInvalidTracking}
      nestedScrollAreas
      isLoadingContent={isLoading}
      dataQa="inventory-modal"
    >
      <FBOEventCycleForm
        formValues={formValues}
        setFormValues={setFormValues}
        setOldFormValues={setOldFormValues}
        onlySerial={onlySerial}
        hasSerialTracking={hasSerialTracking}
        noTracking={noTracking}
        averageCost={(item.cost || 0).toFixed(2)}
        totalQty={selectedInventoryRow ? selectedInventoryRow.totalQty : 0}
        committedQty={
          selectedInventoryRow ? selectedInventoryRow.committedQty : 0
        }
        validationErrors={validationErrors}
        locationQuantity={locationQuantity}
        uomIds={uomIds}
        errorMessage={qtyErrorMessage}
        defaultUomAbbreviation={item.defaultUom?.abbreviation || ''}
      />
      {showTrackingTable && (
        <TrackingTable
          itemTrackingTypes={item.itemTrackingTypeList}
          trackingGroups={enabledTrackingTable ? trackingGroups : []}
          onAddNewTracking={handleAddTrackingClicked}
          onSetTrackingGroups={setTrackingGroups}
          tableType={TrackingTableTypes.CycleNew}
          firstColumnTitle="Quantity"
          formValues={formValues}
          itemUom={formValuesUom!}
          inventoryUom={item.defaultUom}
          emptyTableText="Please select a location to begin"
          isDecimal={!hasSerialTracking}
          disableAutoAssign={false}
          customRow={TrackingRow}
          withoutTitleBar={false}
          removeTrackingGroup={handleRemoveTrackingGroup}
          locationId={locationId}
          uomDefaultAbbreviation={item.defaultUom?.abbreviation || ''}
          itemCost={item.cost || 0}
          duplicateArray={duplicateArray}
          headerMargin="0px 32px"
          shouldSort
        />
      )}
      {showSerialTable ? (
        <CycleCountSerialTable
          minSerialQuantity={formValues.quantity || 0}
          serialList={serialList}
          setSerialList={setSerialList}
          selectedRows={selectedSerialRows}
          onSelectedRowsChanged={handleSelectedCycleCountChange}
          itemTrackingTypes={item.itemTrackingTypeList}
          canClearRow={canEditSerial}
          canEditRow={canEditSerial}
          canSelectRow={!canEditSerial}
          emptyTableText={'Please select Location to begin'}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          disableAutoAssign={false}
          setQtyErrorMessage={setQtyErrorMessage}
          allowSerialNumberImport
          showDuplicates={showDuplicates}
          duplicateArray={duplicateArray}
          headerMargin="0px 24px"
        />
      ) : (
        <></>
      )}
      {showTrackingTable && !showSerialTable && (
        <Box marginLeft={themeRestyle.spacing(2)}>
          <CycleCounts
            requiredQty={formValues.quantity || 0}
            actualQty={trackingGroupQty}
            errorLabel={errorLabel}
          />
        </Box>
      )}
      <ConfirmationModal
        open={showConfirmationModal}
        title="Delete Row?"
        onConfirmClicked={handleRemoveConfirm}
        onCancelClicked={handleRemoveCancel}
        body="All inputted tracking and quantity will be lost if you delete the row."
        confirmButtonRed
        confirmLabel="Delete"
      />
      <ConfirmationModal
        open={showModal}
        onConfirmClicked={() => {
          resetForm();
          setFlag(false);
          setShowModal(false);
          setShowEventModal(false);
          onCancelClicked();
        }}
        onCancelClicked={() => {
          setShowModal(false);
        }}
        title="Do you want to leave?"
        body="All newly inputted tracking will be removed."
        confirmLabel="Yes"
        dataQa="confirmation-modal"
      />
    </Modal>
  );
};

export default memo(CycleEventModal);
