import React, { memo, useCallback, useEffect, useState } from 'react';
import { Box, Tabs, Tab, Typography } from '@mui/material';

import TabPanel from 'ui/components/TabPanel/TabPanel';

import { useCardRowStyle } from '../styled';
import { ShippingQuoteRatesProps } from './types';
import QuoteRatesRow from './QuoteRatesRow';

enum TabRate {
  USPS,
  UPS,
  FedEx,
}

const ShippingQuoteRates: React.FC<ShippingQuoteRatesProps> = (props) => {
  const { rates, defaultShippingSettings, shippingQuote, setShippingQuote } =
    props;

  const classes = useCardRowStyle();

  const [activeTab, setActiveTab] = useState<TabRate | boolean>(false);

  useEffect(() => {
    const uspsRates =
      rates.find((i) => i.carrierCode === 'usps' || 'stamps_com') || null;
    if (uspsRates && uspsRates.services.length) {
      setActiveTab(TabRate.USPS);
      return;
    }

    const upsRates =
      rates.find((i) => i.carrierCode === 'ups' || 'ups_walleted') || null;
    if (upsRates && upsRates.services.length) {
      setActiveTab(TabRate.UPS);
      return;
    }

    const fedexRates = rates.find((i) => i.carrierCode === 'fedex') || null;
    if (fedexRates && fedexRates.services.length) {
      setActiveTab(TabRate.FedEx);
      return;
    }

    return setActiveTab(false);
  }, [rates]);

  const handleActiveTabChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      setActiveTab(newValue);
    },
    []
  );

  const handleSelectService = (carrierCode: string, serviceCode: string) => {
    setShippingQuote((old) => ({
      ...old,
      carrierCode,
      serviceCode,
    }));
  };

  return (
    <>
      <Box className={classes.sidebar}>
        {rates.length === 0 ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
            flexDirection="column"
          >
            <Typography variant="h4">No rates</Typography>
            <Typography variant="body1">
              Cannot finish shipping quote without rates
            </Typography>
          </Box>
        ) : (
          <>
            <Tabs
              value={activeTab}
              onChange={handleActiveTabChange}
              indicatorColor="primary"
            >
              <Tab label="USPS" value={TabRate.USPS} />
              <Tab label="UPS" value={TabRate.UPS} />
              <Tab label="FedEx" value={TabRate.FedEx} />
            </Tabs>
            <Box display="flex" flexDirection="column" width="100%">
              {rates.map((rate, index) => (
                <TabPanel
                  value={activeTab}
                  index={index}
                  flexGrow
                  noSpacing
                  key={`tab_panel_${rate.carrierCode}`}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    width="100%"
                    flexGrow={1}
                  >
                    {rate.services.length > 0 ? (
                      rate.services.map((service) => (
                        <QuoteRatesRow
                          key={`row_${service.serviceCode}`}
                          service={service}
                          selected={
                            shippingQuote.serviceCode === service.serviceCode
                          }
                          onSelect={() =>
                            handleSelectService(
                              rate.carrierCode,
                              service.serviceCode
                            )
                          }
                          defaultShippingSettings={defaultShippingSettings}
                        />
                      ))
                    ) : (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                        marginTop={50}
                        flexGrow={1}
                      >
                        <Typography variant="h4">No rates available</Typography>
                      </Box>
                    )}
                  </Box>
                </TabPanel>
              ))}
            </Box>
            {rates.every((i) => !i.services.length) && !activeTab && (
              <TabPanel value={activeTab} index={activeTab} flexGrow noSpacing>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                  flexGrow={1}
                >
                  <Typography variant="h4">No rates available</Typography>
                </Box>
              </TabPanel>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default memo(ShippingQuoteRates);
