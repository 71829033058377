import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';
import { IconNames } from 'ui/theme';

export const PICK_ITEM_COLUMNS: Column[] = [
  {
    title: 'Item',
    type: ColumnTypes.renderByCustomRow,
    field: 'item.name',
    sortable: true,
  },
  {
    title: 'Status',
    type: ColumnTypes.renderByCustomRow,
    field: 'status',
    sortable: true,
    width: 180,
  },
  {
    title: 'Quantity',
    type: ColumnTypes.renderByCustomRow,
    field: 'quantity',
    sortable: true,
    width: 100,
  },
  {
    title: 'Pick Origin',
    type: ColumnTypes.renderByCustomRow,
    width: 100,
  },
  {
    title: 'Pick Destination',
    type: ColumnTypes.renderByCustomRow,
    width: 120,
  },
  {
    title: 'Slot',
    type: ColumnTypes.renderByCustomRow,
    width: 50,
  },
  {
    title: '',
    width: 64,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: '',
    width: 64,
    type: ColumnTypes.renderByCustomRow,
  },
];

export const ActionBarOptions = [
  { icon: IconNames.Help, label: 'Not yet defined.' },
];
