import _ from 'lodash';

import { transformUom } from 'services/uoms';
import {
  resolveImageUrl,
  roundToDecimals,
  transformDateToMomentDate,
} from 'helpers';
import { transformLocation } from 'services/locations';
import { transformToVendorItem, VendorItem } from 'services/vendors';
import { ActiveItemIdState } from 'ui/components/Page/WithSearchResults';
import {
  ItemTrackingType,
  transformItemTrackingType,
  transformInventoryLocationSummaryList,
  InventoryRow,
} from 'services/inventory';
import { transformToTag } from 'services/tags';
import {
  ObjectType,
  transformCustomField,
  transformToRequestCustomField,
} from 'services/customFields';
import { DiscountTypes } from 'services/salesOrders';
import {
  transformDocument,
  transformToRequestDocument,
} from 'services/documents';
import { transformTaxRate } from 'services/taxRates';
import { CompanySettings } from 'services/settings/company';
import { SalesOrderSettings } from 'services/settings/salesOrders';

import {
  transformSaleItem,
  SaleItem,
  defaultSaleItem,
  DefaultSaleItemType,
} from './saleItems';
import {
  Item,
  ItemImage,
  ItemUomConversion,
  Fob,
  ItemLocation,
  ItemReorderPoint,
  ItemReorderPointsAlert,
  SubstituteItem,
  ItemType,
  ItemByLocation,
  BundleItem,
} from './types';
import { resolveItemUomConversions } from './helpers';

export const transformItem = (response: any): Item => {
  const isBundleItem = response.itemType === ItemType.Bundle;
  const isLabourItem = response.itemType === ItemType.Labor;
  const isOverheadItem = response.itemType === ItemType.Overhead;
  const imageLinkList = response.images || [];
  const saleItemList =
    isBundleItem || isLabourItem || isOverheadItem
      ? []
      : response.saleItems || [];
  const bundleItems = isBundleItem ? response.bundleItems || [] : [];
  const customFields = response.customFields || [];
  const responseDefaultImage = imageLinkList.find((i: any) => i.defaultFlag);
  const itemTrackingTypeList: ItemTrackingType[] = response.itemTrackingTypes
    ? response.itemTrackingTypes.map(transformItemTrackingType)
    : [];

  const inventoryLocationSummaryList =
    response.inventoryLocationSummaries || [];
  const totalQty = inventoryLocationSummaryList
    .map((i: any) => i.totalQty)
    .reduce((a: number, b: number) => a + b, 0);

  const vendorItems = response.vendorItems || [];
  const locationList = response.itemLocations || [];
  const reorderPointsList = response.reorderPoints || [];
  const tags = response.tags || [];
  const substituteItems = response.substituteItems || [];
  const itemSubstitutes = response.itemSubstitutes || [];
  const documents = response.documents || [];

  const defaultUom = response.defaultUom
    ? transformUom(response.defaultUom)
    : null;

  const itemUomConversionList = defaultUom
    ? resolveItemUomConversions(
        defaultUom,
        (response.itemUomConversions || []).map(transformToItemUomConversion)
      )
    : [];

  return {
    id: response.id,
    accountingClassId: response.accountingClassId,
    adjustmentAccountId: response.adjustmentAccountId || -1,
    alertNotes: response.alertNotes,
    assetAccountId: response.assetAccountId || -1,
    bundleItems: bundleItems.map(transformBundleItem),
    cost: roundToDecimals(response.cost || 0, 5),
    price: response.price || 0,
    costOfGoodsSoldAccountId: response.costOfGoodsSoldAccountId || -1,
    customFields: customFields.map(transformCustomField),
    dateCreated: transformDateToMomentDate(response.dateCreated),
    dateLastModified: transformDateToMomentDate(response.dateLastModified),
    defaultImage: responseDefaultImage
      ? resolveImageUrl(responseDefaultImage.url)
      : null,
    defaultUom,
    defaultUomId: response.defaultUomId,
    deleted: response.deleted,
    description: response.description,
    details: response.details,
    dimensionUnit: response.dimensionUnit,
    documents: documents.map(transformDocument),
    height: response.height,
    imageLinkList: imageLinkList.map(transformItemImage),
    inventoryLocationSummaryList: inventoryLocationSummaryList.map(
      transformInventoryLocationSummaryList
    ),
    itemTrackingTypeList,
    itemType: response.itemType,
    itemUomConversionList,
    length: response.length,
    locationList: locationList.map(transformItemLocation),
    name: response.name,
    reorderPointsList: reorderPointsList.map(transformItemReorderPoints),
    saleItemList: saleItemList.map(transformSaleItem),
    scrapAccountId: response.scrapAccountId || -1,
    sku: response.sku,
    substituteItems: substituteItems.map(transformSubstituteItem),
    salesTax: response.salesTax ? transformTaxRate(response.salesTax) : null,
    salesTaxId: response.salesTaxId,
    itemSubstitutes: itemSubstitutes.map(transformSubstituteItem),
    tags: tags.map(transformToTag),
    taxable: response.taxable,
    totalQty,
    upc: response.upc,
    url: response.url,
    vendorId: response.vendorId,
    vendorItems: vendorItems.map(transformToVendorItem),
    version: response.version,
    weight: response.weight,
    weightUnit: response.weightUnit,
    width: response.width,
  };
};

const transformItemReorderPoints = (res: any): ItemReorderPoint => {
  const reorderPointAlerts = res.reorderPointAlerts || [];

  return {
    id: res.id,
    version: res.version,
    location: res.location ? transformLocation(res.location) : null,
    reorderPointAlerts: reorderPointAlerts.map(transformToReorderPointAlerts),
    min: res.min,
    max: res.max,
    dailyConsumptionRate: res.dailyConsumptionRate,
    alert: res.alert,
    locationId: res.locationId,
    itemId: res.itemId,
    deleted: res.deleted,
  };
};

const transformSubstituteItem = (res: any): SubstituteItem => {
  return {
    id: res.id,
    createdBy: res.createdBy,
    createdById: res.createdById,
    dateCreated: res.dateCreated,
    dateLastModified: res.dateLastModified,
    deleted: res.deleted,
    itemId: res.itemId,
    lastModifiedBy: res.lastModifiedBy,
    lastModifiedById: res.lastModifiedById,
    substituteItem: res.substituteItem
      ? transformItem(res.substituteItem)
      : null,
    substituteItemId: res.substituteItemId,
    version: res.version,
    checkVersion: res.checkVersion,
  };
};

const transformToReorderPointAlerts = (res: any): ItemReorderPointsAlert => ({
  id: res.id,
  reorderPointId: res.reorderPointId,
  version: res.version,
  contactName: res.contactName,
  email: res.email,
  receiveEmailNotifications: res.receiveEmailNotifications,
  receiveInStockNotifications: res.receiveInStockNotifications,
  deleted: false,
});

const transformToItemUomConversion = (res: any): ItemUomConversion => {
  return {
    id: res.id,
    deleted: false,
    version: res.version,
    uomConversionId: res.uomConversionId,
    factor: res.factor,
    toUomId: res.uomConversion ? res.uomConversion.toUomId : null,
    fromUomId: res.uomConversion ? res.uomConversion.fromUomId : null,
    originalFactor: res.uomConversion ? res.uomConversion.factor : null,
  };
};

export const transformItemLocation = (res: any): ItemLocation => {
  return {
    id: res.id,
    version: res.version,
    itemId: res.itemId,
    parentLocation: res.parentLocation
      ? transformLocation(res.parentLocation)
      : null,
    parentLocationId: res.parentLocationId,
    pickFromLocation: res.pickFromLocation
      ? transformLocation(res.pickFromLocation)
      : null,
    pickFromLocationId: res.pickFromLocationId,
    pickToLocation: res.pickToLocation
      ? transformLocation(res.pickToLocation)
      : null,
    pickToLocationId: res.pickToLocationId,
    receiveLocation: res.receiveLocation
      ? transformLocation(res.receiveLocation)
      : null,
    receiveLocationId: res.receiveLocationId,
    deleted: res.deleted,
  };
};

export const transformItemImage = (res: any): ItemImage => {
  return {
    id: res.id,
    cloned: false,
    defaultFlag: res.defaultFlag,
    deleted: false,
    url: resolveImageUrl(res.url),
    version: res.version,
    name: null,
  };
};

export const transformItemToPostItem = (item: Item): any => {
  const isBundleItem = item.itemType === ItemType.Bundle;
  const isLabourItem = item.itemType === ItemType.Labor;
  const isOverheadItem = item.itemType === ItemType.Overhead;
  const newSaleItems =
    isBundleItem || isLabourItem || isOverheadItem
      ? []
      : item.saleItemList.map(transformToNewSaleItem);
  const newBundleItems = isBundleItem
    ? item.bundleItems.map(transformToRequestBundleItem)
    : null;

  const images = item.imageLinkList
    .filter((i) => !i.deleted)
    .map((i: ItemImage) => transformToNewItemImage(i));

  const conversions: any = item.itemUomConversionList
    .filter((c) => c.factor !== c.originalFactor)
    .map((c) => _.pick(c, ['uomConversionId', 'factor']));

  const vendorItems =
    item.id === ActiveItemIdState.Duplicate
      ? transformToCopyVendorItems(item.vendorItems || [])
      : item.vendorItems || [];

  const itemSubstitutes =
    item.itemSubstitutes.filter((i) => i.id < 0 && !i.deleted) || [];

  const costOfGoodsSoldAccountId =
    item.costOfGoodsSoldAccountId === -1 ? null : item.costOfGoodsSoldAccountId;
  const assetAccountId =
    item.assetAccountId === -1 ? null : item.assetAccountId;
  const adjustmentAccountId =
    item.adjustmentAccountId === -1 ? null : item.adjustmentAccountId;
  const scrapAccountId =
    item.scrapAccountId === -1 ? null : item.scrapAccountId;
  const accountingClassId = item.accountingClassId;

  return {
    accountingClassId,
    adjustmentAccountId,
    alertNotes: item.alertNotes,
    assetAccountId,
    bundleItems: newBundleItems,
    cost: item.cost,
    costOfGoodsSoldAccountId,
    customFields: item.customFields.map((c) =>
      transformToRequestCustomField(c, ObjectType.Item, true)
    ),
    defaultUomId: item.defaultUomId,
    description: item.description,
    details: item.details,
    dimensionUnit: item.dimensionUnit || 'Inches',
    documents: item.documents.map(transformToRequestDocument),
    height: item.height || 0,
    images,
    itemLocations:
      item.itemType === ItemType.Bundle
        ? []
        : item.locationList
            .filter((l) => l.pickFromLocationId)
            .map(transformToRequestLocationItem),
    itemTrackingTypes: item.itemTrackingTypeList
      .filter((t) => !t.deleted)
      .map((t) => _.pick(t, ['trackingTypeId', 'nextValue'])),
    itemType: item.itemType,
    itemUomConversions: conversions,
    itemSubstitutes: itemSubstitutes.map(transformToReuquestItemSubstitutes),
    length: item.length || 0,
    name: item.name,
    reorderPoints: item.reorderPointsList.map((p) =>
      transformToRequestReorderPointsItem(item.id, p)
    ),
    saleItems: newSaleItems,
    scrapAccountId,
    sku: item.sku,
    salesTaxId: item.salesTaxId,
    tags: item.tags.map((t) => ({ name: t.name })),
    taxable: item.taxable,
    upc: item.upc,
    url: item.url,
    vendorItems:
      item.itemType === ItemType.Bundle
        ? []
        : vendorItems.map(transformToVendorItem),
    weight: item.weight || 0,
    weightUnit: item.weightUnit || 'Pounds',
    width: item.width || 0,
  };
};

export const transformItemToPutItem = (item: Item): any => {
  const isBundleItem = item.itemType === ItemType.Bundle;
  const isLabourItem = item.itemType === ItemType.Labor;
  const isOverheadItem = item.itemType === ItemType.Overhead;
  const saleItems: any =
    isBundleItem || isLabourItem || isOverheadItem
      ? []
      : item.saleItemList.map((i) =>
          i.id < 0 ? transformToNewSaleItem(i) : transformToUpdateSaleItem(i)
        );
  const bundleItems = isBundleItem
    ? item.bundleItems.map(transformToRequestBundleItem)
    : null;

  const newImages: any = item.imageLinkList
    .filter((i) => i.id < 0 && !i.deleted)
    .map((i: ItemImage) => transformToNewItemImage(i));
  const updatedImages: any = item.imageLinkList
    .filter((i) => i.id > 0)
    .map((i: ItemImage) => transformToUpdateItemImage(i));

  // if factor is equal to original factor, then we want to delete this conversion from item
  const existingConversions: any = item.itemUomConversionList
    .filter((c) => c.id && c.id > 0)
    .map((c) => ({ ...c, deleted: c.factor === c.originalFactor }));

  // new conversions are those that have a factor that is not equal to original factor and were not previously added
  const newConversions: any = item.itemUomConversionList
    .filter((c) => c.id === null && c.factor !== c.originalFactor)
    .map((c) => _.pick(c, ['uomConversionId', 'factor']));

  const conversions: any = [...existingConversions, ...newConversions];

  // all existing item locations and new ones with pickFrom, pickTo and receive location id
  const locationList = item.locationList.filter(
    (l) =>
      l.pickFromLocationId ||
      l.pickToLocationId ||
      l.receiveLocationId ||
      (l.id && l.id > 0)
  );

  // Item type bundle cannot have itemLocations or vendorItems
  const itemLocations =
    item.itemType === ItemType.Bundle
      ? []
      : locationList.map(transformToRequestLocationItem);

  const vendorItems =
    item.itemType === ItemType.Bundle ? [] : item.vendorItems || [];

  // sending new and/or deleted items
  const itemSubstitutes =
    item.itemSubstitutes.filter((i) => i.id < 0 || i.deleted) || [];
  const substituteItems = item.substituteItems;

  const costOfGoodsSoldAccountId =
    item.costOfGoodsSoldAccountId === -1 ? null : item.costOfGoodsSoldAccountId;
  const assetAccountId =
    item.assetAccountId === -1 ? null : item.assetAccountId;
  const adjustmentAccountId =
    item.adjustmentAccountId === -1 ? null : item.adjustmentAccountId;
  const scrapAccountId =
    item.scrapAccountId === -1 ? null : item.scrapAccountId;
  const accountingClassId = item.accountingClassId;

  return {
    id: item.id,
    accountingClassId,
    adjustmentAccountId,
    alertNotes: item.alertNotes,
    assetAccountId,
    bundleItems,
    cost: item.cost,
    costOfGoodsSoldAccountId,
    customFields: item.customFields.map((c) =>
      transformToRequestCustomField(c, ObjectType.Item, true)
    ),
    defaultUomId: item.defaultUomId,
    deleted: false,
    description: item.description,
    details: item.details,
    dimensionUnit: item.dimensionUnit || 'Inches',
    documents: item.documents.map(transformToRequestDocument),
    height: item.height || 0,
    images: [...newImages, ...updatedImages],
    itemLocations,
    itemType: item.itemType,
    itemUomConversions: conversions,
    itemTrackingTypes: item.itemTrackingTypeList.map((t) => {
      if (t.id < 0) {
        return _.pick(t, ['trackingTypeId', 'nextValue']);
      }

      return _.pick(t, ['id', 'trackingTypeId', 'nextValue', 'deleted']);
    }),
    itemSubstitutes: itemSubstitutes.map(transformToReuquestItemSubstitutes),
    length: item.length || 0,
    name: item.name,
    reorderPoints: item.reorderPointsList.map((p) =>
      transformToRequestReorderPointsItem(item.id, p)
    ),
    saleItems,
    scrapAccountId,
    substituteItems,
    sku: item.sku,
    salesTaxId: item.salesTaxId,
    tags: item.tags.map((t) => ({ name: t.name })),
    taxable: item.taxable,
    upc: item.upc,
    url: item.url,
    vendorItems: vendorItems.map(transformToVendorItem),
    version: item.version,
    weight: item.weight || 0,
    weightUnit: item.weightUnit || 'Pounds',
    width: item.width || 0,
  };
};

const transformToRequestLocationItem = (item: ItemLocation): any => {
  // POST
  if (!item.id || item.id < 0) {
    return {
      parentLocationId: item.parentLocationId,
      pickFromLocationId: item.pickFromLocationId,
      receiveLocationId: item.receiveLocationId,
      pickToLocationId: item.pickToLocationId,
      deleted: item.deleted,
    };
  }

  // PUT
  return {
    id: item.id,
    version: item.version,
    parentLocationId: item.parentLocationId,
    pickFromLocationId: item.pickFromLocationId,
    receiveLocationId: item.receiveLocationId,
    pickToLocationId: item.pickToLocationId,
    deleted: item.deleted,
  };
};

const transformToReuquestItemSubstitutes = (item: SubstituteItem): any => {
  // PUT
  return {
    id: item.id,
    itemId: item.itemId,
    substituteItemId: item.substituteItemId,
    deleted: item.deleted,
  };
};

const transformToRequestReorderPointsItem = (
  itemId: number | null,
  reorderPoint: ItemReorderPoint
): any => {
  // POST
  if (!itemId || itemId < 0) {
    return {
      min: reorderPoint.min,
      max: reorderPoint.max,
      alert: reorderPoint.alert,
      locationId: reorderPoint.locationId,
      dailyConsumptionRate: reorderPoint.dailyConsumptionRate,
      reorderPointAlerts: reorderPoint.reorderPointAlerts
        .map((a) => transformToRequestReorderPointItemAlert(itemId, a))
        .filter((a) => a !== null),
    };
  }

  // PUT
  return {
    id: reorderPoint.id,
    version: reorderPoint.version,
    min: reorderPoint.min,
    max: reorderPoint.max,
    alert: reorderPoint.alert,
    dailyConsumptionRate: reorderPoint.dailyConsumptionRate,
    locationId: reorderPoint.locationId,
    reorderPointAlerts: reorderPoint.reorderPointAlerts
      .map((a) => transformToRequestReorderPointItemAlert(itemId, a))
      .filter((a) => a !== null),
    deleted: reorderPoint.deleted,
  };
};

const transformToRequestReorderPointItemAlert = (
  itemId: number | null,
  alert: ItemReorderPointsAlert
): any => {
  // if new alert does not have data, we don't want to send it
  const shouldNotSendAlert =
    (alert.id === null || alert.id === -1) &&
    !alert.contactName &&
    !alert.email;

  if (shouldNotSendAlert) {
    return null;
  }

  // POST
  if (!itemId || itemId < 0) {
    return {
      contactName: alert.contactName,
      email: alert.email,
      receiveEmailNotifications: alert.receiveEmailNotifications,
      receiveInStockNotifications: alert.receiveInStockNotifications,
    };
  }

  // PUT
  return {
    id: alert.id,
    version: alert.version,
    contactName: alert.contactName,
    deleted: alert.deleted,
    email: alert.email,
    receiveEmailNotifications: alert.receiveEmailNotifications,
    receiveInStockNotifications: alert.receiveInStockNotifications,
  };
};

const transformToNewSaleItem = (saleItem: SaleItem): any => {
  const images = saleItem.imageLinkList
    .filter((i) => !i.deleted)
    .map((i) => transformToNewItemImage(i));
  return {
    accountingClassId: saleItem.accountingClassId,
    alertNotes: saleItem.alertNotes,
    defaultFlag: saleItem.defaultFlag,
    defaultUomId: saleItem.defaultUomId,
    defaultSalesOrderItemType: saleItem.defaultSalesOrderItemType
      ? saleItem.defaultSalesOrderItemType
      : DefaultSaleItemType.Sale,
    description: saleItem.description,
    details: saleItem.details,
    dimensionUnit: saleItem.dimensionUnit || 'Inches',
    height: saleItem.height || 0,
    images,
    incomeAccountId: saleItem.incomeAccountId,
    length: saleItem.length || 0,
    name: saleItem.name,
    price: saleItem.price,
    productTaxCode: saleItem.productTaxCode,
    salesTaxId: saleItem.salesTaxId,
    sku: saleItem.sku,
    tags: saleItem.tags.map((t) => ({ name: t.name })),
    taxable: saleItem.taxable,
    upc: saleItem.upc,
    url: saleItem.url,
    weight: saleItem.weight || 0,
    weightUnit: saleItem.weightUnit || 'Pounds',
    width: saleItem.width || 0,
  };
};

const transformToUpdateSaleItem = (saleItem: SaleItem): any => {
  const newImages = saleItem.imageLinkList
    .filter((i) => !i.deleted && i.id < 0)
    .map((i) => transformToNewItemImage(i));
  const updateImages = saleItem.imageLinkList
    .filter((i) => i.id > 0)
    .map((i) => transformToUpdateItemImage(i));

  return {
    ...transformToNewSaleItem(saleItem),
    id: saleItem.id,
    deleted: saleItem.deleted,
    version: saleItem.version,
    images: [...newImages, ...updateImages],
  };
};

const transformToNewItemImage = (itemImage: ItemImage): any => {
  return {
    url: itemImage.url,
    defaultFlag: itemImage.defaultFlag,
  };
};

const transformToUpdateItemImage = (itemImage: ItemImage): any => {
  return {
    ...transformToNewItemImage(itemImage),
    id: itemImage.id,
    version: itemImage.version,
    deleted: itemImage.deleted,
  };
};

export const transformFob = (response: any): Fob => {
  return {
    id: response.id,
    dateCreated: transformDateToMomentDate(response.dateCreated),
    dateLastModified: transformDateToMomentDate(response.dateLastModified),
    deleted: response.deleted,
    name: response.name,
    readOnly: response.readOnly,
    version: response.version,
  };
};

export const transformToDefaultSaleItem = (
  item: Item,
  soSettings: SalesOrderSettings,
  companySettings: CompanySettings
): SaleItem => {
  return {
    ...defaultSaleItem,
    defaultFlag: true,
    defaultUomId: item.defaultUomId,
    description: item.description,
    details: item.details,
    dimensionUnit: item.dimensionUnit || 'Inches',
    height: item.height || 0,
    length: item.length || 0,
    name: item.name,
    price: item.cost,
    sku: item.sku,
    taxable: item.taxable || false,
    url: item.url,
    upc: item.upc,
    tags: item.tags,
    weight: item.weight || 0,
    weightUnit: item.weightUnit || 'Pounds',
    width: item.width || 0,
    salesTaxId: companySettings.country !== 'US' ? soSettings.salesTaxId : null,
    imageLinkList: item.imageLinkList.map((i, index) => ({
      ...i,
      id: -1 - index,
    })),
    defaultImage: null,
  };
};

export const transformInventoryRowsToExistingItem = (
  inventoryRows: InventoryRow[],
  itemTrackingTypes: ItemTrackingType[],
  existingTrackingTypeIds: number[]
): any => {
  // we are sending only new tracking types
  const payloadItemTrackingTypes = itemTrackingTypes
    .filter((t) => !existingTrackingTypeIds.includes(t.trackingTypeId))
    .map((t) => ({
      trackingTypeId: t.trackingTypeId,
      nextValue: t.nextValue,
    }));

  const payloadInventoryRows: any = inventoryRows.map((r) => ({
    locationId: r.locationId,
    enableTrackingTypeDateCostInputs: null,
    populateTrackingTypeByExistingTrackingInputs: [],
  }));

  inventoryRows.forEach((inventoryRow, inventoryRowIndex) => {
    inventoryRow.trackingGroupList.forEach((trackingGroup) => {
      const payloadTrackingGroup: any = {};
      payloadTrackingGroup.quantity = trackingGroup.quantity;

      const existingTrackingGroupInfos: any[] = [];
      const populateTrackingTypeInputs: any[] = [];

      // text and date tracking
      trackingGroup.trackingInfoList.forEach((trackingInfo) => {
        const payloadTrackingInfo = {
          trackingTypeId: trackingInfo.trackingTypeId,
          value: trackingInfo.value,
        };
        if (existingTrackingTypeIds.includes(trackingInfo.trackingTypeId)) {
          existingTrackingGroupInfos.push(payloadTrackingInfo);
        } else {
          populateTrackingTypeInputs.push(payloadTrackingInfo);
        }
      });

      // serial tracking
      const serialsObject: any = {};
      trackingGroup.serialList.forEach((serialRow) => {
        _.forEach(serialRow.serialNumbers, (serialValue, trackingTypeId) => {
          if (Array.isArray(serialsObject[trackingTypeId])) {
            serialsObject[trackingTypeId].push(serialValue);
          } else {
            serialsObject[trackingTypeId] = [serialValue];
          }
        });
      });

      _.forEach(serialsObject, (serialList, trackingTypeId) => {
        const trackingTypeIdInt = parseInt(trackingTypeId, 10);
        const payloadTrackingInfo = {
          trackingTypeId: trackingTypeIdInt,
          serialNumbers: serialList,
        };

        if (!existingTrackingTypeIds.includes(trackingTypeIdInt)) {
          populateTrackingTypeInputs.push(payloadTrackingInfo);
        }
      });

      payloadTrackingGroup.existingTrackingGroupInfos =
        existingTrackingGroupInfos;
      payloadTrackingGroup.populateTrackingTypeInputs =
        populateTrackingTypeInputs;

      payloadInventoryRows[
        inventoryRowIndex
      ].populateTrackingTypeByExistingTrackingInputs.push(payloadTrackingGroup);
    });
  });

  return {
    itemTrackingTypeInputs: payloadItemTrackingTypes,
    enableTrackingTypeLocationInputs: payloadInventoryRows,
  };
};

const transformToCopyVendorItems = (vendorItems: VendorItem[]): any => {
  return vendorItems.map((vi, index) => ({
    ...vi,
    id: -(index + 1),
    item: null,
    itemId: null,
    version: null,
  }));
};

// BUNDLE ITEMS

export const transformBundleItem = (res: any): BundleItem => {
  let discountType = DiscountTypes.None;
  if (res.discountPercent) {
    discountType = DiscountTypes.Percent;
  }
  if (res.discountFlatAmount) {
    discountType = DiscountTypes.FlatRate;
  }
  return {
    id: res.id,
    itemId: res.itemId,
    saleItemId: res.saleItemId,
    saleItem: res.saleItem ? transformSaleItem(res.saleItem) : null,
    quantity: res.quantity,
    discountFlatAmount: res.discountFlatAmount,
    discountPercent: res.discountPercent,
    discountType: discountType,
    price: res.price,
    deleted: false,
    version: res.version,
  };
};

const transformToRequestBundleItem = (bundleItem: BundleItem): any => {
  const requestBundleItem = {
    saleItemId: bundleItem.saleItemId,
    quantity: bundleItem.quantity,
    discountFlatAmount:
      bundleItem.discountType === DiscountTypes.FlatRate
        ? bundleItem.discountFlatAmount
        : null,
    discountPercent:
      bundleItem.discountType === DiscountTypes.FlatRate
        ? null
        : bundleItem.discountPercent,
  };
  if (bundleItem.id && bundleItem.id > 0) {
    return {
      ...requestBundleItem,
      id: bundleItem.id,
      itemId: bundleItem.itemId,
      version: bundleItem.version,
      deleted: bundleItem.deleted,
    };
  }
  return requestBundleItem;
};

export const tranformItemByLocation = (res: any): ItemByLocation => {
  return {
    id: res.id,
    name: res.name,
    description: res.description,
    defaultImage: res.imageUrl,
  };
};
