import { createApiCall } from 'services/api';

import {
  transformShippingSettings,
  transformShippingPut,
} from './transformations';
import { ShippingSettings } from './types';

export const getShippingSettings = async () => {
  const soSettingsResponse = await createApiCall({
    path: `/v1/shipping_settings`,
    method: 'GET',
  })();

  return transformShippingSettings(soSettingsResponse.data);
};

export const putShippingSettings = async (
  shippingSettings: ShippingSettings
) => {
  const soSettingsResponse = await createApiCall(
    {
      path: `/v1/shipping_settings`,
      method: 'PUT',
      body: transformShippingPut(shippingSettings),
    },
    {
      getMessage: {
        success: () => 'Updated shipping settings',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformShippingSettings(soSettingsResponse.data);
};
