import React, { useState, useCallback } from 'react';
import { Box } from '@mui/material';

import { setAdminAccessToken } from 'services/auth/services';
import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import { isUserExpired, logInAsUser } from 'services/user';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';

import { RemoteSupportLogInModalProps } from './types';
import { resolveModalBody } from './helpers';
import { showNotification } from 'services/api';

const RemoteSupportLogInModal: React.FC<RemoteSupportLogInModalProps> = ({
  user,
  onClose,
  showModal,
  setRedirect,
}) => {
  const [authorizationKey, setAuthorizationKey] = useState('');
  const [showExpirationModal, setShowExpirationModal] = useState(false);
  const [error, setError] = useState(false);

  const handleTextFieldChange = useCallback(
    (e: any) => setAuthorizationKey(e.target.value),
    []
  );

  const handleLoginAsUser = useCallback(async () => {
    if (!user.email) {
      return;
    }

    let token;

    try {
      token = await logInAsUser(user.email, authorizationKey);
      setAdminAccessToken(token);
    } catch {
      // ignore error
    }

    // if token is provided, close modal and redirect
    if (!!token) {
      onClose();
      setRedirect(true);
      window.location.reload();
    }
  }, [user, authorizationKey, onClose, setRedirect]);

  const handleCancelClick = () => {
    onClose();
    setAuthorizationKey('');
    setError(false);
  };

  const onLogInModalApplyClicked = useCallback(() => {
    const expired = isUserExpired(user);

    if (!authorizationKey) {
      setError(true);
      showNotification('Please enter admin key', { variant: 'warning' });
      return;
    }
    if (expired) {
      setShowExpirationModal(expired);
      return;
    }

    handleLoginAsUser();
  }, [user, handleLoginAsUser, authorizationKey]);

  return (
    <>
      <Modal
        open={showModal}
        title="Log In As User"
        applyLabel="Log In"
        onCancelClicked={handleCancelClick}
        onApplyClicked={onLogInModalApplyClicked}
      >
        <Box mb={2}>
          <TextField
            type="password"
            label="Admin Key"
            placeholder="Enter admin key"
            fullWidth
            autoComplete="off"
            required
            onChange={handleTextFieldChange}
            value={authorizationKey}
            error={error}
          />
        </Box>
      </Modal>
      <ConfirmationModal
        open={showExpirationModal}
        title="Account has expired"
        body={resolveModalBody(user)}
        onCancelClicked={() => setShowExpirationModal(false)}
        onConfirmClicked={handleLoginAsUser}
        confirmLabel="OK"
      />
    </>
  );
};

export default RemoteSupportLogInModal;
