import React, { memo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Typography, Grid } from '@mui/material';
import _ from 'lodash';

import { getAlert, hideAlert, isAlertVisible } from 'services/alert/redux';

import { ConfirmationModal } from '../ConfirmationModal';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const AlertModal: React.FC = () => {
  const dispatch = useDispatch();

  const open = useSelector(isAlertVisible);
  const alert = useSelector(getAlert);

  const handleClose = useCallback(() => {
    dispatch(hideAlert());
  }, [dispatch]);

  const alertMessage = (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <Typography color="textSecondary" variant="body1">
          {alert.msg}
        </Typography>
      </Grid>
      {alert.url && (
        <Grid item md={12}>
          <Link
            target="_blank"
            href={alert.url || undefined}
            underline="always"
          >
            Download for error details
          </Link>
        </Grid>
      )}
    </Grid>
  );

  return (
    <ConfirmationModal
      maxWidth="sm"
      open={open}
      title="Alert"
      onConfirmClicked={_.noop}
      onCancelClicked={_.noop}
      body={alertMessage}
      DialogActionsComponent={() => (
        <FBOButton
          onClick={handleClose}
          variant="primary"
          color="positive"
          data-qa="alert-modal-ok"
        >
          OK
        </FBOButton>
      )}
    />
  );
};

export default memo(AlertModal);
