export interface CompanyCardProps {
  search: string;
}

export enum CostingMethod {
  LIFO = 'LIFO',
  FIFO = 'FIFO',
  Average = 'Average',
  Actual = 'Actual',
}
