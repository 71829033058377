import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colorPalette } from 'ui/theme';
import { BackgroundStatus } from 'services/backgroundTasks';

export const useDownloadsPageStyle = makeStyles((theme: Theme) => ({
  page: {
    backgroundColor: '#F9FCFD',
  },
  container: {
    flex: 1,
    paddingTop: theme.spacing(4),
    height: 'fit-content',
    paddingLeft: theme.spacing(4),
  },
  title: {
    width: '100%',
    paddingTop: theme.spacing(4),
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  containerBox: {
    flexDirection: 'column',
    justifyContent: 'space-around',
    flex: 1,
  },
  card: {
    border: 'none',
    backgroundColor: 'white',
    boxShadow:
      '0px 4px 4px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.16)',
  },
  cardRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 16px',
    borderBottom: '1px solid #EBF0F5',
    cursor: 'pointer',
    transition: 'color .2s ease-in-out',
    '&:only-of-type': {
      borderBottom: 'none',
    },
    '&:last-child': {
      borderBottom: 'none',
    },
    '&:hover p, &:hover .MuiSvgIcon-root': {
      color: colorPalette.fishbowlCorporateBlue,
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    color: '#DADADA',
    transition: 'color .2s ease-in-out',
  },
  noDownloadsIcon: {
    color: colorPalette.fishbowlCorporateGray2,
    marginBottom: theme.spacing(4),
    fontSize: 40,
  },
  progressBar: {
    height: '5px',
    width: '100%',
    backgroundColor: '#EBF0F5',
    '& .MuiLinearProgress-bar': {
      background: 'linear-gradient(90deg, #2987EB 0%, #0029F0 100%)',
    },
  },
  historyTable: {
    height: '720px',
    paddingTop: '78px',
    paddingLeft: theme.spacing(4),
    flex: 1,
    '& th': {
      display: 'none',
    },
    '& thead': {
      height: '0px',
    },
  },
}));

export const useDownloadsHistoryPageStyle = makeStyles(() => ({
  card: {
    border: 'none',
    backgroundColor: 'white',
    boxShadow:
      '0px 4px 4px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.16)',
  },
  cardRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 16px',
    borderBottom: '1px solid #EBF0F5',
    cursor: (props: any) =>
      props.row.status === BackgroundStatus.Failed ||
      props.row.status === BackgroundStatus.PartialSuccess
        ? 'pointer'
        : 'auto',
    transition: 'color .2s ease-in-out',
    '&:only-of-type': {
      borderBottom: 'none',
    },
    '&:last-child': {
      borderBottom: 'none',
    },
    '&:hover p, &:hover .MuiSvgIcon-root': {
      color: (props: any) =>
        props.row.status === BackgroundStatus.Failed ||
        props.row.status === BackgroundStatus.PartialSuccess
          ? colorPalette.fishbowlCorporateBlue
          : 'none',
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
