import * as yup from 'yup';

export const yupChannelSettingsSchemaWithAlternates = yup.object().shape({
  posSettings: yup.object().shape({
    defaultLocation: yup.number().required(),
    status: yup.string().required(),
    taxItem: yup.number().required(),
    shippingItem: yup.number().required(),
    shippingTerms: yup.number().required(),
    orderNumberAssignment: yup.string().required(),
  }),
  customerSettings: yup.object().shape({
    defaultPaymentTerms: yup.number().required(),
    priority: yup.number().nullable(),
    salesRepresentative: yup.number().nullable(),
  }),
  orderSettings: yup.object().shape({
    accountingClass: yup.number().nullable(),
    defaultLocation: yup.number().required(),
    status: yup.string().required(),
    orderNumberPrefix: yup.string().nullable(),
    taxItem: yup.number().required(),
    shippingItem: yup.number().required(),
    shippingTerms: yup.number().required(),
    orderNumberAssignment: yup.string().required(),
  }),
  productImportSettings: yup.object().shape({
    accountingClass: yup.number().nullable(),
    dimensionUom: yup.string().required(),
    uom: yup.number().required(),
    weightUom: yup.string().required(),
  }),
});

export const yupChannelSettingsSchema = yup.object().shape({
  customerSettings: yup.object().shape({
    defaultPaymentTerms: yup.number().nullable(),
    priority: yup.number().nullable(),
    salesRepresentative: yup.number().nullable(),
  }),
  orderSettings: yup.object().shape({
    accountingClass: yup.number().nullable(),
    defaultLocation: yup.number().required(),
    status: yup.string().required(),
    orderNumberPrefix: yup.string().nullable(),
    taxItem: yup.number().required(),
    shippingItem: yup.number().required(),
    shippingTerms: yup.number().required(),
    orderNumberAssignment: yup.string().required(),
  }),
  productImportSettings: yup.object().shape({
    accountingClass: yup.number().nullable(),
    dimensionUom: yup.string().required(),
    uom: yup.number().required(),
    weightUom: yup.string().required(),
  }),
});
