import _ from 'lodash';

import {
  getSearches,
  addSearch,
  updateSearch,
  deleteSearch,
} from 'services/user';
import {
  AdvancedSearchFormValues,
  AdvancedSearchReduxFunctions,
} from 'services/search';
import { PaginationDirection } from 'services/url';
import { Location } from 'services/locations';
import { Dates } from 'helpers';
import { MenuItem } from 'ui/components/Menu';
import { IconNames } from 'ui/theme';

export const advancedSearchReduxActions: AdvancedSearchReduxFunctions = {
  add: addSearch,
  delete: deleteSearch,
  update: updateSearch,
  get: getSearches,
};

export const initialUsersFormValues: AdvancedSearchFormValues = {
  email: null,
  nameFirst: null,
  nameLast: null,
  defaultLocationId: null,
  timezone: null,
  deleted: null,
};

export const createDisplayValueMap = (locations: Location[]) => ({
  [userAdvSearchFields.DefaultLocation]: (id: number) =>
    _.get(
      locations.find((l) => l.id === id),
      'name',
      null
    ),
});

export const userAdvSearchFields = {
  DefaultLocation: 'defaultLocation.id',
};

export const displayNameMap = {
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  timezone: 'Timezone',
  deleted: 'Deleted',
};

export const initialPagination = {
  page: 1,
  pageSize: 25,
  sort: {
    sortBy: 'dateLastModified',
    direction: PaginationDirection.Descending,
  },
  totalRows: 25,
};

export enum UsersPageAction {
  ChangeDate,
}

export const createActionBarOptions = (
  onActionClick: (actionType: UsersPageAction, date: Dates) => void
): MenuItem[] => [
  {
    icon: IconNames.Repeat,
    label: 'Change Dates',
    subItems: [
      {
        label: 'Date Created',
        onOptionClick: () =>
          onActionClick(UsersPageAction.ChangeDate, Dates.DateCreated),
      },
      {
        label: 'Date Last Modified',
        onOptionClick: () =>
          onActionClick(UsersPageAction.ChangeDate, Dates.DateLastModified),
      },
    ],
  },
];
