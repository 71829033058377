import * as yup from 'yup';
import moment from 'moment';

import { PricingRule, PricingRuleType } from 'services/pricingRules';

import { PricingRulesCustomersGroup } from './components/PricingRulesCustomers/types';
import { PricingRulesProductsGroup } from './components/PricingRulesProducts/types';

export const IGNORE_ERROR = 'IGNORE';

const validatePricingRulePercentage = (pricingType: PricingRuleType | null) => {
  switch (pricingType) {
    case PricingRuleType.Margin:
      return yup
        .number()
        .lessThan(
          100,
          'Percentage on Margin type pricing rules must be below 100%.'
        )
        .typeError(IGNORE_ERROR);
    case PricingRuleType.Fixed:
      return yup.mixed().typeError(IGNORE_ERROR);
    default:
      return yup.number().typeError(IGNORE_ERROR);
  }
};

export const getYupSchema = (
  pricingRule: PricingRule,
  groupType: PricingRulesCustomersGroup | null,
  productType: PricingRulesProductsGroup | null,
  type: PricingRuleType | null
) => {
  const shape: any = {
    name: yup.string(),
    price: type === PricingRuleType.Fixed ? yup.number() : yup.mixed(),
    customerId:
      groupType === PricingRulesCustomersGroup.Customer
        ? yup.number()
        : yup.mixed(),
    customerTags: yup
      .array()
      .min(groupType === PricingRulesCustomersGroup.Tag ? 1 : 0),
    percent: validatePricingRulePercentage(type),
    saleItemId:
      productType === PricingRulesProductsGroup.Product
        ? yup.number().nullable(true)
        : yup.mixed(),
    itemId:
      productType === PricingRulesProductsGroup.Product
        ? yup.number().nullable(true)
        : yup.mixed(),
    saleItemTags: yup
      .array()
      .min(productType === PricingRulesProductsGroup.Tag ? 1 : 0),
    startDate: yup.date().nullable(true),
  };

  if (pricingRule.minQuantity !== null) {
    shape.maxQuantity = yup.number();
  }

  if (pricingRule.maxQuantity !== null) {
    shape.minQuantity = yup.number();
  }

  //check if start date is valid
  if (!isNaN(pricingRule.startDate ? pricingRule.startDate.getTime() : NaN)) {
    shape.endDate = yup.date().when('startDate', (startDate: Date) => {
      const date = moment(startDate).add(1, 'day').toDate();

      return yup.date().min(date);
    });
  }

  if (pricingRule.endDate) {
    shape.startDate = yup.date();
  }

  return yup.object().shape(shape);
};
