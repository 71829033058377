import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable/types';

export const INVENTORY_COLUMNS: Column[] = [
  {
    title: 'Location',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Type',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'On Hand',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Allocated',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Committed',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Available',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Incoming',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Short',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: '',
    width: 200,
    field: 'default',
    type: ColumnTypes.string,
  },
];
