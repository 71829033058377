import React, { useState, useCallback, memo, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { Paper } from '@mui/material';

import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { Modal } from 'ui/components/Modal/Modal/Modal';
import {
  TrackingType,
  NewTrackingType,
  getTrackingTypes,
  addTrackingType,
  updateTrackingType,
  deleteTrackingTypes,
} from 'services/settings/tracking';

import { TrackingModalProps } from './types';
import { SETTINGS_TAB_ITEM_COLUMNS, defaultTrackingType } from './consts';
import { TrackingTypeModal } from './components';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const TrackingModal: React.FunctionComponent<TrackingModalProps> = (
  props: TrackingModalProps
) => {
  const { isLoadingDependencies, isModalVisible, setIsModalVisible } = props;

  const { items: trackingTypes } = useSelector(getTrackingTypes);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState<boolean>(false);

  const [activeTrackingType, setActiveTrackingType] = useState<
    TrackingType | NewTrackingType
  >(defaultTrackingType);

  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const idsToDisable = [1, 2, 3];

  const previousTrackingType = useMemo(() => {
    if (activeTrackingType as TrackingType) {
      return trackingTypes.find(
        (t) => t.id === (activeTrackingType as TrackingType).id
      );
    }
  }, [activeTrackingType, trackingTypes]);

  const handleAddNewClicked = useCallback(() => {
    setActiveTrackingType(defaultTrackingType);
    setShowModal(true);
  }, []);

  const handleCancelClicked = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleItemClicked = useCallback(
    (trackingTypeId: number) => {
      setActiveTrackingType(
        trackingTypes.find((t) => t.id === trackingTypeId) as TrackingType
      );
      setShowModal(true);
    },
    [trackingTypes]
  );

  const handleSaveClicked = useCallback(async () => {
    if (_.has(activeTrackingType, 'id')) {
      // Lint skip to be removed
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await dispatch(updateTrackingType(activeTrackingType as TrackingType));
    } else {
      // @ts-ignore
      await dispatch(addTrackingType(activeTrackingType));
    }
    setShowModal(false);
  }, [activeTrackingType, dispatch]);

  const handleSelectedItemsChange = useCallback(
    (id: number | number[]) => {
      if (Array.isArray(id)) {
        setSelectedItems(id.filter((x) => !idsToDisable.includes(x)));
        return;
      }

      const isIdAlreadySelected = selectedItems.includes(id);
      if (isIdAlreadySelected) {
        setSelectedItems(_.without(selectedItems, id));
        return;
      }

      setSelectedItems(
        [...selectedItems, id].filter((x) => !idsToDisable.includes(x))
      );
    },
    [selectedItems, setSelectedItems]
  );

  const handleDeleteClicked = useCallback(() => {
    setShowConfirmationModal(true);
  }, []);

  const handleCancelConfirmationModalClicked = useCallback(() => {
    setShowConfirmationModal(false);
  }, []);

  const handleResetClicked = useCallback(() => {
    previousTrackingType
      ? setActiveTrackingType(previousTrackingType)
      : setActiveTrackingType(defaultTrackingType);
  }, [previousTrackingType]);

  const handleApplyConfirmationModalClicked = useCallback(async () => {
    setDeleteLoading(true);
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await dispatch(deleteTrackingTypes(selectedItems));
    setSelectedItems([]);
    setShowConfirmationModal(false);
    setDeleteLoading(false);
  }, [dispatch, selectedItems]);

  const modalActionsComponent = () => {
    return (
      <>
        <FBOButton
          variant="secondary"
          color="neutral"
          size="large"
          onClick={handleDeleteClicked}
          disabled={selectedItems.length < 1}
          data-qa="tracking-modal-delete"
        >
          Delete
        </FBOButton>

        <FBOButton
          variant="primary"
          color="positive"
          size="large"
          onClick={handleAddNewClicked}
          data-qa="tracking-modal-add-new"
        >
          Add New
        </FBOButton>
      </>
    );
  };

  return (
    <Modal
      open={isModalVisible}
      title="Tracking"
      ModalActionsComponent={modalActionsComponent}
      onCancelClicked={() => setIsModalVisible(false)}
      withoutDefaultPadding={true}
      dataQa="tracking-modal"
    >
      <Paper>
        <ItemsTable
          data={trackingTypes}
          columns={SETTINGS_TAB_ITEM_COLUMNS}
          onItemClick={handleItemClicked}
          selectedItems={selectedItems}
          onSelectedChange={handleSelectedItemsChange}
          isLoadingData={isLoadingDependencies}
          dataQa="settings-tracking-table"
          meta={{
            idsToDisable,
          }}
        />
        <TrackingTypeModal
          activeTrackingType={activeTrackingType}
          setActiveTrackingType={setActiveTrackingType}
          showModal={showModal}
          onSaveClicked={handleSaveClicked}
          onCancelClicked={handleCancelClicked}
          onResetClicked={handleResetClicked}
        />
        <ConfirmationModal
          open={showConfirmationModal}
          title="Delete Tracking Types"
          body="This will delete selected Tracking Types?"
          onCancelClicked={handleCancelConfirmationModalClicked}
          onConfirmClicked={handleApplyConfirmationModalClicked}
          confirmButtonRed
          confirmLabel={'Delete'}
          cancelLabel={'Cancel'}
          isLoading={deleteLoading}
        />
      </Paper>
    </Modal>
  );
};

export default memo(TrackingModal);
