import { IconNames } from 'ui/theme';
import { MenuItem, MenuItemVariant } from 'ui/components/Menu';
import { SalesOrderStatus } from 'services/salesOrders';
import { PermissionType } from 'services/permissions';

export const createActionBarOptions = (
  id: number | null,
  salesOrderStatus: SalesOrderStatus,
  onDeleteClick: () => void,
  onDuplicateClick: () => void,
  onDuplicateAsCreditReturnClick: () => void,
  onShowReport: () => void,
  onEmailClick: () => void,
  onQuoteClick: () => void,
  onCancelClicked: () => void,
  onCreatePOClick: () => void,
  quoteDisabled?: boolean
): MenuItem[] => [
  {
    icon: IconNames.FBOReportSmall,
    label: 'Show Report',
    onOptionClick: onShowReport,
    disabled: !id || id < 0,
    separateAfter: true,
  },
  {
    icon: IconNames.FBOEmailSmall,
    label: 'Send Email',
    onOptionClick: onEmailClick,
    disabled: !id || id < 0,
    separateAfter: true,
  },
  {
    icon: IconNames.FBOAddCircleSmall,
    label: 'Create Purchase Order',
    separateAfter: true,
    permissions: [
      PermissionType.SalesOrderEdit,
      PermissionType.SalesOrderCreate,
    ],
    onOptionClick: onCreatePOClick,
  },
  {
    label: 'Duplicate Order',
    onOptionClick: onDuplicateClick,
    icon: IconNames.FBODuplicateSmall,
    disabled: !id || id < 0,
    separateAfter: true,
    permissions: [
      PermissionType.SalesOrderEdit,
      PermissionType.SalesOrderCreate,
    ],
  },
  {
    label: 'Duplicate Order As Credit Return',
    onOptionClick: onDuplicateAsCreditReturnClick,
    icon: IconNames.FBOReturnSmall,
    disabled: !id || id < 0,
    separateAfter: true,
    permissions: [
      PermissionType.SalesOrderEdit,
      PermissionType.SalesOrderCreate,
    ],
  },
  {
    label: 'Shipping Quote',
    onOptionClick: onQuoteClick,
    icon: IconNames.FBODocumentSmall,
    disabled: quoteDisabled || salesOrderStatus === SalesOrderStatus.Cancelled,
    separateAfter: true,
  },
  {
    label: 'Cancel Sales Order',
    onOptionClick: onCancelClicked,
    icon: IconNames.FBOCloseCircleSmall,
    disabled: salesOrderStatus === SalesOrderStatus.Cancelled,
    separateAfter: true,
  },
  {
    label: 'Delete Order',
    onOptionClick: onDeleteClick,
    icon: IconNames.FBODeleteSmall,
    permissions: [PermissionType.SalesOrderDelete],
    disabled:
      salesOrderStatus === SalesOrderStatus.Issued ||
      salesOrderStatus === SalesOrderStatus.Fulfilled ||
      salesOrderStatus === SalesOrderStatus.Cancelled,
    dataQa: 'sale-order-delete',
    variant: MenuItemVariant.Danger,
  },
];
export const noTaxAddedBodyContent = `No tax is added to the company or item(s) on this \n sales order, even though it was marked taxable.`;
export const noTaxAddedModalTitle = `No Tax Added`;
