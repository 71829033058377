import React, { useState, useMemo } from 'react';
import moment from 'moment';
import { TableCell } from '@mui/material';

import { useGetIntlDateFormatString } from 'helpers';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { IconNames } from 'ui/theme';
import { ExportHistory } from 'services/accounting';
import { StatusLabel } from 'ui/components/Status/StatusLabel';
import { ExportStatus } from './types';
import HistoryDetailsModal from './HistoryDetailsModal';

const CustomRow: React.FC<RenderCustomRowProps<ExportHistory>> = (props) => {
  const { row } = props;

  const intlDateFormat = useGetIntlDateFormatString();
  const [openHistoryDetailsModal, setOpenHistoryDetailsModal] = useState(false);
  const formattedDateTime = useMemo(() => {
    if (row.dateCreated) {
      const formattedDate = moment(row.dateCreated).format(intlDateFormat);

      let formattedTime = row.dateCreated.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      });

      // Check if formattedTime already contains AM or PM
      const alreadyHasAmPm =
        formattedTime.includes('AM') || formattedTime.includes('PM');

      if (!alreadyHasAmPm) {
        formattedTime += row.dateCreated.getHours() >= 12 ? ' PM' : ' AM';
      }

      return `${formattedDate} - ${formattedTime}`;
    }
    return '';
  }, [row.dateCreated, intlDateFormat]);

  return (
    <>
      <TableCell data-qa={`Date-${row.dateCreated}`}>
        {row.dateCreated ? moment(row.dateCreated).format(intlDateFormat) : ''}
      </TableCell>
      <TableCell data-qa={`Time-${row.dateCreated}`}>
        {row.dateCreated?.toLocaleTimeString()}
      </TableCell>
      <TableCell data-qa={`Memo-${row.type}`}>
        {row.type === 'Success' ? (
          <StatusLabel status={ExportStatus.Success} />
        ) : (
          <StatusLabel status={ExportStatus.Failure} />
        )}
      </TableCell>

      <TableCell data-qa={`Details-${row.id}`}>
        <FBOButton
          variant="tertiary"
          color="neutral"
          size="medium"
          icon={IconNames.FBOCaratRight}
          onClick={() => {
            setOpenHistoryDetailsModal(true);
          }}
          data-qa={`details-link-${row.id}`}
          component={'a'}
        />
      </TableCell>
      <HistoryDetailsModal
        open={openHistoryDetailsModal}
        onClose={() => {
          setOpenHistoryDetailsModal(false);
        }}
        ParentHistoryId={row.id}
        title={formattedDateTime}
      />
    </>
  );
};

export default React.memo(CustomRow);
