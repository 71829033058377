import { MenuItem } from 'ui/components/Menu';
import { IconNames } from 'ui/theme';

export const createActionBarOptions = (
  id: number | null,
  onShowReport: () => void
): MenuItem[] => [
  {
    icon: IconNames.FBOReportSmall,
    label: 'Show Report',
    onOptionClick: onShowReport,
    disabled: !id || id < 0,
  },
];
