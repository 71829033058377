import { Dates } from 'helpers';
import { PermissionType } from 'services/permissions';
import { Vendor } from 'services/vendors';
import { MenuItem, MenuItemVariant } from 'ui/components/Menu';
import { IconNames } from 'ui/theme';

export enum VendorPageAction {
  Import,
  Export,
  CustomFields,
  ChangeDate,
}

export enum VendorPageRowAction {
  Delete,
}

export const createActionBarOptions = (
  handleActionClick: (type: VendorPageRowAction) => void,
  vendor: Vendor
): MenuItem[] => [
  {
    label: 'Delete Vendor',
    onOptionClick: () => handleActionClick(VendorPageRowAction.Delete),
    icon: IconNames.FBODeleteSmall,
    permissions: vendor.deleted
      ? [PermissionType.Undelete]
      : [PermissionType.VendorsDelete],
    variant: MenuItemVariant.Danger,
  },
];

export const createVendorsSearchActionBarOptions = (
  onActionClick: (actionType: VendorPageAction, date?: Dates) => void,
  csvDisabled?: boolean
): MenuItem[] => [
  {
    label: 'Import Vendors',
    icon: IconNames.FBOImport,
    onOptionClick: () => onActionClick(VendorPageAction.Import),
    permissions: [PermissionType.VendorsCreate, PermissionType.VendorsEdit],
    separateAfter: true,
    disabled: csvDisabled,
  },
  {
    label: 'Export Vendors',
    icon: IconNames.FBOExport,
    onOptionClick: () => onActionClick(VendorPageAction.Export),
    separateAfter: true,
    disabled: csvDisabled,
  },
  {
    label: 'Custom Fields',
    icon: IconNames.FBOReportSmall,
    onOptionClick: () => onActionClick(VendorPageAction.CustomFields),
    permissions: [PermissionType.VendorsCreate, PermissionType.VendorsEdit],
    separateAfter: true,
  },
  {
    icon: IconNames.FBORecycleSmall,
    label: 'Change Dates',
    subItems: [
      {
        label: 'Date Created',
        onOptionClick: () =>
          onActionClick(VendorPageAction.ChangeDate, Dates.DateCreated),
        separateAfter: true,
      },
      {
        label: 'Date Last Modified',
        onOptionClick: () =>
          onActionClick(VendorPageAction.ChangeDate, Dates.DateLastModified),
      },
    ],
  },
];
