import * as yup from 'yup';

const withMixed = yup.object().shape({
  factor: yup.mixed().notRequired(),
  toUomId: yup.mixed().notRequired(),
});

const withNumber = yup.object().shape({
  factor: yup.number().positive().required(),
  toUomId: yup.number().required(),
});

const withLazy = yup.lazy((value: any) => {
  if (value.deleted !== true) {
    return withNumber;
  }
  return withMixed;
});

export const uomSchema = yup.object().shape({
  name: yup.string().required(),
  abbreviation: yup.string().typeError('Enter abbreviation').required(),
  toConversions: yup.array(withLazy),
  fromConversions: yup.array(withLazy),
});
