import { State } from 'app/redux';
import { createApiAction } from 'services/api';
import {
  StartAction,
  SuccessAction,
  FailureAction,
} from 'services/api/redux/types';

export const searchActionTypes = {
  getFetchStart: (module: keyof State) => `${module}_search/fetch_search_start`,
  getFetchSuccess: (module: keyof State) =>
    `${module}_search/fetch_search_success`,
  getFetchFailure: (module: keyof State) =>
    `${module}_search/fetch_search_failure`,
  getSearchStart: (module: keyof State) => `${module}_search/add_search_start`,
  getSearchSuccess: (module: keyof State) =>
    `${module}_search/add_search_success`,
  getSearchFailure: (module: keyof State) =>
    `${module}_search/add_search_failure`,
  getUpdateStart: (module: keyof State) =>
    `${module}_search/update_search_start`,
  getUpdateSuccess: (module: keyof State) =>
    `${module}_search/update_search_success`,
  getUpdateFailure: (module: keyof State) =>
    `${module}_search/update_search_failure`,
  getDeleteStart: (module: keyof State) =>
    `${module}_search/delete_search_start`,
  getDeleteSuccess: (module: keyof State) =>
    `${module}_search/delete_search_success`,
  getDeleteFailure: (module: keyof State) =>
    `${module}_search/delete_search_failure`,
};

// SEARCH ACTIONS

const fetchSearchActionStart = (module: keyof State) => (): StartAction => ({
  type: searchActionTypes.getFetchStart(module),
  payload: null,
});
const fetchSearchActionSuccess =
  (module: keyof State) =>
  (payload: any): SuccessAction => {
    return {
      type: searchActionTypes.getFetchSuccess(module),
      payload,
    };
  };
const fetchSearchActionFailure =
  (module: keyof State) =>
  (error: string): FailureAction => ({
    type: searchActionTypes.getFetchFailure(module),
    payload: { error },
  });

const addSearchActionStart = (module: keyof State) => (): StartAction => ({
  type: searchActionTypes.getSearchStart(module),
  payload: null,
});
const addSearchActionSuccess =
  (module: keyof State) =>
  (payload: any): SuccessAction => {
    return {
      type: searchActionTypes.getSearchSuccess(module),
      payload,
    };
  };
const addSearchActionFailure =
  (module: keyof State) =>
  (error: string): FailureAction => ({
    type: searchActionTypes.getSearchFailure(module),
    payload: { error },
  });

const updateSearchActionStart = (module: keyof State) => (): StartAction => ({
  type: searchActionTypes.getUpdateStart(module),
  payload: null,
});
const updateSearchActionSuccess =
  (module: keyof State) =>
  (payload: any): SuccessAction => {
    return {
      type: searchActionTypes.getUpdateSuccess(module),
      payload,
    };
  };
const updateSearchActionFailure =
  (module: keyof State) =>
  (error: string): FailureAction => ({
    type: searchActionTypes.getUpdateFailure(module),
    payload: { error },
  });

const deleteSearchActionStart = (module: keyof State) => (): StartAction => ({
  type: searchActionTypes.getDeleteStart(module),
  payload: null,
});
const deleteSearchActionSuccess =
  (module: keyof State) =>
  (payload: any): SuccessAction => {
    return {
      type: searchActionTypes.getDeleteSuccess(module),
      payload,
    };
  };
const deleteSearchActionFailure =
  (module: keyof State) =>
  (error: string): FailureAction => ({
    type: searchActionTypes.getDeleteFailure(module),
    payload: { error },
  });

// SEARCH ACTION CREATORS

export const createFetchSearch = (module: keyof State) => () =>
  createApiAction(
    {
      path: `/v1/users/search/${module}`,
      method: 'GET',
    },
    fetchSearchActionStart(module),
    fetchSearchActionSuccess(module),
    fetchSearchActionFailure(module)
  );

export const createAddSearch = (module: keyof State) => (moduleSearch: any) =>
  createApiAction(
    {
      path: `/v1/users/search/${module}`,
      method: 'POST',
      body: moduleSearch,
    },
    addSearchActionStart(module),
    addSearchActionSuccess(module),
    addSearchActionFailure(module)
  );

export const createUpdateSearch =
  (module: keyof State) => (moduleSearch: any) =>
    createApiAction(
      {
        path: `/v1/users/search/${module}`,
        method: 'PUT',
        body: moduleSearch,
      },
      updateSearchActionStart(module),
      updateSearchActionSuccess(module),
      updateSearchActionFailure(module)
    );

export const createDeleteSearch = (module: keyof State) => (searchId: number) =>
  createApiAction(
    {
      path: `/v1/users/search/${searchId}`,
      method: 'DELETE',
      additionalData: { id: searchId },
    },
    deleteSearchActionStart(module),
    deleteSearchActionSuccess(module),
    deleteSearchActionFailure(module)
  );
