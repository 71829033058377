import _ from 'lodash';
import {
  Address,
  AddressValidation,
  BillToShipToAddress,
} from 'services/addresses';
import {
  initialPurchaseOrder,
  PurchaseOrder,
  PurchaseOrderItemTypes,
} from 'services/purchaseOrders';
import { Location } from 'services/locations';
import { Vendor } from 'services/vendors';

import { PurchaseOrderSettings } from 'services/settings/purchaseOrders';

export const setVerifiedShipToAddress = (
  addressValidation: AddressValidation,
  setPurchaseOrder: React.Dispatch<React.SetStateAction<PurchaseOrder>>
) => {
  setPurchaseOrder((old) => ({
    ...old,
    shipToCity: addressValidation.validated.city,
    shipToStreet: addressValidation.validated.street,
    shipToStreet2: addressValidation.validated.street2,
    shipToState: addressValidation.validated.state,
    shipToPostalCode: addressValidation.validated.postalCode,
    shipToName: addressValidation.validated.name,
    shipToCountry: addressValidation.validated.country,
    shipToCompanyName: addressValidation.validated.companyName,
    shipToResidential: addressValidation.validated.residential,
    shipToAttention: addressValidation.validated.attention,
    shipToVerified: true,
  }));
};

export const setVerifiedBillToAddress = (
  addressValidation: AddressValidation,
  setPurchaseOrder: React.Dispatch<React.SetStateAction<PurchaseOrder>>
) => {
  setPurchaseOrder((old) => ({
    ...old,
    vendorAddressCity: addressValidation.validated.city,
    vendorAddressStreet: addressValidation.validated.street,
    vendorAddressStreet2: addressValidation.validated.street2,
    vendorAddressState: addressValidation.validated.state,
    vendorAddressPostalCode: addressValidation.validated.postalCode,
    vendorAddressName: addressValidation.validated.name,
    vendorAddressCountry: addressValidation.validated.country,
    vendorAddressCompanyName: addressValidation.validated.companyName,
    vendorAddressResidential: addressValidation.validated.residential,
    vendorAddressAttention: addressValidation.validated.attention,
    vendorAddressVerified: true,
  }));
};

export const setShipToAsBillToAddress = (
  setPurchaseOrder: React.Dispatch<React.SetStateAction<PurchaseOrder>>
) => {
  setPurchaseOrder((old) => ({
    ...old,
    shipToName: old.vendorAddressName,
    shipToStreet: old.vendorAddressStreet,
    shipToStreet2: old.vendorAddressStreet2,
    shipToCity: old.vendorAddressCity,
    shipToState: old.vendorAddressState,
    shipToCountry: old.vendorAddressCountry,
    shipToPostalCode: old.vendorAddressPostalCode,
    shipToCompanyName: old.vendorCompanyName,
    shipToResidential: old.vendorResidential,
    shipToAttention: old.vendorAttention,
    shipToVerified: old.vendorAddressVerified,
  }));
};

export const transformInitialPurchaseOrder = (
  lo: Location | null,
  number: string | null,
  buyerId: number | null,
  id: number | null,
  locationId: number | null,
  shippingTermId: number | null,
  poSettings: PurchaseOrderSettings
): PurchaseOrder => {
  return {
    ...initialPurchaseOrder,
    shipToName: _.get(lo, 'address.name'),
    shipToStreet: _.get(lo, 'address.street'),
    shipToStreet2: _.get(lo, 'address.street2'),
    shipToCity: _.get(lo, 'address.city'),
    shipToState: _.get(lo, 'address.state'),
    shipToCountry: _.get(lo, 'address.country'),
    shipToPostalCode: _.get(lo, 'address.postalCode'),
    shipToCompanyName: _.get(lo, 'address.companyName'),
    shipToResidential: _.get(lo, 'address.residential'),
    shipToAttention: _.get(lo, 'address.attention'),
    shipToVerified: _.get(lo, 'address.verified'),
    purchaseTax: poSettings.purchaseTax,
    purchaseTaxId: poSettings.purchaseTaxId,
    number,
    buyerId,
    id,
    locationId,
    shippingTermId,
  };
};

export const getBillToAddress = (
  purchaseOrder: PurchaseOrder
): BillToShipToAddress => {
  return {
    name: purchaseOrder.vendorAddressName,
    street: purchaseOrder.vendorAddressStreet,
    street2: purchaseOrder.vendorAddressStreet2,
    city: purchaseOrder.vendorAddressCity,
    state: purchaseOrder.vendorAddressState,
    country: purchaseOrder.vendorAddressCountry,
    postalCode: purchaseOrder.vendorAddressPostalCode,
    companyName: purchaseOrder.vendorCompanyName,
    residential: purchaseOrder.vendorResidential,
    attention: purchaseOrder.vendorAttention,
    verified: purchaseOrder.vendorAddressVerified,
  };
};

export const getShipToAddress = (
  purchaseOrder: PurchaseOrder,
  vendor: Vendor | null
): BillToShipToAddress => {
  const poHasCreditReturnItems =
    purchaseOrder.purchaseOrderItemList.length > 0 &&
    purchaseOrder.purchaseOrderItemList.every(
      (item) =>
        item.purchaseOrderItemType === PurchaseOrderItemTypes.CreditReturn
    );

  const vendorShipToAddress = vendor
    ? vendor.addresses.find((address) => address.defaultShipTo === true)
    : null;

  if (poHasCreditReturnItems && vendorShipToAddress) {
    return transformVendorAddressToShipToAddress(vendorShipToAddress);
  }

  return transformLocationAddressToShipToAddress(purchaseOrder);
};

const transformVendorAddressToShipToAddress = (old: Address) => {
  return {
    ...old,
    name: old.name,
    street: old.street,
    street2: old.street2,
    city: old.city,
    state: old.state,
    country: old.country,
    postalCode: old.postalCode,
    companyName: old.companyName,
    residential: old.residential,
    attention: old.attention,
    verified: old.verified,
  };
};

const transformLocationAddressToShipToAddress = (old: PurchaseOrder) => {
  return {
    ...old,
    name: old.shipToName,
    street: old.shipToStreet,
    street2: old.shipToStreet2,
    city: old.shipToCity,
    state: old.shipToState,
    country: old.shipToCountry,
    postalCode: old.shipToPostalCode,
    companyName: old.shipToCompanyName,
    residential: old.shipToResidential,
    attention: old.shipToAttention,
    verified: old.shipToVerified,
  };
};
