import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { useFlags } from 'helpers/useFlags';

import { activeUserHasPermission } from 'services/user/redux';

import { SecureRouteProps } from './types';

const SecureRoute: React.FC<SecureRouteProps> = (props) => {
  const { permissions = [], redirectToHome = false, featureFlag } = props;

  const canAccess = useSelector(activeUserHasPermission(permissions));

  const flags = useFlags();

  const enabledFeatureFlag = featureFlag ? flags[featureFlag] : true;

  if (!enabledFeatureFlag) {
    return <Navigate to={'/'} replace />;
  }

  if (!canAccess) {
    return <Navigate to={'/'} replace />;
  }

  if (redirectToHome) {
    return <Navigate to={'/'} replace />;
  }

  return <Outlet />;
};

export default SecureRoute;
