import React, { memo, useMemo } from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';

import { DIMENSIONS_UNITS } from 'services/items';
import { activeUserHasPermission } from 'services/user/redux';

import { TextFieldDimensionsProps } from './types';
import { MenuItem } from 'ui/components/Menu';

import {
  useDimensionsStyle,
  LengthInput,
  WidthInput,
  HeightInput,
} from './styled';
import { TextField } from 'ui/components/TextField/TextField';
import _ from 'lodash';

const dimensionOptions: MenuItem[] = DIMENSIONS_UNITS.map((u) => ({
  label: u.name,
  shortName: u.shortName,
}));

const TextFieldDimensions = (props: TextFieldDimensionsProps) => {
  const {
    lengthValue,
    widthValue,
    heightValue,
    onLengthChange,
    onWidthChange,
    onHeightChange,
    onMenuChange,
    error,
    dimension,
    permissions = [],
    label = 'Dimensions',
  } = props;

  const classes = useDimensionsStyle(props);

  const canEdit = useSelector(activeUserHasPermission(permissions));

  const selectedDimensionIndex = useMemo(() => {
    if (!dimension) {
      return null;
    }
    return dimensionOptions.findIndex(
      (o) => o.label.toLowerCase() === dimension.toLowerCase()
    );
  }, [dimension]);

  if (!canEdit) {
    const dimension = _.get(
      dimensionOptions[selectedDimensionIndex!],
      'shortName',
      ''
    );
    const resolvedValue = `${lengthValue || 0} x  ${widthValue || 0} x  ${
      heightValue || 0
    }  ${dimension}`;

    return (
      <Box display="flex" justifyContent="flex-start" position="relative">
        <Box className={classes.labelContainer}>
          <Typography className={classes.labelDisabled}>Dimensions</Typography>
        </Box>
        <TextField disabled value={resolvedValue} />
      </Box>
    );
  }

  return (
    <Box display="flex" justifyContent="flex-start" position="relative">
      {!!label && (
        <Box className={classes.labelContainer}>
          <Typography className={classes.label}>{label}</Typography>
        </Box>
      )}
      <Tooltip title="Length" placement="bottom">
        <LengthInput
          type="number"
          value={lengthValue}
          onChange={onLengthChange}
          error={error}
          dataQa="item-length"
        />
      </Tooltip>
      <Typography className={classes.separator} color="textSecondary">
        x
      </Typography>
      <Tooltip title="Width" placement="bottom">
        <WidthInput
          type="number"
          value={widthValue}
          onChange={onWidthChange}
          error={error}
          dataQa="item-width"
        />
      </Tooltip>
      <Typography className={classes.separator} color="textSecondary">
        x
      </Typography>
      <Tooltip title="Height" placement="bottom">
        <HeightInput
          type="number"
          value={heightValue}
          onTextChange={onHeightChange}
          onMenuChange={onMenuChange}
          options={dimensionOptions}
          selectedOptionIndex={selectedDimensionIndex}
          error={error}
          inputProps={{
            'data-qa': 'item-height' as any,
          }}
          legacyStyle
        />
      </Tooltip>
    </Box>
  );
};

export default memo(TextFieldDimensions);
