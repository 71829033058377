import moment from 'moment';
import {
  getSearches,
  addSearch,
  updateSearch,
  deleteSearch,
} from 'services/classes';
import {
  AdvancedSearchFormValues,
  AdvancedSearchReduxFunctions,
} from 'services/search';
import { PaginationDirection } from 'services/url';

export const advancedSearchReduxActions: AdvancedSearchReduxFunctions = {
  add: addSearch,
  delete: deleteSearch,
  update: updateSearch,
  get: getSearches,
};

export const initialASFormValues: AdvancedSearchFormValues = {
  name: null,
};

export const initialPagination = {
  page: 1,
  pageSize: 25,
  sort: {
    sortBy: 'id',
    direction: PaginationDirection.Descending,
  },
  totalRows: 25,
};

export const displayNameMap = {
  name: 'Name',
  dateCreatedFrom: 'Date Created After',
  dateCreatedTo: 'Date Created Before',
};

export const createDisplayValueMap = (format: string) => ({
  dateCreatedFrom: (date: string) => (date ? moment(date).format(format) : ''),
  dateCreatedTo: (date: string) => (date ? moment(date).format(format) : ''),
});
