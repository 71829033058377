import moment from 'moment';

import {
  SalesOrder,
  SalesOrderItem,
  SalesOrderStatus,
  SalesOrderItemTypes,
  SalesOrderItemStatus,
  DiscountTypes,
  SalesOrderEmail,
  SalesOrderPayment,
  SalesOrderTransactionType,
  GeneratePaymentInfo,
  PaymentStatus,
  SalesOrderEmailStatus,
  SalesOrderPrintStatus,
} from './types';

export const initialSalesOrder: SalesOrder = {
  accountingClassId: null,
  billToCity: null,
  billToCountry: null,
  billToName: null,
  billToState: null,
  billToStreet: null,
  billToStreet2: null,
  billToPostalCode: null,
  billToResidential: false,
  billToCompanyName: null,
  billToAttention: null,
  billToVerified: false,
  billToEmail: null, // @beta: additionally added for testing
  billToPhone: null, // @beta: additionally added for testing
  carrierId: null,
  carrierServiceId: null,
  channelSource: null,
  customerId: null,
  customer: null,
  customerPONumber: null,
  customFields: [],
  dateCreated: null,
  dateExpires: null,
  dateFulfilled: null,
  dateIssued: null,
  dateLastModified: null,
  dateDue: null,
  dateScheduled: moment().format('YYYY-MM-DD'),
  deleted: false,
  documents: [],
  dropShipPurchaseOrders: [],
  multiCurrencyDiscountTotal: 0,
  multiCurrencyGrandTotal: 0,
  multiCurrencyOrderLevelDiscountTotal: 0,
  multiCurrencyPaymentTotal: 0,
  multiCurrencySubtotal: 0,
  multiCurrencyTaxTotal: 0,
  homeCurrency: null,
  homeCurrencyId: null,
  exchangeCurrency: null,
  exchangeCurrencyId: null,
  exchangeRate: null,
  emails: null,
  emailStatus: null,
  fobPointId: null,
  grandTotal: null,
  id: null,
  locationId: null,
  location: null,
  margin: null,
  marginPercent: null,
  markupPercent: null,
  markup: null,
  notes: null,
  number: null,
  orderLevelDiscountFlatAmount: null,
  orderLevelDiscountPercent: null,
  paymentTerm: null,
  paymentTermId: null,
  paymentTotal: null,
  payments: [],
  phone: null,
  priorityId: null,
  printStatus: null,
  picks: [],
  representativeId: null,
  representative: null,
  salesOrderItemList: [],
  salesItems: [],
  salesTax: null,
  salesTaxId: null,
  status: SalesOrderStatus.Estimate,
  shipToResidential: false,
  shippingTermId: null,
  shipToCity: null,
  shipToCountry: null,
  shipToName: null,
  shipToState: null,
  shipToStreet: null,
  shipToStreet2: null,
  shipToPostalCode: null,
  shipToCompanyName: null,
  shipToAttention: null,
  shipToVerified: false,
  shipToEmail: null, // @beta: additionally added for testing
  shipToPhone: null, // @beta: additionally added for testing
  shipments: [],
  salesOrderBundleItems: [],
  stockStatus: null,
  store: null,
  receipt: null,
  vendorPONumber: null,
  version: null,
};

export const newSalesOrderItem = (
  itemType: SalesOrderItemTypes
): SalesOrderItem => {
  return {
    id: null,
    cost: null,
    deleted: false,
    description: null,
    discount: { value: null, type: DiscountTypes.Percent },
    discountTotal: 0,
    hasPricingRule: false,
    isFiveDecimals: false,
    exchangeCurrency: null,
    exchangeCurrencyId: null,
    exchangeRate: null,
    multiCurrencyDiscountTotal: 0,
    multiCurrencyTaxTotal: 0,
    multiCurrencyTotal: 0,
    multiCurrencySubTotal: 0,
    // does not exist on BE
    multiCurrencyItemPrice: null,
    lineNumber: 0,
    name: null,
    notes: null,
    price: null,
    quantity: null,
    quantityFulfilled: null,
    saleItem: null,
    saleItemId: null,
    salesOrderId: null,
    salesOrderItemType: itemType,
    salesOrder: null,
    status: SalesOrderItemStatus.Entered,
    subTotal: 0,
    tax: null,
    taxable: false,
    taxId: null,
    taxRate: null,
    taxTotal: 0,
    total: 0,
    uomId: null,
    version: null,
    countyTaxAmount: 0,
    countyTaxRate: 0,
    stateTaxAmount: 0,
    stateTaxRate: 0,
    cityTaxAmount: 0,
    cityTaxRate: 0,
    specialDistrictTaxAmount: 0,
    specialDistrictTaxRate: 0,
  };
};

export const initialSalesOrderEmail: SalesOrderEmail = {
  to: null,
  cc: null,
  bcc: null,
  subject: null,
  body: null,
};

export const initialSalesOrderPayment: SalesOrderPayment = {
  id: null,
  amount: null,
  checkVersion: false,
  createdBy: null,
  createdById: null,
  creditCardType: null,
  customer: null,
  customerId: null,
  data: {},
  date: null,
  dateCreated: null,
  dateLastModified: null,
  expirationMonth: null,
  expirationYear: null,
  lastFour: null,
  lastModifiedBy: null,
  lastModifiedById: null,
  multiCurrencyAmount: null,
  nameOnCard: null,
  originalPaymentId: null,
  paymentTypeId: null,
  paymentType: null,
  referenceNumber: null,
  salesOrderId: null,
  status: PaymentStatus.Pending,
  transactionId: null,
  transactionType: SalesOrderTransactionType.Charge,
  version: null,
  refunded: null,
  remainingForRefund: null,
};

export const initialGeneratePaymentInfo: GeneratePaymentInfo = {
  amount: null,
  dueDate: null,
  salesOrderId: null,
};

export const EMAIL_STATUSES: SalesOrderEmailStatus[] = [
  {
    name: 'Need to Send',
    value: 'NeedToSend',
  },
  {
    name: 'Email Sent',
    value: 'EmailSent',
  },
  {
    name: 'Not Set',
    value: 'NotSet',
  },
];

export const PRINT_STATUSES: SalesOrderPrintStatus[] = [
  {
    name: 'Need to Print',
    value: 'NeedToPrint',
  },
  {
    name: 'Print Completed',
    value: 'PrintComplete',
  },
  {
    name: 'Not Set',
    value: 'NotSet',
  },
];
