import * as yup from 'yup';

const requiredString = {
  is: true,
  then: yup.string().required(),
};

const moreThanZero = {
  is: true,
  then: yup.number().required().moreThan(0),
};

export const quotesYupSchema = yup.object().shape({
  shipFromAddress: yup.object().shape({
    name: yup.string().required(),
    street: yup.string().required(),
    city: yup.string().required(),
    state: yup.string().required(),
    country: yup.string().required(),
    postalCode: yup.string().required(),
  }),
  shipToAddress: yup.object().shape({
    name: yup.string().required(),
    street: yup.string().required(),
    city: yup.string().required(),
    state: yup.string().required(),
    country: yup.string().required(),
    postalCode: yup.string().required(),
  }),
  recipientType: yup.mixed().when('containsAlcohol', requiredString),
  dryIceWeight: yup.mixed().when('containsDryIce', moreThanZero),
  uom: yup.mixed().when('containsDryIce', requiredString),
  codMethod: yup.mixed().when('cod', requiredString),
  codCurrency: yup.mixed().when('cod', requiredString),
  codAmount: yup.mixed().when('cod', moreThanZero),
  signature: yup.mixed().when('signatureConfirmation', requiredString),
});

export const yupQuoteCartonsSchema = yup.object().shape({
  weight: moreThanZero.then,
  height: moreThanZero.then,
  width: moreThanZero.then,
  length: moreThanZero.then,
});
