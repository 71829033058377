import { Address, initialAddress } from 'services/addresses';
import { SalesOrderShippingQuote } from 'services/integrations/shipping';
import { SalesOrder } from 'services/salesOrders';

export const transformShipToAddress = (salesOrder: SalesOrder): Address => {
  return {
    ...initialAddress,
    name: salesOrder.shipToName,
    country: salesOrder.shipToCountry,
    companyName: salesOrder.shipToCompanyName,
    postalCode: salesOrder.shipToPostalCode,
    residential: salesOrder.shipToResidential,
    city: salesOrder.shipToCity,
    street: salesOrder.shipToStreet,
    street2: salesOrder.shipToStreet2,
    state: salesOrder.shipToState,
  };
};

export const transformShipToAddressFromQuote = (
  shippingQuote: SalesOrderShippingQuote
): Address => {
  return {
    ...initialAddress,
    name: shippingQuote.shipToAddress.name,
    country: shippingQuote.shipToAddress.country,
    companyName: shippingQuote.shipToAddress.companyName,
    postalCode: shippingQuote.shipToAddress.postalCode,
    residential: shippingQuote.shipToAddress.residential,
    city: shippingQuote.shipToAddress.city,
    street: shippingQuote.shipToAddress.street,
    street2: shippingQuote.shipToAddress.street2,
    state: shippingQuote.shipToAddress.state,
  };
};
