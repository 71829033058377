import React, { useMemo, useState, useCallback, useEffect } from 'react';
import _ from 'lodash';

import { Pagination } from 'services/search';
import {
  getSerialItemTrackingNextValue,
  SerialRow as SerialRowType,
} from 'services/inventory';
import { ItemsTable } from 'ui/components/Table/ItemsTable';

import { SerialTableProps } from './types';
import { initialPagination } from './consts';
import { SerialHeader } from './components';
import {
  clearSerialRow,
  addEmptySerialRows,
  getSerialsFromFile,
  removeEmptyRows,
  autoAssignHandler,
  isCommitted,
  transformToColumnsWithStatus,
  committedSerials,
  sortSerialList,
  duplicateSerialNumberIds,
} from './helpers';
import { SerialImportModal } from './components/SerialImportModal';
import { CycleCounts } from './components/CycleCounts/CycleCounts';
import { CycleCountSerialRow } from './components/CycleCountSerialRow';
import { showNotification } from 'services/api';
import { themeRestyle } from 'ui/theme';
import { Box } from '@mui/material';

const CycleCountSerialTable: React.FC<SerialTableProps> = (props) => {
  const {
    serialList,
    setSerialList = _.noop,
    itemTrackingTypes,
    minSerialQuantity = 0,
    selectedRows = [],
    onSelectedRowsChanged = _.noop,
    title = '',
    withoutTitleBar = false,
    status,
    customStatus,
    customStatusStyle,
    emptyTableText,
    canEditRow = false,
    canSelectRow = false,
    disableAutoAssign = false,
    allowSerialNumberImport = false,
    rowErrors = [],
    setQtyErrorMessage = _.noop,
    isLoading,
    setIsLoading,
    item,
    setItem,
    showDuplicates = false,
    duplicateArray = [],
    readOnly = false,
    alreadySorted,
    headerMargin,
    sx = {},
  } = props;

  const [pagination, setPagination] = useState<Pagination>(initialPagination);
  const [search, setSearch] = useState<string | null>(null);
  const [activeFile, setActiveFile] = useState<File | null>(null);
  const [activeTrackingTypeId, setActiveTrackingTypeId] = useState<
    number | null
  >(null);
  const [autoAssignModalVisible, setAutoAssignModalVisible] = useState(false);
  const [cyclingDown, setCyclingDown] = useState(false);
  const [disableDeleteButton, setDisableDeleteButton] = useState(false);
  const [disableImportButton, setDisableImportButton] = useState(false);
  const [shouldSortSerialList, setShouldSortSerialList] = useState(true);

  const duplicateIds = useMemo(() => {
    return showDuplicates
      ? duplicateSerialNumberIds(serialList, duplicateArray)
      : null;
  }, [serialList, duplicateArray]);

  const handleSelectedItemsChanges = useCallback(
    (id: number | number[]) => {
      if (Array.isArray(id)) {
        onSelectedRowsChanged(
          id.filter((num) => !isCommitted(num, serialList))
        );
        return;
      }

      const isIdAlreadySelected = selectedRows.includes(id);
      if (isIdAlreadySelected) {
        onSelectedRowsChanged(_.without(selectedRows, id));
        return;
      }

      onSelectedRowsChanged(
        [...selectedRows, id].filter((num) => !isCommitted(num, serialList))
      );
    },
    [selectedRows, onSelectedRowsChanged, serialList]
  );

  useEffect(() => {
    if (!alreadySorted && shouldSortSerialList && serialList?.length > 0) {
      sortSerialList(serialList);
      setShouldSortSerialList(false);
    } else {
      setShouldSortSerialList(false);
    }
  }, [serialList]);

  const filteredSerialList = useMemo(() => {
    if (!search) {
      return serialList;
    }

    return serialList.filter((row) => {
      return Object.values(row.serialNumbers).some(
        (number) => number && number.includes(search)
      );
    });
  }, [serialList, search]);

  const columns = useMemo(
    () => transformToColumnsWithStatus(itemTrackingTypes),
    [itemTrackingTypes]
  );

  const paginatedSerialList = useMemo(
    () =>
      filteredSerialList.slice(
        (pagination.page - 1) * pagination.pageSize,
        pagination.pageSize * pagination.page
      ),
    [pagination, filteredSerialList]
  );

  const handleSelectAllEnter = useCallback(() => {
    const newSelectedItems = paginatedSerialList.map((d) => d.id);
    handleSelectedItemsChanges(newSelectedItems);
  }, [paginatedSerialList, handleSelectedItemsChanges]);

  useEffect(() => {
    setPagination((p) => ({
      ...p,
      pageSize: pagination.pageSize,
      totalRows: filteredSerialList.length,
    }));
  }, [filteredSerialList, pagination.pageSize]);

  useEffect(() => {
    if (minSerialQuantity >= serialList.length) {
      setCyclingDown(false);
      return;
    }
    if (minSerialQuantity < serialList.length) {
      setCyclingDown(true);
      return;
    }
  }, [minSerialQuantity, serialList]);

  useEffect(() => {
    //if we have too many rows being shown
    if (minSerialQuantity < serialList.length) {
      // Remove empty rows and let the user remove the rest
      const reducedSerialList = removeEmptyRows(serialList, canSelectRow);
      setSerialList(reducedSerialList);
      setPagination({
        ...initialPagination,
        page: pagination.page,
        pageSize: pagination.pageSize,
        totalRows: reducedSerialList.length,
      });
      return;
    }

    if (minSerialQuantity > serialList.length) {
      //prepend empty rows to visible rows array
      const numberOfRowsNeeded = minSerialQuantity - serialList.length;
      // Add empty rows
      const extendedSerialList = addEmptySerialRows(
        serialList,
        numberOfRowsNeeded,
        itemTrackingTypes
      );
      setSerialList(extendedSerialList);
      setPagination({
        ...initialPagination,
        pageSize: pagination.pageSize,
        totalRows: extendedSerialList.length,
      });
    }
  }, [minSerialQuantity]);

  useEffect(() => {
    setDisableDeleteButton(
      serialList.length - selectedRows.length < minSerialQuantity
    );
  }, [selectedRows, minSerialQuantity, serialList]);

  useEffect(() => {
    setDisableImportButton(committedSerials(serialList)?.length > 0);
  }, [serialList]);

  useEffect(() => {
    if (minSerialQuantity < committedSerials(serialList)?.length) {
      setQtyErrorMessage('Smaller than committed amount');
    } else if (serialList.length > minSerialQuantity) {
      const remainingRows = serialList.length - minSerialQuantity;
      if (remainingRows === 1) {
        setQtyErrorMessage(`Please delete ${remainingRows} row below to cycle`);
      } else
        setQtyErrorMessage(
          `Please delete ${remainingRows} rows below to cycle`
        );
    } else {
      setQtyErrorMessage('');
    }
  }, [serialList, minSerialQuantity]);

  const handleClearRow = useCallback(
    (_index, row) => {
      const serialIndex = serialList.findIndex((item) => item.id === row.id);
      const emptySerialRow = clearSerialRow(serialIndex, serialList);
      setSerialList([
        ...serialList.slice(0, serialIndex),
        emptySerialRow,
        ...serialList.slice(serialIndex + 1),
      ]);
      return;
    },
    [serialList, setSerialList]
  );

  const handleDeleteSelectedRows = useCallback(() => {
    let tempSerials: SerialRowType[] = _.cloneDeep(serialList);
    selectedRows.forEach((id: number) => {
      const indexToDelete = tempSerials.findIndex((serial) => serial.id === id);

      tempSerials = [
        ...tempSerials.slice(0, indexToDelete),
        ...tempSerials.slice(indexToDelete + 1),
      ];
    });
    setSerialList(tempSerials);
    onSelectedRowsChanged([]);
  }, [selectedRows, serialList]);

  const handleSerialListChange = useCallback(
    (changedPage: SerialRowType[]) => {
      const tempSerials = _.cloneDeep(serialList);
      changedPage.forEach((row) => {
        tempSerials[tempSerials.findIndex((serial) => serial.id === row.id)] =
          row;
      });

      setSerialList(tempSerials);
    },
    [pagination, setSerialList]
  );

  const handleAutoAssign = useCallback(
    async (trackingId: number, trackingTypeId: number) => {
      setIsLoading && setIsLoading(true);
      const index =
        item?.itemTrackingTypeList.findIndex(
          (t) => t.trackingTypeId === trackingTypeId
        ) || 0;
      try {
        if (trackingId < 0) {
          const d = autoAssignHandler(
            serialList.length,
            itemTrackingTypes[0].nextValue
          );
          setSerialList(d || []);

          let value = '0';
          if (d) {
            value = d[d?.length - 1].serialNumbers['3'];
          }

          if (setItem)
            setItem((prevItem) => ({
              ...prevItem,
              itemTrackingTypeList: [
                ...prevItem.itemTrackingTypeList.slice(0, index),
                {
                  ...prevItem.itemTrackingTypeList[index],
                  nextValue: value.toString(),
                },
                ...prevItem.itemTrackingTypeList.slice(index + 1),
              ],
            }));
        } else {
          const value = await getSerialItemTrackingNextValue(
            trackingId,
            serialList.length
          );

          setSerialList((old: SerialRowType[]) =>
            old.map((i, index) => {
              if (i.committed) {
                return i;
              } else {
                return {
                  ...i,
                  serialNumbers: {
                    ...i.serialNumbers,
                    [trackingTypeId]: value[index],
                  },
                };
              }
            })
          );
        }
      } catch {
        // Ignore error
      }
      setIsLoading && setIsLoading(false);
      setAutoAssignModalVisible(true);
    },
    [serialList, setSerialList, setIsLoading]
  );

  const handleModalApplyClicked = useCallback(async () => {
    try {
      if (!activeFile || !activeTrackingTypeId) {
        return;
      }

      const importedSerials: string[] = (await getSerialsFromFile(
        activeFile
      )) as string[];

      const importedSerialRows: SerialRowType[] = importedSerials.map(
        (serial, index) => {
          return {
            id: -1 * (index + 1),
            committed: false,
            serialNumbers: {
              [activeTrackingTypeId]: serial,
            },
          };
        }
      );
      if (importedSerialRows.length < minSerialQuantity) {
        const rowsNeeded = minSerialQuantity - importedSerialRows.length;
        // Add empty rows
        const extendedSerialList = addEmptySerialRows(
          importedSerialRows,
          rowsNeeded,
          itemTrackingTypes
        );
        setSerialList(extendedSerialList);
        setPagination({
          ...initialPagination,
          pageSize: pagination.pageSize,
          totalRows: extendedSerialList.length,
        });
      } else {
        setSerialList(importedSerialRows);
        setPagination({
          ...initialPagination,
          pageSize: pagination.pageSize,
          totalRows: importedSerialRows.length,
        });
      }

      setActiveFile(null);
      setActiveTrackingTypeId(null);
    } catch {
      showNotification(
        'There was a problem importing the file, please check the file and try again.',
        {
          variant: 'error',
        }
      );
      return;
    }
    showNotification('Successfully imported serials', {
      variant: 'success',
    });
  }, [activeFile, activeTrackingTypeId, setSerialList, minSerialQuantity]);

  const handleModalCancelClicked = useCallback(() => {
    setActiveTrackingTypeId(null);
    setActiveFile(null);
  }, [setActiveTrackingTypeId]);

  return (
    <>
      {!withoutTitleBar && (
        <SerialHeader
          title={title}
          status={status}
          customStatus={customStatus}
          customStatusStyle={customStatusStyle}
          itemTrackingTypes={itemTrackingTypes}
          handleAutoAssign={handleAutoAssign}
          handleSelectAllEnter={handleSelectAllEnter}
          disableAutoAssign={disableAutoAssign}
          canSelectRow={false}
          search={search}
          allowSerialNumberImport={allowSerialNumberImport}
          setActiveTrackingTypeId={setActiveTrackingTypeId}
          setSearch={setSearch}
          isLoading={isLoading}
          autoAssignModalVisible={autoAssignModalVisible}
          setAutoAssignModalVisible={setAutoAssignModalVisible}
          selectedItems={selectedRows}
          handleDeleteSelectedRows={handleDeleteSelectedRows}
          showDeleteButton={cyclingDown && !readOnly}
          disableDeleteButton={disableDeleteButton}
          disableDeleteTooltip={
            disableDeleteButton
              ? 'Over selecting is not allowed. Please ensure that you have the correct amount selected to delete.'
              : ''
          }
          disableImportButton={disableImportButton}
          disableImportTooltip={
            disableImportButton
              ? 'Importing serial numbers is not allowed when there are committed serials. Please ensure that all orders are finished before proceeding.'
              : ''
          }
          hideSelectButton
          sx={{
            padding: '0 !important',
            margin: headerMargin,
          }}
        />
      )}
      <ItemsTable
        sx={sx}
        columns={columns}
        data={paginatedSerialList}
        setData={handleSerialListChange}
        RenderCustomRow={CycleCountSerialRow}
        meta={{
          canEditRow,
          showDuplicates,
          isCycleCountSerialTable: true,
          duplicateIds,
        }}
        onAction={handleClearRow}
        pagination={pagination}
        onPaginationChange={setPagination}
        selectedItems={selectedRows}
        onSelectedChange={handleSelectedItemsChanges}
        selectableItems={cyclingDown && !readOnly}
        containerHeight={serialList.length > 11 ? 700 : undefined}
        emptyTableText={emptyTableText || 'Add new rows by pressing "Add Rows"'}
        rowErrors={rowErrors}
        dataQa="serial-table"
        showEnhancedTablePaginationActions={true}
        showSelectedCount={false}
        isCycleCountOrScrap
      />
      {!readOnly && (
        <Box marginLeft={themeRestyle.spacing(2)}>
          <CycleCounts
            serials={serialList}
            quantity={minSerialQuantity}
            selectedRows={selectedRows}
            showSelection={cyclingDown}
          />
        </Box>
      )}
      <SerialImportModal
        open={!!activeTrackingTypeId}
        activeFile={activeFile}
        handleModalApplyClicked={handleModalApplyClicked}
        handleModalCancelClicked={handleModalCancelClicked}
        setActiveFile={setActiveFile}
      />
    </>
  );
};

export default React.memo(CycleCountSerialTable);
