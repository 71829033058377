export interface TaxRate {
  id: number | null;
  name: string | null;
  code: string | null;
  dateCreated: Date | null;
  dateLastModified: Date | null;
  percentage: number | null;
  amount: number | null;
  taxType: TaxType;
  agency: string | null;
  agencyId: number | null;
  version: number | null;
  deleted: boolean;
  channel?: Channel | null;
  mappedTaxName?: string | null;
  channelTaxRate?: string | null;
  accountingId?: string | null;
  channelTaxType?: ChannelTaxType | null;
}

export enum TaxType {
  percentage = 'Percentage',
  flatRate = 'Flat Rate',
}
export enum Channel {
  None = 'NONE',
  Xero = 'XERO',
  Qbo = 'QBO',
}
export enum ChannelTaxType {
  Sales = 'SALES',
  Purchase = 'PURCHASE',
  Both = 'BOTH',
}
