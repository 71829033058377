import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { Box, Typography, FormControlLabel, Checkbox } from '@mui/material';

import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { IconNames } from 'ui/theme';
import {
  deleteReportPreset,
  fetchReportPresets,
  getReportThemes,
  putReportPreset,
  ReportId,
  ReportParameters,
  ReportPreset,
  ReportTheme,
} from 'services/reports';
import { getActiveUser } from 'services/user';
import { Errors, validateYup } from 'services/forms/validation';
import { useHandleCheckboxChange } from 'services/forms/hooks';

import {
  CustomerHistoryFilters,
  CycleCountFilters,
  InventoryOnHandFilters,
  SalesOrderFilters,
  InventoryValuationSummaryFilters,
  ItemPriceListFilters,
  PickFilters,
  PurchaseOrderFilters,
  PackingListFilters,
  ShippingInvoiceFilters,
  ReceivingListFilters,
  TotalItemSalesFilters,
  ReorderFilters,
  VendorFulfillmentHistoryFilters,
  PurchaseOrderSummaryFilters,
  BarcodeFilters,
  OnHandExpirationDateFilters,
  LocationBarcodeFilters,
  InventoryEventHistoryFilters,
  ItemBarcodeLabelFilters,
  ItemBarcodeOneOffFilters,
} from './components';
import { ReportFiltersProps, ReportsViewerSidebarProps } from './types';
import { useReportsViewerSidebarStyle } from './styled';
import { ReportsPresetModal } from './components/ReportsPresetModal';
import { transformToInitialPreset } from './transformations';
import { ThemeBuilderViewer } from './components/ThemeBuilder';
import { InventoryAvailabilityFilter } from './components/InventoryAvailabilityFilters';
import MarginFilters from './components/MarginFilters/MarginFilters';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import ConsumptionFilter from './components/ConsumptionFilters/ConsumptionFilter';
import BOLFilters from './components/BOLFilters/BOLFilters';
import { useFlags } from 'helpers/useFlags';
import SalesOrderSummaryFiltersV2 from './components/SalesOrderSummaryFilters/SalesOrderSummaryFiltersV2';
const ReportsViewerSidebar: React.FC<ReportsViewerSidebarProps> = (props) => {
  const {
    report,
    onGenerate,
    setIsLoading,
    defaultParams = {},
    autoGenerate = false,
    params,
    setReportTheme,
  } = props;

  const { user: activeUser } = useSelector(getActiveUser);
  const activeUserId = activeUser ? activeUser.id! : 0;
  const flags = useFlags();

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);
  const [presetModalVisbility, setPresetModalVisibility] = useState(false);
  const [themeBuilderVisibility, setThemeBuilderVisibility] = useState(false);
  const [themes, setThemes] = useState<ReportTheme[]>([]);
  const [presets, setPresets] = useState<ReportPreset[]>([]);
  const [errors, setErrors] = useState<Errors>({});
  const [presetLoaded, setPresetLoaded] = useState<boolean>(false);

  const classes = useReportsViewerSidebarStyle();

  const [activePreset, setActivePreset] = useState<ReportPreset>(
    transformToInitialPreset(report, defaultParams)
  );

  useEffect(() => {
    (async () => {
      setPresetLoaded(false);
      setIsLoading(true);

      if (activeUserId !== 0) {
        const resPresets = await fetchReportPresets(
          activeUserId || -1,
          report.reportId
        );
        setPresets(resPresets);

        const defaultPreset = resPresets.find((p) => p.defaultFlag);

        let initialActivePreset = transformToInitialPreset(
          report,
          defaultParams
        );

        // set default preset as active preset
        if (defaultPreset) {
          initialActivePreset = {
            ...defaultPreset,
            parameters: { ...defaultPreset.parameters, ...defaultParams },
          };
        }

        setActivePreset(initialActivePreset);

        // get themes
        fetchReportThemes();

        if (autoGenerate && resPresets.length) {
          if (report && report.validation) {
            const valid = validateYup(
              initialActivePreset.parameters,
              report.validation,
              setErrors
            );

            if (!valid) {
              setIsLoading(false);
              return;
            }
          }
          onGenerate(initialActivePreset);
        }

        setIsLoading(false);
      }
      setPresetLoaded(true);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report, activeUserId]);

  useEffect(() => {
    setReportTheme(
      themes.find((c) => c.id === activePreset.parameters.reportThemeId) || null
    );
  }, [setReportTheme, activePreset.parameters.reportThemeId, themes]);

  const fetchReportThemes = useCallback(async () => {
    try {
      const resThemes = await getReportThemes(report.reportId);
      setThemes(resThemes);
    } catch {
      // ignore error
    }
  }, [report.reportId]);

  const handleGenerateClicked = useCallback(() => {
    if (report && report.validation) {
      const valid = validateYup(
        activePreset.parameters,
        report.validation,
        setErrors
      );

      if (!valid) {
        return;
      }
    }

    onGenerate(activePreset);
  }, [report, activePreset, onGenerate]);

  const setParameters = useCallback(
    (parameters: React.SetStateAction<ReportParameters>) => {
      if (typeof parameters === 'function') {
        setActivePreset((old) => ({
          ...old,
          parameters: parameters(old.parameters),
        }));
        return;
      }

      setActivePreset((old) => ({
        ...old,
        parameters,
      }));
    },
    []
  );

  const ReportFilters = useCallback(
    (filterProps: ReportFiltersProps) => {
      if (!report) {
        return null;
      }

      switch (report.reportId) {
        case ReportId.Items:
          return <InventoryOnHandFilters {...filterProps} />;
        case ReportId.SalesOrder:
          return <SalesOrderFilters {...filterProps} />;
        case ReportId.CustomerHistory:
          return <CustomerHistoryFilters {...filterProps} />;
        case ReportId.CycleCount:
          return <CycleCountFilters {...filterProps} />;
        case ReportId.InventoryValuation:
          return <InventoryValuationSummaryFilters {...filterProps} />;
        case ReportId.ItemPriceList:
          return <ItemPriceListFilters {...filterProps} />;
        case ReportId.Pick:
          return <PickFilters {...filterProps} />;
        case ReportId.PurchaseOrder:
          return <PurchaseOrderFilters {...filterProps} />;
        case ReportId.PackingList:
          return <PackingListFilters {...filterProps} />;
        case ReportId.ShippingInvoice:
          return <ShippingInvoiceFilters {...filterProps} />;
        case ReportId.ReceivingList:
          return <ReceivingListFilters {...filterProps} />;
        case ReportId.TotalItemSales:
          return <TotalItemSalesFilters {...filterProps} />;
        case ReportId.Reorder:
          return <ReorderFilters {...filterProps} />;
        case ReportId.VendorFulfillmentHistory:
          return <VendorFulfillmentHistoryFilters {...filterProps} />;
        case ReportId.PurchaseOrderSummary:
          return <PurchaseOrderSummaryFilters {...filterProps} />;
        case ReportId.SalesOrderSummary:
          return <SalesOrderSummaryFiltersV2 {...filterProps} />;

        case ReportId.Barcode:
          return <BarcodeFilters {...filterProps} />;
        case ReportId.OnHandByExpiration:
          return <OnHandExpirationDateFilters {...filterProps} />;
        case ReportId.LocationBarcodeReport:
          return <LocationBarcodeFilters {...filterProps} />;
        case ReportId.InventoryEventHistory:
          return <InventoryEventHistoryFilters {...filterProps} />;
        case ReportId.ItemBarcodeLabel:
          return <ItemBarcodeLabelFilters {...filterProps} />;
        case ReportId.ItemBarcodeOneOffReport:
          return <ItemBarcodeOneOffFilters {...filterProps} />;
        case ReportId.InventoryAvailabilityReport:
          return <InventoryAvailabilityFilter {...filterProps} />;
        case ReportId.MarginReport:
          return <MarginFilters {...filterProps} />;
        case ReportId.ConsumptionReport:
          return (
            <ConsumptionFilter {...filterProps} presetLoaded={presetLoaded} />
          );
        case ReportId.BOLReport:
          return <BOLFilters {...filterProps} />;
        default:
          return null;
      }
    },
    [report, presetLoaded, flags]
  );

  const openModal = useCallback(() => {
    setPresetModalVisibility(true);
  }, []);

  const closeModal = useCallback(() => {
    setPresetModalVisibility(false);
  }, []);

  const openThemeBuilder = useCallback(() => {
    setThemeBuilderVisibility(true);
  }, []);

  const closeThemeBuilder = useCallback(() => {
    setThemeBuilderVisibility(false);
    fetchReportThemes();
  }, [fetchReportThemes]);

  const openDeleteModal = useCallback(() => {
    setDeleteModalVisible(true);
  }, []);

  const closeDeleteModal = useCallback(() => {
    setDeleteModalVisible(false);
  }, []);

  const handlePresetChange = useCallback(
    (e: any, value: ReportPreset | null) => {
      if (value) {
        // if preset from backend doesn't have some of the parameters, create valid state with default params
        const parameters: ReportParameters = {};
        Object.keys(report!.parameters).forEach((p) => {
          parameters[p] = value.parameters[p] || report!.parameters[p];
        });

        setActivePreset({
          ...value,
          parameters: { ...value.parameters, ...defaultParams },
        });
      } else {
        setActivePreset(transformToInitialPreset(report!, defaultParams));
      }
    },
    [report, defaultParams]
  );

  const handlePresetUpdate = useCallback(async () => {
    try {
      const newPresets = await putReportPreset(activeUserId, activePreset);
      setPresets(newPresets);

      // update active preset state
      setActivePreset(newPresets.find((p) => p.id === activePreset.id)!);
    } catch {
      // ignore error
    }
  }, [activePreset, activeUserId, setPresets]);

  const handlePresetDelete = async () => {
    setDeleteModalLoading(true);
    try {
      await deleteReportPreset(activeUserId, activePreset.id!);
      const index = presets.findIndex((p) => p.id === activePreset.id);
      setPresets([...presets.slice(0, index), ...presets.slice(index + 1)]);
      setActivePreset(transformToInitialPreset(report!, defaultParams));
    } catch {
      // ignore error
    }
    setDeleteModalVisible(false);
    setDeleteModalLoading(false);
  };

  const togglePresetDefaultFlag = useHandleCheckboxChange(setActivePreset);

  const handleThemeChange = useCallback(
    (e: any, val: ReportTheme | null) => {
      setParameters((old) => ({ ...old, reportThemeId: val ? val.id : null }));
    },
    [setParameters]
  );

  const selectedTheme = useMemo(
    () =>
      themes.find((c) => c.id === activePreset.parameters.reportThemeId) ||
      null,
    [themes, activePreset.parameters.reportThemeId]
  );

  return (
    <>
      <Box className={classes.sidebar}>
        <Box className={classes.header} display="flex" flexDirection="column">
          <Box mt={1}>
            <Autocomplete
              name="newPreset"
              placeholder="New preset"
              label="Preset"
              options={presets}
              getOptionLabel={(preset: ReportPreset) => preset.name || ''}
              value={activePreset}
              isOptionEqualToValue={(preset: ReportPreset) =>
                preset.id === activePreset.id
              }
              onChange={handlePresetChange}
              dataQa="report-presets"
              renderOption={(ReportsViewerSidebarProps, option) => (
                <li {...ReportsViewerSidebarProps}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Typography variant="body2">{option.name}</Typography>
                    {option.defaultFlag && (
                      <Typography variant="body2" color="primary">
                        Default
                      </Typography>
                    )}
                  </Box>
                </li>
              )}
            />
          </Box>
          {activePreset.id === null && (
            <Box>
              <FBOButton
                variant="secondary"
                color="positive"
                size="small"
                data-qa="report-create-preset"
                onClick={openModal}
                fullWidth
              >
                Create New Preset
              </FBOButton>
            </Box>
          )}

          {activePreset.id && (
            <Box display="flex" flexDirection="column" mt={1}>
              <Box>
                <FormControlLabel
                  className="redesign"
                  control={
                    <Checkbox
                      className="redesign"
                      checked={activePreset.defaultFlag}
                      name="defaultFlag"
                      color="primary"
                      onChange={togglePresetDefaultFlag}
                    />
                  }
                  label={
                    activePreset.defaultFlag ? 'Default' : 'Set as default'
                  }
                />
              </Box>
              <Box mt={1} display="flex" justifyContent="space-between">
                <Box mr={1} flexGrow={1}>
                  <FBOButton
                    variant="secondary"
                    color="positive"
                    size="small"
                    data-qa="report-update-preset"
                    onClick={handlePresetUpdate}
                    fullWidth
                  >
                    Update
                  </FBOButton>
                </Box>
                <FBOButton
                  variant="secondary"
                  color="negative"
                  size="small"
                  data-qa="report-delete-preset"
                  onClick={openDeleteModal}
                  icon={IconNames.TrashCan}
                />
              </Box>
            </Box>
          )}

          {report.reportId === ReportId.SalesOrder && (
            <Box display="flex" alignItems="center" mt="16px">
              <Box mr={1} flexGrow={1}>
                <Autocomplete
                  label="Theme"
                  placeholder="Select theme"
                  options={themes}
                  onChange={handleThemeChange}
                  value={selectedTheme}
                  getOptionLabel={(theme: ReportTheme) => theme.name || ''}
                  fullWidth
                />
              </Box>
              <Box>
                <FBOButton
                  variant="primary"
                  color="neutral"
                  size="small"
                  data-qa="report-add-or-edit-theme-button"
                  onClick={openThemeBuilder}
                >
                  {activePreset.parameters.reportThemeId ? 'Edit' : 'Add'}
                </FBOButton>
              </Box>
            </Box>
          )}
        </Box>
        <Box className={classes.content}>
          <ReportFilters
            parameters={activePreset.parameters}
            setParameters={setParameters}
            errors={errors}
          />
        </Box>

        <Box className={classes.footer}>
          <FBOButton
            variant="primary"
            color="positive"
            size="large"
            data-qa="generate-report"
            onClick={handleGenerateClicked}
            fullWidth
          >
            Generate Report
          </FBOButton>
        </Box>
      </Box>

      <ReportsPresetModal
        visibility={presetModalVisbility}
        activePreset={activePreset}
        setActivePreset={setActivePreset}
        presets={presets}
        setPresets={setPresets}
        onClose={closeModal}
      />

      <ThemeBuilderViewer
        visibility={themeBuilderVisibility}
        onClose={closeThemeBuilder}
        reportName={report.reportId}
        params={params}
        selectedReportTheme={selectedTheme}
      />

      <ConfirmationModal
        open={deleteModalVisible}
        title={`Delete preset "${activePreset.name}"`}
        body={`This will delete preset and all related preset options, are you sure?`}
        onCancelClicked={closeDeleteModal}
        onConfirmClicked={handlePresetDelete}
        isLoading={deleteModalLoading}
        confirmLabel="Delete"
        confirmButtonRed
      />
    </>
  );
};

export default memo(ReportsViewerSidebar);
