import React, { memo, useCallback, useState, useMemo, useEffect } from 'react';
import _ from 'lodash';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import {
  getItemTrackingNextValue,
  ItemTrackingType,
  SerialRow,
  TrackingGroup,
} from 'services/inventory';
import { useGetIntlDateFormatString, formatDate } from 'helpers';

import { updateWithNewTrackingInfoEntry } from './helpers';
import { TrackingCycleColumns } from './components';
import { useTrackingRowStyle } from './styled';
import { TrackingTableTypes } from 'ui/components/Table/TrackingTable';
import { getSettingsCompanyCountry } from 'services/settings/company';
import { useSelector } from 'react-redux';
import FBOSerialModal from './components/SerialModal/FBOSerialModal';
const TrackingRow: React.FC<RenderCustomRowProps<TrackingGroup>> = ({
  row: trackingGroup,
  classes,
  columns,
  index,
  setData,
  meta,
  errors: rowErrors,
}) => {
  const rowClasses = useTrackingRowStyle();

  const [serialModalVisible, setSerialModalVisible] = useState(false);
  const [rowContainsDuplicates, setRowContainsDuplicates] = useState(false);
  const [isSearchedRow, setIsSearchedRow] = useState(false);
  const [canEditTracking, setCanEditTracking] = useState(true);
  const companyCountry = useSelector(getSettingsCompanyCountry);

  const tableType: TrackingTableTypes = useMemo(
    () => _.get(meta, 'tableType'),
    [meta]
  );

  const disableAutoAssign: boolean = useMemo(
    () => _.get(meta, 'disableAutoAssign'),
    [meta]
  );

  const itemTrackingTypes: ItemTrackingType[] = useMemo(
    () => _.get(meta, 'itemTrackingTypes'),
    [meta]
  );

  const allowSerialNumberImport: boolean = useMemo(
    () => _.get(meta, 'allowSerialNumberImport', false),
    [meta]
  );

  const duplicateArray: string[] = useMemo(
    () => _.get(meta, 'duplicateArray', false),
    [meta]
  );

  const searchedTrackingGroups: TrackingGroup[] = useMemo(
    () => _.get(meta, 'searchedTrackingGroups', false),
    [meta]
  );

  useEffect(() => {
    setRowContainsDuplicates(false);
    duplicateArray.forEach((dupe) => {
      trackingGroup.serialList.forEach((serial) => {
        if (dupe === Object.values(serial.serialNumbers)[0]) {
          setRowContainsDuplicates(true);
        }
      });
    });
  }, [duplicateArray, trackingGroup.serialList]);

  useEffect(() => {
    setIsSearchedRow(false);
    searchedTrackingGroups.forEach((tg) => {
      if (tg.id === trackingGroup.id) {
        setIsSearchedRow(true);
      }
    });
  }, [searchedTrackingGroups, trackingGroup]);

  const intlDateFormat = useGetIntlDateFormatString();

  const handleSerialLinkClick = useCallback(() => {
    setSerialModalVisible(true);
  }, []);

  const handleAmountChange = useCallback(
    (value: number | null) => {
      const newTrackingGroup = { ...trackingGroup, quantity: value };
      setData(newTrackingGroup, index);
    },
    [setData, index, trackingGroup]
  );
  const createLotChangeHandler = useCallback(
    (tiIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const lot = e.target.value || null;
      const newTrackingGroup = updateWithNewTrackingInfoEntry(
        trackingGroup,
        lot,
        tiIndex
      );
      setData(newTrackingGroup, index);
    },
    [trackingGroup, index, setData]
  );

  const createDateChangeHandler = useCallback(
    (tiIndex: number) => (newDate: string | null | undefined) => {
      if (!newDate) return;

      const formattedDate = formatDate(newDate, companyCountry, intlDateFormat);

      const newTrackingGroup = updateWithNewTrackingInfoEntry(
        trackingGroup,
        formattedDate,
        tiIndex
      );
      setData(newTrackingGroup, index);
    },
    [trackingGroup, index, setData, intlDateFormat]
  );

  const handleLotAutoAssign = useCallback(
    (tiIndex: number, trackingId: number, trackingTypeId: number) =>
      async () => {
        try {
          const lotNextValue = await getItemTrackingNextValue(
            trackingId,
            trackingTypeId
          );
          const newTrackingGroup = updateWithNewTrackingInfoEntry(
            trackingGroup,
            lotNextValue,
            tiIndex
          );
          setData(newTrackingGroup, index);
        } catch {
          // Ignore error
        }
      },
    [trackingGroup, index, setData]
  );

  const handleDateAutoAssign = useCallback(
    (tiIndex: number, trackingId: number, trackingTypeId: number) =>
      async () => {
        try {
          const dateNextValue = await getItemTrackingNextValue(
            trackingId,
            trackingTypeId
          );
          const formattedDate = formatDate(
            dateNextValue,
            companyCountry,
            intlDateFormat
          );

          const newTrackingGroup = updateWithNewTrackingInfoEntry(
            trackingGroup,
            formattedDate,
            tiIndex
          );

          setData(newTrackingGroup, index);
        } catch {
          // Ignore error
        }
      },
    [trackingGroup, index, setData, intlDateFormat]
  );

  const handleCloseModal = useCallback(() => {
    setSerialModalVisible(false);
  }, []);

  const handleApplyModal = useCallback(
    (newSerialList: SerialRow[], newSerialIds: number[]) => {
      const newTrackingGroup: TrackingGroup = {
        ...trackingGroup,
        serialList: newSerialList,
        serialIds: newSerialIds,
      };
      setData(newTrackingGroup, index);
      setSerialModalVisible(false);
    },
    [index, setData, trackingGroup]
  );

  useEffect(() => {
    setCanEditTracking(true);
    trackingGroup.serialList.forEach((serial) => {
      if (serial.committed) {
        setCanEditTracking(false);
      }
    });
  }, [trackingGroup.serialList]);

  return (
    <>
      <TrackingCycleColumns
        columns={columns}
        trackingGroup={trackingGroup}
        onAmountChange={handleAmountChange}
        onSerialLinkClick={handleSerialLinkClick}
        createLotChangeHandler={createLotChangeHandler}
        createDateChangeHandler={createDateChangeHandler}
        handleLotAutoAssign={handleLotAutoAssign}
        handleDateAutoAssign={handleDateAutoAssign}
        handleRemoveTrackingGroup={() =>
          meta.removeTrackingGroup(trackingGroup.id)
        }
        classes={classes}
        itemTrackingTypes={itemTrackingTypes}
        formValues={meta.formValues}
        rowClasses={rowClasses}
        rowErrors={rowErrors}
        itemUom={_.get(meta, 'itemUom')}
        inventoryUom={_.get(meta, 'inventoryUom')}
        isDecimal={_.get(meta, 'isDecimal')}
        rowContainsDuplicates={rowContainsDuplicates}
        canEditTracking={canEditTracking}
        isSearchedRow={isSearchedRow}
      />
      <FBOSerialModal
        visible={serialModalVisible}
        tableType={tableType}
        itemTrackingTypes={itemTrackingTypes}
        quantity={
          trackingGroup.serialQuantity
            ? trackingGroup.serialQuantity
            : trackingGroup.quantity || 0
        }
        serialList={trackingGroup.serialList}
        onApplyClicked={handleApplyModal}
        allowSerialNumberImport={allowSerialNumberImport}
        onCloseClicked={handleCloseModal}
        disableAutoAssign={disableAutoAssign}
        trackingGroup={trackingGroup}
        locationId={_.get(meta, 'locationId')}
        uomDefaultAbbreviation={_.get(meta, 'uomDefaultAbbreviation')}
        selectedUom={_.get(meta, 'itemUom')}
        itemCost={_.get(meta, 'itemCost')}
        onSetTrackingGroups={meta.onSetTrackingGroups}
        duplicateArray={duplicateArray}
        rowContainsDuplicates={rowContainsDuplicates}
        canEditTracking={canEditTracking}
      />
    </>
  );
};

export default memo(TrackingRow);
