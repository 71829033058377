import { makeStyles } from '@mui/styles';

export const useHistoryCardStyle = makeStyles(() => ({
  paper: {
    width: '600px',
    padding: 0,
  },
  container: {
    paddingBottom: '64px',
  },
}));
