import React, { memo } from 'react';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useSelector } from 'react-redux';

import { PermissionType } from 'services/permissions';
import { getUoms } from 'services/uoms/redux/selectors';
import { Icon } from 'ui/components/Icon';
import { IconNames } from 'ui/theme';
import { TextField } from 'ui/components/TextField/TextField';

import { ConversionsProps } from './types';
import { resolveValueParsing } from './helpers';

const FBOItemUomConversions: React.FC<ConversionsProps> = (props) => {
  const { validationErrors, item, setItem } = props;

  const { items: uoms } = useSelector(getUoms);

  const getUomName = (uomId: number) => {
    const uomOption = uoms.find((option) => option.id === uomId);
    if (uomOption) {
      return uomOption.name;
    }
    return '';
  };

  const handleConversionsChange =
    (index: number) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newConversions = [...item.itemUomConversionList];
      newConversions[index].factor = resolveValueParsing(
        item,
        event.target.value
      );
      setItem((prev) => ({ ...prev, itemUomConversionList: newConversions }));
    };

  const handleConversionReset = (index: number) => {
    const newConversions = [...item.itemUomConversionList];
    newConversions[index].factor = newConversions[index].originalFactor;
    setItem((prev) => ({ ...prev, itemUomConversionList: newConversions }));
  };

  return (
    <>
      {item.itemUomConversionList.map((c, i) => (
        <Grid xs={4} key={i}>
          <Box display="flex" alignItems="center">
            <TextField
              className="redesign"
              variant="standard"
              label="Conversion QTY"
              type="number"
              placeholder="Quantity"
              name="quantity"
              autoComplete="nope"
              value={c.factor}
              error={!!validationErrors[`itemUomConversionList[${i}].factor`]}
              onChange={handleConversionsChange(i)}
              permissions={[PermissionType.ItemsModifyUom]}
              dataQa="uom-conversions-quantity-input"
            />
            <Typography ml={2}>
              <i>
                <b>{c.toUomId && getUomName(c.toUomId)} </b>
                {'per '}
                <b>{c.fromUomId && getUomName(c.fromUomId!)}</b>
              </i>
            </Typography>
            {c.factor !== c.originalFactor && (
              <Tooltip title="RESET" placement="top">
                <IconButton
                  onClick={() => handleConversionReset(i)}
                  size="large"
                >
                  <Icon name={IconNames.Refresh} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Grid>
      ))}
    </>
  );
};

export default memo(FBOItemUomConversions);
