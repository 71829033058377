import { createApiCall } from 'services/api';

import { Email } from './types';

export const sendEmail = async (
  email: Email,
  file?: Blob,
  pdfDisabled?: boolean
): Promise<void> => {
  const formData = new FormData();
  const payloadJson = JSON.stringify(email);
  formData.append('bodyString', payloadJson);

  if (file) {
    if (pdfDisabled) {
      formData.append('file', file, `file.xlsx`);
    } else {
      formData.append('file', file, `file.pdf`);
    }
  }

  await createApiCall(
    {
      path: `/email`,
      method: 'POST',
      body: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    },
    {
      getMessage: {
        success: () => 'E-mail successfully sent',
        error: (error: any) => error.response.data.message,
      },
    }
  )();
};
