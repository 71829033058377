import React, { memo, useState, useCallback, useEffect, useMemo } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { Modal } from 'ui/components/Modal/Modal';
import { roundToDecimals, removeValueFromCollection } from 'helpers';
import {
  fetchShippingSalesOrderQuotesRates,
  getShippingSettings,
  initialShippingIntegrationSettings,
  inititialSalesOrderShippingQuote,
  SalesOrderShippingQuote,
  ShippingConnectionType,
  ShippingIntegrationRate,
  ShippingIntegrationSettings,
  shippingQuoteAddToSalesOrder,
  ShippingQuoteAddToSalesOrderParams,
} from 'services/integrations/shipping';
import { getShippingConnection } from 'services/integrations/shipping/redux';
import { getLocations } from 'services/locations';
import TabPanel from 'ui/components/TabPanel/TabPanel';
import { Errors, validateYup } from 'services/forms/validation';
import { initialAddress } from 'services/addresses';

import { SalesOrderShippingQuoteModalProps } from './types';
import { useCardRowStyle } from './styled';
import { ShippingQuoteRates } from './components';
import ShippingQuoteCartonRow from './components/ShippingQuoteCartonRow';
import ShippingExtrasTab from './components/ShippingExtrasTab';
import CustomsInfoTab from './components/CustomsInfoTab';
import ShipAddressTab from './components/ShipAddressTab';
import { quotesYupSchema } from './validations';
import {
  addNewCartonToSoQuote,
  setShippingQuoteFromSettings,
  totalCostCalculation,
  validateSaleOrderQuoteCartonList,
} from './helpers';
import { transformShipToAddressFromQuote } from './components/transformations';
import { logErrorCtx } from 'app/logging';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';

const SalesOrderShippingQuoteModal: React.FC<
  SalesOrderShippingQuoteModalProps
> = (props) => {
  const { visible, onClose, activeSalesOrder, onAddNewItemClick } = props;

  const classes = useCardRowStyle();

  const { items: locations } = useSelector(getLocations);

  const connection = useSelector(getShippingConnection);

  const [shippoSettings, setShippoSetings] =
    useState<ShippingIntegrationSettings>(initialShippingIntegrationSettings);
  const [shippingQuote, setShippingQuote] = useState<SalesOrderShippingQuote>(
    inititialSalesOrderShippingQuote
  );
  const [activeTab, setActiveTab] = useState(0);
  const [rates, setRates] = useState<ShippingIntegrationRate[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Errors>({});
  const [rowErrors, setRowErrors] = useState<Errors[]>([]);
  const [weightTotal, setWeightTotal] = useState(0);

  const itemLocation = useMemo(() => {
    return (
      locations.find((loc) => loc.id === activeSalesOrder.locationId) || null
    );
  }, [activeSalesOrder.locationId, locations]);

  const isInternational = useMemo(() => {
    const shipTo = shippingQuote.shipToAddress;
    const shipFrom = shippingQuote.shipFromAddress;

    return (
      !!shipTo.country &&
      !!shipFrom.country &&
      shipFrom.country !== shipTo.country
    );
  }, [shippingQuote.shipToAddress, shippingQuote.shipFromAddress]);

  useEffect(() => {
    const w = activeSalesOrder.salesOrderItemList.reduce(
      (acc, i) => acc + _.get(i, 'saleItem.weight', 0) * i.quantity!,
      0
    );
    setWeightTotal(w);
  }, [activeSalesOrder.salesOrderItemList]);

  useEffect(() => {
    if (!connection) {
      return;
    }

    (async () => {
      setIsLoading(true);

      try {
        if (visible) {
          const resSettings = await getShippingSettings(connection);
          setShippoSetings(resSettings);

          const shipFromAddress = _.get(
            itemLocation,
            'address',
            initialAddress
          );
          setShippingQuoteFromSettings(
            resSettings,
            weightTotal,
            activeSalesOrder,
            shipFromAddress,
            setShippingQuote
          );
        } else {
          setShippingQuote(inititialSalesOrderShippingQuote);
          setRates([]);
        }
      } catch (e) {
        logErrorCtx('Error in getShippingSettings', {
          error: e as Error,
          stackTrace: (e as Error).stack,
          component: 'SalesOrderShippingQuoteModal',
          title: 'Error in getShippingSettings',
          description: 'Error in getShippingSettings',
        });
      }

      setIsLoading(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const shippoConnected = useMemo(() => {
    if (!connection) {
      return false;
    }

    return connection.typeId === ShippingConnectionType.Shippo;
  }, [connection]);

  const handleAddNewItemToSaleOrder = useCallback(async () => {
    if (!shippingQuote.serviceCode) {
      return;
    }
    const selectedCarrier = rates.find(
      (r) => r.carrierCode === shippingQuote.carrierCode
    )!;
    const selectedService = selectedCarrier.services.find(
      (s) => s.serviceCode === shippingQuote.serviceCode
    )!;

    const totalCost = totalCostCalculation(selectedService, shippoSettings);

    const params: ShippingQuoteAddToSalesOrderParams = {
      id: activeSalesOrder.id,
      soNumber: 'SO ' + activeSalesOrder.number,
      carrierCode: selectedCarrier.carrierCode,
      serviceCode: selectedService.serviceCode,
      carrierName: selectedCarrier.carrierName,
      serviceName: selectedService.serviceName,
      totalCost: totalCost,
      connection: connection!,
      shipTo: transformShipToAddressFromQuote(shippingQuote),
    };

    setIsLoading(true);

    try {
      await shippingQuoteAddToSalesOrder(params);
    } catch (e) {
      logErrorCtx('Error in shippingQuoteAddToSalesOrder', {
        error: e as Error,
        stackTrace: (e as Error).stack,
        component: 'SalesOrderShippingQuoteModal',
        title: 'Error in shippingQuoteAddToSalesOrder',
        description: 'Error in shippingQuoteAddToSalesOrder',
      });
      setIsLoading(false);
      return;
    }

    setRates([]);
    onAddNewItemClick();
    setIsLoading(false);
  }, [
    shippingQuote,
    activeSalesOrder.id,
    activeSalesOrder.number,
    connection,
    shippoSettings,
    rates,
    onAddNewItemClick,
  ]);

  const handleActiveTabChange = useCallback(
    (_event: React.ChangeEvent<{}>, newValue: number) => {
      setActiveTab(newValue);
    },
    []
  );

  const handleAddCartonClicked = useCallback(() => {
    addNewCartonToSoQuote(
      shippoSettings,
      shippingQuote,
      weightTotal,
      setShippingQuote
    );
  }, [shippoSettings, weightTotal, shippingQuote]);

  const onDeleteCartonClicked = (index: number) => {
    if (shippingQuote.cartons.length === 1) {
      return;
    }
    const cartons = shippingQuote.cartons.map((i) => ({
      ...i,
      weight: roundToDecimals(
        weightTotal / (shippingQuote.cartons.length - 1),
        4
      ),
    }));
    setShippingQuote((old) => ({
      ...old,
      cartons: removeValueFromCollection(cartons, index),
    }));
  };

  const onSetWeight = (weight: number) => {
    setWeightTotal(weight);
  };

  const handleFetchRatesClick = useCallback(async () => {
    const isValid = validateYup(shippingQuote, quotesYupSchema, setErrors);
    const isCartonsInvalid = validateSaleOrderQuoteCartonList(
      shippingQuote.cartons,
      setRowErrors
    );

    if (!isValid || isCartonsInvalid) {
      return;
    }

    setIsLoading(true);
    try {
      const resRates = await fetchShippingSalesOrderQuotesRates(
        connection!,
        shippingQuote.cartons,
        shippingQuote.shipToAddress,
        shippingQuote.shipFromAddress,
        shippingQuote
      );
      setRates(resRates);
    } catch (e) {
      logErrorCtx('Error in fetchShippingSalesOrderQuotesRates', {
        error: e as Error,
        stackTrace: (e as Error).stack,
        component: 'SalesOrderShippingQuoteModal',
        title: 'Error in fetchShippingSalesOrderQuotesRates',
        description: 'Error in fetchShippingSalesOrderQuotesRates',
      });
    }
    setIsLoading(false);
  }, [connection, shippingQuote]);

  const titleBar = () => (
    <FBOTitleBar title="Shipping Quotes">
      <FBOButton
        variant="secondary"
        color="positive"
        data-qa="sales-order-shipping-quote-modal-add-package"
        onClick={handleAddCartonClicked}
        sx={{ mr: 2 }}
      >
        Add Package
      </FBOButton>
      <FBOButton
        variant="tertiary"
        color="neutral"
        size="medium"
        icon="FBOClose"
        data-qa={`close-shipping-quote-modal-${activeSalesOrder.id}`}
        onClick={onClose}
      />
    </FBOTitleBar>
  );

  const FBOFooterBar = () => (
    <Box display="flex" justifyContent="space-between" width="100%">
      <FBOButton
        variant="secondary"
        color="neutral"
        size="medium"
        onClick={onClose}
        data-qa="sales-order-shipping-quote-modal-cancel-button"
      >
        Cancel
      </FBOButton>
      <Box className={classes.buttonGroup}>
        <FBOButton
          variant="secondary"
          color="positive"
          size="medium"
          onClick={handleFetchRatesClick}
          data-qa="sales-order-shipping-quote-modal-get-rates-button"
        >
          Get Rates
        </FBOButton>
        <FBOButton
          variant="primary"
          color="positive"
          size="medium"
          disabled={!shippingQuote.serviceCode}
          onClick={handleAddNewItemToSaleOrder}
          data-qa="sales-order-shipping-quote-modal-add-to-sales-order-button"
        >
          Add To Sales Order
        </FBOButton>
      </Box>
    </Box>
  );

  return (
    <Modal
      open={visible}
      onCancelClicked={onClose}
      TitleBarComponent={titleBar}
      ModalActionsComponent={FBOFooterBar}
      onApplyClicked={_.noop}
      withoutDefaultPadding
      footerDivider="border"
      fullWidth
      customHeight={600}
      isLoadingContent={isLoading}
    >
      <Box flexDirection="row" display="flex">
        <Box className={classes.content} p={4}>
          <Box className={classes.cartonBox} pl={4}>
            {shippingQuote.cartons.map((carton, index) => (
              <ShippingQuoteCartonRow
                key={index}
                carton={carton}
                index={index}
                onDeleteClicked={onDeleteCartonClicked}
                shippingQuote={shippingQuote}
                setShippingQuote={setShippingQuote}
                errors={rowErrors[index] ? rowErrors[index] : {}}
                onSetWeight={onSetWeight}
              />
            ))}
          </Box>
          <Box>
            <Tabs
              className="redesign"
              value={activeTab}
              onChange={handleActiveTabChange}
              indicatorColor="primary"
            >
              <Tab label="Ship Address" />
              <Tab label="Shipping Extras" />
              {isInternational && <Tab label="Customs Info" />}
            </Tabs>
          </Box>
          <Box>
            <TabPanel value={activeTab} index={0} flexGrow noSpacing>
              <ShipAddressTab
                shippingQuote={shippingQuote}
                setShippingQuote={setShippingQuote}
                errors={errors}
              />
            </TabPanel>
            <TabPanel value={activeTab} index={1} flexGrow noSpacing>
              <ShippingExtrasTab
                shippingQuote={shippingQuote}
                setShippingQuote={setShippingQuote}
                shippoConnected={shippoConnected}
                errors={errors}
              />
            </TabPanel>
            <TabPanel value={activeTab} index={2} flexGrow noSpacing>
              <CustomsInfoTab
                shippingQuote={shippingQuote}
                setShippingQuote={setShippingQuote}
              />
            </TabPanel>
          </Box>
        </Box>
        <ShippingQuoteRates
          rates={rates}
          defaultShippingSettings={shippoSettings}
          shippingQuote={shippingQuote}
          setShippingQuote={setShippingQuote}
        />
      </Box>
    </Modal>
  );
};

export default memo(SalesOrderShippingQuoteModal);
