import { createApiCall } from 'services/api';

import {
  transformPermissionCategory,
  transformPermissionGroup,
  transformToRequestPermissionGroup,
} from './transformations';
import { PermissionCategory, PermissionGroup } from './types';

export const fetchPermissionCategories = async (): Promise<
  PermissionCategory[]
> => {
  const response = await createApiCall(
    {
      path: '/v1/permission_categories?expand=permissionAreas.permissions',
      method: 'GET',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return response.data.results.map(transformPermissionCategory);
};

export const fetchPermissionGroupsAPI = async (): Promise<
  PermissionGroup[]
> => {
  const response = await createApiCall(
    {
      path: `/v1/access_groups?pageSize=100`,
      method: 'GET',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return response.data.results.map(transformPermissionGroup);
};

export const fetchPermissionGroup = async (
  id: number
): Promise<PermissionGroup> => {
  const response = await createApiCall(
    {
      path: `/v1/access_groups/${id}`,
      method: 'GET',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformPermissionGroup(response.data);
};

export const postPermissionGroup = async (
  permissionGroup: PermissionGroup
): Promise<PermissionGroup> => {
  const response = await createApiCall(
    {
      path: '/v1/access_groups',
      method: 'POST',
      body: transformToRequestPermissionGroup(permissionGroup),
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
        success: () => 'New group added',
      },
    }
  )();

  return transformPermissionGroup(response.data);
};

export const putPermissionGroup = async (
  permissionGroup: PermissionGroup
): Promise<PermissionGroup> => {
  const response = await createApiCall(
    {
      path: '/v1/access_groups',
      method: 'PUT',
      body: transformToRequestPermissionGroup(permissionGroup),
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
        success: () => 'Group edited',
      },
    }
  )();

  return transformPermissionGroup(response.data);
};

export const deletePermissionGroup = async (id: number) => {
  await createApiCall(
    {
      path: `/v1/access_groups/${id}`,
      method: 'DELETE',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
        success: () => 'Group deleted',
      },
    }
  )();
};
