import { IconNames } from 'ui/theme';
import { Routes as MaterialsRoutes } from 'ui/modules/materials';
import { Routes as SetupRoutes } from 'ui/modules/setup';
import { Routes as PurchasingRoutes } from 'ui/modules/purchasing';
import { Routes as SalesRoutes } from 'ui/modules/sales';
import { Routes as ReportsRoutes } from 'ui/modules/reports';
import { Routes as AccountingRoutes } from 'ui/modules/accounting';
import { Routes as HomeRoutes } from 'ui/modules/home';
import { Routes as CommerceRoutes } from 'ui/modules/commerce';
import { Routes as IntegrationsRoutes } from 'ui/modules/integrations';
import { Routes as RemoteSupportRoutes } from 'ui/modules/remotesupport';

import { PermissionType } from 'services/permissions';
import LeaveSandbox from './_sandbox/LeaveSandbox';
import ClearSandboxData from './_sandbox/ClearSandboxData';
import PopulateSandboxSampleData from './_sandbox/PopulateSandboxSampleData';
import PopulateSandboxAccountData from './_sandbox/PopulateSandboxAccountData';
import { DrawerItemType } from './DrawerItem';
import { concatRoute } from 'helpers/routes';

export const DRAWER_WIDTH = 200;
export const DRAWER_SMALL_WIDTH = 57;
export const APPBAR_SPACER_HEIGHT = 56;

/**
 * Array of items that will be rendered in app drawer.
 * {
 *  text: Text that is displayed
 *  icon: Icon that is used
 *  route: Route used for identifying parent. Never used as link.
 *  subitems [
 *    {
 *      text: Text that is display
 *      route: Route used for redirect when item is clicked
 *    }
 *  ]
 * }
 */
export const DRAWER_ITEMS = ({
  quickbooksConnected,
  isTestEnv,
  hasConnectedShippingChannels,
  xeroConnected,
  commerceConnected,
  isMultiCurrencyVisible,
  isCommerceRefactored,
}: Record<
  | 'quickbooksConnected'
  | 'isTestEnv'
  | 'hasConnectedShippingChannels'
  | 'xeroConnected'
  | 'commerceConnected'
  | 'isMultiCurrencyVisible'
  | 'isCommerceRefactored',
  boolean
>): DrawerItemType[] => [
  {
    text: 'Sample Database',
    icon: IconNames.MenuSandbox,
    subitems: [
      {
        text: '',
        route: null,
        component: PopulateSandboxAccountData,
        visible: isTestEnv,
      },
      {
        text: '',
        route: null,
        component: PopulateSandboxSampleData,
        visible: isTestEnv,
      },
      {
        text: '',
        route: null,
        component: ClearSandboxData,
        visible: isTestEnv,
      },
      {
        text: '',
        route: null,
        component: LeaveSandbox,
        visible: isTestEnv,
      },
    ],
    route: null,
    visible: isTestEnv,
  },
  {
    text: 'Home',
    icon: IconNames.MenuHome,
    subitems: [
      {
        text: 'Dashboard',
        permissions: [PermissionType.DashboardView],
        route: HomeRoutes.DashboardPage,
      },
      {
        text: 'Flow',
        route: HomeRoutes.FlowPage,
        permissions: [],
      },
    ],
    route: null,
  },
  {
    text: 'Materials',
    icon: IconNames.MenuMaterials,
    permissions: [PermissionType.InventoryView, PermissionType.ItemsView],
    subitems: [
      {
        text: 'Items',
        route: concatRoute(
          MaterialsRoutes.MaterialsRouter,
          MaterialsRoutes.ItemsPage
        ),
        permissions: [PermissionType.ItemsView],
      },
      {
        text: 'Inventory',
        route: concatRoute(
          MaterialsRoutes.MaterialsRouter,
          MaterialsRoutes.TrackingPage
        ),
        permissions: [PermissionType.InventoryView],
      },
    ],
    route: null,
  },
  {
    text: 'Sales',
    icon: IconNames.MenuSales,
    permissions: [
      PermissionType.CustomersView,
      PermissionType.PricingRulesView,
      PermissionType.SalesOrderView,
      PermissionType.PickingView,
      PermissionType.ShippingView,
    ],
    subitems: [
      {
        text: 'Sales Orders',
        route: concatRoute(SalesRoutes.SalesRouter, SalesRoutes.SalesOrderPage),
        permissions: [PermissionType.SalesOrderView],
      },
      {
        text: 'Picking',
        route: concatRoute(SalesRoutes.SalesRouter, SalesRoutes.PickingPage),
        permissions: [PermissionType.PickingView],
      },
      {
        text: 'Shipping',
        route: concatRoute(SalesRoutes.SalesRouter, SalesRoutes.ShippingPage),
        permissions: [PermissionType.ShippingView],
      },
      {
        text: 'Customers',
        route: concatRoute(SalesRoutes.SalesRouter, SalesRoutes.CustomersPage),
        permissions: [PermissionType.CustomersView],
      },
      {
        text: 'Pricing Rules',
        route: concatRoute(
          SalesRoutes.SalesRouter,
          SalesRoutes.PricingRulesPage
        ),
        permissions: [PermissionType.PricingRulesView],
      },
    ],
    route: null,
  },
  {
    text: 'Purchasing',
    icon: IconNames.MenuPurchasing,
    permissions: [
      PermissionType.VendorsView,
      PermissionType.PurchaseOrderView,
      PermissionType.ReceivingView,
    ],
    subitems: [
      {
        text: 'Purchase Orders',
        route: concatRoute(
          PurchasingRoutes.PurchasingRouter,
          PurchasingRoutes.PurchaseOrderPage
        ),
        permissions: [PermissionType.PurchaseOrderView],
      },
      {
        text: 'Receiving',
        route: concatRoute(
          PurchasingRoutes.PurchasingRouter,
          PurchasingRoutes.ReceivingPage
        ),
        permissions: [PermissionType.ReceivingView],
      },
      {
        text: 'Vendors',
        route: concatRoute(
          PurchasingRoutes.PurchasingRouter,
          PurchasingRoutes.VendorsPage
        ),
        permissions: [PermissionType.VendorsView],
      },
    ],
    route: null,
  },
  {
    text: 'Accounting',
    icon: IconNames.MenuAccounting,
    subitems: [
      {
        text: 'Chart of Accounts',
        route: concatRoute(
          AccountingRoutes.AccountingRouter,
          AccountingRoutes.LedgersPage
        ),
      },
      {
        text: 'Classes',
        route: concatRoute(
          AccountingRoutes.AccountingRouter,
          AccountingRoutes.ClassesPage
        ),
      },
      {
        text: 'Invoices',
        route: concatRoute(
          AccountingRoutes.AccountingRouter,
          AccountingRoutes.InvoicesPage
        ),
      },
      {
        text: 'QuickBooks Online',
        route: concatRoute(
          AccountingRoutes.AccountingRouter,
          AccountingRoutes.AccountingSettingsPage
        ),
        visible: quickbooksConnected,
      },
      {
        text: 'Xero',
        route: concatRoute(
          AccountingRoutes.AccountingRouter,
          AccountingRoutes.AccountingSettingsPage
        ),
        visible: xeroConnected,
      },
    ],
    route: null,
    permissions: [PermissionType.AccountingView],
  },
  {
    text: 'Reports',
    icon: IconNames.MenuReports,
    subitems: [],
    route: concatRoute(ReportsRoutes.ReportsRouter, ReportsRoutes.ReportsPage),
    permissions: [
      PermissionType.ReportVendorFulFilmentHistory,
      PermissionType.ReportTotalItemSales,
      PermissionType.ReportShippingInvoice,
      PermissionType.ReportSalesOrder,
      PermissionType.ReportReorder,
      PermissionType.ReportReceivingList,
      PermissionType.ReportPurchaseOrder,
      PermissionType.ReportPick,
      PermissionType.ReportPackingList,
      PermissionType.ReportItemsPriceList,
      PermissionType.ReportInventoryValuationSummary,
      PermissionType.ReportInventoryOnHand,
      PermissionType.ReportCycleCount,
      PermissionType.ReportCustomerHistory,
      PermissionType.ReportMargin,
      PermissionType.ReportConsumption,
      PermissionType.ReportItemPriceList,
      PermissionType.ReportBarcode,
      PermissionType.ReportInventoryAvailability,
      PermissionType.ReportInventoryEventHistory,
      PermissionType.ReportItemBarcodeOneOff,
      PermissionType.ReportLocationBarcode,
      PermissionType.ReportOnHandByExpiration,
      PermissionType.ReportSalesOrderSummary,
      PermissionType.ReportPurchaseOrderSummary,
      PermissionType.ReportBOL,
    ],
  },
  {
    text: 'Commerce',
    icon: IconNames.MenuCommerce,
    subitems: [
      {
        text: 'Discover Channels',
        route: isCommerceRefactored
          ? null
          : concatRoute(
              CommerceRoutes.CommerceRouter,
              CommerceRoutes.DiscoverChannelsPage
            ),
        visible: !isTestEnv && !isCommerceRefactored,
      },
      {
        text: 'My Channels',
        route: isCommerceRefactored
          ? null
          : concatRoute(
              CommerceRoutes.CommerceRouter,
              CommerceRoutes.MyChannelsPage
            ),
        visible: commerceConnected && !isTestEnv && !isCommerceRefactored,
      },
      {
        text: 'Settings',
        route: isCommerceRefactored
          ? null
          : concatRoute(
              CommerceRoutes.CommerceRouter,
              CommerceRoutes.SettingsPage
            ),
        visible: commerceConnected && !isTestEnv && !isCommerceRefactored,
      },
    ],
    route: null,
    visible: !isTestEnv && !isCommerceRefactored,
  },
  {
    text: 'Integrations',
    icon: IconNames.MenuIntegrations,
    route: null,
    visible: !isTestEnv,
    permissions: [
      PermissionType.IntegrationsECommerce,
      PermissionType.IntegrationsPayments,
      PermissionType.IntegrationsShipping,
    ],
    subitems: [
      {
        text: 'Commerce Channels',
        route: isCommerceRefactored
          ? concatRoute(
              CommerceRoutes.CommerceRouter,
              CommerceRoutes.MyChannelsPage
            )
          : null,
        visible: isCommerceRefactored,
      },
      {
        text: 'Market',
        route: concatRoute(
          IntegrationsRoutes.IntegrationsRouter,
          IntegrationsRoutes.IntegrationsPage
        ),
      },
      {
        text: 'Shipping',
        route: concatRoute(
          IntegrationsRoutes.IntegrationsRouter,
          IntegrationsRoutes.ShippingPage
        ),
        visible: hasConnectedShippingChannels,
      },
    ],
  },
  {
    text: 'Help',
    placement: 'bottom',
    visible: !isCommerceRefactored,
    icon: IconNames.MenuHelp,
    permissions: [],
    subitems: [],
    route: null,
    link: 'https://help.fishbowlonline.com/',
  },
  {
    placement: isCommerceRefactored ? 'top' : 'bottom',
    text: 'Settings',
    icon: IconNames.MenuSettings,
    permissions: [
      PermissionType.UomView,
      PermissionType.LocationView,
      PermissionType.UserView,
      PermissionType.PermissionView,
      PermissionType.PaymentTermsView,
      PermissionType.RepresentativesView,
      PermissionType.TaxRatesView,
      PermissionType.CarriersView,
      PermissionType.SettingsCompanyView,
      PermissionType.SettingsEmailingView,
      PermissionType.SettingsPickingView,
      PermissionType.SettingsSalesOrderView,
      PermissionType.SettingsTrackingView,
      PermissionType.SettingsShippingView,
      PermissionType.SettingsAccountMappingView,
      PermissionType.SettingsShippingView,
    ],
    subitems: [
      {
        text: 'UOMs',
        route: concatRoute(
          SetupRoutes.SetupRouter,
          SetupRoutes.UnitOfMeasurementPage
        ),
        permissions: [PermissionType.UomView],
      },
      {
        text: 'Locations',
        route: concatRoute(SetupRoutes.SetupRouter, SetupRoutes.LocationPage),
        permissions: [PermissionType.LocationView],
      },
      {
        text: 'Users',
        route: concatRoute(SetupRoutes.SetupRouter, SetupRoutes.UsersPage),
        permissions: [PermissionType.UserView],
        visible: !isTestEnv,
      },
      {
        text: 'Permissions',
        route: concatRoute(
          SetupRoutes.SetupRouter,
          SetupRoutes.PermissionsPage
        ),
        permissions: [PermissionType.PermissionView],
      },
      {
        text: 'Payment Terms',
        route: concatRoute(
          SetupRoutes.SetupRouter,
          SetupRoutes.PaymentTermsPage
        ),
        permissions: [PermissionType.PaymentTermsView],
      },
      {
        text: 'Currency',
        route: concatRoute(SetupRoutes.SetupRouter, SetupRoutes.CurrencyPage),
        permissions: [PermissionType.CurrencyView],
        visible: isMultiCurrencyVisible,
      },
      {
        text: 'Representatives',
        route: concatRoute(
          SetupRoutes.SetupRouter,
          SetupRoutes.RepresentativesPage
        ),
        permissions: [PermissionType.RepresentativesView],
      },
      {
        text: 'Tax Rates',
        route: concatRoute(SetupRoutes.SetupRouter, SetupRoutes.TaxRatesPage),
        permissions: [PermissionType.TaxRatesView],
      },
      {
        text: 'Carriers',
        route: concatRoute(SetupRoutes.SetupRouter, SetupRoutes.CarriersPage),
        permissions: [PermissionType.CarriersView],
      },
      {
        text: 'Preferences',
        route: concatRoute(SetupRoutes.SetupRouter, SetupRoutes.SettingsPage),
      },
    ],
    route: null,
  },
  {
    text: 'Remote Support',
    icon: IconNames.MenuRemoteSupport,
    route: concatRoute(
      RemoteSupportRoutes.RemoteSupportRouter,
      RemoteSupportRoutes.RemoteSupportPage
    ),
    subitems: [],
    onlyExecutor: true,
  },
];
