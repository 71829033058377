import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useGeneralTabStyle = makeStyles((theme: Theme) => ({
  buttonLink: {
    'text-transform': 'none',
    'white-space': 'pre',
    cursor: 'pointer',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    columnGap: theme.spacing(2),
  },
  descriptionBox: {
    gridRow: 'span 2',
    gridColumn: 'span 2',
  },
  gridItemSpan2: {
    gridColumn: 'span 2',
  },
  gridItemSpan4: {
    gridColumn: 'span 4',
  },
}));
