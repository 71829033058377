import moment from 'moment';
import { OpenPurchaseOrder, OpenReceipt } from 'services/dashboard';
import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const TOP_PURCHASED_ITEMS_COLUMNS: Column[] = [
  {
    title: 'Item',
    field: 'name',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Description',
    field: 'description',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Barcode / UPC',
    field: 'upc',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'SKU',
    field: 'sku',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Quantity',
    field: 'quantity',
    sortable: true,
    type: ColumnTypes.string,
    width: 192,
  },
];

export const OPEN_PURCHASE_ORDER_COLUMNS: (
  format: string,
  currencyFormatter: (
    value: number,
    currencyCode?: string | undefined
  ) => string
) => Column[] = (format, currencyFormatter) => [
  {
    title: 'No.',
    field: 'number',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Status',
    field: 'status',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Vendor',
    field: 'vendor.name',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Buyer',
    field: 'buyer.firstName',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Date Created',
    field: 'dateCreated',
    sortable: true,
    render: (opo: OpenPurchaseOrder) =>
      moment(opo.dateCreated || '').format(format),
    type: ColumnTypes.custom,
  },
  {
    title: 'Total',
    field: 'total',
    sortable: true,
    width: 192,
    render: (opo: OpenPurchaseOrder) => currencyFormatter(opo.total || 0),
    type: ColumnTypes.custom,
  },
];

export const OPEN_RECEIPTS_COLUMNS: (format: string) => Column[] = (format) => [
  {
    title: 'No.',
    field: 'number',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Status',
    field: 'status',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Customer',
    field: 'customer.name',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Vendor',
    field: 'vendor.name',
    sortable: true,
    type: ColumnTypes.string,
  },
  {
    title: 'Date Issued',
    field: 'dateIssued',
    sortable: true,
    render: (or: OpenReceipt) => moment(or.dateIssued || '').format(format),
    type: ColumnTypes.custom,
  },
  {
    title: 'Carrier',
    width: 192,
    field: 'carrier.name',
    sortable: true,
    type: ColumnTypes.string,
  },
];
