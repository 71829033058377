import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

import { renderTaxRate } from 'services/taxRates';

import { TaxTooltipProps } from './types';
import { TextOnlyTooltip } from './styled';
import { useGetCurrencySymbol } from 'helpers';

const TaxTooltip: React.FC<TaxTooltipProps> = (props) => {
  const { tax, children, placement = 'bottom', taxable } = props;

  const currencySymbol = useGetCurrencySymbol();

  const tooltipValue = () => (
    <Box display="flex" flexDirection="column" width={150}>
      <Paper>
        <Box display="flex" justifyContent="space-between" p={1}>
          {tax ? (
            <>
              <Typography style={{ color: 'grey', overflow: 'hidden' }}>
                {tax.name}
              </Typography>
              <Typography>{renderTaxRate(tax, currencySymbol)}</Typography>
            </>
          ) : (
            <>
              <Typography style={{ color: 'grey', overflow: 'hidden' }}>
                None
              </Typography>
              <Typography>0%</Typography>
            </>
          )}
        </Box>
      </Paper>
    </Box>
  );

  return (
    <TextOnlyTooltip
      placement={placement}
      title={tooltipValue()}
      disableHoverListener={!taxable}
    >
      {children}
    </TextOnlyTooltip>
  );
};

export default TaxTooltip;
