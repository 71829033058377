import React from 'react';
import { Box, Typography } from '@mui/material';

import { Paper } from 'ui/components/Paper/Paper';
import { DriveLogoHorizontal, IconNames } from 'ui/theme';
import { NotACustomer } from 'ui/modules/public/components';

import { AuthContainerProps } from './types';
import { useAuthContainerStyle } from './styled';
import { Icon } from 'ui/components/Icon';

const AuthContainer: React.FC<AuthContainerProps> = (
  props: AuthContainerProps
) => {
  const {
    children,
    title,
    showNotACustomer = false,
    sx = {},
    isMaintenancePage = false,
  } = props;

  const classes = useAuthContainerStyle(props);

  return (
    <Box className={classes.background}>
      <Paper
        p={isMaintenancePage ? '0' : '40px 40px 19px 40px'}
        className={classes.paper}
        sx={sx}
      >
        {isMaintenancePage ? (
          <Box display={'flex'} justifyContent={'center'}>
            <Icon name={IconNames.MaintenanceTools} />
          </Box>
        ) : (
          <Box textAlign="center">
            <img
              id="FishbowlDriveLogo"
              src={DriveLogoHorizontal}
              alt="FishBowl Drive"
              width={200}
            />
          </Box>
        )}
        <Box
          mt={'16px'}
          pb={isMaintenancePage ? '0' : '32px'}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          {isMaintenancePage ? (
            <Typography
              fontSize={'38px'}
              sx={{ maxWidth: '300px', lineHeight: 'normal' }}
              align="center"
            >
              {title}
            </Typography>
          ) : (
            <Typography
              variant="h4"
              align="center"
              className={classes.title}
              sx={{ letterSpacing: 1 }}
            >
              {title}
            </Typography>
          )}
        </Box>
        <Box>{children}</Box>
      </Paper>
      {showNotACustomer && <NotACustomer />}
    </Box>
  );
};

export default React.memo(AuthContainer);
