import React, { memo } from 'react';
import { NoQuantityModalActionsProps } from './types';
import { Box } from '@mui/material';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const NoQuantityModalActions: React.FC<NoQuantityModalActionsProps> = (
  props
) => {
  const { showUseSubstitute, onCancel, onUseSubstitute, onAdd } = props;

  const FBOModalActions = (
    <Box display="flex" justifyContent="space-between" width="100%">
      <Box>
        <FBOButton
          variant="secondary"
          color="neutral"
          size="medium"
          onClick={onCancel}
          data-qa="no-quantity-cancel"
        >
          Cancel
        </FBOButton>
      </Box>
      <Box display="flex">
        {showUseSubstitute && (
          <Box mr={2}>
            <FBOButton
              variant="secondary"
              color="positive"
              size="medium"
              onClick={onUseSubstitute}
              data-qa="no-quantity-use-substitute"
            >
              Use Substitute
            </FBOButton>
          </Box>
        )}
        <FBOButton
          variant="primary"
          color="positive"
          size="medium"
          onClick={onAdd}
          data-qa="no-quantity-add"
        >
          Add Anyway
        </FBOButton>
      </Box>
    </Box>
  );

  return FBOModalActions;
};

export default memo(NoQuantityModalActions);
