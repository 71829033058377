import { Ship } from 'services/shipping';
import { Pagination } from 'services/search';
import { Dates } from 'helpers';

export interface ShippingSearchResultsProps {
  ships: Ship[];
  activeShipId: null | number;
  handleShipClick: (id: number) => void;
  isLoadingShips: boolean;
  pagination: Pagination;
  onPaginationChange: (pagination: Pagination) => void;
  selectedItems: number[];
  setSelectedItems: React.Dispatch<React.SetStateAction<number[]>>;
  onVoidClicked: () => void;
  onPageAction: (action: ShippingPageAction, date?: Dates) => void;
  activeDate: Dates;
}

export enum ShippingPageAction {
  CustomFields,
  ChangeDate,
}
