import {
  createApiReducer,
  chainReducers,
  INITIAL_API_STATUS,
  ApiStatus,
} from 'services/api';

import {
  transformXero,
  transformInternationalTaxRates,
} from '../transformations';
import { XeroAccountActionTypes } from './actions';

export interface XeroApiState<T = any, R = any> {
  _status: ApiStatus;
  items: T[];
  taxRates: R[];
}

export const INITIAL_API_STATE: XeroApiState = {
  _status: INITIAL_API_STATUS,
  items: [],
  taxRates: [],
};

const fetchXeroAccountReducer = createApiReducer(
  [
    XeroAccountActionTypes.FETCH_XERO_ACCOUNT_START,
    XeroAccountActionTypes.FETCH_XERO_ACCOUNT_SUCCESS,
    XeroAccountActionTypes.FETCH_XERO_ACCOUNT_FAILURE,
  ],
  (data, state) => ({ ...state, items: data.results.map(transformXero) }),
  (data) => data
);

export const fetchXeroTaxRatesReducer = createApiReducer(
  [
    XeroAccountActionTypes.XERO_TAX_RATES_START,
    XeroAccountActionTypes.XERO_TAX_RATES_SUCCESS,
    XeroAccountActionTypes.XERO_TAX_RATES_FAILURE,
  ],
  (data, state) => ({
    ...state,
    taxRates: data.results.map(transformInternationalTaxRates),
  }),
  (error) => error.response.data.errorMessage
);

const disconnectXeroReducer = createApiReducer(
  [
    XeroAccountActionTypes.XERO_DISCONNECT_START,
    XeroAccountActionTypes.XERO_DISCONNECT_SUCCESS,
    XeroAccountActionTypes.XERO_DISCONNECT_FAILURE,
  ],
  () => INITIAL_API_STATE,
  (error) => error.response.data.errorMessage
);

const xeroAccountChainedReducers = chainReducers(
  INITIAL_API_STATE,
  fetchXeroAccountReducer,
  fetchXeroTaxRatesReducer,
  disconnectXeroReducer
);

export default xeroAccountChainedReducers;
