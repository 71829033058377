import React, { useCallback } from 'react';
import moment from 'moment';
import { Tooltip, Typography } from '@mui/material';

import { DateTooltipProps } from './types';
import { useGetIntlDateFormatString } from 'helpers';

const DateTooltip: React.FC<DateTooltipProps> = (props) => {
  const { values, children, placement = 'top-start' } = props;

  const intlDate = useGetIntlDateFormatString();

  const tooltipValue = useCallback(
    () =>
      values.map((v, index) => {
        return (
          <Typography key={index}>
            {`${v.message} - ${
              v.date ? moment(v.date).format(intlDate) : 'None'
            }`}
          </Typography>
        );
      }),
    [values, intlDate]
  );

  return (
    <Tooltip placement={placement} title={tooltipValue()}>
      {children}
    </Tooltip>
  );
};

export default DateTooltip;
