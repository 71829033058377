import { makeStyles } from '@mui/styles';
import { colorPalette } from 'ui/theme';

export const useSerialModalStyle = makeStyles(() => ({
  chipSuccess: {
    backgroundColor: colorPalette.statusGreen,
  },
  chipWarning: {
    backgroundColor: colorPalette.statusOrange,
  },
  trackingDetails: {
    display: 'flex',
    justifyContent: 'start',
    width: '100%',
    padding: '24px',
  },
  trackingQty: {
    display: 'flex',
    justifyContent: 'start',
    width: '100%',
    paddingTop: '20px',
    paddingLeft: '24px',
  },
}));
