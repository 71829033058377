import { GlobalSettings } from './types';

export const transformGlobalSettings = (response: any): GlobalSettings => {
  return {
    productExport: {
      masterSku: response?.productExport?.masterSku,
      productTitle: response?.productExport?.productTitle,
      upc: response?.productExport?.upc,
      shortDescription: response?.productExport?.shortDescription,
      longDescription: response?.productExport?.longDescription,
      limitTags: response?.productExport?.limitTags,
    },
    inventoryExport: {
      quantityType: response?.inventoryExport?.quantityType,
      inventoryWarehousesEnabled:
        response?.inventoryExport?.inventoryWarehousesEnabled,
    },
  };
};

export const transformToGlobalSettingsPut = (
  globalSettings: GlobalSettings
) => {
  return {
    productExport: {
      masterSku: globalSettings.productExport.masterSku,
      productTitle: globalSettings.productExport.productTitle,
      upc: globalSettings.productExport.upc,
      shortDescription: globalSettings.productExport.shortDescription,
      longDescription: globalSettings.productExport.longDescription,
      limitTags: globalSettings.productExport.limitTags,
    },
    inventoryExport: {
      quantityType: globalSettings.inventoryExport.quantityType,
      inventoryWarehousesEnabled:
        globalSettings.inventoryExport.inventoryWarehousesEnabled,
    },
  };
};
