import React from 'react';
import FBOButton from '../FBOButton/FBOButton';
import { Menu } from 'ui/components/Menu';
import { MenuItem } from 'ui/components/Menu';
import { ButtonProps } from '@mui/material';
import { PermissionType } from 'services/permissions';

type Variant = 'secondary' | 'tertiary';
type CustomProps = 'color' | 'variant';

type MuiProps = Omit<ButtonProps, CustomProps>;
type FBOMenuButtonProps = {
  variant: Variant;
  items: MenuItem[];
  'data-qa': string;
  tooltip?:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal;
  permissions?: PermissionType[];
} & MuiProps;

const FBOMenuButton = (props: FBOMenuButtonProps) => {
  const { variant, items, 'data-qa': dataQa, ...otherProps } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const isActionMenuVisible = Boolean(anchorEl);

  const handleIconClicked = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const handleActionMenuClose = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <FBOButton
        variant={variant}
        color="neutral"
        size="medium"
        icon="IconKebab"
        data-qa={dataQa}
        onClick={handleIconClicked}
        {...otherProps}
      />
      <Menu
        items={items}
        open={isActionMenuVisible}
        onClose={handleActionMenuClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      />
    </>
  );
};

export default FBOMenuButton;
