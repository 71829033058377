import { chainReducers, createApiReducer } from 'services/api';

import { SettingsCompanyActionTypes } from './actions';
import { transformCompanySettings } from '../transformations';

const fetchSettingsCompanyReducer = createApiReducer(
  [
    SettingsCompanyActionTypes.FETCH_COMPANY_START,
    SettingsCompanyActionTypes.FETCH_COMPANY_SUCCESS,
    SettingsCompanyActionTypes.FETCH_COMPANY_FAILURE,
  ],
  (data) => transformCompanySettings(data),
  (data) => data
);

const settingsCompanyChainedReducers = chainReducers(
  {},
  fetchSettingsCompanyReducer
);

export default settingsCompanyChainedReducers;
