import React, { memo, useMemo } from 'react';
import _ from 'lodash';

import { ViewTrackingModalProps } from './types';
import { Modal } from 'ui/components/Modal/Modal';
import { TrackingTableTypes } from 'ui/components/Table/TrackingTable';
import { TrackingDataTypes } from 'services/settings/tracking';
import TrackingTable from 'ui/components/Table/TrackingTable/TrackingTable';
import SerialTable from 'ui/components/Table/SerialTable/SerialTable';

const ViewTrackingModal: React.FC<ViewTrackingModalProps> = (props) => {
  const {
    open,
    itemTrackingTypes,
    trackingGroups,
    firstColumnTitle,
    disableAutoAssign = false,
    onClose,
    title = 'Tracking',
  } = props;

  const isOnlySerial = useMemo(
    () =>
      itemTrackingTypes.every(
        (itemTrackingType) =>
          _.get(itemTrackingType, 'trackingType.trackingDataType', null) ===
          TrackingDataTypes.SerialNumber
      ),
    [itemTrackingTypes]
  );

  return (
    <Modal
      open={open}
      title={title}
      withoutDefaultPadding
      onCancelClicked={onClose}
      cancelLabel="Close"
    >
      {!isOnlySerial ? (
        <TrackingTable
          itemTrackingTypes={itemTrackingTypes}
          trackingGroups={trackingGroups}
          onSetTrackingGroups={_.noop}
          tableType={TrackingTableTypes.View}
          firstColumnTitle={firstColumnTitle || 'Quantity'}
          withoutTitleBar
        />
      ) : (
        <SerialTable
          itemTrackingTypes={itemTrackingTypes}
          serialList={_.get(trackingGroups, '[0].serialList', [])}
          disableAutoAssign={disableAutoAssign}
        />
      )}
    </Modal>
  );
};

export default memo(ViewTrackingModal);
