import React, { useMemo } from 'react';
import { Grid, ListItem, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { IconNames } from 'ui/theme';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { PermissionType } from 'services/permissions';
import { activeUserHasPermission } from 'services/user/redux';
import { TextField } from 'ui/components/TextField/TextField';
import { getUoms } from 'services/uoms';

import { UomConversionListItemProps } from './types';
import { roundToDecimals } from 'helpers';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

export const UomConversionListItem = (props: UomConversionListItemProps) => {
  const {
    conversion,
    activeUomName,
    uomIdsBeingUsed,
    index,
    conversionsError,
    activeUomId,
    onEditConversion,
    onDeleteConversion,
  } = props;

  const { items: uoms } = useSelector(getUoms);

  const canEditUom =
    activeUomId && activeUomId > 0
      ? [PermissionType.UomEdit]
      : [PermissionType.UomCreate];

  const canClick = useSelector(activeUserHasPermission(canEditUom));

  const getUomName = (uomId: number) => {
    const uom = uoms.find((u) => u.id === uomId);
    return uom ? uom.name : '';
  };

  const selectedToUom = useMemo(() => {
    return uoms.find((uom) => uom.id === conversion.toUomId) || null;
  }, [uoms, conversion.toUomId]);

  const uomOptions = useMemo(() => {
    return uoms.filter((uom) => !uomIdsBeingUsed.includes(uom.id!));
  }, [uoms, uomIdsBeingUsed]);

  const handleQuantityChanged = (e: any) => {
    const factor = !e.target.value
      ? null
      : roundToDecimals(parseFloat(e.target.value));

    onEditConversion({ ...conversion, factor }, index);
  };

  const handleAutoCompleteChanged = (_e: any, v: any) => {
    onEditConversion({ ...conversion, toUomId: v ? v.id : null }, index);
  };

  const handleDeleteClick = () => {
    const toId = conversion.toUomId;
    const toUom = uoms.find((u) => u.id === toId);

    const fromId = conversion.fromUomId;
    const fromUom = uoms.find((u) => u.id === fromId);

    onDeleteConversion(
      toUom ? toUom.name! : '',
      fromUom ? fromUom.name! : activeUomName!,
      index
    );
  };

  return (
    <ListItem disableGutters>
      <Grid container spacing={2} alignItems="center" xl={4} xs={7}>
        <Grid item xs={3}>
          <TextField
            label="Quantity"
            type="number"
            placeholder="Quantity"
            name="quantity"
            required
            permissions={canEditUom}
            value={conversion.factor}
            onChange={handleQuantityChanged}
            error={!!_.get(conversionsError, `toConversions[${index}].factor`)}
            dataQa="conversion-amount"
            disableDebounce={true}
          />
        </Grid>
        <Grid item xs={3}>
          {conversion.id < 0 ? (
            <Autocomplete
              label="Unit"
              placeholder="UOM"
              options={uomOptions}
              getOptionLabel={(uom) => uom.name}
              isOptionEqualToValue={() => true}
              onChange={handleAutoCompleteChanged}
              value={selectedToUom}
              required
              error={
                !!_.get(conversionsError, `toConversions[${index}].toUomId`)
              }
              dataQa="conversion-unit"
            />
          ) : (
            <Typography data-qa="conversion-unit-typography">
              <b>{getUomName(conversion.toUomId || 0)}</b>
            </Typography>
          )}
        </Grid>
        <Grid item>
          <Typography>
            <i>per</i>
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            <b>{getUomName(conversion.fromUomId || 0) || activeUomName}</b>
          </Typography>
        </Grid>
        {canClick && (
          <Grid item>
            <FBOButton
              variant="tertiary"
              color="negative"
              size="medium"
              icon={IconNames.TrashCan}
              onClick={handleDeleteClick}
              data-qa="conversion-delete"
            />
          </Grid>
        )}
      </Grid>
    </ListItem>
  );
};
