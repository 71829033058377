import React, { memo, useCallback, useMemo } from 'react';
import {
  TableCell,
  Typography,
  Link,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import _ from 'lodash';

import { Column } from 'ui/components/Table/ItemsTable/types';
import { DatePickerWrapper } from 'ui/components/TextField/DatePickerWrapper';
import { TextField } from 'ui/components/TextField/TextField';
import { TrackingType, TrackingDataTypes } from 'services/settings/tracking';
import { EACH_UOM_ID } from 'services/uoms';
import { TextFieldQuantity } from 'ui/components/TextField/TextFieldQuantity';

import { TrackingColumnsProps } from '../../types';
import { calculateSerialLinkLabel } from './helpers';
import { getTrackingTypeColumns } from '../../helpers';
import { itemHasOnlyLotTracking } from 'services/inventory';
import { Item } from 'services/items';
import { formatDatePreview, useGetIntlDateFormatString } from 'helpers';
import { getSettingsCompanyCountry } from 'services/settings/company';
import { useSelector } from 'react-redux';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { IconNames, colorPalette } from 'ui/theme';

const TrackingAddColumns: React.FC<TrackingColumnsProps> = (props) => {
  const {
    trackingGroup,
    onAmountChange,
    onSerialLinkClick,
    createLotChangeHandler,
    createDateChangeHandler,
    handleLotAutoAssign,
    handleDateAutoAssign,
    itemTrackingTypes,
    columns,
    formValues,
    itemUom,
    inventoryUom,
    isDecimal,
    isCycle,
  } = props;
  const intlFormatDate = useGetIntlDateFormatString();
  const companyCountry = useSelector(getSettingsCompanyCountry);
  const trackingTypeColumns = useMemo(
    () => getTrackingTypeColumns(columns),
    [columns]
  );
  const hasNoSelectedLocation = useMemo(() => {
    if (formValues) {
      return !formValues.locationFromId || formValues.locationFromId < 0;
    }
    return false;
  }, [formValues]);

  const handleLinkClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      onSerialLinkClick(e);
    },
    [onSerialLinkClick]
  );

  const renderAdornment = (
    adormentHandler: any,
    nextValue: string | null,
    newTrackingType: boolean,
    column: Column
  ) => {
    const trackingDataType: TrackingDataTypes = _.get(
      column,
      'meta.trackingDataType'
    );
    const isDisabled = nextValue ? false : true;

    const title =
      trackingDataType === TrackingDataTypes.Date
        ? 'Populate with the next expiration date in the sequence.'
        : 'Populate with the next lot number in the sequence.';

    return (
      <InputAdornment position="end" style={{ marginRight: '0px' }}>
        <Tooltip title={title} placement="top">
          <FBOButton
            onClick={() => adormentHandler()}
            disabled={isDisabled || hasNoSelectedLocation || newTrackingType}
            size="large"
            variant="tertiary"
            color="neutral"
            icon={IconNames.IconFlashFilledLightning}
            data-qa={`tracking-flash-icon-${trackingDataType}`}
            style={{ color: colorPalette.redesign.contentSecondary }}
            sx={{ padding: '8px', minWidth: '0px' }}
          />
        </Tooltip>
      </InputAdornment>
    );
  };

  const renderTrackingColumn = (column: Column): JSX.Element | null => {
    const trackingDataType: TrackingDataTypes = _.get(
      column,
      'meta.trackingDataType'
    );
    const trackingType: TrackingType = _.get(column, 'meta.trackingType', {});
    const trackingTypeIndex = trackingGroup.trackingInfoList.findIndex(
      (t) => t.trackingTypeId === trackingType.id
    );
    const trackingInfo = trackingGroup.trackingInfoList[trackingTypeIndex];
    const trackingTypeId = trackingInfo?.trackingTypeId;

    const isTrackingInfoNew = trackingInfo && trackingInfo.id === -1;
    const quantity = trackingGroup.quantity || 0;
    const serialQuantity = trackingGroup.serialQuantity || quantity;
    const serialLinkLabel = calculateSerialLinkLabel(
      serialQuantity - trackingGroup.serialList.length
    );
    const itemTrackingType = itemTrackingTypes.find((t) =>
      t.trackingType ? t.trackingType.id === trackingType.id : null
    );

    const newItemTrackingType =
      !itemTrackingType || !itemTrackingType.id || itemTrackingType.id < 0;

    switch (trackingDataType) {
      case TrackingDataTypes.Text:
        return isTrackingInfoNew ? (
          <TextField
            className="redesign no-margin"
            variant="standard"
            disabled={hasNoSelectedLocation}
            onChange={createLotChangeHandler(trackingTypeIndex)}
            value={trackingInfo.value}
            placeholder="Add tracking"
            additionalInputProps={{
              endAdornment: itemTrackingType
                ? renderAdornment(
                    handleLotAutoAssign(
                      trackingTypeIndex,
                      itemTrackingType.id,
                      trackingTypeId
                    ),
                    itemTrackingType.nextValue,
                    newItemTrackingType,
                    column
                  )
                : null,
            }}
            inputProps={{ 'data-qa': 'lot-tracking-add' }}
          />
        ) : (
          <Typography>{trackingInfo ? trackingInfo.value : null}</Typography>
        );
      case TrackingDataTypes.Date: {
        const formattedDateValue = formatDatePreview(
          trackingInfo?.value,
          companyCountry,
          intlFormatDate
        );
        return isTrackingInfoNew ? (
          <DatePickerWrapper
            disabled={hasNoSelectedLocation}
            onChange={createDateChangeHandler(trackingTypeIndex)}
            value={trackingInfo.value}
            InputProps={{
              endAdornment: itemTrackingType
                ? renderAdornment(
                    handleDateAutoAssign(
                      trackingTypeIndex,
                      itemTrackingType.id,
                      trackingTypeId
                    ),
                    itemTrackingType.nextValue,
                    newItemTrackingType,
                    column
                  )
                : null,
            }}
            InputAdornmentProps={{ position: 'start' }}
            fullWidth
            noMargin
            inputProps={{ 'data-qa': 'expiration-date-tracking-add' }}
          />
        ) : (
          <Typography>{formattedDateValue}</Typography>
        );
      }

      case TrackingDataTypes.SerialNumber:
        return serialQuantity ? (
          <Link
            href="#"
            underline="always"
            onClick={handleLinkClick}
            color={
              serialQuantity - trackingGroup.serialList.length !== 0
                ? 'error'
                : 'primary'
            }
            data-qa="serial-tracking-add"
          >
            {serialLinkLabel}
          </Link>
        ) : null;
      default:
        return null;
    }
  };

  const shouldSubstractCommitted =
    isCycle &&
    itemHasOnlyLotTracking({ itemTrackingTypeList: itemTrackingTypes } as Item);

  return (
    <>
      <TableCell>
        <Typography>
          {trackingGroup.onHand -
            (shouldSubstractCommitted ? trackingGroup.committedQuantity : 0)}
          {` ${inventoryUom ? inventoryUom.abbreviation : ''}`}
        </Typography>
      </TableCell>
      <TableCell>
        <TextFieldQuantity
          disabled={hasNoSelectedLocation}
          selectedUomId={itemUom ? itemUom.id! : EACH_UOM_ID}
          uoms={itemUom ? [itemUom] : []}
          value={trackingGroup.quantity}
          onTextChange={onAmountChange}
          dataQa="tracking-table-quantity"
          isDecimal={isDecimal}
          noMargin
        />
      </TableCell>
      {trackingTypeColumns.map((column: Column, index: number) => (
        <TableCell key={index} data-qa={`serial-tracking-row`}>
          {renderTrackingColumn(column)}
        </TableCell>
      ))}
    </>
  );
};

export default memo(TrackingAddColumns);
