import { logErrorCtx } from 'app/logging';
import _ from 'lodash';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Errors } from 'services/forms/validation';

import {
  getShippingSettings,
  initialShippingIntegrationSettings,
  ShippingIntegrationRate,
  ShippingIntegrationSettings,
} from 'services/integrations/shipping';
import {
  initialPurchaseLabel,
  InternationalData,
  PurchaseLabel,
  PurchaseLabelExtra,
} from 'services/integrations/shipping/purchaseLabel';
import { getShippingConnection } from 'services/integrations/shipping/redux';
import { Ship, ShipCarton } from 'services/shipping';

import { setPurchaseLabelFromSettings } from './helpers';

interface PurchaseLabelContextProps {
  purchaseLabel: PurchaseLabel;
  setPurchaseLabel: React.Dispatch<React.SetStateAction<PurchaseLabel>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  cartons: ShipCarton[];
  rates: ShippingIntegrationRate[];
  ratesValidation: Errors;
  setRates: React.Dispatch<React.SetStateAction<ShippingIntegrationRate[]>>;
  setRatesValidation: React.Dispatch<React.SetStateAction<Errors>>;
  settings: ShippingIntegrationSettings;
  notUSAddress: boolean;
}

interface PurchaseLabelProviderProps {
  ship: Ship;
}

export const PurchaseLabelContext = createContext<PurchaseLabelContextProps>({
  purchaseLabel: initialPurchaseLabel,
  setPurchaseLabel: () => {},
  isLoading: false,
  setIsLoading: () => {},
  cartons: [],
  rates: [],
  ratesValidation: {},
  setRates: () => {},
  setRatesValidation: () => {},
  settings: initialShippingIntegrationSettings,
  notUSAddress: false,
});

export const PurchaseLabelProvider: React.FC<PurchaseLabelProviderProps> = ({
  children,
  ship,
}) => {
  const connection = useSelector(getShippingConnection);

  const [purchaseLabel, setPurchaseLabel] =
    useState<PurchaseLabel>(initialPurchaseLabel);
  const [isLoading, setIsLoading] = useState(false);
  const [rates, setRates] = useState<ShippingIntegrationRate[]>([]);
  const [ratesValidation, setRatesValidation] = useState<Errors>({});
  const [settings, setSettings] = useState<ShippingIntegrationSettings>(
    initialShippingIntegrationSettings
  );

  // we want only cartons that do not have trackingNumber
  const cartons = useMemo(
    () => ship.shipCartonList.filter((c) => !c.trackingNumber),
    [ship.shipCartonList]
  );

  const notUSAddress = useMemo(() => {
    return (
      ship.shipToCountry !== 'US' ||
      _.get(ship, 'salesOrder.location.address.country') !== 'US'
    );
  }, [ship]);

  useEffect(() => {
    if (!ship.id || ship.id < 0 || !connection) {
      return;
    }

    (async () => {
      try {
        const resSettings = await getShippingSettings(connection);
        setSettings(resSettings);
        setPurchaseLabelFromSettings(
          resSettings,
          ship,
          setPurchaseLabel,
          connection
        );
      } catch (e) {
        logErrorCtx('Error in getShippingSettings', {
          error: e as Error,
          stackTrace: (e as Error).stack,
          component: 'PurchaseLabelProvider',
          title: 'Error in getShippingSettings',
          description: 'Error in getShippingSettings',
        });
      }
    })();
  }, [connection, ship]);

  return (
    <PurchaseLabelContext.Provider
      value={{
        purchaseLabel,
        setPurchaseLabel,
        isLoading,
        setIsLoading,
        cartons,
        rates,
        ratesValidation,
        setRates,
        setRatesValidation,
        settings,
        notUSAddress,
      }}
    >
      {children}
    </PurchaseLabelContext.Provider>
  );
};

export const usePurchaseLabelExtra = (): {
  purchaseLabelExtra: PurchaseLabelExtra;
  setPurchaseLabelExtra: React.Dispatch<
    React.SetStateAction<PurchaseLabelExtra>
  >;
} => {
  const { purchaseLabel, setPurchaseLabel } = useContext(PurchaseLabelContext);

  const setPurchaseLabelExtra = useCallback(
    (purchaseLabelExtra: React.SetStateAction<PurchaseLabelExtra>) => {
      if (typeof purchaseLabelExtra === 'function') {
        setPurchaseLabel((old) => ({
          ...old,
          purchaseLabelExtra: purchaseLabelExtra(old.purchaseLabelExtra),
        }));

        return;
      }

      setPurchaseLabel((old) => ({ ...old, purchaseLabelExtra }));
    },
    [setPurchaseLabel]
  );

  return {
    purchaseLabelExtra: purchaseLabel.purchaseLabelExtra,
    setPurchaseLabelExtra,
  };
};

export const useInternationalData = (): {
  internationalData: InternationalData;
  setInternationalData: React.Dispatch<React.SetStateAction<InternationalData>>;
} => {
  const { purchaseLabel, setPurchaseLabel } = useContext(PurchaseLabelContext);

  const setInternationalData = useCallback(
    (internationalData: React.SetStateAction<InternationalData>) => {
      if (typeof internationalData === 'function') {
        setPurchaseLabel((old) => ({
          ...old,
          internationalData: internationalData(old.internationalData),
        }));

        return;
      }

      setPurchaseLabel((old) => ({ ...old, internationalData }));
    },
    [setPurchaseLabel]
  );

  return {
    internationalData: purchaseLabel.internationalData,
    setInternationalData,
  };
};
