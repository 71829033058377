import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import styled from 'styled-components';
import { colorPalette } from 'ui/theme';

import { TextField } from 'ui/components/TextField/TextField';

export const useSearchHeaderStyle = makeStyles((theme: Theme) => ({
  chip: {
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    borderRadius: 15,
  },
  link: {
    'text-transform': 'none',
    'white-space': 'pre',
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
  },
  buttonLink: {
    'text-transform': 'none',
    'white-space': 'pre',
    cursor: 'pointer',
  },
  search: {
    width: 408,
  },
  tabs: {
    maxWidth: '700px',
  },
  secondaryButton: {
    marginRight: theme.spacing(2),
  },
  label: {
    color: theme.palette.text.primary,
  },
  apply: {
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(5),
  },
  wrapper: {
    flexDirection: 'row-reverse',
    justifyContent: 'space-evenly',
  },
  searchTab: {
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: 40,
  },
  clearIcon: {
    marginLeft: theme.spacing(1),
  },
  showAllButton: {
    cursor: 'pointer',
  },
  tabsRoot: {
    background: 'transparent',
  },
  scrollable: {
    background: 'transparent',
  },
  filterButton: {
    marginLeft: 10,
    border: '1px solid #D6D6D6',
    padding: 6,
    borderRadius: 3,
  },
  autocompleteViews: {
    width: 280,
  },
  clearViewOption: {
    borderTop: '1px solid #D6D6D6',
  },
  clearInput: {
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  saveChip: {
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    borderRadius: 3,
    color: '#0029F0',
  },
  saveIcon: {
    fill: '#0029F0',
  },
}));

export const SearchInput = styled(TextField)`
  & input {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

export const FBOSearchInput = styled(TextField)({
  backgroundColor: colorPalette.white,
  border: `1px solid ${colorPalette.redesign.background4}`,
  borderRadius: '3px',
  svg: {
    color: colorPalette.redesign.contentSecondary,
  },
  'input::placeholder': {
    color: colorPalette.redesign.contentSecondary,
    opacity: 1,
  },
  fieldset: { border: 'none' },
});
