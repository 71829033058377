import { State } from 'app/redux';

export const getVendors = (state: State) => state.vendor.vendors;

export const getVendorById = (id: number | null) => (state: State) => {
  if (id === null) {
    return;
  }

  return state.vendor.vendors.items.find((item) => item.id === id);
};

export const getSearches = (state: State) => state.vendor.searches;
