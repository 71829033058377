import { ItemType } from './../../items/types';
export interface ModuleNavigationState {
  type: ModuleNavigationType | null;
  navigationItems: ModuleNavigationItems;
}

export interface ModuleNavigationItems {
  salesOrderIds?: number[];
  pickIds?: number[];
  shipIds?: number[];
  receiptId?: number;
  purchaseOrderId?: number;
  itemId?: number;
  itemType?: ItemType;
}

export enum ModuleNavigationType {
  Sales,
  Purchase,
  Materials,
}
