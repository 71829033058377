import React, { memo } from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';

import { CardSectionEmptyProps, CardSectionProps } from './types';
import { useCardSectionStyle } from './styled';

const CardSection: React.FC<CardSectionProps> = (props) => {
  const { title, sectionId, children } = props;

  const classes = useCardSectionStyle(props);

  return (
    <Box className={classes.cardSectionContainer} id={sectionId}>
      <Box className={classes.cardSectionHeader}>
        <Typography variant="h5">
          <b>{title}</b>
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {children ? children : <CardSectionEmpty classes={classes} />}
      </Grid>
    </Box>
  );
};

const CardSectionEmpty: React.FC<CardSectionEmptyProps> = ({ classes }) => (
  <Grid item spacing={2} xs={4} xl={3}>
    <Paper className={classes.cardSectionEmpty} elevation={0}>
      <Typography variant="body1">No available extensions</Typography>
    </Paper>
  </Grid>
);

export default memo(CardSection);
