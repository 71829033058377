import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { colorPalette } from 'ui/theme';

export const useTagsModalStyles = makeStyles((theme: Theme) => ({
  tagInput: {
    '& input': {
      background: colorPalette.white,
    },
    '& input[readonly]': {
      cursor: 'pointer',
      background: 'transparent',
    },
  },
  tagIcon: {
    marginRight: theme.spacing(1),
    color: colorPalette.applicationDarkGray60,
  },
  tagIcons: {
    color: colorPalette.applicationDarkGray60,
  },
  tagEditing: {
    opacity: 0,
    visibility: 'hidden',
    transition: 'all .125s ease-in-out',
  },
  tag: {
    paddingLeft: theme.spacing(2),
    background: '#f1f1f1',
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    borderRadius: 10,
    height: 54,
    minHeight: 54,
    flexShrink: 0,
    '&:hover .tagEditToolbar': {
      opacity: 1,
      visibility: 'visible',
    },
  },
}));

export const useTagsAutocompleteStyles = makeStyles((theme: Theme) => ({
  input: {
    '& .MuiAutocomplete-tag': {
      background: theme.palette.primary.main,
      borderRadius: 20,
      color: colorPalette.white,
      '& span': {
        color: colorPalette.white,
        fontSize: theme.typography.body2.fontSize,
        fontWeight: 600,
      },
      '& svg': {
        color: 'inherit',
        opacity: 0.6,
        transition: 'all .125s ease-in-out',
        '&:hover': {
          opacity: 1,
        },
      },
    },
  },
  tagButton: {
    color: colorPalette.fishbowlCorporateGray1,
    '&:hover': {
      backgroundColor: colorPalette.buttonHoverBackgroundColor,
    },
  },
}));
