import moment from 'moment';

import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';
import {
  getSearches,
  addSearch,
  updateSearch,
  deleteSearch,
} from 'services/vendors';
import { AdvancedSearchReduxFunctions } from 'services/search';
import { PaymentTerm } from 'services/paymentTerms';
import { PaginationDirection } from 'services/url';
import { Dates } from 'helpers';
import { Tag } from 'services/tags';

export const advancedSearchReduxActions: AdvancedSearchReduxFunctions = {
  add: addSearch,
  delete: deleteSearch,
  update: updateSearch,
  get: getSearches,
};

export const initialVendorsFormValues = {
  name: null,
  paymentTermId: null,
  email: null,
  cc: null,
  bcc: null,
  phone: null,
  fax: null,
  'vendorAddresses.street': null,
  'vendorAddresses.city': null,
  'vendorAddresses.state': null,
  'vendorAddresses.postalCode': null,
  'vendorAddresses.country': null,
  notes: null,
  'tags.id': null,
  url: null,
  mobile: null,
  acctNum: null,
  dateCreatedFrom: null,
  dateCreatedTo: null,
  dateLastModifiedFrom: null,
  dateLastModifiedTo: null,
  deleted: null,
};

export const displayNameMap = {
  name: 'Name',
  paymentTermId: 'Payment Term',
  dateCreatedFrom: 'Date Created After',
  dateCreatedTo: 'Date Created Before',
  dateLastModifiedFrom: 'Date Modified After',
  dateLastModifiedTo: 'Date Modified Before',
  'tags.id': 'Tag',
  acctNum: 'Account Number',
  email: 'Email',
  cc: 'CC',
  bcc: 'BCC',
  phone: 'Phone',
  fax: 'Fax',
  mobile: 'Mobile',
  'vendorAddresses.street': 'Sstreet',
  'vendorAddresses.city': 'City',
  'vendorAddresses.state': 'State',
  'vendorAddresses.postalCode': 'Postal code',
  'vendorAddresses.country': 'Country',
  url: 'URL',
  notes: 'Notes',
  deleted: 'Deleted',
};

export const createDisplayValueMap = (
  paymentTerms: PaymentTerm[],
  tags: Tag[],
  format: string
) => ({
  paymentTermId: (paymentId: number) => {
    const pt = paymentTerms.find((p) => p.id === paymentId);
    if (pt) {
      return pt.name;
    }
    return '';
  },
  dateCreatedFrom: (date: string) => (date ? moment(date).format(format) : ''),
  dateCreatedTo: (date: string) => (date ? moment(date).format(format) : ''),
  dateLastModifiedFrom: (date: string) =>
    date ? moment(date).format(format) : '',
  dateLastModifiedTo: (date: string) =>
    date ? moment(date).format(format) : '',
  'tags.id': () => tags.map((t) => t.name).join(', '),
});

export const VENDOR_COLUMNS = (date: Dates): Column[] => {
  const resolveDateColumn = (): Column => {
    switch (date) {
      case Dates.DateLastModified:
        return {
          title: 'Date Last Modified',
          field: Dates.DateLastModified,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
        };
      default:
        return {
          title: 'Date Created',
          field: Dates.DateCreated,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
        };
    }
  };

  return [
    {
      title: 'Name',
      field: 'name',
      sortable: true,
      type: ColumnTypes.string,
    },
    {
      title: 'Payment Term',
      type: ColumnTypes.renderByCustomRow,
      field: 'paymentTerm.name',
      sortable: true,
    },
    resolveDateColumn(),
    {
      title: '',
      width: 50,
      type: ColumnTypes.renderByCustomRow,
    },
  ];
};

export const VENDOR_COLUMNS_SMALL: Column[] = [
  {
    title: 'Name',
    type: ColumnTypes.renderByCustomRow,
  },
];

export const initialPagination = {
  page: 1,
  pageSize: 25,
  sort: {
    sortBy: 'id',
    direction: PaginationDirection.Descending,
  },
  totalRows: 25,
};
