import React, { memo } from 'react';
import { Box, Typography } from '@mui/material';

const TrackingWizardSummary: React.FC<any> = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
      flexDirection="column"
    >
      <Typography variant="h4">Items ready</Typography>
      <Typography variant="body1">
        Tracking will be added to existing items
      </Typography>
    </Box>
  );
};

export default memo(TrackingWizardSummary);
