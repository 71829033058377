import { createFilterOptions, FilterOptionsState } from '@mui/material';

import {
  initialRepresentative,
  Representative,
} from 'services/representatives';
import { fetchRepresentativesAPI } from 'services/representatives/api';
import { Pagination } from 'services/search';

const filter = createFilterOptions<Representative>();

export const currencyAutocompleteOptionLabel = (
  representative: Representative
) => {
  if (!representative.id || representative.id < 0) {
    return `+ Add new ${representative.firstName}`;
  }

  return representative.firstName || '';
};

export const representativeAutocompleteFilterOptions =
  (disableAdd: boolean) =>
  (options: Representative[], params: FilterOptionsState<Representative>) => {
    const filtered = filter(options, params);

    if (disableAdd) {
      return filtered;
    }

    if (!!params.inputValue) {
      filtered.unshift({
        ...initialRepresentative,
        firstName: params.inputValue || null,
      });
    }

    return filtered;
  };

export const resolvedFetchRepresentativeFunction = (
  expands?: string[],
  customQuickSearchColumns?: string[]
) => {
  return (pagination: Pagination, searchValue: string | null) =>
    fetchRepresentativesAPI({
      pagination,
      expands: expands,
      quickSearchValue: searchValue,
      customQuickSearchColumns,
    });
};
