import { datadogLogs } from '@datadog/browser-logs';
import { MessageType, MessageContext } from './types';

const logger = (
  messageType: MessageType,
  message: string,
  messageContext: Partial<MessageContext>
): void => {
  datadogLogs.logger[messageType](message, messageContext);
};

export const logDebugCtx = (
  message: string,
  messageContext: Partial<MessageContext>
) => logger('debug', message, messageContext);

export const logInfoCtx = (
  message: string,
  messageContext: Partial<MessageContext>
) => logger('info', message, messageContext);

export const logWarnCtx = (
  message: string,
  messageContext: Partial<MessageContext>
) => logger('warn', message, messageContext);

export const logErrorCtx = (
  message: string,
  messageContext: Partial<MessageContext>
) => logger('error', message, messageContext);
