import _ from 'lodash';

import { PermissionArea, PermissionGroup } from 'services/permissions';

import { PermissionAreaState } from './types';

export const getPermissionAreaState = (
  permissionArea: PermissionArea,
  groupPermissionIds: number[]
): PermissionAreaState => {
  const areaPermissionIds = permissionArea.permissions.map((p) => p.id);

  // permission ids from area that are not checked
  const notCheckedIds = _.difference(areaPermissionIds, groupPermissionIds);

  if (notCheckedIds.length === 0) {
    return PermissionAreaState.Checked;
  }

  if (notCheckedIds.length === areaPermissionIds.length) {
    return PermissionAreaState.NotChecked;
  }

  return PermissionAreaState.PartiallyChecked;
};

export const addAreaPermissions = (
  permissionArea: PermissionArea,
  setPermissionGroup: React.Dispatch<React.SetStateAction<PermissionGroup>>
) => {
  setPermissionGroup((old) => {
    // get permissions from area that are not checked and add them to permission group
    const permissionsToAdd = permissionArea.permissions.filter(
      (p) => !old.permissionIds.includes(p.id)
    );

    return {
      ...old,
      permissionIds: [
        ...old.permissionIds,
        ...permissionsToAdd.map((p) => p.id),
      ],
      permissions: [...old.permissions, ...permissionsToAdd],
    };
  });
};

export const removeAreaPermissions = (
  permissionArea: PermissionArea,
  setPermissionGroup: React.Dispatch<React.SetStateAction<PermissionGroup>>
) => {
  // remove all area permissions from permission group
  const permissionIdsToRemove = permissionArea.permissions.map((p) => p.id);
  setPermissionGroup((old) => ({
    ...old,
    permissionIds: old.permissionIds.filter(
      (id) => !permissionIdsToRemove.includes(id)
    ),
    permissions: old.permissions.filter(
      (p) => !permissionIdsToRemove.includes(p.id)
    ),
  }));
};
