import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useReportsViewerSidebarStyle = makeStyles((theme: Theme) => ({
  sidebar: {
    width: 240,
    borderLeft: '1px solid #EBEBEB',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
  },
  header: {
    padding: theme.spacing(2),
    borderBottom: '1px solid #EBEBEB',
  },
  content: {
    flex: 1,
    overflowY: 'auto',
    overflowX: 'visible',
    '& .MuiAccordionSummary-root.Mui-expanded': {
      borderBottom: 'none',
    },
  },
  footer: {
    borderTop: '1px solid #EBEBEB',
    padding: theme.spacing(1),
  },
  footerButton: {
    height: 48,
  },
  radioButton: {
    flexDirection: 'column',
  },
}));
