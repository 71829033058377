import React, { useCallback } from 'react';
import { Grid, Box, Typography } from '@mui/material';

import { usePopoverItemStyle } from './styled';

const PopoverItem: React.FC<{
  label: string;
  value: string;
  onClick(value: string): void;
}> = (props) => {
  const { label, value, onClick } = props;
  const classes = usePopoverItemStyle();

  const handleOnClick = useCallback(
    (value: string) => (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      onClick(value);
    },
    [onClick]
  );

  return (
    <Grid
      container
      classes={{ root: classes.popover }}
      onClick={handleOnClick(value)}
    >
      <Grid item xs={6}>
        <Box py={1} pl={1}>
          <Typography
            variant="body2"
            color="textSecondary"
            data-qa="popover-item"
          >
            {label}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box textAlign="right" py={1} p={1}>
          <Typography>{value}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default React.memo(PopoverItem);
