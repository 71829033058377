import _ from 'lodash';

import {
  getSearches,
  addSearch,
  deleteSearch,
  updateSearch,
  Location,
} from 'services/locations';
import { PaginationDirection } from 'services/url';
import {
  AdvancedSearchFormValues,
  AdvancedSearchReduxFunctions,
} from 'services/search';

export const advancedSearchReduxActions: AdvancedSearchReduxFunctions = {
  add: addSearch,
  delete: deleteSearch,
  update: updateSearch,
  get: getSearches,
};

export const displayNameMap = {
  name: 'Name',
  path: 'Path',
  type: 'Type',
  parentLocationId: 'Parent Location',
  description: 'Description',
  'address.street': 'Street',
  'address.city': 'City',
  'address.state': 'State',
  'address.postalCode': 'Postal Code',
  'address.country': 'Country',
  deleted: 'Deleted',
};

export const createDisplayValueMap = (locations: Location[]) => ({
  parentLocationId: (id: number) =>
    _.get(
      locations.find((l) => l.id === id),
      'name',
      null
    ),
});

export const initialLocationASFormValues: AdvancedSearchFormValues = {
  name: null,
  path: null,
  type: null,
  parentLocationId: null,
  description: null,
  'address.street': null,
  'address.city': null,
  'address.state': null,
  'address.postalCode': null,
  'address.country': null,
  deleted: null,
};

export const initialPagination = {
  page: 1,
  pageSize: 25,
  sort: {
    sortBy: 'sortNumber',
    direction: PaginationDirection.Ascending,
  },
  totalRows: 25,
};
