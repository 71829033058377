import React, { memo, useCallback } from 'react';

import { PermissionType } from 'services/permissions';

import { VendorTitleBarProps } from './types';
import { createActionBarOptions } from '../VendorDetailsCard/consts';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';

const VendorTitleBar: React.FC<VendorTitleBarProps> = (props) => {
  const {
    activeVendor,
    onSave,
    onClose,
    deleteModalVisible,
    showReportModal,
    onUndeleteClicked,
  } = props;

  const DeletedVendorActionsRestyle = useCallback(() => {
    return (
      <>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="vendor-save"
          onClick={() => onUndeleteClicked(false)}
          permissions={
            activeVendor.id && activeVendor.id > 0
              ? [PermissionType.VendorsEdit]
              : [PermissionType.VendorsCreate]
          }
        >
          Undelete
        </FBOButton>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="vendor-save-and-close"
          onClick={() => onUndeleteClicked(true)}
          permissions={
            activeVendor.id && activeVendor.id > 0
              ? [PermissionType.VendorsEdit]
              : [PermissionType.VendorsCreate]
          }
        >
          Undelete And Close
        </FBOButton>
      </>
    );
  }, [onUndeleteClicked, activeVendor.id]);

  const VendorActionsRestyle = useCallback(() => {
    return (
      <>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="vendor-save"
          onClick={() => onSave(false)}
          permissions={
            activeVendor.id && activeVendor.id > 0
              ? [PermissionType.VendorsEdit]
              : [PermissionType.VendorsCreate]
          }
        >
          Save
        </FBOButton>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="vendor-save-and-close"
          onClick={async () => {
            await onSave(true);
            onClose();
          }}
          permissions={
            activeVendor.id && activeVendor.id > 0
              ? [PermissionType.VendorsEdit]
              : [PermissionType.VendorsCreate]
          }
        >
          Save And Close
        </FBOButton>
        {!activeVendor.id ||
          (activeVendor.id === -1 ? null : (
            <FBOButton
              sx={{ marginRight: '8px' }}
              variant="secondary"
              color="negative"
              size="medium"
              icon="TrashCan"
              data-qa="vendor-delete"
              onClick={() => deleteModalVisible(true)}
              permissions={[PermissionType.VendorsDelete]}
            >
              Delete
            </FBOButton>
          ))}
        <FBOMenuButton
          sx={{ marginRight: '8px' }}
          variant="tertiary"
          data-qa="vendors-single-three-dot-menu"
          items={createActionBarOptions(activeVendor.id, () =>
            showReportModal(true)
          )}
        />
      </>
    );
  }, [activeVendor.id, onSave, deleteModalVisible, showReportModal]);

  return (
    <FBOTitleBar title={activeVendor && activeVendor.name}>
      {activeVendor.deleted ? (
        <DeletedVendorActionsRestyle />
      ) : (
        <VendorActionsRestyle />
      )}
      <FBOButton
        variant="tertiary"
        color="neutral"
        size="medium"
        icon="FBOClose"
        data-qa="vendor-close"
        onClick={onClose}
      />
    </FBOTitleBar>
  );
};

export default memo(VendorTitleBar);
