import { IconNames } from 'ui/theme';
import {
  Ship,
  packShip,
  unpackShip,
  finishShip,
  voidShip,
  ShipStatus,
} from 'services/shipping';
import { MenuItem } from 'ui/components/Menu';
import { ReportId } from 'services/reports';

export const defaultShip: Ship = {
  id: null,
  carrier: null,
  carrierId: null,
  carrierServiceId: null,
  customFields: [],
  notes: null,
  number: null,
  orderPriorityId: null,
  orderType: null,
  packedByUserId: null,
  parentLocationId: null,
  picks: [],
  purchaseOrderId: null,
  salesOrder: null,
  salesOrderId: null,
  salesOrders: [],
  shipCartonList: [],
  dateShip: null,
  dateCreated: null,
  dateLastModified: null,
  documents: [],
  shipItemList: [],
  shippedByUserId: null,
  shipToAttention: null,
  shipToCity: null,
  shipToCompanyName: null,
  shipToCountry: null,
  shipToName: null,
  shipToPostalCode: null,
  shipToResidential: false,
  shipToState: null,
  shipToStreet: null,
  shipToStreet2: null,
  shipToVerified: false,
  status: null,
  version: null,
  returnLabel: null,
  returnLabelTracking: null,
  returnLabelCost: null,
};

export const createActionBarOptions = (
  id: number | null,
  onShowReport: (reportId: ReportId) => void
): MenuItem[] => [
  {
    icon: IconNames.FBOReportSmall,
    label: 'Reports',
    disabled: !id || id < 0,
    subItems: [
      {
        label: 'Packing List',
        onOptionClick: () => onShowReport(ReportId.PackingList),
        separateAfter: true,
      },
      {
        label: 'Shipping Invoice',
        onOptionClick: () => onShowReport(ReportId.ShippingInvoice),
      },
    ],
  },
];

export enum ShipActions {
  pack = 'pack',
  unpack = 'unpack',
  finish = 'finish',
  void = 'void',
}

export const shipActions = {
  [ShipActions.pack]: packShip,
  [ShipActions.unpack]: unpackShip,
  [ShipActions.finish]: finishShip,
  [ShipActions.void]: voidShip,
};

export enum ShipAction {
  QuickShip,
  PurchaseLabel,
  FulFill,
}

export const createShippingOptions = (
  onActionClick: (actionType: ShipAction) => void,
  status: ShipStatus | null,
  quickShipDisabled: boolean,
  purchaseLabelDisabled: boolean
): MenuItem[] => [
  {
    label: 'Quick Ship',
    onOptionClick: () => onActionClick(ShipAction.QuickShip),
    icon: IconNames.PlayArrow,
    disabled: quickShipDisabled,
  },
  {
    label: 'Purchase Label',
    onOptionClick: () => onActionClick(ShipAction.PurchaseLabel),
    icon: IconNames.Sales,
    disabled: purchaseLabelDisabled,
    separateAfter: true,
  },
  {
    label: 'Fulfill',
    onOptionClick: () => onActionClick(ShipAction.FulFill),
    icon: IconNames.DoneAll,
    disabled: status === ShipStatus.Shipped,
  },
];
