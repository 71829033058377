import { createFilterOptions, FilterOptionsState } from '@mui/material';

import { Pagination } from 'services/search';
import { fetchVendorsAPI, initialVendor, Vendor } from 'services/vendors';

const filter = createFilterOptions<Vendor>();

export const vendorsAutocompleteOptionLabel = (vendor: Vendor) => {
  if (!vendor.id || vendor.id < 0) {
    return `+ Add "${vendor.name}"`;
  }

  return vendor.name || '';
};

export const vendorsAutocompleteFilterOptions =
  (disableAdd: boolean) =>
  (options: Vendor[], params: FilterOptionsState<Vendor>) => {
    const filtered = filter(options, params);

    if (disableAdd) {
      return filtered;
    }

    if (!!params.inputValue) {
      filtered.unshift({
        ...initialVendor,
        name: params.inputValue || null,
      });
    }

    return filtered;
  };

export const resolvedFetchVendorsFunction = (
  expands?: string[],
  customQuickSearchColumns?: string[]
) => {
  return (pagination: Pagination, searchValue: string | null) =>
    fetchVendorsAPI({
      pagination,
      expands: expands,
      quickSearchValue: searchValue,
      customQuickSearchColumns,
    });
};
