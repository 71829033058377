import React, { memo, useCallback } from 'react';
import { PermissionType } from 'services/permissions';
import { RepresentativeTitleBarProps } from './types';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const RepresentativesTitleBar: React.FC<RepresentativeTitleBarProps> = (
  props
) => {
  const {
    activeRepresentative,
    onSave,
    onClose,
    onUndeleteClicked,
    deleteModalVisible,
  } = props;

  const DeletedRepresentativeActionsRestyle = useCallback(() => {
    return (
      <>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="representative-undelete"
          onClick={() => onUndeleteClicked(false)}
          permissions={
            activeRepresentative.id && activeRepresentative.id > 0
              ? [PermissionType.RepresentativesEdit]
              : [PermissionType.RepresentativesCreate]
          }
        >
          Undelete
        </FBOButton>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="representatives-undelete-and-close"
          onClick={() => onUndeleteClicked(true)}
          permissions={
            activeRepresentative.id && activeRepresentative.id > 0
              ? [PermissionType.RepresentativesEdit]
              : [PermissionType.RepresentativesCreate]
          }
        >
          Undelete And Close
        </FBOButton>
      </>
    );
  }, [onUndeleteClicked, activeRepresentative.id]);

  const RepresentativeActionsRestyle = useCallback(() => {
    return (
      <>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="representatives-save"
          onClick={() => onSave(false)}
          permissions={
            activeRepresentative.id && activeRepresentative.id > 0
              ? [PermissionType.RepresentativesEdit]
              : [PermissionType.RepresentativesCreate]
          }
        >
          Save
        </FBOButton>
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="representatives-save-and-close"
          onClick={async () => {
            await onSave(true);
            onClose();
          }}
          permissions={
            activeRepresentative.id && activeRepresentative.id > 0
              ? [PermissionType.RepresentativesEdit]
              : [PermissionType.RepresentativesCreate]
          }
        >
          Save And Close
        </FBOButton>
        {activeRepresentative.id && activeRepresentative.id > 0 && (
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="secondary"
            color="negative"
            size="medium"
            icon="TrashCan"
            data-qa="representative-delete"
            onClick={() => deleteModalVisible(true)}
            permissions={[PermissionType.RepresentativesDelete]}
          >
            Delete
          </FBOButton>
        )}
      </>
    );
  }, [activeRepresentative.id, deleteModalVisible, onSave]);

  return (
    <FBOTitleBar
      title={`${activeRepresentative.firstName || 'New Representative'}`}
    >
      {activeRepresentative.deleted ? (
        <DeletedRepresentativeActionsRestyle />
      ) : (
        <RepresentativeActionsRestyle />
      )}
      <FBOButton
        variant="tertiary"
        color="neutral"
        size="medium"
        icon="FBOClose"
        data-qa="representatives-close"
        onClick={onClose}
      />
    </FBOTitleBar>
  );
};

export default memo(RepresentativesTitleBar);
