import React from 'react';
import { useSelector } from 'react-redux';
import { getCustomers } from 'services/customers/redux/selectors';
import { deleteDocuments } from 'services/documents/api';
import { getShippingConnection } from 'services/integrations/shipping/redux/selectors';
import { ItemType } from 'services/items/types';
import * as SalesOrdersApi from 'serviceModule/api/salesOrders';
import {
  SalesOrder,
  SalesOrderItem,
  SalesOrderItemTypes,
  SalesOrderPaymentStatus,
} from 'services/salesOrders/types';
import { getSettingsCompany } from 'services/settings/company/redux/selectors';
import { getSettingsSalesOrder } from 'services/settings/salesOrders/redux/selectors';
import { getSettingsShipping } from 'services/settings/shipping/redux/selectors';
import { getActiveUser } from 'services/user/redux/selectors';
import { logErrorCtx } from 'app/logging/Logger';
import { SalesOrderActiveIdState } from 'ui/modules/sales/pages/SalesOrderPage/components/SalesOrderDetailsCard/types';
import { getCurrencyById } from 'serviceModule/api/currencies';
import { initialSalesOrder } from 'services/salesOrders/consts';
import {
  transformToDuplicateAsCreditReturn,
  transformToDuplicatedItem,
} from 'ui/modules/sales/pages/SalesOrderPage/components/SalesOrderDetailsCard/helpers';
import { getSalesOrderId } from 'services/salesOrders/api';

// idea was to prepopulate this into the cache. too complicated to be worth it right now
// const salesOrderSettings = await SalesOrdersApi.fetchSalesOrderSettings();
// const customers = await SalesOrdersApi.fetchCustomers();
// const currencies = await SalesOrdersApi.fetchCurrencies();

function useNewSalesOrderDefaultValues() {
  const {
    companySettings,
    activeUser,
    soSettings: salesOrderSettings,
  } = useSalesOrderReduxState();

  return {
    locationId: activeUser?.user?.defaultLocationId ?? null,
    // this is the only place companySettings is used (creating new sales order)
    fobPointId: companySettings.defaultFobPointId,
    priorityId: companySettings.defaultPriorityId,
    shippingTermId: companySettings.defaultShippingTermId,
    salesTaxId: salesOrderSettings.salesTaxId,
    salesTax: salesOrderSettings.salesTax,
  };
}

function useGetSalesOrderDefaultValues(
  selectedSalesOrderId: number | null,
  activeSalesOrder: SalesOrder
) {
  const newSalesOrderDefaults = useNewSalesOrderDefaultValues();
  const getNewSalesOrderDefaultValues = (nextSalesOrdersNumber: number) => {
    const isNewSalesOrder =
      !selectedSalesOrderId ||
      selectedSalesOrderId === SalesOrderActiveIdState.New;
    const isDuplicateSalesOrder =
      selectedSalesOrderId === SalesOrderActiveIdState.Duplicate;
    const isDuplicateAsCreditReturn =
      selectedSalesOrderId === SalesOrderActiveIdState.DuplicateAsCreditReturn;

    switch (true) {
      case isNewSalesOrder: {
        return {
          ...initialSalesOrder,
          ...newSalesOrderDefaults,
          id: SalesOrderActiveIdState.New,
          number: nextSalesOrdersNumber,
        };
      }
      case isDuplicateSalesOrder:
        return transformToDuplicatedItem(
          activeSalesOrder,
          nextSalesOrdersNumber
        );
      case isDuplicateAsCreditReturn:
        return transformToDuplicateAsCreditReturn(activeSalesOrder);
      default:
        return null;
    }
  };
  return getNewSalesOrderDefaultValues;
}

export function useGetSelectedSalesOrder(
  selectedSalesOrderId: number | null,
  activeSalesOrder: SalesOrder
) {
  const { soSettings: salesOrderSettings, customers } =
    useSalesOrderReduxState();

  const getNewSalesOrderDefaultValues = useGetSalesOrderDefaultValues(
    selectedSalesOrderId,
    activeSalesOrder
  );

  // this needs:
  // error handling
  // logging
  // success notifications
  //
  // WARNING: "onDuplicateClicked" was removed and I don't know what that actually did
  const getSelectedSalesOrder = React.useCallback(
    async (salesOrderId: number | null) => {
      let salesOrder: SalesOrder;
      // no id or < 0 means duplicate or new
      if (salesOrderId === null || salesOrderId < 0) {
        const nextSalesOrdersNumber = await getSalesOrderId();
        // show notification?
        // there should be a way to tell the compiler that all cases are covered
        salesOrder = getNewSalesOrderDefaultValues(
          nextSalesOrdersNumber
        ) as SalesOrder;
      } else {
        salesOrder = await SalesOrdersApi.fetchSalesOrderById(salesOrderId);
      }
      // construct sales order (get customer, get currency)
      // everything below this point was originally added to the sales order in an effect somewhere after the fact
      // this is surely not the best way to do this but the hope is that this will get the object set
      // when it's fetched
      // reduce effects/rerenders and make dirty checks easier

      const selectedCustomer = customers.find(
        (c) => c.id === salesOrder.customerId
      )!;

      // move this one into a hook
      if (selectedCustomer?.currencyId) {
        const currency = await getCurrencyById(selectedCustomer.currencyId);
        selectedCustomer.currency = currency;
        salesOrder.customer = selectedCustomer;
      }
      const isCustomerTaxExempt = selectedCustomer?.taxExempt;
      const doesSalesOrderHaveTaxId = activeSalesOrder.salesTaxId;

      // this may also have to happen when the customer is updated
      if (isCustomerTaxExempt) {
        salesOrder.salesTaxId = null;
        salesOrder.salesTax = null;
      } else if (doesSalesOrderHaveTaxId) {
        salesOrder.salesTaxId = salesOrderSettings.salesTaxId;
        salesOrder.salesTax = salesOrderSettings.salesTax;
      }

      return salesOrder;
    },
    [selectedSalesOrderId]
  );

  return getSelectedSalesOrder;
}

export const saveSalesOrder = async (salesOrder: SalesOrder) => {
  // i can't imagine this needs to happen on every save
  const documents = salesOrder.documents.filter((d) => !d.deleted);
  const deletedDocuments = salesOrder.documents.filter((d) => d.deleted);
  const updatedSalesOrder = {
    ...salesOrder,
    documents,
  };

  try {
    await deleteDocuments(deletedDocuments);
  } catch (e) {
    logErrorCtx('Error while deleting documents', {});
  }
  if (!updatedSalesOrder.id || updatedSalesOrder.id < 0) {
    return await SalesOrdersApi.createSalesOrder(updatedSalesOrder);
  } else {
    return await SalesOrdersApi.updateSalesOrder(updatedSalesOrder);
  }
};

export function useSalesOrderReduxState() {
  const { items } = useSelector(getCustomers);
  const companySettings = useSelector(getSettingsCompany);
  const soSettings = useSelector(getSettingsSalesOrder);
  const activeUser = useSelector(getActiveUser);
  const shippingSettings = useSelector(getSettingsShipping);
  const connection = useSelector(getShippingConnection);

  return {
    customers: items,
    companySettings,
    soSettings,
    activeUser,
    shippingSettings,
    connection,
  };
}

export function getPaidStatus(paymentTotal: number, grandTotal: number) {
  if (paymentTotal === 0) {
    return SalesOrderPaymentStatus.Unpaid;
  }

  return grandTotal <= paymentTotal
    ? SalesOrderPaymentStatus.Paid
    : SalesOrderPaymentStatus.PartiallyPaid;
}

// only show confirmation modal if ship has miscsale,service and note item
export function salesOrderItemShopHasMiscSaleServiceAndNote(
  salesOrderItem: SalesOrderItem
) {
  const itemType = salesOrderItem?.saleItem?.item?.itemType;
  const salesOrderType = salesOrderItem?.salesOrderItemType;
  return (
    itemType === ItemType.Service ||
    salesOrderType === SalesOrderItemTypes.MiscSale ||
    salesOrderType === SalesOrderItemTypes.Note
  );
}
