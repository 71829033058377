import { createApiAction } from 'services/api';
import {
  StartAction,
  SuccessAction,
  FailureAction,
} from 'services/api/redux/types';
import {
  createFetchSearch,
  createAddSearch,
  createUpdateSearch,
  createDeleteSearch,
} from 'services/search';

export enum UsersActionTypes {
  FETCH_USERS_START = 'users/fetch_users_start',
  FETCH_USERS_SUCCESS = 'users/fetch_users_success',
  FETCH_USERS_FAILURE = 'users/fetch_users_failure',
  FETCH_ACTIVE_USER_START = 'user/fetch_active_user_start',
  FETCH_ACTIVE_USER_SUCCESS = 'user/fetch_active_user_success',
  FETCH_ACTIVE_USER_FAILURE = 'user/fetch_active_user_failure',
}

const fetchUsersActionStart = (): StartAction => ({
  type: UsersActionTypes.FETCH_USERS_START,
  payload: null,
});

const fetchUsersActionSuccess = (payload: any): SuccessAction => {
  return {
    type: UsersActionTypes.FETCH_USERS_SUCCESS,
    payload,
  };
};

const fetchUsersActionFailure = (error: string): FailureAction => ({
  type: UsersActionTypes.FETCH_USERS_FAILURE,
  payload: { error },
});

export const fetchUsers = () =>
  createApiAction(
    {
      path: '/v1/users?pageSize=100',
      method: 'GET',
    },
    fetchUsersActionStart,
    fetchUsersActionSuccess,
    fetchUsersActionFailure
  );

// SEARCH ACTION CREATORS

export const fetchSearch = createFetchSearch('user');
export const addSearch = createAddSearch('user');
export const updateSearch = createUpdateSearch('user');
export const deleteSearch = createDeleteSearch('user');

// ACTIVE CUSTOMER

const fetchActiveUserActionStart = (): StartAction => ({
  type: UsersActionTypes.FETCH_ACTIVE_USER_START,
  payload: null,
});

const fetchActiveUserActionSuccess = (payload: any): SuccessAction => ({
  type: UsersActionTypes.FETCH_ACTIVE_USER_SUCCESS,
  payload,
});

const fetchActiveUserActionFailure = (error: string): FailureAction => ({
  type: UsersActionTypes.FETCH_ACTIVE_USER_FAILURE,
  payload: { error },
});

export const fetchActiveUser = () =>
  createApiAction(
    {
      path: '/v1/users/me',
      method: 'GET',
    },
    fetchActiveUserActionStart,
    fetchActiveUserActionSuccess,
    fetchActiveUserActionFailure
  );
