import React, { memo, useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import { Box, Typography, CircularProgress } from '@mui/material';
import { Chart } from 'react-google-charts';

import { fetchSalesByRegions } from 'services/dashboard/api';
import { useCurrencyFormatter, useGetCurrencySymbol } from 'helpers';

import { RegionalSaleOrder } from 'services/dashboard/types';
import { DATE_FORMAT } from '../../consts';
import { useDashboardMapStyle } from './styled';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

interface DashboardMapProps {
  title: string;
  salesByRegion: RegionalSaleOrder[];
  setSalesByRegion: React.Dispatch<React.SetStateAction<RegionalSaleOrder[]>>;
  fromStartDate: moment.Moment;
  fromEndDate: moment.Moment;
  nonAddressedOrder: RegionalSaleOrder | null;
}

const DashboardMap: React.FC<DashboardMapProps> = (props) => {
  const {
    title,
    salesByRegion,
    setSalesByRegion,
    fromEndDate,
    fromStartDate,
    nonAddressedOrder,
  } = props;

  const classes = useDashboardMapStyle();
  const currencyFormatter = useCurrencyFormatter();

  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);

  const mapData = useMemo(() => {
    const baseColumn = selectedCountry ? 'State' : 'Country';

    const worldData: any = [[baseColumn, 'Total']];

    salesByRegion.forEach((s) => {
      worldData.push([
        _.get(s, baseColumn.toLowerCase()),
        s.grandTotalSum || 0,
      ]);
    });

    return worldData;
  }, [salesByRegion, selectedCountry]);

  const regionOptions = useMemo(() => {
    if (!selectedCountry) {
      return {};
    }

    return {
      region: selectedCountry,
      displayMode: 'regions',
      resolution: 'provinces',
    };
  }, [selectedCountry]);

  const returnToWorld = async () => {
    const newSalesByRegion = await fetchSalesByRegions(
      fromStartDate.format(DATE_FORMAT),
      fromEndDate.format(DATE_FORMAT)
    );
    setSalesByRegion(newSalesByRegion.salesByRegion);
    setSelectedCountry(null);
  };

  const countryClicked = useCallback(
    async ({ chartWrapper }) => {
      if (selectedCountry) {
        return;
      }

      const chart = chartWrapper.getChart();
      const selection = chart.getSelection();

      if (selection.length === 0) {
        return;
      }

      const countryIndex = selection[0].row + 1;
      const newSelectedCountry = mapData[countryIndex][0];

      const newSalesByRegion = await fetchSalesByRegions(
        fromStartDate.format(DATE_FORMAT),
        fromEndDate.format(DATE_FORMAT),
        newSelectedCountry
      );
      setSalesByRegion(newSalesByRegion.salesByRegion);
      setSelectedCountry(newSelectedCountry);
    },
    [setSalesByRegion, fromEndDate, fromStartDate, mapData, selectedCountry]
  );

  return (
    <>
      <Box className={classes.card}>
        {!!selectedCountry && (
          <FBOButton
            variant="secondary"
            color="positive"
            size="medium"
            onClick={returnToWorld}
            sx={{
              position: 'absolute',
              alignSelf: 'end',
              transform: 'translate(-18.5px, 24px)',
              zIndex: 99,
            }}
            data-qa="dashboard-world-map-button"
          >
            World Map
          </FBOButton>
        )}
        {!selectedCountry && (
          <Box sx={{ position: 'absolute', zIndex: '99', padding: '8px' }}>
            <Typography className={classes.cardHeaderTitle}>{title}</Typography>
            <Typography className={classes.cardChartTitle}>
              <b>Other orders</b>
              <br />
              {currencyFormatter(
                _.get(nonAddressedOrder, 'grandTotalSum', 0) || 0
              )}
            </Typography>
          </Box>
        )}
        <Box className={classes.mapContainer}>
          <Chart
            className={classes.mapInner}
            height="85%"
            loader={<CircularProgress />}
            formatters={[
              {
                type: 'NumberFormat',
                column: 1,
                options: {
                  prefix: useGetCurrencySymbol(),
                },
              },
            ]}
            chartType="GeoChart"
            data={mapData}
            chartEvents={[
              {
                eventName: 'select',
                callback: countryClicked,
              },
            ]}
            // Note: you will need to get a mapsApiKey for your project.
            // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
            // mapsApiKey="AIzaSyC-oV1ePGd1qilnHJ9tb8fCDAXAStvoDJA"
            rootProps={{ 'data-testid': '1' }}
            options={{
              colorAxis: { colors: ['#9BE9A8', '#216E39'] },
              position: 'unset',
              ...regionOptions,
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default memo(DashboardMap);
