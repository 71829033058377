import React from 'react';
import { ButtonBase, Box } from '@mui/material';

import { BreadCrumbProps } from './types';

const FBOBreadCrumb: React.FC<BreadCrumbProps> = (props) => {
  const { children, zIndex, ...otherProps } = props;

  const classes = {
    box: 'redesign breadcrumb',
    button: 'redesign breadcrumb',
  };

  return (
    <Box className={classes.box} zIndex={zIndex}>
      <ButtonBase className={classes.button} {...otherProps}>
        {children}
      </ButtonBase>
    </Box>
  );
};

export default React.memo(FBOBreadCrumb);
