import React, { memo, useState, useEffect, useMemo, useCallback } from 'react';
import { Box, InputAdornment, Tooltip } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { Modal } from 'ui/components/Modal/Modal';
import { TrackingTableTypes } from 'ui/components/Table/TrackingTable/types';
import {
  duplicateSerialNumberIndexes,
  resolveSerialTableErrors,
  sortedSerialListWithDuplicatesFirst,
  sortSerialList,
} from 'ui/components/Table/SerialTable/helpers';
import CycleCountSerialTable from 'ui/components/Table/SerialTable/CycleCountSerialTable';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';
import { TextField } from 'ui/components/TextField/TextField';
import { DatePickerWrapper } from 'ui/components/TextField/DatePickerWrapper';
import { Errors } from 'services/forms/validation';
import { showNotification } from 'services/api/notifications';
import { getLocations } from 'services/locations';
import {
  ItemTrackingType,
  TrackingGroup,
  TrackingInfo,
} from 'services/inventory';
import {
  getTrackingTypes,
  TrackingDataTypes,
} from 'services/settings/tracking';
import { getSettingsCompanyCountry } from 'services/settings/company';

import { generateSerialTableEditProps } from './helpers';
import { formatDate, useGetIntlDateFormatString } from 'helpers';
import { SerialModalProps } from './types';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { themeRestyle } from 'ui/theme';

const FBOSerialModal: React.FC<SerialModalProps> = ({
  visible,
  tableType,
  itemTrackingTypes,
  quantity,
  serialList,
  onCloseClicked,
  onApplyClicked,
  trackingGroup,
  locationId,
  uomDefaultAbbreviation,
  selectedUom,
  itemCost,
  onSetTrackingGroups = _.noop,
  duplicateArray,
  rowContainsDuplicates,
  canEditTracking = true,
}) => {
  const [formValues, setFormValues] = useState(serialList);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [errors, setErrors] = useState<Errors[]>([]);
  const [loadingContent, setLoadingContent] = useState<boolean>(false);
  const [showDuplicates, setShowDuplicates] = useState<boolean>(false);
  const [sortedSerials, setSortedSerials] = useState<boolean>(false);

  const { items: locations } = useSelector(getLocations);
  const trackingTypes = useSelector(getTrackingTypes).items;
  const companyCountry = useSelector(getSettingsCompanyCountry);

  const selectedLocation = useMemo(
    () => locations.find((l) => l.id === locationId),
    [locations, locationId]
  );

  const intlDateFormat = useGetIntlDateFormatString();

  useEffect(() => {
    setFormValues(serialList);
  }, [serialList]);

  const serialItemTrackingTypes: ItemTrackingType[] = useMemo(() => {
    // For serial table we need only serial tracking types
    return itemTrackingTypes.filter(
      (t) =>
        _.get(t, 'trackingType.trackingDataType', null) ===
        TrackingDataTypes.SerialNumber
    );
  }, [itemTrackingTypes]);

  const isViewTable = useMemo(
    () =>
      tableType === TrackingTableTypes.View ||
      tableType === TrackingTableTypes.ReceivingView,
    [tableType]
  );

  const serialTableEditProps = useMemo(
    () => generateSerialTableEditProps(tableType),
    [tableType]
  );

  const handleCancelClicked = useCallback(() => {
    onCloseClicked();
    setErrors([]);
    setFormValues(serialList);
  }, [serialList, onCloseClicked]);

  useEffect(() => {
    if (rowContainsDuplicates && duplicateArray?.length) {
      setShowDuplicates(true);
      setSortedSerials(true);
      sortSerialList(formValues);
      //check serial list for duplicates
      const duplicatesIndexArray = duplicateSerialNumberIndexes(
        formValues,
        duplicateArray
      );

      sortedSerialListWithDuplicatesFirst(
        formValues,
        setFormValues,
        duplicatesIndexArray
      );
    } else {
      setSortedSerials(false);
      setShowDuplicates(false);
    }
  }, [duplicateArray, visible]);

  const setTrackingGroups = (newTrackingInfoList: TrackingInfo[]) => {
    onSetTrackingGroups((old: TrackingGroup[]) => {
      const trackingGroupIndex = old.findIndex(
        (tg) => tg.id === trackingGroup.id
      );
      const newTrackingGroups = old.map((tg, index) => {
        if (index === trackingGroupIndex) {
          return {
            ...tg,
            trackingInfoList: newTrackingInfoList,
          };
        }
        return tg;
      });
      return newTrackingGroups;
    });
  };

  const handleChangeTracking = useCallback(
    (receivedTrackingInfo: TrackingInfo) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const newTrackingInfoList = trackingGroup?.trackingInfoList?.map(
          (ti) => {
            if (ti?.trackingTypeId === receivedTrackingInfo?.trackingTypeId) {
              return {
                ...ti,
                value: event.target.value,
              };
            }
            return ti;
          }
        );
        setTrackingGroups(newTrackingInfoList);
      },
    [onSetTrackingGroups, trackingGroup]
  );

  const handleChangeDateTracking = useCallback(
    (receivedTrackingInfo: TrackingInfo) =>
      (date: string | null | undefined) => {
        const newTrackingInfoList = trackingGroup?.trackingInfoList?.map(
          (ti) => {
            if (ti?.trackingTypeId === receivedTrackingInfo?.trackingTypeId) {
              if (!date) {
                return { ...ti };
              }
              return {
                ...ti,
                value: formatDate(date, companyCountry, intlDateFormat),
              };
            }
            return ti;
          }
        );
        setTrackingGroups(newTrackingInfoList);
      },
    [onSetTrackingGroups, trackingGroup]
  );
  const getLabel = (trackingInfo: TrackingInfo) => {
    return itemTrackingTypes?.find(
      (type) => type.trackingType?.id === trackingInfo.trackingTypeId
    )?.trackingType?.name;
  };

  const handleApplyClicked = useCallback(() => {
    sortSerialList(formValues);
    //check serial list for duplicates
    const duplicatesIndexArray = duplicateSerialNumberIndexes(formValues);

    if (duplicatesIndexArray.length > 0) {
      sortedSerialListWithDuplicatesFirst(
        formValues,
        setFormValues,
        duplicatesIndexArray
      );
      showNotification(
        'Duplicates found, please navigate to the beginning of the list and resolve them before continuing',
        {
          variant: 'error',
        }
      );
      setShowDuplicates(true);
      return;
    }

    // Validate right number of rows
    if (serialTableEditProps.canSelectRow && quantity !== selectedRows.length) {
      showNotification('Please select the right amount of serial rows!', {
        variant: 'warning',
      });
      return;
    }

    if (serialTableEditProps.canEditRow && quantity !== formValues.length) {
      showNotification(`Please add the right amount of serial rows!`, {
        variant: 'warning',
      });
      return;
    }

    const errors = resolveSerialTableErrors(formValues);
    setErrors(errors);
    // Validate that all serials are not empty
    const isValid = errors.every((e) => _.isEmpty(e));

    // Cancel if not valid
    if (!isValid) {
      return;
    }

    onApplyClicked(formValues, selectedRows);
  }, [
    serialTableEditProps,
    quantity,
    selectedRows,
    onApplyClicked,
    formValues,
    setFormValues,
  ]);

  const handleSelectedRowChanged = useCallback((newRowIds: number[]) => {
    setSelectedRows(newRowIds);
  }, []);

  const renderTrackingField = (trackingInfo: TrackingInfo) => {
    const trackingType = trackingTypes.find(
      (trackingType) => trackingType.id === trackingInfo.trackingTypeId
    );
    if (
      trackingType?.trackingDataType === TrackingDataTypes.Date &&
      canEditTracking
    ) {
      return (
        <DatePickerWrapper
          className="redesign"
          onChange={handleChangeDateTracking(trackingInfo)}
          value={trackingInfo.value}
          label={getLabel(trackingInfo)}
          dataQa={`serial-modal-tracking-group-${getLabel(trackingInfo)}`}
        />
      );
    } else {
      return (
        <TextField
          className="redesign"
          variant="standard"
          type="text"
          value={trackingInfo.value}
          label={getLabel(trackingInfo)}
          onChange={handleChangeTracking(trackingInfo)}
          dataQa={`serial-modal-tracking-group-${getLabel(trackingInfo)}`}
          readOnly={!canEditTracking}
        />
      );
    }
  };

  const ModalActions = useCallback(() => {
    return (
      <Box display="flex" justifyContent="space-between" width="100%">
        <FBOButton
          variant="secondary"
          color="positive"
          size="medium"
          onClick={handleApplyClicked}
          data-qa="serial-modal-apply-button"
        >
          Back To Tracking
        </FBOButton>
      </Box>
    );
  }, [isViewTable, handleApplyClicked]);

  return (
    <Modal
      className={'redesign inventory-event-modal'}
      open={visible}
      title="Cycle and Edit Inventory"
      withoutDefaultPadding
      isLoadingContent={loadingContent}
      nestedScrollAreas
      ModalActionsComponent={ModalActions}
      dataQa="serial"
      onCancelClicked={handleCancelClicked}
    >
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        columns={14}
        spacing={themeRestyle.spacing(2)}
        padding={`0px ${themeRestyle.spacing(4)} 0px ${themeRestyle.spacing(
          4
        )}`}
        disableEqualOverflow
      >
        <Grid xs={3}>
          <TextField
            className="redesign"
            variant="standard"
            readOnly
            type="text"
            value={selectedLocation?.name || ''}
            label="Location"
            dataQa="serial-modal-selected-location"
          />
        </Grid>
        <Grid xs={3}>
          <Tooltip
            title="Changing quantity is not allowed when adding serials. Go back to Tracking to edit quantity."
            data-qa="serial-modal-quantity-tooltip"
          >
            <TextField
              className="redesign"
              variant="standard"
              readOnly
              type="text"
              value={quantity}
              label="Quantity"
              dataQa="serial-modal-quantity"
            />
          </Tooltip>
        </Grid>
        <Grid xs={2}>
          <TextField
            className="redesign"
            variant="standard"
            readOnly
            type="text"
            value={selectedUom?.name || ''}
            label="UOM"
            dataQa="serial-modal-selected-uom"
          />
        </Grid>
        <Grid xs={3}>
          <TextField
            className="redesign"
            variant="standard"
            readOnly
            type="text"
            value={
              trackingGroup.quantity || 0 - trackingGroup.committedQuantity
            }
            label="Available to Cycle"
            additionalInputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {uomDefaultAbbreviation}
                </InputAdornment>
              ),
            }}
            dataQa="serial-modal-available-to-cycle"
          />
        </Grid>
        <Grid xs={3}>
          <CurrencyField
            readOnly
            label="Avg. Unit Cost"
            value={itemCost || 0}
            allowNegative
            dataQa="serial-modal-average-unit-cost"
          />
        </Grid>
        {trackingGroup?.trackingInfoList?.map((trackingInfo: TrackingInfo) => (
          <Grid xs={3} key={trackingInfo.id}>
            {!canEditTracking ? (
              <Tooltip title="Can't edit tracking fields if the quantity is committed">
                {renderTrackingField(trackingInfo)}
              </Tooltip>
            ) : (
              <>{renderTrackingField(trackingInfo)}</>
            )}
          </Grid>
        ))}
      </Grid>
      <CycleCountSerialTable
        serialList={formValues}
        setIsLoading={setLoadingContent}
        isLoading={loadingContent}
        setSerialList={setFormValues}
        minSerialQuantity={quantity}
        itemTrackingTypes={serialItemTrackingTypes}
        selectedRows={selectedRows}
        onSelectedRowsChanged={handleSelectedRowChanged}
        disableAutoAssign={false}
        allowSerialNumberImport
        rowErrors={errors}
        showDuplicates={showDuplicates}
        duplicateArray={duplicateArray}
        alreadySorted={sortedSerials}
        canEditRow
        canSelectRow
        headerMargin="0px 24px"
      />
    </Modal>
  );
};

export default memo(FBOSerialModal);
